import { CurrencyEnum, PriceIntervalEnum } from "@finway-group/shared/lib/models"
import React from "react"

import PriceLabel from "Components/priceLabel"

import { PerDiemExpenditureTypeEnum } from "./perDiemOptionRow"

interface PerDiemOptionCellInterface {
    fieldName: PerDiemExpenditureTypeEnum
    isExpenditureActive: boolean
    onChange: (field: string, checked: boolean) => void
    value: number
    icon: JSX.Element
}
const PerDiemOptionCell: React.FC<PerDiemOptionCellInterface> = ({ fieldName, onChange, isExpenditureActive, value, icon }) => {
    const shouldShowNumber = fieldName === PerDiemExpenditureTypeEnum.ACCOMMODATION ? !isExpenditureActive : isExpenditureActive
    const isDeducting = fieldName !== PerDiemExpenditureTypeEnum.ACCOMMODATION
    const displayValue = Math.abs(value) * (Number(isDeducting) ? -1 : 1)
    return (
        <>
            <div className="flex justify-center h-48" onClick={() => onChange(fieldName, !isExpenditureActive)}>
                <div className={`rounded-full flex justify-center w-48 border-2 ${isExpenditureActive ? "border-solid" : "border-transparent"}`}>
                    <span className="self-center w-24 h-24">{icon}</span>
                </div>
            </div>
            <div className="text-center h-16">{shouldShowNumber && <PriceLabel currency={CurrencyEnum.EUR} value={displayValue} interval={PriceIntervalEnum.ONE_TIME} />}</div>
        </>
    )
}

export default React.memo(PerDiemOptionCell)
