import { applyFilter } from "Shared/hooks/filter.hooks"
import { getTableFilterObjectStore } from "Shared/hooks/table.hooks"

export const subscribeToFilter = async (data: any) => {
    const { table } = data.fullDocument.metaData.updatedFields
    const newFilterObj = data.fullDocument.metaData.updatedFields.parameters ?? {}
    applyFilter(table, newFilterObj, undefined, false)
}

export const isIncomingFilterEqualToLocal = (incomingSocketData: any) => {
    const { table } = incomingSocketData.fullDocument.metaData.updatedFields
    const newFilterObj = incomingSocketData.fullDocument.metaData.updatedFields.parameters
    const oldFilterObject = getTableFilterObjectStore(table)
    return JSON.stringify(newFilterObj) === JSON.stringify(oldFilterObject)
}

export default subscribeToFilter
