import {
    ApprovalScopeEnum,
    Employee,
    Expense,
    ExpenseKindEnum,
    ExpenseStatusEnum,
    FilterPresetEnum,
    SubscriptionTypeEnum,
    TransactionFlowFilterEnum,
    TransactionTypeEnum,
} from "@finway-group/shared/lib/models"
import { TransactionStatusEnum } from "@finway-group/shared/lib/models/transaction/transactionStatus.enum"
import { SortOrder } from "antd/lib/table/interface"

import { ATTACHABLE_EXPENSE_STATUSES } from "Shared/config/consts"
import { TableActionsTypes, UpdateTableFilterType } from "Shared/store/actions/tables/tableTypes.enum"
import { TablesEnum } from "Shared/store/reducers/tableConfigReducer"
import type { Table } from "Shared/store/reducers/tableReducer"
import { isFolderExpense } from "Shared/utils/expense.utils"
import { getFilterQueryString } from "Shared/utils/filter.utils"

export enum TableDocType {
    INBOX_INVOICE = "INBOX_INVOICE",
    EXPENSE = "EXPENSE",
    TRANSACTION = "TRANSACTION",
    SUBSCRIPTION = "SUBSCRIPTION",
    CARD = "CARD",
    EMPLOYEE = "EMPLOYEE",
    COST_CENTER = "COST_CENTER",
    VENDOR = "VENDOR",
    WORKFLOW = "WORKFLOW",
    TRANSACTION_CATEGORY = "TRANSACTION_CATEGORY",
    TRIP_FOLDER = "TRIP_FOLDER",
    EXPORT_HISTORY = "EXPORT_HISTORY",
}

export const getTableActionType = (tableActionsTypes: TableActionsTypes, tablesEnum: TablesEnum): string => `${tableActionsTypes}_${tablesEnum}`

export const getSortOrderForColumn = (table: Table<any>, fieldName: string) => (table.data.sort.field === fieldName ? (table.data.sort.order as SortOrder) : undefined)

/**
 * Returns the sort that the table has when the user hasn't selected any particular sorting.
 */
export const getTableDefaultSort = (table: TablesEnum): { field: string; order: string } => {
    switch (table) {
        case TablesEnum.TODO_PURCHASE_APPROVAL:
        case TablesEnum.TODO_INVOICE_APPROVAL:
        case TablesEnum.TODO_INVOICE_AND_PURCHASE:
        case TablesEnum.TODO_DOCS_NEEDED:
        case TablesEnum.TODO_APPROVAL_PENDING:
        case TablesEnum.REVIEW_REQUESTS:
        case TablesEnum.PAY_AND_EXPORT_TO_BE_PAID:
        case TablesEnum.PAY_AND_EXPORT_TO_BE_EXPORTED:
        case TablesEnum.ALL_REQUESTS:
        case TablesEnum.TRIP_FOLDER_DRAFT:
            // oldest requests first
            return { field: "datePurchased", order: "ascend" }
        case TablesEnum.DONE_REQUESTS:
        case TablesEnum.IN_PROGRESS:
        case TablesEnum.ATTACH_EXPENSE:
            return { field: "datePurchased", order: "descend" }
        case TablesEnum.CARDS:
            return { field: "userObject.firstName", order: "descend" }
        case TablesEnum.EMPLOYEES:
        case TablesEnum.EMPLOYEE_VENDORS:
            return { field: "firstName", order: "descend" }
        case TablesEnum.TRANSACTIONS_INFLOW:
        case TablesEnum.TRANSACTIONS_OUTFLOW:
        case TablesEnum.TRANSACTIONS_ARCHIVE:
        case TablesEnum.TRANSACTIONS_ALL:
            return { field: "transactionData.date", order: "descend" }
        case TablesEnum.EXPENSE_TRANSACTIONS:
            return { field: "probabilityForExpense", order: "descend" }
        case TablesEnum.CARD_TRANSACTIONS:
            return { field: "transactionData.date", order: "descend" }
        case TablesEnum.SUBSCRIPTIONS:
            return { field: "name", order: "ascend" }
        case TablesEnum.INBOX_INVOICE_MODAL_EXPENSES:
            return { field: "probability", order: "descend" }
        case TablesEnum.EXPORT_HISTORY:
            return { field: "exportDate", order: "descend" }
        default:
            return { field: "", order: "" }
    }
}

export const EMPLOYEE_SEARCH_TARGETS: Array<keyof Employee> = ["firstName", "lastName", "email"]

export const getSearchTargets = (table: TablesEnum, useGross: boolean) => {
    let searchTargets: Array<string> = []
    switch (table) {
        case TablesEnum.TODO_PURCHASE_APPROVAL:
        case TablesEnum.TODO_INVOICE_APPROVAL:
        case TablesEnum.TODO_INVOICE_AND_PURCHASE:
        case TablesEnum.TODO_DOCS_NEEDED:
        case TablesEnum.TODO_APPROVAL_PENDING:
        case TablesEnum.TRIP_FOLDER_DRAFT:
        case TablesEnum.REVIEW_REQUESTS:
        case TablesEnum.PAY_AND_EXPORT_TO_BE_PAID:
        case TablesEnum.PAY_AND_EXPORT_TO_BE_EXPORTED:
        case TablesEnum.DONE_REQUESTS:
        case TablesEnum.ALL_REQUESTS:
        case TablesEnum.IN_PROGRESS:
        case TablesEnum.INBOX_INVOICE_MODAL_EXPENSES:
        case TablesEnum.ATTACH_EXPENSE:
            searchTargets = [
                "approverName",
                "requesterName",
                "vendorName",
                "invoiceNumber",
                "description",
                "items.name",
                "expenseNumber",
                useGross ? "totalGrossPrice" : "totalNetPrice",
            ]
            break
        case TablesEnum.ARCHIVE:
            searchTargets = ["invoiceNumber", "description", "items.name", "expenseNumber", useGross ? "totalGrossPrice" : "totalNetPrice"]
            break
        case TablesEnum.SUBSCRIPTIONS:
            searchTargets = ["invoiceNumber", "description", "name", "items.name", useGross ? "totalGrossPrice" : "totalNetPrice"]
            break
        case TablesEnum.CARDS:
            searchTargets = ["cardName", "last4Digits", "userObject.firstName", "userObject.lastName"]
            break
        case TablesEnum.TRANSACTIONS_INFLOW:
        case TablesEnum.TRANSACTIONS_OUTFLOW:
        case TablesEnum.EXPENSE_TRANSACTIONS:
        case TablesEnum.TRANSACTIONS_ARCHIVE:
        case TablesEnum.TRANSACTIONS_ALL:
            searchTargets = ["transactionData.description", "transactionData.iban", "transactionData.amount", "transactionData.recipient"]
            break
        case TablesEnum.EMPLOYEES:
        case TablesEnum.EMPLOYEE_VENDORS:
            searchTargets = EMPLOYEE_SEARCH_TARGETS
            break
        case TablesEnum.INBOX_INVOICE:
        case TablesEnum.INBOX_INVOICE_MODAL_INVOICES:
            searchTargets = ["fileName", "uploadedByObject.firstName", "uploadedByObject.lastName", "vendorName", "ocrResults.totalGrossPrice", "ocrResults.totalGrossPrice"]
            break
        default:
            searchTargets = []
    }

    return searchTargets
}

export const getTableDocType = (table: TablesEnum) => {
    switch (table) {
        case TablesEnum.INBOX_INVOICE:
        case TablesEnum.INBOX_INVOICE_MODAL_INVOICES:
            return TableDocType.INBOX_INVOICE
        case TablesEnum.TODO_PURCHASE_APPROVAL:
        case TablesEnum.TODO_INVOICE_AND_PURCHASE:
        case TablesEnum.TODO_INVOICE_APPROVAL:
        case TablesEnum.TODO_DOCS_NEEDED:
        case TablesEnum.TODO_APPROVAL_PENDING:
        case TablesEnum.REVIEW_REQUESTS:
        case TablesEnum.PAY_AND_EXPORT_TO_BE_PAID:
        case TablesEnum.PAY_AND_EXPORT_TO_BE_EXPORTED:
        case TablesEnum.DONE_REQUESTS:
        case TablesEnum.IN_PROGRESS:
        case TablesEnum.ALL_REQUESTS:
        case TablesEnum.SUBSCRIPTIONS:
        case TablesEnum.ARCHIVE:
        case TablesEnum.INBOX_INVOICE_MODAL_EXPENSES:
        case TablesEnum.ATTACH_EXPENSE:
            return TableDocType.EXPENSE
        case TablesEnum.TRIP_FOLDER_DRAFT:
            return TableDocType.TRIP_FOLDER
        case TablesEnum.CARDS:
            return TableDocType.CARD
        case TablesEnum.CARD_TRANSACTIONS:
        case TablesEnum.TRANSACTIONS_INFLOW:
        case TablesEnum.TRANSACTIONS_OUTFLOW:
        case TablesEnum.EXPENSE_TRANSACTIONS:
        case TablesEnum.TRANSACTIONS_ARCHIVE:
        case TablesEnum.TRANSACTIONS_ALL:
            return TableDocType.TRANSACTION
        case TablesEnum.EMPLOYEES:
        case TablesEnum.EMPLOYEE_VENDORS:
            return TableDocType.EMPLOYEE
        case TablesEnum.COST_CENTERS:
            return TableDocType.COST_CENTER
        case TablesEnum.VENDORS:
            return TableDocType.VENDOR
        case TablesEnum.WORKFLOWS:
            return TableDocType.WORKFLOW
        case TablesEnum.TRANSACTION_CATEGORIES_INFLOW:
        case TablesEnum.TRANSACTION_CATEGORIES_OUTFLOW:
            return TableDocType.TRANSACTION_CATEGORY
        case TablesEnum.EXPORT_HISTORY:
            return TableDocType.EXPORT_HISTORY
        default:
            return ""
    }
}

let tablesEnumMapObject: any
/**
 * Concatenates the string passed in the first parameter with the enum key of the table passed in the second parameter.
 * The return value should match the enums declared here: {@link TableActionsTypes}.
 *
 * Example
 *  ```js
 * const tableType = getTableType("UPDATE_TABLE_PAGINATION", TablesEnum.EMPLOYEES)
 * console.log(tableType) // "UPDATE_TABLE_PAGINATION_EMPLOYEES"
 * ```
 */
export const getTableType = (tableType: string, table: TablesEnum) => {
    if (!tablesEnumMapObject) {
        tablesEnumMapObject = {}
        for (const [key, value] of Object.entries(TablesEnum)) {
            tablesEnumMapObject[value] = key
        }
    }
    const enumTable = tablesEnumMapObject[table]

    return `${tableType}_${enumTable}`
}

export const getFilterTableType = (tableType: UpdateTableFilterType, table: TablesEnum) => {
    // If table is from global dashboard filter return
    if (table === TablesEnum.TODO_INVOICE_APPROVAL) {
        return TableActionsTypes.UPDATE_DASHBOARD_TABLES_FILTER
    }

    return getTableType(tableType, table)
}

export const isTableFilterApplied = (filterObject: { [key: string]: string | object | Array<string> }): boolean => !!Object.keys(filterObject).length

export const generateTableFilterQueryString = (table: TablesEnum, useGross: boolean, filterObject: any) => {
    switch (table) {
        case TablesEnum.TODO_PURCHASE_APPROVAL:
        case TablesEnum.TODO_INVOICE_APPROVAL:
        case TablesEnum.TODO_DOCS_NEEDED:
        case TablesEnum.TODO_INVOICE_AND_PURCHASE:
        case TablesEnum.TODO_APPROVAL_PENDING:
        case TablesEnum.REVIEW_REQUESTS:
        case TablesEnum.PAY_AND_EXPORT_TO_BE_PAID:
        case TablesEnum.PAY_AND_EXPORT_TO_BE_EXPORTED:
        case TablesEnum.DONE_REQUESTS:
        case TablesEnum.IN_PROGRESS:
        case TablesEnum.ARCHIVE:
        case TablesEnum.INBOX_INVOICE:
        case TablesEnum.INBOX_INVOICE_MODAL_EXPENSES:
        case TablesEnum.INBOX_INVOICE_MODAL_INVOICES:
        case TablesEnum.CARDS:
        case TablesEnum.EMPLOYEES:
        case TablesEnum.SUBSCRIPTIONS:
        case TablesEnum.POSSIBLE_MATCHES:
        case TablesEnum.TRANSACTIONS_INFLOW:
        case TablesEnum.TRANSACTIONS_OUTFLOW:
        case TablesEnum.TRANSACTIONS_ALL:
        case TablesEnum.EXPENSE_TRANSACTIONS:
        case TablesEnum.TRANSACTIONS_ARCHIVE:
        case TablesEnum.ATTACH_EXPENSE:
        case TablesEnum.TRIP_FOLDER_DRAFT:
            return getFilterQueryString(filterObject, useGross)
        case TablesEnum.ALL_REQUESTS:
            const draftQueryParam = !filterObject?.presets?.includes(FilterPresetEnum.DRAFT_TRIP_FOLDER) ? `&isDraft[nis]=true` : ``
            return draftQueryParam + getFilterQueryString(filterObject, useGross)

        default:
            return ""
    }
}

export const getFolderIds = (expenses: Array<Expense>) => expenses.filter((expense: Expense) => isFolderExpense(expense)).map(({ _id }: any) => _id)

export const getChildrenQuery = ({ queryParams, applySearchQuery, folderIds }: { queryParams: any; applySearchQuery: boolean; folderIds: Array<string> }) =>
    Object.assign(queryParams, {
        // When fetching children, ignore the searchQueryString and search because we dont want to apply the searching when populating a folder's children
        ...(!applySearchQuery
            ? {
                  searchQueryString: "",
                  search: "",
              }
            : {}),

        filterQueryString: `&kind[ne]=${ExpenseKindEnum.TRIP_FOLDER}&deleted[is]=false&folderId[in]=${folderIds}`,
        limit: folderIds.length * 20,
        page: 1,
    })

export const buildTableQueryString = ({
    table,
    loggedInUserId,
    transactionArchiveAfterXDaysQueryString,
    archiveAfterXDaysQueryString,
}: {
    table: TablesEnum
    loggedInUserId: string
    transactionArchiveAfterXDaysQueryString: string
    archiveAfterXDaysQueryString: string
}) => {
    const notDeletedQueryString = "&deleted[is]=false"

    switch (table) {
        case TablesEnum.TODO_PURCHASE_APPROVAL:
            return (
                `&status[in]=${ExpenseStatusEnum.PURCHASE_PENDING}&requestedBy._id[ne]=${loggedInUserId}${notDeletedQueryString}${archiveAfterXDaysQueryString}` +
                `&includeUserIfIsApprover=true&approvalScope=${ApprovalScopeEnum.EXPENSE_PURCHASE}`
            )
        case TablesEnum.TODO_INVOICE_APPROVAL:
            return (
                `&status[in]=${ExpenseStatusEnum.INVOICE_PENDING}&isDraft[nis]=true` +
                `&requestedBy._id[ne]=${loggedInUserId}${notDeletedQueryString}${archiveAfterXDaysQueryString}` +
                `&includeUserIfIsApprover=true&approvalScope=${ApprovalScopeEnum.EXPENSE_INVOICE}` +
                `&todoInvoiceApprovalTable=true`
            )
        case TablesEnum.TODO_INVOICE_AND_PURCHASE:
            return (
                `&status[in]=${ExpenseStatusEnum.INVOICE_PENDING},${ExpenseStatusEnum.PURCHASE_PENDING}` +
                `&requestedBy._id[ne]=${loggedInUserId}${notDeletedQueryString}${archiveAfterXDaysQueryString}`
            )
        case TablesEnum.INBOX_INVOICE_MODAL_EXPENSES:
        case TablesEnum.TODO_DOCS_NEEDED:
            return `&status[in]=${ExpenseStatusEnum.DOCS_NEEDED}${notDeletedQueryString}${archiveAfterXDaysQueryString}&todoDocsNeededTable=true`
        case TablesEnum.TODO_APPROVAL_PENDING:
            // isDraft query using [nis]=true because we want get all with isDraft=false or isDraft=undefined
            // using [is]=false will return all with isDraft=false, but will not return isDraft=undefined
            return (
                `&status[in]=${ExpenseStatusEnum.PURCHASE_PENDING},${ExpenseStatusEnum.INVOICE_PENDING}` +
                `&requestedBy._id[eq]=${loggedInUserId}${notDeletedQueryString}${archiveAfterXDaysQueryString}&isDraft[nis]=true` +
                `&excludeUserIfIsApprover=true`
            )

        case TablesEnum.PAY_AND_EXPORT_TO_BE_PAID:
            return `&status[in]=${ExpenseStatusEnum.REVIEWED}${notDeletedQueryString}${archiveAfterXDaysQueryString}`
        case TablesEnum.PAY_AND_EXPORT_TO_BE_EXPORTED:
            return `&status[in]=${ExpenseStatusEnum.PAID}${notDeletedQueryString}${archiveAfterXDaysQueryString}`

        case TablesEnum.REVIEW_REQUESTS:
            return `&status[eq]=${ExpenseStatusEnum.APPROVED}${notDeletedQueryString}${archiveAfterXDaysQueryString}`

        case TablesEnum.DONE_REQUESTS:
            // to not show duplicates with initial recurring expense
            return `&status[in]=${ExpenseStatusEnum.DONE},${ExpenseStatusEnum.DECLINED}&isFullyProcessedSubscription[is]=false${notDeletedQueryString}${archiveAfterXDaysQueryString}`
        case TablesEnum.IN_PROGRESS:
            return `&status[in]=${ExpenseStatusEnum.APPROVED},${ExpenseStatusEnum.REVIEWED},${ExpenseStatusEnum.PAID}&isFullyProcessedSubscription[is]=false${notDeletedQueryString}${archiveAfterXDaysQueryString}`
        case TablesEnum.ALL_REQUESTS:
            return `&isFullyProcessedSubscription[is]=false${notDeletedQueryString}${archiveAfterXDaysQueryString}`
        case TablesEnum.ARCHIVE:
            return `&archived[is]=true&isFullyProcessedSubscription[is]=false${notDeletedQueryString}${archiveAfterXDaysQueryString}`

        case TablesEnum.SUBSCRIPTIONS:
            return `&kind[eq]=${ExpenseKindEnum.SUBSCRIPTION}&subscriptionType[eq]=${SubscriptionTypeEnum.INITIAL_REQUEST}&status[in]=${ExpenseStatusEnum.PAID},${ExpenseStatusEnum.DONE}${notDeletedQueryString}`

        case TablesEnum.CARDS:
            return ""

        case TablesEnum.TRANSACTIONS_INFLOW:
            return `&transactionFlow=${TransactionFlowFilterEnum.INFLOW}&transactionType[in]=${TransactionTypeEnum.BANK_TRANSACTION},${TransactionTypeEnum.CSV_TRANSACTION},${TransactionTypeEnum.CARD_TRANSACTION}${transactionArchiveAfterXDaysQueryString}`
        case TablesEnum.TRANSACTIONS_OUTFLOW:
            return `&transactionFlow=${TransactionFlowFilterEnum.OUTFLOW}&transactionType[in]=${TransactionTypeEnum.BANK_TRANSACTION},${TransactionTypeEnum.CSV_TRANSACTION},${TransactionTypeEnum.CARD_TRANSACTION}${transactionArchiveAfterXDaysQueryString}`
        case TablesEnum.TRANSACTIONS_ALL:
            return `&transactionType[in]=${TransactionTypeEnum.BANK_TRANSACTION},${TransactionTypeEnum.CSV_TRANSACTION},${TransactionTypeEnum.CARD_TRANSACTION}${transactionArchiveAfterXDaysQueryString}`
        case TablesEnum.EXPENSE_TRANSACTIONS:
            return `&status[ne]=${TransactionStatusEnum.FAILED}&transactionType[in]=${TransactionTypeEnum.BANK_TRANSACTION},${TransactionTypeEnum.CSV_TRANSACTION},${TransactionTypeEnum.CARD_TRANSACTION}${transactionArchiveAfterXDaysQueryString}`
        case TablesEnum.TRANSACTIONS_ARCHIVE:
            return `&archived[is]=true&transactionType[in]=${TransactionTypeEnum.BANK_TRANSACTION},${TransactionTypeEnum.CSV_TRANSACTION},${TransactionTypeEnum.CARD_TRANSACTION}${transactionArchiveAfterXDaysQueryString}`
        case TablesEnum.ATTACH_EXPENSE:
            const unattachableKinds = [ExpenseKindEnum.TRIP_FOLDER, ExpenseKindEnum.RECURRING_SUBSCRIPTION, ExpenseKindEnum.SUBSCRIPTION]
            const filterArchived = `&${archiveAfterXDaysQueryString}&archived[is]=false`
            return `&kind[nin]=${unattachableKinds.join(",")}${notDeletedQueryString}&folderId[set]=false&status[in]=${ATTACHABLE_EXPENSE_STATUSES.join(",")}${filterArchived}`
        case TablesEnum.TRIP_FOLDER_DRAFT:
            //* Draft folder table should only be visible to each user with their own requests
            return `&kind[eq]=${ExpenseKindEnum.TRIP_FOLDER}${notDeletedQueryString}&isDraft[is]=true&requestedBy._id[eq]=${loggedInUserId}${archiveAfterXDaysQueryString}`
        default:
            return ""
    }
}
