import { Company } from "@finway-group/shared/lib/models"
import { Form, Modal } from "antd"
import { Store } from "antd/lib/form/interface"
import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import { CompanySettingsForm } from "Components/forms"
import { useLoggedInEmployeeProfile } from "Shared/hooks/user.hooks"
import { EmployeeService, NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { createCompany, updateCompany } from "Shared/store/actions/company/companyActions"
import { removeStringWhiteSpaces } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

interface CompanySettingsModalProps {
    isShowing: boolean
    isEditMode: boolean
    company: Company
    onCancel: () => void
}
const CompanySettingsModal = ({ isShowing, company, isEditMode, onCancel }: CompanySettingsModalProps) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const loggedInUser = useLoggedInEmployeeProfile()

    const [formInstance] = Form.useForm()

    const [isLoading, setIsLoading] = useStateIfMounted(false)
    const autoApprover = EmployeeService.getAutoApprover()

    const onSubmit = async (values: Store) => {
        setIsLoading(true)

        const isBusinessAddressFilled = values.businessAddressAddressLine || values.businessAddressCity || values.businessAddressCounty || values.businessAddressZip
        const isDeliveryAddressFilled = values.deliveryAddressAddressLine || values.deliveryAddressCity || values.deliveryAddressCountry || values.deliveryAddressZip

        const updatedCompany: any = {
            ...values,
            logo:
                values.logo?.file?.status === "removed"
                    ? ""
                    : isEditMode
                    ? company.logo !== values.logo
                        ? values.logo.file
                            ? values.logo.file.xhr
                            : values.logo
                        : company.logo
                    : values.logo && values.logo?.file
                    ? values.logo.file.xhr
                    : values.logo,
            ...(isBusinessAddressFilled
                ? {
                      businessAddress: {
                          addressLine1: values.name,
                          addressLine2: values.businessAddressAddressLine,
                          city: values.businessAddressCity,
                          country: values.businessAddressCountry,
                          zip: values.businessAddressZip,
                      },
                  }
                : {}),
            ...(isDeliveryAddressFilled
                ? {
                      deliveryAddress: {
                          addressLine1: values.name,
                          addressLine2: values.deliveryAddressAddressLine,
                          city: values.deliveryAddressCity,
                          country: values.deliveryAddressCountry,
                          zip: values.deliveryAddressZip,
                      },
                  }
                : {}),
        }

        // delete unused ones
        delete updatedCompany.businessAddressAddressLine
        delete updatedCompany.businessAddressCity
        delete updatedCompany.businessAddressCountry
        delete updatedCompany.businessAddressZip
        delete updatedCompany.deliveryAddressAddressLine
        delete updatedCompany.deliveryAddressCity
        delete updatedCompany.deliveryAddressCountry
        delete updatedCompany.deliveryAddressZip

        if (updatedCompany.iban) updatedCompany.iban = removeStringWhiteSpaces(updatedCompany.iban)
        if (!company.fallbackApprover && loggedInUser.id !== autoApprover.id) updatedCompany.fallbackApprover = loggedInUser.id

        try {
            if (isEditMode) {
                await dispatch(updateCompany({ ...updatedCompany, _id: company._id }))
            } else {
                await dispatch(createCompany(updatedCompany))
            }
            NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:settings.company.updated.title"), t("notification:settings.company.updated.message"))
            onHide()
        } catch (error) {
            setIsLoading(false)
            NotificationService.showErrorNotificationBasedOnResponseError(error, t("error:settings.edit.title"))
        }
    }

    const onHide = () => {
        setIsLoading(false)
        formInstance.resetFields()
        onCancel()
    }

    return (
        <Modal
            title={t(`action:company_settings.${isEditMode ? "edit" : "add_new"}`)}
            visible={isShowing}
            className="ant-modal--large"
            maskClosable={false}
            onOk={formInstance.submit}
            onCancel={onHide}
            okText={<span data-testid="saveCompanyButton">{t("action:company_settings.save")}</span>}
            cancelText={t("action:cancel")}
            confirmLoading={isLoading}
            keyboard
            destroyOnClose
        >
            <CompanySettingsForm formInstance={formInstance} company={company} onSubmit={onSubmit} isNew={!isEditMode} />
        </Modal>
    )
}

export default React.memo(CompanySettingsModal)
