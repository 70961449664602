import { ExpenseAccount } from "@finway-group/shared/lib/models"
import { ActionCreator, Dispatch } from "redux"

import { ExpenseAccountService } from "Shared/services"
import { ThunkResult } from "Shared/store"

import { CancelFetchAction, FetcherActionTypes } from "../fetcher/fetcherTypes"
import {
    CreateManyExpenseAccountsAction,
    DeleteManyExpenseAccountsAction,
    ExpenseAccountActionTypes,
    FetchAllExpenseAccountsAction,
    UpdateManyExpenseAccountsAction,
} from "./expenseAccountTypes"

export const fetchAllExpenseAccounts: ActionCreator<ThunkResult<Array<ExpenseAccount>>> = () => async (dispatch: Dispatch) => {
    const expenseAccounts = await ExpenseAccountService.fetchExpenseAccounts()
    const cancelFetchAction: CancelFetchAction = {
        type: FetcherActionTypes.CANCEL_FETCH,
        isFetchCancelled: true,
    }

    dispatch(cancelFetchAction)

    const fetchAllExpenseAccounts: FetchAllExpenseAccountsAction = {
        type: ExpenseAccountActionTypes.FETCH_ALL_EXPENSE_ACCOUNTS,
        expenseAccounts,
    }

    dispatch(fetchAllExpenseAccounts)
    return expenseAccounts
}

export const deleteExpenseAccounts = (deleteExpenseAccounts: Array<ExpenseAccount>) => async (dispatch: Dispatch) => {
    const remainingExpenseAccounts = await ExpenseAccountService.deleteExpenseAccounts(deleteExpenseAccounts)
    const deleteManyExpenseAccountsAction: DeleteManyExpenseAccountsAction = {
        type: ExpenseAccountActionTypes.DELETE_MANY_EXPENSE_ACCOUNTS,
        expenseAccounts: remainingExpenseAccounts,
    }

    dispatch(deleteManyExpenseAccountsAction)
}

export const updateExpenseAccounts = (updateExpenseAccounts: Array<ExpenseAccount>) => async (dispatch: Dispatch) => {
    const updatedExpenseAccounts = await ExpenseAccountService.updateExpenseAccounts(updateExpenseAccounts)
    const updateManyExpenseAccountsAction: UpdateManyExpenseAccountsAction = {
        type: ExpenseAccountActionTypes.UPDATE_MANY_EXPENSE_ACCOUNTS,
        expenseAccounts: updatedExpenseAccounts,
    }

    dispatch(updateManyExpenseAccountsAction)
}

export const createExpenseAccounts = (createExpenseAccounts: Array<ExpenseAccount>) => async (dispatch: Dispatch) => {
    const allExpenseAccounts = await ExpenseAccountService.createExpenseAccounts(createExpenseAccounts)
    const createdManyExpenseAccountsAction: CreateManyExpenseAccountsAction = {
        type: ExpenseAccountActionTypes.CREATE_MANY_EXPENSE_ACCOUNTS,
        expenseAccounts: allExpenseAccounts,
    }

    dispatch(createdManyExpenseAccountsAction)
}
