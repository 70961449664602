import { ImportResultTypeEnum } from "@finway-group/shared/lib/models"
import { Col, Modal, Row, Tabs } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"

import CoffeIcon from "Components/icons/coffee"
import { useImportInProgress, useImportResultId } from "Shared/hooks/import.hooks"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

import { ImportDataTab } from "./importDataTab"
import { ImportHistoryTab } from "./importHistoryTab"
import { ImportModalTab } from "./importResult.types"

interface SpreadsheetDataUploadModalProps {
    isShowing?: boolean
    onCancel: () => void
    importResultId?: string
}

export const SpreadsheetDataUploadModal = ({ isShowing, onCancel, importResultId: importResultIdFromProps }: SpreadsheetDataUploadModalProps) => {
    const { t } = useTranslation()

    const isImportInProgress = useImportInProgress()
    const importResultIdFromStore = useImportResultId()
    const importResultId = importResultIdFromProps || importResultIdFromStore

    const [importInProgressType, setImportInProgressType] = useStateIfMounted<ImportResultTypeEnum | undefined>(undefined)

    const handleImportInProgress = (importResultType: ImportResultTypeEnum) => {
        setImportInProgressType(importResultType)
    }

    const dataImportModalMainView = (
        <Tabs defaultActiveKey={ImportModalTab.DATA}>
            <Tabs.TabPane key={ImportModalTab.DATA} tab={t("input:data_upload.import_tab")} closable>
                <ImportDataTab onCancel={onCancel} importResultId={importResultId} onValidate={handleImportInProgress} onImportConfirm={handleImportInProgress} />
            </Tabs.TabPane>
            <Tabs.TabPane key={ImportModalTab.HISTORY} tab={t("input:data_upload.history_tab")} closable>
                <ImportHistoryTab onCancel={onCancel} />
            </Tabs.TabPane>
        </Tabs>
    )

    const importInProgressView = (
        <div className="animation-appear m-20">
            <Row gutter={[16, 32]} justify="center">
                <Col span={24}>
                    <p className="text-center text-lg animation-pulse">
                        {importInProgressType === ImportResultTypeEnum.VALIDATION ? t("info:validating_data") : t("info:import_data")}
                    </p>
                </Col>
                <Col span={24} className="flex justify-center">
                    <CoffeIcon alt="green coffee cup with steaming waves of heat above"></CoffeIcon>
                </Col>
                <Col span={24} className="flex justify-center">
                    <p className="text-center text-lg"> {t("info:you_can_close_this_window")}</p>
                </Col>
            </Row>
        </div>
    )

    return (
        <Modal
            visible={isShowing || false}
            maskClosable={false}
            bodyStyle={{ paddingBottom: isImportInProgress ? "60px" : "10px", paddingTop: "0px" }}
            title={t("action:bulk_data_upload")}
            onCancel={onCancel}
            className="ant-modal--large"
            footer={null}
            destroyOnClose
            closable
            keyboard
        >
            {isImportInProgress ? importInProgressView : dataImportModalMainView}
        </Modal>
    )
}

SpreadsheetDataUploadModal.displayName = "SpreadsheetDataUploadModal"
