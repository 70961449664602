import Icon from "@ant-design/icons"
import React from "react"

const gridSvg = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.13328 1.13353V6.2002H6.19995V1.13353H1.13328ZM9.80003 1.13353V6.2002H14.8667V1.13353H9.80003ZM1.13328 14.8672H6.19995V9.80052H1.13328V14.8672ZM9.80003 9.80052V14.8672H14.8667V9.80052H9.80003ZM6.66662 7.13353H0.666618C0.512067 7.13353 0.398817 7.08289 0.324706 7.00877C0.250595 6.93466 0.199951 6.82141 0.199951 6.66686V0.666862C0.199951 0.512312 0.250595 0.399061 0.324706 0.32495C0.398817 0.250839 0.512067 0.200195 0.666618 0.200195H6.66662C6.82117 0.200195 6.93442 0.250839 7.00853 0.32495C7.08264 0.399061 7.13329 0.512311 7.13329 0.666862V6.66686C7.13329 6.82141 7.08264 6.93466 7.00853 7.00877C6.93442 7.08289 6.82117 7.13353 6.66662 7.13353ZM15.3334 7.13353H9.33337C9.17882 7.13353 9.06557 7.08289 8.99145 7.00877C8.91734 6.93466 8.8667 6.82141 8.8667 6.66686V0.666862C8.8667 0.512311 8.91734 0.399061 8.99145 0.32495C9.06557 0.250839 9.17882 0.200195 9.33337 0.200195H15.3334C15.4879 0.200195 15.6012 0.250839 15.6753 0.32495C15.7494 0.399061 15.8 0.512311 15.8 0.666862V6.66686C15.8 6.82141 15.7494 6.93466 15.6753 7.00877C15.6012 7.08289 15.4879 7.13353 15.3334 7.13353ZM6.66662 15.8005H0.666618C0.512067 15.8005 0.398816 15.7499 0.324706 15.6758C0.250595 15.6017 0.199951 15.4884 0.199951 15.3339V9.33385C0.199951 9.1793 0.250595 9.06605 0.324706 8.99194C0.398817 8.91783 0.512067 8.86719 0.666618 8.86719H6.66662C6.82117 8.86719 6.93442 8.91783 7.00853 8.99194C7.08264 9.06605 7.13329 9.1793 7.13329 9.33385V15.3339C7.13329 15.4884 7.08264 15.6017 7.00853 15.6758C6.93442 15.7499 6.82117 15.8005 6.66662 15.8005ZM15.3334 15.8005H9.33337C9.17881 15.8005 9.06556 15.7499 8.99145 15.6758C8.91734 15.6017 8.8667 15.4884 8.8667 15.3339V9.33385C8.8667 9.1793 8.91734 9.06605 8.99145 8.99194C9.06557 8.91783 9.17882 8.86719 9.33337 8.86719H15.3334C15.4879 8.86719 15.6012 8.91783 15.6753 8.99194C15.7494 9.06605 15.8 9.1793 15.8 9.33385V15.3339C15.8 15.4884 15.7494 15.6017 15.6753 15.6758C15.6012 15.7499 15.4879 15.8005 15.3334 15.8005Z"
            fill="#606C7E"
        />
    </svg>
)

const GridIcon = (props: any) => <Icon component={gridSvg} {...props} />

export default GridIcon
