import { Role } from "@finway-group/shared/lib/models"
import { Input } from "antd"
import Form from "antd/lib/form"
import React from "react"
import { useTranslation } from "react-i18next"

import RoleSettingsRules from "../rules/role.rules"

interface RoleNameInterface {
    role?: Role
    readOnly?: boolean
}

const RoleNameForm = ({ role, readOnly }: RoleNameInterface) => {
    const { t } = useTranslation()
    const rules = RoleSettingsRules()

    return (
        <>
            {!readOnly && (
                <Form.Item label={t("input:settings.role_name")} name="name" rules={rules.name} initialValue={role && role.name}>
                    <Input placeholder={t("placeholder:role_name")} required />
                </Form.Item>
            )}
        </>
    )
}

export default React.memo(RoleNameForm)
