import { ExpenseKindEnum, Hospitality, Mileage, PerDiem, ReimbursementTypeEnum } from "@finway-group/shared/lib/models"
import moment from "moment"
import React, { useEffect } from "react"

import { useExpenseFormContext } from "Components/modals/expenseCreateForm.context"
import expenseInitializer from "Features/pages/expenses/expenseInitializer"

import { InvoiceRequestForm } from "./invoiceRequest.form"
import { HospitalityRequestForm } from "./reimbursementForms/hospitalityRequest.form"
import { MileageRequestForm } from "./reimbursementForms/mileage/mileageRequest.form"
import { PerDiemRequestForm } from "./reimbursementForms/perDiem/perDiem.form"

const ReimbursementRequestForm = () => {
    const [{ expense, expenseForm, isNew }] = useExpenseFormContext()

    // initialize formInstance for all reimbursement forms
    useEffect(() => {
        if (isNew) return
        const typeSpecificFields: Partial<PerDiem & Hospitality & Mileage> = expenseInitializer.preProcessReimbursementFieldsForForm(expense)

        expenseForm.setFieldsValue({
            datePurchased: expense.datePurchased,
            description: expense.description,
            billable: true, // expense.billable || true
            performancePeriod: [
                expense.performancePeriodStartDate ? moment(expense.performancePeriodStartDate) : undefined,
                expense.performancePeriodEndDate ? moment(expense.performancePeriodEndDate) : undefined,
            ],
            currency: expense.currency,
            invoiceNumber: expense.invoiceNumber,
            invoiceDate: expense.invoiceDate,
            invoices: expense.invoices,

            // Putting the type-specific fields to the form
            ...typeSpecificFields,
        })
    }, [])

    const getReimbursementType = () => {
        switch (expense.kind) {
            case ExpenseKindEnum.ONE_TIME_EXPENSE:
                return ReimbursementTypeEnum.STANDARD
            case ExpenseKindEnum.MILEAGE:
                return ReimbursementTypeEnum.MILEAGE
            case ExpenseKindEnum.HOSPITALITY:
                return ReimbursementTypeEnum.HOSPITALITY
            case ExpenseKindEnum.PER_DIEM:
                return ReimbursementTypeEnum.PER_DIEM
            default:
                return ReimbursementTypeEnum.STANDARD
        }
    }

    const renderReimbursementRequestFields = (key: ReimbursementTypeEnum) => {
        switch (key) {
            case ReimbursementTypeEnum.STANDARD:
                return <InvoiceRequestForm />

            case ReimbursementTypeEnum.MILEAGE:
                return <MileageRequestForm />

            case ReimbursementTypeEnum.HOSPITALITY:
                return <HospitalityRequestForm />

            case ReimbursementTypeEnum.PER_DIEM:
                return <PerDiemRequestForm />
            default:
                return null
        }
    }

    return renderReimbursementRequestFields(getReimbursementType())
}
export default ReimbursementRequestForm
