import ExclamationCircleOutlined from "@ant-design/icons/lib/icons/ExclamationCircleOutlined"
import { CostCenter2, RightEnum } from "@finway-group/shared/lib/models"
import Modal from "antd/es/modal"
import Table from "antd/es/table/Table"
import { ColumnsType } from "antd/lib/table"
import React, { useState } from "react"
import { Edit as EditIcon, Plus as PlusIcon } from "react-feather"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import TrashIcon from "Components/icons/trashIcon"
import CostCenters2Modal from "Components/modals/costCenters2.modal"
import SettingsLayout, { SettingsButton } from "Features/settings/settings.layout"
import { useModal } from "Shared/context/modal.context"
import { useCostCenters2 } from "Shared/hooks/costCenter2.hooks"
import { AuthzService } from "Shared/services"
import NotificationService, { NotificationTypeEnum } from "Shared/services/notification.service"
import { deleteCostCenters2 } from "Shared/store/actions/costCenter2/costCenter2Actions"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

const { confirm } = Modal

const CostCenter2Settings = () => {
    const { t } = useTranslation()
    const { showModal } = useModal()

    const costCenters2 = useCostCenters2()
    const [selectedRows, setSelectedRows] = useState<Array<CostCenter2>>([])
    const [isDeleting, setIsDeleting] = useStateIfMounted(false)

    const canWrite = AuthzService.isRightGrantedForLoggedInUser(RightEnum.COMPANY_ACCOUNTING_SETTINGS__ALL__WRITE)

    const dispatch = useDispatch()

    const columns: ColumnsType<CostCenter2> = [
        {
            title: t("label:cost_center_2_name"),
            dataIndex: "name",
            key: "name",
        },
        {
            title: t("label:cost_center_2_code"),
            dataIndex: "code",
            key: "code",
            width: 150,
            align: "right",
        },
    ]

    const handleDelete = async () => {
        try {
            const count = selectedRows.length

            setIsDeleting(true)
            await dispatch(deleteCostCenters2(selectedRows))

            NotificationService.send(
                NotificationTypeEnum.SUCCESS,
                t("notification:settings.cost_center_2.deleted.title"),
                t("notification:settings.cost_center_2.deleted.message", { count }),
            )
            setSelectedRows([])
        } catch (err) {
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:cost_center_2.update.title"))
        } finally {
            setIsDeleting(false)
        }
    }

    const handleDeleteRequest = () => {
        const count = selectedRows.length

        if (count > 1) {
            confirm({
                icon: <ExclamationCircleOutlined />,
                title: t("confirm:cost_center_2_settings.bulk_delete.title"),
                okText: t("confirm:cost_center_2_settings.bulk_delete.confirm"),
                cancelText: t("confirm:cost_center_2_settings.bulk_delete.cancel"),
                content: t("confirm:cost_center_2_settings.bulk_delete.message", { count }),
                onOk: () => handleDelete(),
            })
        } else {
            handleDelete()
        }
    }

    const sortedCostCenters2 = costCenters2.sort((a, b) =>
        a.code.localeCompare(b.code, undefined, {
            numeric: true,
            sensitivity: "base",
        }),
    )

    return (
        <SettingsLayout
            titleKey="label:cost_center_2"
            buttons={
                <>
                    {canWrite && (
                        <>
                            {selectedRows.length > 0 ? (
                                <>
                                    <SettingsButton icon={<TrashIcon />} onClick={handleDeleteRequest} labelKey="action:delete" />
                                    <SettingsButton
                                        icon={<EditIcon />}
                                        onClick={() => showModal(CostCenters2Modal, false, { isShowing: true, costCenters2: selectedRows })}
                                        labelKey="action:edit"
                                    />
                                </>
                            ) : (
                                <SettingsButton
                                    icon={<PlusIcon />}
                                    onClick={() => showModal(CostCenters2Modal, false, { isShowing: true, costCenters2: [{ name: undefined, code: undefined }], isEdit: false })}
                                    labelKey="action:create"
                                />
                            )}
                        </>
                    )}
                </>
            }
        >
            <div>
                {costCenters2.length === 0 ? (
                    <div className="text-text-dark p-10">{t("missing:no_cost_center_2")}</div>
                ) : (
                    <div className="w-full h-screen md:max-h-450 overflow-y-auto overflow-x-hidden">
                        <Table<CostCenter2>
                            columns={columns}
                            dataSource={sortedCostCenters2}
                            rowSelection={
                                canWrite
                                    ? {
                                          onChange: (_keys, rows) => {
                                              setSelectedRows(rows)
                                          },
                                          getCheckboxProps: (record) => ({
                                              name: record.name,
                                          }),
                                          renderCell: (_value, _record, _index, originNode) => (
                                              <div onClick={(e) => e.stopPropagation()} className="bigger-hitbox-checkbox">
                                                  {originNode}
                                              </div>
                                          ),
                                      }
                                    : undefined
                            }
                            rowKey="_id"
                            pagination={{
                                position: ["bottomRight"],
                                showSizeChanger: true,
                                hideOnSinglePage: false,
                                pageSizeOptions: ["5", "10", "20", "50", "100"],
                            }}
                        />
                    </div>
                )}
            </div>
        </SettingsLayout>
    )
}

export default CostCenter2Settings
