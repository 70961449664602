import { DocumentChangeInterface, OperationTypeEnum } from "@finway-group/shared/lib/models"
import * as Sentry from "@sentry/react"

import { fetchAllCsvTemplates } from "Shared/store/actions/csvTemplates/csvTemplateActions"
import { isClientErrorCode } from "Shared/utils/errorHandler.utils"

const subscribeToCsvTemplate = async (dispatch: any, data: DocumentChangeInterface) => {
    const {
        fullDocument: { operationType },
    } = data

    try {
        switch (operationType) {
            case OperationTypeEnum.INSERT:
            case OperationTypeEnum.UPDATE:
            case OperationTypeEnum.DELETE:
                dispatch(fetchAllCsvTemplates())
                break
        }
    } catch (err) {
        if (isClientErrorCode(err)) return
        Sentry.captureException(`[CSV Templates Socket] Error: ${typeof err === "string" ? err : JSON.stringify(err)}`)
    }
}

export default subscribeToCsvTemplate
