import { Mileage } from "@finway-group/shared/lib/models"
import { isNumeric } from "@finway-group/shared/lib/utils/common"
import i18n from "i18next"

import { NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { isEmptyString } from "Shared/utils/helper.utils"

import { allowlistCharValidator } from "./utils.rules"

export const MileageRules = (mileage: Mileage, isDistanceOverExpectedDistanceThreshold?: boolean, hasUserNoticedPrompt?: boolean) => ({
    additionalStopReason: [
        {
            required: true,
            validator: (_rule: any, value: string) => {
                if ((!value || isEmptyString(value)) && isDistanceOverExpectedDistanceThreshold) {
                    if (!hasUserNoticedPrompt) NotificationService.send(NotificationTypeEnum.WARNING, i18n.t("error:error"), i18n.t("validation:mileage.must_provide_reason"))
                    return Promise.reject(new Error(i18n.t("validation:mileage.must_provide_reason")))
                }
                return Promise.resolve()
            },
        },
        allowlistCharValidator,
    ],
    actualDistance: [
        {
            validator: (_rule: any, value: string) => {
                if (!isNumeric(value)) {
                    return Promise.reject(i18n.t("validation:numeric"))
                }
                return Promise.resolve()
            },
        },
    ],
    stops: [
        {
            required: true,
            validator: (_rule: any, value: string) => {
                if (!value) {
                    return Promise.reject(new Error(i18n.t("validation:required_mileage_stops")))
                }
                return Promise.resolve()
            },
        },
    ],
    mileageDate: [
        {
            required: true,
            validator: (_rule: any, value: string) => {
                if (!value) {
                    return Promise.reject(new Error(i18n.t("validation:required")))
                }
                return Promise.resolve()
            },
        },
    ],
    tripReason: [
        {
            required: true,
            validator: (_rule: any, value: string) => {
                if (!value) {
                    return Promise.reject(new Error(i18n.t("validation:required")))
                }
                return Promise.resolve()
            },
        },
        allowlistCharValidator,
    ],
})
