import { Rule } from "antd/lib/form"
import i18n from "i18next"
import moment from "moment-timezone"

export const BankMatchingRules = (dateFilter?: any): { [key: string]: Array<Rule> } => ({
    startDate: [
        { type: "date", required: true, message: i18n.t("validation:required"), validateTrigger: ["blur", "onChange"] },
        {
            validator: (_rule: any, value: moment.Moment) => {
                if (dateFilter?.endDate && value) {
                    if (value.toDate() > dateFilter?.endDate) {
                        return Promise.reject(
                            i18n.t("validation:date_before", {
                                attribute: i18n.t("input:expense_filter.starting_date"),
                                compare: i18n.t("input:expense_filter.ending_date"),
                            }),
                        )
                    }
                }

                return Promise.resolve()
            },
        },
    ],
    endDate: [
        { type: "date", required: true, message: i18n.t("validation:required"), validateTrigger: ["blur", "onChange"] },
        {
            validator: (_rule: any, value: moment.Moment) => {
                if (dateFilter?.startDate && value) {
                    if (value.toDate() < dateFilter?.startDate) {
                        return Promise.reject(
                            i18n.t("validation:date_after", {
                                attribute: i18n.t("input:expense_filter.ending_date"),
                                compare: i18n.t("input:expense_filter.starting_date"),
                            }),
                        )
                    }
                }

                return Promise.resolve()
            },
        },
    ],
})
