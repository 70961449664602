import { COLOR } from "@finway-group/shared/lib/consts"
import { PriceIntervalEnum } from "@finway-group/shared/lib/models"
import { Card } from "antd"
import moment from "moment"
import numeral from "numeral"
import React, { useMemo } from "react"
import { CartesianGrid, Legend, Line, LineChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts"

import Loading from "Components/loading"
import PriceLabel from "Components/priceLabel"
import { useAccountIds, useAccountIdsMap } from "Shared/hooks/bank.hooks"
import { useLoggedInEmployeeProfile } from "Shared/hooks/user.hooks"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

import CustomTooltip from "../../supplements/customTooltip"
import { BankBalanceInfo } from "../../types"
import NoBankAccountCard from "./noBankAccountCard"

// Todo - wibi: tailwind?
const chartColors = ["#3886FF", "#F42A68", "#53BBD8", "#FBAC3B", "#60CDB2"]

interface CashInBankAccountChartInterface {
    bankBalanceData: Array<BankBalanceInfo>
    isLoading: boolean
}

const CashInBankAccountChart: React.FC<CashInBankAccountChartInterface> = ({ bankBalanceData, isLoading }) => {
    const [activeLine, setActiveLine] = useStateIfMounted("")

    const accountIdToNameMap = useAccountIdsMap({ shouldFilterDeleted: true })
    const accountIds = useAccountIds({ shouldFilterDeleted: true })
    const currentEmployee = useLoggedInEmployeeProfile()
    const { globalCurrency } = currentEmployee.settings
    const epochBankBalanceData = useMemo(() => bankBalanceData?.map((b) => ({ ...b, date: new Date(b.date).getTime() })) ?? [], [bankBalanceData])

    const getTicks = () => {
        const ticks = epochBankBalanceData.map((b) => b.date)
        if (ticks.length === 0) return []

        return ticks
    }

    const ticks = getTicks()

    const domain = [
        Math.min(...ticks),
        moment(ticks?.[ticks?.length - 1])
            .endOf("month")
            .valueOf(),
    ]

    if (isLoading) {
        return (
            <Card className="h-400">
                <Loading margin={false} />
            </Card>
        )
    }

    if (!accountIds || accountIds.length === 0) {
        return <NoBankAccountCard />
    }

    return (
        <Card>
            <div className="min-w-400 min-h-450 max-h-450">
                <ResponsiveContainer width={"100%"} height={480}>
                    <LineChart data={epochBankBalanceData} margin={{ right: 35 }}>
                        <CartesianGrid strokeDasharray="3" vertical={false} />
                        <ReferenceLine x={epochBankBalanceData[epochBankBalanceData.length - 1]?.date} />
                        <XAxis
                            style={{ fontSize: "0.7rem" }}
                            dataKey="date"
                            type="number"
                            interval={0}
                            ticks={ticks}
                            // if more than 6 ticks then change the angle
                            {...(ticks.length > 6 && {
                                angle: 35,
                                dx: 20,
                                dy: 20,
                                height: 40,
                                padding: { right: 10 },
                            })}
                            domain={domain}
                            tickFormatter={(tick: number, i) => {
                                // When the current tick is 1 before the last tick, there is chance where this tick will be located in between the 2 months
                                if (i === ticks.length - 2 && ticks.length > 3) {
                                    // Check to see if the "special tick in the middle of a month" is too close to any of the other montlhy ticks.
                                    // If it's within 6 days of the other ticks, then do not render the tick string.
                                    const tickBefore = moment(ticks[i - 1])
                                    const tickAfter = moment(ticks[i + 1])
                                    if (tickBefore.diff(tick, "days") >= -6 || tickAfter.diff(tick, "days") <= 6) {
                                        return ""
                                    }
                                }
                                return moment(tick).format("DD MMM YY")
                            }}
                        />
                        <YAxis style={{ fontSize: "0.8rem" }} width={35} tickFormatter={(val) => numeral(val).format("0,0[.]0a")} />
                        {accountIds.map((accountId, index) => (
                            <Line
                                key={accountId}
                                name={`${accountIdToNameMap.get(accountId)?.bankName} - ${accountIdToNameMap.get(accountId)?.accountName} `}
                                type="linear"
                                dataKey={accountId}
                                stroke={chartColors[(index % chartColors.length) - 1]}
                                dot={false}
                                activeDot={{
                                    onMouseOver: (_) => setActiveLine(accountId),
                                    onMouseLeave: (_) => setActiveLine(""),
                                }}
                            />
                        ))}
                        <Tooltip
                            formatter={(value: number) => <PriceLabel value={value} currency={globalCurrency} interval={PriceIntervalEnum.ONE_TIME} />}
                            itemStyle={{ color: COLOR.primary }}
                            allowEscapeViewBox={{ y: true }}
                            content={(tooltipProps) => {
                                const props = { ...tooltipProps }
                                props.label = moment(props.label).format("DD MMM YY")
                                return <CustomTooltip props={props} activeLine={activeLine} />
                            }}
                        />

                        <Legend margin={{ top: 20 }} wrapperStyle={{ height: "100px", overflow: "auto", paddingLeft: "20px", bottom: "-20px" }} iconType="square" align="left" />
                    </LineChart>
                </ResponsiveContainer>
            </div>
        </Card>
    )
}

export default CashInBankAccountChart
