import { Card, Col, Row, Skeleton } from "antd"
import React, { useMemo } from "react"
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts"

import { ColorGenerator } from "Shared/utils/colorGenerator.utils"

// Todo - wibi: tailwind?
const dummyDataCount = 5
const colors = ColorGenerator.generateColorGradient("DEE4EA", "F2F2F2", dummyDataCount)

interface PieDataPoint {
    key: string
    value: number
    name: string
}

const CategoryCashFlowPieChartSkeleton: React.FC = () => {
    const data = useMemo(() => {
        const arr: Array<PieDataPoint> = []
        for (let i = 0; i < dummyDataCount; i++) {
            arr.push({ key: `pie-skeleton-${i}`, value: Math.random() * 100, name: "" })
        }
        return arr
    }, [])

    return (
        <Card>
            <div className="h-400">
                <Row>
                    <Col span={3}>
                        <div className="h-full mt-120">
                            <Skeleton paragraph={{ rows: 5, width: 100 }} title={false} />
                        </div>
                    </Col>
                    <Col span={21}>
                        <ResponsiveContainer width={"100%"} height={400}>
                            <PieChart>
                                <Pie data={data} dataKey="value" nameKey="name" innerRadius={50} fill="#8884d8" labelLine={false} isAnimationActive={false}>
                                    {data.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                                    ))}
                                </Pie>
                            </PieChart>
                        </ResponsiveContainer>
                    </Col>
                </Row>
            </div>
        </Card>
    )
}

export default CategoryCashFlowPieChartSkeleton
