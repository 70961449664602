import { ErrorCodeEnum, HttpHeaders } from "@finway-group/shared/lib/models"
import * as Sentry from "@sentry/react"
import axios, { AxiosHeaders, AxiosRequestConfig, CancelTokenSource } from "axios"
import * as HttpStatus from "http-status"

import i18n from "Shared/locales/i18n"
import { getIpAddress, refetchIpAddress } from "Shared/queries/ip.queries"
import { AuthnService, LocalStorageService, NotificationService, UserService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import SessionStorageService from "Shared/services/sessionstorage.service"
import store from "Shared/store"
import { setIsWeavrAuthenticated, setShouldShowWeavrAuthModal } from "Shared/store/actions/auth/authActions"
import { removeDuplicatesInFilter } from "Shared/utils/navbarActivityTemplates.utils"

import { AXIOS_CANCELLED_MESSAGE, AXIOS_EXPIRED_MESSAGE, AXIOS_LOGOUT_MESSSAGE, WEBAPP_VERSION } from "./consts"

class AxiosInterceptor {
    private failedQueue: any = []
    private weavrFailedQueue: any = []
    private cancelSource: CancelTokenSource
    private isRefreshing = false
    private isFatalErrorMessageDisplayed = false

    constructor() {
        this.cancelSource = axios.CancelToken.source()
        axios.defaults.baseURL = process.env.REACT_APP_API_URL
        axios.defaults.withCredentials = true
        // Make sure axios is not blocking forever by setting a timeout of 60 sec (value is specified in ms hence * 1000)
        // default timeout is 0
        if (process.env.DEPLOY_ENVIRONMENT !== "localhost") axios.defaults.timeout = 1000 * 60
    }

    resetIsFatalErrorMessageDisplayed = () => {
        this.isFatalErrorMessageDisplayed = false
    }

    setupInterceptors = () => {
        this.setupRequestInterceptor()
        this.setupResponseInterceptor()
    }

    // this should be called right after the login
    setNewCancelToken = () => {
        this.cancelSource = axios.CancelToken.source()
    }

    getBaseHeaders = () => ({
        "Content-Type": "application/json",
        "x-language": i18n.language,
        "x-timezone": Intl.DateTimeFormat().resolvedOptions().timeZone,
        [HttpHeaders.CLIENT_SYSTEM_TIME]: Date.now(),
        [HttpHeaders.CLIENT_VERSION]: WEBAPP_VERSION,
        [HttpHeaders.CLIENT_IP_ADDRESS]: getIpAddress(),
    })

    private setupRequestInterceptor = () => {
        axios.interceptors.request.use(
            async (currentConfig) => {
                const config = { ...currentConfig, headers: new AxiosHeaders() }

                if (LocalStorageService.isAuthorized()) {
                    const headers = { ...this.getBaseHeaders(), ...currentConfig?.headers, ...LocalStorageService.getAuthenticationHeader() }
                    config.headers.set(headers)
                } else {
                    config.headers.set(this.getBaseHeaders())
                }

                this.setNewCancelToken()
                config.cancelToken = this.cancelSource.token
                config.url = removeDuplicatesInFilter(String(config.url))

                return config
            },
            (error) => Promise.reject(error),
        )
    }

    private processQueue = (error: any, isWeavrQueue = false) => {
        const queue = isWeavrQueue ? this.weavrFailedQueue : this.failedQueue
        let isResolved = true

        queue.reverse().forEach((prom: any) => {
            if (error) {
                isResolved = false
                prom.reject(error)
            } else {
                prom.resolve()
            }
        })

        if (queue.length > 0 && !isResolved) {
            this.cancelSource.cancel(AXIOS_CANCELLED_MESSAGE)
        }

        if (isWeavrQueue) this.weavrFailedQueue = []
        else this.failedQueue = []
    }

    private _handleLogout = async (error: Error) => {
        this.processQueue(error)
        LocalStorageService.unsetAccessToken()
        this.cancelSource.cancel(AXIOS_EXPIRED_MESSAGE)
    }

    private overwriteAuthHeader = (originalRequest: AxiosRequestConfig) => {
        originalRequest.headers = { ...originalRequest.headers, ...LocalStorageService.getAuthenticationHeader() }
    }

    private handleSilentRefresh = async (originalRequest: AxiosRequestConfig & { _retry: boolean }) => {
        const self = this
        if (this.isRefreshing) {
            return new Promise((resolve, reject) => {
                self.failedQueue.push({ resolve, reject })
            })
                .then(() => {
                    // Overwrite auth header received from the silent refresh
                    this.overwriteAuthHeader(originalRequest)
                    return axios(originalRequest)
                })
                .catch((err) => Promise.reject(err))
        }

        this.isRefreshing = true
        return new Promise((resolve) => {
            AuthnService.silentRefreshWithoutLogout()
                .then(() => {
                    axios.defaults.cancelToken = self.cancelSource.token
                    originalRequest._retry = true
                    // Overwrite auth header received from the silent refresh
                    this.overwriteAuthHeader(originalRequest)

                    resolve(axios(originalRequest))
                })
                .catch((error) => {
                    self.isRefreshing = false
                    self._handleLogout(error)
                })
                .finally(() => {
                    self.isRefreshing = false
                    self.processQueue(null)
                })
        })
    }

    replayOriginalWeavrRequest = async () => {
        const self = this

        return new Promise((_resolve, reject) => {
            try {
                axios.defaults.cancelToken = self.cancelSource.token

                self.processQueue(null, true)
            } catch (error) {
                reject(error)
            }
        })
    }

    private handleWeavrAuthError = (error: any, originalRequest: AxiosRequestConfig) => {
        store.dispatch(setIsWeavrAuthenticated(false))

        const isCardsProgramEnabled = store.getState().company.item.cardsEnabled

        if (!isCardsProgramEnabled) {
            error.response.data.error = i18n.t("error:corporate.cannot_be_performed_due_to_cards_enrollment_in_other_company")
            return Promise.reject(error)
        }

        const isOnboardingNotFinished =
            (error.response?.data?.errorCode === ErrorCodeEnum.WEAVR_AUTH_REQUIRED &&
                !UserService.getLoggedInEmployeeProfile()?.activeCompanyProfile?.weavrData?.isPasswordCreated) ||
            (error.response?.data?.errorCode === ErrorCodeEnum.WEAVR_STEP_UP_AUTH_REQUIRED &&
                !UserService.getLoggedInEmployeeProfile()?.activeCompanyProfile?.weavrData?.isMobileEnrolled)

        if (isOnboardingNotFinished) {
            error.response.status = HttpStatus.FORBIDDEN
            error.response.data.error = i18n.t("error:cards.card_program_onboarding_not_finished.message")
            return Promise.reject(error)
        }
        if (error.config.url === "/corporate/user/verify-otp") {
            error.response.data.error = i18n.t("error:corporate.authentication_expired")
            return Promise.reject(error)
        }

        // we had a weavr auth error, so show the weavr auth modal
        store.dispatch(setShouldShowWeavrAuthModal(true))

        return this.queueFailedWeavrRequest(originalRequest)
    }

    queueFailedWeavrRequest = (originalRequest: AxiosRequestConfig) => {
        const self = this
        return new Promise((resolve, reject) => {
            self.weavrFailedQueue.push({ resolve, reject })
        })
            .then(() => {
                originalRequest.headers = { ...self.getBaseHeaders(), ...LocalStorageService.getAuthenticationHeader() }
                return axios(originalRequest)
            })
            .catch((err) => Promise.reject(err))
    }

    public cancelWeavrRequests = () => {
        this.processQueue("cancelled", true)
    }

    private setupResponseInterceptor() {
        axios.interceptors.response.use(
            async (response) => {
                if (response && response.config.url && response.config.url?.indexOf("/logout") > -1) {
                    this.cancelSource.cancel(AXIOS_LOGOUT_MESSSAGE)
                }
                return response
            },
            async (error) => {
                const originalRequest = error?.config

                if (error?.code === "ERR_NETWORK") {
                    // Track network errors on sentry
                    // Goal: understand WHEN this happens
                    // see: https://levaroio.atlassian.net/browse/CUS-315
                    Sentry.captureException(error, { tags: { errorType: "ERR_NETWORK_AXIOS_INTERCEPTOR" } })
                    NotificationService.send(NotificationTypeEnum.WARNING, i18n.t("error:error"), i18n.t("error:unavailable"))
                }

                if (!error?.config || !error.config.url || error.config.url === "/login") {
                    return Promise.reject(error)
                }

                const errorCode = error?.response?.data?.errorCode

                if ([ErrorCodeEnum.MAINTENANCE, ErrorCodeEnum.INVALID_SYSTEM_TIME].includes(errorCode)) {
                    if (!this.isFatalErrorMessageDisplayed) {
                        NotificationService.showErrorNotificationBasedOnResponseError(error, i18n.t("error:error"))
                        this.isFatalErrorMessageDisplayed = true
                    }
                    await this._handleLogout(error)
                    return Promise.reject(error)
                }

                if ([ErrorCodeEnum.REQUESTING_SESSION_NOT_AUTHENTICATED, ErrorCodeEnum.REQUESTING_SESSION_NOT_FOUND].includes(errorCode)) {
                    if (errorCode === ErrorCodeEnum.REQUESTING_SESSION_NOT_AUTHENTICATED) {
                        SessionStorageService.setSessionInvalid()
                    }
                    await this._handleLogout(error)
                    // TODO - This is unstable because the best practice is for the caller/component to handle the error.
                    // A lot of axios caller do not handle the errors properly that might lead to unhandled exceptions getting thrown to react
                    // https://github.com/axios/axios/issues/855
                    return Promise.reject(error)
                }

                if (error.config.url === "/token/refresh" || (error.response && error.response.message === i18n.t("error:auth.account_suspended"))) {
                    if (error.config.url === "/token/refresh") {
                        NotificationService.send(NotificationTypeEnum.WARNING, i18n.t("notification:session_expired.title"), i18n.t("notification:session_expired.message"), 0)
                    }
                    await this._handleLogout(error)
                    return Promise.reject(error)
                }
                if (errorCode === ErrorCodeEnum.MISSING_IP_ADDRESS) {
                    return this.handleMissingIpAddressError(error, originalRequest)
                }

                if (error && error.response) {
                    const { status } = error.response
                    if (status === HttpStatus.UNAUTHORIZED && !originalRequest._retry) {
                        const silentRefresh = this.handleSilentRefresh(originalRequest)
                        originalRequest._retry = false
                        return silentRefresh
                    }

                    if (this.isWeavrAuthError(error, originalRequest)) {
                        return this.handleWeavrAuthError(error, originalRequest)
                    }
                }

                return Promise.reject(error)
            },
        )
    }

    private isWeavrAuthError(error: any, originalRequest: any) {
        return (
            error.response.status === HttpStatus.UNPROCESSABLE_ENTITY &&
            [ErrorCodeEnum.WEAVR_AUTH_REQUIRED, ErrorCodeEnum.WEAVR_STEP_UP_AUTH_REQUIRED].includes(error.response.data.errorCode) &&
            !originalRequest._retry
        )
    }

    private handleMissingIpAddressError = async (error: any, originalRequest: AxiosRequestConfig & { _retry: boolean }) => {
        if (!originalRequest._retry) {
            try {
                const ipAddress = await refetchIpAddress()
                originalRequest.headers = { ...originalRequest.headers, [HttpHeaders.CLIENT_IP_ADDRESS]: ipAddress.data }
                originalRequest._retry = true
                return await axios(originalRequest)
            } catch (_err) {
                NotificationService.send(NotificationTypeEnum.ERROR, i18n.t("error:error"), i18n.t("error:internal_server"))
                return Promise.reject(error)
            }
        }
    }
}

export default new AxiosInterceptor()
