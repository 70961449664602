import { ExclamationCircleFilled } from "@ant-design/icons"
import { Alert, Button } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import { RoutePathEnum } from "Shared/router/RoutePath.enum"

const NoBankAccountBanner: React.FC = () => {
    const { t } = useTranslation()
    const history = useHistory()

    return (
        <Alert
            message={
                <>
                    <div className="flex justify-between pr-24">
                        <div className="flex items-center">
                            {/** Antd's built-in icon for alert sucks, so it has to be coded in here for it to be aligned properly */}
                            <ExclamationCircleFilled className="mr-12" style={{ color: "#3866FF" }} />
                            {t("info:liquidity_management.no_account_connected_banner")}
                        </div>
                        <div>
                            <Button
                                onClick={() => {
                                    history.push(RoutePathEnum.SETTINGS_BANK_ACCOUNTS, RoutePathEnum.LM)
                                }}
                                type="default"
                                className="ghost w-250 h-40"
                            >
                                {t("action:liquidity.add_bank_account")}
                            </Button>
                        </div>
                    </div>
                </>
            }
            description={""}
            type="info"
            closable
        />
    )
}
export default React.memo(NoBankAccountBanner)
