import Icon from "@ant-design/icons"
import React from "react"

const arrowSVG = () => (
    <svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.5 8H23.5" stroke="#A1B1C1" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M16 0.5L23.5 8L16 15.5" stroke="#A1B1C1" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
)

const ArrowThinRightIcon = (props: any) => <Icon component={arrowSVG} {...props} />

export default ArrowThinRightIcon
