import { InboxInvoice } from "@finway-group/shared/lib/models"
import axios from "axios"
import { errors } from "playwright/test"

import store from "Shared/store"

const MODEL = "inboxinvoices"

const InboxInvoiceService = {
    updateOne: async (id: string, updatedInboxInvoice: InboxInvoice) => {
        const { data } = await axios.put(`/${MODEL}/${id}`, updatedInboxInvoice)
        return new InboxInvoice(data)
    },
    getInboxInvoices: () => store.getState().inboxInvoice.items,
    getInboxInvoiceIds: () => InboxInvoiceService.getInboxInvoices().map((ii: InboxInvoice) => ii.id),
    fetchInboxInvoicesCount: async (query = "") => {
        const {
            data: { count },
        } = await axios.get(`/${MODEL}/reports/counts?${query}`)
        return count
    },
    getInboxInvoiceById: (id: string) => {
        const inboxInvoices = store.getState().inboxInvoices.items
        return inboxInvoices.find((ii: InboxInvoice) => ii.id === id)
    },
    fetchAllInboxInvoices: async (sortingCriteria: string, page: number = 1, limit: number = 1000) => {
        const {
            data: { docs, totalPages, totalDocs },
        } = await axios.get(`/${MODEL}?page=${page}&limit=${limit}${sortingCriteria}`)

        return {
            inboxInvoices: docs.map((item: any) => new InboxInvoice(item)),
            totalDocs,
            totalPages,
        }
    },
    fetchInboxInvoices: async (queryParams: any, source: any, timeout?: number) => {
        const { data } = await axios.get(
            `/${MODEL}?page=${queryParams.page}&limit=${queryParams.limit}${queryParams.filterQueryString}${queryParams.searchQueryString}${queryParams.sortQueryString}`,
            {
                cancelToken: source.token,
                timeout,
            },
        )
        return data
    },
    fetchOneInboxInvoice: async (id: string) => {
        const { data } = await axios.get(`/${MODEL}/${id}`)
        return new InboxInvoice(data)
    },
    retryInboxInvoiceOcr: async (id: string) => {
        await axios.get(`/${MODEL}/retryOcr/${id}`)
    },
    createInboxInvoice: async (createInboxInvoice: InboxInvoice) => {
        const { data } = await axios.post(`/${MODEL}`, createInboxInvoice)
        return new InboxInvoice(data)
    },
    discardInboxInvoice: async (id: string) => axios.delete(`/${MODEL}/${id}`),
    deleteManyInboxInvoices: async (ids: Array<string>) => axios.post(`/${MODEL}/delete-inbox-invoices`, { ids }),
    fetchMultipleInvoicesByIds: async (ids: Array<string>) => {
        const invoicePromises = ids.map((invoiceId) => InboxInvoiceService.fetchOneInboxInvoice(invoiceId))
        const invoices = await Promise.all(invoicePromises)

        return invoices
    },
}

export default InboxInvoiceService
