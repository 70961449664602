import { LoadingOutlined } from "@ant-design/icons"
import { Alert } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"

import { useCompany } from "Shared/hooks/company.hooks"

interface TransactionCategoryListBanner {}

const TransactionCategoryListBanner: React.FC<TransactionCategoryListBanner> = ({}) => {
    const { t } = useTranslation()
    const company = useCompany()

    if (company?.isCreatingDefaultTransactionCategories)
        return (
            <Alert
                showIcon
                icon={<LoadingOutlined />}
                className="mb-20"
                message={t("info:transaction_categories.default_creation.title")}
                description={t("info:transaction_categories.default_creation.message")}
                type="info"
            />
        )

    return <></>
}

export default TransactionCategoryListBanner
