import { COLOR } from "@finway-group/shared/lib/consts"
import { ResponsivePie } from "@nivo/pie"
import { Col, Row } from "antd"
import numeral from "numeral"
import React, { useMemo } from "react"

import { pieProps } from "Shared/config/consts"
import { UserService } from "Shared/services"
import { ColorGenerator } from "Shared/utils/colorGenerator.utils"
import { formatCurrencyNumber } from "Shared/utils/helper.utils"

interface DataItem {
    id: string
    value: number
    amount: number
}

interface ReportPieInterface {
    dataForPieDiagram: Array<DataItem>
    getLabelName(id: any): string
    truncateEmptyValue?: boolean
}

const PieDiagram: React.FC<ReportPieInterface> = ({ dataForPieDiagram, getLabelName, truncateEmptyValue = true }) => {
    const { globalCurrency } = UserService.getLoggedInEmployeeProfile().settings

    const convertFormatPie = (ccData: Array<DataItem>) =>
        ccData.filter((item) => !truncateEmptyValue || item.value > 0).map((item) => ({ id: item.id, label: getLabelName(item.id), value: item.value }))

    const pieData = useMemo(() => convertFormatPie(dataForPieDiagram), [dataForPieDiagram, getLabelName])

    const colors = useMemo(() => ColorGenerator.generateColorGradient(COLOR.primary, COLOR.secondary, pieData.length), pieData)

    const renderLegend = () => (
        <Row className={"ml-10 mt-10 h-250 overflow-y-auto overflow-x-hidden"} gutter={[0, 16]}>
            {pieData.map((data, index) => (
                <Col key={index} span={24} className="ml-10 my-auto">
                    <div className="mr-10 float-left" key={data.id}>
                        <span className="h-10 w-10 flex inline-flex" style={{ backgroundColor: `${colors[index]}` }}></span>{" "}
                        <label style={{ textOverflow: "ellipsis" }}>{data.label} </label>
                    </div>
                </Col>
            ))}
        </Row>
    )

    return (
        <Row gutter={[16, 16]} className="h-300">
            <Col span={9} className="my-auto">
                {renderLegend()}
            </Col>
            <Col span={15}>
                <Row className="h-300 w-full">
                    <ResponsivePie
                        {...pieProps}
                        data={pieData}
                        colors={colors}
                        arcLinkLabel={"label"}
                        tooltip={(item) => {
                            const slice = item.datum
                            return (
                                <div className="p-5 bg-white shadow">
                                    <label className="font-bold" style={{ color: `${slice.color}` }}>
                                        {slice.label} : {formatCurrencyNumber(slice.value, globalCurrency)}
                                    </label>
                                </div>
                            )
                        }}
                        legends={[]}
                        valueFormat={(value) => numeral(value).format(value < 1000 ? "0,0a" : "0,0[.]00a")}
                    />
                </Row>
            </Col>
        </Row>
    )
}

export default PieDiagram
