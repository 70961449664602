import { Team } from "@finway-group/shared/lib/models"
import axios from "axios"

import store from "Shared/store"

const MODEL = "teams"

const TeamService = {
    fetchTeams: async ({ page = 1, limit = 100, companyId = "", search = "", controller = new AbortController(), timeout = 20000 }) => {
        const {
            data: { docs },
        } = await axios.get(`/${MODEL}?page=${page}&limit=${limit}${companyId ? `&companyId[eq]=${companyId}` : ""}${search ? `&teamName[contains]=${search}` : ""}`, {
            signal: controller.signal,
            timeout,
        })
        return docs as Array<Team>
    },
    updateAllTeams: async (updateData: Array<Team>) => {
        const { data } = await axios.put(`${MODEL}`, { teams: updateData })
        return data as Array<Team>
    },
    getTeamById: (id: string) => {
        const teams = store.getState().teams.items
        return teams.find((team: Team) => team._id === id)
    },
}

export default TeamService
