import { InboxOutlined } from "@ant-design/icons"
import { Currencies } from "@finway-group/shared/lib/consts"
import { currencyEnumArray } from "@finway-group/shared/lib/models"
import * as Sentry from "@sentry/react"
import { Button, Checkbox, Col, DatePicker, Form, Image, Input, Modal, Radio, Row, Select, Tabs, Upload } from "antd"
import { Store } from "antd/lib/form/interface"
import moment from "moment"
import React, { Suspense, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"

import CurrencyInput from "Components/currencyInput"
import { parseCurrencyInput } from "Components/currencyInput/config"
import FormItemCurrency from "Components/form/formItemCurrency"
import { CreditCardRules } from "Components/forms/rules"
import { FINWAY_ADMIN_EMAIL } from "Shared/config/consts"
import { useKYBData, useWallet } from "Shared/hooks/corporate.hooks"
import { usePagedCards } from "Shared/hooks/creditCard.hooks"
import { useLoggedInEmployeeProfile } from "Shared/hooks/user.hooks"
import { EmployeeService, NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import SimulatorService, {
    CardAuthorizationEnum,
    WeavrAuthorizationDeclineReasonEnum,
    WeavrSettlementTypesEnum,
    WeavrWireTransferStateEnum,
} from "Shared/services/simulator.service"
import { setShouldRefetch } from "Shared/store/actions/creditCard/creditCardActions"
import { fetchPaymentProducts } from "Shared/store/actions/payment/paymentActions"
import { refetchCardRelatedTables, resetAllTables } from "Shared/store/actions/tables/tableActions"
import { KYBRejectionReasonEnum } from "Shared/store/reducers/corporateReducer"
import { RootState } from "Shared/store/rootState.interface"
import { doFilterSelect } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

import SimulatorCronTab from "./simualtorConTab"
import SimulatorDataGeneratorTab from "./simulatorDataGeneratorTab"
import { createBodyData as createResetPaymentSettingsRequest } from "./simulatorPaymentSettingsTab"
import SimulatorTenantTab, { createChangeTenantTierRequest } from "./simulatorTenantTab"

interface SimulatorModalInterface {
    isShowing: boolean
    onCancel: () => void
}

enum SimulationAction {
    CARD_AUTHORIZATION_TRANSACTION,
    CARD_SETTLEMENT_TRANSACTION,
    FUNDING_TRANSACTION,
    OUTGOING_WIRE_TRANSFER,
    KYB_ACCEPTANCE,
    KYB_REJECTION,
    GET_MY_INVOICES_UPLOAD,
    PAYMENT_SETTINGS_RESET,
    TENANT_TIER_CHANGE,
    CREATE_RANDOM_DATA,
    CREATE_QA_DATA,
    CARD_PIN_BLOCK,
}

enum SimulatorTabsEnum {
    INTEGRATIONS = "integrations",
    CARDS = "cards",
    CRON_JOB = "cronjob",
    PAYMENT_SETTINGS = "paymentSettings",
    TENANT = "tenant",
    VERSION = "version",
    DATA_GENERATOR = "dataGenerator",
}

const SimulatorPaymentSettingsTab = React.lazy(() => import("./simulatorPaymentSettingsTab"))

const SimulatorModal: React.FC<SimulatorModalInterface> = ({ isShowing, onCancel }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [formInstance] = Form.useForm()
    const [isLoading, setIsLoading] = useStateIfMounted<boolean>(false)
    const [simulationAction, setSimulationAction] = useState(SimulationAction.CARD_SETTLEMENT_TRANSACTION)
    const [shouldSimulateOnWeavr, setShouldSimulateOnWeavr] = useState(false)
    const [activeTab, setActiveTab] = useStateIfMounted(SimulatorTabsEnum.CARDS)
    const [uploadFile, setUploadFile] = useStateIfMounted<any>(undefined)
    const { cards } = usePagedCards(1, 10000)
    const rules = CreditCardRules()
    const wallet = useWallet()
    const corporateId = useKYBData()?.corporateId
    const version = useSelector(({ fetcher }: RootState) => fetcher.apiVersion)
    const loggedInUser = useLoggedInEmployeeProfile()

    useEffect(() => {
        dispatch(fetchPaymentProducts())
    }, [dispatch])

    const onSubmit = async (store: Store) => {
        const {
            amount,
            card,
            merchantName,
            transactionDate,
            reason,
            authorizationType,
            transactionId,
            relatedAuthorizationId,
            settlementType,
            declineReason,
            transferState,
            getMyInvoicesEmail,
            getMyInvoicesUserId,
            sourceAmount,
            sourceCurrency,
            numberOfUsers,
            numberOfVendors,
            numberOfCostCenters,
            baseEmail,
        } = store

        let success
        const cardToUse = cards.find((aCard) => aCard._id === card)

        setIsLoading(true)

        const timestampUTC = (transactionDate || moment()).valueOf()

        try {
            switch (simulationAction) {
                case SimulationAction.CARD_AUTHORIZATION_TRANSACTION:
                    success = await SimulatorService.createCardAuthorizationTransaction(
                        corporateId,
                        cardToUse,
                        amount,
                        wallet?.currency,
                        sourceAmount,
                        sourceCurrency,
                        timestampUTC,
                        merchantName,
                        transactionId,
                        relatedAuthorizationId,
                        authorizationType,
                        declineReason,
                        shouldSimulateOnWeavr,
                    )
                    break
                case SimulationAction.CARD_SETTLEMENT_TRANSACTION:
                    success = await SimulatorService.createCardSettlementTransaction(
                        corporateId,
                        cardToUse,
                        amount,
                        wallet?.currency,
                        sourceAmount,
                        sourceCurrency,
                        timestampUTC,
                        merchantName,
                        transactionId,
                        relatedAuthorizationId,
                        settlementType,
                        shouldSimulateOnWeavr,
                    )
                    break
                case SimulationAction.CARD_PIN_BLOCK:
                    success = await SimulatorService.blockCardPIN(card)
                    break
                case SimulationAction.FUNDING_TRANSACTION:
                    const parsedAmount = parseCurrencyInput(amount)
                    success = await SimulatorService.createFundingTransaction(corporateId, parsedAmount, wallet?.currency, wallet?.accountId, shouldSimulateOnWeavr)
                    break
                case SimulationAction.OUTGOING_WIRE_TRANSFER:
                    success = await SimulatorService.notifyOutgoingWireTransfer(corporateId, transferState, transactionId)
                    break
                case SimulationAction.KYB_ACCEPTANCE:
                    success = await SimulatorService.acceptKYB(corporateId, shouldSimulateOnWeavr)
                    break
                case SimulationAction.KYB_REJECTION:
                    success = await SimulatorService.rejectKYB(corporateId, reason, shouldSimulateOnWeavr)
                    break
                case SimulationAction.GET_MY_INVOICES_UPLOAD:
                    await SimulatorService.uploadByGetMyInvoices(getMyInvoicesEmail, getMyInvoicesUserId, uploadFile)
                    success = true
                    break
                case SimulationAction.PAYMENT_SETTINGS_RESET:
                    success = await SimulatorService.resetPaymentSettings(createResetPaymentSettingsRequest(store))
                    break
                case SimulationAction.TENANT_TIER_CHANGE:
                    await SimulatorService.changeTenantTier(createChangeTenantTierRequest(store))
                    success = true
                    if (success) {
                        NotificationService.showLogoutWarning()
                    }
                    break
                case SimulationAction.CREATE_RANDOM_DATA:
                    await SimulatorService.createRandomData({ numberOfUsers, numberOfVendors, numberOfCostCenters })
                    success = true
                    dispatch(resetAllTables())
                    break
                case SimulationAction.CREATE_QA_DATA:
                    await SimulatorService.createQAData({ baseEmail })
                    success = true
                    dispatch(resetAllTables())
                    break
                default:
                    break
            }

            if (success) {
                NotificationService.send(NotificationTypeEnum.SUCCESS, "Success", "Successfully simulated action")
                onHide()
            } else {
                NotificationService.send(NotificationTypeEnum.ERROR, "Error", "Failed to simulate action")
                setIsLoading(false)
            }
        } catch (err) {
            NotificationService.showErrorNotificationBasedOnResponseError(err, "Error")
            setIsLoading(false)
        }
    }

    const onHide = () => {
        setIsLoading(false)
        setShouldSimulateOnWeavr(false)
        dispatch(setShouldRefetch(true))
        dispatch(refetchCardRelatedTables())
        formInstance.resetFields()
        onCancel()
    }

    const renderWeavrSimulationToggle = () => (
        <>
            <Row>
                <Checkbox checked={shouldSimulateOnWeavr} onChange={(e) => setShouldSimulateOnWeavr(e.target.checked)}>
                    Simulate on Weavr
                </Checkbox>
            </Row>
            <Row className="mt-4">
                <span className="finway-red-text text-bold">
                    <strong>Info: This triggers the action on weavr and the API will wait for the webhook (up to 5m).</strong>
                </span>
            </Row>
        </>
    )

    const filterSelect = (input: string, option: any) => {
        const value = option?.children?.props?.children || ""
        return value.toLowerCase().indexOf(input.toLowerCase()) > -1
    }

    const renderFieldsForAction = () => {
        switch (simulationAction) {
            case SimulationAction.CARD_AUTHORIZATION_TRANSACTION:
                return (
                    <>
                        <Row gutter={[16, 16]}>
                            <Col span={8} sm="24" md="24">
                                <Form.Item label="Card" name="card" rules={rules.card} initialValue={cards[0]?._id}>
                                    <Select showSearch filterOption={filterSelect}>
                                        {cards.map((card: any) => {
                                            const employee = EmployeeService.getEmployeeById(card.user)
                                            return (
                                                <Select.Option key={card._id} value={card._id}>
                                                    <span>{`**** **** **** ${card.last4Digits} - Holder: ${employee?.firstName} ${employee?.lastName} - Limit: ${card.limit} - Balance: ${card.balance}`}</span>
                                                </Select.Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8} sm="24" md="24">
                                <Form.Item className="w-full" name="amount" label="Amount">
                                    <Input type="number" />
                                </Form.Item>
                            </Col>
                            {!shouldSimulateOnWeavr && (
                                <Col span={8} sm="24" md="24">
                                    <Form.Item className="w-full" name="transactionDate" label="Transaction Date">
                                        <DatePicker
                                            name="transactionDate"
                                            style={{ width: "100%" }}
                                            format={moment.localeData().longDateFormat("L")}
                                            placeholder={t("placeholder:request.date")}
                                            onChange={(value) => formInstance.setFieldsValue({ ...formInstance.getFieldsValue(), datePurchased: value?.toDate() })}
                                        />
                                    </Form.Item>
                                </Col>
                            )}
                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col span={8} sm="24" md="24">
                                <Form.Item className="w-full" name="merchantName" label="Merchant name">
                                    <Input />
                                </Form.Item>
                            </Col>
                            {!shouldSimulateOnWeavr && (
                                <>
                                    <Col span={8} sm="24" md="24">
                                        <Form.Item className="w-full" name="authorizationType" label="Authorization type" initialValue={CardAuthorizationEnum.AUTHORISED}>
                                            <Select defaultValue={CardAuthorizationEnum.AUTHORISED} showSearch filterOption={filterSelect}>
                                                <Select.Option value={CardAuthorizationEnum.AUTHORISED}>AUTHORISED</Select.Option>
                                                <Select.Option value={CardAuthorizationEnum.AUTHORISED_CREDIT}>AUTHORISED_CREDIT</Select.Option>
                                                <Select.Option value={CardAuthorizationEnum.DECLINED}>DECLINED</Select.Option>
                                                <Select.Option value={CardAuthorizationEnum.MANUAL_CLOSE}>MANUAL_CLOSE</Select.Option>
                                                <Select.Option value={CardAuthorizationEnum.EXPIRED}>EXPIRED</Select.Option>
                                                <Select.Option value={CardAuthorizationEnum.ONLINE_REVERSE}>ONLINE_REVERSE</Select.Option>
                                                <Select.Option value={CardAuthorizationEnum.CANCELLED} r>
                                                    CANCELLED
                                                </Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={8} sm="24" md="24">
                                        <Form.Item className="w-full" name="declineReason" label="Decline reason" initialValue={WeavrAuthorizationDeclineReasonEnum.NO_REASON}>
                                            <Select defaultValue={WeavrAuthorizationDeclineReasonEnum.NO_REASON} showSearch filterOption={filterSelect}>
                                                <Select.Option value={WeavrAuthorizationDeclineReasonEnum.PHYSICAL_NOT_ACTIVATED}>PHYSICAL_NOT_ACTIVATED</Select.Option>
                                                <Select.Option value={WeavrAuthorizationDeclineReasonEnum.INSUFFICIENT_BALANCE}>INSUFFICIENT_BALANCE</Select.Option>
                                                <Select.Option value={WeavrAuthorizationDeclineReasonEnum.CVV_CHECKS_FAILED}>CVV_CHECKS_FAILED</Select.Option>
                                                <Select.Option value={WeavrAuthorizationDeclineReasonEnum.CVV_RETRIES_EXCEEDED}>CVV_RETRIES_EXCEEDED</Select.Option>
                                                <Select.Option value={WeavrAuthorizationDeclineReasonEnum.CARD_STATUS_NOT_ALLOWED}>CARD_STATUS_NOT_ALLOWED</Select.Option>
                                                <Select.Option value={WeavrAuthorizationDeclineReasonEnum.PIN_CHECKS_FAILED}>PIN_CHECKS_FAILED</Select.Option>
                                                <Select.Option value={WeavrAuthorizationDeclineReasonEnum.PIN_RETRIES_EXCEEDED}>PIN_RETRIES_EXCEEDED</Select.Option>
                                                <Select.Option value={WeavrAuthorizationDeclineReasonEnum.CARD_EXPIRY_CHECKS_FAILED}>CARD_EXPIRY_CHECKS_FAILED</Select.Option>
                                                <Select.Option value={WeavrAuthorizationDeclineReasonEnum.AVS_CHECKS_FAILED}>AVS_CHECKS_FAILED</Select.Option>
                                                <Select.Option value={WeavrAuthorizationDeclineReasonEnum.ATM_WITHDRAWAL_LIMIT_EXCEEDED}>
                                                    ATM_WITHDRAWAL_LIMIT_EXCEEDED
                                                </Select.Option>
                                                <Select.Option value={WeavrAuthorizationDeclineReasonEnum.SCA_REQUIRED}>SCA_REQUIRED</Select.Option>
                                                <Select.Option value={WeavrAuthorizationDeclineReasonEnum.AUTH_RULE_CHECKS_FAILED}>AUTH_RULE_CHECKS_FAILED</Select.Option>
                                                <Select.Option value={WeavrAuthorizationDeclineReasonEnum.TIMEOUT}>TIMEOUT</Select.Option>
                                                <Select.Option value={WeavrAuthorizationDeclineReasonEnum.NO_REASON}>NO_REASON</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </>
                            )}
                        </Row>
                        {!shouldSimulateOnWeavr && (
                            <>
                                <Row gutter={[16, 16]}>
                                    <Col span={8} sm="24" md="24">
                                        <Form.Item className="w-full" name="transactionId" label="Transaction ID">
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8} sm="24" md="24">
                                        <Form.Item className="w-full" name="relatedAuthorizationId" label="Related Authorization ID">
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={[16, 16]}>
                                    <Col span={8} sm="24" md="24">
                                        <Form.Item className="w-full" name="sourceAmount" label="Source Amount">
                                            <Input type="number" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8} sm="24" md="24">
                                        <Form.Item name="sourceCurrency" label={t("input:request.currency")} key="sourceCurrency" initialValue={wallet.currency}>
                                            <Select showSearch filterOption={doFilterSelect}>
                                                {currencyEnumArray.map((currencyValue, index) => (
                                                    <Select.Option key={index} value={currencyValue} label={currencyValue}>
                                                        <div className="space-x-10">
                                                            <Image
                                                                className="align-middle"
                                                                width={20}
                                                                preview={false}
                                                                src={`data:image/png;base64,${Currencies[currencyValue].flag}`}
                                                            />
                                                            <span className="text-text">{currencyValue}</span>
                                                            <span className="text-text">-</span>
                                                            <span className="text-text">{Currencies[currencyValue].name}</span>
                                                        </div>
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </>
                        )}
                        {renderWeavrSimulationToggle()}
                        <Row className="mt-4">
                            <Col span={24}>
                                <span className="finway-red-text text-bold">
                                    Note: Card transaction simulations on weavr will always trigger an authorization AND a settlement right away. You CANNOT simulate a refund
                                    (positive amount).
                                </span>
                            </Col>
                        </Row>
                    </>
                )
            case SimulationAction.CARD_SETTLEMENT_TRANSACTION:
                return (
                    <>
                        <Row gutter={[16, 16]}>
                            <Col span={8} sm="24" md="24">
                                <Form.Item label="Card" name="card" rules={rules.card} initialValue={cards[0]?._id}>
                                    <Select showSearch filterOption={filterSelect}>
                                        {cards.map((card: any) => {
                                            const employee = EmployeeService.getEmployeeById(card.user)
                                            return (
                                                <Select.Option key={card._id} value={card._id}>
                                                    <span>{`**** **** **** ${card.last4Digits} - Holder: ${employee?.firstName} ${employee?.lastName} - Limit: ${card.limit} - Balance: ${card.balance}`}</span>
                                                </Select.Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8} sm="24" md="24">
                                <Form.Item className="w-full" name="amount" label="Amount">
                                    <Input type="number" />
                                </Form.Item>
                            </Col>
                            {!shouldSimulateOnWeavr && (
                                <Col span={8} sm="24" md="24">
                                    <Form.Item className="w-full" name="transactionDate" label="Transaction Date">
                                        <DatePicker
                                            name="transactionDate"
                                            style={{ width: "100%" }}
                                            format={moment.localeData().longDateFormat("L")}
                                            placeholder={t("placeholder:request.date")}
                                            onChange={(value) => formInstance.setFieldsValue({ ...formInstance.getFieldsValue(), datePurchased: value?.toDate() })}
                                        />
                                    </Form.Item>
                                </Col>
                            )}
                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col span={8} sm="24" md="24">
                                <Form.Item className="w-full" name="merchantName" label="Merchant name">
                                    <Input />
                                </Form.Item>
                            </Col>
                            {!shouldSimulateOnWeavr && (
                                <>
                                    <Col span={8} sm="24" md="24">
                                        <Form.Item className="w-full" name="transactionId" label="Transaction ID">
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8} sm="24" md="24">
                                        <Form.Item className="w-full" name="relatedAuthorizationId" label="Related Authorization ID">
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </>
                            )}
                        </Row>
                        {!shouldSimulateOnWeavr && (
                            <>
                                <Row gutter={[16, 16]}>
                                    <Col span={8} sm="24" md="24">
                                        <Form.Item className="w-full" name="settlementType" label="Settlement type" initialValue={WeavrSettlementTypesEnum.SALE_PURCHASE}>
                                            <Select defaultValue={WeavrSettlementTypesEnum.SALE_PURCHASE} showSearch filterOption={filterSelect}>
                                                <Select.Option value={WeavrSettlementTypesEnum.SALE_PURCHASE}>SALE_PURCHASE</Select.Option>
                                                <Select.Option value={WeavrSettlementTypesEnum.CASH_WITHDRAWAL}>CASH_WITHDRAWAL</Select.Option>
                                                <Select.Option value={WeavrSettlementTypesEnum.SALE_WITH_CASHBACK}>SALE_WITH_CASHBACK</Select.Option>
                                                <Select.Option value={WeavrSettlementTypesEnum.MAIL_OR_TELEPHONE_ORDER}>MAIL_OR_TELEPHONE_ORDER</Select.Option>
                                                <Select.Option value={WeavrSettlementTypesEnum.PURCHASE_REFUND_REVERSAL}>PURCHASE_REFUND_REVERSAL</Select.Option>
                                                <Select.Option value={WeavrSettlementTypesEnum.ORIGINAL_CREDIT_TRANSACTION_REVERSAL}>
                                                    ORIGINAL_CREDIT_TRANSACTION_REVERSAL
                                                </Select.Option>
                                                <Select.Option value={WeavrSettlementTypesEnum.CASH_WITHDRAWAL_REVERSAL}>CASH_WITHDRAWAL_REVERSAL</Select.Option>
                                                <Select.Option value={WeavrSettlementTypesEnum.PURCHASE_REFUND}>PURCHASE_REFUND</Select.Option>
                                                <Select.Option value={WeavrSettlementTypesEnum.PURCHASE_REVERSAL}>PURCHASE_REVERSAL</Select.Option>
                                                <Select.Option value={WeavrSettlementTypesEnum.ORIGINAL_CREDIT_TRANSACTION}>ORIGINAL_CREDIT_TRANSACTION</Select.Option>
                                                <Select.Option value={WeavrSettlementTypesEnum.FIRST_CHARGEBACK}>FIRST_CHARGEBACK</Select.Option>
                                                <Select.Option value={WeavrSettlementTypesEnum.FIRST_CHARGEBACK_REVERSAL}>FIRST_CHARGEBACK_REVERSAL</Select.Option>
                                                <Select.Option value={WeavrSettlementTypesEnum.FIRST_REPRESENTMENT}>FIRST_REPRESENTMENT</Select.Option>
                                                <Select.Option value={WeavrSettlementTypesEnum.FIRST_REPRESENTMENT_REVERSAL}>FIRST_REPRESENTMENT_REVERSAL</Select.Option>
                                                <Select.Option value={WeavrSettlementTypesEnum.SECOND_CHARGEBACK}>SECOND_CHARGEBACK</Select.Option>
                                                <Select.Option value={WeavrSettlementTypesEnum.SECOND_CHARGEBACK_REVERSAL}>SECOND_CHARGEBACK_REVERSAL</Select.Option>
                                                <Select.Option value={WeavrSettlementTypesEnum.SECOND_REPRESENTMENT}>SECOND_REPRESENTMENT</Select.Option>
                                                <Select.Option value={WeavrSettlementTypesEnum.ARBITRATION_CHARGEBACK}>ARBITRATION_CHARGEBACK</Select.Option>
                                                <Select.Option value={WeavrSettlementTypesEnum.BALANCE_INQUIRY}>BALANCE_INQUIRY</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={[16, 16]}>
                                    <Col span={8} sm="24" md="24">
                                        <Form.Item className="w-full" name="sourceAmount" label="Source Amount">
                                            <Input type="number" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8} sm="24" md="24">
                                        <Form.Item name="sourceCurrency" label={t("input:request.currency")} key="sourceCurrency" initialValue={wallet.currency}>
                                            <Select showSearch filterOption={doFilterSelect}>
                                                {currencyEnumArray.map((currencyValue, index) => (
                                                    <Select.Option key={index} value={currencyValue} label={currencyValue}>
                                                        <div className="space-x-10">
                                                            <Image
                                                                className="align-middle"
                                                                width={20}
                                                                preview={false}
                                                                src={`data:image/png;base64,${Currencies[currencyValue].flag}`}
                                                            />
                                                            <span className="text-text">{currencyValue}</span>
                                                            <span className="text-text">-</span>
                                                            <span className="text-text">{Currencies[currencyValue].name}</span>
                                                        </div>
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </>
                        )}
                        {renderWeavrSimulationToggle()}
                        <Row className="mt-4">
                            <Col span={24}>
                                <span className="finway-red-text text-bold">
                                    Note: Card transaction simulations on weavr will always trigger an authorization AND a settlement right away. You CANNOT simulate a refund
                                    (positive amount).
                                </span>
                            </Col>
                        </Row>
                    </>
                )
            case SimulationAction.CARD_PIN_BLOCK:
                return (
                    <>
                        <Row gutter={[16, 16]}>
                            <Col span={8} sm="24" md="24">
                                <Form.Item label="Card" name="card" rules={rules.card} initialValue={cards[0]?._id}>
                                    <Select showSearch filterOption={filterSelect}>
                                        {cards.map((card: any) => {
                                            const employee = EmployeeService.getEmployeeById(card.user)
                                            return (
                                                <Select.Option key={card._id} value={card._id}>
                                                    <span>{`**** **** **** ${card.last4Digits} - Holder: ${employee?.firstName} ${employee?.lastName} - Limit: ${card.limit} - Balance: ${card.balance}`}</span>
                                                </Select.Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </>
                )
            case SimulationAction.FUNDING_TRANSACTION:
                return (
                    <>
                        <Col span={12} sm="24" md="24">
                            <FormItemCurrency currency={wallet.currency} className="w-full" name="amount" label="Amount" rules={rules.limit}>
                                <CurrencyInput currency={wallet.currency} value={0} />
                            </FormItemCurrency>
                        </Col>
                        {renderWeavrSimulationToggle()}
                    </>
                )
            case SimulationAction.KYB_ACCEPTANCE:
                return renderWeavrSimulationToggle()
            case SimulationAction.KYB_REJECTION:
                return (
                    <Row>
                        <Col span={24} sm="24" md="24">
                            <Form.Item label="Reason" name="reason" initialValue={KYBRejectionReasonEnum.BENEFICIARY_DETAILS_UNSATISFACTORY}>
                                <Select showSearch filterOption={filterSelect}>
                                    {Object.values(KYBRejectionReasonEnum).map((reason: string) => (
                                        <Select.Option key={reason} value={reason}>
                                            <span>{reason}</span>
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                )
            case SimulationAction.OUTGOING_WIRE_TRANSFER:
                return (
                    <Row>
                        <Col span={24} sm="24" md="24">
                            <Form.Item label="Outgoing Wire Transfer State" name="transferState" initialValue={WeavrWireTransferStateEnum.COMPLETED}>
                                <Select showSearch filterOption={filterSelect}>
                                    {Object.values(WeavrWireTransferStateEnum).map((state: string) => (
                                        <Select.Option key={state} value={state}>
                                            <span>{state}</span>
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24} sm="24" md="24">
                            <Form.Item label="Outgoing Wire Transfer ID" name="transactionId" initialValue={""}>
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                )
            case SimulationAction.GET_MY_INVOICES_UPLOAD:
                return (
                    <>
                        <h3>Get My Invoices</h3>
                        <Row gutter={[16, 16]}>
                            <Col span={4} sm="12" md="12">
                                <Form.Item label="User ID" name="getMyInvoicesUserId" initialValue={""}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={4} sm="12" md="12">
                                <Form.Item label="Email" name="getMyInvoicesEmail" initialValue={""}>
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col span={8} sm="24" md="24">
                                <Form.Item>
                                    <Upload.Dragger
                                        beforeUpload={(file) => {
                                            setUploadFile(file)
                                            // so it doesn't trigger the action event
                                            return false
                                        }}
                                        multiple={false}
                                    >
                                        <p className="ant-upload-drag-icon">
                                            <InboxOutlined />
                                        </p>
                                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                    </Upload.Dragger>
                                </Form.Item>
                            </Col>
                        </Row>
                    </>
                )
            default:
                return null
        }
    }

    const isSimulateActionButtonDisabled = isLoading || activeTab === SimulatorTabsEnum.CRON_JOB

    return (
        <Modal
            className="ant-modal--full"
            title="Simulator"
            visible={isShowing}
            maskClosable={false}
            onCancel={onHide}
            cancelText={t("action:cancel")}
            confirmLoading={isLoading}
            destroyOnClose
            footer={[
                <Button key="back" onClick={onHide}>
                    {t("action:cancel")}
                </Button>,
                <Button key="submit" type="primary" loading={isLoading} disabled={isSimulateActionButtonDisabled} onClick={() => formInstance.submit()}>
                    Simulate Action
                </Button>,
            ]}
        >
            <Form form={formInstance} layout="vertical" onFinish={onSubmit}>
                <Tabs
                    id="dashboard-tabs"
                    destroyInactiveTabPane={true}
                    defaultActiveKey={SimulatorTabsEnum.CARDS}
                    activeKey={activeTab}
                    onTabClick={(key: SimulatorTabsEnum) => {
                        setActiveTab(key)
                        if (key === SimulatorTabsEnum.CARDS) {
                            setShouldSimulateOnWeavr(false)
                            setSimulationAction(key === SimulatorTabsEnum.CARDS ? SimulationAction.CARD_AUTHORIZATION_TRANSACTION : SimulationAction.GET_MY_INVOICES_UPLOAD)
                        } else if (key === SimulatorTabsEnum.PAYMENT_SETTINGS) {
                            setSimulationAction(SimulationAction.PAYMENT_SETTINGS_RESET)
                        } else if (key === SimulatorTabsEnum.TENANT) {
                            setSimulationAction(SimulationAction.TENANT_TIER_CHANGE)
                        } else if (key === SimulatorTabsEnum.DATA_GENERATOR) {
                            setSimulationAction(SimulationAction.CREATE_QA_DATA)
                        } else if (key === SimulatorTabsEnum.INTEGRATIONS) {
                            setSimulationAction(SimulationAction.GET_MY_INVOICES_UPLOAD)
                        }
                    }}
                    className="animation-appear pb-10 flex flex-col"
                >
                    <Tabs.TabPane tab={"Cards"} key={SimulatorTabsEnum.CARDS}>
                        <Row gutter={[20, 16]}>
                            <Col span={24}>
                                <Form.Item name="simulationAction">
                                    <div>
                                        <Radio.Group defaultValue={simulationAction} onChange={(e) => setSimulationAction(e.target.value)}>
                                            <Radio.Button value={SimulationAction.KYB_ACCEPTANCE}>Accept KYB</Radio.Button>
                                            <Radio.Button value={SimulationAction.KYB_REJECTION}>Reject KYB</Radio.Button>
                                            <Radio.Button value={SimulationAction.FUNDING_TRANSACTION}>Funding Transaction</Radio.Button>
                                            {/* TODO: do we still need this? */}
                                            {/* <Radio.Button value={SimulationAction.OUTGOING_WIRE_TRANSFER}>Outgoing Wire Transfer State Update</Radio.Button> */}
                                            <Radio.Button value={SimulationAction.CARD_AUTHORIZATION_TRANSACTION}>Card Authorization</Radio.Button>
                                            <Radio.Button value={SimulationAction.CARD_SETTLEMENT_TRANSACTION}>Card Settlement</Radio.Button>
                                            <Radio.Button value={SimulationAction.CARD_PIN_BLOCK}>Card PIN block</Radio.Button>
                                        </Radio.Group>
                                    </div>
                                </Form.Item>
                                {renderFieldsForAction()}
                            </Col>
                        </Row>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={"Integrations"} key={SimulatorTabsEnum.INTEGRATIONS}>
                        {renderFieldsForAction()}
                    </Tabs.TabPane>

                    <Tabs.TabPane tab={"Cronjob"} key={SimulatorTabsEnum.CRON_JOB}>
                        <SimulatorCronTab />
                    </Tabs.TabPane>

                    <Tabs.TabPane tab={"Payment Settings"} key={SimulatorTabsEnum.PAYMENT_SETTINGS}>
                        <Suspense fallback={<></>}>
                            <SimulatorPaymentSettingsTab formInstance={formInstance} />
                        </Suspense>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={"Tenant"} key={SimulatorTabsEnum.TENANT}>
                        <Suspense fallback={<></>}>
                            <SimulatorTenantTab formInstance={formInstance} />
                        </Suspense>
                    </Tabs.TabPane>
                    {loggedInUser?.email === FINWAY_ADMIN_EMAIL && (
                        <Tabs.TabPane tab={"Data Generator"} key={SimulatorTabsEnum.DATA_GENERATOR}>
                            <Suspense fallback={<></>}>
                                <SimulatorDataGeneratorTab />
                            </Suspense>
                        </Tabs.TabPane>
                    )}
                    <Tabs.TabPane tab={"Version"} key={SimulatorTabsEnum.VERSION}>
                        <Row>
                            <Col span={12}>{version}</Col>
                        </Row>
                        <Button onClick={() => Sentry.captureException(`[Simulator] Sentry test emit exception`)}>Trigger Sentry capture</Button>
                    </Tabs.TabPane>
                </Tabs>
            </Form>
        </Modal>
    )
}

export default SimulatorModal
