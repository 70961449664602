import { TfaChannelEnum } from "@finway-group/shared/lib/models"
import { Button, Modal } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"

import TfAuthForm, { TFAAppStepEnum } from "Components/forms/tfauth/tfauthApp.form"
import { useLoggedInEmployee } from "Shared/hooks/employee.hooks"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

enum TFAStepEnum {
    METHOD_SELECTION,
    QR_CODE_SCAN,
    CODE_INPUT,
}

interface TfaModalInterface {
    isShowing: boolean
    onCancel?: () => void
    setupSmsTfa: () => Promise<void>
    disableTfa: (isEnabled: boolean) => void
}
export const TfaModal: React.FC<TfaModalInterface> = ({ isShowing, onCancel, setupSmsTfa, disableTfa }) => {
    const { t } = useTranslation()

    const loggedInUser = useLoggedInEmployee()
    const securityOptions = loggedInUser.getSecurityOptions()

    const [currentStep, setCurrentStep] = useStateIfMounted<TFAStepEnum>(TFAStepEnum.METHOD_SELECTION)
    const [tfaAppStep, setTfaAppStep] = useStateIfMounted(TFAAppStepEnum.QR_CODE_STEP)
    const [tfaMethod, setTfaMethod] = useStateIfMounted(securityOptions?.tfaMethod || TfaChannelEnum.AUTH_APP)
    const [isLoading, setIsLoading] = useStateIfMounted(false)

    const cleanUpAndClose = () => {
        setCurrentStep(TFAStepEnum.METHOD_SELECTION)
        onCancel?.()
    }

    /**
     *  TFA Selection method ui
     */
    const SelectionSteps = () => {
        const selectionData = [
            {
                type: TfaChannelEnum.AUTH_APP,
                title: t("label:tfa.authenticator.title_app"),
                description: t("label:tfa.authenticator.app_description"),
            },
            {
                type: TfaChannelEnum.SMS,
                title: t("label:tfa.authenticator.title_sms"),
                description: t("label:tfa.authenticator.sms_description"),
            },
        ]

        const handleToolSelection = async (type: TfaChannelEnum) => {
            switch (type) {
                case TfaChannelEnum.AUTH_APP:
                    setTfaMethod(type)
                    // set tfa modal selection step
                    setCurrentStep(TFAStepEnum.QR_CODE_SCAN)
                    // set TFA APP form specific step
                    setTfaAppStep(TFAAppStepEnum.QR_CODE_STEP)
                    break
                default:
                    cleanUpAndClose()
                    await setupSmsTfa()
                    break
            }
        }

        return (
            <>
                {selectionData.map(({ type, title, description }, index) => {
                    const isEnabled = securityOptions?.tfaEnabled && securityOptions.tfaMethod === type
                    return (
                        <div key={index} className={`flex flex-col items-center tfa-tool p-10 mr-10 ml-10 min-h-200 xs:min-h-160 ${isEnabled && "enabled"}`}>
                            <span className="title text-center">
                                <h3>{title}</h3>
                                <span className="text text-xs">{description}</span>
                            </span>

                            {isEnabled ? (
                                <Button className="w-full mt-auto" onClick={() => disableTfa(false)}>
                                    <span>{t("label:disable")}</span>
                                </Button>
                            ) : (
                                <Button type="primary" className="w-full mt-auto" onClick={() => handleToolSelection(type)}>
                                    <span>{t("label:tfa.setup")}</span>
                                </Button>
                            )}
                        </div>
                    )
                })}
            </>
        )
    }

    /**
     * Custom footer for TFA APP QrCode scan step
     */
    const customFooter = () => {
        const GotoInputCodeStep = () => {
            setTfaAppStep(TFAAppStepEnum.TOKEN_CODE_STEP)
            return TFAStepEnum.CODE_INPUT
        }

        const goBack = () => {
            tfaAppStep === TFAAppStepEnum.TOKEN_CODE_STEP && setTfaAppStep(TFAAppStepEnum.QR_CODE_STEP)
            return currentStep === TFAStepEnum.QR_CODE_SCAN ? TFAStepEnum.METHOD_SELECTION : TFAStepEnum.QR_CODE_SCAN
        }

        if ([TFAStepEnum.QR_CODE_SCAN, TFAStepEnum.CODE_INPUT].includes(currentStep)) {
            return [
                <Button id="modalFooterBack" key="back" onClick={() => setCurrentStep(goBack())}>
                    {t("label:modal_footer.return")}
                </Button>,
                <Button id="modalFooterSubmit" key="submit" type="primary" onClick={() => setCurrentStep(GotoInputCodeStep())} loading={isLoading}>
                    {t("label:modal_footer.continue")}
                </Button>,
            ]
        }

        return null
    }

    return (
        <Modal
            className="ant-modal--small"
            visible={isShowing}
            maskClosable={false}
            title={t("label:tfa.authenticator.title")}
            onCancel={cleanUpAndClose}
            footer={customFooter()}
            destroyOnClose
            forceRender
        >
            {currentStep === TFAStepEnum.METHOD_SELECTION && <div className="flex flex-row items-center pb-30">{<SelectionSteps />}</div>}
            {(currentStep === TFAStepEnum.QR_CODE_SCAN || currentStep === TFAStepEnum.CODE_INPUT) && tfaMethod === TfaChannelEnum.AUTH_APP && (
                <div className="flex flex-col items-center">
                    <TfAuthForm setupStep={tfaAppStep} onSuccess={cleanUpAndClose} onLoading={setIsLoading} />
                </div>
            )}
        </Modal>
    )
}
