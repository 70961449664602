import { CsvTemplate } from "@finway-group/shared/lib/models"
import { ActionCreator, Dispatch } from "redux"

import CsvTemplateService from "Shared/services/csvTemplate.service"
import { ThunkResult } from "Shared/store"

import { CreateNewCsvTemplatesAction, CsvTemplateActionTypes, DeleteCsvTemplateAction, FetchAllCsvTemplatesAction, UpdateCsvTemplatesAction } from "./csvTemplateTypes"

export const fetchAllCsvTemplates: ActionCreator<ThunkResult<Array<CsvTemplate>>> = () => async (dispatch: Dispatch) => {
    const templates: Array<CsvTemplate> = await CsvTemplateService.fetchAllTemplates()
    const fetchAllCsvTemplatesAction: FetchAllCsvTemplatesAction = {
        type: CsvTemplateActionTypes.FETCH_ALL_TEMPLATES,
        templates,
    }
    dispatch(fetchAllCsvTemplatesAction)
    return templates
}
export const createCsvTemplate = (newTemplate: CsvTemplate) => async (dispatch: Dispatch) => {
    const template = await CsvTemplateService.createTemplate(newTemplate)
    const createNewCsvTemplateAction: CreateNewCsvTemplatesAction = {
        type: CsvTemplateActionTypes.CREATE_ONE_TEMPLATE,
        template,
    }
    dispatch(createNewCsvTemplateAction)
    return template
}

export const updateCsvTemplate = (template: CsvTemplate) => async (dispatch: Dispatch) => {
    const templateUpdated = await CsvTemplateService.updateTemplate(template)
    const updateCsvTemplateAction: UpdateCsvTemplatesAction = {
        type: CsvTemplateActionTypes.UPDATE_ONE_TEMPLATE,
        template: templateUpdated,
    }
    dispatch(updateCsvTemplateAction)
    return template
}

export const deleteCsvTemplate = (id: string) => async (dispatch: Dispatch) => {
    await CsvTemplateService.deleteTemplate(id)
    const deleteCsvTemplateAction: DeleteCsvTemplateAction = {
        type: CsvTemplateActionTypes.DELETE_ONE_TEMPLATE,
        id,
    }
    dispatch(deleteCsvTemplateAction)
    return id
}
