import { EllipsisOutlined, PlusCircleOutlined } from "@ant-design/icons"
import { Expense, ExpenseKindEnum, ExpensePaymentFlowInformationEnum, ExpenseRequestTypeEnum, ExpenseStatusEnum, RightEnum } from "@finway-group/shared/lib/models"
import { getApprovalProcessInProgress } from "@finway-group/shared/lib/utils/approvalProcess.utils"
import { Button, Dropdown, Menu, Modal } from "antd"
import React from "react"
import { Archive, Edit, RotateCw as ResetIcon, Trash } from "react-feather"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"

import { ApprovalProcessIndicatorMenu } from "Components/approvalProcessesIndicator"
import ExpenseTagSection from "Components/expenseTagSection"
import ExportDropDownMenu from "Components/exportDropDownMenu"
import { AttachExpenseButton } from "Components/modals/attachExpense/attachExpense.modal"
import ExpenseCreateFormModal from "Components/modals/expenseCreateForm.modal"
import { ExpenseExport } from "Features/export/expenseExport"
import { useExpenseDetailsContext } from "Shared/context/expenseDetails.context"
import { useModal } from "Shared/context/modal.context"
import { useArchiveInterval, useCompany } from "Shared/hooks/company.hooks"
import { useLoggedInEmployeeProfile } from "Shared/hooks/user.hooks"
import { AuthzService, CompanyService, EmployeeService, ExpenseService, NotificationService } from "Shared/services"
import DialogService from "Shared/services/dialog.service"
import FolderService from "Shared/services/folder.service"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { deleteExpense, resetExpense, syncExpense } from "Shared/store/actions/expense/expenseActions"
import { isExpenseArchived, isExpenseInvoiceProvided, isFolderExpense } from "Shared/utils/expense.utils"
import { FOLDER_CHILDREN_LIMIT, isFolderResettable } from "Shared/utils/folder.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

import ExpenseRequestDropdown from "../../expenseRequestDropdown"
import { ArchiveFolderModal } from "./archiveFolder.modal"
import { UnarchiveFolderModal } from "./unarchiveFolder.modal"

interface FolderActionButtonsInterface {
    folder: Expense // The folder expense itself
    activeExpense: Expense // The current selected expense. (Could be the folder or a sub-expense)
    subExpenses: Array<Expense>
    isEditableExpense: boolean
}

export const FolderActionButtons: React.FC<FolderActionButtonsInterface> = ({ folder, isEditableExpense, subExpenses, activeExpense }) => {
    const loggedInUser = useLoggedInEmployeeProfile()
    const isCompanyGobCompliant = CompanyService.doesCompanyEnforceGobdCompliantInvoice()
    const { taggingEnabled } = useCompany()
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [isEditModalShowing, setIsEditModalShowing] = useStateIfMounted(false)
    const { showModal } = useModal()
    const { setIsLoading, isReadOnly } = useExpenseDetailsContext()
    const archiveAfterXDays = useArchiveInterval()
    const isFolderArchived = isExpenseArchived(folder, archiveAfterXDays)
    const history = useHistory()

    if (!isFolderExpense(folder)) {
        return <></>
    }

    const isResetDisabled = folder.isInReview() && folder.paymentFlowInformation === ExpensePaymentFlowInformationEnum.ALREADY_APPROVED_AND_PAID
    const isFolderDeleteDisabled = folder.cannotBeDeleted() || folder.isSuccessfullyProcessed() || folder.deleted
    const isAuthorizedToArchive = AuthzService.canLoggedInUserArchiveRequest(loggedInUser, EmployeeService.getEmployeeById(folder.requestedBy._id))

    const onSubExpenseCreated = (subExpense: Expense) => {
        if (subExpense.folderId === folder.id) syncExpense(folder.id)(dispatch)
    }

    const showExportButton = subExpenses.length > 0 && subExpenses.every(isExpenseInvoiceProvided)
    const onlyFileExport = subExpenses.some((expense) => ExpenseService.onlyAllowFileExports(expense))

    const handleEditFolder = () => {
        setIsEditModalShowing(true)
    }

    const handleDeleteFolder = () => {
        Modal.confirm({
            title: t("confirm:request.delete_folder.title"),
            content: t("confirm:request.delete_folder.message"),
            cancelText: t("confirm:request.delete_folder.cancel"),
            type: "warning",
            okText: t("confirm:request.delete_folder.confirm"),
            onOk: async () => {
                try {
                    await deleteExpense(folder.id)(dispatch)
                    history.goBack()
                    NotificationService.send(NotificationTypeEnum.WARNING, t(`notification:trip_folder.cancelled.title`), t(`notification:trip_folder.cancelled.message`))
                } catch (err) {
                    NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:error"))
                }
            },
        })
    }

    const handleArchiveFolder = () => {
        showModal(ArchiveFolderModal, true, {
            isShowing: true,
            folder,
            subExpenses,
        })
    }

    const handleUnarchiveFolder = async () => {
        showModal(UnarchiveFolderModal, true, {
            isShowing: true,
            folder,
            subExpenses,
        })
    }

    const handleResetFolderStatus = async () => {
        if (await DialogService.confirmFolderStatusReset(folder.isPending())) {
            try {
                setIsLoading(true)
                await resetExpense(folder.id)(dispatch)
                NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:request.reset.title"), t("notification:request.reset.message"))
            } catch (err) {
                NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:error"))
            } finally {
                setIsLoading(false)
            }
        }
    }

    const onDropdownClicked = ({ key, domEvent }: any) => {
        domEvent.stopPropagation()

        switch (key) {
            case "edit":
                handleEditFolder()
                break
            case "delete":
                handleDeleteFolder()
                break
            case "archive":
                handleArchiveFolder()
                break
            case "unarchive":
                handleUnarchiveFolder()
                break
            case "reset_status":
                handleResetFolderStatus()
                break
            default:
                break
        }
    }

    const dropdownMenu = (
        <Menu onClick={onDropdownClicked}>
            <Menu.Item key="edit" className="flex items-center min-w-190 py-8">
                <div className="flex items-center justify-center rounded-full w-16 h-16">
                    <Edit strokeWidth={1} />
                </div>
                <div className="flex items-center ml-12">
                    <p className="leading-none">
                        <span className="text-text">{t("action:request.folder.edit")}</span>
                    </p>
                </div>
            </Menu.Item>
            <Menu.Item key="delete" className="flex items-center min-w-190 py-8" disabled={isFolderDeleteDisabled}>
                <div className="flex items-center justify-center rounded-full w-16 h-16">
                    <Trash strokeWidth={1} />
                </div>
                <div className="flex items-center ml-12">
                    <p className="leading-none">
                        <span className="text-text">{t("action:request.folder.delete")}</span>
                    </p>
                </div>
            </Menu.Item>
            {isFolderResettable(folder) && (
                <Menu.Item key="reset_status" className="flex items-center min-w-190 py-8" disabled={isResetDisabled}>
                    <div className="flex items-center justify-center rounded-full w-16 h-16">
                        <ResetIcon strokeWidth={1.3} />
                    </div>
                    <div className="flex items-center ml-12">
                        <p className="leading-none">
                            <span className="text-text">{t("action:request.reset_status")}</span>
                        </p>
                    </div>
                </Menu.Item>
            )}

            {isAuthorizedToArchive && (!isFolderArchived || !isCompanyGobCompliant) && (
                <Menu.Item key={isFolderArchived ? "unarchive" : "archive"} className="flex items-center min-w-190 py-8">
                    <div className="flex items-center justify-center rounded-full w-16 h-16">
                        <Archive strokeWidth={1} />
                    </div>
                    <div className="flex items-center ml-12">
                        <p className="leading-none">
                            <span className="text-text">{isFolderArchived ? t("action:request.folder.unarchive") : t("action:request.folder.archive")}</span>
                        </p>
                    </div>
                </Menu.Item>
            )}
        </Menu>
    )

    return (
        <div className="flex-wrap flex">
            {isEditModalShowing && (
                <ExpenseCreateFormModal // To edit the folder itself
                    type={ExpenseRequestTypeEnum.TRIP_FOLDER}
                    hideExpenseKindRadioOption={true}
                    isNew={false}
                    isShowing={isEditModalShowing}
                    kind={folder.kind}
                    onCancel={() => setIsEditModalShowing(false)}
                />
            )}
            {taggingEnabled && <ExpenseTagSection expenseId={folder.id} expenseTags={folder.expenseTags} hideTags={folder.expenseTags?.length > 1} />}
            <ApprovalProcessIndicatorMenu
                approvalProcesses={activeExpense.approvalProcesses}
                scope={getApprovalProcessInProgress(activeExpense.approvalProcesses)?.scope}
                title={activeExpense.kind === ExpenseKindEnum.TRIP_FOLDER ? t("action:folder_approval_process") : undefined}
            />
            {((!isReadOnly && isEditableExpense) || AuthzService.isLoggedInUserAllowedToApproveExpense(folder)) &&
            ExpenseService.isExpenseEditableBasedOnArchive(isFolderArchived) ? (
                <>
                    <Dropdown overlay={dropdownMenu}>
                        <Button className="m-5">
                            <EllipsisOutlined />
                        </Button>
                    </Dropdown>
                    {folder.status < ExpenseStatusEnum.REVIEWED && (
                        <>
                            <AttachExpenseButton folder={folder} subExpenseCount={subExpenses.length} />
                            <ExpenseRequestDropdown // To create the content of the folder
                                onExpenseCreated={onSubExpenseCreated}
                                folder={folder}
                                disabled={subExpenses.length >= FOLDER_CHILDREN_LIMIT}
                                buttonOverride={
                                    <Button data-testid="addRequestToFolder" type="primary" className="m-5" disabled={subExpenses.length >= FOLDER_CHILDREN_LIMIT}>
                                        <PlusCircleOutlined className="mt-2" /> {t("action:request.folder.add")}
                                    </Button>
                                }
                            />
                        </>
                    )}
                </>
            ) : (
                <></>
            )}
            {AuthzService.isRightGrantedForLoggedInUser(RightEnum.EXPENSE__ALL__EXPORT) && showExportButton && (
                <div className="mt-5">
                    <ExpenseExport
                        render={({ onExport, isExporting }) => (
                            <ExportDropDownMenu
                                expenses={[folder, ...subExpenses]}
                                onExport={({ expensesArrayOrExpenseQuery: expenses, exportFormat, csvTemplate }) =>
                                    FolderService.handleFolderExportAction(activeExpense, subExpenses, showModal, onExport, expenses, exportFormat, csvTemplate)
                                }
                                isLoading={isExporting}
                                includeSepa
                                noIcon
                                asDottedButton
                                onlyFileExport={onlyFileExport}
                                placement="bottomRight"
                            />
                        )}
                    />
                </div>
            )}
        </div>
    )
}
