import { Employee } from "@finway-group/shared/lib/models"
import { Form, Modal } from "antd"
import { Store } from "antd/lib/form/interface"
import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import SettingsForm from "Components/forms/settings.form"
import { NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { updateEmployeeSettings } from "Shared/store/actions/employee/employeeActions"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

interface ToolSettingModalProps {
    isShowing: boolean
    employee: Employee
    onCancel: () => void
}

const ToolSettingsModal = ({ isShowing, employee, onCancel }: ToolSettingModalProps) => {
    const { t, i18n } = useTranslation()
    const dispatch = useDispatch()

    const [formInstance] = Form.useForm()

    const [isLoading, setIsLoading] = useStateIfMounted(false)

    const onSubmit = ({
        globalCurrency,
        showExpenseNumbers,
        enableOcr,
        enableOcrItemization,
        enableGoToNextExpense,
        showGrossAmount,
        csvDelimiter,
        csvNumberDecimalSeparator,
        language,
    }: Store) => {
        setIsLoading(true)
        employee.setGlobalSettings(
            globalCurrency,
            showExpenseNumbers,
            enableOcr,
            enableOcrItemization,
            showGrossAmount,
            csvDelimiter,
            language,
            enableGoToNextExpense,
            csvNumberDecimalSeparator,
        )
        updateEmployeeSettings(
            employee.id,
            employee,
        )(dispatch)
            .then(() => {
                if (i18n.language !== employee.getUserLanguage()) i18n.changeLanguage(employee.getUserLanguage())
                NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:settings.tools.updated.title"), t("notification:settings.tools.updated.message"))
                formInstance.resetFields()
                setIsLoading(false)
                onCancel()
            })
            .catch((err) => {
                setIsLoading(false)
                NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:settings.edit.title"))
            })
    }

    return (
        <Modal
            title={t("action:tool_settings.edit")}
            visible={isShowing}
            maskClosable={false}
            onOk={formInstance.submit}
            onCancel={onCancel}
            okText={t("action:tool_settings.save")}
            cancelText={t("action:cancel")}
            confirmLoading={isLoading}
            keyboard
            destroyOnClose
            bodyStyle={{ paddingBottom: "0" }}
        >
            <SettingsForm formInstance={formInstance} onSubmit={onSubmit} userSettings={employee.settings} />
        </Modal>
    )
}

export default ToolSettingsModal
