import { BankOutlined } from "@ant-design/icons"
import { Alert, Button, Card, Table } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import Icon from "Components/icon"
import { RoutePathEnum } from "Shared/router/RoutePath.enum"

const NoBankAccountCard: React.FC = () => {
    const { t } = useTranslation()
    const history = useHistory()

    return (
        <Card>
            <div className="h-400 min-w-400">
                <Alert showIcon message={t("info:liquidity_management.account_currency_alert")}></Alert>
                <div className="flex items-center justify-center mt-80">
                    <Icon icon="empty_bank" />
                </div>
                <div className="flex items-center justify-center mt-24">
                    <p className="w-300 text-center">{t("info:liquidity_management.no_account_connected")}</p>
                </div>
                <div className="flex items-center justify-center mt-24">
                    <Button
                        onClick={() => {
                            history.push(RoutePathEnum.SETTINGS_BANK_ACCOUNTS, RoutePathEnum.LM)
                        }}
                    >
                        <BankOutlined />
                        {t("action:liquidity.add_bank_account")}
                    </Button>
                </div>
            </div>
        </Card>
    )
}

export default NoBankAccountCard
