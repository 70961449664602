import { Expense, ExpenseStatusEnum, TripFolder } from "@finway-group/shared/lib/models"
import { TFunction } from "i18next"

import { isFolderExpense } from "Shared/utils/expense.utils"

export const FOLDER_CHILDREN_LIMIT = 20

export const getFolderMaximumPossibleStatus = (folder: TripFolder, children: Array<Expense>) =>
    children.reduce((min: ExpenseStatusEnum, expense: Expense) => {
        if (expense.status === ExpenseStatusEnum.DECLINED || min === ExpenseStatusEnum.DECLINED) return ExpenseStatusEnum.DECLINED

        return expense.status < min ? expense.status : min
    }, folder.status)

export const canFolderAdvance = (folder: TripFolder, subExpenses: Array<Expense>) => {
    const childrenStatus = getFolderMaximumPossibleStatus(folder, subExpenses)

    if (childrenStatus === ExpenseStatusEnum.DECLINED) return false

    return folder.status <= childrenStatus
}

export const isFolderAttachableAndDetachable = (folder: TripFolder) => folder.status < ExpenseStatusEnum.REVIEWED && folder.status !== ExpenseStatusEnum.DECLINED

export const getProcessableSubExpenses = (folder: TripFolder, subExpenses: Array<Expense>) => subExpenses.filter((expense) => folder.status === expense.status)

export const getNextStatus = (status: ExpenseStatusEnum) => {
    switch (status) {
        case ExpenseStatusEnum.DOCS_NEEDED:
            return ExpenseStatusEnum.INVOICE_PENDING
        case ExpenseStatusEnum.INVOICE_PENDING:
            return ExpenseStatusEnum.APPROVED
        case ExpenseStatusEnum.APPROVED:
            return ExpenseStatusEnum.REVIEWED
        case ExpenseStatusEnum.REVIEWED:
            return ExpenseStatusEnum.PAID
        case ExpenseStatusEnum.PAID:
            return ExpenseStatusEnum.DONE
        default:
            return undefined
    }
}

export const getFolderProgressionInfo = (folder: TripFolder, subExpenses: Array<Expense>) => {
    const canAdvance = canFolderAdvance(folder, subExpenses)
    const processableSubExpenses = getProcessableSubExpenses(folder, subExpenses)
    const nextStatus = getNextStatus(folder.status)
    const currentStatus = folder.status

    return { canAdvance, processableSubExpenses, currentStatus, nextStatus }
}

export const getFolderActionStrings = (folder: TripFolder, t: TFunction) => {
    const statusString = folder.isDraft ? "draft" : ExpenseStatusEnum[folder.status].toLowerCase()
    return {
        title: t(`info:folder.status.${statusString}.title`),
        description: t(`info:folder.status.${statusString}.description`),
        tooltip: t(`info:folder.status.${statusString}.tooltip`),
        confirm: t(`action:request.folder.status.${statusString}.confirm`),
        reject: t(`action:request.folder.status.${statusString}.reject`),
    }
}

export const isFolderResettable = (folder: TripFolder) =>
    [ExpenseStatusEnum.APPROVED, ExpenseStatusEnum.REVIEWED, ExpenseStatusEnum.PAID, ExpenseStatusEnum.DONE].includes(folder.status)

export const separateFoldersAndIndividualExpenses = (expenses: Array<any>) => {
    const folders: Array<TripFolder> = []
    const individualExpenses: Array<Expense> = []
    for (const expense of expenses) {
        if (isFolderExpense(expense)) folders.push(expense)
        else individualExpenses.push(expense)
    }
    return { folders, individualExpenses }
}

export const getExpensesThatDontBelongToFolders = (expenses: Array<any>) => {
    const { folders, individualExpenses } = separateFoldersAndIndividualExpenses(expenses)
    const folderMap = new Map<string, TripFolder>(folders.map((folder) => [folder.id, folder]))

    return { expensesWithoutFolder: individualExpenses.filter((expense) => !expense.folderId || !folderMap.get(expense.folderId)), folders }
}

export const isFolderPayable = (folder: TripFolder, subExpenses: Array<Expense>) => {
    if (folder.status !== ExpenseStatusEnum.REVIEWED) return false
    const payableSubExpenses = subExpenses.filter((subExpense) => subExpense.status === ExpenseStatusEnum.REVIEWED && subExpense.totalGrossPrice > 0)
    return payableSubExpenses.length > 0
}

export const isFolderMarkableAsPaid = (folder: TripFolder, subExpenses: Array<Expense>) => {
    if (isFolderPayable(folder, subExpenses)) return true

    if (folder.status !== ExpenseStatusEnum.REVIEWED) return false

    const allSubExpensesAreAhead = subExpenses.every((subExpense) => subExpense.status > ExpenseStatusEnum.REVIEWED)
    return allSubExpensesAreAhead
}
