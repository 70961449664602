import { Employee, PriceIntervalEnum } from "@finway-group/shared/lib/models"
import { Col, Row } from "antd"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import InfiniteScroll from "react-infinite-scroller"
import { Link, generatePath } from "react-router-dom"

import ProgressBar from "Components/ProgressBar"
import Loading from "Components/loading"
import PriceLabel from "Components/priceLabel"
import { usePagedCards } from "Shared/hooks/creditCard.hooks"
import { RoutePathEnum } from "Shared/router/RoutePath.enum"
import { UserService } from "Shared/services"
import { capitalizeFirstLetter } from "Shared/utils/helper.utils"

interface EmployeeCardsInterface {
    employee: Employee
}

const EmployeeCards: React.FC<EmployeeCardsInterface> = ({ employee }) => {
    const { t } = useTranslation()
    const loggedInUser = UserService.getLoggedInEmployeeProfile()
    const { globalCurrency } = loggedInUser.settings
    const [append, setAppend] = useState(false)
    const [isAppending, setIsAppending] = useState(false)
    const { cards, totalDocs, isLoading, currentPage, setCurrentPage } = usePagedCards(1, 10, `&user[eq]=${employee.id}&sortBy=desc(createdAt)`, [], append)
    const loadMore = cards.length < totalDocs

    const onLoadMorePages = () => {
        setAppend(true)
        setIsAppending(true)
        setCurrentPage(currentPage + 1)
    }

    // To avoid the infinite scrolling from appending more pages when the cards.length havent properly updated yet.
    useEffect(() => {
        if (isAppending) setIsAppending(false)
    }, [cards.length])

    return (
        <>
            {isLoading ? (
                <Loading className="h-150 mb-40" />
            ) : cards.length === 0 ? (
                <p>{t("missing:cards.no_cards")}</p>
            ) : (
                <InfiniteScroll loadMore={onLoadMorePages} hasMore={!isLoading && !isAppending && loadMore}>
                    {cards.map((card: any, index: number) => {
                        const cardType = capitalizeFirstLetter(card.cardType.toLowerCase())
                        const margin = index < cards.length - 1 ? `mb-20` : ``
                        return (
                            <Link to={{ pathname: generatePath(RoutePathEnum.CARD_DETAIL_PAGE, { id: card._id }) }}>
                                <Row key={index} className={margin}>
                                    <Col span={12}>
                                        <Row>
                                            <p className="font-bold leading-tight mb-0 truncate">{cardType}</p>
                                        </Row>
                                        <Row>
                                            <p className="text-xs text-text leading-none mb-0 truncate">{`*** ${card.last4Digits}`}</p>
                                        </Row>
                                    </Col>
                                    <Col span={12}>
                                        <Row className="block">
                                            <span className="text-text-dark mb-4 leading-none float-left ml-2">
                                                <PriceLabel value={card.spending} currency={globalCurrency} interval={PriceIntervalEnum.ONE_TIME} />
                                            </span>

                                            <span className="float-right mb-4 text-xs mr-5 opacity-75">
                                                <PriceLabel value={card.limit} currency={globalCurrency} interval={PriceIntervalEnum.ONE_TIME} />
                                            </span>
                                        </Row>
                                        <Row className="block">
                                            <ProgressBar
                                                total={card.limit || 1}
                                                value={card.spending}
                                                currency={globalCurrency}
                                                thin
                                                hideLabels
                                                extended
                                                // TODO: what do we want?
                                                // showRemaining={GREEN_LIGHT4}
                                            />
                                        </Row>
                                    </Col>
                                </Row>
                            </Link>
                        )
                    })}
                </InfiniteScroll>
            )}
        </>
    )
}

export default EmployeeCards
