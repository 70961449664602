import { textMultipleWhiteSpace, textNoWhiteSpacesRegex } from "@finway-group/shared/lib/utils/validators"
import { FormInstance, Rule } from "antd/lib/form"
import i18n from "i18next"

import { FormService } from "Shared/services"
import { isNotSet, isUniqueInReduxAndForm } from "Shared/utils/helper.utils"

import { allowlistCharValidator } from "./utils.rules"

const ExpenseAccountRules = (formInstance: FormInstance, isEdit: boolean): { [key: string]: Array<Rule> } => ({
    accountCode: [
        {
            validator: (_rule: any, value: string) => {
                if (!value) {
                    return Promise.reject(i18n.t("validation:required"))
                }

                if (!textNoWhiteSpacesRegex.test(value)) {
                    return Promise.reject(new Error(i18n.t("validation:string_white_space")))
                }
                const { formValues, existingValuesRaw } = FormService.getFormAndReduxValues("expenseAccounts", formInstance)
                if (!isUniqueInReduxAndForm({ formValues, existingValuesRaw, valueKey: "accountCode", value, isEdit })) {
                    return Promise.reject(i18n.t("validation:unique", { value: i18n.t("validation:account_code") }))
                }

                if (value.length > 10) {
                    return Promise.reject(i18n.t("validation:max.string", { max: 10 }))
                }

                return Promise.resolve()
            },
        },
    ],
    accountName: [
        {
            validator: (_rule: any, value: string) => {
                if (isNotSet(value)) {
                    return Promise.reject(i18n.t("validation:required"))
                }

                if (value !== value.trim()) {
                    return Promise.reject(new Error(i18n.t("validation:string_white_space_start_or_end")))
                }

                if (textMultipleWhiteSpace.test(value)) {
                    return Promise.reject(new Error(i18n.t("validation:string_white_space_duplicate")))
                }

                return Promise.resolve()
            },
        },
        allowlistCharValidator,
    ],
})

export default ExpenseAccountRules
