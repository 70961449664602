import { Expense, ExpenseKindEnum, ExpenseRequestTypeEnum, PriceSourceEnum } from "@finway-group/shared/lib/models"
import { Col, Row } from "antd"
import { FormInstance } from "antd/lib/form"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import CurrencyInput from "Components/currencyInput"
import FormAddExpenseItemRow from "Components/form/formAddExpenseItemRow"
import FormAdditionalExpense from "Components/form/formAdditionalExpense"
import FormItemCurrency from "Components/form/formItemCurrency"
import FormTaxRateSelector from "Components/form/formTaxRateSelector"
import { ExpenseRules } from "Components/forms/rules"
import { ExpenseData } from "Components/modals/expenseCreateForm.context"
import { NO_TAX_RATE } from "Shared/config/consts"
import { useExpenseHook } from "Shared/hooks/expense.hooks"
import { useTaxes } from "Shared/hooks/tax.hooks"
import { doTotalAndInlineAmountsMismatch, isHospitalityExpense } from "Shared/utils/expense.utils"
import { getTaxLabel } from "Shared/utils/helper.utils"
import { getPopupAnchor } from "Shared/utils/popup.utils"

import { HoverableInfo } from "../reimbursementForms/auxilliary/HoverableInfo"

interface TotalAmountFormInterface {
    expense: Expense
    updateExpense(data: ExpenseData): void
    isLinked: boolean
    setIsLinked(isLinked: boolean): void
    isNew: boolean
    type?: ExpenseRequestTypeEnum
    expenseForm: FormInstance
    isForcedAmountSync?: boolean // Forces the total amount form to not be adjustable, and have to adjust through items
}

const TotalAmountForm: React.FC<TotalAmountFormInterface> = ({ expense, updateExpense, isLinked, setIsLinked, isNew, type, expenseForm, isForcedAmountSync = false }) => {
    const { t } = useTranslation()
    const { totalNetPrice, name } = ExpenseRules()

    const taxValues = useTaxes()

    const [priceSource, setPriceSource] = useState(PriceSourceEnum.GROSS_PRICE)

    const amountsMismatch = doTotalAndInlineAmountsMismatch(expense, taxValues, isNew)
    const isHospitality = isHospitalityExpense(expense)
    const {
        onAddItem,
        onSelect,
        onTaxRateUpdate,
        onTaxPriceUpdate,
        onNetPriceUpdate,
        onTaxRateSplitUpdate,
        onTotalNetPriceUpdate,
        onTotalTaxPriceUpdate,
        onTotalGrossPriceUpdate,
        onGrossPriceUpdate,
        onNameChange,
        setShouldRecalculateAmounts,
    } = useExpenseHook(expense, expenseForm, updateExpense, isLinked, setIsLinked, isForcedAmountSync)

    const isSubscription = expense.kind === ExpenseKindEnum.SUBSCRIPTION
    const rules = type === ExpenseRequestTypeEnum.REIMBURSEMENT || type === ExpenseRequestTypeEnum.INVOICE ? [] : totalNetPrice

    return (
        <>
            <Row gutter={[16, 16]} align="top">
                <Col span={6} data-testid="totalNetPrice">
                    <FormItemCurrency currency={expense.currency} name="totalNetPrice" label={t("input:request.total_net_amount")} rules={rules} required>
                        <CurrencyInput
                            currency={expense.currency}
                            value={expense.totalNetPrice}
                            onChange={(value) => onTotalNetPriceUpdate.callback(value)}
                            onKeyDown={() => {
                                setPriceSource(PriceSourceEnum.NET_PRICE)
                            }}
                            isDisabled={isForcedAmountSync}
                        />
                    </FormItemCurrency>
                </Col>
                <FormTaxRateSelector
                    elem={{
                        name: ["taxRate", "_id"],
                        label: t("input:request.tax"),
                        key: "taxRate",
                        isRequired: expense.isAtLeastApproved(),
                        className: "w-full px-0 text-xs md:text-base no-padding-select",
                        getPopupAnchor: getPopupAnchor(),
                        onChange: (value) => onTaxRateUpdate(String(value), priceSource),
                        isDisabled: isForcedAmountSync,
                        initialValue: !expense.taxRate ? NO_TAX_RATE : undefined,
                        taxValues,
                        value: expense.taxRate?._id,
                    }}
                    span={5}
                />
                <Col span={5} data-testid="totalTaxPrice">
                    <FormItemCurrency currency={expense.currency} name="totalTaxPrice" label={t("input:request.total_tax")}>
                        <CurrencyInput
                            id="totalTax"
                            currency={expense.currency}
                            value={expense.totalTaxPrice}
                            onChange={(value) => onTotalTaxPriceUpdate(value, PriceSourceEnum.NET_PRICE)}
                            isDisabled={isForcedAmountSync}
                        />
                    </FormItemCurrency>
                </Col>
                <Col span={1} />
                <Col span={5} data-testid="totalGrossPrice">
                    <FormItemCurrency
                        currency={expense.currency}
                        name="totalGrossPrice"
                        label={
                            <>
                                {t("input:request.total_gross_amount")}
                                {isHospitality && <HoverableInfo message={t("tooltips:hospitality.total_calculated_amount")} type="info" />}
                            </>
                        }
                        required
                    >
                        <CurrencyInput
                            currency={expense.currency}
                            value={0}
                            onChange={(value) => onTotalGrossPriceUpdate.callback(value)}
                            onKeyDown={() => {
                                setPriceSource(PriceSourceEnum.GROSS_PRICE)
                            }}
                            isDisabled={isForcedAmountSync}
                        />
                    </FormItemCurrency>
                </Col>
                <div className="self-center">
                    <FormAddExpenseItemRow
                        elem={{
                            isHospitality,
                            expense,
                            isSubscription,
                            onAddItem,
                            isLinked,
                            onSelect,
                        }}
                        span={1}
                    />
                </div>
            </Row>
            <FormAdditionalExpense
                elem={{
                    expense,
                    taxValues,
                    isSubscription,
                    name,
                    onNameChange,
                    onNetPriceUpdate,
                    onTaxRateSplitUpdate,
                    onTaxPriceUpdate,
                    onGrossPriceUpdate,
                    totalNetPrice,
                    getPopupAnchor,
                    isAmountsMismatch: amountsMismatch,
                    updateExpense,
                    setShouldRecalculateAmounts,
                    isHospitality,
                }}
            />
        </>
    )
}

export default React.memo(TotalAmountForm)
