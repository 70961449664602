import { Company } from "@finway-group/shared/lib/models"
import { Col, Row, Switch } from "antd"
import Form, { FormInstance } from "antd/lib/form"
import { Store } from "antd/lib/form/interface"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"

import { FINWAY_ADMIN_EMAIL, isDemo } from "Shared/config/consts"
import { useLoggedInEmployeeProfile } from "Shared/hooks/user.hooks"

interface BankingAndCardFormInterface {
    formInstance: FormInstance
    onSubmit: (values: Store) => void
    company: Company
}

const BankingAndCardForm: React.FC<BankingAndCardFormInterface> = ({ formInstance, onSubmit, company }) => {
    const { t } = useTranslation()
    const loggedInUser = useLoggedInEmployeeProfile()

    useEffect(() => {
        if (company) {
            formInstance.setFieldsValue({
                ...company,
            })
        }

        return () => {
            formInstance.resetFields()
        }
    }, [])

    const onFinish = (values: Store) => onSubmit({ ...values })

    return (
        <Form form={formInstance} layout="vertical" onFinish={onFinish} autoComplete="off">
            <Row gutter={[16, 16]}>
                <Col span={12}>
                    <Form.Item
                        label={t("input:company_settings.card_feature")}
                        name="cardsEnabled"
                        valuePropName="checked"
                        extra={t("info:cards.ask_support_to_enable_card_program")}
                    >
                        <Switch disabled={isDemo || loggedInUser.email !== FINWAY_ADMIN_EMAIL} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={t("input:company_settings.multi_currency_wallet")}
                        name="multiCurrencyWalletEnabled"
                        valuePropName="checked"
                        extra={t("info:cards.ask_support_to_enable_multi_currency_wallet")}
                    >
                        <Switch disabled={isDemo || loggedInUser.email !== FINWAY_ADMIN_EMAIL} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[16, 16]}>
                <Col span={12}>
                    <Form.Item label={t("input:company_settings.liquidity_management_feature")} name="liquidityManagementEnabled" valuePropName="checked" initialValue={true}>
                        <Switch />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={t("input:company_settings.auto_match_feature")} name="autoMatchEnabled" valuePropName="checked" initialValue={true}>
                        <Switch />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[16, 16]}>
                <Col span={12}>
                    <Form.Item label={t("input:company_settings.allow_banking_payment_auto_matching")} name="bankingPaymentAutoMatchEnabled" valuePropName="checked">
                        <Switch />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    )
}

export default BankingAndCardForm
