import { ApproverData, Company, IntegrationTypeEnum, User } from "@finway-group/shared/lib/models"
import UserCompanyProfileInterface from "@finway-group/shared/lib/models/user/userCompanyProfileInterface"
import { Col, Form, Row, Select, Tooltip } from "antd"
import { FormInstance, FormProps } from "antd/lib/form"
import { SelectValue } from "antd/lib/select"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import UserImage from "Components/UserImage"
import { DebounceSelect, SelectOption } from "Components/select/select.debounce"
import { NOT_SET_VALUE, SORT_ASC_BY_FIRST_NAME_QUERY_STRING } from "Shared/config/consts"
import { useCompanies, useCompany } from "Shared/hooks/company.hooks"
import { useRolesForSelect } from "Shared/hooks/role.hooks"
import { useIntegrationConfigurations } from "Shared/queries/integration.queries"
import { CompanyService, NotificationService, TeamService, UserService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { getUserRoleName } from "Shared/utils/getter.utils"
import { isEmptyObject } from "Shared/utils/helper.utils"
import { getPopupAnchor } from "Shared/utils/popup.utils"

import UserCompanyProfileRules from "../rules/userCompanyProfile.form"

interface UserCompanyProfileFormInterface extends FormProps {
    isShowing?: boolean
    isEditable?: boolean
    isCompanyEditable?: boolean
    user?: User
    companyProfile?: UserCompanyProfileInterface
    onHide?: () => void
    formInstance?: FormInstance<UserCompanyProfileInterface>
    availableCompanies?: Array<Company>
}

export const UserCompanyProfileForm: React.FC<UserCompanyProfileFormInterface> = ({
    isShowing = true,
    companyProfile,
    user,
    isCompanyEditable = false,
    isEditable = true,
    onHide,
    formInstance = Form.useForm<UserCompanyProfileInterface>()[0],
    availableCompanies = [],
}) => {
    const { t } = useTranslation()
    const roles = useRolesForSelect({ id: companyProfile?.roleId })

    const companies = useCompanies()
    const currentCompanyId = useCompany()?._id
    const isAlreadyInCurrentCompany = user?.companyProfiles?.map(({ companyId }) => companyId).includes(currentCompanyId)

    const [company, setCompany] = useState<undefined | SelectValue>(undefined)
    const [team, setTeam] = useState<undefined | SelectValue>(undefined)

    const isCompanyDependentSelectDisabled = !isEditable || (isCompanyEditable && !company)
    const rules = UserCompanyProfileRules()
    const integrations = useIntegrationConfigurations()
    const personioIntegration = integrations.data?.[IntegrationTypeEnum.PERSONIO]
    const isPersonioUser = Boolean(user?.source?.id && user?.source?.system === IntegrationTypeEnum.PERSONIO)
    const isPersonioIntegrationEnabled = personioIntegration?.isEnabled ?? false
    const isPersonioEmployeeSyncEnabled = personioIntegration?.attributes?.enableEmployeeSync ?? false
    const isTeamSyncEnabled = personioIntegration?.attributes?.enableTeamSync ?? false
    const isFetchingDataFromPersonio = integrations.isFetching && !isCompanyEditable
    const personioSubCompany = user?.source?.subcompany?.replace(/ /g, "").toLowerCase()
    const currentCompanyName =
        company &&
        companies
            .find((one) => one._id === company)
            ?.name.replace(/\s/g, "")
            .toLowerCase()
    const isCompanyMatchPersonioSubCompany = personioSubCompany === currentCompanyName || companies.length === 1
    const isBaseSyncConditionsMet = !isCompanyEditable && isPersonioIntegrationEnabled && isPersonioUser && isCompanyMatchPersonioSubCompany
    const isValidEmployeeSyncCondition = isBaseSyncConditionsMet && isPersonioEmployeeSyncEnabled
    const isValidTeamSyncCondition = isBaseSyncConditionsMet && isTeamSyncEnabled

    useEffect(() => {
        const isEditingExistingCompanyProfile = companyProfile && !isEmptyObject(companyProfile)
        if (isEditingExistingCompanyProfile) {
            formInstance.setFieldsValue(companyProfile)
            setCompany(companyProfile?.companyId)
            setTeam(companyProfile?.team)
        } else {
            const preselectedCompanyId = isAlreadyInCurrentCompany ? availableCompanies[0]?._id : currentCompanyId
            formInstance.setFieldsValue({ companyId: preselectedCompanyId })
            setCompany(preselectedCompanyId)
        }
    }, [companyProfile, formInstance])

    const onChangeCompany = (value: SelectValue) => {
        setCompany(value)
        setTeam(undefined)
        formInstance.setFieldsValue({ roleId: undefined, team: undefined, superior: undefined })
    }

    const onChangeTeam = (value: SelectValue) => {
        setTeam(value)
        formInstance.setFieldsValue({ superior: undefined })
    }

    const fetchSuperiorOptions = async ({ search }: any) => {
        let superiors: Array<SelectOption> = []

        if (team && company) {
            try {
                await CompanyService.getPossibleApprovers({
                    userId: user?.id,
                    companyId: company?.toString(),
                    teamId: team?.toString(),
                    search,
                    sortQueryString: SORT_ASC_BY_FIRST_NAME_QUERY_STRING,
                }).then(async (users: Array<ApproverData>) => {
                    const currentlySelectedSuperiorId = formInstance.getFieldValue("superior")
                    const isCurrentlySelectedSuperiorPossibleApprover = currentlySelectedSuperiorId && users.some(({ _id }) => _id === currentlySelectedSuperiorId)

                    if (isCurrentlySelectedSuperiorPossibleApprover === false) {
                        try {
                            const currentSuperior = await UserService.fetchUserById(currentlySelectedSuperiorId)
                            users.push({
                                _id: currentSuperior._id,
                                firstName: currentSuperior.firstName,
                                lastName: currentSuperior.lastName,
                                img: currentSuperior.img,
                                isDisabled: true,
                            })
                        } catch (error) {
                            NotificationService.send(NotificationTypeEnum.ERROR, t("error:error"), t("error:profile.edit.message"))
                            if (onHide) onHide()
                        }
                    }

                    const noValueSetOption = {
                        label: (
                            <div className="flex items-center">
                                <span>{t("missing:employee.superior")}</span>
                            </div>
                        ),
                        value: NOT_SET_VALUE,
                    }

                    const superiorUserOptions = users.map((user: any) => ({
                        label: (
                            <div className="flex items-center" id={`approver-option-${user._id}`}>
                                <UserImage user={user} />
                                <span>{`${user.firstName} ${user.lastName}`}</span>
                            </div>
                        ),
                        value: user._id,
                        disabled: user.isDisabled,
                    }))

                    superiors = companyProfile?.superior ? [noValueSetOption, ...superiorUserOptions] : superiorUserOptions
                })
            } catch (error) {
                console.error("Failed to load superiors: ", error)
            }
        }

        return superiors
    }

    if (!isShowing) return null

    return (
        <div>
            <div className="p-15">
                {isCompanyEditable && (
                    <Row className="mb-5">
                        <Col span={24}>
                            <Form.Item name="companyId" label={t("input:employee.company")} required shouldUpdate rules={rules.company}>
                                <Select onChange={onChangeCompany} disabled={!isEditable} getPopupContainer={getPopupAnchor()}>
                                    {availableCompanies.map(({ _id, name }) => (
                                        <Select.Option key={_id} value={_id}>
                                            {name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                )}
                <Row className="mb-5">
                    <Col span={24}>
                        <Form.Item name="roleId" data-testid="roleSelection" label={t("input:employee.role")} required shouldUpdate rules={rules.role}>
                            <Select disabled={isCompanyDependentSelectDisabled} getPopupContainer={getPopupAnchor()}>
                                {roles.map((role) => (
                                    <Select.Option key={role._id} value={role._id} disabled={role.deleted}>
                                        {getUserRoleName(role)}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row className="mb-5">
                    <Col span={24}>
                        <Tooltip title={isValidTeamSyncCondition && !integrations.isFetching ? t("input:employee.personioTooltip") : undefined}>
                            <Form.Item name="team" label={t("input:employee.team")} required shouldUpdate rules={rules.team}>
                                <DebounceSelect
                                    loading={isFetchingDataFromPersonio}
                                    disabled={isCompanyDependentSelectDisabled || isValidTeamSyncCondition || isFetchingDataFromPersonio}
                                    onChange={onChangeTeam}
                                    value={formInstance.getFieldValue("team")}
                                    className="w-full"
                                    fetchOptionsDependency={company} // To make sure that we refetch the fetchOptions when the company is changed.
                                    fetchOptions={async ({ search }) =>
                                        !isCompanyDependentSelectDisabled
                                            ? TeamService.fetchTeams({ companyId: formInstance.getFieldValue("companyId"), search }).then((teams) =>
                                                  teams
                                                      .filter((elem) => !elem.deleted || elem._id === team)
                                                      .map((team) => ({ label: team.teamName, value: team._id, disabled: team.deleted })),
                                              )
                                            : []
                                    }
                                />
                            </Form.Item>
                        </Tooltip>
                    </Col>
                </Row>

                <Row className="mb-5">
                    <Col span={24}>
                        <Tooltip title={isValidEmployeeSyncCondition && !integrations.isFetching ? t("input:employee.personioTooltip") : undefined}>
                            <Form.Item name="superior" label={t("input:employee.superior")} shouldUpdate style={{ marginBottom: "0px" }}>
                                <DebounceSelect
                                    loading={isFetchingDataFromPersonio}
                                    disabled={isCompanyDependentSelectDisabled || isValidEmployeeSyncCondition || isFetchingDataFromPersonio}
                                    value={formInstance.getFieldValue("superior")}
                                    className="w-full"
                                    fetchOptionsDependency={team} // To make sure that we refetch the fetchOptions when team is changed.
                                    fetchOptions={fetchSuperiorOptions}
                                />
                            </Form.Item>
                        </Tooltip>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default UserCompanyProfileForm
