import { CloseOutlined, EditOutlined, ExclamationCircleOutlined } from "@ant-design/icons"
import { vendorRecommendations } from "@finway-group/shared/lib/consts"
import { supportedInvoiceFormats } from "@finway-group/shared/lib/consts/common"
import { CollectionNameEnum, FileExtEnum, RightEnum, UploadKindEnum, Vendor } from "@finway-group/shared/lib/models"
import { Alert, Button, Card, Modal, Row, Space, Spin, Tabs, Tag, Tooltip, Upload } from "antd"
import React, { useEffect, useState } from "react"
import { AlertTriangle, X as CancelIcon, Check as CheckIcon, Edit as EditIcon, Upload as UploadIcon } from "react-feather"
import { Helmet } from "react-helmet"
import { useTranslation } from "react-i18next"
import { formatPhoneNumberIntl } from "react-phone-number-input"
import { useDispatch } from "react-redux"
import { useHistory, useParams } from "react-router-dom"

import PageCustomHeader from "Components/PageCustomHeader"
import VendorImage from "Components/VendorImage"
import CommentContainer from "Components/comment/comment.container"
import FileViewerCached from "Components/fileViewer/fileViewerCached"
import GroupedExpenses from "Components/groupedExpenses"
import VendorFormModal from "Components/modals/vendor.modal"
import VendorRuleModal from "Components/modals/vendorRule.modal"
import { API_URL } from "Shared/config/consts"
import { useCompany } from "Shared/hooks/company.hooks"
import { useCostCenter2ById, useCostCenters2 } from "Shared/hooks/costCenter2.hooks"
import { useCostCenterById } from "Shared/hooks/costCenter.hooks"
import { useExpenseAccountById, useExpenseAccounts } from "Shared/hooks/expenseAccount.hooks"
import { useLoggedInEmployeeProfile } from "Shared/hooks/user.hooks"
import { useVendor } from "Shared/hooks/vendor.hooks"
import { AuthzService, FileService, NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { ThunkDispatchResult } from "Shared/store"
import { approveVendorStatus, deleteVendor, fetchOneVendor, updateVendor, updateVendorContracts } from "Shared/store/actions/vendor/vendorActions"
import { isPaymentFlowInfoDisabled } from "Shared/utils/expense.utils"
import { isFileExtensionMatching } from "Shared/utils/file.utils"
import { getIBANPrintFormat, getRecommendationTagColour, getTooltipPopupContainer, isCreditorNumberDATEVCompatible } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

const { Dragger } = Upload
const { confirm } = Modal
const { TabPane } = Tabs

interface IMatchParams {
    id: string
}

const VendorDetail: React.FC<{}> = () => {
    const { t } = useTranslation()
    const history = useHistory()
    const { id } = useParams<IMatchParams>()

    const dispatch: ThunkDispatchResult = useDispatch()

    // TODO: change after rules are added to shared
    const vendor = useVendor()
    const costCenter = useCostCenterById(vendor.rule?.costCenter, true)
    const costCenter2 = useCostCenter2ById(vendor.rule?.costCenter2)
    const costCenters2 = useCostCenters2()
    const expenseAccount = useExpenseAccountById(vendor.rule?.expenseAccount)
    const expenseAccounts = useExpenseAccounts()
    const company = useCompany()

    const loggedInUser = useLoggedInEmployeeProfile()

    const [isUploading, setIsUploading] = useStateIfMounted(false)
    const [isModalShowing, setIsModalShowing] = useState(false)
    const [isRuleModalShowing, setIsRuleModalShowing] = useState(false)
    // TODO: check if we can remove this or implement
    const [fileList] = useState<Array<any>>([])
    const [isUpdatingVendorStatus, setIsUpdatingVendorStatus] = useStateIfMounted(false)

    // Rights
    const canViewPayments = AuthzService.isRightGrantedForLoggedInUser(RightEnum.VENDOR_CONTRACT__ALL__READ)
    const canEditContracts = !vendor.deleted && AuthzService.isRightGrantedForLoggedInUser(RightEnum.VENDOR_CONTRACT__ALL__UPDATE)
    const canViewContracts = AuthzService.isRightGrantedForLoggedInUser(RightEnum.VENDOR_CONTRACT__ALL__READ)

    const discountRule = vendor.rule?.discounts?.[0] // In anticipation of multiple discounts

    useEffect(() => {
        dispatch(fetchOneVendor(id)).catch(() => {
            history.push("/vendors")
        })
    }, [id, dispatch, history])

    const approveOrRejectVendor = async (isVendorApproved: boolean) => {
        setIsUpdatingVendorStatus(true)
        try {
            if (isVendorApproved) {
                await dispatch(approveVendorStatus(vendor.id))
                NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:vendor.approved.title"), t("notification:vendor.approved.message"))
            } else {
                await dispatch(deleteVendor(vendor.id))
                NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:vendor.rejected.title"), t("notification:vendor.rejected.message"))
                history.push({ pathname: "/vendors" })
            }
        } catch {
            NotificationService.showErrorNotificationBasedOnResponseError(t("error:update.title"), t("error:update.message"))
        } finally {
            setIsUpdatingVendorStatus(false)
        }
    }

    const handleDelete = () => {
        if (vendor)
            confirm({
                title: t("confirm:vendor.delete.title"),
                icon: <ExclamationCircleOutlined />,
                content: t("confirm:vendor.delete.message"),
                okText: t("confirm:vendor.delete.confirm"),
                cancelText: t("confirm:vendor.delete.cancel"),
                onOk() {
                    return new Promise<void>((resolve) => {
                        updateVendor(
                            vendor.id,
                            new Vendor({ ...vendor, deleted: true, dateDeleted: Date.now() }),
                        )(dispatch)
                            .then(() => {
                                NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:vendor.deleted.title"), t("notification:vendor.deleted.message"))
                            })
                            .then(() => {
                                history.push({ pathname: "/vendors" })
                            })
                            .catch((err: Error) => {
                                NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:vendor.delete.title"))
                            })
                            .finally(() => {
                                resolve()
                            })
                    })
                },
                onCancel() {},
            })
    }

    /**
     * @todo fix typings, property file doesnt have a storageName property
     * @param file any for now
     * @param kind
     */
    const doFileUpload = (file: any) => {
        if (!FileService.checkFileSizeAndHandleError(file) || !vendor) return

        setIsUploading(true)

        FileService.upload(file, UploadKindEnum.VENDOR_CONTRACT)
            .then((data) => {
                setIsUploading(false)
                vendor.contracts.push(data.url)
                // add a field to the file so we can identify it
                // during delete if the remove button is clicked
                file.storageName = data.url

                updateVendorContracts(
                    vendor.id,
                    vendor.contracts,
                )(dispatch)
                    .then(() => {
                        NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:file.uploaded.title"), t("notification:file.uploaded.message"))
                    })
                    .catch((err: Error) => {
                        NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:error"))
                    })
            })
            .catch((error: Error) => {
                setIsUploading(false)
                NotificationService.showErrorNotificationBasedOnResponseError(error, t("error:file.upload.title"))
            })
    }

    const handleRemoveTab = (targetKey: string) => {
        if (!vendor) return
        confirm({
            title: t("confirm:file.delete_from_server.title"),
            icon: <ExclamationCircleOutlined />,
            content: t("confirm:file.delete_from_server.message"),
            cancelText: t("confirm:file.delete_from_server.cancel"),
            okText: t("confirm:file.delete_from_server.confirm"),
            onOk() {
                return new Promise<boolean>((resolve, reject) => {
                    vendor.contracts.splice(Number(targetKey), 1)

                    updateVendorContracts(
                        vendor.id,
                        vendor.contracts,
                    )(dispatch)
                        .then(() => {
                            resolve(true)
                            NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:file.deleted.title"), t("notification:file.deleted.message"))
                        })
                        .catch((error) => {
                            reject(error)
                            NotificationService.send(NotificationTypeEnum.ERROR, t("error:file.delete.title"), t("error:file.delete.message"))
                        })
                }).catch(() => {}) // no-op ignore error
            },
            onCancel() {},
        })
    }

    const handleEditTab = (targetKey: string, action: "add" | "remove") => action === "remove" && handleRemoveTab(targetKey)
    const openRuleModal = () => setIsRuleModalShowing(true)

    const renderStreetAddress = () => {
        if (vendor.streetName && vendor.streetNumber) return `${vendor.streetName} ${vendor.streetNumber}`
        if (vendor.streetName) return vendor.streetName
        if (vendor.streetNumber) return vendor.streetNumber
        return "-"
    }

    const getVendorRuleButton = () => {
        if (vendor.deleted) return <></>
        return (
            <Button size="small" className="btn-default block w-full" onClick={openRuleModal}>
                <span>{t("action:set")}</span>
            </Button>
        )
    }

    const getEditRulesIcon = () => {
        if (vendor.deleted) return <></>
        return <EditIcon className="ml-14 cursor-pointer w-14 min-w-14" onClick={openRuleModal} />
    }

    const getDiscountLabel = () => {
        if (!discountRule) {
            return "-"
        }
        return `${discountRule.percentage}% - ${t("label:vendor_discount.days", { count: discountRule.period })}`
    }

    const getContactDetails = () => (
        <ul className="description-list">
            <li>
                <span className="title">{t("label:street_address")}</span>
                <span className="text max-w-200">{renderStreetAddress()}</span>
            </li>
            <li>
                <span className="title">{t("label:city")}</span>
                <span className="text truncate max-w-200">{vendor.city || "-"}</span>
            </li>
            <li>
                <span className="title">{t("label:zip")}</span>
                <span className="text truncate max-w-200">{vendor.zip || "-"}</span>
            </li>
            <li>
                <span className="title">{t("label:country")}</span>
                <span className="text truncate max-w-200">{vendor.country || "-"}</span>
            </li>
            <li>
                <span className="title">{t("label:contact_person")}</span>
                <span className="text truncate max-w-200">{vendor.contactPersonName || "-"}</span>
            </li>
            <li>
                <span className="title">{t("label:email")}</span>
                <span className="text truncate max-w-200">{vendor.contactPersonEmail || "-"}</span>
            </li>
            <li>
                <span className="title">{t("label:phone")}</span>
                <span className="text truncate max-w-200">{formatPhoneNumberIntl(vendor.contactPersonPhone) || "-"}</span>
            </li>
        </ul>
    )

    const getBusinessDetails = () => (
        <ul className="description-list overflow-hidden">
            <li>
                <span className="title min-w-100">{t("label:name")}</span>
                <span className="text truncate">{vendor.name}</span>
            </li>
            <li>
                <span className="title min-w-100">{t("label:description")}</span>
                <span className="text truncate">{vendor.description || "-"}</span>
            </li>

            <li>
                <span className="title min-w-100">{t("label:creditor_number")}</span>
                <span className="text truncate">
                    {vendor.creditorNumber}
                    {vendor.creditorNumber && !isCreditorNumberDATEVCompatible(String(vendor.creditorNumber)) && (
                        <span className="relative" style={{ bottom: 3 }}>
                            <Tooltip getPopupContainer={getTooltipPopupContainer} title={t("tooltips:vendor.incompatible_creditor_number")} overlayStyle={{ width: "200px" }}>
                                <ExclamationCircleOutlined className="ml-6" />
                            </Tooltip>
                        </span>
                    )}
                </span>
            </li>
            <li>
                <span className="title">{t("label:recommendation")}</span>
                <span className="text">
                    <Tag className={`min-w-80" ${getRecommendationTagColour(vendorRecommendations[vendor.recommendation])}`}>
                        <span className="">{t(`label:recommendations.${vendorRecommendations[vendor.recommendation].toLowerCase()}`)}</span>
                    </Tag>
                </span>
            </li>
            <li>
                {/* TODO replace iban class w/ min-w-[20%] after upgrading to tailwind v3 https://tailwindcss.com/docs/min-width#arbitrary-values */}
                <span className="title iban">{t("label:iban")}</span>
                <span className="text-right">{vendor.iban ? getIBANPrintFormat(vendor.iban) : "-"}</span>
            </li>
            <li>
                <span className="title">{t("label:bic")}</span>
                <span className="text truncate max-w-200">{vendor.bic || "-"}</span>
            </li>
            <li>
                <span className="title">{t("label:tax_number")}</span>
                <span className="text truncate max-w-200">{vendor.taxNumber || "-"}</span>
            </li>
            <li>
                <span className="title">{t("label:vat_number")}</span>
                <span className="text truncate max-w-200">{vendor.vatNumber || "-"}</span>
            </li>

            {!vendor.deleted && (
                <li>
                    <span className="grid-breakpoints hidden title xs:block xs:w-1/2 sm:hidden xl:block xl:w-1/2">{t("label:contracts")}</span>
                    <span className="grid-breakpoints w-full xs:w-1/2 sm:w-full xl:w-1/2 ant-btn p-0">
                        <span className="block">
                            <Upload
                                className="custom-upload"
                                action={`${API_URL}/files`}
                                headers={{ Authorization: `Bearer ${localStorage.accessToken}` }}
                                multiple
                                listType="text"
                                accept={supportedInvoiceFormats.join(",")}
                                fileList={fileList}
                                showUploadList={true}
                                customRequest={(req) => {
                                    doFileUpload(req.file)
                                }}
                                disabled={!canEditContracts}
                            >
                                <Button size="small" loading={isUploading} disabled={!canEditContracts} className="btn-default block w-full">
                                    <UploadIcon />
                                    <span>{t("action:upload_contract")}</span>
                                </Button>
                            </Upload>
                        </span>
                    </span>
                </li>
            )}
        </ul>
    )

    const getRules = () => (
        <ul className="description-list overflow-hidden">
            <li>
                <span className="title min-w-100">{t("label:cost_center")}</span>
                <div className="flex text truncate min-w-100 items-center justify-end">
                    {AuthzService.isRightGrantedForLoggedInUser(RightEnum.VENDOR__ALL__UPDATE) && vendor.isApproved ? (
                        costCenter ? (
                            <>
                                <span className="flex overflow-x-auto">
                                    <span className="truncate">{costCenter.name}</span>
                                </span>
                                {getEditRulesIcon()}
                            </>
                        ) : (
                            getVendorRuleButton()
                        )
                    ) : (
                        <span className="flex overflow-x-auto">
                            <span className="truncate">{costCenter?.name || "-"}</span>
                        </span>
                    )}
                </div>
            </li>
            {costCenters2.length > 0 && (
                <li>
                    <span className="title min-w-100">{t("label:cost_center_2")}</span>
                    <div className="flex text truncate min-w-100 items-center justify-end">
                        {AuthzService.isRightGrantedForLoggedInUser(RightEnum.VENDOR__ALL__UPDATE) && vendor.isApproved ? (
                            costCenter2 ? (
                                <>
                                    <span className="flex overflow-x-auto">
                                        <span className="truncate">{costCenter2.name}</span>
                                    </span>
                                    {getEditRulesIcon()}
                                </>
                            ) : (
                                getVendorRuleButton()
                            )
                        ) : (
                            <span className="flex flex-row overflow-x-auto">
                                <span className="truncate">{costCenter2?.name || "-"}</span>
                            </span>
                        )}
                    </div>
                </li>
            )}
            {expenseAccounts.length > 0 && (
                <li>
                    <span className="title min-w-140">{t("label:expense_account")}</span>
                    <div className="flex text truncate min-w-100 items-center justify-end">
                        {AuthzService.isRightGrantedForLoggedInUser(RightEnum.VENDOR__ALL__UPDATE) && vendor.isApproved ? (
                            expenseAccount ? (
                                <>
                                    <span className="flex flex-row overflow-x-auto">
                                        <span className="truncate">
                                            {expenseAccount.accountCode} - {expenseAccount.accountName}
                                        </span>
                                    </span>
                                    {getEditRulesIcon()}
                                </>
                            ) : (
                                getVendorRuleButton()
                            )
                        ) : (
                            <span className="flex flex-row overflow-x-auto">
                                <span className="truncate">{(expenseAccount && `${expenseAccount.accountCode} - ${expenseAccount.accountName}`) || "-"}</span>
                            </span>
                        )}
                    </div>
                </li>
            )}
            <li>
                <span className="title min-w-140">{t("label:payment_flow_information.title")}</span>
                <div className="flex text truncate min-w-100 items-center justify-end">
                    {AuthzService.isRightGrantedForLoggedInUser(RightEnum.VENDOR__ALL__UPDATE) && vendor.isApproved ? (
                        vendor.rule?.expensePaymentFlowInformation ? (
                            <>
                                {isPaymentFlowInfoDisabled(vendor.rule?.expensePaymentFlowInformation, company) && (
                                    <span className="relative" style={{ bottom: 3 }}>
                                        <Tooltip
                                            getPopupContainer={getTooltipPopupContainer}
                                            title={t("tooltips:vendor.vendor_rule_inactive_due_to_settings")}
                                            overlayStyle={{ width: "200px" }}
                                        >
                                            <ExclamationCircleOutlined className="mr-6" style={{ color: "#ffc069" }} />
                                        </Tooltip>
                                    </span>
                                )}
                                <span className="flex flex-row overflow-x-auto">{t(`label:payment_flow_information.${vendor.rule?.expensePaymentFlowInformation}`)}</span>
                                {getEditRulesIcon()}
                            </>
                        ) : (
                            getVendorRuleButton()
                        )
                    ) : (
                        <span className="flex flex-row overflow-x-auto">
                            {vendor.rule?.expensePaymentFlowInformation ? t(`label:payment_flow_information.${vendor.rule?.expensePaymentFlowInformation}`) : "-"}
                        </span>
                    )}
                </div>
            </li>
            <li>
                <span className="title min-w-100">{t("label:payment_term_desc")}</span>
                <div className="flex text truncate min-w-100 items-center justify-end">
                    {AuthzService.isRightGrantedForLoggedInUser(RightEnum.VENDOR__ALL__UPDATE) && vendor.isApproved ? (
                        vendor.rule?.paymentTerm ? (
                            <>
                                <span className="flex flex-row overflow-x-auto">{vendor.rule?.paymentTerm}</span>
                                {getEditRulesIcon()}
                            </>
                        ) : (
                            getVendorRuleButton()
                        )
                    ) : (
                        <span className="flex flex-row overflow-x-auto">{vendor.rule?.paymentTerm || "-"}</span>
                    )}
                </div>
            </li>
            <li>
                <span className="title min-w-100">{t("label:vendor_discount.discount")}</span>
                <div className="flex text truncate min-w-100 items-center justify-end">
                    {AuthzService.isRightGrantedForLoggedInUser(RightEnum.VENDOR__ALL__UPDATE) && vendor.isApproved ? (
                        discountRule ? (
                            <>
                                <span className="flex flex-row overflow-x-auto">{getDiscountLabel()} </span>
                                {getEditRulesIcon()}
                            </>
                        ) : (
                            getVendorRuleButton()
                        )
                    ) : (
                        <span className="flex flex-row overflow-x-auto">{getDiscountLabel()}</span>
                    )}
                </div>
            </li>
        </ul>
    )

    return (
        <Spin spinning={!vendor}>
            {vendor?.id === id && (
                <>
                    <Helmet>
                        <title>{vendor.name}</title>
                    </Helmet>
                    {vendor.deleted && (
                        <Alert
                            className="mb-20"
                            message={t("notification:vendor.already_deleted.title")}
                            type="error"
                            description={t("notification:vendor.already_deleted.message")}
                            closable={false}
                            showIcon={true}
                        />
                    )}

                    <PageCustomHeader
                        title={
                            <div className="flex max-w-200 xs:max-w-300 sm:max-w-450 md:max-w-3xl">
                                <div>
                                    <VendorImage size={55} vendor={vendor} />
                                </div>
                                <div className="flex flex-col ml-8 mt-2 overflow-x-auto no-scrollbar">
                                    <div className="flex items-center">
                                        <h1 className="mb-3 overflow-x-auto no-scrollbar">{vendor.name}</h1>
                                        {!vendor?.isApproved && (
                                            <Tooltip getPopupContainer={getTooltipPopupContainer} title={t("info:pending_vendor")}>
                                                <AlertTriangle fill="#FBAC3B" size={26} stroke="#FFFFFF" className="ml-4" />
                                            </Tooltip>
                                        )}
                                    </div>
                                    <p className="leading-tight overflow-x-auto no-scrollbar">{vendor.description}</p>
                                </div>
                            </div>
                        }
                        actionButtons={
                            !vendor.deleted && vendor.isApproved ? (
                                <>
                                    {AuthzService.isRightGrantedForLoggedInUser(RightEnum.VENDOR__ALL__UPDATE) && (
                                        <Button className="btn-default" onClick={handleDelete} icon={<CloseOutlined />}>
                                            <span>{t("action:delete")}</span>
                                        </Button>
                                    )}
                                    {(AuthzService.isRightGrantedForLoggedInUser(RightEnum.VENDOR__ALL__UPDATE) || loggedInUser.id === vendor.createdBy) && (
                                        <Button className="ml-10 btn-default" onClick={() => setIsModalShowing(true)} icon={<EditOutlined />}>
                                            <span>{t("action:edit")}</span>
                                        </Button>
                                    )}
                                </>
                            ) : (
                                <>
                                    {AuthzService.isRightGrantedForLoggedInUser(RightEnum.VENDOR__ALL__UPDATE) && (
                                        <>
                                            <Tooltip title={t("info:reject_vendor")}>
                                                <Button className="btn-default" onClick={() => approveOrRejectVendor(false)} icon={<CancelIcon />}>
                                                    <span>{t("action:reject")}</span>
                                                </Button>
                                            </Tooltip>
                                            <Tooltip title={t("info:approve_vendor")}>
                                                <Button
                                                    loading={isUpdatingVendorStatus}
                                                    disabled={isUpdatingVendorStatus}
                                                    type="primary"
                                                    onClick={() => approveOrRejectVendor(true)}
                                                    icon={<CheckIcon />}
                                                >
                                                    <span>{t("action:approve_vendor")}</span>
                                                </Button>
                                            </Tooltip>
                                        </>
                                    )}
                                </>
                            )
                        }
                    />

                    <VendorFormModal isNew={false} isShowing={isModalShowing} onCancel={setIsModalShowing} />
                    <VendorRuleModal isShowing={isRuleModalShowing} onCancel={setIsRuleModalShowing} vendorIDToUpdate={vendor.id} />

                    <div className="grid md:grid-cols-3 md:auto-rows-auto gap-24 sm:grid-cols-1">
                        <div className="sm:col-span-full">
                            <Card className="w-full" id="vendor-source-section">
                                <Tabs defaultActiveKey="1" className="mb-8">
                                    <TabPane key="rule" tab={t("label:rules")}>
                                        {getRules()}
                                    </TabPane>
                                    <TabPane key="vendorData" tab={t("label:business_details")}>
                                        {getBusinessDetails()}
                                    </TabPane>
                                    <TabPane key="transactionData" tab={t("label:contact_details")}>
                                        {getContactDetails()}
                                    </TabPane>
                                </Tabs>
                            </Card>
                        </div>
                        <div className="md:col-span-2 md:row-start-1 md:row-end-4 sm:col-span-full">
                            <Row className="-mt-10 sm:mt-0">
                                <Card className="w-full card-with-tabs">
                                    <Tabs className="w-full min-h-200 ml-1">
                                        <Tabs.TabPane key="contracts" tab={t("label:contracts")}>
                                            {vendor.contracts?.length === 0 ? (
                                                <Space style={{ display: "flex" }} className="pr-10 pl-10">
                                                    <Dragger
                                                        action={`${API_URL}/files`}
                                                        headers={{
                                                            Authorization: `Bearer ${localStorage.accessToken}`,
                                                        }}
                                                        multiple={false}
                                                        showUploadList={false}
                                                        customRequest={(req) => {
                                                            doFileUpload(req.file)
                                                        }}
                                                        disabled={vendor.deleted || !vendor.isApproved || !canEditContracts}
                                                    >
                                                        {!vendor.deleted && (
                                                            <div className="el-upload__text">
                                                                <i className="el-icon-upload" />
                                                                <br />
                                                                <Button loading={isUploading} disabled={!vendor.isApproved || !canEditContracts}>
                                                                    {t("action:add_contract")}
                                                                </Button>
                                                                <br />
                                                                <small>{t("action:or_drop_files")}</small>
                                                            </div>
                                                        )}
                                                    </Dragger>
                                                </Space>
                                            ) : (
                                                <div>
                                                    <Tabs type={canEditContracts ? "editable-card" : "card"} hideAdd defaultActiveKey={`${0}`} onEdit={handleEditTab}>
                                                        {vendor.contracts?.map((filePath: string, index: number) => (
                                                            <Tabs.TabPane key={`${index}`} closable tab={`${t("label:contract_document")} ${index + 1}`} forceRender>
                                                                {isFileExtensionMatching(filePath, FileExtEnum.PDF) ? (
                                                                    <FileViewerCached filePath={filePath} />
                                                                ) : (
                                                                    <img alt="Failed to load file" src={filePath} />
                                                                )}
                                                            </Tabs.TabPane>
                                                        ))}
                                                    </Tabs>
                                                </div>
                                            )}
                                        </Tabs.TabPane>
                                    </Tabs>
                                </Card>
                            </Row>
                            {AuthzService.isRightGrantedForLoggedInUser(RightEnum.EXPENSE__TEAM__READ) && (
                                <Row>
                                    <div className={canViewContracts ? "mt-24" : "mt-0"} style={{ width: "100%" }}>
                                        <GroupedExpenses groupedByField="vendor._id" groupedById={vendor.id} />
                                    </div>
                                </Row>
                            )}
                        </div>
                        <div className="sm:col-span-full">
                            <CommentContainer type={CollectionNameEnum.VENDOR} comments={vendor.comments} loggedInUser={loggedInUser} object={vendor} />
                        </div>
                    </div>
                </>
            )}
        </Spin>
    )
}

export default VendorDetail
