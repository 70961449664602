import { OcrStatusEnum } from "@finway-group/shared/lib/models"
import { Badge } from "antd"
import moment from "moment"
import React from "react"
import { useTranslation } from "react-i18next"

interface DateDueInBadgeInterface {
    dueOn: Date
}
const DateDueInBadge: React.FC<DateDueInBadgeInterface> = ({ dueOn }) => {
    const { t } = useTranslation()

    const today = moment().startOf("day")
    const targetDate = moment(dueOn).startOf("day")
    const numberOfDays = moment(targetDate).diff(today, "days")

    let color = "grey"
    let text = t("label:date_due_in_badge.in_x_days", { numberOfDays })
    if (numberOfDays < 0) {
        color = "red"
        text = t("label:date_due_in_badge.overdue")
    } else if (numberOfDays === 0) {
        color = "volcano"
        text = t("label:date_due_in_badge.due_today")
    } else if (numberOfDays === 1) {
        color = "volcano"
        text = t("label:date_due_in_badge.due_tomorrow")
    } else if (numberOfDays < 5) {
        color = "orange"
    }

    return <Badge className="ant-badge-normal-text" color={color} text={text} />
}

export default DateDueInBadge
