import { FeatureFlagsInterface } from "@finway-group/shared/lib/models"
import { TenantTierEnum } from "@finway-group/shared/lib/models/featureFlags/tenantTier.enum"

export const mapFeatureFlagsToTenantTier = (featureFlags?: FeatureFlagsInterface): TenantTierEnum => {
    if (!featureFlags) return TenantTierEnum.FREE
    const { paidVersion, costCenterGroupsEnabled, customCsvTemplatesEnabled, subCompaniesEnabled, travelEnabled } = featureFlags
    if (paidVersion && costCenterGroupsEnabled && customCsvTemplatesEnabled && subCompaniesEnabled && travelEnabled) return TenantTierEnum.TIER_2
    if (paidVersion && !costCenterGroupsEnabled && !customCsvTemplatesEnabled && !subCompaniesEnabled && !travelEnabled) return TenantTierEnum.TIER_1
    return TenantTierEnum.FREE
}
