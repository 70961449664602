import React from "react"

import { SelectedExpensesProvider, SelectedInboxInvoicesProvider } from "Shared/context/selectedExpenses.context"

import ExpenseContainer from "../expenses/expenses.container"

const Dashboard: React.FC = () => (
    <SelectedExpensesProvider>
        <SelectedInboxInvoicesProvider>
            <ExpenseContainer />
        </SelectedInboxInvoicesProvider>
    </SelectedExpensesProvider>
)

export default Dashboard
