import { Button, Form, Input } from "antd"
import { Store } from "antd/lib/form/interface"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import ForgotPasswordRules from "./rules/forgotPassword.rules"

interface ForgotPasswordFormInterface {
    onSubmit: (values: Store) => void
}
const ForgotPasswordForm: React.FC<ForgotPasswordFormInterface> = ({ onSubmit }) => {
    const { t } = useTranslation()
    const [formInstance] = Form.useForm()
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)

    const rules = ForgotPasswordRules()

    const handleValuesChange = (allFields: any) => setIsSubmitDisabled(!allFields.email || !allFields.email.length)

    return (
        <Form form={formInstance} layout="vertical" onFinish={onSubmit} onValuesChange={handleValuesChange}>
            <Form.Item label={t("input:work_email")} name="email" rules={rules.email}>
                <Input type="email" placeholder={t("placeholder:work_email")} required className="border-1 border-gray-500 rounded-lg outline-login min-h-40 px-10"></Input>
            </Form.Item>

            <Form.Item className="px-0 mt-24 pt-10">
                <Button size="large" className="w-full rounded-lg font-medium bg-login-button" type="primary" onClick={formInstance.submit} disabled={isSubmitDisabled}>
                    {t("action:reset_password")}
                </Button>
            </Form.Item>
        </Form>
    )
}

export default ForgotPasswordForm
