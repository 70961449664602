import Icon from "@ant-design/icons"
import React from "react"

const doneSvg = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M5.73333 10.0667L3.4 7.73333C3.14227 7.4756 2.7244 7.4756 2.46667 7.73333C2.20893 7.99107 2.20893 8.40893 2.46667 8.66667L5.73333 11.9333L13.2667 4.4C13.5244 4.14227 13.5244 3.7244 13.2667 3.46667C13.0089 3.20893 12.5911 3.20893 12.3333 3.46667L5.73333 10.0667Z"
            fill="#60CDB2"
        />
    </svg>
)

const DoneIcon = (props: any) => <Icon component={doneSvg} {...props} />

export default DoneIcon
