import { Employee } from "@finway-group/shared/lib/models"
import { Alert, Form, Input, Modal } from "antd"
import { Store } from "antd/lib/form/interface"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import { EmailFormRule } from "Components/forms/rules"
import { useCompanyById } from "Shared/hooks/company.hooks"
import { EmployeeService, NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { syncEmployee } from "Shared/store/actions/employee/employeeActions"

interface AddEmailAliasModalInterface {
    companyId: string
    employee: Employee
    isShowing: boolean
    // this means that the modal is only shown
    // when the API returns the error code for it
    isShownOnError?: boolean
    onCancel: () => void
    // only needed when shown on error
    onSuccess?: () => void
}
const AddEmailAliasModal: React.FC<AddEmailAliasModalInterface> = ({ companyId, employee, isShowing, isShownOnError = false, onCancel, onSuccess }) => {
    const [form] = Form.useForm()
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false)
    const company = useCompanyById(companyId)
    const profile = employee.companyProfiles?.find((p) => p.companyId === companyId)

    useEffect(() => {
        form.resetFields()
    }, [isShowing])

    const onFormSubmit = async ({ emailAlias }: Store) => {
        setIsLoading(true)

        try {
            const updatedUser = await EmployeeService.setCompanyEmailAlias(employee.id, companyId, emailAlias)
            dispatch(syncEmployee(updatedUser.id))
            NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:employee.email_alias_updated.title"), t("notification:employee.email_alias_updated.message"))
            onSuccess?.()
            handleHide()
        } catch (err) {
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:error"))
        } finally {
            setIsLoading(false)
        }
    }

    const handleHide = () => {
        setIsLoading(false)
        form.resetFields()
        onCancel()
    }

    return (
        <Modal
            title={t(`label:${isShownOnError ? "alternative_email_address_required" : "add_email_alias"}`)}
            visible={isShowing}
            maskClosable={false}
            onOk={form.submit}
            onCancel={handleHide}
            okText={t("action:save")}
            keyboard
            destroyOnClose
            confirmLoading={isLoading}
        >
            {isShownOnError && <Alert className="mb-20" type="warning" message={t("info:cards.alternative_email_address_required.message")} showIcon />}
            <Form form={form} layout="vertical" onFinish={onFormSubmit}>
                <Form.Item
                    label={
                        <span>
                            {t(`input:${isShownOnError ? "alternative_email_for" : "email_alias_for"}`)}
                            <b>{company?.name}</b>
                        </span>
                    }
                    name="emailAlias"
                    initialValue={profile?.weavrData?.weavrEmail ?? ""}
                    rules={EmailFormRule}
                >
                    <Input placeholder={t("placeholder:work_email")} />
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default AddEmailAliasModal
