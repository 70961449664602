import { PerDiem } from "@finway-group/shared/lib/models"
import { FormInstance, Rule } from "antd/lib/form"
import i18n from "i18next"
import moment from "moment"

import { PerDiemDestinationItinerary } from "../expenseForm/reimbursementForms/perDiem/perDiem.form.types"

type DestinationValidationGeneratorType = {
    name: string
    index: number
    formPart: "date" | "time"
    form: FormInstance<PerDiem>
}

const generatePerDiemEndDateValidator =
    ({ name, index, formPart, form }: DestinationValidationGeneratorType) =>
    (_rule: Rule, value: moment.Moment) => {
        if (!value) return Promise.reject(new Error(i18n.t("validation:required")))
        const tripDestinations: Array<PerDiemDestinationItinerary> = form.getFieldsValue([name])?.destinations ?? []
        const previousDestination = tripDestinations[index - 1]
        if (previousDestination?.endDate && value.isSameOrBefore(previousDestination.endDate, "minute")) {
            return Promise.reject(new Error(i18n.t(`validation:per_diem.end_${formPart}_less_than_last_transfer_${formPart}`)))
        }
        if (tripDestinations[index]?.startDate && value.isSameOrBefore(tripDestinations[index]?.startDate)) {
            if (tripDestinations.length === 1) {
                return Promise.reject(new Error(i18n.t(`validation:per_diem.end_${formPart}_less_than_start_${formPart}`)))
            }
            return Promise.reject(new Error(i18n.t(`validation:per_diem.end_${formPart}_less_than_transfer_${formPart}`)))
        }
        return Promise.resolve()
    }

const generatePerDiemStartDateValidator =
    ({ name, index, formPart, form }: DestinationValidationGeneratorType) =>
    (_rule: Rule, value: moment.Moment) => {
        if (index > 0) return Promise.resolve() // Start date only exist in the 0th index of destinations, so only evaluate for >0 indexes
        if (!value) return Promise.reject(new Error(i18n.t("validation:required")))
        const tripDestinations: Array<PerDiemDestinationItinerary> = form.getFieldsValue([name])?.destinations ?? []
        const currentDestination = tripDestinations[index]

        if (currentDestination?.endDate && value.isSameOrAfter(currentDestination.endDate, "minute")) {
            return Promise.reject(new Error(i18n.t(`validation:per_diem.start_${formPart}_more_than_end_${formPart}`)))
        }

        return Promise.resolve()
    }

export const DestinationFieldsRule = (destinationFieldName: string, currentIndex: number): { [key: string]: Array<Rule> } => ({
    endDate: [
        (form: FormInstance) => ({ required: true, validator: generatePerDiemEndDateValidator({ name: destinationFieldName, index: currentIndex, formPart: "date", form }) }),
    ],
    endTime: [
        (form: FormInstance) => ({ required: true, validator: generatePerDiemEndDateValidator({ name: destinationFieldName, index: currentIndex, formPart: "time", form }) }),
    ],
    startDate: [
        (form: FormInstance) => ({ required: true, validator: generatePerDiemStartDateValidator({ name: destinationFieldName, index: currentIndex, formPart: "date", form }) }),
    ],
    startTime: [
        (form: FormInstance) => ({ required: true, validator: generatePerDiemStartDateValidator({ name: destinationFieldName, index: currentIndex, formPart: "time", form }) }),
    ],
    destination: [{ required: true, message: i18n.t("validation:required"), validateTrigger: "blur" }],
})

export const ExpenditureRules = (isSynced: boolean) => ({
    syncDestinations: [
        {
            validator: (_rule: Rule) => {
                if (!isSynced) {
                    return Promise.reject(new Error(i18n.t("validation:per_diem.invalid_table")))
                }
                return Promise.resolve()
            },
        },
    ],
})
