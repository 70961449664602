import { ExclamationCircleOutlined } from "@ant-design/icons"
import { RightEnum } from "@finway-group/shared/lib/models"
import { TenantTierEnum } from "@finway-group/shared/lib/models/featureFlags/tenantTier.enum"
import { Button, Tooltip } from "antd"
import React from "react"
import { Edit as EditIcon } from "react-feather"
import { useTranslation } from "react-i18next"

import MileageDistanceBufferModal from "Components/modals/mileageDistanceBuffer.modal"
import { useModal } from "Shared/context/modal.context"
import { useCompany } from "Shared/hooks/company.hooks"
import { useIsTravelEnabled, useTenantTier } from "Shared/hooks/featureFlags.hooks"
import { AuthzService } from "Shared/services"
import { getTooltipPopupContainer, roundNumber } from "Shared/utils/helper.utils"

import FeatureSettingStatus from "./featureSettingStatus"

const DashboardAndWorkflowTabContent = () => {
    const { t } = useTranslation()
    const company = useCompany()
    const { showModal } = useModal()

    const isTravelEnabled = useIsTravelEnabled()
    const handleOnMileageDistanceBufferEdit = () => showModal(MileageDistanceBufferModal, false, { isShowing: true })
    const tenantTier = useTenantTier()
    const canWrite = AuthzService.canUpdateCompanyFeatureSettings()

    return (
        <div>
            <ul className="description-list">
                <FeatureSettingStatus
                    title={t("label:expense_payment_flow_information.is_already_approved_and_paid_enabled")}
                    isEnabled={company.expensePaymentFlowInformation?.isAlreadyApprovedAndPaidEnabled}
                    tooltipText={t("info:expense_payment_flow_information.is_already_approved_and_paid_enabled")}
                />
                <FeatureSettingStatus
                    title={t("label:invoice_auto_matching_feature")}
                    isEnabled={company.autoInvoiceMatchEnabled}
                    tooltipText={t("tooltips:invoice_auto_matching_feature")}
                />
                <FeatureSettingStatus
                    title={t("label:expense_payment_flow_information.is_already_paid_enabled")}
                    isEnabled={company.expensePaymentFlowInformation?.isAlreadyPaidEnabled}
                    tooltipText={t("info:expense_payment_flow_information.is_already_paid_enabled")}
                />
                <FeatureSettingStatus
                    title={t("label:expense_payment_flow_information.is_report_only_enabled")}
                    isEnabled={company.expensePaymentFlowInformation?.isReportingOnlyEnabled}
                    tooltipText={t("info:expense_payment_flow_information.is_report_only_enabled")}
                />
                <li>
                    <span className="title w-240">{t("label:archiveExpensesAfterXDays")}</span>
                    <span className="text text-left">
                        {company.archiveAfterXDays} {t("label:days")}
                    </span>
                </li>
                <li>
                    <span className="title w-240">{t("label:archiveTransactionsAfterXDays")}</span>
                    <span className="text text-left">
                        {company.transactionArchiveAfterXDays} {t("label:days")}
                    </span>
                </li>
                {isTravelEnabled && (
                    <li>
                        <span className="title w-300">
                            {t("label:mileage.distance_buffer")}
                            <Tooltip getPopupContainer={getTooltipPopupContainer} title={t("tooltips:mileage.distance_buffer")} placement="right" className="align-middle">
                                <ExclamationCircleOutlined className="ml-6" />
                            </Tooltip>
                        </span>
                        <span className="flex items-center space-x-12 text">
                            <span className="overflow-x-auto w-240">
                                {company.mileageDistanceBuffer ? (
                                    <span className="flex justify-end items-center gap-12">
                                        {`${roundNumber(company.mileageDistanceBuffer)} km`}
                                        {canWrite && <EditIcon className="cursor-pointer" size={14} onClick={handleOnMileageDistanceBufferEdit} />}
                                    </span>
                                ) : (
                                    <Button onClick={handleOnMileageDistanceBufferEdit}>{t("label:mileage.setup")}</Button>
                                )}
                            </span>
                        </span>
                    </li>
                )}
                <FeatureSettingStatus
                    title={t("label:allow_workflow_adjustments")}
                    isEnabled={company.allowWorkflowAdjustments}
                    tooltipText={t("info:allow_workflow_adjustments")}
                />
                <FeatureSettingStatus
                    title={t("label:recalculate_approval_process_after_amount_changes")}
                    isEnabled={company.recalculateApprovalProcessesInProgress.amount}
                    tooltipText={t("tooltips:recalculate_approval_process_after_amount_changes")}
                />
                <FeatureSettingStatus
                    title={t("label:recalculate_approval_process_after_other_changes")}
                    isEnabled={company.recalculateApprovalProcessesInProgress.other}
                    tooltipText={t("tooltips:recalculate_approval_process_after_other_changes")}
                />
                {tenantTier === TenantTierEnum.TIER_2 && (
                    <>
                        <FeatureSettingStatus
                            title={t("label:recalculate_approval_process_after_folder_amount_changes")}
                            isEnabled={company.recalculateApprovalProcessesInProgress.folderAmount}
                            tooltipText={t("tooltips:recalculate_approval_process_after_folder_amount_changes")}
                        />
                        <FeatureSettingStatus
                            title={t("label:recalculate_approval_process_after_folder_other_changes")}
                            isEnabled={company.recalculateApprovalProcessesInProgress.folderOther}
                            tooltipText={t("tooltips:recalculate_approval_process_after_folder_other_changes")}
                        />
                    </>
                )}
            </ul>
        </div>
    )
}

export default DashboardAndWorkflowTabContent
