import React from "react"

import Icon, { IconInterface } from "Components/icon"

const breakfastSvg = (
    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M25.1016 16.7303C29.7181 14.8254 31.339 12.5396 31.339 11.3359C31.339 10.0026 30.314 9.33594 29.339 9.33594C28.0241 9.33594 26.9681 10.0929 25.9585 10.8926"
            stroke="#606C7E"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M19.9583 24.0026C23.3274 22.0833 26.0104 17.8 26.0104 9.33594H3.34375C3.34375 17.8 6.02677 22.0833 9.39591 24.0026"
            stroke="#606C7E"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M26.0052 26.6667H3.33854C1.86578 26.6667 0.671875 25.4728 0.671875 24V24H28.6719V24C28.6719 25.4728 27.478 26.6667 26.0052 26.6667Z"
            stroke="#606C7E"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path d="M6.625 15.3359C7.26061 17.9992 8.48025 20.4175 10.7066 21.6859" stroke="#606C7E" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path
            d="M14.6719 6.66927C14.6719 5.33594 16.0052 5.33594 16.0052 4.0026C16.0052 2.66927 14.6719 2.66927 14.6719 1.33594"
            stroke="#606C7E"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M8.67188 6.66927C8.67188 5.33594 10.0052 5.33594 10.0052 4.0026C10.0052 2.66927 8.67188 2.66927 8.67188 1.33594"
            stroke="#606C7E"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M20.6719 6.66927C20.6719 5.33594 22.0052 5.33594 22.0052 4.0026C22.0052 2.66927 20.6719 2.66927 20.6719 1.33594"
            stroke="#606C7E"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)

const BreakfastIcon = (props: IconInterface) => <Icon component={breakfastSvg} {...props} />

export default BreakfastIcon
