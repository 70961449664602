import { ExpenseTag } from "@finway-group/shared/lib/models"
import { Reducer } from "redux"

import { CreateExpenseTagAction, DeleteExpenseTagAction, ExpenseTagActionTypes, ExpenseTagActions, FetchAllExpenseTagsAction } from "../actions/expenseTag/expenseTagTypes"

export interface ExpenseTagState {
    items: Array<ExpenseTag>
}

const initialState = {
    items: [],
}

const fetchAllExpenseTags = (state: ExpenseTagState, action: FetchAllExpenseTagsAction): ExpenseTagState => ({
    ...state,
    items: action.expenseTags,
})

const createExpenseTag = (state: ExpenseTagState, action: CreateExpenseTagAction): ExpenseTagState => ({
    ...state,
    items: [...state.items, action.expenseTag],
})

const deleteExpenseTag = (state: ExpenseTagState, action: DeleteExpenseTagAction): ExpenseTagState => ({
    ...state,
    items: state.items.filter((expenseTag) => expenseTag._id !== action.expenseTagId),
})

export const expenseTagReducer: Reducer<ExpenseTagState, ExpenseTagActions> = (state = initialState, action) => {
    switch (action.type) {
        case ExpenseTagActionTypes.FETCH_ALL_EXPENSE_TAGS:
            return fetchAllExpenseTags(state, action)
        case ExpenseTagActionTypes.CREATE_EXPENSE_TAG:
            return createExpenseTag(state, action)
        case ExpenseTagActionTypes.DELETE_EXPENSE_TAG:
            return deleteExpenseTag(state, action)
        default:
            return state
    }
}
