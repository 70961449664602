import { CostCenter2 } from "@finway-group/shared/lib/models"
import { useSelector } from "react-redux"

import { RootState } from "Shared/store/rootState.interface"

export const useCostCenters2 = () => {
    const costCenters2 = useSelector(({ costCenters2 }: RootState) => costCenters2.items)
    return costCenters2.sort((a: CostCenter2, b: CostCenter2) => a.name?.localeCompare(b.name))
}

export const useCostCenter2ById = (id?: string) => useCostCenters2().find((cc: CostCenter2) => id && cc._id === id)
