import { ClockCircleOutlined } from "@ant-design/icons"
import "@draft-js-plugins/emoji/lib/plugin.css"
import { MentionData } from "@draft-js-plugins/mention"
import "@draft-js-plugins/mention/lib/plugin.css"
import { CommentItem, RightEnum } from "@finway-group/shared/lib/models"
import { parseAutoGeneratedComment } from "@finway-group/shared/lib/utils"
import { Col, Comment, List, Popconfirm, Row, Tooltip } from "antd"
import { CompositeDecorator, ContentState, DraftDecorator, EditorState } from "draft-js"
import draftToHtml from "draftjs-to-html"
import moment from "moment"
import React, { useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

import UserImage from "Components/UserImage"
import { FINWAY_ADMIN_EMAIL } from "Shared/config/consts"
import { useAuth } from "Shared/hooks/auth.hooks"
import { useEmployees } from "Shared/hooks/employee.hooks"
import { useLoggedInEmployeeProfile } from "Shared/hooks/user.hooks"
import { AuthzService, EmployeeService } from "Shared/services"
import { getEditorContent } from "Shared/utils/comment.utils"
import { sortItemsDescByDateField } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"
import { parseHtml } from "Shared/utils/htmlParser.utils"

import CommentEditor from "./comment.editor"
import { useEditorDecoratorProps } from "./useEditorDecoratorsProps"

interface CommentListInterface {
    comments: Array<CommentItem>
    mentions: Array<MentionData>
    onUpdate: (index: number, newComment: string) => void
    onDelete: (index: number) => void
}
const CommentList = React.memo(({ comments, mentions, onUpdate, onDelete }: CommentListInterface) => {
    const { t } = useTranslation()
    const [inEditMode, setInEditMode] = useState(false)
    const [InEditItem, setInEditItem] = useState<string>("")

    const { rights } = useAuth()
    const loggedInUser = useLoggedInEmployeeProfile()

    const employees = useEmployees({ includeAutoApprover: true })
    const sortedComments = sortItemsDescByDateField(comments, "datePosted")
    const autoApprover = EmployeeService.getAutoApprover()

    const decoratorProps = useEditorDecoratorProps()
    const pluginDecorators = decoratorProps.plugins.reduce<Array<DraftDecorator>>((acc: any, p: any) => [...acc, ...p.decorators], [])
    const [editorState, setEditorState] = useStateIfMounted<EditorState>(EditorState.createWithContent(ContentState.createFromText(""), new CompositeDecorator(pluginDecorators)))

    /**
     * hide autogenerated comment that include a link to a transaction for user without TRANSACTION_READ right.
     */
    const filteredComments = sortItemsDescByDateField(sortedComments, "datePosted").filter(
        (comment) => !(comment.isAutoGenerated && !AuthzService.isRightGrantedForLoggedInUser(RightEnum.TRANSACTION__ALL__READ) && comment.message.includes("/transactions")),
    )

    const getActionSection = (commentData: CommentItem, index: number) => {
        /* Since we're doing optimistic comment updates, (commentData.id) ensures that the comment has id before updating/deleting it */
        if (commentData.postedBy === loggedInUser.id && !commentData.isAutoGenerated && (commentData.id || commentData._id)) {
            return [
                <span
                    key={commentData.id}
                    onClick={() => {
                        // apply edit mode on right comment in the list
                        if (!inEditMode) {
                            setInEditMode(true)
                            setInEditItem(commentData.id)
                            return
                        }

                        // to allow editor edit mode jump
                        if (inEditMode && InEditItem !== commentData.id) {
                            setInEditItem(commentData.id)
                            return
                        }

                        const editorContent = getEditorContent(editorState.getCurrentContent())
                        onUpdate(index, editorContent)
                        setInEditMode(false)
                        setInEditItem("")
                    }}
                >
                    {inEditMode && commentData.id === InEditItem ? t("action:save") : t("action:edit")}
                </span>,
                <Popconfirm
                    title={t("confirm:comment.delete.title")}
                    onConfirm={() => onDelete(index)}
                    okText={t("confirm:file.delete_from_server.confirm")}
                    cancelText={t("confirm:file.delete_from_server.cancel")}
                >
                    {InEditItem !== commentData.id && <span key={commentData.id}>{t("action:delete")}</span>}
                </Popconfirm>,
                <span key={commentData.id}></span>,
            ]
        }

        return []
    }

    /**
     * append edited span to edited comments
     * @param htmlString
     * @returns
     */
    const appendEditedFlag = (htmlString: string) => {
        const wrapper = document.createElement("div")
        wrapper.innerHTML = htmlString

        const editedWrapper = document.createElement("span")
        editedWrapper.className = "text-xs edited-flag"
        editedWrapper.innerHTML = " (edited)"

        wrapper.getElementsByTagName("p")[0].append(editedWrapper)
        return wrapper
    }

    /**
     * parse comment message from mention element or html string
     * @param param0
     * @returns
     */
    const getCommentContent = ({ message, isEdited, isAutoGenerated }: CommentItem) => {
        let commentContent = ""

        if (isAutoGenerated) {
            /**
            For auto generated comments, the comment message saved in the db has the following structure: "message,extra". Extra can be a string or a link. 
            The differentiation is done in the locales json file. 
            e.g 
            - to interpolate a string: "has sent out a reminder to {{extra1}} (Approver)."
            - to interpolate a link: "has matched the request to <0>this transaction</0>."
                <0> -> 0 is the index of the component in the components array in <Trans ... />
           */

            const { commentMessage, values, commentLinks } = parseAutoGeneratedComment(message, loggedInUser.settings.language, {
                autoApproverId: autoApprover.id,
                rights,
            })

            // Add link components
            const components = commentLinks.map((link) => {
                if (!link) return <></>
                return <Link key={link} to={link} />
            })

            // Translate values
            for (const key of Object.keys(values)) {
                values[key] = t(values[key])
            }

            return <Trans t={t} i18nKey={`activity:autoGeneratedComments.${commentMessage}`} values={values} components={components} />
        }

        try {
            const htmlString = draftToHtml(JSON.parse(message), {}, false, (entity: any, text: string) => {
                switch (entity.type) {
                    case "mention":
                        return `<a target="_blank" href="/employees/${entity.data.mention.link}">${text}</a>`
                    case "LINK":
                        return `<a target="_blank" rel="noopener noreferrer" href="${entity.data.url}">${text}</a>`
                    default:
                        return ""
                }
            })
            commentContent = isEdited ? appendEditedFlag(htmlString).innerHTML : htmlString
        } catch (error) {
            commentContent = `${message} ${isEdited ? <span className="text-xs">(edited)</span> : ""}`
        }

        return parseHtml(commentContent)
    }

    const renderDateTime = ({ datePosted }: CommentItem) => (
        <Row>
            <Col className="mr-5">
                <Tooltip title={moment(datePosted).fromNow()} placement="top" className="align-middle pb-2">
                    <ClockCircleOutlined />
                </Tooltip>
            </Col>
            <Col>
                <time className="text-xs">{moment(datePosted).format(t("label:comment_date_time_format"))}</time>
            </Col>
        </Row>
    )

    return (
        <List
            dataSource={filteredComments}
            header={`${filteredComments.length} ${filteredComments.length > 1 ? t("activity:comments.replies") : t("activity:comments.reply")}`}
            itemLayout="horizontal"
            renderItem={(commentData, index) => {
                const employee = employees.find((employee) => employee.id === commentData.postedBy)

                if (!employee) {
                    return <></>
                }
                const props = {
                    actions: getActionSection(commentData, index),
                    author: (
                        <b className="text-text-dark truncate">
                            <Link to={`/employees/${employee.email !== FINWAY_ADMIN_EMAIL ? employee.id : ""}`}>
                                {employee?.name || `${employee?.firstName} ${employee?.lastName}`}
                            </Link>
                        </b>
                    ),
                    avatar: (
                        <Link to={`/employees/${employee.id}`}>
                            <UserImage user={employee} size={30} />
                        </Link>
                    ),
                    content:
                        inEditMode && commentData.id === InEditItem ? (
                            <CommentEditor
                                decoratorProps={decoratorProps}
                                mentions={mentions}
                                onSubmit={(value) => {
                                    setInEditMode(false)
                                    setInEditItem(commentData.id)
                                    onUpdate(index, value)
                                }}
                                editorState={editorState}
                                setEditorState={setEditorState}
                                defaultValue={commentData.message}
                            />
                        ) : (
                            getCommentContent(commentData)
                        ),
                    datetime: renderDateTime(commentData),
                }
                return <Comment key={index} {...props} />
            }}
        />
    )
})

export default CommentList
