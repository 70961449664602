import moment from "moment"
import React from "react"
import { Edit as EditIcon } from "react-feather"
import { useTranslation } from "react-i18next"

import { DatevClientAndConsultantModal } from "Components/modals/datev/clientAndConsultant.modal"
import { DatevCsvConfigModal } from "Components/modals/datev/datevCsvConfig.modal"
import { FiscalYearModal } from "Components/modals/fiscalYear.modal"
import { useModal } from "Shared/context/modal.context"
import { useCompany } from "Shared/hooks/company.hooks"
import { AuthzService } from "Shared/services"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

export const DatevFeatureSettingsList = () => {
    const { t } = useTranslation()
    const { showModal } = useModal()
    const company = useCompany()

    const [isShowingFiscalYearModal, setIsShowingFiscalYearModal] = useStateIfMounted(false)

    const canUpdateCompanyFeatureSettings = AuthzService.canUpdateCompanyFeatureSettings()
    const anyClientAndConsultantNumberAreSet = company.datevSettings?.datevContext?.clientNumber || company.datevSettings?.datevContext?.consultantNumber
    const { fiscalYear } = company

    const handleShowDatevCsvConfigModal = () => showModal(DatevCsvConfigModal, true, { isShowing: true })
    const handleShowClientAndConsultantModal = () => showModal(DatevClientAndConsultantModal, true, { isShowing: true })

    if (!canUpdateCompanyFeatureSettings) return null

    return (
        <>
            <li>
                <span className="title w-180">{t("label:fiscal_year")}</span>
                <div className="flex text items-center gap-12">
                    {t(`info:fiscal_year.dates`, {
                        start_date: moment(fiscalYear.start).format("L"),
                        end_date: moment(fiscalYear.end).format("L"),
                    })}
                    {<EditIcon className="cursor-pointer" size={14} onClick={() => setIsShowingFiscalYearModal(true)} />}
                </div>
                {isShowingFiscalYearModal && <FiscalYearModal isShowing={isShowingFiscalYearModal} onHide={() => setIsShowingFiscalYearModal(false)} />}
            </li>
            <li>
                <span className="title w-180">{t("label:datev_client_and_consultant.title")}</span>
                <div className="flex text items-center gap-12">
                    {anyClientAndConsultantNumberAreSet && (
                        <>{[company.datevSettings?.datevContext?.clientNumber, company.datevSettings?.datevContext?.consultantNumber].filter((v) => v).join(" - ")}</>
                    )}
                    <EditIcon className="cursor-pointer" size={14} onClick={() => handleShowClientAndConsultantModal()} />
                </div>
            </li>
            <li>
                <div className="flex items-center">
                    <span className="title">{t("label:datev_csv_config.title")}</span>
                </div>
                <span className="flex items-center space-x-12 text">
                    <EditIcon className="cursor-pointer" size={14} onClick={() => handleShowDatevCsvConfigModal()} />
                </span>
            </li>
        </>
    )
}
