import { Rule } from "antd/lib/form"
import i18n from "i18next"

import { parseCurrencyInput } from "Components/currencyInput/config"
import { WEAVR_NAME_ON_CARD_LIMIT, WEAVR_NAME_ON_CARD_REGEX } from "Shared/config/consts"
import { isEmptyString } from "Shared/utils/helper.utils"

import { allowlistCharValidator } from "./utils.rules"

const CreditCardRules = (): { [key: string]: Array<Rule> } => ({
    cardHolder: [
        {
            required: true,
            message: i18n.t("validation:required"),
        },
    ],
    limit: [
        {
            validator: (_rule: any, inputValue: string) => {
                let value = 0
                inputValue = inputValue ? inputValue.toString() : ""
                if (inputValue !== "") {
                    value = parseCurrencyInput(inputValue)
                    if (value <= 0) {
                        return Promise.reject(new Error(i18n.t("validation:greater.number_short", { min: 0 })))
                    }

                    return Promise.resolve()
                }

                return Promise.reject(new Error(i18n.t("validation:greater.number_short", { min: 0 })))
            },
        },
    ],
    window: [
        {
            required: true,
            message: i18n.t("validation:required"),
        },
    ],
    card: [
        {
            required: true,
            message: i18n.t("validation:required"),
        },
    ],
    cardName: [
        {
            validator: (_rule: any, inputValue: string) => {
                if (isEmptyString(inputValue)) return Promise.reject(i18n.t("validation:required"))
                if (inputValue.length > 50) return Promise.reject(new Error(i18n.t("validation:below.string", { max: 50 })))

                return Promise.resolve()
            },
        },
        allowlistCharValidator,
    ],
    nameOnCard: [
        {
            validator: (_rule: any, value: string) => {
                if (isEmptyString(value)) {
                    return Promise.reject(i18n.t("validation:required"))
                }

                if (!WEAVR_NAME_ON_CARD_REGEX.test(value)) {
                    return Promise.reject(new Error(i18n.t("validation:only_letters_including_umlauts")))
                }

                if (value.length > WEAVR_NAME_ON_CARD_LIMIT) {
                    return Promise.reject(new Error(i18n.t("validation:below.string", { max: WEAVR_NAME_ON_CARD_LIMIT })))
                }

                return Promise.resolve()
            },
        },
    ],
})

export default CreditCardRules
