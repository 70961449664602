import { FormDueInDaysEnum } from "@finway-group/shared/lib/models/form/dueInDays.enum"
import { DatePickerProps } from "antd/lib/date-picker"
import { FormInstance, Rule } from "antd/lib/form"
import { Moment } from "moment-timezone"
import { PickerMode } from "rc-picker/lib/interface"

export enum FormInputEnum {
    DATE = "date",
    NUMBER = "number",
    TEXT = "text",
}

export enum FormCategoryEnum {
    MIN = "isMin",
    MAX = "isMax",
    DAYS = "isDays",
}

export type OnChangeEventType = (e: any, dateString?: string) => void

export interface FormActiveElementInterface {
    isMin?: boolean
    isMax?: boolean
    isDays?: boolean
}

export interface FormInputElementInterface {
    initialValue?: number | "text" | Moment | Date | string
    label: string
    key?: string
    name: string
    onChange?: OnChangeEventType
    onBlur?: () => void
    rules?: Array<Rule>
    placeholder: string
    isDisabled?: boolean
    inputType?: FormInputEnum
    disabledDateFunction?: DatePickerProps["disabledDate"]
    disabledMonthFunction?: DatePickerProps["disabledDate"]
    disabledYearFunction?: DatePickerProps["disabledDate"]
}

export interface FormMinMaxBaseInterface {
    header: string
    min: FormInputElementInterface
    max: FormInputElementInterface
    keyCode?: string
    extra?: JSX.Element
    category?: FormCategoryEnum
}

export interface FormMinMaxCurrencyInterface extends FormMinMaxBaseInterface {
    currency?: string
}

export interface FormMinMaxInterface extends FormMinMaxBaseInterface {
    inputType: FormInputEnum
}

export interface FormMinMaxDateInterface extends FormMinMaxBaseInterface {
    picker?: PickerMode
    dateFormat?: string
    disableDateOption?: boolean
    isCollapse?: boolean
    allowClear?: boolean
}

export interface FormMinMaxDateDueDaysInterface extends Omit<FormMinMaxBaseInterface, "onChange"> {
    isCollapse?: boolean
    days: FormInputElementInterface
    dueInDaysSelected?: FormDueInDaysEnum
    dateFormat?: string
    disableDateOption?: boolean
}

export interface FormSelectOption {
    param: string
    value: string | number
    index?: number
    label?: string
}

export interface AntdFormInterface<T> {
    formInstance: FormInstance<T>
    onSubmit: (formValues: T) => void
    isFormDisabled?: boolean
}
