import { Expense } from "@finway-group/shared/lib/models"
import { UserLanguageEnum } from "@finway-group/shared/lib/models/user/userSettings.interface"
import { Button, Card, Divider, Dropdown, Menu } from "antd"
import React from "react"
import { Download as DownloadIcon } from "react-feather"
import { useTranslation } from "react-i18next"

import MileageRequestMap from "Components/forms/expenseForm/reimbursementForms/mileage/mileageRequestMap"
import { PerDiemReimbursementsPerDayTable } from "Components/forms/expenseForm/reimbursementForms/perDiem/perDiemReimbursementsPerDayTable"
import { UploadFilesDraggerInterface } from "Components/upload/UploadFilesDragger"
import { isFolderExpense, isMileageExpense, isPerDiemExpense } from "Shared/utils/expense.utils"
import { getPopupAnchor } from "Shared/utils/popup.utils"

import ExpenseDocumentSection from "./expenseDocumentSection"
import { FolderSummary } from "./folderDetails/folderSummary"

interface ExpenseBottomSectionContainerInterface {
    expenseToRender: Expense
    subExpenses: Array<Expense>
    doFileUpload: UploadFilesDraggerInterface["doFileUpload"]
    onInboxSelected: (event: any) => void
    isUploadingInvoice?: boolean
    ocrSettings: {
        isOcrEnabled: boolean
        isOcrItemizationEnabled: boolean
        setIsOcrEnabled: (flag: boolean) => void
        setIsOcrItemizationEnabled: (flag: boolean) => void
    }
    isOcrUploading: boolean
    isReadOnly: boolean
    isSummaryDownloadLoading: boolean
    onSummaryDownload: (menuInfo: any) => void
}
export const ExpenseBottomSectionContainer = ({
    expenseToRender,
    doFileUpload,
    onInboxSelected,
    isUploadingInvoice,
    ocrSettings,
    isOcrUploading,
    isReadOnly,
    isSummaryDownloadLoading,
    onSummaryDownload,
    subExpenses,
}: ExpenseBottomSectionContainerInterface) => {
    const { t } = useTranslation()

    const exportDropDownOverlay = () => (
        <Menu onClick={onSummaryDownload} className="w-150 ml-10">
            <Menu.Item key={UserLanguageEnum.EN} className="flex items-center min-w-150 py-8">
                <div className="flex items-center ml-12">
                    <p className="leading-none">
                        <span className="text-text">{t("label:english")}</span>
                    </p>
                </div>
            </Menu.Item>
            <Menu.Item key={UserLanguageEnum.DE} className="flex items-center min-w-150 py-8">
                <div className="flex items-center ml-12">
                    <p className="leading-none">
                        <span className="text-text">{t("label:german")}</span>
                    </p>
                </div>
            </Menu.Item>
        </Menu>
    )

    if (isMileageExpense(expenseToRender)) {
        return (
            <Card className="w-full">
                <div className="flex justify-between align-center">
                    <p>{t("label:trip_map")}</p>

                    <Dropdown getPopupContainer={getPopupAnchor()} overlay={exportDropDownOverlay} trigger={["click"]} className="flex items-center">
                        <div className={"cursor-pointer"} onClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}>
                            <Button
                                onClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}
                                block
                                loading={isSummaryDownloadLoading}
                                className={`pointer-events-none btn-default ml-10`}
                                icon={<DownloadIcon />}
                                type={"default"}
                            >
                                <span>
                                    {t("action:mileage_summary")} <i className="el-icon-caret-bottom el-icon--right" />
                                </span>
                            </Button>
                        </div>
                    </Dropdown>
                </div>
                <Divider />
                <div className="h-600">
                    <MileageRequestMap
                        interactive={false}
                        markers={{
                            stops: expenseToRender.stops,
                        }}
                        initialRoute={expenseToRender.route}
                    />
                </div>
            </Card>
        )
    }

    if (isPerDiemExpense(expenseToRender)) {
        return (
            <Card className="w-full">
                <div className="flex justify-between align-center">
                    <p>{t("label:per_diem_calculation")}</p>

                    <Dropdown getPopupContainer={getPopupAnchor()} overlay={exportDropDownOverlay} trigger={["click"]} className="flex items-center justify-center">
                        <div className={"cursor-pointer"} onClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}>
                            <Button
                                onClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}
                                block
                                loading={isSummaryDownloadLoading}
                                className={`pointer-events-none btn-default ml-10`}
                                icon={<DownloadIcon />}
                                type={"default"}
                            >
                                <span>
                                    {t("action:per_diem_summary")}
                                    <i className="el-icon-caret-bottom el-icon--right" />
                                </span>
                            </Button>
                        </div>
                    </Dropdown>
                </div>
                <Divider />
                <div className="w-full overflow-x-auto">
                    <PerDiemReimbursementsPerDayTable perDiemExpense={expenseToRender} />
                </div>
            </Card>
        )
    }

    if (isFolderExpense(expenseToRender)) {
        return <FolderSummary folder={expenseToRender} subExpenses={subExpenses} />
    }

    return (
        <ExpenseDocumentSection
            expense={expenseToRender}
            doFileUpload={doFileUpload}
            onInboxSelected={onInboxSelected}
            isUploadingInvoice={isUploadingInvoice}
            ocrSettings={ocrSettings}
            isOcrUploading={isOcrUploading}
            isReadOnly={isReadOnly}
        />
    )
}
