import { Company } from "@finway-group/shared/lib/models"
import { TenantTierEnum } from "@finway-group/shared/lib/models/featureFlags/tenantTier.enum"
import { Button, Col, Divider, Input, Row, Select, Switch } from "antd"
import Form, { FormInstance } from "antd/lib/form"
import { Store } from "antd/lib/form/interface"
import React, { useEffect } from "react"
import { Plus as PlusIcon } from "react-feather"
import { useTranslation } from "react-i18next"

import { archiveAfterXDaysDefaults } from "Shared/config/consts"
import { useTenantTier } from "Shared/hooks/featureFlags.hooks"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"
import { getPopupAnchor } from "Shared/utils/popup.utils"

import CompanySettingsRules from "../rules/companySettings.rules"

interface DashboardAndWorkflowFormInterface {
    formInstance: FormInstance
    company: Company
    onSubmit: (values: Store) => void
}

const { Option } = Select

const DashboardAndWorkflowForm: React.FC<DashboardAndWorkflowFormInterface> = ({ formInstance, onSubmit, company }) => {
    const { t } = useTranslation()
    const rules = CompanySettingsRules()

    const onFinish = (values: Store) =>
        onSubmit({ ...values, expensePaymentFlowInformation: { ...values.expensePaymentFlowInformation, isAlreadyPaidEnabled, isAlreadyApprovedAndPaidEnabled } })

    const [archiveAfterXDaysOptions, setArchiveAfterXDaysOptions] = useStateIfMounted<Array<number>>(archiveAfterXDaysDefaults)
    const [isAlreadyPaidEnabled, setIsAlreadyPaidEnabled] = useStateIfMounted(company.expensePaymentFlowInformation?.isAlreadyPaidEnabled)
    const [isAlreadyApprovedAndPaidEnabled, setIsAlreadyApprovedAndPaidEnabled] = useStateIfMounted(company.expensePaymentFlowInformation?.isAlreadyApprovedAndPaidEnabled)
    const [customArchiveAfterXDays, setCustomArchiveAfterXDays] = useStateIfMounted("")
    const tenantTier = useTenantTier()

    useEffect(() => {
        if (company) {
            formInstance.setFieldsValue({
                ...company,
            })
        }

        return () => {
            formInstance.resetFields()
        }
    }, [])

    const archiveAfterXDaysDropdown = (menu: React.ReactElement<any, string | React.JSXElementConstructor<any>>) => (
        <>
            {menu}
            <Divider className="my-6" />
            <Input.Group className="flex flex-row space-x-4" compact>
                <Input type="number" value={customArchiveAfterXDays} onChange={(e) => setCustomArchiveAfterXDays(e.target.value)} />
                <Button
                    onClick={() => {
                        setArchiveAfterXDaysOptions([...archiveAfterXDaysOptions, Number(customArchiveAfterXDays)])
                        setCustomArchiveAfterXDays("")
                    }}
                >
                    <PlusIcon />
                </Button>
            </Input.Group>
        </>
    )

    return (
        <Form form={formInstance} layout="vertical" onFinish={onFinish} autoComplete="off">
            <Row gutter={[16, 16]}>
                <Col span={8}>
                    <Form.Item
                        label={t("input:company_settings.payment_flow_information.already_approved_and_paid")}
                        name={["expensePaymentFlowInformation", "isAlreadyApprovedAndPaidEnabled"]}
                        initialValue={false}
                    >
                        <Switch
                            checked={isAlreadyApprovedAndPaidEnabled}
                            onChange={(value) => {
                                setIsAlreadyApprovedAndPaidEnabled(value)
                                // if the above changed to true, then we gotta disable the other one
                                value && setIsAlreadyPaidEnabled(false)
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        label={t("input:company_settings.payment_flow_information.already_paid")}
                        name={["expensePaymentFlowInformation", "isAlreadyPaidEnabled"]}
                        initialValue={true}
                    >
                        <Switch
                            checked={isAlreadyPaidEnabled}
                            onChange={(value) => {
                                setIsAlreadyPaidEnabled(value)
                                // if the above changed to true, then we gotta disable the other one
                                value && setIsAlreadyApprovedAndPaidEnabled(false)
                            }}
                        />
                    </Form.Item>
                </Col>

                <Col span={8}>
                    <Form.Item
                        label={t("input:company_settings.payment_flow_information.reporting_only")}
                        name={["expensePaymentFlowInformation", "isReportingOnlyEnabled"]}
                        valuePropName="checked"
                    >
                        <Switch />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[16, 16]}>
                <Col span={8}>
                    <Form.Item label={t("input:company_settings.allow_workflow_adjustments")} name="allowWorkflowAdjustments" valuePropName="checked">
                        <Switch />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        label={t("input:company_settings.recalculate_approval_process_after_amount_changes")}
                        name={["recalculateApprovalProcessesInProgress", "amount"]}
                        valuePropName="checked"
                        initialValue={true}
                    >
                        <Switch />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        label={t("input:company_settings.recalculate_approval_process_after_other_changes")}
                        name={["recalculateApprovalProcessesInProgress", "other"]}
                        valuePropName="checked"
                        initialValue={true}
                    >
                        <Switch />
                    </Form.Item>
                </Col>
                {tenantTier === TenantTierEnum.TIER_2 && (
                    <>
                        <Col span={8}>
                            <Form.Item
                                label={t("input:company_settings.recalculate_approval_process_after_folder_amount_changes")}
                                name={["recalculateApprovalProcessesInProgress", "folderAmount"]}
                                valuePropName="checked"
                                initialValue={true}
                            >
                                <Switch />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label={t("input:company_settings.recalculate_approval_process_after_folder_other_changes")}
                                name={["recalculateApprovalProcessesInProgress", "folderOther"]}
                                valuePropName="checked"
                                initialValue={true}
                            >
                                <Switch />
                            </Form.Item>
                        </Col>
                    </>
                )}
            </Row>
            <Row gutter={[16, 16]}>
                <Col span={8}>
                    <Form.Item label={t("input:company_settings.invoice_auto_match_feature")} name="autoInvoiceMatchEnabled" valuePropName="checked">
                        <Switch />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[16, 16]}>
                <Col lg={12} sm={24} className="min-w-100">
                    <Form.Item
                        label={t("input:settings.archiveExpensesAfterXDays")}
                        name="archiveAfterXDays"
                        rules={rules.archiveAfterXDays}
                        extra={t("info:archive.expenseArchiveAfterXDays_settings")}
                        initialValue={90}
                    >
                        <Select className="w-100" getPopupContainer={getPopupAnchor()} dropdownRender={archiveAfterXDaysDropdown}>
                            {archiveAfterXDaysOptions.map((item: number) => (
                                <Option value={item} key={item}>
                                    {item}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={12} className="min-w-100">
                    <Form.Item
                        label={t("input:settings.archiveTransactionsAfterXDays")}
                        name="transactionArchiveAfterXDays"
                        rules={rules.archiveAfterXDays}
                        extra={t("info:archive.transactionArchiveAfterXDays_settings")}
                        initialValue={90}
                    >
                        <Select className="w-100" getPopupContainer={getPopupAnchor()} dropdownRender={archiveAfterXDaysDropdown}>
                            {archiveAfterXDaysOptions.map((item: number) => (
                                <Option value={item} key={item}>
                                    {item}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    )
}

export default DashboardAndWorkflowForm
