import { Mileage, VehicleEnum } from "@finway-group/shared/lib/models"
import { Card, Col, Row, Tabs } from "antd"
import moment from "moment"
import React from "react"
import { useTranslation } from "react-i18next"

import UserImage from "Components/UserImage"
import { useEmployeeById } from "Shared/hooks/employee.hooks"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

import MileageRequestMap, { RouteInfo } from "./mileageRequestMap"

interface MileageRequestSummaryInterface {}

enum TabEnum {
    SUMMARY = "SUMMARY",
    MAP = "MAP",
}

interface MileageRequestSummaryInterface {
    updateRoute?: (route: RouteInfo) => void
    interactive?: boolean
    expense: Partial<Mileage>
}
const MileageRequestSummary: React.FC<MileageRequestSummaryInterface> = ({ updateRoute, interactive, expense }) => {
    const { t } = useTranslation()

    // TODO_TRM: This one should init with SUMMARY, but antd have its own shadow realm when rendering tabs, causing the map to render with the wrong size.
    const [activeTab, setActiveTab] = useStateIfMounted(TabEnum.MAP)

    const employee = useEmployeeById(expense.requestedBy?._id!)

    const getVehicleType = () => {
        switch (expense.vehicle) {
            case VehicleEnum.CAR:
                return t(`label:mileage.vehicle_type.car`)
            case VehicleEnum.MOTORCYCLE:
                return t(`label:mileage.vehicle_type.motorcycle`)
            default:
                return "n/a"
        }
    }

    const startAddress = expense?.stops?.[0]?.address
    const destinationAddress = expense?.stops?.[(expense?.stops?.length ?? 1) - 1]?.address

    return (
        <>
            <Tabs activeKey={activeTab} onTabClick={(key: TabEnum) => setActiveTab(key)} className="w-full min-h-200 ml-1" defaultActiveKey={TabEnum.SUMMARY}>
                <Tabs.TabPane key={TabEnum.SUMMARY} tab={t("label:mileage.summary")}>
                    <div className="text-base font-semibold mb-8 ml-16 finway-dark-text">{t("label:mileage.trip_information")}</div>
                    <Card className="finway-dark-text w-full min-h-600">
                        <Row gutter={[16, 24]}>
                            <Col span={12}>
                                <div>{t("label:employee")}</div>
                                <div className="flex justify-start">
                                    <div className="-ml-8">
                                        {employee && (
                                            <div className="flex items-center flex-no-wrap">
                                                <UserImage user={employee} />
                                                <span className="leading-tight truncate text-base font-medium">{employee?.getFullName()}</span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </Col>
                            <Col span={12}>
                                <div>{t("label:mileage.reason")}</div>
                                <div className="flex justify-start h-32 items-center">
                                    <div className="leading-tight truncate text-base font-medium">{expense.tripReason ?? "n/a"}</div>
                                </div>
                            </Col>

                            <Col span={12}>
                                <div>{t("label:mileage.date")}</div>
                                <div className="flex justify-start h-32 items-center">
                                    <div className="leading-tight truncate text-base font-medium">
                                        {expense.datePurchased ? moment(expense.datePurchased).format("DD.MM.YYYY") : "n/a"}
                                    </div>
                                </div>
                            </Col>
                            <Col span={12} />
                            <Col span={12}>
                                <div>{t("label:mileage.distance")}</div>
                                <div className="flex justify-start h-32 items-center font-medium">
                                    <div className="leading-tight truncate text-base">{expense.actualDistance ?? 0} km</div>
                                </div>
                            </Col>
                            <Col span={12}>
                                <div>{t("label:mileage.vehicle")}</div>
                                <div className="flex justify-start h-32 items-center font-medium">
                                    <div className="leading-tight truncate text-base">{getVehicleType()}</div>
                                </div>
                            </Col>
                            <Col span={24}>
                                <div>{t("label:mileage.start_address")}</div>
                                <div className="flex justify-start h-32 items-center font-medium">
                                    <div className="leading-tight truncate text-base">{startAddress ?? "n/a"}</div>
                                </div>
                            </Col>
                            <Col span={24}>
                                <div>{t("label:mileage.destination_address")}</div>
                                <div className="flex justify-start h-32 items-center font-medium">
                                    <div className="leading-tight truncate text-base">{destinationAddress ?? "n/a"}</div>
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </Tabs.TabPane>
                <Tabs.TabPane key={TabEnum.MAP} tab={t("label:mileage.map")} forceRender>
                    <div className="h-600 w-full">
                        <MileageRequestMap
                            markers={{
                                stops: expense.stops,
                            }}
                            interactive={interactive}
                            onRouteUpdate={(route) => {
                                if (interactive && updateRoute) {
                                    updateRoute(route)
                                }
                            }}
                            initialRoute={(expense as any).route}
                        />
                    </div>
                </Tabs.TabPane>
            </Tabs>
        </>
    )
}

export default MileageRequestSummary
