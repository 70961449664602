import { AccessTokenType, FeatureFlags, User } from "@finway-group/shared/lib/models"
import jwt from "jsonwebtoken"
import moment, { unitOfTime } from "moment"

import { ErrorTypes } from "Shared/error.types"

export const decodeAndFormatAccessTokenValidTime = (encodedAccessToken: string | null, unit: unitOfTime.Diff = "seconds") => {
    if (!encodedAccessToken) throw new Error(ErrorTypes.ACCESS_TOKEN_MISSING)

    const accessToken = decodeAccessToken(encodedAccessToken)
    const time = moment(Number(accessToken.exp) * 1000)
    const dif = time.diff(moment(), unit)
    return dif
}

export const decodeAndFormatAccessToken = (encodedAccessToken: string | null) => {
    if (!encodedAccessToken) throw new Error(ErrorTypes.ACCESS_TOKEN_MISSING)

    const decodedAccessToken = decodeAccessToken(encodedAccessToken)
    const userClass = new User(decodedAccessToken)
    const featureFlags = decodedAccessToken?.featureFlags ? new FeatureFlags(decodedAccessToken.featureFlags) : undefined
    const companyId = decodedAccessToken?.currentCompanyId as string

    return {
        userId: userClass?.id,
        userClass,
        companyId,
        featureFlags,
        isSsoLogin: decodedAccessToken?.isSsoLogin === true,
    }
}

export const decodeAccessToken = (encodedToken: string) => {
    const decodedAccessToken = jwt.decode(encodedToken) as AccessTokenType
    return decodedAccessToken
}
