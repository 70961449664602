import { BankOutlined as BankIcon } from "@ant-design/icons"
import { BankConnection, RightEnum } from "@finway-group/shared/lib/models"
import { Button, Dropdown, Menu } from "antd"
import React, { useState } from "react"
import { File as FileIcon, Plus as PlusIcon } from "react-feather"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

import { useCompany } from "Shared/hooks/company.hooks"
import { useIsFreeVersion } from "Shared/hooks/featureFlags.hooks"
import { AuthzService } from "Shared/services"
import { RootState } from "Shared/store/rootState.interface"

import BankMatchingModal from "./modals/bankMatching.modal"
import CsvMatchingModal from "./modals/csvMatching.modal"

interface MatchingButtonInterface {
    onUpdate: () => void
}

const MatchingButton: React.FC<MatchingButtonInterface> = ({ onUpdate }) => {
    const { t } = useTranslation()
    const isFreeVersion = useIsFreeVersion()
    const company = useCompany()
    const [isBankMatchingShowing, setIsBankMatchingShowing] = useState<boolean>(false)
    const [isCsvMatchingShowing, setIsCsvMatchingShowing] = useState<boolean>(false)
    const defaultBankConnection = useSelector(({ banks }: RootState) => banks.items)?.find((con: BankConnection) => con.isDefault)
    const disabled = company?.isImportingTransactions || company?.isMatchingTransactions || (isFreeVersion && !defaultBankConnection)

    const onSelect = ({ key, domEvent }: any) => {
        domEvent.stopPropagation()
        setIsCsvMatchingShowing(key === "csv_matching")
        setIsBankMatchingShowing(key === "bank_matching")
    }

    const dropDownMenu = () => (
        <Menu onClick={onSelect}>
            {!isFreeVersion && (
                <Menu.Item key="csv_matching" className="flex items-center min-w-190 py-8">
                    <div className="flex items-center justify-center rounded-full w-16 h-16">
                        <FileIcon />
                    </div>
                    <div className="flex items-center ml-12">
                        <p className="leading-none">
                            <span className="text-text">{t("action:matching.csv.menu")}</span>
                        </p>
                    </div>
                </Menu.Item>
            )}
            {defaultBankConnection && (
                <Menu.Item key="bank_matching" className="flex items-center min-w-190 py-8">
                    <div className="flex items-center justify-center rounded-full w-16 h-16">
                        <BankIcon />
                    </div>
                    <div className="flex items-center ml-12">
                        <p className="leading-none">
                            <span className="text-text">{t("action:matching.bank.menu")}</span>
                        </p>
                    </div>
                </Menu.Item>
            )}
        </Menu>
    )

    if (!AuthzService.isRightGrantedForLoggedInUser(RightEnum.TRANSACTION__ALL__UPDATE)) return null

    return (
        <>
            <CsvMatchingModal isShowing={isCsvMatchingShowing} onSubmit={onUpdate} onCancel={() => setIsCsvMatchingShowing(false)} />
            <BankMatchingModal isShowing={isBankMatchingShowing} onCancel={() => setIsBankMatchingShowing(false)} />

            <Dropdown disabled={disabled} overlay={dropDownMenu} trigger={["click"]} className="flex items-center z-100">
                <Button type="primary" onClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}>
                    <PlusIcon />
                    {t("action:matching.add_transactions")}
                </Button>
            </Dropdown>
        </>
    )
}

export default MatchingButton
