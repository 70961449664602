import { CloseOutlined } from "@ant-design/icons"
import { RightEnum, Transaction } from "@finway-group/shared/lib/models"
import { Button, Modal, Tabs } from "antd"
import React, { useEffect, useState } from "react"
import { Archive as ArchiveIcon } from "react-feather"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useDebouncedCallback } from "use-debounce/lib"

import Filter from "Components/filter/filter"
import FilterSection from "Components/filterSection"
import MatchingButton from "Components/matchingButton"
import SearchInput from "Components/searchInput"
import { useAccounts, useBankConnections } from "Shared/hooks/bank.hooks"
import { applyFilter } from "Shared/hooks/filter.hooks"
import { useTable, useTableFilterObject, useTableSearchString } from "Shared/hooks/table.hooks"
import { handleArchiveTransactions, handleUnarchiveTransactions, useActiveTransactionTab } from "Shared/hooks/transaction.hooks"
import { useCreditors } from "Shared/hooks/vendor.hooks"
import { AuthzService, NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { refetchTable, updateTableSearch } from "Shared/store/actions/tables/tableActions"
import { deleteManyTransactions, setActiveTransactionTab } from "Shared/store/actions/transaction/transactionActions"
import { TablesEnum } from "Shared/store/reducers/tableConfigReducer"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

import ListTransactionBanner from "./listTransactionBanner"
import { TransactionTable } from "./transactionTable"

const { confirm } = Modal

export const enum TransactionTabTypeEnum {
    INFLOW = "INFLOW",
    OUTFLOW = "OUTFLOW",
    ARCHIVE = "ARCHIVE",
    ALL = "ALL",
}

interface TransactionsListInterface {}

export const TransactionsList: React.FC<TransactionsListInterface> = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const [isDeleting, setIsDeleting] = useStateIfMounted(false)
    const [selectedTransactions, setSelectedTransactions] = useStateIfMounted<Array<Transaction>>([])
    const [isFilterModalShowing, setIsFilterModalShowing] = useState<boolean>(false)
    const creditors = useCreditors(AuthzService.isRightGrantedForLoggedInUser(RightEnum.EMPLOYEE__ALL__READ), true, true)
    const activeTab = useActiveTransactionTab()
    const [tablesEnumTransaction, setTablesEnumTransaction] = useStateIfMounted<TablesEnum>(TablesEnum.TRANSACTIONS_OUTFLOW)
    const connections = useBankConnections({ shouldFilterDeleted: false })
    const accounts = useAccounts({ shouldFilterDeleted: false })

    const txnTable = useTable<Transaction>(tablesEnumTransaction)
    const searchString = useTableSearchString(tablesEnumTransaction)
    const filterObject = useTableFilterObject(tablesEnumTransaction)

    // Rights
    const hasWriteAccess = AuthzService.isRightGrantedForLoggedInUser(RightEnum.TRANSACTION__ALL__UPDATE)

    const onTabClick = (key: TransactionTabTypeEnum) => {
        if (key !== activeTab) dispatch(setActiveTransactionTab(key))
    }

    useEffect(() => {
        switch (activeTab) {
            case TransactionTabTypeEnum.OUTFLOW:
                setTablesEnumTransaction(TablesEnum.TRANSACTIONS_OUTFLOW)
                break
            case TransactionTabTypeEnum.INFLOW:
                setTablesEnumTransaction(TablesEnum.TRANSACTIONS_INFLOW)
                break
            case TransactionTabTypeEnum.ALL:
                setTablesEnumTransaction(TablesEnum.TRANSACTIONS_ALL)
                break
            case TransactionTabTypeEnum.ARCHIVE:
                setTablesEnumTransaction(TablesEnum.TRANSACTIONS_ARCHIVE)
                break
            default:
                break
        }
    }, [activeTab])

    const onDelete = () => {
        confirm({
            title: t("confirm:transaction.bulk_delete.title"),
            content: t("confirm:transaction.bulk_delete.message"),
            cancelText: t("confirm:transaction.delete.cancel"),
            type: "warning",
            okText: t("confirm:transaction.delete.confirm"),
            onOk() {
                handleDelete()
            },
        })
    }

    const handleDelete = async () => {
        try {
            setIsDeleting(true)
            const transactionIds = selectedTransactions.map((t) => t.id)
            await dispatch(deleteManyTransactions(transactionIds))
            setSelectedTransactions([])
            NotificationService.send(NotificationTypeEnum.WARNING, t("notification:transaction.bulk_deleted.title"), t("notification:transaction.bulk_deleted.message"))
        } catch (err) {
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:transaction.bulk_delete.title"))
        } finally {
            setIsDeleting(false)
        }
    }

    const handleSearch = useDebouncedCallback((searchString) => dispatch(updateTableSearch(tablesEnumTransaction, searchString)), 800)

    // only show the table when the active tab is not archive or if the archive tab is active then minTransactionDate and maxTransactionDate filter values should be defined.
    const showTable = !!(tablesEnumTransaction !== TablesEnum.TRANSACTIONS_ARCHIVE || (filterObject.minTransactionDate && filterObject.maxTransactionDate))
    const isArchiveTable = tablesEnumTransaction === TablesEnum.TRANSACTIONS_ARCHIVE
    return (
        <div>
            <div className="flex flex-row flex-wrap justify-between mb-16 space-x-10">
                <h1 className="mb-8">{t("view:transactions.title")}</h1>
                <div className="flex btn-wrapper flex-wrap">
                    {showTable && (txnTable.data.totalDocs > 0 || searchString) && (
                        <SearchInput onSearch={(searchString) => handleSearch.callback(searchString)} value={searchString} />
                    )}

                    {showTable && (
                        <Filter
                            table={tablesEnumTransaction}
                            isFilterModalShowing={isFilterModalShowing}
                            setIsFilterModalShowing={setIsFilterModalShowing}
                            onFilter={(data) => applyFilter(tablesEnumTransaction, data)}
                            options={{ creditors }}
                        />
                    )}

                    {hasWriteAccess && (
                        <>
                            <Button
                                className="btn-default"
                                disabled={!selectedTransactions.length}
                                onClick={async () => {
                                    isArchiveTable
                                        ? await handleUnarchiveTransactions(selectedTransactions.map((obj) => obj.id))
                                        : await handleArchiveTransactions(
                                              selectedTransactions.map((obj) => obj.id),
                                              tablesEnumTransaction,
                                          )
                                }}
                                icon={<ArchiveIcon />}
                            >
                                <span>{t(`action:archive.${tablesEnumTransaction === TablesEnum.TRANSACTIONS_ARCHIVE ? "undo" : "do"}`)}</span>
                            </Button>

                            <Button loading={isDeleting} className="btn-default" onClick={onDelete} icon={<CloseOutlined />} disabled={selectedTransactions.length === 0}>
                                <span>{t("action:delete")}</span>
                            </Button>
                            <MatchingButton
                                onUpdate={() => {
                                    dispatch(refetchTable(TablesEnum.TRANSACTIONS_INFLOW))
                                    dispatch(refetchTable(TablesEnum.TRANSACTIONS_OUTFLOW))
                                }}
                            />
                        </>
                    )}
                </div>
            </div>

            <FilterSection table={tablesEnumTransaction} options={{ creditors, connections, accounts }} />

            <ListTransactionBanner />

            <Tabs onTabClick={onTabClick} activeKey={activeTab} className="pb-10">
                <Tabs.TabPane tab={`${t("label:transaction_container.outflow")}`} forceRender={true} key={TransactionTabTypeEnum.OUTFLOW}>
                    <TransactionTable
                        tablesEnumTransaction={TablesEnum.TRANSACTIONS_OUTFLOW}
                        transactionTable={txnTable}
                        showTable={showTable}
                        setSelectedTransactions={setSelectedTransactions}
                    />
                </Tabs.TabPane>

                <Tabs.TabPane tab={`${t("label:transaction_container.inflow")}`} forceRender={true} key={TransactionTabTypeEnum.INFLOW}>
                    <TransactionTable
                        tablesEnumTransaction={TablesEnum.TRANSACTIONS_INFLOW}
                        transactionTable={txnTable}
                        showTable={showTable}
                        setSelectedTransactions={setSelectedTransactions}
                    />
                </Tabs.TabPane>
                <Tabs.TabPane tab={`${t("label:transaction_container.all")}`} forceRender={true} key={TransactionTabTypeEnum.ALL}>
                    <TransactionTable
                        tablesEnumTransaction={TablesEnum.TRANSACTIONS_ALL}
                        transactionTable={txnTable}
                        showTable={showTable}
                        setSelectedTransactions={setSelectedTransactions}
                    />
                </Tabs.TabPane>
                <Tabs.TabPane tab={`${t("label:transaction_container.archive")}`} forceRender={true} key={TransactionTabTypeEnum.ARCHIVE}>
                    <TransactionTable
                        tablesEnumTransaction={TablesEnum.TRANSACTIONS_ARCHIVE}
                        transactionTable={txnTable}
                        showTable={showTable}
                        setSelectedTransactions={setSelectedTransactions}
                    />
                </Tabs.TabPane>
            </Tabs>
        </div>
    )
}

export default TransactionsList
