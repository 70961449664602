import { ExclamationCircleTwoTone, LoadingOutlined, PlusOutlined } from "@ant-design/icons"
import { GREEN, ORANGE } from "@finway-group/shared/lib/consts"
import { CostCenter, PriceIntervalEnum, RightEnum } from "@finway-group/shared/lib/models"
import { GetEmployeeById } from "@finway-group/shared/lib/models/user/employee.model"
import { Badge, Button, Empty, Row, Table, Tooltip } from "antd"
import { ColumnsType } from "antd/es/table"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { useDebouncedCallback } from "use-debounce/lib"

import NoSearchDataFound from "Components/NoSearchDataFound"
import UserImage from "Components/UserImage"
import DataUploadAlert from "Components/alert/dataUpload.alert"
import { ColorBar, ColorsBar } from "Components/colorsBar"
import Filter from "Components/filter/filter"
import FilterSection from "Components/filterSection"
import CornerDownRightArrowIcon from "Components/icons/cornerDownRightArrow"
import DownCircleIcon from "Components/icons/downCircleIcon"
import UpCircleIcon from "Components/icons/upCircleIcon"
import { ErrorTable } from "Components/layout/errorTable"
import Loading from "Components/loading"
import LoadingData from "Components/loading.data"
import MassExportButton from "Components/massExport.button"
import PageTitle from "Components/page/pageTitle"
import PriceLabel from "Components/priceLabel"
import SearchInput from "Components/searchInput"
import { useEmployees } from "Shared/hooks/employee.hooks"
import { applyFilter } from "Shared/hooks/filter.hooks"
import { useFetchTable, useTable, useTableFilterAndSearchLoading } from "Shared/hooks/table.hooks"
import { useLoggedInEmployeeProfile } from "Shared/hooks/user.hooks"
import { AuthzService, CostCenterService, CurrencyService } from "Shared/services"
import { ThunkDispatchResult } from "Shared/store"
import { setCostCenter } from "Shared/store/actions/costCenter/costCenterActions"
import { refetchTable, updateTableOnChange, updateTableSearch } from "Shared/store/actions/tables/tableActions"
import { TablesEnum } from "Shared/store/reducers/tableConfigReducer"
import { CostCenterWithBudgetData } from "Shared/store/reducers/tableReducer"
import { getBudgetColorBars, groupCostCentersByParent, isParentCostCenterAvailable } from "Shared/utils/costCenter.utils"
import { getTooltipPopupContainer } from "Shared/utils/helper.utils"
import { getSortOrderForColumn, isTableFilterApplied } from "Shared/utils/table.utils"

import CostCenterFormModal from "./costCenterForm/CostCenterFormModal"

export const CostCenters: React.FC<{}> = () => {
    const { t } = useTranslation()
    const history = useHistory()
    const dispatch: ThunkDispatchResult = useDispatch()
    const employees = useEmployees()
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [isFilterModalShowing, setIsFilterModalShowing] = useState<boolean>(false)

    const costCentersTable = useTable<CostCenterWithBudgetData>(TablesEnum.COST_CENTERS)
    const groupedCostCenters = groupCostCentersByParent(costCentersTable.data.docs)

    const loggedInUser = useLoggedInEmployeeProfile()

    const { globalCurrency } = loggedInUser.settings
    const pageLoading = useTableFilterAndSearchLoading(TablesEnum.COST_CENTERS)

    useFetchTable(TablesEnum.COST_CENTERS)

    const columns: ColumnsType<CostCenterWithBudgetData> = [
        {
            dataIndex: "expandIcon",
            width: 30,
            align: "center",
        },
        {
            title: t("label:name"),
            key: "name",
            ellipsis: true,
            width: 300,
            sortOrder: getSortOrderForColumn(costCentersTable, "name"),
            sorter: (a: CostCenter, b: CostCenter) => a.name.localeCompare(b.name),
            render: (costCenter: any) => (
                <>
                    {costCenter.id && (
                        <span className="mb-4 text-xs mr-5 opacity-75">
                            {t("label:id")} {costCenter.id}
                        </span>
                    )}
                    <span className="ml-10">
                        {costCenter.name}
                        {costCenter.children && <Badge className="inline-block" style={{ backgroundColor: "#EEF4F8", color: "#606C7E" }} count={costCenter.children.length} />}
                    </span>
                </>
            ),
        },
        {
            key: "responsibleUser",
            title: t("label:responsible_employee"),
            align: "left",
            width: 300,
            ellipsis: true,
            sortOrder: getSortOrderForColumn(costCentersTable, "responsibleUser"),
            sorter: (a: CostCenter, b: CostCenter) =>
                (GetEmployeeById(employees, a.responsibleUser)?.firstName || "")?.localeCompare(GetEmployeeById(employees, b.responsibleUser)?.firstName || ""),
            render: (data: CostCenter) => {
                const responsibleUser = GetEmployeeById(employees, data.responsibleUser)

                return (
                    <div className="flex items-center">
                        {responsibleUser && <UserImage user={responsibleUser} />}
                        <div className="inline-flex space-x-4">
                            <span className="truncate">
                                {responsibleUser?.firstName} {responsibleUser?.lastName}
                            </span>
                            {responsibleUser?.activeCompanyProfile.deleted && (
                                <Tooltip
                                    getPopupContainer={getTooltipPopupContainer}
                                    autoAdjustOverflow={false}
                                    placement="right"
                                    title={t("info:employee_deleted")}
                                    color={ORANGE}
                                >
                                    <ExclamationCircleTwoTone twoToneColor={ORANGE} className="pt-4" />
                                </Tooltip>
                            )}
                        </div>
                    </div>
                )
            },
        },
        {
            key: "expenses",
            title: t("label:expenses"),
            align: "right",
            className: "pr-10",
            sortOrder: getSortOrderForColumn(costCentersTable, "expenses"),
            sorter: (costCenterA: CostCenterWithBudgetData, costCenterB: CostCenterWithBudgetData) => {
                // while there is no data it doesn't sort
                if (!costCentersTable.data.docs.length) return 0
                const defaultBudgetData = { capacity: 0, consumption: 0, forecast: 0 }
                const budgetDataA = costCenterA.budgetData || defaultBudgetData

                const budgetDataB = costCenterB.budgetData || defaultBudgetData

                if (budgetDataA.consumption === budgetDataB.consumption) {
                    return budgetDataA.consumption + budgetDataA.forecast - (budgetDataB.consumption + budgetDataB.forecast)
                }
                return budgetDataA.consumption - budgetDataB.consumption
            },
            render: (costCenter: CostCenterWithBudgetData) => {
                const className = "h-8"
                const showAsSubCostCenter = isParentCostCenterAvailable(costCenter, costCentersTable.data.docs)
                if (!costCenter.budgetData)
                    return (
                        <div className="relative">
                            {/* Place Holder to have the corret row height and avoid jumping */}
                            <div className="invisible">
                                {renderBudgetData(showAsSubCostCenter, { capacity: 0, consumption: 0, forecast: 0, id: "0" }, 1, [{ value: 1, color: GREEN, className }])}
                            </div>
                            <div className="absolute inset-0">
                                <LoadingData />
                            </div>
                        </div>
                    )
                const { budgetData } = costCenter
                const convertedBudgetLimit = CurrencyService.convert(budgetData?.capacity, costCenter.budgetCurrency, globalCurrency)
                const colorBars = getBudgetColorBars(budgetData?.consumption, budgetData?.forecast, convertedBudgetLimit, className)
                return renderBudgetData(showAsSubCostCenter, budgetData, convertedBudgetLimit, colorBars)
            },
            width: 180,
        },
    ]

    const renderBudgetData = (
        showAsSubCostCenter: "" | CostCenter | undefined,
        budgetData: CostCenterWithBudgetData["budgetData"],
        convertedBudgetLimit: number,
        colorBars: Array<ColorBar>,
    ) => (
        <>
            <Row className={`block ${showAsSubCostCenter && "ml-40"}`}>
                <span className="text-text-dark mb-4 leading-none float-left ml-2">
                    <PriceLabel value={budgetData?.consumption || 0} currency={globalCurrency} interval={PriceIntervalEnum.ONE_TIME} />
                </span>
                {convertedBudgetLimit >= 0 && (
                    <span className="float-right mb-4 text-xs mr-5 opacity-75">
                        <PriceLabel value={convertedBudgetLimit} currency={globalCurrency} interval={PriceIntervalEnum.ONE_TIME} />
                    </span>
                )}
            </Row>
            <Row className={`block ${showAsSubCostCenter && "ml-40"}`}>
                <ColorsBar bars={colorBars} />
                <div className="w-full flex justify-center">
                    <PriceLabel value={budgetData?.forecast || 0} currency={globalCurrency} interval={PriceIntervalEnum.ONE_TIME} /> {t("label:forecast")}
                </div>
            </Row>
        </>
    )

    const getEmptyCostCenterListMessage = (description: string, content: any) => (
        <>
            {AuthzService.isDataUploaderGrantedForLoggedInUser() && !isTableFilterApplied(costCentersTable.data.filter) && <DataUploadAlert />}

            <Empty
                className="animation-appear"
                image="./icons/empty_table.svg"
                imageStyle={{
                    height: 150,
                    marginRight: "auto",
                    marginLeft: "auto",
                    marginTop: "15%",
                    marginBottom: "40px",
                    display: "inline-block",
                }}
                description={<span className="text-lg font-bold">{description}</span>}
            >
                <div className="flex flex-col justify-between items-center">
                    {content}
                    {AuthzService.isRightGrantedForLoggedInUser(RightEnum.CC1__ALL__MANAGE) && (
                        <Button className="mt-14" type="primary" onClick={() => setIsModalVisible(true)} icon={<PlusOutlined />}>
                            {t("action:cost_center:create")}
                        </Button>
                    )}
                </div>
            </Empty>
        </>
    )

    const handleSearch = useDebouncedCallback((searchString) => dispatch(updateTableSearch(TablesEnum.COST_CENTERS, searchString)), 800)

    const onExpandIcon = (props: any) => {
        // if row has a children, show circle icon
        if (props.record?.children?.length > 0) {
            return props.expanded ? (
                <div
                    role="button"
                    onClick={(e: any) => {
                        e.stopPropagation()
                        props.onExpand(props.record, e)
                    }}
                >
                    <UpCircleIcon />
                </div>
            ) : (
                <div
                    role="button"
                    onClick={(e: any) => {
                        e.stopPropagation()
                        props.onExpand(props.record, e)
                    }}
                >
                    <DownCircleIcon />
                </div>
            )
        }
        if (props.record?.parentCostCenter) {
            // if the category has a parent
            return <CornerDownRightArrowIcon />
        }
        return <></>
    }

    return (
        <div>
            <PageTitle titleKey="view:cost_centers.title">
                {(groupedCostCenters.length > 0 || costCentersTable.data.search) && (
                    <SearchInput onSearch={(searchString) => handleSearch.callback(searchString)} value={costCentersTable.data.search} />
                )}
                {AuthzService.isRightGrantedForLoggedInUser(RightEnum.CC1__ALL__MANAGE) && (
                    <Filter
                        table={TablesEnum.COST_CENTERS}
                        isFilterModalShowing={isFilterModalShowing}
                        setIsFilterModalShowing={setIsFilterModalShowing}
                        onFilter={(data) => applyFilter(TablesEnum.COST_CENTERS, data)}
                        options={{ employees }}
                    />
                )}

                <MassExportButton
                    serviceExportFunction={CostCenterService.export}
                    visible={AuthzService.isRightGrantedForLoggedInUser(RightEnum.CC1__ALL__READ) && costCentersTable.data?.docs?.length > 0}
                />

                {AuthzService.isRightGrantedForLoggedInUser(RightEnum.CC1__ALL__MANAGE) && (
                    <div>
                        {(groupedCostCenters.length > 0 || costCentersTable.data.search) && (
                            <Button type="primary" onClick={() => setIsModalVisible(true)} icon={<PlusOutlined />}>
                                {t("action:cost_center:create")}
                            </Button>
                        )}
                        <CostCenterFormModal isNew={true} isShowing={isModalVisible} onHide={() => setIsModalVisible(false)} />
                    </div>
                )}
            </PageTitle>

            <FilterSection table={TablesEnum.COST_CENTERS} options={{ employees }} />

            {pageLoading ? (
                <Loading />
            ) : costCentersTable.error ? (
                <ErrorTable onTableReload={() => dispatch(refetchTable(TablesEnum.COST_CENTERS))} isLoading={costCentersTable.isFetching} />
            ) : groupedCostCenters.length === 0 ? (
                !costCentersTable.data.search ? (
                    AuthzService.isRightGrantedForLoggedInUser(RightEnum.CC1__ALL__MANAGE) ? (
                        !isTableFilterApplied(costCentersTable.data.filter) ? (
                            getEmptyCostCenterListMessage(t("info:empty_state.cost_centers.title"), t("info:empty_state.cost_centers.message"))
                        ) : (
                            getEmptyCostCenterListMessage(t("info:no_filter_data_found.title.cost_center_1"), t("info:no_filter_data_found.message"))
                        )
                    ) : !isTableFilterApplied(costCentersTable.data.filter) ? (
                        getEmptyCostCenterListMessage(t("info:empty_state.cost_centers_approver.title"), <p>{t("info:empty_state.cost_centers_approver.message")}</p>)
                    ) : (
                        getEmptyCostCenterListMessage(t("info:no_filter_data_found.title.cost_center_1"), <p>{t("info:no_filter_data_found.message_approver")}</p>)
                    )
                ) : (
                    <div className="animation-appear">
                        <NoSearchDataFound />
                    </div>
                )
            ) : (
                <div>
                    <div className="overflow-auto p-2 animation-appear">
                        <Table
                            data-testid="costCenter1Table"
                            rowKey={(record) => record._id}
                            pagination={{
                                position: ["bottomRight"],
                                showSizeChanger: true,
                                current: costCentersTable.data.search ? 1 : costCentersTable.data.page,
                                pageSize: costCentersTable.data.limit,
                                hideOnSinglePage: false,
                                pageSizeOptions: ["5", "10", "20", "50", "100"],
                            }}
                            columns={columns}
                            onChange={updateTableOnChange(TablesEnum.COST_CENTERS, costCentersTable.data, false)}
                            loading={{
                                spinning: costCentersTable.isFetching,
                                indicator: <LoadingOutlined style={{ fontSize: 30, color: "black" }} spin />,
                            }}
                            dataSource={groupedCostCenters}
                            onRow={(costCenter) => ({
                                onClick: () => {
                                    dispatch(setCostCenter(costCenter))
                                    history.push(`/costCenters/${costCenter._id}`)
                                },
                            })}
                            expandIcon={(props) => onExpandIcon(props)}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}

export default CostCenters
