import { BudgetPeriodEnum } from "@finway-group/shared/lib/models"
import { Button, Dropdown, Menu } from "antd"
import { Moment } from "moment"
import React, { useEffect } from "react"

import { getInitialPeriodOption, translateDateString } from "Shared/utils/costCenter.utils"
import { getPreviousAndNextMonthsWithYear, getPreviousAndNextQuartersWithYear, getPreviousAndNextYears } from "Shared/utils/date.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"
import useUpdateEffect from "Shared/utils/hooks/useUpdateEffect"

interface CostCenterPeriodDropDownInterface {
    date: Moment
    budgetPeriod: BudgetPeriodEnum
    onSelect: (date: Moment) => void
}

const CostCenterPeriodDropDown = ({ date, budgetPeriod, onSelect }: CostCenterPeriodDropDownInterface) => {
    const [menuOptions, setMenuOptions] = useStateIfMounted<Array<string>>([])
    const initialOption = getInitialPeriodOption(date, budgetPeriod)
    const [selectedOption, setSelectedOption] = useStateIfMounted<string>(initialOption)

    useUpdateEffect(() => {
        if (initialOption !== selectedOption) {
            setSelectedOption(initialOption)
        }
    }, [initialOption])

    useEffect(() => {
        switch (budgetPeriod) {
            case BudgetPeriodEnum.MONTHLY:
                setMenuOptions(getPreviousAndNextMonthsWithYear(6, 6))
                break
            case BudgetPeriodEnum.QUARTERLY:
                setMenuOptions(getPreviousAndNextQuartersWithYear(6, 6))
                break
            case BudgetPeriodEnum.YEARLY:
                setMenuOptions(getPreviousAndNextYears(6, 6))
                break
            default:
                break
        }
    }, [budgetPeriod])

    const handleSelect = ({ key }: any) => {
        setSelectedOption(key)
        onSelect(key)
    }

    const exportDropDownOverlay = () => (
        <Menu onClick={handleSelect}>
            {menuOptions
                ?.filter((option) => option !== selectedOption)
                .map((option: string) => (
                    <Menu.Item key={option} className="flex items-center min-w-190 py-8">
                        <div className="flex items-center ml-12">
                            <p className="leading-none">
                                <span className="text-text">{translateDateString(option, budgetPeriod)}</span>
                            </p>
                        </div>
                    </Menu.Item>
                ))}
        </Menu>
    )

    return (
        <Dropdown overlay={exportDropDownOverlay} trigger={["click"]} className="z-100" arrow>
            <Button className="m-5">
                <span>{translateDateString(selectedOption, budgetPeriod)} </span>
                <i className="el-icon-caret-bottom el-icon--right" />
            </Button>
        </Dropdown>
    )
}

export default CostCenterPeriodDropDown
