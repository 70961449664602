import { Expense, Tax } from "@finway-group/shared/lib/models"
import { Alert, Button, Col, Row } from "antd"
import React from "react"

import { ExpenseData } from "Components/modals/expenseCreateForm.context"
import { syncTotalExpenseAmountsToItems } from "Shared/utils/expense.utils"

export interface FormAmountMismatchAlertInterface {
    elem: { isAmountsMismatch: boolean; msgText: string; btnText: string; updateExpense: (data: ExpenseData) => void; expense: Expense; taxValues: Array<Tax> }
    span?: number
    md?: number | object
    sm?: number | object
}

function FormAmountsMismatchAlert({ elem, ...columnProps }: FormAmountMismatchAlertInterface) {
    const { isAmountsMismatch, msgText, btnText, updateExpense, expense } = elem

    const handleClick = () => updateExpense(syncTotalExpenseAmountsToItems(expense.splits))

    if (!isAmountsMismatch) return null

    return (
        <Row gutter={[16, 16]} align="middle">
            <Col {...columnProps}>
                <Alert
                    showIcon
                    type="warning"
                    message={
                        <>
                            <span>{msgText}</span>
                            <Button className="button-warning-notification ml-4" type="text" onClick={handleClick}>
                                {btnText}
                            </Button>
                        </>
                    }
                />
            </Col>
        </Row>
    )
}

export default React.memo(FormAmountsMismatchAlert)
