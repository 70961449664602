import { Form, Modal } from "antd"
import { Store } from "antd/lib/form/interface"
import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import ArchiveModalForm from "Components/forms/archiveSearch.form"
import { TransactionTabTypeEnum } from "Features/pages/transactions/transactions.list"
import { NotificationService } from "Shared/services"
import { updateTableFilter, updateTableSearch } from "Shared/store/actions/tables/tableActions"
import { TablesEnum } from "Shared/store/reducers/tableConfigReducer"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

interface TransactionArchiveSearchModalProps {
    isShowing: boolean
    onCancel: () => void
}

const TransactionArchiveSearchModal = ({ isShowing, onCancel }: TransactionArchiveSearchModalProps) => {
    const { t } = useTranslation()
    const [formInstance] = Form.useForm()
    const [isLoading, setIsLoading] = useStateIfMounted(false)

    const dispatch = useDispatch()

    const onSubmit = async (values: Store) => {
        try {
            let filter = {}

            switch (values.transactionTabType) {
                case TransactionTabTypeEnum.INFLOW:
                    filter = { minTransactionAmount: "0" }
                    break
                case TransactionTabTypeEnum.OUTFLOW:
                    filter = { maxTransactionAmount: "0" }
                    break
            }

            setIsLoading(true)
            dispatch(updateTableSearch(TablesEnum.TRANSACTIONS_ARCHIVE, values.searchString, false)) // should not fetch
            dispatch(updateTableFilter(TablesEnum.TRANSACTIONS_ARCHIVE, { ...filter, minTransactionDate: values.period[0], maxTransactionDate: values.period[1] }))
            onHide()
        } catch (err) {
            setIsLoading(false)
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:error"))
        }
    }

    const onHide = () => {
        setIsLoading(false)
        onCancel()
    }

    return (
        <>
            <Modal
                className="ant-modal--card"
                title={t("action:archive.transaction_search")}
                visible={isShowing}
                maskClosable={false}
                onOk={formInstance.submit}
                onCancel={onHide}
                okText={t("action:confirm")}
                cancelText={t("action:cancel")}
                confirmLoading={isLoading}
                keyboard
            >
                <ArchiveModalForm formInstance={formInstance} onSubmit={onSubmit} archiveTable={TablesEnum.TRANSACTIONS_ARCHIVE} />
            </Modal>
        </>
    )
}

export default TransactionArchiveSearchModal
