import { FilterOutlined, LoadingOutlined } from "@ant-design/icons"
import { Button, Card, Col, Layout, Row } from "antd"
import { Store } from "antd/lib/form/interface"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { Download as DownloadIcon } from "react-feather"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import Icon from "Components/icon"
import ExportModal from "Components/modals/export.modal"
import ReportingFilterModal from "Components/modals/reportingFilter.modal"
import { useCostCenterOverLast12MonthsData, useCostCenterOverLast12MonthsFilter } from "Shared/hooks/analytics.hooks"
import { useCostCenterIds, useSubCostCenterIds } from "Shared/hooks/costCenter.hooks"
import { NotificationService, UserService } from "Shared/services"
import { fetchExpensesPerCostCenterOverLast12MonthsData, setExpensesPerCostCenterOverLast12MonthsFilter } from "Shared/store/actions/analytics/analyticsActions"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"
import { convertQueryFormatIntoFilterObject, filterOvertimeAnalyticsDataByDateRange } from "Shared/utils/report.utils"

import CostCenterOverTimeDiagram from "./costCenterOverTimeDiagram"

const ExpensesOverTimeAnalytics = React.memo(() => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [isExportModalShowing, setIsExportModalShowing] = useState<boolean>(false)
    const [isFilterModalShowing, setIsFilterModalShowing] = useStateIfMounted<boolean>(false)
    const [loading, setIsLoading] = useStateIfMounted(false)
    const [isGroupDiagram, setIsGroupDiagram] = useState(false)
    const [expenseOverTimeView, setExpenseOverTimeView] = useState<Array<any>>([])
    const costCenters = useSubCostCenterIds(true, true)
    const expenseData = useCostCenterOverLast12MonthsData()
    const reportFilter = useCostCenterOverLast12MonthsFilter()

    const handleSubmit = async (values: Store) => {
        setIsLoading(true)
        try {
            const filter = convertQueryFormatIntoFilterObject(values)
            dispatch(setExpensesPerCostCenterOverLast12MonthsFilter(filter))
            setIsLoading(false)
            setIsFilterModalShowing(false)
        } catch (err) {
            setIsLoading(false)
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("notification:reports.no_data"))
        }
    }

    const applyFilterToView = async () => {
        setIsLoading(true)
        const isMinDateSet = moment.isMoment(reportFilter.minDate)
        const isMaxDateSet = moment.isMoment(reportFilter.maxDate)

        // const last12MonthsResData = await AnalyticsService.fetchExpensesPerCostCenterOverLast12Months(reportFilter.costCenters, globalCurrency);
        const last12MonthsResData = [...expenseData]

        const defaultStartDate = moment().subtract(11, "month").startOf("month")
        const defaultEndDate = moment()

        if (isMinDateSet || isMaxDateSet) {
            const now = moment()

            let monthCount = 12
            const anyLast12MonthsResData = last12MonthsResData as any // So we can use .map
            // Sample the given month length given by the backend.
            if (anyLast12MonthsResData.length > 0) {
                monthCount = anyLast12MonthsResData[0].data.length
            }

            let minMonthIndex = 0
            let maxMonthIndex = monthCount

            if (isMinDateSet) {
                // -1 So that the starting date is included.
                minMonthIndex = monthCount - Math.floor(now.diff(reportFilter.minDate, "months", true)) - 1
            }

            if (isMaxDateSet) {
                maxMonthIndex = monthCount - Math.floor(now.diff(reportFilter.maxDate, "months", true))
            }

            const filteredLast12MonthsResData = anyLast12MonthsResData.map((info: any) => ({
                ...info,
                data: filterOvertimeAnalyticsDataByDateRange(
                    isMinDateSet ? moment(reportFilter.minDate) : defaultStartDate,
                    isMaxDateSet ? moment(reportFilter.maxDate) : defaultEndDate,
                    info.data,
                ),
            }))

            setExpenseOverTimeView(filteredLast12MonthsResData)
        } else {
            setExpenseOverTimeView(last12MonthsResData.map((info: any) => ({ ...info, data: filterOvertimeAnalyticsDataByDateRange(defaultStartDate, defaultEndDate, info.data) })))
        }
        setIsLoading(false)
    }

    // When visiting/revisiting the reporting page, reset all the filters
    useEffect(() => {
        setIsLoading(true)

        const initFilter = {
            minDate: "",
            maxDate: "",
            costCenters,
        }
        dispatch(setExpensesPerCostCenterOverLast12MonthsFilter(initFilter))

        setExpenseOverTimeView(expenseData)
        setIsLoading(false)
    }, [])

    // When filter changes, fetch the newest data with the filtered cost centers
    useEffect(() => {
        dispatch(fetchExpensesPerCostCenterOverLast12MonthsData())
    }, [reportFilter])

    // TODO: Convert to useMemo
    // When the data is updated, apply time filter to view
    useEffect(() => {
        applyFilterToView()
    }, [expenseData])

    return (
        <>
            <Row gutter={[16, 16]}>
                {/* action buttons */}
                <Col xs={24} md={24}>
                    <div className="flex justify-between items-center">
                        <h2 className="py-8 mb-0">{t("label:expenses_by_cost_center")}</h2>
                        <div className="flex">
                            <Button className="btn-default" onClick={() => setIsGroupDiagram(!isGroupDiagram)}>
                                <Icon icon={isGroupDiagram ? "report_horizontal" : "report_vertical"} />
                            </Button>
                            <Button className="btn-default ml-10" onClick={() => setIsFilterModalShowing(true)}>
                                <FilterOutlined style={{ color: "#606C7E" }} />
                                {t("action:filter")}
                            </Button>

                            <Button className="btn-default ml-10" onClick={() => setIsExportModalShowing(true)}>
                                <DownloadIcon />
                                <span>{t("action:export")}</span>
                            </Button>
                        </div>
                    </div>
                </Col>
                <Col xs={24} md={24}>
                    {/* BAR DIAGRAM FOR COST CENTERS */}
                    <Card>
                        {loading ? (
                            <Layout style={{ backgroundColor: "#FFFFFF", alignItems: "center", height: "400px" }}>
                                <div className="flex flex-col mt-auto mb-auto">
                                    <LoadingOutlined style={{ fontSize: 30 }} spin />
                                </div>
                            </Layout>
                        ) : expenseData?.length > 0 ? (
                            <React.Fragment>
                                <CostCenterOverTimeDiagram dataForCCOverTimeDiagram={expenseOverTimeView} grouped={isGroupDiagram} />
                            </React.Fragment>
                        ) : (
                            <div className="flex justify-center items-center h-100">
                                <p>{t("missing:expense_data")}</p>
                            </div>
                        )}
                    </Card>
                </Col>
            </Row>

            <ReportingFilterModal isVisible={isFilterModalShowing} reportFilter={reportFilter} onSubmit={handleSubmit} onCancel={setIsFilterModalShowing} />
            <ExportModal isShowing={isExportModalShowing} onCancel={setIsExportModalShowing} />
        </>
    )
})

export default ExpensesOverTimeAnalytics
