import { Button } from "antd"
import React, { MouseEvent } from "react"

import { AntIconType } from "Components/icons/antIcon.types"

type SocialLoginButtonType = {
    onClick: (e: MouseEvent<HTMLElement>) => void
    Icon: (props: AntIconType) => JSX.Element
    text: string
}

export default function SocialLoginButton({ onClick, Icon, text }: SocialLoginButtonType) {
    return (
        <Button
            size="large"
            className="w-full bg-white font-medium border-1 border-finway-input-grey rounded-lg border-solid text-finway-primary gap-10 flex items-center h-40 shadow-none"
            type="primary"
            onClick={onClick}
            style={{
                textShadow: "none",
            }}
        >
            <Icon className="w-20" />
            <span className="m-0">{text}</span>
        </Button>
    )
}
