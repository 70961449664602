import i18n from "Shared/locales/i18n"

export interface ErrorInterface {
    response: ResponseInterface
    message: string
}
interface ResponseInterface {
    status: number
    data: {
        errors: Array<string>
        error: string
    }
}

export const extractErrors = ({ status, data: { errors, error } }: ResponseInterface) => {
    const extractedErrors: Array<string> = []
    if (Array.isArray(errors) && errors.length > 0) errors.forEach((e: any) => extractedErrors.push(e.msg))
    else if (error) extractedErrors.push(error)
    else extractedErrors.push(i18n.t("error:unknown"))
    return extractedErrors
}

export const isClientErrorCode = (err: any) => {
    if (err?.response?.status && err.response.status >= 400 && err.response.status < 500) {
        return true
    }
    return false
}
