import Icon from "@ant-design/icons"
import React from "react"

const walletSvg = () => (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.2456 4.18636H1.87285C1.37632 4.18578 0.900288 3.98827 0.549188 3.63717C0.198088 3.28607 0.000583125 2.81005 0 2.31352C0.000583361 1.70011 0.244515 1.112 0.678257 0.678257C1.112 0.244515 1.70011 0.000583361 2.31352 0L8.48289 0C9.0963 0.000583361 9.68441 0.244515 10.1182 0.678257C10.5519 1.112 10.7958 1.70011 10.7964 2.31352V3.63553C10.7964 3.78162 10.7384 3.92172 10.6351 4.02503C10.5318 4.12833 10.3917 4.18636 10.2456 4.18636ZM2.31352 1.10167C1.99229 1.10226 1.6844 1.23012 1.45726 1.45726C1.23012 1.6844 1.10226 1.99229 1.10167 2.31352C1.10167 2.51804 1.18292 2.71419 1.32755 2.85882C1.47217 3.00344 1.66832 3.08469 1.87285 3.08469H9.69473V2.31352C9.69415 1.99229 9.56629 1.6844 9.33915 1.45726C9.11201 1.23012 8.80411 1.10226 8.48289 1.10167H2.31352Z" />
        <path d="M10.6862 12.9998H2.31352C1.70011 12.9992 1.112 12.7553 0.678257 12.3215C0.244515 11.8878 0.000583361 11.2997 0 10.6863L0 2.31353C0 2.16744 0.0580345 2.02733 0.161336 1.92403C0.264638 1.82073 0.404746 1.7627 0.550837 1.7627C0.696928 1.7627 0.837036 1.82073 0.940338 1.92403C1.04364 2.02733 1.10167 2.16744 1.10167 2.31353V10.6863C1.10226 11.0075 1.23012 11.3154 1.45726 11.5425C1.6844 11.7697 1.99229 11.8975 2.31352 11.8981H10.6862C11.0075 11.8975 11.3154 11.7697 11.5425 11.5425C11.7696 11.3154 11.8975 11.0075 11.8981 10.6863V5.39822C11.8975 5.077 11.7696 4.7691 11.5425 4.54196C11.3154 4.31482 11.0075 4.18696 10.6862 4.18638H8.61069C8.46459 4.18638 8.32449 4.12834 8.22118 4.02504C8.11788 3.92174 8.05985 3.78163 8.05985 3.63554C8.05985 3.48945 8.11788 3.34934 8.22118 3.24604C8.32449 3.14274 8.46459 3.0847 8.61069 3.0847H10.6862C11.2996 3.08529 11.8878 3.32922 12.3215 3.76296C12.7552 4.1967 12.9992 4.78482 12.9998 5.39822V10.6863C12.9992 11.2997 12.7552 11.8878 12.3215 12.3215C11.8878 12.7553 11.2996 12.9992 10.6862 12.9998Z" />
        <path d="M12.4482 10.3565H8.92289C8.30931 10.3565 7.72086 10.1128 7.28699 9.67891C6.85312 9.24504 6.60938 8.65659 6.60938 8.04301C6.60938 7.42943 6.85312 6.84097 7.28699 6.40711C7.72086 5.97324 8.30931 5.72949 8.92289 5.72949H12.4482C12.5943 5.72949 12.7344 5.78753 12.8377 5.89083C12.9411 5.99413 12.9991 6.13424 12.9991 6.28033V9.80569C12.9991 9.95178 12.9411 10.0919 12.8377 10.1952C12.7344 10.2985 12.5943 10.3565 12.4482 10.3565ZM8.92289 6.83117C8.60149 6.83117 8.29325 6.95884 8.06599 7.18611C7.83872 7.41337 7.71105 7.72161 7.71105 8.04301C7.71105 8.36441 7.83872 8.67265 8.06599 8.89991C8.29325 9.12717 8.60149 9.25485 8.92289 9.25485H11.8974V6.83117H8.92289Z" />
        <path d="M9.80554 8.70385C10.1706 8.70385 10.4665 8.4079 10.4665 8.04284C10.4665 7.67778 10.1706 7.38184 9.80554 7.38184C9.44047 7.38184 9.14453 7.67778 9.14453 8.04284C9.14453 8.4079 9.44047 8.70385 9.80554 8.70385Z" />
    </svg>
)

const WalletIcon = (props: any) => <Icon component={walletSvg} {...props} />

export default WalletIcon
