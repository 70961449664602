import Icon from "@ant-design/icons"
import React from "react"

const trashSvg = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2 3.66699H3.33333H14" stroke="#606C7E" strokeLinecap="round" strokeLinejoin="round" />
        <path
            d="M13.1663 3.6665C13.1663 3.39036 12.9425 3.1665 12.6663 3.1665C12.3902 3.1665 12.1663 3.39036 12.1663 3.6665H13.1663ZM3.83301 3.6665C3.83301 3.39036 3.60915 3.1665 3.33301 3.1665C3.05687 3.1665 2.83301 3.39036 2.83301 3.6665H3.83301ZM4.83301 3.66667C4.83301 3.94281 5.05687 4.16667 5.33301 4.16667C5.60915 4.16667 5.83301 3.94281 5.83301 3.66667H4.83301ZM10.1663 3.66667C10.1663 3.94281 10.3902 4.16667 10.6663 4.16667C10.9425 4.16667 11.1663 3.94281 11.1663 3.66667H10.1663ZM12.1663 3.6665V12.9998H13.1663V3.6665H12.1663ZM12.1663 12.9998C12.1663 13.4601 11.7932 13.8332 11.333 13.8332V14.8332C12.3455 14.8332 13.1663 14.0124 13.1663 12.9998H12.1663ZM11.333 13.8332H4.66634V14.8332H11.333V13.8332ZM4.66634 13.8332C4.2061 13.8332 3.83301 13.4601 3.83301 12.9998H2.83301C2.83301 14.0124 3.65382 14.8332 4.66634 14.8332V13.8332ZM3.83301 12.9998V3.6665H2.83301V12.9998H3.83301ZM5.83301 3.66667V2.33333H4.83301V3.66667H5.83301ZM5.83301 2.33333C5.83301 1.8731 6.2061 1.5 6.66634 1.5V0.5C5.65382 0.5 4.83301 1.32081 4.83301 2.33333H5.83301ZM6.66634 1.5H9.33301V0.5H6.66634V1.5ZM9.33301 1.5C9.79325 1.5 10.1663 1.8731 10.1663 2.33333H11.1663C11.1663 1.32081 10.3455 0.5 9.33301 0.5V1.5ZM10.1663 2.33333V3.66667H11.1663V2.33333H10.1663Z"
            fill="#606C7E"
        />
        <path d="M6.66699 7V11" stroke="#606C7E" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M9.33301 7V11" stroke="#606C7E" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
)

const TrashIcon = (props: any) => <Icon component={trashSvg} {...props} />

export default TrashIcon
