import { EllipsisOutlined, EyeOutlined as EyeIcon } from "@ant-design/icons"
import { Expense, RightEnum, TripFolder } from "@finway-group/shared/lib/models"
import { isDiscountActionPossibleForExpense } from "@finway-group/shared/lib/utils"
import { Button, Dropdown, Menu, Modal, Switch } from "antd"
import { SwitchChangeEventHandler } from "antd/lib/switch"
import React from "react"
import { Trash } from "react-feather"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"

import ExportDropDownMenu from "Components/exportDropDownMenu"
import { CircleArrowIcon } from "Components/icons"
import PriceLabel from "Components/priceLabel"
import { ExpenseExport } from "Features/export/expenseExport"
import { useExpenseDetailsContext } from "Shared/context/expenseDetails.context"
import { useArchiveInterval, useCompany } from "Shared/hooks/company.hooks"
import { detachExpense } from "Shared/hooks/expense.hooks"
import { ApprovalProcessService, AuthzService, ExpenseService, NotificationService } from "Shared/services"
import DialogService from "Shared/services/dialog.service"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { deleteExpense, resetExpense, updateExpense } from "Shared/store/actions/expense/expenseActions"
import { isExpenseArchived, isExpenseInvoiceProvided, isExpenseNotResettable, isFolderExpense, isPendingExpense } from "Shared/utils/expense.utils"
import { isFolderAttachableAndDetachable } from "Shared/utils/folder.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

import RemoveFromFolderIcon from "../icons/removeFromFolder.icon.tsx"
import { AttachDiscountMenuItemContent } from "./attachDiscountMenuItem"
import { ExpenseEditButton } from "./expenseEditButton"

interface FolderContentInteractablesInterface {
    expense: Expense
    folder: TripFolder
}
export const FolderContentInteractables = ({ expense, folder }: FolderContentInteractablesInterface) => {
    const { t } = useTranslation()
    const [isLoading, setLoading] = useStateIfMounted(false)
    const dispatch = useDispatch()
    const company = useCompany()
    const showDetachButton = isFolderAttachableAndDetachable(folder)
    const history = useHistory()
    const { setIsShowingImportProtocolModal, setIsShowingDiscountForm } = useExpenseDetailsContext()
    const archiveAfterXDays = useArchiveInterval()
    const archived = isExpenseArchived(expense, archiveAfterXDays)
    const expenseIsGobdCompliantInvoice = expense.isGobdCompliantInvoice()
    const isEditableBasedOnArchive = ExpenseService.isExpenseEditableBasedOnArchive(archived)
    const hasPayRight = AuthzService.canLoggedInUserPayByBank()

    const onlyFileExport = ExpenseService.onlyAllowFileExports(expense)

    const onBillableSwitch: SwitchChangeEventHandler = async (checked) => {
        setLoading(true)
        expense.billable = checked
        try {
            await updateExpense(expense.id, expense)(dispatch)
        } catch (err) {
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:error"))
        } finally {
            setLoading(false)
        }
    }

    const onDetachExpense = async () => {
        try {
            setLoading(true)
            const showDetachedFolderConfirmation = isPendingExpense(expense)
            const isFolderAmountChangeApproved = await ApprovalProcessService.confirmFolderAmountChange(
                -expense.totalGrossPrice,
                folder,
                company,
                showDetachedFolderConfirmation ? expense : undefined,
            )
            if (!isFolderAmountChangeApproved) {
                setLoading(false)
                return
            }

            await detachExpense(expense)
            NotificationService.send(NotificationTypeEnum.SUCCESS, t(`notification:request.detached.title`), t(`notification:request.detached.message`))
            history.replace(`/expenses/${folder.id}`) // Move cursor back to the folder itself
        } catch (err) {
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:error"))
        } finally {
            setLoading(false)
        }
    }

    const onDetachAndDelete = () => {
        Modal.confirm({
            title: t("confirm:request.delete.title"),
            content: t("confirm:request.delete.message"),
            cancelText: t("confirm:request.delete.cancel"),
            type: "warning",
            okText: t("confirm:request.delete.confirm"),
            async onOk() {
                try {
                    setLoading(true)
                    const showDetachedFolderConfirmation = isPendingExpense(expense)
                    const isFolderAmountChangeApproved = await ApprovalProcessService.confirmFolderAmountChange(
                        -expense.totalGrossPrice,
                        folder,
                        company,
                        showDetachedFolderConfirmation ? expense : undefined,
                    )
                    if (!isFolderAmountChangeApproved) {
                        setLoading(false)
                        return
                    }

                    await detachExpense(expense)
                    await deleteExpense(expense.id)(dispatch)
                    NotificationService.send(
                        NotificationTypeEnum.WARNING,
                        t(`notification:request.delete.title`),
                        t(`notification:request.delete.message`, { expenseNumber: expense.expenseNumber }),
                    )
                } catch (err) {
                    NotificationService.send(NotificationTypeEnum.ERROR, t("error:error"), err)
                } finally {
                    setLoading(false)
                }
            },
        })
    }

    const onResetStatus = async () => {
        if (await DialogService.confirmExpenseStatusReset(expense)) {
            try {
                setLoading(true)
                await resetExpense(expense.id)(dispatch)
                NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:request.reset.title"), t("notification:request.reset.message"))
            } catch (err) {
                NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:error"))
            } finally {
                setLoading(false)
            }
        }
    }

    const onActionClick = ({ key, domEvent }: any) => {
        domEvent.stopPropagation()

        switch (key) {
            case "detach":
                onDetachExpense()
                break
            case "delete":
                onDetachAndDelete()
                break
            case "reset_status":
                onResetStatus()
                break
            case "datev_import_protocol":
                setIsShowingImportProtocolModal(true)
                break
            case "attach_discount":
                setIsShowingDiscountForm(true)
                break
            default:
                break
        }
    }

    const isFolderDeleteDisabled = folder.cannotBeDeleted() || folder.isSuccessfullyProcessed() || folder.deleted
    const isDeleteAndResetAllowed = AuthzService.isLoggedInUserGlobalApprover() && !expenseIsGobdCompliantInvoice && isEditableBasedOnArchive

    const actionMenu = (
        <Menu onClick={onActionClick}>
            {showDetachButton && !expenseIsGobdCompliantInvoice && isEditableBasedOnArchive && (
                <Menu.Item key="detach" className="flex items-center min-w-190 py-8">
                    <div className="flex items-center justify-center rounded-full w-16 h-16">
                        <RemoveFromFolderIcon />
                    </div>
                    <div className="flex items-center ml-12">
                        <p className="leading-none">
                            <span className="text-text">{t("action:request.folder.detach")}</span>
                        </p>
                    </div>
                </Menu.Item>
            )}
            {isDeleteAndResetAllowed && (
                <Menu.Item key="delete" className="flex items-center min-w-190 py-8" disabled={isFolderDeleteDisabled}>
                    <div className="flex items-center justify-center rounded-full w-16 h-16">
                        <Trash strokeWidth={1} />
                    </div>
                    <div className="flex items-center ml-12">
                        <p className="leading-none">
                            <span className="text-text">{t("action:request.delete")}</span>
                        </p>
                    </div>
                </Menu.Item>
            )}
            {isDeleteAndResetAllowed && (
                <Menu.Item key="reset_status" className="flex items-center min-w-190 py-8" disabled={isExpenseNotResettable(expense)}>
                    <div className="flex items-center justify-center rounded-full w-16 h-16">
                        <CircleArrowIcon strokeWidth={1} />
                    </div>
                    <div className="flex items-center ml-12">
                        <p className="leading-none">
                            <span className="text-text">{t("action:request.reset_status")}</span>
                        </p>
                    </div>
                </Menu.Item>
            )}
            {expense.datevOnlineInformation?.datevProtocolEntries?.length && (
                <Menu.Item key="datev_import_protocol" className="flex items-center min-w-190 py-8">
                    <div className="flex items-center justify-center rounded-full w-16 h-16">
                        <EyeIcon />
                    </div>
                    <div className="flex items-center ml-12">
                        <p className="leading-none">
                            <span className="text-text">{t("action:request.show_datev_import_protocol")}</span>
                        </p>
                    </div>
                </Menu.Item>
            )}
            {hasPayRight && isDiscountActionPossibleForExpense(expense) && (
                <Menu.Item key="attach_discount" className="flex items-center min-w-190 py-8">
                    <AttachDiscountMenuItemContent />
                </Menu.Item>
            )}
        </Menu>
    )

    const folderDisplayAmount = expense.getUsedOrActiveDiscount()?.reducedAmount?.gross ?? expense.totalGrossPrice

    return (
        <div className="flex gap-24 items-center mb-8">
            <div className="flex gap-4 items-center">
                <div>{t("input:request.billable")}</div>
                <div>
                    <Switch checked={expense.billable} onChange={onBillableSwitch} disabled={isLoading} />
                </div>
            </div>
            <div className="flex gap-8 items-center">
                <div>{isFolderExpense(expense) ? t("label:folder.total") : t("label:amount")}</div>
                <div data-testid="folderPriceLabel" className="font-bold text-lg">
                    <PriceLabel value={folderDisplayAmount} currency={expense.currency} />
                </div>
            </div>
            {!isFolderExpense(expense) && (
                <div className="flex gap-8">
                    {(showDetachButton || AuthzService.isRightGrantedForLoggedInUser(RightEnum.EXPENSE__ALL__DELETE)) && (
                        <Dropdown overlay={actionMenu}>
                            <Button>
                                <EllipsisOutlined />
                            </Button>
                        </Dropdown>
                    )}
                    {isEditableBasedOnArchive && <ExpenseEditButton expense={expense} folder={folder} />}
                    {AuthzService.isRightGrantedForLoggedInUser(RightEnum.EXPENSE__ALL__EXPORT) && isExpenseInvoiceProvided(expense) && (
                        <ExpenseExport
                            render={({ onExport, isExporting }) => (
                                <ExportDropDownMenu
                                    expenses={[expense]}
                                    onExport={onExport}
                                    isLoading={isExporting}
                                    includeSepa
                                    noIcon
                                    primaryButton
                                    asDottedButton
                                    onlyFileExport={onlyFileExport}
                                />
                            )}
                        />
                    )}
                </div>
            )}
        </div>
    )
}
