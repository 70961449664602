import {
    ApprovalScopeEnum,
    ApprovalStatusEnum,
    Employee,
    Expense,
    ExpenseKindEnum,
    ExpenseRequestTypeEnum,
    ExpenseStatusEnum,
    Hospitality,
    InboxInvoice,
    InvoiceSplit,
    Mileage,
    PerDiem,
    SplitTypeEnum,
    TransactionData,
    TripFolder,
    VendorTypeEnum,
} from "@finway-group/shared/lib/models"
import { ExpensePaymentFlowInformationEnum } from "@finway-group/shared/lib/models/expense/expensePaymentFlowInformation.enum"
import { ExpensePaymentOptionEnum } from "@finway-group/shared/lib/models/expense/expensePaymentOption.enum"
import { getApprovalProcess } from "@finway-group/shared/lib/utils/approvalProcess.utils"
import { willUpdateFlagExpenseAsGobdCompliantInvoice } from "@finway-group/shared/lib/utils/gobdInvoiceCompliance.utils"
import { Button, Form, Modal } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"

import ExpenseForm from "Components/forms/expenseForm/expenseForm"
import { EXPENSE_KINDS_WITHOUT_INVOICE, FINWAY_ADMIN_EMAIL, NOT_SET_VALUE } from "Shared/config/consts"
import { useCompany } from "Shared/hooks/company.hooks"
import { useEmployees } from "Shared/hooks/employee.hooks"
import { useExpense } from "Shared/hooks/expense.hooks"
import { useFormTouchedHandler } from "Shared/hooks/form.hooks"
import { usePerDiemDestinations } from "Shared/hooks/perDiemDestination.hooks"
import { useRolesMap } from "Shared/hooks/role.hooks"
import { useTaxes } from "Shared/hooks/tax.hooks"
import { useVendors } from "Shared/hooks/vendor.hooks"
import { ApprovalProcessService, ExpenseService, NotificationService, UserService } from "Shared/services"
import DialogService from "Shared/services/dialog.service"
import { NotificationDurationSec, NotificationTypeEnum } from "Shared/services/notification.service"
import { createExpense, createPreApprovedSubscription, updateExpense } from "Shared/store/actions/expense/expenseActions"
import { deleteManyInboxInvoices } from "Shared/store/actions/inboxInvoice/inboxInvoiceActions"
import { getNextApproverId } from "Shared/utils/approvalProcess.utils"
import { getAbsentApprovers, getAvailableReplacementsForAbsentApprover } from "Shared/utils/employee.utils"
import {
    addInvoiceCommentsIfNeeded,
    createLinkToRequestFunction,
    deleteUnnecessaryExpenseFormData,
    doTotalAndInlineAmountsMismatch,
    isExpenseWithoutInvoice,
    isFolderExpense,
    isMileageExpense,
    isMileageOrPerDiem,
    isPerDiemExpense,
    syncTotalExpenseAmountsToItems,
} from "Shared/utils/expense.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"
import { isPerDiemTripNotReimbursable } from "Shared/utils/perDiem.utils"

import ExpenseFormContext from "./expenseCreateForm.context"
import ReplacementForAbsentApproverModal from "./replacementForAbsentApprover.modal"

interface ExpenseCreateFormInterface {
    kind?: ExpenseKindEnum
    type: ExpenseRequestTypeEnum
    hideExpenseKindRadioOption?: boolean
    isShowing: boolean
    isNew: boolean
    isPreApprovedSubscription?: boolean
    transactionData?: TransactionData
    isReimbursement?: boolean
    onExpenseCreated?: (expense: Expense) => void
    onCancel: () => void
    inboxInvoices?: Array<InboxInvoice>
    inboxOcrSource?: InboxInvoice

    // Used when we don't want to load the expense from the redux, e.g. editing a sub-expense
    expenseOverride?: Expense | PerDiem | Mileage | Hospitality

    // Used when we're making an sub-expense for a folder
    folder?: TripFolder

    // Enables/Disables request types selection. By default all are enabled.
    reimbursementRequestsEnabled?: { standard: boolean; mileage: boolean; hospitality: boolean; perDiem: boolean }
}
const ExpenseCreateFormModal: React.FC<ExpenseCreateFormInterface> = ({
    type,
    kind = ExpenseKindEnum.ONE_TIME_EXPENSE,
    isShowing,
    isNew = true,
    isPreApprovedSubscription = false,
    isReimbursement = false,
    hideExpenseKindRadioOption,
    transactionData,
    onExpenseCreated,
    onCancel,
    inboxInvoices,
    inboxOcrSource,
    reimbursementRequestsEnabled,
    expenseOverride,
    folder,
}) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const loggedInUser = UserService.getLoggedInEmployeeProfile()
    const employees = useEmployees({ excludeDeleted: true, includeAutoApprover: true })
    const [isLoading, setIsLoading] = useStateIfMounted<boolean>(false)
    const [isUploading, setIsUploading] = useStateIfMounted(false)
    const [isBuildingApprovalProcesses, setIsBuildingApprovalProcesses] = useStateIfMounted(false)
    const [isReplacementForAbsentApproverShowing, setIsReplacementForAbsentApproverShowing] = useStateIfMounted(false)
    const [absentApprovers, setAbsentApprovers] = useStateIfMounted<Array<Employee>>([])
    const [availableReplacementsForAbsentApprover, setAvailableReplacementsForAbsentApprover] = useStateIfMounted<Array<Employee>>([])
    const [checkIfApproverInAbsence, setCheckIfApproverInAbsence] = useStateIfMounted(true)
    const [replacementForAbsentApprover, setReplacementForAbsentApprover] = useStateIfMounted("")
    const rolesMap = useRolesMap({ excludeDeleted: true })
    const { destinations: perDiemDestinations } = usePerDiemDestinations()
    const history = useHistory()

    const [expenseForm] = Form.useForm()
    const taxes = useTaxes()
    const vendors = useVendors()
    const { isFormTouched, handleTouchForm, handleResetTouchForm } = useFormTouchedHandler()

    // Separating this to its on reduxExpense because we have to maintain the amount of hook calls between renders.
    const reduxExpense = useExpense()
    const expenseDetails = expenseOverride ?? reduxExpense

    const company = useCompany()

    const isInvoiceRequest = type === ExpenseRequestTypeEnum.INVOICE
    const isPurchaseRequest = type === ExpenseRequestTypeEnum.PURCHASE
    isReimbursement = type === ExpenseRequestTypeEnum.REIMBURSEMENT
    const isTripFolder = type === ExpenseRequestTypeEnum.TRIP_FOLDER

    const onSubmit = async (
        expense: ((Expense & { replacementForAbsentApprover?: string }) | Mileage | Hospitality | PerDiem | TripFolder) & { initializeNotStartedApprovalProcess?: boolean },
        extraData?: { originalApproverId: string },
    ) => {
        setIsLoading(true)
        const approvalScope = isPurchaseRequest ? ApprovalScopeEnum.EXPENSE_PURCHASE : ApprovalScopeEnum.EXPENSE_INVOICE
        const approvalProcess = getApprovalProcess(approvalScope, expense.approvalProcesses)
        if (checkIfApproverInAbsence && !replacementForAbsentApprover) {
            const absentApprovers = approvalProcess ? getAbsentApprovers(approvalProcess, employees) : []
            if (absentApprovers.length > 0) {
                setAvailableReplacementsForAbsentApprover(getAvailableReplacementsForAbsentApprover(expense, employees, rolesMap))
                setAbsentApprovers(absentApprovers)
                setIsReplacementForAbsentApproverShowing(true)
                setIsLoading(false)
                return
            }
        }
        const autoApprover = employees.find(({ email }) => email === FINWAY_ADMIN_EMAIL)

        ;(expense as any).replacementForAbsentApprover = replacementForAbsentApprover

        // check for duplicate
        if (!(await ExpenseService.checkForDuplicate(expense, expense.invoiceNumber, { ignoreIds: inboxInvoices?.map(({ id }) => id) }))) {
            setIsLoading(false)
            return
        }

        // confirm reporting only status
        if (
            expense.paymentFlowInformation === ExpensePaymentFlowInformationEnum.REPORTING_ONLY &&
            !(await DialogService.confirmPaymentFlowInformationReportingOnlyWarningOnCreation())
        ) {
            setIsLoading(false)
            return
        }

        // confirm mismatching amounts
        if (doTotalAndInlineAmountsMismatch(expense, taxes, isNew) && (await DialogService.confirmAmountAdjustment())) {
            const { totalNetPrice, totalTaxPrice, totalGrossPrice, taxRate } = syncTotalExpenseAmountsToItems(expense.splits)
            const updatedExpense = new Expense({ ...expense, totalNetPrice, totalTaxPrice, totalGrossPrice, taxRate })
            const approvalProcesses = await ApprovalProcessService.getApprovalProcessesAfterExpenseUpdate(updatedExpense, company, {
                previousExpense: expense,
                awaitConfirmation: true,
            })
            if (approvalProcesses) {
                updatedExpense.approvalProcesses = approvalProcesses
            }
            expense = updatedExpense
        }

        if (isPerDiemExpense(expense)) {
            if (isPerDiemTripNotReimbursable(expense, perDiemDestinations)) {
                await DialogService.confirmPerDiemLessThan8Hours()
                setIsLoading(false)
                return
            }
        }

        if (
            company.gobdCompliance?.enforceGobdCompliantInvoice &&
            willUpdateFlagExpenseAsGobdCompliantInvoice(reduxExpense, expense) &&
            !(await DialogService.confirmExpenseGobdCompliantInvoiceFlagging())
        ) {
            setIsLoading(false)
            return
        }

        // if cost center 2 or expense account is "not set" remove value because api "" (empty string) is not a valid ID
        // and undefined select value is not supported by antd :/
        if (expense.costCenter2 === NOT_SET_VALUE) expense.costCenter2 = undefined
        if (expense.expenseAccount?._id === NOT_SET_VALUE || !expense.expenseAccount?._id) expense.expenseAccount = undefined
        if (expense.taxRate?._id === NOT_SET_VALUE) expense.taxRate = undefined

        // Clean split's cc2 or expense account from NOT_SET_VALUE
        for (const split of expense.splits) {
            if (split.costCenter2 === NOT_SET_VALUE) split.costCenter2 = undefined
            if (split.expenseAccount?._id === NOT_SET_VALUE || !split.expenseAccount?._id) split.expenseAccount = undefined as any
        }

        // confirm auto approver
        const nextApproverId = getNextApproverId(expense.approvalProcesses)
        const isFirstStepTheAutoApprover = nextApproverId && nextApproverId === autoApprover?.id // auto approver can only be selected as first approver and will be alone in the step.
        const willThereBeAtLeastAnApproverOtherThanTheAutoApprover = (() => {
            if (!isFirstStepTheAutoApprover) return true
            if (!approvalProcess?.steps) return false
            return approvalProcess?.steps.slice(1)?.some((step) => step.status !== ApprovalStatusEnum.SKIPPED)
        })()

        if (
            !willThereBeAtLeastAnApproverOtherThanTheAutoApprover &&
            company?.showAutoApproverWarning &&
            (extraData?.originalApproverId !== autoApprover?.id || extraData?.originalApproverId !== nextApproverId || isNew) &&
            expense.paymentFlowInformation !== ExpensePaymentFlowInformationEnum.REPORTING_ONLY &&
            !(await DialogService.confirmAutoApprover())
        ) {
            setIsLoading(false)
            return
        }

        if (expense.splitType === SplitTypeEnum.SPLIT && expense.splits.length === 1) {
            const split = expense.splits[0] as InvoiceSplit
            split.taxRate = expense.taxRate
            split.netPrice = expense.totalNetPrice
            split.grossPrice = expense.totalGrossPrice
            split.taxPrice = expense.totalTaxPrice
        }

        if (isMileageOrPerDiem(expense)) {
            // Mileage and per diem will always have the requester as the vendor
            expense.creditorUser = { _id: expense.requestedBy?._id }
            expense.vendorType = VendorTypeEnum.USER
            // For mileage requests, the invoiceDate will be datePurchased.
            // For per diem requests, the invoiceDate will be datePurchased, derived from the last date of the trip.
            expense.invoiceDate = expense.datePurchased
        }

        if (isExpenseWithoutInvoice(expense)) {
            expense.invoices = []
        }

        if (isNew && folder && expense.kind !== ExpenseKindEnum.TRIP_FOLDER) {
            // If created under a folder, then assign the folder id to the expense
            expense.folderId = folder?.id
        }

        const approvalProcesses =
            isNew || expense.folderId
                ? expense.approvalProcesses
                : await ApprovalProcessService.getMergedApprovalProcesses(expenseDetails.approvalProcesses, expense.approvalProcesses, {
                      awaitConfirmation: !isPreApprovedSubscription, // Since in pre approved subscriptions the approval processes are always in status "approved" we don't need to confirm the change.
                      prioritizeApproved: isPreApprovedSubscription,
                  })
        if (!approvalProcesses) {
            setIsLoading(false)
            return
        }
        expense.approvalProcesses = approvalProcesses

        // When expense request is created we will initiate the approval process automatically.
        // If the expense is being updated, we will not and user will have to click on "submit" to initialize the approval process on their own.
        // @issue https://levaroio.atlassian.net/browse/CUS-259
        expense.initializeNotStartedApprovalProcess = isNew

        // If expense is a sub-expense of a folder...
        if (expense.folderId && folder?.approvalProcesses) {
            const folderApprovalProcess = getApprovalProcess(ApprovalScopeEnum.EXPENSE_INVOICE, folder.approvalProcesses)

            // Do not initialize it if its folder is not initialized.
            if (folderApprovalProcess?.status === ApprovalStatusEnum.NOT_STARTED) {
                expense.initializeNotStartedApprovalProcess = false
            }

            // Check if we need to update the folder approval process after an amount change. (Since children change the total amount of folders)
            const isFolderAmountChangeApproved = await ApprovalProcessService.confirmFolderAmountChange(expense.totalGrossPrice - expenseDetails.totalGrossPrice, folder, company)
            if (!isFolderAmountChangeApproved) {
                setIsLoading(false)
                return
            }
        }

        if (isFolderExpense(expense)) {
            if (isNew) {
                expense.status = ExpenseStatusEnum.INVOICE_PENDING
            }
            // TODO: Remove this and calculate totalTax and netPrice in the evaluateFolderExec fn.
            expense.totalNetPrice = 0
            expense.totalTaxPrice = 0
            expense.splits = []
            // Folders cannot be initiated via the expense form. Should always be initiated via the "submit" button.
            expense.initializeNotStartedApprovalProcess = false
        }

        onExpenseAction(expense)
    }

    const onExpenseAction = async (expense: Expense | Mileage | Hospitality | PerDiem) => {
        setIsLoading(true)
        // delete helper form fields
        const sanitizedExpense = deleteUnnecessaryExpenseFormData(expense)
        if (isNew) await onCreateExpense(sanitizedExpense)
        else await onUpdateExpense(sanitizedExpense)
    }

    const onCreateExpense = async (expense: Expense | Mileage | Hospitality | PerDiem) => {
        if (!expense.expenseAccount) {
            const vendor = vendors.find((v) => v.id === expense.vendor?._id)
            expense.expenseAccount = vendor?.rule?.expenseAccount ? { _id: vendor?.rule?.expenseAccount } : undefined
        }

        const createAction = isPreApprovedSubscription ? createPreApprovedSubscription : createExpense

        createAction(expense)(dispatch)
            .then((newExpense: Expense) => {
                if (isFolderExpense(expense)) {
                    NotificationService.send(
                        NotificationTypeEnum.SUCCESS,
                        t("notification:trip_folder.created.title"),
                        t("notification:trip_folder.created.message"),
                        NotificationDurationSec.REQUEST_CREATION,
                        undefined,
                        {
                            onClick: createLinkToRequestFunction(newExpense, history),
                            text: t("notification:trip_folder.created.link"),
                        },
                    )
                } else if (
                    expense.isSubscription() &&
                    expense.isSuccessfullyProcessed() &&
                    expense.isInitialSubscription() &&
                    expense.paymentOption === ExpensePaymentOptionEnum.SMART_CARD
                ) {
                    NotificationService.send(
                        NotificationTypeEnum.SUCCESS,
                        t("notification:request.created_with_card_processing.title"),
                        t("notification:request.created_with_card_processing.message"),
                        20,
                        undefined,
                        {
                            onClick: createLinkToRequestFunction(newExpense, history),
                            text: t("notification:request.created.link"),
                        },
                    )
                } else {
                    NotificationService.send(
                        NotificationTypeEnum.SUCCESS,
                        t("notification:request.created.title"),
                        t("notification:request.created.message"),
                        NotificationDurationSec.REQUEST_CREATION,
                        undefined,
                        {
                            onClick: createLinkToRequestFunction(newExpense, history),
                            text: t("notification:request.created.link"),
                        },
                    )
                }

                if (onExpenseCreated) onExpenseCreated(newExpense)

                if (inboxInvoices?.length) deleteManyInboxInvoices(inboxInvoices.map((invoice) => invoice.id))(dispatch)
                setReplacementForAbsentApprover("")
                setCheckIfApproverInAbsence(true)
                handleHide()
            })
            .catch((err) => {
                setIsLoading(false)
                NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:request.create.title"))
            })
    }

    const onUpdateExpense = async (expense: Expense) => {
        addInvoiceCommentsIfNeeded(expense, expenseDetails, loggedInUser)

        updateExpense(
            expense.id,
            expense,
        )(dispatch)
            .then(() => {
                NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:request.updated.title"), t("notification:request.updated.message"))

                if (inboxInvoices?.length) deleteManyInboxInvoices(inboxInvoices.map((invoice) => invoice.id))(dispatch)
                setReplacementForAbsentApprover("")
                setCheckIfApproverInAbsence(true)
                handleHide()
            })
            .catch((err) => {
                setIsLoading(false)
                NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:request.edit.title"))
            })
    }

    const getModalTitle = (): string => {
        if (isPreApprovedSubscription) {
            return isNew ? t("action:subscription.create") : t("action:subscription.edit")
        }

        if (isReimbursement) {
            if (isNew) return t("action:reimbursement_request.create")
            if (isMileageExpense(expenseDetails) || isPerDiemExpense(expenseDetails)) return t("action:reimbursement_request.edit")
            return t("action:invoice_request.edit")
        }

        if (isInvoiceRequest) {
            return isNew ? t("action:invoice_request.create") : t("action:invoice_request.edit")
        }

        if (isPurchaseRequest) {
            return isNew ? t("action:purchase_request.create") : t("action:purchase_request.edit")
        }

        if (isTripFolder) {
            return isNew ? t("action:trip_folder.create") : t("action:trip_folder.edit")
        }

        return isNew ? t("action:request.create") : t("action:request.edit")
    }

    const getActionButtonTitle = () => {
        if (isPreApprovedSubscription) {
            return isNew ? t("action:subscription.create") : t("action:subscription.save")
        }

        if (isTripFolder) {
            return isNew ? t("action:trip_folder.submit") : t("action:trip_folder.save")
        }

        return isNew ? t("action:request.submit") : t("action:request.save")
    }

    const handleHide = () => {
        setIsLoading(false)
        handleResetTouchForm()
        onCancel()
    }

    const handleHideOnCloseOrCancel = async () => {
        if (isFormTouched.current) {
            const isConfirmed = await DialogService.confirmUnsavedChanges()
            if (!isConfirmed) return
        }

        handleHide()
    }

    return (
        <Modal
            destroyOnClose={true}
            afterClose={() => expenseForm.resetFields()}
            visible={isShowing}
            maskClosable={false}
            title={getModalTitle()}
            onCancel={handleHideOnCloseOrCancel}
            closable={true}
            keyboard={true}
            className={isInvoiceRequest || isReimbursement || isTripFolder ? "ant-modal--full" : "ant-modal--large"}
            footer={[
                <Button id="expenseCreateModalCancel" className="btn-default" key="back" onClick={handleHideOnCloseOrCancel}>
                    {t("action:cancel")}
                </Button>,
                <Button
                    data-testid="expenseCreateModalSubmit"
                    key="submit"
                    type="primary"
                    loading={isLoading}
                    disabled={isUploading || isBuildingApprovalProcesses}
                    onClick={() => expenseForm.submit()}
                >
                    {getActionButtonTitle()}
                </Button>,
            ]}
        >
            <ExpenseFormContext
                isNew={isNew}
                type={type}
                expenseForm={expenseForm}
                kind={kind}
                isReimbursement={isReimbursement}
                isPreApprovedSubscription={isPreApprovedSubscription}
                hideExpenseKindRadioOption={!isNew || !!hideExpenseKindRadioOption}
                transactionData={transactionData}
                isUploading={isUploading}
                setIsUploading={setIsUploading}
                inboxInvoices={inboxInvoices}
                reimbursementRequestsEnabled={reimbursementRequestsEnabled}
                expenseOverride={expenseDetails}
                folder={folder}
                isBuildingApprovalProcesses={isBuildingApprovalProcesses}
                setIsBuildingApprovalProcesses={setIsBuildingApprovalProcesses}
            >
                <ExpenseForm inboxOcrSource={inboxOcrSource} onSubmit={onSubmit} onFormValuesChange={handleTouchForm} />
            </ExpenseFormContext>
            {isReplacementForAbsentApproverShowing && (
                <ReplacementForAbsentApproverModal
                    id="replacementApprover"
                    availableReplacementsForAbsentApprover={availableReplacementsForAbsentApprover}
                    absentApprovers={absentApprovers}
                    isVisible={isReplacementForAbsentApproverShowing}
                    closeModal={() => setIsReplacementForAbsentApproverShowing(false)}
                    onSubmit={() => expenseForm.submit()}
                    setCheckIfApproverInAbsence={setCheckIfApproverInAbsence}
                    setReplacementForAbsentApprover={setReplacementForAbsentApprover}
                    isPurchaseRequest={isPurchaseRequest}
                />
            )}
        </Modal>
    )
}

export default ExpenseCreateFormModal
