import Icon from "@ant-design/icons"
import React from "react"

const pdfSvg = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
            <path
                d="M13.6673 3.6667V14.6666C13.6673 15.2189 13.2196 15.6666 12.6673 15.6666H3.33398C2.7817 15.6666 2.33398 15.2189 2.33398 14.6666V1.33325C2.33398 0.780967 2.7817 0.333252 3.33398 0.333252H10.3339L13.6673 3.6667Z"
                stroke="#606C7E"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.334 0.333252V2.66659C10.334 3.21887 10.7817 3.66659 11.334 3.66659H13.6674"
                stroke="#606C7E"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.66732 5.6665C7.19281 5.6665 7.00065 6.03166 7.00065 6.69833C7.00065 7.59984 8.16732 8.83317 8.66732 9.33317C9.16732 9.83317 10.6673 10.9998 11.0007 10.9998C11.5301 10.9998 11.6673 10.7881 11.6673 10.4547C11.6673 10.0234 11.1614 9.99984 10.6673 9.99984C10.334 9.99984 8.00065 10.3332 6.66732 10.9998C5.33398 11.6665 4.33398 11.9998 4.33398 12.5593C4.33398 12.8103 4.66732 12.9998 5.00065 12.9998C5.50653 12.9998 6.66732 11.9998 7.33398 9.99984C8.00065 7.99984 8.33398 6.99984 8.33398 6.6665C8.33398 5.6665 8.00065 5.6665 7.66732 5.6665Z"
                stroke="#606C7E"
                strokeWidth="0.8"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip0">
                <rect width="16" height="16" fill="white" />
            </clipPath>
        </defs>
    </svg>
)

const PdfIcon = (props: any) => <Icon component={pdfSvg} {...props} />

export default PdfIcon
