import { Employee, Expense, ExpenseKindEnum, ExpensePaymentFlowInformationEnum, InvoiceInterface } from "@finway-group/shared/lib/models"
import { isMileageOrPerDiem } from "@finway-group/shared/lib/utils"
import { MAX_INPUT_LENGTH, allowListPersonNameRegex, isMaxLengthOfInvoiceNumberRespected } from "@finway-group/shared/lib/utils/validators"
import { Rule } from "antd/lib/form"
import i18n from "i18next"
import moment from "moment"

import { parseCurrencyInput } from "Components/currencyInput/config"
import { CompanyService } from "Shared/services"
import { isNotSet } from "Shared/utils/helper.utils"

import { allowlistCharValidator } from "./utils.rules"

interface ExpenseRulesOption {
    expense?: Expense
    kind?: ExpenseKindEnum
    employees?: Array<Employee>
    invoices?: Array<InvoiceInterface>
}

export const ExpenseRules = ({ expense, employees, invoices }: ExpenseRulesOption = {}): { [key: string]: Array<Rule> } => ({
    totalNetPrice: [
        {
            validator: (_rule, inputValue: string) => {
                const resolvedValue = inputValue ? inputValue.toString() : ""
                if (Number(resolvedValue) <= 0) {
                    return Promise.reject(new Error(i18n.t("validation:greater.number_short", { min: 0 })))
                }

                return Promise.resolve()
            },
        },
    ],
    totalGrossPrice: [
        {
            validator: (_rule: Rule, inputValue: string) => {
                const resolvedValue = inputValue ? inputValue.toString() : ""
                if (Number(resolvedValue) === 0) {
                    return Promise.reject(new Error(i18n.t("validation:not_zero")))
                }

                return Promise.resolve()
            },
        },
    ],
    quantity: [
        {
            validator: (_rule, value: number) => {
                if (!isNotSet(value)) {
                    if (value <= 0) {
                        return Promise.reject(new Error(i18n.t("validation:greater.number_short", { min: 0 })))
                    }
                    return Promise.resolve()
                }

                return Promise.reject(new Error(i18n.t("validation:greater.number_short", { min: 0 })))
            },
        },
    ],
    name: [{ required: true, message: i18n.t("validation:required"), validateTrigger: "blur" }, allowlistCharValidator],
    subscriptionRate: [
        {
            validator: (_rule, inputValue: string) => {
                const resolvedValue = inputValue ? inputValue.toString() : ""
                if (resolvedValue !== "") {
                    const value = parseCurrencyInput(resolvedValue)
                    if (value <= 0) {
                        return Promise.reject(new Error(i18n.t("validation:greater.number_short", { min: 0 })))
                    }

                    return Promise.resolve()
                }

                return Promise.reject(new Error(i18n.t("validation:greater.number_short", { min: 0 })))
            },
        },
    ],
    link: [{ type: "url", message: i18n.t("validation:url"), validateTrigger: "blur" }],
    costCenter: [{ required: true, message: i18n.t("validation:required"), validateTrigger: "blur" }],
    approvalNeededBy: [
        { required: true, message: i18n.t("validation:required"), validateTrigger: "blur" },
        {
            validator: (_rule, value: string) => {
                const foundEmployee = employees?.find((employee) => employee.id === value)
                if (foundEmployee?.activeCompanyProfile.deleted) {
                    return Promise.reject(i18n.t("validation:user_deleted"))
                }
                return Promise.resolve()
            },
        },
    ],
    requestedBy: [{ required: true, message: i18n.t("validation:required"), validateTrigger: "blur" }],
    vendor: [{ required: true, message: i18n.t("validation:required"), validateTrigger: "blur" }],
    dateCancellationDue: [
        { type: "object", required: true, message: i18n.t("validation:required"), validateTrigger: "blur" },
        {
            validator: (_rule, value: moment.Moment) => {
                if (expense?.kind !== ExpenseKindEnum.SUBSCRIPTION) return Promise.resolve()

                if (expense?.datePurchased && value) {
                    if (value.isSameOrBefore(expense?.datePurchased)) {
                        return Promise.reject(
                            i18n.t("validation:date_after", {
                                attribute: i18n.t("input:request.date_cancellation_due"),
                                compare: i18n.t("input:request.performance_date"),
                            }),
                        )
                    }
                }

                return Promise.resolve()
            },
        },
    ],
    datePurchased: [
        { type: "object", required: true, message: i18n.t("validation:required"), validateTrigger: "blur" },
        {
            validator: (_rule, value: moment.Moment) => {
                if (expense?.kind !== ExpenseKindEnum.SUBSCRIPTION) return Promise.resolve()

                if (expense?.dateCancellationDue && value) {
                    if (value.isSameOrAfter(expense?.dateCancellationDue)) {
                        return Promise.reject(
                            i18n.t("validation:date_before", {
                                attribute: i18n.t("input:request.performance_date"),
                                compare: i18n.t("input:request.date_cancellation_due"),
                            }),
                        )
                    }
                }

                return Promise.resolve()
            },
        },
    ],
    invoiceNumber: [
        {
            validator: (_rule, value: string) => {
                if (!value?.trim()) {
                    // When at least approved and it's not a reporting only expense, dont allow empty invoiceNumbers
                    if (expense?.isAtLeastApproved() && expense.paymentFlowInformation !== ExpensePaymentFlowInformationEnum.REPORTING_ONLY) {
                        return Promise.reject(i18n.t("validation:required_short"))
                    }
                    // Else allow empty
                    return Promise.resolve()
                }

                // DATEV limit
                if (!isMaxLengthOfInvoiceNumberRespected(value)) return Promise.reject(i18n.t("validation:string_too_long"))

                /**
                 * Disabled number for now
                 * - https://levaroio.atlassian.net/browse/BUG-9226
                 * - https://levaroio.atlassian.net/browse/CUS-214
                 */
                // GoB requirement
                // if (!isMinLengthOfInvoiceNumberRespected(value)) return Promise.reject(i18n.t("validation:string_too_short"))
                // if (!isNoWhitespace(value)) return Promise.reject(i18n.t("validation:string_white_space"))

                return Promise.resolve()
            },
        },
        allowlistCharValidator,
    ],
    invoiceDate: [
        {
            validator: (_rule, value: moment.Moment) => {
                if (expense?.isAtLeastApproved() && expense.paymentFlowInformation !== ExpensePaymentFlowInformationEnum.REPORTING_ONLY && !value) {
                    return Promise.reject(i18n.t("validation:required_short"))
                }

                if (expense?.invoiceDueDate && value) {
                    if (value.isAfter(expense?.invoiceDueDate, "day")) {
                        return Promise.reject(
                            i18n.t("validation:date_after", {
                                attribute: i18n.t("input:request.invoice_date"),
                                compare: i18n.t("input:request.invoice_due_date"),
                            }),
                        )
                    }
                }

                return Promise.resolve()
            },
        },
    ],
    invoiceDueDate: [
        {
            validator: (_rule, value: moment.Moment) => {
                if (expense?.invoiceDate && value) {
                    if (value.isBefore(expense?.invoiceDate, "day")) {
                        return Promise.reject(
                            i18n.t("validation:date_after", {
                                attribute: i18n.t("input:request.invoice_due_date"),
                                compare: i18n.t("input:request.invoice_date"),
                            }),
                        )
                    }
                }

                return Promise.resolve()
            },
        },
    ],
    datePaidAt: [
        {
            validator: (_rule, value: moment.Moment) => {
                if (expense?.isSuccessfullyProcessed() && !value) {
                    return Promise.reject(i18n.t("validation:required"))
                }

                return Promise.resolve()
            },
        },
    ],
    invoiceUpload: [
        {
            validator: () => {
                if (!invoices || invoices.length === 0) {
                    return Promise.reject(i18n.t("validation:invoice_needed"))
                }
                return Promise.resolve()
            },
        },
    ],
    reasonToReject: [
        {
            validator: (_rule, value) => {
                if (!value?.trim()) {
                    return Promise.reject(i18n.t("validation:required"))
                }
                return Promise.resolve()
            },
        },
    ],
    merchant: [
        {
            validator: (_rule, value) => {
                if (!value?.trim()) {
                    return Promise.reject(i18n.t("validation:required"))
                }
                return Promise.resolve()
            },
        },
        { max: 50, message: i18n.t("validation:max.string", { max: 50 }) },
        allowlistCharValidator,
    ],
    hospitalityDate: [
        {
            validator: (_rule, value: moment.Moment) => {
                if (!value) {
                    return Promise.reject(i18n.t("validation:required"))
                }

                if (value.isAfter(moment().endOf("day"))) {
                    return Promise.reject(i18n.t("validation:cannot_be_in_future"))
                }

                return Promise.resolve()
            },
        },
    ],
    attendees: [
        {
            required: true,
            validator: (_rule, value: Array<any>) => {
                if (!value || value?.length < 1) {
                    return Promise.reject(i18n.t("validation:required"))
                }
                const invalidNames = value.filter((nameEntry) => !nameEntry.value.trim().match(allowListPersonNameRegex))
                if (invalidNames.length > 0) {
                    return Promise.reject(i18n.t("validation:name"))
                }
                return Promise.resolve()
            },
        },
    ],
    reason: [{ required: true, message: i18n.t("validation:required"), validateTrigger: "blur" }, allowlistCharValidator],
    description: [
        {
            required: expense && (isMileageOrPerDiem(expense) || CompanyService.doesCompanyEnforceGobdCompliantInvoice()),
            validator: ({ required }, value) => {
                if (!required) return Promise.resolve()
                if (!value?.trim()) {
                    return Promise.reject(i18n.t("validation:required"))
                }
                return Promise.resolve()
            },
        },
        {
            validator: (_rule, value) => {
                if (value && value.length > MAX_INPUT_LENGTH) {
                    return Promise.reject(i18n.t("validation:max.string", { max: MAX_INPUT_LENGTH }))
                }
                return Promise.resolve()
            },
        },
        allowlistCharValidator,
    ],
    expenseAccount: [
        { required: CompanyService.doesCompanyEnforceGobdCompliantInvoice(), message: i18n.t("validation:required"), validateTrigger: "blur" },
        {
            validator: (_rule, value) => {
                if (!CompanyService.doesCompanyEnforceGobdCompliantInvoice()) return Promise.resolve()
                if (!value && expense?.splits?.length === 1) return Promise.reject(i18n.t("validation:required"))
                return Promise.resolve()
            },
        },
    ],
})
