import { Currencies } from "@finway-group/shared/lib/consts"
import { CurrencyEnum, currencyEnumArray } from "@finway-group/shared/lib/models/common/currency.enum"
import Col from "antd/lib/col"
import Collapse from "antd/lib/collapse"
import Form from "antd/lib/form"
import Image from "antd/lib/image"
import Row from "antd/lib/row"
import Select from "antd/lib/select"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import { FormCategoryEnum, FormInputEnum, FormMinMaxCurrencyInterface } from "./form.types"
import FormInputElem from "./formInputElem"

export default function FormMinMaxCurrency({ header, min, max, currency = CurrencyEnum.EUR, keyCode = "" }: FormMinMaxCurrencyInterface) {
    const { t } = useTranslation()
    const [activeCategory, setActiveCategory] = useState({
        isMin: !!min.initialValue,
        isMax: !!max.initialValue,
    })

    return (
        <Collapse ghost defaultActiveKey={keyCode} className={activeCategory.isMin || activeCategory.isMax ? "filter--active" : ""}>
            <Collapse.Panel header={header} key={keyCode} forceRender>
                <Row gutter={[16, 16]} className="mb-8 mt-0">
                    <FormInputElem
                        elem={{ ...min, inputType: FormInputEnum.NUMBER }}
                        span={9}
                        activeCategory={activeCategory}
                        setActiveCategory={setActiveCategory}
                        category={FormCategoryEnum.MIN}
                    />
                    <FormInputElem
                        elem={{ ...max, inputType: FormInputEnum.NUMBER }}
                        span={9}
                        activeCategory={activeCategory}
                        setActiveCategory={setActiveCategory}
                        category={FormCategoryEnum.MAX}
                    />
                    <Col span={6} className="p-0 pl-10">
                        <Form.Item name="currency" style={{ marginBottom: "0px" }} initialValue={currency ?? CurrencyEnum.EUR}>
                            <Select showSearch={true} filterOption={true}>
                                {currencyEnumArray.map((currency) => (
                                    <Select.Option key={currency} value={currency} label={t("label:currency")}>
                                        <div className="space-x-10">
                                            <Image className="align-middle" width={20} preview={false} src={`data:image/png;base64,${Currencies[currency].flag}`} />
                                            <span className="text-text">{currency}</span>
                                        </div>
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Collapse.Panel>
        </Collapse>
    )
}
