import { CurrencyEnum, PriceIntervalEnum } from "@finway-group/shared/lib/models"
import { Card, Col, Row } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"

import PriceLabel from "Components/priceLabel"
import { useCardOverviewData } from "Shared/hooks/analytics.hooks"
import { useWallet } from "Shared/hooks/corporate.hooks"
import { AuthzService } from "Shared/services"

export const CardWidget: React.FC<{}> = () => {
    const { t } = useTranslation()
    const wallet = useWallet()
    const cardOverviewData = useCardOverviewData()
    const hasViewWalletRight = AuthzService.canLoggedInUserViewWallet()
    const hasViewCardRight = AuthzService.canLoggedInUserViewAllCards()

    if (!hasViewWalletRight && !hasViewCardRight) {
        return null
    }

    return (
        <Row gutter={[16, 0]} className="mb-32">
            {hasViewWalletRight && (
                <Col span={24} md={12} lg={8}>
                    <Card>
                        <Row className="mt-10 mb-0">
                            <h4 className="mb-0 truncate inline">{t("creditCard:card_widget.balance")}</h4>
                        </Row>
                        <Row>
                            <div className="mt-16">
                                <p className="text-2xl">
                                    <PriceLabel currency={wallet?.currency ?? CurrencyEnum.EUR} interval={PriceIntervalEnum.ONE_TIME} value={wallet?.balance || 0} showFullNumber />
                                </p>
                            </div>
                        </Row>
                    </Card>
                </Col>
            )}
            {hasViewCardRight && (
                <Col span={24} md={12} lg={8}>
                    <Card>
                        <Row className="mt-10 mb-0">
                            <h4 className="mb-0 truncate inline">{t("creditCard:card_widget.loaded_sum")}</h4>
                        </Row>
                        <Row>
                            <div className="mt-16">
                                <p className="text-2xl">
                                    <PriceLabel
                                        currency={wallet?.currency ?? CurrencyEnum.EUR}
                                        interval={PriceIntervalEnum.ONE_TIME}
                                        value={cardOverviewData?.totalCardBalance || 0}
                                        showFullNumber
                                    />
                                </p>
                            </div>
                        </Row>
                    </Card>
                </Col>
            )}
        </Row>
    )
}

export default CardWidget
