import { LoadingOutlined } from "@ant-design/icons"
import { RightEnum, Transaction, TransactionTypeEnum } from "@finway-group/shared/lib/models"
import { TransactionStatusEnum } from "@finway-group/shared/lib/models/transaction/transactionStatus.enum"
import { Button, Empty, Table, Tag } from "antd"
import { ColumnsType } from "antd/lib/table"
import moment from "moment"
import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"

import NoSearchDataFound from "Components/NoSearchDataFound"
import { ErrorTable } from "Components/layout/errorTable"
import Loading from "Components/loading"
import MatchingButton from "Components/matchingButton"
import TransactionAmountColumn from "Components/transactionAmountColumn"
import TransactionStatusTag from "Components/transactionStatusTag"
import { useCompany } from "Shared/hooks/company.hooks"
import { useIsFreeVersion } from "Shared/hooks/featureFlags.hooks"
import { useFetchTable, useTableFilterAndSearchLoading, useTableSearchString } from "Shared/hooks/table.hooks"
import { AuthzService, EmployeeService, VendorService } from "Shared/services"
import { refetchTable, updateTableOnChange } from "Shared/store/actions/tables/tableActions"
import { TablesEnum } from "Shared/store/reducers/tableConfigReducer"
import { Table as TableType } from "Shared/store/reducers/tableReducer"
import { insertIf } from "Shared/utils/helper.utils"
import { getSortOrderForColumn, isTableFilterApplied } from "Shared/utils/table.utils"

import TransactionArchiveTab from "./transactionArchiveTab"
import { TransactionCategorySelect } from "./transactionCategory.select"

interface TransactionTableInterface {
    tablesEnumTransaction: TablesEnum
    transactionTable: TableType<Transaction>
    showTable?: boolean
    setSelectedTransactions: (value: any) => any
}

export const TransactionTable: React.FC<TransactionTableInterface> = ({ tablesEnumTransaction, transactionTable, showTable = true, setSelectedTransactions }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const history = useHistory()
    const pageLoading = useTableFilterAndSearchLoading(tablesEnumTransaction)
    const isFreeVersion = useIsFreeVersion()
    const searchString = useTableSearchString(tablesEnumTransaction)
    const { liquidityManagementEnabled } = useCompany()

    // Rights
    const hasWriteAccess = AuthzService.isRightGrantedForLoggedInUser(RightEnum.TRANSACTION__ALL__UPDATE)

    useFetchTable(tablesEnumTransaction)

    const isTransactionPendingForTooLong = ({ transactionType, status, transactionData: { date } }: Transaction): boolean =>
        transactionType === TransactionTypeEnum.BANK_TRANSACTION && status === TransactionStatusEnum.PENDING && moment().diff(moment(date), "day") >= 10

    const columns: ColumnsType<Transaction> = [
        {
            key: "transactionData.date",
            title: t("label:transaction_date"),
            ellipsis: true,
            width: 160,
            sortOrder: getSortOrderForColumn(transactionTable, "transactionData.date"),
            sorter: true,
            render: ({ transactionData: { date } }: Transaction) => <span>{moment(date).format("Do MMM, YYYY")}</span>,
        },
        {
            key: "transactionCategory",
            title: t("label:transaction_category"),
            width: 140,
            sorter: true,
            sortOrder: getSortOrderForColumn(transactionTable, "transactionCategory"),
            render: (transaction: Transaction) => <TransactionCategorySelect transaction={transaction} />,
            hidden: !liquidityManagementEnabled,
        },
        {
            key: "vendor",
            title: () => {
                switch (tablesEnumTransaction) {
                    case TablesEnum.TRANSACTIONS_OUTFLOW:
                        return t("label:vendor")
                    case TablesEnum.TRANSACTIONS_INFLOW:
                        return t("label:debtor")
                    case TablesEnum.TRANSACTIONS_ALL:
                    default:
                        return `${t("label:vendor")}/${t("label:debtor")}`
                }
            },
            ellipsis: true,
            width: 155,
            sorter: true,
            sortOrder: getSortOrderForColumn(transactionTable, "vendor"),
            render: ({ transactionData: { vendor, recipient, reimbursementEmployee } }: Transaction) => (
                <span>{recipient || VendorService.getVendorById(vendor)?.name || EmployeeService.getEmployeeById(reimbursementEmployee)?.getFullName() || "n/a"}</span>
            ),
        },
        {
            key: "transactionType",
            title: t("label:transaction_source"),
            width: 180,
            sortOrder: getSortOrderForColumn(transactionTable, "transactionType"),
            sorter: true,
            ellipsis: true,
            render: ({ transactionType, csvName, bankName, accountName }: Transaction) => {
                const csvNameString = csvName ? ` - ${csvName}` : ""
                const transactionTypeLabel = t(`label:${transactionType.toLocaleLowerCase()}`)
                return (
                    <span>
                        {/* For bank transactions if bank name is not defined fallback to transactionTypeLabel */}
                        {transactionType === TransactionTypeEnum.BANK_TRANSACTION && bankName ? `${bankName}${accountName ? ` - ${accountName}` : ""}` : transactionTypeLabel}
                        {csvNameString}
                    </span>
                )
            },
        },
        {
            key: "transactionData.amount",
            title: t("label:amount"),
            ellipsis: true,
            width: 120,
            sorter: true,
            sortOrder: getSortOrderForColumn(transactionTable, "transactionData.amount"),
            render: (transaction: Transaction) => <TransactionAmountColumn transaction={transaction} />,
        },
        {
            key: "status",
            title: t("label:status"),
            ellipsis: true,
            width: 60,
            sorter: true,
            align: "center",
            sortOrder: getSortOrderForColumn(transactionTable, "status"),
            render: (transaction: Transaction) => <TransactionStatusTag status={transaction.status} isTransactionPendingForTooLong={isTransactionPendingForTooLong(transaction)} />,
        },
        ...insertIf(!isFreeVersion, {
            key: "matchingData.matchedExpenses",
            title: t("label:matching.match_status"),
            ellipsis: true,
            align: "right",
            width: 150,
            sorter: true,
            sortOrder: getSortOrderForColumn(transactionTable, "matchingData.matchedExpenses"),
            render: ({ matchingData: { matchedExpenses } }: Transaction) =>
                matchedExpenses.length > 0 ? (
                    <Tag className="ant-tag-green my-5">{t("info:matched")}</Tag>
                ) : (
                    <Button className="btn-highlight-green">{t("action:matching.match")}</Button>
                ),
        }),
        // TODO: filter can be deleted once updated to Antd 5
    ].filter((item) => !item.hidden)

    return (
        <>
            {!showTable ? (
                <TransactionArchiveTab />
            ) : pageLoading ? (
                <Loading />
            ) : transactionTable.error ? (
                <ErrorTable onTableReload={() => dispatch(refetchTable(tablesEnumTransaction))} isLoading={transactionTable.isFetching} />
            ) : transactionTable.data.totalDocs === 0 && !transactionTable.isFetching ? (
                searchString ? (
                    <div className="animation-appear">
                        <NoSearchDataFound />
                    </div>
                ) : (
                    <Empty
                        className="animation-appear"
                        image={"./icons/empty_table.svg"}
                        imageStyle={{
                            height: 150,
                            marginRight: "auto",
                            marginLeft: "auto",
                            marginTop: "15%",
                            marginBottom: "40px",
                            display: "inline-block",
                        }}
                        description={
                            <span className="text-lg font-bold mt-2 mb-0">
                                {!isTableFilterApplied(transactionTable.data.filter) ? t("info:empty_state.transactions.title") : t("info:no_filter_data_found.title.transaction")}
                            </span>
                        }
                    >
                        <div className="flex flex-col justify-between items-center gap-y-4">
                            <p className="mb-14">
                                {!isTableFilterApplied(transactionTable.data.filter) ? t("info:empty_state.transactions.message") : t("info:no_filter_data_found.message")}
                            </p>
                            {tablesEnumTransaction !== TablesEnum.TRANSACTIONS_ARCHIVE && <MatchingButton onUpdate={() => dispatch(refetchTable(tablesEnumTransaction))} />}
                        </div>
                    </Empty>
                )
            ) : (
                <div>
                    <div className="overflow-auto p-2 animation-appear">
                        <Table<Transaction>
                            rowKey={(transaction) => transaction.id}
                            dataSource={transactionTable.data.docs}
                            pagination={{
                                position: ["bottomRight"],
                                showSizeChanger: true,
                                current: transactionTable.data.page,
                                pageSize: transactionTable.data.limit,
                                hideOnSinglePage: false,
                                total: transactionTable.data.totalDocs,
                                pageSizeOptions: ["5", "10", "20", "50", "100"],
                            }}
                            rowSelection={
                                hasWriteAccess
                                    ? {
                                          type: "checkbox",
                                          onChange: (_selectedRowKey, selectedRow) => {
                                              setSelectedTransactions(selectedRow)
                                          },
                                          onSelect: (record) => {
                                              setSelectedTransactions([record])
                                          },
                                          renderCell: (_value, _record, _index, originNode) => (
                                              <div onClick={(e) => e.stopPropagation()} className="bigger-hitbox-checkbox">
                                                  {originNode}
                                              </div>
                                          ),
                                      }
                                    : undefined
                            }
                            columns={columns}
                            sortDirections={["descend", "ascend", "descend"]}
                            loading={{
                                spinning: transactionTable.isFetching,
                                indicator: <LoadingOutlined style={{ fontSize: 30, color: "black" }} spin />,
                            }}
                            onChange={updateTableOnChange(tablesEnumTransaction, transactionTable.data)}
                            onRow={(transaction) => ({
                                onClick: () => {
                                    history.push(`/transactions/${transaction.id}`)
                                },
                            })}
                        />
                    </div>
                </div>
            )}
        </>
    )
}
