import { Mileage, PriceIntervalEnum } from "@finway-group/shared/lib/models"
import { Col, Divider, Row } from "antd"
import moment from "moment"
import React from "react"
import { useTranslation } from "react-i18next"

import { HoverableInfo } from "Components/forms/expenseForm/reimbursementForms/auxilliary/HoverableInfo"
import PriceLabel from "Components/priceLabel"
import { useCompany } from "Shared/hooks/company.hooks"
import { parseHtml } from "Shared/utils/htmlParser.utils"
import { getVehicleString, isDistanceOverThreshold } from "Shared/utils/mileage.utils"

import { ExpenseHeader } from "../auxiliary/expenseHeader"
import { StopsView } from "./mileage/StopsView"

interface MileageMainDetailsSectionInterface {
    expense: Mileage
    showHeader?: boolean
}

export const MileageMainDetailsSection: React.FC<MileageMainDetailsSectionInterface> = ({ expense, showHeader }) => {
    const { t } = useTranslation()
    const company = useCompany()
    const distanceBuffer = company.mileageDistanceBuffer
    const showOverThresholdInfo = isDistanceOverThreshold(expense, distanceBuffer)

    return (
        <Row gutter={[16, 24]}>
            {showHeader && (
                <>
                    <Col span={24}>
                        <ExpenseHeader expense={expense} />
                    </Col>
                    <Divider className="mt-0 mb-20" />
                </>
            )}
            <Col span={10}>
                <div>{t("label:mileage.reason")}</div>
                <div className="flex justify-start h-32 items-center">
                    <div className="leading-tight truncate text-base font-medium">{expense?.tripReason ? parseHtml(expense.tripReason) : t("label:n/a")}</div>
                </div>
            </Col>
            {showOverThresholdInfo ? (
                <Col span={9}>
                    <div>{t("label:mileage.additional_distance_reason")}</div>
                    <div className="flex justify-start h-32 items-center">
                        <div className="leading-tight truncate text-base font-medium">{expense?.additionalStopReason ?? t("label:n/a")}</div>
                    </div>
                </Col>
            ) : (
                <Col span={9} />
            )}

            <Col span={5}>
                <StopsView stops={expense.stops} />
            </Col>

            <Col span={10}>
                <div>{t("label:mileage.vehicle")}</div>
                <div className="flex justify-start h-32 items-center font-medium">
                    <div className="leading-tight truncate text-base">{getVehicleString(expense.vehicle, t)}</div>
                </div>
            </Col>

            <Col span={9}>
                <div>{t("label:mileage.date")}</div>
                <div className="flex justify-start h-32 items-center">
                    <div className="leading-tight truncate text-base font-medium">
                        {expense.datePurchased ? moment(expense.datePurchased).format("DD.MM.YYYY") : t("label:n/a")}
                    </div>
                </div>
            </Col>

            <Col span={5}>
                <div className="flex justify-end text-right">
                    <div>{t("label:mileage.amount")}</div>
                </div>
                <div className="flex justify-end h-32 items-center font-medium">
                    <PriceLabel value={expense.totalGrossPrice} currency={expense.currency} interval={PriceIntervalEnum.ONE_TIME} />
                </div>
            </Col>

            <Col span={10}>
                <div>{t("label:mileage.start_address")}</div>
                <div className="flex justify-start h-32 items-center font-medium">
                    <div className="leading-tight truncate text-base">{expense?.stops?.[0]?.address ?? t("label:n/a")}</div>
                </div>
            </Col>

            <Col span={9}>
                <div>{t("label:mileage.destination_address")}</div>
                <div className="flex justify-start h-32 items-center font-medium">
                    <div className="leading-tight truncate text-base">{expense?.stops?.[(expense?.stops?.length ?? 1) - 1].address ?? t("label:n/a")}</div>
                </div>
            </Col>

            <Col span={5}>
                <div className="flex justify-end">
                    <div>{t("label:mileage.distance")}</div>
                </div>
                <div className="flex justify-end h-32 items-center font-medium">
                    <div className="flex leading-tight truncate text-base">
                        {expense?.actualDistance ?? 0} km
                        {showOverThresholdInfo ? <HoverableInfo type="warn" message={t("info:mileage.above_threshold", { distance: distanceBuffer })} /> : <></>}
                    </div>
                </div>
            </Col>
        </Row>
    )
}
