import { Expense, ExpenseKindEnum, ExpenseSplit, ExpenseStatusEnum, PriceIntervalEnum, SplitTypeEnum } from "@finway-group/shared/lib/models"
import { ExpensePaymentOptionEnum } from "@finway-group/shared/lib/models/expense/expensePaymentOption.enum"
import { getApprovalProcessScopeFromExpenseStatus } from "@finway-group/shared/lib/utils/approvalProcess.utils"
import { getSplitUsedOrActiveDiscount } from "@finway-group/shared/lib/utils/expense.discount.utils"
import { Button, Card, Tag, Tooltip } from "antd"
import moment from "moment"
import React, { useState } from "react"
import { Bell as BellIcon, X as CancelIcon, Check as CheckIcon, Upload as UploadIcon } from "react-feather"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"

import { useCompany } from "Shared/hooks/company.hooks"
import { useCostCenterById } from "Shared/hooks/costCenter.hooks"
import { useEmployees } from "Shared/hooks/employee.hooks"
import { approveExpenseRequest, cancelExpenseRequest, sendEmailReminder } from "Shared/hooks/expense.hooks"
import { useTaxes } from "Shared/hooks/tax.hooks"
import { ApprovalProcessService, AuthzService, EmployeeService, UserService } from "Shared/services"
import { setExpense } from "Shared/store/actions/expense/expenseActions"
import { getCostCenterSplitInfo, getReimbursementLabel, getSubscriptionPriceInterval } from "Shared/utils/expense.utils"
import { formatCurrencyNumber } from "Shared/utils/helper.utils"
import { parseHtml } from "Shared/utils/htmlParser.utils"

import UserImage from "./UserImage"
import ExpenseStatusTag from "./expenseStatusTag"
import ExpenseTagColumn from "./expenseTagColumn"
import DiscountIcon from "./icons/discountIcon"
import RejectModal from "./modals/rejectModal"
import PriceLabel from "./priceLabel"

interface RequestPropInterface {
    request: Expense
    isMinimized?: boolean
    hideActionButtons?: boolean
    isSelectable?: boolean
    costCenterViewIds?: string | Array<string>
    onSelect?: (flag: boolean, requestId: string) => void
}

const RequestPreview = ({ request, isMinimized, isSelectable, hideActionButtons, costCenterViewIds }: RequestPropInterface) => {
    const { t } = useTranslation()
    const history = useHistory()
    const dispatch = useDispatch()
    const taxes = useTaxes()

    const employees = useEmployees()
    const costCenter = useCostCenterById(request.costCenter)
    const creditor = request.getCreditor()

    const [isExporting, setIsExporting] = useState(false)
    const [isSelected] = useState(false)
    const [isRejectModalVisible, setIsRejectModalVisible] = useState(false)

    const loggedInProfile = UserService.getLoggedInEmployeeProfile()

    const nextPendingApprover = ApprovalProcessService.getNextPendingApprover(request.approvalProcesses)
    const requester = EmployeeService.getEmployeeById(request.requestedBy._id)
    const isMyExpense = request.requestedBy._id === loggedInProfile.id

    const isRequester = requester?.id === loggedInProfile.id
    const requestPrice = loggedInProfile.settings.showGrossAmount ? request.totalGrossPrice : request.totalNetPrice
    const { taggingEnabled } = useCompany()

    const costCenterSplits =
        costCenterViewIds && request.splits.length > 1
            ? request.splits.filter((s: ExpenseSplit) =>
                  !costCenterViewIds.length ? s.costCenter === costCenterViewIds : s.costCenter && costCenterViewIds.includes(s.costCenter),
              )
            : undefined

    // Construct the total percentage and price of the split, depending if the user is looking with gross or net
    const splitInfo = costCenterSplits ? getCostCenterSplitInfo(costCenterSplits, request, loggedInProfile, taxes) : undefined

    const showSplitDistributionInTag = splitInfo && splitInfo.percentage < 100 && splitInfo.price !== requestPrice

    const getDiscountAmount = () => {
        const key = loggedInProfile.settings.showGrossAmount ? "gross" : "net"

        const requestDiscount = request.getUsedOrActiveDiscount()

        if (!requestDiscount) return undefined

        // If this card is showing the split distribution tag, that means the main amount is showing the original expense amounts, therefore show the root discount amount
        if (showSplitDistributionInTag) return requestDiscount.reducedAmount[key]

        // When there's no specific cost centers to display, we show the root discount amount
        if (!costCenterSplits?.length) return requestDiscount.reducedAmount[key]

        const splitDiscountedTotal = costCenterSplits?.reduce((acc, split) => {
            const splitDiscount = getSplitUsedOrActiveDiscount(split)
            return acc + (splitDiscount?.reducedAmount[key] ?? 0)
        }, 0)

        return splitDiscountedTotal
    }

    const discountAmount = getDiscountAmount()

    const onReject = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation()
        setIsRejectModalVisible(true)
    }

    const onCancelRequest = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation()
        cancelExpenseRequest(request, false)
    }

    const onSendReminder = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation()
        sendEmailReminder(employees, request)
    }

    const onApprove = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation()
        const approvalScope = getApprovalProcessScopeFromExpenseStatus(request.status)
        if (!nextPendingApprover || !approvalScope) return
        approveExpenseRequest(request, approvalScope, loggedInProfile, nextPendingApprover.getFullName())
    }

    const onClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation()
        dispatch(setExpense(request))
        history.push(`/expenses/${request.id}`)
    }

    const onCheckBox = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation()
    }

    const onReviewButton = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation()
    }

    const onExportButton = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation()
        setIsExporting(true)
    }

    const SplitTag = () => {
        if (!showSplitDistributionInTag) return null

        if (request.splitType === SplitTypeEnum.SPLIT) {
            return <Tag className="ant-tag-gray">{`${splitInfo.percentage.toFixed(0)}% ${t("label:split_noun")}: ${formatCurrencyNumber(splitInfo.price, request.currency)}`}</Tag>
        }

        return <Tag className="ant-tag-gray">{`${t("label:item")}: ${formatCurrencyNumber(splitInfo.price, request.currency)}`}</Tag>
    }

    const getActionButtonContent = ({ status }: Expense) => {
        switch (status) {
            case ExpenseStatusEnum.PURCHASE_PENDING:
            case ExpenseStatusEnum.INVOICE_PENDING:
                if (isRequester) {
                    return (
                        <div className="btn-wrapper">
                            <Button className="btn-default" type="default" icon={<CancelIcon />} onClick={onCancelRequest}>
                                <span>{t("action:cancel_request")}</span>
                            </Button>
                            <Button className="btn-highlight-blue" icon={<BellIcon />} onClick={onSendReminder}>
                                <span className="m-0">{t("action:send_reminder")}</span>
                            </Button>
                        </div>
                    )
                }
                if (AuthzService.isLoggedInUserPotentialApprover()) {
                    return (
                        <div className="btn-wrapper">
                            <Button className="btn-default" icon={<CancelIcon />} onClick={onReject}>
                                <span>{t("action:reject")}</span>
                            </Button>
                            <Button type="primary" icon={<CheckIcon />} onClick={onApprove}>
                                <span>{t("action:approve")}</span>
                            </Button>
                        </div>
                    )
                }
                return <></>
            case ExpenseStatusEnum.DOCS_NEEDED:
                return (
                    <Button className="btn-highlight-blue" icon={<UploadIcon />}>
                        <span>{t("action:upload_invoice")}</span>
                    </Button>
                )
            case ExpenseStatusEnum.APPROVED:
                return (
                    <Button onClick={onReviewButton}>
                        <span>{t("action:mark_as_reviewed")}</span>
                    </Button>
                )
            case ExpenseStatusEnum.REVIEWED:
            case ExpenseStatusEnum.PAID:
                return (
                    <div className="btn-wrapper">
                        <Button loading={isExporting} onClick={onExportButton}>
                            <span>{t("action:export")}</span>
                        </Button>
                    </div>
                )
            default:
                return <></>
        }
    }

    return (
        <>
            <RejectModal
                isVisible={isRejectModalVisible}
                title={t("notification:rejection_note.title")}
                message={t("notification:rejection_note.message")}
                cancelText={t("notification:rejection_note.cancel")}
                okText={t("notification:rejection_note.confirm")}
                request={request}
                onCancel={() => setIsRejectModalVisible(false)}
            />
            <div onClick={onClick}>
                <Card className="cursor-pointer hover:bg-gray-100 overflow-hidden">
                    <div className="flex items-start sm:items-center">
                        {isSelectable && (
                            <div className="mr-10">
                                <input className="mr-10" type="checkbox" checked={isSelected} onClick={onCheckBox} />
                            </div>
                        )}
                        {requester && (
                            <>
                                {!isMinimized && nextPendingApprover ? (
                                    <div className="flex-1 overflow-hidden">
                                        <div className="flex image-loader flex-no-wrap">
                                            <UserImage user={isMyExpense ? nextPendingApprover : requester} size="large" />
                                            <div className="flex flex-col mr-5 overflow-x-auto overflow-y-hidden">
                                                <p className="text-text-dark font-bold mt-3 mb-3 leading-none truncate">
                                                    {isMyExpense ? `${nextPendingApprover.getFullName()} (${t("label:approver")})` : `${requester.getFullName()}`}
                                                </p>
                                                <div className="flex">
                                                    <p className="text-sm leading-tight truncate min-w-40">
                                                        {creditor?.name || t("label:n/a")} ({costCenter?.name?.trim() || t("label:n/a")})
                                                    </p>

                                                    {request.kind === ExpenseKindEnum.SUBSCRIPTION && <Tag className="ant-tag-blue ml-10">{t("label:recurring")}</Tag>}
                                                    {request.isReimbursement && (
                                                        <Tooltip title={t(`tooltips:reimbursement_types.${getReimbursementLabel(request.kind)}`)}>
                                                            <Tag className="ant-tag-blue ml-10">{t(`label:reimbursement_types.${getReimbursementLabel(request.kind)}`)}</Tag>
                                                        </Tooltip>
                                                    )}
                                                    {request.paymentOption === ExpensePaymentOptionEnum.SMART_CARD && (
                                                        <Tag className="ml-10 ant-tag-blue">{t("label:cards.card")}</Tag>
                                                    )}
                                                    {(request.totalNetPrice < 0 || request.totalGrossPrice < 0) && <Tag className="ml-10 ant-tag-green">{t("label:refund")}</Tag>}
                                                    {taggingEnabled && (
                                                        <div className="ml-8 h-14">
                                                            <ExpenseTagColumn expenseTagIds={request.expenseTags} />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-8 overflow">
                                            <p className="text-sm sm:text-base leading-tight truncate max-h-20" onClick={(e) => e.preventDefault()}>
                                                {loggedInProfile.settings.showExpenseNumbers &&
                                                    `# ${request.expenseNumber}${request.description || request.isAutoGenerated ? " - " : ""}`}
                                                {request.description ? (
                                                    parseHtml(request.description)
                                                ) : request.isAutoGenerated ? (
                                                    <span className="italic">{t("info:auto_generated_request")}</span>
                                                ) : null}
                                            </p>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="flex-1 overflow-hidden">
                                        <div className="flex items-center">
                                            <UserImage user={requester} size="large" />
                                            <div className="flex flex-col mr-5 overflow-x-auto overflow-y-hidden">
                                                <p className="text-text-dark font-bold mt-3 leading-none">{requester.getFullName()}</p>

                                                <div className="flex items-start">
                                                    {creditor?.name || t("label:n/a")}
                                                    {request.kind === ExpenseKindEnum.SUBSCRIPTION && <Tag className="ant-tag-blue ml-10">{t("label:recurring")}</Tag>}
                                                    {request.paymentOption === ExpensePaymentOptionEnum.SMART_CARD && (
                                                        <Tag className="ml-10 ant-tag-blue">{t("label:cards.card")}</Tag>
                                                    )}
                                                    {taggingEnabled && (
                                                        <div className="ml-8 h-14">
                                                            <ExpenseTagColumn expenseTagIds={request.expenseTags} />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-8 overflow">
                                            <p className="text-sm sm:text-base leading-tight truncate max-h-20 ml-6" onClick={(e) => e.preventDefault()}>
                                                {loggedInProfile.settings.showExpenseNumbers &&
                                                    `# ${request.expenseNumber}${request.description || request.isAutoGenerated ? " - " : ""}`}
                                                {request.description ? (
                                                    parseHtml(request.description)
                                                ) : request.isAutoGenerated ? (
                                                    <span className="italic">{t("info:auto_generated_request")}</span>
                                                ) : null}
                                            </p>
                                        </div>
                                    </div>
                                )}
                            </>
                        )}

                        {!hideActionButtons && <div className="hidden md:block px-34">{getActionButtonContent(request)}</div>}
                        <div className="flex flex-col items-end m-w-160">
                            <div className="text-text-dark leading-none mb-2 flex gap-4 font-bold items-center">
                                {!!discountAmount && (
                                    <>
                                        <div className="finway-green-2-text mb-2">
                                            <DiscountIcon />
                                        </div>
                                        <div className="finway-green-2-text">
                                            <PriceLabel
                                                value={discountAmount}
                                                currency={request.currency}
                                                interval={request.kind === "Subscription" ? getSubscriptionPriceInterval(request) : PriceIntervalEnum.ONE_TIME}
                                            />
                                        </div>
                                        <div>|</div>
                                    </>
                                )}
                                <div>
                                    <PriceLabel
                                        value={requestPrice}
                                        currency={request.currency}
                                        interval={request.kind === "Subscription" ? getSubscriptionPriceInterval(request) : PriceIntervalEnum.ONE_TIME}
                                    />
                                </div>
                            </div>
                            <p className="text-sm mb-5">{moment(request.datePurchased).format("ll")}</p>
                            <div className="flex">
                                <SplitTag />
                                {!isMinimized && <ExpenseStatusTag status={request.status} />}
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
        </>
    )
}

export default RequestPreview
