import { LiquidityForecastRule } from "@finway-group/shared/lib/models"
import { Col, Row } from "antd"
import React from "react"
import { Edit as EditIcon, Trash as TrashIcon } from "react-feather"
import { useTranslation } from "react-i18next"

import { useTransactionCategoryById } from "Shared/hooks/transactionCategory.hooks"
import { useLoggedInEmployeeProfile } from "Shared/hooks/user.hooks"

import PriceLabel from "./priceLabel"

interface LiquidityForecastRulePropInterface {
    forecastRule: LiquidityForecastRule
    onEditForecastRule: (_id?: string) => void
    onDeleteEditForecastRule: (_id?: string) => void
}

const liquidityForecastRuleCard = ({ forecastRule, onEditForecastRule, onDeleteEditForecastRule }: LiquidityForecastRulePropInterface) => {
    const { t } = useTranslation()

    const currentEmployee = useLoggedInEmployeeProfile()
    const { globalCurrency } = currentEmployee.settings

    return (
        <Row gutter={[16, 16]} justify="center">
            <Col span={22}>
                <div className="p-10 flex flex-row border flex-grow w-full rounded-lg">
                    <Col span={5}>
                        <div className="flex flex-col justify-center">
                            <span className="text-text">{t("label:liquidity.forecast_rule.type")}</span>
                            <span className="truncate">{forecastRule.frequency ? t("label:recurring") : t("label:one_time")}</span>
                        </div>
                    </Col>

                    <Col span={6}>
                        <div className="flex flex-col justify-center">
                            <span className="text-text">{t("label:liquidity.forecast_rule.description")}</span>
                            <span className="truncate">{forecastRule.title}</span>
                        </div>
                    </Col>

                    <Col span={5}>
                        <div className="flex flex-col justify-center">
                            <span className="text-text">{t("label:liquidity.forecast_rule.amount")}</span>
                            <span className="truncate">
                                {
                                    <PriceLabel
                                        value={forecastRule.amount * forecastRule.quantity}
                                        currency={globalCurrency}
                                        interval={forecastRule.frequency ? forecastRule.frequency : 0}
                                    />
                                }
                            </span>
                        </div>
                    </Col>
                    <Col span={7}>
                        <div className="flex flex-col justify-center">
                            <span className="text-text">{t("label:liquidity.forecast_rule.category")}</span>
                            <span className="truncate">{useTransactionCategoryById(forecastRule.category)?.name}</span>
                        </div>
                    </Col>
                </div>
            </Col>
            <Col span={2}>
                <Row className="mt-12 cursor-pointer">
                    <div onClick={() => onEditForecastRule(forecastRule._id)}>
                        <EditIcon size={14} />
                    </div>
                </Row>
                <Row className="mt-20 cursor-pointer">
                    <div onClick={() => onDeleteEditForecastRule(forecastRule._id)}>
                        <TrashIcon size={14} />
                    </div>
                </Row>
            </Col>
        </Row>
    )
}

export default React.memo(liquidityForecastRuleCard)
