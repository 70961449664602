import { Form, Modal, Row } from "antd"
import { Store } from "antd/lib/form/interface"
import moment from "moment"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import FormMinMaxDate from "Components/form/formMinMaxDate"
import { useCompany } from "Shared/hooks/company.hooks"
import { NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { updateCompanyFeatureSettings } from "Shared/store/actions/company/companyActions"

interface FiscalYearModalInterface {
    isShowing: boolean
    onHide: () => void
}

enum FiscalYearEnum {
    START = "start",
    END = "end",
}

export const FiscalYearModal: React.FC<FiscalYearModalInterface> = ({ isShowing, onHide }) => {
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useState(false)
    const company = useCompany()
    const initialFiscalYear = company.fiscalYear

    const onFormSubmit = async (values: Store) => {
        try {
            setIsLoading(true)

            await dispatch(updateCompanyFeatureSettings(company._id, { fiscalYear: { start: values[FiscalYearEnum.START], end: values[FiscalYearEnum.END] } }))

            NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:settings.company.fiscal_year.title"), t("notification:settings.company.fiscal_year.message"))
            onHide()
        } catch (err) {
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:error"))
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Modal
            title={t("label:fiscal_year")}
            visible={isShowing}
            maskClosable={false}
            onOk={form.submit}
            onCancel={onHide}
            okText={t("action:save")}
            className={"ant-modal--large"}
            keyboard
            destroyOnClose={true}
            confirmLoading={isLoading}
        >
            <Form form={form} onFinish={onFormSubmit}>
                <Row className="mb-30">
                    <span className="text-text">{t("info:fiscal_year.select")}</span>
                </Row>
                <FormMinMaxDate
                    allowClear={false}
                    header={""}
                    min={{
                        name: FiscalYearEnum.START,
                        label: t("input:fiscal_year.start"),
                        placeholder: t("placeholder:expense_filter.min_date"),
                        initialValue: initialFiscalYear.start,
                        disabledDateFunction: (current) => {
                            const start = current.startOf("day")
                            const end = form.getFieldValue(FiscalYearEnum.END) as moment.Moment
                            return start.isSameOrBefore(end.clone().subtract(1, "year")) || start.isAfter(end.clone().subtract(1, "day")) || start.isAfter(moment())
                        },
                    }}
                    max={{
                        name: FiscalYearEnum.END,
                        label: t("input:fiscal_year.end"),
                        placeholder: t("placeholder:expense_filter.max_date"),
                        initialValue: initialFiscalYear.end,
                        disabledDateFunction: (current) => {
                            const end = current.endOf("day")
                            const start = form.getFieldValue(FiscalYearEnum.START) as moment.Moment
                            return end.isSameOrAfter(start.clone().add(1, "year")) || end.isBefore(start.clone().add(1, "day")) || end.isBefore(moment())
                        },
                    }}
                    disableDateOption={true}
                    isCollapse={false}
                />
            </Form>
        </Modal>
    )
}
