import { COLOR } from "@finway-group/shared/lib/consts"
import MileageLocationInterface from "@finway-group/shared/lib/models/expense/mileageLocation.interface"
import { Avatar, Steps, Tooltip } from "antd"
import React from "react"
import { MapPin } from "react-feather"
import { useTranslation } from "react-i18next"

import { MapPinMultiIcon } from "Components/icons/MapPinMulti.icon"
import { MapPinSingleIcon } from "Components/icons/MapPinSingle.icon"
import { MILEAGE_MAX_STOP_DISPLAY } from "Shared/config/consts"
import { getTooltipPopupContainer } from "Shared/utils/helper.utils"

interface StopsListInterface {
    stops: Array<MileageLocationInterface>
}

const StopsList = ({ stops }: StopsListInterface) => {
    const { t } = useTranslation()
    const stepItems =
        stops.length > MILEAGE_MAX_STOP_DISPLAY
            ? [
                  { title: stops[0].address, icon: <MapPinSingleIcon /> },
                  { title: t("label:mileage.x_stops", { count: stops.length - 2 }), icon: <MapPinMultiIcon /> },
                  { title: stops[stops.length - 1].address, icon: <MapPinSingleIcon /> },
              ]
            : stops.map((stop) => ({ title: stop.address, icon: <MapPinSingleIcon /> }))

    return (
        <Steps direction="vertical" size="small" current={stops.length} className="py-12">
            {stepItems.map((step, index) => (
                <Steps.Step
                    key={`step-item-${index}`}
                    className={stepItems.length - 1 === index ? `max-h-24` : ``}
                    icon={step.icon}
                    title={<p className="text-black max-w-200 truncate">{step.title}</p>}
                />
            ))}
        </Steps>
    )
}

interface StopsViewInterface {
    stops: Array<MileageLocationInterface>
}

export const StopsView = ({ stops }: StopsViewInterface) => {
    const { t } = useTranslation()

    // TODO - Antd: Replace this abominiation to use the maxCount prop and the maxPopoverTrigger to avoid the automatically generated tooltip to show up when we upgrade the dependency of antd.
    // What we want: Avatars to show maximum 4 elements, and the the 5th avatar to show the count of the hidden elements (e.g. +4), with a a custom tooltip
    // What 4.6 provides: can set maxCount prop so it renders 4 elements (good), and the 5th avatar to show the count of the hidden elements (good), but it automatically renders an unconfigurable tooltip (bad)
    const generateAvatars = (limit: number) => {
        const truncatedStops = stops.slice(0, limit)

        const displayedAvatar = truncatedStops.map((_stop, index) => (
            <Avatar key={index} className="bg-gray-200">
                <MapPin color={COLOR.finway.blue} size={16} className="mt-6" />
            </Avatar>
        ))

        // When the stops are not truncated , do not display (+avatar)
        if (stops.length <= limit) return displayedAvatar

        const restAvatar = <Avatar key={`rest-${stops.length}`} className="bg-gray-700">{`+${stops.length - truncatedStops.length}`}</Avatar>

        return [...displayedAvatar, restAvatar]
    }

    return (
        <div className="flex items-center justify-end">
            <div className="mb-8 mr-12">
                {stops.length} {t("label:mileage.stops")}
            </div>
            <Tooltip title={<StopsList stops={stops} />} placement="topLeft" color={COLOR.white} getPopupContainer={getTooltipPopupContainer}>
                <div>
                    <Avatar.Group>{generateAvatars(MILEAGE_MAX_STOP_DISPLAY)}</Avatar.Group>
                </div>
            </Tooltip>
        </div>
    )
}
