import { CurrencyEnum, Employee } from "@finway-group/shared/lib/models"
import { Card, Tabs } from "antd"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"

import { useCompanies } from "Shared/hooks/company.hooks"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

interface EmployeeTabViewInterface {
    globalCurrency: CurrencyEnum
    approvals: Array<{
        approver: Employee
        currency: CurrencyEnum
        limit: number
    }>
    employee: Employee
    cardsActive: boolean
}
const { TabPane } = Tabs
const EmployeeLimits = React.lazy(() => import("./employeeLimits"))
const EmployeeCostCenter = React.lazy(() => import("./employeeCostCenters"))
const EmployeeCards = React.lazy(() => import("./employeeCards"))
const EmailAliases = React.lazy(() => import("./employeeEmailAliases"))
const EmployeeRules = React.lazy(() => import("./employeeRules"))

const EmployeeTabView: React.FC<EmployeeTabViewInterface> = ({ globalCurrency, approvals, employee, cardsActive }) => {
    const [isActive, setIsActive] = useStateIfMounted("1")
    const { t } = useTranslation()
    const companies = useCompanies(false)
    const shouldShowEmailAliases = useMemo(() => companies.find((company) => company.cardsEnabled) && (employee?.companyProfiles?.length ?? 0) > 1, [companies])
    return (
        <Card style={{ marginTop: "35px" }}>
            <Tabs defaultActiveKey={isActive} onChange={(key) => setIsActive(key)}>
                <TabPane tab={t("label:rules")} key="1">
                    <EmployeeRules employee={employee} />
                </TabPane>
                <TabPane tab={t("label:cost_center")} key="2">
                    <EmployeeCostCenter globalCurrency={globalCurrency} employee={employee} />
                </TabPane>
                {cardsActive && (
                    <TabPane tab={t("label:cards.credit_cards")} key="3">
                        <EmployeeCards employee={employee} />
                    </TabPane>
                )}
                {shouldShowEmailAliases && (
                    <TabPane tab="Email Alias" key="4">
                        <EmailAliases employee={employee} shouldShowEmailAliases={shouldShowEmailAliases} />
                    </TabPane>
                )}
                <TabPane tab={t("label:limits")} key="5">
                    <EmployeeLimits globalCurrency={globalCurrency} approvals={approvals} employee={employee} />
                </TabPane>
            </Tabs>
        </Card>
    )
}

export default EmployeeTabView
