import { ExpensePaymentFlowInformationEnum } from "@finway-group/shared/lib/models"
import { RecommendationEnum } from "@finway-group/shared/lib/models/vendor/recommendation.enum"
import { VendorFilter } from "@finway-group/shared/lib/models/vendor/vendor.filter"
import Form from "antd/lib/form"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"

import { FilterFormInterface } from "Components/filter/filterTypes"
import { FormInputEnum } from "Components/form/form.types"
import FormMinMax from "Components/form/formMinMax"
import FormSelect from "Components/form/formSelect"
import { getTableFilterObjectStore } from "Shared/hooks/table.hooks"
import { createDynamicListWithLabel, createYesOrNoOption } from "Shared/utils/filter.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

import { generateRules } from "./rules/vendorFilter.rules"

const VendorFilterForm: React.FC<FilterFormInterface> = ({ table, formInstance, onSubmit }) => {
    const { t } = useTranslation()

    const tableFilterObject = getTableFilterObjectStore<VendorFilter>(table)
    const [vendorFilter, setVendorFilter] = useStateIfMounted(tableFilterObject)

    const rules = generateRules(formInstance)
    const yesOrNoOptions = createYesOrNoOption()

    useEffect(() => {
        formInstance.resetFields()
        setVendorFilter(vendorFilter)
    }, [])

    useEffect(() => {
        formInstance.validateFields()
    }, [vendorFilter])

    const vendorRulesExpensePaymentFlowInformationList = createDynamicListWithLabel(
        "enum",
        Object.values(ExpensePaymentFlowInformationEnum).map((value) => ({ param: `ExpensePaymentFlowInformationEnum.${value}`, value })),
    )

    const recommendationList = createDynamicListWithLabel("label", [
        { param: "recommendations.blacklisted", value: RecommendationEnum.BLACK_LIST },
        { param: "recommendations.fine", value: RecommendationEnum.FINE },
    ])

    return (
        <Form id="vendorFilterForm" className="form--filter" form={formInstance} onFinish={onSubmit}>
            <FormMinMax
                header={t("input:filter_form.creditor_number_range")}
                inputType={FormInputEnum.NUMBER}
                min={{
                    name: "minCreditorNumber",
                    label: t("input:filter_form.min_amount"),
                    placeholder: t("placeholder:expense_filter.min_amount"),
                    onChange: () => formInstance.validateFields(["maxCreditorNumber", "minCreditorNumber"]),
                    initialValue: vendorFilter.minCreditorNumber,
                    rules: rules.minCreditorNumber,
                }}
                max={{
                    name: "maxCreditorNumber",
                    label: t("input:filter_form.max_amount"),
                    placeholder: t("placeholder:expense_filter.max_amount"),
                    onChange: () => formInstance.validateFields(["maxCreditorNumber", "minCreditorNumber"]),
                    initialValue: vendorFilter.maxCreditorNumber,
                    rules: rules.maxCreditorNumber,
                }}
            />

            <FormSelect
                name="vendorRulesSet"
                header={t("input:filter_form.vendor_rules_set")}
                initialValue={vendorFilter.vendorRulesSet}
                options={yesOrNoOptions}
                isCollapse
                fallback
                isForm
            />

            <FormSelect
                name="vendorRulesExpensePaymentFlowInformation"
                header={t("label:payment_flow_information.title")}
                initialValue={vendorFilter.vendorRulesExpensePaymentFlowInformation}
                options={vendorRulesExpensePaymentFlowInformationList}
                isCollapse
                fallback
                isForm
            />

            <FormSelect
                name="contractSet"
                header={t("input:filter_form.contract_set")}
                initialValue={vendorFilter.contractSet}
                options={yesOrNoOptions}
                isCollapse
                fallback
                isForm
            />

            <FormSelect
                name="paymentDataSet"
                header={t("input:filter_form.payment_data_added")}
                initialValue={vendorFilter.paymentDataSet}
                options={yesOrNoOptions}
                isCollapse
                fallback
                isForm
            />

            <FormSelect name="taxSet" header={t("input:filter_form.tax_set")} initialValue={vendorFilter.taxSet} options={yesOrNoOptions} isCollapse fallback isForm />

            <FormSelect name="vatSet" header={t("input:filter_form.vat_set")} initialValue={vendorFilter.vatSet} options={yesOrNoOptions} isCollapse fallback isForm />

            <FormSelect
                name="recommendation"
                header={t("input:filter_form.recommendation")}
                initialValue={vendorFilter.recommendation}
                options={recommendationList}
                isCollapse
                fallback
                isForm
            />
        </Form>
    )
}

export default VendorFilterForm
