import { CalculationIntervals } from "@finway-group/shared/lib/consts"
import { CalculationIntervalEnum } from "@finway-group/shared/lib/models"
import { Card, Col, Row, Select } from "antd"
import React, { useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import ProgressBar from "Components/ProgressBar"
import { useCostCenterExpenseData, useCostCenterInterval } from "Shared/hooks/analytics.hooks"
import { useSubCostCenterIds } from "Shared/hooks/costCenter.hooks"
import { CostCenterService, UserService } from "Shared/services"
import { fetchCostCenterExpenseData, setCostCenterInterval } from "Shared/store/actions/analytics/analyticsActions"
import { getSelectLabel } from "Shared/utils/report.utils"

const { Option } = Select

const CostCenterProgressBarChart = () => {
    const { t } = useTranslation()
    const costCenterIntervalBar = useCostCenterInterval()
    const subCostCenterIds = useSubCostCenterIds(true, true)
    const costCenterExpenseData = useCostCenterExpenseData().filter((costCenterExpenseData) => subCostCenterIds.includes(costCenterExpenseData.id))
    const dispatch = useDispatch()

    const globalCurrency = UserService.getLoggedInEmployeeProfile()?.settings.globalCurrency

    const maxCostCenterValue = useMemo(() => Math.max(...costCenterExpenseData.map((dataSet: any) => dataSet.value)), [costCenterExpenseData])

    const totalSumCostCenters = useMemo(() => costCenterExpenseData.reduce((total, expense) => total + expense.value, 0), [costCenterExpenseData])

    const handleBarCostCentersFilterChange = async (value: string) => {
        dispatch(setCostCenterInterval(Number(value)))
    }

    useEffect(() => {
        dispatch(fetchCostCenterExpenseData())
    }, [costCenterIntervalBar])

    return (
        <>
            <Row>
                <Col xs={24} lg={24}>
                    <div className="flex justify-between items-center pb-0">
                        <h2 className="pt-8">{t("label:cost_center")}</h2>
                        <div>
                            <Select
                                className="float-right"
                                defaultValue={getSelectLabel(CalculationIntervals[costCenterIntervalBar])}
                                onChange={handleBarCostCentersFilterChange}
                                dropdownMatchSelectWidth={false}
                            >
                                <Option value={CalculationIntervalEnum.ALL_TIME} label="All Times">
                                    <span>{getSelectLabel(CalculationIntervals[CalculationIntervalEnum.ALL_TIME])}</span>
                                </Option>
                                <Option value={CalculationIntervalEnum.CURRENT_YEAR} label="Current Year">
                                    <span>{getSelectLabel(CalculationIntervals[CalculationIntervalEnum.CURRENT_YEAR])}</span>
                                </Option>
                                <Option value={CalculationIntervalEnum.CURRENT_MONTH} label="Current Month">
                                    <span>{getSelectLabel(CalculationIntervals[CalculationIntervalEnum.CURRENT_MONTH])}</span>
                                </Option>
                            </Select>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                {/* Cost Center Progress Bar Chart */}
                <Col xs={24} lg={24} className="h-full">
                    <Card id="cost-center-section">
                        {costCenterExpenseData?.length > 0 ? (
                            <React.Fragment>
                                {costCenterExpenseData.map((dataSet: any, i: number) => (
                                    <div key={i} className="flex flex-col xs:flex-row items-start xs:items-center my-16">
                                        <p className="text-left w-full xs:w-1/4 leading-tight overflow-x-auto whitespace-no-wrap no-scrollbar">
                                            {CostCenterService.getCostCenterById(dataSet.id).name}
                                        </p>
                                        <div className="w-full xs:w-3/4 pl-0 xs:ml-20 mt-5 xs:mt-0">
                                            <ProgressBar maxValue={maxCostCenterValue} total={totalSumCostCenters} value={dataSet.value} currency={globalCurrency} />
                                        </div>
                                    </div>
                                ))}
                            </React.Fragment>
                        ) : (
                            <div className="flex justify-center items-center h-100">
                                <p>{t("missing:expense_data")}</p>
                            </div>
                        )}
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default CostCenterProgressBarChart
