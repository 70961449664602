import { LoadingOutlined } from "@ant-design/icons"
import { ExportFormatEnum, ExportHistory, ExportHistoryStatusEnum } from "@finway-group/shared/lib/models"
import { Button, Table, Tag } from "antd"
import { ColumnsType } from "antd/lib/table"
import moment from "moment"
import React from "react"
import { Download, Info } from "react-feather"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import { ErrorTable } from "Components/layout/errorTable"
import ExportHistoryValidationModal from "Components/modals/exportHistoryValidation.modal"
import SettingsLayout from "Features/settings/settings.layout"
import { useFetchTable, useTable } from "Shared/hooks/table.hooks"
import { FileService, NotificationService } from "Shared/services"
import { refetchTable, updateTableOnChange } from "Shared/store/actions/tables/tableActions"
import { TablesEnum } from "Shared/store/reducers/tableConfigReducer"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

const ExportHistoryList = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const [isValidationModalShowing, setIsValidationModalShowing] = useStateIfMounted(false)
    const [currentEntryValidationData, setCurrentEntryValidationData] = useStateIfMounted<ExportHistory["validationData"] | undefined>(undefined)
    const [currentFormat, setCurrentFormat] = useStateIfMounted<ExportFormatEnum | undefined>(undefined)

    const exportHistoryTable = useTable<ExportHistory>(TablesEnum.EXPORT_HISTORY)
    useFetchTable(TablesEnum.EXPORT_HISTORY)

    const columns: ColumnsType<ExportHistory> = [
        {
            title: t("label:export_history.export_date"),
            key: "exportDate",
            render: ({ exportDate }) => <span>{moment(exportDate).format("lll")}</span>,
        },
        {
            title: t("label:export_history.resource.title"),
            key: "resource",
            render: ({ resource }) => <span>{t(`label:export_history.resource.${resource?.toLowerCase()}`)}</span>,
        },
        {
            title: t("label:export_history.format"),
            key: "resource",
            render: ({ format }) => <span>{t(`input:export.export_format.${format}`)}</span>,
        },
        {
            title: t("label:export_history.status.title"),
            key: "status",
            render: ({ status }) => <Tag className={`ant-tag-${getTagColorForStatus(status)}`}>{t(`label:export_history.status.${status}`)}</Tag>,
        },
        {
            title: t("label:export_history.actions"),
            key: "download",
            width: 100,
            align: "center",
            render: ({ fileUrl, validationData, format, status }) => {
                const validationButtonDisabled =
                    status === ExportHistoryStatusEnum.PROCESSING || (!validationData?.verifiedExpenses?.length && !validationData?.filteredOutExpenses?.length)

                return (
                    <div className="flex flex-row">
                        <Button className={`m-5 btn-default ${!fileUrl ? "opacity-50" : ""}`} icon={<Download />} onClick={() => handleFileDownload(fileUrl)} disabled={!fileUrl} />
                        <Button
                            className={`m-5 btn-default ${validationButtonDisabled ? "opacity-50" : ""}`}
                            icon={<Info />}
                            onClick={() => showValidationData(validationData, format)}
                            disabled={validationButtonDisabled}
                        />
                    </div>
                )
            },
        },
    ]

    const getTagColorForStatus = (status: ExportHistoryStatusEnum) => {
        switch (status) {
            case ExportHistoryStatusEnum.PROCESSING:
                return "blue"
            case ExportHistoryStatusEnum.DONE:
                return "green"
            case ExportHistoryStatusEnum.FAILED:
                return "red"
            case ExportHistoryStatusEnum.PARTIALLY_FAILED:
                return "yellow"
            default:
                return "grey"
        }
    }

    const showValidationData = (validationData: any, format: ExportFormatEnum) => {
        setCurrentEntryValidationData(validationData)
        setCurrentFormat(format)
        setIsValidationModalShowing(true)
    }

    const handleFileDownload = async (url: string) => {
        try {
            FileService.downloadFileAsSignedUrl(url)
        } catch (error) {
            NotificationService.showErrorNotificationBasedOnResponseError(error, t("error:error"))
        }
    }

    return (
        <SettingsLayout titleKey="label:export_history.title">
            <ExportHistoryValidationModal
                format={currentFormat}
                validationData={currentEntryValidationData}
                isShowing={isValidationModalShowing}
                onCancel={() => setIsValidationModalShowing(false)}
            />
            {exportHistoryTable.error ? (
                <ErrorTable onTableReload={() => dispatch(refetchTable(TablesEnum.EXPORT_HISTORY))} isLoading={exportHistoryTable.isFetching} />
            ) : exportHistoryTable.data.totalDocs === 0 ? (
                <div className="text-text-dark p-10">{t("missing:no_export_history")}</div>
            ) : (
                <div className="p-5 w-full overflow-y-auto overflow-x-hidden">
                    <Table
                        columns={columns}
                        dataSource={exportHistoryTable.data.docs}
                        rowKey={(record) => (record as any)._id}
                        pagination={{
                            position: ["bottomRight"],
                            showSizeChanger: true,
                            current: exportHistoryTable.data.search ? 1 : exportHistoryTable.data.page,
                            pageSize: exportHistoryTable.data.limit,
                            total: exportHistoryTable.data.totalDocs,
                            hideOnSinglePage: false,
                            pageSizeOptions: ["5", "10", "20", "50", "100"],
                        }}
                        onChange={updateTableOnChange(TablesEnum.EXPORT_HISTORY, exportHistoryTable.data)}
                        loading={{
                            spinning: exportHistoryTable.isFetching,
                            indicator: <LoadingOutlined style={{ fontSize: 30, color: "black" }} spin />,
                        }}
                    />
                </div>
            )}
        </SettingsLayout>
    )
}

export default React.memo(ExportHistoryList)
