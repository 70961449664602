import { Button } from "antd"
import { ButtonType } from "antd/es/button"
import { ButtonProps } from "antd/lib/button"
import React from "react"
import { ArrowLeft } from "react-feather"
import { useTranslation } from "react-i18next"

import { useSettingsContext } from "Features/settings/settings.context"

interface SettingsButtonProps {
    icon: React.ReactNode
    labelKey: string
    onClick?: ButtonProps["onClick"]
    type?: ButtonType
}

export const SettingsButton = ({ icon, labelKey, onClick, type }: SettingsButtonProps) => {
    const { t } = useTranslation()

    return (
        <>
            <span className="md:block hidden">
                <Button onClick={onClick} icon={icon} type={type}>
                    {t(labelKey)}
                </Button>
            </span>
            <span className="md:hidden">
                <Button onClick={onClick} icon={icon} type={type} />
            </span>
        </>
    )
}

interface SettingsLayoutProps {
    children: React.ReactNode
    titleKey: string
    titleExtra?: React.ReactNode
    buttons?: React.ReactNode
}

const SettingsLayout = ({ children, titleKey, titleExtra, buttons }: SettingsLayoutProps) => {
    const { t } = useTranslation()
    const { setListVisible } = useSettingsContext()

    return (
        <div id="finway-settings" className="w-full">
            <div className="flex flex-row justify-between items-center  min-h-34 mb-10">
                <div className="flex flex-row justify-between items-center">
                    <span className="sm:hidden max-h-36 mr-5 -ml-4" onClick={() => setListVisible(true)}>
                        <ArrowLeft />
                    </span>
                    <h2 className="mb-0">{t(titleKey)}</h2>
                    {titleExtra}
                </div>
                <div className="ml-10">
                    <div className="flex gap-10 flex-wrap">{buttons}</div>
                </div>
            </div>

            {children}
        </div>
    )
}

export default SettingsLayout
