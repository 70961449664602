import { Expense } from "@finway-group/shared/lib/models"
import { Form, Modal } from "antd"
import { Store } from "antd/lib/form/interface"
import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"

import CreateRecurringExpenseForm from "Components/forms/createRecurringExpense.form"
import { NotificationService } from "Shared/services"
import { NotificationDurationSec, NotificationTypeEnum } from "Shared/services/notification.service"
import { createRecurringExpense } from "Shared/store/actions/expense/expenseActions"
import { createLinkToRequestFunction, deleteUnnecessaryExpenseFormData } from "Shared/utils/expense.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

interface CreateRecurringExpenseModalInterface {
    isVisible: boolean
    onCancel: (state: false) => void
    parentSubscription: Expense
}

const CreateRecurringExpenseModal: React.FC<CreateRecurringExpenseModalInterface> = ({ isVisible, onCancel, parentSubscription }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [formInstance] = Form.useForm()
    const [isLoading, setIsLoading] = useStateIfMounted(false)
    const history = useHistory()

    const handleSubmit = async (values: Store) => {
        setIsLoading(true)

        // prepare data
        values.parentSubscription = parentSubscription.id
        values.datePurchased = values.datePurchased.toDate()
        const expense = new Expense(values)
        const sanitizedExpense = deleteUnnecessaryExpenseFormData(expense)

        createRecurringExpense(sanitizedExpense)(dispatch)
            .then((newExpense) => {
                NotificationService.send(
                    NotificationTypeEnum.SUCCESS,
                    t("notification:request.created.title"),
                    t("notification:request.created.message"),
                    NotificationDurationSec.REQUEST_CREATION,
                    undefined,
                    {
                        onClick: createLinkToRequestFunction(newExpense, history),
                        text: t("notification:request.created.link"),
                    },
                )
                handleHide()
            })
            .catch((err) => {
                setIsLoading(false)
                NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:request.create.title"))
            })
    }

    const handleHide = () => {
        setIsLoading(false)
        formInstance.resetFields()
        onCancel(false)
    }

    return (
        <Modal
            title={t("action:create_recurring_expense.create")}
            visible={isVisible}
            maskClosable={false}
            onOk={formInstance.submit}
            onCancel={handleHide}
            okText={t("action:create_recurring_expense.submit")}
            cancelText={t("action:cancel")}
            keyboard
            destroyOnClose
            confirmLoading={isLoading}
        >
            <CreateRecurringExpenseForm formInstance={formInstance} handleSubmit={handleSubmit} parentSubscription={parentSubscription} />
        </Modal>
    )
}

export default CreateRecurringExpenseModal
