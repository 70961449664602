import Icon from "@ant-design/icons"
import React from "react"

const eyeSvg = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="24" height="24" rx="4" fill="black" fillOpacity="0.31" />
        <path
            d="M11.9993 7C8.66602 7 5.81935 9.07333 4.66602 12C5.81935 14.9267 8.66602 17 11.9993 17C15.336 17 18.1793 14.9267 19.3327 12C18.1793 9.07333 15.336 7 11.9993 7ZM11.9993 15.3333C10.1593 15.3333 8.66602 13.84 8.66602 12C8.66602 10.16 10.1593 8.66667 11.9993 8.66667C13.8393 8.66667 15.3327 10.16 15.3327 12C15.3327 13.84 13.8393 15.3333 11.9993 15.3333ZM11.9993 10C10.896 10 9.99935 10.8967 9.99935 12C9.99935 13.1033 10.896 14 11.9993 14C13.1027 14 13.9993 13.1033 13.9993 12C13.9993 10.8967 13.1027 10 11.9993 10Z"
            fill="white"
        />
    </svg>
)

const EyeIcon = (props: any) => <Icon component={eyeSvg} {...props} />

export default EyeIcon
