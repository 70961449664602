import linkifyIt from "linkify-it"

const linkify = linkifyIt()

const isURL = (text: string) => !!linkify.match(text)

const getUrlFromString = (text: string) => {
    const matchLinkList = linkify.match(text)
    return matchLinkList && matchLinkList[0].url
}

export { isURL, getUrlFromString }
