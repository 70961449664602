import { Team } from "@finway-group/shared/lib/models"
import { useSelector } from "react-redux"

import { RootState } from "Shared/store/rootState.interface"

export const useTeams = (excludeDeleted: boolean = false) => {
    let teams = useSelector(({ teams }: RootState) => teams.items)

    if (excludeDeleted) {
        teams = teams?.filter((team: Team) => !team.deleted)
    }

    return teams ? teams.sort((a: Team, b: Team) => a.teamName?.localeCompare(b.teamName)) : []
}
