import { CalculationIntervals } from "@finway-group/shared/lib/consts"
import { CalculationIntervalEnum } from "@finway-group/shared/lib/models"
import moment from "moment"
import { useTranslation } from "react-i18next"

import { ReportingFilterInterface } from "Features/pages/reporting/reportingFilter.interface"

export const convertQueryFormatIntoFilterObject = (query: any): ReportingFilterInterface => ({
    minDate: (query.minDate && moment(query.minDate)) || "",
    maxDate: (query.maxDate && moment(query.maxDate)) || "",
    costCenters: query.costCenters,
})

export const getSelectLabel = (label: string) => {
    const { t } = useTranslation()

    switch (label) {
        case CalculationIntervals[CalculationIntervalEnum.ALL_TIME]:
            return t("label:all_times")
        case CalculationIntervals[CalculationIntervalEnum.CURRENT_YEAR]:
            return t("label:current_year")
        case CalculationIntervals[CalculationIntervalEnum.CURRENT_MONTH]:
            return t("label:current_month")
        default:
            return ""
    }
}

export const filterOvertimeAnalyticsDataByDateRange = (startDate: moment.Moment, endDate: moment.Moment, arrayToFilter: Array<any>) =>
    arrayToFilter.filter((elem) => moment(`${elem.year}${elem.month + 1}`, "YYYYMM").isBetween(startDate.startOf("month"), endDate.endOf("month"), undefined, "[]"))
