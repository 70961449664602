import { CalculationIntervals } from "@finway-group/shared/lib/consts"
import { CalculationIntervalEnum } from "@finway-group/shared/lib/models"
import { Card, Col, Row, Select } from "antd"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import { useCostCenterExpenseDataPie, useCostCenterIntervalPie } from "Shared/hooks/analytics.hooks"
import { useSubCostCenterIds } from "Shared/hooks/costCenter.hooks"
import { CostCenterService } from "Shared/services"
import { fetchCostCenterExpenseDataPie, setCostCenterIntervalPie } from "Shared/store/actions/analytics/analyticsActions"
import { getSelectLabel } from "Shared/utils/report.utils"

import PieDiagram from "../pieDiagram"

const { Option } = Select

const CostCenterPieChart = () => {
    const { t } = useTranslation()
    const subCostCenterIds = useSubCostCenterIds(true, true)
    const costCenterExpenseDataPie = useCostCenterExpenseDataPie().filter((costCenterExpenseData) => subCostCenterIds.includes(costCenterExpenseData.id))
    const costCenterIntervalPie = useCostCenterIntervalPie()
    const dispatch = useDispatch()

    const expenseValueTotal = costCenterExpenseDataPie.reduce((acc, curr) => acc + curr.value, 0)

    const handlePieCostCentersFilterChange = async (value: string) => {
        dispatch(setCostCenterIntervalPie(Number(value)))
    }

    useEffect(() => {
        dispatch(fetchCostCenterExpenseDataPie())
    }, [costCenterIntervalPie])

    return (
        <>
            {/* Dropdown for period filter of Cost Center Pie Chart */}
            <Row>
                <Col xs={24} lg={24}>
                    <div className="flex justify-between items-center pb-0">
                        <h2 className="pt-8">{t("label:cost_center")}</h2>
                        <Select
                            className="float-right"
                            defaultValue={getSelectLabel(CalculationIntervals[costCenterIntervalPie])}
                            onChange={handlePieCostCentersFilterChange}
                            dropdownMatchSelectWidth={false}
                        >
                            <Option value={CalculationIntervalEnum.ALL_TIME} label="All Times">
                                <span>{getSelectLabel(CalculationIntervals[CalculationIntervalEnum.ALL_TIME])}</span>
                            </Option>
                            <Option value={CalculationIntervalEnum.CURRENT_YEAR} label="Current Year">
                                <span>{getSelectLabel(CalculationIntervals[CalculationIntervalEnum.CURRENT_YEAR])}</span>
                            </Option>
                            <Option value={CalculationIntervalEnum.CURRENT_MONTH} label="Current Month">
                                <span>{getSelectLabel(CalculationIntervals[CalculationIntervalEnum.CURRENT_MONTH])}</span>
                            </Option>
                        </Select>
                    </div>
                </Col>
            </Row>
            {/* Cost Center usage Pie Chart */}
            <Row>
                <Col xs={24} lg={24} className="h-full">
                    <Card id="cost-center-section-pie">
                        {costCenterExpenseDataPie?.length > 0 && expenseValueTotal > 0 ? (
                            <React.Fragment>
                                <PieDiagram dataForPieDiagram={costCenterExpenseDataPie} getLabelName={(id) => CostCenterService.getCostCenterById(id).name} />
                            </React.Fragment>
                        ) : (
                            <div className="flex justify-center items-center h-300">
                                <p>{t("missing:expense_data")}</p>
                            </div>
                        )}
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default CostCenterPieChart
