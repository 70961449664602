import { ExclamationCircleOutlined } from "@ant-design/icons"
import { Company } from "@finway-group/shared/lib/models"
import { Form, Switch, Tooltip } from "antd"
import React, { useEffect } from "react"
import { useDebouncedCallback } from "use-debounce/lib"

import { useCompany } from "Shared/hooks/company.hooks"
import { getTooltipPopupContainer } from "Shared/utils/helper.utils"

export type GeneralNotificationType = {
    name: string
    title: string
    input?: any
    tooltip?: string
}

interface GeneralNotificationInterface {
    generalSettings: Array<GeneralNotificationType>
    onUpdate: (companyNotificationSettings: Company) => void
}

const GeneralNotificationSettings = ({ generalSettings, onUpdate }: GeneralNotificationInterface) => {
    const [formInstance] = Form.useForm()

    const { notifications, shouldRefetch, iban, ...rest } = useCompany()

    const DynamicComponent = (Component: any, props: any) => <Component {...props} />

    useEffect(() => {
        formInstance.setFieldsValue({
            ...notifications,
            "monthlyInvoiceReminder.dayOfMonth": notifications?.monthlyInvoiceReminder.dayOfMonth,
            "monthlyInvoiceReminder.enabled": notifications?.monthlyInvoiceReminder.enabled,
            "weeklyInvoiceReminder.startAfter": notifications?.weeklyInvoiceReminder.startAfter,
            "weeklyInvoiceReminder.enabled": notifications?.weeklyInvoiceReminder.enabled,
        })
    }, [])

    const debounceUpdate = useDebouncedCallback((key: string, value: any, fn: any) => fn(key, value), 800)

    /**
     * debounce and dispatch form changes
     */
    const handleUpdate = (data: any) =>
        Object.keys(data).map((key) =>
            debounceUpdate.callback(key, data[key], (key: string, value: boolean | number) => {
                const objectKeys = key.split(".")
                const inputValue = Number(value) || value
                onUpdate({ ...rest, notifications: { ...formInstance.getFieldsValue(), [objectKeys[0]]: { [objectKeys[1]]: inputValue } } })
            }),
        )

    return (
        <Form form={formInstance} onValuesChange={handleUpdate} onFinish={handleUpdate}>
            <ul className="description-list">
                {generalSettings.map((section, index) => (
                    <li key={index} className="flex items-center justify-between h-100 md:h-40">
                        <div className="flex flex-1 items-center w-3/4">
                            <span className="title font-normal flex items-center justify-start">
                                {!section.input && section.title}
                                {section.input && (
                                    <Form.Item label={section.title} name={section.input.name} className="h-6">
                                        {DynamicComponent(section.input.component, section.input.props)}
                                    </Form.Item>
                                )}

                                {section.tooltip && (
                                    <Tooltip getPopupContainer={getTooltipPopupContainer} title={section.tooltip} className="p-5">
                                        <ExclamationCircleOutlined />
                                    </Tooltip>
                                )}
                            </span>
                        </div>

                        <Form.Item className="mt-auto mb-auto" name={section.name} valuePropName="checked">
                            <Switch className="mr-10 pb-2" />
                        </Form.Item>
                    </li>
                ))}
            </ul>
        </Form>
    )
}

export default GeneralNotificationSettings
