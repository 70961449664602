import { supportedInvoiceFormats } from "@finway-group/shared/lib/consts/common"
import {
    ApprovalStatusEnum,
    CardTransactionTypeEnum,
    CostCenter,
    CostCenter2,
    DocumentTypeEnum,
    Employee,
    Expense,
    ExpenseKindEnum,
    ExpensePaymentFlowInformationEnum,
    ExpenseRequestTypeEnum,
    ExpenseStatusEnum,
    PriceIntervalEnum,
    RightEnum,
    SubscriptionTypeEnum,
    Transaction,
    TripFolder,
    Vendor,
    VendorTypeEnum,
} from "@finway-group/shared/lib/models"
import { getApproverIdsOfApprovalProcesses } from "@finway-group/shared/lib/utils/approvalProcess.utils"
import { MAX_INVOICE_NUMBER_LENGTH } from "@finway-group/shared/lib/utils/validators"
import { Button, Card, DatePicker, Input, Select, Tabs, Tooltip, Upload } from "antd"
import { ColumnsType } from "antd/es/table"
import Table from "antd/lib/table"
import moment from "moment"
import React, { useEffect } from "react"
import { AlertTriangle, RotateCcw, Upload as UploadIcon } from "react-feather"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { Link, useHistory } from "react-router-dom"
import { useDebouncedCallback } from "use-debounce/lib"

import { ApprovalProcessIndicatorTag } from "Components/approvalProcessesIndicator"
import DeletedTooltip from "Components/deletedTooltip"
import EmployeeLink from "Components/employeeLink"
import ExpenseStatusTag from "Components/expenseStatusTag"
import ExpenseTransactionStatusTag from "Components/expenseTransactionStatusTag"
import FolderStatusTag from "Components/folderStatusDraft"
import { CreditorOptionLabel } from "Components/forms/expenseForm/commonInputFields/creditorNumberDisplay"
import PriceLabel from "Components/priceLabel"
import TransactionStatusTag from "Components/transactionStatusTag"
import { useArchiveInterval, useCompany } from "Shared/hooks/company.hooks"
import { useSubCostCenters } from "Shared/hooks/costCenter.hooks"
import { useCardByExpense } from "Shared/hooks/creditCard.hooks"
import { useEmployees } from "Shared/hooks/employee.hooks"
import { useExpenseMatchedTransaction } from "Shared/hooks/transaction.hooks"
import { useCreditorById, useFilteredCreditorsForExpense, useVendors } from "Shared/hooks/vendor.hooks"
import { useWorkflowById } from "Shared/hooks/workflow.hooks"
import { ApprovalProcessService, AuthzService, ExpenseHttpService, ExpenseService, NotificationService } from "Shared/services"
import { ThunkDispatchResult } from "Shared/store"
import { syncExpense, updateExpense } from "Shared/store/actions/expense/expenseActions"
import { setTransaction } from "Shared/store/actions/transaction/transactionActions"
import { getNextExpenseEmbeddedApprover } from "Shared/utils/approvalProcess.utils"
import { getCostCenterLabel } from "Shared/utils/costCenter.utils"
import {
    buildUpdateDataAccordingToSetting,
    isExpenseArchived,
    isExpenseVendorDataOutdated,
    isFolderExpense,
    isMileageExpense,
    isPerDiemExpense,
    isSplitPropertiesSame,
} from "Shared/utils/expense.utils"
import { doFilterSelect, getIBANPrintFormat, getTooltipPopupContainer } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"
import { getPopupAnchor } from "Shared/utils/popup.utils"

const { TabPane } = Tabs
interface ExpenseSourceSectionInterface {
    loggedInUser: Employee
    expense: Expense
    requestedBy: Employee
    costCenter?: CostCenter
    costCenter2?: CostCenter2
    requestType: ExpenseRequestTypeEnum | undefined
    isDeliveryNoteUploading: boolean
    isInlineEditDisabled?: boolean
    doFileUpload?: (file: any, kind: DocumentTypeEnum) => any
    setIsButtonDisabled?: React.Dispatch<React.SetStateAction<boolean>>
    setIsExpenseUpdating?: React.Dispatch<React.SetStateAction<boolean>>
    isTransactionTableLoading?: boolean
    setSelectedExpense?: (expense: Expense | undefined) => void
    isExpenseUpdating?: boolean
    isOcrUploading?: boolean
    isReadOnly?: boolean
}

const ExpenseSourceSection: React.FC<ExpenseSourceSectionInterface> = ({
    loggedInUser,
    expense,
    requestedBy,
    costCenter,
    costCenter2,
    requestType,
    isDeliveryNoteUploading,
    isInlineEditDisabled = false,
    doFileUpload,
    setIsExpenseUpdating,
    isTransactionTableLoading = false,
    setSelectedExpense,
    isExpenseUpdating = false,
    isOcrUploading = false,
    isReadOnly = false,
}) => {
    const { t } = useTranslation()
    const history = useHistory()
    const dispatch: ThunkDispatchResult = useDispatch()
    const { status, kind } = expense
    const isSubscription = kind === ExpenseKindEnum.SUBSCRIPTION

    const archiveAfterXDays = useArchiveInterval()
    const archived = isExpenseArchived(expense, archiveAfterXDays)
    const isEditableBasedOnArchive = ExpenseService.isExpenseEditableBasedOnArchive(archived)

    const shouldShowInvoiceFields =
        (expense.status === ExpenseStatusEnum.INVOICE_PENDING || expense.status === ExpenseStatusEnum.APPROVED) && !expense.deleted && !isReadOnly && isEditableBasedOnArchive
    const isInvoiceFieldRequired = expense.status === ExpenseStatusEnum.APPROVED && expense.paymentFlowInformation !== ExpensePaymentFlowInformationEnum.REPORTING_ONLY
    const [parentSubscription, setParentSubscription] = useStateIfMounted<Expense | undefined>(undefined)
    const [invoiceNumber, setInvoiceNumber] = useStateIfMounted(expense.invoiceNumber)
    const [invoiceDate, setInvoiceDate] = useStateIfMounted<moment.Moment | undefined | null>(expense.invoiceDate ? moment(expense.invoiceDate) : undefined)
    const [invoiceDueDate, setInvoiceDueDate] = useStateIfMounted<moment.Moment | undefined | null>(expense.invoiceDueDate ? moment(expense.invoiceDueDate) : undefined)
    const [expenseAccount, setExpenseAccount] = useStateIfMounted(expense.expenseAccount)
    const [datePaidAt, setDatePaidAt] = useStateIfMounted(expense.datePaidAt ? moment(expense.datePaidAt) : moment())
    const [accountingPeriod, setAccountingPeriod] = useStateIfMounted(expense.accountingPeriod ? moment(expense.accountingPeriod) : null) // Uses null so it can be emptied.
    const [isCostCenterUpdating, setIsCostCenterUpdating] = useStateIfMounted(false)
    const [isVendorUpdating, setIsVendorUpdating] = useStateIfMounted(false)
    const { card } = useCardByExpense(expense)
    const costCenters = useSubCostCenters(true)
    const employees = useEmployees({ excludeDeleted: true })

    const creditor = useCreditorById(expense.getCreditor()?._id)

    const company = useCompany()
    const currentApprovalProcess =
        expense.approvalProcesses.find((approvalProcess) => approvalProcess.status === ApprovalStatusEnum.IN_PROGRESS) ||
        expense.approvalProcesses.find((approvalProcess) => approvalProcess.status === ApprovalStatusEnum.NOT_STARTED)
    const currentWorkflowId = currentApprovalProcess?.workflow
    const currentWorkflow = useWorkflowById(currentWorkflowId)
    const splitsHasAnyExpenseAccount = expense.splits.some((s) => !!s.expenseAccount)
    const splitsHasAnyCostCenter2 = expense.splits.some((s) => !!s.costCenter2)
    const isUniform = isSplitPropertiesSame(expense.splits)
    const showCostCenterSelect = !isInlineEditDisabled && expense.splits.every((s) => !s.costCenter) && !expense.costCenter
    const { showCostCentersIds } = useCompany()
    const vendors = useVendors(true)
    const creditors = useFilteredCreditorsForExpense(AuthzService.isRightGrantedForLoggedInUser(RightEnum.EMPLOYEE__ALL__READ), expense)

    const currentExpenseCreditor = useCreditorById(expense.getCreditor()?._id)
    const embeddedExpenseCreditor = expense.getCreditor()

    const shouldDisableDate = isMileageExpense(expense) ? (date: moment.Moment) => date.isAfter(moment(), "day") : () => false
    const areInputsDisabled = isOcrUploading || isExpenseUpdating
    const paymentTerm = currentExpenseCreditor?.source instanceof Vendor ? currentExpenseCreditor?.source?.rule?.paymentTerm : undefined

    const nextApprover = ApprovalProcessService.getNextApprover(expense.approvalProcesses)
    const nextExpenseEmbeddedApprover = getNextExpenseEmbeddedApprover(expense.approvalProcesses)

    const { transactionsToDisplay: displayedTransactions } = useExpenseMatchedTransaction(expense)

    const showError = (err: any) => NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:request.edit.title"))

    const SyncExpenseWithCreditor = async () => {
        try {
            await ExpenseHttpService.syncExpenseCreditor(expense.id)
            dispatch(syncExpense(expense.id))
        } catch (err) {
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:error"))
        }
    }

    const debouncedEventHandler = useDebouncedCallback(async (value, type) => {
        try {
            setIsExpenseUpdating?.(true)
            switch (type) {
                case "invoiceNumber":
                    if (await ExpenseService.checkForDuplicate(expense, value)) {
                        await dispatch(updateExpense(expense.id, new Expense({ ...expense, invoiceNumber: value, skipEmail: true })))
                    } else {
                        setInvoiceNumber(expense.invoiceNumber)
                    }
                    break
                case "invoiceDate":
                    const updateData: any = new Expense({ ...expense, invoiceDate: value, skipEmail: true })
                    if (paymentTerm && value) {
                        const dueDate = moment(value).add(paymentTerm, "days")
                        if (dueDate !== invoiceDueDate) {
                            setInvoiceDueDate(dueDate)
                            updateData.invoiceDueDate = dueDate
                        }
                    }
                    if (isMileageExpense(updateData) || isPerDiemExpense(updateData)) {
                        updateData.datePurchased = updateData.invoiceDate
                    }
                    await dispatch(updateExpense(expense.id, updateData))
                    break
                case "invoiceDueDate":
                    await dispatch(updateExpense(expense.id, new Expense({ ...expense, invoiceDueDate: value, skipEmail: true })))
                    break
                case "expenseAccount":
                    await dispatch(updateExpense(expense.id, new Expense({ ...expense, expenseAccount: value, skipEmail: true })))
                    break
                case "datePaidAt":
                    await dispatch(updateExpense(expense.id, new Expense({ ...expense, datePaidAt: value, skipEmail: true })))
                    break
                default:
                    break
            }
        } catch (err) {
            showError(err)
        } finally {
            setIsExpenseUpdating?.(false)
        }
    }, 1500)

    // When the component goes to be unmounted, flush the debounced input
    useEffect(() => () => debouncedEventHandler.flush(), [debouncedEventHandler])

    useEffect(() => {
        const fetchParentSubscription = async () => {
            const subscriptions = await ExpenseService.fetchSubscriptions()
            const parentSub = subscriptions.find((exp: Expense) => exp.id === expense.parentSubscription)
            setParentSubscription(parentSub)
        }
        if (isSubscription && expense.subscriptionType === SubscriptionTypeEnum.RENEWAL_REQUEST) {
            fetchParentSubscription()
        }
    }, [expense.id, expense.parentSubscription])

    useEffect(() => setInvoiceNumber(expense.invoiceNumber), [expense.invoiceNumber])
    useEffect(() => setExpenseAccount(expense.expenseAccount), [expense.expenseAccount])
    useEffect(() => setDatePaidAt(expense.datePaidAt ? moment(expense.datePaidAt) : moment()), [expense.datePaidAt])
    useEffect(() => setInvoiceDate(expense.invoiceDate ? moment(expense.invoiceDate) : undefined), [expense.invoiceDate])
    useEffect(() => setInvoiceDueDate(expense.invoiceDueDate ? moment(expense.invoiceDueDate) : undefined), [expense.invoiceDueDate])
    useEffect(() => setAccountingPeriod(expense.accountingPeriod ? moment(expense.accountingPeriod) : null), [expense.accountingPeriod])

    const onVendorUpdate = async (value: string) => {
        try {
            setIsVendorUpdating(true)
            setIsExpenseUpdating?.(true)
            const selectedCreditor = creditors.find((c) => c.id === value)

            // Assign new vendor value to the expense object
            const updatedExpense = new Expense({
                ...expense,
                ...(selectedCreditor?.type === VendorTypeEnum.USER
                    ? {
                          vendorType: VendorTypeEnum.USER,
                          creditorUser: { _id: value },
                          vendor: undefined,
                      }
                    : {
                          vendorType: VendorTypeEnum.VENDOR,
                          vendor: { _id: value },
                          creditorUser: undefined,
                      }),
                skipEmail: true,
            })

            // Determine the cc, cc2, expenseaccount, etc, based on the newly updated expense
            const extraUpdateData = buildUpdateDataAccordingToSetting(updatedExpense, vendors, employees, company)
            Object.assign(updatedExpense, extraUpdateData)

            // Adjust splits. Rules applied by changing vendor here should overwrite the splits.
            for (const split of expense.splits) {
                if (updatedExpense.costCenter) split.costCenter = updatedExpense.costCenter
            }

            // Rebuild approval process with vendor change. Expenses inside folder do not change its aproval processes and follow the folder approval processes.
            if (!updatedExpense.folderId) {
                const approvalProcesses = await ApprovalProcessService.getApprovalProcessesAfterExpenseUpdate(updatedExpense, company, {
                    valuesChange: { vendor: { previousValue: expense.vendor?._id, currentValue: value } },
                    awaitConfirmation: true,
                })
                if (!approvalProcesses) return
                updatedExpense.approvalProcesses = approvalProcesses
            }

            const updatedExpenseResponse = await updateExpense(expense.id, updatedExpense)(dispatch)
            setSelectedExpense?.(updatedExpenseResponse)
        } catch (err) {
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:error"))
        } finally {
            setIsVendorUpdating(false)
            setIsExpenseUpdating?.(false)
        }
    }

    const onCostCenterUpdate = async (value: string) => {
        try {
            setIsCostCenterUpdating(true)
            setIsExpenseUpdating?.(true)
            const updatedExpense = new Expense({
                ...expense,
                costCenter: value,
                skipEmail: true,
            })

            // Rebuild approval process with cost center change. Expenses inside folder do not change its aproval processes and follow the folder approval processes.
            if (!expense.folderId) {
                const approvalProcesses = await ApprovalProcessService.getApprovalProcessesAfterExpenseUpdate(updatedExpense, company, {
                    valuesChange: { vendor: { previousValue: expense.costCenter, currentValue: value } },
                    awaitConfirmation: true,
                })
                if (!approvalProcesses) return
                updatedExpense.approvalProcesses = approvalProcesses
            }

            const updatedExpenseResponse = await updateExpense(expense.id, updatedExpense)(dispatch)
            setSelectedExpense?.(updatedExpenseResponse)
        } catch (err) {
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:error"))
        } finally {
            setIsCostCenterUpdating(false)
            setIsExpenseUpdating?.(false)
        }
    }

    const VendorDetails = () => {
        if (!currentExpenseCreditor || !embeddedExpenseCreditor) return null
        // early return if user has no vendor view right
        if (!AuthzService.isRightGrantedForLoggedInUser(RightEnum.VENDOR__ALL__READ)) return <span className="text">{embeddedExpenseCreditor.name}</span>

        const redirectDestination = currentExpenseCreditor?.type === "User" ? "employees" : "vendors"
        const VendorLink = <Link to={`/${redirectDestination}/${currentExpenseCreditor.id}`}>{embeddedExpenseCreditor.name}</Link>
        return (
            <div>
                {embeddedExpenseCreditor.creditorNumber ? (
                    <>
                        {VendorLink}
                        {currentExpenseCreditor?.source?.deleted && (
                            <DeletedTooltip translationKey="tooltips:vendor.was_deleted" deletionDate={currentExpenseCreditor?.source?.dateDeleted} />
                        )}
                    </>
                ) : (
                    <Tooltip getPopupContainer={getTooltipPopupContainer} title={t("info:employee_no_creditor_number")}>
                        <div className="flex justify-end">
                            <AlertTriangle className="px-2 mt-2" fill="#FBAC3B" size={18} stroke="#FFFFFF" />
                            {VendorLink}
                        </div>
                    </Tooltip>
                )}
            </div>
        )
    }

    const workflowSourceSection = (
        <li>
            <span className="title">{t("label:workflow.workflow")}</span>
            <div className="flex gap-10">
                <div className="truncate xl:max-w-160 max-w-100">
                    {AuthzService.isRightGrantedForLoggedInUser(RightEnum.WORKFLOW__ALL__READ) ? (
                        <Link to={`/workflows/${currentWorkflow?._id}`}>{currentWorkflow?.name}</Link>
                    ) : (
                        <span className="text-text">{currentWorkflow?.name}</span>
                    )}
                </div>
                <ApprovalProcessIndicatorTag scope={currentApprovalProcess?.scope} approvalProcesses={expense.approvalProcesses} />
            </div>
        </li>
    )

    const getPurchaseData = () => {
        const isCostCenterApprover = AuthzService.isRightGrantedForLoggedInUser(RightEnum.CC1__ALL__OWNER) && loggedInUser.id === costCenter?.responsibleUser
        const isRequester = loggedInUser.id === expense.requestedBy._id
        const isApproverOfAnyApprovalProcess = getApproverIdsOfApprovalProcesses(expense.approvalProcesses).includes(loggedInUser.id)
        const isTeamApprover = AuthzService.isRightGrantedForLoggedInUser(RightEnum.EXPENSE__TEAM__APPROVE) && loggedInUser.activeCompanyProfile.team === requestedBy.team
        const canReadCostCenter = AuthzService.isRightGrantedForLoggedInUser(RightEnum.CC1__ALL__READ) || isCostCenterApprover
        return (
            <ul className="description-list pb-10" id="expense-purchase-data-list">
                <li>
                    <span className="title">{t("label:status")}</span>
                    <span className="text">
                        <ExpenseStatusTag status={status} />
                    </span>
                </li>
                <li>
                    <span className="title">{t("label:purchased")}</span>
                    <span data-testid="purchaseDateExpenseForm" className="text">
                        {moment(expense.datePurchased).format("ll")}
                    </span>
                </li>
                {expense.performancePeriodStartDate &&
                    (expense.performancePeriodStartDate === expense.performancePeriodEndDate ? (
                        <li>
                            <span className="title">
                                <Tooltip getPopupContainer={getTooltipPopupContainer} title={t("info:cannot_be_transferred_to_datev")}>
                                    {t("label:performance_date")}*
                                </Tooltip>
                            </span>
                            <span data-testid="performancePeriodStartDateExpenseForm" className="text">
                                {moment(expense.performancePeriodStartDate).format("ll")}
                            </span>
                        </li>
                    ) : (
                        <>
                            <li>
                                <span className="title">
                                    <Tooltip getPopupContainer={getTooltipPopupContainer} placement="bottom" title={t("info:cannot_be_transferred_to_datev")}>
                                        {t("label:performance_period_start_date")}*
                                    </Tooltip>
                                </span>
                                <span className="text" style={{ marginLeft: "10px", minWidth: "85px" }}>
                                    {moment(expense.performancePeriodStartDate).format("ll")}
                                </span>
                            </li>
                            <li>
                                <span className="title">
                                    <Tooltip getPopupContainer={getTooltipPopupContainer} placement="bottom" title={t("info:cannot_be_transferred_to_datev")}>
                                        {t("label:performance_period_end_date")}*
                                    </Tooltip>
                                </span>

                                <span className="text" style={{ marginLeft: "10px", minWidth: "85px" }}>
                                    {moment(expense.performancePeriodEndDate).format("ll")}
                                </span>
                            </li>
                        </>
                    ))}
                <li>
                    <span className="title">{t("label:requested_by")}</span>
                    <span className="text truncate max-w-250">
                        <EmployeeLink employee={requestedBy} expenseExtendedRefUser={expense.requestedBy} />
                    </span>
                </li>
                {nextApprover && (
                    <li>
                        <span className="title">{t("label:next_approver")}</span>
                        <span className="text truncate max-w-250">
                            <EmployeeLink employee={nextApprover} expenseExtendedRefUser={nextExpenseEmbeddedApprover} />
                        </span>
                    </li>
                )}
                {currentWorkflow && workflowSourceSection}
                <li>
                    <span className="title">{t("label:cost_center")}</span>
                    {(canReadCostCenter || isRequester || isApproverOfAnyApprovalProcess || isTeamApprover) && costCenter && isUniform.costCenter ? (
                        <div className="flex gap-10">
                            {canReadCostCenter ? (
                                <Link to={`/costCenters/${costCenter._id}`}>
                                    <span className="block truncate max-w-180 text-right">{costCenter.name}</span>
                                </Link>
                            ) : (
                                <span className="block truncate max-w-180 text-right">{costCenter.name}</span>
                            )}
                            {costCenter?.deleted && <DeletedTooltip className="mt-4" translationKey="tooltips:cost_center.was_deleted" deletionDate={costCenter?.dateDeleted} />}
                        </div>
                    ) : showCostCenterSelect ? (
                        <Select
                            onSelect={onCostCenterUpdate}
                            className="w-1/2"
                            showSearch
                            filterOption={doFilterSelect}
                            getPopupContainer={getPopupAnchor()}
                            defaultValue=""
                            loading={isCostCenterUpdating}
                            disabled={isCostCenterUpdating || isReadOnly || areInputsDisabled || isVendorUpdating}
                        >
                            <Select.Option value="" disabled>
                                <div className="flex items-center overflow-hidden">
                                    <span>-</span>
                                </div>
                            </Select.Option>
                            {costCenters.map((cc, index) => (
                                <Select.Option key={index} value={cc._id} id={`costcenter-option-${index}`} label={getCostCenterLabel(cc, showCostCentersIds)}>
                                    {getCostCenterLabel(cc, showCostCentersIds)}
                                </Select.Option>
                            ))}
                        </Select>
                    ) : (
                        <span className="text truncate">{isUniform.costCenter && costCenter ? costCenter?.name : expense.splits.length > 1 ? t("label:splitted") : "-"}</span>
                    )}
                </li>
                {(costCenter2 || (expense.splits.length > 1 && splitsHasAnyCostCenter2)) && (
                    <li>
                        <span className="title">{t("label:cost_center_2")}</span>
                        <span className="text truncate max-w-180 text-right">{isUniform.costCenter2 && costCenter2 ? costCenter2.name : t("label:splitted")}</span>
                    </li>
                )}
                {(expenseAccount || (expense.splits.length > 1 && splitsHasAnyExpenseAccount)) && (
                    <li>
                        <span className="title">{t("label:expense_account")}</span>
                        <span className="text ml-16">
                            <p className="truncate">
                                {expenseAccount && isUniform.expenseAccount ? `${expenseAccount.accountCode} - ${expenseAccount.accountName}` : t("label:splitted")}
                            </p>
                        </span>
                    </li>
                )}
                <li>
                    <span className="title">{t("label:vendor")}</span>
                    {currentExpenseCreditor && (currentExpenseCreditor.type !== VendorTypeEnum.VENDOR || (currentExpenseCreditor.source as Vendor)?.isApproved) ? (
                        <VendorDetails />
                    ) : !isInlineEditDisabled && !isReadOnly ? (
                        <Select
                            onSelect={onVendorUpdate}
                            className="w-1/2"
                            showSearch
                            filterOption={doFilterSelect}
                            getPopupContainer={getPopupAnchor()}
                            value={currentExpenseCreditor ? currentExpenseCreditor.id : ""}
                            loading={isVendorUpdating}
                            disabled={isReadOnly || isVendorUpdating || areInputsDisabled || isCostCenterUpdating}
                        >
                            <Select.Option value="" disabled>
                                <div className="flex items-center overflow-hidden">
                                    <span>-</span>
                                </div>
                            </Select.Option>
                            {creditors.map((creditor, index) => (
                                <Select.Option key={index} value={creditor.id} label={creditor.name} id={`vendor-option-${index}`}>
                                    <CreditorOptionLabel creditor={creditor} />
                                </Select.Option>
                            ))}
                        </Select>
                    ) : (
                        <span className="text truncate">-</span>
                    )}
                </li>
                {card && (
                    <li>
                        <span className="title">{t("label:attached_card")}</span>
                        <span className="text truncate max-w-300">
                            <Link to={`/credit-cards/${card?._id}`}>{`**** **** **** ${card?.last4Digits}`}</Link>
                        </span>
                    </li>
                )}
                {isSubscription && expense.subscriptionType === SubscriptionTypeEnum.RENEWAL_REQUEST && parentSubscription && (
                    <li>
                        <span className="title">{t("label:subscription")}</span>
                        <span className="text">
                            <Link to={`/subscriptions/${parentSubscription?.id}`}>{parentSubscription?.name}</Link>
                        </span>
                    </li>
                )}
                {expense.paymentFlowInformation && (
                    <li>
                        <span className="title">{t("label:payment_flow_information.title")}</span>
                        <span className="text">
                            <span>{t(`label:payment_flow_information.${expense.paymentFlowInformation}`)}</span>
                        </span>
                    </li>
                )}
                {requestType !== ExpenseRequestTypeEnum.PURCHASE && (
                    <React.Fragment>
                        {shouldShowInvoiceFields ? (
                            <li>
                                <span className={`w-1/2 title ${isInvoiceFieldRequired && "label-required"}`}>
                                    {isMileageExpense(expense) || isPerDiemExpense(expense) ? t("label:internal_invoice_no") : t("label:invoice_no")}
                                </span>
                                <Input
                                    className="w-1/2"
                                    placeholder="Invoice number"
                                    value={invoiceNumber}
                                    onChange={(event) => {
                                        setInvoiceNumber(event.target.value)
                                        debouncedEventHandler.callback(event.target.value, "invoiceNumber")
                                        if (!event.target.value) debouncedEventHandler.flush()
                                    }}
                                    onBlur={() => debouncedEventHandler.flush()}
                                    disabled={areInputsDisabled}
                                    maxLength={MAX_INVOICE_NUMBER_LENGTH}
                                />
                            </li>
                        ) : (
                            expense.invoiceNumber && (
                                <li>
                                    <span className="title">{isMileageExpense(expense) || isPerDiemExpense(expense) ? t("label:internal_invoice_no") : t("label:invoice_no")}</span>
                                    <span data-testid="invoiceNumberExpenseForm" className="text">
                                        {expense.invoiceNumber}
                                    </span>
                                </li>
                            )
                        )}
                        {shouldShowInvoiceFields ? (
                            <li>
                                <span className={`w-1/2 title ${isInvoiceFieldRequired && "label-required"}`}>
                                    {isMileageExpense(expense) || isPerDiemExpense(expense) ? t("label:internal_invoice_date") : t("label:invoice_date")}
                                </span>
                                <DatePicker
                                    className="w-1/2"
                                    value={invoiceDate}
                                    format={moment.localeData().longDateFormat("L")}
                                    placeholder={t("placeholder:request.date")}
                                    onChange={(value) => {
                                        setInvoiceDate(value)
                                        debouncedEventHandler.callback(value, "invoiceDate")
                                        if (!value) debouncedEventHandler.flush()
                                    }}
                                    onBlur={(_event) => debouncedEventHandler.flush()}
                                    getPopupContainer={getPopupAnchor()}
                                    disabledDate={shouldDisableDate}
                                    disabled={areInputsDisabled}
                                />
                            </li>
                        ) : (
                            expense.invoiceDate && (
                                <li>
                                    <span className="title">
                                        {isMileageExpense(expense) || isPerDiemExpense(expense) ? t("label:internal_invoice_date") : t("label:invoice_date")}
                                    </span>
                                    <span data-testid="invoiceDateExpenseForm" className="text">
                                        {moment(expense.invoiceDate).format("ll")}
                                    </span>
                                </li>
                            )
                        )}
                        {shouldShowInvoiceFields ? (
                            <li>
                                <span className={`w-1/2 title `}>{t("label:invoice_due_date")}</span>
                                <DatePicker
                                    className="w-1/2"
                                    value={invoiceDueDate}
                                    format={moment.localeData().longDateFormat("L")}
                                    placeholder={t("placeholder:request.date")}
                                    onChange={(value) => {
                                        setInvoiceDueDate(value)
                                        debouncedEventHandler.callback(value, "invoiceDueDate")
                                        if (!value) debouncedEventHandler.flush()
                                    }}
                                    onBlur={(_event) => debouncedEventHandler.flush()}
                                    getPopupContainer={getPopupAnchor()}
                                    disabled={areInputsDisabled}
                                />
                            </li>
                        ) : (
                            expense.invoiceDueDate && (
                                <li>
                                    <span className="title">{t("label:invoice_due_date")}</span>
                                    <span className="text">{moment(expense.invoiceDueDate).format("ll")}</span>
                                </li>
                            )
                        )}
                        {expense.isReviewed() &&
                        AuthzService.isRightGrantedForLoggedInUser(RightEnum.EXPENSE__ALL__REVIEW) &&
                        ExpenseService.isExpenseEditableBasedOnArchive(archived) ? (
                            <li>
                                <span className={`w-1/2 title label-required`}>{t("label:date_paid_at")}</span>
                                <DatePicker
                                    className="w-1/2"
                                    value={datePaidAt}
                                    format={moment.localeData().longDateFormat("L")}
                                    placeholder={t("placeholder:request.date")}
                                    onChange={(value) => {
                                        setDatePaidAt(value ?? moment())
                                        debouncedEventHandler.callback(value, "datePaidAt")
                                        if (!value) debouncedEventHandler.flush()
                                    }}
                                    onBlur={(_event) => debouncedEventHandler.flush()}
                                    getPopupContainer={getPopupAnchor()}
                                    disabled={areInputsDisabled}
                                />
                            </li>
                        ) : (
                            expense.isSuccessfullyProcessed() &&
                            expense.datePaidAt && (
                                <li>
                                    <span className="title">
                                        {moment(expense.datePaidAt).startOf("day").isAfter(moment()) ? t("label:to_be_paid_at") : t("label:date_paid_at")}
                                    </span>
                                    <span className="text">{moment(expense.datePaidAt).format("ll")}</span>
                                </li>
                            )
                        )}
                        {/* TODO enable when we want to include GoB in the forthcoming version */}
                        {/* {expense.isAtLeastApproved() &&
                            (expense.isGobdCompliantInvoice() || !shouldShowInvoiceFields ? (
                                <li>
                                    <span className="title">{t("label:accounting_period")}</span>
                                    <span className="text">{expense.accountingPeriod ? moment(expense.accountingPeriod).format("ll") : "-"}</span>
                                </li>
                            ) : (
                                <li>
                                    <span className="w-1/2 title label-required">{t("label:accounting_period")}</span>
                                    <DatePicker
                                        className="w-1/2"
                                        value={accountingPeriod}
                                        format={moment.localeData().longDateFormat("L")}
                                        placeholder={t("placeholder:request.date")}
                                        onChange={(value) => {
                                            setAccountingPeriod(value)
                                            debouncedEventHandler.callback(value ? value.toDate() : null, "accountingPeriod")
                                            if (!value) debouncedEventHandler.flush()
                                        }}
                                        // Accounting period cannot be older than the invoice date or it cannot be younger than CPU Date (gobdInvoiceComplianceDate)
                                        disabledDate={(date) =>
                                            date.isAfter(expense.invoiceDate, "day") ||
                                            (!expense.gobdInvoiceComplianceDate && date.isBefore(expense.gobdInvoiceComplianceDate, "day"))
                                        }
                                        onBlur={(_event) => debouncedEventHandler.flush()}
                                        getPopupContainer={getPopupAnchor()}
                                    />
                                </li>
                            ))} */}
                        {expense.isGobdCompliantInvoice() && (
                            <li>
                                <span className="title">{t("label:gobd.invoice_compliance_date")}</span>
                                <span className="text">{expense.gobdInvoiceComplianceDate ? moment(expense.gobdInvoiceComplianceDate).format("ll") : "-"}</span>
                            </li>
                        )}
                        {expense.matchedTransactions?.length > 0 && AuthzService.isRightGrantedForLoggedInUser(RightEnum.TRANSACTION__ALL__READ) && (
                            <li>
                                <span className="title">{t("label:matching.transaction_status")}</span>
                                <span className="text">
                                    <ExpenseTransactionStatusTag expense={expense} />
                                </span>
                            </li>
                        )}
                        {!isSubscription &&
                            doFileUpload !== undefined &&
                            !isReadOnly &&
                            expense.kind !== ExpenseKindEnum.MILEAGE &&
                            expense.kind !== ExpenseKindEnum.PER_DIEM &&
                            !expense.isGobdCompliantInvoice() &&
                            isEditableBasedOnArchive && (
                                <li>
                                    <span className="grid-breakpoints hidden title xs:block xs:w-1/2 sm:hidden xl:block xl:w-1/2">{t("label:delivery_note")}</span>
                                    <span className="grid-breakpoints w-full xs:w-1/2 sm:w-full xl:w-1/2 ant-btn p-0">
                                        <Upload
                                            customRequest={(req) => {
                                                doFileUpload([req.file], DocumentTypeEnum.DELIVERY_NOTE)
                                            }}
                                            accept={supportedInvoiceFormats.join(",")}
                                            showUploadList={false}
                                            disabled={areInputsDisabled}
                                        >
                                            <Button loading={isDeliveryNoteUploading} disabled={expense.deleted} type="text" className="bg-transparent shadow-none">
                                                <UploadIcon />
                                                <span>{expense.deliveryNote ? t("action:replace_delivery_note") : t("action:upload_delivery_note")}</span>
                                            </Button>
                                        </Upload>
                                    </span>
                                </li>
                            )}
                    </React.Fragment>
                )}
            </ul>
        )
    }

    const renderCreditorData = () => {
        if (!creditor) return <></>

        if (creditor.source instanceof Vendor) {
            const expenseVendor = expense.vendor

            if (!expenseVendor) return null
            const vendor = creditor.source
            let locationLbl = ""
            if (!expenseVendor?.city && !expenseVendor?.zip && !expenseVendor?.country) locationLbl = "-"
            else if (expenseVendor?.city && expenseVendor?.country)
                locationLbl = `${expenseVendor.city} ${expenseVendor.zip ? `(${expenseVendor.zip}` : ""}, ${expenseVendor.country})`
            else if (expenseVendor.country) locationLbl = expenseVendor.country

            return (
                <ul className="description-list pb-10 overflow-auto">
                    <li>
                        <span className="title">{t("label:name")}</span>
                        <span className="text truncate max-w-250">
                            <Link to={`/vendors/${expenseVendor?._id}`}>{expenseVendor?.name}</Link>
                        </span>
                    </li>
                    <li>
                        <span className="title">{t("label:creditor_number")}</span>
                        <span className="text truncate max-w-250">{expenseVendor?.creditorNumber ?? "-"}</span>
                    </li>
                    <li>
                        <span className="title">{t("label:description")}</span>
                        <span className="text truncate max-w-250">{vendor.description}</span>
                    </li>
                    <li>
                        <span className="title">{t("label:location")}</span>
                        <span className="text truncate max-w-250">{locationLbl}</span>
                    </li>
                    <li>
                        <span className="title">{t("label:vat_number")}</span>
                        <span className="text">{expenseVendor?.vatNumber ?? "-"}</span>
                    </li>
                    <li>
                        <span className="title">{t("label:iban")}</span>
                        <span className="text">{expenseVendor?.iban ? getIBANPrintFormat(expenseVendor.iban) : "-"}</span>
                    </li>
                    <li>
                        <span className="title">{t("label:bic")}</span>
                        <span className="text">{expenseVendor?.bic || "-"}</span>
                    </li>
                    <li>
                        <span className="title">{t("label:contact_person")}</span>
                        <span className="text truncate max-w-250">{vendor.contactPersonName || "-"}</span>
                    </li>
                </ul>
            )
        }
        const expenseEmployee = expense.creditorUser

        return (
            <ul className="description-list pb-10 overflow-auto">
                <li>
                    <span className="title">{t("label:name")}</span>
                    <span className="text truncate max-w-250">
                        <Link to={`/employees/${expenseEmployee?._id}`}>
                            {expenseEmployee?.firstName} {expenseEmployee?.lastName}
                        </Link>
                    </span>
                </li>
                <li>
                    <span className="title">{t("label:email")}</span>
                    <span className="text truncate max-w-250">{expenseEmployee?.email}</span>
                </li>
                <li>
                    <span className="title">{t("label:location")}</span>
                    <span className="text truncate max-w-250">{expenseEmployee?.location}</span>
                </li>
                <li>
                    <span className="title">{t("label:iban")}</span>
                    <span className="text">{expenseEmployee?.iban || "-"}</span>
                </li>
                <li>
                    <span className="title">{t("label:bic")}</span>
                    <span className="text">{expenseEmployee?.bic || "-"}</span>
                </li>
                {AuthzService.isRightGrantedForLoggedInUser(RightEnum.EMPLOYEE__ALL__READ) && (
                    <li>
                        <span className="title">{t("label:creditor_number")}</span>
                        <span className="text truncate max-w-250">{expenseEmployee?.creditorNumber || "-"}</span>
                    </li>
                )}
            </ul>
        )
    }

    const transactionColumns: ColumnsType<any> = [
        {
            key: "transactionData.date",
            ellipsis: true,
            sorter: true,
            align: "center",
            render: ({ transactionData }: Transaction) => <span>{moment(transactionData?.date).format("Do MMM, YYYY")}</span>,
        },
        {
            key: "transactionData.amount",
            ellipsis: true,
            align: "center",
            render: ({ transactionData }: Transaction) => (
                <PriceLabel value={transactionData?.amount} currency={transactionData?.currency || expense.currency} interval={PriceIntervalEnum.ONE_TIME} addPlusSign />
            ),
        },
        {
            key: "status",
            title: t("label:status"),
            ellipsis: true,
            render: ({ status, cardData }: Transaction) => (
                <TransactionStatusTag status={status} isReversal={cardData?.cardTransactionType === CardTransactionTypeEnum.ONLINE_REVERSE} />
            ),
        },
    ]

    const getTransactionData = () => (
        <Table
            showHeader={false}
            loading={isTransactionTableLoading}
            rowKey={({ _id }: any) => _id}
            dataSource={displayedTransactions}
            pagination={false}
            columns={transactionColumns}
            onRow={(transaction) => ({
                onClick: () => {
                    dispatch(setTransaction(transaction))
                    history.push(`/transactions/${transaction._id}`)
                },
            })}
        />
    )

    const getFolderData = () => {
        if (!isFolderExpense(expense)) return <></>
        const isCostCenterApprover = AuthzService.isRightGrantedForLoggedInUser(RightEnum.CC1__ALL__OWNER) && loggedInUser.id === costCenter?.responsibleUser
        return (
            <ul className="description-list pb-10 overflow-auto">
                <li>
                    <span className="title">{t("label:status")}</span>
                    <span className="text">
                        <FolderStatusTag folder={expense as TripFolder} />
                    </span>
                </li>
                <li>
                    <span className="title">{t("label:folder.trip_start_date")}</span>
                    <span className="text">{moment(expense.tripDate).format("ll")}</span>
                </li>
                <li>
                    <span className="title">{t("label:requested_by")}</span>
                    <span className="text truncate max-w-250">
                        <EmployeeLink employee={requestedBy} expenseExtendedRefUser={expense.requestedBy} />
                    </span>
                </li>
                {nextApprover && (
                    <li>
                        <span className="title">{t("label:next_approver")}</span>
                        <span className="text truncate max-w-250">
                            <EmployeeLink employee={nextApprover} expenseExtendedRefUser={nextExpenseEmbeddedApprover} />
                        </span>
                    </li>
                )}
                {currentWorkflow && workflowSourceSection}
                <li>
                    <span className="title">{t("label:cost_center")}</span>
                    <span>
                        {AuthzService.isRightGrantedForLoggedInUser(RightEnum.CC1__ALL__READ) || isCostCenterApprover ? (
                            <Link to={`/costCenters/${costCenter?._id}`}>{costCenter?.name}</Link>
                        ) : (
                            costCenter?.name
                        )}
                    </span>
                </li>

                {(costCenter2 || (expense.splits.length > 1 && splitsHasAnyCostCenter2)) && (
                    <li>
                        <span className="title">{t("label:cost_center_2")}</span>
                        <span className="text truncate max-w-250">{isUniform.costCenter2 && costCenter2 ? costCenter2.name : t("label:splitted")}</span>
                    </li>
                )}
                {(expenseAccount || (expense.splits.length > 1 && splitsHasAnyExpenseAccount)) && (
                    <li>
                        <span className="title">{t("label:expense_account")}</span>
                        <span className="text ml-16">
                            <p className="truncate">
                                {expenseAccount && isUniform.expenseAccount ? `${expenseAccount.accountCode} - ${expenseAccount.accountName}` : t("label:splitted")}
                            </p>
                        </span>
                    </li>
                )}
                <li>
                    <span className="title">{t("label:folder.submitted_on")}</span>
                    <span className="text">{moment(expense.dateCreated).format("ll")}</span>
                </li>
                {(expense as any).submittedOn && (
                    <li>
                        <span className="title">{t("label:submitted_on")}</span>
                        <span className="text">{moment((expense as any).submittedOn).format("ll")}</span>
                    </li>
                )}
            </ul>
        )
    }

    return (
        <Card className="card-with-tabs" id="expense-source-section">
            <Tabs defaultActiveKey="1" className="mb-8">
                {!isFolderExpense(expense) ? (
                    <TabPane key="purchaseData" tab={t("label:purchase_data")}>
                        {getPurchaseData()}
                    </TabPane>
                ) : (
                    <TabPane key="folderDetails" tab={t("label:folder.trip_folder_details")}>
                        {getFolderData()}
                    </TabPane>
                )}

                {currentExpenseCreditor?.source && AuthzService.isRightGrantedForLoggedInUser(RightEnum.VENDOR__ALL__READ) && (
                    <TabPane key="vendorData" tab={t("label:vendor_data")}>
                        {isExpenseVendorDataOutdated(currentExpenseCreditor.source, expense.getCreditor()?.source) && !expense.gobdInvoiceComplianceDate && (
                            <Tooltip title={t("label:vendor_data_changed")}>
                                <Button className="w-full mt-5 mb-10 flex-1 btn-default p-5" onClick={SyncExpenseWithCreditor}>
                                    <RotateCcw />
                                    <span>{t("action:request.sync")}</span>
                                </Button>
                            </Tooltip>
                        )}
                        {renderCreditorData()}
                    </TabPane>
                )}
                {displayedTransactions.length > 0 && (
                    <TabPane key="transactionData" tab={t("label:transaction_data")}>
                        {getTransactionData()}
                    </TabPane>
                )}
            </Tabs>
        </Card>
    )
}

export default ExpenseSourceSection
