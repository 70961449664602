import { LoadingOutlined } from "@ant-design/icons"
import { Expense } from "@finway-group/shared/lib/models"
import { Empty, Table } from "antd"
import { ColumnsType } from "antd/lib/table"
import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"

import NoSearchDataFound from "Components/NoSearchDataFound"
import AmountColumn from "Components/expenseAmountColumn"
import ExpenseTagColumn from "Components/expenseTagColumn"
import { ErrorTable } from "Components/layout/errorTable"
import Loading from "Components/loading"
import RequestColumn from "Components/requestColumn"
import ExpandTripFolderTableIcon from "Components/tables/expandTripFolderTableIcon"
import { useCompany } from "Shared/hooks/company.hooks"
import { useFetchTable, useTable, useTableFilterAndSearchLoading, useTableSearchString } from "Shared/hooks/table.hooks"
import { useLoggedInEmployeeProfile } from "Shared/hooks/user.hooks"
import { setExpense } from "Shared/store/actions/expense/expenseActions"
import { refetchTable, updateTableOnChange } from "Shared/store/actions/tables/tableActions"
import { TablesEnum } from "Shared/store/reducers/tableConfigReducer"
import { getStatusLabel } from "Shared/utils/expense.utils"
import { insertIf } from "Shared/utils/helper.utils"
import { getSortOrderForColumn } from "Shared/utils/table.utils"

const InProgressTab: React.FC<{}> = () => {
    const { t } = useTranslation()
    const history = useHistory()
    const dispatch = useDispatch()
    const loggedInUser = useLoggedInEmployeeProfile()
    const dashboardSearchString = useTableSearchString(TablesEnum.IN_PROGRESS)
    const inProgressTable = useTable<Expense>(TablesEnum.IN_PROGRESS)
    const tabLoading = useTableFilterAndSearchLoading(TablesEnum.IN_PROGRESS)
    const { taggingEnabled } = useCompany()
    const showTags = taggingEnabled && inProgressTable.data.docs.some((expense) => expense?.expenseTags?.length)

    useFetchTable(TablesEnum.IN_PROGRESS)

    const expandableColumn = inProgressTable.data.docs.some((expense) => (expense as any)?.children && (expense as any)?.children.length > 0)
        ? [
              {
                  dataIndex: "expandIcon",
                  width: 30,
                  align: "center",
              } as any,
          ]
        : []

    const columns: ColumnsType<Expense> = [
        ...expandableColumn,
        {
            title: t("label:request"),
            key: "expenseNumber",
            align: "left",
            className: "pl-10",
            width: 300,
            ...(loggedInUser.settings.showExpenseNumbers && {
                sorter: true,
                sortOrder: getSortOrderForColumn(inProgressTable, "expenseNumber"),
            }),
            render: (data: Expense) => <RequestColumn request={data} />,
        },
        ...insertIf(showTags, {
            title: t("label:tag"),
            key: "tag",
            align: "left",
            width: 200,
            render: ({ expenseTags: expenseTagIds }: Expense) => <ExpenseTagColumn expenseTagIds={expenseTagIds} />,
        }),
        {
            title: t("label:status"),
            key: "status",
            width: 120,
            align: "left",
            sorter: true,
            sortOrder: getSortOrderForColumn(inProgressTable, "status"),
            render: (data: Expense) => <div>{getStatusLabel(data.status)}</div>,
        },
        {
            title: loggedInUser.settings.showGrossAmount ? t("label:gross_amount") : t("label:net_amount"),
            key: "totalNetPrice",
            width: 180,
            align: "right",
            className: "pr-10",
            sorter: true,
            sortOrder: getSortOrderForColumn(inProgressTable, "totalNetPrice"),
            render: (data: Expense) => <AmountColumn request={data} showGross={loggedInUser.settings.showGrossAmount} />,
        },
    ]

    return (
        <>
            {tabLoading ? (
                <Loading />
            ) : (
                <div>
                    {inProgressTable.error ? (
                        <ErrorTable onTableReload={() => dispatch(refetchTable(TablesEnum.IN_PROGRESS))} isLoading={inProgressTable.isFetching} />
                    ) : inProgressTable.data.totalDocs === 0 && !inProgressTable.isFetching ? (
                        dashboardSearchString ? (
                            <div className="animation-appear">
                                <NoSearchDataFound />
                            </div>
                        ) : (
                            <Empty
                                className="animation-appear"
                                image={`./icons/empty_table.svg`}
                                imageStyle={{
                                    height: 150,
                                    marginRight: "auto",
                                    marginLeft: "auto",
                                    marginTop: "15%",
                                    marginBottom: "40px",
                                    display: "inline-block",
                                }}
                                description={<span className="text-lg font-bold mt-2 mb-0">{t("info:empty_state.all.title")}</span>}
                            >
                                <p>{t("info:empty_state.all.message")}</p>
                            </Empty>
                        )
                    ) : (
                        <div className="overflow-auto mb-24 p-2 animation-appear">
                            <Table<Expense>
                                rowKey={(record) => record.id}
                                dataSource={inProgressTable.data.docs}
                                columns={columns}
                                onChange={updateTableOnChange(TablesEnum.IN_PROGRESS, inProgressTable.data)}
                                loading={{
                                    spinning: inProgressTable.isFetching,
                                    indicator: <LoadingOutlined style={{ fontSize: 30, color: "black" }} spin />,
                                }}
                                pagination={{
                                    position: ["bottomRight"],
                                    showSizeChanger: true,
                                    current: inProgressTable.data.page,
                                    pageSize: inProgressTable.data.limit,
                                    hideOnSinglePage: false,
                                    total: inProgressTable.data.totalDocs,
                                    pageSizeOptions: ["5", "10", "20", "50", "100"],
                                }}
                                onRow={(record, _rowIndex) => ({
                                    onClick: (_event) => {
                                        dispatch(setExpense(record))
                                        history.push(`/expenses/${record.id}`)
                                    },
                                })}
                                expandIcon={(props) => <ExpandTripFolderTableIcon props={props} />}
                            />
                        </div>
                    )}
                </div>
            )}
        </>
    )
}

export default InProgressTab
