import { LoadingOutlined } from "@ant-design/icons"
import { Expense, ExpenseKindEnum, ExpenseRequestTypeEnum, PriceIntervalEnum, RightEnum } from "@finway-group/shared/lib/models"
import { Badge, Button, Empty, Row, Table, Tag, Tooltip } from "antd"
import { ColumnsType } from "antd/lib/table"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { Plus as PlusIcon } from "react-feather"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { useDebouncedCallback } from "use-debounce/lib"

import NoSearchDataFound from "Components/NoSearchDataFound"
import ProgressBar from "Components/ProgressBar"
import VendorImage from "Components/VendorImage"
import DataUploadAlert from "Components/alert/dataUpload.alert"
import { ConditionalWrapper } from "Components/conditionalWrapper"
import Filter from "Components/filter/filter"
import FilterSection from "Components/filterSection"
import { ErrorTable } from "Components/layout/errorTable"
import Loading from "Components/loading"
import MassExportButton from "Components/massExport.button"
import CreateCardModal from "Components/modals/createCard.modal"
import ExpenseCreateFormModal from "Components/modals/expenseCreateForm.modal"
import PageTitle from "Components/page/pageTitle"
import PriceLabel from "Components/priceLabel"
import SearchInput from "Components/searchInput"
import { useAreCardsActivated } from "Shared/hooks/corporate.hooks"
import { useCostCenters2 } from "Shared/hooks/costCenter2.hooks"
import { useCostCenters } from "Shared/hooks/costCenter.hooks"
import { usePagedCards } from "Shared/hooks/creditCard.hooks"
import { useEmployees } from "Shared/hooks/employee.hooks"
import { useExpenseAccounts } from "Shared/hooks/expenseAccount.hooks"
import { applyFilter } from "Shared/hooks/filter.hooks"
import { useRolesMap } from "Shared/hooks/role.hooks"
import { useFetchTable, useTable, useTableFilterAndSearchLoading } from "Shared/hooks/table.hooks"
import { useCreditors, useVendors } from "Shared/hooks/vendor.hooks"
import { AuthzService, ExpenseHttpService, UserService } from "Shared/services"
import DialogService from "Shared/services/dialog.service"
import { setExpense } from "Shared/store/actions/expense/expenseActions"
import { refetchTable, updateTableOnChange, updateTableSearch } from "Shared/store/actions/tables/tableActions"
import { TablesEnum } from "Shared/store/reducers/tableConfigReducer"
import { getCardDataForSubscription } from "Shared/utils/creditCard.utils"
import { getExpenseCards, getPriceIntervalForBillingPeriod, isRequestActionDisabled, isSubscriptionRenewingSoon } from "Shared/utils/expense.utils"
import { getTooltipPopupContainer, insertIf } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"
import { parseHtml } from "Shared/utils/htmlParser.utils"
import { getSortOrderForColumn, isTableFilterApplied } from "Shared/utils/table.utils"

const SubscriptionList: React.FC = () => {
    const { t } = useTranslation()
    const history = useHistory()
    const dispatch = useDispatch()
    const loggedInUser = UserService.getLoggedInEmployeeProfile()
    const [isExpenseFormShowing, setIsExpenseFormShowing] = useState<boolean>(false)
    const [isCreateCardModalVisible, setIsCreateCardModalVisible] = useStateIfMounted(false)
    const [cardPrefillData, setCardPrefillData] = useStateIfMounted<any>(undefined)
    const [isFilterModalShowing, setIsFilterModalShowing] = useState<boolean>(false)
    const costCenters = useCostCenters()
    const vendors = useVendors()
    const creditors = useCreditors(AuthzService.isRightGrantedForLoggedInUser(RightEnum.EMPLOYEE__ALL__READ), true, true)

    const employees = useEmployees()
    const cardsActive = useAreCardsActivated()
    const costCenters2 = useCostCenters2()
    const expenseAccounts = useExpenseAccounts()
    const subscriptionTable = useTable<Expense>(TablesEnum.SUBSCRIPTIONS)
    const subCardsIds = getExpenseCards(subscriptionTable.data.docs)
    const pagedCards = usePagedCards(1, 20, `&_id[in]=${subCardsIds}`)
    const canWriteSubscriptions = AuthzService.canCreateSubscription()
    const pageLoading = useTableFilterAndSearchLoading(TablesEnum.SUBSCRIPTIONS)
    const rolesMap = useRolesMap({ excludeDeleted: true })
    const [areButtonsEnabled, setAreButtonsEnabled] = useStateIfMounted(!isRequestActionDisabled(loggedInUser, vendors, costCenters, employees, rolesMap))

    useFetchTable(TablesEnum.SUBSCRIPTIONS)

    useEffect(() => {
        pagedCards.setCurrentLimit(subscriptionTable.data.limit)
        pagedCards.setQuery(`&_id[in]=${subCardsIds}`)
    }, [subscriptionTable.data.docs])

    useEffect(() => {
        setAreButtonsEnabled(costCenters.length > 0 && vendors.length > 0)
    }, [costCenters.length, vendors.length, setAreButtonsEnabled])

    const onIssueCard = async (e: any, expense: Expense) => {
        e.stopPropagation()

        if (expense.paymentFlowInformation && !(await DialogService.confirmPaymentFlowInformationResetOnCardIssue())) return

        setCardPrefillData(getCardDataForSubscription(expense))
        setIsCreateCardModalVisible(true)
    }

    const renderAvailableCardAmountForMissingCardViewingRights = (expenseCard: string | undefined) =>
        expenseCard ? <Tag className="ant-tag-green">{t("label:cards.tag.active")}</Tag> : <span>{t("info:cards.ask_admin_to_issue")}</span>

    const columns: ColumnsType<Expense> = [
        {
            title: t("label:subscription"),
            width: 250,
            key: "name",
            className: "pl-10",
            ellipsis: true,
            sortOrder: getSortOrderForColumn(subscriptionTable, "name"),
            sorter: true,
            render: (expense: Expense) => {
                const vendor = vendors.find((vendor) => vendor.id === expense.vendor?._id)
                return (
                    <div className="flex items-center image-loader gap-10">
                        {vendor && <VendorImage size="large" vendor={vendor} />}
                        <div className="flex flex-col truncate">
                            <div className="flex items-center mb-3">
                                <p className="font-bold leading-tight mb-0 truncate">{expense.name}</p>
                                {isSubscriptionRenewingSoon(expense.dateCancellationDue) && <Badge className="ml-8 ant-badge-warning">{t("label:renews_soon")}</Badge>}
                                {expense.isPaused && <Badge className="ml-8 ant-badge-info">{t("label:paused")}</Badge>}
                            </div>
                            <p className="text-sm text-text leading-none mb-0 truncate max-h-16">{parseHtml(expense.description)}</p>
                        </div>
                    </div>
                )
            },
        },
        {
            title: t("label:renewal_date"),
            width: 220,
            key: "dateCancellationDue",
            sortOrder: getSortOrderForColumn(subscriptionTable, "dateCancellationDue"),
            sorter: true,
            render: (expense: Expense) => <div>{moment(expense.dateCancellationDue).format("ll")}</div>,
        },
        {
            title: t("label:amount"),
            width: 160,
            key: loggedInUser.settings.showGrossAmount ? "totalGrossPrice" : "totalNetPrice",
            className: "pr-10",
            sortOrder: loggedInUser.settings.showGrossAmount
                ? getSortOrderForColumn(subscriptionTable, "totalGrossPrice")
                : getSortOrderForColumn(subscriptionTable, "totalNetPrice"),
            sorter: true,
            render: (expense: Expense) => (
                <PriceLabel
                    value={loggedInUser.settings.showGrossAmount ? expense.totalGrossPrice : expense.totalNetPrice}
                    currency={expense.currency}
                    interval={getPriceIntervalForBillingPeriod(expense.billingPeriod)}
                />
            ),
        },
        ...insertIf(cardsActive, {
            title: t("label:cards.subscriptions.available_amount_per_card"),
            width: 220,
            align: "right",
            className: "pr-10",
            render: (expense: Expense) => {
                const subscriptionCard = pagedCards.cards.find((card) => card._id === expense.card)
                return subscriptionCard ? (
                    <div>
                        <Row className="block">
                            <span className="text-text-dark mb-4 leading-none float-left ml-2">
                                <PriceLabel value={subscriptionCard.spending} currency={subscriptionCard.currency} interval={PriceIntervalEnum.ONE_TIME} />
                            </span>

                            <span className="float-right mb-4 text-xs mr-5 opacity-75">
                                <PriceLabel value={subscriptionCard.limit} currency={subscriptionCard.currency} interval={PriceIntervalEnum.ONE_TIME} />
                            </span>
                        </Row>
                        <Row className="block">
                            <ProgressBar
                                total={subscriptionCard.limit || 1}
                                value={subscriptionCard.spending}
                                currency={subscriptionCard.currency}
                                forecastValue={subscriptionCard.spendingForecast || 0}
                                thin
                                hideLabels
                                extended
                                // TODO: what do we want?
                                // showRemaining={GREEN_LIGHT4}
                            />
                        </Row>
                    </div>
                ) : (
                    AuthzService.isRightGrantedForLoggedInUser(RightEnum.CARD__ALL__WRITE) ||
                        (AuthzService.isRightGrantedForLoggedInUser(RightEnum.CARD__TEAM__WRITE) && UserService.isInMyTeam(expense.createdBy._id) ? (
                            <Button className="btn-highlight-green" onClick={(e) => onIssueCard(e, expense)}>
                                {t("action:cards.issue_card")}
                            </Button>
                        ) : (
                            // If `expense.card` is defined then we assume that the user is not allowed to see the card details as the subscription card is not included in the list of fetched cards. In this case we only show "active" tag.
                            renderAvailableCardAmountForMissingCardViewingRights(expense.card)
                        ))
                )
            },
        }),
    ]

    const getEmptySubscriptionList = (description: string, content: any) => (
        <>
            {AuthzService.isDataUploaderGrantedForLoggedInUser() && !isTableFilterApplied(subscriptionTable.data.filter) && <DataUploadAlert />}

            <Empty
                className="animation-appear"
                image="./icons/empty_table.svg"
                imageStyle={{
                    height: 150,
                    marginRight: "auto",
                    marginLeft: "auto",
                    marginTop: "15%",
                    marginBottom: "40px",
                    display: "inline-block",
                }}
                description={<span className="text-lg font-bold">{description}</span>}
            >
                <div className="flex flex-col justify-between items-center">
                    {content}
                    {canWriteSubscriptions && (
                        <ConditionalWrapper
                            condition={!areButtonsEnabled}
                            wrapper={(children: any) => (
                                <Tooltip
                                    getPopupContainer={getTooltipPopupContainer}
                                    // prettier-ignore
                                    title={<div>{t('tooltips:vendor_cc_approver_needed.create_at_least_one')}</div>}
                                    placement="left"
                                >
                                    {children}
                                </Tooltip>
                            )}
                        >
                            <Button className="mt-14" disabled={!areButtonsEnabled} type="primary" onClick={() => setIsExpenseFormShowing(true)}>
                                <PlusIcon />
                                <span>{t("action:subscription:create")}</span>
                            </Button>
                        </ConditionalWrapper>
                    )}
                </div>
            </Empty>
        </>
    )

    const handleSearch = useDebouncedCallback((searchString) => dispatch(updateTableSearch(TablesEnum.SUBSCRIPTIONS, searchString)), 800)

    return (
        <div>
            <PageTitle titleKey={"view:subscriptions.title"}>
                <SearchInput onSearch={(searchString) => handleSearch.callback(searchString)} value={subscriptionTable.data.search} />

                <Filter
                    table={TablesEnum.SUBSCRIPTIONS}
                    isFilterModalShowing={isFilterModalShowing}
                    setIsFilterModalShowing={setIsFilterModalShowing}
                    onFilter={(data) => applyFilter(TablesEnum.SUBSCRIPTIONS, data)}
                    options={{ employees, creditors, costCenters, costCenters2, expenseAccounts }}
                />

                <MassExportButton
                    serviceExportFunction={ExpenseHttpService.exportAllSubscriptions}
                    visible={AuthzService.isRightGrantedForLoggedInUser(RightEnum.SUBSCRIPTION__ALL__READ)}
                />
                <ConditionalWrapper
                    condition={!areButtonsEnabled}
                    wrapper={(children: any) => (
                        <Tooltip
                            getPopupContainer={getTooltipPopupContainer}
                            // prettier-ignore
                            title={<div>{t('tooltips:vendor_cc_approver_needed.create_at_least_one')}</div>}
                            placement="left"
                        >
                            {children}
                        </Tooltip>
                    )}
                >
                    {canWriteSubscriptions && (
                        <Button disabled={!areButtonsEnabled} type="primary" onClick={() => setIsExpenseFormShowing(true)}>
                            <PlusIcon />
                            <span>{t("action:subscription:create")}</span>
                        </Button>
                    )}
                </ConditionalWrapper>

                <ExpenseCreateFormModal
                    kind={ExpenseKindEnum.SUBSCRIPTION}
                    type={ExpenseRequestTypeEnum.PURCHASE}
                    isPreApprovedSubscription={true}
                    hideExpenseKindRadioOption={true}
                    isNew={true}
                    isShowing={isExpenseFormShowing}
                    onCancel={() => setIsExpenseFormShowing(false)}
                />
                <CreateCardModal isVisible={isCreateCardModalVisible} hideCardType prefilledData={cardPrefillData} onCancel={() => setIsCreateCardModalVisible(false)} />
            </PageTitle>

            <FilterSection table={TablesEnum.SUBSCRIPTIONS} options={{ employees, creditors, costCenters, costCenters2, expenseAccounts }} />

            {pageLoading ? (
                <Loading />
            ) : subscriptionTable.error ? (
                <ErrorTable onTableReload={() => dispatch(refetchTable(TablesEnum.SUBSCRIPTIONS))} isLoading={subscriptionTable.isFetching} />
            ) : subscriptionTable.data.totalDocs === 0 && !subscriptionTable.isFetching ? (
                subscriptionTable.data.search ? (
                    <div className="animation-appear">
                        <NoSearchDataFound />
                    </div>
                ) : canWriteSubscriptions ? (
                    !isTableFilterApplied(subscriptionTable.data.filter) ? (
                        getEmptySubscriptionList(t("info:empty_state.subscriptions.title"), t("info:empty_state.subscriptions.message"))
                    ) : (
                        getEmptySubscriptionList(t("info:no_filter_data_found.title.subscription"), t("info:no_filter_data_found.message"))
                    )
                ) : !isTableFilterApplied(subscriptionTable.data.filter) ? (
                    getEmptySubscriptionList(t("info:empty_state.subscriptions_employees.title"), t("info:empty_state.subscriptions_employees.message"))
                ) : (
                    getEmptySubscriptionList(t("info:no_filter_data_found.title.subscription"), t("info:no_filter_data_found.message_approver"))
                )
            ) : (
                <div>
                    <div className="overflow-auto p-2 animation-appear">
                        <Table
                            rowKey={(record: Expense) => record.id}
                            dataSource={subscriptionTable.data.docs}
                            columns={columns}
                            onChange={updateTableOnChange(TablesEnum.SUBSCRIPTIONS, subscriptionTable.data)}
                            pagination={{
                                position: ["bottomRight"],
                                showSizeChanger: true,
                                current: subscriptionTable.data.page,
                                defaultPageSize: subscriptionTable.data.limit,
                                hideOnSinglePage: false,
                                total: subscriptionTable.data.totalDocs,
                                pageSizeOptions: ["5", "10", "20", "50", "100"],
                            }}
                            loading={{
                                spinning: subscriptionTable.isFetching || pagedCards.isLoading,
                                indicator: <LoadingOutlined style={{ fontSize: 30, color: "black" }} spin />,
                            }}
                            onRow={(record: Expense) => ({
                                onClick: () => {
                                    dispatch(setExpense(record))
                                    history.push(`/subscriptions/${record.id}`)
                                },
                            })}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}

export default SubscriptionList
