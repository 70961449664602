/* This modal allows the user to select the fields to overwrite the expense with the OCR data */
import { ExclamationCircleOutlined } from "@ant-design/icons"
import { CurrencyEnum, Expense, OcrResult, VendorTypeEnum } from "@finway-group/shared/lib/models"
import { OcrResultItem } from "@finway-group/shared/lib/models/ocr/ocr.model"
import { Modal, Table } from "antd"
import { ButtonProps } from "antd/lib/button"
import React, { useEffect } from "react"
import { ArrowRight } from "react-feather"
import { useTranslation } from "react-i18next"

import { isSet } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"
import { RowInterface } from "Shared/utils/ocr.utils.types"

interface ConfirmOCRChangesModalProps {
    isShowing: boolean
    expense: Expense
    ocrObject: OcrResult
    formOCRDataDifferences: Array<RowInterface>
    defaultCurrency: CurrencyEnum
    onCancel: () => void
    setUpdatedObject: React.Dispatch<React.SetStateAction<OcrResult>>
}

const ConfirmOCRChangesModal = ({ isShowing, expense, ocrObject, formOCRDataDifferences, onCancel, setUpdatedObject }: ConfirmOCRChangesModalProps) => {
    const [selectedRows, setSelectedRows] = useStateIfMounted<Array<RowInterface>>([])
    const [newCurrency, setNewCurrency] = useStateIfMounted<CurrencyEnum>(ocrObject.currency ?? expense?.currency ?? CurrencyEnum.EUR)

    const { t } = useTranslation()

    const columns = [
        {
            title: <div className="text-xxs text-gray-900 font-light">{t("info:ocr_updates.fields").toUpperCase()}</div>,
            dataIndex: "formattedField",
            key: "field",
            render(text: string) {
                return {
                    props: {
                        className: "cursor-default",
                    },
                    children: <div className={` "p-0" text-xxs text-gray-900`}>{text}</div>,
                }
            },
        },
        {
            title: <div className="text-xxs text-gray-900  font-light">{t("info:ocr_updates.original").toUpperCase()}</div>,
            dataIndex: "formattedOriginalData",
            key: "originalData",
            render(text: string, record: RowInterface) {
                return {
                    props: {
                        className: "cursor-default",
                    },
                    children: (
                        <div className="flex justify-between items-center text-xxs text-primary">
                            <p>{isSet(text) ? text : "-"}</p>
                            {record.field !== "itemParent" && <ArrowRight className="w-12 h-12 text-gray-700" />}
                        </div>
                    ),
                }
            },
        },
        {
            title: <div className="text-xxs text-gray-900 font-light">{t("info:ocr_updates.new").toUpperCase()}</div>,
            dataIndex: "formattedOcrData",
            key: "ocrData",
            render(text: string) {
                return {
                    props: {
                        className: "cursor-default",
                    },
                    children: <div className="text-xxs text-primary">{text}</div>,
                }
            },
        },
    ]

    //  to initialize the table with all rows selected
    useEffect(() => {
        const allDataDifferenceRows: Array<RowInterface> = []

        for (const row of formOCRDataDifferences) {
            allDataDifferenceRows.push(row)

            if (row.children?.length) {
                for (const child of row.children) {
                    allDataDifferenceRows.push(child)
                }
            }
        }

        setSelectedRows(allDataDifferenceRows)
    }, [])

    const updateOcrDataBasedOnRowsSelected = (): OcrResult => {
        const selectedRowsExcludingItems: Array<RowInterface> = []
        const selectedRowsItems: Array<RowInterface> = []

        for (const row of selectedRows) {
            if (row.split) {
                selectedRowsItems.push(row)
            } else {
                selectedRowsExcludingItems.push(row)
            }
        }

        // format vendor and invoice data from row to expense object
        const formattedRowsExcludingSplits = selectedRowsExcludingItems.map((row) => ({
            [row.field]: row.ocrData,
        }))
        const ocrObjectExcludingSplits = Object.assign({}, ...formattedRowsExcludingSplits)

        // updating itemsData with the ocr values selected on the modal
        const updatedExpenseSplits = expense.splits.map((split: OcrResultItem, splitIndex) => {
            selectedRowsItems.forEach(({ field, key, ocrData }) => {
                if (key === `${splitIndex}${field}`) {
                    split = {
                        ...split,
                        [field]: ocrData,
                    }
                }
            })

            return split
        })

        // get the matching splits (OCR and expense)
        const matchedSplitNames = updatedExpenseSplits.map((split) => "name" in split && split.name && split.name.toLowerCase())

        // add the OCR items that didn't match with the expense splits to the list of splits
        const notMatchedOcrItems = ocrObject.splits?.filter((split) => "name" in split && !matchedSplitNames.includes(split.name ? split.name.toLowerCase() : false))

        const allSplits = notMatchedOcrItems ? updatedExpenseSplits.concat(notMatchedOcrItems) : updatedExpenseSplits

        return {
            ...ocrObjectExcludingSplits,
            ...(ocrObjectExcludingSplits.vendor && { vendorType: VendorTypeEnum.VENDOR, creditorUser: undefined }),
            ...(allSplits.length !== 0 && { splits: expense.splits.length ? allSplits : ocrObject.splits }),
        }
    }

    const onSubmit = () => {
        setUpdatedObject(updateOcrDataBasedOnRowsSelected())
        onCancel()
    }

    const onRowSelect = (_keys: Array<React.Key>, selectedRows: Array<RowInterface>) => {
        setSelectedRows(selectedRows)

        const selectedCurrency = selectedRows.find((selectedRow) => selectedRow.field === "currency")
        if (selectedCurrency) {
            if (newCurrency !== selectedCurrency.ocrData) setNewCurrency(selectedCurrency.ocrData)
        } else if (newCurrency !== expense.currency) setNewCurrency(expense.currency)
    }

    return (
        <Modal
            className="ant-modal--large"
            visible={isShowing}
            onOk={onSubmit}
            onCancel={onCancel}
            okText={t("action:update_through_ocr_continue")}
            title={t("info:ocr_updates.modal_title")}
            okButtonProps={{ "data-testid": "continueButtonOcrUpdate" } as ButtonProps}
            cancelButtonProps={{ "data-testid": "cancelButtonOcrUpdate" } as ButtonProps}
        >
            <div id="ocrUpdateModal" className="flex flex-col">
                <div className="flex gap-18 items-center ">
                    <ExclamationCircleOutlined className="w-22 h-22 text-finway-orange-dark" />
                    <span className="text-primary text-sm font-normal">{t("info:ocr_updates.automatically_recognized_data")}</span>
                </div>
                <span className="text-[14px] text-gray-900 mt-20">{t("info:ocr_updates.description")}</span>
                <span className="text-primary text-sm font-normal mt-20">{t("info:ocr_updates.updates_through_ocr")}</span>
                <div className="border-1 border-gray-300 mt-10">
                    <Table
                        rowKey="key"
                        bordered={false}
                        columns={columns}
                        dataSource={formOCRDataDifferences}
                        pagination={false}
                        defaultExpandAllRows
                        rowSelection={{
                            checkStrictly: false,
                            selectedRowKeys: selectedRows.map((row) => row.key),
                            onChange: onRowSelect,
                            renderCell: (_value, _record, _index, originNode) => (
                                <div onClick={(e) => e.stopPropagation()} className="bigger-hitbox-checkbox" data-testid="ocrCheckboxCell">
                                    {originNode}
                                </div>
                            ),
                        }}
                    />
                </div>
            </div>
        </Modal>
    )
}

export default ConfirmOCRChangesModal
