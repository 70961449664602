import { DocumentChangeInterface, OperationTypeEnum } from "@finway-group/shared/lib/models"
import * as Sentry from "@sentry/react"

import { refetchTransactionCategoryRelatedTables } from "Shared/store/actions/tables/tableActions"
import { fetchAllTransactionCategories, syncTransactionCategory } from "Shared/store/actions/transactionCategory/transactionCategoryAction"
import { isClientErrorCode } from "Shared/utils/errorHandler.utils"

export const subscribeToTransactionCategory = async (dispatch: any, data: DocumentChangeInterface) => {
    const {
        fullDocument: { documentId, operationType },
    } = data

    try {
        switch (operationType) {
            case OperationTypeEnum.INSERT:
            case OperationTypeEnum.UPDATE:
            case OperationTypeEnum.DELETE:
                await dispatch(syncTransactionCategory(documentId as string))
                dispatch(refetchTransactionCategoryRelatedTables())
                break
            case OperationTypeEnum.BULK_INSERT:
            case OperationTypeEnum.BULK_UPDATE:
            case OperationTypeEnum.BULK_DELETE:
                await dispatch(fetchAllTransactionCategories())
                dispatch(refetchTransactionCategoryRelatedTables())
                break
            default:
                break
        }
    } catch (err) {
        if (isClientErrorCode(err)) return
        Sentry.captureException(`[Transaction Category Socket] Error: ${typeof err === "string" ? err : JSON.stringify(err)}`)
    }
}

export const subscribeToTransactionCategoryCallbacks = async (dispatch: any, { fullDocument: { operationType } }: DocumentChangeInterface) => {
    switch (operationType) {
        case OperationTypeEnum.BULK_INSERT:
        case OperationTypeEnum.BULK_UPDATE:
        case OperationTypeEnum.BULK_DELETE:
            await dispatch(fetchAllTransactionCategories())
            dispatch(refetchTransactionCategoryRelatedTables())
            break
        default:
            break
    }
}
