import { LoadingOutlined } from "@ant-design/icons"
import { vendorRecommendations } from "@finway-group/shared/lib/consts"
import { PriceIntervalEnum, RightEnum, Vendor } from "@finway-group/shared/lib/models"
import { Button, Empty, Table, Tag, Tooltip } from "antd"
import { ColumnsType } from "antd/lib/table"
import React from "react"
import { AlertTriangle, Plus as PlusIcon } from "react-feather"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"

import NoSearchDataFound from "Components/NoSearchDataFound"
import VendorImage from "Components/VendorImage"
import DataUploadAlert from "Components/alert/dataUpload.alert"
import FilterSection from "Components/filterSection"
import { ErrorTable } from "Components/layout/errorTable"
import Loading from "Components/loading"
import LoadingData from "Components/loading.data"
import PriceLabel from "Components/priceLabel"
import { useGroupedExpenseData } from "Shared/hooks/analytics.hooks"
import { useCompany } from "Shared/hooks/company.hooks"
import { useFetchTable, useTable, useTableFilterAndSearchLoading } from "Shared/hooks/table.hooks"
import { useLoggedInEmployeeProfile } from "Shared/hooks/user.hooks"
import { AuthzService, VendorService } from "Shared/services"
import { ThunkDispatchResult } from "Shared/store"
import { refetchTable, updateTableOnChange } from "Shared/store/actions/tables/tableActions"
import { setVendor } from "Shared/store/actions/vendor/vendorActions"
import { TablesEnum } from "Shared/store/reducers/tableConfigReducer"
import { VendorWithExpenseData } from "Shared/store/reducers/tableReducer"
import { getRecommendationTagColour, insertIf } from "Shared/utils/helper.utils"
import { getSortOrderForColumn, isTableFilterApplied } from "Shared/utils/table.utils"

const VendorTable: React.FC<{ onAddVendor: (state: boolean) => void }> = ({ onAddVendor }) => {
    const { t } = useTranslation()
    const history = useHistory()
    const dispatch: ThunkDispatchResult = useDispatch()

    const vendorsTable = useTable<VendorWithExpenseData>(TablesEnum.VENDORS)

    const currentEmployee = useLoggedInEmployeeProfile()
    const { globalCurrency } = currentEmployee.settings
    const pageLoading = useTableFilterAndSearchLoading(TablesEnum.VENDORS)
    const { isVendorCreationRestricted } = useCompany()

    useFetchTable(TablesEnum.VENDORS)

    const { expenseData, setCreditorIds } = useGroupedExpenseData(TablesEnum.VENDORS, vendorsTable, "vendor")

    const columns: ColumnsType<VendorWithExpenseData> = [
        {
            title: <span>{t("label:vendor")}</span>,
            key: "name",
            width: 300,
            className: "pl-10",
            ellipsis: true,
            sortOrder: getSortOrderForColumn(vendorsTable, "name"),
            sorter: {
                compare: (x: Vendor, y: Vendor) => x.name.localeCompare(y.name),
            },

            render: (vendor: Vendor) => (
                <div className="flex items-center image-loader gap-10">
                    <VendorImage size={"large"} vendor={vendor} />
                    <div className="flex flex-col truncate">
                        <div className="flex items-center mb-3">
                            <p className="font-bold leading-tight mb-0 truncate">{vendor.name}</p>
                            {!vendor?.isApproved && (
                                <Tooltip title={t("info:pending_vendor")}>
                                    <AlertTriangle fill="#FBAC3B" size={18} stroke="#FFFFFF" className="ml-3" />
                                </Tooltip>
                            )}
                        </div>
                        <p className="text-sm text-text leading-none mb-0 truncate">{vendor.description}</p>
                    </div>
                </div>
            ),
        },
        ...insertIf(AuthzService.isRightGrantedForLoggedInUser(RightEnum.EXPENSE__ALL__ACCESS_TABLE_PAY_READ), {
            title: t("label:creditor_number"),
            key: "creditorNumber",
            width: 150,
            sortOrder: getSortOrderForColumn(vendorsTable, "creditorNumber"),
            sorter: (vendorA: Vendor, vendorB: Vendor) => vendorA.creditorNumber - vendorB.creditorNumber,
            render: (vendor: Vendor) => vendor.creditorNumber,
        }),
        {
            title: t("label:orders"),
            key: "orders",
            width: 100,
            render: (vendor: VendorWithExpenseData) => {
                const vendorExpenseData = expenseData.find((data) => data.id === vendor.id)
                if (!vendorExpenseData) return <LoadingData />
                return <div>{vendorExpenseData.amount}</div>
            },
        },
        {
            title: t("label:recommendation"),
            key: "recommendation",
            width: 150,
            sortOrder: getSortOrderForColumn(vendorsTable, "recommendation"),
            sorter: {
                compare: (a: Vendor, b: Vendor) => a.recommendation - b.recommendation,
            },
            render: (vendor: Vendor) => (
                <div>
                    <Tag className={`min-w-80" ${getRecommendationTagColour(vendorRecommendations[vendor.recommendation])}`}>
                        <span className="">{t(`label:recommendations.${vendorRecommendations[vendor.recommendation].toLowerCase()}`)}</span>
                    </Tag>
                </div>
            ),
        },
        ...insertIf(AuthzService.isRightGrantedForLoggedInUser(RightEnum.EXPENSE__TEAM__READ), {
            title: t("label:expenses"),
            key: "expenses",
            width: 100,
            align: "right",
            className: "pr-10",
            render: (vendor: VendorWithExpenseData) => {
                const vendorExpenseData = expenseData.find((data) => data.id === vendor.id)
                if (!vendorExpenseData) return <LoadingData />
                return <PriceLabel value={vendorExpenseData.value || 0} currency={globalCurrency} interval={PriceIntervalEnum.ONE_TIME} />
            },
        }),
    ]

    return (
        <div>
            <FilterSection table={TablesEnum.VENDORS} />

            {pageLoading ? (
                <Loading />
            ) : vendorsTable.error ? (
                <ErrorTable onTableReload={() => dispatch(refetchTable(TablesEnum.VENDORS))} isLoading={vendorsTable.isFetching} />
            ) : vendorsTable.data.totalDocs === 0 && !vendorsTable.isFetching ? (
                !vendorsTable.data.search ? (
                    <>
                        {AuthzService.isDataUploaderGrantedForLoggedInUser() && !isTableFilterApplied(vendorsTable.data.filter) && <DataUploadAlert />}

                        <Empty
                            className="animation-appear"
                            image={`./icons/empty_table.svg`}
                            imageStyle={{
                                height: 150,
                                marginRight: "auto",
                                marginLeft: "auto",
                                marginTop: "15%",
                                marginBottom: "40px",
                                display: "inline-block",
                            }}
                            description={
                                <span className="text-lg font-bold mt-2 mb-0">
                                    {!isTableFilterApplied(vendorsTable.data.filter) ? t("info:empty_state.vendors.title") : t("info:no_filter_data_found.title.vendor")}
                                </span>
                            }
                        >
                            <div className="flex flex-col justify-between items-center">
                                <p>{!isTableFilterApplied(vendorsTable.data.filter) ? t("info:empty_state.vendors.message") : t("info:no_filter_data_found.message")}</p>
                                {VendorService.shouldShowVendorButton(isVendorCreationRestricted) && (
                                    <Button className="mt-14" type="primary" onClick={() => onAddVendor(true)}>
                                        <PlusIcon />
                                        <span>{t("action:vendor:create")}</span>
                                    </Button>
                                )}
                            </div>
                        </Empty>
                    </>
                ) : (
                    <div className="animation-appear">
                        <NoSearchDataFound />
                    </div>
                )
            ) : (
                <div>
                    <div className="overflow-auto p-2 animation-appear">
                        <Table<Vendor>
                            data-testid="vendorTable"
                            rowKey={(record) => record.id}
                            columns={columns}
                            pagination={{
                                position: ["bottomRight"],
                                showSizeChanger: true,
                                current: vendorsTable.data.search ? 1 : vendorsTable.data.page,
                                pageSize: vendorsTable.data.limit,
                                hideOnSinglePage: false,
                                pageSizeOptions: ["5", "10", "20", "50", "100"],
                            }}
                            onChange={(pagination, filters, sorter, extra) => {
                                const onChange = updateTableOnChange(TablesEnum.VENDORS, vendorsTable.data, false)
                                onChange(pagination, filters, sorter)

                                const creditorIds = extra.currentDataSource.map((vendor: Vendor) => vendor.id)
                                setCreditorIds(creditorIds)
                            }}
                            dataSource={vendorsTable.data.docs}
                            loading={{
                                spinning: vendorsTable.isFetching,
                                indicator: <LoadingOutlined style={{ fontSize: 30, color: "black" }} spin />,
                            }}
                            onRow={(vendor: Vendor) => ({
                                onClick: () => {
                                    dispatch(setVendor(vendor))
                                    history.push(`/vendors/${vendor.id}`)
                                },
                            })}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}

export default VendorTable
