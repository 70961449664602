import { Expense, Tax } from "@finway-group/shared/lib/models"
import { Button, Col, Row } from "antd"
import React from "react"
import { Plus as PlusIcon, Trash2 as TrashIcon } from "react-feather"

import { ExpenseData } from "Components/modals/expenseCreateForm.context"
import ExpenseInitializer from "Features/pages/expenses/expenseInitializer"

type AddDefaultValueType = {
    name: string
    taxRate: { _id: string }
    netPrice: number
    grossPrice: number
    taxPrice: number
}
export interface FormItemSideButtonsInterface {
    elem: {
        fieldsLength: number
        index: number
        onRemoveClick: (index: number | Array<number>) => void
        setShouldRecalculateAmounts: (v: boolean) => void
        updateExpense: (data: ExpenseData) => void
        expense: Expense
        isHospitality: boolean
        isTipRow: boolean
        taxValues: Array<Tax>
        onAddClick: (defaultValue?: AddDefaultValueType, insertIndex?: number) => void
    }
    span?: number
    md?: number | object
    sm?: number | object
}

function FormItemSideButtons({ elem, ...columnProps }: FormItemSideButtonsInterface) {
    const { fieldsLength, index, onRemoveClick, setShouldRecalculateAmounts, updateExpense, expense, isHospitality, isTipRow, taxValues, onAddClick } = elem

    const handleRemoveClick = () => {
        onRemoveClick(index)
        setShouldRecalculateAmounts(true)
        updateExpense({ splits: expense.splits.filter((_split, i) => i !== index) })
    }

    const handleAddClick = () => {
        const insertIndex = index + 1
        const newItem = ExpenseInitializer.initializeNewItemSplit(taxValues)
        onAddClick(newItem, insertIndex)
        const items = [...expense.splits]
        items.splice(insertIndex, 0, newItem)
        updateExpense({ splits: items })
    }

    return (
        <Col {...columnProps}>
            {fieldsLength > 0 && (
                <Row>
                    <Col>
                        <Button onClick={handleRemoveClick} disabled={isHospitality && expense.splits.length <= 1}>
                            <TrashIcon />
                        </Button>
                    </Col>
                </Row>
            )}
            {!isTipRow && (
                <>
                    <Row>
                        <div role="none" style={{ height: "4px" }} /> {/** Just a visual spacer */}
                    </Row>
                    <Row>
                        <Col>
                            <Button onClick={handleAddClick}>
                                <PlusIcon />
                            </Button>
                        </Col>
                    </Row>
                </>
            )}
        </Col>
    )
}

export default React.memo(FormItemSideButtons)
