import React, { useEffect } from "react"
import { withRouter } from "react-router-dom"

interface ScrollToTopInterface {}
const ScrollToTop: React.FC<ScrollToTopInterface> = ({ location }: { location: string }) => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [location])

    return null
}

export default withRouter(ScrollToTop)
