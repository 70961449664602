import { Reducer } from "redux"

import {
    SetCurrentPageAction,
    SetFilterStringAction,
    SetItemsPerPageAction,
    SetSearchValueAction,
    SetSearchValueDashboardAction,
    SetSortingAction,
    TableConfigActionTypes,
    TableConfigActions,
} from "../actions/tableConfig/tableConfigTypes"

export enum TablesEnum {
    INBOX_INVOICE = "inboxInvoiceTable",
    INBOX_INVOICE_MODAL_INVOICES = "inboxInvoiceModalInvoicesTable",
    INBOX_INVOICE_MODAL_EXPENSES = "inboxInvoiceModalExpensesTable",
    TODO_PURCHASE_APPROVAL = "todoPurchaseApprovalTable",
    TODO_INVOICE_APPROVAL = "todoInvoiceApprovalTable",
    TODO_INVOICE_AND_PURCHASE = "todoInvoiceAndPurchaseApprovalTable",
    TODO_DOCS_NEEDED = "todoDocsNeededTable",
    TODO_APPROVAL_PENDING = "todoApprovalPendingTable",
    REVIEW_REQUESTS = "reviewTable",
    PAY_AND_EXPORT_TO_BE_PAID = "payAndExportToBePaidTable",
    PAY_AND_EXPORT_TO_BE_EXPORTED = "payAndExportToBeExportedTable",
    DONE_REQUESTS = "doneTable",
    IN_PROGRESS = "inProgressTable",
    SUBSCRIPTIONS = "subscriptionTable",
    TRANSACTIONS_INFLOW = "transactionInflowTable",
    TRANSACTIONS_OUTFLOW = "transactionOutflowTable",
    TRANSACTIONS_ALL = "transactionAllTable",
    POSSIBLE_MATCHES = "possibleMatchesTable",
    CARDS = "cardTable",
    COST_CENTERS = "costcenterTable",
    EMPLOYEES = "employeeTable",
    EMPLOYEE_VENDORS = "employeeVendorTable",
    VENDORS = "vendorTable",
    WORKFLOWS = "workflowTable",
    CARD_TRANSACTIONS = "cardTransactionsTable",
    TRANSACTION_CATEGORIES_INFLOW = "transactionCategoryInflowTable",
    TRANSACTION_CATEGORIES_OUTFLOW = "transactionCategoryOutflowTable",
    ARCHIVE = "archiveTable",
    EXPENSE_TRANSACTIONS = "expenseTransactionsTable",
    TRANSACTIONS_ARCHIVE = "transactionArchiveTable",
    ALL_REQUESTS = "allRequestsTable",
    TRIP_FOLDER_DRAFT = "draftTripFolderTable",
    ATTACH_EXPENSE = "attachExpenseTable",
    EXPORT_HISTORY = "exportHistoryTable",
}

export interface TableConfigData {
    currentPage: number
    itemsPerPage: number
    sorting: any
    searchValue: string
    filterString: string
}

export interface TableConfigState {
    inboxInvoiceTable: TableConfigData
    todoPurchaseApprovalTable: TableConfigData
    todoInvoiceApprovalTable: TableConfigData
    todoDocsNeededTable: TableConfigData
    todoApprovalPendingTable: TableConfigData
    todoInvoiceAndPurchaseApprovalTable: TableConfigData
    inboxInvoiceModalInvoicesTable: TableConfigData
    inboxInvoiceModalExpensesTable: TableConfigData
    todoTable: TableConfigData
    reviewTable: TableConfigData
    payAndExportToBePaidTable: TableConfigData
    payAndExportToBeExportedTable: TableConfigData
    doneTable: TableConfigData
    inProgressTable: TableConfigData
    subscriptionTable: TableConfigData
    transactionInflowTable: TableConfigData
    transactionOutflowTable: TableConfigData
    possibleMatchesTable: TableConfigData
    cardTable: TableConfigData
    costcenterTable: TableConfigData
    employeeTable: TableConfigData
    employeeVendorTable: TableConfigData
    vendorTable: TableConfigData
    workflowTable: TableConfigData
    cardTransactionsTable: TableConfigData
    transactionCategoryInflowTable: TableConfigData
    transactionCategoryOutflowTable: TableConfigData
    archiveTable: TableConfigData
    transactionArchiveTable: TableConfigData
    allRequestsTable: TableConfigData
    expenseTransactionsTable: TableConfigData
    attachExpenseTable: TableConfigData
    draftTripFolderTable: TableConfigData
    // separate because it's shared across tables
    dashboardSerachValue: string
    exportHistoryTable: TableConfigData
    transactionAllTable: TableConfigData
}

export const initialTableConfigData = {
    currentPage: 1,
    itemsPerPage: 20,
    sorting: {},
    searchValue: "",
    filterString: "",
}

export const initialLowLimitTableConfigData = {
    ...initialTableConfigData,
    itemsPerPage: 5,
}

export const initialTableConfigState = {
    inboxInvoiceTable: initialTableConfigData,
    todoPurchaseApprovalTable: initialLowLimitTableConfigData,
    todoInvoiceApprovalTable: initialLowLimitTableConfigData,
    todoInvoiceAndPurchaseApprovalTable: initialLowLimitTableConfigData,
    todoDocsNeededTable: initialLowLimitTableConfigData,
    todoApprovalPendingTable: initialLowLimitTableConfigData,
    inboxInvoiceModalInvoicesTable: initialTableConfigData,
    inboxInvoiceModalExpensesTable: initialTableConfigData,
    todoTable: initialTableConfigData,
    reviewTable: initialTableConfigData,
    payAndExportToBePaidTable: initialTableConfigData,
    payAndExportToBeExportedTable: initialTableConfigData,
    doneTable: initialTableConfigData,
    inProgressTable: initialTableConfigData,
    allRequestsTable: initialTableConfigData,
    subscriptionTable: initialTableConfigData,
    transactionInflowTable: initialTableConfigData,
    transactionOutflowTable: initialTableConfigData,
    possibleMatchesTable: initialTableConfigData,
    cardTable: initialTableConfigData,
    costcenterTable: initialTableConfigData,
    employeeTable: initialTableConfigData,
    employeeVendorTable: initialTableConfigData,
    vendorTable: initialTableConfigData,
    workflowTable: initialTableConfigData,
    cardTransactionsTable: initialTableConfigData,
    transactionCategoryInflowTable: initialTableConfigData,
    transactionCategoryOutflowTable: initialTableConfigData,
    archiveTable: initialTableConfigData,
    expenseTransactionsTable: initialTableConfigData,
    transactionArchiveTable: initialTableConfigData,
    attachExpenseTable: initialTableConfigData,
    draftTripFolderTable: initialTableConfigData,
    exportHistoryTable: initialTableConfigData,
    transactionAllTable: initialTableConfigData,
    // separate because it's shared across tables
    dashboardSerachValue: "",
}

const setCurrentPage = (state: TableConfigState, action: SetCurrentPageAction): TableConfigState => ({
    ...state,
    [action.table]: {
        ...state[action.table],
        currentPage: action.currentPage,
    },
})

const setItemsPerPage = (state: TableConfigState, action: SetItemsPerPageAction): TableConfigState => ({
    ...state,
    [action.table]: {
        ...state[action.table],
        itemsPerPage: action.itemsPerPage,
    },
})

const setSorting = (state: TableConfigState, action: SetSortingAction): TableConfigState => ({
    ...state,
    [action.table]: {
        ...state[action.table],
        sorting: action.sorting,
    },
})

const setSearchValue = (state: TableConfigState, action: SetSearchValueAction): TableConfigState => ({
    ...state,
    [action.table]: {
        ...state[action.table],
        searchValue: action.searchValue,
    },
})

const setSearchValueDashboard = (state: TableConfigState, action: SetSearchValueDashboardAction): TableConfigState => ({
    ...state,
    dashboardSerachValue: action.searchValue,
})

const setFilterString = (state: TableConfigState, action: SetFilterStringAction): TableConfigState => ({
    ...state,
    [action.table]: {
        ...state[action.table],
        filterString: action.filterString,
    },
})

export const tableConfigReducer: Reducer<TableConfigState, TableConfigActions> = (state = initialTableConfigState, action) => {
    switch (action.type) {
        case TableConfigActionTypes.SET_CURRENT_PAGE:
            return setCurrentPage(state, action)
        case TableConfigActionTypes.SET_ITEMS_PER_PAGE:
            return setItemsPerPage(state, action)
        case TableConfigActionTypes.SET_SORTING:
            return setSorting(state, action)
        case TableConfigActionTypes.SET_SEARCH_VALUE:
            return setSearchValue(state, action)
        case TableConfigActionTypes.SET_SEARCH_VALUE_DASHBOARD:
            return setSearchValueDashboard(state, action)
        case TableConfigActionTypes.SET_FILTER_STRING:
            return setFilterString(state, action)
        default:
            return state
    }
}
