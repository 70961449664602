import { Skeleton } from "antd"
import React from "react"

const ActivitySkeleton = () => (
    <div className="flex-1 overflow-hidden">
        <div className="flex">
            <Skeleton.Avatar className="ml-10 pt-10" active size={"large"} shape={"circle"} />

            <div className="w-full ml-5">
                <Skeleton active title={{ width: "100px" }} paragraph={false} />
                <Skeleton className="-mt-5" active title={{ width: "280px" }} paragraph={false} />
            </div>
        </div>
    </div>
)

export default React.memo(ActivitySkeleton)
