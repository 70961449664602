import { FeatureFlagsInterface } from "@finway-group/shared/lib/models"
import { Reducer } from "redux"

import { FeatureFlagsActionTypes, FeatureFlagsActions } from "../actions/featureFlags/featureFlagsTypes"

export interface FeatureFlagsState {
    item?: FeatureFlagsInterface
}

const initialState = {
    item: undefined,
}

const setFeatureFlags = (state: FeatureFlagsState, action: FeatureFlagsActions): FeatureFlagsState => ({
    ...state,
    item: action.featureFlags,
})

export const featureFlagsReducer: Reducer<FeatureFlagsState, FeatureFlagsActions> = (state = initialState, action) => {
    switch (action.type) {
        case FeatureFlagsActionTypes.SET_FEATURE_FLAGS:
            return setFeatureFlags(state, action)
        default:
            return state
    }
}
