import React, { createContext, useContext, useState } from "react"

interface SettingsContextInterface {
    listVisible: boolean
    setListVisible: (isVisible: boolean) => void
}

export const SettingsContext = createContext<SettingsContextInterface>({} as SettingsContextInterface)

export const useSettingsContext = () => useContext(SettingsContext)

interface SettingsContextProviderProps {
    children: React.ReactNode
}

export const SettingsContextProvider = ({ children }: SettingsContextProviderProps) => {
    const [listVisible, setListVisible] = useState(false)

    return <SettingsContext.Provider value={{ listVisible, setListVisible }}>{children}</SettingsContext.Provider>
}
