import { MAX_ROLE_NAME_LENGTH, MIN_INPUT_LENGTH, textMultipleWhiteSpace } from "@finway-group/shared/lib/utils/validators"
import { Rule } from "antd/lib/form"
import i18n from "i18next"

import { allowlistCharValidator } from "./utils.rules"

const RoleSettingsRules = (): { [key: string]: Array<Rule> } => ({
    template: [{ required: true, message: i18n.t("validation:required"), validateTrigger: "blur" }],
    name: [
        {
            required: true,
            validator: (_rule, value: string) => {
                // Required
                if (!value || value === "") return Promise.reject(new Error(i18n.t("validation:required")))

                if (value.length < MIN_INPUT_LENGTH) return Promise.reject(new Error(i18n.t("validation:string_too_short")))
                if (value.length > MAX_ROLE_NAME_LENGTH) return Promise.reject(new Error(i18n.t("validation:string_too_long")))
                if (value !== value.trim()) return Promise.reject(new Error(i18n.t("validation:string_white_space_start_or_end")))
                if (textMultipleWhiteSpace.test(value)) return Promise.reject(new Error(i18n.t("validation:string_white_space_duplicate")))

                return Promise.resolve()
            },
        },
        allowlistCharValidator,
    ],
})

export default RoleSettingsRules
