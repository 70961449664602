import { ArrowLeftOutlined, CloseOutlined, ExclamationCircleOutlined } from "@ant-design/icons"
import { SystemRightEnum } from "@finway-group/shared/lib/models"
import { Alert, Button, Col, Form, List, PageHeader, Row, Tooltip } from "antd"
import React, { useEffect } from "react"
import { Edit as EditIcon, Plus as PlusIcon } from "react-feather"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import { ConditionalWrapper } from "Components/conditionalWrapper"
import RoleForm from "Components/forms/roles/role.form"
import { mapRoleModelToFormModel } from "Components/forms/roles/role.form.mapper"
import RolesSettingsModal from "Components/modals/rolesSettings.modal"
import { useModal } from "Shared/context/modal.context"
import { useIsCustomRolesEnabled } from "Shared/hooks/featureFlags.hooks"
import { useRole, useRoles } from "Shared/hooks/role.hooks"
import { useLoggedInEmployeeProfile } from "Shared/hooks/user.hooks"
import { AuthzService } from "Shared/services"
import DialogService from "Shared/services/dialog.service"
import { deleteRole, selectRole } from "Shared/store/actions/role/roleActions"
import { isSelfAdministrationOfRoleAllowed } from "Shared/utils/employee.utils"
import { getUserRoleName } from "Shared/utils/getter.utils"
import { insertIf } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

import { useSettingsContext } from "../settings.context"

const RoleSettings = () => {
    const { t } = useTranslation()
    const [formInstance] = Form.useForm()
    const { showModal } = useModal()
    const dispatch = useDispatch()

    const { setListVisible } = useSettingsContext()
    const loggedInUser = useLoggedInEmployeeProfile()
    const roles = useRoles({ excludeDeleted: true })
    const selectedRole = useRole()
    const isCustomRolesEnabled = useIsCustomRolesEnabled()
    const [showRoleList, setShowRoleList] = useStateIfMounted(true)

    useEffect(() => {
        if (selectedRole) {
            formInstance.resetFields()
            mapRoleModelToFormModel(selectedRole, formInstance)
        }
    }, [selectedRole, formInstance])

    const onDeleteRole = async () => {
        if (!selectedRole || !(await DialogService.confirmDeleteRole())) return
        dispatch(deleteRole(selectedRole._id))
    }

    return (
        <div className="w-full">
            <Row className="m-0 p-0 flex justify-between">
                <Col span={24} lg={6} className={`${showRoleList ? "block" : "hidden md:block"}  justify-start`} style={{ border: "solid 1px #EEF4F8" }}>
                    <div>
                        <PageHeader
                            ghost={false}
                            backIcon={<ArrowLeftOutlined className="md:hidden" />}
                            onBack={() => setListVisible(true)}
                            title={<h2 className="mb-0">{t("label:roles.title")}</h2>}
                            extra={[
                                <React.Fragment key="action_buttons">
                                    {AuthzService.isSystemRightGrantedForLoggedInUser(SystemRightEnum.SYS__ROLE__ALL__CREATE) && isCustomRolesEnabled && (
                                        <Button
                                            className="mr-6 mt-2"
                                            size="small"
                                            type="primary"
                                            onClick={() => showModal(RolesSettingsModal, true, { isShowing: true, isNew: true })}
                                        >
                                            <PlusIcon />
                                            <span>{t("action:create")}</span>
                                        </Button>
                                    )}
                                </React.Fragment>,
                            ]}
                        />
                    </div>

                    <div>
                        <List
                            dataSource={roles}
                            renderItem={(role) => (
                                <List.Item
                                    key={role._id}
                                    className={`settings-menu-item ${role._id === selectedRole?._id ? "active" : ""}`}
                                    onClick={() => {
                                        dispatch(selectRole(role))
                                        setShowRoleList(false)
                                    }}
                                >
                                    <span className="ml-10 text-base leading-relaxed truncate">{getUserRoleName(role)}</span>
                                </List.Item>
                            )}
                        />
                    </div>
                </Col>
                {selectedRole && (
                    <Col span={24} lg={18} className={`p-10 ${showRoleList ? "hidden md:block" : "block"}`}>
                        <div>
                            <PageHeader
                                ghost={false}
                                backIcon={<ArrowLeftOutlined className="md:hidden" />}
                                onBack={() => setShowRoleList(true)}
                                title={
                                    <div className="flex items-center space-x-10">
                                        <h3 className="mb-0">{getUserRoleName(selectedRole)} </h3>
                                        {selectedRole.isReadOnly && (
                                            <Tooltip title={t("tooltips:default_role_cannot_be_deleted")}>
                                                <ExclamationCircleOutlined />
                                            </Tooltip>
                                        )}
                                    </div>
                                }
                                extra={[
                                    ...insertIf(
                                        !selectedRole.isReadOnly,
                                        <React.Fragment key="action_buttons">
                                            <div key="role_btn-wrapper" className="flex items-center space-x-10">
                                                {AuthzService.isSystemRightGrantedForLoggedInUser(SystemRightEnum.SYS__ROLE__ALL__UPDATE) && (
                                                    <ConditionalWrapper
                                                        condition={!isSelfAdministrationOfRoleAllowed(loggedInUser, selectedRole._id)}
                                                        wrapper={(children: any) => (
                                                            <Tooltip title={t("error:self_administration_edit_disallowed")} placement="bottom">
                                                                {children}
                                                            </Tooltip>
                                                        )}
                                                    >
                                                        <Button
                                                            disabled={selectedRole.isReadOnly || !isSelfAdministrationOfRoleAllowed(loggedInUser, selectedRole._id)}
                                                            onClick={() => showModal(RolesSettingsModal, true, { isShowing: true, role: selectedRole, isNew: false })}
                                                        >
                                                            <EditIcon />
                                                            <span>{t("action:edit")}</span>
                                                        </Button>
                                                    </ConditionalWrapper>
                                                )}
                                                {AuthzService.isSystemRightGrantedForLoggedInUser(SystemRightEnum.SYS__ROLE__ALL__DELETE) && (
                                                    <ConditionalWrapper
                                                        condition={!isSelfAdministrationOfRoleAllowed(loggedInUser, selectedRole._id)}
                                                        wrapper={(children: any) => (
                                                            <Tooltip title={t("error:self_administration_delete_disallowed")} placement="bottom">
                                                                {children}
                                                            </Tooltip>
                                                        )}
                                                    >
                                                        <Button
                                                            onClick={() => onDeleteRole()}
                                                            disabled={selectedRole.isReadOnly || !isSelfAdministrationOfRoleAllowed(loggedInUser, selectedRole._id)}
                                                        >
                                                            <CloseOutlined />
                                                            <span>{t("action:delete")}</span>
                                                        </Button>
                                                    </ConditionalWrapper>
                                                )}
                                            </div>
                                        </React.Fragment>,
                                    ),
                                ]}
                            />
                        </div>
                        {selectedRole.isReadOnly ? (
                            <Alert
                                type="info"
                                showIcon
                                className="mb-20"
                                message={<span className="font-bold">{getUserRoleName(selectedRole)}</span>}
                                description={t(`info:roles.${selectedRole.name}`)}
                            />
                        ) : (
                            <RoleForm role={selectedRole} formInstance={formInstance} />
                        )}
                    </Col>
                )}
            </Row>
        </div>
    )
}

export default RoleSettings
