import { Button } from "antd"
import { ButtonType } from "antd/lib/button"
import React from "react"
import { Upload as UploadIcon } from "react-feather"
import { useDispatch } from "react-redux"

import { SpreadsheetDataUploadModal } from "Components/modals/spreadsheetDataUpload/spreadsheetDataUpload.modal"
import { useModal } from "Shared/context/modal.context"
import { openDataUploadModal } from "Shared/store/actions/import/importActions"

interface DataUploaderOpenModalButtonInterface {
    importResultId?: any
    text?: string
    buttonType?: ButtonType
}

export const DataUploaderOpenModalButton: React.FC<DataUploaderOpenModalButtonInterface> = ({ importResultId, text = "", buttonType = "default" }) => {
    const { showModal } = useModal()
    const dispatch = useDispatch()

    const handleClick = () => {
        showModal(SpreadsheetDataUploadModal, true, { isShowing: true, importResultId })
        dispatch(openDataUploadModal())
    }

    return (
        <Button className="h-40" type={buttonType} onClick={() => handleClick()}>
            <UploadIcon />
            <p className="inline-block">{text}</p>
        </Button>
    )
}
