import { CurrencyEnum, PriceIntervalEnum } from "@finway-group/shared/lib/models"
import React from "react"
import { useTranslation } from "react-i18next"

import { formatCurrencyNumber } from "Shared/utils/helper.utils"

interface PriceLabelPropInterface {
    value: number
    currency: CurrencyEnum
    interval?: number
    isMonthly?: boolean
    showFullNumber?: boolean
    addPlusSign?: boolean
}

const PriceLabel: React.FC<PriceLabelPropInterface> = ({ interval, currency, showFullNumber, value, addPlusSign }) => {
    const { t } = useTranslation()

    return (
        <>
            {`${addPlusSign && value > 0 ? "+" : ""}${formatCurrencyNumber(value, currency, showFullNumber)}`}
            {interval === PriceIntervalEnum.MONTHLY && ` / ${t("append:per_month")}`}
            {interval === PriceIntervalEnum.QUARTERLY && ` / ${t("append:per_quarter")}`}
            {interval === PriceIntervalEnum.YEARLY && ` / ${t("append:per_year")}`}
            {!interval && ""}
        </>
    )
}

export default PriceLabel
