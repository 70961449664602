import { CreditorInterface, ExpenseStatusEnum, ExportFormatEnum, RightEnum } from "@finway-group/shared/lib/models"
import { Checkbox, Form } from "antd"
import { FormInstance } from "antd/lib/form"
import { Store } from "antd/lib/form/interface"
import moment from "moment"
import React, { useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"

import { FormInputEnum, FormSelectOption } from "Components/form/form.types"
import FormMinMax from "Components/form/formMinMax"
import FormMinMaxDate from "Components/form/formMinMaxDate"
import FormSelect from "Components/form/formSelect"
import FormSelectTags from "Components/form/formSelectTags"
import { expenseStati } from "Shared/config/consts"
import { useArchiveInterval, useCompany } from "Shared/hooks/company.hooks"
import { useCostCenters2 } from "Shared/hooks/costCenter2.hooks"
import { useCostCenters } from "Shared/hooks/costCenter.hooks"
import { useEmployees } from "Shared/hooks/employee.hooks"
import { useExpenseAccounts } from "Shared/hooks/expenseAccount.hooks"
import { useIsTravelEnabled } from "Shared/hooks/featureFlags.hooks"
import { getTableFilterQueryStringStore } from "Shared/hooks/table.hooks"
import { useCreditors } from "Shared/hooks/vendor.hooks"
import { AuthzService } from "Shared/services"
import { TablesEnum } from "Shared/store/reducers/tableConfigReducer"
import { getFilteredExpenseStatusArrayForExport } from "Shared/utils/expense.utils"
import {
    convertQueryFormatIntoFilterObject,
    getDynamicCostCenter2List,
    getDynamicCostCenterList,
    getDynamicCreditorList,
    getDynamicExpenseAccountList,
    getExpenseKindList,
} from "Shared/utils/filter.utils"
import { decodeFilterString } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

import { ExpenseFilter } from "./expenseFilter.form"
import { generateRules } from "./rules/export.rules"

const adjustFilterObject = (filterObject: ExpenseFilter): any => ({
    ...filterObject,
    minDate: filterObject.minDate ? moment(filterObject.minDate) : "",
    maxDate: filterObject.maxDate ? moment(filterObject.maxDate) : "",
    minInvoiceDate: filterObject.minInvoiceDate ? moment(filterObject.minInvoiceDate) : "",
    maxInvoiceDate: filterObject.maxInvoiceDate ? moment(filterObject.maxInvoiceDate) : "",
})

interface ExportFormInterface {
    subscriptions?: boolean
    transactions?: boolean
    formInstance: FormInstance
    onSubmit: (values: Store) => void
}

const ExportForm: React.FC<ExportFormInterface> = ({ formInstance, subscriptions = false, transactions = false, onSubmit }) => {
    const { t } = useTranslation()
    const dashboardFilterQueryString = getTableFilterQueryStringStore(TablesEnum.DONE_REQUESTS)
    const rules = generateRules(formInstance)
    const archiveAfterXDays = useArchiveInterval()
    const hasExportRight = AuthzService.canLoggedInUserExport()
    const hasApproveRight = AuthzService.isLoggedInUserPotentialApprover()
    const [exportFormat, setExportFormat] = useStateIfMounted(ExportFormatEnum.ALL)
    const isTravelEnabled = useIsTravelEnabled()
    const costCenters = useCostCenters(true)
    const employees = useEmployees({ excludeDeleted: true })
    const costCenters2 = useCostCenters2()
    const expenseAccounts = useExpenseAccounts()

    const expenseKindList = getExpenseKindList(isTravelEnabled)
    const { showCostCentersIds } = useCompany()

    const creditors = useCreditors(AuthzService.isRightGrantedForLoggedInUser(RightEnum.EMPLOYEE__ALL__READ), true, true)

    const includeArchiveFormItem = (
        <Form.Item noStyle name="includeArchive" valuePropName="checked">
            <Checkbox className="ml-10" onClick={(domEvent) => domEvent.stopPropagation()}>
                {t("input:expense_filter.include_archived")}
            </Checkbox>
        </Form.Item>
    )

    const formatOptions: Array<FormSelectOption> = []

    // Selected options pushed according to a predefined order
    if (hasExportRight || hasApproveRight) {
        formatOptions.push({
            param: t("input:export.export_format.all_formats"),
            value: ExportFormatEnum.ALL,
        })
    }

    if (hasExportRight) {
        formatOptions.push(
            {
                param: t("input:export.export_format.csv"),
                value: ExportFormatEnum.CSV,
            },
            {
                param: t("input:export.export_format.datev_invoice_csv"),
                value: ExportFormatEnum.DATEV_INVOICE_CSV,
            },
            {
                param: t("input:export.export_format.datev"),
                value: ExportFormatEnum.DATEV,
            },
        )
    }

    formatOptions.push({
        param: t("input:export.export_format.invoices"),
        value: ExportFormatEnum.INVOICES,
    })

    const statusOptions: Array<FormSelectOption> = [
        {
            param: t("input:filter_form.not_set"),
            value: "",
        },
        ...getFilteredExpenseStatusArrayForExport(exportFormat).map((status: ExpenseStatusEnum) => ({
            param: t(expenseStati[status]),
            value: status.toString(),
        })),
    ]

    // Try to copy the content from expense filter, so the values are prefilled
    const [filterData, onFilterDataChange] = useStateIfMounted(
        adjustFilterObject(
            convertQueryFormatIntoFilterObject(
                decodeFilterString(dashboardFilterQueryString ?? `&datePurchased[gte]=${moment().subtract(1, "M").toISOString()}&datePurchased[lte]=${moment().toISOString()}`),
            ),
        ),
    )

    const anchorRef = useRef(null)

    // Dummy effect re-setting the filterData value. Fixes the wrongly displayed dropdowns
    useEffect(() => {
        onFilterDataChange(
            adjustFilterObject(
                convertQueryFormatIntoFilterObject(
                    decodeFilterString(dashboardFilterQueryString ?? `&datePurchased[gte]=${moment().subtract(1, "M").toISOString()}&datePurchased[lte]=${moment().toISOString()}`),
                ),
            ),
        )
    }, [])

    // Applying filter data values to the form
    useEffect(() => {
        formInstance.setFieldsValue({ ...filterData })
    }, [filterData])

    // if min date is included in the date range of auto archived expenses then includeArchive is set to true
    const handleValuesChange = (changedFields: any, _allFields: any) => {
        if (changedFields.minDate && moment(changedFields.minDate).isSameOrBefore(moment().subtract(archiveAfterXDays, "d"))) formInstance.setFieldsValue({ includeArchive: true })
    }

    return (
        <Form className="form--filter" id="exportForm" form={formInstance} onValuesChange={handleValuesChange} onFinish={onSubmit}>
            <div ref={anchorRef} />
            <div className="pl-18">
                <FormMinMaxDate
                    isCollapse={false}
                    keyCode="0"
                    header={t("input:expense_filter.purchase_date_range")}
                    min={{
                        name: "minDate",
                        label: t("input:expense_filter.min_date"),
                        placeholder: t("placeholder:expense_filter.min_date"),
                        initialValue: filterData.minDate,
                        rules: rules.minDate,
                        onChange: () => formInstance.validateFields(["minDate", "maxDate"]),
                    }}
                    max={{
                        name: "maxDate",
                        label: t("input:expense_filter.max_date"),
                        placeholder: t("placeholder:expense_filter.max_date"),
                        initialValue: filterData.maxDate,
                        rules: rules.maxDate,
                        onChange: () => formInstance.validateFields(["minDate", "maxDate"]),
                    }}
                    extra={includeArchiveFormItem}
                />
            </div>
            <FormMinMax
                header={t("input:filter_form.amount")}
                inputType={FormInputEnum.NUMBER}
                min={{
                    name: "minAmount",
                    label: t("input:filter_form.min_amount"),
                    placeholder: t("placeholder:expense_filter.min_amount"),
                    onChange: () => formInstance.validateFields(["minAmount", "maxAmount"]),
                    rules: rules.minAmount,
                }}
                max={{
                    name: "maxAmount",
                    label: t("input:filter_form.max_amount"),
                    placeholder: t("placeholder:expense_filter.max_amount"),
                    onChange: () => formInstance.validateFields(["minAmount", "maxAmount"]),
                    rules: rules.maxAmount,
                }}
            />

            <FormSelect
                name="format"
                header={t("input:export.format")}
                initialValue={hasExportRight || hasApproveRight ? ExportFormatEnum.ALL : ExportFormatEnum.INVOICES}
                showSearch
                options={formatOptions}
                isCollapse
                fallback={false}
                onChange={(value: ExportFormatEnum) => setExportFormat(value)}
            />

            {!subscriptions && !transactions && (
                <FormSelect
                    name="status"
                    header={t("label:status")}
                    initialValue=""
                    rules={rules.status}
                    showSearch
                    options={statusOptions}
                    isCollapse
                    fallback
                    dependencies={["format"]}
                />
            )}
            <FormMinMaxDate
                header={t("input:expense_filter.invoice_date_range")}
                min={{
                    name: "minInvoiceDate",
                    label: t("input:expense_filter.min_date"),
                    placeholder: t("placeholder:expense_filter.min_date"),
                    initialValue: filterData.minInvoiceDate,
                    rules: rules.minInvoiceDate,
                    onChange: () => formInstance.validateFields(["minInvoiceDate", "maxInvoiceDate"]),
                }}
                max={{
                    name: "maxInvoiceDate",
                    label: t("input:expense_filter.max_date"),
                    placeholder: t("placeholder:expense_filter.max_date"),
                    initialValue: filterData.maxInvoiceDate,
                    rules: rules.maxInvoiceDate,
                    onChange: () => formInstance.validateFields(["minInvoiceDate", "maxInvoiceDate"]),
                }}
            />
            <FormSelect name="kind" header={t("input:expense_filter.kind")} initialValue={filterData.kind} options={expenseKindList} isCollapse fallback />
            <FormSelectTags header={t("input:expense_filter.expenseTags")} placeholder={t("input:filter_form.not_set")} initialValue={filterData.expenseTags} name="expenseTags" />
            <FormSelect
                name="costCenter"
                header={t("input:expense_filter.cost_center")}
                initialValue={filterData.costCenter}
                options={getDynamicCostCenterList(costCenters, showCostCentersIds)}
                isCollapse
                showSearch
                fallback
            />
            {costCenters2.length > 0 && (
                <FormSelect
                    name="costCenter2"
                    header={t("input:expense_filter.cost_center_2")}
                    initialValue={filterData.costCenter2}
                    options={getDynamicCostCenter2List(costCenters2)}
                    isCollapse
                    showSearch
                    fallback
                />
            )}

            <FormSelect
                name="expenseAccount"
                header={t("input:expense_filter.expense_account")}
                initialValue={filterData.expenseAccount}
                options={getDynamicExpenseAccountList(expenseAccounts)}
                isCollapse
                showSearch
                fallback
            />
            {AuthzService.isLoggedInUserPotentialApprover() && (
                <FormSelect
                    name="requestedBy"
                    header={t("input:expense_filter.requester")}
                    initialValue={filterData.requestedBy}
                    options={employees}
                    displayImages
                    isCollapse
                    showSearch
                    fallback
                />
            )}
            <FormSelect
                name="approvalNeededBy"
                header={t("input:expense_filter.approver")}
                initialValue={filterData.approvalNeededBy}
                options={employees.filter((e) => AuthzService.isPotentialApprover(e.activeCompanyProfile?.roleId))}
                displayImages
                isCollapse
                showSearch
                fallback
            />
            <FormSelect
                name="vendor"
                header={t("input:filter_form.vendor")}
                initialValue={filterData.vendor}
                options={getDynamicCreditorList(creditors as Array<CreditorInterface>)}
                showSearch
                isCollapse
                fallback
            />
        </Form>
    )
}

export default ExportForm
