import { BankOutlined as BankIcon, ExclamationCircleOutlined } from "@ant-design/icons"
import {
    ApprovalScopeEnum,
    ApprovalStatusEnum,
    BankConnection,
    CurrencyEnum,
    DocumentTypeEnum,
    Employee,
    Expense,
    ExpenseKindEnum,
    ExpenseStatusEnum,
    ExportFormatEnum,
    RightEnum,
    TripFolder,
} from "@finway-group/shared/lib/models"
import { ExpensePaymentOptionEnum } from "@finway-group/shared/lib/models/expense/expensePaymentOption.enum"
import {
    getApprovalProcess,
    getApprovalProcessScopeFromExpenseStatus,
    getApproverIdsOfApprovalProcess,
    getApproverIdsOfApprovalProcesses,
} from "@finway-group/shared/lib/utils/approvalProcess.utils"
import { Button, Divider, Dropdown, Menu, Tooltip } from "antd"
import React from "react"
import { Bell as BellIcon, X as CancelIcon, Check as CheckIcon, Settings as CogIcon, MoreHorizontal as MoreIcon, Upload as UploadIcon } from "react-feather"
import { Trans, useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"

import { ConditionalWrapper } from "Components/conditionalWrapper"
import ExportDropDownMenu from "Components/exportDropDownMenu"
import { HoverableInfo } from "Components/forms/expenseForm/reimbursementForms/auxilliary/HoverableInfo"
import ArrowThinUpIcon from "Components/icons/arrowThinUp"
import UploadFilesDragger from "Components/upload/UploadFilesDragger"
import { ExpenseExport } from "Features/export/expenseExport"
import { useExpenseDetailsContext } from "Shared/context/expenseDetails.context"
import { useModal } from "Shared/context/modal.context"
import { useArchiveInterval, useCompany } from "Shared/hooks/company.hooks"
import { useCostCenters } from "Shared/hooks/costCenter.hooks"
import { useInboxInvoices } from "Shared/hooks/expense.hooks"
import { useVendorById } from "Shared/hooks/vendor.hooks"
import { AuthzService, ExpenseService, FolderService } from "Shared/services"
import { approveVendorStatus, deleteVendor } from "Shared/store/actions/vendor/vendorActions"
import { RootState } from "Shared/store/rootState.interface"
import { canApproveFolderBasedOnSubExpensesProgression } from "Shared/utils/approvalProcess.utils"
import { breakpoints } from "Shared/utils/breakpoints.utils"
import { isResponsibleForCostCenterOrSharedWith } from "Shared/utils/costCenter.utils"
import {
    getCostCentersFromSplits,
    getExpensesToPayOrExportForTables,
    isExpenseArchived,
    isFolderExpense,
    isMileageOrPerDiem,
    isReviewButtonEnabled,
} from "Shared/utils/expense.utils"
import { getFolderActionStrings, getFolderProgressionInfo, isFolderMarkableAsPaid, isFolderPayable } from "Shared/utils/folder.utils"
import { useResizeWindowWidth } from "Shared/utils/hooks/useResizeWindowWidth"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

import ExpenseCostCenterBudgetUsageProgressBar from "./expenseCostCenterBudgetUsageProgressBar"
import { doFileUploadFunction } from "./expenseDetails.container.types"

const SEPAExportConfirmationModal = React.lazy(() => import("Components/modals/sepaExportConfirmation.modal"))

interface ExpenseActionButtonViewInterface {
    loggedInUser: Employee
    isSubscription: boolean
    isLoading: boolean
    isInvoiceUploading: boolean
    rejectRequest: () => void
    markRequestAsApproved: (expense: Expense, approvalScope: ApprovalScopeEnum) => void
    markRequestAsReviewed: (expense: Expense) => void
    markRequestAsPaid: (expense: Expense) => void
    payExpense: (isScheduledPayment: boolean) => void
    doFileUpload: doFileUploadFunction
    onInboxSelected: (event: any) => void
    isButtonDisabled?: boolean
    isExpenseUpdating?: boolean
    isOcrUploading: boolean
    sendEmailReminder: () => void
    canApprove?: boolean
    cancelRequest: () => void
}

const ExpenseActionButtonView: React.FC<ExpenseActionButtonViewInterface> = ({
    loggedInUser,
    isLoading,
    isInvoiceUploading,
    rejectRequest,
    markRequestAsApproved,
    markRequestAsReviewed,
    markRequestAsPaid,
    payExpense,
    doFileUpload,
    onInboxSelected,
    isExpenseUpdating,
    isOcrUploading,
    sendEmailReminder,
    canApprove,
    cancelRequest,
}) => {
    const { activeExpense, subExpenses } = useExpenseDetailsContext()

    const { t } = useTranslation()
    const hasDefaultBankConnection = useSelector(({ banks }: RootState) => banks.items)?.find((con: BankConnection) => con.isDefault)
    const isRefundExpense = activeExpense.totalNetPrice < 0 || activeExpense.totalGrossPrice < 0
    const windowWidth = useResizeWindowWidth()
    const isMyExpense = activeExpense.isCurrentUserExpense(loggedInUser.id)
    const company = useCompany()

    const invoiceApprovalProcess = getApprovalProcess(ApprovalScopeEnum.EXPENSE_INVOICE, activeExpense.approvalProcesses)
    const isApproverOfInvoiceApprovalProcess = getApproverIdsOfApprovalProcess(invoiceApprovalProcess).includes(loggedInUser.id)

    const isRequester = activeExpense.requestedBy._id === loggedInUser.id
    const inboxInvoiceData = useInboxInvoices()
    const dispatch = useDispatch()
    const { showModal } = useModal()
    const archiveAfterXDays = useArchiveInterval()
    const isArchived = isExpenseArchived(activeExpense, archiveAfterXDays)

    const { expenseEditable } = ExpenseService.calculateExpenseEditButtonView(activeExpense, loggedInUser, archiveAfterXDays, t)

    // Will only have value when the activeExpense has a vendor, not a userCreditor
    const vendor = useVendorById(activeExpense.vendor?._id)

    const [isUpdatingVendorStatus, setIsUpdatingVendorStatus] = useStateIfMounted(false)

    const costCenters = useCostCenters(true)
    const isAllowedToCreateExpenses = AuthzService.isLoggedInUserAllowedToCreateExpenses()
    const isAllowedToReview = AuthzService.isRightGrantedForLoggedInUser(RightEnum.EXPENSE__ALL__REVIEW)

    const costCentersFromSplits = getCostCentersFromSplits(activeExpense.splits)

    const costCentersAllowedToView = AuthzService.isRightGrantedForLoggedInUser(RightEnum.CC1__ALL__READ)
        ? costCentersFromSplits
        : costCentersFromSplits.filter((costCenter) => isResponsibleForCostCenterOrSharedWith(costCenter, loggedInUser.id, costCenters))

    const isExpenseFolderAndEmpty = isFolderExpense(activeExpense) && subExpenses.length === 0
    const isCreditorNumberMissing = isFolderExpense(activeExpense)
        ? subExpenses.some(ExpenseService.isCreditorNumberReferenceMissing)
        : ExpenseService.isCreditorNumberReferenceMissing(activeExpense)

    const isNonSepaXmlExportEnabled =
        company?.isNonSepaXmlExportEnabled && (activeExpense.currency !== CurrencyEnum.EUR || subExpenses.every((expense) => expense.currency !== CurrencyEnum.EUR))

    const approveOrRejectVendor = async (isVendorApproved: boolean) => {
        setIsUpdatingVendorStatus(true)
        try {
            if (isVendorApproved) {
                await dispatch(approveVendorStatus(activeExpense.vendor._id))
            } else {
                await dispatch(deleteVendor(activeExpense.vendor._id))
            }
        } finally {
            setIsUpdatingVendorStatus(false)
        }
    }

    const generateFolderDescription = ({ tooltip, description }: { tooltip: string; description: string }, showTooltip: boolean = true) => (
        <>
            {description} {showTooltip && <HoverableInfo iconOverride={<ExclamationCircleOutlined />} message={tooltip} />}
        </>
    )

    const renderApprovalConfirmationView = () => {
        const approvalScope = getApprovalProcessScopeFromExpenseStatus(activeExpense.status, true)
        if (canApprove) {
            let title = t("label:approval_confirmation")
            let description = <>{t("label:approval_confirmation_desc")}</>

            // We should block actions + show tooltip when the user is a pending approver, but dont have the right to approve.
            // This may happen if the right to approve is stripped when the user is currently an approver of a request.
            let disabled = isOcrUploading || isExpenseUpdating || !canApprove

            let tooltip: string | undefined

            if (!canApprove) {
                tooltip = t("tooltips:approval_process.no_right")
            }

            // When it's a folder, it should check the sub-expenses to see if the folder is approvable or not.
            if (!tooltip && isFolderExpense(activeExpense)) {
                const folderStrings = getFolderActionStrings(activeExpense, t)
                title = folderStrings.title
                description = generateFolderDescription(folderStrings)

                // Checks the sub-expense status compared to the folder to see if any sub-expense is behind
                const subExpensesOnSameStatusOrLess = subExpenses.filter((subExpense) => subExpense.status <= activeExpense.status)

                // Checks the approval process steps / approval to see if any sub-expense is behind
                const approvalProcessValid = canApproveFolderBasedOnSubExpensesProgression(activeExpense, subExpensesOnSameStatusOrLess, ApprovalScopeEnum.EXPENSE_INVOICE)

                if (!approvalProcessValid) {
                    disabled = disabled || !getFolderProgressionInfo(activeExpense, subExpenses).canAdvance || !approvalProcessValid
                    tooltip = t("tooltips:folder.sub_expense_status_left_behind")
                }
            }

            return (
                <>
                    <h2 className="mb-0 pl-0 -mt-3">{title}</h2>
                    <p className="mb-5">{description}</p>
                    <div className="btn-wrapper sm:flex-col lg:flex-row fixed left-0 right-0 bottom-0 py-16 px-10 bg-white shadow sm:relative sm:left-auto sm:right-auto sm:bottom-auto sm:p-0 sm:pt-12 sm:pb-2 sm:bg-transparent sm:shadow-none z-20 sm:z-0 gap-8">
                        <Button className="sm:py-5 sm:px-10 flex-1 btn-default" onClick={rejectRequest} disabled={!canApprove}>
                            <CancelIcon />
                            <span>{t("action:reject")}</span>
                        </Button>
                        <ConditionalWrapper
                            condition={tooltip}
                            wrapper={(children: React.ReactNode) => (
                                <Tooltip className="sm:relative sm:flex-col flex flex-1 mt-0" title={tooltip}>
                                    {children}
                                </Tooltip>
                            )}
                            children={
                                <Button
                                    loading={isLoading}
                                    className={disabled ? "flex-1 mx-2" : "ant-btn-primary sm:px-10 flex-1 mx-2"}
                                    onClick={() => approvalScope && markRequestAsApproved(activeExpense, approvalScope)}
                                    type="primary"
                                    disabled={disabled}
                                >
                                    <CheckIcon />
                                    <span>{t("action:approve")}</span>
                                </Button>
                            }
                        />
                    </div>
                    {costCentersAllowedToView.length > 0 && (
                        <>
                            <Divider></Divider>
                            <ExpenseCostCenterBudgetUsageProgressBar data={costCentersAllowedToView} />
                        </>
                    )}
                </>
            )
        }

        return (
            <>
                <h2 className="mb-0 pl-0 -mt-3">{t("label:approval_confirmation_non_approver")}</h2>
                <p className="mb-5">{t("label:approval_confirmation_desc_non_approver")}</p>
            </>
        )
    }

    const renderDocumentsNeededView = () =>
        (canApprove || isApproverOfInvoiceApprovalProcess) && !isRequester ? (
            <>
                <div>
                    <h2 className="mb-0 pl-0 -mt-3">{t("label:missing_invoices")}</h2>
                    <p className="mb-5">{t("label:missing_invoices_desc")}</p>
                </div>
                <Button className="flex-1 btn-highlight-blue m-2 p-5 w-full" onClick={sendEmailReminder} disabled={!isAllowedToCreateExpenses}>
                    <BellIcon />
                    <span className="m-0">{t("action:send_reminder")}</span>
                </Button>
            </>
        ) : (
            <>
                <div>
                    <h2 className="mb-0 pl-0 -mt-3">{t("label:upload_documents")}</h2>
                    {!isRequester && <p className="mb-5">{t("label:upload_documents_desc")}</p>}
                </div>
                <UploadFilesDragger
                    doFileUpload={doFileUpload}
                    documentType={DocumentTypeEnum.INVOICE}
                    disabled={isInvoiceUploading || !isAllowedToCreateExpenses || !expenseEditable}
                >
                    {inboxInvoiceData.count > 0 && (
                        <Button className="btn-highlight-blue mt-10 mr-8" onClick={onInboxSelected} disabled={!isAllowedToCreateExpenses || !expenseEditable}>
                            <UploadIcon />
                            {t("action:inbox_invoice.inbox")}
                        </Button>
                    )}
                    <Button loading={isInvoiceUploading} className="btn-highlight-blue mt-10" disabled={!isAllowedToCreateExpenses || !expenseEditable}>
                        <UploadIcon />
                        <span>{t("action:upload_invoice")}</span>
                    </Button>
                </UploadFilesDragger>
            </>
        )

    const renderExpenseApprovedView = () => {
        let reviewTitle = t("label:review_confirmation")
        let reviewDescription = <>{t("label:review_confirmation_desc")}</>

        const isButtonDisabled = !isReviewButtonEnabled(activeExpense, subExpenses) || isExpenseFolderAndEmpty

        if (isMileageOrPerDiem(activeExpense)) {
            reviewDescription = <>{t("label:review_mileage_perdiem_desc")}</>
        } else if (isFolderExpense(activeExpense)) {
            const folderStrings = getFolderActionStrings(activeExpense, t)
            reviewTitle = folderStrings.title
            reviewDescription = generateFolderDescription(folderStrings)
        }

        if (!isAllowedToReview) {
            return (
                <>
                    <h2 className="mb-0 pl-0 -mt-3">{t("label:review_phase")}</h2>
                    <p className="mb-5">{t("label:review_phase_desc")}</p>
                </>
            )
        }

        if (!vendor || vendor.isApproved) {
            return (
                <>
                    <h2 className="mb-0 pl-0 -mt-3">{reviewTitle}</h2>
                    <p className="mb-5">{reviewDescription}</p>
                    <div className="btn-wrapper sm:flex-col lg:flex-row fixed left-0 right-0 bottom-0 py-16 px-10 bg-white shadow sm:relative sm:left-auto sm:right-auto sm:bottom-auto sm:p-0 sm:pt-12 sm:pb-2 sm:bg-transparent sm:shadow-none z-20 sm:z-0 flex flex-wrap">
                        <Button className="sm:py-5 sm:px-10 flex-1 btn-default mx-2 my-5" onClick={rejectRequest}>
                            <CancelIcon />
                            <span>{t("action:reject")}</span>
                        </Button>
                        <ConditionalWrapper
                            condition={isButtonDisabled}
                            wrapper={(children: any) => (
                                <Tooltip
                                    title={
                                        <>
                                            <p>{t("tooltips:expenses.not_all_information_provided")}</p>

                                            {isFolderExpense(activeExpense) && subExpenses.some((child) => child.status < ExpenseStatusEnum.APPROVED) && (
                                                <p>{t("tooltips:expenses.not_approved_yet")}</p>
                                            )}
                                        </>
                                    }
                                    placement="bottom"
                                    className="sm:relative sm:flex-col flex flex-1 mt-0"
                                >
                                    {children}
                                </Tooltip>
                            )}
                        >
                            <Button
                                disabled={isButtonDisabled || isExpenseUpdating || isOcrUploading}
                                loading={isButtonDisabled ? false : isLoading}
                                className={isButtonDisabled ? "sm:py-5 flex-1 mx-2 my-5" : "ant-btn-primary sm:py-5 sm:px-10 flex-1 mx-2 my-5"}
                                type={isButtonDisabled ? "primary" : "default"}
                                onClick={() => markRequestAsReviewed(activeExpense)}
                            >
                                <CheckIcon />
                                <span>{t("action:mark_as_reviewed")}</span>
                            </Button>
                        </ConditionalWrapper>
                    </div>
                </>
            )
        }
        return (
            <>
                <h2 className="mb-0 pl-0 -mt-3">{t("label:vendor_confirmation")}</h2>
                <p className="mb-5">
                    <Trans
                        t={t}
                        values={{ vendorName: vendor?.name }}
                        i18nKey="label:vendor_confirmation_desc"
                        components={[<Link key="linkKey" to={`/vendors/${vendor?.id}`} />]}
                    />
                </p>
                <div className="btn-wrapper sm:flex-col lg:flex-row fixed left-0 right-0 bottom-0 py-16 px-10 bg-white shadow sm:relative sm:left-auto sm:right-auto sm:bottom-auto sm:p-0 sm:pt-12 sm:pb-2 sm:bg-transparent sm:shadow-none z-20 sm:z-0 flex flex-wrap">
                    <Button className="sm:py-5 sm:px-10 flex-1 btn-default mx-2 my-5" onClick={() => approveOrRejectVendor(false)}>
                        <CancelIcon />
                        <span>{t("action:reject_vendor")}</span>
                    </Button>
                    <Button
                        disabled={isUpdatingVendorStatus}
                        loading={isUpdatingVendorStatus}
                        className={isUpdatingVendorStatus ? "sm:py-5 flex-1 mx-2 my-5" : "ant-btn-primary sm:py-5 sm:px-10 flex-1 mx-2 my-5"}
                        type={isUpdatingVendorStatus ? "primary" : "default"}
                        onClick={() => approveOrRejectVendor(true)}
                    >
                        <CheckIcon />
                        <span>{t("action:approve_vendor")}</span>
                    </Button>
                </div>
            </>
        )
    }

    const renderReviewedExpenseView = () => {
        const onCmd = ({ key, domEvent }: any) => {
            domEvent.stopPropagation()
            switch (key) {
                case "mark_as_paid":
                    markRequestAsPaid(activeExpense)
                    break
                case "pay_on_date":
                    payExpense(true)
                    break
                case "generate_sepa":
                case "generate_non_sepa":
                    const adjustedExpense = isFolderExpense(activeExpense) ? new TripFolder({ ...activeExpense, children: subExpenses }) : activeExpense
                    const isNonSepaXml = key === "generate_non_sepa"
                    showModal(SEPAExportConfirmationModal, true, {
                        isShowing: true,
                        expenses: getExpensesToPayOrExportForTables([adjustedExpense], undefined, true),
                        isNonSepaXml,
                        onSubmit: ({
                            expensesToExport,
                            subjectLines,
                            exportFormat,
                        }: {
                            expensesToExport: Array<Expense>
                            subjectLines: Record<string, string>
                            exportFormat: ExportFormatEnum.SEPA | ExportFormatEnum.NON_SEPA
                        }) => ExpenseService.exportSepa({ expenses: expensesToExport, subjectLines, exportFormat }),
                    })
                    break
                default:
                    break
            }
        }

        const isExpenseActionable = () => {
            if (activeExpense.isInPaymentProcess) return false
            if (isOcrUploading) return false
            if (isExpenseUpdating) return false
            if (isCreditorNumberMissing) return false
            if (isExpenseFolderAndEmpty) return false
            return true
        }

        const shouldEnableMarkAsPaidOption = () => {
            if (!isExpenseActionable()) return false

            if (!isFolderExpense(activeExpense)) return true

            if (isFolderPayable(activeExpense, subExpenses)) return true

            return isFolderMarkableAsPaid(activeExpense, subExpenses)
        }

        const shouldEnablePayOptions = () => {
            if (!isExpenseActionable()) return false

            if (!isFolderExpense(activeExpense)) return true

            return isFolderPayable(activeExpense, subExpenses)
        }

        const shouldShowPayNow = () => {
            if (!hasDefaultBankConnection) return false

            if (isRefundExpense) return false

            if (!isFolderExpense(activeExpense)) return true

            return isFolderPayable(activeExpense, subExpenses)
        }

        const checkPlacement = () => (windowWidth < breakpoints.sm ? "topRight" : "bottomLeft")

        let title = shouldShowPayNow() ? t("label:pay_confirmation") : t("label:mark_confirmation")
        let description = <>{shouldShowPayNow() ? t("label:pay_confirmation_desc") : t("label:mark_confirmation_desc")}</>

        if (isFolderExpense(activeExpense)) {
            const folderStrings = getFolderActionStrings(activeExpense, t)
            title = folderStrings.title
            description = generateFolderDescription(folderStrings)
        }

        return activeExpense.paymentOption === ExpensePaymentOptionEnum.SMART_CARD ? (
            <>
                <h2 className="mb-0 pl-0 -mt-3">{t("label:cards.waiting_for_card_transaction.title")}</h2>
                <p className="mb-5">{t("label:cards.waiting_for_card_transaction.message")}</p>
            </>
        ) : AuthzService.isRightGrantedForLoggedInUser(RightEnum.EXPENSE__ALL__PAY_BY_BANK) ? (
            <>
                <h2 className="mb-0 pl-0 -mt-3">{title}</h2>
                <p className="mb-5">{description}</p>
                <div className="btn-wrapper md:flex-col lg:flex-row fixed left-0 md:left-40 right-0 bottom-0 py-16 px-10 bg-white shadow sm:relative sm:left-auto sm:right-auto sm:bottom-auto sm:p-0 sm:pt-12 sm:pb-2 sm:bg-transparent sm:shadow-none z-20 sm:z-0">
                    <Button
                        loading={isLoading}
                        className="ant-btn-primary text-center  md:py-5 md:px-10 flex-1 btn-default"
                        onClick={shouldShowPayNow() ? () => payExpense(false) : () => markRequestAsPaid(activeExpense)}
                        disabled={shouldShowPayNow() ? !shouldEnablePayOptions() : !shouldEnableMarkAsPaidOption()}
                    >
                        <CheckIcon className="mr-2" />
                        <span>{shouldShowPayNow() ? t("action:banking.pay_now") : t("action:mark_as_paid")}</span>
                    </Button>
                    <Dropdown
                        trigger={["click"]}
                        placement={checkPlacement()}
                        arrow
                        disabled={!isExpenseActionable()}
                        overlay={
                            <Menu onClick={onCmd}>
                                {shouldShowPayNow() && (
                                    <>
                                        <Menu.Item key="pay_on_date" className="flex items-center min-w-190 py-8">
                                            <div className="flex items-center justify-center rounded-full w-16 h-16">
                                                <BankIcon />
                                            </div>
                                            <div className="flex items-center ml-12">
                                                <p className="leading-none">
                                                    <span className="text-text">{t("action:banking.pay_on_date")}</span>
                                                </p>
                                            </div>
                                        </Menu.Item>
                                        <Menu.Item key="mark_as_paid" className="flex items-center min-w-190 py-8">
                                            <div className="flex items-center justify-center rounded-full w-16 h-16">
                                                <CheckIcon />
                                            </div>
                                            <div className="flex items-center ml-12">
                                                <p className="leading-none">
                                                    <span className="text-text">{t("action:mark_as_paid")}</span>
                                                </p>
                                            </div>
                                        </Menu.Item>
                                    </>
                                )}
                                <Menu.Item key="generate_sepa" className="flex items-center min-w-190 py-8">
                                    <div className="flex items-center justify-center rounded-full w-16 h-16">
                                        <CogIcon />
                                    </div>
                                    <div className="flex items-center ml-12">
                                        <p className="leading-none">
                                            <span className="text-text">{t("action:generate_sepa")}</span>
                                        </p>
                                    </div>
                                </Menu.Item>
                                {isNonSepaXmlExportEnabled ? (
                                    <Menu.Item key="generate_non_sepa" className="flex items-center min-w-190 py-8">
                                        <div className="flex items-center justify-center rounded-full w-16 h-16">
                                            <CogIcon />
                                        </div>
                                        <div className="flex items-center ml-12">
                                            <p className="leading-none">
                                                <span className="text-text">{t("action:generate_non_sepa")}</span>
                                            </p>
                                        </div>
                                    </Menu.Item>
                                ) : null}
                            </Menu>
                        }
                    >
                        <Button className="md:py-5 md:px-10 md:mt-10 lg:mt-0 md:ml-0 lg:ml-10">
                            <MoreIcon />
                        </Button>
                    </Dropdown>
                </div>
            </>
        ) : (
            <>
                <h2 className="mb-0 pl-0 -mt-3">{t("label:pay_phase")}</h2>
                <p className="mb-5">{t("label:pay_phase_desc")}</p>
            </>
        )
    }

    const renderPaidExpenseView = () => {
        let title = t("label:export_confirmation")
        let description = <>{t("label:export_confirmation_desc")}</>

        if (isFolderExpense(activeExpense)) {
            const folderStrings = getFolderActionStrings(activeExpense, t)
            title = folderStrings.title
            description = generateFolderDescription(folderStrings, false)
        }

        return AuthzService.isRightGrantedForLoggedInUser(RightEnum.EXPENSE__ALL__EXPORT) ? (
            <>
                <h2 className="mb-0 pl-0 -mt-3">{title}</h2>
                <p className="mb-5">{description}</p>
                <div className="w-full">
                    <ExpenseExport
                        render={({ onExport, isExporting }) => {
                            const { showModal } = useModal()

                            return (
                                <ExportDropDownMenu
                                    onExport={({ expensesArrayOrExpenseQuery: expenses, exportFormat, csvTemplate }) =>
                                        FolderService.handleFolderExportAction(activeExpense, subExpenses, showModal, onExport, expenses, exportFormat, csvTemplate)
                                    }
                                    expenses={isFolderExpense(activeExpense) ? [activeExpense, ...subExpenses] : [activeExpense]}
                                    isLoading={isExporting}
                                    includeSepa
                                    primaryButton
                                    disabled={isCreditorNumberMissing}
                                    isAuditTrailVisible={true}
                                />
                            )
                        }}
                    />
                </div>
            </>
        ) : (
            <>
                <h2 className="mb-0 pl-0 -mt-3">{t("label:export_phase")}</h2>
                <p className="mb-5">{t("label:export_phase_desc")}</p>
            </>
        )
    }

    const renderMyExpenseView = () => (
        <>
            <h2 className="mb-0 pl-0 -mt-3">{t("label:approval_pending")}</h2>
            <p className="mb-5">{t("label:approval_pending_desc")}</p>
            <div className="btn-wrapper sm:flex-col lg:flex-row flex-wrap-reverse fixed left-0 right-0 bottom-0 py-16 px-10 bg-white shadow sm:relative sm:left-auto sm:right-auto sm:bottom-auto sm:p-0 sm:pt-12 sm:pb-2 sm:bg-transparent sm:shadow-none z-20 sm:z-0">
                <Button className="flex-1 btn-default m-2 p-5" onClick={cancelRequest} disabled={!isAllowedToCreateExpenses}>
                    <CancelIcon />
                    <span>{t("action:cancel_request")}</span>
                </Button>
                {/* DO not remove  md:mt-10 lg:mt-0 md:ml-0 lg:ml-10 */}
                <Button className="flex-1 btn-highlight-blue m-2 p-5" onClick={sendEmailReminder} disabled={!isAllowedToCreateExpenses}>
                    <BellIcon />
                    <span className="m-0">{t("action:send_reminder")}</span>
                </Button>
            </div>
        </>
    )

    const renderDeletedStatus = () => (
        <>
            <h2 className="mb-0 pl-0 -mt-3">{t("label:expense_deleted")}</h2>
            <p className="mb-5">{t("label:expense_deleted_desc")}</p>
        </>
    )

    const renderDeclinedExpenseView = () => {
        let title = t("label:expense_rejected")
        let message = t("label:expense_rejected_desc")
        if (isFolderExpense(activeExpense)) {
            const folderStrings = getFolderActionStrings(activeExpense, t)
            title = folderStrings.title
            message = folderStrings.description
        }
        return (
            <>
                <h2 className="mb-0 pl-0 -mt-3">{title}</h2>
                <p className="mb-5">{message}</p>
            </>
        )
    }

    const renderArchivedStatus = () => (
        <>
            <h2 className="mb-0 pl-0 -mt-3">{t("label:expense_archived")}</h2>
            <p className="mb-5">{t("label:expense_archived_desc")}</p>
        </>
    )

    const renderInitApprovalProcessView = (scope: ApprovalScopeEnum, expenseKind: ExpenseKindEnum) => {
        const disabled =
            !isAllowedToCreateExpenses ||
            isExpenseUpdating ||
            isOcrUploading ||
            (scope === ApprovalScopeEnum.EXPENSE_INVOICE
                ? activeExpense.invoices.length === 0 && !isMileageOrPerDiem(activeExpense) && activeExpense.kind !== ExpenseKindEnum.TRIP_FOLDER
                : false)

        const title =
            expenseKind === ExpenseKindEnum.TRIP_FOLDER
                ? t("label:folder_submit_confirmation")
                : scope === ApprovalScopeEnum.EXPENSE_INVOICE
                ? t("label:invoice_submit_confirmation")
                : t("label:purchase_submit_confirmation")
        return (
            <>
                <h2 className="mb-0 pl-0 -mt-3">{title}</h2>
                <p className="mb-5">
                    {expenseKind === ExpenseKindEnum.TRIP_FOLDER ? t("label:folder_submit_confirmation_description") : t("label:request_submit_confirmation_description")}
                </p>
                <div className="w-full">
                    <Button loading={isLoading} className="w-full" onClick={() => scope && markRequestAsApproved(activeExpense, scope)} type="primary" disabled={disabled}>
                        <ArrowThinUpIcon />
                        <span>{t("action:submit")}</span>
                    </Button>
                </div>
            </>
        )
    }

    const renderApprovalPendingView = (expenseStatus: ExpenseStatusEnum.PURCHASE_PENDING | ExpenseStatusEnum.INVOICE_PENDING) => {
        const scope = expenseStatus === ExpenseStatusEnum.PURCHASE_PENDING ? ApprovalScopeEnum.EXPENSE_PURCHASE : ApprovalScopeEnum.EXPENSE_INVOICE
        const approvalProcess = getApprovalProcess(scope, activeExpense.approvalProcesses)
        if (approvalProcess?.status === ApprovalStatusEnum.NOT_STARTED) {
            const isApproverOfApprovalProcess = getApproverIdsOfApprovalProcesses(activeExpense.approvalProcesses).includes(loggedInUser.id)

            // The requester, approvers of the approval process or any admin can submit/initiate an approval process.
            if (isMyExpense || isApproverOfApprovalProcess || canApprove) return renderInitApprovalProcessView(scope, activeExpense.kind)
            return renderApprovalConfirmationView()
        }
        // approval process is in progress
        if (canApprove) {
            return renderApprovalConfirmationView()
        }
        if (isMyExpense) {
            return renderMyExpenseView()
        }
        return renderApprovalConfirmationView()
    }

    const getActionHeader = () => {
        if (activeExpense.deleted) return renderDeletedStatus()
        if (isArchived) return renderArchivedStatus()

        switch (activeExpense.status) {
            case ExpenseStatusEnum.PURCHASE_PENDING:
                return renderApprovalPendingView(ExpenseStatusEnum.PURCHASE_PENDING)
            case ExpenseStatusEnum.INVOICE_PENDING:
                return renderApprovalPendingView(ExpenseStatusEnum.INVOICE_PENDING)
            case ExpenseStatusEnum.DOCS_NEEDED:
                return renderDocumentsNeededView()
            case ExpenseStatusEnum.APPROVED:
                return renderExpenseApprovedView()
            case ExpenseStatusEnum.REVIEWED:
                return renderReviewedExpenseView()
            case ExpenseStatusEnum.PAID:
                return renderPaidExpenseView()
            case ExpenseStatusEnum.DECLINED:
                return renderDeclinedExpenseView()
            default:
                return (
                    <>
                        <h2 className="mb-0 pl-0 -mt-3 -mb-2">{t("label:past_request")}</h2>
                        <p className="mb-5">{t("label:past_request_desc")}</p>
                    </>
                )
        }
    }

    return getActionHeader()
}

export default ExpenseActionButtonView
