import { CostCenter, CostCenter2, WorkflowConditionEnum } from "@finway-group/shared/lib/models"
import Form from "antd/lib/form"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"

import { FilterFormInterface } from "Components/filter/filterTypes"
import { FormSelectOption } from "Components/form/form.types"
import FormSelect from "Components/form/formSelect"
import { SELECT_ALL_RESOURCES_OPTION } from "Shared/config/consts"
import { useCompany } from "Shared/hooks/company.hooks"
import { useIsTravelEnabled } from "Shared/hooks/featureFlags.hooks"
import { getTableFilterObjectStore } from "Shared/hooks/table.hooks"
import { createDynamicList, getDynamicCostCenter2List, getDynamicCostCenterList, getDynamicCreditorList } from "Shared/utils/filter.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"
import { getWorkFlowBasicRequestTypeLabels } from "Shared/utils/workflow.form.utils"

import { WorkflowFormRequestTypeEnum } from "./workflowForm/workflowFormValues.interface"

export interface WorkflowFilter {
    requestType?: WorkflowFormRequestTypeEnum
    triggerType?: WorkflowConditionEnum
    vendor?: string
    costCenter?: string
    costCenter2?: string
    requestedBy?: string
}

const WorkflowFilterForm: React.FC<FilterFormInterface> = ({ table, formInstance, options, onSubmit }) => {
    const { t } = useTranslation()

    const tableFilterObject = getTableFilterObjectStore<WorkflowFilter>(table)
    const [workflowFilter, setWorkflowFilter] = useStateIfMounted(tableFilterObject)
    const { showCostCentersIds } = useCompany()
    const isTravelEnabled = useIsTravelEnabled()

    useEffect(() => {
        formInstance.resetFields()
        setWorkflowFilter(workflowFilter)
    }, [])

    useEffect(() => {
        formInstance.validateFields()
    }, [workflowFilter])

    const requestTypeList: Array<FormSelectOption> = createDynamicList(
        "label:workflow.request_type",
        isTravelEnabled ? Object.values(WorkflowFormRequestTypeEnum) : getWorkFlowBasicRequestTypeLabels(),
    )

    const triggerList: Array<FormSelectOption> = createDynamicList("label:workflow.trigger", [
        WorkflowConditionEnum.VENDOR,
        WorkflowConditionEnum.COST_CENTER,
        WorkflowConditionEnum.REQUESTER,
        WorkflowConditionEnum.NET_AMOUNT,
        WorkflowConditionEnum.COST_CENTER_2,
    ])

    return (
        <>
            <Form id="workflowForm" className="form--filter" form={formInstance} onFinish={onSubmit}>
                <FormSelect
                    name="requestType"
                    header={t("input:workflow.request_type.title")}
                    initialValue={workflowFilter.requestType}
                    options={requestTypeList}
                    isCollapse
                    isForm
                    fallback
                />
                <FormSelect
                    name="triggerType"
                    header={t("input:workflow.trigger.trigger_type")}
                    initialValue={workflowFilter.triggerType}
                    options={triggerList}
                    isCollapse
                    fallback
                    isForm
                />
                <FormSelect
                    name="vendor"
                    header={t("label:workflow.trigger.vendor")}
                    initialValue={workflowFilter.vendor ?? ""}
                    options={getDynamicCreditorList(options.creditors)}
                    fallback={String(t("input:filter_form.all"))}
                    fallbackValue={SELECT_ALL_RESOURCES_OPTION}
                    isCollapse
                    showSearch
                    isForm
                />
                <FormSelect
                    name="costCenter"
                    header={t("label:workflow.trigger.cost_center")}
                    initialValue={workflowFilter.costCenter}
                    options={getDynamicCostCenterList(options.costCenters as Array<CostCenter>, showCostCentersIds)}
                    fallback={String(t("input:filter_form.all"))}
                    fallbackValue={SELECT_ALL_RESOURCES_OPTION}
                    isCollapse
                    showSearch
                    isForm
                />
                <FormSelect
                    name="requestedBy"
                    header={t("label:workflow.trigger.requester")}
                    initialValue={workflowFilter.requestedBy}
                    options={options.employees ?? []}
                    fallback={String(t("input:filter_form.all"))}
                    fallbackValue={SELECT_ALL_RESOURCES_OPTION}
                    displayImages
                    isCollapse
                    showSearch
                    isForm
                />
                <FormSelect
                    name="costCenter2"
                    header={t("label:workflow.trigger.cost_center_2")}
                    initialValue={workflowFilter.costCenter2}
                    options={getDynamicCostCenter2List(options.costCenters2 as Array<CostCenter2>)}
                    fallback={String(t("input:filter_form.all"))}
                    fallbackValue={SELECT_ALL_RESOURCES_OPTION}
                    isCollapse
                    showSearch
                    isForm
                />
            </Form>
        </>
    )
}

export default WorkflowFilterForm
