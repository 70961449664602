import CopyOutlined from "@ant-design/icons/lib/icons/CopyOutlined"
import { Tooltip } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"

import { copyTextToClipboard } from "Shared/utils/copy.utils"

interface CopyProps {
    className?: string
    value: string
    onCopied?: () => void
}

const Copy = ({ value, onCopied, className = "" }: CopyProps) => {
    const { t } = useTranslation()

    return (
        <Tooltip title={t("label:copy")}>
            <CopyOutlined
                className={className}
                onClick={() => {
                    copyTextToClipboard(value)
                    onCopied?.()
                }}
            />
        </Tooltip>
    )
}

export default Copy
