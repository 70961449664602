import { CurrencyEnum, RightEnum } from "@finway-group/shared/lib/models"
import { NotificationBundleEnum, UserNotificationSettingsInterface } from "@finway-group/shared/lib/models/user/userSettings.interface"
import { Form, Select, Switch } from "antd"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useDebouncedCallback } from "use-debounce/lib"

import CurrencyInput from "Components/currencyInput"
import { parseCurrencyInput } from "Components/currencyInput/config"
import SettingsLayout from "Features/settings/settings.layout"
import { DEBOUNCE_DURATION_LONG } from "Shared/config/consts"
import { useLoggedInEmployeeProfile } from "Shared/hooks/user.hooks"
import { AuthzService } from "Shared/services"
import { updateEmployeeSettings } from "Shared/store/actions/employee/employeeActions"
import { insertIf } from "Shared/utils/helper.utils"

import AdvancedNotificationContainer, { AdvancedNotificationType } from "./advancedNotificationContainer"
import EmailNotificationSettings from "./emailNotificationSettings"

const UserNotificationSettingsContainer = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [formInstance] = Form.useForm()

    const loggedInEmployee = useLoggedInEmployeeProfile()
    const [notificationSettings, setNotificationSettings] = useState<UserNotificationSettingsInterface>(loggedInEmployee.getNotificationSettings() as any)
    const [isWalletBalanceLimitNegative, setIsWalletBalanceLimitNegative] = useState<boolean>(
        Number(loggedInEmployee.getNotificationSettings()?.walletBalanceThreshold?.amount) < 0,
    )

    /**
     * General email notification schema
     */
    const EmailNotificationConfigs = [
        {
            name: "enableCommentsAndMentions",
            title: t("label:notifications.enable_comments_mentions.title"),
            description: t("label:notifications.enable_comments_mentions.description"),
            tooltip: t("label:notifications.enable_comments_mentions.tooltip"),
            checked: notificationSettings?.enableCommentsAndMentions || notificationSettings?.enabledAllNotifications,
        },
        {
            name: "enableActionCall",
            title: t("label:notifications.enable_action_to_call.title"),
            description: t("label:notifications.enable_action_to_call.description"),
            tooltip: t("label:notifications.enable_action_to_call.tooltip"),
            checked: notificationSettings?.enableActionCall || notificationSettings?.enabledAllNotifications,
        },
        {
            name: "enableUserAction",
            title: t("label:notifications.enable_my_action.title"),
            description: t("label:notifications.enable_my_action.description"),
            tooltip: t("label:notifications.enable_my_action.tooltip"),
            checked: notificationSettings?.enableUserAction || notificationSettings?.enabledAllNotifications,
        },
        ...insertIf(AuthzService.isLoggedInUserGlobalApprover(), {
            // product requirement. It should cover 99% of the cases
            name: "enableInformative",
            title: t("label:notifications.informative.title"),
            description: t("label:notifications.informative.description"),
            tooltip: t("label:notifications.informative.tooltip"),
            checked: notificationSettings?.enableInformative || notificationSettings?.enabledAllNotifications,
        }),
    ]

    /**
     * Advanced notification settings schema
     */
    const AdvancedNotificationSettings: Array<AdvancedNotificationType> = [
        ...insertIf(AuthzService.isRightGrantedForLoggedInUser(RightEnum.CARD_WALLET__ALL__MANAGE), {
            name: "incomingMoneyToWallet",
            title: t("label:notifications.received_money_wallet"),
        }),
        ...insertIf(AuthzService.isRightGrantedForLoggedInUser(RightEnum.CARD_WALLET__ALL__MANAGE), {
            name: "walletBalanceThreshold.enabled",
            title: t("label:notifications.wallet_balance"),
            tooltip: t("label:notifications.wallet_balance_tooltip"),
            input: {
                name: "walletBalanceThreshold.amount",
                component: CurrencyInput,
                props: {
                    currency: CurrencyEnum.EUR,
                    value: 0,
                    className: "ant-input w-100 md:w-140 border-1 p-2",
                },
            },
        }),
        /* { // todo for next release (refactor cost center calc and move to api side)
            name: "costCenterBudgetAlert",
            title: t("label:notifications.costcenter_spent_budget"),
        }, */
        ...insertIf(AuthzService.isRightGrantedForLoggedInUser(RightEnum.CARD__ALL__READ), {
            name: "cardTransactionAlert",
            title: t("label:notifications.card_transaction_status"),
            tooltip: t("label:notifications.card_transaction_status_tooltip"),
        }),
        ...insertIf(AuthzService.isRightGrantedForLoggedInUser(RightEnum.EXPENSE__ALL__PAY_BY_BANK), {
            name: "pendingTransactionsAlert",
            title: t("label:notifications.pending_transactions_alert"),
            tooltip: t("label:notifications.pending_transactions_alert_tooltip"),
        }),
    ]

    /**
     * BundleEntries Schema
     */
    const BundleEntries = [
        {
            key: NotificationBundleEnum.OFF,
            value: t("label:notifications.bundle.off"),
        },
        {
            key: NotificationBundleEnum.DAILY,
            value: t("label:notifications.bundle.daily"),
        },
        {
            key: NotificationBundleEnum.WEEKLY,
            value: t("label:notifications.bundle.weekly"),
        },
    ]

    useEffect(() => {
        let mounted = true
        if (mounted) {
            const settings = loggedInEmployee.getNotificationSettings()
            setNotificationSettings(settings || ({} as UserNotificationSettingsInterface))
            setIsWalletBalanceLimitNegative(Number(settings?.walletBalanceThreshold?.amount) < 0)
            formInstance.setFieldsValue({
                ...settings,
                "walletBalanceThreshold.amount": settings?.walletBalanceThreshold.amount,
                "walletBalanceThreshold.enabled": settings?.walletBalanceThreshold.enabled,
            })
        }

        return () => {
            mounted = false
        }
    }, [loggedInEmployee, formInstance])

    /**
     * prepare local notification settings to dispatch
     * @param key
     * @param value
     */
    const handleUpdatedSchema = (key: string, value: any) => {
        const nestedKeys: Array<string> = key.split(".")
        if (nestedKeys.length > 1) {
            loggedInEmployee.setNotificationSettings(nestedKeys[0], {
                ...(loggedInEmployee.getNotificationSettings() as any)[nestedKeys[0]],
                [nestedKeys[1]]: value,
            })
        } else {
            loggedInEmployee.setNotificationSettings(key, value)
        }
    }

    /**
     * handle allNotificationEnabled behaviour when switched on
     * dispatch loggedInUser notification settings update
     * @param key form key
     * @param value form value
     */
    const handleNotificationSettingsUpdate = (key: string, value: any) => {
        if (key !== "enabledAllNotifications" && !value) {
            loggedInEmployee.setNotificationSettings("enabledAllNotifications", value)
        }

        if (key === "enabledAllNotifications" && value) {
            ;[...EmailNotificationConfigs, ...AdvancedNotificationSettings].map(({ name }) => handleUpdatedSchema(name, true))
        }

        handleUpdatedSchema(key, value)

        dispatch(updateEmployeeSettings(loggedInEmployee.id, loggedInEmployee))
    }

    const debounceUpdate = useDebouncedCallback((key: string, value: any, fn: any) => fn(key, value), DEBOUNCE_DURATION_LONG)

    /**
     * transform values changes to key value object
     * convert curerncy amount to number
     * invoke update function
     * @param values
     * @returns
     */
    const handleUpdate = (values: any) =>
        Object.keys(values).forEach((key: string) => {
            const isAmountUpdate = key.includes("amount")
            const value = isAmountUpdate ? parseCurrencyInput(values[key]) : values[key]
            if (isAmountUpdate) {
                // Debounce amount updates because it might flood the server with requests every time the user types a number
                return debounceUpdate.callback(key, value, handleNotificationSettingsUpdate)
            }

            handleNotificationSettingsUpdate(key, value)
        })

    const BundleSelect = () => (
        <div className="flex justify-between items-center">
            <span className="mr-10">{t("label:notifications.bundle.title")}</span>
            <Select
                defaultValue={notificationSettings?.bundle}
                style={{ width: 120 }}
                onChange={(value) => debounceUpdate.callback("bundle", value, handleNotificationSettingsUpdate)}
            >
                {BundleEntries.map(({ key, value }, index: number) => (
                    <Select.Option key={index} value={key}>
                        {value}
                    </Select.Option>
                ))}
            </Select>
        </div>
    )

    return (
        <SettingsLayout titleKey="label:notifications.email_settings" buttons={<BundleSelect key={"bundleIndex"} />}>
            <Form form={formInstance} onValuesChange={handleUpdate} onFinish={handleUpdate} initialValues={notificationSettings}>
                <div className="w-full">
                    <li key="enable_all" className="flex">
                        <div className="flex flex-1 flex-col justify-center">
                            <span className="title font-bold flex items-center">{t("label:notifications.enable_all_notifications.title")}</span>
                            <p className="hidden md:block w-full pr-20 text-left">{t("label:notifications.enable_all_notifications.description")}</p>
                        </div>
                        <div className="text text-left flex items-center flex-no-wrap">
                            <Form.Item name={"enabledAllNotifications"} valuePropName="checked">
                                <Switch className="mr-10" />
                            </Form.Item>
                        </div>
                    </li>

                    <EmailNotificationSettings emailNotificationConfigs={EmailNotificationConfigs} />

                    <hr className="divider mr-20" />

                    {AdvancedNotificationSettings.length > 0 && (
                        <AdvancedNotificationContainer advancedNotification={AdvancedNotificationSettings} isWalletBalanceLimitNegative={isWalletBalanceLimitNegative} />
                    )}
                </div>
            </Form>
        </SettingsLayout>
    )
}

export default UserNotificationSettingsContainer
