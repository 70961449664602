import { ApproverTypeEnum, CostCenter, Employee, Vendor } from "@finway-group/shared/lib/models"
import { Form, Select } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"

import UserImage from "Components/UserImage"
import DeletedTooltip from "Components/deletedTooltip"
import { ALL_TRIGGER_OBJECTS } from "Shared/config/consts"
import { useCompany } from "Shared/hooks/company.hooks"
import { EmployeeService } from "Shared/services"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

export enum WorkflowFormResourceTypeEnum {
    EMPLOYEE = "EMPLOYEE",
    COST_CENTER = "COST_CENTER",
    VENDOR = "VENDOR",
    COST_CENTER_2 = "COST_CENTER_2",
}

interface WorkflowTriggerObjectSelectInterface {
    name: Array<string | number>
    label: string
    required: boolean
    resources: Array<any>
    selectedResourcesIds: Array<string>
    anchor: any
    resourceType: WorkflowFormResourceTypeEnum
    index: number
    isMultiple?: boolean
    isTags?: boolean
    rules?: any
    onSelect: (index: number, value: any | Array<any>) => void
    maxOptions?: number
    includeAutoApprover?: boolean
    includeSuperior?: boolean
    includeResponsibleEmployee?: boolean
    includeRequester?: boolean
    includeAllOption?: boolean
}

const stringLabels = {
    [ApproverTypeEnum.AUTO_APPROVER]: "input:workflow.auto_approver",
    [ApproverTypeEnum.SUPERIOR]: "input:workflow.superior",
    [ApproverTypeEnum.RESPONSIBLE_USER]: "input:workflow.responsible_employee",
    [ApproverTypeEnum.REQUESTER]: "input:workflow.requester",
}

const deletedLabels: any = {
    [WorkflowFormResourceTypeEnum.COST_CENTER]: "tooltips:cost_center.was_deleted",
    [WorkflowFormResourceTypeEnum.COST_CENTER_2]: "tooltips:cost_center_2.was_deleted",
    [WorkflowFormResourceTypeEnum.VENDOR]: "tooltips:vendor.was_deleted",
}

export const WorkflowTriggerObjectSelect: React.FC<WorkflowTriggerObjectSelectInterface> = ({
    name,
    label,
    required = false,
    resources,
    resourceType,
    index,
    isMultiple = false,
    isTags = false,
    selectedResourcesIds,
    anchor,
    rules,
    includeAutoApprover = false,
    includeSuperior = false,
    includeResponsibleEmployee = false,
    includeRequester = false,
    includeAllOption = false,
    maxOptions,
    onSelect,
}) => {
    const { t } = useTranslation()
    const { showCostCentersIds } = useCompany()
    const autoApprover = EmployeeService.getAutoApprover()
    const [open, setOpen] = useStateIfMounted<boolean>(false)
    const selectMode = isMultiple ? "multiple" : isTags ? "tags" : undefined

    const getSpecialOptionLabel = (optionType: ApproverTypeEnum.AUTO_APPROVER | ApproverTypeEnum.SUPERIOR | ApproverTypeEnum.RESPONSIBLE_USER | ApproverTypeEnum.REQUESTER) => (
        <div className="flex items-center h-30">
            <UserImage user={autoApprover} />
            <span className="mt-2">{t(stringLabels[optionType])}</span>
        </div>
    )

    // prettier-ignore
    const renderTriggerObjectLabel = (resourceId: any) => {
        const { totalNumForAll, label } = getTriggerObjectContext(resourceId)
        const isLastElement = selectedResourcesIds?.slice(-1)[0] === resourceId
        const suffix = `${isLastElement ? ` (${selectedResourcesIds?.length})` : ","}`

        if (resourceId === ALL_TRIGGER_OBJECTS) return <div>{`${t("input:workflow.trigger.all")} (${totalNumForAll || 0})`}</div>
        if (!resources?.length) return  <div className="mr-2"> {suffix} {label} </div> 

        if (resourceType === WorkflowFormResourceTypeEnum.EMPLOYEE) {
            if (resourceId === ApproverTypeEnum.SUPERIOR) return <div className="mr-2"> {t("input:workflow.superior")}{suffix}</div> 
            if (resourceId === ApproverTypeEnum.RESPONSIBLE_USER) return <div className="mr-2"> {t("input:workflow.responsible_employee")}{suffix}</div> 
            if (resourceId === ApproverTypeEnum.AUTO_APPROVER) return <div className="mr-2"> {t("input:workflow.auto_approver")}{suffix}</div> 
            if (resourceId === ApproverTypeEnum.REQUESTER) return <div className="mr-2"> {t("input:workflow.requester")}{suffix}</div> 

            const user = resources.find((employee) => employee.id === resourceId)

            return (
                <div className="mr-2">
                    <p className="inline-block">{label}</p>
                    {user?.activeCompanyProfile.deleted && <DeletedTooltip translationKey={t("info:employee_deleted")} deletionDate={user?.deleted ? user?.dateDeleted : undefined} />}
                    <p className="inline-block">{suffix}</p>
                </div>
            )
        }

        const resource = resources.find((resource) => resource._id === resourceId)
        return (
            <div className="mr-2">
                <span className="inline-block align-middle max-w-200 truncate">{label}</span>
                {(resource?.deleted || !resource) && (
                    <span className="align-middle">
                        <DeletedTooltip translationKey={deletedLabels[resourceType]} deletionDate={resource?.deleted ? resource?.dateDeleted : undefined} />{" "}
                    </span>
                )}
                <span className="inline-block align-middle max-w-100 truncate">{suffix}</span>
            </div>
        )
    }

    const getTriggerObjectContext = (triggerObjectId: any) => {
        let label
        let totalNumForAll

        switch (resourceType) {
            case WorkflowFormResourceTypeEnum.EMPLOYEE:
                const employee = resources.find(({ id }: any) => id === triggerObjectId)
                label = `${employee?.firstName} ${employee?.lastName}`
                totalNumForAll = resources.filter((employee: Employee) => !employee.activeCompanyProfile.deleted).length
                break
            case WorkflowFormResourceTypeEnum.VENDOR:
                label = resources.find(({ id }: any) => id === triggerObjectId)?.name
                totalNumForAll = resources.filter((vendor: Vendor) => !vendor.deleted).length
                break
            case WorkflowFormResourceTypeEnum.COST_CENTER:
                const costCenter = resources.find(({ _id }: any) => _id === triggerObjectId)
                label = showCostCentersIds ? `ID ${costCenter?.id} - ${costCenter?.name}` : costCenter?.name
                totalNumForAll = resources.filter((costCenter: CostCenter) => !costCenter.deleted).length
                break
            case WorkflowFormResourceTypeEnum.COST_CENTER_2:
                label = resources.find(({ _id }: any) => _id === triggerObjectId)?.name
                totalNumForAll = resources.length
                break
        }

        return { totalNumForAll, label }
    }

    const renderTriggerObjectOption = (triggerObject: Employee | CostCenter | any, index: number) => {
        switch (resourceType) {
            case WorkflowFormResourceTypeEnum.EMPLOYEE:
                return (
                    <Select.Option key={index} label={`${triggerObject.firstName} ${triggerObject.lastName}`} value={triggerObject.id}>
                        <div className="flex items-center h-30">
                            <UserImage user={triggerObject} />
                            <span className="mt-2"> {`${triggerObject.firstName} ${triggerObject.lastName}`}</span>
                            {(triggerObject as Employee).activeCompanyProfile?.deleted && (
                                <DeletedTooltip
                                    translationKey={deletedLabels[resourceType]}
                                    deletionDate={triggerObject?.activeCompanyProfile?.deleted ? triggerObject?.dateDeleted : undefined}
                                />
                            )}
                        </div>
                    </Select.Option>
                )
            case WorkflowFormResourceTypeEnum.COST_CENTER:
                return (
                    <Select.Option key={index} value={triggerObject._id}>
                        <div className="w-30">
                            <span className="truncate">{showCostCentersIds ? `ID ${triggerObject.id} - ${triggerObject.name}` : triggerObject.name}</span>
                            {(triggerObject as CostCenter).deleted && (
                                <DeletedTooltip translationKey={deletedLabels[resourceType]} deletionDate={triggerObject?.deleted ? triggerObject?.dateDeleted : undefined} />
                            )}
                        </div>
                    </Select.Option>
                )
            case WorkflowFormResourceTypeEnum.VENDOR:
            case WorkflowFormResourceTypeEnum.COST_CENTER_2:
                return (
                    <Select.Option key={index} value={triggerObject._id}>
                        {triggerObject.name}
                        {(triggerObject as Vendor).deleted && (
                            <DeletedTooltip translationKey={deletedLabels[resourceType]} deletionDate={triggerObject?.deleted ? triggerObject?.dateDeleted : undefined} />
                        )}
                    </Select.Option>
                )
            default:
                return <React.Fragment key={index} />
        }
    }

    const handleChange = (value: any | Array<any>) => {
        if (isMultiple) {
            if (maxOptions && value.length > maxOptions) {
                onSelect(index, value.slice(0, -1))
                return
            }
            const lastAddedValue = value[value.length - 1]
            const referenceIds = lastAddedValue === ALL_TRIGGER_OBJECTS ? [ALL_TRIGGER_OBJECTS] : value.filter((triggerType: any) => triggerType !== ALL_TRIGGER_OBJECTS)
            onSelect(index, referenceIds)

            return
        }

        setOpen(false)
        onSelect(index, value)
    }

    return (
        <Form.Item label={label} name={name} required={required} shouldUpdate rules={rules}>
            <Select
                showArrow
                showSearch
                mode={selectMode}
                getPopupContainer={anchor}
                open={open}
                dropdownMatchSelectWidth={false}
                onDropdownVisibleChange={(visible) => setOpen(visible)}
                tagRender={({ value }) => renderTriggerObjectLabel(value)}
                onChange={handleChange}
                filterOption={(input, option) => option?.value && (getTriggerObjectContext(option.value).label || "").toLocaleLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
                {includeAllOption && <Select.Option value={ALL_TRIGGER_OBJECTS}>{t("input:workflow.trigger.all")}</Select.Option>}
                {includeAutoApprover && <Select.Option value={ApproverTypeEnum.AUTO_APPROVER}>{getSpecialOptionLabel(ApproverTypeEnum.AUTO_APPROVER)}</Select.Option>}
                {includeSuperior && <Select.Option value={ApproverTypeEnum.SUPERIOR}>{getSpecialOptionLabel(ApproverTypeEnum.SUPERIOR)}</Select.Option>}
                {includeResponsibleEmployee && <Select.Option value={ApproverTypeEnum.RESPONSIBLE_USER}>{getSpecialOptionLabel(ApproverTypeEnum.RESPONSIBLE_USER)}</Select.Option>}
                {includeRequester && <Select.Option value={ApproverTypeEnum.REQUESTER}>{getSpecialOptionLabel(ApproverTypeEnum.REQUESTER)}</Select.Option>}
                {resources
                    .filter((resource) => !((resource.deleted || resource.activeCompanyProfile?.deleted) && !selectedResourcesIds.includes(resource._id)))
                    .map((resource: any, index: number) => renderTriggerObjectOption(resource, index))}
            </Select>
        </Form.Item>
    )
}
