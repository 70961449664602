import { EyeInvisibleOutlined as InvisibleIcon, EyeOutlined as VisibleIcon } from "@ant-design/icons"
import { Expense, ExpenseKindEnum, PriceIntervalEnum, RightEnum, Transaction } from "@finway-group/shared/lib/models"
import { roundNumberTo2Decimals } from "@finway-group/shared/lib/utils"
import { Button, Card, Table } from "antd"
import { ColumnsType } from "antd/lib/table"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { useDebouncedCallback } from "use-debounce/lib"

import NoSearchDataFound from "Components/NoSearchDataFound"
import Filter from "Components/filter/filter"
import FilterSection from "Components/filterSection"
import Loading from "Components/loading"
import PriceLabel from "Components/priceLabel"
import SearchInput from "Components/searchInput"
import { useArchiveInterval } from "Shared/hooks/company.hooks"
import { useCostCenters2 } from "Shared/hooks/costCenter2.hooks"
import { useCostCenters } from "Shared/hooks/costCenter.hooks"
import { useEmployees } from "Shared/hooks/employee.hooks"
import { useExpenses, useShouldRefetchExpenses } from "Shared/hooks/expense.hooks"
import { useExpenseAccounts } from "Shared/hooks/expenseAccount.hooks"
import { applyFilter } from "Shared/hooks/filter.hooks"
import { useTableFilterQueryString } from "Shared/hooks/table.hooks"
import { usePagingGuard, useTableConfig } from "Shared/hooks/tableConfig.hooks"
import { useCreditors } from "Shared/hooks/vendor.hooks"
import { AuthzService } from "Shared/services"
import { fetchAllExpenses, setExpense } from "Shared/store/actions/expense/expenseActions"
import { handleTableConfigChange, setSearchValue } from "Shared/store/actions/tableConfig/tableConfigActions"
import { TablesEnum } from "Shared/store/reducers/tableConfigReducer"
import { getPriceIntervalForBillingPeriod } from "Shared/utils/expense.utils"
import { convertSearchValueToQuery, getIBANPrintFormat, isEmptyObject } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"
import { parseHtml } from "Shared/utils/htmlParser.utils"

interface PossibleMatchesInterface {
    transaction: Transaction
    loading: boolean
    loadingId: string
    onMatch: (expense: string) => void
}

export const PossibleMatches: React.FC<PossibleMatchesInterface> = ({ transaction, loadingId, loading, onMatch }) => {
    const { t } = useTranslation()
    const history = useHistory()
    const dispatch = useDispatch()
    const [isFilterModalShowing, setIsFilterModalShowing] = useState(false)
    const [isInitialLoading, setIsInitialLoading] = useStateIfMounted(true)
    const [shouldHideAlreadyMatched, setShouldHideAlreadyMatched] = useStateIfMounted(false)
    const [shouldHideArchivedExpenses, setShouldHideArchivedExpenses] = useStateIfMounted(true)
    const [isLoading, setIsLoading] = useStateIfMounted(false)

    const creditors = useCreditors(AuthzService.isRightGrantedForLoggedInUser(RightEnum.EMPLOYEE__ALL__READ), true, true)
    const costCenters = useCostCenters(true)
    const costCenters2 = useCostCenters2()
    const expenseAccounts = useExpenseAccounts()
    const employees = useEmployees({ excludeDeleted: true })

    const { currentPage, itemsPerPage, sorting, searchValue } = useTableConfig(TablesEnum.POSSIBLE_MATCHES)
    const { expenses, totalDocs } = useExpenses(true)

    const searchTargets = ["vendorName", "totalGrossPrice", "description", "iban", "probability", "invoiceNumber"]
    const filterQueryString = useTableFilterQueryString(TablesEnum.POSSIBLE_MATCHES)
    const shouldRefetchExpenses = useShouldRefetchExpenses()
    const matchedIds = transaction.matchingData.matchedExpenses.map((match: any) => match.expense?._id).filter((id: string) => id)
    const archiveAfterXDays = useArchiveInterval()

    // to avoid empty fetched pages (only needed for async fetching)
    usePagingGuard(TablesEnum.POSSIBLE_MATCHES, expenses, totalDocs, currentPage)

    useEffect(() => {
        let isFetching = true

        isFetching && fetchData()

        return () => {
            isFetching = false
        }
    }, [
        transaction.id,
        currentPage,
        itemsPerPage,
        sorting,
        searchValue,
        shouldHideAlreadyMatched,
        shouldHideArchivedExpenses,
        JSON.stringify(transaction.matchingData.matchedExpenses),
        filterQueryString,
        searchValue,
    ])

    useEffect(() => {
        if (shouldRefetchExpenses) fetchData()
    }, [shouldRefetchExpenses])

    const fetchData = async () => {
        setIsLoading(true)
        try {
            const sortingString = !isEmptyObject(sorting) ? `&sortBy=${sorting.order === "ascend" ? "asc" : "desc"}(${sorting.field})` : "&sortBy=desc(probability)"
            const searchString = convertSearchValueToQuery(searchValue, searchTargets)
            // prettier-ignore
            let localFilterString = `${matchedIds.length > 0 ? `&_id[nin]=${matchedIds}` : ''}&txn[eq]=${transaction.id}&hide[is]=${shouldHideAlreadyMatched}&deleted[is]=false&isFullyProcessedSubscription[is]=false`;
            if (shouldHideArchivedExpenses) {
                localFilterString += `&archiveAfterXDays[eq]=${archiveAfterXDays}`
            }
            const compoundFilter = `&${searchString}${filterQueryString}${localFilterString}`

            await fetchAllExpenses(compoundFilter, sortingString, currentPage, itemsPerPage)(dispatch)
        } finally {
            setIsLoading(false)
            isInitialLoading && setIsInitialLoading(false)
        }
    }

    const onSearch = useDebouncedCallback((searchValue) => {
        !searchValue && setIsInitialLoading(true)
        return dispatch(setSearchValue(TablesEnum.POSSIBLE_MATCHES, searchValue))
    }, 800)

    const onTableChange = (pagination: any, _filters: any, sorter: any) => dispatch(handleTableConfigChange(TablesEnum.POSSIBLE_MATCHES, pagination, sorter))

    const possibleMatchesColumns: ColumnsType<Expense> = [
        {
            key: "datePurchased",
            title: t("label:purchased_on"),
            ellipsis: true,
            width: 180,
            className: "pl-58",
            sortOrder: sorting.field === "datePurchased" && sorting.order,
            sorter: true,
            render: (expense: Expense) => <span>{expense.datePurchased ? moment(expense.datePurchased).format("ll") : "n/a"}</span>,
        },
        {
            key: "invoiceDueDate",
            title: t("label:due_date"),
            ellipsis: true,
            width: 160,
            sortOrder: sorting.field === "invoiceDueDate" && sorting.order,
            sorter: true,
            render: (expense: Expense) => <span>{expense.invoiceDueDate ? moment(expense.invoiceDueDate).format("ll") : "n/a"}</span>,
        },
        {
            key: "vendorName",
            title: t("label:vendor"),
            ellipsis: true,
            width: 180,
            sorter: true,
            sortOrder: sorting.field === "vendorName" && sorting.order,
            render: (expense: Expense) => {
                const name = expense?.getCreditor()?.name
                return <span>{name || "n/a"}</span>
            },
        },
        {
            key: "iban",
            title: t("label:iban"),
            ellipsis: true,
            width: 250,
            sorter: true,
            sortOrder: sorting.field === "iban" && sorting.order,
            render: (expense: Expense) => {
                const iban = expense?.getCreditor()?.iban
                return <span>{iban ? getIBANPrintFormat(iban) : "n/a"}</span>
            },
        },
        {
            key: "description",
            title: t("label:description"),
            width: 180,
            ellipsis: true,
            sorter: true,
            sortOrder: sorting.field === "description" && sorting.order,
            render: (expense: Expense) => <span>{parseHtml(expense.description || "n/a")}</span>,
        },
        {
            key: "totalGrossPrice",
            title: t("label:gross_amount"),
            ellipsis: true,
            width: 140,
            sorter: true,
            sortOrder: sorting.field === "totalGrossPrice" && sorting.order,
            render: (expense: Expense) => {
                const isSub = expense.kind === ExpenseKindEnum.SUBSCRIPTION
                const interval = isSub ? getPriceIntervalForBillingPeriod(expense.billingPeriod) : PriceIntervalEnum.ONE_TIME
                return <PriceLabel value={expense.totalGrossPrice} currency={expense.currency} interval={interval} />
            },
        },
        {
            key: "probability",
            title: t("label:probability_short"),
            ellipsis: true,
            width: 80,
            sorter: true,
            sortOrder: sorting.field === "probability" && sorting.order,
            render: (expense: Expense) => <span>{roundNumberTo2Decimals(expense?.probability ?? 0)}%</span>,
        },
        {
            key: "isMatched",
            title: t("label:matching.match_status"),
            ellipsis: true,
            width: 150,
            align: "right",
            sortOrder: sorting.field === "isMatched" && sorting.order,
            render: (expense: Expense) => (
                <Button
                    loading={expense?.id === loadingId}
                    onClick={(e) => {
                        e.stopPropagation()
                        onMatch(expense?.id)
                    }}
                    className={`min-w-80 btn-highlight-green`}
                >
                    {t("action:matching.match")}
                </Button>
            ),
        },
    ]

    return (
        <div>
            <div className="flex flex-wrap gap-10 justify-between items-center mb-16">
                <h2>{t("label:possible_expenses")}</h2>
                <div className="ml-auto flex flex-row space-x-10 mt-5">
                    <SearchInput onSearch={(searchValue) => onSearch.callback(searchValue)} value={searchValue} isLoading={loading} />
                    <Filter
                        table={TablesEnum.POSSIBLE_MATCHES}
                        isFilterModalShowing={isFilterModalShowing}
                        setIsFilterModalShowing={setIsFilterModalShowing}
                        onFilter={(data) => applyFilter(TablesEnum.POSSIBLE_MATCHES, data)}
                        options={{ creditors, costCenters, costCenters2, expenseAccounts, employees }}
                    />
                    <Button
                        className="btn-default"
                        onClick={() => setShouldHideAlreadyMatched(!shouldHideAlreadyMatched)}
                        icon={shouldHideAlreadyMatched ? <VisibleIcon /> : <InvisibleIcon />}
                    >
                        <span>{t(`action:matching.${shouldHideAlreadyMatched ? "show" : "hide"}_already_matched`)}</span>
                    </Button>
                    <Button
                        className="btn-default"
                        onClick={() => setShouldHideArchivedExpenses(!shouldHideArchivedExpenses)}
                        icon={shouldHideArchivedExpenses ? <VisibleIcon /> : <InvisibleIcon />}
                    >
                        <span>{t(`action:matching.${shouldHideArchivedExpenses ? "include" : "hide"}_archived_expenses`)}</span>
                    </Button>
                </div>
            </div>
            <div>
                {isInitialLoading || !expenses ? (
                    <Loading />
                ) : totalDocs === 0 ? (
                    <Card>
                        <FilterSection table={TablesEnum.POSSIBLE_MATCHES} options={{ creditors, costCenters, costCenters2, expenseAccounts, employees }} />
                        <NoSearchDataFound imageStyle={{ marginTop: "10px" }} />
                    </Card>
                ) : (
                    <div className="overflow-auto p-2">
                        <FilterSection table={TablesEnum.POSSIBLE_MATCHES} options={{ creditors, costCenters, costCenters2, expenseAccounts, employees }} />
                        <Table
                            rowKey={(expense: Expense) => expense?.id}
                            pagination={{
                                position: ["bottomRight"],
                                showSizeChanger: true,
                                current: currentPage,
                                pageSize: itemsPerPage,
                                total: totalDocs,
                                hideOnSinglePage: false,
                                pageSizeOptions: ["5", "10", "20", "50", "100"],
                            }}
                            columns={possibleMatchesColumns}
                            dataSource={expenses.map((e) => new Expense(e))}
                            onChange={onTableChange}
                            loading={isLoading}
                            onRow={(expense: Expense) => ({
                                onClick: () => {
                                    dispatch(setExpense(expense))
                                    history.push(`/expenses/${expense?.id}`)
                                },
                            })}
                        />
                    </div>
                )}
            </div>
        </div>
    )
}

export default PossibleMatches
