/* global SocketIOClient */
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"

import { useAuth } from "Shared/hooks/auth.hooks"
import SocketService from "Shared/services/socketSync/socket.service"
import { isTest } from "Shared/utils/helper.utils"

const SOCKET_NOTIFICATION_KEY = "SOCKET_CONNECT"

const SocketTracker = ({ onSocket }: { onSocket: (socket: SocketIOClient.Socket) => void }) => {
    const { t } = useTranslation()
    const location = useLocation()?.pathname

    // DO NOT DELETE: useState is async and new values are not dispatched in real time like the event we are receiving
    let isConnectionLost = false
    let isNotificationClosed = false

    const { userId } = useAuth()

    const handleSocketReconnection = async () => {
        isConnectionLost = false
        handleLostSocketConnection("ioState", true)
        await SocketService.reconnect({ refreshToken: false })
    }

    const onRetryClose = () => {
        if (isConnectionLost && !isNotificationClosed) {
            isConnectionLost = false
            isNotificationClosed = true
            handleLostSocketConnection("ioState", true, true)
        }
    }

    /**
     * Handle socket connection lost from server crash to normal socket disconnection
     * @param _state do not delete it contains the socket state:: not used but mandatory for the event call
     * @param isReconnecting refiring the notification with loading state
     */
    const handleLostSocketConnection = async (_state: any, isReconnecting = false, shouldReload = false) => {
        if (!isConnectionLost) {
            isConnectionLost = true
            !isReconnecting && !shouldReload && (await handleSocketReconnection())
        }
    }

    /**
     * handle reconnection notification
     */
    const handleSuccessfulReconnection = () => {
        if (isConnectionLost) {
            isConnectionLost = false
        }
    }

    useEffect(() => {
        if (!isTest) {
            const globalSocket = SocketService.connect({ loggedInUserId: userId, onError: handleLostSocketConnection, onSuccess: handleSuccessfulReconnection })
            onSocket(globalSocket)
        }

        return () => {
            SocketService.disconnect("LoggingOut")
        }
    }, [])

    useEffect(() => {
        SocketService.setLocation(location)
    }, [location])

    return <></>
}

export default React.memo(SocketTracker)
