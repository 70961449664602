import { Employee, ErrorCodeEnum, RightEnum } from "@finway-group/shared/lib/models"
import { FormInstance } from "antd/lib/form"
import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import TokenCodeForm from "Components/forms/tfauth/tokenCode.form"
import { CardEnrollmentStepsEnum } from "Components/modals/enrollCard.modal"
import { AuthzService, CorporateService, NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { updateEmployee } from "Shared/store/actions/employee/employeeActions"
import { ErrorInterface } from "Shared/utils/errorHandler.utils"
import { unsetApprovalFields } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

import MobileInputForm from "../mobileInput.form"

interface WeavrMobileVerificationFormInterface {
    workPhoneFormInstance: FormInstance
    employee: Employee
    currentStep: CardEnrollmentStepsEnum
    onCurrentStepChange?: (currentStep: CardEnrollmentStepsEnum) => void
    onSuccess?: () => void
    setIsLoading: (isLoading: boolean) => void
    disableFormSubmission: () => void
    isWeavrOTPRetryLimitReached: boolean
}
const WeavrMobileVerificationForm = ({
    workPhoneFormInstance,
    employee,
    currentStep,
    onSuccess,
    setIsLoading,
    onCurrentStepChange,
    disableFormSubmission,
    isWeavrOTPRetryLimitReached,
}: WeavrMobileVerificationFormInterface) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [errorMessage, setErrorMessage] = useStateIfMounted("")

    const handleHide = () => {
        setIsLoading?.(false)
        workPhoneFormInstance.resetFields()
        setErrorMessage("")
        onCurrentStepChange?.(CardEnrollmentStepsEnum.WORK_PHONE_INPUT_STEP)
    }

    const onMobileNumberEntered = async (mobileNumber: string) => {
        try {
            setIsLoading?.(true)

            // update user object
            if (employee.workPhone !== mobileNumber) {
                employee.workPhone = mobileNumber
                if (!AuthzService.isRightGrantedForLoggedInUser(RightEnum.EMPLOYEE_APPROVAL_DATA__ALL__UPDATE)) unsetApprovalFields(employee)
                await updateEmployee(employee.id, employee)(dispatch)
            }

            // user identification via weavr token in request
            await CorporateService.requestMobileVerificationCode()

            setErrorMessage("")
            onCurrentStepChange?.(CardEnrollmentStepsEnum.WORK_PHONE_VERIFICATION_CODE_STEP)
        } catch (error) {
            if (error.response?.data?.errorCode === ErrorCodeEnum.MOBILE_ALREADY_ENROLLED) {
                NotificationService.send(
                    NotificationTypeEnum.SUCCESS,
                    t("notification:corporate.mobile_already_verified.title"),
                    t("notification:corporate.mobile_already_verified.message"),
                )
                onSuccess?.()
                handleHide()
                return
            }

            if (error.response?.data?.error?.includes?.("Duplicate")) {
                setErrorMessage(t("label:phone_verification.duplicate_work_phone"))
                return
            }

            setErrorMessage(t("label:phone_verification.sms_request_error"))
        } finally {
            setIsLoading?.(false)
        }
    }

    const onMobileCodeVerification = async (code: string) => {
        setIsLoading?.(true)

        try {
            await CorporateService.verifyMobile(code)
            onSuccess?.()
            handleHide()
        } catch (error) {
            handleMobileCodeVerificationError(error)
        } finally {
            setIsLoading?.(false)
        }
    }

    const handleMobileCodeVerificationError = (error: ErrorInterface | any) => {
        const shouldHandleErrorByCode = error.response?.data?.error && error.response?.data?.errorCode

        if (shouldHandleErrorByCode) {
            setErrorMessage(error.response?.data?.error)
            if (error.response.data.errorCode === ErrorCodeEnum.WEAVR_CHALLENGE_LIMIT_EXCEEDED) disableFormSubmission()

            return
        }

        setErrorMessage(t("label:phone_verification.invalid_code"))
    }

    return (
        <>
            {currentStep === CardEnrollmentStepsEnum.WORK_PHONE_INPUT_STEP && (
                <MobileInputForm onSubmit={onMobileNumberEntered} setErrorMessage={setErrorMessage} formInstance={workPhoneFormInstance} preSetPhoneNumber={employee?.workPhone} />
            )}

            {currentStep === CardEnrollmentStepsEnum.WORK_PHONE_VERIFICATION_CODE_STEP && (
                <>
                    <p className="mb-10 font-medium items-center primary-placeholder">{t("label:phone_verification.code_description")}</p>
                    <TokenCodeForm isWeavrOTPRetryLimitReached={isWeavrOTPRetryLimitReached} isLoginForm={false} onSubmit={onMobileCodeVerification} />
                </>
            )}

            {errorMessage && errorMessage.length > 0 && <span className="mt-10 text-error">{errorMessage}</span>}
        </>
    )
}

export default React.memo(WeavrMobileVerificationForm)
