import { Col, Row } from "antd"
import React from "react"
import { isMobile } from "react-device-detect"

import { CI_BUILD_TIME, CI_COMMIT, REACT_APP_SHOW_INTERNAL_VERSION } from "Shared/config/consts"

import AuthFooter from "./components/auth.footer"
import LoginLogo from "./components/login.logo"

export default function AuthLayout({ children }: { children: React.ReactNode }) {
    const scrollTo = (ref: HTMLDivElement) => {
        if (isMobile && ref) {
            ref.scrollIntoView({ behavior: "smooth", block: "start" })
        }
    }

    return (
        <>
            <div className="login-bg flex flex-col items-center justify-center min-h-screen h-full w-full overflow-hidden relative">
                <Row className="w-full p-10 z-50 mb-0 xs:mb-30 my-auto" ref={scrollTo}>
                    <Col span={24} className="max-w-400 min-w-300 my-0 mx-auto overflow-x-hidden overflow-y-hidden">
                        <LoginLogo />
                        <div className="bg-white w-full rounded-lg shadow mt-32 md:mt-40 pt-16">
                            <div className="p-32">{children}</div>
                        </div>
                    </Col>
                </Row>
                <Row className="w-full p-10 z-50 bottom-0 mb-0 sticky mt-auto">
                    {REACT_APP_SHOW_INTERNAL_VERSION && (
                        <Col span={24}>
                            <p>{`build ${CI_BUILD_TIME}`}</p>
                            <p>{`commit ${CI_COMMIT}`}</p>
                        </Col>
                    )}
                    <Col span={2} className="my-0">
                        <p className="meta-long text-white text-sm sm:mr-0" style={{ whiteSpace: "nowrap" }}>
                            {`version ${require("../../../package.json").version}`}
                        </p>
                    </Col>
                    <Col span={20} className="max-w-450 min-w-300 my-0 mx-auto">
                        <AuthFooter />
                    </Col>
                    {/* just to help center the middle column context */}
                    <Col span={2}></Col>
                </Row>
            </div>
        </>
    )
}
