import { CostCenter2 } from "@finway-group/shared/lib/models"
import { Form, Modal } from "antd"
import { Store } from "antd/lib/form/interface"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import CostCenters2Form from "Components/forms/costCenters2.form"
import { NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { createCostCenters2, updateCostCenters2 } from "Shared/store/actions/costCenter2/costCenter2Actions"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

interface CostCenters2ModalProps {
    isShowing: boolean
    costCenters2: Array<CostCenter2>
    isEdit?: boolean
    onCancel: () => void
}

const CostCenters2Modal = ({ costCenters2, isShowing, onCancel, isEdit = true }: CostCenters2ModalProps) => {
    const { t } = useTranslation()
    const [formInstance] = Form.useForm()
    const [isLoading, setIsLoading] = useStateIfMounted(false)

    const dispatch = useDispatch()

    useEffect(() => {
        if (isShowing) formInstance.setFieldsValue({ costCenters2, isEdit })
    }, [isShowing])

    const onSubmit = async (values: Store) => {
        try {
            const count = values.costCenters2.length
            const msgKey = isEdit ? "updated" : "saved"

            setIsLoading(true)

            if (isEdit) {
                await dispatch(updateCostCenters2(values.costCenters2))
            } else {
                await dispatch(createCostCenters2(values.costCenters2))
            }

            NotificationService.send(
                NotificationTypeEnum.SUCCESS,
                t(`notification:settings.cost_center_2.${msgKey}.title`),
                t(`notification:settings.cost_center_2.${msgKey}.message`, { count }),
            )

            onHide()
        } catch (err) {
            setIsLoading(false)
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:cost_center_2.update.title"))
        }
    }

    const onHide = () => {
        setIsLoading(false)
        onCancel()
    }

    return (
        <Modal
            title={t("action:cost_center_2_settings.cost_center_2")}
            visible={isShowing}
            maskClosable={false}
            onOk={formInstance.submit}
            onCancel={onHide}
            okText={t("action:cost_center_2_settings.save")}
            cancelText={t("action:cancel")}
            confirmLoading={isLoading}
            keyboard
            destroyOnClose
        >
            <CostCenters2Form formInstance={formInstance} onSubmit={onSubmit} isEdit={isEdit} />
        </Modal>
    )
}

export default CostCenters2Modal
