import { CalendarOutlined } from "@ant-design/icons"
import { BudgetPeriodEnum } from "@finway-group/shared/lib/models"
import { Button, Col, DatePicker, Form, Popover, Radio, Row } from "antd"
import moment from "moment"
import React, { memo, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { CostCenterDateRangePickerRules } from "Components/forms/rules/costCenterDateRangePicker.rules"
import { breakpoints } from "Shared/utils/breakpoints.utils"
import { getBudgetPeriodString, getDateRangeObject } from "Shared/utils/costCenter.utils"
import { useResizeWindowWidth } from "Shared/utils/hooks/useResizeWindowWidth"
import useUpdateEffect from "Shared/utils/hooks/useUpdateEffect"
import { getPopupAnchor } from "Shared/utils/popup.utils"

import { PeriodRangeOptionEnum } from "./costCenterPeriodOption.interface"

interface CostCenterDateRangePickerInterface {
    startDate: string
    endDate: string
    periodRangeOption: PeriodRangeOptionEnum
    viewPeriod: BudgetPeriodEnum
    onSubmit: (data: any) => void
}

const CostCenterDateRangePicker: React.FC<CostCenterDateRangePickerInterface> = ({ startDate, endDate, periodRangeOption, viewPeriod, onSubmit }) => {
    const { t } = useTranslation()
    const [formInstance] = Form.useForm()
    const windowWidth = useResizeWindowWidth()
    const isOnSmallScreens = windowWidth < breakpoints.md
    const isOnMobile = windowWidth < breakpoints.xs
    const rules = CostCenterDateRangePickerRules(formInstance, isOnMobile)
    const defaultPeriodOption = isOnMobile ? PeriodRangeOptionEnum.LAST_6_PERIODS : PeriodRangeOptionEnum.LAST_12_PERIODS

    const [showDatePicker, setShowDatePicker] = useState<boolean>(false)
    const [selectedPeriodRangeOption, setSelectedPeriodRangeOption] = useState<PeriodRangeOptionEnum>(periodRangeOption)
    const [selectedViewPeriod, setSelectedViewPeriod] = useState<moment.unitOfTime.DurationConstructor>(getBudgetPeriodString(viewPeriod))

    const handleFinish = (formValues: any) => {
        setShowDatePicker(false)
        onSubmit({ ...formValues, periodRangeOption: selectedPeriodRangeOption })
    }

    const handleSubmit = () => {
        formInstance.submit()
    }

    const handleSelectStartDate = (date: any) => {
        formInstance.setFieldsValue({ startDate: date })
        setSelectedPeriodRangeOption(PeriodRangeOptionEnum.CUSTOM_PERIOD)
    }

    const handleSelectEndDate = (date: any) => {
        formInstance.setFieldsValue({ endDate: date })
        setSelectedPeriodRangeOption(PeriodRangeOptionEnum.CUSTOM_PERIOD)
    }

    const setDates = (selectedViewPeriod: moment.unitOfTime.DurationConstructor, periodOption: PeriodRangeOptionEnum = defaultPeriodOption) => {
        formInstance.setFieldsValue(getDateRangeObject(periodOption, selectedViewPeriod))
    }

    const handleSelectPeriodOption = (value: PeriodRangeOptionEnum) => {
        setSelectedPeriodRangeOption(value)
        setDates(selectedViewPeriod, value)
    }

    const handleSelectViewPeriodOption = (value: BudgetPeriodEnum) => {
        formInstance.setFieldsValue({ viewPeriod: value })
        setSelectedViewPeriod(getBudgetPeriodString(value))
    }

    useEffect(() => {
        formInstance.setFieldsValue({
            startDate: moment(startDate, moment.ISO_8601),
            endDate: moment(endDate, moment.ISO_8601),
            periodRangeOption,
            viewPeriod,
        })
    }, [])

    // change popover form values when user changes budgetPeriod on cost center form
    useEffect(() => {
        setSelectedViewPeriod(getBudgetPeriodString(viewPeriod))
        setDates(getBudgetPeriodString(viewPeriod), selectedPeriodRangeOption)
        formInstance.setFieldsValue({ viewPeriod, periodRangeOption: selectedPeriodRangeOption || defaultPeriodOption })
    }, [viewPeriod])

    // update dates when a different view period option is selected
    useUpdateEffect(() => {
        setDates(selectedViewPeriod, selectedPeriodRangeOption)
    }, [selectedViewPeriod])

    return (
        <Popover
            id="dateRangePickerPopover"
            trigger="click"
            placement="bottomRight"
            visible={showDatePicker}
            onVisibleChange={(visible) => setShowDatePicker(visible)}
            content={
                <Form form={formInstance} layout="vertical" onFinish={handleFinish}>
                    <Row gutter={[16, 20]}>
                        <Col span={12}>
                            <Form.Item
                                label={<label style={{ opacity: "50%" }}>{t("label:select_dates")}</label>}
                                name="startDate"
                                className="mb-0 text-opacity-50"
                                rules={rules.startDate}
                            >
                                <DatePicker
                                    name="startDate"
                                    style={{ width: "100%" }}
                                    format={moment.localeData().longDateFormat("L")}
                                    placeholder={t("placeholder:start_date")}
                                    onChange={handleSelectStartDate}
                                    getPopupContainer={getPopupAnchor()}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={" "} name="endDate" className="mb-0" rules={rules.endDate}>
                                <DatePicker
                                    name="endDate"
                                    style={{ width: "100%" }}
                                    format={moment.localeData().longDateFormat("L")}
                                    placeholder={t("placeholder:end_date")}
                                    onChange={handleSelectEndDate}
                                    getPopupContainer={getPopupAnchor()}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[4, 20]} className="text-center">
                        <Col span={24}>
                            <Form.Item label={<label style={{ opacity: "50%" }}>{t("label:last_&_upcoming_months")}</label>} className="mb-1">
                                <Radio.Group className={" w-300 sm:w-400"} value={selectedPeriodRangeOption} onChange={(e) => handleSelectPeriodOption(e.target.value)}>
                                    {isOnMobile ? (
                                        <>
                                            <Radio.Button className="w-1/3 text-xs sm:text-base px-5 " value={PeriodRangeOptionEnum.LAST_6_PERIODS}>
                                                {`6 ${t(`label:${selectedViewPeriod}s`)}`}
                                            </Radio.Button>
                                            <Radio.Button className="w-1/3 text-xs sm:text-base px-5" value={PeriodRangeOptionEnum.LAST_4_AND_NEXT_2_PERIODS}>
                                                {`4 + 2 ${t(`label:${selectedViewPeriod}s`)}`}
                                            </Radio.Button>
                                            <Radio.Button className="w-1/3 text-xs sm:text-base px-5" value={PeriodRangeOptionEnum.LAST_3_AND_NEXT_3_PERIODS}>
                                                {`3 + 3 ${t(`label:${selectedViewPeriod}s`)}`}
                                            </Radio.Button>
                                        </>
                                    ) : (
                                        <>
                                            <Radio.Button className="w-1/3 text-xs sm:text-base px-5" value={PeriodRangeOptionEnum.LAST_12_PERIODS}>
                                                {`12 ${t(`label:${selectedViewPeriod}s`)}`}
                                            </Radio.Button>
                                            <Radio.Button className="w-1/3 text-xs sm:text-base px-5" value={PeriodRangeOptionEnum.LAST_9_AND_NEXT_3_PERIODS}>
                                                {`9 + 3 ${t(`label:${selectedViewPeriod}s`)}`}
                                            </Radio.Button>
                                            <Radio.Button className="w-1/3 text-xs sm:text-base px-5" value={PeriodRangeOptionEnum.LAST_6_AND_NEXT_6_PERIODS}>
                                                {`6 + 6 ${t(`label:${selectedViewPeriod}s`)}`}
                                            </Radio.Button>
                                        </>
                                    )}
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[4, 20]} className="text-center">
                        <Col span={24}>
                            <Form.Item name="viewPeriod" label={<label style={{ opacity: "50%" }}>{t("label:viewing_period")}</label>} className="mb-1">
                                <Radio.Group className={" w-300 sm:w-400"} onChange={(e) => handleSelectViewPeriodOption(e.target.value)}>
                                    <Radio.Button className={"w-1/3 text-xs sm:text-base px-5"} value={BudgetPeriodEnum.MONTHLY}>
                                        {isOnSmallScreens ? t("label:monthly") : t("label:monthly_view")}
                                    </Radio.Button>
                                    <Radio.Button className={"w-1/3 text-xs sm:text-base px-5"} value={BudgetPeriodEnum.QUARTERLY}>
                                        {isOnSmallScreens ? t("label:quarterly") : t("label:quarterly_view")}
                                    </Radio.Button>
                                    <Radio.Button className={"w-1/3 text-xs sm:text-base px-5"} value={BudgetPeriodEnum.YEARLY}>
                                        {isOnSmallScreens ? t("label:yearly") : t("label:yearly_view")}
                                    </Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[4, 4]}>
                        <Col span={24} className="text-right">
                            <Button key="submit" type="primary" onClick={handleSubmit}>
                                {t("action:show")}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            }
        >
            <Button className="m-5" onClick={() => setShowDatePicker(true)}>
                <CalendarOutlined /> {t("action:period")} <i className="el-icon-caret-bottom el-icon--right" />
            </Button>
        </Popover>
    )
}

export default memo(CostCenterDateRangePicker)
