import { COLOR } from "@finway-group/shared/lib/consts"
import { Employee, SystemRightEnum, User } from "@finway-group/shared/lib/models"
import UserCompanyProfileInterface from "@finway-group/shared/lib/models/user/userCompanyProfileInterface"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { Badge, Button, Table, Tooltip } from "antd"
import React, { useEffect } from "react"
import { Edit as EditIcon, Plus as PlusIcon, Trash as TrashIcon } from "react-feather"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import { ConditionalWrapper } from "Components/conditionalWrapper"
import Icon from "Components/icon"
import CornerDownRightArrowIcon from "Components/icons/cornerDownRightArrow"
import DownCircleIcon from "Components/icons/downCircleIcon"
import UpCircleIcon from "Components/icons/upCircleIcon"
import Loading from "Components/loading"
import { DeleteOrDemoteEmployeeModal, DeleteOrDemoteEmployeeModalProps } from "Components/modals/deleteEmployee.modal"
import { UserModal } from "Components/modals/user.modal"
import { UserCompanyProfileModal } from "Components/modals/userCompanyProfile.modal"
import SystemAdminTag from "Components/systemAdminTag"
import { useModal } from "Shared/context/modal.context"
import { useAuth } from "Shared/hooks/auth.hooks"
import { useCompanies } from "Shared/hooks/company.hooks"
import { useRolesMap } from "Shared/hooks/role.hooks"
import { QueryKeyEnum } from "Shared/reactQuery.types"
import { AuthzService, NotificationService, UserService } from "Shared/services"
import DialogService from "Shared/services/dialog.service"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { fetchAllCompanies } from "Shared/store/actions/company/companyActions"
import { syncEmployee } from "Shared/store/actions/employee/employeeActions"
import { isSysAdminRightsRevocationDenied } from "Shared/utils/employee.utils"
import { getUserRoleName } from "Shared/utils/getter.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

interface UserListInterface {
    searchValue: string
}

const UsersList: React.FC<UserListInterface> = ({ searchValue }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const rolesMap = useRolesMap()
    const queryClient = useQueryClient()

    const hasLoggedInUserUserUpdateRights = AuthzService.isSystemRightGrantedForLoggedInUser(SystemRightEnum.SYS__USER__ALL__UPDATE)

    const [isUsersLoading, setIsUsersLoading] = useStateIfMounted(false)

    const { data: allUsers, isLoading } = useQuery({
        queryFn: () => fetchAllUsers(),
        queryKey: [QueryKeyEnum.USERS],
        initialData: { docs: [] as Array<User>, totalPages: 0, totalDocs: 0, page: 1 },
    })

    // TODO: delete once reactQuery is set up for all users actions (currently only set up for fetch all)
    useEffect(() => {
        setIsUsersLoading(isLoading)
    }, [isLoading])

    const [visibleUsers, setVisibleUsers] = useStateIfMounted<Array<User>>([])

    const [sysAdminsCount, setSysAdminsCount] = useStateIfMounted(0)

    const { userId } = useAuth()
    const { showModal, hideModal } = useModal()
    const [pagination, setPagination] = useStateIfMounted({ page: 1, pageSize: 10 })
    const companies = useCompanies()

    useEffect(() => {
        searchUsers()
    }, [allUsers, searchValue])

    /**
     * fetches all users similar to how we do for employees
     */

    const fetchAllUsers = async () => {
        const data = await UserService.fetch({ page: 1, limit: 10000 })
        data.docs.forEach((employeeUtils) => {
            employeeUtils.companyProfiles = (employeeUtils.companyProfiles ?? [])
                .filter((company: any) => !company.company.deleted)
                .map((company: UserCompanyProfileInterface & { id: string }) => ({
                    ...company,
                    id: employeeUtils.id, // used for row key
                }))
        })
        const systemAdminCount = data.docs.filter((user) => user.hasSystemRights).length
        setSysAdminsCount(systemAdminCount)
        return data
    }

    const searchUsers = async () => {
        try {
            setIsUsersLoading(true)

            const filteredUsers = allUsers.docs.filter(
                (user) => user.name.toLowerCase().includes(searchValue.toLowerCase()) || user.email.toLowerCase().includes(searchValue.toLowerCase()),
            )

            setVisibleUsers(filteredUsers)
        } finally {
            setIsUsersLoading(false)
        }
    }

    const onUpdateUser = async (user: User) => {
        const employee = new Employee(user)
        showModal(UserModal, true, {
            isShowing: true,
            isNew: false,
            employee,
            sysAdminsCount,
        })
    }

    const onCreateUserCompanyProfile = async (user: User) => {
        showModal(UserCompanyProfileModal, true, {
            user,
            companyProfile: {},
            isShowing: true,
            isNewCompanyProfile: true,
        })
    }

    const onUpdateUserCompanyProfile = async (user: User, companyProfile: any) => {
        delete companyProfile.user
        showModal(UserCompanyProfileModal, true, {
            user,
            companyProfile,
            isShowing: true,
            isNewCompanyProfile: false,
        })
    }

    const onDeleteUserCompanyProfile = async (user: User, companyId: string) => {
        showModal(DeleteOrDemoteEmployeeModal, true, {
            isShowing: true,
            operationType: "delete",
            onSubmit: (_employee, workflowsReplacementApproverId) => {
                doDeleteUserCompanyProfile(user.id, companyId, workflowsReplacementApproverId)
            },
            user,
            companyId,
        } as DeleteOrDemoteEmployeeModalProps)
    }

    const doDeleteUserCompanyProfile = async (userIdToDelete: string, companyId: string, workflowsReplacementApproverId: string) => {
        try {
            setIsUsersLoading(true)
            await UserService.deleteCompanyProfile(userIdToDelete, companyId, workflowsReplacementApproverId)
            NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:company_profile.deleted.title"), t("notification:company_profile.deleted.message"))
            queryClient.invalidateQueries([QueryKeyEnum.USERS])
            hideModal(DeleteOrDemoteEmployeeModal, true, {})
            await fetchAllUsers()
            dispatch(syncEmployee(userIdToDelete))

            // Fetching the company is needed because removing a user might have a side effect on the company settings e.g. fallback approver.
            dispatch(fetchAllCompanies(false))
        } catch (err) {
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:error"))
        } finally {
            setIsUsersLoading(false)
        }
    }

    const onRestoreUserCompanyProfile = async (user: User, companyProfile: UserCompanyProfileInterface) => {
        if (!user || !(await DialogService.confirmRestoreRole())) return
        try {
            companyProfile.deleted = false
            setIsUsersLoading(true)
            await UserService.updateCompanyProfile(user.id, companyProfile.companyId, companyProfile)
            NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:company_profile.restored.title"), t("notification:company_profile.restored.message"))
            await fetchAllUsers()
            dispatch(syncEmployee(user.id))
        } catch (err) {
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:error"))
        } finally {
            setIsUsersLoading(false)
        }
    }

    if (isUsersLoading) return <Loading />

    return (
        <div className="overflow-auto animation-appear">
            <Table<User | UserCompanyProfileInterface>
                rowKey={(record: any) => `${record.id}_${record.companyId}`}
                pagination={{
                    onChange: (page, pageSize) => {
                        setPagination({ page, pageSize: pageSize ?? 10 })
                    },
                    pageSize: pagination.pageSize,
                    position: ["bottomRight"],
                    hideOnSinglePage: false,
                    showSizeChanger: true,
                    pageSizeOptions: ["5", "10", "20", "50", "100"],
                }}
                dataSource={visibleUsers}
                expandRowByClick
                columns={[
                    {
                        dataIndex: "expandIcon",
                        align: "center",
                        width: 50,
                    },
                    {
                        title: t("label:user"),
                        className: "pl-10",
                        width: "30%",
                        ellipsis: true,
                        render: (employee: Employee) => (
                            <>
                                <div className="flex flex-col md:flex-row">
                                    <p className="font-bold truncate">
                                        {employee.firstName} {employee.lastName}
                                    </p>
                                    <div className="md:ml-5">{AuthzService.hasEmployeeSystemRight(employee) && <SystemAdminTag />}</div>
                                </div>
                                <p className="truncate">{employee.email}</p>
                            </>
                        ),
                    },
                    {
                        title: t("label:companies"),
                        className: "pl-10",
                        ellipsis: true,
                        width: "auto",
                        render: (cell: User | UserCompanyProfileInterface) => {
                            if (!(cell instanceof User)) return <>{(cell as any).company.name}</>
                            return (
                                <Badge
                                    className="inline-block"
                                    style={{ backgroundColor: COLOR.gray[200], color: COLOR.text }}
                                    showZero={true}
                                    count={cell.companyProfiles?.filter((profile) => !profile.deleted).length ?? 0}
                                />
                            )
                        },
                    },
                    {
                        title: t("label:roles.title"),
                        className: "pl-10",
                        ellipsis: true,
                        width: "auto",
                        render: (cell: User | UserCompanyProfileInterface) => {
                            if (!(cell instanceof User)) return <>{getUserRoleName(rolesMap.get(cell.roleId))}</>
                            return (
                                <Badge
                                    className="inline-block text-text"
                                    style={{ backgroundColor: COLOR.gray[200], color: COLOR.text }}
                                    count={new Set(cell.companyProfiles?.filter((profile) => !profile.deleted).map(({ roleId }) => roleId)).size}
                                />
                            )
                        },
                    },
                    {
                        title: t("label:action"),
                        className: "pl-10",
                        ellipsis: false,
                        width: 100,
                        align: "right",
                        render: (cell: User | UserCompanyProfileInterface) => {
                            if (!(cell instanceof User)) {
                                const userCell = cell as unknown as User
                                const user = visibleUsers.find((user) => user.id === userCell.id) as User
                                return (
                                    <div key="button_wrapper" className={`flex justify-end space-x-10 ${userId === user.id && "invisible"}`}>
                                        {!cell.deleted && hasLoggedInUserUserUpdateRights && (
                                            <Button
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    onUpdateUserCompanyProfile(user, cell)
                                                }}
                                            >
                                                <EditIcon />
                                            </Button>
                                        )}
                                        {!cell.deleted && AuthzService.isSystemRightGrantedForLoggedInUser(SystemRightEnum.SYS__USER__ALL__DELETE) && (
                                            <ConditionalWrapper
                                                condition={isSysAdminRightsRevocationDenied({ sysAdminsCount, user, isDeletion: true })}
                                                wrapper={(children: React.ReactNode) => (
                                                    <Tooltip title={<div>{t("error:sys_admin_user_cannot_be_deleted")}</div>} placement="bottom">
                                                        {children}
                                                    </Tooltip>
                                                )}
                                            >
                                                <Button
                                                    disabled={isSysAdminRightsRevocationDenied({ sysAdminsCount, user, isDeletion: true })}
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        onDeleteUserCompanyProfile(user, cell.companyId)
                                                    }}
                                                >
                                                    <TrashIcon />
                                                </Button>
                                            </ConditionalWrapper>
                                        )}
                                        {cell.deleted && hasLoggedInUserUserUpdateRights && (
                                            <Tooltip title={<div>{t("confirm:employee.restore.title")}</div>} placement="bottom">
                                                <Button
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        onRestoreUserCompanyProfile(user, cell)
                                                    }}
                                                >
                                                    <Icon color={COLOR.text} icon="restore" />
                                                </Button>
                                            </Tooltip>
                                        )}
                                    </div>
                                )
                            }
                            return (
                                <div key="button_wrapper" className={`flex justify-end space-x-10 ${userId === cell.id ? "invisible" : ""}`}>
                                    {hasLoggedInUserUserUpdateRights && (
                                        <Button
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                onUpdateUser(cell)
                                            }}
                                        >
                                            <EditIcon />
                                        </Button>
                                    )}
                                    {hasLoggedInUserUserUpdateRights && cell.companyProfiles?.length !== companies.length && (
                                        <Button
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                onCreateUserCompanyProfile(cell)
                                            }}
                                        >
                                            <PlusIcon />
                                        </Button>
                                    )}
                                </div>
                            )
                        },
                    },
                ]}
                expandable={{
                    childrenColumnName: "companyProfiles",
                    rowExpandable: () => true,
                    expandIcon: ({ expanded, record }) => {
                        if (!(record instanceof User)) return <CornerDownRightArrowIcon />
                        if (record.companyProfiles?.length === 0) return <></>
                        return <div role="button">{expanded ? <UpCircleIcon /> : <DownCircleIcon />}</div>
                    },
                }}
            />
        </div>
    )
}

export default UsersList
