import { Expense } from "@finway-group/shared/lib/models"
import React from "react"
import { useTranslation } from "react-i18next"

interface SimpleExpenseListInterface {
    expenses: Array<Expense>
}

export const SimpleExpenseList = ({ expenses }: SimpleExpenseListInterface) => {
    const { t } = useTranslation()

    return (
        <ul className="list-none my-20">
            {expenses.map((expense) => (
                <li>
                    {t("label:request")} #{expense.expenseNumber}
                </li>
            ))}
        </ul>
    )
}
