import { DocumentChangeInterface, OperationTypeEnum } from "@finway-group/shared/lib/models"
import * as Sentry from "@sentry/react"

import { fetchAllCostCenters2 } from "Shared/store/actions/costCenter2/costCenter2Actions"
import { isClientErrorCode } from "Shared/utils/errorHandler.utils"

const subscribeToCostCenter2 = (dispatch: any, data: DocumentChangeInterface) => {
    const {
        fullDocument: { operationType },
    } = data

    try {
        if (
            operationType === OperationTypeEnum.INSERT ||
            operationType === OperationTypeEnum.UPDATE ||
            operationType === OperationTypeEnum.DELETE ||
            operationType === OperationTypeEnum.BULK_UPDATE
        ) {
            dispatch(fetchAllCostCenters2())
        }
    } catch (err) {
        if (isClientErrorCode(err)) return
        Sentry.captureException(`[Cost Center 2 Socket] Error: ${typeof err === "string" ? err : JSON.stringify(err)}`)
    }
}

export default subscribeToCostCenter2
