import { Card, Col, Row } from "antd"
import React from "react"

import TokenCodeForm from "Components/forms/tfauth/tokenCode.form"

interface TfAuthContainerInterface {
    tokenValidation: (token: string) => void
}
const TfAuthContainer: React.FC<TfAuthContainerInterface> = ({ tokenValidation }) => (
    <div className="login-bg flex flex-col items-center justify-center min-h-screen h-full w-full">
        <Row className="w-full p-10 z-50 my-auto">
            <Col span={24} className="max-w-half min-w-300 min-h-450 my-0 mx-auto overflow-x-hidden overflow-y-hidden">
                <Card title="Two-Factor Authentication" className="my-0 mx-auto shadow">
                    <div className="p-10">
                        <TokenCodeForm onSubmit={tokenValidation} isLoginForm />
                    </div>
                </Card>
            </Col>
        </Row>
    </div>
)

export default TfAuthContainer
