import { UploadOutlined } from "@ant-design/icons"
import { COLOR } from "@finway-group/shared/lib/consts"
import { DEFAULT_COMPANY_TIMEZONE, supportedImageInvoiceFormats } from "@finway-group/shared/lib/consts/common"
import { Company, UploadKindEnum } from "@finway-group/shared/lib/models"
import { UserLanguageEnum } from "@finway-group/shared/lib/models/user/userSettings.interface"
import { Alert, Button, Checkbox, Col, Input, Row, Select, Tooltip, Upload } from "antd"
import Form, { FormInstance } from "antd/lib/form"
import { Store } from "antd/lib/form/interface"
import { RcCustomRequestOptions, RcFile } from "antd/lib/upload/interface"
import moment from "moment-timezone"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"

import { ConditionalWrapper } from "Components/conditionalWrapper"
import IbanInput from "Components/ibanInput"
import { API_URL } from "Shared/config/consts"
import { useAuth } from "Shared/hooks/auth.hooks"
import { useLoggedInEmployee } from "Shared/hooks/employee.hooks"
import { FileService } from "Shared/services"
import { doFilterSelect, getIBANPrintFormat, getTooltipPopupContainer } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"
import { getPopupAnchor } from "Shared/utils/popup.utils"

import CompanySettingsRules from "./rules/companySettings.rules"

interface CompanySettingsFormInterface {
    isNew: boolean
    formInstance: FormInstance
    company: Company
    onSubmit: (values: Store) => void
}

const CompanySettingsForm = ({ formInstance, company, onSubmit, isNew }: CompanySettingsFormInterface) => {
    const { t } = useTranslation()
    const rules = CompanySettingsRules()
    const [deliverySameAsBusinessAddress, setDeliverySameAsBusinessAddress] = useStateIfMounted<boolean>(company.deliverySameAsBusinessAddress)
    const [fileList, setFileList] = useStateIfMounted<any>([])
    const { language } = useLoggedInEmployee().settings
    const { isFinwayAdmin } = useAuth()

    const isTimeZoneChangeDisabled = !isFinwayAdmin && !isNew
    const showTimeZoneAlert = isNew && !isFinwayAdmin

    useEffect(() => {
        let isMounted = true

        if (company) {
            formInstance.setFieldsValue({
                ...company,
                iban: company.iban ? getIBANPrintFormat(company.iban) : ``,
                businessAddressAddressLine: company.businessAddress?.addressLine2,
                businessAddressCity: company.businessAddress?.city,
                businessAddressCountry: company.businessAddress?.country,
                businessAddressZip: company.businessAddress?.zip,
                deliveryAddressAddressLine: company.deliveryAddress?.addressLine2,
                deliveryAddressCity: company.deliveryAddress?.city,
                deliveryAddressCountry: company.deliveryAddress?.country,
                deliveryAddressZip: company.deliveryAddress?.zip,
                deliverySameAsBusinessAddress: company.deliverySameAsBusinessAddress,
            })
        }

        if (company.logo && company.logo.length) {
            const currentFileList = [
                {
                    uid: "1",
                    name: company.name,
                    status: "done",
                    thumbUrl: company.logo,
                    type: "image/png",
                },
            ]
            setFileList(currentFileList)
        }
        if (isNew && isMounted) {
            formInstance.setFieldsValue({ timeZone: DEFAULT_COMPANY_TIMEZONE })
        }

        return () => {
            formInstance.resetFields()
            isMounted = false
        }
    }, [])

    // TODO: Move this to consts as it is used on multiple places
    const doFileUpload = async (options: RcCustomRequestOptions) => {
        const { onSuccess, onError, onProgress, file } = options
        if (!FileService.checkFileSizeAndHandleError(file)) return

        try {
            const response = await FileService.upload(file, UploadKindEnum.COMPANY_IMG, onProgress)
            onSuccess(response, response.url as unknown as RcFile)
        } catch (err) {
            onError(err)
        }
    }

    const onFinish = (values: Store) =>
        onSubmit({
            ...values,
            deliverySameAsBusinessAddress,
        })

    return (
        <Form form={formInstance} layout="vertical" onFinish={onFinish} autoComplete="off">
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Form.Item label={t("input:company_settings.logo")} name="logo">
                        <Upload
                            action={`${API_URL}/files`}
                            accept={supportedImageInvoiceFormats.join(",")}
                            headers={{ Authorization: `Bearer ${localStorage.accessToken}` }}
                            listType="picture"
                            fileList={fileList}
                            onRemove={() => setFileList([])}
                            onPreview={() => {}}
                            customRequest={doFileUpload}
                            onChange={(data) => setFileList(data.fileList)}
                            progress={{
                                strokeWidth: 3,
                                showInfo: false,
                                strokeColor: COLOR.secondary,
                                className: "pr-20",
                            }}
                        >
                            {fileList.length === 0 && <Button icon={<UploadOutlined />}>Upload</Button>}
                        </Upload>
                    </Form.Item>

                    <Form.Item label={t("input:company_settings.name")} name="name" rules={rules.name} required>
                        <Input data-testid="companyNameInputField" placeholder={t("placeholder:company_settings.name")} maxLength={100} />
                    </Form.Item>

                    <Form.Item label={t("input:company_settings.companyIdentifier")} name="companyIdentifier" rules={rules.companyIdentifier}>
                        <Input placeholder={t("placeholder:company_settings.companyIdentifier")} maxLength={30} />
                    </Form.Item>

                    <Form.Item label={t("input:company_settings.iban")} name="iban" rules={rules.iban}>
                        <IbanInput required />
                    </Form.Item>

                    <Form.Item label={t("input:company_settings.bic")} name="bic" rules={rules.bic}>
                        <Input placeholder={t("placeholder:company_settings.bic")} required maxLength={11} />
                    </Form.Item>

                    <Form.Item label={t("input:company_settings.default_creditor_number")} name="defaultCreditorNumber" rules={rules.defaultCreditorNumber}>
                        <Input placeholder={t("placeholder:company_settings.default_creditor_number")} maxLength={9} />
                    </Form.Item>

                    <Form.Item label={t("input:company_settings.default_employee_creditor_number")} name="defaultEmployeeCreditorNumber" rules={rules.defaultCreditorNumber}>
                        <Input placeholder={t("placeholder:company_settings.default_employee_creditor_number")} maxLength={9} />
                    </Form.Item>

                    <ConditionalWrapper
                        condition={isTimeZoneChangeDisabled}
                        wrapper={(children: any) => (
                            <Tooltip getPopupContainer={getTooltipPopupContainer} title={<div>{t("tooltips:company_settings.time_zone")}</div>} placement="bottom">
                                {children}
                            </Tooltip>
                        )}
                    >
                        <Form.Item label={t("input:company_settings.timezone")} name="timeZone" required className={showTimeZoneAlert ? `mb-5` : ``}>
                            <Select showSearch filterOption={doFilterSelect} getPopupContainer={getPopupAnchor()} disabled={isTimeZoneChangeDisabled}>
                                {moment.tz.names().map((name) => (
                                    <Select.Option key={name} label={name} value={name}>
                                        {name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </ConditionalWrapper>

                    {showTimeZoneAlert && <Alert className="mb-24" message={<span>{t("info:company_tz_change")}</span>} type="info" showIcon />}

                    <Form.Item label={t("input:company_settings.default_employee_language")} name="defaultEmployeeLanguage" initialValue={language}>
                        <Select getPopupContainer={getPopupAnchor()}>
                            {Object.values(UserLanguageEnum).map((lang) => (
                                <Select.Option value={lang} label={t(`enum:UserLanguageEnum.${lang}`)} key={lang}>
                                    {t(`enum:UserLanguageEnum.${lang}`)}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <h2 className="mt-20">{t("label:business_address")}</h2>

                    <Form.Item className="mt-20" label={t("input:company_settings.address_line")} name="businessAddressAddressLine" rules={rules.businessAddressAddressLine}>
                        <Input placeholder={t("placeholder:company_settings.address_line")} maxLength={100} />
                    </Form.Item>
                    <Form.Item label={t("input:company_settings.city")} name="businessAddressCity" rules={rules.city}>
                        <Input placeholder={t("placeholder:company_settings.city")} maxLength={20} />
                    </Form.Item>
                    <Form.Item label={t("input:company_settings.country")} name="businessAddressCountry" rules={rules.countryCode}>
                        <Input placeholder={t("placeholder:company_settings.country")} maxLength={2} />
                    </Form.Item>
                    <Form.Item label={t("input:company_settings.zip")} name="businessAddressZip" rules={rules.zip}>
                        <Input placeholder={t("placeholder:company_settings.zip")} maxLength={12} />
                    </Form.Item>

                    <h2 className="mt-20">{t("label:delivery_address")}</h2>

                    <Checkbox className="mt-20" checked={deliverySameAsBusinessAddress} onChange={(e) => setDeliverySameAsBusinessAddress(e.target.checked)}>
                        {t("input:company_settings.sameAsBusinessAddress")}
                    </Checkbox>

                    {!deliverySameAsBusinessAddress && (
                        <>
                            <Form.Item className="mt-40" label={t("input:company_settings.address_line")} name="deliveryAddressAddressLine">
                                <Input placeholder={t("placeholder:company_settings.address_line")} maxLength={100} />
                            </Form.Item>
                            <Form.Item label={t("input:company_settings.city")} name="deliveryAddressCity" rules={rules.city}>
                                <Input placeholder={t("placeholder:company_settings.city")} maxLength={20} />
                            </Form.Item>
                            <Form.Item label={t("input:company_settings.country")} name="deliveryAddressCountry" rules={rules.countryCode}>
                                <Input placeholder={t("placeholder:company_settings.country")} maxLength={2} />
                            </Form.Item>
                            <Form.Item label={t("input:company_settings.zip")} name="deliveryAddressZip" rules={rules.zip}>
                                <Input placeholder={t("placeholder:company_settings.zip")} maxLength={12} />
                            </Form.Item>
                        </>
                    )}
                </Col>
            </Row>
        </Form>
    )
}

export default CompanySettingsForm
