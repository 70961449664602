import { Workflow } from "@finway-group/shared/lib/models"
import { useEffect } from "react"
import { useSelector } from "react-redux"

import WorkflowService from "Shared/services/workflowService"
import store from "Shared/store"
import { RootState } from "Shared/store/rootState.interface"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

export const useWorkflows = (searchValue?: string) => {
    const workflows = useSelector(({ workflows: { items } }: RootState) => items)
    return filterWorkflows(workflows, searchValue)
}

export const getWorkflowsFromStore = (searchValue?: string) => {
    const workflows = store.getState().workflows.items
    return filterWorkflows(workflows, searchValue)
}

export const useWorkflowById = (id?: string | null, searchValue?: string) => {
    const [currentWorkflow, setCurrentWorkflow] = useStateIfMounted<Workflow | undefined>(undefined)
    const workflows = useWorkflows(searchValue)
    const foundWorkflow = workflows.find((workflow: Workflow) => workflow._id === id)

    const fetchWorkflow = async (id: string) => {
        const workflow = await WorkflowService.fetchOneWorkflow(id)
        setCurrentWorkflow(workflow)
    }

    useEffect(() => {
        if (!id || foundWorkflow) return
        fetchWorkflow(id)
    }, [id])

    if (!id) return undefined
    if (foundWorkflow) return foundWorkflow
    return currentWorkflow
}

export const getWorkflowById = (id?: string | null) => (id ? new Workflow(store.getState().workflows.items.find((workflow: any) => workflow._id === id)) : undefined)

const filterWorkflows = (workflows: Array<any>, searchValue: any): Array<Workflow> => {
    if (searchValue) {
        workflows = workflows?.filter((workflow: Workflow) => workflow.name.toLowerCase().includes(searchValue.toLowerCase()))
    }

    return workflows.map((workflow) => new Workflow(workflow)).sort((workflowA: any, workflowB: any) => workflowA.order - workflowB.order)
}

export const useWorkflow = () => useSelector(({ workflows: { item } }: RootState) => item)
