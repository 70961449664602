import { ChangeEvent, MouseEvent, useEffect, useState } from "react"

export type KeySelector<T> = (item: T) => string

export const useSearch = <T>(data: Array<T>, keySelector: KeySelector<T>) => {
    const [searchTerm, setSearchTerm] = useState("")
    const [searchResults, setSearchResults] = useState<Array<T>>([])

    useEffect(() => {
        const results = data.filter((item) => keySelector(item).toLowerCase().includes(searchTerm.toLowerCase()))
        setSearchResults(results)
    }, [searchTerm, data, keySelector])

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value)
    }

    const handleInputClick = (event: MouseEvent<HTMLInputElement>) => {
        event.stopPropagation()
    }

    return { searchTerm, searchResults, handleInputChange, handleInputClick }
}
