import { Tag } from "antd"
import { TagType } from "antd/lib/tag"
import React from "react"
import { useTranslation } from "react-i18next"

interface SystemAdminTagInterface extends Partial<TagType> {
    className?: string
}

const SystemAdminTag: React.FC<SystemAdminTagInterface> = (props: SystemAdminTagInterface) => {
    const { t } = useTranslation()
    const { className, ...rest } = props

    return (
        <Tag {...rest} className={`ant-tag-purple ${className}`}>
            {t("label:system_admin")}
        </Tag>
    )
}

export default SystemAdminTag
