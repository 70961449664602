import { useCallback, useEffect, useState } from "react"

import * as serviceWorkerRegistration from "../../serviceWorkerRegistration"

export const useServiceWorker = () => {
    const [waitingWorker, setWaitingWorker] = useState<ServiceWorker | null>(null)
    const [showReload, setShowReload] = useState<boolean>(false)

    // tell the service worker to skip the waiting phase
    // and reload page
    const reloadPage = useCallback(() => {
        waitingWorker?.postMessage({ type: "SKIP_WAITING" })
        setShowReload(false)
        window.location.reload()
    }, [waitingWorker])

    // called when a service worker updates
    // this function is a callback to the actual service worker registration.onUpdate()
    const onUpdateAvailable = useCallback((registration: ServiceWorkerRegistration) => {
        setShowReload(true)
        setWaitingWorker(registration.waiting)
    }, [])

    // register service worker
    useEffect(() => {
        serviceWorkerRegistration.register({
            onUpdate: onUpdateAvailable,
        })
    }, [onUpdateAvailable])

    return { showReload, waitingWorker, reloadPage }
}
