import { isPaymentTermDaysValid, textMultipleWhiteSpace } from "@finway-group/shared/lib/utils/validators"
import { MAX_PAYMENT_TERM_DAYS, MIN_PAYMENT_TERM_DAYS } from "@finway-group/shared/lib/utils/validators/vendor/vendorValidationConsts"
import { Rule } from "antd/lib/form"
import i18n from "i18next"
import { isValidPhoneNumber } from "react-phone-number-input"

import { isSet, isValidBIC, isValidIBAN, isValidVAT } from "Shared/utils/helper.utils"
import validateEmail from "Shared/validators/email.validator"

import { allowlistCharValidator } from "./utils.rules"

const VendorRules = (): { [key: string]: Array<Rule> } => ({
    name: [
        {
            validator: (_rule: any, value: string) => {
                if (!value) {
                    return Promise.reject(i18n.t("validation:required"))
                }

                // DATEV limit
                if (value.length > 50) {
                    return Promise.reject(i18n.t("validation:string_too_long"))
                }

                if (value !== value.trim()) {
                    return Promise.reject(new Error(i18n.t("validation:string_white_space_start_or_end")))
                }

                if (textMultipleWhiteSpace.test(value)) {
                    return Promise.reject(new Error(i18n.t("validation:string_white_space_duplicate")))
                }

                return Promise.resolve()
            },
        },
        allowlistCharValidator,
    ],
    city: [
        {
            validator: (_rule: any, value: string) => {
                if (value) {
                    // DATEV limit
                    if (value.length > 30) {
                        return Promise.reject(i18n.t("validation:string_too_long"))
                    }
                }

                return Promise.resolve()
            },
        },
        allowlistCharValidator,
    ],
    streetName: [allowlistCharValidator],
    streetNumber: [allowlistCharValidator],
    country: [allowlistCharValidator],
    zip: [allowlistCharValidator],
    description: [{ required: false, message: i18n.t("validation:required"), validateTrigger: "blur" }, allowlistCharValidator],
    rating: [{ type: "integer", required: true, message: i18n.t("validation:required"), validateTrigger: "blur" }],
    recommendation: [{ type: "integer", required: true, message: i18n.t("validation:required"), validateTrigger: "blur" }],
    contactPersonName: [allowlistCharValidator],
    contactPersonEmail: [
        {
            validator: (_rule: any, value: string) => {
                if (!validateEmail(value) && value) {
                    return Promise.reject(new Error(i18n.t("validation:email")))
                }

                return Promise.resolve()
            },
        },
    ],
    contactPersonPhone: [
        {
            validator: (_rule: any, value: string) => {
                if (!isValidPhoneNumber(value) && value) {
                    return Promise.reject(new Error(i18n.t("validation:phone")))
                }
                return Promise.resolve()
            },
        },
    ],
    iban: [
        {
            validator: (_rule: any, value: string) => {
                if (value && value !== "" && !isValidIBAN(value)) {
                    return Promise.reject(new Error(i18n.t("validation:iban")))
                }

                return Promise.resolve()
            },
        },
    ],
    bic: [
        {
            validator: (_rule: any, value: string) => {
                if (value && value !== "" && !isValidBIC(value)) {
                    return Promise.reject(new Error(i18n.t("validation:bic")))
                }

                return Promise.resolve()
            },
        },
    ],
    vatNumber: [
        {
            validator: (_rule: any, value: string) => {
                if (value && value !== "" && !isValidVAT(value)) {
                    return Promise.reject(new Error(i18n.t("validation:vat")))
                }

                return Promise.resolve()
            },
        },
    ],
    paymentTerm: [
        {
            validator: (_rule: any, value?: number) => {
                if (!isSet(value)) {
                    return Promise.resolve()
                }

                if (!Number.isInteger(Number(value))) {
                    return Promise.reject(new Error(i18n.t("validation:integer")))
                }

                if (!isPaymentTermDaysValid(value)) {
                    return Promise.reject(new Error(i18n.t("validation:numeric_min_max", { min: MIN_PAYMENT_TERM_DAYS, max: MAX_PAYMENT_TERM_DAYS })))
                }

                return Promise.resolve()
            },
        },
    ],
})

export default VendorRules
