import { PriceIntervalEnum } from "@finway-group/shared/lib/models"
import { Avatar } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"
import { TooltipProps } from "recharts"
import { NameType, ValueType } from "recharts/src/component/DefaultTooltipContent"

import PriceLabel from "Components/priceLabel"
import { useLoggedInEmployeeProfile } from "Shared/hooks/user.hooks"
import { getDiagramLegendString } from "Shared/utils/liquidity.utils"

interface CustomTooltipInterface {
    props: TooltipProps<ValueType, NameType>
    activeLine?: string
}

const CustomTooltip: React.FC<CustomTooltipInterface> = ({ props, activeLine }) => {
    const { t } = useTranslation()

    const currentEmployee = useLoggedInEmployeeProfile()
    const { globalCurrency } = currentEmployee.settings

    if (props.active && activeLine !== undefined) {
        props.payload = props.payload?.filter((elem) => {
            if (!elem.dataKey) return false
            return !activeLine.localeCompare(elem.dataKey.toString())
        })
    }

    if (props.active && props.payload?.length) {
        return (
            <div className="rechart-custom-tooltip">
                <span className="text-text text-xs">{props.label}</span>
                {props.payload?.map((elem, index) => (
                    <div className="flex justify-between" key={index}>
                        <div>
                            <span className="-mt-1">
                                <Avatar style={{ border: 0, backgroundColor: elem.color }} size={8} />
                            </span>
                            <span className="ml-8 text-text-dark">{getDiagramLegendString(t, `${elem.name}`) ?? elem.name}</span>
                        </div>
                        <span className="ml-16 font-bold text-text-dark">
                            <PriceLabel value={Number(elem.value)} currency={globalCurrency} interval={PriceIntervalEnum.ONE_TIME} />
                        </span>
                    </div>
                ))}
            </div>
        )
    }
    return null
}
export default React.memo(CustomTooltip)
