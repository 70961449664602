import { LoadingOutlined } from "@ant-design/icons"
import { Alert } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"

import { useCompany } from "Shared/hooks/company.hooks"

interface ListTransactionBannerInterface {}

const ListTransactionBanner: React.FC<ListTransactionBannerInterface> = ({}) => {
    const { t } = useTranslation()
    const company = useCompany()

    return company?.isImportingTransactions ? (
        <Alert
            showIcon
            icon={<LoadingOutlined />}
            className="mb-20"
            message={t("info:matching.import_in_progress.title")}
            description={t("info:matching.import_in_progress.message")}
            type="info"
        />
    ) : company?.isMatchingTransactions ? (
        <Alert
            showIcon
            icon={<LoadingOutlined />}
            className="mb-20"
            message={t("info:matching.matching_in_progress.title")}
            description={t("info:matching.matching_in_progress.message")}
            type="info"
        />
    ) : (
        <></>
    )
}

export default ListTransactionBanner
