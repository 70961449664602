import { BillingPeriodEnum, CurrencyEnum, Expense, ExpenseKindEnum, SubscriptionTypeEnum } from "@finway-group/shared/lib/models"
import { ExpensePaymentOptionEnum } from "@finway-group/shared/lib/models/expense/expensePaymentOption.enum"
import { SubscriptionRenewalGenerationTypeEnum } from "@finway-group/shared/lib/models/expense/subscriptionRenewalGenerationType.enum"
import { Col, DatePicker, Form, Row, Select } from "antd"
import moment from "moment"
import React, { ChangeEvent } from "react"
import { useTranslation } from "react-i18next"
import { useDebouncedCallback } from "use-debounce/lib"

import { FormInputElem, FormTextArea } from "Components/form/formInputAndTextarea"
import FormSelectCurrency from "Components/form/formSelectCurrency"
import { useExpenseFormContext } from "Components/modals/expenseCreateForm.context"
import { useEmployees } from "Shared/hooks/employee.hooks"
import { gobdNonCompliantDates } from "Shared/utils/date.utils"
import { doFilterSelect } from "Shared/utils/helper.utils"
import { getPopupAnchor } from "Shared/utils/popup.utils"

import { ExpenseRules } from "../rules"
import TotalAmountForm from "./commonInputFields/totalAmount.form"

interface ExpenseSubscriptionInterface {}
const SubscriptionExpenseForm: React.FC<ExpenseSubscriptionInterface> = () => {
    const [{ expense, expenseForm: formInstance, updateExpense, isLinked, setIsLinked, type, isNew }] = useExpenseFormContext()
    const { t } = useTranslation()
    const employees = useEmployees()
    const { name, link, datePurchased, dateCancellationDue } = ExpenseRules({ expense, employees })
    const isCardSubscription = expense.paymentOption === ExpensePaymentOptionEnum.SMART_CARD

    const debounceUpdate = useDebouncedCallback((updateData) => {
        updateExpense(updateData)
    }, 600)

    return (
        <div>
            <TotalAmountForm expense={expense} expenseForm={formInstance} updateExpense={updateExpense} isLinked={isLinked} setIsLinked={setIsLinked} type={type} isNew={isNew} />
            <Row gutter={[16, 16]} align="middle">
                <FormInputElem
                    elem={{
                        name: "name",
                        key: "name",
                        label: t("input:request.name"),
                        placeholder: t("placeholder:request.subscription_name"),
                        initialValue: expense.name,
                        rules: name,
                        maxLength: 100,
                        isRequired: true,
                        isAutoComplete: false,
                        onChange: (e: ChangeEvent<HTMLInputElement>) => debounceUpdate.callback({ name: e.target.value }),
                    }}
                    span={12}
                />
                <FormInputElem
                    elem={{
                        name: "link",
                        key: "link",
                        label: t("input:request.link"),
                        placeholder: t("placeholder:request.link"),
                        initialValue: expense.link,
                        rules: link,
                        onChange: (e: ChangeEvent<HTMLInputElement>) => debounceUpdate.callback({ link: e.target.value }),
                    }}
                    span={12}
                    sm={24}
                    md={13}
                />
            </Row>
            <Row gutter={[16, 16]} align="middle">
                <FormTextArea
                    elem={{
                        name: "description",
                        label: t("input:request.description"),
                        placeholder: t("placeholder:request.description"),
                        key: "description",
                        initialValue: expense.description,
                        className: "leading-normal",
                        autoSize: { minRows: 1, maxRows: 3 },
                        onChange: (e: ChangeEvent<HTMLTextAreaElement>) => debounceUpdate.callback({ description: e.target.value }),
                    }}
                    span={24}
                    sm={24}
                    md={24}
                />
            </Row>
            <Row gutter={[16, 16]} align="middle">
                <FormSelectCurrency
                    elem={{
                        label: t("input:request.currency"),
                        initialValue: expense.currency,
                        filterOption: doFilterSelect,
                        getPopupContainer: getPopupAnchor,
                        onChange: (v: CurrencyEnum) => updateExpense({ currency: v }),
                    }}
                    span={24}
                    sm={24}
                    md={24}
                />
            </Row>
            <Row gutter={[16, 16]} align="middle">
                <Col span={12} sm="24" md="12">
                    <Form.Item label={t("input:request.date_started")} key="datePurchased" name="datePurchased" rules={datePurchased} required>
                        <DatePicker
                            disabledDate={gobdNonCompliantDates}
                            name="datePurchased"
                            style={{ width: "100%" }}
                            format={moment.localeData().longDateFormat("L")}
                            placeholder={t("placeholder:request.date")}
                            getPopupContainer={getPopupAnchor()}
                            onChange={(v) => updateExpense({ datePurchased: v as any })}
                        />
                    </Form.Item>
                </Col>
                <Col span={12} sm="24" md="12">
                    <Form.Item label={t("input:request.date_cancellation_due")} key="dateCancellationDue" name="dateCancellationDue" rules={dateCancellationDue} required>
                        <DatePicker
                            name="dateCancellationDue"
                            style={{ width: "100%" }}
                            format={moment.localeData().longDateFormat("L")}
                            placeholder={t("placeholder:request.date")}
                            getPopupContainer={getPopupAnchor()}
                            onChange={(v) => updateExpense({ dateCancellationDue: v as any })}
                        />
                    </Form.Item>
                </Col>

                <Col span={expense.subscriptionType === SubscriptionTypeEnum.RENEWAL_REQUEST || isCardSubscription ? 12 : 24} sm="24">
                    <Form.Item label={t("input:request.billing_period")} key="billingPeriod" name="billingPeriod" initialValue={BillingPeriodEnum.MONTHLY}>
                        <Select getPopupContainer={getPopupAnchor()} onChange={(v: BillingPeriodEnum) => updateExpense({ billingPeriod: v })}>
                            <Select.Option value={BillingPeriodEnum.MONTHLY}>{t("input:request.monthly")}</Select.Option>
                            <Select.Option value={BillingPeriodEnum.QUARTERLY}>{t("input:request.quarterly")}</Select.Option>
                            <Select.Option value={BillingPeriodEnum.YEARLY}>{t("input:request.yearly")}</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
                {expense.subscriptionType === SubscriptionTypeEnum.INITIAL_REQUEST && isCardSubscription && (
                    <Col span={12} sm="24">
                        <Form.Item
                            label={t("input:request.renewal_generation.title")}
                            key="renewalGenerationType"
                            name="renewalGenerationType"
                            initialValue={SubscriptionRenewalGenerationTypeEnum.BY_DATE}
                        >
                            <Select getPopupContainer={getPopupAnchor()} onChange={(v: SubscriptionRenewalGenerationTypeEnum) => updateExpense({ renewalGenerationType: v })}>
                                <Select.Option value={SubscriptionRenewalGenerationTypeEnum.BY_DATE}>{t("input:request.renewal_generation.by_date")}</Select.Option>
                                <Select.Option value={SubscriptionRenewalGenerationTypeEnum.BY_TRANSACTION}>{t("input:request.renewal_generation.by_transaction")}</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                )}
                {expense.subscriptionType === SubscriptionTypeEnum.RENEWAL_REQUEST && (
                    <Col span={12}>
                        <Form.Item label={t("label:performance_period")} name="performancePeriod" className="text-opacity-50">
                            <DatePicker.RangePicker
                                disabledDate={gobdNonCompliantDates}
                                name="performancePeriod"
                                style={{ width: "100%" }}
                                allowEmpty={[true, true]}
                                format={moment.localeData().longDateFormat("L")}
                                placeholder={[t("placeholder:start_date"), t("placeholder:end_date")]}
                                getPopupContainer={getPopupAnchor()}
                                onChange={(values) => {
                                    updateExpense({ performancePeriod: values })
                                }}
                            />
                        </Form.Item>
                    </Col>
                )}
            </Row>
        </div>
    )
}

export default React.memo(SubscriptionExpenseForm)
