import { SwapOutlined } from "@ant-design/icons"
import { CurrencyEnum, PriceIntervalEnum, Transaction, TransactionTypeEnum } from "@finway-group/shared/lib/models"
import { TransactionStatusEnum } from "@finway-group/shared/lib/models/transaction/transactionStatus.enum"
import moment from "moment"
import React from "react"

import ExpenseConversionInfo from "./expenseConversionInfo"
import PriceLabel from "./priceLabel"

interface TransactionAmountColumnInterface {
    transaction: Transaction
    expenseCurrency?: CurrencyEnum
}

const TransactionAmountColumn: React.FC<TransactionAmountColumnInterface> = ({ transaction, expenseCurrency }) => {
    let transactionSourceRawContent: any = {}
    try {
        transactionSourceRawContent =
            expenseCurrency && expenseCurrency !== transaction.transactionData.currency && transaction.transactionType === TransactionTypeEnum.CARD_TRANSACTION
                ? JSON.parse(`${(transaction as any).sourceRawContent}`)
                : undefined
    } catch (err) {}
    const displayConversion = expenseCurrency && expenseCurrency !== transaction.transactionData.currency

    // for failed card transactions, even though they usually have a positive value (since they refund the amount), we show them as negative
    if (transaction.transactionType === TransactionTypeEnum.CARD_TRANSACTION && transaction.status === TransactionStatusEnum.FAILED && transaction.transactionData.amount > 0) {
        transaction.transactionData.amount = -transaction.transactionData.amount
    }

    return (
        <div className="flex align-middle gap-8 justify-start">
            <div className={`flex`}>
                <PriceLabel value={transaction.transactionData.amount} currency={transaction.transactionData.currency} interval={PriceIntervalEnum.ONE_TIME} addPlusSign />
            </div>

            {displayConversion && (
                <>
                    <div className="text-xs opacity-75">
                        <SwapOutlined />
                    </div>
                    <div>
                        <ExpenseConversionInfo
                            className="text-xs pt-2"
                            value={Math.abs(transaction.transactionData.amount)}
                            sourceCurrency={transaction.transactionData.currency}
                            destCurrency={expenseCurrency}
                            showSourceAmount={false}
                            exchangeDate={transaction.transactionData?.date ? moment(transaction.transactionData.date) : undefined}
                            sourceAmount={
                                transaction.transactionType === TransactionTypeEnum.CARD_TRANSACTION && transactionSourceRawContent?.sourceAmount
                                    ? Math.abs(transactionSourceRawContent.sourceAmount)
                                    : undefined
                            }
                            size="sm"
                        />
                    </div>
                </>
            )}
        </div>
    )
}

export default TransactionAmountColumn
