import { Action } from "redux"

import { TablesEnum } from "Shared/store/reducers/tableConfigReducer"

export enum TableConfigActionTypes {
    SET_CURRENT_PAGE = "SET_CURRENT_PAGE",
    SET_ITEMS_PER_PAGE = "SET_ITEMS_PER_PAGE",
    SET_SORTING = "SET_SORTING",
    SET_SEARCH_VALUE = "SET_SEARCH_VALUE",
    SET_SEARCH_VALUE_DASHBOARD = "SET_SEARCH_VALUE_DASHBOARD",
    SET_FILTER_STRING = "SET_FILTER_STRING",
}
export interface SetSearchValueAction extends Action<typeof TableConfigActionTypes.SET_SEARCH_VALUE> {
    type: typeof TableConfigActionTypes.SET_SEARCH_VALUE
    table: TablesEnum
    searchValue: string
}

export interface SetSearchValueDashboardAction extends Action<typeof TableConfigActionTypes.SET_SEARCH_VALUE_DASHBOARD> {
    type: typeof TableConfigActionTypes.SET_SEARCH_VALUE_DASHBOARD
    searchValue: string
}
export interface SetCurrentPageAction extends Action<typeof TableConfigActionTypes.SET_CURRENT_PAGE> {
    type: typeof TableConfigActionTypes.SET_CURRENT_PAGE
    table: TablesEnum
    currentPage: number
}

export interface SetItemsPerPageAction extends Action<typeof TableConfigActionTypes.SET_ITEMS_PER_PAGE> {
    type: typeof TableConfigActionTypes.SET_ITEMS_PER_PAGE
    table: TablesEnum
    itemsPerPage: number
}

export interface SetSortingAction extends Action<typeof TableConfigActionTypes.SET_SORTING> {
    type: typeof TableConfigActionTypes.SET_SORTING
    table: TablesEnum
    sorting: {}
}

export interface SetFilterStringAction extends Action<typeof TableConfigActionTypes.SET_FILTER_STRING> {
    type: typeof TableConfigActionTypes.SET_FILTER_STRING
    table: TablesEnum
    filterString: string
}

export type TableConfigActions = SetCurrentPageAction | SetItemsPerPageAction | SetSortingAction | SetSearchValueAction | SetSearchValueDashboardAction | SetFilterStringAction
