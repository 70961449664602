import { Expense, TripFolder } from "@finway-group/shared/lib/models"
import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import { NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { archiveExpense, archiveMultipleExpenses, detachAllFolderContents, syncExpense } from "Shared/store/actions/expense/expenseActions"
import { isFolderAttachableAndDetachable } from "Shared/utils/folder.utils"

import { ConfirmOption, MultiActionConfirmModal } from "./multiActionConfirm.modal"

interface ArchiveFolderModalProps {
    folder: TripFolder
    subExpenses: Array<Expense>
    isShowing: boolean
    onCancel: () => void
}

export const ArchiveFolderModal = ({ folder, isShowing, onCancel, subExpenses }: ArchiveFolderModalProps) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const areSubExpensesDetachable = isFolderAttachableAndDetachable(folder)
    const isFolderFilled = subExpenses.length > 0

    const executeArchiveFolder = async (scope?: "all" | "folder_only") => {
        const toBeArchivedIds = [folder, ...subExpenses].map((expense) => expense.id)

        try {
            // If the callback sends no parameter and we dont ask the user to choose the scope of tha archive, that means we can just archive the folder itself
            if (!scope && !isFolderFilled) {
                await archiveExpense(folder.id)(dispatch)
            } else if (!scope && !areSubExpensesDetachable) {
                await archiveMultipleExpenses(toBeArchivedIds)(dispatch)
            } else {
                switch (scope) {
                    case "all":
                        // Archives the folder and the contents
                        await archiveMultipleExpenses(toBeArchivedIds)(dispatch)
                        break
                    case "folder_only":
                        // Detach all contents, archive the folder only
                        await detachAllFolderContents(folder.id)(dispatch)
                        await archiveExpense(folder.id)(dispatch)
                        break
                }
            }

            NotificationService.send(NotificationTypeEnum.SUCCESS, t(`notification:trip_folder.archived.title`), t(`notification:trip_folder.archived.message`))
            onCancel()
            return
        } catch (err) {
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:error"))
        } finally {
            await syncExpense(folder.id)(dispatch)
        }
    }

    const getArchiveDescription = () => {
        if (!isFolderFilled) return t("confirm:request.archive_folder.message")
        if (!areSubExpensesDetachable) {
            return t("confirm:request.archive_folder.all")
        }
        return `${t("confirm:request.archive_folder.message")}  ${t("confirm:request.archive_folder.extra")}`
    }

    const getArchiveConfirmOptions = (): Array<ConfirmOption> => {
        if (!isFolderFilled || !areSubExpensesDetachable) return []
        return [
            {
                action: "folder_only",
                text: t("confirm:request.archive_folder.select.folder_only"),
            },
            {
                action: "all",
                text: t("confirm:request.archive_folder.select.all"),
            },
        ]
    }

    return (
        <MultiActionConfirmModal
            isShowing={isShowing}
            onCancel={onCancel}
            onConfirm={executeArchiveFolder}
            okButtonText={t("confirm:request.archive_folder.confirm")}
            cancelButtonText={t("confirm:request.archive_folder.cancel")}
            title={t("confirm:request.archive_folder.title")}
            description={getArchiveDescription()}
            size="sm"
            confirmOptions={getArchiveConfirmOptions()}
        />
    )
}
