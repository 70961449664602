import { supportedInvoiceFormats } from "@finway-group/shared/lib/consts/common"
import { DocumentTypeEnum } from "@finway-group/shared/lib/models"
import { Upload } from "antd"
import { UploadFile } from "antd/es/upload/interface"
import React, { useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import { doFileUploadFunction } from "Features/pages/expenses/expenseDetails/expenseDetails.container.types"
import NotificationService, { NotificationTypeEnum } from "Shared/services/notification.service"

export interface UploadFilesDraggerInterface {
    doFileUpload: doFileUploadFunction
    documentType: DocumentTypeEnum
    disabled?: boolean
}

const UploadFilesDragger: React.FC<UploadFilesDraggerInterface> = ({ doFileUpload, documentType, disabled = false, children }) => {
    const { t } = useTranslation()
    const [fileListState, setFileListState] = useState([] as Array<UploadFile>)
    const onChangeCallCount = useRef(0) // have to useRef because useState won't work w/ tight onChange of antd between invocations
    const allowMultipleUpload = [DocumentTypeEnum.INVOICE, DocumentTypeEnum.OFFER].includes(documentType)

    const resetState = () => {
        setFileListState([])
        onChangeCallCount.current = 0
    }

    const hasDuplicates = (fileList: Array<UploadFile>) => fileList.length !== new Set(fileList.map((file) => file.name)).size

    return (
        <Upload.Dragger
            multiple={allowMultipleUpload}
            showUploadList={false}
            accept={supportedInvoiceFormats.join(",")}
            disabled={disabled}
            /**
             * TODO: use onDrop event instead after upgrading antd
             * see: https://ant.design/components/upload/#API
             */
            // handle multi file upload-hack
            fileList={fileListState}
            beforeUpload={() => false} // prevent automatic upload of single files
            onChange={({ fileList }) => {
                setFileListState(fileList.filter((file) => file.status !== "error"))
                // if the number of times this method was called EQUALS the number of files dragged into the field execute the upload
                if (++onChangeCallCount.current === fileList.length) {
                    // somehow the list can contain duplicates
                    // to prevent API errors dedupe the list by the name of the file
                    if (hasDuplicates(fileList)) {
                        resetState()
                        NotificationService.send(NotificationTypeEnum.WARNING, t("error:file.duplicates.title"), t("error:file.duplicates.message"))
                        return
                    }
                    doFileUpload(
                        fileList.map((file) => file.originFileObj as File),
                        documentType,
                    ).finally(() => {
                        // after upload reset file list and method call-count
                        resetState()
                    })
                }
            }}
        >
            {children}
        </Upload.Dragger>
    )
}

export default UploadFilesDragger
