import axios from "axios"

export type GeoLocation = { address: string; point: { coordinates: [number, number]; type: string } }

const GeoLocationService = {
    getPlacesAutocomplete: async (searchText: string) => {
        const { data = [] }: { data: Array<GeoLocation> } = await axios.get(`/geo-location/places?searchText=${searchText}`)
        return data
    },
    fetchDirections: async (coordinates: string) => {
        const { data } = await axios.get(`/geo-location/directions?coordinates=${coordinates}`)
        return data
    },
}

export default GeoLocationService
