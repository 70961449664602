import { ExclamationCircleOutlined } from "@ant-design/icons"
import {
    ApprovalProcess,
    ApprovalScopeEnum,
    ApprovalStatusEnum,
    ApproverTypeEnum,
    CostCenter2,
    Employee,
    Expense,
    ExpensePaymentFlowInformationEnum,
    ExpenseStatusEnum,
    RightEnum,
    SubscriptionTypeEnum,
    UserRule,
    Vendor,
    VendorRule,
    VendorTypeEnum,
} from "@finway-group/shared/lib/models"
import { ExpensePaymentOptionEnum } from "@finway-group/shared/lib/models/expense/expensePaymentOption.enum"
import { GetEmployeeById } from "@finway-group/shared/lib/models/user/employee.model"
import { getApprovalProcess, getApprovalProcessScopeFromExpenseStatus } from "@finway-group/shared/lib/utils/approvalProcess.utils"
import { Alert, Button, Col, Form, Row, Select, Tooltip } from "antd"
import React, { useEffect, useMemo, useRef } from "react"
import { Plus as PlusIcon } from "react-feather"
import { useTranslation } from "react-i18next"

import UserImage from "Components/UserImage"
import { ApprovalProcessIndicatorTag } from "Components/approvalProcessesIndicator"
import DeletedTooltip from "Components/deletedTooltip"
import ExpenseTagSection from "Components/expenseTagSection"
import FormSelect from "Components/form/formSelect"
import { ExpenseRules } from "Components/forms/rules"
import CostCenter2Modal from "Components/modals/costCenter2.modal"
import EmployeeRuleModal from "Components/modals/employeeRule.modal"
import { useExpenseFormContext } from "Components/modals/expenseCreateForm.context"
import VendorFormModal from "Components/modals/vendor.modal"
import VendorRuleModal from "Components/modals/vendorRule.modal"
import { FINWAY_ADMIN_EMAIL, NOT_SET_VALUE } from "Shared/config/consts"
import { useModal } from "Shared/context/modal.context"
import { useCompany } from "Shared/hooks/company.hooks"
import { useCostCenters2 } from "Shared/hooks/costCenter2.hooks"
import { useCostCenterById, useSubCostCenters } from "Shared/hooks/costCenter.hooks"
import { useEmployees, useLoggedInEmployee } from "Shared/hooks/employee.hooks"
import { useExpense } from "Shared/hooks/expense.hooks"
import { useExpenseAccounts } from "Shared/hooks/expenseAccount.hooks"
import { useCreditorById, useFilteredCreditorsForExpense, useVendors } from "Shared/hooks/vendor.hooks"
import { useWorkflowById } from "Shared/hooks/workflow.hooks"
import { ApprovalProcessService, AuthzService, EmployeeService, ExpenseService } from "Shared/services"
import { getCostCenterLabel } from "Shared/utils/costCenter.utils"
import { changeAllSplitPropertyValues, isMileageOrPerDiem } from "Shared/utils/expense.utils"
import { doAllElementsHaveTheSameFieldValue, doFilterSelect, insertIf, isExpensePaymentFlowInfoEnabled } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"
import { getPopupAnchor } from "Shared/utils/popup.utils"

import { CreditorOptionLabel } from "./creditorNumberDisplay"

interface ExpenseCommonInputInterface {
    allowVendorCreation?: boolean
    allowPaymentFlow?: boolean
    showVendor?: boolean
}
const MINIMUM_EMPLOYEE_RULES = 1
const MINIMUM_VENDOR_RULES = 4

const ExpenseCommonInputFieldsForm: React.FC<ExpenseCommonInputInterface> = ({ allowVendorCreation, showVendor = true, allowPaymentFlow = true }) => {
    const [{ expenseForm, expense, isPreApprovedSubscription, isNew, updateExpense, prefilledVendorData, folder, isBuildingApprovalProcesses }] = useExpenseFormContext()

    const loggedInUser = useLoggedInEmployee()
    const employees = useEmployees({ includeAutoApprover: true })
    const costCenters = useSubCostCenters(true)
    const { t } = useTranslation()
    const { showModal } = useModal()
    const { costCenter, vendor, requestedBy, approvalNeededBy, expenseAccount } = ExpenseRules({ expense, employees })
    const company = useCompany()
    const [proposedApprovers, setProposedApprovers] = useStateIfMounted<Array<Employee>>([])
    const scope =
        getApprovalProcessScopeFromExpenseStatus(expense.status, true) ??
        (isPreApprovedSubscription && expense.status === ExpenseStatusEnum.DONE ? ApprovalScopeEnum.EXPENSE_INVOICE : undefined)

    const approvalProcess = scope ? getApprovalProcess(scope, expense.approvalProcesses) : undefined
    const workflow = useWorkflowById(approvalProcess?.workflow)

    const isCardExpense = expense.paymentOption === ExpensePaymentOptionEnum.SMART_CARD
    const isLoggedInUserPotentialApprover = AuthzService.isLoggedInUserPotentialApprover()
    const showPaymentFlowInformation = allowPaymentFlow
        ? !isCardExpense && !expense.isReimbursement && isExpensePaymentFlowInfoEnabled(company, isLoggedInUserPotentialApprover)
        : false
    const isApproverChangeDisabled =
        !!folder ||
        !!expense.folderId ||
        (expense.paymentFlowInformation &&
            [ExpensePaymentFlowInformationEnum.ALREADY_APPROVED_AND_PAID, ExpensePaymentFlowInformationEnum.REPORTING_ONLY].includes(expense.paymentFlowInformation)) ||
        (!!approvalProcess?.workflow && !company?.allowWorkflowAdjustments && !AuthzService.isRightGrantedForLoggedInUser(RightEnum.EXPENSE__ALL__ADJUST_WORKFLOW))

    const shouldShowOnlyVerifiedVendors = [ExpenseStatusEnum.DONE, ExpenseStatusEnum.PAID, ExpenseStatusEnum.DECLINED, ExpenseStatusEnum.REVIEWED].includes(expense.status)
    const isDoneParentSubscription = expense.isInitialSubscription() && expense.isSuccessfullyProcessed()

    const expenseAccounts = useExpenseAccounts()
    const costCenters2 = useCostCenters2()
    const vendors = useVendors()
    const { splits } = expense
    const isCostCenterChangeable = doAllElementsHaveTheSameFieldValue(splits, "costCenter")
    const isCostCenter2Changeable = doAllElementsHaveTheSameFieldValue(splits, "costCenter2")
    const isExpenseAccountChangeable = doAllElementsHaveTheSameFieldValue(splits, "expenseAccount._id")
    const { showCostCentersIds, taggingEnabled } = useCompany()
    const creditors = useFilteredCreditorsForExpense(AuthzService.isRightGrantedForLoggedInUser(RightEnum.EMPLOYEE__ALL__READ), expense)
    const expenseDetails = useExpense()
    const allowCostCenter2Creation = useCompany()?.allowCostCenter2Creation
    // expense.getCreditor() doesn't work here
    const selectedCreditor = useCreditorById(expense.vendor?._id || expense.creditorUser?._id)
    const selectedCostCenter = useCostCenterById(expense.costCenter)
    const requester = useMemo(() => GetEmployeeById(employees, expense.requestedBy._id), [employees, expense.requestedBy._id])

    const isPaymentFlowInfoEditDisabled =
        !isNew &&
        !expenseDetails.isInitialSubscription() &&
        (expenseDetails.paymentFlowInformation === ExpensePaymentFlowInformationEnum.REPORTING_ONLY || expenseDetails.isReviewed() || expenseDetails.isProcessed())

    const purchaseApprovalProcess = getApprovalProcess(ApprovalScopeEnum.EXPENSE_PURCHASE, expense.approvalProcesses)
    const isDonePurchaseParentSub = expense.subscriptionType === SubscriptionTypeEnum.INITIAL_REQUEST && expense.status === ExpenseStatusEnum.DONE && purchaseApprovalProcess
    // e.g. if the requester was deleted, we still want to show him
    if (expense.requestedBy._id && !GetEmployeeById(employees, expense.requestedBy._id)) {
        const requester = EmployeeService.getEmployeeById(expense.requestedBy._id)
        requester && employees.push(requester)
    }

    useEffect(() => {
        const proposedApprovers = ExpenseService.determinePossibleApproversForExpense(expense, employees, costCenters, loggedInUser, isPreApprovedSubscription, true)
        setProposedApprovers(proposedApprovers)
    }, [expense])

    const onCostCenterChange = (value: any) => {
        const splitsUpdate = [...splits]
        changeAllSplitPropertyValues(splitsUpdate, "costCenter", value)
        updateExpense({ costCenter: value, splits: splitsUpdate })
    }

    const onPaymentFlowInfoChanged = (value: any) => {
        updateExpense({ paymentFlowInformation: value })
    }

    const onCostCenter2Change = (value: any) => {
        const splitsUpdate = [...splits]
        changeAllSplitPropertyValues(splitsUpdate, "costCenter2", value)
        updateExpense({ costCenter2: value, splits: splitsUpdate })
    }

    const onExpenseAccountChange = (value: any) => {
        const splitsUpdate = [...splits]
        changeAllSplitPropertyValues(splitsUpdate, "expenseAccount", value)
        updateExpense({ expenseAccount: { _id: value }, splits: splitsUpdate })
    }

    const onRequestedByChange = (value: any) => {
        updateExpense({ requestedBy: { _id: value } })
    }

    const onVendorChange = (value: any) => {
        const creditor = creditors.find((i) => i.id === value)

        // Properly select which field to assign. If a vendor is chosen, we assign to expense.vendor. If a user is chosen, we assign to expense.creditorUser
        let updateObject: Partial<Expense>
        if (creditor?.type === VendorTypeEnum.USER) {
            updateObject = {
                vendor: undefined,
                creditorUser: { _id: value },
            }
        } else {
            updateObject = {
                vendor: {
                    _id: value,
                    ...(creditor?.name && { name: creditor?.name }),
                },
                creditorUser: undefined,
            }
        }
        updateExpense({ ...updateObject, vendorType: creditor?.type })
    }

    const onApproverChange = async (value: any) => {
        /**
          isDoneParentSub is added to give the possibility to the user to change the approver for the subscription and it's recurring expenses
          (check https://levaroio.atlassian.net/browse/CS-2057 for context) 
        */
        const scopes = [
            ...insertIf(expense.status === ExpenseStatusEnum.PURCHASE_PENDING || isDonePurchaseParentSub, ApprovalScopeEnum.EXPENSE_PURCHASE),
            ApprovalScopeEnum.EXPENSE_INVOICE,
        ]
        const newApprovalProcesses = []
        for (const scope of scopes) {
            const approvalProcess = getApprovalProcess(scope, expense.approvalProcesses)
            const newApprovalProcess: ApprovalProcess = {
                scope,
                status: isPreApprovedSubscription ? ApprovalStatusEnum.APPROVED : ApprovalStatusEnum.NOT_STARTED,
                steps: [
                    {
                        status: isPreApprovedSubscription ? ApprovalStatusEnum.APPROVED : ApprovalStatusEnum.NOT_STARTED,
                        approvalExpression: { approverType: ApproverTypeEnum.USER, approver: { _id: value } },
                    },
                ],
                workflow: null,
                buildIssues: approvalProcess?.buildIssues,
            }

            if (!approvalProcess) {
                newApprovalProcesses.push(newApprovalProcess)
                continue
            }

            // If expense is a pre-approved condition, replace the approval process.
            if (isPreApprovedSubscription) {
                newApprovalProcesses.push(newApprovalProcess)
                continue
            }

            // If approval process is rejected do not replace the approval process.
            if (approvalProcess.status === ApprovalStatusEnum.REJECTED) continue

            // If approval process is approved do not replace the approval process.
            if (approvalProcess.status === ApprovalStatusEnum.APPROVED) continue

            // If we are modifying the approver while in purchase pending, only modify the invoice approval process if it has no workflow.
            if (approvalProcess.scope === ApprovalScopeEnum.EXPENSE_INVOICE && expense.status === ExpenseStatusEnum.PURCHASE_PENDING) {
                if (approvalProcess.workflow) continue
            }

            newApprovalProcesses.push(newApprovalProcess)
        }

        const approvalProcesses = await ApprovalProcessService.getMergedApprovalProcesses(expense.approvalProcesses, newApprovalProcesses, {
            prioritizeApproved: isPreApprovedSubscription,
        })

        updateExpense({ approvalProcesses })
    }

    const onVendorCreation = (_flag: boolean, vendor: Vendor) => {
        if (vendor) {
            expenseForm.setFieldsValue({ vendor: vendor.id })
            updateExpense({ vendor: { _id: vendor.id } })
        }
    }

    const onCostCenter2Creation = (_flag: boolean, costCenter2: CostCenter2) => {
        if (costCenter2) {
            // that means we created a new one
            expenseForm.setFieldsValue({ costCenter2: costCenter2._id })
            updateExpense({ costCenter2: costCenter2._id })
        }
    }

    const showVendorModal = () => {
        showModal(VendorFormModal, true, {
            isShowing: true,
            isNew: true,
            prefilledData: prefilledVendorData,
            onCancel: onVendorCreation,
            shouldRedirectOnRestoration: false,
            isDraggable: true,
            isOpenedByExpenseModal: true,
        })
    }

    const showVendorRuleModal = () => {
        showModal(VendorRuleModal, true, {
            isShowing: true,
            vendorIDToUpdate: expense.vendor._id,
            onSubmit: (rules: Pick<VendorRule, "costCenter" | "costCenter2" | "expenseAccount" | "expensePaymentFlowInformation" | "paymentTerm">) => {
                updateExpense({
                    costCenter: rules.costCenter,
                    costCenter2: rules.costCenter2,
                    expenseAccount: rules.expenseAccount ? { _id: rules.expenseAccount } : undefined,
                    paymentFlowInformation: rules.expensePaymentFlowInformation,
                })
            },
        })
    }
    const showEmployeeRuleModal = () => {
        showModal(EmployeeRuleModal, true, {
            isShowing: true,
            employeeIDToUpdate: expense.requestedBy._id,
            onSubmit: (rules: Pick<UserRule, "costCenter" | "costCenter2" | "expenseAccount">) => {
                updateExpense({
                    costCenter: rules.costCenter,
                    costCenter2: rules.costCenter2,
                    expenseAccount: rules.expenseAccount ? { _id: rules.expenseAccount } : undefined,
                })
            },
        })
    }

    const showCostCenter2Modal = () => {
        showModal(CostCenter2Modal, true, { isShowing: true, isNew: true, shouldRedirectOnRestoration: false, onCancel: onCostCenter2Creation })
    }
    const renderPaymentFlowSelect = (width: number) => (
        <Col span={width} sm="24">
            <Form.Item label={t("input:request.payment_flow_information.title")} key="paymentFlowInformation" name="paymentFlowInformation">
                <Select
                    getPopupContainer={getPopupAnchor()}
                    defaultValue={NOT_SET_VALUE}
                    value={expense.paymentFlowInformation ?? NOT_SET_VALUE}
                    onSelect={onPaymentFlowInfoChanged}
                    disabled={isPaymentFlowInfoEditDisabled}
                >
                    {(company?.expensePaymentFlowInformation?.isAlreadyPaidEnabled || expense.paymentFlowInformation === ExpensePaymentFlowInformationEnum.ALREADY_PAID) && (
                        <Select.Option value={ExpensePaymentFlowInformationEnum.ALREADY_PAID}>
                            {t(`input:request.payment_flow_information.${ExpensePaymentFlowInformationEnum.ALREADY_PAID}`)}
                        </Select.Option>
                    )}
                    {(company?.expensePaymentFlowInformation?.isAlreadyApprovedAndPaidEnabled ||
                        expense.paymentFlowInformation === ExpensePaymentFlowInformationEnum.ALREADY_APPROVED_AND_PAID) && (
                        <Select.Option value={ExpensePaymentFlowInformationEnum.ALREADY_APPROVED_AND_PAID}>
                            {t(`input:request.payment_flow_information.${ExpensePaymentFlowInformationEnum.ALREADY_APPROVED_AND_PAID}`)}
                        </Select.Option>
                    )}
                    {((AuthzService.isLoggedInUserAllowedToApproveExpense(expense) && company?.expensePaymentFlowInformation?.isReportingOnlyEnabled) ||
                        expense.paymentFlowInformation === ExpensePaymentFlowInformationEnum.REPORTING_ONLY) && (
                        <Select.Option value={ExpensePaymentFlowInformationEnum.REPORTING_ONLY}>
                            {t(`input:request.payment_flow_information.${ExpensePaymentFlowInformationEnum.REPORTING_ONLY}`)}
                        </Select.Option>
                    )}
                    <Select.Option value={NOT_SET_VALUE}>{t("input:request.payment_flow_information.not_set")}</Select.Option>
                </Select>
            </Form.Item>
        </Col>
    )

    const getVendorRuleCount = () => {
        // TODO: Refactor when useCreditorById hook has been refactored
        const rules = vendors.find(({ id }) => id === selectedCreditor?.source.id)?.rule ?? {}
        const numberOfRules = Object.values(rules).filter((rule) => (Array.isArray(rule) ? rule.length > 0 : !!rule)).length
        // if rules is not the empty object, it includes the id field which is not a rule and is therefore not included in the rule count
        return numberOfRules === 0 ? numberOfRules : numberOfRules - 1
    }

    const getUserRuleCount = () => {
        const rules = employees.find((employee) => employee.id === expense.requestedBy._id)?.rule?.find(({ companyId }) => companyId === company._id) ?? {}
        const numberOfRules = Object.values(rules).filter((rule) => rule !== null).length
        // if rules is not the empty object, it includes the id field and the companyId which are not rules and are therefore not included in the rule count
        return numberOfRules === 0 ? numberOfRules : numberOfRules - 2
    }
    const vendorLabel = () => {
        const canSeeVendorRules = AuthzService.isRightGrantedForLoggedInUser(RightEnum.EXPENSE__ALL__READ) && selectedCreditor?.type === VendorTypeEnum.VENDOR
        const handleOnVendorRuleClick = () => {
            if (canSeeVendorRules) {
                showVendorRuleModal()
            }
        }
        return (
            <>
                {selectedCreditor && (selectedCreditor.source as Vendor) ? (
                    <span className={`${canSeeVendorRules ? "cursor-pointer" : ""}`} onClick={handleOnVendorRuleClick}>
                        {`${t("input:request.vendor")} `}
                        {canSeeVendorRules && (
                            <u className="rule-link">{`(${t("label:rules")} ${getVendorRuleCount()}/${
                                MINIMUM_VENDOR_RULES + Number(costCenters2.length > 0) + Number(expenseAccounts.length > 0)
                            })`}</u>
                        )}
                    </span>
                ) : (
                    <span>{t("input:request.vendor")}</span>
                )}
            </>
        )
    }

    const userLabel = () => (
        <>
            {expense.requestedBy ? (
                <span className="cursor-pointer" onClick={showEmployeeRuleModal}>
                    {`${t("input:request.requested_by")} `}
                    <u className="rule-link">{`(${t("label:rules")} ${getUserRuleCount()}/${
                        MINIMUM_EMPLOYEE_RULES + Number(costCenters2.length > 0) + Number(expenseAccounts.length > 0)
                    })`}</u>
                </span>
            ) : (
                <span>{t("input:request.requested_by")}</span>
            )}
        </>
    )

    const anchorRef = useRef(null)
    return (
        <>
            {taggingEnabled && (
                <Row gutter={[16, 16]} align="middle">
                    <div className="flex items-center py-10 flex-wrap">
                        <ExpenseTagSection
                            expenseId={expense.id}
                            inCreation={true}
                            expenseTags={expense.expenseTags}
                            onTagChange={(id: string, detach: boolean) => {
                                const expenseTags = detach ? expense.expenseTags.filter((tag) => tag !== id) : [...expense.expenseTags, id]
                                updateExpense({ expenseTags })
                            }}
                        />
                    </div>
                </Row>
            )}

            <Row gutter={[16, 16]} align="middle">
                {!isMileageOrPerDiem(expense) && showVendor && (
                    <Col span={allowVendorCreation ? 22 : 24}>
                        <Form.Item
                            label={vendorLabel()}
                            key="vendor"
                            name={[expense?.vendorType === VendorTypeEnum.USER ? "creditorUser" : "vendor", "_id"]}
                            rules={vendor}
                            extra={<p className="input-help">{t("info:vendor_rule")}</p>}
                        >
                            <Select
                                data-testid="expenseFormVendorSelectField"
                                onSelect={onVendorChange}
                                showSearch
                                filterOption={doFilterSelect}
                                getPopupContainer={getPopupAnchor()}
                                placeholder={t("placeholder:vendor.select_a_vendor")}
                            >
                                {selectedCreditor && selectedCreditor?.source.deleted && (
                                    <Select.Option key={creditors.length} value={selectedCreditor.id} label={selectedCreditor?.name} id={`vendor-option-${creditors.length}`}>
                                        <CreditorOptionLabel creditor={selectedCreditor} />
                                    </Select.Option>
                                )}
                                {creditors.map(
                                    (creditor, index) =>
                                        (!shouldShowOnlyVerifiedVendors ||
                                            isDoneParentSubscription ||
                                            creditor.type !== VendorTypeEnum.VENDOR ||
                                            (creditor.source as Vendor).isApproved) && (
                                            <Select.Option key={index} value={creditor.id} label={creditor.name} id={`vendor-option-${index}`}>
                                                <CreditorOptionLabel creditor={creditor} />
                                            </Select.Option>
                                        ),
                                )}
                            </Select>
                        </Form.Item>
                    </Col>
                )}
                {allowVendorCreation && (
                    <Col span={2} className="mb-8 h-full">
                        <Form.Item>
                            <Button className="btn-default" onClick={() => showVendorModal()}>
                                <PlusIcon />
                            </Button>
                        </Form.Item>
                    </Col>
                )}
            </Row>
            {expenseAccounts.length > 0 ? (
                <Row gutter={[16, 16]} align="middle">
                    <Col span={!showPaymentFlowInformation ? 24 : 12} sm="24">
                        <Form.Item name={["expenseAccount", "_id"]} key="expenseAccount" label={t("input:request.expense_account")} rules={expenseAccount} preserve>
                            <Select
                                showSearch
                                filterOption={doFilterSelect}
                                value={expense.expenseAccount?._id || ""}
                                dropdownMatchSelectWidth={false}
                                onSelect={onExpenseAccountChange}
                                disabled={!isExpenseAccountChangeable}
                                placeholder={!isExpenseAccountChangeable ? t("label:splitted") : t("placeholder:expense_account.select_an_expense_account")}
                                getPopupContainer={getPopupAnchor()}
                            >
                                <Select.Option value={NOT_SET_VALUE} label="">
                                    <div className="flex items-center">
                                        <span>{t("missing:request.expense_account")}</span>
                                    </div>
                                </Select.Option>
                                {expenseAccounts.map((expAccount, index) => (
                                    <Select.Option key={index} value={expAccount._id} label={`${expAccount.accountName} ${expAccount.accountCode}`}>
                                        {expAccount.accountCode} - {expAccount.accountName}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    {showPaymentFlowInformation && renderPaymentFlowSelect(12)}
                </Row>
            ) : (
                showPaymentFlowInformation && (
                    <Row gutter={[16, 16]} align="middle">
                        {renderPaymentFlowSelect(24)}
                    </Row>
                )
            )}
            <Row gutter={[16, 16]} align="middle">
                <Col data-testid="expenseCostCenter" span={24} sm="24">
                    <Form.Item label={t("input:request.cost_center")} key="costCenter" name="costCenter" required rules={isCostCenterChangeable ? costCenter : undefined}>
                        <Select
                            onSelect={onCostCenterChange}
                            showSearch
                            filterOption={doFilterSelect}
                            getPopupContainer={getPopupAnchor()}
                            value={expense.costCenter}
                            disabled={!isCostCenterChangeable}
                            placeholder={!isCostCenterChangeable ? t("label:splitted") : t("placeholder:cost_center.select_a_cost_center")}
                        >
                            {selectedCostCenter?.deleted && (
                                <Select.Option
                                    key={costCenters.length}
                                    value={selectedCostCenter._id}
                                    id={`costcenter-option-${costCenters.length}`}
                                    label={getCostCenterLabel(selectedCostCenter, showCostCentersIds)}
                                >
                                    <div>
                                        {getCostCenterLabel(selectedCostCenter, showCostCentersIds)}
                                        <DeletedTooltip translationKey="tooltips:cost_center.was_deleted" deletionDate={selectedCostCenter.dateDeleted} />
                                    </div>
                                </Select.Option>
                            )}
                            {costCenters.map(({ _id, id, name }, index) => (
                                <Select.Option key={index} value={_id} id={`costcenter-option-${index}`} label={`ID ${id} - ${name}`}>
                                    {showCostCentersIds ? `ID ${id} - ${name}` : name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            {(allowCostCenter2Creation || costCenters2.length > 0) && (
                <Row gutter={[16, 16]} align="middle">
                    <Col span={allowCostCenter2Creation ? 22 : 24}>
                        <Form.Item label={t("label:cost_center_2")} key="costCenter2" name="costCenter2">
                            <Select
                                onSelect={onCostCenter2Change}
                                showSearch
                                filterOption={doFilterSelect}
                                getPopupContainer={getPopupAnchor()}
                                value={expense.costCenter2 || NOT_SET_VALUE}
                                disabled={!isCostCenter2Changeable}
                                placeholder={!isCostCenter2Changeable ? t("label:splitted") : t("placeholder:cost_center_2.select_a_cost_center_2")}
                                notFoundContent={t("missing:no_cost_center_2")}
                            >
                                <Select.Option value={NOT_SET_VALUE} label="">
                                    <div className="flex items-center">
                                        <span>{t("missing:request.cost_center_2")}</span>
                                    </div>
                                </Select.Option>
                                {costCenters2.map((costCenter2, index) => (
                                    <Select.Option key={costCenter2._id} value={costCenter2._id} label={costCenter2.name} id={`costcenter2-option-${index}`}>
                                        {costCenter2.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    {allowCostCenter2Creation && (
                        <Col span={2}>
                            <Form.Item className="mt-10">
                                <Button className="btn-default mt-22" onClick={() => showCostCenter2Modal()}>
                                    <PlusIcon />
                                </Button>
                            </Form.Item>
                        </Col>
                    )}
                </Row>
            )}
            {(scope || isPreApprovedSubscription) && (
                <Row gutter={[16, 16]} className="flex-wrap">
                    {workflow && !isBuildingApprovalProcesses && (
                        <Col flex={1} className="flex-grow">
                            <Form.Item label={<p>{t("input:request.workflow")}</p>} key="workflow" name="workflow">
                                <Select className="mb-5" defaultValue={workflow.name} disabled />
                            </Form.Item>
                        </Col>
                    )}
                    <Col flex={1} className="flex-grow" ref={anchorRef}>
                        <Form.Item
                            labelCol={{ className: "h-30" }}
                            label={
                                <div className="flex gap-10">
                                    <p className="inline-block">
                                        {isPreApprovedSubscription ? (
                                            <>
                                                {t("input:request.recurring_expense_approval_process")}
                                                <Tooltip
                                                    getPopupContainer={getPopupAnchor(anchorRef.current)}
                                                    title={t("tooltips:approval_process_will_apply_to_recurring_expenses")}
                                                    placement="right"
                                                    className="align-middle"
                                                >
                                                    <ExclamationCircleOutlined className="ml-6" />
                                                </Tooltip>
                                            </>
                                        ) : (
                                            t("input:request.next_approver")
                                        )}
                                    </p>
                                    <ApprovalProcessIndicatorTag scope={scope} approvalProcesses={expense.approvalProcesses} showLoadingSpinner={isBuildingApprovalProcesses} />
                                </div>
                            }
                            key="nextApprover"
                            name="nextApprover"
                            rules={approvalNeededBy}
                        >
                            <Select showSearch filterOption={doFilterSelect} onChange={onApproverChange} getPopupContainer={getPopupAnchor()} disabled={isApproverChangeDisabled}>
                                {proposedApprovers.map((employee) => (
                                    <Select.Option key={employee.id} label={`${employee.firstName} ${employee.lastName}`} value={employee.id}>
                                        <div className="flex items-center" id={`approver-option-${employee.id}`}>
                                            <UserImage user={employee} />
                                            <span>{`${employee.firstName} ${employee.lastName}`}</span>
                                        </div>
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            )}

            {AuthzService.isRightGrantedForLoggedInUser(RightEnum.EXPENSE__ALL__CREATE_ON_BEHALF) && (
                <div className="flex flex-col gap-6" data-testid="expenseFormRequestedBy">
                    <Form.Item label={userLabel()} className="mb-0">
                        <FormSelect
                            name={["requestedBy", "_id"]}
                            header=""
                            initialValue={loggedInUser.id}
                            isDisabled={!!expense.card}
                            rules={requestedBy}
                            options={employees.filter((employee: Employee) => {
                                if (
                                    employee.email !== FINWAY_ADMIN_EMAIL &&
                                    !employee.activeCompanyProfile.deleted &&
                                    AuthzService.isAllowedToCreateExpenses(employee.activeCompanyProfile.roleId)
                                )
                                    return true

                                // Fallback if someone does not have right to create expense, but already assigned as the requestedBy, so it doesnt show the ID string
                                if (employee.id === expense.requestedBy._id) return true

                                return false
                            })}
                            extra={expense.card && <p className="input-help">{t("info:cards.cannot_change_requester_with_attached_card")}</p>}
                            onChange={onRequestedByChange}
                            displayImages
                            showSearch
                            keyCode="0"
                        />
                    </Form.Item>

                    {expense.isReimbursement && requester && (
                        <Alert
                            message={
                                <span>
                                    <strong>{requester.name}</strong> {t("info:will_receive_reimbursement_payment")}
                                </span>
                            }
                            type="info"
                            showIcon
                        />
                    )}
                </div>
            )}
        </>
    )
}

export default ExpenseCommonInputFieldsForm
