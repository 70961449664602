import { Expense, ExpenseKindEnum, ExpenseStatusEnum, ExportFormatEnum, TripFolder } from "@finway-group/shared/lib/models"
import { Empty } from "antd"
import React, { useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import NoSearchDataFound from "Components/NoSearchDataFound"
import BankConnectionAlert from "Components/bankConnectionAlert"
import ExportDropDownMenu from "Components/exportDropDownMenu"
import Loading from "Components/loading"
import PaymentConfirmationModal from "Components/modals/paymentConfirmation.modal"
import SEPAExportConfirmationModal from "Components/modals/sepaExportConfirmation.modal"
import { PaymentDropDownButton } from "Components/paymentDropDownMenu"
import ExportTable from "Components/tables/exportTable"
import PayTable from "Components/tables/payTable"
import { ExpenseExport } from "Features/export/expenseExport"
import { useModal } from "Shared/context/modal.context"
import { useCompany } from "Shared/hooks/company.hooks"
import { markExpenseAsPaid, markMultipleExpensesAsPaid } from "Shared/hooks/expense.hooks"
import { getTableQueryStringWithoutPagination, useFetchTable, usePayAndExportFilterAndSearchTabLoading, useTable, useTableSearchString } from "Shared/hooks/table.hooks"
import { AuthzService, ExpenseService } from "Shared/services"
import { TablesEnum } from "Shared/store/reducers/tableConfigReducer"
import { getExpensesToPayOrExportForTables, isFolderExpense, normalizeExpensesToExport } from "Shared/utils/expense.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

interface PayExportTabPropInterface {}
const ToPayAndExportTab: React.FC<PayExportTabPropInterface> = () => {
    const { t } = useTranslation()
    const toBePaidTable = useTable<Expense>(TablesEnum.PAY_AND_EXPORT_TO_BE_PAID)
    const toBeExportedTable = useTable<Expense>(TablesEnum.PAY_AND_EXPORT_TO_BE_EXPORTED)
    const tabLoading = usePayAndExportFilterAndSearchTabLoading()
    const dashboardSearchString = useTableSearchString(TablesEnum.DONE_REQUESTS)
    const { taggingEnabled } = useCompany()
    const showTags =
        taggingEnabled && (toBePaidTable.data.docs.some((expense) => expense?.expenseTags?.length) || toBeExportedTable.data.docs.some((expense) => expense?.expenseTags?.length))

    // Selected expenses via table checkbox. They should proc a render because of the checkbox checked/unchecked visuals.
    const [checkedExpensesToBePaid, setCheckedExpensesToBePaid] = useStateIfMounted<Array<Expense>>([])
    const [checkedExpensesToBeExported, setCheckedExpensesToBeExported] = useStateIfMounted<Array<Expense>>([])

    // allExpensesToBeExported is enabled on selectAll. excludedExpensesFromExport contains the ids of expenses explicitly deselected by the user after selectAll
    const [excludedExpensesFromExport, setExcludedExpensesFromExport] = useStateIfMounted<Array<string>>([])
    const [exportSelectAll, setExportSelectAll] = useStateIfMounted(false)

    // Selected expenses via the dropdown button. We dont need to proc a re-render and thats why its a ref instead of a state.
    // This added variable is there so when click for example on "pay now", we dont check the checkbox in that row.
    const selectedExpenses = useRef<Array<Expense>>([])
    const isScheduledPayment = useRef<boolean>(false)

    const [isPaymentConfirmationModalVisible, setIsPaymentConfirmationModalVisible] = useState(false)
    const { showModal } = useModal()

    useFetchTable(TablesEnum.PAY_AND_EXPORT_TO_BE_PAID)
    useFetchTable(TablesEnum.PAY_AND_EXPORT_TO_BE_EXPORTED)

    const hasReadRightForExpensesToPay = AuthzService.canLoggedInUserSeeExpensesToPay()
    const hasReadRightForExpensesToExport = AuthzService.canLoggedInUserSeeExpensesToExport()
    const hasExportRight = AuthzService.canLoggedInUserExport()
    const hasPayByBankRight = AuthzService.canLoggedInUserPayByBank()

    const handlePaymentSelect = async (key: string, expenses: Array<Expense>) => {
        selectedExpenses.current = expenses
        switch (key) {
            case "mark_as_paid":
                if (expenses.length === 1 && !isFolderExpense(expenses[0])) await markExpenseAsPaid(expenses[0])
                else await markMultipleExpensesAsPaid(getExpensesToPayOrExportForTables(expenses, ExpenseStatusEnum.REVIEWED, true))
                setCheckedExpensesToBePaid([])
                break
            case "pay_now":
                isScheduledPayment.current = false
                setIsPaymentConfirmationModalVisible(true)
                break
            case "pay_on_date":
                isScheduledPayment.current = true
                setIsPaymentConfirmationModalVisible(true)
                break
            case "generate_sepa":
            case "generate_non_sepa":
                isScheduledPayment.current = true
                const isNonSepaXml = key === "generate_non_sepa"
                showModal(SEPAExportConfirmationModal, true, {
                    isShowing: true,
                    expenses: normalizeExpensesToExport(expenses, false),
                    isNonSepaXml,
                    onSubmit: ({
                        expensesToExport,
                        subjectLines,
                        exportFormat,
                    }: {
                        expensesToExport: Array<Expense>
                        subjectLines: Record<string, string>
                        exportFormat: ExportFormatEnum.SEPA | ExportFormatEnum.NON_SEPA
                    }) => ExpenseService.exportSepa({ expenses: expensesToExport, subjectLines, exportFormat }),
                })
                break
            default:
                break
        }
    }

    const handleExportSuccess = () => {
        setCheckedExpensesToBeExported([])
        setExportSelectAll(false)
    }

    const handleCheckboxClick = (table: TablesEnum.PAY_AND_EXPORT_TO_BE_PAID | TablesEnum.PAY_AND_EXPORT_TO_BE_EXPORTED, selectedRows: any) => {
        if (table === TablesEnum.PAY_AND_EXPORT_TO_BE_PAID) {
            setCheckedExpensesToBePaid(selectedRows)
            return
        }

        setCheckedExpensesToBeExported(selectedRows)
    }

    const isPaymentDropdownDisabled =
        !checkedExpensesToBePaid.length ||
        checkedExpensesToBePaid.some((expense) => expense.isInPaymentProcess || (expense.kind === ExpenseKindEnum.TRIP_FOLDER && (expense as TripFolder)?.children?.length === 0))

    return (
        <>
            {isPaymentConfirmationModalVisible && (
                <PaymentConfirmationModal
                    isVisible={isPaymentConfirmationModalVisible}
                    expenses={getExpensesToPayOrExportForTables(selectedExpenses.current, ExpenseStatusEnum.REVIEWED)}
                    isScheduledPayment={isScheduledPayment.current}
                    onCancel={() => {
                        setIsPaymentConfirmationModalVisible(false)
                        selectedExpenses.current = []
                    }}
                />
            )}
            {tabLoading ? (
                <Loading />
            ) : (
                <div className="animation-appear">
                    {toBePaidTable.data.totalDocs === 0 && toBeExportedTable.data.totalDocs === 0 ? (
                        dashboardSearchString ? (
                            <NoSearchDataFound />
                        ) : (
                            <Empty
                                image={`./icons/empty_table.svg`}
                                imageStyle={{
                                    height: 150,
                                    marginRight: "auto",
                                    marginLeft: "auto",
                                    marginTop: "15%",
                                    marginBottom: "40px",
                                    display: "inline-block",
                                }}
                                description={<span className="text-lg font-bold mt-2 mb-0">{t("info:empty_state.requests.title")}</span>}
                            >
                                <p>{t("info:empty_state.pay_export.message")}</p>
                            </Empty>
                        )
                    ) : (
                        <>
                            {AuthzService.canLoggedInUserSeeBankAccounts() && <BankConnectionAlert />}
                            {hasReadRightForExpensesToPay && (
                                <>
                                    <div className="flex justify-between mb-10">
                                        <div>
                                            <h2>{`${t("label:to_be_paid")} (${toBePaidTable.data.totalDocs})`}</h2>
                                        </div>
                                        <div className="flex justify-between btn-wrapper">
                                            {hasPayByBankRight && (
                                                <PaymentDropDownButton
                                                    expenses={checkedExpensesToBePaid}
                                                    onSelect={(key: string) => handlePaymentSelect(key, checkedExpensesToBePaid)}
                                                    text={t("action:bulk_payment")}
                                                    disabledTooltip={
                                                        isPaymentDropdownDisabled && checkedExpensesToBePaid.length === 0 ? t("tooltips:expenses.no_expense_selected") : undefined
                                                    }
                                                    disabled={isPaymentDropdownDisabled}
                                                    buttonClassName="min-w-140"
                                                />
                                            )}

                                            <ExpenseExport
                                                onSuccess={() => setCheckedExpensesToBePaid([])}
                                                render={({ onExport }) => (
                                                    <ExportDropDownMenu
                                                        expenses={checkedExpensesToBePaid}
                                                        onExport={onExport}
                                                        noIcon
                                                        disabled={checkedExpensesToBePaid.length === 0}
                                                        buttonClassName="w-140"
                                                        disabledTooltip={t("tooltips:expenses.no_expense_selected")}
                                                        primaryButton
                                                        asDottedButton
                                                        onlyFileExport={!hasExportRight}
                                                        isAuditTrailVisible={checkedExpensesToBeExported?.length === 1}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>
                                    <PayTable
                                        tableEnum={TablesEnum.PAY_AND_EXPORT_TO_BE_PAID}
                                        table={toBePaidTable}
                                        checkedExpenses={checkedExpensesToBePaid}
                                        onCheckboxClick={handleCheckboxClick}
                                        onPaymentSelect={handlePaymentSelect}
                                        onExportSuccess={handleExportSuccess}
                                        showTags={showTags}
                                    />
                                </>
                            )}
                            {hasReadRightForExpensesToExport && (
                                <>
                                    <div className="flex justify-between mb-10">
                                        <div>
                                            <h2>{`${t("label:to_be_exported")} (${toBeExportedTable.data.totalDocs})`}</h2>
                                        </div>
                                        <div>
                                            <ExpenseExport
                                                onSuccess={handleExportSuccess}
                                                render={({ onExport }) => (
                                                    <ExportDropDownMenu
                                                        text={t("action:bulk_export")}
                                                        expenses={!exportSelectAll ? checkedExpensesToBeExported : undefined}
                                                        expensesQuery={
                                                            exportSelectAll
                                                                ? `&${getTableQueryStringWithoutPagination(
                                                                      TablesEnum.PAY_AND_EXPORT_TO_BE_EXPORTED,
                                                                  )}&_id[nin]=${excludedExpensesFromExport.join(",")}`
                                                                : ""
                                                        }
                                                        onExport={onExport}
                                                        includeSepa={!exportSelectAll}
                                                        noIcon
                                                        disabled={!exportSelectAll && checkedExpensesToBeExported.length === 0}
                                                        buttonClassName="w-140"
                                                        disabledTooltip={t("tooltips:expenses.no_expense_selected")}
                                                        primaryButton
                                                        isAllPagesOptionSelected={exportSelectAll}
                                                        onlyFileExport={!hasExportRight}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>
                                    <ExportTable
                                        tableEnum={TablesEnum.PAY_AND_EXPORT_TO_BE_EXPORTED}
                                        table={toBeExportedTable}
                                        checkedExpenses={checkedExpensesToBeExported}
                                        onCheckboxClick={handleCheckboxClick}
                                        onExportSuccess={handleExportSuccess}
                                        onExpenseSelectAll={setExportSelectAll}
                                        allSelected={exportSelectAll}
                                        setExcludedExpensesFromExport={setExcludedExpensesFromExport}
                                        excludedExpensesFromExport={excludedExpensesFromExport}
                                        showTags={showTags}
                                    />
                                </>
                            )}
                        </>
                    )}
                </div>
            )}
        </>
    )
}

export default ToPayAndExportTab
