import { COLOR } from "@finway-group/shared/lib/consts"
import { Expense, ExportFormatEnum, ExportHistory } from "@finway-group/shared/lib/models"
import { Button, Modal, Table } from "antd"
import { ColumnsType } from "antd/lib/table"
import React from "react"
import { Check as CheckedIcon, X as XIcon } from "react-feather"
import { useTranslation } from "react-i18next"

import { getErrorMessageForDuoExportFailure } from "Shared/utils/expense.utils"

interface ExportHistoryValidationModalInterface {
    validationData: ExportHistory["validationData"] | undefined
    format: ExportFormatEnum | undefined
    isShowing: boolean
    onCancel: (state: boolean) => void
}

const ExportHistoryValidationModal: React.FC<ExportHistoryValidationModalInterface> = ({ validationData, isShowing, onCancel, format }) => {
    const { t } = useTranslation()

    const renderCheckedOrPlusIconColumn = (isChecked: boolean) => (
        <div className="ml-10">{isChecked ? <CheckedIcon color={COLOR.finway.green} /> : <XIcon color={COLOR.finway.red} />}</div>
    )
    const showDuoProtocol = format === ExportFormatEnum.DATEV_ONLINE && (validationData?.datevImportProtocol?.length ?? 0) > 0

    const extractErrorMessage = (errorMessage?: string) => {
        switch (format) {
            case ExportFormatEnum.DATEV_ONLINE:
                return getErrorMessageForDuoExportFailure(errorMessage)
            default:
                return errorMessage
        }
    }

    const validationEntries = validationData
        ? [
              ...validationData.verifiedExpenses.map(({ expenseNumber }) => ({
                  expenseNumber,
                  status: "success",
                  message: t("label:export_history.validation.success_message"),
              })),
              ...validationData.filteredOutExpenses.map(({ expense, errorMessage }) => ({
                  expenseNumber: expense.expenseNumber,
                  status: "failed",
                  message: extractErrorMessage(errorMessage),
              })),
          ]
        : []

    const columns: ColumnsType<typeof validationEntries[0]> = [
        {
            key: "status",
            width: 50,
            title: t("label:export_history.validation.status"),
            render: ({ status }) => renderCheckedOrPlusIconColumn(status === "success"),
        },
        {
            key: "expense",
            width: 150,
            title: t("label:export_history.validation.expense"),
            render: ({ expenseNumber }) => (
                <span>
                    {t("label:export_history.validation.expense")} # {expenseNumber || "n/a"}
                </span>
            ),
        },
        {
            key: "message",
            width: 300,
            title: t("label:export_history.validation.message"),
            render: ({ message }) => <span>{message}</span>,
        },
    ]

    const duoImportProtocolColumns: ColumnsType<Expense> = [
        {
            key: "status",
            width: 50,
            title: t("label:export_history.validation.status"),
            render: ({ type }) => renderCheckedOrPlusIconColumn(type !== "warning"),
        },
        {
            key: "filename",
            width: 150,
            title: t("label:duo_import_protocol.filename"),
            render: ({ filename }) => <span>{filename}</span>,
        },
        {
            key: "text",
            width: 300,
            title: t("label:export_history.validation.message"),
            render: ({ text }) => <span>{text}</span>,
        },
    ]

    return (
        <Modal
            visible={isShowing}
            maskClosable={false}
            title={format ? t("label:export_history.validation.title_format", { format: t(`input:export.export_format.${format}`) }) : t("label:export_history.validation.title")}
            onCancel={() => onCancel(false)}
            className="ant-modal--large"
            footer={[
                <Button key="back" onClick={() => onCancel(false)}>
                    {t("action:cancel")}
                </Button>,
            ]}
            destroyOnClose
            closable
        >
            <div className="overflow-auto">
                <Table
                    columns={columns}
                    dataSource={validationEntries}
                    pagination={{
                        position: ["bottomRight"],
                        showSizeChanger: false,
                        pageSize: 100,
                        hideOnSinglePage: true,
                    }}
                />
            </div>
            {showDuoProtocol && (
                <div className="mt-20">
                    <h2>{t("label:duo_import_protocol.title")}</h2>
                    <div className="overflow-auto mt-20">
                        <Table
                            columns={duoImportProtocolColumns}
                            dataSource={validationData?.datevImportProtocol}
                            pagination={{
                                position: ["bottomRight"],
                                showSizeChanger: false,
                                pageSize: 100,
                                hideOnSinglePage: true,
                            }}
                        />
                    </div>
                </div>
            )}
        </Modal>
    )
}

export default ExportHistoryValidationModal
