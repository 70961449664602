export enum RoutePathEnum {
    // auth
    LOGIN = "/login",
    RESET_TOKEN = "/reset/:token",
    FORGOT_PASSWORD = "/forgot-password",
    NEW_PASSWORD = "/new-password",
    RESET_PASSWORD = "/reset/:token", // TODO this is WRONG(!)
    CONFIRM_EMAIL = "/email/verify/:token",
    // auth0 routes
    AUTH_ERROR = "/auth-error",
    NO_ACCESS = "/no-access",
    LOGGED_IN = "/loggedin",
    DEFORCE_SSO = "/deforce-sso/:code",

    // expenses
    EXPENSES = "/expenses",
    EXPENSE_DETAIL_PAGE = "/expenses/:id",
    FOLDER_EXPENSE_DETAIL_PAGE = "/expenses/:id/:childId",

    // subscriptions
    SUBSCRIPTIONS = "/subscriptions",
    SUBSCRIPTION_DETAIL_PAGE = "/subscriptions/:id",

    // cards
    CARDS = "/credit-cards",
    CARD_DETAIL_PAGE = "/credit-cards/:id",
    WALLET_DETAIL_PAGE = "/credit-cards/wallet-details",

    // transactions
    TRANSACTIONS = "/transactions",
    TRANSACTION_DETAIL_PAGE = "/transactions/:id",

    // workflows
    WORKFLOWS = "/workflows",
    WORKFLOW_DETAIL_PAGE = "/workflows/:id",

    // employees
    EMPLOYEES = "/employees",
    EMPLOYEE_DETAIL_PAGE = "/employees/:id",

    // vendors
    VENDORS = "/vendors",
    VENDOR_DETAIL_PAGE = "/vendors/:id",

    // cost centers
    COST_CENTERS = "/costCenters",
    COST_CENTER_DETAIL_PAGE = "/costCenters/:id",

    // LM
    LM = "/liquidity",

    // reports
    REPORTS = "/reports",

    // settings
    SETTINGS = "/settings",

    SETTINGS_USER = "/settings/user",
    SETTINGS_PROFILE = "/settings/user/profile",
    SETTINGS_SECURITY = "/settings/user/tools-security",
    SETTINGS_USER_NOTIFICATIONS = "/settings/user/notifications",
    SETTINGS_USER_INTEGRATIONS = "/settings/user/integrations",
    SETTINGS_USER_ACTIVE_SESSIONS = "/settings/user/active-sessions",

    SETTINGS_COMPANY = "/settings/company",
    SETTINGS_FEATURE = "/settings/company/feature-settings",
    SETTINGS_COMPANY_SETTINGS = "/settings/company/settings",
    SETTINGS_BANK_ACCOUNTS = "/settings/company/bank-accounts",
    SETTINGS_EXPENSE_ACCOUNTS = "/settings/company/expense-accounts",
    SETTINGS_CC2 = "/settings/company/costcenter-2",
    SETTINGS_VAT = "/settings/company/vat",
    SETTINGS_TEAMS = "/settings/company/teams",
    SETTINGS_INTEGRATIONS = "/settings/company/integrations",
    SETTINGS_EXPORT_HISTORY = "/settings/company/export-history",
    SETTINGS_NOTIFICATIONS = "/settings/company/notifications",

    SETTINGS_SYSTEM = "/settings/system",
    SETTINGS_COMPANIES = "/settings/system/companies",
    SETTINGS_ROLES = "/settings/system/roles",
    SETTINGS_USERS = "/settings/system/users",
    SETTINGS_SYSTEM_INTEGRATIONS = "/settings/system/integrations",
    SETTINGS_SYSTEM_Security = "/settings/system/security",
    SETTINGS_BILLING = "/settings/system/billing",
    SETTINGS_BILLING_TAB_PLAN = "/settings/system/billing/plan",
    SETTINGS_BILLING_TAB_PAYMENT_METHOD = "/settings/system/billing/payment_method",
    SETTINGS_BILLING_TAB_INVOICES = "/settings/system/billing/invoices",

    // others
    ERROR = "/error",
    ROOT = "/",
    GLOBAL = "/*",
}

export interface SettingsTabRouteMatchInterface<T = string> {
    settingsTab: T
}

export type PaymentSettingsTabRoutes = RoutePathEnum.SETTINGS_BILLING_TAB_PLAN | RoutePathEnum.SETTINGS_BILLING_TAB_PAYMENT_METHOD | RoutePathEnum.SETTINGS_BILLING_TAB_INVOICES

export const detailPageRoutePaths = [
    RoutePathEnum.EXPENSE_DETAIL_PAGE,
    RoutePathEnum.FOLDER_EXPENSE_DETAIL_PAGE,
    RoutePathEnum.SUBSCRIPTION_DETAIL_PAGE,
    RoutePathEnum.CARD_DETAIL_PAGE,
    RoutePathEnum.TRANSACTION_DETAIL_PAGE,
    RoutePathEnum.WORKFLOW_DETAIL_PAGE,
    RoutePathEnum.EMPLOYEE_DETAIL_PAGE,
    RoutePathEnum.VENDOR_DETAIL_PAGE,
    RoutePathEnum.COST_CENTER_DETAIL_PAGE,
]
