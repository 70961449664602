import { Card, Skeleton } from "antd"
import React, { useMemo } from "react"
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts"

import { ColorGenerator } from "Shared/utils/colorGenerator.utils"

const colors = ColorGenerator.generateColorGradient("DEE4EA", "F2F2F2", 2)

const CashFlowGaugeChartSkeleton: React.FC = () => {
    const RADIAN = Math.PI / 180
    const width = 900

    /**
     * We use a pie chart to build the gauge chart.
     */
    const chartData = useMemo(() => {
        const actualTotal = Math.random() * 500
        const forecastTotal = Math.random() * 500
        return {
            limit: forecastTotal,
            actual: actualTotal,
            data:
                forecastTotal > actualTotal
                    ? [
                          { name: "", value: actualTotal, color: colors[0] },
                          { name: "", value: forecastTotal - actualTotal, color: colors[1] },
                      ]
                    : [
                          { name: "", value: forecastTotal, color: colors[0] },
                          { name: "", value: actualTotal - forecastTotal, color: colors[1] },
                      ],
        }
    }, [])

    const pieRadius = {
        innerRadius: (width / 2) * 0.3,
        outerRadius: (width / 2) * 0.4,
    }

    const pieProps = {
        startAngle: 180,
        endAngle: 0,
        cy: 250,
    }

    const Arrow = ({ cx, cy, startAngle, outerRadius }: any) => {
        // eslint-disable-line react/no-multi-comp
        const sin = Math.sin(-RADIAN * startAngle)
        const cos = Math.cos(-RADIAN * startAngle)
        const mx = cx + (outerRadius + width * 0.03) * cos
        const my = cy + (outerRadius + width * 0.03) * sin
        return (
            <g>
                <path d={`M${cx},${cy}L${mx},${my}`} strokeWidth="6" stroke={colors[0]} fill="none" strokeLinecap="butt" />
            </g>
        )
    }

    return (
        <Card>
            <div className="h-400">
                <Skeleton paragraph={{ rows: 1 }} />
                <ResponsiveContainer width={"100%"} minHeight={400} height={400}>
                    <PieChart width={width} height={width / 2}>
                        <Pie data={chartData.data} fill="#8884d8" dataKey="value" labelLine={false} nameKey="name" isAnimationActive={false} {...pieRadius} {...pieProps}>
                            {chartData.data.map((_, index) => (
                                <Cell key={`cell-${index}`} fill={chartData.data[index].color} />
                            ))}
                        </Pie>
                        <Pie
                            stroke="none"
                            activeIndex={1}
                            activeShape={Arrow}
                            data={chartData.data}
                            outerRadius={pieRadius.innerRadius - 30}
                            fill="none"
                            dataKey="value"
                            isAnimationActive={false}
                            {...pieProps}
                        />
                    </PieChart>
                </ResponsiveContainer>
            </div>
        </Card>
    )
}

export default CashFlowGaugeChartSkeleton
