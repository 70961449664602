import { Dropdown, Menu } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import UserImage from "Components/UserImage"
import SimulatorModal from "Components/modals/simulator/simulator.modal"
import { useModal } from "Shared/context/modal.context"
import { useAuth } from "Shared/hooks/auth.hooks"
import { useLoggedInEmployeeProfile } from "Shared/hooks/user.hooks"
import { RoutePathEnum } from "Shared/router/RoutePath.enum"
import { isDev, isInternal } from "Shared/utils/helper.utils"
import { getPopupAnchor } from "Shared/utils/popup.utils"

const UserProfileNavbar = () => {
    const history = useHistory()
    const { t } = useTranslation()
    const { showModal } = useModal()
    const { logout, userClass } = useAuth()
    const loggedInUser = useLoggedInEmployeeProfile()

    return (
        <Dropdown
            getPopupContainer={getPopupAnchor()}
            className="flex items-center"
            trigger={["click"]}
            overlay={
                <Menu className="z-20 user-dropdown">
                    <div className="flex mr-20 ml-10 pt-8 pb-8 mb-10 border-b">
                        <UserImage user={loggedInUser} size="large" />
                        <div className="flex flex-col justify-center">
                            <span className="text-base text-text-dark font-bold leading-none truncate">{userClass.name}</span>
                            <span className="text-sm truncate max-w-200">{userClass.email}</span>
                        </div>
                    </div>
                    {(isDev || isInternal) && <Menu.Item onClick={() => showModal(SimulatorModal, false, { isShowing: true })}>{t("label:simulator")}</Menu.Item>}

                    <Menu.Item onClick={() => history.push(RoutePathEnum.SETTINGS_USER)}>{t("nav:settings")}</Menu.Item>
                    <Menu.Item onClick={() => logout()}>{t("nav:logout")}</Menu.Item>
                </Menu>
            }
        >
            <div className="w-full cursor-pointer">
                <UserImage user={loggedInUser} size="large" isPersistent />
            </div>
        </Dropdown>
    )
}

export default UserProfileNavbar
