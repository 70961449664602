import Collapse from "antd/lib/collapse"
import Row from "antd/lib/row"
import React, { useState } from "react"

import { FormCategoryEnum, FormMinMaxInterface } from "./form.types"
import FormInputElem from "./formInputElem"

export default function FormMinMax({ header, inputType, min, max, keyCode = "" }: FormMinMaxInterface) {
    const [activeCategory, setActiveCategory] = useState({
        isMin: !!min.initialValue,
        isMax: !!max.initialValue,
    })

    return (
        <Collapse ghost defaultActiveKey={keyCode} className={activeCategory.isMin || activeCategory.isMax ? "filter--active" : ""}>
            <Collapse.Panel header={header} key={keyCode} forceRender>
                <Row gutter={[16, 16]}>
                    <FormInputElem elem={{ ...min, inputType }} activeCategory={activeCategory} setActiveCategory={setActiveCategory} category={FormCategoryEnum.MIN} />
                    <FormInputElem elem={{ ...max, inputType }} activeCategory={activeCategory} setActiveCategory={setActiveCategory} category={FormCategoryEnum.MAX} />
                </Row>
            </Collapse.Panel>
        </Collapse>
    )
}
