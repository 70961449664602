import { Form, Switch, Tooltip } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"

export enum StandardRightFormElementsEnum {
    UPLOAD_INVOICES = "UPLOAD_INVOICES",
    CREATE_REQUESTS = "CREATE_REQUESTS",
    VENDOR_SUGGESTION = "VENDOR_SUGGESTION",
}

const StandardRightForm = () => {
    const { t } = useTranslation()

    const standardRights = [
        {
            name: StandardRightFormElementsEnum.UPLOAD_INVOICES,
            title: t("label:rights.standard_upload_invoices.title"),
        },
        {
            name: StandardRightFormElementsEnum.CREATE_REQUESTS,
            title: t("label:rights.standard_create_expenses.title"),
        },
        {
            name: StandardRightFormElementsEnum.VENDOR_SUGGESTION,
            title: t("label:rights.standard_suggest_vendors.title"),
            tooltip: t("label:rights.standard_suggest_vendors.tooltip"),
        },
    ]

    return (
        <ul className="description-list my-30">
            <span className="title font-bold flex items-center">{t("label:roles.standard_rights")}</span>
            {standardRights.map((element, index) => (
                <li key={index} className="flex items-center justify-between h-50">
                    <div className="flex flex-1 items-center w-3/4">
                        <Tooltip title={element.tooltip} className="p-5" getPopupContainer={(triggerNode) => triggerNode}>
                            {element.title}
                        </Tooltip>
                    </div>
                    <div className="title font-normal flex items-center justify-end space-x-10">
                        <Form.Item name={element.name} className="h-6" valuePropName="checked">
                            <Switch disabled className="mr-10 pb-2" />
                        </Form.Item>
                    </div>
                </li>
            ))}
        </ul>
    )
}

export default StandardRightForm
