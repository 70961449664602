import { Rule } from "antd/lib/form"
import i18n from "i18next"

const UserCompanyProfileRules = (): { [key: string]: Array<Rule> } => ({
    company: [{ required: true, message: i18n.t("validation:required"), validateTrigger: "blur" }],
    team: [{ required: true, message: i18n.t("validation:required"), validateTrigger: "blur" }],
    role: [{ required: true, message: i18n.t("validation:required"), validateTrigger: "blur" }],
})

export default UserCompanyProfileRules
