import { Company } from "@finway-group/shared/lib/models"
import { Reducer } from "redux"

import {
    CompanyActionTypes,
    CompanyActions,
    CreateCompanyAction,
    FetchAllCompaniesAction,
    FetchCompanyAction,
    MarkCompanyAsDeletedAction,
    SelectCompanyAction,
    UpdateCompanyAction,
} from "../actions/company/companyTypes"

export interface CompanyState {
    items: Array<any>
    item: any
}

const initialState: CompanyState = {
    items: [],
    item: {},
}

const fetchCompany = (state: CompanyState, action: FetchCompanyAction): CompanyState => ({
    ...state,
    items: [{ ...action.company, shouldRefetch: true }],
})

const updateCompany = (state: CompanyState, action: UpdateCompanyAction | MarkCompanyAsDeletedAction): CompanyState => ({
    ...state,
    item: action.company?._id === state.item?._id ? action.company : state.item,
    items: state.items.map((company: Company) => (company._id === action.company._id ? action.company : company)),
})

const createCompany = (state: CompanyState, action: CreateCompanyAction): CompanyState => ({
    ...state,
    items: [action.company, ...state.items],
})

const selectCompany = (state: CompanyState, action: SelectCompanyAction): CompanyState => ({
    ...state,
    item: { ...action.company, shouldRefetch: true },
})

const fetchAllCompanies = (state: CompanyState, action: FetchAllCompaniesAction): CompanyState => ({
    ...state,
    items: action.companies,
    item: action.company,
})

const markCompanyAsDeleted = (state: CompanyState, action: UpdateCompanyAction | MarkCompanyAsDeletedAction): CompanyState => ({
    ...state,
    items: state.items.map((company: Company) => (company._id === action.company._id ? action.company : company)),
})

export const companyReducer: Reducer<CompanyState, CompanyActions> = (state = initialState, action) => {
    switch (action.type) {
        case CompanyActionTypes.FETCH_COMPANY:
            return fetchCompany(state, action)
        case CompanyActionTypes.UPDATE_COMPANY:
            return updateCompany(state, action)
        case CompanyActionTypes.FETCH_ALL_COMPANIES:
            return fetchAllCompanies(state, action)
        case CompanyActionTypes.CREATE_COMPANY:
            return createCompany(state, action)
        case CompanyActionTypes.SELECT_COMPANY:
            return selectCompany(state, action)
        case CompanyActionTypes.MARK_AS_DELETED:
            return markCompanyAsDeleted(state, action)
        default:
            return state
    }
}
