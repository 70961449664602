import Icon from "@ant-design/icons"
import React from "react"

const listSvg = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M2.875 8.875C3.35825 8.875 3.75 8.48325 3.75 8C3.75 7.51675 3.35825 7.125 2.875 7.125C2.39175 7.125 2 7.51675 2 8C2 8.48325 2.39175 8.875 2.875 8.875Z"
            fill="#606C7E"
        />
        <path d="M2.875 5C3.35825 5 3.75 4.60825 3.75 4.125C3.75 3.64175 3.35825 3.25 2.875 3.25C2.39175 3.25 2 3.64175 2 4.125C2 4.60825 2.39175 5 2.875 5Z" fill="#606C7E" />
        <path
            d="M2.875 12.75C3.35825 12.75 3.75 12.3582 3.75 11.875C3.75 11.3918 3.35825 11 2.875 11C2.39175 11 2 11.3918 2 11.875C2 12.3582 2.39175 12.75 2.875 12.75Z"
            fill="#606C7E"
        />
        <path
            d="M13.5 7.5H5.98438C5.70937 7.5 5.48438 7.725 5.48438 8C5.48438 8.275 5.70937 8.5 5.98438 8.5H13.5C13.775 8.5 14 8.275 14 8C14 7.725 13.775 7.5 13.5 7.5ZM13.5 11.375H5.98438C5.70937 11.375 5.48438 11.6 5.48438 11.875C5.48438 12.15 5.70937 12.375 5.98438 12.375H13.5C13.775 12.375 14 12.15 14 11.875C14 11.6 13.775 11.375 13.5 11.375ZM5.98438 4.625H13.5C13.775 4.625 14 4.4 14 4.125C14 3.85 13.775 3.625 13.5 3.625H5.98438C5.70937 3.625 5.48438 3.85 5.48438 4.125C5.48438 4.4 5.70937 4.625 5.98438 4.625Z"
            fill="#606C7E"
        />
    </svg>
)

const ListIcon = (props: any) => <Icon component={listSvg} {...props} />

export default ListIcon
