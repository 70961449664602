import {
    ApprovalScopeEnum,
    CommentItem,
    DiscountRule,
    DocumentTypeEnum,
    Expense,
    Hospitality,
    Mileage,
    OperationTypeEnum,
    PerDiem,
    TabTypeEnum,
    TripFolder,
} from "@finway-group/shared/lib/models"
import { t } from "i18next"
import { ActionCreator, Dispatch } from "redux"

import { getTableFilterQueryStringStore, getTableSearchQueryStringStore } from "Shared/hooks/table.hooks"
import i18n from "Shared/locales/i18n"
import { ExpenseHttpService, NotificationService } from "Shared/services"
import { ThunkResult } from "Shared/store"
import { TablesEnum } from "Shared/store/reducers/tableConfigReducer"

import { fetchDashboardInboxInvoicesCount } from "../inboxInvoice/inboxInvoiceActions"
import { refetchExpenseTables, refetchTable } from "../tables/tableActions"
import {
    AttachTagExpenseAction,
    DeleteDiscountsAction,
    DeleteExpenseAction,
    DetachTagExpenseAction,
    ExpenseActionTypes,
    FetchAllExpensesAction,
    FetchExpenseCardAction,
    FetchExpenseCountsAction,
    FetchOneExpenseAction,
    SendExpenseReminderAction,
    SetActiveTabAction,
    SetShouldRefetchExpensesAction,
    SubmitDiscountsAction,
    SyncOneExpenseAction,
    UpdateExpenseAction,
    UpdateRealTimeComments,
} from "./expenseTypes"

// prettier-ignore
export const fetchAllExpenses = (query = '', sortingCriteria = '', page = 1, limit = 20, append = false) => async (dispatch: Dispatch) => {
    const { expenses, totalPages, totalDocs } = await ExpenseHttpService.fetchExpenses(query, sortingCriteria, page, limit);
    const fetchAllExpenses: FetchAllExpensesAction = {
        type: ExpenseActionTypes.FETCH_ALL_EXPENSES,
        expenses,
        totalPages,
        totalDocs,
        append,
    };

    dispatch(fetchAllExpenses);

    // reset refetch flag
    setShouldRefetchExpenses(false)(dispatch);

    return expenses;
};

export const fetchArchivedExpensesCounts: ActionCreator<ThunkResult<void>> = () => async (dispatch: Dispatch, getState: any) => {
    const filterQueryString = getTableFilterQueryStringStore(TablesEnum.ARCHIVE)
    const searchQueryString = getTableSearchQueryStringStore(TablesEnum.ARCHIVE)
    const archiveAfterXDaysQueryString = `&archiveAfterXDays[eq]=${getState().company.item.archiveAfterXDays}`
    const ignoreSubExpenseQueryString = filterQueryString || searchQueryString ? "" : `&folderId[set]=false`

    try {
        const counts = await ExpenseHttpService.fetchExpenseCount(
            `&archived[is]=true${archiveAfterXDaysQueryString}${filterQueryString}${searchQueryString}${ignoreSubExpenseQueryString}`,
        )
        const fetchExpenseCounts: FetchExpenseCountsAction = {
            type: ExpenseActionTypes.FETCH_EXPENSE_COUNTS,
            counts: { ...getState().expenses?.counts, archive: counts.archive },
        }
        dispatch(fetchExpenseCounts)
    } catch (err) {
        // Silent error, since it is not relevant to show the user that the tab counts failed to update.
    }
}

export const fetchDashboardExpensesCounts: ActionCreator<ThunkResult<void>> = () => async (dispatch: Dispatch, getState: any) => {
    const filterQueryString = getTableFilterQueryStringStore(TablesEnum.TODO_INVOICE_APPROVAL)
    const searchQueryString = getTableSearchQueryStringStore(TablesEnum.DONE_REQUESTS)
    // If filtering or searching, we should include the sub-expenses in the count because we're not hiding them under their folder
    const ignoreSubExpenseQueryString = filterQueryString || searchQueryString ? "" : `&folderId[set]=false`
    const archiveAfterXDaysQueryString = `&archiveAfterXDays[eq]=${getState().company.item.archiveAfterXDays}`
    try {
        const counts = await ExpenseHttpService.fetchExpenseCount(filterQueryString + searchQueryString + archiveAfterXDaysQueryString + ignoreSubExpenseQueryString)
        setExpenseCounts(counts)(dispatch, getState)
    } catch (err) {
        // Silent error, since it is not relevant to show the user that the tab counts failed to update.
    }
}

export const setExpenseCounts = (counts: any) => async (dispatch: Dispatch, getState: any) => {
    const fetchExpenseCounts: FetchExpenseCountsAction = {
        type: ExpenseActionTypes.FETCH_EXPENSE_COUNTS,
        counts: { ...counts, archive: getState().expenses?.counts.archive },
    }
    dispatch(fetchExpenseCounts)
}

export const fetchOneExpense: ActionCreator<ThunkResult<Expense>> = (id: string) => async (dispatch: Dispatch) => {
    const expense = await ExpenseHttpService.fetchOneExpense(id)
    if (!expense) {
        throw Error("Expense not found")
    }

    const fetchOneExpenseAction: FetchOneExpenseAction = {
        type: ExpenseActionTypes.FETCH_ONE_EXPENSE,
        expense,
    }

    dispatch(fetchOneExpenseAction)
    return expense
}

export const syncExpense = (id: string) => async (dispatch: Dispatch) => {
    const expense = await ExpenseHttpService.fetchOneExpense(id)
    const syncOneExpenseAction: SyncOneExpenseAction = {
        type: ExpenseActionTypes.SYNC_ONE_EXPENSE,
        expense,
    }

    dispatch(syncOneExpenseAction)

    return expense
}

export const setExpense = (expense: Expense) => async (dispatch: Dispatch) => {
    const fetchOneExpenseAction: FetchOneExpenseAction = {
        type: ExpenseActionTypes.FETCH_ONE_EXPENSE,
        expense,
    }

    dispatch(fetchOneExpenseAction)
}

// prettier-ignore
export const updateExpense = (id: string, updateExpense: Expense, refetchExpenses = true) => async (dispatch: Dispatch<any>) => {
    const expense = await ExpenseHttpService.updateExpense(id, updateExpense)
    return onExpenseUpdate(expense, refetchExpenses)(dispatch)
}

export const detachOneExpense =
    (id: string, refetchExpenses = true) =>
    async (dispatch: Dispatch<any>) => {
        const expense = await ExpenseHttpService.detachOneExpense(id)
        onExpenseUpdate(expense, refetchExpenses)(dispatch)
    }

export const attachExpenses =
    (folderId: string, subExpenseIds: Array<string>, refetchExpenses = true) =>
    async (dispatch: Dispatch<any>) => {
        const expense = await ExpenseHttpService.attachExpenses(folderId, subExpenseIds)
        onExpenseUpdate(expense, refetchExpenses)(dispatch)
    }

export const detachAllFolderContents =
    (folderId: string, refetchExpenses = true) =>
    async (dispatch: Dispatch<any>) => {
        const expense = await ExpenseHttpService.detachFolderContents(folderId)
        onExpenseUpdate(expense, refetchExpenses)(dispatch)
    }

// prettier-ignore
export const approveExpense = (id: string, scope: ApprovalScopeEnum) => async (dispatch: Dispatch<any>) => {
    const expense = await ExpenseHttpService.approveExpense(id, scope)
    onExpenseUpdate(expense)(dispatch)
}

export const submitFolder = (id: string) => async (dispatch: Dispatch<any>) => {
    const expense = await ExpenseHttpService.submitFolder(id)
    onExpenseUpdate(expense)(dispatch)
}

export const approveFolder = (folderId: string, scope: ApprovalScopeEnum) => async (dispatch: Dispatch<any>) => {
    const folder = await ExpenseHttpService.approveFolder(folderId, scope)
    onExpenseUpdate(folder)(dispatch)
}

// prettier-ignore
export const reviewExpense = (id: string, refetchExpenseTables: boolean) => async (dispatch: Dispatch<any>) => {
    const expense = await ExpenseHttpService.reviewExpense(id)
    onExpenseUpdate(expense, refetchExpenseTables)(dispatch)
}

export const reviewMultipleExpenses = (ids: Array<string>) => async (dispatch: Dispatch<any>) => {
    const { filteredOutExpenseNumbers } = await ExpenseHttpService.reviewMultipleExpenses(ids)
    onMultipleExpenseUpdate()(dispatch)

    return filteredOutExpenseNumbers
}

export const reviewFolder = (folder: TripFolder) => async (dispatch: Dispatch<any>) => {
    await ExpenseHttpService.reviewFolder(folder.id)
    onMultipleExpenseUpdate()(dispatch) // sync the tables
    await syncExpense(folder.id)(dispatch) // sync the folder itself
}

export const initializeApprovalProcess = (expenseId: string, scope: ApprovalScopeEnum) => async (dispatch: Dispatch<any>) => {
    try {
        const expense = await ExpenseHttpService.initializeApprovalProcess(expenseId, scope)
        dispatch(syncExpense(expense.id))
        dispatch(refetchExpenseTables())
        dispatch(fetchDashboardExpensesCounts())

        return expense
    } catch (err) {
        if (err === "cancelled") return
        NotificationService.showErrorNotificationBasedOnResponseError(err, i18n.t(`error:error`))
    }
}

export const archiveExpense = (id: string) => async (dispatch: Dispatch<any>) => {
    const expense = await ExpenseHttpService.archiveExpense(id)
    onExpenseUpdate(expense)(dispatch)
}

export const unarchiveExpense = (id: string) => async (dispatch: Dispatch<any>) => {
    const expense = await ExpenseHttpService.unarchiveExpense(id)
    onExpenseUpdate(expense)(dispatch)
}

export const archiveMultipleExpenses = (ids: Array<string>) => async (dispatch: Dispatch<any>) => {
    await ExpenseHttpService.archiveMultipleExpenses(ids)
    onMultipleExpenseUpdate()(dispatch)
}

export const unarchiveMultipleExpenses = (ids: Array<string>) => async (dispatch: Dispatch<any>) => {
    await ExpenseHttpService.unarchiveMultipleExpenses(ids)
    onMultipleExpenseUpdate()(dispatch)
}

// prettier-ignore
export const rejectExpense = (id: string,  rejectionNote: string, scope?: ApprovalScopeEnum,) => async (dispatch: Dispatch<any>) => {
    const expense = await ExpenseHttpService.rejectExpense(id, rejectionNote, scope)
    onExpenseUpdate(expense)(dispatch)
}

// prettier-ignore
export const resetExpense = (id: string) => async (dispatch: Dispatch<any>) => {
    const expense = await ExpenseHttpService.resetExpense(id)
    onExpenseUpdate(expense)(dispatch)
}

// prettier-ignore
export const setExpensePaid = (id: string, refetchExpenseTables: boolean) => async (dispatch: Dispatch<any>) => {
    const expense = await ExpenseHttpService.markExpenseAsPaid(id)
    onExpenseUpdate(expense, refetchExpenseTables)(dispatch)
}

export const setMultipleExpensesAsPaid = (ids: Array<string>) => async (dispatch: Dispatch<any>) => {
    const { filteredOutExpenseNumbers } = await ExpenseHttpService.markMultipleExpensesAsPaid(ids)
    onMultipleExpenseUpdate()(dispatch)

    return filteredOutExpenseNumbers
}

// prettier-ignore
export const setExpenseDone = (id: string, refetchExpenseTables: boolean) => async (dispatch: Dispatch<any>) => {
    const expense = await ExpenseHttpService.markExpenseAsDone(id)
    onExpenseUpdate(expense, refetchExpenseTables)(dispatch)
}

export const setMultipleExpensesAsDoneByExpenseIds = (ids: Array<string>) => async (dispatch: Dispatch<any>) => {
    const { filteredOutExpenseNumbers } = await ExpenseHttpService.markMultipleExpensesAsDoneByExpenseIds(ids)
    onMultipleExpenseUpdate()(dispatch)

    return filteredOutExpenseNumbers
}

export const setAllExpensesAsDone = (query: string) => async (dispatch: Dispatch<any>) => {
    const { filteredOutExpenseNumbers } = await ExpenseHttpService.markAllExpensesAsDone(query)
    onMultipleExpenseUpdate()(dispatch)

    return filteredOutExpenseNumbers
}

// prettier-ignore
export const onExpenseUpdate = (updatedExpense: Expense, refetchExpenses = true) => async (dispatch: Dispatch<any>) => {
    const {isReadOnly} = await ExpenseHttpService.fetchExpenseWriteAccess(updatedExpense.id)
    updatedExpense.isReadOnly = isReadOnly
    const updateExpenseAction: UpdateExpenseAction = {
        type: ExpenseActionTypes.UPDATE_EXPENSE,
        expense: updatedExpense,
    }

    dispatch(updateExpenseAction)
    if (refetchExpenses) {
        dispatch(refetchExpenseTables())
        dispatch(fetchDashboardExpensesCounts())

        if (getTableFilterQueryStringStore(TablesEnum.ARCHIVE)) {
            dispatch(refetchTable(TablesEnum.ARCHIVE))
            dispatch(fetchArchivedExpensesCounts())
        }
    }

    setShouldRefetchExpenses(true)(dispatch)

    return updatedExpense
}

export const onMultipleExpenseUpdate = () => async (dispatch: Dispatch<any>) => {
    dispatch(refetchExpenseTables())
    dispatch(fetchDashboardExpensesCounts())

    if (getTableFilterQueryStringStore(TablesEnum.ARCHIVE)) {
        dispatch(refetchTable(TablesEnum.ARCHIVE))
        dispatch(fetchArchivedExpensesCounts())
    }

    setShouldRefetchExpenses(true)(dispatch)
}

export const updateDocuments = (id: string, kind: DocumentTypeEnum, documentData: any, operation: OperationTypeEnum) => async (dispatch: Dispatch<any>) => {
    const expense = await ExpenseHttpService.updateDocuments(id, kind, documentData, operation)
    if (expense?.invoices?.length === 1) {
        dispatch(refetchTable(TablesEnum.INBOX_INVOICE))
        dispatch(fetchDashboardInboxInvoicesCount())
    }
    return onExpenseUpdate(expense)(dispatch)
}

export const deleteExpense = (id: string) => async (dispatch: Dispatch<any>) => {
    const expense = await ExpenseHttpService.deleteExpense(id)

    const deleteExpenseAction: DeleteExpenseAction = {
        type: ExpenseActionTypes.DELETE_EXPENSE,
        expense,
    }

    dispatch(deleteExpenseAction)
    setShouldRefetchExpenses(true)(dispatch)
    dispatch(refetchExpenseTables())
    dispatch(fetchDashboardExpensesCounts())

    if (getTableFilterQueryStringStore(TablesEnum.ARCHIVE)) {
        // If the user uses the archive tab
        dispatch(refetchTable(TablesEnum.ARCHIVE))
        dispatch(fetchArchivedExpensesCounts())
    }

    return expense
}

export const pauseSubscription = (id: string) => async (dispatch: Dispatch) => {
    const expense = await ExpenseHttpService.pauseSubscription(id)

    const updateExpenseAction: UpdateExpenseAction = {
        type: ExpenseActionTypes.UPDATE_EXPENSE,
        expense,
    }

    dispatch(refetchTable(TablesEnum.SUBSCRIPTIONS))
    dispatch(updateExpenseAction)
    return expense
}

export const unpauseSubscription = (id: string) => async (dispatch: Dispatch) => {
    const expense = await ExpenseHttpService.unpauseSubscription(id)

    const updateExpenseAction: UpdateExpenseAction = {
        type: ExpenseActionTypes.UPDATE_EXPENSE,
        expense,
    }

    dispatch(refetchTable(TablesEnum.SUBSCRIPTIONS))
    dispatch(updateExpenseAction)
    return expense
}

export const updateComments: ActionCreator<ThunkResult<Expense>> =
    (id: string, comment: CommentItem, operation: OperationTypeEnum.INSERT | OperationTypeEnum.DELETE | OperationTypeEnum.UPDATE) => async (dispatch: Dispatch) => {
        let expense

        switch (operation) {
            case OperationTypeEnum.INSERT:
                expense = await ExpenseHttpService.createComment(id, comment)
                break
            case OperationTypeEnum.DELETE:
                expense = await ExpenseHttpService.deleteComment(id, comment)
                break
            case OperationTypeEnum.UPDATE:
                expense = await ExpenseHttpService.updateComment(id, comment)
                break
            default:
                const exhaustiveCheck: never = operation
                throw new Error(exhaustiveCheck)
        }

        const updateExpenseAction: UpdateExpenseAction = {
            type: ExpenseActionTypes.UPDATE_EXPENSE,
            expense,
        }

        dispatch(updateExpenseAction)
        return expense
    }

export const updateRealTimeComments: ActionCreator<ThunkResult<Array<CommentItem>>> = (id: string, comments: Array<CommentItem>) => async (dispatch: Dispatch) => {
    const updateExpenseAction: UpdateRealTimeComments = {
        type: ExpenseActionTypes.UPDATE_EXPENSE_COMMENTS,
        id,
        comments,
    }

    dispatch(updateExpenseAction)
    return comments
}

export const createExpense = (expense: Expense | Mileage | Hospitality | PerDiem) => async (dispatch: Dispatch<any>) => {
    const newExpense = await ExpenseHttpService.createExpense(expense)
    onExpenseCreation(dispatch)
    return newExpense
}

export const createPreApprovedSubscription = (expense: Expense | Mileage | Hospitality | PerDiem) => async (dispatch: Dispatch<any>) => {
    const newExpense = await ExpenseHttpService.createPreApprovedSubscription(expense)
    onExpenseCreation(dispatch)
    return newExpense
}

export const createRecurringExpense = (expense: Expense) => async (dispatch: Dispatch<any>) => {
    const newExpense = await ExpenseHttpService.createRecurringExpense(expense)
    onExpenseCreation(dispatch)
    return newExpense
}

const onExpenseCreation = async (dispatch: Dispatch<any>) => {
    // reset refetch flag
    setShouldRefetchExpenses(true)(dispatch)
    dispatch(refetchExpenseTables())
    dispatch(fetchDashboardExpensesCounts())
}

export const sendReminder = (expenseId: string) => async (dispatch: Dispatch) => {
    await ExpenseHttpService.sendReminder(expenseId)
    const sendExpenseReminderActions: SendExpenseReminderAction = {
        type: ExpenseActionTypes.SEND_REMINDER,
    }

    dispatch(sendExpenseReminderActions)
}

export const setActiveTab: ActionCreator<ThunkResult<void>> = (activeTab: TabTypeEnum) => async (dispatch: Dispatch) => {
    const setActiveTabAction: SetActiveTabAction = {
        type: ExpenseActionTypes.SET_ACTIVE_TAB,
        activeTab,
    }

    dispatch(setActiveTabAction)
}

export const setShouldRefetchExpenses = (shouldRefetch: boolean) => async (dispatch: Dispatch) => {
    const setShouldRefetchAction: SetShouldRefetchExpensesAction = {
        type: ExpenseActionTypes.SET_SHOULD_REFETCH_EXPENSES,
        shouldRefetch,
    }

    dispatch(setShouldRefetchAction)
}

export const attachTagExpense = (expenseTagId: string, expenseId: string) => async (dispatch: Dispatch) => {
    await ExpenseHttpService.attachTagToExpense(expenseTagId, expenseId)
    const attachTagExpenseAction: AttachTagExpenseAction = {
        type: ExpenseActionTypes.ATTACH_TAG_EXPENSE,
        expenseTagId,
        expenseId,
    }

    dispatch(attachTagExpenseAction)
}

export const detachTagExpense = (expenseTagId: string, expenseId: string) => async (dispatch: Dispatch) => {
    await ExpenseHttpService.detachTagFromExpense(expenseTagId, expenseId)

    const detachTagExpenseAction: DetachTagExpenseAction = {
        type: ExpenseActionTypes.DETACH_TAG_EXPENSE,
        expenseTagId,
    }

    dispatch(detachTagExpenseAction)
}

export const setReminder =
    (expenseId: string, { date, operation }: { date: Date; operation: OperationTypeEnum.INSERT | OperationTypeEnum.UPDATE }) =>
    async (dispatch: Dispatch) => {
        let expense

        switch (operation) {
            case OperationTypeEnum.INSERT:
                expense = await ExpenseHttpService.createReminder(expenseId, { date })
                break
            case OperationTypeEnum.UPDATE:
                expense = await ExpenseHttpService.updateReminder(expenseId, { date })
                break
        }

        const updateExpenseAction: UpdateExpenseAction = {
            type: ExpenseActionTypes.UPDATE_EXPENSE,
            expense,
        }
        dispatch(updateExpenseAction)
        dispatch(refetchExpenseTables())
        onExpenseUpdate(expense)(dispatch)
    }

export const deleteReminder = (expenseId: string) => async (dispatch: Dispatch) => {
    const expense = await ExpenseHttpService.deleteReminder(expenseId)

    const updateExpenseAction: UpdateExpenseAction = {
        type: ExpenseActionTypes.UPDATE_EXPENSE,
        expense,
    }

    dispatch(updateExpenseAction)
    dispatch(refetchExpenseTables())
    onExpenseUpdate(expense)(dispatch)
}

export const submitDiscounts = (expenseId: string, discounts: Array<DiscountRule>) => async (dispatch: Dispatch<any>) => {
    const discountsResponse = await ExpenseHttpService.submitDiscounts(expenseId, discounts)

    const submitDiscountsAction: SubmitDiscountsAction = {
        type: ExpenseActionTypes.SUBMIT_DISCOUNTS,
        expenseId,
        discounts: discountsResponse,
    }

    dispatch(submitDiscountsAction)
    dispatch(syncExpense(expenseId))
    dispatch(refetchExpenseTables())
}

export const deleteDiscounts = (expenseId: string) => async (dispatch: Dispatch) => {
    await ExpenseHttpService.deleteDiscounts(expenseId)

    const deleteDiscountsAction: DeleteDiscountsAction = {
        type: ExpenseActionTypes.DELETE_DISCOUNTS,
        expenseId,
    }

    dispatch(deleteDiscountsAction)
    await syncExpense(expenseId)(dispatch)
    dispatch(refetchExpenseTables())
}

export const fetchExpenseCard = (id: string) => async (dispatch: Dispatch) => {
    try {
        const card = await ExpenseHttpService.fetchExpenseCard(id)

        const fetchOneCard: FetchExpenseCardAction = {
            type: ExpenseActionTypes.FETCH_EXPENSE_CARD,
            card,
        }

        dispatch(fetchOneCard)

        return card
    } catch (err) {
        NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:error"))
    }
}
