import { Company } from "@finway-group/shared/lib/models"
import { Form, Modal } from "antd"
import { Store } from "antd/lib/form/interface"
import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import BankingAndCardForm from "Components/forms/featureSettings/bankingAndCard.form"
import DashboardAndWorkflowForm from "Components/forms/featureSettings/dashboardAndWorkflow.form"
import ExportAndBookkeepingForm from "Components/forms/featureSettings/exportAndBookkeeping.form"
import GeneralTabForm from "Components/forms/featureSettings/generalTab.form"
import { FeatureSettingsTabEnum } from "Features/settings/features/featureSettings"
import { NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { updateCompanyFeatureSettings } from "Shared/store/actions/company/companyActions"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

interface FeatureSettingsModalProps {
    isShowing: boolean
    company: Company
    onCancel: () => void
    activeTab: FeatureSettingsTabEnum
}

const FeatureSettingsModal = ({ isShowing, company, onCancel, activeTab }: FeatureSettingsModalProps) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const [formInstance] = Form.useForm()

    const [isLoading, setIsLoading] = useStateIfMounted(false)

    const onSubmit = async (values: Store) => {
        setIsLoading(true)

        const updatedCompany: any = {
            ...values,
        }

        try {
            await dispatch(updateCompanyFeatureSettings(company._id, updatedCompany))
            NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:settings.company.updated.title"), t("notification:settings.company.updated.message"))
            onHide()
        } catch (error) {
            setIsLoading(false)
            NotificationService.showErrorNotificationBasedOnResponseError(error, t("error:settings.edit.title"))
        }
    }

    const onHide = () => {
        setIsLoading(false)
        onCancel()
    }

    const displayForm = () => {
        switch (activeTab) {
            case FeatureSettingsTabEnum.GENERAL:
                return <GeneralTabForm formInstance={formInstance} onSubmit={onSubmit} company={company} />
            case FeatureSettingsTabEnum.DASHBOARD_AND_WORKFLOW:
                return <DashboardAndWorkflowForm formInstance={formInstance} onSubmit={onSubmit} company={company} />
            case FeatureSettingsTabEnum.BANKING_AND_CARDS:
                return <BankingAndCardForm formInstance={formInstance} onSubmit={onSubmit} company={company} />
            case FeatureSettingsTabEnum.EXPORT_AND_BOOKKEEPING:
                return <ExportAndBookkeepingForm formInstance={formInstance} onSubmit={onSubmit} company={company} />
            default:
                return <> </>
        }
    }

    return (
        <Modal
            title={t("action:feature_settings.edit")}
            visible={isShowing}
            className="ant-modal--large"
            maskClosable={false}
            onOk={formInstance.submit}
            onCancel={onHide}
            okText={t("action:company_settings.save")}
            cancelText={t("action:cancel")}
            confirmLoading={isLoading}
            keyboard
            destroyOnClose
        >
            {displayForm()}
        </Modal>
    )
}

export default React.memo(FeatureSettingsModal)
