import { useEffect } from "react"

import useStateIfMounted from "./useStateIfMounted"

export const useResizeWindowWidth = () => {
    const [windowWidth, setWindowWidth] = useStateIfMounted(isSafari ? document.documentElement.clientWidth : window.innerWidth)

    useEffect(() => {
        const handleResizeWindow = () => setWindowWidth(isSafari ? document.documentElement.clientWidth : window.innerWidth)
        window.addEventListener("resize", handleResizeWindow)
        handleResizeWindow()
        return () => window.removeEventListener("resize", handleResizeWindow)
    }, [])

    return windowWidth
}

const isSafari = navigator.userAgent.indexOf("Safari") != -1 && navigator.userAgent.indexOf("Chrome") == -1
