import { ExclamationCircleOutlined } from "@ant-design/icons"
import { ExpenseAccount } from "@finway-group/shared/lib/models"
import { isExpenseAccountCodeDATEVCompliant } from "@finway-group/shared/lib/utils/datevValidation"
import { Button, Checkbox, Form, Modal, Tooltip } from "antd"
import { Store } from "antd/lib/form/interface"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import ExpenseAccountForm from "Components/forms/expenseAccount.form"
import { useCompany } from "Shared/hooks/company.hooks"
import { ExpenseAccountService, NotificationService } from "Shared/services"
import DialogService from "Shared/services/dialog.service"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { createExpenseAccounts, updateExpenseAccounts } from "Shared/store/actions/expenseAccount/expenseAccountActions"
import { getTooltipPopupContainer } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

interface ExpenseAccountModalProps {
    isShowing: boolean
    expenseAccounts: Array<ExpenseAccount>
    isEdit?: boolean
    onCancel: () => void
}

const ExpenseAccountModal = ({ expenseAccounts, isShowing, onCancel, isEdit = true }: ExpenseAccountModalProps) => {
    const { t } = useTranslation()
    const [formInstance] = Form.useForm()
    const [isLoading, setIsLoading] = useStateIfMounted(false)
    const [shouldSyncExpenses, setShouldSyncExpenses] = useStateIfMounted(false)
    const allowSpecialCharCodes = useCompany()?.allowSpecialCharactersInExpenseAccountCodesForDatev

    const dispatch = useDispatch()

    useEffect(() => {
        if (isShowing) {
            formInstance.setFieldsValue({ expenseAccounts, isEdit })
            setShouldSyncExpenses(false)
        }
    }, [isShowing])

    const onSubmit = async (values: Store) => {
        try {
            // extra variable to change it after confirmation dialog
            let shouldSynchronizeExpenses = shouldSyncExpenses

            const datevIncompatible = values.expenseAccounts.some((value: ExpenseAccount) => !isExpenseAccountCodeDATEVCompliant(value.accountCode))

            if (datevIncompatible) {
                if (!allowSpecialCharCodes) {
                    NotificationService.send(NotificationTypeEnum.ERROR, t("error:expense_account.incompatible.title"), t("error:expense_account.incompatible.message"))
                    return
                }
                const isConfirmed = await DialogService.confirmExpenseAccountCodeDatevCompliance()
                if (!isConfirmed) return
            }

            if (!shouldSynchronizeExpenses && isEdit && (await DialogService.confirmSync())) {
                shouldSynchronizeExpenses = true
            }

            const count = values.expenseAccounts.length
            const msgKey = isEdit ? "updated" : "saved"

            setIsLoading(true)
            if (isEdit) {
                await dispatch(updateExpenseAccounts(values.expenseAccounts))
                if (shouldSynchronizeExpenses) await ExpenseAccountService.syncExpenses(values.expenseAccounts)
            } else {
                await dispatch(createExpenseAccounts(values.expenseAccounts))
            }

            NotificationService.send(
                NotificationTypeEnum.SUCCESS,
                t(`notification:settings.expense_account.${msgKey}.title`),
                t(`notification:settings.expense_account.${msgKey}.message`, { count }),
            )

            onHide()
        } catch (err) {
            setIsLoading(false)
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:expense_account.update.title"))
        }
    }

    const onHide = () => {
        setIsLoading(false)
        onCancel()
    }

    return (
        <Modal
            title={t("action:expense_account_settings.expense_accounts")}
            visible={isShowing}
            maskClosable={false}
            onCancel={onHide}
            confirmLoading={isLoading}
            keyboard
            destroyOnClose
            footer={
                <div className="flex justify-between items-center">
                    {isEdit && (
                        <div>
                            <Checkbox className="ml-8" onChange={(e) => setShouldSyncExpenses(e.target.checked)}>
                                {t("input:sync_updates")}
                            </Checkbox>
                            <Tooltip getPopupContainer={getTooltipPopupContainer} title={t("tooltips:synch_expenses:expense_account")} placement="top" className="align-middle">
                                <ExclamationCircleOutlined />
                            </Tooltip>
                        </div>
                    )}
                    <div className="ml-auto mr-0">
                        <Button key="back" onClick={onHide}>
                            {t("action:cancel")}
                        </Button>

                        <Button key="submit" type="primary" onClick={formInstance.submit}>
                            {t(`action:expense_account_settings.${isEdit ? "edit" : "save"}`)}
                        </Button>
                    </div>
                </div>
            }
        >
            <ExpenseAccountForm formInstance={formInstance} onSubmit={onSubmit} isEdit={isEdit} expenseAccounts={expenseAccounts} />
        </Modal>
    )
}

export default ExpenseAccountModal
