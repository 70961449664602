import { Input } from "antd"
import Form, { FormInstance } from "antd/lib/form"
import { Store } from "antd/lib/form/interface"
import React from "react"
import { useTranslation } from "react-i18next"

import { validateCreditorNumberDATEVCompatibility } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

import { EmployeeRules } from "./rules"

interface EmployeeCreditorNumberFormInterface {
    formInstance: FormInstance
    onSubmit: (values: Store) => void
}
const EmployeeCreditorNumberForm: React.FC<EmployeeCreditorNumberFormInterface> = ({ formInstance, onSubmit }) => {
    const { t } = useTranslation()
    const { creditorNumber } = EmployeeRules([], formInstance)
    const [creditorNumberInfo, setCreditorNumberInfo] = useStateIfMounted<string | undefined>(undefined)

    const onCreditorNumberChange = (e: any) => {
        try {
            validateCreditorNumberDATEVCompatibility(e.target.value)
            setCreditorNumberInfo(undefined)
        } catch (err) {
            setCreditorNumberInfo(t("error:vendor.incompatible_creditor_number"))
        }
    }

    return (
        <Form form={formInstance} layout="vertical" onFinish={onSubmit} autoComplete="off">
            <Form.Item
                name={["creditorNumber"]}
                label={t("input:vendor.creditor_number")}
                key="creditorNumber"
                required
                rules={creditorNumber}
                help={creditorNumberInfo}
                validateStatus={creditorNumberInfo ? "warning" : undefined}
            >
                <Input placeholder={t("placeholder:employee.creditor_number")} onChange={onCreditorNumberChange} />
            </Form.Item>
        </Form>
    )
}

export default EmployeeCreditorNumberForm
