import { WarningFilled } from "@ant-design/icons"
import { Currencies } from "@finway-group/shared/lib/consts"
import { CsvDelimiterTypeEnum, CurrencyEnum, RightEnum, UserSettingsInterface, currencyEnumArray } from "@finway-group/shared/lib/models"
import { CsvNumberDecimalSeparatorTypeEnum, UserLanguageEnum } from "@finway-group/shared/lib/models/user/userSettings.interface"
import { Alert, Col, Form, Image, Row, Select, Switch } from "antd"
import { FormInstance } from "antd/lib/form"
import { Store } from "antd/lib/form/interface"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"

import { useLoggedInEmployeeProfile } from "Shared/hooks/user.hooks"
import { AuthzService } from "Shared/services"
import { doFilterSelect } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"
import { getPopupAnchor } from "Shared/utils/popup.utils"

import { SettingRules } from "./rules"

const { Option } = Select

interface SettingsFormInterface {
    userSettings: UserSettingsInterface | any
    formInstance: FormInstance
    onSubmit: (values: Store) => void
}
const SettingsForm: React.FC<SettingsFormInterface> = ({
    userSettings: {
        globalCurrency,
        showExpenseNumbers,
        enableOcr,
        enableOcrItemization,
        enableGoToNextExpense,
        showGrossAmount,
        csvDelimiter,
        csvNumberDecimalSeparator,
        language,
    },
    formInstance,
    onSubmit,
}) => {
    const { t } = useTranslation()
    const rules = SettingRules()
    const currentEmployee = useLoggedInEmployeeProfile()

    const [isOcrEnabled, setIsOcrEnabled] = useStateIfMounted(enableOcr)

    useEffect(() => {
        formInstance.setFieldsValue({ globalCurrency, showExpenseNumbers, enableOcr, enableOcrItemization, enableGoToNextExpense, showGrossAmount, language })
    }, [])

    return (
        <Form form={formInstance} layout="vertical" onFinish={onSubmit}>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Form.Item label={t("input:settings.global_currency")} name="globalCurrency" rules={rules.globalCurrency} initialValue={globalCurrency}>
                        <Select showSearch filterOption={doFilterSelect} getPopupContainer={getPopupAnchor()}>
                            {currencyEnumArray.map((currency: CurrencyEnum, index: number) => (
                                <Option key={index} value={currency} label={currency}>
                                    <div className="space-x-10">
                                        <Image className="align-middle" width={20} preview={false} src={`data:image/png;base64,${Currencies[currency].flag}`} />
                                        <span className="text-text">{currency}</span>
                                        <span className="text-text">-</span>
                                        <span className="text-text">{Currencies[currency].name}</span>
                                    </div>
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Form.Item label={t("label:language")} name="language" initialValue={language === UserLanguageEnum.DE ? t("label:german") : t("label:english")}>
                        <Select getPopupContainer={getPopupAnchor()}>
                            <Option value={"de"} label={t("label:german")}>
                                {t("label:german")}
                            </Option>
                            <Option value={"en"} label={t("label:english")}>
                                {t("label:english")}
                            </Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={[16, 16]} align="bottom">
                <Col span={12} className="min-w-200">
                    <Form.Item
                        label={t("input:settings.show_expense_numbers")}
                        name="showExpenseNumbers"
                        rules={rules.showExpenseNumbers}
                        initialValue={showExpenseNumbers}
                        valuePropName="checked"
                    >
                        <Switch defaultChecked={showExpenseNumbers} />
                    </Form.Item>
                </Col>
                <Col span={12} className="min-w-100">
                    <Form.Item label={t("input:settings.show_gross")} name="showGrossAmount" initialValue={showGrossAmount} valuePropName="checked">
                        <Switch defaultChecked={showGrossAmount} />
                    </Form.Item>
                </Col>
                <Col span={12} className="min-w-100">
                    <Form.Item label={t("input:settings.enable_ocr")} name="enableOcr" initialValue={enableOcr} valuePropName="checked">
                        <Switch data-testid="settingsOCRToggle" defaultChecked={enableOcr} onChange={(checked) => setIsOcrEnabled(checked)} />
                    </Form.Item>
                </Col>
                <Col span={12} className="min-w-100">
                    <Form.Item label={t("input:settings.enable_ocr_itemization")} name="enableOcrItemization" initialValue={enableOcrItemization} valuePropName="checked">
                        <Switch data-testid="settingsInlineToggle" defaultChecked={enableOcrItemization} disabled={!isOcrEnabled} />
                    </Form.Item>
                </Col>
                {AuthzService.isRightGrantedForLoggedInUser(RightEnum.EXPENSE__BASIC__APPROVE) && (
                    <Col span={12} className="min-w-100">
                        <Form.Item label={t("input:settings.enable_go_to_next_expense")} name="enableGoToNextExpense" initialValue={enableGoToNextExpense} valuePropName="checked">
                            <Switch defaultChecked={enableGoToNextExpense} />
                        </Form.Item>
                    </Col>
                )}
            </Row>

            <Alert
                className="mb-20"
                message={
                    <>
                        <div>{t("info:csv_number_separator:title")}</div>
                        <ul>
                            <li>
                                <span className="font-bold">{t("info:csv_number_separator:english_bold")}</span>
                                {t("info:csv_number_separator:english")}
                            </li>
                            <li>
                                <span className="font-bold">{t("info:csv_number_separator:german_bold")}</span>
                                {t("info:csv_number_separator:german")}
                            </li>
                        </ul>
                    </>
                }
                type="warning"
                showIcon
                icon={<WarningFilled />}
            />

            <Row>
                <Col span={12} className="min-w-100">
                    <Form.Item label={t("input:settings.csv_delimiter")} name="csvDelimiter" initialValue={csvDelimiter}>
                        <Select className="w-140" getPopupContainer={getPopupAnchor()}>
                            <Option value={CsvDelimiterTypeEnum.COMMA} key={CsvDelimiterTypeEnum.COMMA}>
                                {t("label:csv_delimiter_type.comma")}
                            </Option>
                            <Option value={CsvDelimiterTypeEnum.SEMICOLON} key={CsvDelimiterTypeEnum.SEMICOLON}>
                                {t("label:csv_delimiter_type.semicolon")}
                            </Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={12} className="min-w-100">
                    <Form.Item
                        label={t("input:settings.number_separator")}
                        name="csvNumberDecimalSeparator"
                        initialValue={csvNumberDecimalSeparator ?? CsvNumberDecimalSeparatorTypeEnum.COMMA}
                    >
                        <Select className="w-140" getPopupContainer={getPopupAnchor()}>
                            <Option value={CsvNumberDecimalSeparatorTypeEnum.DOT} key={CsvNumberDecimalSeparatorTypeEnum.DOT}>
                                {t("label:number_separator_type.dot")}
                            </Option>
                            <Option value={CsvNumberDecimalSeparatorTypeEnum.COMMA} key={CsvNumberDecimalSeparatorTypeEnum.COMMA}>
                                {t("label:number_separator_type.comma")}
                            </Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    )
}

export default SettingsForm
