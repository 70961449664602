import { RightEnum } from "@finway-group/shared/lib/models"
import { Button, Tabs } from "antd"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import PageCustomHeader from "Components/PageCustomHeader"
import WalletIcon from "Components/icons/walletIcon"
import WalletFundingModal from "Components/modals/walletFunding.modal"
import WalletWithdrawalModal from "Components/modals/walletWithdrawal.modal"
import { useWallet } from "Shared/hooks/corporate.hooks"
import { useLoggedInEmployee } from "Shared/hooks/employee.hooks"
import { useIsOutgoingWireTransferEnabled } from "Shared/hooks/featureFlags.hooks"
import { AuthzService, NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { fetchWallet } from "Shared/store/actions/corporate/corporateActions"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

import { FINWAY_ADMIN_EMAIL } from "../../../../shared/config/consts"
import WalletService from "../../../../shared/services/wallet.service"
import FinishWallet from "../kyb/finishWallet"
import AccountStatements from "./accountStatement"
import FundingHistory from "./fundingHistory"

const { TabPane } = Tabs

enum WalletActiveTab {
    ACCOUNT_STATEMENTS = "ACCOUNT_STATEMENTS",
    FUNDING_HISTORY = "FUNDING_HISTORY",
}

const WalletDetailsContainer = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [activeTab, setActiveTab] = useStateIfMounted<any>(WalletActiveTab.ACCOUNT_STATEMENTS)
    const [isWalletFundingModalVisible, setIsWalletFundingModalVisible] = useStateIfMounted(false)
    const [isWalletWithdrawalModalVisible, setIsWalletWithdrawalModalVisible] = useStateIfMounted(false)
    const loggedInUser = useLoggedInEmployee()
    const isOutgoingWireTransferEnabled = useIsOutgoingWireTransferEnabled()
    const wallet = useWallet()

    useEffect(() => {
        if (!wallet.accountId) {
            dispatch(fetchWallet())
        }
    }, [])

    const onRegenerateAllStatements = async () => {
        try {
            await WalletService.regenerateAllAccountStatements()
            NotificationService.send(
                NotificationTypeEnum.INFO,
                t("notification:cards.account_statement_regenerating.title"),
                t("notification:cards.account_statement_regenerating.message"),
            )
        } catch (err) {
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:error"))
        }
    }

    const onGenerateOutstandingStatements = async () => {
        try {
            await WalletService.generateOutstandingAccountStatements()
            NotificationService.send(
                NotificationTypeEnum.INFO,
                t("notification:cards.account_statement_regenerating.title"),
                t("notification:cards.account_statement_regenerating.message"),
            )
        } catch (err) {
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:error"))
        }
    }

    return (
        <div>
            <PageCustomHeader
                title={
                    <div className="flex">
                        <h1 className="-mb-2">{t("label:wallet.wallet_details")}</h1>
                    </div>
                }
                actionButtons={
                    !!wallet?.iban &&
                    AuthzService.isRightGrantedForLoggedInUser(RightEnum.CARD_WALLET__ALL__MANAGE) && (
                        <div className="flex flex-row space-x-10 items-center">
                            {loggedInUser.activeCompanyProfile?.weavrData?.isPasswordCreated && isOutgoingWireTransferEnabled && (
                                <Button onClick={() => setIsWalletWithdrawalModalVisible(true)}>
                                    <WalletIcon />
                                    <span>{t("action:wallet.withdrawal")}</span>
                                </Button>
                            )}
                            <Button onClick={() => setIsWalletFundingModalVisible(true)} type="primary">
                                <WalletIcon className="mb-2" />
                                <span className="mt-0">{t("action:wallet.funding")}</span>
                            </Button>
                            {loggedInUser.email === FINWAY_ADMIN_EMAIL && (
                                <>
                                    <Button onClick={onRegenerateAllStatements}>
                                        <span className="mt-0">Regenerate all statements</span>
                                    </Button>
                                    <Button onClick={onGenerateOutstandingStatements}>
                                        <span className="mt-0">Generate outstanding statements</span>
                                    </Button>
                                </>
                            )}
                        </div>
                    )
                }
            />
            {wallet.accountId && !wallet?.iban ? (
                <FinishWallet />
            ) : (
                <>
                    <Tabs defaultActiveKey={WalletActiveTab.ACCOUNT_STATEMENTS} activeKey={activeTab} className="pb-10" onTabClick={(key) => setActiveTab(key)}>
                        <TabPane tab={t("creditCard:label.account_statement")} key={WalletActiveTab.ACCOUNT_STATEMENTS}>
                            <AccountStatements />
                        </TabPane>

                        <TabPane tab={t("creditCard:label.funding_history")} key={WalletActiveTab.FUNDING_HISTORY}>
                            <FundingHistory />
                        </TabPane>
                    </Tabs>
                </>
            )}
            <WalletFundingModal isVisible={isWalletFundingModalVisible} handleCancel={() => setIsWalletFundingModalVisible(false)} />
            {isOutgoingWireTransferEnabled && <WalletWithdrawalModal isVisible={isWalletWithdrawalModalVisible} handleCancel={() => setIsWalletWithdrawalModalVisible(false)} />}
        </div>
    )
}

export default WalletDetailsContainer
