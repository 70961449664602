import { CurrencyExchange } from "@finway-group/shared/lib/models"
import { Reducer } from "redux"

import { CurrencyActionTypes, CurrencyActions, FetchCurrencyExchangeAction } from "../actions/currency/currencyTypes"

export interface CurrencyState {
    item?: CurrencyExchange
}

const initialState = {
    item: undefined,
}

const fetchCurrencyExchange = (state: CurrencyState, action: FetchCurrencyExchangeAction): CurrencyState => ({
    ...state,
    item: action.currencyExchange,
})

export const currencyReducer: Reducer<CurrencyState, CurrencyActions> = (state = initialState, action) => {
    switch (action.type) {
        case CurrencyActionTypes.FETCH_CURRENCY_EXCHANGE:
            return fetchCurrencyExchange(state, action)
        default:
            return state
    }
}
