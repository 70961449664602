import { Expense } from "@finway-group/shared/lib/models"
import { DatevOnlineJobStatusEnum } from "@finway-group/shared/lib/utils/datevOnlineJobStatus.enum"
import { Alert, Button } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"

import { ExpenseHttpService, NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { getErrorMessageForDuoExportFailure } from "Shared/utils/expense.utils"

interface DatevOnlineJobBannerInterface {
    expense: Expense
}

const DatevOnlineJobBanner: React.FC<DatevOnlineJobBannerInterface> = ({ expense }) => {
    const { t } = useTranslation()

    const onCheckStatus = async () => {
        try {
            await ExpenseHttpService.checkDatevOnlineJobStatusForExpense(expense.id)
            NotificationService.send(
                NotificationTypeEnum.INFO,
                t("notification:request.datev_job_status_manual_check.title"),
                t("notification:request.datev_job_status_manual_check.message"),
            )
        } catch (err) {
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:error"))
        }
    }

    switch (expense.datevOnlineInformation?.status) {
        case DatevOnlineJobStatusEnum.RECEIVED:
        case DatevOnlineJobStatusEnum.PROCESSING:
            return (
                <Alert
                    type="info"
                    showIcon
                    className="mb-20"
                    message={
                        <div>
                            <span>{t("info:datev_export_job.pending")}</span>
                            <Button className="button-warning-notification ml-4 pt-2" onClick={onCheckStatus} type="text">
                                {t("info:datev_export_job.pending_button")}
                            </Button>
                        </div>
                    }
                />
            )
        case DatevOnlineJobStatusEnum.ERROR:
            return (
                <Alert
                    type="error"
                    showIcon
                    className="mb-20"
                    message={
                        <div>
                            <span>{getErrorMessageForDuoExportFailure(expense.datevOnlineInformation?.error)}</span>
                        </div>
                    }
                />
            )
        default:
            return <></>
    }
}

export default DatevOnlineJobBanner
