import { ExclamationCircleOutlined } from "@ant-design/icons"
import { Form, Switch, Tooltip } from "antd"
import React from "react"

import { getTooltipPopupContainer } from "Shared/utils/helper.utils"

interface EmailNotificationInterface {
    emailNotificationConfigs: Array<{ name: string; title: string; description: string; tooltip: string }>
}
const EmailNotificationSettings = ({ emailNotificationConfigs }: EmailNotificationInterface) => (
    <div>
        <ul className="description-list">
            {emailNotificationConfigs.map((section, index) => (
                <li key={index} className="flex">
                    <div className="flex flex-1 flex-col justify-center">
                        <span className="title font-bold flex items-center justify-start">
                            {section.title}
                            {section.tooltip && (
                                <Tooltip getPopupContainer={getTooltipPopupContainer} title={section.tooltip} className="p-5 hidden md:block">
                                    <ExclamationCircleOutlined />
                                </Tooltip>
                            )}

                            <Tooltip getPopupContainer={getTooltipPopupContainer} title={section.description} className="p-5 md:hidden">
                                <ExclamationCircleOutlined />
                            </Tooltip>
                        </span>
                        <p className="hidden md:block w-full pr-20 text-left">{section.description}</p>
                    </div>
                    <div className="flex text text-right items-center flex-no-wrap">
                        <Form.Item name={section.name} valuePropName="checked">
                            <Switch className="mr-10" />
                        </Form.Item>
                    </div>
                </li>
            ))}
        </ul>
    </div>
)

export default EmailNotificationSettings
