import { MAX_DATEV_CLIENT_NUMBER, MAX_DATEV_CONSULTANT_NUMBER, MIN_DATEV_CONSULTANT_NUMBER } from "@finway-group/shared/lib/consts"
import { DatevContextInterface } from "@finway-group/shared/lib/models"
import { FormInstance, Rule } from "antd/lib/form"
import i18n from "i18next"

const DatevClientAndConsultantRules = (): Record<keyof Pick<DatevContextInterface, "clientNumber" | "consultantNumber">, Array<Rule>> => ({
    clientNumber: [
        () => ({
            validator: (_rule: any, value: string) => {
                if (!value) {
                    return Promise.resolve()
                }
                if (Number(value) > MAX_DATEV_CLIENT_NUMBER) return Promise.reject(new Error(i18n.t("validation:max.number", { max: MAX_DATEV_CLIENT_NUMBER })))

                return Promise.resolve()
            },
        }),
    ],
    consultantNumber: [
        () => ({
            validator: (_rule: any, value: string) => {
                if (!value) {
                    return Promise.resolve()
                }
                if (Number(value) > MAX_DATEV_CONSULTANT_NUMBER) {
                    return Promise.reject(new Error(i18n.t("validation:numeric_min_max", { min: MIN_DATEV_CONSULTANT_NUMBER, max: MAX_DATEV_CONSULTANT_NUMBER })))
                }
                if (Number(value) < MIN_DATEV_CONSULTANT_NUMBER) {
                    return Promise.reject(new Error(i18n.t("validation:numeric_min_max", { min: MIN_DATEV_CONSULTANT_NUMBER, max: MAX_DATEV_CONSULTANT_NUMBER })))
                }

                return Promise.resolve()
            },
        }),
    ],
})

export default DatevClientAndConsultantRules
