import { TransactionCategory } from "@finway-group/shared/lib/models"
import { Action } from "redux"

export enum TransactionCategoryActionTypes {
    FETCH_ALL_TRANSACTION_CATEGORIES = "FETCH_ALL_TRANSACTION_CATEGORIES",
    CREATE_TRANSACTION_CATEGORY = "CREATE_TRANSACTION_CATEGORY",
    UPDATE_TRANSACTION_CATEGORY = "UPDATE_TRANSACTION_CATEGORY",
    DELETE_TRANSACTION_CATEGORY = "DELETE_TRANSACTION_CATEGORY",
    UPDATE_TRANSACTION_CATEGORY_ORDER = "UPDATE_TRANSACTION_CATEGORY_ORDER",
    SYNC_ONE_TRANSACTION_CATEGORY = "SYNC_ONE_TRANSACTION_CATEGORY",
}

export interface FetchAllTransactionCategoryAction extends Action<typeof TransactionCategoryActionTypes.FETCH_ALL_TRANSACTION_CATEGORIES> {
    type: typeof TransactionCategoryActionTypes.FETCH_ALL_TRANSACTION_CATEGORIES
    transactionCategories: Array<TransactionCategory>
}

export interface CreateTransactionCategoryAction extends Action<typeof TransactionCategoryActionTypes.CREATE_TRANSACTION_CATEGORY> {
    type: typeof TransactionCategoryActionTypes.CREATE_TRANSACTION_CATEGORY
    transactionCategory: TransactionCategory
    parentTransactionCategory: TransactionCategory
}

export interface UpdateTransactionCategoryAction extends Action<typeof TransactionCategoryActionTypes.UPDATE_TRANSACTION_CATEGORY> {
    type: typeof TransactionCategoryActionTypes.UPDATE_TRANSACTION_CATEGORY
    transactionCategory: TransactionCategory
}

export interface DeleteTransactionCategoryAction extends Action<typeof TransactionCategoryActionTypes.DELETE_TRANSACTION_CATEGORY> {
    type: typeof TransactionCategoryActionTypes.DELETE_TRANSACTION_CATEGORY
    deletedTransactionCategoriesIds: Array<string>
    updatedParentTransactionCategory: TransactionCategory
}

export interface UpdateTransactionCategoryOrderAction extends Action<typeof TransactionCategoryActionTypes.UPDATE_TRANSACTION_CATEGORY_ORDER> {
    type: typeof TransactionCategoryActionTypes.UPDATE_TRANSACTION_CATEGORY_ORDER
    transactionCategories: Array<TransactionCategory>
}

export interface SyncOneTransactionCategoryAction extends Action<typeof TransactionCategoryActionTypes.SYNC_ONE_TRANSACTION_CATEGORY> {
    type: typeof TransactionCategoryActionTypes.SYNC_ONE_TRANSACTION_CATEGORY
    transactionCategory: TransactionCategory
}

export type TransactionCategoryActions =
    | FetchAllTransactionCategoryAction
    | CreateTransactionCategoryAction
    | UpdateTransactionCategoryAction
    | DeleteTransactionCategoryAction
    | UpdateTransactionCategoryOrderAction
    | SyncOneTransactionCategoryAction
