import { CloseOutlined, DeleteOutlined, EllipsisOutlined } from "@ant-design/icons"
import { CreateIntegrationType, IntegrationType, IntegrationTypeEnum } from "@finway-group/shared/lib/models"
import { Button, Dropdown, Form, Menu, Modal, Tabs } from "antd"
import { FieldData } from "rc-field-form/lib/interface"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import PersonioExclusionList from "Features/settings/integrations/personio/personioExclusionList"
import PersonioSettings from "Features/settings/integrations/personio/personioSettings"
import { useCreateIntegrationConfiguration, useDeleteIntegrationConfiguration, useUpdateIntegrationConfiguration } from "Shared/queries/integration.queries"
import { NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"
import { getPopupAnchor } from "Shared/utils/popup.utils"

import { PersonioActiveTabEnum } from "./personio.modal.type"

interface PersonioModalType {
    isShowing: boolean
    icon: JSX.Element
    connection?: IntegrationType
    onCancel: () => void
    refetch: Function
}

const { TabPane } = Tabs

const PersonioModal = ({ isShowing, icon, connection, onCancel, refetch }: PersonioModalType) => {
    const [tabsWithError, setTabsWithError] = useState<Array<string>>([])
    const [excludedUsers, setExcludedUsers] = useState<Array<string>>(connection?.attributes?.excludedUsers || [])
    const [isLoading, setIsLoading] = useStateIfMounted(false)
    const [formInstance] = Form.useForm()
    const [activeTabIndex, setActiveTabIndex] = useStateIfMounted<number>(0)

    const { t } = useTranslation()

    const createIntegrationConfiguration = useCreateIntegrationConfiguration()
    const updateIntegrationConfiguration = useUpdateIntegrationConfiguration()
    const deleteIntegrationConfiguration = useDeleteIntegrationConfiguration()

    useEffect(() => {
        if (connection) {
            setExcludedUsers(connection?.attributes?.excludedUsers || [])
        }
    }, [connection, isShowing])

    useEffect(() => {
        if (isShowing) formInstance.resetFields()
    }, [isShowing])

    const sharedCallback = {
        onSuccess: () => {
            NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:integrations.personio.title"), t("notification:integrations.personio.message"))
            onCancel()
        },
        onError: (error: unknown) => {
            NotificationService.showErrorNotificationBasedOnResponseError(error, t(`error:error`))
        },
        onSettled: () => {
            setIsLoading(false)
            refetch()
        },
    }

    const handlePotentialFieldError = (allFields: Array<FieldData>) => {
        const fieldErrors = allFields
            .filter(({ errors }) => {
                if (!errors) return
                return errors.length > 0
            })
            .map(({ name }) => name)
            .flat(1)

        // Determine on what tabs there are errors
        const filteredError = []
        if (fieldErrors.includes("clientId") || fieldErrors.includes("clientSecret")) filteredError.push(t("label:personio.settings"))
        if (fieldErrors.includes("excludedUsers")) filteredError.push(t("label:personio.exclusion_list"))

        setTabsWithError(filteredError)
    }

    const handleSubmit = async (data: Omit<CreateIntegrationType, "integrationType">) => {
        setIsLoading(true)
        if (!data) return

        // Add the excluded users from the select field to the ones in the table
        const newExcludedUserList = excludedUsers.concat(data.attributes?.excludedUsers ?? [])
        const newUniqueExcludedUserList = [...new Set(newExcludedUserList)]

        const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: "base" })
        newUniqueExcludedUserList.sort(collator.compare)

        if (!data.attributes) {
            data.attributes = {
                enableEmployeeSync: false,
                enableAbsenceSync: false,
                enableTeamSync: false,
                enableCreateEmployee: false,
                excludedUsers: newUniqueExcludedUserList,
            }
        } else {
            data.attributes.excludedUsers = newUniqueExcludedUserList
        }

        if (connection) {
            updateIntegrationConfiguration.mutate(
                {
                    id: connection._id!,
                    data: {
                        ...connection,
                        ...data,
                    },
                },
                {
                    ...sharedCallback,
                },
            )
        } else {
            createIntegrationConfiguration.mutate(
                {
                    ...data,
                    integrationType: IntegrationTypeEnum.PERSONIO,
                },
                {
                    ...sharedCallback,
                },
            )
        }
    }
    const handleDelete = async () => {
        if (connection) {
            deleteIntegrationConfiguration.mutate(connection._id!, {
                ...sharedCallback,
            })
        }
    }

    const tabs = [
        {
            title: t("label:personio.settings"),
            key: PersonioActiveTabEnum.SETTINGS,
            component: <PersonioSettings connection={connection} />,
            confirmText: connection?.isEnabled ? t("action:integrations.personio.update") : t("action:integrations.personio.connect"),
        },
        {
            title: t("label:personio.exclusion_list"),
            key: PersonioActiveTabEnum.EXCLUSION_LIST,
            component: <PersonioExclusionList formInstance={formInstance} excludedUsers={excludedUsers} setExcludedUsers={setExcludedUsers} />,
            confirmText: t("action:integrations.personio.save"),
        },
    ]

    return (
        <Modal
            className="ant-modal--small provider-menu-title ant-modal--personio"
            visible={isShowing}
            maskClosable={false}
            closable={false}
            title={
                <div className="ant-modal-title flex items-center gap-10 w-full">
                    {icon}

                    <div className="flex items-center justify-between w-full">
                        {t("label:personio.title")}
                        {connection ? (
                            <Dropdown
                                getPopupContainer={getPopupAnchor()}
                                className={"flex items-center"}
                                trigger={["click"]}
                                overlay={
                                    <Menu className="z-20 user-dropdown">
                                        <Menu.Item icon={<DeleteOutlined />} danger onClick={handleDelete}>
                                            {t("action:delete")}
                                        </Menu.Item>
                                    </Menu>
                                }
                            >
                                <EllipsisOutlined />
                            </Dropdown>
                        ) : (
                            <CloseOutlined onClick={onCancel} />
                        )}
                    </div>
                </div>
            }
            onCancel={onCancel}
            onOk={formInstance.submit}
            confirmLoading={isLoading}
            footer={[
                tabsWithError.length > 0 ? (
                    <p className="finway-red-text text-bold mb-5">{t("validation:personio.tabs", { count: tabsWithError.length, tab: tabsWithError.join(" & ") })}</p>
                ) : null,
                <Button
                    key="back"
                    onClick={() => {
                        formInstance.resetFields()
                        onCancel()
                    }}
                >
                    {t("action:cancel")}
                </Button>,
                <Button key="submit" type="primary" loading={isLoading} onClick={() => formInstance.submit()}>
                    {tabs[activeTabIndex].confirmText}
                </Button>,
            ]}
            destroyOnClose
        >
            <Form
                id="personioForm"
                form={formInstance}
                onFinish={handleSubmit}
                layout="vertical"
                onFieldsChange={(_fieldsChanged, allFields) => {
                    handlePotentialFieldError(allFields)
                }}
            >
                <Tabs onTabClick={(activeKey: any) => setActiveTabIndex(activeKey as PersonioActiveTabEnum)}>
                    {tabs.map((tab) => (
                        <TabPane tab={tab.title} key={tab.key} className="pb-10">
                            {tab.component}
                        </TabPane>
                    ))}
                </Tabs>
            </Form>
        </Modal>
    )
}

export default React.memo(PersonioModal)
