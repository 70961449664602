import { ExclamationCircleOutlined } from "@ant-design/icons"
import { Tax } from "@finway-group/shared/lib/models"
import { isBuCodeDATEVCompliant } from "@finway-group/shared/lib/utils/datevValidation"
import { Button, Checkbox, Form, Modal, Tooltip } from "antd"
import { Store } from "antd/lib/form/interface"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import TaxForm from "Components/forms/tax.form"
import { NotificationService, TaxService } from "Shared/services"
import DialogService from "Shared/services/dialog.service"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { updateTaxes } from "Shared/store/actions/tax/taxActions"
import { getTooltipPopupContainer } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

interface TaxModalProps {
    taxes: Array<Tax>
    isShowing: boolean
    onCancel: () => void
}

const TaxModal = ({ taxes, isShowing, onCancel }: TaxModalProps) => {
    const { t } = useTranslation()
    const [formInstance] = Form.useForm()
    const [isLoading, setIsLoading] = useStateIfMounted(false)
    const [shouldSyncExpenses, setShouldSyncExpenses] = useStateIfMounted(false)

    const dispatch = useDispatch()

    useEffect(() => {
        if (isShowing) formInstance.setFieldsValue({ taxes })
    }, [isShowing])

    const onSubmit = async (values: Store) => {
        try {
            // extra variable to change it after confirmation dialog
            let shouldSynchronizeExpenses = shouldSyncExpenses

            const datevIncompatible = values.taxes.some((value: any) => value.buCode && !isBuCodeDATEVCompliant(value.buCode))
            if (datevIncompatible && !(await DialogService.confirmBuCodesDatevCompliance())) return

            if (!shouldSynchronizeExpenses && (await DialogService.confirmSync())) {
                shouldSynchronizeExpenses = true
            }

            setIsLoading(true)
            await dispatch(updateTaxes(values.taxes))
            if (shouldSynchronizeExpenses) await TaxService.syncExpenses(values.taxes)
            NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:settings.tax.updated.title"), t("notification:settings.tax.updated.message"))
            onHide()
        } catch (err) {
            setIsLoading(false)
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:error"))
        }
    }

    const onHide = () => {
        setIsLoading(false)
        onCancel()
    }

    return (
        <>
            <Modal
                title={t("action:tax_settings.taxes")}
                visible={isShowing}
                maskClosable={false}
                onCancel={onHide}
                confirmLoading={isLoading}
                keyboard
                destroyOnClose
                width={750}
                footer={
                    <div className="flex justify-between items-center">
                        <div>
                            <Checkbox className="ml-8" onChange={(e) => setShouldSyncExpenses(e.target.checked)}>
                                {t("input:sync_updates")}
                            </Checkbox>
                            <Tooltip getPopupContainer={getTooltipPopupContainer} title={t("tooltips:synch_expenses:vat")} placement="top" className="align-middle">
                                <ExclamationCircleOutlined />
                            </Tooltip>
                        </div>
                        <div className="ml-auto mr-0">
                            <Button key="back" onClick={onHide}>
                                {t("action:cancel")}
                            </Button>

                            <Button data-testid="saveTaxRate" key="submit" type="primary" onClick={formInstance.submit}>
                                {t("action:save")}
                            </Button>
                        </div>
                    </div>
                }
            >
                <TaxForm taxes={taxes} formInstance={formInstance} onSubmit={onSubmit} onHide={onHide} />
            </Modal>
        </>
    )
}

export default TaxModal
