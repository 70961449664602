import { SsoProviderTypeEnum } from "@finway-group/shared/lib/models"
import localForage from "localforage"

import { queryClient } from "Shared/config/reactQueryConfig"
import store from "Shared/store"
import { resetReduxStore } from "Shared/store/actions/auth/authActions"

export const ACCESS_TOKEN = "accessToken"
export const WEAVR_TOKEN = "weavrAccessToken"
export const SSO_PROVIDER_TYPES = "ssoProviderTypes"

const LocalStorageService = {
    isAuthorized: () => LocalStorageService.getAccessToken() !== null,

    getAuthenticationHeader: () => ({
        Authorization: `Bearer ${LocalStorageService.getAccessToken()}`,
        "x-weavr-token": LocalStorageService.get(WEAVR_TOKEN) ?? "",
    }),

    // weavr
    setWeavrToken: (value: string) => localStorage.setItem(WEAVR_TOKEN, value),
    unsetWeavrToken: () => localStorage.removeItem(WEAVR_TOKEN),

    // access token
    getAccessToken: () => {
        const accessToken = localStorage.getItem(ACCESS_TOKEN)
        return accessToken
    },
    setAccessToken: (value: string) => {
        localStorage.setItem(ACCESS_TOKEN, value)
        // We dispatch a custom event so every useLocalStorage hook is notified
        window.dispatchEvent(new Event("local-storage"))
    },
    /**
     * Deletes ALL local state
     * - local storage
     * - redux (back by local forage)
     * - react query data
     */
    unsetAccessToken: () => {
        localStorage.removeItem(ACCESS_TOKEN)
        // We dispatch a custom event so every useLocalStorage hook is notified
        window.dispatchEvent(new Event("local-storage"))

        LocalStorageService.clear()

        store.dispatch(resetReduxStore())

        localForage.clear()

        queryClient.resetQueries()
    },

    getSsoProviderTypes: () => (localStorage.getItem(SSO_PROVIDER_TYPES)?.split(",") as Array<SsoProviderTypeEnum>) ?? [],
    setSsoProviderTypes: ({ ssoProviderTypes }: { ssoProviderTypes: Array<SsoProviderTypeEnum> }) => {
        const existingSsoProviderTypes = localStorage.getItem(SSO_PROVIDER_TYPES)
        const ssoProviderTypesString = ssoProviderTypes.join(",")
        if (existingSsoProviderTypes !== ssoProviderTypesString) {
            localStorage.setItem(SSO_PROVIDER_TYPES, ssoProviderTypesString)
            // We dispatch a custom event so every useLocalStorage hook is notified
            window.dispatchEvent(new Event("local-storage"))
        }
    },

    get: (key: string) => localStorage.getItem(key),
    clear: () => {
        // clear all items from local storage except ssoProviderTypes
        const existingSsoProviderTypes = localStorage.getItem(SSO_PROVIDER_TYPES)
        localStorage.clear()
        if (existingSsoProviderTypes) localStorage.setItem(SSO_PROVIDER_TYPES, existingSsoProviderTypes)
    },
}

export default LocalStorageService
