import { Role } from "@finway-group/shared/lib/models"
import { Form, Modal } from "antd"
import * as HttpStatus from "http-status"
import React, { useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import RoleForm from "Components/forms/roles/role.form"
import { enableStandardRights, mapFormModelToRoleModel, mapRoleModelToFormModel } from "Components/forms/roles/role.form.mapper"
import { RightFormElementsEnum } from "Components/forms/roles/role.form.types"
import { NotificationService } from "Shared/services"
import DialogService from "Shared/services/dialog.service"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { createRole, updateRole } from "Shared/store/actions/role/roleActions"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

interface RoleSettingsModalProps {
    role: Role
    isShowing: boolean
    onCancel: () => void
    isNew: boolean
}

const RoleSettingsModal = ({ role, isShowing, onCancel, isNew }: RoleSettingsModalProps) => {
    const { t } = useTranslation()
    const [formInstance] = Form.useForm()
    const [isLoading, setIsLoading] = useStateIfMounted(false)
    const initialAbilityToApproveRef = useRef<boolean | undefined>(undefined)

    const dispatch = useDispatch()

    useEffect(() => {
        if (role) mapRoleModelToFormModel(role, formInstance)
        else enableStandardRights(formInstance)
        initialAbilityToApproveRef.current = formInstance.getFieldValue(RightFormElementsEnum.REQUEST_ABILITY_APPROVE)
    }, [role, formInstance])

    const onSubmit = async () => {
        try {
            setIsLoading(true)

            const roleData = mapFormModelToRoleModel(formInstance)

            if (isNew) {
                await createRole(roleData)(dispatch)
            } else {
                await updateRole(role._id, roleData)(dispatch)
            }
            NotificationService.send(
                NotificationTypeEnum.SUCCESS,
                t(`notification:settings.role.${isNew ? "created" : "updated"}.title`),
                t(`notification:settings.role.${isNew ? "created" : "updated"}.message`),
            )
            onHide()
        } catch (err) {
            setIsLoading(false)
            if (err.response?.status === HttpStatus.BAD_REQUEST && err.response?.data?.error?.length) {
                const roleDependencies = err.response.data.error
                DialogService.errorApprovalRightRemoval(roleDependencies)
                return
            }
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:error"))
        }
    }

    const onHide = () => {
        setIsLoading(false)
        formInstance.resetFields()
        onCancel()
    }

    return (
        <Modal
            title={isNew ? t("action:role_settings.create_custom_role") : t("action:role_settings.edit_custom_role")}
            visible={isShowing}
            maskClosable={false}
            onOk={formInstance.submit}
            onCancel={onHide}
            okText={t("action:save")}
            cancelText={t("action:cancel")}
            confirmLoading={isLoading}
            className="ant-modal--large"
            keyboard
            destroyOnClose
        >
            <RoleForm role={role} formInstance={formInstance} onSubmit={onSubmit} />
        </Modal>
    )
}

export default RoleSettingsModal
