import { Employee, ExtendedReferencedUser } from "@finway-group/shared/lib/models"
import { Tooltip } from "antd"
import React from "react"
import { generatePath, useHistory } from "react-router-dom"

import UserImage, { UserImageBadgeType } from "Components/UserImage"
import { ConditionalWrapper } from "Components/conditionalWrapper"
import { FINWAY_ADMIN_EMAIL } from "Shared/config/consts"
import { RoutePathEnum } from "Shared/router/RoutePath.enum"
import { getTooltipPopupContainer } from "Shared/utils/helper.utils"

interface EmployeeLinkInterface {
    employee: Employee
    avatarBadge?: UserImageBadgeType
    heightPadding?: boolean
    showEmailTooltip?: boolean
    expenseExtendedRefUser?: ExtendedReferencedUser
    approverTypeString?: string
}

const EmployeeLink = ({ employee, avatarBadge, expenseExtendedRefUser, heightPadding = true, showEmailTooltip = true, approverTypeString }: EmployeeLinkInterface) => {
    const history = useHistory()
    const { email, firstName, lastName } = expenseExtendedRefUser ?? employee

    const id = expenseExtendedRefUser?._id || employee.id

    if (!id) return <></>
    const path = generatePath(RoutePathEnum.EMPLOYEE_DETAIL_PAGE, { id })

    const onClick = (event: any) => {
        event.preventDefault()
        history.push({ pathname: path })
    }

    return (
        <ConditionalWrapper
            condition={email !== FINWAY_ADMIN_EMAIL}
            wrapper={(children) => (
                <a href={path} onClick={onClick} className="flex items-center justify-end">
                    {children}
                </a>
            )}
        >
            <ConditionalWrapper
                condition={showEmailTooltip}
                wrapper={(children) => (
                    <Tooltip getPopupContainer={getTooltipPopupContainer} title={email} placement="top" className="align-middle">
                        {children}
                    </Tooltip>
                )}
            >
                <div className="flex items-center flex-no-wrap w-full">
                    <UserImage user={employee} badge={avatarBadge} heightPadding={heightPadding} />
                    <span className="leading-tight truncate">
                        {firstName} {lastName}
                    </span>
                    {approverTypeString && <p className="leading-none text-xs whitespace-no-wrap text-text pl-5"> {approverTypeString}</p>}
                </div>
            </ConditionalWrapper>
        </ConditionalWrapper>
    )
}

export default EmployeeLink
