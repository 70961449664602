import { AccessTokenType, User } from "@finway-group/shared/lib/models"
import { Reducer } from "redux"

import {
    AuthActionTypes,
    AuthActions,
    LoginAction,
    SetAuthUserAction,
    SetIsWeavrAuthenticatedAction,
    SetIsWeavrOnboardAction,
    SetShouldShowWeavrAuthModalAction,
} from "../actions/auth/authTypes"

export enum WeavrUserOnboardEnum {
    ONBOARD = "ONBOARD",
    CANCELLED = "CANCELLED",
    NOT_ONBOARD = "NOT_ONBOARD",
}

// Model imports
export interface AuthState {
    isWeavrAuthenticated: boolean
    isWeavrUserOnBoard: WeavrUserOnboardEnum
    shouldShowWeavrAuthModal: boolean
    user: User | undefined
    accessToken: AccessTokenType | undefined

    isRefreshingToken?: boolean
}

const initialState: AuthState = {
    isWeavrAuthenticated: false,
    isWeavrUserOnBoard: WeavrUserOnboardEnum.NOT_ONBOARD,
    shouldShowWeavrAuthModal: false,
    isRefreshingToken: false,
    user: undefined,
    accessToken: undefined,
}

const authLogin = (state: AuthState, action: LoginAction): AuthState => ({
    ...state,
    user: action.user,
    accessToken: action.accessToken,
})

const setAuthUser = (state: AuthState, action: SetAuthUserAction): AuthState => ({
    ...state,
    user: action.user,
    accessToken: action.accessToken,
})

const weavrLogin = (state: AuthState, action: SetIsWeavrAuthenticatedAction): AuthState => ({
    ...state,
    isWeavrAuthenticated: action.isWeavrAuthenticated,
})

const weavrUserOnboard = (state: AuthState, action: SetIsWeavrOnboardAction): AuthState => ({
    ...state,
    isWeavrUserOnBoard: action.isWeavrUserOnboard,
})

const setShouldShowWeavrAuthModal = (state: AuthState, action: SetShouldShowWeavrAuthModalAction): AuthState => ({
    ...state,
    shouldShowWeavrAuthModal: action.shouldShowWeavrAuthModal,
})

const authTokenRefresh = (state: AuthState): AuthState => ({
    ...state,
    isRefreshingToken: true,
})

const authTokenRefreshed = (state: AuthState): AuthState => ({
    ...state,
    isRefreshingToken: false,
})

export const authReducer: Reducer<AuthState, AuthActions> = (state = initialState, action) => {
    switch (action.type) {
        case AuthActionTypes.LOGIN:
            return authLogin(state, action)
        case AuthActionTypes.TOKEN_REFRESH:
            return authTokenRefresh(state)
        case AuthActionTypes.TOKEN_REFRESHED:
            return authTokenRefreshed(state)
        case AuthActionTypes.SET_WEAVR_AUTH:
            return weavrLogin(state, action)
        case AuthActionTypes.SET_WEAVR_USER_ONBOARD:
            return weavrUserOnboard(state, action)
        case AuthActionTypes.SET_SHOULD_SHOW_WEAVR_AUTH_MODAL:
            return setShouldShowWeavrAuthModal(state, action)
        case AuthActionTypes.SET_AUTH_USER:
            return setAuthUser(state, action)
        default:
            return state
    }
}
