import { ExportFormatEnum } from "@finway-group/shared/lib/models"
import { Button, Dropdown, Menu } from "antd"
import { ButtonType } from "antd/lib/button"
import React from "react"
import { Download as DownloadIcon } from "react-feather"
import { useTranslation } from "react-i18next"

import { useLoggedInEmployeeProfile } from "Shared/hooks/user.hooks"
import { NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"
import { getPopupAnchor } from "Shared/utils/popup.utils"

interface MassExportButtonInterface {
    serviceExportFunction: () => Promise<void>
    buttonType?: ButtonType
    visible: boolean
}

const MassExportButton: React.FC<MassExportButtonInterface> = ({ serviceExportFunction, buttonType = "default", visible }) => {
    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useStateIfMounted(false)

    const handleSelect = () => {
        setIsLoading(true)
        serviceExportFunction()
            .then(() => {
                NotificationService.send(NotificationTypeEnum.INFO, t(`notification:async_export_started.title`), t(`notification:async_export_started.message`), 0)
            })
            .catch((e) => {
                NotificationService.showErrorNotificationBasedOnResponseError(e, t("error:request.exported"))
            })
            .finally(() => {
                setIsLoading(false)
            })
    }
    return (
        !!visible && (
            <Dropdown
                getPopupContainer={getPopupAnchor()}
                overlay={() => (
                    <Menu onClick={handleSelect}>
                        <Menu.Item key={ExportFormatEnum.CSV} className="flex items-center min-w-190 py-8">
                            <div className="flex items-center ml-12">
                                <p className="leading-none">
                                    <span className="text-text">{t("input:export.export_format.csv")}</span>
                                </p>
                            </div>
                        </Menu.Item>
                    </Menu>
                )}
                trigger={["click"]}
                className="flex items-center"
            >
                <div className={`${"cursor-pointer"}`} onClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}>
                    <Button
                        onClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}
                        block
                        loading={isLoading}
                        className={`pointer-events-none`}
                        icon={<DownloadIcon />}
                        type={buttonType}
                    >
                        <span>{t("action:export")} </span>
                        <i className="el-icon-caret-bottom el-icon--right" />
                    </Button>
                </div>
            </Dropdown>
        )
    )
}

export default MassExportButton
