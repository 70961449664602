import Icon from "@ant-design/icons"
import React from "react"

const arrowSVG = () => (
    <svg width="16" height="24" viewBox="0 0 16 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 0.5L8 23.5" stroke="#A1B1C1" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M15.5 16L8 23.5L0.5 16" stroke="#A1B1C1" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
)

const ArrowThinDownIcon = (props: any) => <Icon component={arrowSVG} {...props} />

export default ArrowThinDownIcon
