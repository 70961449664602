import { LoadingOutlined, UploadOutlined } from "@ant-design/icons"
import { FileUploadData, UploadKindEnum } from "@finway-group/shared/lib/models"
import { Button, Col, Form, Row, Select, Table, Tooltip, Upload } from "antd"
import { FormInstance } from "antd/lib/form"
import React, { useEffect } from "react"
import { Trash } from "react-feather"
import { useTranslation } from "react-i18next"
import { useDebouncedCallback } from "use-debounce"

import { personioRules } from "Components/forms/rules/personio.rules"
import SearchInput from "Components/searchInput"
import { DEBOUNCE_DURATION_LONG } from "Shared/config/consts"
import { FileService, NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { doFilterTagSelect } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"
import { getPopupAnchor } from "Shared/utils/popup.utils"
import validateEmail from "Shared/validators/email.validator"

interface PersonioExclusionListProps {
    formInstance: FormInstance
    excludedUsers: Array<string>
    setExcludedUsers: (userEmails: Array<string>) => void
}

const PersonioExclusionList = ({ formInstance, excludedUsers, setExcludedUsers }: PersonioExclusionListProps) => {
    const [userSearch, setUserSearch] = useStateIfMounted<string>("")
    const [isExcludedUsersLoading, setIsExcludedUsersLoading] = useStateIfMounted<boolean>(false)
    const [isUploadingCsv, setIsUploadingCsv] = useStateIfMounted<boolean>(false)
    const [visibleExcludedUsers, setVisibleExcludedUsers] = useStateIfMounted<Array<string>>(excludedUsers || [])

    const handleGlobalUserSearch = useDebouncedCallback((searchString) => setUserSearch(searchString), DEBOUNCE_DURATION_LONG)
    const { t } = useTranslation()
    const rules = personioRules(excludedUsers)

    const searchExcludedUsers = async () => {
        try {
            const filteredUsers = await excludedUsers.filter((userEmail: string) => userEmail?.toLowerCase().includes(userSearch.toLowerCase()))
            setVisibleExcludedUsers(filteredUsers)
        } finally {
            setIsExcludedUsersLoading(false)
        }
    }

    useEffect(() => {
        searchExcludedUsers()
    }, [userSearch])

    useEffect(() => {
        formInstance.validateFields()
    }, [excludedUsers.length])

    const removeUserFromExclusionList = (userEmailToBeRemoved: string) => {
        setExcludedUsers(excludedUsers?.filter((userEmail) => userEmail !== userEmailToBeRemoved))
        setVisibleExcludedUsers(visibleExcludedUsers?.filter((userEmail) => userEmail !== userEmailToBeRemoved))
    }

    const onUploadCSV = async (file: File) => {
        setIsUploadingCsv(true)

        let data: FileUploadData
        try {
            data = await FileService.upload(file, UploadKindEnum.CSV_MATCHING)
        } catch (err) {
            setIsUploadingCsv(false)
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:file.upload.title"))
            return
        }

        try {
            const csvRecords = await FileService.getCSVFileData(data.url)

            // check if valid email
            const validEmails = csvRecords.filter((value: string) => validateEmail(value))
            if (validEmails.length === 0) {
                NotificationService.send(NotificationTypeEnum.ERROR, t("error:csv_matching.title"), t("error:csv_matching.no_emails"))
                return
            }

            // remove emails that already exist in the select field
            const currentFieldValue = formInstance.getFieldValue(["attributes", "excludedUsers"]) ?? []
            const uniqueEmails = validEmails.filter((email: string) => !currentFieldValue.includes(email))
            if (uniqueEmails.length === 0) {
                NotificationService.send(NotificationTypeEnum.ERROR, t("error:csv_matching.title"), t("error:csv_matching.no_new_emails"), 4)
                return
            }

            formInstance.setFieldsValue({
                attributes: {
                    excludedUsers: [...currentFieldValue, ...uniqueEmails],
                },
            })
            formInstance.validateFields()
        } catch (err) {
            NotificationService.send(NotificationTypeEnum.ERROR, t("error:csv_matching.title"), t("error:csv_matching.process"))
        } finally {
            setIsUploadingCsv(false)
        }
    }

    return (
        <>
            <p className="pb-20">{t("label:personio.exclusion_list_explanation")}</p>
            <Row gutter={[16, 16]} align="middle">
                <Col span={21}>
                    <Form.Item name={["attributes", "excludedUsers"]} label={t("label:email")} rules={rules.excludedUsers}>
                        <Select
                            className="w-full"
                            mode="tags"
                            placeholder={t("placeholder:personio.email")}
                            filterOption={doFilterTagSelect}
                            getPopupContainer={getPopupAnchor()}
                            allowClear
                        />
                    </Form.Item>
                </Col>
                <Col span={3}>
                    <Tooltip title={t("tooltips:personio.upload_csv")}>
                        <Upload
                            showUploadList={false}
                            accept=".csv"
                            customRequest={(req) => {
                                onUploadCSV(req.file)
                            }}
                        >
                            <Button className="btn-default" loading={isUploadingCsv}>
                                <UploadOutlined />
                            </Button>
                        </Upload>
                    </Tooltip>
                </Col>
            </Row>

            <div className="flex items-center mb-10">
                <h3 className="mb-0">{t("label:personio.excludedUser", { count: visibleExcludedUsers?.length })}</h3>
                <SearchInput
                    className="min-w-32 ml-12"
                    onSearch={(searchString) => {
                        setIsExcludedUsersLoading(true)
                        handleGlobalUserSearch.callback(searchString)
                    }}
                    value={userSearch}
                />
            </div>

            <Table
                rowKey={(record: any) => record}
                showHeader={false}
                columns={[
                    {
                        key: "userEmail",
                        ellipsis: true,
                        defaultSortOrder: "ascend",
                        sorter: true,
                        width: 270,
                        render: (userEmail: string) => (
                            <div className="flex justify-between items-center">
                                {userEmail}
                                <Tooltip title={t("tooltips:personio.include_user")}>
                                    <Button onClick={() => removeUserFromExclusionList(userEmail)} className="text-dark">
                                        <Trash style={{ color: "inherit" }} />
                                    </Button>
                                </Tooltip>
                            </div>
                        ),
                    },
                ]}
                scroll={{ y: 220 }}
                dataSource={visibleExcludedUsers}
                pagination={{
                    position: ["bottomRight"],
                    showSizeChanger: true,
                    defaultPageSize: 5,
                    hideOnSinglePage: false,
                    pageSizeOptions: ["5", "10", "20", "50", "100"],
                }}
                loading={{
                    spinning: isExcludedUsersLoading,
                    indicator: <LoadingOutlined style={{ fontSize: 30, color: "black" }} spin />,
                }}
            />
        </>
    )
}

export default PersonioExclusionList
