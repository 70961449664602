import { Tax } from "@finway-group/shared/lib/models"
import { isBuCodeDATEVCompliant } from "@finway-group/shared/lib/utils/datevValidation"
import { Button, Col, Input, Radio, Row, Tooltip } from "antd"
import Form, { FormInstance } from "antd/lib/form"
import { Store } from "antd/lib/form/interface"
import React, { useEffect, useState } from "react"
import { Plus as PlusIcon, Trash2 as TrashIcon } from "react-feather"
import { useTranslation } from "react-i18next"

import { ConditionalWrapper } from "Components/conditionalWrapper"
import Loading from "Components/loading"
import { WARNING_ORANGE } from "Shared/config/consts"
import { NotificationService, TaxService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { blurInputOnWeel, getTooltipPopupContainer, isSet } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

import TaxRules from "./rules/tax.rules"

interface TaxFormInterface {
    taxes: Array<Tax>
    formInstance: FormInstance
    onSubmit: (values: Store) => void
    onHide: () => void
}

const TaxForm = ({ taxes, formInstance, onSubmit, onHide }: TaxFormInterface) => {
    const { t } = useTranslation()
    const { taxRate, buCode, buCodeName } = TaxRules(formInstance)
    const [buCodes, setBuCodes] = useState(taxes.map((tax: Tax) => tax.buCode))
    const [buCodeNames, setBuCodeNames] = useState(taxes.map((tax: Tax) => tax.buCodeName))
    const [defaultTaxRateIndex, setDefaultTaxRateIndex] = useState<number>(taxes.findIndex((tax: Tax) => tax?.isDefault))
    const [referencedTaxRateIds, setReferencedTaxRateIds] = useState<Array<string>>([])
    const [isLoading, setIsLoading] = useStateIfMounted<boolean>(false)

    useEffect(() => {
        const fetchReferencedTaxRates = async () => {
            setIsLoading(true)
            try {
                const refTaxRateIds = await TaxService.getReferencedTaxRates()
                setReferencedTaxRateIds([...refTaxRateIds])
            } catch (err) {
                setIsLoading(false)
                NotificationService.send(NotificationTypeEnum.ERROR, t("error:error"), t("error:vat.vat_rate_fetch.message"))
                onHide()
            }
            setIsLoading(false)
        }

        fetchReferencedTaxRates()
    }, [])

    const onEmptyTaxList = <div className="mb-20">{t("missing:no_tax_yet")}</div>

    const onUpdateBuCode = (value: string, index: number) => {
        setBuCodes([...buCodes.slice(0, index), value, ...buCodes.slice(index + 1)])
    }

    const onUpdateBuCodeName = (value: string, index: number) => {
        setBuCodeNames([...buCodeNames.slice(0, index), value, ...buCodeNames.slice(index + 1)])
    }

    const onUpdateDefaultTaxRate = (_value: boolean, newDefaultTaxRateIndex: number) => {
        setDefaultTaxRateIndex(newDefaultTaxRateIndex)
    }

    const onFinish = (values: Store) => {
        values.taxes = values.taxes.map((tax: Tax, index: number) => ({ ...tax, isDefault: index === defaultTaxRateIndex }))
        onSubmit(values)
    }

    return (
        <>
            {isLoading ? (
                <Loading />
            ) : (
                <Form id="tax-form" form={formInstance} layout="vertical" onFinish={onFinish} autoComplete="off">
                    <Form.List name="taxes">
                        {(fields, { add, remove }) => (
                            <div>
                                {fields.length === 0 && onEmptyTaxList}
                                {fields.map(({ key, name, fieldKey }, index) => {
                                    const isTaxRateReferenced = referencedTaxRateIds.includes(taxes[index]?._id)
                                    const isCurrentDefaultTax = index === defaultTaxRateIndex

                                    return (
                                        <Row key={key} gutter={[8, 8]} align="top">
                                            <Col span={6} offset={0}>
                                                <ConditionalWrapper
                                                    condition={isTaxRateReferenced}
                                                    wrapper={(children: any) => (
                                                        <Tooltip
                                                            getPopupContainer={getTooltipPopupContainer}
                                                            // prettier-ignore
                                                            title={<div>{t('tooltips:vat.disabled_tax_rate_input')}</div>}
                                                            placement="bottom"
                                                        >
                                                            {children}
                                                        </Tooltip>
                                                    )}
                                                >
                                                    <Form.Item name={[name, "taxRate"]} fieldKey={[fieldKey, "taxRate"]} label={t("input:tax.tax_rate")} rules={taxRate} required>
                                                        <Input disabled={isTaxRateReferenced} onWheel={blurInputOnWeel} placeholder={t("placeholder:tax.tax_rate")} />
                                                    </Form.Item>
                                                </ConditionalWrapper>
                                            </Col>

                                            <Col span={7} offset={0}>
                                                <Form.Item name={[name, "buCodeName"]} fieldKey={[fieldKey, "buCodeName"]} label={t("input:tax.bu_code_name")} rules={buCodeName}>
                                                    <Input
                                                        onChange={(event) => onUpdateBuCodeName(event.target.value, index)}
                                                        maxLength={50}
                                                        placeholder={t("placeholder:tax.bu_code_name")}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={5} offset={0}>
                                                <Form.Item
                                                    className="buCode"
                                                    name={[name, "buCode"]}
                                                    label={t("input:tax.bu_code")}
                                                    fieldKey={[fieldKey, "buCode"]}
                                                    rules={buCode}
                                                    validateTrigger={"onChange"}
                                                    shouldUpdate
                                                    extra={
                                                        isSet(buCodes[index]) &&
                                                        !isBuCodeDATEVCompliant(buCodes[index]) && (
                                                            <span className="-ml-4 text-base" style={{ color: WARNING_ORANGE }}>
                                                                {t("validation:bu_code_validation")}
                                                            </span>
                                                        )
                                                    }
                                                >
                                                    <Input onChange={(event) => onUpdateBuCode(event.target.value, index)} placeholder={t("placeholder:tax.bu_code")} />
                                                </Form.Item>
                                            </Col>

                                            <Col span={3} offset={0}>
                                                <Form.Item
                                                    className="isDefault items-center"
                                                    name={[name, "isDefault"]}
                                                    label={t("input:tax.default")}
                                                    fieldKey={[fieldKey, "isDefault"]}
                                                    shouldUpdate
                                                >
                                                    <Radio
                                                        className="flex justify-center mr-0"
                                                        checked={isCurrentDefaultTax}
                                                        onChange={(event) => {
                                                            onUpdateDefaultTaxRate(event.target.value, index)
                                                        }}
                                                    />
                                                </Form.Item>
                                            </Col>

                                            {fields.length > 1 && (
                                                <Col span={3} className="mt-24 flex justify-center">
                                                    {isCurrentDefaultTax ? (
                                                        <Tooltip placement="top" title={t("tooltips:vat.default_not_deletable")}>
                                                            <Button disabled className="opacity-50">
                                                                <TrashIcon />
                                                            </Button>
                                                        </Tooltip>
                                                    ) : (
                                                        <Button
                                                            onClick={() => {
                                                                remove(name)
                                                                formInstance.validateFields()

                                                                // we only have to adjust the index if the deleted element was BEFORE
                                                                if (index < defaultTaxRateIndex) {
                                                                    setDefaultTaxRateIndex(defaultTaxRateIndex - 1)
                                                                }
                                                            }}
                                                        >
                                                            <TrashIcon />
                                                        </Button>
                                                    )}
                                                </Col>
                                            )}
                                        </Row>
                                    )
                                })}
                                <Row>
                                    <Button
                                        data-testid="addVatButton"
                                        className="mb-20"
                                        type="dashed"
                                        onClick={() => {
                                            add()
                                        }}
                                        block
                                    >
                                        <PlusIcon /> {t("action:tax.add")}
                                    </Button>
                                </Row>
                            </div>
                        )}
                    </Form.List>
                </Form>
            )}
        </>
    )
}

export default React.memo(TaxForm)
