import { CsvTemplate, CsvTemplateSelectedField } from "@finway-group/shared/lib/models"
import { Rule } from "antd/lib/form"
import i18n from "i18next"

const CsvTemplateRules = (templates: Array<CsvTemplate>): { [key: string]: Array<Rule> } => ({
    name: [
        { required: true, message: i18n.t("validation:required"), validateTrigger: "blur" },
        {
            validator: (_rule: any, value: string) => {
                if (value) {
                    if (templates.map(({ name }) => name).includes(value.trim())) {
                        return Promise.reject(new Error(i18n.t("validation:csv_settings_name_unique")))
                    }
                    if (!value.trim()) {
                        return Promise.reject(new Error(i18n.t("validation:name")))
                    }
                }
                return Promise.resolve()
            },
        },
    ],
    csvHeadersTable: [
        {
            required: true,
            validator: (_rule: any, value: Array<CsvTemplateSelectedField>) => {
                if (value.length === 0) {
                    return Promise.reject(new Error(i18n.t("validation:csv_settings_headers_empty")))
                }
                return Promise.resolve()
            },
        },
    ],
})
export default CsvTemplateRules
