import { Employee, User } from "@finway-group/shared/lib/models"
import { Divider, Modal } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"

import UserImage from "Components/UserImage"
import { DebounceSelect } from "Components/select/select.debounce"
import { AuthzService, CompanyService } from "Shared/services"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

export interface DeleteOrDemoteEmployeeModalProps {
    isShowing: boolean
    onCancel: (event: any) => void
    onSubmit: (employee: Employee, workflowsReplacementApproverId: string) => void
    operationType?: "delete" | "demote"
    user: User
    companyId: string
}

export const DeleteOrDemoteEmployeeModal = ({ isShowing, onCancel, onSubmit, operationType = "delete", user, companyId }: DeleteOrDemoteEmployeeModalProps) => {
    const { t } = useTranslation()
    const employee = new Employee(user)
    const employeeProfile = companyId ? employee.setActiveCompanyProfile(companyId) : employee.activeCompanyProfile
    const isLoggedInUserPotentialApprover = AuthzService.isPotentialApprover(employeeProfile?.roleId)

    const [workflowsReplacementApproverId, setWorkflowsReplacementApproverId] = useStateIfMounted<string>("")

    const isDemoteOperation = operationType === "demote"

    const shouldAskForApproverReplacement = isDemoteOperation || isLoggedInUserPotentialApprover

    if (!isShowing) return null

    return (
        <Modal
            title={isDemoteOperation ? t("confirm:employee.demote.title") : t("confirm:employee.delete.title")}
            cancelText={isDemoteOperation ? t("confirm:employee.demote.cancel") : t("confirm:employee.delete.cancel")}
            okText={isDemoteOperation ? t("confirm:employee.demote.confirm") : t("confirm:employee.delete.confirm")}
            visible={isShowing}
            onCancel={onCancel}
            destroyOnClose
            maskClosable={true}
            okButtonProps={{ disabled: shouldAskForApproverReplacement && !workflowsReplacementApproverId }}
            onOk={() => {
                onSubmit(employee, workflowsReplacementApproverId)
            }}
        >
            <>
                <p>{isDemoteOperation ? t("confirm:employee.demote.message") : t("confirm:employee.delete.message")}</p>

                {shouldAskForApproverReplacement && (
                    <>
                        <Divider />
                        <div className="flex-col align-center justify-center gap-10">
                            <label>{isDemoteOperation ? t("confirm:employee.demote.replacement_label") : t("confirm:employee.delete.replacement_label")}</label>
                            <DebounceSelect
                                onChange={(value) => {
                                    setWorkflowsReplacementApproverId(value)
                                }}
                                disabled={false}
                                className="w-full"
                                fetchOptionsDependency={user.id} // To make sure that we refetch the fetchOptions when team is changed.
                                fetchOptions={async ({ search }) =>
                                    user.id
                                        ? CompanyService.getPossibleApprovers({ userId: user.id, companyId, search }).then((users) =>
                                              users.map((user: any) => ({
                                                  label: (
                                                      <div className="flex items-center" id={`approver-option-${user._id}`}>
                                                          <UserImage user={user} />
                                                          <span>{`${user.firstName} ${user.lastName}`}</span>
                                                      </div>
                                                  ),
                                                  value: user._id,
                                              })),
                                          )
                                        : []
                                }
                            />
                        </div>
                    </>
                )}
            </>
        </Modal>
    )
}
