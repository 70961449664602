import React from "react"

import SettingsLayout from "Features/settings/settings.layout"

import SSOSettings from "./ssoSettings"

const SystemSecurityContainer = () => (
    <SettingsLayout titleKey="label:security">
        <SSOSettings />
    </SettingsLayout>
)

export default React.memo(SystemSecurityContainer)
