import { BillingPeriodEnum, CostCenter, CostCenter2, Employee, Expense, PriceIntervalEnum, RightEnum, Vendor } from "@finway-group/shared/lib/models"
import { ExpensePaymentOptionEnum } from "@finway-group/shared/lib/models/expense/expensePaymentOption.enum"
import { SubscriptionRenewalGenerationTypeEnum } from "@finway-group/shared/lib/models/expense/subscriptionRenewalGenerationType.enum"
import { Card } from "antd"
import moment from "moment"
import React from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

import EmployeeLink from "Components/employeeLink"
import PriceLabel from "Components/priceLabel"
import { useCard } from "Shared/hooks/creditCard.hooks"
import { AuthzService } from "Shared/services"
import { getPeriodPricesForSubscription } from "Shared/utils/expense.utils"

interface SubscriptionDataInterface {
    expense: Expense
    loggedInUser: Employee
    costCenter?: CostCenter
    costCenter2?: CostCenter2
    vendor?: Vendor
    requestedBy: Employee
}
const SubscriptionData: React.FC<SubscriptionDataInterface> = ({ expense, loggedInUser, costCenter, costCenter2, vendor, requestedBy }) => {
    const { t } = useTranslation()
    const card = useCard()

    const { monthlyPrice, quarterlyPrice, yearlyPrice } = getPeriodPricesForSubscription(expense, loggedInUser.settings.showGrossAmount)

    const isExpenseSplitted = !expense.costCenter && expense.splits.length > 1
    const isCardSubscription = expense.paymentOption === ExpensePaymentOptionEnum.SMART_CARD

    return (
        <Card>
            <ul className="description-list overflow-hidden" id="subscription-data-list">
                <li>
                    <span className="title">{t("label:monthly_price")}</span>
                    <span className="text">
                        <PriceLabel value={monthlyPrice} currency={expense.currency} interval={PriceIntervalEnum.ONE_TIME} showFullNumber={true} /> / {t("append:per_month")}
                    </span>
                </li>

                <li>
                    <span className="title">{t("label:quarterly_price")}</span>
                    <span className="text">
                        <PriceLabel value={quarterlyPrice} currency={expense.currency} interval={PriceIntervalEnum.ONE_TIME} showFullNumber={true} /> / {t("append:per_quarter")}
                    </span>
                </li>

                <li>
                    <span className="title">{t("label:yearly_price")}</span>
                    <span className="text">
                        <PriceLabel value={yearlyPrice} currency={expense.currency} interval={PriceIntervalEnum.ONE_TIME} showFullNumber={true} /> / {t("append:per_year")}
                    </span>
                </li>

                <li>
                    <span className="title">{t("label:billing_period")}</span>
                    <span className="text">
                        {expense.billingPeriod === BillingPeriodEnum.MONTHLY
                            ? t("label:monthly")
                            : expense.billingPeriod === BillingPeriodEnum.QUARTERLY
                            ? t("label:quarterly")
                            : t("label:yearly")}
                    </span>
                </li>

                {isCardSubscription && (
                    <li>
                        <span className="title">{t("label:renewal_generation.title")}</span>
                        <span className="text">
                            {expense.renewalGenerationType === SubscriptionRenewalGenerationTypeEnum.BY_DATE
                                ? t("label:renewal_generation.by_date")
                                : t("label:renewal_generation.by_transaction")}
                        </span>
                    </li>
                )}

                {expense.paymentFlowInformation && (
                    <li>
                        <span className="title">{t("label:payment_flow_information.title")}</span>
                        <span className="text">
                            <span>{t(`label:payment_flow_information.${expense.paymentFlowInformation}`)}</span>
                        </span>
                    </li>
                )}

                {card && (
                    <li>
                        <span className="title">{t("label:attached_card")}</span>
                        <span className="text truncate max-w-300">
                            <Link to={`/credit-cards/${card?._id}`}>{`**** **** **** ${card?.last4Digits}`}</Link>
                        </span>
                    </li>
                )}

                {expense.link && (
                    <li>
                        <span className="title">{t("label:link")}</span>
                        <span className="text truncate max-w-300">
                            <a href={expense.link} target="_blank" rel="noopener noreferrer">
                                {expense.link}
                            </a>
                        </span>
                    </li>
                )}

                <li>
                    <span className="title">{t("label:starting_date")}</span>
                    <span className="text">{moment(expense.datePurchased).format("ll")}</span>
                </li>
                <li>
                    <span className="title">{t("label:renewal_date")}</span>
                    <span className="text">{moment(expense.dateCancellationDue).format("ll")}</span>
                </li>

                <li>
                    <span className="title">{t("label:cost_center")}</span>
                    {AuthzService.isRightGrantedForLoggedInUser(RightEnum.CC1__ALL__READ) || costCenter?.responsibleUser === loggedInUser.id ? (
                        <span className="text truncate max-w-300">
                            {!isExpenseSplitted ? <Link to={`/costCenters/${costCenter?._id}`}>{costCenter?.name}</Link> : t("label:splitted")}
                        </span>
                    ) : (
                        <span className="text truncate max-w-300">{!isExpenseSplitted ? costCenter?.name : t("label:splitted")}</span>
                    )}
                </li>
                {costCenter2 ? (
                    <li>
                        <span className="title">{t("label:cost_center_2")}</span>
                        <span className="text truncate max-w-300">{costCenter2?.name}</span>
                    </li>
                ) : (
                    <></>
                )}
                <li>
                    <span className="title">{t("label:vendor")}</span>
                    <span className="text truncate max-w-300">
                        <Link to={`/vendors/${vendor?.id}`}>{vendor?.name}</Link>
                    </span>
                </li>

                <li>
                    <span className="title">{t("label:requested_by")}</span>
                    <span className="text truncate max-w-300">
                        <EmployeeLink employee={requestedBy} expenseExtendedRefUser={expense.requestedBy} />
                    </span>
                </li>
            </ul>
        </Card>
    )
}

export default React.memo(SubscriptionData)
