import Icon from "@ant-design/icons"
import React from "react"

const addSvg = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M16.2422 11.25H12.75V7.75781C12.75 7.34531 12.4125 7.00781 12 7.00781C11.5875 7.00781 11.25 7.34531 11.25 7.75781V11.25H7.75781C7.34531 11.25 7.00781 11.5312 7.00781 12C7.00781 12.4688 7.35938 12.75 7.75781 12.75H11.25V16.2422C11.25 16.6875 11.5781 16.9922 12 16.9922C12.4219 16.9922 12.75 16.6547 12.75 16.2422V12.75H16.2422C16.6547 12.75 16.9922 12.4125 16.9922 12C16.9922 11.5875 16.6547 11.25 16.2422 11.25Z"
            fill="white"
        />
        <path
            d="M12 3.5625C14.2547 3.5625 16.3734 4.43906 17.9672 6.03281C19.5609 7.62656 20.4375 9.74531 20.4375 12C20.4375 14.2547 19.5609 16.3734 17.9672 17.9672C16.3734 19.5609 14.2547 20.4375 12 20.4375C9.74531 20.4375 7.62656 19.5609 6.03281 17.9672C4.43906 16.3734 3.5625 14.2547 3.5625 12C3.5625 9.74531 4.43906 7.62656 6.03281 6.03281C7.62656 4.43906 9.74531 3.5625 12 3.5625ZM12 2.25C6.61406 2.25 2.25 6.61406 2.25 12C2.25 17.3859 6.61406 21.75 12 21.75C17.3859 21.75 21.75 17.3859 21.75 12C21.75 6.61406 17.3859 2.25 12 2.25Z"
            fill="white"
        />
    </svg>
)

const AddIcon = (props: any) => <Icon component={addSvg} {...props} />

export default AddIcon
