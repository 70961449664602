import { CommentItem, Vendor } from "@finway-group/shared/lib/models"
import axios from "axios"

import { AuthzService, CostCenter2Service, CostCenterService, ExpenseAccountService } from "Shared/services"
import store from "Shared/store"
import { VendorRuleForForm } from "Shared/utils/vendorRule.form.utils"

const VendorService = {
    getVendorById: (id: string): Vendor => store.getState().vendors.items.find((vendor: Vendor) => vendor.id === id),
    update: async (id: string, updatedVendor: Vendor) => {
        const { data } = await axios.put(`/vendors/${id}`, { ...updatedVendor })
        return new Vendor(data)
    },
    create: async (newVendor: Vendor) => {
        const { data } = await axios.post(`/vendors`, newVendor)
        return new Vendor(data)
    },
    checkForDuplicateVendors: async (newVendor: Partial<Vendor>) => {
        const { data } = await axios.post(`/vendors/check-duplicates`, newVendor)
        return data
    },
    fetchOneVendor: async (id: string) => {
        const { data } = await axios.get(`/vendors/${id}`)
        return new Vendor(data)
    },
    deleteOneVendor: async (id: string) => {
        await axios.delete(`/vendors/${id}`)
    },
    updateComment: async (id: string, comments: Array<CommentItem>) => {
        const { data } = await axios.put(`/vendors/${id}/comments`, comments)
        return new Vendor(data)
    },
    updateVendorRule: async (id: string, rule: any) => {
        const { data } = await axios.patch(`/vendors/${id}/rule`, rule)
        return new Vendor(data)
    },
    updateVendorContracts: async (id: string, contracts: Vendor["contracts"]) => {
        const { data } = await axios.patch(`/vendors/${id}/contracts`, { contracts })
        return new Vendor(data)
    },
    approveVendorStatus: async (id: string) => {
        const { data } = await axios.put(`/vendors/${id}/approve`)
        return new Vendor(data)
    },
    fetchAllVendors: async () => {
        const {
            data: { docs },
        } = await axios.get(`vendors?limit=50000&sortBy=desc(createdAt)`)
        return docs.map((vendor: any) => new Vendor(vendor))
    },
    getLatestVendorCreditorNumber: async () => {
        const {
            data: { creditorNumberSequence },
        } = await axios.get("/vendors/sequence/latest-creditor-number")
        return creditorNumberSequence
    },
    export: async () => {
        await axios.post("/vendors/export")
    },
    syncExpenses: async (id: string) => {
        const { data } = await axios.post(`/vendors/${id}/sync-expenses`)
        return data
    },
    /**
     * If vendor creation is restricted that means having:
     *  - VENDOR__ALL__CREATE -> can create a directly approved vendor (button is shown)
     *  - VENDOR__ALL__CREATE_UNAPPROVED -> can create a vendor, but will be unapproved (button is shown)
     *  - None -> cannot open the form
     *
     * If vendor creation is not restricted, then
     *  - VENDOR__ALL__CREATE -> can create a directly approved vendor (button is shown)
     *  - VENDOR__ALL__CREATE_UNAPPROVED -> can create a directly approved vendor (button is shown)
     *  - None -> cannot open the form
     */
    shouldShowVendorButton: (isVendorCreationRestricted: boolean) =>
        AuthzService.isAllowedToCreateVendors() || (!isVendorCreationRestricted && AuthzService.isAllowedToCreateUnapprovedVendors()),
    getInitialVendorFormRule: (vendor: Vendor | undefined): VendorRuleForForm => ({
        costCenter: CostCenterService.getCostCenterById(vendor?.rule?.costCenter)?._id ?? "",
        costCenter2: CostCenter2Service.getCostCenter2ById(vendor?.rule?.costCenter2)?._id ?? "",
        expenseAccount: ExpenseAccountService.getExpenseAccountById(vendor?.rule?.expenseAccount)?._id ?? "",
        expensePaymentFlowInformation: vendor?.rule?.expensePaymentFlowInformation ?? "",
        paymentTerm: vendor?.rule?.paymentTerm,
        discounts: vendor?.rule?.discounts?.length ? vendor.rule.discounts : [{ period: undefined, percentage: undefined }],
    }),
}

export default VendorService
