import { TEN_BILLION } from "@finway-group/shared/lib/consts"
import { FormInstance, Rule } from "antd/lib/form"
import i18n from "i18next"
import moment from "moment-timezone"

import { parseCurrencyInput } from "Components/currencyInput/config"

export const LiquidityForecastRules = (formInstance: FormInstance): { [key: string]: Array<Rule> } => ({
    title: [{ type: "string", required: true, message: i18n.t("validation:required"), validateTrigger: ["blur", "onChange"] }],
    category: [{ required: true, message: i18n.t("validation:required"), validateTrigger: ["blur", "onChange"] }],
    quantity: [
        { required: true, message: i18n.t("validation:required") },
        //  { transform: (v) => Number(v), type: "number", message: i18n.t("validation:numeric") },
        { transform: (v) => Number(v), type: "integer", message: i18n.t("validation:integer") },
        { transform: (v) => Number(v), type: "number", min: 1, message: i18n.t("validation:min.number", { min: 1 }) },
    ],
    amount: [
        {
            validator: (_rule: any, inputValue: string) => {
                let value = 0
                inputValue = inputValue ? inputValue.toString() : ""
                if (inputValue !== "") {
                    value = parseCurrencyInput(inputValue)
                    if (value >= TEN_BILLION) {
                        return Promise.reject(new Error(i18n.t("validation:below.number_short", { max: "10B" })))
                    }
                    if (value <= -TEN_BILLION) {
                        return Promise.reject(new Error(i18n.t("validation:greater.number_short", { min: "-10B" })))
                    }
                    return Promise.resolve()
                }

                return Promise.reject(new Error(i18n.t("validation:required")))
            },
        },
    ],
    startDate: [
        { type: "date", required: true, message: i18n.t("validation:required"), validateTrigger: ["blur", "onChange"] },
        {
            validator: (_rule: any, value: moment.Moment) => {
                const endDate = formInstance.getFieldValue("endDate")
                if (value && endDate) {
                    if (value.isAfter(endDate, "month")) {
                        return Promise.reject(
                            i18n.t("validation:date_before", {
                                attribute: i18n.t("input:liquidity.planned_transaction.start_date"),
                                compare: i18n.t("input:liquidity.planned_transaction.end_date"),
                            }),
                        )
                    }
                }
                return Promise.resolve()
            },
        },
    ],
    endDate: [
        { type: "date", required: true, message: i18n.t("validation:required"), validateTrigger: ["blur", "onChange"] },
        {
            validator: (_rule: any, value: moment.Moment) => {
                const startDate = formInstance.getFieldValue("startDate")
                if (value && startDate) {
                    if (value.isBefore(startDate, "month")) {
                        return Promise.reject(
                            i18n.t("validation:date_after", {
                                attribute: i18n.t("input:liquidity.planned_transaction.end_date"),
                                compare: i18n.t("input:liquidity.planned_transaction.start_date"),
                            }),
                        )
                    }
                }
                return Promise.resolve()
            },
        },
    ],
})

export default LiquidityForecastRules
