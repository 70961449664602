import { Company, RightEnum } from "@finway-group/shared/lib/models"
import { notification } from "antd"
import React, { Suspense, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Redirect, Switch } from "react-router-dom"

import FullScreenLoader from "Components/FullScreenLoader"
import Loading from "Components/loading"
import ScrollToTop from "Components/scrollToTop"
import { ConfirmEmail, ForgotPassword, Login, NewPasswordPage, ResetPasswordPage } from "Features/authentication"
import AuthErrorPage from "Features/authentication/AuthErrorPage"
import ErrorPage from "Features/errors/ErrorPage"
import NotFoundPage from "Features/errors/NotFoundPage"
import LiquidityContainer from "Features/pages/liquidity/liquidity.container"
import { isIntercomEnabled, isLocal } from "Shared/config/consts"
import { SelectedInboxInvoicesProvider } from "Shared/context/selectedExpenses.context"
import { useApiVersion } from "Shared/hooks/apiVersion.hooks"
import { useAuth } from "Shared/hooks/auth.hooks"
import { useCompanies } from "Shared/hooks/company.hooks"
import { useIsFreeVersion } from "Shared/hooks/featureFlags.hooks"
import { useLoggedInEmployeeProfile } from "Shared/hooks/user.hooks"
import { AuthzService } from "Shared/services"

import { ErrorRoute } from "./ErrorRoute"
import { FullScreenRoute, PrivateFullScreen } from "./FullScreenRoute"
import { PrivateRoute } from "./PrivateRoute"
import { RoutePathEnum } from "./RoutePath.enum"

// lazy loading
const SettingsPage = React.lazy(() => import("Features/settings/settings.page").then((module) => ({ default: module.SettingsPage })))
const Intercom = React.lazy(() => import("Components/intercom"))
const UserPilot = React.lazy(() => import("Components/userPilot"))

const Dashboard = React.lazy(() => import("Features/pages/dashboard/dashboard"))
const ExpenseDetails = React.lazy(() => import("Features/pages/expenses/expenseDetails/expenseDetails.container"))

const CostCenters = React.lazy(() => import("Features/pages/costCenters/costCenter.list"))
const CostCenterDetail = React.lazy(() => import("Features/pages/costCenters/costCenterDetails/costCenterDetails.container"))

const Employees = React.lazy(() => import("Features/pages/employees/employees.page"))
const EmployeeDetail = React.lazy(() => import("Features/pages/employees/employeeDetails.container"))

const VendorList = React.lazy(() => import("Features/pages/vendors/vendor.list"))
const VendorDetail = React.lazy(() => import("Features/pages/vendors/vendorDetails.container"))

const SubscriptionList = React.lazy(() => import("Features/pages/subscriptions/subscription.list"))
const SubscriptionDetails = React.lazy(() => import("Features/pages/subscriptions/subscriptionDetails.container"))

const CreditCardContainer = React.lazy(() => import("Features/pages/creditCards/creditCard.container"))
const CreditCardDetail = React.lazy(() => import("Features/pages/creditCards/creditCardContainer/creditCardDetails.container"))
const WalletDetailsContainer = React.lazy(() => import("Features/pages/creditCards/walletDetails/walletDetails.container"))

const TransactionList = React.lazy(() => import("Features/pages/transactions/transactions.list"))
const TransactionsDetails = React.lazy(() => import("Features/pages/transactions/transactionsDetails.container"))

const Workflows = React.lazy(() => import("Features/pages/workflows/workflow.list"))

const Reports = React.lazy(() => import("Features/pages/reporting/Reports.container"))

const AppRouter: React.FC = () => {
    const { t } = useTranslation()
    const { isAuthenticated, logout, companyId, featureFlags } = useAuth()

    const companies = useCompanies()
    const cardsEnabled = companies.find((company: Company) => company._id === companyId)?.cardsEnabled

    const isFreeVersion = useIsFreeVersion()
    const loggedInEmployee = useLoggedInEmployeeProfile()

    const { checkedVersion } = useApiVersion()

    useEffect(() => {
        if (isAuthenticated && companyId) {
            // Make sure to update the token by logging loggedInEmployee out if any feature flag is missing
            if (featureFlags && (featureFlags.paidVersion === undefined || featureFlags.stripeEnabled === undefined || featureFlags.outgoingWireTransferEnabled === undefined)) {
                notification.open({
                    type: "info",
                    message: t("notification:new_api_version.title"),
                    description: t("notification:new_api_version.message"),
                    duration: 0,
                })
                logout()
            }

            loggedInEmployee.setActiveCompanyProfile(companyId)
        }
    }, [loggedInEmployee, companyId])

    return !checkedVersion ? (
        <FullScreenLoader />
    ) : (
        <Suspense fallback={<Loading />}>
            {isIntercomEnabled && <Intercom />}
            {!isLocal && <UserPilot />}
            <ScrollToTop />

            <Switch>
                <FullScreenRoute exact path={RoutePathEnum.LOGIN}>
                    <Login />
                </FullScreenRoute>
                <FullScreenRoute path={RoutePathEnum.FORGOT_PASSWORD}>
                    <ForgotPassword />
                </FullScreenRoute>
                <PrivateFullScreen path={RoutePathEnum.NEW_PASSWORD}>
                    <NewPasswordPage />
                </PrivateFullScreen>
                <FullScreenRoute path={RoutePathEnum.RESET_TOKEN}>
                    <ResetPasswordPage />
                </FullScreenRoute>
                <FullScreenRoute path={RoutePathEnum.CONFIRM_EMAIL}>
                    <ConfirmEmail />
                </FullScreenRoute>

                {/* // TODO: @John and @Vittorio re-add */}
                {/* <FullScreenRoute path="/deforce-sso/:code">
                        <DeforceSso />
                    </FullScreenRoute> */}

                <PrivateRoute path={RoutePathEnum.SETTINGS} includedFreeVersion>
                    <SettingsPage />
                </PrivateRoute>

                <PrivateRoute exact path="/" includedFreeVersion>
                    <Redirect to={{ pathname: isFreeVersion ? RoutePathEnum.LM : RoutePathEnum.EXPENSES }} />
                </PrivateRoute>

                <PrivateRoute exact path={RoutePathEnum.EXPENSES}>
                    <Dashboard />
                </PrivateRoute>

                <PrivateRoute exact path={RoutePathEnum.EXPENSE_DETAIL_PAGE}>
                    <SelectedInboxInvoicesProvider>
                        <ExpenseDetails />
                    </SelectedInboxInvoicesProvider>
                </PrivateRoute>

                <PrivateRoute exact path={RoutePathEnum.FOLDER_EXPENSE_DETAIL_PAGE}>
                    <SelectedInboxInvoicesProvider>
                        <ExpenseDetails />
                    </SelectedInboxInvoicesProvider>
                </PrivateRoute>

                <PrivateRoute exact path={RoutePathEnum.SUBSCRIPTIONS}>
                    <SubscriptionList />
                </PrivateRoute>

                <PrivateRoute exact path={RoutePathEnum.SUBSCRIPTION_DETAIL_PAGE}>
                    <SubscriptionDetails />
                </PrivateRoute>

                {/* MIND THE ORDER! This needs to be BEFORE CreditCardDetail */}
                <PrivateRoute exact path={RoutePathEnum.WALLET_DETAIL_PAGE} canSee={cardsEnabled && AuthzService.isRightGrantedForLoggedInUser(RightEnum.CARD_WALLET__ALL__READ)}>
                    <WalletDetailsContainer />
                </PrivateRoute>

                <PrivateRoute exact path={RoutePathEnum.CARDS} canSee={cardsEnabled}>
                    <CreditCardContainer />
                </PrivateRoute>

                <PrivateRoute exact path={RoutePathEnum.CARD_DETAIL_PAGE} canSee={cardsEnabled}>
                    <CreditCardDetail />
                </PrivateRoute>

                <PrivateRoute exact path={RoutePathEnum.TRANSACTIONS} includedFreeVersion canSee={AuthzService.isRightGrantedForLoggedInUser(RightEnum.TRANSACTION__ALL__READ)}>
                    <TransactionList />
                </PrivateRoute>

                <PrivateRoute exact path={RoutePathEnum.TRANSACTION_DETAIL_PAGE} includedFreeVersion>
                    <TransactionsDetails />
                </PrivateRoute>

                <PrivateRoute exact path={RoutePathEnum.WORKFLOWS} canSee={AuthzService.isRightGrantedForLoggedInUser(RightEnum.WORKFLOW__ALL__READ)}>
                    <Workflows />
                </PrivateRoute>

                <PrivateRoute exact path={RoutePathEnum.WORKFLOW_DETAIL_PAGE} canSee={AuthzService.isRightGrantedForLoggedInUser(RightEnum.WORKFLOW__ALL__READ)}>
                    <Workflows />
                </PrivateRoute>

                <PrivateRoute exact path={RoutePathEnum.EMPLOYEES} includedFreeVersion>
                    <Employees />
                </PrivateRoute>

                <PrivateRoute exact path={RoutePathEnum.EMPLOYEE_DETAIL_PAGE} includedFreeVersion>
                    <EmployeeDetail />
                </PrivateRoute>

                <PrivateRoute exact path={RoutePathEnum.VENDORS} canSee={AuthzService.isRightGrantedForLoggedInUser(RightEnum.VENDOR__ALL__READ)}>
                    <VendorList />
                </PrivateRoute>
                <PrivateRoute exact path={RoutePathEnum.VENDOR_DETAIL_PAGE} canSee={AuthzService.isRightGrantedForLoggedInUser(RightEnum.VENDOR__ALL__READ)}>
                    <VendorDetail />
                </PrivateRoute>

                <PrivateRoute exact path={RoutePathEnum.COST_CENTERS}>
                    <CostCenters />
                </PrivateRoute>

                <PrivateRoute exact path={RoutePathEnum.COST_CENTER_DETAIL_PAGE}>
                    <CostCenterDetail />
                </PrivateRoute>

                <PrivateRoute path={RoutePathEnum.LM} includedFreeVersion canSee={AuthzService.isRightGrantedForLoggedInUser(RightEnum.LIQUIDITY__ALL__READ)}>
                    <LiquidityContainer />
                </PrivateRoute>

                <PrivateRoute exact path={RoutePathEnum.REPORTS} canSee={AuthzService.isRightGrantedForLoggedInUser(RightEnum.REPORTING__ALL__READ)}>
                    <Reports />
                </PrivateRoute>

                <ErrorRoute path={RoutePathEnum.ERROR}>
                    <ErrorPage />
                </ErrorRoute>
                <FullScreenRoute path={RoutePathEnum.AUTH_ERROR}>
                    <AuthErrorPage />
                </FullScreenRoute>

                {/* this will redirect any URL without a route to "/not-found" */}
                <ErrorRoute path={RoutePathEnum.GLOBAL}>
                    <NotFoundPage />
                </ErrorRoute>
            </Switch>
        </Suspense>
    )
}

export default AppRouter
