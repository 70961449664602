import { MutableRefObject, useEffect, useRef } from "react"

export const useCompare = (value: any) => {
    const prevValue = usePrevious(value)
    return prevValue !== value
}

export const usePrevious = (value: any) => {
    const ref = useRef()
    useEffect(() => {
        ref.current = value
    })
    return ref.current
}

export function usePreviousTyped<T>(value: T): MutableRefObject<T | undefined>["current"] {
    const ref = useRef<T>()
    useEffect(() => {
        ref.current = value
    }, [value])
    return ref.current
}
