import { CostCenter, CostCenter2, CreditorInterface, Employee, ExpenseAccount, PossibleMatchesFilter } from "@finway-group/shared/lib/models"
import { TransactionStatusEnum } from "@finway-group/shared/lib/models/transaction/transactionStatus.enum"
import Form from "antd/lib/form"
import moment from "moment"
import React, { ChangeEvent, useEffect } from "react"
import { useTranslation } from "react-i18next"

import { FilterFormInterface } from "Components/filter/filterTypes"
import { FormInputEnum, FormSelectOption } from "Components/form/form.types"
import FormMinMax from "Components/form/formMinMax"
import FormMinMaxDate from "Components/form/formMinMaxDate"
import FormMinMaxDateDueDays from "Components/form/formMinMaxDateDueDays"
import FormSelect from "Components/form/formSelect"
import { useCompany } from "Shared/hooks/company.hooks"
import { useIsTravelEnabled } from "Shared/hooks/featureFlags.hooks"
import { getTableFilterObjectStore } from "Shared/hooks/table.hooks"
import { UserService } from "Shared/services"
import {
    createDynamicListWithLabel,
    getDynamicCostCenter2List,
    getDynamicCostCenterList,
    getDynamicCreditorList,
    getDynamicExpenseAccountList,
    getExpenseKindList,
} from "Shared/utils/filter.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

import { generateRules } from "./rules/possibleMatchesFilter.rules"

const adjustFilterObject = (filterObject: PossibleMatchesFilter): PossibleMatchesFilter => ({
    ...filterObject,
    minDatePurchased: filterObject.minDatePurchased ? moment(filterObject.minDatePurchased).toDate() : undefined,
    maxDatePurchased: filterObject.maxDatePurchased ? moment(filterObject.maxDatePurchased).toDate() : undefined,
    minDueDate: filterObject.minDueDate ? moment(filterObject.minDueDate).toDate() : undefined,
    maxDueDate: filterObject.maxDueDate ? moment(filterObject.maxDueDate).toDate() : undefined,
    minInvoiceDueDate: filterObject.minInvoiceDueDate ? moment(filterObject.minInvoiceDueDate).toDate() : undefined,
    maxInvoiceDueDate: filterObject.maxInvoiceDueDate ? moment(filterObject.maxInvoiceDueDate).toDate() : undefined,
    minAmount: filterObject.minAmount ? Number(filterObject.minAmount) : undefined,
    maxAmount: filterObject.maxAmount ? Number(filterObject.maxAmount) : undefined,
    minProbability: filterObject.minProbability ? Number(filterObject.minProbability) : undefined,
    maxProbability: filterObject.maxProbability ? Number(filterObject.maxProbability) : undefined,
})

const PossibleMatchFilterForm: React.FC<FilterFormInterface> = ({ table, formInstance, options, onSubmit }) => {
    const { t } = useTranslation()
    const isTravelEnabled = useIsTravelEnabled()

    const tableFilterObject = getTableFilterObjectStore<PossibleMatchesFilter>(table)
    const [possibleMatchesFilter, setPossibleMatchesFilter] = useStateIfMounted(adjustFilterObject(tableFilterObject))

    const rules = generateRules(formInstance)
    const employeeList = UserService.getApproverEmployeeList(options.employees as Array<Employee>)
    const expenseKindList = getExpenseKindList(isTravelEnabled)
    const { showCostCentersIds } = useCompany()

    const transactionStatusList: Array<FormSelectOption> = createDynamicListWithLabel("label", [
        { param: "matching.transaction_tag.completed", value: TransactionStatusEnum.COMPLETED },
        { param: "matching.transaction_tag.pending", value: TransactionStatusEnum.PENDING },
        { param: "matching.transaction_tag.failed", value: TransactionStatusEnum.FAILED },
    ])

    useEffect(() => {
        formInstance.resetFields()
        setPossibleMatchesFilter(adjustFilterObject(possibleMatchesFilter))
    }, [])

    useEffect(() => {
        formInstance.validateFields()
    }, [possibleMatchesFilter])

    return (
        <Form id="possibleMatchFilterForm" className="form--filter" form={formInstance} onFinish={onSubmit}>
            <FormMinMax
                header={t("input:filter_form.amount")}
                inputType={FormInputEnum.NUMBER}
                min={{
                    initialValue: possibleMatchesFilter.minAmount,
                    label: t("input:filter_form.min_amount"),
                    name: "minAmount",
                    onChange: (e: ChangeEvent<HTMLInputElement>) => setPossibleMatchesFilter({ ...possibleMatchesFilter, minAmount: +e.target.value }),
                    rules: rules.minAmount,
                    placeholder: t("placeholder:filter_form.min_amount"),
                }}
                max={{
                    initialValue: possibleMatchesFilter.maxAmount,
                    label: t("input:filter_form.max_amount"),
                    name: "maxAmount",
                    onChange: (e: ChangeEvent<HTMLInputElement>) => setPossibleMatchesFilter({ ...possibleMatchesFilter, maxAmount: +e.target.value }),
                    rules: rules.maxAmount,
                    placeholder: t("placeholder:filter_form.max_amount"),
                }}
            />

            <FormMinMaxDate
                header={t("input:filter_form.date_purchased")}
                min={{
                    name: "minDatePurchased",
                    label: t("input:filter_form.from"),
                    placeholder: t("placeholder:filter_form.min_date"),
                    initialValue: possibleMatchesFilter.minDatePurchased,
                    rules: rules.minDatePurchased,
                    onChange: (v: any) => setPossibleMatchesFilter({ ...possibleMatchesFilter, minDatePurchased: v?.startOf("day") }),
                }}
                max={{
                    name: "maxDatePurchased",
                    label: t("input:filter_form.to"),
                    placeholder: t("placeholder:filter_form.max_date"),
                    initialValue: possibleMatchesFilter.maxDatePurchased,
                    rules: rules.maxDatePurchased,
                    onChange: (v: any) => setPossibleMatchesFilter({ ...possibleMatchesFilter, maxDatePurchased: v?.startOf("day") }),
                }}
            />

            <FormMinMaxDateDueDays
                header={t("input:filter_form.due_date")}
                dueInDaysSelected={possibleMatchesFilter.dueInDaysRadio}
                min={{
                    name: "minInvoiceDueDate",
                    label: t("input:expense_filter.min_date"),
                    placeholder: t("placeholder:expense_filter.min_date"),
                    initialValue: possibleMatchesFilter.minDueDate,
                    rules: rules.minDueDate,
                    onChange: () => formInstance.validateFields(["minDueDate", "maxDueDate"]),
                }}
                max={{
                    name: "maxInvoiceDueDate",
                    label: t("input:expense_filter.max_date"),
                    placeholder: t("placeholder:expense_filter.max_date"),
                    initialValue: possibleMatchesFilter.maxDueDate,
                    rules: rules.maxDueDate,
                    onChange: () => formInstance.validateFields(["minDueDate", "maxDueDate"]),
                }}
                days={{
                    name: "dueInDays",
                    label: t("input:filter_form.due_in_days"),
                    placeholder: t("placeholder:filter_form.due_in_days"),
                    initialValue: possibleMatchesFilter.dueInDays,
                    onChange: () => formInstance.validateFields(["dueInDays"]),
                    rules: rules.dueInDays,
                }}
            />

            <FormSelect name="kind" header={t("input:expense_filter.kind")} initialValue={possibleMatchesFilter.kind} options={expenseKindList} isCollapse fallback />

            <FormSelect
                name="vendor"
                header={t("input:filter_form.vendor")}
                initialValue={possibleMatchesFilter.vendor ?? ""}
                options={getDynamicCreditorList(options.creditors as Array<CreditorInterface>)}
                isCollapse
                showSearch
                fallback
            />

            <FormSelect
                name="costCenter"
                header={t("input:expense_filter.cost_center")}
                initialValue={possibleMatchesFilter.costCenter}
                options={getDynamicCostCenterList(options.costCenters as Array<CostCenter>, showCostCentersIds)}
                isCollapse
                showSearch
                fallback
            />

            <FormSelect
                name="costCenter2"
                header={t("input:filter_form.cost_center_2")}
                initialValue={possibleMatchesFilter.costCenter2}
                options={getDynamicCostCenter2List(options.costCenters2 as Array<CostCenter2>)}
                isCollapse
                showSearch
                fallback
            />

            <FormSelect
                name="expenseAccount"
                header={t("input:filter_form.expense_account")}
                initialValue={possibleMatchesFilter.expenseAccount}
                options={getDynamicExpenseAccountList(options.expenseAccounts as Array<ExpenseAccount>)}
                isCollapse
                showSearch
                fallback
            />

            <FormSelect
                name="requestedBy"
                header={t("input:expense_filter.requester")}
                initialValue={possibleMatchesFilter.requestedBy}
                options={options.employees ?? []}
                displayImages
                isCollapse
                showSearch
                fallback
            />

            <FormSelect
                name="approvalNeededBy"
                header={t("input:expense_filter.approver")}
                initialValue={possibleMatchesFilter.approvalNeededBy}
                options={employeeList}
                displayImages
                isCollapse
                showSearch
                fallback
            />

            <FormSelect
                name="transactionStatus"
                header={t("input:transaction_filter.transaction_status")}
                initialValue={String(possibleMatchesFilter.transactionStatus ?? "")}
                options={transactionStatusList}
                isCollapse
                fallback
            />

            <FormMinMax
                header={t("input:filter_form.probability_range")}
                inputType={FormInputEnum.NUMBER}
                min={{
                    initialValue: possibleMatchesFilter.minProbability,
                    label: t("input:filter_form.min_probability"),
                    name: "minProbability",
                    onChange: (e: ChangeEvent<HTMLInputElement>) => setPossibleMatchesFilter({ ...possibleMatchesFilter, minProbability: +e.target.value }),
                    rules: rules.minProbability,
                    placeholder: t("placeholder:filter_form.min_probability"),
                }}
                max={{
                    initialValue: possibleMatchesFilter.maxProbability,
                    label: t("input:filter_form.max_probability"),
                    name: "maxProbability",
                    onChange: (e: ChangeEvent<HTMLInputElement>) => setPossibleMatchesFilter({ ...possibleMatchesFilter, maxProbability: +e.target.value }),
                    rules: rules.maxProbability,
                    placeholder: t("placeholder:filter_form.max_probability"),
                }}
            />
        </Form>
    )
}

export default PossibleMatchFilterForm
