import { CalculationIntervalEnum } from "@finway-group/shared/lib/models"
import type { Team } from "@finway-group/shared/lib/models"
import { Action } from "redux"

import { CostCenterBudgetUsageBarChartsOptions } from "Features/pages/costCenters/costCenterDetails/costCenterBudgetUsageBarCharts"
import { ReportingFilterInterface } from "Features/pages/reporting/reportingFilter.interface"

export enum AnalyticsActionTypes {
    FETCH_OVERVIEW_DATA = "FETCH_OVERVIEW_DATA",
    FETCH_CARD_OVERVIEW_DATA = "FETCH_CARD_OVERVIEW_DATA",
    FETCH_EXPENSES_PER_EMPLOYEE_DATA = "FETCH_EXPENSES_PER_EMPLOYEE_DATA",
    FETCH_EXPENSES_PER_COST_CENTER_DATA = "FETCH_EXPENSES_PER_COST_CENTER_DATA",
    FETCH_EXPENSES_PER_COST_CENTER_OVER_LAST_12_MONTHS_DATA = "FETCH_EXPENSES_PER_COST_CENTER_OVER_LAST_12_MONTHS_DATA",
    FETCH_COST_CENTER_BUDGET_DATA = "FETCH_COST_CENTER_BUDGET_DATA",
    FETCH_COST_CENTER_EXPENSE_DATA = "FETCH_COST_CENTER_EXPENSE_DATA",
    FETCH_COST_CENTER_EXPENSE_DATA_PIE = "FETCH_COST_CENTER_EXPENSE_DATA_PIE",
    FETCH_EXPENSES_PER_DEPARTMENT_DATA_PIE = "FETCH_EXPENSES_PER_DEPARTMENT_DATA_PIE",
    SET_EXPENSES_PER_COST_CENTER_OVER_LAST_12_MONTHS_FILTER = "SET_EXPENSES_PER_COST_CENTER_OVER_LAST_12_MONTHS_FILTER",
    SET_COST_CENTER_INTERVAL = "SET_COST_CENTER_INTERVAL",
    SET_COST_CENTER_INTERVAL_PIE = "SET_COST_CENTER_INTERVAL_PIE",
    SET_EXPENSES_PER_DEPARTMENT_INTERVAL_PIE = "SET_EXPENSES_PER_DEPARTMENT_INTERVAL_PIE",
    SET_EMPLOYEE_INTERVAL = "SET_EMPLOYEE_INTERVAL",
    SET_COST_CENTER_BUDGET_USAGE_BAR_GRAPH_OPTIONS = "SET_COST_CENTER_BUDGET_USAGE_BAR_GRAPH_OPTIONS",
    RESET_CARD_OVERVIEW_DATA = "RESET_CARD_OVERVIEW_DATA",
    FETCH_TEAMS_WITH_USERS_COUNT = "FETCH_TEAMS_WITH_USERS_COUNT",
}

export interface FetchOverviewDataAction extends Action<typeof AnalyticsActionTypes.FETCH_OVERVIEW_DATA> {
    type: typeof AnalyticsActionTypes.FETCH_OVERVIEW_DATA
    overviewData: any
}

export interface FetchCardOverviewDataAction extends Action<typeof AnalyticsActionTypes.FETCH_CARD_OVERVIEW_DATA> {
    type: typeof AnalyticsActionTypes.FETCH_CARD_OVERVIEW_DATA
    cardOverviewData: any
}
export interface FetchExpensesPerEmployeeDataAction extends Action<typeof AnalyticsActionTypes.FETCH_EXPENSES_PER_EMPLOYEE_DATA> {
    type: typeof AnalyticsActionTypes.FETCH_EXPENSES_PER_EMPLOYEE_DATA
    expensesPerEmployee: any
}
export interface FetchExpensesPerCostCenterOverLast12MonthsDataAction extends Action<typeof AnalyticsActionTypes.FETCH_EXPENSES_PER_COST_CENTER_OVER_LAST_12_MONTHS_DATA> {
    type: typeof AnalyticsActionTypes.FETCH_EXPENSES_PER_COST_CENTER_OVER_LAST_12_MONTHS_DATA
    expensesPerCostCenterOverLast12MonthsData: any
}

export interface FetchCostCenterBudgetDataAction extends Action<typeof AnalyticsActionTypes.FETCH_COST_CENTER_BUDGET_DATA> {
    type: typeof AnalyticsActionTypes.FETCH_COST_CENTER_BUDGET_DATA
    costCenterBudgetData: any
}

export interface FetchCostCenterExpenseDataAction extends Action<typeof AnalyticsActionTypes.FETCH_COST_CENTER_EXPENSE_DATA> {
    type: typeof AnalyticsActionTypes.FETCH_COST_CENTER_EXPENSE_DATA
    costCenterExpenseData: Array<any>
}

export interface FetchCostCenterExpenseDataPieAction extends Action<typeof AnalyticsActionTypes.FETCH_COST_CENTER_EXPENSE_DATA_PIE> {
    type: typeof AnalyticsActionTypes.FETCH_COST_CENTER_EXPENSE_DATA_PIE
    costCenterExpenseDataPie: Array<any>
}

export interface FetchExpensesPerDepartmentPie extends Action<typeof AnalyticsActionTypes.FETCH_EXPENSES_PER_DEPARTMENT_DATA_PIE> {
    type: typeof AnalyticsActionTypes.FETCH_EXPENSES_PER_DEPARTMENT_DATA_PIE
    expensesPerDepartmentDataPie: Array<any>
}

export interface SetCostCenterIntervalAction extends Action<typeof AnalyticsActionTypes.SET_COST_CENTER_INTERVAL> {
    type: typeof AnalyticsActionTypes.SET_COST_CENTER_INTERVAL
    costCenterInterval: CalculationIntervalEnum
}

export interface SetCostCenterIntervalPieAction extends Action<typeof AnalyticsActionTypes.SET_COST_CENTER_INTERVAL_PIE> {
    type: typeof AnalyticsActionTypes.SET_COST_CENTER_INTERVAL_PIE
    costCenterIntervalPie: CalculationIntervalEnum
}

export interface SetExpensesPerDepartmentIntervalPieAction extends Action<typeof AnalyticsActionTypes.SET_EXPENSES_PER_DEPARTMENT_INTERVAL_PIE> {
    type: typeof AnalyticsActionTypes.SET_EXPENSES_PER_DEPARTMENT_INTERVAL_PIE
    expensesPerDepartmentIntervalPie: CalculationIntervalEnum
}

export interface SetEmployeeIntervalAction extends Action<typeof AnalyticsActionTypes.SET_EMPLOYEE_INTERVAL> {
    type: typeof AnalyticsActionTypes.SET_EMPLOYEE_INTERVAL
    employeeInterval: CalculationIntervalEnum
}

export interface SetExpensesPerCostCenterOver12MonthsFilter extends Action<typeof AnalyticsActionTypes.SET_EXPENSES_PER_COST_CENTER_OVER_LAST_12_MONTHS_FILTER> {
    type: typeof AnalyticsActionTypes.SET_EXPENSES_PER_COST_CENTER_OVER_LAST_12_MONTHS_FILTER
    expensesPerCostCenterOverLast12MonthsFilter: ReportingFilterInterface
}

export interface SetCostCenterBudgetUsageBarGraphOptions extends Action<typeof AnalyticsActionTypes.SET_COST_CENTER_BUDGET_USAGE_BAR_GRAPH_OPTIONS> {
    type: typeof AnalyticsActionTypes.SET_COST_CENTER_BUDGET_USAGE_BAR_GRAPH_OPTIONS
    costCenterBudgetUsageBarGraphOptions: CostCenterBudgetUsageBarChartsOptions
}
export interface ResetCardOverviewDataAction extends Action<typeof AnalyticsActionTypes.RESET_CARD_OVERVIEW_DATA> {
    type: typeof AnalyticsActionTypes.RESET_CARD_OVERVIEW_DATA
}

export interface FetchTeamsWithUsersCountAction extends Action<typeof AnalyticsActionTypes.FETCH_TEAMS_WITH_USERS_COUNT> {
    type: typeof AnalyticsActionTypes.FETCH_TEAMS_WITH_USERS_COUNT
    teams: Array<Team>
}

export type AnalyticsActions =
    | FetchOverviewDataAction
    | FetchExpensesPerEmployeeDataAction
    | FetchExpensesPerCostCenterOverLast12MonthsDataAction
    | FetchCostCenterBudgetDataAction
    | FetchExpensesPerDepartmentPie
    | SetCostCenterIntervalAction
    | SetCostCenterIntervalPieAction
    | SetEmployeeIntervalAction
    | SetExpensesPerDepartmentIntervalPieAction
    | SetExpensesPerCostCenterOver12MonthsFilter
    | FetchCardOverviewDataAction
    | FetchCostCenterExpenseDataAction
    | FetchCostCenterExpenseDataPieAction
    | SetCostCenterBudgetUsageBarGraphOptions
    | ResetCardOverviewDataAction
    | FetchTeamsWithUsersCountAction
