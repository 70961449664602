import { COLOR } from "@finway-group/shared/lib/consts"
import { Budget, BudgetPeriodEnum, CostCenter, CostCenterChartTypeEnum, CurrencyEnum } from "@finway-group/shared/lib/models"
import moment, { Moment } from "moment"

import { ColorBar } from "Components/colorsBar"
import { parseCurrencyInput } from "Components/currencyInput/config"
import { PeriodRangeOptionEnum } from "Features/pages/costCenters/costCenterDetails/costCenterPeriodOption.interface"
import { BudgetListElement } from "Features/pages/costCenters/costCenterForm/costCenterFormData.interface"
import i18n from "Shared/locales/i18n"
import { getPreviousAndNextMonthsWithYear, getPreviousAndNextQuartersWithYear, getPreviousAndNextYears } from "Shared/utils/date.utils"
import { mergeArrays } from "Shared/utils/helper.utils"

/**
 * Groups the given cost center by parents. If no parent is available, or the parent is not
 * in the given list (e.g. because of permission restrictions), then leave as an individual
 * cost center.
 */
export const groupCostCentersByParent = (costCenters: Array<CostCenter>) =>
    costCenters
        .filter((costCenter: CostCenter) => !costCenter.parentCostCenter || !costCenters.find((otherCostCenter: CostCenter) => otherCostCenter._id === costCenter.parentCostCenter))
        .map((costCenter: any) =>
            costCenter.subCostCenters?.length > 0
                ? {
                      ...costCenter,
                      children: findSubCostCentersByIds(
                          costCenter.subCostCenters,
                          costCenters.filter((costCenter: CostCenter) => costCenter.parentCostCenter),
                      ),
                  }
                : costCenter,
        )

const findSubCostCentersByIds = (subCostCenterIds: Array<string>, costCenters: Array<CostCenter>): any =>
    subCostCenterIds
        .map((costCenterId: string) => costCenters.find((costCenter: CostCenter) => costCenter._id === costCenterId))
        .filter((costCenter: CostCenter) => costCenter !== undefined)

export const findSearchResultParentCostCenters = (costCenters: Array<CostCenter>): Array<any> => {
    const parentCostCenterIds: Array<string> = []
    for (const costCenter of costCenters) {
        const isParentSearchResult = costCenters.find((cc: CostCenter) => cc._id === costCenter.parentCostCenter)
        const isParentAlreadyMatched = costCenter.parentCostCenter && parentCostCenterIds.includes(costCenter.parentCostCenter)

        if (costCenter.parentCostCenter && !isParentSearchResult && !isParentAlreadyMatched) {
            parentCostCenterIds.push(costCenter.parentCostCenter)
        }
    }

    return parentCostCenterIds
}

export const isParentCostCenterAvailable = (costCenter: CostCenter, costCenters: Array<CostCenter>) =>
    costCenter.parentCostCenter && costCenters.find((otherCostCenter: CostCenter) => otherCostCenter._id === costCenter.parentCostCenter)

export const getCostCenterLabel = (costCenter: CostCenter, shouldShowId: boolean) => (shouldShowId ? `ID ${costCenter.id} - ${costCenter.name}` : costCenter.name)

export const isResponsibleForCostCenterOrSharedWith = (costCenterId: string, userId: string, costCenters: Array<CostCenter>) => {
    const costCenter = costCenters.filter((cc1) => cc1._id === costCenterId)[0]
    const isResponsibleForOrSharedWith = costCenter?.responsibleUser === userId || costCenter?.sharedUsers?.includes(userId)
    return isResponsibleForOrSharedWith
}

export const calculatePaddingValue = (value: number) => (value < 6 ? 0.5 : value < 9 ? 0.3 : 0.2)

export const getBudgetsList = (budgets?: Array<Budget>): Array<any> => {
    if (!budgets) return []
    const budgetsList = []
    for (const budget of budgets) {
        let periodName: string
        if (budget.period === 0) periodName = `M${moment(budget.startDate).format("YYYY-MM")}`
        else if (budget.period === 1) periodName = `Q${moment(budget.startDate).format("YYYY-Q")}`
        else periodName = `Y${moment(budget.startDate).format("YYYY")}`
        budgetsList.push({ value: budget.value, periodName })
    }
    return budgetsList
}

export const getPeriodNames = (period?: BudgetPeriodEnum, budgetsList?: Array<BudgetListElement>): Array<string> => {
    let periodNames: Array<string> = [] // [M2021-04, M2021-05, M2021-06, ...]
    if (period === BudgetPeriodEnum.MONTHLY) periodNames = getPreviousAndNextMonthsWithYear(4, 24).map((month: string) => `M${month}`)
    else if (period === BudgetPeriodEnum.QUARTERLY) periodNames = getPreviousAndNextQuartersWithYear(4, 8).map((quarter: string) => `Q${quarter}`)
    else if (period === BudgetPeriodEnum.YEARLY) periodNames = getPreviousAndNextYears(2, 3).map((year: string) => `Y${year}`)
    if (budgetsList) {
        const budgetsListPeriodNames = budgetsList.map((budget) => budget.periodName)
        periodNames = mergeArrays(periodNames, budgetsListPeriodNames).sort()
    }
    return periodNames
}

export const getBudgetsFromBudgetsList = (budgetsList: Array<BudgetListElement> | undefined, defaultCurrency: CurrencyEnum, defaultPeriod: BudgetPeriodEnum): Array<any> => {
    if (!budgetsList) return []
    const budgets = []

    budgetsList.sort((a, b) => a.periodName.localeCompare(b.periodName))

    for (const budgetListElement of budgetsList) {
        let startDate: Moment = moment()
        let endDate: Moment = moment()

        if (budgetListElement?.periodName[0] === "M") {
            const [year, month] = budgetListElement.periodName.substr(1).split("-")
            const date = moment().set({ year: Number(year), month: Number(month) - 1 }) // set month in moment uses 0 index
            startDate = date.startOf("month")
            endDate = date.clone().endOf("month")
        } else if (budgetListElement?.periodName[0] === "Q") {
            const [year, quarter] = budgetListElement.periodName.substr(1).split("-")
            const date = moment().set({ year: Number(year), quarter: Number(quarter) })
            startDate = date.startOf("quarter")
            endDate = date.clone().endOf("quarter")
        } else {
            const year = budgetListElement.periodName.substr(1)
            const date = moment().set({ year: Number(year) })
            startDate = date.startOf("year")
            endDate = date.clone().endOf("year")
        }

        budgets.push({
            startDate: startDate.toDate(),
            endDate: endDate.toDate(),
            value: parseCurrencyInput(budgetListElement.value),
            currency: defaultCurrency,
            period: defaultPeriod,
        })
    }
    return budgets
}

/**
 * translateDateString() returns a translated string from a dateString of the form "YYYY-MM", "YYYY-Q" or "YYYY".
 * Examples:
 * 1) translateDateString("2021-02", BudgetPeriodEnum.MONTHLY) = "February 2021"
 * 2) translateDateString("2021-2", BudgetPeriodEnum.QUARTERLY) = "2nd quarter 2021"
 * 3) translateDateString("2021", BudgetPeriodEnum.YEARLY) = "2021"
 */
export const translateDateString = (dateString: string, budgetPeriod: BudgetPeriodEnum, format: "short" | "long" = "long"): string => {
    const [year, quarterOrMonth] = dateString.split("-").map((e) => Number(e))
    switch (budgetPeriod) {
        case BudgetPeriodEnum.MONTHLY:
            const monthFormat = format === "short" ? "MMM" : "MMMM"
            return moment()
                .set({ month: quarterOrMonth - 1, year })
                .format(`${monthFormat} YYYY`) // set month in moment uses 0 index
        case BudgetPeriodEnum.QUARTERLY:
            const quarterFormat = format === "short" ? `Q[Q]` : `Qo [${`${i18n.t("label:quarter")}`}]`
            return moment().set({ quarter: quarterOrMonth, year }).format(`${quarterFormat} YYYY`)
        case BudgetPeriodEnum.YEARLY:
            return dateString
        default:
            return ""
    }
}

export const getInitialPeriodOption = (date: Moment, budgetPeriod: BudgetPeriodEnum): string => {
    switch (budgetPeriod) {
        case BudgetPeriodEnum.MONTHLY:
            return date.clone().format("YYYY-MM")
        case BudgetPeriodEnum.QUARTERLY:
            return date.clone().format("YYYY-Q")
        case BudgetPeriodEnum.YEARLY:
            return date.clone().format("YYYY")
        default:
            return ""
    }
}

// consumption
const calculateFirstBar = (consumption: number, capacity: number) => (consumption > capacity ? capacity : consumption)

// future expenses or expenses above budget
const calculateSecondBar = (consumption: number, forecast: number, capacity: number) => {
    if (consumption > capacity) return consumption - capacity
    if (consumption + forecast > capacity) return capacity - consumption
    return forecast
}

// future expenses above the budget or the budget left
const calculateThirdBar = (consumption: number, forecast: number, capacity: number) => {
    if (consumption > capacity) return forecast
    if (consumption + forecast > capacity) return forecast - (capacity - consumption)

    const result = capacity - (consumption + forecast)
    return result <= capacity ? result : capacity
}

export const getBarData = (chartType: CostCenterChartTypeEnum, budgets?: Array<any>) => {
    if (!budgets) return []
    const barData = []

    for (const budget of budgets) {
        if (chartType === CostCenterChartTypeEnum.DETAIL_VIEW) {
            barData.push({
                ...budget,
                name: translateDateString(budget.name, budget.period, "short"),
            })
        } else {
            barData.push({
                name: translateDateString(budget.name, budget.period, "short"),
                keys: ["firstBar", "secondBar", "thirdBar"],
                firstBar: calculateFirstBar(budget.consumption, budget.capacity),
                firstBarColor: COLOR.finway.green,
                firstBarLabel: i18n.t("label:spending_within_budget"),
                secondBar: calculateSecondBar(budget.consumption, budget.forecast, budget.capacity),
                secondBarColor: budget.consumption <= budget.capacity ? COLOR.finway["green-light4"] : COLOR.finway.red2,
                secondBarLabel: budget.consumption <= budget.capacity ? i18n.t("label:spending_forecast_within_budget") : i18n.t("label:spending_above_budget"),
                thirdBar: calculateThirdBar(budget.consumption, budget.forecast, budget.capacity),
                thirdBarColor: budget.consumption + budget.forecast <= budget.capacity ? COLOR.gray[500] : COLOR.finway["red-light2"],
                thirdBarLabel: budget.consumption + budget.forecast <= budget.capacity ? i18n.t("label:budget_left") : i18n.t("label:spending_forecast_above_budget"),
            })
        }
    }

    return barData
}

export const calculateGraphMaxValue = (barData: any) => {
    let maxValue = 0
    barData.forEach((bar: any) => {
        const localMax = bar.firstBar + bar.secondBar + bar.thirdBar
        if (localMax > maxValue) maxValue = localMax
    })
    return maxValue
}

export const getBudgetColorBars = (consumption: any, forecast: any, capacity: any, className = ""): Array<ColorBar> => {
    const firstBar = calculateFirstBar(consumption, capacity)
    const secondBar = calculateSecondBar(consumption, forecast, capacity)
    const thirdBar = capacity ? calculateThirdBar(consumption, forecast, capacity) : 1 // "1" to show at least gray bar if there is no budget value assigned to that cost center
    const firstBarColor = COLOR.finway.green
    const secondBarColor = consumption <= capacity ? COLOR.finway["green-light2"] : COLOR.finway.red2
    const thirBarColor = consumption + forecast <= capacity ? COLOR.gray[500] : COLOR.finway["red-light2"]
    return [
        { value: firstBar, color: firstBarColor, className },
        { value: secondBar, color: secondBarColor, className },
        { value: thirdBar, color: thirBarColor, className },
    ]
}

export const getDateRangeObject = (periodRangeOption: PeriodRangeOptionEnum, periodString: moment.unitOfTime.DurationConstructor): { startDate: Moment; endDate: Moment } => {
    switch (periodRangeOption) {
        case PeriodRangeOptionEnum.LAST_12_PERIODS:
            return { startDate: moment().subtract(12, periodString).startOf(periodString), endDate: moment().endOf(periodString) }
        case PeriodRangeOptionEnum.LAST_9_AND_NEXT_3_PERIODS:
            return { startDate: moment().subtract(9, periodString).startOf(periodString), endDate: moment().add(3, periodString).endOf(periodString) }
        case PeriodRangeOptionEnum.LAST_6_AND_NEXT_6_PERIODS:
            return { startDate: moment().subtract(6, periodString).startOf(periodString), endDate: moment().add(6, periodString).endOf(periodString) }
        case PeriodRangeOptionEnum.LAST_6_PERIODS:
            return { startDate: moment().subtract(6, periodString).startOf(periodString), endDate: moment().endOf(periodString) }
        case PeriodRangeOptionEnum.LAST_4_AND_NEXT_2_PERIODS:
            return { startDate: moment().subtract(4, periodString).startOf(periodString), endDate: moment().add(2, periodString).endOf(periodString) }
        case PeriodRangeOptionEnum.LAST_3_AND_NEXT_3_PERIODS:
            return { startDate: moment().subtract(3, periodString).startOf(periodString), endDate: moment().add(3, periodString).endOf(periodString) }
        default:
            return { startDate: moment().startOf(periodString), endDate: moment().endOf(periodString) }
    }
}

export const getBudgetPeriodString = (period: BudgetPeriodEnum) => (period === BudgetPeriodEnum.YEARLY ? "year" : period === BudgetPeriodEnum.QUARTERLY ? "quarter" : "month")

export const getDetailChartKeys = (budgetUsageData: any) => {
    const detailViewChartKeys: Array<string> = []
    budgetUsageData.forEach((budgetUsage: any) => {
        detailViewChartKeys.push(...budgetUsage.detailChartKeys)
    })

    return [...new Set(detailViewChartKeys)]
}
