import { LiquidityScenario } from "@finway-group/shared/lib/models"
import { Form, Modal } from "antd"
import { Store } from "antd/lib/form/interface"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import ScenarioForm from "Components/forms/liquidity/scenario.form"
import { LiquidityService, NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { createScenario, updateScenario } from "Shared/store/actions/liquidity/liquidityActions"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

interface ScenarioModalInterface {
    scenario: LiquidityScenario
    isShowing: boolean
    isNew: boolean
    onCancel: () => void
}

const LiquidityScenarioModal: React.FC<ScenarioModalInterface> = ({ scenario, isShowing, isNew, onCancel }) => {
    const { t } = useTranslation()
    const [formInstance] = Form.useForm()
    const [isLoading, setIsLoading] = useStateIfMounted(false)

    const dispatch = useDispatch()

    useEffect(() => {
        if (isShowing && scenario && !isNew) formInstance.setFieldsValue(scenario)
    }, [isShowing])

    const onSubmit = async (values: Store) => {
        try {
            setIsLoading(true)
            if (isNew) {
                await createScenario(values as LiquidityScenario)(dispatch)
                NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:liquidity.add_scenario.title"), t("notification:liquidity.add_scenario.message"))
            } else {
                await updateScenario(scenario._id, values as LiquidityScenario)(dispatch)
                NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:liquidity.update_scenario.title"), t("notification:liquidity.update_scenario.message"))
            }
            onHide()
            formInstance.resetFields()
        } catch (err) {
            setIsLoading(false)
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:error"))
        }
    }

    const onHide = () => {
        setIsLoading(false)
        formInstance.resetFields()
        onCancel()
    }

    return (
        <>
            <Modal
                title={isNew ? t("label:liquidity.scenario.create") : t("label:liquidity.scenario.edit")}
                visible={isShowing}
                maskClosable={false}
                onOk={formInstance.submit}
                onCancel={onHide}
                okText={t("action:save")}
                cancelText={t("action:cancel")}
                confirmLoading={isLoading}
                keyboard
                destroyOnClose
            >
                <ScenarioForm formInstance={formInstance} onSubmit={onSubmit} isNew={isNew} />
            </Modal>
        </>
    )
}

export default LiquidityScenarioModal
