import { Form, Switch, Tooltip } from "antd"
import { FormInstance } from "antd/lib/form"
import React from "react"
import { useTranslation } from "react-i18next"

import { RightFormElementsEnum, RoleConfigFormElementsEnum } from "./role.form.types"
import { StandardRightFormElementsEnum } from "./standardRight.form"

interface RoleConfigFormInterface {
    formInstance: FormInstance
    readOnly?: boolean
}

const RoleConfigForm = ({ readOnly, formInstance }: RoleConfigFormInterface) => {
    const { t } = useTranslation()

    const globalRights = [
        {
            name: RoleConfigFormElementsEnum.ALL_NO_EDIT,
            title: t("label:rights.global_no_editing_rights.title"),
            tooltip: t("label:rights.global_no_editing_rights.tooltip"),
            onChange: (enabled: boolean) => {
                // if enabled remove all abilities
                if (enabled) {
                    formInstance.setFieldsValue({
                        [RoleConfigFormElementsEnum.REQUEST_CREATE_ON_BEHALF]: false,
                        [RoleConfigFormElementsEnum.SELECTABLE_AS_APPROVER]: false,
                        [RoleConfigFormElementsEnum.INBOX_INVOICE_MANAGE_ALL]: false,
                        [StandardRightFormElementsEnum.CREATE_REQUESTS]: false,
                        [StandardRightFormElementsEnum.UPLOAD_INVOICES]: false,
                        [StandardRightFormElementsEnum.VENDOR_SUGGESTION]: false,
                        [RightFormElementsEnum.REQUEST_ABILITY_APPROVE_TEAM]: false,
                        [RightFormElementsEnum.REQUEST_ABILITY_MANAGE_TEAM]: false,
                        [RightFormElementsEnum.REQUEST_ABILITY_APPROVE]: false,
                        [RightFormElementsEnum.REQUEST_ABILITY_MANAGE]: false,
                        [RightFormElementsEnum.REQUEST_ABILITY_APPROVE_DASHBOARD]: false,
                        [RightFormElementsEnum.CARD_ABILITY_MANAGE_TEAM]: false,
                        [RightFormElementsEnum.CARD_ABILITY_MANAGE]: false,
                        [RightFormElementsEnum.CARD_WALLET_ABILITY_MANAGE]: false,
                        [RightFormElementsEnum.COST_CENTER_1_ABILITY_MANAGE]: false,
                        [RightFormElementsEnum.COST_CENTER_1_ABILITY_OWNER]: false,
                        [RightFormElementsEnum.EXPORT_ABILITY_EXPORT_ALL_FORMATS]: false,
                        [RightFormElementsEnum.REQUEST_ABILITY_FORMAL_REVIEW]: false,
                        [RightFormElementsEnum.LIQUIDITY_ABILITY_MANAGE]: false,
                        [RightFormElementsEnum.PAY_ABILITY_PAY_REQUESTS]: false,
                        [RightFormElementsEnum.COMPANY_FEATURE_SETTINGS_ABILITY_MANAGE]: false,
                        [RightFormElementsEnum.COMPANY_ACCOUNTING_SETTINGS_ABILITY_MANAGE]: false,
                        [RightFormElementsEnum.BANK_ACCOUNT_ABILITY_WRITE]: false,
                        [RightFormElementsEnum.REQUEST_SUBSCRIPTION_TEAM_ABILITY_MANAGE]: false,
                        [RightFormElementsEnum.REQUEST_SUBSCRIPTION_ABILITY_MANAGE]: false,
                        [RightFormElementsEnum.TRANSACTION_ABILITY_MANAGE]: false,
                        [RightFormElementsEnum.USER_ABILITY_APPROVAL_DATA_EDIT_ALL]: false,
                        [RightFormElementsEnum.USER_ABILITY_APPROVAL_DATA_EDIT_TEAM]: false,
                        [RightFormElementsEnum.USER_ABILITY_GLOBAL_DATA_EDIT_ALL]: false,
                        [RightFormElementsEnum.USER_ABILITY_GLOBAL_DATA_EDIT_TEAM]: false,
                        [RightFormElementsEnum.VENDOR_ABILITY_MANAGE]: false,
                        [RightFormElementsEnum.VENDOR_PAYMENT_DATA_ABILITY_EDIT]: false,
                        [RightFormElementsEnum.VENDOR_CONTRACT_ABILITY_EDIT]: false,
                        [RightFormElementsEnum.WORKFLOW_ABILITY_ADJUST]: false,
                        [RightFormElementsEnum.WORKFLOW_ABILITY_MANAGE]: false,
                    })
                } else {
                    formInstance.setFieldsValue({
                        [StandardRightFormElementsEnum.CREATE_REQUESTS]: true,
                        [StandardRightFormElementsEnum.UPLOAD_INVOICES]: true,
                        [StandardRightFormElementsEnum.VENDOR_SUGGESTION]: true,
                    })
                }
            },
        },
        {
            name: RoleConfigFormElementsEnum.ALL_VIEW,
            title: t("label:rights.global_viewing_right.title"),
            tooltip: t("label:rights.global_viewing_right.tooltip"),
            onChange: (enabled: boolean) => {
                // if enabled add all access rights
                if (enabled)
                    formInstance.setFieldsValue({
                        [RoleConfigFormElementsEnum.REQUEST_VIEW_ALL_TEAM]: true,
                        [RoleConfigFormElementsEnum.REQUEST_VIEW_ALL]: true,
                        [RightFormElementsEnum.CARD_ACCESS_TEAM]: true,
                        [RightFormElementsEnum.CARD_ACCESS]: true,
                        [RightFormElementsEnum.CARD_WALLET_ACCESS]: true,
                        [RightFormElementsEnum.COST_CENTER_1_ACCESS]: true,
                        [RightFormElementsEnum.EXPORT_ACCESS]: true,
                        [RightFormElementsEnum.REQUEST_ACCESS_FORMAL_REVIEW]: true,
                        [RightFormElementsEnum.LIQUIDITY_ACCESS]: true,
                        [RightFormElementsEnum.PAY_ACCESS]: true,
                        [RightFormElementsEnum.REPORTING_ACCESS]: true,
                        [RightFormElementsEnum.COMPANY_ACCOUNTING_SETTINGS_ACCESS]: true,
                        [RightFormElementsEnum.COMPANY_FEATURE_SETTINGS_ACCESS]: true,
                        [RightFormElementsEnum.BANK_ACCOUNT_ACCESS]: true,
                        [RightFormElementsEnum.REQUEST_SUBSCRIPTION_TEAM_ACCESS]: true,
                        [RightFormElementsEnum.REQUEST_SUBSCRIPTION_ACCESS]: true,
                        [RightFormElementsEnum.TRANSACTION_ACCESS]: true,
                        [RightFormElementsEnum.USER_ACCESS]: true,
                        [RightFormElementsEnum.USER_ACCESS_TEAM]: true,
                        [RightFormElementsEnum.VENDOR_ACCESS]: true,
                        [RightFormElementsEnum.VENDOR_PAYMENT_DATA_ACCESS]: true,
                        [RightFormElementsEnum.VENDOR_CONTRACT_ACCESS]: true,
                        [RightFormElementsEnum.WORKFLOW_ACCESS]: true,
                    })
            },
        },
        {
            name: RoleConfigFormElementsEnum.INBOX_INVOICE_MANAGE_ALL,
            title: t("label:rights.global_inbox_invoices.title"),
            tooltip: t("label:rights.global_inbox_invoices.tooltip"),
            onChange: (enabled: boolean) => {
                if (enabled) formInstance.setFieldsValue({ [RoleConfigFormElementsEnum.ALL_NO_EDIT]: false })
            },
        },
        {
            name: RoleConfigFormElementsEnum.REQUEST_CREATE_ON_BEHALF,
            title: t("label:rights.global_request_on_others_behalf.title"),
            tooltip: t("label:rights.global_request_on_others_behalf.tooltip"),
            onChange: (enabled: boolean) => {
                if (enabled) formInstance.setFieldsValue({ [RoleConfigFormElementsEnum.ALL_NO_EDIT]: false })
            },
        },
        {
            name: RoleConfigFormElementsEnum.SELECTABLE_AS_APPROVER,
            title: t("label:rights.global_selectable_as_approver.title"),
            onChange: (enabled: boolean) => {
                if (enabled) formInstance.setFieldsValue({ [RoleConfigFormElementsEnum.ALL_NO_EDIT]: false })

                if (!enabled)
                    formInstance.setFieldsValue({
                        [RightFormElementsEnum.REQUEST_ABILITY_APPROVE]: false,
                        [RightFormElementsEnum.REQUEST_ABILITY_APPROVE_TEAM]: false,
                        [RightFormElementsEnum.REQUEST_ABILITY_APPROVE_DASHBOARD]: false,
                    })
            },
        },
    ]

    return (
        <div>
            <ul className="description-list">
                <span className="title font-bold flex items-center">{t("label:roles.global_rights")}</span>
                {globalRights.map((element, index) => (
                    <li key={index} className="flex items-center justify-between h-50">
                        <div className="flex flex-1 items-center w-3/4">
                            <Tooltip title={element.tooltip} className="p-5" getPopupContainer={(triggerNode) => triggerNode}>
                                {element.title}
                            </Tooltip>
                        </div>
                        <div className="title font-normal flex items-center justify-end space-x-10">
                            <Form.Item name={element.name} className="h-6" initialValue={false} valuePropName="checked">
                                <Switch disabled={readOnly} className="mr-10 pb-2" onChange={element.onChange} />
                            </Form.Item>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default RoleConfigForm
