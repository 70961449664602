import { CopyOutlined, PlusCircleOutlined } from "@ant-design/icons"
import { InboxFilter } from "@finway-group/shared/lib/models"
import { Button, Tooltip } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"

import { useCompany } from "Shared/hooks/company.hooks"
import { useInboxInvoices } from "Shared/hooks/expense.hooks"
import { getTableFilterObjectStore } from "Shared/hooks/table.hooks"
import { NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { TablesEnum } from "Shared/store/reducers/tableConfigReducer"
import { copyTextToClipboard } from "Shared/utils/copy.utils"

export interface UploadFileDraggerContentInterface {
    label: {
        buttonLabel: string
        draggerDescription: string
    }
    isUploading?: boolean
    onInboxSelected?: (event: any) => void
    readOnly: boolean
    isCompanyEmailForwardingAddressButtonVisible?: boolean
}

const UploadFileDraggerContent: React.FC<UploadFileDraggerContentInterface> = ({ label, isUploading, onInboxSelected, readOnly, isCompanyEmailForwardingAddressButtonVisible }) => {
    const { t } = useTranslation()
    const inboxInvoiceData = onInboxSelected && useInboxInvoices()
    const filterObject = getTableFilterObjectStore<InboxFilter>(TablesEnum.INBOX_INVOICE)
    // If count > 0 show
    // If count <= 0 AND a filter is set show anyway (because we might filter all invoices out)
    const isInboxInvoiceButtonVisible = onInboxSelected && inboxInvoiceData && (inboxInvoiceData.count > 0 || Object.values(filterObject).find((value) => value !== ""))
    const company = useCompany()

    const handleClick = (event: React.MouseEvent<HTMLInputElement>) => {
        event.stopPropagation()
        copyTextToClipboard(company.emailForwardingAddress)
        NotificationService.send(
            NotificationTypeEnum.SUCCESS,
            t("notification:settings.company.email_forwarding_address.copied.title"),
            <div>
                {t("notification:settings.company.email_forwarding_address.copied.message_1")}
                <b>{company.emailForwardingAddress}</b>
                {t("notification:settings.company.email_forwarding_address.copied.message_2")}
            </div>,
            20,
        )
    }

    return (
        <>
            <i className="el-icon-upload text-lg" />
            <div className="el-upload__text">
                {isInboxInvoiceButtonVisible && (
                    <Button data-testid="addInvoiceInboxExpenseForm" className="mt-10 mr-8" onClick={onInboxSelected} disabled={readOnly}>
                        {t("action:inbox_invoice.inbox")}
                    </Button>
                )}
                <Button data-testid="addInvoiceInbox" loading={isUploading} disabled={readOnly}>
                    <PlusCircleOutlined />
                    {label.buttonLabel}
                </Button>
                {isCompanyEmailForwardingAddressButtonVisible && (
                    <Tooltip title={company.emailForwardingAddress} overlayInnerStyle={{ whiteSpace: "nowrap" }} overlayStyle={{ maxWidth: "800px" }}>
                        <Button className="ml-10 font-medium" onClick={handleClick}>
                            <span className="flex items-center">
                                <CopyOutlined />
                                <span className="font-medium ml-8 leading-snug">{t("label:email_forwarding_address")}</span>
                            </span>
                        </Button>
                    </Tooltip>
                )}
                <br />
                <small>{label.draggerDescription}</small>
            </div>
        </>
    )
}

export default UploadFileDraggerContent
