import { Currencies } from "@finway-group/shared/lib/consts"
import { CurrencyEnum, currencyEnumArray } from "@finway-group/shared/lib/models/common/currency.enum"
import { Col, Form, Select } from "antd"
import Image from "antd/lib/image"
import { OptionProps } from "antd/lib/select"
import React from "react"

import { GetPopupAnchor } from "Shared/utils/popup.utils"

export interface FormSelectCurrencyInterface {
    elem: {
        label: string
        filterOption?: (input: string, option: OptionProps) => boolean
        initialValue?: string
        getPopupContainer: GetPopupAnchor
        onChange: (value: CurrencyEnum) => void
    }
    span?: number
    sm?: number | object
    md?: number | object
}

function FormSelectCurrency({ elem, ...rest }: FormSelectCurrencyInterface) {
    const { label, initialValue, filterOption, getPopupContainer, onChange } = elem

    return (
        <Col {...rest}>
            <Form.Item name="currency" label={label} key="currency" initialValue={initialValue}>
                <Select showSearch filterOption={filterOption} getPopupContainer={getPopupContainer()} onChange={onChange}>
                    {currencyEnumArray.map((currencyValue, index) => (
                        <Select.Option key={index} value={currencyValue} label={currencyValue}>
                            <div className="space-x-10 text-text">
                                <Image className="align-middle" width={20} preview={false} src={`data:image/png;base64,${Currencies[currencyValue].flag}`} />
                                <span>{currencyValue}</span>
                                <span>-</span>
                                <span>{Currencies[currencyValue].name}</span>
                            </div>
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
        </Col>
    )
}

export default React.memo(FormSelectCurrency)
