import React from "react"
import { useTranslation } from "react-i18next"

import DiscountIcon from "Components/icons/discountIcon"

export const AttachDiscountMenuItemContent = () => {
    const { t } = useTranslation()

    return (
        <>
            <div className="flex items-center justify-center rounded-full w-16 h-16">
                <DiscountIcon />
            </div>
            <div className="flex items-center ml-12">
                <p className="leading-none">
                    <span className="text-text">{t("action:request.attach_discount")}</span>
                </p>
            </div>
        </>
    )
}
