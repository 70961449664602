import { CurrencyEnum, KYBStatusEnum } from "@finway-group/shared/lib/models"
import axios from "axios"
import { v4 as uuidv4 } from "uuid"

import { KYBRejectionReasonEnum } from "Shared/store/reducers/corporateReducer"

import { prepareFileUpload } from "./file.service"
import { PaymentProductUI, MODEL as PaymentSettingsModel } from "./paymentSettings.service"
import { TENANT_MODEL } from "./tenant.service"

const MODEL = "weavr"

export enum WebhookMessageTypeEnum {
    KYB = "KYB",
    TRANSACTION_AUTHORIZATION = "TRANSACTION_AUTHORIZATION",
    TRANSACTION_SETTLEMENT = "TRANSACTION_SETTLEMENT",
    DEPOSIT = "DEPOSIT",
    OUTGOING_WIRE_TRANSFER = "OUTGOING_WIRE_TRANSFER",
}

export enum CardAuthorizationEnum {
    AUTHORISED = "AUTHORISED",
    DECLINED = "DECLINED",
    ONLINE_REVERSE = "ONLINE_REVERSE",
    EXPIRED = "EXPIRED",
    MANUAL_CLOSE = "MANUAL_CLOSE",
    AUTHORISED_CREDIT = "AUTHORISED_CREDIT",
    CANCELLED = "CANCELLED",
}

export enum WeavrSettlementTypesEnum {
    SALE_PURCHASE = "SALE_PURCHASE",
    CASH_WITHDRAWAL = "CASH_WITHDRAWAL",
    SALE_WITH_CASHBACK = "SALE_WITH_CASHBACK",
    MAIL_OR_TELEPHONE_ORDER = "MAIL_OR_TELEPHONE_ORDER",
    PURCHASE_REFUND_REVERSAL = "PURCHASE_REFUND_REVERSAL",
    ORIGINAL_CREDIT_TRANSACTION_REVERSAL = "ORIGINAL_CREDIT_TRANSACTION_REVERSAL",
    CASH_WITHDRAWAL_REVERSAL = "CASH_WITHDRAWAL_REVERSAL",
    PURCHASE_REFUND = "PURCHASE_REFUND",
    PURCHASE_REVERSAL = "PURCHASE_REVERSAL",
    ORIGINAL_CREDIT_TRANSACTION = "ORIGINAL_CREDIT_TRANSACTION",
    FIRST_CHARGEBACK = "FIRST_CHARGEBACK",
    FIRST_CHARGEBACK_REVERSAL = "FIRST_CHARGEBACK_REVERSAL",
    FIRST_REPRESENTMENT = "FIRST_REPRESENTMENT",
    FIRST_REPRESENTMENT_REVERSAL = "FIRST_REPRESENTMENT_REVERSAL",
    SECOND_CHARGEBACK = "SECOND_CHARGEBACK",
    SECOND_CHARGEBACK_REVERSAL = "SECOND_CHARGEBACK_REVERSAL",
    SECOND_REPRESENTMENT = "SECOND_REPRESENTMENT",
    ARBITRATION_CHARGEBACK = "ARBITRATION_CHARGEBACK",
    BALANCE_INQUIRY = "BALANCE_INQUIRY",
}

export enum WeavrAuthorizationDeclineReasonEnum {
    PHYSICAL_NOT_ACTIVATED = "PHYSICAL_NOT_ACTIVATED",
    INSUFFICIENT_BALANCE = "INSUFFICIENT_BALANCE",
    CVV_CHECKS_FAILED = "CVV_CHECKS_FAILED",
    CVV_RETRIES_EXCEEDED = "CVV_RETRIES_EXCEEDED",
    CARD_STATUS_NOT_ALLOWED = "CARD_STATUS_NOT_ALLOWED",
    PIN_CHECKS_FAILED = "PIN_CHECKS_FAILED",
    PIN_RETRIES_EXCEEDED = "PIN_RETRIES_EXCEEDED",
    CARD_EXPIRY_CHECKS_FAILED = "CARD_EXPIRY_CHECKS_FAILED",
    AVS_CHECKS_FAILED = "AVS_CHECKS_FAILED",
    ATM_WITHDRAWAL_LIMIT_EXCEEDED = "ATM_WITHDRAWAL_LIMIT_EXCEEDED",
    SCA_REQUIRED = "SCA_REQUIRED",
    AUTH_RULE_CHECKS_FAILED = "AUTH_RULE_CHECKS_FAILED",
    NO_REASON = "NO_REASON",
    TIMEOUT = "TIMEOUT",
}

export enum WeavrWireTransferStateEnum {
    PENDING_CHALLENGE = "PENDING_CHALLENGE",
    SUBMITTED = "SUBMITTED",
    REJECTED = "REJECTED",
    APPROVED = "APPROVED",
    FAILED = " FAILED",
    COMPLETED = "COMPLETED",
}

/**
 * Weavr sends data w/o decimal
 * therefore multiply amount by 100
 *
 * Example: 100 => $1.00
 *
 * @param amount decimal amount
 * @returns unit_amount (weavr format)
 */
const convertDecimalAmountToWeavrUnitAmount = (amount: number) => amount * 100

export interface ResetPaymentSettingsRequest {
    /* TODO: Remove once existing customers are migrated */
    isExistingCustomer: boolean
    countryCode: string
    lineItems: Array<{
        startDate: Date
        productId: string
        termInMonths: number
        discount?: {
            off: number
            currency?: CurrencyEnum
        }
    }>
}

const SimulatorService = {
    createCardAuthorizationTransaction: async (
        corporateId: string | undefined,
        card: any,
        amount: number,
        currency: CurrencyEnum,
        sourceAmount: number,
        sourceCurrency: CurrencyEnum,
        timestamp: Date,
        merchantName: string,
        transactionId: string,
        relatedAuthorisationId: string,
        authorisationType: CardAuthorizationEnum,
        declineReason: WeavrAuthorizationDeclineReasonEnum,
        shouldSimulateOnWeavr: boolean,
    ) => {
        const bodyData = {
            id: { id: card.cardId },
            owner: { id: corporateId },
            transactionId: transactionId ?? uuidv4(),
            transactionAmount: { amount: convertDecimalAmountToWeavrUnitAmount(amount), currency },
            transactionTimestamp: timestamp,
            sourceAmount: { amount: convertDecimalAmountToWeavrUnitAmount(sourceAmount ?? amount), currency: sourceCurrency ?? currency },
            merchantName,
            availableBalanceAmount: convertDecimalAmountToWeavrUnitAmount(card?.balance + amount),
            // note: s not z
            authorisationType,
            relatedAuthorisationId,
            declineReason,
            approved: true,
            isSimulation: true,
            shouldSimulateOnWeavr,
        }

        const { data } = await axios.post(`/${MODEL}/managed_cards/authorisations/watch/simulate`, bodyData)
        return data?.success
    },
    createCardSettlementTransaction: async (
        corporateId: string | undefined,
        card: any,
        amount: number,
        currency: CurrencyEnum,
        sourceAmount: number,
        sourceCurrency: CurrencyEnum,
        timestamp: Date,
        merchantName: string,
        transactionId: string,
        relatedAuthorisationId: string,
        settlementType: WeavrSettlementTypesEnum,
        shouldSimulateOnWeavr: boolean,
    ) => {
        const bodyData = {
            id: { id: card.cardId },
            owner: { id: corporateId },
            messageType: WebhookMessageTypeEnum.TRANSACTION_SETTLEMENT,
            transactionId: transactionId ?? uuidv4(),
            transactionAmount: { amount: convertDecimalAmountToWeavrUnitAmount(amount), currency },
            transactionTimestamp: timestamp,
            sourceAmount: { amount: convertDecimalAmountToWeavrUnitAmount(sourceAmount ?? amount), currency: sourceCurrency ?? currency },
            merchantName,
            availableBalanceAmount: convertDecimalAmountToWeavrUnitAmount(card?.balance + amount),
            relatedAuthorisationId,
            settlementType,
            isSimulation: true,
            shouldSimulateOnWeavr,
        }
        const { data } = await axios.post(`/${MODEL}/managed_cards/settlements/watch/simulate`, bodyData)
        return data?.success
    },
    blockCardPIN: async (cardId: string) => {
        const { data } = await axios.patch(`/cards/${cardId}/pin/simulate/block`)
        return data?.success
    },
    createFundingTransaction: async (corporateId: string | undefined, amount: number, currency: CurrencyEnum, accountId: string, shouldSimulateOnWeavr: boolean) => {
        const bodyData = {
            id: { id: accountId },
            owner: { id: corporateId },
            transactionId: uuidv4(),
            transactionAmount: { amount: convertDecimalAmountToWeavrUnitAmount(amount), currency },
            transactionTimestamp: new Date().getTime(),
            isSimulation: true,
            shouldSimulateOnWeavr,
            state: "COMPLETED",
        }
        const { data } = await axios.post(`/${MODEL}/managed_accounts/deposits/watch/simulate`, bodyData)
        return data?.success
    },
    notifyOutgoingWireTransfer: async (corporateId: string | undefined, state: WeavrWireTransferStateEnum, wireTransferId: string) => {
        // TODO: this needs to be fixed
        // const bodyData = {
        //     transfer: {
        //         id: { id: v4()},
        //         transactionAmount: { convertDecimalAmountToWeavrUnitAmount(amount), currency: CurrencyEnum.EUR },
        //     }
        //     wireTransferId,
        //     state,
        //     publishedTimestamp: timestamp
        // }
        // const { data } = await axios.post(`/${MODEL}/outgoing_wire_transfers/watch/simulate`, bodyData)
        // return data?.success
    },
    acceptKYB: async (corporateId: string | undefined, shouldSimulateOnWeavr: boolean) => {
        const bodyData = {
            corporateId,
            status: KYBStatusEnum.APPROVED,
            isSimulation: true,
            shouldSimulateOnWeavr,
        }

        const { data } = await axios.post(`/${MODEL}/corporates/kyb/watch/simulate`, bodyData)
        return data?.success
    },
    rejectKYB: async (corporateId: string | undefined, reason: KYBRejectionReasonEnum, shouldSimulateOnWeavr: boolean) => {
        const bodyData = {
            corporateId,
            status: KYBStatusEnum.REJECTED,
            details: { FailureReason: reason },
            isSimulation: true,
            shouldSimulateOnWeavr,
        }

        const { data } = await axios.post(`/${MODEL}/corporates/kyb/watch/simulate`, bodyData)
        return data?.success
    },
    uploadByGetMyInvoices: async (email: string, userId: string, file: File) => {
        const { formData, config } = prepareFileUpload(file)
        formData.append("email", email)
        formData.append("userId", userId)
        const { data } = await axios.post(`/files/get-my-invoices/simulate`, formData, config)
        return data
    },
    getAllProducts: async () => {
        const { data } = await axios.get(`${PaymentSettingsModel}/products-all/simulate`)
        return data as Array<PaymentProductUI>
    },
    resetPaymentSettings: async (reqData: ResetPaymentSettingsRequest) => {
        const { data } = await axios.post(`${PaymentSettingsModel}/reset/simulate`, reqData)
        return data?.success
    },
    changeTenantTier: async (reqData: any) => {
        const { data } = await axios.put(`${TENANT_MODEL}/simulate`, reqData)
        return data
    },
    createRandomData: async (reqData: any) => axios.post(`create-random-data`, reqData),
    createQAData: async (reqData: any) => axios.post(`create-qa-data`, reqData),
}

export default SimulatorService
