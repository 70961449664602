import Icon from "@ant-design/icons"
import React from "react"

const alertTriangleSvg = () => (
    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.65202 0.732911L0.204036 11.4973C-0.0664892 11.9658 -0.0681043 12.5427 0.199793 13.0126C0.46769 13.4826 0.964861 13.7752 1.50581 13.7811H14.4018C14.9427 13.7752 15.4399 13.4826 15.7078 13.0126C15.9757 12.5427 15.9741 11.9658 15.7036 11.4973L9.25557 0.732911C8.97956 0.277884 8.48599 0 7.95379 0C7.4216 0 6.92803 0.277884 6.65202 0.732911ZM7 4.44444H9V8.40404H7V4.44444ZM9 9.77778H7V11.9596H9V9.77778Z"
            fill="white"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.65202 0.732911L0.204036 11.4973C-0.0664892 11.9658 -0.0681043 12.5427 0.199793 13.0126C0.46769 13.4826 0.964861 13.7752 1.50581 13.7811H14.4018C14.9427 13.7752 15.4399 13.4826 15.7078 13.0126C15.9757 12.5427 15.9741 11.9658 15.7036 11.4973L9.25557 0.732911C8.97956 0.277884 8.48599 0 7.95379 0C7.4216 0 6.92803 0.277884 6.65202 0.732911ZM7 4.44444H9V8.40404H7V4.44444ZM9 9.77778H7V11.9596H9V9.77778Z"
            fill="#FBAC3B"
        />
    </svg>
)

const AlertTriangleIcon = (props: any) => <Icon component={alertTriangleSvg} {...props} />

export default AlertTriangleIcon
