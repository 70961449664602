import React from "react"

export const LogoutIcon = () => (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1_118926)">
            <path
                d="M10.344 9.15986C10.2955 9.20407 10.2677 9.26673 10.2677 9.33258V10.0326C10.2677 10.1253 10.3226 10.2094 10.4078 10.2465C10.4377 10.2595 10.4696 10.2659 10.501 10.2659C10.5587 10.2659 10.6159 10.2445 10.6599 10.2035L13.9265 7.17015C13.9739 7.12617 14.001 7.06419 14.001 6.99925C14.001 6.93431 13.9739 6.87233 13.9265 6.82835L10.6599 3.79502C10.5917 3.73145 10.4919 3.71481 10.4078 3.75195C10.3226 3.78909 10.2677 3.87318 10.2677 3.96592V4.66592C10.2677 4.73177 10.2955 4.79443 10.344 4.83864L12.2075 6.53258H3.96771C3.83874 6.53258 3.73438 6.63695 3.73438 6.76592V7.23258C3.73438 7.36156 3.83874 7.46592 3.96771 7.46592H12.2075L10.344 9.15986Z"
                fill="#606C7E"
            />
            <path
                d="M0.933333 14H8.4C8.91547 14 9.33333 13.5821 9.33333 13.0667V10.9667C9.33333 10.8378 9.22887 10.7333 9.1 10.7333H8.63333C8.50447 10.7333 8.4 10.8378 8.4 10.9667V13.0667H0.933333V0.933333H8.4V3.03333C8.4 3.1622 8.50447 3.26667 8.63333 3.26667H9.1C9.22887 3.26667 9.33333 3.1622 9.33333 3.03333V0.933333C9.33333 0.417868 8.91547 0 8.4 0H0.933333C0.417868 0 0 0.417868 0 0.933333V13.0667C0 13.5821 0.417868 14 0.933333 14Z"
                fill="#606C7E"
            />
        </g>
        <defs>
            <clipPath id="clip0_1_118926">
                <rect width="14" height="14" fill="white" />
            </clipPath>
        </defs>
    </svg>
)
