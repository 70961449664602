import {
    BillingPeriodEnum,
    CardModeEnum,
    CardTypeEnum,
    CardWindowEnum,
    CostCenter,
    CostCenter1BudgetEnum,
    CostCenter2,
    CostCenterFilter,
    CreditorInterface,
    Employee,
    EmployeeFilter,
    ExpenseAccount,
    ExpenseKindEnum,
    ExpenseTag,
    FilterBaseInterface,
    FilterPresetEnum,
    FormDueInDaysEnum,
    RecommendationEnum,
    Role,
    TabTypeEnum,
    Team,
    TransactionTypeEnum,
    VendorFilter,
    Workflow,
    WorkflowConditionEnum,
} from "@finway-group/shared/lib/models"
import { TransactionStatusEnum } from "@finway-group/shared/lib/models/transaction/transactionStatus.enum"
import { GetEmployeeById } from "@finway-group/shared/lib/models/user/employee.model"
import { SetStateEnum } from "@finway-group/shared/lib/utils"
import { flattenExpression } from "@finway-group/shared/lib/utils/logicalExpression.utils"
import { Store } from "antd/lib/form/interface"
import moment from "moment"

import { FilterSectionOptionsInterface } from "Components/filter/filterTypes"
import { FormSelectOption } from "Components/form/form.types"
import { WorkflowFilter } from "Components/forms/workflowFilter.form"
import { ALL_REIMBURSEMENTS_OPTION, SELECT_ALL_RESOURCES_OPTION, expenseStati } from "Shared/config/consts"
import i18n from "Shared/locales/i18n"
import { TablesEnum } from "Shared/store/reducers/tableConfigReducer"
import { CostCenterWithBudgetData, EmployeeWithExpenseData, VendorWithExpenseData } from "Shared/store/reducers/tableReducer"
import { buildFilterCondition, buildWorkflowTriggerConditionReferences, getRequestAndTriggerTypesOptions } from "Shared/utils/workflow.form.utils"

import { getCostCenter, getCostCenter2, getExpenseAccount, getTeam, getUserRoleName } from "./getter.utils"
import { encodeFilterString, isSet, isString, lowercaseFirstLetter } from "./helper.utils"
import { isVendorRuleEmpty } from "./vendor.utils"

const convertToYesOrNo = (x: string | boolean) => i18n.t(`label:${x == SetStateEnum.SET ? "yes" : "no"}`)

export const getCurrentPresets = (filterObject: any) => (filterObject?.presets as Array<FilterPresetEnum>) ?? []

export const trimFilterObject = (filterObject: any) => {
    if (filterObject.dueInDaysRadio !== undefined) {
        if (filterObject.dueInDaysRadio === FormDueInDaysEnum.DATE_RANGE) {
            delete filterObject.dueInDays
        } else if (filterObject.dueInDaysRadio === FormDueInDaysEnum.DAY_RANGE) {
            delete filterObject.minDueDate
            delete filterObject.maxDueDate
        }

        delete filterObject.dueInDaysRadio
    }

    return filterObject
}

export interface FilterTag {
    name: string
    value: string
    label: string
}

export const createFilterTagsArray = (
    filterObject: any,
    table?: TablesEnum,
    {
        costCenters = [],
        costCenters2 = [],
        employees = [],
        creditors = [],
        teams = [],
        expenseTags = [],
        expenseAccounts = [],
        rolesMap = new Map(),
        activeTab,
        accounts = [],
        connections = [],
    }: FilterSectionOptionsInterface = {},
) => {
    filterObject = trimFilterObject(filterObject)

    const excludedKeys = ["isReimbursement", "presets"]
    const filterObjectKeys = Object.keys(filterObject)

    const filterArray = filterObjectKeys
        .filter((key) => {
            if (excludedKeys.includes(key)) return false
            if (Array.isArray(filterObject[key])) {
                return filterObject[key].length > 0
            }
            return isSet(filterObject[key])
        })
        .map((key: any): FilterTag => {
            const rawValue = filterObject[key]
            let value = rawValue
            let label

            // eslint-disable-next-line default-case
            switch (key) {
                case "kind":
                    if (rawValue === ExpenseKindEnum.SUBSCRIPTION) {
                        value = i18n.t("label:subscription")
                    } else if (rawValue === ExpenseKindEnum.ONE_TIME_EXPENSE) {
                        if (filterObject?.isReimbursement) value = i18n.t("label:standard_reimbursement")
                        else value = i18n.t("label:one_time_purchase")
                    } else if (rawValue === ExpenseKindEnum.MILEAGE) {
                        value = i18n.t("label:mileage_reimbursement")
                    } else if (rawValue === ExpenseKindEnum.HOSPITALITY) {
                        value = i18n.t("label:hospitality_reimbursement")
                    } else if (rawValue === ExpenseKindEnum.PER_DIEM) {
                        value = i18n.t("label:per_diem_reimbursement")
                    } else {
                        value = i18n.t("label:all_reimbursements")
                    }
                    label = i18n.t("label:expense_type")
                    break
                case "minAmount":
                case "minTransactionAmount":
                    label = i18n.t("label:min_amount")
                    break
                case "maxAmount":
                case "maxTransactionAmount":
                    label = i18n.t("label:max_amount")
                    break
                case "minCreditorNumber":
                    label = i18n.t("label:min_creditor_number")
                    break
                case "maxCreditorNumber":
                    label = i18n.t("label:max_creditor_number")
                    break
                case "minLimit":
                    label = i18n.t("label:min_limit")
                    break
                case "maxLimit":
                    label = i18n.t("label:max_limit")
                    break
                case "minBudget":
                    label = i18n.t("label:min_budget")
                    break
                case "maxBudget":
                    label = i18n.t("label:max_budget")
                    break
                case "minCostCenterId":
                    label = i18n.t("label:min_cost_center_id")
                    break
                case "maxCostCenterId":
                    label = i18n.t("label:max_cost_center_id")
                    break
                case "minExpenses":
                    label = i18n.t("label:min_expenses")
                    break
                case "maxExpenses":
                    label = i18n.t("label:max_expenses")
                    break
                case "minDate":
                case "minTransactionDate":
                    value = moment(rawValue).format("ll")
                    label = i18n.t("label:min_date")
                    break
                case "maxDate":
                case "maxTransactionDate":
                    value = moment(rawValue).format("ll")
                    label = i18n.t("label:max_date")
                    break
                case "transactionStatus":
                    Number(rawValue) === TransactionStatusEnum.COMPLETED
                        ? (value = i18n.t("label:matching.transaction_tag.completed"))
                        : Number(rawValue) === TransactionStatusEnum.PENDING
                        ? (value = i18n.t("label:matching.transaction_tag.pending"))
                        : (value = i18n.t("label:matching.transaction_tag.failed"))

                    label = i18n.t("label:transaction_status")
                    break
                case "billingPeriod":
                    Number(rawValue) === BillingPeriodEnum.MONTHLY
                        ? (value = i18n.t("label:monthly"))
                        : Number(rawValue) === BillingPeriodEnum.QUARTERLY
                        ? (value = i18n.t("label:quarterly"))
                        : (value = i18n.t("label:yearly"))

                    label = i18n.t("label:billing_period")
                    break
                case "minInvoiceDate":
                    value = moment(rawValue).format("ll")
                    label = i18n.t("label:min_invoice_date")
                    break
                case "maxInvoiceDate":
                    value = moment(rawValue).format("ll")
                    label = i18n.t("label:max_invoice_date")
                    break
                case "minInvoiceDueDate":
                    value = moment(rawValue).format("ll")
                    label = i18n.t("label:min_invoice_due_date")
                    break
                case "maxInvoiceDueDate":
                    value = moment(rawValue).format("ll")
                    label = i18n.t("label:max_invoice_due_date")
                    break
                case "minDateCancellationDue":
                    value = moment(rawValue).format("ll")
                    label = i18n.t("label:min_date_renewal")
                    break
                case "maxDateCancellationDue":
                    value = moment(rawValue).format("ll")
                    label = i18n.t("label:max_date_renewal")
                    break
                case "status":
                    // both cards and expense has "status" key, so it has to be checked.
                    if (table === TablesEnum.CARDS) {
                        value = i18n.t(`label:cards.tag.${rawValue.toLowerCase()}`)
                    } else {
                        value = i18n.t(expenseStati[value])
                    }
                    label = i18n.t("label:status")
                    break
                case "requestType":
                    value = i18n.t(`label:workflow.request_type.${rawValue.toLowerCase()}`)
                    label = i18n.t("label:workflow.request_type.title")
                    break
                case "budgetStatus":
                    value = i18n.t(`label:budget_detail.${rawValue as CostCenter1BudgetEnum}`)
                    label = i18n.t("label:budget_detail.status")
                    break
                case "triggerType":
                    value = i18n.t(`label:workflow.trigger.${rawValue.toLowerCase()}`)
                    label = i18n.t("label:workflow.trigger.trigger_type")
                    break
                case "costCenter":
                    value = value === SELECT_ALL_RESOURCES_OPTION ? i18n.t("label:all") : getCostCenter(costCenters, rawValue)?.name
                    label = i18n.t("label:cost_center")
                    break
                case "costCenter2":
                    value = value === SELECT_ALL_RESOURCES_OPTION ? i18n.t("label:all") : getCostCenter2(costCenters2, rawValue)?.name
                    label = i18n.t("label:cost_centers_2")
                    break
                case "expenseAccount":
                    value = getExpenseAccount(expenseAccounts, rawValue)?.accountName
                    label = i18n.t("label:expense_account")
                    break
                case "sharedWith":
                    const sharedWith = GetEmployeeById(employees, rawValue)
                    value = `${sharedWith?.firstName} ${sharedWith?.lastName}`
                    label = i18n.t("label:shared_with")
                    break
                case "requestedBy":
                    value = value === SELECT_ALL_RESOURCES_OPTION ? i18n.t("label:all") : getEmployeeFilterTagValue(employees, rawValue)
                    label = i18n.t("label:requested_by")
                    break
                case "user":
                    const user = GetEmployeeById(employees, rawValue)
                    value = `${user?.firstName} ${user?.lastName}`
                    label = i18n.t("label:card_holder")
                    break
                case "approvalNeededBy":
                    value = getEmployeeFilterTagValue(employees, rawValue)
                    label = i18n.t("label:approver")
                    break
                case "vendor":
                    value = value === SELECT_ALL_RESOURCES_OPTION ? i18n.t("label:all") : creditors.find(({ id }) => id === rawValue)?.name
                    label = i18n.t("label:vendor")
                    break
                case "team":
                    value = getTeam(teams, rawValue)?.teamName
                    label = i18n.t("label:team")
                    break
                case "location":
                    label = i18n.t("label:location")
                    break
                case "roleId":
                    const role = rolesMap.get(rawValue)
                    value = getUserRoleName(role)
                    label = i18n.t("label:role")
                    break
                case "transactionType":
                    value = i18n.t(`label:transaction_types.${rawValue.toLowerCase() as TransactionTypeEnum}`)
                    label = i18n.t("label:role")
                    break
                case "accountName":
                    const account = accounts.find(({ accountName }) => accountName === rawValue)
                    const connection = connections.find(({ accounts }) => accounts.find(({ accountName }) => accountName === rawValue))
                    value = `${connection?.bankName} - ${account?.accountName}`
                    label = i18n.t("label:bank_account_filter")
                    break
                case "currency":
                    label = i18n.t("label:currency")
                    break
                case "cardType":
                    value = i18n.t(`label:cards.type.${rawValue.toLowerCase() as CardTypeEnum}`)
                    label = i18n.t("label:card_type")
                    break
                case "vendorRulesSet":
                    value = convertToYesOrNo(rawValue)
                    label = i18n.t("label:vendor_rule")
                    break
                case "vendorRulesExpensePaymentFlowInformation":
                    value = i18n.t(`enum:ExpensePaymentFlowInformationEnum.${rawValue}`)
                    label = i18n.t("label:payment_flow_information.title")
                    break
                case "contractSet":
                    value = convertToYesOrNo(rawValue)
                    label = i18n.t("label:vendor_contract_set")
                    break
                case "paymentDataSet":
                    value = convertToYesOrNo(rawValue)
                    label = i18n.t("label:payment_data_added")
                    break
                case "taxSet":
                    value = convertToYesOrNo(rawValue)
                    label = i18n.t("label:tax_set")
                    break
                case "vatSet":
                    value = convertToYesOrNo(rawValue)
                    label = i18n.t("label:vat_set")
                    break
                case "recommendation":
                    value = i18n.t(`label:recommendations.${rawValue === RecommendationEnum.BLACK_LIST ? "blacklisted" : "fine"}`)
                    label = i18n.t("label:recommendation_set")
                    break
                case "transactionMatched":
                    value = i18n.t(`label:matched_transaction_options.${rawValue === "true" ? "matched" : "not_matched"}`)
                    label = i18n.t("label:transaction_matched")
                    break
                case "subscriptionAttached":
                    value = convertToYesOrNo(rawValue)
                    label = i18n.t("label:subscription_attached")
                    break
                case "cardAttached":
                    value = convertToYesOrNo(rawValue)
                    label = i18n.t("label:card_attached")
                    break
                case "tfaEnabled":
                    value = convertToYesOrNo(rawValue)
                    label = i18n.t("label:tfa.authenticator.title")
                    break
                case "phoneNumberVerified":
                    value = convertToYesOrNo(rawValue)
                    label = i18n.t("label:phoneNumberVerified")
                    break
                case "superiorSet":
                    value = convertToYesOrNo(rawValue)
                    label = i18n.t("label:superior_set")
                    break
                case "cardProgramEnrolled":
                    value = convertToYesOrNo(rawValue)
                    label = i18n.t("label:enrolled_in_card_program")
                    break
                case "paymentDataAdded":
                    value = convertToYesOrNo(rawValue)
                    label = i18n.t("label:payment_data_added")
                    break
                case "window":
                    value = i18n.t(`label:cards.window.${rawValue.toLowerCase() as CardWindowEnum}`)
                    label = i18n.t("label:window")
                    break
                case "mode":
                    value = i18n.t(`label:cards.mode.${rawValue.toLowerCase() as CardModeEnum}`)
                    label = i18n.t("label:cards.mode.mode")
                    break
                case "iban":
                    label = i18n.t("label:iban")
                    break
                case "minDatePurchased":
                    value = moment(rawValue).format("ll")
                    label = i18n.t("label:min_date_purchased")
                    break
                case "maxDatePurchased":
                    value = moment(rawValue).format("ll")
                    label = i18n.t("label:max_date_purchased")
                    break
                case "minDueDate":
                    value = moment(rawValue).format("ll")
                    label = i18n.t("label:min_due_date")
                    break
                case "maxDueDate":
                    value = moment(rawValue).format("ll")
                    label = i18n.t("label:max_due_date")
                    break
                case "dueInDays":
                    value = `${rawValue} days`
                    label = i18n.t("label:due_in_days")
                    break
                case "minPaidAtDate":
                    value = moment(rawValue).format("ll")
                    label = i18n.t("label:min_date_paid_at")
                    break
                case "maxPaidAtDate":
                    value = moment(rawValue).format("ll")
                    label = i18n.t("label:max_date_paid_at")
                    break
                case "minProbability":
                    value = `${value}%`
                    label = i18n.t("label:min_probability")
                    break
                case "maxProbability":
                    value = `${value}%`
                    label = i18n.t("label:max_probability")
                    break
                case "description":
                    label = i18n.t("label:description")
                    break
                case "uploadedBy":
                    const uploadedBy = GetEmployeeById(employees, rawValue)
                    value = `${uploadedBy?.firstName} ${uploadedBy?.lastName}`
                    label = i18n.t("label:uploaded_by")
                    break
                case "responsibleUser":
                    const responsibleUser = GetEmployeeById(employees, rawValue)
                    value = `${responsibleUser?.firstName} ${responsibleUser?.lastName}`
                    label = i18n.t("label:responsible_employee")
                    break
                case "minUploadDate":
                    value = moment(rawValue).format("ll")
                    label = i18n.t("label:min_upload_date")
                    break
                case "maxUploadDate":
                    value = moment(rawValue).format("ll")
                    label = i18n.t("label:max_upload_date")
                    break
                case "vendorName":
                    value = rawValue
                    label = i18n.t("label:vendor_name")
                    break
                case "exportFormat":
                    value = i18n.t(`label:export_format.${rawValue}`)
                    label = i18n.t("label:export_format.title")
                    break
                case "expenseTags":
                    value = expenseTags
                        .filter((expenseTag: ExpenseTag) => rawValue.includes(expenseTag._id))
                        .map((expenseTag) => expenseTag.tagName)
                        .join(", ")
                    label = i18n.t("label:tags")
                    break
                case "billable":
                    label = i18n.t("label:billable")
                    value = rawValue === "true" ? i18n.t("label:yes") : i18n.t("label:no")
                    break
                case "commentsAndMentions":
                    label = i18n.t("label:comments_and_mentions")
                    value = rawValue
                        .map((singleValue: string, index: number) => {
                            if (index !== 0) {
                                return lowercaseFirstLetter(i18n.t(`input:expense_filter.comments_and_mentions.${singleValue}`))
                            }
                            return i18n.t(`input:expense_filter.comments_and_mentions.${singleValue}`)
                        })
                        .join(", ")
                    break
            }

            return { name: key, value, label: label as string }
        })

    if (activeTab && activeTab === TabTypeEnum.ARCHIVE && filterObject.minDate && filterObject.maxDate) {
        const value = `${moment(filterObject.minDate).format("ll")}  -  ${moment(filterObject.maxDate).format("ll")}`
        filterArray.push({ name: "period", value, label: i18n.t("label:period") })
        return filterArray.filter((elem) => !["minDate", "maxDate"].includes(elem.name))
    }

    return filterArray
}

const getEmployeeFilterTagValue = (employees: Array<Employee>, id: string) => {
    const employee = GetEmployeeById(employees, id)
    return `${employee?.firstName} ${employee?.lastName}`
}

export const getFilterQueryString = (filterObject: any, showGross: boolean) => {
    const filterQueryObject = convertFilterObjectIntoQueryFormat(filterObject, showGross)
    const filterQueryString = encodeFilterString(filterQueryObject)
    return filterQueryString
}

export const convertQueryFormatIntoFilterObject = (query: any) => {
    const result: any = {}

    const getEQ = (key: string) => {
        if (query[key] !== undefined && query[key].eq) result[key] = query[key].eq
    }

    const getMin = (objKey: string, formKey: string) => {
        if (query[objKey] && query[objKey].gte) result[formKey] = query[objKey].gte
    }

    const getMax = (objKey: string, formKey: string) => {
        if (query[objKey] && query[objKey].lte) result[formKey] = query[objKey].lte
    }

    const getMinDate = (objKey: string, formKey: string) => {
        if (query[objKey] && query[objKey].gte) result[formKey] = moment(query[objKey].gte).toDate()
    }

    const getMaxDate = (objKey: string, formKey: string) => {
        if (query[objKey] && query[objKey].lte) result[formKey] = moment(query[objKey].lte).toDate()
    }

    const getIn = (key: string) => {
        if (query[key] && query[key].in) result[key] = query[key].in.split(",")
    }

    if (query.kind && query.kind.in) result.kind = query.kind.in

    // numbers
    getMin("totalGrossPrice", "minAmount")
    getMax("totalGrossPrice", "maxAmount")
    getMin("cardLimit", "minLimit")
    getMax("cardLimit", "maxLimit")
    getMin("totalNetPrice", "minAmount")
    getMax("totalNetPrice", "maxAmount")
    getMin("expensesSoFar", "minExpenses")
    getMax("expensesSoFar", "maxExpenses")
    getMin("probability", "minProbability")
    getMax("probability", "maxProbability")
    getMin("transactionData.amount", "minTransactionAmount")
    getMax("transactionData.amount", "maxTransactionAmount")
    getMin("minCreditorNumber", "creditorNumber")
    getMax("maxCreditorNumber", "creditorNumber")
    getMin("minBudget", "budget")
    getMax("maxBudget", "budget")
    getMin("minCostCenterId", "costCenterId")
    getMax("maxCostCenterId", "costCenterId")

    // dates
    getMinDate("datePurchased", "minDate")
    getMaxDate("datePurchased", "maxDate")
    getMinDate("invoiceDate", "minInvoiceDate")
    getMaxDate("invoiceDate", "maxInvoiceDate")
    getMinDate("paidAtDate", "minPaidAtDate")
    getMaxDate("paidAtDate", "maxPaidAtDate")
    getMinDate("dateCancellationDue", "minDateCancellationDue")
    getMaxDate("dateCancellationDue", "maxDateCancellationDue")
    getMinDate("uploadDate", "minUploadDate")
    getMaxDate("uploadDate", "maxUploadDate")
    getMinDate("invoiceDueDate", "minDueDate")
    getMaxDate("invoiceDueDate", "maxDueDate")
    getMinDate("transactionDate", "minTransactionDate")
    getMaxDate("transactionDate", "maxTransactionDate")

    // is equal
    getEQ("costCenter")
    getEQ("costCenter2")
    getEQ("currency")
    getEQ("requestedBy")
    getEQ("approvalNeededBy")
    getEQ("vendor")
    getEQ("cardAttached")
    getEQ("expenseAccount")
    getEQ("status")
    getEQ("user")
    getEQ("billingPeriod")
    getEQ("subscriptionAttached")
    getEQ("cardType")
    getEQ("mode")
    getEQ("window")
    getEQ("exportFormat")
    getEQ("uploadedBy")
    getEQ("phoneNumberVerified")
    getEQ("cardProgramEnrolled")
    getEQ("paymentDataAdded")
    getEQ("superiorSet")
    getEQ("team")
    getEQ("role")
    getEQ("transactionType")
    getEQ("transactionStatus")
    getEQ("transactionMatched")
    getEQ("scope")
    getEQ("triggerType")
    getEQ("vendorRulesSet")
    getEQ("contractSet")
    getEQ("paymentDataSet")
    getEQ("recommendation")
    getEQ("taxSet")
    getEQ("vatSet")
    getEQ("responsibleUser")
    getEQ("budgetStatus")
    getEQ("sharedWith")

    // in array
    getIn("presets")
    getIn("expenseTags")
    getIn("commentsAndMentions")

    // custom
    if (isSet(query.isReimbursement)) result.isReimbursement = query.isReimbursement
    if (query.vendorName && query.vendorName.contains) result.vendorName = query.vendorName.contains
    if (query.kind) result.kind = query.kind?.in ? query.kind.in : query.kind?.eq

    // fixed days
    if (query.dueDate) {
        getMinDate("dueDate", "minDueDate")
        getMaxDate("dueDate", "maxDueDate")
        result.dueInDaysRadio = FormDueInDaysEnum.DATE_RANGE

        // flexible due in days
    } else if (query.dueInDays) {
        getEQ("dueInDays")
        result.dueInDaysRadio = FormDueInDaysEnum.DAY_RANGE
    }

    return result
}

export const convertFilterObjectIntoQueryFormat = (values: Store, useGross: boolean): any => {
    const result: any = {}

    const getMinDate = (objKey: string, formKey: string) => {
        if (values[objKey]) result[formKey] = { ...result[formKey], gte: moment(values[objKey]).toISOString() }
    }

    const getMaxDate = (objKey: string, formKey: string) => {
        if (values[objKey]) result[formKey] = { ...result[formKey], lte: moment(values[objKey]).toISOString() }
    }

    const getMin = (objKey: string, formKey: string) => {
        if (values[objKey]) result[formKey] = { ...result[formKey], gte: values[objKey] }
    }

    const getMax = (objKey: string, formKey: string) => {
        if (values[objKey]) result[formKey] = { ...result[formKey], lte: values[objKey] }
    }

    const getEQ = (key: string, overrideKey?: string) => {
        const value = values[key]
        if (value !== undefined && (typeof value === "number" || (isString(value) && value?.trim() !== ""))) result[overrideKey || key] = { eq: value }
    }

    const getIs = (key: string) => {
        const value = values[key]
        if (value !== undefined && (typeof value === "number" || (isString(value) && value?.trim() !== ""))) result[key] = { is: value }
    }

    const getIn = (key: string) => {
        if (values[key]?.length > 0) result[key] = { in: values[key].join(",") }
    }

    if (values.minAmount) {
        if (useGross) result.totalGrossPrice = { ...result.totalGrossPrice, gte: values.minAmount }
        else result.totalNetPrice = { ...result.totalNetPrice, gte: values.minAmount }
    }

    if (values.maxAmount) {
        result.totalGrossPrice = { ...result.totalGrossPrice, lte: values.maxAmount }
        result.totalNetPrice = { ...result.totalNetPrice, lte: values.maxAmount }
    }

    // numbers
    getMin("minLimit", "cardLimit")
    getMax("maxLimit", "cardLimit")
    getMin("minExpenses", "expensesSoFar")
    getMax("maxExpenses", "expensesSoFar")
    getMin("minProbability", "probability")
    getMax("maxProbability", "probability")
    getMin("minTransactionAmount", "transactionData.amount")
    getMax("maxTransactionAmount", "transactionData.amount")
    getMin("minCreditorNumber", "creditorNumber")
    getMax("maxCreditorNumber", "creditorNumber")
    getMin("minBudget", "budget")
    getMax("maxBudget", "budget")
    getMin("minCostCenterId", "costCenterId")
    getMax("maxCostCenterId", "costCenterId")

    // dates
    getMinDate("minDate", "datePurchased")
    getMaxDate("maxDate", "datePurchased")
    getMinDate("minPaidAtDate", "paidAtDate")
    getMaxDate("maxPaidAtDate", "paidAtDate")
    getMinDate("minDateCancellationDue", "dateCancellationDue")
    getMaxDate("maxDateCancellationDue", "dateCancellationDue")
    getMinDate("minUploadDate", "uploadDate")
    getMaxDate("maxUploadDate", "uploadDate")
    getMinDate("minInvoiceDate", "invoiceDate")
    getMaxDate("maxInvoiceDate", "invoiceDate")
    getMinDate("minDueDate", "invoiceDueDate")
    getMaxDate("maxDueDate", "invoiceDueDate")
    getMinDate("minTransactionDate", "transactionDate")
    getMaxDate("maxTransactionDate", "transactionDate")
    getMinDate("minDatePurchased", "datePurchased")
    getMaxDate("maxDatePurchased", "datePurchased")

    // is equal
    getEQ("cardType")
    getEQ("mode")
    getEQ("subscriptionAttached")
    getEQ("costCenter")
    getEQ("costCenter2")
    getEQ("requestedBy", "requestedBy._id")
    getEQ("expenseAccount", "expenseAccount._id")
    getEQ("approvalNeededBy")
    getEQ("vendor", "vendor._id")
    getEQ("user")
    getEQ("currency")
    getEQ("billingPeriod")
    getEQ("cardAttached")
    getEQ("status")
    getEQ("exportFormat")
    getEQ("uploadedBy")
    getEQ("exportFormat")
    getEQ("phoneNumberVerified")
    getEQ("cardProgramEnrolled")
    getEQ("paymentDataAdded")
    getEQ("superiorSet")
    getEQ("window")
    getEQ("team")
    getEQ("role")
    getEQ("transactionType")
    getEQ("accountName")
    getEQ("transactionStatus")
    getEQ("transactionMatched")
    getEQ("scope")
    getEQ("triggerType")
    getEQ("vendorRulesSet")
    getEQ("contractSet")
    getEQ("paymentDataSet")
    getEQ("recommendation")
    getEQ("taxSet")
    getEQ("vatSet")
    getEQ("responsibleUser")
    getEQ("budgetStatus")
    getEQ("sharedWith")

    getIs("billable")

    // in array
    getIn("expenseTags")
    getIn("commentsAndMentions")
    getIn("presets")

    // custom
    if (values.vendorName) result.vendorName = { contains: values.vendorName }
    if (isSet(values.isReimbursement)) result.isReimbursement = { is: values.isReimbursement }
    if (values.kind) {
        if (Array.isArray(values.kind) && values.kind.length) {
            result.kind = { in: values.kind.join(",") }
        } else {
            result.kind = { eq: values.kind }
        }
    }

    // fixed days
    if (values.dueInDaysRadio === FormDueInDaysEnum.DAY_RANGE || values.dueInDays) {
        // flexible due in days
        getEQ("dueInDays")
    } else if (values?.minDueDate || values?.maxDueDate) {
        getMinDate("minDueDate", "invoiceDueDate")
        getMaxDate("maxDueDate", "invoiceDueDate")
    } else {
        getMinDate("minInvoiceDueDate", "invoiceDueDate")
        getMaxDate("maxInvoiceDueDate", "invoiceDueDate")
    }

    return result
}

export const createDynamicList = (path: string, keys: Array<string>, ignore = false) =>
    keys.map((value: string, index: number) => ({
        param: i18n.t(`${path}${ignore ? "" : "."}${value.toLowerCase()}`),
        value,
        index,
    }))

export const getDynamicLocationList = (locations: Array<string>) =>
    locations.map((location: string, index: number) => ({
        index,
        param: location,
        value: location,
        label: location,
    }))

export const getDynamicCreditorList = (creditors: Array<CreditorInterface> = []) =>
    creditors.map(({ id, name }, index: number) => ({
        index,
        param: name ?? "",
        value: id,
        label: name ?? "",
    }))

export const getDynamicCostCenterList = (costCenters: Array<CostCenter>, showCostCentersIds: boolean = true) =>
    costCenters.map(({ _id, id, name }, index: number) => ({
        index,
        param: showCostCentersIds ? `ID ${id ?? ""} - ${name}` : name,
        value: _id,
        label: showCostCentersIds ? `ID ${id ?? ""} - ${name}` : name,
    }))

export const getDynamicCostCenter2List = (costCenters2: Array<CostCenter2>) =>
    costCenters2.map(({ _id, name, code }, index: number) => ({
        index,
        param: `${name ?? ""} - ${code}`,
        value: _id,
        label: `${name ?? ""} - ${code}`,
    }))

export const getDynamicExpenseAccountList = (expenseAccounts: Array<ExpenseAccount>) =>
    expenseAccounts.map(({ _id, accountName, accountCode }, index: number) => ({
        index,
        param: `${accountCode ?? ""} - ${accountName}`,
        value: _id,
        label: `${accountCode ?? ""} - ${accountName}`,
    }))

export const getDynamicTeamList = (teams: Array<Team>) =>
    teams.map(({ _id, teamName }, index: number) => ({
        index,
        param: teamName,
        value: _id,
        label: teamName,
    }))

export const getDynamicRoleList = (roles: Array<Role>) =>
    roles.map((role, index: number) => {
        const roleName = getUserRoleName(role)
        return {
            index,
            param: roleName,
            value: role._id,
            label: roleName,
        }
    })

export const getCardHolderList = (employees: Array<Employee>) => employees.filter((e: Employee) => e.activeCompanyProfile.weavrData?.userId)

export const createDynamicListWithLabel = (path: string, keys: Array<{ value: string | number; param: string }>, ignorePath = false): Array<FormSelectOption> =>
    keys.map(({ value, param }, index: number) => ({
        param: i18n.t(`${!ignorePath ? `${path}:` : ""}${param}`),
        value,
        index,
    }))

export const getExpenseKindList = (isTravelEnabled: boolean = false) =>
    createDynamicListWithLabel("label", [
        { param: "one_time_purchase", value: ExpenseKindEnum.ONE_TIME_EXPENSE },
        { param: "subscription", value: ExpenseKindEnum.SUBSCRIPTION },
        { param: isTravelEnabled ? "standard_reimbursement" : "reimbursement", value: ExpenseKindEnum.STANDARD },
        ...(isTravelEnabled
            ? [
                  { param: "all_reimbursements", value: ALL_REIMBURSEMENTS_OPTION },
                  { param: "mileage_reimbursement", value: ExpenseKindEnum.MILEAGE },
                  { param: "hospitality_reimbursement", value: ExpenseKindEnum.HOSPITALITY },
                  { param: "per_diem_reimbursement", value: ExpenseKindEnum.PER_DIEM },
              ]
            : []),
    ])

export const createYesOrNoOption = () =>
    createDynamicListWithLabel("label", [
        { param: "yes", value: "true" },
        { param: "no", value: "false" },
    ])

export const formatRemovalFilterOptions = (table: TablesEnum, key: string, filterObject: FilterBaseInterface) => {
    filterObject[key] = Array.isArray(filterObject[key]) ? [] : ""

    let shouldFetch = true

    if (
        getFilterTargetPage(table) === TablesEnum.TODO_INVOICE_APPROVAL ||
        table === TablesEnum.POSSIBLE_MATCHES ||
        table === TablesEnum.ATTACH_EXPENSE ||
        table === TablesEnum.INBOX_INVOICE_MODAL_EXPENSES
    ) {
        if (key === "kind") {
            filterObject.isReimbursement = ""
        }
    }

    if (key === "transactionType") {
        filterObject.accountName = ""
    }

    switch (table) {
        case TablesEnum.WORKFLOWS:
            // when the trigger type is removed also clear vendor/costCenter/requester
            if (key === "triggerType") {
                filterObject.vendor = ""
                filterObject.costCenter = ""
                filterObject.requestedBy = ""
            }
            break
        case TablesEnum.TRANSACTIONS_ARCHIVE:
            // clear all filters when minTransactionDate or maxTransactionDate is unset
            if (key === "minTransactionDate" || key === "maxTransactionDate") {
                filterObject = {}
                shouldFetch = false
            }
            break
        case TablesEnum.ARCHIVE:
            // clear all filters when minDate or maxDate is unset
            if (key === "period") {
                filterObject = {}
                shouldFetch = false
            }
            break
    }

    return { formattedFilterObject: filterObject, shouldFetch }
}

export const dashboardTables = [
    TablesEnum.ALL_REQUESTS,
    TablesEnum.DONE_REQUESTS,
    TablesEnum.IN_PROGRESS,
    TablesEnum.PAY_AND_EXPORT_TO_BE_EXPORTED,
    TablesEnum.PAY_AND_EXPORT_TO_BE_PAID,
    TablesEnum.REVIEW_REQUESTS,
    TablesEnum.TODO_APPROVAL_PENDING,
    TablesEnum.TODO_DOCS_NEEDED,
    TablesEnum.TODO_INVOICE_APPROVAL,
    TablesEnum.TODO_INVOICE_AND_PURCHASE,
    TablesEnum.TODO_PURCHASE_APPROVAL,
    TablesEnum.TRIP_FOLDER_DRAFT,
]

/*
    IF table is inside the dashboard AND is not the invoice inbox or archive THEN return TODO_INVOICE_APPROVAL else tableEnum
    Reason: the dashboard shares the same report (excl. invoice inbox & archive); it's saved in TODO_INVOICE_APPROVAL redux
*/
export const getFilterTargetPage = (table: TablesEnum): TablesEnum => (dashboardTables.includes(table) ? TablesEnum.TODO_INVOICE_APPROVAL : table)

export const getFilteredEmployees = (filterObj: EmployeeFilter, employees: Array<EmployeeWithExpenseData>) => {
    const filterConditions: Array<object> = []

    if (filterObj?.roleId) filterConditions.push((employee: EmployeeWithExpenseData) => employee.activeCompanyProfile.roleId === filterObj.roleId)
    if (filterObj?.minExpenses)
        filterConditions.push((employee: EmployeeWithExpenseData) => employee.expenseData && employee.expenseData.value >= parseFloat(String(filterObj.minExpenses)))
    if (filterObj?.maxExpenses)
        filterConditions.push((employee: EmployeeWithExpenseData) => employee.expenseData && employee.expenseData.value <= parseFloat(String(filterObj.maxExpenses)))
    if (filterObj?.team) filterConditions.push((employee: EmployeeWithExpenseData) => employee.activeCompanyProfile.team === filterObj.team)
    if (filterObj?.location) filterConditions.push((employee: EmployeeWithExpenseData) => employee.location === filterObj.location)
    if (filterObj?.cardProgramEnrolled === SetStateEnum.SET) filterConditions.push((employee: EmployeeWithExpenseData) => employee.activeCompanyProfile.weavrData?.userId)
    if (filterObj?.cardProgramEnrolled === SetStateEnum.NOT_SET) filterConditions.push((employee: EmployeeWithExpenseData) => !employee.activeCompanyProfile.weavrData?.userId)
    if (filterObj?.superiorSet === SetStateEnum.SET) filterConditions.push((employee: EmployeeWithExpenseData) => employee.activeCompanyProfile.superior)
    if (filterObj?.superiorSet === SetStateEnum.NOT_SET) filterConditions.push((employee: EmployeeWithExpenseData) => !employee.activeCompanyProfile.superior)
    if (filterObj?.phoneNumberVerified === SetStateEnum.SET) filterConditions.push((employee: EmployeeWithExpenseData) => employee.settings?.security?.workPhoneVerified)
    if (filterObj?.phoneNumberVerified === SetStateEnum.NOT_SET) filterConditions.push((employee: EmployeeWithExpenseData) => !employee.settings?.security?.workPhoneVerified)
    if (filterObj?.tfaEnabled === SetStateEnum.SET) filterConditions.push((employee: EmployeeWithExpenseData) => employee.settings?.security?.tfaEnabled)
    if (filterObj?.tfaEnabled === SetStateEnum.NOT_SET) filterConditions.push((employee: EmployeeWithExpenseData) => !employee.settings?.security?.tfaEnabled)

    return employees.filter((employee) => filterConditions.every((condition: any) => condition(employee)))
}

export const getFilteredCostCenter1 = (filterObj: CostCenterFilter, costCenters: Array<CostCenterWithBudgetData>) => {
    const filterConditions: Array<object> = []

    if (filterObj?.minBudget)
        filterConditions.push((costCenter: CostCenterWithBudgetData) => costCenter.budgetValue && costCenter.budgetValue >= parseFloat(String(filterObj.minBudget)))
    if (filterObj?.maxBudget)
        filterConditions.push((costCenter: CostCenterWithBudgetData) => costCenter.budgetValue && costCenter.budgetValue <= parseFloat(String(filterObj.maxBudget)))
    if (filterObj?.responsibleUser) filterConditions.push((costCenter: CostCenter) => costCenter.responsibleUser === filterObj.responsibleUser)
    if (filterObj?.sharedWith) filterConditions.push((costCenter: CostCenter) => costCenter.sharedUsers?.includes(filterObj.sharedWith ?? ""))
    if (filterObj?.minCostCenterId) filterConditions.push((costCenter: CostCenterWithBudgetData) => parseInt(costCenter.id) >= parseInt(String(filterObj.minCostCenterId)))
    if (filterObj?.maxCostCenterId) filterConditions.push((costCenter: CostCenterWithBudgetData) => parseInt(costCenter.id) <= parseInt(String(filterObj.maxCostCenterId)))
    if (filterObj?.budgetStatus && filterObj?.budgetStatus === CostCenter1BudgetEnum.ABOVE_BUDGET)
        filterConditions.push((costCenter: CostCenterWithBudgetData) => costCenter.budgetData && costCenter.budgetData.consumption > costCenter.budgetData.forecast)
    if (filterObj?.budgetStatus && filterObj?.budgetStatus === CostCenter1BudgetEnum.WITHIN_BUDGET)
        filterConditions.push((costCenter: CostCenterWithBudgetData) => costCenter.budgetData && costCenter.budgetData.consumption <= costCenter.budgetData.forecast)
    if (filterObj?.currency) filterConditions.push((costCenter: CostCenterWithBudgetData) => costCenter.budgetData && costCenter.budgetCurrency === filterObj.currency)

    return costCenters.filter((costCenters) => filterConditions.every((condition: any) => condition(costCenters)))
}

export const getFilteredVendors = (filterObj: VendorFilter, vendors: Array<VendorWithExpenseData>) => {
    const filterConditions: Array<object> = []

    if (filterObj.minExpenses) filterConditions.push((vendor: VendorWithExpenseData) => vendor.expenseData && vendor.expenseData.value >= parseFloat(String(filterObj.minExpenses)))
    if (filterObj.maxExpenses) filterConditions.push((vendor: VendorWithExpenseData) => vendor.expenseData && vendor.expenseData.value <= parseFloat(String(filterObj.maxExpenses)))
    if (filterObj.minCreditorNumber) filterConditions.push((vendor: VendorWithExpenseData) => vendor.creditorNumber >= parseFloat(String(filterObj.minCreditorNumber)))
    if (filterObj.maxCreditorNumber) filterConditions.push((vendor: VendorWithExpenseData) => vendor.creditorNumber <= parseFloat(String(filterObj.maxCreditorNumber)))
    if (filterObj?.vendorRulesSet === SetStateEnum.SET) filterConditions.push((vendor: VendorWithExpenseData) => !isVendorRuleEmpty(vendor.rule))
    if (filterObj?.vendorRulesSet === SetStateEnum.NOT_SET) filterConditions.push((vendor: VendorWithExpenseData) => isVendorRuleEmpty(vendor.rule))
    if (filterObj?.vendorRulesExpensePaymentFlowInformation)
        filterConditions.push((vendor: VendorWithExpenseData) => vendor.rule?.expensePaymentFlowInformation === filterObj.vendorRulesExpensePaymentFlowInformation)
    if (filterObj?.contractSet === SetStateEnum.SET) filterConditions.push((vendor: VendorWithExpenseData) => vendor.contracts.length > 0)
    if (filterObj?.contractSet === SetStateEnum.NOT_SET) filterConditions.push((vendor: VendorWithExpenseData) => vendor.contracts.length === 0)
    if (filterObj?.taxSet === SetStateEnum.SET) filterConditions.push((vendor: VendorWithExpenseData) => vendor.taxNumber)
    if (filterObj?.taxSet === SetStateEnum.NOT_SET) filterConditions.push((vendor: VendorWithExpenseData) => !vendor.taxNumber)
    if (filterObj?.vatSet === SetStateEnum.SET) filterConditions.push((vendor: VendorWithExpenseData) => vendor.vatNumber)
    if (filterObj?.vatSet === SetStateEnum.NOT_SET) filterConditions.push((vendor: VendorWithExpenseData) => !vendor.vatNumber)
    if (filterObj?.paymentDataSet === SetStateEnum.SET) filterConditions.push((vendor: VendorWithExpenseData) => vendor?.iban && vendor?.bic)
    if (filterObj?.paymentDataSet === SetStateEnum.NOT_SET) filterConditions.push((vendor: VendorWithExpenseData) => !vendor?.iban && !vendor?.bic)
    if (filterObj?.recommendation) filterConditions.push((vendor: VendorWithExpenseData) => vendor.recommendation === parseInt(filterObj.recommendation))

    return vendors.filter((vendors) => filterConditions.every((condition: any) => condition(vendors)))
}

export const getFilteredWorkflows = (filterObj: WorkflowFilter, workflows: Array<Workflow>, isTravelEnabled: boolean) => {
    const filterConditions: Array<object> = []
    if (filterObj?.requestType) {
        filterConditions.push((workflow: Workflow) => {
            if (!workflow.triggerConditionExpression) return true
            const { requestTypes } = getRequestAndTriggerTypesOptions(workflow, isTravelEnabled)

            return requestTypes.includes(filterObj.requestType!)
        })
    }

    if (filterObj?.triggerType)
        filterConditions.push((workflow: Workflow) => {
            const triggerConditions = workflow.triggerConditionExpression ? flattenExpression(workflow.triggerConditionExpression) : []
            const conditions = triggerConditions.map((triggerCondition) => triggerCondition.condition)
            return conditions.includes(filterObj.triggerType!)
        })
    if (filterObj?.costCenter)
        filterConditions.push((workflow: Workflow, triggerConditionReferences: Array<string>) =>
            buildFilterCondition({
                workflow,
                triggerConditionReferences,
                workflowConditionEnum: WorkflowConditionEnum.COST_CENTER,
                filterValue: filterObj?.costCenter,
            }),
        )
    if (filterObj?.costCenter2)
        filterConditions.push((workflow: Workflow, triggerConditionReferences: Array<string>) =>
            buildFilterCondition({
                workflow,
                triggerConditionReferences,
                workflowConditionEnum: WorkflowConditionEnum.COST_CENTER_2,
                filterValue: filterObj?.costCenter2,
            }),
        )
    if (filterObj?.vendor)
        filterConditions.push((workflow: Workflow, triggerConditionReferences: Array<string>) =>
            buildFilterCondition({ workflow, triggerConditionReferences, workflowConditionEnum: WorkflowConditionEnum.VENDOR, filterValue: filterObj?.vendor }),
        )
    if (filterObj?.requestedBy)
        filterConditions.push((workflow: Workflow, triggerConditionReferences: Array<string>) =>
            buildFilterCondition({
                workflow,
                triggerConditionReferences,
                workflowConditionEnum: WorkflowConditionEnum.REQUESTER,
                filterValue: filterObj?.requestedBy,
            }),
        )

    const filteredWorkflows = workflows.filter((workflow) => {
        const references = buildWorkflowTriggerConditionReferences(workflow)
        const condition = filterConditions.every((condition: any) => condition(workflow, references))

        return condition
    })

    return filteredWorkflows
}

export const mapExpenseFilterKindOptionsToExpenseKind = (option: ExpenseKindEnum) => {
    const mapObj: any = {
        [ExpenseKindEnum.ONE_TIME_EXPENSE]: ExpenseKindEnum.ONE_TIME_EXPENSE,
        [ExpenseKindEnum.SUBSCRIPTION]: ExpenseKindEnum.SUBSCRIPTION,
        [ExpenseKindEnum.REIMBURSEMENT]: [ExpenseKindEnum.ONE_TIME_EXPENSE, ExpenseKindEnum.MILEAGE, ExpenseKindEnum.HOSPITALITY, ExpenseKindEnum.PER_DIEM],
        [ExpenseKindEnum.STANDARD]: ExpenseKindEnum.ONE_TIME_EXPENSE,
        [ExpenseKindEnum.MILEAGE]: ExpenseKindEnum.MILEAGE,
        [ExpenseKindEnum.HOSPITALITY]: ExpenseKindEnum.HOSPITALITY,
        [ExpenseKindEnum.PER_DIEM]: ExpenseKindEnum.PER_DIEM,
    }
    return mapObj[option]
}

export const mapExpenseKindToFilterKindOptions = (filterObjectKind: string | Array<string>, isReimbursement: boolean) => {
    if (filterObjectKind === ExpenseKindEnum.ONE_TIME_EXPENSE) {
        if (isReimbursement) {
            return ExpenseKindEnum.STANDARD
        }
        return ExpenseKindEnum.ONE_TIME_EXPENSE
    }
    if (filterObjectKind === ExpenseKindEnum.SUBSCRIPTION) {
        return ExpenseKindEnum.SUBSCRIPTION
    }
    if (filterObjectKind === ExpenseKindEnum.RECURRING_SUBSCRIPTION) {
        return ExpenseKindEnum.SUBSCRIPTION
    }
    if (filterObjectKind === ExpenseKindEnum.MILEAGE) {
        return ExpenseKindEnum.MILEAGE
    }
    if (filterObjectKind === ExpenseKindEnum.HOSPITALITY) {
        return ExpenseKindEnum.HOSPITALITY
    }
    if (filterObjectKind === ExpenseKindEnum.PER_DIEM) {
        return ExpenseKindEnum.PER_DIEM
    }
    if (Array.isArray(filterObjectKind)) {
        return ExpenseKindEnum.REIMBURSEMENT
    }
    return ""
}

export const showSearchButton = (dataCount: number, searchString: string) => !(!dataCount && !searchString?.trim())

/** handles start and end of day filter dates */
export const adjustTimeRangeOfDateFields = (values: Store) => {
    const minDateFields = ["minDate", "minInvoiceDate", "minTransactionDate", "minDateCancellationDue", "minPaidAtDate", "minInvoiceDueDate", "minUploadDate"]
    const maxDateFields = ["maxDate", "maxInvoiceDate", "maxTransactionDate", "maxDateCancellationDue", "maxPaidAtDate", "maxInvoiceDueDate", "maxUploadDate"]

    minDateFields.forEach((field) => {
        if (values[field]) {
            values[field] = values[field].startOf("day")
        }
    })
    maxDateFields.forEach((field) => {
        if (values[field]) {
            values[field] = values[field].endOf("day")
        }
    })
}
