import { DocumentChangeInterface, OperationTypeEnum } from "@finway-group/shared/lib/models"
import * as Sentry from "@sentry/react"

import { fetchAllExpenseAccounts } from "Shared/store/actions/expenseAccount/expenseAccountActions"
import { isClientErrorCode } from "Shared/utils/errorHandler.utils"

const subscribeToExpenseAccount = (dispatch: any, data: DocumentChangeInterface) => {
    const {
        fullDocument: { operationType },
    } = data

    try {
        if (
            operationType === OperationTypeEnum.INSERT ||
            operationType === OperationTypeEnum.UPDATE ||
            operationType === OperationTypeEnum.DELETE ||
            operationType === OperationTypeEnum.BULK_UPDATE
        ) {
            dispatch(fetchAllExpenseAccounts())
        }
    } catch (err) {
        if (isClientErrorCode(err)) return
        Sentry.captureException(`[Expense Account Socket] Error: ${typeof err === "string" ? err : JSON.stringify(err)}`)
    }
}

export default subscribeToExpenseAccount
