import { CreditorInterface, Employee, Vendor, VendorTypeEnum } from "@finway-group/shared/lib/models"
import { Reducer } from "redux"
import createTransform from "redux-persist/es/createTransform"

import { createCreditorObject } from "Shared/utils/vendor.utils"

import { EmployeeActionTypes, EmployeeActions } from "../actions/employee/employeeTypes"
import { VendorActionTypes, VendorActions } from "../actions/vendor/vendorTypes"

export interface CreditorState {
    items: Map<string, CreditorInterface>
}

interface PersistCreditorState {
    items: Array<CreditorInterface>
}

const initialState = {
    items: new Map<string, CreditorInterface>(),
}

type Creditor = CreditorInterface["source"]

const updateAllCreditors = (state: CreditorState, creditors: Array<Creditor>, vendorType: VendorTypeEnum): CreditorState => {
    // flush old creditors of given type
    state.items.forEach((e) => {
        if (e.type === vendorType) state.items.delete(e.id)
    })
    // add new creditors of given type
    creditors.forEach((e) => state.items.set(e.id, createCreditorObject(e)))
    return {
        ...state,
    }
}

const setCreditor = (state: CreditorState, creditor: Creditor): CreditorState => {
    state.items.set(creditor.id, createCreditorObject(creditor))
    return {
        ...state,
    }
}

const deleteCreditor = (state: CreditorState, creditorId: string): CreditorState => {
    state.items.delete(creditorId)
    return {
        ...state,
    }
}

export const creditorReducer: Reducer<CreditorState, EmployeeActions | VendorActions> = (state = initialState, action) => {
    switch (action.type) {
        case EmployeeActionTypes.FETCH_ALL_EMPLOYEES:
            return updateAllCreditors(state, action.employees, VendorTypeEnum.USER)
        case VendorActionTypes.FETCH_ALL_VENDORS:
            return updateAllCreditors(state, action.vendors, VendorTypeEnum.VENDOR)

        case EmployeeActionTypes.FETCH_ONE_EMPLOYEE:
            return setCreditor(state, action.employee)
        case VendorActionTypes.FETCH_ONE_VENDOR:
            return setCreditor(state, action.vendor)

        case EmployeeActionTypes.SYNC_EMPLOYEE:
            return setCreditor(state, new Employee(action.employee))
        case VendorActionTypes.SYNC_VENDOR:
            return setCreditor(state, action.vendor)

        case EmployeeActionTypes.NEW_USER:
            return setCreditor(state, action.employee)
        case VendorActionTypes.NEW_VENDOR:
            return setCreditor(state, action.vendor)

        case EmployeeActionTypes.UPDATE_EMPLOYEE:
            return setCreditor(state, action.employee)
        case VendorActionTypes.UPDATE_VENDOR:
            return setCreditor(state, action.vendor)
        case VendorActionTypes.DELETE_VENDOR:
            return deleteCreditor(state, action.vendorId)

        default:
            return state
    }
}

/**
 * Transform is not actually storing anything because local storage is not big enough (exceeded the quota)
 *
 * Therefore we persist an empty array and instead re-build the data from vendors and employees
 */
export const creditorsTransform = createTransform<CreditorState, PersistCreditorState>(
    (inboundState) => ({ ...inboundState, items: [] }),
    (outboundState, _key, rawState) => {
        const items = new Map<string, CreditorInterface>()

        try {
            if (rawState.vendors) {
                const vendors = JSON.parse(rawState.vendors)
                if (vendors?.items?.length)
                    vendors.items.forEach((e: any) => {
                        const vendor = createCreditorObject(new Vendor(e))
                        items.set(vendor.id, vendor)
                    })
            }
            if (rawState.employees) {
                const employees = JSON.parse(rawState.employees)
                if (employees?.items?.length)
                    employees.items.forEach((e: any) => {
                        const employee = createCreditorObject(new Employee(e))
                        items.set(employee.id, employee)
                    })
            }
        } catch (err) {
            // logout ?
        }

        return { ...outboundState, items }
    },
    { whitelist: ["creditors"] },
)
