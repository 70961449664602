import { DocumentChangeInterface, OperationTypeEnum } from "@finway-group/shared/lib/models"
import * as Sentry from "@sentry/react"

import { fetchKYBProgress } from "Shared/store/actions/corporate/corporateActions"
import { isClientErrorCode } from "Shared/utils/errorHandler.utils"

const subscribeToCorporate = (dispatch: any, _data: DocumentChangeInterface) => {
    try {
        if (OperationTypeEnum.UPDATE) {
            dispatch(fetchKYBProgress())
        }
    } catch (err) {
        if (isClientErrorCode(err)) return
        Sentry.captureException(`[Corporate Socket] Error: ${typeof err === "string" ? err : JSON.stringify(err)}`)
    }
}

export default subscribeToCorporate
