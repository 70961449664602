import { OcrResult } from "@finway-group/shared/lib/models"
import { ExpensePaymentOptionEnum } from "@finway-group/shared/lib/models/expense/expensePaymentOption.enum"
import { LabeledValue } from "antd/lib/select"
import axios from "axios"

import ConfirmOCRChangesModal from "Components/modals/confirmOCRChanges.modal"
import { ExpenseService, NotificationService } from "Shared/services"
import { isHospitalityExpense } from "Shared/utils/expense.utils"
import {
    compareFormOcrData,
    formatOCRObject,
    formatOcrObjectForAutoGeneratedCardRequests,
    mergeExistingAndOcrItems,
    updateOcrItems,
    updatingAttendees,
} from "Shared/utils/ocr.utils"
import { BuildOcrUpdateDataParams, RunOcrParams, SplitDataType, UpdatedOcrObject } from "Shared/utils/ocr.utils.types"
import { checkVendorSimilarity } from "Shared/utils/vendor.utils"

const OcrService = {
    uploadAndRetrieve: async (requestData: { filePath: string; isHospitality: boolean; ocrItemizationEnabled: boolean; isOcrRetrigger: boolean; meta?: object }) => {
        const { data } = await axios.post("ocr", requestData)
        return data.ocrObject as OcrResult
    },
    runOcr: async ({
        invoiceUrl,
        expense,
        setIsOcrUploading,
        vendors,
        t,
        noDataDifferenceFunction,
        setUpdatedObject,
        showModal,
        language,
        isOcrItemizationEnabled,
        meta,
        showOcrDifferencesModal = true,
        isOcrRetrigger = false,
    }: RunOcrParams) => {
        try {
            setIsOcrUploading(true)

            const isHospitality = isHospitalityExpense(expense)
            const ocrItemizationEnabled = isOcrItemizationEnabled || isHospitality

            const ocrObject = await OcrService.uploadAndRetrieve({ filePath: invoiceUrl, isHospitality, ocrItemizationEnabled, isOcrRetrigger, meta })

            const ocrObjectWithFormattedTaxRates = OcrService.formatOcrObjectTaxRate(ocrObject)

            if (!isOcrRetrigger && ocrObjectWithFormattedTaxRates.invoiceNumber) {
                if (!(await ExpenseService.checkForDuplicate(expense, ocrObjectWithFormattedTaxRates.invoiceNumber))) delete ocrObjectWithFormattedTaxRates.invoiceNumber
            }

            const formOCRDataDifferences = compareFormOcrData({ expense, ocrObject: ocrObjectWithFormattedTaxRates, vendors, t }, language)

            const shouldNotShowDifferencesModal = !formOCRDataDifferences || !showOcrDifferencesModal

            if (shouldNotShowDifferencesModal) {
                const ocrObjectWithUpdatedSplits = ocrObjectWithFormattedTaxRates.splits
                    ? { ...ocrObjectWithFormattedTaxRates, splits: mergeExistingAndOcrItems(expense.splits, ocrObjectWithFormattedTaxRates.splits) }
                    : { ...ocrObjectWithFormattedTaxRates }
                return noDataDifferenceFunction(ocrObjectWithUpdatedSplits)
            }

            showModal(ConfirmOCRChangesModal, true, {
                isShowing: true,
                expense,
                ocrObject: ocrObjectWithFormattedTaxRates,
                formOCRDataDifferences,
                onClose: () => {},
                setUpdatedObject,
            })
        } catch (err) {
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:ocr.title"))
        } finally {
            setIsOcrUploading(false)
        }
    },
    formatOcrObjectTaxRate: (ocrObject: OcrResult) => {
        const newInvoiceTaxRate = ExpenseService.getExpenseTaxRate(ocrObject.taxRate)

        let newSplits
        if (ocrObject.splits) {
            newSplits = [...ocrObject.splits]
            newSplits.forEach((split) => {
                split.taxRate = ExpenseService.getExpenseTaxRate(split.taxRate)
            })
        }

        return {
            ...ocrObject,
            taxRate: newInvoiceTaxRate,
            ...(newSplits && { splits: newSplits }),
        }
    },
    buildOcrUpdateData: ({ ocrObject, existingData, vendors, taxes, t, ocrItemizationEnabled, isHospitality, requester }: BuildOcrUpdateDataParams): UpdatedOcrObject => {
        const ocrObjectWithFormattedTaxes = OcrService.formatOcrObjectTaxRate(ocrObject)

        const { vendor } = ocrObject

        const foundVendor = vendor?.name && checkVendorSimilarity(vendors, vendor.name)

        const attendees: Array<LabeledValue> | undefined = isHospitality ? updatingAttendees(existingData, requester) : undefined

        const newOcrObject = {
            ...ocrObjectWithFormattedTaxes,
            ...(isHospitality && attendees && { attendees }),
            ...(foundVendor && { vendor: { ...ocrObject.vendor, _id: foundVendor.id } }),
        }

        if (!foundVendor) {
            delete newOcrObject.vendor
        }

        const isAutoGeneratedCardRequest = existingData.isAutoGenerated && existingData.paymentOption === ExpensePaymentOptionEnum.SMART_CARD

        if (isAutoGeneratedCardRequest) {
            return formatOcrObjectForAutoGeneratedCardRequests(newOcrObject)
        }

        const updatedOcrItems: SplitDataType =
            ocrObject.splits && ocrItemizationEnabled
                ? updateOcrItems({
                      ocrItemizationEnabled,
                      ocrObject: newOcrObject,
                      expense: existingData,
                      isHospitality,
                  })
                : {
                      splits: existingData.splits,
                  }

        const ocrObjectWithUpdatedSplits = { ...newOcrObject, ...(updatedOcrItems && { ...updatedOcrItems }) }

        const ocrObjectWithUpdatedTaxRate = {
            ...ocrObjectWithUpdatedSplits,
        }

        return formatOCRObject(ocrObjectWithUpdatedTaxRate)
    },
}

export default OcrService
