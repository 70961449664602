import { COLOR } from "@finway-group/shared/lib/consts"
import { PriceIntervalEnum } from "@finway-group/shared/lib/models"
import { Button, Col, Row } from "antd"
import * as HttpStatus from "http-status"
import moment from "moment"
import React, { memo, useEffect } from "react"
import { ArrowLeftCircle, ArrowRightCircle } from "react-feather"
import { useTranslation } from "react-i18next"

import BudgetUsageProgressBar from "Components/budgetUsageProgressBar"
import Icon from "Components/icon"
import { useCostCenterById } from "Shared/hooks/costCenter.hooks"
import { AnalyticsService, NotificationService } from "Shared/services"
import { hasMoreThan1Element } from "Shared/utils/array.utils"
import { getBudgetPeriodString } from "Shared/utils/costCenter.utils"
import { isNotSet } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

interface ExpenseCostCenterBudgetUsageProgressBarInterface {
    data: Array<string>
    className?: string
}

interface budgetUsageData {
    costCenter: string
    consumption: number
    forecast: number
    capacity: number
}

const ExpenseCostCenterBudgetUsageProgressBar: React.FC<ExpenseCostCenterBudgetUsageProgressBarInterface> = ({ data, className }) => {
    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useStateIfMounted(false)
    const [index, setIndex] = useStateIfMounted(0)
    const [currentCostCenterIndex, setCurrentCostCenterIndex] = useStateIfMounted(data[index])
    const currentCostCenter = useCostCenterById(currentCostCenterIndex)
    const [budgetUsage, setBudgetUsage] = useStateIfMounted<budgetUsageData>({ costCenter: "", consumption: 0, forecast: 0, capacity: 0 })
    const [budgetUsages, setBudgetUsages] = useStateIfMounted<Array<budgetUsageData>>([])

    const fetchBudgetUsageData = async (id: string) => {
        if (isNotSet(currentCostCenter?.budgetPeriod)) return

        setIsLoading(true)
        const startDate = moment().startOf(getBudgetPeriodString(currentCostCenter.budgetPeriod))
        const endDate = moment().endOf(getBudgetPeriodString(currentCostCenter.budgetPeriod))
        try {
            const [budgetUsageData] = await AnalyticsService.fetchCostCenterBudgetUsageData(id, startDate.toISOString(), endDate.toISOString())
            setBudgetUsages([...budgetUsages, { ...budgetUsageData, costCenter: id }])
            setBudgetUsage(budgetUsageData)
        } catch (err) {
            if (err.response?.status !== HttpStatus.FORBIDDEN) NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:fetch.title"), t("error:fetch.message"))
        }
        setIsLoading(false)
    }

    const onIndexChange = (index: number) => {
        setIndex(index)
        if (data.filter((i) => i !== "").length !== 0) {
            const id = data.filter((i) => i !== "").map((i) => i)[index]
            setCurrentCostCenterIndex(id)
            if (!budgetUsages.find(({ costCenter }) => costCenter === id)) fetchBudgetUsageData(id)
            else setBudgetUsage(budgetUsages.find(({ costCenter }) => costCenter === id)!)
        }
    }

    useEffect(() => {
        setIndex(0)
        if (data.filter((i) => i !== "").length !== 0) {
            const id = data.filter((i) => i !== "").map((i) => i)[0]
            setCurrentCostCenterIndex(id)
            fetchBudgetUsageData(id)
        }
    }, [JSON.stringify(data)])

    const previousButton = (
        <Button
            onClick={() => onIndexChange(index - 1)}
            disabled={index === 0}
            className="btn-default mr-3 mb-10"
            icon={<ArrowLeftCircle color={index === 0 ? COLOR.button.disabled : undefined} />}
        />
    )

    const nextButton = (
        <Button
            onClick={() => onIndexChange(index + 1)}
            disabled={index === data.length - 1}
            className="btn-default"
            icon={<ArrowRightCircle color={index === data.length - 1 ? COLOR.button.disabled : undefined} />}
        />
    )

    return (
        <div className={className ?? "mb-10"}>
            {currentCostCenter && (
                <>
                    <Row>
                        <Col span={hasMoreThan1Element(data) ? 16 : 24}>
                            <Row className="costCenterProgressBarHeader mt-5">
                                <Col span={hasMoreThan1Element(data) ? 4 : 3}>
                                    <Icon color="$color-finway-primary !important" className="mr-8 primary" icon="cost_center" />
                                </Col>
                                <Col span={hasMoreThan1Element(data) ? 20 : 21}>
                                    <h2 className="mb-0 pl-0 -mt-3 truncate">{currentCostCenter?.name ?? ""}</h2>
                                </Col>
                            </Row>
                        </Col>
                        {hasMoreThan1Element(data) && (
                            <Col span={8}>
                                <Row justify="end">
                                    {previousButton}
                                    {nextButton}
                                </Row>
                            </Col>
                        )}
                    </Row>
                    <BudgetUsageProgressBar
                        isLoading={isLoading}
                        budgetUsage={budgetUsage}
                        interval={PriceIntervalEnum.ONE_TIME}
                        costCenter={currentCostCenter}
                    ></BudgetUsageProgressBar>
                </>
            )}
        </div>
    )
}

export default memo(ExpenseCostCenterBudgetUsageProgressBar)
