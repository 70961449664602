import { RightEnum } from "@finway-group/shared/lib/models"
import { InboxFilter } from "@finway-group/shared/lib/models/invoice/invoiceInbox.filter"
import Form from "antd/lib/form"
import moment from "moment"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"

import { FilterFormInterface } from "Components/filter/filterTypes"
import FormMinMaxDate from "Components/form/formMinMaxDate"
import FormSelect from "Components/form/formSelect"
import { useEmployees } from "Shared/hooks/employee.hooks"
import { getTableFilterObjectStore } from "Shared/hooks/table.hooks"
import { AuthzService } from "Shared/services"
import { TablesEnum } from "Shared/store/reducers/tableConfigReducer"
import { getDynamicCreditorList } from "Shared/utils/filter.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

import { generateRules } from "./rules/expenseFilter.rules"

const adjustFilterObject = (filterObject: InboxFilter): InboxFilter => ({
    ...filterObject,
    minUploadDate: filterObject.minUploadDate ? moment(filterObject.minUploadDate).startOf("day").toDate() : undefined,
    maxUploadDate: filterObject.maxUploadDate ? moment(filterObject.maxUploadDate).endOf("day").toDate() : undefined,
})

const InboxFilterForm: React.FC<FilterFormInterface> = ({ formInstance, onSubmit, options }) => {
    const { t } = useTranslation()
    const filterObject = getTableFilterObjectStore<InboxFilter>(TablesEnum.INBOX_INVOICE)
    const [filter, setFilter] = useStateIfMounted(adjustFilterObject(filterObject))
    const rules = generateRules(formInstance)
    const employees = useEmployees({ excludeDeleted: true })

    useEffect(() => {
        formInstance.resetFields()
        setFilter(adjustFilterObject(filterObject))
    }, [])

    return (
        <Form id="expenseFilterForm" className="form--filter" form={formInstance} onFinish={onSubmit}>
            <FormMinMaxDate
                header={t("input:inbox_filter.upload_date_range")}
                min={{
                    name: "minUploadDate",
                    label: t("input:inbox_filter.min_upload_date"),
                    placeholder: t("placeholder:inbox_filter.min_upload_date"),
                    initialValue: filter.minUploadDate,
                    rules: rules.minUploadDate,
                    onChange: () => formInstance.validateFields(["minUploadDate", "maxUploadDate"]),
                }}
                max={{
                    name: "maxUploadDate",
                    label: t("input:inbox_filter.max_upload_date"),
                    placeholder: t("placeholder:inbox_filter.max_upload_date"),
                    initialValue: filter.maxUploadDate,
                    rules: rules.maxUploadDate,
                    onChange: () => formInstance.validateFields(["minUploadDate", "maxUploadDate"]),
                }}
            />

            <FormSelect
                name="vendorName"
                header={t("input:filter_form.vendor")}
                initialValue={filter.vendorName}
                options={getDynamicCreditorList(options.creditors)}
                isCollapse
                showSearch
                fallback
            />

            {AuthzService.isRightGrantedForLoggedInUser(RightEnum.INBOX_INVOICE__ALL__READ) && (
                <FormSelect
                    name="uploadedBy"
                    header={t("input:inbox_filter.uploaded_by")}
                    initialValue={filter.uploadedBy}
                    options={employees}
                    isCollapse
                    displayImages
                    showSearch
                    fallback
                />
            )}
        </Form>
    )
}

export default InboxFilterForm
