import { Company, SystemRightEnum } from "@finway-group/shared/lib/models"
import { Button, Collapse } from "antd"
import React, { useEffect, useMemo } from "react"
import { Plus as PlusIcon, Trash2 as TrashIcon } from "react-feather"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import CompanySettingsModal from "Components/modals/companySettings.modal"
import SelectedCompanyPreview from "Components/selectedCompanyPreview"
import SettingsLayout, { SettingsButton } from "Features/settings/settings.layout"
import { FINWAY_ADMIN_EMAIL, isDemo } from "Shared/config/consts"
import { useModal } from "Shared/context/modal.context"
import { useCompanies, useCompany } from "Shared/hooks/company.hooks"
import { useLoggedInEmployeeProfile } from "Shared/hooks/user.hooks"
import { AuthzService, NotificationService, UtilityService } from "Shared/services"
import DialogService from "Shared/services/dialog.service"
import { markCompanyAsDeleted, updateCompany } from "Shared/store/actions/company/companyActions"
import { sortCompanyByName } from "Shared/utils/company.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

import CompanyCard from "./CompanyCard"

const { Panel } = Collapse

const CompanyList = () => {
    const { t } = useTranslation()
    const { showModal } = useModal()
    const dispatch = useDispatch()
    const loggedInUser = useLoggedInEmployeeProfile()

    const [showDeleted, setShowDeleted] = useStateIfMounted(false)
    const [isResettingDemoData, setIsResettingDemoData] = useStateIfMounted(false)

    const companies = useCompanies(true)
    const { name, logo, _id, isRoot } = useCompany()
    const activeCompanies = useMemo(() => companies.filter((company) => !company.deleted && company._id !== _id).sort(sortCompanyByName), [companies, _id])
    const deletedCompanies = useMemo(() => companies.filter(({ deleted }) => !!deleted).sort(sortCompanyByName), [companies])

    const openCompanyModal = () => showModal(CompanySettingsModal, false, { company: {}, isShowing: true, isEditMode: false })
    const handleOnCompanyEdit = (company: Company) => showModal(CompanySettingsModal, false, { company, isShowing: true, isEditMode: true })

    const handleOnDelete = async (id: string) => {
        try {
            await markCompanyAsDeleted(id)(dispatch)
        } catch (err) {
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:error"))
        }
    }

    const handleOnRestore = async (company: Company) => {
        try {
            await updateCompany({ ...company, deleted: false })(dispatch)
        } catch (err) {
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:error"))
        }
    }

    const handleResetDemoData = async () => {
        try {
            if (!(await DialogService.confirmResetDemoData())) return

            await UtilityService.resetDemoData()
            setIsResettingDemoData(false)
        } catch (err) {
            NotificationService.showErrorNotificationBasedOnResponseError(err, t(`error:error`))
            setIsResettingDemoData(false)
        }
    }
    useEffect(() => {
        if (!deletedCompanies.length) setShowDeleted(false)
    }, [deletedCompanies.length, setShowDeleted])

    return (
        <SettingsLayout
            titleKey=""
            titleExtra={<SelectedCompanyPreview name={name} logo={logo} isRoot={isRoot} />}
            buttons={
                <div data-testid="addNewCompanyButton" className="btn-wrapper">
                    {AuthzService.isSystemRightGrantedForLoggedInUser(SystemRightEnum.SYS__COMPANY__ALL__CREATE) && (
                        <SettingsButton icon={<PlusIcon />} onClick={openCompanyModal} labelKey={"action:company_settings.add_new"} />
                    )}

                    {isDemo && loggedInUser?.email === FINWAY_ADMIN_EMAIL && (
                        <Button loading={isResettingDemoData} onClick={handleResetDemoData} type="primary" className="btn-danger">
                            <TrashIcon />
                            {t("label:reset_demo_data")}
                        </Button>
                    )}
                </div>
            }
        >
            <div className="flex flex-col">
                {activeCompanies?.map((company: Company) => (
                    <CompanyCard
                        key={company._id}
                        company={company}
                        canDelete={AuthzService.isSystemRightGrantedForLoggedInUser(SystemRightEnum.SYS__COMPANY__ALL__DELETE)}
                        onEdit={handleOnCompanyEdit}
                        onDelete={handleOnDelete}
                    />
                ))}

                {deletedCompanies.length > 0 && (
                    <>
                        <Collapse className="w-100 -ml-12 mt-14 -mb-10 pb-0" expandIconPosition="right" onChange={() => setShowDeleted(!showDeleted)} ghost>
                            <Panel header={t("label:deleted")} key="1" />
                        </Collapse>
                        {showDeleted && (
                            <div className="-mt-16">
                                {deletedCompanies?.map((company: Company) => (
                                    <CompanyCard
                                        key={company._id}
                                        company={company}
                                        canDelete={AuthzService.isSystemRightGrantedForLoggedInUser(SystemRightEnum.SYS__COMPANY__ALL__DELETE)}
                                        onEdit={handleOnCompanyEdit}
                                        onDelete={handleOnDelete}
                                        onRestore={handleOnRestore}
                                    />
                                ))}
                            </div>
                        )}
                    </>
                )}
            </div>
        </SettingsLayout>
    )
}

export default React.memo(CompanyList)
