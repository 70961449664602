import { KYBStatusEnum, KYBStepsEnum } from "@finway-group/shared/lib/models"
import { Alert, Button, Card, Col, Row, Steps } from "antd"
import { useForm } from "antd/lib/form/Form"
import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import { useCurrentKYBStep, useKYBData } from "Shared/hooks/corporate.hooks"
import { CorporateService } from "Shared/services"
import { setIsWeavrAuthenticated } from "Shared/store/actions/auth/authActions"
import { setCurrentKYBStep } from "Shared/store/actions/corporate/corporateActions"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

import KYBCompanyDetails from "./steps/kybCompanyDetails.step"
import KYBCreateAccount from "./steps/kybCreateAccount.step"
import KYBEmailVerification from "./steps/kybEmailVerification.step"
import KYBForm from "./steps/kybForm.step"
import KYBMobileVerification from "./steps/kybMobileVerification.step"
import KYBCorporatePIN from "./steps/kybSetupPin.step"

interface KYBInterface {}

const KYB: React.FC<KYBInterface> = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const currentStep = useCurrentKYBStep()
    const [companyDetailsForm] = useForm()
    const [corporatePinForm] = useForm()
    const [verifyEmailForm] = useForm()
    const [verifyMobileForm] = useForm()
    const [createAccountForm] = useForm()
    const [isLoading, setIsLoading] = useStateIfMounted(false)

    const goNext = () => dispatch(setCurrentKYBStep(currentStep + 1))

    const onNext = async () => {
        switch (currentStep) {
            case KYBStepsEnum.COMPANY_DETAILS:
                companyDetailsForm.submit()
                break
            case KYBStepsEnum.CREATE_PASSWORD:
                corporatePinForm.submit()
                break
            case KYBStepsEnum.VERIFY_EMAIL:
                verifyEmailForm.submit()
                break
            case KYBStepsEnum.VERIFY_MOBILE:
                verifyMobileForm.submit()
                break
            case KYBStepsEnum.CREATE_ACCOUNT:
                createAccountForm.submit()
                break
            default:
                break
        }
    }

    const onPasswordCreated = (tokenizedPassword: string) => {
        setIsLoading(true)
        CorporateService.authenticateUser(tokenizedPassword)
            .then((accessToken) => {
                dispatch(setIsWeavrAuthenticated(true, accessToken))
            })
            .finally(() => {
                // request the email code for the next step
                CorporateService.requestEmailVerificationCode()
                setIsLoading(false)
                goNext()
            })
    }

    const onEmailVerified = async () => {
        // request the mobile code for the next step
        await CorporateService.requestMobileVerificationCodeRootUser()
        goNext()
    }

    const steps = [
        {
            title: t("corporate:details.step"),
            content: <KYBCompanyDetails formInstance={companyDetailsForm} onSubmit={goNext} setIsLoading={setIsLoading} />,
            step: KYBStepsEnum.COMPANY_DETAILS,
        },
        {
            title: t("corporate:pin.step"),
            content: <KYBCorporatePIN formInstance={corporatePinForm} onSubmit={onPasswordCreated} setIsLoading={setIsLoading} />,
            step: KYBStepsEnum.CREATE_PASSWORD,
        },
        {
            title: t("corporate:verification.email.step"),
            content: <KYBEmailVerification formInstance={verifyEmailForm} onSubmit={onEmailVerified} setIsLoading={setIsLoading} />,
            step: KYBStepsEnum.VERIFY_EMAIL,
        },
        {
            title: t("corporate:verification.mobile.step"),
            content: <KYBMobileVerification formInstance={verifyMobileForm} onSubmit={goNext} setIsLoading={setIsLoading} />,
            step: KYBStepsEnum.VERIFY_MOBILE,
        },
        {
            title: t("corporate:kyb.step"),
            content: <KYBForm />,
            step: KYBStepsEnum.KYB_FORM,
        },
        {
            title: t("corporate:business_account.step"),
            content: <KYBCreateAccount formInstance={createAccountForm} setIsLoading={setIsLoading} />,
            step: KYBStepsEnum.CREATE_ACCOUNT,
        },
    ]

    const renderButtons = () => (
        <>
            <div className="ml-auto ">
                {currentStep !== KYBStepsEnum.KYB_FORM && (
                    <Button type="primary" loading={isLoading} onClick={() => onNext()}>
                        {currentStep === KYBStepsEnum.CREATE_ACCOUNT ? t("corporate:create_account") : t("creditCard:buttons.next")}
                    </Button>
                )}
            </div>
        </>
    )

    const RejectionAlert = () => {
        const { kybStatus, reason } = useKYBData()

        return (
            <>
                {kybStatus && kybStatus === KYBStatusEnum.REJECTED && reason && (
                    <Row gutter={[16, 16]}>
                        <Col span={24} sm={24} md={20} lg={16} className="mx-auto mt-20">
                            <Alert
                                message={t("creditCard:kyb_reason.rejected")}
                                type={"error"}
                                description={t(`corporate:kyb.rejection_reason.${reason}`)}
                                closable={false}
                                showIcon={true}
                            />
                        </Col>
                    </Row>
                )}
            </>
        )
    }

    return (
        <>
            <div>
                <h1 className="text-center">{t("creditCard:title")}</h1>
                <Row gutter={[0, 32]} align="middle">
                    <Col span={24} sm={24} md={20} lg={16} className="mx-auto">
                        <p className="text-center mt-10 text-sm">{t("creditCard:information")}</p>
                        <p className="text-center text-sm">{t("creditCard:information_2")}</p>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} align="middle" justify="center">
                    <Col span={18}>
                        <Steps size="small" progressDot current={currentStep} className="overflow-auto py-10">
                            {steps.map((item) => (
                                <Steps.Step key={item.title} title={item.title} />
                            ))}
                        </Steps>
                    </Col>
                </Row>

                <RejectionAlert />

                <Row gutter={[16, 16]} align="middle">
                    <Col span={24} sm={24} md={20} lg={16} className="mx-auto mt-20">
                        <Card>
                            <div className="mb-60" style={currentStep === KYBStepsEnum.KYB_FORM ? { minHeight: "541px" } : {}}>
                                {steps[currentStep]?.content}
                            </div>
                            <div className="mt-20 mb-10 flex">{renderButtons()}</div>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default KYB
