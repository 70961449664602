import moment from "moment-timezone"
import { useEffect } from "react"

import { useCompany } from "./company.hooks"

export const useDefaultTimeZone = () => {
    const selectedCompany = useCompany()

    useEffect(() => {
        if (!selectedCompany) return

        moment.tz.setDefault(selectedCompany.timeZone)
    }, [selectedCompany._id, selectedCompany.timeZone])
}
