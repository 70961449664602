import { PerDiemDestination } from "@finway-group/shared/lib/models"
import { Form, Select } from "antd"
import { FormListFieldData } from "antd/lib/form/FormList"
import numeral from "numeral"
import React, { memo } from "react"
import { useTranslation } from "react-i18next"

import { usePerDiemDestinationsForYear } from "Shared/hooks/perDiemDestination.hooks"
import { doFilterSelect } from "Shared/utils/helper.utils"
import { generatePerDiemDestinationLabel } from "Shared/utils/perDiem.utils"
import { getPopupAnchor } from "Shared/utils/popup.utils"

type PerDiemDestinationSelectorType = {
    rules: Array<{ required: boolean; message: string; validateTrigger: string }>
    elementField: FormListFieldData
    tripYear: number
    adjustDestinationsOnChange: () => void
    isSingleDestination?: boolean
    destinationIndex: number
    value?: string
}

function PerDiemDestinationSelector({
    rules,
    elementField,
    tripYear,
    adjustDestinationsOnChange,
    destinationIndex,
    value,
    isSingleDestination = true,
}: PerDiemDestinationSelectorType) {
    const { t } = useTranslation()
    const { destinations } = usePerDiemDestinationsForYear(tripYear, true)

    const renderPerDiemSelectionOption = (destination: PerDiemDestination) => {
        if (!destination.children || destination.children.length === 0) {
            return (
                <Select.Option key={`per-diem-country-selection-${destination._id}`} label={generatePerDiemDestinationLabel(destination)} value={destination._id}>
                    {generatePerDiemDestinationLabel(destination)}
                </Select.Option>
            )
        }

        return (
            <Select.OptGroup key={`per-diem-country-selection-${destination._id}`} label={destination.destination}>
                {(destination.children as Array<PerDiemDestination>).map(renderPerDiemSelectionOption)}
            </Select.OptGroup>
        )
    }

    return (
        <Form.Item
            {...elementField}
            name={[elementField.name, "destination"]}
            label={t(isSingleDestination ? "input:request.per_diem.destination" : "input:request.per_diem.nth_destination", {
                nth: numeral(destinationIndex + 1).format("0o"),
            })}
            key="destination"
            rules={rules}
        >
            <Select
                key={`destination_${value}`}
                filterOption={doFilterSelect}
                className="per-diem-select"
                getPopupContainer={getPopupAnchor()}
                onChange={adjustDestinationsOnChange}
                showSearch
            >
                {destinations.map(renderPerDiemSelectionOption)}
            </Select>
        </Form.Item>
    )
}

export default memo(PerDiemDestinationSelector)
