import { DocumentChangeInterface, OperationTypeEnum } from "@finway-group/shared/lib/models"
import * as Sentry from "@sentry/react"

import { FINWAY_ADMIN_EMAIL } from "Shared/config/consts"
import { fetchAllCompanies } from "Shared/store/actions/company/companyActions"
import { isClientErrorCode } from "Shared/utils/errorHandler.utils"

import EmployeeService from "../employee.service"

const subscribeToCompanySettings = (dispatch: any, data: DocumentChangeInterface) => {
    const {
        fullDocument: { operationType },
    } = data

    try {
        if (operationType === OperationTypeEnum.INSERT || operationType === OperationTypeEnum.UPDATE) {
            dispatch(fetchAllCompanies(false))
        }
    } catch (err) {
        if (isClientErrorCode(err)) return
        Sentry.captureException(`[Company Settings Socket] Error: ${typeof err === "string" ? err : JSON.stringify(err)}`)
    }
}

export const subscribeToCompanySettingsCallbacks = (dispatch: any, data: DocumentChangeInterface) => {
    // only enable these updates if logged in as the finway admin
    if (EmployeeService.getLoggedInEmployeeProfile().email !== FINWAY_ADMIN_EMAIL) {
        return
    }

    subscribeToCompanySettings(dispatch, data)
}

export default subscribeToCompanySettings
