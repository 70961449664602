import { UserLanguageEnum } from "@finway-group/shared/lib/models/user/userSettings.interface"

export const PERSONIO_RESOURCES = {
    general: {
        [UserLanguageEnum.DE]: "https://help.finway.de/de/articles/160507-personio-integration",
        [UserLanguageEnum.EN]: "https://help.finway.de/en/articles/160507-personio-integration",
    },
    anchorClientId: {
        [UserLanguageEnum.DE]: "https://help.finway.de/de/articles/160507-personio-integration#h_913647855e",
        [UserLanguageEnum.EN]: "https://help.finway.de/en/articles/160507-personio-integration#h_5afd9b6019",
    },
}
