import Icon from "@ant-design/icons"
import React from "react"

const suspendCardSvg = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
            <path d="M14.3333 3.66699H0.333252V5.66699H14.3333V3.66699Z" stroke="#606C7E" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M11 10.3337V9.66699C11 8.56243 11.8954 7.66699 13 7.66699C14.1046 7.66699 15 8.56243 15 9.66699V10.3337"
                stroke="#606C7E"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.6666 10.334H15.3333C15.5173 10.334 15.6666 10.4832 15.6666 10.6673V14.0007C15.6666 14.1847 15.5173 14.334 15.3333 14.334H10.6666C10.4825 14.334 10.3333 14.1847 10.3333 14.0007V10.6673C10.3333 10.4832 10.4825 10.334 10.6666 10.334Z"
                stroke="#606C7E"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14.3333 8.18604V2.66699C14.3333 2.11471 13.8855 1.66699 13.3333 1.66699H1.33325C0.780965 1.66699 0.333252 2.11471 0.333252 2.66699V10.667C0.333252 11.2193 0.780965 11.667 1.33325 11.667H10.3333"
                stroke="#606C7E"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.9998 12.3337C13.1839 12.3337 13.3332 12.1844 13.3332 12.0003C13.3332 11.8162 13.1839 11.667 12.9998 11.667C12.8157 11.667 12.6665 11.8162 12.6665 12.0003C12.6665 12.1844 12.8157 12.3337 12.9998 12.3337Z"
                stroke="#606C7E"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M13 12.334V13.0007" stroke="#606C7E" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        </g>
        <defs>
            <clipPath id="clip0">
                <rect width="16" height="16" fill="white" />
            </clipPath>
        </defs>
    </svg>
)

const SuspendCardIcon = (props: any) => <Icon component={suspendCardSvg} {...props} />

export default SuspendCardIcon
