import { Action } from "redux"

export enum ImportActionTypes {
    FETCH_IMPORT_RESULT = "FETCH_IMPORT_RESULT",
    IMPORT_DATA_REQUEST_ACCEPTED = "IMPORT_DATA_REQUEST_ACCEPTED",
    IMPORT_DATA_VALIDATE_ONLY_SUCCESS = "IMPORT_DATA_VALIDATE_ONLY_SUCCESS",
    IMPORT_DATA_VALIDATE_ONLY_FAILURE = "IMPORT_DATA_VALIDATE_ONLY_FAILURE",
    IMPORT_DATA_VALIDATION_ERRORS = "IMPORT_DATA_VALIDATION_ERRORS",
    IMPORT_DATA_SUCCESS = "IMPORT_DATA_SUCCESS",
    IMPORT_DATA_FAILURE = "IMPORT_DATA_FAILURE",

    OPEN_DATA_UPLOAD_MODAL = "OPEN_DATA_UPLOAD_MODAL",
    CLOSE_DATA_UPLOAD_NOTIFICATION = "CLOSE_DATA_UPLOAD_NOTIFICATION",
    RESET_IMPORT_RESULTS = "RESET_IMPORT_RESULTS",
}

export enum DataUploadNotificationTypeEnum {
    VALIDATE_ONLY_SUCCESS = "VALIDATE_ONLY_SUCCESS",
    VALIDATION_ERRORS = "VALIDATION_ERRORS",
    SUCCESS = "SUCCESS",
}

export interface FetchImportResultAction extends Action<typeof ImportActionTypes.FETCH_IMPORT_RESULT> {
    type: typeof ImportActionTypes.FETCH_IMPORT_RESULT
    payload: {
        importResultId: string
    }
}

export interface ImportDataRequestAcceptedAction extends Action<typeof ImportActionTypes.IMPORT_DATA_REQUEST_ACCEPTED> {
    type: typeof ImportActionTypes.IMPORT_DATA_REQUEST_ACCEPTED
    payload: {
        isInProgress: boolean
    }
}

export interface ImportDataValidateOnlySuccessAction extends Action<typeof ImportActionTypes.IMPORT_DATA_VALIDATE_ONLY_SUCCESS> {
    type: typeof ImportActionTypes.IMPORT_DATA_VALIDATE_ONLY_SUCCESS
    payload: {
        importResultId: string
        isInProgress: boolean
        notification?: DataUploadNotificationTypeEnum
    }
}

export interface ImportDataValidateOnlyFailureAction extends Action<typeof ImportActionTypes.IMPORT_DATA_VALIDATE_ONLY_FAILURE> {
    type: typeof ImportActionTypes.IMPORT_DATA_VALIDATE_ONLY_FAILURE
    payload: {
        isInProgress: boolean
    }
}

export interface ImportDataSuccessAction extends Action<typeof ImportActionTypes.IMPORT_DATA_SUCCESS> {
    type: typeof ImportActionTypes.IMPORT_DATA_SUCCESS
    payload: {
        importResultId: string
        isInProgress: boolean
        notification?: DataUploadNotificationTypeEnum
    }
}

export interface ImportDataFailureAction extends Action<typeof ImportActionTypes.IMPORT_DATA_FAILURE> {
    type: typeof ImportActionTypes.IMPORT_DATA_FAILURE
    payload: {
        isInProgress: boolean
    }
}

export interface ImportDataValidationErrorsAction extends Action<typeof ImportActionTypes.IMPORT_DATA_VALIDATION_ERRORS> {
    type: typeof ImportActionTypes.IMPORT_DATA_VALIDATION_ERRORS
    payload: {
        importResultId: string
        isInProgress: boolean
        notification?: DataUploadNotificationTypeEnum
    }
}

export interface OpenDataUploadModalAction extends Action<typeof ImportActionTypes.OPEN_DATA_UPLOAD_MODAL> {
    type: typeof ImportActionTypes.OPEN_DATA_UPLOAD_MODAL
    payload: {
        notification?: DataUploadNotificationTypeEnum
    }
}

export interface CloseDataUploadNotificationAction extends Action<typeof ImportActionTypes.CLOSE_DATA_UPLOAD_NOTIFICATION> {
    type: typeof ImportActionTypes.CLOSE_DATA_UPLOAD_NOTIFICATION
    payload: {
        notification?: DataUploadNotificationTypeEnum
    }
}

export interface ResetImportResultsAction extends Action<typeof ImportActionTypes.RESET_IMPORT_RESULTS> {
    type: typeof ImportActionTypes.RESET_IMPORT_RESULTS
}

export type ImportActions =
    | FetchImportResultAction
    | ImportDataRequestAcceptedAction
    | ImportDataValidateOnlySuccessAction
    | ImportDataValidateOnlyFailureAction
    | ImportDataValidationErrorsAction
    | ImportDataSuccessAction
    | ImportDataFailureAction
    | OpenDataUploadModalAction
    | CloseDataUploadNotificationAction
    | ResetImportResultsAction
