import { Employee } from "@finway-group/shared/lib/models"
import { Form, Modal } from "antd"
import { Store } from "antd/lib/form/interface"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import EmployeeCreditorNumberForm from "Components/forms/employeeCreditorNumber.form"
import { EmployeeService, NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { updateEmployee } from "Shared/store/actions/employee/employeeActions"
import { isEmptyString } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

interface EmployeeCreditorNumberModalProps {
    isShowing: boolean
    employee: Employee
    onCancel: () => void
    onCreditorNumberChangeSuccess?: () => Promise<void>
}

const EmployeeCreditorNumberModal = ({ employee, isShowing, onCancel, onCreditorNumberChangeSuccess }: EmployeeCreditorNumberModalProps) => {
    const { t } = useTranslation()
    const [formInstance] = Form.useForm()
    const [isLoading, setIsLoading] = useStateIfMounted(false)
    const { activeCompanyProfile } = employee

    const dispatch = useDispatch()

    useEffect(() => {
        let isMounted = true

        if (isShowing) {
            formInstance.setFieldsValue({ companyProfile: activeCompanyProfile })

            EmployeeService.getLatestEmployeeCreditorNumber().then((creditorNumber) => {
                if (isMounted) formInstance.setFieldsValue({ creditorNumber })
            })
        }

        return () => {
            isMounted = false
        }
    }, [isShowing])

    const onSubmit = async (values: Store) => {
        try {
            setIsLoading(true)
            const creditorNumber = !isEmptyString(values.creditorNumber) ? values.creditorNumber : null
            const employeeData = new Employee({
                ...employee,
                companyProfiles: employee?.companyProfiles?.map((userCompanyProfile) => {
                    if (userCompanyProfile.companyId === activeCompanyProfile.companyId) {
                        return {
                            ...userCompanyProfile,
                            creditorNumber,
                        }
                    }
                    return userCompanyProfile
                }),
            })
            await dispatch(updateEmployee(employee.id, employeeData))
            NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:employee.updated.title"), t("notification:employee.updated.message"))
            await onCreditorNumberChangeSuccess?.()
            onHide()
        } catch (err) {
            setIsLoading(false)
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:employee.edit.title"))
        }
    }

    const onHide = () => {
        setIsLoading(false)
        onCancel()
    }

    return (
        <Modal
            title={t("action:employee.add_creditor_number_name", { name: employee.getFullName() })}
            visible={isShowing}
            maskClosable={false}
            onOk={formInstance.submit}
            onCancel={onHide}
            okText={t("action:save")}
            cancelText={t("action:cancel")}
            confirmLoading={isLoading}
            keyboard
            destroyOnClose
        >
            <EmployeeCreditorNumberForm formInstance={formInstance} onSubmit={onSubmit} />
        </Modal>
    )
}

export default EmployeeCreditorNumberModal
