import { TransactionCategory, TransactionCategoryTypeEnum } from "@finway-group/shared/lib/models"
import { Form, Modal } from "antd"
import { Store } from "antd/lib/form/interface"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import TransactionCategoryForm from "Components/forms/transactionCategory.form"
import { NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { setShouldFetchCashFlowsData } from "Shared/store/actions/liquidity/liquidityActions"
import { createTransactionCategory, deleteTransactionCategory, updateTransactionCategory } from "Shared/store/actions/transactionCategory/transactionCategoryAction"
import { TransactionCategoryWithChildren } from "Shared/store/reducers/tableReducer"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

interface TransactionCategoryModalProps {
    modalType: ModalTypeEnum
    isShowing: boolean
    transactionCategory?: TransactionCategory | TransactionCategoryWithChildren
    transactionCategoryType?: TransactionCategoryTypeEnum
    onCancel: () => void
}

export enum ModalTypeEnum {
    CREATE = "CREATE",
    EDIT = "EDIT",
    DELETE = "DELETE",
}

const TransactionCategoryModal = ({ modalType, isShowing, transactionCategory, transactionCategoryType, onCancel }: TransactionCategoryModalProps) => {
    const { t } = useTranslation()
    const [formInstance] = Form.useForm()
    const [isLoading, setIsLoading] = useStateIfMounted(false)
    const dispatch = useDispatch()

    useEffect(() => {
        if (isShowing && modalType === ModalTypeEnum.EDIT) {
            formInstance.setFieldsValue({ name: transactionCategory?.name })
        }
    }, [isShowing])

    useEffect(() => {
        if (!transactionCategory || modalType !== ModalTypeEnum.DELETE) return
        const deleteModal = Modal.confirm({
            title: t("confirm:transaction_category.delete.title"),
            content: t("confirm:transaction_category.delete.message"),
            cancelText: t("confirm:transaction_category.delete.cancel"),

            type: "warning",
            okText: t("confirm:transaction_category.delete.confirm"),
            onOk: async () => {
                setIsLoading(true)
                try {
                    await dispatch(deleteTransactionCategory(transactionCategory.id, transactionCategory.type))
                    dispatch(setShouldFetchCashFlowsData(true))
                    NotificationService.send(
                        NotificationTypeEnum.SUCCESS,
                        t("notification:transaction_category.deleted.title"),
                        t("notification:transaction_category.deleted.message"),
                    )
                } catch (err) {
                    NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:transaction_category.delete.title"), t("error:transaction_category.delete.message"))
                }
                handleHide()
            },
            onCancel: () => deleteModal.destroy(),
        })
        return () => deleteModal.destroy()
    }, [transactionCategory])

    const handleSubmit = async (values: Store) => {
        setIsLoading(true)
        if (modalType === ModalTypeEnum.CREATE) {
            if (!transactionCategoryType) return
            const createData = { name: values.name, type: transactionCategoryType, parentId: values.parent, isGroup: values.isGroup }
            try {
                await dispatch(createTransactionCategory(createData))
                dispatch(setShouldFetchCashFlowsData(true))
                NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:transaction_category.created.title"), t("notification:transaction_category.created.message"))
            } catch (err) {
                NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:transaction_category.create.title"))
            }
        } else if (modalType === ModalTypeEnum.EDIT) {
            if (!transactionCategory) return
            const updateData = { name: values.name }
            try {
                await dispatch(updateTransactionCategory(transactionCategory.id, updateData))
                dispatch(setShouldFetchCashFlowsData(true))
                NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:transaction_category.updated.title"), t("notification:transaction_category.updated.message"))
            } catch (err) {
                NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:transaction_category.update.title"), t("error:transaction_category.update.message"))
            }
        }
        handleHide()
    }

    const handleHide = () => {
        setIsLoading(false)
        onCancel()
    }

    return (
        <>
            {modalType === ModalTypeEnum.DELETE ? (
                <></>
            ) : (
                <Modal
                    title={modalType === ModalTypeEnum.CREATE ? t("action:transaction_category.title.create") : t("action:transaction_category.title.edit")}
                    visible={isShowing}
                    maskClosable={false}
                    onOk={formInstance.submit}
                    onCancel={handleHide}
                    okText={
                        modalType === ModalTypeEnum.CREATE
                            ? (transactionCategory?.type || transactionCategoryType) === TransactionCategoryTypeEnum.INFLOW
                                ? t("action:transaction_category.create_inflow")
                                : t("action:transaction_category.create_outflow")
                            : t("action:transaction_category.edit")
                    }
                    cancelText={t("action:cancel")}
                    confirmLoading={isLoading}
                    keyboard
                    destroyOnClose
                >
                    <TransactionCategoryForm
                        modalType={modalType}
                        formInstance={formInstance}
                        onSubmit={handleSubmit}
                        transactionCategoryType={transactionCategory?.type || transactionCategoryType || TransactionCategoryTypeEnum.INFLOW}
                    />
                </Modal>
            )}
        </>
    )
}

export default TransactionCategoryModal
