import { isDateGobdCompliant } from "@finway-group/shared/lib/utils/date.utils"
import moment from "moment"

/**
 * getPreviousAndNextMonthsWithYear(0,0) would return an array with a single element: the current month "YYYY-MM" format.
 *
 * Example output: ["2021-07"]
 *
 * getPreviousAndNextMonthsWithYear(1,2) would return an array of last month, current month and next 2 months in "YYYY-MM" format.
 *
 * Example output: ["2021-06", "2021-07", "2021-08", "2021-09"]
 */
export const getPreviousAndNextMonthsWithYear = (previousMonths: number, nextMonths: number): Array<string> => {
    const months: Array<string> = []
    const firstMonth = moment().subtract(previousMonths + 1, "month")
    const firstYear = moment().subtract(previousMonths + 1, "month")
    for (let i = -previousMonths - 1; i < nextMonths; i++) {
        const currentMonth = firstMonth.add(1, "month")
        const currentYear = firstYear.add(1, "month")
        months.push(`${currentYear.format("YYYY")}-${currentMonth.format("MM")}`)
    }
    return months
}

/**
 * getPreviousAndNextQuartersWithYear(0,0) would return an array with a single element: the current quarter "YYYY-Q" format.
 *
 * Example output: ["2021-3"]
 *
 * getPreviousAndNextQuartersWithYear(2,2) would return an array of last 2 quarters, current quarter and next quarter in "YYYY-Q" format.
 *
 * Example output: ["2021-1", "2021-2", "2021-3", "2021-4", "2022-1"]
 */
export const getPreviousAndNextQuartersWithYear = (previousQuarters: number, nextQuarters: number): Array<string> => {
    const quarters: Array<string> = []
    const firstQuarter = moment().subtract(previousQuarters + 1, "quarter")
    const firstYear = moment().subtract(previousQuarters + 1, "quarter")
    for (let i = -previousQuarters - 1; i < nextQuarters; i++) {
        const currentQuarter = firstQuarter.add(1, "quarter")
        const currentYear = firstYear.add(1, "quarter")
        quarters.push(`${currentYear.format("YYYY")}-${currentQuarter.format("Q")}`)
    }
    return quarters
}

/**
 * getPreviousAndNextYears(0,0) would return an array with a single element: the current year in "YYYY" format.
 *
 * Example output: ["2021"]
 *
 * getPreviousAndNextQuartersWithYear(1,1) would return an array of last year, current year and next year in "YYYY" format.
 *
 * Example output: ["2020", "2021", "2022"]
 */
export const getPreviousAndNextYears = (previousYears: number, nextYears: number): Array<string> => {
    const years: Array<string> = []
    const firstYear = moment().subtract(previousYears, "year")
    for (let i = -previousYears; i < nextYears; i++) {
        const currentYear = firstYear.add(1, "year")
        years.push(currentYear.format("YYYY"))
    }

    return years
}

export const gobdNonCompliantDates = (current: moment.Moment) => current && !isDateGobdCompliant(current)

export const isDateOutOfRange = (current: moment.Moment) => current > moment().add(1, "M").startOf("month") || current < moment().subtract(11, "M").startOf("month")
