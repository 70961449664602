import { Expense, Mileage } from "@finway-group/shared/lib/models"

import { BankSubjectOptionEnum } from "Components/modals/customBankSubject.modal"

import { stripForBankSubjectLine, stripForSEPASubjectLine } from "./helper.utils"

export const buildBankSubjectLine = (bankSubjectParts: Array<any>, expense: Expense) => stripForBankSubjectLine(buildRawBankSubjectLine(bankSubjectParts, expense))

export const buildSEPASubjectLine = (bankSubjectParts: Array<any>, expense: Expense) => stripForSEPASubjectLine(buildRawBankSubjectLine(bankSubjectParts, expense))

export const buildRawBankSubjectLine = (bankSubjectParts: Array<any>, expense: Expense) =>
    (bankSubjectParts ?? [])
        .map((subjectPart: any) => {
            switch (subjectPart.part) {
                case BankSubjectOptionEnum.DESCRIPTION:
                    return expense.description || (expense as Mileage)?.tripReason || "-"
                case BankSubjectOptionEnum.EXPENSE_NUMBER:
                    return `Expense Nr. ${expense.expenseNumber}`
                case BankSubjectOptionEnum.INVOICE_NUMBER:
                    return expense.invoiceNumber ?? "-"
                case BankSubjectOptionEnum.REQUEST_ITEMS:
                    // TODO: what do do here exactly?
                    return expense.splits.map((split: any) => split.name)
                case BankSubjectOptionEnum.VENDOR_NAME:
                    return expense.getCreditor()?.name
                default:
                    return ""
            }
        })
        .join(", ")
