import { Role } from "@finway-group/shared/lib/models"
import { Reducer } from "react"

import { CreateRoleAction, DeleteRoleAction, FetchAllRolesAction, RoleTypes, RolesActions, SelectRoleAction, UpdateRoleAction } from "../actions/role/roleTypes"

export interface RoleState {
    items: Array<Role>
    item: Role | undefined
}

const initialState = {
    items: [],
    item: undefined,
}

const fetchAllRoles = (state: RoleState, action: FetchAllRolesAction): RoleState => {
    let { item } = state
    if (item) {
        const itemInAction = action.roles.find(({ _id }: Role) => _id === item?._id)
        if (itemInAction) item = itemInAction
    } else item = action.roles[0]

    return {
        ...state,
        items: action.roles,
        item,
    }
}

const updateRole = (state: RoleState, { role }: UpdateRoleAction): RoleState => ({
    ...state,
    items: state.items.map((item) => (item._id === role._id ? role : item)),
    item: state.item?._id === role._id ? role : state.item,
})

const selectRole = (state: RoleState, { role }: SelectRoleAction): RoleState => ({
    ...state,
    item: role,
})

const createRole = (state: RoleState, action: CreateRoleAction): RoleState => ({
    ...state,
    items: [...state.items, action.role],
    item: action.role,
})

const deleteRole = (state: RoleState, action: DeleteRoleAction): RoleState => {
    const newItems = state.items.filter((item) => item._id !== action.roleId)
    // If the deleted member is the current item, get the last non-deleted role array member
    const newItem = state.item?._id === action.roleId ? newItems.filter((item) => !item.deleted).pop() : state.item
    return {
        ...state,
        items: newItems,
        item: newItem,
    }
}

export const roleReducer: Reducer<RoleState, RolesActions> = (state = initialState, action) => {
    switch (action.type) {
        case RoleTypes.FETCH_ALL_ROLES:
            return fetchAllRoles(state, action)
        case RoleTypes.UPDATE_ROLE:
            return updateRole(state, action)
        case RoleTypes.SELECT_ROLE:
            return selectRole(state, action)
        case RoleTypes.CREATE_ROLE:
            return createRole(state, action)
        case RoleTypes.DELETE_ROLE:
            return deleteRole(state, action)
        default:
            return state
    }
}
