import { Input } from "antd"
import { SizeType } from "antd/es/config-provider/SizeContext"
import React, { InputHTMLAttributes, useEffect, useRef, useState } from "react"

interface ControlledInputInterface extends Omit<InputHTMLAttributes<HTMLInputElement>, "size"> {
    computeCursorPosition?: (e: any, value: any) => number
    value?: any
    onChange?: (value: any) => void
    size?: SizeType
}

const ControlledInput: React.FC<ControlledInputInterface> = (props) => {
    const { value, onChange, computeCursorPosition, ...rest } = props
    const [cursor, setCursor] = useState(null)
    const ref = useRef<any>(null)

    useEffect(() => {
        const input = ref.current
        if (input) input.input.setSelectionRange(cursor, cursor)
    }, [ref, cursor, value])

    const handleChange = (e: any) => {
        const cursorPosition = computeCursorPosition ? computeCursorPosition(e, value) : e.target.selectionStart
        setCursor(cursorPosition)
        if (onChange) onChange(e)
    }

    return <Input value={value} ref={ref} onChange={handleChange} {...rest} />
}

export default ControlledInput
