import { Expense, ExpenseKindEnum } from "@finway-group/shared/lib/models"
import { Col, DatePicker, Form, Input, Radio, Row } from "antd"
import { RadioChangeEvent } from "antd/lib/radio"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"

interface ExpenseKindRadioFieldInterface {
    expense: Expense
    onKindChange: (newKind: ExpenseKindEnum) => void
}

const ExpenseKindRadioField: React.FC<ExpenseKindRadioFieldInterface> = ({ expense, onKindChange }) => {
    const { t } = useTranslation()
    return (
        <Form.Item label={t("input:request.kind")}>
            <Radio.Group value={expense?.kind} onChange={(e) => onKindChange(e.target.value)} name="kind">
                <Radio key={0} value={ExpenseKindEnum.ONE_TIME_EXPENSE}>
                    {t("label:one_time_purchase")}
                </Radio>
                <Radio key={1} value={ExpenseKindEnum.SUBSCRIPTION}>
                    {t("label:subscription")}
                </Radio>
            </Radio.Group>
        </Form.Item>
    )
}

export default ExpenseKindRadioField
