import { BudgetPeriodEnum, CalculationIntervalEnum } from "@finway-group/shared/lib/models"
import type { Team } from "@finway-group/shared/lib/models"
import { Reducer } from "redux"

import { CostCenterBudgetUsageBarChartsOptions } from "Features/pages/costCenters/costCenterDetails/costCenterBudgetUsageBarCharts"
import { PeriodRangeOptionEnum } from "Features/pages/costCenters/costCenterDetails/costCenterPeriodOption.interface"
import { ReportingFilterInterface } from "Features/pages/reporting/reportingFilter.interface"

import {
    AnalyticsActionTypes,
    AnalyticsActions,
    FetchCardOverviewDataAction,
    FetchCostCenterBudgetDataAction,
    FetchCostCenterExpenseDataAction,
    FetchCostCenterExpenseDataPieAction,
    FetchExpensesPerCostCenterOverLast12MonthsDataAction,
    FetchExpensesPerDepartmentPie as FetchExpensesPerDepartmentPieAction,
    FetchExpensesPerEmployeeDataAction,
    FetchOverviewDataAction,
    FetchTeamsWithUsersCountAction,
    ResetCardOverviewDataAction,
    SetCostCenterBudgetUsageBarGraphOptions,
    SetCostCenterIntervalAction,
    SetCostCenterIntervalPieAction,
    SetEmployeeIntervalAction,
    SetExpensesPerCostCenterOver12MonthsFilter,
    SetExpensesPerDepartmentIntervalPieAction,
} from "../actions/analytics/analyticsTypes"

export interface AnalyticsState {
    overviewData: any
    expensesPerEmployee: Array<any>
    expensesPerCostCenterOverLast12MonthsData: Array<any>
    expensesPerCostCenterOverLast12MonthsFilter: ReportingFilterInterface
    expensesPerDepartmentDataPie: Array<any>
    expensesPerDepartmentIntervalPie: CalculationIntervalEnum
    costCenterBudgetData: Array<any>
    costCenterInterval: CalculationIntervalEnum
    costCenterExpenseData: Array<any>
    costCenterIntervalPie: CalculationIntervalEnum
    costCenterExpenseDataPie: Array<any>
    employeeInterval: CalculationIntervalEnum
    cards: {
        overviewData: {
            totalCardBalance: number
        }
    }
    costCenterBudgetUsageBarGraphOptions: CostCenterBudgetUsageBarChartsOptions
    teams: Array<Team>
}

const initialState = {
    overviewData: { currentMonthTotal: 0, currentQuarterTotal: 0, currentYearTotal: 0, allTimeTotal: 0 },
    expensesPerEmployee: [],
    expensesPerCostCenterOverLast12MonthsData: [],
    expensesPerCostCenterOverLast12MonthsFilter: { minDate: "", maxDate: "", costCenters: [] },
    expensesPerDepartmentDataPie: [],
    expensesPerDepartmentIntervalPie: CalculationIntervalEnum.CURRENT_YEAR,
    costCenterBudgetData: [],
    costCenterInterval: CalculationIntervalEnum.CURRENT_YEAR,
    costCenterExpenseData: [],
    costCenterIntervalPie: CalculationIntervalEnum.CURRENT_YEAR,
    costCenterExpenseDataPie: [],
    employeeInterval: CalculationIntervalEnum.CURRENT_YEAR,
    cards: {
        overviewData: { totalCardBalance: 0 },
    },
    costCenterBudgetUsageBarGraphOptions: { startDate: "", endDate: "", periodRangeOption: PeriodRangeOptionEnum.LAST_12_PERIODS, viewPeriod: BudgetPeriodEnum.MONTHLY },
    teams: [],
}

const fetchOverviewData = (state: AnalyticsState, action: FetchOverviewDataAction): AnalyticsState => ({
    ...state,
    overviewData: action.overviewData,
})

const fetchCardOverviewData = (state: AnalyticsState, action: FetchCardOverviewDataAction): AnalyticsState => ({
    ...state,
    cards: {
        ...state.cards,
        overviewData: action.cardOverviewData,
    },
})

const resetCardOverviewData = (state: AnalyticsState, _action: ResetCardOverviewDataAction): AnalyticsState => ({
    ...state,
    cards: {
        ...state.cards,
        overviewData: initialState.cards.overviewData,
    },
})

const fetchExpensesPerEmployeeData = (state: AnalyticsState, { expensesPerEmployee }: FetchExpensesPerEmployeeDataAction): AnalyticsState => ({
    ...state,
    expensesPerEmployee,
})

const fetchExpensesPerCostCenterOverLast12Months = (
    state: AnalyticsState,
    { expensesPerCostCenterOverLast12MonthsData }: FetchExpensesPerCostCenterOverLast12MonthsDataAction,
): AnalyticsState => ({
    ...state,
    expensesPerCostCenterOverLast12MonthsData,
})

const fetchCostCenterBudgetData = (state: AnalyticsState, { costCenterBudgetData }: FetchCostCenterBudgetDataAction): AnalyticsState => ({
    ...state,
    costCenterBudgetData,
})

const fetchExpensesPerDepartmentPie = (state: AnalyticsState, { expensesPerDepartmentDataPie }: FetchExpensesPerDepartmentPieAction): AnalyticsState => ({
    ...state,
    expensesPerDepartmentDataPie,
})

const setExpensePerDepartmentIntervalPie = (state: AnalyticsState, { expensesPerDepartmentIntervalPie }: SetExpensesPerDepartmentIntervalPieAction): AnalyticsState => ({
    ...state,
    expensesPerDepartmentIntervalPie,
})

const setEmployeeInterval = (state: AnalyticsState, { employeeInterval }: SetEmployeeIntervalAction): AnalyticsState => ({
    ...state,
    employeeInterval,
})

const setCostCenterInterval = (state: AnalyticsState, { costCenterInterval }: SetCostCenterIntervalAction): AnalyticsState => ({
    ...state,
    costCenterInterval,
})
const setCostCenterIntervalPie = (state: AnalyticsState, { costCenterIntervalPie }: SetCostCenterIntervalPieAction): AnalyticsState => ({
    ...state,
    costCenterIntervalPie,
})

const setExpensesPerCostCenterOverLast12MonthsFilter = (state: AnalyticsState, { expensesPerCostCenterOverLast12MonthsFilter }: SetExpensesPerCostCenterOver12MonthsFilter) => ({
    ...state,
    expensesPerCostCenterOverLast12MonthsFilter,
})

const fetchCostCenterExpenseData = (state: AnalyticsState, { costCenterExpenseData }: FetchCostCenterExpenseDataAction) => ({
    ...state,
    costCenterExpenseData,
})

const fetchCostCenterExpenseDataPie = (state: AnalyticsState, { costCenterExpenseDataPie }: FetchCostCenterExpenseDataPieAction) => ({
    ...state,
    costCenterExpenseDataPie,
})

const setCostCenterBudgetUsageBarGraphOptions = (state: AnalyticsState, { costCenterBudgetUsageBarGraphOptions }: SetCostCenterBudgetUsageBarGraphOptions) => ({
    ...state,
    costCenterBudgetUsageBarGraphOptions,
})

const fetchTeamsWithUsersCount = (state: AnalyticsState, { teams }: FetchTeamsWithUsersCountAction): AnalyticsState => ({
    ...state,
    teams,
})

export const analyticsReducer: Reducer<AnalyticsState, AnalyticsActions> = (state = initialState, action) => {
    switch (action.type) {
        case AnalyticsActionTypes.FETCH_OVERVIEW_DATA:
            return fetchOverviewData(state, action)
        case AnalyticsActionTypes.FETCH_CARD_OVERVIEW_DATA:
            return fetchCardOverviewData(state, action)
        case AnalyticsActionTypes.FETCH_EXPENSES_PER_EMPLOYEE_DATA:
            return fetchExpensesPerEmployeeData(state, action)
        case AnalyticsActionTypes.FETCH_EXPENSES_PER_COST_CENTER_OVER_LAST_12_MONTHS_DATA:
            return fetchExpensesPerCostCenterOverLast12Months(state, action)
        case AnalyticsActionTypes.FETCH_EXPENSES_PER_DEPARTMENT_DATA_PIE:
            return fetchExpensesPerDepartmentPie(state, action)
        case AnalyticsActionTypes.FETCH_COST_CENTER_BUDGET_DATA:
            return fetchCostCenterBudgetData(state, action)
        case AnalyticsActionTypes.FETCH_COST_CENTER_EXPENSE_DATA:
            return fetchCostCenterExpenseData(state, action)
        case AnalyticsActionTypes.FETCH_COST_CENTER_EXPENSE_DATA_PIE:
            return fetchCostCenterExpenseDataPie(state, action)
        case AnalyticsActionTypes.SET_COST_CENTER_INTERVAL:
            return setCostCenterInterval(state, action)
        case AnalyticsActionTypes.SET_COST_CENTER_INTERVAL_PIE:
            return setCostCenterIntervalPie(state, action)
        case AnalyticsActionTypes.SET_EXPENSES_PER_DEPARTMENT_INTERVAL_PIE:
            return setExpensePerDepartmentIntervalPie(state, action)
        case AnalyticsActionTypes.SET_EMPLOYEE_INTERVAL:
            return setEmployeeInterval(state, action)
        case AnalyticsActionTypes.SET_EXPENSES_PER_COST_CENTER_OVER_LAST_12_MONTHS_FILTER:
            return setExpensesPerCostCenterOverLast12MonthsFilter(state, action)
        case AnalyticsActionTypes.SET_COST_CENTER_BUDGET_USAGE_BAR_GRAPH_OPTIONS:
            return setCostCenterBudgetUsageBarGraphOptions(state, action)
        case AnalyticsActionTypes.RESET_CARD_OVERVIEW_DATA:
            return resetCardOverviewData(state, action)
        case AnalyticsActionTypes.FETCH_TEAMS_WITH_USERS_COUNT:
            return fetchTeamsWithUsersCount(state, action)
        default:
            return state
    }
}
