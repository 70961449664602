import { COLOR, Currencies, INDIGO_BLUE } from "@finway-group/shared/lib/consts"
import { BudgetPeriodEnum, CostCenter, CostCenterChartTypeEnum, CostCenterTypeEnum } from "@finway-group/shared/lib/models"
import { BarTooltipDatum, ResponsiveBar } from "@nivo/bar"
import { Card, Col, Radio, Row } from "antd"
import * as HttpStatus from "http-status"
import numeral from "numeral"
import React, { memo, useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import Loading from "Components/loading"
import ExpenseBudgetReportModal from "Components/modals/expenseBudgetReport.modal"
import CostCenterDateRangePicker from "Features/pages/costCenters/costCenterDetails/costCenterDateRangePicker"
import LegendDetailModal from "Features/pages/reporting/reportingDetails/legendDetailModal"
import { useCostCenterBudgetUsageBarGraphOptions } from "Shared/hooks/analytics.hooks"
import { AnalyticsService, NotificationService, UserService } from "Shared/services"
import { setCostCenterBudgetUsageBarGraphOptions } from "Shared/store/actions/analytics/analyticsActions"
import { breakpoints } from "Shared/utils/breakpoints.utils"
import { ColorGenerator } from "Shared/utils/colorGenerator.utils"
import { calculateGraphMaxValue, calculatePaddingValue, getBarData, getDetailChartKeys } from "Shared/utils/costCenter.utils"
import { formatCurrencyNumber } from "Shared/utils/helper.utils"
import { useResizeWindowWidth } from "Shared/utils/hooks/useResizeWindowWidth"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

import { PeriodRangeOptionEnum } from "./costCenterPeriodOption.interface"

export interface CostCenterBudgetUsageBarChartsOptions {
    startDate: string
    endDate: string
    periodRangeOption: PeriodRangeOptionEnum
    viewPeriod: BudgetPeriodEnum
}
interface CostCenterBudgetUsageBarChartsInterface {
    costCenter: CostCenter
}

const CostCenterBudgetUsageBarCharts: React.FC<CostCenterBudgetUsageBarChartsInterface> = ({ costCenter }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const windowWidth = useResizeWindowWidth()
    const isOnSmallScreens = windowWidth < breakpoints.md
    const isOnMobile = windowWidth < breakpoints.xs
    const graphOptions = useCostCenterBudgetUsageBarGraphOptions(isOnMobile)

    const usedCurrency = costCenter ? costCenter.budgetCurrency : UserService.getLoggedInEmployeeProfile().settings.globalCurrency

    const [showExpensesModal, setShowExpensesModal] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useStateIfMounted(false)
    const [budgetUsageData, setBudgetUsageData] = useStateIfMounted<any>([])
    const [chartType, setChartType] = useState<CostCenterChartTypeEnum>(CostCenterChartTypeEnum.BUDGET_VIEW)
    const [showLegendModal, setShowLegendModal] = useStateIfMounted(false)
    const [selectedBudgetUsage, setSelectedBudgetUsage] = useStateIfMounted<any>(undefined)

    const budgetChartKeys = ["firstBar", "secondBar", "thirdBar"]
    const detailChartKeys = getDetailChartKeys(budgetUsageData)
    const budgetChartColors = (colorProps: any) => colorProps.data[`${colorProps.id}Color`]
    const detailChartColors = ColorGenerator.generateColorGradient(COLOR.finway.green, COLOR.primary, detailChartKeys.length)

    const handlePopoverSubmit = useCallback((selectedOptions: any) => {
        dispatch(setCostCenterBudgetUsageBarGraphOptions(selectedOptions))
    }, [])

    useEffect(() => {
        let isFetching = true

        const fetchBudgetUsageData = async () => {
            setIsLoading(true)
            try {
                if (!isFetching) return
                const budgetUsageData = await AnalyticsService.fetchCostCenterBudgetUsageData(costCenter._id, graphOptions.startDate, graphOptions.endDate, graphOptions.viewPeriod)
                setBudgetUsageData(budgetUsageData)
            } catch (err) {
                if (err.response?.status !== HttpStatus.FORBIDDEN)
                    NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:fetch.title"), t("error:fetch.message"))
            }
            setIsLoading(false)
        }
        fetchBudgetUsageData()
        return () => {
            isFetching = false
        }
    }, [costCenter._id, costCenter.budgetValue, costCenter.budgetCurrency, JSON.stringify(costCenter.alternativeBudgets), graphOptions])

    const onBarClicked = (nodeData: any) => {
        setSelectedBudgetUsage(budgetUsageData[nodeData.index])
        setShowExpensesModal(true)
    }

    const data = useMemo(() => getBarData(chartType, budgetUsageData), [budgetUsageData, chartType])
    const maxValue = useMemo(() => calculateGraphMaxValue(data), [data])

    const budgetViewTooltip = (tooltipProps: BarTooltipDatum) => {
        const keys: Array<string> = tooltipProps.data.keys as unknown as Array<string>
        const tooltips = []
        for (let i = keys.length; i >= 0; i--) {
            const value = formatCurrencyNumber(tooltipProps.data[`${keys[i]}`], usedCurrency)

            // show tooltip only if value is above 0
            if (tooltipProps.data[keys[i]] > 0) {
                tooltips.push(
                    <li className="flex gap-1 justify-between" key={keys[i]}>
                        <div className="flex items-center">
                            <div style={{ width: "15px", height: "15px", borderRadius: "50%", backgroundColor: tooltipProps.data[`${keys[i]}Color`] as string }}></div>
                            <div className="m-1 ml-8 font-medium">
                                <p>{`${tooltipProps.data[`${keys[i]}Label`] as string}`}</p>
                            </div>
                        </div>

                        <div className="ml-12">
                            <b>{value}</b>
                        </div>
                    </li>,
                )
            }
        }
        return <ul>{tooltips}</ul>
    }

    const detailViewTooltip = (tooltipProps: BarTooltipDatum) => {
        const tooltips = []

        for (let i = detailChartKeys.length; i >= 0; i--) {
            const value = formatCurrencyNumber(tooltipProps.data[`${detailChartKeys[i]}`], usedCurrency)

            // show tooltip only if value is above 0
            if (tooltipProps.data[detailChartKeys[i]] > 0) {
                tooltips.push(
                    <li className="flex gap-1 items-center" key={detailChartKeys[i]}>
                        <div style={{ width: "15px", height: "15px", borderRadius: "50%", backgroundColor: detailChartColors[i] }}></div>
                        <div className="m-1 ml-8 font-medium">
                            <p>
                                {`${detailChartKeys[i] as string}`} <b style={{ marginLeft: "10px" }}>{value}</b>
                            </p>
                        </div>
                    </li>,
                )
            }
        }
        return <ul>{tooltips}</ul>
    }

    const renderBudgetViewLegend = () => {
        const legends = [
            { name: t("label:spending_within_budget"), color: COLOR.finway.green },
            { name: t("label:spending_forecast_within_budget"), color: COLOR.finway["green-light4"] },
            { name: t("label:spending_above_budget"), color: COLOR.finway.red2 },
            { name: t("label:spending_forecast_above_budget"), color: COLOR.finway["red-light2"] },
            { name: t("label:budget_left"), color: COLOR.gray[500] },
        ]

        return (
            // TODO: Remove percentage
            <Row className="mt-10 flex flex-wrap" style={{ marginLeft: "8%", marginRight: "5%" }}>
                {legends.map((legend) => (
                    <Col key={legend.name} className="mr-20 flex items-center">
                        <span className="h-12 w-12 inline-block" style={{ borderRadius: "2px", backgroundColor: legend.color, marginRight: "5px" }}></span>{" "}
                        <label>{legend.name}</label>
                    </Col>
                ))}
            </Row>
        )
    }

    const renderDetailViewLegend = () => (
        <>
            <LegendDetailModal
                isVisible={showLegendModal}
                onClose={() => {
                    setShowLegendModal(false)
                }}
                items={detailChartKeys.map((costCenterName: string, index) => ({
                    id: costCenterName,
                    name: costCenterName,
                    color: detailChartColors[index],
                }))}
            />
            <Row className="ml-80 mt-30 overflow-y-hidden" style={{ height: "1.4em" }}>
                <Col span={20}>
                    <Row>
                        {detailChartKeys.map((costName: string, index: number) => (
                            <Col className="inline-block">
                                <div className="mr-10 float-left" key={index}>
                                    <span className="h-10 w-10 flex inline-flex" style={{ backgroundColor: `${detailChartColors[index]}` }}></span> <label>{costName} </label>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Col>
                <Col span={4} className="overflow-x-overlay">
                    <a className="float-right mr-10" onClick={() => setShowLegendModal(true)} style={{ whiteSpace: "nowrap" }}>
                        {t("label:show_more")}
                    </a>
                </Col>
            </Row>
        </>
    )

    const getResponsibleBarProps = (chartType: CostCenterChartTypeEnum) => {
        const keysProp = chartType === CostCenterChartTypeEnum.DETAIL_VIEW ? detailChartKeys : budgetChartKeys
        const colorsProp = chartType === CostCenterChartTypeEnum.DETAIL_VIEW ? detailChartColors : budgetChartColors
        const tooltipProp = chartType === CostCenterChartTypeEnum.BUDGET_VIEW ? budgetViewTooltip : detailViewTooltip
        const maxValueProp = chartType === CostCenterChartTypeEnum.BUDGET_VIEW ? maxValue + maxValue / (isOnMobile ? 6 : 10) : undefined
        const onClickProp = chartType === CostCenterChartTypeEnum.BUDGET_VIEW ? (e: any) => onBarClicked(e) : undefined

        return {
            keysProp,
            colorsProp,
            tooltipProp,
            maxValueProp,
            onClickProp,
        }
    }

    return (
        <>
            <div className="text-right flex-row align-baseline">
                {costCenter.costCenterType === CostCenterTypeEnum.COST_CENTER_GROUP && (
                    <Radio.Group defaultValue={CostCenterChartTypeEnum.BUDGET_VIEW} onChange={(e) => setChartType(e.target.value)}>
                        <Radio.Button className="h-34" value={CostCenterChartTypeEnum.DETAIL_VIEW}>
                            {t("action:detail_view")}
                        </Radio.Button>
                        <Radio.Button className="h-34" value={CostCenterChartTypeEnum.BUDGET_VIEW}>
                            {t("action:budget_view")}
                        </Radio.Button>
                    </Radio.Group>
                )}
                <CostCenterDateRangePicker
                    onSubmit={handlePopoverSubmit}
                    startDate={graphOptions.startDate}
                    endDate={graphOptions.endDate}
                    periodRangeOption={graphOptions.periodRangeOption}
                    viewPeriod={graphOptions.viewPeriod}
                />
            </div>
            <Card>
                {isLoading ? (
                    <Loading />
                ) : (
                    <>
                        <div className="h-400 w-full animation-appear">
                            <ResponsiveBar
                                keys={getResponsibleBarProps(chartType).keysProp}
                                data={data}
                                colors={getResponsibleBarProps(chartType).colorsProp}
                                tooltip={getResponsibleBarProps(chartType).tooltipProp}
                                isInteractive={getResponsibleBarProps(chartType).tooltipProp !== undefined}
                                maxValue={getResponsibleBarProps(chartType).maxValueProp}
                                indexBy="name"
                                padding={calculatePaddingValue(data.length)}
                                groupMode="stacked"
                                onClick={getResponsibleBarProps(chartType).onClickProp}
                                label={(labelProps: any) => numeral(labelProps.value).format("0.00")}
                                labelTextColor="#ffffff"
                                labelSkipWidth={40}
                                labelSkipHeight={20}
                                enableGridX={false}
                                enableGridY={false}
                                theme={{ axis: { ticks: { line: { stroke: INDIGO_BLUE }, text: { fill: INDIGO_BLUE } } } }}
                                animate={true}
                                motionStiffness={50}
                                margin={isOnSmallScreens ? { top: 10, right: 30, bottom: 50, left: 70 } : { top: 30, right: 60, bottom: 50, left: 110 }}
                                motionDamping={15}
                                axisLeft={{
                                    tickSize: 10,
                                    tickPadding: 5,
                                    format: (v: number) => formatCurrencyNumber(v, usedCurrency, false, 1, 5 - Currencies[usedCurrency].symbol.length),
                                    tickValues: 8,
                                }}
                                axisBottom={{
                                    tickSize: 10,
                                    tickPadding: isOnSmallScreens ? 0 : 5,
                                    tickRotation: isOnSmallScreens ? 45 : 0,
                                }}
                            />
                        </div>
                        {chartType === CostCenterChartTypeEnum.DETAIL_VIEW ? renderDetailViewLegend() : renderBudgetViewLegend()}
                        {costCenter && selectedBudgetUsage && (
                            <ExpenseBudgetReportModal
                                costCenterId={costCenter._id}
                                costCenterName={costCenter.name}
                                budgetUsage={selectedBudgetUsage}
                                isVisible={showExpensesModal}
                                onCancel={setShowExpensesModal}
                            />
                        )}
                    </>
                )}
            </Card>
        </>
    )
}

export default memo(CostCenterBudgetUsageBarCharts)
