import { Button, Col, Input, Row } from "antd"
import Form, { FormInstance } from "antd/lib/form"
import { Store } from "antd/lib/form/interface"
import React from "react"
import { Plus as PlusIcon } from "react-feather"
import { useTranslation } from "react-i18next"

import CostCenter2Rules from "./rules/costCenter2.rules"

interface CostCenters2FormInterface {
    formInstance: FormInstance
    onSubmit: (values: Store) => void
    isEdit: boolean
}
const CostCenters2Form: React.FC<CostCenters2FormInterface> = ({ formInstance, onSubmit, isEdit }) => {
    const { t } = useTranslation()
    const { name, code } = CostCenter2Rules(formInstance, isEdit)

    return (
        <Form form={formInstance} layout="vertical" onFinish={onSubmit} autoComplete="off">
            <Form.List name="costCenters2">
                {(fields, { add }) => (
                    <div>
                        {fields.map((field, index) => (
                            <Row key={index} gutter={[8, 8]} align="top">
                                <Col span={11} offset={0}>
                                    <Form.Item name={[field.name, "name"]} label={t("input:cost_center_2.name")} key="name" rules={name} required>
                                        <Input placeholder={t("placeholder:cost_center_2.name")} maxLength={100} />
                                    </Form.Item>
                                </Col>

                                <Col span={10} offset={0}>
                                    <Form.Item name={[field.name, "code"]} label={t("input:cost_center_2.code")} key="code" rules={code} required>
                                        <Input placeholder={t("placeholder:cost_center_2.code")} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        ))}

                        {!isEdit && (
                            <Row>
                                <Button className="mb-20" type="dashed" onClick={() => add()} block icon={<PlusIcon />}>
                                    <span>{t("action:cost_center_2.add")}</span>
                                </Button>
                            </Row>
                        )}
                    </div>
                )}
            </Form.List>
        </Form>
    )
}

export default CostCenters2Form
