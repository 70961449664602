import React from "react"
import { useTranslation } from "react-i18next"

const PasswordPolicyResetInfoPanel = () => {
    const { t } = useTranslation()
    return (
        <div>
            <p className="font-bold">{t("info:new_password_panel.password_policy.title")}</p>
            <ul className="m-20">
                <li className="list-disc">{t("info:new_password_panel.password_policy.1")}</li>
                <li className="list-disc">{t("info:new_password_panel.password_policy.2")}</li>
                <li className="list-disc">{t("info:new_password_panel.password_policy.3")}</li>
                <li className="list-disc">{t("info:new_password_panel.password_policy.4")}</li>
            </ul>
        </div>
    )
}

export default PasswordPolicyResetInfoPanel
