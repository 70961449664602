import Icon from "@ant-design/icons"
import React from "react"

const spreadSheetFileGreenIcon = () => (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_126_126976)">
            <path
                d="M15.0534 11.9167C15.0534 10.625 14.8642 10.625 14.4076 10.625C14.1818 10.625 13.7617 10.625 13.7617 11.9785C13.7617 12.4837 14.0323 13.1528 14.5394 13.9336C14.8717 12.8703 15.0534 12.1891 15.0534 11.9167Z"
                fill="#F94747"
            />
            <path
                d="M15.0248 16.6333C14.8324 17.2148 14.6167 17.8606 14.3771 18.5796C14.3392 18.6944 14.3001 18.8079 14.2598 18.9202C15.0696 18.6635 15.9166 18.4484 16.7125 18.275C16.3127 17.9338 16.0125 17.6613 15.8914 17.5402C15.5817 17.2306 15.2928 16.9285 15.0248 16.6333Z"
                fill="#F94747"
            />
            <path
                d="M8.59766 23.3346C8.62478 23.368 8.87453 23.5421 9.24349 23.5421C9.60992 23.5421 10.5692 22.89 11.5392 21.4835C11.4528 21.5245 11.3677 21.5642 11.2838 21.604C9.96311 22.2296 8.59766 22.8767 8.59766 23.3346Z"
                fill="#F94747"
            />
            <path
                d="M26.4897 5.64675L20.0329 -0.810003C19.916 -0.927312 19.7544 -1 19.5755 -1H6.01302C4.58891 -1 3.42969 0.158589 3.42969 1.58333V27.4167C3.42969 28.8414 4.58891 30 6.01302 30H24.0964C25.5205 30 26.6797 28.8414 26.6797 27.4167V6.10417C26.6797 5.92528 26.607 5.76367 26.4897 5.64675ZM21.513 5.45833C20.8003 5.45833 20.2214 4.87872 20.2214 4.16667V1.20492L24.4748 5.45833H21.513Z"
                fill="#346642"
            />
            <path
                d="M20.2224 19.0204C20.1467 19.0204 20.0123 19.0305 19.8301 19.0507C20.3321 19.3925 20.7389 19.6322 20.9149 19.67C21.514 19.6662 21.514 19.5458 21.514 19.2556C21.4472 19.1137 21.1766 19.0204 20.2224 19.0204Z"
                fill="#F94747"
            />
            <path
                d="M12.0593 11.1947L14.7343 15.389H14.7957L17.4707 11.1947H18.5134L15.4043 16.0259L18.5134 20.8571H17.4707L14.7957 16.71H14.7343L12.0593 20.8571H11.0166L14.1729 16.0259L11.0166 11.1947H12.0593Z"
                fill="white"
            />
        </g>
        <defs>
            <clipPath id="clip0_126_126976">
                <rect width="30" height="30" fill="white" />
            </clipPath>
        </defs>
    </svg>
)

export const SpreadSheetFileGreenIcon = (props: any) => <Icon component={spreadSheetFileGreenIcon} {...props} />
