import { ExpenseKindEnum, SubscriptionTypeEnum } from "@finway-group/shared/lib/models"
import { Button, Form, Modal } from "antd"
import { Store } from "antd/lib/form/interface"
import httpStatus from "http-status"
import React from "react"
import { useTranslation } from "react-i18next"

import ExportForm from "Components/forms/export.form"
import { useCompany } from "Shared/hooks/company.hooks"
import { useLoggedInEmployeeProfile } from "Shared/hooks/user.hooks"
import { FileService, NotificationService } from "Shared/services"
import DialogService from "Shared/services/dialog.service"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { adjustFilterDataForSelectedExpenseType, shouldAskToAdvanceStatusOnExport } from "Shared/utils/expense.utils"
import { convertFilterObjectIntoQueryFormat } from "Shared/utils/filter.utils"
import { encodeFilterString } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

interface ExportModalInterface {
    isShowing: boolean
    subscriptions?: boolean
    transactions?: boolean
    onCancel: (state: boolean) => void
}
const ExportModal: React.FC<ExportModalInterface> = ({ isShowing, subscriptions = false, transactions = false, onCancel }) => {
    const { t } = useTranslation()
    const [formInstance] = Form.useForm()
    const loggedInUser = useLoggedInEmployeeProfile()
    const company = useCompany()

    const [isLoading, setIsLoading] = useStateIfMounted<boolean>(false)

    const exportExpenses = async (values: Store, shouldAdvanceStatus?: boolean) => {
        const archiveAfterXDaysQueryString = `&archiveAfterXDays[eq]=${company.archiveAfterXDays}`
        const convertedValues = convertFilterObjectIntoQueryFormat(values, loggedInUser.settings.showGrossAmount)
        // prettier-ignore
        const encodedQueryString = `${encodeFilterString(convertedValues)}&format=${values.format}&deleted[is]=false&subscriptionType[ne]=${SubscriptionTypeEnum.INITIAL_REQUEST}${!values.includeArchive ? archiveAfterXDaysQueryString : ""}`;

        try {
            const response = await FileService.export(encodedQueryString, !shouldAdvanceStatus ?? true)

            const isAsyncExport = response?.status === httpStatus.ACCEPTED

            if (!isAsyncExport) FileService.downloadFileAsSignedUrl(response?.data.fileUrl)

            NotificationService.showNotificationOnExportSuccess(response)

            handleHide()
        } catch (err) {
            if (err.response && err.response.status === httpStatus.NOT_FOUND) {
                NotificationService.send(NotificationTypeEnum.WARNING, t("error:file:export_no_data:title"), t("error:file:export_no_data:message"))
            } else {
                NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:file:export:title"))
            }
        } finally {
            setIsLoading(false)
        }
    }

    const exportTransactions = () => {
        NotificationService.send(NotificationTypeEnum.INFO, "Transaction export", "To be implemented")
        setIsLoading(false)
        onCancel(false)
    }

    const onSubmit = async (values: Store) => {
        let formValues = { ...values }

        if (formValues.minDate) formValues.minDate.startOf("day")
        if (formValues.maxDate) formValues.maxDate.endOf("day")
        if (formValues.minInvoice) formValues.minInvoice.startOf("day")
        if (formValues.maxInvoiceDate) formValues.maxInvoiceDate.endOf("day")
        formValues = adjustFilterDataForSelectedExpenseType(formValues)

        let shouldAdvanceStatus

        if (shouldAskToAdvanceStatusOnExport(formValues.format, formValues.status)) {
            const { resolved, advanceStatus } = await DialogService.confirmExpenseStatusAdvancement()
            if (!resolved) return
            shouldAdvanceStatus = advanceStatus
        }

        setIsLoading(true)
        if (!transactions) exportExpenses(formValues, shouldAdvanceStatus)
        else exportTransactions()
    }

    const handleHide = () => {
        setIsLoading(false)
        formInstance.resetFields()
        onCancel(false)
    }

    return (
        <Modal
            visible={isShowing}
            maskClosable={false}
            title={t("action:request.export.title")}
            onCancel={handleHide}
            className="ant-modal--small"
            footer={[
                <Button key="back" onClick={() => onCancel(false)}>
                    {t("action:cancel")}
                </Button>,
                <Button key="submit" type="primary" loading={isLoading} onClick={formInstance.submit}>
                    {t("action:request.export.submit")}
                </Button>,
            ]}
            destroyOnClose
            closable
            keyboard
        >
            <ExportForm subscriptions={subscriptions} transactions={transactions} formInstance={formInstance} onSubmit={onSubmit} />
        </Modal>
    )
}

export default ExportModal
