import Icon from "@ant-design/icons"
import React from "react"

const germanySvg = () => (
    <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.5 4.6875C0.5 2.51288 2.26288 0.75 4.4375 0.75H10.5625C12.7371 0.75 14.5 2.51288 14.5 4.6875V4.6875H0.5V4.6875Z" fill="#272727" />
        <rect x="0.5" y="4.25" width="14" height="3.5" fill="#E31D1C" />
        <path d="M0.5 7.75H14.5V7.75C14.5 9.683 12.933 11.25 11 11.25H4C2.067 11.25 0.5 9.683 0.5 7.75V7.75Z" fill="#FFD018" />
    </svg>
)

const GermanyIcon = (props: any) => <Icon component={germanySvg} {...props} />

export default GermanyIcon
