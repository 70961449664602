import { BankOutlined as BankIcon } from "@ant-design/icons"
import { CurrencyEnum, Expense } from "@finway-group/shared/lib/models"
import Button from "antd/es/button"
import Dropdown from "antd/es/dropdown"
import Menu from "antd/es/menu"
import Tooltip from "antd/es/tooltip"
import React from "react"
import { Check as CheckedIcon, Settings as CogIcon } from "react-feather"
import { useTranslation } from "react-i18next"

import { ConditionalWrapper } from "Components/conditionalWrapper"
import { useDefaultAccount } from "Shared/hooks/bank.hooks"
import { useCompany } from "Shared/hooks/company.hooks"
import { AuthzService } from "Shared/services"
import { isFolderExpense } from "Shared/utils/expense.utils"
import { isFolderMarkableAsPaid, isFolderPayable } from "Shared/utils/folder.utils"
import { getTooltipPopupContainer } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

interface PaymentDropDownButtonInterface {
    onSelect: (key: string) => Promise<void>
    expenses: Array<Expense>
    text?: string
    disabledTooltip?: string
    disabled?: boolean
    oneColorStyle?: boolean
    asDottedButton?: boolean
    buttonClassName?: string
}

export const PaymentDropDownButton: React.FC<PaymentDropDownButtonInterface> = ({ text, expenses, onSelect, disabledTooltip, disabled = false, buttonClassName = "" }) => {
    const { t } = useTranslation()
    const [loading, setLoading] = useStateIfMounted(false)
    const company = useCompany()

    const amountPaid = expenses.filter((e: Expense) => e.isPaid()).length
    const defaultAccount = useDefaultAccount()
    const containsRefundExpense = expenses.some((expense) => expense.totalNetPrice < 0 || expense.totalGrossPrice === 0)
    const hasPayByBankRight = AuthzService.canLoggedInUserPayByBank()
    const isNonSepaXmlExportEnabled = company?.isNonSepaXmlExportEnabled && expenses.every((expense) => expense.currency !== CurrencyEnum.EUR)

    const shouldShowBankingPaymentOption = () => {
        if (!defaultAccount) return false
        if (!hasPayByBankRight) return false
        if (containsRefundExpense) return false

        const containsUnpayableFolder = expenses.some((expense) => isFolderExpense(expense) && !isFolderPayable(expense, expense.children))
        if (containsUnpayableFolder) return false

        return true
    }

    const shouldShowMarkAsPaidOption = () => {
        if (!hasPayByBankRight) return false

        const containsUnmarkableAsPaidFolder = expenses.some((expense) => isFolderExpense(expense) && !isFolderMarkableAsPaid(expense, expense.children))
        if (containsUnmarkableAsPaidFolder) return false

        return true
    }

    const handleSelect = async ({ key, domEvent }: any) => {
        try {
            domEvent.stopPropagation()
            setLoading(true)
            await onSelect(key)
        } finally {
            setLoading(false)
        }
    }

    const dropDownMenu = () => (
        <Menu onClick={handleSelect}>
            {amountPaid === 0 && (
                <>
                    {shouldShowBankingPaymentOption() && (
                        <>
                            <Menu.Item key="pay_now" className="flex items-center min-w-190 py-8" disabled={disabled}>
                                <div className="flex items-center justify-center rounded-full w-16 h-16">
                                    <BankIcon />
                                </div>
                                <div className="flex items-center ml-12">
                                    <p className="leading-none">
                                        <span className="text-text">{t("action:banking.pay_now")}</span>
                                    </p>
                                </div>
                            </Menu.Item>
                            <Menu.Item key="pay_on_date" className="flex items-center min-w-190 py-8" disabled={disabled}>
                                <div className="flex items-center justify-center rounded-full w-16 h-16">
                                    <BankIcon />
                                </div>
                                <div className="flex items-center ml-12">
                                    <p className="leading-none">
                                        <span className="text-text">{t("action:banking.pay_on_date")}</span>
                                    </p>
                                </div>
                            </Menu.Item>
                        </>
                    )}
                    {shouldShowMarkAsPaidOption() && (
                        <>
                            <Menu.Item key="mark_as_paid" className="flex items-center min-w-190 py-8" disabled={disabled}>
                                <div className="flex items-center justify-center rounded-full w-16 h-16">
                                    <CheckedIcon />
                                </div>
                                <div className="flex items-center ml-12">
                                    <p className="leading-none">
                                        <span className="text-text">{t("action:mark_as_paid")}</span>
                                    </p>
                                </div>
                            </Menu.Item>
                        </>
                    )}
                    {hasPayByBankRight && (
                        <>
                            <Menu.Item key="generate_sepa" className="flex items-center min-w-190 py-8" disabled={disabled}>
                                <div className="flex items-center justify-center rounded-full w-16 h-16">
                                    <CogIcon />
                                </div>
                                <div className="flex items-center ml-12">
                                    <p className="leading-none">
                                        <span className="text-text">{t("action:generate_sepa")}</span>
                                    </p>
                                </div>
                            </Menu.Item>
                            {isNonSepaXmlExportEnabled ? (
                                <Menu.Item key="generate_non_sepa" className="flex items-center min-w-190 py-8" disabled={disabled}>
                                    <div className="flex items-center justify-center rounded-full w-16 h-16">
                                        <CogIcon />
                                    </div>
                                    <div className="flex items-center ml-12">
                                        <p className="leading-none">
                                            <span className="text-text">{t("action:generate_non_sepa")}</span>
                                        </p>
                                    </div>
                                </Menu.Item>
                            ) : null}
                        </>
                    )}
                </>
            )}
        </Menu>
    )

    return (
        <ConditionalWrapper
            condition={disabled && !!disabledTooltip}
            wrapper={(children: any) => (
                <Tooltip getPopupContainer={getTooltipPopupContainer} title={<div>{disabledTooltip}</div>} placement="bottom">
                    {children}
                </Tooltip>
            )}
        >
            <Dropdown overlay={dropDownMenu} trigger={["click"]} className="flex items-center">
                {/* // need this div due to a bug in antd that not always properly hides the tooltip if there are pointerEvents in Button component */}
                <div className={`${disabled ? "cursor-not-allowed" : "cursor-pointer"} `} onClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}>
                    <Button
                        onClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}
                        disabled={disabled}
                        loading={loading}
                        block
                        className={`pointer-events-none ${buttonClassName}`}
                        type="primary"
                    >
                        {text}
                        <i className="el-icon-caret-bottom el-icon--right "></i>
                    </Button>
                </div>
            </Dropdown>
        </ConditionalWrapper>
    )
}
