import { BankOutlined, LoadingOutlined } from "@ant-design/icons"
import { BankConnection, BankConnectionUpdateStatusEnum, RightEnum } from "@finway-group/shared/lib/models"
import { Col, Row, Tag } from "antd"
import React from "react"
import { Edit as EditIcon, Trash as TrashIcon } from "react-feather"
import { useTranslation } from "react-i18next"

import { useCompany } from "Shared/hooks/company.hooks"
import { AuthzService } from "Shared/services"

import LogoFinder from "./logoFinder"

interface BankAccountPropInterface {
    bankConnection: BankConnection
    onEditBankConnection: (id: string) => void
    onDeleteBankConnection: (id: string) => void
}

const BankAccount = ({ bankConnection, onEditBankConnection, onDeleteBankConnection }: BankAccountPropInterface) => {
    const { t } = useTranslation()
    const company = useCompany()
    const canWrite = AuthzService.isRightGrantedForLoggedInUser(RightEnum.BANK_ACCOUNT__ALL__CREATE)

    return (
        <Row gutter={[16, 16]} justify="center">
            <Col lg={23} xs={22}>
                <div className="p-10 flex flex-row border flex-grow w-full rounded-lg">
                    <LogoFinder name={bankConnection.bankName} fallback={<BankOutlined style={{ fontSize: "30px" }} />} />
                    <div className="flex flex-col w-full">
                        <div className="align-left flex flex-col mr-10 w-full">
                            <div>
                                <b className="truncate placeholder-primary mr-10">{bankConnection.bankName}</b>
                                {bankConnection.isDefault && (
                                    <Tag className="ant-tag ant-tag-blue" color="primary">
                                        {t("label:default")}
                                    </Tag>
                                )}
                                {bankConnection.status === BankConnectionUpdateStatusEnum.DOWNLOADING && (
                                    <Tag className="mr-10 ant-tag ant-tag-yellow" color="warn">
                                        {t("info:connecting")}
                                    </Tag>
                                )}
                                {bankConnection.consentActionRequired &&
                                    company.bankingPaymentAutoMatchEnabled &&
                                    bankConnection.status !== BankConnectionUpdateStatusEnum.DOWNLOADING && (
                                        <Tag className="mr-10 ant-tag ant-tag-yellow" color="warn">
                                            {t("info:action_required")}
                                        </Tag>
                                    )}
                            </div>
                            <span className="text w-auto">{bankConnection.bankCode}</span>
                        </div>
                    </div>

                    <div className="text-left md:text-right">
                        {bankConnection.status === BankConnectionUpdateStatusEnum.DOWNLOADING && <LoadingOutlined style={{ fontSize: 24 }} spin className="ml-8" />}
                    </div>
                </div>
            </Col>
            {canWrite && (
                <Col lg={1} xs={2}>
                    <Row className="mt-12 cursor-pointer">
                        <div onClick={() => onEditBankConnection(bankConnection._id)}>
                            <EditIcon size={14} />
                        </div>
                    </Row>
                    <Row className="mt-20 cursor-pointer">
                        <div onClick={() => onDeleteBankConnection(bankConnection._id)}>
                            <TrashIcon size={14} />
                        </div>
                    </Row>
                </Col>
            )}
        </Row>
    )
}

export default React.memo(BankAccount)
