import { ExclamationCircleOutlined } from "@ant-design/icons"
import { Tooltip } from "antd"
import React from "react"
import { Edit as EditIcon } from "react-feather"
import { useTranslation } from "react-i18next"

import CustomBankSubjectModal from "Components/modals/customBankSubject.modal"
import DebitCardLimitBufferModal from "Components/modals/debitCardLimitBuffer.modal"
import { useCompany } from "Shared/hooks/company.hooks"
import { AuthzService } from "Shared/services"
import { getTooltipPopupContainer } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

import FeatureSettingStatus from "./featureSettingStatus"

const BankingAndCardsTabContent = () => {
    const { t } = useTranslation()
    const [isShowingCustomBankSubjectModal, setIsShowingCustomBankSubjectModal] = useStateIfMounted(false)
    const [isShowingDebitCardLimitBufferModal, setIsShowingDebitCardLimitBufferModal] = useStateIfMounted(false)

    const company = useCompany()
    const canWrite = AuthzService.canUpdateCompanyFeatureSettings()

    return (
        <div>
            <ul className="description-list">
                <FeatureSettingStatus title={t("label:card_feature")} isEnabled={company.cardsEnabled} />
                <FeatureSettingStatus title={t("label:multi_currency_wallet")} isEnabled={company.multiCurrencyWalletEnabled} />
                <FeatureSettingStatus title={t("label:liquidity_management_feature")} isEnabled={company.liquidityManagementEnabled} />
                <FeatureSettingStatus title={t("label:auto_matching_feature")} isEnabled={company.autoMatchEnabled} />
                <FeatureSettingStatus title={t("label:auto_bank_payment_matching")} isEnabled={company.bankingPaymentAutoMatchEnabled} />
                <li>
                    <span className="title w-240 flex items-center">
                        {t("label:debit_card_limit_buffer")}
                        <Tooltip getPopupContainer={getTooltipPopupContainer} title={t("tooltips:debit_card_limit_buffer")} className="ml-5">
                            <ExclamationCircleOutlined />
                        </Tooltip>
                    </span>
                    <span className="flex items-center space-x-12 text">
                        <span className="overflow-x-auto w-240">{company.debitCardLimitBuffer} %</span>
                        {canWrite && <EditIcon className="cursor-pointer" size={14} onClick={() => setIsShowingDebitCardLimitBufferModal(true)} />}
                    </span>
                    <DebitCardLimitBufferModal isShowing={isShowingDebitCardLimitBufferModal} onCancel={() => setIsShowingDebitCardLimitBufferModal(false)} />
                </li>
                <li>
                    <span className="title w-240">
                        <span className="title w-240"> {t("label:custom_bank_subject.title")}</span>
                        <Tooltip getPopupContainer={getTooltipPopupContainer} title={t("tooltips:custom_bank_subject")} placement="right" className="align-middle">
                            <ExclamationCircleOutlined className="ml-6" />
                        </Tooltip>
                    </span>
                    <span className="flex items-center space-x-12 text">
                        <span className="overflow-x-auto w-240">
                            {company.customBankSubjectParts.map((subjectPart: any) => t(`label:custom_bank_subject.${subjectPart.part}`)).join(", ")}
                        </span>
                        {canWrite && <EditIcon className="cursor-pointer" size={14} onClick={() => setIsShowingCustomBankSubjectModal(true)} />}
                    </span>
                    <CustomBankSubjectModal
                        isShowing={isShowingCustomBankSubjectModal}
                        onCancel={() => setIsShowingCustomBankSubjectModal(false)}
                        bankSubjectParts={company.customBankSubjectParts}
                    />
                </li>
            </ul>
        </div>
    )
}

export default BankingAndCardsTabContent
