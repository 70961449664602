import { FormInstance, Rule } from "antd/lib/form"

import { checkMaxNumber, checkMinNumber } from "./utils.rules"

export const generateRules = (formInstance: FormInstance): { [key: string]: Array<Rule> } => ({
    minExpenses: [
        {
            validator: (_rule: any, value: number | string) => checkMinNumber({ formInstance, value, name: "maxExpenses" }),
        },
    ],
    maxExpenses: [
        {
            validator: (_rule: any, value: number | string) => checkMaxNumber({ formInstance, value, name: "minExpenses" }),
        },
    ],
    minCreditorNumber: [
        {
            validator: (_rule: any, value: number | string) => checkMinNumber({ formInstance, value, name: "maxCreditorNumber" }),
        },
    ],
    maxCreditorNumber: [
        {
            validator: (_rule: any, value: number | string) => checkMaxNumber({ formInstance, value, name: "minCreditorNumber" }),
        },
    ],
})
