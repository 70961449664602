import { PauseCircleOutlined as PausedIcon, PlayCircleOutlined as ResumeIcon } from "@ant-design/icons"
import { CollectionNameEnum, Expense, ExpenseRequestTypeEnum, RightEnum } from "@finway-group/shared/lib/models"
import { Alert, Badge, Button, Card, Col, Empty, Modal, Row } from "antd"
import React, { Suspense, SyntheticEvent, useEffect, useState } from "react"
import { X as CancelIcon, CreditCard as CreditCardIcon, Edit2 as EditIcon, File as FileIcon } from "react-feather"
import { Helmet } from "react-helmet"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useHistory, useParams } from "react-router-dom"

import PageCustomHeader from "Components/PageCustomHeader"
import VendorImage from "Components/VendorImage"
import { ApprovalProcessIndicatorMenu } from "Components/approvalProcessesIndicator"
import GroupedExpenses from "Components/groupedExpenses"
import Loading from "Components/loading"
import CreateCardModal from "Components/modals/createCard.modal"
import CreateRecurringExpenseModal from "Components/modals/createRecurringExpense.modal"
import ExpenseCreateFormModal from "Components/modals/expenseCreateForm.modal"
import { useAreCardsActivated } from "Shared/hooks/corporate.hooks"
import { useCostCenter2ById } from "Shared/hooks/costCenter2.hooks"
import { useCostCenterById } from "Shared/hooks/costCenter.hooks"
import { useCardByExpense } from "Shared/hooks/creditCard.hooks"
import { useCardRootUser } from "Shared/hooks/employee.hooks"
import { useExpense } from "Shared/hooks/expense.hooks"
import { useLoggedInEmployeeProfile } from "Shared/hooks/user.hooks"
import { useVendorById } from "Shared/hooks/vendor.hooks"
import { AuthzService, EmployeeService, NotificationService, UserService } from "Shared/services"
import DialogService from "Shared/services/dialog.service"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { ThunkDispatchResult } from "Shared/store"
import { deleteExpense, fetchOneExpense, pauseSubscription, unpauseSubscription } from "Shared/store/actions/expense/expenseActions"
import { getCardDataForSubscription } from "Shared/utils/creditCard.utils"
import { isFullyProcessedSubscription, isSubscriptionRenewingSoon } from "Shared/utils/expense.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"
import { parseHtml } from "Shared/utils/htmlParser.utils"

import ExpenseCreditCardSection from "../expenses/expenseDetails/expenseCreditCardSection"

const SubscriptionData = React.lazy(() => import("./subscriptionData"))
const CommentContainer = React.lazy(() => import("Components/comment/comment.container"))

const { confirm } = Modal

const SubscriptionDetails: React.FC = () => {
    const history = useHistory()
    const dispatch: ThunkDispatchResult = useDispatch()
    const { id } = useParams<{ id: string }>()
    const { t } = useTranslation()
    const [isCreateCardModalVisible, setIsCreateCardModalVisible] = useState(false)
    const [isCreateRecurringExpenseModalVisible, setIsCreateRecurringExpenseModalVisible] = useState(false)
    const [isExpenseModalShowing, setIsExpenseModalShowing] = useState<boolean>(false)
    const [cardPrefillData, setCardPrefillData] = useStateIfMounted<any>(undefined)
    const loggedInUser = useLoggedInEmployeeProfile()
    const expense = useExpense()
    const vendor = useVendorById(expense.vendor?._id)
    const costCenter = useCostCenterById(expense.costCenter)
    const costCenter2 = useCostCenter2ById(expense.costCenter2)
    const isMyOwnSubscription = expense.requestedBy?._id === loggedInUser.id
    const isSubscriptionOfMyCostCenter = costCenter?.responsibleUser === loggedInUser.id
    const isSubscriptionOfMyTeam = expense.requestedBy ? UserService.isInMyTeam(expense.requestedBy?._id) : false
    const cardsActive = useAreCardsActivated()
    const { card, isLoadingCard } = useCardByExpense(expense)
    const splitHasCostCenterData = expense.splits?.some((s) => !!s.costCenter)
    const canUpdateSubscription = AuthzService.canUpdateSubscription(isMyOwnSubscription, isSubscriptionOfMyTeam, isSubscriptionOfMyCostCenter)
    const requestedBy = EmployeeService.getEmployeeById(expense.requestedBy?._id)
    const isRequesterInCardProgramm = requestedBy.isEnrolledInCardProgramOfCompany({ companyId: loggedInUser.activeCompanyProfile.companyId })
    const rootUser = useCardRootUser()

    useEffect(() => {
        dispatch(fetchOneExpense(id))
            .then((fetchedExpense) => {
                if (!isFullyProcessedSubscription(fetchedExpense)) history.push("/subscriptions")
            })
            .catch(() => {
                history.push("/subscriptions")
            })
        return () => {}
    }, [id])

    const pauseOrUnpauseSubscription = (event: SyntheticEvent) => {
        event.stopPropagation()
        if (!expense.isPaused) {
            dispatch(pauseSubscription(expense.id))
                .then(() => {
                    dispatch(fetchOneExpense(id))
                    NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:request.paused.title"), t("notification:request.paused.message"))
                })
                .catch((err) => NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:request.edit.title")))
        } else {
            dispatch(unpauseSubscription(expense.id))
                .then(() => {
                    dispatch(fetchOneExpense(id))
                    NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:request.unpaused.title"), t("notification:request.unpaused.message"))
                })
                .catch((err) => NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:request.edit.title")))
        }
    }

    const handleDelete = () => {
        confirm({
            title: t("confirm:subscription.delete.title"),
            content: t("confirm:subscription.delete.message"),
            cancelText: t("confirm:subscription.delete.cancel"),
            type: "warning",
            okText: t("confirm:subscription.delete.confirm"),
            onOk() {
                deleteExpense(expense.id)(dispatch)
                    .then(() =>
                        NotificationService.send(NotificationTypeEnum.WARNING, t("notification:subscription.deleted.title"), t("notification:subscription.deleted.message")),
                    )
                    .then(() => history.replace("/subscriptions"))
                    .catch(() => NotificationService.send(NotificationTypeEnum.ERROR, t("error:subscription.delete.title"), t("error:subscription.delete.message")))
            },
        })
    }

    const onIssueCard = async (e: any, cardExpense: Expense) => {
        e.stopPropagation()

        if (cardExpense.paymentFlowInformation && !(await DialogService.confirmPaymentFlowInformationResetOnCardIssue())) return

        setCardPrefillData(getCardDataForSubscription(cardExpense))
        setIsCreateCardModalVisible(true)
    }

    const manuallyCreateRecurringExpenseButton = canUpdateSubscription && (
        <>
            <CreateRecurringExpenseModal isVisible={isCreateRecurringExpenseModalVisible} onCancel={setIsCreateRecurringExpenseModalVisible} parentSubscription={expense} />
            <Button icon={<FileIcon />} onClick={() => setIsCreateRecurringExpenseModalVisible(true)}>
                {t("action:request.generate_request")}
            </Button>
        </>
    )

    const canIssueCard =
        AuthzService.isRightGrantedForLoggedInUser(RightEnum.CARD__ALL__WRITE) ||
        (AuthzService.isRightGrantedForLoggedInUser(RightEnum.CARD__TEAM__WRITE) && (isMyOwnSubscription || isSubscriptionOfMyCostCenter || isSubscriptionOfMyTeam))

    return !expense || (expense && expense.id !== id) ? (
        <Loading />
    ) : (
        <div>
            <Helmet>
                <title>{expense.name}</title>
            </Helmet>

            {expense.deleted && (
                <Alert
                    className="mb-20"
                    message={t("notification:subscription.already_deleted.title")}
                    type="error"
                    description={t("notification:subscription.already_deleted.message")}
                    closable={false}
                    showIcon={true}
                />
            )}

            <PageCustomHeader
                title={
                    <div className="flex flex-1 gap-10">
                        <div>{vendor && <VendorImage size="large" vendor={vendor} />}</div>
                        <div className="flex flex-col flex-1 truncate">
                            <div className="flex flex-1">
                                <h1 className="mb-3 truncate">{expense.name}</h1>
                                {isSubscriptionRenewingSoon(expense.dateCancellationDue) && <Badge className="ml-8 h-20 ant-badge-warning">{t("label:renews_soon")}</Badge>}
                            </div>
                            <p className="leading-tight truncate max-h-200">{parseHtml(expense.description)}</p>
                        </div>
                    </div>
                }
                actionButtons={
                    canUpdateSubscription && (
                        <>
                            <div className="btn-wrapper">
                                <ApprovalProcessIndicatorMenu approvalProcesses={expense.approvalProcesses} buttonClassName="m-0"></ApprovalProcessIndicatorMenu>
                                <Button className="ml-10 btn-default" onClick={pauseOrUnpauseSubscription}>
                                    {expense.isPaused ? (
                                        <>
                                            <ResumeIcon />
                                            <span>{t("action:request.unpause")}</span>
                                        </>
                                    ) : (
                                        <>
                                            <PausedIcon />
                                            <span>{t("action:request.pause")}</span>
                                        </>
                                    )}
                                </Button>

                                <Button onClick={handleDelete} className="ml-10 btn-default">
                                    <CancelIcon />
                                    <span>{t("action:delete")}</span>
                                </Button>

                                <Button className="ml-10 btn-default" onClick={() => setIsExpenseModalShowing(true)}>
                                    <EditIcon />
                                    <span>{t("action:edit")}</span>
                                </Button>
                            </div>
                            <ExpenseCreateFormModal
                                kind={expense.kind}
                                type={ExpenseRequestTypeEnum.PURCHASE}
                                hideExpenseKindRadioOption={true}
                                isPreApprovedSubscription={true}
                                isNew={false}
                                isShowing={isExpenseModalShowing}
                                onCancel={() => setIsExpenseModalShowing(false)}
                            />
                            <CreateCardModal
                                isVisible={isCreateCardModalVisible}
                                hideCardType
                                prefilledData={cardPrefillData}
                                onCancel={() => setIsCreateCardModalVisible(false)}
                            />
                        </>
                    )
                }
            />
            <br />
            {expense.isPaused && (
                <Alert
                    className="mb-20"
                    message={t("notification:request.paused_info.title")}
                    type="info"
                    description={t("notification:request.paused_info.message")}
                    closable={false}
                    showIcon={true}
                />
            )}

            <Row className="flex flex-col-reverse md:flex-row" gutter={[16, 16]}>
                <Col sm={24} lg={14}>
                    {(costCenter || splitHasCostCenterData) && (
                        <Suspense fallback={<Loading />}>
                            <SubscriptionData
                                expense={expense}
                                vendor={vendor}
                                costCenter={costCenter}
                                costCenter2={costCenter2}
                                loggedInUser={loggedInUser}
                                requestedBy={requestedBy}
                            />
                        </Suspense>
                    )}

                    <div className="mt-40">
                        <GroupedExpenses groupedByField="parentSubscription" groupedById={expense.id} showPending actionButton={manuallyCreateRecurringExpenseButton} />
                    </div>
                </Col>
                <Col sm={24} lg={10} className="mt-32 sm:mt-0 overflow-hidden">
                    <Suspense fallback={<Loading />}>
                        {cardsActive && (
                            <div className="text-center">
                                {isLoadingCard ? (
                                    <Loading />
                                ) : card ? (
                                    <div className="min-w-340">
                                        <ExpenseCreditCardSection expense={expense} />
                                    </div>
                                ) : (
                                    canIssueCard && (
                                        <Card>
                                            <div className="border-1 border-dashed border-finway-blue-accent-light rounded-lg md:w-340 mx-auto">
                                                <Empty
                                                    image="./icons/blue_card.svg"
                                                    imageStyle={{
                                                        height: 58,
                                                        marginRight: "auto",
                                                        marginLeft: "auto",
                                                        marginTop: "10%",
                                                        marginBottom: "20px",
                                                        display: "inline-block",
                                                    }}
                                                    description={
                                                        <Button disabled={!isRequesterInCardProgramm} className="btn-highlight-blue mb-30" onClick={(e) => onIssueCard(e, expense)}>
                                                            <CreditCardIcon /> {t("action:cards.issue_card")}
                                                        </Button>
                                                    }
                                                />
                                            </div>

                                            <div className="mx-auto mt-50 md:w-340">
                                                {isRequesterInCardProgramm ? (
                                                    <>{t("label:cards.subscriptions.switch_to_finway_cards")}</>
                                                ) : (
                                                    <Alert
                                                        className="text-left"
                                                        showIcon={true}
                                                        type="warning"
                                                        message={`${t("label:cards.subscriptions.issue_card_requester_not_enrolled")}${
                                                            rootUser
                                                                ? ` ${t("label:cards.subscriptions.issue_card_requester_not_enrolled_please_contact", {
                                                                      name: rootUser?.getFullName(),
                                                                  })}`
                                                                : ""
                                                        }`}
                                                    />
                                                )}
                                            </div>
                                        </Card>
                                    )
                                )}
                            </div>
                        )}
                        <div className={`hidden md:block z-40 h-full ${cardPrefillData && (card || canIssueCard) ? "mt-40" : ""}`}>
                            <CommentContainer type={CollectionNameEnum.EXPENSE} comments={expense.comments} loggedInUser={loggedInUser} object={expense} />
                        </div>
                    </Suspense>
                </Col>
            </Row>
            <Row>
                <Col xs={24}>
                    <div className={`md:hidden z-40 h-full ${cardPrefillData && (card || canIssueCard) ? "mt-40" : ""}`}>
                        <CommentContainer type={CollectionNameEnum.EXPENSE} comments={expense.comments} loggedInUser={loggedInUser} object={expense} />
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default SubscriptionDetails
