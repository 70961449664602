import { TEN_BILLION } from "@finway-group/shared/lib/consts"
import { Employee } from "@finway-group/shared/lib/models"
import { textMultipleWhiteSpace, textNoWhiteSpacesRegex } from "@finway-group/shared/lib/utils/validators"
import { Rule } from "antd/lib/form"
import { FormInstance } from "antd/lib/form/Form"
import i18n from "i18next"
import { isValidPhoneNumber } from "react-phone-number-input"

import { parseCurrencyInput } from "Components/currencyInput/config"
import { countInArray, doesContainForbiddenChars, isNumeric, isValidIBAN } from "Shared/utils/helper.utils"
import validateEmail from "Shared/validators/email.validator"

import { allowlistCharValidator } from "./utils.rules"

export const EmployeeRules = (
    possibleApprovers: Array<Employee>,
    formInstance: FormInstance,
    fallbackApproverId?: string,
    employee?: Employee,
): { [key: string]: Array<Rule> } => ({
    firstName: [
        { required: true, message: i18n.t("validation:required"), validateTrigger: ["blur", "onChange"] },
        {
            validator: (_rule: Rule, value: string) => {
                if (doesContainForbiddenChars(value) && value !== "") {
                    return Promise.reject(i18n.t("validation:name"))
                }

                if (value !== value.trim()) {
                    return Promise.reject(new Error(i18n.t("validation:string_white_space_start_or_end")))
                }

                if (textMultipleWhiteSpace.test(value)) {
                    return Promise.reject(new Error(i18n.t("validation:string_white_space_duplicate")))
                }

                return Promise.resolve()
            },
        },
        allowlistCharValidator,
    ],
    lastName: [
        { required: true, message: i18n.t("validation:required"), validateTrigger: ["blur", "onChange"] },
        {
            validator: (_rule: Rule, value: string) => {
                if (doesContainForbiddenChars(value) && value !== "") {
                    return Promise.reject(i18n.t("validation:name"))
                }

                if (value !== value.trim()) {
                    return Promise.reject(new Error(i18n.t("validation:string_white_space_start_or_end")))
                }

                if (textMultipleWhiteSpace.test(value)) {
                    return Promise.reject(new Error(i18n.t("validation:string_white_space_duplicate")))
                }

                return Promise.resolve()
            },
        },
        allowlistCharValidator,
    ],
    email: [
        { required: true, message: i18n.t("validation:required"), validateTrigger: ["blur", "onChange"] },
        {
            validator: (_rule: any, value: string) => {
                if (value !== value.trim()) {
                    return Promise.reject(new Error(i18n.t("validation:string_white_space_start_or_end")))
                }

                if (!textNoWhiteSpacesRegex.test(value)) {
                    return Promise.reject(new Error(i18n.t("validation:string_white_space")))
                }

                if (!validateEmail(value) && value) {
                    return Promise.reject(new Error(i18n.t("validation:email")))
                }

                return Promise.resolve()
            },
        },
    ],
    workPhone: [
        {
            validator: (_rule: Rule, value: string) => {
                if (value && !isValidPhoneNumber(value) && value !== "") {
                    return Promise.reject(i18n.t("validation:phone"))
                }

                return Promise.resolve()
            },
        },
    ],
    team: [
        {
            required: true,
            message: i18n.t("validation:required"),
        },
    ],
    creditorNumber: [
        {
            validator: (_rule: any, value: string) => {
                // DATEV limit
                if (value) {
                    if (!isNumeric(value)) {
                        return Promise.reject(i18n.t("validation:numeric"))
                    }

                    if (value.length > 0 && value.substr(0, 1) === "0") {
                        return Promise.reject(i18n.t("validation:no_leading_zero"))
                    }

                    // DATEV limit
                    if (value.length < 5) {
                        return Promise.reject(i18n.t("validation:string_too_short"))
                    }
                    if (value.length > 9) {
                        return Promise.reject(i18n.t("validation:string_too_long"))
                    }

                    if (!textNoWhiteSpacesRegex.test(value)) {
                        return Promise.reject(new Error(i18n.t("validation:string_white_space")))
                    }
                }

                return Promise.resolve()
            },
        },
    ],
    dateOfBirth: [{ type: "date", required: true, message: i18n.t("validation:required"), validateTrigger: ["blur", "onChange"] }],
    hiredDate: [{ type: "date", required: true, message: i18n.t("validation:required"), validateTrigger: ["blur", "onChange"] }],
    limit: [
        {
            validator: (_rule: any, inputValue: string) => {
                let value = 0
                inputValue = inputValue ? inputValue.toString() : ""
                if (inputValue !== "") {
                    value = parseCurrencyInput(inputValue)
                    if (value <= 0) {
                        return Promise.reject(new Error(i18n.t("validation:greater.number_short", { min: 0 })))
                    }
                    if (
                        countInArray(
                            formInstance.getFieldValue("approvals").map((approval: any) => parseCurrencyInput(approval.limit)),
                            value,
                        ) >= 2
                    ) {
                        return Promise.reject(i18n.t("validation:duplicate_approval_limit"))
                    }
                    return Promise.resolve()
                }

                return Promise.reject(new Error(i18n.t("validation:greater.number_short", { min: 0 })))
            },
        },
    ],
    approver: [
        {
            validator: (_rule: Rule, value: string) => {
                if (!value || value === "") {
                    return Promise.reject(new Error(i18n.t("validation:required")))
                }

                if (!possibleApprovers.map((approver: Employee) => approver.id).includes(value)) {
                    return Promise.reject(new Error(i18n.t("validation:limit_approver")))
                }

                if (
                    countInArray(
                        formInstance.getFieldValue("approvals").map((approval: any) => approval.approver),
                        value,
                    ) >= 2
                ) {
                    return Promise.reject(i18n.t("validation:duplicate_limit_approver"))
                }

                return Promise.resolve()
            },
        },
    ],
    mileageRate: [
        {
            validator: (_rule: Rule, value: string) => {
                const number = Number(value)
                if (value && isNaN(number)) {
                    return Promise.reject(i18n.t("validation:number"))
                }
                if (number < 0 || number > TEN_BILLION) return Promise.reject(i18n.t("validation:numeric_min_max", { min: 0, max: "10B" }))
                return Promise.resolve()
            },
        },
    ],
    iban: [
        {
            validator: (_rule: any, value: string) => {
                if (value && value !== "" && !isValidIBAN(value)) {
                    return Promise.reject(new Error(i18n.t("validation:iban")))
                }

                return Promise.resolve()
            },
        },
    ],
})
