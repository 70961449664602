import { FormInstance, Rule } from "antd/lib/form"
import { Moment } from "moment-timezone"

import { checkDueInDays, checkMaxDate, checkMaxNumber, checkMinDate, checkMinNumber } from "./utils.rules"

export const generateRules = (formInstance: FormInstance): { [key: string]: Array<Rule> } => ({
    minAmount: [
        {
            validator: (_rule: any, value: number | string) => checkMinNumber({ formInstance, value, name: "maxAmount" }),
        },
    ],
    maxAmount: [
        {
            validator: (_rule: any, value: number | string) => checkMaxNumber({ formInstance, value, name: "minAmount" }),
        },
    ],
    minDatePurchased: [
        {
            validator: (_rule: any, value: Moment) => checkMinDate(formInstance, value, "maxDatePurchased"),
        },
    ],
    maxDatePurchased: [
        {
            validator: (_rule: any, value: Moment) => checkMaxDate(formInstance, value, "minDatePurchased"),
        },
    ],
    minDueDate: [
        {
            validator: (_rule: any, value: Moment) => checkMinDate(formInstance, value, "maxDueDate"),
        },
    ],
    maxDueDate: [
        {
            validator: (_rule: any, value: Moment) => checkMaxDate(formInstance, value, "minDueDate"),
        },
    ],
    minInvoiceDueDate: [
        {
            validator: (_rule: any, value: Moment) => checkMinDate(formInstance, value, "maxInvoiceDueDate"),
        },
    ],
    maxInvoiceDueDate: [
        {
            validator: (_rule: any, value: Moment) => checkMaxDate(formInstance, value, "minInvoiceDueDate"),
        },
    ],
    minProbability: [
        {
            validator: (_rule: any, value: number | string) =>
                checkMinNumber({
                    formInstance,
                    value,
                    name: "maxProbability",
                    minText: "input:filter_form.min_probability",
                    maxText: "input:filter_form.max_probability",
                    limit: 100,
                }),
        },
    ],
    maxProbability: [
        {
            validator: (_rule: any, value: number | string) =>
                checkMaxNumber({
                    formInstance,
                    value,
                    name: "minProbability",
                    minText: "input:filter_form.min_probability",
                    maxText: "input:filter_form.max_probability",
                    limit: 100,
                }),
        },
    ],
    dueInDays: [
        {
            validator: (_rule: any, value: number | string) => checkDueInDays(value),
        },
    ],
})
