import { Button } from "antd"
import React from "react"
import { useCookies } from "react-cookie"
import { useTranslation } from "react-i18next"

import { useAuth } from "Shared/hooks/auth.hooks"
import { NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"

const TroubleShootingSettings = () => {
    const { t } = useTranslation()

    const { logout } = useAuth()

    const [, , removeCookie] = useCookies()

    const onTroubleshoot = async () => {
        removeCookie("refreshToken")

        NotificationService.send(
            NotificationTypeEnum.SUCCESS,
            t("notification:settings.troubleshooting.cleared.title"),
            t("notification:settings.troubleshooting.cleared.message"),
            10,
        )

        logout()
    }

    return (
        <div id="finway-troubleshooting-settings">
            <div className="mt-10">
                <ul className="description-list">
                    <li>
                        <span className="title w-180">{t("label:troubleshooting")}</span>
                        <span className="text text-left"></span>
                        <span onClick={onTroubleshoot} className="text text-left">
                            <Button style={{ whiteSpace: "normal", height: "100%" }} className="btn-default">
                                {t("action:troubleshooting.clear_cache_and_cookies")}
                            </Button>
                        </span>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default TroubleShootingSettings
