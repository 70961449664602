import { CsvTemplate } from "@finway-group/shared/lib/models"
import axios from "axios"

const MODEL = "csv-templates"

const CsvTemplateService = {
    createTemplate: async (template: CsvTemplate) => {
        const { data } = await axios.post(MODEL, template)
        return data as CsvTemplate
    },

    fetchAllTemplates: async (page = 1, limit = 200) => {
        const {
            data: { docs },
        } = await axios.get(`${MODEL}/?page=${page}&limit=${limit}`)

        return docs.map((item: any) => item as CsvTemplate)
    },

    updateTemplate: async (template: CsvTemplate) => {
        const { data } = await axios.put(`${MODEL}/${template._id}`, template)
        return data as CsvTemplate
    },

    deleteTemplate: async (id: string) => axios.delete(`${MODEL}/${id}`),
}
export default CsvTemplateService
