import { PlusCircleOutlined } from "@ant-design/icons"
import { CsvTemplate, CsvTemplateSelectedField, HeaderFieldsDict } from "@finway-group/shared/lib/models"
import { Button, Col, Input, Radio, Row, Select } from "antd"
import Form, { FormInstance } from "antd/lib/form"
import { Store } from "antd/lib/form/interface"
import React from "react"
import { useTranslation } from "react-i18next"

import { TrashIcon } from "Components/icons"
import SelectedCsvHeadersTable from "Components/tables/selectedCsvHeadersTable"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"
import { getPopupAnchor } from "Shared/utils/popup.utils"

import CsvTemplateRules from "./rules/csvTemplates.rules"

const EMPTY_TEMPLATE_ID = ""
const FORM_INSTANCE_TABLE_NAME = "csvHeadersTable"
const FORM_INSTANCE_FIELD_DROPDOWN = "selectedHeaderField"
const FORM_INSTANCE_TEMPLATE_NAME_DROPDOWN = "selectedTemp"

interface CsvTemplateFormInterface {
    formInstance: FormInstance
    templates: Array<CsvTemplate>
    onSubmit: (values: Store, isEditMode: boolean, id?: string) => void
    onDelete: (id: string) => void
}

const CsvTemplateForm: React.FC<CsvTemplateFormInterface> = ({ formInstance, templates, onSubmit, onDelete }) => {
    const { t } = useTranslation()
    const initialHeader: Array<CsvTemplateSelectedField> = []
    const rules = CsvTemplateRules(templates)
    const headerFieldsFromDict = Object.entries(HeaderFieldsDict).map((k, v) => ({ label: k[1], value: k[0], key: v }))
    const existingTemplates = templates.map((obj) => ({ label: obj.name, value: obj._id! }))
    const onFinish = (values: Store) => onSubmit({ ...values }, editMode, templateIdToEdit)
    const [editMode, setEditMode] = useStateIfMounted(false)
    const [fieldSelected, setFieldSelected] = useStateIfMounted(false)
    const [unselectedHeaderFields, setUnselectedHeaderFields] = useStateIfMounted([...headerFieldsFromDict])
    const [templateIdToEdit, setTemplateIdToEdit] = useStateIfMounted(EMPTY_TEMPLATE_ID)
    const onChange = (e: any) => {
        setEditMode(e.target.value)
        if (!formInstance.getFieldValue(FORM_INSTANCE_TEMPLATE_NAME_DROPDOWN)) resetTable()
    }

    const addHeader = () => {
        const nameOfField = formInstance.getFieldValue(FORM_INSTANCE_FIELD_DROPDOWN)
        const headerToAdd = unselectedHeaderFields.find((obj) => obj.value === nameOfField)!
        const selectedHeaders: Array<CsvTemplateSelectedField> = [...formInstance.getFieldValue(FORM_INSTANCE_TABLE_NAME), headerToAdd]
        formInstance.setFieldsValue({ csvHeadersTable: selectedHeaders })
        const unselectedHeaders = headerFieldsFromDict.filter(({ value }) => !selectedHeaders.some((item) => item.value === value))
        setUnselectedHeaderFields(unselectedHeaders)
        setFieldSelected(false)
        formInstance.setFieldsValue({ selectedHeaderField: undefined })
    }
    const updateHeaders = (updatedArr: Array<CsvTemplateSelectedField>): void => {
        formInstance.setFieldsValue({ csvHeadersTable: updatedArr })
    }

    const deleteHeader = (deletedHeader: CsvTemplateSelectedField) => {
        const selectedHeaders: Array<CsvTemplateSelectedField> = formInstance.getFieldValue(FORM_INSTANCE_TABLE_NAME)
        const index = selectedHeaders.indexOf(deletedHeader)
        selectedHeaders.splice(index, 1)
        const unselectedHeaders = headerFieldsFromDict.filter(({ value }) => !selectedHeaders.some((item) => item.value === value))
        setUnselectedHeaderFields(unselectedHeaders)
        formInstance.setFieldsValue({ csvHeadersTable: [...selectedHeaders] })
    }

    const prefillHeadersTable = (id: string) => {
        const prefillTemplate = templates.find((obj) => obj._id === id)!
        formInstance.setFieldsValue({ nameUpdate: prefillTemplate.name })
        setTemplateIdToEdit(prefillTemplate._id!)
        const selectedHeaders = prefillTemplate.headers.map((obj, index) => ({ label: HeaderFieldsDict[obj], value: obj, key: index } as CsvTemplateSelectedField))
        const unselectedHeaders = headerFieldsFromDict.filter(({ value }) => !selectedHeaders.some((item) => item.value === value))
        formInstance.setFieldsValue({ csvHeadersTable: selectedHeaders })
        setUnselectedHeaderFields(unselectedHeaders)
    }

    const resetTable = () => {
        setUnselectedHeaderFields(headerFieldsFromDict)
        setTemplateIdToEdit(EMPTY_TEMPLATE_ID)
        formInstance.setFieldsValue({
            csvHeadersTable: [],
            nameUpdate: null,
            selectedHeaderField: null,
        })
    }

    return (
        <>
            <Row justify="center">
                <Radio.Group onChange={onChange} defaultValue={editMode} size="middle">
                    <Radio.Button value={true}>{t("label:csv_settings:edit")}</Radio.Button>
                    <Radio.Button value={false}>{t("label:csv_settings:new")}</Radio.Button>
                </Radio.Group>
            </Row>
            <Form form={formInstance} initialValues={{ csvHeadersTable: initialHeader }} layout="vertical" style={{ marginTop: "25px" }} onFinish={onFinish} autoComplete="off">
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        {editMode ? (
                            <>
                                <Row gutter={[16, 16]}>
                                    <Col span={21}>
                                        <Form.Item label={t("input:csv_settings:edit_template_name")} rules={rules.name} name={FORM_INSTANCE_TEMPLATE_NAME_DROPDOWN}>
                                            <Select onClear={resetTable} onSelect={prefillHeadersTable} options={existingTemplates} allowClear></Select>
                                        </Form.Item>
                                    </Col>
                                    <Col className="mt-28" span={3}>
                                        <Button disabled={templateIdToEdit === ""}>
                                            <TrashIcon
                                                onClick={() => {
                                                    onDelete(templateIdToEdit)
                                                    resetTable
                                                }}
                                            />
                                        </Button>
                                    </Col>
                                </Row>
                                <Form.Item label={t("input:csv_settings:rename_template")} name="nameUpdate">
                                    <Input placeholder={t("placeholder:csv_settings:new_template_name")} required />
                                </Form.Item>
                            </>
                        ) : (
                            <>
                                <Form.Item label={t("input:csv_settings:new_template_name")} rules={rules.name} name="name">
                                    <Input placeholder={t("placeholder:csv_settings:new_template_name")} required />
                                </Form.Item>
                                <Form.Item label={t("input:csv_settings:select_template_name")} name={FORM_INSTANCE_TEMPLATE_NAME_DROPDOWN}>
                                    <Select
                                        onClear={resetTable}
                                        getPopupContainer={getPopupAnchor()}
                                        onSelect={prefillHeadersTable}
                                        options={existingTemplates}
                                        allowClear
                                    ></Select>
                                </Form.Item>
                            </>
                        )}
                        <Form.Item label={t("input:csv_settings:field_individualisation")} name={FORM_INSTANCE_TABLE_NAME} valuePropName="data" rules={rules.csvHeadersTable}>
                            <SelectedCsvHeadersTable onChangeArray={updateHeaders} onDeleteItem={deleteHeader}></SelectedCsvHeadersTable>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col span={21}>
                        <Form.Item label={t("input:csv_settings:field_add")} name={FORM_INSTANCE_FIELD_DROPDOWN}>
                            <Select
                                getPopupContainer={getPopupAnchor()}
                                disabled={editMode && templateIdToEdit === ""}
                                showSearch
                                options={unselectedHeaderFields}
                                onSelect={() => setFieldSelected(true)}
                            ></Select>
                        </Form.Item>
                    </Col>
                    <Col className="mt-30" span={3}>
                        <Button disabled={!fieldSelected} onClick={addHeader}>
                            <PlusCircleOutlined />
                        </Button>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default CsvTemplateForm
