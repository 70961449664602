import { SsoProviderTypeEnum } from "@finway-group/shared/lib/models"
import { Modal } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"

import AzureActiveDirectoryLogo from "Components/icons/logos/azureActiveDirectory.logo"
import GoogleLogo from "Components/icons/logos/google.logo"
import SsoProviderContainer from "Components/ssoProviderContainer"
import { useSsoConfigurations } from "Shared/queries/sso.queries"

type SsoProviderListModalProps = {
    isShowing: boolean
    onCancel: () => void
}

const SsoProviderListModal = ({ isShowing, onCancel }: SsoProviderListModalProps) => {
    const { t } = useTranslation()

    const { data } = useSsoConfigurations()

    /**
     * IDEA: We could restrict available options based on tenant tier
     */
    const ssoProviderData = [
        {
            icon: <GoogleLogo className="w-28" />,
            type: SsoProviderTypeEnum.GOOGLE,
            title: t("label:sso.providers.google.title"),
            description: t("label:sso.providers.google.desc"),
            isAvailable: true,
        },
        {
            icon: <AzureActiveDirectoryLogo className="w-28" />,
            type: SsoProviderTypeEnum.AZURE_AD,
            title: t("label:sso.providers.azure_ad.title"),
            description: t("label:sso.providers.azure_ad.desc"),
            isAvailable: true,
        },
    ]

    return (
        <Modal className="ant-modal--small" visible={isShowing} maskClosable={false} title={t("label:sso.title")} onCancel={onCancel} footer={null} destroyOnClose forceRender>
            <p>{t("label:sso.text")}</p>

            <div className="flex flex-col gap-10 pt-10 pb-20">
                {ssoProviderData.map((provider) => (
                    <SsoProviderContainer key={provider.title} {...provider} connection={data?.find(({ type }) => type === provider.type)} />
                ))}
            </div>
        </Modal>
    )
}

export default React.memo(SsoProviderListModal)
