import { ExclamationCircleOutlined } from "@ant-design/icons"
import { CardDeliveryMethodEnum, CardModeEnum, CardTypeEnum, CardWindowEnum, CurrencyEnum, Employee, ExpenseKindEnum } from "@finway-group/shared/lib/models"
import { CardBinTypeEnum } from "@finway-group/shared/lib/models/card/cardBinType.enum"
import { SubscriptionRenewalGenerationTypeEnum } from "@finway-group/shared/lib/models/expense/subscriptionRenewalGenerationType.enum"
import { Alert, Col, Form, Input, Radio, Row, Select, Switch, Tooltip } from "antd"
import { FormInstance } from "antd/lib/form/Form"
import { Store } from "antd/lib/form/interface"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import UserImage from "Components/UserImage"
import CreditCard from "Components/creditCard/creditCard"
import CurrencyInput from "Components/currencyInput"
import { parseCurrencyInput } from "Components/currencyInput/config"
import FormItemCurrency from "Components/form/formItemCurrency"
import { CreditCardRules } from "Components/forms/rules"
import { useWallet } from "Shared/hooks/corporate.hooks"
import { useEmployees } from "Shared/hooks/employee.hooks"
import { AuthzService, CurrencyService } from "Shared/services"
import { cardWindowArray, getInfoTextForExpenseCard, getLabelForWindow, getWindowForExpenseCard } from "Shared/utils/creditCard.utils"
import { doFilterSelect } from "Shared/utils/helper.utils"
import { getPopupAnchor } from "Shared/utils/popup.utils"

const { Option } = Select

export type CardPrefillData = {
    cardHolder?: string
    expense?: {
        _id: string
        kind: ExpenseKindEnum
    }
    limit?: number
    currency: CurrencyEnum
    nameOnCard?: string
    window?: CardWindowEnum
    cardName?: string
}

interface CreateCardFormInterface {
    formInstance: FormInstance
    hideCardType?: boolean
    prefilledData?: CardPrefillData
    onSubmit: (values: Store) => void
}

const CreateCardFrom = ({ formInstance, hideCardType = false, prefilledData, onSubmit }: CreateCardFormInterface) => {
    const { t } = useTranslation()
    const employees = useEmployees({ excludeDeleted: true })
    const eligibleEmployees = employees.filter((employee) => AuthzService.canManageCardForUser(employee))
    const wallet = useWallet()
    const [cardHolder, setCardHolder] = useState(prefilledData?.cardHolder || eligibleEmployees[0]?.id)
    const [cardType, setCardType] = useState(CardTypeEnum.VIRTUAL)
    const [cardMode, setCardMode] = useState(CardModeEnum.DEBIT_MODE)
    const rules = CreditCardRules()
    const isSubscriptionCard = prefilledData?.expense?.kind === ExpenseKindEnum.SUBSCRIPTION
    const isPhysicalCard = cardType === CardTypeEnum.PHYSICAL

    const onFinish = (values: Store) => {
        values.limit = parseCurrencyInput(values.limit)
        values.currency = wallet.currency
        onSubmit(values)
    }

    useEffect(() => {
        const limit = prefilledData?.limit ? CurrencyService.convert(prefilledData?.limit, prefilledData?.currency, wallet.currency) : 0

        // initial field set
        formInstance.setFieldsValue({
            cardHolder: prefilledData?.cardHolder || eligibleEmployees[0]?.id,
            nameOnCard: prefilledData?.nameOnCard || "",
            cardType: CardTypeEnum.VIRTUAL,
            limit,
            currency: wallet.currency,
            cardName: prefilledData?.cardName || "",
            window: prefilledData?.window || CardWindowEnum.MONTH,
            mode: CardModeEnum.DEBIT_MODE,
            cardDeliveryMethod: CardDeliveryMethodEnum.STANDARD_DELIVERY,
            shouldUpdateExpenseAmountOnCardTransaction: true,
        })
    }, [])

    useEffect(() => {
        const cardHolderObject = eligibleEmployees.find((emp: Employee) => emp.id === cardHolder)
        if (cardHolderObject) {
            formInstance.setFieldsValue({
                nameOnCard: `${cardHolderObject.firstName} ${cardHolderObject.lastName}`,
            })
            formInstance.validateFields(["nameOnCard"])
        }
    }, [cardHolder])

    return (
        <Form form={formInstance} layout="vertical" onFinish={onFinish}>
            <Row gutter={[20, 16]}>
                <Col span={24}>
                    <Form.Item name="cardType" hidden={hideCardType}>
                        <div className="card-modal">
                            <Radio.Group defaultValue={CardTypeEnum.VIRTUAL} onChange={(e) => setCardType(e.target.value)}>
                                <Radio.Button value={CardTypeEnum.VIRTUAL}>{t("creditCard:type.virtual")}</Radio.Button>
                                <Radio.Button value={CardTypeEnum.PHYSICAL}>{t("creditCard:type.physical")}</Radio.Button>
                            </Radio.Group>
                        </div>
                    </Form.Item>
                    <Form.Item>
                        <div className="flex items-center mb-10">
                            <CreditCard card={{ user: cardHolder, cardType, binType: CardBinTypeEnum.GERMAN_BIN }} cardInfo={{ holderName: cardHolder, cardType }} skipDemoTag />
                        </div>
                    </Form.Item>
                    {isPhysicalCard && <Alert message={t("info:cards.physical_card_costs")} type="info" showIcon className="mb-20" />}
                    <Form.Item
                        label={t("creditCard:label.card_holder")}
                        name="cardHolder"
                        extra={prefilledData?.cardHolder && getInfoTextForExpenseCard(prefilledData?.expense)}
                        rules={rules.cardHolder}
                    >
                        <Select
                            showSearch
                            filterOption={doFilterSelect}
                            disabled={!!prefilledData?.cardHolder}
                            onChange={(value) => setCardHolder(value?.toString())}
                            getPopupContainer={getPopupAnchor()}
                        >
                            {eligibleEmployees.map((employee: Employee) => (
                                <Option key={employee.id} label={`${employee.firstName} ${employee.lastName}`} value={employee.id}>
                                    <div className="flex items-center">
                                        <UserImage user={employee} />
                                        <span>{`${employee.firstName} ${employee.lastName}`}</span>
                                    </div>
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={24} sm="24" md="24">
                    <Form.Item
                        className="w-full"
                        name="nameOnCard"
                        label={t("input:card.name_on_card")}
                        required
                        rules={rules.nameOnCard}
                        extra={t("input:card.cannot_be_changed_after_creation")}
                    >
                        <Input placeholder={t("creditCard:placeholder.name_on_card")} />
                    </Form.Item>
                </Col>
                <Col span={24} sm="24" md="24">
                    <Form.Item name="mode" label={t("input:card.mode.mode")} required>
                        <Select className="w-full" getPopupContainer={getPopupAnchor()} onChange={(v: any) => setCardMode(v)}>
                            <Select.Option value={CardModeEnum.DEBIT_MODE}>{t(`input:card.mode.${CardModeEnum.DEBIT_MODE.toLowerCase()}`)}</Select.Option>
                            <Select.Option value={CardModeEnum.PREPAID_MODE}>{t(`input:card.mode.${CardModeEnum.PREPAID_MODE.toLowerCase()}`)}</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
                {isSubscriptionCard && (
                    <Col span={24} sm="24" md="24">
                        <Form.Item
                            label={t("input:card.renewal_generation_for_sub_card")}
                            name="renewalGenerationType"
                            initialValue={SubscriptionRenewalGenerationTypeEnum.BY_TRANSACTION}
                            required
                        >
                            <Select className="w-full" getPopupContainer={getPopupAnchor()}>
                                <Select.Option value={SubscriptionRenewalGenerationTypeEnum.BY_DATE}>{t("input:request.renewal_generation.by_date")}</Select.Option>
                                <Select.Option value={SubscriptionRenewalGenerationTypeEnum.BY_TRANSACTION}>{t("input:request.renewal_generation.by_transaction")}</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                )}
                <Col span={24} sm="24" md="24">
                    <Form.Item className="w-full" name="cardName" label={t("creditCard:label.card_name")} rules={rules.cardName} required>
                        <Input placeholder={t("creditCard:placeholder.card_name")} />
                    </Form.Item>
                </Col>
                <Col span={12} sm="24" md="24">
                    <FormItemCurrency currency={wallet.currency} className="w-full" name="limit" label={t("creditCard:label.limit")} rules={rules.limit} required>
                        <CurrencyInput currency={wallet.currency} value={0} />
                    </FormItemCurrency>
                </Col>
                <Col span={12} sm="24" md="24">
                    <Form.Item
                        name="window"
                        label={t("creditCard:label.frequency")}
                        extra={prefilledData?.window && getInfoTextForExpenseCard(prefilledData?.expense)}
                        rules={rules.window}
                    >
                        <Select className="w-full" disabled={!!prefilledData?.window} getPopupContainer={getPopupAnchor()}>
                            {prefilledData?.window ? (
                                <Select.Option value={prefilledData?.window}>{getWindowForExpenseCard(prefilledData?.expense)}</Select.Option>
                            ) : (
                                cardWindowArray
                                    // phyiscal and debit cards should not allow one-time windows
                                    .filter((window) => {
                                        if (isPhysicalCard || cardMode === CardModeEnum.DEBIT_MODE) return window.value !== CardWindowEnum.ONE_TIME
                                        return true
                                    })
                                    .map((window, index) => (
                                        <Select.Option key={index} value={window.value}>
                                            {getLabelForWindow(window.value)}
                                        </Select.Option>
                                    ))
                            )}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        name="shouldUpdateExpenseAmountOnCardTransaction"
                        label={
                            <span className="title w-240">
                                {t("input:card.should_update_expense_amount_on_card_transaction")}
                                <Tooltip title={t("info:cards.update_expense_amount_on_card_transaction")} placement="right" className="align-middle">
                                    <ExclamationCircleOutlined className="ml-6" />
                                </Tooltip>
                            </span>
                        }
                        valuePropName="checked"
                    >
                        <Switch />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    )
}

export default CreateCardFrom
