import { Expense, FilterPresetEnum, RightEnum, TabTypeEnum } from "@finway-group/shared/lib/models"
import { Tabs, Tooltip } from "antd"
import React, { Suspense, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useDebouncedCallback } from "use-debounce/lib"

import { FilterSectionOptionsInterface } from "Components/filter/filterTypes"
import FilterSection from "Components/filterSection"
import Loading from "Components/loading"
import PageTitle from "Components/page/pageTitle"
import { useSelectedExpenses } from "Shared/context/selectedExpenses.context"
import { useCostCenters2 } from "Shared/hooks/costCenter2.hooks"
import { useCostCenters } from "Shared/hooks/costCenter.hooks"
import { useEmployees } from "Shared/hooks/employee.hooks"
import { useActiveTab, useExpenseCounts, useInboxInvoicesCount, useRequestActionDisabled } from "Shared/hooks/expense.hooks"
import { useExpenseAccounts } from "Shared/hooks/expenseAccount.hooks"
import { useExpenseTags } from "Shared/hooks/expenseTags.hooks"
import { applyFilter } from "Shared/hooks/filter.hooks"
import { getTableFilterQueryStringStore, useTable, useTableFilterObject, useTableSearchString } from "Shared/hooks/table.hooks"
import { useCreditors } from "Shared/hooks/vendor.hooks"
import { AuthzService } from "Shared/services"
import { fetchArchivedExpensesCounts, fetchDashboardExpensesCounts, setActiveTab as setActiveTabAction } from "Shared/store/actions/expense/expenseActions"
import { ExpenseCountInterface } from "Shared/store/actions/expense/expenseTypes"
import { updateDashboardSearch, updateTableSearch } from "Shared/store/actions/tables/tableActions"
import { TablesEnum } from "Shared/store/reducers/tableConfigReducer"

import useStateIfMounted from "../../../shared/utils/hooks/useStateIfMounted"
import ExpenseActionButton from "./expenseActionButton"
import InProgressTab from "./tabs/inProgressTab"

const InboxInvoiceTab = React.lazy(() => import("./tabs/inboxInvoicesTab"))
const DoneOrAllTab = React.lazy(() => import("./tabs/doneOrAllTab"))
const ReviewTab = React.lazy(() => import("./tabs/ReviewTab"))
const TodoTab = React.lazy(() => import("./tabs/todoTab"))
const ToPayAndExportTab = React.lazy(() => import("./tabs/ToPayAndExportTab"))
const ArchiveTab = React.lazy(() => import("./tabs/archiveTab"))

const { TabPane } = Tabs

const ExpenseContainer: React.FC = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const activeTab = useActiveTab()
    const creditors = useCreditors(AuthzService.isRightGrantedForLoggedInUser(RightEnum.EMPLOYEE__ALL__READ), true, true)
    const costCenters = useCostCenters(true)
    const employees = useEmployees()
    const expenseTags = useExpenseTags()
    const costCenters2 = useCostCenters2()
    const expenseAccounts = useExpenseAccounts()
    const filterSetRef = useRef(false)
    const filterObject = useTableFilterObject(TablesEnum.TODO_INVOICE_APPROVAL)

    const [selectedExpenses, setSelectedExpenses] = useSelectedExpenses()
    const [filterOptions, setFilterOptions] = useState<FilterSectionOptionsInterface>({})
    const [tableEnum, setTableEnum] = useState(TablesEnum.TODO_DOCS_NEEDED)
    const isGlobalApprover = AuthzService.isLoggedInUserGlobalApprover()
    const hasReadRightForExpensesToPay = AuthzService.canLoggedInUserSeeExpensesToPay()
    const hasReadRightForExpensesToExport = AuthzService.canLoggedInUserSeeExpensesToExport()
    const hasReadRightForExpensesToReview = AuthzService.canLoggedInUserSeeExpensesToReview()

    // Loading for the entire dashboard. Awaits that the document counts are updated to render the tabs
    const [dashboardLoading, setDashboardLoading] = useStateIfMounted(false)

    const dashboardExpenseCounts = useExpenseCounts()

    const requestActionDisabled = useRequestActionDisabled()

    const inboxInvoicesCount = useInboxInvoicesCount()

    const [actionBtnState, setActionBtnState] = useStateIfMounted({
        enableToDoRequestCreation: !requestActionDisabled,
        enableToDoExport: !requestActionDisabled,
        enableReviewed: false,
        enablePayExport: false,
    })

    useEffect(() => {
        const updated = { expenseTags, costCenters, employees, creditors, costCenters2, expenseAccounts }
        if (JSON.stringify(updated) !== JSON.stringify(filterOptions)) setFilterOptions({ expenseTags, costCenters, employees, creditors, costCenters2, expenseAccounts })
    }, [expenseTags, costCenters, employees, creditors, costCenters2, expenseAccounts])

    useEffect(() => {
        setTableEnum(
            activeTab === TabTypeEnum.ARCHIVE
                ? TablesEnum.ARCHIVE
                : activeTab === TabTypeEnum.DONE
                ? TablesEnum.DONE_REQUESTS
                : activeTab === TabTypeEnum.INBOX_INVOICES
                ? TablesEnum.INBOX_INVOICE
                : activeTab === TabTypeEnum.IN_PROGRESS
                ? TablesEnum.IN_PROGRESS
                : activeTab === TabTypeEnum.PAY_EXPORT
                ? TablesEnum.PAY_AND_EXPORT_TO_BE_EXPORTED
                : activeTab === TabTypeEnum.REVIEW
                ? TablesEnum.REVIEW_REQUESTS
                : TablesEnum.TODO_DOCS_NEEDED,
        )
    }, [activeTab])

    const dashboardSearchString = useTableSearchString(tableEnum)
    const archiveTable = useTable<Expense>(TablesEnum.ARCHIVE)

    useEffect(() => {
        setActionBtnState({
            enableToDoRequestCreation: actionBtnState.enableToDoRequestCreation,
            enableToDoExport: actionBtnState.enablePayExport,
            enableReviewed: !!selectedExpenses.length,
            enablePayExport: !!selectedExpenses.length,
        })
    }, [selectedExpenses.length])

    // Use effect to make sure that background changes with loggedInUser/vendors/costCenters/employees affects the request button properly
    useEffect(() => {
        setActionBtnState({
            ...actionBtnState,
            enableToDoRequestCreation: !requestActionDisabled,
        })
    }, [requestActionDisabled])

    const handleTabClick = (key: string, _event: React.KeyboardEvent | React.MouseEvent) => {
        setSelectedExpenses([])
        if (key !== activeTab) {
            dispatch(setActiveTabAction(key))
        }
    }

    const handleDashboardSearch = useDebouncedCallback(async (searchString) => {
        setDashboardLoading(true)
        if (activeTab === TabTypeEnum.ARCHIVE) {
            dispatch(updateTableSearch(TablesEnum.ARCHIVE, searchString))
            dispatch(fetchArchivedExpensesCounts())
        } else {
            await dispatch(updateDashboardSearch(searchString))
        }
        setDashboardLoading(false)
    }, 800)

    const getDataCount = (tableEnum: TabTypeEnum, data: ExpenseCountInterface) => {
        if (tableEnum === TabTypeEnum.IN_PROGRESS) return data.inProgress
        if (tableEnum === TabTypeEnum.PAY_EXPORT) return data.payExport
        if (tableEnum === TabTypeEnum.ARCHIVE) return data.archive
        if (tableEnum === TabTypeEnum.REVIEW) return data.review
        if (tableEnum === TabTypeEnum.TO_DOS) return data.toDo
        if (tableEnum === TabTypeEnum.DONE) return data.done
        if (tableEnum === TabTypeEnum.ALL) return data.allRequests
        if (tableEnum === TabTypeEnum.MY_TO_DOS) return data.toDo
        return 0
    }

    const isMyTodo = getTableFilterQueryStringStore(TablesEnum.TODO_INVOICE_APPROVAL).includes(FilterPresetEnum.MY_TODO)

    useEffect(() => {
        if (filterSetRef.current === false && Object.keys(filterObject).length > 0) {
            dispatch(fetchDashboardExpensesCounts())
            filterSetRef.current = true
        }
    }, [JSON.stringify(filterObject)])

    return (
        <div>
            <PageTitle titleKey={"view:dashboard.title"}>
                <Tooltip title="test">
                    <ExpenseActionButton
                        table={tableEnum}
                        state={actionBtnState}
                        searchString={dashboardSearchString}
                        onSearch={handleDashboardSearch}
                        dataCount={getDataCount(activeTab, dashboardExpenseCounts)}
                        onFilter={(data) => {
                            setSelectedExpenses([])
                            return applyFilter(tableEnum, data)
                        }}
                        options={filterOptions}
                    />
                </Tooltip>
            </PageTitle>
            <div className={dashboardLoading ? "block" : "hidden"}>
                <Loading />
            </div>
            <div className={dashboardLoading ? "hidden" : "block"}>
                <Tabs
                    id="dashboard-tabs"
                    destroyInactiveTabPane={true}
                    defaultActiveKey={TabTypeEnum.TO_DOS}
                    activeKey={activeTab}
                    onTabClick={handleTabClick}
                    className="animation-appear pb-10 flex flex-col"
                >
                    <TabPane tab={`${t("label:invoice_inbox")} | ${inboxInvoicesCount}`} key={TabTypeEnum.INBOX_INVOICES}>
                        <Suspense fallback={<Loading />}>
                            <InboxInvoiceTab />
                        </Suspense>
                    </TabPane>

                    <TabPane
                        tab={`${t("label:to_dos")} | ${
                            isMyTodo ? getDataCount(TabTypeEnum.MY_TO_DOS, dashboardExpenseCounts) : getDataCount(TabTypeEnum.TO_DOS, dashboardExpenseCounts)
                        }`}
                        key={TabTypeEnum.TO_DOS}
                    >
                        <FilterSection table={TablesEnum.TODO_DOCS_NEEDED} options={filterOptions} />

                        <Suspense fallback={<Loading />}>
                            <TodoTab />
                        </Suspense>
                    </TabPane>

                    {!isGlobalApprover && (
                        <TabPane tab={`${t("label:in_progress")} | ${getDataCount(TabTypeEnum.IN_PROGRESS, dashboardExpenseCounts)}`} key={TabTypeEnum.IN_PROGRESS}>
                            <FilterSection table={TablesEnum.IN_PROGRESS} options={filterOptions} />

                            <Suspense fallback={<Loading />}>
                                <InProgressTab />
                            </Suspense>
                        </TabPane>
                    )}

                    {hasReadRightForExpensesToReview && (
                        <TabPane tab={`${t("label:review")} | ${getDataCount(TabTypeEnum.REVIEW, dashboardExpenseCounts)}`} key={TabTypeEnum.REVIEW}>
                            <FilterSection table={TablesEnum.REVIEW_REQUESTS} options={filterOptions} />

                            <Suspense fallback={<Loading />}>
                                <ReviewTab />
                            </Suspense>
                        </TabPane>
                    )}
                    {(hasReadRightForExpensesToPay || hasReadRightForExpensesToExport) && (
                        <TabPane tab={`${t("label:pay_and_export")} | ${getDataCount(TabTypeEnum.PAY_EXPORT, dashboardExpenseCounts)}`} key={TabTypeEnum.PAY_EXPORT}>
                            <FilterSection table={TablesEnum.PAY_AND_EXPORT_TO_BE_EXPORTED} options={filterOptions} />

                            <Suspense fallback={<Loading />}>
                                <ToPayAndExportTab />
                            </Suspense>
                        </TabPane>
                    )}

                    <TabPane tab={`${t("label:done")} | ${getDataCount(TabTypeEnum.DONE, dashboardExpenseCounts)}`} key={TabTypeEnum.DONE}>
                        <FilterSection table={TablesEnum.DONE_REQUESTS} options={filterOptions} />

                        <Suspense fallback={<Loading />}>
                            <DoneOrAllTab tablesEnum={TablesEnum.DONE_REQUESTS} />
                        </Suspense>
                    </TabPane>

                    {isGlobalApprover && (
                        <TabPane tab={`${t("label:all")} | ${getDataCount(TabTypeEnum.ALL, dashboardExpenseCounts)}`} key={TabTypeEnum.ALL}>
                            <FilterSection table={TablesEnum.ALL_REQUESTS} options={filterOptions} />

                            <Suspense fallback={<Loading />}>
                                <DoneOrAllTab tablesEnum={TablesEnum.ALL_REQUESTS} />
                            </Suspense>
                        </TabPane>
                    )}

                    <TabPane
                        tab={
                            getTableFilterQueryStringStore(TablesEnum.ARCHIVE) && !archiveTable.isFetching
                                ? `${t("label:archive")} | ${getDataCount(TabTypeEnum.ARCHIVE, dashboardExpenseCounts)}`
                                : t("label:archive")
                        }
                        key={TabTypeEnum.ARCHIVE}
                    >
                        <FilterSection table={TablesEnum.ARCHIVE} options={{ ...filterOptions, activeTab: TabTypeEnum.ARCHIVE }} />

                        <Suspense fallback={<Loading />}>
                            <ArchiveTab />
                        </Suspense>
                    </TabPane>
                </Tabs>
            </div>
        </div>
    )
}

export default ExpenseContainer
