import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons"
import { COLOR } from "@finway-group/shared/lib/consts"
import { LiquidityCashFlow, PriceIntervalEnum } from "@finway-group/shared/lib/models"
import { roundNumberTo2Decimals } from "@finway-group/shared/lib/utils"
import { Card, Col, Divider, Row } from "antd"
import moment from "moment"
import React from "react"
import { useTranslation } from "react-i18next"

import Loading from "Components/loading"
import PriceLabel from "Components/priceLabel"
import { CashFlowDirectionEnum } from "Features/pages/liquidity/liquidity.types"
import { useLoggedInEmployeeProfile } from "Shared/hooks/user.hooks"
import { findCurrentMonthCashFlow, getInflowValue } from "Shared/utils/liquidity.utils"

import SkeletonCard from "./skeletonCard"

interface CashFlowBalanceCardInterface {
    cashFlowData: Array<LiquidityCashFlow>
    cashFlowDirection: CashFlowDirectionEnum
    isTableLoading: boolean
    showSkeleton: boolean
    height: number
}

const CashFlowCumulatedCard: React.FC<CashFlowBalanceCardInterface> = ({ cashFlowData, cashFlowDirection, isTableLoading, showSkeleton, height }) => {
    const { t } = useTranslation()
    const currentEmployee = useLoggedInEmployeeProfile()

    if (showSkeleton) return <SkeletonCard title={t("label:liquidity.cash_balance")} height={height} />

    if (!cashFlowData || cashFlowData.length === 0 || isTableLoading)
        return (
            <Card>
                <Loading margin={false} />
            </Card>
        )

    const { globalCurrency } = currentEmployee.settings

    const monthlyValue = getInflowValue(cashFlowData, moment().startOf("month"), moment().endOf("month"), cashFlowDirection)
    // TODO: To be discussed to let the user to select fiscal year, so the yearly value will be the start of that fiscal year - next year.
    const yearlyValue = getInflowValue(cashFlowData, moment().startOf("year"), moment().endOf("year"), cashFlowDirection)

    const currentMonthCashFlow = findCurrentMonthCashFlow(cashFlowData)!

    const amountDiff = currentMonthCashFlow[cashFlowDirection].actual - currentMonthCashFlow[cashFlowDirection].forecast
    const amountDiffPercentage =
        currentMonthCashFlow[cashFlowDirection].forecast !== 0 ? roundNumberTo2Decimals((amountDiff / Math.abs(currentMonthCashFlow[cashFlowDirection].forecast)) * 100) : 0

    const yearlyAmountDiff = yearlyValue.actual - yearlyValue.forecast
    const yearlyAmountDiffPercentage = yearlyValue.forecast !== 0 ? roundNumberTo2Decimals((yearlyAmountDiff / Math.abs(yearlyValue.forecast)) * 100) : 0

    const generatePercentDisplay = (value: number) => {
        const caret = value > 0 ? <CaretUpOutlined /> : value < 0 ? <CaretDownOutlined /> : <></>
        const sign = value > 0 ? "+" : ""
        const color = value > 0 ? COLOR.finway.green : value < 0 ? COLOR.finway.red2 : COLOR.gray[500]
        return (
            <>
                <span style={{ color }}>
                    {sign}
                    {value.toFixed(0)}%
                </span>
                <span style={{ color }} className="mb-4">
                    {caret}
                </span>
            </>
        )
    }

    return (
        <Card className={`h-${height} finway-dark-text`}>
            <Row>
                <Col span={24}>
                    <p className="text-base text-xl font-semibold">{t("label:liquidity.cashflow_cumulated")}</p>
                </Col>
            </Row>
            <Row className="mt-8">
                <Col span={24}>
                    <Row>
                        <Col span="12">
                            <p className="text-base">{t("label:liquidity.this_month")}</p>
                        </Col>
                        <Col span={12}>
                            <div className="mt-16">{generatePercentDisplay(amountDiffPercentage)}</div>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={12}>
                            <p className="xl:text-xl lg:text-sm xs:text-xl cashflow-summary">
                                <PriceLabel currency={globalCurrency} value={monthlyValue.forecast} interval={PriceIntervalEnum.ONE_TIME} />
                            </p>
                        </Col>
                        <Col span={12}>
                            <p className="xl:text-xl lg:text-sm xs:text-xl cashflow-summary">
                                <PriceLabel currency={globalCurrency} value={monthlyValue.actual} interval={PriceIntervalEnum.ONE_TIME} />
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <p>{t("label:liquidity.plan")}</p>
                        </Col>
                        <Col span={12}>
                            <p>{t("label:liquidity.actual")}</p>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="mt-32">
                <Col span={24}>
                    <Divider className="my-0" />
                    <div className="flex justify-between finway-gray-text">
                        <p>{t("label:liquidity.month_over_month")}</p>
                        <p>
                            <PriceLabel currency={globalCurrency} value={amountDiff} interval={PriceIntervalEnum.ONE_TIME} />
                        </p>
                        <p>
                            {amountDiffPercentage >= 0 ? "+" : ""}
                            {amountDiffPercentage}%
                        </p>
                    </div>
                    <Divider className="my-0" />
                </Col>
            </Row>
            <Row className="mt-10 mb-2">
                <Col span={24}>
                    <Row>
                        <Col span={12}>
                            <p className="text-base primary">{t("label:liquidity.this_year")}</p>
                        </Col>
                        <Col span={12}>
                            <div className="mt-16">{generatePercentDisplay(yearlyAmountDiffPercentage)}</div>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={12}>
                            <p className="xl:text-xl lg:text-sm xs:text-xl cashflow-summary">
                                <PriceLabel currency={globalCurrency} value={yearlyValue.forecast} interval={PriceIntervalEnum.ONE_TIME} />
                            </p>
                        </Col>
                        <Col span={12}>
                            <p className="xl:text-xl lg:text-sm xs:text-xl cashflow-summary">
                                <PriceLabel currency={globalCurrency} value={yearlyValue.actual} interval={PriceIntervalEnum.ONE_TIME} />
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <p>{t("label:liquidity.plan")}</p>
                        </Col>
                        <Col span={12}>
                            <p>{t("label:liquidity.actual")}</p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Card>
    )
}

export default CashFlowCumulatedCard
