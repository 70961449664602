import { Activity, CollectionNameEnum, Employee, Expense, OperationTypeEnum } from "@finway-group/shared/lib/models"
import React, { useCallback, useEffect } from "react"
import { useTranslation } from "react-i18next"

import { useCompanies } from "Shared/hooks/company.hooks"
import { useEmployeeById } from "Shared/hooks/employee.hooks"
import { isFolderExpense, isFullyProcessedSubscription } from "Shared/utils/expense.utils"
import { formatCurrencyNumber } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"
import {
    BaseOperationNotificationType,
    CombinedInfo,
    formulateBankConnectionNotification,
    formulateBaseOperationNotification,
    formulateExpenseNotification,
    formulateFolderNotification,
    formulateImportResultNotification,
    formulateSubscriptionNotification,
} from "Shared/utils/navbarActivityTemplates.utils"

import { BaseActivityCard, CardSystemActivityCard, SystemActivityCard } from "./templates"

interface ActivityCardInterface {
    activity: Activity
    loggedInUser: Employee
}
const ActivityCard = ({ activity, loggedInUser }: ActivityCardInterface) => {
    const { createdBy, collectionName, fullData, documentId, operationType, created, viewedBy, toNotify, companyId, isCombined, combinedDependencies, metaData } = activity as any

    const { t } = useTranslation()

    const [description, setDescription] = useStateIfMounted<any>(undefined)
    const [systemActivityLink, setSystemActivityLink] = useStateIfMounted("")

    const employee = useEmployeeById(createdBy) || new Employee()
    const companies = useCompanies()

    const getCompanyName = useCallback(() => companies.filter(({ _id }) => companyId === _id)?.[0]?.name, [companies])

    const isNotViewed = viewedBy.indexOf(loggedInUser.id) < 0

    const getActivityDescription = () => {
        let baseOperationObject: BaseOperationNotificationType
        const combinedInfo: CombinedInfo = { isCombined, combinedDependencies }
        const multiple_suffix = combinedInfo.isCombined ? "_multiple" : ""
        const combinedLength = (combinedInfo.combinedDependencies?.length ?? 0) + 1

        switch (collectionName) {
            case CollectionNameEnum.EXPENSE:
                const expense = new Expense(fullData)
                if (documentId === createdBy) return t(`activity:expense.system_generated${multiple_suffix}`, { num: expense.expenseNumber, combinedLength })
                if (isFullyProcessedSubscription(expense)) {
                    return formulateSubscriptionNotification(operationType, expense, loggedInUser, toNotify, combinedInfo, metaData)
                }
                if (isFolderExpense(expense)) {
                    return formulateFolderNotification(operationType, expense, loggedInUser, toNotify, combinedInfo, metaData)
                }
                return formulateExpenseNotification(operationType, expense, loggedInUser, toNotify, combinedInfo, metaData)
            case CollectionNameEnum.VENDOR:
                baseOperationObject = { operationType, type: "vendor", value: fullData.name, loggedInEmployee: loggedInUser, toNotify }
                break
            case CollectionNameEnum.COST_CENTER:
                baseOperationObject = { operationType, type: fullData.costCenterType, value: fullData.name, loggedInEmployee: loggedInUser, toNotify }
                break
            case CollectionNameEnum.CARD:
                if (operationType === OperationTypeEnum.EXPENSE_CARD_TRANSACTION) {
                    const convertedExpense = new Expense(fullData)
                    return t("activity:expense.system_generated", { num: convertedExpense.expenseNumber })
                }

                if (operationType === OperationTypeEnum.CARD_REQUEST_LIMIT_CHANGE) {
                    return t("activity:creditCard.limit_change_request", {
                        cardName: fullData?.cardName,
                        currentLimit: formatCurrencyNumber(fullData?.limit, fullData?.currency),
                        requestedLimit: formatCurrencyNumber(fullData?.limitChangeRequest?.limit, fullData.currency),
                    })
                }

                const forUser = fullData.user === loggedInUser.id ? t("label:custom.for_you") : t("label:custom.for_user", { userName: fullData?.cardholderName })
                baseOperationObject = {
                    operationType,
                    type: "creditCard",
                    value: `**** ${fullData.last4Digits}`,
                    loggedInEmployee: loggedInUser,
                    arg: t(`label:cards.${fullData?.cardType?.toLowerCase()}`),
                    details: forUser,
                }
                break
            case CollectionNameEnum.WALLET:
                setSystemActivityLink("/credit-cards/wallet-details")
                return t("activity:wallet.update")
            case CollectionNameEnum.INBOX_INVOICE:
                baseOperationObject = { operationType, type: "inboxInvoice", loggedInEmployee: loggedInUser, value: fullData.fileName }
                break
            case CollectionNameEnum.BANK_CONNECTION:
                return formulateBankConnectionNotification(operationType, new Expense(fullData), loggedInUser.settings.showGrossAmount, combinedInfo)
            case CollectionNameEnum.USER:
                baseOperationObject = { operationType, type: "user", value: getCompanyName(), loggedInEmployee: loggedInUser }
                break
            case CollectionNameEnum.WORKFLOW:
                baseOperationObject = { operationType, type: "workflow", value: fullData.name, loggedInEmployee: loggedInUser }
                break
            case CollectionNameEnum.TEAM:
                baseOperationObject = { operationType, type: "team", loggedInEmployee: loggedInUser }
                break
            case CollectionNameEnum.COST_CENTER_2:
                baseOperationObject = { operationType, type: "costCenter2", loggedInEmployee: loggedInUser }
                break
            case CollectionNameEnum.TAX:
                baseOperationObject = { operationType, type: "tax", loggedInEmployee: loggedInUser }
                break
            case CollectionNameEnum.EXPENSE_ACCOUNT:
                baseOperationObject = { operationType, type: "expenseAccount", loggedInEmployee: loggedInUser }
                break
            case CollectionNameEnum.IMPORT_RESULT:
                return formulateImportResultNotification()
            default:
                return ""
        }

        return baseOperationObject ? formulateBaseOperationNotification(baseOperationObject, combinedInfo) : ""
    }

    useEffect(() => {
        setDescription(getActivityDescription())
    }, [loggedInUser.settings.showExpenseNumbers, companies])

    /**
     * Since currency exchange activity is blocked by the api, this will never be visible
     * needs to implement a cool design for a currency widget
     */
    const currencyExchangeWidgetCard = <h1>Soon</h1>

    const templateFetcher = () => {
        switch (collectionName) {
            /** keept it as case for custom made templates in the future for credit cards */
            case CollectionNameEnum.CURRENCY_EXCHANGE:
                return currencyExchangeWidgetCard
            default:
                if (documentId === createdBy) return <SystemActivityCard created={created} isNotViewed={isNotViewed} description={description} link={systemActivityLink} />
                if (operationType === OperationTypeEnum.EXPENSE_CARD_TRANSACTION)
                    return (
                        <CardSystemActivityCard
                            key={createdBy}
                            created={created}
                            cardId={createdBy}
                            isNotViewed={isNotViewed}
                            description={description}
                            link={systemActivityLink}
                        />
                    )
                if (documentId !== createdBy && employee.id) return <BaseActivityCard employee={employee} created={created} isNotViewed={isNotViewed} description={description} />
                return <SystemActivityCard created={created} isNotViewed={isNotViewed} description={description} />
        }
    }

    return (
        <div className="flex-1 overflow-hidden">
            <div className="flex image-loader">{templateFetcher()}</div>
        </div>
    )
}

export default React.memo(ActivityCard)
