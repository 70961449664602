import { DatevContextInterface } from "@finway-group/shared/lib/models"
import { isDatevOnlineConnected } from "@finway-group/shared/lib/models/company/datev/datevOnlineConnectionStatus.enum"
import { Form, Modal } from "antd"
import React, { memo, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import DatevClientAndConsultantForm from "Components/forms/datev/datevClientAndConsultant.form"
import { useCompany } from "Shared/hooks/company.hooks"
import { CompanyService, NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { setCompany } from "Shared/store/actions/company/companyActions"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

import { ModalType } from "../modal.types"

export type DatevClientAndConsultantFormDataType = Pick<DatevContextInterface, "clientNumber" | "consultantNumber">

export const DatevClientAndConsultantModal = ({ isShowing, onCancel }: ModalType) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const company = useCompany()

    const [formInstance] = Form.useForm<DatevClientAndConsultantFormDataType>()
    const [isLoading, setIsLoading] = useStateIfMounted(false)

    const isDUOConnected = isDatevOnlineConnected(company?.datevSettings?.datevContext?.status)

    const onSubmit = async (formValues: DatevClientAndConsultantFormDataType) => {
        setIsLoading(true)

        try {
            const updatedCompany = await CompanyService.updateDatevClientAndConsultantInfo(company?._id, formValues)
            dispatch(setCompany(updatedCompany))

            NotificationService.send(
                NotificationTypeEnum.SUCCESS,
                t("notification:settings.company.datev_client_and_consultant_info.title"),
                t("notification:settings.company.datev_client_and_consultant_info.message"),
            )
            onHide()
        } catch (error) {
            NotificationService.showErrorNotificationBasedOnResponseError(error, t("error:error"))
        } finally {
            setIsLoading(false)
        }
    }

    const onHide = () => {
        setIsLoading(false)
        formInstance.resetFields()
        onCancel()
    }
    return (
        <Modal
            title={t("label:datev_client_and_consultant.title")}
            visible={isShowing}
            maskClosable={false}
            onOk={formInstance.submit}
            okButtonProps={{ disabled: isDUOConnected }}
            onCancel={onHide}
            okText={t("action:company_settings.save")}
            cancelText={t("action:cancel")}
            confirmLoading={isLoading}
            keyboard
        >
            <DatevClientAndConsultantForm isFormDisabled={isDUOConnected} formInstance={formInstance} onSubmit={onSubmit} />
        </Modal>
    )
}
