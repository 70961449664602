import { FormInstance, Rule } from "antd/lib/form"
import moment from "moment"

import i18n from "Shared/locales/i18n"
import { getBudgetPeriodString } from "Shared/utils/costCenter.utils"

const MAX_DESKTOP_PERIODS = 13
const MAX_MOBILE_PERIODS = 7

export const CostCenterDateRangePickerRules = (formInstance: FormInstance, isOnMobile: boolean): { [key: string]: Array<Rule> } => ({
    startDate: [
        {
            validator: (_rule: any, startDate: moment.Moment) => {
                if (!startDate) return Promise.reject(i18n.t("validation:required"))

                const endDate = formInstance.getFieldValue("endDate")
                if (!endDate) return

                if (startDate.isAfter(endDate)) {
                    return Promise.reject(i18n.t("validation:date_before", { attribute: i18n.t("validation:start_date"), compare: i18n.t("validation:end_date") }))
                }

                const viewPeriod = getBudgetPeriodString(formInstance.getFieldValue("viewPeriod"))
                const maxPeriods = isOnMobile ? MAX_MOBILE_PERIODS : MAX_DESKTOP_PERIODS
                if (startDate.isBefore(endDate.clone().subtract(maxPeriods, viewPeriod).startOf(viewPeriod))) {
                    return Promise.reject(i18n.t(`validation:date_range_max_${viewPeriod}s`, { max: maxPeriods }))
                }
                return Promise.resolve()
            },
        },
    ],
    endDate: [
        {
            validator: (_rule: any, endDate: moment.Moment) => {
                if (!endDate) return Promise.reject(i18n.t("validation:required"))

                const startDate = formInstance.getFieldValue("startDate")
                if (!startDate) return

                if (endDate.isBefore(startDate)) {
                    return Promise.reject(i18n.t("validation:date_after", { attribute: i18n.t("validation:end_date"), compare: i18n.t("validation:start_date") }))
                }

                const viewPeriod = getBudgetPeriodString(formInstance.getFieldValue("viewPeriod"))
                const maxPeriods = isOnMobile ? MAX_MOBILE_PERIODS : MAX_DESKTOP_PERIODS
                if (endDate.isAfter(startDate.clone().add(maxPeriods, viewPeriod).startOf(viewPeriod))) {
                    return Promise.reject(i18n.t(`validation:date_range_max_${viewPeriod}s`, { max: maxPeriods }))
                }

                return Promise.resolve()
            },
        },
    ],
})
