import React from "react"
import { Redirect, Route, RouteProps } from "react-router-dom"

import FullScreen from "Components/layout/fullScreen"
import { LocalStorageService } from "Shared/services"

// Imports of shared modules

export const FullScreenRoute: React.FC<RouteProps> = (props) => (
    <Route {...props}>
        <FullScreen>{props.children}</FullScreen>
    </Route>
)

export const PrivateFullScreen: React.FC<RouteProps> = (props) =>
    LocalStorageService.isAuthorized() ? (
        <Route {...props}>
            <FullScreen>{props.children}</FullScreen>
        </Route>
    ) : (
        <Redirect to={{ pathname: "/login", state: { from: props.path } }} />
    )
