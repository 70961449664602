import { Button, Col, Form, Input, Modal, Row } from "antd"
import { Rule } from "antd/lib/form"
import { Store } from "antd/lib/form/interface"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import { useLoggedInEmployeeProfile } from "Shared/hooks/user.hooks"
import { EmployeeService, NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { syncEmployee } from "Shared/store/actions/employee/employeeActions"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

interface GetMyInvoicesModalInterface {
    isVisible: boolean
    onCancel: (state: boolean) => void
    onSubmit: (value: string) => void
}

const GetMyInvoicesModal: React.FC<GetMyInvoicesModalInterface> = ({ isVisible, onCancel, onSubmit }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [formInstance] = Form.useForm()
    const [isLoading, setIsLoading] = useStateIfMounted(false)
    const loggedInUser = useLoggedInEmployeeProfile()
    // TODO: typing
    const { getMyInvoicesUserId } = loggedInUser.activeCompanyProfile as any

    const requiredRule: Rule = { type: "string", required: true, message: t("validation:required"), validateTrigger: "blur" }

    useEffect(() => {
        if (isVisible) {
            formInstance.setFieldsValue({ getMyInvoicesUserId })
        }

        return () => {
            if (isVisible) formInstance.resetFields()
        }
    }, [isVisible])

    const onFinish = ({ getMyInvoicesUserId }: Store) => {
        setIsLoading(true)

        try {
            onSubmit(getMyInvoicesUserId)
            handleHide()
        } catch (err) {
            setIsLoading(false)
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:error"))
        }
    }

    const handleHide = () => {
        setIsLoading(false)
        formInstance.resetFields()
        onCancel(false)
    }

    return (
        <Modal
            title={t("action:integrations.get_my_invoices.set_up")}
            visible={isVisible}
            maskClosable={false}
            onCancel={handleHide}
            destroyOnClose
            onOk={formInstance.submit}
            footer={[
                <Button key="back" onClick={handleHide}>
                    {t("action:cancel")}
                </Button>,
                <Button key="submit" type="primary" loading={isLoading} onClick={() => formInstance.submit()}>
                    {t("action:integrations.get_my_invoices.save")}
                </Button>,
            ]}
        >
            <Form form={formInstance} layout="vertical" onFinish={onFinish}>
                <Row gutter={[16, 16]}>
                    <Col span={24} sm="24" md="24">
                        <Form.Item name="getMyInvoicesUserId" label={t("input:integrations.get_my_invoices.user_id")} className="w-full" rules={[requiredRule]}>
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

export default GetMyInvoicesModal
