import { LinkOutlined } from "@ant-design/icons"
import { UserTagging } from "@finway-group/shared/lib/models"
import { Tag, Tooltip } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"

import { useLoggedInEmployeeProfile } from "Shared/hooks/user.hooks"
import { EmployeeService } from "Shared/services"

interface SharedExpenseTagInterface {
    tagging?: Array<UserTagging>
}

const SharedExpenseTag: React.FC<SharedExpenseTagInterface> = ({ tagging }) => {
    const { t } = useTranslation()
    const loggedInProfile = useLoggedInEmployeeProfile()
    const taggedByUsers = [...new Set(tagging?.filter(({ tagged }) => tagged === loggedInProfile.id).map(({ taggedBy }) => taggedBy))]
    const getUserNamesAsString = (ids: Array<string>) => {
        const employees = EmployeeService.getEmployees(false)
        return ids.map((employeeId) => employees.find(({ id }) => id == employeeId)?.getFullName()).join(", ")
    }

    const tooltipDescription =
        taggedByUsers.length > 2 ? t("tooltips:shared_expense_tooltip.shared_multiple") : `${t("tooltips:shared_expense_tooltip.shared")} ${getUserNamesAsString(taggedByUsers)}.`

    return (
        <Tooltip className="ml-5" title={tooltipDescription}>
            <Tag className="ant-tag-gray inline-flex items-center">
                <LinkOutlined />
                <span className="ml-5">{t("label:shared")} </span>
            </Tag>
        </Tooltip>
    )
}
export default SharedExpenseTag
