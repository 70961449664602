import { ExpenseKindEnum, ExpenseStatusEnum, PriceIntervalEnum } from "@finway-group/shared/lib/models"
import { isNumeric } from "@finway-group/shared/lib/utils"
import { Col, Row } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"

import ExpenseConversionInfo from "Components/expenseConversionInfo"
import AlertTriangleIcon from "Components/icons/alertTriangleIcon"
import PriceLabel from "Components/priceLabel"
import { WARNING_ORANGE } from "Shared/config/consts"
import { useExpenseDetailsContext } from "Shared/context/expenseDetails.context"
import { useCardByExpense } from "Shared/hooks/creditCard.hooks"
import { getClassNameForDiscountHighlight } from "Shared/utils/expense.discount.utils"
import { getSubscriptionPriceInterval } from "Shared/utils/expense.utils"

import { useMainDetailsSectionContext } from "../mainDetailsSection.context"

export const ExpenseTotalRow = () => {
    const { isShowingDiscountForm } = useExpenseDetailsContext()
    const { discountDisplay, expense, expenseTaxPrice: currentExpenseTaxPrice, expenseNetPrice: currentExpenseNetPrice } = useMainDetailsSectionContext()
    const { t } = useTranslation()
    const expenseInterval = expense.kind !== ExpenseKindEnum.ONE_TIME_EXPENSE ? getSubscriptionPriceInterval(expense) : PriceIntervalEnum.ONE_TIME
    const { card } = useCardByExpense(expense)
    const discountDisplayClassName = getClassNameForDiscountHighlight(expense)

    // TODO - make this a table (check antd table Summary component) so it can align properly, even with the expense split detail table.
    return (
        <Row justify="end">
            <Col span={17}>
                <Row className="text-right mt-8 pl-4 text" justify="end">
                    <Col span={4}>
                        <span className="text-text-dark mr-3">{t("label:total_net_amount")}</span>
                    </Col>
                    <Col span={4}>
                        <span className="text-text-dark mr-10">
                            <PriceLabel value={currentExpenseNetPrice} currency={expense.currency} interval={expenseInterval} showFullNumber />
                        </span>
                    </Col>
                </Row>
                <Row className="flex text-right mt-2 pl-4 text" justify="end">
                    <Col span={5}>
                        <span className="mr-3"> {t("label:vat")}</span>
                    </Col>
                    <Col span={4} className="flex-grow">
                        <span className="text-text-dark mr-10">
                            <PriceLabel value={currentExpenseTaxPrice} currency={expense.currency} interval={expenseInterval} showFullNumber />
                        </span>
                    </Col>
                </Row>
                <Row className="text-right pl-4 text" justify="end">
                    {/* Safe conditional rendering see: https://stackoverflow.com/a/53048160 */}
                    {expense.totalGrossPriceApproved && expense.totalGrossPriceApproved < expense.totalGrossPrice ? (
                        <div className="flex justify-end border-l-4 rounded-sm border-orange-light bg-orange-light custom-bg-opacity-10 -mt-4 p-4">
                            <AlertTriangleIcon fill={WARNING_ORANGE} className="text-white pr-2" />
                            <span>{t("info:gross_amount_increased")} </span>
                        </div>
                    ) : null}
                    <Col span={5}>
                        <span>{t("label:total_gross_amount")}</span>
                    </Col>
                    <Col span={4}>
                        <span className="text-text-dark mr-10 font-bold">
                            <PriceLabel value={expense.totalGrossPrice} currency={expense.currency} interval={expenseInterval} showFullNumber />
                        </span>
                    </Col>
                </Row>
                {isNumeric(discountDisplay?.reduced.gross) && (isShowingDiscountForm || !!expense.discounts?.length) && (
                    <Row className="text-right mt-8 text" justify="end">
                        <Col span={8} className="flex justify-end">
                            <div className={`${discountDisplayClassName} left w-fit`}>{t("label:total_gross_amount_with_discount")}</div>
                        </Col>
                        <Col span={4}>
                            <div className={`${discountDisplayClassName} w-full font-bold right`}>
                                <PriceLabel value={discountDisplay!.reduced.gross} currency={expense.currency} interval={expenseInterval} showFullNumber />
                            </div>
                        </Col>
                    </Row>
                )}
                <Row justify="end">
                    <Col span={24}>
                        {/* if a card expense has a matching transaction then the user should see the conversion info in the transaction details page to avoid confusions since the exchange rates used by weavr are different that ours. */}
                        {expense.card && !expense.matchedTransactions.length && card?.currency && card.currency !== expense.currency && (
                            <ExpenseConversionInfo
                                className="flex justify-end text-xs pt-2 w-full"
                                value={expense.totalGrossPrice}
                                sourceCurrency={expense.currency}
                                destCurrency={card.currency}
                                showSourceAmount={false}
                                // If not paid yet, use current loaded forex.
                                exchangeId={expense.status < ExpenseStatusEnum.PAID ? undefined : (expense.currencyExchangeAtPayment as string)}
                            />
                        )}
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}
