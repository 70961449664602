import { LiquidityCashFlow, PriceIntervalEnum } from "@finway-group/shared/lib/models"
import { roundNumberTo2Decimals } from "@finway-group/shared/lib/utils"
import { Card, Col, Row } from "antd"
import moment from "moment"
import numeral from "numeral"
import React from "react"
import { useTranslation } from "react-i18next"
import { CartesianGrid, Line, LineChart, ResponsiveContainer, XAxis, YAxis } from "recharts"

import Loading from "Components/loading"
import PriceLabel from "Components/priceLabel"
import { useLoggedInEmployeeProfile } from "Shared/hooks/user.hooks"
import { findCurrentMonthCashFlow, findPreviousMonthCashFlow, getLineChartData } from "Shared/utils/liquidity.utils"

import SkeletonCard from "./skeletonCard"

interface CashBalanceChartInterface {
    cashFlowData: Array<LiquidityCashFlow>
    isTableLoading: boolean
    showSkeleton: boolean
    height: number
}

const CashBalanceChart: React.FC<CashBalanceChartInterface> = ({ cashFlowData, isTableLoading, showSkeleton, height }) => {
    const { t } = useTranslation()
    const currentEmployee = useLoggedInEmployeeProfile()

    if (showSkeleton) return <SkeletonCard title={t("label:liquidity.cash_balance")} height={height} />

    if (!cashFlowData || cashFlowData.length === 0 || isTableLoading)
        return (
            <Card>
                <Loading margin={false} />
            </Card>
        )

    const currentMonthCashFlow = findCurrentMonthCashFlow(cashFlowData)
    const previousMonthCashFlow = findPreviousMonthCashFlow(cashFlowData)
    const amountDiff =
        currentMonthCashFlow?.totalBalance.actual && previousMonthCashFlow?.totalBalance.actual
            ? currentMonthCashFlow.totalBalance.actual - previousMonthCashFlow.totalBalance.actual
            : 0
    const amountDiffPercentage = currentMonthCashFlow?.totalBalance.actual ? roundNumberTo2Decimals((amountDiff / Math.abs(currentMonthCashFlow?.totalBalance.actual)) * 100) : 0

    const { globalCurrency } = currentEmployee.settings

    return (
        <Card className={`h-${height} finway-dark-text`}>
            <Row>
                <Col span={24}>
                    <div className="flex justify-between">
                        <p className="text-base text-xl font-semibold">{t("label:liquidity.cash_balance")}</p>
                        <div className="finway-gray-text self-center xl:text-md lg:text-xs xs:text-md">{moment().format("Do MMM YYYY")}</div>
                    </div>
                </Col>
            </Row>
            <Row className="mt-16">
                <Col span={24}>
                    <div className="flex justify-center">
                        <p className="xl:text-3xl lg:text-lg xs:text-3xl cashflow-summary">
                            <PriceLabel currency={globalCurrency} interval={PriceIntervalEnum.ONE_TIME} value={currentMonthCashFlow?.finalBalance.actual ?? 0} />
                        </p>
                    </div>
                </Col>
            </Row>
            <Row className="mt-16">
                <Col span={24}>
                    <div className="w-full border-t-1" />
                    <div className="flex justify-between w-full finway-gray-text">
                        <p className="text-base">{t("label:liquidity.month_over_month")}</p>
                        <p className="text-base">
                            <PriceLabel currency={globalCurrency} value={amountDiff} interval={PriceIntervalEnum.ONE_TIME} />
                        </p>
                        <p className="text-base">
                            {amountDiffPercentage >= 0 ? "+" : ""}
                            {amountDiffPercentage}%
                        </p>
                    </div>
                    <div className="w-full border-t-1" />
                </Col>
            </Row>
            <Row className="mt-24">
                <Col span={24}>
                    <div className="w-full h-130">
                        <ResponsiveContainer width={"100%"} height={130}>
                            <LineChart data={getLineChartData(cashFlowData)[0].data} margin={{ right: 15 }}>
                                <CartesianGrid strokeDasharray="3 3" vertical={false} />
                                <XAxis dataKey="x" tickFormatter={(val) => moment(val).format("MMM YY")} />
                                <YAxis tickFormatter={(val) => numeral(val).format("0,0[.]00a")} width={35} />
                                <Line type="linear" dataKey="y" stroke="#3886FF" dot={{ fill: "#3886FF", stroke: "white", strokeWidth: 2, r: 4 }} />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                </Col>
            </Row>
        </Card>
    )
}

export default CashBalanceChart
