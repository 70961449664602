import { KYBStatusEnum, RightEnum } from "@finway-group/shared/lib/models"
import * as Sentry from "@sentry/react"

import { AuthzService } from "Shared/services"
import store from "Shared/store"
import { fetchCardOverviewData } from "Shared/store/actions/analytics/analyticsActions"
import { fetchWallet } from "Shared/store/actions/corporate/corporateActions"
import { isClientErrorCode } from "Shared/utils/errorHandler.utils"

const subscribeToWallet = (dispatch: any) => {
    const cardsActivated = store.getState().corporate.kybData.kybStatus === KYBStatusEnum.COMPLETED

    try {
        if (AuthzService.isRightGrantedForLoggedInUser(RightEnum.CARD_WALLET__ALL__READ) && cardsActivated) {
            dispatch(fetchWallet())
            dispatch(fetchCardOverviewData())
        }
    } catch (err) {
        if (isClientErrorCode(err)) return
        Sentry.captureException(`[Wallet Socket] Error: ${typeof err === "string" ? err : JSON.stringify(err)}`)
    }
}

export default subscribeToWallet
