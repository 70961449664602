import { PerDiemDailyExpenses } from "@finway-group/shared/lib/models"
import { Col, Divider, Row } from "antd"
import moment from "moment"
import React from "react"

import { useExpenseFormContext } from "Components/modals/expenseCreateForm.context"
import { PER_DIEM_BREAKFAST_RATE, PER_DIEM_DINNER_RATE, PER_DIEM_LUNCH_RATE } from "Shared/config/consts"
import { usePerDiemDestinationById, usePerDiemDestinationMap } from "Shared/hooks/perDiemDestination.hooks"

import AccommodationIcon from "../icons/accommodation.icon"
import BreakfastIcon from "../icons/breakfast.icon"
import DinnerIcon from "../icons/dinner.icon"
import LunchIcon from "../icons/lunch.icon"
import PerDiemOptionCell from "./perDiemOptionCell"

export enum PerDiemExpenditureTypeEnum {
    BREAKFAST = "breakfast",
    LUNCH = "lunch",
    DINNER = "dinner",
    ACCOMMODATION = "accommodation",
}

interface PerDiemOptionRowInterface {
    tripDailyExpenses: Array<PerDiemDailyExpenses>
    dailyExpense: PerDiemDailyExpenses
    index: number
}
export const PerDiemOptionRow: React.FC<PerDiemOptionRowInterface> = ({ tripDailyExpenses, dailyExpense, index }) => {
    const { date } = dailyExpense
    const dateMoment = moment(date)

    const [{ expenseForm, updateExpense }] = useExpenseFormContext()
    const rowState = expenseForm.getFieldValue(["dailyExpenses", index, "expenditure"])

    const destinationRate = usePerDiemDestinationById(dailyExpense.destination)!

    const onChange = (field: PerDiemExpenditureTypeEnum, checked: boolean) => {
        expenseForm.setFields([
            {
                // Setting dailyExpenses[index].expenditure[field] = checked
                name: ["dailyExpenses", index, "expenditure", field],
                value: checked,
            },
        ])
        updateExpense({ dailyExpenses: [...expenseForm.getFieldValue(["dailyExpenses"])] })
    }

    return (
        <Col span={24} className={index === tripDailyExpenses.length - 1 ? "mb-24" : ""}>
            <Row className="mb-12">
                <Col span={24}>{dateMoment.format("dddd, DD/MM/YYYY")}</Col>
            </Row>
            <Row>
                <Col span={6} className="border-x-gray-300">
                    <PerDiemOptionCell
                        fieldName={PerDiemExpenditureTypeEnum.BREAKFAST}
                        isExpenditureActive={rowState.breakfast}
                        onChange={onChange}
                        value={-((destinationRate.fullDay ?? 0) * PER_DIEM_BREAKFAST_RATE)}
                        icon={<BreakfastIcon />}
                    />
                </Col>
                <Col span={6} className="border-x-gray-300">
                    <PerDiemOptionCell
                        fieldName={PerDiemExpenditureTypeEnum.LUNCH}
                        isExpenditureActive={rowState.lunch}
                        onChange={onChange}
                        value={-((destinationRate.fullDay ?? 0) * PER_DIEM_LUNCH_RATE)}
                        icon={<LunchIcon />}
                    />
                </Col>
                <Col span={6} className="border-x-gray-300">
                    <PerDiemOptionCell
                        fieldName={PerDiemExpenditureTypeEnum.DINNER}
                        isExpenditureActive={rowState.dinner}
                        onChange={onChange}
                        value={-((destinationRate.fullDay ?? 0) * PER_DIEM_DINNER_RATE)}
                        icon={<DinnerIcon />}
                    />
                </Col>
                <Col span={6} className="border-x-gray-300">
                    {index < tripDailyExpenses.length - 1 && (
                        <PerDiemOptionCell
                            fieldName={PerDiemExpenditureTypeEnum.ACCOMMODATION}
                            isExpenditureActive={rowState.accommodation}
                            onChange={onChange}
                            value={destinationRate.accommodation ?? 0}
                            icon={<AccommodationIcon />}
                        />
                    )}
                </Col>
            </Row>
            {<Divider className={`mb-0 mt-20 ${index === tripDailyExpenses.length - 1 && "hidden"}`} />}
        </Col>
    )
}
