import { Alert, Button, Col, Form, Input, Row } from "antd"
import React from "react"

import { useAuth } from "Shared/hooks/auth.hooks"
import { NotificationService, UtilityService } from "Shared/services"

interface SimulatorDataGeneratorTabInterface {}

const SimulatorDataGeneratorTab: React.FC<SimulatorDataGeneratorTabInterface> = ({}) => {
    const { logout } = useAuth()

    const onWipeAllData = async () => {
        try {
            await UtilityService.wipeAllData()
            logout()
        } catch (err) {
            NotificationService.showErrorNotificationBasedOnResponseError(err, "Error")
        }
    }

    return (
        <>
            <Row>
                <Col span={24}>
                    <Form.Item name="wipeData" extra="This will log you out">
                        <Button onClick={onWipeAllData}>Wipe all data</Button>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <Alert
                        className="mb-10"
                        message="The button above will delete ALL data from EVERY company and will leave you only with a base company and the finway admin user. This CANNOT BE REVERSED."
                        type="warning"
                    />
                </Col>
            </Row>
            <Row>
                <Col span={6}>
                    <Form.Item name="baseEmail" label="Base email" extra="Emails will be generated like basePart1_role@basePart2">
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <Alert
                        message="This will generate sample data needed by QA on a fresh company. That includes a user for every standard role, 5 randomized vendors and 5 randomized cost centers."
                        type="info"
                    />
                </Col>
            </Row>
        </>
    )
}

export default SimulatorDataGeneratorTab
