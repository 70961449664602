import { deActivity, deEnum, deError as deSharedError, enActivity, enEnum, enError as enSharedError } from "@finway-group/shared/lib/locales"
import i18n from "i18next"
import { initReactI18next } from "react-i18next"

import deAction from "./de/action.json"
import deAppend from "./de/append.json"
import deConfirm from "./de/confirm.json"
import deConjunction from "./de/conjunction.json"
import deCorporate from "./de/corporate.json"
import deCreditCard from "./de/creditCard.json"
import deError from "./de/error.json"
import deHelp from "./de/help.json"
import deInfo from "./de/info.json"
import deInput from "./de/input.json"
import deLabel from "./de/label.json"
import deMissing from "./de/missing.json"
import deNav from "./de/nav.json"
import deNotification from "./de/notification.json"
import dePlaceholder from "./de/placeholder.json"
import deTooltips from "./de/tooltips.json"
import deValidation from "./de/validation.json"
import deView from "./de/view.json"
import enAction from "./en/action.json"
import enAppend from "./en/append.json"
import enConfirm from "./en/confirm.json"
import enConjunction from "./en/conjunction.json"
import enCorporate from "./en/corporate.json"
import enCreditCard from "./en/creditCard.json"
import enError from "./en/error.json"
import enHelp from "./en/help.json"
import enInfo from "./en/info.json"
import enInput from "./en/input.json"
import enLabel from "./en/label.json"
import enMissing from "./en/missing.json"
import enNav from "./en/nav.json"
import enNotification from "./en/notification.json"
import enPlaceholder from "./en/placeholder.json"
import envTooltips from "./en/tooltips.json"
import enValidation from "./en/validation.json"
import enView from "./en/view.json"

const resources = {
    en: {
        nav: enNav,
        action: enAction,
        validation: enValidation,
        input: enInput,
        placeholder: enPlaceholder,
        tooltips: envTooltips,
        error: { ...enError, ...enSharedError },
        view: enView,
        label: enLabel,
        append: enAppend,
        notification: enNotification,
        confirm: enConfirm,
        conjunction: enConjunction,
        info: enInfo,
        missing: enMissing,
        help: enHelp,
        activity: enActivity,
        enum: enEnum,
        creditCard: enCreditCard,
        corporate: enCorporate,
    },
    de: {
        nav: deNav,
        action: deAction,
        validation: deValidation,
        input: deInput,
        placeholder: dePlaceholder,
        tooltips: deTooltips,
        error: { ...deError, ...deSharedError },
        view: deView,
        label: deLabel,
        append: deAppend,
        notification: deNotification,
        confirm: deConfirm,
        conjunction: deConjunction,
        info: deInfo,
        missing: deMissing,
        help: deHelp,
        activity: deActivity,
        enum: deEnum,
        creditCard: deCreditCard,
        corporate: deCorporate,
    },
}

/*
declare custom type options so the return is always a string. 
reference: https://www.i18next.com/overview/typescript#argument-of-type-defaulttfuncreturn-is-not-assignable-to-parameter-of-type-xyz
*/
declare module "i18next" {
    interface CustomTypeOptions {
        returnNull: false
    }
}

i18n.use(initReactI18next) // bind react-i18next to the instance
    .init({
        react: {
            useSuspense: false,
        },
        resources,
        lng: process.env.NODE_ENV !== "test" ? navigator.language : "en",
        fallbackLng: "en",
        interpolation: {
            escapeValue: false, // react already safes from xss
        },
        returnNull: false,
    })

i18n.services?.formatter?.add("lowercase", (value, lng, options) => value.toLowerCase())

export default i18n
