import {
    CostCenter,
    CostCenter2,
    Employee,
    ExpenseAccount,
    ExpenseCommentsMentionsEnum,
    ExpenseKindEnum,
    ExpenseTag,
    FormDueInDaysEnum,
    RightEnum,
} from "@finway-group/shared/lib/models"
import { ExpenseFilterExportFormatEnum } from "@finway-group/shared/lib/models/expense/expenseFilterExportFormat.enum"
import { Form } from "antd"
import moment from "moment"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"

import { FilterFormInterface } from "Components/filter/filterTypes"
import { FormInputEnum, FormSelectOption } from "Components/form/form.types"
import FormMinMax from "Components/form/formMinMax"
import FormMinMaxDate from "Components/form/formMinMaxDate"
import FormMinMaxDateDueDays from "Components/form/formMinMaxDateDueDays"
import FormSelect from "Components/form/formSelect"
import FormSelectTags from "Components/form/formSelectTags"
import { ALL_REIMBURSEMENTS_OPTION, expenseStati } from "Shared/config/consts"
import { useCompany } from "Shared/hooks/company.hooks"
import { useIsTravelEnabled } from "Shared/hooks/featureFlags.hooks"
import { getTableFilterObjectStore } from "Shared/hooks/table.hooks"
import { AuthzService, UserService } from "Shared/services"
import {
    createDynamicList,
    createYesOrNoOption,
    getDynamicCostCenter2List,
    getDynamicCostCenterList,
    getDynamicCreditorList,
    getDynamicExpenseAccountList,
    getExpenseKindList,
} from "Shared/utils/filter.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

import { generateRules } from "./rules/expenseFilter.rules"

export interface ExpenseFilter {
    minAmount?: number
    maxAmount?: number
    status?: string
    billable?: boolean
    kind?: ExpenseKindEnum | typeof ALL_REIMBURSEMENTS_OPTION
    minDate?: Date
    maxDate?: Date
    minInvoiceDate?: Date
    maxInvoiceDate?: Date
    minInvoiceDueDate?: Date
    maxInvoiceDueDate?: Date
    dueInDays?: FormDueInDaysEnum
    dueInDaysRadio?: FormDueInDaysEnum
    minPaidAtDate?: Date
    maxPaidAtDate?: Date
    expenseTags?: Array<ExpenseTag>
    costCenter?: string
    requestedBy?: string
    approvalNeededBy?: string
    vendor?: string
    exportFormat?: ExpenseFilterExportFormatEnum
    isReimbursement: boolean
    costCenter2?: string
    expenseAccount?: string
    commentsAndMentions?: Array<ExpenseCommentsMentionsEnum>
}

const adjustFilterObject = (filterObject: ExpenseFilter): ExpenseFilter => {
    const fixed = {
        ...filterObject,
        minAmount: filterObject.minAmount ? Number(filterObject.minAmount) : undefined,
        maxAmount: filterObject.maxAmount ? Number(filterObject.maxAmount) : undefined,
        minDate: filterObject.minDate ? moment(filterObject.minDate).startOf("day").toDate() : undefined,
        maxDate: filterObject.maxDate ? moment(filterObject.maxDate).endOf("day").toDate() : undefined,
        minInvoiceDate: filterObject.minInvoiceDate ? moment(filterObject.minInvoiceDate).startOf("day").toDate() : undefined,
        maxInvoiceDate: filterObject.maxInvoiceDate ? moment(filterObject.maxInvoiceDate).endOf("day").toDate() : undefined,
        minInvoiceDueDate: filterObject.minInvoiceDueDate ? moment(filterObject.minInvoiceDueDate).startOf("day").toDate() : undefined,
        maxInvoiceDueDate: filterObject.maxInvoiceDueDate ? moment(filterObject.maxInvoiceDueDate).endOf("day").toDate() : undefined,
        minPaidAtDate: filterObject.minPaidAtDate ? moment(filterObject.minPaidAtDate).startOf("day").toDate() : undefined,
        maxPaidAtDate: filterObject.maxPaidAtDate ? moment(filterObject.maxPaidAtDate).endOf("day").toDate() : undefined,
        expenseTags: filterObject.expenseTags || [],
        billable: filterObject.billable,
        commentsAndMentions: filterObject.commentsAndMentions?.length ? filterObject.commentsAndMentions : undefined,
    }

    if (filterObject?.kind) {
        if (
            Array.isArray(filterObject.kind) &&
            [ExpenseKindEnum.ONE_TIME_EXPENSE, ExpenseKindEnum.MILEAGE, ExpenseKindEnum.HOSPITALITY, ExpenseKindEnum.PER_DIEM].every(
                (kind) => filterObject.kind && filterObject.kind.includes(kind),
            )
        ) {
            fixed.kind = ALL_REIMBURSEMENTS_OPTION
        } else if (filterObject?.kind === ExpenseKindEnum.ONE_TIME_EXPENSE) {
            if (filterObject.isReimbursement) {
                fixed.kind = ExpenseKindEnum.STANDARD
            } else {
                fixed.kind = ExpenseKindEnum.ONE_TIME_EXPENSE
            }
        }
    }

    return fixed
}

const ExpenseFilterForm: React.FC<FilterFormInterface> = ({ table, formInstance, options, onSubmit }) => {
    const { t } = useTranslation()
    const tableFilterObject = getTableFilterObjectStore<ExpenseFilter>(table)
    const [expenseFilter, setExpenseFilter] = useStateIfMounted(adjustFilterObject(tableFilterObject))
    const rules = generateRules(formInstance, table)
    const employeeList = UserService.getApproverEmployeeList(options.employees as Array<Employee>)
    const isTravelEnabled = useIsTravelEnabled()
    const expenseKindList = getExpenseKindList(isTravelEnabled)
    const { showCostCentersIds } = useCompany()

    useEffect(() => {
        formInstance.resetFields()
        setExpenseFilter(adjustFilterObject(tableFilterObject))
    }, [])

    const statusOptions: Array<FormSelectOption> = expenseStati.map((status: string, index: number) => ({
        param: t(status),
        value: index.toString(),
        index,
    }))

    const commentsAndMentionsOptions: Array<FormSelectOption> = createDynamicList("input:expense_filter.comments_and_mentions", [
        ExpenseCommentsMentionsEnum.COMMENTS,
        ExpenseCommentsMentionsEnum.MENTIONS,
    ])

    const exportOptions: Array<FormSelectOption> = createDynamicList("input:expense_filter.export_format", [
        ExpenseFilterExportFormatEnum.CSV,
        ExpenseFilterExportFormatEnum.NOT_CSV,
        ExpenseFilterExportFormatEnum.DATEV_XML,
        ExpenseFilterExportFormatEnum.NOT_DATEV_XML,
        ExpenseFilterExportFormatEnum.DATEV_INVOICE_CSV,
        ExpenseFilterExportFormatEnum.NOT_DATEV_INVOICE_CSV,
        ExpenseFilterExportFormatEnum.DATEV_ONLINE,
        ExpenseFilterExportFormatEnum.NOT_DATEV_ONLINE,
        ExpenseFilterExportFormatEnum.ALL_DATEV,
        ExpenseFilterExportFormatEnum.NOT_ALL_DATEV,
    ])

    return (
        <Form id="expenseFilterForm" className="form--filter" form={formInstance} onFinish={onSubmit}>
            <FormMinMax
                header={t("input:filter_form.amount")}
                inputType={FormInputEnum.NUMBER}
                min={{
                    name: "minAmount",
                    label: t("input:filter_form.min_amount"),
                    placeholder: t("placeholder:expense_filter.min_amount"),
                    onChange: () => formInstance.validateFields(["minAmount", "maxAmount"]),
                    initialValue: expenseFilter.minAmount,
                    rules: rules.minAmount,
                }}
                max={{
                    name: "maxAmount",
                    label: t("input:filter_form.max_amount"),
                    placeholder: t("placeholder:expense_filter.max_amount"),
                    onChange: () => formInstance.validateFields(["minAmount", "maxAmount"]),
                    initialValue: expenseFilter.maxAmount,
                    rules: rules.maxAmount,
                }}
            />

            <FormSelect
                name="billable"
                header={t("input:expense_filter.billable.title")}
                initialValue={expenseFilter.billable?.toString()}
                options={createYesOrNoOption()}
                isCollapse
                fallback
            />

            <FormSelect name="status" header={t("input:expense_filter.status")} initialValue={expenseFilter.status} options={statusOptions} isCollapse fallback />

            <FormSelect
                name="commentsAndMentions"
                header={t("input:expense_filter.comments_and_mentions.title")}
                initialValue={expenseFilter.commentsAndMentions}
                options={commentsAndMentionsOptions}
                isCollapse
                fallback
                mode="tags"
            />

            <FormSelect name="kind" header={t("input:expense_filter.kind")} initialValue={expenseFilter.kind} options={expenseKindList} isCollapse fallback />

            <FormMinMaxDate
                header={t("input:expense_filter.purchase_date_range")}
                min={{
                    name: "minDate",
                    label: t("input:expense_filter.min_date"),
                    placeholder: t("placeholder:expense_filter.min_date"),
                    initialValue: expenseFilter.minDate,
                    rules: rules.minDate,
                    onChange: () => formInstance.validateFields(["minDate", "maxDate"]),
                }}
                max={{
                    name: "maxDate",
                    label: t("input:expense_filter.max_date"),
                    placeholder: t("placeholder:expense_filter.max_date"),
                    initialValue: expenseFilter.maxDate,
                    rules: rules.maxDate,
                    onChange: () => formInstance.validateFields(["minDate", "maxDate"]),
                }}
            />

            <FormMinMaxDate
                header={t("input:expense_filter.invoice_date_range")}
                min={{
                    name: "minInvoiceDate",
                    label: t("input:expense_filter.min_date"),
                    placeholder: t("placeholder:expense_filter.min_date"),
                    initialValue: expenseFilter.minInvoiceDate,
                    rules: rules.minInvoiceDate,
                    onChange: () => formInstance.validateFields(["minInvoiceDate", "maxInvoiceDate"]),
                }}
                max={{
                    name: "maxInvoiceDate",
                    label: t("input:expense_filter.max_date"),
                    placeholder: t("placeholder:expense_filter.max_date"),
                    initialValue: expenseFilter.maxInvoiceDate,
                    rules: rules.maxInvoiceDate,
                    onChange: () => formInstance.validateFields(["minInvoiceDate", "maxInvoiceDate"]),
                }}
            />

            <FormMinMaxDateDueDays
                header={t("input:filter_form.due_date")}
                dueInDaysSelected={expenseFilter.dueInDaysRadio}
                min={{
                    name: "minInvoiceDueDate",
                    label: t("input:expense_filter.min_date"),
                    placeholder: t("placeholder:expense_filter.min_date"),
                    initialValue: expenseFilter.minInvoiceDueDate,
                    rules: rules.minInvoiceDueDate,
                    onChange: () => formInstance.validateFields(["minInvoiceDueDate", "maxInvoiceDueDate"]),
                }}
                max={{
                    name: "maxInvoiceDueDate",
                    label: t("input:expense_filter.max_date"),
                    placeholder: t("placeholder:expense_filter.max_date"),
                    initialValue: expenseFilter.maxInvoiceDueDate,
                    rules: rules.maxInvoiceDueDate,
                    onChange: () => formInstance.validateFields(["minInvoiceDueDate", "maxInvoiceDueDate"]),
                }}
                days={{
                    name: "dueInDays",
                    label: t("input:filter_form.due_in_days"),
                    placeholder: t("placeholder:filter_form.due_in_days"),
                    initialValue: expenseFilter.dueInDays,
                    onChange: () => formInstance.validateFields(["dueInDays"]),
                    rules: rules.dueInDays,
                }}
            />

            <FormMinMaxDate
                header={t("input:request.date_paid_at")}
                min={{
                    name: "minPaidAtDate",
                    label: t("input:expense_filter.min_date"),
                    placeholder: t("placeholder:expense_filter.min_date"),
                    initialValue: expenseFilter.minPaidAtDate,
                    rules: rules.minPaidAtDate,
                    onChange: () => formInstance.validateFields(["minPaidAtDate", "maxInvoiceDate"]),
                }}
                max={{
                    name: "maxPaidAtDate",
                    label: t("input:expense_filter.max_date"),
                    placeholder: t("placeholder:expense_filter.max_date"),
                    initialValue: expenseFilter.maxPaidAtDate,
                    rules: rules.maxPaidAtDate,
                    onChange: () => formInstance.validateFields(["minPaidAtDate", "maxPaidAtDate"]),
                }}
            />

            <FormSelectTags
                header={t("input:expense_filter.expenseTags")}
                placeholder={t("input:filter_form.not_set")}
                initialValue={expenseFilter.expenseTags}
                name="expenseTags"
            />

            <FormSelect
                name="costCenter"
                header={t("input:expense_filter.cost_center")}
                initialValue={expenseFilter.costCenter}
                options={getDynamicCostCenterList(options.costCenters as Array<CostCenter>, showCostCentersIds)}
                isCollapse
                showSearch
                fallback
            />

            {(options.costCenters2?.length ?? 0) > 0 && (
                <FormSelect
                    name="costCenter2"
                    header={t("input:filter_form.cost_center_2")}
                    initialValue={expenseFilter.costCenter2}
                    options={getDynamicCostCenter2List(options.costCenters2 as Array<CostCenter2>)}
                    isCollapse
                    showSearch
                    fallback
                />
            )}

            {(options.expenseAccounts?.length ?? 0) > 0 && (
                <FormSelect
                    name="expenseAccount"
                    header={t("input:filter_form.expense_account")}
                    initialValue={expenseFilter.expenseAccount}
                    options={getDynamicExpenseAccountList(options.expenseAccounts as Array<ExpenseAccount>)}
                    isCollapse
                    showSearch
                    fallback
                />
            )}

            {(AuthzService.isRightGrantedForLoggedInUser(RightEnum.EXPENSE__ALL__READ) || AuthzService.isRightGrantedForLoggedInUser(RightEnum.EXPENSE__TEAM__READ)) && (
                <FormSelect
                    name="requestedBy"
                    header={t("input:expense_filter.requester")}
                    initialValue={expenseFilter.requestedBy}
                    options={options.employees ?? []}
                    displayImages
                    isCollapse
                    showSearch
                    fallback
                />
            )}

            <FormSelect
                name="approvalNeededBy"
                header={t("input:expense_filter.approver")}
                initialValue={expenseFilter.approvalNeededBy}
                options={employeeList}
                displayImages
                isCollapse
                showSearch
                fallback
            />

            <FormSelect
                name="vendor"
                header={t("input:filter_form.vendor")}
                initialValue={expenseFilter.vendor}
                options={getDynamicCreditorList(options.creditors)}
                isCollapse
                showSearch
                fallback
            />

            <FormSelect
                name="exportFormat"
                header={t("input:expense_filter.export_format.title")}
                initialValue={expenseFilter.exportFormat}
                options={exportOptions}
                isCollapse
                fallback
            />
        </Form>
    )
}

export default ExpenseFilterForm
