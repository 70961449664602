import { Form } from "antd"
import { FormInstance } from "antd/lib/form"
import { Store } from "antd/lib/form/interface"
import React from "react"
import { useTranslation } from "react-i18next"

import FormSelect from "Components/form/formSelect"
import { useCompany } from "Shared/hooks/company.hooks"
import { useCostCenters2 } from "Shared/hooks/costCenter2.hooks"
import { useSubCostCenters } from "Shared/hooks/costCenter.hooks"
import { useExpenseAccounts } from "Shared/hooks/expenseAccount.hooks"
import { getDynamicCostCenter2List, getDynamicCostCenterList, getDynamicExpenseAccountList } from "Shared/utils/filter.utils"
import { handleEmptyValues } from "Shared/utils/helper.utils"

interface EmployeeRuleFormInterface {
    formInstance: FormInstance
    onSubmit: (values: Store) => void
}

const EmployeeRuleForm = ({ formInstance, onSubmit }: EmployeeRuleFormInterface) => {
    const { t } = useTranslation()
    const costCenters = useSubCostCenters(true)
    const costCenters2 = useCostCenters2()
    const expenseAccounts = useExpenseAccounts()

    const { showCostCentersIds } = useCompany()

    const handleFinish = (values: Store) => {
        const formValues = handleEmptyValues(values, ["costCenter", "costCenter2", "expenseAccount"])
        onSubmit(formValues)
    }

    return (
        <Form layout="vertical" form={formInstance} onFinish={handleFinish} scrollToFirstError>
            <FormSelect
                name="costCenter"
                header={t("input:request.cost_center")}
                options={getDynamicCostCenterList(costCenters, showCostCentersIds)}
                fallback={String(t("missing:request.cost_center_1"))}
                showSearch
            />

            {costCenters2.length > 0 && (
                <FormSelect
                    name="costCenter2"
                    header={t("label:cost_center_2")}
                    options={getDynamicCostCenter2List(costCenters2)}
                    fallback={String(t("missing:request.cost_center_2"))}
                    showSearch
                />
            )}
            {expenseAccounts.length > 0 && (
                <FormSelect
                    name="expenseAccount"
                    header={t("label:expense_account")}
                    options={getDynamicExpenseAccountList(expenseAccounts)}
                    fallback={String(t("missing:request.expense_account"))}
                    showSearch
                />
            )}
        </Form>
    )
}

export default EmployeeRuleForm
