import { CreateIntegrationType, IntegrationType, IntegrationTypeEnum } from "@finway-group/shared/lib/models"
import { useMutation, useQuery } from "@tanstack/react-query"

import { queryClient } from "Shared/config/reactQueryConfig"
import { QueryKeyEnum } from "Shared/reactQuery.types"
import { AuthnService } from "Shared/services"
import { queryDelete, queryGet, queryPatch, queryPost, queryPut } from "Shared/utils/reactQuery.utils"

const MODEL = "integrations"

type Integrations = {
    [key in IntegrationTypeEnum]: IntegrationType
}

const invalidateIntegrationCache = () => {
    queryClient.invalidateQueries({
        queryKey: [QueryKeyEnum.INTEGRATION_CONFIGURATIONS],
    })
}

export const useIntegrationConfigurations = () => useQuery([QueryKeyEnum.INTEGRATION_CONFIGURATIONS], ({ signal }) => queryGet<Integrations>(MODEL, { signal }))

export const useCreateIntegrationConfiguration = () =>
    useMutation((data: CreateIntegrationType) => queryPost(MODEL, data), {
        onSuccess: invalidateIntegrationCache,
    })

export const useUpdateIntegrationConfiguration = () =>
    useMutation(({ id, data }: { id: string; data: IntegrationType }) => queryPut(`${MODEL}/${id}`, data), {
        onSuccess: invalidateIntegrationCache,
    })

export const useDeleteIntegrationConfiguration = () =>
    useMutation((id: string) => queryDelete(`${MODEL}/${id}`), {
        onSuccess: invalidateIntegrationCache,
    })

export const useToggleIntegrationConfiguration = () =>
    useMutation(({ id, enable }: { id: string; enable: boolean }) => queryPatch(`${MODEL}/${id}/${enable ? "enable" : "disable"}`), {
        onSuccess: invalidateIntegrationCache,
    })

export const useTriggerPersonioSync = () => useMutation(() => queryPost(`${MODEL}/${IntegrationTypeEnum.PERSONIO}/sync`), { onSuccess: invalidateIntegrationCache })

// Get's removed once admin dashboard exists
export const useEnablePersonioFeature = () =>
    useMutation(
        () =>
            queryPatch(`/tenant/${AuthnService.getCurrentTenantId()}/flags`, {
                personioEnabled: true,
            }),
        { onSuccess: invalidateIntegrationCache },
    )
