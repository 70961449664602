import React from "react"

export interface ColorBar {
    value: number
    color: string
    width?: string
    className?: string
}

interface ColorsBarInterface {
    bars: Array<ColorBar>
}

export const ColorsBar: React.FC<ColorsBarInterface> = ({ bars }) => {
    const barValues = bars.map(({ value }) => value)
    const total = barValues.reduce((acc: number, current) => acc + current)

    bars.forEach((bar) => {
        if (!bar.width) bar.width = `${(bar.value / total) * 100}%`
    })

    return (
        <div className="flex w-full overflow-hidden rounded-sm">
            {bars.map((bar: ColorBar, index) => (
                <div key={index} className={bar.className} style={{ width: bar.width, backgroundColor: bar.color }} />
            ))}
        </div>
    )
}
