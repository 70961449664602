import React from "react"
import { useTranslation } from "react-i18next"

import { IMPRINT_LINK, PRIVACY_POLICY_LINK, TERMS_OF_SERVICES_LINK } from "Shared/config/consts"

const AuthFooter = () => {
    const { t } = useTranslation()

    return (
        <div className="w-full flex items-center justify-center" id="auth-footer">
            <p className="meta-long text-white">
                <a data-testid="imprintLink" className="text-white hover:text-gray-100 hover:underline text-sm sm:mr-0" href={IMPRINT_LINK}>
                    {t("nav:imprint")}
                </a>
            </p>
            <p className="meta-long text-white">
                <a data-testid="privacyLink" className="text-white hover:text-gray-100 hover:underline text-sm sm:mr-0" href={PRIVACY_POLICY_LINK}>
                    {t("nav:privacy")}
                </a>
            </p>
            <p className="meta-long text-white">
                <a data-testid="termsOfUseLink" className="text-white hover:text-gray-100 hover:underline text-sm sm:mr-0" href={TERMS_OF_SERVICES_LINK}>
                    {t("nav:terms_of_use")}
                </a>
            </p>
        </div>
    )
}

export default AuthFooter
