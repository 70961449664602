import { CalendarOutlined } from "@ant-design/icons"
import {
    ApprovalScopeEnum,
    ApprovalStatusEnum,
    CollectionNameEnum,
    DocumentTypeEnum,
    Expense,
    ExpenseKindEnum,
    OcrResult,
    OperationTypeEnum,
    RightEnum,
    UploadKindEnum,
} from "@finway-group/shared/lib/models"
import { getApprovalProcess, getApprovalProcessScopeFromExpenseStatus } from "@finway-group/shared/lib/utils/approvalProcess.utils"
import { willReviewFlagExpenseAsGobdCompliantInvoice } from "@finway-group/shared/lib/utils/gobdInvoiceCompliance.utils"
import { Alert, Button, Col, Row, Tag, Tooltip } from "antd"
import moment from "moment"
import React, { Suspense, useEffect, useMemo, useRef, useState } from "react"
import { AlertTriangle, X as CancelIcon, Edit2 as EditIcon } from "react-feather"
import { Helmet } from "react-helmet"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useHistory, useParams } from "react-router-dom"

import PageCustomHeader from "Components/PageCustomHeader"
import AdditionalExpenseOptionButton from "Components/additionalExpenseOptionsButton"
import { ApprovalProcessIndicatorMenu } from "Components/approvalProcessesIndicator"
import ExpenseSwitching from "Components/expenseSwitching"
import ExpenseTagSection from "Components/expenseTagSection"
import ExportDropDownMenu from "Components/exportDropDownMenu"
import ExportTag from "Components/exportTag"
import Loading from "Components/loading"
import DuoImportProtocolModal from "Components/modals/duoImportProtocol.modal"
import ExpenseCreateFormModal from "Components/modals/expenseCreateForm.modal"
import ExpenseReminderModal from "Components/modals/expenseReminder.modal"
import InboxInvoiceModal from "Components/modals/inboxInvoice/inboxInvoice.modal"
import PaymentConfirmationModal from "Components/modals/paymentConfirmation.modal"
import SharedExpenseTag from "Components/sharedExpenseTag"
import { ExpenseExport } from "Features/export/expenseExport"
import { FINWAY_ADMIN_EMAIL } from "Shared/config/consts"
import { ExpenseDetailsContext } from "Shared/context/expenseDetails.context"
import { useModal } from "Shared/context/modal.context"
import { useArchiveInterval, useCompany } from "Shared/hooks/company.hooks"
import { useEmployees } from "Shared/hooks/employee.hooks"
import {
    approveExpenseRequest,
    approveFolderRequest,
    cancelExpenseRequest,
    handleTripFolderSubmit,
    markExpenseAsPaid,
    markExpenseAsReviewed,
    markMultipleExpensesAsPaid,
    sendEmailReminder,
    useExpense,
    useExpenseSwitching,
    useExpenses,
    useShouldRefetchExpenses,
} from "Shared/hooks/expense.hooks"
import { onUpdateExpenseFromOcr } from "Shared/hooks/ocr.hooks"
import { useTaxes } from "Shared/hooks/tax.hooks"
import { useCreditorById, useVendors } from "Shared/hooks/vendor.hooks"
import { ApprovalProcessService, AuthzService, BankService, ExpenseService, FileService, NotificationService, OcrService, UserService } from "Shared/services"
import DialogService from "Shared/services/dialog.service"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { ThunkDispatchResult } from "Shared/store"
import {
    deleteReminder,
    fetchAllExpenses,
    fetchOneExpense,
    initializeApprovalProcess,
    reviewFolder,
    setExpense,
    syncExpense,
    updateDocuments,
} from "Shared/store/actions/expense/expenseActions"
import { refetchTable } from "Shared/store/actions/tables/tableActions"
import { TablesEnum } from "Shared/store/reducers/tableConfigReducer"
import {
    getExpenseReminderForUser,
    getReimbursementLabel,
    isExpenseExportable,
    isExpenseSwitchingShown,
    isFolderExpense,
    isHospitalityExpense,
    isMatched,
    showPaymentFailedAlert,
} from "Shared/utils/expense.utils"
import { getFolderProgressionInfo } from "Shared/utils/folder.utils"
import { convertFileDataFormat, getTooltipPopupContainer, isExtendedAlphanumeric } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"
import useUpdateEffect from "Shared/utils/hooks/useUpdateEffect"

import { ExpenseArchiveTags } from "./auxiliary/expenseArchiveTags"
import DatevOnlineJobBanner from "./datevOnlineJobBanner"
import { ExpenseBottomSectionContainer } from "./expenseBottomSectionContainer"
import ExpenseCreditorAlert from "./expenseCreditorAlert"
import { doFileUploadFunction } from "./expenseDetails.container.types"
import ExpenseRightSection from "./expenseRightSection"
import { FolderActionButtons } from "./folderDetails/folderActionButtons"
import MainDetailsTabs from "./mainDetailsTabs"

const CommentContainer = React.lazy(() => import("Components/comment/comment.container"))

const ExpenseDetails: React.FC = () => {
    // id is the main expense (can be a normal expense, or can be a folder), and activeId will be filled in when id is a folderId and we're looking at a folder's subexpense.
    const { id, childId } = useParams<{ id: string; childId?: string }>()
    const { t } = useTranslation()
    const dispatch: ThunkDispatchResult = useDispatch()
    const history = useHistory()
    const [isInvoiceUploading, setIsInvoiceUploading] = useStateIfMounted<boolean>(false)
    const [isDeliveryNoteUploading, setIsDeliveryNoteUploading] = useStateIfMounted<boolean>(false)
    const [isLoading, setIsLoading] = useStateIfMounted<boolean>(false)
    const [isLoadingSubExpenses, setIsLoadingSubExpenses] = useStateIfMounted<boolean>(false)
    const [isEditModalShowing, setIsEditModalShowing] = useState<boolean>(false)
    const [isShowingImportProtocolModal, setIsShowingImportProtocolModal] = useStateIfMounted(false)
    const [showInboxModal, setShowInboxModal] = useState(false)
    const [isShowingPaymentConfirmationModal, setIsShowingPaymentConfirmationModal] = useState<boolean>(false)
    const [isScheduledPayment, setIsScheduledPayment] = useStateIfMounted<boolean>(false)
    const [isSummaryDownloadLoading, setSummaryDownloadLoading] = useStateIfMounted<boolean>(false)

    // Uses redux so that changes in the sub-expenses will apply directly.
    const { expenses: subExpenses } = useExpenses()
    const shouldRefetchSubExpenses = useShouldRefetchExpenses()
    const isFetchingSubExpenses = useRef(false)

    // expense is the current loaded "main" expense loaded in redux. Could be a normal expense, could be a folder
    const expense = useExpense()

    // activeExpense is which expense that the user is currently looking at.
    // This is required because in case when the `expense` is a folder, the user should be able click on other sub-expenses of the folder.
    // Example: The user have folder #100, and its subexpenses are #105,#106,#107. When loading the page, expense will be #100, and the activeExpense will be #100
    // But when the user clicks on #105 to take a look, expense will remain #100, but the activeExpense will be #105
    // The structure of the expense detail page component URL is: /expenses/:id/:childId -- the :id will always be the `expense`, and if :childId exists, it will be the activeExpense.
    const activeExpenseId = childId ?? id
    const activeExpense = expense.kind !== ExpenseKindEnum.TRIP_FOLDER ? expense : subExpenses.find((child) => child.id === activeExpenseId) ?? expense

    const employees = useEmployees({ includeAutoApprover: true })
    const vendor = useCreditorById(activeExpense.getCreditor()?._id)
    const loggedInUser = UserService.getLoggedInEmployeeProfile()
    const [isOcrEnabled, setIsOcrEnabled] = useStateIfMounted(loggedInUser.settings.enableOcr)
    const [isOcrItemizationEnabled, setIsOcrItemizationEnabled] = useStateIfMounted(loggedInUser.settings.enableOcrItemization)
    const [isOcrUploading, setIsOcrUploading] = useStateIfMounted(false)
    const [isLoadingExpense, setIsLoadingExpense] = useStateIfMounted(false)
    const [isExpenseUpdating, setIsExpenseUpdating] = useStateIfMounted(false)
    const [updatedOcrObject, setUpdatedObject] = useStateIfMounted<OcrResult | null>(null)
    const [showDiscountForm, setShowDiscountForm] = useStateIfMounted<boolean>(false)

    const vendors = useVendors(true)
    const taxes = useTaxes()
    const company = useCompany()
    const archiveAfterXDays = useArchiveInterval()

    const nextPendingApprover = ApprovalProcessService.getNextPendingApprover(expense.approvalProcesses)
    const approvalProcessScope = getApprovalProcessScopeFromExpenseStatus(expense.status, true)

    const isReadOnly = expense.isReadOnly ?? true // calculated in the API. readOnly determines if user will be able to add/delete files to the Upload Dragger component.
    const taggedUsers = expense.userTagging?.map((tagging) => tagging.tagged)
    const displayUserAsTagged = isReadOnly && taggedUsers?.includes(loggedInUser.id)

    const requestType = expense.getRequestType()
    const splitsHasCompleteCostCenter = expense.splits?.length === 0 || expense.splits?.every((s) => s.costCenter)
    const splitsHasAnyCostCenter = expense.splits?.some((s) => s.costCenter)
    const { taggingEnabled } = useCompany()

    const editButtonView = ExpenseService.calculateExpenseEditButtonView(expense, loggedInUser, archiveAfterXDays, t)
    const isLoggedInUserGlobalApprover = AuthzService.isLoggedInUserGlobalApprover()
    const showExportButton = isExpenseExportable(expense, loggedInUser, isLoggedInUserGlobalApprover)

    const reminder = getExpenseReminderForUser(expense.reminders, loggedInUser.id)

    const { showModal } = useModal()

    /* useMemo is not used for performance reasons but to meet the desired functionality for expense switching */
    const expenseStatusMemoized = useMemo(() => expense.status, [expense.id])
    const showExpenseSwitching = useMemo(() => isExpenseSwitchingShown(expense, archiveAfterXDays), [expense.id])

    const { prevExpense, nextExpense, isExpenseSwitchingLoading, hasError, adjacentExpenseLabel } = useExpenseSwitching(expense.id, expenseStatusMemoized, expense.requestedBy?._id)

    const expenseTitle = isFolderExpense(expense) ? `#${expense.expenseNumber} - ${expense.folderName}` : `${t("label:request")} #${expense?.expenseNumber}`

    // For folders, because the title/name might be too long, we have put the tags below the title instead of beside the action buttons
    const showTagsBelowTitle = taggingEnabled && isFolderExpense(expense) && expense.expenseTags?.length > 1

    useEffect(() => {
        setIsLoadingExpense(true)
        dispatch(fetchOneExpense(id))
            .then((expense) => {
                // When we discover that the newly loaded expense belongs in a folder, change the id in the params, and it will re-trigger this use effect again, with the new ID.
                if (expense.folderId && !childId) {
                    // If the loaded expense belongs in a folder, then redirect it so that the main id is the folder's and the set the childId to be the expense that was loaded the first time
                    history.replace(`/expenses/${expense.folderId}/${expense.id}`)
                    return
                }
                setIsLoadingExpense(false) // it is not necessary to setLoading to false in a .finally() since if there is an error we unmount the component
            })
            .catch((_error: any) => {
                history.push("/expenses")
            })
        dispatch(refetchTable(TablesEnum.EXPENSE_TRANSACTIONS))
    }, [id, expense.folderId])

    /**
     * When looking in the detail page of a folder, we want to keep track on what was being seen last.
     * This is particularly useful when the user want to share a link to a specific folder's content
     */
    const setActiveExpenseId = (newId: string) => {
        if (newId === id) {
            // Using replace so it doesnt pollute the back button
            history.replace(`/expenses/${id}`)
            return
        }
        history.replace(`/expenses/${id}/${newId}`)
    }

    const onCancelRequest = () => {
        cancelExpenseRequest(expense, true, () => {
            history.goBack()
        })
    }

    const fetchFolderContents = async (silent: boolean = false) => {
        if (isFolderExpense(expense)) {
            if (isFetchingSubExpenses.current) {
                return
            }
            isFetchingSubExpenses.current = true
            if (!silent) setIsLoadingSubExpenses(true)
            try {
                await fetchAllExpenses(`&folderId[eq]=${id}&presets[in]=follow_up_requests`, "", 1, 100)(dispatch)
                if (childId) await syncExpense(childId)(dispatch)
            } catch (err) {
                NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:error"))
            } finally {
                if (!silent) setIsLoadingSubExpenses(false)
                isFetchingSubExpenses.current = false
            }
        }
    }

    // Use effect to populate the subExpenses if needed
    useEffect(() => {
        fetchFolderContents()
    }, [expense.id])

    useUpdateEffect(() => {
        fetchFolderContents(true)
    }, [expense.updatedAt?.toString()])

    // Refetch the sub-expenses if needed (e.g. a subexpense is getting attached, or removed, and do it silently for UX purpose.)
    useEffect(() => {
        if (shouldRefetchSubExpenses) {
            fetchFolderContents(true)
        }
    }, [shouldRefetchSubExpenses])

    const onSendEmailReminder = () => sendEmailReminder(employees, expense)

    const markRequestAsApproved = (expense: Expense, approvalScope: ApprovalScopeEnum) => {
        setIsLoading(true)

        const approvalProcess = getApprovalProcess(approvalScope, expense.approvalProcesses)
        if (approvalProcess?.status === ApprovalStatusEnum.NOT_STARTED) {
            // if expense has to be submitted
            if (isFolderExpense(expense) && expense.isDraft) {
                handleTripFolderSubmit(expense).finally(() => setIsLoading(false))
            } else {
                dispatch(initializeApprovalProcess(expense.id, approvalScope)).finally(() => setIsLoading(false))
            }
        } else if (isFolderExpense(expense)) {
            approveFolderRequest(expense, approvalScope, loggedInUser, nextPendingApprover?.getFullName()).finally(() => setIsLoading(false))
        } else {
            approveExpenseRequest(expense, approvalScope, loggedInUser, nextPendingApprover?.getFullName())
                .then(() => {
                    loggedInUser.settings.enableGoToNextExpense && nextExpense && history.push({ pathname: nextExpense, state: { fromExpenseSwitching: true } })
                })
                .finally(() => {
                    setIsLoading(false)
                })
        }
    }

    const markRequestAsReviewed = async (expense: Expense) => {
        if (isFolderExpense(expense)) {
            const { processableSubExpenses } = getFolderProgressionInfo(expense, subExpenses)

            if (processableSubExpenses.some((processableSubExpense) => processableSubExpense.invoiceNumber && !isExtendedAlphanumeric(processableSubExpense.invoiceNumber))) {
                try {
                    await DialogService.confirmDatevWarningPromise(true)
                } catch (err) {
                    return // means that the user clicked on cancel
                }
            }

            try {
                setIsLoading(true)
                await reviewFolder(expense)(dispatch)
            } catch (err) {
                NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:error"))
            } finally {
                setIsLoading(false)
            }
        } else {
            if (expense.invoiceNumber && !isExtendedAlphanumeric(expense.invoiceNumber)) {
                try {
                    await DialogService.confirmDatevWarningPromise(true)
                } catch (err) {
                    return // means that the user clicked on cancel
                }
            }
            if (
                company.gobdCompliance?.enforceGobdCompliantInvoice &&
                willReviewFlagExpenseAsGobdCompliantInvoice(expense) &&
                !(await DialogService.confirmExpenseGobdCompliantInvoiceFlagging())
            ) {
                return
            }
            onMarkAsReviewed(expense)
        }
    }

    const onMarkAsReviewed = (expense: Expense) => {
        setIsLoading(true)
        markExpenseAsReviewed(expense)
            .then(() => {
                loggedInUser.settings.enableGoToNextExpense && nextExpense && history.push({ pathname: nextExpense, state: { fromExpenseSwitching: true } })
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const markRequestAsPaid = (expense: Expense) => {
        setIsLoading(true)

        if (isFolderExpense(expense)) {
            const { processableSubExpenses } = getFolderProgressionInfo(expense, subExpenses)
            return markMultipleExpensesAsPaid([expense, ...processableSubExpenses]).finally(() => setIsLoading(false))
        }

        return markExpenseAsPaid(expense).finally(() => setIsLoading(false))
    }

    const payExpense = (isScheduledPayment: boolean) => {
        setIsShowingPaymentConfirmationModal(true)
        setIsScheduledPayment(isScheduledPayment)
    }

    const onInboxSelected = (e: any) => {
        e.stopPropagation()
        setShowInboxModal(true)
    }

    const onlyFileExport = ExpenseService.onlyAllowFileExports(activeExpense)

    const renderActionButtons = (
        <div className="flex-wrap flex">
            {taggingEnabled && <ExpenseTagSection expenseId={activeExpense.id} expenseTags={activeExpense.expenseTags} />}
            {reminder && (
                <Tooltip placement="bottom" overlayStyle={{ maxWidth: "100%" }} title={t("tooltips:expense_reminder", { date: moment(reminder.date).format("DD/MM/YYYY") })}>
                    <Button
                        className="m-5 rounded-lg bg-white border-solid font-normal text-gray-800  border-gray-300 border-1"
                        onClick={() => {
                            showModal(ExpenseReminderModal, true, { isShowing: true, expenseId: id, reminder })
                        }}
                    >
                        <CalendarOutlined />
                        <span className="font-medium text-text">{moment(reminder.date).format("DD/MM/YYYY")} </span>
                        <CancelIcon
                            className="ml-3"
                            onClick={(e) => {
                                dispatch(deleteReminder(id))
                                e.stopPropagation()
                            }}
                        />
                    </Button>
                </Tooltip>
            )}

            {editButtonView.buttonVisible && (
                <>
                    <ApprovalProcessIndicatorMenu approvalProcesses={activeExpense.approvalProcesses} scope={approvalProcessScope} />
                    <Tooltip
                        getPopupContainer={getTooltipPopupContainer}
                        {...(editButtonView.buttonDisabled ? {} : { visible: false })}
                        title={<div>{editButtonView.reasonToDisplay}</div>}
                        placement="bottomLeft"
                    >
                        <Button className="m-5 btn-default" disabled={editButtonView.buttonDisabled} onClick={() => setIsEditModalShowing(true)}>
                            <EditIcon />
                            <span>{t("action:edit")}</span>
                        </Button>
                    </Tooltip>
                </>
            )}
            {/** We always show the export dropdown when the expense is archived to allow the user to export. */}
            {showExportButton && (
                <ExpenseExport
                    render={({ onExport, isExporting }) => (
                        <ExportDropDownMenu
                            onExport={onExport}
                            expenses={isFolderExpense(activeExpense) ? getFolderProgressionInfo(activeExpense, subExpenses).processableSubExpenses : [activeExpense]}
                            isLoading={isExporting}
                            buttonClassName={"m-5"}
                            includeSepa
                            onlyFileExport={onlyFileExport}
                            isAuditTrailVisible={true}
                        />
                    )}
                />
            )}
            <AdditionalExpenseOptionButton
                expense={expense}
                isEditDisabled={editButtonView.buttonDisabled}
                setIsShowingImportProtocolModal={setIsShowingImportProtocolModal}
                onOpenDiscountForm={() => setShowDiscountForm(true)}
            />
            {showExpenseSwitching && (
                <ExpenseSwitching
                    hasError={hasError}
                    isLoading={isExpenseSwitchingLoading}
                    prevExpense={prevExpense}
                    nextExpense={nextExpense}
                    adjacentExpenseLabel={adjacentExpenseLabel}
                />
            )}
        </div>
    )

    useEffect(() => {
        if (updatedOcrObject) onUpdateExpenseFromOcr({ updatedOcrObject, activeExpense, vendors, taxes, t, isOcrItemizationEnabled, company, expense, dispatch })
    }, [updatedOcrObject])

    const doFileUpload: doFileUploadFunction = async (files: Array<File>, kind: DocumentTypeEnum) => {
        if (!FileService.checkFilesSizeAndHandleError(files)) return

        const isInvoice = kind === DocumentTypeEnum.INVOICE
        const setIsLoadingFunction = isInvoice ? setIsInvoiceUploading : setIsDeliveryNoteUploading
        setIsLoadingFunction(true)

        try {
            const data = await FileService.uploadMultiple(files, UploadKindEnum.EXPENSE)
            const dataFiltered = await Promise.all(data.filter((fileUploadData) => ExpenseService.checkForDuplicateInvoiceFile(fileUploadData.duplicateData)))
            const docsToAdd = dataFiltered.map(convertFileDataFormat)

            if (docsToAdd.length < 1) return

            const updatedExpense = await updateDocuments(activeExpense.id, kind, docsToAdd, OperationTypeEnum.INSERT)(dispatch)
            /* if it's a parent expense,update the parent */
            setExpense(updatedExpense.id === expense.id ? activeExpense : new Expense({ ...expense }))
            NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:file.uploaded.title"), t("notification:file.uploaded.message"))

            if (!isOcrEnabled || !isInvoice || isHospitalityExpense(updatedExpense)) return

            // we only run OCR on the last uploaded file in case of multiples
            const lastDocAdded = docsToAdd.pop()
            OcrService.runOcr({
                invoiceUrl: lastDocAdded?.url,
                expense: updatedExpense,
                setIsOcrUploading,
                vendors,
                t,
                noDataDifferenceFunction: setUpdatedObject,
                setUpdatedObject,
                showModal,
                isOcrItemizationEnabled,
                language: loggedInUser?.getUserLanguage(),
                meta: { source: "expenseDetails.container.tsx" },
            })
        } catch (err) {
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:file.upload.title"))
        } finally {
            setIsLoadingFunction(false)
        }
    }

    const onSummaryDownload = async (menuInfo: any) => {
        try {
            switch (activeExpense.kind) {
                case ExpenseKindEnum.PER_DIEM:
                case ExpenseKindEnum.MILEAGE:
                    setSummaryDownloadLoading(true)
                    await FileService.exportExpenseSummary(activeExpense.id, menuInfo.key)
                    break
                default:
                    break
            }
        } catch (error) {
            console.error(`Error on summary download lang: ${menuInfo.key}, kind: ${expense.kind}, error: ${JSON.stringify(error)}`)
        } finally {
            setSummaryDownloadLoading(false)
        }
    }

    const handleRefreshPaymentStatus = async () => {
        try {
            await BankService.refreshPaymentStatus(activeExpense.id)
            NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:banking.refresh_payment_status.title"), t("notification:banking.refresh_payment_status.message"))
        } catch {
            NotificationService.send(NotificationTypeEnum.ERROR, t("error:banking.refresh_payment_status.title"), t("error:banking.refresh_payment_status.message"))
        }
    }

    const handleHardResetPaymentStatus = async () => {
        try {
            await BankService.resetPaymentStatus(activeExpense.id)
            NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:banking.refresh_payment_status.title"), t("notification:banking.refresh_payment_status.message"))
        } catch {
            NotificationService.send(NotificationTypeEnum.ERROR, t("error:banking.refresh_payment_status.title"), t("error:banking.refresh_payment_status.message"))
        }
    }

    return !expense || !expense.id || expense.id !== id || isLoadingExpense || isLoadingSubExpenses ? (
        <Loading />
    ) : (
        <ExpenseDetailsContext
            expense={expense}
            activeExpense={activeExpense}
            folder={isFolderExpense(expense) ? expense : undefined}
            subExpenses={subExpenses ?? []}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            setIsShowingImportProtocolModal={setIsShowingImportProtocolModal}
            isShowingDiscountForm={showDiscountForm}
            setIsShowingDiscountForm={setShowDiscountForm}
            isReadOnly={isReadOnly}
        >
            <div>
                <Helmet>
                    <title>{expenseTitle}</title>
                </Helmet>
                {expense.deleted && (
                    <Alert
                        className="mb-20"
                        message={t("notification:request.already_deleted.title")}
                        type="error"
                        description={t("notification:request.already_deleted.message")}
                        closable={false}
                        showIcon={true}
                    />
                )}
                {showPaymentFailedAlert(activeExpense.status, activeExpense.payment?.bankPaymentInformation) && (
                    <Alert
                        className="mb-20"
                        message={t("error:payment_failed.message")}
                        type="error"
                        description={t("error:payment_failed.description", {
                            message: activeExpense.payment?.bankPaymentInformation?.bankMessage ?? t("error:payment_failed.none"),
                        })}
                        closable={false}
                        showIcon={true}
                    />
                )}
                <DatevOnlineJobBanner expense={activeExpense} />
                {activeExpense.isInPaymentProcess && (
                    <Alert
                        className="mb-20"
                        message={
                            <div className="flex items-center justify-between space-x-20">
                                <div>
                                    <p className="font-bold text-base mb-10">{t("label:payment_pending.message")}</p>
                                    <p>{t("label:payment_pending.description")}</p>
                                </div>
                                <div>
                                    <Button type="primary" onClick={handleRefreshPaymentStatus}>
                                        {t("action:banking.refresh_payment_status")}
                                    </Button>
                                    {loggedInUser.email == FINWAY_ADMIN_EMAIL && (
                                        <Button className="mt-10 btn-danger" onClick={handleHardResetPaymentStatus} icon={<AlertTriangle size={14} />}>
                                            {t("action:banking.force_payment_status_reset")}
                                        </Button>
                                    )}
                                </div>
                            </div>
                        }
                        type="info"
                        description={""}
                        closable={false}
                        showIcon={true}
                    />
                )}
                <ExpenseCreateFormModal
                    type={requestType}
                    hideExpenseKindRadioOption={true}
                    isNew={false}
                    isShowing={isEditModalShowing}
                    kind={expense.kind}
                    onCancel={() => setIsEditModalShowing(false)}
                    folder={isFolderExpense(expense) ? expense : undefined}
                />
                <InboxInvoiceModal
                    isShowing={showInboxModal}
                    onCancel={() => setShowInboxModal(false)}
                    expenses={[activeExpense]}
                    isOcrEnabled={isOcrEnabled}
                    isOcrItemizationEnabled={isOcrItemizationEnabled}
                    preSelectedExpense={activeExpense}
                    preSelectedInvoice={undefined}
                    shouldResetSelectedInboxOnClose
                    setIsShowingDiscountForm={setShowDiscountForm}
                />
                <PageCustomHeader
                    title={
                        <div className="flex items-center space-x-4">
                            <h1 className="mr-6">{expenseTitle}</h1>
                            {expense.isReimbursement && (
                                <Tooltip title={t(`tooltips:reimbursement_types.${getReimbursementLabel(expense.kind)}`)}>
                                    <Tag className="ant-tag-blue">{t(`label:reimbursement_types.${getReimbursementLabel(expense.kind)}`)}</Tag>
                                </Tooltip>
                            )}
                            {displayUserAsTagged && <SharedExpenseTag tagging={expense.userTagging?.filter(({ tagged }: any) => tagged === loggedInUser.id)} />}
                            {AuthzService.isRightGrantedForLoggedInUser(RightEnum.TRANSACTION__ALL__READ) && isMatched(expense) && (
                                <Tag className="ant-tag-green">{t("info:matched")}</Tag>
                            )}
                            <ExportTag expense={expense} />
                            <ExpenseArchiveTags expense={expense} />
                        </div>
                    }
                    actionButtons={
                        isFolderExpense(expense) ? (
                            <FolderActionButtons folder={expense} subExpenses={subExpenses} isEditableExpense={editButtonView.expenseEditable} activeExpense={activeExpense} />
                        ) : (
                            renderActionButtons
                        )
                    }
                    className={showTagsBelowTitle ? "pb-0" : ""}
                />

                {/**
                 * ExpenseTagSection is not put inside a condition because when deleting a tag and showTagsBelowTitle becomes false,
                 * unmounting this directly will cause a memory leak
                 * */}
                <ExpenseTagSection expenseId={expense.id} expenseTags={expense.expenseTags} hideButton hideTags={!showTagsBelowTitle} />
                {showTagsBelowTitle && <div className="pb-24" />}
                {(!splitsHasAnyCostCenter || !expense.costCenter) && !expense.getCreditor() && (
                    <Alert
                        className="mb-20"
                        message={<div className="font-bold">{t("info:missing_vendor_or_cc.title")}</div>}
                        type="warning"
                        description={t("info:missing_vendor_or_cc.message")}
                        closable={false}
                        showIcon={true}
                    />
                )}
                {!splitsHasCompleteCostCenter && expense.getCreditor() && (
                    <Alert
                        className="mb-20"
                        message={<div className="font-bold">{t("info:missing_cc.title")}</div>}
                        type="warning"
                        description={t("info:missing_cc.message")}
                        closable={false}
                        showIcon={true}
                    />
                )}
                {!isFolderExpense(activeExpense) && <ExpenseCreditorAlert vendor={vendor} expense={activeExpense} />}
                {expense.id && (
                    <>
                        <Row>
                            <Col span={24}>
                                <MainDetailsTabs
                                    setIsExpenseUpdating={setIsExpenseUpdating}
                                    setIsEditModalShowing={setIsEditModalShowing}
                                    setActiveExpenseId={setActiveExpenseId}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <div className="grid grid-cols-1 md:grid-cols-5 lg:grid-cols-9 gap-24 grid-rows-auto1fr">
                                    <div className="md:col-span-2 lg:col-span-3">
                                        <ExpenseRightSection
                                            isInvoiceUploading={isInvoiceUploading}
                                            isDeliveryNoteUploading={isDeliveryNoteUploading}
                                            isLoading={isLoading}
                                            nextExpense={nextExpense}
                                            cancelRequest={onCancelRequest}
                                            sendEmailReminder={onSendEmailReminder}
                                            markRequestAsApproved={markRequestAsApproved}
                                            markRequestAsReviewed={markRequestAsReviewed}
                                            markRequestAsPaid={markRequestAsPaid}
                                            doFileUpload={doFileUpload}
                                            payExpense={payExpense}
                                            onInboxSelected={onInboxSelected}
                                            isOcrUploading={isOcrUploading}
                                            isExpenseUpdating={isExpenseUpdating}
                                            setIsExpenseUpdating={setIsExpenseUpdating}
                                        />
                                    </div>
                                    <div className="md:col-span-3 lg:col-span-6 md:row-span-2 md:order-first h-auto">
                                        <ExpenseBottomSectionContainer
                                            expenseToRender={activeExpense}
                                            subExpenses={subExpenses}
                                            doFileUpload={doFileUpload}
                                            onInboxSelected={onInboxSelected}
                                            isUploadingInvoice={isInvoiceUploading}
                                            ocrSettings={{ isOcrEnabled, isOcrItemizationEnabled, setIsOcrEnabled, setIsOcrItemizationEnabled }}
                                            isOcrUploading={isOcrUploading}
                                            isReadOnly={isReadOnly}
                                            isSummaryDownloadLoading={isSummaryDownloadLoading}
                                            onSummaryDownload={onSummaryDownload}
                                        />
                                    </div>
                                    <div className="md:col-span-2 lg:col-span-3">
                                        <Suspense fallback={<></>}>
                                            <CommentContainer
                                                type={CollectionNameEnum.EXPENSE}
                                                comments={activeExpense.comments}
                                                loggedInUser={loggedInUser}
                                                object={activeExpense}
                                            />
                                        </Suspense>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </>
                )}
                {isShowingPaymentConfirmationModal && (
                    <PaymentConfirmationModal
                        isScheduledPayment={isScheduledPayment}
                        isVisible={isShowingPaymentConfirmationModal}
                        expenses={isFolderExpense(activeExpense) ? getFolderProgressionInfo(activeExpense, subExpenses).processableSubExpenses : [activeExpense]}
                        onCancel={() => setIsShowingPaymentConfirmationModal(false)}
                    />
                )}
                {!!activeExpense.datevOnlineInformation?.datevProtocolEntries?.length && (
                    <DuoImportProtocolModal
                        isShowing={isShowingImportProtocolModal}
                        onCancel={() => {
                            setIsShowingImportProtocolModal(false)
                        }}
                        importProtocolEntries={activeExpense.datevOnlineInformation.datevProtocolEntries}
                    />
                )}
            </div>
        </ExpenseDetailsContext>
    )
}

export default ExpenseDetails
