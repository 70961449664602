import { Bank, BankConnection, CurrencyEnum, FinApiActionEnum, MatchingDataInterface } from "@finway-group/shared/lib/models"
import axios from "axios"
import * as HttpStatus from "http-status"
import moment from "moment-timezone"

const MODEL = "banking"

export const handleWebFormError = (err: any, rethrow = false) => {
    if (err.response.status === HttpStatus.UNAVAILABLE_FOR_LEGAL_REASONS) {
        const { webFormUrl } = err.response.data.error
        window.open(webFormUrl, "_blank")
        if (rethrow) throw err
    } else {
        throw err
    }
}

const BankService = {
    fetchBankConnectionById: async (id: string) => {
        const { data } = await axios.get(`/${MODEL}/${id}`)
        return data as BankConnection
    },
    fetchAllBankConnections: async () => {
        const { data } = await axios.get(`/${MODEL}`)
        return data as Array<BankConnection>
    },
    updateBankConnection: async (id: string, updateData: BankConnection) => {
        const { data } = await axios.put(`/${MODEL}/${id}`, updateData)
        return data as BankConnection
    },
    deleteBankConnection: async (id: string) => axios.delete(`/${MODEL}/${id}`),
    deleteBankAccount: async (id: string) => axios.delete(`/${MODEL}/account/${id}`),
    addNewInterface: async (connectionId: string, interfaceToConnect: string) =>
        axios.post(`/${MODEL}/connectinterface`, { connectionId, interfaceToConnect }).catch(handleWebFormError),
    performManualBankConnectionUpdate: async (connectionId: string, accountId?: string, repair: boolean = false) =>
        axios.post(`${MODEL}/update`, { connectionId, accountId, repair }).catch((err) => handleWebFormError(err, true)),
    importBankConnection: async (bankId: string) => axios.post(`/${MODEL}/import`, { bankId }).catch(handleWebFormError),
    issuePayment: async (accountId: string, expenseIds: Array<string>, executionDate?: string, paymentSubjects?: any, shouldBypassAmountCheck?: boolean) =>
        axios.post(`/${MODEL}/pay`, { accountId, expenseIds, executionDate, paymentSubjects, shouldBypassAmountCheck }).catch(handleWebFormError),
    searchBanks: async (searchTerm: string) => {
        const { data } = await axios.post(`/${MODEL}/search`, { searchTerm })
        return data as Array<Bank>
    },
    checkWebFormStatus: async (webFormId: string, action: FinApiActionEnum, data?: any | undefined) => {
        const res = await axios.post(`/${MODEL}/webform/${webFormId}`, { action, data })
        return res.data as BankConnection
    },
    matchBankTransactions: async (accountId: string) => {
        const {
            data: { matchingData },
        } = await axios.post(`/${MODEL}/match`, { accountId })
        return matchingData as Array<MatchingDataInterface>
    },
    revokeConsent: async (connectionId: string) => axios.post(`/${MODEL}/consent/revoke`, { connectionId }),
    fundWallet: async (accountId: string, amount: number, currency: CurrencyEnum, executionDate?: string) => {
        await axios.post(`/${MODEL}/fund-wallet`, { accountId, amount, currency, executionDate }).catch(handleWebFormError)
    },
    getCashBalanceForPeriod: async (startDate: moment.Moment, endDate: moment.Moment) => {
        const { data } = await axios.post(`/${MODEL}/balance`, { startDate: startDate.format("YYYY-MM-DD"), endDate: endDate.format("YYYY-MM-DD") })
        return data
    },
    refreshPaymentStatus: async (expenseId: string) => {
        const { data } = await axios.post(`/${MODEL}/payment-status`, { expenseId })
        return data
    },
    getBankAccounts: async () => {
        const { data } = await axios.get(`/${MODEL}/accounts/all`)
        return data
    },
    resetPaymentStatus: async (expenseId: string) => axios.post(`/${MODEL}/reset-payment-status`, { expenseId }),
}

export default BankService
