import { AccessTokenType, User } from "@finway-group/shared/lib/models"
import { Action } from "redux"

import { WeavrUserOnboardEnum } from "Shared/store/reducers/authReducer"

export enum AuthActionTypes {
    LOGIN = "LOGIN",
    RESET_REDUX_STORE = "RESET_REDUX_STORE",
    TOKEN_REFRESH = "TOKEN_REFRESH",
    TOKEN_REFRESHED = "TOKEN_REFRESHED",
    RESET = "RESET",
    SET_TOKENS = "SET_TOKENS",
    SET_WEAVR_AUTH = "SET_WEAVR_AUTH",
    SET_WEAVR_USER_ONBOARD = "SET_WEAVR_USER_ONBOARD",
    SET_AUTH_USER = "SET_AUTH_USER",
    SET_SHOULD_SHOW_WEAVR_AUTH_MODAL = "SET_SHOULD_SHOW_WEAVR_AUTH_MODAL",
}

export interface LoginAction extends Action<typeof AuthActionTypes.LOGIN> {
    type: typeof AuthActionTypes.LOGIN
    user: User
    accessToken: AccessTokenType
}

export interface SetAuthUserAction extends Action<typeof AuthActionTypes.SET_AUTH_USER> {
    type: typeof AuthActionTypes.SET_AUTH_USER
    user: User
    accessToken: AccessTokenType
}

export interface TokenRefreshAction extends Action<typeof AuthActionTypes.TOKEN_REFRESH> {
    type: typeof AuthActionTypes.TOKEN_REFRESH
}

export interface TokenRefreshedAction extends Action<typeof AuthActionTypes.TOKEN_REFRESHED> {
    type: typeof AuthActionTypes.TOKEN_REFRESHED
}

export interface SetTokensAction extends Action<AuthActionTypes.SET_TOKENS> {
    type: typeof AuthActionTypes.SET_TOKENS
    accessToken: string
}

export interface SetIsWeavrAuthenticatedAction extends Action<typeof AuthActionTypes.SET_WEAVR_AUTH> {
    type: typeof AuthActionTypes.SET_WEAVR_AUTH
    isWeavrAuthenticated: boolean
}

export interface SetIsWeavrOnboardAction extends Action<typeof AuthActionTypes.SET_WEAVR_USER_ONBOARD> {
    type: typeof AuthActionTypes.SET_WEAVR_USER_ONBOARD
    isWeavrUserOnboard: WeavrUserOnboardEnum
}

export interface SetShouldShowWeavrAuthModalAction extends Action<typeof AuthActionTypes.SET_SHOULD_SHOW_WEAVR_AUTH_MODAL> {
    type: typeof AuthActionTypes.SET_SHOULD_SHOW_WEAVR_AUTH_MODAL
    shouldShowWeavrAuthModal: boolean
}

export interface ResetRedux extends Action<typeof AuthActionTypes.RESET_REDUX_STORE> {
    type: typeof AuthActionTypes.RESET_REDUX_STORE
}

export type AuthActions =
    | LoginAction
    | TokenRefreshAction
    | TokenRefreshedAction
    | SetTokensAction
    | SetIsWeavrAuthenticatedAction
    | SetIsWeavrOnboardAction
    | SetAuthUserAction
    | SetShouldShowWeavrAuthModalAction
