import { Col, Row } from "antd"
import { FormInstance } from "antd/lib/form/Form"
import React from "react"
import { useTranslation } from "react-i18next"

import WeavrEmailVerificationForm from "Components/forms/weavr/weavrEmailVerification.form"

interface KYBEmailVerificationInterface {
    formInstance: FormInstance
    onSubmit: () => void
    setIsLoading: (flag: boolean) => void
}

const KYBEmailVerification: React.FC<KYBEmailVerificationInterface> = ({ formInstance, onSubmit, setIsLoading }) => {
    const { t } = useTranslation()

    return (
        <div>
            <div className="flex items-center">
                <h2 className="ml-10">{t("corporate:verification.email.title")}</h2>
            </div>
            <Row gutter={[16, 0]}>
                <Col span={20}>
                    <div className="ml-10">{t("info:corporate.email_verification")}</div>
                </Col>
            </Row>
            <WeavrEmailVerificationForm formInstance={formInstance} setIsLoading={setIsLoading} onSubmit={onSubmit} />
        </div>
    )
}

export default KYBEmailVerification
