import { CreditorInterface, Vendor, VendorTypeEnum } from "@finway-group/shared/lib/models"
import { Tooltip } from "antd"
import React from "react"
import { AlertTriangle } from "react-feather"
import { useTranslation } from "react-i18next"

import DeletedTooltip from "Components/deletedTooltip"
import { getTooltipPopupContainer } from "Shared/utils/helper.utils"

interface CreditorOptionLabelInterface {
    creditor: CreditorInterface
}
export const CreditorOptionLabel: React.FC<CreditorOptionLabelInterface> = ({ creditor }) => {
    const { t } = useTranslation()
    return (
        <div className="flex flex-row items-center">
            <div>{creditor.name}</div>
            {creditor.type === VendorTypeEnum.USER && !creditor?.creditorNumber && (
                <div>
                    <Tooltip getPopupContainer={getTooltipPopupContainer} title={t("info:employee_no_creditor_number")}>
                        <AlertTriangle fill="#FBAC3B" size={18} stroke="#FFFFFF" className="ml-2 mb-2" />
                    </Tooltip>
                </div>
            )}
            {creditor.type === VendorTypeEnum.VENDOR && !(creditor.source as Vendor).isApproved && (
                <Tooltip getPopupContainer={getTooltipPopupContainer} title={t("info:pending_vendor")}>
                    <AlertTriangle fill="#FBAC3B" size={18} stroke="#FFFFFF" className="ml-2 mb-2" />
                </Tooltip>
            )}
            {creditor.source.deleted && <DeletedTooltip translationKey="tooltips:vendor.was_deleted" deletionDate={creditor.source.dateDeleted!} />}
        </div>
    )
}
