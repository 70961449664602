import { Vendor } from "@finway-group/shared/lib/models"
import { Reducer } from "redux"

import {
    DeleteVendorAction,
    FetchAllVendorsAction,
    FetchOneVendorAction,
    NewVendorAction,
    SyncVendorAction,
    UpdateVendorAction,
    VendorActionTypes,
    VendorActions,
} from "../actions/vendor/vendorTypes"

export interface VendorState {
    items: Array<Vendor>
    item?: Vendor
}

const initialState: VendorState = {
    items: [],
    item: undefined,
}

const fetchAllVendors = (state: VendorState, action: FetchAllVendorsAction): VendorState => {
    let { item } = state
    if (item) {
        const itemInAction = action.vendors.find(({ id }: Vendor) => id === item?.id)
        if (itemInAction) item = itemInAction
    }

    return {
        ...state,
        items: action.vendors,
        item,
    }
}

const fetchOneVendor = (state: VendorState, action: FetchOneVendorAction): VendorState => ({
    ...state,
    item: action.vendor,
})

const deleteOneVendor = (state: VendorState, action: DeleteVendorAction): VendorState => ({
    ...state,
    items: state.items.filter((vendor) => vendor.id !== action.vendorId),
})

const syncVendor = (state: VendorState, { vendor }: SyncVendorAction): VendorState => ({
    ...state,
    items: state.items.filter((vend) => vend.id === vendor.id).length > 0 ? state.items.map((item) => (item.id === vendor.id ? vendor : item)) : [vendor, ...state.items],
    item: state.item?.id === vendor.id ? vendor : state.item,
})

const newVendor = (state: VendorState, action: NewVendorAction): VendorState => ({
    ...state,
    items: [...state.items, action.vendor],
})

const updateVendor = (state: VendorState, action: UpdateVendorAction): VendorState => ({
    ...state,
    item: action.vendor,
    items: [...state.items.filter((element) => element.id !== action.vendor.id), action.vendor],
})

export const vendorReducer: Reducer<VendorState, VendorActions> = (state = initialState, action) => {
    switch (action.type) {
        case VendorActionTypes.FETCH_ALL_VENDORS:
            return fetchAllVendors(state, action)
        case VendorActionTypes.FETCH_ONE_VENDOR:
            return fetchOneVendor(state, action)
        case VendorActionTypes.SYNC_VENDOR:
            return syncVendor(state, action)
        case VendorActionTypes.NEW_VENDOR:
            return newVendor(state, action)
        case VendorActionTypes.UPDATE_VENDOR:
            return updateVendor(state, action)
        case VendorActionTypes.DELETE_VENDOR:
            return deleteOneVendor(state, action)
        default:
            return state
    }
}
