import { ForecastChange, LiquidityForecastRule, LiquidityScenario } from "@finway-group/shared/lib/models"
import axios from "axios"

const SCENARIO_MODEL = "liquidity/scenarios"
const FORECAST_RULE_MODEL = "liquidity/rules"

const LiquidityService = {
    getScenario: async (id: string) => {
        const { data } = await axios.get(`/${SCENARIO_MODEL}/${id}`)
        return data as LiquidityScenario
    },

    getAllScenarios: async () => {
        const { data } = await axios.get(`/${SCENARIO_MODEL}`)
        return data as Array<LiquidityScenario>
    },

    createScenario: async ({ name, color, parentScenario }: LiquidityScenario) => {
        const { data } = await axios.post(`/${SCENARIO_MODEL}`, { name, color, parentScenario })
        return data as LiquidityScenario
    },

    updateScenario: async (id: string, updateScenario: LiquidityScenario) => {
        const { data } = await axios.put(`/${SCENARIO_MODEL}/${id}`, updateScenario)
        return data as LiquidityScenario
    },

    deleteScenario: async (id: string) => {
        await axios.delete(`/${SCENARIO_MODEL}/${id}`)
        return {}
    },

    getCashFlow: async ({ interval, scenarioId }: any) => {
        const { data } = await axios.post(`liquidity/cashflow`, { interval, scenarioId })
        return data
    },

    updateCashFlowForecast: async (forecastChanges: Array<ForecastChange>, isGeneralized: boolean, scenarioId?: string) => {
        const { data } = await axios.patch(`liquidity/cashflow/forecast`, { forecastChanges, scenarioId, isGeneralized })
        return data
    },

    getForecastRule: async (id: string) => {
        const { data } = await axios.get(`/${FORECAST_RULE_MODEL}/${id}`)
        return data as LiquidityForecastRule
    },

    getAllForecastRules: async () => {
        const { data } = await axios.get(`/${FORECAST_RULE_MODEL}?limit=100`)
        return data.docs as Array<LiquidityForecastRule>
    },

    createForecastRule: async (forecastRule: LiquidityForecastRule) => {
        const { data } = await axios.post(`/${FORECAST_RULE_MODEL}`, forecastRule)
        return data as LiquidityForecastRule
    },

    updateForecastRule: async (id: string, updateForecastRule: LiquidityForecastRule) => {
        const { data } = await axios.put(`/${FORECAST_RULE_MODEL}/${id}`, updateForecastRule)
        return data as LiquidityForecastRule
    },

    deleteForecastRule: async (id: string) => {
        await axios.delete(`/${FORECAST_RULE_MODEL}/${id}`)
        return {}
    },
}

export default LiquidityService
