import { AccessTokenType, Employee, LoginInput, User } from "@finway-group/shared/lib/models"
import * as HttpStatus from "http-status"
import jwt from "jsonwebtoken"
import { Dispatch } from "redux"

import { AuthnService, LocalStorageService } from "Shared/services"
import { WeavrUserOnboardEnum } from "Shared/store/reducers/authReducer"

import { EmployeeActionTypes, UpdateEmployeeAction } from "../employee/employeeTypes"
import { FetcherActionTypes, StartLoadingAction } from "../fetcher/fetcherTypes"
import { AuthActionTypes, LoginAction, SetAuthUserAction, SetIsWeavrAuthenticatedAction, SetIsWeavrOnboardAction, SetShouldShowWeavrAuthModalAction } from "./authTypes"

export const createLoginAction = (accessTokenEncoded: string): LoginAction => {
    const accessToken = jwt.decode(accessTokenEncoded) as AccessTokenType
    const user = new User(accessToken)
    return {
        type: AuthActionTypes.LOGIN,
        user,
        accessToken,
    }
}

export const login = (loginInput: LoginInput) => async (dispatch: Dispatch) => {
    // prettier-ignore
    const { data: { accessToken, companyId, passwordResetToken }, status, headers } = await AuthnService.login(loginInput)

    if (status === HttpStatus.PARTIAL_CONTENT) {
        throw { response: { data: {}, status, headers } }
    }

    LocalStorageService.setAccessToken(accessToken)

    // after login show loading indicator
    const setLoadingAction: StartLoadingAction = {
        type: FetcherActionTypes.START_LOADING,
        hasLoaded: false,
    }
    dispatch(setLoadingAction)

    // @todo check if we can return the auth user from the api and remove the jwt lib from the frontend
    const loginAction = createLoginAction(accessToken)

    dispatch(loginAction)
}

export const resetReduxStore = () => ({
    type: AuthActionTypes.RESET_REDUX_STORE,
})

export const refreshAuthUser = () => async (dispatch: Dispatch) => {
    const accessToken = LocalStorageService.getAccessToken()
    if (!accessToken) return

    const accessTokenDecoded = jwt.decode(accessToken) as AccessTokenType
    const user = new User(accessTokenDecoded)

    const setAuthUserAction: SetAuthUserAction = {
        type: AuthActionTypes.SET_AUTH_USER,
        user,
        accessToken: accessTokenDecoded,
    }

    dispatch(setAuthUserAction)
}

export const updatePassword = (loginInput: LoginInput) => async (dispatch: Dispatch) => {
    const { data } = await AuthnService.updatePassword(loginInput)
    LocalStorageService.setAccessToken(data.accessToken)

    // @todo return user from api and delete jwt lib
    const loginAction = createLoginAction(data.accessToken)
    dispatch(loginAction)
    return loginAction.user
}

export const setupTwoFactorAuth = (token: string) => async (dispatch: Dispatch) => {
    const {
        data: { user },
    } = await AuthnService.setupTfAuth(token)

    const { companyId } = AuthnService.getDataFromAccessToken()

    const employee = new Employee(user)
    if (companyId) employee.setActiveCompanyProfile(companyId)

    const updateSettingsAction: UpdateEmployeeAction = {
        type: EmployeeActionTypes.UPDATE_EMPLOYEE,
        employee,
    }

    dispatch(updateSettingsAction)
    return employee
}

export const setupSMSTwoFactorAuth = () => async (dispatch: Dispatch) => {
    const {
        data: { user },
    } = await AuthnService.enableSmsTfAuth()

    const { companyId } = AuthnService.getDataFromAccessToken()
    const employee = new Employee(user)
    if (companyId) employee.setActiveCompanyProfile(companyId)

    const updateSettingsAction: UpdateEmployeeAction = {
        type: EmployeeActionTypes.UPDATE_EMPLOYEE,
        employee,
    }

    dispatch(updateSettingsAction)
    return employee
}

export const disableTwoFactorAuth = () => async (dispatch: Dispatch) => {
    const {
        data: { user },
    } = await AuthnService.disableTfAuth()

    const { companyId } = AuthnService.getDataFromAccessToken()
    const employee = new Employee(user)
    if (companyId) employee.setActiveCompanyProfile(companyId)

    const updateSettingsAction: UpdateEmployeeAction = {
        type: EmployeeActionTypes.UPDATE_EMPLOYEE,
        employee,
    }

    dispatch(updateSettingsAction)
    return employee
}

export const setIsWeavrAuthenticated = (isWeavrAuthenticated: boolean, token?: string) => async (dispatch: Dispatch) => {
    // handle the token
    if (!isWeavrAuthenticated) {
        LocalStorageService.unsetWeavrToken()
    } else if (token) {
        LocalStorageService.setWeavrToken(token)
    }

    const setIsWeavrAuthenticatedAction: SetIsWeavrAuthenticatedAction = {
        type: AuthActionTypes.SET_WEAVR_AUTH,
        isWeavrAuthenticated,
    }

    dispatch(setIsWeavrAuthenticatedAction)

    // when we're authenticated, we don't have to show the modal anymore
    if (setIsWeavrAuthenticatedAction) {
        setShouldShowWeavrAuthModal(false)(dispatch)
    }

    return isWeavrAuthenticated
}

export const setIsWeavrUserOnboard = (isWeavrUserOnboard: WeavrUserOnboardEnum) => async (dispatch: Dispatch) => {
    const setIsWeavrOnboardAction: SetIsWeavrOnboardAction = {
        type: AuthActionTypes.SET_WEAVR_USER_ONBOARD,
        isWeavrUserOnboard,
    }

    dispatch(setIsWeavrOnboardAction)
    return isWeavrUserOnboard
}

export const setShouldShowWeavrAuthModal = (shouldShowWeavrAuthModal: boolean) => async (dispatch: Dispatch) => {
    const setShouldShowWeavrAuthModalAction: SetShouldShowWeavrAuthModalAction = {
        type: AuthActionTypes.SET_SHOULD_SHOW_WEAVR_AUTH_MODAL,
        shouldShowWeavrAuthModal,
    }

    dispatch(setShouldShowWeavrAuthModalAction)
    return shouldShowWeavrAuthModal
}
