import { ExpenseAccount } from "@finway-group/shared/lib/models"
import { Form, Select } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"

import { NOT_SET_VALUE } from "Shared/config/consts"
import { useCompany } from "Shared/hooks/company.hooks"
import { useExpenseAccounts } from "Shared/hooks/expenseAccount.hooks"
import { doFilterSelect } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

import { useMainDetailsSectionContext } from "../mainDetailsSection.context"
import { ExpenseSplitCellInterface } from "./expenseSplitCellInterface"

const ExpenseAccountCell: React.FC<ExpenseSplitCellInterface> = ({ field, editable, splitIndex }) => {
    const { splits, onSplitUpdate } = useMainDetailsSectionContext()
    const currentSplit = splits[splitIndex]
    const { t } = useTranslation()
    const expenseAccounts = useExpenseAccounts()
    const company = useCompany()

    const [embeddedExpenseAccount] = useStateIfMounted(currentSplit.expenseAccount)

    const getSelectOptionLabel = (expenseAccount: ExpenseAccount) =>
        currentSplit.expenseAccount?._id === expenseAccount._id
            ? `${currentSplit.expenseAccount.accountName} ${currentSplit.expenseAccount.accountCode}`
            : `${expenseAccount.accountName} ${expenseAccount.accountCode}`

    const generateCell = () => {
        if (!editable && currentSplit.expenseAccount?._id) if (!editable) return <div>{currentSplit.expenseAccount?.accountName}</div>
        return (
            <Form.Item name={[field.name, "expenseAccount", "_id"]} key="expenseAccount" className="mb-0">
                <Select
                    showSearch
                    filterOption={doFilterSelect}
                    className="text-xs md:text-base no-padding-select"
                    dropdownMatchSelectWidth={false}
                    bordered={false}
                    onChange={() => onSplitUpdate(splitIndex)}
                    placeholder={t("label:not_set")}
                    dropdownClassName="z-50"
                >
                    {/** Dont allow user to pick "Not set" value when gobd compliance is enforced. */}
                    {!company.gobdCompliance?.enforceGobdCompliantInvoice && <Select.Option value={NOT_SET_VALUE}>{t("label:not_set")}</Select.Option>}
                    {expenseAccounts.map((expenseAccount: ExpenseAccount, index) => (
                        <Select.Option value={expenseAccount._id} label={getSelectOptionLabel(expenseAccount)} key={index}>
                            {embeddedExpenseAccount?._id === expenseAccount._id && embeddedExpenseAccount.accountName // check if the embedded is data is unpopulated or not. If not, fallback to the expense account array
                                ? `${embeddedExpenseAccount.accountName} - ${embeddedExpenseAccount.accountCode}`
                                : `${expenseAccount.accountName} - ${expenseAccount.accountCode}`}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
        )
    }
    return generateCell()
}

export default ExpenseAccountCell
