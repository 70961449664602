import { CostCenter, PriceIntervalEnum } from "@finway-group/shared/lib/models"
import { Button, Card, Row } from "antd"
import React, { memo, useMemo } from "react"
import { useTranslation } from "react-i18next"

import { getBudgetColorBars } from "Shared/utils/costCenter.utils"

import { ColorsBar } from "./colorsBar"
import Loading from "./loading"
import PriceLabel from "./priceLabel"

interface BudgetUsageProgressBarInterface {
    isLoading: boolean
    budgetUsage: { consumption: number; forecast: number; capacity: number }
    interval: PriceIntervalEnum
    costCenter: CostCenter
}

const BudgetUsageProgressBar: React.FC<BudgetUsageProgressBarInterface> = ({ isLoading, budgetUsage, interval, costCenter }) => {
    const { t } = useTranslation()
    const colorBars = useMemo(() => getBudgetColorBars(budgetUsage.consumption, budgetUsage.forecast, budgetUsage.capacity, "h-22"), [budgetUsage])
    return (
        <>
            {isLoading ? (
                <Loading className="h-60" margin={false} />
            ) : (
                <div className="animation-appear">
                    <Row className="block" id="cost-center-budget-section">
                        <span className="text-text-dark mb-4 leading-none float-left ml-2">
                            <PriceLabel value={budgetUsage.consumption} currency={costCenter.budgetCurrency} interval={interval} />
                        </span>
                        {budgetUsage.capacity > 0 && (
                            <span className="float-right mb-4 text-xs mr-10 opacity-75">
                                <PriceLabel value={budgetUsage.capacity} currency={costCenter.budgetCurrency} interval={interval} />
                            </span>
                        )}
                    </Row>
                    <Row className="block">
                        <ColorsBar bars={colorBars} />
                        <div className="w-full flex justify-center">
                            <PriceLabel value={budgetUsage?.forecast} currency={costCenter.budgetCurrency} interval={interval} /> {t("label:forecast")}
                        </div>
                    </Row>
                </div>
            )}
        </>
    )
}

export default memo(BudgetUsageProgressBar)
