import { CurrencyEnum, KYBStatusEnum, KYBStepsEnum, Transaction } from "@finway-group/shared/lib/models"
import { Reducer } from "redux"

import {
    CorporateActionTypes,
    CorporateActions,
    FetchKYBProgressAction,
    ResetWalletAction,
    SetCurrentKYBStepAction,
    SetKYBDataAction,
    SetWalletAction,
} from "../actions/corporate/corporateTypes"

export enum KYBRejectionReasonEnum {
    ROOT_USER_MUST_BE_DIRECTOR_REPRESENTATIVE = "ROOT_USER_MUST_BE_DIRECTOR_REPRESENTATIVE",
    DOCUMENTS_UNSATISFACTORY = "DOCUMENTS_UNSATISFACTORY",
    SUPPLIED_DATA_AND_DOCUMENTS_MISMATCH = "SUPPLIED_DATA_AND_DOCUMENTS_MISMATCH",
    LANGUAGE_IN_DOCUMENTS_UNSUPPORTED = "LANGUAGE_IN_DOCUMENTS_UNSUPPORTED",
    SELFIE_UNSATISFACTORY = "SELFIE_UNSATISFACTORY",
    SELFIE_AND_DOCUMENTS_MISMATCH = "SELFIE_AND_DOCUMENTS_MISMATCH",
    CORPORATE_DETAILS_UNSATISFACTORY = "CORPORATE_DETAILS_UNSATISFACTORY",
    BENEFICIARY_DETAILS_UNSATISFACTORY = "BENEFICIARY_DETAILS_UNSATISFACTORY",
    REPRESENTATIVE_DETAILS_UNSATISFACTORY = "REPRESENTATIVE_DETAILS_UNSATISFACTORY",
    ROOT_USER_UNMATCHED_TO_DIRECTOR_OR_REPRESENTATIVE = "ROOT_USER_UNMATCHED_TO_DIRECTOR_OR_REPRESENTATIVE",
    OTHER = "OTHER",
}

export interface KYBData {
    corporateId: string | undefined
    isPasswordCreated: boolean
    isEmailVerified: boolean
    isMobileVerified: boolean
    isWalletCreated: boolean
    kybStatus: KYBStatusEnum | undefined
    reason: KYBRejectionReasonEnum | undefined
}

export interface Wallet {
    beneficiary: string
    accountId: string
    iban: string
    bic: string
    balance: number
    currency: CurrencyEnum
    transactions: Array<Transaction>
}

export interface CorporateState {
    currentKYBStep: KYBStepsEnum
    wallet: Wallet
    kybData: KYBData
}

const initialState: CorporateState = {
    currentKYBStep: KYBStepsEnum.COMPANY_DETAILS,
    wallet: {
        beneficiary: "",
        accountId: "",
        iban: "",
        bic: "",
        balance: 0,
        currency: CurrencyEnum.EUR,
        transactions: [],
    },
    kybData: {
        corporateId: undefined,
        isPasswordCreated: false,
        isEmailVerified: false,
        isMobileVerified: false,
        isWalletCreated: false,
        kybStatus: undefined,
        reason: undefined,
    },
}

const fetchKYBProgressAction = (state: CorporateState, action: FetchKYBProgressAction): CorporateState => ({
    ...state,
    currentKYBStep: action.currentKYBStep,
    kybData: action.kybData,
})

const setKYBData = (state: CorporateState, action: SetKYBDataAction): CorporateState => ({
    ...state,
    kybData: action.kybData,
})

const setCurrentKYBStep = (state: CorporateState, action: SetCurrentKYBStepAction): CorporateState => ({
    ...state,
    currentKYBStep: action.currentKYBStep,
})

const setWallet = (state: CorporateState, action: SetWalletAction): CorporateState => ({
    ...state,
    wallet: action.wallet,
})

const resetWallet = (state: CorporateState, _action: ResetWalletAction): CorporateState => ({
    ...state,
    wallet: initialState.wallet,
})

export const corporateReducer: Reducer<CorporateState, CorporateActions> = (state = initialState, action) => {
    switch (action.type) {
        case CorporateActionTypes.FETCH_KYB_PROGRESS:
            return fetchKYBProgressAction(state, action)
        case CorporateActionTypes.SET_CURRENT_KYB_STEP:
            return setCurrentKYBStep(state, action)
        case CorporateActionTypes.SET_KYB_DATA:
            return setKYBData(state, action)
        case CorporateActionTypes.SET_WALLET:
            return setWallet(state, action)
        case CorporateActionTypes.RESET_WALLET:
            return resetWallet(state, action)
        default:
            return state
    }
}
