import { CloseCircleFilled } from "@ant-design/icons"
import { CreditorInterface, Expense } from "@finway-group/shared/lib/models"
import { Alert, Button } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import EmployeeCreditorNumberModal from "Components/modals/employeeCreditorNumber.modal"
import { useModal } from "Shared/context/modal.context"
import { ExpenseHttpService, ExpenseService, NotificationService } from "Shared/services"
import { syncExpense } from "Shared/store/actions/expense/expenseActions"

const ExpenseCreditorAlert = ({ vendor, expense }: { vendor?: CreditorInterface; expense: Expense }) => {
    const { showModal } = useModal()
    const { t } = useTranslation()

    const dispatch = useDispatch()

    if (!vendor) return <></>

    // if the expense's reference have creditor number, all is good.
    const creditorNumberRefMissing = ExpenseService.isCreditorNumberReferenceMissing(expense)
    if (!creditorNumberRefMissing) return <></>

    const syncExpenseCreditor = async () => {
        try {
            await ExpenseHttpService.syncExpenseCreditor(expense.id)
            await syncExpense(expense.id)(dispatch)
        } catch (err) {
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:error"))
        }
    }

    // if the expense reference does not have a creditor number, but the source have it, show a message to synchronize.
    const creditorNumberMissingFromSource = ExpenseService.isCreditorNumberMissing(expense, vendor)
    if (!creditorNumberMissingFromSource)
        return (
            <Alert
                className="mb-20"
                icon={
                    <div className="mt-4">
                        <CloseCircleFilled />
                    </div>
                }
                message={
                    <>
                        <div className="flex justify-between ml-8 pr-24">
                            <div className="">
                                <div className="font-bold">{t("error:employee.creditor_number_unsynced.title")}</div>
                                <div className="max-w-4xl">{t("error:employee.creditor_number_unsynced.message")}</div>
                            </div>

                            <div className="flex items-center">
                                <Button key="submit" type="default" className="btn-highlight-gray min-w-120" ghost onClick={syncExpenseCreditor}>
                                    {t("action:sync")}
                                </Button>
                            </div>
                        </div>
                    </>
                }
                type="warning"
                closable={false}
                showIcon={true}
            />
        )

    return (
        <Alert
            className="mb-20"
            icon={
                <div className="mt-4">
                    <CloseCircleFilled />
                </div>
            }
            message={
                <>
                    <div className="flex justify-between ml-8 pr-24">
                        <div className="">
                            <div className="font-bold">{t("error:employee.no_creditor_number.title")}</div>
                            <div className="max-w-4xl">{t("error:employee.no_creditor_number.message")}</div>
                        </div>

                        <div className="flex items-center">
                            <Button
                                key="submit"
                                type="default"
                                className="btn-highlight-gray min-w-120"
                                ghost
                                onClick={() =>
                                    showModal(EmployeeCreditorNumberModal, false, { isShowing: true, employee: vendor.source, onCreditorNumberChangeSuccess: syncExpenseCreditor })
                                }
                            >
                                {t("action:employee.add_creditor_number")}
                            </Button>
                        </div>
                    </div>
                </>
            }
            type="error"
            closable={false}
            showIcon={true}
        />
    )
}

export default ExpenseCreditorAlert
