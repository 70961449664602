import { Employee, RightEnum, SystemRightEnum } from "@finway-group/shared/lib/models"
import { Button } from "antd"
import httpStatus from "http-status"
import React, { useState } from "react"
import { Plus as PlusIcon, User as UserIcon } from "react-feather"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { useDebouncedCallback } from "use-debounce/lib"

import Filter from "Components/filter/filter"
import PageTitle from "Components/page/pageTitle"
import SearchInput from "Components/searchInput"
import EmployeesList from "Features/pages/employees/employees.list"
import { useAreCardsActivated } from "Shared/hooks/corporate.hooks"
import { useEmployees } from "Shared/hooks/employee.hooks"
import { applyFilter } from "Shared/hooks/filter.hooks"
import { useRoles } from "Shared/hooks/role.hooks"
import { useTable } from "Shared/hooks/table.hooks"
import { useTeams } from "Shared/hooks/team.hooks"
import { useLoggedInEmployeeProfile } from "Shared/hooks/user.hooks"
import { RoutePathEnum } from "Shared/router/RoutePath.enum"
import { AuthzService, CorporateService, NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { ThunkDispatchResult } from "Shared/store"
import { updateTableSearch } from "Shared/store/actions/tables/tableActions"
import { TablesEnum } from "Shared/store/reducers/tableConfigReducer"
import { EmployeeWithExpenseData } from "Shared/store/reducers/tableReducer"
import { getEmployeeLocations } from "Shared/utils/getter.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

const EmployeesPage = () => {
    const { t } = useTranslation()
    const dispatch: ThunkDispatchResult = useDispatch()
    const history = useHistory()

    const loggedInUser = useLoggedInEmployeeProfile()
    const employeesTable = useTable<EmployeeWithExpenseData>(TablesEnum.EMPLOYEES)
    const cardsActive = useAreCardsActivated()
    const hasUnenrolledEmployees = cardsActive && employeesTable.data.docs.find((emp: Employee) => !emp?.activeCompanyProfile?.weavrData?.userId)

    const [isFilterModalShowing, setIsFilterModalShowing] = useState(false)

    const handleEmployeeSearch = useDebouncedCallback((searchString) => dispatch(updateTableSearch(TablesEnum.EMPLOYEES, searchString)), 800)

    const teams = useTeams()
    const employees = useEmployees({ excludeDeleted: true })
    const locations = getEmployeeLocations(employees)
    const roles = useRoles({ excludeDeleted: true })

    const [isEnrolling, setIsEnrolling] = useStateIfMounted(false)
    const enrollAll = () => {
        setIsEnrolling(true)
        CorporateService.enrollAll()
            .then(() => NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:cards.all_enrolled.title"), t("notification:cards.all_enrolled.message")))
            .catch((err) => {
                if (err.response?.status === httpStatus.UNPROCESSABLE_ENTITY) {
                    NotificationService.send(NotificationTypeEnum.WARNING, t("notification:cards.not_all_enrolled.title"), t("notification:cards.not_all_enrolled.message"))
                } else {
                    NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:error"))
                }
            })
            .finally(() => setIsEnrolling(false))
    }

    return (
        <>
            <PageTitle titleKey={"view:employees.title"}>
                {(employeesTable.data.totalDocs > 0 || employeesTable.data.search) && (
                    <SearchInput className="min-w-32" onSearch={(searchString) => handleEmployeeSearch.callback(searchString)} value={employeesTable.data.search} />
                )}
                <Filter
                    table={TablesEnum.EMPLOYEES}
                    isFilterModalShowing={isFilterModalShowing}
                    setIsFilterModalShowing={setIsFilterModalShowing}
                    onFilter={(data) => applyFilter(TablesEnum.EMPLOYEES, data)}
                    options={{ teams, locations, employees, roles }}
                />

                <div className="flex gap-10 flex-wrap">
                    {AuthzService.isRightGrantedForLoggedInUser(RightEnum.CARD__ALL__WRITE) && hasUnenrolledEmployees && loggedInUser?.activeCompanyProfile.weavrData?.userId && (
                        <Button className="min-w-120" onClick={enrollAll} loading={isEnrolling}>
                            <UserIcon />
                            <span>{t("creditCard:action.enroll_all_users")}</span>
                        </Button>
                    )}
                    {AuthzService.isSystemRightGrantedForLoggedInUser(SystemRightEnum.SYS__USER__ALL__READ) && (
                        <Button icon={<PlusIcon />} onClick={() => history.push(RoutePathEnum.SETTINGS_USERS)} type="primary">
                            {t("action:user.create")}
                        </Button>
                    )}
                </div>
            </PageTitle>

            <EmployeesList />
        </>
    )
}

export default EmployeesPage
