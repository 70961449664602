import { Button, Checkbox, Modal } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"

import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

interface RecurringDebitAgreementModalProps {
    isShowing: boolean
    onCancel: (event: any) => void
    onConfirm: () => void
}

const RecurringDebitAgreementModal = ({ isShowing, onCancel, onConfirm }: RecurringDebitAgreementModalProps) => {
    const { t } = useTranslation()

    const [isCheckboxChecked, setIsCheckboxChecked] = useStateIfMounted(false)

    const handleCheckboxClick = (e: any) => {
        setIsCheckboxChecked(e.target.checked)
    }

    return (
        <Modal
            title={t("confirm:direct_debit_agreement.title")}
            visible={isShowing}
            maskClosable={false}
            className="ant-modal--large"
            onCancel={onCancel}
            keyboard
            footer={[
                <Button key="back" onClick={onCancel}>
                    {t("action:cancel")}
                </Button>,
                <Button key="submit" type="primary" onClick={onConfirm} disabled={!isCheckboxChecked}>
                    {t("action:confirm")}
                </Button>,
            ]}
        >
            <p className="whitespace-pre-line">{t("confirm:direct_debit_agreement.message")}</p>
            <div className="flex items-center mt-20">
                <Checkbox checked={isCheckboxChecked} className="finway-green-checkbox" onChange={handleCheckboxClick} />
                <p className="ml-10 inline-block text-lg">{t("action:accept_sepa_debit_agreement")}</p>
            </div>
        </Modal>
    )
}

export default RecurringDebitAgreementModal
