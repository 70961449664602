import { DatePicker } from "antd"
import moment from "moment"
import React from "react"
import { useDispatch } from "react-redux"

import { useInterval } from "Shared/hooks/liquidity.hooks"
import { setShouldFetchBankBalanceData, setShouldFetchCashFlowsData, updateInterval } from "Shared/store/actions/liquidity/liquidityActions"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

interface DatePickerInterface {
    className: string
}

const { RangePicker } = DatePicker

const PeriodRangePicker: React.FC<DatePickerInterface> = ({ className }) => {
    const dateInterval = useInterval()
    const dispatch = useDispatch()
    const [isFirstDateSelected, setIsFirstDateSelected] = useStateIfMounted(false) // this boolean is to check if both the start date and end date are selected. Only in this case, an interval update is performed.

    const onDatePickerChange = async (values: [moment.Moment, moment.Moment] | null) => {
        if (isFirstDateSelected) {
            setIsFirstDateSelected(false)
            dispatch(updateInterval(values || undefined))
            dispatch(setShouldFetchCashFlowsData(true))
            dispatch(setShouldFetchBankBalanceData(true))
        } else {
            setIsFirstDateSelected(true)
        }
    }

    return (
        <RangePicker
            className={className}
            size="small"
            format={"MMMM YYYY"}
            picker="month"
            onChange={onDatePickerChange}
            value={[moment(dateInterval?.[0]), moment(dateInterval?.[1])]}
        />
    )
}
export default React.memo(PeriodRangePicker)
