import { PriceIntervalEnum } from "@finway-group/shared/lib/models"
import { toAmount, toDinero } from "@finway-group/shared/lib/utils"
import React from "react"

import CurrencyInput from "Components/currencyInput"
import FormItemCurrency from "Components/form/formItemCurrency"
import PriceLabel from "Components/priceLabel"

import { useMainDetailsSectionContext } from "../mainDetailsSection.context"
import { AmountCellInterface } from "./expenseSplitCellInterface"

const AmountCell: React.FC<AmountCellInterface> = ({ field, splitIndex, priceField, split, smallDisplay }) => {
    const { onPriceAdjustment, expense, isEditing } = useMainDetailsSectionContext()

    const roundedAmount = toDinero(split[priceField])

    const generateCell = () => {
        if (!isEditing)
            return (
                <div data-testid="currencyValue" className="text-xs md:text-base">
                    <PriceLabel value={toAmount(roundedAmount)} currency={expense.currency} interval={PriceIntervalEnum.ONE_TIME} showFullNumber />
                </div>
            )

        // return form when the expense doesnt have items, only split
        return (
            <FormItemCurrency
                currency={expense.currency}
                name={[field.name, priceField]}
                key={`${priceField}`}
                className="mb-0 text-xs md:text-base overflow-x-visible w-full flex justify-end border-b-1"
            >
                <CurrencyInput
                    className={`p-0 ${smallDisplay ? "w-60" : "xl:w-100 xs:w-60"}`}
                    value={toAmount(roundedAmount)}
                    currency={expense.currency}
                    onChange={(value) => onPriceAdjustment(splitIndex, priceField, value)}
                />
            </FormItemCurrency>
        )
    }

    return generateCell()
}

export default AmountCell
