import { Employee } from "@finway-group/shared/lib/models"
import { Input, Menu } from "antd"
import React, { MouseEvent } from "react"
import { useTranslation } from "react-i18next"

import UserImage from "Components/UserImage"
import { KeySelector, useSearch } from "Shared/hooks/search.hooks"

interface EmployeeListProps {
    employees: Array<Employee>
    keySelector: KeySelector<Employee>
    onEmployeeSelect: (employee: Employee) => void
}

export const EmployeeList = ({ employees, keySelector, onEmployeeSelect, ...antdProps }: EmployeeListProps) => {
    const { t } = useTranslation()
    const { searchTerm, searchResults, handleInputChange, handleInputClick } = useSearch(employees, keySelector)

    const handleClick = (e: MouseEvent<HTMLElement>, employee: Employee) => {
        e.preventDefault()
        onEmployeeSelect(employee)
    }

    return (
        <Menu className="mx-10">
            <Menu.Item>
                <Input type="text" placeholder={t("action:inbox_invoice.search")} value={searchTerm} onChange={handleInputChange} onClick={handleInputClick} />
            </Menu.Item>

            {searchResults.map((employee) => (
                <Menu.Item key={employee.id} {...antdProps}>
                    <div className="flex items-center" onClick={(e) => handleClick(e, employee)}>
                        <UserImage user={employee} />
                        <span className="text-text truncate leading-none w-120">{keySelector(employee)}</span>
                    </div>
                </Menu.Item>
            ))}
        </Menu>
    )
}
