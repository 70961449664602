import { useQuery } from "@tanstack/react-query"
import axios, { AxiosResponse } from "axios"

import { isLocal } from "Shared/config/consts"
import { queryClient } from "Shared/config/reactQueryConfig"

enum CacheEnum {
    IP_ADDRESS = "IP_ADDRESS",
}

export type IpAddressResponse = string

/**
 * Seperate axios instance to avoid conflicts with the default axios instance
 */
const axiosInstance = axios.create()

export const useIpAddress = () =>
    useQuery({
        queryKey: [CacheEnum.IP_ADDRESS],
        refetchOnWindowFocus: false,
        refetchOnReconnect: true,
        refetchInterval: 5 * 60 * 1000,
        queryFn: ({ signal }) => {
            if (isLocal) return Promise.resolve({ data: "127.0.0.1" }) as unknown as AxiosResponse<IpAddressResponse>
            return axiosInstance.get<IpAddressResponse>(process.env.REACT_APP_CLIENT_IP_SERVICE_URL as string, { signal })
        },
    })

export const getIpAddress = () => {
    const queryData = queryClient.getQueryData<AxiosResponse<IpAddressResponse>>([CacheEnum.IP_ADDRESS])
    return queryData?.data ?? ""
}

export const refetchIpAddress = () => queryClient.fetchQuery<AxiosResponse<IpAddressResponse>>([CacheEnum.IP_ADDRESS])
