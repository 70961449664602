import { Employee } from "@finway-group/shared/lib/models"
import { Badge } from "antd"
import moment from "moment"
import React from "react"
import { Trans } from "react-i18next"
import { Link } from "react-router-dom"

import UserImage from "Components/UserImage"

interface BaseActivityCardInterface {
    created: Date
    isNotViewed: boolean
    employee: Employee

    description?: any
}

const BaseActivityCard = ({ created, isNotViewed, employee, description }: BaseActivityCardInterface) => (
    <>
        <Link to={`/employees/${employee?.id}`}>
            <UserImage user={employee} size="large" />
        </Link>
        <div className="flex flex-col mr-5 overflow-auto w-full">
            <div className="mt-1 mb-1 leading-none" onClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}>
                <Link to={`/employees/${employee?.id}`} className="text-text-dark text-xs font-bold">
                    {employee?.getFullName()}
                </Link>
            </div>
            <Trans>
                <div className="text-xs leading-tight whitespace-normal">{description}</div>
            </Trans>
            <div>
                <time className="text-xs">{moment(new Date(created)).fromNow()}</time>
            </div>
        </div>
        {isNotViewed ? (
            <div className="mt-14">
                <Badge dot={true} />
            </div>
        ) : (
            <></>
        )}
    </>
)

export default React.memo(BaseActivityCard)
