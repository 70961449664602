import { TenantTierEnum } from "@finway-group/shared/lib/models/featureFlags/tenantTier.enum"
import { Col, Collapse, Form, Input, Row, Select } from "antd"
import { FormInstance } from "antd/lib/form"
import { Store } from "antd/lib/form/interface"
import React, { useEffect, useState } from "react"

import Loading from "Components/loading"
import { NotificationService } from "Shared/services"
import TenantService from "Shared/services/tenant.service"

interface SimulatorPaymentSettingsTabInterface {
    formInstance: FormInstance<any>
}

const SimulatorTenantTab: React.FC<SimulatorPaymentSettingsTabInterface> = ({ formInstance }) => {
    const [isFetching, setIsFetching] = useState(true)
    const [tenant, setTenant] = useState<{ [key: string]: string }>()

    useEffect(() => {
        setIsFetching(true)
        TenantService.getTenant()
            .then((res) => {
                setTenant(res)
                formInstance.setFieldsValue({ tenantId: res.tenantId, tenantTier: res.tenantTier })
            })
            .catch((e) => {
                NotificationService.showErrorNotificationBasedOnResponseError(e, "Failed to get tenant!", "This is most likely a bug!")
            })
            .finally(() => setIsFetching(false))
    }, [formInstance])

    if (isFetching) <Loading />

    return (
        <Row>
            <Col span={24}>
                <p className="mb-10">Tenant Management</p>
                <Row className="mb-10">
                    <Col className="px-10" span={8}>
                        <Form.Item label={`Tenant ID for ${window.location.hostname}`} name="tenantId" initialValue={""}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col className="px-10" span={8}>
                        <Form.Item label="Tenant Tier" name="tenantTier">
                            <Select>
                                {Object.values(TenantTierEnum).map((e) => (
                                    <Select.Option key={e} value={e}>
                                        {e}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Col>
            <Col span={24}>
                <Collapse>
                    <Collapse.Panel header="Tenant Data" key="1">
                        <pre>{JSON.stringify(tenant, null, 2)}</pre>
                    </Collapse.Panel>
                </Collapse>
            </Col>
        </Row>
    )
}

export const createChangeTenantTierRequest = (store: Store) => ({
    tenantId: store.tenantId,
    tenantTier: store.tenantTier,
})

export default SimulatorTenantTab
