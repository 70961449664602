import Icon from "@ant-design/icons"
import React from "react"

const withdrawSvg = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M13.6667 3.66667H14C14.5523 3.66667 15 3.21895 15 2.66667V2C15 1.44771 14.5523 1 14 1H2C1.44771 1 1 1.44771 1 2V2.66667C1 3.21895 1.44771 3.66667 2 3.66667H2.33333"
            stroke="#606C7E"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M2.33398 2.33301V13.9997C2.33398 14.552 2.7817 14.9997 3.33398 14.9997H12.6673C13.2196 14.9997 13.6673 14.552 13.6673 13.9997V2.33301H2.33398Z"
            stroke="#606C7E"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M11.666 14.9997L11.666 2.33301L9.66602 2.33301L9.66602 14.9997L11.666 14.9997Z"
            stroke="#606C7E"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M7.66732 11.3337C7.66732 10.9567 7.53748 10.6129 7.32646 10.3337C7.53748 10.0544 7.66732 9.71065 7.66732 9.33366C7.66732 8.41317 6.92114 7.66699 6.00065 7.66699C5.0802 7.66699 4.33398 8.41317 4.33398 9.33366C4.33398 9.71065 4.46383 10.0544 4.67485 10.3337C4.46383 10.6129 4.33398 10.9567 4.33398 11.3337C4.33398 12.2542 5.0802 13.0003 6.00065 13.0003C6.92114 13.0003 7.66732 12.2542 7.66732 11.3337Z"
            stroke="#606C7E"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)

const WithdrawIcon = (props: any) => <Icon component={withdrawSvg} {...props} />

export default WithdrawIcon
