import { supportedInvoiceFormats } from "@finway-group/shared/lib/consts/common"
import { DocumentTypeEnum, UploadKindEnum } from "@finway-group/shared/lib/models"
import { Button, Form, Modal, Upload } from "antd"
import { FormInstance } from "antd/lib/form"
import { RcCustomRequestOptions, UploadFile } from "antd/lib/upload/interface"
import React from "react"
import { Upload as UploadIcon } from "react-feather"
import { useTranslation } from "react-i18next"
import { v4 as uuidv4 } from "uuid"

import { useExpense } from "Shared/hooks/expense.hooks"
import { FileService, NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"

interface OfferDocumentUploadInterface {
    isNew: boolean
    updateExpense: (updateValue: any) => void
    expenseForm: FormInstance
}
const OfferDocumentUploadForm: React.FC<OfferDocumentUploadInterface> = ({ isNew, updateExpense, expenseForm }) => {
    const { t } = useTranslation()

    const expenseDetails = useExpense()

    const doFileUpload = (req: RcCustomRequestOptions) => {
        const { file } = req
        if (!FileService.checkFileSizeAndHandleError(file)) return
        FileService.upload(file, UploadKindEnum.EXPENSE)
            .then((data) => {
                const formOffers = expenseForm.getFieldValue("offers")
                updateExpense({ offers: [...formOffers] })
                req.onSuccess(data, data.url as any)
            })
            .catch(() => NotificationService.send(NotificationTypeEnum.ERROR, t("error:file.upload.title"), t("error:file.upload.message")))
    }

    const formatFileEvent = (e: any) => {
        if (Array.isArray(e)) {
            return e
        }
        return e && e.fileList
    }

    const formatInitialFiles = (files?: Array<string>): Array<UploadFile> | undefined =>
        files?.map(
            (file: string, index: number): UploadFile => ({ uid: uuidv4(), name: `Offer Document ${index + 1}`, status: "done", xhr: file, type: "application/pdf", size: 4500 }),
        )

    return (
        <>
            <Form.Item key="offers" name="offers" getValueFromEvent={formatFileEvent} shouldUpdate valuePropName="">
                <Upload
                    defaultFileList={!isNew && expenseDetails ? formatInitialFiles(expenseDetails.offers) : []}
                    multiple
                    accept={supportedInvoiceFormats.join(",")}
                    customRequest={(req: RcCustomRequestOptions) => doFileUpload(req)}
                >
                    <Button className="text-left btn-default min-w-200 text-center">
                        <UploadIcon />
                        <span>{t("action:upload_offer")}</span>
                    </Button>
                </Upload>
            </Form.Item>
        </>
    )
}

export default OfferDocumentUploadForm
