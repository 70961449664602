import { Alert, Button, Modal, Table } from "antd"
import moment from "moment"
import React from "react"
import { useTranslation } from "react-i18next"

import { isSet } from "Shared/utils/helper.utils"

interface DuoImportProtocolModalInterface {
    importProtocolEntries: Array<any>
    isShowing: boolean
    onCancel: (state: boolean) => void
}

const DuoImportProtocolModal: React.FC<DuoImportProtocolModalInterface> = ({ importProtocolEntries, isShowing, onCancel }) => {
    const { t } = useTranslation()

    // filter out non-important stuff
    importProtocolEntries = importProtocolEntries.filter((entry) => isSet(entry?.text.trim()))

    const columns = [
        {
            key: "type",
            width: 200,
            title: t("label:duo_import_protocol.type"),
            render: ({ type }: any) => <span>{type}</span>,
        },
        {
            key: "filename",
            width: 200,
            title: t("label:duo_import_protocol.filename"),
            render: ({ filename }: any) => <span>{filename}</span>,
        },
        {
            key: "time",
            width: 200,
            title: t("label:duo_import_protocol.time"),
            render: ({ time }: any) => <span>{moment(time).format("lll")}</span>,
        },
        {
            key: "text",
            title: t("label:duo_import_protocol.text"),
            render: ({ text }: any) => <span>{text}</span>,
        },
    ]

    return (
        <Modal
            visible={isShowing}
            maskClosable={false}
            title={t("label:duo_import_protocol.title")}
            onCancel={() => onCancel(false)}
            className="ant-modal--full"
            footer={[
                <Button key="back" onClick={() => onCancel(false)}>
                    {t("action:close")}
                </Button>,
            ]}
            destroyOnClose
            closable
        >
            <Alert type="info" showIcon className="mb-20" message={t("info:duo_import_protocol_info")} />
            <div className="overflow-auto">
                <Table columns={columns} pagination={false} dataSource={importProtocolEntries} />
            </div>
        </Modal>
    )
}

export default DuoImportProtocolModal
