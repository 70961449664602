import React from "react"

import Icon, { IconInterface } from "Components/icon"

const lunchSvg = (
    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M30.1197 12.2635H1.87618C1.13339 12.2635 0.53125 11.7064 0.53125 11.0191V9.77465C0.53125 7.0255 6.58342 4.79688 15.9979 4.79688C25.4124 4.79688 31.4646 7.0255 31.4646 9.77465V11.0191C31.4646 11.7064 30.8624 12.2635 30.1197 12.2635Z"
            stroke="#606C7E"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M30.3979 22.9297H1.59792C1.00881 22.9297 0.53125 23.4073 0.53125 23.9964V23.9964C0.53125 25.7637 1.96394 27.1964 3.73125 27.1964H28.2646C30.0319 27.1964 31.4646 25.7637 31.4646 23.9964V23.9964C31.4646 23.4073 30.987 22.9297 30.3979 22.9297Z"
            stroke="#606C7E"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M27.7307 22.9266H4.26406C3.38041 22.9266 2.66406 22.2102 2.66406 21.3266V21.3266C2.66406 20.4429 3.38041 19.7266 4.26406 19.7266H27.7307C28.6144 19.7266 29.3307 20.4429 29.3307 21.3266V21.3266C29.3307 22.2102 28.6144 22.9266 27.7307 22.9266Z"
            stroke="#606C7E"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path d="M15.3287 7.42969L14.6562 8.74733" stroke="#606C7E" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10.6184 7.42969L9.27344 8.08851" stroke="#606C7E" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M5.90684 8.75L5.23438 10.0676" stroke="#606C7E" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M26.7584 10.0676L26.0859 8.75" stroke="#606C7E" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M20.7031 8.09375L22.0481 8.75257" stroke="#606C7E" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path
            d="M4.26406 15.4656C3.38041 15.4656 2.66406 14.7493 2.66406 13.8656V13.8656C2.66406 12.982 3.38041 12.2656 4.26406 12.2656H27.7307C28.6144 12.2656 29.3307 12.982 29.3307 13.8656V13.8656C29.3307 14.7493 28.6144 15.4656 27.7307 15.4656"
            stroke="#606C7E"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M5.86458 15.4609C13.3313 15.4609 15.4646 19.7276 15.4646 19.7276C15.4646 19.7276 16.5313 15.4609 19.7313 15.4609H29.3313C30.5094 15.4609 31.4646 16.416 31.4646 17.5943C31.4646 18.7725 30.5094 19.7276 29.3313 19.7276H2.66458C1.48639 19.7276 0.53125 18.7725 0.53125 17.5943C0.53125 16.416 1.48639 15.4609 2.66458 15.4609H5.86458Z"
            stroke="#606C7E"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)

const LunchIcon = (props: IconInterface) => <Icon component={lunchSvg} {...props} />

export default LunchIcon
