import { EnrichedPaymentSettings } from "@finway-group/shared/lib/models"
import { Action } from "redux"

import { PaymentProductUI } from "Shared/services/paymentSettings.service"

export enum PaymentActionTypes {
    FETCH_PAYMENT_SETTINGS = "FETCH_PAYMENT_SETTINGS",
    FETCH_PAYMENT_PRODUCTS = "FETCH_PAYMENT_PRODUCTS",
}

export interface FetchPaymentSettingsAction extends Action<typeof PaymentActionTypes.FETCH_PAYMENT_SETTINGS> {
    type: typeof PaymentActionTypes.FETCH_PAYMENT_SETTINGS
    settings: {
        data?: EnrichedPaymentSettings
        isFetching: boolean
    }
}

export interface FetchPaymentProductsAction extends Action<typeof PaymentActionTypes.FETCH_PAYMENT_PRODUCTS> {
    type: typeof PaymentActionTypes.FETCH_PAYMENT_PRODUCTS
    products: {
        plans: Array<PaymentProductUI>
        cards: Array<PaymentProductUI>
        isFetching: boolean
    }
}

export type PaymentActions = FetchPaymentSettingsAction | FetchPaymentProductsAction
