import { KYBStatusEnum, KYBStepsEnum } from "@finway-group/shared/lib/models"
import { Dispatch } from "redux"

import { CorporateService } from "Shared/services"
import WalletService from "Shared/services/wallet.service"
import { KYBData, Wallet } from "Shared/store/reducers/corporateReducer"

import { CorporateActionTypes, FetchKYBProgressAction, ResetWalletAction, SetCurrentKYBStepAction, SetKYBDataAction, SetWalletAction } from "./corporateTypes"

const getCurrentStep = ({ corporateId, isEmailVerified, isPasswordCreated, isMobileVerified, kybStatus }: KYBData): KYBStepsEnum => {
    if (kybStatus === KYBStatusEnum.APPROVED) return KYBStepsEnum.CREATE_ACCOUNT
    if (isMobileVerified) return KYBStepsEnum.KYB_FORM
    if (isEmailVerified) return KYBStepsEnum.VERIFY_MOBILE
    if (isPasswordCreated) return KYBStepsEnum.VERIFY_EMAIL
    if (corporateId) return KYBStepsEnum.CREATE_PASSWORD
    return KYBStepsEnum.COMPANY_DETAILS
}

export const fetchKYBProgress = () => async (dispatch: Dispatch) => {
    try {
        const kybProgress = await CorporateService.getKYBProgress()
        const currentKYBStep = getCurrentStep(kybProgress)

        const action: FetchKYBProgressAction = {
            type: CorporateActionTypes.FETCH_KYB_PROGRESS,
            kybData: kybProgress,
            currentKYBStep,
        }
        dispatch(action)
    } catch (err) {
        setCurrentKYBStep(KYBStepsEnum.COMPANY_DETAILS)
    }
}

export const setKYBData = (kybData: KYBData) => async (dispatch: Dispatch) => {
    const action: SetKYBDataAction = {
        type: CorporateActionTypes.SET_KYB_DATA,
        kybData,
    }

    dispatch(action)
}

export const setCurrentKYBStep = (currentKYBStep: KYBStepsEnum) => async (dispatch: Dispatch) => {
    const action: SetCurrentKYBStepAction = {
        type: CorporateActionTypes.SET_CURRENT_KYB_STEP,
        currentKYBStep,
    }

    dispatch(action)
}

export const setWallet = (wallet: Wallet) => async (dispatch: Dispatch) => {
    const action: SetWalletAction = {
        type: CorporateActionTypes.SET_WALLET,
        wallet,
    }

    dispatch(action)
}

export const fetchWallet = () => async (dispatch: Dispatch) => {
    const wallet = await WalletService.fetchWalletDetails()

    const action: SetWalletAction = {
        type: CorporateActionTypes.SET_WALLET,
        wallet,
    }

    dispatch(action)

    return wallet
}

export const resetWallet = () => async (dispatch: Dispatch) => {
    const action: ResetWalletAction = {
        type: CorporateActionTypes.RESET_WALLET,
    }

    dispatch(action)
}
