import { Expense, PriceIntervalEnum } from "@finway-group/shared/lib/models"
import { Tooltip } from "antd"
import moment from "moment"
import React from "react"
import { useTranslation } from "react-i18next"

import { getPriceIntervalForBillingPeriod } from "Shared/utils/expense.utils"
import { formatCurrencyNumber, getTooltipPopupContainer } from "Shared/utils/helper.utils"

import { ConditionalWrapper } from "./conditionalWrapper"
import Icon from "./icon"
import DiscountIcon from "./icons/discountIcon"
import PriceLabel from "./priceLabel"

interface ExpenseAmountColumnInterface {
    request: Expense
    showGross: boolean
    showDiscountWhenAvailable?: boolean
}

const ExpenseAmountColumn: React.FC<ExpenseAmountColumnInterface> = ({ request, showGross, showDiscountWhenAvailable }) => {
    const { t } = useTranslation()
    const discount = request.getActiveDiscount()
    const shouldShowDiscount = discount && showDiscountWhenAvailable

    const DiscountTooltip = (children: JSX.Element) => {
        if (!discount) return children
        return (
            <Tooltip title={t("label:expense_discount.discounted_total_gross", { amount: formatCurrencyNumber(discount.reducedAmount.gross, request.currency) })}>
                {children}
            </Tooltip>
        )
    }

    return (
        <div className="flex flex-col items-end">
            <ConditionalWrapper condition={!!shouldShowDiscount} wrapper={DiscountTooltip}>
                <p className="flex items-center justify-end text-text-dark leading-none mb-2 gap-4">
                    {request.paymentFlowInformation && (
                        <Tooltip
                            getPopupContainer={getTooltipPopupContainer}
                            title={t(`tooltips:expense_payment_flow_information.${request.paymentFlowInformation}`)}
                            className="ml-5"
                        >
                            <Icon icon={request.paymentFlowInformation} className="mr-8" />
                        </Tooltip>
                    )}
                    {shouldShowDiscount && <DiscountIcon className="finway-green-2-text" />}
                    <b>
                        <PriceLabel
                            value={showGross ? request.totalGrossPrice : request.totalNetPrice}
                            currency={request.currency}
                            interval={request.isSubscription() ? getPriceIntervalForBillingPeriod(request.billingPeriod) : PriceIntervalEnum.ONE_TIME}
                        />
                    </b>
                </p>
                <p className="text-text text-sm">{moment(request.datePurchased).format("ll")}</p>
            </ConditionalWrapper>
        </div>
    )
}

export default ExpenseAmountColumn
