import { Expense, SplitTypeEnum } from "@finway-group/shared/lib/models"
import { Button, Col, Dropdown, Menu } from "antd"
import React, { Key, MouseEvent, ReactInstance } from "react"
import { MoreHorizontal as DotsIcon, Link as LinkIcon, Plus as PlusIcon, Trash as TrashIcon } from "react-feather"
import { useTranslation } from "react-i18next"

import { getPopupAnchor } from "Shared/utils/popup.utils"

export type MenuInfo = { key: Key; keyPath?: Array<Key>; item?: ReactInstance; domEvent: MouseEvent<HTMLElement> }

export interface FormAddExpenseItemRowInterface {
    elem: {
        isHospitality: boolean
        isSubscription: boolean
        expense: Expense
        onAddItem: (value: boolean) => void
        isLinked: boolean
        onSelect: (info: MenuInfo) => void
    }
    span?: number
    md?: number | object
    sm?: number | object
}

function FormAddExpenseItemRow({ elem, ...columnProps }: FormAddExpenseItemRowInterface) {
    const { isHospitality, expense, isSubscription, onAddItem, isLinked, onSelect } = elem
    const { t } = useTranslation()

    const renderAddItemButton = () => {
        const handleClick = (e: React.MouseEvent<HTMLElement>) => {
            e.stopPropagation()
            if (isHospitality) onAddItem(isHospitality)
        }

        return <Button onClick={handleClick}>{!isHospitality ? <DotsIcon /> : <PlusIcon />}</Button>
    }

    const renderDropDownMenu = () => (
        <Menu onClick={onSelect}>
            {!isSubscription && (
                <>
                    {expense.splitType === SplitTypeEnum.SPLIT || expense.splits.length === 0 || (isHospitality && expense.splits.length === 1) ? (
                        <Menu.Item key="add_item" className="flex items-center min-w-190 py-8">
                            <PlusIcon size={12} />
                            <span className="ml-6">{t("action:request.item.add")}</span>
                        </Menu.Item>
                    ) : (
                        !isHospitality && (
                            <Menu.Item key="delete_all_items" className="flex items-center min-w-190 py-8">
                                <TrashIcon size={12} />
                                <span className="ml-6">{t("action:request.delete_all_items")}</span>
                            </Menu.Item>
                        )
                    )}
                </>
            )}
            <Menu.Item key="link" className="flex items-center min-w-190 py-8">
                <LinkIcon size={12} />
                <span className="ml-6">{isLinked ? t(`action:request.unlink_fields`) : t(`action:request.link_fields`)}</span>
            </Menu.Item>
        </Menu>
    )

    if (isHospitality && (!isHospitality || expense.splits.length >= 2)) {
        return null
    }

    return (
        <Col {...columnProps}>
            {!isHospitality ? (
                <Dropdown getPopupContainer={getPopupAnchor()} className="flex items-center z-100" overlay={renderDropDownMenu()} trigger={["click"]}>
                    {renderAddItemButton()}
                </Dropdown>
            ) : (
                renderAddItemButton()
            )}
        </Col>
    )
}

export default React.memo(FormAddExpenseItemRow)
