import { ExpenseAccount } from "@finway-group/shared/lib/models"
import { Action } from "redux"

// Model imports

export enum ExpenseAccountActionTypes {
    FETCH_ALL_EXPENSE_ACCOUNTS = "FETCH_ALL_EXPENSE_ACCOUNTS",
    UPDATE_ALL_EXPENSE_ACCOUNTS = "UPDATE_ALL_EXPENSE_ACCOUNTS",
    DELETE_MANY_EXPENSE_ACCOUNTS = "DELETE_MANY_EXPENSE_ACCOUNTS",
    UPDATE_MANY_EXPENSE_ACCOUNTS = "UPDATE_MANY_EXPENSE_ACCOUNTS",
    CREATE_MANY_EXPENSE_ACCOUNTS = "CREATE_MANY_EXPENSE_ACCOUNTS",
}

export interface FetchAllExpenseAccountsAction extends Action<typeof ExpenseAccountActionTypes.FETCH_ALL_EXPENSE_ACCOUNTS> {
    type: typeof ExpenseAccountActionTypes.FETCH_ALL_EXPENSE_ACCOUNTS
    expenseAccounts: Array<ExpenseAccount>
}
export interface UpdateAllExpenseAccountAction extends Action<typeof ExpenseAccountActionTypes.UPDATE_ALL_EXPENSE_ACCOUNTS> {
    type: typeof ExpenseAccountActionTypes.UPDATE_ALL_EXPENSE_ACCOUNTS
    expenseAccounts: Array<ExpenseAccount>
}

export interface DeleteManyExpenseAccountsAction extends Action<typeof ExpenseAccountActionTypes.DELETE_MANY_EXPENSE_ACCOUNTS> {
    type: typeof ExpenseAccountActionTypes.DELETE_MANY_EXPENSE_ACCOUNTS
    expenseAccounts: Array<ExpenseAccount>
}

export interface UpdateManyExpenseAccountsAction extends Action<typeof ExpenseAccountActionTypes.UPDATE_MANY_EXPENSE_ACCOUNTS> {
    type: typeof ExpenseAccountActionTypes.UPDATE_MANY_EXPENSE_ACCOUNTS
    expenseAccounts: Array<ExpenseAccount>
}

export interface CreateManyExpenseAccountsAction extends Action<typeof ExpenseAccountActionTypes.CREATE_MANY_EXPENSE_ACCOUNTS> {
    type: typeof ExpenseAccountActionTypes.CREATE_MANY_EXPENSE_ACCOUNTS
    expenseAccounts: Array<ExpenseAccount>
}

export type ExpenseAccountActions =
    | FetchAllExpenseAccountsAction
    | UpdateAllExpenseAccountAction
    | DeleteManyExpenseAccountsAction
    | UpdateManyExpenseAccountsAction
    | CreateManyExpenseAccountsAction
