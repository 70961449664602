import { ApproverTypeEnum, Workflow, WorkflowApproval } from "@finway-group/shared/lib/models"
import axios from "axios"

import i18n from "Shared/locales/i18n"

import EmployeeService from "./employee.service"

const model = "workflows"

const WorkflowService = {
    update: async (
        id: string,
        workflowData: Partial<Workflow>,
        otherData: { recalculateApprovalProcessesNotStarted?: boolean; recalculateApprovalProcessesInProgress?: boolean } = {},
    ) => {
        const { data } = await axios.put(`/${model}/${id}`, { ...workflowData, ...otherData })
        return new Workflow(data)
    },
    create: async (workflowData: Partial<Workflow>) => {
        const { data } = await axios.post(`/${model}`, workflowData)
        return new Workflow(data)
    },
    deleteWorkflow: async (id: string, data: { recalculateApprovalProcessesInProgress?: boolean } = {}) => axios.delete(`/${model}/${id}`, { data }),
    fetchOneWorkflow: async (id: string) => {
        const { data } = await axios.get(`/${model}/${id}`)
        return new Workflow(data)
    },
    fetchAllWorkflows: async () => {
        // prettier-ignore
        const { data: { docs } } = await axios.get(`/${model}?limit=1000`);
        return docs.map((doc: any) => new Workflow(doc))
    },
    getApproverLabelData: (approval: WorkflowApproval) => {
        if (!approval) return undefined

        const autoApprover = EmployeeService.getAutoApprover()

        if (approval.approverType === ApproverTypeEnum.SUPERIOR) return { label: i18n.t("input:workflow.superior"), user: autoApprover }
        if (approval.approverType === ApproverTypeEnum.RESPONSIBLE_USER) return { label: i18n.t("input:workflow.responsible_employee"), user: autoApprover }
        if (approval.approverType === ApproverTypeEnum.AUTO_APPROVER) return { label: i18n.t("input:workflow.auto_approver"), user: autoApprover }
        if (approval.approverType === ApproverTypeEnum.REQUESTER) return { label: i18n.t("input:workflow.requester"), user: autoApprover }
        if (approval.approverType === ApproverTypeEnum.USER) {
            const approverObject = EmployeeService.getEmployeeById(approval.workflowApprover)
            return { label: `${approverObject?.firstName} ${approverObject?.lastName}`, user: approverObject }
        }
        return undefined
    },
}

export default WorkflowService
