import { Expense } from "@finway-group/shared/lib/models"
import React from "react"

import MainDetailsSectionComponent from "./mainDetailsSection.component"
import MainDetailsSectionContext from "./mainDetailsSection.context"

interface MainDetailsSectionInterface {
    expense: Expense
    isMinimized?: boolean
    openEditModal?: () => void
    setIsExpenseUpdating?: React.Dispatch<React.SetStateAction<boolean>>
    showProbability?: boolean
    showLink?: boolean
    setIsShowingDiscountForm?: React.Dispatch<React.SetStateAction<boolean>>
}
const MainDetailsSectionWrapper: React.FC<MainDetailsSectionInterface> = ({
    expense,
    isMinimized = false,
    openEditModal,
    setIsExpenseUpdating,
    setIsShowingDiscountForm,
    showProbability = false,
    showLink = false,
}) => (
    <MainDetailsSectionContext
        expense={expense}
        isMinimized={isMinimized}
        setIsExpenseUpdating={setIsExpenseUpdating}
        openEditModal={openEditModal}
        showProbability={showProbability}
        showLink={showLink}
        setIsShowingDiscountForm={setIsShowingDiscountForm}
    >
        <MainDetailsSectionComponent />
    </MainDetailsSectionContext>
)

export default MainDetailsSectionWrapper
