import { CurrencyEnum, LiquidityCashFlow, LiquidityScenario, PriceIntervalEnum } from "@finway-group/shared/lib/models"
import { roundNumberTo2Decimals } from "@finway-group/shared/lib/utils"
import { Card, Col, Divider, Row } from "antd"
import moment from "moment"
import React from "react"
import { useTranslation } from "react-i18next"

import ProgressBar from "Components/ProgressBar"
import Loading from "Components/loading"
import PriceLabel from "Components/priceLabel"
import { CashFlowDirectionEnum } from "Features/pages/liquidity/liquidity.types"
import { useLoggedInEmployeeProfile } from "Shared/hooks/user.hooks"
import { findCurrentMonthCashFlow, getInflowValue } from "Shared/utils/liquidity.utils"

import SkeletonCard from "./skeletonCard"

interface CashFlowChartInterface {
    cashFlowData: Array<LiquidityCashFlow>
    cashFlowDirection: CashFlowDirectionEnum
    title: string
    isTableLoading: boolean
    showSkeleton: boolean
    height: number
}

const CashFlowProgressChart: React.FC<CashFlowChartInterface> = ({ cashFlowData, cashFlowDirection, title, isTableLoading, showSkeleton, height }) => {
    const { t } = useTranslation()
    const currentEmployee = useLoggedInEmployeeProfile()

    if (showSkeleton) return <SkeletonCard title={title} height={height} />

    if (!cashFlowData || cashFlowData.length === 0 || isTableLoading)
        return (
            <Card>
                <Loading margin={false} />
            </Card>
        )

    const monthlyValue = getInflowValue(cashFlowData, moment().startOf("month"), moment().endOf("month"), cashFlowDirection)
    const yearlyValue = getInflowValue(cashFlowData, moment().startOf("year"), moment().endOf("year"), cashFlowDirection)

    const currentMonthCashFlow = findCurrentMonthCashFlow(cashFlowData)!

    const amountDiff = currentMonthCashFlow[cashFlowDirection].actual - currentMonthCashFlow[cashFlowDirection].forecast
    const amountDiffPercentage =
        currentMonthCashFlow[cashFlowDirection].forecast !== 0 ? roundNumberTo2Decimals((amountDiff / Math.abs(currentMonthCashFlow[cashFlowDirection].forecast)) * 100) : 0

    const { globalCurrency } = currentEmployee.settings

    return (
        <Card className={`h-${height} finway-dark-text`}>
            <Row>
                <Col span={24}>
                    <div className="flex justify-between">
                        <p className="text-base text-xl font-semibold">{title}</p>
                    </div>
                </Col>
            </Row>
            <Row className="mt-8">
                <Col span={24}>
                    <div className="flex justify-between">
                        <p className="text-base">{t("label:liquidity.this_month")}</p>
                    </div>
                </Col>
            </Row>
            <Row className="mt-18">
                <Col span={24}>
                    <div className="flex justify-between items-center w-full xl:text-lg lg:text-xs xs:text-lg cashflow-summary">
                        <p>
                            <PriceLabel currency={globalCurrency} value={0} interval={PriceIntervalEnum.ONE_TIME} />
                        </p>
                        <p className="xl:text-xl lg:text-sm xs:text-xl cashflow-summary">
                            <PriceLabel currency={globalCurrency} value={monthlyValue.actual} interval={PriceIntervalEnum.ONE_TIME} />
                        </p>
                        <p>
                            <PriceLabel currency={globalCurrency} value={monthlyValue.forecast} interval={PriceIntervalEnum.ONE_TIME} />
                        </p>
                    </div>
                </Col>
            </Row>
            <Row className="block w-full">
                <ProgressBar value={Math.abs(monthlyValue.actual)} total={Math.abs(monthlyValue.forecast)} currency={CurrencyEnum.EUR} hideLabels extended />
            </Row>
            <Row className="mt-28">
                <Col span={24}>
                    <Divider className="my-0" />
                    <div className="flex justify-between finway-gray-text">
                        <p>{t("label:liquidity.month_over_month")}</p>
                        <p>
                            <PriceLabel currency={globalCurrency} value={amountDiff} interval={PriceIntervalEnum.ONE_TIME} />
                        </p>
                        <p>
                            {amountDiffPercentage >= 0 ? "+" : ""}
                            {amountDiffPercentage}%
                        </p>
                    </div>
                    <Divider className="my-0" />
                </Col>
            </Row>
            <Row className="mt-8">
                <Col span={24}>
                    <div className="flex justify-between">
                        <p className="text-base">{t("label:liquidity.this_year")}</p>
                    </div>
                </Col>
            </Row>
            <Row className="mt-18">
                <Col span={24}>
                    <div className="flex justify-between items-center w-full xl:text-lg lg:text-xs xs:text-lg cashflow-summary">
                        <p>
                            <PriceLabel currency={globalCurrency} value={0} interval={PriceIntervalEnum.ONE_TIME} />
                        </p>
                        <p className="xl:text-xl lg:text-sm xs:text-xl cashflow-summary">
                            <PriceLabel currency={globalCurrency} value={yearlyValue.actual} interval={PriceIntervalEnum.ONE_TIME} />
                        </p>
                        <p>
                            <PriceLabel currency={globalCurrency} value={yearlyValue.forecast} interval={PriceIntervalEnum.ONE_TIME} />
                        </p>
                    </div>
                </Col>
            </Row>
            <Row className="block w-full">
                <ProgressBar value={Math.abs(yearlyValue.actual)} total={Math.abs(yearlyValue.forecast)} currency={globalCurrency} hideLabels extended />
            </Row>
        </Card>
    )
}

export default CashFlowProgressChart
