import React, { Suspense, useContext, useState } from "react"
import { useHistory } from "react-router-dom"

interface CancelableComponent {
    onCancel?: (...params: Array<any>) => void
}

// TODO switch to strongly typed method that enforces the props of the component
//
// type UpdateModalContextFunction = <PropsType>(
//     component: (props: PropsType & CancelableComponent) => JSX.Element | null,
//     destroy: boolean,
//     props: PropsType & CancelableComponent
// ) => void
export type UpdateModalContextFunction = (component: (props: any) => JSX.Element | null, destroy: boolean, props: any) => void

interface ModalContextInterface {
    component: ((props: object & CancelableComponent) => JSX.Element | null) | null
    props: object & CancelableComponent
    destroy?: boolean
    showModal: UpdateModalContextFunction
    hideModal: UpdateModalContextFunction
}

const ModalContext = React.createContext<ModalContextInterface>({ component: null, showModal: () => {}, hideModal: () => {}, props: {} })
export const useModal = () => useContext(ModalContext)

interface ModalContextProviderInterface {
    children: React.ReactNode
}

export const ModalContextProvider = React.memo(({ children }: ModalContextProviderInterface) => {
    const history = useHistory()

    const [state, setState] = useState<ModalContextInterface>({
        component: null,
        props: {},
        destroy: false,
        showModal: (component, destroy, props = {} as any) => {
            setState({ ...state, destroy, component, props })

            history.listen((location, action) => {
                if (location.pathname === "/login" && action === "REPLACE") setState({ ...state, props: { ...props, isShowing: false } })
            })
        },
        hideModal: (component, destroy, props = {} as any) => {
            if (destroy) {
                setState({ ...state })
            } else {
                setState({ ...state, component, props: { ...props, isShowing: false } })
            }
        },
    })

    return <ModalContext.Provider value={state}>{children}</ModalContext.Provider>
})

export const ModalContextConsumer = () => (
    <ModalContext.Consumer>
        {({ component: Component, destroy = false, props, hideModal }) =>
            Component && (
                <Suspense fallback={<></>}>
                    <Component
                        {...props}
                        onCancel={(...params: Array<any>) => {
                            // Make sure that the onCancel original prop of the modal is handled first.
                            props.onCancel?.(...params)
                            hideModal(Component, destroy, props)
                        }}
                    />
                </Suspense>
            )
        }
    </ModalContext.Consumer>
)
