import { Form, TreeSelect } from "antd"
import { FormInstance } from "antd/lib/form"
import { TreeNode } from "antd/lib/tree-select"
import React from "react"
import { useTranslation } from "react-i18next"

import { useIsTravelEnabled } from "Shared/hooks/featureFlags.hooks"
import { getPopupAnchor } from "Shared/utils/popup.utils"
import { workflowFormBasicRequestTypeOptions, workflowFormReimbursementRequestTypeOptions } from "Shared/utils/workflow.form.utils"

import { WorkflowFormRequestTypeEnum } from "./workflowFormValues.interface"

interface RequestTypeTreeSelectInterface {
    value: Array<any>
    formInstance: FormInstance
    onSelect: (requestTypes: any) => void
    rule?: any
}

export const RequestTypeTreeSelect: React.FC<RequestTypeTreeSelectInterface> = ({ value, formInstance, onSelect, rule }) => {
    const { t } = useTranslation()
    const isTravelEnabled = useIsTravelEnabled() ?? false

    // Handles the logic of the tree select. (For example when selecting all the reimbursement types separately, it will change the current selection to "all reimbursements". When selecting "all reimbursements", "purchase" and "invoice" it will change the selection to "all".)
    const handleChange = (requestTypes: Array<WorkflowFormRequestTypeEnum>) => {
        const value = requestTypes?.[requestTypes.length - 1]

        if (!isTravelEnabled) {
            if (value === WorkflowFormRequestTypeEnum.ALL || areAllBasicOptionsSelected(requestTypes)) requestTypes = [WorkflowFormRequestTypeEnum.ALL]
            else requestTypes = requestTypes?.filter((value: any) => value !== WorkflowFormRequestTypeEnum.ALL)
        } else if (
            value === WorkflowFormRequestTypeEnum.ALL ||
            (requestTypes && Object.values(WorkflowFormRequestTypeEnum).every((requestType) => requestTypes!.includes(requestType))) ||
            (requestTypes &&
                [WorkflowFormRequestTypeEnum.ALL_REIMBURSEMENTS, WorkflowFormRequestTypeEnum.PURCHASE, WorkflowFormRequestTypeEnum.INVOICE].every((requestType) =>
                    requestTypes!.includes(requestType),
                ))
        ) {
            requestTypes = [WorkflowFormRequestTypeEnum.ALL]
        } else if (value === WorkflowFormRequestTypeEnum.ALL_REIMBURSEMENTS) {
            requestTypes = requestTypes?.filter((value: any) => ![...workflowFormReimbursementRequestTypeOptions, WorkflowFormRequestTypeEnum.ALL].includes(value))
        } else if (requestTypes && workflowFormReimbursementRequestTypeOptions.every((requestType) => requestTypes!.includes(requestType))) {
            requestTypes = requestTypes?.filter((value: any) => !workflowFormReimbursementRequestTypeOptions.includes(value))
            requestTypes.push(WorkflowFormRequestTypeEnum.ALL_REIMBURSEMENTS)
        } else if ([WorkflowFormRequestTypeEnum.PURCHASE, WorkflowFormRequestTypeEnum.INVOICE].includes(value)) {
            requestTypes = requestTypes?.filter((value: any) => value !== WorkflowFormRequestTypeEnum.ALL)
        } else {
            requestTypes = requestTypes?.filter(
                (requestType: any) => requestType !== WorkflowFormRequestTypeEnum.ALL && requestType !== WorkflowFormRequestTypeEnum.ALL_REIMBURSEMENTS,
            )
        }

        formInstance.setFieldsValue({ requestTypes })
        onSelect(requestTypes)
    }

    const areAllBasicOptionsSelected = (requestTypes: Array<WorkflowFormRequestTypeEnum>): boolean =>
        requestTypes && workflowFormBasicRequestTypeOptions.every((requestType) => requestTypes.includes(requestType))

    return (
        <Form.Item label={t("label:workflow.request_type.title")} required name="requestTypes" rules={rule}>
            <TreeSelect
                showSearch
                style={{ width: "100%" }}
                dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                placeholder={t("placeholder:workflow.request_type")}
                allowClear
                multiple
                treeDefaultExpandAll
                getPopupContainer={getPopupAnchor()}
                value={value}
                onChange={handleChange}
            >
                <TreeNode value={WorkflowFormRequestTypeEnum.ALL} title={t("input:workflow.trigger.all")}>
                    <TreeNode value={WorkflowFormRequestTypeEnum.PURCHASE} title={t("input:workflow.request_type.purchase")} />
                    <TreeNode value={WorkflowFormRequestTypeEnum.INVOICE} title={t("input:workflow.request_type.invoice")} />
                    {isTravelEnabled ? (
                        <TreeNode value={WorkflowFormRequestTypeEnum.ALL_REIMBURSEMENTS} title={t("input:workflow.request_type.all_reimbursements")}>
                            <TreeNode value={WorkflowFormRequestTypeEnum.STANDARD_REIMBURSEMENT} title={t("input:workflow.request_type.standard_reimbursement")} />
                            <TreeNode value={WorkflowFormRequestTypeEnum.MILEAGE_REIMBURSEMENT} title={t("input:workflow.request_type.mileage_reimbursement")} />
                            <TreeNode value={WorkflowFormRequestTypeEnum.HOSPITALITY_REIMBURSEMENT} title={t("input:workflow.request_type.hospitality_reimbursement")} />
                            <TreeNode value={WorkflowFormRequestTypeEnum.PER_DIEM_REIMBURSEMENT} title={t("input:workflow.request_type.per_diem_reimbursement")} />
                        </TreeNode>
                    ) : (
                        <TreeNode value={WorkflowFormRequestTypeEnum.STANDARD_REIMBURSEMENT} title={t("input:workflow.request_type.reimbursement")} />
                    )}
                </TreeNode>
            </TreeSelect>
        </Form.Item>
    )
}
