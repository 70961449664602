import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons"
import { Button, Col, Input, Row } from "antd"
import Form, { FormInstance } from "antd/lib/form/Form"
import FormItem from "antd/lib/form/FormItem"
import { Store } from "rc-field-form/lib/interface"
import React from "react"
import { useTranslation } from "react-i18next"

import { CorporateService, NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

interface KYBMobileVerificationInterface {
    formInstance: FormInstance
    onSubmit: () => void
    setIsLoading: (flag: boolean) => void
}

const KYBMobileVerification: React.FC<KYBMobileVerificationInterface> = ({ formInstance, onSubmit, setIsLoading }) => {
    const { t } = useTranslation()
    const [isResending, setIsResending] = useStateIfMounted(false)

    const onFinish = (values: Store) => {
        setIsLoading(true)
        CorporateService.verifyMobileRootUser(values.verificationCode)
            .then(() => {
                NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:corporate.verify_mobile.title"), t("notification:corporate.verify_mobile.message"))
                formInstance.resetFields()
                onSubmit()
            })
            .catch((err) =>
                NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:corporate.verify_mobile.title"), t("error:corporate.verify_mobile.message")),
            )
            .finally(() => setIsLoading(false))
    }

    const onRequestVerification = () => {
        setIsResending(true)
        CorporateService.requestMobileVerificationCodeRootUser()
            .then(() =>
                NotificationService.send(
                    NotificationTypeEnum.SUCCESS,
                    t("notification:corporate.mobile_verification_code.title"),
                    t("notification:corporate.mobile_verification_code.message"),
                ),
            )
            .catch((err) => NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:corporate.mobile_verification_code.title")))
            .finally(() => setIsResending(false))
    }

    return (
        <div>
            <div className="flex items-center">
                <h2 className="ml-10">{t("corporate:verification.mobile.title")}</h2>
            </div>
            <Row gutter={[16, 0]}>
                <Col span={20}>
                    <div className="ml-10">{t("info:corporate.mobile_verification")}</div>
                </Col>
            </Row>
            <div className="flex items-center mt-20 ml-10">
                <Form form={formInstance} onFinish={onFinish} layout="vertical" className="mx-auto w-full">
                    <Row gutter={[16, 0]}>
                        <Col span={12}>
                            <FormItem
                                name="verificationCode"
                                label={t("corporate:verification.code")}
                                required
                                rules={[{ required: true, message: t("validation:required"), validateTrigger: "blur" }]}
                            >
                                <Input.Password placeholder="Verification code" iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
                            </FormItem>
                        </Col>
                    </Row>
                    <Button loading={isResending} onClick={onRequestVerification}>
                        {t("corporate:verification.resend_code")}
                    </Button>
                </Form>
            </div>
        </div>
    )
}

export default KYBMobileVerification
