import { UserRule } from "@finway-group/shared/lib/models"
import { Button, Form, Modal } from "antd"
import { Store } from "antd/lib/form/interface"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import EmployeeRuleForm from "Components/forms/employeeRule.form"
import { useCompany } from "Shared/hooks/company.hooks"
import { useEmployeeById } from "Shared/hooks/employee.hooks"
import { EmployeeService, NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { updateEmployeeRule } from "Shared/store/actions/employee/employeeActions"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

interface EmployeeRuleModalProps {
    isShowing: boolean
    employeeIDToUpdate: string
    onCancel: (state: boolean) => void
    onSubmit?: (rule: Pick<UserRule, "costCenter" | "costCenter2" | "expenseAccount">) => void
}

const EmployeeRuleModal = ({ isShowing, onCancel, employeeIDToUpdate, onSubmit }: EmployeeRuleModalProps) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const employee = useEmployeeById(employeeIDToUpdate)
    const company = useCompany()
    const [formInstance] = Form.useForm()
    const [isLoading, setIsLoading] = useStateIfMounted<boolean>(false)

    useEffect(() => {
        if (isShowing) {
            const initialEmployeeFormRule = EmployeeService.getInitialEmployeeFormRule(employee, company._id)
            formInstance.setFieldsValue(initialEmployeeFormRule)
        }

        return () => {
            if (isShowing) formInstance.resetFields()
        }
    }, [isShowing, employeeIDToUpdate])

    const handleHide = () => {
        setIsLoading(false)
        formInstance.resetFields()
        onCancel(false)
    }

    const handleSubmit = async (values: Store) => {
        setIsLoading(true)
        try {
            await updateEmployeeRule(employeeIDToUpdate, { rule: values })(dispatch)
            onSubmit?.(values)
            NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:employee.updated.title"), t("notification:employee.updated.message"))
            handleHide()
        } catch (err) {
            setIsLoading(false)
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:employee.edit.title"))
        }
    }

    return (
        <Modal
            destroyOnClose={true}
            afterClose={() => formInstance.resetFields()}
            visible={isShowing}
            maskClosable={false}
            title={t("label:set_employee_rule")}
            onCancel={handleHide}
            closable={true}
            keyboard={true}
            className="ant-modal--small"
            footer={[
                <Button key="back" onClick={handleHide}>
                    {t("action:cancel")}
                </Button>,
                <Button
                    key="submit"
                    type="primary"
                    loading={isLoading}
                    onClick={() => {
                        formInstance.submit()
                    }}
                >
                    {t("action:vendor.set_rule")}
                </Button>,
            ]}
        >
            <EmployeeRuleForm formInstance={formInstance} onSubmit={handleSubmit} />
        </Modal>
    )
}
export default EmployeeRuleModal
