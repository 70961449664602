import { Company } from "@finway-group/shared/lib/models"
import { Button, Tabs } from "antd"
import React from "react"
import { Edit as EditIcon } from "react-feather"
import { useTranslation } from "react-i18next"

import FeatureSettingsModal from "Components/modals/featureSettings.modal"
import SettingsLayout, { SettingsButton } from "Features/settings/settings.layout"
import { useModal } from "Shared/context/modal.context"
import { useCompany } from "Shared/hooks/company.hooks"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

import BankingAndCardsTabContent from "./bankingAndCardsTabContent"
import DashboardAndWorkflowTabContent from "./dashboardAndWorkflowTabContent"
import ExportAndBookkeepingTabContent from "./exportAndBookkeepingTabContent"
import GeneralTabContent from "./generalTabContent"

export enum FeatureSettingsTabEnum {
    GENERAL = "GENERAL",
    DASHBOARD_AND_WORKFLOW = "DASHBOARD_AND_WORKFLOW",
    BANKING_AND_CARDS = "BANKING_AND_CARDS",
    EXPORT_AND_BOOKKEEPING = "EXPORT_AND_BOOKKEEPING",
}

const FeatureSettings = () => {
    const { t } = useTranslation()
    const { showModal } = useModal()

    const [activeTab, setActiveTab] = useStateIfMounted<FeatureSettingsTabEnum>(FeatureSettingsTabEnum.GENERAL)
    const company = useCompany()

    const handleOnCompanyEdit = (selectedCompany: Company) => showModal(FeatureSettingsModal, false, { isShowing: true, company: selectedCompany, activeTab })

    return (
        <SettingsLayout titleKey="label:feature_settings" buttons={<SettingsButton icon={<EditIcon />} onClick={() => handleOnCompanyEdit(company)} labelKey={"action:edit"} />}>
            <Tabs defaultActiveKey={FeatureSettingsTabEnum.GENERAL} activeKey={activeTab} className="pb-10" onTabClick={(key: FeatureSettingsTabEnum) => setActiveTab(key)}>
                <Tabs.TabPane tab={t("label:general")} key={FeatureSettingsTabEnum.GENERAL}>
                    <GeneralTabContent />
                </Tabs.TabPane>
                <Tabs.TabPane tab={t("label:dashboard_and_workflows")} key={FeatureSettingsTabEnum.DASHBOARD_AND_WORKFLOW}>
                    <DashboardAndWorkflowTabContent />
                </Tabs.TabPane>
                <Tabs.TabPane tab={t("label:banking_and_cards")} key={FeatureSettingsTabEnum.BANKING_AND_CARDS}>
                    <BankingAndCardsTabContent />
                </Tabs.TabPane>
                <Tabs.TabPane tab={t("label:export_and_bookkeeping")} key={FeatureSettingsTabEnum.EXPORT_AND_BOOKKEEPING}>
                    <ExportAndBookkeepingTabContent />
                </Tabs.TabPane>
            </Tabs>
        </SettingsLayout>
    )
}

export default React.memo(FeatureSettings)
