import { Badge } from "antd"
import { BadgeProps } from "antd/lib/badge"
import React from "react"

import Icon from "Components/icon"
import { useNotificationUnseenCount } from "Shared/queries/notification.queries"

const NotificationBadge = React.forwardRef((props: BadgeProps, _ref) => {
    const notificationsUnseenCountResult = useNotificationUnseenCount()

    return (
        <Badge size="small" count={notificationsUnseenCountResult.data?.totalUnseen ?? 0} offset={[-10, 8]} {...props}>
            <Icon className="cursor-pointer" icon="activity_bell" />
        </Badge>
    )
})

export default React.memo(NotificationBadge)
