import { User } from "@finway-group/shared/lib/models"
import UserCompanyProfileInterface from "@finway-group/shared/lib/models/user/userCompanyProfileInterface"
import { useQueryClient } from "@tanstack/react-query"
import { Button, Form, Modal } from "antd"
import React, { useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import { DeleteOrDemoteEmployeeModal } from "Components/modals/deleteEmployee.modal"
import { useCompanies } from "Shared/hooks/company.hooks"
import { QueryKeyEnum } from "Shared/reactQuery.types"
import { NotificationService, UserService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { getAvailableCompanies } from "Shared/utils/company.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

import { UserCompanyProfileForm } from "../forms/employeeForm/userCompanyProfile.form"

interface UserCompanyProfileModalProps {
    user: User
    companyProfile: UserCompanyProfileInterface & { company: { _id: string; name: string } }
    onSubmit: () => void
    isNewCompanyProfile: boolean
    isShowing: boolean
    onCancel: () => void
}

export const UserCompanyProfileModal = ({ isNewCompanyProfile, isShowing, onCancel, user, companyProfile, onSubmit }: UserCompanyProfileModalProps) => {
    const { t } = useTranslation()
    const queryClient = useQueryClient()

    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useStateIfMounted(false)
    const [workflowsReplacementApproverId, setWorkflowsReplacementApproverId] = useStateIfMounted<string | undefined>(undefined)
    const [showDemoteEmployeeModal, setShowDemoteEmployeeModal] = useStateIfMounted<boolean>(false)

    const [formInstance] = Form.useForm<UserCompanyProfileInterface>()

    useEffect(
        () => () => {
            if (isShowing) formInstance.resetFields()
        },
        [isShowing, formInstance],
    )

    const companies = useCompanies()

    /**
     * available companies do not include companies the user is currently part of
     */
    const availableCompanies = useMemo(() => getAvailableCompanies(companies, user), [companies, user?.companyProfiles])

    const handleSubmit = async (values: UserCompanyProfileInterface) => {
        try {
            setIsLoading(true)

            if (!isNewCompanyProfile) {
                const showDemoteEmployeeModal = await UserService.submitCompanyProfileUpdate({ values, user, workflowsReplacementApproverId, dispatch })
                if (showDemoteEmployeeModal.showDemoteEmployeeModal) {
                    setShowDemoteEmployeeModal(showDemoteEmployeeModal.showDemoteEmployeeModal)
                    return
                }

                NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:company_profile.updated.title"), t("notification:company_profile.updated.message"))
            }

            if (isNewCompanyProfile) {
                await UserService.submitNewCompanyProfile({ values, user, dispatch })
                NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:company_profile.created.title"), t("notification:company_profile.created.message"))
            }

            handleHide(true)
            queryClient.invalidateQueries([QueryKeyEnum.USERS])
        } catch (err) {
            if (isNewCompanyProfile) {
                NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:employee.create.title"))
            } else NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:employee.edit.title"))
        } finally {
            setIsLoading(false)
        }
    }

    const handleHide = (isSubmit = false) => {
        setIsLoading(false)
        setWorkflowsReplacementApproverId(undefined)
        formInstance.resetFields()
        if (isSubmit && onSubmit) onSubmit()
        onCancel()
    }

    return (
        <>
            <DeleteOrDemoteEmployeeModal
                isShowing={showDemoteEmployeeModal}
                onCancel={() => {
                    setShowDemoteEmployeeModal(false)
                }}
                onSubmit={(_, workflowsReplacementApproverIdVal) => {
                    setShowDemoteEmployeeModal(false)
                    setWorkflowsReplacementApproverId(workflowsReplacementApproverIdVal)
                    formInstance.submit()
                }}
                operationType="demote"
                user={user}
                companyId={companyProfile.companyId}
            />
            <Modal
                title={!isNewCompanyProfile ? `${t("action:company_profile.edit")}: ${companyProfile.company?.name}` : t("action:company_profile.add")}
                visible={isShowing}
                maskClosable={false}
                onCancel={() => handleHide()}
                cancelText={t("action:cancel")}
                confirmLoading={isLoading}
                destroyOnClose
                footer={[
                    <Button
                        key="back"
                        onClick={() => {
                            formInstance.resetFields()
                            handleHide()
                        }}
                    >
                        {t("action:cancel")}
                    </Button>,
                    <Button
                        key="submit"
                        type="primary"
                        loading={isLoading}
                        onClick={() => {
                            formInstance.submit()
                        }}
                    >
                        {isNewCompanyProfile ? t("action:add") : t("action:save")}
                    </Button>,
                ]}
            >
                <Form className="p-5" form={formInstance} onFinish={() => handleSubmit(formInstance.getFieldsValue(true))} layout="vertical">
                    <UserCompanyProfileForm
                        isCompanyEditable={isNewCompanyProfile}
                        companyProfile={companyProfile}
                        user={user}
                        formInstance={formInstance}
                        onFinish={handleSubmit}
                        onHide={() => handleHide()}
                        availableCompanies={availableCompanies}
                    />
                </Form>
            </Modal>
        </>
    )
}

export default React.memo(UserCompanyProfileModal)
