import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons"
import { Button, Form, Modal } from "antd"
import { useForm } from "antd/lib/form/Form"
import FormItem from "antd/lib/form/FormItem"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import { NotificationService } from "Shared/services"
import CardService from "Shared/services/card.service"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { ThunkDispatchResult } from "Shared/store"
import { syncCreditCard } from "Shared/store/actions/creditCard/creditCardActions"
import { codeInitInputRefHelper, codeInputJumpHelper, codePasteHelper } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

interface CardActivationModalInterface {
    isVisible: boolean
    card?: any
    onHide: () => void
    onActivationSuccess: () => void
}

export const CardActivationModal = ({ isVisible, card, onHide, onActivationSuccess }: CardActivationModalInterface) => {
    const { t } = useTranslation()
    const [formInstance] = useForm()
    const dispatch: ThunkDispatchResult = useDispatch()
    const [isLoading, setIsLoading] = useStateIfMounted(false)
    const [isShown, setIsShown] = useState(false)
    const activationCodeCodeRef: any = []

    const onSubmit = async () => {
        const activationCode = activationCodeCodeRef.map((ref: any) => ref.value).join("")

        setIsLoading(true)
        CardService.activateCard(card._id, card.user, activationCode)
            .then((card: any) => {
                NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:cards.activated.title"), t("notification:cards.activated.message"))
                dispatch(syncCreditCard(card))
                onActivationSuccess()
            })
            .catch((err: any) => {
                NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:error"))
            })
            .finally(() => setIsLoading(false))
    }

    const handleHide = () => {
        setIsLoading(false)
        formInstance.resetFields()
        onHide()
    }

    return (
        <Modal
            title={t("label:cards.activation_code.title")}
            className="ant-modal--card-verification-code"
            visible={isVisible}
            maskClosable={false}
            onCancel={handleHide}
            cancelText={t("action:cancel")}
            confirmLoading={isLoading}
            destroyOnClose
            footer={[
                <Button key="back" onClick={handleHide}>
                    {t("action:cancel")}
                </Button>,
                <Button key="submit" type="primary" loading={isLoading} disabled={isLoading} onClick={formInstance.submit}>
                    {t("action:cards.activate_card")}
                </Button>,
            ]}
        >
            <Form form={formInstance} onFinish={onSubmit} layout="inline" className="my-10 mx-auto">
                <p className="mx-auto text-center text-sm mb-20 font-bold"> {t("label:cards.activation_code.description_title")}</p>
                <p className="mx-auto text-center text-sm text-text mb-40"> {t("label:cards.activation_code.message")}</p>
                <div className="mx-auto">
                    {[...Array(6)].map((_x, i) => (
                        <FormItem name={`pin-${i}`} key={i} className="inline-block">
                            <input
                                className="token-input text-xl h-30 ml-5 w-40"
                                pattern="[0-9]{1}"
                                maxLength={1}
                                inputMode="numeric"
                                type={isShown ? "tel" : "password"}
                                ref={(input) => codeInitInputRefHelper(input, i, activationCodeCodeRef)}
                                onKeyDown={(e) => codeInputJumpHelper(e, i, formInstance, activationCodeCodeRef, 6, false)}
                                onPaste={(e) => codePasteHelper(e, formInstance, activationCodeCodeRef, 6, false)}
                                value=""
                            />
                        </FormItem>
                    ))}
                    <Button className="bg-transparent" onClick={() => setIsShown(!isShown)}>
                        {isShown ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                    </Button>
                </div>
            </Form>
        </Modal>
    )
}

export default React.memo(CardActivationModal)
