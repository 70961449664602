import { Rule } from "antd/lib/form"
import i18n from "i18next"

export const SettingRules = (): { [key: string]: Array<Rule> } => ({
    globalCurrency: [{ type: "string", required: true, message: i18n.t("validation:required"), validateTrigger: "blur" }],
    archiveAfterXDays: [
        { transform: (v) => Number(v), type: "number", message: i18n.t("validation:numeric") },
        { transform: (v) => Number(v), type: "number", min: 0, message: i18n.t("validation:min.number", { min: 0 }) },
    ],
})
