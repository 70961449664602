import { ExclamationCircleOutlined } from "@ant-design/icons"
import { RightEnum, Tax } from "@finway-group/shared/lib/models"
import { isBuCodeDATEVCompliant } from "@finway-group/shared/lib/utils/datevValidation"
import { Alert, Col, Row, Tag, Tooltip } from "antd"
import React from "react"
import { Edit as EditIcon, Plus as PlusIcon } from "react-feather"
import { useTranslation } from "react-i18next"

import TaxModal from "Components/modals/tax.modal"
import SettingsLayout, { SettingsButton } from "Features/settings/settings.layout"
import { WARNING_ORANGE } from "Shared/config/consts"
import { useModal } from "Shared/context/modal.context"
import { useTaxes } from "Shared/hooks/tax.hooks"
import { AuthzService } from "Shared/services"
import { getTaxLabel } from "Shared/utils/helper.utils"

const TaxSettings = () => {
    const { t } = useTranslation()

    const taxValues = useTaxes(true)
    const { showModal } = useModal()

    const isDatevCompliant = taxValues.every((tax: Tax) => !tax.buCode || isBuCodeDATEVCompliant(tax.buCode))
    const canWrite = AuthzService.isRightGrantedForLoggedInUser(RightEnum.COMPANY_ACCOUNTING_SETTINGS__ALL__WRITE)
    const isEmpty = taxValues.length === 0

    return (
        <SettingsLayout
            titleKey="label:vat_title"
            buttons={
                <>
                    {canWrite && (
                        <SettingsButton
                            icon={isEmpty ? <PlusIcon /> : <EditIcon data-testid="taxSettingsEditIcon" />}
                            onClick={() => showModal(TaxModal, false, { taxes: taxValues, isShowing: true })}
                            labelKey={isEmpty ? "action:create" : "action:edit"}
                        />
                    )}
                </>
            }
        >
            {!isDatevCompliant && <Alert type="warning" showIcon className="mb-20" message={t("info:bu_code_datev_compliance")} />}
            {isEmpty ? (
                <div className="text-text-dark p-10">{t("missing:no_tax")}</div>
            ) : (
                <div className="p-5 w-full overflow-y-auto overflow-x-hidden">
                    <Row gutter={[16, 16]} className="font-bold">
                        <Col span={8} className="text-left self-center">
                            {t("label:vat_rate")}
                        </Col>
                        <Col span={10} className="text-left self-center">
                            {t("label:bu_code_name")}
                        </Col>
                        <Col span={6} className="text-right self-center">
                            {t("label:bu_code")}
                        </Col>
                    </Row>
                    {taxValues.map((tax: Tax, index: number) => (
                        <Row gutter={[16, 16]} key={index}>
                            <Col span={8} className="text-left self-center truncate">
                                {getTaxLabel({ taxRate: tax.taxRate })}

                                {tax.isDefault && <Tag className="ml-10 ant-tag-blue">{t(`label:default`)}</Tag>}
                            </Col>
                            <Col span={10} className="text-left self-center truncate">
                                <Tooltip placement="bottom" title={tax.buCodeName ? tax.buCodeName : "-"}>
                                    {tax.buCodeName ? tax.buCodeName : "-"}
                                </Tooltip>
                            </Col>

                            <Col span={6} className="self-center truncate flex items-center justify-end">
                                {tax.buCode ? tax.buCode : "-"}
                                {tax.buCode && !isBuCodeDATEVCompliant(tax.buCode) && <ExclamationCircleOutlined className="ml-6" style={{ color: WARNING_ORANGE }} />}
                            </Col>
                        </Row>
                    ))}
                </div>
            )}
        </SettingsLayout>
    )
}

export default React.memo(TaxSettings)
