import { Button, Col, Row } from "antd"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"

import { useAuth } from "Shared/hooks/auth.hooks"

import AuthFooter from "./components/auth.footer"

const AuthErrorPage = () => {
    const { logout } = useAuth()
    const { search } = useLocation()
    const { t } = useTranslation()

    const errorMessage = useMemo(() => decodeURI(new URLSearchParams(search).get("error") ?? ""), [search])

    return (
        <div className="login-bg flex flex-col items-center justify-center min-h-screen h-full w-full overflow-hidden relative">
            <Row className="w-full p-10 z-50 mb-30 my-auto">
                <Col span={24} className="max-w-400 min-w-300 my-0 mx-auto overflow-x-hidden overflow-y-hidden">
                    <div className="flex w-full flex-col items-center justify-center p-28 z-50">
                        <img src={"./images/logo.svg"} className="h-36" alt={"FINWAY"} />
                    </div>
                    <div className="bg-white w-full rounded-lg shadow p-32 mb-32 mt-32 md:mt-40">
                        <div className="flex gap-16 flex-col items-center text-center">
                            <h1>{t("info:auth_error.login_failed")}</h1>
                            <p>{t("info:auth_error.text")}</p>

                            <div className="w-full px-16 py-10 rounded-lg bg-error-bg border border-error text-error font-medium">{errorMessage}</div>

                            <Button type="primary" onClick={logout}>
                                {t("label:back_to_login")}
                            </Button>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row className="w-full p-10 z-50 bottom-0 sticky mt-auto">
                <Col span={24} className="max-w-450 min-w-300 my-0 mx-auto">
                    <AuthFooter />
                </Col>
            </Row>
        </div>
    )
}

export default AuthErrorPage
