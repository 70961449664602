import { Expense, TabTypeEnum } from "@finway-group/shared/lib/models"
import { Reducer } from "redux"

import {
    AttachTagExpenseAction,
    DeleteDiscountsAction,
    DeleteExpenseAction,
    DetachTagExpenseAction,
    ExpenseActionTypes,
    ExpenseActions,
    FetchAllExpensesAction,
    FetchExpenseCountsAction,
    FetchOneExpenseAction,
    SetActiveTabAction,
    SetShouldRefetchExpensesAction,
    SubmitDiscountsAction,
    SyncOneExpenseAction,
    UpdateExpenseAction,
    UpdateFilterAction,
} from "../actions/expense/expenseTypes"

export interface ExpenseState {
    item: any
    items: Array<Expense>
    counts: any
    totalPages: number
    totalDocs: number
    activeTab: TabTypeEnum
    filter: string
    shouldRefetch: boolean
}

const initialState = {
    item: new Expense(),
    items: [],
    counts: { toDo: 0, review: 0, payExport: 0, done: 0, inProgress: 0, allRequests: 0, archive: 0 },
    totalPages: 0,
    totalDocs: 0,
    activeTab: TabTypeEnum.TO_DOS,
    // review tab
    itemsPerPageRequestsReviewTab: 10,
    currentPageRequestsReviewTab: 1,
    // pay&export tab
    itemsPerPageRequestsPayExportTab: 10,
    currentPageRequestsPayExportTab: 1,
    // all tab
    itemsPerPageRequestsAllTab: 10,
    currentPageRequestsAllTab: 1,
    // subscriptions
    itemsPerPageSubscriptions: 10,
    currentPageSubscriptions: 1,
    // filter
    filter: "",
    shouldRefetch: false,
}

const fetchAllExpenses = (state: ExpenseState, { expenses, totalPages, totalDocs, append }: FetchAllExpensesAction): ExpenseState => {
    let { item } = state
    if (item) {
        const itemInAction = expenses.find(({ id }: Expense) => id === item?.id)
        if (itemInAction) item = itemInAction
    }

    return {
        ...state,
        items: !append ? expenses : [...state.items, ...expenses],
        item,
        totalPages,
        totalDocs,
    }
}

const setDashboardExpensesCount = (state: ExpenseState, { counts }: FetchExpenseCountsAction): ExpenseState => ({
    ...state,
    counts,
})

const fetchOneExpense = (state: ExpenseState, { expense }: FetchOneExpenseAction): ExpenseState => ({
    ...state,
    item: expense,
})

const syncOneExpense = (state: ExpenseState, { expense }: SyncOneExpenseAction): ExpenseState => {
    const isIncludedInStateItems = state.items.filter((exp) => exp.id === expense.id).length > 0

    return {
        ...state,
        items: isIncludedInStateItems ? state.items.map((item) => (item.id === expense.id ? expense : item)) : state.items,
        item: state.item?.id === expense.id ? expense : state.item,
    }
}

const updateExpense = (state: ExpenseState, { expense }: UpdateExpenseAction): ExpenseState => ({
    ...state,
    items: state.items.map((exp) => (exp.id === expense.id ? expense : exp)),
    item: state.item?.id === expense.id ? expense : state.item,
})

const deleteExpense = (state: ExpenseState, { expense }: DeleteExpenseAction): ExpenseState => ({
    ...state,
    items: state.items.filter((exp) => exp.id !== expense.id),
    item: state.item?.id === expense.id ? new Expense() : state.item,
})

const setActiveTab = (state: ExpenseState, action: SetActiveTabAction): ExpenseState => ({
    ...state,
    activeTab: action.activeTab,
})

const setFilter = (state: ExpenseState, action: UpdateFilterAction): ExpenseState => ({
    ...state,
    filter: action.filter,
})

const setShouldRefetch = (state: ExpenseState, action: SetShouldRefetchExpensesAction): ExpenseState => ({
    ...state,
    shouldRefetch: action.shouldRefetch,
})

const attachTagExpense = (state: ExpenseState, action: AttachTagExpenseAction): ExpenseState => ({
    ...state,
    item: new Expense({ ...state.item, expenseTags: [...(state.item.expenseTags as Array<string>), action.expenseTagId] }),
})

const detachTagExpense = (state: ExpenseState, action: DetachTagExpenseAction): ExpenseState => ({
    ...state,
    item: new Expense({ ...state.item, expenseTags: state.item?.expenseTags?.filter((tagId: string) => tagId !== action.expenseTagId) }),
})

const submitDiscounts = (state: ExpenseState, action: SubmitDiscountsAction): ExpenseState => ({
    ...state,
    item: state.item?.id === action.expenseId ? new Expense({ ...state.item, discounts: action.discounts }) : state.item,
    items: state.items.map((e) => (e.id === action.expenseId ? new Expense({ ...e, discounts: action.discounts }) : e)),
})

const deleteDiscounts = (state: ExpenseState, action: DeleteDiscountsAction): ExpenseState => ({
    ...state,
    item: state.item?.id === action.expenseId ? new Expense({ ...state.item, discounts: [] }) : state.item,
    items: state.items.map((e) => (e.id === action.expenseId ? new Expense({ ...e, discounts: [] }) : e)),
})

export const expenseReducer: Reducer<ExpenseState, ExpenseActions> = (state = initialState, action) => {
    switch (action.type) {
        case ExpenseActionTypes.FETCH_ALL_EXPENSES:
            return fetchAllExpenses(state, action)
        case ExpenseActionTypes.FETCH_EXPENSE_COUNTS:
            return setDashboardExpensesCount(state, action)
        case ExpenseActionTypes.FETCH_ONE_EXPENSE:
            return fetchOneExpense(state, action)
        case ExpenseActionTypes.SYNC_ONE_EXPENSE:
            return syncOneExpense(state, action)
        case ExpenseActionTypes.UPDATE_EXPENSE:
            return updateExpense(state, action)
        case ExpenseActionTypes.DELETE_EXPENSE:
            return deleteExpense(state, action)
        case ExpenseActionTypes.SEND_REMINDER:
            return state
        case ExpenseActionTypes.SET_ACTIVE_TAB:
            return setActiveTab(state, action)
        case ExpenseActionTypes.UPDATE_FILTER:
            return setFilter(state, action)
        case ExpenseActionTypes.SET_SHOULD_REFETCH_EXPENSES:
            return setShouldRefetch(state, action)
        case ExpenseActionTypes.ATTACH_TAG_EXPENSE:
            return attachTagExpense(state, action)
        case ExpenseActionTypes.DETACH_TAG_EXPENSE:
            return detachTagExpense(state, action)
        case ExpenseActionTypes.SUBMIT_DISCOUNTS:
            return submitDiscounts(state, action)
        case ExpenseActionTypes.DELETE_DISCOUNTS:
            return deleteDiscounts(state, action)
        default:
            return state
    }
}
