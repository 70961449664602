import { CalculationIntervals } from "@finway-group/shared/lib/consts"
import { CalculationIntervalEnum } from "@finway-group/shared/lib/models"
import { Card, Col, Row, Select } from "antd"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import ProgressBar from "Components/ProgressBar"
import UserImage from "Components/UserImage"
import { useEmployeeData, useEmployeeInterval } from "Shared/hooks/analytics.hooks"
import { EmployeeService, UserService } from "Shared/services"
import { fetchExpensesPerEmployeeData, setEmployeeInterval } from "Shared/store/actions/analytics/analyticsActions"
import { getSelectLabel } from "Shared/utils/report.utils"

const { Option } = Select

const EmployeeAnalytics = React.memo(() => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const employeeInterval = useEmployeeInterval()
    const globalCurrency = UserService.getLoggedInEmployeeProfile()?.settings.globalCurrency
    const { employeeData, maxEmployeeValue, totalSumEmployees } = useEmployeeData()

    useEffect(() => {
        fetchExpensesPerEmployeeData()(dispatch)
    }, [employeeInterval])

    const handleFilterChange = async (value: string) => dispatch(setEmployeeInterval(Number(value)))

    return (
        <>
            <Row>
                <Col xs={24} lg={12}>
                    <div className="flex justify-between items-center pb-0">
                        <h2 className="pt-8">{t("label:employees")}</h2>
                        <Select
                            className="float-right"
                            defaultValue={getSelectLabel(CalculationIntervals[employeeInterval])}
                            onChange={handleFilterChange}
                            dropdownMatchSelectWidth={false}
                        >
                            <Option value={CalculationIntervalEnum.ALL_TIME} label="All Times">
                                <span>{getSelectLabel(CalculationIntervals[CalculationIntervalEnum.ALL_TIME])}</span>
                            </Option>
                            <Option value={CalculationIntervalEnum.CURRENT_YEAR} label="Current Year">
                                <span>{getSelectLabel(CalculationIntervals[CalculationIntervalEnum.CURRENT_YEAR])}</span>
                            </Option>
                            <Option value={CalculationIntervalEnum.CURRENT_MONTH} label="Current Month">
                                <span>{getSelectLabel(CalculationIntervals[CalculationIntervalEnum.CURRENT_MONTH])}</span>
                            </Option>
                        </Select>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col xs={24} lg={12}>
                    <Card id={"reporting-employee-section"}>
                        {employeeData?.length > 0 ? (
                            <>
                                {employeeData?.map((dataSet: any, i: number) => {
                                    const employee = EmployeeService.getEmployeeById(dataSet.id)
                                    return (
                                        <div className="flex flex-col xs:flex-row items-start xs:items-center my-16" key={i}>
                                            <div className="flex mr-5 w-full xs:w-1/3">
                                                {employee && (
                                                    <>
                                                        <UserImage user={employee} size="large" />
                                                        <div className="flex flex-col justify-center truncate">
                                                            <p className="text-text-dark font-bold mt-3 mb-3 leading-none truncate">
                                                                {`${employee.firstName} ${employee.lastName}`}
                                                            </p>
                                                            <p className="text-xs leading-tight truncate">{employee.position}</p>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                            <div className="w-full xs:w-2/3 mt-10 xs:mt-0">
                                                <ProgressBar maxValue={maxEmployeeValue} total={totalSumEmployees} value={dataSet.value} currency={globalCurrency} />
                                            </div>
                                        </div>
                                    )
                                })}
                            </>
                        ) : (
                            <div className="flex justify-center items-center h-100">
                                <p>{t("missing:expense_data")}</p>
                            </div>
                        )}
                    </Card>
                </Col>
            </Row>
        </>
    )
})

export default EmployeeAnalytics
