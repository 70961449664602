import { Expense, Hospitality, Mileage, PerDiem, TripFolder } from "@finway-group/shared/lib/models"
import React, { useContext } from "react"

interface ExpenseDetailsContextInterface {
    expense: Expense | Mileage | PerDiem | Hospitality | TripFolder // The "main" expense that's currently loaded on redux
    activeExpense: Expense | Mileage | PerDiem | Hospitality | TripFolder // The expense that is currently selected, can be the folder itself, can be the content
    folder?: TripFolder // If its a folder type expense, this field will be populated.
    subExpenses: Array<Expense> // The sub expenses from the folder. If its not a folder, it will be []
    isLoading: boolean
    setIsLoading: (value: boolean) => void
    setIsShowingImportProtocolModal: (value: boolean) => void // Value to display the DuoImportProtocolModal component in expenseDetails container.
    isShowingDiscountForm: boolean
    setIsShowingDiscountForm: (value: boolean) => void
    isReadOnly: boolean
}

export const Context = React.createContext<ExpenseDetailsContextInterface | undefined>(undefined)

export const ExpenseDetailsContext: React.FC<ExpenseDetailsContextInterface> = ({
    activeExpense,
    folder,
    subExpenses,
    expense,
    children,
    isLoading,
    setIsLoading,
    setIsShowingImportProtocolModal,
    isShowingDiscountForm,
    setIsShowingDiscountForm,
    isReadOnly,
}) => (
    <Context.Provider
        value={{
            activeExpense,
            expense,
            folder,
            subExpenses,
            isLoading,
            setIsLoading,
            setIsShowingImportProtocolModal,
            isReadOnly,
            isShowingDiscountForm,
            setIsShowingDiscountForm,
        }}
    >
        {children}
    </Context.Provider>
)

export const useExpenseDetailsContext = () => {
    const context = useContext(Context) as ExpenseDetailsContextInterface
    if (context === undefined) {
        throw new Error("useExpenseDetailsContext must be used inside <ExpenseDetailsContext></ExpenseDetailsContext>")
    }
    return context
}
