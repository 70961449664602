import { EyeOutlined as EyeIcon } from "@ant-design/icons"
import { Expense, ExpenseKindEnum, ExpenseStatusEnum, RightEnum } from "@finway-group/shared/lib/models"
import { ExpensePaymentOptionEnum } from "@finway-group/shared/lib/models/expense/expensePaymentOption.enum"
import { isDiscountActionPossibleForExpense } from "@finway-group/shared/lib/utils/expense.discount.utils"
import { Button, Dropdown, Menu, Modal } from "antd"
import React from "react"
import { Archive as ArchiveIcon, Calendar, X as CancelIcon, Check as CheckedIcon, MoreHorizontal as MoreIcon, RotateCcw as RecoverIcon, RotateCw as ResetIcon } from "react-feather"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"

import { AttachDiscountMenuItemContent } from "Features/pages/expenses/expenseDetails/auxiliary/attachDiscountMenuItem"
import AttachIcon from "Features/pages/expenses/expenseDetails/icons/attach.icon"
import { ATTACHABLE_EXPENSE_STATUSES } from "Shared/config/consts"
import { useModal } from "Shared/context/modal.context"
import { useArchiveInterval } from "Shared/hooks/company.hooks"
import { cancelExpenseRequest, markExpenseAsPaid, restoreExpense } from "Shared/hooks/expense.hooks"
import { useIsTravelEnabled } from "Shared/hooks/featureFlags.hooks"
import { useLoggedInEmployeeProfile } from "Shared/hooks/user.hooks"
import { AuthzService, CompanyService, EmployeeService, ExpenseService, NotificationService } from "Shared/services"
import DialogService from "Shared/services/dialog.service"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { archiveExpense, resetExpense, unarchiveExpense } from "Shared/store/actions/expense/expenseActions"
import { getExpenseReminderForUser, isExpenseArchived, isExpenseNotResettable } from "Shared/utils/expense.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

import { AttachToFolderModal } from "./modals/attachToFolder/attachToFolder.modal"
import ExpenseReminderModal from "./modals/expenseReminder.modal"

interface AdditionalExpenseOptionButtonInterface {
    expense: Expense
    isEditDisabled: boolean
    setIsShowingImportProtocolModal: (isShowing: boolean) => void
    onOpenDiscountForm: () => void
}

const AdditionalExpenseOptionButton = ({ expense, setIsShowingImportProtocolModal, isEditDisabled = false, onOpenDiscountForm }: AdditionalExpenseOptionButtonInterface) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const history = useHistory()
    const loggedInUser = useLoggedInEmployeeProfile()
    const isCompanyGobCompliant = CompanyService.doesCompanyEnforceGobdCompliantInvoice()
    const isTravelEnabled = useIsTravelEnabled()
    const isLoggedInUserGlobalApprover = AuthzService.isLoggedInUserGlobalApprover()

    const [isLoading, setIsLoading] = useStateIfMounted(false)
    const reminder = getExpenseReminderForUser(expense.reminders, loggedInUser.id)

    const archiveAfterXDays = useArchiveInterval()
    const archived = isExpenseArchived(expense, archiveAfterXDays)
    const isEditableBasedOnArchive = ExpenseService.isExpenseEditableBasedOnArchive(archived)

    const deleteDisabled =
        expense.cannotBeDeleted() || (expense.isSuccessfullyProcessed() && !isLoggedInUserGlobalApprover) || expense.deleted || isEditDisabled || !isEditableBasedOnArchive
    const { showModal } = useModal()

    const canAttachExpenseToFolder =
        expense.kind !== ExpenseKindEnum.TRIP_FOLDER &&
        ATTACHABLE_EXPENSE_STATUSES.includes(expense.status) &&
        isTravelEnabled &&
        !expense.isSubscription() &&
        isEditableBasedOnArchive

    const requestedBy = EmployeeService.getEmployeeById(expense.requestedBy._id)
    const hasExportRight = AuthzService.canLoggedInUserExport()
    const hasPayRight = AuthzService.canLoggedInUserPayByBank()

    const expenseIsGobdCompliantInvoice = expense.isGobdCompliantInvoice()

    const isAuthorizedToDeleteAndNotGobCompliant = AuthzService.canLoggedInUserDeleteRequest(loggedInUser, requestedBy) && !expenseIsGobdCompliantInvoice
    const isAuthorizedToArchive = AuthzService.canLoggedInUserArchiveRequest(loggedInUser, requestedBy)
    const isAuthorizedToResetStatus = AuthzService.canLoggedInUserResetStatusRequest(loggedInUser, requestedBy)

    const onSelect = async ({ key, domEvent }: any) => {
        domEvent.stopPropagation()
        switch (key) {
            case "reset_status":
                onReset()
                break
            case "restore":
                onRestoreExpense()
                break
            case "delete":
                onDeleteExpense()
                break
            case "mark_as_paid":
                onMarkAsPaid()
                break
            case "archive":
                handleArchive()
                break
            case "unarchive":
                handleUnarchive()
                break
            case "datev_import_protocol":
                onShowDuoImportProtocol()
                break
            case "add_to_folder":
                handleAddToFolder()
                break
            case "attach_discount":
                onOpenDiscountForm()
                break
            default:
                break
        }
    }

    const onShowDuoImportProtocol = () => {
        setIsShowingImportProtocolModal(true)
    }

    const onMarkAsPaid = () => {
        setIsLoading(true)
        markExpenseAsPaid(expense).finally(() => setIsLoading(false))
    }

    const onReset = async () => {
        const isConfirmed = await DialogService.confirmExpenseStatusReset(expense)

        if (isConfirmed) {
            try {
                setIsLoading(true)
                await resetExpense(expense.id)(dispatch)
                NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:request.reset.title"), t("notification:request.reset.message"))
            } catch (err) {
                NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:error"))
            } finally {
                setIsLoading(false)
            }
        }
    }

    const onRestoreExpense = () => {
        Modal.confirm({
            title: t("confirm:request.restore.title"),
            content: t("confirm:request.restore.message"),
            cancelText: t("confirm:request.restore.cancel"),
            type: "warning",
            okText: t("confirm:request.restore.confirm"),
            async onOk() {
                try {
                    setIsLoading(true)
                    await restoreExpense(expense, loggedInUser.id)
                } catch (err) {
                    NotificationService.send(NotificationTypeEnum.ERROR, t("error:error"), err)
                } finally {
                    setIsLoading(false)
                }
            },
        })
    }

    const onDeleteExpense = () => {
        cancelExpenseRequest(expense, true, () => {
            history.goBack()
        })
    }

    const handleArchive = async () => {
        const isConfirmed = await DialogService.confirmRequestArchive()
        if (!isConfirmed) return

        setIsLoading(true)
        archiveExpense(expense.id)(dispatch)
            .then(() => NotificationService.send(NotificationTypeEnum.SUCCESS, t(`notification:request.archived.title`), t(`notification:request.archived.message`)))
            .catch((err) => {
                NotificationService.showErrorNotificationBasedOnResponseError(err, t(`error:request.archived.title`))
            })
            .finally(() => setIsLoading(false))
    }

    const handleUnarchive = () => {
        setIsLoading(true)
        unarchiveExpense(expense.id)(dispatch)
            .then(() => NotificationService.send(NotificationTypeEnum.SUCCESS, t(`notification:request.unarchived.title`), t(`notification:request.unarchived.message`)))
            .catch((err) => {
                NotificationService.showErrorNotificationBasedOnResponseError(err, t(`error:request.unarchived.title`))
            })
            .finally(() => setIsLoading(false))
    }

    const handleAddToFolder = () => {
        showModal(AttachToFolderModal, true, { isShowing: true, expense, onClose: () => {} })
    }

    const generateDropDownContent = () => {
        const menus: Array<JSX.Element> = []

        if (expense.deleted) {
            if (isLoggedInUserGlobalApprover) {
                menus.push(
                    <Menu.Item key="restore" className="flex items-center min-w-190 py-8">
                        <div className="flex items-center justify-center rounded-full w-16 h-16">
                            <RecoverIcon />
                        </div>
                        <div className="flex items-center ml-12">
                            <p className="leading-none">
                                <span className="text-text">{t("action:request.restore")}</span>
                            </p>
                        </div>
                    </Menu.Item>,
                )
                return menus
            }
            return []
        }

        if (
            expense.status === ExpenseStatusEnum.REVIEWED &&
            expense.paymentOption === ExpensePaymentOptionEnum.SMART_CARD &&
            AuthzService.isRightGrantedForLoggedInUser(RightEnum.CARD_WALLET__ALL__MANAGE)
        ) {
            menus.push(
                <Menu.Item key="mark_as_paid" className="flex items-center min-w-190 py-8">
                    <div className="flex items-center justify-center rounded-full w-16 h-16">
                        <CheckedIcon strokeWidth={1.3} />
                    </div>
                    <div className="flex items-center ml-12">
                        <p className="leading-none">
                            <span className="text-text">{t("action:mark_as_paid")}</span>
                        </p>
                    </div>
                </Menu.Item>,
            )
        }

        if (!reminder) {
            menus.push(
                <Menu.Item
                    key="follow_up"
                    className="flex items-center min-w-190 py-8"
                    onClick={() => showModal(ExpenseReminderModal, true, { isShowing: true, expenseId: expense.id, reminder })}
                >
                    <div className="flex items-center justify-center rounded-full w-16 h-16">
                        <Calendar strokeWidth={1.3} />
                    </div>
                    <div className="flex items-center ml-12">
                        <p className="leading-none">
                            <span className="text-text">{t("action:follow_up")}</span>
                        </p>
                    </div>
                </Menu.Item>,
            )
        }

        if (isAuthorizedToDeleteAndNotGobCompliant && !deleteDisabled) {
            menus.push(
                <Menu.Item key="delete" className="flex items-center min-w-190 py-8">
                    <div className="flex items-center justify-center rounded-full w-16 h-16">
                        <CancelIcon strokeWidth={1.3} />
                    </div>
                    <div className="flex items-center ml-12">
                        <p className="leading-none">
                            <span className="text-text">{t("action:delete")}</span>
                        </p>
                    </div>
                </Menu.Item>,
            )
        }

        if (isAuthorizedToResetStatus && !archived) {
            menus.push(
                <Menu.Item key="reset_status" className="flex items-center min-w-190 py-8" disabled={isExpenseNotResettable(expense)}>
                    <div className="flex items-center justify-center rounded-full w-16 h-16">
                        <ResetIcon strokeWidth={1.3} />
                    </div>
                    <div className="flex items-center ml-12">
                        <p className="leading-none">
                            <span className="text-text">{t("action:request.reset_status")}</span>
                        </p>
                    </div>
                </Menu.Item>,
            )
        }
        if (isAuthorizedToArchive && (!archived || !isCompanyGobCompliant)) {
            menus.push(
                <Menu.Item key={archived ? "unarchive" : "archive"} className="flex items-center min-w-190 py-8">
                    <div className="flex items-center justify-center rounded-full w-16 h-16">
                        <ArchiveIcon strokeWidth={1.3} />
                    </div>
                    <div className="flex items-center ml-12">
                        <p className="leading-none">
                            <span className="text-text">{t(`action:archive.${archived ? "undo" : "do"}`)}</span>
                        </p>
                    </div>
                </Menu.Item>,
            )
        }

        if (canAttachExpenseToFolder && !expense.folderId && !expenseIsGobdCompliantInvoice && !archived) {
            menus.push(
                <Menu.Item key="add_to_folder" className="flex items-center min-w-190 py-8">
                    <div className="flex items-center justify-center rounded-full w-16 h-16">
                        <AttachIcon />
                    </div>
                    <div className="flex items-center ml-12">
                        <p className="leading-none">
                            <span className="text-text">{t("action:request.add_to_folder")}</span>
                        </p>
                    </div>
                </Menu.Item>,
            )
        }

        if (hasExportRight && expense.datevOnlineInformation?.datevProtocolEntries?.length) {
            menus.push(
                <Menu.Item key="datev_import_protocol" className="flex items-center min-w-190 py-8">
                    <div className="flex items-center justify-center rounded-full w-16 h-16">
                        <EyeIcon />
                    </div>
                    <div className="flex items-center ml-12">
                        <p className="leading-none">
                            <span className="text-text">{t("action:request.show_datev_import_protocol")}</span>
                        </p>
                    </div>
                </Menu.Item>,
            )
        }

        if (hasPayRight && isDiscountActionPossibleForExpense(expense)) {
            menus.push(
                <Menu.Item key="attach_discount" className="flex items-center min-w-190 py-8">
                    <AttachDiscountMenuItemContent />
                </Menu.Item>,
            )
        }

        return menus
    }

    const dropDownContent = generateDropDownContent()

    const dropDownMenu = () => <Menu onClick={onSelect}>{dropDownContent}</Menu>

    /* In case there is no element in dropdown, don't display the button at all */
    return dropDownContent.length ? (
        <Dropdown placement="bottomRight" overlay={dropDownMenu} trigger={["click"]} className="flex items-center z-100 m-5">
            <Button loading={isLoading} onClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}>
                <MoreIcon />
            </Button>
        </Dropdown>
    ) : null
}

export default AdditionalExpenseOptionButton
