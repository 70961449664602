import { Transaction } from "@finway-group/shared/lib/models"
import moment from "moment-timezone"

import { isNotSet } from "Shared/utils/helper.utils"

export const isTransactionArchived = (transaction: Pick<Transaction, "isManuallyArchived" | "transactionData">, archiveAfterXDays: number) =>
    !isNotSet(transaction.isManuallyArchived)
        ? transaction.isManuallyArchived
        : moment(transaction.transactionData.date).isSameOrBefore(moment().subtract(archiveAfterXDays, "days").toDate())

export const isTransactionManuallyUnarchived = (transaction: Pick<Transaction, "isManuallyArchived" | "transactionData">, archiveAfterXDays: number) =>
    !isNotSet(transaction.isManuallyArchived) &&
    !transaction.isManuallyArchived &&
    moment(transaction.transactionData.date).isSameOrBefore(moment().subtract(archiveAfterXDays, "days").toDate())
