import { TransactionCategory, TransactionCategoryTypeEnum } from "@finway-group/shared/lib/models"
import { ActionCreator, Dispatch } from "redux"

import i18n from "Shared/locales/i18n"
import { NotificationService, TransactionCategoryService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { ThunkResult } from "Shared/store"
import { TablesEnum } from "Shared/store/reducers/tableConfigReducer"

import { CancelFetchAction, FetcherActionTypes } from "../fetcher/fetcherTypes"
import { refetchTable } from "../tables/tableActions"
import { TableActionsTypes } from "../tables/tableTypes.enum"
import {
    CreateTransactionCategoryAction,
    DeleteTransactionCategoryAction,
    FetchAllTransactionCategoryAction,
    SyncOneTransactionCategoryAction,
    TransactionCategoryActionTypes,
    UpdateTransactionCategoryAction,
    UpdateTransactionCategoryOrderAction,
} from "./transactionCategoryTypes"

const fetchAllTransactionCategoriesAction = (transactionCategories: Array<TransactionCategory>): FetchAllTransactionCategoryAction => ({
    type: TransactionCategoryActionTypes.FETCH_ALL_TRANSACTION_CATEGORIES,
    transactionCategories,
})

export const fetchAllTransactionCategories: ActionCreator<ThunkResult<Array<TransactionCategory>>> = () => async (dispatch: Dispatch) => {
    const transactionCategories: Array<TransactionCategory> = await TransactionCategoryService.fetchAllTransactionCategories()

    const cancelFetchAction: CancelFetchAction = {
        type: FetcherActionTypes.CANCEL_FETCH,
        isFetchCancelled: true,
    }

    dispatch(cancelFetchAction)

    dispatch(fetchAllTransactionCategoriesAction(transactionCategories))

    return transactionCategories
}

export const createTransactionCategory = (createData: { name: string; type: TransactionCategoryTypeEnum; parentId?: string }) => async (dispatch: Dispatch) => {
    const { createdTransactionCategory, updatedParentTransactionCategory } = await TransactionCategoryService.create(createData)

    const createTransactionCategoryAction: CreateTransactionCategoryAction = {
        type: TransactionCategoryActionTypes.CREATE_TRANSACTION_CATEGORY,
        transactionCategory: createdTransactionCategory,
        parentTransactionCategory: updatedParentTransactionCategory,
    }

    dispatch(createTransactionCategoryAction)
    if (createdTransactionCategory.type === TransactionCategoryTypeEnum.INFLOW) {
        dispatch(refetchTable(TablesEnum.TRANSACTION_CATEGORIES_INFLOW))
    } else if (createdTransactionCategory.type === TransactionCategoryTypeEnum.OUTFLOW) {
        dispatch(refetchTable(TablesEnum.TRANSACTION_CATEGORIES_OUTFLOW))
    }
}

export const updateTransactionCategory = (transactionCategoryId: string, updateData: { name: string }) => async (dispatch: Dispatch) => {
    const transactionCategory = await TransactionCategoryService.update(transactionCategoryId, updateData)

    const updateTransactionCategoryAction: UpdateTransactionCategoryAction = {
        type: TransactionCategoryActionTypes.UPDATE_TRANSACTION_CATEGORY,
        transactionCategory,
    }

    dispatch(updateTransactionCategoryAction)
    if (transactionCategory.type === TransactionCategoryTypeEnum.INFLOW) {
        dispatch(refetchTable(TablesEnum.TRANSACTION_CATEGORIES_INFLOW))
    } else if (transactionCategory.type === TransactionCategoryTypeEnum.OUTFLOW) {
        dispatch(refetchTable(TablesEnum.TRANSACTION_CATEGORIES_OUTFLOW))
    }
}

export const deleteTransactionCategory = (transactionCategoryId: string, transactionCategoryType?: TransactionCategoryTypeEnum) => async (dispatch: Dispatch) => {
    const { deletedTransactionCategoriesIds, updatedParentTransactionCategory } = await TransactionCategoryService.delete(transactionCategoryId)

    const deleteTransactionCategoryAction: DeleteTransactionCategoryAction = {
        type: TransactionCategoryActionTypes.DELETE_TRANSACTION_CATEGORY,
        deletedTransactionCategoriesIds,
        updatedParentTransactionCategory,
    }

    dispatch(deleteTransactionCategoryAction)
    if (transactionCategoryType === TransactionCategoryTypeEnum.INFLOW) {
        dispatch(refetchTable(TablesEnum.TRANSACTION_CATEGORIES_INFLOW))
    } else if (transactionCategoryType === TransactionCategoryTypeEnum.OUTFLOW) {
        dispatch(refetchTable(TablesEnum.TRANSACTION_CATEGORIES_OUTFLOW))
    }
}

export const updateTransactionCategoryOrder = (transactionCategoryId: string, newOrder: any) => async (dispatch: Dispatch) => {
    try {
        await TransactionCategoryService.update(transactionCategoryId, { order: newOrder })
        const transactionCategories = await TransactionCategoryService.fetchAllTransactionCategories()

        const updateTransactionCategoriesOrderAction: UpdateTransactionCategoryOrderAction = {
            type: TransactionCategoryActionTypes.UPDATE_TRANSACTION_CATEGORY_ORDER,
            transactionCategories,
        }

        dispatch(updateTransactionCategoriesOrderAction)
        dispatch(refetchTable(TablesEnum.TRANSACTION_CATEGORIES_INFLOW))
        dispatch(refetchTable(TablesEnum.TRANSACTION_CATEGORIES_OUTFLOW))
    } catch (err) {
        NotificationService.showErrorNotificationBasedOnResponseError(err, i18n.t("error:transaction_category.order.title"), i18n.t("error:transaction_category.order.message"))
    }
}

export const syncTransactionCategory = (id: string) => async (dispatch: Dispatch) => {
    const transactionCategory = await TransactionCategoryService.fetchOneTransactionCategory(id)
    const syncOneTransactionAction: SyncOneTransactionCategoryAction = {
        type: TransactionCategoryActionTypes.SYNC_ONE_TRANSACTION_CATEGORY,
        transactionCategory,
    }

    dispatch(syncOneTransactionAction)

    return transactionCategory
}
