import { DatevErrorEnum } from "@finway-group/shared/lib/models/company/datev/datevError.enum"
import { Alert } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"

import { useDatevOnlineError } from "Shared/hooks/company.hooks"

const IGNORED_ERRORS = [
    DatevErrorEnum.SETUP_ABORT, // Ignore because companies outside of Germany don't need DATEV and can't dismiss the message by other means
]

const DatevOnlineErrorBanner: React.FC = () => {
    const { t } = useTranslation()
    const datevOnlineError = useDatevOnlineError()

    if (!datevOnlineError || IGNORED_ERRORS.includes(datevOnlineError)) return null

    return (
        <Alert
            message={<span className="font-bold">{t(`info:datev_online_error.title`)}</span>}
            description={t(`info:datev_online_error.${datevOnlineError?.toLowerCase()}`)}
            type="error"
            showIcon={true}
        />
    )
}

export default DatevOnlineErrorBanner
