import { CardModeEnum, CardTypeEnum, ExpenseKindEnum, PriceIntervalEnum } from "@finway-group/shared/lib/models"
import { Card } from "antd"
import moment from "moment"
import React from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

import PriceLabel from "Components/priceLabel"
import { useEmployeeById } from "Shared/hooks/employee.hooks"
import { useLoggedInEmployeeProfile } from "Shared/hooks/user.hooks"
import { AuthzService } from "Shared/services"
import { getCardLimitWithoutBuffer, getLabelForWindow } from "Shared/utils/creditCard.utils"

interface CardSettingsInterface {
    card: any
}

const CardSettings: React.FC<CardSettingsInterface> = ({ card }) => {
    const { t } = useTranslation()
    const loggedInUser = useLoggedInEmployeeProfile()
    const isMyCard = card.user === loggedInUser.id

    const user = useEmployeeById(card?.user)

    const showPhoneNumber = isMyCard || AuthzService.canManageCardForUser(user)

    return (
        <Card className="h-full">
            <ul className="description-list mt-10">
                {card.cardName && (
                    <li>
                        <span className="title">{t("label:cards.card_name")}</span>
                        <span className="text text-left truncate max-w-250">{card.cardName}</span>
                    </li>
                )}
                <li>
                    <span className="title">{t("label:cards.created_at")}</span>
                    <span className="text text-left truncate max-w-250">{moment(card.createdAt).format("ll")}</span>
                </li>
                <li>
                    <span className="title">{t("label:cards.card_type")}</span>
                    <span className="text text-left truncate max-w-250">{t(`creditCard:type.${card.cardType.toLowerCase()}`)}</span>
                </li>
                {card.cardType === CardTypeEnum.PHYSICAL && (
                    <li>
                        <span className="title">{t("label:cards.delivery_type.title")}</span>
                        <span className="text text-left truncate max-w-250">{t(`label:cards.delivery_type.${card.cardDeliveryMethod?.toLowerCase()}`)}</span>
                    </li>
                )}
                <li>
                    <span className="title">{t("label:cards.frequency")}</span>
                    <span className="text text-left">{getLabelForWindow(card.window)}</span>
                </li>
                <li>
                    <span className="title">{t("label:cards.mode.mode")}</span>
                    <span className="text text-left">{t(`label:cards.mode.${card.mode?.toLowerCase()}`)}</span>
                </li>
                <li>
                    <span className="title">{t("label:cards.amount_limit")}</span>
                    <span className="text text-left">
                        <PriceLabel value={getCardLimitWithoutBuffer(card)} currency={card.currency} interval={PriceIntervalEnum.ONE_TIME} showFullNumber />
                    </span>
                </li>
                {card.mode === CardModeEnum.PREPAID_MODE && (
                    <>
                        <li>
                            <span className="title">{t("label:cards.available_balance")}</span>
                            <span className="text text-left">
                                <PriceLabel value={card.balance} currency={card.currency} interval={PriceIntervalEnum.ONE_TIME} showFullNumber />
                            </span>
                        </li>

                        <li>
                            <span className="title">{t("label:cards.auto_recharge_enabled")}</span>
                            <span className="text text-left truncate max-w-250">{card.isAutoRechargeEnabled ? t("label:cards.enabled") : t("label:cards.disabled")}</span>
                        </li>
                    </>
                )}
                <li>
                    <span className="title">{t("label:cards.update_expense_amount_on_card_transaction")}</span>
                    <span className="text text-left truncate max-w-250">
                        {card.shouldUpdateExpenseAmountOnCardTransaction ? t("label:cards.enabled") : t("label:cards.disabled")}
                    </span>
                </li>
                {card.expense && (
                    <li>
                        <span className="title">
                            {card.expense.kind === ExpenseKindEnum.SUBSCRIPTION ? t("label:cards.attached_subscription") : t("label:cards.attached_expense")}
                        </span>
                        <span className="text">
                            {card.expense.kind === ExpenseKindEnum.SUBSCRIPTION ? (
                                <Link to={`/subscriptions/${card.expense._id}`}>{card.expense.name}</Link>
                            ) : (
                                <Link to={`/expenses/${card.expense._id}`}>
                                    {t("label:request")} # {card.expense.expenseNumber}
                                </Link>
                            )}
                        </span>
                    </li>
                )}
                {showPhoneNumber && (
                    <li>
                        <span className="title">{t("label:phone_number")}</span>
                        <span className="text text-left truncate max-w-250">{card.cardholderPhoneNumber || "-"}</span>
                    </li>
                )}
            </ul>
        </Card>
    )
}

export default CardSettings
