import Icon from "@ant-design/icons"
import React from "react"

export const CubeXLSVG = () => (
    <svg fill="currentColor" version="1.1" viewBox="0 0 52.917 52.917" xmlns="http://www.w3.org/2000/svg">
        <g transform="translate(.24906 -.10857)">
            <g transform="matrix(.26458 0 0 .26458 3.9843 4.2284)">
                <path
                    d="m2 124.64v-83.839c0-2.3122 1.377-4.4023 3.5016-5.3149l75.313-32.349c1.4427-0.61969 3.0753-0.62624 4.523-0.01816l77.119 32.394c2.147 0.902 3.544 3.0039 3.544 5.333v83.747c0 2.252-1.306 4.299-3.349 5.247l-77.115 35.802c-1.5604 0.724-3.3624 0.717-4.9165-0.021l-75.316-35.755c-2.0177-0.958-3.3037-2.992-3.3037-5.226z"
                    stroke="#60cdb2"
                    strokeWidth="4"
                />
            </g>
            <g transform="matrix(.26458 0 0 .26458 4.5004 13.812)">
                <path d="M 81.3721,129 V 38.2857 m 0,0 L 163,2 M 81.3721,38.2857 1,2" stroke="#60cdb2" strokeWidth="4" />
            </g>
            <g transform="matrix(.26458 0 0 .26458 15.051 8.7988)">
                <path d="M 83,39 1,2" stroke="#60cdb2" strokeWidth="4" />
            </g>
        </g>
    </svg>
)

export const CubeXLIcon = (props: any) => <Icon component={CubeXLSVG} {...props} />

export default CubeXLIcon
