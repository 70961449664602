import { PASSWORD_MIN_ALLOWED_LENGTH } from "@finway-group/shared/lib/consts/common"
import { checkIfStringContainsSpecialCharacters } from "@finway-group/shared/lib/utils/common"
import { FormInstance } from "antd/lib/form"

import i18n from "Shared/locales/i18n"

const PasswordFormRule = [
    { required: true, message: i18n.t("validation:required"), validateTrigger: "onChange" },
    { min: 6, message: i18n.t("validation:min.string", { min: 6 }), validateTrigger: "onSubmit" },
]

export default PasswordFormRule

export const generateRules = (formInstance: FormInstance) => ({
    password: [
        { required: true, message: i18n.t("validation:required"), validateTrigger: "onChange" },
        { min: PASSWORD_MIN_ALLOWED_LENGTH, message: i18n.t("validation:min.string", { min: PASSWORD_MIN_ALLOWED_LENGTH }), validateTrigger: "onSubmit" },
        { max: 100, message: i18n.t("validation:max.string", { max: 100 }), validateTrigger: "onChange" },
        {
            validator: (_rule: any, value: string) => {
                const hasLeadingOrTrailingSpaces = value.trim() !== value
                if (hasLeadingOrTrailingSpaces) {
                    return Promise.reject(new Error(i18n.t("validation:password.password_has_leading_or_trailing_spaces")))
                }
                return Promise.resolve()
            },
            validateTrigger: "onChange",
        },
        {
            validator: (_rule: any, value: string) => {
                const passwordDoesNotHaveSpecialCharacters = !checkIfStringContainsSpecialCharacters(value)
                if (passwordDoesNotHaveSpecialCharacters) {
                    return Promise.reject(new Error(i18n.t("validation:password.password_must_have_at_least_one_special_symbol")))
                }
                return Promise.resolve()
            },
            validateTrigger: "onSubmit",
        },
    ],
    confirmPassword: [
        { required: true, message: i18n.t("validation:required"), validateTrigger: "onChange" },
        { min: PASSWORD_MIN_ALLOWED_LENGTH, message: i18n.t("validation:min.string", { min: PASSWORD_MIN_ALLOWED_LENGTH }), validateTrigger: "onSubmit" },
        { max: 100, message: i18n.t("validation:max.string", { max: 100 }), validateTrigger: "onChange" },
        {
            validator: (_rule: any, value: string) => {
                if (value !== formInstance.getFieldValue("password")) {
                    return Promise.reject(new Error(i18n.t("validation:password.notmatching")))
                }

                return Promise.resolve()
            },
        },
    ],
})
