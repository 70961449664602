import { Checkbox, Col, Divider, Form, Row, Tooltip } from "antd"
import { CheckboxChangeEvent } from "antd/lib/checkbox"
import { FormInstance } from "antd/lib/form"
import React from "react"
import { useTranslation } from "react-i18next"

import { isSet } from "Shared/utils/helper.utils"

import { rightFormData } from "./right.form.config"
import { RightEnumFormValue } from "./role.form.types"

interface RightFormInterface {
    formInstance: FormInstance
    readOnly?: boolean
}

const RightForm = ({ readOnly, formInstance }: RightFormInterface) => {
    const { t } = useTranslation()

    /**
     * Hack to make tool tips work correctly for disabled elements
     * TODO: Check and remove w/ antd upgrade if possible maybe use the tooltip prop of the formitem element instead introduced in 4.7.0
     * @see https://github.com/ant-design/ant-design/issues/18137#issuecomment-650866924
     */
    const renderCheckBoxTooltipSafe = ({
        name,
        title,
        tooltip,
        onEnabled,
        onDisabled,
    }: {
        name?: string
        title?: string
        tooltip?: string
        onEnabled?: RightEnumFormValue
        onDisabled?: RightEnumFormValue
    }) => (
        <Tooltip title={tooltip ? t(tooltip) : ""} getPopupContainer={(triggerNode) => triggerNode} mouseLeaveDelay={0}>
            <span style={{ cursor: readOnly ? "not-allowed" : "pointer" }}>
                <div className="flex">
                    <Form.Item name={name} initialValue={false} valuePropName="checked" noStyle>
                        <Checkbox
                            style={readOnly ? { pointerEvents: "none" } : {}}
                            disabled={readOnly}
                            onChange={({ target: { checked: enabled } }: CheckboxChangeEvent) => {
                                if (enabled && onEnabled) formInstance.setFieldsValue(onEnabled)
                                else if (onDisabled) formInstance.setFieldsValue(onDisabled)
                            }}
                        />
                    </Form.Item>
                    {title && <div className={`ml-10 ${readOnly ? "text-gray-800" : ""}`}>{t(title)}</div>}
                </div>
            </span>
        </Tooltip>
    )

    return (
        <>
            <Row className="flex justify-between mb-10 sticky top-0 z-50 mt-30 bg-white font-bold">
                <Col span={24} className="mb-10">
                    {t("label:roles.detailed_rights")}
                </Col>
                <Col span={4}>{t("label:roles.resource")}</Col>
                <Col span={11} className="flex items-center justify-center">
                    {t("label:roles.access_rights")}
                </Col>
                <Col span={9}>{t("label:roles.action_rights")}</Col>
                <Divider className="mb-0" />
            </Row>

            {rightFormData.map((e, index) => (
                <React.Fragment key={`detailed_rights_${index}`}>
                    {index > 0 && e.resource && <Divider className="w-full" />}
                    <Row className="h-auto min-h-30">
                        <Col span={4} className="h-auto min-h-30 flex items-center">
                            {e.resource ? t(e.resource) : ""}
                        </Col>
                        <Col span={11} className="flex items-center justify-center">
                            {isSet(e.readRight) && e.readRight && renderCheckBoxTooltipSafe(e.readRight)}
                        </Col>
                        <Col span={9} className="flex items-center">
                            {e.actionRight && renderCheckBoxTooltipSafe(e.actionRight)}
                        </Col>
                    </Row>
                </React.Fragment>
            ))}
        </>
    )
}

export default RightForm
