import { Action } from "redux"

// Model imports

export enum CreditCardActionTypes {
    FETCH_ALL_CARDS = "FETCH_ALL_CARDS",
    FETCH_ONE_CARD = "FETCH_ONE_CARD",
    SYNC_ONE_CARD = "SYNC_ONE_CARD",
    SET_SHOULD_REFETCH_CARDS = "SET_SHOULD_REFETCH_CARDS",
}

// TODO: type
export interface FetchAllCardsAction extends Action<typeof CreditCardActionTypes.FETCH_ALL_CARDS> {
    type: typeof CreditCardActionTypes.FETCH_ALL_CARDS
    cards: Array<any>
    totalPages: number
    totalDocs: number
    append: boolean
}

export interface FetchOneCardAction extends Action<typeof CreditCardActionTypes.FETCH_ONE_CARD> {
    type: typeof CreditCardActionTypes.FETCH_ONE_CARD
    card: any
}
export interface SyncOneCardAction extends Action<typeof CreditCardActionTypes.SYNC_ONE_CARD> {
    type: typeof CreditCardActionTypes.SYNC_ONE_CARD
    card: any
}

export interface SetShouldRefetchCardsAction extends Action<typeof CreditCardActionTypes.SET_SHOULD_REFETCH_CARDS> {
    type: typeof CreditCardActionTypes.SET_SHOULD_REFETCH_CARDS
    shouldRefetch: boolean
}

export type CreditCardActions = FetchAllCardsAction | FetchOneCardAction | SyncOneCardAction | SetShouldRefetchCardsAction
