import React from "react"

export const MobileIcon = () => (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10.4987 0H3.4987C2.85527 0 2.33203 0.523234 2.33203 1.16667V12.8333C2.33203 13.4768 2.85527 14 3.4987 14H10.4987C11.1421 14 11.6654 13.4768 11.6654 12.8333V1.16667C11.6654 0.523234 11.1421 0 10.4987 0ZM10.4987 1.16667V12.25H3.4987V1.16667H10.4987Z"
            fill="#606C7E"
        />
        <path
            d="M6.70573 2.33333H7.28906C7.45028 2.33333 7.58073 2.20288 7.58073 2.04167C7.58073 1.88045 7.45028 1.75 7.28906 1.75H6.70573C6.54451 1.75 6.41406 1.88045 6.41406 2.04167C6.41406 2.20288 6.54451 2.33333 6.70573 2.33333Z"
            fill="#606C7E"
        />
        <path
            d="M9.6237 2.33333C9.78478 2.33333 9.91536 2.20275 9.91536 2.04167C9.91536 1.88058 9.78478 1.75 9.6237 1.75C9.46261 1.75 9.33203 1.88058 9.33203 2.04167C9.33203 2.20275 9.46261 2.33333 9.6237 2.33333Z"
            fill="#606C7E"
        />
    </svg>
)
