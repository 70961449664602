import { LoginInput } from "@finway-group/shared/lib/models"
import axios from "axios"

import { ErrorTypes } from "Shared/error.types"
import { decodeAccessToken, decodeAndFormatAccessToken, decodeAndFormatAccessTokenValidTime } from "Shared/utils/auth.utils"

import LocalStorageService from "./localstorage.service"

const AuthnService = {
    forgotPassword: async (email: string) => axios.post(`/forgot-password`, { email }),
    verifyToken: async (token: string) => axios.get<{ email: string }>(`/verify/reset/${token}`),
    verifyEmailConfirmationToken: async (token: string) => axios.get(`/verify/email/${token}`),
    updatePassword: async (loginInput: LoginInput) => axios.post("/change-password", loginInput),
    resetPassword: async (token: string, bodyData: { newPassword: string; loggedInUserEmail?: string }) => {
        const { data } = await axios.post(`/reset/${token}`, { password: bodyData.newPassword, email: bodyData.loggedInUserEmail })
        return data
    },
    silentRefresh: async () => {
        try {
            return await AuthnService.silentRefreshWithoutLogout()
        } catch (e) {
            // log user out
            LocalStorageService.unsetAccessToken()
        }
    },
    silentRefreshWithoutLogout: async () => {
        try {
            const { data } = await axios.post("/token/refresh", {}, { withCredentials: true })
            LocalStorageService.setAccessToken(data.accessToken)
            axios.defaults.headers.common.Authorization = `Bearer ${data.accessToken}`
            return data
        } catch (e) {
            throw new Error(ErrorTypes.SILENT_REFRESH_FAILED)
        }
    },
    setCurrentCompany: async (companyId: string) => {
        const { data } = await axios.post(`/token/set-company/${companyId}`, {}, { withCredentials: true })
        axios.defaults.headers.common.Authorization = `Bearer ${data.accessToken}`
        LocalStorageService.setAccessToken(data.accessToken)
        return data
    },
    login: async (loginInput: LoginInput) => axios.post<{ accessToken: string; companyId: string; passwordResetToken?: string }>("/login", loginInput),
    logout: async () => axios.post("/logout", {}),
    logoutAllSessions: async () => {
        await axios.post("/logout-all-sessions", {})
        LocalStorageService.unsetAccessToken()
    },
    // TFA AUTH APP
    fetchTotp: async () => axios.post("/generate-totp"),
    setupTfAuth: async (token: string) => axios.post("setup-tfauth", { token }),
    disableTfAuth: async () => axios.post("/disable-tfauth"),
    validateToken: async (token: string) => axios.post("/validate-totp-token", { token }),
    // TFA AUTH SMS
    enableSmsTfAuth: async () => axios.post("enable-sms-tfauth", {}),
    validateSmsTfAuthToken: async (token: string) => axios.post("validate-sms-tfauth-token", { token }),
    getDataFromAccessToken: () => {
        const encodedAccessToken = LocalStorageService.getAccessToken()
        return decodeAndFormatAccessToken(encodedAccessToken)
    },
    getAccessTokenValidTime: () => {
        const accessTokenEncoded = LocalStorageService.getAccessToken()
        return decodeAndFormatAccessTokenValidTime(accessTokenEncoded)
    },
    getCurrentTenantId: () => {
        const encodedAccessToken = LocalStorageService.getAccessToken()
        const { tenantId } = decodeAccessToken(encodedAccessToken!)
        return tenantId
    },
}

export default AuthnService
