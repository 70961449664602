import { Input } from "antd"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"

import useStateIfMounted from "../shared/utils/hooks/useStateIfMounted"

const { Search } = Input
interface SearchInputInterface {
    onSearch: (text: string) => void
    isLoading?: boolean
    value?: string
    className?: string
}

const SearchInput = ({ onSearch, isLoading = false, value = "", className = "" }: SearchInputInterface) => {
    const { t } = useTranslation()

    const [isEmpty, setIsEmpty] = useStateIfMounted(true)
    const [isOpen, setIsOpen] = useStateIfMounted(false)
    const [isOnBlur, setIsOnBlur] = useStateIfMounted(false)
    const [searchValue, setSearchValue] = useStateIfMounted("")

    useEffect(() => {
        setSearchValue(value)

        if (value.length > 0) {
            setIsEmpty(false)
            handleOnSearch()
        } else if (isOpen) setIsOpen(false)
    }, [value])

    // handle search value change
    const handleOnChange = (e: any) => {
        const { value } = e.target
        if (!value.length) {
            setIsEmpty(true)
            setSearchValue("")
            onSearch("")
            return
        }

        setIsEmpty(false)
        setSearchValue(value)
        onSearch(value)
    }

    // on blur (click or leave) check state and set value
    const handleOnBlur = () => {
        if (isOpen) {
            if (isEmpty) {
                setIsOnBlur(true)
                setIsOpen(false)
            }

            return
        }

        setIsOnBlur(false)
    }

    // on search icon click => check input state and react
    const handleOnSearch = () => {
        if (!isOnBlur) {
            if (!isOpen) setIsOpen(true)
        }

        setIsOnBlur(false)
    }

    // when on blur and transition end, reset blur
    const handleTransitionEnd = () => !isOpen && isOnBlur && setIsOnBlur(false)

    return (
        <Search
            data-testid="searchInputField"
            className={`${isOpen ? "open" : ""} ${className}`}
            placeholder={t("placeholder:search")}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onTransitionEnd={handleTransitionEnd}
            onSearch={handleOnSearch}
            value={searchValue}
            loading={isLoading}
            /* Not always true as we have small search button when no input and it won't fit two icons (search and delete) */
            allowClear={!!value}
        />
    )
}

export default React.memo(SearchInput)
