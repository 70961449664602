import { ExclamationCircleOutlined, EyeOutlined } from "@ant-design/icons"
import LoadingOutlined from "@ant-design/icons/lib/icons/LoadingOutlined"
import { CardStatusEnum, Employee, RightEnum, Transaction, TransactionTypeEnum, Vendor } from "@finway-group/shared/lib/models"
import { TransactionStatusEnum } from "@finway-group/shared/lib/models/transaction/transactionStatus.enum"
import { Button, Card, Col, Empty, Modal, Popover, Row, Skeleton, Table, Tag } from "antd"
import { ColumnsType } from "antd/lib/table"
import moment from "moment"
import React, { Suspense, useEffect, useState } from "react"
import { Edit as EditIcon } from "react-feather"
import { Helmet } from "react-helmet"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { Link, generatePath, useHistory, useParams } from "react-router-dom"

import PageCustomHeader from "Components/PageCustomHeader"
import UserImage from "Components/UserImage"
import CardStatusTag from "Components/cardStatusTag"
import CreditCard from "Components/creditCard/creditCard"
import ExpenseStatusTag from "Components/expenseStatusTag"
import { ErrorTable } from "Components/layout/errorTable"
import Loading from "Components/loading"
import CardActivationModal from "Components/modals/cardActivation.modal"
import ChangeCardLimitModal from "Components/modals/changeCardLimit.modal"
import EditCardDetailModal from "Components/modals/editCard.modal"
import PhoneVerificationModal from "Components/modals/phoneVerfication.modal"
import TransactionAmountColumn from "Components/transactionAmountColumn"
import TransactionStatusTag from "Components/transactionStatusTag"
import { useModal } from "Shared/context/modal.context"
import { useCard } from "Shared/hooks/creditCard.hooks"
import { useEmployeeById, useEmployees } from "Shared/hooks/employee.hooks"
import { useFetchTable, useTable } from "Shared/hooks/table.hooks"
import { useLoggedInEmployeeProfile } from "Shared/hooks/user.hooks"
import { useVendors } from "Shared/hooks/vendor.hooks"
import { RoutePathEnum } from "Shared/router/RoutePath.enum"
import { AuthzService, NotificationService, VendorService } from "Shared/services"
import CardService from "Shared/services/card.service"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { ThunkDispatchResult } from "Shared/store"
import { emptyCard, fetchOneCreditCard, syncCreditCard, topUpCard, unblockCardPin, updateCardStatus } from "Shared/store/actions/creditCard/creditCardActions"
import { refetchTable, updateTableOnChange } from "Shared/store/actions/tables/tableActions"
import { setTransaction } from "Shared/store/actions/transaction/transactionActions"
import { TablesEnum } from "Shared/store/reducers/tableConfigReducer"
import { getCardStatusForAction } from "Shared/utils/creditCard.utils"
import { getStatusLabel } from "Shared/utils/expense.utils"
import { formatCurrencyNumber, isEmptyObject, isNotSet } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

import CardActionMenu from "./cardActionMenu"
import CardBanner from "./cardBanner"
import CardSettings from "./cardSettings"
import DisplayPinModal from "./showPinModal"

const { confirm } = Modal

const CreditCardDetail = () => {
    const { t } = useTranslation()
    const history = useHistory()
    const { id } = useParams<{ id: string }>()
    const dispatch: ThunkDispatchResult = useDispatch()
    const [showCardActivationModal, setShowCardActivationModal] = useStateIfMounted<any>(undefined)
    const [showPinModal, setShowPinModal] = useStateIfMounted(false)
    const [showPhoneVerificationModal, setShowPhoneVerificationModal] = useStateIfMounted(false)
    const [isEditModalVisible, setIsEditModalVisible] = useState<boolean>(false)
    const [isUpdating, setIsUpdating] = useStateIfMounted(false)
    const [isRetrying, setIsRetrying] = useStateIfMounted<boolean>(false)
    const card = useCard()
    const cardUser = useEmployeeById(card?.user)
    const loggedInUser = useLoggedInEmployeeProfile()
    const employees = useEmployees()
    const vendors = useVendors()

    const canManageCard = AuthzService.canManageCardForUser(cardUser)

    const cardTransactionsTable = useTable<Transaction>(TablesEnum.CARD_TRANSACTIONS)
    useFetchTable(TablesEnum.CARD_TRANSACTIONS, { cardId: id })

    const cardDisabled = [CardStatusEnum.TERMINATED, CardStatusEnum.REPORTED_LOST, CardStatusEnum.REPORTED_STOLEN].includes(card?.status)
    const { showModal } = useModal()

    useEffect(() => {
        dispatch(fetchOneCreditCard(id as any)).catch(() => {
            history.push("/credit-cards")
        })
        dispatch(refetchTable(TablesEnum.CARD_TRANSACTIONS))
    }, [id])

    const onRetryCardCreation = (e: any) => {
        e.stopPropagation()
        setIsRetrying(true)
        CardService.fixCardError(card._id)
            .then((cardFixed) => {
                NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:cards.fix_card.title"), t("notification:cards.fix_card.message"))
                dispatch(syncCreditCard(cardFixed))
            })
            .catch((err: any) => {
                NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:error"))
            })
            .finally(() => setIsRetrying(false))
    }

    const columns: ColumnsType = [
        {
            title: t("label:vendor"),
            key: "transactionData.vendor",
            className: "pl-10",
            ellipsis: true,
            width: 150,
            render: ({ transactionData: { vendor, recipient } }: any) => <span>{VendorService.getVendorById(vendor)?.name || recipient || "n/a"}</span>,
            sorter: true,
        },
        {
            title: t("label:status"),
            key: "status",
            width: 150,
            align: "center",
            render: ({ status }: any) => <TransactionStatusTag status={status} />,
            sorter: true,
        },
        {
            title: t("label:type"),
            key: "cardTransactionType",
            width: 150,
            render: ({ cardData: { cardTransactionType }, transactionType }: any) =>
                transactionType === TransactionTypeEnum.INTERNAL_TRANSFER ? t("label:transfer") : t(`label:cards.transaction_types.${cardTransactionType?.toLowerCase()}`),
            sorter: true,
        },
        {
            title: t("label:date"),
            key: "transactionData.date",
            width: 150,
            render: ({ transactionData: { date } }) => <span>{moment(date).format("Do MMM, YYYY")}</span>,
            sorter: true,
        },
        {
            title: t("label:amount"),
            key: "transactionData.amount",
            width: 150,
            render: (transaction: Transaction) => <TransactionAmountColumn transaction={transaction} />,
            sorter: true,
        },
        {
            title: t("label:matching.match_status"),
            key: "status",
            width: 140,
            className: "pr-10",
            sorter: true,
            render: ({ status, matchingData: { matchedExpenses }, transactionType }) =>
                status === TransactionStatusEnum.FAILED || transactionType === TransactionTypeEnum.INTERNAL_TRANSFER ? (
                    <Tag className="ant-tag-gray">N/A</Tag>
                ) : matchedExpenses.length > 0 ? (
                    <Tag className="ant-tag-green">{t("info:matched")}</Tag>
                ) : (
                    <Button className="btn-highlight-green">{t("action:matching.match")}</Button>
                ),
        },
        {
            title: t("label:request_status"),
            key: "reqStatus",
            align: "right",
            width: 200,
            className: "pr-10",
            render: ({ status, matchingData: { matchedExpenses }, transactionType }) => (
                <div
                    onClick={(e) => {
                        e.stopPropagation()
                    }}
                >
                    {status === TransactionStatusEnum.FAILED || transactionType === TransactionTypeEnum.INTERNAL_TRANSFER || matchedExpenses.length === 0 ? (
                        <span>n/a</span>
                    ) : (
                        <>
                            {matchedExpenses.length === 1 ? (
                                <Link to={{ pathname: generatePath(RoutePathEnum.EXPENSE_DETAIL_PAGE, { id: matchedExpenses[0].expense._id }) }}>
                                    <ExpenseStatusTag status={matchedExpenses[0].expense.status} />
                                    <Button className="ml-10" size="small">
                                        <EyeOutlined />
                                    </Button>
                                </Link>
                            ) : (
                                <>
                                    <Tag className="ant-tag-green">{`${getStatusLabel(matchedExpenses[0].expense.status)} (+${matchedExpenses.length - 1})`}</Tag>
                                    <Popover color="white" content={renderMultipleTags(matchedExpenses)} trigger="click">
                                        <Button className="ml-10" size="small">
                                            <EyeOutlined />
                                        </Button>
                                    </Popover>
                                </>
                            )}
                        </>
                    )}
                </div>
            ),
        },
    ]

    const renderMultipleTags = (matchedExpenses: any) =>
        matchedExpenses.map((matchedExpense: { expense: any }) => {
            const employee = employees.find((e: Employee) => e.id === matchedExpense.expense.requestedBy._id)!
            const vendorName = vendors.find((vendor: Vendor) => vendor.id === matchedExpense.expense.vendor)?.name ?? "n/a"
            return (
                <Link to={{ pathname: generatePath(RoutePathEnum.EXPENSE_DETAIL_PAGE, { id: matchedExpense.expense._id }) }}>
                    <Row className="text-black hover:bg-gray-100 cursor-pointer">
                        <div>
                            <Row className="font-bold">
                                <span>{`${employee.firstName} ${employee.lastName}`}</span>
                            </Row>
                            <Row>
                                <span>{`#${matchedExpense.expense.expenseNumber} ${vendorName}`}</span>
                            </Row>
                        </div>
                    </Row>
                </Link>
            )
        })

    const onCardMenuAction = async (action: string) => {
        try {
            switch (action) {
                case "top_up":
                    setIsUpdating(true)
                    await dispatch(topUpCard(card?._id))
                    NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:cards.top_up.title"), t("notification:cards.top_up.message"))
                    setIsUpdating(false)
                    break
                case "empty":
                    setIsUpdating(true)
                    await dispatch(emptyCard(card?._id))
                    NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:cards.empty.title"), t("notification:cards.empty.message"))
                    setIsUpdating(false)
                    break
                case "update_pin":
                    setShowCardActivationModal(true)
                    break
                case "resume":
                    setIsUpdating(true)
                    await dispatch(updateCardStatus(card?._id, card?.user, CardStatusEnum.ACTIVE))
                    NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:cards.status.resumed.title"), t("notification:cards.status.resumed.message"))
                    setIsUpdating(false)
                    break
                case "show_pin":
                    setShowPinModal(true)
                    break
                case "suspend":
                case "close":
                case "report_stolen":
                case "report_lost":
                    await handleUserConfirmation(action)
                    break
                case "unblock_pin":
                    setIsUpdating(true)
                    await dispatch(unblockCardPin(card?._id))
                    NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:cards.pin_unblocked.title"), t("notification:cards.pin_unblocked.message"))
                    setIsUpdating(false)
                    break
                case "update_phone_number":
                    await handlePhoneNumberUpdate()
                    break
                case "request_limit_change":
                    showModal(ChangeCardLimitModal, true, { isShowing: true, card })
                    break
                default:
                    break
            }
        } catch (err) {
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:cards.update_status.title"))
            setIsUpdating(false)
        }
    }

    const handlePhoneNumberUpdate = () => {
        setShowPhoneVerificationModal(true)
    }

    const handleUserConfirmation = async (action: string) =>
        confirm({
            title: t(`confirm:cards.${action}.title`),
            icon: <ExclamationCircleOutlined />,
            content: t(`confirm:cards.${action}.message`),
            okText: t(`confirm:cards.${action}.confirm`),
            cancelText: t(`confirm:cards.${action}.cancel`),
            onOk() {
                return new Promise<void>((resolve, reject) => {
                    dispatch(updateCardStatus(card?._id, card?.user, getCardStatusForAction(action)))
                        .then(() => {
                            NotificationService.send(NotificationTypeEnum.WARNING, t(`notification:cards.status.${action}.title`), t(`notification:cards.status.${action}.message`))
                            resolve()
                        })
                        .catch((err) => {
                            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:cards.update_status.title"))
                            reject()
                        })
                })
            },
            onCancel() {},
        })

    const handleActivationSuccess = async () => {
        setShowPinModal(true)
        setShowCardActivationModal(false)
    }

    const handleLimitChange = async (isAccepted: boolean) => {
        const limit = isAccepted ? card.limitChangeRequest.limit : card.limit
        try {
            const updatedCard = await CardService.updateCard(card?._id, card?.user, { ...card, limit, limitChangeRequest: undefined })
            dispatch(syncCreditCard(updatedCard))
            NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:cards.updated.title"), t("notification:cards.updated.message"))
        } catch (err) {
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:cards.edit.title"))
        }
    }

    return !card || isEmptyObject(card) ? (
        <Loading />
    ) : (
        <div>
            {canManageCard && card.limitChangeRequest?.limit && (
                <Card>
                    <Row align="middle">
                        <Col span={18}>
                            <div className="flex">
                                {cardUser && (
                                    <div className="flex items-center h-40 w-50 inline-block">
                                        <UserImage user={cardUser} size="large" />
                                    </div>
                                )}
                                <div className="grow">
                                    {loggedInUser?.id === card?.user ? (
                                        <p className="text-lg font-bold">
                                            {t("info:cards.pending_limit_request", {
                                                requestedLimit: formatCurrencyNumber(card?.limitChangeRequest?.limit, card.currency),
                                            })}
                                        </p>
                                    ) : (
                                        <>
                                            <p className="text-lg font-bold">
                                                {t("info:cards.limit_change_request", {
                                                    userName: cardUser?.getFullName(),
                                                    cardName: card?.cardName,
                                                })}
                                            </p>
                                            <p>
                                                {t("info:cards.current_limit_and_requested_limit", {
                                                    currentLimit: formatCurrencyNumber(card?.limit, card.currency),
                                                    requestedLimit: formatCurrencyNumber(card?.limitChangeRequest?.limit, card.currency),
                                                })}
                                            </p>
                                        </>
                                    )}

                                    <p>{`${t("label:reason_of_the_request")}: ${card.limitChangeRequest?.reason || t("label:no_reason")}`}</p>
                                </div>
                            </div>
                        </Col>
                        <Col span={6} className="flex justify-center flex-wrap">
                            <Button type="default" className="inline-block min-w-120 m-5" onClick={() => handleLimitChange(false)}>
                                {t("action:reject")}
                            </Button>
                            <Button type="primary" className="inline-block min-w-120 m-5" onClick={() => handleLimitChange(true)}>
                                {t("action:approve")}
                            </Button>
                        </Col>
                    </Row>
                </Card>
            )}

            <Helmet>
                <title>{t("label:smart_card_details")}</title>
            </Helmet>
            <CardBanner card={card} isRetrying={isRetrying} onRetryCardCreation={onRetryCardCreation} setShowCardActivationModal={setShowCardActivationModal} />
            <EditCardDetailModal isVisible={isEditModalVisible} onCancel={setIsEditModalVisible} />
            <PageCustomHeader
                title={
                    <div className="flex">
                        <h1 className="-mb-2">{t("label:smart_card_details")}</h1>
                        {<Tag className="text-lg ant-tag-blue ml-16 mt-4">{t(`label:cards.mode.${card.mode?.toLowerCase()}`)}</Tag>}
                    </div>
                }
                actionButtons={
                    <>
                        {!isRetrying && (
                            <div className="btn-wrapper">
                                {card.status !== CardStatusEnum.UNACTIVATED && canManageCard && (
                                    <Button disabled={cardDisabled} className="btn-default" onClick={() => setIsEditModalVisible(true)}>
                                        <EditIcon />
                                        <span>{t("action:edit")}</span>
                                    </Button>
                                )}
                                {(canManageCard || loggedInUser.id === card.user) && (
                                    <CardActionMenu card={card} onCardMenuAction={onCardMenuAction} isLoading={isUpdating} canManageCard={canManageCard} />
                                )}
                            </div>
                        )}
                    </>
                }
            />

            <Row className="flex flex-col md:flex-row" gutter={[8, 16]}>
                <Col xs={24} sm={24} md={24} lg={10}>
                    <Suspense fallback={<Loading />}>
                        <Card className="text-center h-full items-center">
                            <div className="flex px-10 py-12 items-center justify-between">
                                <div className="flex items-center font-bold ">
                                    {cardUser && (
                                        <>
                                            <div className="flex items-center h-40 w-50">
                                                <UserImage user={cardUser} size="large" />
                                            </div>
                                            {cardUser.getFullName()}
                                        </>
                                    )}
                                </div>
                                <CardStatusTag status={card.status} hasError={!isNotSet(card.errorCode)} />
                            </div>

                            <CreditCard card={card} showActionBtn showBudget />
                        </Card>
                    </Suspense>
                </Col>
                <Col xs={24} sm={24} md={24} lg={14}>
                    <Suspense fallback={<Loading />}>
                        <CardSettings card={card} />
                    </Suspense>
                </Col>
            </Row>
            <div className="flex flex-wrap justify-between items-center mb-16">
                <h2>{t("label:transactions")}</h2>
            </div>
            <div className="overflow-auto p-2">
                {cardTransactionsTable.error ? (
                    <ErrorTable onTableReload={() => dispatch(refetchTable(TablesEnum.CARD_TRANSACTIONS))} isLoading={cardTransactionsTable.isFetching} />
                ) : cardTransactionsTable.data.totalDocs === 0 && !cardTransactionsTable.isFetching ? (
                    <Card>
                        <p className="text-center">{t("missing:cards.no_transactions")}</p>
                    </Card>
                ) : (
                    <Table<Transaction>
                        rowKey={(record: any) => record._id}
                        dataSource={cardTransactionsTable.data.docs}
                        columns={columns}
                        onChange={updateTableOnChange(TablesEnum.CARD_TRANSACTIONS, cardTransactionsTable.data, true)}
                        pagination={{
                            position: ["bottomRight"],
                            showSizeChanger: true,
                            current: cardTransactionsTable.data.page,
                            pageSize: cardTransactionsTable.data.limit,
                            hideOnSinglePage: false,
                            pageSizeOptions: ["5", "10", "20", "50", "100"],
                            total: cardTransactionsTable.data.totalDocs,
                        }}
                        loading={{
                            spinning: cardTransactionsTable.isFetching,
                            indicator: <LoadingOutlined style={{ fontSize: 30, color: "black" }} spin />,
                        }}
                        locale={{
                            emptyText: cardTransactionsTable.isFetching ? <Skeleton active={true} /> : <Empty />,
                        }}
                        onRow={(transaction) => ({
                            onClick: () => {
                                dispatch(setTransaction(transaction))
                                if (AuthzService.isRightGrantedForLoggedInUser(RightEnum.TRANSACTION__ALL__READ)) history.push(`/transactions/${transaction.id}`)
                            },
                        })}
                    />
                )}
            </div>
            <CardActivationModal isVisible={showCardActivationModal} onHide={() => setShowCardActivationModal(false)} card={card} onActivationSuccess={handleActivationSuccess} />
            <DisplayPinModal isVisible={showPinModal} card={card} onClose={() => setShowPinModal(false)} />
            <PhoneVerificationModal
                isShowing={showPhoneVerificationModal}
                phoneNumber={card.cardholderPhoneNumber}
                card={card}
                onSuccess={() => {
                    NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:cards.phone_number_updated.title"), t("notification:cards.phone_number_updated.message"))
                    setShowPhoneVerificationModal(false)
                }}
                onCancel={() => setShowPhoneVerificationModal(false)}
            />
        </div>
    )
}

export default CreditCardDetail
