import Icon from "@ant-design/icons"
import React from "react"

const selectSvg = () => (
    <svg width="12" height="9" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M0.470256 0.469744C0.729771 0.210229 1.15046 0.209999 1.41026 0.469231L4 3.05333L6.58974 0.469231C6.84954 0.209999 7.27023 0.210229 7.52974 0.469744C7.78946 0.729459 7.78946 1.15054 7.52974 1.41026L4.70711 4.23289C4.31658 4.62342 3.68342 4.62342 3.29289 4.23289L0.470256 1.41026C0.21054 1.15054 0.210541 0.729459 0.470256 0.469744Z"
            fill="white"
        />
    </svg>
)

const SelectIcon = (props: any) => <Icon component={selectSvg} {...props} />

export default SelectIcon
