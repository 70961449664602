import { Form, Input } from "antd"
import { FormInstance } from "antd/lib/form"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import PhoneInput from "react-phone-number-input"
import "react-phone-number-input/style.css"

import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

interface MobileInputFormInterface {
    formInstance: FormInstance
    preSetPhoneNumber: string | undefined
    showLabel?: boolean
    onSubmit: (number: string) => Promise<void>
    setErrorMessage: (msg: string) => void
}
const MobileInputForm = ({ formInstance, preSetPhoneNumber, showLabel = true, onSubmit, setErrorMessage }: MobileInputFormInterface) => {
    const { t } = useTranslation()

    const [phoneNumberState, setPhoneNumberState] = useStateIfMounted(preSetPhoneNumber)

    useEffect(() => {
        let unmount = false

        if (!unmount) {
            setPhoneNumberState(preSetPhoneNumber)
        }

        return () => {
            unmount = true
        }
    }, [preSetPhoneNumber])

    const handlePhoneNumberChange = (phoneNumberParam: string) => {
        setPhoneNumberState(phoneNumberParam)
        setErrorMessage("")
    }

    return (
        <Form form={formInstance} onFinish={() => onSubmit(phoneNumberState || "")}>
            <p className="mb-10 font-medium items-center primary-placeholder">{t("label:phone_verification.number_description")}</p>
            {showLabel && (
                <label htmlFor="phone-number" className="placeholder-gray-800">
                    {t("label:phone_verification.label")}
                </label>
            )}
            <Input
                hidden
                className="hidden-input-phone-number"
                addonAfter={
                    <PhoneInput key="workPhone" placeholder={t("placeholder:employee.work_phone")} value={phoneNumberState || ""} onChange={handlePhoneNumberChange}></PhoneInput>
                }
            />
        </Form>
    )
}

export default React.memo(MobileInputForm)
