import { ExclamationCircleOutlined } from "@ant-design/icons"
import { COLOR } from "@finway-group/shared/lib/consts"
import { Tooltip } from "antd"
import moment from "moment"
import React, { RefObject, useRef } from "react"
import { useTranslation } from "react-i18next"

import { getTooltipPopupContainer } from "Shared/utils/helper.utils"

interface DeletedSelectOptionInterface {
    translationKey: string
    deletionDate?: Date
    className?: string

    /**
     * Wether to anchor the tooltip popup to the document body or not.
     * Pass true when outside of modals, because default is to anchor it to grandfather node, and that could cause visibiity issues outside of modals. (Clipped tooltips)
     *  */
    anchorToDocumentBody?: boolean
}
const DeletedTooltip: React.FC<DeletedSelectOptionInterface> = ({ translationKey, deletionDate, className, anchorToDocumentBody = false }) => {
    const { t } = useTranslation()

    return (
        <Tooltip
            color={COLOR.finway.orange}
            getPopupContainer={anchorToDocumentBody ? () => document.body : (node) => getTooltipPopupContainer(node)}
            title={t(translationKey, { ...(deletionDate ? { date: moment(deletionDate).format("DD.MM.YYYY") } : {}) })}
        >
            <ExclamationCircleOutlined className={`${className ?? "ml-4"} inline-flex align-middle`} style={{ color: COLOR.finway.orange }} />
        </Tooltip>
    )
}

export default DeletedTooltip
