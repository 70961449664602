import { CalendarOutlined } from "@ant-design/icons"
import { Expense } from "@finway-group/shared/lib/models"
import { Tooltip } from "antd"
import moment from "moment"
import React from "react"
import { useTranslation } from "react-i18next"

import { UserService } from "Shared/services"
import { getExpenseReminderForUser } from "Shared/utils/expense.utils"

interface ExpenseReminderInfoInterface {
    request: Expense
}

const ExpenseReminderInfo: React.FC<ExpenseReminderInfoInterface> = ({ request }) => {
    const { t } = useTranslation()
    const loggedInProfile = UserService.getLoggedInEmployeeProfile()
    const reminder = getExpenseReminderForUser(request.reminders, loggedInProfile.id)

    if (!reminder) return <></>

    return (
        <Tooltip placement="bottom" overlayStyle={{ maxWidth: "100%" }} title={t("tooltips:expense_reminder", { date: moment(reminder.date).format("DD/MM/YYYY") })}>
            <div className="bg-transparent rounded-lg border-gray-300 border-1  ">
                <CalendarOutlined className="pb-5" />
            </div>
        </Tooltip>
    )
}

export default ExpenseReminderInfo
