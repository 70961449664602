import { Expense } from "@finway-group/shared/lib/models"
import { ExpenseExportInformation } from "@finway-group/shared/lib/models/expense/expenseExportInformation.interface"
import { DatevOnlineJobStatusEnum, isDuoJobPending } from "@finway-group/shared/lib/utils/datevOnlineJobStatus.enum"
import { Tag, Tooltip } from "antd"
import { TFunction } from "i18next"
import React from "react"
import { useTranslation } from "react-i18next"

import { getPopupAnchor } from "Shared/utils/popup.utils"

interface ExportTagInterface {
    expense: Expense
    className?: string
}

const hasExpenseBeenExported = (exportInformation: ExpenseExportInformation) =>
    exportInformation.isExportedAsAll ||
    exportInformation.isExportedAsCSV ||
    exportInformation.isExportedAsLexwareCSV ||
    exportInformation.isExportedAsDatevZip ||
    exportInformation.isExportedAsDatevInvoiceCsv ||
    exportInformation.isExportedAsSepa ||
    exportInformation.isExportedAsNonSepa ||
    exportInformation.isExportedToDatevOnline ||
    exportInformation.isExportedAsInvoices

const getExportList = (exportInformation: ExpenseExportInformation, t: TFunction) => [
    ...(exportInformation.isExportedAsAll ? [t("info:export_information.all")] : []),
    ...(exportInformation.isExportedAsCSV ? ["CSV"] : []),
    ...(exportInformation.isExportedAsLexwareCSV ? ["Lexware CSV"] : []),
    ...(exportInformation.isExportedAsDatevZip ? ["DATEV XML"] : []),
    ...(exportInformation.isExportedAsDatevInvoiceCsv ? [t("info:export_information.datev_invoice_csv")] : []),
    ...(exportInformation.isExportedAsSepa ? ["SEPA"] : []),
    ...(exportInformation.isExportedAsNonSepa ? ["NON-SEPA"] : []),
    ...(exportInformation.isExportedToDatevOnline ? ["DUO"] : []),
    ...(exportInformation.isExportedAsInvoices ? [t("info:export_information.invoices")] : []),
]

const EXPORT_TAG_COMBINED_LENGTH = 1

const ExportTag = ({ expense, className = "" }: ExportTagInterface) => {
    const { t } = useTranslation()

    const isExported = hasExpenseBeenExported(expense?.exportInformation)

    const getSortedExportList = () =>
        getExportList(expense?.exportInformation, t).sort((first, second) => {
            if (first.includes("DATEV") && second === "CSV") return -1
            if (first === "DATEV XML" && second === "DUO") return 1
            if (first.includes("DATEV") && !second.includes("DATEV")) return -1
            if (first === "CSV" && !(second === "CSV")) return -1
            return 0
        })

    const sortedExportList = getSortedExportList()

    const createTagLabel = () => {
        const { length } = sortedExportList
        return length > EXPORT_TAG_COMBINED_LENGTH ? `${sortedExportList[0]} (+${length - EXPORT_TAG_COMBINED_LENGTH})` : sortedExportList.join(", ")
    }

    const isDatevOnlineExporting = expense?.datevOnlineInformation?.status && isDuoJobPending(expense?.datevOnlineInformation?.status as DatevOnlineJobStatusEnum)
    const isDatevOnlineFailed = expense?.datevOnlineInformation?.status && expense?.datevOnlineInformation?.status === DatevOnlineJobStatusEnum.ERROR

    return (
        <>
            {isDatevOnlineExporting ? (
                <Tag className={`ant-tag-green ${className}`}>{t("label:being_exported_to_duo")}</Tag>
            ) : isDatevOnlineFailed ? (
                <Tag className={`ant-tag-red ${className}`}>{t("label:export_to_duo_failed")}</Tag>
            ) : (
                <></>
            )}
            {!isExported ? (
                <></>
            ) : (
                <>
                    {sortedExportList.length > EXPORT_TAG_COMBINED_LENGTH ? (
                        <Tooltip
                            getPopupContainer={getPopupAnchor(undefined, true)}
                            placement="right"
                            title={
                                <div>
                                    {t("info:exported_as")}: {sortedExportList.join(", ")}
                                </div>
                            }
                        >
                            <Tag className={`ant-tag-green ${className}`}>{createTagLabel()}</Tag>
                        </Tooltip>
                    ) : (
                        <Tag className={`ant-tag-green ${className}`}>{createTagLabel()}</Tag>
                    )}
                </>
            )}
        </>
    )
}

export default ExportTag
