import { SsoProviderType, SsoProviderTypeEnum } from "@finway-group/shared/lib/models"
import { Switch, Tag } from "antd"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useDebouncedCallback } from "use-debounce/lib"

import { useToggleSsoConfiguration } from "Shared/queries/sso.queries"
import { NotificationService } from "Shared/services"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

import SsoProviderModal from "./modals/ssoProvider.modal"

type SsoProviderContainerType = {
    icon: JSX.Element
    type: SsoProviderTypeEnum
    title: string
    description: string
    isAvailable: boolean
    connection?: SsoProviderType
}

const SsoProviderContainer = ({ icon, type, title, description, isAvailable, connection }: SsoProviderContainerType) => {
    const { t } = useTranslation()

    const [isLoading, setIsLoading] = useStateIfMounted(false)
    const [isConfigShown, setIsConfigShown] = useState(false)

    const toggleSsoConnection = useToggleSsoConfiguration()

    const toggleProvider = (enable: boolean) => {
        if (!connection) return

        setIsLoading(true)

        toggleSsoConnection.mutate(
            {
                type: connection.type,
                enable,
            },
            {
                onError: (error) => {
                    NotificationService.showErrorNotificationBasedOnResponseError(error, t(`error:error`))
                },
                onSettled: () => setIsLoading(false),
            },
        )
    }

    const delayedSsoProviderToggle = useDebouncedCallback((enable: boolean) => {
        toggleProvider(enable)
    }, 100)

    return (
        <div
            key={type}
            className={`flex items-center justify-between border pr-14 rounded ${isAvailable ? "cursor-pointer" : "cursor-not-allowed opacity-50"} provider-list ${
                connection?.isEnabled ? "active" : ""
            }`}
        >
            <div className="flex gap-10 items-center w-full px-14 py-10" onClick={() => isAvailable && setIsConfigShown(true)}>
                {icon}

                <span className="shadow-none grow">
                    <div className="flex gap-10 items-center">
                        <h3 className="mb-0">{title}</h3>
                        {!isAvailable && (
                            <Tag className="cursor-not-allowed" color="green">
                                {t("label:coming_soon")}
                            </Tag>
                        )}
                    </div>
                    <span className="text text-xs">{description}</span>
                </span>
            </div>

            {connection && <Switch checked={connection?.isEnabled} onChange={(checked) => delayedSsoProviderToggle.callback(checked)} disabled={!isAvailable || isLoading} />}

            <SsoProviderModal isShowing={isConfigShown} onCancel={() => setIsConfigShown(false)} type={type} connection={connection} icon={icon} />
        </div>
    )
}
export default React.memo(SsoProviderContainer)
