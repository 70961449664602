import { FormOutlined } from "@ant-design/icons"
import { SystemRightEnum } from "@finway-group/shared/lib/models"
import { Button } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"

import SsoProviderListModal from "Components/modals/ssoProviderList.modal"
import { AuthzService } from "Shared/services"

import { useModal } from "../../../shared/context/modal.context"

const SSOSettings = () => {
    const { t } = useTranslation()
    const { showModal } = useModal()

    return (
        <div className="mt-10">
            <ul className="description-list">
                {AuthzService.isSystemRightGrantedForLoggedInUser(SystemRightEnum.SYS__IDP__ALL__MANAGE) && (
                    <li>
                        <div className="flex items-center gap-5">
                            <span className="title">{t("label:sso.title")}</span>
                        </div>

                        <Button onClick={() => showModal(SsoProviderListModal, true, { isShowing: true, isNew: true })}>
                            <FormOutlined />
                        </Button>
                    </li>
                )}
            </ul>
        </div>
    )
}

export default SSOSettings
