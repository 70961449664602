import { Employee, UserApprover } from "@finway-group/shared/lib/models"
import { ActionCreator, Dispatch } from "redux"

import { EmployeeService, UserService } from "Shared/services"
import { ThunkResult } from "Shared/store"
import { TablesEnum } from "Shared/store/reducers/tableConfigReducer"

import { CancelFetchAction, FetcherActionTypes } from "../fetcher/fetcherTypes"
import { refetchTable, refetchUserRelatedTables } from "../tables/tableActions"
import { EmployeeActionTypes, FetchAllEmployeesAction, FetchOneEmployeeAction, NewUserAction, SyncEmployeeAction, UpdateEmployeeAction, UpdateUserAction } from "./employeeTypes"

export const fetchAllEmployees: ActionCreator<ThunkResult<Array<Employee>>> = () => async (dispatch: Dispatch) => {
    const employees = await EmployeeService.fetchAllEmployees()
    const cancelFetchAction: CancelFetchAction = {
        type: FetcherActionTypes.CANCEL_FETCH,
        isFetchCancelled: true,
    }

    dispatch(cancelFetchAction)

    const fetchAllEmployeesAction: FetchAllEmployeesAction = {
        type: EmployeeActionTypes.FETCH_ALL_EMPLOYEES,
        employees,
    }

    dispatch(fetchAllEmployeesAction)

    return employees
}

export const fetchOneEmployee = (id: string) => async (dispatch: Dispatch) => {
    const employee = await EmployeeService.fetchEmployeeById(id)
    if (!employee) {
        throw Error("Employee not found")
    }

    const fetchOneEmployee: FetchOneEmployeeAction = {
        type: EmployeeActionTypes.FETCH_ONE_EMPLOYEE,
        employee,
    }

    dispatch(fetchOneEmployee)

    return employee
}

export const syncEmployee = (id: string) => async (dispatch: Dispatch) => {
    const employee = await EmployeeService.fetchEmployeeById(id)

    if (employee) {
        const syncEmployeeAction: SyncEmployeeAction = {
            type: EmployeeActionTypes.SYNC_EMPLOYEE,
            employee,
        }

        dispatch(syncEmployeeAction)
        dispatch(refetchTable(TablesEnum.EMPLOYEES))
    }

    return employee
}

export const syncEmployeeWithEmployeeInfo = (employee: Employee) => async (dispatch: Dispatch) => {
    const syncEmployeeAction: SyncEmployeeAction = {
        type: EmployeeActionTypes.SYNC_EMPLOYEE,
        employee,
    }

    dispatch(syncEmployeeAction)
    dispatch(refetchTable(TablesEnum.EMPLOYEES))

    return employee
}

export const setEmployee: ActionCreator<ThunkResult<void>> = (employee: Employee) => async (dispatch: Dispatch) => {
    const fetchOneEmployee: FetchOneEmployeeAction = {
        type: EmployeeActionTypes.FETCH_ONE_EMPLOYEE,
        employee,
    }

    dispatch(fetchOneEmployee)
}

export const createUser: ActionCreator<ThunkResult<Employee>> = (createEmployee: Employee) => async (dispatch: Dispatch) => {
    const employee = await EmployeeService.createEmployee(createEmployee)
    const newEmployeeAction: NewUserAction = {
        type: EmployeeActionTypes.NEW_USER,
        employee,
    }

    dispatch(newEmployeeAction)
    dispatch(refetchUserRelatedTables())

    return employee
}

export const updateUser = (id: string, updateEmployee: Employee) => async (dispatch: Dispatch) => {
    const employee = await UserService.update(id, updateEmployee)
    const updateUserAction: UpdateUserAction = {
        type: EmployeeActionTypes.UPDATE_USER,
        employee,
    }

    dispatch(updateUserAction)
    dispatch(refetchUserRelatedTables())

    return employee
}

export const updateEmployee = (id: string, updateEmployee: Employee) => async (dispatch: Dispatch) => {
    const employee = await EmployeeService.updateEmployee(id, updateEmployee)
    const updateEmployeeAction: UpdateEmployeeAction = {
        type: EmployeeActionTypes.UPDATE_EMPLOYEE,
        employee,
    }

    dispatch(updateEmployeeAction)
    dispatch(refetchUserRelatedTables())

    return employee
}

export const updateEmployeeSettings =
    (id: string, { settings }: Employee) =>
    async (dispatch: Dispatch) => {
        const employee = await EmployeeService.updateEmployeeSettings(id, settings)
        const updateSettingsAction: UpdateEmployeeAction = {
            type: EmployeeActionTypes.UPDATE_EMPLOYEE,
            employee,
        }

        dispatch(updateSettingsAction)

        return employee
    }

export const updateEmployeeImg = (id: string, imgUrl: string) => async (dispatch: Dispatch) => {
    const employee = await EmployeeService.updateEmployeeImg(id, imgUrl)
    const updateImgAction: UpdateEmployeeAction = {
        type: EmployeeActionTypes.UPDATE_EMPLOYEE,
        employee,
    }

    dispatch(updateImgAction)
    dispatch(refetchUserRelatedTables())

    return employee
}

export const updateEmployeeAbsence = (id: string, absence: any) => async (dispatch: Dispatch) => {
    const employee = await EmployeeService.updateAbsence(id, absence)
    const updateAbsenceAction: UpdateEmployeeAction = {
        type: EmployeeActionTypes.UPDATE_EMPLOYEE,
        employee,
    }

    dispatch(updateAbsenceAction)

    return employee
}

export const updateEmployeeRule = (id: string, rule: any) => async (dispatch: Dispatch) => {
    const employee = await EmployeeService.updateEmployeeRule(id, rule)
    const updateRuleAction: UpdateEmployeeAction = {
        type: EmployeeActionTypes.UPDATE_EMPLOYEE,
        employee,
    }
    dispatch(updateRuleAction)
    dispatch(refetchUserRelatedTables())
    return employee
}

export const updateEmployeeApprovals = (id: string, approvals: Array<UserApprover>, companyId: string) => async (dispatch: Dispatch) => {
    const employee = await EmployeeService.updateEmployeeApprovals(id, approvals, companyId)
    const updateRuleAction: UpdateEmployeeAction = {
        type: EmployeeActionTypes.UPDATE_EMPLOYEE,
        employee,
    }
    dispatch(updateRuleAction)
    dispatch(refetchUserRelatedTables())
    return employee
}
