import { DatevCsvConfig } from "@finway-group/shared/lib/models"
import { Form, Modal } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import DatevCsvConfigForm from "Components/forms/datev/datevCsvConfig.form"
import { useCompany } from "Shared/hooks/company.hooks"
import { CompanyService, NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { setCompany } from "Shared/store/actions/company/companyActions"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

import { ModalType } from "../modal.types"

export const DatevCsvConfigModal = ({ isShowing, onCancel }: ModalType) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const company = useCompany()
    const [formInstance] = Form.useForm<DatevCsvConfig>()

    const [isLoading, setIsLoading] = useStateIfMounted(false)

    const onSubmit = async (formValues: DatevCsvConfig) => {
        setIsLoading(true)
        try {
            const updatedCompany = await CompanyService.updateDatevCsvInfo(company?._id, formValues)
            dispatch(setCompany(updatedCompany))

            NotificationService.send(
                NotificationTypeEnum.SUCCESS,
                t("notification:settings.company.datev_csv_config_info.title"),
                t("notification:settings.company.datev_csv_config_info.message"),
            )
            onHide()
        } catch (error) {
            NotificationService.showErrorNotificationBasedOnResponseError(error, t("error:error"))
        } finally {
            setIsLoading(false)
        }
    }

    const onHide = () => {
        setIsLoading(false)
        formInstance.resetFields()
        onCancel()
    }

    return (
        <Modal
            title={t("label:datev_csv_config.title")}
            visible={isShowing}
            maskClosable={false}
            onOk={formInstance.submit}
            onCancel={onHide}
            okText={t("action:company_settings.save")}
            cancelText={t("action:cancel")}
            confirmLoading={isLoading}
            keyboard
        >
            <DatevCsvConfigForm formInstance={formInstance} onSubmit={onSubmit} />
        </Modal>
    )
}
