import React from "react"
import { Helmet } from "react-helmet"
import { useTranslation } from "react-i18next"

interface PageTitleProps {
    titleKey: string
}

const PageTitle: React.FC<PageTitleProps> = ({ children, titleKey }) => {
    const { t } = useTranslation()

    const titleText = t(titleKey)

    return (
        <>
            <Helmet>
                <title>{titleText}</title>
            </Helmet>
            <div className="flex flex-row flex-wrap justify-between mb-16 space-x-10">
                <h1>{titleText}</h1>
                <div className="flex flex-row space-x-10 min-h-36">{children}</div>
            </div>
        </>
    )
}

export default PageTitle
