import Icon from "@ant-design/icons"
import React from "react"

const removeCard = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
            <path
                d="M14.2632 8.33009V2.94737C14.2632 2.42415 13.839 2 13.3158 2H1.94737C1.42415 2 1 2.42415 1 2.94737V10.5263C1 11.0495 1.42415 11.4737 1.94737 11.4737H9.27417"
                stroke="#606C7E"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path d="M14.2632 3.89453H1V5.78927H14.2632V3.89453Z" stroke="#606C7E" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path
                d="M12.3688 13.9994C14.1129 13.9994 15.5267 12.5855 15.5267 10.8415C15.5267 9.09743 14.1129 7.68359 12.3688 7.68359C10.6248 7.68359 9.21094 9.09743 9.21094 10.8415C9.21094 12.5855 10.6248 13.9994 12.3688 13.9994Z"
                stroke="#606C7E"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path d="M11.1055 10.8418H13.6318" stroke="#606C7E" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        </g>
        <defs>
            <clipPath id="clip0">
                <rect width="16" height="16" fill="white" />
            </clipPath>
        </defs>
    </svg>
)

const RemoveCardIcon = (props: any) => <Icon component={removeCard} {...props} />

export default RemoveCardIcon
