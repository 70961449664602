import { Col, Row } from "antd"
import Form from "antd/lib/form"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { NumericFormat } from "react-number-format"

import { AntdFormInterface } from "Components/form/form.types"
import { DatevClientAndConsultantFormDataType } from "Components/modals/datev/clientAndConsultant.modal"
import { useCompany } from "Shared/hooks/company.hooks"

import DatevClientAndConsultantRules from "../rules/datevClientAndConsultant.rules"

const DatevClientAndConsultantForm = ({ formInstance, onSubmit, isFormDisabled }: AntdFormInterface<DatevClientAndConsultantFormDataType>) => {
    const { t } = useTranslation()
    const company = useCompany()
    const rules = DatevClientAndConsultantRules()

    useEffect(() => {
        formInstance.resetFields()
    }, [company._id])

    return (
        <>
            <p>{t("label:datev_client_and_consultant.info")}</p>

            <Form
                form={formInstance}
                layout="vertical"
                style={{ marginTop: "25px" }}
                onFinish={onSubmit}
                autoComplete="off"
                initialValues={{
                    clientNumber: company.datevSettings?.datevContext?.clientNumber,
                    consultantNumber: company.datevSettings?.datevContext?.consultantNumber,
                }}
            >
                <Row gutter={[16, 16]}>
                    <Col span={12} sm={24} md={12}>
                        <Form.Item label={t("input:datev_client_and_consultant.client_number")} name="clientNumber" rules={rules.clientNumber}>
                            <NumericFormat
                                disabled={isFormDisabled}
                                allowLeadingZeros={false}
                                allowNegative={false}
                                decimalScale={0}
                                className="ant-input-number-input"
                                placeholder={t("placeholder:datev_client_and_consultant_number.client_number")}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12} sm={24} md={12}>
                        <Form.Item label={t("input:datev_client_and_consultant.consultant_number")} name="consultantNumber" rules={rules.consultantNumber}>
                            <NumericFormat
                                disabled={isFormDisabled}
                                allowLeadingZeros={false}
                                allowNegative={false}
                                decimalScale={0}
                                className="ant-input-number-input"
                                placeholder={t("placeholder:datev_client_and_consultant_number.consultant_number")}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default DatevClientAndConsultantForm
