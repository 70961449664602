import { KYBStatusEnum } from "@finway-group/shared/lib/models"
import { Alert, Button } from "antd"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import EnrollCardModal from "Components/modals/enrollCard.modal"
import WeavrAuthModal from "Components/modals/weavrAuth.modal"
import AxiosInterceptor from "Shared/config/axios.interceptor"
import { useModal } from "Shared/context/modal.context"
import { useWeavrAuth } from "Shared/hooks/auth.hooks"
import { useCompany } from "Shared/hooks/company.hooks"
import { useKYBData } from "Shared/hooks/corporate.hooks"
import { useLoggedInEmployeeProfile } from "Shared/hooks/user.hooks"
import { AuthnService } from "Shared/services"
import { setIsWeavrUserOnboard } from "Shared/store/actions/auth/authActions"
import { WeavrUserOnboardEnum } from "Shared/store/reducers/authReducer"
import { checkShouldOnboardUser } from "Shared/utils/helper.utils"

const WeavrOnBoardingBanner = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const companySettings = useCompany()
    const { showModal } = useModal()
    const kyb = useKYBData()
    const { companyId } = AuthnService.getDataFromAccessToken()

    const user = useLoggedInEmployeeProfile()
    const { isWeavrUserOnBoard, shouldShowWeavrAuthModal } = useWeavrAuth()
    const shouldOnboardUser = checkShouldOnboardUser({ user, kybData: kyb, companyId })

    const showOnBoardingModal = () =>
        showModal(EnrollCardModal, true, {
            isShowing: true,
            onClosed: onCloseModal,
        })

    useEffect(() => {
        if (!user) return

        if (isWeavrUserOnBoard === WeavrUserOnboardEnum.NOT_ONBOARD && shouldOnboardUser) {
            showModal(EnrollCardModal, true, {
                isShowing: true,
                onClosed: onCloseModal,
            })
        }
    }, [isWeavrUserOnBoard])

    const onCloseModal = async () => {
        await dispatch(setIsWeavrUserOnboard(WeavrUserOnboardEnum.CANCELLED))
        AxiosInterceptor.cancelWeavrRequests()
    }

    useEffect(() => {
        const handleUserOnboardStatus = async () => dispatch(setIsWeavrUserOnboard(WeavrUserOnboardEnum.ONBOARD))

        companySettings?.cardsEnabled &&
            kyb.kybStatus === KYBStatusEnum.COMPLETED &&
            user.activeCompanyProfile.weavrData?.userId && // to check if user is enrolled
            !shouldOnboardUser &&
            handleUserOnboardStatus()
    }, [])

    const AlertContent = !user.activeCompanyProfile.weavrData?.isPasswordCreated ? (
        <>
            <span>{t("info:cards.finish_onboarding_part_one")}</span>
            <Button className="button-warning-notification" type="text" onClick={showOnBoardingModal}>
                {t("action:cards.finish_onboarding")}
            </Button>
            <span>{t("info:cards.finish_onboarding_part_two")}</span>
        </>
    ) : (
        <>
            <span>{t("info:cards.finish_onboarding_mobile")}</span>
            <Button className="button-warning-notification" type="text" onClick={showOnBoardingModal}>
                {t("action:cards.finish_onboarding_mobile")}
            </Button>
        </>
    )

    return (
        <>
            {shouldShowWeavrAuthModal && <WeavrAuthModal isShowing={shouldShowWeavrAuthModal} onClosed={onCloseModal} />}
            {shouldOnboardUser && <Alert message={t("creditCard:card_onboarding.title")} description={AlertContent} type="warning" showIcon />}
        </>
    )
}

export default React.memo(WeavrOnBoardingBanner)
