import { ExpenseKindEnum, ExpenseStatusEnum, RightEnum, SubscriptionTypeEnum } from "@finway-group/shared/lib/models"
import { Card } from "antd"
import moment from "moment"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import InfiniteScroll from "react-infinite-scroller"
import { useDispatch } from "react-redux"

import ExportDropDownMenu from "Components/exportDropDownMenu"
import RequestPreview from "Components/requestPreview"
import { ExpenseExport } from "Features/export/expenseExport"
import { useExpenses, useShouldRefetchExpenses } from "Shared/hooks/expense.hooks"
import { AuthzService, NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { fetchAllExpenses } from "Shared/store/actions/expense/expenseActions"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

import Loading from "./loading"

interface GroupedExpensesInterface {
    groupedByField: string
    groupedById: string | Array<string>
    showPending?: boolean
    actionButton?: any
    startTime?: moment.Moment
    endTime?: moment.Moment
}

const GroupedExpenses: React.FC<GroupedExpensesInterface> = ({ groupedByField, groupedById, actionButton, showPending = false, startTime, endTime }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const [currentPage, setCurrentPage] = useStateIfMounted(1)
    const [loading, setLoading] = useStateIfMounted(false)
    const { expenses, totalPages, totalDocs } = useExpenses(true)
    const shouldRefetch = useShouldRefetchExpenses()
    const loadMore = expenses.length !== totalDocs
    const timeQuery = `${startTime ? `&datePurchased[gte]=${startTime.format("yyyy-MM-DD HH:mm:ss.SSS")}` : ""}${
        endTime ? `&datePurchased[lte]=${endTime.format("yyyy-MM-DD HH:mm:ss.SSS")}` : ""
    }`
    // prettier-ignore
    const costCenterSharedUsersFilter = groupedByField.includes("costCenter") ? "&costCenterSharedUsers[is]=true" : "";
    const folderFilter = `kind[ne]=${ExpenseKindEnum.TRIP_FOLDER}`
    const filterString = `&${groupedByField}[in]=${groupedById}${
        !showPending ? `&status[in]=${ExpenseStatusEnum.PAID},${ExpenseStatusEnum.DONE}` : ""
    }&deleted[is]=false&subscriptionType[ne]=${SubscriptionTypeEnum.INITIAL_REQUEST}${timeQuery}${costCenterSharedUsersFilter}&${folderFilter}`
    const hasExportRight = AuthzService.isRightGrantedForLoggedInUser(RightEnum.EXPENSE__ALL__EXPORT)

    const fetchExpenses = async (page: number, append = false) => {
        try {
            setLoading(true)
            await dispatch(fetchAllExpenses(filterString, "&sortBy=desc(datePurchased)", page, 20, append))
            setCurrentPage(page)
        } catch (err) {
            NotificationService.send(NotificationTypeEnum.ERROR, t("error:request.fetch.title"), t("error:request.fetch.title"))
        }
        setLoading(false)
    }

    const onLoadMorePages = () => {
        if (currentPage !== totalPages) {
            fetchExpenses(currentPage + 1, true)
        }
    }

    useEffect(() => {
        let isFetching = true

        if (isFetching) {
            fetchExpenses(1)
        }

        return () => {
            isFetching = false
        }
    }, [groupedById])

    useEffect(() => {
        let isFetching = true

        if (shouldRefetch && isFetching) {
            fetchExpenses(1)
        }

        return () => {
            isFetching = false
        }
    }, [shouldRefetch])

    return loading ? (
        <Loading />
    ) : (
        <div>
            <div className="flex justify-between items-center mb-8">
                <h2 className="mb-0">
                    {t("label:requests")} ({totalDocs})
                </h2>
                <div className="flex items-center">
                    {actionButton && actionButton}
                    <ExpenseExport
                        skipStatusUpdate
                        render={({ onExport, isExporting }) => (
                            <ExportDropDownMenu
                                onExport={onExport}
                                expensesQuery={filterString}
                                isLoading={isExporting}
                                buttonClassName={"m-5"}
                                hideDatevOnline
                                disabled={expenses.length === 0}
                                onlyFileExport={!hasExportRight}
                                showAllFormat
                            />
                        )}
                    />
                </div>
            </div>
            {expenses.length > 0 ? (
                <ul className="card-list" id="grouped-expenses-list">
                    <InfiniteScroll loadMore={onLoadMorePages} hasMore={!loading && loadMore}>
                        {expenses.map((request, index) => (
                            <RequestPreview key={index} request={request} costCenterViewIds={groupedByField.includes("costCenter") ? groupedById : undefined} hideActionButtons />
                        ))}
                    </InfiniteScroll>
                </ul>
            ) : (
                <Card className="flex items-center h-100">
                    <p>{t("missing:expense_data")}</p>
                </Card>
            )}
        </div>
    )
}

export default GroupedExpenses
