import { isAtMostNChar } from "@finway-group/shared/lib/utils/common"
import { MAX_COST_CENTER_ID_LENGTH, textMultipleWhiteSpace, textNoWhiteSpacesRegex } from "@finway-group/shared/lib/utils/validators"
import { Rule } from "antd/lib/form"
import i18n from "i18next"

import { parseCurrencyInput } from "Components/currencyInput/config"

import { allowlistCharValidator } from "./utils.rules"

export const costCenterRules = (): { [key: string]: Array<Rule> } => ({
    id: [
        {
            validator: (_rule, value) => {
                if (!value || value === "") {
                    return Promise.reject(new Error(i18n.t("validation:required")))
                }

                if (!textNoWhiteSpacesRegex.test(value)) {
                    return Promise.reject(new Error(i18n.t("validation:string_white_space")))
                }

                if (!isAtMostNChar(value, MAX_COST_CENTER_ID_LENGTH)) {
                    return Promise.reject(i18n.t("validation:max.string", { max: MAX_COST_CENTER_ID_LENGTH }))
                }

                return Promise.resolve()
            },
        },
        allowlistCharValidator,
    ],
    name: [
        {
            validator: (_rule, value) => {
                if (!value || value === "") {
                    return Promise.reject(new Error(i18n.t("validation:required")))
                }

                if (value !== value.trim()) {
                    return Promise.reject(new Error(i18n.t("validation:string_white_space_start_or_end")))
                }

                if (textMultipleWhiteSpace.test(value)) {
                    return Promise.reject(new Error(i18n.t("validation:string_white_space_duplicate")))
                }

                return Promise.resolve()
            },
        },
        allowlistCharValidator,
    ],
    responsibleUser: [{ required: true, message: i18n.t("validation:required") }],
    budgetValue: [
        {
            validator: (_rule: any, inputValue: string) => {
                const value = parseCurrencyInput(inputValue)
                if (!value) return Promise.resolve()
                if (value < 0) return Promise.reject(new Error(i18n.t("validation:must_be_positive")))

                return Promise.resolve()
            },
        },
    ],
    periodName: [{ required: true, message: i18n.t("validation:alternative_budget_period") }],
})
