import { CardStatusEnum } from "@finway-group/shared/lib/models"
import { Tag } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"

interface StatusTagInterface {
    status: CardStatusEnum
    hasError: boolean
}

const CardStatusTag: React.FC<StatusTagInterface> = ({ status, hasError = false }) => {
    const { t } = useTranslation()
    let tagClass
    let label

    if (hasError && status !== CardStatusEnum.TERMINATED) {
        tagClass = "ant-tag-red"
        label = t("label:cards.tag.error")
    } else {
        switch (status) {
            case CardStatusEnum.UNACTIVATED:
                tagClass = "ant-tag-yellow"
                label = t("label:cards.tag.ordered")
                break
            case CardStatusEnum.ACTIVE:
                tagClass = "ant-tag-green"
                label = t("label:cards.tag.active")
                break
            case CardStatusEnum.SUSPENDED:
                tagClass = "ant-tag-red"
                label = t("label:cards.tag.suspended")
                break
            case CardStatusEnum.TERMINATED:
                label = t("label:cards.tag.terminated")
                tagClass = "ant-tag-red"
                break
            case CardStatusEnum.REPORTED_STOLEN:
                label = t("label:cards.tag.reported_stolen")
                tagClass = "ant-tag-red"
                break
            case CardStatusEnum.REPORTED_LOST:
                label = t("label:cards.tag.reported_lost")
                tagClass = "ant-tag-red"
                break
        }
    }

    return <Tag className={`${tagClass}`}>{label}</Tag>
}

export default CardStatusTag
