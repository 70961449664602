import { ApprovalProcess, Employee, Expense, RightEnum, Role, User } from "@finway-group/shared/lib/models"
import { ExpensePaymentOptionEnum } from "@finway-group/shared/lib/models/expense/expensePaymentOption.enum"
import moment from "moment-timezone"

import { FINWAY_ADMIN_EMAIL } from "Shared/config/consts"
import { getRemainingApprovers } from "Shared/utils/approvalProcess.utils"
import { isRightGranted } from "Shared/utils/helper.utils"

export const getEmployeesByIdsInAbsenceOfLeave = (employeeIds: Array<string>, employees: Array<Employee>) =>
    employees.filter((employee: Employee) => {
        if (!employeeIds.includes(employee.id)) return false
        return employee.isInAbsenceOfLeave()
    })

export const getAbsentApprovers = (approvalProcess: ApprovalProcess, employees: Array<Employee>) => {
    const remainingApproversIds = getRemainingApprovers(approvalProcess).map((approvalProcess) => approvalProcess.approver?._id)
    const absentApprovers = getEmployeesByIdsInAbsenceOfLeave(remainingApproversIds, employees)
    return absentApprovers
}

/* Admin is considered absent if it is currently or in the next in absence of leave */
export const isAdminAbsent = (startDate: Date, endDate: Date) =>
    moment().isBetween(startDate, endDate, "day", "[]") || moment(startDate).isBetween(moment(), moment().add(1, "week"), "day", "[]")

export const getAvailableReplacementsForAbsentApprover = (expense: Expense, employees: Array<Employee>, rolesMap: Map<string, Role>) => {
    const availableReplacementsForAbsentApprover = employees.filter((employee) => {
        const { activeCompanyProfile, id, email, absence } = employee
        if (email === FINWAY_ADMIN_EMAIL || id === expense.requestedBy._id || !isRightGranted(rolesMap.get(activeCompanyProfile.roleId)?.rights, RightEnum.EXPENSE__ALL__APPROVE))
            return false
        if (expense?.paymentOption === ExpensePaymentOptionEnum.SMART_CARD) {
            // TODO: use employee class method isEnrolledInCardProgramOfCompany when 6.3 shared is promoted to master.
            const isNotEnrolledInCompanyCardProgram = !activeCompanyProfile.weavrData?.userId
            if (isNotEnrolledInCompanyCardProgram) return false
        }

        return !absence || !isAdminAbsent(absence.startDate, absence.endDate)
    })

    return availableReplacementsForAbsentApprover
}

export const formatEmployeeAbsenceDate = (employee: Employee) =>
    `${moment(employee.absence?.startDate).format("Do MMM, YYYY")} - ${moment(employee.absence?.endDate).format("Do MMM, YYYY")}`

export const isSelfAdministrationOfRoleAllowed = (user: Employee, roleId: string) => !user.hasSystemRights || !user.companyProfiles?.map((cp) => cp.roleId).includes(roleId)

export const isSysAdminRightsRevocationDenied = ({ user, sysAdminsCount, isDeletion = false }: { user: User; sysAdminsCount: number; isDeletion?: boolean }) => {
    const sysAdminCountBelowOrEqualLimit = sysAdminsCount <= 2
    const nonDeletedCompanyProfilesCount = user.companyProfiles?.filter((cp) => !cp.deleted).length ?? 0
    return sysAdminCountBelowOrEqualLimit && user.hasSystemRights && (isDeletion ? nonDeletedCompanyProfilesCount <= 1 : true)
}
