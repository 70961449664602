import { Alert, Button, Col, Form, Image, Input, Modal, Row, Select } from "antd"
import { FormInstance } from "antd/lib/form"
import { Store } from "antd/lib/form/interface"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import WalletService from "Shared/services/wallet.service"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

export const WeavrAuthenticationFactorBanner: React.FC = ({}) => {
    const { t } = useTranslation()
    const [isVerified, setIsVerified] = useStateIfMounted<boolean>(true)
    const [isVerificationModalShowing, setIsVerificationModalShowing] = useStateIfMounted<boolean>(false)
    const [shouldCheckAuthFactor, setShouldCheckAuthFactor] = useStateIfMounted<boolean>(true)
    const [isLoading, setIsLoading] = useStateIfMounted<boolean>(false)
    const [formInstance] = Form.useForm()

    useEffect(() => {
        if (shouldCheckAuthFactor) {
            WalletService.checkUserAuthenticationFactor()
                .then((response) => {
                    setIsVerified(response.available)
                })
                .finally(() => setShouldCheckAuthFactor(false))
        }
    }, [shouldCheckAuthFactor])

    const onVerificationCodeSubmit = (values: Store) => {
        setIsLoading(true)
        WalletService.verifyUserAuthenticationFactor(values.verificationCode)
            .then(() => {
                setShouldCheckAuthFactor(true)
                setIsVerificationModalShowing(false)
                NotificationService.send(
                    NotificationTypeEnum.SUCCESS,
                    t("notification:wallet.auth_factor_enroll_success.title"),
                    t("notification:wallet.auth_factor_enroll_success.message"),
                    10,
                )
            })
            .catch((err) => {
                NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:error"))
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const requestVerificationCode = () => {
        WalletService.enrollUserAuthenticationFactor()
            .then(() => {
                setIsVerificationModalShowing(true)
            })
            .catch((err) => {
                NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:error"))
            })
    }

    const message = (
        <>
            {t("info:wallet.auth_factor.auth_factor_needed")}
            <Button className="button-warning-notification ml-4" onClick={requestVerificationCode}>
                {t("action:wallet.auth_factor.resolve_now")}
            </Button>
        </>
    )

    return (
        <>
            {!isVerified && <Alert showIcon type="warning" message={message} />}
            <Modal
                destroyOnClose={true}
                visible={isVerificationModalShowing}
                maskClosable={false}
                title={t("action:wallet.withdrawal")}
                onCancel={() => setIsVerificationModalShowing(false)}
                closable={true}
                keyboard={true}
                className="ant-modal--small"
                footer={[
                    <Button key="back" onClick={() => setIsVerificationModalShowing(false)}>
                        {t("action:cancel")}
                    </Button>,
                    <Button
                        key="submit"
                        type="primary"
                        loading={isLoading}
                        onClick={() => {
                            formInstance.submit()
                        }}
                    >
                        {t("action:submit")}
                    </Button>,
                ]}
            >
                <Form form={formInstance} onFinish={onVerificationCodeSubmit}>
                    <p className="mb-24">{t("info:wallet.auth_factor.verification_code_sent")}</p>
                    <Form.Item label={t("input:wallet.verification_code")} name="verificationCode" key="verificationCode" required>
                        <Input placeholder={t("placeholder:wallet.verification_code")} />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default WeavrAuthenticationFactorBanner
