import * as Sentry from "@sentry/react"
import axios from "axios"
import * as HttpStatus from "http-status"
import { ActionCreator, Dispatch } from "redux"

import i18n from "Shared/locales/i18n"
import { NotificationService, TableService } from "Shared/services"
import FilterService from "Shared/services/filter.service"
import store, { ThunkResult } from "Shared/store"
import { fetchDashboardInboxInvoicesCount } from "Shared/store/actions/inboxInvoice/inboxInvoiceActions"
import { TablesEnum } from "Shared/store/reducers/tableConfigReducer"
import { TableData } from "Shared/store/reducers/tableReducer"
import { getFilterTargetPage } from "Shared/utils/filter.utils"
import { getFilterTableType, getTableType } from "Shared/utils/table.utils"

import { getTableDocsStore, getTableQueryParams, tableOptions } from "../../../hooks/table.hooks"
import { fetchArchivedExpensesCounts, fetchDashboardExpensesCounts } from "../expense/expenseActions"
import {
    FetchTableFailureAction,
    FetchTableSuccessAction,
    InitialFetchTablesAction,
    RefetchCardRelatedTablesAction,
    RefetchExpenseRelatedTablesAction,
    RefetchInboxInvoiceTablesAction,
    RefetchMultipleTablesAction,
    RefetchTransactionCategoryRelatedTables,
    RefetchUserRelatedTablesAction,
    ResetAllTablesAction,
    TableFetchingAction,
    UpdateTableFilterAction,
    UpdateTablePaginationAction,
    UpdateTableSearchAction,
    UpdateTableSortAction,
    resetExpenseArchiveTableAction,
} from "./tableTypes"
import {
    FetchTableFailureTypes,
    FetchTableSuccessTypes,
    RefetchTableTypes,
    TableActionsTypes,
    TableFetchingTypes,
    UpdateTableFilterType,
    UpdateTablePaginationType,
    UpdateTableSearchType,
    UpdateTableSortType,
} from "./tableTypes.enum"

/* 
ALL TABLES ACTIONS 
*/
export const initialFetchTables: ActionCreator<InitialFetchTablesAction> = () => ({
    type: TableActionsTypes.INITIAL_FETCH_TABLES,
    payload: {
        shouldFetch: true,
    },
})

export const resetAllTables: ActionCreator<ResetAllTablesAction> = () => ({
    type: TableActionsTypes.RESET_ALL_TABLES,
})

export const updateDashboardSearch: ActionCreator<ThunkResult<any>> = (searchString: string) => async (dispatch: Dispatch<any>) => {
    const updateDashboardSearch = {
        type: TableActionsTypes.UPDATE_DASHBOARD_TABLES_SEARCH,
        payload: {
            search: searchString,
            shouldFetch: true,
        },
    }
    dispatch(updateDashboardSearch)
    await dispatch(fetchDashboardExpensesCounts())
}

export const resetExpenseArchiveTable: ActionCreator<resetExpenseArchiveTableAction> = () => ({
    type: TableActionsTypes.RESET_TABLE_ARCHIVE,
})

/*
EXPENSE TABLES ACTIONS
*/
/**
 * Refetches all tables that show expense data.
 */
export const refetchExpenseTables: ActionCreator<RefetchExpenseRelatedTablesAction> = () => ({
    type: TableActionsTypes.REFETCH_EXPENSE_RELATED_TABLES,
    payload: {
        tables: [
            TablesEnum.TODO_APPROVAL_PENDING,
            TablesEnum.TODO_DOCS_NEEDED,
            TablesEnum.TODO_INVOICE_APPROVAL,
            TablesEnum.TODO_PURCHASE_APPROVAL,
            TablesEnum.TODO_INVOICE_AND_PURCHASE,
            TablesEnum.ALL_REQUESTS,
            TablesEnum.REVIEW_REQUESTS,
            TablesEnum.PAY_AND_EXPORT_TO_BE_PAID,
            TablesEnum.PAY_AND_EXPORT_TO_BE_EXPORTED,
            TablesEnum.ALL_REQUESTS,
            TablesEnum.DONE_REQUESTS,
            TablesEnum.IN_PROGRESS,
            TablesEnum.SUBSCRIPTIONS,
            TablesEnum.EMPLOYEES, // because of the expensesSoFar (expenseData) column
            TablesEnum.EMPLOYEE_VENDORS,
            TablesEnum.COST_CENTERS, // because of the expenses (budgetData) column
            TablesEnum.VENDORS, // because of the expenses (expenseData) column
            TablesEnum.INBOX_INVOICE_MODAL_EXPENSES,
            TablesEnum.ATTACH_EXPENSE,
            TablesEnum.TRIP_FOLDER_DRAFT,
        ],
        shouldFetch: true,
    },
})

/*
CARDS TABLES ACTIONS
*/
/**
 * Refetches all tables that show card data.
 */
export const refetchCardRelatedTables: ActionCreator<RefetchCardRelatedTablesAction> = () => ({
    type: TableActionsTypes.REFETCH_CARD_RELATED_TABLES,
    payload: {
        tables: [TablesEnum.CARDS, TablesEnum.SUBSCRIPTIONS],
        shouldFetch: true,
    },
})

/*
INBOX INVOICE TABLES ACTIONS
*/
export const refetchInboxInvoiceTables: ActionCreator<RefetchInboxInvoiceTablesAction> = () => ({
    type: TableActionsTypes.REFETCH_INBOX_INVOICE_TABLES,
    payload: {
        shouldFetch: true,
    },
})

/*
USER TABLES ACTIONS
*/
/**
 * Re-fetches all tables that show user data.
 */
export const refetchUserRelatedTables: ActionCreator<RefetchUserRelatedTablesAction> = () => ({
    type: TableActionsTypes.REFETCH_USER_RELATED_TABLES,
    payload: {
        tables: [
            TablesEnum.EMPLOYEES,
            TablesEnum.EMPLOYEE_VENDORS,
            TablesEnum.COST_CENTERS,
            TablesEnum.WORKFLOWS,
            TablesEnum.CARDS,
            TablesEnum.TODO_APPROVAL_PENDING,
            TablesEnum.TODO_DOCS_NEEDED,
            TablesEnum.TODO_INVOICE_APPROVAL,
            TablesEnum.TODO_PURCHASE_APPROVAL,
            TablesEnum.TODO_INVOICE_AND_PURCHASE,
            TablesEnum.INBOX_INVOICE,
        ],
        shouldFetch: true,
    },
})

/*
TRANSACTION CATEGORIES TABLES ACTIONS
*/
/**
 * Refetches all tables that depend on transaction category data.
 */
export const refetchTransactionCategoryRelatedTables: ActionCreator<RefetchTransactionCategoryRelatedTables> = () => ({
    type: TableActionsTypes.REFETCH_TRANSACTION_CATEGORY_RELATED_TABLES,
    payload: {
        tables: [
            TablesEnum.TRANSACTIONS_INFLOW,
            TablesEnum.TRANSACTIONS_OUTFLOW,
            TablesEnum.TRANSACTIONS_ALL,
            TablesEnum.TRANSACTION_CATEGORIES_INFLOW,
            TablesEnum.TRANSACTION_CATEGORIES_OUTFLOW,
        ],
        shouldFetch: true,
    },
})

/* 
SINGLE TABLE ACTIONS
*/
export const tableFetching: ActionCreator<TableFetchingAction> = (table: TablesEnum) => {
    const type = getTableType(TableFetchingTypes.TABLE_FETCHING, table) as TableFetchingTypes

    return {
        type: TableFetchingTypes[type],
        payload: {
            table,
            isFetching: true,
            shouldFetch: false,
        },
    }
}

export const fetchTableSuccess: ActionCreator<FetchTableSuccessAction> = <T>(table: TablesEnum, data: TableData<T>) => {
    const type = getTableType(FetchTableSuccessTypes.FETCH_TABLE_SUCCESS, table) as FetchTableSuccessTypes
    const fetchTableSuccess: FetchTableSuccessAction = {
        type: FetchTableSuccessTypes[type],
        payload: {
            table,
            data,
            isFetching: false,
            error: undefined,
            shouldFetch: false,
        },
    }
    return fetchTableSuccess
}

export const fetchTableFailure: ActionCreator<FetchTableFailureAction> = (table: TablesEnum, error: string) => {
    const type = getTableType(FetchTableFailureTypes.FETCH_TABLE_FAILURE, table) as FetchTableFailureTypes
    const fetchTableFailure: FetchTableFailureAction = {
        type: FetchTableFailureTypes[type],
        payload: {
            table,
            error,
            isFetching: false,
            shouldFetch: false,
        },
    }
    return fetchTableFailure
}

/**
 * Sets table "shouldFetch" flag as true so that the table is re-fetched next time it is rendered.
 */
export const refetchTable = (table: TablesEnum) => {
    const type = getTableType(RefetchTableTypes.REFETCH_TABLE, table) as RefetchTableTypes

    return {
        type,
        payload: {
            table,
            shouldFetch: true,
        },
    }
}

/**
 * Sets tables' "shouldFetch" flag as true so that the tables' is re-fetched next time it is rendered.
 */
export const refetchMultipleTables: ActionCreator<RefetchMultipleTablesAction> = (tables: Array<TablesEnum>) => ({
    type: TableActionsTypes.REFETCH_MULTIPLE_TABLES,
    payload: {
        tables,
        shouldFetch: true,
    },
})

export const updateTablePagination: ActionCreator<UpdateTablePaginationAction | undefined> = (table: TablesEnum, pagination: any, shouldFetch = true) => {
    const type = getTableType(UpdateTablePaginationType.UPDATE_TABLE_PAGINATION, table) as UpdateTablePaginationType
    const { page, limit } = store.getState().tables[table].data

    // if nothing changed, early return
    if (pagination.current === page && pagination.pageSize === limit) return

    return {
        type,
        payload: { table, page: pagination.current, limit: pagination.pageSize, shouldFetch },
    }
}

export const updateTableSort: ActionCreator<UpdateTableSortAction> = (table: TablesEnum, sorter: any, shouldFetch = true) => {
    const type = getTableType(UpdateTableSortType.UPDATE_TABLE_SORT, table) as UpdateTableSortType
    const sort = { order: sorter.order, field: sorter.columnKey }
    return {
        type,
        payload: {
            table,
            sort,
            shouldFetch,
        },
    }
}

export const updateTableSearch: ActionCreator<UpdateTableSearchAction> = (table: TablesEnum, searchString: string, shouldFetch = true) => {
    const type = getTableType(UpdateTableSearchType.UPDATE_TABLE_SEARCH, table) as UpdateTableSearchType
    return {
        type,
        payload: {
            table,
            search: searchString,
            shouldFetch,
        },
    }
}

/**
 * Updates the state of the filter BOTH in the backend, and then in the redux store, then refetching the relevant table
 */
export const setTableFilter: ActionCreator<ThunkResult<any>> = (table: TablesEnum, filterObject: any) => async (dispatch: Dispatch<any>) => {
    if (filterObject === undefined) return

    const targetPage = getFilterTargetPage(table)
    const filterType = getFilterTableType(UpdateTableFilterType.UPDATE_TABLE_FILTER, targetPage) as UpdateTableFilterType
    const updatedFilterObj = await FilterService.setFilter(targetPage, filterObject)
    const updateFilter = {
        type: filterType,
        payload: {
            table: targetPage,
            filter: updatedFilterObj,
            shouldFetch: true,
        },
    }

    dispatch(updateFilter)

    // refetch expense counts
    switch (targetPage) {
        case TablesEnum.ARCHIVE:
            await dispatch(fetchArchivedExpensesCounts())
            break
        case TablesEnum.TODO_INVOICE_APPROVAL:
            await dispatch(fetchDashboardExpensesCounts())
            break
        case TablesEnum.INBOX_INVOICE:
            dispatch(fetchDashboardInboxInvoicesCount())
            break
        default:
            break
    }
}

export const fetchAllFilters: ActionCreator<ThunkResult<void>> = () => async (dispatch: Dispatch) => {
    try {
        const filters = await FilterService.getFilters()

        filters.forEach((filter) => {
            const targetPage = getFilterTargetPage(filter.table)
            const filterType = getFilterTableType(UpdateTableFilterType.UPDATE_TABLE_FILTER, targetPage) as UpdateTableFilterType
            const updateFilter = {
                type: filterType,
                payload: {
                    table: targetPage,
                    filter: filter.parameters,
                    shouldFetch: true,
                },
            }

            dispatch(updateFilter)
        })
    } catch (err) {
        Sentry.captureException(`[Table Actions] Failed to fetch all filters: ${typeof err === "string" ? err : JSON.stringify(err)}`)
    }
}

/**
 * Updates the state of the filter ONLY in the redux store, then refetching the relevant table
 */
export const updateTableFilter: ActionCreator<UpdateTableFilterAction> = (table: TablesEnum, filterObject: any, shouldFetch = true) => {
    const type = getTableType(UpdateTableFilterType.UPDATE_TABLE_FILTER, table) as UpdateTableFilterType

    return {
        type,
        payload: {
            table,
            filter: filterObject,
            shouldFetch,
        },
    }
}

export const resetTableFilter: ActionCreator<UpdateTableFilterAction> = (table: TablesEnum) => {
    const type = getTableType(UpdateTableFilterType.UPDATE_TABLE_FILTER, table) as UpdateTableFilterType

    return {
        type,
        payload: {
            table,
            filter: {},
            shouldFetch: true,
        },
    }
}

/* 
UTILITY FUNCTIONS THAT DISPATCH TABLE ACTIONS 
*/
export const fetchTable = (table: TablesEnum, source: any, options: tableOptions) => async (dispatch: Dispatch) => {
    const queryParams = await getTableQueryParams(table)

    dispatch(tableFetching(table))
    try {
        let response = await TableService.fetchTableData(table, queryParams, source, options)
        // retry fetch if last fetch came empty but there are documents
        if (!response.docs.length && response.totalPages > 0 && queryParams.page > response.totalPages) {
            queryParams.page = response.totalPages
            response = await TableService.fetchTableData(table, queryParams, source, options)
        }
        dispatch(fetchTableSuccess(table, response))
    } catch (error) {
        dispatch(fetchTableFailure(table, error.message))
        if (axios.isCancel(error)) {
            console.error(`request cancelled:${error.message}`)
        } else if (error.message && error.message !== "logout" && error.config?.url !== "/token/refresh") {
            if (error.response?.status === HttpStatus.FORBIDDEN) {
                NotificationService.showErrorNotificationBasedOnResponseError(error, i18n.t("error:unauthorized_action"))
            } else {
                NotificationService.showErrorNotificationBasedOnResponseError(error, i18n.t("error:table_fetch_failed.title"), i18n.t("error:table_fetch_failed.message"))
            }
        }
    }
}

export const updateTableOnChange =
    <T>(table: TablesEnum, tableData: TableData<T>, shouldFetch = true) =>
    (pagination: any, _filters: any, sorter: any) => {
        if (pagination.current !== tableData.page || pagination.pageSize !== tableData.limit) {
            store.dispatch(updateTablePagination(table, pagination, shouldFetch))
        }

        if (sorter.columnKey && (sorter.columnKey !== tableData.sort.field || sorter.order !== tableData.sort.order)) {
            if (sorter.columnKey === tableData.sort.field && !sorter.order) store.dispatch(updateTableSort(table, sorter, shouldFetch, true))
            else store.dispatch(updateTableSort(table, sorter, shouldFetch))
        }
    }

/**
 * Refetches a table only if the table in store currently has at least one of documents that matches the ids passed as second argument.
 * Used for deletes. Do not use for updates or creation of new documents, because sorted tables might not work as intended.
 */
export const refetchTableIfNecessary = (table: TablesEnum, ids: Array<string>) => {
    const docsInStore = getTableDocsStore(table)
    if (docsInStore.find((doc: any) => ids.includes(doc._id))) store.dispatch(refetchTable(table))
}
