import { DocumentChangeInterface, OperationTypeEnum } from "@finway-group/shared/lib/models"
import * as Sentry from "@sentry/react"

import { RoutePathEnum } from "Shared/router/RoutePath.enum"
import store from "Shared/store"
import { setShouldFetchCashFlowsData } from "Shared/store/actions/liquidity/liquidityActions"
import { refetchTable } from "Shared/store/actions/tables/tableActions"
import { syncTransaction } from "Shared/store/actions/transaction/transactionActions"
import { TablesEnum } from "Shared/store/reducers/tableConfigReducer"
import { getCurrentPagePathParams } from "Shared/utils/dashboard.utils"
import { isClientErrorCode } from "Shared/utils/errorHandler.utils"

const subscribeToTransactions = async (dispatch: any, data: DocumentChangeInterface) => {
    const {
        fullDocument: { documentId, operationType },
    } = data

    try {
        switch (operationType) {
            case OperationTypeEnum.INSERT:
            case OperationTypeEnum.DELETE:
            case OperationTypeEnum.BULK_UPDATE:
            case OperationTypeEnum.UPDATE:
                dispatch(refetchTable(TablesEnum.TRANSACTIONS_INFLOW))
                dispatch(refetchTable(TablesEnum.TRANSACTIONS_OUTFLOW))
                dispatch(refetchTable(TablesEnum.TRANSACTIONS_ALL))
                dispatch(refetchTable(TablesEnum.EXPENSE_TRANSACTIONS))
                dispatch(refetchTable(TablesEnum.CARD_TRANSACTIONS))

                if (operationType === OperationTypeEnum.BULK_UPDATE || operationType === OperationTypeEnum.UPDATE) {
                    // Perform sync to the currently opened transaction
                    const currentTransactionItem = store.getState().transactions.item?.id
                    if (currentTransactionItem) dispatch(syncTransaction(currentTransactionItem))
                }
                break
            case OperationTypeEnum.TRANSACTION_UPDATE_CATEGORY:
                setShouldFetchCashFlowsData(true)(dispatch)
                dispatch(refetchTable(TablesEnum.TRANSACTIONS_INFLOW))
                dispatch(refetchTable(TablesEnum.TRANSACTIONS_OUTFLOW))
                dispatch(refetchTable(TablesEnum.TRANSACTIONS_ALL))
                break
            default:
                break
        }
    } catch (err) {
        if (isClientErrorCode(err)) return
        Sentry.captureException(`[Transaction Socket] Error: ${typeof err === "string" ? err : JSON.stringify(err)}`)
    }
}

export const subscribeToTransactionsCallbacks = (dispatch: any, { fullDocument: { operationType } }: DocumentChangeInterface) => {
    switch (operationType) {
        case OperationTypeEnum.BULK_UPDATE:
        case OperationTypeEnum.BULK_INSERT:
            // Required to update transaction view when a category has been successful deleted when checkAndNotify returns false
            // Perform sync to the transactions
            dispatch(refetchTable(TablesEnum.TRANSACTIONS_INFLOW))
            dispatch(refetchTable(TablesEnum.TRANSACTIONS_OUTFLOW))
            dispatch(refetchTable(TablesEnum.TRANSACTIONS_ALL))
            // Perform sync to the currently opened transaction
            if (operationType === OperationTypeEnum.BULK_UPDATE) {
                const currentPageParams = getCurrentPagePathParams()
                if (currentPageParams.domain !== RoutePathEnum.TRANSACTIONS) return

                const currentCompanyId = store.getState().company.item?.id
                const currentTransactionItem = store.getState().transactions.item

                /**
                 * TODO - There are some rare cases where transactions.item state still contains the transaction from the previous company
                 * Caused by when switching company, we do not clear the item states.
                 * In local development, even it can contain the transaction from a deleted database.
                 * This temp fix avoids the sync of a transcation that doesnt belong to the current company / exists
                 */
                if (!currentTransactionItem || currentTransactionItem.companyId !== currentCompanyId) return

                if (currentTransactionItem) dispatch(syncTransaction(currentTransactionItem))
            }
            break
        default:
            break
    }
}

export default subscribeToTransactions
