import { LiquidityForecastRule } from "@finway-group/shared/lib/models"
import { Form, Modal } from "antd"
import { Store } from "antd/lib/form/interface"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import ForecastRulesForm from "Components/forms/liquidity/forecastRules.form"
import { ForecastTransactionTypeEnum } from "Features/pages/liquidity/liquidity.types"
import { useScenario } from "Shared/hooks/liquidity.hooks"
import { LiquidityService, NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { createForecastRule, deleteForecastRule, fetchAllForecastRules, updateForecastRule } from "Shared/store/actions/liquidity/liquidityActions"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

interface ForecastRulesInterface {
    isShowing: boolean
    onCancel: () => void
    onCashFlowChange: () => void
}

const ForecastRulesModal: React.FC<ForecastRulesInterface> = ({ isShowing, onCancel, onCashFlowChange }) => {
    const { t } = useTranslation()
    const [formInstance] = Form.useForm()
    const [isLoading, setIsLoading] = useStateIfMounted(false)
    const [editForecastRule, setEditForecastRule] = useStateIfMounted("")
    const selectedScenario = useScenario()
    const [saveButtonDisabled, setSaveButtonDisabled] = useStateIfMounted(true)

    const dispatch = useDispatch()

    const onSubmit = async ({ kind, title, quantity, amount, currency, category, startDate, endDate, frequency, isGeneralized }: Store) => {
        const newForecastRule: LiquidityForecastRule = {
            _id: editForecastRule || "",
            title,
            quantity,
            amount,
            currency,
            category,
            startDate,
            isGeneralized,
        }

        if (!isGeneralized) newForecastRule.scenarioId = selectedScenario?._id
        if (kind === ForecastTransactionTypeEnum.RECURRING) {
            newForecastRule.endDate = endDate
            newForecastRule.frequency = frequency
        }

        try {
            setIsLoading(true)
            if (editForecastRule) {
                await updateForecastRule(editForecastRule, newForecastRule)(dispatch)
                NotificationService.send(
                    NotificationTypeEnum.SUCCESS,
                    t("notification:liquidity.update_forecast_rule.title"),
                    t("notification:liquidity.update_forecast_rule.message"),
                )
            } else {
                await createForecastRule(newForecastRule)(dispatch)
                NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:liquidity.add_forecast_rule.title"), t("notification:liquidity.add_forecast_rule.message"))
            }
            onCashFlowChange()

            onHide()
        } catch (err) {
            setIsLoading(false)
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:error"))
        }
    }

    const onDeleteEditForecastRule = (id: string) => {
        Modal.confirm({
            title: t("confirm:liquidity.delete_forecast_rule.title"),
            content: t("confirm:liquidity.delete_forecast_rule.message"),
            okText: t("confirm:liquidity.delete_forecast_rule.confirm"),
            cancelText: t("confirm:liquidity.delete_forecast_rule.cancel"),
            okType: "danger",
            onOk() {
                return deleteForecastRule(id)(dispatch)
                    .then(async () => {
                        onCashFlowChange()
                        NotificationService.send(
                            NotificationTypeEnum.SUCCESS,
                            t("notification:liquidity.delete_forecast_rule.title"),
                            t("notification:liquidity.delete_forecast_rule.message"),
                        )
                    })
                    .catch((err) => {
                        NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:liquidity.deleted_forecast_rule.title"))
                    })
            },
        })
    }

    const onHide = () => {
        setIsLoading(false)
        formInstance.resetFields()
        onCancel()
        setEditForecastRule("")
    }

    return (
        <Modal
            visible={isShowing}
            maskClosable={false}
            onOk={formInstance.submit}
            okButtonProps={{ disabled: saveButtonDisabled, style: { display: saveButtonDisabled ? "none" : "inline-block" } }}
            onCancel={onHide}
            okText={t("action:save")}
            cancelText={t("action:cancel")}
            confirmLoading={isLoading}
            keyboard
            destroyOnClose
        >
            <ForecastRulesForm
                formInstance={formInstance}
                onSubmit={onSubmit}
                onDeleteEditForecastRule={onDeleteEditForecastRule}
                editForecastRule={editForecastRule}
                setEditForecastRule={setEditForecastRule}
                setSaveButtonDisabled={setSaveButtonDisabled}
            />
        </Modal>
    )
}

export default ForecastRulesModal
