import { PriceIntervalEnum } from "@finway-group/shared/lib/models"
import { Card, Col, Row } from "antd"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import PriceLabel from "Components/priceLabel"
import { useOverviewData } from "Shared/hooks/analytics.hooks"
import { UserService } from "Shared/services"
import { fetchOverviewData } from "Shared/store/actions/analytics/analyticsActions"
import { breakpoints } from "Shared/utils/breakpoints.utils"

const AnalyticsBadges = React.memo(() => {
    const { t } = useTranslation()

    const globalCurrency = UserService.getLoggedInEmployeeProfile()?.settings.globalCurrency

    const overviewData = useOverviewData()

    const dispatch = useDispatch()

    useEffect(() => {
        fetchOverviewData()(dispatch)
    }, [])

    return (
        <>
            <Row gutter={[16, 0]} className="mb-32">
                <Col xs={24} md={24}>
                    <div className="flex justify-between items-center">
                        <h2 className="py-8 mb-0">{t("label:expenses")}</h2>
                    </div>
                </Col>
            </Row>
            <div className="overflow-x-auto sm:overflow-x-hidden mb-32">
                <Row gutter={[16, 0]} style={{ minWidth: breakpoints.xs }}>
                    <Col span={8}>
                        <Card>
                            <Row className="mt-10 mb-0">
                                <h4 className="mb-0 truncate inline">{t("label:this_month")}</h4>
                            </Row>
                            <Row>
                                <div className="mt-16">
                                    <p className="text-2xl">
                                        <PriceLabel value={overviewData?.currentMonthTotal || 0} currency={globalCurrency} interval={PriceIntervalEnum.ONE_TIME} />
                                    </p>
                                </div>
                            </Row>
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card>
                            <Row className="mt-10 mb-0">
                                <h4 className="mb-0 truncate inline">{t("label:this_quarter")}</h4>
                            </Row>
                            <Row>
                                <div className="mt-16">
                                    <p className="text-2xl">
                                        <PriceLabel value={overviewData?.currentQuarterTotal || 0} currency={globalCurrency} interval={PriceIntervalEnum.ONE_TIME} />
                                    </p>
                                </div>
                            </Row>
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card>
                            <Row className="mt-10 mb-0">
                                <h4 className="mb-0 truncate inline">{t("label:this_year")}</h4>
                            </Row>
                            <Row>
                                <div className="mt-16">
                                    <p className="text-2xl">
                                        <PriceLabel value={overviewData?.currentYearTotal || 0} currency={globalCurrency} interval={PriceIntervalEnum.ONE_TIME} />
                                    </p>
                                </div>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    )
})

export default AnalyticsBadges
