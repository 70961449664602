import { LoadingOutlined } from "@ant-design/icons"
import Modal from "antd/lib/modal/Modal"
import React from "react"
import { useTranslation } from "react-i18next"

import { useWeavrPhysicalPinDisplay } from "Shared/hooks/weavr.hooks"

interface DisplayPinModal {
    isVisible: boolean
    card: any
    onClose: () => void
}
const DisplayPinModal: React.FC<DisplayPinModal> = ({ isVisible, card, onClose }) => {
    const { isLoading } = useWeavrPhysicalPinDisplay(card, "physical-card-pin", isVisible, onClose)
    const { t } = useTranslation()

    return (
        <Modal visible={isVisible} destroyOnClose maskClosable={false} footer={null} onCancel={onClose} title={t(`creditCard:show_pin.title`)}>
            <h4 className="mb-20">{t(`info:cards.card_pin_info`)}</h4>
            <div className="mb-24">
                <div style={isLoading ? { display: "none" } : {}}>
                    <div id="physical-card-pin"></div>
                </div>
                {isLoading && (
                    <>
                        <LoadingOutlined style={{ fontSize: 24 }} spin />
                        <span className="ml-10">{t("info:cards.fetching_pin")}</span>
                    </>
                )}
            </div>
            <div>&nbsp;</div>
        </Modal>
    )
}

export default DisplayPinModal
