import { DatevCsvConfig } from "@finway-group/shared/lib/models"
import { Col, Row } from "antd"
import Form from "antd/lib/form"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { NumericFormat } from "react-number-format"

import { AntdFormInterface } from "Components/form/form.types"
import { useCompany } from "Shared/hooks/company.hooks"

import { FormLabel } from "../FormLabel"
import DatevCsvRules from "../rules/datevCsvConfig.rules"

const DatevCsvConfigForm = ({ formInstance, onSubmit }: AntdFormInterface<DatevCsvConfig>) => {
    const { t } = useTranslation()
    const company = useCompany()
    const rules = DatevCsvRules()

    useEffect(() => {
        formInstance.resetFields()
    }, [company._id])

    return (
        <>
            <p>{t("label:datev_csv_config.info")}</p>
            <Form
                form={formInstance}
                layout="vertical"
                style={{ marginTop: "25px" }}
                onFinish={onSubmit}
                autoComplete="off"
                initialValues={{
                    walletAccount: company.datevSettings?.datevCsvConfig?.walletAccount,
                    cashTransitAccount: company.datevSettings?.datevCsvConfig?.cashTransitAccount,
                    accountNumberLength: company.datevSettings?.datevCsvConfig?.accountNumberLength,
                }}
            >
                <Row gutter={[16, 16]}>
                    <Col span={12} sm={24} md={12}>
                        <Form.Item
                            label={<FormLabel label={t("input:datev_csv_config.wallet_account")} tooltip={t("tooltips:datev_csv_config.wallet_account")} />}
                            required
                            rules={rules.walletAccount}
                            name="walletAccount"
                        >
                            <NumericFormat
                                allowLeadingZeros={true}
                                allowNegative={false}
                                decimalScale={0}
                                className="ant-input-number-input"
                                placeholder={t("placeholder:datev_csv_config.wallet_account")}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12} sm={24} md={12}>
                        <Form.Item
                            label={<FormLabel label={t("input:datev_csv_config.cash_transit_account")} tooltip={t("tooltips:datev_csv_config.cash_transit_account")} />}
                            required
                            name="cashTransitAccount"
                            rules={rules.cashTransitAccount}
                        >
                            <NumericFormat
                                allowLeadingZeros={true}
                                allowNegative={false}
                                decimalScale={0}
                                className="ant-input-number-input"
                                placeholder={t("placeholder:datev_csv_config.cash_transit_account")}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Col span={24}>
                    <Form.Item
                        label={<FormLabel label={t("input:datev_csv_config.account_length")} tooltip={t("tooltips:datev_csv_config.account_number_length")} />}
                        required
                        name="accountNumberLength"
                        rules={rules.accountNumberLength}
                    >
                        <NumericFormat
                            allowLeadingZeros={false}
                            allowNegative={false}
                            decimalScale={0}
                            className="ant-input-number-input"
                            placeholder={t("placeholder:datev_csv_config.account_number_length")}
                        />
                    </Form.Item>
                </Col>
            </Form>
        </>
    )
}

export default DatevCsvConfigForm
