import { UserLanguageEnum } from "@finway-group/shared/lib/models/user/userSettings.interface"
import { Button, Form, Spin } from "antd"
import { Store } from "antd/lib/form/interface"
import React, { useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useHistory, useLocation, useParams } from "react-router-dom"

import PasswordForm from "Components/forms/password.form"
import { useAuth } from "Shared/hooks/auth.hooks"
import { RoutePathEnum } from "Shared/router/RoutePath.enum"
import { AuthnService, NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { ThunkDispatchResult } from "Shared/store"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

import AuthLayout from "./auth.layout"
import PasswordPolicyResetInfoPanel from "./components/passwordPolicyResetInfoPanel"

const ResetPasswordPage = () => {
    const dispatch: ThunkDispatchResult = useDispatch()
    const { token } = useParams<{ token: string }>()
    const { search } = useLocation()
    const { t, i18n } = useTranslation()
    const history = useHistory<{ isPasswordAllowed?: boolean; isInitial?: boolean }>()
    const { logout } = useAuth()

    const targetLanguage = useMemo(() => new URLSearchParams(search).get("language"), [search])
    const allowedLanguages = Object.values(UserLanguageEnum) as Array<string>

    useEffect(() => {
        if (targetLanguage && allowedLanguages.includes(targetLanguage)) {
            i18n.changeLanguage(targetLanguage)
        }
    }, [targetLanguage])

    const [formInstance] = Form.useForm()

    const [isLoading, setIsLoading] = useStateIfMounted(false)
    const [hasLoaded, setHasLoaded] = useStateIfMounted(false)
    const [isTokenValid, setIsTokenValid] = useStateIfMounted(true)
    const [userEmail, setUserEmail] = useStateIfMounted<string | undefined>(undefined)

    const verifyTokenAndSetUserEmail = async () => {
        try {
            const email = (await AuthnService.verifyToken(token)).data?.email
            setUserEmail(email)
            setIsTokenValid(true)
        } catch (err) {
            setIsTokenValid(false)
        } finally {
            setHasLoaded(true)
        }
    }

    useEffect(() => {
        verifyTokenAndSetUserEmail()
    }, [])

    const handleSubmit = async ({ password }: Store) => {
        if (!userEmail) return

        try {
            setIsLoading(true)

            await AuthnService.resetPassword(token, { newPassword: password, loggedInUserEmail: userEmail })

            if (history.location.state?.isInitial) {
                NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:password_set.title"), t("notification:password_set.message"))
            } else {
                NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:password_reset.title"), t("notification:password_reset.message"))
            }

            logout?.()
            history.push(RoutePathEnum.LOGIN)
        } catch (err) {
            NotificationService.showErrorNotificationBasedOnResponseError(err, "Error")
        } finally {
            setIsLoading(false)
        }
    }

    const infoPanel = history.location.state?.isPasswordAllowed === false ? <PasswordPolicyResetInfoPanel /> : undefined

    return (
        <AuthLayout>
            {!hasLoaded ? (
                <Spin spinning={true} />
            ) : isTokenValid ? (
                <>
                    <PasswordForm formInstance={formInstance} onSubmit={handleSubmit} infoPanel={infoPanel} />
                    <Button size="large" className="w-full rounded-lg font-medium bg-login-button" type="primary" loading={isLoading} onClick={formInstance.submit}>
                        {t("action:change_password")}
                    </Button>
                </>
            ) : (
                <div>
                    <h2 className="p-0">{t("error:reset_password.title")}</h2>
                    <p className="mt-10">{t("error:reset_password.message")}</p>
                </div>
            )}
        </AuthLayout>
    )
}

export default ResetPasswordPage
