import Icon from "@ant-design/icons"
import React from "react"

const eyeNoBgSvg = () => (
    <svg width="17" height="12" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.33333 1C5 1 2.15333 3.07333 1 6C2.15333 8.92667 5 11 8.33333 11C11.6667 11 14.5133 8.92667 15.6667 6C14.5133 3.07333 11.6667 1 8.33333 1Z" stroke="#606C7E" />
        <path
            d="M8.3335 4C7.22683 4 6.3335 4.89333 6.3335 6C6.3335 7.10667 7.22683 8 8.3335 8C9.44016 8 10.3335 7.10667 10.3335 6C10.3335 4.89333 9.44016 4 8.3335 4Z"
            stroke="#606C7E"
        />
    </svg>
)

const EyeNoBgIcon = (props: any) => <Icon component={eyeNoBgSvg} {...props} />

export default EyeNoBgIcon
