import { CsvTemplate, Expense, ExpenseStatusEnum, ExportFormatEnum } from "@finway-group/shared/lib/models"

import { OnExportSignature } from "Features/export/expenseExport"
import { MultiActionConfirmModal } from "Features/pages/expenses/expenseDetails/folderDetails/multiActionConfirm.modal"
import i18n from "Shared/locales/i18n"
import { isFolderExpense } from "Shared/utils/expense.utils"

import NotificationService, { NotificationTypeEnum } from "./notification.service"

const FolderService = {
    handleFolderExportAction: async (
        activeExpense: Expense,
        subExpenses: Array<Expense>,
        showModal: (component: any, destroy: boolean, props: any) => void,
        onExport: OnExportSignature,
        callbackExpenses: Array<Expense> | string,
        exportFormat: ExportFormatEnum,
        csvTemplate?: CsvTemplate,
    ) => {
        if (!isFolderExpense(activeExpense)) {
            return onExport({ expensesArrayOrExpenseQuery: callbackExpenses, exportFormat, csvTemplate })
        }

        // See if any of the subexpenses has been exported to the specific format
        // e.g. if the export format is SEPA, exportedSubExpenses should have all expenses with exportInformation.isExportedAsSepa === true
        const exportedSubExpenses = subExpenses.filter((subExpense) => subExpense.hasBeenExportedTo(exportFormat))
        const unexportedSubExpenses = subExpenses.filter((subExpense) => !exportedSubExpenses.includes(subExpense))

        const onConfirm = (key: string) => {
            // determine which to export
            switch (key) {
                case "not_exported":
                    if (unexportedSubExpenses.length === 0) {
                        // When no unexported expense remaining, cancel the action.
                        const noExportableExpensesMessage = i18n.exists(`info:folder.status.paid.modal.format.${exportFormat}`)
                            ? i18n.t("info:folder.status.paid.modal.already_exported.message_format", { format: i18n.t(`info:folder.status.paid.modal.format.${exportFormat}`) })
                            : i18n.t("info:folder.status.paid.modal.already_exported.message")
                        NotificationService.send(NotificationTypeEnum.ERROR, i18n.t("error:error"), noExportableExpensesMessage)
                        return
                    }
                    onExport({ expensesArrayOrExpenseQuery: [activeExpense, ...unexportedSubExpenses], exportFormat, csvTemplate })
                    return
                case "all":
                default:
                    onExport({ expensesArrayOrExpenseQuery: [activeExpense, ...subExpenses], exportFormat, csvTemplate })
            }
        }

        if (exportFormat === ExportFormatEnum.MARK_AS_DONE) {
            return onExport(
                // Only pass in the folder + subexpenses that are in the right state to be marked as done
                { expensesArrayOrExpenseQuery: [activeExpense, ...subExpenses].filter((expense) => expense.status === ExpenseStatusEnum.PAID), exportFormat, csvTemplate },
            )
        }

        if (exportedSubExpenses.length > 0) {
            // When a specific format has a translation string, we use the sentence with the format. Else, we use just the generic sentence.
            const description = i18n.exists(`info:folder.status.paid.modal.format.${exportFormat}`)
                ? i18n.t("info:folder.status.paid.modal.description_1_format", { format: i18n.t(`info:folder.status.paid.modal.format.${exportFormat}`) })
                : i18n.t("info:folder.status.paid.modal.description_1")

            // If any subexpense has been exported, show the message
            showModal(MultiActionConfirmModal, true, {
                isShowing: true,
                onConfirm,
                confirmOptions: [
                    { action: "not_exported", text: i18n.t("info:folder.status.paid.modal.only_not_exported") },
                    { action: "all", text: i18n.t("info:folder.status.paid.modal.export_all") },
                ],
                okButtonText: i18n.t("info:folder.status.paid.modal.confirm"),
                cancelButtonText: i18n.t("info:folder.status.paid.modal.cancel"),
                title: i18n.t("info:folder.status.paid.modal.title"),
                description: description + i18n.t("info:folder.status.paid.modal.description_2"),
            })
        } else {
            // If none has been exported yet, execute the export directl
            onConfirm("all")
        }
    },
}

export default FolderService
