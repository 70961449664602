import { CsvTemplate, CurrencyEnum, Expense, ExpenseStatusEnum, ExportFormatEnum, RightEnum } from "@finway-group/shared/lib/models"
import { ExpensePaymentFlowInformationEnum } from "@finway-group/shared/lib/models/expense/expensePaymentFlowInformation.enum"
import { isAtLeastReviewed } from "@finway-group/shared/lib/utils"
import { isExpenseDATEVCompliant } from "@finway-group/shared/lib/utils/datevValidation"
import { Button, Dropdown, Menu } from "antd"
import Tooltip from "antd/es/tooltip"
import { ButtonType } from "antd/lib/button"
import { DropDownProps } from "antd/lib/dropdown"
import React from "react"
import { Download as DownloadIcon } from "react-feather"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

import { OnExportSignature } from "Features/export/expenseExport"
import { useCompany, useDatevOnlineConnected, useDatevOnlineError } from "Shared/hooks/company.hooks"
import { useExpenseAccounts } from "Shared/hooks/expenseAccount.hooks"
import { useTaxes } from "Shared/hooks/tax.hooks"
import { AuthzService, NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { RootState } from "Shared/store/rootState.interface"
import { isFolderExpense, isMileageExpense, isPerDiemExpense } from "Shared/utils/expense.utils"
import { isValidDatevInvoiceCsvExport } from "Shared/utils/export.utils"
import { getTooltipPopupContainer } from "Shared/utils/helper.utils"

import { ConditionalWrapper } from "./conditionalWrapper"

interface ExportDropDownMenuInterface {
    expenses?: Array<Expense>
    expensesQuery?: string
    disabled?: boolean
    includeSepa?: boolean
    disabledTooltip?: string
    noIcon?: boolean
    text?: string
    asDottedButton?: boolean
    buttonClassName?: string
    hideDatevOnline?: boolean
    onlyFileExport?: boolean
    isBulk?: boolean
    buttonType?: ButtonType
    isLoading?: boolean
    primaryButton?: boolean
    isAllPagesOptionSelected?: boolean
    isAuditTrailVisible?: boolean
    showAllFormat?: boolean
    onExport?: OnExportSignature

    placement?: DropDownProps["placement"]
}

const ExportDropDownMenu = ({
    onExport,
    expenses,
    expensesQuery,
    disabled = false,
    includeSepa = false,
    disabledTooltip,
    noIcon,
    text,
    placement,
    asDottedButton = false,
    buttonClassName = "",
    hideDatevOnline = false,
    onlyFileExport = false,
    isLoading = false,
    primaryButton = false,
    isAllPagesOptionSelected = false,
    isAuditTrailVisible: showAuditTrail = false,
    showAllFormat = false,
}: ExportDropDownMenuInterface) => {
    const { t } = useTranslation()
    const isDatevOnlineConnected = useDatevOnlineConnected()
    const datevOnlineError = useDatevOnlineError()
    const company = useCompany()
    const taxValues = useTaxes()
    const expenseAccounts = useExpenseAccounts()
    const templates = useSelector(({ csvTemplates: { templates } }: RootState) => templates as Array<CsvTemplate>)
    const handleSelect = async ({ key, domEvent }: any) => {
        domEvent.stopPropagation()
        const expensesArrayOrExpenseQuery = expenses || expensesQuery
        if (!expensesArrayOrExpenseQuery) {
            NotificationService.send(NotificationTypeEnum.WARNING, t("error:file:export:title"), t("error:file:export:message"))
            return
        }
        if (key === ExportFormatEnum.DATEV_INVOICE_CSV && expenses && !isValidDatevInvoiceCsvExport(company, expenses)) {
            NotificationService.send(NotificationTypeEnum.WARNING, t("notification:datev_invoice_csv.title"), t("notification:datev_invoice_csv.message"), 10)
        }

        if (!Object.values(ExportFormatEnum).includes(key))
            onExport?.({
                expensesArrayOrExpenseQuery,
                exportFormat: ExportFormatEnum.CSV,
                csvTemplate: templates.find((template) => template._id === key),
            })
        else onExport?.({ expensesArrayOrExpenseQuery, exportFormat: key })
    }
    const hasExportRight = AuthzService.canLoggedInUserExport()

    const isAccountingExportDisabled = expenses?.every((expense) => expense.paymentFlowInformation === ExpensePaymentFlowInformationEnum.REPORTING_ONLY)

    const isDatevXmlExportDisabled =
        isAccountingExportDisabled ||
        expenses?.every((expense) => {
            const checks = { amounts: true, taxes: true, expenseAccounts: !company?.allowSpecialCharactersInExpenseAccountCodesForDatev }
            return !isExpenseDATEVCompliant(expense, taxValues, expenseAccounts, checks)?.isCompliant
        })

    const isDatevCsvDisabled = isAccountingExportDisabled || expenses?.some((expense) => !isAtLeastReviewed(expense.status))

    // DUO export is disabled even when we allow special characters in expense account codes
    // this way they can use the XML file, but not DUO since that will cause a guaranteed rejection
    const isDatevOnlineExportDisabled =
        isAccountingExportDisabled ||
        expenses?.every((expense) => {
            const checks = { amounts: true, taxes: true, expenseAccounts: true }
            return !isExpenseDATEVCompliant(expense, taxValues, expenseAccounts, checks)?.isCompliant
        })

    const isInternalReceipt = expenses && expenses.length === 1 && (isPerDiemExpense(expenses[0]) || isMileageExpense(expenses[0]))

    const shouldShowMarkAsDone =
        hasExportRight &&
        (isAllPagesOptionSelected ||
            expenses?.every((expense) => expense.status === ExpenseStatusEnum.PAID) ||
            expenses?.some((expense) => isFolderExpense(expense) && expense.status === ExpenseStatusEnum.PAID))

    const isNonSepaXmlExportEnabled = company?.isNonSepaXmlExportEnabled && expenses?.every((expense) => expense.currency !== CurrencyEnum.EUR)

    const exportDropDownOverlay = () => (
        <Menu onClick={handleSelect}>
            {(showAllFormat || !onlyFileExport) && (
                <Menu.Item key={ExportFormatEnum.ALL} className="flex items-center min-w-190 py-8">
                    <div className="flex items-center ml-12">
                        <p className="leading-none">
                            <span className="text-text">{t("input:export.export_format.all_formats")}</span>
                        </p>
                    </div>
                </Menu.Item>
            )}
            {!onlyFileExport && (
                <>
                    <Menu.Item key={ExportFormatEnum.CSV} className="flex items-center min-w-190 py-8">
                        <div className="flex items-center ml-12">
                            <p className="leading-none">
                                <span className="text-text">{t("input:export.export_format.csv")}</span>
                            </p>
                        </div>
                    </Menu.Item>
                    {templates.map((e) => (
                        <Menu.Item key={e._id} className="flex items-center min-w-190 py-8">
                            <div className="flex items-center ml-12">
                                <p className="leading-none">
                                    <span className="text-text">{e.name}</span>
                                </p>
                            </div>
                        </Menu.Item>
                    ))}
                    {company?.isLexwareExportEnabled && (
                        <Menu.Item key={ExportFormatEnum.CSV_LEXWARE} className="flex items-center min-w-190 py-8" disabled={isAccountingExportDisabled}>
                            <div className="flex items-center ml-12">
                                <p className="leading-none">
                                    <span className="text-text">{t("input:export.export_format.lexware_csv")}</span>
                                </p>
                            </div>
                        </Menu.Item>
                    )}
                    <Menu.Item key={ExportFormatEnum.DATEV_INVOICE_CSV} className="flex items-center min-w-190 py-8" disabled={isDatevCsvDisabled}>
                        <div className="flex items-center ml-12">
                            <p className="leading-none">
                                <span className="text-text">{t("input:export.export_format.datev_invoice_csv")}</span>
                            </p>
                        </div>
                    </Menu.Item>
                    <Menu.Item key={ExportFormatEnum.DATEV} className="flex items-center min-w-190 py-8" disabled={isDatevXmlExportDisabled}>
                        <div className="flex items-center ml-12">
                            <p className="leading-none">
                                <span className="text-text">{t("input:export.export_format.datev")}</span>
                            </p>
                        </div>
                    </Menu.Item>
                    {isDatevOnlineConnected && !datevOnlineError && !hideDatevOnline && (
                        <Menu.Item key={ExportFormatEnum.DATEV_ONLINE} className="flex items-center min-w-190 py-8" disabled={isDatevOnlineExportDisabled}>
                            <div className="flex items-center ml-12">
                                <p className="leading-none">
                                    <span className="text-text">{t("input:export.export_format.datev_online")}</span>
                                </p>
                            </div>
                        </Menu.Item>
                    )}
                    {AuthzService.isRightGrantedForLoggedInUser(RightEnum.EXPENSE__ALL__PAY_BY_BANK) && includeSepa && (
                        <>
                            <Menu.Item key={ExportFormatEnum.SEPA} className="flex items-center min-w-190 py-8">
                                <div className="flex items-center ml-12">
                                    <p className="leading-none">
                                        <span className="text-text">{t("input:export.export_format.sepa")}</span>
                                    </p>
                                </div>
                            </Menu.Item>
                            {isNonSepaXmlExportEnabled ? (
                                <Menu.Item key={ExportFormatEnum.NON_SEPA} className="flex items-center min-w-190 py-8" disabled={isAccountingExportDisabled}>
                                    <div className="flex items-center ml-12">
                                        <p className="leading-none">
                                            <span className="text-text">{t("input:export.export_format.non_sepa")}</span>
                                        </p>
                                    </div>
                                </Menu.Item>
                            ) : null}
                        </>
                    )}
                </>
            )}
            <Menu.Item key={ExportFormatEnum.INVOICES} className="flex items-center min-w-190 py-8">
                <div className="flex items-center ml-12">
                    <p className="leading-none">
                        {isInternalReceipt ? (
                            <span className="text-text">{t("input:export.export_format.internal_receipt")}</span>
                        ) : (
                            <span className="text-text">{t("input:export.export_format.invoices")}</span>
                        )}
                    </p>
                </div>
            </Menu.Item>
            {showAuditTrail && (
                <Menu.Item key={ExportFormatEnum.AUDIT_TRAIL} className="flex items-center min-w-190 py-8">
                    <div className="flex items-center ml-12">
                        <p className="leading-none">
                            <span className="text-text">{t("input:export.export_format.audit_trail")}</span>
                        </p>
                    </div>
                </Menu.Item>
            )}
            {shouldShowMarkAsDone && (
                <Menu.Item key={"mark_as_done"} className="flex items-center min-w-190 py-8">
                    <div className="flex items-center ml-12">
                        <p className="leading-none">
                            <span className="text-text">{t("input:export.export_format.mark_as_done")}</span>
                        </p>
                    </div>
                </Menu.Item>
            )}
        </Menu>
    )
    return (
        <ConditionalWrapper
            condition={disabled && disabledTooltip}
            wrapper={(children: any) => (
                <Tooltip getPopupContainer={getTooltipPopupContainer} title={<div>{disabledTooltip}</div>} placement="bottom">
                    {children}
                </Tooltip>
            )}
        >
            <Dropdown overlay={exportDropDownOverlay} trigger={["click"]} className="flex items-center" disabled={disabled} placement={placement}>
                {asDottedButton ? (
                    <Button onClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()} loading={isLoading}>
                        {isLoading ? <> </> : <DownloadIcon />}
                    </Button>
                ) : (
                    <div className={`${disabled ? "cursor-not-allowed" : "cursor-pointer"}`} onClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}>
                        <Button
                            onClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}
                            block
                            loading={isLoading}
                            disabled={disabled}
                            className={`pointer-events-none ${buttonClassName}`}
                            icon={noIcon ? <></> : <DownloadIcon />}
                            type={primaryButton ? "primary" : "default"}
                        >
                            <span>{text || t("action:export")} </span>
                            <i className="el-icon-caret-bottom el-icon--right" />
                        </Button>
                    </div>
                )}
            </Dropdown>
        </ConditionalWrapper>
    )
}

export default ExportDropDownMenu
