import { Company, DatevOnlineConnectionStatusEnum, Employee } from "@finway-group/shared/lib/models"
import UserCompanyProfileInterface from "@finway-group/shared/lib/models/user/userCompanyProfileInterface"
import moment from "moment"
import { useMemo } from "react"
import { useSelector } from "react-redux"

import { DEFAULT_DATE_FORMAT, DEFAULT_DATE_TIME_FORMAT, DEFAULT_TIME_FORMAT } from "Shared/config/consts"
import { RootState } from "Shared/store/rootState.interface"

export const useDatevOnlineConnected = () => {
    const company: any = useSelector(({ company: { item } }: RootState) => item)
    return company?.datevSettings?.datevContext?.status === DatevOnlineConnectionStatusEnum.CONNECTED
}

export const useDatevOnlineError = () => {
    const company: Company = useSelector(({ company: { item } }: RootState) => item)
    return company?.datevSettings?.datevContext?.error
}

export const useCompany = (): Company => useSelector(({ company: { item } }: RootState) => item)

export const useCompanies = (showDeleted = false) => {
    const items: Array<Company> = useSelector(({ company: { items } }: RootState) => items || [])
    if (showDeleted) return items

    return items.filter((item) => !item.deleted)
}

export const useCompanyById = (id: string) => {
    const items = useSelector(({ company: { items } }: RootState) => items || [])
    return items.find((item) => item._id === id)
}

// TODO: GoB - Use company settings to store date time format
export const useCompanyDateTimeFormat = () => ({
    dateFormat: DEFAULT_DATE_FORMAT,
    timeFormat: DEFAULT_TIME_FORMAT,
    dateTimeFormat: DEFAULT_DATE_TIME_FORMAT,
})

export const useCompanyDateTimeFormatter = () => {
    const { dateFormat, timeFormat, dateTimeFormat } = useCompanyDateTimeFormat()

    const generateFn = (format: string) => (input: Date | moment.Moment, fallback?: string) => input ? moment(input).format(format) : fallback

    return {
        formatDate: generateFn(dateFormat),
        formatTime: generateFn(timeFormat),
        formatDateTime: generateFn(dateTimeFormat),
    }
}

export const useArchiveInterval = () => useSelector(({ company: { item } }: RootState) => item.archiveAfterXDays)

export const useUserCompanies = (companies: Array<Company>, loggedInEmployee: Employee, activeCompany: UserCompanyProfileInterface) => {
    const userCompanies = useMemo(
        () =>
            companies
                .filter((company) => loggedInEmployee?.companyProfiles?.some((profile) => profile.companyId === company._id && !profile.deleted))
                .filter((company) => company._id !== activeCompany.companyId),
        [companies, loggedInEmployee, activeCompany],
    )

    return userCompanies
}

export const useAvailableCompanies = () => {}
