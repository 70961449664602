import { Currencies } from "@finway-group/shared/lib/consts"
import { CurrencyEnum, currencyEnumArray } from "@finway-group/shared/lib/models"
import { Col, Form, Image, Input, Row, Select } from "antd"
import { FormInstance } from "antd/lib/form"
import { Store } from "antd/lib/form/interface"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import CurrencyInput from "Components/currencyInput"
import FormItemCurrency from "Components/form/formItemCurrency"
import { WalletRules } from "Components/forms/rules/wallet.rules"
import IbanInput from "Components/ibanInput"
import { WeavrAuthenticationFactorBanner } from "Components/weavrAuthenticatonFactorBanner"
import { useWallet } from "Shared/hooks/corporate.hooks"
import { doFilterSelect } from "Shared/utils/helper.utils"

interface WalletWithdrawalFormInterface {
    formInstance: FormInstance
    onSubmit: (values: Store) => void
}

export const WalletWithdrawalForm: React.FC<WalletWithdrawalFormInterface> = ({ formInstance, onSubmit }) => {
    const { t } = useTranslation()
    const rules = WalletRules()
    const wallet = useWallet()
    const [currency, setCurrency] = useState(wallet.currency)

    return (
        <div>
            <Row gutter={[16, 16]} align="bottom">
                <Col span={24}>
                    <WeavrAuthenticationFactorBanner />
                </Col>
            </Row>
            <Form layout="vertical" form={formInstance} onFinish={onSubmit} scrollToFirstError={true}>
                <Row gutter={[16, 16]} align="bottom">
                    <Col span={12} md="14">
                        <FormItemCurrency currency={currency} label={t("input:wallet.amount")} name="amount" key="amount" required rules={rules.amount}>
                            <CurrencyInput currency={currency} value={0} />
                        </FormItemCurrency>
                    </Col>
                    <Col span={12} md="14">
                        <Form.Item label={t("input:wallet.currency")} name="currency" key="currency" initialValue={currency} required>
                            <Select disabled showSearch filterOption={doFilterSelect} onChange={(value: string) => setCurrency(value as CurrencyEnum)}>
                                {currencyEnumArray.map((currencyValue, index) => (
                                    <Select.Option key={index} value={currencyValue} label={currencyValue}>
                                        <div className="space-x-10 cursor-pointer">
                                            <Image className="align-middle" width={20} preview={false} src={`data:image/png;base64,${Currencies[currencyValue].flag}`} />
                                            <span className="text-text">{currencyValue}</span>
                                            <span className="text-text">-</span>
                                            <span className="text-text">{Currencies[currencyValue].name}</span>
                                        </div>
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} align="bottom">
                    <Col span={24}>
                        <Form.Item label={t("input:wallet.description")} name="description" key="description" required rules={rules.description}>
                            <Input placeholder={t("placeholder:wallet.description")} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} align="bottom">
                    <Col span={24}>
                        <Form.Item label={t("input:wallet.beneficiary_name")} name="beneficiaryName" key="beneficiaryName" required rules={rules.required}>
                            <Input placeholder={t("placeholder:wallet.beneficiary_name")} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} align="bottom">
                    <Col span={24}>
                        <Form.Item label={t("input:wallet.beneficiary_address")} name="beneficiaryAddress" key="beneficiaryAddress" required>
                            <Input placeholder={t("placeholder:wallet.beneficiary_address")} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} align="bottom">
                    <Col span={24}>
                        <Form.Item label={t("input:wallet.bank_name")} name="bankName" key="bankName" required>
                            <Input placeholder={t("placeholder:wallet.bank_name")} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} align="bottom">
                    <Col span={14}>
                        <Form.Item label={t("input:wallet.bank_address")} name="bankAddress" required>
                            <Input placeholder={t("placeholder:wallet.bank_address")} />
                        </Form.Item>
                    </Col>
                    <Col span={10}>
                        <Form.Item label={t("input:wallet.bank_country")} name="bankCountry" rules={rules.countryCode} required>
                            <Input placeholder={t("placeholder:wallet.bank_country_code")} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} align="bottom">
                    {wallet.currency !== CurrencyEnum.EUR ? (
                        <>
                            <Col span={14} md="14">
                                <Form.Item label={t("input:vendor.account_number")} name="accountNumber" required rules={rules.accountNumber}>
                                    <Input placeholder={t("placeholder:vendor.account_number")} />
                                </Form.Item>
                            </Col>
                            <Col span={10} md="10">
                                <Form.Item label={t("input:vendor.sort_code")} name="sortCode" required rules={rules.sortCode}>
                                    <Input placeholder={t("placeholder:vendor.sort_code")} />
                                </Form.Item>
                            </Col>
                        </>
                    ) : (
                        <>
                            <Col span={14} md="14">
                                <Form.Item label={t("input:vendor.iban")} name="iban" required rules={rules.iban}>
                                    <IbanInput />
                                </Form.Item>
                            </Col>
                            <Col span={10} md="10">
                                <Form.Item label={t("input:vendor.bic")} name="bic" required rules={rules.bic}>
                                    <Input placeholder={t("placeholder:vendor.bic")} />
                                </Form.Item>
                            </Col>
                        </>
                    )}
                </Row>
            </Form>
        </div>
    )
}

export default WalletWithdrawalForm
