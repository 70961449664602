import { CardStatusEnum } from "@finway-group/shared/lib/models"
import { t } from "i18next"
import { ActionCreator, Dispatch } from "redux"

import { ExpenseHttpService, NotificationService } from "Shared/services"
import CardService from "Shared/services/card.service"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { ThunkResult } from "Shared/store"

import { refetchCardRelatedTables } from "../tables/tableActions"
import { CreditCardActionTypes, FetchAllCardsAction, FetchOneCardAction, SetShouldRefetchCardsAction, SyncOneCardAction } from "./creditCardTypes"

// prettier-ignore
export const fetchAllCreditCards = (query = '', sortingCriteria = '', page = 1, limit = 20, append = false) => async (dispatch: Dispatch) => {
    const { cards, totalPages, totalDocs } = await CardService.fetchCards(query, sortingCriteria, page, limit);

    const fetchAllCards: FetchAllCardsAction = {
        type: CreditCardActionTypes.FETCH_ALL_CARDS,
        cards,
        totalPages,
        totalDocs,
        append,
    };

    dispatch(fetchAllCards);

    // reset refetch flag
    setShouldRefetch(false)(dispatch);

    return cards;
};

export const fetchOneCreditCard = (id: string) => async (dispatch: Dispatch) => {
    const card = await CardService.fetchCardById(id)
    if (!card) {
        throw Error("Card not found")
    }

    const fetchOneCard: FetchOneCardAction = {
        type: CreditCardActionTypes.FETCH_ONE_CARD,
        card,
    }

    dispatch(fetchOneCard)

    return card
}

export const fetchExpenseCard = (id: string) => async (dispatch: Dispatch) => {
    try {
        const card = await ExpenseHttpService.fetchExpenseCard(id)

        const fetchOneCard: FetchOneCardAction = {
            type: CreditCardActionTypes.FETCH_ONE_CARD,
            card,
        }

        dispatch(fetchOneCard)

        return card
    } catch (err) {
        NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:error"))
    }
}

export const setCreditCard = (card: any) => async (dispatch: Dispatch) => {
    const fetchOneCard: FetchOneCardAction = {
        type: CreditCardActionTypes.FETCH_ONE_CARD,
        card,
    }

    dispatch(fetchOneCard)
}

export const syncCreditCard: ActionCreator<ThunkResult<void>> = (card: any) => async (dispatch: Dispatch) => {
    // card can be either the card itself or the cardID
    if (!card._id) card = await CardService.fetchCardById(card.toString())

    const syncOneCard: SyncOneCardAction = {
        type: CreditCardActionTypes.SYNC_ONE_CARD,
        card,
    }

    dispatch(syncOneCard)
    dispatch(refetchCardRelatedTables())
}

export const updateCardStatus: ActionCreator<ThunkResult<void>> = (id: string, userId: string, status: CardStatusEnum) => async (dispatch: Dispatch) => {
    const card = await CardService.updateCardStatus(id, userId, status)

    const syncOneCard: SyncOneCardAction = {
        type: CreditCardActionTypes.SYNC_ONE_CARD,
        card,
    }

    dispatch(syncOneCard)
    dispatch(refetchCardRelatedTables())
}

export const unblockCardPin: ActionCreator<ThunkResult<void>> = (id: string) => async (dispatch: Dispatch) => {
    const card = await CardService.unblockCardPin(id)

    const syncOneCard: SyncOneCardAction = {
        type: CreditCardActionTypes.SYNC_ONE_CARD,
        card,
    }

    dispatch(syncOneCard)
}

export const topUpCard: ActionCreator<ThunkResult<void>> = (id: string) => async (dispatch: Dispatch) => {
    const card = await CardService.topUpCard(id)

    const syncOneCard: SyncOneCardAction = {
        type: CreditCardActionTypes.SYNC_ONE_CARD,
        card,
    }

    dispatch(syncOneCard)
    dispatch(refetchCardRelatedTables())
}

export const emptyCard: ActionCreator<ThunkResult<void>> = (id: string) => async (dispatch: Dispatch) => {
    const card = await CardService.emptyCard(id)

    const syncOneCard: SyncOneCardAction = {
        type: CreditCardActionTypes.SYNC_ONE_CARD,
        card,
    }

    dispatch(syncOneCard)
    dispatch(refetchCardRelatedTables())
}

export const setShouldRefetch = (shouldRefetch: boolean) => async (dispatch: Dispatch) => {
    const setShouldRefetchAction: SetShouldRefetchCardsAction = {
        type: CreditCardActionTypes.SET_SHOULD_REFETCH_CARDS,
        shouldRefetch,
    }

    dispatch(setShouldRefetchAction)
}
