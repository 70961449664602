import { FileTemplateEnum, FileUploadData, FilterPresetEnum, MatchingDataInterface, UploadKindEnum } from "@finway-group/shared/lib/models"
import axios, { ResponseType } from "axios"
import i18n from "i18next"

import { checkFilesSize } from "Shared/utils/helper.utils"

import NotificationService, { NotificationTypeEnum } from "./notification.service"

type SignedUrlData = {
    signedUrl: string
}

/**
 * Axios instance w/o base config and interceptors required for API
 */
const axios3rdParty = axios.create()
axios3rdParty.interceptors.request.clear()
axios3rdParty.interceptors.response.clear()

const FileService = {
    upload: async (file: File, kind: UploadKindEnum, onProgress?: any) => {
        const { formData, config } = prepareFileUpload(file, onProgress)
        formData.append("kind", kind.toString())
        const { data } = await axios.post(`/files`, formData, config)
        return data as FileUploadData
    },

    uploadMultiple: async (files: Array<File>, kind: UploadKindEnum, onProgress?: any) => {
        const { formData, config } = prepareFilesUpload(files, onProgress)
        formData.append("kind", kind.toString())
        const { data } = await axios.post(`/files/multiple`, formData, config)
        return data as Array<FileUploadData>
    },

    uploadMatchingCSV: async (file: File, onProgress?: any) => {
        const { formData, config } = prepareFileUpload(file, onProgress)
        formData.append("kind", UploadKindEnum.CSV_MATCHING.toString())
        const { data } = await axios.post(`/matching/determine`, formData, config)
        return data as Array<MatchingDataInterface>
    },

    export: (filter: string, shouldSkipUpdate = false, subjectLines?: Record<string, string>) =>
        // Use "Follow up requests" preset because the API filters it out by default. By doing this we avoid our target expense to be filtered out.
        axios.post(`export/expenses?${filter}&presets[in]=${FilterPresetEnum.FOLLOW_UP_REQUESTS}`, { subjectLines, shouldSkipUpdate }),

    getCSVFileData: async (url: string) => {
        const { data } = await axios.post(`/files/parse-csv`, { csvFileUrl: url })
        return data as Array<string>
    },

    getSignedUrl: (url: string) => axios.post<SignedUrlData>(`/files/signed-url`, { url }),

    downladSignedUrl: async <T = Blob>({ url, responseType = "blob" }: { url: string; responseType?: ResponseType }) => {
        const { data } = await FileService.getSignedUrl(url)
        return axios3rdParty.get<T>(data.signedUrl, { responseType })
    },

    exportExpenseSummary: async (id: string, language: string) => {
        const {
            data: { pdfUrl },
        } = await axios.post(`/export/expenses/${id}/summary`, { language })
        FileService.downloadFileAsSignedUrl(pdfUrl)
    },
    downloadFileAsSignedUrl: (fileUrl: string) => {
        FileService.getSignedUrl(fileUrl).then(({ data: { signedUrl } }) => window.open(signedUrl, "_self"))
    },
    checkFilesSizeAndHandleError: (files: Array<File>) => {
        if (!checkFilesSize(files)) {
            NotificationService.send(NotificationTypeEnum.ERROR, i18n.t("error:file.too_big.title"), i18n.t("error:file.too_big.message"))
            return false
        }
        return true
    },
    checkFileSizeAndHandleError: (file: File) => FileService.checkFilesSizeAndHandleError([file]),
}

export const prepareFileUpload = (file: File, onProgress?: any) => prepareFilesUpload([file], onProgress)

export const prepareFilesUpload = (files: Array<File>, onProgress?: any) => {
    const formData = new FormData()
    let config = {
        headers: { "Content-type": "multipart/form-data" },
        onUploadProgress: (event: any) => {},
    }

    if (onProgress) {
        config = {
            ...config,
            onUploadProgress: (event: any) => {
                onProgress({ percent: (event.loaded / event.total) * 100 })
            },
        }
    }

    files.forEach((file) => formData.append("uploadFile", file))
    return { formData, config }
}

export default FileService
