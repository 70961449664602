import { Reducer } from "redux"

import {
    CloseDataUploadNotificationAction,
    DataUploadNotificationTypeEnum,
    FetchImportResultAction,
    ImportActionTypes,
    ImportActions,
    ImportDataFailureAction,
    ImportDataRequestAcceptedAction,
    ImportDataSuccessAction,
    ImportDataValidateOnlyFailureAction,
    ImportDataValidateOnlySuccessAction,
    ImportDataValidationErrorsAction,
    OpenDataUploadModalAction,
} from "../actions/import/importTypes"

export interface ImportState {
    importResultId?: string
    isInProgress: boolean
    notification?: DataUploadNotificationTypeEnum
}

const getInitialState = () => ({
    importResultId: undefined,
    isInProgress: false,
    notification: undefined,
})

const setImportResultId = (state: ImportState, action: FetchImportResultAction): ImportState => ({
    ...state,
    importResultId: action.payload.importResultId,
})

const setIsInProgress = (state: ImportState, action: ImportDataRequestAcceptedAction | ImportDataValidateOnlyFailureAction | ImportDataFailureAction): ImportState => ({
    ...state,
    isInProgress: action.payload.isInProgress,
})

const setNotification = (state: ImportState, action: OpenDataUploadModalAction | CloseDataUploadNotificationAction): ImportState => ({
    notification: action.payload.notification,
    importResultId: state.importResultId,
    isInProgress: state.isInProgress,
})

const setState = (action: ImportDataValidateOnlySuccessAction | ImportDataValidationErrorsAction | ImportDataSuccessAction): ImportState => ({
    importResultId: action.payload.importResultId,
    isInProgress: action.payload.isInProgress,
    notification: action.payload.notification,
})

const resetState = () => getInitialState()

export const importReducer: Reducer<ImportState, ImportActions> = (state = getInitialState(), action) => {
    switch (action.type) {
        case ImportActionTypes.FETCH_IMPORT_RESULT:
            return setImportResultId(state, action)

        case ImportActionTypes.IMPORT_DATA_REQUEST_ACCEPTED:
        case ImportActionTypes.IMPORT_DATA_FAILURE:
        case ImportActionTypes.IMPORT_DATA_VALIDATE_ONLY_FAILURE:
            return setIsInProgress(state, action)

        case ImportActionTypes.OPEN_DATA_UPLOAD_MODAL:
        case ImportActionTypes.CLOSE_DATA_UPLOAD_NOTIFICATION:
            return setNotification(state, action)

        case ImportActionTypes.IMPORT_DATA_VALIDATE_ONLY_SUCCESS:
        case ImportActionTypes.IMPORT_DATA_VALIDATION_ERRORS:
        case ImportActionTypes.IMPORT_DATA_SUCCESS:
            return setState(action)

        case ImportActionTypes.RESET_IMPORT_RESULTS:
            return resetState()

        default:
            return state
    }
}
