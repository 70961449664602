import { Expense } from "@finway-group/shared/lib/models"
import { getApprovalProcessScopeFromExpenseStatus } from "@finway-group/shared/lib/utils/approvalProcess.utils"
import { MAX_INPUT_LENGTH } from "@finway-group/shared/lib/utils/validators"
import { Form, Input, Modal } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import { ExpenseRules } from "Components/forms/rules"
import { rejectExpenseRequest } from "Shared/hooks/expense.hooks"
import { UserService } from "Shared/services"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

interface RejectModalInterface {
    isVisible: boolean
    title: string
    message: string
    okText: string
    cancelText: string
    request: Expense
    nextExpense?: string
    onCancel: () => void
}
const RejectModal: React.FC<RejectModalInterface> = ({ request, isVisible, title, message, okText, cancelText, nextExpense, onCancel }) => {
    const history = useHistory()
    const [form] = Form.useForm()
    const { t } = useTranslation()
    const loggedInProfile = UserService.getLoggedInEmployeeProfile()
    const [isLoading, setIsLoading] = useStateIfMounted(false)
    const rules = ExpenseRules()

    const onFormSubmit = (values: { [key: string]: string }) => {
        setIsLoading(true)
        const scope = getApprovalProcessScopeFromExpenseStatus(request.status)
        rejectExpenseRequest({ expense: request, rejectionNote: values.reasonToReject, history, scope })
            .then(() => {
                handleHide()
                if (loggedInProfile.settings.enableGoToNextExpense && nextExpense) {
                    history.push({ pathname: nextExpense, state: { fromExpenseSwitching: true } })
                }
            })
            .catch(() => {
                setIsLoading(false)
            })
    }

    const handleHide = () => {
        setIsLoading(false)
        form.resetFields()
        onCancel()
    }

    return (
        <Modal
            title={title}
            visible={isVisible}
            maskClosable={false}
            onOk={form.submit}
            onCancel={handleHide}
            okText={okText}
            cancelText={cancelText}
            keyboard
            destroyOnClose
            confirmLoading={isLoading}
        >
            <Form form={form} layout="vertical" onFinish={onFormSubmit}>
                <Form.Item name="reasonToReject" key="reasonToReject" label={message} required rules={rules.reasonToReject}>
                    <Input placeholder={t("placeholder:request.reject")} maxLength={MAX_INPUT_LENGTH} />
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default RejectModal
