import React from "react"

import SettingsLayout from "../settings.layout"
import DatevOnlineSettings from "./datevOnlineSettings"

const CompanyIntegrations = () => (
    <SettingsLayout titleKey="label:integrations">
        <div className="justify-items-center">
            <DatevOnlineSettings />
        </div>
    </SettingsLayout>
)

export default CompanyIntegrations
