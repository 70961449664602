import { RightEnum } from "@finway-group/shared/lib/models"
import { Employee } from "@finway-group/shared/lib/models/user/employee.model"
import { Form, Select } from "antd"
import { FormInstance } from "antd/lib/form"
import React, { useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import UserImage from "Components/UserImage"
import { costCenterRules } from "Components/forms/rules/costCenter.rules"
import { useLoggedInEmployeeProfile } from "Shared/hooks/user.hooks"
import { UserService } from "Shared/services"
import { doFilterSelect, isRightGranted } from "Shared/utils/helper.utils"
import { getPopupAnchor } from "Shared/utils/popup.utils"

interface CostCenterFormUsersSelectsInterface {
    formInstance: FormInstance
    approvers: Array<Employee>
    employees: Array<Employee>
}

const ALL = "__all"
const MAX_SINGLE_OPTIONS_COUNT = 3
export const CostCenterFormUsersSelects: React.FC<CostCenterFormUsersSelectsInterface> = ({ formInstance, approvers, employees }) => {
    const { t } = useTranslation()
    const loggedInUser = useLoggedInEmployeeProfile()
    const rules = costCenterRules()
    const grpLabel = t("input:cost_center.shared_users_people_counter_label")
    const allLabel = t("input:cost_center.shared_users_all_option_label")

    const filterAdminsAndResponsibleEmployee = (responsibleEmployeeId: string) => {
        const filteredEmployees = employees.filter(
            (e) =>
                e.id !== responsibleEmployeeId &&
                (!isRightGranted(UserService.getLoggedActiveCompanyProfileRole(e)?.rights, RightEnum.CC1__ALL__READ) || formInstance.getFieldValue("sharedUsers")?.includes(e.id)),
        )

        // Remove self from selection if not added previously
        if (!(formInstance.getFieldValue("sharedUsers") ?? []).includes(loggedInUser.id)) {
            return filteredEmployees.filter((employee) => employee.id !== loggedInUser.id)
        }

        return filteredEmployees
    }

    const [sharedUsersOptions, setSharedUsersOptions] = useState<Array<Employee>>(filterAdminsAndResponsibleEmployee(formInstance.getFieldValue("responsibleUser")))
    const [selectedOptions, setSelectedOptions] = useState(formInstance.getFieldValue("sharedUsers")?.length ?? 0)
    const tagRenderCallCounter = useRef(0) // reference required for aggregating tags into one entry (e.g. [5] People)

    const renderEmployeeSelectOptions = (employees: Array<Employee>) =>
        employees.map((employee) => (
            <Select.Option key={employee.id} label={`${employee.firstName} ${employee.lastName}`} value={employee.id}>
                <div className="flex items-center">
                    <UserImage user={employee} />
                    <span>{`${employee.firstName} ${employee.lastName}`}</span>
                </div>
            </Select.Option>
        ))

    const handleResponsibleUserChanged = (responsibleEmployeeId: string) => {
        const sharedUsers = formInstance.getFieldValue("sharedUsers")
        if (sharedUsers && sharedUsers.includes(responsibleEmployeeId)) {
            const newSharedUsers = sharedUsers.filter((user: string) => user !== responsibleEmployeeId)
            formInstance.setFieldsValue({ sharedUsers: newSharedUsers })
            setSelectedOptions(newSharedUsers.length)
        }
        setSharedUsersOptions(filterAdminsAndResponsibleEmployee(responsibleEmployeeId))
    }

    const handleSelectAll = (value: any) => {
        if (value && value.length && value.includes(ALL)) {
            if (value.length === sharedUsersOptions.length + 1) {
                setSelectedOptions(0)
                return []
            }
            setSelectedOptions(sharedUsersOptions.length)
            return sharedUsersOptions.map((employee) => employee.id)
        }
        return value
    }

    const onSelect = (value: any, option: any) => {
        if (option.key != ALL) {
            setSelectedOptions(selectedOptions + 1)
        }
    }
    const onDeselect = () => {
        setSelectedOptions(selectedOptions - 1)
    }
    const onClear = () => {
        setSelectedOptions(0)
    }

    const tagRender = (value: any) => {
        if (selectedOptions <= MAX_SINGLE_OPTIONS_COUNT) {
            return <div className="mr-10">{value.label}</div>
        }

        return tagRenderAggregate()
    }

    const tagRenderAggregate = () => {
        tagRenderCallCounter.current += 1

        if (tagRenderCallCounter.current !== selectedOptions) {
            return <></>
        }

        tagRenderCallCounter.current = 0

        return (
            <span>
                ({selectedOptions}) {selectedOptions !== sharedUsersOptions.length ? grpLabel : allLabel}
            </span>
        )
    }

    return (
        <>
            <Form.Item name="responsibleUser" label={t("input:cost_center.responsible_user")} rules={rules.responsibleUser} required>
                <Select showSearch filterOption={doFilterSelect} getPopupContainer={getPopupAnchor()} onSelect={handleResponsibleUserChanged}>
                    {renderEmployeeSelectOptions(approvers)}
                </Select>
            </Form.Item>
            <Form.Item name="sharedUsers" label={t("input:cost_center.shared_users")} getValueFromEvent={handleSelectAll}>
                <Select
                    mode="multiple"
                    showArrow
                    showSearch
                    allowClear
                    filterOption={doFilterSelect}
                    getPopupContainer={getPopupAnchor()}
                    onSelect={onSelect}
                    onDeselect={onDeselect}
                    onClear={onClear}
                    tagRender={tagRender}
                >
                    <Select.Option key={ALL} value={ALL}>
                        <span>{allLabel}</span>
                    </Select.Option>
                    {renderEmployeeSelectOptions(sharedUsersOptions)}
                </Select>
            </Form.Item>
        </>
    )
}

export default CostCenterFormUsersSelects
