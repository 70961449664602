import { LoadingOutlined } from "@ant-design/icons"
import { PaymentInvoice } from "@finway-group/shared/lib/models"
import { Button, Table, Tag } from "antd"
import { ColumnsType } from "antd/lib/table"
import moment from "moment"
import React, { useEffect } from "react"
import { Download as DownloadIcon } from "react-feather"
import { useTranslation } from "react-i18next"

import PriceLabel from "Components/priceLabel"
import { PaymentSettingsTabRoutes, RoutePathEnum } from "Shared/router/RoutePath.enum"
import { NotificationService } from "Shared/services"
import PaymentSettingsService, { PaymentInvoiceLinks } from "Shared/services/paymentSettings.service"
import { convertSortingToString } from "Shared/utils/expense.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

const DEFAULT_PAGE_SIZE = 5

interface PaymentInvoiceListInterface {
    activeTab: PaymentSettingsTabRoutes
}

type PaymentInvoiceLinkKeys = keyof PaymentInvoiceLinks

const PaymentInvoiceList: React.FC<PaymentInvoiceListInterface> = ({ activeTab }) => {
    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useStateIfMounted(false)
    const [invoices, setInvoices] = useStateIfMounted({ docs: [], totalPages: 0, totalDocs: 0 } as { docs: Array<PaymentInvoice>; totalPages: number; totalDocs: number })

    useEffect(() => {
        if (activeTab === RoutePathEnum.SETTINGS_BILLING_TAB_INVOICES) {
            fetchInvoices()
        }
    }, [activeTab])

    const fetchInvoices = async (query = "", sortingCriteria = "", page = 1, limit = DEFAULT_PAGE_SIZE) => {
        setIsLoading(true)
        try {
            const fetchedInvoices = await PaymentSettingsService.getInvoices(query, sortingCriteria, page, limit)
            setInvoices(fetchedInvoices)
        } finally {
            setIsLoading(false)
        }
    }

    const getInvoiceLinksAndOpen = (invoice: PaymentInvoice, link: PaymentInvoiceLinkKeys) => {
        PaymentSettingsService.getInvoiceLinks(invoice)
            .then((data) => {
                window.open(data[link], "_blank", "noopener,noreferrer")
            })
            .catch((err) => {
                NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:request:not_found.title"), t("error:request:not_found.message"))
            })
    }

    const columns: ColumnsType<PaymentInvoice> = [
        {
            title: t("label:payment_settings.invoice_no"),
            key: "invoiceNumber",
            width: 100,
            sorter: true,
            render: (invoice: PaymentInvoice) => invoice.invoiceNumber,
        },
        {
            title: t("label:date"),
            key: "created",
            width: 100,
            sorter: true,
            render: (invoice: PaymentInvoice) => <>{moment(invoice.created).format("ll")}</>,
        },
        {
            title: t("label:status"),
            key: "status",
            width: 100,
            sorter: true,
            render: (invoice: PaymentInvoice) => <Tag>{invoice.status}</Tag>,
        },
        {
            title: t("label:total"),
            key: "amountDue",
            width: 100,
            sorter: true,
            align: "right",
            render: (invoice: PaymentInvoice) => <PriceLabel value={invoice.amountDue / 100} currency={invoice.currency} />,
        },
        {
            title: t("label:pdf"),
            key: "stripeHostedPdf",
            width: 40,
            sorter: false,
            align: "right",
            render: (invoice: PaymentInvoice) => (
                <span className="ml-20">
                    <Button onClick={() => getInvoiceLinksAndOpen(invoice, "stripeHostedPdf")}>
                        <DownloadIcon />
                    </Button>
                </span>
            ),
        },
    ]

    return (
        <div className="overflow-auto p-2 animation-appear">
            <Table<PaymentInvoice>
                rowKey={(record) => record.invoiceId}
                columns={columns}
                dataSource={invoices.docs}
                loading={{
                    spinning: isLoading,
                    indicator: <LoadingOutlined style={{ fontSize: 30, color: "black" }} spin />,
                }}
                pagination={{
                    position: ["bottomRight"],
                    showSizeChanger: true,
                    total: invoices.totalDocs,
                    hideOnSinglePage: false,
                    pageSizeOptions: ["5", "10", "20", "50", "100"],
                    defaultPageSize: DEFAULT_PAGE_SIZE,
                }}
                onChange={(pagination, filters, sorter: any) => {
                    fetchInvoices("", convertSortingToString({ order: sorter.order, field: sorter.columnKey }), pagination.current, pagination.pageSize)
                }}
                onRow={(invoice) => ({
                    onClick: () => {
                        getInvoiceLinksAndOpen(invoice, "stripeHostedUrl")
                    },
                })}
            />
        </div>
    )
}

export default PaymentInvoiceList
