import { VendorRule } from "@finway-group/shared/lib/models/vendor/vendorRule.model"
import { Button, Form, Modal } from "antd"
import { Store } from "antd/lib/form/interface"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import VendorRuleForm from "Components/forms/vendorRule.form"
import { useVendorById } from "Shared/hooks/vendor.hooks"
import { NotificationService, VendorService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { updateVendorRule } from "Shared/store/actions/vendor/vendorActions"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

interface VendorRuleModalProps {
    isShowing: boolean
    vendorIDToUpdate: string
    onCancel: (state: boolean) => void
    onSubmit?: (rule: Pick<VendorRule, "costCenter" | "costCenter2" | "expenseAccount" | "expensePaymentFlowInformation" | "paymentTerm">) => void
}

const VendorRuleModal = ({ isShowing, onCancel, vendorIDToUpdate, onSubmit }: VendorRuleModalProps) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [formInstance] = Form.useForm()
    const [isLoading, setIsLoading] = useStateIfMounted<boolean>(false)
    const vendor = useVendorById(vendorIDToUpdate)

    useEffect(() => {
        if (isShowing) {
            const initialVendorFormRule = VendorService.getInitialVendorFormRule(vendor)
            formInstance.setFieldsValue(initialVendorFormRule)
        }

        return () => {
            if (isShowing) formInstance.resetFields()
        }
    }, [isShowing, vendorIDToUpdate])

    const handleHide = () => {
        setIsLoading(false)
        formInstance.resetFields()
        onCancel(false)
    }

    const handleSubmit = async (values: Store) => {
        setIsLoading(true)

        try {
            await updateVendorRule(vendorIDToUpdate, { rule: values })(dispatch)
            onSubmit?.(values)
            handleHide()
            NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:vendor.updated.title"), t("notification:vendor.updated.message"))
        } catch (err) {
            setIsLoading(false)
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:vendor.edit.title"))
        }
    }

    return (
        <Modal
            destroyOnClose
            afterClose={() => formInstance.resetFields()}
            visible={isShowing}
            maskClosable
            title={t("label:set_vendor_rule")}
            onCancel={handleHide}
            closable
            keyboard
            className="ant-modal--small"
            footer={[
                <Button key="back" onClick={handleHide}>
                    {t("action:cancel")}
                </Button>,
                <Button
                    key="submit"
                    type="primary"
                    loading={isLoading}
                    onClick={() => {
                        formInstance.submit()
                    }}
                >
                    {t("action:vendor.set_rule")}
                </Button>,
            ]}
        >
            <VendorRuleForm formInstance={formInstance} vendor={vendor} onSubmit={handleSubmit} />
        </Modal>
    )
}

export default VendorRuleModal
