import { CalculationIntervalEnum, Team } from "@finway-group/shared/lib/models"
import HttpStatus from "http-status"
import { Dispatch } from "redux"

import { CostCenterBudgetUsageBarChartsOptions } from "Features/pages/costCenters/costCenterDetails/costCenterBudgetUsageBarCharts"
import { ReportingFilterInterface } from "Features/pages/reporting/reportingFilter.interface"
import { AnalyticsService, EmployeeService, UserService } from "Shared/services"
import CostCenterService from "Shared/services/costCenter.service"
import store from "Shared/store"
import { getStartAndEndDateOfInterval } from "Shared/utils/helper.utils"

import { syncEmployee } from "../employee/employeeActions"
import {
    AnalyticsActionTypes,
    FetchCardOverviewDataAction,
    FetchCostCenterBudgetDataAction,
    FetchCostCenterExpenseDataAction,
    FetchCostCenterExpenseDataPieAction,
    FetchExpensesPerCostCenterOverLast12MonthsDataAction,
    FetchExpensesPerDepartmentPie,
    FetchExpensesPerEmployeeDataAction,
    FetchOverviewDataAction,
    FetchTeamsWithUsersCountAction,
    ResetCardOverviewDataAction,
    SetCostCenterIntervalAction,
    SetCostCenterIntervalPieAction,
    SetEmployeeIntervalAction,
} from "./analyticsTypes"

export const updateAnalytics = () => async (dispatch: Dispatch) => {
    const loggedInUser = UserService.getLoggedInEmployeeProfile()
    if (!loggedInUser || !loggedInUser?.settings) return

    await Promise.allSettled([
        // To ensure that the employee is on the "newest" state
        syncEmployee(loggedInUser.id)(dispatch),
        fetchOverviewData()(dispatch),
        fetchExpensesPerEmployeeData()(dispatch),
        fetchExpensesPerCostCenterOverLast12MonthsData()(dispatch),
        fetchCostCenterBudgetData()(dispatch),
        fetchCostCenterExpenseData()(dispatch),
        fetchCostCenterExpenseDataPie()(dispatch),
        fetchExpensesPerDepartmentDataPie()(dispatch),
    ])
}

export const fetchOverviewData = () => async (dispatch: Dispatch) => {
    const { globalCurrency } = UserService.getLoggedInEmployeeProfile().settings
    const overviewData = await AnalyticsService.fetchOverviewData(globalCurrency)

    const fetchOverviewData: FetchOverviewDataAction = {
        type: AnalyticsActionTypes.FETCH_OVERVIEW_DATA,
        overviewData,
    }

    dispatch(fetchOverviewData)
    return overviewData
}

export const fetchCardOverviewData = () => async (dispatch: Dispatch) => {
    try {
        const { globalCurrency } = UserService.getLoggedInEmployeeProfile().settings
        const cardOverviewData = await AnalyticsService.fetchCardOverviewData(globalCurrency)

        const fetchCardOverviewData: FetchCardOverviewDataAction = {
            type: AnalyticsActionTypes.FETCH_CARD_OVERVIEW_DATA,
            cardOverviewData,
        }

        dispatch(fetchCardOverviewData)
        return cardOverviewData
    } catch (err) {
        if (err.response?.status === HttpStatus.UNPROCESSABLE_ENTITY) return
        throw err
    }
}

export const fetchExpensesPerEmployeeData = () => async (dispatch: Dispatch) => {
    const { globalCurrency } = UserService.getLoggedInEmployeeProfile().settings
    const employeeIds = EmployeeService.getEmployeeIds(true, true)
    const timeInterval = getStartAndEndDateOfInterval(AnalyticsService.getEmployeeInterval())

    const expensesPerEmployee = await AnalyticsService.fetchExpenseDataForGroup({
        groupingField: "requestedBy._id",
        ids: employeeIds,
        destCurrency: globalCurrency,
        timeInterval,
    })

    const fetchExpensesPerEmployeeData: FetchExpensesPerEmployeeDataAction = {
        type: AnalyticsActionTypes.FETCH_EXPENSES_PER_EMPLOYEE_DATA,
        expensesPerEmployee,
    }

    dispatch(fetchExpensesPerEmployeeData)
    return expensesPerEmployee
}

// Used for cost center expense progress bar chart
export const fetchCostCenterExpenseData = () => async (dispatch: Dispatch) => {
    const { globalCurrency } = UserService.getLoggedInEmployeeProfile().settings
    const costCenterIds = CostCenterService.getCostCenterIds(true, true)
    const timeInterval = getStartAndEndDateOfInterval(AnalyticsService.getCostCenterInterval())

    const costCenterExpenseData = await AnalyticsService.fetchExpenseDataForGroupByCostCenterSplits(costCenterIds, globalCurrency, timeInterval)

    const fetchCostCenterExpenseDataDispatch: FetchCostCenterExpenseDataAction = {
        type: AnalyticsActionTypes.FETCH_COST_CENTER_EXPENSE_DATA,
        costCenterExpenseData,
    }

    dispatch(fetchCostCenterExpenseDataDispatch)
    return costCenterExpenseData
}

// Used for cost center expense pie chart
export const fetchCostCenterExpenseDataPie = () => async (dispatch: Dispatch) => {
    const { globalCurrency } = UserService.getLoggedInEmployeeProfile().settings
    const costCenterIds = CostCenterService.getCostCenterIds(true, true)
    const timeInterval = getStartAndEndDateOfInterval(AnalyticsService.getCostCenterIntervalPie())

    const costCenterExpenseDataPie = await AnalyticsService.fetchExpenseDataForGroupByCostCenterSplits(costCenterIds, globalCurrency, timeInterval)

    const fetchCostCenterExpenseDataDispatch: FetchCostCenterExpenseDataPieAction = {
        type: AnalyticsActionTypes.FETCH_COST_CENTER_EXPENSE_DATA_PIE,
        costCenterExpenseDataPie,
    }

    dispatch(fetchCostCenterExpenseDataDispatch)
    return costCenterExpenseDataPie
}

export const fetchExpensesPerDepartmentDataPie = () => async (dispatch: Dispatch) => {
    const { globalCurrency } = UserService.getLoggedInEmployeeProfile().settings

    const teamIds = store.getState().teams.items?.map(({ _id }: Team) => _id)

    const timeInterval = getStartAndEndDateOfInterval(AnalyticsService.getExpensesPerDepartmentIntervalPie())

    const expensesPerDepartmentDataPie = await AnalyticsService.fetchExpenseDataForGroupByTeam(teamIds, globalCurrency, timeInterval)
    const fetchExpensesPerDepartmentDataPie: FetchExpensesPerDepartmentPie = {
        type: AnalyticsActionTypes.FETCH_EXPENSES_PER_DEPARTMENT_DATA_PIE,
        expensesPerDepartmentDataPie,
    }

    dispatch(fetchExpensesPerDepartmentDataPie)
    return expensesPerDepartmentDataPie
}

export const fetchExpensesPerCostCenterOverLast12MonthsData = () => async (dispatch: Dispatch) => {
    const loggedInProfile = UserService.getLoggedInEmployeeProfile()
    const filter = AnalyticsService.getExpensesPerCostCenterOverLast12MonthsFilter()
    const expensesPerCostCenterOverLast12MonthsData = await AnalyticsService.fetchExpensesPerCostCenterOverLast12Months(
        filter?.costCenters ?? CostCenterService.getCostCenterIds(true, true),
        loggedInProfile.settings.globalCurrency,
    )

    const fetchExpensesPerCostCenterOverLast12MonthsData: FetchExpensesPerCostCenterOverLast12MonthsDataAction = {
        type: AnalyticsActionTypes.FETCH_EXPENSES_PER_COST_CENTER_OVER_LAST_12_MONTHS_DATA,
        expensesPerCostCenterOverLast12MonthsData,
    }

    dispatch(fetchExpensesPerCostCenterOverLast12MonthsData)
    return expensesPerCostCenterOverLast12MonthsData
}

export const setExpensesPerCostCenterOverLast12MonthsData = (data: any) => async (dispatch: Dispatch) => {
    const fetchExpensesPerCostCenterOverLast12MonthsData: FetchExpensesPerCostCenterOverLast12MonthsDataAction = {
        type: AnalyticsActionTypes.FETCH_EXPENSES_PER_COST_CENTER_OVER_LAST_12_MONTHS_DATA,
        expensesPerCostCenterOverLast12MonthsData: data,
    }

    dispatch(fetchExpensesPerCostCenterOverLast12MonthsData)
    return data
}

export const fetchCostCenterBudgetData = () => async (dispatch: Dispatch) => {
    const loggedInProfile = UserService.getLoggedInEmployeeProfile()
    const costCenterBudgetData = await AnalyticsService.fetchCostCenterBudgetData(CostCenterService.getCostCenterIds(true, true), loggedInProfile.settings.globalCurrency)
    const fetchCostCenterBudgetData: FetchCostCenterBudgetDataAction = {
        type: AnalyticsActionTypes.FETCH_COST_CENTER_BUDGET_DATA,
        costCenterBudgetData,
    }

    dispatch(fetchCostCenterBudgetData)
    return costCenterBudgetData
}

// Set interval for cost center expense progress bar chart
export const setCostCenterInterval = (costCenterInterval: CalculationIntervalEnum) => async (dispatch: Dispatch) => {
    const setCostCenterInterval: SetCostCenterIntervalAction = {
        type: AnalyticsActionTypes.SET_COST_CENTER_INTERVAL,
        costCenterInterval,
    }
    dispatch(setCostCenterInterval)
}

// Set interval for cost center expense progress bar chart
export const setCostCenterIntervalPie = (costCenterIntervalPie: CalculationIntervalEnum) => async (dispatch: Dispatch) => {
    const setCostCenterIntervalPie: SetCostCenterIntervalPieAction = {
        type: AnalyticsActionTypes.SET_COST_CENTER_INTERVAL_PIE,
        costCenterIntervalPie,
    }
    dispatch(setCostCenterIntervalPie)
}

export const setEmployeeInterval = (employeeInterval: CalculationIntervalEnum) => async (dispatch: Dispatch) => {
    const setEmployeeInterval: SetEmployeeIntervalAction = {
        type: AnalyticsActionTypes.SET_EMPLOYEE_INTERVAL,
        employeeInterval,
    }
    dispatch(setEmployeeInterval)
}

export const setExpensesPerDepartmentIntervalPie = (expensesPerDepartmentIntervalPie: CalculationIntervalEnum) => async (dispatch: Dispatch) => {
    const setExpensesPerDepartmentIntervalPie = {
        type: AnalyticsActionTypes.SET_EXPENSES_PER_DEPARTMENT_INTERVAL_PIE,
        expensesPerDepartmentIntervalPie,
    }
    dispatch(setExpensesPerDepartmentIntervalPie)
}

export const setExpensesPerCostCenterOverLast12MonthsFilter = (expensesPerCostCenterOverLast12MonthsFilter: ReportingFilterInterface) => async (dispatch: Dispatch) => {
    const action = {
        type: AnalyticsActionTypes.SET_EXPENSES_PER_COST_CENTER_OVER_LAST_12_MONTHS_FILTER,
        expensesPerCostCenterOverLast12MonthsFilter,
    }
    dispatch(action)
}

export const setCostCenterBudgetUsageBarGraphOptions = (costCenterBudgetUsageBarGraphOptions: CostCenterBudgetUsageBarChartsOptions) => async (dispatch: Dispatch) => {
    const action = {
        type: AnalyticsActionTypes.SET_COST_CENTER_BUDGET_USAGE_BAR_GRAPH_OPTIONS,
        costCenterBudgetUsageBarGraphOptions,
    }
    dispatch(action)
}

export const resetCardOverviewData = () => async (dispatch: Dispatch) => {
    const action: ResetCardOverviewDataAction = {
        type: AnalyticsActionTypes.RESET_CARD_OVERVIEW_DATA,
    }
    dispatch(action)
}

export const fetchAllTeamsWithUsersCount = () => async (dispatch: Dispatch) => {
    const teams = await AnalyticsService.fetchTeams()

    const fetchAllTeams: FetchTeamsWithUsersCountAction = {
        type: AnalyticsActionTypes.FETCH_TEAMS_WITH_USERS_COUNT,
        teams,
    }

    dispatch(fetchAllTeams)
    return teams
}
