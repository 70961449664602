import { CostCenter, CostCenterTypeEnum, Employee, RightEnum } from "@finway-group/shared/lib/models"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

import i18n from "Shared/locales/i18n"
import { AuthzService, NotificationService, UserService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import store from "Shared/store"
import { RootState } from "Shared/store/rootState.interface"
import { findSearchResultParentCostCenters } from "Shared/utils/costCenter.utils"

import { useEmployees } from "./employee.hooks"

export const useCostCenters = (excludeDeleted: boolean = false, filtered: boolean = false, searchValue?: string) => {
    const costCenters = useSelector(({ costCenters }: RootState) => costCenters.items)
    const employees = useEmployees()
    return filterCostCenters(costCenters, employees, excludeDeleted, filtered, searchValue)
}

export const getCostCentersFromStore = (excludeDeleted: boolean = false, filtered: boolean = false, searchValue?: string) => {
    const costCenters = store.getState().costCenters.items
    const employees = store.getState().employees.items
    return filterCostCenters(costCenters, employees, excludeDeleted, filtered, searchValue)
}

const filterCostCenters = (costCenters: Array<any>, employees: Array<any>, excludeDeleted: boolean = false, filtered: boolean = false, searchValue?: string) => {
    const loggedInUser = UserService.getLoggedInEmployeeProfile()
    const allCostCenters = [...costCenters]

    if (excludeDeleted) {
        costCenters = costCenters?.filter((costCenter: CostCenter) => !costCenter.deleted)
    }

    if (filtered) {
        const ccIdMap = new Map<string, CostCenter>(costCenters.map((cc) => [cc._id, cc]))
        costCenters = costCenters.filter(
            (cc: CostCenter) =>
                // admin
                AuthzService.isRightGrantedForLoggedInUser(RightEnum.CC1__ALL__READ) ||
                // responsible user
                cc.responsibleUser === loggedInUser.id ||
                (cc.parentCostCenter && ccIdMap.get(cc.parentCostCenter)?.responsibleUser === loggedInUser.id) ||
                // cost center shared users (direct or parent)
                cc.sharedUsers?.includes(loggedInUser.id) ||
                (cc.parentCostCenter && ccIdMap.get(cc.parentCostCenter)?.sharedUsers?.includes(loggedInUser.id)),
        )
    }

    if (searchValue) {
        costCenters = costCenters.filter((costCenter: CostCenter) => {
            const employee = employees.find((e) => e.id === costCenter.responsibleUser)
            const searchables = [
                costCenter.name.toLowerCase(),
                ...(employee ? [employee?.firstName.toLowerCase(), employee?.lastName.toLowerCase()] : []),
                ...(costCenter.id ? [costCenter.id.toLocaleLowerCase()] : []),
            ]
            return searchables.join(" ").includes(searchValue.toLowerCase())
        })

        const searchResultParentCostCenters: Array<any> = findSearchResultParentCostCenters(costCenters).map((costCenterId: string) =>
            allCostCenters.find((costCenter: CostCenter) => costCenter._id === costCenterId),
        )
        costCenters.push(...searchResultParentCostCenters)
    }

    return costCenters.sort((a: CostCenter, b: CostCenter) => a.name.localeCompare(b.name))
}

export const useCostCenterIds = (excludeDeleted: boolean = false, filtered: boolean = false) => useCostCenters(excludeDeleted, filtered).map((cc: CostCenter) => cc._id)

export const useSubCostCenterIds = (excludeDeleted: boolean = false, filtered: boolean = false) => useSubCostCenters(excludeDeleted, filtered).map((cc: CostCenter) => cc._id)

export const useCostCenterById = (id?: string, excludeDeleted: boolean = false) => useCostCenters(excludeDeleted).find((cc: CostCenter) => cc._id === id)

export const useCostCenter = () => useSelector(({ costCenters }: RootState) => costCenters.item)

export const useCostCenterGroups = (excludeDeleted: boolean = false, filtered: boolean = false, searchValue?: string) =>
    useCostCenters(excludeDeleted, filtered, searchValue).filter((costCenter: CostCenter) => costCenter.costCenterType === CostCenterTypeEnum.COST_CENTER_GROUP)

export const useSubCostCenters = (excludeDeleted: boolean = false, filtered: boolean = false, searchValue?: string) =>
    useCostCenters(excludeDeleted, filtered, searchValue).filter((costCenter: CostCenter) => costCenter.costCenterType === CostCenterTypeEnum.SUB_COST_CENTER)

export const useCostCenterAuthorizedGuard = (id: string, costCenter: CostCenter | undefined, parentCostCenter: CostCenter, loggedInUser: Employee) => {
    const history = useHistory()

    useEffect(() => {
        if (
            !AuthzService.isRightGrantedForLoggedInUser(RightEnum.CC1__ALL__READ) &&
            !(costCenter?.responsibleUser === loggedInUser.id) &&
            !parentCostCenter?.sharedUsers?.includes(loggedInUser.id) &&
            !costCenter?.sharedUsers?.includes(loggedInUser.id)
        ) {
            NotificationService.send(NotificationTypeEnum.WARNING, i18n.t("error:auth.title"), i18n.t("error:unauthorized_action"))
            history.push("/costCenters")
        }
    }, [id, loggedInUser, costCenter])
}
