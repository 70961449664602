import Icon from "@ant-design/icons"
import React from "react"

const newPurchase = () => (
    <svg data-testid="sideBarPurchaseRequestButton" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="32" height="32" rx="16" fill="#F0FEFA" />
        <path
            d="M12.4961 9.3335C12.1813 9.3335 11.8849 9.48169 11.6961 9.7335L10.5891 11.2095C10.3913 11.4732 10.5795 11.8495 10.9091 11.8495H21.0831C21.4127 11.8495 21.6009 11.4732 21.4031 11.2095L20.2961 9.7335C20.1072 9.48169 19.8109 9.3335 19.4961 9.3335H12.4961Z"
            fill="#60CDB2"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.99609 21.3335V13.0495C9.99609 12.8286 10.1752 12.6495 10.3961 12.6495H21.5961C21.817 12.6495 21.9961 12.8286 21.9961 13.0495V21.3335C21.9961 21.6871 21.8556 22.0263 21.6056 22.2763C21.3555 22.5264 21.0164 22.6668 20.6628 22.6668H11.3294C10.9758 22.6668 10.6367 22.5264 10.3866 22.2763C10.1366 22.0263 9.99609 21.6871 9.99609 21.3335ZM13.682 14.666C13.682 14.4727 13.5253 14.316 13.332 14.316C13.1387 14.316 12.982 14.4727 12.982 14.666C12.982 15.4661 13.2999 16.2334 13.8656 16.7991C14.4313 17.3649 15.1986 17.6827 15.9987 17.6827C16.7988 17.6827 17.5661 17.3649 18.1318 16.7991C18.6975 16.2334 19.0154 15.4661 19.0154 14.666C19.0154 14.4727 18.8587 14.316 18.6654 14.316C18.4721 14.316 18.3154 14.4727 18.3154 14.666C18.3154 15.2804 18.0713 15.8697 17.6368 16.3041C17.2024 16.7386 16.6131 16.9827 15.9987 16.9827C15.3843 16.9827 14.795 16.7386 14.3606 16.3041C13.9261 15.8697 13.682 15.2804 13.682 14.666Z"
            fill="#60CDB2"
        />
    </svg>
)

const NewPurchaseIcon = (props: any) => <Icon component={newPurchase} {...props} />

export default NewPurchaseIcon
