import { Expense, ExpenseKindEnum, TripFolder } from "@finway-group/shared/lib/models"
import { ExpensePaymentOptionEnum } from "@finway-group/shared/lib/models/expense/expensePaymentOption.enum"
import { getApproverIdsOfApprovalProcesses } from "@finway-group/shared/lib/utils/approvalProcess.utils"
import { Tag, Tooltip } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"

import { useCompany } from "Shared/hooks/company.hooks"
import { useCostCenterById } from "Shared/hooks/costCenter.hooks"
import { ApprovalProcessService, EmployeeService, ExpenseService, NotificationService, UserService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { getReimbursementLabel } from "Shared/utils/expense.utils"

import UserImage from "./UserImage"
import ExpenseTagColumn from "./expenseTagColumn"
import { FolderIcon } from "./icons"
import SharedExpenseTag from "./sharedExpenseTag"

interface RequestColumnWithAvatarInterface {
    request: Expense
    showExpenseNumbers?: boolean
}

const RequestColumnWithAvatar = ({ request, showExpenseNumbers = false }: RequestColumnWithAvatarInterface) => {
    const { t } = useTranslation()

    const loggedInProfile = UserService.getLoggedInEmployeeProfile()
    const nextApprover = ApprovalProcessService.getNextApprover(request.approvalProcesses)
    const requester = EmployeeService.getEmployeeById(request.requestedBy._id)
    const isMyExpense = request.requestedBy._id === loggedInProfile.id
    const isTripFolder = request.kind === ExpenseKindEnum.TRIP_FOLDER
    const costCenter = useCostCenterById(request.costCenter)
    const creditor = request.getCreditor()
    const { taggingEnabled } = useCompany()

    const isApproverOfAnyApprovalProcess = getApproverIdsOfApprovalProcesses(request.approvalProcesses).includes(loggedInProfile.id)
    const displayUserAsTagged = ExpenseService.shouldDisplayUserAsTagged(isMyExpense, isApproverOfAnyApprovalProcess, loggedInProfile, request)

    const employeeToShow = isMyExpense ? nextApprover ?? requester : requester ?? nextApprover
    if (!employeeToShow) {
        NotificationService.send(NotificationTypeEnum.WARNING, t("error:error"), t("error:employee.missing"))
        return <></>
    }

    const TripFolderDescription = () => (
        <p className="flex items-center gap-6">
            <FolderIcon />
            <span>{showExpenseNumbers && `#${request.expenseNumber}`}</span>
            <span className="tracking-wide">{(request as TripFolder).folderName}</span>
        </p>
    )

    return (
        <div className="flex image-loader gap-10 flex-no-wrap">
            <UserImage user={employeeToShow} size="large" />
            <div className="flex flex-col mr-5 overflow-auto no-scrollbar">
                <p className="text-text-dark font-bold mt-3 mb-3 leading-none flex">
                    <span className="truncate">
                        {`${employeeToShow.getFullName()}`} {isMyExpense && `(${t("label:approver")})`}
                    </span>
                    {isTripFolder && <Tag className="ant-tag-blue ml-10">{t(`label:trip_folder`)}</Tag>}
                    {displayUserAsTagged && <SharedExpenseTag tagging={request.userTagging?.filter(({ tagged }) => tagged === loggedInProfile.id)} />}
                </p>
                <div className="flex">
                    <div className="text-sm leading-tight truncate min-w-40">
                        {isTripFolder && <TripFolderDescription />}
                        {!isTripFolder && (
                            <>
                                {showExpenseNumbers && `# ${request.expenseNumber}${request.description || request.isAutoGenerated ? " - " : ""}`} {creditor?.name || "n/a"} (
                                {costCenter?.name?.trim() || t("label:n/a")})
                            </>
                        )}
                    </div>

                    {request.kind === ExpenseKindEnum.SUBSCRIPTION && <Tag className="ant-tag-blue ml-10">{t("label:recurring")}</Tag>}
                    {request.isReimbursement && (
                        <Tooltip title={t(`tooltips:reimbursement_types.${getReimbursementLabel(request.kind)}`)}>
                            <Tag className="ant-tag-blue ml-10">{t(`label:reimbursement_types.${getReimbursementLabel(request.kind)}`)}</Tag>{" "}
                        </Tooltip>
                    )}
                    {request.paymentOption === ExpensePaymentOptionEnum.SMART_CARD && <Tag className="ml-10 ant-tag-blue">{t("label:cards.card")}</Tag>}
                    {(request.totalNetPrice < 0 || request.totalGrossPrice < 0) && <Tag className="ml-10 ant-tag-green">{t("label:refund")}</Tag>}
                    {taggingEnabled && request.expenseTags && (
                        <div className="ml-10 h-14">
                            <ExpenseTagColumn expenseTagIds={request.expenseTags} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default React.memo(RequestColumnWithAvatar)
