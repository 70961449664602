import { CurrencyEnum } from "@finway-group/shared/lib/models"
import { isAllowedMultiLineCharsOnly } from "@finway-group/shared/lib/utils/validators"
import { FormInstance, Rule } from "antd/lib/form"
import { Moment } from "moment-timezone"

import { parseCurrencyInput } from "Components/currencyInput/config"
import i18n from "Shared/locales/i18n"
import { CurrencyService } from "Shared/services"
import { formatCurrencyNumber } from "Shared/utils/helper.utils"

interface NumberOptions {
    arePositiveNumbersAllowed: boolean
    areNegativeNumbersAllowed: boolean
}

export const checkMinDate = (formInstance: FormInstance, value: Moment, name: string, minText = "", maxText = "") => {
    const maxDate = formInstance.getFieldValue(name)

    if (maxDate && value && value.toDate() > maxDate) {
        return Promise.reject(
            i18n.t("validation:date_before", {
                attribute: i18n.t(minText || "input:filter_form.starting_date"),
                compare: i18n.t(maxText || "input:filter_form.ending_date"),
            }),
        )
    }

    return Promise.resolve()
}

export const checkMaxDate = (formInstance: FormInstance, value: Moment, name: string, minText = "", maxText = "") => {
    const minDate = formInstance.getFieldValue(name)

    if (minDate && value && value.toDate() < minDate) {
        return Promise.reject(
            i18n.t("validation:date_after", {
                attribute: i18n.t(minText || "input:filter_form.ending_date"),
                compare: i18n.t(maxText || "input:filter_form.starting_date"),
            }),
        )
    }

    return Promise.resolve()
}

export const checkMinNumber = ({
    formInstance,
    value,
    name,
    minText = "",
    maxText = "",
    limit = undefined,
    numberOptions = { arePositiveNumbersAllowed: true, areNegativeNumbersAllowed: false },
}: {
    formInstance: FormInstance
    value: any
    name: string
    minText?: string
    maxText?: string
    limit?: number
    numberOptions?: NumberOptions
}) => {
    value = parseAmountFilterValue(value)
    if (value || value === 0) {
        const maxAmount = formInstance.getFieldValue(name)
        if (isNegativeAmountWhenOnlyPositiveAllowed(numberOptions, value)) return Promise.reject(new Error(i18n.t("validation:min.number", { min: 0 })))
        if (isPositiveAmountWhenOnlyNegativeAllowed(numberOptions, value)) return Promise.reject(new Error(i18n.t("validation:min.number_negative", { min: 0 })))
        if (limit && value > limit) return Promise.reject(new Error(i18n.t("validation:max.number", { max: limit })))
        if (maxAmount && value > Number(maxAmount)) {
            return Promise.reject(
                i18n.t("validation:bigger_amount", {
                    attribute: i18n.t(minText || "input:filter_form.min_amount"),
                    compare: i18n.t(maxText || "input:filter_form.max_amount"),
                }),
            )
        }
    }
    return Promise.resolve()
}

export const checkMaxNumber = ({
    formInstance,
    value,
    name,
    minText = "",
    maxText = "",
    limit = undefined,
    numberOptions = { arePositiveNumbersAllowed: true, areNegativeNumbersAllowed: false },
}: {
    formInstance: FormInstance
    value: any
    name: string
    minText?: string
    maxText?: string
    limit?: number
    numberOptions?: NumberOptions
}) => {
    value = parseAmountFilterValue(value)
    if (value || value === 0) {
        const minAmount = formInstance.getFieldValue(name)
        if (isNegativeAmountWhenOnlyPositiveAllowed(numberOptions, value)) return Promise.reject(new Error(i18n.t("validation:min.number", { min: 0 })))
        if (isPositiveAmountWhenOnlyNegativeAllowed(numberOptions, value)) return Promise.reject(new Error(i18n.t("validation:min.number_negative", { min: 0 })))
        if (limit && value > limit) return Promise.reject(new Error(i18n.t("validation:max.number", { max: limit })))
        if (minAmount && value < Number(minAmount)) {
            return Promise.reject(
                i18n.t("validation:smaller_amount", {
                    attribute: i18n.t(minText || "input:filter_form.min_amount"),
                    compare: i18n.t(maxText || "input:filter_form.max_amount"),
                }),
            )
        }
    }
    return Promise.resolve()
}

const parseAmountFilterValue = (value: any) => (value !== "" ? Number(value) : value)

const isNegativeAmountWhenOnlyPositiveAllowed = (options: NumberOptions, value: number): boolean =>
    options.arePositiveNumbersAllowed && !options.areNegativeNumbersAllowed && value < 0
const isPositiveAmountWhenOnlyNegativeAllowed = (options: NumberOptions, value: number): boolean =>
    !options.arePositiveNumbersAllowed && options.areNegativeNumbersAllowed && value >= 0

export const checkDueInDays = (value: any) => {
    if (value !== "undefined" && Number(value) < 0) return Promise.reject(new Error(i18n.t("validation:min.number", { min: 0 })))

    return Promise.resolve()
}

export const allowlistCharValidator = {
    validator: (_rule: any, value: string) => {
        if (!value) return Promise.resolve()
        if (!isAllowedMultiLineCharsOnly(value)) return Promise.reject(new Error(i18n.t("validation:invalid_characters")))
        return Promise.resolve()
    },
}

export const isWithinCurrencyLimit = (currency: CurrencyEnum): Rule => ({
    validator: (_rule: any, inputValue: string) => {
        const value = parseCurrencyInput(inputValue)

        const maxValue = CurrencyService.getMaxInputValueByCurrency(currency)
        const minValue = -maxValue
        if (value > maxValue) return Promise.reject(new Error(i18n.t("validation:max.number", { max: formatCurrencyNumber(maxValue, currency, false, undefined, 7) })))
        if (value < minValue) return Promise.reject(new Error(i18n.t("validation:min.number_very_short", { min: formatCurrencyNumber(minValue, currency, false, undefined, 7) })))
        return Promise.resolve()
    },
})
