import { CostCenter2 } from "@finway-group/shared/lib/models"
import axios from "axios"

import store from "Shared/store"

const MODEL = "costcenters2"

const CostCenter2Service = {
    fetchCostCenters2: async () => {
        const { data } = await axios.get(`/${MODEL}`)
        return data as Array<CostCenter2>
    },
    updateCostCenters2: async (updateData: Array<CostCenter2>) => {
        const { data } = await axios.put(`${MODEL}`, { costCenters2: updateData })
        return data as Array<CostCenter2>
    },
    deleteCostCenters2: async (deleteData: Array<CostCenter2>) => {
        const { data } = await axios.post(`${MODEL}/delete-many`, { costCenters2: deleteData })
        return data as Array<CostCenter2>
    },
    createCostCenters2: async (insertData: Array<CostCenter2>) => {
        const { data } = await axios.post(`${MODEL}/create-many`, { costCenters2: insertData })
        return data as Array<CostCenter2>
    },
    getCostCenter2ById: (id: string | undefined) => {
        const costCenters2 = store.getState().costCenters2.items
        return costCenters2.find((acc: CostCenter2) => acc._id === id)
    },
    create: async (costCenter2: CostCenter2) => {
        const { data } = await axios.post(`${MODEL}`, costCenter2)
        return data as CostCenter2
    },
}

export default CostCenter2Service
