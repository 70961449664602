export enum DragModalTypeEnum {
    small = "ant-modal--small",
    large = "ant-modal--large",
    full = "ant-modal--full",
}

export const dragElement = (element: HTMLElement, elementId: string, modalType: DragModalTypeEnum) => {
    let pos1 = 0
    let pos2 = 0
    let pos3 = 0
    let pos4 = 0

    const dragMouseDown = (e: DragEvent) => {
        e = e || window.event
        e.preventDefault()
        // get the mouse cursor position at startup:
        pos3 = e.clientX
        pos4 = e.clientY
        document.onmouseup = closeDragElement
        // call a function whenever the cursor moves:
        document.onmousemove = elementDrag
    }

    const header = document.getElementById(elementId)?.parentNode?.parentNode as HTMLElement

    if (header) {
        // add cursor move to header
        header.classList.add("cursor-move")
        header.onmousedown = dragMouseDown

        // center element in the center since absolute mess up the position
        element.style.left = `${window.innerWidth / 2 - element.clientWidth / 2}px`
    } else {
        // otherwise, move the DIV from anywhere inside the DIV:
        element.onmousedown = dragMouseDown
    }

    const elementDrag = (e: DragEvent) => {
        e = e || window.event
        e.preventDefault()
        // calculate the new cursor position:
        pos1 = pos3 - e.clientX
        pos2 = pos4 - e.clientY
        pos3 = e.clientX
        pos4 = e.clientY

        // set the element's new position:
        const modals = document.getElementsByClassName("ant-modal--small")
        for (let i = 0; i < modals.length; i++) (modals[i] as HTMLElement).style.setProperty("top", `${element.offsetTop - pos2}px`, "important")

        element.style.left = `${element.offsetLeft - pos1}px`
    }

    function closeDragElement() {
        // stop moving when mouse button is released:
        document.onmouseup = null
        document.onmousemove = null
    }
}
