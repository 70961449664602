import * as HttpStatus from "http-status"
import React, { useEffect } from "react"

import { LOGO_API } from "Shared/config/consts"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

interface LogoFinderInterface {
    name: string
    fallback: any
}

const LogoFinder = ({ name, fallback }: LogoFinderInterface) => {
    const [isLoading, setIsLoading] = useStateIfMounted(false)
    const [logo, setLogo] = useStateIfMounted("")

    const domains = ["com", "de", "fr"]
    useEffect(() => {
        const fetchLogoLink = async () => {
            try {
                const promises = domains.map((domain: string) => fetch(`${LOGO_API}/${name.trim()}.${domain}`))
                const foundLinks = (await Promise.all(promises)).filter((data: any) => data && data.status !== HttpStatus.NOT_FOUND).map((data: any) => data.url)
                if (foundLinks.length > 0) setLogo(foundLinks[0])
            } catch (error) {}
        }

        fetchLogoLink()
    }, [])

    return (
        <div className="w-full h-full max-w-50 max-h-50 border justify-center align-center self-center items-center mr-10 p-2 rounded-lg">
            {logo && logo.length ? (
                <img className="w-full h-auto max-w-50 max-h-50  flex justify-center items-center" src={logo} alt="logo" />
            ) : (
                <span className="placeholder-primary text-xl h-44 max-w-50 flex justify-center items-center">{fallback}</span>
            )}
        </div>
    )
}

export default React.memo(LogoFinder)
