import { CardStatusEnum } from "@finway-group/shared/lib/models"
import { Alert, Button } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"

import Loading from "Components/loading"
import { useEmployeeById } from "Shared/hooks/employee.hooks"
import { useLoggedInEmployeeProfile } from "Shared/hooks/user.hooks"
import { AuthzService, NotificationService } from "Shared/services"
import CardService from "Shared/services/card.service"
import DialogService from "Shared/services/dialog.service"
import { NotificationTypeEnum } from "Shared/services/notification.service"

interface CardBannerInterface {
    card: any
    isRetrying: boolean
    onRetryCardCreation: (e: any) => void
    setShowCardActivationModal: (flag: boolean) => void
}

const CardBanner: React.FC<CardBannerInterface> = ({ card, isRetrying, onRetryCardCreation, setShowCardActivationModal }) => {
    const { t } = useTranslation()
    const loggedInUser = useLoggedInEmployeeProfile()
    const isMyCard = card.user === loggedInUser.id
    const user = useEmployeeById(card?.user)

    const shouldShowBanner = AuthzService.canManageCardForUser(user) || isMyCard

    const onActivate = async () => {
        if (!(await DialogService.confirmPhysicalCardDelivery())) return

        setShowCardActivationModal(true)
    }

    const onResendVerificationCode = async () => {
        try {
            await CardService.resendCardActivationEmail(card._id)
            NotificationService.send(
                NotificationTypeEnum.SUCCESS,
                t("notification:cards.verification_email_resent.title"),
                t("notification:cards.verification_email_resent.message"),
            )
        } catch (err) {
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:error"))
        }
    }

    return (
        <>
            {shouldShowBanner &&
                (card.errorCode && card.status !== CardStatusEnum.TERMINATED ? (
                    isRetrying ? (
                        <Loading className="mt-4 h-60" />
                    ) : (
                        <Alert
                            type="error"
                            showIcon
                            className="mb-20"
                            message={
                                <>
                                    <span>{t("error:cards.something_went_wrong")}</span>
                                    <Button className="button-warning-notification ml-4" type="text" onClick={onRetryCardCreation}>
                                        {t("action:cards.or_retry")}
                                    </Button>
                                </>
                            }
                        />
                    )
                ) : !card.cardholderPhoneNumber ? (
                    <Alert
                        type="warning"
                        showIcon
                        className="mb-20"
                        message={
                            <>
                                <span>{t("missing:cards.no_phone_number")}</span>
                            </>
                        }
                    />
                ) : (
                    isMyCard &&
                    card.status === CardStatusEnum.UNACTIVATED && (
                        <Alert
                            className="mb-20"
                            message={t("notification:cards.activation_request")}
                            type="warning"
                            description={
                                <span>
                                    {t("notification:cards.click")}
                                    <Button className="button-warning-notification ml-4 mr-4 pb-4" onClick={onActivate} type="text">
                                        {t("notification:cards.pin_link")}
                                    </Button>
                                    {t("label:or")}
                                    <Button className="button-warning-notification ml-4 pb-4" onClick={onResendVerificationCode} type="text">
                                        {t("notification:cards.pin_resend_email")}
                                    </Button>
                                </span>
                            }
                            closable
                            showIcon
                        />
                    )
                ))}
            {shouldShowBanner && card.pinBlocked && (
                <Alert
                    type="error"
                    showIcon
                    className="mb-20"
                    message={
                        <>
                            <span>{t("info:cards.pin_blocked")}</span>
                        </>
                    }
                />
            )}
        </>
    )
}

export default CardBanner
