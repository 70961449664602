import { LoginInput } from "@finway-group/shared/lib/models"
import { Button, Form } from "antd"
import { Store } from "antd/lib/form/interface"
import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"

import PasswordForm from "Components/forms/password.form"
import { useAuth } from "Shared/hooks/auth.hooks"
import { NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { ThunkDispatchResult } from "Shared/store"
import { updatePassword } from "Shared/store/actions/auth/authActions"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

import AuthLayout from "./auth.layout"

const NewPasswordPage = () => {
    const dispatch: ThunkDispatchResult = useDispatch()
    const history = useHistory()

    const { userClass } = useAuth()
    const [loading, setLoading] = useStateIfMounted(false)
    const { t } = useTranslation()

    const [formInstance] = Form.useForm()

    const handleSetNewPassword = async ({ password }: Store) => {
        if (userClass) {
            const loginInput: LoginInput = {
                email: userClass.email,
                password,
            }

            try {
                setLoading(true)
                await dispatch(updatePassword(loginInput))
                history.push("/")

                NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:password_set.title"), t("notification:password_set.message"))
            } catch (err) {
                NotificationService.showErrorNotificationBasedOnResponseError(err, `Error`)
            } finally {
                setLoading(false)
            }
        }
    }

    return (
        <AuthLayout>
            <PasswordForm formInstance={formInstance} onSubmit={handleSetNewPassword} />
            <Button size="large" className="w-full rounded-lg font-medium bg-login-button" type="primary" loading={loading} onClick={formInstance.submit}>
                {t("action:change_password")}
            </Button>
        </AuthLayout>
    )
}

export default NewPasswordPage
