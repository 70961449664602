import { Button } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import { useAuth } from "Shared/hooks/auth.hooks"
import { RoutePathEnum } from "Shared/router/RoutePath.enum"
import { isDev } from "Shared/utils/helper.utils"

interface ErrorPageInterface {
    error?: any
    componentStack?: string
    resetError?: any
}
export const ErrorPage = ({ error, componentStack, resetError }: ErrorPageInterface) => {
    const { t } = useTranslation()
    const { isAuthenticated, logout } = useAuth()
    const history = useHistory()

    const onReturnToDashboardClick = () => {
        history.push(RoutePathEnum.EXPENSES)
        resetError?.()
    }

    const onLogoutClick = async () => {
        if (isAuthenticated) await logout()
        history.push(RoutePathEnum.LOGIN)
        resetError?.()
    }

    return (
        <>
            <div className="text-center">
                <h2 className="p-0">{t("view:error.unknown.title")}</h2>
                <p className="mb-20">{t("view:error.unknown.message")}</p>

                {isAuthenticated && (
                    <Button className="m-5" type="primary" onClick={() => onReturnToDashboardClick()}>
                        {t("view:error.button.return")}
                    </Button>
                )}

                <Button className="m-5" type="primary" onClick={() => onLogoutClick()}>
                    {t("label:logout")}
                </Button>
            </div>

            {isDev && error && componentStack && (
                <div className="text-left flex flex-col">
                    <div>
                        <b>Error: </b> {error.toString()}
                    </div>
                    <div>
                        <b>Stack: </b> {componentStack}
                    </div>
                </div>
            )}
        </>
    )
}

export default React.memo(ErrorPage)
