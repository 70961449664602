import { InboxInvoice } from "@finway-group/shared/lib/models"
import { ActionCreator, Dispatch } from "redux"

import { getTableFilterQueryStringStore, getTableSearchQueryStringStore } from "Shared/hooks/table.hooks"
import InboxInvoiceService from "Shared/services/inboxInvoice.service"
import { ThunkResult } from "Shared/store"
import { refetchInboxInvoiceTables, refetchTable } from "Shared/store/actions/tables/tableActions"
import { TablesEnum } from "Shared/store/reducers/tableConfigReducer"

import {
    DeleteManyInboxInvoicesAction,
    DiscardInboxInvoiceAction,
    FetchAllInboxInvoicesAction,
    FetchInboxInvoicesCountAction,
    FetchOneInboxInvoiceAction,
    InboxInvoiceActionTypes,
    MoveOneInboxInvoiceAction,
    NewInboxInvoiceAction,
    SetShouldRefetchInboxAction,
    SyncOneInboxInvoiceAction,
} from "./inboxInvoiceTypes"

export const setInboxInvoice: ActionCreator<ThunkResult<void>> = (inboxInvoice: InboxInvoice) => async (dispatch: Dispatch) => {
    const fetchOneInboxInvoiceAction: FetchOneInboxInvoiceAction = {
        type: InboxInvoiceActionTypes.FETCH_ONE_INBOX_INVOICE,
        inboxInvoice,
    }

    dispatch(fetchOneInboxInvoiceAction)
}

// prettier-ignore
export const fetchAllInboxInvoices = (sortingCriteria = '', page = 1, limit = 20, append = false) =>
    async (dispatch: Dispatch) => {
        const { inboxInvoices, totalPages, totalDocs } = await InboxInvoiceService.fetchAllInboxInvoices(sortingCriteria, page, limit);

        const fetchAllInboxInvoicesAction: FetchAllInboxInvoicesAction = {
            type: InboxInvoiceActionTypes.FETCH_ALL_INBOX_INVOICES,
            inboxInvoices,
            totalPages,
            totalDocs,
            append,
        };

        dispatch(fetchAllInboxInvoicesAction);

        // reset refetch flag
        setShouldRefetchInbox(false)(dispatch);

        return inboxInvoices;
    };

export const fetchDashboardInboxInvoicesCount: ActionCreator<ThunkResult<void>> = () => async (dispatch: Dispatch) => {
    const filterQueryString = getTableFilterQueryStringStore(TablesEnum.INBOX_INVOICE)
    const searchQueryString = getTableSearchQueryStringStore(TablesEnum.INBOX_INVOICE)

    const count = await InboxInvoiceService.fetchInboxInvoicesCount(filterQueryString + searchQueryString)

    const fetchInboxInvoicesCount: FetchInboxInvoicesCountAction = {
        type: InboxInvoiceActionTypes.FETCH_INBOX_INVOICES_COUNT,
        count,
    }

    dispatch(fetchInboxInvoicesCount)
    return count
}

export const fetchOneInboxInvoice: ActionCreator<ThunkResult<InboxInvoice>> = (id: string) => async (dispatch: Dispatch) => {
    const inboxInvoice = await InboxInvoiceService.fetchOneInboxInvoice(id)
    const fetchOneInboxInvoiceAction: FetchOneInboxInvoiceAction = {
        type: InboxInvoiceActionTypes.FETCH_ONE_INBOX_INVOICE,
        inboxInvoice,
    }

    dispatch(fetchOneInboxInvoiceAction)
    return inboxInvoice
}

export const createInboxInvoice = (createInboxInvoice: any) => async (dispatch: Dispatch<any>) => {
    const inboxInvoice = await InboxInvoiceService.createInboxInvoice(createInboxInvoice)
    const newInboxInvoiceAction: NewInboxInvoiceAction = {
        type: InboxInvoiceActionTypes.NEW_INBOX_INVOICE,
        inboxInvoice,
    }

    dispatch(newInboxInvoiceAction)
    setShouldRefetchInbox(true)(dispatch)
    dispatch(refetchTable(TablesEnum.INBOX_INVOICE))
    dispatch(fetchDashboardInboxInvoicesCount())
    return inboxInvoice
}

export const discardInboxInvoice = (discardedInboxInvoice: InboxInvoice) => async (dispatch: Dispatch<any>) => {
    await InboxInvoiceService.discardInboxInvoice(discardedInboxInvoice.id)
    const discardInboxInvoiceAction: DiscardInboxInvoiceAction = {
        type: InboxInvoiceActionTypes.DISCARD_INBOX_INVOICE,
        inboxInvoice: discardedInboxInvoice,
    }

    dispatch(discardInboxInvoiceAction)
    dispatch(fetchDashboardInboxInvoicesCount())
    dispatch(refetchInboxInvoiceTables())

    // refetches the table
    setShouldRefetchInbox(true)(dispatch)
}

export const deleteManyInboxInvoices = (ids: Array<string>) => async (dispatch: Dispatch<any>) => {
    await InboxInvoiceService.deleteManyInboxInvoices(ids)
    const deleteManyInboxInvoicesAction: DeleteManyInboxInvoicesAction = {
        type: InboxInvoiceActionTypes.DELETE_MANY_INBOX_INVOICES,
        inboxInvoiceIds: ids,
    }

    dispatch(deleteManyInboxInvoicesAction)
    dispatch(fetchDashboardInboxInvoicesCount())
    dispatch(refetchInboxInvoiceTables())

    // refetches the table
    setShouldRefetchInbox(true)(dispatch)
}

export const syncInvoiceInbox = (id: string) => async (dispatch: Dispatch) => {
    const inboxInvoice = await InboxInvoiceService.fetchOneInboxInvoice(id)
    const syncOneInboxInvoiceAction: SyncOneInboxInvoiceAction = {
        type: InboxInvoiceActionTypes.SYNC_ONE_INBOX_INVOICE,
        inboxInvoice,
    }

    dispatch(syncOneInboxInvoiceAction)

    return inboxInvoice
}

export const setShouldRefetchInbox = (shouldRefetch: boolean) => async (dispatch: Dispatch) => {
    const setShouldRefetchAction: SetShouldRefetchInboxAction = {
        type: InboxInvoiceActionTypes.SET_SHOULD_REFETCH_INBOX,
        shouldRefetch,
    }

    dispatch(setShouldRefetchAction)
}

export const moveOneInboxInvoice = (id: string, newInvoice: InboxInvoice) => async (dispatch: Dispatch) => {
    const data = await InboxInvoiceService.updateOne(id, newInvoice)

    const moveOneInvoiceInboxAction: MoveOneInboxInvoiceAction = {
        type: InboxInvoiceActionTypes.MOVE_ONE_INBOX_INVOICE,
        inboxInvoiceId: id,
    }

    dispatch(moveOneInvoiceInboxAction)

    return data
}
