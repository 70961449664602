import {
    BillingPeriodEnum,
    CommentItem,
    Company,
    CostCenter,
    CreditorInterface,
    CurrencyEnum,
    Employee,
    Expense,
    ExpenseKindEnum,
    ExpenseReminder,
    ExpenseSplit,
    ExpenseStatusEnum,
    ExportFormatEnum,
    ExtendedReferencedUser,
    ExtendedReferencedVendor,
    FinApiPayment,
    FinApiPaymentStatusEnum,
    Hospitality,
    InvoiceInterface,
    InvoiceSplit,
    ItemSplit,
    Mileage,
    PerDiem,
    PerDiemDestination,
    PriceIntervalEnum,
    PriceSourceEnum,
    RightEnum,
    Role,
    SplitTypeEnum,
    SubscriptionTypeEnum,
    Tax,
    Transaction,
    TransactionTypeEnum,
    TripFolder,
    User,
    UserRule,
    Vendor,
    VendorTypeEnum,
    WeavrAuthorizationTypesEnum,
    WeavrSettlementTypesEnum,
} from "@finway-group/shared/lib/models"
import { AutoGeneratedCommentsEnum } from "@finway-group/shared/lib/models/comment/autoGeneratedComments.enum"
import { ExpensePaymentFlowInformationEnum } from "@finway-group/shared/lib/models/expense/expensePaymentFlowInformation.enum"
import { ExpensePaymentOptionEnum } from "@finway-group/shared/lib/models/expense/expensePaymentOption.enum"
import { TransactionStatusEnum } from "@finway-group/shared/lib/models/transaction/transactionStatus.enum"
import { GetEmployeeById } from "@finway-group/shared/lib/models/user/employee.model"
import { roundNumberTo2Decimals, toAmount, toDinero } from "@finway-group/shared/lib/utils"
import { getApproverIdsOfApprovalProcesses } from "@finway-group/shared/lib/utils/approvalProcess.utils"
import { isDiscountExpired } from "@finway-group/shared/lib/utils/expense.discount.utils"
import { calculateNetPriceFromGrossPriceAndTaxRate } from "@finway-group/shared/lib/utils/expense.utils"
import { Store } from "antd/lib/form/interface"
import dinero from "dinero.js"
import { TFunction } from "i18next"
import moment from "moment"
import React from "react"
import { RouterChildContext } from "react-router-dom"

import { parseCurrencyInput } from "Components/currencyInput/config"
import { DONE_STATUSES, EXPENSE_KINDS_WITHOUT_INVOICE, FINWAY_ADMIN_EMAIL, NOT_SET_VALUE, NO_TAX_RATE } from "Shared/config/consts"
import i18n from "Shared/locales/i18n"
import { getNextApproverId } from "Shared/utils/approvalProcess.utils"
import { getFolderProgressionInfo } from "Shared/utils/folder.utils"
import { getUniqueElements, isEmptyObject, isNotSet, isRightGranted } from "Shared/utils/helper.utils"

import { UpdatedOcrObject } from "./ocr.utils.types"

export const sortExpenses = (expenses: Array<Expense>, type: "ASC" | "DESC" = "DESC") =>
    expenses.sort((expA, expB) => {
        if (type === "ASC") {
            return new Date(expA.updatedAt).getTime() - new Date(expB.updatedAt).getTime()
        }

        return new Date(expB.updatedAt).getTime() - new Date(expA.updatedAt).getTime()
    })

export const needsDocs = (expense: Expense) => expense.status === ExpenseStatusEnum.DOCS_NEEDED

export const isPendingExpense = (expense: Expense) => expense.status === ExpenseStatusEnum.PURCHASE_PENDING || expense.status === ExpenseStatusEnum.INVOICE_PENDING

export const getExpensesDocsNeeded = (expenses: Array<Expense>) => sortExpenses(expenses.filter((exp) => needsDocs(exp)))

export const getPriceIntervalForBillingPeriod = (billingPeriod: number) => {
    switch (billingPeriod) {
        case BillingPeriodEnum.QUARTERLY:
            return PriceIntervalEnum.QUARTERLY
        case BillingPeriodEnum.YEARLY:
            return PriceIntervalEnum.YEARLY
        default:
            return PriceIntervalEnum.MONTHLY
    }
}

export const getStatusLabel = (status: ExpenseStatusEnum, isDraft?: boolean) => {
    if (isDraft) {
        return i18n.t("label:draft")
    }
    switch (status) {
        case ExpenseStatusEnum.PURCHASE_PENDING:
            return i18n.t("label:purchase_pending")
        case ExpenseStatusEnum.DOCS_NEEDED:
            return i18n.t("label:documents_needed")
        case ExpenseStatusEnum.INVOICE_PENDING:
            return i18n.t("label:invoice_pending")
        case ExpenseStatusEnum.APPROVED:
            return i18n.t("label:in_review")
        case ExpenseStatusEnum.DECLINED:
            return i18n.t("label:declined")
        case ExpenseStatusEnum.REVIEWED:
            return i18n.t("label:in_payment")
        case ExpenseStatusEnum.PAID:
            return i18n.t("label:in_export")
        case ExpenseStatusEnum.DONE:
            return i18n.t("label:processed")
        default:
            return "n/a"
    }
}

export const isRequestActionDisabled = (user: Employee, vendors: Array<Vendor>, costCenters: Array<CostCenter>, employees: Array<Employee>, rolesMap: Map<string, Role>) => {
    const filteredEmployees = employees.filter((employeeToFilter) => {
        const role = rolesMap.get(employeeToFilter.activeCompanyProfile?.roleId)
        return (
            employeeToFilter.activeCompanyProfile &&
            !employeeToFilter.activeCompanyProfile.deleted &&
            ((isRightGranted(role?.rights, RightEnum.EXPENSE__ALL__APPROVE) && employeeToFilter.email !== FINWAY_ADMIN_EMAIL) ||
                (isRightGranted(role?.rights, RightEnum.EXPENSE__TEAM__APPROVE) && employeeToFilter.activeCompanyProfile.team === user.activeCompanyProfile.team))
        )
    })

    return vendors.length === 0 || costCenters.length === 0 || filteredEmployees.length === 0
}

// TODO: can we remove this after the migration?
export const convertToSortingString = (sortingCriteria: Array<any>) => {
    if (sortingCriteria.length === 0) return ""
    const sortingStrings = sortingCriteria.map((criteria: any, i) => {
        // antd understands "ascend and descend" and back end understands "asc and desc"
        let criteriaOrderAux = criteria.order
        if (criteria.order === "ascend") criteriaOrderAux = "asc"
        if (criteria.order === "descend") criteriaOrderAux = "desc"
        return `${criteriaOrderAux}(${criteria.field})`
    })
    return `&sortBy=${sortingStrings.join(",")}`
}

// antd understands "ascend and descend" and back end understands "asc and desc"
export const convertSortingToString = (sorting: any) => {
    if (sorting.order || sorting.field) return `&sortBy=${sorting.order === "ascend" ? "asc" : "desc"}(${sorting.field})`
    return ""
}

export const isSubscriptionRenewingSoon = (date: Date) => {
    const in8Weeks = moment().add(8, "w")
    const currentDate = moment()
    return moment(date).isBetween(currentDate, in8Weeks, undefined, "[]")
}

export const getSubscriptionPriceInterval = (expense: Expense) => {
    switch (expense.billingPeriod) {
        case BillingPeriodEnum.MONTHLY:
            return PriceIntervalEnum.MONTHLY
        case BillingPeriodEnum.QUARTERLY:
            return PriceIntervalEnum.QUARTERLY
        case BillingPeriodEnum.YEARLY:
            return PriceIntervalEnum.YEARLY
    }
}

export const getExpenseCards = (expenses: Array<any>) =>
    expenses
        .map((exp) => exp.card)
        .filter((card) => card)
        .join(",")

/**
 * Only for expenses that have transactionState or expenses that have looked up/populated the matchedTransactions
 */
export const summarizeExpenseTransactions = (expense: Expense) => {
    const { transactionState } = expense
    const matchedTransactions = expense.matchedTransactions as Array<Transaction>

    if (!isNotSet(transactionState)) return transactionState

    if (matchedTransactions?.length > 0) {
        if ([TransactionTypeEnum.BANK_TRANSACTION, TransactionTypeEnum.CSV_TRANSACTION].includes(matchedTransactions[0].transactionType)) {
            return matchedTransactions[0].status
        }
        if (matchedTransactions[0].transactionType === TransactionTypeEnum.CARD_TRANSACTION) {
            const settlementTransactions = matchedTransactions.filter((mt) => Object.values(WeavrSettlementTypesEnum).includes(mt.cardData.cardTransactionType as any))

            // show pending when there's at least one pending
            if (matchedTransactions.some((mt) => mt.status === TransactionStatusEnum.PENDING)) return TransactionStatusEnum.PENDING

            // if 1 settlement succeeded, show completed
            if (settlementTransactions.some((mt) => mt.status === TransactionStatusEnum.COMPLETED)) return TransactionStatusEnum.COMPLETED

            // if there is none pending, no settlement and at least one failed that's not a reversal, show as failed
            if (matchedTransactions.some((mt) => mt.status === TransactionStatusEnum.FAILED && mt.cardData.cardTransactionType !== WeavrAuthorizationTypesEnum.ONLINE_REVERSE))
                return TransactionStatusEnum.FAILED
        }
    }
    return undefined
}

export const getPeriodPricesForSubscription = (expense: any, showGrossAmount: boolean) => {
    let monthlyPrice
    let quarterlyPrice
    let yearlyPrice
    switch (expense.billingPeriod) {
        case BillingPeriodEnum.MONTHLY:
            monthlyPrice = showGrossAmount ? expense.totalGrossPrice : expense.totalNetPrice
            quarterlyPrice = roundNumberTo2Decimals(monthlyPrice * 3)
            yearlyPrice = roundNumberTo2Decimals(monthlyPrice * 12)
            break
        case BillingPeriodEnum.QUARTERLY:
            quarterlyPrice = showGrossAmount ? expense.totalGrossPrice : expense.totalNetPrice
            monthlyPrice = roundNumberTo2Decimals(quarterlyPrice / 3)
            yearlyPrice = roundNumberTo2Decimals(quarterlyPrice * 4)
            break
        case BillingPeriodEnum.YEARLY:
            yearlyPrice = showGrossAmount ? expense.totalGrossPrice : expense.totalNetPrice
            monthlyPrice = roundNumberTo2Decimals(yearlyPrice / 12)
            quarterlyPrice = roundNumberTo2Decimals(yearlyPrice / 4)
    }
    return { monthlyPrice: Number(monthlyPrice), quarterlyPrice: Number(quarterlyPrice), yearlyPrice: Number(yearlyPrice) }
}

export const doTotalAndInlineAmountsMismatch = (expense: Expense, taxes: Array<Tax>, isNew: boolean = true) => {
    // early out
    if (expense.kind !== ExpenseKindEnum.ONE_TIME_EXPENSE || expense.splits.length === 0 || expense.splitType === SplitTypeEnum.SPLIT) return false

    const totalNetPriceDiff = isExpenseAndTotalSplitPriceMatch(expense, PriceSourceEnum.NET_PRICE)

    const totalGrossPriceDiff = isExpenseAndTotalSplitPriceMatch(expense, PriceSourceEnum.GROSS_PRICE)

    // prettier-ignore
    const taxRateDiff = expense.taxRate?._id === NO_TAX_RATE || (!isNew && !expense.taxRate)
        ? isExpenseAndTotalSplitPriceMatch(expense, PriceSourceEnum.TAX_PRICE)
        : getCommonTaxRateAcrossItems(expense.splits)?._id !== expense.taxRate?._id

    return totalNetPriceDiff || totalGrossPriceDiff || taxRateDiff
}

export const isExpenseAndTotalSplitPriceMatch = (expense: Expense, priceSource: PriceSourceEnum) => {
    let expenseTotalPrice: number

    switch (priceSource) {
        case PriceSourceEnum.NET_PRICE:
            expenseTotalPrice = expense.totalNetPrice
            break
        case PriceSourceEnum.GROSS_PRICE:
            expenseTotalPrice = expense.totalGrossPrice
            break
        case PriceSourceEnum.TAX_PRICE:
            expenseTotalPrice = expense.totalTaxPrice
            break
    }

    return (
        roundNumberTo2Decimals(expenseTotalPrice) !==
        roundNumberTo2Decimals(expense.splits.reduce((total, curr: ExpenseSplit) => roundNumberTo2Decimals(total + curr[priceSource]), 0))
    )
}

export const syncTotalExpenseAmountsToItems = (splits: Array<ExpenseSplit>) => {
    // total net price = sum of all net prices of items (calculates in integer as float might have information loss)
    const totalNetPrice = toAmount(splits.reduce((total, curr: ExpenseSplit) => total.add(toDinero(curr.netPrice)), toDinero(0)))

    // total gross price = sum of all gross prices of items (calculates in integer as float might have information loss)
    const totalGrossPrice = toAmount(splits.reduce((total, curr: ExpenseSplit) => total.add(toDinero(curr.grossPrice)), toDinero(0)))

    // if there's only one common VAT across all items, then use that one as the global VAT otherwise, set  none
    const taxRate = getCommonTaxRateAcrossItems(splits)

    // calc total tax price and gross price by using the above
    // TODO: EXPENSE REFACTOR - double check accuracy
    // const totalTaxPrice = roundNumberTo2Decimals(taxRate !== NO_TAX_RATE ? (totalNetPrice * getTax(taxes, taxRate)) / 100 : calcSumOverAllItemSplitTaxes(expense, taxes))
    const totalTaxPrice = toAmount(splits.reduce((total, curr: ExpenseSplit) => total.add(toDinero(curr.taxPrice)), toDinero(0)))

    // clear up all float error in the splits.
    const newSplits = [...splits].map((split) => ({
        ...split,
        taxPrice: toAmount(toDinero(split.taxPrice)),
        grossPrice: toAmount(toDinero(split.grossPrice)),
        netPrice: toAmount(toDinero(split.netPrice)),
    }))

    return { totalNetPrice, totalTaxPrice, totalGrossPrice, taxRate, splits: newSplits }
}

const getCommonTaxRateAcrossItems = (splits: Array<ExpenseSplit>) => {
    const splitTaxIds: Array<string> = splits.map((split) => split.taxRate?._id!)
    const hasEqualTaxRates = splitTaxIds.every((val) => val === splitTaxIds[0])
    const firstSplitTaxRate = splits[0]?.taxRate === undefined ? { _id: NO_TAX_RATE } : splits[0].taxRate
    return hasEqualTaxRates && splits.length > 0 ? firstSplitTaxRate : { _id: NO_TAX_RATE }
}

export const calculateTotalTax = (splits: Array<ExpenseSplit>) => {
    const taxTotal = splits.reduce((acc, split) => acc + split.taxPrice, 0)
    return roundNumberTo2Decimals(taxTotal)
}

// When cc/cc2/ea is different for each items, then expense shouldnt have any of these info.\
// But when it's all the same, then it should be set to follow what has been filled in the items.
export const getExpenseFieldsSummaryBasedOnSplits = (splits: Array<ExpenseSplit>, expenseTaxRate?: string, syncTax: boolean = false): Partial<ExpenseSplit> => {
    const isUniform = isSplitPropertiesSame(splits)

    // If tax is synced and tax are all the same, adjust the taxRate to be the split's taxRate.
    // If not, set to the expense's tax rate
    let targetTaxRate: { _id: string } | undefined = expenseTaxRate ? { _id: expenseTaxRate } : undefined
    if (isUniform.taxRate && syncTax && splits[0]?.taxRate?._id) {
        targetTaxRate = { _id: splits[0]?.taxRate?._id }
    } else if (syncTax) {
        targetTaxRate = undefined
    }

    return {
        costCenter: isUniform.costCenter ? splits[0]?.costCenter : undefined,
        costCenter2: isUniform.costCenter2 ? splits[0]?.costCenter2 : undefined,
        expenseAccount: isUniform.expenseAccount ? splits[0]?.expenseAccount : undefined,
        taxRate: targetTaxRate?._id === NO_TAX_RATE ? undefined : targetTaxRate,
    }
}

/**
 * Sets undefined taxRate into NO_TAX_RATE so its recognizable by antd form
 */
export const initializeExpenseItemSplitsForForm = (expenseData: any): Array<ExpenseSplit> => {
    if (expenseData.splits) {
        const clonedSplits = JSON.parse(JSON.stringify(expenseData.splits))
        for (const split of clonedSplits) {
            if (split.taxRate === undefined) split.taxRate = { _id: NO_TAX_RATE }
        }
        return clonedSplits
    }
    return []
}

export const deleteUnnecessaryExpenseFormData = (expenseData: any) => {
    const newExpenseData = {
        ...expenseData,
        taxRate: expenseData.taxRate?._id === NO_TAX_RATE ? undefined : expenseData.taxRate,
        splits: expenseData.splits?.map((itemSplit: ItemSplit) => ({
            ...itemSplit,
            taxRate: itemSplit.taxRate?._id === NO_TAX_RATE ? undefined : itemSplit.taxRate,
        })),
    }

    const shouldRemovePaymentFlowInformation = expenseData.paymentOption === ExpensePaymentOptionEnum.SMART_CARD || expenseData.paymentFlowInformation === NOT_SET_VALUE
    if (shouldRemovePaymentFlowInformation) delete newExpenseData.paymentFlowInformation

    delete newExpenseData.items // TODO - EXPENSE REFACTOR: Remove this, when Expense deserializable is updated to get rid of items.

    return newExpenseData
}

interface ExpenseUpdateData extends Pick<Expense, "costCenter" | "costCenter2" | "expenseAccount" | "paymentFlowInformation"> {}

/**
 * Applies vendor and employee rules corrding to company, user and vendor settings depending on expense vendorType
 *
 * @returns expense data that should be updated
 */
export const buildUpdateDataAccordingToSetting = (expense: Expense, vendors: Array<Vendor>, users: Array<Employee>, company: Company) => {
    const userId = expense?.requestedBy._id
    const vendorId = expense?.vendor?._id
    const companyId = company._id
    const vendorRulesShouldOverwrite = company.vendorRulesOverwriteEmployeeRulesEnabled

    const updateData: ExpenseUpdateData = {}

    if (!userId && !vendorId) return updateData
    if (userId && !vendorId) return buildEmployeeRuleUpdateData(userId, users, companyId, expense.expenseAccount?._id)

    // if the VendorTypeEnum is USER => only apply the employee rules (of the person requesting the expense here: userId)
    if (expense.vendorType === VendorTypeEnum.USER) {
        const employeeRules = buildEmployeeRuleUpdateData(userId!, users, companyId, expense.expenseAccount?._id)

        if (isEmptyObject(employeeRules)) return updateData

        updateData.costCenter = employeeRules.costCenter ?? expense.costCenter
        updateData.costCenter2 = employeeRules.costCenter2 ?? expense.costCenter2
        updateData.expenseAccount = employeeRules.expenseAccount ?? expense.expenseAccount
        updateData.paymentFlowInformation = expense.paymentFlowInformation

        return updateData
    }

    if (!userId && vendorId) return buildVendorRuleUpdateData(expense, vendorId, vendors, company)

    const vendorRules = buildVendorRuleUpdateData(expense, vendorId!, vendors, company)
    const employeeRules = buildEmployeeRuleUpdateData(userId!, users, companyId, expense.expenseAccount?._id)

    if (isEmptyObject(vendorRules) && isEmptyObject(employeeRules)) return updateData

    if (vendorRulesShouldOverwrite) {
        updateData.costCenter = vendorRules.costCenter ?? employeeRules.costCenter ?? expense.costCenter
        updateData.costCenter2 = vendorRules.costCenter2 ?? employeeRules.costCenter2
        updateData.expenseAccount = vendorRules.expenseAccount ?? employeeRules.expenseAccount ?? expense.expenseAccount
        updateData.paymentFlowInformation = vendorRules.paymentFlowInformation ?? expense.paymentFlowInformation
    } else {
        updateData.costCenter = employeeRules.costCenter ?? vendorRules.costCenter ?? expense.costCenter
        updateData.costCenter2 = employeeRules.costCenter2 ?? vendorRules.costCenter2
        updateData.expenseAccount = employeeRules.expenseAccount ?? vendorRules.expenseAccount ?? expense.expenseAccount
        updateData.paymentFlowInformation = vendorRules.paymentFlowInformation ?? expense.paymentFlowInformation
    }
    return updateData
}

const buildVendorRuleUpdateData = (expense: Expense, vendorId: string, vendors: Array<Vendor>, company: any) => {
    const updateData: ExpenseUpdateData = { expenseAccount: undefined, paymentFlowInformation: undefined, costCenter: undefined, costCenter2: undefined }
    const vendor = vendors.find((vendorElement: Vendor) => vendorElement.id === vendorId)

    if (!vendor?.rule || (Object.keys(vendor?.rule).length === 1 && Object.keys(vendor?.rule)[0] === "_id")) return {}

    if (vendor.rule?.costCenter) updateData.costCenter = vendor?.rule?.costCenter ?? expense.costCenter
    if (vendor.rule?.costCenter2) updateData.costCenter2 = vendor?.rule?.costCenter2
    if (vendor.rule?.expenseAccount) updateData.expenseAccount = { _id: vendor?.rule?.expenseAccount } ?? expense.expenseAccount
    if (vendor.rule?.expensePaymentFlowInformation && expense.paymentOption !== ExpensePaymentOptionEnum.SMART_CARD && !expense.isReimbursement) {
        if (!isPaymentFlowInfoDisabled(vendor.rule?.expensePaymentFlowInformation, company)) {
            updateData.paymentFlowInformation = vendor.rule?.expensePaymentFlowInformation
        }
    }

    return updateData
}

const buildEmployeeRuleUpdateData = (userId: string, users: Array<Employee>, companyId: string, expenseAccount?: string) => {
    const updateData: ExpenseUpdateData = { expenseAccount: expenseAccount ? { _id: expenseAccount } : undefined, costCenter: undefined, costCenter2: undefined }
    const user = users.find((userElement: User) => userElement.id === userId)
    const rule = user?.rule?.find((userRule: UserRule) => userRule.companyId === companyId)

    if (!rule) return {}

    if (rule?.costCenter) updateData.costCenter = rule?.costCenter
    if (rule?.costCenter2) updateData.costCenter2 = rule?.costCenter2
    if (rule?.expenseAccount) updateData.expenseAccount = { _id: rule?.expenseAccount }
    return updateData
}

export const getCostCenterSplitInfo = (costCenterSplits: Array<ExpenseSplit>, expense: Expense, loggedInProfile: Employee, taxes: Array<Tax>) =>
    costCenterSplits
        .map((s) => {
            const percentage = isInvoiceSplit(s, expense) ? s.percentage : 0
            let price = 0
            if (loggedInProfile.settings.showGrossAmount) {
                price = s.grossPrice
            } else {
                price = s.netPrice
            }

            return {
                percentage,
                price,
            }
        })
        .reduce(
            (total, curr) => ({
                percentage: total.percentage + curr.percentage,
                price: total.price + curr.price,
            }),
            { percentage: 0, price: 0 },
        )

export const getDashboardSearchTargets = (useGross: boolean) => [
    "approverName",
    "requesterName",
    "vendorName",
    "invoiceNumber",
    "description",
    "items.name",
    "expenseNumber",
    useGross ? "totalGrossPrice" : "totalNetPrice",
]

export const isFullyProcessedSubscription = (expense: Expense) =>
    expense.kind === ExpenseKindEnum.SUBSCRIPTION &&
    expense.subscriptionType === SubscriptionTypeEnum.INITIAL_REQUEST &&
    [ExpenseStatusEnum.PAID, ExpenseStatusEnum.DONE].includes(expense.status)

export const isExpenseArchived = (expense: Expense, archiveAfterXDays: number) =>
    !isNotSet(expense.isManuallyArchived)
        ? expense.isManuallyArchived
        : DONE_STATUSES.includes(expense.status) && moment(expense.datePurchased).isSameOrBefore(moment().subtract(archiveAfterXDays, "days").toDate())

export const isExpenseManuallyUnarchived = (expense: Expense, archiveAfterXDays: number) =>
    !isNotSet(expense.isManuallyArchived) && !expense.isManuallyArchived && moment(expense.datePurchased).isSameOrBefore(moment().subtract(archiveAfterXDays, "days").toDate())

export const isSplitPropertiesSame = (splits: Array<ExpenseSplit>) => ({
    costCenter: splits.every((s) => s.costCenter === splits[0].costCenter),
    costCenter2: splits.every((s) => s.costCenter2 === splits[0].costCenter2),
    expenseAccount: splits.every((s) => s.expenseAccount?._id === splits[0].expenseAccount?._id),
    taxRate: splits.every((s) => s.taxRate?._id === splits[0].taxRate?._id),
})

export const calculateSplitPercentageTotal = (splits: Array<InvoiceSplit>) => splits.reduce((accu: number, split: InvoiceSplit) => accu + Number(split.percentage), 0)

/**
 * Type guard to determine if the splits of an expense are InvoiceSplit. If true, typescript can safely assume in the rest of the code block that the split is InvoiceSplit
 */
export const areInvoiceSplits = (splits: Array<ExpenseSplit>, expense: Expense): splits is Array<InvoiceSplit> => expense.splitType === SplitTypeEnum.SPLIT
export const isInvoiceSplit = (split: ExpenseSplit, expense: Expense): split is InvoiceSplit => expense.splitType === SplitTypeEnum.SPLIT

/**
 * Type guard to determine if the splits of an expense are ItemSplit. If true, typescript can safely assume in the rest of the code block that the split is ItemSplit
 */
export const areItemSplits = (splits: Array<ExpenseSplit>, expense: Expense): splits is Array<ItemSplit> => expense.splitType === SplitTypeEnum.ITEM

export const isHospitalityExpense = (expense: Expense): expense is Hospitality => expense.kind === ExpenseKindEnum.HOSPITALITY
export const isPerDiemExpense = (expense: Expense): expense is PerDiem => expense.kind === ExpenseKindEnum.PER_DIEM
export const isMileageExpense = (expense: Expense): expense is Mileage => expense.kind === ExpenseKindEnum.MILEAGE
export const isMileageOrPerDiem = (expense: Expense): expense is Mileage | PerDiem => [ExpenseKindEnum.MILEAGE, ExpenseKindEnum.PER_DIEM].includes(expense.kind)
export const isExpenseWithoutInvoice = (expense: Expense): expense is Mileage | PerDiem => EXPENSE_KINDS_WITHOUT_INVOICE.includes(expense.kind)
export const isFolderExpense = (expense: Expense): expense is TripFolder => expense.kind === ExpenseKindEnum.TRIP_FOLDER

export const getExpenseModel = (expense: Expense) => {
    const ExpenseModel = getExpenseModelByKind(expense.kind)
    return new ExpenseModel(expense)
}

export const getExpenseModelByKind = (kind: ExpenseKindEnum) => {
    switch (kind) {
        case ExpenseKindEnum.HOSPITALITY:
            return Hospitality
        case ExpenseKindEnum.PER_DIEM:
            return PerDiem
        case ExpenseKindEnum.MILEAGE:
            return Mileage
        case ExpenseKindEnum.TRIP_FOLDER:
            return TripFolder
        case ExpenseKindEnum.ONE_TIME_EXPENSE:
        case ExpenseKindEnum.SUBSCRIPTION:
        case ExpenseKindEnum.RECURRING_SUBSCRIPTION:
        default:
            return Expense
    }
}

export const isExpenseAlreadyPaid = (expense: Expense) => !isNotSet(expense.paymentFlowInformation) || [ExpenseStatusEnum.PAID, ExpenseStatusEnum.DONE].includes(expense.status)

/**
 * Mutates splits array so all the values of the selected property is changed to the new value
 */
export const changeAllSplitPropertyValues = (splits: Array<ExpenseSplit>, propertyToChange: "costCenter" | "costCenter2" | "expenseAccount", newValue: string) => {
    for (const split of splits) {
        if (propertyToChange === "expenseAccount") split[propertyToChange] = { _id: newValue }
        else split[propertyToChange] = newValue
    }
}

/**
 * @returns true if the expense could move past the APPROVED stage until the end.
 */
export const isExpenseAbleToProceed = (data: Expense) => {
    // Invoice data must be complete
    const isInvoiceDataComplete = Boolean(data.invoiceNumber && data.invoiceDate)

    // Cost center must be completely populated
    const isCostCenterComplete = data.splits?.length === 0 || data.splits?.every((s) => s.costCenter)

    // Creditor number must exist on APPROVED stage
    const isCreditorNumberOk = isExpenseCreditorNumberInfoOk(data)

    const itHasInvoicesIfInPaidStatus = expenseHasInvoiceInPaidStatus(data)

    return isInvoiceDataComplete && isCostCenterComplete && isCreditorNumberOk && itHasInvoicesIfInPaidStatus
}

export const isExpenseCreditorNumberInfoOk = (expense: Pick<Expense, "status" | "getCreditor">) =>
    expense.status <= ExpenseStatusEnum.APPROVED && Boolean(expense.getCreditor()?.creditorNumber)

export const expenseHasInvoiceInPaidStatus = (expense: Expense) => expense.status !== ExpenseStatusEnum.PAID || expense.invoices.length > 0

export const isExpenseSwitchingShown = (expense: Expense, archiveAfterXDays: number) => !isExpenseArchived(expense, archiveAfterXDays)

export const shouldExpenseIncludeVendors = (expense: Expense) => !isMileageOrPerDiem(expense)

export const shouldExpenseIncludeEmployeesAsVendor = (expense: Expense) => expense.isReimbursement

export const determineDefaultVendorForReimbursement = (requestedBy: string, expenseKind: ExpenseKindEnum, creditors: Array<CreditorInterface>) => {
    if (![ExpenseKindEnum.PER_DIEM, ExpenseKindEnum.MILEAGE].includes(expenseKind)) return undefined

    return creditors.find((c) => c.type === VendorTypeEnum.USER && c.id === requestedBy)
}

export const isPaymentFlowInfoDisabled = (paymentFlowInformation: ExpensePaymentFlowInformationEnum, company: Company) => {
    if (paymentFlowInformation === ExpensePaymentFlowInformationEnum.ALREADY_PAID && !company.expensePaymentFlowInformation?.isAlreadyPaidEnabled) {
        // already paid disabled
        return true
    }

    if (paymentFlowInformation === ExpensePaymentFlowInformationEnum.ALREADY_APPROVED_AND_PAID && !company.expensePaymentFlowInformation?.isAlreadyApprovedAndPaidEnabled) {
        // already approved & paid disabled
        return true
    }

    if (paymentFlowInformation === ExpensePaymentFlowInformationEnum.REPORTING_ONLY && !company.expensePaymentFlowInformation?.isReportingOnlyEnabled) {
        // reporting only disabled
        return true
    }

    return false
}

export const showPaymentFailedAlert = (status: ExpenseStatusEnum, payment?: FinApiPayment) =>
    payment &&
    [FinApiPaymentStatusEnum.DISCARDED, FinApiPaymentStatusEnum.NOT_SUCCESSFUL].includes(payment.status) &&
    ![ExpenseStatusEnum.PAID, ExpenseStatusEnum.DONE].includes(status)

export const generatePerDiemDestinationTree = (perDiemDestinations: Array<PerDiemDestination>): Array<PerDiemDestination> => {
    // add children
    const treeDestinations: Array<PerDiemDestination> = []
    for (const destination of perDiemDestinations) {
        if (destination.parent) continue // if have a parent, skip...

        if (destination.children && destination.children.length > 0) {
            const childrenDestinations: Array<PerDiemDestination> = perDiemDestinations.filter((resource) => resource.parent === destination._id)
            destination.children = childrenDestinations
        }
        treeDestinations.push(destination)
    }
    return treeDestinations
}

export const getDefaultInvoiceNumber = (kind: ExpenseKindEnum, nextExpenseNumber?: number) => {
    if (!nextExpenseNumber) return undefined
    switch (kind) {
        case ExpenseKindEnum.PER_DIEM:
            return `finway-pd-${moment().get("year")}-${nextExpenseNumber}`
        case ExpenseKindEnum.MILEAGE:
            return `finway-ml-${moment().get("year")}-${nextExpenseNumber}`
        default:
            return undefined
    }
}

export const statusWithCreditorNumberExpected = [ExpenseStatusEnum.APPROVED, ExpenseStatusEnum.REVIEWED, ExpenseStatusEnum.PAID]

export const detectSplitRoundingError = (splits: Array<ExpenseSplit>) => {
    const expectedAmount = {
        net: 0,
        tax: 0,
        gross: 0,
    }
    const roundedAmount = {
        net: 0,
        tax: 0,
        gross: 0,
    }
    for (const split of splits) {
        expectedAmount.net += split.netPrice
        roundedAmount.net += roundNumberTo2Decimals(split.netPrice)

        expectedAmount.tax += split.taxPrice
        roundedAmount.tax += roundNumberTo2Decimals(split.taxPrice)

        expectedAmount.gross += split.grossPrice
        roundedAmount.gross += roundNumberTo2Decimals(split.grossPrice)
    }

    return {
        netPrice: roundNumberTo2Decimals(expectedAmount.net - roundedAmount.net),
        grossPrice: roundNumberTo2Decimals(expectedAmount.gross - roundedAmount.gross),
        taxPrice: roundNumberTo2Decimals(expectedAmount.gross - roundedAmount.gross),
    }
}

/**
 * Calculates if there is an error in the amounts vertically (net / gross / tax).
 * returns the difference between: (Split 0's price + ... + Split n's price) and (Expense price)
 * 0 in tax/gross/net means the amounts is fine.
 */
export const detectVerticalSplitError = (expense: Expense, splits: Array<ExpenseSplit>) => {
    const splitAmount = {
        net: toDinero(0),
        tax: toDinero(0),
        gross: toDinero(0),
    }

    for (const split of splits) {
        splitAmount.net = splitAmount.net.add(toDinero(split.netPrice))
        splitAmount.tax = splitAmount.tax.add(toDinero(split.taxPrice))
        splitAmount.gross = splitAmount.gross.add(toDinero(split.grossPrice))
    }

    splitAmount.net = splitAmount.net.subtract(toDinero(expense.totalNetPrice))
    splitAmount.tax = splitAmount.tax.subtract(toDinero(expense.totalTaxPrice))
    splitAmount.gross = splitAmount.gross.subtract(toDinero(expense.totalGrossPrice))

    return {
        netPrice: toAmount(splitAmount.net),
        taxPrice: toAmount(splitAmount.tax),
        grossPrice: toAmount(splitAmount.gross),
    }
}

/**
 * Calculates if there is an error horizontally
 * Returns array of indices of rows that doesnt add up
 * Split 0's net + tax should equal gross
 */
export const detectHorizontalSplitError = (splits: Array<ExpenseSplit>) => {
    const errors: Array<number> = []
    splits.forEach((split, index) => {
        const { netPrice, taxPrice, grossPrice } = split
        if (!toDinero(netPrice).add(toDinero(taxPrice)).equalsTo(toDinero(grossPrice))) errors.push(index)
    })

    return errors
}

export const convertAmountToInteger = (amt: number) => Math.round(amt * 100)

export const adjustGrossAmountsIfNeeded = (splits: Array<ExpenseSplit>, expense: Expense) => {
    if (!areInvoiceSplits(splits, expense)) {
        return splits
    }

    const roundingError = detectSplitRoundingError(splits)
    if (roundingError.grossPrice !== 0) {
        const totalGross = splits.reduce((acc, val) => acc + val.grossPrice, 0)
        const percentages = splits.map((s) => Number(s.percentage))
        const grossPriceAllocation = dinero({ amount: convertAmountToInteger(totalGross) }).allocate(percentages)
        return [...splits].map((s, i) => ({
            ...s,
            grossPrice: grossPriceAllocation[i].getAmount() / 100,
        }))
    }

    return splits
}

export const isMatched = (expense: Expense) => summarizeExpenseTransactions(expense) !== undefined

export const addInvoiceCommentsIfNeeded = (expense: Expense, previousExpense: Expense, user: User) => {
    const newExpenseUrls = expense.invoices.map(({ url }: InvoiceInterface) => url)
    const previousExpenseUrls = previousExpense.invoices.map(({ url }: InvoiceInterface) => url)

    const newInvoices = expense.invoices.filter(({ url }: InvoiceInterface) => !previousExpenseUrls.includes(url))
    const removedInvoices = previousExpense.invoices.filter(({ url }: InvoiceInterface) => !newExpenseUrls.includes(url))

    const commentMessages = [
        ...removedInvoices.map(({ fileName }: InvoiceInterface) =>
            fileName ? `${AutoGeneratedCommentsEnum.INVOICE_DELETED_WITH_FILENAME},${fileName}` : AutoGeneratedCommentsEnum.INVOICE_DELETED,
        ),
        ...newInvoices.map(({ fileName }: InvoiceInterface) =>
            fileName ? `${AutoGeneratedCommentsEnum.INVOICE_UPLOADED_WITH_FILENAME},${fileName}` : AutoGeneratedCommentsEnum.INVOICE_UPLOADED,
        ),
    ]
    expense.comments.push(
        ...commentMessages.map(
            (message: string) =>
                new CommentItem({
                    postedBy: user.id,
                    message,
                    datePosted: new Date(),
                    isAutoGenerated: true,
                }),
        ),
    )
}

export const getReimbursementLabel = (kind: ExpenseKindEnum) => {
    switch (kind) {
        case ExpenseKindEnum.HOSPITALITY:
            return "hospitality"
        case ExpenseKindEnum.PER_DIEM:
            return "per_diem"
        case ExpenseKindEnum.MILEAGE:
            return "mileage"
        case ExpenseKindEnum.STANDARD:
            return "standard"
        default:
            return "standard_reimbursement"
    }
}

// A product requirement for export filter in ALL tab
export const getFilteredExpenseStatusArrayForExport = (exportFormat: ExportFormatEnum) => {
    switch (exportFormat) {
        case ExportFormatEnum.DATEV:
        case ExportFormatEnum.DATEV_INVOICE_CSV:
            return [ExpenseStatusEnum.REVIEWED, ExpenseStatusEnum.PAID, ExpenseStatusEnum.DONE]
        case ExportFormatEnum.ALL:
        case ExportFormatEnum.CSV:
            return [
                ExpenseStatusEnum.PURCHASE_PENDING,
                ExpenseStatusEnum.DOCS_NEEDED,
                ExpenseStatusEnum.INVOICE_PENDING,
                ExpenseStatusEnum.APPROVED,
                ExpenseStatusEnum.REVIEWED,
                ExpenseStatusEnum.PAID,
                ExpenseStatusEnum.DONE,
                ExpenseStatusEnum.DECLINED,
            ]
        case ExportFormatEnum.INVOICES:
            return [ExpenseStatusEnum.INVOICE_PENDING, ExpenseStatusEnum.APPROVED, ExpenseStatusEnum.REVIEWED, ExpenseStatusEnum.PAID, ExpenseStatusEnum.DONE]
        default:
            return []
    }
}

// if expense format is different than INVOICES or ALL (they don't advance status by default) and the expense status is or includes PAID then we let the user decide if they want to advance expense status to DONE
export const shouldAskToAdvanceStatusOnExport = (format: ExportFormatEnum, status: any) =>
    ![ExportFormatEnum.INVOICES, ExportFormatEnum.ALL].includes(format) && (!status || Number(status) === ExpenseStatusEnum.PAID)

export const getExpenseReminderForUser = (reminders: Array<ExpenseReminder>, userId: string) => reminders?.find((reminder) => reminder.user === userId)

/**
 * Will automatically filter for folders, and selects the childrens instead
 * e.g.
 * we are exporting the following:
 * Expense A, B, C, and Folder D (children: Expense X, Y)
 * This will return A,B,C,X,Y
 * if includeFolder is set to true, it will return A,B,C,D,X,Y
 *
 * folders passed through expenses require .children to be populated
 */
export const getExpensesToPayOrExportForTables = (expenses: Array<Expense>, currentExpectedStatus?: ExpenseStatusEnum, includeFolder = false) => {
    const actionableExpenses: Array<Expense> = []

    for (const expense of expenses) {
        if (actionableExpenses.includes(expense) || (currentExpectedStatus && expense.status !== currentExpectedStatus)) continue

        if (isFolderExpense(expense)) {
            actionableExpenses.push(...getExpensesToPayOrExportForTables(expense.children, currentExpectedStatus))
            if (!includeFolder) {
                continue
            }
        }

        actionableExpenses.push(expense)
    }

    return getUniqueElements(actionableExpenses, "id")
}

export const isExpenseReviewable = (expense: Expense) =>
    (expense.status === ExpenseStatusEnum.APPROVED && isExpenseAbleToProceed(expense)) || expense.paymentFlowInformation === ExpensePaymentFlowInformationEnum.REPORTING_ONLY

export const isReviewButtonEnabled = (activeExpense: Expense, subExpenses: Array<Expense>) => {
    if (isFolderExpense(activeExpense)) {
        if (subExpenses.length === 0) return false
        const { processableSubExpenses, canAdvance } = getFolderProgressionInfo(activeExpense, subExpenses)
        return canAdvance && processableSubExpenses.every((subExpense) => isExpenseReviewable(subExpense))
    }
    return isExpenseReviewable(activeExpense)
}

export const sumTotalGrossPriceByCurrencies = (expenses: Array<Expense>) => {
    const totalGrossPriceByCurrency = new Map<CurrencyEnum, number>()

    for (const expense of expenses) {
        const currentTotalPrice = totalGrossPriceByCurrency.get(expense.currency)
        const discount = expense.getActiveDiscount()
        const grossPriceToPay = discount && !isDiscountExpired(expense) ? discount.reducedAmount.gross : expense.totalGrossPrice

        if (currentTotalPrice) {
            totalGrossPriceByCurrency.set(expense.currency, toAmount(toDinero(currentTotalPrice).add(toDinero(grossPriceToPay))))
        } else {
            totalGrossPriceByCurrency.set(expense.currency, toAmount(toDinero(grossPriceToPay)))
        }
    }

    return totalGrossPriceByCurrency
}

export const isExpenseInformationCompleteForReview = (expense: Expense) => {
    const isNotAbleToProceed = !isExpenseAbleToProceed(expense) && expense.paymentFlowInformation !== ExpensePaymentFlowInformationEnum.REPORTING_ONLY

    const isInReview = expense.status === ExpenseStatusEnum.APPROVED

    return isInReview && !isNotAbleToProceed
}

export const isExpenseCreditorApproved = (expense: Expense, creditorMap: Map<string, CreditorInterface>) => {
    const creditorRef = expense.getCreditor()
    if (!creditorRef) return false
    if (creditorRef?.type == VendorTypeEnum.USER) return true

    // Extended reference of vendor in expenses does not store isApproved, so we still have to fetch the whole creditor object.
    const creditor = creditorMap.get(creditorRef._id)
    return (creditor?.source as Vendor)?.isApproved
}

/**
 * Passing an folder in the expense param needs .children to be populated
 */
export const diagnoseExpenseForReview = (expense: Expense, creditorMap: Map<string, CreditorInterface>) => {
    // Is all the expense data OK and the expense is in the correct state?
    let isReviewable = isExpenseInformationCompleteForReview(expense)

    // Is the vendor attached to the expense already approved?
    let isVendorApproved = isExpenseCreditorApproved(expense, creditorMap)

    // If it's a folder expense, check if all the children are ready to review
    if (isFolderExpense(expense)) {
        const { processableSubExpenses, canAdvance } = getFolderProgressionInfo(expense, expense.children) // checks by expense.children for tables
        isReviewable = expense.children.length > 0 ? processableSubExpenses.every((child: Expense) => isExpenseInformationCompleteForReview(child)) && canAdvance : false
        isVendorApproved = processableSubExpenses.every((child: Expense) => isExpenseCreditorApproved(child, creditorMap))
    }

    // Return the diagnostic result. Values are separated for the caller to be able to show the correct message
    return {
        isReviewable,
        isVendorApproved,
    }
}

export const isExpenseExportable = (expense: Expense, loggedInUser: Employee, isLoggedInUserGlobalApprover: boolean) => {
    const invoiceWillBeAutoGenerated = [ExpenseKindEnum.PER_DIEM, ExpenseKindEnum.MILEAGE].includes(expense.kind)
    const invoiceIsAttached = expense.invoices?.length > 0
    const isMyExpense = expense.requestedBy?._id === loggedInUser.id
    const isApproverOfAnyApprovalProcess = getApproverIdsOfApprovalProcesses(expense.approvalProcesses).includes(loggedInUser.id)
    const isExpenseRelevantToMe = isMyExpense || isApproverOfAnyApprovalProcess

    return (isLoggedInUserGlobalApprover || isExpenseRelevantToMe) && (invoiceIsAttached || invoiceWillBeAutoGenerated)
}

export const isEditButtonDisabled = (expense: Expense, t: TFunction, isLoggedInUserGlobalApprover: boolean) => {
    const isNotEditableBecauseOfStatus = (expense.status === ExpenseStatusEnum.PAID && !isLoggedInUserGlobalApprover) || expense.status === ExpenseStatusEnum.DONE
    const isReportingOnly = expense.paymentFlowInformation === ExpensePaymentFlowInformationEnum.REPORTING_ONLY

    if (isNotEditableBecauseOfStatus && !isReportingOnly) {
        return {
            disabled: true,
            reason: t("tooltips:expenses.not_editable_exported"),
        }
    }

    if (expense.deleted) {
        return {
            disabled: true,
        }
    }

    if (expense.isGobdCompliantInvoice()) {
        return {
            disabled: true,
            reason: t("tooltips:expenses.edit_disabled_due_to_beleg"),
        }
    }

    return {
        disabled: false,
    }
}

/**
 * Billable cannot be manipulated by user of th expense is in the purchase pending states and when it's a subscription.
 */
export const isExpenseBillableAccessible = (expense: Expense) =>
    ![ExpenseStatusEnum.PURCHASE_PENDING, ExpenseStatusEnum.DOCS_NEEDED].includes(expense.status) && !expense.isSubscription()

/**
 * Checks if invoice in the expense is provided. For mileage/perdiem, invoice is automatically generated internally
 */
export const isExpenseInvoiceProvided = (expense: Expense) => isMileageOrPerDiem(expense) || expense.invoices?.length > 0

export const isExpenseVendorDataOutdated = (vendor: Vendor | (Employee & { creditorNumber?: string }), vendorData?: ExtendedReferencedVendor | ExtendedReferencedUser): boolean => {
    if (!vendorData) return false

    if (vendor instanceof Employee) vendor.creditorNumber = vendor.activeCompanyProfile.creditorNumber

    const vendorKeys = Object.keys(vendor)
    const vendorValues = Object.values(vendor)
    const vendorDataEnumerable = Object.entries(vendorData)
    return vendorDataEnumerable.filter(([key, value]) => vendorKeys.includes(key) && vendorValues.includes(value)).length !== vendorDataEnumerable.length
}

export const adjustFilterDataForSelectedExpenseType = (values: Store) => {
    // Standard expense type filter translates to kind === ONE_TIME_EXPENSE and isReimbursement === true
    // we don't have in the database expenses with STANDARD kind, we parse the kind
    if (values.kind === ExpenseKindEnum.STANDARD) return parseStandardReimbursementExpenseFormValues(values)
    // One time expense type filter translates to kind === ONE_TIME_EXPENSE and isReimbursement === false
    if (values.kind === ExpenseKindEnum.ONE_TIME_EXPENSE) return parseOneTimeExpenseExpenseFormValues(values)

    return values
}

export const parseStandardReimbursementExpenseFormValues = (values: Store) => ({
    ...values,
    kind: ExpenseKindEnum.ONE_TIME_EXPENSE,
    isReimbursement: true,
})

export const parseOneTimeExpenseExpenseFormValues = (values: Store) => ({
    ...values,
    kind: ExpenseKindEnum.ONE_TIME_EXPENSE,
    isReimbursement: false,
})

export const isExpenseMissingCostCenter1 = (expense: Expense): boolean => !(expense.costCenter || expense.splits.some((split: ExpenseSplit) => split.costCenter))

export const getCostCentersFromSplits = (expenseSplits: Array<ExpenseSplit>): Array<string> => [
    ...new Set(expenseSplits.filter((expenseSplit: ExpenseSplit) => expenseSplit.costCenter).map((expenseSplit) => expenseSplit.costCenter) as Array<string>),
]

export const getErrorMessageForDuoExportFailure = (failureType?: string) =>
    i18n.exists(`info:datev_export_job.failure_type_enum.${failureType}`)
        ? i18n.t(`info:datev_export_job.failure_type_enum.${failureType}`)
        : i18n.t(`info:datev_export_job.failure_type_enum.UNKNOWN`)

export const isAtLeastReviewed = (status: ExpenseStatusEnum): boolean =>
    status === ExpenseStatusEnum.REVIEWED || status === ExpenseStatusEnum.PAID || status === ExpenseStatusEnum.DONE

/**
 * To properly get the expenses to be exported, when incoming expense is a folder.
 * E.g. When the expense param is folder A with A.children = [X,Y,Z], this will return [X,Y,Z]. It filters out expenses that are not in the suitable status for exports.
 * When the expense param is folder J, it will return [J]
 *
 * @param expense expense that we want to export. Expense should be expense on the table so that expense.children is populated
 * @param includeFolder indicates when the expense to be exported is a folder, should the folder itself be included or not (following the case above, when this flag is true it will return [A,X,Y,Z])
 * @returns array of expense that can be exported
 */
export const normalizeExpenseToExport = (expense: Expense, includeFolder?: boolean): Array<Expense> => {
    if (!isFolderExpense(expense)) {
        return [expense]
    }

    const expensesToExport: Array<Expense> = (expense.children ?? []).filter((subExpense) => isAtLeastReviewed(subExpense.status))
    if (includeFolder) expensesToExport.push(expense)
    return expensesToExport
}

export const normalizeExpensesToExport = (expenses: Array<Expense>, includeFolder?: boolean): Array<Expense> => {
    const normalizedExpenses = expenses.flatMap((expense) => normalizeExpenseToExport(expense, includeFolder))
    // normalizedExpenses might contain duplicate, because some folder's children might be already passed in expenses.
    const uniqueIds = [...new Set(normalizedExpenses.map((expense) => expense.id))]
    return uniqueIds.map((id) => normalizedExpenses.find((expense) => expense.id === id) as Expense)
}

export const createLinkToRequestFunction = (expense: Expense, history: RouterChildContext["router"]["history"]) => {
    const isDoneParentSubscription = expense.isInitialSubscription() && expense.isSuccessfullyProcessed()
    let url = `/expenses/${expense.id}`

    if (isDoneParentSubscription) {
        url = `/subscriptions/${expense.id}`
    }

    return (_event: React.KeyboardEvent | React.MouseEvent) => {
        history.push(url)
    }
}

export const determineExpensePrice = (taxRate: number, price: number, priceSource: PriceSourceEnum) => {
    switch (priceSource) {
        case PriceSourceEnum.NET_PRICE: {
            const taxPrice = toDinero(price).percentage(taxRate)
            return {
                netPrice: price,
                taxPrice: toAmount(taxPrice),
                grossPrice: toAmount(toDinero(price).add(taxPrice)),
            }
        }
        case PriceSourceEnum.GROSS_PRICE:
        default: {
            const netPrice = calculateNetPriceFromGrossPriceAndTaxRate(price, taxRate)
            return {
                grossPrice: price,
                netPrice,
                taxPrice: toAmount(toDinero(price).subtract(toDinero(netPrice))),
            }
        }
    }
}

export const isExpenseNotResettable = (expense: any) => {
    const isExpenseMileageOrPerDiemAndInInvoicePendingState =
        (expense.kind === ExpenseKindEnum.MILEAGE || expense.kind === ExpenseKindEnum.PER_DIEM) && expense.status === ExpenseStatusEnum.INVOICE_PENDING

    return (
        (expense.isInReview() && expense.paymentFlowInformation === ExpensePaymentFlowInformationEnum.REPORTING_ONLY) ||
        isExpenseMileageOrPerDiemAndInInvoicePendingState ||
        expense.status === ExpenseStatusEnum.DECLINED
    )
}

export const getCreditorUpdateObject = (creditorId?: string, creditorType?: VendorTypeEnum): Partial<Expense> => {
    if (!creditorId) {
        return { creditorUser: undefined, vendor: undefined, vendorType: creditorType ?? VendorTypeEnum.VENDOR }
    }

    if (creditorType === VendorTypeEnum.USER) {
        return { creditorUser: { _id: creditorId }, vendor: undefined, vendorType: creditorType }
    }

    return { creditorUser: undefined, vendor: { _id: creditorId }, vendorType: creditorType }
}

export const createCancelExpenseRequestTranslationStrings = (isTripFolder: boolean, shouldUseDeleteLabel: boolean, t: TFunction) => {
    let confirmTranslations = {
        title: t("confirm:request.cancel.title"),
        content: t("confirm:request.cancel.message"),
        cancelText: t("confirm:request.cancel.cancel"),
        okText: t("confirm:request.cancel.confirm"),
    }

    if (shouldUseDeleteLabel) {
        confirmTranslations = {
            title: t("confirm:request.delete.title"),
            content: t("confirm:request.delete.message"),
            cancelText: t("confirm:request.delete.cancel"),
            okText: t("confirm:request.delete.confirm"),
        }

        if (isTripFolder) {
            confirmTranslations = {
                title: t("confirm:trip_folder.delete.title"),
                content: t("confirm:trip_folder.delete.message"),
                cancelText: t("confirm:trip_folder.delete.cancel"),
                okText: t("confirm:trip_folder.delete.confirm"),
            }
        }
    }

    return confirmTranslations
}

export const addCostCenterResponsible = ({ filteredEmployees, costCenters, expense }: { filteredEmployees: Array<Employee>; costCenters: Array<CostCenter>; expense: Expense }) => {
    const costCenter = costCenters.find((costCenter: CostCenter) => costCenter._id === expense.costCenter)
    const isResponsibleUserInTheList = filteredEmployees.find((employee) => employee.id === costCenter?.responsibleUser)
    if (costCenter && !isResponsibleUserInTheList) {
        const responsibleUser = filteredEmployees.find((employee) => employee.id === costCenter.responsibleUser)
        if (responsibleUser && !responsibleUser?.activeCompanyProfile.deleted) filteredEmployees.push(responsibleUser)
    }
    return filteredEmployees
}

export const addSuperiorToApproverList = ({ superiorId, employees, filteredEmployees }: { superiorId: string; employees: Array<Employee>; filteredEmployees: Array<Employee> }) => {
    const superior = GetEmployeeById(employees, superiorId)
    if (superior && !superior.activeCompanyProfile.deleted && !filteredEmployees.find((e) => e.id === superior.id)) filteredEmployees.push(superior)

    return filteredEmployees
}

export const ensureNextApproverInDropdown = ({ expense, employees, filteredEmployees }: { expense: Expense; employees: Array<Employee>; filteredEmployees: Array<Employee> }) => {
    const nextApproverId = getNextApproverId(expense.approvalProcesses)
    if (nextApproverId) {
        const workflowApprover = GetEmployeeById(employees, nextApproverId)
        if (workflowApprover && !workflowApprover.activeCompanyProfile.deleted && !filteredEmployees.find((e) => e.id === workflowApprover.id)) {
            filteredEmployees.push(workflowApprover)
        }
    }
    return filteredEmployees
}
export const syncExpenseSplits = (expense: any, splitType: SplitTypeEnum) => {
    const expenseWithSyncedSplits = expense
    if (splitType === SplitTypeEnum.ITEM && expense.splits.length > 0) {
        // If item split, parse the currency input properly.
        expenseWithSyncedSplits.splits =
            expense.splits?.map((itemSplit: ExpenseSplit) => ({
                ...itemSplit,
                netPrice: parseCurrencyInput(itemSplit.netPrice),
                taxPrice: parseCurrencyInput(itemSplit.taxPrice),
                grossPrice: parseCurrencyInput(itemSplit.grossPrice),
            })) ?? [] // TODO: how can this be empty?
    } else {
        // If invoice split or item is now empty, make it an invoice split and adjust according to percentage.
        expenseWithSyncedSplits.splitType = SplitTypeEnum.SPLIT
        expenseWithSyncedSplits.splits =
            expense.splits?.map((invoiceSplit: InvoiceSplit) => ({
                ...invoiceSplit,
                taxRate: expense.taxRate,
                netPrice: (expense.totalNetPrice * invoiceSplit.percentage) / 100,
                taxPrice: (expense.totalTaxPrice * invoiceSplit.percentage) / 100,
                grossPrice: (expense.totalGrossPrice * invoiceSplit.percentage) / 100,
            })) ?? [] // If form doesnt have split (perdiem/mileage, make it an empty array)
    }
    return expenseWithSyncedSplits
}
