const fallbackCopyTextToClipboard = (text: string) => {
    const textArea = document.createElement("textarea")
    textArea.value = text

    // Avoid scrolling to bottom
    textArea.style.top = "0"
    textArea.style.left = "0"
    textArea.style.position = "fixed"

    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()

    try {
        const successful = document.execCommand("copy")
        if (!successful) {
            console.error("Fallback copy failed")
        }
    } catch (error) {
        console.error("Fallback: Unable to copy", error)
    }

    document.body.removeChild(textArea)
}

export const copyTextToClipboard = (text: string) => {
    if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text)
        return
    }
    navigator.clipboard.writeText(text).then(
        () => {},
        (error) => {
            console.error("Async: Could not copy text: ", error)
        },
    )
}
