import {
    ApprovalScopeEnum,
    Expense,
    ExpenseKindEnum,
    ExpensePaymentFlowInformationEnum,
    ExpenseStatusEnum,
    Hospitality,
    InboxInvoice,
    ItemSplit,
    Mileage,
    PerDiem,
    PriceSourceEnum,
    RightEnum,
    SplitTypeEnum,
    TripFolder,
} from "@finway-group/shared/lib/models"
import { Employee, GetEmployeeById } from "@finway-group/shared/lib/models/user/employee.model"
import { roundNumberTo2Decimals, toAmount, toDinero } from "@finway-group/shared/lib/utils"
import { calculateNetPriceFromGrossPriceAndTaxRate } from "@finway-group/shared/lib/utils/expense.utils"
import { Modal } from "antd"
import { FormInstance } from "antd/lib/form"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import { RouterChildContext } from "react-router-dom"
import { useDebouncedCallback } from "use-debounce/lib"

import { MenuInfo } from "Components/form/formAddExpenseItemRow"
import { ExpenseData, Filler } from "Components/modals/expenseCreateForm.context"
import ExpenseInitializer from "Features/pages/expenses/expenseInitializer"
import { DEBOUNCE_DURATION_LONG, FINWAY_ADMIN_EMAIL, NO_TAX_RATE } from "Shared/config/consts"
import { useTaxes } from "Shared/hooks/tax.hooks"
import i18n from "Shared/locales/i18n"
import { ApprovalProcessService, AuthzService, ExpenseHttpService, ExpenseService, NotificationService, TaxService } from "Shared/services"
import DialogService from "Shared/services/dialog.service"
import { NotificationDurationSec, NotificationTypeEnum } from "Shared/services/notification.service"
import store from "Shared/store"
import {
    approveExpense,
    approveFolder,
    deleteExpense,
    detachOneExpense,
    rejectExpense,
    reviewExpense,
    reviewMultipleExpenses,
    sendReminder,
    setAllExpensesAsDone,
    setExpenseDone,
    setExpensePaid,
    setMultipleExpensesAsDoneByExpenseIds,
    setMultipleExpensesAsPaid,
    submitFolder,
    updateExpense,
} from "Shared/store/actions/expense/expenseActions"
import { ExpenseCountInterface } from "Shared/store/actions/expense/expenseTypes"
import { RootState } from "Shared/store/rootState.interface"
import {
    createCancelExpenseRequestTranslationStrings,
    createLinkToRequestFunction,
    determineExpensePrice,
    getExpenseModelByKind,
    isFolderExpense,
    syncTotalExpenseAmountsToItems,
} from "Shared/utils/expense.utils"
import { getFolderProgressionInfo } from "Shared/utils/folder.utils"
import { addPrices, copyDeeply, getTax, isExtendedAlphanumeric, isRightGranted, subtractPrices } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

import { useCostCenters } from "./costCenter.hooks"
import { useEmployees } from "./employee.hooks"
import { useRolesMap } from "./role.hooks"
import { useActiveCompanyProfile } from "./user.hooks"
import { useVendors } from "./vendor.hooks"

export const useExpenseCounts = (): ExpenseCountInterface => useSelector(({ expenses }: RootState) => expenses.counts)

export const useInboxInvoicesCount = () => useSelector(({ inboxInvoices }: RootState) => inboxInvoices.count)

export const useShouldRefetchExpenses = () => useSelector(({ expenses }: RootState) => expenses.shouldRefetch)

export const useShouldRefetchInbox = () => useSelector(({ inboxInvoices }: RootState) => inboxInvoices.shouldRefetch)

export const useExpenses = (excludeDeleted: boolean = false) => {
    const { items, totalPages, totalDocs, counts } = useSelector(({ expenses }: RootState) => expenses)
    let expenses = items.map((expense: Expense) => new Expense(expense))

    if (excludeDeleted) {
        expenses = expenses?.filter((expense: Expense) => !expense.deleted)
    }

    return {
        expenses,
        totalPages,
        totalDocs,
        counts,
    }
}

export const useInboxInvoices = () => {
    const { items, totalPages, totalDocs, count } = useSelector(({ inboxInvoices }: RootState) => inboxInvoices)
    const inboxInvoices = items.map((i: InboxInvoice) => new InboxInvoice(i))

    return {
        inboxInvoices,
        totalPages,
        totalDocs,
        count,
    }
}

export const useSubscriptions = (excludeDeleted: boolean = false) => {
    const { expenses } = useExpenses(excludeDeleted)
    return expenses.filter((expense: Expense) => expense.isSubscription() && expense.isInitialSubscription())
}

export const useExpense = () => {
    const expenseState = useSelector(({ expenses }: RootState) => expenses.item)
    const ExpenseModel = getExpenseModelByKind(expenseState.kind)

    const expense = new ExpenseModel(expenseState)

    return expense
}

export const useActiveTab = () => useSelector(({ expenses }: RootState) => expenses.activeTab)

export const useExpenseSwitching = (expenseId: string, expenseStatus: ExpenseStatusEnum, requestedBy: string) => {
    const [adjacentExpenses, setAdjacentExpenses] = useStateIfMounted({ prevExpense: "", nextExpense: "" })
    const [isLoading, setIsLoading] = useStateIfMounted(false)
    const [hasError, setHasError] = useStateIfMounted(false)
    const [table, adjacentExpenseLabel] = ExpenseService.getTableAndAdjacentExpenseLabel(expenseStatus, requestedBy)

    const { prevExpense, nextExpense } = adjacentExpenses

    useEffect(() => {
        if (expenseId) {
            setIsLoading(true)
            ExpenseHttpService.getAdjacentExpenses(expenseId, table)
                .then(({ prev, next }: { prev: string; next: string }) => setAdjacentExpenses({ prevExpense: prev, nextExpense: next }))
                .catch(() => setHasError(true))
                .finally(() => setIsLoading(false))
        }
    }, [expenseId])

    return { prevExpense, nextExpense, isExpenseSwitchingLoading: isLoading, hasError, adjacentExpenseLabel }
}

export const useRequestActionDisabled = () => {
    const employees = useEmployees({ excludeDeleted: true })
    const vendors = useVendors(true)
    const costCenters = useCostCenters(true)
    const rolesMap = useRolesMap({ excludeDeleted: true })
    const activeCompanyProfile = useActiveCompanyProfile()

    const filteredEmployees = employees.filter((employeeToFilter) => {
        const role = rolesMap.get(employeeToFilter.activeCompanyProfile?.roleId)
        return (
            employeeToFilter.activeCompanyProfile &&
            !employeeToFilter.activeCompanyProfile.deleted &&
            ((isRightGranted(role?.rights, RightEnum.EXPENSE__ALL__APPROVE) && employeeToFilter.email !== FINWAY_ADMIN_EMAIL) ||
                (isRightGranted(role?.rights, RightEnum.EXPENSE__TEAM__APPROVE) && employeeToFilter.activeCompanyProfile.team === activeCompanyProfile.team))
        )
    })

    return vendors.length === 0 || costCenters.length === 0 || filteredEmployees.length === 0
}

type UpdateClosure<T> = (split: ItemSplit) => T

export const useExpenseHook = (
    expense: Expense,
    expenseForm: FormInstance<Partial<Expense<string> | PerDiem | Hospitality | Mileage | TripFolder> | Filler>,
    updateExpense: (data: ExpenseData) => void,
    isLinked: boolean,
    setIsLinked: (linked: boolean) => void,
    isForcedSync?: boolean,
) => {
    const taxValues = useTaxes()
    // when isForcedSync is true, where changes in the items MUST affect the total amounts to keep them synced at all times.
    const [shouldRecalculateAmounts, setShouldRecalculateAmounts] = useStateIfMounted(false)

    const recalculateAmountsCallback = useDebouncedCallback(() => setShouldRecalculateAmounts(true), 200)

    const onAddItem = (addItemAtTheBeginning = false) => {
        const taxValueArray = ExpenseInitializer.initializeNewItemSplit(taxValues)

        updateExpense({
            splits: addItemAtTheBeginning ? [taxValueArray, ...expense.splits] : [...expense.splits, taxValueArray],
            splitType: SplitTypeEnum.ITEM,
        })
    }

    const onSelect = ({ key, domEvent }: MenuInfo): void => {
        domEvent.stopPropagation()
        switch (key) {
            case "add_item":
                onAddItem()
                break
            case "delete_all_items":
                updateExpense({ splits: [] })
                break
            case "link":
                setIsLinked(!isLinked)
                break
            default:
                break
        }
    }

    const applyUpdateClosureAtIndex = <T>(split: ItemSplit, index: number, targetIndex: number, updateClosure: UpdateClosure<T>): ItemSplit =>
        index === targetIndex ? { ...split, ...updateClosure(split) } : split

    const updateItemSplit = <T>(index: number, updateClosure: UpdateClosure<T>): void => {
        const splits = expenseForm.getFieldValue("splits")
        const updatedSplits = splits.map((split: ItemSplit, i: number) => applyUpdateClosureAtIndex(split, i, index, updateClosure))

        updateExpense({ splits: updatedSplits })

        if (isForcedSync && expense.splits?.length > 0 && expense.splitType === SplitTypeEnum.ITEM) {
            recalculateAmountsCallback.callback()
        }
    }

    const onNameChange = useDebouncedCallback((name: string, index: number) => {
        updateItemSplit(index, () => ({ name }))
    }, DEBOUNCE_DURATION_LONG)

    const onTotalNetPriceUpdate = useDebouncedCallback((totalNetPrice: number) => {
        const { taxRate } = expense
        const dineroTotalNet = toDinero(totalNetPrice)
        const totalTaxPrice = taxRate && taxRate?._id !== NO_TAX_RATE ? toAmount(dineroTotalNet.percentage(getTax(taxValues, taxRate?._id))) : expense.totalTaxPrice
        const totalGrossPrice = toAmount(dineroTotalNet.add(toDinero(totalTaxPrice)))
        updateExpense({
            totalNetPrice,
            ...(isLinked ? { totalGrossPrice, totalTaxPrice } : {}),
        })
    }, DEBOUNCE_DURATION_LONG)

    const onTaxRateUpdate = (taxRateId: string, priceSource: PriceSourceEnum) => {
        if (taxRateId === NO_TAX_RATE) {
            updateExpense({ taxRate: { _id: NO_TAX_RATE } })
            return
        }

        const taxRate = TaxService.getTaxById(taxRateId)

        const {
            taxPrice: totalTaxPrice,
            grossPrice: totalGrossPrice,
            netPrice: totalNetPrice,
        } = determineExpensePrice(taxRate.taxRate, priceSource === PriceSourceEnum.NET_PRICE ? expense.totalNetPrice : expense.totalGrossPrice, priceSource)

        updateExpense({
            taxRate,
            ...(isLinked ? { totalTaxPrice, totalGrossPrice, totalNetPrice } : {}),
        })
    }

    const onTaxRateSplitUpdate = (index: number, taxRateId: string, priceSource: PriceSourceEnum) => {
        // Note: for general tax rate update after split tax rate update, see getCommonTaxRateAcrossItems
        updateItemSplit(index, (itemSplit: ItemSplit) => {
            if (taxRateId === NO_TAX_RATE) return { taxRate: { _id: NO_TAX_RATE } }
            const taxRate = TaxService.getTaxById(taxRateId)

            const { taxPrice, grossPrice, netPrice } = determineExpensePrice(
                taxRate.taxRate,
                priceSource === PriceSourceEnum.NET_PRICE ? itemSplit.netPrice : itemSplit.grossPrice,
                priceSource,
            )

            return {
                taxRate,
                ...(isLinked ? { taxPrice, grossPrice, netPrice } : {}),
            }
        })
    }

    const onTotalTaxPriceUpdate = (totalTaxPrice: number, priceSource: PriceSourceEnum) => {
        // we unset the taxRate when we manually update the total tax price
        if (!isLinked) {
            updateExpense({ totalTaxPrice })
            return
        }

        const updateObject = { totalTaxPrice, taxRate: { _id: NO_TAX_RATE } }

        switch (priceSource) {
            case PriceSourceEnum.GROSS_PRICE:
                updateExpense({ ...updateObject, totalNetPrice: subtractPrices(expense.totalGrossPrice, [totalTaxPrice]) })
                break
            case PriceSourceEnum.NET_PRICE:
                updateExpense({ ...updateObject, totalGrossPrice: addPrices([expense.totalNetPrice, totalTaxPrice]) })
                break
            default:
                break
        }
    }

    const onTotalGrossPriceUpdate = useDebouncedCallback((totalGrossPrice: number) => {
        if (!isLinked) {
            updateExpense({ totalGrossPrice })
            return
        }

        if (expense.taxRate && expense.taxRate?._id !== NO_TAX_RATE) {
            const tax = getTax(taxValues, expense.taxRate?._id)
            const totalNetPrice = calculateNetPriceFromGrossPriceAndTaxRate(totalGrossPrice, tax)

            updateExpense({
                totalNetPrice,
                totalTaxPrice: toAmount(toDinero(totalNetPrice).percentage(tax)),
                totalGrossPrice,
            })
        } else {
            const netPriceDifference = totalGrossPrice - expense.totalTaxPrice
            const totalNetPrice = netPriceDifference < 0 ? 0 : roundNumberTo2Decimals(netPriceDifference)
            const totalTaxPrice = netPriceDifference < 0 ? 0 : expense.totalTaxPrice
            updateExpense({ totalNetPrice, totalGrossPrice, totalTaxPrice })
        }
    }, DEBOUNCE_DURATION_LONG)

    const calculateTaxPrice = ({ taxRate, taxPrice }: ItemSplit, netPrice: number) => {
        if (taxRate?._id !== NO_TAX_RATE) {
            return toAmount(toDinero(netPrice).percentage(getTax(taxValues, taxRate?._id!)))
        }
        return toAmount(toDinero(taxPrice))
    }

    const onNetPriceUpdate = (index: number, netPrice: number) => {
        updateItemSplit(index, (itemSplit) => {
            const taxPrice = calculateTaxPrice(itemSplit, netPrice)

            const updatedItemSplit = {
                netPrice,
                ...(isLinked
                    ? {
                          grossPrice: toAmount(toDinero(netPrice).add(toDinero(taxPrice))),
                          taxPrice,
                      }
                    : {}),
            }

            return updatedItemSplit
        })
    }

    const onTaxPriceUpdate = (index: number, taxPrice: number, priceSource: PriceSourceEnum) => {
        // we unset the taxRate when we manually update the total tax price
        if (!isLinked) {
            updateItemSplit(index, (_itemSplit: ItemSplit) => ({ taxPrice }))
            return
        }

        const updateObject = {
            taxPrice,
            taxRate: { _id: NO_TAX_RATE },
        }

        switch (priceSource) {
            case PriceSourceEnum.NET_PRICE:
                updateItemSplit(index, (itemSplit: ItemSplit) => ({
                    ...updateObject,
                    grossPrice: addPrices([itemSplit.netPrice, taxPrice]),
                }))
                break
            case PriceSourceEnum.GROSS_PRICE:
                updateItemSplit(index, (itemSplit: ItemSplit) => ({
                    ...updateObject,
                    netPrice: subtractPrices(itemSplit.grossPrice, [taxPrice]),
                }))
                break
            default:
                break
        }
    }

    const onGrossPriceUpdate = (index: number, grossPrice: number) => {
        updateItemSplit(index, ({ taxRate, taxPrice }: ItemSplit) => {
            if (!isLinked) return { grossPrice }

            if (taxRate?._id !== NO_TAX_RATE) {
                const tax = getTax(taxValues, taxRate?._id!)
                const netPrice = calculateNetPriceFromGrossPriceAndTaxRate(grossPrice, tax)

                return {
                    taxPrice: toAmount(toDinero(netPrice).percentage(tax)),
                    netPrice,
                    grossPrice,
                }
            }

            const netPriceDifference = toAmount(toDinero(grossPrice).subtract(toDinero(taxPrice)))

            if (netPriceDifference <= 0) return { netPrice: 0, taxPrice: 0, grossPrice }

            return { netPriceDifference, taxPrice, grossPrice }
        })
    }

    useEffect(() => {
        if (shouldRecalculateAmounts) {
            updateExpense(syncTotalExpenseAmountsToItems(expense.splits))
            setShouldRecalculateAmounts(false)
        }
    }, [shouldRecalculateAmounts])

    return {
        onAddItem,
        onSelect,
        onTaxRateUpdate,
        onTaxPriceUpdate,
        onNetPriceUpdate,
        onTaxRateSplitUpdate,
        onTotalNetPriceUpdate,
        onTotalTaxPriceUpdate,
        onTotalGrossPriceUpdate,
        onGrossPriceUpdate,
        onNameChange,
        setShouldRecalculateAmounts,
    }
}

export const setMultipleExpensesAsDoneAndReturnFailures = async (expensesArrayOrExpenseQuery: Array<Expense> | string) => {
    if (Array.isArray(expensesArrayOrExpenseQuery)) {
        const expenseIds = expensesArrayOrExpenseQuery.map(({ id }) => id)
        return setMultipleExpensesAsDoneByExpenseIds(expenseIds)(store.dispatch)
    }

    return setAllExpensesAsDone(expensesArrayOrExpenseQuery)(store.dispatch)
}

export const markMultipleExpensesAsDone = async (expensesArrayOrExpenseQuery: Array<Expense> | string) => {
    try {
        const filteredOutExpenseNumbers = await setMultipleExpensesAsDoneAndReturnFailures(expensesArrayOrExpenseQuery)

        if (filteredOutExpenseNumbers.length) {
            NotificationService.send(
                NotificationTypeEnum.WARNING,
                i18n.t(`notification:request.exported_bulk_partial.title`),
                i18n.t(`notification:request.exported_bulk_partial.message`, { expenses: filteredOutExpenseNumbers.join(", ") }),
                0,
            )
        } else {
            // TODO: check if this notification is ok w/ product
            NotificationService.send(NotificationTypeEnum.SUCCESS, i18n.t(`notification:request.exported_bulk.title`), i18n.t(`notification:request.exported_bulk.message`))
        }
    } catch (err) {
        if (err === "cancelled") return
        NotificationService.showErrorNotificationBasedOnResponseError(err, i18n.t(`error:request.exported.title`))
        throw err
    }
}

export const applyExpenseUpdate = (expense: Expense, notificationLabel: string, skipHandling = false, refetchExpenseTables = true) =>
    updateExpense(
        expense.id,
        expense,
        refetchExpenseTables,
    )(store.dispatch)
        .then(() => {
            if (!skipHandling)
                NotificationService.send(
                    NotificationTypeEnum.SUCCESS,
                    i18n.t(`notification:request.${notificationLabel}.title`),
                    i18n.t(`notification:request.${notificationLabel}.message`),
                )
        })
        .catch((err) => {
            if (err === "cancelled") return
            if (!skipHandling) NotificationService.showErrorNotificationBasedOnResponseError(err, i18n.t(`error:request.${notificationLabel}.title`))
            throw err
        })

export const detachExpense = (expensePara: Expense) => {
    const expense = copyDeeply(expensePara, Expense)
    return detachOneExpense(expense.id)(store.dispatch)
}

export const restoreExpense = (expensePara: Expense, currentUserId: string) => {
    const expense = copyDeeply(expensePara, Expense)
    expense.deleted = false
    expense.dateDeleted = undefined

    return applyExpenseUpdate(expense, "restored")
}

export const sendEmailReminder = (employees: Array<Employee>, expense: Expense) =>
    sendReminder(expense.id)(store.dispatch)
        .then(() => {
            if (expense.status === ExpenseStatusEnum.DOCS_NEEDED) {
                const requester = GetEmployeeById(employees, expense.requestedBy._id)
                if (!requester) {
                    NotificationService.send(NotificationTypeEnum.WARNING, "", i18n.t("notification:request.reminder_not_sent.message"))
                    return
                }
            } else {
                const approver = ApprovalProcessService.getNextApprover(expense.approvalProcesses)

                if (!approver) {
                    NotificationService.send(NotificationTypeEnum.WARNING, "", i18n.t("notification:request.reminder_not_sent.message"))
                    return
                }
            }
            NotificationService.send(NotificationTypeEnum.SUCCESS, i18n.t("notification:request.reminder_sent.title"), i18n.t("notification:request.reminder_sent.message"))
        })
        .catch((error: any) => {
            NotificationService.showErrorNotificationBasedOnResponseError(error, i18n.t("error:request.send_reminder.title"))
        })

export const reviewFolder = async (folder: TripFolder, subExpenses: Array<Expense> = []) => {
    const { processableSubExpenses } = getFolderProgressionInfo(folder, subExpenses)

    if (processableSubExpenses.some((processableSubExpense) => processableSubExpense.invoiceNumber && !isExtendedAlphanumeric(processableSubExpense.invoiceNumber))) {
        try {
            await DialogService.confirmDatevWarningPromise(true)
        } catch {
            return
        }
    }

    return reviewMultipleExpenses([folder.id, ...processableSubExpenses.map((e) => e.id)])(store.dispatch).catch((err) => {
        NotificationService.showErrorNotificationBasedOnResponseError(err, i18n.t("error:error"))
    })
}

export const approveExpenseRequest = async (expense: Expense, scope: ApprovalScopeEnum, user: Employee, approverName?: string) => {
    if (AuthzService.isLoggedInUserAllowedToApproveExpense(expense) && approverName) {
        if (!(await ExpenseService.confirmAdminNotOwnApproval(expense, scope, user, approverName))) return
    }
    return approveExpense(
        expense.id,
        scope,
    )(store.dispatch)
        .then(() => NotificationService.send(NotificationTypeEnum.SUCCESS, i18n.t(`notification:request.approved.title`), i18n.t(`notification:request.approved.message`)))
        .catch((err) => {
            if (err === "cancelled") return
            NotificationService.showErrorNotificationBasedOnResponseError(err, i18n.t(`error:request.approved.title`))
            throw err
        })
}

export const rejectExpenseRequest = ({
    expense,
    rejectionNote,
    history,
    scope,
}: {
    expense: Expense
    rejectionNote: string
    history: RouterChildContext["router"]["history"]
    scope?: ApprovalScopeEnum
}) =>
    rejectExpense(
        expense.id,
        rejectionNote,
        scope,
    )(store.dispatch)
        .then(() => {
            if (isFolderExpense(expense)) {
                NotificationService.send(
                    NotificationTypeEnum.WARNING,
                    i18n.t(`notification:trip_folder.rejected.title`),
                    i18n.t(`notification:trip_folder.rejected.message`),
                    NotificationDurationSec.REQUEST_CREATION,
                    undefined,
                    {
                        onClick: createLinkToRequestFunction(expense, history),
                        text: i18n.t("notification:trip_folder.rejected.link"),
                    },
                )
            } else {
                NotificationService.send(
                    NotificationTypeEnum.WARNING,
                    i18n.t(`notification:request.rejected.title`),
                    i18n.t(`notification:request.rejected.message`),
                    NotificationDurationSec.REQUEST_CREATION,
                    undefined,
                    {
                        onClick: createLinkToRequestFunction(expense, history),
                        text: i18n.t("notification:request.rejected.link"),
                    },
                )
            }
        })
        .catch((err) => {
            if (err === "cancelled") return
            NotificationService.showErrorNotificationBasedOnResponseError(err, i18n.t(`error:request.rejected.title`))
            throw err
        })

export const cancelExpenseRequest = (expense: Expense, shouldUseDeleteLabel = false, onCancel?: () => void) => {
    const { confirm } = Modal
    const isTripFolder = expense.kind === ExpenseKindEnum.TRIP_FOLDER

    const confirmTranslations = createCancelExpenseRequestTranslationStrings(isTripFolder, shouldUseDeleteLabel, i18n.t)

    const translationType = isTripFolder ? "trip_folder" : "request"

    confirm({
        ...confirmTranslations,
        type: "warning",
        onOk() {
            return new Promise<void>((resolve, reject) => {
                deleteExpense(expense.id)(store.dispatch)
                    .then(() => {
                        NotificationService.send(
                            NotificationTypeEnum.WARNING,
                            i18n.t(`notification:${translationType}.cancelled.title`),
                            i18n.t(`notification:${translationType}.cancelled.message`),
                        )
                        resolve()
                    })
                    .catch((err) => {
                        NotificationService.showErrorNotificationBasedOnResponseError(err, i18n.t(`error:${translationType}.cancel.title`))
                        reject()
                    })
            })
                .then(() => onCancel && onCancel())
                .catch((err) => NotificationService.showErrorNotificationBasedOnResponseError(err, i18n.t(`error:${translationType}.cancel.title`)))
        },
        onCancel() {},
    })
}

export const approveFolderRequest = async (folder: TripFolder, scope: ApprovalScopeEnum, user: Employee, approverName?: string) => {
    if (AuthzService.isLoggedInUserGlobalApprover() && approverName) {
        if (!(await ExpenseService.confirmAdminNotOwnApproval(folder, scope, user, approverName))) return
    }

    return approveFolder(
        folder.id,
        scope,
    )(store.dispatch)
        .then(() => NotificationService.send(NotificationTypeEnum.SUCCESS, i18n.t(`notification:request.approved.title`), i18n.t(`notification:request.approved.message`)))
        .catch((err) => {
            if (err === "cancelled") return
            NotificationService.showErrorNotificationBasedOnResponseError(err, i18n.t(`error:request.approved.title`))
            throw err
        })
}

export const handleTripFolderSubmit = (folder: TripFolder) =>
    submitFolder(folder.id)(store.dispatch)
        .then(() => {
            NotificationService.send(NotificationTypeEnum.SUCCESS, i18n.t(`notification:trip_folder.submitted.title`), i18n.t(`notification:trip_folder.submitted.message`))
        })
        .catch((err) => {
            if (err === "cancelled") return
            NotificationService.showErrorNotificationBasedOnResponseError(err, i18n.t(`error:request.rejected.title`))
            throw err
        })

export const markExpenseAsReviewed = async (expense: Expense, refetchExpenseTables = true) => {
    // confirm reporting only status
    const PAYMENT_FLOW_TO_CONFIRM = [ExpensePaymentFlowInformationEnum.ALREADY_PAID, ExpensePaymentFlowInformationEnum.ALREADY_APPROVED_AND_PAID]
    if (
        expense.paymentFlowInformation &&
        PAYMENT_FLOW_TO_CONFIRM.includes(expense.paymentFlowInformation) &&
        !(await DialogService.confirmPaymentFlowInformationAlreadyPaidWarningOnReview(expense.paymentFlowInformation))
    )
        return

    return reviewExpense(
        expense.id,
        refetchExpenseTables,
    )(store.dispatch)
        .then(() => NotificationService.send(NotificationTypeEnum.SUCCESS, i18n.t(`notification:request.reviewed.title`), i18n.t(`notification:request.reviewed.message`)))
        .catch((err) => {
            if (err === "cancelled") return
            NotificationService.showErrorNotificationBasedOnResponseError(err, i18n.t(`error:request.reviewed.title`))
            throw err
        })
}

export const markExpenseAsPaid = (expense: Expense, refetchExpenseTables = true) =>
    setExpensePaid(
        expense.id,
        refetchExpenseTables,
    )(store.dispatch)
        .then(() => NotificationService.send(NotificationTypeEnum.SUCCESS, i18n.t(`notification:request.paid.title`), i18n.t(`notification:request.paid.message`)))
        .catch((err) => {
            if (err === "cancelled") return
            NotificationService.showErrorNotificationBasedOnResponseError(err, i18n.t(`error:request.paid.title`))
            throw err
        })

export const markExpenseAsDone = (expense: Expense, refetchExpenseTables = true) =>
    setExpenseDone(
        expense.id,
        refetchExpenseTables,
    )(store.dispatch)
        .then(() => {
            if (isFolderExpense(expense)) {
                NotificationService.send(NotificationTypeEnum.SUCCESS, i18n.t(`notification:trip_folder.exported.title`), i18n.t(`notification:trip_folder.exported.message`))
            } else {
                NotificationService.send(NotificationTypeEnum.SUCCESS, i18n.t(`notification:request.exported.title`), i18n.t(`notification:request.exported.message`))
            }
        })
        .catch((err) => {
            if (err === "cancelled") return
            NotificationService.showErrorNotificationBasedOnResponseError(err, i18n.t(`error:request.exported.title`))
            throw err
        })

export const markMultipleExpensesAsReviewed = async (expenses: Array<Expense>) => {
    // confirm reporting only status
    const PAYMENT_FLOW_TO_CONFIRM = [ExpensePaymentFlowInformationEnum.ALREADY_PAID, ExpensePaymentFlowInformationEnum.ALREADY_APPROVED_AND_PAID]
    const paymentFlowSet = expenses.find((expense) => expense.paymentFlowInformation && PAYMENT_FLOW_TO_CONFIRM.includes(expense.paymentFlowInformation))
    if (paymentFlowSet && !(await DialogService.confirmPaymentFlowsOnBulkReview())) return

    try {
        const expenseIds = expenses.map(({ id }) => id)
        const filteredOutExpenseNumbers = await reviewMultipleExpenses(expenseIds)(store.dispatch)

        if (filteredOutExpenseNumbers.length) {
            NotificationService.send(
                NotificationTypeEnum.WARNING,
                i18n.t(`notification:request.reviewed_bulk_partial.title`),
                i18n.t(`notification:request.reviewed_bulk_partial.message`, { expenses: filteredOutExpenseNumbers }),
                0,
            )
        } else {
            NotificationService.send(NotificationTypeEnum.SUCCESS, i18n.t(`notification:request.reviewed_bulk.title`), i18n.t(`notification:request.reviewed_bulk.message`))
        }
    } catch (err) {
        if (err === "cancelled") return
        NotificationService.showErrorNotificationBasedOnResponseError(err, i18n.t(`error:request.reviewed.title`))
        throw err
    }
}

export const markMultipleExpensesAsPaid = async (expenses: Array<Expense>) => {
    try {
        const expenseIds = expenses.map(({ id }) => id)
        const filteredOutExpenseNumbers = await setMultipleExpensesAsPaid(expenseIds)(store.dispatch)

        if (filteredOutExpenseNumbers.length) {
            NotificationService.send(
                NotificationTypeEnum.WARNING,
                i18n.t(`notification:request.paid_bulk_partial.title`),
                i18n.t(`notification:request.paid_bulk_partial.message`, { expenses: filteredOutExpenseNumbers }),
                0,
            )
        } else {
            NotificationService.send(NotificationTypeEnum.SUCCESS, i18n.t(`notification:request.paid_bulk.title`), i18n.t(`notification:request.paid_bulk.message`))
        }
    } catch (err) {
        if (err === "cancelled") return
        NotificationService.showErrorNotificationBasedOnResponseError(err, i18n.t(`error:request.paid.title`))
        throw err
    }
}
