import { FilterOutlined } from "@ant-design/icons"
import { Button } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"

import FilterModal from "Components/modals/filter.modal"
import { TablesEnum } from "Shared/store/reducers/tableConfigReducer"

import { FilterSectionOptionsInterface } from "./filterTypes"

interface FilterInterface {
    table: TablesEnum
    isFilterModalShowing: boolean
    options: FilterSectionOptionsInterface
    setIsFilterModalShowing: (state: boolean) => void
    onFilter: (filterData: any) => Promise<void>
}

const Filter: React.FC<FilterInterface> = ({ table, isFilterModalShowing, options, setIsFilterModalShowing, onFilter }) => {
    const { t } = useTranslation()

    return (
        <>
            <FilterModal table={table} options={options} isVisible={isFilterModalShowing} onCancel={setIsFilterModalShowing} onSubmit={onFilter} />

            <Button type="default" className="w-100" onClick={() => setIsFilterModalShowing(true)}>
                <FilterOutlined />
                {t("action:filter")}
            </Button>
        </>
    )
}

export default Filter
