import { OcrStatusEnum } from "@finway-group/shared/lib/models"
import { Tag } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"

interface OcrStatusBadgeInterface {
    ocrStatus: OcrStatusEnum
}
const OcrStatusBadge: React.FC<OcrStatusBadgeInterface> = ({ ocrStatus }) => {
    const { t } = useTranslation()

    if (ocrStatus === OcrStatusEnum.STARTED) {
        return <Tag className="ant-tag-yellow mb-3">{t("info:ocr.in_progress")}</Tag>
    }
    if (ocrStatus === OcrStatusEnum.FINISHED) {
        return <Tag className="ant-tag-green mb-3">{t("info:ocr.captured")} </Tag>
    }
    return <Tag className="ant-tag-red mb-3">{t("info:ocr.not_captured")} </Tag>
}

export default OcrStatusBadge
