import { CurrencyEnum } from "@finway-group/shared/lib/models"
import axios from "axios"

import { KYBData } from "Shared/store/reducers/corporateReducer"

const MODEL = "corporate"

const CorporateService = {
    startKYB: async () => {
        const { data } = await axios.post(`/${MODEL}/kyb/start`)
        return data
    },
    createCompanyData: async (companyDetails: any) => {
        const { data } = await axios.post(`/${MODEL}/company`, companyDetails)
        return data
    },
    createUserPasscode: async (tokenizedPassword: string) => {
        const { data } = await axios.post(`/${MODEL}/user/passcode`, { tokenizedPassword })
        return data
    },
    requestEmailVerificationCode: async () => {
        const { data } = await axios.post(`/${MODEL}/email/verification`)
        return data
    },
    requestMobileVerificationCodeRootUser: async () => {
        const { data } = await axios.post(`/${MODEL}/root/mobile/verification`)
        return data
    },
    requestMobileVerificationCode: async () => {
        const { data } = await axios.post(`/${MODEL}/user/mobile/verification`)
        return data
    },
    verifyEmail: async (verificationCode: string) => {
        const { data } = await axios.post(`/${MODEL}/email/verification/submit`, { verificationCode })
        return data
    },
    verifyMobileRootUser: async (verificationCode: string) => {
        const { data } = await axios.post(`/${MODEL}/root/mobile/verification/submit`, { verificationCode })
        return data
    },
    verifyMobile: async (verificationCode: string) => {
        const { data } = await axios.post(`/${MODEL}/user/mobile/verification/submit`, { verificationCode })
        return data
    },
    authenticateUser: async (tokenizedPassword: string) => {
        // prettier-ignore
        const { data: { accessToken } } = await axios.post(`/${MODEL}/user/authenticate`, { tokenizedPassword });
        return accessToken
    },
    // this will trigger the step-up token process
    strongAuthenticateUser: async (tokenizedPassword: string) => {
        // prettier-ignore
        const { data: { accessToken } } = await axios.post(`/${MODEL}/user/strong-authenticate`, { tokenizedPassword })
        return accessToken
    },
    requestStepUpChallenge: async () => axios.get(`/${MODEL}/user/request-otp`),
    verifyStepUpChallenge: async (accessTokenParam: string, verificationCode: string) => {
        // prettier-ignore
        const { data: { accessToken } } = await axios.post(`/${MODEL}/user/verify-otp`, { accessToken: accessTokenParam, verificationCode })
        return accessToken
    },
    // passcodes must be tokenized
    updateUserPasscode: async (oldPasscode: string, newPasscode: string) => {
        const { data } = await axios.put(`/${MODEL}/user/passcode`, { oldTokenizedPasscode: oldPasscode, newTokenizedPasscode: newPasscode })
        return data
    },
    enrollUser: async (employeeId: string) => {
        const { data } = await axios.post(`/${MODEL}/user`, { user: employeeId })
        return data
    },
    enrollAll: async () => {
        const { data } = await axios.post(`/${MODEL}/user/enrollall`)
        return data
    },
    getKYBFormToken: async () => {
        // prettier-ignore
        const { data } = await axios.post(`/${MODEL}/kyb/token`)
        return data
    },
    getKYBProgress: async () => {
        const { data } = await axios.get(`/${MODEL}/kyb`)
        return data as KYBData
    },
    createBusinessAccount: async (accountData: { createAdminUsers: boolean; currency: CurrencyEnum }) => {
        const { data } = await axios.post(`/${MODEL}/account`, accountData)
        return data
    },
    initiatePasscodeRecovery: async () => {
        const { data } = await axios.post(`/${MODEL}/user/passcode/recover`)
        return data
    },
    continuePasscodeRecovery: async (nonce: string, newPasscode: string) => {
        const { data } = await axios.post(`/${MODEL}/user/passcode/recover/resume`, { nonce, newPasscode })
        return data
    },
    updateBusinessAddress: async (updateData: any) => {
        const { data } = await axios.put(`/${MODEL}/address`, updateData)
        return data
    },
    fetchOfficeAddress: async () => {
        const { data } = await axios.get(`/${MODEL}/address`)
        return data
    },
}

export default CorporateService
