import { Input } from "antd"
import Form, { FormInstance } from "antd/lib/form"
import { Store } from "antd/lib/form/interface"
import React from "react"
import { useTranslation } from "react-i18next"

import CompanySettingsRules from "./rules/companySettings.rules"

interface MileageDistanceBufferFormInterface {
    formInstance: FormInstance
    onSubmit: (values: Store) => void
}
const MileageDistanceBufferForm: React.FC<MileageDistanceBufferFormInterface> = ({ formInstance, onSubmit }) => {
    const { t } = useTranslation()
    const rules = CompanySettingsRules()

    return (
        <Form form={formInstance} layout="vertical" onFinish={onSubmit} autoComplete="off">
            <div className="mb-24 finway-gray-text">{t("info:mileage.distance_buffer")}</div>
            <Form.Item
                name={["mileageDistanceBuffer"]}
                label={t("input:company_settings.distance_buffer")}
                key="mileageDistanceBuffer"
                required
                rules={[...rules.mileageDistanceBuffer, ...rules.required]}
            >
                <Input placeholder={t("placeholder:company_settings.distance_buffer")} />
            </Form.Item>
        </Form>
    )
}

export default MileageDistanceBufferForm
