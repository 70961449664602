import { Company } from "@finway-group/shared/lib/models"
import { AutoLogoutEnum } from "@finway-group/shared/lib/models/company/autoLogout.enum"
import { Col, Row, Select, Switch } from "antd"
import Form, { FormInstance } from "antd/lib/form"
import { Store } from "antd/lib/form/interface"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"

import { getPopupAnchor } from "Shared/utils/popup.utils"

interface GeneralTabFormInterface {
    formInstance: FormInstance
    onSubmit: (values: Store) => void
    company: Company
}

const GeneralTabForm: React.FC<GeneralTabFormInterface> = ({ formInstance, onSubmit, company }) => {
    const { t } = useTranslation()

    useEffect(() => {
        if (company) {
            formInstance.setFieldsValue({
                ...company,
            })
        }

        return () => {
            formInstance.resetFields()
        }
    }, [])

    return (
        <Form form={formInstance} layout="vertical" onFinish={onSubmit} autoComplete="off">
            <Row gutter={[16, 16]}>
                <Col span={8}>
                    <Form.Item
                        label={t("input:company_settings.vendor_creation_for_all")}
                        name="isVendorCreationRestricted"
                        /**
                         * Instead of using valuePropName="checked" we use getValueFromEvent to invert the value
                         */
                        getValueFromEvent={(event: boolean) => !event}
                    >
                        <Switch defaultChecked={!company.isVendorCreationRestricted} />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label={t("input:company_settings.vendor_rules_should_overwrite")} name="vendorRulesOverwriteEmployeeRulesEnabled" valuePropName="checked">
                        <Switch />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label={t("input:company_settings.tagging_feature")} name="taggingEnabled" valuePropName="checked" initialValue={true}>
                        <Switch />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[16, 16]}>
                <Col span={8}>
                    <Form.Item label={t("input:company_settings.enable_edit_rights")} name="editingTagsEnabled" valuePropName="checked">
                        <Switch />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label={t("input:company_settings.show_auto_approver_warning")} name="showAutoApproverWarning" valuePropName="checked" initialValue={true}>
                        <Switch />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label={t("input:company_settings.allow_all_to_update_absences")} name="allowAllToUpdateAbsences" valuePropName="checked">
                        <Switch />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[16, 16]}>
                <Col span={8}>
                    <Form.Item label={t("input:company_settings.show_cost_centers_ids")} name="showCostCentersIds" valuePropName="checked" initialValue={true}>
                        <Switch />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label={t("input:company_settings.allow_cost_center_2_creation")} name="allowCostCenter2Creation" valuePropName="checked">
                        <Switch />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[16, 16]}>
                <Col xl={12} sm={24}>
                    <Form.Item label={t("label:auto_logout.title")} name="autoLogout" extra={t("info:auto_logout_suggestion")} initialValue={AutoLogoutEnum.FIFTEEN_MINUTES}>
                        <Select defaultValue={company.autoLogout} getPopupContainer={getPopupAnchor()}>
                            <Select.Option key={AutoLogoutEnum.FIFTEEN_MINUTES} value={AutoLogoutEnum.FIFTEEN_MINUTES}>
                                {t(`label:auto_logout.fifteen_minutes`)}
                            </Select.Option>
                            <Select.Option key={AutoLogoutEnum.ONE_HOUR} value={AutoLogoutEnum.ONE_HOUR}>
                                {t(`label:auto_logout.one_hour`)}
                            </Select.Option>
                            <Select.Option key={AutoLogoutEnum.ONE_WORK_DAY} value={AutoLogoutEnum.ONE_WORK_DAY}>
                                {t(`label:auto_logout.one_work_day`)}
                            </Select.Option>
                            <Select.Option key={AutoLogoutEnum.ONE_FULL_DAY} value={AutoLogoutEnum.ONE_FULL_DAY}>
                                {t(`label:auto_logout.one_full_day`)}
                            </Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    )
}

export default GeneralTabForm
