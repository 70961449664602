import { Company, CompanyFeatureSettings } from "@finway-group/shared/lib/models"
import { Dispatch } from "redux"

import { AuthnService, CompanyService } from "Shared/services"

import { resetCardOverviewData } from "../analytics/analyticsActions"
import { createLoginAction, setIsWeavrAuthenticated } from "../auth/authActions"
import { resetWallet } from "../corporate/corporateActions"
import { resetCostCenter } from "../costCenter/costCenterActions"
import { setLoading } from "../fetcher/fetcherActions"
import { FetcherActionTypes } from "../fetcher/fetcherTypes"
import { resetImportResults } from "../import/importActions"
import { resetLiquidities } from "../liquidity/liquidityActions"
import { resetAllTables } from "../tables/tableActions"
import { CompanyActionTypes, CreateCompanyAction, FetchAllCompaniesAction, MarkCompanyAsDeletedAction, SelectCompanyAction, UpdateCompanyAction } from "./companyTypes"

export const updateCompany = (updatedCompany: Company) => async (dispatch: Dispatch) => {
    const company = await CompanyService.updateCompany(updatedCompany)
    const updateCompanyAction: UpdateCompanyAction = {
        type: CompanyActionTypes.UPDATE_COMPANY,
        company,
    }

    dispatch(updateCompanyAction)
    return company
}

export const updateCompanyFeatureSettings = (id: string, updatedCompanyFeatureSettings: Partial<CompanyFeatureSettings>) => async (dispatch: Dispatch) => {
    const company = await CompanyService.updateFeatureSettings(id, updatedCompanyFeatureSettings)
    const updateCompanyAction: UpdateCompanyAction = {
        type: CompanyActionTypes.UPDATE_COMPANY,
        company,
    }

    dispatch(updateCompanyAction)
    return company
}

export const fetchAllCompanies =
    (shouldRefetch: boolean = true) =>
    async (dispatch: Dispatch) => {
        // temporary until we update shared to include id instead of _id
        const companies = await CompanyService.fetchCompanies()

        const { companyId } = AuthnService.getDataFromAccessToken()

        const selectedCompany = companies.find(({ _id }) => _id === companyId)

        const fetchAllCompaniesAction: FetchAllCompaniesAction = {
            type: CompanyActionTypes.FETCH_ALL_COMPANIES,
            companies,
            company: { ...selectedCompany, shouldRefetch },
        }

        dispatch(fetchAllCompaniesAction)
        return companies
    }

export const createCompany = (newCompany: Company) => async (dispatch: Dispatch) => {
    const company = await CompanyService.createCompany(newCompany)

    const createCompanyAction: CreateCompanyAction = {
        type: CompanyActionTypes.CREATE_COMPANY,
        company,
    }

    dispatch(createCompanyAction)

    return company
}

export const selectCompany = (company: Company) => async (dispatch: Dispatch) => {
    try {
        setLoading()(dispatch)

        const selectCompanyAction: SelectCompanyAction = {
            type: CompanyActionTypes.SELECT_COMPANY,
            company,
        }
        const { accessToken } = await AuthnService.setCurrentCompany(company._id)
        const loginAction = createLoginAction(accessToken)

        dispatch(loginAction)
        dispatch(selectCompanyAction)

        dispatch(resetAllTables())
        dispatch(resetImportResults())
        // reset cost center item
        dispatch(resetCostCenter())
        resetLiquidities()(dispatch)
        resetWalletInformation()(dispatch)
        setIsWeavrAuthenticated(false)(dispatch)
    } catch (err) {
        dispatch({
            type: FetcherActionTypes.LOADED,
            hasLoaded: true,
        })
        throw err
    }
}

export const resetWalletInformation = () => async (dispatch: Dispatch) => {
    resetWallet()(dispatch)
    resetCardOverviewData()(dispatch)
}

export const markCompanyAsDeleted = (id: string) => async (dispatch: Dispatch) => {
    const deletedCompany = await CompanyService.deleteCompany(id)
    const markCompanyAsDeletedAction: MarkCompanyAsDeletedAction = {
        type: CompanyActionTypes.MARK_AS_DELETED,
        company: deletedCompany,
    }

    dispatch(markCompanyAsDeletedAction)
    return deletedCompany
}

export const setCompany = (company: Company) => async (dispatch: Dispatch) => {
    const updateCompanyAction: UpdateCompanyAction = {
        type: CompanyActionTypes.UPDATE_COMPANY,
        company,
    }

    dispatch(updateCompanyAction)
    return company
}
