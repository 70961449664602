import { COLOR } from "@finway-group/shared/lib/consts"
import { Company, SystemRightEnum } from "@finway-group/shared/lib/models"
import { Button, Col, Modal, Row, Tag, Tooltip } from "antd"
import React, { useMemo } from "react"
import { Edit as EditIcon, Trash as TrashIcon } from "react-feather"
import { useTranslation } from "react-i18next"

import { useCompany } from "Shared/hooks/company.hooks"
import { AuthzService } from "Shared/services"
import { getTooltipPopupContainer } from "Shared/utils/helper.utils"

import Icon from "../../../components/icon"

const { confirm } = Modal

interface CompanyCardInterface {
    company: any
    canDelete: boolean
    onEdit: (company: Company) => void
    onDelete: (id: string) => void
    onRestore?: (company: Company) => void
}
const CompanyCard = ({ company, canDelete, onEdit, onDelete, onRestore }: CompanyCardInterface) => {
    const { name, companyIdentifier, iban, bic, _id, defaultCreditorNumber, logo } = company

    const { t } = useTranslation()

    const selectedCompany = useCompany()

    const getCompanyNameInitials = useMemo(() => {
        const arr = name ? name.trim().split(" ") : ""

        return arr.slice(0, arr.length > 3 ? 3 : arr.length).map((word: string) => word[0].toUpperCase())
    }, [name])

    const handleDeleteConfirm = () => {
        confirm({
            title: t("confirm:company.delete.title"),
            content: t("confirm:company.delete.message"),
            cancelText: t("confirm:company.delete.cancel"),
            type: "warning",
            okText: t("confirm:company.delete.confirm"),
            onOk: () => onDelete(_id),
        })
    }

    const handleRestore = () => {
        confirm({
            title: t("confirm:company.restore.title"),
            content: t("confirm:company.restore.message"),
            cancelText: t("confirm:company.restore.cancel"),
            type: "warning",
            okText: t("confirm:company.restore.confirm"),
            onOk: () => onRestore && onRestore({ ...company, deleted: undefined }),
        })
    }

    const CompanyLogo = ({ isMobile }: { isMobile?: boolean }) => {
        const cssClasses = isMobile
            ? "md:hidden w-full h-full max-w-50 max-h-50 border justify-center align-left mr-10 p-2 mb-10 rounded-lg"
            : "hidden md:block w-full h-full max-w-50 max-h-50 border justify-center align-center self-center items-center mr-10 p-2 rounded-lg"
        return (
            <div className={cssClasses}>
                {logo ? (
                    <img className="w-full h-auto max-w-50 max-h-50  flex justify-center items-center" src={logo} alt="logo" />
                ) : (
                    <span className="placeholder-primary text-xl h-44 max-w-50 flex justify-center items-center">{getCompanyNameInitials}</span>
                )}
            </div>
        )
    }

    return (
        <Row gutter={[16, 16]} justify="center">
            <Col md={23} xs={22}>
                <Row className="p-10 border rounded-lg">
                    <CompanyLogo />
                    <Col className="align-left p-10" span={18}>
                        <CompanyLogo isMobile />
                        <Row>
                            <span className="truncate pr-10 placeholder-primary">{companyIdentifier ? `${name} (${companyIdentifier})` : name}</span>
                            {company.isRoot && <Tag className="ant-tag-green ml-md-4">{t("label:root_company")}</Tag>}
                            {company.deleted && <Tag className="ant-tag-red ml-md-4">{t("label:deleted")}</Tag>}
                        </Row>
                        <Row>
                            <span className="overflow-x-wrap pr-10">{iban}</span>
                        </Row>
                    </Col>
                    <Col className=" p-10" md={4} xs={18}>
                        <Row className="md:justify-end">
                            <span>{defaultCreditorNumber}</span>
                        </Row>
                        <Row className="md:justify-end">
                            <span>{bic}</span>
                        </Row>
                    </Col>
                </Row>
            </Col>
            <Col md={1} xs={2}>
                {!company.deleted ? (
                    <>
                        <Row className="mt-12">
                            {AuthzService.isSystemRightGrantedForLoggedInUser(SystemRightEnum.SYS__COMPANY__ALL__UPDATE) && (
                                <div className="cursor-pointer" onClick={() => onEdit(company)}>
                                    <EditIcon size={14} />
                                </div>
                            )}
                        </Row>

                        {selectedCompany && selectedCompany._id !== _id && canDelete && (
                            <Row className="mt-20">
                                {company.isRoot ? (
                                    <div className="cursor-default opacity-50">
                                        <Tooltip title={t("tooltips:root_company_not_deletable")} getPopupContainer={getTooltipPopupContainer}>
                                            <TrashIcon size={14} />
                                        </Tooltip>
                                    </div>
                                ) : (
                                    <div className="cursor-pointer" onClick={handleDeleteConfirm}>
                                        <TrashIcon size={14} />
                                    </div>
                                )}
                            </Row>
                        )}
                    </>
                ) : (
                    <Row className="mt-12">
                        <Button onClick={handleRestore}>
                            <Icon color={COLOR.text} icon="restore" />
                        </Button>
                    </Row>
                )}
            </Col>
        </Row>
    )
}

export default React.memo(CompanyCard)
