import { GREEN, HIGHLIGHT_BLUE, HIGHLIGHT_RED, ORANGE, PURPLE } from "@finway-group/shared/lib/consts"
import { CardDeliveryMethodEnum, ExpenseKindEnum, ExpenseStatusEnum, ExpenseTagColorEnum, cardDeliveryMethodEnumArray } from "@finway-group/shared/lib/models"

export const API_URL = process.env.REACT_APP_API_URL

export const DEV_CLIENT_PORT = 3000

export const NOTIFICATION_OPEN_DURATION = 10

export const FINWAY_ADMIN_EMAIL = process.env.REACT_APP_FINWAY_ADMIN_EMAIL

export const HELP_CENTER_LINK = "http://help.finway.de/"

export const IMPRINT_LINK = "https://finway.de/impressum"

export const TERMS_OF_SERVICES_LINK = "https://finway.de/karten-agb/"

export const PRIVACY_POLICY_LINK = "https://finway.de/datenschutzerklaerung"

export const PAYNETICS_TERMS_OF_AGREEMENT = "https://finway.de/wp-content/uploads/pdf/paynetics-termsofagreement.pdf"

export const WEAVR_TERMS_OF_AGREEMENT = "https://finway.de/wp-content/uploads/pdf/weavr-termsofagreement.pdf"

export const SOURCE_OF_FUNDS_DECLARATION = "https://finway.de/wp-content/uploads/downloads/source-of-funds-declaration.pdf"

export const DEFAULT_IMG_PATH = encodeURIComponent("imgs/default.svg")

export const LOGO_API = "https://logo.clearbit.com"

export const WEBAPP_VERSION = require("../../../package.json").version

export const INTERCOM_APP_ID: string | undefined = process.env.REACT_APP_INTERCOM_NEW_APP_ID

export const INTERCOM_TEST_APP_ID: string | undefined = process.env.REACT_APP_INTERCOM_TEST_APP_ID

export const USERPILOT_APP_ID: string | undefined = process.env.REACT_APP_USERPILOT_APP_ID

export const USERPILOT_TEST_APP_ID: string | undefined = process.env.REACT_APP_USERPILOT_TEST_APP_ID

export const WEAVR_UX_SCRIPT_URL = process.env.REACT_WEAVR_UX_SCRIPT_URL

export const WEAVR_UX_KEY: string | undefined = process.env.REACT_APP_WEAVR_UX_KEY

export const MIXPANEL_TOKEN: string = process.env.REACT_APP_MIXPANEL_TOKEN ? process.env.REACT_APP_MIXPANEL_TOKEN : `Some token`

export const BUGSNAG_API_KEY: string = process.env.REACT_APP_BUGSNAG_API_KEY ? process.env.REACT_APP_BUGSNAG_API_KEY : `Some token`

export const STRIPE_API_KEY: string = process.env.REACT_APP_STRIPE_API_KEY ?? ""

export const REACT_APP_SHOW_INTERNAL_VERSION = process.env.REACT_APP_SHOW_INTERNAL_VERSION === "true"
export const { CI_COMMIT } = process.env
export const { CI_BUILD_TIME } = process.env

export const isDemo = process.env.REACT_APP_DEMO === "true"
export const isLocal = process.env.DEPLOY_ENVIRONMENT === "localhost"
export const isIntercomEnabled = process.env.REACT_APP_INTERCOM_ENABLED === "true"

export const ALLOWED_UPLOAD_FILE_SIZE_IN_MB = 10

export const WARNING_ORANGE = "#ffc069"

export const { MAPBOX_ACCESS_TOKEN } = process.env

export const DEBOUNCE_DURATION_SHORT = 300
export const DEBOUNCE_DURATION_MEDIUM = 500
export const DEBOUNCE_DURATION_LONG = 800

export const AXIOS_EXPIRED_MESSAGE = "expired"
export const AXIOS_CANCELLED_MESSAGE = "cancelled"
export const AXIOS_LOGOUT_MESSSAGE = "logout"

// order is important! index values are used for this array
export const expenseStati = [
    "label:purchase_pending",
    "label:documents_needed",
    "label:invoice_pending",
    "label:in_review",
    "label:declined",
    "label:in_payment",
    "label:in_export",
    "label:processed",
]

export const ExpenseStati = Object.values(ExpenseStatusEnum)

export const US_DATE_DISPLAY_FORMAT = "Do MMM, YYYY"
export const DE_DATE_DISPLAY_FORMAT = "DD. MMM, YYYY"
export const DE_DATE_FORMAT = "DD.MM.YYYY"
export const US_DATE_FORMAT = "MM/DD/YYYY"

export enum NumberFormat {
    US,
    DE,
}

export const barProps = {
    margin: { top: 20, right: 0, bottom: 40, left: 70 },
    innerPadding: 1,
    labelTextColor: "#ffffff",
    labelSkipWidth: 32,
    labelSkipHeight: 20,
    enableGridX: false,
    enableGridY: false,
    theme: { axis: { ticks: { line: { stroke: "#231c5b" }, text: { fill: "#231c5b" } } } },
    animate: true,
    motionStiffness: 50,
    motionDamping: 15,
}

export const pieProps = {
    margin: { top: 30, right: 30, left: 30, bottom: 30 },
    innerRadius: 0.5,
    padAngle: 1.5,
    cornerRadius: 0,
    enableRadialLabels: false,
    radialLabelsSkipAngle: 10,
    radialLabelsTextXOffset: 6,
    radialLabelsTextColor: "#231c5b",
    radialLabelsLinkStrokeWidth: 1,
    radialLabelsLinkOffset: 1,
    radialLabelsLinkDiagonalLength: 10,
    radialLabelsLinkHorizontalLength: 5,
    sliceLabelsTextColor: "#ffffff",
    sliceLabelsSkipAngle: 20,
    animate: true,
    motionStiffness: 50,
    motionDamping: 15,
}

export const defaultCountry = "DE"

export const NO_TAX_RATE = "NO_TAX_RATE"
export const NO_PARENT_COST_CENTER = "NO_PARENT_COST_CENTER"

// TODO: extract to shared
export const ALLOWED_DATEV_BU_CODES = [
    1,
    6,
    7,
    8,
    9,
    16,
    17,
    18,
    19,
    40,
    49,
    ...[...Array(10).keys()].map((i) => i + 50), // 50-59
    91,
    92,
    94,
    95,
    97,
    98,
    99,
    400,
    401,
    402,
    490,
    506,
    507,
    511,
    512,
    516,
    517,
    521,
    522,
    525,
    526,
    527,
    531,
    532,
    536,
    537,
    541,
    542,
    546,
    547,
    551,
    552,
    556,
    557,
    561,
    562,
    566,
    567,
    701,
    702,
    ...[...Array(100).keys()].map((i) => i + 900), // 900-999
    6501,
    6502,
    6506,
    6507,
    6511,
    6512,
    6516,
    6517,
    6521,
    6522,
    6526,
    6527,
    6531,
    6532,
    6536,
    6537,
    6541,
    6542,
    6546,
    6547,
    6551,
    6552,
    6556,
    6557,
    6561,
    6562,
    6566,
    6567,
    6701,
    6702,
    9401,
    9402,
    9501,
    9506,
    9701,
    9510,
]

export const archiveAfterXDaysDefaults = [1, 30, 60, 90, 180, 365]

export const EXPENSE_TAG_ICON_COLORS = {
    [ExpenseTagColorEnum.RED]: HIGHLIGHT_RED,
    [ExpenseTagColorEnum.GREEN]: GREEN,
    [ExpenseTagColorEnum.BLUE]: HIGHLIGHT_BLUE,
    [ExpenseTagColorEnum.YELLOW]: ORANGE,
    [ExpenseTagColorEnum.PURPLE]: PURPLE,
}

export const DEFAULT_PER_DIEM_DESTINATION = "Deutschland"

export const DATA_UPLOAD_SPREADSHEET_TEMPLATE_DISPLAY_NAME = "finway_data_import.xlsx"

export const REIMBURSABLE_DURATION_THRESHOLD_MINUTE = 8 * 60

// TODO: remove after BIN testing on teams
export const DELIVERY_CARD_METHOD_PRICES = {
    [CardDeliveryMethodEnum.STANDARD_DELIVERY]: 0,
    [CardDeliveryMethodEnum.REGISTERED_MAIL]: 9.9,
    [CardDeliveryMethodEnum.FIRST_CLASS_MAIL]: 9.9,
}

// TODO: remove after BIN testing on teams
export const AVAILABLE_DELIVERY_METHODS = cardDeliveryMethodEnumArray.filter((deliveryMethod: CardDeliveryMethodEnum) => deliveryMethod !== CardDeliveryMethodEnum.COURIER)

// TODO FA-161: Refactor and let these values be provided by the backend and store them in redux
export const PER_DIEM_BREAKFAST_RATE = 0.2
export const PER_DIEM_LUNCH_RATE = 0.4
export const PER_DIEM_DINNER_RATE = 0.4

export const MAX_PER_DIEM_DESTINATION_DAYS = 90
export const MAX_PER_DIEM_DESTINATION_MONTHS = 3
export const MIN_PER_DIEM_HOURS = 8

export const DEFAULT_DATE_FORMAT = "DD.MM.yyyy"
export const DEFAULT_TIME_FORMAT = "HH:mm:ss"
export const DEFAULT_DATE_TIME_FORMAT = "DD.MM.yyyy HH:mm:ss"

// Only used to show the warning of potential extra fees on banking payments. If the list is needed in the API then the latter should be moved to SHARED.
export const EURO_COUNTRY_CODE = ["DE", "EL", "AT", "BE", "HR", "CY", "EE", "FI", "FR", "IE", "IT", "LV", "LT", "LU", "MT", "NT", "PT", "SK", "SL", "SE"]

export const WEAVR_NAME_ON_CARD_LIMIT = 20

// taken from weavr API: https://weavr-multi-api.redoc.ly/3.34.0/tag/Managed-Cards
export const WEAVR_NAME_ON_CARD_REGEX = /^[a-zA-Z0-9ßÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÑÒÓÔÕÖØŠÙÚÛÜŸÝŽĄĆČĎĘĹĽŁŃŇŐŔŚȘȚŤŰŹŻäácdæéëeíllnöoøôrštüúuuýž\\-^. '-]*$/

export const SORT_ASC_BY_FIRST_NAME_QUERY_STRING = "&sortBy=asc(firstName)"

export const MILEAGE_MAX_STOP_DISPLAY = 4

// Sentry rates
export const tracesSampleRate = Number(process.env.SENTRY_TRACING_SAMPLE_RATE || "0.1")
export const replaysSessionSampleRate = Number(process.env.SENTRY_REPLAY_SESSION_SAMPLE_RATE || "0")
export const replaysOnErrorSampleRate = Number(process.env.SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE || "0")

export const TODO_STATUSES = [ExpenseStatusEnum.PURCHASE_PENDING, ExpenseStatusEnum.DOCS_NEEDED, ExpenseStatusEnum.INVOICE_PENDING]
export const PAY_AND_EXPORT_STATUSES = [ExpenseStatusEnum.REVIEWED, ExpenseStatusEnum.PAID]
export const DONE_STATUSES = [ExpenseStatusEnum.DONE, ExpenseStatusEnum.DECLINED]
export const IN_PROGRESS_STATUSES = [ExpenseStatusEnum.APPROVED, ExpenseStatusEnum.REVIEWED, ExpenseStatusEnum.PAID]

// TODO: consider using this file only for config consts and create another file that handles the rest
export const ALL_TRIGGER_OBJECTS = "ALL_TRIGGER_OBJECTS"
export const ALL_REIMBURSEMENTS_OPTION = "ALL_REIMBURSEMENTS_OPTION"

export const NOT_SET_VALUE = ""

export const ATTACHABLE_EXPENSE_STATUSES = Object.freeze([
    ExpenseStatusEnum.PURCHASE_PENDING,
    ExpenseStatusEnum.DOCS_NEEDED,
    ExpenseStatusEnum.INVOICE_PENDING,
    ExpenseStatusEnum.APPROVED,
    ExpenseStatusEnum.REVIEWED,
    ExpenseStatusEnum.PAID,
])

export const SELECT_ALL_RESOURCES_OPTION = "ALL"

export const EXPENSE_KINDS_WITHOUT_INVOICE = [ExpenseKindEnum.MILEAGE, ExpenseKindEnum.PER_DIEM]
