import { Button, Form, Modal } from "antd"
import { Store } from "antd/lib/form/interface"
import React from "react"
import { useTranslation } from "react-i18next"

import { parseCurrencyInput } from "Components/currencyInput/config"
import WalletWithdrawalForm from "Components/forms/walletWithdrawal.form"
import { NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import WalletService from "Shared/services/wallet.service"
import { removeStringWhiteSpaces } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

import { WalletWithdrawalChallengeModal } from "./walletWithdrawalChallenge.modal"

interface WalletWithdrawalModalInterface {
    isVisible: boolean
    handleCancel: () => void
}
const WalletWithdrawalModal: React.FC<WalletWithdrawalModalInterface> = ({ isVisible, handleCancel }) => {
    const { t } = useTranslation()
    const [formInstance] = Form.useForm()
    const [isLoading, setIsLoading] = useStateIfMounted<boolean>(false)

    const [showChallengeModal, setShowChallengeModal] = useStateIfMounted<boolean>(false)
    const [wireTransferId, setWireTransferId] = useStateIfMounted<string | undefined>(undefined)

    const onOutgoingWireTransferSuccess = () => {
        NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:wallet.wire_sent.title"), t("notification:wallet.wire_sent.message"))
        setShowChallengeModal(false)
        handleHide()
    }

    const onSubmit = async (values: Store) => {
        setIsLoading(true)

        values.amount = parseCurrencyInput(values.amount)
        if (values.iban) values.iban = removeStringWhiteSpaces(values.iban)

        WalletService.executeOutgoingWireTransfer(values)
            .then((result) => {
                if (result.isChallenged) {
                    const { wireTransferId } = result
                    setWireTransferId(wireTransferId)
                    setShowChallengeModal(true)
                } else {
                    onOutgoingWireTransferSuccess()
                }
            })
            .catch((err) => {
                NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:wallet.wire_send.title"))
                setIsLoading(false)
            })
    }

    const handleHide = () => {
        setIsLoading(false)
        formInstance.resetFields()
        handleCancel()
    }

    return (
        <>
            <Modal
                destroyOnClose={true}
                afterClose={() => formInstance.resetFields()}
                visible={isVisible}
                maskClosable={false}
                title={t("action:wallet.withdrawal")}
                onCancel={handleHide}
                closable={true}
                keyboard={true}
                className="ant-modal--small"
                footer={[
                    <Button
                        key="back"
                        onClick={() => {
                            formInstance.resetFields()
                            handleCancel()
                        }}
                    >
                        {t("action:cancel")}
                    </Button>,
                    <Button
                        key="submit"
                        type="primary"
                        loading={isLoading}
                        onClick={() => {
                            formInstance.submit()
                        }}
                    >
                        {t("action:wallet.submit")}
                    </Button>,
                ]}
            >
                <WalletWithdrawalForm formInstance={formInstance} onSubmit={onSubmit} />
            </Modal>
            <WalletWithdrawalChallengeModal
                isVisible={showChallengeModal}
                wireTransferId={wireTransferId}
                handleCancel={() => {
                    setShowChallengeModal(false)
                    handleHide()
                }}
                onSuccess={onOutgoingWireTransferSuccess}
            />
        </>
    )
}

export default WalletWithdrawalModal
