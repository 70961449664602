import { FeatureFlagEnum } from "@finway-group/shared/lib/models"
import React from "react"

import SettingsContainer from "Features/settings/settings.container"
import { RoutePathEnum } from "Shared/router/RoutePath.enum"
import { AuthzService } from "Shared/services"

import UserIntegrations from "./integrations/userIntegrations"

const UserProfileSettings = React.lazy(() => import("./user/userProfileSettings"))
const ToolsSecurityContainer = React.lazy(() => import("./toolsAndSecurity/toolsAndSecurityContainer"))
const UserNotificationSettingsContainer = React.lazy(() => import("./notifications/userNotificationSettingsContainer"))
const UserActiveSessionsSettings = React.lazy(() => import("./activeSessions/activeSessionsSettings"))

const UserSettingsContainer = () => {
    const menuList = [
        { link: RoutePathEnum.SETTINGS_PROFILE, titleKey: "label:profile", requiredFeatureFlags: [], canSee: true, component: <UserProfileSettings />, isDefault: true },
        { link: RoutePathEnum.SETTINGS_SECURITY, titleKey: "label:tool_settings_security", requiredFeatureFlags: [], canSee: true, component: <ToolsSecurityContainer /> },
        {
            link: RoutePathEnum.SETTINGS_USER_INTEGRATIONS,
            titleKey: "label:integrations",
            requiredFeatureFlags: [FeatureFlagEnum.PAID_VERSION],
            canSee: AuthzService.isLoggedInUserAllowedToCreateExpenses(),
            component: <UserIntegrations />,
        },
        {
            link: RoutePathEnum.SETTINGS_USER_NOTIFICATIONS,
            titleKey: "label:notifications.title",
            requiredFeatureFlags: [FeatureFlagEnum.PAID_VERSION],
            canSee: true,
            component: <UserNotificationSettingsContainer />,
        },
        {
            link: RoutePathEnum.SETTINGS_USER_ACTIVE_SESSIONS,
            titleKey: "label:active_sessions",
            requiredFeatureFlags: [],
            canSee: true,
            component: <UserActiveSessionsSettings />,
        },
    ]

    return <SettingsContainer menuList={menuList} defaultPath={RoutePathEnum.SETTINGS_USER} />
}

export default React.memo(UserSettingsContainer)
