import type { RenderExpandIconProps } from "rc-table/es/interface"
import React from "react"

import CornerDownRightArrowIcon from "Components/icons/cornerDownRightArrow"
import DownCircleIcon from "Components/icons/downCircleIcon"
import UpCircleIcon from "Components/icons/upCircleIcon"

const ExpandTripFolderTableIcon: React.FC<{ props: RenderExpandIconProps<any> }> = ({ props }) => {
    // if row has a children, show circle icon
    if (props.record?.children?.length > 0) {
        return props.expanded ? (
            <div
                role="button"
                onClick={(e) => {
                    e.stopPropagation()
                    props.onExpand(props.record, e)
                }}
            >
                <UpCircleIcon />
            </div>
        ) : (
            <div
                role="button"
                onClick={(e) => {
                    e.stopPropagation()
                    props.onExpand(props.record, e)
                }}
            >
                <DownCircleIcon />
            </div>
        )
    }
    if (props.record?.folderId) {
        // if the category has a parent
        return <CornerDownRightArrowIcon />
    }
    return <></>
}

export default ExpandTripFolderTableIcon
