import { SplitTypeEnum, Tax } from "@finway-group/shared/lib/models"
import { Form, Select, Tooltip } from "antd"
import React from "react"

import DeletedTooltip from "Components/deletedTooltip"
import { NO_TAX_RATE } from "Shared/config/consts"
import { useTaxById, useTaxes } from "Shared/hooks/tax.hooks"
import { doFilterSelect, getTaxLabel } from "Shared/utils/helper.utils"

import { useMainDetailsSectionContext } from "../mainDetailsSection.context"
import { ExpenseSplitCellInterface } from "./expenseSplitCellInterface"

const TaxRateCell: React.FC<ExpenseSplitCellInterface> = ({ field, editable, splitIndex }) => {
    const { splits, onSplitUpdate, expense } = useMainDetailsSectionContext()
    const currentSplit = splits[splitIndex]

    const taxRates = useTaxes(true)
    const currentTaxRate = useTaxById(currentSplit.taxRate?._id)
    const embeddedExpenseTax = currentSplit.taxRate

    // Only let the user edit when the expense is a percentage split
    // TODO: Add a condition, where when it's unlinked, we should
    const isTaxCellEditable = editable && expense.splitType === SplitTypeEnum.SPLIT

    const generateCell = () => {
        if (!isTaxCellEditable)
            return (
                <div>
                    {currentSplit.taxRate && currentSplit.taxRate._id !== NO_TAX_RATE ? (
                        <>
                            <Tooltip placement="bottom" title={currentTaxRate?.buCodeName}>
                                <p className="truncate">
                                    {getTaxLabel({
                                        taxRate: embeddedExpenseTax?.taxRate,
                                        buCode: embeddedExpenseTax?.buCode,
                                        buCodeName: currentTaxRate?.buCodeName,
                                    })}
                                </p>
                            </Tooltip>
                            {currentTaxRate?.deleted && (
                                <DeletedTooltip translationKey="tooltips:vat.was_deleted" deletionDate={currentTaxRate?.dateDeleted} anchorToDocumentBody />
                            )}
                        </>
                    ) : (
                        "-"
                    )}
                </div>
            )

        // return form when the expense doesnt have items, only split
        return (
            <Form.Item name={[field.name, "taxRate", "_id"]} key="taxRate" className="mb-0">
                <Select
                    dropdownMatchSelectWidth={false}
                    dropdownStyle={{ maxWidth: "200px", overflowX: "hidden" }}
                    showSearch
                    filterOption={doFilterSelect}
                    className="w-full text-xs md:text-base no-padding-select"
                    bordered={false}
                    onChange={() => onSplitUpdate(splitIndex)}
                    placeholder="-"
                    dropdownClassName="z-50"
                >
                    <Select.Option key={NO_TAX_RATE} value={NO_TAX_RATE} disabled={false} label="-">
                        -
                    </Select.Option>
                    {currentTaxRate?.deleted && (
                        <Select.Option value={currentTaxRate._id} key={currentTaxRate._id} label={`${currentTaxRate.taxRate}`}>
                            <div>
                                {getTaxLabel({
                                    taxRate: embeddedExpenseTax?.taxRate,
                                    buCode: embeddedExpenseTax?.buCode,
                                    buCodeName: currentTaxRate?.buCodeName,
                                })}
                                <DeletedTooltip translationKey="tooltips:vat.was_deleted" deletionDate={currentTaxRate.dateDeleted} anchorToDocumentBody />
                            </div>
                        </Select.Option>
                    )}
                    {taxRates.map((entry: Tax, index: number) => (
                        <Select.Option disabled={entry.deleted} key={index} value={entry._id} label={`${entry.taxRate}`}>
                            {getTaxLabel(entry)}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
        )
    }

    return generateCell()
}

export default TaxRateCell
