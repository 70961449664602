import { ApproverData, Company, CompanyFeatureSettings, DatevCsvConfig } from "@finway-group/shared/lib/models"
import axios from "axios"
import { Store } from "redux"

import { DatevClientAndConsultantFormDataType } from "Components/modals/datev/clientAndConsultant.modal"
import store from "Shared/store"
import { RootState } from "Shared/store/rootState.interface"

import EmployeeService from "./employee.service"

const MODEL = "company"

const CompanyService = {
    updateCompany: async (newCompany: Company): Promise<Company> => {
        const { data } = await axios.put(`/company/${newCompany._id}`, newCompany)
        return data as any
    },
    fetchCompany: async (): Promise<Company> => {
        const { data } = await axios.get(`/${MODEL}`)
        return data as Company
    },
    fetchCompanies: async () => {
        const { data } = await axios.get(`/${MODEL}`)
        return data as Array<Company>
    },
    createCompany: async (newCompany: Company) => {
        const { data } = await axios.post(`/${MODEL}`, newCompany)
        return data
    },
    deleteCompany: async (id: string) => {
        const { data } = await axios.delete(`/${MODEL}/${id}`)
        return data
    },
    updateFeatureSettings: async (id: string, featureSettings: Partial<CompanyFeatureSettings>) => {
        const { data } = await axios.patch(`/${MODEL}/${id}/feature-settings`, featureSettings)
        return data
    },
    connectDatevOnline: async () => {
        const { data } = await axios.get(`/${MODEL}/datev/connect`)
        return data as Company
    },
    revokeDatevConsent: async () => {
        const { data } = await axios.put(`/${MODEL}/datev/revoke-consent`)
        return data
    },
    updateDatevAutoExport: async (autoExport: boolean) => {
        const { data } = await axios.put(`/${MODEL}/datev/auto-export`, { autoExport })
        return data
    },
    abortDatevSetup: async () => {
        const { data } = await axios.put(`/${MODEL}/datev/setup-abort`)
        return data
    },
    // TODO: fix any
    updateBankSubjectParts: async (id: string, customBankSubjectParts: Array<any>) => {
        const { data } = await axios.put(`/${MODEL}/${id}/bank-subject-line`, { customBankSubjectParts })
        return data
    },
    updateDatevInfo: async (id: string, customDatevFields: any) => {
        const { data } = await axios.patch(`/${MODEL}/${id}/custom-datev-fields`, { customDatevFields })
        return data
    },
    updateDatevCsvInfo: async (id: string, datevCsvConfig: DatevCsvConfig) => {
        const { data } = await axios.patch(`/${MODEL}/${id}/datev-settings/datev-csv-config`, { datevCsvConfig })
        return data
    },
    updateDatevClientAndConsultantInfo: async (id: string, datevClientAndConsultantInfo: DatevClientAndConsultantFormDataType) => {
        const { data } = await axios.patch(`/${MODEL}/${id}/datev-settings/datev-context`, { datevClientAndConsultantInfo })
        return data
    },
    doesCompanyEnforceGobdCompliantInvoice: () => {
        const loggedInUser = EmployeeService.getLoggedInEmployeeProfile()
        const company = ((store as Store<RootState>).getState().company.items as Array<Company>).find((c) => c._id === loggedInUser.activeCompanyProfile?.companyId)
        return Boolean(company?.gobdCompliance?.enforceGobdCompliantInvoice)
    },
    getPossibleApprovers: async ({
        userId,
        companyId,
        teamId,
        search,
        sortQueryString = "",
        page = 1,
        limit = 100,
    }: {
        userId?: string
        companyId: string
        teamId?: string
        search?: string
        sortQueryString?: string
        limit?: number
        page?: number
    }) => {
        const { data } = await axios.get<{ docs: Array<ApproverData> }>(
            `/company/${companyId}/possibleApprovers?page=${page}&limit=${limit}${teamId ? `&teamId=${teamId}` : ""}${userId ? `&userId=${userId}` : ""}${
                search ? `&firstName[contains]=${search}&lastName[contains]=${search}` : ""
            }${sortQueryString}`,
        )
        return data.docs
    },
}

export default CompanyService
