import LoadingOutlined from "@ant-design/icons/lib/icons/LoadingOutlined"
import { CSV_EMPTY_VALUE } from "@finway-group/shared/lib/consts/common"
import { PriceIntervalEnum, WeavrTransactionTypesEnum } from "@finway-group/shared/lib/models"
import { Alert, Button, Dropdown, Empty, Menu, Tooltip } from "antd"
import Table, { ColumnsType } from "antd/lib/table"
import moment from "moment"
import React, { useEffect } from "react"
import { RefreshCcw } from "react-feather"
import { useTranslation } from "react-i18next"

import { DropdownDotsIcon } from "Components/icons"
import CsvIcon from "Components/icons/csvIcon"
import PdfIcon from "Components/icons/pdfIcon"
import Loading from "Components/loading"
import PriceLabel from "Components/priceLabel"
import { useCompany } from "Shared/hooks/company.hooks"
import { FileService, NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import WalletService from "Shared/services/wallet.service"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

const AccountStatements = () => {
    const { t } = useTranslation()
    const company = useCompany()

    const [isInitialLoading, setIsInitalLoading] = useStateIfMounted(true)
    const [isLoading, setIsLoading] = useStateIfMounted(false)
    const [accountStatements, setAccountStatements] = useStateIfMounted<Array<any>>([]) // TODO: Add account statements' DTO
    const [isRegeneratingStatement, setIsRegeneratingStatement] = useStateIfMounted(company?.isGeneratingAccountStatements)

    useEffect(() => {
        const fetchStatements = async () => {
            const accountStatements = await WalletService.fetchAccountStatements()
            setAccountStatements(accountStatements)
        }

        if (isRegeneratingStatement && !company?.isGeneratingAccountStatements) {
            fetchStatements()
        }

        setIsRegeneratingStatement(company?.isGeneratingAccountStatements)
    }, [company?.isGeneratingAccountStatements])

    useEffect(() => {
        let isFetching = true
        const fetchFundingHistory = async () => {
            try {
                if (!isFetching) return
                setIsLoading(true)
                const accountStatements = await WalletService.fetchAccountStatements()
                isFetching && setAccountStatements(accountStatements)
            } catch (err) {
                NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:fetch.title"), t("error:fetch.message"))
            } finally {
                setIsInitalLoading(false)
                setIsLoading(false)
            }
        }
        fetchFundingHistory()

        return () => {
            isFetching = false
        }
    }, [])

    const handleFileDownload = async (url: string) => {
        try {
            FileService.downloadFileAsSignedUrl(url)
        } catch (error) {
            NotificationService.send(NotificationTypeEnum.ERROR, t("error:error"), t("error:cards.account_statement_download"))
        }
    }

    const handleDatevCsvWalletFileDownload = async (url: string, accountStatementId: string) => {
        try {
            const areDatevCsvSettingsSet = !!company.datevSettings.datevCsvConfig?.walletAccount || !!company.datevSettings.datevCsvConfig?.cashTransitAccount
            const hasMissingVendorCreditorNumber = accountStatements
                ?.find(({ _id }) => _id === accountStatementId)
                ?.transactions?.some((txn) => txn?.vendorCreditorNumber === CSV_EMPTY_VALUE && txn?.type !== WeavrTransactionTypesEnum.DEPOSIT)

            if (!areDatevCsvSettingsSet || hasMissingVendorCreditorNumber) {
                NotificationService.send(NotificationTypeEnum.WARNING, t("error:datev_wallet_csv.title"), t("error:datev_wallet_csv.message"))
            }

            FileService.downloadFileAsSignedUrl(url)
        } catch (error) {
            NotificationService.send(NotificationTypeEnum.ERROR, t("error:error"), t("error:cards.account_statement_download"))
        }
    }

    const onRegenerateOneStatement = async (id: string) => {
        try {
            await WalletService.regenerateAccountStatement(id)
            NotificationService.send(
                NotificationTypeEnum.INFO,
                t("notification:cards.account_statement_regenerating.title"),
                t("notification:cards.account_statement_regenerating.message"),
            )
        } catch (err) {
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:error"))
        }
    }

    const columns: ColumnsType = [
        {
            title: t("creditCard:label.month"),
            width: 140,
            className: "pl-10",
            render: ({ date: { month, year } }: any) => <span>{moment({ year, month }).format("MMM, YYYY")}</span>,
            sorter: (a: any, b: any) => new Date(b.date.year, b.date.month, 1).getTime() - new Date(a.date.year, a.date.month, 1).getTime(),
        },
        {
            title: t("creditCard:label.opening_balance"),
            width: 200,
            render: ({ startBalance, currency }: any) => <PriceLabel value={startBalance} currency={currency} interval={PriceIntervalEnum.ONE_TIME} />,
        },
        {
            title: t("creditCard:label.debit"),
            width: 200,
            render: ({ totalSpending, currency }: any) => (totalSpending ? <PriceLabel value={totalSpending} currency={currency} interval={PriceIntervalEnum.ONE_TIME} /> : "-"),
            sorter: (a: any, b: any) => b.totalSpending - a.totalSpending,
        },
        {
            title: t("creditCard:label.credit"),
            width: 200,
            render: ({ totalFunding, currency }: any) => (totalFunding ? <PriceLabel value={totalFunding} currency={currency} interval={PriceIntervalEnum.ONE_TIME} /> : "-"),
            sorter: (a: any, b: any) => b.totalFunding - a.totalFunding,
        },
        {
            title: t("creditCard:label.closing_balance"),
            width: 200,
            render: ({ endBalance, currency }: any) => (endBalance ? <PriceLabel value={endBalance} currency={currency} interval={PriceIntervalEnum.ONE_TIME} /> : "-"),
        },
        {
            title: t("creditCard:label.documents"),
            width: 100,
            render: ({ _id, pdfUrl, csvUrl, datevCsvWalletUrl }) => (
                <div className="flex flex-row">
                    <Button className="m-5 btn-default" icon={<CsvIcon />} onClick={() => handleFileDownload(csvUrl)} disabled={isLoading} loading={isLoading}>
                        {isLoading ? t("info:loading_data") : t("action:download_csv")}
                    </Button>
                    <Button className="m-5 btn-default" icon={<PdfIcon />} onClick={() => handleFileDownload(pdfUrl)} disabled={isLoading} loading={isLoading}>
                        {isLoading ? t("info:loading_data") : t("action:download_pdf")}
                    </Button>
                    {datevCsvWalletUrl ? (
                        <Button
                            className="m-5 btn-default"
                            icon={<CsvIcon />}
                            onClick={() => handleDatevCsvWalletFileDownload(datevCsvWalletUrl, _id)}
                            disabled={isLoading}
                            loading={isLoading}
                        >
                            {isLoading ? t("info:loading_data") : t("label:datev_csv")}
                        </Button>
                    ) : (
                        <Tooltip title={t("tooltips:datev_wallet_csv_statement_not_existing")}>
                            <Button className="m-5 btn-default" icon={<CsvIcon />} disabled={true}>
                                {t("label:datev_csv")}
                            </Button>
                        </Tooltip>
                    )}
                    <Dropdown
                        trigger={["click"]}
                        disabled={isRegeneratingStatement}
                        overlay={
                            <Menu
                                onClick={({ domEvent }) => {
                                    domEvent.stopPropagation()
                                    onRegenerateOneStatement(_id)
                                }}
                            >
                                <Menu.Item key="restore" className="flex items-center min-w-190 py-8">
                                    <div className="flex items-center justify-center rounded-full w-16 h-16">
                                        <RefreshCcw />
                                    </div>
                                    <div className="flex items-center ml-12">
                                        <p className="leading-none">
                                            <span className="text-text">{t("action:regenerate_statement")}</span>
                                        </p>
                                    </div>
                                </Menu.Item>
                            </Menu>
                        }
                        className="p-5"
                    >
                        <Button
                            disabled={isRegeneratingStatement}
                            icon={<DropdownDotsIcon />}
                            onClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}
                            className={"m-5 btn-default"}
                        />
                    </Dropdown>
                </div>
            ),
        },
    ]

    return isInitialLoading ? (
        <Loading />
    ) : (
        <>
            {isRegeneratingStatement ? (
                <Alert
                    showIcon
                    icon={<LoadingOutlined />}
                    className="mb-20"
                    message={t("info:wallet.generating_statements_in_progress.title")}
                    description={t("info:wallet.generating_statements_in_progress.message")}
                    type="info"
                />
            ) : null}
            <div className="overflow-auto p-2">
                {accountStatements.length === 0 ? (
                    <Empty
                        image={`./icons/empty_account_statements.svg`}
                        imageStyle={{
                            height: 150,
                            marginRight: "auto",
                            marginLeft: "auto",
                            marginTop: "15%",
                            marginBottom: "40px",
                            display: "inline-block",
                        }}
                        description={<span className="text-lg font-bold mt-2 mb-0">{t("info:empty_state.account_statements.title")}</span>}
                    >
                        <p>{t("info:empty_state.account_statements.message")}</p>
                    </Empty>
                ) : (
                    <Table
                        rowKey={(record: any) => record._id}
                        dataSource={accountStatements}
                        columns={columns}
                        pagination={false}
                        loading={{
                            spinning: isLoading,
                            indicator: <LoadingOutlined style={{ fontSize: 30, color: "black" }} spin />,
                        }}
                    />
                )}
            </div>
        </>
    )
}

export default AccountStatements
