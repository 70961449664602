import { Card } from "antd"
import moment from "moment"
import React, { useMemo } from "react"
import { Bar, CartesianGrid, ComposedChart, ResponsiveContainer, XAxis, YAxis } from "recharts"

const skeletonColor = "#f2f2f2"

const DummyTickX: React.FC<any> = ({ x, y }) => (
    <g transform={`translate(${x - 20},${y})`}>
        <rect width="40" height="20" rx="5" fill={skeletonColor} />
    </g>
)

const DummyTickY: React.FC<any> = ({ x, y }) => (
    <g transform={`translate(${x - 40},${y - 10})`}>
        <rect width="40" height="20" rx="5" fill={skeletonColor} />
    </g>
)

const ForecastDiagramSkeleton: React.FC = () => {
    const data = useMemo(() => {
        const dummyDataPoints: Array<any> = []
        for (let i = 0; i < 12; i++) {
            dummyDataPoints.push({
                month: moment().add(i, "month").format("MMM YY"),
                income: Math.random() * 5000,
                spending: Math.random() * -5000,
            })
        }
        return dummyDataPoints
    }, [])

    return (
        <Card className="w-full">
            <div className="h-400">
                <ResponsiveContainer width={"100%"} height={400}>
                    <ComposedChart data={data} stackOffset="sign">
                        <XAxis dataKey="month" tick={<DummyTickX />} />
                        <YAxis tick={<DummyTickY />} />
                        <CartesianGrid stroke="#f5f5f5" vertical={false} />
                        <Bar dataKey="income" stackId="1" barSize={40} fill={skeletonColor} stroke={skeletonColor} radius={[5, 5, 5, 5]} />
                        <Bar dataKey="spending" stackId="1" barSize={40} fill={skeletonColor} stroke={skeletonColor} radius={[5, 5, 5, 5]} />
                    </ComposedChart>
                </ResponsiveContainer>
            </div>
        </Card>
    )
}

export default ForecastDiagramSkeleton
