import Icon from "@ant-design/icons"
import React from "react"

const newReimbursement = () => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="32" height="32" rx="16" fill="#F0FEFA" />
        <path
            d="M15.5 8C11.3646 8 8 11.3646 8 15.5C8 19.6354 11.3646 23 15.5 23C19.6354 23 23 19.6354 23 15.5C23 11.3646 19.6354 8 15.5 8ZM11.0078 15.7383C10.9539 15.7825 10.8877 15.8125 10.8125 15.8125C10.6398 15.8125 10.5 15.6727 10.5 15.5L10.0981 13.4903C10.0536 13.2682 10.2619 13.0789 10.4787 13.1442L11.0127 13.305C11.8382 11.6289 13.5726 10.5 15.5 10.5C17.3123 10.5 18.9937 11.4986 19.8703 13.0694C19.9568 13.2245 19.8859 13.4227 19.7254 13.4988L19.1595 13.7674C19.0089 13.8389 18.8353 13.7755 18.752 13.6311C18.0874 12.4794 16.8415 11.75 15.5 11.75C14.1977 11.75 13.0132 12.4374 12.3378 13.4916L12.7864 13.6412C12.8938 13.6769 12.9734 13.7681 12.9945 13.8795C13.0156 13.9909 12.9747 14.105 12.8877 14.1777L11.0127 15.7402C11.0111 15.7415 11.0078 15.7383 11.0078 15.7383ZM20.5325 15.5L20.9428 17.6941C20.9696 17.8372 20.8393 17.9601 20.698 17.925L19.9925 17.7497C19.1564 19.3956 17.4389 20.5 15.5325 20.5C13.7202 20.5 12.0388 19.5014 11.1622 17.9306C11.0757 17.7755 11.1466 17.5773 11.3071 17.5012L11.873 17.2326C12.0236 17.1611 12.1972 17.2245 12.2805 17.3689C12.9451 18.5206 14.191 19.25 15.5325 19.25C16.8348 19.25 18.0193 18.5626 18.6947 17.5084L18.2461 17.3588C18.1387 17.3231 18.0591 17.2319 18.038 17.1205C18.0169 17.0091 18.0578 16.895 18.1448 16.8223L20.0198 15.2598C20.0214 15.2585 20.0247 15.2617 20.0247 15.2617C20.0786 15.2175 20.1448 15.1875 20.22 15.1875C20.3927 15.1875 20.5325 15.3273 20.5325 15.5Z"
            fill="#60CDB2"
        />
    </svg>
)

const NewReimbursementIcon = (props: any) => <Icon component={newReimbursement} {...props} />

export default NewReimbursementIcon
