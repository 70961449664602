import { InvoiceSplit, ItemSplit, SplitTypeEnum } from "@finway-group/shared/lib/models"
import { roundNumberTo2Decimals , roundNumberTo2Decimals } from "@finway-group/shared/lib/utils"
import { Form, Input } from "antd"
import React from "react"

import { SplitRules } from "Components/forms/rules/splitRules"

import { SplitUpdatablePropertyEnum, useMainDetailsSectionContext } from "../mainDetailsSection.context"
import { ExpenseSplitCellInterface } from "./expenseSplitCellInterface"

const PercentageOrItemCell: React.FC<ExpenseSplitCellInterface> = ({ field, splitIndex, editable }) => {
    const { expense, splits, onSplitUpdate } = useMainDetailsSectionContext()
    const currentSplit = splits[splitIndex]

    const rules = SplitRules(expense.splits)

    const generateCell = () => {
        if (expense.splitType === SplitTypeEnum.ITEM) {
            // When it's an expense with item, show item name
            return (
                <div>
                    <p className="text-xs md:text-base overflow-x-auto no-scrollbar whitespace-no-wrap">{(currentSplit as ItemSplit).name}</p>
                </div>
            )
        }

        if (splits.length === 1) {
            // When it's a splitted expense, and the length is only 1, dont show percentage
            return <></>
        }

        if (!editable)
            return (
                <div>
                    <p className="text-xs md:text-base">{roundNumberTo2Decimals((currentSplit as InvoiceSplit).percentage)}%</p>
                </div>
            )

        // We suppress the error messages here because it will be shown by the alert box of the expense main detail section

        return (
            <Form.Item
                name={[field.name, "percentage"]}
                key="percentage"
                rules={rules.splitPercentage}
                className="min-w-60 mb-0 input-responsive-font suppress-antd-form-error-text"
            >
                <Input
                    className="no-input-arrows pl-0"
                    type="number"
                    size="small"
                    placeholder={"50"}
                    onChange={() => onSplitUpdate(splitIndex, false, SplitUpdatablePropertyEnum.PERCENTAGE)}
                    suffix="%"
                    bordered={false}
                />
            </Form.Item>
        )
    }

    return generateCell()
}

export default PercentageOrItemCell
