import { FeatureFlags } from "@finway-group/shared/lib/models"
import { Switch } from "antd"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import { FINWAY_ADMIN_EMAIL } from "Shared/config/consts"
import { useAuth } from "Shared/hooks/auth.hooks"
import { useIsPersonioEnabled } from "Shared/hooks/featureFlags.hooks"
import { useEnablePersonioFeature } from "Shared/queries/integration.queries"
import { AuthnService } from "Shared/services"
import NotificationService, { NotificationTypeEnum } from "Shared/services/notification.service"
import { setFeatureFlags } from "Shared/store/actions/featureFlags/featureFlagsActions"
import { decodeAccessToken } from "Shared/utils/auth.utils"

// TODO: Remove once admin interface is setup
export default function CS_PersonioFeatureFlag() {
    const { t } = useTranslation()
    const { userClass } = useAuth()

    const [isLoading, setIsLoading] = useState(false)

    const dispatch = useDispatch()

    const isPersonioEnabled = useIsPersonioEnabled()
    const enablePersonioFeatureQuery = useEnablePersonioFeature()

    if (userClass.email !== FINWAY_ADMIN_EMAIL) {
        return null
    }

    const onEnablePersonioFeature = async () => {
        setIsLoading(true)

        enablePersonioFeatureQuery.mutate(undefined, {
            onSuccess: async () => {
                const { accessToken } = await AuthnService.silentRefreshWithoutLogout()

                const decodedToken = decodeAccessToken(accessToken)
                const featureFlags = new FeatureFlags(decodedToken.featureFlags)

                dispatch(setFeatureFlags(featureFlags.getFlagsObject()))
                setIsLoading(false)
            },
            onError: (error) => {
                setIsLoading(false)
                NotificationService.send(NotificationTypeEnum.ERROR, t("error:error"), error)
            },
        })
    }

    return (
        <div className="flex justify-between items-center">
            <span>{t("label:cs_feature_flags.personio_integration")}</span>
            <Switch checked={isPersonioEnabled} onChange={onEnablePersonioFeature} disabled={isPersonioEnabled} loading={isLoading} />
        </div>
    )
}
