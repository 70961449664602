import { GREEN } from "@finway-group/shared/lib/consts"
import { Vendor } from "@finway-group/shared/lib/models"
import { Avatar, Skeleton } from "antd"
import React, { useEffect, useState } from "react"

import { doesImageExist } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

interface VendorImageInterface {
    size: "small" | "large" | number
    vendor: Vendor
    bordered?: boolean
}
const VendorImage: React.FC<VendorImageInterface> = ({ size = "small", vendor, bordered = false }) => {
    const { id, img } = vendor

    const [imgLoading, setImgLoading] = useStateIfMounted<boolean>(true)
    const [showInitials, setShowInitials] = useStateIfMounted<boolean>(false)

    const initInitialAvatar = () => {
        setImgLoading(false)
        setShowInitials(true)
    }

    useEffect(() => {
        let isSubscribing = true
        doesImageExist(
            img,
            () => {
                if (isSubscribing) {
                    setImgLoading(false)
                    setShowInitials(false)
                }
            },
            () => isSubscribing && initInitialAvatar(),
        )

        return () => {
            isSubscribing = false
        }
    }, [img])

    const getNameInitials = () => {
        const fullName = ""

        try {
            return getVendorInitials()
        } catch (error) {
            return fullName
        }
    }

    const getVendorInitials = () => {
        if (vendor.name.length > 1) {
            return vendor.name.substr(0, 2).toUpperCase()
        }
        return vendor.name.charAt(0).toUpperCase()
    }

    const minWidth = size === "large" ? 38 : size === "small" ? 24 : size
    const minHeight = size === "large" ? 38 : size === "small" ? 24 : size

    const onError = () => {
        initInitialAvatar()
        return false
    }

    return (
        <>
            {imgLoading ? (
                <Skeleton.Avatar
                    key={id}
                    className={`${bordered ? "border p-4 rounded-lg" : "border-none"} min-w-${minWidth}  min-h-${minHeight}`}
                    active={true}
                    size={size}
                    shape="square"
                />
            ) : showInitials ? (
                <Avatar
                    key={vendor.id}
                    size={size}
                    className={`${bordered ? "border p-4 rounded-lg" : "border-none"} animation-appear min-w-${minWidth} min-h-${minHeight}`}
                    style={{ color: GREEN, backgroundColor: "#ffffff" }}
                    shape="square"
                >
                    <span className="items-center self-center">{getNameInitials().trim()}</span>
                </Avatar>
            ) : (
                <Avatar
                    key={vendor.id}
                    className={`${bordered ? "border p-4 rounded-lg" : "border-none"} min-w-${minWidth}  min-h-${minHeight}`}
                    size={size}
                    src={img}
                    shape="square"
                    onError={onError}
                />
            )}
        </>
    )
}

export default VendorImage
