import { DocumentChangeInterface, Expense, OperationTypeEnum } from "@finway-group/shared/lib/models"
import * as Sentry from "@sentry/react"
import i18n from "i18next"

import { refetchDashboardTablesIfNeeded } from "Shared/hooks/dashboard.hooks"
import { ThunkDispatchResult } from "Shared/store"
import { fetchAllExpenses, syncExpense } from "Shared/store/actions/expense/expenseActions"
import { getCurrentPageInfo } from "Shared/utils/dashboard.utils"
import { isClientErrorCode } from "Shared/utils/errorHandler.utils"
import { FOLDER_CHILDREN_LIMIT } from "Shared/utils/folder.utils"

import FileService from "../file.service"
import NotificationService, { NotificationTypeEnum } from "../notification.service"

/**
 * If someone else modified an expense, dispatch updates
 */
export const subscribeToExpenses = async (dispatch: ThunkDispatchResult, data: DocumentChangeInterface) => {
    const { fullDocument } = data
    const { documentId, operationType, metaData, dependencies } = fullDocument
    const updatedExpense = fullDocument?.fullData ? new Expense(fullDocument.fullData) : undefined

    const { currentDocId, currentFolderId, isOnCurrentDetailPage, isAffectedByBulkOperation } = getCurrentPageInfo(documentId as string, dependencies as Array<string>)

    try {
        // Updating detail page if user is on it
        if (isOnCurrentDetailPage) {
            // Re-sync the expense so we get the isReadOnly value and the signed file urls.
            if (updatedExpense) await syncExpense(updatedExpense.id)(dispatch)
        } else if (currentFolderId) {
            await syncExpense(currentFolderId)(dispatch)
            await fetchAllExpenses(`&folderId[eq]=${currentFolderId}`, "", 1, FOLDER_CHILDREN_LIMIT)(dispatch)
        } else if (isAffectedByBulkOperation) {
            await syncExpense(currentDocId!)(dispatch)
        }

        // refetch tables if we need to
        refetchDashboardTablesIfNeeded({
            operationType: operationType as OperationTypeEnum,
            updatedExpense,
            metaData,
            isExpenseSocketCallback: false,
        })(dispatch)
    } catch (err) {
        if (isClientErrorCode(err)) return
        Sentry.captureException(`[Expense Socket] Error: ${typeof err === "string" ? err : JSON.stringify(err)}`)
    }
}

/**
 * For async actions for the same user who initiated it
 */
export const subscribeToExpenseCallbacks = (dispatch: ThunkDispatchResult, data: DocumentChangeInterface) => {
    const { fullDocument } = data
    const { documentId, operationType, metaData, dependencies } = fullDocument
    const updatedExpense = fullDocument?.fullData ? new Expense(fullDocument.fullData) : undefined
    const { currentDocId, isAffectedByBulkOperation } = getCurrentPageInfo(documentId as string, dependencies as Array<string>)

    // Updating detail page if user is on it (but only for bulk updates, as for single updates he would've caused it himself)
    if (isAffectedByBulkOperation) syncExpense(currentDocId!)(dispatch)

    // refetch tables if we need to
    refetchDashboardTablesIfNeeded({
        operationType: operationType as OperationTypeEnum,
        updatedExpense,
        metaData,
        isExpenseSocketCallback: true,
    })(dispatch)

    // handle async notifications
    switch (operationType) {
        case OperationTypeEnum.EXPENSE_CARD_CREATION_SUCCESS:
            // prettier-ignore
            NotificationService.send(NotificationTypeEnum.SUCCESS, i18n.t("notification:cards.expense_card_creation.title"), i18n.t("notification:cards.expense_card_creation.message"))
            break
        case OperationTypeEnum.EXPENSE_CARD_CREATION_FAILED:
            NotificationService.send(NotificationTypeEnum.ERROR, i18n.t("error:error"), i18n.t("error:cards.expense_card_creation"), 30)
            break
        case OperationTypeEnum.DATEV_ONLINE_EXPORT_WRONG_IMPORT_TARGET:
            NotificationService.send(NotificationTypeEnum.ERROR, i18n.t("error:datev_online.wrong_target.title"), i18n.t("error:datev_online.wrong_target.message"), 0)
            break
        case OperationTypeEnum.DATEV_ONLINE_EXPORT_ERROR_ACCOUNT_NOT_FOUND:
            NotificationService.send(NotificationTypeEnum.ERROR, i18n.t("error:datev_online.account_not_found.title"), i18n.t("error:datev_online.account_not_found.message"), 0)
            break
        case OperationTypeEnum.DATEV_ONLINE_UNSUPPORTED_FILE_TYPE:
            NotificationService.send(
                NotificationTypeEnum.ERROR,
                i18n.t("error:datev_online.unsupported_file_type.title"),
                i18n.t("error:datev_online.unsupported_file_type.message"),
                0,
            )
            break
        case OperationTypeEnum.DATEV_ONLINE_TIMEOUT:
            NotificationService.send(NotificationTypeEnum.ERROR, i18n.t("error:datev_online.export_timeout.title"), i18n.t("error:datev_online.export_timeout.message"), 0)
            break
        case OperationTypeEnum.DATEV_ONLINE_EXPORT_ERROR:
            NotificationService.send(NotificationTypeEnum.WARNING, i18n.t("error:datev_online.export_error.title"), i18n.t("error:datev_online.export_error.message"), 0)
            break
        case OperationTypeEnum.DATEV_ONLINE_EXPORT_PARTIAL:
            NotificationService.send(NotificationTypeEnum.WARNING, i18n.t("error:datev_online.export_partial.title"), i18n.t("error:datev_online.export_partial.message"), 0)
            break
        case OperationTypeEnum.DATEV_ONLINE_DAMAGED_INVOICE_FILE:
            NotificationService.send(NotificationTypeEnum.WARNING, i18n.t("error:datev_online.damaged_invoice.title"), i18n.t("error:datev_online.damaged_invoice.message"), 0)
            break
        case OperationTypeEnum.INVALID_DATEV_ACCESS_TOKEN:
            NotificationService.send(
                NotificationTypeEnum.ERROR,
                i18n.t("error:datev_online.invalid_access_token.title"),
                i18n.t("error:datev_online.invalid_access_token.message"),
                0,
            )
            break
        case OperationTypeEnum.ASYNC_EXPORT:
            NotificationService.send(NotificationTypeEnum.SUCCESS, i18n.t("notification:request.exported_bulk.title"), i18n.t("notification:request.exported_bulk.message"), 0)
            const fileUrl = (metaData as any)?.fileUrl
            if (fileUrl) FileService.downloadFileAsSignedUrl(fileUrl)
            break

        case OperationTypeEnum.ASYNC_EXPORT_FAILED:
            NotificationService.send(
                NotificationTypeEnum.ERROR,
                i18n.t("notification:request.async_export_file_too_large.title"),
                i18n.t("notification:request.async_export_file_too_large.message"),
                0,
            )
            break
        case OperationTypeEnum.SYNC_VENDOR_DATA:
            NotificationService.send(
                NotificationTypeEnum.SUCCESS,
                i18n.t("notification:request.sync_expense_creditor.title"),
                i18n.t("notification:request.sync_expense_creditor.message"),
            )
            break
        default:
            break
    }
}

export default subscribeToExpenses
