import { Expense } from "@finway-group/shared/lib/models"
import { isExpenseDATEVCompliant } from "@finway-group/shared/lib/utils/datevValidation"
import { Alert } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"

import { useExpenseAccounts } from "Shared/hooks/expenseAccount.hooks"
import { useTaxes } from "Shared/hooks/tax.hooks"

interface DatevCompatibilityBannerInterface {
    expense: Expense
}

const DatevCompatibilityBanner: React.FC<DatevCompatibilityBannerInterface> = ({ expense }) => {
    const { t } = useTranslation()
    const taxes = useTaxes()
    const expenseAccounts = useExpenseAccounts()
    const issues: Array<string> = []

    const checks = { amounts: true, taxes: true, expenseAccounts: true }
    const { isCompliant, checks: checksResults } = isExpenseDATEVCompliant(expense, taxes, expenseAccounts, checks)

    if (!isCompliant) {
        if (checksResults.hasIncompatibleTaxBuCodes) issues.push(t("info:bu_code_datev_compliance"))
        if (checksResults.hasIncompatibleExpenseAccountCodes) issues.push(t("info:expense_account_code_datev_compliance"))
        if (checksResults.hasZeroGrossAmount) issues.push(t("info:zero_amount_datev_compliance"))
        if (checksResults.isConsolidatedAmountUnsafe) issues.push(t("info:consolidated_amount_unsafe_datev_compliance"))
    }

    return !issues.length ? (
        <></>
    ) : (
        <Alert
            className="mb-14"
            message={
                <div>
                    <span>{t("info:datev_compliance_issues")}</span>
                    <ul>
                        {issues.map((issue: string, index: number) => (
                            <li key={index} className="ml-8 mt-2">
                                - {issue}
                            </li>
                        ))}
                    </ul>
                </div>
            }
            type="warning"
            showIcon={true}
        />
    )
}

export default DatevCompatibilityBanner
