import { SortEnum } from "@finway-group/shared/lib/consts"
import { Company, Employee, User } from "@finway-group/shared/lib/models"
import stringSimilarity from "string-similarity"

export const sortCompanyByName = (companyA: Company, companyB: Company) => companyA.name.localeCompare(companyB.name)

export const sortCompaniesByName = (companies: Array<Company>, sort = SortEnum.DESC) => {
    const sorted = [...companies].sort(sortCompanyByName)
    return sort === SortEnum.DESC ? sorted.reverse() : sorted
}

export const sortCompaniesByCreatedAt = (companies: Array<Company>, sort = SortEnum.DESC) => {
    const sorted = [...companies].sort((companyA: any, companyB: any) => new Date(companyA?.createdAt)?.getTime() - new Date(companyB?.createdAt)?.getTime())
    return sort === SortEnum.DESC ? sorted.reverse() : sorted
}

export const searchCompanies = (search: string, companies: Array<Company>) => {
    if (search.trim() === "") return companies
    return companies.filter(
        (company) =>
            stringSimilarity.compareTwoStrings(company?.name?.toLocaleLowerCase()?.trim() || "", search?.toLocaleLowerCase()?.trim()) > 0.6 || company.name.includes(search),
    )
}

export const getAvailableCompanies = (companies: Array<Company>, user?: User | Employee) => {
    if (!user?.companyProfiles) return companies
    const connectedCompanies = user?.companyProfiles.map(({ companyId }) => companyId)

    return companies.filter(({ _id }) => !connectedCompanies.includes(_id))
}
