import { CostCenter, RightEnum } from "@finway-group/shared/lib/models"
import axios from "axios"

import { AuthzService, UserService } from "Shared/services"
import store from "Shared/store"

const MODEL = "costcenters"

const CostCenterService = {
    getCostCenters: (excludeDeleted: boolean = false, filtered: boolean = false) => {
        let costCenters = store.getState().costCenters.items
        const loggedInUser = UserService.getLoggedInEmployeeProfile()

        if (excludeDeleted) {
            costCenters = costCenters?.filter((costCenter: CostCenter) => !costCenter.deleted)
        }

        if (filtered) {
            costCenters = costCenters?.filter(
                (cc: CostCenter) => AuthzService.isRightGrantedForLoggedInUser(RightEnum.REPORTING__ALL__READ) || cc.responsibleUser === loggedInUser.id,
            )
        }

        return costCenters
    },
    getCostCenterIds: (excludeDeleted: boolean = false, filtered: boolean = false) => CostCenterService.getCostCenters(excludeDeleted, filtered).map((cc: CostCenter) => cc._id),
    getCostCenterById: (id: string | undefined) => {
        const costCenters = store.getState().costCenters.items
        return costCenters.find((cc: CostCenter) => cc._id === id)
    },
    // limit is changed to 3500 because of https://levaroio.atlassian.net/browse/CUS-1163

    fetchAllCostCenters: async (limit: number = 3500) => {
        const {
            data: { docs },
        } = await axios.get(`/${MODEL}?limit=${limit}`)

        return docs.map((item: any) => item as CostCenter)
    },
    fetchOneCostCenter: async (id: string) => {
        const { data } = await axios.get(`/${MODEL}/${id}`)
        return data as CostCenter
    },
    createCostCenter: async (createCostCenter: CostCenter) => {
        const { data } = await axios.post(`/${MODEL}`, createCostCenter)

        return data as CostCenter
    },
    updateCostCenter: async (id: string, updateCostCenter: CostCenter) => {
        const { data } = await axios.put(`/${MODEL}/${id}`, updateCostCenter)
        return data as CostCenter
    },
    deleteCostCenter: async (id: string) => {
        const { data } = await axios.delete(`/${MODEL}/${id}`)
        return data as CostCenter
    },
    export: async () => {
        await axios.post(`/${MODEL}/export`)
    },
}

export default CostCenterService
