import { ReloadOutlined } from "@ant-design/icons"
import { CurrencyEnum, PerDiem, PerDiemDailyExpenses, PriceIntervalEnum } from "@finway-group/shared/lib/models"
import { calculateTotalReimbursement } from "@finway-group/shared/lib/utils"
import { Button, Col, DatePicker, Divider, Form, Input, Row, Switch, Tabs } from "antd"
import { NamePath } from "antd/lib/form/interface"
import moment, { Moment } from "moment"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"

import { ExpenseRules } from "Components/forms/rules"
import { ExpenditureRules } from "Components/forms/rules/perDiem.rules"
import { useExpenseFormContext } from "Components/modals/expenseCreateForm.context"
import PriceLabel from "Components/priceLabel"
import { MAX_PER_DIEM_DESTINATION_DAYS, MAX_PER_DIEM_DESTINATION_MONTHS, MIN_PER_DIEM_HOURS } from "Shared/config/consts"
import { useEmployees } from "Shared/hooks/employee.hooks"
import { usePerDiemDestinationMap, usePerDiemDestinations } from "Shared/hooks/perDiemDestination.hooks"
import { NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"
import { formatPerDiemDestinationDates, generateDailyExpenditureData } from "Shared/utils/perDiem.utils"
import { getPopupAnchor } from "Shared/utils/popup.utils"

import ExpenseCommonInputFieldsForm from "../../commonInputFields/commonInputFields.form"
import ExpenseFormLayout from "../../expenseFormLayout"
import { HoverableInfo } from "../auxilliary/HoverableInfo"
import ReimbursementTypeSelector from "../reimbursementTypeSelector"
import { FormPerDiemDestinationItinerary, PerDiemDailyExpensesWithMomentDate, PerDiemDestinationItinerary } from "./perDiem.form.types"
import { PerDiemDestinationFormFieldsContainer } from "./perDiemDestinationFormFields.container"
import { PerDiemOptionTable } from "./perDiemOptionTable"
import { PerDiemReimbursementsPerDayTable } from "./perDiemReimbursementsPerDayTable"

export const PerDiemRequestFieldsForm = ({ perDiemExpense }: { perDiemExpense: PerDiem }) => {
    const { t } = useTranslation()
    const employees = useEmployees()

    const [{ expenseForm, updateExpense, isNew }] = useExpenseFormContext()

    const [isDestinationFormValid, setIsDestinationFormValid] = useStateIfMounted(false)
    const [isDestinationFormChanged, setIsDestinationFormChanged] = useStateIfMounted(false)
    const [previousDestinations, setPreviousDestinations] = useStateIfMounted<Array<FormPerDiemDestinationItinerary>>(formatPerDiemDestinationDates(perDiemExpense))
    const [errors, setErrors] = useStateIfMounted<Array<string>>([])

    const { destinations } = usePerDiemDestinations()
    const { destinationMap } = usePerDiemDestinationMap()

    const tripDailyExpenses: Array<PerDiemDailyExpenses> = expenseForm.getFieldValue(["dailyExpenses"]) ?? []
    const totalReimbursement = calculateTotalReimbursement(tripDailyExpenses, destinations)

    const expenditureRules = ExpenditureRules(!isDestinationFormChanged)
    const expenseRules = ExpenseRules({ expense: perDiemExpense, employees })

    const buildDailyExpensesFormAndTable = () => {
        const currentFormState = expenseForm.getFieldsValue()
        const currentDestinations: Array<FormPerDiemDestinationItinerary> = currentFormState?.destinations

        try {
            const { dailyExpenses, correctedPerDiemItinerary, problematicDestination, correctedDestination } = generateDailyExpenditureData(
                currentDestinations,
                expenseForm.getFieldValue(["dailyExpenses"]),
                destinationMap,
                t,
            )

            // When the destinations needs correction (due to a year change, and in the new year, the visited region have no rates data)
            if (correctedPerDiemItinerary) {
                const tData = { destination: problematicDestination?.destination, year: correctedDestination?.year }
                NotificationService.send(
                    NotificationTypeEnum.INFO,
                    t("notification:per_diem.destination_break_off.title", tData),
                    t("notification:per_diem.destination_break_off.message", tData),
                    30,
                )
                expenseForm.setFieldsValue({ destinations: correctedPerDiemItinerary })
                updateExpense({ destinations: correctedPerDiemItinerary as unknown as Array<PerDiemDestinationItinerary> | undefined })
            }

            return dailyExpenses
        } catch (err) {
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:error"), t("notification:per_diem.missing_destination_for_date_range"))
        }
    }

    const initializePerDiemDailyExpensesForm = (formValues: Array<PerDiemDailyExpensesWithMomentDate>) => {
        const dailyExpenses: Array<PerDiemDailyExpensesWithMomentDate> = expenseForm.getFieldValue(["dailyExpenses"])

        // If form content already exist, check before setting the state
        // Do not overwrite expenditures (but overwrite location) that has existed in the previous data of the same date.
        if (dailyExpenses) {
            for (const formValue of formValues) {
                const previousFormValue = dailyExpenses.find((e: PerDiemDailyExpensesWithMomentDate) => formValue.date.isSame(e.date, "day"))
                if (previousFormValue) {
                    formValue.expenditure = previousFormValue.expenditure
                }
            }
        }

        let updateObject: any = {}
        if (formValues?.length > 0) {
            updateObject = { performancePeriod: [formValues[0]?.date, formValues[formValues.length - 1]?.date] }
        }

        const amount = calculateTotalReimbursement(formValues as Array<any>, destinations)
        updateObject.totalNetPrice = amount
        updateObject.totalGrossPrice = amount
        expenseForm.setFieldsValue({ dailyExpenses: formValues, ...updateObject })
        updateExpense({ dailyExpenses: formValues as any, ...updateObject })
    }

    const validateDestinationsForm = async (isSyncAction = false) => {
        const formDestinations: PerDiem["destinations"] = perDiemExpense.destinations
        const collectedErrors: Array<string> = []
        if (!formDestinations || formDestinations.length === 0) {
            collectedErrors.push(t("validation:per_diem.destinations_invalid"))
            return collectedErrors
        }

        const firstDate = formDestinations[0]?.startDate
        const lastDate = formDestinations[formDestinations.length - 1]?.endDate

        // Validate each row one by one
        for (let i = 0; i < formDestinations.length; i++) {
            const relevantNamePaths: Array<NamePath> = [
                ["destinations", i, "endDate"],
                ["destinations", i, "destination"],
            ]
            if (i === 0) relevantNamePaths.push(["destinations", i, "startDate"])
            if (isSyncAction) {
                // Only perform validation here when the user is clicking on the sync button so errors will then popup
                try {
                    // Only perform validation in the destinations field.
                    await expenseForm.validateFields(relevantNamePaths)
                } catch (err) {
                    /**
                     * handles ant design errors
                     */
                    if ("errorFields" in err && Array.isArray(err.errorFields)) {
                        err.errorFields.forEach((errorField: { errors: Array<string> }) => {
                            collectedErrors.push(...errorField.errors)
                        })
                    }
                }
            }
        }

        if (!firstDate || !lastDate) return collectedErrors

        const startMoment = moment(firstDate)
        const endMoment = moment(lastDate)

        if (endMoment.isBefore(startMoment, "date")) {
            collectedErrors.push(t("validation:per_diem.end_time_less_than_start_time"))
        }

        if (Math.abs(startMoment.diff(endMoment, "day")) > MAX_PER_DIEM_DESTINATION_DAYS) {
            collectedErrors.push(t("validation:per_diem.months_maximum", { months: MAX_PER_DIEM_DESTINATION_MONTHS }))
        }

        if (Math.abs(startMoment.diff(endMoment, "hours")) < MIN_PER_DIEM_HOURS) {
            collectedErrors.push(t("validation:per_diem.hoursMinimum", { count: MIN_PER_DIEM_HOURS }))
        }

        return collectedErrors
    }

    const syncPerDiemDailyExpenseFormWithDestinationsForm = async () => {
        const newErrors = await validateDestinationsForm(true)
        if (newErrors.length > 0) {
            setErrors(newErrors)
            return
        }

        const dailyExpenses = buildDailyExpensesFormAndTable()

        if (dailyExpenses) {
            initializePerDiemDailyExpensesForm(dailyExpenses)

            // When synced, we save the state of the destinations fields so can be compared in the future to check whether this fields has changed.
            setPreviousDestinations(expenseForm.getFieldValue(["destinations"]))

            setIsDestinationFormChanged(false)
        }
    }

    // Use effect to check if the dates configuration are valid or not. Then determine the dates and the locations the user undergo through according to the forms
    useEffect(() => {
        const currentDestinations: Array<PerDiemDestinationItinerary> = perDiemExpense.destinations
        // TODO: any better way to do this?
        setIsDestinationFormChanged(JSON.stringify(currentDestinations) !== JSON.stringify(previousDestinations))

        validateDestinationsForm().then((errors) => {
            setErrors(errors)
            setIsDestinationFormValid(errors.length === 0)
        })
    }, [JSON.stringify(perDiemExpense.destinations)])

    useEffect(() => {
        updateExpense({ totalTaxPrice: 0, totalNetPrice: totalReimbursement, totalGrossPrice: totalReimbursement })
    }, [totalReimbursement])

    // When the form is new, make sure that the default "Deutschland" destination is properly synced
    useEffect(() => {
        if (isNew) syncPerDiemDailyExpenseFormWithDestinationsForm()
    }, [])

    const leftSide = (
        <Tabs activeKey="PER_DIEM_CALCULATION">
            <Tabs.TabPane key="PER_DIEM_CALCULATION">
                <PerDiemReimbursementsPerDayTable perDiemExpense={expenseForm.getFieldsValue(true) as PerDiem} isDestinationFormChanged={isDestinationFormChanged} />
            </Tabs.TabPane>
        </Tabs>
    )

    const rightSide = (
        <>
            <ReimbursementTypeSelector />
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <p>{t("info:per_diem.foreign_trip_prompt")}</p>
                </Col>
                <Col span={24}>
                    <PerDiemDestinationFormFieldsContainer belowRange={errors.includes(t("validation:per_diem.hoursMinimum", { count: MIN_PER_DIEM_HOURS }))} />
                </Col>
                {isDestinationFormChanged && (
                    <Col span={24}>
                        <div className="flex items-center justify-between">
                            <span className="text-finway-red">{t("info:sync_per_diem_request.text")}</span>

                            <Button disabled={!isDestinationFormValid} onClick={syncPerDiemDailyExpenseFormWithDestinationsForm}>
                                <ReloadOutlined />
                                {t("info:sync_per_diem_request.action")}
                            </Button>
                        </div>
                    </Col>
                )}
                <Col span={24}>
                    <p>{t("info:per_diem.please_select")}</p>
                </Col>
                <Col span={24} className={`${isDestinationFormChanged ? "opacity-25 cursor-not-allowed" : ""}`}>
                    <Form.Item rules={expenditureRules.syncDestinations} className="w-full" name={["dailyExpenses"]}>
                        <PerDiemOptionTable />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <div className="w-full flex justify-end gap-8">
                        <span>{t("info:per_diem.total_reimbursable_amount")} </span>
                        <span className="font-bold">
                            <PriceLabel currency={CurrencyEnum.EUR} value={totalReimbursement} interval={PriceIntervalEnum.ONE_TIME} />
                        </span>

                        <HoverableInfo message={t("tooltips:per_diem.total_calculated_amount")} type="info" />
                    </div>
                </Col>
            </Row>
            <Divider />
            <Row>
                <Col span={15}>
                    <Form.Item name="description" key="description" label={t("input:request.per_diem.reason")} rules={expenseRules.description}>
                        <Input.TextArea
                            className="leading-normal"
                            autoSize
                            rows={1}
                            name="description"
                            placeholder={t("placeholder:request.description")}
                            onChange={(e) => updateExpense({ description: e.target.value })}
                        />
                    </Form.Item>
                </Col>
                <Col span={9}>
                    <Form.Item label={t("input:request.billable")} name="billable" valuePropName="checked" className="content-end">
                        <Switch onChange={(checked) => updateExpense({ billable: checked })} />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item label={t("label:performance_period")} name="performancePeriod" className="text-opacity-50">
                        <DatePicker.RangePicker
                            name="performancePeriod"
                            style={{ width: "100%" }}
                            format={moment.localeData().longDateFormat("L")}
                            placeholder={[t("placeholder:start_date"), t("placeholder:end_date")]}
                            getPopupContainer={getPopupAnchor()}
                            disabledDate={(date: Moment) => date.isAfter(moment(), "day")}
                        />
                    </Form.Item>
                </Col>
                <Col span={24} className="hidden">
                    <Form.Item key="totalNetPrice" name="totalNetPrice" hidden>
                        <Input name="totalNetPrice" />
                    </Form.Item>
                    <Form.Item key="totalGrossPrice" name="totalGrossPrice" hidden>
                        <Input name="totalGrossPrice" />
                    </Form.Item>
                    <Form.Item key="totalTaxPrice" name="totalTaxPrice" hidden>
                        <Input name="totalTaxPrice" />
                    </Form.Item>
                </Col>
            </Row>
            <ExpenseCommonInputFieldsForm />
        </>
    )

    return <ExpenseFormLayout leftSide={leftSide} rightSide={rightSide} />
}
