import { ApprovalScopeEnum, ApprovalStatusEnum, Employee, Expense, ExpenseStatusEnum } from "@finway-group/shared/lib/models"
import { ExpensePaymentOptionEnum } from "@finway-group/shared/lib/models/expense/expensePaymentOption.enum"
import { GetEmployeeById } from "@finway-group/shared/lib/models/user/employee.model"
import { getApprovalProcess } from "@finway-group/shared/lib/utils/approvalProcess.utils"
import { Card } from "antd"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import RejectFolderModal from "Components/modals/folderReject.modal"
import RejectModal from "Components/modals/rejectModal"
import { useExpenseDetailsContext } from "Shared/context/expenseDetails.context"
import { useArchiveInterval } from "Shared/hooks/company.hooks"
import { useAreCardsActivated } from "Shared/hooks/corporate.hooks"
import { useCostCenter2ById } from "Shared/hooks/costCenter2.hooks"
import { useCostCenterById } from "Shared/hooks/costCenter.hooks"
import { useEmployees } from "Shared/hooks/employee.hooks"
import { useLoggedInEmployeeProfile } from "Shared/hooks/user.hooks"
import { AuthzService } from "Shared/services"
import { isExpenseArchived, isFolderExpense } from "Shared/utils/expense.utils"

import ExpenseActionButtonView from "./expenseActionButtonView"
import ExpenseCreditCardSection from "./expenseCreditCardSection"
import { doFileUploadFunction } from "./expenseDetails.container.types"
import ExpenseSourceSection from "./expenseSourceSection"
import { DraftSubmissionButtonView } from "./folderDetails/draftSubmissionButtonView"

interface ExpenseRightSectionInterface {
    isInvoiceUploading: boolean
    isDeliveryNoteUploading: boolean
    isLoading: boolean
    nextExpense: string
    cancelRequest: () => void
    sendEmailReminder: () => void
    markRequestAsApproved: (expense: Expense, approvalScope: ApprovalScopeEnum) => void
    markRequestAsReviewed: (expense: Expense) => void
    markRequestAsPaid: (expense: Expense) => void
    payExpense: (isScheduledPayment: boolean) => void
    doFileUpload: doFileUploadFunction
    onInboxSelected: (event: any) => void
    isOcrUploading: boolean
    isExpenseUpdating: boolean
    setIsExpenseUpdating: React.Dispatch<React.SetStateAction<boolean>>
}
const ExpenseRightSection: React.FC<ExpenseRightSectionInterface> = ({
    isInvoiceUploading,
    isDeliveryNoteUploading,
    isLoading,
    nextExpense,
    cancelRequest,
    sendEmailReminder,
    markRequestAsApproved,
    markRequestAsReviewed,
    markRequestAsPaid,
    payExpense,
    doFileUpload,
    onInboxSelected,
    isOcrUploading,
    isExpenseUpdating,
    setIsExpenseUpdating,
}) => {
    // Displays the information regarding the current active expense
    const { activeExpense, folder, isReadOnly } = useExpenseDetailsContext()
    const { t } = useTranslation()
    const employees = useEmployees({ includeAutoApprover: true })
    const costCenter = useCostCenterById(activeExpense.costCenter)!
    const costCenter2 = useCostCenter2ById(activeExpense.costCenter2)
    const requestedBy = GetEmployeeById(employees, activeExpense.requestedBy._id) || new Employee()
    const requestType = activeExpense.getRequestType()
    const loggedInUser = useLoggedInEmployeeProfile()
    const archiveAfterXDays = useArchiveInterval()
    const isArchived = isExpenseArchived(activeExpense, archiveAfterXDays)

    const [isRejectModalVisible, setIsRejectModalVisible] = useState<boolean>(false)
    const isSubscription = activeExpense.isSubscription()
    const isMyExpense = activeExpense.isCurrentUserExpense(loggedInUser.id)
    const cardsActive = useAreCardsActivated()
    const showCard =
        !isReadOnly &&
        cardsActive &&
        activeExpense.paymentOption === ExpensePaymentOptionEnum.SMART_CARD &&
        (activeExpense.status !== ExpenseStatusEnum.DECLINED || activeExpense.card)
    const isAllowedToCreateExpenses = AuthzService.isLoggedInUserAllowedToCreateExpenses()

    const renderRightSideActionContent = () => {
        const invoiceApprovalProcess = getApprovalProcess(ApprovalScopeEnum.EXPENSE_INVOICE, activeExpense.approvalProcesses)
        if (isFolderExpense(activeExpense) && invoiceApprovalProcess?.status === ApprovalStatusEnum.NOT_STARTED) {
            /*
            When the folder is in draft and the approver is opening the folder
            OR when the folder is in draft and the requester does not have the standard expense creation right anymore, don't let them do things.
            */
            if ((!isMyExpense && !AuthzService.isLoggedInUserGlobalApprover()) || (isMyExpense && !isAllowedToCreateExpenses)) return null

            if (isArchived) return null
            // Only admin or the owner of the folder can submit a draft folder
            return (
                <Card className="border-b-0 mb-20 " id="expense-action-section" style={{ zIndex: 1 }}>
                    <DraftSubmissionButtonView />
                </Card>
            )
        }

        if (!isFolderExpense(activeExpense) && folder && getApprovalProcess(ApprovalScopeEnum.EXPENSE_INVOICE, folder.approvalProcesses)?.status === ApprovalStatusEnum.NOT_STARTED)
            return null // When the folder is in draft, and we're currently looking at a subexpense, hide the action buttons from the right action

        return (
            <Card className="border-b-0 mb-20 " id="expense-action-section" style={{ zIndex: 1 }}>
                <ExpenseActionButtonView
                    loggedInUser={loggedInUser}
                    isSubscription={isSubscription}
                    isLoading={isLoading}
                    isInvoiceUploading={isInvoiceUploading}
                    markRequestAsApproved={markRequestAsApproved}
                    rejectRequest={() => setIsRejectModalVisible(true)}
                    markRequestAsReviewed={markRequestAsReviewed}
                    markRequestAsPaid={markRequestAsPaid}
                    doFileUpload={doFileUpload}
                    payExpense={payExpense}
                    onInboxSelected={onInboxSelected}
                    isOcrUploading={isOcrUploading}
                    sendEmailReminder={sendEmailReminder}
                    isExpenseUpdating={isExpenseUpdating}
                    canApprove={!isReadOnly && AuthzService.isLoggedInUserAllowedToApproveExpense(activeExpense)}
                    cancelRequest={cancelRequest}
                />
            </Card>
        )
    }

    return (
        <>
            {!isFolderExpense(activeExpense) ? (
                <RejectModal
                    isVisible={isRejectModalVisible}
                    title={t("notification:rejection_note.title")}
                    message={t("notification:rejection_note.message")}
                    cancelText={t("notification:rejection_note.cancel")}
                    okText={t("notification:rejection_note.confirm")}
                    request={activeExpense}
                    nextExpense={nextExpense}
                    onCancel={() => setIsRejectModalVisible(false)}
                />
            ) : (
                <RejectFolderModal
                    isVisible={isRejectModalVisible}
                    request={activeExpense}
                    cancelText={t("notification:rejection_note.cancel")}
                    onCancel={() => setIsRejectModalVisible(false)}
                />
            )}

            {renderRightSideActionContent()}

            {showCard && <ExpenseCreditCardSection expense={activeExpense} />}

            <ExpenseSourceSection
                expense={activeExpense}
                loggedInUser={loggedInUser}
                requestedBy={requestedBy}
                costCenter={costCenter}
                costCenter2={costCenter2!}
                requestType={requestType}
                isDeliveryNoteUploading={isDeliveryNoteUploading}
                doFileUpload={doFileUpload}
                setIsExpenseUpdating={setIsExpenseUpdating}
                isExpenseUpdating={isExpenseUpdating}
                isOcrUploading={isOcrUploading}
                isReadOnly={isReadOnly}
            />
        </>
    )
}

export default ExpenseRightSection
