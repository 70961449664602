import { Button, Layout } from "antd"
import React, { Suspense, useEffect } from "react"
import { X as CloseIcon, Menu as MenuIcon } from "react-feather"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

import Icon from "Components/icon"
import { BetaTag } from "Components/tags/BetaTag"
import UpgradeModal from "Components/upgradeModal"
import { isLocal } from "Shared/config/consts"
import { useModal } from "Shared/context/modal.context"
import { useAuth } from "Shared/hooks/auth.hooks"
import { useIsFreeVersion } from "Shared/hooks/featureFlags.hooks"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

import DebugNavbar from "./debug.navbar"

const SettingsNavbar = React.lazy(() => import("./settings.navbar"))
const UserProfileNavbar = React.lazy(() => import("./user.navbar"))
const NotificationNavbar = React.lazy(() => import("./activity/notification.navbar"))
const CompanySelectionNavBar = React.lazy(() => import("./companySelection.navbar"))

const { Header } = Layout

interface NavbarInterface {
    onMobileDevice: boolean
    showMobileNav: boolean
    onMobileToggleClick(visible: boolean): void
}

const Navbar = (props: NavbarInterface) => {
    const { t } = useTranslation()
    const { showModal } = useModal()
    const isFreeVersion = useIsFreeVersion()
    const { isAuthenticated } = useAuth()
    const [showMobileNav, setShowMobileNav] = useStateIfMounted(false)

    const toggleMobileNav = (visible: boolean): void => {
        props.onMobileToggleClick(visible)
        setShowMobileNav(visible)
    }

    useEffect(() => {
        toggleMobileNav(props.showMobileNav)
    }, [props.showMobileNav])

    return (
        <Header className="bg-primary w-full fixed z-60 top-0 h-56 px-20">
            <div className="container">
                <div className="flex justify-between items-center h-56 py-10">
                    <div onClick={() => toggleMobileNav(!showMobileNav)} className={`${!props.onMobileDevice ? "hidden" : ""} cursor`}>
                        {showMobileNav ? <CloseIcon color={"white"} /> : <MenuIcon color={"white"} />}
                    </div>

                    <div className="flex flex-row items-center">
                        <Link className="hidden md:block finway-logo" to="/">
                            <img src={"/images/logo.svg"} className="w-120 sm:w-140 mt-4" alt={"finway"} />
                        </Link>
                        {isLocal && <img src="./images/wise-monkey-and-seal.png" className="h-40" alt="monkey-surfing-on-top-of-a-seal" />}
                        {isFreeVersion && (
                            <div className="hidden md:block">
                                <BetaTag />
                            </div>
                        )}
                    </div>

                    <DebugNavbar onMobileDevice={props.onMobileDevice} />

                    <div className="flex flex-row items-center">
                        {isFreeVersion ? (
                            <Button className="mr-10 btn-upgrade-version" onClick={() => showModal(UpgradeModal, true, { isShowing: true })}>
                                <Icon icon="rocket" className="mr-8" />
                                {t("label:upgrade")}
                            </Button>
                        ) : (
                            <>
                                <Suspense fallback={<></>}>
                                    <CompanySelectionNavBar />
                                </Suspense>

                                <Suspense fallback={<React.Fragment />}>
                                    <NotificationNavbar />
                                </Suspense>
                            </>
                        )}
                        {isAuthenticated ? (
                            <Suspense fallback={null}>
                                <SettingsNavbar />
                                <UserProfileNavbar />
                            </Suspense>
                        ) : (
                            <div className="flex items-center">
                                <Link className="text-white" to="/login">
                                    {t("nav:login")}
                                </Link>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Header>
    )
}

export default Navbar
