import { Employee } from "@finway-group/shared/lib/models"
import { Button, DatePicker, Form, Space } from "antd"
import type { RangePickerProps } from "antd/es/date-picker"
import { FormInstance } from "antd/lib/form"
import { Store } from "antd/lib/form/interface"
import moment from "moment"
import React, { useRef } from "react"
import { useTranslation } from "react-i18next"

import { TrashIcon } from "Components/icons"
import { getPopupAnchor } from "Shared/utils/popup.utils"

import { EmployeeAbsenceRules } from "./rules/employeeAbsence.rules"

interface EmployeeAbsenceFormInterface {
    formInstance: FormInstance
    onSubmit: (values: Store) => void
    employee: Employee
    handleRemoveAbsence: () => void
}

const disabledDate: RangePickerProps["disabledDate"] = (current) =>
    // Can not select days before today
    current && current < moment().startOf("day")
const EmployeeAbsenceForm: React.FC<EmployeeAbsenceFormInterface> = ({ formInstance, onSubmit, employee, handleRemoveAbsence }) => {
    const { t } = useTranslation()
    const rules = EmployeeAbsenceRules()
    const anchorRef = useRef(null)

    const startDate = employee.absence?.startDate
    const endDate = employee.absence?.endDate

    // The input fields (startDate,endDate) should be pre-filled only if the user's absence is not over.
    const isBeforeEndDate = endDate ? moment().isSameOrBefore(endDate, "days") : false

    return (
        <Form id="employeeAbsenseForm" ref={anchorRef} form={formInstance} onFinish={onSubmit} layout="vertical">
            <Space direction="horizontal" className="w-full">
                <Form.Item
                    label={t("input:employee_absence.period")}
                    name="absenceDate"
                    rules={rules.absenceDate}
                    required
                    initialValue={isBeforeEndDate ? [moment(startDate), moment(endDate)] : null}
                >
                    <DatePicker.RangePicker
                        name="absencePeriod"
                        disabledDate={disabledDate}
                        style={{ width: "100%" }}
                        format={moment.localeData().longDateFormat("L")}
                        placeholder={[t("placeholder:start_date"), t("placeholder:end_date")]}
                        getPopupContainer={getPopupAnchor()}
                    />
                </Form.Item>
                {isBeforeEndDate && (
                    <Button className="ml-8 mt-5" onClick={() => handleRemoveAbsence()}>
                        <TrashIcon />
                    </Button>
                )}
            </Space>
        </Form>
    )
}

export default EmployeeAbsenceForm
