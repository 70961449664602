import Icon from "@ant-design/icons"
import React from "react"

const arrowSVG = () => (
    <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 5L5 1L9 5" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M5 1V13" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
)

const ArrowThinUpIcon = (props: any) => <Icon component={arrowSVG} {...props} />

export default ArrowThinUpIcon
