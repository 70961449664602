import { Col, Form, Modal, Row, Select } from "antd"
import { Store } from "antd/lib/form/interface"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import { useCompany } from "Shared/hooks/company.hooks"
import { CompanyService, NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { setCompany, updateCompany } from "Shared/store/actions/company/companyActions"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"
import { getPopupAnchor } from "Shared/utils/popup.utils"

// TODO: into shared
export enum BankSubjectOptionEnum {
    INVOICE_NUMBER = "invoice_number",
    EXPENSE_NUMBER = "expense_number",
    VENDOR_NAME = "vendor_name",
    DESCRIPTION = "description",
    REQUEST_ITEMS = "request_items",
}

interface CustomBankSubjectModalInterface {
    bankSubjectParts: Array<any>
    isShowing: boolean
    onCancel: () => void
}

const buildSubjectPartsData = (bankSubjectParts: Array<any>) => {
    bankSubjectParts.sort((a, b) => a.order - b.order)
    return {
        first: bankSubjectParts[0]?.part ?? "",
        second: bankSubjectParts[1]?.part ?? "",
        third: bankSubjectParts[2]?.part ?? "",
        fourth: bankSubjectParts[3]?.part ?? "",
        fifth: bankSubjectParts[4]?.part ?? "",
    }
}
const CustomBankSubjectModal: React.FC<CustomBankSubjectModalInterface> = ({ isShowing, onCancel, bankSubjectParts }) => {
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useState(false)
    const companyId = useCompany()?._id

    const [subjectParts, setSubjectParts] = useStateIfMounted(buildSubjectPartsData(bankSubjectParts))
    const isPartAlreadySelected = (part: BankSubjectOptionEnum) => Object.values(subjectParts).includes(part)

    useEffect(() => {
        setSubjectParts(buildSubjectPartsData(bankSubjectParts))
    }, [isShowing, bankSubjectParts])

    const onFormSubmit = async (_values: Store) => {
        setIsLoading(true)
        try {
            // filter out empty parts and assign ordering by indexing
            const convertedParts = Object.values(subjectParts)
                .filter((x) => x)
                .map((part, index) => ({ order: index, part }))

            const updatedCompany = await CompanyService.updateBankSubjectParts(companyId, convertedParts)
            dispatch(setCompany(updatedCompany))

            NotificationService.send(
                NotificationTypeEnum.SUCCESS,
                t("notification:settings.company.custom_bank_subject_updated.title"),
                t("notification:settings.company.custom_bank_subject_updated.message"),
            )
            handleHide()
        } catch (err) {
            setIsLoading(false)
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:error"))
        }
    }

    const handleHide = () => {
        setIsLoading(false)
        onCancel()
    }

    const renderSelect = (defaultValue: any, setter: any) => (
        <Select getPopupContainer={getPopupAnchor()} defaultValue={defaultValue} onChange={setter} dropdownStyle={{ minWidth: "150px" }}>
            {Object.values(BankSubjectOptionEnum).map((part) => (
                <Select.Option disabled={isPartAlreadySelected(part)} value={part}>
                    {t(`input:custom_bank_subject.${part}`)}
                </Select.Option>
            ))}
            <Select.Option value="" label={t("input:custom_bank_subject.not_set")}>
                {t("input:custom_bank_subject.not_set")}
            </Select.Option>
        </Select>
    )

    return (
        <Modal
            title={t("label:custom_bank_subject.title")}
            visible={isShowing}
            maskClosable={false}
            onOk={form.submit}
            onCancel={handleHide}
            okText={t("action:save")}
            className={"ant-modal--large"}
            keyboard
            destroyOnClose
            confirmLoading={isLoading}
        >
            <Form form={form} layout="vertical" onFinish={onFormSubmit}>
                <Row className="mb-30">
                    <span className="text-text">{t("info:custom_bank_subject")}</span>
                </Row>
                <Form.Item label={t("input:custom_bank_subject.title")}>
                    <Row gutter={[8, 8]}>
                        <Col>{renderSelect(subjectParts.first, (value: any) => setSubjectParts({ ...subjectParts, first: value }))}</Col>
                        <Col>{renderSelect(subjectParts.second, (value: any) => setSubjectParts({ ...subjectParts, second: value }))}</Col>
                        <Col>{renderSelect(subjectParts.third, (value: any) => setSubjectParts({ ...subjectParts, third: value }))}</Col>
                        <Col>{renderSelect(subjectParts.fourth, (value: any) => setSubjectParts({ ...subjectParts, fourth: value }))}</Col>
                        <Col>{renderSelect(subjectParts.fifth, (value: any) => setSubjectParts({ ...subjectParts, fifth: value }))}</Col>
                    </Row>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default CustomBankSubjectModal
