import { Col, Form, Input, Row } from "antd"
import { FormInstance } from "antd/lib/form"
import { Store } from "antd/lib/form/interface"
import React, { ReactElement } from "react"
import { useTranslation } from "react-i18next"

import { generateRules } from "./rules/password.rule"

interface PasswordFormInterface {
    formInstance: FormInstance
    onSubmit: (values: Store) => void
    infoPanel?: ReactElement
}

const PasswordForm: React.FC<PasswordFormInterface> = ({ formInstance, onSubmit, infoPanel }) => {
    const { t } = useTranslation()
    const rules = generateRules(formInstance)

    return (
        <div>
            <Form id="passwordForm" form={formInstance} layout="vertical" onFinish={onSubmit}>
                <Row gutter={[16, 16]}>
                    {infoPanel && (
                        <Col span={24} sm="24">
                            {infoPanel}
                        </Col>
                    )}
                    <Col span={24} sm="24">
                        <Form.Item label={t("input:new_password")} name="password" rules={rules.password}>
                            <Input.Password type="password" placeholder={t("placeholder:new_password")} required autoComplete="new-password" />
                        </Form.Item>
                    </Col>
                    <Col span={24} sm="24">
                        <Form.Item label={t("input:confirm_new_password")} name="confirmPassword" rules={rules.confirmPassword}>
                            <Input.Password type="password" placeholder={t("placeholder:confirm_new_password")} required autoComplete="new-password" />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default PasswordForm
