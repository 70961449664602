import { FormInstance } from "antd/lib/form"

import store from "Shared/store"
import { RootState } from "Shared/store/rootState.interface"

const FormService = {
    getFormAndReduxValues: (reduxKey: keyof RootState, formInstance: FormInstance, formIsArray = true) => {
        const formValues = formIsArray ? formInstance.getFieldValue([reduxKey]) : [formInstance.getFieldsValue()]
        const existingValuesRaw: Array<any> = store.getState()[reduxKey].items

        if (!existingValuesRaw) throw new Error(`Redux value ${reduxKey}.items is not available`)

        return {
            formValues,
            existingValuesRaw,
        }
    },
}

export default FormService
