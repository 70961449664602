import { SyncOutlined } from "@ant-design/icons"
import { Button, Col, Row } from "antd"
import moment from "moment"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"

import UpgradeAlert from "Components/upgradeAlert"
import { useAccountIds } from "Shared/hooks/bank.hooks"
import { useIsFreeVersion } from "Shared/hooks/featureFlags.hooks"
import { useBankBalanceData, useCashFlows, useInterval, useShouldFetchBankBalanceData } from "Shared/hooks/liquidity.hooks"
import { RoutePathEnum } from "Shared/router/RoutePath.enum"
import { NotificationService } from "Shared/services"
import { fetchBankBalanceData, setShouldFetchBankBalanceData } from "Shared/store/actions/liquidity/liquidityActions"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

import NoBankAccountBanner from "../../supplements/noBankAccountBanner"
import PeriodRangePicker from "../../supplements/periodRangePicker"
import ScenarioSelectionComponent from "../cashflowPlanning/ScenarioSelectionComponent"
import ForecastDiagramSkeleton from "../cashflowPlanning/forecastDiagram.skeleton"
import ActualVsForecastBarChart from "./actualVsForecastBarChart"
import BankAccountCashTable from "./bankAccountCashBalanceTable"
import CashFlowGaugeChart from "./cashFlowGaugeChart"
import CashFlowGaugeChartSkeleton from "./cashFlowGaugeChart.skeleton"
import CashInBankAccountChart from "./cashInBankAccountChart"
import CategoryCashFlowPieChart from "./categoryCashFlowPieChart"
import CategoryCashFlowPieChartSkeleton from "./categoryCashFlowPieChart.skeleton"

interface DashboardTabInterface {
    isTableLoading: boolean
    isYearlyCashFlowLoading: boolean
    onCashFlowChange: () => void
}

const DashboardTab: React.FC<DashboardTabInterface> = ({ isTableLoading, isYearlyCashFlowLoading }) => {
    const { t } = useTranslation()
    const history = useHistory()
    const dispatch = useDispatch()
    const isFreeVersion = useIsFreeVersion()

    const bankBalanceData = useBankBalanceData()
    const shouldFetchBankBalanceData = useShouldFetchBankBalanceData()
    const [isBankBalanceDataLoading, setIsBankBalanceDataLoading] = useStateIfMounted(false)
    const dateInterval = useInterval()
    const cashFlowData = useCashFlows()
    const accountIds = useAccountIds({ shouldFilterDeleted: true })
    const hasAccountConnected = accountIds.length > 0

    useEffect(() => {
        if (dateInterval?.[0] && dateInterval?.[1] && shouldFetchBankBalanceData) {
            setIsBankBalanceDataLoading(true)
            fetchBankBalanceData(
                moment(dateInterval[0]),
                moment(dateInterval[1]),
            )(dispatch)
                .catch((err) => NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:error")))
                .finally(() => setIsBankBalanceDataLoading(false))
        }
    }, [dateInterval?.[0], dateInterval?.[1], shouldFetchBankBalanceData])

    const onRefreshClicked = () => {
        setShouldFetchBankBalanceData(true)(dispatch)
    }

    return (
        <div className="space-y-40">
            {!hasAccountConnected && <NoBankAccountBanner />}
            <Row gutter={[32, 32]} justify="center">
                <Col span={24}>
                    <div className="flex flex-row justify-between mb-20">
                        <h2 className="pt-8">{t("label:liquidity.cash_actual_vs_forecast")}</h2>
                        {hasAccountConnected && (
                            <div className="flex flex-row justify-between mt-8">
                                <PeriodRangePicker className="min-w-300 mr-10 h-32" />
                                <ScenarioSelectionComponent hideOptions isLoading={isTableLoading || isYearlyCashFlowLoading} />
                            </div>
                        )}
                    </div>
                    {hasAccountConnected ? <ActualVsForecastBarChart cashFlowData={cashFlowData} isDiagramLoading={isTableLoading} /> : <ForecastDiagramSkeleton />}
                </Col>
                <Col span={24} sm={20} lg={14} xl={12}>
                    <div className="flex flex-row justify-between mb-10">
                        <h2>{t("label:liquidity.cash_balance")}</h2>
                        <div>
                            <Button className="mr-8" onClick={onRefreshClicked} disabled={isBankBalanceDataLoading}>
                                <SyncOutlined />
                            </Button>
                            <Button
                                onClick={() => {
                                    history.push(RoutePathEnum.SETTINGS_BANK_ACCOUNTS, RoutePathEnum.LM)
                                }}
                            >
                                {t("action:banking.manage_accounts")}
                            </Button>
                        </div>
                    </div>
                    <BankAccountCashTable bankBalanceData={bankBalanceData} isLoading={isBankBalanceDataLoading} />
                </Col>
                <Col span={24} sm={20} lg={14} xl={12}>
                    <h2 className="mb-18">{t("label:liquidity.cash_in_all_bank_accounts")}</h2>
                    <CashInBankAccountChart bankBalanceData={bankBalanceData} isLoading={isBankBalanceDataLoading} />
                </Col>
                {isFreeVersion && (
                    <Col span={24}>
                        <UpgradeAlert />
                    </Col>
                )}
                <Col span={24} sm={20} lg={14} xl={12}>
                    <h2>{t("label:liquidity.overall_expenses_by_main_categories")}</h2>
                    {hasAccountConnected ? (
                        <CategoryCashFlowPieChart cashFlowData={cashFlowData} direction="spending" isDiagramLoading={isTableLoading} />
                    ) : (
                        <CategoryCashFlowPieChartSkeleton />
                    )}
                </Col>
                <Col span={24} sm={20} lg={14} xl={12}>
                    <h2>{t("label:liquidity.total_spending_compared_to_plan")}</h2>
                    {hasAccountConnected ? <CashFlowGaugeChart cashFlowData={cashFlowData} isDiagramLoading={isTableLoading} /> : <CashFlowGaugeChartSkeleton />}
                </Col>
            </Row>
        </div>
    )
}

export default DashboardTab
