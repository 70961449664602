import { CostCenter, CostCenter2, Employee, Expense, ExpenseAccount, Role, Team, Vendor } from "@finway-group/shared/lib/models"

import i18n from "Shared/locales/i18n"

import { groupBy } from "./helper.utils"

export const getCostCenter = (costCenters: Array<CostCenter>, costCenterId: string) => costCenters.find((costCenter: CostCenter) => costCenter._id === costCenterId)

export const getCostCenter2 = (costCenters2: Array<CostCenter2>, costCenter2Id: string) => costCenters2.find((costCenter2: CostCenter2) => costCenter2._id === costCenter2Id)

export const getAuthorizationHeader = () => ({
    Authorization: `Bearer ${localStorage.accessToken}`,
})

export const getExpenseAccount = (expenseAccounts: Array<ExpenseAccount>, expenseAccountId: string) =>
    expenseAccounts.find((expenseAccount) => expenseAccount._id === expenseAccountId)

export const getVendor = (vendors: Array<Vendor>, vendorId: string) => vendors.find((vendor) => vendor.id === vendorId)

export const getTeam = (teams: Array<Team>, teamId: string) => teams.find((team) => team._id === teamId)

export const getEmployeeLocations = (employees: Array<Employee>) => [...new Set(employees.map((employee) => employee.location))]

export const getUserRoleName = (role?: Role) => (!role ? "" : i18n.t(`enum:StandardRoleEnum.${role.name}`, role.name))

export const getMonthsForQuarter = (currQuarter: number) => {
    const maxMonth = currQuarter * 3 - 1 // -1 since months start at 0
    return [maxMonth, maxMonth - 1, maxMonth - 2]
}

export const getExpensesForCostCenter = (expenses: Array<Expense>, costCenterId: string) => groupBy(expenses, "costCenter")[costCenterId] || []

export const getQuarter = (currentMonth: number): number => Math.ceil((currentMonth + 1) / 3)
