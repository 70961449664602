import { Worker } from "@react-pdf-viewer/core"
import * as Sentry from "@sentry/react"
import { QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { ConfigProvider } from "antd"
import en_US from "antd/lib/locale/en_US"
import type { History } from "history"
import moment from "moment"
import React, { useEffect } from "react"
import { Helmet } from "react-helmet"
import { Provider } from "react-redux"
import { Router } from "react-router-dom"
import { PersistGate } from "redux-persist/integration/react"

import AxiosInterceptor from "Shared/config/axios.interceptor"
import { queryClient } from "Shared/config/reactQueryConfig"
import { ModalContextConsumer, ModalContextProvider } from "Shared/context/modal.context"
import { useDefaultTimeZone } from "Shared/hooks/timeZone.hooks"
import { useAutoUpdate } from "Shared/hooks/useAutoUpdate"
import { useLoadWeavr } from "Shared/hooks/weavr.hooks"
import i18n from "Shared/locales/i18n"
import { useIpAddress } from "Shared/queries/ip.queries"
import AppRouter from "Shared/router/AppRouter"
import store, { persistor } from "Shared/store"
import { configLocaleNumberFormatting, getAntdLocale } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"
import ErrorWrapper from "Shared/wrapper/error.wrapper"

import "./App.scss"

interface AppInterface {
    history: History
}

// eslint-disable-next-line arrow-body-style
const App = ({ history }: AppInterface) => {
    useLoadWeavr()

    return (
        <Router history={history}>
            <QueryClientProvider client={queryClient}>
                <Provider store={store}>
                    <AppContent history={history} />
                </Provider>
                <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
        </Router>
    )
}

export default Sentry.withProfiler(App)

function AppContent({ history }: AppInterface) {
    const [locale, setLocale] = useStateIfMounted(en_US)

    useEffect(() => {
        AxiosInterceptor.setupInterceptors()
    }, [])

    i18n.on("languageChanged", (lng) => {
        configLocaleNumberFormatting(lng)
        moment.locale(lng)
        setLocale(getAntdLocale(lng))
    })

    useAutoUpdate()

    useDefaultTimeZone()
    useIpAddress()

    return (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.12.313/build/pdf.worker.min.js">
            <Helmet titleTemplate="%s - finway" defaultTitle="Finway">
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0, viewport-fit=cover" />
                <link rel="icon" href="./images/favicons/32x32.png" sizes="32x32" />
                <link rel="icon" href="./images/favicons/192x192.png" sizes="192x192" />
                <link rel="apple-touch-icon-precomposed" href="./images/favicons/180x180.png" />
                <meta name="msapplication-TileImage" content="./images/favicons/310x310.png" />
            </Helmet>
            <Router history={history}>
                <ErrorWrapper>
                    <PersistGate loading={null} persistor={persistor}>
                        <ConfigProvider locale={locale}>
                            <ModalContextProvider>
                                <ModalContextConsumer />
                                <AppRouter />
                            </ModalContextProvider>
                        </ConfigProvider>
                    </PersistGate>
                </ErrorWrapper>
            </Router>
        </Worker>
    )
}
