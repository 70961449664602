import Icon from "@ant-design/icons"
import React from "react"

const downCircleSvg = () => (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="13" cy="13" r="12" transform="rotate(-180 13 13)" stroke="#DEE4EA" />
        <path d="M17 11.6672L13 15.6672L9 11.6672" stroke="#606C7E" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
)

const DownCircleIcon = (props: any) => <Icon component={downCircleSvg} {...props} />

export default DownCircleIcon
