import { Employee, SystemRightEnum } from "@finway-group/shared/lib/models"
import { FormInstance } from "antd/lib/form"
import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import TokenCodeForm from "Components/forms/tfauth/tokenCode.form"
import { AuthzService, UserService } from "Shared/services"
import CardService from "Shared/services/card.service"
import { fetchOneCreditCard } from "Shared/store/actions/creditCard/creditCardActions"
import { updateEmployee } from "Shared/store/actions/employee/employeeActions"
import { unsetApprovalFields } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

import MobileInputForm from "./mobileInput.form"

export enum PhoneVerificationStepEnum {
    WORK_PHONE_INPUT_STEP,
    WORK_PHONE_VERIFICATION_CODE_STEP,
}

interface PhoneVerificationFormInterface {
    workPhoneFormInstance: FormInstance
    phoneNumber: string | undefined
    employee?: Employee
    card?: any // TODO: update card shared type
    currentStep: PhoneVerificationStepEnum
    showLabel?: boolean
    onCurrentStepChange?: (currentStep: PhoneVerificationStepEnum) => void
    onCancel: (state: boolean) => void
    onSuccess?: () => void
    setIsLoading?: (isLoading: boolean) => void
}
const PhoneVerificationForm = ({
    workPhoneFormInstance,
    phoneNumber,
    employee,
    card,
    currentStep,
    showLabel = true,
    onSuccess,
    setIsLoading,
    onCurrentStepChange,
    onCancel,
}: PhoneVerificationFormInterface) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const [errorMessage, setErrorMessage] = useStateIfMounted("")

    const handleHide = () => {
        setIsLoading?.(false)
        workPhoneFormInstance.resetFields()
        setErrorMessage("")
        onCancel(false)
        onCurrentStepChange?.(PhoneVerificationStepEnum.WORK_PHONE_INPUT_STEP)
    }

    const shouldUnsetApprovalFields = () => !AuthzService.isSystemRightGrantedForLoggedInUser(SystemRightEnum.SYS__USER__ALL__CREATE)

    const handlePhoneVerification = async (phoneNumberParam: string) => {
        try {
            setIsLoading?.(true)
            if (employee) {
                if (employee.workPhone !== phoneNumberParam) {
                    employee.workPhone = phoneNumberParam
                    if (shouldUnsetApprovalFields()) unsetApprovalFields(employee)
                    await dispatch(updateEmployee(employee.id, employee))
                }

                await UserService.requestPhoneNumberVerification(phoneNumberParam)
            }

            if (card) {
                await CardService.requestPhoneNumberVerification(card.id, phoneNumberParam)
            }

            setIsLoading?.(false)
            setErrorMessage("")
            onCurrentStepChange?.(PhoneVerificationStepEnum.WORK_PHONE_VERIFICATION_CODE_STEP)
        } catch (error) {
            setIsLoading?.(false)
            if (error.response?.data?.error?.includes("Duplicate")) {
                setErrorMessage(t("label:phone_verification.duplicate_work_phone"))
                return
            }

            setErrorMessage(t("label:phone_verification.sms_request_error"))
        }
    }

    const handlePhoneCodeVerification = async (code: string) => {
        setIsLoading?.(true)
        try {
            const isVerified = employee ? await UserService.checkPhoneNumberVerification(code) : card ? await CardService.checkPhoneNumberVerification(card.id, code) : false
            if (!isVerified) {
                setErrorMessage(t("label:phone_verification.invalid_code"))
                return
            }
            if (employee) {
                employee.setSecurityKeyValue("workPhoneVerified", true)
                if (shouldUnsetApprovalFields()) unsetApprovalFields(employee)
                await dispatch(updateEmployee(employee.id, employee))
            }

            if (card) {
                await fetchOneCreditCard(card.id)(dispatch)
            }

            setIsLoading?.(false)
            onSuccess?.()
            handleHide()
        } catch (error) {
            setIsLoading?.(false)
            setErrorMessage(t("label:phone_verification.invalid_code"))
        }
    }

    return (
        <>
            {currentStep === PhoneVerificationStepEnum.WORK_PHONE_INPUT_STEP && (
                <MobileInputForm onSubmit={handlePhoneVerification} setErrorMessage={setErrorMessage} formInstance={workPhoneFormInstance} preSetPhoneNumber={phoneNumber} />
            )}

            {currentStep === PhoneVerificationStepEnum.WORK_PHONE_VERIFICATION_CODE_STEP && (
                <>
                    <p className="mb-10 font-medium items-center primary-placeholder">{t("label:phone_verification.code_description")}</p>
                    <TokenCodeForm isLoginForm={false} onSubmit={handlePhoneCodeVerification} />
                </>
            )}

            {errorMessage && errorMessage.length > 0 && <span className="mt-10 text-error">{errorMessage}</span>}
        </>
    )
}

export default React.memo(PhoneVerificationForm)
