import Form, { useForm } from "antd/lib/form/Form"
import FormItem from "antd/lib/form/FormItem"
import { Store } from "antd/lib/form/interface"
import React from "react"
import { useTranslation } from "react-i18next"

import { codeInitInputRefHelper, codeInputJumpHelper, codePasteHelper } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

interface TokenCodeFormInterface {
    isLoginForm?: boolean
    isWeavrOTPRetryLimitReached?: boolean
    onSubmit: (token: string) => void
}
const TokenCodeForm = ({ isLoginForm = false, onSubmit, isWeavrOTPRetryLimitReached = false }: TokenCodeFormInterface) => {
    const { t } = useTranslation()
    const [form] = useForm()
    const [isDisabled, setIsDisabled] = useStateIfMounted(false)

    const tokenCodeRef: any = []
    const isInputDisabled = isDisabled || isWeavrOTPRetryLimitReached

    const onFinish = async (_values: Store) => {
        const code = tokenCodeRef.map((ref: any) => ref.value).join("")
        setIsDisabled(true)
        await onSubmit(code)
        setIsDisabled(false)
        form.resetFields()
    }

    return (
        <div className={isLoginForm ? "h-200" : "h-150"}>
            {isLoginForm && (
                <div>
                    <div role="heading">
                        <div className="text font-bold">{t("label:tfa.confirm_code")}</div>
                    </div>
                    <div className="mt-5">
                        <div>{t("label:tfa.confirm_code_description")}</div>
                    </div>
                </div>
            )}
            <Form form={form} name="tokenForm" className="mt-20 mx-auto" layout="inline" style={{ width: "fit-content" }} onFinish={onFinish}>
                {[...Array(6)].map((_x, i) => (
                    <FormItem name={`token-${i}`} key={i}>
                        <input
                            className="token-input text-xl sm:text-5xl h-30 sm:h-50 ml-5 sm:ml-10 w-20 sm:w-50"
                            pattern="[0-9]{1}"
                            maxLength={1}
                            inputMode="numeric"
                            type="tel"
                            ref={(input) => codeInitInputRefHelper(input, i, tokenCodeRef)}
                            onKeyDown={(e) => codeInputJumpHelper(e, i, form, tokenCodeRef, 6)}
                            onPaste={(e) => codePasteHelper(e, form, tokenCodeRef, 6)}
                            disabled={isInputDisabled}
                            value=""
                        />
                    </FormItem>
                ))}
            </Form>
        </div>
    )
}

export default React.memo(TokenCodeForm)
