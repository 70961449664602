import React from "react"
import { useTranslation } from "react-i18next"

import Icon from "Components/icon"
import { msToTime } from "Shared/utils/helper.utils"

type AuthRateLimiterContainerType = {
    attemptCount: number
    isLoginError: boolean
    retryAfter: number
}

export default function AuthRateLimiterContainer({ attemptCount, isLoginError, retryAfter }: AuthRateLimiterContainerType) {
    const { t } = useTranslation()

    const isShown = attemptCount > 0 || retryAfter > 1 || isLoginError

    if (!isShown) {
        return null
    }

    return (
        <div className="finway-error-bg border-l-4 border-error small">
            <div className="flex items-center">
                <div className="p-8 ml-20 mr-10 rounded-full border-1 border-error bg-white">
                    <Icon icon="login-error-lock" />
                </div>
                <div data-testid="incorrectPasswordError" className="flex-col p-8 mr-10">
                    <h3 data-testid="passwordErrorHeading" className="finway-error-login-text font-medium text-xs sm:text-base mb-0">
                        {attemptCount > 0 || isLoginError ? t("label:login_error.error") : t("label:login_error.account_blocked")}
                    </h3>
                    <p data-testid="passwordErrorText" className="finway-error-login-text font-medium text-xxs sm:text-xs">
                        {attemptCount > 0 ? (
                            <>
                                <span className="font-bold finway-error-login-text">{attemptCount}</span> {t("label:login_error.remaining_attempt")}
                            </>
                        ) : (
                            !isLoginError && (
                                <>
                                    {t("label:login_error.retry_time")} {msToTime(retryAfter)}
                                </>
                            )
                        )}
                    </p>
                </div>
            </div>
        </div>
    )
}
