import { ExpenseAccount } from "@finway-group/shared/lib/models"
import { useSelector } from "react-redux"

import { RootState } from "Shared/store/rootState.interface"

export const useExpenseAccounts = () => {
    const expenseAccounts = useSelector(({ expenseAccounts }: RootState) => expenseAccounts.items)
    return expenseAccounts.sort((a: ExpenseAccount, b: ExpenseAccount) => a.accountCode?.localeCompare(b.accountCode))
}

export const useExpenseAccountById = (id?: string) => useExpenseAccounts().find((cc: ExpenseAccount) => id && cc._id === id)
