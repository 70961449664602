import { BankConnection } from "@finway-group/shared/lib/models"
import { Action } from "redux"

export enum BankActionTypes {
    FETCH_ALL_BANK_CONNECTIONS = "FETCH_ALL_BANK_CONNECTIONS",
    FETCH_ONE_BANK_CONNECTION = "FETCH_ONE_BANK_CONNECTION",
    SET_ACTION_IN_PROGRESS = "SET_ACTION_IN_PROGRESS",
}

export interface FetchAllBankConnectionsAction extends Action<typeof BankActionTypes.FETCH_ALL_BANK_CONNECTIONS> {
    type: typeof BankActionTypes.FETCH_ALL_BANK_CONNECTIONS
    bankConnections: Array<BankConnection>
}

export interface FetchOneBankConnectionAction extends Action<typeof BankActionTypes.FETCH_ONE_BANK_CONNECTION> {
    type: typeof BankActionTypes.FETCH_ONE_BANK_CONNECTION
    bankConnection: BankConnection
}

export interface SetActionInProgressAction extends Action<typeof BankActionTypes.SET_ACTION_IN_PROGRESS> {
    type: typeof BankActionTypes.SET_ACTION_IN_PROGRESS
    actionInProgress: boolean
}

export type BankActions = FetchAllBankConnectionsAction | FetchOneBankConnectionAction | SetActionInProgressAction
