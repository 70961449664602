import { isValidUrl } from "@finway-group/shared/lib/utils/"
import { Button, Col, Form, Input, Modal, Row, Spin } from "antd"
import httpStatus from "http-status"
import * as HttpStatus from "http-status"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useDebouncedCallback } from "use-debounce/lib"

import Loading from "Components/loading"
import { LOGO_API } from "Shared/config/consts"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

interface IconSearchInterface {
    isVisible: boolean
    onCancel: (state: boolean) => void
    setIcon: (name: string, link: string) => void
}

const IconSearchModal: React.FC<IconSearchInterface> = ({ isVisible, onCancel, setIcon }) => {
    const { t } = useTranslation()
    const [formInstance] = Form.useForm()
    const [isLoading, setIsLoading] = useStateIfMounted(false)
    const [didSearch, setDidSearch] = useState(true)
    const [searchTerm, setSearchTerm] = useState("")
    const [foundIcons, setFoundIcons] = useState<Array<string>>([])
    const [chosenIcon, setChoosenIcon] = useState("")
    const domains = ["com", "de", "fr"]
    const shouldShowResultRow = didSearch && !isLoading && searchTerm !== ""

    const onSubmit = () => {
        if (isLoading) return // To avoid "enter/return" keypress submitting when loading is not done

        const selectedIcon = getSelectedIcon()

        setIcon(searchTerm, selectedIcon)
        handleHide()
    }

    const getSelectedIcon = () => {
        if (chosenIcon) return chosenIcon
        if (!chosenIcon && foundIcons[0]) return foundIcons[0] // Fallback if user presses enter without selecting
        return ""
    }

    const fetchImage = (url: string) => fetch(url, { method: "HEAD" })

    const debouncedEventHandler = useDebouncedCallback(async (searchString: string) => {
        if (!searchString) return

        setDidSearch(true)

        try {
            let imageCheckingPromises: Array<Promise<any>> = []

            if (isValidUrl(searchString)) {
                imageCheckingPromises.push(fetchImage(`${LOGO_API}/${searchString}`).catch(() => {}))
            } else {
                imageCheckingPromises = domains.map((domain: string) => fetchImage(`${LOGO_API}/${searchString}.${domain}`).catch(() => {}))
            }

            const existingImages = (await Promise.all(imageCheckingPromises)).filter((data: any) => data && data.status !== HttpStatus.NOT_FOUND).map((data: any) => data.url)

            setFoundIcons(existingImages)
            setIsLoading(false)
        } catch (err) {
            setIsLoading(false)
            setFoundIcons([])
        }
    }, 1000)

    const handleHide = () => {
        setIsLoading(false)
        formInstance.resetFields()
        setDidSearch(false)
        onCancel(false)
        setFoundIcons([])
        setChoosenIcon("")
    }

    const renderIconList = () =>
        foundIcons.length > 0 ? (
            <div>
                <span className="text-text-dark">{t("label:found_icons")}:</span>
                <ul className="flex flex-wrap mt-20">
                    {foundIcons.map((url: string, index: number) => (
                        <li key={index} className="mr-10 inline">
                            <img
                                className={`${url === chosenIcon ? "finway-primary-border" : ""}`}
                                width="75"
                                height="75"
                                src={url}
                                alt={t("label:no_icon_found_for_vendor")}
                                onClick={() => setChoosenIcon(url)}
                            />
                        </li>
                    ))}
                </ul>
            </div>
        ) : (
            <span>{t("label:no_icon_found_for_vendor")}</span>
        )

    return (
        <Modal
            title={t("action:vendor.icon_search.title")}
            visible={isVisible}
            maskClosable={false}
            onOk={formInstance.submit}
            onCancel={handleHide}
            keyboard
            destroyOnClose
            footer={[
                <Button key="back" onClick={() => handleHide()}>
                    {t("action:cancel")}
                </Button>,
                <Button key="submit" type="primary" disabled={!chosenIcon} onClick={() => formInstance.submit()}>
                    {t("action:vendor.icon_search.confirm")}
                </Button>,
            ]}
        >
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Form form={formInstance} layout="vertical" onFinish={onSubmit} autoComplete="off">
                        <Form.Item name="searchTerm" key="searchTerm">
                            <Input
                                onChange={({ target: { value } }) => {
                                    setIsLoading(value !== "")
                                    setSearchTerm(value)
                                    setChoosenIcon("")
                                    debouncedEventHandler.callback(value)
                                }}
                                placeholder={t("placeholder:icon_search")}
                            />
                        </Form.Item>
                    </Form>
                </Col>
            </Row>

            <Row>
                <Col span={24}>{isLoading ? <Loading className="h-150 mb-20" /> : shouldShowResultRow && renderIconList()}</Col>
            </Row>
        </Modal>
    )
}

export default IconSearchModal
