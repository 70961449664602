import { FormInstance, Rule } from "antd/lib/form"
import { Moment } from "moment"

import { TablesEnum } from "Shared/store/reducers/tableConfigReducer"
import { insertIf } from "Shared/utils/helper.utils"

import { checkDueInDays, checkMaxDate, checkMaxNumber, checkMinDate, checkMinNumber } from "./utils.rules"

export const generateRules = (formInstance: FormInstance, table?: TablesEnum): { [key: string]: Array<Rule> } => ({
    minAmount: [
        {
            validator: (_rule: any, value: number | string) => checkMinNumber({ formInstance, value, name: "maxAmount" }),
        },
    ],
    maxAmount: [
        {
            validator: (_rule: any, value: number | string) => checkMaxNumber({ formInstance, value, name: "minAmount" }),
        },
    ],
    minDate: [
        ...insertIf(table === TablesEnum.ARCHIVE, { required: true }),
        {
            validator: (_rule: any, value: Moment) => checkMinDate(formInstance, value, "maxDate"),
        },
    ],
    maxDate: [
        ...insertIf(table === TablesEnum.ARCHIVE, { required: true }),
        {
            validator: (_rule: any, value: Moment) => checkMaxDate(formInstance, value, "minDate"),
        },
    ],
    minInvoiceDate: [
        {
            validator: (_rule: any, value: Moment) => checkMinDate(formInstance, value, "maxInvoiceDate"),
        },
    ],
    maxInvoiceDate: [
        {
            validator: (_rule: any, value: Moment) => checkMaxDate(formInstance, value, "minInvoiceDate"),
        },
    ],
    minInvoiceDueDate: [
        {
            validator: (_rule: any, value: Moment) => checkMinDate(formInstance, value, "maxInvoiceDueDate"),
        },
    ],
    maxInvoiceDueDate: [
        {
            validator: (_rule: any, value: Moment) => checkMaxDate(formInstance, value, "minInvoiceDueDate"),
        },
    ],
    minPaidAtDate: [
        {
            validator: (_rule: any, value: Moment) => checkMinDate(formInstance, value, "maxPaidAtDate"),
        },
    ],
    maxPaidAtDate: [
        {
            validator: (_rule: any, value: Moment) => checkMaxDate(formInstance, value, "minPaidAtDate"),
        },
    ],
    minUploadDate: [
        {
            validator: (_rule: any, value: Moment) => checkMinDate(formInstance, value, "maxUploadDate"),
        },
    ],
    maxUploadDate: [
        {
            validator: (_rule: any, value: Moment) => checkMaxDate(formInstance, value, "minUploadDate"),
        },
    ],
    dueInDays: [
        {
            validator: (_rule: any, value: number | string) => checkDueInDays(value),
        },
    ],
})
