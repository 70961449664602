import { Employee, Role } from "@finway-group/shared/lib/models"
import { useSelector } from "react-redux"

import { FINWAY_ADMIN_EMAIL } from "Shared/config/consts"
import store from "Shared/store"
import { RootState } from "Shared/store/rootState.interface"
import { employeeSorter } from "Shared/utils/helper.utils"

import { useAuth } from "./auth.hooks"
import { useCompany } from "./company.hooks"

interface EmployeeQuery {
    excludeDeleted?: boolean
    includeAutoApprover?: boolean
    searchValue?: string
    companyId?: string
}

const DEFAULT_EMPLOYEE_QUERY = {
    excludeDeleted: false,
    includeAutoApprover: false,
    searchValue: undefined,
    companyId: undefined,
}

export const useEmployees = ({ excludeDeleted, includeAutoApprover, searchValue, companyId }: EmployeeQuery = DEFAULT_EMPLOYEE_QUERY) => {
    const employeesResult = useSelector(({ employees }: RootState) => employees.items)
    const currentCompanyId = useCompany()._id
    return filterEmployees(employeesResult, companyId ?? currentCompanyId, excludeDeleted, includeAutoApprover, searchValue).sort(employeeSorter)
}

export const useCardRootUser = () => useEmployees({ excludeDeleted: true }).find((employee) => employee.activeCompanyProfile?.weavrData?.isRoot)

export const getEmployeesFromStore = ({ excludeDeleted, includeAutoApprover, searchValue, companyId }: EmployeeQuery = DEFAULT_EMPLOYEE_QUERY) => {
    const employees = store.getState().employees.items
    const selectedCompanyId = store.getState().company.item._id
    return filterEmployees(employees, companyId ?? selectedCompanyId, excludeDeleted, includeAutoApprover, searchValue)
}

const filterEmployees = (
    employees: Array<Employee>,
    selectedCompanyId: string,
    excludeDeleted: boolean = false,
    includeAutoApprover: boolean = false,
    searchValue?: string,
): Array<Employee> => {
    // Construct an array of employees, throw employee if there is no suitable company profile for the selected company id
    const employeesOfCompany = employees
        .map((employee) => {
            const employeeObject = new Employee(employee)
            const activeCompanyProfile = employeeObject.getUserCompanyProfile(selectedCompanyId)
            if (!activeCompanyProfile) return undefined
            employeeObject.activeCompanyProfile = activeCompanyProfile
            return employeeObject
        })
        .filter((e) => !!e) as Array<Employee>

    const filtersToApply: Array<(e: Employee) => boolean> = []

    if (!includeAutoApprover) filtersToApply.push((employee) => employee.email !== FINWAY_ADMIN_EMAIL)
    if (excludeDeleted) filtersToApply.push((employee) => !!employee.activeCompanyProfile.companyId && !employee.activeCompanyProfile.deleted)
    if (searchValue)
        filtersToApply.push(
            (employee) => employee.getFullName().toLowerCase().includes(searchValue.toLowerCase()) || employee.email.toLowerCase().includes(searchValue.toLowerCase()),
        )

    return employeesOfCompany.filter((e) => filtersToApply.every((filterFn) => filterFn(e)))
}

export const useEmployeeById = (id: string) => useEmployees().find((employee: Employee) => employee.id === id)

export const useEmployee = () => new Employee(useSelector(({ employees }: RootState) => employees.item))

export const useLoggedInEmployee = () => {
    const { userId } = useAuth()
    return new Employee(useSelector(({ employees: { items } }: RootState) => items.find((employee) => employee.id === userId)))
}

export const useEmployeeLanguage = () => useLoggedInEmployee().getUserLanguage()

export const useEmployeesWithEditingRights = ({ excludeDeleted, rolesMap }: { excludeDeleted: boolean; rolesMap: Map<string, Role> }) => {
    const employeesExcludeDeleted = useEmployees({ excludeDeleted })
    return employeesExcludeDeleted
        .filter((e) => !rolesMap.get(e.activeCompanyProfile?.roleId)?.hasNoWriteRights)
        .sort((employeeA: Employee, employeeB: Employee) => `${employeeA.firstName} ${employeeA.lastName}`.localeCompare(`${employeeB.firstName} ${employeeB.lastName}`))
}
