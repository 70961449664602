import { CollectionNameEnum, ImportResult, ImportResultTypeEnum } from "@finway-group/shared/lib/models"
import { Collapse, Tabs } from "antd"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"

import { ImportResultOperationEnum } from "Components/modals/spreadsheetDataUpload/importResult.types"
import { getDataUploadTotalCounts } from "Shared/store/actions/import/importActions"

export const ImportResults = ({ importResult }: { importResult: ImportResult }) => {
    const { t } = useTranslation()

    const importTotals = useMemo(() => {
        if (importResult) return getDataUploadTotalCounts(importResult)
        return { errorsList: 0, created: 0, restored: 0, ignored: 0 }
    }, [importResult])

    const isEmpty = importTotals.errorsList + importTotals.created + importTotals.restored + importTotals.ignored < 1

    const renderErrorResults = (validationErrors: any) => (
        <ul>
            {validationErrors?.map(({ identifier, row, error }: any, i: number) => (
                <li key={row} className="m-20">
                    <p className="inline-block font-bold truncate" style={{ maxWidth: "40ch" }}>
                        {`${identifier} - `}
                    </p>
                    <p className="inline-block font-bold ml-5 overflow-hidden whitespace-no-wrap">{`${t("info:row")}: ${row}`}</p>
                    <div className="ml-40">
                        <ul>
                            {error?.map(({ msg }: { value?: any; msg?: string; param?: string; location?: string }) => (
                                <li key={i + row} className="list-disc">
                                    <p>{msg?.toString()}</p>
                                </li>
                            ))}
                        </ul>
                    </div>
                </li>
            ))}
        </ul>
    )

    const renderOtherResults = (entries: any) => (
        <ul>
            {entries?.map(({ identifier, row, msg }: any) => (
                <li key={row} className="m-20">
                    <p className="inline-block font-bold truncate" style={{ maxWidth: "40ch" }}>
                        {identifier}
                    </p>
                    <p className="inline-block font-bold ml-5 overflow-hidden whitespace-no-wrap">{`${t("info:on_row")}: ${row}`}</p>
                    <div className="ml-40">
                        <p>{msg}</p>
                    </div>
                </li>
            ))}
        </ul>
    )

    const collapsePanelClassName = "border-2 rounded hover:shadow my-10"

    const renderTabPane = (importResultOperationEnum: ImportResultOperationEnum) => {
        // prettier-ignore
        const operationString = importResultOperationEnum === ImportResultOperationEnum.CREATE ? "created" : importResultOperationEnum === ImportResultOperationEnum.RESTORE ? "restored" : importResultOperationEnum === ImportResultOperationEnum.IGNORE ? "ignored" : "errorsList"
        const renderResultList = importResultOperationEnum === ImportResultOperationEnum.ERROR ? renderErrorResults : renderOtherResults

        return (
            <Tabs.TabPane
                className="animation-appear max-h-300 overflow-auto"
                key={importResultOperationEnum}
                tab={`${t(`input:data_upload.${operationString}`)} (${importTotals[operationString]})`}
            >
                <Collapse expandIconPosition="right" className={"bg-transparent border-0"}>
                    {operationString === "ignored" && <span>{t("info:ignored_entries_because_they_exist_in_the_app")}</span>}
                    {importResult?.collections.teams?.[operationString].length && (
                        <Collapse.Panel header={t("input:data_upload.teams")} key={CollectionNameEnum.TEAM} className={collapsePanelClassName}>
                            {renderResultList(importResult?.collections.teams?.[operationString])}
                        </Collapse.Panel>
                    )}
                    {importResult?.collections.employees?.[operationString].length && (
                        <Collapse.Panel header={t("input:data_upload.employees")} key={CollectionNameEnum.USER} className={collapsePanelClassName}>
                            {renderResultList(importResult.collections.employees?.[operationString])}
                        </Collapse.Panel>
                    )}
                    {importResult?.collections.groupCostCenters?.[operationString].length && (
                        <Collapse.Panel header={t("input:data_upload.group_cost_centers")} key={`group${CollectionNameEnum.COST_CENTER}`} className={collapsePanelClassName}>
                            {renderResultList(importResult.collections.groupCostCenters?.[operationString])}
                        </Collapse.Panel>
                    )}
                    {importResult?.collections.costCenters?.[operationString].length && (
                        <Collapse.Panel header={t("input:data_upload.cost_centers")} key={CollectionNameEnum.COST_CENTER} className={collapsePanelClassName}>
                            {renderResultList(importResult.collections.costCenters?.[operationString])}
                        </Collapse.Panel>
                    )}
                    {importResult?.collections.costCenters2?.[operationString].length && (
                        <Collapse.Panel header={t("input:data_upload.cost_centers_2")} key={CollectionNameEnum.COST_CENTER_2} className={collapsePanelClassName}>
                            {renderResultList(importResult.collections.costCenters2?.[operationString])}
                        </Collapse.Panel>
                    )}
                    {importResult?.collections.expenseAccounts?.[operationString].length && (
                        <Collapse.Panel header={t("input:data_upload.expense_accounts")} key={CollectionNameEnum.EXPENSE_ACCOUNT} className={collapsePanelClassName}>
                            {renderResultList(importResult.collections.expenseAccounts?.[operationString])}
                        </Collapse.Panel>
                    )}
                    {importResult?.collections.vendors?.[operationString].length && (
                        <Collapse.Panel header={t("input:data_upload.vendors")} key={CollectionNameEnum.VENDOR} className={collapsePanelClassName}>
                            {renderResultList(importResult.collections.vendors?.[operationString])}
                        </Collapse.Panel>
                    )}
                    {importResult?.collections.subscriptions?.[operationString].length && (
                        <Collapse.Panel header={t("input:data_upload.subscriptions")} key={CollectionNameEnum.EXPENSE} className={collapsePanelClassName}>
                            {renderResultList(importResult.collections.subscriptions?.[operationString])}
                        </Collapse.Panel>
                    )}
                </Collapse>
            </Tabs.TabPane>
        )
    }

    if (isEmpty) return <p>{t("info:data_validation_empty_sheet")}</p>

    return (
        <div className="animation-appear max-h-400 w-full">
            <p> {importResult.importResultType === ImportResultTypeEnum.VALIDATION ? t("info:data_validation_result_below") : t("info:data_import_result_below")}</p>
            <Tabs
                className="w-full min-h-300"
                defaultActiveKey={ImportResultOperationEnum.ERROR}
                tabBarExtraContent={{
                    left: (
                        <p className="mr-20">
                            {importResult.importResultType === ImportResultTypeEnum.VALIDATION
                                ? t("info:the_following_entries_will_be")
                                : t("info:the_following_entries_have_been")}
                        </p>
                    ),
                }}
            >
                {importTotals.errorsList ? renderTabPane(ImportResultOperationEnum.ERROR) : <></>}
                {importTotals.created ? renderTabPane(ImportResultOperationEnum.CREATE) : <></>}
                {importTotals.restored ? renderTabPane(ImportResultOperationEnum.RESTORE) : <></>}
                {importTotals.ignored ? renderTabPane(ImportResultOperationEnum.IGNORE) : <></>}
            </Tabs>
        </div>
    )
}
