import { Role, StandardRoleEnum } from "@finway-group/shared/lib/models"
import { useSelector } from "react-redux"

import { RootState } from "Shared/store/rootState.interface"

const STANDARD_ROLES: Array<string> = Object.values(StandardRoleEnum)

export const useRoles = ({ excludeDeleted }: { excludeDeleted?: boolean } = { excludeDeleted: false }) => {
    let roles = useSelector((state: RootState) => state.roles.items)
    if (excludeDeleted) roles = roles.filter((role) => !role.deleted)

    // Custom sorting as per product spec
    const sortedRoles: Array<Role> = []
    const pushIfPresent = (standardRole: StandardRoleEnum) => {
        const val = roles.find((role) => role.name === standardRole)
        if (val) sortedRoles.push(val)
    }
    // STANDARD roles in THIS fixed order
    pushIfPresent(StandardRoleEnum.EMPLOYEE)
    pushIfPresent(StandardRoleEnum.APPROVER)
    pushIfPresent(StandardRoleEnum.ADMIN)
    pushIfPresent(StandardRoleEnum.AUDITOR)
    // CUSTOM roles alphabetical
    roles.sort((a, b) => a.name.localeCompare(b.name))
    roles.forEach((role) => {
        if (!STANDARD_ROLES.includes(role.name)) sortedRoles.push(role)
    })

    return sortedRoles
}

export const useAuditorRole = () => {
    const roles = useSelector((state: RootState) => state.roles.items)
    return roles.find((r) => r.name === StandardRoleEnum.AUDITOR)
}

export const useRolesForSelect = ({ id }: { id?: string } = {}) => {
    const roles = useSelector((state: RootState) => state.roles.items)
    return roles.filter((role) => !role.deleted || role._id === id)
}

export const useRolesMap = ({ excludeDeleted }: { excludeDeleted?: boolean } = { excludeDeleted: false }) => new Map(useRoles({ excludeDeleted }).map((role) => [role._id, role]))

export const useRole = () => useSelector(({ roles }: RootState) => roles.item)

export const useRoleById = (id: string) => useRoles().find((role: Role) => role._id === id)
