import { COLOR, HIGHLIGHT_GREEN, HIGHLIGHT_RED } from "@finway-group/shared/lib/consts"
import { ActualForecastType, AntdTableCompatibleCashFlow, CashFlowRowIndexEnum, CurrencyEnum, PriceIntervalEnum, cashFlowRowIndexEnumArray } from "@finway-group/shared/lib/models"
import { roundNumberTo2Decimals } from "@finway-group/shared/lib/utils"
import { Tooltip } from "antd"
import React from "react"
import { Trans, useTranslation } from "react-i18next"

import PriceLabel from "Components/priceLabel"
import { getTooltipPopupContainer, isSet } from "Shared/utils/helper.utils"

interface ActualDataPropInterface {
    value: ActualForecastType
    currency: CurrencyEnum
    record: AntdTableCompatibleCashFlow
    periodIndex: string
}

const ActualData = ({ value, currency, record, periodIndex }: ActualDataPropInterface) => {
    const { t } = useTranslation()

    const eurDiff = roundNumberTo2Decimals(value.actual - value.forecast)
    const deviationPercentage = value.forecast !== 0 ? roundNumberTo2Decimals((eurDiff / Math.abs(value.forecast)) * 100) : 0

    // we consider the case where the parent category includes in turn transactions. We show a tooltip showing the total amount of the transactions that belong to the parent category.
    let cumulatedChildrenAmount
    if (record.children?.length && !cashFlowRowIndexEnumArray.includes(Number(record.key))) {
        cumulatedChildrenAmount = 0
        for (const child of record.children) {
            cumulatedChildrenAmount += child[periodIndex].actual
        }
    }
    const parentCategoryTooltipCondition = isSet(cumulatedChildrenAmount) && !cashFlowRowIndexEnumArray.includes(Number(record.key)) && cumulatedChildrenAmount !== value.actual

    return (
        <Tooltip
            getPopupContainer={getTooltipPopupContainer}
            title={
                <div>
                    <p className="text-black">
                        <span style={{ color: deviationPercentage >= 0 ? HIGHLIGHT_GREEN : HIGHLIGHT_RED }}>
                            {deviationPercentage > 0 && "+"}
                            {deviationPercentage}%{" "}
                        </span>
                        {t("label:liquidity.compared_to_plan")}
                    </p>
                    <p className="text-black">
                        <span style={{ color: eurDiff >= 0 ? HIGHLIGHT_GREEN : HIGHLIGHT_RED }}>
                            {eurDiff > 0 && "+"}
                            <PriceLabel value={eurDiff} currency={currency} interval={PriceIntervalEnum.ONE_TIME} />{" "}
                        </span>
                        {t("label:liquidity.compared_to_plan")}
                    </p>

                    {parentCategoryTooltipCondition && (
                        <p className="text-black mt-20">
                            <span>
                                <Trans
                                    t={t}
                                    i18nKey={"label:liquidity.directly_allocated_to_parent_category"}
                                    values={{ categoryName: record.rowTitle }}
                                    components={[<PriceLabel value={value.actual - (cumulatedChildrenAmount ?? 0)} currency={currency} interval={PriceIntervalEnum.ONE_TIME} />]}
                                />
                            </span>
                        </p>
                    )}
                </div>
            }
            color={COLOR.finway["alice-blue"]}
            mouseEnterDelay={1}
            destroyTooltipOnHide
        >
            <p>
                <PriceLabel value={Number(value.actual.toFixed(2))} currency={currency} interval={PriceIntervalEnum.ONE_TIME} />
            </p>
        </Tooltip>
    )
}

export default React.memo(ActualData)
