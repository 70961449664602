import { DocumentChangeInterface, OperationTypeEnum } from "@finway-group/shared/lib/models"
import * as Sentry from "@sentry/react"

import { refetchTable } from "Shared/store/actions/tables/tableActions"
import { fetchAllWorkflows } from "Shared/store/actions/workflow/workflowActions"
import { TablesEnum } from "Shared/store/reducers/tableConfigReducer"
import { isClientErrorCode } from "Shared/utils/errorHandler.utils"

export const subscribeToWorkflow = async (dispatch: any, data: DocumentChangeInterface) => {
    const {
        fullDocument: { operationType },
    } = data

    try {
        if (
            operationType === OperationTypeEnum.INSERT ||
            operationType === OperationTypeEnum.UPDATE ||
            operationType === OperationTypeEnum.DELETE ||
            operationType === OperationTypeEnum.BULK_UPDATE
        ) {
            await dispatch(fetchAllWorkflows())
            dispatch(refetchTable(TablesEnum.WORKFLOWS))
        }
    } catch (err) {
        if (isClientErrorCode(err)) return
        Sentry.captureException(`[Workflow Socket] Error: ${typeof err === "string" ? err : JSON.stringify(err)}`)
    }
}
