import { Expense, SplitTypeEnum, TripFolder } from "@finway-group/shared/lib/models"
import moment from "moment"

import { ApprovalProcessService, EmployeeService, OcrService, VendorService } from "Shared/services"
import NotificationService, { NotificationTypeEnum } from "Shared/services/notification.service"
import { updateExpense } from "Shared/store/actions/expense/expenseActions"
import { deleteUnnecessaryExpenseFormData, isHospitalityExpense, syncExpenseSplits } from "Shared/utils/expense.utils"
import { FillFormWithOcrDataParams, OnUpdateExpenseFromOcrParams, UpdatedOcrObject } from "Shared/utils/ocr.utils.types"

// TODO: these hooks should be refactored to act as real hooks, e.g. using useDispatch instead of passing dispatch into the function
export const onUpdateExpenseFromOcr = async ({
    updatedOcrObject,
    activeExpense,
    vendors,
    taxes,
    t,
    isOcrItemizationEnabled,
    company,
    expense,
    dispatch,
}: OnUpdateExpenseFromOcrParams) => {
    try {
        const updateData = OcrService.buildOcrUpdateData({
            ocrObject: updatedOcrObject,
            existingData: activeExpense,
            vendors,
            taxes,
            t,
            ocrItemizationEnabled: isOcrItemizationEnabled,
        })
        const sanitizedUpdateData = deleteUnnecessaryExpenseFormData(updateData)

        // check for vendor rule to adjust dueOn if needed
        const vendor = activeExpense.vendor?._id ? VendorService.getVendorById(activeExpense.vendor._id) : undefined
        const paymentTerm = vendor?.rule?.paymentTerm
        const dueDateUpdate =
            !sanitizedUpdateData.invoiceDueDate && paymentTerm && sanitizedUpdateData.invoiceDate
                ? { invoiceDueDate: moment(sanitizedUpdateData.invoiceDate).add(paymentTerm, "days") }
                : {}
        const newExpense = new Expense({ ...activeExpense, ...sanitizedUpdateData, ...dueDateUpdate, skipEmail: true, isOcrUpdate: true })
        const updatedExpense = syncExpenseSplits(newExpense, newExpense.splitType)

        // Rebuild approval process with ocr changes. Expenses inside folder do not change its aproval processes and follow the folder approval processes.
        if (!updatedExpense.folderId) {
            const approvalProcesses = await ApprovalProcessService.getApprovalProcessesAfterExpenseUpdate(updatedExpense, company, {
                previousExpense: expense,
                awaitConfirmation: true,
                ocrConfirmation: true,
            })
            if (!approvalProcesses) return
            updatedExpense.approvalProcesses = approvalProcesses
        } else {
            const folder = expense as TripFolder
            const isFolderAmountChangeApproved = await ApprovalProcessService.confirmFolderAmountChange(
                updatedExpense.totalGrossPrice - activeExpense.totalGrossPrice,
                folder,
                company,
            )
            if (!isFolderAmountChangeApproved) return
        }

        await updateExpense(updatedExpense.id, updatedExpense)(dispatch)
        NotificationService.send(NotificationTypeEnum.INFO, t("notification:ocr.processed.title"), t("notification:ocr.processed_and_updated.message"), 60)
    } catch (err) {
        NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:ocr.title"))
    }
}

export const fillFormWithOcrData = async ({
    formInstance,
    expense,
    ocrObject,
    vendors,
    taxes,
    updateExpense,
    setPrefilledVendorData,
    t,
    ocrItemizationEnabled,
}: FillFormWithOcrDataParams) => {
    const isHospitality = isHospitalityExpense(expense)
    const requester = EmployeeService.getEmployeeById(expense.requestedBy._id)
    const formFields = formInstance.getFieldsValue()
    if (!formFields.paymentOption) formFields.paymentOption = expense.paymentOption
    if (!formFields.kind) formFields.kind = expense.kind
    if (!formFields.splitType) formFields.splitType = ocrItemizationEnabled ? SplitTypeEnum.ITEM : expense.splitType

    const updateData: UpdatedOcrObject = OcrService.buildOcrUpdateData({
        ocrObject,
        existingData: formFields,
        vendors,
        taxes,
        t,
        ocrItemizationEnabled,
        isHospitality,
        requester,
    })

    if (ocrObject.vendor) setPrefilledVendorData({ ...ocrObject.vendor, description: t("info:filled_by_ocr") })

    updateExpense(updateData)
}
