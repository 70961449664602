import { ClockCircleOutlined } from "@ant-design/icons"
import { Button, Empty } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import { useWallet } from "Shared/hooks/corporate.hooks"
import { NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import WalletService from "Shared/services/wallet.service"
import { setWallet } from "Shared/store/actions/corporate/corporateActions"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

interface FinishWalletInterface {}

const FinishWallet: React.FC<FinishWalletInterface> = () => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const [isCreatingIban, setIsCreatingIban] = useStateIfMounted(false)
    const wallet = useWallet()

    const createIbanForWallet = async () => {
        setIsCreatingIban(true)
        try {
            const updatedWallet = await WalletService.createIban(wallet.accountId)
            dispatch(setWallet(updatedWallet))
            NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:wallet.iban_created.title"), t("notification:wallet.iban_created.message"))
        } catch (error) {
            NotificationService.showErrorNotificationBasedOnResponseError(error, t("error:error"))
        } finally {
            setIsCreatingIban(false)
        }
    }

    return (
        <Empty
            image={`./icons/walletCreation.svg`}
            imageStyle={{
                height: 150,
                marginRight: "auto",
                marginLeft: "auto",
                marginTop: "15%",
                marginBottom: "40px",
                display: "inline-block",
            }}
            description={<span className="text-lg font-bold mt-2 mb-0">{t("info:empty_state.wallet_iban_process.title")}</span>}
        >
            <div className="flex flex-col justify-between items-center">
                <p>{t("info:empty_state.wallet_iban_process.message")}</p>
                <Button className="mt-14" type="primary" loading={isCreatingIban} onClick={createIbanForWallet} icon={<ClockCircleOutlined />}>
                    {t("action:wallet:finish_setup")}
                </Button>
            </div>
        </Empty>
    )
}

export default FinishWallet
