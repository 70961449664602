import { Workflow } from "@finway-group/shared/lib/models"
import { Action } from "redux"

export enum WorkflowActionTypes {
    FETCH_ALL_WORKFLOWS = "FETCH_ALL_WORKFLOWS",
    SYNC_WORKFLOW = "SYNC_WORKFLOW",
    NEW_WORKFLOW = "NEW_WORKFLOW",
    SET_WORKFLOW = "SET_WORKFLOW",
}

export interface FetchAllWorkflowsAction extends Action<typeof WorkflowActionTypes.FETCH_ALL_WORKFLOWS> {
    type: typeof WorkflowActionTypes.FETCH_ALL_WORKFLOWS
    workflows: Array<Workflow>
}

export interface SyncWorkflowAction extends Action<typeof WorkflowActionTypes.SYNC_WORKFLOW> {
    type: typeof WorkflowActionTypes.SYNC_WORKFLOW
    workflow: Workflow
}

export interface NewWorkflowAction extends Action<typeof WorkflowActionTypes.NEW_WORKFLOW> {
    type: typeof WorkflowActionTypes.NEW_WORKFLOW
    workflow: Workflow
}

export interface SetWorkflowAction extends Action<typeof WorkflowActionTypes.SET_WORKFLOW> {
    type: typeof WorkflowActionTypes.SET_WORKFLOW
    workflow: Workflow | undefined
}

export type WorkflowActions = FetchAllWorkflowsAction | SyncWorkflowAction | SetWorkflowAction | NewWorkflowAction
