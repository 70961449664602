import { DocumentChangeInterface, OperationTypeEnum } from "@finway-group/shared/lib/models"
import * as Sentry from "@sentry/react"

import i18n from "Shared/locales/i18n"
import { syncOneCostCenter } from "Shared/store/actions/costCenter/costCenterActions"
import { refetchTable } from "Shared/store/actions/tables/tableActions"
import { TablesEnum } from "Shared/store/reducers/tableConfigReducer"
import { isClientErrorCode } from "Shared/utils/errorHandler.utils"

import FileService from "../file.service"
import NotificationService, { NotificationTypeEnum } from "../notification.service"

const subscribeToCostCenter = async (dispatch: any, data: DocumentChangeInterface) => {
    const {
        fullDocument: { documentId, operationType },
    } = data

    try {
        if (operationType === OperationTypeEnum.INSERT || operationType === OperationTypeEnum.UPDATE || operationType === OperationTypeEnum.DELETE) {
            await dispatch(syncOneCostCenter(documentId))
            dispatch(refetchTable(TablesEnum.COST_CENTERS))
        }
    } catch (err) {
        if (isClientErrorCode(err)) return
        Sentry.captureException(`[Cost Center Socket] Error: ${typeof err === "string" ? err : JSON.stringify(err)}`)
    }
}

/**
 * For async actions for the same user who initiated it
 */
export const subscribeToCostCenterCallbacks = (data: DocumentChangeInterface) => {
    // prettier-ignore
    const { fullDocument: { operationType, metaData } } = data;

    switch (operationType) {
        case OperationTypeEnum.ASYNC_EXPORT:
            NotificationService.send(
                NotificationTypeEnum.SUCCESS,
                i18n.t("notification:cost_center.exported_bulk.title"),
                i18n.t("notification:cost_center.exported_bulk.message"),
                0,
            )
            FileService.downloadFileAsSignedUrl((metaData as any).fileUrl)
            break
        default:
            break
    }
}

export default subscribeToCostCenter
