import { notification } from "antd"
import i18next from "i18next"
import React, { ReactElement, ReactNode, useEffect } from "react"
import { useDispatch } from "react-redux"

import { DataUploaderOpenModalButton } from "Components/actionButton/dataUploaderOpenModalButton"
import { useModal } from "Shared/context/modal.context"
import { useDataUploadNotification } from "Shared/hooks/import.hooks"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { closeDataUploadNotification } from "Shared/store/actions/import/importActions"
import { DataUploadNotificationTypeEnum } from "Shared/store/actions/import/importTypes"

export const DataUploadNotification: React.FC<{}> = () => {
    const dataUploadNotification = useDataUploadNotification()
    const dispatch = useDispatch()
    const { component } = useModal()

    const [api, contextHolder] = notification.useNotification()

    useEffect(() => {
        if (!dataUploadNotification) return
        if (component?.displayName === "SpreadsheetDataUploadModal") {
            // if data upload is open we dont show a notification, and remove it rom redux
            dispatch(closeDataUploadNotification())
            return
        }

        const { type, message, description, buttonText } = getPropsBasedOnNotificationType(dataUploadNotification)
        api.open({
            type,
            message,
            description,
            onClose: () => {
                dispatch(closeDataUploadNotification())
            },
            duration: 600,
            btn: <DataUploaderOpenModalButton text={buttonText} buttonType="primary" />,
            className: "max-w-full",
            key: "notification-import-result",
        })
    }, [dataUploadNotification])

    return <>{dataUploadNotification && contextHolder}</>
}

const getPropsBasedOnNotificationType = (notificationType?: DataUploadNotificationTypeEnum) => {
    switch (notificationType) {
        case DataUploadNotificationTypeEnum.VALIDATE_ONLY_SUCCESS:
            return {
                type: NotificationTypeEnum.INFO,
                message: i18next.t("notification:import_result.validate_only.title"),
                description: i18next.t("notification:import_result.validate_only.message"),
                buttonText: i18next.t("action:validation_results"),
            }

        case DataUploadNotificationTypeEnum.VALIDATION_ERRORS:
            return {
                type: NotificationTypeEnum.WARNING,
                message: i18next.t("notification:import_result.validation_errors.title"),
                description: i18next.t("notification:import_result.validation_errors.message"),
                buttonText: i18next.t("action:validation_results"),
            }

        case DataUploadNotificationTypeEnum.SUCCESS:
            return {
                type: NotificationTypeEnum.SUCCESS,
                message: i18next.t("notification:import_result.success.title"),
                description: i18next.t("notification:import_result.success.message"),
                buttonText: i18next.t("action:import_results"),
            }
        default:
            return { message: "", description: "", buttonText: "" }
    }
}
