import { COLOR } from "@finway-group/shared/lib/consts"
import { Button, Skeleton, Tooltip } from "antd"
import React from "react"
import { ArrowLeftCircle, ArrowRightCircle } from "react-feather"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

import { getTooltipPopupContainer } from "Shared/utils/helper.utils"

interface ExpenseSwitchingInterface {
    prevExpense: string
    nextExpense: string
    adjacentExpenseLabel: string
    hasError: boolean
    isLoading: boolean
}

const ExpenseSwitching: React.FC<ExpenseSwitchingInterface> = ({ hasError, isLoading, prevExpense, nextExpense, adjacentExpenseLabel }) => {
    const { t } = useTranslation()

    /* Specifying the color for the icon is needed because the icon is not disabled when the button is disabled */
    const previousButton = <Button disabled={!prevExpense} className="btn-default mr-3" icon={<ArrowLeftCircle color={!prevExpense ? COLOR.button.disabled : undefined} />} />
    const nextButton = <Button disabled={!nextExpense} className="btn-default" icon={<ArrowRightCircle color={!nextExpense ? COLOR.button.disabled : undefined} />} />

    return hasError ? (
        <> </>
    ) : isLoading ? (
        <div className="m-5">
            <Skeleton.Avatar className="mr-3" shape="square" />
            <Skeleton.Avatar shape="square" />
        </div>
    ) : (
        <div className="m-5">
            <Tooltip
                getPopupContainer={getTooltipPopupContainer}
                placement="bottom"
                title={
                    <span className="block min-w-180">
                        {t("tooltips:adjacent_request.previous")}
                        <strong>{t(`tooltips:adjacent_request.${adjacentExpenseLabel}`)} </strong>
                    </span>
                }
            >
                {prevExpense ? <Link to={{ pathname: prevExpense, state: { fromExpenseSwitching: true } }}>{previousButton} </Link> : previousButton}
            </Tooltip>
            <Tooltip
                getPopupContainer={getTooltipPopupContainer}
                placement="bottom"
                title={
                    <span className="block min-w-180">
                        {t("tooltips:adjacent_request.next")}
                        <strong>{t(`tooltips:adjacent_request.${adjacentExpenseLabel}`)} </strong>
                    </span>
                }
            >
                {nextExpense ? <Link to={{ pathname: nextExpense, state: { fromExpenseSwitching: true } }}> {nextButton} </Link> : nextButton}
            </Tooltip>
        </div>
    )
}

export default ExpenseSwitching
