import { COLOR, vendorRecommendations } from "@finway-group/shared/lib/consts"
import { RightEnum, UploadKindEnum, User, Vendor } from "@finway-group/shared/lib/models"
import { MAX_INPUT_LENGTH } from "@finway-group/shared/lib/utils/validators"
import { Button, Col, Form, Input, Row, Select, Upload } from "antd"
import { FormInstance } from "antd/lib/form"
import { Store } from "antd/lib/form/interface"
import { RcCustomRequestOptions, RcFile } from "antd/lib/upload/interface"
import React, { useEffect, useState } from "react"
import { Search as SearchIcon, Upload as UploadIcon } from "react-feather"
import { useTranslation } from "react-i18next"
import PhoneInput from "react-phone-number-input"
import "react-phone-number-input/style.css"

import IbanInput from "Components/ibanInput"
import IconSearchModal from "Features/pages/vendors/iconSearchModal"
import { API_URL } from "Shared/config/consts"
import { useCompany } from "Shared/hooks/company.hooks"
import { useLoggedInEmployeeProfile } from "Shared/hooks/user.hooks"
import { AuthzService, FileService, VendorService } from "Shared/services"
import { getDisplayCountryCallingCode, getIBANPrintFormat, validateCreditorNumberDATEVCompatibility } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"
import { getPopupAnchor } from "Shared/utils/popup.utils"

import { VendorRules } from "./rules"

const { Option } = Select

interface VendorFormInterface {
    formInstance: FormInstance
    vendor: Vendor
    loggedInUser: User
    isNew: boolean
    onSubmit: (values: Store) => void
    setIsUploading: (flag: boolean) => void
    onFormValuesChange: () => void
}
export const VendorForm: React.FC<VendorFormInterface> = ({ formInstance, vendor, setIsUploading, isNew, onSubmit, onFormValuesChange }) => {
    const { t } = useTranslation()
    const rules = VendorRules()
    const companyDefaultCreditorNumber = useCompany()?.defaultCreditorNumber
    const [fileList, setFileList] = useStateIfMounted<Array<any>>([])
    const [showIconSearch, setShowIconSearch] = useState(false)
    const [contactPersonPhone, setContactPersonPhone] = useStateIfMounted(formInstance.getFieldValue("contactPersonPhone"))
    const [phoneInputPlaceholder, setPhoneInputPlaceholder] = useState(getDisplayCountryCallingCode())
    const [creditorNumberInfo, setCreditorNumberInfo] = useStateIfMounted<string | undefined>(undefined)
    const loggedInUser = useLoggedInEmployeeProfile()
    useEffect(() => {
        let isFetching = true

        const getLatestCreditorNumber = async () => {
            const latestSequence = await VendorService.getLatestVendorCreditorNumber()
            formInstance.setFieldsValue({ creditorNumber: latestSequence })
        }

        if (!isNew && vendor) initFormWithVendorData()
        if (isNew && isFetching) getLatestCreditorNumber()

        return () => {
            isFetching = false
        }
    }, [vendor.id])

    const initFormWithVendorData = () => {
        if (vendor.iban) vendor.iban = getIBANPrintFormat(vendor.iban)
        if (vendor.img) {
            const currentFileList = [
                {
                    uid: "1",
                    name: vendor.name,
                    status: "done",
                    thumbUrl: vendor.img,
                    type: "image/png",
                },
            ]
            setFileList(currentFileList)
        }
        setContactPersonPhone(vendor.contactPersonPhone)
    }

    // TODO: Move this to consts as it is used on multiple places
    const doFileUpload = async (options: RcCustomRequestOptions) => {
        const { onSuccess, onError, onProgress, file } = options
        if (!FileService.checkFileSizeAndHandleError(file)) return

        try {
            setIsUploading(true)
            const data = await FileService.upload(file, UploadKindEnum.VENDOR_IMG, onProgress)
            setIsUploading(false)
            onSuccess(data, data.url as unknown as RcFile)
        } catch (err) {
            setIsUploading(false)
            onError(err)
        }
    }

    const onIconChosen = (name: string, iconUrl: string) => {
        formInstance.setFieldsValue({ img: iconUrl })

        setFileList([
            {
                uid: -1,
                name,
                status: "done",
                url: iconUrl,
                thumbUrl: iconUrl,
                type: "image/png",
            },
        ])
    }

    const handlePhoneNumberChange = (value: string) => {
        formInstance.setFieldsValue({
            contactPersonPhone: value,
        })
        return {
            contactPersonPhone: value,
        }
    }

    const handleCountryChange = (value: any) => {
        if (value) {
            const displayCountryCallingCode = getDisplayCountryCallingCode(value)
            setPhoneInputPlaceholder(displayCountryCallingCode)
        }
    }

    const handleCreditorNumberChange = () => {
        const formValues = formInstance.getFieldsValue()
        const { creditorNumber } = formValues
        try {
            validateCreditorNumberDATEVCompatibility(creditorNumber)
            setCreditorNumberInfo(undefined)
        } catch (err) {
            setCreditorNumberInfo(t("error:vendor.incompatible_creditor_number"))
        }
    }

    const canEditVendor = isNew || AuthzService.isRightGrantedForLoggedInUser(RightEnum.VENDOR_PAYMENT_DATA__ALL__CREATE)

    return (
        <div>
            <Form layout="vertical" form={formInstance} onFinish={onSubmit} scrollToFirstError={true} onValuesChange={onFormValuesChange}>
                <Row gutter={[16, 16]} align="top">
                    <Col span={14} md="14">
                        <Form.Item label={t("input:vendor.company_name")} name="name" rules={rules.name} required>
                            <Input placeholder={t("placeholder:vendor.company_name")} required autoComplete="off" />
                        </Form.Item>
                    </Col>
                    <Col span={10} md="10">
                        <Form.Item
                            label={t("input:vendor.creditor_number")}
                            name="creditorNumber"
                            // Removed in: https://gitlab.com/finway-group/webapp/-/merge_requests/2616
                            // TODO GOB How can we fix this better? I'm sure we still need validation for this :(
                            //
                            // rules={rules.creditorNumber}
                            initialValue={companyDefaultCreditorNumber}
                            help={creditorNumberInfo}
                            validateStatus={creditorNumberInfo ? "warning" : undefined}
                        >
                            <Input placeholder={t("placeholder:vendor.creditor_number")} onChange={handleCreditorNumberChange} />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label={t("input:vendor.description")} name="description" rules={rules.description} normalize={(value) => (value === "" ? undefined : value)}>
                            <Input maxLength={MAX_INPUT_LENGTH} placeholder={t("placeholder:vendor.description")} />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label={t("input:vendor.recommendation")} name="recommendation" initialValue={0} rules={rules.recommendation}>
                            <Select getPopupContainer={getPopupAnchor()}>
                                {vendorRecommendations.map((recommendation, index) => (
                                    <Option key={index} value={index}>
                                        {t(`input:vendor.recommendations.${recommendation.toLowerCase()}`)}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={16} md="16">
                        <Form.Item label={t("input:vendor.street_name")} name="streetName" rules={rules.streetName}>
                            <Input maxLength={MAX_INPUT_LENGTH} placeholder={t("placeholder:vendor.street_name")} autoComplete="off" />
                        </Form.Item>
                    </Col>
                    <Col span={8} md="8">
                        <Form.Item label={t("input:vendor.street_number")} name="streetNumber" rules={rules.streetNumber}>
                            <Input maxLength={MAX_INPUT_LENGTH} placeholder={t("placeholder:vendor.street_number")} autoComplete="off" />
                        </Form.Item>
                    </Col>
                    <Col span={7} md="7">
                        <Form.Item label={t("input:vendor.zip")} name="zip" rules={rules.zip}>
                            <Input maxLength={MAX_INPUT_LENGTH} placeholder={t("placeholder:vendor.zip")} autoComplete="off" />
                        </Form.Item>
                    </Col>
                    <Col span={8} md="8">
                        <Form.Item label={t("input:vendor.city")} name="city" rules={rules.city}>
                            <Input maxLength={MAX_INPUT_LENGTH} placeholder={t("placeholder:vendor.city")} autoComplete="off" />
                        </Form.Item>
                    </Col>
                    <Col span={9} md="9">
                        <Form.Item label={t("input:vendor.country")} name="country" rules={rules.country}>
                            <Input maxLength={MAX_INPUT_LENGTH} placeholder={t("placeholder:vendor.country")} autoComplete="off" />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label={t("input:vendor.contact_person_name")} name="contactPersonName" rules={rules.contactPersonName}>
                            <Input maxLength={MAX_INPUT_LENGTH} placeholder={t("placeholder:vendor.contact_person_name")} autoComplete="off" />
                        </Form.Item>
                    </Col>
                    <Col span={12} md="12">
                        <Form.Item label={t("input:vendor.contact_person_email")} name="contactPersonEmail" rules={rules.contactPersonEmail}>
                            <Input type="email" placeholder={t("placeholder:vendor.contact_person_email")} autoComplete="off" />
                        </Form.Item>
                    </Col>
                    <Col span={12} md="12">
                        <Form.Item
                            label={t("input:vendor.contact_person_phone")}
                            name="contactPersonPhone"
                            rules={rules.contactPersonPhone}
                            getValueFromEvent={handlePhoneNumberChange}
                        >
                            <Input
                                hidden
                                className="hidden-input-phone-number-no-border"
                                addonAfter={
                                    <PhoneInput
                                        key="contactPersonPhone"
                                        placeholder={phoneInputPlaceholder}
                                        onCountryChange={handleCountryChange}
                                        onChange={handlePhoneNumberChange}
                                        value={contactPersonPhone}
                                        className="bg-white"
                                    />
                                }
                            />
                        </Form.Item>
                    </Col>
                    <Col span={14} md="14">
                        <Form.Item label={t("input:vendor.tax_number")} name="taxNumber">
                            <Input maxLength={MAX_INPUT_LENGTH} placeholder={t("placeholder:vendor.tax_number")} />
                        </Form.Item>
                    </Col>
                    <Col span={10} md="10">
                        <Form.Item label={t("input:vendor.vat_number")} name="vatNumber">
                            <Input maxLength={MAX_INPUT_LENGTH} placeholder={t("placeholder:vendor.vat_number")} />
                        </Form.Item>
                    </Col>
                    {(AuthzService.isRightGrantedForLoggedInUser(RightEnum.VENDOR_PAYMENT_DATA__ALL__CREATE) || loggedInUser.id === vendor.createdBy || isNew) && (
                        <>
                            <Col span={14} md="14">
                                <Form.Item label={t("input:vendor.iban")} name="iban" rules={rules.iban}>
                                    <IbanInput disabled={!canEditVendor} />
                                </Form.Item>
                            </Col>
                            <Col span={10} md="10">
                                <Form.Item label={t("input:vendor.bic")} name="bic" rules={rules.bic}>
                                    <Input placeholder={t("placeholder:vendor.bic")} disabled={!canEditVendor} />
                                </Form.Item>
                            </Col>
                        </>
                    )}
                    <Col span={fileList.length > 0 ? 24 : 20} md="20">
                        <Form.Item label={t("input:vendor.company_logo")} name="img" shouldUpdate>
                            <Upload
                                accept="image/*"
                                action={`${API_URL}/files`}
                                headers={{ Authorization: `Bearer ${localStorage.accessToken}` }}
                                listType="picture"
                                fileList={fileList}
                                onRemove={() => setFileList([])}
                                isImageUrl={(file: any) => !!file.type?.includes("image")}
                                // to avoid opening the URL on click
                                onPreview={() => {}}
                                customRequest={doFileUpload}
                                onChange={(data) => setFileList(data.fileList)}
                                progress={{
                                    strokeWidth: 3,
                                    showInfo: false,
                                    strokeColor: COLOR.secondary,
                                    className: "pr-20",
                                }}
                            >
                                {fileList.length === 0 && (
                                    <Button className="text-left btn-default">
                                        <UploadIcon />
                                        <span> {t("action:upload_company_logo")}</span>
                                    </Button>
                                )}
                            </Upload>
                        </Form.Item>
                    </Col>
                    {fileList.length === 0 && (
                        <Col span={4} md="4">
                            <Form.Item label=" ">
                                <Button onClick={(e) => setShowIconSearch(true)}>
                                    <SearchIcon />
                                </Button>
                            </Form.Item>
                        </Col>
                    )}
                </Row>
            </Form>

            <IconSearchModal isVisible={showIconSearch} onCancel={setShowIconSearch} setIcon={onIconChosen} />
        </div>
    )
}

export default VendorForm
