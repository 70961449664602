import { Button, Col, DatePicker, Row } from "antd"
import React from "react"
import { PlusCircle as PlusIcon } from "react-feather"
import { useTranslation } from "react-i18next"

import BankConnectionAlert from "Components/bankConnectionAlert"
import ForecastRulesModal from "Components/modals/liquidity/forecastRules.modal"
import { useAccountIds } from "Shared/hooks/bank.hooks"
import { useCashFlows, useLast6MonthsCashFlows, useScenario, useYearlyCashFlows } from "Shared/hooks/liquidity.hooks"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

import { CashFlowDirectionEnum } from "../../liquidity.types"
import NoBankAccountBanner from "../../supplements/noBankAccountBanner"
import PeriodRangePicker from "../../supplements/periodRangePicker"
import ScenarioSelectionComponent from "./ScenarioSelectionComponent"
import CashBalanceChart from "./cards/cashBalanceChart"
import CashFlowCumulatedCard from "./cards/cashFlowCumulatedCard"
import CashFlowProgressChart from "./cards/cashFlowProgressChart"
import CashFlowTableSkeleton from "./cashFlowDetails.skeleton"
import CashFlowDetails from "./cashflowDetails"
import ForecastDiagram from "./forecastDiagram"
import ForecastDiagramSkeleton from "./forecastDiagram.skeleton"

interface CashFlowPlanningTabInterface {
    isYearlyCashFlowLoading: boolean
    isTableLoading: boolean
    onCashFlowChange: () => void
}

const { RangePicker } = DatePicker

const CashFlowPlanningTab: React.FC<CashFlowPlanningTabInterface> = ({ isTableLoading, isYearlyCashFlowLoading, onCashFlowChange }) => {
    const { t } = useTranslation()
    const selectedScenario = useScenario()
    const yearlyCashFlowData = useYearlyCashFlows()
    const last6MonthsCashFlowData = useLast6MonthsCashFlows()
    const cashFlowData = useCashFlows()
    const accountIds = useAccountIds({ shouldFilterDeleted: true })

    const [showPlanTransactionModal, setShowPlanTransactionModal] = useStateIfMounted(false)

    const hasAccountConnected = accountIds.length > 0
    const widgetsHeight = 340

    return (
        <div className="space-y-12">
            <ForecastRulesModal isShowing={showPlanTransactionModal} onCancel={() => setShowPlanTransactionModal(false)} onCashFlowChange={onCashFlowChange} />
            {!hasAccountConnected && <NoBankAccountBanner />}
            <BankConnectionAlert showConsentAlert />

            <Row gutter={[12, 16]} justify="center" className="mt-20">
                <Col xs={24} sm={18} md={12} lg={10} xl={6}>
                    <CashBalanceChart cashFlowData={last6MonthsCashFlowData} isTableLoading={isYearlyCashFlowLoading} showSkeleton={!hasAccountConnected} height={widgetsHeight} />
                </Col>
                <Col xs={24} sm={18} md={12} lg={10} xl={6}>
                    <CashFlowCumulatedCard
                        cashFlowData={yearlyCashFlowData}
                        cashFlowDirection={CashFlowDirectionEnum.TOTAL_BALANCE}
                        isTableLoading={isYearlyCashFlowLoading}
                        showSkeleton={!hasAccountConnected}
                        height={widgetsHeight}
                    />
                </Col>
                <Col xs={24} sm={18} md={12} lg={10} xl={6}>
                    <CashFlowProgressChart
                        showSkeleton={!hasAccountConnected}
                        cashFlowData={yearlyCashFlowData}
                        cashFlowDirection={CashFlowDirectionEnum.TOTAL_INCOME}
                        title={t("label:liquidity.cash_inflow")}
                        isTableLoading={isYearlyCashFlowLoading}
                        height={widgetsHeight}
                    />
                </Col>
                <Col xs={24} sm={18} md={12} lg={10} xl={6}>
                    <CashFlowProgressChart
                        showSkeleton={!hasAccountConnected}
                        cashFlowData={yearlyCashFlowData}
                        cashFlowDirection={CashFlowDirectionEnum.TOTAL_SPENDING}
                        title={t("label:liquidity.cash_outflow")}
                        isTableLoading={isYearlyCashFlowLoading}
                        height={widgetsHeight}
                    />
                </Col>
            </Row>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <div className="flex flex-row justify-between mb-20">
                        <p className="text-xl font-semibold text-black pt-8">{t("label:liquidity.cash_forecast")}</p>
                        <div className="mt-5"> {hasAccountConnected && <ScenarioSelectionComponent isLoading={isTableLoading || isYearlyCashFlowLoading} />}</div>
                    </div>
                    {hasAccountConnected ? <ForecastDiagram cashFlowData={cashFlowData} isDiagramLoading={isTableLoading} /> : <ForecastDiagramSkeleton />}
                </Col>
            </Row>

            <Row gutter={[16, 16]}>
                {hasAccountConnected ? (
                    <Col span={24}>
                        <div className="flex flex-row space-x-10 justify-between mb-16">
                            <PeriodRangePicker className="min-w-300" />
                            <Button className="btn-default" icon={<PlusIcon />} onClick={() => setShowPlanTransactionModal(true)}>
                                <span>{t("action:liquidity.plan_transactions")}</span>
                            </Button>
                        </div>
                        {selectedScenario && (
                            <CashFlowDetails
                                cashFlowData={cashFlowData}
                                selectedScenario={selectedScenario}
                                isTableLoading={isTableLoading}
                                onCashFlowChange={onCashFlowChange}
                                setShowPlanTransactionModal={setShowPlanTransactionModal}
                            />
                        )}
                    </Col>
                ) : (
                    <Col span={24}>
                        <CashFlowTableSkeleton />
                    </Col>
                )}
            </Row>
        </div>
    )
}

export default CashFlowPlanningTab
