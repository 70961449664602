import { Tax } from "@finway-group/shared/lib/models"
import { useSelector } from "react-redux"

import { RootState } from "Shared/store/rootState.interface"

export const useTaxes = (excludeDeleted: boolean = false) => {
    let taxes = useSelector(({ taxes }: RootState) => taxes.items)

    if (excludeDeleted) {
        taxes = taxes?.filter((tax: Tax) => !tax.deleted)
    }

    return taxes.sort((a: Tax, b: Tax) => a.taxRate - b.taxRate)
}

export const useTaxById = (taxId?: string) => {
    const taxes = useSelector(({ taxes }: RootState) => taxes.items)
    if (!taxId) return undefined
    return taxes.find((tax) => tax._id === taxId)
}
