import React from "react"

import SettingsLayout from "../settings.layout"
import GetMyInvoicesSettings from "./getMyInvoicesIntegration"

const UserIntegrations = () => (
    <SettingsLayout titleKey="label:integrations">
        <div className="justify-items-center">
            <GetMyInvoicesSettings />
        </div>
    </SettingsLayout>
)

export default UserIntegrations
