import { CompanyPositionEnum } from "@finway-group/shared/lib/models"
import { Checkbox, Col, Input, Row, Select } from "antd"
import Form, { FormInstance } from "antd/lib/form/Form"
import FormItem from "antd/lib/form/FormItem"
import { Store } from "antd/lib/form/interface"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import CorporateRules from "Components/forms/rules/corporate.rules"
import ScrollToTop from "Components/scrollToTop"
import { PAYNETICS_TERMS_OF_AGREEMENT, WEAVR_TERMS_OF_AGREEMENT } from "Shared/config/consts"
import { CorporateService, NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"

import { CorporateIndustryTypeEnum } from "../types/corporateIndustryTypes"
import { CorporateSourceOfFundsEnum } from "../types/corporateSourceOfFundsTypes"

interface KYBCompanyDetailsInterface {
    formInstance: FormInstance
    onSubmit: () => void
    setIsLoading: (flag: boolean) => void
}

const KYBCompanyDetails: React.FC<KYBCompanyDetailsInterface> = ({ formInstance, onSubmit, setIsLoading }) => {
    const { t } = useTranslation()
    const [sourceOfFunds, setSourceOfFunds] = useState<any | undefined>()
    const rules = CorporateRules()

    const onFinish = (values: Store) => {
        setIsLoading(true)
        const companyDetails: any = { ...values, hasAcceptedTerms: values.weavrTerms && values.payneticsTerms }
        CorporateService.createCompanyData(companyDetails)
            .then(() => {
                NotificationService.send(
                    NotificationTypeEnum.SUCCESS,
                    t("notification:corporate.company_details_submitted.title"),
                    t("notification:corporate.company_details_submitted.message"),
                )
                onSubmit()
            })
            .catch((err) => NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:corporate.company_details_submit.title")))
            .finally(() => setIsLoading(false))
    }

    return (
        <div>
            <ScrollToTop />
            <div className="flex items-center">
                <h2 className="ml-10">{t("corporate:details.title")}</h2>
            </div>
            <div className="flex items-center mt-20">
                <Form form={formInstance} onFinish={onFinish} layout="vertical" className="mx-auto w-full" scrollToFirstError>
                    <Row gutter={[16, 0]}>
                        <Col span={12}>
                            <FormItem name="companyName" label={t("corporate:details.name")} required rules={rules.companyName}>
                                <Input />
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem name="companyRegistrationNumber" label={t("corporate:details.registration_number")} required rules={rules.companyRegistrationNumber}>
                                <Input />
                            </FormItem>
                        </Col>
                    </Row>
                    <Row gutter={[16, 0]}>
                        <Col span={12}>
                            <FormItem name="registrationCountry" label={t("corporate:details.registration_country")} required rules={rules.countryCode}>
                                <Input />
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem name="companyPosition" label={t("input:corporate.primary_contact_position")} required rules={rules.required}>
                                <Select>
                                    {Object.keys(CompanyPositionEnum)
                                        .filter((key: any) => isNaN(key))
                                        .map((key, index) => (
                                            <Select.Option key={index} value={key}>
                                                {t(`corporate:company_position.${key.toLowerCase()}`)}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem name="industryType" label={t("input:corporate.industry_type")} required rules={rules.required}>
                                <Select>
                                    {Object.keys(CorporateIndustryTypeEnum)
                                        .filter((key: any) => isNaN(key))
                                        .map((key, index) => (
                                            <Select.Option key={index} value={key}>
                                                {t(`corporate:industry_type.${key.toLowerCase()}`)}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem name="sourceOfFunds" label={t(`input:corporate.source_of_funds`)} required rules={rules.required}>
                                <Select onChange={(value) => setSourceOfFunds(value)}>
                                    {Object.keys(CorporateSourceOfFundsEnum)
                                        .filter((key: any) => isNaN(key))
                                        .map((key, index) => (
                                            <Select.Option key={index} value={key}>
                                                {t(`corporate:source_of_funds.${key.toLowerCase()}`)}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </FormItem>
                        </Col>
                    </Row>
                    {sourceOfFunds === "OTHER" && (
                        <Row gutter={[16, 0]}>
                            <Col span={12} offset={12}>
                                <FormItem name="sourceOfFundsOther" label={t("input:corporate.source_of_funds_other")} required rules={rules.required}>
                                    <Input />
                                </FormItem>
                            </Col>
                        </Row>
                    )}
                    <div className="flex items-center mb-20">
                        <h2 className="ml-10">{t("label:corporate.business_address")}</h2>
                    </div>
                    <Row gutter={[16, 0]}>
                        <Col span={12}>
                            <FormItem name="addressLine" label={t("corporate:address.address_line")} rules={rules.required} required>
                                <Input placeholder={t("placeholder:company_settings.address_line")} />
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem name="city" label={t("corporate:address.city")} rules={rules.city} required>
                                <Input placeholder={t("placeholder:company_settings.city")} />
                            </FormItem>
                        </Col>
                    </Row>
                    <Row gutter={[16, 0]}>
                        <Col span={12}>
                            <FormItem name="country" label={t("corporate:address.country")} rules={rules.countryCode} required>
                                <Input placeholder={t("placeholder:company_settings.country")} />
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem name="zip" label={t("corporate:address.zip")} rules={rules.zip} required>
                                <Input placeholder={t("placeholder:company_settings.zip")} />
                            </FormItem>
                        </Col>
                    </Row>

                    <Row gutter={[16, 0]}>
                        <Col span={24}>
                            <FormItem name="weavrTerms" valuePropName="checked" rules={rules.terms} className="mb-4">
                                <Checkbox>
                                    <a href={WEAVR_TERMS_OF_AGREEMENT} target="_blank" rel="noreferrer">
                                        {t("action:cards.weavr_terms_agree")}
                                    </a>
                                </Checkbox>
                            </FormItem>
                            <FormItem name="payneticsTerms" valuePropName="checked" rules={rules.terms}>
                                <Checkbox>
                                    <a href={PAYNETICS_TERMS_OF_AGREEMENT} target="_blank" rel="noreferrer">
                                        {t("action:cards.paynetics_terms_agree")}{" "}
                                    </a>
                                </Checkbox>
                            </FormItem>
                        </Col>
                    </Row>
                </Form>
            </div>
        </div>
    )
}

export default KYBCompanyDetails
