import React from "react"
import { useTranslation } from "react-i18next"

import ExpenseTag from "Components/expenseTag"
import ExpenseTagViewer from "Components/popovers/expenseTagViewer.popover"
import { useExpenseTagsByIds } from "Shared/hooks/expenseTags.hooks"

interface ExpenseTagColumnInterface {
    expenseTagIds: Array<string>
}

const ExpenseTagColumn: React.FC<ExpenseTagColumnInterface> = ({ expenseTagIds }) => {
    const expenseTags = useExpenseTagsByIds(expenseTagIds)
    const { t } = useTranslation()

    if (!expenseTags || expenseTags.length == 0) {
        return <> </>
    }

    const { tagColor } = expenseTags[0]

    if (expenseTags.length > 1) {
        return (
            <ExpenseTagViewer expenseTags={expenseTags}>
                <ExpenseTag color={tagColor}> {`(${expenseTags.length}) ${t("label:tags")}`} </ExpenseTag>
            </ExpenseTagViewer>
        )
    }

    return (
        <div className="truncate">
            <ExpenseTag color={tagColor}>{expenseTags[0].tagName} </ExpenseTag>
        </div>
    )
}

export default ExpenseTagColumn
