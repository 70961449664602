import { GREEN_DARK, GREEN_LIGHT, HIGHLIGHT_RED, INDIGO_BLUE, LIGHT_GRAY } from "@finway-group/shared/lib/consts"
import { LiquidityCashFlow, PriceIntervalEnum } from "@finway-group/shared/lib/models"
import { roundNumberTo2Decimals } from "@finway-group/shared/lib/utils"
import { Alert, Card } from "antd"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts"

import Loading from "Components/loading"
import PriceLabel from "Components/priceLabel"
import { useLoggedInEmployeeProfile } from "Shared/hooks/user.hooks"
import { formatCurrencyNumber } from "Shared/utils/helper.utils"

interface CashFlowGaugeChartInterface {
    cashFlowData: Array<LiquidityCashFlow>
    isDiagramLoading: boolean
}

const CashFlowGaugeChart: React.FC<CashFlowGaugeChartInterface> = ({ cashFlowData, isDiagramLoading }) => {
    if (isDiagramLoading) return <Loading />

    const { t } = useTranslation()
    const RADIAN = Math.PI / 180
    const width = 900

    const currentEmployee = useLoggedInEmployeeProfile()
    const { globalCurrency } = currentEmployee.settings

    /**
     * We use a pie chart to build the gauge chart.
     */
    const chartData = useMemo(() => {
        if (!cashFlowData)
            return {
                limit: 0,
                actual: 0,
                data: [],
            }

        const actualTotal = Math.abs(cashFlowData.reduce((a, c) => c.totalSpending.actual + a, 0))
        const forecastTotal = Math.abs(cashFlowData.reduce((a, c) => c.totalSpending.forecast + a, 0))
        return {
            limit: forecastTotal,
            actual: actualTotal,
            data:
                forecastTotal > actualTotal
                    ? [
                          { name: t("label:liquidity.actual_spending"), value: actualTotal, color: GREEN_DARK },
                          { name: t("label:liquidity.forecast_spending"), value: forecastTotal - actualTotal, color: GREEN_LIGHT },
                      ]
                    : [
                          { name: t("label:liquidity.forecast_spending"), value: forecastTotal, color: GREEN_DARK },
                          { name: t("label:liquidity.actual_spending"), value: actualTotal - forecastTotal, color: !forecastTotal ? LIGHT_GRAY : "#FFBACB" },
                      ],
        }
    }, [cashFlowData])

    const pieRadius = {
        innerRadius: (width / 2) * 0.3,
        outerRadius: (width / 2) * 0.4,
    }

    const pieProps = {
        startAngle: 180,
        endAngle: 0,
        cy: 250,
    }

    const Arrow = ({ cx, cy, startAngle, outerRadius }: any) => {
        // eslint-disable-line react/no-multi-comp
        const sin = Math.sin(-RADIAN * startAngle)
        const cos = Math.cos(-RADIAN * startAngle)
        const mx = cx + (outerRadius + width * 0.03) * cos
        const my = cy + (outerRadius + width * 0.03) * sin
        return (
            <g>
                <path d={`M${cx},${cy}L${mx},${my}`} strokeWidth="6" stroke={INDIGO_BLUE} fill="none" strokeLinecap="butt" />
            </g>
        )
    }

    /**
     * There are two indices 0 or 1.
     * if forecastTotal > actualTotal then Actual spending will have index 0 and Forecast spending index 1, otherwise the reverse.
     */
    const renderCustomizedLabel = ({ cx, cy, startAngle, endAngle, innerRadius, outerRadius, percent, index }: any) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5
        const x = cx + radius * Math.cos(-(index ? endAngle : startAngle) * RADIAN)
        const y = cy + 20 + radius * Math.sin(-(index ? endAngle : startAngle) * RADIAN)

        return (
            <text x={x} y={y} fill={!index || chartData.limit > chartData.actual || !chartData.limit ? "#606C7E" : HIGHLIGHT_RED} textAnchor={"middle"} dominantBaseline="central">
                <PriceLabel currency={globalCurrency} value={index ? Math.max(chartData.actual, chartData.limit) : 0} interval={PriceIntervalEnum.ONE_TIME} />
            </text>
        )
    }

    const renderArrowCustomizedLabel = ({ cx, cy, innerRadius, outerRadius, percent, index }: any) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5
        const x = cx + radius * Math.cos(90 * RADIAN)
        const y = cy - 20 + radius * Math.sin(90 * RADIAN)

        const percentage = roundNumberTo2Decimals((chartData.actual / chartData.limit) * 100)
        return (
            index && (
                <text x={x} y={y} fontWeight={"bold"} fontSize={16} fill={INDIGO_BLUE} textAnchor={"middle"} dominantBaseline="central">
                    {!chartData.limit ? "n/a" : `${percentage}%`}
                </text>
            )
        )
    }

    return (
        <Card>
            <div className="h-400">
                {!chartData.limit && <Alert className="mb-10" showIcon message={t("info:liquidity_management.no_forecast")}></Alert>}

                <h2>
                    {t("label:liquidity.actual_spending")}: <PriceLabel value={chartData.actual} currency={globalCurrency} interval={PriceIntervalEnum.ONE_TIME} />
                </h2>
                <p>
                    {t("label:liquidity.vs_plan")}: <PriceLabel value={chartData.limit} currency={globalCurrency} interval={PriceIntervalEnum.ONE_TIME} />
                </p>
                <ResponsiveContainer width={"100%"} minHeight={400} height={400} className="-mt-30">
                    <PieChart width={width} height={width / 2}>
                        <Pie
                            data={chartData.data}
                            fill="#8884d8"
                            dataKey="value"
                            labelLine={false}
                            nameKey="name"
                            isAnimationActive={false}
                            label={renderCustomizedLabel}
                            {...pieRadius}
                            {...pieProps}
                        >
                            {chartData.data.map((_, index) => (
                                <Cell key={`cell-${index}`} fill={chartData.data[index].color} />
                            ))}
                        </Pie>
                        <Tooltip
                            isAnimationActive={false}
                            content={(props) => (
                                <div className="p-5 bg-white shadow">
                                    <label className="font-bold" style={{ color: `${props.payload?.[0]?.payload.fill}` }}>
                                        {props.payload?.[0]?.name} : {formatCurrencyNumber(props.payload?.[0]?.value as number, globalCurrency)}
                                    </label>
                                </div>
                            )}
                        />
                        <Pie
                            stroke="none"
                            activeIndex={1}
                            activeShape={Arrow}
                            data={chartData.data}
                            outerRadius={pieRadius.innerRadius - 30}
                            fill="none"
                            dataKey="value"
                            isAnimationActive={false}
                            label={renderArrowCustomizedLabel}
                            {...pieProps}
                        />
                    </PieChart>
                </ResponsiveContainer>
            </div>
        </Card>
    )
}

export default CashFlowGaugeChart
