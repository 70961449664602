import { EnrichedPaymentSettings, PaymentInvoice } from "@finway-group/shared/lib/models"
import { Reducer } from "redux"

import { PaymentProductUI } from "Shared/services/paymentSettings.service"

import { FetchPaymentProductsAction, FetchPaymentSettingsAction, PaymentActionTypes, PaymentActions } from "../actions/payment/paymentTypes"

export interface PaymentState {
    settings: {
        data?: EnrichedPaymentSettings
        isFetching: boolean
    }
    products: {
        plans: Array<PaymentProductUI>
        cards: Array<PaymentProductUI>
        isFetching: boolean
    }
    invoices?: {
        data: Array<PaymentInvoice>
        isFetching: boolean
    }
}

const initialState = {
    settings: {
        data: undefined,
        isFetching: true,
    },
    products: {
        plans: [],
        cards: [],
        isFetching: true,
    },
}

const fetchPaymentSettings = (state: PaymentState, action: FetchPaymentSettingsAction): PaymentState => {
    // If fetching, don't overwrite the settings data prop to prevent flickering and re-renders on subsequent fetches
    if (action.settings.isFetching) {
        const update = {
            ...state,
        }
        update.settings.isFetching = true
        return update
    }
    return {
        ...state,
        settings: action.settings ?? undefined,
    }
}

const fetchPaymentProducts = (state: PaymentState, action: FetchPaymentProductsAction): PaymentState => ({
    ...state,
    products: action.products,
})

export const paymentReducer: Reducer<PaymentState, PaymentActions> = (state = initialState, action) => {
    switch (action.type) {
        case PaymentActionTypes.FETCH_PAYMENT_SETTINGS:
            return fetchPaymentSettings(state, action)
        case PaymentActionTypes.FETCH_PAYMENT_PRODUCTS:
            return fetchPaymentProducts(state, action)
        default:
            return state
    }
}
