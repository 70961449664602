import { CsvTemplate } from "@finway-group/shared/lib/models"
import { Action } from "redux"

export enum CsvTemplateActionTypes {
    FETCH_ALL_TEMPLATES = "FETCH_ALL_TEMPLATES",
    CREATE_ONE_TEMPLATE = "CREATE_ONE_TEMPLATE",
    UPDATE_ONE_TEMPLATE = "UPDATE_ONE_TEMPLATE",
    DELETE_ONE_TEMPLATE = "DELTE_ONE_TEMPLATE",
}

export interface FetchAllCsvTemplatesAction extends Action<typeof CsvTemplateActionTypes.FETCH_ALL_TEMPLATES> {
    type: typeof CsvTemplateActionTypes.FETCH_ALL_TEMPLATES
    templates: Array<CsvTemplate>
}

export interface CreateNewCsvTemplatesAction extends Action<typeof CsvTemplateActionTypes.CREATE_ONE_TEMPLATE> {
    type: typeof CsvTemplateActionTypes.CREATE_ONE_TEMPLATE
    template: CsvTemplate
}
export interface UpdateCsvTemplatesAction extends Action<typeof CsvTemplateActionTypes.UPDATE_ONE_TEMPLATE> {
    type: typeof CsvTemplateActionTypes.UPDATE_ONE_TEMPLATE
    template: CsvTemplate
}

export interface DeleteCsvTemplateAction extends Action<typeof CsvTemplateActionTypes.DELETE_ONE_TEMPLATE> {
    type: typeof CsvTemplateActionTypes.DELETE_ONE_TEMPLATE
    id: string
}

export type CsvTemplateActions = FetchAllCsvTemplatesAction | CreateNewCsvTemplatesAction | UpdateCsvTemplatesAction | DeleteCsvTemplateAction
