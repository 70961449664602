import { COLOR } from "@finway-group/shared/lib/consts"
import { CurrencyEnum, PriceIntervalEnum } from "@finway-group/shared/lib/models"
import { roundNumberTo2Decimals , roundNumberTo2Decimals } from "@finway-group/shared/lib/utils"
import React from "react"
import { useTranslation } from "react-i18next"

import PriceLabel from "Components/priceLabel"

import { ConditionalWrapper } from "./conditionalWrapper"

interface ProgressBarInterface {
    total: number
    value: number
    currency: CurrencyEnum
    forecastValue?: number
    extended?: boolean
    hideLabels?: boolean
    thin?: boolean
    maxValue?: number
    dontShowAsExceeding?: boolean
    renderTooltip?: (children: any) => any
    includeValuesBelowBar?: boolean // will show the current value right below where the value so far has reached. Only in extended mode.
    showRemaining?: string
    barClassName?: string
}

export const ProgressBar: React.FC<ProgressBarInterface> = ({
    total,
    value,
    currency,
    forecastValue = 0,
    extended,
    hideLabels,
    thin,
    maxValue,
    dontShowAsExceeding,
    renderTooltip = undefined,
    includeValuesBelowBar,
    showRemaining = COLOR.gray[180],
    barClassName = "w-full",
}) => {
    const { t } = useTranslation()
    value = value < 0 ? 0 : roundNumberTo2Decimals(value)
    total = roundNumberTo2Decimals(total)
    let percentage = (value / total) * 100
    const percentageFromMaxValue = maxValue ? (value / roundNumberTo2Decimals(maxValue)) * 100 : 0
    let forecastValuePercentage = 0
    const reachedBgColor = percentage <= 100 || dontShowAsExceeding ? "reached-color loading" : "reachedBgColor exceeds"

    const height = thin ? "h-8" : "h-22"

    if (percentage > 100) {
        percentage = 100
    }

    const getForecastProgress = () => {
        if (forecastValue > 0 && percentage < 100) {
            forecastValuePercentage = (forecastValue / total) * 100
            return (
                <div
                    className={`${height} progress-predicted ${forecastValuePercentage <= 100 ? "forecast-bg-color" : "forecast-bg-color-reached"}`}
                    style={{ width: `${forecastValuePercentage}%` }}
                ></div>
            )
        }
        return <></>
    }

    return (
        <div className={`${barClassName} block flex items-center`}>
            {!extended ? (
                <>
                    <div className={`${height} base-color mr-8 border-l-2 finway-border-green rounded-xs`} style={{ width: `${percentageFromMaxValue}%` }} />
                    <div className="min-w-100">
                        <PriceLabel value={value} currency={currency} interval={PriceIntervalEnum.ONE_TIME} />
                    </div>
                </>
            ) : (
                <>
                    <div className="wrapper mr-8">
                        <ConditionalWrapper wrapper={(children: any) => renderTooltip?.(children)} condition={renderTooltip !== undefined}>
                            <div className="progress" style={{ backgroundColor: showRemaining }}>
                                <div className={`${height} progress-reached ${reachedBgColor}`} style={{ width: `${percentage}%` }} />
                                {getForecastProgress()}
                            </div>
                        </ConditionalWrapper>
                        {includeValuesBelowBar && (
                            <div className="relative w-full">
                                <p className="absolute opacity-50">{value}</p>
                                {percentage > 10 &&
                                    percentage < 90 && ( // to avoid start/end numbers visually colliding with current value
                                        <p className="absolute transform -translate-x-10" style={{ marginLeft: `${percentage}%` }}>
                                            {value}
                                        </p>
                                    )}
                                <p className="absolute opacity-50 right-0">{total}</p>
                            </div>
                        )}

                        {forecastValue > 0 && (
                            <div className="forecast-budget">
                                + <PriceLabel value={forecastValue} currency={currency} interval={PriceIntervalEnum.ONE_TIME} /> {t("label:forecast")}
                            </div>
                        )}
                    </div>

                    {!hideLabels && (
                        <div className="min-w-160">
                            <span>
                                <PriceLabel value={value} currency={currency} interval={PriceIntervalEnum.ONE_TIME} />
                            </span>
                            <span className="ml-10 mr-10">/</span>
                            <span className="text-xs">
                                <PriceLabel value={total} currency={currency} interval={PriceIntervalEnum.ONE_TIME} />
                            </span>
                        </div>
                    )}
                </>
            )}
        </div>
    )
}
export default ProgressBar
