import Icon from "@ant-design/icons"
import React from "react"

const requestPinSvg = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8.00008 1.06641C7.26493 1.06641 6.66675 1.66432 6.66675 2.39974C6.66675 3.13516 7.26493 3.73307 8.00008 3.73307C8.73524 3.73307 9.33341 3.13516 9.33341 2.39974C9.33341 1.66432 8.73524 1.06641 8.00008 1.06641ZM8.00008 3.19974C7.55894 3.19974 7.20008 2.84089 7.20008 2.39974C7.20008 1.95859 7.55894 1.59974 8.00008 1.59974C8.44123 1.59974 8.80008 1.95859 8.80008 2.39974C8.80008 2.84089 8.44123 3.19974 8.00008 3.19974Z"
            fill="#606C7E"
        />
        <path
            d="M12.0001 3.73307C12.7352 3.73307 13.3334 3.13516 13.3334 2.39974C13.3334 1.66432 12.7352 1.06641 12.0001 1.06641C11.2649 1.06641 10.6667 1.66432 10.6667 2.39974C10.6667 3.13516 11.2649 3.73307 12.0001 3.73307ZM12.0001 1.59974C12.4412 1.59974 12.8001 1.95859 12.8001 2.39974C12.8001 2.84089 12.4412 3.19974 12.0001 3.19974C11.5589 3.19974 11.2001 2.84089 11.2001 2.39974C11.2001 1.95859 11.5589 1.59974 12.0001 1.59974Z"
            fill="#606C7E"
        />
        <path
            d="M4.00008 1.06641C3.26493 1.06641 2.66675 1.66432 2.66675 2.39974C2.66675 3.13516 3.26493 3.73307 4.00008 3.73307C4.73524 3.73307 5.33341 3.13516 5.33341 2.39974C5.33341 1.66432 4.73524 1.06641 4.00008 1.06641ZM4.00008 3.19974C3.55894 3.19974 3.20008 2.84089 3.20008 2.39974C3.20008 1.95859 3.55894 1.59974 4.00008 1.59974C4.44123 1.59974 4.80008 1.95859 4.80008 2.39974C4.80008 2.84089 4.44123 3.19974 4.00008 3.19974Z"
            fill="#606C7E"
        />
        <path
            d="M8.00008 4.79974C7.26493 4.79974 6.66675 5.39766 6.66675 6.13307C6.66675 6.86849 7.26493 7.46641 8.00008 7.46641C8.73524 7.46641 9.33341 6.86849 9.33341 6.13307C9.33341 5.39766 8.73524 4.79974 8.00008 4.79974ZM8.00008 6.93307C7.55894 6.93307 7.20008 6.57422 7.20008 6.13307C7.20008 5.69193 7.55894 5.33307 8.00008 5.33307C8.44123 5.33307 8.80008 5.69193 8.80008 6.13307C8.80008 6.57422 8.44123 6.93307 8.00008 6.93307Z"
            fill="#606C7E"
        />
        <path
            d="M12.0001 4.79974C11.2649 4.79974 10.6667 5.39766 10.6667 6.13307C10.6667 6.86849 11.2649 7.46641 12.0001 7.46641C12.7352 7.46641 13.3334 6.86849 13.3334 6.13307C13.3334 5.39766 12.7352 4.79974 12.0001 4.79974ZM12.0001 6.93307C11.5589 6.93307 11.2001 6.57422 11.2001 6.13307C11.2001 5.69193 11.5589 5.33307 12.0001 5.33307C12.4412 5.33307 12.8001 5.69193 12.8001 6.13307C12.8001 6.57422 12.4412 6.93307 12.0001 6.93307Z"
            fill="#606C7E"
        />
        <path
            d="M4.00008 4.79974C3.26493 4.79974 2.66675 5.39766 2.66675 6.13307C2.66675 6.86849 3.26493 7.46641 4.00008 7.46641C4.73524 7.46641 5.33341 6.86849 5.33341 6.13307C5.33341 5.39766 4.73524 4.79974 4.00008 4.79974ZM4.00008 6.93307C3.55894 6.93307 3.20008 6.57422 3.20008 6.13307C3.20008 5.69193 3.55894 5.33307 4.00008 5.33307C4.44123 5.33307 4.80008 5.69193 4.80008 6.13307C4.80008 6.57422 4.44123 6.93307 4.00008 6.93307Z"
            fill="#606C7E"
        />
        <path
            d="M8.00008 8.53307C7.26493 8.53307 6.66675 9.13099 6.66675 9.86641C6.66675 10.6018 7.26493 11.1997 8.00008 11.1997C8.73524 11.1997 9.33341 10.6018 9.33341 9.86641C9.33341 9.13099 8.73524 8.53307 8.00008 8.53307ZM8.00008 10.6664C7.55894 10.6664 7.20008 10.3076 7.20008 9.86641C7.20008 9.42526 7.55894 9.06641 8.00008 9.06641C8.44123 9.06641 8.80008 9.42526 8.80008 9.86641C8.80008 10.3076 8.44123 10.6664 8.00008 10.6664Z"
            fill="#606C7E"
        />
        <path
            d="M12.0001 8.53307C11.2649 8.53307 10.6667 9.13099 10.6667 9.86641C10.6667 10.6018 11.2649 11.1997 12.0001 11.1997C12.7352 11.1997 13.3334 10.6018 13.3334 9.86641C13.3334 9.13099 12.7352 8.53307 12.0001 8.53307ZM12.0001 10.6664C11.5589 10.6664 11.2001 10.3076 11.2001 9.86641C11.2001 9.42526 11.5589 9.06641 12.0001 9.06641C12.4412 9.06641 12.8001 9.42526 12.8001 9.86641C12.8001 10.3076 12.4412 10.6664 12.0001 10.6664Z"
            fill="#606C7E"
        />
        <path
            d="M4.00008 8.53307C3.26493 8.53307 2.66675 9.13099 2.66675 9.86641C2.66675 10.6018 3.26493 11.1997 4.00008 11.1997C4.73524 11.1997 5.33341 10.6018 5.33341 9.86641C5.33341 9.13099 4.73524 8.53307 4.00008 8.53307ZM4.00008 10.6664C3.55894 10.6664 3.20008 10.3076 3.20008 9.86641C3.20008 9.42526 3.55894 9.06641 4.00008 9.06641C4.44123 9.06641 4.80008 9.42526 4.80008 9.86641C4.80008 10.3076 4.44123 10.6664 4.00008 10.6664Z"
            fill="#606C7E"
        />
        <path
            d="M8.00008 12.2664C7.26493 12.2664 6.66675 12.8643 6.66675 13.5997C6.66675 14.3352 7.26493 14.9331 8.00008 14.9331C8.73524 14.9331 9.33341 14.3352 9.33341 13.5997C9.33341 12.8643 8.73524 12.2664 8.00008 12.2664ZM8.00008 14.3997C7.55894 14.3997 7.20008 14.0409 7.20008 13.5997C7.20008 13.1586 7.55894 12.7997 8.00008 12.7997C8.44123 12.7997 8.80008 13.1586 8.80008 13.5997C8.80008 14.0409 8.44123 14.3997 8.00008 14.3997Z"
            fill="#606C7E"
        />
        <path
            d="M8.00008 1.06641C7.26493 1.06641 6.66675 1.66432 6.66675 2.39974C6.66675 3.13516 7.26493 3.73307 8.00008 3.73307C8.73524 3.73307 9.33341 3.13516 9.33341 2.39974C9.33341 1.66432 8.73524 1.06641 8.00008 1.06641ZM8.00008 3.19974C7.55894 3.19974 7.20008 2.84089 7.20008 2.39974C7.20008 1.95859 7.55894 1.59974 8.00008 1.59974C8.44123 1.59974 8.80008 1.95859 8.80008 2.39974C8.80008 2.84089 8.44123 3.19974 8.00008 3.19974Z"
            stroke="#606C7E"
            strokeWidth="0.2"
        />
        <path
            d="M12.0001 3.73307C12.7352 3.73307 13.3334 3.13516 13.3334 2.39974C13.3334 1.66432 12.7352 1.06641 12.0001 1.06641C11.2649 1.06641 10.6667 1.66432 10.6667 2.39974C10.6667 3.13516 11.2649 3.73307 12.0001 3.73307ZM12.0001 1.59974C12.4412 1.59974 12.8001 1.95859 12.8001 2.39974C12.8001 2.84089 12.4412 3.19974 12.0001 3.19974C11.5589 3.19974 11.2001 2.84089 11.2001 2.39974C11.2001 1.95859 11.5589 1.59974 12.0001 1.59974Z"
            stroke="#606C7E"
            strokeWidth="0.2"
        />
        <path
            d="M4.00008 1.06641C3.26493 1.06641 2.66675 1.66432 2.66675 2.39974C2.66675 3.13516 3.26493 3.73307 4.00008 3.73307C4.73524 3.73307 5.33341 3.13516 5.33341 2.39974C5.33341 1.66432 4.73524 1.06641 4.00008 1.06641ZM4.00008 3.19974C3.55894 3.19974 3.20008 2.84089 3.20008 2.39974C3.20008 1.95859 3.55894 1.59974 4.00008 1.59974C4.44123 1.59974 4.80008 1.95859 4.80008 2.39974C4.80008 2.84089 4.44123 3.19974 4.00008 3.19974Z"
            stroke="#606C7E"
            strokeWidth="0.2"
        />
        <path
            d="M8.00008 4.79974C7.26493 4.79974 6.66675 5.39766 6.66675 6.13307C6.66675 6.86849 7.26493 7.46641 8.00008 7.46641C8.73524 7.46641 9.33341 6.86849 9.33341 6.13307C9.33341 5.39766 8.73524 4.79974 8.00008 4.79974ZM8.00008 6.93307C7.55894 6.93307 7.20008 6.57422 7.20008 6.13307C7.20008 5.69193 7.55894 5.33307 8.00008 5.33307C8.44123 5.33307 8.80008 5.69193 8.80008 6.13307C8.80008 6.57422 8.44123 6.93307 8.00008 6.93307Z"
            stroke="#606C7E"
            strokeWidth="0.2"
        />
        <path
            d="M12.0001 4.79974C11.2649 4.79974 10.6667 5.39766 10.6667 6.13307C10.6667 6.86849 11.2649 7.46641 12.0001 7.46641C12.7352 7.46641 13.3334 6.86849 13.3334 6.13307C13.3334 5.39766 12.7352 4.79974 12.0001 4.79974ZM12.0001 6.93307C11.5589 6.93307 11.2001 6.57422 11.2001 6.13307C11.2001 5.69193 11.5589 5.33307 12.0001 5.33307C12.4412 5.33307 12.8001 5.69193 12.8001 6.13307C12.8001 6.57422 12.4412 6.93307 12.0001 6.93307Z"
            stroke="#606C7E"
            strokeWidth="0.2"
        />
        <path
            d="M4.00008 4.79974C3.26493 4.79974 2.66675 5.39766 2.66675 6.13307C2.66675 6.86849 3.26493 7.46641 4.00008 7.46641C4.73524 7.46641 5.33341 6.86849 5.33341 6.13307C5.33341 5.39766 4.73524 4.79974 4.00008 4.79974ZM4.00008 6.93307C3.55894 6.93307 3.20008 6.57422 3.20008 6.13307C3.20008 5.69193 3.55894 5.33307 4.00008 5.33307C4.44123 5.33307 4.80008 5.69193 4.80008 6.13307C4.80008 6.57422 4.44123 6.93307 4.00008 6.93307Z"
            stroke="#606C7E"
            strokeWidth="0.2"
        />
        <path
            d="M8.00008 8.53307C7.26493 8.53307 6.66675 9.13099 6.66675 9.86641C6.66675 10.6018 7.26493 11.1997 8.00008 11.1997C8.73524 11.1997 9.33341 10.6018 9.33341 9.86641C9.33341 9.13099 8.73524 8.53307 8.00008 8.53307ZM8.00008 10.6664C7.55894 10.6664 7.20008 10.3076 7.20008 9.86641C7.20008 9.42526 7.55894 9.06641 8.00008 9.06641C8.44123 9.06641 8.80008 9.42526 8.80008 9.86641C8.80008 10.3076 8.44123 10.6664 8.00008 10.6664Z"
            stroke="#606C7E"
            strokeWidth="0.2"
        />
        <path
            d="M12.0001 8.53307C11.2649 8.53307 10.6667 9.13099 10.6667 9.86641C10.6667 10.6018 11.2649 11.1997 12.0001 11.1997C12.7352 11.1997 13.3334 10.6018 13.3334 9.86641C13.3334 9.13099 12.7352 8.53307 12.0001 8.53307ZM12.0001 10.6664C11.5589 10.6664 11.2001 10.3076 11.2001 9.86641C11.2001 9.42526 11.5589 9.06641 12.0001 9.06641C12.4412 9.06641 12.8001 9.42526 12.8001 9.86641C12.8001 10.3076 12.4412 10.6664 12.0001 10.6664Z"
            stroke="#606C7E"
            strokeWidth="0.2"
        />
        <path
            d="M4.00008 8.53307C3.26493 8.53307 2.66675 9.13099 2.66675 9.86641C2.66675 10.6018 3.26493 11.1997 4.00008 11.1997C4.73524 11.1997 5.33341 10.6018 5.33341 9.86641C5.33341 9.13099 4.73524 8.53307 4.00008 8.53307ZM4.00008 10.6664C3.55894 10.6664 3.20008 10.3076 3.20008 9.86641C3.20008 9.42526 3.55894 9.06641 4.00008 9.06641C4.44123 9.06641 4.80008 9.42526 4.80008 9.86641C4.80008 10.3076 4.44123 10.6664 4.00008 10.6664Z"
            stroke="#606C7E"
            strokeWidth="0.2"
        />
        <path
            d="M8.00008 12.2664C7.26493 12.2664 6.66675 12.8643 6.66675 13.5997C6.66675 14.3352 7.26493 14.9331 8.00008 14.9331C8.73524 14.9331 9.33341 14.3352 9.33341 13.5997C9.33341 12.8643 8.73524 12.2664 8.00008 12.2664ZM8.00008 14.3997C7.55894 14.3997 7.20008 14.0409 7.20008 13.5997C7.20008 13.1586 7.55894 12.7997 8.00008 12.7997C8.44123 12.7997 8.80008 13.1586 8.80008 13.5997C8.80008 14.0409 8.44123 14.3997 8.00008 14.3997Z"
            stroke="#606C7E"
            strokeWidth="0.2"
        />
    </svg>
)

const RequestPinIcon = (props: any) => <Icon component={requestPinSvg} {...props} />

export default RequestPinIcon
