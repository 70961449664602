import { DocumentChangeInterface, OperationTypeEnum } from "@finway-group/shared/lib/models"
import * as Sentry from "@sentry/react"

import i18n from "Shared/locales/i18n"
import { isClientErrorCode } from "Shared/utils/errorHandler.utils"

import LocalStorageService from "../localstorage.service"
import NotificationService, { NotificationTypeEnum } from "../notification.service"

const subscribeToTenant = (data: DocumentChangeInterface) => {
    const {
        fullDocument: { operationType },
    } = data

    try {
        if (operationType === OperationTypeEnum.RESET_DEMO_DATA) {
            LocalStorageService.unsetAccessToken()
            NotificationService.send(
                NotificationTypeEnum.INFO,
                i18n.t(`notification:data_demo_is_being_created.title`),
                i18n.t(`notification:data_demo_is_being_created.message`),
                0,
            )
        }
    } catch (err) {
        if (isClientErrorCode(err)) return
        Sentry.captureException(`[Tenant Socket] Error: ${typeof err === "string" ? err : JSON.stringify(err)}`)
    }
}

export default subscribeToTenant
