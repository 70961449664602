import { LiquidityForecastRule, LiquidityScenario } from "@finway-group/shared/lib/models"
import moment from "moment"
import { ActionCreator, Dispatch } from "redux"

import { LMTabTypeEnum } from "Features/pages/liquidity/liquidity.types"
import { BankBalanceInfo } from "Features/pages/liquidity/types"
import { BankService, LiquidityService } from "Shared/services"
import { ThunkResult } from "Shared/store"

import { CancelFetchAction, FetcherActionTypes } from "../fetcher/fetcherTypes"
import {
    CreateOneForecastRuleAction,
    CreateOneScenarioAction,
    DeleteOneForecastRuleAction,
    DeleteOneScenarioAction,
    FetchAllForecastRulesAction,
    FetchAllScenariosAction,
    FetchCashFlowsAction,
    FetchLast6MonthsCashFlowsAction,
    FetchYearlyCashFlowsAction,
    LiquidityTypes,
    ResetLiquiditiesAction,
    SelectScenarioAction,
    SetLiquidityActiveTabAction,
    UpdateIntervalAction,
    UpdateOneForecastRuleAction,
    UpdateOneScenarioAction,
} from "./liquidityTypes"

export const fetchAllScenarios: ActionCreator<ThunkResult<Array<LiquidityScenario>>> = () => async (dispatch: Dispatch) => {
    const scenarios = await LiquidityService.getAllScenarios()
    const cancelFetchAction: CancelFetchAction = {
        type: FetcherActionTypes.CANCEL_FETCH,
        isFetchCancelled: true,
    }
    dispatch(cancelFetchAction)

    const fetchAllScenarios: FetchAllScenariosAction = {
        type: LiquidityTypes.FETCH_ALL_SCENARIOS,
        scenarios,
        scenario: scenarios[0],
    }

    dispatch(fetchAllScenarios)

    // select one scenario
    const selectScenario: SelectScenarioAction = {
        type: LiquidityTypes.SELECT_SCENARIO,
        scenario: scenarios[0],
    }
    dispatch(selectScenario)

    return scenarios
}

export const createScenario = (scenario: LiquidityScenario) => async (dispatch: Dispatch) => {
    try {
        const newScenario = await LiquidityService.createScenario(scenario)
        const createScenarioAction: CreateOneScenarioAction = {
            type: LiquidityTypes.CREATE_ONE_SCENARIO,
            scenario: newScenario,
        }

        dispatch(createScenarioAction)
        return scenario
    } catch (err) {
        throw err
    }
}

export const updateScenario = (scenarioId: string, scenario: LiquidityScenario) => async (dispatch: Dispatch) => {
    const updatedScenario = await LiquidityService.updateScenario(scenarioId, scenario)
    const updateScenarioAction: UpdateOneScenarioAction = {
        type: LiquidityTypes.UPDATE_ONE_SCENARIO,
        scenario: updatedScenario,
    }

    dispatch(updateScenarioAction)
    return scenario
}

export const deleteScenario = (scenarioId: string) => async (dispatch: Dispatch) => {
    await LiquidityService.deleteScenario(scenarioId)
    const deleteAction: DeleteOneScenarioAction = {
        type: LiquidityTypes.DELETE_ONE_SCENARIO,
        scenarioId,
    }

    dispatch(deleteAction)
    return scenarioId
}

export const selectScenario = (scenario: LiquidityScenario) => async (dispatch: Dispatch) => {
    const selectAction: SelectScenarioAction = {
        type: LiquidityTypes.SELECT_SCENARIO,
        scenario,
    }

    dispatch(selectAction)
    return scenario
}

export const updateInterval = (interval?: [moment.Moment, moment.Moment]) => async (dispatch: Dispatch) => {
    const updateAction: UpdateIntervalAction = {
        type: LiquidityTypes.UPDATE_INTERVAL,
        interval,
    }

    dispatch(updateAction)
    return interval
}

export const fetchAllForecastRules: ActionCreator<ThunkResult<Array<LiquidityForecastRule>>> = () => async (dispatch: Dispatch) => {
    const forecastRules = await LiquidityService.getAllForecastRules()
    const cancelFetchAction: CancelFetchAction = {
        type: FetcherActionTypes.CANCEL_FETCH,
        isFetchCancelled: true,
    }

    dispatch(cancelFetchAction)

    const fetchAllForecastRules: FetchAllForecastRulesAction = {
        type: LiquidityTypes.FETCH_ALL_FORECAST_RULES,
        forecastRules,
    }

    dispatch(fetchAllForecastRules)
    return forecastRules
}

export const createForecastRule = (forecastRule: LiquidityForecastRule) => async (dispatch: Dispatch) => {
    try {
        const newForecastRule = await LiquidityService.createForecastRule(forecastRule)
        const createForecastRuleAction: CreateOneForecastRuleAction = {
            type: LiquidityTypes.CREATE_ONE_FORECAST_RULE,
            forecastRule: newForecastRule,
        }

        dispatch(createForecastRuleAction)
        return forecastRule
    } catch (err) {
        throw err
    }
}

export const updateForecastRule = (forecastRuleId: string, forecastRule: LiquidityForecastRule) => async (dispatch: Dispatch) => {
    const updatedForecastRule = await LiquidityService.updateForecastRule(forecastRuleId, forecastRule)
    const updateForecastRuleAction: UpdateOneForecastRuleAction = {
        type: LiquidityTypes.UPDATE_ONE_FORECAST_RULE,
        forecastRule: updatedForecastRule,
    }

    dispatch(updateForecastRuleAction)
    return updatedForecastRule
}

export const deleteForecastRule = (forecastRuleId: string) => async (dispatch: Dispatch) => {
    await LiquidityService.deleteForecastRule(forecastRuleId)
    const deleteAction: DeleteOneForecastRuleAction = {
        type: LiquidityTypes.DELETE_ONE_FORECAST_RULE,
        forecastRuleId,
    }

    dispatch(deleteAction)
    return forecastRuleId
}

export const setLiquidityActiveTab: ActionCreator<ThunkResult<void>> = (activeTab: LMTabTypeEnum) => async (dispatch: Dispatch) => {
    const setActiveTabAction: SetLiquidityActiveTabAction = {
        type: LiquidityTypes.SET_LM_ACTIVE_TAB,
        activeTab,
    }

    dispatch(setActiveTabAction)
}

export const fetchCashFlowData = (interval: [moment.Moment, moment.Moment] | undefined, scenarioId: string) => async (dispatch: Dispatch) => {
    const cashFlows = await LiquidityService.getCashFlow({ interval, scenarioId })

    const cancelFetchAction: CancelFetchAction = {
        type: FetcherActionTypes.CANCEL_FETCH,
        isFetchCancelled: true,
    }
    dispatch(cancelFetchAction)

    const fetchCashFlows: FetchCashFlowsAction = {
        type: LiquidityTypes.FETCH_CASH_FLOWS,
        cashFlows,
    }
    dispatch(fetchCashFlows)
    return fetchCashFlows
}

export const fetchYearlyCashflowData = (scenarioId: string) => async (dispatch: Dispatch) => {
    const yearlyCashFlows = await LiquidityService.getCashFlow({
        interval: [moment().startOf("years").subtract(1, "month"), moment().endOf("years")],
        scenarioId,
    })
    const cancelFetchAction: CancelFetchAction = {
        type: FetcherActionTypes.CANCEL_FETCH,
        isFetchCancelled: true,
    }
    dispatch(cancelFetchAction)

    const fetchCashFlows: FetchYearlyCashFlowsAction = {
        type: LiquidityTypes.FETCH_YEARLY_CASH_FLOWS,
        yearlyCashFlows,
    }
    dispatch(fetchCashFlows)
    return fetchCashFlows
}

export const fetchLast6MonthsCashflowData = (scenarioId: string) => async (dispatch: Dispatch) => {
    const last6MonthsCashFlows = await LiquidityService.getCashFlow({
        interval: [moment().subtract(6, "month"), moment()],
        scenarioId,
    })
    const cancelFetchAction: CancelFetchAction = {
        type: FetcherActionTypes.CANCEL_FETCH,
        isFetchCancelled: true,
    }
    dispatch(cancelFetchAction)

    const fetchCashFlows: FetchLast6MonthsCashFlowsAction = {
        type: LiquidityTypes.FETCH_LAST_6_MONTHS_CASH_FLOWS,
        last6MonthsCashFlows,
    }
    dispatch(fetchCashFlows)
    return fetchCashFlows
}

export const resetLiquidities = () => async (dispatch: Dispatch) => {
    const resetLiqudities: ResetLiquiditiesAction = {
        type: LiquidityTypes.RESET_LIQUIDITIES,
    }

    dispatch(resetLiqudities)
}

export const setShouldFetchCashFlowsData = (value: boolean) => async (dispatch: Dispatch) => {
    const setShouldFetchCashFlowsData = {
        type: LiquidityTypes.SET_SHOULD_FETCH_CASH_FLOWS_DATA,
        value,
    }
    dispatch(setShouldFetchCashFlowsData)
}

export const setShouldFetchBankBalanceData = (value: boolean) => (dispatch: Dispatch) => {
    const setLastBankBalanceData = {
        type: LiquidityTypes.SET_SHOULD_FETCH_BANK_BALANCE_DATA,
        value,
    }
    dispatch(setLastBankBalanceData)
}

export const fetchBankBalanceData = (startDate: moment.Moment, endDate: moment.Moment) => async (dispatch: Dispatch) => {
    const bankBalanceData: Array<BankBalanceInfo> = (await BankService.getCashBalanceForPeriod(startDate.startOf("month"), endDate.endOf("month")))?.data ?? []

    const cancelFetchAction: CancelFetchAction = {
        type: FetcherActionTypes.CANCEL_FETCH,
        isFetchCancelled: true,
    }
    dispatch(cancelFetchAction)

    const setLastBankBalanceData = {
        type: LiquidityTypes.SET_BANK_BALANCE_DATA,
        value: bankBalanceData,
    }
    dispatch(setLastBankBalanceData)

    setShouldFetchBankBalanceData(false)(dispatch)

    return bankBalanceData
}
