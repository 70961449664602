import { LoginInput } from "@finway-group/shared/lib/models"
import { UserLanguageEnum } from "@finway-group/shared/lib/models/user/userSettings.interface"
import * as HttpStatus from "http-status"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useHistory, useLocation, withRouter } from "react-router-dom"

import LoginForm from "Components/forms/loginForm"
import axiosInterceptor from "Shared/config/axios.interceptor"
import i18n from "Shared/locales/i18n"
import { LocalStorageService } from "Shared/services"
import NotificationService, { NotificationTypeEnum } from "Shared/services/notification.service"
import { ThunkDispatchResult } from "Shared/store"
import { login } from "Shared/store/actions/auth/authActions"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

import AuthLayout from "./auth.layout"
import AuthRateLimiterContainer from "./components/AuthRateLimiterContainer"
import { SessionConflictModal } from "./components/sessionConflict.modal"
import TfAuthContainer from "./tfAuth.container"

const LoginPage = () => {
    const { t } = useTranslation()

    const history = useHistory()

    const { search } = useLocation()
    const overrideLanguage = new URLSearchParams(search).get("language")
    const allowedLanguages = Object.values(UserLanguageEnum) as Array<string>

    const [loading, setLoading] = useStateIfMounted(false)
    const [isTfAuthVisible, setIsTfAuthVisible] = useStateIfMounted(false)
    const [loginData, setLoginData] = useStateIfMounted<LoginInput>({} as LoginInput)
    const [attemptCount, setAttemptCount] = useStateIfMounted(0)
    const [retryAfter, setRetryAfter] = useStateIfMounted(0)
    const [isLoginError, setIsLoginError] = useStateIfMounted(false)

    const dispatch: ThunkDispatchResult = useDispatch()

    useEffect(() => {
        if (overrideLanguage && allowedLanguages.includes(overrideLanguage)) {
            i18n.changeLanguage(overrideLanguage)
        }
        axiosInterceptor.resetIsFatalErrorMessageDisplayed()
    }, [])

    const onPasswordLogin = async (loginInput: LoginInput) => {
        try {
            setLoading(true)
            await dispatch(login(loginInput))
        } catch ({ response }) {
            setLoading(false)
            setAttemptCount(0)
            setRetryAfter(0)

            if (response) {
                const { status, data, headers } = response
                switch (status) {
                    case HttpStatus.PARTIAL_CONTENT:
                        setLoginData(loginInput)
                        setIsTfAuthVisible(true)
                        break
                    case HttpStatus.UNAUTHORIZED:
                        const attemptCounter = headers["retry-attempt"] || 0
                        setAttemptCount(Number(attemptCounter))

                        if (!headers["retry-attempt"]) {
                            setIsLoginError(true)
                        }

                        break
                    case HttpStatus.TOO_MANY_REQUESTS:
                        setRetryAfter(headers["retry-after"])
                        setAttemptCount(Number(headers["retry-attempt"]))
                        break

                    case HttpStatus.CONFLICT:
                        NotificationService.send(NotificationTypeEnum.ERROR, t("error:login.title"), t("error:login.clear_cookies"))
                        LocalStorageService.unsetAccessToken()
                        break

                    case HttpStatus.BAD_REQUEST:
                        NotificationService.showErrorNotificationBasedOnResponseError(data.error, t("error:login.title"), data.error)
                        break

                    case HttpStatus.FORBIDDEN:
                        if (data.passwordResetToken) {
                            history.push({ pathname: `/reset/${data.passwordResetToken}`, state: { isPasswordAllowed: false, isInitial: true } })
                            break
                        }

                        if (data && data.error?.includes("suspended")) {
                            NotificationService.send(NotificationTypeEnum.ERROR, t("error:login.title"), t("error:login.message_forbidden"))
                            break
                        }

                        NotificationService.showErrorNotificationBasedOnResponseError(data.error, t("error:login.title"), data.error)
                        break
                    case HttpStatus.SERVICE_UNAVAILABLE:
                        NotificationService.showErrorNotificationBasedOnResponseError(data.error, t("error:login.title"), data.error)
                        break
                    default:
                        NotificationService.showErrorNotificationBasedOnResponseError(data.error, t("error:login.title"))
                        break
                }
            }
        }
    }

    const onTokenValidation = async (token: string) => {
        await onPasswordLogin({ ...loginData, token } as LoginInput)
        setIsTfAuthVisible(false)
    }

    if (isTfAuthVisible) {
        return <TfAuthContainer tokenValidation={onTokenValidation} />
    }

    return (
        <AuthLayout>
            <SessionConflictModal />
            <AuthRateLimiterContainer attemptCount={attemptCount} retryAfter={retryAfter} isLoginError={isLoginError} />
            <LoginForm onSubmit={onPasswordLogin} loading={loading} attemptCount={attemptCount} />
        </AuthLayout>
    )
}

export default withRouter(LoginPage)
