import Icon from "@ant-design/icons"
import React from "react"

const circleArrowSvg = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.3335 2.6665V6.6665H10.3335" stroke="#606C7E" strokeLinecap="round" strokeLinejoin="round" />
        <path
            d="M12.6602 10.0002C12.2269 11.2268 11.4066 12.2793 10.323 12.9992C9.23942 13.719 7.95122 14.0672 6.65253 13.9912C5.35383 13.9153 4.11502 13.4192 3.12275 12.578C2.13048 11.7367 1.43853 10.5957 1.15116 9.32688C0.863784 8.0581 0.996564 6.7303 1.52949 5.54355C2.06241 4.35681 2.9666 3.37541 4.10581 2.74726C5.24503 2.11912 6.55753 1.87824 7.84555 2.06093C9.13357 2.24363 10.3273 2.84 11.2469 3.76017L14.3336 6.66684"
            stroke="#606C7E"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)

const CircleArrowIcon = (props: any) => <Icon component={circleArrowSvg} {...props} />

export default CircleArrowIcon
