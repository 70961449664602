import { FormInstance, Rule } from "antd/lib/form"

import { checkMaxNumber, checkMinNumber } from "./utils.rules"

export const generateRules = (formInstance: FormInstance): { [key: string]: Array<Rule> } => ({
    minLimit: [
        {
            validator: (_rule: any, value: number | string) => checkMinNumber({ formInstance, value, name: "maxLimit" }),
        },
    ],
    maxLimit: [
        {
            validator: (_rule: any, value: number | string) => checkMaxNumber({ formInstance, value, name: "minLimit" }),
        },
    ],
})
