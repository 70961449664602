import { CollectionNameEnum, Employee, Expense, ExpenseKindEnum, OperationTypeEnum, TripFolder } from "@finway-group/shared/lib/models"
import { generatePath } from "react-router-dom"

import i18n from "Shared/locales/i18n"
import { RoutePathEnum } from "Shared/router/RoutePath.enum"
import { isFullyProcessedSubscription } from "Shared/utils/expense.utils"
import { formatCurrencyNumber } from "Shared/utils/helper.utils"

export const SystemEmployee = new Employee({
    img: "/images/favicons/192x192.png",
    firstName: "System",
    lastName: "",
})

type mentionCommentType = {
    loggedInEmployee: Employee
    mentionValue: any
    toNotify: Array<string>
    isExpense?: boolean
    isSubscription?: boolean
    multipleSuffix?: string
    combinedLength?: number
    isFolder?: boolean
}

const handleMentionComment = ({ loggedInEmployee, mentionValue, toNotify, isExpense, isSubscription, multipleSuffix = "", combinedLength = 0, isFolder }: mentionCommentType) => {
    if (toNotify && toNotify.length > 0 && toNotify.includes(loggedInEmployee.id)) {
        if (isExpense) return i18n.t(`activity:expense.mention_comment${multipleSuffix}`, { num: mentionValue, combinedLength })
        if (isSubscription) return i18n.t(`activity:subscription.mention_comment${multipleSuffix}`, { name: mentionValue, combinedLength })
        if (isFolder) return i18n.t(`activity:folder.mention_comment${multipleSuffix}`, { name: mentionValue, num: mentionValue, combinedLength })
        return i18n.t(`activity:vendor.mention_comment${multipleSuffix}`, { name: mentionValue, num: mentionValue, combinedLength })
    }

    return null
}

export const formulateExpenseNotification = (
    operationType: OperationTypeEnum,
    expense: Expense,
    loggedInEmployee: Employee,
    toNotify: any,
    combinedInfo: CombinedInfo,
    metaData: any,
): string => {
    const { showGrossAmount } = loggedInEmployee.settings
    const expensePrice = showGrossAmount ? expense.totalGrossPrice : expense.totalNetPrice
    const multipleSuffix = combinedInfo.isCombined ? "_multiple" : ""
    const combinedLength = (combinedInfo.combinedDependencies?.length ?? 0) + 1

    switch (operationType) {
        case OperationTypeEnum.INSERT:
            return i18n.t(`activity:expense.create${multipleSuffix}`, { num: expense.expenseNumber, combinedLength })
        case OperationTypeEnum.DOCUMENT_NEEDED_EXPENSE:
            return i18n.t("activity:expense.document_needed", { num: expense.expenseNumber, priceLabel: formatCurrencyNumber(expensePrice, expense.currency) })
        case OperationTypeEnum.DOCUMENT_EXPENSE:
            return i18n.t("activity:expense.document", { num: expense.expenseNumber })
        case OperationTypeEnum.APPROVE_EXPENSE:
            return [ExpenseKindEnum.HOSPITALITY, ExpenseKindEnum.MILEAGE, ExpenseKindEnum.PER_DIEM].includes(expense.kind)
                ? i18n.t("activity:expense.reimbursement_approved", { num: expense.expenseNumber, priceLabel: formatCurrencyNumber(expensePrice, expense.currency) })
                : i18n.t("activity:expense.approved", { num: expense.expenseNumber, priceLabel: formatCurrencyNumber(expensePrice, expense.currency) })
        case OperationTypeEnum.DECLINED_EXPENSE:
            return i18n.t("activity:expense.declined", { num: expense.expenseNumber })
        case OperationTypeEnum.REVIEWED_EXPENSE:
            return i18n.t("activity:expense.reviewed", { num: expense.expenseNumber })
        case OperationTypeEnum.PAID_EXPENSE:
            return i18n.t("activity:expense.paid", { num: expense.expenseNumber, priceLabel: formatCurrencyNumber(expensePrice, expense.currency) })
        case OperationTypeEnum.INSERT_COMMENT:
            return (
                handleMentionComment({ loggedInEmployee, mentionValue: expense.expenseNumber, toNotify, isExpense: true, multipleSuffix, combinedLength }) ||
                i18n.t(`activity:expense.insert_comment${multipleSuffix}`, { num: expense.expenseNumber, combinedLength })
            )
        case OperationTypeEnum.UPDATE_COMMENT:
            return (
                handleMentionComment({ loggedInEmployee, mentionValue: expense.expenseNumber, toNotify, isExpense: true }) ||
                i18n.t(`activity:expense.updated_comment${multipleSuffix}`, { num: expense.expenseNumber, combinedLength })
            )
        case OperationTypeEnum.DELETE_COMMENT:
            return i18n.t("activity:expense.delete_comment", { num: expense.expenseNumber })
        case OperationTypeEnum.DELETE:
            return i18n.t("activity:expense.delete", { num: expense.expenseNumber })
        case OperationTypeEnum.RESTORE:
            return i18n.t("activity:expense.restore", { num: expense.expenseNumber })
        case OperationTypeEnum.UPLOAD_INVOICE_REMINDER:
            return i18n.t("activity:expense.invoice_upload_reminder", { num: expense.expenseNumber })
        case OperationTypeEnum.APPROVE_REMINDER:
            return i18n.t("activity:expense.approve_reminder", { num: expense.expenseNumber })
        case OperationTypeEnum.INVOICE_AUTO_MATCH:
            return i18n.t("activity:expense.auto_match_invoice", { num: expense.expenseNumber, fileName: metaData?.fileName })
        case OperationTypeEnum.SEND_EXPENSE_REMINDER_NOTIFICATION:
            return i18n.t("activity:expense.follow_up_today", { num: expense.expenseNumber })
        case OperationTypeEnum.ASYNC_EXPORT:
            return i18n.t("activity:expense.async_export")
        default:
            return i18n.t(`activity:expense.update${multipleSuffix}`, { num: expense.expenseNumber })
    }
}

export const formulateSubscriptionNotification = (
    operationType: OperationTypeEnum,
    subscription: Expense,
    loggedInEmployee: Employee,
    toNotify: any,
    combinedInfo: CombinedInfo,
    metaData: any,
): string => {
    const multiple_suffix = combinedInfo.isCombined ? "_multiple" : ""
    const combinedLength = (combinedInfo.combinedDependencies?.length ?? 0) + 1

    switch (operationType) {
        case OperationTypeEnum.INSERT_COMMENT:
            return (
                handleMentionComment({ loggedInEmployee, mentionValue: subscription.name, toNotify, isSubscription: true }) ||
                i18n.t(`activity:subscription.insert_comment${multiple_suffix}`, { combinedLength, name: subscription.name })
            )
        case OperationTypeEnum.UPDATE_COMMENT:
            return (
                handleMentionComment({ loggedInEmployee, mentionValue: subscription.name, toNotify, isSubscription: true }) ||
                i18n.t("activity:subscription.updated_comment", { name: subscription.name })
            )
        case OperationTypeEnum.DELETE_COMMENT:
            return i18n.t("activity:subscription.delete_comment", { name: subscription.name })
        default:
            return formulateExpenseNotification(operationType, subscription, loggedInEmployee, toNotify, combinedInfo, metaData)
    }
}

export const formulateFolderNotification = (
    operationType: OperationTypeEnum,
    folder: TripFolder,
    loggedInEmployee: Employee,
    toNotify: any,
    combinedInfo: CombinedInfo,
    metaData: any,
): string => {
    const multipleSuffix = combinedInfo.isCombined ? "_multiple" : ""
    const combinedLength = (combinedInfo.combinedDependencies?.length ?? 0) + 1

    switch (operationType) {
        case OperationTypeEnum.INSERT:
            return i18n.t(`activity:folder.create_draft${multipleSuffix}`, { num: folder.expenseNumber, combinedLength })
        // TODO: insert multiple?
        case OperationTypeEnum.APPROVE_EXPENSE:
            return i18n.t(`activity:folder.approved`, { num: folder.expenseNumber, priceLabel: formatCurrencyNumber(folder.totalGrossPrice, folder.currency) })
        case OperationTypeEnum.DECLINED_EXPENSE:
            return i18n.t(`activity:folder.declined`, { num: folder.expenseNumber, priceLabel: formatCurrencyNumber(folder.totalGrossPrice, folder.currency) })
        case OperationTypeEnum.REVIEWED_EXPENSE:
            return i18n.t(`activity:folder.reviewed`, { num: folder.expenseNumber, priceLabel: formatCurrencyNumber(folder.totalGrossPrice, folder.currency) })
        case OperationTypeEnum.PAID_EXPENSE:
            return i18n.t(`activity:folder.paid`, { num: folder.expenseNumber, priceLabel: formatCurrencyNumber(folder.totalGrossPrice, folder.currency) })
        case OperationTypeEnum.INSERT_COMMENT:
            return (
                handleMentionComment({ loggedInEmployee, mentionValue: folder.expenseNumber, toNotify, isFolder: true, multipleSuffix, combinedLength }) ||
                i18n.t(`activity:folder.insert_comment${multipleSuffix}`, { num: folder.expenseNumber, combinedLength })
            )
        case OperationTypeEnum.UPDATE_COMMENT:
            return (
                handleMentionComment({ loggedInEmployee, mentionValue: folder.expenseNumber, toNotify, isSubscription: true }) ||
                i18n.t(`activity:subscription.updated_comment${multipleSuffix}`, { combinedLength, num: folder.subscriptionType })
            )
        case OperationTypeEnum.DELETE_COMMENT:
            return i18n.t(`activity:folder.delete_comment`, { num: folder.expenseNumber })
        case OperationTypeEnum.MENTION_COMMENT:
            return i18n.t(`activity:folder.mention_comment${multipleSuffix}`, { num: folder.expenseNumber, combinedLength })
        case OperationTypeEnum.DELETE:
            return i18n.t("activity:folder.delete", { num: folder.expenseNumber, priceLabel: formatCurrencyNumber(folder.totalGrossPrice, folder.currency) })
        case OperationTypeEnum.APPROVE_REMINDER:
            return i18n.t("activity:folder.approve_reminder", { num: folder.expenseNumber })
        case OperationTypeEnum.SEND_EXPENSE_REMINDER_NOTIFICATION:
            return i18n.t("activity:folder.follow_up_today", { num: folder.expenseNumber })
        case OperationTypeEnum.ASYNC_EXPORT:
            return i18n.t("activity:folder.async_export")
        case OperationTypeEnum.SUBMIT_FOLDER:
            return i18n.t("activity:folder.submit_folder", { num: folder.expenseNumber, priceLabel: formatCurrencyNumber(folder.totalGrossPrice, folder.currency) })
        default:
            return i18n.t(`activity:folder.update${multipleSuffix}`, {
                num: folder.expenseNumber,
                priceLabel: formatCurrencyNumber(folder.totalGrossPrice, folder.currency),
                combinedLength,
            })
    }
}

export const formulateBankConnectionNotification = (operationType: OperationTypeEnum, expense: Expense, showGrossAmount: boolean, combinedInfo: CombinedInfo) => {
    const expensePrice = showGrossAmount ? expense.totalGrossPrice : expense.totalNetPrice
    const multiple_suffix = combinedInfo.isCombined ? "_multiple" : ""

    switch (operationType) {
        case OperationTypeEnum.BANK_PAYMENT_SUCCESS:
            return i18n.t("activity:expense.paid", { num: expense.expenseNumber, priceLabel: formatCurrencyNumber(expensePrice, expense.currency) })
        case OperationTypeEnum.UPDATE:
            return i18n.t(`activity:bankConnection.update${multiple_suffix}`)
        case OperationTypeEnum.BANK_IMPORT_SUCCESS:
            return i18n.t("activity:bankConnection.added")
        default:
            return `${operationType}`
    }
}

export type BaseOperationNotificationType = {
    operationType: OperationTypeEnum
    type: string
    loggedInEmployee: Employee
    value?: string
    arg?: string
    toNotify?: any
    details?: string
}

export type CombinedInfo = {
    isCombined?: boolean
    combinedDependencies?: Array<string>
}

export const formulateBaseOperationNotification = (
    { operationType, type, value, loggedInEmployee, arg, toNotify, details }: BaseOperationNotificationType,
    combinedInfo: CombinedInfo,
) => {
    // * contains extras details like company name or articulations
    const appendix = value && value.length ? `<strong>${value}</strong>` : ""
    const multipleSuffix = combinedInfo.isCombined ? "_multiple" : ""
    const combinedLength = (combinedInfo.combinedDependencies?.length ?? 0) + 1

    switch (operationType) {
        case OperationTypeEnum.INSERT:
            return i18n.t(`activity:${type}.create${multipleSuffix}`, { type: arg, for: details, combinedLength })
        case OperationTypeEnum.DELETE:
            return i18n.t(`activity:${type}.delete`, { appendix })
        case OperationTypeEnum.INSERT_COMMENT:
            return (
                handleMentionComment({ loggedInEmployee, mentionValue: value, toNotify, multipleSuffix, combinedLength }) ||
                i18n.t(`activity:${type}.insert_comment${multipleSuffix}`, { name: value, combinedLength })
            )
        case OperationTypeEnum.UPDATE_COMMENT:
            return (
                handleMentionComment({ loggedInEmployee, mentionValue: value, toNotify, multipleSuffix, combinedLength }) ||
                i18n.t(`activity:${type}.updated_comment${multipleSuffix}`, { name: value, combinedLength })
            )
        case OperationTypeEnum.DELETE_COMMENT:
            return i18n.t(`activity:${type}.delete_comment`, { name: value })
        case OperationTypeEnum.INVITE_USER:
            return i18n.t(`activity:${type}.invite_user`, { name: value })
        case OperationTypeEnum.SHARED:
            if (!toNotify.includes(loggedInEmployee.id)) return i18n.t(`activity:${type}.shared`, { name: value })
            return i18n.t(`activity:${type}.shared_with_me`, { name: value })
        default:
            return i18n.t(`activity:${type}.update${multipleSuffix}`, { appendix, combinedLength })
    }
}

export const formulateImportResultNotification = () => i18n.t(`activity:import_result.see_result`)

export const removeDuplicatesInFilter = (filterString: string) => [...new Set(filterString.split("&"))].join("&")

export const isNotification = (activityData: any): boolean => activityData.isNotification

export const getActivityDocumentRoute = async ({ fullData, collectionName, operationType, documentId }: any) => {
    switch (collectionName) {
        case CollectionNameEnum.EXPENSE:
            const expense = new Expense(fullData)
            const basePath = isFullyProcessedSubscription(expense) ? RoutePathEnum.SUBSCRIPTION_DETAIL_PAGE : RoutePathEnum.EXPENSE_DETAIL_PAGE
            return generatePath(basePath, { id: documentId })
        case CollectionNameEnum.VENDOR:
            return generatePath(RoutePathEnum.VENDOR_DETAIL_PAGE, { id: documentId })
        case CollectionNameEnum.USER:
            return generatePath(RoutePathEnum.EMPLOYEE_DETAIL_PAGE, { id: documentId })
        case CollectionNameEnum.COST_CENTER:
            return generatePath(RoutePathEnum.COST_CENTER_DETAIL_PAGE, { id: documentId })
        case CollectionNameEnum.INBOX_INVOICE:
            return generatePath(RoutePathEnum.EXPENSES)
        case CollectionNameEnum.BANK_CONNECTION:
            if (operationType === OperationTypeEnum.BANK_PAYMENT_SUCCESS) return generatePath(RoutePathEnum.EXPENSE_DETAIL_PAGE, { id: documentId })
            return generatePath(RoutePathEnum.SETTINGS_BANK_ACCOUNTS)
        case CollectionNameEnum.CARD:
            if (operationType === OperationTypeEnum.EXPENSE_CARD_TRANSACTION) return generatePath(RoutePathEnum.EXPENSE_DETAIL_PAGE, { id: documentId })
            return generatePath(RoutePathEnum.CARD_DETAIL_PAGE, { id: documentId })
        case CollectionNameEnum.WORKFLOW:
            return generatePath(RoutePathEnum.WORKFLOW_DETAIL_PAGE, { id: documentId })
        default:
            return ""
    }
}
