import { Company, Expense } from "@finway-group/shared/lib/models"

export const isValidDatevInvoiceCsvExport = (company: Company, expenses: Array<Expense>) =>
    company.datevSettings?.datevContext?.clientNumber &&
    company.datevSettings?.datevContext?.consultantNumber &&
    company.datevSettings?.datevCsvConfig?.accountNumberLength &&
    expenses.every(({ splits, expenseAccount }) => {
        if (!splits || splits.length === 0) {
            return expenseAccount?._id
        }
        return splits.every(({ expenseAccount }) => expenseAccount?._id)
    })
