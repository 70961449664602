import {
    BillingPeriodEnum,
    CardStatusEnum,
    CardTransactionTypeEnum,
    CardWindowEnum,
    Employee,
    Expense,
    ExpenseKindEnum,
    KYBStatusEnum,
    TransactionTypeEnum,
    WeavrAuthorizationTypesEnum,
} from "@finway-group/shared/lib/models"
import { TransactionStatusEnum } from "@finway-group/shared/lib/models/transaction/transactionStatus.enum"
import i18n from "i18next"

import { KYBData } from "Shared/store/reducers/corporateReducer"

export const cardWindowArray = [
    { label: i18n.t("creditCard:frequency.daily"), value: CardWindowEnum.DAY },
    { label: i18n.t("creditCard:frequency.weekly"), value: CardWindowEnum.WEEK },
    { label: i18n.t("creditCard:frequency.monthly"), value: CardWindowEnum.MONTH },
    { label: i18n.t("creditCard:frequency.quarterly"), value: CardWindowEnum.QUARTER },
    { label: i18n.t("creditCard:frequency.yearly"), value: CardWindowEnum.YEAR },
    { label: i18n.t("creditCard:frequency.life_time"), value: CardWindowEnum.LIFETIME },
    { label: i18n.t("creditCard:frequency.one_time"), value: CardWindowEnum.ONE_TIME },
]

export const getLabelForWindow = (window: CardWindowEnum) => {
    switch (window) {
        case CardWindowEnum.DAY:
            return i18n.t("label:cards.window.daily")
        case CardWindowEnum.WEEK:
            return i18n.t("label:cards.window.weekly")
        case CardWindowEnum.MONTH:
            return i18n.t("label:cards.window.monthly")
        case CardWindowEnum.QUARTER:
            return i18n.t("label:cards.window.quarterly")
        case CardWindowEnum.YEAR:
            return i18n.t("label:cards.window.yearly")
        case CardWindowEnum.LIFETIME:
            return i18n.t("label:cards.window.life_time")
        case CardWindowEnum.ONE_TIME:
            return i18n.t("label:cards.window.one_time")
        default:
            return ""
    }
}

export const getCardDataForSubscription = (expense: Expense) => ({
    limit: expense.totalGrossPrice,
    currency: expense.currency,
    cardHolder: expense.requestedBy._id,
    expense,
    cardName: `Subscription card for ${expense.name}`,
    window: getCardWindowForSubscriptionBillingPeriod(expense.billingPeriod),
})

// TODO: extract to shared
export const getCardWindowForSubscriptionBillingPeriod = (billingPeriod: BillingPeriodEnum) => {
    switch (billingPeriod) {
        case BillingPeriodEnum.MONTHLY:
            return CardWindowEnum.MONTH
        case BillingPeriodEnum.QUARTERLY:
            return CardWindowEnum.QUARTER
        case BillingPeriodEnum.YEARLY:
            return CardWindowEnum.YEAR
    }
}

export const getWindowForExpenseCard = (cardExpense: any) => {
    if (cardExpense.kind === ExpenseKindEnum.SUBSCRIPTION) {
        switch (cardExpense.billingPeriod) {
            case BillingPeriodEnum.MONTHLY:
                return i18n.t("label:cards.window.monthly")
            case BillingPeriodEnum.QUARTERLY:
                return i18n.t("label:cards.window.quarterly")
            case BillingPeriodEnum.YEARLY:
                return i18n.t("label:cards.window.yearly")
            default:
                return "n/a"
        }
    } else {
        return i18n.t("label:cards.window.one_time")
    }
}

export const getInfoTextForExpenseCard = (cardExpense: any) => {
    if (cardExpense.kind === ExpenseKindEnum.SUBSCRIPTION) {
        return i18n.t("info:cards.window.no_edit_sub_card")
    }
    return i18n.t("info:cards.window.no_edit_one_time_card")
}

export const marshalCardData = (card: any) => ({
    ...card,
    expense: card.expense?._id,
    transactions: card.transactions.map((txn: any) => txn._id),
})

export const getCardStatusForAction = (action: string) => {
    switch (action) {
        case "suspend":
            return CardStatusEnum.SUSPENDED
        case "close":
            return CardStatusEnum.TERMINATED
        case "report_stolen":
            return CardStatusEnum.REPORTED_STOLEN
        case "report_lost":
            return CardStatusEnum.REPORTED_LOST
        default:
            throw Error("Action not expected")
    }
}

export const isAuthorizationRequest = (cardTransactionType: CardTransactionTypeEnum) =>
    [WeavrAuthorizationTypesEnum.AUTHORISED, WeavrAuthorizationTypesEnum.AUTHORISED_CREDIT].includes(cardTransactionType)

export const filterTransactionsToDisplay = (transactions: Array<any>, shouldFilterTransfers: boolean) =>
    transactions.filter(({ transactionType, cardData, status, transactionData }: any) => {
        // if not a card transaction/transfer we don't need to filter anything else
        if (transactionType != TransactionTypeEnum.CARD_TRANSACTION && transactionType !== TransactionTypeEnum.INTERNAL_TRANSFER) return true
        // only show transfers if indicated via flag
        if (shouldFilterTransfers && transactionType === TransactionTypeEnum.INTERNAL_TRANSFER) return false
        // only show pending authorization requests
        if (isAuthorizationRequest(cardData?.cardTransactionType) && status !== TransactionStatusEnum.PENDING) return false
        // only show partial reversals as long as the initial auth request is still pending
        if (cardData?.cardTransactionType === WeavrAuthorizationTypesEnum.ONLINE_REVERSE) {
            return (
                status === TransactionStatusEnum.COMPLETED &&
                transactions.find((txn: any) => cardData?.relatedTransactionIds.includes(txn.transactionId) && txn.status === TransactionStatusEnum.PENDING)
            )
        }
        // filter out 0 amounts
        if (!transactionData || transactionData.amount === 0) return false
        return true
    })

export const isCashWithdrawal = (cardType: any) => [CardTransactionTypeEnum.CASH_WITHDRAWAL, CardTransactionTypeEnum.CASH_WITHDRAWAL_REVERSAL].includes(cardType)

export const isUserEnrolled = (weavrData: any) => weavrData.userId && weavrData.isPasswordCreated

export const calcBufferSpending = (card: any) => {
    if (!card || card.spending < card.limit - card.limitBuffer) return 0
    return card.limitBuffer - card.limit + card.spending
}

export const getCardLimitWithoutBuffer = (card: any) => card?.limit - card?.limitBuffer

export const shouldShowRootUserEmailReverification = (employee: Employee, kybData: KYBData) => {
    const isRootUser = employee?.activeCompanyProfile?.weavrData?.isRoot
    const isActiveCorporate = kybData?.kybStatus === KYBStatusEnum.COMPLETED

    return isRootUser && isActiveCorporate && !kybData?.isEmailVerified
}
