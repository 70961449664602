/* eslint-disable prefer-rest-params */

/* global Intercom_ */
import { AccessTokenType, RightEnum, Role } from "@finway-group/shared/lib/models"
import { useCallback, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"

import { INTERCOM_APP_ID, INTERCOM_TEST_APP_ID } from "Shared/config/consts"
import { useAuth } from "Shared/hooks/auth.hooks"
import { useCompany } from "Shared/hooks/company.hooks"
import { useRolesMap } from "Shared/hooks/role.hooks"
import { breakpoints } from "Shared/utils/breakpoints.utils"
import { isDev, isInternal } from "Shared/utils/helper.utils"
import { useResizeWindowWidth } from "Shared/utils/hooks/useResizeWindowWidth"

const APP_ID_ENV = isDev || isInternal ? INTERCOM_TEST_APP_ID : INTERCOM_APP_ID

const RIGHTS = Object.values(RightEnum)

const mapRightsToCustomAttributes = (rights?: Array<RightEnum>) => {
    const rightsAttributes = {} as { [key: string]: boolean }

    for (const right of RIGHTS) {
        rightsAttributes[`finway_right_${right}`] = false
    }
    for (const right of rights || []) {
        rightsAttributes[`finway_right_${right}`] = true
    }

    return rightsAttributes
}

const extractIntercomUserData = ({
    accessToken,
    companyId,
    companyName,
    currentPage,
    roles,
}: {
    accessToken?: AccessTokenType
    companyId?: string
    companyName?: string
    currentPage?: string
    roles?: Map<string, Role>
}): Intercom_.IntercomSettings => {
    if (!accessToken)
        return {
            app_id: APP_ID_ENV,
        }

    const activeCompanyProfile = accessToken.companyProfiles?.find((companyProfile) => companyProfile.companyId === String(companyId))!
    const role = roles?.get(activeCompanyProfile?.roleId)
    const roleTemplate = role?.template ? roles?.get(role?.template) : role

    const rightAttributes = mapRightsToCustomAttributes(role?.rights)

    return {
        app_id: APP_ID_ENV,
        api_base: "https://api-iam.eu.intercom.io",
        user_id: accessToken.id,
        name: accessToken.name,
        email: accessToken.email,

        finway_full_url: window.location.host,

        // R&R related
        finway_role: role?.name,
        finway_role_template: roleTemplate?.name,
        finway_system_rights: accessToken.hasSystemRights ?? false,
        ...(rightAttributes && { ...rightAttributes }),

        // misc
        finway_tenant_id: accessToken.tenantId,
        finway_current_page: currentPage,
        company_id: companyId,
        company_name: companyName,
    }
}

/* eslint-disable func-names */
const intercomCodeSnippet = (APP_ID: string = "") => {
    ;(function () {
        const w = window
        const ic = (w as any).Intercom
        if (typeof ic === "function") {
            ic("reattach_activator")
            ic("update", (w as any).intercomSettings)
        } else {
            const d = document
            const i = function () {
                ;(i as any).c(arguments)
            }
            ;(i as any).q = []
            ;(i as any).c = function (args: any) {
                ;(i as any).q.push(args)
            }
            ;(w as any).Intercom = i
            const l = function () {
                const s = d.createElement("script")
                s.type = "text/javascript"
                s.async = true
                s.src = `https://widget.intercom.io/widget/${APP_ID}`
                const x = d.getElementsByTagName("script")[0]
                x.parentNode?.insertBefore(s, x)
            }
            if (document.readyState === "complete") {
                l()
            } else if ((w as any).attachEvent) {
                ;(w as any).attachEvent("onload", l)
            } else {
                w.addEventListener("load", l, false)
            }
        }
    })()
}

const Intercom = () => {
    const { pathname: currentPage } = useLocation()
    const roles = useRolesMap()
    const windowWidth = useResizeWindowWidth()
    const { userClass, userId, companyId } = useAuth()
    const accessToken = userClass as unknown as AccessTokenType
    const currentCompany = useCompany()

    const [hasBooted, setHasBooted] = useState(false)

    const updateIntercom = useCallback(
        (command: Intercom_.IntercomCommand = "update") => {
            window.Intercom(command, extractIntercomUserData({ accessToken, companyId, currentPage, roles, companyName: currentCompany?.name }))
        },
        [accessToken, companyId, currentPage, roles],
    )

    const shutdownIntercom = () => {
        window.Intercom("shutdown")
        setHasBooted(false)
    }

    useEffect(
        () => () => {
            if (!userId) {
                shutdownIntercom()
            }
        },
        [],
    )

    // when the user ID changes (different logins or logouts), do the corresponding action
    useEffect(() => {
        if (!window.Intercom) intercomCodeSnippet(APP_ID_ENV)

        if (userId && !hasBooted) {
            updateIntercom("boot")
            setHasBooted(true)
        }
    }, [updateIntercom, hasBooted, userId])

    useEffect(() => {
        if (userId && hasBooted) updateIntercom()
    }, [
        updateIntercom,
        hasBooted,
        // intercom should be updated on every URL change
        currentPage,
        // when user data that is used for intercom changes, update as well
        userId,
        accessToken,
        companyId,
    ])

    // intercom launcher should be slightly moved up when expense action buttons are at the bottom of the page.
    useEffect(() => {
        window.Intercom("update", {
            vertical_padding: currentPage.includes("/expenses/") && windowWidth < breakpoints.sm ? 50 : 20,
        })
    }, [currentPage, windowWidth])

    return null
}

export default Intercom
