import { LoadingOutlined } from "@ant-design/icons"
import { AutoComplete, Form } from "antd"
import React, { useEffect } from "react"
import { useDebouncedCallback } from "use-debounce/lib"

import { DEBOUNCE_DURATION_LONG } from "Shared/config/consts"
import GeoLocationService, { GeoLocation } from "Shared/services/geoLocation.service"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"
import { getPopupAnchor } from "Shared/utils/popup.utils"

interface AddressAutocompleteInterface {
    placeholder: string
    onSelect: (e: { label: string; value: string; coordinates: [number, number] }) => void
    className?: string
    label?: string
    name?: string | Array<string> | number
    formItemKey?: string | number
    initialValue?: any
    rules: any
    [rest: string]: any // For Form.List control
}

// This type is strictly for the AutoComplete component
export interface LocationForForm {
    label: string
    value: string
    coordinates: [number, number]
}

const AddressAutocomplete: React.FC<AddressAutocompleteInterface> = ({ placeholder, onSelect, className, label, name, formItemKey, initialValue, rules, ...rest }) => {
    const [searchResults, setSearchResults] = useStateIfMounted<Array<LocationForForm>>([])
    const [isLoading, setIsLoading] = useStateIfMounted<boolean>(false)
    const [currentQueryString, setCurrentQueryString] = useStateIfMounted<string | undefined>(undefined)

    const onLocationSearch = useDebouncedCallback((queryString: string) => {
        setCurrentQueryString(queryString)
    }, DEBOUNCE_DURATION_LONG)

    useEffect(() => {
        if (!currentQueryString) return
        let isMounted = true
        GeoLocationService.getPlacesAutocomplete(currentQueryString)
            .then((data) => {
                if (!isMounted) return
                if (data.length) {
                    setSearchResults(data.map(({ address, point: { coordinates } }: GeoLocation) => ({ label: address, value: address, coordinates })))
                    setCurrentQueryString(undefined)
                }
            })
            .catch((error) => console.error("AddressAutocomplete", error))
            .finally(() => setIsLoading(false))
        return () => {
            isMounted = false
        }
    }, [currentQueryString])

    const handleSelection = (value: string, option: LocationForForm) => {
        // Option will be the value of the dropdown that the user selected
        if (option) {
            onSelect(option)
            return
        }

        // When option is somehow empty, fallback to use the searchResults. This is not ideal because searchResults might be outdated (see known bug below)
        const result = searchResults.find((item) => item.value === value)
        if (result) {
            onSelect(result)
        }
    }

    /**
     * Callback func for antd to set the internal state (form.fieldValues) of the form instance
     *
     * TODO - Known bug: searchResults here might be several render behind... causing .find to return nothing
     *
     * Wibi:
     *
     * Maybe caused by the Form.Item component calling this function defined by the previous render (when searchResult is still empty / outdated)?
     *
     * To replicate: Paste a value into the field, then select the dropdown. (https://levaroio.atlassian.net/browse/CS-2042)
     *
     * To put a band aid on this bug, the parent of this component should set the antd form manually, when onSelect is called, and the selected value is passed.
     * (Or probably i'm overthinking this and there's a much better elegant solution)
     */
    const getFormItemValue = (selected: string) => {
        const result = searchResults.find((item) => item.value === selected)
        if (!result) return
        const formItemValue = {
            coordinates: result.coordinates,
            address: result.value,
        }
        return formItemValue
    }

    return (
        <Form.Item
            className={className ?? ""}
            label={label}
            name={name}
            key={formItemKey}
            initialValue={initialValue}
            rules={rules}
            {...rest}
            getValueFromEvent={getFormItemValue}
            validateTrigger={"onBlur"}
        >
            <AutoComplete
                placeholder={placeholder}
                onSearch={(queryString) => {
                    setIsLoading(true)
                    onLocationSearch.callback(queryString)
                }}
                options={isLoading ? undefined : searchResults}
                onSelect={handleSelection}
                getPopupContainer={getPopupAnchor()}
            >
                {isLoading && (
                    <AutoComplete.Option value={""} key={"loading-option"} disabled>
                        <div className="w-full text-center">
                            <LoadingOutlined />
                        </div>
                    </AutoComplete.Option>
                )}
            </AutoComplete>
        </Form.Item>
    )
}

export default AddressAutocomplete
