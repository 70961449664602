import { useEffect, useRef } from "react"

/**
 * Used to only render on updates and avoid rendering on the first mount.
 */
export const useUpdateEffect: typeof useEffect = (effect, depedencyArray) => {
    const isFirstMount = useRef(true)

    useEffect(() => {
        if (!isFirstMount.current) effect()
        else isFirstMount.current = false
    }, depedencyArray)
}

export default useUpdateEffect
