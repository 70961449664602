import { TransactionTypeEnum } from "@finway-group/shared/lib/models"
import { TransactionStatusEnum } from "@finway-group/shared/lib/models/transaction/transactionStatus.enum"
import { Alert } from "antd"
import React from "react"
import { Trans, useTranslation } from "react-i18next"

import { WeavrAuthorizationDeclineReasonEnum } from "Shared/services/simulator.service"
import { isCashWithdrawal } from "Shared/utils/creditCard.utils"

interface TransactionBannerInterface {
    transaction: any
}

const TransactionBanner: React.FC<TransactionBannerInterface> = ({ transaction }) => {
    const { t } = useTranslation()
    const shouldShowCardInfo = transaction.transactionType === TransactionTypeEnum.CARD_TRANSACTION && transaction.status === TransactionStatusEnum.COMPLETED
    const isCashTransaction = shouldShowCardInfo && isCashWithdrawal(transaction?.cardData.cardTransactionType)
    const isBankTransactionPending = transaction.transactionType === TransactionTypeEnum.BANK_TRANSACTION && transaction.status === TransactionStatusEnum.PENDING
    const shouldShowPending = transaction.status === TransactionStatusEnum.PENDING
    const shouldShowFailed = transaction.status === TransactionStatusEnum.FAILED
    const hasReason =
        (transaction.transactionType === TransactionTypeEnum.CARD_TRANSACTION || transaction.transactionType === TransactionTypeEnum.OUTGOING_TRANSACTION) &&
        transaction.statusReason &&
        transaction.statusReason !== WeavrAuthorizationDeclineReasonEnum.NO_REASON

    return isCashTransaction ? (
        <Alert className="mb-20" message={t("info:matching.cash_card_transaction_info")} type="info" showIcon={true} />
    ) : shouldShowCardInfo ? (
        <Alert className="mb-20" message={t("info:matching.card_transaction_info")} type="info" showIcon={true} />
    ) : isBankTransactionPending ? (
        <Alert className="mb-20" message={<Trans t={t} i18nKey={`info:matching.pending_bank_transaction.message`} components={[<b />]} />} type="warning" showIcon={true} />
    ) : shouldShowPending ? (
        <Alert className="mb-20" message={t("info:matching.pending_transaction_info")} type="warning" showIcon={true} />
    ) : shouldShowFailed ? (
        <Alert
            className="mb-20"
            message={t(`info:matching.failed_transaction_info${hasReason ? "_for_reason" : ""}`, {
                reason: t(`label:cards.decline_reasons.${transaction?.statusReason?.toLowerCase()}`),
            })}
            type="error"
            showIcon={true}
        />
    ) : (
        <></>
    )
}

export default TransactionBanner
