import Icon from "@ant-design/icons"
import React from "react"

const folderSvg = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="16" height="16" rx="2" fill="#EEF4F8" />
        <path
            d="M12.6296 11.2037C12.6296 11.4591 12.5282 11.704 12.3476 11.8846C12.167 12.0652 11.9221 12.1667 11.6667 12.1667H3.96296C3.70757 12.1667 3.46264 12.0652 3.28205 11.8846C3.10145 11.704 3 11.4591 3 11.2037V4.46296C3 4.20757 3.10145 3.96264 3.28205 3.78205C3.46264 3.60145 3.70757 3.5 3.96296 3.5H6.37037L7.33333 4.94444H11.6667C11.9221 4.94444 12.167 5.0459 12.3476 5.22649C12.5282 5.40708 12.6296 5.65201 12.6296 5.90741V11.2037Z"
            stroke="#606C7E"
            strokeWidth="0.75"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)

const FolderIcon = (props: any) => <Icon component={folderSvg} {...props} />

export default FolderIcon
