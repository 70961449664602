import { CollectionNameEnum, Company, Expense, ExpenseSplit, InvoiceSplit, SplitTypeEnum } from "@finway-group/shared/lib/models"
import { DiscountInformation, isAtLeastReviewed, roundNumberTo2Decimals, toAmount, toDinero } from "@finway-group/shared/lib/utils"
import { calculateNetPriceFromGrossPriceAndTaxRate } from "@finway-group/shared/lib/utils/expense.utils"
import { Form, Modal } from "antd"
import { FormInstance } from "antd/lib/form"
import React, { useContext, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import { NOT_SET_VALUE, NO_TAX_RATE } from "Shared/config/consts"
import { useArchiveInterval, useCompany } from "Shared/hooks/company.hooks"
import { useLoggedInEmployee } from "Shared/hooks/employee.hooks"
import { useTaxes } from "Shared/hooks/tax.hooks"
import { ApprovalProcessService, ExpenseHttpService, ExpenseService, NotificationService } from "Shared/services"
import DialogService from "Shared/services/dialog.service"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { ThunkDispatchResult } from "Shared/store"
import { updateExpense } from "Shared/store/actions/expense/expenseActions"
import {
    adjustGrossAmountsIfNeeded,
    areInvoiceSplits,
    areItemSplits,
    calculateSplitPercentageTotal,
    calculateTotalTax,
    detectHorizontalSplitError,
    detectSplitRoundingError,
    detectVerticalSplitError,
    getExpenseFieldsSummaryBasedOnSplits,
    isFolderExpense,
    isSplitPropertiesSame,
} from "Shared/utils/expense.utils"
import { emptyStringToUndefined, isEmptyString } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

export enum PriceFieldEnum {
    NET_PRICE = "netPrice",
    GROSS_PRICE = "grossPrice",
    TAX_PRICE = "taxPrice",
}

export interface VerticalErrorInfo {
    netPrice: number
    taxPrice: number
    grossPrice: number
}

export enum SplitUpdatablePropertyEnum {
    COST_CENTER = "costCenter",
    COST_CENTER_2 = "costCenter2",
    EXPENSE_ACCOUNT = "expenseAccount",
    PERCENTAGE = "percentage",
    TAX_RATE = "taxRate",
}

interface MainDetailsSectionContextState {
    expense: Expense
    splits: Array<ExpenseSplit>
    setSplits: (splits: Array<ExpenseSplit>) => void
    isMinimized?: boolean
    openEditModal?: () => void
    setIsExpenseUpdating?: (boolean: boolean) => void
    problems: Array<string>
    hasUnsavedChanges: boolean
    setHasUnsavedChanges: (value: boolean) => void
    setProblems: (problems: Array<string>) => void
    isSplitManuallyAdjusted: boolean
    setIsSplitManuallyAdjusted: (val: boolean) => void
    expenseForm: FormInstance
    onSplitUpdate: (index?: number, isNew?: boolean, updatedField?: SplitUpdatablePropertyEnum) => void
    onPriceAdjustment: (index: number, field: PriceFieldEnum, amount: number) => void
    onAllSplitUpdate: (updatedProperty: CollectionNameEnum) => void
    onSplitDelete: (index: number) => void
    validateAndSave: (value: any) => Promise<void>
    saveSplitChanges: (totalTaxPriceOverride?: number) => void
    onSyncNetAndTax: () => void
    unlockSplitAmounts: () => void
    getSplitProblems: (splitsToCheck: Array<ExpenseSplit>) => Promise<Array<string>>
    expenseTaxPrice: number
    setExpenseTaxPrice: (price: number) => void
    expenseNetPrice: number
    setExpenseNetPrice: (price: number) => void
    isLoading: boolean
    setIsLoading: (isLoading: boolean) => void
    onSave: () => void
    disableSaveButton: boolean
    showProbability: boolean
    showLink: boolean
    roundingErrors: VerticalErrorInfo
    verticalErrors: VerticalErrorInfo
    horizontalErrors: Array<number>
    isEditing: boolean
    setIsEditing: (value: boolean) => void
    resetChanges: () => void
    isExpenseEditable: boolean
    discountDisplay: DiscountInformation | undefined
    setDiscountDisplay: (input?: DiscountInformation) => void
}

interface MainDetailsSectionContextProps {
    expense: Expense
    isMinimized: boolean
    openEditModal?: () => void
    setIsExpenseUpdating?: React.Dispatch<React.SetStateAction<boolean>>
    showProbability?: boolean
    showLink?: boolean
    setIsShowingDiscountForm?: React.Dispatch<React.SetStateAction<boolean>>
}

const Context = React.createContext<MainDetailsSectionContextState | undefined>(undefined)

export const useMainDetailsSectionContext = () => {
    const context = useContext(Context) as MainDetailsSectionContextState
    if (context === undefined) {
        throw new Error("useMainDetailsSectionContext must be used inside <MainDetailsSectionContext></MainDetailsSectionContext>")
    }
    return context
}

/**
 * Maintains the logic of the expense split table
 *
 * In the future we can try to utilize https://reactjs.org/docs/render-props.html
 */
const MainDetailsSectionContext: React.FC<MainDetailsSectionContextProps> = ({
    expense,
    isMinimized,
    openEditModal,
    setIsExpenseUpdating,
    setIsShowingDiscountForm,
    showProbability = false,
    showLink = false,
    children,
}) => {
    const { t } = useTranslation()
    const dispatch: ThunkDispatchResult = useDispatch()
    const taxValues = useTaxes()
    const company = useCompany() as Company

    const [isSplitManuallyAdjusted, setIsSplitManuallyAdjusted] = useStateIfMounted(expense.splitManuallyAdjusted ?? false)
    const [expenseTaxPrice, setExpenseTaxPrice] = useStateIfMounted<number>(expense.totalTaxPrice || 0)
    const [expenseNetPrice, setExpenseNetPrice] = useStateIfMounted<number>(expense.totalNetPrice || expense.totalGrossPrice - expense.totalTaxPrice || 0)

    const [splits, setSplits] = useStateIfMounted(expense.splits)
    const [isLoading, setIsLoading] = useStateIfMounted<boolean>(false)
    const [expenseForm] = Form.useForm()
    const [hasUnsavedChanges, setHasUnsavedChanges] = useStateIfMounted(false)
    const [isEditing, setIsEditing] = useStateIfMounted(false)
    const [discountDisplay, setDiscountDisplay] = useStateIfMounted<DiscountInformation | undefined>(undefined)

    const archiveAfterXDays = useArchiveInterval()

    const totalSplitPercentage = areInvoiceSplits(splits, expense) ? roundNumberTo2Decimals(calculateSplitPercentageTotal(splits)) : 100
    const totalTaxPrice = calculateTotalTax(splits)
    const isTaxSynced = roundNumberTo2Decimals(expenseTaxPrice || 0) === roundNumberTo2Decimals(totalTaxPrice || 0)

    const [problems, setProblems] = useStateIfMounted<Array<string>>([])
    // extract to function
    const disableSaveButton = expense.splitType === SplitTypeEnum.SPLIT && totalSplitPercentage !== 100

    const roundingErrors = detectSplitRoundingError(splits)
    const verticalErrors = detectVerticalSplitError(expense, splits)
    const horizontalErrors = detectHorizontalSplitError(splits)

    const loggedInEmployee = useLoggedInEmployee()

    // The split/item table should correlate with the edit button. When the button is hidden / disabled, the table must be set read-only
    const { expenseEditable: isExpenseEditable } = ExpenseService.calculateExpenseEditButtonView(expense, loggedInEmployee, archiveAfterXDays, t)

    const folder = isFolderExpense(expense) ? expense : undefined

    // Useeffect to trigger when there is any changes in the expense.
    // Will cancel the changes that happens in the split form when somewhere else updates the expense (e.g. other user).
    // To ensure that this will trigger if there is an actual change in the expense object, we depend on the id and updatedAt.
    useEffect(() => {
        setProblems([])
        expenseForm.setFieldsValue({
            ...expense,
        })
        setSplits([...expense.splits])
        setExpenseTaxPrice(expense.totalTaxPrice || 0)
        setExpenseNetPrice(expense.totalNetPrice || 0)
        setIsSplitManuallyAdjusted(expense.splitManuallyAdjusted ?? false)
        setHasUnsavedChanges(false)
        if (setIsShowingDiscountForm) setIsShowingDiscountForm(false)
        setDiscountDisplay(undefined)
    }, [expense.id, String(expense.updatedAt)])

    const postSplitUpdateAdjustment = (updatedSplits: Array<ExpenseSplit>) => {
        if (areItemSplits(updatedSplits, expense))
            expenseForm.setFieldsValue(getExpenseFieldsSummaryBasedOnSplits(updatedSplits, expenseForm.getFieldValue("taxRate"), isTaxSynced))

        if (areInvoiceSplits(updatedSplits, expense)) {
            getSplitProblems(updatedSplits).then((problems) => {
                setProblems(problems)
            })
        }
    }

    const onPriceAdjustment = (index: number, field: PriceFieldEnum, amount: number) => {
        const newExpense = expenseForm.getFieldsValue()
        const updatedSplits: Array<InvoiceSplit> = newExpense.splits
        updatedSplits[index][field] = amount

        expenseForm.setFieldsValue({ ...newExpense, splits: [...updatedSplits] })
        setSplits([...updatedSplits])
        setHasUnsavedChanges(true)
    }

    const onSplitUpdate = (index?: number, isNew: boolean = false, updatedField?: SplitUpdatablePropertyEnum) => {
        const newExpense = expenseForm.getFieldsValue()
        const updatedSplits: Array<InvoiceSplit> = newExpense.splits
        const isSplitPercentageUpdate = updatedField === SplitUpdatablePropertyEnum.PERCENTAGE
        const doesSplitNeedRecalculation = isNew || isSplitPercentageUpdate ? true : !isSplitManuallyAdjusted

        if (isNew) {
            updatedSplits[updatedSplits.length - 1] = {
                ...updatedSplits[0],
                _id: undefined,
                percentage: 50,
            }
            // TODO: this should be updated to prevent having percentages above 100 after adding a third split
            if (updatedSplits.length === 2) updatedSplits[0].percentage = 50
        }

        const updatedNewExpense = updateExpenseOnUniformSplitProperties(newExpense, updatedSplits)

        const splitsWithUpdatedAmounts =
            expense.splitType === SplitTypeEnum.SPLIT && doesSplitNeedRecalculation ? recalculateSplitAmounts(updatedSplits, isSplitPercentageUpdate, index) : updatedSplits

        const cleanedUpSplits = cleanupSplitFields(splitsWithUpdatedAmounts)

        expenseForm.setFieldsValue({ ...updatedNewExpense, splits: [...cleanedUpSplits] })
        setSplits([...cleanedUpSplits])
        setIsSplitManuallyAdjusted(!doesSplitNeedRecalculation)
        setHasUnsavedChanges(true)
        postSplitUpdateAdjustment(cleanedUpSplits)
    }

    const updateExpenseOnUniformSplitProperties = (expense: Expense, updatedSplits: Array<InvoiceSplit>) => {
        const isUniform = isSplitPropertiesSame(updatedSplits)

        expense.costCenter = isUniform.costCenter ? updatedSplits[0].costCenter : ""
        expense.costCenter2 = isUniform.costCenter2 ? updatedSplits[0].costCenter2 : ""
        expense.expenseAccount = isUniform.expenseAccount ? updatedSplits[0].expenseAccount : { _id: NOT_SET_VALUE }
        expense.taxRate = isUniform.taxRate ? updatedSplits[0].taxRate : { _id: NO_TAX_RATE }

        return expense
    }

    const cleanupSplitFields = (updatedSplits: Array<InvoiceSplit>) => {
        for (const split of updatedSplits) {
            if (!split.expenseAccount?._id || split.expenseAccount?._id === NOT_SET_VALUE) split.expenseAccount = undefined
            if (!split.taxRate?._id || split.taxRate?._id === NO_TAX_RATE) split.taxRate = undefined
            if (split.costCenter2 === NOT_SET_VALUE) split.costCenter2 = undefined
        }
        return updatedSplits
    }

    const recalculateSplitAmounts = (updatedSplits: Array<InvoiceSplit>, isSplitPercentageUpdate: boolean, index?: number) => {
        const updatedSplit = updatedSplits[index ?? 0]

        const isTaxRateChanged = !Number.isNaN(index) && updatedSplit.taxRate && updatedSplit?.taxRate?._id !== splits[index!]?.taxRate?._id

        for (const split of updatedSplits) {
            const percentage = Number(split.percentage) / 100
            split.grossPrice = expense.totalGrossPrice * percentage
            split.netPrice = expense.totalNetPrice * percentage

            if (isTaxRateChanged || isSplitPercentageUpdate) {
                // Get the tax value from redux, not from the reference, because the newly assigned tax value reference will not have the tax rate yet.
                const newTax = taxValues.find((tax) => tax._id === updatedSplit.taxRate?._id)
                split.taxRate = newTax?._id ? newTax : { _id: NO_TAX_RATE }
                if (split.taxRate._id !== NO_TAX_RATE) {
                    split.netPrice = calculateNetPriceFromGrossPriceAndTaxRate(split.grossPrice, newTax?.taxRate)
                    split.taxPrice = toAmount(toDinero(split.grossPrice).subtract(toDinero(split.netPrice)))
                }
            } else {
                const taxAmount = expense.totalTaxPrice * percentage
                split.grossPrice = split.netPrice + taxAmount
                split.taxPrice = taxAmount
            }
        }

        return updatedSplits
    }

    const onAllSplitUpdate = (updatedProperty: CollectionNameEnum) => {
        const updatedSplits = [...splits]
        const updatedExpense: Expense = expenseForm.getFieldsValue()
        // Update properties of all split to follow the "change all" row
        for (const split of updatedSplits) {
            switch (updatedProperty) {
                case CollectionNameEnum.COST_CENTER:
                    split.costCenter = updatedExpense.costCenter!
                    break
                case CollectionNameEnum.COST_CENTER_2:
                    const newCostCenter2 = emptyStringToUndefined(updatedExpense.costCenter2)
                    split.costCenter2 = newCostCenter2
                    break
                case CollectionNameEnum.EXPENSE_ACCOUNT:
                    if (updatedExpense.expenseAccount?.accountCode) split.expenseAccount = updatedExpense.expenseAccount
                    break
                case CollectionNameEnum.TAX:
                    split.taxRate = updatedExpense.taxRate
                    break
            }
        }

        setSplits(updatedSplits)
        expenseForm.setFieldsValue({
            splits: updatedSplits,
            costCenter: updatedExpense.costCenter,
            costCenter2: updatedExpense.costCenter2,
            expenseAccount: updatedExpense.expenseAccount,
        })
        setHasUnsavedChanges(true)
    }

    const onSplitDelete = (index: number) => {
        const remainingExpenseSplits = splits.filter((_s: ExpenseSplit, idx: number) => idx !== index)
        if (remainingExpenseSplits.length === 1) {
            // delete can only be done when split type is SPLIT
            const remainingSplit = remainingExpenseSplits[0] as InvoiceSplit
            remainingSplit.percentage = 100
            remainingSplit.netPrice = expense.totalNetPrice
            remainingSplit.taxPrice = expense.totalTaxPrice
            remainingSplit.grossPrice = expense.totalGrossPrice
            remainingSplit.taxRate = expense.taxRate
            setIsSplitManuallyAdjusted(false)
        }
        expenseForm.setFieldsValue({
            splits: remainingExpenseSplits,
        })
        setSplits(remainingExpenseSplits)
        setHasUnsavedChanges(true)
    }

    const validateAndSave = async (value: any) => {
        if (!isTaxSynced) {
            Modal.confirm({
                title: t("confirm:request.tax_mismatch.title"),
                content: t("confirm:request.tax_mismatch.message"),
                cancelText: t("confirm:request.tax_mismatch.cancel"),
                type: "warning",
                okText: t("confirm:request.tax_mismatch.confirm"),
                onOk: () => {
                    saveSplitChanges(totalTaxPrice)
                },
                onCancel: () => {
                    saveSplitChanges()
                },
            })
        } else saveSplitChanges()
    }

    const saveSplitChanges = async (totalTaxPriceOverride?: number) => {
        try {
            setIsLoading(true)
            setIsExpenseUpdating?.(true)

            const cleanSplits = splits.map((split: ExpenseSplit) => ({
                ...split,
                costCenter2: isEmptyString(split.costCenter2 ?? "") ? undefined : split.costCenter2,
                taxRate: split.taxRate?._id === NO_TAX_RATE ? undefined : split.taxRate,
                expenseAccount: split.expenseAccount?._id ? split.expenseAccount : undefined,
            }))
            const isUniform = isSplitPropertiesSame(cleanSplits)
            const expenseUpdateData = expenseForm.getFieldsValue()
            // Determine if we need to change the expense model's cc/cc2/ea/tax if a a splits field have the same contents
            const updatedExpenseFields = getExpenseFieldsSummaryBasedOnSplits(cleanSplits, expenseUpdateData.taxRate, isTaxSynced)

            // Calculate expense's total tax price & total gross price
            const totalTaxPrice = totalTaxPriceOverride ?? expenseTaxPrice
            const taxRate = (totalTaxPriceOverride || totalTaxPriceOverride === 0) && isUniform.taxRate ? splits[0]?.taxRate : undefined
            const totalNetPrice = toAmount(toDinero(expense.totalGrossPrice).subtract(toDinero(totalTaxPrice)))

            // Save the splits
            const updatedExpense = new Expense({ ...expense, splits: cleanSplits, ...updatedExpenseFields, totalTaxPrice, totalNetPrice })
            if (taxRate) updatedExpense.taxRate = taxRate

            updatedExpense.splitManuallyAdjusted = isSplitManuallyAdjusted

            // Rebuild approval processes with splits change. Expenses inside folder do not change its aproval processes and follow the folder approval processes.
            if (!updatedExpense.folderId) {
                const approvalProcesses = await ApprovalProcessService.getApprovalProcessesAfterExpenseUpdate(updatedExpense, company, {
                    previousExpense: expense,
                    awaitConfirmation: true,
                })
                if (!approvalProcesses) return
                updatedExpense.approvalProcesses = approvalProcesses
            } else {
                const isFolderAmountChangeApproved = folder
                    ? await ApprovalProcessService.confirmFolderAmountChange(updatedExpense.totalGrossPrice - expense.totalGrossPrice, folder, company)
                    : true
                if (!isFolderAmountChangeApproved) return
            }
            if (isAtLeastReviewed(updatedExpense.status) && company.gobdCompliance?.enforceGobdCompliantInvoice) {
                const validation = await ExpenseHttpService.checkForExpenseGobdCompliance(expense.id, updatedExpense)
                if (validation.isCompliant && !(await DialogService.confirmExpenseGobdCompliantInvoiceFlagging())) {
                    return
                }
            }
            // TODO: Add handling for len > 1
            if (updatedExpense.splitType === SplitTypeEnum.SPLIT && updatedExpense.splits.length === 1) {
                const { netPrice, grossPrice, taxPrice } = updatedExpense.splits[0]
                updatedExpense.totalGrossPrice = grossPrice
                updatedExpense.totalNetPrice = netPrice
                updatedExpense.totalTaxPrice = taxPrice
            }

            await dispatch(updateExpense(expense.id, updatedExpense))

            setHasUnsavedChanges(false)
            NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:request.updated.title"), t("notification:request.updated.message"))
        } catch (err) {
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:request.edit.title"))
        } finally {
            setIsLoading(false)
            setIsExpenseUpdating?.(false)
            setIsEditing(false)
        }
    }

    const resetChanges = () => {
        setIsSplitManuallyAdjusted(expense.splitManuallyAdjusted ?? false)
        setSplits(expense.splits)
        setIsEditing(false)
        expenseForm.setFieldsValue({
            ...expense,
        })
        setHasUnsavedChanges(false)
    }

    const getSplitProblems = async (splitsToCheck: Array<ExpenseSplit>) => {
        if (!hasUnsavedChanges) {
            return []
        }
        const foundProblems: Array<string> = []
        try {
            await expenseForm.validateFields()
        } catch (err) {
            err.errorFields.forEach((errorField: any) => {
                foundProblems.push(...errorField.errors)
            })
        }

        if (areInvoiceSplits(splitsToCheck, expense) && roundNumberTo2Decimals(calculateSplitPercentageTotal(splitsToCheck)) !== 100)
            foundProblems.push(t("info:split_not_hundred_percent"))

        return foundProblems
    }

    const onSyncNetAndTax = () => {
        if (expense.splitType === SplitTypeEnum.SPLIT && totalSplitPercentage !== 100) {
            NotificationService.send(NotificationTypeEnum.ERROR, t("error:splits.unable_sync_tax.title"), t("error:splits.unable_sync_tax.message"))
            return
        }
        expenseForm.setFieldsValue(getExpenseFieldsSummaryBasedOnSplits(splits, expense.taxRate?._id, true))
        setExpenseTaxPrice(totalTaxPrice)
        setExpenseNetPrice(expense.totalGrossPrice - totalTaxPrice)
        setHasUnsavedChanges(true)
    }

    const unlockSplitAmounts = () => {
        const newSplits = adjustGrossAmountsIfNeeded(splits, expense)
        setSplits(newSplits)
        expenseForm.setFieldsValue({ splits: newSplits })
        setIsSplitManuallyAdjusted(true)
        setIsEditing(true)
    }

    const contextContent: MainDetailsSectionContextState = {
        // Base props
        expense,
        splits,
        isMinimized,
        openEditModal,
        setIsExpenseUpdating,
        showProbability,
        showLink,

        // Local states
        problems,
        hasUnsavedChanges,
        isSplitManuallyAdjusted,
        expenseTaxPrice,
        expenseNetPrice,
        isLoading,
        isEditing,

        // setters
        setProblems,
        setSplits,
        setHasUnsavedChanges,
        setIsSplitManuallyAdjusted,
        setExpenseTaxPrice,
        setExpenseNetPrice,
        setIsLoading,
        setIsEditing,

        // Derived
        disableSaveButton,
        isExpenseEditable,

        // antd expense form state
        expenseForm,

        // Action handlers
        onSplitUpdate,
        onPriceAdjustment,
        onAllSplitUpdate,
        onSplitDelete,
        onSave: () => {
            expenseForm.submit()
        },
        validateAndSave,
        saveSplitChanges,
        onSyncNetAndTax,
        unlockSplitAmounts,
        getSplitProblems,
        roundingErrors,
        horizontalErrors,
        verticalErrors,
        resetChanges,

        // Discount
        discountDisplay,
        setDiscountDisplay,
    }

    return <Context.Provider value={contextContent}>{children}</Context.Provider>
}

export default MainDetailsSectionContext
