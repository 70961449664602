import { RightEnum, Transaction, TransactionCategory, TransactionCategoryTypeEnum, TransactionTypeEnum } from "@finway-group/shared/lib/models"
import { Select, Tag, Tooltip } from "antd"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import { useTransactionCategoriesByType } from "Shared/hooks/transactionCategory.hooks"
import { AuthzService, NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { ThunkDispatchResult } from "Shared/store"
import { setShouldFetchCashFlowsData } from "Shared/store/actions/liquidity/liquidityActions"
import { updateTransactionCategoryField } from "Shared/store/actions/transaction/transactionActions"
import { doFilterSelect } from "Shared/utils/helper.utils"
import { getPopupAnchor } from "Shared/utils/popup.utils"

interface TransactionCategorySelectProps {
    transaction: Transaction
}

export const TransactionCategorySelect: React.FC<TransactionCategorySelectProps> = ({ transaction }) => {
    const { t } = useTranslation()

    const hasWriteAccess = AuthzService.isRightGrantedForLoggedInUser(RightEnum.TRANSACTION__ALL__UPDATE)

    const [selected, setSelected] = useState<string | undefined>(transaction.transactionCategoryId)
    const dispatch: ThunkDispatchResult = useDispatch()
    const transactionCategories = useTransactionCategoriesByType(transaction.transactionData.amount < 0 ? TransactionCategoryTypeEnum.OUTFLOW : TransactionCategoryTypeEnum.INFLOW)

    useEffect(() => {
        setSelected(transaction.transactionCategoryId)
    }, [transaction.id, transaction.transactionCategoryId])

    // Show tag if transaction cannot be categorized
    if (transaction.transactionType === TransactionTypeEnum.CSV_TRANSACTION || transaction.transactionType === TransactionTypeEnum.CARD_TRANSACTION) {
        return <Tag className="ant-tag-gray my-5">{t("info:not_categorizable")}</Tag>
    }

    const onClick = (event: React.MouseEvent<Element, MouseEvent>) => event.stopPropagation()

    const onChange = async (transactionCategoryId: string) => {
        try {
            setSelected(transactionCategoryId)
            await updateTransactionCategoryField(transaction.id, transactionCategoryId)(dispatch)
            setShouldFetchCashFlowsData(true)(dispatch)
            NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:transaction.update_category.title"), t("notification:transaction.update_category.message"))
        } catch (err) {
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:transaction:update_category:title"))
            setSelected(transaction.transactionCategoryId)
        }
    }

    const renderTransactionCategoryOptions = () =>
        transactionCategories.map((transactionCategory: TransactionCategory) => (
            <Select.Option disabled={transactionCategory.isGroup} label={transactionCategory.name} key={transactionCategory.id} value={transactionCategory.id}>
                <span className="pl-1">{transactionCategory.name}</span>
            </Select.Option>
        ))

    const categorizationDisabled = !transaction.transactionId || !hasWriteAccess
    return (
        <div className={`finway-transaction-category-select ${categorizationDisabled && "finway-transaction-category-select-disabled"}`}>
            <Tooltip getPopupContainer={getPopupAnchor()} title={categorizationDisabled ? t("label:transaction_categorization_disabled") : ""}>
                <Select
                    className="w-full"
                    placeholder={t("placeholder:transaction.transaction_category")}
                    showSearch
                    filterOption={doFilterSelect}
                    onClick={onClick}
                    onChange={onChange}
                    value={selected}
                    bordered={false}
                    disabled={categorizationDisabled}
                >
                    {renderTransactionCategoryOptions()}
                </Select>
            </Tooltip>
        </div>
    )
}

export default TransactionCategorySelect
