import { Reducer } from "redux"

import {
    CancelFetchAction,
    FetchApiVersionAction,
    FetcherActionTypes,
    FetcherActions,
    LoadedAction,
    ResetFetchFlagAction,
    StartLoadingAction,
} from "../actions/fetcher/fetcherTypes"

export interface FetcherState {
    apiVersion: any
    hasLoaded: boolean
    isCancelled: boolean
}

const initialState: FetcherState = {
    apiVersion: undefined,
    hasLoaded: false,
    isCancelled: false,
}

const handleLoadingObject = (state: FetcherState, action: LoadedAction | StartLoadingAction) => ({
    ...state,
    hasLoaded: action.hasLoaded,
})

const fetchApiVersion = (state: FetcherState, action: FetchApiVersionAction): FetcherState => ({
    ...state,
    apiVersion: action.apiVersion,
})

const cancelFetch = (state: FetcherState, action: CancelFetchAction): FetcherState => ({
    ...state,
    isCancelled: action.isFetchCancelled,
})

const resetFetchFlag = (state: FetcherState, action: ResetFetchFlagAction): FetcherState => ({
    ...state,
    isCancelled: action.isCancelled,
})

export const fetcherReducer: Reducer<FetcherState, FetcherActions> = (state = initialState, action) => {
    switch (action.type) {
        case FetcherActionTypes.FETCH_API_VERSION:
            return fetchApiVersion(state, action)
        case FetcherActionTypes.START_LOADING:
            return handleLoadingObject(state, action)
        case FetcherActionTypes.LOADED:
            return handleLoadingObject(state, action)
        case FetcherActionTypes.CANCEL_FETCH:
            return cancelFetch(state, action)
        case FetcherActionTypes.RESET_FETCH_FLAG:
            return resetFetchFlag(state, action)
        default:
            return state
    }
}
