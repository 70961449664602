import { Company } from "@finway-group/shared/lib/models"
import { Input, Menu } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"

import CompanyLogo from "Components/company/CompanyLogo"
import { KeySelector, useSearch } from "Shared/hooks/search.hooks"

interface SubCompanyListProps {
    userCompanies: Array<Company>
    keySelector: KeySelector<Company>
    onCompanySelect: (company: Company) => void
}

export const SubCompanyList = ({ userCompanies, keySelector, onCompanySelect, ...antdProps }: SubCompanyListProps) => {
    const { t } = useTranslation()
    const { searchTerm, searchResults, handleInputChange, handleInputClick } = useSearch(userCompanies, keySelector)

    return (
        <div className="flex flex-col min-w-200">
            <div className="px-12 pb-5 pt-2">
                <Input type="text" placeholder={t("action:inbox_invoice.search")} value={searchTerm} onChange={handleInputChange} onClick={handleInputClick} />
            </div>

            {searchResults.map((company) => (
                <Menu.Item key={company._id} {...antdProps}>
                    <div className="flex items-center gap-8" onClick={() => onCompanySelect(company)}>
                        <CompanyLogo {...company} />
                        <span className="text-text leading-none">{keySelector(company)}</span>
                    </div>
                </Menu.Item>
            ))}
        </div>
    )
}
