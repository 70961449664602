import React from "react"

export const LaptopIcon = () => (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M13.9572 11.3001C13.9131 11.2381 13.8416 11.2012 13.7653 11.2012H8.75414C8.71768 11.2012 8.68169 11.2096 8.64913 11.2258L8.27886 11.4099C8.24654 11.4261 8.21008 11.4345 8.17386 11.4345H5.82614C5.78992 11.4345 5.75346 11.4261 5.72114 11.4099L5.35087 11.2258C5.31831 11.2096 5.28232 11.2012 5.24586 11.2012H0.23473C0.158383 11.2012 0.086851 11.2381 0.0428313 11.3001C-0.000959026 11.3618 -0.011964 11.4411 0.0134849 11.5127C0.146461 11.8845 0.50114 12.1345 0.895941 12.1345H13.1041C13.4989 12.1345 13.8535 11.8845 13.9865 11.5127C14.012 11.4411 14.001 11.3618 13.9572 11.3001Z"
            fill="#606C7E"
        />
        <path
            d="M0.379294 10.7339H13.6205C13.6984 10.7339 13.7711 10.6953 13.8149 10.6313C13.8585 10.5673 13.8674 10.4857 13.8385 10.4139L13.0666 8.58885V2.80052C13.0666 2.28577 12.6828 1.86719 12.1649 1.86719H1.83492C1.317 1.86719 0.933229 2.28577 0.933229 2.80052V8.58885L0.161259 10.4139C0.132371 10.4857 0.141313 10.5673 0.184874 10.6313C0.228664 10.6953 0.301343 10.7339 0.379294 10.7339ZM12.1332 2.80052V8.40052H1.86656V2.80052H12.1332Z"
            fill="#606C7E"
        />
    </svg>
)
