import { ContentState } from "draft-js"
import * as React from "react"

interface LinkProps {
    contentState: ContentState
    entityKey: string
}

const Link: React.FC<LinkProps> = (props) => {
    const data = props.contentState.getEntity(props.entityKey).getData()
    const { url } = data
    if (!url) {
        return <span>{props.children}</span>
    }
    return (
        <a href={url} title={url}>
            {props.children}
        </a>
    )
}

export default Link
