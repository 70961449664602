import axios from "axios"

const MODEL = "export-history"

const ExportHistoryService = {
    fetchAllExportHistory: async (queryParams: any, source: any, timeout?: number) => {
        const { data } = await axios.get(
            `/${MODEL}?page=${queryParams.page}&limit=${queryParams.limit}${queryParams.filterQueryString}${queryParams.searchQueryString}${queryParams.sortQueryString}`,
            {
                cancelToken: source.token,
                timeout,
            },
        )
        return data
    },
}

export default ExportHistoryService
