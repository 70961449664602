import { ApprovalScopeEnum, ApprovalStatusEnum, Expense, RightEnum, TripFolder } from "@finway-group/shared/lib/models"
import { getApprovalProcess } from "@finway-group/shared/lib/utils"
import { Empty } from "antd"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import NoSearchDataFound from "Components/NoSearchDataFound"
import Loading from "Components/loading"
import RejectModal from "Components/modals/rejectModal"
import { TodoTable } from "Components/tables/todoTable"
import { approveExpenseRequest, approveFolderRequest, cancelExpenseRequest, handleTripFolderSubmit, sendEmailReminder } from "Shared/hooks/expense.hooks"
import { useFetchTable, useTable, useTableSearchString, useTodoFilterAndSearchTabLoading } from "Shared/hooks/table.hooks"
import { ApprovalProcessService, AuthzService, EmployeeService, UserService } from "Shared/services"
import { initializeApprovalProcess, submitFolder } from "Shared/store/actions/expense/expenseActions"
import { refetchTable, updateTableOnChange } from "Shared/store/actions/tables/tableActions"
import { TablesEnum } from "Shared/store/reducers/tableConfigReducer"
import { isFolderExpense } from "Shared/utils/expense.utils"

interface TodoTabInterface {}
const TodoTab: React.FC<TodoTabInterface> = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const loggedInEmployee = UserService.getLoggedInEmployeeProfile()
    const employees = EmployeeService.getEmployees(true)
    const [isRejectModalVisible, setIsRejectModalVisible] = useState(false)
    const [selectedRequest, setSelectedRequest] = useState(new Expense())

    const tabLoading = useTodoFilterAndSearchTabLoading()

    const draftFolderTable = useTable<TripFolder>(TablesEnum.TRIP_FOLDER_DRAFT)
    const purchaseApprovalTable = useTable<Expense>(TablesEnum.TODO_PURCHASE_APPROVAL)
    const invoiceApprovalTable = useTable<Expense>(TablesEnum.TODO_INVOICE_APPROVAL)
    const taggedTable = useTable<Expense>(TablesEnum.TODO_INVOICE_AND_PURCHASE)
    const docsNeededTable = useTable<Expense>(TablesEnum.TODO_DOCS_NEEDED)
    const approvalPendingTable = useTable<Expense>(TablesEnum.TODO_APPROVAL_PENDING)
    const docsLength = [draftFolderTable, purchaseApprovalTable, invoiceApprovalTable, taggedTable, docsNeededTable, approvalPendingTable].reduce(
        (totalDocs, table) => totalDocs + table.data.totalDocs,
        0,
    )

    const dashboardSearchString = useTableSearchString(TablesEnum.DONE_REQUESTS)
    const canApprove = AuthzService.isLoggedInUserPotentialApprover()
    const showTagTable = false // TODO: Check if we are going to delete this table completely.
    const isAllowedToCreateExpenses = AuthzService.isLoggedInUserAllowedToCreateExpenses()

    useFetchTable(TablesEnum.TRIP_FOLDER_DRAFT)
    useFetchTable(TablesEnum.TODO_PURCHASE_APPROVAL)
    useFetchTable(TablesEnum.TODO_INVOICE_APPROVAL)
    useFetchTable(TablesEnum.TODO_DOCS_NEEDED)
    useFetchTable(TablesEnum.TODO_APPROVAL_PENDING)
    useFetchTable(TablesEnum.TODO_INVOICE_AND_PURCHASE)

    const handleApproveRequest = async (event: React.MouseEvent<HTMLElement>, request: Expense, approvalScope: ApprovalScopeEnum) => {
        event.stopPropagation()
        const nextPendingApprover = ApprovalProcessService.getNextPendingApprover(request.approvalProcesses)

        const approvalProcess = getApprovalProcess(approvalScope, request.approvalProcesses)
        if (approvalProcess?.status === ApprovalStatusEnum.NOT_STARTED) {
            if (isFolderExpense(request)) {
                await dispatch(submitFolder(request.id))
                return
            }
            await dispatch(initializeApprovalProcess(request.id, approvalScope))
            return
        }
        if (isFolderExpense(request)) {
            await approveFolderRequest(request, approvalScope, loggedInEmployee, nextPendingApprover?.getFullName())
            return
        }
        await approveExpenseRequest(request, approvalScope, loggedInEmployee, nextPendingApprover?.getFullName())
    }

    const handleReject = (event: React.MouseEvent<HTMLElement>, request: Expense) => {
        event.stopPropagation()
        setIsRejectModalVisible(true)
        setSelectedRequest(request)
    }

    const handleSendReminder = async (event: React.MouseEvent<HTMLElement>, request: Expense) => {
        event.stopPropagation()
        await sendEmailReminder(employees, request)
    }

    const handleCancel = (event: React.MouseEvent<HTMLElement>, request: Expense, cancelPopupConfirm = true) => {
        event.stopPropagation()
        cancelExpenseRequest(request, cancelPopupConfirm)
    }

    const handleFolderSubmit = (event: React.MouseEvent<HTMLElement>, request: Expense) => {
        event.stopPropagation()
        handleTripFolderSubmit(request as TripFolder)
    }

    const handleFolderDelete = (event: React.MouseEvent<HTMLElement>, request: Expense) => handleCancel(event, request, true)

    const rejectModal = (
        <RejectModal
            isVisible={isRejectModalVisible}
            title={t("notification:rejection_note.title")}
            message={t("notification:rejection_note.message")}
            cancelText={t("notification:rejection_note.cancel")}
            okText={t("notification:rejection_note.confirm")}
            request={selectedRequest}
            onCancel={() => setIsRejectModalVisible(false)}
        />
    )

    return (
        <div>
            {selectedRequest ? rejectModal : <></>}

            <div>
                {tabLoading ? (
                    <Loading />
                ) : docsLength === 0 ? (
                    dashboardSearchString ? (
                        <div className="animation-appear">
                            <NoSearchDataFound />
                        </div>
                    ) : (
                        <Empty
                            className="animation-appear"
                            image={`./icons/empty_table.svg`}
                            imageStyle={{ height: 150, marginRight: "auto", marginLeft: "auto", marginTop: "15%", marginBottom: "40px", display: "inline-block" }}
                            description={<span className="text-lg font-bold mt-2 mb-0">{t("info:empty_state.requests.title")}</span>}
                        >
                            <p>{t("info:empty_state.requests.message")}</p>
                        </Empty>
                    )
                ) : (
                    <section className="animation-appear">
                        {
                            <>
                                {draftFolderTable.data?.docs.length > 0 && (
                                    <div className="mb-24 animation-appear">
                                        <h2>{t("label:draft_trip_folder")}</h2>
                                        <TodoTable
                                            table={draftFolderTable}
                                            onTableReload={() => dispatch(refetchTable(TablesEnum.TRIP_FOLDER_DRAFT))}
                                            onTableChange={updateTableOnChange(TablesEnum.TRIP_FOLDER_DRAFT, draftFolderTable.data)}
                                            requestButtonProps={{ onSubmitFolder: handleFolderSubmit, onDeleteFolder: handleFolderDelete }}
                                            showActionButtons={AuthzService.isRightGrantedForLoggedInUser(RightEnum.EXPENSE__ALL__APPROVE_REJECT_DASHBOARD)}
                                        />
                                    </div>
                                )}
                                {(canApprove || !!purchaseApprovalTable.data.totalDocs) && (
                                    <div className=" mb-24 animation-appear">
                                        <h2>{t("label:approval_needed_purchase")}</h2>
                                        <TodoTable
                                            showActionButtons={AuthzService.isRightGrantedForLoggedInUser(RightEnum.EXPENSE__ALL__APPROVE_REJECT_DASHBOARD)}
                                            table={purchaseApprovalTable}
                                            onTableReload={() => dispatch(refetchTable(TablesEnum.TODO_PURCHASE_APPROVAL))}
                                            onTableChange={updateTableOnChange(TablesEnum.TODO_PURCHASE_APPROVAL, purchaseApprovalTable.data)}
                                            requestButtonProps={{ onApprove: handleApproveRequest, onReject: handleReject }}
                                        />
                                    </div>
                                )}

                                {(canApprove || !!invoiceApprovalTable.data.totalDocs) && (
                                    <div className="mb-24 animation-appear">
                                        <h2>{t("label:approval_needed_invoice")}</h2>
                                        <TodoTable
                                            table={invoiceApprovalTable}
                                            onTableReload={() => dispatch(refetchTable(TablesEnum.TODO_INVOICE_APPROVAL))}
                                            onTableChange={updateTableOnChange(TablesEnum.TODO_INVOICE_APPROVAL, invoiceApprovalTable.data)}
                                            requestButtonProps={{ onApprove: handleApproveRequest, onReject: handleReject }}
                                            showActionButtons={isAllowedToCreateExpenses}
                                        />
                                    </div>
                                )}
                            </>
                        }

                        <div className="mb-24 animation-appear">
                            <h2>{t("label:documents_needed")}</h2>
                            <TodoTable
                                table={docsNeededTable}
                                showActionButtons={isAllowedToCreateExpenses}
                                onTableReload={() => dispatch(refetchTable(TablesEnum.TODO_DOCS_NEEDED))}
                                onTableChange={updateTableOnChange(TablesEnum.TODO_DOCS_NEEDED, docsNeededTable.data)}
                                requestButtonProps={{ onSendReminder: handleSendReminder, onCancel: handleCancel }}
                            />
                        </div>

                        <div className="mb-24 animation-appear">
                            <h2>{t("label:approval_pending")}</h2>
                            <TodoTable
                                table={approvalPendingTable}
                                showActionButtons={isAllowedToCreateExpenses}
                                onTableReload={() => dispatch(refetchTable(TablesEnum.TODO_APPROVAL_PENDING))}
                                onTableChange={updateTableOnChange(TablesEnum.TODO_APPROVAL_PENDING, approvalPendingTable.data)}
                                requestButtonProps={{ onSendReminder: handleSendReminder, onCancel: handleCancel }}
                            />
                        </div>
                        {showTagTable && (
                            <div className="mb-24 animation-appear">
                                <h2>{t("label:tagged")}</h2>

                                <TodoTable
                                    table={taggedTable}
                                    showActionButtons={false}
                                    onTableChange={updateTableOnChange(TablesEnum.TODO_INVOICE_AND_PURCHASE, taggedTable.data)}
                                    onTableReload={() => dispatch(refetchTable(TablesEnum.TODO_INVOICE_AND_PURCHASE))}
                                ></TodoTable>
                            </div>
                        )}
                    </section>
                )}
            </div>
        </div>
    )
}

export default TodoTab
