import { DocumentChangeInterface, InboxInvoice, OperationTypeEnum } from "@finway-group/shared/lib/models"
import * as Sentry from "@sentry/react"

import i18n from "Shared/locales/i18n"
import store from "Shared/store"
import { fetchDashboardExpensesCounts } from "Shared/store/actions/expense/expenseActions"
import { fetchAllInboxInvoices, fetchDashboardInboxInvoicesCount, syncInvoiceInbox } from "Shared/store/actions/inboxInvoice/inboxInvoiceActions"
import { refetchExpenseTables, refetchInboxInvoiceTables } from "Shared/store/actions/tables/tableActions"
import { isClientErrorCode } from "Shared/utils/errorHandler.utils"

import NotificationService, { NotificationTypeEnum } from "../notification.service"

export const subscribeToInboxInvoices = (dispatch: any, data: DocumentChangeInterface) => {
    const {
        fullDocument: { documentId, operationType },
    } = data

    try {
        switch (operationType) {
            case OperationTypeEnum.INSERT:
            case OperationTypeEnum.DELETE:
            case OperationTypeEnum.BULK_INSERT:
            case OperationTypeEnum.BULK_DELETE:
                dispatch(fetchAllInboxInvoices())
                dispatch(refetchInboxInvoiceTables())
                dispatch(fetchDashboardInboxInvoicesCount())
                break
            case OperationTypeEnum.UPDATE:
                onUpdate(dispatch, documentId)
                dispatch(fetchAllInboxInvoices())
                dispatch(refetchInboxInvoiceTables()) // TODO: improve
                break
            default:
                break
        }
    } catch (err) {
        if (isClientErrorCode(err)) return
        Sentry.captureException(`[Inbox Invoice Socket] Error: ${typeof err === "string" ? err : JSON.stringify(err)}`)
    }
}

export const subscribeToInboxInvoiceCallbacks = (dispatch: any, data: DocumentChangeInterface) => {
    // prettier-ignore
    const { fullDocument: { documentId, operationType, metaData } } = data;

    if (operationType === OperationTypeEnum.UPDATE) {
        onUpdate(dispatch, documentId)
        dispatch(fetchAllInboxInvoices())
        dispatch(refetchInboxInvoiceTables()) // TODO: improve
        dispatch(fetchDashboardInboxInvoicesCount())
    } else if (operationType === OperationTypeEnum.INVOICE_AUTO_MATCH) {
        const { expenseNumber, fileName } = metaData as any
        NotificationService.send(
            NotificationTypeEnum.INFO,
            i18n.t("notification:invoice_inbox.auto_matched.title"),
            i18n.t("notification:invoice_inbox.auto_matched.message", { expenseNumber, fileName }),
            0,
        )
        dispatch(refetchInboxInvoiceTables()) // TODO: improve
        dispatch(fetchDashboardInboxInvoicesCount())
        dispatch(refetchExpenseTables())
        dispatch(fetchDashboardExpensesCounts())
    }
}

const onUpdate = (dispatch: any, documentId: any) => {
    const inboxState = store.getState().inboxInvoices
    // for updates, refetch if it's present in the store
    const inboxInvoice = inboxState.item?.id === documentId ? inboxState.item : inboxState.items.find(({ id }: InboxInvoice) => id === documentId)
    if (inboxInvoice) dispatch(syncInvoiceInbox(documentId.toString()))
}
