import { Team } from "@finway-group/shared/lib/models"
import { ActionCreator, Dispatch } from "redux"

import { TeamService } from "Shared/services"
import { ThunkResult } from "Shared/store"

import { CancelFetchAction, FetcherActionTypes } from "../fetcher/fetcherTypes"
import { FetchAllTeamsAction, TeamTypes, UpdateAllTeamsAction } from "./teamTypes"

export const fetchAllTeams: ActionCreator<ThunkResult<Array<Team>>> = () => async (dispatch: Dispatch) => {
    const teams = await TeamService.fetchTeams({})
    const cancelFetchAction: CancelFetchAction = {
        type: FetcherActionTypes.CANCEL_FETCH,
        isFetchCancelled: true,
    }

    dispatch(cancelFetchAction)

    const fetchAllTeams: FetchAllTeamsAction = {
        type: TeamTypes.FETCH_ALL_TEAMS,
        teams,
    }

    dispatch(fetchAllTeams)
    return teams
}

export const updateTeams = (updatedTeams: Array<Team>) => async (dispatch: Dispatch) => {
    const teams = await TeamService.updateAllTeams(updatedTeams)
    const updateAllTeamsAction: UpdateAllTeamsAction = {
        type: TeamTypes.UPDATE_ALL_TEAMS,
        teams,
    }

    dispatch(updateAllTeamsAction)
    return teams
}
