import { CurrencyEnum, Employee, Expense, ExpenseKindEnum, ExpenseSplit, InvoiceSplit, ItemSplit, OcrResult, SplitTypeEnum } from "@finway-group/shared/lib/models"
import { ExpensePaymentOptionEnum } from "@finway-group/shared/lib/models/expense/expensePaymentOption.enum"
import { OcrResultItem } from "@finway-group/shared/lib/models/ocr/ocr.model"
import { UserLanguageEnum } from "@finway-group/shared/lib/models/user/userSettings.interface"
import moment from "moment"
import * as stringSimilarity from "string-similarity"

import { DE_DATE_FORMAT, NO_TAX_RATE, US_DATE_FORMAT } from "Shared/config/consts"
import { isHospitalityExpense } from "Shared/utils/expense.utils"
import { capitalizeFirstLetter, formatCurrencyNumber, isEmptyObject, isSet } from "Shared/utils/helper.utils"

import {
    CompareDataParams,
    CompareFormOcrDataParams,
    CompareSplitsDataParams,
    HospitalityWithLabeledAttendees,
    MapSplitsParams,
    OcrObjectKeys,
    OcrObjectSplitsKeys,
    RowInterface,
    SplitDataType,
    UpdateOcrItemsParams,
    UpdatedOcrObject,
} from "./ocr.utils.types"
import { checkVendorSimilarity } from "./vendor.utils"

export const itemFieldsToBeCompared: Array<OcrObjectSplitsKeys> = ["name", "netPrice", "grossPrice", "taxRate", "taxPrice"]

export const formatOCRObject = (ocrObject: OcrResult) => {
    const ocrObjectKeys = Object.keys(ocrObject) as Array<OcrObjectKeys>

    const newOcrObject = ocrObject as UpdatedOcrObject

    ocrObjectKeys.forEach((ocrKey) => {
        switch (ocrKey) {
            case "invoiceDate":
                newOcrObject[ocrKey] = newOcrObject[ocrKey] ? moment(newOcrObject[ocrKey]) : undefined
                break
            case "invoiceDueDate":
                newOcrObject[ocrKey] = moment(newOcrObject[ocrKey])
                break
            case "performancePeriod":
                newOcrObject[ocrKey] = newOcrObject[ocrKey]?.map((date) => moment(date))
                break
            case "datePurchased":
                newOcrObject[ocrKey] = moment(newOcrObject[ocrKey])
        }
    })

    return newOcrObject
}

export const formatFieldValue = ({
    field,
    value,
    currency,
    userLanguage = UserLanguageEnum.DE,
}: {
    field: OcrObjectKeys | OcrObjectSplitsKeys
    value: any
    currency: CurrencyEnum
    userLanguage?: UserLanguageEnum
}) => {
    if (!isSet(value) && field !== "taxRate") return ""

    switch (field) {
        case "invoiceNumber":
            return `#${value}`
        case "invoiceDate":
            return formatOcrDate(value, userLanguage)
        case "invoiceDueDate":
            return formatOcrDate(value, userLanguage)
        case "totalNetPrice":
            return formatCurrencyNumber(Number(value), currency)
        case "totalTaxPrice":
            return formatCurrencyNumber(Number(value), currency)
        case "totalGrossPrice":
            return formatCurrencyNumber(Number(value), currency)
        case "taxRate":
            if (!value || value._id === NO_TAX_RATE) return "-"
            return `${value.taxRate}%`
        case "taxPrice":
            return formatCurrencyNumber(Number(value), currency)
        case "grossPrice":
            return formatCurrencyNumber(Number(value), currency)
        case "netPrice":
            return formatCurrencyNumber(Number(value), currency)
        case "performancePeriod":
            if (value.includes(undefined) || !value.length) return "-"
            return `${formatOcrDate(value[0], userLanguage)} - ${formatOcrDate(value[1], userLanguage)}`
        case "datePurchased":
            return formatOcrDate(value, userLanguage)
        case "name":
            return capitalizeFirstLetter(value)
        case "vendor":
            return value.name
        default:
            return String(value)
    }
}

export const formatOcrDate = (value: string | Date, userLanguage: UserLanguageEnum) => moment(value).format(userLanguage === UserLanguageEnum.DE ? DE_DATE_FORMAT : US_DATE_FORMAT)

export const compareSplits = ({ originalSplits, ocrItems, t, newCurrency, originalCurrency }: MapSplitsParams): Array<RowInterface> => {
    const ocrItemsNames = ocrItems.map((item: ItemSplit) => item.name?.toLowerCase())

    const splitsData: Array<RowInterface> = originalSplits.map((originalSplit, itemIndex) => {
        let item: RowInterface = {} as RowInterface

        const ocrBestMatching = ocrItemsNames.length && "name" in originalSplit ? stringSimilarity.findBestMatch(originalSplit.name?.toLowerCase(), ocrItemsNames) : undefined
        const ocrBestMatchingName = ocrBestMatching?.bestMatch?.target?.toLowerCase()
        const ocrBestMatchingRating = ocrBestMatching?.bestMatch?.rating ?? 0

        for (const ocrItem of ocrItems) {
            const areSplitsMatching = ocrItem?.name?.toLowerCase() === ocrBestMatchingName && ocrBestMatchingRating >= 0.6

            // if the OCR split is matching with an original split, they should be compared
            if (areSplitsMatching) {
                const itemParent = compareSplitsData({ field: "itemParent", originalSplit, ocrItem, newCurrency, originalCurrency, itemIndex, t })

                const itemChildren: Array<RowInterface> = []

                for (const field of itemFieldsToBeCompared) {
                    const comparedData = compareSplitsData({ field, originalSplit, ocrItem, newCurrency, originalCurrency, itemIndex, t })
                    if (comparedData) itemChildren.push(comparedData)
                }

                if (itemChildren.length) {
                    if (itemParent) item = { ...itemParent, children: itemChildren }
                }
            }
        }

        return { ...item }
    })

    return splitsData
}

export const compareSplitsData = ({ field, originalSplit, ocrItem, newCurrency, originalCurrency, itemIndex, t }: CompareSplitsDataParams): RowInterface | void => {
    let originalData: any = originalSplit[field as keyof ExpenseSplit]
    let ocrData: any = ocrItem[field as keyof ExpenseSplit]

    if (field === "itemParent") {
        originalData = "name" in originalSplit ? originalSplit.name : ""
        ocrData = ""
    }

    const formattedOcrData = formatFieldValue({ field, value: ocrData, currency: newCurrency })
    const formattedOriginalData = formatFieldValue({ field, value: originalData, currency: originalCurrency })
    const formattedField = t(`info:ocr_updates.fields_names.${field}`)

    const isDifferent = compareOcrAndOriginalData(formattedOriginalData, formattedOcrData)

    if (originalData !== undefined && ocrData !== undefined && isDifferent) {
        return {
            key: itemIndex + field,
            field,
            ocrData,
            itemIndex,
            formattedOriginalData,
            formattedOcrData,
            formattedField,
            split: true,
        }
    }
}

export const compareData = (
    { field, expense, ocrObject, vendors, newCurrency, originalCurrency, t, isHospitality }: CompareDataParams,
    userLanguage: UserLanguageEnum | undefined,
): RowInterface | void => {
    let originalData = expense[field as keyof Expense]
    let ocrData: any = ocrObject[field as keyof OcrResult]
    let formattedField = t(`info:ocr_updates.fields_names.${field}`)

    if (field === "performancePeriod") {
        originalData = originalData || [expense.performancePeriodStartDate, expense.performancePeriodEndDate]
    }

    if (field === "datePurchased") {
        formattedField = t(`info:ocr_updates.fields_names.${isHospitality ? "hospitalityDate" : "performanceDate"}`)
    }

    if (field === "vendor") {
        const expenseCreditorName = expense.getCreditorName()
        const foundVendor = ocrObject.vendor?.name ? checkVendorSimilarity(vendors, ocrObject.vendor?.name) : false

        if (expenseCreditorName) originalData = { name: expenseCreditorName }
        if (foundVendor) ocrData = { name: foundVendor.name }
    }

    if (field === "merchant") {
        ocrData = ocrData || ocrObject.vendor?.name
    }

    if (field === "taxRate") {
        // if the tax rate of all items is the same and this tax rate already exists
        // the tax rate should be displayed in the modal, else "-" should be displayed
        const firstSplitTaxRate = ocrObject.splits && ocrObject.splits?.[0]?.taxRate?.taxRate

        const isItemsTaxRateSame = ocrObject.splits && ocrObject.splits?.length > 0 ? ocrObject.splits.every((s) => s.taxRate?.taxRate === firstSplitTaxRate) : true

        const shouldDisplayTotalTaxRate = isItemsTaxRateSame && ocrObject.taxRate

        originalData = expense.taxRate
        ocrData = shouldDisplayTotalTaxRate ? ocrObject.taxRate : { _id: NO_TAX_RATE }
    }

    const formattedOcrData = formatFieldValue({ field, value: ocrData, currency: newCurrency, userLanguage })
    const formattedOriginalData = formatFieldValue({ field, value: originalData, currency: originalCurrency, userLanguage })

    const isDifferent = compareOcrAndOriginalData(formattedOriginalData, formattedOcrData)

    if (isSet(ocrData) && isDifferent) {
        return {
            key: field,
            field,
            ocrData,
            formattedOriginalData,
            formattedOcrData,
            formattedField,
        }
    }
}

export const compareFormOcrData = ({ expense, ocrObject, vendors, t }: CompareFormOcrDataParams, loggedInUserLanguage: UserLanguageEnum) => {
    const originalCurrency = expense.currency ?? CurrencyEnum.EUR
    const newCurrency = ocrObject.currency ?? originalCurrency

    const isAutoGeneratedCardRequest = expense.isAutoGenerated && expense.paymentOption === ExpensePaymentOptionEnum.SMART_CARD
    const isHospitality = isHospitalityExpense(expense)

    // Set the invoice and vendor fields that need to be compared
    const invoiceAndVendorFieldsToBeCompared: Array<OcrObjectKeys> = ["invoiceNumber", "currency", "invoiceDate", "invoiceDueDate", "vendor", "performancePeriod", "datePurchased"]

    // For autoGenerated card requests we disregard the OCR amount prices and tax rate
    if (!isAutoGeneratedCardRequest) {
        invoiceAndVendorFieldsToBeCompared.push("totalNetPrice", "totalTaxPrice", "totalGrossPrice", "taxRate")
    }
    if (isHospitality) {
        invoiceAndVendorFieldsToBeCompared.push("merchant")
    }

    const formOCRDataDifferences: Array<RowInterface> = []

    const originalSplits = expense.splits
    const ocrItems = ocrObject.splits as Array<ItemSplit>

    const shouldUpdateItems = ocrItems && expense.kind === (isHospitality ? ExpenseKindEnum.HOSPITALITY : ExpenseKindEnum.ONE_TIME_EXPENSE)

    if (shouldUpdateItems) {
        const splits = compareSplits({ originalSplits, ocrItems, t, newCurrency, originalCurrency }).filter((item) => !isEmptyObject(item))
        if (splits?.length) {
            formOCRDataDifferences.push(...splits)
        }
    }

    for (const field of invoiceAndVendorFieldsToBeCompared) {
        const comparedData = compareData({ field, expense, ocrObject, vendors, newCurrency, originalCurrency, t, isHospitality }, loggedInUserLanguage)
        if (comparedData) formOCRDataDifferences.push(comparedData)
    }

    if (formOCRDataDifferences.length === 0) return

    const sortedDataDifference = formOCRDataDifferences.sort((a, _b) => (a.field === "itemParent" ? 1 : -1))

    return sortedDataDifference
}

export const mergeExistingAndOcrItems = (existingItems: Array<ExpenseSplit>, ocrItems: Array<OcrResultItem>) => {
    const noEmptyExistingItems = existingItems.filter((item) => item.netPrice !== 0)
    const expenseItemsNames = noEmptyExistingItems.map((split: ItemSplit) => split.name?.toLowerCase())
    const notRepeatedOcrDataItems = ocrItems.filter((item: ItemSplit) => !expenseItemsNames.includes(item.name?.toLowerCase()))

    return [...noEmptyExistingItems, ...notRepeatedOcrDataItems]
}

export const compareOcrAndOriginalData = (originalData: string, ocrData: string) => originalData?.toLowerCase() !== ocrData?.toLowerCase()

export const updateOcrItems = ({ ocrItemizationEnabled, ocrObject, expense, isHospitality }: UpdateOcrItemsParams): SplitDataType => {
    const shouldUpdateItems = ocrItemizationEnabled && ocrObject.splits && expense.kind === (isHospitality ? ExpenseKindEnum.HOSPITALITY : ExpenseKindEnum.ONE_TIME_EXPENSE)

    if (shouldUpdateItems) {
        return {
            splitType: SplitTypeEnum.ITEM,
            splits: ocrObject.splits?.map((itemSplit: ItemSplit) => ({
                ...itemSplit,
                name: itemSplit.name ?? "Item",
            })),
        }
    }

    if (expense.splitType === SplitTypeEnum.ITEM) {
        return undefined
    }
    return {
        splits: expense.splits?.map((split: InvoiceSplit) => ({
            ...split,
            netPrice: (ocrObject.totalNetPrice ?? expense.totalNetPrice ?? 0) * (split.percentage / 100),
            taxPrice: (ocrObject.totalTaxPrice ?? expense.totalTaxPrice ?? 0) * (split.percentage / 100),
            taxRate: ocrObject.taxRate ? (ocrObject.taxRate as InvoiceSplit["taxRate"]) : expense.taxRate ? expense.taxRate : { _id: NO_TAX_RATE },
            grossPrice: (ocrObject.totalGrossPrice ?? expense.totalGrossPrice ?? 0) * (split.percentage / 100),
        })),
    }
}

export const updatingAttendees = (existingData: Expense | HospitalityWithLabeledAttendees, requester?: Employee) => {
    const areExistingAttendees = "attendees" in existingData && existingData.attendees?.length > 0

    if (!areExistingAttendees && !requester) return

    if (areExistingAttendees && !requester) return existingData.attendees

    if (requester) {
        const requesterObject = { value: requester.id, label: requester.name }

        if (areExistingAttendees) {
            const containsRequester = existingData.attendees.find(({ value }) => value === requesterObject.value)
            return containsRequester ? existingData.attendees : [...existingData.attendees, requesterObject]
        }

        return [requesterObject]
    }

    return undefined
}

// For autoGenerated card requests we disregard the OCR total amount prices & splits
export const formatOcrObjectForAutoGeneratedCardRequests = (ocrObject: OcrResult) => {
    const fieldsNotToBeUpdatedOnAutoGeneratedCardRequest = ["totalNetPrice", "totalGrossPrice", "totalTaxPrice", "splits", "taxRate"]
    const ocrObjectToFormat = { ...ocrObject }

    for (const field of fieldsNotToBeUpdatedOnAutoGeneratedCardRequest) {
        delete ocrObjectToFormat[field as keyof OcrResult]
    }
    return formatOCRObject(ocrObjectToFormat)
}
