import { FormInstance, Rule } from "antd/lib/form"
import { Moment } from "moment"

import { TablesEnum } from "../../../shared/store/reducers/tableConfigReducer"
import { checkMaxDate, checkMaxNumber, checkMinDate, checkMinNumber } from "./utils.rules"

export const generateRules = (formInstance: FormInstance, table: TablesEnum): { [key: string]: Array<Rule> } => ({
    minTransactionAmount: [
        {
            validator: (_rule: any, value: number | string) => {
                const numberOptions = { arePositiveNumbersAllowed: table !== TablesEnum.TRANSACTIONS_OUTFLOW, areNegativeNumbersAllowed: table !== TablesEnum.TRANSACTIONS_INFLOW }

                return checkMinNumber({
                    formInstance,
                    value,
                    name: "maxTransactionAmount",
                    numberOptions,
                })
            },
        },
    ],
    maxTransactionAmount: [
        {
            validator: (_rule: any, value: number | string) => {
                const numberOptions = { arePositiveNumbersAllowed: table !== TablesEnum.TRANSACTIONS_OUTFLOW, areNegativeNumbersAllowed: table !== TablesEnum.TRANSACTIONS_INFLOW }

                return checkMaxNumber({ formInstance, value, name: "minTransactionAmount", numberOptions })
            },
        },
    ],
    minTransactionDate: [
        {
            validator: (_rule: any, value: Moment) => checkMinDate(formInstance, value, "maxTransactionDate"),
        },
    ],
    maxTransactionDate: [
        {
            validator: (_rule: any, value: Moment) => checkMaxDate(formInstance, value, "minTransactionDate"),
        },
    ],
})
