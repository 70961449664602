import { LoadingOutlined } from "@ant-design/icons"
import { Expense, ExpenseKindEnum, ExpenseStatusEnum, TripFolder } from "@finway-group/shared/lib/models"
import { Row, Table, Tooltip } from "antd"
import { ColumnsType } from "antd/lib/table"
import moment from "moment"
import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"

import DateDueInBadge from "Components/DateDueInBadge"
import { ConditionalWrapper } from "Components/conditionalWrapper"
import AmountColumn from "Components/expenseAmountColumn"
import ExpenseReminderInfo from "Components/expenseReminderInfo"
import ExpenseStatusTag from "Components/expenseStatusTag"
import ExpenseTagColumn from "Components/expenseTagColumn"
import ExpenseTransactionStatusTag from "Components/expenseTransactionStatusTag"
import ExportDropDownMenu from "Components/exportDropDownMenu"
import DiscountIcon from "Components/icons/discountIcon"
import { EmptyTable } from "Components/layout/emptyTable"
import { ErrorTable } from "Components/layout/errorTable"
import { PaymentDropDownButton } from "Components/paymentDropDownMenu"
import RequestColumn from "Components/requestColumn"
import { ExpenseExport } from "Features/export/expenseExport"
import { useTableSearchString } from "Shared/hooks/table.hooks"
import { AuthzService, UserService } from "Shared/services"
import { setExpense } from "Shared/store/actions/expense/expenseActions"
import { refetchTable, updateTableOnChange } from "Shared/store/actions/tables/tableActions"
import { TablesEnum } from "Shared/store/reducers/tableConfigReducer"
import { Table as TableInterface } from "Shared/store/reducers/tableReducer"
import { isFolderExpense, normalizeExpenseToExport } from "Shared/utils/expense.utils"
import { insertIf } from "Shared/utils/helper.utils"
import { getSortOrderForColumn } from "Shared/utils/table.utils"

import ExpandTripFolderTableIcon from "./expandTripFolderTableIcon"

interface PayTableInterface {
    tableEnum: TablesEnum.PAY_AND_EXPORT_TO_BE_PAID
    table: TableInterface<Expense>
    showTags: boolean
    checkedExpenses: Array<Expense>
    onCheckboxClick: (table: TablesEnum, selectedRows: Array<Expense>) => void
    onPaymentSelect: (key: string, expenses: Array<Expense>) => Promise<void>
    onExportSuccess?: () => void
}

const PayTable: React.FC<PayTableInterface> = ({ tableEnum, table, checkedExpenses, showTags, onCheckboxClick, onPaymentSelect, onExportSuccess }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const history = useHistory()

    const loggedInProfile = UserService.getLoggedInEmployeeProfile()
    const dashboardSearchString = useTableSearchString(TablesEnum.DONE_REQUESTS)

    const hasPayByBankRight = AuthzService.canLoggedInUserPayByBank()
    const hasExportRight = AuthzService.canLoggedInUserExport()

    const isPaymentDisabledForFolder = (expense: any) => expense.kind === ExpenseKindEnum.TRIP_FOLDER && (expense as TripFolder)?.children?.length === 0

    //* If any of the table data have children adjust the table design accordingly otherwise remove extras spaces provided for the dropdown icon
    const expandableColumn = table.data.docs.some((expense) => isFolderExpense(expense) && (expense as TripFolder)?.children.length > 0)
        ? [
              {
                  dataIndex: "expandIcon",
                  width: 35,
                  align: "center",
              } as any,
          ]
        : []

    const getTableColumns = (): ColumnsType<Expense> => {
        const columns: ColumnsType<Expense> = [
            ...expandableColumn,
            {
                title: t("label:request"),
                key: "expenseNumber",
                ellipsis: true,
                width: 320,
                ...(loggedInProfile.settings.showExpenseNumbers && {
                    sorter: true,
                    sortOrder: getSortOrderForColumn(table, "expenseNumber"),
                }),
                render: (data: Expense) => <RequestColumn request={data} />,
            },
            {
                title: t("label:due_date"),
                key: "invoiceDueDate",
                align: "left",
                width: 130,
                sorter: true,
                sortOrder: getSortOrderForColumn(table, "invoiceDueDate"),
                render: (expense: Expense) => {
                    const discount = expense.getActiveDiscount()
                    const DiscountTooltip = (children: JSX.Element) => (
                        <Tooltip
                            title={
                                <div>
                                    {t("label:expense_discount.discounted_due_date")}: {moment(discount?.dueDate).format("ll")}
                                </div>
                            }
                        >
                            {children}
                        </Tooltip>
                    )

                    return (
                        <>
                            {expense.invoiceDueDate ? (
                                <>
                                    <ConditionalWrapper condition={!!discount} wrapper={DiscountTooltip}>
                                        <>
                                            <Row className="flex gap-4 items-center">
                                                {discount && <DiscountIcon className="finway-green-2-text" />}
                                                {moment(expense.invoiceDueDate).format("ll")}
                                            </Row>
                                            <Row>
                                                <DateDueInBadge dueOn={expense.invoiceDueDate} />
                                            </Row>
                                        </>
                                    </ConditionalWrapper>
                                </>
                            ) : (
                                <Row>
                                    <ConditionalWrapper condition={!!discount} wrapper={DiscountTooltip}>
                                        <div className="flex gap-4 items-center">
                                            {discount && <DiscountIcon className="finway-green-2-text" />}
                                            n/a
                                        </div>
                                    </ConditionalWrapper>
                                </Row>
                            )}
                        </>
                    )
                },
            },
            ...insertIf(showTags, {
                title: t("label:tag"),
                key: "tag",
                align: "left",
                width: 150,
                render: ({ expenseTags: expenseTagIds }: Expense) => <ExpenseTagColumn expenseTagIds={expenseTagIds} />,
            }),
            {
                key: "reminder",
                align: "center",
                width: 50,
                render: (request: Expense) => <ExpenseReminderInfo request={request} />,
            },
            ...insertIf(hasPayByBankRight, {
                title: t("label:action"),
                key: "status",
                align: "left",
                width: 190,
                render: (expense: Expense) => (
                    <div className="btn-wrapper">
                        {expense.status === ExpenseStatusEnum.REVIEWED ? (
                            <PaymentDropDownButton
                                expenses={[expense]}
                                text={t("action:payment")}
                                onSelect={(key) => onPaymentSelect(key, [expense])}
                                buttonClassName="w-120"
                                disabled={expense.isInPaymentProcess || isPaymentDisabledForFolder(expense)}
                            />
                        ) : (
                            <div className="mt-4">
                                <ExpenseStatusTag status={expense.status} />
                            </div>
                        )}
                        <ExpenseExport
                            onSuccess={onExportSuccess}
                            render={({ onExport, isExporting }) => (
                                <ExportDropDownMenu
                                    expenses={normalizeExpenseToExport(expense)}
                                    onExport={onExport}
                                    isLoading={isExporting}
                                    disabled={isPaymentDisabledForFolder(expense)}
                                    includeSepa
                                    noIcon
                                    primaryButton
                                    asDottedButton
                                    onlyFileExport={!hasExportRight}
                                    isAuditTrailVisible={true}
                                />
                            )}
                        />
                    </div>
                ),
            }),
            {
                title: t("label:matching.transaction_status"),
                key: "transactionState",
                align: "center",
                width: 170,
                sorter: true,
                sortOrder: getSortOrderForColumn(table, "transactionState"),
                render: (data: Expense) => <ExpenseTransactionStatusTag expense={data} />,
            },
            {
                title: loggedInProfile.settings.showGrossAmount ? t("label:gross_amount") : t("label:net_amount"),
                key: loggedInProfile.settings.showGrossAmount ? "totalGrossPrice" : "totalNetPrice",
                align: "right",
                width: 180,
                className: "pr-10",
                sorter: true,
                sortOrder: getSortOrderForColumn(table, loggedInProfile.settings.showGrossAmount ? "totalGrossPrice" : "totalNetPrice"),
                render: (data: Expense) => <AmountColumn request={data} showGross={loggedInProfile.settings.showGrossAmount} showDiscountWhenAvailable />,
            },
        ]
        return columns
    }

    return (
        <div className="animation-appear overflow-x-auto mb-20">
            {table.error ? (
                <ErrorTable onTableReload={() => dispatch(refetchTable(tableEnum))} isLoading={table.isFetching} />
            ) : table.data.totalDocs === 0 && !table.isFetching ? (
                <EmptyTable showSearchIcon={!!dashboardSearchString} noSearchText={t("info:no_search_data_found.title")} noDocumentText={t("info:empty_state.requests.title")} />
            ) : (
                <Table
                    rowKey={(record) => record.id}
                    dataSource={table.data.docs}
                    columns={getTableColumns()}
                    rowSelection={{
                        type: "checkbox",
                        renderCell: (_value, _record, _index, originNode) => (
                            <div onClick={(e) => e.stopPropagation()} className="bigger-hitbox-checkbox">
                                {originNode}
                            </div>
                        ),
                        getCheckboxProps: (record) => ({ disabled: record.status !== ExpenseStatusEnum.REVIEWED }),
                        checkStrictly: false,
                        selectedRowKeys: checkedExpenses.map((record) => record.id),
                        onChange: (_selectedRowKeys, selectedRows) => {
                            onCheckboxClick(tableEnum, selectedRows)
                        },
                    }}
                    pagination={{
                        position: ["bottomRight"],
                        showSizeChanger: true,
                        current: table.data.page,
                        pageSize: table.data.limit,
                        hideOnSinglePage: false,
                        total: table.data.totalDocs,
                        pageSizeOptions: ["5", "10", "20", "50", "100"],
                    }}
                    loading={{
                        spinning: table.isFetching,
                        indicator: <LoadingOutlined style={{ fontSize: 30, color: "black" }} spin />,
                    }}
                    onChange={updateTableOnChange(tableEnum, table.data)}
                    onRow={(record, _rowIndex) => ({
                        onClick: (_event) => {
                            dispatch(setExpense(record))
                            history.push(`/expenses/${record.id}`)
                        },
                    })}
                    expandIcon={(props) => <ExpandTripFolderTableIcon props={props} />}
                />
            )}
        </div>
    )
}

export default PayTable
