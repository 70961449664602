import { DocumentTypeEnum, Expense, ExpenseKindEnum, ExpenseRequestTypeEnum, Hospitality, InboxInvoice, Mileage, PerDiem, TripFolder } from "@finway-group/shared/lib/models"
import { GetEmployeeById } from "@finway-group/shared/lib/models/user/employee.model"
import { FormInstance } from "antd/lib/form"
import { Moment } from "moment"
import { RangeValue } from "rc-picker/lib/interface"
import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from "react"

import ExpenseInitializer from "Features/pages/expenses/expenseInitializer"
import { useCompany } from "Shared/hooks/company.hooks"
import { useEmployees } from "Shared/hooks/employee.hooks"
import { useExpense } from "Shared/hooks/expense.hooks"
import { usePerDiemDestinations } from "Shared/hooks/perDiemDestination.hooks"
import { useTaxes } from "Shared/hooks/tax.hooks"
import { useLoggedInEmployeeProfile } from "Shared/hooks/user.hooks"
import { useCreditors, useVendors } from "Shared/hooks/vendor.hooks"
import EmployeeService from "Shared/services/employee.service"
import { getExpenseModelByKind } from "Shared/utils/expense.utils"

// TODO: Chase & narrow-down these types
export interface Filler {
    [key: string]: any
}

export type ExpenseData = Partial<Expense | PerDiem | Hospitality | Mileage | TripFolder> & {
    performancePeriod?: RangeValue<Moment>
}

interface ExpenseFormContextState {
    // from modal
    isNew: boolean
    isPreApprovedSubscription: boolean
    hideExpenseKindRadioOption: boolean
    type: ExpenseRequestTypeEnum
    expenseForm: FormInstance<
        | ExpenseData
        | {
              [key: string]: any
          }
    >
    isUploading: boolean
    setIsUploading: (isUploading: boolean) => void
    inboxInvoices?: Array<InboxInvoice>

    // from form
    expense: Expense
    isInvoiceRequest: boolean // TODO: weg
    isSubscription: boolean // TODO: Weg
    ocrSettings: {
        isOcrEnabled: boolean
        isOcrItemizationEnabled: boolean
        isOcrItemizationDisplayed: boolean
        setIsOcrEnabled: (flag: boolean) => void
        setIsOcrItemizationEnabled: (flag: boolean) => void
    }
    isOcrProcessed: boolean
    isFormTouched: boolean
    activeDocumentsTab: DocumentTypeEnum
    prefilledVendorData: any // When reading OCR data, we store the detected vendor data so that the create vendor modal can be prefilled
    isLinked: boolean
    reimbursementRequestsEnabled: { standard: boolean; mileage: boolean; hospitality: boolean; perDiem: boolean }
    onKindChange: (newKind: ExpenseKindEnum) => void
    updateExpense: (data: ExpenseData) => void
    setIsOcrProcessed: (flag: boolean) => void
    setIsFormTouched: (flag: boolean) => void
    setActiveDocumentsTab: (tab: DocumentTypeEnum) => void
    setPrefilledVendorData: (prefilledVendorData: any) => void
    setIsLinked: (flag: boolean) => void
    isBuildingApprovalProcesses: boolean
    setIsBuildingApprovalProcesses: (flag: boolean) => void
    folder?: TripFolder // folder where this expense should belong to
}

export type ExpenseFormContextInterface = [ExpenseFormContextState, Dispatch<SetStateAction<ExpenseFormContextState>>]

const Context = React.createContext<ExpenseFormContextInterface | undefined>(undefined)

export const useExpenseFormContext = () => {
    const context = useContext(Context) as ExpenseFormContextInterface
    if (context === undefined) {
        throw new Error("useExpenseFormContext must be used inside <ExpenseFormContext></ExpenseFormContext>")
    }
    return context
}

interface ExpenseFormContextProps {
    isNew: boolean
    isPreApprovedSubscription: boolean
    kind: ExpenseKindEnum
    hideExpenseKindRadioOption: boolean
    type: ExpenseRequestTypeEnum
    expenseForm: FormInstance
    isUploading: boolean
    setIsUploading: (isUploading: boolean) => void
    isReimbursement: boolean
    transactionData: any
    isBuildingApprovalProcesses: boolean
    setIsBuildingApprovalProcesses: (flag: boolean) => void
    inboxInvoices?: Array<InboxInvoice>
    reimbursementRequestsEnabled?: { standard: boolean; mileage: boolean; hospitality: boolean; perDiem: boolean }
    expenseOverride?: Expense | Mileage | PerDiem | Hospitality
    folder?: TripFolder // If its a new expense creation but under a folder, use the folder object as the template
}

const ExpenseFormContext: React.FC<ExpenseFormContextProps> = ({
    isNew = true,
    isPreApprovedSubscription = false,
    kind,
    hideExpenseKindRadioOption,
    type,
    expenseForm,
    isUploading,
    setIsUploading,
    inboxInvoices,
    isReimbursement,
    transactionData,
    reimbursementRequestsEnabled = { standard: true, mileage: true, hospitality: true, perDiem: true },
    children,
    expenseOverride,
    folder,
    isBuildingApprovalProcesses,
    setIsBuildingApprovalProcesses,
}) => {
    const loggedInProfile = useLoggedInEmployeeProfile()

    const reduxExpense = useExpense()
    const expenseDetails = expenseOverride ?? reduxExpense

    const isInvoiceRequest = type === ExpenseRequestTypeEnum.INVOICE
    const employees = useEmployees({ excludeDeleted: true, includeAutoApprover: true })
    const vendors = useVendors(true)
    const taxes = useTaxes()
    const { destinations: perDiemDestinations } = usePerDiemDestinations()
    const company = useCompany()
    // gotta use all creditors here because onKindChange relies on getting correct creditors for all ExpenseKinds
    // and using a hook inside the method won't work
    const creditors = useCreditors(true, true, true)

    // e.g. if the requester was deleted, we still want to show him
    if (expenseDetails.requestedBy && !GetEmployeeById(employees, expenseDetails.requestedBy._id)) {
        const requester = EmployeeService.getEmployeeById(expenseDetails.requestedBy._id)
        requester && employees.push(requester)
    }

    // prettier-ignore
    const [expense] = useState<Expense>(ExpenseInitializer.initializeExpenseState({ isNew, prefilledExpense: expenseDetails, isInvoiceRequest, isReimbursement, isPreApprovedSubscription, kind, inboxInvoices: inboxInvoices || [], transactionData, employees, vendors, loggedInProfile, taxes, creditors, perDiemDestinations, company, folder }))
    const [originalData] = useState<Expense>(
        ExpenseInitializer.initializeExpenseState({
            isNew,
            prefilledExpense: expenseDetails,
            isInvoiceRequest,
            isReimbursement,
            isPreApprovedSubscription,
            kind,
            inboxInvoices: inboxInvoices || [],
            transactionData,
            employees,
            vendors,
            loggedInProfile,
            taxes,
            creditors,
            perDiemDestinations,
            company,
            folder,
        }),
    )
    const isSubscription = expense.kind === ExpenseKindEnum.SUBSCRIPTION

    useEffect(() => {
        // Update expense if redux state changes (e.g. because OCR on detail page finished)
        updateExpense({ __v: (expenseDetails as any).__v } as any)
    }, [expenseDetails])

    const updateExpense = (data: ExpenseData) => {
        const ExpenseModel = getExpenseModelByKind(expense.kind)
        setExpenseFormContext((prevState) => ({ ...prevState, expense: new ExpenseModel({ ...prevState.expense, ...data }) }))
    }
    const onKindChange = (newKind: ExpenseKindEnum) => {
        const ExpenseModel = getExpenseModelByKind(newKind)
        setExpenseFormContext((prevState) => {
            // prettier-ignore
            // to init the expense correctly depending on the kind we need all creditors
            const initExpense = ExpenseInitializer.initializeOnKindChange({
                expense: prevState.expense,
                isReimbursement: prevState.expense.isReimbursement,
                kind: newKind,
                transactionData,
                employees,
                vendors,
                loggedInUser: loggedInProfile,
                isOcrProcessed: prevState.isOcrProcessed,
                expenseForm: prevState.expenseForm,
                taxes,
                isNew,
                perDiemDestinations,
                creditors,
                company,
                originalData,
                folder,
            })
            return { ...prevState, expense: new ExpenseModel(initExpense), inboxInvoices: initExpense.invoices }
        })
    }

    const setPrefilledVendorData = (prefilledVendorData: any) => {
        setExpenseFormContext((prevState) => ({ ...prevState, prefilledVendorData }))
    }

    const setActiveDocumentsTab = (tab: DocumentTypeEnum) => {
        setExpenseFormContext((prevState) => ({ ...prevState, activeDocumentsTab: tab }))
    }

    const setIsOcrProcessed = (flag: boolean) => {
        setExpenseFormContext((prevState) => ({ ...prevState, isOcrProcessed: flag }))
    }

    const setIsFormTouched = (flag: boolean) => {
        setExpenseFormContext((prevState) => ({ ...prevState, isFormTouched: flag }))
    }

    const setIsOcrEnabled = (flag: boolean) => {
        setExpenseFormContext((prevState) => ({ ...prevState, ocrSettings: { ...prevState.ocrSettings, isOcrEnabled: flag } }))
    }

    const setIsOcrItemizationEnabled = (flag: boolean) => {
        setExpenseFormContext((prevState) => ({ ...prevState, ocrSettings: { ...prevState.ocrSettings, isOcrItemizationEnabled: flag } }))
    }

    const setIsLinked = (flag: boolean) => {
        setExpenseFormContext((prevState) => ({ ...prevState, isLinked: flag }))
    }

    const setIsBuildingApprovalProcessesFlag = (flag: boolean) => {
        setIsBuildingApprovalProcesses(flag)
        // To trigger context re-render we need to update its state
        setExpenseFormContext((prevState) => ({ ...prevState, isBuildingApprovalProcesses: flag }))
    }

    const [expenseFormContext, setExpenseFormContext] = useState<ExpenseFormContextState>({
        // use useStateIfMounted after is properly typed
        // from props
        isNew,
        isPreApprovedSubscription,
        hideExpenseKindRadioOption,
        type,
        expenseForm,
        isUploading,
        inboxInvoices,
        setIsUploading,

        // from local
        expense,
        isInvoiceRequest, // TODO: weg
        isSubscription, // TODO: Weg
        isOcrProcessed: false,
        isFormTouched: false,
        ocrSettings: {
            isOcrEnabled: loggedInProfile.settings.enableOcr,
            isOcrItemizationEnabled: loggedInProfile.settings.enableOcrItemization,
            isOcrItemizationDisplayed: true,
            setIsOcrEnabled,
            setIsOcrItemizationEnabled,
        },
        activeDocumentsTab: DocumentTypeEnum.INVOICE,
        prefilledVendorData: undefined,
        isLinked: true,
        reimbursementRequestsEnabled,
        updateExpense,
        onKindChange,
        setPrefilledVendorData,
        setActiveDocumentsTab,
        setIsOcrProcessed,
        setIsFormTouched,
        setIsLinked,
        folder,
        isBuildingApprovalProcesses,
        setIsBuildingApprovalProcesses: setIsBuildingApprovalProcessesFlag,
    })

    return <Context.Provider value={[expenseFormContext, setExpenseFormContext]}>{children}</Context.Provider>
}

export default ExpenseFormContext
