import { LoadingOutlined } from "@ant-design/icons"
import { Layout } from "antd"
import React from "react"

interface LoadingInterface {
    className?: string
    text?: string
    margin?: boolean
}

const Loading = ({ className = "", text, margin = true }: LoadingInterface) => (
    <div className={`relative ${margin ? "mt-56" : ""} ${className || "h-340"}`}>
        <div className="container" style={{ height: "100%" }}>
            <Layout style={{ background: "transparent", alignItems: "center", height: "100%" }}>
                <div className="flex flex-col mt-auto mb-auto">
                    <LoadingOutlined style={{ fontSize: 30 }} spin />
                    {text && <span className="text-text-dark mt-20">{text}</span>}
                </div>
            </Layout>
        </div>
    </div>
)

export default Loading
