import { Team } from "@finway-group/shared/lib/models"
import { Action } from "redux"

export enum TeamTypes {
    FETCH_ALL_TEAMS = "FETCH_ALL_TEAMS",
    UPDATE_ALL_TEAMS = "UPDATE_ALL_TEAMS",
}

export interface FetchAllTeamsAction extends Action<typeof TeamTypes.FETCH_ALL_TEAMS> {
    type: typeof TeamTypes.FETCH_ALL_TEAMS
    teams: Array<Team>
}
export interface UpdateAllTeamsAction extends Action<typeof TeamTypes.UPDATE_ALL_TEAMS> {
    type: typeof TeamTypes.UPDATE_ALL_TEAMS
    teams: Array<Team>
}

export type TeamsActions = FetchAllTeamsAction | UpdateAllTeamsAction
