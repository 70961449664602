import { ImportResult, ImportResultCollection } from "@finway-group/shared/lib/models"
import { RcFile } from "antd/lib/upload"
import { Dispatch } from "redux"

import { ImportResultOperationEnum } from "Components/modals/spreadsheetDataUpload/importResult.types"
import { ImportService } from "Shared/services"

import {
    CloseDataUploadNotificationAction,
    DataUploadNotificationTypeEnum,
    FetchImportResultAction,
    ImportActionTypes,
    ImportDataFailureAction,
    ImportDataSuccessAction,
    ImportDataValidateOnlyFailureAction,
    ImportDataValidateOnlySuccessAction,
    ImportDataValidationErrorsAction,
    OpenDataUploadModalAction,
} from "./importTypes"

const fetchImportResultAction = (importResultId: string): FetchImportResultAction => ({ type: ImportActionTypes.FETCH_IMPORT_RESULT, payload: { importResultId } })

const importDataRequestAcceptedAction = () => ({ type: ImportActionTypes.IMPORT_DATA_REQUEST_ACCEPTED, payload: { isInProgress: true } })

export const fetchImportResult = (importResultId: string) => async (dispatch: Dispatch) => {
    const importResult = await ImportService.fetchImportResult(importResultId)
    dispatch(fetchImportResultAction(importResult._id))
}

export const fetchLatestImportResult = () => async (dispatch: Dispatch) => {
    const data = await ImportService.fetchLatestImportResults({ limit: 1 })
    const importResult = data.docs[0]
    if (importResult._id) dispatch(fetchImportResultAction(importResult._id))
}

export const requestDataValidationFromSpreadSheet = (file: RcFile) => async (dispatch: Dispatch) => {
    await ImportService.requestDataImportFromSpreadsheet(file, true)
    dispatch(importDataRequestAcceptedAction())
}

export const requestDataCreationFromSpreadSheet = (file: RcFile) => async (dispatch: Dispatch) => {
    await ImportService.requestDataImportFromSpreadsheet(file, false)
    dispatch(importDataRequestAcceptedAction())
}

export const importDataValidateOnlySuccess = (importResultId: string): ImportDataValidateOnlySuccessAction => ({
    type: ImportActionTypes.IMPORT_DATA_VALIDATE_ONLY_SUCCESS,
    payload: { importResultId, isInProgress: false, notification: DataUploadNotificationTypeEnum.VALIDATE_ONLY_SUCCESS },
})

export const importDataValidateOnlyFailure = (): ImportDataValidateOnlyFailureAction => ({
    type: ImportActionTypes.IMPORT_DATA_VALIDATE_ONLY_FAILURE,
    payload: { isInProgress: false },
})

export const importDataSuccess = (importResultId: string): ImportDataSuccessAction => ({
    type: ImportActionTypes.IMPORT_DATA_SUCCESS,
    payload: { importResultId, isInProgress: false, notification: DataUploadNotificationTypeEnum.SUCCESS },
})

export const importDataValidationErrors = (importResultId: string): ImportDataValidationErrorsAction => ({
    type: ImportActionTypes.IMPORT_DATA_VALIDATION_ERRORS,
    payload: { importResultId, isInProgress: false, notification: DataUploadNotificationTypeEnum.VALIDATION_ERRORS },
})

export const importDataFailure = (): ImportDataFailureAction => ({
    type: ImportActionTypes.IMPORT_DATA_FAILURE,
    payload: { isInProgress: false },
})

export const openDataUploadModal = (): OpenDataUploadModalAction => ({
    type: ImportActionTypes.OPEN_DATA_UPLOAD_MODAL,
    payload: { notification: undefined },
})

export const closeDataUploadNotification = (): CloseDataUploadNotificationAction => ({
    type: ImportActionTypes.CLOSE_DATA_UPLOAD_NOTIFICATION,
    payload: { notification: undefined },
})

export const getDataUploadOperationTotalCount = (importResult: ImportResult, operation: ImportResultOperationEnum) => {
    const operationMap: { [key: string]: keyof ImportResultCollection } = { ERROR: "errorsList", CREATE: "created", RESTORE: "restored", IGNORE: "ignored" }
    return Object.values(importResult.collections).reduce((total, value: ImportResultCollection) => total + (value?.[operationMap[operation]]?.length || 0), 0)
}

export const getDataUploadTotalCounts = (importResult: ImportResult) => ({
    errorsList: getDataUploadOperationTotalCount(importResult, ImportResultOperationEnum.ERROR),
    created: getDataUploadOperationTotalCount(importResult, ImportResultOperationEnum.CREATE),
    restored: getDataUploadOperationTotalCount(importResult, ImportResultOperationEnum.RESTORE),
    ignored: getDataUploadOperationTotalCount(importResult, ImportResultOperationEnum.IGNORE),
})

export const resetImportResults = () => ({
    type: ImportActionTypes.RESET_IMPORT_RESULTS,
})
