import Icon from "@ant-design/icons"
import { IntegrationType, IntegrationTypeEnum, PersonioAttributes, Vendor } from "@finway-group/shared/lib/models"
import { Button, Col, Image, Row, Tag, Tooltip } from "antd"
import React, { useMemo } from "react"
import { RefreshCw } from "react-feather"
import { useTranslation } from "react-i18next"
import { v4 as uuidv4 } from "uuid"

import VendorImage from "Components/VendorImage"
import PersonioModal from "Components/modals/personio/personio.modal"
import PersonioActionButton from "Components/personioActionButton"
import { LOGO_API } from "Shared/config/consts"
import { useToggleIntegrationConfiguration, useTriggerPersonioSync } from "Shared/queries/integration.queries"
import { NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { getTooltipPopupContainer } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

interface PersonioSettingsInterface {
    connection?: IntegrationType
    refetch: Function
}

const img = `${LOGO_API}/personio.com`
const personioIntegration = new Vendor({ name: IntegrationTypeEnum.PERSONIO, img, id: uuidv4() })

const PersonioIntegration = ({ connection, refetch }: PersonioSettingsInterface) => {
    const { t } = useTranslation()
    const [isShowingModal, setIsShowingModal] = useStateIfMounted(false)
    const [isLoading, setIsLoading] = useStateIfMounted(false)
    const togglePersonioConnection = useToggleIntegrationConfiguration()
    const triggerPersonioSync = useTriggerPersonioSync()

    const onDisconnect = async () => {
        if (!connection) return

        setIsLoading(true)

        togglePersonioConnection.mutate(
            {
                id: connection._id!,
                enable: false,
            },
            {
                onSuccess: () => {
                    NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:integrations.personio.title"), t("notification:integrations.personio.message"))
                },
                onError: (error) => {
                    NotificationService.showErrorNotificationBasedOnResponseError(error, t(`error:error`))
                },
                onSettled: () => {
                    setIsLoading(false)
                    refetch()
                },
            },
        )
    }
    const onTriggerSync = async () => {
        if (!connection) return

        triggerPersonioSync.mutate(undefined, {
            onSuccess: () => {
                NotificationService.send(NotificationTypeEnum.INFO, t("notification:integrations.personio.info"), t("notification:integrations.personio.infoText"))
            },
            onError: (error) => {
                NotificationService.showErrorNotificationBasedOnResponseError(error, t(`error:error`))
            },
        })
    }

    const personioAttributes = useMemo(() => {
        const attributeConfig: Array<{
            name: keyof PersonioAttributes
            title: string
        }> = [
            {
                name: "enableAbsenceSync",
                title: t("label:personio.enable_absence_sync.title"),
            },
            {
                name: "enableCreateEmployee",
                title: t("label:personio.enable_create_employee.title"),
            },
            {
                name: "enableEmployeeSync",
                title: t("label:personio.enable_employee_sync.title"),
            },
            {
                name: "enableTeamSync",
                title: t("label:personio.enable_team_sync.title"),
            },
        ]

        const mapped = attributeConfig.map((config) => ({
            ...config,
            isEnabled: connection?.attributes?.[config.name] ?? false,
        }))
        const sorted = mapped.sort((a, b) => Number(b.isEnabled) - Number(a.isEnabled))

        return sorted
    }, [connection?.attributes, t])

    return (
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <div className="p-10 flex flex-col sm:flex-row border flex-grow w-full rounded-lg justify-between">
                    <div className="flex flex-row">
                        <div className="mr-10">
                            <VendorImage vendor={personioIntegration} size={45} bordered />
                        </div>

                        <div className="align-left flex flex-col ml-10 justify-center">
                            <b className="truncate placeholder-primary">{t("label:personio.title")}</b>
                            {connection?.isEnabled && (
                                <div className="flex flex-row justify-left items-center flex-wrap">
                                    {personioAttributes.map(({ name, title, isEnabled }) => (
                                        <Tag key={name} className={`mt-5 mr-5 ${isEnabled ? "" : "opacity-50"}`} color={isEnabled ? "green" : "default"}>
                                            {title}
                                        </Tag>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="flex justify-end md:justify-center items-center mt-auto mb-auto float-right">
                        {connection?.isEnabled && (
                            <Tooltip title={t("action:integrations.personio.manualSync")} getPopupContainer={(triggerNode) => getTooltipPopupContainer(triggerNode)}>
                                <Button onClick={() => onTriggerSync()} className="text-dark">
                                    <RefreshCw style={{ color: "inherit" }} />
                                </Button>
                            </Tooltip>
                        )}

                        <PersonioActionButton onClick={() => setIsShowingModal(true)} isDisconnected={!connection?.isEnabled} />

                        {connection?.isEnabled && (
                            <Button loading={isLoading} className="ml-8" onClick={() => onDisconnect()}>
                                {t("action:disconnect")}
                            </Button>
                        )}
                    </div>
                </div>
            </Col>
            <PersonioModal
                isShowing={isShowingModal}
                onCancel={() => setIsShowingModal(false)}
                connection={connection}
                icon={<Icon component={() => <Image src={img} />} className="w-28" />}
                refetch={refetch}
            />
        </Row>
    )
}

export default PersonioIntegration
