import { ExpenseStatusEnum } from "@finway-group/shared/lib/models"
import { Tag } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"

interface TagPropInterface {
    status: ExpenseStatusEnum
    isDraft?: boolean
}

const ExpenseStatusTag: React.FC<TagPropInterface> = ({ status, isDraft }) => {
    const { t } = useTranslation()

    return (
        <>
            {(() => {
                if (isDraft) {
                    return <Tag className="ant-tag-yellow">{t("label:draft")}</Tag>
                }
                switch (status) {
                    case ExpenseStatusEnum.PURCHASE_PENDING:
                        return <Tag className="ant-tag-gray">{t("label:purchase_pending")}</Tag>
                    case ExpenseStatusEnum.DOCS_NEEDED:
                        return <Tag className="ant-tag-gray">{t("label:documents_needed")}</Tag>
                    case ExpenseStatusEnum.INVOICE_PENDING:
                        return <Tag className="ant-tag-gray">{t("label:invoice_pending")}</Tag>
                    case ExpenseStatusEnum.APPROVED:
                        return <Tag className="ant-tag-green">{t("label:in_review")}</Tag>
                    case ExpenseStatusEnum.DECLINED:
                        return <Tag className="ant-tag-red">{t("label:declined")}</Tag>
                    case ExpenseStatusEnum.REVIEWED:
                        return <Tag className="ant-tag-green">{t("label:reviewed")}</Tag>
                    case ExpenseStatusEnum.PAID:
                        return <Tag className="ant-tag-green">{t("label:paid")}</Tag>
                    case ExpenseStatusEnum.DONE:
                        return <Tag className="ant-tag-green">{t("label:exported")}</Tag>
                    default:
                        return <></>
                }
            })()}
        </>
    )
}

export default ExpenseStatusTag
