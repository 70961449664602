import MileageLocationInterface from "@finway-group/shared/lib/models/expense/mileageLocation.interface"
import MapboxDraw from "@mapbox/mapbox-gl-draw"
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css"
import * as turf from "@turf/turf"
import mapboxgl, { GeoJSONSource } from "mapbox-gl"
import "mapbox-gl/dist/mapbox-gl.css"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { MAPBOX_ACCESS_TOKEN } from "Shared/config/consts"
import { usePreviousTyped } from "Shared/hooks/common.hooks"
import GeoLocationService from "Shared/services/geoLocation.service"
import useUpdateEffect from "Shared/utils/hooks/useUpdateEffect"
import { convertMeterToKilometer } from "Shared/utils/mileage.utils"

import { mapBoxDrawConfig } from "./mapAuxilliary/mapboxConfig"

export type RouteInfo = {
    distance: number
    duration: number
    geoObject: GeoObject
}

export type GeoObject = {
    type: string
    properties: any
    geometry: {
        type: "LineString"
        coordinates: Array<[number, number]>
    }
}

type MapProps = {
    markers: { stops?: Array<MileageLocationInterface> }
    interactive?: boolean
    onRouteUpdate?: (routeInfo: RouteInfo) => void
    initialRoute?: RouteInfo
}

const MUNICH_CENTER_COORDINATES: [number, number] = [11.575382, 48.137108]

const MileageRequestMap: React.FC<MapProps> = ({ markers, interactive = true, onRouteUpdate, initialRoute }) => {
    const { t } = useTranslation()
    // this is where the map instance will be stored after initialization
    const [map, setMap] = React.useState<mapboxgl.Map>()
    const [useInitialRoute, setUseInitialRoute] = useState<boolean>(!!initialRoute)
    const [distance, setDistance] = useState<Number | undefined>(initialRoute ? initialRoute.distance : undefined)
    const [duration, setDuration] = useState<Number | undefined>(initialRoute ? initialRoute.duration : undefined)
    const [isMapReady, setIsMapReady] = useState<boolean>(false)

    // Coordinates for the stops in a string format
    const [stopPointsAsString, setStopPointsAsString] = useState<string>()
    const prevStopPointsAsString = usePreviousTyped(stopPointsAsString)

    const mapNode = React.useRef(null)

    // map bounds coordinates
    const pathCoordinatesMap: any = []

    // custom route drawer
    const Draw = new MapboxDraw(mapBoxDrawConfig)

    const drawRoute = (routeInfo: RouteInfo) => {
        const { distance, geoObject, duration } = routeInfo
        setDistance(Number(distance))
        setDuration(Number(duration))

        if (map) {
            if (map.getSource("routeId")) {
                ;(map.getSource("routeId") as GeoJSONSource).setData(geoObject as any)
            } else {
                map.addLayer({
                    id: "routeId",
                    type: "line",
                    source: {
                        type: "geojson",
                        data: geoObject as any,
                    },
                    layout: {
                        "line-join": "round",
                        "line-cap": "round",
                    },
                    paint: {
                        "line-color": "#3887be",
                        "line-width": 5,
                        "line-opacity": 0.75,
                    },
                })
            }
        }
    }

    // Use effect to draw stop markers
    useEffect(() => {
        const stopMarkers: Array<mapboxgl.Marker> = []
        let routePath = ""

        if (map) {
            if (markers) {
                const stopsLength = markers.stops?.length || 0
                if (markers.stops && stopsLength > 1) {
                    // draw additional stops markers and routes
                    markers.stops.forEach((stop: any, i: number) => {
                        if (stop?.coordinates) {
                            stopMarkers[i] = new mapboxgl.Marker().setLngLat(stop.coordinates).addTo(map)
                            pathCoordinatesMap.push(stop.coordinates)
                        }
                    })
                }
            }

            // map build dynamic map bounds
            if (pathCoordinatesMap.length > 1) {
                const line = turf.lineString(pathCoordinatesMap)
                const bbox = turf.bbox(line)
                if (line) {
                    map.fitBounds(bbox as any, { padding: 100 })
                }

                if (markers.stops)
                    routePath = markers.stops
                        .filter((stops: MileageLocationInterface) => !!stops)
                        .map((stop: any) => stop?.coordinates.join(","))
                        .join(";")

                setStopPointsAsString(routePath)
            }
        }

        return () => {
            stopMarkers?.map((marker) => marker?.remove())
        }
    }, [markers?.stops, map])

    // Use effect to draw the route
    useEffect(() => {
        if (stopPointsAsString && isMapReady) {
            if (initialRoute && useInitialRoute) {
                // When initial route is given as prop and useInitalRoute is set to true, draw the given route
                drawRoute(initialRoute)
            } else {
                // When not using initial route, fetch how the route should be.
                GeoLocationService.fetchDirections(stopPointsAsString).then((routeInfo: RouteInfo) => {
                    onRouteUpdate?.(routeInfo)
                    drawRoute(routeInfo)
                })
            }
        }
    }, [stopPointsAsString, isMapReady, useInitialRoute])

    // When the stop points are updated, dont use the initial route, and re-determine route
    useUpdateEffect(() => {
        // The stopPointsAsString comparison is needed here because if this useEffect fires when isMapReady is changed,
        // we dont want to set useInitalRoute to false if there is no change in the stopPointsAsString
        if (isMapReady && stopPointsAsString !== prevStopPointsAsString) {
            setUseInitialRoute(false)
        }
    }, [stopPointsAsString, isMapReady])

    // Use effect to setup the map
    useEffect(() => {
        const node = mapNode.current
        // if the window object is not found, that means
        // the component is rendered on the server
        // or the dom node is not initialized, then return early
        if (typeof window === "undefined" || node === null) return

        let mapboxMap: any

        try {
            // otherwise, create a map instance
            mapboxMap = new mapboxgl.Map({
                container: node,
                accessToken: MAPBOX_ACCESS_TOKEN,
                style: "mapbox://styles/finwayjan/cl26btozu003g15tcg3jkpglg",
                center: MUNICH_CENTER_COORDINATES,
                zoom: 10,
                interactive,
            })

            // TODO_TRM Ver.2 : Implement route drawing feature on the maps.
            // if (interactive) mapboxMap.addControl(Draw, "top-left")

            mapboxMap.once("load", () => {
                setIsMapReady(true)
            })

            setMap(mapboxMap)
        } catch (err) {
            console.error("Failed to load mapbox: ", err)
        }

        return () => {
            mapboxMap?.remove()
        }
    }, [])

    const TravelDetails = () => {
        const formatDuration = duration ? Number(duration) * 1000 : undefined
        const convertedDistance = distance ? convertMeterToKilometer(Number(distance)) : undefined
        if (formatDuration && convertedDistance) {
            // TODO FA-161: Replace style={{ opacity: 0.7, top: 10, right: 10 } w/ tailwind classes after upgrade to v3.x
            return (
                <div className="absolute z-10 text-white flex flex-col px-5 py-10 text-sm border-r-4 bg-black" style={{ opacity: 0.7, top: 10, right: 10 }}>
                    <span>
                        {t("label:mileage.distance")}: {convertedDistance.toFixed(2)} km
                    </span>
                    <span>
                        {t("label:mileage.duration")}: {moment(formatDuration).format(formatDuration > 60 ? "HH:mm" : "mm")}
                    </span>
                </div>
            )
        }

        return <></>
    }

    return (
        <div className="relative w-full h-full">
            <div ref={mapNode} className="w-full h-full" />
            <TravelDetails />
        </div>
    )
}

export default MileageRequestMap
