import { Col, Form, Modal, Row } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"

import CoffeIcon from "Components/icons/coffee"
import Loading from "Components/loading"

interface WaitingModalInterface {
    isVisible: boolean
    onCancel?: () => void
    infoText?: string
}
const WaitingModal: React.FC<WaitingModalInterface> = ({ isVisible, onCancel, infoText }) => {
    const { t } = useTranslation()
    const [formInstance] = Form.useForm()

    const handleHide = () => {
        formInstance.resetFields()
        onCancel?.()
    }

    return (
        <Modal
            title={t("action:waiting.title")}
            visible={isVisible}
            onCancel={handleHide}
            maskClosable={false}
            cancelText={t("action:close")}
            closable={false}
            destroyOnClose
            okButtonProps={{
                style: { display: "none" },
            }}
        >
            <div className="animation-appear">
                <Row gutter={[16, 32]} justify="center">
                    <Col>
                        <Loading className="mt-0 h-50" />
                    </Col>
                    <Col span={24}>
                        <p className="text-center text-lg">{infoText ?? t("info:sit_back_and_wait")}</p>
                    </Col>
                    <Col span={24} className="flex justify-center">
                        <CoffeIcon></CoffeIcon>
                    </Col>
                </Row>
            </div>
        </Modal>
    )
}

export default WaitingModal
