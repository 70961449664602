import { Expense, ExpenseStatusEnum, OperationTypeEnum } from "@finway-group/shared/lib/models"
import { Dispatch } from "react"

import { DONE_STATUSES, IN_PROGRESS_STATUSES, PAY_AND_EXPORT_STATUSES, TODO_STATUSES } from "Shared/config/consts"
import { TableService } from "Shared/services"
import store from "Shared/store"
import { fetchArchivedExpensesCounts, fetchDashboardExpensesCounts, setExpenseCounts, syncExpense } from "Shared/store/actions/expense/expenseActions"
import { fetchDashboardInboxInvoicesCount } from "Shared/store/actions/inboxInvoice/inboxInvoiceActions"
import { refetchExpenseTables, refetchMultipleTables, refetchTable } from "Shared/store/actions/tables/tableActions"
import { TablesEnum } from "Shared/store/reducers/tableConfigReducer"
import { getDashboardTablesForStatus } from "Shared/utils/dashboard.utils"
import { flattenArray } from "Shared/utils/helper.utils"

import { getTableFilterQueryStringStore } from "./table.hooks"

export const refetchDashboardTablesIfNeeded =
    ({
        operationType,
        updatedExpense,
        metaData,
        isExpenseSocketCallback = false,
    }: {
        operationType: OperationTypeEnum
        updatedExpense?: Expense
        metaData: any
        isExpenseSocketCallback: boolean
    }) =>
    async (dispatch: Dispatch<any>) => {
        const currentOpenedExpense = store.getState().expenses.item?.id
        const updatedExpenseStatuses = updatedExpense ? [updatedExpense.status] : []
        switch (operationType) {
            case OperationTypeEnum.INSERT_COMMENT:
            case OperationTypeEnum.UPDATE_COMMENT:
            case OperationTypeEnum.DELETE_COMMENT:
                // Fetching is already done in exepense actions
                if (isExpenseSocketCallback) break

                if (metaData?.isTaggedComment) {
                    dispatch(refetchDashboardTablesBasedOnStatuses(updatedExpenseStatuses))
                    dispatch(fetchDashboardExpensesCounts())
                }
                // if not then nothing to do
                break
            case OperationTypeEnum.ATTACH_TAG:
            case OperationTypeEnum.DETACH_TAG:
                if (currentOpenedExpense && currentOpenedExpense === updatedExpense?.id) dispatch(syncExpense(currentOpenedExpense))

                refetchDashboardTablesBasedOnStatuses(updatedExpenseStatuses)(dispatch)
                break
            case OperationTypeEnum.INSERT:
            case OperationTypeEnum.DELETE:
                if (isExpenseSocketCallback) break

                refetchDashboardTablesBasedOnStatuses(updatedExpenseStatuses)(dispatch)
                dispatch(fetchDashboardExpensesCounts())
                break
            case OperationTypeEnum.UPLOAD_INVOICE_REMINDER:
            case OperationTypeEnum.APPROVE_REMINDER:
                if (currentOpenedExpense && currentOpenedExpense === updatedExpense?.id) dispatch(syncExpense(currentOpenedExpense))
                break
            case OperationTypeEnum.UPLOAD_DOCUMENT:
                if (isExpenseSocketCallback) break

                if (updatedExpense?.invoices.length === 1) {
                    // that means we transitioned from docs-needed into invoice pending
                    refetchDashboardTablesBasedOnStatuses([ExpenseStatusEnum.DOCS_NEEDED, ExpenseStatusEnum.INVOICE_PENDING])(dispatch)
                    dispatch(refetchTable(TablesEnum.INBOX_INVOICE_MODAL_EXPENSES))
                    dispatch(refetchTable(TablesEnum.INBOX_INVOICE))
                    dispatch(fetchDashboardInboxInvoicesCount())
                }
                break
            case OperationTypeEnum.REMOVE_DOCUMENT:
                if (isExpenseSocketCallback) break

                if (operationType === OperationTypeEnum.REMOVE_DOCUMENT && updatedExpense?.invoices.length === 0) {
                    refetchDashboardTablesBasedOnStatuses([updatedExpense?.status, ...(metaData?.previousStatus ? [metaData.previousStatus] : [])])(dispatch)
                    recalculateDashboardCounts(updatedExpense?.status, metaData?.previousStatus)(dispatch)
                }
                break
            case OperationTypeEnum.DOCUMENT_NEEDED_EXPENSE:
                if (isExpenseSocketCallback) break

                // that means we transitioned from purchase-pending to docs-needed
                refetchDashboardTablesBasedOnStatuses([ExpenseStatusEnum.PURCHASE_PENDING, ExpenseStatusEnum.DOCS_NEEDED])(dispatch)
                dispatch(refetchTable(TablesEnum.INBOX_INVOICE_MODAL_EXPENSES))
                // no dashboard counts will change in this case
                break
            case OperationTypeEnum.APPROVE_EXPENSE:
            case OperationTypeEnum.DECLINED_EXPENSE:
            case OperationTypeEnum.RESET_STATUS:
                if (isExpenseSocketCallback) break
                if (updatedExpense?.status) {
                    refetchDashboardTablesBasedOnStatuses([updatedExpense?.status, ...(metaData?.previousStatus ? [metaData.previousStatus] : [])])(dispatch)
                    recalculateDashboardCounts(updatedExpense?.status, metaData?.previousStatus)(dispatch)
                }
                break
            case OperationTypeEnum.REVIEWED_EXPENSE:
                if (isExpenseSocketCallback) break

                // that means we transitioned from approved to reviewed
                refetchDashboardTablesBasedOnStatuses([ExpenseStatusEnum.REVIEWED, ExpenseStatusEnum.APPROVED])(dispatch)
                recalculateDashboardCounts(ExpenseStatusEnum.REVIEWED, ExpenseStatusEnum.APPROVED)(dispatch)
                break
            case OperationTypeEnum.BULK_REVIEWED_EXPENSES:
                if (isExpenseSocketCallback) break

                // That means we transitioned from approved to reviewed
                refetchDashboardTablesBasedOnStatuses([ExpenseStatusEnum.REVIEWED, ExpenseStatusEnum.APPROVED])(dispatch)
                dispatch(fetchDashboardExpensesCounts())
                break
            case OperationTypeEnum.PAID_EXPENSE:
            case OperationTypeEnum.BULK_PAID_EXPENSES:
                if (isExpenseSocketCallback) break
                // that means we transitioned from reviewed to paid
                refetchDashboardTablesBasedOnStatuses([ExpenseStatusEnum.REVIEWED, ExpenseStatusEnum.PAID])(dispatch)
                // no dashboard counts will change in this case
                break
            case OperationTypeEnum.BULK_UPDATE:
            case OperationTypeEnum.DATEV_ONLINE_EXPORT:
            case OperationTypeEnum.BULK_EXPORT_EXPENSES:
                // on mass updates refetch the store
                dispatch(refetchExpenseTables())
                dispatch(fetchDashboardExpensesCounts())
                break

            case OperationTypeEnum.EXPORT_EXPENSE:
                if (updatedExpense?.status) {
                    refetchDashboardTablesBasedOnStatuses([updatedExpense?.status, ...(metaData?.previousStatus ? [metaData.previousStatus] : [])])(dispatch)
                    recalculateDashboardCounts(updatedExpense?.status, metaData?.previousStatus)(dispatch)
                }
                break
            case OperationTypeEnum.SUBMIT_FOLDER:
                if (isExpenseSocketCallback) break

                refetchDashboardTablesBasedOnStatuses([ExpenseStatusEnum.DOCS_NEEDED, ExpenseStatusEnum.INVOICE_PENDING])(dispatch)
                dispatch(fetchDashboardExpensesCounts())
                break
            case OperationTypeEnum.ARCHIVE:
            case OperationTypeEnum.UNARCHIVE:
                if (isExpenseSocketCallback) break
                const isArchiveTabActive = getTableFilterQueryStringStore(TablesEnum.ARCHIVE)
                dispatch(refetchMultipleTables([TablesEnum.ALL_REQUESTS, ...(isArchiveTabActive ? [TablesEnum.ARCHIVE] : [])]))
                refetchDashboardTablesBasedOnStatuses(updatedExpenseStatuses)(dispatch)
                dispatch(fetchDashboardExpensesCounts())

                if (isArchiveTabActive) dispatch(fetchArchivedExpensesCounts())
                break
            case OperationTypeEnum.SYNC_VENDOR_DATA:
                refetchDashboardTablesBasedOnStatuses(updatedExpenseStatuses)(dispatch)
                break
            case OperationTypeEnum.UPDATE:
                if (isExpenseSocketCallback) break
                // core data = any data that is shown in table rows
                if (metaData?.didCoreDataChange) {
                    const didExpenseStatusChange = metaData?.previousStatus !== updatedExpense?.status
                    const statusesToUpdate = [updatedExpense?.status, ...(didExpenseStatusChange ? [metaData?.previousStatus] : [])]
                    refetchDashboardTablesBasedOnStatuses(statusesToUpdate)(dispatch)
                }
                break
            default:
                // nothing to do
                break
        }
    }

const recalculateDashboardCounts = (newStatus: ExpenseStatusEnum, oldStatus?: ExpenseStatusEnum) => async (dispatch: Dispatch<any>) => {
    // if we have any search term or filters applied, we cannot calculate locally but have to fetch instead
    const affectedTables = [...getDashboardTablesForStatus(newStatus), ...(oldStatus ? getDashboardTablesForStatus(oldStatus) : [])]
    const hasSearchEnabled = affectedTables.some((table) => TableService.hasTableSearchOrFilterEnabled(table))
    if (hasSearchEnabled) {
        dispatch(fetchDashboardExpensesCounts())
        return
    }

    const currentCounts = store.getState().expenses.counts

    const handleSingleStatus = (status: ExpenseStatusEnum, fieldName: string) => {
        if (newStatus === status && (!oldStatus || oldStatus !== status)) {
            currentCounts[fieldName] += 1
        } else if (newStatus !== status && oldStatus === status) {
            currentCounts[fieldName] -= 1
        }
    }

    const handleStatusArray = (statuses: Array<ExpenseStatusEnum>, fieldName: string) => {
        if (statuses.includes(newStatus) && (!oldStatus || !statuses.includes(oldStatus))) {
            currentCounts[fieldName] += 1
        } else if (!statuses.includes(newStatus) && oldStatus && statuses.includes(oldStatus)) {
            currentCounts[fieldName] -= 1
        }
    }

    handleStatusArray(TODO_STATUSES, "toDo")
    handleSingleStatus(ExpenseStatusEnum.APPROVED, "review")
    handleStatusArray(PAY_AND_EXPORT_STATUSES, "payExport")
    handleStatusArray(DONE_STATUSES, "done")
    handleStatusArray(IN_PROGRESS_STATUSES, "inProgress")
    handleSingleStatus(ExpenseStatusEnum.DOCS_NEEDED, "docs_needed")

    dispatch(setExpenseCounts(currentCounts))
}

const refetchDashboardTablesBasedOnStatuses = (statuses: Array<ExpenseStatusEnum>) => async (dispatch: Dispatch<any>) => {
    const tables = flattenArray([...new Set(statuses.map((status) => getDashboardTablesForStatus(status)))])
    dispatch(refetchMultipleTables(tables))
}
