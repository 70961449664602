import { Button, Empty } from "antd"
import React from "react"
import { Loader as LoaderIcon } from "react-feather"
import { useTranslation } from "react-i18next"

import TransactionArchiveSearchModal from "Components/modals/transactionArchiveSearch.modal"
import { useModal } from "Shared/context/modal.context"
import { TablesEnum } from "Shared/store/reducers/tableConfigReducer"

interface ArchiveInterface {}

const TransactionArchiveTab: React.FC<ArchiveInterface> = () => {
    const { t } = useTranslation()

    const { showModal } = useModal()

    return (
        <Empty
            image={`./icons/no_data_found.svg`}
            imageStyle={{
                height: 150,
                marginRight: "auto",
                marginLeft: "auto",
                marginTop: "15%",
                marginBottom: "40px",
                display: "inline-block",
            }}
            description={<span className="text-lg font-bold mt-2 mb-0">{t("info:archive.transaction_initial_display.title")}</span>}
        >
            <div className="flex flex-col justify-between items-center gap-y-4">
                <p className="mb-20 w-400">{t("info:archive.transaction_initial_display.message")}</p>
                <Button
                    size="small"
                    type="primary"
                    onClick={() => showModal(TransactionArchiveSearchModal, true, { isShowing: true, archiveTable: TablesEnum.TRANSACTIONS_ARCHIVE })}
                >
                    <LoaderIcon />
                    {t("action:archive.load")}
                </Button>
            </div>
        </Empty>
    )
}

export default TransactionArchiveTab
