import { COLOR } from "@finway-group/shared/lib/consts"
import { TransactionStatusEnum } from "@finway-group/shared/lib/models/transaction/transactionStatus.enum"
import { Tag, Tooltip } from "antd"
import React from "react"
import { AlertTriangle } from "react-feather"
import { useTranslation } from "react-i18next"

interface StatusTagInterface {
    status: TransactionStatusEnum
    isTransactionPendingForTooLong?: boolean
    isReversal?: boolean
}

const TransactionStatusTag: React.FC<StatusTagInterface> = ({ status, isReversal = false, isTransactionPendingForTooLong = false }) => {
    const { t } = useTranslation()
    let tagClass
    let label

    if (isReversal) {
        tagClass = "ant-tag-grey"
        label = t("label:matching.transaction_tag.reversal")
    } else {
        switch (status) {
            case TransactionStatusEnum.PENDING:
                tagClass = "ant-tag-yellow"
                label = t("label:matching.transaction_tag.pending")
                break
            case TransactionStatusEnum.COMPLETED:
                tagClass = "ant-tag-green"
                label = t("label:matching.transaction_tag.completed")
                break
            case TransactionStatusEnum.FAILED:
                tagClass = "ant-tag-red"
                label = t("label:matching.transaction_tag.failed")
                break
        }
    }

    return (
        <div className="flex align-middle justify-center">
            <Tag className={`${tagClass}`}>{label}</Tag>
            {isTransactionPendingForTooLong && (
                <Tooltip title={t("info:matching.pending_bank_transaction.tooltip")}>
                    <AlertTriangle fill={COLOR.finway.orange} size={18} stroke={COLOR.white} />
                </Tooltip>
            )}
        </div>
    )
}

export default TransactionStatusTag
