import { LoadingOutlined } from "@ant-design/icons"
import { COLOR } from "@finway-group/shared/lib/consts"
import { Employee, PriceIntervalEnum, RightEnum } from "@finway-group/shared/lib/models"
import { Empty, Table, Tooltip } from "antd"
import { ColumnsType } from "antd/lib/table"
import React from "react"
import { AlertTriangle } from "react-feather"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"

import NoSearchDataFound from "Components/NoSearchDataFound"
import UserImage from "Components/UserImage"
import DataUploadAlert from "Components/alert/dataUpload.alert"
import FilterSection from "Components/filterSection"
import { ErrorTable } from "Components/layout/errorTable"
import Loading from "Components/loading"
import LoadingData from "Components/loading.data"
import PriceLabel from "Components/priceLabel"
import { useGroupedExpenseData } from "Shared/hooks/analytics.hooks"
import { useFetchTable, useTable, useTableFilterAndSearchLoading } from "Shared/hooks/table.hooks"
import { useLoggedInEmployeeProfile } from "Shared/hooks/user.hooks"
import { AuthzService } from "Shared/services"
import { ThunkDispatchResult } from "Shared/store"
import { refetchTable, updateTableOnChange } from "Shared/store/actions/tables/tableActions"
import { setVendor as setEmployee } from "Shared/store/actions/vendor/vendorActions"
import { TablesEnum } from "Shared/store/reducers/tableConfigReducer"
import { EmployeeWithExpenseData } from "Shared/store/reducers/tableReducer"
import { insertIf } from "Shared/utils/helper.utils"
import { getSortOrderForColumn } from "Shared/utils/table.utils"

const EmployeeVendorTable: React.FC<{}> = () => {
    const { t } = useTranslation()
    const history = useHistory()
    const dispatch: ThunkDispatchResult = useDispatch()

    const employeeVendorsTable = useTable<EmployeeWithExpenseData>(TablesEnum.EMPLOYEE_VENDORS)

    const currentEmployee = useLoggedInEmployeeProfile()
    const { globalCurrency } = currentEmployee.settings
    const pageLoading = useTableFilterAndSearchLoading(TablesEnum.EMPLOYEE_VENDORS)
    useFetchTable(TablesEnum.EMPLOYEE_VENDORS)

    const { expenseData, setCreditorIds } = useGroupedExpenseData(TablesEnum.EMPLOYEES, employeeVendorsTable, "creditorUser")

    const getEmployeeCreditorNumber = (employee: Employee) => {
        const company: any = employee.activeCompanyProfile
        return company?.creditorNumber
    }

    const columns: ColumnsType<EmployeeWithExpenseData> = [
        {
            title: t("label:employee"),
            key: "name",
            width: 300,
            className: "pl-10",
            ellipsis: true,
            sortOrder: getSortOrderForColumn(employeeVendorsTable, "name"),
            sorter: (a, b) => `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`),
            render: (employee: EmployeeWithExpenseData) => (
                <div className="flex items-center image-loader gap-10">
                    <UserImage user={employee} size="large" />
                    <div className="flex flex-col truncate">
                        <div className="flex items-center mb-3">
                            <p className="font-bold leading-tight mb-0 truncate">
                                {employee.firstName} {employee.lastName}
                            </p>
                        </div>
                        <p className="text-xs text-text leading-none mb-0 truncate">{employee.position}</p>
                    </div>
                </div>
            ),
        },
        {
            title: t("label:creditor_number"),
            key: "creditorNumber",
            width: 150,
            sortOrder: getSortOrderForColumn(employeeVendorsTable, "creditorNumber"),
            sorter: (employeeA: Employee, employeeB: Employee) => (getEmployeeCreditorNumber(employeeA) ?? 0) - (getEmployeeCreditorNumber(employeeB) ?? 0),
            render: (employee: Employee) =>
                getEmployeeCreditorNumber(employee) ?? (
                    <Tooltip title={t("info:employee_no_creditor_number")}>
                        <AlertTriangle fill={COLOR.finway.orange} size={18} stroke={COLOR.white} className="mt-2" />
                    </Tooltip>
                ),
        },

        ...insertIf(AuthzService.isRightGrantedForLoggedInUser(RightEnum.EXPENSE__ALL__READ), {
            title: t("label:expenses"),
            key: "expenses",
            width: 100,
            align: "right",
            className: "pr-10",
            render: (employee: EmployeeWithExpenseData) => {
                const employeeExpenseData = expenseData.find((data) => data.id === employee.id)
                if (!employeeExpenseData) return <LoadingData />

                return <PriceLabel value={employeeExpenseData.value || 0} currency={globalCurrency} interval={PriceIntervalEnum.ONE_TIME} />
            },
        }),
    ]

    return (
        <div>
            <FilterSection table={TablesEnum.EMPLOYEE_VENDORS} />

            {pageLoading ? (
                <Loading />
            ) : employeeVendorsTable.error ? (
                <ErrorTable onTableReload={() => dispatch(refetchTable(TablesEnum.EMPLOYEE_VENDORS))} isLoading={employeeVendorsTable.isFetching} />
            ) : employeeVendorsTable.data.totalDocs === 0 && !employeeVendorsTable.isFetching ? (
                !employeeVendorsTable.data.search ? (
                    <>
                        {AuthzService.isDataUploaderGrantedForLoggedInUser() && <DataUploadAlert />}

                        <Empty
                            className="animation-appear"
                            image={`./icons/empty_table.svg`}
                            imageStyle={{
                                height: 150,
                                marginRight: "auto",
                                marginLeft: "auto",
                                marginTop: "15%",
                                marginBottom: "40px",
                                display: "inline-block",
                            }}
                            description={<span className="text-lg font-bold mt-2 mb-0">{t("info:empty_state.employees.title")}</span>}
                        >
                            <div className="flex flex-col justify-between items-center">
                                <p>{t("info:empty_state.employees.message")}</p>
                            </div>
                        </Empty>
                    </>
                ) : (
                    <div className="animation-appear">
                        <NoSearchDataFound />
                    </div>
                )
            ) : (
                <div>
                    <div className="overflow-auto p-2 animation-appear">
                        <Table<EmployeeWithExpenseData>
                            rowKey={(record) => record.id}
                            columns={columns}
                            pagination={{
                                position: ["bottomRight"],
                                showSizeChanger: true,
                                current: employeeVendorsTable.data.search ? 1 : employeeVendorsTable.data.page,
                                pageSize: employeeVendorsTable.data.limit,
                                hideOnSinglePage: false,
                                pageSizeOptions: ["5", "10", "20", "50", "100"],
                            }}
                            onChange={(pagination, filters, sorter, extra) => {
                                const onChange = updateTableOnChange(TablesEnum.EMPLOYEE_VENDORS, employeeVendorsTable.data, false)
                                onChange(pagination, filters, sorter)

                                const creditorIds = extra.currentDataSource.map((employeeWithExpenseData: EmployeeWithExpenseData) => employeeWithExpenseData.id)
                                setCreditorIds(creditorIds)
                            }}
                            dataSource={employeeVendorsTable.data.docs}
                            loading={{
                                spinning: employeeVendorsTable.isFetching,
                                indicator: <LoadingOutlined style={{ fontSize: 30, color: "black" }} spin />,
                            }}
                            onRow={(employee: Employee) => ({
                                onClick: () => {
                                    dispatch(setEmployee(employee))
                                    history.push(`/employees/${employee.id}`)
                                },
                            })}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}

export default EmployeeVendorTable
