import { Col, Form, Input, Row } from "antd"
import React from "react"

import { blurInputOnWeel } from "Shared/utils/helper.utils"

interface FormTextInputInterface {
    initialValue?: string
    label: string
    name: string
    placeholder: string
    link?: {
        to: string
        label: string
    }
    rules?: any
}

export default function FormTextInput({ initialValue, label, name, placeholder, link, rules }: FormTextInputInterface) {
    return (
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <Form.Item
                    label={label}
                    initialValue={initialValue}
                    name={name}
                    className="mb-0"
                    rules={rules}
                    extra={
                        link && (
                            <a href={link.to} rel="noreferrer" target="_blank">
                                {link.label}
                            </a>
                        )
                    }
                >
                    <Input type="text" placeholder={placeholder} onWheel={blurInputOnWeel} />
                </Form.Item>
            </Col>
        </Row>
    )
}
