import { SsoProviderTypeEnum } from "@finway-group/shared/lib/models"
import React, { MouseEvent, useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useHistory, useLocation } from "react-router-dom"

import AzureActiveDirectoryLogo from "Components/icons/logos/azureActiveDirectory.logo"
import GoogleLogo from "Components/icons/logos/google.logo"
import { API_URL, WEBAPP_VERSION } from "Shared/config/consts"
import { useSsoProviderTypes } from "Shared/queries/sso.queries"
import { LocalStorageService, NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { ThunkDispatchResult } from "Shared/store"
import { createLoginAction } from "Shared/store/actions/auth/authActions"
import { FetcherActionTypes, StartLoadingAction } from "Shared/store/actions/fetcher/fetcherTypes"

import SocialLoginButton from "./SocialLoginButton"

export default function SocialLogins() {
    const { t } = useTranslation()
    const { data: ssoProviderTypes } = useSsoProviderTypes()
    const { search } = useLocation()

    const history = useHistory()
    const dispatch: ThunkDispatchResult = useDispatch()

    const ssoAccessToken = useMemo(() => new URLSearchParams(search).get("token"), [search])
    const ssoLoginError = useMemo(() => new URLSearchParams(search).get("error"), [search])

    const redirect = (url: string) => window.open(`${url}?host=${window.location.host}&clientVersion=${WEBAPP_VERSION}`, "_self")

    const loginWithGoogle = async (e: MouseEvent<HTMLElement>) => {
        e.preventDefault()
        redirect(`${API_URL}/sso/google`)
    }

    const loginWithAzureActiveDirectory = async (e: MouseEvent<HTMLElement>) => {
        e.preventDefault()
        redirect(`${API_URL}/sso/azure-ad`)
    }

    /**
     * listen to url to retrieve, and set accessToken
     */
    useEffect(() => {
        if (ssoAccessToken) {
            LocalStorageService.setAccessToken(ssoAccessToken)

            const setLoadingAction: StartLoadingAction = {
                type: FetcherActionTypes.START_LOADING,
                hasLoaded: false,
            }
            dispatch(setLoadingAction)

            const loginAction = createLoginAction(ssoAccessToken)
            dispatch(loginAction)
        }
    }, [ssoAccessToken])

    /**
     * login error handler
     */
    useEffect(() => {
        if (ssoLoginError) {
            NotificationService.send(NotificationTypeEnum.ERROR, t("error:error"), ssoLoginError)
            history.replace({ search: "" })
        }
    }, [ssoLoginError])

    const showOr = (ssoProviderTypes?.length ?? 0) > 0
    const showGoogle = ssoProviderTypes?.includes(SsoProviderTypeEnum.GOOGLE)
    const showAzure = ssoProviderTypes?.includes(SsoProviderTypeEnum.AZURE_AD)

    return (
        <div className={`flex gap-8 w-full flex-col items-center`}>
            <span className={`text-gray-700 ${showOr ? "" : "hidden"}`}>{t("label:or")}</span>

            <div className={`w-full ${showGoogle ? "" : "hidden"}`}>
                <SocialLoginButton
                    Icon={GoogleLogo}
                    onClick={loginWithGoogle}
                    text={t("action:sso.login_with_x", {
                        key: t("label:sso.providers.google.title"),
                    })}
                />
            </div>

            <div className={`w-full ${showAzure ? "" : "hidden"}`}>
                <SocialLoginButton
                    Icon={AzureActiveDirectoryLogo}
                    onClick={loginWithAzureActiveDirectory}
                    text={t("action:sso.login_with_x", {
                        key: t("label:sso.providers.azure_ad.title"),
                    })}
                />
            </div>
        </div>
    )
}
