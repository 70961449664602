import { ActivityFilterEnum } from "@finway-group/shared/lib/models"
import { Button } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"

import { useMarkAllNotificationsAsViewed } from "Shared/queries/notification.queries"
import { NotificationService } from "Shared/services"

export const NotificationMarkAllAsViewedButton = ({ onSuccess }: { onSuccess: () => void }) => {
    const { t } = useTranslation()

    const markAllNotificationsAsViewed = useMarkAllNotificationsAsViewed()

    const handleMarkAllActivitiesAsViewed = () => {
        markAllNotificationsAsViewed
            .mutateAsync()
            .then(() => {
                onSuccess()
            })
            .catch((error) => {
                NotificationService.showErrorNotificationBasedOnResponseError(error, t("error:error"))
            })
    }

    return (
        <Button type="default" onClick={handleMarkAllActivitiesAsViewed} className="m-5 px-14 max-w-fit inline-block">
            {t("label:mark_all_as_read")}
        </Button>
    )
}
