import { SsoProviderType } from "@finway-group/shared/lib/models"
import { Form } from "antd"
import { FormInstance } from "antd/lib/form"
import React from "react"
import { useTranslation } from "react-i18next"

import Copy from "Components/copy"
import FormTextInput from "Components/form/formTextInput"
import { ssoRules } from "Components/forms/rules/sso.rules"
import { DEV_CLIENT_PORT } from "Shared/config/consts"
import { isDev } from "Shared/utils/helper.utils"

type SsoProviderConfigurationModalType = {
    formInstance: FormInstance
    connection?: SsoProviderType
    handleSubmit: (data: SsoProviderType) => void

    learnMoreLink: JSX.Element

    redirectUrl: string

    clientIdLink?: {
        to: string
        label: string
    }
}

const SsoProviderConfigurationForm = ({ formInstance, connection, learnMoreLink, redirectUrl, clientIdLink, handleSubmit }: SsoProviderConfigurationModalType) => {
    const { t } = useTranslation()

    const rules = ssoRules()

    const copyPasteBlock = (text: string, link: string) => (
        <div className="sso-copy-paste">
            <span>{text}:</span>
            <div>
                <code className="text-xs">{link}</code>
                <Copy value={link} />
            </div>
        </div>
    )

    return (
        <Form id="ssoProviderConfigurationForm" form={formInstance} onFinish={handleSubmit} layout="vertical">
            <p>
                {`${t("label:sso.easily_setup")} `}
                {learnMoreLink}
            </p>

            <hr className="pb-10 mt-10" />

            <div className="flex flex-col gap-4">
                {copyPasteBlock(t("label:sso.config.origins"), `http${isDev ? "" : "s"}://${window.location.hostname}${isDev ? `:${DEV_CLIENT_PORT}` : ""}`)}
                {copyPasteBlock(t("label:sso.config.redirects"), redirectUrl)}
            </div>

            <hr className="pb-10 mt-10" />

            <FormTextInput
                label={t("input:sso.tenant_domain")}
                name="domain"
                placeholder={t("placeholder:sso.tenant_domain")}
                rules={rules.domain}
                initialValue={connection?.domain}
            />

            <FormTextInput
                key="clientIdKey"
                label={t("input:sso.client_id")}
                name="clientId"
                placeholder={t("placeholder:sso.client_id")}
                link={clientIdLink}
                rules={rules.clientId}
                initialValue={connection?.clientId}
            />

            <FormTextInput
                label={t("input:sso.client_secret")}
                name="clientSecret"
                placeholder={t("placeholder:sso.client_secret")}
                rules={rules.clientSecret}
                initialValue={connection?.clientSecret}
            />
        </Form>
    )
}

export default SsoProviderConfigurationForm
