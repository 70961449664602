import { CostCenter1BudgetEnum, CostCenterFilter, CurrencyEnum, Employee } from "@finway-group/shared/lib/models"
import Form from "antd/lib/form"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"

import { FilterFormInterface } from "Components/filter/filterTypes"
import { FormInputEnum, FormSelectOption } from "Components/form/form.types"
import FormMinMax from "Components/form/formMinMax"
import FormMinMaxCurrency from "Components/form/formMinMaxCurrency"
import FormSelect from "Components/form/formSelect"
import { getTableFilterObjectStore } from "Shared/hooks/table.hooks"
import { UserService } from "Shared/services"
import { createDynamicList } from "Shared/utils/filter.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

import { generateRules } from "./rules/costCenterFilter.rules"

const adjustFilterObject = (filterObject: CostCenterFilter): CostCenterFilter => ({
    ...filterObject,
    minBudget: filterObject.minBudget ? filterObject.minBudget : undefined,
    maxBudget: filterObject.maxBudget ? filterObject.maxBudget : undefined,
    minCostCenterId: filterObject.minCostCenterId ? filterObject.minCostCenterId : undefined,
    maxCostCenterId: filterObject.maxCostCenterId ? filterObject.maxCostCenterId : undefined,
    currency: filterObject.currency ? filterObject.currency : CurrencyEnum.EUR,
})

const CostCenterFilterForm: React.FC<FilterFormInterface> = ({ table, formInstance, options, onSubmit }) => {
    const { t } = useTranslation()

    const tableFilterObject = getTableFilterObjectStore<CostCenterFilter>(table)
    const [costCenterFilter, setCostCenterFilter] = useStateIfMounted(adjustFilterObject(tableFilterObject))

    const rules = generateRules(formInstance)
    const employeeList = UserService.getApproverEmployeeList(options.employees as Array<Employee>)

    useEffect(() => {
        formInstance.resetFields()
        setCostCenterFilter(adjustFilterObject(costCenterFilter))
    }, [])

    useEffect(() => {
        formInstance.validateFields()
    }, [costCenterFilter])

    const budgetList: Array<FormSelectOption> = createDynamicList("input:cost_center.budget", [CostCenter1BudgetEnum.ABOVE_BUDGET, CostCenter1BudgetEnum.WITHIN_BUDGET])

    return (
        <Form id="costCenterFilterForm" className="form--filter" form={formInstance} onFinish={onSubmit}>
            <FormMinMaxCurrency
                header={t("input:filter_form.budget")}
                min={{
                    name: "minBudget",
                    label: t("input:filter_form.min_amount"),
                    placeholder: t("placeholder:expense_filter.min_amount"),
                    onChange: () => formInstance.validateFields(["maxBudget", "minBudget"]),
                    initialValue: costCenterFilter.minBudget,
                    rules: rules.minBudget,
                }}
                max={{
                    name: "maxBudget",
                    label: t("input:filter_form.max_amount"),
                    placeholder: t("placeholder:expense_filter.max_amount"),
                    onChange: () => formInstance.validateFields(["maxBudget", "minBudget"]),
                    initialValue: costCenterFilter.maxBudget,
                    rules: rules.maxBudget,
                }}
                currency={costCenterFilter.currency}
            />

            <FormSelect name="budgetStatus" header={t("input:cost_center.budget.status")} initialValue={costCenterFilter.budgetStatus} options={budgetList} isCollapse fallback />

            <FormSelect
                name="responsibleUser"
                header={t("input:cost_center.responsible_user")}
                initialValue={costCenterFilter.responsibleUser}
                options={employeeList}
                displayImages
                isCollapse
                showSearch
                fallback
            />

            <FormSelect
                name="sharedWith"
                header={t("input:cost_center.shared_with")}
                initialValue={costCenterFilter.sharedWith}
                options={options.employees ?? []}
                displayImages
                showSearch
                isCollapse
                fallback
            />

            <FormMinMax
                header={t("input:cost_center.id")}
                inputType={FormInputEnum.NUMBER}
                min={{
                    name: "minCostCenterId",
                    label: t("input:filter_form.min_amount"),
                    placeholder: t("placeholder:expense_filter.min_amount"),
                    onChange: () => formInstance.validateFields(["maxCostCenterId", "minCostCenterId"]),
                    initialValue: costCenterFilter.minCostCenterId,
                    rules: rules.minCostCenterId,
                }}
                max={{
                    name: "maxCostCenterId",
                    label: t("input:filter_form.max_amount"),
                    placeholder: t("placeholder:expense_filter.max_amount"),
                    onChange: () => formInstance.validateFields(["maxCostCenterId", "minCostCenterId"]),
                    initialValue: costCenterFilter.maxCostCenterId,
                    rules: rules.maxCostCenterId,
                }}
            />
        </Form>
    )
}

export default CostCenterFilterForm
