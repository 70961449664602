import { Expense, ExpenseKindEnum, RightEnum, TripFolder } from "@finway-group/shared/lib/models"
import { ExpensePaymentOptionEnum } from "@finway-group/shared/lib/models/expense/expensePaymentOption.enum"
import { getApproverIdsOfApprovalProcesses } from "@finway-group/shared/lib/utils/approvalProcess.utils"
import { Tag, Tooltip } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"

import { AuthzService, ExpenseService, UserService } from "Shared/services"
import { getReimbursementLabel, isFolderExpense, showPaymentFailedAlert } from "Shared/utils/expense.utils"
import { parseHtml } from "Shared/utils/htmlParser.utils"

import ExpenseStatusTag from "./expenseStatusTag"
import ExportTag from "./exportTag"
import { FolderIcon } from "./icons"
import SharedExpenseTag from "./sharedExpenseTag"

interface RequestColumnInterface {
    request: Expense
    showStatus?: boolean
}

const RequestColumn: React.FC<RequestColumnInterface> = ({ request, showStatus }) => {
    const { t } = useTranslation()
    const loggedInProfile = UserService.getLoggedInEmployeeProfile()
    const {
        expenseNumber,
        kind,
        description,
        isReimbursement,
        paymentOption,
        isAutoGenerated,
        payment,
        totalNetPrice,
        totalGrossPrice,
        isInPaymentProcess,
        status,
        userTagging,
        requestedBy,
    } = request
    const creditorName = request.getCreditor()?.name
    const isMyExpense = requestedBy._id === loggedInProfile.id

    const isApproverOfAnyApprovalProcess = getApproverIdsOfApprovalProcesses(request.approvalProcesses).includes(loggedInProfile.id)
    const displayUserAsTagged = ExpenseService.shouldDisplayUserAsTagged(isMyExpense, isApproverOfAnyApprovalProcess, loggedInProfile, request)
    const isTripFolder = request.kind === ExpenseKindEnum.TRIP_FOLDER

    const TripFolderDescription = () => (
        <p className="flex items-center gap-6">
            <FolderIcon />
            <span>{loggedInProfile.settings.showExpenseNumbers && `#${request.expenseNumber}`}</span>
            <span className="tracking-wide">{(request as TripFolder).folderName}</span>
        </p>
    )

    return (
        <div className="flex-1 overflow-hidden max-w-md">
            <div className="flex items-center gap-6">
                <span className="text-text-dark font-bold mt-3 mb-3 leading-none truncate min-w-request-column-title">
                    {!isFolderExpense(request) ? creditorName ?? "n/a" : `${requestedBy.firstName} ${requestedBy.lastName}`}
                </span>
                {displayUserAsTagged && <SharedExpenseTag tagging={userTagging?.filter(({ tagged }) => tagged === loggedInProfile.id)} />}
                {kind === ExpenseKindEnum.SUBSCRIPTION && <Tag className="ant-tag-blue">{t("label:recurring")}</Tag>}
                {paymentOption === ExpensePaymentOptionEnum.SMART_CARD && <Tag className="ant-tag-blue">{t("label:cards.card")}</Tag>}
                {AuthzService.isRightGrantedForLoggedInUser(RightEnum.EXPENSE__ALL__ACCESS_TABLE_EXPORT_READ) && <ExportTag expense={request} />}
                {isReimbursement && (
                    <Tooltip title={t(`tooltips:reimbursement_types.${getReimbursementLabel(kind)}`)}>
                        <Tag className="ant-tag-blue">{t(`label:reimbursement_types.${getReimbursementLabel(kind)}`)}</Tag>{" "}
                    </Tooltip>
                )}
                {isTripFolder && <Tag className="ant-tag-blue">{t(`label:trip_folder`)}</Tag>}
                {(totalNetPrice < 0 || totalGrossPrice < 0) && <Tag className="ant-tag-green">{t("label:refund")}</Tag>}
                {isInPaymentProcess && <Tag className="ant-tag-gray">{t("label:payment_pending.tag")}</Tag>}
                {showPaymentFailedAlert(status, payment) && <Tag color="error">{t("error:payment_failed.tag")}</Tag>}
                {showStatus && <ExpenseStatusTag status={status} />}
            </div>
            <div>
                <p className="text-text text-sm sm:text-base leading-tight whitespace-normal max-h-20 truncate">
                    {isTripFolder ? <TripFolderDescription /> : loggedInProfile.settings.showExpenseNumbers && `# ${expenseNumber}${description || isAutoGenerated ? " - " : ""}`}
                    {description ? parseHtml(description) : isAutoGenerated ? <span className="italic">{t("info:auto_generated_request")}</span> : null}
                </p>
            </div>
        </div>
    )
}

export default RequestColumn
