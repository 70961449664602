import { CostCenter } from "@finway-group/shared/lib/models"
import { Reducer } from "redux"

import {
    CostCenterActionTypes,
    CostCenterActions,
    FetchAllCostCentersAction,
    FetchOneCostCenterAction,
    NewCostCenterAction,
    ResetCostCenterAction,
    SyncOneCostCenterAction,
    UpdateConstCenterAction,
} from "../actions/costCenter/costCenterTypes"

export interface CostCenterState {
    items: Array<CostCenter>
    item?: CostCenter
}

const initialState = {
    items: [],
    item: undefined,
}

const fetchAllCostCenters = (state: CostCenterState, action: FetchAllCostCentersAction): CostCenterState => {
    let { item } = state
    if (item) {
        const itemInAction = action.costCenters.find(({ id }: CostCenter) => id === item?.id)
        if (itemInAction) item = itemInAction
    }

    return {
        ...state,
        items: action.costCenters,
        item,
    }
}

const fetchOneCostCenter = (state: CostCenterState, action: FetchOneCostCenterAction): CostCenterState => ({
    ...state,
    item: action.costCenter,
})

const syncOneCostCenter = (state: CostCenterState, { costCenter }: SyncOneCostCenterAction): CostCenterState => ({
    ...state,
    items:
        state.items.filter((costCenterItem) => costCenterItem._id === costCenter._id).length > 0
            ? state.items.map((item) => (item._id === costCenter._id ? costCenter : item))
            : [costCenter, ...state.items],
    item: state.item?._id === costCenter._id ? costCenter : state.item,
})

const newCostCenter = (state: CostCenterState, action: NewCostCenterAction): CostCenterState => ({
    ...state,
    items: [...state.items, action.costCenter],
})

const updateCostCenter = (state: CostCenterState, action: UpdateConstCenterAction): CostCenterState => ({
    ...state,
    item: action.costCenter,
    items: state.items.map((costCenter: CostCenter) =>
        costCenter._id !== action.costCenter._id ? costCenter : { ...costCenter, ...action.costCenter, parentCostCenter: action.costCenter?.parentCostCenter },
    ),
})

const resetCostCenter = (state: CostCenterState, action: ResetCostCenterAction): CostCenterState => ({
    ...state,
    item: undefined,
})

export const costCenterReducer: Reducer<CostCenterState, CostCenterActions> = (state = initialState, action) => {
    switch (action.type) {
        case CostCenterActionTypes.FETCH_ALL_COST_CENTERS:
            return fetchAllCostCenters(state, action)
        case CostCenterActionTypes.FETCH_ONE_COST_CENTER:
            return fetchOneCostCenter(state, action)
        case CostCenterActionTypes.SYNC_ONE_COST_CENTER:
            return syncOneCostCenter(state, action)
        case CostCenterActionTypes.NEW_COST_CENTER:
            return newCostCenter(state, action)
        case CostCenterActionTypes.UPDATE_COST_CENTER:
            return updateCostCenter(state, action)
        case CostCenterActionTypes.RESET_COST_CENTER:
            return resetCostCenter(state, action)
        default:
            return state
    }
}
