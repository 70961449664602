import React from "react"
import { Redirect, Route } from "react-router-dom"

import FullScreenLoader from "Components/FullScreenLoader"
import Main from "Components/layout/Main"
import NotFoundPage from "Features/errors/NotFoundPage"
import { useIsUserRightsReady } from "Shared/hooks/authz.hooks"
import { useIsFreeVersion } from "Shared/hooks/featureFlags.hooks"
import { LocalStorageService } from "Shared/services"

import { ErrorRoute } from "./ErrorRoute"

// Imports of shared modules
interface PrivateRouteInterface {
    path: string
    children: any
    exact?: boolean
    canSee?: boolean
    includedFreeVersion?: boolean
    location?: string
}

export const PrivateRoute: React.FC<PrivateRouteInterface> = ({ path, canSee = true, includedFreeVersion = false, exact = false, children, location }) => {
    const isTenantOnFreeVersion = useIsFreeVersion()

    // if user it not logged in redirect to login regardless of tenant flags or role
    if (!LocalStorageService.isAuthorized()) return <Redirect to={{ pathname: "/login", state: { from: location } }} />
    // const { isAuthenticated } = useAuth()
    // if (!isAuthenticated) return <Redirect to={{ pathname: "/login", state: { from: location } }} />

    // if tenant of user is only allowed free version forbid access to paid features
    if (isTenantOnFreeVersion && !includedFreeVersion)
        return (
            <ErrorRoute>
                <NotFoundPage />
            </ErrorRoute>
        )

    // if user canSee aka has the correct role render route
    if (canSee)
        return (
            <Route {...{ path, exact }}>
                <Main>{children}</Main>
            </Route>
        )

    // redirect to landing page
    return <Redirect to={{ pathname: "/", state: { from: location } }} />
}
export default PrivateRoute
