import { Expense, Transaction } from "@finway-group/shared/lib/models"
import { useEffect } from "react"
import { useSelector } from "react-redux"

import { TransactionService } from "Shared/services"
import store from "Shared/store"
import { refetchTable } from "Shared/store/actions/tables/tableActions"
import { TablesEnum } from "Shared/store/reducers/tableConfigReducer"
import { RootState } from "Shared/store/rootState.interface"
import { filterTransactionsToDisplay } from "Shared/utils/creditCard.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

export const useTransaction = () => useSelector(({ transactions }: RootState) => transactions.item)

export const useActiveTransactionTab = () => useSelector(({ transactions }: RootState) => transactions.activeTransactionTab)

export const useExpenseMatchedTransaction = (expense: Expense) => {
    const [matchedTransactions, setMatchedTransactions] = useStateIfMounted<Array<Transaction>>([])

    const transactionsToDisplay = filterTransactionsToDisplay(matchedTransactions, true)

    useEffect(() => {
        if (expense.matchedTransactions.length === 0) {
            setMatchedTransactions([])
            return
        }

        // expense.matchedTransactions should be populated
        if (typeof expense.matchedTransactions[0] === "object") {
            setMatchedTransactions(expense.matchedTransactions as Array<Transaction>)
        }
    }, [expense.id, JSON.stringify(expense.matchedTransactions)])

    return { matchedTransactions, transactionsToDisplay }
}

export const handleArchiveTransactions = async (transactionIds: Array<string>, table: TablesEnum) => {
    await TransactionService.archiveTransactions(transactionIds)
    store.dispatch(refetchTable(table))
    store.dispatch(refetchTable(TablesEnum.TRANSACTIONS_ARCHIVE))
}

export const handleUnarchiveTransactions = async (transactionIds: Array<string>) => {
    await TransactionService.unarchiveTransactions(transactionIds)

    store.dispatch(refetchTable(TablesEnum.TRANSACTIONS_INFLOW))
    store.dispatch(refetchTable(TablesEnum.TRANSACTIONS_OUTFLOW))
    store.dispatch(refetchTable(TablesEnum.TRANSACTIONS_ALL))
    store.dispatch(refetchTable(TablesEnum.TRANSACTIONS_ARCHIVE))
}
