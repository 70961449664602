import { ExclamationCircleOutlined } from "@ant-design/icons"
import { Tooltip } from "antd"
import React from "react"

interface FormLabelProps {
    label: string
    tooltip: string
}

export const FormLabel = ({ label, tooltip }: FormLabelProps) => (
    <span className="inline-flex items-center">
        {label}
        <Tooltip title={tooltip} placement="right" className="align-middle">
            <ExclamationCircleOutlined className="ml-6" />
        </Tooltip>
    </span>
)
