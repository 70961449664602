import { AccessTokenType, FeatureFlags, User } from "@finway-group/shared/lib/models"
import jwt from "jsonwebtoken"
import { useEffect, useMemo, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useLocation } from "react-router-dom"

import { FINWAY_ADMIN_EMAIL } from "Shared/config/consts"
import { AuthnService, NotificationService } from "Shared/services"
import LocalStorageService, { ACCESS_TOKEN } from "Shared/services/localstorage.service"
import { createLoginAction } from "Shared/store/actions/auth/authActions"
import { fetchAllCompanies } from "Shared/store/actions/company/companyActions"
import { setFeatureFlags } from "Shared/store/actions/featureFlags/featureFlagsActions"
import { fetchApiVersion } from "Shared/store/actions/fetcher/fetcherActions"
import { RootState } from "Shared/store/rootState.interface"

import useLocalStorage from "./useLocalStorage"

export const useAuth = () => {
    const dispatch = useDispatch()

    const previousAuthenticatedState = useRef(!!LocalStorageService.getAccessToken())
    const history = useHistory()
    const location = useLocation()
    const isAccessTokenInRedux = !!useSelector(({ auth: { accessToken } }: RootState) => accessToken)

    const logout = async () => {
        await AuthnService.logout()
        LocalStorageService.unsetAccessToken()
    }

    const { userClass, isSsoLogin, activeRights, featureFlags, companyId } = useAccessTokenData()

    const isAuthenticated = useMemo(() => !!userClass?.email, [userClass.email])

    const redirect = () => {
        NotificationService.clear()

        /**
         * sso logins
         */
        if (!isSsoLogin && userClass.passwordExpired) {
            history.push("/new-password")
            return
        }

        /**
         * in-app re-auth redirects
         */
        if (window.history.state && window.history.state.idx > 0) {
            history.goBack()
            return
        }

        history.push("/")
    }

    /**
     * automatically re-direct from /login if logged-in
     */
    useEffect(() => {
        if (location.pathname === "/login" && isAuthenticated) {
            redirect()
        }
    }, [location, isAuthenticated])

    useEffect(() => {
        /**
         * trigger login flow once accessToken has been found
         */
        if (isAuthenticated === true && previousAuthenticatedState.current === false && featureFlags) {
            const token = LocalStorageService.getAccessToken()
            /* In case the user has two tabs open and they login on the first one, we should manually create a login action on the other */
            if (token && !isAccessTokenInRedux) {
                const loginAction = createLoginAction(token)
                dispatch(loginAction)
            }
            dispatch(fetchApiVersion())
            dispatch(setFeatureFlags(featureFlags.getFlagsObject()))
            dispatch(fetchAllCompanies())
            redirect()
        }

        /**
         * perform proper logout
         */
        if (isAuthenticated === false && previousAuthenticatedState.current === true) {
            LocalStorageService.unsetAccessToken()
        }

        // update ref
        previousAuthenticatedState.current = isAuthenticated
    }, [isAuthenticated])

    return {
        logout,
        isAuthenticated,
        rights: activeRights,
        isFinwayAdmin: userClass.email === FINWAY_ADMIN_EMAIL,
        userClass,
        isSsoLogin,
        featureFlags,
        companyId,
        userId: userClass?.id,
    }
}

const useAccessToken = () => {
    const [accessToken, setEncodedAccessToken] = useLocalStorage(ACCESS_TOKEN, "")

    return {
        encodedAccessToken: accessToken,
        decodedAccessToken: accessToken ? (jwt.decode(accessToken) as AccessTokenType) : undefined,
        setEncodedAccessToken,
    }
}

const useAccessTokenData = () => {
    const { decodedAccessToken } = useAccessToken()

    const userClass = new User(decodedAccessToken)
    const featureFlags = decodedAccessToken?.featureFlags ? new FeatureFlags(decodedAccessToken.featureFlags) : undefined
    const companyId = decodedAccessToken?.currentCompanyId

    return {
        userClass,
        companyId: String(companyId),
        featureFlags,
        isSsoLogin: Boolean(decodedAccessToken?.isSsoLogin) === true,
        activeRights: decodedAccessToken?.activeRights,
    }
}

export const useWeavrAuth = () => {
    const { isWeavrAuthenticated, shouldShowWeavrAuthModal, isWeavrUserOnBoard } = useSelector(({ auth }: RootState) => auth)

    return {
        isWeavrAuthenticated,
        shouldShowWeavrAuthModal,
        isWeavrUserOnBoard,
    }
}
