import { LoadingOutlined } from "@ant-design/icons"
import { COLOR } from "@finway-group/shared/lib/consts"
import { Expense, RightEnum } from "@finway-group/shared/lib/models"
import { Button, Empty, Table } from "antd"
import { ColumnsType } from "antd/lib/table"
import React from "react"
import { Check as CheckedIcon, Loader as LoaderIcon, X as XIcon } from "react-feather"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"

import NoSearchDataFound from "Components/NoSearchDataFound"
import AmountColumn from "Components/expenseAmountColumn"
import { ErrorTable } from "Components/layout/errorTable"
import ExpenseArchiveSearchModal from "Components/modals/expenseArchiveSearch.modal"
import RequestColumn from "Components/requestColumn"
import ExpandTripFolderTableIcon from "Components/tables/expandTripFolderTableIcon"
import { useModal } from "Shared/context/modal.context"
import { useFetchTable, useTable, useTableFilterObject, useTableSearchString } from "Shared/hooks/table.hooks"
import { useLoggedInEmployeeProfile } from "Shared/hooks/user.hooks"
import { AuthzService } from "Shared/services"
import { setExpense } from "Shared/store/actions/expense/expenseActions"
import { refetchTable, updateTableOnChange } from "Shared/store/actions/tables/tableActions"
import { TablesEnum } from "Shared/store/reducers/tableConfigReducer"
import { getStatusLabel, isFolderExpense } from "Shared/utils/expense.utils"
import { insertIf } from "Shared/utils/helper.utils"
import { getSortOrderForColumn } from "Shared/utils/table.utils"

interface ArchiveInterface {}

const ArchiveTab: React.FC<ArchiveInterface> = () => {
    const { t } = useTranslation()
    const history = useHistory()
    const dispatch = useDispatch()
    const loggedInUser = useLoggedInEmployeeProfile()
    const { showModal } = useModal()

    const searchString = useTableSearchString(TablesEnum.ARCHIVE)
    const filterObject = useTableFilterObject(TablesEnum.ARCHIVE)

    const archiveTable = useTable<Expense>(TablesEnum.ARCHIVE)

    useFetchTable(TablesEnum.ARCHIVE)

    const renderCheckedOrPlusIconColumn = (isChecked: boolean) => (
        <div className="ml-30">{isChecked ? <CheckedIcon color={COLOR.finway.green} /> : <XIcon color={COLOR.finway.red} />}</div>
    )

    //* If any of the table data have children adjust the table design accordingly otherwise remove extras spaces provided for the dropdown icon
    const expandableColumn = archiveTable.data.docs.some((expense) => (expense as any)?.children && (expense as any)?.children.length > 0)
        ? [
              {
                  dataIndex: "expandIcon",
                  width: 30,
                  align: "center",
              } as any,
          ]
        : []

    /*
     * The archive table is the same as in DONE now.
     * We may decide to include more "archive" related infos in the table.
     * Therefore the two table codes are separated to be able to easily customize them separately in the future.
     */
    const columns: ColumnsType<Expense> = [
        ...expandableColumn,
        {
            title: t("label:request"),
            key: "expenseNumber",
            align: "left",
            className: "pl-10",
            width: 300,
            ...(loggedInUser.settings.showExpenseNumbers && {
                sorter: true,
                sortOrder: getSortOrderForColumn(archiveTable, "expenseNumber"),
            }),
            render: (data: Expense) => <RequestColumn request={data} />,
        },
        ...insertIf(AuthzService.isRightGrantedForLoggedInUser(RightEnum.EXPENSE__ALL__REVIEW), {
            title: t("label:invoice"),
            key: "invoices",
            width: 100,
            align: "center",
            sorter: true,
            sortOrder: getSortOrderForColumn(archiveTable, "invoices"),
            render: (expense: Expense) => (!isFolderExpense(expense) ? renderCheckedOrPlusIconColumn(!!expense.invoices.length) : <></>),
        }),
        ...insertIf(AuthzService.isRightGrantedForLoggedInUser(RightEnum.TRANSACTION__ALL__READ), {
            title: t("label:transaction"),
            key: "matchedTransactions",
            width: 110,
            align: "center",
            sorter: true,
            sortOrder: getSortOrderForColumn(archiveTable, "matchedTransactions"),
            render: (data: Expense) => (!isFolderExpense(data) ? renderCheckedOrPlusIconColumn(data.transactionState !== null) : <></>),
        }),
        {
            title: t("label:status"),
            key: "status",
            width: 120,
            align: "left",
            sorter: true,
            sortOrder: getSortOrderForColumn(archiveTable, "status"),
            render: (data: Expense) => <div>{getStatusLabel(data.status)}</div>,
        },
        {
            title: loggedInUser.settings.showGrossAmount ? t("label:gross_amount") : t("label:net_amount"),
            key: loggedInUser.settings.showGrossAmount ? "totalGrossPrice" : "totalNetPrice",
            width: 180,
            align: "right",
            className: "pr-10",
            sorter: true,
            sortOrder: getSortOrderForColumn(archiveTable, loggedInUser.settings.showGrossAmount ? "totalGrossPrice" : "totalNetPrice"),
            render: (data: Expense) => <AmountColumn request={data} showGross={loggedInUser.settings.showGrossAmount} showDiscountWhenAvailable />,
        },
    ]

    if (!(filterObject.minDate && filterObject.maxDate)) {
        return (
            <div className="flex justify-center">
                <Empty
                    image={`./icons/no_data_found.svg`}
                    imageStyle={{
                        height: 150,
                        marginRight: "auto",
                        marginLeft: "auto",
                        marginTop: "15%",
                        marginBottom: "40px",
                        display: "inline-block",
                    }}
                    description={<span className="text-lg font-bold mt-2 mb-0">{t("info:archive.expense_initial_display.title")}</span>}
                >
                    <p className="mb-20 w-400">{t("info:archive.expense_initial_display.message")}</p>
                    <Button size="small" type="primary" onClick={() => showModal(ExpenseArchiveSearchModal, true, { isShowing: true, archiveTable: TablesEnum.ARCHIVE })}>
                        <LoaderIcon />
                        {t("action:archive.load")}
                    </Button>
                </Empty>
            </div>
        )
    }

    return (
        <div>
            {archiveTable.error ? (
                <ErrorTable onTableReload={() => dispatch(refetchTable(TablesEnum.ARCHIVE))} isLoading={archiveTable.isFetching} />
            ) : archiveTable.data.totalDocs === 0 && !archiveTable.isFetching ? (
                searchString ? (
                    <div className="animation-appear">
                        <NoSearchDataFound />
                    </div>
                ) : (
                    <Empty
                        className="animation-appear"
                        image={`./icons/empty_table.svg`}
                        imageStyle={{
                            height: 150,
                            marginRight: "auto",
                            marginLeft: "auto",
                            marginTop: "15%",
                            marginBottom: "40px",
                            display: "inline-block",
                        }}
                        description={<span className="text-lg font-bold mt-2 mb-0">{t("info:empty_state.archive.title")}</span>}
                    >
                        <p>{t("info:empty_state.archive.message")}</p>
                    </Empty>
                )
            ) : (
                <div className="overflow-auto mb-24 p-2 animation-appear">
                    <Table<Expense>
                        rowKey={(record) => record.id}
                        dataSource={archiveTable.data.docs}
                        columns={columns}
                        onChange={updateTableOnChange(TablesEnum.ARCHIVE, archiveTable.data)}
                        loading={{
                            spinning: archiveTable.isFetching,
                            indicator: <LoadingOutlined style={{ fontSize: 30, color: "black" }} spin />,
                        }}
                        pagination={{
                            position: ["bottomRight"],
                            showSizeChanger: true,
                            current: archiveTable.data.page,
                            pageSize: archiveTable.data.limit,
                            hideOnSinglePage: false,
                            total: archiveTable.data.totalDocs,
                            pageSizeOptions: ["5", "10", "20", "50", "100"],
                        }}
                        onRow={(record, _rowIndex) => ({
                            onClick: (_event) => {
                                dispatch(setExpense(record))
                                history.push(`/expenses/${record.id}`)
                            },
                        })}
                        expandIcon={(props) => <ExpandTripFolderTableIcon props={props} />}
                    />
                </div>
            )}
        </div>
    )
}

export default ArchiveTab
