import { ExclamationCircleOutlined } from "@ant-design/icons"
import { Employee, RightEnum, SystemRightEnum } from "@finway-group/shared/lib/models"
import { Button, Checkbox, Form, Modal, Tooltip } from "antd"
import { Store } from "antd/lib/form/interface"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useHistory, useParams } from "react-router-dom"

import EmployeeForm from "Components/forms/employeeForm/employee.form"
import { useCompany } from "Shared/hooks/company.hooks"
import { AuthzService, EmployeeService, NotificationService, UserService } from "Shared/services"
import DialogService from "Shared/services/dialog.service"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { ThunkDispatchResult } from "Shared/store"
import { fetchAllEmployees, updateEmployee } from "Shared/store/actions/employee/employeeActions"
import { getTooltipPopupContainer, isEmptyString, removeStringWhiteSpaces, roundNumber, validateCreditorNumberDATEVCompatibility } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

const { confirm } = Modal

interface EmployeeModalProps {
    employee?: Employee

    isShowing: boolean
    onCancel: () => void
    onSubmit: () => void
}

export const EmployeeModal = ({ isShowing, employee, onCancel, onSubmit }: EmployeeModalProps) => {
    const { t } = useTranslation()
    const { id } = useParams<{ id: string }>()

    const dispatch: ThunkDispatchResult = useDispatch()
    const history = useHistory()
    const loggedInUser = UserService.getLoggedInEmployeeProfile()

    const [isLoading, setIsLoading] = useStateIfMounted(false)
    const [isUploading, setIsUploading] = useStateIfMounted(false)
    const companyId = useCompany()?._id
    const [shouldSyncExpenses, setShouldSyncExpenses] = useStateIfMounted(false)

    const [formInstance] = Form.useForm()
    const canManageEmployees =
        AuthzService.isSystemRightGrantedForLoggedInUser(SystemRightEnum.SYS__USER__ALL__UPDATE) || AuthzService.isRightGrantedForLoggedInUser(RightEnum.EMPLOYEE__ALL__UPDATE)

    useEffect(() => {
        // To show mileage rate as cents
        const adjustedMileageRate = employee?.activeCompanyProfile?.mileageRate ? roundNumber(employee.activeCompanyProfile.mileageRate * 100) : undefined
        if (isShowing) formInstance.setFieldsValue({ ...employee, ...employee?.activeCompanyProfile, mileageRate: adjustedMileageRate })

        return () => {
            if (isShowing) formInstance.resetFields()
        }
    }, [isShowing])

    const handleSubmit = async (values: Store, forceSubmit?: boolean) => {
        // extra variable to change it after confirmation dialog
        let shouldSynchronizeExpenses = shouldSyncExpenses

        if (!forceSubmit) {
            try {
                validateCreditorNumberDATEVCompatibility(values.creditorNumber)
            } catch (err) {
                confirm({
                    title: t("confirm:vendor.incompatible_creditor_number.title"),
                    content: t(`confirm:vendor.incompatible_creditor_number.message`, { reason: err.message }),
                    cancelText: t("confirm:vendor.incompatible_creditor_number.cancel"),
                    type: "warning",
                    okText: t("confirm:vendor.incompatible_creditor_number.confirm"),
                    onOk() {
                        return handleSubmit(values, true)
                    },
                })
                return
            }
        }

        if (!shouldSynchronizeExpenses && (await DialogService.confirmSync())) {
            shouldSynchronizeExpenses = true
        }

        setIsLoading(true)

        const { superior, team, creditorNumber, mileageRate, ...restValues } = values
        const adjustedCreditorNumber = !isEmptyString(values.creditorNumber) ? values.creditorNumber : null
        const adjustedMileageRate = mileageRate ? Number(mileageRate) / 100 : null

        const companyProfiles = employee?.companyProfiles?.map((userCompanyProfile) => {
            if (userCompanyProfile.companyId === companyId) {
                return {
                    ...userCompanyProfile,
                    companyId,
                    superior,
                    team,
                    mileageRate: adjustedMileageRate,
                    creditorNumber: adjustedCreditorNumber,
                }
            }

            return userCompanyProfile
        })

        const employeeData = new Employee({
            ...restValues,

            positionSource: employee?.positionSource,
            img: employee?.img !== values.img ? (values.img.file ? values.img.file.xhr ?? null : values.img) : employee?.img,

            ...(canManageEmployees && {
                companyProfiles: companyProfiles ?? [],
            }),
        })

        if (employeeData.iban) employeeData.iban = removeStringWhiteSpaces(employeeData.iban)

        try {
            const employeeId = id ?? (employee && employee.id)
            if (employeeId) await dispatch(updateEmployee(employeeId, employeeData))
            if (shouldSynchronizeExpenses) await EmployeeService.syncExpenses(employeeId)

            handleHide(true)
            NotificationService.send(
                NotificationTypeEnum.SUCCESS,
                employeeId === loggedInUser.id ? t("notification:settings.user_profile.updated.title") : t("notification:employee.updated.title"),
                employeeId === loggedInUser.id ? t("notification:settings.user_profile.updated.message") : t("notification:employee.updated.message"),
            )
        } catch (err) {
            setIsLoading(false)
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:employee.edit.title"))
        }
    }

    const handleRestore = async (employeeToRestore: Employee) => {
        try {
            await dispatch(updateEmployee(employeeToRestore.id, employeeToRestore))
            NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:employee.restored.title"), t("notification:employee.restored.message"))
            handleHide()
            await dispatch(fetchAllEmployees())
            history.push(`/employees/${employeeToRestore.id}`)
        } catch (err) {
            setIsLoading(false)
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:employee.edit.title"))
        }
    }

    const restoreEmployee = (e: Employee) => {
        confirm({
            title: t("confirm:employee.restore.title"),
            content: t("confirm:employee.restore.message"),
            cancelText: t("confirm:employee.restore.cancel"),
            type: "warning",
            okText: t("confirm:employee.restore.confirm"),
            onOk: () => handleRestore(e),
        })
    }

    const handleHide = (isSubmit = false) => {
        setIsLoading(false)
        formInstance.resetFields()
        if (isSubmit && onSubmit) onSubmit()
        onCancel()
    }

    const titleText = () => {
        if (employee?.id === loggedInUser.id) return t("action:user_profile.edit")
        return `${t("action:employee.edit")}`
    }

    const buttonText = () => {
        if (employee?.id === loggedInUser.id) return t("action:user_profile.save")
        return `${t("action:save")} ${t("label:employee")}`
    }

    return (
        <Modal
            title={titleText()}
            visible={isShowing}
            maskClosable={false}
            onCancel={() => handleHide()}
            cancelText={t("action:cancel")}
            confirmLoading={isLoading}
            destroyOnClose
            footer={
                <div className="flex justify-between items-center">
                    <div className="flex items-center">
                        <Checkbox className="ml-8" onChange={(e) => setShouldSyncExpenses(e.target.checked)} checked={shouldSyncExpenses}>
                            {t("input:sync_updates")}
                        </Checkbox>
                        <Tooltip getPopupContainer={getTooltipPopupContainer} title={t("tooltips:synch_expenses:user")} placement="top" className="align-middle">
                            <ExclamationCircleOutlined />
                        </Tooltip>
                    </div>

                    <div className="ml-auto mr-0">
                        <Button
                            key="back"
                            onClick={() => {
                                formInstance.resetFields()
                                handleHide()
                            }}
                        >
                            {t("action:cancel")}
                        </Button>
                        <Button
                            key="submit"
                            type="primary"
                            loading={isLoading}
                            disabled={isUploading}
                            onClick={() => {
                                formInstance.submit()
                            }}
                        >
                            {buttonText()}
                        </Button>
                    </div>
                </div>
            }
        >
            <EmployeeForm form={formInstance} employee={employee} onSubmit={handleSubmit} onRestore={restoreEmployee} setIsUploading={setIsUploading} />
        </Modal>
    )
}

export default React.memo(EmployeeModal)
