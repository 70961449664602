import { ExclamationCircleOutlined } from "@ant-design/icons"
import { Expense, ExpenseKindEnum, PriceIntervalEnum } from "@finway-group/shared/lib/models"
import { ExpensePaymentOptionEnum } from "@finway-group/shared/lib/models/expense/expensePaymentOption.enum"
import { roundNumberTo2Decimals, toDinero } from "@finway-group/shared/lib/utils"
import { Alert, Button, Col, Divider, Row, Switch, Tag, Tooltip } from "antd"
import { SwitchChangeEventHandler } from "antd/lib/switch"
import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { Link, generatePath } from "react-router-dom"

import { ConditionalWrapper } from "Components/conditionalWrapper"
import PriceLabel from "Components/priceLabel"
import { NO_TAX_RATE } from "Shared/config/consts"
import { useArchiveInterval } from "Shared/hooks/company.hooks"
import { useTaxes } from "Shared/hooks/tax.hooks"
import { RoutePathEnum } from "Shared/router/RoutePath.enum"
import { NotificationService } from "Shared/services"
import { updateExpense } from "Shared/store/actions/expense/expenseActions"
import {
    calculateTotalTax,
    doTotalAndInlineAmountsMismatch,
    getReimbursementLabel,
    getSubscriptionPriceInterval,
    isExpenseArchived,
    isExpenseBillableAccessible,
    isFolderExpense,
    syncTotalExpenseAmountsToItems,
} from "Shared/utils/expense.utils"
import { formatCurrencyNumber, linkDetection } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"
import { parseHtml } from "Shared/utils/htmlParser.utils"

import { ExpenseEditButton } from "./auxiliary/expenseEditButton"
import ExpenseSplitsDetailTable from "./expenseSplitsDetailTable"
import { useMainDetailsSectionContext } from "./mainDetailsSection.context"

interface MainDetailsSectionInterface {}

const MainDetailsSectionComponent: React.FC<MainDetailsSectionInterface> = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const taxValues = useTaxes()

    const {
        problems,
        hasUnsavedChanges,
        splits,
        expenseTaxPrice,
        isSplitManuallyAdjusted,
        onSyncNetAndTax,
        expense,
        isMinimized,
        showLink,
        showProbability,
        roundingErrors,
        verticalErrors,
        horizontalErrors,
        isExpenseEditable,
    } = useMainDetailsSectionContext()

    const folder = isFolderExpense(expense) ? expense : undefined

    const isSubscription = expense.kind === "Subscription"
    const totalTaxPrice = calculateTotalTax(splits)
    const isTaxSynced = toDinero(expenseTaxPrice || 0).equalsTo(toDinero(totalTaxPrice || 0))
    const splitsHasFilledTaxInformation = splits.some((s: any) => !!s.taxRate)
    const [isSwitchLoading, setIsSwitchLoading] = useStateIfMounted<boolean>(false)
    const isCardExpense = expense.paymentOption === ExpensePaymentOptionEnum.SMART_CARD
    const expenseInterval = expense.kind !== ExpenseKindEnum.ONE_TIME_EXPENSE ? getSubscriptionPriceInterval(expense) : PriceIntervalEnum.ONE_TIME
    const archiveAfterXDays = useArchiveInterval()
    const archived = isExpenseArchived(expense, archiveAfterXDays)
    const amountsMismatch = doTotalAndInlineAmountsMismatch(expense, taxValues, false)

    const onUpdateShouldUpdateExpenseAmountOnCardTransaction = (checked: boolean) => {
        setIsSwitchLoading(true)
        // TODO: rename this prop: isOcrUpdate
        const updateData = new Expense({ ...expense, shouldUpdateExpenseAmountOnCardTransaction: checked, isOcrUpdate: true, skipEmail: true })
        updateExpense(
            expense.id,
            updateData,
        )(dispatch)
            .catch((err) => NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:request.edit.title")))
            .finally(() => setIsSwitchLoading(false))
    }

    const onBillableSwitch: SwitchChangeEventHandler = async (checked: boolean) => {
        setIsSwitchLoading(true)
        expense.billable = checked
        try {
            await updateExpense(expense.id, expense)(dispatch)
        } catch (err) {
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:request.edit.title"))
        } finally {
            setIsSwitchLoading(false)
        }
    }

    const onSyncTotalExpenseAmounts = async () => {
        try {
            const { totalNetPrice, totalTaxPrice, totalGrossPrice, taxRate, splits } = syncTotalExpenseAmountsToItems(expense.splits)
            expense.totalGrossPrice = totalGrossPrice
            expense.totalNetPrice = totalNetPrice
            // TODO vigan: Remove  exclamation mark once revision-control is merged, because expense.taxRate will be already optional.
            expense.taxRate = taxRate?._id !== NO_TAX_RATE ? taxRate : undefined
            expense.splits = splits
            expense.totalTaxPrice = totalTaxPrice
            await updateExpense(expense.id, expense)(dispatch)
        } catch (err) {
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:request.edit.title"))
        }
    }

    // TODO: Store error as a state so we can combine errors from antd form too
    const renderAlert = () => {
        if (problems.length > 0) return <Alert className="mb-14" message={problems[0]} showIcon type="error" />

        if (splits.length > 0 && !isSplitManuallyAdjusted) {
            const totalItemGrossAmount = splits.reduce((acc, curr) => acc + curr.grossPrice, 0)
            if (roundNumberTo2Decimals(totalItemGrossAmount) !== roundNumberTo2Decimals(expense.totalGrossPrice))
                return (
                    <Alert
                        className="mb-14"
                        message={
                            <div>
                                <span>
                                    {t("info:item_gross_total_mismatch.main", {
                                        items_gross: formatCurrencyNumber(totalItemGrossAmount, expense.currency),
                                        expense_gross: formatCurrencyNumber(expense.totalGrossPrice, expense.currency),
                                    })}
                                </span>
                                {!isMinimized && (
                                    <ExpenseEditButton
                                        expense={expense}
                                        button={(buttonProps) => (
                                            <Button className="button-warning-notification ml-4" type="text" {...buttonProps}>
                                                <span>{t("info:item_gross_total_mismatch.prompt")}</span>
                                            </Button>
                                        )}
                                    />
                                )}
                            </div>
                        }
                        showIcon
                        type="error"
                    />
                )
        }

        if (!isTaxSynced && splitsHasFilledTaxInformation)
            return (
                <Alert
                    className="mb-14"
                    message={
                        <div>
                            <span>{t("info:tax_mismatch")}</span>
                            {isExpenseEditable && (
                                <Button className="button-warning-notification ml-4" type="text" onClick={onSyncNetAndTax}>
                                    {t("action:request.sync_now")}
                                </Button>
                            )}
                        </div>
                    }
                    showIcon
                    type="warning"
                />
            )

        const hasRoundingError = roundingErrors.grossPrice !== 0 || roundingErrors.netPrice !== 0 || roundingErrors.taxPrice !== 0
        if (!hasUnsavedChanges && hasRoundingError && !isSplitManuallyAdjusted) {
            return (
                <Alert
                    className="mb-14"
                    message={
                        <div>
                            <span>{isExpenseEditable ? t("info:split.rounding_error_detected") : t("info:split.rounding_error_non_editable")}</span>
                        </div>
                    }
                    showIcon
                    type="warning"
                />
            )
        }

        // Checking for custom input, if there is any discrepancy in the vertical direction, or horizontal direction
        if (isSplitManuallyAdjusted) {
            const hasGrossDiscrepancy = verticalErrors.grossPrice !== 0

            if (hasGrossDiscrepancy && horizontalErrors.length > 0) {
                return <Alert className="mb-14" message={t("info:split.has_both_discrepancy")} showIcon type="warning" />
            }
            if (hasGrossDiscrepancy) {
                return <Alert className="mb-14" message={t("info:split.has_vertical_discrepancy_gross")} showIcon type="warning" />
            }
            if (horizontalErrors.length > 0) {
                return <Alert className="mb-14" message={t("info:split.has_horizontal_discrepancy")} showIcon type="warning" />
            }
        }

        if (amountsMismatch) {
            return (
                <Row gutter={[16, 16]} align="middle">
                    <Col span={24} sm="24" md="24">
                        <Alert
                            showIcon
                            type="warning"
                            message={
                                <div>
                                    <span>{t("info:request_net_amount_mismatch")}</span>
                                    <Button className="button-warning-notification ml-4" type="text" onClick={onSyncTotalExpenseAmounts}>
                                        {t("action:request.sync_now")}
                                    </Button>
                                </div>
                            }
                        />
                    </Col>
                </Row>
            )
        }

        return <></>
    }

    const ExpenseAmountOnCardTransactionUpdateSwitch = () => {
        if (!isCardExpense) {
            return <></>
        }

        return (
            <Col>
                <span className="title w-240">
                    <Tooltip title={t("info:request.update_expense_amount_on_card_transaction")} placement="right" className="align-middle">
                        <ExclamationCircleOutlined className="mr-6" />
                    </Tooltip>
                    {t("label:update_expense_amount_on_card_transaction")}
                </span>
                <Switch
                    checked={expense.shouldUpdateExpenseAmountOnCardTransaction}
                    size="small"
                    className="ml-6 mb-2"
                    onChange={onUpdateShouldUpdateExpenseAmountOnCardTransaction}
                    loading={isSwitchLoading}
                />
            </Col>
        )
    }

    const BillableSwitch = () => (
        <Col>
            <span className="title w-240">{t("label:billable")}</span>
            <Switch
                checked={expense.billable}
                size="small"
                className="ml-6 mb-2"
                onChange={onBillableSwitch}
                disabled={archived || expense.isGobdCompliantInvoice()}
                loading={isSwitchLoading}
            />
        </Col>
    )

    const minimizedExpenseDetailsSection = () => {
        const tax = expense.taxRate
        let taxPrice = 0
        if (tax) taxPrice = expense.totalNetPrice * (tax.taxRate! / 100)
        const width = showProbability ? 5 : 6
        return (
            <Row gutter={[16, 0]} data-testid="oneTimeExpenseDetailsRow">
                <Col span={6}>
                    <Row className="mb-12">
                        <p className="font-bold text-xs md:text-base">{t("label:name")}</p>
                    </Row>
                    <Row>
                        <ConditionalWrapper
                            condition={showLink}
                            wrapper={(children: any) => <Link to={{ pathname: generatePath(RoutePathEnum.EXPENSE_DETAIL_PAGE, { id: expense?.id }) }}>{children}</Link>}
                        >
                            <p data-testid="expenseNumberRow" className="text-xs md:text-base">
                                {t("label:request")} #{expense.expenseNumber}
                            </p>
                        </ConditionalWrapper>
                    </Row>
                </Col>

                <Col span={width}>
                    <Row className="mb-12">
                        <p className="font-bold text-xs md:text-base">{t("label:total_net_amount")}</p>
                    </Row>
                    <Row>
                        <p data-testid="expenseTotalNetRow" className="text-xs md:text-base">
                            <PriceLabel value={expense.totalNetPrice} currency={expense.currency} interval={expenseInterval} showFullNumber />
                        </p>
                    </Row>
                </Col>

                <Col span={width}>
                    <Row className="mb-12">
                        <p className="font-bold text-xs md:text-base">{t("label:vat")}</p>
                    </Row>
                    <Row>
                        <p data-testid="expenseVatRow" className="text-xs md:text-base">
                            <PriceLabel value={taxPrice} currency={expense.currency} interval={expenseInterval} showFullNumber />
                        </p>
                    </Row>
                </Col>

                <Col span={width}>
                    <Row justify={showProbability ? "start" : "end"} className="mb-12">
                        <p className="font-bold text-xs md:text-base">{t("label:total_gross_amount")}</p>
                    </Row>
                    <Row justify={showProbability ? "start" : "end"}>
                        <p data-testid="expenseTotalGrossRow" className="text-xs md:text-base">
                            <PriceLabel value={expense.totalGrossPrice} currency={expense.currency} interval={expenseInterval} showFullNumber />
                        </p>
                    </Row>
                </Col>

                {showProbability && (
                    <Col span={3}>
                        <Row justify="end" className="mb-12">
                            <p className="font-bold text-xs md:text-base">{t("label:probability_short")}</p>
                        </Row>
                        <Row justify="end">
                            <p className="text-xs md:text-base">{(expense as any).probability ?? 0} %</p>
                        </Row>
                    </Col>
                )}
            </Row>
        )
    }

    const renderOneTimeExpenseDetails = (
        <>
            <Row className="mb-12 px-12" align="middle">
                <Col span={14}>
                    <Row className="font-bold text-text-dark text-lg h-auto w-full break-word-wrap">
                        <span className="leading-tight h-auto w-full whitespace-pre-wrap break-word-wrap">
                            {t("label:order_description")}
                            {expense.isReimbursement && (
                                <Tooltip title={t(`tooltips:reimbursement_types.${getReimbursementLabel(expense.kind)}`)}>
                                    <Tag className="ml-10 ant-tag-blue">{t(`label:reimbursement_types.${getReimbursementLabel(expense.kind)}`)}</Tag>
                                </Tooltip>
                            )}
                            {isCardExpense && <Tag className="ml-10 ant-tag-blue">{t("label:cards.card")}</Tag>}
                            {(expense.totalNetPrice < 0 || expense.totalGrossPrice < 0) && <Tag className="ml-10 ant-tag-green">{t("label:refund")}</Tag>}
                        </span>
                    </Row>
                    <Row>
                        <p className="text-sm sm:text-base leading-tight max-h-100 overflow-y-scroll w-full whitespace-pre-wrap break-word-wrap mt-4">
                            {expense.description ? (
                                parseHtml(expense.description)
                            ) : expense.isAutoGenerated ? (
                                <span className="italic">{t("info:auto_generated_request")}</span>
                            ) : null}
                        </p>
                    </Row>
                </Col>
                <Col span={10}>
                    <Row className="flex justify-end">{renderAlert()}</Row>
                    {!isMinimized && (
                        <Row className="flex flex-row-reverse gap-12">
                            {/** Should only show the billable switch when its accessible and when it's not inside a folder, as folders has its own billable switch display */}
                            {isExpenseBillableAccessible(expense) && !folder && <BillableSwitch />}
                            <ExpenseAmountOnCardTransactionUpdateSwitch />
                        </Row>
                    )}
                </Col>
            </Row>

            {isMinimized ? (
                <>
                    <Divider />
                    {minimizedExpenseDetailsSection()}
                </>
            ) : (
                <div>
                    <ExpenseSplitsDetailTable />
                </div>
            )}
        </>
    )

    const renderSubscriptionDetails = (
        <div>
            <Row className="px-12 mb-12" align="middle">
                <Col span={14}>
                    <Row className="font-bold text-text-dark text-lg h-auto w-full break-word-wrap" align="middle">
                        <Col className="leading-tight h-auto w-full whitespace-pre-wrap break-word-wrap">
                            {expense.name}
                            <Tag className="ml-10 ant-tag-blue">{t("label:recurring")}</Tag>
                            {expense.paymentOption === ExpensePaymentOptionEnum.SMART_CARD && <Tag className="ml-10 ant-tag-blue">{t("label:cards.card")}</Tag>}
                            {(expense.totalNetPrice < 0 || expense.totalGrossPrice < 0) && <Tag className="ml-10 ant-tag-green">{t("label:refund")}</Tag>}
                        </Col>
                    </Row>
                    {expense.link && <Row className="text-primary">{parseHtml(linkDetection(expense.link))}</Row>}
                    <Row className="">
                        <p className="text-sm sm:text-base leading-tight h-auto w-full whitespace-pre-wrap break-word-wrap mt-4">
                            {expense.description ? (
                                parseHtml(expense.description)
                            ) : expense.isAutoGenerated ? (
                                <span className="italic">{t("info:auto_generated_request")}</span>
                            ) : null}
                        </p>
                    </Row>
                </Col>
                <Col span={10}>
                    <Row>{renderAlert()}</Row>
                    {!isMinimized && (
                        <Row className="flex flex-row-reverse gap-12">
                            <ExpenseAmountOnCardTransactionUpdateSwitch />
                        </Row>
                    )}
                </Col>
            </Row>

            {isMinimized ? (
                <>
                    <Divider />
                    {minimizedExpenseDetailsSection()}
                </>
            ) : (
                <ExpenseSplitsDetailTable />
            )}
        </div>
    )

    return <>{!isSubscription ? renderOneTimeExpenseDetails : renderSubscriptionDetails}</>
}

export default React.memo(MainDetailsSectionComponent)
