import { Collapse } from "antd"
import React from "react"

export const CollapseWrapper = ({
    keyCode,
    header,
    children,
    isCollapse,
    isActive,
    extra,
}: {
    keyCode: string
    header: string
    children: JSX.Element
    isCollapse: boolean
    isActive: boolean
    extra?: JSX.Element
}) => (
    <>
        {isCollapse ? (
            <Collapse ghost defaultActiveKey={keyCode} className={`px-0 pt-0 ${isActive ? "filter--active" : ""}`}>
                <Collapse.Panel header={header} key={keyCode} extra={extra} forceRender>
                    {children}
                </Collapse.Panel>
            </Collapse>
        ) : (
            <>
                {header ? <div className="pb-12">{header}</div> : null}
                {children}
                {extra}
            </>
        )}
    </>
)
