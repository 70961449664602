import { Employee, RightEnum, UserApprover, UserRule, UserSettingsInterface, Workflow } from "@finway-group/shared/lib/models"
import axios from "axios"

import { FINWAY_ADMIN_EMAIL } from "Shared/config/consts"
import { AuthnService, AuthzService, CostCenter2Service, CostCenterService, ExpenseAccountService, UserService } from "Shared/services"
import store from "Shared/store"
import { isEmptyObject } from "Shared/utils/helper.utils"

const MODEL = "employees"

const EmployeeService = {
    getEmployeesFromStore: () => {
        const employeesFromStore: Array<Employee> = store.getState().employees.items
        return employeesFromStore
    },

    getEmployees: (excludeDeleted = false, filtered = false): Array<Employee> => {
        const employeesFromStore = EmployeeService.getEmployeesFromStore()

        let employees = employeesFromStore.filter(({ email }) => email !== FINWAY_ADMIN_EMAIL).map((employee) => EmployeeService.mapEmployeeActiveCompany(new Employee(employee)))

        let loggedInProfile = UserService.getLoggedInEmployeeProfile()

        if (!isEmptyObject(loggedInProfile)) loggedInProfile = EmployeeService.mapEmployeeActiveCompany(loggedInProfile)

        if (excludeDeleted) {
            employees = employees?.filter((employee) => !employee.activeCompanyProfile?.deleted)
        }

        if (filtered) {
            employees = employees?.filter(
                (employee) =>
                    AuthzService.isRightGrantedForLoggedInUser(RightEnum.EXPENSE__ALL__READ) ||
                    (AuthzService.isRightGrantedForLoggedInUser(RightEnum.EXPENSE__TEAM__READ) &&
                        employee.activeCompanyProfile?.team === loggedInProfile.activeCompanyProfile?.team),
            )
        }

        return employees.map((employee) => new Employee(employee))
    },

    getEmployeeIds: (excludeDeleted = false, filtered = false) => EmployeeService.getEmployees(excludeDeleted, filtered).map(({ id }) => id),

    getEmployeeById: (employeeId: string) => {
        const employeeInStore = EmployeeService.getEmployeesFromStore().find(({ id }) => id === employeeId)
        return new Employee(employeeInStore)
    },

    getEmployeeByIdOptional: (id?: string): Employee | undefined => (id ? EmployeeService.getEmployeeById(id) : undefined),

    /**
     * type cast, because the auto approver is guaranteed
     * to be in the selection for every finway customer
     */
    getAutoApprover: () => EmployeeService.getEmployeesFromStore().find(({ email }) => email === FINWAY_ADMIN_EMAIL) as Employee,

    getLoggedInEmployeeProfile: () => {
        const { userId } = AuthnService.getDataFromAccessToken()
        return EmployeeService.getEmployeeById(userId)
    },

    //
    // API methods
    //

    fetchAllEmployees: async (limit: number = 2000) => {
        const {
            data: { docs },
        } = await axios.get<{ docs: Array<any> }>(`/${MODEL}?limit=${limit}`)
        return docs.map((item: any) => EmployeeService.mapEmployeeActiveCompany(new Employee(item)))
    },
    fetchEmployeeById: async (id: string) => {
        const { data } = await axios.get(`/${MODEL}/${id}`)
        return data ? EmployeeService.mapEmployeeActiveCompany(new Employee(data)) : null
    },
    fetchOneByEmail: async (email: string) => {
        const data = await UserService.fetchOneByEmail(email)
        return EmployeeService.mapEmployeeActiveCompany(new Employee(data))
    },
    createEmployee: async (createEmployee: Employee) => {
        const data = await UserService.create(createEmployee)
        return EmployeeService.mapEmployeeActiveCompany(new Employee(data))
    },
    updateEmployee: async (id: string, updateEmployee: Employee) => {
        const { data } = await axios.put(`/${MODEL}/${id}`, updateEmployee)
        return EmployeeService.mapEmployeeActiveCompany(new Employee(data))
    },
    deleteEmployee: async (id: string, replacementApproverId: string) => {
        const { data } = await axios.delete(`/${MODEL}/${id}`, { data: { replacementApproverId } })
        return EmployeeService.mapEmployeeActiveCompany(new Employee(data))
    },
    updateEmployeeSettings: async (id: string, settings: UserSettingsInterface) => {
        const { data } = await axios.put(`/${MODEL}/${id}/settings`, settings)
        return EmployeeService.mapEmployeeActiveCompany(new Employee(data))
    },
    updateEmployeeImg: async (id: string, img: string) => {
        const { data } = await axios.put(`/${MODEL}/${id}/img`, { img })
        return EmployeeService.mapEmployeeActiveCompany(new Employee(data))
    },
    setCompanyEmailAlias: async (id: string, companyId: string, emailAlias: string) => {
        const { data } = await axios.put(`/${MODEL}/${id}/email-alias`, { affectedCompanyId: companyId, emailAlias })
        return EmployeeService.mapEmployeeActiveCompany(new Employee(data))
    },
    updateGetMyInvoicesUserId: async (id: string, getMyInvoicesUserId: string) => {
        const { data } = await axios.put(`/${MODEL}/${id}/settings/get-my-invoices`, { getMyInvoicesUserId })
        return data
    },
    updateAbsence: async (id: string, absence: any) => {
        const { data } = await axios.put(`/${MODEL}/${id}/absence`, absence)
        return EmployeeService.mapEmployeeActiveCompany(new Employee(data))
    },
    updateEmployeeRule: async (id: string, rule: any) => {
        const { data } = await axios.put(`${MODEL}/${id}/rule`, rule)
        return EmployeeService.mapEmployeeActiveCompany(new Employee(data))
    },
    updateEmployeeApprovals: async (id: string, approvals: Array<UserApprover>, companyId: string) => {
        const { data } = await axios.put(`${MODEL}/${id}/approval`, { approvals, companyId })
        return EmployeeService.mapEmployeeActiveCompany(new Employee(data))
    },
    getLatestEmployeeCreditorNumber: async (): Promise<number> => {
        const {
            data: { employeeCreditorNumberSequence },
        } = await axios.get(`${MODEL}/sequence/latest-creditor-number`)
        return employeeCreditorNumberSequence
    },
    getWorkflows: async (userId: string): Promise<Array<Workflow>> => {
        const { data } = await axios.get(`${MODEL}/${userId}/workflows`)
        return data.map((workflowData: any) => new Workflow(workflowData))
    },
    resetPassword: async (userId: string) => axios.delete(`${MODEL}/${userId}/password`),

    unblockEmployee: async (userId: string) => axios.patch(`${MODEL}/${userId}/unblock`),

    syncExpenses: async (userId: string) => {
        const { data } = await axios.post(`${MODEL}/${userId}/sync-expenses`)
        return data
    },
    getInitialEmployeeFormRule: (employee: Employee | undefined, companyIdParam: string): Pick<UserRule, "costCenter" | "costCenter2" | "expenseAccount"> => {
        const rule = employee?.rule?.find(({ companyId }) => companyId === companyIdParam)

        return {
            costCenter: CostCenterService.getCostCenterById(rule?.costCenter)?._id ?? "",
            costCenter2: CostCenter2Service.getCostCenter2ById(rule?.costCenter2)?._id ?? "",
            expenseAccount: ExpenseAccountService.getExpenseAccountById(rule?.expenseAccount)?._id ?? "",
        }
    },
    mapEmployeeActiveCompany: (employeeObject: Employee) => {
        const { companyId } = AuthnService.getDataFromAccessToken()

        if (companyId) {
            employeeObject.activeCompanyProfile = employeeObject.getUserCompanyProfile(companyId)!
        }

        return employeeObject
    },
}

export default EmployeeService
