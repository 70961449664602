import { Form, Modal } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"

import { parseCurrencyInput } from "Components/currencyInput/config"
import CardLimitForm from "Components/forms/cardLimit.form"
import { NotificationService } from "Shared/services"
import CardService from "Shared/services/card.service"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

interface ChangeCardLimitModalProps {
    isShowing: boolean
    onCancel: () => void
    card: any
}

const ChangeCardLimitModal = ({ isShowing, onCancel, card }: ChangeCardLimitModalProps) => {
    const { t } = useTranslation()
    const [formInstance] = Form.useForm()
    const [isLoading, setIsLoading] = useStateIfMounted(false)

    const onSubmit = async (formData: any) => {
        try {
            const updateData = { limit: parseCurrencyInput(formData.limit), reason: formData.reason }
            await CardService.requestLimitChange(card.id, updateData)
            NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:cards.limit_change_request.title"), t("notification:cards.limit_change_request.message"))
        } catch (err) {
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:cards.limit_change_request"))
        } finally {
            handleHide()
        }
    }

    const handleHide = () => {
        setIsLoading(false)
        formInstance.resetFields()
        onCancel()
    }

    return (
        <Modal
            title={t("action:cards.request_limit_change")}
            visible={isShowing}
            maskClosable={false}
            onOk={formInstance.submit}
            onCancel={handleHide}
            okText={t("action:cards.limit_change_submit")}
            cancelText={t("action:cancel")}
            keyboard
            destroyOnClose
            confirmLoading={isLoading}
        >
            <CardLimitForm card={card} formInstance={formInstance} onSubmit={onSubmit} />
        </Modal>
    )
}

export default ChangeCardLimitModal
