export const mapBoxDrawConfig = {
    displayControlsDefault: false,
    controls: {
        line_string: true,
        trash: true,
    },
    defaultMode: "draw_line_string",
    styles: [
        // Set the line style for the user-input coordinates.
        {
            id: "gl-draw-line",
            type: "line",
            filter: ["all", ["==", "$type", "LineString"], ["!=", "mode", "static"]],
            layout: {
                "line-cap": "round",
                "line-join": "round",
            },
            paint: {
                "line-color": "#438EE4",
                "line-dasharray": [0.2, 2],
                "line-width": 4,
                "line-opacity": 0.7,
            },
        },
        // Style the vertex point halos.
        {
            id: "gl-draw-polygon-and-line-vertex-halo-active",
            type: "circle",
            filter: ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"], ["!=", "mode", "static"]],
            paint: {
                "circle-radius": 12,
                "circle-color": "#FFF",
            },
        },
        // Style the vertex points.
        {
            id: "gl-draw-polygon-and-line-vertex-active",
            type: "circle",
            filter: ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"], ["!=", "mode", "static"]],
            paint: {
                "circle-radius": 8,
                "circle-color": "#438EE4",
            },
        },
    ],
}
