import { Expense, Tax } from "@finway-group/shared/lib/models"
import { Col, Form, Select, Tooltip } from "antd"
import { NamePath } from "antd/lib/form/interface"
import { OptionProps, SelectValue } from "antd/lib/select"
import React from "react"

import DeletedTooltip from "Components/deletedTooltip"
import { NO_TAX_RATE } from "Shared/config/consts"
import { getTaxLabel } from "Shared/utils/helper.utils"

export interface FormTaxRateSelectorInterface {
    elem: {
        id?: string
        name: NamePath
        label: string
        key: string
        initialValue?: string
        isRequired: boolean
        dropdownStyle?: CSSStyleRule
        isMatchSelectWidth?: boolean
        isBordered?: boolean
        className?: string
        getPopupAnchor?: (trigger: HTMLElement) => HTMLElement
        onChange?: (value: SelectValue, option: OptionProps) => void
        isDisabled: boolean
        taxValues: Array<Tax>
        value?: string
    }
    span?: number
    md?: number | object
    sm?: number | object
}

function FormTaxRateSelector({ elem, ...columnProps }: FormTaxRateSelectorInterface) {
    const { name, label, key, initialValue, isRequired, isBordered = false, isMatchSelectWidth = false, className, getPopupAnchor, onChange, isDisabled, taxValues, value } = elem

    return (
        <Col {...columnProps}>
            <Form.Item name={name} label={label} key={key} initialValue={initialValue} required={isRequired}>
                <Select
                    defaultValue={initialValue}
                    className={className}
                    dropdownStyle={{ minWidth: "150px", overflowX: "hidden" }}
                    dropdownMatchSelectWidth={isMatchSelectWidth}
                    bordered={isBordered}
                    getPopupContainer={getPopupAnchor}
                    onChange={onChange}
                    disabled={isDisabled}
                    value={value}
                >
                    {taxValues
                        .filter((tax) => !tax.deleted || tax._id === value)
                        .map((entry, index) => (
                            <Select.Option disabled={entry.deleted} key={index} value={entry._id}>
                                {entry.deleted && <DeletedTooltip translationKey="tooltips:vat.was_deleted" deletionDate={entry.dateDeleted} className="mr-4" />}
                                <Tooltip placement="bottom" title={entry?.buCodeName}>
                                    <span data-testid="formRateSelectorTaxRate" className="truncate">
                                        {getTaxLabel(entry)}
                                    </span>
                                </Tooltip>
                            </Select.Option>
                        ))}
                    <Select.Option value={NO_TAX_RATE}>-</Select.Option>
                </Select>
            </Form.Item>
        </Col>
    )
}

export default React.memo(FormTaxRateSelector)
