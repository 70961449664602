import { KYBStatusEnum, OperationTypeEnum, RightEnum } from "@finway-group/shared/lib/models"
import * as Sentry from "@sentry/react"
import i18n from "i18next"

import { AuthzService } from "Shared/services"
import store from "Shared/store"
import { fetchCardOverviewData } from "Shared/store/actions/analytics/analyticsActions"
import { fetchWallet } from "Shared/store/actions/corporate/corporateActions"
import { setShouldRefetch, syncCreditCard } from "Shared/store/actions/creditCard/creditCardActions"
import { refetchCardRelatedTables } from "Shared/store/actions/tables/tableActions"
import { isClientErrorCode } from "Shared/utils/errorHandler.utils"

import NotificationService, { NotificationTypeEnum } from "../notification.service"

export const subscribeToCreditCards = async (dispatch: any, data: any, loggedInUserId: string) => {
    const {
        fullDocument: { operationType, documentId },
    } = data

    try {
        switch (operationType) {
            case OperationTypeEnum.INSERT:
                dispatch(setShouldRefetch(true))
                dispatch(refetchCardRelatedTables())
                break
            default:
                const cardsState = store.getState().creditCards
                // for updates, refetch if it's present in the store
                const card = cardsState.item?._id === documentId ? cardsState.item : cardsState.items.find(({ _id }: any) => _id === documentId)
                if (card) dispatch(syncCreditCard(documentId.toString()))
                else dispatch(refetchCardRelatedTables())
                break
        }

        const cardsActivated = store.getState().corporate.kybData.kybStatus === KYBStatusEnum.COMPLETED
        if (cardsActivated && ![OperationTypeEnum.CARD_TRANSITION, OperationTypeEnum.CARD_UPDATE_PIN].includes(operationType)) {
            if (AuthzService.isRightGrantedForLoggedInUser(RightEnum.CARD_WALLET__ALL__READ)) dispatch(fetchWallet())
            if (AuthzService.isRightGrantedForLoggedInUser(RightEnum.CARD__ALL__READ)) dispatch(fetchCardOverviewData())
        }
    } catch (err) {
        if (isClientErrorCode(err)) return
        Sentry.captureException(`[Credit Card Socket] Error: ${typeof err === "string" ? err : JSON.stringify(err)}`)
    }
}

export const subscribeToCreditCardCallbacks = (data: any) => {
    const {
        fullDocument: { operationType },
    } = data
    switch (operationType) {
        case OperationTypeEnum.TOP_UP_CARD_FAILED:
            NotificationService.send(NotificationTypeEnum.ERROR, i18n.t("error:error"), i18n.t("error:cards.topup.title"), 30)
            break
        default:
            break
    }
}
