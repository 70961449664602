import { MAX_INPUT_LENGTH } from "@finway-group/shared/lib/utils/validators"
import { Button, Col, Input, Row, Tooltip } from "antd"
import Form, { FormInstance } from "antd/lib/form"
import { Store } from "antd/lib/form/interface"
import React from "react"
import { Plus as PlusIcon, Trash2 as TrashIcon } from "react-feather"
import { useTranslation } from "react-i18next"

import PersonioTag from "Components/personioTag"
import { getTooltipPopupContainer } from "Shared/utils/helper.utils"
import { isTeamSourcePersonio } from "Shared/utils/team.utils"

import TeamRules from "./rules/team.rules"

interface TeamFormInterface {
    formInstance: FormInstance
    onSubmit: (values: Store) => void
}

const TeamForm: React.FC<TeamFormInterface> = ({ formInstance, onSubmit }) => {
    const { t } = useTranslation()
    const { teamName } = TeamRules(formInstance)

    const onEmptyTeamList = <div className="mb-20">{t("missing:no_team_yet")}</div>

    return (
        <Form form={formInstance} layout="vertical" onFinish={onSubmit} autoComplete="off">
            <Form.List name="teams">
                {(fields, { add, remove }) => (
                    <div>
                        {fields.length === 0 && onEmptyTeamList}
                        {fields.map(({ key, name, fieldKey }, index) => {
                            const teams = formInstance.getFieldsValue()?.teams ?? []
                            return (
                                <Row key={key} gutter={[8, 8]} align="bottom">
                                    <Col span={21} offset={0}>
                                        <Form.Item
                                            name={[name, "teamName"]}
                                            fieldKey={[fieldKey, "teamName"]}
                                            label={
                                                <>
                                                    {t("input:team.team_name")}
                                                    {isTeamSourcePersonio(teams[index]) && (
                                                        <Tooltip
                                                            getPopupContainer={getTooltipPopupContainer}
                                                            overlayStyle={{ maxWidth: "100%" }}
                                                            title={t("tooltips:personio.team_source")}
                                                        >
                                                            <PersonioTag />
                                                        </Tooltip>
                                                    )}
                                                </>
                                            }
                                            rules={teamName}
                                            validateTrigger={"onSubmit"}
                                            required
                                            shouldUpdate
                                        >
                                            <Input disabled={isTeamSourcePersonio(teams[index])} maxLength={MAX_INPUT_LENGTH} placeholder={t("placeholder:team.team_name")} />
                                        </Form.Item>
                                    </Col>
                                    {!isTeamSourcePersonio(teams[index]) && (
                                        <Col span={3} className="mb-24">
                                            <Button
                                                onClick={() => {
                                                    remove(name)
                                                    formInstance.validateFields()
                                                }}
                                            >
                                                <TrashIcon />
                                            </Button>
                                        </Col>
                                    )}
                                </Row>
                            )
                        })}
                        <Row>
                            <Button className="mb-20" type="dashed" onClick={() => add()} block>
                                <PlusIcon /> {t("action:team.add")}
                            </Button>
                        </Row>
                    </div>
                )}
            </Form.List>
        </Form>
    )
}

export default TeamForm
