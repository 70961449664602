import { Expense } from "@finway-group/shared/lib/models"
import { Tag } from "antd"
import React from "react"
import { Archive } from "react-feather"
import { useTranslation } from "react-i18next"

import { useArchiveInterval } from "Shared/hooks/company.hooks"
import { isExpenseArchived, isExpenseManuallyUnarchived } from "Shared/utils/expense.utils"

interface ExpenseArchiveTagsInterface {
    expense: Expense
}

export const ExpenseArchiveTags: React.FC<ExpenseArchiveTagsInterface> = ({ expense }) => {
    const archiveAfterXDays = useArchiveInterval()
    const { t } = useTranslation()

    return (
        <>
            {isExpenseArchived(expense, archiveAfterXDays) ? (
                <Tag className="ant-tag-blue flex flex-row">
                    <Archive className="self-center" size={12} />
                    <span className="ml-5"> {t("label:archived")}</span>
                </Tag>
            ) : (
                isExpenseManuallyUnarchived(expense, archiveAfterXDays) && (
                    <Tag className="ant-tag-blue flex flex-row">
                        <Archive className="self-center" size={12} />
                        <span className="ml-5"> {t("label:manually_unarchived")}</span>
                    </Tag>
                )
            )}
        </>
    )
}
