import { CardWindowEnum } from "@finway-group/shared/lib/models"
import { Button, Form, Modal } from "antd"
import { Store } from "antd/lib/form/interface"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import EditCardForm from "Components/forms/editCard.form"
import { useCard } from "Shared/hooks/creditCard.hooks"
import { NotificationService } from "Shared/services"
import CardService from "Shared/services/card.service"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { syncCreditCard } from "Shared/store/actions/creditCard/creditCardActions"
import { getCardLimitWithoutBuffer, marshalCardData } from "Shared/utils/creditCard.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

interface EditCardModalInterface {
    isVisible: boolean
    onCancel: (state: boolean) => void
}

const EditCardModal: React.FC<EditCardModalInterface> = ({ isVisible, onCancel }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [formInstance] = Form.useForm()
    const [isLoading, setIsLoading] = useStateIfMounted(false)
    const card = useCard()

    useEffect(() => {
        if (isVisible) {
            formInstance.setFieldsValue({
                limit: getCardLimitWithoutBuffer(card) || 0,
                window: card?.window || CardWindowEnum.MONTH,
                isAutoRechargeEnabled: card?.isAutoRechargeEnabled || false,
                cardName: card?.cardName || "",
            })
        }

        return () => {
            if (isVisible) formInstance.resetFields()
        }
    }, [isVisible])

    const onSubmit = (values: Store) => {
        setIsLoading(true)

        CardService.updateCard(card?._id, card?.user, { ...marshalCardData(card), ...values })
            .then((card) => {
                dispatch(syncCreditCard(card))
                handleHide()
                NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:cards.updated.title"), t("notification:cards.updated.message"))
            })
            .catch((err) => {
                setIsLoading(false)
                NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:cards.edit.title"))
            })
    }

    const handleHide = () => {
        setIsLoading(false)
        formInstance.resetFields()
        onCancel(false)
    }

    return (
        <Modal
            title={t("creditCard:edit.title")}
            visible={isVisible}
            maskClosable={false}
            onCancel={handleHide}
            destroyOnClose
            onOk={formInstance.submit}
            footer={[
                <Button key="back" onClick={handleHide}>
                    {t("action:cancel")}
                </Button>,
                <Button key="submit" type="primary" loading={isLoading} onClick={() => formInstance.submit()}>
                    {t("action:cards.edit.submit")}
                </Button>,
            ]}
        >
            <EditCardForm formInstance={formInstance} card={card} onSubmit={onSubmit} />
        </Modal>
    )
}

export default EditCardModal
