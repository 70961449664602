import { ColumnMappingEnum, Transaction } from "@finway-group/shared/lib/models"
import { CsvMatchingAmountOptionEnum } from "@finway-group/shared/lib/models/common/csvMatchingAmountOption.enum"
import axios from "axios"

import { CsvColumnMappingResponse } from "Shared/utils/matching.utils"

import { handleWebFormError } from "./bank.service"

const MODEL = "transactions"

const TransactionService = {
    fetchMatchingTransactions: async (page: number, limit: number, sortingCriteria = "", query = "") => {
        const {
            data: { docs, totalPages, totalDocs },
        } = await axios.get(`${MODEL}?page=${page}&limit=${limit}${query}${sortingCriteria}`)
        return {
            transactions: docs.map((transactionData: any) => new Transaction(transactionData)),
            totalPages,
            totalDocs,
        }
    },
    fetchAllTransactions: async (queryParams: any, source: any, timeout?: number) => {
        const { data } = await axios.get(
            `/${MODEL}?page=${queryParams.page}&limit=${queryParams.limit}${queryParams.filterQueryString}${queryParams.searchQueryString}${queryParams.sortQueryString}`,
            {
                cancelToken: source.token,
                timeout,
            },
        )
        return data
    },
    fetchCardTransactions: async (queryParams: any, source: any, cardId: string, timeout?: number) => {
        const { data } = await axios.get(
            `/cards/${cardId}/${MODEL}?page=${queryParams.page}&limit=${queryParams.limit}${queryParams.filterQueryString}${queryParams.searchQueryString}${queryParams.sortQueryString}`,
            {
                cancelToken: source.token,
                timeout,
            },
        )
        return data
    },
    fetchPossibleMatches: async (transactionId: string, page: number, limit: number, sortingCriteria = "", query = "") => {
        const { data } = await axios.get(`${MODEL}/${transactionId}/possible-matches?page=${page}&limit=${limit}${query}${sortingCriteria}`)
        return data
    },
    fetchMatchedExpenses: async (transactionId: string) => {
        const { data } = await axios.get(`${MODEL}/${transactionId}/matched-expenses`)
        return data
    },
    fetchOneMatchingTransaction: async (id: string) => {
        const { data } = await axios.get(`${MODEL}/${id}`)
        return new Transaction(data)
    },
    matchTransaction: async (transactionId: string, expenseId: string) => {
        const { data } = await axios.post(`${MODEL}/${transactionId}/match`, { expenseId })
        return new Transaction(data)
    },
    unmatchTransaction: async (transactionId: string, expenseId: string) => {
        const { data } = await axios.post(`${MODEL}/${transactionId}/unmatch`, { expenseId })
        return new Transaction(data)
    },
    updateTransactionCategory: async (transactionId: string, transactionCategoryId: string) => {
        const { data } = await axios.patch(`/${MODEL}/${transactionId}/transaction-category/${transactionCategoryId}`)
        return new Transaction(data)
    },
    determineCSVMapping: async (url: string) => {
        const { data } = await axios.post(`${MODEL}/determine/csv/mapping`, { csvFileUrl: url })
        return data as CsvColumnMappingResponse
    },
    determineCSVMatching: async (url: string, columnMapping: Array<ColumnMappingEnum>, amountOption: CsvMatchingAmountOptionEnum, csvName: string, isSignChanged: boolean) =>
        axios.post(`${MODEL}/determine/csv`, { csvFileUrl: url, columnMapping, amountOption, csvName, isSignChanged }),
    determineBankMatching: async (accountId: string, startDate: Date, endDate: Date) =>
        axios.post(`/${MODEL}/determine/bank`, { accountId, startDate, endDate }).catch((err) => handleWebFormError(err, true)),
    deleteTransaction: async (id: string) => axios.delete(`/${MODEL}/${id}`),
    deleteManyTransactions: async (ids: Array<string>) => axios.post(`/${MODEL}/delete-transactions`, { ids }),
    archiveTransactions: async (ids: Array<string>) => axios.patch(`/${MODEL}/archive`, { ids }),
    unarchiveTransactions: async (ids: Array<string>) => axios.patch(`/${MODEL}/unarchive`, { ids }),
}

export default TransactionService
