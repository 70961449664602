import { useEffect } from "react"

import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

export const useApiVersion = () => {
    const [checkedVersion, setCheckedVersion] = useStateIfMounted(false)
    // const apiVersion = useSelector((state: RootState) => state.fetcher.apiVersion)

    useEffect(() => {
        const checkApiVersion = async () => {
            // const remoteApiVersion = await UtilityService.getApiVersion()
            // if (apiVersion < remoteApiVersion) {
            //     notification.open({
            //         type: "warning",
            //         message: t("notification:new_api_version.title"),
            //         description: t("notification:new_api_version.message"),
            //         duration: 0,
            //     })

            //     await logout()
            // }
            setCheckedVersion(true)
        }

        if (!checkedVersion) checkApiVersion()
    }, [])

    return {
        checkedVersion,
    }
}
