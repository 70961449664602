import { FeatureFlagEnum, SystemRightEnum } from "@finway-group/shared/lib/models"
import React from "react"

import SettingsContainer from "Features/settings/settings.container"
import { RoutePathEnum } from "Shared/router/RoutePath.enum"
import { AuthzService } from "Shared/services"

const SystemIntegrationsContainer = React.lazy(() => import("./integrations/systemIntegrations.container"))
const SystemSecurityContainer = React.lazy(() => import("./integrations/systemSecurity.container"))
const BillingContainer = React.lazy(() => import("./billing/billing.container"))
const CompanyList = React.lazy(() => import("./company/companyList"))
const RoleSettings = React.lazy(() => import("./roles/roleSettingsContainer"))
const UsersContainer = React.lazy(() => import("./users/users.container"))

const SystemSettingsContainer = () => {
    const menuList = [
        {
            link: RoutePathEnum.SETTINGS_BILLING,
            titleKey: "label:payment_settings.title",
            requiredFeatureFlags: [FeatureFlagEnum.PAID_VERSION, FeatureFlagEnum.STRIPE_ENABLED],
            canSee: AuthzService.isSystemRightGrantedForLoggedInUser(SystemRightEnum.SYS__PAYMENT_SETTINGS__ALL__MANAGE),
            component: <BillingContainer />,
            isDefault: true,
        },
        {
            link: RoutePathEnum.SETTINGS_COMPANIES,
            titleKey: "label:companies",
            requiredFeatureFlags: [FeatureFlagEnum.PAID_VERSION, FeatureFlagEnum.SUB_COMPANIES_ENABLED],
            canSee: AuthzService.isSystemRightGrantedForLoggedInUser(SystemRightEnum.SYS__COMPANY__ALL__READ),
            component: <CompanyList />,
        },
        {
            link: RoutePathEnum.SETTINGS_ROLES,
            titleKey: "label:roles.title",
            requiredFeatureFlags: [FeatureFlagEnum.CUSTOM_ROLE_ENABLED],
            canSee: AuthzService.isSystemRightGrantedForLoggedInUser(SystemRightEnum.SYS__ROLE__ALL__READ),
            component: <RoleSettings />,
        },
        {
            link: RoutePathEnum.SETTINGS_USERS,
            titleKey: "label:users",
            requiredFeatureFlags: [],
            canSee: AuthzService.isSystemRightGrantedForLoggedInUser(SystemRightEnum.SYS__USER__ALL__READ),
            component: <UsersContainer />,
        },
        {
            link: RoutePathEnum.SETTINGS_SYSTEM_INTEGRATIONS,
            titleKey: "label:integrations",
            requiredFeatureFlags: [FeatureFlagEnum.PAID_VERSION],
            canSee: AuthzService.isSystemRightGrantedForLoggedInUser(SystemRightEnum.SYS__IDP__ALL__MANAGE),
            component: <SystemIntegrationsContainer />,
        },
        {
            link: RoutePathEnum.SETTINGS_SYSTEM_Security,
            titleKey: "label:security",
            requiredFeatureFlags: [FeatureFlagEnum.PAID_VERSION],
            canSee: AuthzService.isSystemRightGrantedForLoggedInUser(SystemRightEnum.SYS__IDP__ALL__MANAGE),
            component: <SystemSecurityContainer />,
        },
    ]

    return <SettingsContainer menuList={menuList} defaultPath={RoutePathEnum.SETTINGS_SYSTEM} />
}

export default React.memo(SystemSettingsContainer)
