import { Employee } from "@finway-group/shared/lib/models"
import { Button, Form, Modal } from "antd"
import { Store } from "antd/lib/form/interface"
import moment from "moment"
import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"

import EmployeeAbsenceForm from "Components/forms/employeeAbsence.from"
import { NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { updateEmployeeAbsence } from "Shared/store/actions/employee/employeeActions"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

interface EmployeeAbsenceModalInterface {
    isVisible: boolean
    onCancel: (state: false) => void
    employee: Employee
}

const EmployeeAbsenceModal: React.FC<EmployeeAbsenceModalInterface> = ({ isVisible, onCancel, employee }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { id } = useParams<{ id: string }>()

    const [isLoading, setIsLoading] = useStateIfMounted(false)

    const [formInstance] = Form.useForm()

    const handleSubmit = async (values: Store) => {
        const [startDate, endDate] = values.absenceDate
        try {
            await dispatch(updateEmployeeAbsence(String(id), { startDate, endDate }))
            NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:employee.absence_updated.title"), t("notification:employee.absence_updated.message"))
            handleCancel()
        } catch (err) {
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:employee_absence.update.title"))
        }
    }

    const handleRemoveAbsence = async () => {
        try {
            const yesterday = moment().subtract(1, "day")
            await dispatch(updateEmployeeAbsence(String(id), { startDate: yesterday, endDate: yesterday }))
            NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:employee.absence_removed.title"), t("notification:employee.absence_removed.message"))
            handleCancel()
        } catch (err) {
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:employee_absence.update.title"))
        }
    }

    const handleCancel = () => {
        setIsLoading(false)
        onCancel(false)
    }

    return (
        <Modal
            title={t("action:employee_absence.set_up")}
            visible={isVisible}
            maskClosable={false}
            onOk={formInstance.submit}
            onCancel={handleCancel}
            keyboard
            destroyOnClose
            footer={[
                <Button
                    key="back"
                    onClick={() => {
                        formInstance.resetFields()
                        onCancel(false)
                    }}
                >
                    {t("action:cancel")}
                </Button>,
                <Button
                    key="submit"
                    type="primary"
                    loading={isLoading}
                    onClick={() => {
                        formInstance.submit()
                    }}
                >
                    {t("action:save")}
                </Button>,
            ]}
        >
            <EmployeeAbsenceForm formInstance={formInstance} onSubmit={handleSubmit} handleRemoveAbsence={handleRemoveAbsence} employee={employee} />
            <span className="text-text">{t("info:employee_absence_role")}</span>
        </Modal>
    )
}

export default EmployeeAbsenceModal
