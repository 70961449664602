import { removeQueryString } from "@finway-group/shared/lib/utils"
import axios, { AxiosHeaders } from "axios"
import React, { useEffect, useState } from "react"

import Loading from "Components/loading"

import FileViewer, { FileViewerProps } from "./fileViewer"

const axiosInstance = axios.create({ headers: new AxiosHeaders(), baseURL: undefined, withCredentials: false })

const FileViewerCached = (props: FileViewerProps) => {
    const [{ url, path, isRenderedSuccessfully }, setFileData] = useState({ url: "", path: "", isRenderedSuccessfully: false })

    useEffect(() => {
        const { filePath } = props
        const isReRenderOfSameDocument = isRenderedSuccessfully && path && filePath && removeQueryString(path) === removeQueryString(filePath)
        if (isReRenderOfSameDocument) return

        const axiosRequestAbortController = new AbortController()
        axiosInstance
            .get(filePath, { responseType: "blob", signal: axiosRequestAbortController.signal })
            .then(({ data }) => {
                setFileData({ url: URL.createObjectURL(data), path: filePath, isRenderedSuccessfully: true })
            })
            .catch(() => {
                // Fallback: render file w/o caching
                setFileData({ url: filePath, path: props.filePath, isRenderedSuccessfully: false })
            })
        return () => {
            axiosRequestAbortController.abort()
            // Release memory if needed
            if (url) URL.revokeObjectURL(url)
        }
    }, [props.filePath])

    if (!url) return <Loading />

    const fileViewerProps = { ...props, filePath: url }
    return <FileViewer {...fileViewerProps} />
}

export default FileViewerCached
