import { ActionCreator, Dispatch } from "redux"

import { UtilityService } from "Shared/services"
import { ThunkResult } from "Shared/store"

import { CancelFetchAction, FetchApiVersionAction, FetcherActionTypes, LoadedAction, ResetFetchFlagAction, StartLoadingAction } from "./fetcherTypes"

export const setLoading = () => async (dispatch: Dispatch) => {
    const StartLoadingAction: StartLoadingAction = {
        type: FetcherActionTypes.START_LOADING,
        hasLoaded: false,
    }

    dispatch(StartLoadingAction)
}

export const setHasLoaded: ActionCreator<ThunkResult<void>> = () => async (dispatch: Dispatch) => {
    const hasLoadedAction: LoadedAction = {
        type: FetcherActionTypes.LOADED,
        hasLoaded: true,
    }

    dispatch(hasLoadedAction)
}

export const cancelFetch: ActionCreator<ThunkResult<void>> = () => async (dispatch: Dispatch) => {
    const cancelFetchAction: CancelFetchAction = {
        type: FetcherActionTypes.CANCEL_FETCH,
        isFetchCancelled: true,
    }

    dispatch(cancelFetchAction)
}

export const resetFetchFlag: ActionCreator<ThunkResult<void>> = () => async (dispatch: Dispatch) => {
    const resetFetchFlagAction: ResetFetchFlagAction = {
        type: FetcherActionTypes.RESET_FETCH_FLAG,
        isCancelled: false,
    }

    dispatch(resetFetchFlagAction)
}

export const fetchApiVersion: ActionCreator<ThunkResult<string>> = () => async (dispatch: Dispatch) => {
    const apiVersion = await UtilityService.getApiVersion()

    const FetchApiVersionAction: FetchApiVersionAction = {
        type: FetcherActionTypes.FETCH_API_VERSION,
        apiVersion,
    }

    dispatch(FetchApiVersionAction)

    return apiVersion
}
