import { FilterOutlined } from "@ant-design/icons"
import { RightEnum } from "@finway-group/shared/lib/models"
import { Button } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useDebouncedCallback } from "use-debounce/lib"

import SearchInput from "Components/searchInput"
import { useCostCenters } from "Shared/hooks/costCenter.hooks"
import { useEmployees } from "Shared/hooks/employee.hooks"
import { useExpenseTags } from "Shared/hooks/expenseTags.hooks"
import { useTableSearchString } from "Shared/hooks/table.hooks"
import { useCreditors } from "Shared/hooks/vendor.hooks"
import { AuthzService } from "Shared/services"
import { updateTableSearch } from "Shared/store/actions/tables/tableActions"
import { TablesEnum } from "Shared/store/reducers/tableConfigReducer"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

import FilterSection from "./filterSection"

interface TableButtonsInterface {
    table: TablesEnum
    onSearch?: (searchString: string) => void
    injectFilterModal: (isVisible: boolean, onCancel: (flag: boolean) => void) => any
    setIsShowingModal?: (isShowingModal: boolean) => void
    additionalButtons?: any
}

const TableButtons: React.FC<TableButtonsInterface> = ({ table, onSearch, injectFilterModal, additionalButtons = <></> }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [isFilterModalShowing, setIsFilterModalShowing] = useStateIfMounted<boolean>(false)
    const searchString = useTableSearchString(table)
    const employees = useEmployees({ excludeDeleted: true })
    const creditors = useCreditors(AuthzService.isRightGrantedForLoggedInUser(RightEnum.EMPLOYEE__ALL__READ), true, true)
    const costCenters = useCostCenters(true)
    const expenseTags = useExpenseTags()

    const searchCallback = useDebouncedCallback(async (searchString: string) => {
        if (onSearch) {
            onSearch(searchString)
        } else {
            dispatch(updateTableSearch(table, searchString))
        }
    }, 800)

    return (
        <>
            <div className="flex btn-wrapper mt-20 justify-end">
                {injectFilterModal(isFilterModalShowing, setIsFilterModalShowing)}
                <SearchInput onSearch={(value) => searchCallback.callback(value)} value={searchString} />
                <Button className="w-100" onClick={() => setIsFilterModalShowing(true)}>
                    <FilterOutlined />
                    {t("action:filter")}
                </Button>
                {additionalButtons}
            </div>

            <FilterSection table={table} options={{ employees, creditors, costCenters, expenseTags }} />
        </>
    )
}

export default TableButtons
