import { useEffect } from "react"
import { useSelector } from "react-redux"

import store from "Shared/store"
import { setCurrentPage } from "Shared/store/actions/tableConfig/tableConfigActions"
import { TablesEnum } from "Shared/store/reducers/tableConfigReducer"
import { RootState } from "Shared/store/rootState.interface"
import { getDashboardSearchTargets } from "Shared/utils/expense.utils"
import { convertSearchValueToQuery } from "Shared/utils/helper.utils"

export const useTableConfig = (table: TablesEnum) => useSelector(({ tableConfig }: RootState) => tableConfig[table]) || { currentPage: 1, itemsPerPage: 20, sorting: {} }

export const useTodoTablesConfig = () =>
    useSelector(({ tableConfig }: RootState) => ({
        todoPurchaseApprovalTable: tableConfig[TablesEnum.TODO_PURCHASE_APPROVAL],
        todoInvoiceApprovalTable: tableConfig[TablesEnum.TODO_INVOICE_APPROVAL],
        todoInvoiceAndPurchaseApprovalTable: tableConfig[TablesEnum.TODO_INVOICE_AND_PURCHASE],
        todoDocsNeededTable: tableConfig[TablesEnum.TODO_DOCS_NEEDED],
        todoApprovalPendingTable: tableConfig[TablesEnum.TODO_APPROVAL_PENDING],
    }))

export const usePagingGuard = (table: TablesEnum, resources: Array<any>, totalDocs: number, currentPage: number) => {
    // if a page became empty (e.g. through deletion of resources), fetch the previous one
    useEffect(() => {
        if (resources.length === 0 && totalDocs > 0 && currentPage > 1) {
            store.dispatch(setCurrentPage(table, currentPage - 1))
        }
    }, [resources.length])
}

export const useDashboardSearchValue = () => useSelector(({ tableConfig }: RootState) => tableConfig.dashboardSerachValue)

export const useDashboardSearchQueryString = () => {
    const searchValue = useSelector(({ tableConfig }: RootState) => tableConfig.dashboardSerachValue)
    const useGross = useSelector(({ auth: { user } }: RootState) => user?.settings.showGrossAmount || false)
    const searchTargets = getDashboardSearchTargets(useGross)
    return convertSearchValueToQuery(searchValue, searchTargets)
}

export const usePayAndExportTablesConfig = () =>
    useSelector(({ tableConfig }: RootState) => ({
        payAndExportToBePaidTable: tableConfig[TablesEnum.PAY_AND_EXPORT_TO_BE_PAID],
        payAndExportToBeExportedTable: tableConfig[TablesEnum.PAY_AND_EXPORT_TO_BE_EXPORTED],
    }))
