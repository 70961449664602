export interface GetPopupAnchor {
    (anchor?: HTMLElement | null, isForm?: boolean): (trigger: HTMLElement) => HTMLElement
}

export const getPopupAnchor: GetPopupAnchor =
    (anchor?: HTMLElement | null, isForm = false) =>
    (trigger: HTMLElement) => {
        const formFallback = document.querySelector(".ant-modal-content")

        if (!anchor && isForm && formFallback) return formFallback as HTMLElement
        if (!anchor) return trigger.parentElement ?? document.body
        return anchor
    }
