import { isEmptyStringOrNullish } from "@finway-group/shared/lib/utils/common"
import {
    MAX_DISCOUNT_DURATION_DAYS,
    MAX_DISCOUNT_PERCENTAGE,
    MIN_DISCOUNT_DURATION_DAYS,
    MIN_DISCOUNT_PERCENTAGE,
    isDiscountPercentageValid,
    isDiscountPeriodValid,
} from "@finway-group/shared/lib/utils/validators"
import { FormInstance, Rule } from "antd/lib/form"
import { InternalNamePath } from "antd/lib/form/interface"
import i18n from "i18next"

export const DiscountFormRules = (formInstance: FormInstance, namePath: InternalNamePath, allowBothEmpty = true): { [key: string]: Array<Rule> } => ({
    percentage: [
        {
            validator: (_rule: any, value: string) => {
                if (isEmptyStringOrNullish(value)) {
                    const period = formInstance.getFieldValue([...namePath, "period"])
                    if (isEmptyStringOrNullish(period) && allowBothEmpty) {
                        // Resolve if both percentage and period are empty
                        return Promise.resolve()
                    }

                    return Promise.reject(new Error(i18n.t("validation:required")))
                }

                if (!isDiscountPercentageValid(value)) {
                    return Promise.reject(new Error(i18n.t("validation:numeric_min_max", { min: MIN_DISCOUNT_PERCENTAGE, max: MAX_DISCOUNT_PERCENTAGE })))
                }

                return Promise.resolve()
            },
        },
    ],
    period: [
        {
            validator: (_rule: any, value: string) => {
                if (isEmptyStringOrNullish(value)) {
                    const percentage = formInstance.getFieldValue([...namePath, "percentage"])
                    if (isEmptyStringOrNullish(percentage) && allowBothEmpty) {
                        // Resolve if both percentage and period are empty
                        return Promise.resolve()
                    }

                    return Promise.reject(new Error(i18n.t("validation:required")))
                }

                if (!Number.isInteger(Number(value))) {
                    return Promise.reject(new Error(i18n.t("validation:integer")))
                }

                if (!isDiscountPeriodValid(value)) {
                    return Promise.reject(new Error(i18n.t("validation:numeric_min_max", { min: MIN_DISCOUNT_DURATION_DAYS, max: MAX_DISCOUNT_DURATION_DAYS })))
                }

                return Promise.resolve()
            },
        },
    ],
})

export const DiscountFormRulesStrict = (formInstance: FormInstance, namePath: InternalNamePath) => DiscountFormRules(formInstance, namePath, false)
