import Icon from "@ant-design/icons"
import React from "react"

const newTripFolder = () => (
    <svg data-testid="sideBarTripFolderButton" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="32" height="32" rx="16" fill="#F0FEFA" />
        <path d="M11.5 20C12.3284 20 13 19.3284 13 18.5C13 17.6716 12.3284 17 11.5 17C10.6716 17 10 17.6716 10 18.5C10 19.3284 10.6716 20 11.5 20Z" fill="#60CDB2" />
        <path
            d="M20.25 20C21.0784 20 21.75 19.3284 21.75 18.5C21.75 17.6716 21.0784 17 20.25 17C19.4216 17 18.75 17.6716 18.75 18.5C18.75 19.3284 19.4216 20 20.25 20Z"
            fill="#60CDB2"
        />
        <path
            d="M18.45 14L17.625 12.5C17.5509 12.3561 17.4403 12.2343 17.3043 12.1465C17.1683 12.0588 17.0116 12.0083 16.85 12H10.575C10.325 12 10.075 12.25 9.925 12.5C9.775 12.75 9.45 13.325 9.1 14H18.45ZM23 15H8.575L8.45 15.25C8.32373 15.3862 8.25247 15.5643 8.25 15.75V18.5H9C9 17.837 9.26339 17.2011 9.73223 16.7322C10.2011 16.2634 10.837 16 11.5 16C12.163 16 12.7989 16.2634 13.2678 16.7322C13.7366 17.2011 14 17.837 14 18.5H17.75C17.75 17.837 18.0134 17.2011 18.4822 16.7322C18.9511 16.2634 19.587 16 20.25 16C20.913 16 21.5489 16.2634 22.0178 16.7322C22.4866 17.2011 22.75 17.837 22.75 18.5H23.75V15.75C23.75 15.5511 23.671 15.3603 23.5303 15.2197C23.3897 15.079 23.1989 15 23 15Z"
            fill="#60CDB2"
        />
    </svg>
)

const NewTripFolderIcon = (props: any) => <Icon component={newTripFolder} {...props} />

export default NewTripFolderIcon
