import { CalculationIntervals } from "@finway-group/shared/lib/consts"
import { CalculationIntervalEnum } from "@finway-group/shared/lib/models"
import { Card, Col, Row, Select } from "antd"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import { useExpensesPerDepartmentDataPie, useExpensesPerDepartmentIntervalPie } from "Shared/hooks/analytics.hooks"
import { useTeams } from "Shared/hooks/team.hooks"
import { fetchExpensesPerDepartmentDataPie, setExpensesPerDepartmentIntervalPie } from "Shared/store/actions/analytics/analyticsActions"
import { getItemById } from "Shared/utils/helper.utils"
import { getSelectLabel } from "Shared/utils/report.utils"

import PieDiagram from "../pieDiagram"

const { Option } = Select

const ExpenseByDepartmentPieChart = () => {
    const { t } = useTranslation()
    const departmentExpenseDataPie = useExpensesPerDepartmentDataPie()
    const departmentExpenseIntervalPie = useExpensesPerDepartmentIntervalPie()
    const dispatch = useDispatch()
    const teams = useTeams()

    const expenseValueTotal = departmentExpenseDataPie.reduce((acc, curr) => acc + curr.value, 0)

    const handlePieDepartmentExpenseFilterChange = async (value: string) => {
        dispatch(setExpensesPerDepartmentIntervalPie(Number(value)))
    }

    useEffect(() => {
        dispatch(fetchExpensesPerDepartmentDataPie())
    }, [departmentExpenseIntervalPie])

    return (
        <>
            {/* Dropdown for period filter of Department Expense Pie Chart */}
            <Row>
                <Col xs={24} lg={24}>
                    <div className="flex justify-between items-center pb-0">
                        <h2 className="pt-8">{t("label:team_expenses")}</h2>
                        <Select
                            className="float-right"
                            defaultValue={getSelectLabel(CalculationIntervals[departmentExpenseIntervalPie])}
                            onChange={handlePieDepartmentExpenseFilterChange}
                            dropdownMatchSelectWidth={false}
                        >
                            <Option value={CalculationIntervalEnum.ALL_TIME} label="All Times">
                                <span>{getSelectLabel(CalculationIntervals[CalculationIntervalEnum.ALL_TIME])}</span>
                            </Option>
                            <Option value={CalculationIntervalEnum.CURRENT_YEAR} label="Current Year">
                                <span>{getSelectLabel(CalculationIntervals[CalculationIntervalEnum.CURRENT_YEAR])}</span>
                            </Option>
                            <Option value={CalculationIntervalEnum.CURRENT_MONTH} label="Current Month">
                                <span>{getSelectLabel(CalculationIntervals[CalculationIntervalEnum.CURRENT_MONTH])}</span>
                            </Option>
                        </Select>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col xs={24} lg={24} className="h-full">
                    <Card id="cost-center-section-pie">
                        {departmentExpenseDataPie?.length > 0 && expenseValueTotal > 0 ? (
                            <React.Fragment>
                                <PieDiagram dataForPieDiagram={departmentExpenseDataPie} getLabelName={(id) => getItemById(teams, id)?.teamName} />
                            </React.Fragment>
                        ) : (
                            <div className="flex justify-center items-center h-300">
                                <p>{t("missing:expense_data")}</p>
                            </div>
                        )}
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default ExpenseByDepartmentPieChart
