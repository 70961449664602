import { Role, StandardRoleEnum } from "@finway-group/shared/lib/models"
import { Radio } from "antd"
import Form, { FormInstance } from "antd/lib/form"
import { RadioChangeEvent } from "antd/lib/radio"
import React from "react"
import { useTranslation } from "react-i18next"

import { useRoles } from "Shared/hooks/role.hooks"
import { getUserRoleName } from "Shared/utils/getter.utils"

import RoleSettingsRules from "../rules/role.rules"
import { applyTemplateToFormModel } from "./role.form.mapper"

interface RoleFormInterface {
    formInstance: FormInstance
    role?: Role
    readOnly?: boolean
}

const RoleTemplateForm = ({ formInstance, role, readOnly }: RoleFormInterface) => {
    const { t } = useTranslation()
    const roles = useRoles()
    const rules = RoleSettingsRules()

    const onDefaultProfileChange = ({ target: { value } }: RadioChangeEvent) => {
        const roleTemplate = roles.find((r) => r._id === value)
        if (roleTemplate) applyTemplateToFormModel(roleTemplate, formInstance)
    }

    return (
        <>
            {!(readOnly && role && role.isReadOnly) && (
                <Form.Item name="template" fieldKey="template" label={t("input:settings.default_role_profile")} rules={rules.template}>
                    <Radio.Group disabled={readOnly} onChange={onDefaultProfileChange} className="w-full">
                        <div className="flex justify-items-stretch">
                            {roles
                                .filter((r) => r.isTemplate && [StandardRoleEnum.EMPLOYEE, StandardRoleEnum.APPROVER, StandardRoleEnum.ADMIN].includes(r.name as StandardRoleEnum))
                                .map((r) => (
                                    <Radio.Button key={r._id} value={r._id} className="w-full text-center">
                                        {getUserRoleName(r)}
                                    </Radio.Button>
                                ))}
                        </div>
                    </Radio.Group>
                </Form.Item>
            )}
        </>
    )
}

export default React.memo(RoleTemplateForm)
