export enum ImportResultOperationEnum {
    ERROR = "ERROR",
    CREATE = "CREATE",
    RESTORE = "RESTORE",
    IGNORE = "IGNORE",
}

export enum ImportModalTab {
    DATA = "DATA",
    HISTORY = "HISTORY",
}
