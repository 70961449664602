import {
    ApprovalScopeEnum,
    DocumentTypeEnum,
    Expense,
    ExpenseKindEnum,
    ExpenseRequestTypeEnum,
    ExpenseStatusEnum,
    Hospitality,
    InboxInvoice,
    Mileage,
    PerDiem,
} from "@finway-group/shared/lib/models"
import * as Sentry from "@sentry/react"
import { Form } from "antd"
import { Store } from "antd/lib/form/interface"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { parseCurrencyInput } from "Components/currencyInput/config"
import { useExpenseFormContext } from "Components/modals/expenseCreateForm.context"
import { useCompany } from "Shared/hooks/company.hooks"
import { useSubCostCenters } from "Shared/hooks/costCenter.hooks"
import { useEmployees } from "Shared/hooks/employee.hooks"
import { fillFormWithOcrData } from "Shared/hooks/ocr.hooks"
import { useTaxes } from "Shared/hooks/tax.hooks"
import { useLoggedInEmployeeProfile } from "Shared/hooks/user.hooks"
import { useVendorById, useVendors } from "Shared/hooks/vendor.hooks"
import { ApprovalProcessService, CostCenterService, ExpenseHttpService, VendorService } from "Shared/services"
import { getFirstNotSkippedApprover, getNextApproverId, getResetApprovalProcessesCopy } from "Shared/utils/approvalProcess.utils"
import { buildUpdateDataAccordingToSetting, initializeExpenseItemSplitsForForm, syncExpenseSplits } from "Shared/utils/expense.utils"
import { insertIf, isEmptyObject, isNotSet } from "Shared/utils/helper.utils"
import { useChangeEffect } from "Shared/utils/hooks/useChangeEffect"
import useUpdateEffect from "Shared/utils/hooks/useUpdateEffect"

import TripFolderRequestForm from "./TripFolderRequestForm"
import { InvoiceRequestForm } from "./invoiceRequest.form"
import PurchaseRequestForm from "./purchaseRequest.form"
import ReimbursementRequestForm from "./reimbursement.form"

interface ExpenseFormPropsInterface {
    onSubmit: (expense: Expense, extraData: { originalApproverId: string }) => void
    onFormValuesChange: () => void
    inboxOcrSource?: InboxInvoice
}

const ExpenseForm: React.FC<ExpenseFormPropsInterface> = ({ onSubmit, inboxOcrSource, onFormValuesChange: onFormValuesChangeProp }) => {
    const { t } = useTranslation()
    const loggedInProfile = useLoggedInEmployeeProfile()
    const employees = useEmployees({ excludeDeleted: true, includeAutoApprover: true })
    const costCenters = useSubCostCenters(true)
    const vendors = useVendors(true)
    const taxes = useTaxes()
    const company = useCompany()

    // prettier-ignore
    const [
        {
            expense,
            isNew,
            expenseForm,
            type,
            inboxInvoices,
            updateExpense,
            ocrSettings,
            isInvoiceRequest,
            isPreApprovedSubscription,
            setPrefilledVendorData,
            setIsOcrProcessed,
            setActiveDocumentsTab,
            folder,
            setIsBuildingApprovalProcesses,
            setIsFormTouched
        },
    ] = useExpenseFormContext()
    // add preset values, even if they were deleted. Otherwise it'll show the ID only.

    // approver at the moment of modal opening, to check if it has changed or not
    const [originalApproverId] = useState(expense.approvalNeededBy)

    if (expense.costCenter && !costCenters.find((cc) => cc._id === expense.costCenter)) costCenters.push(CostCenterService.getCostCenterById(expense.costCenter))
    if (expense.vendor && !vendors.find((vendor) => vendor.id === expense.vendor._id)) {
        const vendor = VendorService.getVendorById(expense.vendor._id)
        if (vendor) vendors.push(vendor)
    }
    const vendor = useVendorById(expense.vendor?._id)
    const paymentTerm = vendor?.rule?.paymentTerm

    const updateDueDateBasedOnRule = (invoiceDate: any) => {
        if (paymentTerm && invoiceDate) return { invoiceDueDate: moment(invoiceDate).add(paymentTerm, "days") }
        return {}
    }

    // New expenses that belong to a folder will follow its folder approval process.
    useEffect(() => {
        if (folder && isNew) expense.approvalProcesses = getResetApprovalProcessesCopy(folder.approvalProcesses)
    }, [])

    useEffect(() => {
        const initExpense = { ...expense }
        const initSplits = initializeExpenseItemSplitsForForm(initExpense)
        // Note: Antd has a bug where controlled fields (value prop) of Select does not work, so we need to update the form instance.
        const nextApproverId =
            getNextApproverId(expense.approvalProcesses) ?? (expense.approvalProcesses[0] ? getFirstNotSkippedApprover(expense.approvalProcesses[0])?._id : undefined)
        expenseForm.setFieldsValue({ ...expenseForm.getFieldsValue(), ...initExpense, splits: initSplits, nextApprover: nextApproverId })
    }, [expense])

    useEffect(() => {
        const fillFormOcrData = async () => {
            if (inboxInvoices?.length && loggedInProfile.settings.enableOcr && inboxOcrSource) {
                const { ocrResults } = inboxOcrSource
                if (!isNotSet(ocrResults) && !isEmptyObject(ocrResults)) {
                    try {
                        await fillFormWithOcrData({
                            formInstance: expenseForm,
                            expense,
                            ocrObject: ocrResults,
                            vendors,
                            taxes,
                            updateExpense,
                            setPrefilledVendorData,
                            t,
                            ocrItemizationEnabled: ocrSettings.isOcrItemizationEnabled,
                        })
                    } finally {
                        setIsOcrProcessed(true)
                    }
                }
            }
        }
        fillFormOcrData()
    }, [inboxInvoices?.length])

    // Initialize approval processes on new forms.
    useEffect(() => {
        if (!isNew || expense.folderId || folder) return
        const buildApprovalProcesses = async () => {
            try {
                setIsBuildingApprovalProcesses(true)
                const scopes = [...insertIf(expense.status === ExpenseStatusEnum.PURCHASE_PENDING, ApprovalScopeEnum.EXPENSE_PURCHASE), ApprovalScopeEnum.EXPENSE_INVOICE]
                const approvalProcesses = await ExpenseHttpService.buildApprovalProcesses(scopes, expense)
                updateExpense({ approvalProcesses })
            } catch (buildApprovalProcessesError: unknown) {
                Sentry.captureException(buildApprovalProcessesError, { extra: { fun: "buildApprovalProcesses" } })
            } finally {
                setIsBuildingApprovalProcesses(false)
            }
        }
        buildApprovalProcesses()
    }, [])

    // Update expense approval processes on change
    // prettier-ignore
    useChangeEffect(
        (changedDeps: any) => {
            let updateData: any
            if (changedDeps.vendor || changedDeps.requestedBy) {
                updateData = { ...buildUpdateDataAccordingToSetting(expense, vendors, employees, company), ...(updateDueDateBasedOnRule(expense.invoiceDate) as any) }
            }
            
            if (changedDeps.requestedBy) {
                updateData = buildUpdateDataAccordingToSetting(expense, vendors, employees, company)
            }

            // Expenses inside a folder do not change the approval process. They follow the folder approval process.
            // The expense's approval process has been determined in the useEffect with [] dependency
            if (expense.folderId || folder) {
                if (updateData) updateExpense(updateData)
                return
            }

            const updateApprovalProcesses = async () => {
                try {
                    setIsBuildingApprovalProcesses(true)
                    const updatedExpense = new Expense({ ...expense, ...updateData })
                    const approvalProcesses = await ApprovalProcessService.getApprovalProcessesAfterExpenseUpdate(updatedExpense, company, { valuesChange: changedDeps, isPreApprovedSubscription })
                    if (!approvalProcesses) return
                    updateExpense({ ...updateData, approvalProcesses })
                } catch (updateApprovalProcessError: unknown) {
                    Sentry.captureException(updateApprovalProcessError, { extra: { fun: "updateApprovalProcess" } })
                } finally {
                    setIsBuildingApprovalProcesses(false)
                }
            }
            updateApprovalProcesses()
        },
        [expense.requestedBy, expense.costCenter, expense.costCenter2, expense.vendor, expense.totalNetPrice, expense.totalGrossPrice, expense.totalTaxPrice, expense.taxRate, expense.paymentFlowInformation, expense.paymentOption, expense.kind],
        ["requestedBy", "costCenter", "costCenter2", "vendor", "totalNetPrice", "totalGrossPrice", "totalTaxPrice", "taxRate", "paymentFlowInformation", "paymentOption", "kind"],
        useUpdateEffect
    )

    useUpdateEffect(() => {
        updateExpense(updateDueDateBasedOnRule(expense.invoiceDate) as any)
    }, [expense.invoiceDate])

    const onFinish = (form: Store) => {
        form.totalNetPrice = parseCurrencyInput(form.totalNetPrice)
        form.totalTaxPrice = parseCurrencyInput(form.totalTaxPrice)

        form = syncExpenseSplits(form, expense.splitType)

        form.performancePeriodStartDate = form.performancePeriod?.[0]
        form.performancePeriodEndDate = form.performancePeriod?.[1]
        delete form.performancePeriod

        const offers = expenseForm.getFieldValue("offers")
        if (offers) form.offers = !isInvoiceRequest ? offers.map((offer: any) => offer.xhr || offer) : [...offers]

        // Transforming form data to be compatible with expense
        if (expense.kind === ExpenseKindEnum.HOSPITALITY) {
            form.attendees = form.attendees?.map(({ value, label }: any) => ({ internal: value !== label, user: value }))
        }

        const formData = {
            ...expense,
            ...form,
        }

        switch (form.kind) {
            case ExpenseKindEnum.HOSPITALITY:
                onSubmit(new Hospitality(formData), { originalApproverId })
                break
            case ExpenseKindEnum.MILEAGE:
                onSubmit(new Mileage(formData), { originalApproverId })
                break
            case ExpenseKindEnum.PER_DIEM:
                onSubmit(new PerDiem(formData), { originalApproverId })
                break
            default:
                onSubmit(new Expense(formData), { originalApproverId })
        }
    }

    const onFormFinishFailed = ({ errorFields }: any) => {
        if (isInvoiceRequest) {
            expenseForm.scrollToField(["invoiceNumber"])
            if (errorFields.some((errorField: any) => errorField.name.includes("invoices"))) {
                setActiveDocumentsTab(DocumentTypeEnum.INVOICE)
            }
        } else expenseForm.scrollToField(errorFields[0].name)
    }

    const onFormValuesChange = () => {
        setIsFormTouched(true)
        onFormValuesChangeProp()
    }

    return (
        <Form
            form={expenseForm}
            layout="vertical"
            name="CreateExpenseForm"
            onFinish={onFinish}
            onFinishFailed={onFormFinishFailed}
            autoComplete="off"
            // Use onValuesChange prop from antd Form component
            // setFieldsValue do not trigger onValuesChange
            // only user interactive can trigger the change event Reference
            // See: https://4x.ant.design/components/form/#setFieldsValue-do-not-trigger-onFieldsChange-or-onValuesChange
            onValuesChange={onFormValuesChange}
        >
            {type === ExpenseRequestTypeEnum.PURCHASE && <PurchaseRequestForm />}

            {type === ExpenseRequestTypeEnum.INVOICE && <InvoiceRequestForm />}

            {type === ExpenseRequestTypeEnum.REIMBURSEMENT && <ReimbursementRequestForm />}

            {type === ExpenseRequestTypeEnum.TRIP_FOLDER && <TripFolderRequestForm />}
        </Form>
    )
}

export default ExpenseForm
