import { Currencies } from "@finway-group/shared/lib/consts/currencies"
import { CurrencyEnum } from "@finway-group/shared/lib/models"
import { Checkbox, Col, Form, Image, Row, Select } from "antd"
import { FormInstance } from "antd/lib/form"
import { Store } from "antd/lib/form/interface"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import { useCompany } from "Shared/hooks/company.hooks"
import { NotificationService } from "Shared/services"
import CorporateService from "Shared/services/corporate.service"
import DialogService from "Shared/services/dialog.service"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { setKYBData, setWallet } from "Shared/store/actions/corporate/corporateActions"

interface KYBCreateAccountInterface {
    formInstance: FormInstance
    setIsLoading: (flag: boolean) => void
}

const KYBCreateAccount: React.FC<KYBCreateAccountInterface> = ({ formInstance, setIsLoading }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [shouldCreateAdminUsers, setShouldCreateAdminUsers] = useState(true)
    const isMultiCurrencyEnabled = useCompany()?.multiCurrencyWalletEnabled

    const onFinish = async (values: Store) => {
        setIsLoading(true)

        if (!isMultiCurrencyEnabled && !values.currency) {
            values.currency = CurrencyEnum.EUR
        }
        // make sure the non EUR selection is on purpose
        else if (values.currency !== CurrencyEnum.EUR && !(await DialogService.confirmNonEuroWallet())) {
            return
        }

        CorporateService.createBusinessAccount({ createAdminUsers: shouldCreateAdminUsers, currency: values.currency })
            .then(({ wallet, kybData, atLeastOneFailed }: any) => {
                if (atLeastOneFailed) {
                    NotificationService.send(NotificationTypeEnum.WARNING, t("notification:cards.not_all_enrolled.title"), t("notification:cards.not_all_enrolled.message"))
                } else {
                    NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:corporate.account_created.title"), t("notification:corporate.account_created.message"))
                }
                dispatch(setWallet(wallet))
                dispatch(setKYBData(kybData))
            })
            .catch((err) => NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:corporate.account_create.title")))
            .finally(() => setIsLoading(false))
    }

    return (
        <div>
            <div className="flex items-center">
                <h2 className="ml-10">{t("corporate:business_account.title")}</h2>
            </div>
            <Form form={formInstance} onFinish={onFinish} layout="vertical" className="mx-auto w-full ml-10">
                <Row gutter={[16, 0]}>
                    <Col span={24}>
                        <div>{t("info:corporate.create_account")}</div>
                    </Col>
                </Row>
                {isMultiCurrencyEnabled ? (
                    <Row gutter={[16, 0]} className="mt-20">
                        <Col span={14}>
                            <Form.Item
                                label={t("input:kyb.wallet_base_currency")}
                                name="currency"
                                style={{ marginBottom: "0px" }}
                                initialValue={CurrencyEnum.EUR}
                                extra={t("input:kyb.wallet_base_currency_note")}
                            >
                                <Select>
                                    {[CurrencyEnum.EUR, CurrencyEnum.USD, CurrencyEnum.GBP].map((currency) => (
                                        <Select.Option key={currency} value={currency} label={t("label:currency")}>
                                            <div className="space-x-10">
                                                <Image className="align-middle" width={20} preview={false} src={`data:image/png;base64,${Currencies[currency].flag}`} />
                                                <span className="text-text">{currency}</span>
                                                <span className="text-text">-</span>
                                                <span className="text-text">{Currencies[currency].name}</span>
                                            </div>
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                ) : null}
                <Row gutter={[16, 0]} className="mt-20">
                    <Col span={24}>
                        <Checkbox checked={shouldCreateAdminUsers} onChange={(event) => setShouldCreateAdminUsers(event.target.checked)}>
                            {t("input:corporate.enroll_all_admins")}
                        </Checkbox>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default KYBCreateAccount
