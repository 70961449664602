import { DatevOnlineConnectionStatusEnum, Vendor } from "@finway-group/shared/lib/models"
import { Button, Col, Row, Switch, Tag } from "antd"
import HttpStatus from "http-status"
import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { v4 as uuidv4 } from "uuid"

import VendorImage from "Components/VendorImage"
import { useCompany, useDatevOnlineConnected } from "Shared/hooks/company.hooks"
import { AuthzService, CompanyService, NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { setCompany } from "Shared/store/actions/company/companyActions"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

interface DatevOnlineSettingsInterface {}

const datevVendor = new Vendor({ name: "DATEV", img: "https://logo.clearbit.com/DATEV.de", id: uuidv4() })

const DatevOnlineSettings: React.FC<DatevOnlineSettingsInterface> = ({}) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const isDatevOnlineConnected = useDatevOnlineConnected()
    const [isLoadingDatev, setIsLoadingDatev] = useStateIfMounted(false)
    const [isLoadingAbort, setIsLoadingAbort] = useStateIfMounted(false)
    const [isLoadingDatevSwitch, setIsLoadingDatevSwitch] = useStateIfMounted(false)

    const company = useCompany()
    const isPending = company?.datevSettings?.datevContext?.status === DatevOnlineConnectionStatusEnum.PENDING
    const canWrite = AuthzService.canUpdateCompanyAccountingSettings()

    const onDATEVButtonClick = async () => {
        try {
            setIsLoadingDatev(true)
            if (isDatevOnlineConnected) {
                dispatch(setCompany(await CompanyService.revokeDatevConsent()))
                NotificationService.send(
                    NotificationTypeEnum.WARNING,
                    t("notification:settings.company.disconnected_datev.title"),
                    t("notification:settings.company.disconnected_datev.message"),
                )
            } else {
                const updatedCompany = await CompanyService.connectDatevOnline()
                if (updatedCompany?.datevSettings?.datevContext?.status === DatevOnlineConnectionStatusEnum.CONNECTED) {
                    NotificationService.send(
                        NotificationTypeEnum.SUCCESS,
                        t("notification:settings.company.connected_datev.title"),
                        t("notification:settings.company.connected_datev.message"),
                    )
                } else {
                    // still pending
                    NotificationService.send(
                        NotificationTypeEnum.INFO,
                        t("notification:settings.company.connect_datev.title"),
                        t("notification:settings.company.connect_datev.message"),
                        0,
                    )
                    window.open(updatedCompany?.datevSettings?.datevContext?.url, "_blank", "noopener")
                }
                dispatch(setCompany(updatedCompany))
            }
        } catch (err) {
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:error"))

            // If for some reason company gets updated in the db, but response don't reach the front end, this will prevent the user from being stuck in "status pending", and buttons "continue" and "abort" not working. (This could also be solved by refreshing/logging out too)
            if (err.response?.status === HttpStatus.UNPROCESSABLE_ENTITY && isPending) {
                const companyData = await CompanyService.fetchCompany()
                dispatch(setCompany(companyData))
            }
        } finally {
            setIsLoadingDatev(false)
        }
    }

    const onAbortSetup = async () => {
        try {
            setIsLoadingAbort(true)
            dispatch(setCompany(await CompanyService.abortDatevSetup()))
            NotificationService.send(
                NotificationTypeEnum.WARNING,
                t("notification:settings.company.aborted_datev_setup.title"),
                t("notification:settings.company.aborted_datev_setup.message"),
                10000,
            )
        } catch (err) {
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:error"))
        } finally {
            setIsLoadingAbort(false)
        }
    }

    const onDATEVAutoExport = async (value: boolean) => {
        try {
            setIsLoadingDatevSwitch(true)
            dispatch(setCompany(await CompanyService.updateDatevAutoExport(value)))
        } catch (err) {
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:error"))
        } finally {
            setIsLoadingDatevSwitch(false)
        }
    }

    const getDATEVButtonText = () => {
        switch (company?.datevSettings?.datevContext?.status) {
            case DatevOnlineConnectionStatusEnum.CONNECTED:
                return t("action:disconnect")
            case DatevOnlineConnectionStatusEnum.PENDING:
                return t("action:continue")
            case DatevOnlineConnectionStatusEnum.DISCONNECTED:
                return t("action:connect")
            default:
                return ""
        }
    }

    const getDATEVConnectionTag = () => {
        switch (company?.datevSettings?.datevContext?.status) {
            case DatevOnlineConnectionStatusEnum.CONNECTED:
                return <Tag className="ant-tag-green">{t("info:connected")}</Tag>
            case DatevOnlineConnectionStatusEnum.PENDING:
                return <Tag className="ant-tag-orange">{t("info:pending")}</Tag>
            default:
                return ""
        }
    }

    return (
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <div className="p-10 flex flex-col sm:flex-row border flex-grow w-full rounded-lg justify-between">
                    <div className="flex flex-row">
                        <div className="mr-10">
                            <VendorImage vendor={datevVendor} size={45} bordered />
                        </div>

                        <div className="align-left flex flex-col ml-10 justify-center">
                            <b className="truncate placeholder-primary">{t("label:datev_online")}</b>
                            {isDatevOnlineConnected && (
                                <div className="flex flex-row md:justify-center md:items-center">
                                    <span className="pr-10">{t("action:datev_auto_export")}</span>
                                    <Switch
                                        className=""
                                        size="small"
                                        loading={isLoadingDatevSwitch}
                                        checked={company?.datevSettings?.datevContext?.autoExport || false}
                                        onChange={onDATEVAutoExport}
                                        disabled={!canWrite}
                                    />
                                </div>
                            )}
                        </div>
                    </div>

                    {isDatevOnlineConnected ? (
                        <div className="flex flex-col mt-10 sm:mt-0 justify-center">
                            <span className="mr-8">
                                {t("label:consultant_number")} {company?.datevSettings?.datevContext?.consultantNumber}
                            </span>

                            <span className="mr-8">
                                {t("label:client_number")} {company?.datevSettings?.datevContext?.clientNumber}
                            </span>
                        </div>
                    ) : (
                        <div className="flex flex-col justify-center items-center">{getDATEVConnectionTag()}</div>
                    )}

                    <div className="flex justify-end md:justify-center items-center mt-auto mb-auto float-right">
                        <Button loading={isLoadingDatev} onClick={onDATEVButtonClick} disabled={!canWrite}>
                            {getDATEVButtonText()}
                        </Button>
                        {isPending && (
                            <Button loading={isLoadingAbort} onClick={onAbortSetup} className="min-w-120 ml-8" disabled={!canWrite}>
                                {t("action:abort_setup")}
                            </Button>
                        )}
                    </div>
                </div>
            </Col>
        </Row>
    )
}

export default DatevOnlineSettings
