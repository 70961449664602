import Icon from "@ant-design/icons"
import React from "react"

const pauseCard = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8.00065 14.6663C4.31875 14.6663 1.33398 11.6816 1.33398 7.99967C1.33398 4.31778 4.31875 1.33301 8.00065 1.33301C11.6825 1.33301 14.6673 4.31778 14.6673 7.99967C14.6673 11.6816 11.6825 14.6663 8.00065 14.6663ZM8.00065 13.9997C11.3144 13.9997 14.0007 11.3134 14.0007 7.99967C14.0007 4.68597 11.3144 1.99967 8.00065 1.99967C4.68694 1.99967 2.00065 4.68597 2.00065 7.99967C2.00065 11.3134 4.68694 13.9997 8.00065 13.9997ZM6.00065 4.66634C6.73703 4.66634 7.33398 5.26329 7.33398 5.99967V9.99967C7.33398 10.7361 6.73703 11.333 6.00065 11.333C5.26427 11.333 4.66732 10.7361 4.66732 9.99967V5.99967C4.66732 5.26329 5.26427 4.66634 6.00065 4.66634ZM6.00065 5.33301C5.63246 5.33301 5.33398 5.63148 5.33398 5.99967V9.99967C5.33398 10.3679 5.63246 10.6663 6.00065 10.6663C6.36884 10.6663 6.66732 10.3679 6.66732 9.99967V5.99967C6.66732 5.63148 6.36884 5.33301 6.00065 5.33301ZM10.0007 4.66634C10.737 4.66634 11.334 5.26329 11.334 5.99967V9.99967C11.334 10.7361 10.737 11.333 10.0007 11.333C9.26427 11.333 8.66732 10.7361 8.66732 9.99967V5.99967C8.66732 5.26329 9.26427 4.66634 10.0007 4.66634ZM10.0007 5.33301C9.63246 5.33301 9.33398 5.63148 9.33398 5.99967V9.99967C9.33398 10.3679 9.63246 10.6663 10.0007 10.6663C10.3688 10.6663 10.6673 10.3679 10.6673 9.99967V5.99967C10.6673 5.63148 10.3688 5.33301 10.0007 5.33301Z"
            fill="#606C7E"
            stroke="#606C7E"
            strokeWidth="0.2"
        />
    </svg>
)

const PauseCardIcon = (props: any) => <Icon component={pauseCard} {...props} />

export default PauseCardIcon
