import { parseCurrencyInput } from "Components/currencyInput/config"
import i18n from "Shared/locales/i18n"

export const cardLimitRules = () => ({
    cardLimit: [
        {
            validator: (_rule: any, inputValue: string) => {
                let value = 0
                if (inputValue) {
                    value = parseCurrencyInput(inputValue)
                    if (value <= 0) {
                        return Promise.reject(new Error(i18n.t("validation:greater.number_short", { min: 0 })))
                    }

                    return Promise.resolve()
                }

                return Promise.reject(new Error(i18n.t("validation:greater.number_short", { min: 0 })))
            },
        },
    ],
    reason: [{ required: true, message: i18n.t("validation:card_limit_change_reason"), trigger: "blur" }],
})
