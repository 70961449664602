import { Badge } from "antd"
import moment from "moment"
import React, { useEffect } from "react"
import { Trans } from "react-i18next"
import { Link } from "react-router-dom"

import UserImage from "Components/UserImage"
import { useCards } from "Shared/hooks/creditCard.hooks"
import CardService from "Shared/services/card.service"
import { SystemEmployee } from "Shared/utils/navbarActivityTemplates.utils"

import useStateIfMounted from "../../../../../../shared/utils/hooks/useStateIfMounted"

interface SystemActivityInterface {
    cardId: string
    created: Date
    isNotViewed: boolean
    description?: any
    link?: string
}

const CardSystemActivityCard = ({ cardId, created, isNotViewed, description, link = "" }: SystemActivityInterface) => {
    const [cardNumber, setCardNumber] = useStateIfMounted(undefined)

    const selectedCard = useCards().cards.filter((card: any) => card._id === cardId)?.[0]

    useEffect(() => {
        let isFetching = true
        const fetchCardById = async () => {
            const data = await CardService.fetchCardById(cardId)
            setCardNumber(data?.last4Digits)
        }

        if (!selectedCard && isFetching) {
            fetchCardById()
            return
        }

        setCardNumber(selectedCard?.last4Digits)

        return () => {
            isFetching = false
        }
    }, [])

    return (
        <>
            <Link to={link}>
                <UserImage user={SystemEmployee} size="large" />
            </Link>
            <div className="flex flex-col mr-5 overflow-auto w-full">
                <div className="mt-1 mb-1 leading-none">
                    <Link to={link} className="text-text-dark text-xs font-bold">
                        Card **** {cardNumber}
                    </Link>
                </div>
                <Trans>
                    <div className="text-xs leading-tight whitespace-normal">{description}</div>
                </Trans>
                <div>
                    <time className="text-xs">{moment(new Date(created)).fromNow()}</time>
                </div>
            </div>
            {isNotViewed ? (
                <div className="mt-14">
                    <Badge dot={true} />
                </div>
            ) : (
                <></>
            )}
        </>
    )
}

export default React.memo(CardSystemActivityCard)
