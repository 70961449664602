import { TripFolder } from "@finway-group/shared/lib/models"
import { MAX_INPUT_LENGTH, TRIP_DATE_FUTURE_LIMIT_YEARS, TRIP_DATE_PAST_LIMIT_YEARS, TRIP_MAXIMUM_DURATION_DAYS } from "@finway-group/shared/lib/utils/validators"
import { Alert, Col, DatePicker, Form, Input, List, Row, TimePicker } from "antd"
import moment from "moment"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import { useExpenseFormContext } from "Components/modals/expenseCreateForm.context"
import { isFolderExpense } from "Shared/utils/expense.utils"
import { getIntegerRangeExclusive } from "Shared/utils/helper.utils"
import { getPopupAnchor } from "Shared/utils/popup.utils"

import { TripFolderFieldsRule } from "../rules"
import ExpenseCommonInputFieldsForm from "./commonInputFields/commonInputFields.form"

const TripFolderRequestForm = () => {
    const { t } = useTranslation()

    const [previousDestName, setPreviousDestName] = useState()

    const [{ expenseForm, updateExpense, expense }] = useExpenseFormContext()
    const tripFolderRules = TripFolderFieldsRule(expense as TripFolder)

    if (!isFolderExpense(expense)) return <></>
    const pastDateLimit = moment().subtract(TRIP_DATE_PAST_LIMIT_YEARS, "year")
    const futureDateLimit = moment().add(TRIP_DATE_FUTURE_LIMIT_YEARS, "year")
    const startDisabledDate = (current: moment.Moment) =>
        (expense.returnDate && current.isAfter(expense.returnDate, "minute")) || !current.isBetween(pastDateLimit, futureDateLimit, "day")
    const endDisabledDate = (current: moment.Moment) =>
        (expense.tripDate && current.isBefore(expense.tripDate, "minute")) || !current.isBetween(pastDateLimit, futureDateLimit, "day")
    const startDisabledTime = (current: moment.Moment) => {
        const returnDate = expense.returnDate ? moment(expense.returnDate) : undefined
        return {
            disabledHours: () => {
                if (!returnDate) return []
                if (!returnDate.isSame(current, "day")) return []
                const disabledFrom = returnDate.get("hour")
                return getIntegerRangeExclusive(disabledFrom, 24)
            },
            disabledMinutes: (selectedHour: number) => {
                if (!returnDate) return []
                if (returnDate.get("hour") !== selectedHour) return []
                const disabledFrom = returnDate.get("minute")
                return getIntegerRangeExclusive(disabledFrom, 60)
            },
        }
    }
    const endDisabledTime = (current: moment.Moment) => {
        const startDate = expense.tripDate ? moment(expense.tripDate) : undefined
        return {
            disabledHours: () => {
                if (!startDate) return []
                if (!startDate.isSame(current, "day")) return []
                const disabledTo = startDate.get("hour")
                return getIntegerRangeExclusive(0, disabledTo)
            },
            disabledMinutes: (selectedHour: number) => {
                if (!startDate) return []
                if (startDate.get("hour") !== selectedHour) return []
                const disabledTo = startDate.get("minute")
                return getIntegerRangeExclusive(0, disabledTo)
            },
        }
    }

    const isTripDateExceedsAllowedPeriod = expense.tripDate && expense.returnDate && moment(expense.returnDate).diff(moment(expense.tripDate), "days") > TRIP_MAXIMUM_DURATION_DAYS

    const infoList = [
        t("label:trip_folder_request_info.step_1"),
        t("label:trip_folder_request_info.step_2"),
        t("label:trip_folder_request_info.step_3"),
        t("label:trip_folder_request_info.step_4"),
        t("label:trip_folder_request_info.step_5"),
        t("label:trip_folder_request_info.step_6"),
        t("label:trip_folder_request_info.step_7"),
    ]

    const handleOnChange = () => {
        let updateFolderName = false
        const formValues = expenseForm.getFieldsValue()
        const { tripDate, returnDate, destination, folderName } = formValues

        if (!previousDestName || previousDestName !== destination) {
            setPreviousDestName(destination)
            if (tripDate && returnDate && destination) {
                updateFolderName = true
            }
        } else if (!folderName && tripDate && returnDate && destination) {
            updateFolderName = true
        }

        if (updateFolderName) {
            formValues.folderName = `${tripDate.format("DD.MM.YYYY")}-${returnDate.format("DD.MM.YYYY")}-${destination.toLowerCase()}`
        }

        updateExpense(formValues)
    }

    return (
        <div className="w-full px-4">
            <Row gutter={[16, 16]} justify="center">
                <Col xs={24} lg={10} span={12} id="info">
                    <div className="flex items-center">
                        <List
                            header={<h2>{t("label:trip_folder_info")}</h2>}
                            dataSource={infoList}
                            renderItem={(item, index) => (
                                <List.Item>
                                    <div className="flex items-center justify-between" key={index}>
                                        <div className="flex ant-btn btn-highlight-green items-center justify-center rounded-full w-28 h-28 mr-8">{index + 1}</div>
                                        <div className="btn-highlight-green max-w-lg">{item}</div>
                                    </div>
                                </List.Item>
                            )}
                        />
                    </div>
                </Col>
                <Col xs={24} lg={10} span={12} id="form">
                    <h2 className="pt-12">{t("label:trip_folder_title")}</h2>
                    <Row gutter={[16, 16]}>
                        <Col span={6}>
                            <Form.Item label={t("input:request.folder.trip_date")} name={"tripDate"} key="startDate" rules={tripFolderRules.tripDate}>
                                <DatePicker
                                    allowClear={false}
                                    format={"DD/MM/YYYY"}
                                    getPopupContainer={getPopupAnchor()}
                                    onChange={handleOnChange}
                                    disabledDate={startDisabledDate}
                                    data-testid="startDatePicker"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={t("input:request.folder.time")} name={"tripDate"} key="tripTime" rules={tripFolderRules.tripTime}>
                                <TimePicker
                                    allowClear={false}
                                    format={"HH:mm"}
                                    getPopupContainer={getPopupAnchor()}
                                    popupClassName="timepicker-display-fix"
                                    onChange={handleOnChange}
                                    disabledDate={startDisabledDate}
                                    {...startDisabledTime(moment(expense.tripDate))}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={t("input:request.folder.return_date")} name={"returnDate"} key="returnDate" rules={tripFolderRules.returnDate}>
                                <DatePicker
                                    allowClear={false}
                                    className="w-full"
                                    format={"DD/MM/YYYY"}
                                    getPopupContainer={getPopupAnchor()}
                                    onChange={handleOnChange}
                                    disabledDate={endDisabledDate}
                                    data-testid="returnDatePicker"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={t("input:request.folder.time")} name={"returnDate"} key="returnTime" rules={tripFolderRules.returnTime}>
                                <TimePicker
                                    allowClear={false}
                                    className="w-full"
                                    format={"HH:mm"}
                                    getPopupContainer={getPopupAnchor()}
                                    popupClassName="timepicker-display-fix"
                                    onChange={handleOnChange}
                                    disabledDate={endDisabledDate}
                                    {...endDisabledTime(moment(expense.returnDate))}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    {isTripDateExceedsAllowedPeriod && (
                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                <Alert message={t("notification:trip_folder.date_error")} type="error" closable={false} showIcon={true} />
                            </Col>
                        </Row>
                    )}

                    <Row gutter={[16, 16]}>
                        <Col data-testid="tripFolderDestination" span={24}>
                            <Form.Item
                                className="max-w-160 md:max-w-full"
                                label={t("input:request.folder.destination")}
                                name={"destination"}
                                key="destination"
                                rules={tripFolderRules.requiredTextInput}
                            >
                                <Input size="small" maxLength={MAX_INPUT_LENGTH} placeholder={t("placeholder:request.folder.destination")} onChange={handleOnChange} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Form.Item
                                className="max-w-160 md:max-w-full"
                                label={t("input:request.folder.folder_name")}
                                name={"folderName"}
                                key="folder_name"
                                rules={tripFolderRules.requiredTextInput}
                            >
                                <Input size="small" placeholder={t("placeholder:request.folder.folder_name")} maxLength={100} onChange={handleOnChange} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Col span={24}>
                        <Form.Item name={"description"} key="description" label={t("input:request.per_diem.reason")}>
                            <Input.TextArea
                                className="leading-normal p-0"
                                autoSize
                                rows={1}
                                name="description"
                                maxLength={600}
                                placeholder={t("placeholder:request.description")}
                                onChange={handleOnChange}
                            />
                        </Form.Item>
                    </Col>

                    <ExpenseCommonInputFieldsForm allowPaymentFlow={false} showVendor={false} />
                </Col>
            </Row>
        </div>
    )
}

export default TripFolderRequestForm
