import React from "react"

import PaymentSignUpBanner from "Features/settings/billing/paymentSignUp.banner"

import DatevOnlineErrorBanner from "./datevOnlineError.banner"
import SpreadsheetImportInProgressBanner from "./spreadsheetImportInProgress.banner"
import WeavrOnBoardingBanner from "./weavrOnBoarding.banner"

const BannerContainer = () => (
    <div className="finway-global-banner mx-10 md:mx-0">
        <PaymentSignUpBanner />
        <WeavrOnBoardingBanner />
        <SpreadsheetImportInProgressBanner />
        <DatevOnlineErrorBanner />
    </div>
)

export default BannerContainer
