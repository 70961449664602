import * as Sentry from "@sentry/react"
import React from "react"

import Error from "Components/layout/error"
import ErrorPage from "Features/errors/ErrorPage"
import { ErrorTypes } from "Shared/error.types"

export default function ErrorWrapper({ children }: { children: JSX.Element }) {
    const ErrorHandler: Sentry.FallbackRender = (props) => {
        const { error } = props
        // in react development mode, react error boundaries will still rethrow the error so that you will see the red screen, but on production this wont happen.
        if (error.message === ErrorTypes.ACCESS_TOKEN_MISSING) {
            props.resetError()
            return <></>
        }
        return (
            <Error>
                <ErrorPage {...props} />
            </Error>
        )
    }

    return <Sentry.ErrorBoundary fallback={ErrorHandler}>{children}</Sentry.ErrorBoundary>
}
