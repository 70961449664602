import { CreditorInterface, Employee, Expense, Vendor } from "@finway-group/shared/lib/models"
import { useEffect } from "react"
import { useSelector } from "react-redux"

import { FINWAY_ADMIN_EMAIL } from "Shared/config/consts"
import { VendorService } from "Shared/services"
import store from "Shared/store"
import { RootState } from "Shared/store/rootState.interface"
import { shouldExpenseIncludeEmployeesAsVendor, shouldExpenseIncludeVendors } from "Shared/utils/expense.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"
import { createCreditorObject } from "Shared/utils/vendor.utils"

import { useLoggedInEmployee } from "./employee.hooks"

export const useVendors = (excludeDeleted: boolean = false, searchValue?: string) => {
    const vendors = useSelector(({ vendors }: RootState) => vendors.items)
    return filterVendors(vendors, excludeDeleted, searchValue)
}

export const getVendorsFromStore = (excludeDeleted: boolean = false, searchValue?: string) => {
    const vendors = store.getState().vendors.items
    return filterVendors(vendors, excludeDeleted, searchValue)
}

const filterVendors = (vendors: Array<any>, excludeDeleted: boolean = false, searchValue?: string) => {
    if (excludeDeleted) {
        vendors = vendors?.filter((vendor: Vendor) => !vendor.deleted)
    }

    if (searchValue) {
        vendors = vendors?.filter(
            (vendor: Vendor) => vendor.name.toLowerCase().includes(searchValue.toLowerCase()) || vendor.creditorNumber?.toString().includes(searchValue.toLowerCase()),
        )
    }

    return vendors.map((vendor: any) => new Vendor(vendor)).sort((a: Vendor, b: Vendor) => a.name?.localeCompare(b.name))
}

export const useVendorById = (id: string) => useVendors().find((vendor: Vendor) => vendor.id === id)

export const useVendor = () => new Vendor(useSelector(({ vendors }: RootState) => vendors.item))

/*
 * CREDITOR-HOOKS
 */

export const useAllCreditors = () => useSelector(({ creditors }: RootState) => creditors.items)

// export const useCreditorById = (creditorId: string) => useAllCreditors().get(creditorId)

// TODO: remove and enable the one above again once we have react query
export const useCreditorById = (creditorId: string | undefined) => {
    const creditors = useAllCreditors()
    const [isFetching, setIsFetching] = useStateIfMounted(false)
    const targetCreditor = creditorId ? creditors.get(creditorId) : undefined
    const [vendor, setVendor] = useStateIfMounted(targetCreditor)

    useEffect(() => {
        const creditor = creditorId ? creditors.get(creditorId) : undefined
        if (creditor) {
            setVendor(creditor)
        } else if (creditorId && !vendor && !isFetching) {
            setIsFetching(true)
            VendorService.fetchOneVendor(creditorId)
                .then((vendor) => setVendor(createCreditorObject(vendor)))
                .catch(() => {}) // ignore should never happen
                .finally(() => setIsFetching(false))
        } else {
            setVendor(undefined)
        }
    }, [creditorId, targetCreditor?.creditorNumber])

    return vendor
}

export const useCreditors = (canSeeEveryEmployee: boolean, includeVendors: boolean, includeEmployees: boolean) => {
    const res: Array<CreditorInterface> = []

    useAllCreditors().forEach((creditor) => {
        // only add vendors if requested
        if (includeVendors && creditor.source instanceof Vendor && !creditor.source.deleted) {
            res.push(creditor)
            return
        }

        // only add all employees if requested
        if (
            includeEmployees &&
            canSeeEveryEmployee &&
            creditor.source instanceof Employee &&
            creditor.source.email !== FINWAY_ADMIN_EMAIL &&
            !creditor.source.activeCompanyProfile?.deleted
        ) {
            res.push(creditor)
        }
    })
    // add only self
    const currentEmployee = useLoggedInEmployee()
    if (includeEmployees && !canSeeEveryEmployee) res.push(createCreditorObject(currentEmployee))

    return res
}

export const useFilteredCreditorsForExpense = (canSeeEveryEmployee: boolean, expense: Expense) =>
    useCreditors(canSeeEveryEmployee, shouldExpenseIncludeVendors(expense), shouldExpenseIncludeEmployeesAsVendor(expense))
