import Icon from "@ant-design/icons"
import React from "react"

const newCostCenter1 = () => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="32" height="32" rx="16" fill="#ECF3FF" />
        <path
            d="M14.8711 13.9377C14.8711 14.9633 16.9145 15.5 18.933 15.5C20.9514 15.5 22.9949 14.9633 22.9949 13.9377C22.9949 11.8866 14.8711 11.8866 14.8711 13.9377Z"
            fill="#3886FF"
        />
        <path d="M8 18.3122C8 19.3378 10.0435 19.8745 12.0619 19.8745C14.0803 19.8745 16.1238 19.3378 16.1238 18.3122C16.1238 16.2612 8 16.2612 8 18.3122Z" fill="#3886FF" />
        <path
            d="M15.6664 19.8162C14.8248 20.2568 13.5448 20.4997 12.0619 20.4997C10.579 20.4997 9.29894 20.2568 8.45739 19.8162C8.36066 19.7656 8.2438 19.7696 8.15073 19.8257C8.05706 19.8821 8 19.9838 8 20.093V20.1873C8 21.2128 10.0435 21.7495 12.0619 21.7495C14.0803 21.7495 16.1238 21.2128 16.1238 20.1873V20.093C16.1238 19.9838 16.0667 19.8821 15.973 19.8257C15.8794 19.7696 15.7631 19.7656 15.6664 19.8162Z"
            fill="#3886FF"
        />
        <path
            d="M13.9369 13.6255L14.0522 13.6237C14.181 13.6228 14.296 13.5428 14.3418 13.4223C14.6427 12.6323 15.7228 12.0745 17.3833 11.8521C17.51 11.835 17.6134 11.7429 17.6448 11.6187C17.676 11.4948 17.6287 11.3645 17.5252 11.2894C16.8545 10.8031 15.4796 10.501 13.9369 10.501C11.9185 10.501 9.875 11.0377 9.875 12.0632C9.875 13.0888 11.9185 13.6255 13.9369 13.6255Z"
            fill="#3886FF"
        />
        <path
            d="M13.9369 15.5005L14.0693 15.4984C14.1877 15.4975 14.2954 15.43 14.3476 15.3238C14.3888 15.2399 14.4312 15.1731 14.4767 15.1191C14.5673 15.012 14.5755 14.8579 14.4971 14.742C14.4404 14.6577 14.3909 14.5616 14.347 14.4478C14.2991 14.3242 14.1157 14.2495 13.9997 14.2495L13.9369 14.2507C12.454 14.2507 11.1739 14.0078 10.3324 13.5672C10.2354 13.5166 10.1191 13.5205 10.0257 13.5767C9.93206 13.6331 9.875 13.7347 9.875 13.844V13.9383C9.875 14.9638 11.9185 15.5005 13.9369 15.5005Z"
            fill="#3886FF"
        />
        <path
            d="M22.5396 17.3167C21.6981 17.7573 20.4181 18.0002 18.9351 18.0002C18.2941 18.0002 17.6762 17.9517 17.0983 17.8565C17.0028 17.8419 16.9063 17.8696 16.8358 17.9346C16.7651 17.9999 16.7281 18.0939 16.7358 18.1897C16.7361 18.1937 16.7474 18.3087 16.748 18.3127C16.748 18.4289 16.7315 18.5482 16.6976 18.6776C16.676 18.7606 16.6894 18.8491 16.7348 18.922C16.7803 18.9949 16.8538 19.0459 16.9381 19.063C17.5447 19.1853 18.2352 19.25 18.9351 19.25C20.9536 19.25 22.997 18.7133 22.997 17.6878V17.5935C22.997 17.4842 22.94 17.3826 22.8463 17.3262C22.7529 17.27 22.6361 17.2661 22.5396 17.3167Z"
            fill="#3886FF"
        />
        <path
            d="M22.5375 15.4417C21.6959 15.8823 20.4159 16.1252 18.933 16.1252C17.4501 16.1252 16.17 15.8823 15.3285 15.4417C15.2315 15.3911 15.1146 15.395 15.0218 15.4512C14.9282 15.5076 14.8711 15.6092 14.8711 15.7185V15.8128C14.8711 16.8383 16.9145 17.375 18.933 17.375C20.9514 17.375 22.9949 16.8383 22.9949 15.8128V15.7185C22.9949 15.6092 22.9378 15.5076 22.8441 15.4512C22.7508 15.395 22.6339 15.3911 22.5375 15.4417Z"
            fill="#3886FF"
        />
        <path
            d="M10.2122 16.2653C10.2286 16.2653 10.2454 16.2641 10.2622 16.2613C10.3815 16.2421 10.503 16.2262 10.625 16.211C10.7666 16.1936 10.8783 16.0828 10.8966 15.9415C10.9152 15.8003 10.8359 15.6642 10.7034 15.6111C10.5741 15.5589 10.4493 15.5028 10.3324 15.4417C10.2354 15.3911 10.1191 15.395 10.0257 15.4512C9.93206 15.5079 9.875 15.6092 9.875 15.7185V15.8128C9.875 15.89 9.88843 15.9681 9.91589 16.0511C9.95861 16.1804 10.0794 16.2653 10.2122 16.2653Z"
            fill="#3886FF"
        />
    </svg>
)

const NewCostCenter1Icon = (props: any) => <Icon component={newCostCenter1} {...props} />

export default NewCostCenter1Icon
