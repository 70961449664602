import { Employee } from "@finway-group/shared/lib/models"
import { Button } from "antd"
import React, { useState } from "react"
import { Edit as EditIcon } from "react-feather"
import { useTranslation } from "react-i18next"

import EmployeeRuleModal from "Components/modals/employeeRule.modal"
import { useCompany } from "Shared/hooks/company.hooks"
import { useCostCenter2ById, useCostCenters2 } from "Shared/hooks/costCenter2.hooks"
import { useCostCenterById } from "Shared/hooks/costCenter.hooks"
import { useExpenseAccountById, useExpenseAccounts } from "Shared/hooks/expenseAccount.hooks"
import { AuthzService } from "Shared/services"

interface EmployeeRulesInterface {
    employee: Employee
}

const EmployeeRules: React.FC<EmployeeRulesInterface> = ({ employee }) => {
    const { t } = useTranslation()
    const currentCompanyId = useCompany()._id
    const currentRule = employee.rule?.filter(({ companyId }) => companyId === currentCompanyId)[0]

    const costCenter = useCostCenterById(currentRule?.costCenter, true)
    const costCenter2 = useCostCenter2ById(currentRule?.costCenter2)
    const costCenters2 = useCostCenters2()
    const expenseAccounts = useExpenseAccounts()
    const expenseAccount = useExpenseAccountById(currentRule?.expenseAccount)
    const [isModalShowing, setIsModalShowing] = useState(false)
    const openRuleModal = () => setIsModalShowing(true)

    const canUpdateEmployeeRules = AuthzService.canUpdateUserApprovalData(employee)

    const getEmployeeRuleButton = () => (
        <Button size="small" className="btn-default block w-full" onClick={openRuleModal}>
            <span>{t("action:set")}</span>
        </Button>
    )

    const getEditRulesIcon = () => <EditIcon className="ml-14 cursor-pointer w-14 min-w-14" onClick={openRuleModal} />

    return (
        <>
            <EmployeeRuleModal employeeIDToUpdate={employee.id} onCancel={setIsModalShowing} isShowing={isModalShowing} />
            <ul className="description-list overflow-hidden">
                <li>
                    <span className="title min-w-100">{t("label:cost_center")}</span>
                    <div className="flex text truncate min-w-100 items-center justify-end">
                        {canUpdateEmployeeRules ? (
                            costCenter ? (
                                <>
                                    <span className="flex overflow-x-auto">
                                        <span className="truncate">{costCenter.name}</span>
                                    </span>
                                    {getEditRulesIcon()}
                                </>
                            ) : (
                                getEmployeeRuleButton()
                            )
                        ) : (
                            <span className="flex overflow-x-auto">
                                <span className="truncate">{costCenter?.name || "-"}</span>
                            </span>
                        )}
                    </div>
                </li>
                {costCenters2.length > 0 && (
                    <li>
                        <span className="title min-w-100">{t("label:cost_center_2")}</span>
                        <div className="flex text truncate min-w-100 items-center justify-end">
                            {canUpdateEmployeeRules ? (
                                costCenter2 ? (
                                    <>
                                        <span className="flex overflow-x-auto">
                                            <span className="truncate">{costCenter2.name}</span>
                                        </span>
                                        {getEditRulesIcon()}
                                    </>
                                ) : (
                                    getEmployeeRuleButton()
                                )
                            ) : (
                                <span className="flex flex-row overflow-x-auto">
                                    <span className="truncate">{costCenter2?.name || "-"}</span>
                                </span>
                            )}
                        </div>
                    </li>
                )}
                {expenseAccounts.length > 0 && (
                    <li>
                        <span className="title min-w-140">{t("label:expense_account")}</span>
                        <div className="flex text truncate min-w-100 items-center justify-end">
                            {canUpdateEmployeeRules ? (
                                expenseAccount ? (
                                    <>
                                        <span className="flex flex-row overflow-x-auto">
                                            <span className="truncate">
                                                {expenseAccount.accountCode} - {expenseAccount.accountName}
                                            </span>
                                        </span>
                                        {getEditRulesIcon()}
                                    </>
                                ) : (
                                    getEmployeeRuleButton()
                                )
                            ) : (
                                <span className="flex flex-row overflow-x-auto">
                                    <span className="truncate">{(expenseAccount && `${expenseAccount.accountCode} - ${expenseAccount.accountName}`) || "-"}</span>
                                </span>
                            )}
                        </div>
                    </li>
                )}
            </ul>
        </>
    )
}
export default EmployeeRules
