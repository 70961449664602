import { Col, Row } from "antd"
import Form, { FormInstance } from "antd/lib/form/Form"
import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import { useAuth } from "Shared/hooks/auth.hooks"
import { useWeavrPasscodeInput } from "Shared/hooks/weavr.hooks"
import { CorporateService, NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { fetchKYBProgress } from "Shared/store/actions/corporate/corporateActions"
import { syncEmployee } from "Shared/store/actions/employee/employeeActions"

interface KYBSetupPINInterface {
    formInstance: FormInstance
    onSubmit: (tokenizedPassword: string) => void
    setIsLoading: (flag: boolean) => void
}

const KYBSetupPIN = ({ formInstance, onSubmit, setIsLoading }: KYBSetupPINInterface) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { secureForm } = useWeavrPasscodeInput("passcode")
    const { userId } = useAuth()

    const onFinish = () => {
        setIsLoading(true)
        secureForm.tokenize(
            (tokens: any) => {
                const tokenizedPassword = tokens.passcode
                CorporateService.createUserPasscode(tokenizedPassword)
                    .then(async () => {
                        await syncEmployee(userId)(dispatch)
                        dispatch(fetchKYBProgress())
                        NotificationService.send(
                            NotificationTypeEnum.SUCCESS,
                            t("notification:corporate.setup_user_passcode.title"),
                            t("notification:corporate.setup_user_passcode.message"),
                        )
                        onSubmit(tokenizedPassword)
                    })
                    .catch((err) => NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:corporate.setup_user_passcode.title")))
                    .finally(() => setIsLoading(false))
            },
            (err: any) => {
                NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:corporate.setup_user_passcode.title"), t("error:corporate.setup_user_passcode.message"))
                setIsLoading(false)
            },
        )
    }

    return (
        <>
            <div className="flex items-center">
                <h2 className="ml-10">{t("corporate:pin.title")}</h2>
            </div>
            <Row gutter={[16, 0]}>
                <Col span={20}>
                    <div className="ml-10">{t("info:corporate.user_pincode_info")}</div>
                </Col>
            </Row>
            <div className="flex items-center mt-20 ml-10">
                <Form form={formInstance} onFinish={onFinish} layout="vertical" className="mx-auto w-full">
                    <Row gutter={[16, 0]}>
                        <Col span={24}>
                            <div className="ant-form-item-label">
                                <label className={"ant-form-item-required"}>{t("input:corporate.pin")}</label>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className="ant-input rounded-lg min-h-32 mt-4 pt-8 max-w-140" id="passcode" />
                        </Col>
                    </Row>
                </Form>
            </div>
        </>
    )
}

export default React.memo(KYBSetupPIN)
