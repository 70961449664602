import { GREEN } from "@finway-group/shared/lib/consts"
import { Employee } from "@finway-group/shared/lib/models"
import { Avatar, Skeleton, Tooltip } from "antd"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"

import { doesImageExist } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

import { CrossRedCircled } from "./icons/crossRedCircled"
import TickGreenCircled from "./icons/tickGreenCircled"

export type UserImageBadgeType = "success" | "failure"

type UserImageType = {
    user: Employee
    size?: "large" | "small" | number
    badge?: UserImageBadgeType
    heightPadding?: boolean
    isPersistent?: boolean
    tooltipTitle?: string
}

const UserImage = ({ user, size = "small", badge, heightPadding = true, isPersistent = false, tooltipTitle }: UserImageType) => {
    if (!user) return null

    const [imgLoading, setImgLoading] = useStateIfMounted(true)
    const [showInitials, setShowInitials] = useStateIfMounted(false)
    const { t } = useTranslation()

    const initInitialAvatar = () => {
        setImgLoading(false)
        setShowInitials(true)
    }

    useEffect(() => {
        let isSubscribing = true
        doesImageExist(
            user?.img,
            () => {
                if (isSubscribing) {
                    setImgLoading(false)
                    setShowInitials(false)
                }
            },
            () => isSubscribing && initInitialAvatar(),
        )

        return () => {
            isSubscribing = false
        }
    }, [user?.img])

    const getNameInitials = () => {
        const fullName = ""

        try {
            return user.getInitials().replace(" ", "")
        } catch (error) {
            return fullName
        }
    }

    const minWidth = size === "large" ? 38 : size === "small" ? 24 : size
    const minHeight = size === "large" ? 38 : size === "small" ? 24 : size

    const onError = () => {
        initInitialAvatar()
        return false
    }

    const renderSuccessOrFailureBadge = (badgeType: UserImageBadgeType) =>
        badgeType === "success" ? (
            <TickGreenCircled size={10} className="absolute z-10" style={{ fontSize: "10px", bottom: "-2px", right: "-2px" }} />
        ) : badgeType === "failure" ? (
            <CrossRedCircled size={10} className="absolute z-10" style={{ fontSize: "10px", bottom: "-2px", right: "-2px" }} />
        ) : null

    return (
        <div style={{ height: heightPadding ? minHeight + 8 : minHeight, width: minWidth + 14 }} className="flex flex-shrink-0 justify-center items-center">
            {imgLoading ? (
                <Skeleton.Avatar key={user?.id} className={`min-w-${minWidth} min-h-${minHeight}`} active={true} size={size} />
            ) : (
                <div className="relative">
                    {badge && renderSuccessOrFailureBadge(badge)}
                    <Tooltip title={tooltipTitle} placement="top">
                        {showInitials ? (
                            <Avatar
                                key={user?.id}
                                size={size}
                                className={`${isPersistent ? "" : "animation-appear"} flex items-center self-center min-w-${minWidth} min-h-${minHeight}`}
                                style={{ color: GREEN, backgroundColor: "#ffffff" }}
                            >
                                {getNameInitials().trim()}
                            </Avatar>
                        ) : (
                            <Avatar key={user?.id} className={`min-w-${minWidth} min-h-${minHeight} bg-white`} size={size} src={user?.img} alt="User image" onError={onError} />
                        )}
                    </Tooltip>
                </div>
            )}
        </div>
    )
}

export default UserImage
