import { MentionData } from "@draft-js-plugins/mention"
import { CollectionNameEnum, CommentItem, Employee, Expense, OperationTypeEnum, Vendor } from "@finway-group/shared/lib/models"
import { Comment } from "antd"
import { CompositeDecorator, ContentState, DraftDecorator, EditorState } from "draft-js"
import React, { Suspense, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import UserImage from "Components/UserImage"
import { FINWAY_ADMIN_EMAIL } from "Shared/config/consts"
import { useEmployees } from "Shared/hooks/employee.hooks"
import { NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { updateComments as updateExpenseComment } from "Shared/store/actions/expense/expenseActions"
import { updateComments as updateVendorComment } from "Shared/store/actions/vendor/vendorActions"
import { isEmptyString } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

import { useEditorDecoratorProps } from "./useEditorDecoratorsProps"

const CommentEditor = React.lazy(() => import("./comment.editor"))
const CommentList = React.lazy(() => import("./comment.list"))

interface CommentContainerInterface {
    loggedInUser: Employee
    object: Expense | Vendor
    type: CollectionNameEnum
    comments: Array<CommentItem>
}

const CommentContainer = ({ loggedInUser, object, type, comments }: CommentContainerInterface) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const employees = useEmployees({ excludeDeleted: true })
    // So that when users do something with comments, it doesn't wait for the dispatch to resolve to display the results
    const [commentsView, setCommentsView] = useStateIfMounted(comments)

    const decoratorProps = useEditorDecoratorProps()
    const pluginDecorators = decoratorProps.plugins.reduce<Array<DraftDecorator>>((acc: any, p: any) => [...acc, ...p.decorators], [])
    const [editorState, setEditorState] = useStateIfMounted<EditorState>(EditorState.createWithContent(ContentState.createFromText(""), new CompositeDecorator(pluginDecorators)))

    const mapToMentionData = (list: Array<Employee>) =>
        list
            .filter((employee: Employee) => employee && employee.id !== loggedInUser.id && employee.email !== FINWAY_ADMIN_EMAIL)
            .map(({ getFullName, img, id }: Employee) => ({
                name: getFullName?.(),
                avatar: img && img.length ? img : `${window.location.origin}/images/default.svg`,
                link: id,
            })) as Array<MentionData>

    // Make sure that commentView always a 1:1 copy the comments prop when there's a change.
    useEffect(() => {
        setCommentsView(comments)
    }, [comments])

    const handleSubmit = (newComment: string) => {
        if (isEmptyString(newComment)) {
            NotificationService.send(NotificationTypeEnum.ERROR, t("error:comment.save.title"), t("error:comment.save.message"))
            return
        }

        const comment = new CommentItem({
            postedBy: loggedInUser.id,
            message: newComment,
            datePosted: new Date(),
            isAutoGenerated: false,
        })

        comments.unshift(comment)

        setCommentsView([...comments])

        dispatch(type === CollectionNameEnum.EXPENSE ? updateExpenseComment(object.id, comment, OperationTypeEnum.INSERT) : updateVendorComment(object.id, { comments }))
    }

    const handleUpdate = (index: number, newComment: string) => {
        if (isEmptyString(newComment)) {
            NotificationService.send(NotificationTypeEnum.ERROR, t("error:comment.update.title"), t("error:comment.update.message"))
            return
        }

        comments[index] = new CommentItem({
            ...comments[index],
            message: newComment,
            isEdited: true,
        })

        setCommentsView([...comments])

        dispatch(type === CollectionNameEnum.EXPENSE ? updateExpenseComment(object.id, comments[index], OperationTypeEnum.UPDATE) : updateVendorComment(object.id, { comments }))
    }

    const handleDelete = (index: number) => {
        const comment = comments[index]
        comments.splice(index, 1)

        setCommentsView([...comments])

        dispatch(type === CollectionNameEnum.EXPENSE ? updateExpenseComment(object.id, comment, OperationTypeEnum.DELETE) : updateVendorComment(object.id, { comments }))
    }

    return (
        <div className="h-full min-h-300">
            <Comment
                avatar={<UserImage user={loggedInUser} size={30} />}
                content={
                    <CommentEditor
                        decoratorProps={decoratorProps}
                        mentions={mapToMentionData(employees)}
                        editorState={editorState}
                        setEditorState={setEditorState}
                        onSubmit={handleSubmit}
                    />
                }
            />

            {commentsView.length > 0 && (
                <Suspense fallback={<></>}>
                    <CommentList comments={commentsView} mentions={mapToMentionData(employees)} onUpdate={handleUpdate} onDelete={handleDelete} />
                </Suspense>
            )}
        </div>
    )
}

export default CommentContainer
