import Icon from "@ant-design/icons"
import React from "react"

const rocketSvg = () => (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M16.5 0.333333C16.5 0.149089 16.3509 0 16.1667 0C11.5923 0 8.57306 2.76025 5.921 6.00224C2.4059 6.04321 1.23911 7.3953 0.51727 9.5612C0.48309 9.66276 0.500343 9.77474 0.562843 9.86165C0.625668 9.94857 0.726254 10 0.833351 10H3.362L3.69533 10.3333L2.59767 11.431C2.46747 11.5612 2.46747 11.7721 2.59767 11.9023L2.81158 12.1163C1.60313 12.4797 0.500017 13.7621 0.500017 15.6667C0.500017 15.8509 0.649106 16 0.833351 16C2.73793 16 4.02032 14.8969 4.38377 13.6884L4.59767 13.9023C4.66278 13.9674 4.74806 14 4.83335 14C4.91864 14 5.00392 13.9674 5.06903 13.9023L6.16668 12.8047L6.49839 13.1364V15.6667C6.49839 15.7738 6.54982 15.8743 6.63674 15.9372C6.69435 15.9785 6.76271 16 6.83172 16C6.8672 16 6.90269 15.9945 6.93719 15.9827C9.1028 15.261 10.4548 14.0944 10.4961 10.5804C13.7389 7.92802 16.5 4.90849 16.5 0.333333ZM2.83335 14C2.64911 14 2.50002 13.8509 2.50002 13.6667C2.50002 13.0904 2.72906 12.6397 3.04779 12.3525L4.14756 13.4522C3.86033 13.771 3.4096 14 2.83335 14ZM9.83335 5C9.83335 4.08105 10.5811 3.33333 11.5 3.33333C12.419 3.33333 13.1667 4.08105 13.1667 5C13.1667 5.91895 12.419 6.66667 11.5 6.66667C10.5811 6.66667 9.83335 5.91895 9.83335 5Z"
            fill="white"
        />
    </svg>
)

const RocketIcon = (props: any) => <Icon component={rocketSvg} {...props} />

export default RocketIcon
