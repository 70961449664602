import { TripFolder } from "@finway-group/shared/lib/models"
import { Button, Modal } from "antd"
import React from "react"
import { Check as CheckIcon, Trash } from "react-feather"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"

import ArrowThinUpIcon from "Components/icons/arrowThinUp"
import { useExpenseDetailsContext } from "Shared/context/expenseDetails.context"
import { handleTripFolderSubmit } from "Shared/hooks/expense.hooks"
import { NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { deleteExpense } from "Shared/store/actions/expense/expenseActions"
import { isFolderExpense } from "Shared/utils/expense.utils"
import { getFolderActionStrings } from "Shared/utils/folder.utils"

export const DraftSubmissionButtonView = () => {
    const { activeExpense, setIsLoading, subExpenses, isLoading } = useExpenseDetailsContext()
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const history = useHistory()

    if (!isFolderExpense(activeExpense)) return <></>

    const submitDraftFolder = (expense: TripFolder) => {
        setIsLoading(true)
        handleTripFolderSubmit(expense).finally(() => setIsLoading(false))
    }

    const handleDeleteFolder = () => {
        Modal.confirm({
            title: t("confirm:request.delete_folder.title"),
            content: t("confirm:request.delete_folder.message"),
            cancelText: t("confirm:request.delete_folder.cancel"),
            type: "warning",
            okText: t("confirm:request.delete_folder.confirm"),
            onOk: async () => {
                try {
                    await deleteExpense(activeExpense.id)(dispatch)
                    history.goBack()
                    NotificationService.send(
                        NotificationTypeEnum.WARNING,
                        t(`notification:trip_folder.deleted.title`),
                        t(`notification:trip_folder.deleted.message`, { expenseNumber: activeExpense.expenseNumber }),
                    )
                } catch (err) {
                    NotificationService.send(NotificationTypeEnum.ERROR, t("error:error"), err)
                }
            },
        })
    }

    const folderString = getFolderActionStrings(activeExpense, t)

    return (
        <>
            <h2 className="mb-0 pl-0 -mt-3">{folderString.title}</h2>
            <p className="mb-5">{folderString.description}</p>
            <div className="btn-wrapper md:flex-col lg:flex-row fixed left-0 md:left-40 right-0 bottom-0 py-16 px-10 bg-white shadow sm:relative sm:left-auto sm:right-auto sm:bottom-auto sm:p-0 sm:pt-12 sm:pb-2 sm:bg-transparent sm:shadow-none z-20 sm:z-0">
                <Button
                    className="sm:py-5 sm:px-10 flex-1 btn-default mx-2"
                    onClick={() => {
                        handleDeleteFolder()
                    }}
                >
                    <Trash />
                    <span>{t("action:delete")}</span>
                </Button>
                <Button
                    className="ant-btn-primary text-center  md:py-5 md:px-10 flex-1 btn-default"
                    onClick={() => {
                        submitDraftFolder(activeExpense)
                    }}
                    loading={isLoading}
                    disabled={subExpenses.length === 0}
                >
                    <ArrowThinUpIcon />
                    <span>{t("action:submit")}</span>
                </Button>
            </div>
        </>
    )
}
