import { ONE_HUNDRED_MILLION } from "@finway-group/shared/lib/consts/common"
import { CurrencyEnum, CurrencyExchange } from "@finway-group/shared/lib/models"
import axios from "axios"
import moment from "moment-timezone"

import store from "Shared/store"
import { getExchangeConversionFactor } from "Shared/utils/helper.utils"

const MODEL = "currency"

const CurrencyService = {
    fetchCurrencyExchange: async (currencyExchangeId?: string, date?: moment.Moment) => {
        const query: Array<string> = []
        if (currencyExchangeId) query.push(`id=${currencyExchangeId}`)
        if (date) query.push(`date=${date.format("YYYY-MM-DD")}`)

        const { data } = await axios.get(`/${MODEL}/exchange?${query.join("&")}`)
        return data as CurrencyExchange
    },
    convert: (value: number, sourceCurrency: CurrencyEnum, destCurrency: CurrencyEnum, precision: number = 2, currencyExchange?: CurrencyExchange) => {
        let result = value

        if (sourceCurrency === destCurrency) {
            return value
        }

        // convert to base currency if we need to
        if (sourceCurrency !== CurrencyEnum.USD) {
            let sourceFactor: undefined | number
            if (currencyExchange) {
                sourceFactor = getExchangeConversionFactor(currencyExchange, sourceCurrency)
            }
            if (!sourceFactor) {
                sourceFactor = CurrencyService.getConversionFactor(sourceCurrency)
            }
            result /= sourceFactor
        }
        // convert to dest currency
        if (destCurrency !== CurrencyEnum.USD) {
            let destFactor: undefined | number
            if (currencyExchange) {
                destFactor = getExchangeConversionFactor(currencyExchange, destCurrency)
            }
            if (!destFactor) {
                destFactor = CurrencyService.getConversionFactor(destCurrency)
            }
            result *= destFactor
        }

        return Number(result.toFixed(precision))
    },
    getConversionFactor: (currency: CurrencyEnum): number => {
        const exchange = store.getState().currency.item as CurrencyExchange
        const rate = getExchangeConversionFactor(exchange, currency)
        if (!rate) {
            throw new Error(`Cannot get conversion factor for currency ${currency}`)
        }

        return rate
    },
    getMaxInputValueByCurrency: (currency: CurrencyEnum) => {
        try {
            return Math.floor(CurrencyService.convert(ONE_HUNDRED_MILLION, CurrencyEnum.EUR, currency))
        } catch {
            return ONE_HUNDRED_MILLION
        }
    },
}

export default CurrencyService
