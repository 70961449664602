import { EyeOutlined, PhoneOutlined } from "@ant-design/icons"
import { Card, CardModeEnum, CardStatusEnum, CardTypeEnum, RightEnum } from "@finway-group/shared/lib/models"
import { Button, Dropdown, Menu } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"

import { AddCardIcon, BlockCardIcon, PauseCardIcon, RemoveCardIcon, ReportIcon, ResumeCardIcon } from "Components/icons"
import { useLoggedInEmployeeProfile } from "Shared/hooks/user.hooks"
import { AuthzService } from "Shared/services"

interface CardActionMenuInterface {
    card: Card
    isLoading: boolean
    canManageCard: boolean
    onCardMenuAction: (action: string) => void
}
const CardActionMenu: React.FC<CardActionMenuInterface> = ({ isLoading, card, canManageCard, onCardMenuAction }) => {
    const { t } = useTranslation()
    const loggedInUser = useLoggedInEmployeeProfile()
    const cardDisabled = [CardStatusEnum.TERMINATED, CardStatusEnum.REPORTED_LOST, CardStatusEnum.REPORTED_STOLEN].includes(card?.status)
    const amountToTopUp = card?.limit - card?.balance
    const isOwner = loggedInUser.id === card?.user
    const isPhysicalCard = card?.cardType === CardTypeEnum.PHYSICAL
    const isCardNotSuspended = card?.status !== CardStatusEnum.SUSPENDED

    const isActionAvailable = canManageCard || isOwner

    const menu = (
        <Menu onClick={({ key }) => onCardMenuAction(key.toString())}>
            {card.status === CardStatusEnum.UNACTIVATED && canManageCard ? (
                <Menu.Item key="close" icon={<BlockCardIcon />} className="flex items-center">
                    {t("creditCard:action.close")}
                </Menu.Item>
            ) : (
                <>
                    {canManageCard && (
                        <>
                            {card?.mode === CardModeEnum.PREPAID_MODE && (
                                <>
                                    <Menu.Item disabled={amountToTopUp <= 0} key="top_up" icon={<AddCardIcon />} className="flex items-center">
                                        {t("creditCard:action.top_up_card")}
                                    </Menu.Item>
                                    <Menu.Item disabled={card?.balance === 0} key="empty" icon={<RemoveCardIcon />} className="flex items-center">
                                        {t("creditCard:action.empty_card")}
                                    </Menu.Item>
                                </>
                            )}

                            {card?.status === CardStatusEnum.SUSPENDED ? (
                                <Menu.Item key="resume" icon={<ResumeCardIcon />} className="flex items-center">
                                    {t("creditCard:action.resume")}
                                </Menu.Item>
                            ) : (
                                <Menu.Item key="suspend" icon={<PauseCardIcon />} className="flex items-center">
                                    {t("creditCard:action.suspend")}
                                </Menu.Item>
                            )}
                            <Menu.Item key="close" icon={<BlockCardIcon />} className="flex items-center">
                                {t("creditCard:action.close")}
                            </Menu.Item>
                        </>
                    )}
                    {isPhysicalCard && isCardNotSuspended && (
                        <Menu.Item key="show_pin" icon={<EyeOutlined />} className="flex items-center">
                            {t("creditCard:action.show_pin")}
                        </Menu.Item>
                    )}
                    {card.status === CardStatusEnum.ACTIVE && (
                        <>
                            {isPhysicalCard && (
                                <>
                                    <Menu.Item key="report_stolen" icon={<ReportIcon />} className="flex items-center">
                                        {t("action:cards.report_stolen")}
                                    </Menu.Item>
                                    <Menu.Item key="report_lost" icon={<ReportIcon />} className="flex items-center">
                                        {t("action:cards.report_lost")}
                                    </Menu.Item>
                                    {card?.pinBlocked && (
                                        <Menu.Item key="unblock_pin" icon={<ResumeCardIcon />} className="flex items-center">
                                            {t("action:cards.unblock_pin")}
                                        </Menu.Item>
                                    )}
                                </>
                            )}
                            <Menu.Item key="update_phone_number" icon={<PhoneOutlined />} className="flex items-center">
                                {t("action:cards.update_phone_number")}
                            </Menu.Item>
                            {!AuthzService.isRightGrantedForLoggedInUser(RightEnum.CARD__ALL__WRITE) && isOwner && (
                                <Menu.Item key="request_limit_change" icon={<AddCardIcon />} className="flex items-center">
                                    {t("action:cards.request_limit_change")}
                                </Menu.Item>
                            )}
                        </>
                    )}
                </>
            )}
        </Menu>
    )

    return (
        <>
            {isActionAvailable && (
                <Dropdown disabled={cardDisabled} overlay={menu}>
                    <Button loading={isLoading}>
                        <span>{t("action:credit_card.options")} </span>
                        <i className="el-icon-caret-bottom el-icon--right" />
                    </Button>
                </Dropdown>
            )}
        </>
    )
}

export default CardActionMenu
