import { BankAccount, BankConnection } from "@finway-group/shared/lib/models"
import { Button, Form, Modal } from "antd"
import { Store } from "antd/lib/form/interface"
import * as HttpStatus from "http-status"
import moment from "moment"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"

import BankMatchingForm from "Components/forms/matching/bankMatching.form"
import { useBankConnections } from "Shared/hooks/bank.hooks"
import { NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import TransactionService from "Shared/services/transaction.service"
import { flatten } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

interface BankMatchingModalInterface {
    isShowing: boolean
    onCancel: () => void
}

const BankMatchingModal: React.FC<BankMatchingModalInterface> = ({ isShowing = false, onCancel }) => {
    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useStateIfMounted(false)
    const [formInstance] = Form.useForm()

    const bankAccounts = flatten(
        useBankConnections({ shouldFilterDeleted: true }).map((con: BankConnection) =>
            con.accounts.map((account: BankAccount) => ({ ...account, bank: con.bankName, isDefault: con.isDefault && account.isDefault })),
        ),
    )

    useEffect(() => {
        if (isShowing) {
            const initialState = {
                bankAccount: bankAccounts.find((account: any) => account.isDefault)?.accountId,
                startDate: moment().subtract(1, "M"),
                endDate: moment(),
            }
            formInstance.setFieldsValue(initialState)
        }

        return () => {
            if (isShowing) formInstance.resetFields()
        }
    }, [isShowing])

    const onSubmitForm = ({ startDate, endDate, bankAccount }: Store) => {
        setIsLoading(true)
        TransactionService.determineBankMatching(bankAccount, startDate.toDate(), endDate.toDate())
            .then(() => {
                NotificationService.send(NotificationTypeEnum.INFO, t("notification:matching.bank.title"), t("notification:matching.bank.message"), 0)
                handleHide()
            })
            .catch((err) => {
                setIsLoading(false)
                const status = err.response?.status
                switch (status) {
                    case HttpStatus.UNAVAILABLE_FOR_LEGAL_REASONS:
                        NotificationService.send(NotificationTypeEnum.INFO, t("notification:matching.need_consent.title"), t("notification:matching.need_consent.message"), 0)
                        break
                    case HttpStatus.NOT_FOUND:
                        NotificationService.send(
                            NotificationTypeEnum.ERROR,
                            t("error:matching.determine.no_transactions_found.title"),
                            t("error:matching.determine.no_transactions_found.message"),
                        )
                        break
                    case HttpStatus.INTERNAL_SERVER_ERROR:
                        NotificationService.send(NotificationTypeEnum.ERROR, t("error:matching.determine.title"), t("error:matching.determine.message"))
                        break
                    default:
                        NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:matching.determine.title"))
                }
            })
    }

    const handleHide = () => {
        setIsLoading(false)
        formInstance.resetFields()
        onCancel()
    }

    return (
        <Modal
            destroyOnClose={true}
            maskClosable={false}
            visible={isShowing}
            title={t("action:matching.bank.title")}
            onCancel={handleHide}
            closable={true}
            keyboard={true}
            className="ant-modal--large"
            footer={[
                <Button key="back" onClick={handleHide}>
                    {t("action:cancel")}
                </Button>,
                <Button key="submit" type="primary" loading={isLoading} onClick={() => formInstance.submit()}>
                    {t("action:matching.bank.submit")}
                </Button>,
            ]}
        >
            <BankMatchingForm formInstance={formInstance} onSubmit={onSubmitForm} bankAccounts={bankAccounts} />
        </Modal>
    )
}

export default BankMatchingModal
