import { Form, Modal } from "antd"
import { Store } from "antd/lib/form/interface"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import MileageDistanceBufferForm from "Components/forms/mileageDistanceBuffer.form"
import { useCompany } from "Shared/hooks/company.hooks"
import { NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { updateCompanyFeatureSettings } from "Shared/store/actions/company/companyActions"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

interface MileageDistanceBufferModalProps {
    isShowing: boolean
    onCancel: () => void
}

const MileageDistanceBufferModal = ({ isShowing, onCancel }: MileageDistanceBufferModalProps) => {
    const { t } = useTranslation()
    const company = useCompany()
    const dispatch = useDispatch()

    const [formInstance] = Form.useForm()

    const [isLoading, setIsLoading] = useStateIfMounted(false)

    const onSubmit = async (values: Store) => {
        setIsLoading(true)
        try {
            company.shouldRefetch = undefined
            await dispatch(updateCompanyFeatureSettings(company._id, { mileageDistanceBuffer: Number(values.mileageDistanceBuffer) }))
            setIsLoading(false)
            NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:settings.company.updated.title"), t("notification:settings.company.updated.message"))
            onHide()
        } catch (err) {
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:settings.edit.title"))
        } finally {
            setIsLoading(false)
        }
    }

    const onHide = () => {
        setIsLoading(false)
        formInstance.resetFields()
        onCancel()
    }

    useEffect(() => {
        formInstance.setFieldsValue({ mileageDistanceBuffer: company.mileageDistanceBuffer })
    })

    return (
        <Modal
            title={t("action:company_settings.mileage_distance_buffer")}
            visible={isShowing}
            maskClosable={false}
            onOk={formInstance.submit}
            onCancel={onHide}
            okText={t("action:company_settings.save")}
            cancelText={t("action:cancel")}
            confirmLoading={isLoading}
            keyboard
            destroyOnClose
        >
            <MileageDistanceBufferForm formInstance={formInstance} onSubmit={onSubmit} />
        </Modal>
    )
}
export default React.memo(MileageDistanceBufferModal)
