import { Card } from "antd"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import ProgressBar from "Components/ProgressBar"
import { useCostCenterBudgetData } from "Shared/hooks/analytics.hooks"
import { useSubCostCenterIds } from "Shared/hooks/costCenter.hooks"
import { CostCenterService, UserService } from "Shared/services"
import { fetchCostCenterBudgetData } from "Shared/store/actions/analytics/analyticsActions"

const CostCenterBudgetChart = () => {
    const { t } = useTranslation()
    const globalCurrency = UserService.getLoggedInEmployeeProfile()?.settings.globalCurrency
    const costCenterIds = useSubCostCenterIds(true, true)
    const costCenterBudgetData = useCostCenterBudgetData().filter((costCenterBudgetData) => costCenterIds.includes(costCenterBudgetData.id))
    const dispatch = useDispatch()
    const fetchCostCenterNameById = (id: string) => CostCenterService.getCostCenterById(id)?.name

    useEffect(() => {
        dispatch(fetchCostCenterBudgetData())
    }, [])

    return (
        <>
            <div className="flex justify-between items-center">
                <h2 className="pb-8">{t("label:budgets")}</h2>
            </div>
            <Card>
                {costCenterBudgetData?.length > 0 ? (
                    <React.Fragment>
                        {costCenterBudgetData?.map((dataSet: any, i: number) => (
                            <div key={i} className="flex flex-col xs:flex-row items-start xs:items-center my-16">
                                <div className="text-left w-full xs:w-1/5 leading-tight overflow-x-auto whitespace-no-wrap no-scrollbar">{fetchCostCenterNameById(dataSet.id)}</div>

                                <div className="w-full xs:w-4/5 pl-0 xs:ml-20 mt-5 xs:mt-0">
                                    {dataSet.capacity > 0 ? (
                                        <ProgressBar
                                            total={dataSet.capacity || 0}
                                            value={dataSet.consumption || 0}
                                            currency={globalCurrency}
                                            extended={true}
                                            dontShowAsExceeding={dataSet.capacity === 0}
                                        />
                                    ) : (
                                        <span>{t("label:no_budget_set")}</span>
                                    )}
                                </div>
                            </div>
                        ))}
                    </React.Fragment>
                ) : (
                    <div className="flex justify-center items-center h-100">
                        <p>{t("missing:expense_data")}</p>
                    </div>
                )}
            </Card>
        </>
    )
}

export default CostCenterBudgetChart
