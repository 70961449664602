import type { ContentState } from "draft-js"
import { convertToRaw } from "draft-js"

export const getEditorContent = (content: ContentState) => {
    const raw = convertToRaw(content)

    // important To avoid html parsing avatar link
    const rawObject = JSON.parse(JSON.stringify(raw))
    Object.keys(rawObject.entityMap).forEach((key) => delete rawObject.entityMap[key].data?.mention?.avatar)

    return JSON.stringify(rawObject)
}
