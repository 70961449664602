import { InfoCircleOutlined } from "@ant-design/icons"
import { Employee } from "@finway-group/shared/lib/models"
import { Button, Modal, Select } from "antd"
import moment from "moment-timezone"
import React from "react"
import { useTranslation } from "react-i18next"

import UserImage from "Components/UserImage"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

interface ReplacementForAbsentApproverModalInterface {
    id?: string
    isPurchaseRequest: boolean
    isVisible: boolean
    onSubmit: () => void
    closeModal: () => void
    availableReplacementsForAbsentApprover: Array<Employee>
    absentApprovers: Array<Employee>
    setCheckIfApproverInAbsence: (state: boolean) => void
    setReplacementForAbsentApprover: (value: any) => void
}

const ReplacementForAbsentApproverModal: React.FC<ReplacementForAbsentApproverModalInterface> = ({
    isPurchaseRequest,
    isVisible,
    closeModal,
    onSubmit,
    availableReplacementsForAbsentApprover,
    absentApprovers,
    setCheckIfApproverInAbsence,
    setReplacementForAbsentApprover,
}) => {
    const { t } = useTranslation()
    const [isRequestUrgent, setIsRequestUrgent] = useStateIfMounted(false)
    // We only inform the user about the employee that is the longest in absence of leave (in workflows there can be more than one approver that is absent)
    const { firstName = "", lastName = "", absence = undefined } = absentApprovers.sort((a: Employee, b: Employee) => b.inAbsenceOfLeaveFor() - a.inAbsenceOfLeaveFor())?.[0] ?? []

    const getFooterButtons = () => [
        <Button
            key="cancel"
            onClick={() => {
                setReplacementForAbsentApprover("")
                closeModal()
            }}
        >
            {t("action:cancel")}
        </Button>,
        isRequestUrgent && availableReplacementsForAbsentApprover.length > 0 ? (
            <Button
                key="submit"
                type="primary"
                onClick={() => {
                    closeModal()
                    onSubmit()
                }}
            >
                {t("action:request.submit")}
            </Button>
        ) : (
            <Button
                key="can-wait"
                type="primary"
                onClick={() => {
                    setCheckIfApproverInAbsence(false)
                    closeModal()
                    onSubmit()
                }}
            >
                {t("action:can_wait")}
            </Button>
        ),
    ]

    return (
        <Modal
            destroyOnClose={true}
            visible={isVisible}
            maskClosable={false}
            onCancel={() => {
                setReplacementForAbsentApprover("")
                closeModal()
            }}
            closable={true}
            keyboard={true}
            className="ant-modal--small"
            footer={getFooterButtons()}
        >
            <div className="flex flex-row">
                <InfoCircleOutlined className="mt-6 mr-5" style={{ color: "#FFCA5A" }} />
                <h2>{t("label:employee_absence.title")}</h2>
            </div>
            <p className="mb-20 text-text">
                {t("label:employee_absence.description", { name: `${firstName} ${lastName}`, date: moment(absence?.endDate).format("DD/MM/YYYY") })}
                {isPurchaseRequest && t("label:employee_absence.purchase_request_hint")}.
            </p>
            {isRequestUrgent ? (
                <Select showArrow style={{ width: 300 }} onChange={(value) => setReplacementForAbsentApprover(value)}>
                    {availableReplacementsForAbsentApprover.map((employee, index) => {
                        const { id, firstName, lastName } = employee
                        return (
                            <Select.Option key={index} label={`${firstName} ${lastName}`} value={id}>
                                <div className="flex items-center" id={`replacementForAbsentApprover-option-${index}`}>
                                    <UserImage user={employee} />
                                    <span>{`${firstName} ${lastName}`}</span>
                                </div>
                            </Select.Option>
                        )
                    })}
                </Select>
            ) : (
                <Button className="text-text px-16" onClick={() => setIsRequestUrgent(true)}>
                    {t("action:employee_absence.select_replacement_approver")}
                </Button>
            )}
        </Modal>
    )
}

export default ReplacementForAbsentApproverModal
