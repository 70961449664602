import { currencyEnumArray } from "@finway-group/shared/lib/models"
import Big from "big.js"

export const formatCurrency = (value: number, localeConfig: any, currencyName: string, hideSymbol: boolean = false) => {
    const numberConfig = localeConfig.formats.number[currencyName]
    const formatter = new global.Intl.NumberFormat(localeConfig.locale, hideSymbol ? { ...numberConfig, currency: undefined, style: undefined } : numberConfig)

    return formatter.format(Number(Big(value)))
}

export const parseCurrencyInput = (value: any): number => {
    if (value !== undefined && isNaN(value)) {
        const response = value.toString().match(/\d|-/g)
        if (response) {
            return Number(response.join("")) / 100
        }
    }

    if (value && value.length === 1) return Number(value) / 100

    return value
}

interface CurrencyConfigInterface {
    [currency: string]: {
        style: string
        currency: string
        minimumFractionDigits: number
        maximumFractionDigits: number
    }
}

const currenciesConfig: CurrencyConfigInterface = {}
currencyEnumArray.forEach((currency: string) => {
    currenciesConfig[`${currency}`] = {
        style: "currency",
        currency: `${currency}`,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }
})

export interface ConfigInterface {
    locale: string
    formats: {
        number: CurrencyConfigInterface
    }
}

export const defaultConfig: ConfigInterface = {
    locale: "en-US",
    formats: {
        number: currenciesConfig,
    },
}

export enum CurrencySymbolPosition {
    OUTSIDE_INPUT,
    INSIDE_INPUT,
    HIDDEN,
}
