import { DocumentChangeInterface, OperationTypeEnum } from "@finway-group/shared/lib/models"
import * as Sentry from "@sentry/react"

import { invalidateNotifications } from "Shared/queries/notification.queries"
import { AuthnService } from "Shared/services"
import { refreshAuthUser } from "Shared/store/actions/auth/authActions"
import { fetchAllCompanies } from "Shared/store/actions/company/companyActions"
import { fetchAllEmployees, syncEmployee } from "Shared/store/actions/employee/employeeActions"
import { refetchUserRelatedTables } from "Shared/store/actions/tables/tableActions"
import { isClientErrorCode } from "Shared/utils/errorHandler.utils"

const updatedDependencies = async (dispatch: any) => {
    await AuthnService.silentRefresh()
    await dispatch(refreshAuthUser())
    invalidateNotifications()
}

const subscribeToEmployee = async (dispatch: any, data: DocumentChangeInterface, loggedInUserId: string) => {
    const {
        fullDocument: { documentId, operationType },
    } = data

    try {
        if (operationType === OperationTypeEnum.INSERT || operationType === OperationTypeEnum.UPDATE || operationType === OperationTypeEnum.UPDATE_PROFILE_IMG) {
            if (operationType === OperationTypeEnum.UPDATE && documentId === loggedInUserId) {
                await updatedDependencies(dispatch)
            }

            await dispatch(syncEmployee(documentId.toString()))
            dispatch(refetchUserRelatedTables())
            await dispatch(fetchAllCompanies(false))
        }

        if (operationType === OperationTypeEnum.DELETE) {
            await updatedDependencies(dispatch)
            await dispatch(syncEmployee(documentId.toString()))
            dispatch(refetchUserRelatedTables())
            await dispatch(fetchAllCompanies(false))
        }

        if (operationType === OperationTypeEnum.BULK_UPDATE) {
            await dispatch(fetchAllEmployees())
            dispatch(refetchUserRelatedTables())
        }
    } catch (err) {
        if (isClientErrorCode(err)) return
        Sentry.captureException(`[Employee Socket] Error: ${typeof err === "string" ? err : JSON.stringify(err)}`)
    }
}

export default subscribeToEmployee
