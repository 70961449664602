import { ApproverTypeEnum, ComparisonOperatorEnum, CurrencyEnum, LogicalOperatorEnum, WorkflowConditionEnum } from "@finway-group/shared/lib/models"

export enum WorkflowFormRequestTypeEnum {
    ALL = "ALL",
    PURCHASE = "PURCHASE",
    INVOICE = "INVOICE",
    ALL_REIMBURSEMENTS = "ALL_REIMBURSEMENTS",
    STANDARD_REIMBURSEMENT = "STANDARD_REIMBURSEMENT",
    MILEAGE_REIMBURSEMENT = "MILEAGE_REIMBURSEMENT",
    PER_DIEM_REIMBURSEMENT = "PER_DIEM_REIMBURSEMENT",
    HOSPITALITY_REIMBURSEMENT = "HOSPITALITY_REIMBURSEMENT",
    TRIP_FOLDER = "TRIP_FOLDER",
}

export enum WorkflowFormTypeEnum {
    REQUEST = "REQUEST",
    FOLDER = "TRIP_FOLDER",
}

export interface WorkflowFormTriggerType {
    condition: WorkflowConditionEnum
    referenceIds?: Array<string>
    amount?: string | number
    currency?: CurrencyEnum
    comparison?: ComparisonOperatorEnum
}

type SpecialApprovers = ApproverTypeEnum.RESPONSIBLE_USER | ApproverTypeEnum.SUPERIOR | ApproverTypeEnum.AUTO_APPROVER | ApproverTypeEnum.REQUESTER

export interface WorkflowFormValuesInterface {
    name: string
    requestTypes: Array<WorkflowFormRequestTypeEnum>
    triggerTypes: Array<WorkflowFormTriggerType>
    steps: Array<{
        /**
         * Approver string id or approver type
         */
        approver: string | SpecialApprovers // id strings or special approver enum
        otherApprovers: Array<string | SpecialApprovers>
        operator?: LogicalOperatorEnum
        condition?: WorkflowConditionEnum
        comparison?: ComparisonOperatorEnum
        currency?: CurrencyEnum
        amount?: string | number
        [key: string]: any
    }>
    order?: number
    recalculateApprovalProcessesInProgress?: boolean
    workflowType: WorkflowFormTypeEnum
}
