import { DocumentTypeEnum, FileExtEnum, InvoiceInterface, InvoicePdfEnum, OcrMetadataInterface } from "@finway-group/shared/lib/models"
import { removeQueryString } from "@finway-group/shared/lib/utils"
import { Avatar, Radio, Tabs } from "antd"
import { RadioChangeEvent } from "antd/lib/radio"
import React, { Suspense, useEffect } from "react"
import { useTranslation } from "react-i18next"

import Loading from "Components/loading"
import { isFileExtensionMatching } from "Shared/utils/file.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

import UploadFileDraggerContent from "./upload/UploadFileDraggerContent"
import UploadFilesDragger, { UploadFilesDraggerInterface } from "./upload/UploadFilesDragger"

const FileViewerCached = React.lazy(() => import("Components/fileViewer/fileViewerCached"))

interface DocumentManagerTabInterface {
    invoices?: Array<InvoiceInterface>
    fileObject?: {
        activeKey: number
        fileData: Uint8Array
        legendContent: Array<OcrMetadataInterface>
    }
    deliveryNote?: string
    doFileUpload: UploadFilesDraggerInterface["doFileUpload"]
    onRemoveTab: (documentType: DocumentTypeEnum, targetKey: string | React.MouseEvent | React.KeyboardEvent) => void
    documentType: DocumentTypeEnum
    label: {
        buttonLabel: string
        draggerDescription: string
    }
    onChangeInvoicesActiveKey?: (activeInvoiceTabKey: number) => void
    onInboxSelected?: (event: any) => void
    isUploading?: boolean
    readOnly?: boolean
    showPaidStamp?: boolean
}

interface InvoiceTabPagesInterface {
    newDoc: boolean
    title: string
    filePath: string
    fileData?: Uint8Array
    legendContent?: Array<OcrMetadataInterface>
}

const DocumentTabManager: React.FC<DocumentManagerTabInterface> = ({
    invoices,
    deliveryNote,
    doFileUpload,
    onRemoveTab,
    documentType,
    label,
    onChangeInvoicesActiveKey,
    fileObject,
    onInboxSelected,
    isUploading,
    readOnly = false,
    showPaidStamp = false,
}) => {
    const { t } = useTranslation()
    const [invoiceTabPages, setInvoiceTabPages] = useStateIfMounted<any>([])
    const [activeInvoiceTabKey, setActiveInvoiceTabKey] = useStateIfMounted(0)
    const [showOriginalInvoice, setShowOriginalInvoice] = useStateIfMounted(false)
    const switchTypeCondition =
        documentType === DocumentTypeEnum.INVOICE || documentType === DocumentTypeEnum.OFFER ? invoices?.length === 0 : !deliveryNote || deliveryNote === undefined

    const getTabTitle = (invoiceTabPage: InvoiceTabPagesInterface, index: number) => {
        const clientIndex = index + 1

        if (!invoiceTabPage.newDoc && !invoiceTabPage.title) {
            switch (documentType) {
                case DocumentTypeEnum.INVOICE:
                    return `${t("label:expense_document.invoice")} ${clientIndex}`
                case DocumentTypeEnum.OFFER:
                    return `${t("label:expense_document.offer")} ${clientIndex}`
                default:
                    return invoiceTabPage.title
            }
        } else {
            return invoiceTabPage.title
        }
    }

    useEffect(() => {
        const invoiceTabPagesTmp = invoiceTabPages.filter((i: InvoiceTabPagesInterface) => invoices?.find((invoice: InvoiceInterface) => i.filePath === invoice?.url))

        invoices?.forEach((invoice: any) => {
            const docUrl = invoice?.url ?? invoice
            const title = invoice?.fileName ?? ""
            if (!invoiceTabPagesTmp.some((e: InvoiceTabPagesInterface) => e.filePath === docUrl)) {
                invoiceTabPagesTmp.push({ newDoc: false, title, filePath: docUrl })
            }
        })

        setInvoiceTabPages(invoiceTabPagesTmp)
    }, [invoices])

    useEffect(() => {
        if (fileObject && invoiceTabPages[fileObject.activeKey]) {
            const invoiceTabPagesTmp: Array<InvoiceTabPagesInterface> = invoiceTabPages
            invoiceTabPagesTmp[fileObject.activeKey].fileData = fileObject.fileData
            invoiceTabPagesTmp[fileObject.activeKey].legendContent = fileObject.legendContent
            setInvoiceTabPages(invoiceTabPagesTmp)
        }
    }, [fileObject])

    useEffect(() => {
        setActiveInvoiceTabKey(Math.max(invoiceTabPages.length - 1, 0))
    }, [invoiceTabPages])

    useEffect(() => {
        onChangeInvoicesActiveKey && onChangeInvoicesActiveKey(activeInvoiceTabKey)
    }, [activeInvoiceTabKey])

    const onChange = (activeInvoiceTabKey: string) => {
        setActiveInvoiceTabKey(parseInt(activeInvoiceTabKey))
    }

    const isInvoiceOCRProcessed = invoiceTabPages.length > 0 && invoiceTabPages[activeInvoiceTabKey] && invoiceTabPages[activeInvoiceTabKey].fileData

    const onActionTab = (documentType: DocumentTypeEnum, targetKey: string | React.MouseEvent | React.KeyboardEvent, action: "remove" | "add") => {
        if (action == "remove") {
            const index = Number(targetKey)
            if (invoiceTabPages[index]?.newDoc) {
                setInvoiceTabPages(invoiceTabPages.slice(0, index))
            } else {
                onRemoveTab(documentType, targetKey)
            }
        } else if (action == "add") {
            setInvoiceTabPages([...invoiceTabPages, { newDoc: true, filePath: "", title: t("label:expense_document.new_document") }])
        }
    }
    const onRadioGroupChange = (e: RadioChangeEvent) => {
        // original radio has enum value 1
        setShowOriginalInvoice(e.target.value)
    }

    const ocrRadioGroup = (
        <div className="mr-10 text-sm ">
            <Radio.Group defaultValue={InvoicePdfEnum.EDITED} optionType="button" buttonStyle="solid" onChange={onRadioGroupChange}>
                <Radio.Button value={InvoicePdfEnum.EDITED}>{t("label:invoice_pdf_edited")}</Radio.Button>
                <Radio.Button value={InvoicePdfEnum.ORIGINAL}>{t("label:invoice_pdf_original")}</Radio.Button>
            </Radio.Group>
        </div>
    )
    const ocrLegend = (legendContent?: Array<OcrMetadataInterface>) => (
        <div className="flex flex-wrap ">
            {legendContent?.map((ocrColorsLabel: OcrMetadataInterface, index: number) => (
                <div key={index} className="flex items-center mt-8 mr-12">
                    <Avatar style={{ border: 0, backgroundColor: ocrColorsLabel.color }} size={15} />
                    <div className="ml-3 mt-3 font-normal">{ocrColorsLabel.label}</div>
                </div>
            ))}
        </div>
    )
    return (
        <>
            {switchTypeCondition ? (
                <UploadFilesDragger doFileUpload={doFileUpload} documentType={documentType} disabled={isUploading || readOnly}>
                    <UploadFileDraggerContent label={label} onInboxSelected={onInboxSelected} isUploading={isUploading} readOnly={readOnly} />
                </UploadFilesDragger>
            ) : (
                <>
                    <Tabs
                        type={readOnly ? "card" : "editable-card"}
                        hideAdd={documentType === DocumentTypeEnum.DELIVERY_NOTE || readOnly}
                        onChange={onChange}
                        activeKey={documentType === DocumentTypeEnum.INVOICE || documentType === DocumentTypeEnum.OFFER ? `${activeInvoiceTabKey}` : "deliveryNote"}
                        onEdit={(targetKey, action) => onActionTab(documentType, targetKey, action)}
                        tabBarExtraContent={isInvoiceOCRProcessed ? ocrRadioGroup : undefined}
                    >
                        {documentType === DocumentTypeEnum.INVOICE || documentType === DocumentTypeEnum.OFFER
                            ? invoiceTabPages.map((tabPage: InvoiceTabPagesInterface, index: number) => (
                                  <Tabs.TabPane key={index} tab={getTabTitle(tabPage, index)} closable forceRender>
                                      {tabPage.newDoc ? (
                                          <UploadFilesDragger doFileUpload={doFileUpload} documentType={documentType} disabled={isUploading || readOnly}>
                                              <UploadFileDraggerContent label={label} isUploading={isUploading} onInboxSelected={onInboxSelected} readOnly={readOnly} />
                                          </UploadFilesDragger>
                                      ) : (
                                          <>
                                              <Suspense fallback={<Loading />}>
                                                  <FileViewerCached key={removeQueryString(tabPage.filePath)} filePath={tabPage.filePath} showPaidStamp={showPaidStamp} />
                                              </Suspense>
                                              {isInvoiceOCRProcessed && !showOriginalInvoice && ocrLegend(tabPage.legendContent)}
                                          </>
                                      )}
                                  </Tabs.TabPane>
                              ))
                            : deliveryNote && (
                                  <Tabs.TabPane closable tab={t("label:expense_document.delivery_note")} key="deliveryNote" forceRender>
                                      {isFileExtensionMatching(deliveryNote, FileExtEnum.PDF) && (
                                          <Suspense fallback={<Loading />}>
                                              <FileViewerCached filePath={deliveryNote} showPaidStamp={showPaidStamp} />
                                          </Suspense>
                                      )}
                                  </Tabs.TabPane>
                              )}
                    </Tabs>
                </>
            )}
        </>
    )
}

export default DocumentTabManager
