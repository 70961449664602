import { CardTypeEnum, Expense, ExpenseKindEnum, ExpenseStatusEnum } from "@finway-group/shared/lib/models"
import { Alert, Button, Card } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import CreditCard from "Components/creditCard/creditCard"
import Loading from "Components/loading"
import { useCardByExpense } from "Shared/hooks/creditCard.hooks"
import CardService from "Shared/services/card.service"
import NotificationService, { NotificationTypeEnum } from "Shared/services/notification.service"
import { syncCreditCard } from "Shared/store/actions/creditCard/creditCardActions"
import { isNotSet } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

interface ExpenseCreditCardSectionInterface {
    expense: Expense
}
const ExpenseCreditCardSection: React.FC<ExpenseCreditCardSectionInterface> = ({ expense }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [isRetrying, setIsRetrying] = useStateIfMounted<boolean>(false)
    const { card, isLoadingCard } = useCardByExpense(expense)
    const hasCardCreated = !isNotSet(expense.card)
    const hasCardError = !isNotSet(card?.errorCode)
    const defaultCardDisplay = {
        user: expense.requestedBy,
        cardType: CardTypeEnum.VIRTUAL,
        nameOnCard: `${expense.requestedBy.firstName} ${expense.requestedBy.lastName}` || "John Doe",
    }

    const onRetryCardCreation = (e: any) => {
        e.stopPropagation()
        setIsRetrying(true)
        CardService.fixCardError(card._id)
            .then((card: any) => {
                NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:cards.fix_card.title"), t("notification:cards.fix_card.message"))
                dispatch(syncCreditCard(card))
            })
            .catch((err: any) => {
                NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:error"))
            })
            .finally(() => setIsRetrying(false))
    }

    return (
        <Card className="text-center mb-20">
            <CreditCard
                isCardLoading={isLoadingCard}
                card={card || defaultCardDisplay}
                showActionBtn={hasCardCreated && !hasCardError}
                showBudget={hasCardCreated && !hasCardError}
                dimension="sm:w-400 sm:h-252 lg:w-280 lg:h-176 md:w-300 md:h-189 xl:w-400 xl:h-252"
                showPaddingInfo={expense.currency !== card?.currency}
            />
            {!hasCardCreated && expense.status === ExpenseStatusEnum.PURCHASE_PENDING ? (
                <Alert
                    type="info"
                    showIcon
                    className="mt-20"
                    message={
                        expense.kind === ExpenseKindEnum.ONE_TIME_EXPENSE
                            ? t("info:cards.one_time_card_creation_on_approval")
                            : t("info:cards.subscription_card_creation_on_approval")
                    }
                />
            ) : (
                hasCardError &&
                (isRetrying ? (
                    <Loading className="mt-4 h-60" />
                ) : (
                    <Alert
                        type="error"
                        showIcon
                        className="mt-20"
                        message={
                            <>
                                <span>{t("error:cards.something_went_wrong")}</span>
                                <Button className="button-warning-notification ml-4" type="text" onClick={onRetryCardCreation}>
                                    {t("action:cards.or_retry")}
                                </Button>
                            </>
                        }
                    />
                ))
            )}
        </Card>
    )
}

export default ExpenseCreditCardSection
