import { CurrencyEnum } from "@finway-group/shared/lib/models"
import { Form } from "antd"
import * as React from "react"

import { isWithinCurrencyLimit } from "Components/forms/rules/utils.rules"

interface FormItemCurrencyInterface extends React.ComponentProps<typeof Form.Item> {
    currency: CurrencyEnum
}

const FormItemCurrency = ({ rules = [], currency, ...rest }: FormItemCurrencyInterface) => {
    const rulesProp = [...rules, isWithinCurrencyLimit(currency)]
    return <Form.Item {...rest} rules={rulesProp} />
}
export default FormItemCurrency
