import { useSelector } from "react-redux"

import store from "Shared/store"
import { RootState } from "Shared/store/rootState.interface"
import { mapFeatureFlagsToTenantTier } from "Shared/utils/tenant.utils"

export const useIsPaidVersion = () => getFeatureFlags()?.paidVersion

export const useIsFreeVersion = () => !useIsPaidVersion()

/**
 * @deprecated in favor of useAccessTokenData()
 */
export const useFeatureFlags = () => getFeatureFlags()

export const useTenantTier = () => mapFeatureFlagsToTenantTier(getFeatureFlags())

export const useIsPersonioEnabled = () => getFeatureFlags()?.personioEnabled ?? false

export const useIsOutgoingWireTransferEnabled = () => getFeatureFlags()?.outgoingWireTransferEnabled

export const useIsCostCenterGroupsEnabled = () => getFeatureFlags()?.costCenterGroupsEnabled

export const useIsCustomCsvTemplatesEnabled = () => getFeatureFlags()?.customCsvTemplatesEnabled

export const useIsSubCompaniesEnabled = () => getFeatureFlags()?.subCompaniesEnabled

export const useIsTravelEnabled = () => getFeatureFlags()?.travelEnabled

export const useIsCustomRolesEnabled = () => getFeatureFlags()?.customRolesEnabled

export const getIsTravelEnabledFeatureFromStore = (): boolean => store.getState().featureFlags.item.travelEnabled

const getFeatureFlags = () => useSelector(({ featureFlags }: RootState) => featureFlags.item)
