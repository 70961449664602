import React from "react"

interface ExpensePropertyDisplayInterface {
    title: string
    value: string
    children?: React.ReactNode
    className?: string
}
export const ExpensePropertyDisplay = ({ title, value, children, className }: ExpensePropertyDisplayInterface) => (
    <div className={className}>
        <div>{title}</div>
        <div className="flex justify-start h-32 items-center">
            <div className="leading-tight truncate text-base font-medium">{children ?? value}</div>
        </div>
    </div>
)
