import Icon from "@ant-design/icons"
import React from "react"

const searchSvg = () => (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.50057 13.4167C10.7222 13.4167 13.3339 10.805 13.3339 7.58333C13.3339 4.36167 10.7222 1.75 7.50057 1.75C4.27891 1.75 1.66724 4.36167 1.66724 7.58333C1.66724 10.805 4.27891 13.4167 7.50057 13.4167Z"
            stroke="#606C7E"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path d="M15.6672 15.7495L11.6228 11.7051" stroke="#606C7E" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
)

const SearchIcon = (props: any) => <Icon component={searchSvg} {...props} />

export default SearchIcon
