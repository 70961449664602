import { Rule } from "antd/lib/form"
import moment from "moment"

import i18n from "Shared/locales/i18n"

export const EmployeeAbsenceRules = (): { [key: string]: Array<Rule> } => ({
    absenceDate: [
        {
            validator: (_rule: any, absenceDate: moment.Moment) => {
                if (!absenceDate) return Promise.reject(i18n.t("validation:required"))

                return Promise.resolve()
            },
        },
    ],
})
