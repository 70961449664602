import { ExpenseTag } from "@finway-group/shared/lib/models"
import { Action } from "redux"

export enum ExpenseTagActionTypes {
    FETCH_ALL_EXPENSE_TAGS = "FETCH_ALL_EXPENSE_TAGS",
    CREATE_EXPENSE_TAG = "CREATE_EXPENSE_TAG",
    DELETE_EXPENSE_TAG = "DELETE_EXPENSE_TAG",
}

export interface FetchAllExpenseTagsAction extends Action<typeof ExpenseTagActionTypes.FETCH_ALL_EXPENSE_TAGS> {
    type: typeof ExpenseTagActionTypes.FETCH_ALL_EXPENSE_TAGS
    expenseTags: Array<ExpenseTag>
}

export interface CreateExpenseTagAction extends Action<typeof ExpenseTagActionTypes.CREATE_EXPENSE_TAG> {
    type: typeof ExpenseTagActionTypes.CREATE_EXPENSE_TAG
    expenseTag: ExpenseTag
}

export interface DeleteExpenseTagAction extends Action<typeof ExpenseTagActionTypes.DELETE_EXPENSE_TAG> {
    type: typeof ExpenseTagActionTypes.DELETE_EXPENSE_TAG
    expenseTagId: string
}

export type ExpenseTagActions = FetchAllExpenseTagsAction | CreateExpenseTagAction | DeleteExpenseTagAction
