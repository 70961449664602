import { Company } from "@finway-group/shared/lib/models"
import React, { useMemo } from "react"

export default function CompanyLogo({ logo, name }: Pick<Company, "logo" | "name">) {
    const companyNameInitials: string = useMemo(() => {
        // given name `Example AG`
        const array = name ? name.trim().split(" ") : [""]

        // return first letter of first word if only one word (e.g. Example -> E)
        if (array.length === 1) return array[0][0]
        // return initials of first and last company name (e.g. Example AG -> EA)
        if (array.length > 1) {
            const first = array[0][0]
            const last = array[array.length - 1][0]
            return `${first}${last}`
        }

        return ""
    }, [name])

    return (
        <div className="w-20 h-20 flex border justify-center items-center rounded">
            {logo ? <img className="w-full h-auto" src={logo} alt="logo" /> : <span className="placeholder-primary text-xs uppercase">{companyNameInitials}</span>}
        </div>
    )
}
