import { LoadingOutlined } from "@ant-design/icons"
import ExclamationCircleOutlined from "@ant-design/icons/lib/icons/ExclamationCircleOutlined"
import { COLOR } from "@finway-group/shared/lib/consts"
import {
    Approval,
    ApprovalProcess,
    ApprovalScopeEnum,
    ApprovalStatusEnum,
    ApprovalStep,
    ApproverTypeEnum,
    ComparisonOperatorEnum,
    WorkflowConditionEnum,
} from "@finway-group/shared/lib/models"
import { ApprovalProcessBuildIssueEnum } from "@finway-group/shared/lib/models/approvalProcess/approvalProcessBuildIssueEnum"
import { UserLanguageEnum } from "@finway-group/shared/lib/models/user/userSettings.interface"
import { flattenExpression, isOperator, isWorkflowCondition } from "@finway-group/shared/lib/utils/logicalExpression.utils"
import { Alert, Button, Card, Divider, Menu, Popover, Steps, Tag } from "antd"
import Dropdown from "antd/es/dropdown"
import Tooltip from "antd/es/tooltip"
import { StepProps } from "antd/lib/steps"
import moment from "moment"
import React from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

import EmployeeLink from "Components/employeeLink"
import { getWorkflowById } from "Shared/hooks/workflow.hooks"
import i18n from "Shared/locales/i18n"
import { ApprovalProcessService, EmployeeService, UserService } from "Shared/services"
import { getStatusLabelForApprovalProcess } from "Shared/utils/approvalProcess.utils"
import { formatCurrencyNumber } from "Shared/utils/helper.utils"
import { getPopupAnchor } from "Shared/utils/popup.utils"

interface ApprovalProcessesIndicatorInterface {
    approvalProcesses: Array<ApprovalProcess>
    scope?: ApprovalScopeEnum
    showWorkflows?: boolean
    title?: string
    buttonClassName?: string
    showLoadingSpinner?: boolean
}

const renderConditionLabel = (step: ApprovalStep) => {
    let label = ""
    if (step.conditionExpression) {
        // we dont support complex expressions yet.
        if (isOperator(step.conditionExpression)) return ""

        // We only support IF AMOUNT > condition for now.
        if (isWorkflowCondition(step.conditionExpression) && step.conditionExpression.condition === WorkflowConditionEnum.NET_AMOUNT) {
            const { comparison, currency, amount } = step.conditionExpression
            if (comparison === ComparisonOperatorEnum.GREATER_THAN) {
                label = i18n.t("label:workflow.condition.amount", {
                    comparison: ComparisonOperatorEnum.GREATER_THAN,
                    amount: formatCurrencyNumber(amount, currency),
                })
            }
        }
    }

    if (step.status === ApprovalStatusEnum.SKIPPED) label += ` ${i18n.t("label:skipped")}`

    return <p className="whitespace-no-wrap text-xs opacity-75">{label}</p>
}

const renderApproverLabel = (approval: Approval) => {
    const loggedInProfile = UserService.getLoggedInEmployeeProfile()

    const approverEmployee = EmployeeService.getEmployeeById(approval.approver._id)
    const approverName = approval.approver.firstName ? `${approval.approver.firstName} ${approval.approver.lastName}` : approverEmployee?.getFullName()
    let workflowApproverName = approverName
    if (approval.workflowApprover && approval.approver._id !== approval.workflowApprover) {
        const employee = EmployeeService.getEmployeeById(approval.workflowApprover)
        workflowApproverName = `${employee?.firstName} ${employee?.lastName}`
    }

    let approverTypeString
    if (approval.approverType === ApproverTypeEnum.SUPERIOR) approverTypeString = ` (${i18n.t("label:superior")})`
    else if (approval.approverType === ApproverTypeEnum.RESPONSIBLE_USER) approverTypeString = ` (${i18n.t("label:responsible_employee")})`
    else if (approval.approverType === ApproverTypeEnum.FALLBACK_APPROVER) approverTypeString = ` (${i18n.t("label:fallback_approver")})`
    else if (approval.approverType === ApproverTypeEnum.REQUESTER) approverTypeString = ` (${i18n.t("label:requester")})`

    if (workflowApproverName && workflowApproverName !== approverName) {
        approverTypeString = `${i18n.t("conjunction:replaces")} ${workflowApproverName}${approverTypeString ?? ""}`
    }
    const dateFormat = loggedInProfile.settings.language === UserLanguageEnum.DE ? "DD.MM.YY" : "YY-MM-DD"
    // Do not remove the wrapping divs of employeeLink or the tooltip wrapper won't work.
    const employeeLink = (
        <div>
            <EmployeeLink
                employee={approverEmployee}
                expenseExtendedRefUser={approval.approver.firstName ? approval.approver : undefined}
                avatarBadge={approval.approvedBy ? "success" : approval.rejectedBy ? "failure" : undefined}
                heightPadding={false}
                approverTypeString={approverTypeString}
                showEmailTooltip={false}
            />
        </div>
    )

    // Data for the tooltip
    let tooltip
    if (approval.dateProcessed && (approval.approvedBy || approval.rejectedBy)) {
        const approvedOrRejectedBy = approval.approvedBy || approval.rejectedBy
        const approvedOrRejectedByName = `${approvedOrRejectedBy?.firstName} ${approvedOrRejectedBy?.lastName}`
        const dateString = approval.dateProcessed ? moment(approval.dateProcessed).format(dateFormat) : ""
        let tooltipTranslation
        const isApproverEqualToApprovedBy = approval.approvedBy === approval.approver || approval.rejectedBy === approval.approver
        if (isApproverEqualToApprovedBy) {
            tooltipTranslation = approval.approvedBy ? "tooltips:approval_process.has_approved_request_on" : "tooltips:approval_process.has_rejected_request_on"
        } else {
            tooltipTranslation = approval.approvedBy ? "tooltips:approval_process.has_approved_request_on_for" : "tooltips:approval_process.has_rejected_request_on_for"
        }
        const tooltipText = i18n.t(tooltipTranslation, { name: approvedOrRejectedByName, date: dateString, ...(!isApproverEqualToApprovedBy && { approverName }) })
        tooltip = (
            <Tooltip title={tooltipText} overlayInnerStyle={{ whiteSpace: "nowrap" }} overlayStyle={{ width: "max-content" }}>
                {employeeLink}
            </Tooltip>
        )
    }

    return <div>{tooltip ?? employeeLink}</div>
}

const renderStepDescription = (step: ApprovalStep) => {
    const approvals = flattenExpression(step.approvalExpression)
    const operator = isOperator(step.approvalExpression) ? step.approvalExpression.operator : undefined
    const tag = operator ? <Tag className="h-20">{i18n.t(`label:workflow.operator.${operator.toLowerCase()}`)}</Tag> : undefined
    return (
        <>
            {step.conditionExpression && renderConditionLabel(step)}
            <div className="flex gap-10 w-auto">
                {approvals.map((approval, index) => (
                    <div key={index} className="flex gap-10 items-center">
                        {renderApproverLabel(approval)}
                        {index !== approvals.length - 1 && operator && tag}
                    </div>
                ))}
            </div>
        </>
    )
}

const renderBuildIssuesAlert = (approvalProcess: ApprovalProcess) => {
    const { buildIssues } = approvalProcess
    if (!buildIssues) return <></>

    const alerts = []

    // This build issue means that at least one approver of the final step is not enrolled in the cards. When this happens we add an extra step. We will show an info alert to let the user know this.
    const finalStepApproversNotEnrolledIssue = buildIssues.issues.find((buildIssue) => buildIssue.issue === ApprovalProcessBuildIssueEnum.FINAL_STEP_APPROVERS_NOT_ENROLLED)
    if (finalStepApproversNotEnrolledIssue) {
        alerts.push(
            <Alert
                className="my-1"
                message={<p className="mt-2 text-xs">{ApprovalProcessService.getBuildIssueTranslationString(finalStepApproversNotEnrolledIssue)}</p>}
                type="info"
                showIcon
            />,
        )
    }

    // This build issue means that the previous steps are estimated and approvers stated there might be not the actual approver. You should check comments to see who approved unequivocally. Only expenses that existed when workflow refactor was released will have this issue. All new expenses won't have it.
    const estimatedPastStepsIssue = buildIssues.issues.find((buildIssue) => buildIssue.issue === ApprovalProcessBuildIssueEnum.ESTIMATED_PAST_STEPS)
    if (estimatedPastStepsIssue) {
        alerts.push(
            <Alert
                className="my-1"
                message={<p className="mt-2 text-xs">{ApprovalProcessService.getBuildIssueTranslationString(estimatedPastStepsIssue)}</p>}
                type="warning"
                showIcon
            />,
        )
    }

    // This are the issues that cause the approval process to not be built from the workflow.
    const otherBuildIssues = buildIssues.issues.filter(
        (buildIssue) => ![ApprovalProcessBuildIssueEnum.ESTIMATED_PAST_STEPS, ApprovalProcessBuildIssueEnum.FINAL_STEP_APPROVERS_NOT_ENROLLED].includes(buildIssue.issue),
    )

    if (otherBuildIssues.length) {
        const workflow = getWorkflowById(buildIssues.triggeredWorkflowId)
        alerts.push(
            <Alert
                className="my-1"
                message={
                    <>
                        <p className="text-xs">{i18n.t("info:approval_process.build_issue.title", { workflowName: workflow?.name ?? "" })}</p>
                        <ul>
                            {otherBuildIssues.map((buildIssue) => (
                                <li key={buildIssue.issue}>{<p className="text-xs">{`- ${ApprovalProcessService.getBuildIssueTranslationString(buildIssue)}`}</p>}</li>
                            ))}
                        </ul>
                    </>
                }
                type="warning"
                showIcon
            />,
        )
    }

    return (
        !!alerts.length && (
            <>
                {alerts}
                <Divider />
            </>
        )
    )
}

const renderApprovalTitle = (approvalProcess: ApprovalProcess, showWorkflows: boolean) => {
    const { t } = useTranslation()
    const workflow = getWorkflowById(approvalProcess?.workflow)
    return (
        <div key={approvalProcess.scope} className="flex gap-4 items-center font-normal">
            <p className="whitespace-no-wrap">{approvalProcess.scope === ApprovalScopeEnum.EXPENSE_PURCHASE ? t("label:purchase_approval") : t("label:invoice_approval")}</p>
            {workflow && showWorkflows && (
                <>
                    <span>-</span>
                    <div className="text-xs gray-200">
                        <div className="flex">
                            <Link key="workflowLink" to={`/workflows/${workflow._id}`}>
                                <p className="whitespace-no-wrap">{workflow.name}</p>
                            </Link>
                            <span className="ml-5">{t("label:workflow.workflow")}</span>
                        </div>
                    </div>
                </>
            )}
            <span>-</span>
            <span className="whitespace-no-wrap text-gray-800 py-0">{getStatusLabelForApprovalProcess(approvalProcess.status)}</span>
        </div>
    )
}

// eslint-disable-next-line arrow-body-style
export const ApprovalProcessesIndicator: React.FC<ApprovalProcessesIndicatorInterface> = ({ approvalProcesses, showWorkflows = true }) => {
    return (
        <div className="flex flex-col items-center flex-grow">
            {approvalProcesses.map((approvalProcess) => {
                const buildIssuesAlert = renderBuildIssuesAlert(approvalProcess)
                const approvalTitle = renderApprovalTitle(approvalProcess, showWorkflows)
                const currentStep =
                    approvalProcess.status === ApprovalStatusEnum.IN_PROGRESS
                        ? approvalProcess?.steps.findIndex((step) => step.status === ApprovalStatusEnum.IN_PROGRESS)
                        : approvalProcess.status === ApprovalStatusEnum.APPROVED || approvalProcess.status === ApprovalStatusEnum.REJECTED
                        ? approvalProcess.steps.length
                        : -1

                return (
                    <div className="max-h-300 w-full flex justify-start flex-col">
                        <h3>{approvalTitle}</h3>
                        <Card key={approvalProcess.scope} className="m-0 overflow-auto max-h-300 w-full no-padding-bottom" size="small">
                            {buildIssuesAlert && <div>{buildIssuesAlert}</div>}

                            <Steps direction="vertical" className="approval-process-steps-indicator w-auto inline-block" size="small" current={currentStep}>
                                {approvalProcess?.steps.map((step) => {
                                    let status: StepProps["status"]
                                    if (step.status === ApprovalStatusEnum.REJECTED) status = "error"
                                    return (
                                        <Steps.Step
                                            key={`${step}`}
                                            status={status}
                                            className={step.status === ApprovalStatusEnum.SKIPPED ? "skipped-step" : ""}
                                            title={renderStepDescription(step)}
                                        />
                                    )
                                })}
                            </Steps>
                        </Card>
                    </div>
                )
            })}
        </div>
    )
}

const calculateStepInProgressIndex = (currentApprovalProcess: ApprovalProcess<string> | undefined) =>
    currentApprovalProcess
        ? currentApprovalProcess.steps[0].status === ApprovalStatusEnum.NOT_STARTED
            ? 0
            : currentApprovalProcess.steps.findIndex((step) => step.status === ApprovalStatusEnum.IN_PROGRESS) + 1
        : undefined

export const ApprovalProcessIndicatorTag: React.FC<ApprovalProcessesIndicatorInterface> = ({ scope, approvalProcesses, showLoadingSpinner }) => {
    const currentApprovalProcess = approvalProcesses.find((approvalProcess) => approvalProcess.scope === scope)

    const stepInProgressIndex = calculateStepInProgressIndex(currentApprovalProcess)
    return (
        <Popover getPopupContainer={getPopupAnchor(undefined, true)} content={<ApprovalProcessesIndicator approvalProcesses={approvalProcesses} />}>
            <Tag className="flex items-center" color={COLOR.finway.green} icon={<ExclamationCircleOutlined />} style={{ maxHeight: "23px" }}>
                {showLoadingSpinner ? (
                    <div className="inline-block mb-2">
                        <LoadingOutlined style={{ fontSize: 14 }} spin />
                    </div>
                ) : (
                    currentApprovalProcess && <p className="inline-block mt-2"> {`${stepInProgressIndex}/${currentApprovalProcess.steps.length}`}</p>
                )}
            </Tag>
        </Popover>
    )
}

export const ApprovalProcessIndicatorMenu: React.FC<ApprovalProcessesIndicatorInterface> = ({ approvalProcesses, scope, title, buttonClassName }) => {
    const currentApprovalProcess = approvalProcesses.find((approvalProcess) => approvalProcess.scope === scope)
    const stepInProgressIndex = calculateStepInProgressIndex(currentApprovalProcess)

    const dropDownMenu = () => (
        <Menu>
            {approvalProcesses.map((approvalProcess) => (
                <Menu.Item key={approvalProcess.scope} className="flex items-center min-w-190 cursor-auto hover:bg-white">
                    <ApprovalProcessesIndicator approvalProcesses={[approvalProcess]} />
                </Menu.Item>
            ))}
        </Menu>
    )

    return (
        <Dropdown overlayClassName="z-60" overlay={dropDownMenu} trigger={["click"]} className="flex items-center z-100">
            <Button className={buttonClassName ?? "btn-default m-5"}>
                {currentApprovalProcess && (
                    <Tag className="ant-tag ant-tag-green-highlight pb-2 mr-8" color="primary">
                        {`${stepInProgressIndex}/${currentApprovalProcess.steps.length}`}
                    </Tag>
                )}
                <span>{title ?? i18n.t("action:approval_process")}</span>
            </Button>
        </Dropdown>
    )
}
