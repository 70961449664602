import { Col, Row } from "antd"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { QRCode } from "react-qrcode-logo"
import { useDispatch } from "react-redux"

import { AuthnService } from "Shared/services"
import { setupTwoFactorAuth } from "Shared/store/actions/auth/authActions"

import useStateIfMounted from "../../../shared/utils/hooks/useStateIfMounted"
import TokenCodeForm from "./tokenCode.form"

export enum TFAAppStepEnum {
    QR_CODE_STEP,
    TOKEN_CODE_STEP,
}
interface TfAuthAppInterface {
    setupStep: TFAAppStepEnum
    onLoading: (state: boolean) => void
    onSuccess: () => void
}
const TfAuthForm = ({ setupStep, onSuccess, onLoading }: TfAuthAppInterface) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const [secret, setSecret] = useStateIfMounted("")
    const [qrCodeLink, setQrCodeLink] = useStateIfMounted("")
    const [isCodeWrong, setIsCodeWrong] = useStateIfMounted(false)

    useEffect(() => {
        const initQrCode = async () => {
            const { data } = await AuthnService.fetchTotp()
            setSecret(data.secret)
            setQrCodeLink(data.otpauth_url)
        }

        if (setupStep === TFAAppStepEnum.QR_CODE_STEP) initQrCode()
    }, [])

    const setupAppAuth = async (token: string) => {
        try {
            onLoading(true)
            await dispatch(setupTwoFactorAuth(token))
            setIsCodeWrong(false)
            onLoading(false)
            onSuccess()
        } catch (_error) {
            setIsCodeWrong(true)
            onLoading(false)
        }
    }

    return (
        <div className="w-full h-full m-5">
            {setupStep === TFAAppStepEnum.QR_CODE_STEP && (
                <>
                    <Row>
                        <div className="flex flex-row">
                            <div className="">
                                <div className="font-bold">{t("label:tfa.setup_tfa_app_title")}</div>
                                <div>{t("label:tfa.setup_tfa_app_description")}</div>
                            </div>
                        </div>
                    </Row>

                    <Row className="mb-20 mt-20 w-full text-center tf-auth mx-0" align="middle">
                        <Col xs={24} sm={12}>
                            <div className="qr-code">
                                <QRCode value={qrCodeLink} logoOpacity={0.5} logoWidth={100} logoImage="/images/favicons/192x192.png" qrStyle="dots" size={160} />
                            </div>
                        </Col>
                        <Col xs={24} sm={12}>
                            <div className="auth-code">
                                <span className="code-header">{t("label:tfa.setup_tfa_app_code")}</span>
                                <span className="code-text">{secret}</span>
                            </div>
                        </Col>
                    </Row>
                </>
            )}

            {setupStep === TFAAppStepEnum.TOKEN_CODE_STEP && (
                <div className="items-center justify-center text-center">
                    <TokenCodeForm onSubmit={setupAppAuth} isLoginForm={false} />
                    {isCodeWrong && <p className="mt-20 text-error">{t("label:tfa.confirm_code_error")}</p>}
                </div>
            )}
        </div>
    )
}

export default React.memo(TfAuthForm)
