import { ExpenseTag as ExpenseTagInterface } from "@finway-group/shared/lib/models/expense/expenseTag.enum"
import { Tag } from "antd"
import Col from "antd/lib/col"
import Collapse from "antd/lib/collapse"
import Form from "antd/lib/form"
import Row from "antd/lib/row"
import Select from "antd/lib/select"
import React, { useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import ExpenseTag from "Components/expenseTag"
import { useCompany } from "Shared/hooks/company.hooks"
import { useSubCostCenters } from "Shared/hooks/costCenter.hooks"
import { useExpenseTags } from "Shared/hooks/expenseTags.hooks"
import { doFilterSelect } from "Shared/utils/helper.utils"
import { getPopupAnchor } from "Shared/utils/popup.utils"

interface FormSelectTagsInterface {
    header: string
    initialValue?: Array<string> | Array<ExpenseTagInterface>
    placeholder: string
    onChange?: any
    name: "costCenters" | "expenseTags"
    allowClear?: boolean
    selectAndDeselectAll?: boolean
}

export default function FormSelectTags({ header, initialValue = [], placeholder, onChange, name, allowClear = false, selectAndDeselectAll = false }: FormSelectTagsInterface) {
    const { t } = useTranslation()
    const tags = name === "costCenters" ? useSubCostCenters(true, true) : useExpenseTags()
    const { showCostCentersIds } = useCompany()

    const anchorRef = useRef(null)

    const [isActive, setIsActive] = useState(!!initialValue.length)

    const renderCustomTag = (props: any) => {
        const { label, onClose, closable } = props

        if (!label?.props) return <></>

        if (name == "expenseTags") {
            return (
                <ExpenseTag color={label.props.color} onClose={onClose} closable>
                    {label.props.label}
                </ExpenseTag>
            )
        }

        return (
            <Tag className="ant-tag-filter-report flex flex-row items-center" closable={closable} onClose={onClose}>
                <div className="max-w-200 truncate">{label}</div>
            </Tag>
        )
    }

    return (
        <>
            <div ref={anchorRef} />
            <Collapse ghost className={isActive ? "filter--active" : ""}>
                <Collapse.Panel header={header} key="" forceRender={true}>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Form.Item name={name} style={{ marginBottom: "0px" }} initialValue={initialValue}>
                                <Select
                                    allowClear={allowClear}
                                    showSearch
                                    showArrow
                                    mode="multiple"
                                    placeholder={placeholder}
                                    filterOption={doFilterSelect}
                                    tagRender={renderCustomTag}
                                    getPopupContainer={getPopupAnchor(anchorRef.current, true)}
                                    onChange={(e: []) => {
                                        setIsActive(!!e.length)
                                        if (onChange) onChange(e)
                                    }}
                                >
                                    {selectAndDeselectAll && (
                                        <Select.Option value="all">{tags.length > 0 ? t("action:filter_option.deselect_all") : t("action:filter_option.select_all")}</Select.Option>
                                    )}

                                    {name === "expenseTags"
                                        ? tags.map(({ _id, tagColor, tagName }) => (
                                              <Select.Option key={_id} value={_id} label={tagName}>
                                                  <ExpenseTag color={tagColor} label={tagName}>
                                                      {tagName}
                                                  </ExpenseTag>
                                              </Select.Option>
                                          ))
                                        : tags.map(({ _id, id, name }) => (
                                              <Select.Option key={_id} value={_id} label={showCostCentersIds ? `ID ${id} - ${name}` : name}>
                                                  <Tag className="inline-flex items-center">{showCostCentersIds ? `ID ${id} - ${name}` : name}</Tag>
                                              </Select.Option>
                                          ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Collapse.Panel>
            </Collapse>
        </>
    )
}
