import { Workflow } from "@finway-group/shared/lib/models"
import { Reducer } from "redux"

import { FetchAllWorkflowsAction, NewWorkflowAction, SetWorkflowAction, SyncWorkflowAction, WorkflowActionTypes, WorkflowActions } from "../actions/workflow/workflowTypes"

export interface WorkflowState {
    items: Array<Workflow>
    item?: Workflow
}

const initialState: WorkflowState = {
    items: [],
    item: undefined,
}

const fetchAllWorkflows = (state: WorkflowState, action: FetchAllWorkflowsAction): WorkflowState => {
    let { item } = state
    if (item) {
        const itemInAction = action.workflows.find(({ _id }: any) => _id === item?._id)
        if (itemInAction) item = itemInAction
    }

    return {
        ...state,
        items: action.workflows,
        item,
    }
}

// prettier-ignore
const syncOneWorkflow = (state: WorkflowState, { workflow }: SyncWorkflowAction): WorkflowState => ({
    ...state,
    items: state.items.filter((other) => other._id === workflow._id).length > 0 ? state.items.map((item) => (item._id === workflow._id ? workflow : item)) : [workflow, ...state.items],
    item: state.item?._id === workflow._id ? workflow : state.item,
});

const setWorkflow = (state: WorkflowState, { workflow }: SetWorkflowAction): WorkflowState => ({
    ...state,
    item: workflow,
})

const newWorkflow = (state: WorkflowState, action: NewWorkflowAction): WorkflowState => ({
    ...state,
    items: [...state.items, action.workflow],
})

export const workflowReducer: Reducer<WorkflowState, WorkflowActions> = (state = initialState, action) => {
    switch (action.type) {
        case WorkflowActionTypes.FETCH_ALL_WORKFLOWS:
            return fetchAllWorkflows(state, action)
        case WorkflowActionTypes.SYNC_WORKFLOW:
            return syncOneWorkflow(state, action)
        case WorkflowActionTypes.NEW_WORKFLOW:
            return newWorkflow(state, action)
        case WorkflowActionTypes.SET_WORKFLOW:
            return setWorkflow(state, action)
        default:
            return state
    }
}
