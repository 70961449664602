import * as Sentry from "@sentry/react"
import { createBrowserHistory } from "history"
import React from "react"
import ReactDOM from "react-dom"

import { CI_COMMIT, replaysOnErrorSampleRate, replaysSessionSampleRate, tracesSampleRate } from "Shared/config/consts"
import { ErrorTypes } from "Shared/error.types"
import { isInternal, isProd } from "Shared/utils/helper.utils"

import App from "./App"

if (isProd && !isInternal) {
    console.log = () => {}
    console.error = () => {}
    console.warn = () => {}
    console.info = () => {}
}

// Create Custom Sentry Route components
const history = createBrowserHistory()

if (isProd) {
    Sentry.init({
        // Config for Sentry
        dsn: process.env.SENTRY_OPTION_DSN_URL,

        environment: process.env.DEPLOY_ENVIRONMENT,

        // Config for the release to map errors to version and commit
        release: process.env.npm_package_version,
        dist: CI_COMMIT,

        integrations: [
            // The BrowserTracing integration creates a new transaction for each page load and navigation event, and creates a child span for every XMLHttpRequest or fetch request that occurs while those transactions are open.
            new Sentry.BrowserTracing({
                routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
            }),
            new Sentry.Replay(),
        ],

        ignoreErrors: ["ResizeObserver loop", ErrorTypes.SILENT_REFRESH_FAILED],

        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /finway\.de\/api/],

        enableTracing: true,

        // Sentry rates
        tracesSampleRate,
        replaysSessionSampleRate,
        replaysOnErrorSampleRate,
    })
}

ReactDOM.render(<App history={history} />, document.getElementById("root"))
