import { AggregatePaginateResult, ImportResult, ImportResultTypeEnum } from "@finway-group/shared/lib/models"
import { RcFile } from "antd/lib/upload"
import axios from "axios"

import { prepareFileUpload } from "./file.service"

const ImportService = {
    downloadDataImportSpreadSheetTemplate: () => axios.get<Blob>(`/import/template`, { responseType: "blob" }),
    /**
     * Requests the server to import (validate and create data on the db) the file given in the first parameter.
     * If the file is well formed it will send 202 ACCEPTED and will start the operations asynchronously.
     * Upon completition it will communicate the success/failure of the operation via events.
     * @param file - File to import
     * @param validateOnly - If set to true it will validate data only, but won't create any record in the database.
     */
    requestDataImportFromSpreadsheet: async (file: RcFile, validateOnly = false) => {
        const { formData } = prepareFileUpload(file)
        formData.append("validateOnly", validateOnly.toString())
        await axios.post("/import/collections/spreadsheet", formData)
    },

    fetchImportResult: async (importResultId: string): Promise<ImportResult> => {
        const { data } = await axios.get(`/import/collections/results/${importResultId}`)
        return data
    },

    fetchLatestImportResults: async ({
        limit = 10,
        page = 1,
        importResultType,
    }: { limit?: number; page?: number; importResultType?: ImportResultTypeEnum; getDocumentsCount?: boolean } = {}): Promise<AggregatePaginateResult<ImportResult>> => {
        let query = `?limit=${limit}&page=${page}`
        if (importResultType) query += `&type=${importResultType}`

        const { data } = await axios.get(`/import/collections/results/latest${query}`)
        return data
    },
}

export default ImportService
