import {
    Card,
    CardStatusEnum,
    CostCenter,
    Employee,
    Expense,
    ExportHistory,
    InboxInvoice,
    Transaction,
    TransactionCategory,
    TripFolder,
    Vendor,
    Workflow,
} from "@finway-group/shared/lib/models"
import { insertIf } from "@finway-group/shared/lib/utils"
import { Reducer } from "redux"

import { isDemo } from "Shared/config/consts"
import { getTableDefaultSort } from "Shared/utils/table.utils"

import {
    FetchTableFailureAction,
    FetchTableSuccessAction,
    InitialFetchTablesAction,
    RefetchCardRelatedTablesAction,
    RefetchExpenseRelatedTablesAction,
    RefetchInboxInvoiceTablesAction,
    RefetchMultipleTablesAction,
    RefetchTableAction,
    RefetchTransactionCategoryRelatedTables,
    RefetchUserRelatedTablesAction,
    TableActions,
    TableFetchingAction,
    UpdateDashboardTablesFilterAction,
    UpdateDashboardTablesSearchAction,
    UpdateTableFilterAction,
    UpdateTablePaginationAction,
    UpdateTableSearchAction,
    UpdateTableSortAction,
} from "../actions/tables/tableTypes"
import {
    FetchTableFailureTypes,
    FetchTableSuccessTypes,
    RefetchTableTypes,
    TableActionsTypes,
    TableFetchingTypes,
    UpdateTableFilterType,
    UpdateTablePaginationType,
    UpdateTableSearchType,
    UpdateTableSortType,
} from "../actions/tables/tableTypes.enum"
import { TablesEnum } from "./tableConfigReducer"

export interface TableData<T> {
    docs: Array<T>
    totalDocs: number
    limit: number
    totalPages: number
    page: number // the actual page the table is in based on the current filter/search
    sort: {
        field: string
        order: string // ascend / descend
    }
    filter: { [key: string]: string | object | Array<string> }
    search: string
}

export interface Table<T> {
    data: TableData<T>
    isFetching: boolean
    error: any
    shouldFetch: boolean
}

export type EmployeeWithExpenseData = Employee & { expenseData?: { amount: number; value: number; id: string } }
export type VendorWithExpenseData = Vendor & { expenseData?: { amount: number; value: number; id: string } }
export type CostCenterWithBudgetData = CostCenter & { budgetData?: { capacity: number; consumption: number; forecast: number; id: string } }
export type TransactionCategoryWithChildren = Omit<TransactionCategory, "children"> & { children: Array<TransactionCategory> }

export interface TablesState {
    inboxInvoiceTable: Table<InboxInvoice>
    todoPurchaseApprovalTable: Table<Expense>
    todoInvoiceApprovalTable: Table<Expense>
    todoInvoiceAndPurchaseApprovalTable: Table<Expense>
    todoDocsNeededTable: Table<Expense>
    todoApprovalPendingTable: Table<Expense>
    reviewTable: Table<Expense>
    payAndExportToBePaidTable: Table<Expense>
    payAndExportToBeExportedTable: Table<Expense>
    doneTable: Table<Expense>
    allRequestsTable: Table<Expense>
    inProgressTable: Table<Expense>
    subscriptionTable: Table<Expense>
    possibleMatchesTable: Table<Expense>
    cardTransactionsTable: Table<Transaction>
    transactionInflowTable: Table<Transaction>
    transactionOutflowTable: Table<Transaction>
    transactionAllTable: Table<Transaction>
    cardTable: Table<Card>
    costcenterTable: Table<CostCenterWithBudgetData>
    employeeTable: Table<EmployeeWithExpenseData>
    employeeVendorTable: Table<Employee>
    vendorTable: Table<VendorWithExpenseData>
    workflowTable: Table<Workflow>
    inboxInvoiceModalExpensesTable: Table<Expense>
    inboxInvoiceModalInvoicesTable: Table<InboxInvoice>
    transactionCategoryInflowTable: Table<TransactionCategoryWithChildren>
    transactionCategoryOutflowTable: Table<TransactionCategoryWithChildren>
    archiveTable: Table<Expense>
    expenseTransactionsTable: Table<Transaction>
    transactionArchiveTable: Table<Transaction>
    attachExpenseTable: Table<Expense>
    draftTripFolderTable: Table<TripFolder>
    exportHistoryTable: Table<ExportHistory>
}

export const createInitialTable = (
    { filter, sort }: { filter?: { [key: string]: string | object | Array<string> }; sort?: { field: string; order: string } } = {},
    limit: number = 20,
) => ({
    data: {
        docs: [],
        totalDocs: 0,
        totalPages: 0,

        // query data
        page: 1,
        limit,
        sort: sort || { field: "", order: "" },
        filter: filter || {},
        search: "",
    },
    isFetching: false,
    error: undefined,
    shouldFetch: false,
})

const createInitialTablesState = () => ({
    inboxInvoiceTable: createInitialTable(),
    todoPurchaseApprovalTable: createInitialTable(),
    todoInvoiceApprovalTable: createInitialTable(),
    todoDocsNeededTable: createInitialTable(),
    todoInvoiceAndPurchaseApprovalTable: createInitialTable(),
    todoApprovalPendingTable: createInitialTable(),
    reviewTable: createInitialTable(),
    payAndExportToBePaidTable: createInitialTable(),
    payAndExportToBeExportedTable: createInitialTable(),
    doneTable: createInitialTable({ sort: getTableDefaultSort(TablesEnum.DONE_REQUESTS) }),
    allRequestsTable: createInitialTable(),
    inProgressTable: createInitialTable({ sort: getTableDefaultSort(TablesEnum.IN_PROGRESS) }),
    subscriptionTable: createInitialTable(),
    possibleMatchesTable: createInitialTable(),
    cardTransactionsTable: createInitialTable(),
    transactionInflowTable: createInitialTable({ sort: getTableDefaultSort(TablesEnum.TRANSACTIONS_INFLOW) }),
    transactionOutflowTable: createInitialTable({ sort: getTableDefaultSort(TablesEnum.TRANSACTIONS_OUTFLOW) }),
    transactionAllTable: createInitialTable({ sort: getTableDefaultSort(TablesEnum.TRANSACTIONS_ALL) }),
    cardTable: createInitialTable(...insertIf(!isDemo, { filter: { status: CardStatusEnum.ACTIVE } })),
    costcenterTable: createInitialTable(),
    employeeTable: createInitialTable({ sort: getTableDefaultSort(TablesEnum.EMPLOYEES) }),
    employeeVendorTable: createInitialTable({ sort: getTableDefaultSort(TablesEnum.EMPLOYEE_VENDORS) }),
    vendorTable: createInitialTable(),
    workflowTable: createInitialTable(),
    inboxInvoiceModalExpensesTable: createInitialTable(),
    inboxInvoiceModalInvoicesTable: createInitialTable(),
    archiveTable: createInitialTable(),
    transactionCategoryInflowTable: createInitialTable(),
    transactionCategoryOutflowTable: createInitialTable(),
    expenseTransactionsTable: createInitialTable({}, 5),
    draftTripFolderTable: createInitialTable({}, 5),
    attachExpenseTable: createInitialTable(),
    transactionArchiveTable: createInitialTable({ sort: getTableDefaultSort(TablesEnum.TRANSACTIONS_ARCHIVE) }),
    exportHistoryTable: createInitialTable(),
})

export const initialTablesState = createInitialTablesState()

const setTableFetching = (state: TablesState, action: TableFetchingAction): TablesState => {
    const { table, isFetching, shouldFetch } = action.payload
    return { ...state, [table]: { ...state[table], isFetching, shouldFetch } }
}

const setTableData = <T>(state: TablesState, action: FetchTableSuccessAction<T>): TablesState => {
    const { table, isFetching, error, shouldFetch } = action.payload
    const data = { ...state[table].data, ...action.payload.data, count: action.payload.data.totalDocs }
    return { ...state, [table]: { ...state[table], data, isFetching, error, shouldFetch } }
}

const resetExpenseArchiveTable = (state: TablesState): TablesState => ({ ...state, archiveTable: createInitialTable() })

const setTableError = (state: TablesState, action: FetchTableFailureAction): TablesState => {
    const { table, error, isFetching, shouldFetch } = action.payload
    return { ...state, [table]: { ...state[table], error, isFetching, shouldFetch } }
}

const setTableShouldFetch = (state: TablesState, action: RefetchTableAction): TablesState => ({
    ...state,
    [action.payload.table]: { ...state[action.payload.table], shouldFetch: action.payload.shouldFetch },
})

// Sets the shouldFetch flag on all tables to true so they are fetched the first time the user navigates to the component where the table is.
const initialFetchTables = (state: TablesState, action: InitialFetchTablesAction): TablesState => {
    const stateCopy: any = { ...state }
    for (const table of Object.keys(stateCopy)) {
        if (table === TablesEnum.ARCHIVE) continue // The archive table should not be initially fetch, but rather when the user enters a fetching period
        stateCopy[table].shouldFetch = action.payload.shouldFetch
    }
    return stateCopy
}

const resetAllTables = (): TablesState => createInitialTablesState()

const refetchInboxInvoiceTables = (state: TablesState, action: RefetchInboxInvoiceTablesAction): TablesState => {
    const { shouldFetch } = action.payload
    return {
        ...state,
        inboxInvoiceTable: { ...state.inboxInvoiceTable, shouldFetch },
        inboxInvoiceModalInvoicesTable: { ...state.inboxInvoiceModalInvoicesTable, shouldFetch },
    }
}

const refetchRelatedTables = (
    state: TablesState,
    action:
        | RefetchMultipleTablesAction
        | RefetchCardRelatedTablesAction
        | RefetchUserRelatedTablesAction
        | RefetchExpenseRelatedTablesAction
        | RefetchTransactionCategoryRelatedTables,
): TablesState => {
    const { shouldFetch, tables } = action.payload
    const stateCopy: any = { ...state }
    for (const table of tables) {
        stateCopy[table] = { ...stateCopy[table], shouldFetch }
    }
    return stateCopy
}

const updateDashboardTablesSearch = (state: TablesState, action: UpdateDashboardTablesSearchAction): TablesState => {
    const { shouldFetch, search } = action.payload
    return {
        ...state,
        todoPurchaseApprovalTable: { ...state.todoPurchaseApprovalTable, shouldFetch, data: { ...state.todoPurchaseApprovalTable.data, search } },
        todoInvoiceApprovalTable: { ...state.todoInvoiceApprovalTable, shouldFetch, data: { ...state.todoInvoiceApprovalTable.data, search } },
        todoInvoiceAndPurchaseApprovalTable: { ...state.todoInvoiceAndPurchaseApprovalTable, shouldFetch, data: { ...state.todoInvoiceAndPurchaseApprovalTable.data, search } },
        todoDocsNeededTable: { ...state.todoDocsNeededTable, shouldFetch, data: { ...state.todoDocsNeededTable.data, search } },
        todoApprovalPendingTable: { ...state.todoApprovalPendingTable, shouldFetch, data: { ...state.todoApprovalPendingTable.data, search } },
        payAndExportToBePaidTable: { ...state.payAndExportToBePaidTable, shouldFetch, data: { ...state.payAndExportToBePaidTable.data, search } },
        payAndExportToBeExportedTable: { ...state.payAndExportToBeExportedTable, shouldFetch, data: { ...state.payAndExportToBeExportedTable.data, search } },
        reviewTable: { ...state.reviewTable, shouldFetch, data: { ...state.reviewTable.data, search } },
        doneTable: { ...state.doneTable, shouldFetch, data: { ...state.doneTable.data, search } },
        inProgressTable: { ...state.inProgressTable, shouldFetch, data: { ...state.inProgressTable.data, search } },
        allRequestsTable: { ...state.allRequestsTable, shouldFetch, data: { ...state.allRequestsTable.data, search } },
        draftTripFolderTable: { ...state.draftTripFolderTable, shouldFetch, data: { ...state.draftTripFolderTable.data, search } },
    }
}

const updateDashboardTablesFilter = (state: TablesState, action: UpdateDashboardTablesFilterAction): TablesState => {
    const { shouldFetch, filter } = action.payload
    return {
        ...state,
        todoPurchaseApprovalTable: { ...state.todoPurchaseApprovalTable, shouldFetch, data: { ...state.todoPurchaseApprovalTable.data, filter } },
        todoInvoiceApprovalTable: { ...state.todoInvoiceApprovalTable, shouldFetch, data: { ...state.todoInvoiceApprovalTable.data, filter } },
        todoDocsNeededTable: { ...state.todoDocsNeededTable, shouldFetch, data: { ...state.todoDocsNeededTable.data, filter } },
        todoInvoiceAndPurchaseApprovalTable: { ...state.todoInvoiceAndPurchaseApprovalTable, shouldFetch, data: { ...state.todoInvoiceAndPurchaseApprovalTable.data, filter } },
        todoApprovalPendingTable: { ...state.todoApprovalPendingTable, shouldFetch, data: { ...state.todoApprovalPendingTable.data, filter } },
        payAndExportToBePaidTable: { ...state.payAndExportToBePaidTable, shouldFetch, data: { ...state.payAndExportToBePaidTable.data, filter } },
        payAndExportToBeExportedTable: { ...state.payAndExportToBeExportedTable, shouldFetch, data: { ...state.payAndExportToBeExportedTable.data, filter } },
        reviewTable: { ...state.reviewTable, shouldFetch, data: { ...state.reviewTable.data, filter } },
        doneTable: { ...state.doneTable, shouldFetch, data: { ...state.doneTable.data, filter } },
        inProgressTable: { ...state.inProgressTable, shouldFetch, data: { ...state.inProgressTable.data, filter } },
        allRequestsTable: { ...state.allRequestsTable, shouldFetch, data: { ...state.allRequestsTable.data, filter } },
        draftTripFolderTable: { ...state.draftTripFolderTable, shouldFetch, data: { ...state.draftTripFolderTable.data, filter } },
    }
}

const updateTablePagination = (state: TablesState, action: UpdateTablePaginationAction): TablesState => {
    const { table, page, limit, shouldFetch } = action.payload
    const data = { ...state[table].data, page, limit }
    return { ...state, [table]: { ...state[table], shouldFetch, data } }
}

const setTableSort = (state: TablesState, action: UpdateTableSortAction): TablesState => {
    const { table, sort, shouldFetch } = action.payload
    return { ...state, [table]: { ...state[table], data: { ...state[table].data, sort: { ...state[table].data.sort, order: sort.order, field: sort.field } }, shouldFetch } }
}

const setTableSearch = (state: TablesState, action: UpdateTableSearchAction): TablesState => {
    const { table, search, shouldFetch } = action.payload
    return { ...state, [table]: { ...state[table], data: { ...state[table].data, search }, shouldFetch } }
}

const setTableFilter = (state: TablesState, action: UpdateTableFilterAction): TablesState => {
    const { table, filter, shouldFetch } = action.payload
    return { ...state, [table]: { ...state[table], data: { ...state[table].data, filter }, shouldFetch } }
}

export const tableReducer: Reducer<TablesState, TableActions> = (state = initialTablesState, action) => {
    switch (action.type) {
        // All tables
        case TableActionsTypes.INITIAL_FETCH_TABLES:
            return initialFetchTables(state, action)
        case TableActionsTypes.RESET_ALL_TABLES:
            return resetAllTables()

        // Dashboard tables
        case TableActionsTypes.UPDATE_DASHBOARD_TABLES_FILTER:
            return updateDashboardTablesFilter(state, action)
        case TableActionsTypes.UPDATE_DASHBOARD_TABLES_SEARCH:
            return updateDashboardTablesSearch(state, action)
        case TableActionsTypes.RESET_TABLE_ARCHIVE:
            return resetExpenseArchiveTable(state)

        // Inbox invoice tables
        case TableActionsTypes.REFETCH_INBOX_INVOICE_TABLES:
            return refetchInboxInvoiceTables(state, action)

        case TableActionsTypes.REFETCH_EXPENSE_RELATED_TABLES:
        case TableActionsTypes.REFETCH_CARD_RELATED_TABLES:
        case TableActionsTypes.REFETCH_USER_RELATED_TABLES:
        case TableActionsTypes.REFETCH_TRANSACTION_CATEGORY_RELATED_TABLES:
        case TableActionsTypes.REFETCH_MULTIPLE_TABLES:
            return refetchRelatedTables(state, action)

        default:
            return singleTableReducer(state, action)
    }
}

const singleTableReducer: Reducer<TablesState, TableActions> = (state = initialTablesState, action) => {
    switch (true) {
        case Object.values(TableFetchingTypes).includes(action.type as TableFetchingTypes):
            return setTableFetching(state, action as TableFetchingAction)

        case Object.values(FetchTableSuccessTypes).includes(action.type as any):
            return setTableData(state, action as FetchTableSuccessAction)

        case Object.values(FetchTableFailureTypes).includes(action.type as any):
            return setTableError(state, action as FetchTableFailureAction)

        case Object.values(RefetchTableTypes).includes(action.type as RefetchTableTypes):
            return setTableShouldFetch(state, action as RefetchTableAction)

        case Object.values(UpdateTablePaginationType).includes(action.type as UpdateTablePaginationType):
            return updateTablePagination(state, action as UpdateTablePaginationAction)

        case Object.values(UpdateTableSortType).includes(action.type as UpdateTableSortType):
            return setTableSort(state, action as UpdateTableSortAction)

        case Object.values(UpdateTableSearchType).includes(action.type as UpdateTableSearchType):
            return setTableSearch(state, action as UpdateTableSearchAction)

        case Object.values(UpdateTableFilterType).includes(action.type as UpdateTableFilterType):
            return setTableFilter(state, action as UpdateTableFilterAction)

        default:
            return state
    }
}
