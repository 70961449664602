import { EmployeeFilter, RightEnum, Team } from "@finway-group/shared/lib/models"
import { Form } from "antd"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"

import { FilterFormInterface } from "Components/filter/filterTypes"
import FormSelect from "Components/form/formSelect"
import { getTableFilterObjectStore } from "Shared/hooks/table.hooks"
import { AuthzService } from "Shared/services"
import { createYesOrNoOption, getDynamicLocationList, getDynamicRoleList, getDynamicTeamList } from "Shared/utils/filter.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

const EmployeeFilterForm: React.FC<FilterFormInterface> = ({ table, formInstance, options, onSubmit }) => {
    const { t } = useTranslation()
    const tableFilterObject = getTableFilterObjectStore<EmployeeFilter>(table)
    const [employeeFilter, setEmployeeFilter] = useStateIfMounted(tableFilterObject)

    useEffect(() => {
        formInstance.resetFields()
        setEmployeeFilter({ ...tableFilterObject })
    }, [])

    useEffect(() => {
        formInstance.validateFields()
    }, [employeeFilter])

    const yesOrNoOptions = createYesOrNoOption()

    return (
        <Form id="employeeFilterForm" className="form--filter" form={formInstance} onFinish={onSubmit}>
            <FormSelect
                name="location"
                header={t("input:employee.location")}
                initialValue={employeeFilter.location}
                options={getDynamicLocationList(options.locations as Array<string>)}
                isCollapse
                showSearch
                fallback
            />

            {AuthzService.isRightGrantedForLoggedInUser(RightEnum.EMPLOYEE__ALL__READ) && (
                <>
                    <FormSelect
                        name="team"
                        header={t("input:filter_form.team")}
                        initialValue={employeeFilter.team}
                        options={getDynamicTeamList(options.teams as Array<Team>)}
                        isCollapse
                        showSearch
                        fallback
                    />

                    <FormSelect
                        name="roleId"
                        header={t("input:filter_form.role")}
                        initialValue={employeeFilter.roleId}
                        options={getDynamicRoleList(options.roles ?? [])}
                        isCollapse
                        showSearch
                        fallback
                    />

                    <FormSelect
                        name="cardProgramEnrolled"
                        header={t("input:filter_form.card_program_enrolled")}
                        initialValue={employeeFilter.cardProgramEnrolled}
                        options={yesOrNoOptions}
                        isCollapse
                        fallback
                    />

                    <FormSelect
                        name="superiorSet"
                        header={t("input:filter_form.superior_set")}
                        initialValue={employeeFilter.superiorSet}
                        options={yesOrNoOptions}
                        isCollapse
                        fallback
                    />

                    <FormSelect
                        name="phoneNumberVerified"
                        header={t("input:filter_form.phone_number_verified")}
                        initialValue={employeeFilter.phoneNumberVerified}
                        options={yesOrNoOptions}
                        isCollapse
                        fallback
                    />

                    <FormSelect
                        name="tfaEnabled"
                        header={t("input:filter_form.tfa_enabled")}
                        initialValue={employeeFilter.tfaEnabled}
                        options={yesOrNoOptions}
                        isCollapse
                        fallback
                    />
                </>
            )}
        </Form>
    )
}

export default EmployeeFilterForm
