import { ExpenseKindEnum } from "@finway-group/shared/lib/models"
import { Button, Form, Modal } from "antd"
import { Store } from "antd/lib/form/interface"
import React from "react"
import { useTranslation } from "react-i18next"

import { FilterModalInterface } from "Components/filter/filterTypes"
import CardFilterForm from "Components/forms/cardFilter.form"
import CostCenterFilterForm from "Components/forms/costCenterFilter.form"
import EmployeeFilterForm from "Components/forms/employeeFilter.form"
import ExpenseFilterForm from "Components/forms/expenseFilter.form"
import InboxFilterForm from "Components/forms/inboxFilter.form"
import PossibleMatchFilterForm from "Components/forms/possibleMatchFilter.form"
import SubscriptionFilterForm from "Components/forms/subscriptionFilter.form"
import TransactionFilterForm from "Components/forms/transactionFilter.form"
import VendorFilterForm from "Components/forms/vendorFilter.form"
import WorkflowFilterForm from "Components/forms/workflowFilter.form"
import { ALL_REIMBURSEMENTS_OPTION } from "Shared/config/consts"
import { TablesEnum } from "Shared/store/reducers/tableConfigReducer"
import { adjustFilterDataForSelectedExpenseType } from "Shared/utils/expense.utils"
import { adjustTimeRangeOfDateFields, getFilterTargetPage } from "Shared/utils/filter.utils"

const FilterModal: React.FC<FilterModalInterface> = ({ table, isVisible, options = {}, onCancel, onSubmit }) => {
    const { t } = useTranslation()
    const [formInstance] = Form.useForm()

    const handleSubmit = async (values: Store) => {
        let filterValues = { ...values }
        // extend inside this function min/max params whenever we add a new minMax date component to filters
        adjustTimeRangeOfDateFields(filterValues)
        // this condition checks if the filter form is for an expense table.
        if (getFilterTargetPage(table) === TablesEnum.TODO_INVOICE_APPROVAL || table === TablesEnum.POSSIBLE_MATCHES || table === TablesEnum.ATTACH_EXPENSE || TablesEnum.ARCHIVE) {
            if (filterValues.kind === ALL_REIMBURSEMENTS_OPTION) {
                filterValues.kind = [ExpenseKindEnum.ONE_TIME_EXPENSE, ExpenseKindEnum.MILEAGE, ExpenseKindEnum.HOSPITALITY, ExpenseKindEnum.PER_DIEM]
                filterValues.isReimbursement = true
            }

            filterValues = adjustFilterDataForSelectedExpenseType(filterValues)

            if ([ExpenseKindEnum.MILEAGE, ExpenseKindEnum.HOSPITALITY, ExpenseKindEnum.PER_DIEM].includes(filterValues.kind)) {
                filterValues.isReimbursement = true
            }
        }

        handleHide()
        await onSubmit(filterValues)
    }

    const handleHide = () => {
        onCancel(false)
    }

    return (
        <Modal
            title={t("action:filter")}
            visible={isVisible}
            maskClosable={false}
            onOk={formInstance.submit}
            onCancel={handleHide}
            keyboard
            destroyOnClose
            footer={[
                <Button
                    key="back"
                    onClick={() => {
                        formInstance.resetFields()
                        onCancel(false)
                    }}
                >
                    {t("action:cancel")}
                </Button>,
                <Button
                    key="submit"
                    type="primary"
                    onClick={() => {
                        formInstance.submit()
                    }}
                >
                    {t("action:apply_filter")}
                </Button>,
            ]}
        >
            {table === TablesEnum.CARDS ? (
                <CardFilterForm table={table} options={options} formInstance={formInstance} onSubmit={handleSubmit} />
            ) : table === TablesEnum.EMPLOYEES ? (
                <EmployeeFilterForm table={table} options={options} formInstance={formInstance} onSubmit={handleSubmit} />
            ) : table === TablesEnum.SUBSCRIPTIONS ? (
                <SubscriptionFilterForm table={table} options={options} formInstance={formInstance} onSubmit={handleSubmit} />
            ) : table === TablesEnum.TRANSACTIONS_INFLOW ||
              table === TablesEnum.TRANSACTIONS_OUTFLOW ||
              table === TablesEnum.TRANSACTIONS_ALL ||
              table === TablesEnum.TRANSACTIONS_ARCHIVE ? (
                <TransactionFilterForm table={table} options={options} formInstance={formInstance} onSubmit={handleSubmit} />
            ) : table === TablesEnum.POSSIBLE_MATCHES ? (
                <PossibleMatchFilterForm table={table} options={options} formInstance={formInstance} onSubmit={handleSubmit} />
            ) : table === TablesEnum.WORKFLOWS ? (
                <WorkflowFilterForm table={table} options={options} formInstance={formInstance} onSubmit={handleSubmit} />
            ) : table === TablesEnum.VENDORS ? (
                <VendorFilterForm table={table} options={options} formInstance={formInstance} onSubmit={handleSubmit} />
            ) : table === TablesEnum.COST_CENTERS ? (
                <CostCenterFilterForm table={table} options={options} formInstance={formInstance} onSubmit={handleSubmit} />
            ) : [TablesEnum.TODO_INVOICE_APPROVAL, TablesEnum.ARCHIVE].includes(getFilterTargetPage(table)) ? (
                <ExpenseFilterForm table={table} options={options} formInstance={formInstance} onSubmit={handleSubmit} />
            ) : [TablesEnum.ATTACH_EXPENSE, TablesEnum.INBOX_INVOICE_MODAL_EXPENSES].includes(table) ? (
                <ExpenseFilterForm table={table} options={options} formInstance={formInstance} onSubmit={handleSubmit} />
            ) : table === TablesEnum.INBOX_INVOICE ? (
                <InboxFilterForm table={table} options={options} formInstance={formInstance} onSubmit={handleSubmit} />
            ) : null}
        </Modal>
    )
}

export default FilterModal
