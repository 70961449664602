import { RightEnum } from "@finway-group/shared/lib/models"
import { Badge, Divider, Layout, Menu } from "antd"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Link, useLocation } from "react-router-dom"

import Icon from "Components/icon"
import CashFlowPlanningIcon from "Components/icons/cashFlowPlanning"
import CategorySettingsIcon from "Components/icons/categorySettings"
import DashboardLiquidityIcon from "Components/icons/dashboardLiquidity"
import { BetaTag } from "Components/tags/BetaTag"
import { HELP_CENTER_LINK } from "Shared/config/consts"
import { useAuth } from "Shared/hooks/auth.hooks"
import { useCompany } from "Shared/hooks/company.hooks"
import { useExpenseCounts } from "Shared/hooks/expense.hooks"
import { useIsFreeVersion } from "Shared/hooks/featureFlags.hooks"
import { useLoggedInEmployeeProfile } from "Shared/hooks/user.hooks"
import { AuthzService } from "Shared/services"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

import ActionMenuSidebar from "./navbar/actionMenu.sidebar"

const { Sider } = Layout

const showLiquidity = true // Set to true to show the WIP liquidity feature. TO BE REMOVED WHEN THE FEATURE IS READY.

interface SideBarInterface {
    onMobileDevice: boolean
    showMobileNav: boolean
}
export const Sidebar: React.FC<SideBarInterface> = ({ onMobileDevice, showMobileNav }) => {
    const { t } = useTranslation()
    const location = useLocation()
    const [badgeValue, setBadgeValue] = useStateIfMounted(0)

    const { isAuthenticated } = useAuth()

    const expenseCounts = useExpenseCounts()
    const company = useCompany()
    const isFreeVersion = useIsFreeVersion()
    const loggedInUser = useLoggedInEmployeeProfile()

    const [activeMenuItem, setActiveMenuItem] = useStateIfMounted("")

    useEffect(() => {
        const pathnameParts = location.pathname ? location.pathname.split("/") : []

        switch (pathnameParts[1]) {
            case "liquidity":
                setActiveMenuItem(isFreeVersion ? pathnameParts[2] : pathnameParts[1])
                break
            case "settings":
                setActiveMenuItem("")
                break
            case pathnameParts.length > 1 && pathnameParts[1]:
                setActiveMenuItem(pathnameParts[1])
                break
            default:
                setActiveMenuItem("expenses")
                break
        }
    }, [location.pathname])

    const asideStyles = {
        left: !onMobileDevice || showMobileNav ? "0px" : "-200px",
        transition: "left .5s",
    }

    useEffect(() => {
        setBadgeValue(expenseCounts.toDo + expenseCounts.review + expenseCounts.payExport)
    }, [expenseCounts.toDo, expenseCounts.review, expenseCounts.payExport])

    return isAuthenticated && loggedInUser ? (
        <Sider className="z-50 bg-body h-full w-200 sm:w-3/12 md:w-3/12 lg:w-2/12 pt-20 sm:pt-34 md:pt-34 pl-16 fixed lg:relative" style={asideStyles}>
            {isFreeVersion ? (
                <Menu className="fixed" selectedKeys={[activeMenuItem]}>
                    <Menu.Item key="cashflow-planning">
                        <Link to="/liquidity/cashflow-planning">
                            <CashFlowPlanningIcon />
                            <span>{t("nav:cashflow_planning")}</span>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="dashboard">
                        <Link to="/liquidity/dashboard">
                            <DashboardLiquidityIcon />
                            <span>{t("nav:dashboard")}</span>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="categorization">
                        <Link to="/liquidity/categorization">
                            <CategorySettingsIcon />
                            <span>{t("nav:category_settings")}</span>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="transactions">
                        <Link to="/transactions">
                            <Icon icon="transaction" />
                            <span>{t("nav:transactions")}</span>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="employees">
                        <Link to="/employees">
                            <Icon icon="employee" />
                            <span>{t("nav:user_management")}</span>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="help" className="opacity-75">
                        <Divider className="mt-4 mb-4" />
                        <a href={HELP_CENTER_LINK} target="_blank" rel="noreferrer">
                            <Icon icon="help" />
                            <span>{t("nav:help_center")}</span>
                        </a>
                    </Menu.Item>
                </Menu>
            ) : (
                <Menu className="fixed" selectedKeys={[activeMenuItem]}>
                    <ActionMenuSidebar />
                    <Menu.Item data-testid="dashboardSideBar" key="expenses">
                        <Link to="/expenses">
                            <Icon icon="request" />
                            <span>{t("nav:dashboard")}</span>
                            <Badge count={badgeValue} showZero={false} className="-mb-5" />
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="subscriptions">
                        <Link to="/subscriptions">
                            <Icon icon="subscription" />
                            <span>{t("nav:subscriptions")}</span>
                        </Link>
                    </Menu.Item>
                    {company.cardsEnabled && (
                        <Menu.Item key="credit-cards">
                            <Link to="/credit-cards">
                                <Icon icon="credit_card" />
                                <span>{t("nav:credit_card")}</span>
                            </Link>
                        </Menu.Item>
                    )}
                    {AuthzService.isRightGrantedForLoggedInUser(RightEnum.TRANSACTION__ALL__READ) && (
                        <Menu.Item key="transactions">
                            <Link to="/transactions">
                                <Icon icon="transaction" />
                                <span>{t("nav:transactions")}</span>
                            </Link>
                        </Menu.Item>
                    )}
                    <Menu.Item key="employees">
                        <Link to="/employees">
                            <Icon icon="employee" />
                            <span>{t("nav:employees")}</span>
                        </Link>
                    </Menu.Item>
                    <Menu.Item data-testid="menuCostCentersButton" key="costCenters">
                        <Link to="/costCenters">
                            <Icon icon="cost_center" />
                            <span>{t("nav:cost_centers")}</span>
                        </Link>
                    </Menu.Item>
                    {AuthzService.isRightGrantedForLoggedInUser(RightEnum.VENDOR__ALL__READ) && (
                        <Menu.Item data-testid="menuVendorButton" key="vendors">
                            <Link to="/vendors">
                                <Icon icon="vendor" />
                                <span>{t("nav:vendors")}</span>
                            </Link>
                        </Menu.Item>
                    )}
                    {AuthzService.isRightGrantedForLoggedInUser(RightEnum.WORKFLOW__ALL__READ) && (
                        <Menu.Item data-testid="menuWorkflowButton" key="workflows">
                            <Link to="/workflows">
                                <Icon icon="workflow" />
                                <span>{t("nav:workflows")}</span>
                            </Link>
                        </Menu.Item>
                    )}
                    {AuthzService.isRightGrantedForLoggedInUser(RightEnum.LIQUIDITY__ALL__READ) && company.liquidityManagementEnabled && showLiquidity && (
                        <Menu.Item key="liquidity">
                            <Link to="/liquidity">
                                <Icon icon="liquidity" />
                                <span>{t("nav:liquidity")}</span>
                                <BetaTag />
                            </Link>
                        </Menu.Item>
                    )}
                    {AuthzService.isRightGrantedForLoggedInUser(RightEnum.REPORTING__ALL__READ) && (
                        <Menu.Item key="reports">
                            <Link to="/reports">
                                <Icon icon="report" />
                                <span>{t("nav:reporting")}</span>
                            </Link>
                        </Menu.Item>
                    )}
                    <Menu.Item key="help" className="opacity-75">
                        <Divider className="mt-4 mb-4" />
                        <a href={HELP_CENTER_LINK} target="_blank" rel="noreferrer">
                            <Icon icon="help" />
                            <span>{t("nav:help_center")}</span>
                        </a>
                    </Menu.Item>
                </Menu>
            )}
        </Sider>
    ) : (
        <React.Fragment />
    )
}

export default Sidebar
