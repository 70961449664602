import { useEffect } from "react"
import { useTranslation } from "react-i18next"

import { useServiceWorker } from "Shared/hooks/useServiceWorker"
import { RoutePathEnum } from "Shared/router/RoutePath.enum"
import { NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"

export const useAutoUpdate = () => {
    // show message to user if new version is available and schedule auto-reload
    const { waitingWorker, showReload, reloadPage } = useServiceWorker()
    const { t } = useTranslation()

    const determineUpdateTime = () => {
        switch (window.location.pathname) {
            case RoutePathEnum.ROOT:
            case RoutePathEnum.LOGIN:
                return 5
            default:
                return 30
        }
    }

    useEffect(() => {
        if (!showReload || !waitingWorker) return

        const UPDATE_TIME_SEC = determineUpdateTime()

        NotificationService.send(
            NotificationTypeEnum.INFO,
            t("notification:new_version.title"),
            t("notification:new_version.message", { time: UPDATE_TIME_SEC }),
            UPDATE_TIME_SEC * 1000,
        )

        setTimeout(() => reloadPage(), UPDATE_TIME_SEC * 1000)
    }, [showReload, waitingWorker, reloadPage, t])
}
