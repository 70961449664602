import { Company } from "@finway-group/shared/lib/models"
import { Action } from "redux"

export enum CompanyActionTypes {
    UPDATE_COMPANY = "UPDATE_COMPANY",
    FETCH_COMPANY = "FETCH_COMPANY",
    FETCH_ALL_COMPANIES = "FETCH_ALL_COMPANIES",
    CREATE_COMPANY = "CREATE_COMPANY",
    SELECT_COMPANY = "SELECT_COMPANY",
    MARK_AS_DELETED = "MARK_AS_DELETED",
}

export interface UpdateCompanyAction extends Action<typeof CompanyActionTypes.UPDATE_COMPANY> {
    type: typeof CompanyActionTypes.UPDATE_COMPANY
    company: Company
}

export interface FetchCompanyAction extends Action<typeof CompanyActionTypes.FETCH_COMPANY> {
    type: typeof CompanyActionTypes.FETCH_COMPANY
    company: Company
}

export interface FetchAllCompaniesAction extends Action<typeof CompanyActionTypes.FETCH_ALL_COMPANIES> {
    type: typeof CompanyActionTypes.FETCH_ALL_COMPANIES
    companies: Array<Company>
    company: Company | any
}

export interface CreateCompanyAction extends Action<typeof CompanyActionTypes.CREATE_COMPANY> {
    type: typeof CompanyActionTypes.CREATE_COMPANY
    company: Company
}

export interface SelectCompanyAction extends Action<typeof CompanyActionTypes.SELECT_COMPANY> {
    type: typeof CompanyActionTypes.SELECT_COMPANY
    company: Company
}

export interface MarkCompanyAsDeletedAction extends Action<typeof CompanyActionTypes.MARK_AS_DELETED> {
    type: typeof CompanyActionTypes.MARK_AS_DELETED
    company: Company
}

export type CompanyActions = UpdateCompanyAction | FetchCompanyAction | FetchAllCompaniesAction | CreateCompanyAction | SelectCompanyAction | MarkCompanyAsDeletedAction
