import {
    ApprovalProcess,
    CollectionNameEnum,
    Expense,
    ExpensePaymentFlowInformationEnum,
    ExpenseStatusEnum,
    FileUploadDuplicateData,
    RoleDependency,
    Vendor,
} from "@finway-group/shared/lib/models"
import { Button, Card, Checkbox, Divider, List, Modal } from "antd"
import { ButtonProps } from "antd/lib/button"
import { CheckboxChangeEvent } from "antd/lib/checkbox"
import i18n from "i18next"
import React from "react"

import { ApprovalProcessesIndicator } from "Components/approvalProcessesIndicator"
import { ArrowThinDownIcon } from "Components/icons"
import VendorItem from "Components/vendorItem"
import { DuplicateVendorInformationInterface, getAllMatchedAttributes } from "Shared/utils/vendor.utils"

import CompanyService from "./company.service"

export enum DuplicateInvoiceWarningEnum {
    INVOICE_NUMBER = "invoice_number",
    INVOICE_FILE = "invoice_file",
}

const DialogService = {
    confirmDuplicateDatevOnlineExport: async (duplicates: Array<any>) =>
        new Promise<boolean>((resolve) => {
            const multipleExpenses = duplicates.length > 1
            Modal.confirm({
                title: i18n.t(`confirm:export.duplicate_datev_online.title`),
                content: (
                    <div>
                        {!multipleExpenses
                            ? DialogService.buildDuplicateText({ identifier: duplicates[0].expenseNumber, collection: CollectionNameEnum.EXPENSE })
                            : duplicates.map(({ _id, expenseNumber }, index) =>
                                  DialogService.buildDuplicateText({ identifier: expenseNumber, collection: CollectionNameEnum.EXPENSE }, index < duplicates.length - 1),
                              )}
                        {i18n.t(`confirm:export.duplicate_datev_online.message_${!multipleExpenses ? "singular" : "plural"}`)}
                    </div>
                ),
                cancelText: i18n.t(`confirm:export.duplicate_datev_online.cancel`),
                type: "warning",
                okText: i18n.t(`confirm:export.duplicate_datev_online.confirm`),
                onOk() {
                    resolve(true)
                },
                onCancel() {
                    resolve(false)
                },
            })
        }),
    confirmDuplicateInvoiceFileWarning: async (conflictingData: FileUploadDuplicateData) =>
        DialogService.confirmDuplicateInvoiceWarning(DuplicateInvoiceWarningEnum.INVOICE_FILE, conflictingData),
    confirmDuplicateInvoiceNumberWarning: async (conflictingData: any) => DialogService.confirmDuplicateInvoiceWarning(DuplicateInvoiceWarningEnum.INVOICE_NUMBER, conflictingData),
    confirmDuplicateInvoiceWarning: (duplicateType: DuplicateInvoiceWarningEnum, conflictingData: FileUploadDuplicateData) =>
        new Promise<boolean>((resolve) => {
            Modal.confirm({
                title: i18n.t(`confirm:request.duplicate.${duplicateType}.title`),
                content: (
                    <div>
                        {i18n.t(`confirm:request.duplicate.${duplicateType}.message_one`)}
                        {DialogService.buildDuplicateText(conflictingData)}
                        {i18n.t(`confirm:request.duplicate.${duplicateType}.message_two`)}
                    </div>
                ),
                cancelText: i18n.t(`confirm:request.duplicate.${duplicateType}.cancel`),
                type: "warning",
                okText: i18n.t(`confirm:request.duplicate.${duplicateType}.confirm`),
                okButtonProps: {
                    "data-testid": "continueButtonDuplicateInvoiceWarning",
                } as ButtonProps,
                cancelButtonProps: {
                    "data-testid": "backButtonDuplicateInvoiceWarning",
                } as ButtonProps,
                onOk() {
                    resolve(true)
                },
                onCancel() {
                    resolve(false)
                },
            })
        }),
    buildDuplicateText: ({ identifier, collection }: Pick<FileUploadDuplicateData, "id" | "identifier" | "collection">, addComma: boolean = false) => {
        switch (collection) {
            case CollectionNameEnum.EXPENSE:
                return (
                    <span>
                        {i18n.t("label:request")} # {identifier}
                        {addComma ? ", " : ""}
                    </span>
                )
            case CollectionNameEnum.INBOX_INVOICE:
                return (
                    <span className="font-bold">
                        {i18n.t("label:inbox_invoice")} {identifier}
                        {addComma ? ", " : ""}
                    </span>
                )
            default:
                return ""
        }
    },
    confirmAutoApprover: () =>
        new Promise<boolean>((resolve) => {
            Modal.confirm({
                title: i18n.t(`confirm:request.auto_approver.title`),
                content: i18n.t(`confirm:request.auto_approver.message`),
                cancelText: i18n.t(`confirm:request.auto_approver.cancel`),
                type: "warning",
                okText: i18n.t(`confirm:request.auto_approver.confirm`),
                onOk() {
                    resolve(true)
                },
                onCancel() {
                    resolve(false)
                },
            })
        }),
    confirmAmountAdjustment: () =>
        new Promise<boolean>((resolve) => {
            Modal.confirm({
                title: i18n.t(`confirm:request.amounts_mismatch.title`),
                content: i18n.t(`confirm:request.amounts_mismatch.message`),
                cancelText: i18n.t(`confirm:request.amounts_mismatch.cancel`),
                type: "warning",
                okText: i18n.t(`confirm:request.amounts_mismatch.confirm`),
                onOk() {
                    resolve(true)
                },
                onCancel() {
                    resolve(false)
                },
            })
        }),
    confirmDeleteTransaction: () =>
        new Promise<boolean>((resolve) => {
            Modal.confirm({
                title: i18n.t(`confirm:transaction.delete.title`),
                content: i18n.t(`confirm:transaction.delete.message`),
                cancelText: i18n.t(`confirm:transaction.delete.cancel`),
                type: "warning",
                okText: i18n.t(`confirm:transaction.delete.confirm`),
                onOk() {
                    resolve(true)
                },
                onCancel() {
                    resolve(false)
                },
            })
        }),
    confirmPhysicalCardDelivery: () =>
        new Promise<boolean>((resolve) => {
            Modal.confirm({
                title: i18n.t(`confirm:cards.physical_card_delivery.title`),
                content: i18n.t(`confirm:cards.physical_card_delivery.message`),
                cancelText: i18n.t(`confirm:cards.physical_card_delivery.cancel`),
                type: "warning",
                okText: i18n.t(`confirm:cards.physical_card_delivery.confirm`),
                onOk() {
                    resolve(true)
                },
                onCancel() {
                    resolve(false)
                },
            })
        }),

    confirmDeleteLiquidityScenario: () =>
        new Promise<boolean>((resolve) => {
            Modal.confirm({
                title: i18n.t(`confirm:liquidity.delete_scenario.title`),
                content: i18n.t(`confirm:liquidity.delete_scenario.message`),
                cancelText: i18n.t(`confirm:liquidity.delete_scenario.cancel`),
                type: "warning",
                okText: i18n.t(`confirm:liquidity.delete_scenario.confirm`),
                onOk() {
                    resolve(true)
                },
                onCancel() {
                    resolve(false)
                },
            })
        }),

    confirmBuCodesDatevCompliance: () =>
        new Promise<boolean>((resolve) => {
            Modal.confirm({
                title: i18n.t(`confirm:datev.bu_code.title`),
                content: i18n.t(`confirm:datev.bu_code.message`),
                cancelText: i18n.t(`confirm:datev.bu_code.cancel`),
                type: "warning",
                okText: i18n.t(`confirm:datev.bu_code.confirm`),
                onOk() {
                    resolve(true)
                },
                onCancel() {
                    resolve(false)
                },
            })
        }),
    confirmExpenseAccountCodeDatevCompliance: () =>
        new Promise<boolean>((resolve) => {
            Modal.confirm({
                title: i18n.t(`confirm:datev.expense_account_code.title`),
                content: i18n.t(`confirm:datev.expense_account_code.message`),
                cancelText: i18n.t(`confirm:datev.expense_account_code.cancel`),
                type: "warning",
                okText: i18n.t(`confirm:datev.expense_account_code.confirm`),
                onOk() {
                    resolve(true)
                },
                onCancel() {
                    resolve(false)
                },
            })
        }),
    confirmQueuedExport: () =>
        new Promise<boolean>((resolve) => {
            Modal.confirm({
                title: i18n.t(`confirm:request.queued_export.title`),
                content: i18n.t(`confirm:request.queued_export.message`),
                cancelButtonProps: { style: { display: "none" } },
                type: "warning",
                okText: i18n.t(`confirm:request.queued_export.confirm`),
                onOk() {
                    resolve(true)
                },
                onCancel() {
                    resolve(false)
                },
            })
        }),

    confirmDeleteRole: () =>
        new Promise<boolean>((resolve) => {
            Modal.confirm({
                title: i18n.t(`confirm:delete_role.title`),
                content: i18n.t(`confirm:delete_role.message`),
                cancelText: i18n.t(`confirm:delete_role.cancel`),
                type: "warning",
                okText: i18n.t(`confirm:delete_role.confirm`),
                onOk() {
                    resolve(true)
                },
                onCancel() {
                    resolve(false)
                },
            })
        }),
    confirmRestoreRole: () =>
        new Promise<boolean>((resolve) => {
            Modal.confirm({
                title: i18n.t("confirm:employee.restore.title"),
                content: i18n.t("confirm:employee.restore.message"),
                cancelText: i18n.t("confirm:employee.restore.cancel"),
                type: "warning",
                okText: i18n.t("confirm:employee.restore.confirm"),
                onOk() {
                    resolve(true)
                },
                onCancel() {
                    resolve(false)
                },
            })
        }),
    confirmPaymentFlowInformationResetOnCardIssue: () =>
        new Promise<boolean>((resolve) => {
            Modal.confirm({
                title: i18n.t(`confirm:cards.payment_flow_info_reset.title`),
                content: i18n.t(`confirm:cards.payment_flow_info_reset.message`),
                cancelText: i18n.t(`confirm:cards.payment_flow_info_reset.cancel`),
                type: "warning",
                okText: i18n.t(`confirm:cards.payment_flow_info_reset.confirm`),
                onOk() {
                    resolve(true)
                },
                onCancel() {
                    resolve(false)
                },
            })
        }),
    confirmPaymentFlowInformationReportingOnlyWarningOnCreation: () =>
        new Promise<boolean>((resolve) => {
            Modal.confirm({
                title: i18n.t(`confirm:cards.payment_flow_info_reporting_only_on_creation.title`),
                content: i18n.t(`confirm:cards.payment_flow_info_reporting_only_on_creation.message`),
                cancelText: i18n.t(`confirm:cards.payment_flow_info_reporting_only_on_creation.cancel`),
                type: "warning",
                okText: i18n.t(`confirm:cards.payment_flow_info_reporting_only_on_creation.confirm`),
                onOk() {
                    resolve(true)
                },
                onCancel() {
                    resolve(false)
                },
            })
        }),
    confirmPaymentFlowInformationAlreadyPaidWarningOnReview: (paymentFlowInfo: ExpensePaymentFlowInformationEnum) =>
        new Promise<boolean>((resolve) => {
            Modal.confirm({
                title: i18n.t(`confirm:cards.payment_flow_info_${paymentFlowInfo}_on_review.title`),
                content: i18n.t(`confirm:cards.payment_flow_info_${paymentFlowInfo}_on_review.message`),
                cancelText: i18n.t(`confirm:cards.payment_flow_info_${paymentFlowInfo}_on_review.cancel`),
                type: "warning",
                okText: i18n.t(`confirm:cards.payment_flow_info_${paymentFlowInfo}_on_review.confirm`),
                onOk() {
                    resolve(true)
                },
                onCancel() {
                    resolve(false)
                },
            })
        }),
    confirmPaymentFlowsOnBulkReview: () =>
        new Promise<boolean>((resolve) => {
            Modal.confirm({
                title: i18n.t(`confirm:cards.payment_flow_info_on_bulk_review.title`),
                content: i18n.t(`confirm:cards.payment_flow_info_on_bulk_review.message`),
                cancelText: i18n.t(`confirm:cards.payment_flow_info_on_bulk_review.cancel`),
                type: "warning",
                okText: i18n.t(`confirm:cards.payment_flow_info_on_bulk_review.confirm`),
                onOk() {
                    resolve(true)
                },
                onCancel() {
                    resolve(false)
                },
            })
        }),
    confirmCreationOfPotentialDuplicateVendor: (
        potentialDuplicateVendor: Vendor,
        vendorsInformation: Array<DuplicateVendorInformationInterface>,
        enableToChooseAnotherVendor: boolean = true,
    ) =>
        new Promise<{ resolved: boolean; chosenVendor?: Vendor }>((resolve) => {
            Modal.confirm({
                width: 480,
                title: i18n.t(`confirm:vendor.duplicate.title`),
                content: (
                    <div>
                        <p>{i18n.t("confirm:vendor.duplicate.message")}</p>
                        <div className="-ml-40">
                            <h3 className="mt-20 text-base">{i18n.t("label:new_vendor")}</h3>
                            <div className="flex border shadow align-middle py-8 px-16 mb-20">
                                <VendorItem
                                    showVendorPendingSign={false}
                                    vendorInformation={{ vendor: potentialDuplicateVendor, matchedAttributes: getAllMatchedAttributes(vendorsInformation) }}
                                />
                            </div>
                            <h3 className="mt-10 text-base">{i18n.t("label:potential_duplicate_vendors")}</h3>
                            <List
                                size="small"
                                bordered
                                className="shadow"
                                dataSource={vendorsInformation}
                                renderItem={(vendorInformation) => (
                                    <List.Item>
                                        {
                                            <div className="flex justify-between items-center w-full overflow-auto">
                                                <VendorItem vendorInformation={vendorInformation} />
                                                {enableToChooseAnotherVendor && (
                                                    <Button
                                                        className="text-text ml-20"
                                                        onClick={() => {
                                                            resolve({ resolved: true, chosenVendor: new Vendor(vendorInformation.vendor) })
                                                            Modal.destroyAll()
                                                        }}
                                                    >
                                                        {i18n.t("action:choose_this_instead")}
                                                    </Button>
                                                )}
                                            </div>
                                        }
                                    </List.Item>
                                )}
                            />
                        </div>
                    </div>
                ),
                type: "warning",
                okText: i18n.t(`action:vendor.submit`),
                okButtonProps: { "data-testid": "okButtonDuplicateVendor" } as ButtonProps,
                onOk() {
                    resolve({ resolved: true })
                },
                onCancel() {
                    resolve({ resolved: false })
                },
            })
        }),
    confirmExpenseStatusReset: (expense: Expense) => {
        const determineContent = () => {
            if (expense.isPending() || expense.isInReview()) return "confirm:request.reset_status.message.pending"
            if (!!expense.getUsedOrActiveDiscount() && expense.status === ExpenseStatusEnum.REVIEWED) return "confirm:request.reset_status.message.discount_applied"

            return "confirm:request.reset_status.message.default"
        }

        return new Promise<boolean>((resolve) => {
            Modal.confirm({
                title: i18n.t("confirm:request.reset_status.title"),
                content: i18n.t(determineContent()),
                cancelText: i18n.t("confirm:request.reset_status.cancel"),
                type: "warning",
                okText: i18n.t("confirm:request.reset_status.confirm"),
                onOk() {
                    resolve(true)
                },
                onCancel() {
                    resolve(false)
                },
            })
        })
    },
    confirmFolderStatusReset: (isPendingExpense: boolean) =>
        new Promise<boolean>((resolve) => {
            Modal.confirm({
                title: i18n.t("confirm:request.reset_status_folder.title"),
                content: isPendingExpense ? i18n.t("confirm:request.reset_status_folder.pending_request_message") : i18n.t("confirm:request.reset_status_folder.message"),
                cancelText: i18n.t("confirm:request.reset_status_folder.cancel"),
                type: "warning",
                okText: i18n.t("confirm:request.reset_status_folder.confirm"),
                onOk() {
                    resolve(true)
                },
                onCancel() {
                    resolve(false)
                },
            })
        }),
    confirmPerDiemLessThan8Hours: () =>
        new Promise<boolean>((resolve) => {
            Modal.confirm({
                title: i18n.t(`confirm:request.per_diem_less_than_8h.title`),
                content: i18n.t(`confirm:request.per_diem_less_than_8h.message`),
                type: "warning",
                cancelButtonProps: { style: { display: "none" } },
                okText: i18n.t(`confirm:request.per_diem_less_than_8h.confirm`),
                onOk() {
                    resolve(true)
                },
            })
        }),

    confirmAdminNotOwnApprovalModal: async (approverName: string, isFolder = false) =>
        new Promise<boolean>((resolve) => {
            Modal.confirm({
                title: i18n.t(`confirm:request.admin_not_own_approval.title`),
                content: i18n.t(`${isFolder ? "confirm:request.admin_not_own_approval.folder_approval_message" : "confirm:request.admin_not_own_approval.message"}`, {
                    name: approverName,
                }),
                type: "warning",
                okText: i18n.t(`confirm:request.admin_not_own_approval.confirm`),
                cancelText: i18n.t(`confirm:request.admin_not_own_approval.cancel`),
                onOk() {
                    resolve(true)
                },
                onCancel() {
                    resolve(false)
                },
            })
        }),
    confirmExpenseStatusAdvancement: () =>
        new Promise<{ resolved: boolean; advanceStatus?: boolean }>((resolve) => {
            let advanceStatus = true
            const onChange = (e: CheckboxChangeEvent) => {
                advanceStatus = e.target.checked
            }
            Modal.confirm({
                title: i18n.t(`confirm:request.status_advancement.title`),
                content: (
                    <div>
                        <span className="text-text"> {i18n.t(`confirm:request.status_advancement.message_one`)}</span>

                        <Checkbox defaultChecked={advanceStatus} onChange={onChange} className="mt-20">
                            <span className="text-text">{i18n.t(`confirm:request.status_advancement.message_two`)}</span>
                        </Checkbox>
                    </div>
                ),
                type: "warning",
                okText: i18n.t(`confirm:request.per_diem_less_than_8h.confirm`),
                onOk() {
                    resolve({ resolved: true, advanceStatus })
                },
                onCancel() {
                    resolve({ resolved: false })
                },
            })
        }),
    confirmRecalculateApprovalProcesses: () =>
        new Promise<boolean>((resolve) => {
            Modal.confirm({
                title: i18n.t("confirm:approval_process.recalculate_in_progress.title"),
                content: i18n.t("confirm:approval_process.recalculate_in_progress.update_or_delete_workflow_message"),
                style: { marginTop: "20px" },
                onOk() {
                    resolve(true)
                },
                onCancel() {
                    resolve(false)
                },
            })
        }),
    confirmExpenseGobdCompliantInvoiceFlagging: () =>
        new Promise<boolean>((resolve) => {
            Modal.confirm({
                title: i18n.t(`confirm:request.gobd_invoice_compliance.flagging.title`),
                content: i18n.t(`confirm:request.gobd_invoice_compliance.flagging.message`),
                cancelText: i18n.t(`confirm:request.gobd_invoice_compliance.flagging.cancel`),
                type: "warning",
                okText: i18n.t(`confirm:request.gobd_invoice_compliance.flagging.confirm`),
                onOk() {
                    resolve(true)
                },
                onCancel() {
                    resolve(false)
                },
            })
        }),
    confirmTagDelete: (tagName: string, count: number) =>
        new Promise<boolean>((resolve) => {
            Modal.confirm({
                title: i18n.t("confirm:expenseTag.delete.title"),
                type: "warning",
                content: i18n.t("confirm:expenseTag.delete.message", { expenseTagName: tagName, count }),
                cancelText: i18n.t("confirm:expenseTag.delete.cancel"),
                okText: i18n.t("confirm:expenseTag.delete.confirm"),
                zIndex: 3000,
                async onOk() {
                    resolve(true)
                },
            })
        }),

    confirmApprovalProcessReplacement: (
        title: string,
        approvalProcessChangeBlocks: Array<{ message: string; currentApprovalProcess: ApprovalProcess; newApprovalProcess: ApprovalProcess }>,
    ) =>
        new Promise<boolean>((resolve) => {
            const renderApprovalChangeBlock = (message: string, currentApprovalProcess: ApprovalProcess, newApprovalProcess: ApprovalProcess) => (
                <div className="bg-gray-150 p-30 ant-modal-confirm-content--grey-div">
                    {newApprovalProcess && <p className="text-text approval-process__message">{message}</p>}
                    <div className="flex flex-col flex-1 items-center gap-8">
                        <div className="w-full">
                            <h2 className="p-0 mb-18 text-text">{i18n.t("confirm:approval_process.recalculate_in_progress.current_workflow_title")}</h2>
                            <ApprovalProcessesIndicator approvalProcesses={[currentApprovalProcess]} showWorkflows={false} />
                        </div>
                        <ArrowThinDownIcon />
                        <div className="mr-2 w-full updated-workflow">
                            <h2 className="text-finway-green-light2 mb-18">{i18n.t("confirm:approval_process.recalculate_in_progress.updated_workflow_title")}</h2>
                            <ApprovalProcessesIndicator approvalProcesses={[newApprovalProcess]} showWorkflows={false} />
                        </div>
                    </div>
                </div>
            )

            Modal.confirm({
                title,
                className: "ant-modal--very-large-fit ant-modal--confirm-approval-process-replacement",
                content: (
                    <div className="ant-modal-confirm-content__container">
                        <div className="flex justify-center border-b-1 border-solid gap-20 border-gray-150">
                            {approvalProcessChangeBlocks.map(({ message, currentApprovalProcess, newApprovalProcess }) =>
                                renderApprovalChangeBlock(message, currentApprovalProcess, newApprovalProcess),
                            )}
                        </div>
                        <hr />
                        <p className="text-right pr-10 approval-question">{i18n.t(`confirm:approval_process.recalculate_in_progress.continue_process`)}</p>
                    </div>
                ),

                type: "warning",
                okText: i18n.t(`confirm:request.per_diem_less_than_8h.confirm`),
                onOk() {
                    resolve(true)
                },
                onCancel() {
                    resolve(false)
                },
            })
        }),
    confirmNonEuroWallet: () =>
        new Promise<boolean>((resolve) => {
            Modal.confirm({
                title: i18n.t(`confirm:cards.non_euro_wallet.title`),
                content: i18n.t(`confirm:cards.non_euro_wallet.message`),
                cancelText: i18n.t(`confirm:cards.non_euro_wallet.cancel`),
                type: "warning",
                okText: i18n.t(`confirm:cards.non_euro_wallet.confirm`),
                onOk() {
                    resolve(true)
                },
                onCancel() {
                    resolve(false)
                },
            })
        }),
    confirmRequestArchive: (showPluralText: boolean = false) =>
        new Promise<boolean>((resolve) => {
            if (!CompanyService.doesCompanyEnforceGobdCompliantInvoice()) {
                resolve(true)
                return
            }
            Modal.confirm({
                title: i18n.t(`confirm:request.archive.title${showPluralText ? "_plural" : ""}`),
                content: i18n.t(`confirm:request.archive.message${showPluralText ? "_plural" : ""}`),
                cancelText: i18n.t(`confirm:request.archive.cancel`),
                type: "warning",
                okText: i18n.t(`confirm:request.archive.confirm`),
                onOk() {
                    resolve(true)
                },
                onCancel() {
                    resolve(false)
                },
            })
        }),
    errorApprovalRightRemoval: (roleDependencies: Array<RoleDependency>) =>
        new Promise<boolean>((resolve) => {
            Modal.error({
                className: "ant-modal--large",
                title: i18n.t(`confirm:edit_right_restricted.title`),
                content: (() => {
                    // Helper function to group role depenencies by company
                    const groupByCompany = (items: Array<any>) =>
                        items.reduce((result: any, item: RoleDependency["expenses"][0] | RoleDependency["workflows"][0]) => {
                            const companyName = item.company.name
                            if (!result[companyName]) result[companyName] = []
                            result[companyName].push(item)
                            return result
                        }, {})

                    const renderRightEditRestrictionPerUser = (roleDependency: RoleDependency) => {
                        const { user, workflows, expenses } = roleDependency

                        const groupedExpenses: Record<string, RoleDependency["expenses"]> = groupByCompany(expenses)
                        const groupedWorkflows: Record<string, RoleDependency["workflows"]> = groupByCompany(workflows)
                        if (Object.keys(groupedExpenses).length === 0 && Object.keys(groupedWorkflows).length === 0) return null

                        return (
                            <div className="m-10">
                                <p className="font-bold">
                                    {user.firstName} {user.lastName} ({user.email})
                                </p>

                                {Object.entries(groupedExpenses).map(([companyName, expenseDeps]) => (
                                    <ul key={companyName} className="ml-10">
                                        <li className="list-disc">
                                            {i18n.t(`confirm:edit_right_restricted.approval_right.expenses`, {
                                                list: expenseDeps.map((expenseDep) => expenseDep.expenseNumber).join(", "),
                                                companyName,
                                            })}
                                        </li>
                                    </ul>
                                ))}

                                {Object.entries(groupedWorkflows).map(([companyName, workflowDeps]) => (
                                    <ul key={companyName} className="ml-10">
                                        <li className="list-disc">
                                            {i18n.t(`confirm:edit_right_restricted.approval_right.workflows`, {
                                                list: workflowDeps.map((workflowDep) => workflowDep.order).join(", "),
                                                companyName,
                                            })}
                                        </li>
                                    </ul>
                                ))}
                            </div>
                        )
                    }

                    const renderRightEditRestrictionPanel = (roleDependencies: Array<RoleDependency>) => {
                        const title = i18n.t(`confirm:edit_right_restricted.approval_right.title`)
                        const message = i18n.t(`confirm:edit_right_restricted.approval_right.message`)
                        const editRestrictionUserPanels = roleDependencies
                            .map((roleDependency) => renderRightEditRestrictionPerUser(roleDependency))
                            .filter((panel) => panel !== null)
                        if (!editRestrictionUserPanels.length) return null

                        return (
                            <Card>
                                <p className="font-bold">{title}</p>
                                <p className="mb-10">{message}</p>
                                {editRestrictionUserPanels}
                            </Card>
                        )
                    }

                    return (
                        <div className="ml-2" style={{ marginLeft: 0 }}>
                            <p>{i18n.t(`confirm:edit_right_restricted.message`)}</p>
                            <Divider />
                            {renderRightEditRestrictionPanel(roleDependencies)}
                        </div>
                    )
                })(),
                okType: "default",
                type: "error",
                okText: i18n.t(`confirm:edit_right_restricted.confirm`),
                onOk() {
                    resolve(false)
                },
            })
        }),

    confirmSubmitExpiredDiscount: () =>
        new Promise<boolean>((resolve) => {
            Modal.confirm({
                title: i18n.t("confirm:discount.apply_expired.title"),
                content: i18n.t("confirm:discount.apply_expired.message"),
                okText: i18n.t("confirm:discount.apply_expired.confirm"),
                cancelText: i18n.t("confirm:discount.apply_expired.cancel"),

                onOk() {
                    resolve(true)
                },
                onCancel() {
                    resolve(false)
                },
            })
        }),
    confirmUnsavedChanges: () =>
        new Promise<boolean>((resolve) => {
            Modal.confirm({
                className: "ant-modal--unsaved-changes",
                title: i18n.t(`confirm:unsaved_changes.title`),
                content: i18n.t(`confirm:unsaved_changes.message`),
                cancelText: i18n.t(`confirm:unsaved_changes.cancel`),
                type: "warning",
                okText: i18n.t(`confirm:unsaved_changes.confirm`),
                okButtonProps: { "data-testid": "confirmUnsavedChangesButton" } as ButtonProps,
                onOk() {
                    resolve(true)
                },
                onCancel() {
                    resolve(false)
                },
            })
        }),
    confirmSync: () =>
        new Promise<boolean>((resolve) => {
            Modal.confirm({
                title: i18n.t(`confirm:no_request_sync.title`),
                content: i18n.t(`confirm:no_request_sync.message`),
                cancelText: i18n.t(`confirm:no_request_sync.cancel`),
                type: "warning",
                okText: i18n.t(`confirm:no_request_sync.confirm`),
                onOk() {
                    resolve(true)
                },
                onCancel() {
                    resolve(false)
                },
                okButtonProps: {
                    "data-testid": "confirmSyncOkButton",
                } as ButtonProps,
            })
        }),

    confirmResetDemoData: () =>
        new Promise<boolean>((resolve) => {
            Modal.confirm({
                title: i18n.t(`confirm:reset_demo_data.title`),
                content: i18n.t(`confirm:reset_demo_data.message`),
                cancelText: i18n.t(`confirm:reset_demo_data.cancel`),
                type: "warning",
                okText: i18n.t(`confirm:reset_demo_data.confirm`),
                onOk() {
                    resolve(true)
                },
                onCancel() {
                    resolve(false)
                },
            })
        }),

    confirmAssignInvoiceToUser: async (name: string) =>
        new Promise<boolean>((resolve) => {
            Modal.confirm({
                title: i18n.t(`confirm:inbox_invoice.assign_colleague.title`, {
                    name,
                }),
                content: i18n.t(`confirm:inbox_invoice.assign_colleague.message`, {
                    name,
                }),
                type: "warning",
                okText: i18n.t(`confirm:inbox_invoice.assign_colleague.confirm`),
                cancelText: i18n.t(`confirm:inbox_invoice.assign_colleague.cancel`),
                onOk() {
                    resolve(true)
                },
                onCancel() {
                    resolve(false)
                },
            })
        }),
    confirmUnassignInvoiceToUser: async (name: string) =>
        new Promise<boolean>((resolve) => {
            Modal.confirm({
                title: i18n.t(`confirm:inbox_invoice.unassign_colleague.title`, {
                    name,
                }),
                content: i18n.t(`confirm:inbox_invoice.unassign_colleague.message`, {
                    name,
                }),
                type: "warning",
                okText: i18n.t(`confirm:inbox_invoice.unassign_colleague.confirm`),
                cancelText: i18n.t(`confirm:inbox_invoice.unassign_colleague.cancel`),
                onOk() {
                    resolve(true)
                },
                onCancel() {
                    resolve(false)
                },
            })
        }),
    confirmDatevWarningPromise: (multiple = false) =>
        new Promise<void>((resolve, reject) => {
            Modal.confirm({
                title: i18n.t("confirm:request.datev_warning.title"),
                content: i18n.t(`confirm:request.datev_warning.message${multiple ? "_multiple" : ""}`),
                cancelText: i18n.t("confirm:request.datev_warning.cancel"),
                type: "warning",
                okText: i18n.t("confirm:request.datev_warning.confirm"),
                onOk() {
                    resolve()
                },
                onCancel() {
                    reject()
                },
            })
        }),
}

export default DialogService
