import { CommentItem, RightEnum, Vendor } from "@finway-group/shared/lib/models"
import { ActionCreator, Dispatch } from "redux"

import { AuthzService, VendorService } from "Shared/services"
import { ThunkResult } from "Shared/store"
import { TablesEnum } from "Shared/store/reducers/tableConfigReducer"

import { CancelFetchAction, FetcherActionTypes } from "../fetcher/fetcherTypes"
import { refetchTable } from "../tables/tableActions"
import { DeleteVendorAction, FetchAllVendorsAction, FetchOneVendorAction, NewVendorAction, SyncVendorAction, UpdateVendorAction, VendorActionTypes } from "./vendorTypes"

export const fetchAllVendors = () => async (dispatch: Dispatch) => {
    const vendors: Array<Vendor> = await VendorService.fetchAllVendors()

    const cancelFetchAction: CancelFetchAction = {
        type: FetcherActionTypes.CANCEL_FETCH,
        isFetchCancelled: true,
    }

    dispatch(cancelFetchAction)

    const fetchAllVendorsAction: FetchAllVendorsAction = {
        type: VendorActionTypes.FETCH_ALL_VENDORS,
        vendors,
    }

    dispatch(fetchAllVendorsAction)

    return vendors
}

export const fetchOneVendor: ActionCreator<ThunkResult<Vendor>> = (id: string) => async (dispatch: Dispatch) => {
    const vendor = await VendorService.fetchOneVendor(id)
    const fetchOneVendorsAction: FetchOneVendorAction = {
        type: VendorActionTypes.FETCH_ONE_VENDOR,
        vendor,
    }

    dispatch(fetchOneVendorsAction)
    return vendor
}

export const SyncVendor: ActionCreator<ThunkResult<Vendor>> = (id: string) => async (dispatch: Dispatch) => {
    const vendor = await VendorService.fetchOneVendor(id)
    const syncVendorsAction: SyncVendorAction = {
        type: VendorActionTypes.SYNC_VENDOR,
        vendor,
    }

    dispatch(syncVendorsAction)
    return vendor
}

// Check if the invoice is of type Vendor
export const setVendor: ActionCreator<ThunkResult<void>> = (vendor: Vendor) => async (dispatch: Dispatch) => {
    const fetchOneVendorsAction: FetchOneVendorAction = {
        type: VendorActionTypes.FETCH_ONE_VENDOR,
        vendor,
    }

    dispatch(fetchOneVendorsAction)
}

export const updateVendor = (id: string, updateVendor: Vendor) => async (dispatch: Dispatch) => {
    if (!AuthzService.isRightGrantedForLoggedInUser(RightEnum.VENDOR__ALL__UPDATE)) delete updateVendor.rule

    const vendor = await VendorService.update(id, updateVendor)
    const updateVendorAction: UpdateVendorAction = {
        type: VendorActionTypes.UPDATE_VENDOR,
        vendor,
    }

    dispatch(updateVendorAction)
    dispatch(refetchTable(TablesEnum.VENDORS))
    return vendor
}

export const updateComments: ActionCreator<ThunkResult<Vendor>> = (id: string, comments: Array<CommentItem>) => async (dispatch: Dispatch) => {
    const vendor = await VendorService.updateComment(id, comments)
    const updateVendorCommentsAction: UpdateVendorAction = {
        type: VendorActionTypes.UPDATE_VENDOR,
        vendor,
    }

    dispatch(updateVendorCommentsAction)
    return vendor
}

export const updateVendorRule = (id: string, rule: any) => async (dispatch: Dispatch) => {
    const vendor = await VendorService.updateVendorRule(id, rule)
    const updateVendorRuleAction: UpdateVendorAction = {
        type: VendorActionTypes.UPDATE_VENDOR,
        vendor,
    }

    dispatch(updateVendorRuleAction)
    dispatch(refetchTable(TablesEnum.VENDORS))
    return vendor
}

export const updateVendorContracts = (id: string, contracts: Vendor["contracts"]) => async (dispatch: Dispatch) => {
    const vendor = await VendorService.updateVendorContracts(id, contracts)
    const updateVendorAction: UpdateVendorAction = {
        type: VendorActionTypes.UPDATE_VENDOR,
        vendor,
    }

    dispatch(updateVendorAction)
    dispatch(refetchTable(TablesEnum.VENDORS))
    return vendor
}

export const createVendor = (newVendor: Vendor) => async (dispatch: Dispatch) => {
    const vendor = await VendorService.create(newVendor)
    const createVendorAction: NewVendorAction = {
        type: VendorActionTypes.NEW_VENDOR,
        vendor,
    }

    dispatch(createVendorAction)
    dispatch(refetchTable(TablesEnum.VENDORS))
    return vendor
}

export const approveVendorStatus = (id: string) => async (dispatch: Dispatch) => {
    const vendor = await VendorService.approveVendorStatus(id)
    const updateVendorAction: UpdateVendorAction = {
        type: VendorActionTypes.UPDATE_VENDOR,
        vendor,
    }

    dispatch(updateVendorAction)
    dispatch(refetchTable(TablesEnum.VENDORS))
    return vendor
}

export const deleteVendor = (id: string) => async (dispatch: Dispatch) => {
    await VendorService.deleteOneVendor(id)
    const deleteVendorAction: DeleteVendorAction = {
        type: VendorActionTypes.DELETE_VENDOR,
        vendorId: id,
    }

    dispatch(deleteVendorAction)
    dispatch(refetchTable(TablesEnum.VENDORS))
}
