import { TransactionCategory, TransactionCategoryTypeEnum } from "@finway-group/shared/lib/models"
import axios from "axios"

import store from "Shared/store"

const ENDPOINT = "transaction-category"

const TransactionCategoryService = {
    getTransactionCategoryById: (id: string | undefined): TransactionCategory =>
        store.getState().transactionCategories.items.find((transactionCategory: TransactionCategory) => transactionCategory.id === id),

    fetchOneTransactionCategory: async (id: string) => {
        const { data } = await axios.get(`${ENDPOINT}/${id}`)
        return new TransactionCategory(data)
    },
    fetchAllTransactionCategories: async () => {
        const { data } = await axios.get(`${ENDPOINT}?limit=1000&sortBy=asc(order)`)
        return data.map((e: any) => new TransactionCategory(e))
    },
    create: async (createData: any) => {
        const { data } = await axios.post(`/${ENDPOINT}`, createData)
        return {
            createdTransactionCategory: new TransactionCategory(data.createdTransactionCategory),
            updatedParentTransactionCategory: new TransactionCategory(data.updatedParentTransactionCategory),
        }
    },
    update: async (id: string, updateData: any) => {
        const { data } = await axios.put(`/${ENDPOINT}/${id}`, updateData)
        return new TransactionCategory(data)
    },
    delete: async (id: string) => {
        const { data } = await axios.delete(`/${ENDPOINT}/${id}`)
        return {
            deletedTransactionCategoriesIds: data.deletedTransactionCategoriesIds,
            updatedParentTransactionCategory: new TransactionCategory(data.updatedParentTransactionCategory),
        }
    },
}

export default TransactionCategoryService
