import { PaymentSettings } from "@finway-group/shared/lib/models"
import { IbanElement } from "@stripe/react-stripe-js"
import { StripeElementChangeEvent } from "@stripe/stripe-js"
import { Button, Checkbox, Col, Form, Input, Row, Tooltip } from "antd"
import { Store } from "antd/lib/form/interface"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"

import { ConditionalWrapper } from "Components/conditionalWrapper"
import RecurringDebitAgreementModal from "Components/modals/directDebitAgreement.modal"
import { useModal } from "Shared/context/modal.context"
import { getTooltipPopupContainer } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

import { EmailFormRule } from "./rules"
import CorporateRules from "./rules/corporate.rules"

interface PaymentMethodSepaDebitFormInterface {
    paymentSettings: PaymentSettings
    isEditable: boolean
    onSubmit: (values: Store) => void
    onCancel: () => void
}

const PaymentMethodSepaDebitForm = ({ paymentSettings, isEditable, onSubmit, onCancel }: PaymentMethodSepaDebitFormInterface) => {
    const { t, i18n } = useTranslation()
    const [formInstance] = Form.useForm()
    // TODO: extract into own rule set
    const rules = CorporateRules()

    // checkbox will be disabled to check until user reads the sepa agreement
    const [isCheckboxEnabled, setIsCheckboxEnabled] = useStateIfMounted(false)
    const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useStateIfMounted<any>(false)
    const [requiredFieldsComplete, setRequiredFieldsComplete] = useStateIfMounted({
        name: false,
        email: false,
        iban: false,
        invoiceAddress: false,
        postcode: false,
        city: false,
        country: false,
        isSepaAccepted: false,
    })

    const { showModal, hideModal } = useModal()

    // form initialization
    useEffect(() => {
        formInstance.setFieldsValue({
            name: paymentSettings.paymentMethod?.sepaDebit?.name,
            email: paymentSettings.paymentMethod?.email,
            secondEmail: paymentSettings.paymentMethod?.secondEmail,
            invoiceAddress: paymentSettings.paymentMethod?.sepaDebit?.invoiceAddress.line1,
            secondAddress: paymentSettings.paymentMethod?.sepaDebit?.invoiceAddress.line2,
            postcode: paymentSettings.paymentMethod?.sepaDebit?.invoiceAddress.postcode,
            city: paymentSettings.paymentMethod?.sepaDebit?.invoiceAddress.city,
            country: paymentSettings.paymentMethod?.sepaDebit?.invoiceAddress.country,
        })

        setRequiredFieldsComplete({
            name: !!paymentSettings.paymentMethod?.sepaDebit?.name,
            email: !!paymentSettings.paymentMethod?.email,
            iban: false,
            invoiceAddress: !!paymentSettings.paymentMethod?.sepaDebit?.invoiceAddress,
            postcode: !!paymentSettings.paymentMethod?.sepaDebit?.invoiceAddress.postcode,
            city: !!paymentSettings.paymentMethod?.sepaDebit?.invoiceAddress.city,
            country: !!paymentSettings.paymentMethod?.sepaDebit?.invoiceAddress.country,
            isSepaAccepted: false,
        })
    }, [])

    // enables save button when all required fields are filled
    useEffect(() => {
        if (Object.values(requiredFieldsComplete).every((value) => value)) {
            setIsSaveButtonEnabled(true)
        } else if (isSaveButtonEnabled) {
            setIsSaveButtonEnabled(false)
        }
    }, [requiredFieldsComplete])

    const handleStripeIbanElementChange = (event: StripeElementChangeEvent) => {
        if (event.complete) {
            setRequiredFieldsComplete({ ...requiredFieldsComplete, iban: true })
        } else if (requiredFieldsComplete.iban === true) {
            setRequiredFieldsComplete({ ...requiredFieldsComplete, iban: false })
        }
    }

    const handleRequiredFieldChange = async (event: any) => {
        const field = event.target?.id
        const value = event.target?.value
        const checked = event.target?.checked
        if (!value && !checked) {
            setRequiredFieldsComplete({ ...requiredFieldsComplete, [field]: false })
            return
        }

        try {
            await formInstance.validateFields([field])
            setRequiredFieldsComplete({ ...requiredFieldsComplete, [field]: true })
        } catch (err) {
            if ((requiredFieldsComplete as any)[field]) setRequiredFieldsComplete({ ...requiredFieldsComplete, [field]: false })
        }
    }

    const handleShowDirectDebitAgreement = () => {
        showModal(RecurringDebitAgreementModal, false, { isShowing: true, onConfirm: handleConfirmDebitAgreement })
    }

    const handleConfirmDebitAgreement = () => {
        setRequiredFieldsComplete({ ...requiredFieldsComplete, isSepaAccepted: true })
        setIsCheckboxEnabled(true)
        hideModal(RecurringDebitAgreementModal, false, {})
    }

    const acceptSepaFooter = () => (
        <div className="flex items-center">
            <ConditionalWrapper
                condition={!isCheckboxEnabled}
                wrapper={(children: any) => (
                    <Tooltip getPopupContainer={getTooltipPopupContainer} title={t("tooltips:read_direct_debit_agreement")} placement="bottom">
                        {children}
                    </Tooltip>
                )}
            >
                <Form.Item name="isSepaAccepted" required className="mb-0 flex flex-row items-center" label={<></>}>
                    <Checkbox
                        className="finway-green-checkbox"
                        checked={requiredFieldsComplete.isSepaAccepted}
                        disabled={!isCheckboxEnabled}
                        onChange={handleRequiredFieldChange}
                        onClick={(e: any) => setRequiredFieldsComplete({ ...requiredFieldsComplete, isSepaAccepted: e.target.checked })}
                    >
                        <span className="text-text-dark">
                            {i18n.language === "en" ? (
                                <>
                                    I agree to the current <a onClick={handleShowDirectDebitAgreement}>direct debit agreement</a>
                                </>
                            ) : (
                                <>
                                    Ich stimme der aktuellen <a onClick={handleShowDirectDebitAgreement}>Vereinbarung zur Lastschrift</a> zu.
                                </>
                            )}
                        </span>
                    </Checkbox>
                </Form.Item>
            </ConditionalWrapper>
        </div>
    )

    const footerRow = (firstColumnContent: any) => (
        <Row className="flex justify-between gap-10">
            <Col className="flex grow-0 items-center space-x-10">{firstColumnContent()}</Col>
            <Col className="flex grow-0 items-center space-x-10">
                <Button onClick={onCancel}>{t("action:payment_method.cancel")}</Button>
                <Button type="primary" disabled={!isSaveButtonEnabled} loading={false} onClick={() => formInstance.submit()}>
                    {t("action:payment_method.save")}
                </Button>
            </Col>
        </Row>
    )

    return (
        <Form form={formInstance} id="payment-method-sepa-debit-form" /* contains custom styles */ layout="vertical" onFinish={onSubmit} className="m-2" validateTrigger="onBlur">
            <div className="animation-appear">
                <Row gutter={[16, 16]} align="bottom">
                    <Col span={24} md={12}>
                        <Form.Item name="name" label={t("label:payment_settings.name")} required rules={[{ required: true }]}>
                            <Input className="w-full" disabled={!isEditable} onChange={handleRequiredFieldChange} />
                        </Form.Item>
                    </Col>

                    <Col span={24} md={12}>
                        <Form.Item name="email" className="w-full" label={t("label:payment_settings.email")} required rules={EmailFormRule}>
                            <Input onChange={handleRequiredFieldChange} disabled={!isEditable} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[16, 16]} align="bottom">
                    {/* 
                    // TODO: enable when implemented - currently not yet supported by stripe API (dashboard only)
                    // See: https://stackoverflow.com/q/65685098
                    <Col span={24} md={12}>
                        <Form.Item name="secondEmail" className="w-full" label={t("label:payment_settings.second_email")} rules={[{ type: "email" }]}>
                            <Input disabled={!isEditable} />
                        </Form.Item> 
                    </Col> 
                    */}

                    <Col span={24} md={12}>
                        {/* No name on "iban" field, is handled by stripe */}
                        <Form.Item className="w-full border-b-1" label={t("label:payment_settings.iban")} required>
                            {isEditable ? (
                                <IbanElement
                                    options={{
                                        supportedCountries: ["SEPA"],
                                        placeholderCountry: "DE",
                                        classes: { base: "w-full border-2 border-transparent p-8", focus: "border-2 border-lightsteelblue shadow-md" },
                                        disabled: false,
                                    }}
                                    onChange={handleStripeIbanElementChange}
                                />
                            ) : (
                                <Input value={`******************${paymentSettings.paymentMethod?.sepaDebit?.last4Characters}`} className="w-full" disabled />
                            )}
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[16, 16]} align="bottom">
                    <Col span={24} md={12}>
                        <Form.Item name="invoiceAddress" label={t("label:payment_settings.invoice_address")} required rules={[{ required: true }]}>
                            <Input className="w-full" onChange={handleRequiredFieldChange} disabled={!isEditable} />
                        </Form.Item>
                    </Col>

                    <Col span={24} md={12}>
                        <Form.Item name="secondAddress" label={t("label:payment_settings.second_address")}>
                            <Input value={paymentSettings.paymentMethod?.sepaDebit?.invoiceAddress.line2} disabled={!isEditable} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[16, 16]} align="bottom">
                    <Col span={12} md={6}>
                        <Form.Item name="postcode" label={t("label:payment_settings.postcode")} required rules={[{ required: true }]}>
                            <Input className="w-full" onChange={handleRequiredFieldChange} disabled={!isEditable} />
                        </Form.Item>
                    </Col>

                    <Col span={12} md={6}>
                        <Form.Item name="city" label={t("label:payment_settings.city")} required rules={[{ required: true }]}>
                            <Input className="w-full" disabled={!isEditable} onChange={handleRequiredFieldChange} />
                        </Form.Item>
                    </Col>

                    <Col span={24} md={12}>
                        <Form.Item name="country" label={t("label:payment_settings.country")} required rules={rules.countryCode}>
                            <Input
                                className="w-full"
                                disabled={!isEditable}
                                value={paymentSettings.paymentMethod?.sepaDebit?.invoiceAddress.country}
                                onChange={handleRequiredFieldChange}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                {isEditable && footerRow(acceptSepaFooter)}
            </div>
        </Form>
    )
}

export default PaymentMethodSepaDebitForm
