import { InboxInvoice } from "@finway-group/shared/lib/models"
import { Action } from "redux"

export enum InboxInvoiceActionTypes {
    FETCH_INBOX_INVOICES_COUNT = "FETCH_INBOX_INVOICES_COUNT",
    FETCH_ALL_INBOX_INVOICES = "FETCH_ALL_INBOX_INVOICES",
    FETCH_ONE_INBOX_INVOICE = "FETCH_ONE_INBOX_INVOICE",
    NEW_INBOX_INVOICE = "NEW_INBOX_INVOICE",
    DISCARD_INBOX_INVOICE = "DISCARD_INBOX_INVOICE",
    DELETE_MANY_INBOX_INVOICES = "DELETE_MANY_INBOX_INVOICES",
    SYNC_ONE_INBOX_INVOICE = "SYNC_ONE_INBOX_INVOICE",
    SET_SHOULD_REFETCH_INBOX = "SET_SHOULD_REFETCH_INBOX",
    MOVE_ONE_INBOX_INVOICE = "MOVE_ONE_INBOX_INVOICE",
}

export interface FetchInboxInvoicesCountAction extends Action<typeof InboxInvoiceActionTypes.FETCH_INBOX_INVOICES_COUNT> {
    type: typeof InboxInvoiceActionTypes.FETCH_INBOX_INVOICES_COUNT
    count: number
}

export interface FetchAllInboxInvoicesAction extends Action<typeof InboxInvoiceActionTypes.FETCH_ALL_INBOX_INVOICES> {
    type: typeof InboxInvoiceActionTypes.FETCH_ALL_INBOX_INVOICES
    inboxInvoices: Array<InboxInvoice>
    totalPages: number
    totalDocs: number
    append: boolean
}

export interface FetchOneInboxInvoiceAction extends Action<typeof InboxInvoiceActionTypes.FETCH_ONE_INBOX_INVOICE> {
    type: typeof InboxInvoiceActionTypes.FETCH_ONE_INBOX_INVOICE
    inboxInvoice: InboxInvoice
}

export interface MoveOneInboxInvoiceAction extends Action<typeof InboxInvoiceActionTypes.MOVE_ONE_INBOX_INVOICE> {
    type: typeof InboxInvoiceActionTypes.MOVE_ONE_INBOX_INVOICE
    inboxInvoiceId: string
}

export interface NewInboxInvoiceAction extends Action<typeof InboxInvoiceActionTypes.NEW_INBOX_INVOICE> {
    type: typeof InboxInvoiceActionTypes.NEW_INBOX_INVOICE
    inboxInvoice: InboxInvoice
}

export interface DiscardInboxInvoiceAction extends Action<typeof InboxInvoiceActionTypes.DISCARD_INBOX_INVOICE> {
    type: typeof InboxInvoiceActionTypes.DISCARD_INBOX_INVOICE
    inboxInvoice: InboxInvoice
}

export interface DeleteManyInboxInvoicesAction extends Action<typeof InboxInvoiceActionTypes.DELETE_MANY_INBOX_INVOICES> {
    type: typeof InboxInvoiceActionTypes.DELETE_MANY_INBOX_INVOICES
    inboxInvoiceIds: Array<string>
}

export interface SyncOneInboxInvoiceAction extends Action<typeof InboxInvoiceActionTypes.SYNC_ONE_INBOX_INVOICE> {
    type: typeof InboxInvoiceActionTypes.SYNC_ONE_INBOX_INVOICE
    inboxInvoice: InboxInvoice
}

export interface SetShouldRefetchInboxAction extends Action<typeof InboxInvoiceActionTypes.SET_SHOULD_REFETCH_INBOX> {
    type: typeof InboxInvoiceActionTypes.SET_SHOULD_REFETCH_INBOX
    shouldRefetch: boolean
}

export type InboxInvoiceActions =
    | FetchInboxInvoicesCountAction
    | FetchAllInboxInvoicesAction
    | FetchOneInboxInvoiceAction
    | NewInboxInvoiceAction
    | DiscardInboxInvoiceAction
    | SyncOneInboxInvoiceAction
    | SetShouldRefetchInboxAction
    | DeleteManyInboxInvoicesAction
    | MoveOneInboxInvoiceAction
