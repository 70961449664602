import { Team } from "@finway-group/shared/lib/models"
import { Form, Modal } from "antd"
import { Store } from "antd/lib/form/interface"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import TeamForm from "Components/forms/team.form"
import { NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { updateTeams } from "Shared/store/actions/team/teamActions"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

interface TeamModalProps {
    isShowing: boolean
    teams: Array<Team>
    onCancel: () => void
}

const TeamModal = ({ teams, isShowing, onCancel }: TeamModalProps) => {
    const { t } = useTranslation()
    const [formInstance] = Form.useForm()
    const [isLoading, setIsLoading] = useStateIfMounted(false)

    const dispatch = useDispatch()

    useEffect(() => {
        if (isShowing) formInstance.setFieldsValue({ teams })
    }, [isShowing])

    const onSubmit = async (values: Store) => {
        try {
            setIsLoading(true)
            await dispatch(updateTeams(values.teams))
            NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:settings.team.updated.title"), t("notification:settings.team.updated.message"))
            onHide()
        } catch (err) {
            setIsLoading(false)
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:error"))
        }
    }

    const onHide = () => {
        setIsLoading(false)
        onCancel()
    }

    return (
        <Modal
            title={t("action:team_settings.teams")}
            visible={isShowing}
            maskClosable={false}
            onOk={formInstance.submit}
            onCancel={onHide}
            okText={t("action:save")}
            cancelText={t("action:cancel")}
            confirmLoading={isLoading}
            keyboard
            destroyOnClose
        >
            <TeamForm formInstance={formInstance} onSubmit={onSubmit} />
        </Modal>
    )
}

export default TeamModal
