import { ApprovalProcess, ApprovalProcessBuildIssueEnum, ApprovalScopeEnum, ApprovalStatusEnum, Company, Expense, TripFolder } from "@finway-group/shared/lib/models"
import { flattenExpression, getApprovalProcess } from "@finway-group/shared/lib/utils"

import { EmployeeService, ExpenseHttpService } from "Shared/services"
import {
    ValuesChange,
    buildIssueTranslationString,
    determineApprovalScopes,
    getApprovalProcessChangeConfirmData,
    getNextApproverId,
    getNextPendingApproverId,
} from "Shared/utils/approvalProcess.utils"

import DialogService from "./dialog.service"

const ApprovalProcessService = {
    buildNewApprovalProcessesForExpenseIfApplicable: async (
        updatedExpense: Expense,
        company: Company,
        otherData: { valuesChange?: ValuesChange; previousExpense?: Expense; isPreApprovedSubscription?: boolean } = {},
    ) => {
        const scopes = determineApprovalScopes(updatedExpense, company, otherData)

        if (!scopes.length) return

        const newApprovalProcesses = await ExpenseHttpService.buildApprovalProcesses(scopes, updatedExpense)
        return newApprovalProcesses
    },
    /**
     *
     * @param expense
     * @param company
     * @returns approval processes with changes if applicable or undefined if user has not confirmed the changes.
     */
    getApprovalProcessesAfterExpenseUpdate: async (
        expense: Expense,
        company: Company,
        {
            previousExpense,
            valuesChange,
            awaitConfirmation,
            isPreApprovedSubscription,
            ocrConfirmation,
            folderAmountConfirmation,
            detachedExpense,
        }: {
            previousExpense?: any
            valuesChange?: any
            awaitConfirmation?: boolean
            isPreApprovedSubscription?: boolean
            ocrConfirmation?: boolean
            folderAmountConfirmation?: boolean
            detachedExpense?: Expense
        } = {},
    ): Promise<Array<ApprovalProcess> | undefined> => {
        const newApprovalProcesses = await ApprovalProcessService.buildNewApprovalProcessesForExpenseIfApplicable(expense, company, {
            previousExpense,
            valuesChange,
            isPreApprovedSubscription,
        })
        if (!newApprovalProcesses) return expense.approvalProcesses

        const newApprovalProcessesOfDetachedExpense = detachedExpense
            ? await ExpenseHttpService.buildApprovalProcesses([ApprovalScopeEnum.EXPENSE_INVOICE], detachedExpense)
            : undefined
        const detachedExpenseApprovalProcess = newApprovalProcessesOfDetachedExpense
            ? getApprovalProcess(ApprovalScopeEnum.EXPENSE_INVOICE, newApprovalProcessesOfDetachedExpense)
            : undefined

        return ApprovalProcessService.getMergedApprovalProcesses(expense.approvalProcesses, newApprovalProcesses, {
            awaitConfirmation,
            ocrConfirmation,
            prioritizeApproved: isPreApprovedSubscription,
            folderAmountConfirmation,
            detachedExpenseApprovalProcess,
        })
    },
    confirmFolderAmountChange: async (grossDifference: number, folder: TripFolder, company: any, detachedExpense?: Expense) => {
        if (grossDifference === 0) return true
        const approvalProcesses = await ApprovalProcessService.getApprovalProcessesAfterExpenseUpdate(
            new Expense({ ...folder, totalGrossPrice: folder.totalGrossPrice + grossDifference }),
            company,
            {
                valuesChange: { totalGrossPrice: grossDifference },
                awaitConfirmation: true,
                folderAmountConfirmation: true,
                detachedExpense,
            },
        )
        if (!approvalProcesses) return false
        return true
    },
    getNextPendingApprover: (approvalProcesses: Array<ApprovalProcess>) => EmployeeService.getEmployeeByIdOptional(getNextPendingApproverId(approvalProcesses)),
    /**
     * @returns next approver that has yet to approve in the current step in progress. If there is no step in progress, the approver of the next not-skipped step.
     */
    getNextApprover: (approvalProcesses: Array<ApprovalProcess>) => EmployeeService.getEmployeeByIdOptional(getNextApproverId(approvalProcesses)),

    getBuildIssueTranslationString: (buildIssue: { issue: ApprovalProcessBuildIssueEnum; references: Array<any> }) => {
        const getCommaSeparatedNames = (userIds: Array<string>) =>
            userIds.length === 1
                ? EmployeeService.getEmployeeById(userIds[0]).getFullName()
                : `${userIds.map((userId) => EmployeeService.getEmployeeById(userId).getFullName()).join(", ")}.`
        const namesString = getCommaSeparatedNames(buildIssue.references)

        return buildIssueTranslationString(buildIssue.issue, namesString)
    },
    /**
     *
     * @param currentApprovalProcesses
     * @param newApprovalProcesses
     * @param {Object} options
     * @param {string} options.awaitConfirmation - Wether to await confirmation to merge the approval processes. If declined, returns undefined..
     * @param {string} options.prioritizeApproved - Wether to prioritize approval processes in status approved. If the new approval process is in status {@link ApprovalStatusEnum.APPROVED}, it will be selected over the current one.
     * @param {string} options.ocrConfirmation - Special type of confirmation that will show OCR related messages. (Only if awaitConfirmation is true).
     * @param {string} options.ocrConfirmation - Special type of confirmation that will show folder related messages. (Only if awaitConfirmation is true).
     * @param {string} options.newApprovalProcessDetachedExpense - Approval process of the expense that is going to be detached from the folder.
     * @returns
     */
    getMergedApprovalProcesses: async (
        currentApprovalProcesses: Array<ApprovalProcess>,
        newApprovalProcesses: Array<ApprovalProcess>,
        {
            awaitConfirmation,
            ocrConfirmation,
            prioritizeApproved,
            folderAmountConfirmation,
            detachedExpenseApprovalProcess,
        }: {
            awaitConfirmation?: boolean
            ocrConfirmation?: boolean
            prioritizeApproved?: boolean
            folderAmountConfirmation?: boolean
            detachedExpenseApprovalProcess?: ApprovalProcess
        } = {},
    ) => {
        const scopes = [...new Set(currentApprovalProcesses.concat(newApprovalProcesses).map((approvalProcess) => approvalProcess.scope))]
        const approvalProcesses: Array<ApprovalProcess> = []
        for (const scope of scopes) {
            const currentApprovalProcess = currentApprovalProcesses.find((currentApprovalProcess) => currentApprovalProcess.scope === scope)
            const newApprovalProcess = newApprovalProcesses.find((newApprovalProcess) => newApprovalProcess.scope === scope)

            if (!currentApprovalProcess) {
                approvalProcesses.push(newApprovalProcess!)
                continue
            }

            if (!newApprovalProcess) {
                approvalProcesses.push(currentApprovalProcess)
                continue
            }

            // If the current approval process has not yet initiated, replace it with the new one.
            if (currentApprovalProcess.status === ApprovalStatusEnum.NOT_STARTED) {
                approvalProcesses.push(newApprovalProcess)
                continue
            }

            // If prioritizeApproved option is true and the approval process is approved, replace the old approval process with the new one.
            if (prioritizeApproved && newApprovalProcess.status === ApprovalStatusEnum.APPROVED) {
                approvalProcesses.push(newApprovalProcess)
                continue
            }

            // // In approval process with status progress/rejected/approved, if approval process hasn't changed, keep the current one.
            if (newApprovalProcess.workflow === currentApprovalProcess.workflow && newApprovalProcess.status === currentApprovalProcess.status) {
                approvalProcesses.push(currentApprovalProcess)
                continue
            }

            // // In case where the current approval process has no workflow and the new one has no workflow as well, check if the approvers are the same. If they are, keep the current approval process. -- Why do don't we reset the approval process here? Rationale is that approval process with no workflow have only one approver. So the moment that approver approves, he/she has the complete information of what is approving. There is no past approver that might have not agreed to a new change.
            if (!currentApprovalProcess.workflow && !newApprovalProcess.workflow) {
                const [currentApproval] = flattenExpression(currentApprovalProcess.steps[0].approvalExpression)
                const [newApproval] = flattenExpression(newApprovalProcess.steps[0].approvalExpression)
                if (currentApproval.approver === newApproval.approver) {
                    approvalProcesses.push(currentApprovalProcess)
                    continue
                }
            }

            // For approval processes in status progress/approved/rejected, ask confirmation before updating if the workflow changed.
            if (awaitConfirmation) {
                const { title, approvalProcessChangeBlocks } = getApprovalProcessChangeConfirmData({
                    ocrConfirmation,
                    folderAmountConfirmation,
                    currentApprovalProcess,
                    newApprovalProcess,
                    detachedExpenseApprovalProcess,
                })
                if (!(await DialogService.confirmApprovalProcessReplacement(title, approvalProcessChangeBlocks))) {
                    return
                }
            }

            approvalProcesses.push(newApprovalProcess)
        }

        return approvalProcesses
    },
}

export default ApprovalProcessService
