import React from "react"

export enum NavbarMenuItemEnum {
    PURCHASED_REQUEST = "purchase_request",
    INVOICE_REQUEST = "invoice_request",
    REIMBURSEMENT_REQUEST = "reimbursement_request",
    TRIP_FOLDER_REQUEST = "trip_folder_request",
    SUBSCRIPTION = "subscription",
    EMPLOYEE = "employee",
    COST_CENTER = "costCenter",
    VENDOR = "vendor",
    LOGOUT = "logout",
    HELP_CENTER = "helpCenter",
}

export interface MenuItemInterface {
    link: string
    label: string
    icon: React.ReactNode
    canView?: boolean
}
