import { Col, Row } from "antd"
import Form, { FormInstance } from "antd/lib/form/Form"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"

import { useWeavrPasscodeInput } from "Shared/hooks/weavr.hooks"
import { NotificationService } from "Shared/services"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

interface WeavrPasscodeFormInterface {
    formInstance: FormInstance
    isModalShowing: boolean
    setIsLoading: (isLoading: boolean) => void
    onSubmit: (tokenizedPassword: string) => Promise<void>
}

const WeavrPasscodeForm = ({ formInstance, isModalShowing, setIsLoading, onSubmit }: WeavrPasscodeFormInterface) => {
    const { t } = useTranslation()
    const { secureForm, setShouldLoad } = useWeavrPasscodeInput("modal-passcode", isModalShowing)
    const [isFieldEmpty, setIsFieldEmpty] = useStateIfMounted(false)

    useEffect(() => {
        if (!isModalShowing) return

        setShouldLoad(true)
        // reset the weavr token
        ;(window as any)?.OpcUxSecureClient?.associate(null)
    }, [isModalShowing])

    const onFinish = async () => {
        setIsLoading(true)
        secureForm.tokenize(
            (tokens: any) => {
                const tokenizedPassword = tokens.passcode
                if (!tokenizedPassword || tokenizedPassword.length === 0) {
                    setIsFieldEmpty(true)
                    setIsLoading(false)
                    return
                }

                onSubmit(tokenizedPassword).finally(initState)
            },
            (err: any) => {
                NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:error"))
                setIsLoading(false)
            },
        )
    }

    const initState = () => {
        setIsLoading(false)
        setIsFieldEmpty(false)
    }

    return (
        <Form form={formInstance} onFinish={onFinish} layout="vertical">
            <Row gutter={[16, 0]}>
                <Col span={24}>
                    <div className="ant-form-item-label">
                        <label>{t("corporate:pin.enter_prompt")}</label>
                    </div>
                </Col>
                <Col span={12} className="flex flex-col">
                    <div className="ant-input rounded-lg min-h-32 mt-4 pt-8 w-100" id="modal-passcode" />
                </Col>
            </Row>
        </Form>
    )
}

export default React.memo(WeavrPasscodeForm)
