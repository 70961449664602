import { PerDiemDestination } from "@finway-group/shared/lib/models"
import { Action } from "redux"

export enum PerDiemDestinationActionTypes {
    FETCH_PER_DIEM_DESTINATIONS = "FETCH_PER_DIEM_DESTINATIONS",
}

export interface FetchPerDiemDestinationAction extends Action<typeof PerDiemDestinationActionTypes.FETCH_PER_DIEM_DESTINATIONS> {
    type: typeof PerDiemDestinationActionTypes.FETCH_PER_DIEM_DESTINATIONS
    perDiemDestinations: Array<PerDiemDestination>
}
export type PerDiemDestinationActions = FetchPerDiemDestinationAction
