import { DatePicker, Form, Input, Radio } from "antd"
import { FormInstance } from "antd/lib/form"
import { Store } from "antd/lib/form/interface"
import moment from "moment"
import React from "react"
import { Search as SearchIcon } from "react-feather"
import { useTranslation } from "react-i18next"

import { TransactionTabTypeEnum } from "Features/pages/transactions/transactions.list"
import { TablesEnum } from "Shared/store/reducers/tableConfigReducer"

import { archiveSearchRules } from "./rules/archiveSearch.rules"

const { RangePicker } = DatePicker

interface ArchiveModalFormInterface {
    formInstance: FormInstance
    onSubmit: (values: Store) => void
    archiveTable: TablesEnum.ARCHIVE | TablesEnum.TRANSACTIONS_ARCHIVE
}

const ArchiveModalForm: React.FC<ArchiveModalFormInterface> = ({ formInstance, onSubmit, archiveTable }) => {
    const { t } = useTranslation()
    const rules = archiveSearchRules()

    return (
        <Form id="expenseSearchForm" layout="vertical" form={formInstance} onFinish={onSubmit}>
            {archiveTable === TablesEnum.TRANSACTIONS_ARCHIVE && (
                <Form.Item className="gap-0 w-full" name="transactionTabType" initialValue={TransactionTabTypeEnum.ALL}>
                    <Radio.Group className="flex flex-wrap">
                        <Radio.Button className="flex-grow text-xs sm:text-base text-center" value={TransactionTabTypeEnum.ALL}>
                            {t("label:all")}
                        </Radio.Button>
                        <Radio.Button className="flex-grow text-xs sm:text-base text-center" value={TransactionTabTypeEnum.OUTFLOW}>
                            {t("label:outflow")}
                        </Radio.Button>
                        <Radio.Button className="flex-grow text-xs sm:text-base text-center" value={TransactionTabTypeEnum.INFLOW}>
                            {t("label:inflow")}
                        </Radio.Button>
                    </Radio.Group>
                </Form.Item>
            )}

            <Form.Item name="searchString" extra={t("info:archive.expense_initial_display.warning")}>
                <Input className="w-full" size="small" placeholder={t("placeholder:search")} prefix={<SearchIcon size={15} />} allowClear />
            </Form.Item>

            <Form.Item
                label={t("input:archive.searchPeriod")}
                name="period"
                required
                rules={rules.period}
                extra={t(`info:archive.${archiveTable === TablesEnum.ARCHIVE ? "expense_search_info" : "transaction_search_info"}`)}
                initialValue={[moment().subtract(6, "months"), moment()]}
            >
                <RangePicker className="w-full" format={moment.localeData().longDateFormat("L")} />
            </Form.Item>
        </Form>
    )
}

export default ArchiveModalForm
