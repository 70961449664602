import { Expense, ExpenseKindEnum, ExpenseRequestTypeEnum, TransactionData, TripFolder } from "@finway-group/shared/lib/models"
import { Button, Dropdown, Menu } from "antd"
import React, { useState } from "react"
import { FileText as InvoiceIcon, Plus as PlusIcon, RefreshCw as RefreshIcon, ShoppingBag as ShoppingBagIcon } from "react-feather"
import { useTranslation } from "react-i18next"

import { TripIcon } from "Components/icons"
import ExpenseCreateFormModal from "Components/modals/expenseCreateForm.modal"
import { useIsTravelEnabled } from "Shared/hooks/featureFlags.hooks"
import { AuthzService } from "Shared/services"

interface ExpenseRequestDropdownInterface {
    disabled?: boolean
    hideMenu?: boolean
    transactionData?: TransactionData
    onExpenseCreated?: (expense: Expense) => void
    folder?: TripFolder // When this button is clicked inside a folder, we have to give the created subexpense the parent id
    buttonOverride?: React.ReactNode
}

const ExpenseRequestDropdown: React.FC<ExpenseRequestDropdownInterface> = ({ disabled = false, hideMenu = false, transactionData, onExpenseCreated, folder, buttonOverride }) => {
    const { t } = useTranslation()
    const isTravelEnabled = useIsTravelEnabled()
    const [requestType, setRequestType] = useState<ExpenseRequestTypeEnum>(ExpenseRequestTypeEnum.PURCHASE)
    const [isReimbursement, setIsReimbursement] = useState<boolean>(false)
    const [showCreateModal, setShowCreateModal] = useState<boolean>(false)
    const isInsideFolder = !!folder // we dont show the purchase request and trip folder when this button is used to create subexpense
    const onItemClicked = ({ key }: { key: React.Key }) => {
        setRequestType(Number(key))
        setIsReimbursement(key === ExpenseRequestTypeEnum.REIMBURSEMENT)
        setShowCreateModal(true)
    }

    const DropMenu = (
        <Menu className="w-full" onClick={onItemClicked}>
            {!isInsideFolder && (
                <Menu.Item key={ExpenseRequestTypeEnum.PURCHASE} className="flex items-center w-340 py-8 px-16">
                    <div className="flex ant-btn btn-highlight-green items-center justify-center rounded-full w-44 h-44">
                        <ShoppingBagIcon className="stroke-current w-20" />
                    </div>
                    <div className="flex items-center ml-12">
                        <p className="leading-none">
                            <span className="font-bold text-text-dark">{t("label:purchase_request")}</span>
                            <br />
                            <span className="block mt-4 text-text">{t("label:purchase_request_desc")}</span>
                        </p>
                    </div>
                </Menu.Item>
            )}
            <Menu.Item data-testid="folderInvoiceRequest" key={ExpenseRequestTypeEnum.INVOICE} className="flex items-center w-350 py-8 px-16">
                <div className="flex ant-btn btn-highlight-green items-center justify-center rounded-full w-44 h-44">
                    <InvoiceIcon className="stroke-current w-20" />
                </div>
                <div className="flex items-center ml-12">
                    <p className="leading-none">
                        <span className="font-bold text-text-dark">{t("label:invoice_request")}</span>
                        <br />
                        <span className="block mt-4 text-text">{t("label:invoice_request_desc")}</span>
                    </p>
                </div>
            </Menu.Item>
            <Menu.Item data-testid="folderReimbursementRequest" key={ExpenseRequestTypeEnum.REIMBURSEMENT} className="flex items-center w-350 py-8 px-16">
                <div className="flex ant-btn btn-highlight-green items-center justify-center rounded-full w-44 h-44">
                    <RefreshIcon className="stroke-current w-20" />
                </div>
                <div className="flex items-center ml-12">
                    <p className="leading-none">
                        <span className="font-bold text-text-dark">{t("label:reimbursement_request")}</span>
                        <br />
                        <span className="block mt-4 text-text">{t("label:reimbursement_request_desc")}</span>
                    </p>
                </div>
            </Menu.Item>
            {!isInsideFolder && isTravelEnabled && (
                <Menu.Item key={ExpenseRequestTypeEnum.TRIP_FOLDER} className="flex items-center w-350 py-8 px-16">
                    <div className="flex ant-btn btn-highlight-green items-center justify-center rounded-full w-44 h-44">
                        <TripIcon className="stroke-current w-20" />
                    </div>
                    <div className="flex items-center ml-12">
                        <p className="leading-none">
                            <span className="font-bold text-text-dark">{t("label:trip_folder_request")}</span>
                            <br />
                            <span className="block mt-4 text-text">{t("label:trip_folder_request_desc")}</span>
                        </p>
                    </div>
                </Menu.Item>
            )}
        </Menu>
    )

    const onCreateInvoiceRequest = (e: any) => {
        e.preventDefault()
        setRequestType(ExpenseRequestTypeEnum.INVOICE)
        setShowCreateModal(true)
    }

    const renderAddButton = () => {
        if (hideMenu)
            return (
                <Button disabled={disabled} className="ant-btn-primary" onClick={onCreateInvoiceRequest}>
                    <PlusIcon />
                    <span>{t("action:request:create")}</span>
                </Button>
            )

        return (
            <Dropdown disabled={disabled} overlay={DropMenu} trigger={["click"]}>
                {buttonOverride || (
                    <Button className="ant-btn-primary" onClick={(e) => e.preventDefault()}>
                        <PlusIcon />
                        <span>{t("action:request:create")}</span>
                    </Button>
                )}
            </Dropdown>
        )
    }

    return (
        <>
            <ExpenseCreateFormModal
                type={requestType}
                kind={requestType === ExpenseRequestTypeEnum.TRIP_FOLDER ? ExpenseKindEnum.TRIP_FOLDER : ExpenseKindEnum.ONE_TIME_EXPENSE}
                isNew={true}
                isShowing={showCreateModal}
                transactionData={transactionData}
                onCancel={() => setShowCreateModal(false)}
                onExpenseCreated={onExpenseCreated}
                isReimbursement={isReimbursement}
                folder={folder}
                hideExpenseKindRadioOption={!!folder}
            />

            {AuthzService.isLoggedInUserAllowedToCreateExpenses() && renderAddButton()}
        </>
    )
}

export default ExpenseRequestDropdown
