import { ExpenseStatusEnum } from "@finway-group/shared/lib/models"
import { matchPath } from "react-router-dom"

import { RoutePathEnum } from "Shared/router/RoutePath.enum"
import { TablesEnum } from "Shared/store/reducers/tableConfigReducer"

export const getCurrentPageInfo = (documentId: string, dependencies: Array<string> = []) => {
    const path = window.location.pathname
    const isOnExpensePage = path.startsWith(RoutePathEnum.EXPENSES) || path.startsWith(RoutePathEnum.SUBSCRIPTIONS)
    const isOnCurrentDetailPage = isOnExpensePage && path.endsWith(documentId)

    const { expenseId, folderId: currentFolderId } = extractExpenseDetailPagePathname(path)

    // Need to handle case where this method is invoked when window.location is not in the expense detail page, hence the fallback.
    const currentDocId = expenseId ?? path.split("/").pop()

    const folderIsAffected = currentFolderId && dependencies.includes(currentFolderId)

    const isAffectedByBulkOperation = isOnExpensePage && currentDocId && (dependencies.includes(currentDocId) || folderIsAffected)

    return {
        currentDocId,
        currentFolderId,
        isOnExpensePage,
        isOnCurrentDetailPage,
        isAffectedByBulkOperation,
    }
}

export const getCurrentPagePathParams = () => {
    const path = window.location.pathname
    const pathParts = path.split("/")

    return {
        domain: pathParts.shift(),
        documentId: pathParts.shift(),
        subDocumentId: pathParts.shift(),
    }
}

export const getDashboardTablesForStatus = (status: ExpenseStatusEnum) => {
    switch (status) {
        case ExpenseStatusEnum.PURCHASE_PENDING:
            return [TablesEnum.TODO_PURCHASE_APPROVAL, TablesEnum.TODO_INVOICE_AND_PURCHASE, TablesEnum.TODO_APPROVAL_PENDING]
        case ExpenseStatusEnum.DOCS_NEEDED:
            return [TablesEnum.TODO_DOCS_NEEDED]
        case ExpenseStatusEnum.INVOICE_PENDING:
            return [TablesEnum.TODO_INVOICE_APPROVAL, TablesEnum.TODO_INVOICE_AND_PURCHASE, TablesEnum.TODO_APPROVAL_PENDING, TablesEnum.TRIP_FOLDER_DRAFT]
        case ExpenseStatusEnum.APPROVED:
            return [TablesEnum.REVIEW_REQUESTS, TablesEnum.IN_PROGRESS]
        case ExpenseStatusEnum.REVIEWED:
            return [TablesEnum.PAY_AND_EXPORT_TO_BE_PAID, TablesEnum.IN_PROGRESS]
        case ExpenseStatusEnum.PAID:
            return [TablesEnum.PAY_AND_EXPORT_TO_BE_EXPORTED, TablesEnum.IN_PROGRESS]
        case ExpenseStatusEnum.DECLINED:
        case ExpenseStatusEnum.DONE:
            return [TablesEnum.DONE_REQUESTS]
        default:
            return []
    }
}

const extractExpenseDetailPagePathname = (pathname: string) => {
    const folderPathInfo = matchPath<{ id: string; childId: string }>(pathname, { path: RoutePathEnum.FOLDER_EXPENSE_DETAIL_PAGE })
    if (folderPathInfo) {
        return {
            folderId: folderPathInfo.params.id,
            expenseId: folderPathInfo.params.childId,
        }
    }
    const expensePathInfo = matchPath<{ id: string }>(pathname, { path: RoutePathEnum.EXPENSE_DETAIL_PAGE })
    if (expensePathInfo) {
        return {
            expenseId: expensePathInfo?.params.id,
        }
    }
    const subscriptionPathInfo = matchPath<{ id: string }>(pathname, { path: RoutePathEnum.SUBSCRIPTION_DETAIL_PAGE })
    return {
        expenseId: subscriptionPathInfo?.params.id,
    }
}
