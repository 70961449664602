import { CostCenterTypeEnum } from "@finway-group/shared/lib/models"
import { Button, Form, Modal } from "antd"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import { useFormTouchedHandler } from "Shared/hooks/form.hooks"
import DialogService from "Shared/services/dialog.service"

import CostCenterFormContainer from "./costCenterForm.container"

interface CostCenterFormModalInterface {
    isNew: boolean
    isShowing: boolean
    onHide: () => any
}

export const CostCenterFormModal: React.FC<CostCenterFormModalInterface> = ({ isNew, isShowing, onHide }) => {
    const { t } = useTranslation()
    const [formInstance] = Form.useForm()

    // state
    const [loading, setLoading] = useState(false)
    const [costCenterType, setCostCenterType] = useState(CostCenterTypeEnum.COST_CENTER_GROUP)
    const isSubCostCenter = costCenterType === CostCenterTypeEnum.SUB_COST_CENTER
    const { isFormTouched, handleTouchForm, handleResetTouchForm } = useFormTouchedHandler()

    const handleHide = () => {
        formInstance.resetFields()
        setLoading(false)
        handleResetTouchForm()
        onHide()
    }

    const handleHideOnCloseOrCancel = async () => {
        if (isFormTouched.current) {
            const isConfirmed = await DialogService.confirmUnsavedChanges()
            if (!isConfirmed) return
        }

        handleHide()
    }

    const handleChangeCostCenterType = (costCenterType: CostCenterTypeEnum) => {
        setCostCenterType(costCenterType)
    }

    return (
        <Modal
            title={
                isNew
                    ? isSubCostCenter
                        ? t("action:cost_center.create")
                        : t("action:cost_center_group.create")
                    : isSubCostCenter
                    ? t("action:cost_center.edit")
                    : t("action:cost_center_group.edit")
            }
            visible={isShowing}
            maskClosable={false}
            onCancel={handleHideOnCloseOrCancel}
            destroyOnClose
            footer={[
                <Button key={1} className="btn-default" size={"large"} onClick={handleHideOnCloseOrCancel}>
                    {t("action:cancel")}
                </Button>,
                <Button data-testid="submitCostCenterCreationButton" key={2} size={"large"} type="primary" loading={loading} htmlType="submit" onClick={formInstance.submit}>
                    {isNew
                        ? isSubCostCenter
                            ? t("action:cost_center.submit")
                            : t("action:cost_center_group.submit")
                        : isSubCostCenter
                        ? t("action:cost_center.save")
                        : t("action:cost_center_group.save")}
                </Button>,
            ]}
        >
            <CostCenterFormContainer
                isNew={isNew}
                handleHide={handleHide}
                formInstance={formInstance}
                setIsLoading={setLoading}
                onCostCenterTypeChange={handleChangeCostCenterType}
                onFormValuesChange={handleTouchForm}
            />
        </Modal>
    )
}

export default CostCenterFormModal
