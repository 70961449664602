import { Workflow } from "@finway-group/shared/lib/models"
import { Dispatch } from "redux"

import WorkflowService from "Shared/services/workflowService"
import { TablesEnum } from "Shared/store/reducers/tableConfigReducer"

import { refetchTable } from "../tables/tableActions"
import { FetchAllWorkflowsAction, NewWorkflowAction, SetWorkflowAction, SyncWorkflowAction, WorkflowActionTypes } from "./workflowTypes"

export const fetchAllWorkflows = () => async (dispatch: Dispatch) => {
    const workflows = await WorkflowService.fetchAllWorkflows()

    const fetchWorkflowsAction: FetchAllWorkflowsAction = {
        type: WorkflowActionTypes.FETCH_ALL_WORKFLOWS,
        workflows,
    }

    dispatch(fetchWorkflowsAction)
    dispatch(refetchTable(TablesEnum.WORKFLOWS))

    return workflows
}

export const syncWorkflow = (id: string) => async (dispatch: Dispatch) => {
    const workflow = await WorkflowService.fetchOneWorkflow(id)

    const syncWorkflowAction: SyncWorkflowAction = {
        type: WorkflowActionTypes.SYNC_WORKFLOW,
        workflow,
    }

    dispatch(syncWorkflowAction)

    return workflow
}

export const setWorkflow = (workflow: Workflow | undefined) => async (dispatch: Dispatch) => {
    const setWorkflowAction: SetWorkflowAction = {
        type: WorkflowActionTypes.SET_WORKFLOW,
        workflow,
    }

    dispatch(setWorkflowAction)

    return workflow
}

export const updateWorkflow =
    (id: string, workflowData: Partial<Workflow>, otherData: { recalculateApprovalProcessesNotStarted?: boolean; recalculateApprovalProcessesInProgress?: boolean } = {}) =>
    async (dispatch: Dispatch) => {
        const workflow = await WorkflowService.update(id, workflowData, otherData)

        const syncWorkflowAction: SyncWorkflowAction = {
            type: WorkflowActionTypes.SYNC_WORKFLOW,
            workflow,
        }

        dispatch(syncWorkflowAction)
        dispatch(refetchTable(TablesEnum.WORKFLOWS))

        return workflow
    }

export const createWorkflow = (createWorkflowData: Partial<Workflow>) => async (dispatch: Dispatch) => {
    const workflow = await WorkflowService.create(createWorkflowData)

    const newWorkflowAction: NewWorkflowAction = {
        type: WorkflowActionTypes.NEW_WORKFLOW,
        workflow,
    }

    dispatch(newWorkflowAction)
    dispatch(refetchTable(TablesEnum.WORKFLOWS))

    return workflow
}
