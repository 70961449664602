import { Empty } from "antd"
import React from "react"

import NoSearchDataFound from "./NoSearchDataFound"

interface EmptyTableDataInterface {
    searchString?: string
    title: string
    message: string
}

const EmptyTableData: React.FC<EmptyTableDataInterface> = ({ searchString, title, message }) =>
    searchString ? (
        <div className="animation-appear">
            <NoSearchDataFound />
        </div>
    ) : (
        <Empty
            id="emptyInboxTableData"
            className="animation-appear"
            image="./icons/empty_inbox.svg"
            imageStyle={{
                height: 120,
                marginRight: "auto",
                marginLeft: "auto",
                marginTop: "10%",
                marginBottom: "40px",
                display: "inline-block",
            }}
            description={<span className="text-lg font-bold mt-2 mb-0">{title}</span>}
        >
            <p>{message}</p>
        </Empty>
    )

export default EmptyTableData
