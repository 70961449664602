import React from "react"

import { useExpenseFormContext } from "Components/modals/expenseCreateForm.context"
import { usePerDiemDestinationMap } from "Shared/hooks/perDiemDestination.hooks"
import { isPerDiemExpense } from "Shared/utils/expense.utils"

import ReimbursementTypeSelector from "../reimbursementTypeSelector"
import { PerDiemRequestFieldsForm } from "./perDiemFields.form"

export const PerDiemRequestForm = () => {
    const [{ expense }] = useExpenseFormContext()
    const { isReady } = usePerDiemDestinationMap()

    if (!isPerDiemExpense(expense)) {
        return <ReimbursementTypeSelector />
    }

    if (!isReady) {
        return null
    }

    return <PerDiemRequestFieldsForm perDiemExpense={expense} />
}
