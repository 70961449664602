import { Team } from "@finway-group/shared/lib/models"
import { Reducer } from "redux"

import { FetchAllTeamsAction, TeamTypes, TeamsActions, UpdateAllTeamsAction } from "../actions/team/teamTypes"

export interface TeamState {
    items: Array<Team>
}

const initialState = {
    items: [],
}

const fetchAllTeams = (state: TeamState, action: FetchAllTeamsAction): TeamState => ({
    ...state,
    items: action.teams,
})

const updateAllTeams = (state: TeamState, action: UpdateAllTeamsAction): TeamState => ({
    ...state,
    items: action.teams,
})

export const teamReducer: Reducer<TeamState, TeamsActions> = (state = initialState, action) => {
    switch (action.type) {
        case TeamTypes.FETCH_ALL_TEAMS:
            return fetchAllTeams(state, action)
        case TeamTypes.UPDATE_ALL_TEAMS:
            return updateAllTeams(state, action)
        default:
            return state
    }
}
