import { CreditorInterface, Employee, Vendor, VendorRule, VendorTypeEnum } from "@finway-group/shared/lib/models"
import stringSimilarity from "string-similarity"

import { removeStringWhiteSpaces } from "./helper.utils"

export const createCreditorObject = (creditor: Employee | Vendor): CreditorInterface => {
    if (creditor instanceof Employee) {
        return {
            id: creditor.id,
            name: creditor.getFullName(),
            type: VendorTypeEnum.USER,
            creditorNumber: creditor?.activeCompanyProfile?.creditorNumber ? String(creditor.activeCompanyProfile.creditorNumber) : undefined,
            bic: creditor.bic,
            iban: creditor.iban,
            source: new Employee(creditor),
        }
    }

    return {
        id: creditor?.id,
        name: creditor?.name,
        type: VendorTypeEnum.VENDOR,
        creditorNumber: creditor?.creditorNumber ? String(creditor.creditorNumber) : undefined,
        bic: creditor?.bic,
        iban: creditor?.iban,
        source: new Vendor(creditor),
    }
}

export interface DuplicateVendorInformationInterface {
    vendor: Vendor
    matchedAttributes: Array<keyof Vendor>
}

export const getAllMatchedAttributes = (duplicateVendors: Array<DuplicateVendorInformationInterface>) => [
    ...new Set(duplicateVendors.flatMap(({ matchedAttributes }) => matchedAttributes)),
]

/* Vendor rule object could have only _id defined therefore at least two values need to be defined */
export const isVendorRuleEmpty = (rule: VendorRule | undefined) => Object.values(rule || {}).filter(Boolean).length < 2

/**
 * Checks for the best match in a list of vendors giving a vendorName
 */
export const checkVendorSimilarity = (vendors: Array<Vendor>, vendorName: string) => {
    const similarVendors = vendors.filter((vendor) => stringSimilarity.compareTwoStrings(vendor.name.toLocaleLowerCase(), vendorName?.toLocaleLowerCase() || "") > 0.6)
    if (similarVendors.length === 0) return null
    const bestMatch = stringSimilarity.findBestMatch(
        vendorName,
        similarVendors.map((vendor) => vendor.name),
    )
    return similarVendors[bestMatch.bestMatchIndex]
}
