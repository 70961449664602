import { CsvTemplate } from "@finway-group/shared/lib/models"
import { Reducer } from "redux"

import {
    CreateNewCsvTemplatesAction,
    CsvTemplateActionTypes,
    CsvTemplateActions,
    DeleteCsvTemplateAction,
    FetchAllCsvTemplatesAction,
    UpdateCsvTemplatesAction,
} from "../actions/csvTemplates/csvTemplateTypes"

export interface TemplateState {
    templates: Array<CsvTemplate>
    template?: CsvTemplate
}

const initialState: TemplateState = {
    templates: [],
    template: undefined,
}

const fetchAllCsvTemplates = (state: TemplateState, action: FetchAllCsvTemplatesAction): TemplateState => ({
    ...state,
    templates: action.templates,
})

const createNewCsvTemplate = (state: TemplateState, action: CreateNewCsvTemplatesAction): TemplateState => ({
    ...state,
    templates: [...state.templates, action.template],
})

const updateCsvTemplate = (state: TemplateState, action: UpdateCsvTemplatesAction): TemplateState => ({
    ...state,
    templates: [...state.templates.filter(({ _id }) => _id !== action.template._id), action.template],
})

const deleteCsvTemplate = (state: TemplateState, action: DeleteCsvTemplateAction): TemplateState => ({
    ...state,
    templates: state.templates.filter(({ _id }) => _id !== action.id),
})
export const csvTemplateReducer: Reducer<TemplateState, CsvTemplateActions> = (state = initialState, action) => {
    switch (action.type) {
        case CsvTemplateActionTypes.FETCH_ALL_TEMPLATES:
            return fetchAllCsvTemplates(state, action)
        case CsvTemplateActionTypes.CREATE_ONE_TEMPLATE:
            return createNewCsvTemplate(state, action)
        case CsvTemplateActionTypes.UPDATE_ONE_TEMPLATE:
            return updateCsvTemplate(state, action)
        case CsvTemplateActionTypes.DELETE_ONE_TEMPLATE:
            return deleteCsvTemplate(state, action)
        default:
            return state
    }
}
