import { Col, Form, Input, Row } from "antd"
import { FormInstance } from "antd/lib/form"
import { Store } from "antd/lib/form/interface"
import React from "react"
import { useTranslation } from "react-i18next"

interface WalletWithdrawalChallengeFormInterface {
    formInstance: FormInstance
    onSubmit: (values: Store) => void
}

export const WalletWithdrawalChallengeForm: React.FC<WalletWithdrawalChallengeFormInterface> = ({ formInstance, onSubmit }) => {
    const { t } = useTranslation()

    return (
        <Form layout="vertical" form={formInstance} onFinish={onSubmit} scrollToFirstError={true}>
            <Row gutter={[16, 16]} align="bottom">
                <Col span={24}>
                    <p className="mb-24">{t("info:wallet.wire_transfer.verification_code_sent")}</p>
                </Col>
                <Col span={12} md="14">
                    <Form.Item label={t("input:wallet.verification_code")} name="verificationCode" key="verificationCode" required>
                        <Input placeholder={t("placeholder:wallet.verification_code")} />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    )
}
