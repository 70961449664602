import { ArrowLeftOutlined, DeleteOutlined, EllipsisOutlined } from "@ant-design/icons"
import { CreateSsoProviderType, SsoProviderType, SsoProviderTypeEnum } from "@finway-group/shared/lib/models"
import { Button, Dropdown, Form, Menu, Modal } from "antd"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"

import SsoProviderConfigurationForm from "Components/forms/ssoProviderConfiguration.form"
import { API_URL } from "Shared/config/consts"
import { getLanguageCode } from "Shared/hooks/i18n.hooks"
import { useCreateSsoConfiguration, useDeleteSsoConfiguration, useUpdateSsoConfiguration } from "Shared/queries/sso.queries"
import NotificationService from "Shared/services/notification.service"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"
import { getPopupAnchor } from "Shared/utils/popup.utils"
import { SSO_SETUP_RESOURCES } from "Shared/utils/sso.utils"

type SsoProviderModalType = {
    isShowing: boolean
    type: SsoProviderTypeEnum
    icon: JSX.Element
    connection?: SsoProviderType
    onCancel: () => void
}

const SsoProviderModal = ({ isShowing, type, icon, connection, onCancel }: SsoProviderModalType) => {
    const { t } = useTranslation()

    const [isLoading, setIsLoading] = useStateIfMounted(false)
    const [formInstance] = Form.useForm()

    const createSsoConfiguration = useCreateSsoConfiguration()
    const updateSsoConfiguration = useUpdateSsoConfiguration()
    const deleteSsoConfiguration = useDeleteSsoConfiguration()

    useEffect(() => {
        formInstance.resetFields()
    }, [])

    const handleSubmit = async (data: Omit<CreateSsoProviderType, "type">) => {
        setIsLoading(true)

        if (!data) return

        if (connection) {
            updateSsoConfiguration.mutate(
                {
                    ...connection,
                    ...data,
                },
                {
                    onSuccess: () => {
                        onCancel()
                    },
                    onError: (error) => {
                        NotificationService.showErrorNotificationBasedOnResponseError(error, t(`error:error`))
                    },
                    onSettled: () => {
                        setIsLoading(false)
                    },
                },
            )
        } else {
            createSsoConfiguration.mutate(
                {
                    ...data,
                    type,
                },
                {
                    onSuccess: () => {
                        onCancel()
                    },
                    onError: (error) => {
                        NotificationService.showErrorNotificationBasedOnResponseError(error, t(`error:error`))
                    },
                    onSettled: () => {
                        setIsLoading(false)
                    },
                },
            )
        }
    }

    const handleDelete = async () => {
        if (connection) {
            deleteSsoConfiguration.mutate(connection?.type, {
                onSuccess: () => {
                    onCancel()
                },
                onError: (error) => {
                    NotificationService.showErrorNotificationBasedOnResponseError(error, t(`error:error`))
                },
                onSettled: () => {
                    setIsLoading(false)
                },
            })
        }
    }

    const languageCode = getLanguageCode()

    return (
        <Modal
            className="ant-modal--small provider-menu-title"
            visible={isShowing}
            maskClosable={false}
            closable={false}
            title={
                <div className="ant-modal-title flex items-center gap-10 w-full">
                    <ArrowLeftOutlined onClick={onCancel} />
                    {icon}

                    <div className="flex items-center justify-between w-full">
                        {type === SsoProviderTypeEnum.GOOGLE && t("label:sso.providers.google.title")}
                        {type === SsoProviderTypeEnum.AZURE_AD && t("label:sso.providers.azure_ad.title")}

                        {connection && (
                            <Dropdown
                                getPopupContainer={getPopupAnchor()}
                                className={"flex items-center"}
                                trigger={["click"]}
                                overlay={
                                    <Menu className="z-20 user-dropdown">
                                        <Menu.Item icon={<DeleteOutlined />} danger onClick={handleDelete}>
                                            {t("action:delete")}
                                        </Menu.Item>
                                    </Menu>
                                }
                            >
                                <EllipsisOutlined />
                            </Dropdown>
                        )}
                    </div>
                </div>
            }
            onCancel={onCancel}
            onOk={formInstance.submit}
            confirmLoading={isLoading}
            footer={[
                <Button
                    key="back"
                    onClick={() => {
                        formInstance.resetFields()
                        onCancel()
                    }}
                >
                    {t("action:cancel")}
                </Button>,
                <Button
                    key="submit"
                    type="primary"
                    loading={isLoading}
                    onClick={() => {
                        formInstance.submit()
                    }}
                >
                    {t(`action:sso.${connection ? "update" : "setup"}`)}
                </Button>,
            ]}
            destroyOnClose
        >
            {type === SsoProviderTypeEnum.GOOGLE ? (
                <SsoProviderConfigurationForm
                    handleSubmit={handleSubmit}
                    formInstance={formInstance}
                    connection={connection}
                    learnMoreLink={
                        <a href={SSO_SETUP_RESOURCES[SsoProviderTypeEnum.GOOGLE].howToConnect[languageCode]} rel="noreferrer" target="_blank">
                            {t("label:learn_more")}
                        </a>
                    }
                    redirectUrl={`${API_URL}/sso/google/callback`}
                    clientIdLink={{
                        to: SSO_SETUP_RESOURCES[SsoProviderTypeEnum.GOOGLE].clientIds[languageCode],
                        label: t("label:sso.how_to_obtain_client_id"),
                    }}
                />
            ) : type === SsoProviderTypeEnum.AZURE_AD ? (
                <SsoProviderConfigurationForm
                    handleSubmit={handleSubmit}
                    formInstance={formInstance}
                    connection={connection}
                    learnMoreLink={
                        <a href={SSO_SETUP_RESOURCES[SsoProviderTypeEnum.AZURE_AD].howToConnect[languageCode]} rel="noreferrer" target="_blank">
                            {t("label:learn_more")}
                        </a>
                    }
                    redirectUrl={`${API_URL}/sso/azure-ad/callback`}
                    clientIdLink={{
                        to: SSO_SETUP_RESOURCES[SsoProviderTypeEnum.AZURE_AD].clientIds[languageCode],
                        label: t("label:sso.how_to_obtain_client_id"),
                    }}
                />
            ) : null}
        </Modal>
    )
}

export default React.memo(SsoProviderModal)
