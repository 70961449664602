import { DocumentChangeInterface, OperationTypeEnum } from "@finway-group/shared/lib/models"
import * as Sentry from "@sentry/react"

import i18n from "Shared/locales/i18n"
import store from "Shared/store"
import { refetchTable } from "Shared/store/actions/tables/tableActions"
import { SyncVendor, fetchAllVendors } from "Shared/store/actions/vendor/vendorActions"
import { TablesEnum } from "Shared/store/reducers/tableConfigReducer"
import { isClientErrorCode } from "Shared/utils/errorHandler.utils"

import FileService from "../file.service"
import NotificationService, { NotificationTypeEnum } from "../notification.service"

const subscribeToVendor = async (dispatch: any, data: DocumentChangeInterface) => {
    const {
        fullDocument: { documentId, operationType },
    } = data

    try {
        switch (operationType) {
            case OperationTypeEnum.INSERT:
            case OperationTypeEnum.UPDATE:
            case OperationTypeEnum.UPDATE_COMMENT:
            case OperationTypeEnum.INSERT_COMMENT:
            case OperationTypeEnum.DELETE_COMMENT:
                await dispatch(SyncVendor(documentId))
                dispatch(refetchTable(TablesEnum.VENDORS))
                break
            case OperationTypeEnum.DELETE:
                dispatch(fetchAllVendors())
                break
            case OperationTypeEnum.BULK_UPDATE:
                await dispatch(fetchAllVendors())
                dispatch(refetchTable(TablesEnum.VENDORS))
                if (operationType === OperationTypeEnum.BULK_UPDATE) {
                    // Perform sync to the currently opened vendor
                    const currentOpenedVendor = store.getState().vendors.item?.id
                    if (currentOpenedVendor) dispatch(SyncVendor(currentOpenedVendor))
                }
                break
            default:
                break
        }
    } catch (err) {
        if (isClientErrorCode(err)) return
        Sentry.captureException(`[Vendor Socket] Error: ${typeof err === "string" ? err : JSON.stringify(err)}`)
    }
}

/**
 * For async actions for the same user who initiated it
 */
export const subscribeToVendorCallbacks = (data: DocumentChangeInterface) => {
    // prettier-ignore
    const { fullDocument: { operationType, metaData } } = data;

    switch (operationType) {
        case OperationTypeEnum.ASYNC_EXPORT:
            NotificationService.send(NotificationTypeEnum.SUCCESS, i18n.t("notification:vendor.exported_bulk.title"), i18n.t("notification:vendor.exported_bulk.message"), 0)
            FileService.downloadFileAsSignedUrl((metaData as any).fileUrl)
            break
        default:
            break
    }
}

export default subscribeToVendor
