import { Button, Col, Form, Modal, Row } from "antd"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"

import { NOTIFICATION_OPEN_DURATION } from "Shared/config/consts"
import { useWeavrUpdatePasscodeInput } from "Shared/hooks/weavr.hooks"
import { CorporateService, NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

interface WeavrUpdatePasscodeModalProps {
    isShowing: boolean
    onCancel?: () => void
    onSuccess?: () => void
}

const WeavrUpdatePasscodeModal = ({ isShowing, onCancel, onSuccess }: WeavrUpdatePasscodeModalProps) => {
    const { t } = useTranslation()
    const [formInstance] = Form.useForm()
    const [isLoading, setIsLoading] = useStateIfMounted<boolean>(false)
    const { secureForm, setShouldLoad } = useWeavrUpdatePasscodeInput("old-passcode-field", "new-passcode-field", isShowing)
    const [oldPasscodeEmpty, setOldPasscodeEmpty] = useStateIfMounted(false)
    const [newPasscodeEmpty, setNewPasscodeEmpty] = useStateIfMounted(false)

    useEffect(() => {
        if (isShowing) setShouldLoad(true)
    }, [isShowing])

    const onSubmit = () => {
        setIsLoading(true)
        secureForm.tokenize(
            (tokens: any) => {
                const { oldPasscode, newPasscode } = tokens

                const isOldPasscodeInvalid = !oldPasscode || oldPasscode.length === 0
                setOldPasscodeEmpty(isOldPasscodeInvalid)

                const isNewPasscodeInvalid = !newPasscode || newPasscode.length === 0
                setNewPasscodeEmpty(isNewPasscodeInvalid)

                if (isOldPasscodeInvalid || isNewPasscodeInvalid) {
                    setIsLoading(false)
                    return
                }

                CorporateService.updateUserPasscode(oldPasscode, newPasscode)
                    .then(() => {
                        NotificationService.send(
                            NotificationTypeEnum.SUCCESS,
                            t(`corporate:passcode.update_success`),
                            t(`corporate:passcode.update_success`),
                            NOTIFICATION_OPEN_DURATION,
                        )
                        handleHide()
                        onSuccess?.()
                    })
                    .catch((err) => NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:error")))
                    .finally(() => setIsLoading(false))
                setIsLoading(false)
            },
            (err: any) => {
                NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:error"))
                setIsLoading(false)
            },
        )
    }

    const handleHide = () => {
        setIsLoading(false)
        formInstance.resetFields()
        onCancel?.()
    }

    return (
        <>
            <Modal
                visible={isShowing}
                maskClosable={false}
                title={t(`corporate:passcode.update`)}
                onCancel={handleHide}
                className="ant-modal--small"
                footer={[
                    <Button key="back" onClick={() => onCancel?.()}>
                        {t("action:cancel")}
                    </Button>,
                    <Button key="submit" type="primary" loading={isLoading} onClick={formInstance.submit}>
                        {t("action:submit")}
                    </Button>,
                ]}
                destroyOnClose
                closable
                keyboard
            >
                <Form form={formInstance} onFinish={onSubmit} layout="vertical">
                    <Row gutter={[16, 0]}>
                        <Col span={12}>
                            <Row>
                                <div className="ant-form-item-label">
                                    <label className={"ant-form-item-required"}>{t(`corporate:passcode.enter_old_passcode`)}</label>
                                </div>
                            </Row>
                            <Row>
                                <div className="ant-input rounded-lg min-h-32 mt-4 pt-8 w-140" id="old-passcode-field" />
                                {oldPasscodeEmpty && <span className="text-error">{t("validation:required")}</span>}
                            </Row>
                        </Col>
                        <Col span={12}>
                            <Row>
                                <div className="ant-form-item-label">
                                    <label className={"ant-form-item-required"}>{t(`corporate:passcode.enter_new_passcode`)}</label>
                                </div>
                            </Row>
                            <Row>
                                <div className="ant-input rounded-lg min-h-32 mt-4 pt-8 w-140" id="new-passcode-field" />
                                {newPasscodeEmpty && <span className="text-error">{t("validation:required")}</span>}
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    )
}

export default WeavrUpdatePasscodeModal
