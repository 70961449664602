import { TransactionCategory } from "@finway-group/shared/lib/models"
import { Reducer } from "redux"

import {
    CreateTransactionCategoryAction,
    DeleteTransactionCategoryAction,
    FetchAllTransactionCategoryAction,
    SyncOneTransactionCategoryAction,
    TransactionCategoryActionTypes,
    TransactionCategoryActions,
    UpdateTransactionCategoryAction,
    UpdateTransactionCategoryOrderAction,
} from "../actions/transactionCategory/transactionCategoryTypes"

export interface TransactionCategoryState {
    items: Array<TransactionCategory>
}

const initialState = {
    items: [] as Array<TransactionCategory>,
}

const setTransactionCategories = (state: TransactionCategoryState, action: FetchAllTransactionCategoryAction | UpdateTransactionCategoryOrderAction): TransactionCategoryState => ({
    items: action.transactionCategories,
})

const addTransactionCategory = (state: TransactionCategoryState, action: CreateTransactionCategoryAction): TransactionCategoryState => {
    const items = [...state.items]
    items.push(action.transactionCategory)
    if (action.parentTransactionCategory) return { items: items.map((item) => (item.id === action.parentTransactionCategory.id ? action.parentTransactionCategory : item)) }
    return { items }
}

const updateTransactionCategory = (state: TransactionCategoryState, action: UpdateTransactionCategoryAction): TransactionCategoryState => ({
    items: [...state.items.map((item) => (item.id === action.transactionCategory.id ? action.transactionCategory : item))],
})

const deleteTransactionCategory = (state: TransactionCategoryState, action: DeleteTransactionCategoryAction): TransactionCategoryState => {
    if (action.updatedParentTransactionCategory) {
        const parentIdx = state.items.findIndex((item: any) => item._id === action.updatedParentTransactionCategory.id)
        state.items[parentIdx] = action.updatedParentTransactionCategory
    }
    return {
        items: state.items.filter((transactionCategory) => !action.deletedTransactionCategoriesIds.includes(transactionCategory.id)),
    }
}

const syncOneTransactionCategory = (state: TransactionCategoryState, { transactionCategory }: SyncOneTransactionCategoryAction): TransactionCategoryState => {
    const isIncludedInStateItems = state.items.filter((txCat) => txCat.id === transactionCategory.id).length > 0
    return {
        ...state,
        items: isIncludedInStateItems ? state.items.map((item) => (item.id === transactionCategory.id ? transactionCategory : item)) : [...state.items, transactionCategory],
    }
}

export const transactionCategoryReducer: Reducer<TransactionCategoryState, TransactionCategoryActions> = (state = initialState, action) => {
    switch (action.type) {
        case TransactionCategoryActionTypes.FETCH_ALL_TRANSACTION_CATEGORIES:
            return setTransactionCategories(state, action)
        case TransactionCategoryActionTypes.CREATE_TRANSACTION_CATEGORY:
            return addTransactionCategory(state, action)
        case TransactionCategoryActionTypes.UPDATE_TRANSACTION_CATEGORY:
            return updateTransactionCategory(state, action)
        case TransactionCategoryActionTypes.DELETE_TRANSACTION_CATEGORY:
            return deleteTransactionCategory(state, action)
        case TransactionCategoryActionTypes.UPDATE_TRANSACTION_CATEGORY_ORDER:
            return setTransactionCategories(state, action)
        case TransactionCategoryActionTypes.SYNC_ONE_TRANSACTION_CATEGORY:
            return syncOneTransactionCategory(state, action)

        default:
            return state
    }
}
