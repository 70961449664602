import { Employee, Hospitality, ItemSplit, PriceIntervalEnum } from "@finway-group/shared/lib/models"
import { ItemSplitKindEnum } from "@finway-group/shared/lib/models/expense/expenseSplit.model"
import { Avatar, Col, Divider, Row, Tooltip } from "antd"
import moment from "moment"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

import PriceLabel from "Components/priceLabel"
import { useEmployees } from "Shared/hooks/employee.hooks"
import { useTaxes } from "Shared/hooks/tax.hooks"
import { EmployeeService } from "Shared/services"
import { getTaxLabel, getTooltipPopupContainer } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"
import { parseHtml } from "Shared/utils/htmlParser.utils"

import { ExpenseHeader } from "../auxiliary/expenseHeader"

type AttendeeType = {
    internal: boolean
    employee: Employee
}
interface HospitalityMailDetailsSectionInterface {
    expense: Hospitality
    showHeader?: boolean
}

export const HospitalityMainDetailsSection: React.FC<HospitalityMailDetailsSectionInterface> = ({ expense, showHeader }) => {
    const { t } = useTranslation()
    const employees = useEmployees({ excludeDeleted: true })
    const taxes = useTaxes()
    const [attendees, setAttendees] = useStateIfMounted<Array<AttendeeType>>([])

    const renderInfoItem = (label: string, info: any) => (
        <>
            <label>{label}</label>
            <div className="flex justify-start h-32 items-center">
                <div className="leading-tight truncate text-base font-medium">{info}</div>
            </div>
        </>
    )

    useEffect(() => {
        let mounted = true
        const fetchAttendees = async () => {
            const attendeeList: Array<AttendeeType> = []
            expense?.attendees.map(async ({ user, internal }) => {
                if (!internal) {
                    attendeeList.push({ employee: new Employee({ firstName: user, lastName: "" }), internal })
                    return
                }

                const employee = employees.find(({ id }) => id === user) || (await EmployeeService.fetchEmployeeById(user))
                if (employee) {
                    attendeeList.push({ employee, internal })
                }
            })

            setAttendees(attendeeList)
        }

        if (expense?.attendees && mounted) {
            fetchAttendees()
        }

        return () => {
            mounted = false
        }
    }, [expense?.attendees])

    const CustomAttendeeAvatar = ({ employee, internal }: AttendeeType) => {
        const [showInitials, setShowInitials] = useStateIfMounted<boolean>(false)

        const handleAvatarError = () => {
            setShowInitials(true)
            return false
        }

        const LinkWrapper = ({ children, employeeId }: { children: React.ReactNode; employeeId: string }) => (
            <>{employeeId ? <Link to={`/employees/${employeeId}`}>{children}</Link> : <>{children}</>}</>
        )

        if (internal && employee?.img && !showInitials) {
            return (
                <LinkWrapper employeeId={employee.id}>
                    <Tooltip getPopupContainer={getTooltipPopupContainer} title={employee?.getFullName()} placement="top">
                        <Avatar src={employee?.img} onError={handleAvatarError} />
                    </Tooltip>
                </LinkWrapper>
            )
        }

        return (
            <LinkWrapper employeeId={employee.id}>
                <Tooltip getPopupContainer={getTooltipPopupContainer} title={employee.getFullName()} placement="top">
                    <Avatar style={{ backgroundColor: "#f56a00" }}>{employee.getInitials().toUpperCase()?.replace(/\s/g, "")}</Avatar>
                </Tooltip>
            </LinkWrapper>
        )
    }

    const AttendeesView = () => (
        <Avatar.Group maxCount={4}>
            {attendees.map(({ internal, employee }, index) => (
                <CustomAttendeeAvatar key={index} internal={internal} employee={employee} />
            ))}
        </Avatar.Group>
    )

    const tip = expense.splits.find((split) => (split as ItemSplit).kind === ItemSplitKindEnum.TIP)?.grossPrice

    return (
        <>
            <Row gutter={[16, 24]}>
                {showHeader && (
                    <>
                        <Col span={24}>
                            <ExpenseHeader expense={expense} />
                        </Col>
                        <Divider className="mt-0 mb-20" />
                    </>
                )}
                <Col xs={12} sm={8} md={8}>
                    {renderInfoItem(t("label:hospitality.reason"), expense.description ? parseHtml(expense.description) : t("label:n/a"))}
                </Col>
                <Col xs={12} sm={8} md={4}>
                    {renderInfoItem(t("label:total_net_amount"), <PriceLabel value={expense.totalNetPrice} currency={expense.currency} interval={PriceIntervalEnum.ONE_TIME} />)}
                </Col>
                <Col xs={12} sm={8} md={4}>
                    {renderInfoItem(
                        t("label:vat_unit", { unit: "%" }),
                        getTaxLabel({
                            taxRate: expense.taxRate?.taxRate,
                            buCode: expense.taxRate?.buCode,
                            buCodeName: taxes.find((tax) => tax._id === expense.taxRate?._id)?.buCodeName,
                        }),
                    )}
                </Col>
                <Col xs={12} sm={8} md={4}>
                    {renderInfoItem(t("label:vat"), <PriceLabel value={expense.totalTaxPrice} currency={expense.currency} interval={PriceIntervalEnum.ONE_TIME} />)}
                </Col>
                <Col xs={12} sm={8} md={4}>
                    {renderInfoItem(
                        t("label:total_gross_amount"),
                        <PriceLabel value={expense.totalGrossPrice} currency={expense.currency} interval={PriceIntervalEnum.ONE_TIME} />,
                    )}
                </Col>

                <Col xs={12} sm={8} md={3}>
                    {renderInfoItem(t("label:hospitality.tip"), tip ? <PriceLabel value={tip} currency={expense.currency} interval={PriceIntervalEnum.ONE_TIME} /> : "-")}
                </Col>

                <Col xs={12} sm={8} md={5}>
                    {renderInfoItem(t("label:invoice_no"), expense.invoiceNumber ?? t("label:n/a"))}
                </Col>
                <Col xs={12} sm={8} md={4}>
                    {renderInfoItem(t("label:invoice_date"), expense.invoiceDate ? moment(expense.invoiceDate).format("ll") : t("label:n/a"))}
                </Col>
                <Col xs={12} sm={8} md={4}>
                    {renderInfoItem(t("label:hospitality.merchant"), (expense as any).merchant)}
                </Col>
                <Col xs={12} sm={8} md={4}>
                    {renderInfoItem(t("label:hospitality.date"), expense.datePurchased ? moment(expense.datePurchased).format("ll") : t("label:n/a"))}
                </Col>
                <Col xs={12} sm={8} md={4}>
                    {renderInfoItem(t("label:hospitality.attendees"), <AttendeesView />)}
                </Col>
            </Row>
        </>
    )
}
