import { Currencies } from "@finway-group/shared/lib/consts"
import { ApproverTypeEnum, CurrencyEnum, Employee, LogicalOperatorEnum, WorkflowConditionEnum, currencyEnumArray } from "@finway-group/shared/lib/models"
import { Button, Col, Form, Image, Row, Select, Steps } from "antd"
import React, { useEffect, useRef } from "react"
import { Plus as PlusIcon, Trash2 as TrashIcon } from "react-feather"
import { useTranslation } from "react-i18next"

import { SwitchToogleButton } from "Components/actionButton/switchToogleButton"
import CurrencyInput from "Components/currencyInput"
import FormItemCurrency from "Components/form/formItemCurrency"
import { useEmployees, useLoggedInEmployee } from "Shared/hooks/employee.hooks"
import { EmployeeService, UserService } from "Shared/services"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"
import { getPopupAnchor } from "Shared/utils/popup.utils"

import { WorkflowFormResourceTypeEnum, WorkflowTriggerObjectSelect } from "./triggerObjectSelect"
import { WorkflowFormTypeEnum, WorkflowFormValuesInterface } from "./workflowFormValues.interface"

interface WorkflowFormStepsInterface {
    formValues: WorkflowFormValuesInterface
    setFormValues: (formValues: Partial<WorkflowFormValuesInterface>) => void
    rules: any
}

export const WorkflowFormSteps: React.FC<WorkflowFormStepsInterface> = ({ formValues, setFormValues, rules }) => {
    const { t } = useTranslation()
    const loggedInEmployee = useLoggedInEmployee()
    const autoApprover = EmployeeService.getAutoApprover()
    const employees = useEmployees()

    const [isAutoApproverSelected, setIsAutoApproverSelected] = useStateIfMounted(formValues.steps?.[0]?.approver === autoApprover.id)
    const possibleApprovers = UserService.getApproverEmployeeList(employees)

    const anchorRef = useRef(null)

    // Handles selection of auto approver. By design, if auto approver is selected we do not allow other approvers on the step. Additionally, auto approver can only be selected  on first step.
    useEffect(() => {
        const { steps } = formValues
        const firstStepApprover = formValues.steps?.[0]?.approver
        if (firstStepApprover === ApproverTypeEnum.AUTO_APPROVER) {
            steps[0].otherApprovers = []
            steps[0].operator = undefined
            steps[0].condition = undefined
            setFormValues({ steps })
            setIsAutoApproverSelected(true)
        } else if (isAutoApproverSelected === true) setIsAutoApproverSelected(false)
    }, [formValues.steps?.[0]?.approver])

    // Request workflow: Enforce net amount condition on second step when auto approver is selected.
    // Folder workflow: Prevent any other step from being created.
    useEffect(() => {
        let { steps } = formValues
        if (steps?.[0]?.approver === ApproverTypeEnum.AUTO_APPROVER && steps[1]) {
            if (formValues.workflowType === WorkflowFormTypeEnum.FOLDER) {
                steps = [steps[0]]
            } else {
                steps[1].condition = WorkflowConditionEnum.NET_AMOUNT
                steps[1].currency = loggedInEmployee.settings.globalCurrency ?? CurrencyEnum.EUR
            }
            setFormValues({ steps })
        }
    }, [formValues.steps?.[0]?.approver, formValues.steps?.length === 2, formValues.steps?.[1]?.condition])

    const handleNetAmountConditionClick = (stepIndex: number) => {
        const { steps } = formValues
        steps[stepIndex].condition = WorkflowConditionEnum.NET_AMOUNT
        steps[stepIndex].currency = loggedInEmployee.settings.globalCurrency ?? CurrencyEnum.EUR
        setFormValues({ steps })
    }

    const handleRemove = (stepIndex: number, isRemovalOfCondition: boolean, removeElement: (index: number | Array<number>) => void) => {
        const { steps } = formValues
        if (isRemovalOfCondition) {
            steps[stepIndex].condition = undefined
            setFormValues({ steps })
        } else {
            removeElement(stepIndex)
        }
    }

    const handleSelectApprover = (stepIndex: number, approver: string) => {
        const { steps } = formValues
        steps[stepIndex].approver = approver
        setFormValues({ steps })
    }

    const handleSelectOtherApprovers = (stepIndex: number, otherApprovers: Array<string>) => {
        const { steps } = formValues
        steps[stepIndex].otherApprovers = otherApprovers
        setFormValues({ steps })
    }

    const renderNetAmountButton = (stepIndex: number) => (
        <Button className="h-32 mt-6" onClick={() => handleNetAmountConditionClick(stepIndex)}>
            {">€"}
        </Button>
    )
    const renderRemoveButton = (stepIndex: number, isRemovalOfCondition: boolean, removeElement: (index: number | Array<number>) => void) => (
        <Button className="h-32 mt-6" onClick={() => handleRemove(stepIndex, isRemovalOfCondition, removeElement)}>
            <TrashIcon />
        </Button>
    )

    const handleAndOrSwitchClick = (stepIndex: number, selectedButtons: { button1: boolean; button2: boolean }) => {
        const { steps } = formValues
        const removeOperator = () => {
            steps[stepIndex].operator = undefined
            steps[stepIndex].otherApprovers = []
        }

        if (selectedButtons.button1) {
            if (steps[stepIndex].operator === LogicalOperatorEnum.AND) removeOperator()
            else steps[stepIndex].operator = LogicalOperatorEnum.AND
        } else if (selectedButtons.button2) {
            if (steps[stepIndex].operator === LogicalOperatorEnum.OR) removeOperator()
            else steps[stepIndex].operator = LogicalOperatorEnum.OR
        } else {
            removeOperator()
        }
        setFormValues({ steps })
    }

    const renderButtonSet = (step: WorkflowFormValuesInterface["steps"][0], stepIndex: number, remove: (index: number | Array<number>) => void) => {
        const isTheAutoApprover = step.approver === ApproverTypeEnum.AUTO_APPROVER
        const shouldRenderNetAmountButton = formValues.workflowType !== WorkflowFormTypeEnum.FOLDER && !step.condition && !isTheAutoApprover
        return (
            <Col span={3} className="flex gap-4">
                {shouldRenderNetAmountButton && renderNetAmountButton(stepIndex)}
                {stepIndex > 0 && renderRemoveButton(stepIndex, false, remove)}
            </Col>
        )
    }

    return (
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <Form.List name="steps">
                    {(fields, { add, remove }) => (
                        <div className="workflows workflow-steps" ref={anchorRef}>
                            <Steps className="" direction="vertical" size="small" current={-1}>
                                {fields.map((_field, stepIndex) => {
                                    const { steps } = formValues
                                    const step = steps[stepIndex]
                                    if (!step) return <></>
                                    const isTheAutoApprover = (approver: Employee) => approver.id === autoApprover.id
                                    const isDeleted = (approver: Employee) => approver.activeCompanyProfile.deleted
                                    const currentlySelectedApprover = formValues.steps[stepIndex].approver
                                    const isAlreadyInTheApproverDropdown = (approver: Employee) => approver.id === currentlySelectedApprover
                                    const isAlreadyInTheOtherApproversDropdown = (approver: Employee) => formValues.steps[stepIndex].otherApprovers.includes(approver.id)
                                    const approverSelectOptions = possibleApprovers.filter(
                                        (approver) =>
                                            !isTheAutoApprover(approver) && // Select approvers that are not the auto approver, ...
                                            !isAlreadyInTheOtherApproversDropdown(approver) && // ... that are not already in the other dropdown  ...
                                            (!isDeleted(approver) || isAlreadyInTheApproverDropdown(approver)), // ... and that are not deleted, unless the approver was already in the form. (So we don't show the mongo string id)
                                    )

                                    // add the currently selected approver to the approverSelectOptions if it is not already in there, so we don't show a doc id
                                    if (
                                        !!currentlySelectedApprover &&
                                        !(Object.values(ApproverTypeEnum) as Array<string>).includes(currentlySelectedApprover) &&
                                        !approverSelectOptions.filter((selectOption) => selectOption.id === currentlySelectedApprover).length
                                    ) {
                                        approverSelectOptions.push(EmployeeService.getEmployeeById(currentlySelectedApprover))
                                    }

                                    const otherApproversSelectOptions = possibleApprovers.filter(
                                        (approver) =>
                                            !isTheAutoApprover(approver) &&
                                            !isAlreadyInTheApproverDropdown(approver) &&
                                            (!isDeleted(approver) || isAlreadyInTheOtherApproversDropdown(approver)),
                                    )

                                    return (
                                        <Steps.Step
                                            key={stepIndex}
                                            status="process"
                                            description={
                                                <>
                                                    {step.condition === WorkflowConditionEnum.NET_AMOUNT && (
                                                        <Row align="middle" className="w-full" gutter={[8, 0]}>
                                                            <Col span={5}>
                                                                <FormItemCurrency
                                                                    name={[stepIndex, "amount"]}
                                                                    label={t("input:workflow.condition.net_amount_bigger")}
                                                                    required
                                                                    rules={rules.step.amount}
                                                                    currency={step.currency || loggedInEmployee.settings.globalCurrency}
                                                                >
                                                                    <CurrencyInput
                                                                        className="w-full react-currency-input"
                                                                        isNegativeAllowed={false}
                                                                        value={0}
                                                                        currency={step.currency || loggedInEmployee.settings.globalCurrency}
                                                                    />
                                                                </FormItemCurrency>
                                                            </Col>
                                                            <Col span={4}>
                                                                <Form.Item
                                                                    name={[stepIndex, "currency"]}
                                                                    label={t("input:request.currency")}
                                                                    key="currency"
                                                                    initialValue={step.currency || loggedInEmployee.settings.globalCurrency}
                                                                    rules={rules.step.currency}
                                                                >
                                                                    <Select showSearch getPopupContainer={getPopupAnchor(anchorRef.current)}>
                                                                        {currencyEnumArray.map((currencyValue, index) => (
                                                                            <Select.Option key={index} value={currencyValue} label={currencyValue}>
                                                                                <div className="space-x-10">
                                                                                    <Image
                                                                                        className="align-middle"
                                                                                        width={20}
                                                                                        preview={false}
                                                                                        src={`data:image/png;base64,${Currencies[currencyValue].flag}`}
                                                                                    />
                                                                                    <span className="text-text">{currencyValue}</span>
                                                                                </div>
                                                                            </Select.Option>
                                                                        ))}
                                                                    </Select>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={2}>
                                                                {formValues.steps?.[stepIndex]?.approver !== ApproverTypeEnum.AUTO_APPROVER &&
                                                                    renderRemoveButton(stepIndex, true, remove)}
                                                            </Col>
                                                        </Row>
                                                    )}

                                                    <Row align="middle" className="w-full" gutter={[8, 8]}>
                                                        <Col span={9} data-testid="selectApprover">
                                                            <WorkflowTriggerObjectSelect
                                                                name={[stepIndex, "approver"]}
                                                                label={t("input:workflow.approver")}
                                                                required={true}
                                                                index={stepIndex}
                                                                resources={approverSelectOptions}
                                                                resourceType={WorkflowFormResourceTypeEnum.EMPLOYEE}
                                                                selectedResourcesIds={[step.approver]}
                                                                includeAutoApprover={stepIndex === 0}
                                                                includeSuperior={!formValues.steps[stepIndex].otherApprovers.includes(ApproverTypeEnum.SUPERIOR)}
                                                                includeResponsibleEmployee={!formValues.steps[stepIndex].otherApprovers.includes(ApproverTypeEnum.RESPONSIBLE_USER)}
                                                                includeRequester={!formValues.steps[stepIndex].otherApprovers.includes(ApproverTypeEnum.REQUESTER)}
                                                                anchor={getPopupAnchor(anchorRef.current)}
                                                                onSelect={handleSelectApprover}
                                                                rules={rules.step.approver}
                                                            />
                                                        </Col>
                                                        {formValues.steps[stepIndex].approver !== ApproverTypeEnum.AUTO_APPROVER && (
                                                            <Col span={3} className="mt-6">
                                                                <SwitchToogleButton
                                                                    value={{
                                                                        button1: formValues.steps[stepIndex].operator === LogicalOperatorEnum.AND,
                                                                        button2: formValues.steps[stepIndex].operator === LogicalOperatorEnum.OR,
                                                                    }}
                                                                    onClick={(selectedButtons) => handleAndOrSwitchClick(stepIndex, selectedButtons)}
                                                                    label1={t("input:workflow.operator.and")}
                                                                    label2={t("input:workflow.operator.or")}
                                                                />
                                                            </Col>
                                                        )}
                                                        {step.operator &&
                                                            [LogicalOperatorEnum.AND, LogicalOperatorEnum.OR].includes(step.operator) &&
                                                            (!isAutoApproverSelected || stepIndex !== 0) && (
                                                                <Col span={9}>
                                                                    <WorkflowTriggerObjectSelect
                                                                        name={[stepIndex, "otherApprovers"]}
                                                                        label={t("input:workflow.approver")}
                                                                        required={true}
                                                                        isMultiple
                                                                        index={stepIndex}
                                                                        resources={otherApproversSelectOptions}
                                                                        resourceType={WorkflowFormResourceTypeEnum.EMPLOYEE}
                                                                        selectedResourcesIds={step.otherApprovers}
                                                                        includeSuperior={formValues.steps[stepIndex].approver !== ApproverTypeEnum.SUPERIOR}
                                                                        includeResponsibleEmployee={formValues.steps[stepIndex].approver !== ApproverTypeEnum.RESPONSIBLE_USER}
                                                                        includeRequester={formValues.steps[stepIndex].approver !== ApproverTypeEnum.REQUESTER}
                                                                        anchor={getPopupAnchor(anchorRef.current)}
                                                                        onSelect={handleSelectOtherApprovers}
                                                                        rules={rules.step.otherApprovers}
                                                                        maxOptions={2}
                                                                    />
                                                                </Col>
                                                            )}
                                                        {renderButtonSet(step, stepIndex, remove)}
                                                    </Row>
                                                </>
                                            }
                                        />
                                    )
                                })}
                            </Steps>

                            <Row gutter={[8, 0]}>
                                {(formValues.workflowType !== WorkflowFormTypeEnum.FOLDER || !isAutoApproverSelected) && (
                                    <Button onClick={() => add({ approver: "", otherApprovers: [] })}>
                                        <PlusIcon />
                                        <span className="ml-2">{t("action:workflow.next_step")}</span>
                                    </Button>
                                )}
                            </Row>
                        </div>
                    )}
                </Form.List>
            </Col>
        </Row>
    )
}
