import Icon from "@ant-design/icons"
import React from "react"

const addCard = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
            <path
                d="M14.2632 8.33009V2.94737C14.2632 2.42415 13.839 2 13.3158 2H1.94737C1.42415 2 1 2.42415 1 2.94737V10.5263C1 11.0495 1.42415 11.4737 1.94737 11.4737H9.27417"
                stroke="#606C7E"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M14.2632 3.89453H1V5.78927H14.2632V3.89453Z" stroke="#606C7E" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M12.3688 14.0004C14.1129 14.0004 15.5267 12.5865 15.5267 10.8425C15.5267 9.09841 14.1129 7.68457 12.3688 7.68457C10.6248 7.68457 9.21094 9.09841 9.21094 10.8425C9.21094 12.5865 10.6248 14.0004 12.3688 14.0004Z"
                stroke="#606C7E"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M11.1055 10.8418H13.6318" stroke="#606C7E" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12.3691 9.5791V12.1054" stroke="#606C7E" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        </g>
        <defs>
            <clipPath id="clip0">
                <rect width="16" height="16" fill="white" />
            </clipPath>
        </defs>
    </svg>
)

const AddCardIcon = (props: any) => <Icon component={addCard} {...props} />

export default AddCardIcon
