import { FilterBaseInterface } from "@finway-group/shared/lib/models/filter/base.filter"
import axios from "axios"

import { TablesEnum } from "Shared/store/reducers/tableConfigReducer"

const MODEL = "filters"

export interface Filter {
    _id: string
    user: string
    table: TablesEnum
    parameters: FilterBaseInterface
    companyId: string
    tenantId: string
}

const FilterService = {
    getFilters: async () => {
        const { data } = await axios.get<Array<Filter>>(`${MODEL}`)
        return data
    },

    setFilter: async (table: TablesEnum, filterData: FilterBaseInterface) => {
        const { data } = await axios.put(`${MODEL}?page=1&table[eq]=${table}`, { parameters: filterData })
        return data as FilterBaseInterface
    },
}

export default FilterService
