export enum RoleConfigFormElementsEnum {
    ALL_NO_EDIT = "ALL_NO_EDIT",
    ALL_VIEW = "ALL_VIEW",
    INBOX_INVOICE_MANAGE_ALL = "INBOX_INVOICE_MANAGE_ALL",
    REQUEST_VIEW_ALL = "REQUEST_VIEW_ALL",
    REQUEST_VIEW_ALL_TEAM = "REQUEST_VIEW_ALL_TEAM",
    REQUEST_CREATE_ON_BEHALF = "REQUEST_CREATE_ON_BEHALF",
    SELECTABLE_AS_APPROVER = "SELECTABLE_AS_APPROVER",
}

export enum RightFormElementsEnum {
    REQUEST_ACCESS_TEAM = "REQUEST_ACCESS_TEAM",
    REQUEST_ABILITY_APPROVE_TEAM = "REQUEST_ABILITY_APPROVE_TEAM",
    REQUEST_ABILITY_MANAGE_TEAM = "REQUEST_ABILITY_MANAGE_TEAM",
    REQUEST_ACCESS = "REQUEST_ACCESS",
    REQUEST_ABILITY_APPROVE = "REQUEST_ABILITY_APPROVE",
    REQUEST_ABILITY_MANAGE = "REQUEST_ABILITY_MANAGE",
    COST_CENTER_1_ABILITY_OWNER = "COST_CENTER_1_ABILITY_OWNER",
    REQUEST_ABILITY_APPROVE_DASHBOARD = "REQUEST_ABILITY_APPROVE_DASHBOARD",

    CARD_ACCESS_TEAM = "CARD_ACCESS_TEAM",
    CARD_ABILITY_MANAGE_TEAM = "CARD_ABILITY_MANAGE_TEAM",
    CARD_ACCESS = "CARD_ACCESS",
    CARD_ABILITY_MANAGE = "CARD_ABILITY_MANAGE",
    CARD_WALLET_ACCESS = "CARD_WALLET_ACCESS",
    CARD_WALLET_ABILITY_MANAGE = "CARD_WALLET_ABILITY_MANAGE",

    COST_CENTER_1_ACCESS = "COST_CENTER_1_ACCESS",
    COST_CENTER_1_ABILITY_MANAGE = "COST_CENTER_1_ABILITY_MANAGE",

    EXPORT_ACCESS = "EXPORT_ACCESS",
    EXPORT_ABILITY_EXPORT_ALL_FORMATS = "EXPORT_ABILITY_EXPORT_ALL_FORMATS",

    REQUEST_ACCESS_FORMAL_REVIEW = "REQUEST_ACCESS_FORMAL_REVIEW",
    REQUEST_ABILITY_FORMAL_REVIEW = "REQUEST_ABILITY_FORMAL_REVIEW",

    LIQUIDITY_ACCESS = "LIQUIDITY_ACCESS",
    LIQUIDITY_ABILITY_MANAGE = "LIQUIDITY_ABILITY_MANAGE",

    PAY_ACCESS = "PAY_ACCESS",
    PAY_ABILITY_PAY_REQUESTS = "PAY_ABILITY_PAY_REQUESTS",

    REPORTING_ACCESS = "REPORTING_ACCESS",

    COMPANY_FEATURE_SETTINGS_ACCESS = "COMPANY_FEATURE_SETTINGS_ACCESS",
    COMPANY_FEATURE_SETTINGS_ABILITY_MANAGE = "COMPANY_FEATURE_SETTINGS_ABILITY_MANAGE",
    COMPANY_ACCOUNTING_SETTINGS_ACCESS = "COMPANY_ACCOUNTING_SETTINGS_ACCESS",
    COMPANY_ACCOUNTING_SETTINGS_ABILITY_MANAGE = "COMPANY_ACCOUNTING_SETTINGS_ABILITY_MANAGE",

    BANK_ACCOUNT_ACCESS = "BANK_ACCOUNT_ACCESS",
    BANK_ACCOUNT_ABILITY_WRITE = "BANK_ACCOUNT_ABILITY_WRITE",

    REQUEST_SUBSCRIPTION_TEAM_ACCESS = "REQUEST_SUBSCRIPTION_TEAM_ACCESS",
    REQUEST_SUBSCRIPTION_TEAM_ABILITY_MANAGE = "REQUEST_SUBSCRIPTION_TEAM_ABILITY_MANAGE",
    REQUEST_SUBSCRIPTION_ACCESS = "REQUEST_SUBSCRIPTION_ACCESS",
    REQUEST_SUBSCRIPTION_ABILITY_MANAGE = "REQUEST_SUBSCRIPTION_ABILITY_MANAGE",

    TRANSACTION_ACCESS = "TRANSACTION_ACCESS",
    TRANSACTION_ABILITY_MANAGE = "TRANSACTION_ABILITY_MANAGE",

    USER_ACCESS = "USER_ACCESS",
    USER_ACCESS_TEAM = "USER_ACCESS_TEAM",
    USER_ABILITY_APPROVAL_DATA_EDIT_ALL = "USER_ABILITY_APPROVAL_DATA_EDIT_ALL",
    USER_ABILITY_APPROVAL_DATA_EDIT_TEAM = "USER_ABILITY_APPROVAL_DATA_EDIT_TEAM",
    USER_ABILITY_GLOBAL_DATA_EDIT_ALL = "USER_ABILITY_GLOBAL_DATA_EDIT_ALL",
    USER_ABILITY_GLOBAL_DATA_EDIT_TEAM = "USER_ABILITY_GLOBAL_DATA_EDIT_TEAM",

    VENDOR_ACCESS = "VENDOR_ACCESS",
    VENDOR_ABILITY_MANAGE = "VENDOR_ABILITY_MANAGE",
    VENDOR_PAYMENT_DATA_ACCESS = "VENDOR_PAYMENT_DATA_ACCESS",
    VENDOR_PAYMENT_DATA_ABILITY_EDIT = "VENDOR_PAYMENT_DATA_EDIT",
    VENDOR_CONTRACT_ACCESS = "VENDOR_CONTRACT_ACCESS",
    VENDOR_CONTRACT_ABILITY_EDIT = "VENDOR_CONTRACT_ABILITY_EDIT",

    WORKFLOW_ACCESS = "WORKFLOW_ACCESS",
    WORKFLOW_ABILITY_ADJUST = "WORKFLOW_ABILITY_ADJUST",
    WORKFLOW_ABILITY_MANAGE = "WORKFLOW_ABILITY_MANAGE",
}

type RightsEnumUnion = RoleConfigFormElementsEnum | RightFormElementsEnum
export type RightEnumFormValue = { [key in RightsEnumUnion]?: boolean }
