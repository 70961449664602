import { Employee } from "@finway-group/shared/lib/models"
import { Tag, Tooltip } from "antd"
import Col from "antd/lib/col"
import Form, { Rule } from "antd/lib/form"
import { NamePath } from "antd/lib/form/interface"
import Row from "antd/lib/row"
import Select, { SelectValue } from "antd/lib/select"
import moment from "moment"
import { OptionProps } from "rc-select/lib/Option"
import React, { useRef } from "react"
import { useTranslation } from "react-i18next"

import UserImage from "Components/UserImage"
import { doFilterSelect, isNotSet } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"
import { getPopupAnchor } from "Shared/utils/popup.utils"
import { CollapseWrapper } from "Shared/wrapper/collapse.wrapper"

import { FormSelectOption, OnChangeEventType } from "./form.types"

export type EmployeeOption = Employee & Omit<OptionProps, "children" | "value">

interface FormSelectInterface {
    name: string | Array<string>
    header: string
    initialValue?: SelectValue
    options: Array<FormSelectOption> | Array<EmployeeOption> | Array<string>
    displayImages?: boolean
    showSearch?: boolean
    keyCode?: string
    mode?: "multiple" | "tags"
    fallback?: boolean | string
    fallbackValue?: string | number | null | undefined
    onChange?: OnChangeEventType | any
    isForm?: boolean
    isCollapse?: boolean
    isDisabled?: boolean
    rules?: Array<Rule>
    extra?: React.ReactNode
    dependencies?: Array<NamePath>
}

export default function FormSelect({
    name,
    header,
    initialValue,
    options,
    mode,
    rules,
    keyCode = "",
    fallback = "",
    fallbackValue = "",
    isForm = false,
    showSearch = false,
    displayImages = false,
    onChange,
    isCollapse = false,
    isDisabled = false,
    extra,
    dependencies = [],
}: FormSelectInterface) {
    const { t } = useTranslation()

    const [isActive, setIsActive] = useStateIfMounted(!!initialValue)

    const anchorRef = useRef(null)

    const fallbackText = fallback && fallback !== true ? fallback : t("input:filter_form.not_set")

    return (
        <>
            {/* empty div to fix ant-design bug for non-sticky dropdowns */}
            <div ref={anchorRef} />

            <CollapseWrapper keyCode={keyCode} header={header} isCollapse={isCollapse} isActive={isActive}>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Form.Item
                            name={name}
                            style={{ marginBottom: "0px" }}
                            initialValue={initialValue}
                            rules={rules}
                            extra={extra}
                            className={isActive ? "active" : ""}
                            dependencies={dependencies}
                        >
                            <Select
                                showArrow
                                key={`${keyCode}_select`}
                                mode={mode}
                                placeholder={fallbackText}
                                showSearch={showSearch}
                                disabled={isDisabled}
                                filterOption={showSearch ? doFilterSelect : undefined}
                                onChange={
                                    onChange ||
                                    ((value: SelectValue) => {
                                        if (value.constructor === Array ? !value.length : !value) {
                                            setIsActive(false)
                                        } else {
                                            setIsActive(true)
                                        }
                                    })
                                }
                                getPopupContainer={getPopupAnchor(anchorRef.current, isForm)}
                            >
                                {fallback && mode !== "tags" && (
                                    <Select.Option value={fallbackValue ?? ""} label={fallbackText}>
                                        {fallbackText}
                                    </Select.Option>
                                )}
                                {typeof options[0] === "string"
                                    ? makeStringOptions(options as Array<string>, keyCode)
                                    : displayImages
                                    ? makeEmployeeOptions(options as Array<EmployeeOption>)
                                    : makeObjectOptions(options as Array<FormSelectOption>, name)}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </CollapseWrapper>
        </>
    )
}

const makeStringOptions = (options: Array<string>, prefix = "") => (
    <>
        {options.map((option, index) => (
            <Select.Option keyCode={`${prefix}_${index}`} value={index}>
                {option}
            </Select.Option>
        ))}
    </>
)

const makeObjectOptions = (options: Array<FormSelectOption>, name: string | Array<string>) => (
    <>
        {options
            .filter((option) => !isNotSet(option.value))
            .map((option, index) => (
                <Select.Option
                    value={name === "vendorName" ? option.param.toString() : option.value.toString()}
                    key={option.index ? option.index : index}
                    label={option.label ? option.label : option.value}
                >
                    {option.param}
                </Select.Option>
            ))}
    </>
)

const makeEmployeeOptions = (employees: Array<EmployeeOption>) => {
    const { t } = useTranslation()

    return (
        <>
            {employees.map((employee, index) => {
                const { id, firstName, lastName, activeCompanyProfile } = employee
                const fullName = `${firstName} ${lastName}`
                return (
                    <Select.Option key={index} value={id} label={fullName} disabled={employee.disabled ?? false}>
                        <div className="flex items-center">
                            <UserImage user={employee} />

                            {fullName}
                            {activeCompanyProfile.deleted && (
                                <Tooltip
                                    title={t("tooltips:employee.was_deleted", {
                                        date: moment(activeCompanyProfile.deletedAt).format("DD.MM.YYYY"),
                                    })}
                                >
                                    <Tag className="ml-5" color="red">
                                        {t("label:deleted")}
                                    </Tag>
                                </Tooltip>
                            )}
                        </div>
                    </Select.Option>
                )
            })}
        </>
    )
}
