import { Button } from "antd"
import JsFileDownloader from "js-file-downloader"
import React from "react"
import { Download as DownloadIcon } from "react-feather"
import { useTranslation } from "react-i18next"

import { DATA_UPLOAD_SPREADSHEET_TEMPLATE_DISPLAY_NAME } from "Shared/config/consts"
import { ImportService, NotificationService } from "Shared/services"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

interface DownloadDataImportSheetButtonInterface {}

const DownloadDataImportSheetButton: React.FC<DownloadDataImportSheetButtonInterface> = () => {
    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useStateIfMounted(false)

    const handleSpreadsheetDownload = async () => {
        setIsLoading(true)
        try {
            const { data } = await ImportService.downloadDataImportSpreadSheetTemplate()
            await new JsFileDownloader({
                url: URL.createObjectURL(data),
                autoStart: true,
                filename: DATA_UPLOAD_SPREADSHEET_TEMPLATE_DISPLAY_NAME,
            })
        } catch (err) {
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:download_spreadsheet_template"))
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Button onClick={handleSpreadsheetDownload} loading={isLoading}>
            <DownloadIcon />
            <p className="inline-block">{t("action:sheet_template")}</p>
        </Button>
    )
}

export default React.memo(DownloadDataImportSheetButton)
