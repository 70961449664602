import { Button, Form, Modal } from "antd"
import { Store } from "antd/lib/form/interface"
import React from "react"
import { useTranslation } from "react-i18next"

import { WalletWithdrawalChallengeForm } from "Components/forms/walletWithdrawalChallenge.form"
import { NotificationService } from "Shared/services"
import WalletService from "Shared/services/wallet.service"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

interface WalletWithdrawalChallengeModalInterface {
    isVisible: boolean
    wireTransferId?: string
    handleCancel: () => void
    onSuccess: () => void
}

export const WalletWithdrawalChallengeModal: React.FC<WalletWithdrawalChallengeModalInterface> = ({ isVisible, wireTransferId, handleCancel, onSuccess }) => {
    const [isLoading, setIsLoading] = useStateIfMounted<boolean>(false)
    const { t } = useTranslation()
    const [formInstance] = Form.useForm()

    const onSubmitChallenge = (value: Store) => {
        setIsLoading(true)
        WalletService.verifyOutgoingWireTransfer(wireTransferId!, value.verificationCode)
            .then(() => {
                onSuccess()
            })
            .catch((err) => {
                NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:error"))
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    return (
        <Modal
            destroyOnClose={true}
            visible={isVisible}
            maskClosable={false}
            title={t("action:wallet.withdrawal")}
            onCancel={handleCancel}
            closable={true}
            keyboard={true}
            className="ant-modal--small"
            footer={[
                <Button
                    key="back"
                    onClick={() => {
                        handleCancel()
                    }}
                >
                    {t("action:cancel")}
                </Button>,
                <Button
                    key="submit"
                    type="primary"
                    loading={isLoading}
                    onClick={() => {
                        formInstance.submit()
                    }}
                >
                    {t("action:wallet.submit")}
                </Button>,
            ]}
        >
            <WalletWithdrawalChallengeForm formInstance={formInstance} onSubmit={onSubmitChallenge} />
        </Modal>
    )
}
