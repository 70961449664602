import { ExpenseAccount } from "@finway-group/shared/lib/models"
import { Reducer } from "redux"

import {
    CreateManyExpenseAccountsAction,
    DeleteManyExpenseAccountsAction,
    ExpenseAccountActionTypes,
    ExpenseAccountActions,
    FetchAllExpenseAccountsAction,
    UpdateManyExpenseAccountsAction,
} from "../actions/expenseAccount/expenseAccountTypes"

export interface ExpenseAccountState {
    items: Array<ExpenseAccount>
}

const initialState = {
    items: [],
}

const fetchAllExpenseAccounts = (state: ExpenseAccountState, action: FetchAllExpenseAccountsAction): ExpenseAccountState => ({
    ...state,
    items: action.expenseAccounts,
})

const updateManyExpenseAccounts = (state: ExpenseAccountState, action: UpdateManyExpenseAccountsAction): ExpenseAccountState => ({
    ...state,
    items: action.expenseAccounts,
})

const createManyExpenseAccounts = (state: ExpenseAccountState, action: CreateManyExpenseAccountsAction): ExpenseAccountState => ({
    ...state,
    items: action.expenseAccounts,
})

const deleteManyExpenseAccounts = (state: ExpenseAccountState, action: DeleteManyExpenseAccountsAction): ExpenseAccountState => ({
    ...state,
    items: action.expenseAccounts,
})

export const expenseAccountReducer: Reducer<ExpenseAccountState, ExpenseAccountActions> = (state = initialState, action) => {
    switch (action.type) {
        case ExpenseAccountActionTypes.FETCH_ALL_EXPENSE_ACCOUNTS:
            return fetchAllExpenseAccounts(state, action)
        case ExpenseAccountActionTypes.UPDATE_MANY_EXPENSE_ACCOUNTS:
            return updateManyExpenseAccounts(state, action)
        case ExpenseAccountActionTypes.DELETE_MANY_EXPENSE_ACCOUNTS:
            return deleteManyExpenseAccounts(state, action)
        case ExpenseAccountActionTypes.CREATE_MANY_EXPENSE_ACCOUNTS:
            return createManyExpenseAccounts(state, action)
        default:
            return state
    }
}
