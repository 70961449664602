import { EyeInvisibleOutlined } from "@ant-design/icons"
import { Button } from "antd"
import React from "react"

import Icon from "Components/icon"
import { useKYBData, useWallet } from "Shared/hooks/corporate.hooks"
import { useCard } from "Shared/hooks/creditCard.hooks"
import { useTenantTier } from "Shared/hooks/featureFlags.hooks"
import { isDev, isInternal } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

interface DebugNavbarProps {
    onMobileDevice: boolean
}

const DebugNavbar = ({ onMobileDevice }: DebugNavbarProps) => {
    const tenantTier = useTenantTier()
    const [hideDebugNavbar, setHideDebugFlag] = useStateIfMounted(false)

    const corporateId = useKYBData()?.corporateId
    const accountId = useWallet()?.accountId
    const cardId = useCard()?.cardId

    const isDebugEnabled = !onMobileDevice && (isDev || isInternal) && !hideDebugNavbar
    if (!isDebugEnabled) return null

    return (
        <div className="flex flex-row items-center h-full text-xs text-white truncate px-20">
            <Button
                style={{ borderRadius: "50px", height: 16, width: 16 }}
                className="mr-5"
                icon={<Icon className="mb-2" component={<EyeInvisibleOutlined />} color="white" />}
                onClick={() => setHideDebugFlag(true)}
            />
            <span className="truncate">
                CorporateID {corporateId || "n/a"} - AccountID: {accountId || "n/a"} - CardID: {cardId || "n/a"} - Tier: {tenantTier}
            </span>
        </div>
    )
}

export default DebugNavbar
