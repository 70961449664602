import { Employee } from "@finway-group/shared/lib/models"
import { useMemo } from "react"
import { useSelector } from "react-redux"

import { RootState } from "Shared/store/rootState.interface"

import { useAuth } from "./auth.hooks"

export const useLoggedInEmployeeProfile = () => {
    const { companyId, userId } = useAuth()

    // do not use useEmployeeById since that would not include the current admin user.
    const employee = new Employee(useSelector(({ employees }: RootState) => employees.items).find((e) => e.id === userId))
    if (companyId) employee.setActiveCompanyProfile(companyId)
    return employee
}

export const useActiveCompanyProfile = () => {
    const { companyId } = useAuth()

    const loggedInEmployee = useLoggedInEmployeeProfile()

    /**
     * sync active companyProfile
     */
    const activeCompanyProfile = useMemo(() => loggedInEmployee.setActiveCompanyProfile(companyId), [loggedInEmployee.activeCompanyProfile, companyId])

    return activeCompanyProfile
}
