import Icon from "@ant-design/icons"
import React from "react"

const reportSvg = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10.405 14.5H5.82331C5.37877 14.4993 4.94219 14.382 4.55714 14.1599C4.17208 13.9377 3.85203 13.6185 3.62892 13.234L1.33806 9.2672C1.11656 8.88161 1 8.44469 1 8C1 7.55531 1.11656 7.11839 1.33806 6.7328L3.62892 2.766C3.85203 2.3815 4.17208 2.06225 4.55714 1.84011C4.94219 1.61796 5.37877 1.50069 5.82331 1.5L10.405 1.5C10.8496 1.50069 11.2861 1.61796 11.6712 1.84011C12.0563 2.06225 12.3763 2.3815 12.5994 2.766L14.8903 6.7328C15.1118 7.11839 15.2283 7.55531 15.2283 8C15.2283 8.44469 15.1118 8.88161 14.8903 9.2672L12.5994 13.234C12.3763 13.6185 12.0563 13.9377 11.6712 14.1599C11.2861 14.382 10.8496 14.4993 10.405 14.5V14.5ZM5.82331 2.70571C5.59032 2.70591 5.36146 2.76726 5.1596 2.88363C4.95775 3 4.78998 3.16732 4.67306 3.36886L2.38221 7.33565C2.26606 7.5378 2.20494 7.76686 2.20494 8C2.20494 8.23314 2.26606 8.4622 2.38221 8.66435L4.67306 12.6311C4.79035 12.8324 4.95821 12.9994 5.15997 13.1157C5.36174 13.2321 5.59042 13.2936 5.82331 13.2943H10.405C10.6379 13.2936 10.8666 13.2321 11.0684 13.1157C11.2701 12.9994 11.438 12.8324 11.5553 12.6311L13.8461 8.66435C13.9623 8.4622 14.0234 8.23314 14.0234 8C14.0234 7.76686 13.9623 7.5378 13.8461 7.33565L11.5529 3.36886C11.4361 3.16767 11.2688 3.00058 11.0674 2.88423C10.866 2.76788 10.6376 2.70633 10.405 2.70571H5.82331Z"
            fill="#606C7E"
        />
        <path
            d="M8.11216 9.09782C7.95269 9.09719 7.79997 9.03339 7.68744 8.9204C7.5749 8.80741 7.51172 8.65444 7.51172 8.49497V5.11897C7.51172 4.95908 7.57523 4.80574 7.68829 4.69269C7.80135 4.57963 7.95469 4.51611 8.11458 4.51611C8.27446 4.51611 8.4278 4.57963 8.54086 4.69269C8.65392 4.80574 8.71743 4.95908 8.71743 5.11897V8.49497C8.71775 8.57443 8.7023 8.65316 8.67197 8.72661C8.64163 8.80005 8.59702 8.86675 8.54072 8.92282C8.48442 8.9789 8.41755 9.02324 8.34399 9.05328C8.27042 9.08332 8.19162 9.09846 8.11216 9.09782V9.09782Z"
            fill="#606C7E"
        />
        <path
            d="M8.11216 11.4959C7.95476 11.4953 7.80384 11.4332 7.69164 11.3228C7.57944 11.2124 7.51487 11.0625 7.51172 10.9051V10.6109C7.51172 10.451 7.57523 10.2977 7.68829 10.1846C7.80135 10.0716 7.95469 10.0081 8.11458 10.0081C8.27446 10.0081 8.4278 10.0716 8.54086 10.1846C8.65392 10.2977 8.71743 10.451 8.71743 10.6109V10.9051C8.71489 11.0635 8.64985 11.2145 8.53648 11.3252C8.4231 11.4358 8.27059 11.4972 8.11216 11.4959Z"
            fill="#606C7E"
        />
    </svg>
)

const ReportIcon = (props: any) => <Icon component={reportSvg} {...props} />

export default ReportIcon
