import { LiquidityCashFlow, TransactionCategory } from "@finway-group/shared/lib/models"
import { Card } from "antd"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts"
import { Payload } from "recharts/types/component/DefaultLegendContent"

import Loading from "Components/loading"
import { useTransactionCategoriesMap } from "Shared/hooks/transactionCategory.hooks"
import { useLoggedInEmployeeProfile } from "Shared/hooks/user.hooks"
import { ColorGenerator } from "Shared/utils/colorGenerator.utils"
import { formatCurrencyNumber } from "Shared/utils/helper.utils"

// Todo - wibi: tailwind?
const chartColors = ["#3886FF", "#53BBD8", "#60CDB2", "#F42A68", "#FBAC3B"]
const colors = ColorGenerator.generateMultipleColor(chartColors, 10)

interface PieDataPoint {
    key: string
    value: number
    name: string
}
interface CategoryCashFlowPieChartInterface {
    cashFlowData: Array<LiquidityCashFlow>
    direction: "income" | "spending" | "balance"
    isDiagramLoading: boolean
}

const CategoryCashFlowPieChart: React.FC<CategoryCashFlowPieChartInterface> = ({ cashFlowData, direction, isDiagramLoading }) => {
    if (isDiagramLoading) return <Loading />

    const currentEmployee = useLoggedInEmployeeProfile()
    const { globalCurrency } = currentEmployee.settings
    const { t } = useTranslation()

    const leafCategoryMap = useTransactionCategoriesMap(true)
    const leafCategoryIds = Array.from(leafCategoryMap.keys())
    const RADIAN = Math.PI / 180

    // should we compile this data in the backend?
    const data = useMemo(() => {
        if (!cashFlowData) return []
        const categoryTotalMap = new Map<string, number>()

        for (const cashFlow of cashFlowData) {
            for (const categoryCashFlow of cashFlow.cashFlowsPerCategory) {
                categoryTotalMap.set(categoryCashFlow.category as string, categoryCashFlow[direction].actual + (categoryTotalMap.get(categoryCashFlow.category as string) ?? 0))
            }
        }

        const pieData: Array<PieDataPoint> = []
        for (const [key, value] of categoryTotalMap) {
            if (leafCategoryIds.includes(key)) {
                pieData.push({ key, value: Math.abs(value), name: leafCategoryMap.get(key)?.name ?? key })
            }
        }

        // remove all that have 0 value.
        const nonZeroPieData = pieData.filter((d) => d.value > 0).sort((a, b) => b.value - a.value)
        const mainData = nonZeroPieData.splice(0, 9)
        const otherTotal = nonZeroPieData.splice(9).reduce((a, d) => d.value + a, 0)
        return [...mainData, { key: "Others", value: otherTotal, name: "Others" }]
    }, [cashFlowData])

    const generateLabelPayload = (data: Array<PieDataPoint>, categoryMap: Map<string, TransactionCategory>): Array<Payload> =>
        data.map((d, index) => ({
            id: d.key,
            type: "square",
            value: categoryMap.get(d.key)?.name ?? d.key,
            color: colors[index % colors.length],
        }))

    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }: any) => {
        if (percent < 0.1) return <></>

        const radius = innerRadius + (outerRadius - innerRadius) * 0.5
        const x = cx + radius * Math.cos(-midAngle * RADIAN)
        const y = cy + radius * Math.sin(-midAngle * RADIAN)

        return (
            <text x={x} y={y} fill="white" textAnchor="middle" dominantBaseline="central">
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        )
    }

    return (
        <Card>
            <div className="h-400">
                <ResponsiveContainer width={"100%"} height={380}>
                    <PieChart>
                        <Pie data={data} dataKey="value" nameKey="name" innerRadius={50} fill="#8884d8" labelLine={false} label={renderCustomizedLabel} isAnimationActive={false}>
                            {data.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                            ))}
                        </Pie>
                        <Tooltip
                            isAnimationActive={false}
                            content={(props) => (
                                <div className="p-5 bg-white shadow">
                                    <label className="font-bold" style={{ color: `${props.payload?.[0]?.payload.fill}` }}>
                                        {props.payload?.[0]?.name} : {formatCurrencyNumber(props.payload?.[0]?.value as number, globalCurrency)}
                                    </label>
                                </div>
                            )}
                        />
                        <Legend
                            formatter={(value) => <span className="text-text">{value}</span>}
                            layout="vertical"
                            align="left"
                            verticalAlign="middle"
                            payload={generateLabelPayload(data, leafCategoryMap)}
                        />
                    </PieChart>
                </ResponsiveContainer>
                <div className="mb-12">{t("info:liquidity_management.percentages_are_rounded")}</div>
            </div>
        </Card>
    )
}

export default CategoryCashFlowPieChart
