import { Button } from "antd"
import React, { useEffect } from "react"

import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

interface SwitchToogleButtonInterface {
    value?: { button1: boolean; button2: boolean }
    label1?: string
    label2?: string
    onClick?: (selectedButtons: { button1: boolean; button2: boolean }) => void
}

export const SwitchToogleButton: React.FC<SwitchToogleButtonInterface> = ({ label1 = "", label2 = "", onClick, value }) => {
    const [selectedButtons, setSelectedButtons] = useStateIfMounted(value ?? { button1: false, button2: false })

    const handleClickButton1 = () => {
        const newState = { button1: !selectedButtons.button1, button2: false }
        setSelectedButtons(newState)
        onClick?.(newState)
    }

    const handleClickButton2 = () => {
        const newState = { button2: !selectedButtons.button2, button1: false }
        setSelectedButtons(newState)
        onClick?.(newState)
    }

    return (
        <div className="flex">
            <Button
                className="m-0 flex-1 h-32 min-w-32 p-4 rounded-none rounded-l"
                style={{ borderStyle: "solid", borderWidth: "0px", borderColor: "#e8e8e8" }} // not using tailwind for border because it adds some on-hover effects and colors
                type={selectedButtons.button1 ? "primary" : "default"}
                onClick={handleClickButton1}
            >
                <p className="inline-block">{label1}</p>
            </Button>
            <Button
                className="m-0 flex-1 h-32 min-w-32 p-4 rounded-none rounded-r"
                style={{ borderStyle: "solid", borderWidth: "0px", borderLeftWidth: "1px", borderColor: "#e8e8e8" }}
                type={selectedButtons.button2 ? "primary" : "default"}
                onClick={handleClickButton2}
            >
                <p className="inline-block">{label2}</p>
            </Button>
        </div>
    )
}
