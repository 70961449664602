import { InfoCircleFilled } from "@ant-design/icons"
import { CurrencyEnum } from "@finway-group/shared/lib/models"
import { Alert, Button, Col, Form, Modal, Radio, Row } from "antd"
import { Store } from "antd/lib/form/interface"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import CurrencyInput from "Components/currencyInput"
import { CurrencySymbolPosition, parseCurrencyInput } from "Components/currencyInput/config"
import { WalletRules } from "Components/forms/rules/wallet.rules"
import { useDefaultAccount, useDefaultConnection } from "Shared/hooks/bank.hooks"
import { useWallet } from "Shared/hooks/corporate.hooks"
import { RoutePathEnum } from "Shared/router/RoutePath.enum"
import { BankService, NotificationService, UserService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { getIBANPrintFormat } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

interface WalletFundingModalInterface {
    isVisible: boolean
    handleCancel: () => void
}

enum WalletModalTabEnum {
    DIRECT_FUNDING = "DIRECT_FUNDING",
    BANK_TRANSFER = "BANK_TRANSFER",
}

const WalletFundingModal: React.FC<WalletFundingModalInterface> = ({ isVisible, handleCancel }) => {
    const [form] = Form.useForm()
    const { t } = useTranslation()
    const history = useHistory()
    const wallet = useWallet()
    const accountId = useDefaultAccount()
    const { globalCurrency } = UserService.getLoggedInEmployeeProfile().settings
    const defaultBankConnection = useDefaultConnection()
    const [walletModalTab, setWalletModalTab] = useStateIfMounted<WalletModalTabEnum>(
        wallet.currency === CurrencyEnum.EUR ? WalletModalTabEnum.DIRECT_FUNDING : WalletModalTabEnum.BANK_TRANSFER,
    )
    const [isLoading, setIsLoading] = useStateIfMounted<boolean>(false)
    const { amount } = WalletRules()

    useEffect(() => {
        if (wallet.currency) {
            setWalletModalTab(wallet.currency === CurrencyEnum.EUR ? WalletModalTabEnum.DIRECT_FUNDING : WalletModalTabEnum.BANK_TRANSFER)
        }
    }, [wallet?.currency])

    const handleSettingsClick = (event: any) => {
        event.preventDefault()
        history.push({ pathname: RoutePathEnum.SETTINGS_BANK_ACCOUNTS })
    }

    const handleSubmit = async (values: Store) => {
        values.amount = parseCurrencyInput(values.amount)
        setIsLoading(true)
        try {
            await BankService.fundWallet(accountId!, values.amount, globalCurrency)
            NotificationService.send(NotificationTypeEnum.INFO, t("notification:banking.payment_started.title"), t("notification:banking.payment_started.message"), 30)
            handleHide()
        } catch (err) {
            setIsLoading(false)
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:banking.error"))
        } finally {
            setIsLoading(false)
        }
    }

    const handleHide = () => {
        setIsLoading(false)
        form.resetFields()
        handleCancel()
    }

    return (
        <>
            <Modal
                title={t("creditCard:action.wallet_funding_via_bank_transfer")}
                visible={isVisible}
                maskClosable={false}
                onCancel={handleHide}
                onOk={form.submit}
                okText={t("action:wallet.transfer_now")}
                cancelText={t("action:close")}
                destroyOnClose
                confirmLoading={isLoading}
                okButtonProps={{
                    disabled: walletModalTab === WalletModalTabEnum.DIRECT_FUNDING && !defaultBankConnection,
                    style: { display: walletModalTab === WalletModalTabEnum.BANK_TRANSFER ? "none" : "inline-block" },
                }}
            >
                <div className="card-modal">
                    <Radio.Group defaultValue={walletModalTab} onChange={(e) => setWalletModalTab(e.target.value)}>
                        {wallet.currency === CurrencyEnum.EUR ? <Radio.Button value={WalletModalTabEnum.DIRECT_FUNDING}>{t("action:wallet.direct_funding")}</Radio.Button> : <></>}
                        <Radio.Button value={WalletModalTabEnum.BANK_TRANSFER}>{t("action:wallet.bank_transfer")}</Radio.Button>
                    </Radio.Group>
                </div>
                <div className="w-full p-10">
                    <Row gutter={[16, 16]}>
                        <Col span={1}>
                            <InfoCircleFilled style={{ color: "#3886FF", fontSize: "16px" }} />
                        </Col>
                        <Col span={23}>
                            <p className="text-xs text-justify pl-5">
                                {t(`info:cards.${walletModalTab === WalletModalTabEnum.DIRECT_FUNDING ? "direct_bank_transfer_funding" : "bank_transfer_funding"}`)}
                            </p>
                        </Col>
                    </Row>
                    <div>
                        {walletModalTab === WalletModalTabEnum.BANK_TRANSFER ? (
                            <ul className="description-list mx-6 mt-20">
                                <li>
                                    <span className="title">{t("creditCard:label.beneficiary")}</span>
                                    <span className="ml-auto text text-left">{wallet?.beneficiary}</span>
                                </li>
                                <li>
                                    <span className="title">{wallet?.currency === CurrencyEnum.GBP ? t("label:account_number") : t("label:iban")}</span>
                                    <span className="ml-auto text text-left">
                                        {wallet?.iban && (wallet.currency !== CurrencyEnum.GBP ? getIBANPrintFormat(wallet.iban) : wallet.iban)}
                                    </span>
                                </li>
                                {wallet?.bic && (
                                    <li>
                                        <span className="title">{wallet?.currency === CurrencyEnum.GBP ? t("label:sort_code") : t("label:bic")}</span>
                                        <span className="ml-auto text text-left uppercase">{wallet?.bic}</span>
                                    </li>
                                )}
                                <li>
                                    <span className="title">{t("creditCard:label.bank_name")}</span>
                                    <span className="ml-auto text text-left">Paynetics AD</span>
                                </li>
                            </ul>
                        ) : defaultBankConnection ? (
                            <ul className="description-list mx-6 mt-20">
                                <li>
                                    <span className="title">{t("label:wallet.transfer_from")}</span>
                                    <span className="ml-auto text text-left uppercase">{defaultBankConnection?.bankName}</span>
                                </li>
                                <li>
                                    <span className="title">{t("label:wallet.amount_to_be_transferred")}</span>
                                </li>
                                <Form id="walletFundingForm" form={form} onFinish={handleSubmit}>
                                    <Form.Item name="amount" rules={amount}>
                                        <CurrencyInput
                                            currency={globalCurrency}
                                            value={0}
                                            className="react-wallet-funding-currency-input"
                                            currencySymbolPosition={CurrencySymbolPosition.INSIDE_INPUT}
                                        />
                                    </Form.Item>
                                </Form>
                            </ul>
                        ) : (
                            <Alert
                                type="warning"
                                className="mt-20"
                                showIcon
                                message={t("label:note")}
                                description={
                                    <div>
                                        <span>{t("missing:banking.bank_connection")}</span>
                                        <Button className="button-warning-notification ml-4" type="text" onClick={handleSettingsClick}>
                                            {t("action:banking.add_bank_connection")}
                                        </Button>
                                    </div>
                                }
                            />
                        )}
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default WalletFundingModal
