import { ExclamationCircleOutlined } from "@ant-design/icons"
import { Alert, Tooltip } from "antd"
import React from "react"
import { Edit as EditIcon } from "react-feather"
import { useTranslation } from "react-i18next"

import UserImage from "Components/UserImage"
import Copy from "Components/copy"
import { FallbackApproverModal } from "Components/modals/fallbackApprover.modal"
import { useCompany } from "Shared/hooks/company.hooks"
import { AuthzService, EmployeeService, NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { getTooltipPopupContainer } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

import FeatureSettingStatus from "./featureSettingStatus"

const GeneralTabContent = () => {
    const { t } = useTranslation()
    const company = useCompany()

    const [isShowingFallbackApproverModal, setIsShowingFallbackApproverModal] = useStateIfMounted(false)

    const fallbackApprover = company?.fallbackApprover ? EmployeeService.getEmployeeById(company.fallbackApprover) : undefined
    const canWrite = AuthzService.canUpdateCompanyFeatureSettings()

    return (
        <div>
            <ul className="description-list">
                {/* As we changed the label for vendor creation setting from only admins can create vendors to eveyone can create vendors. We have to negate isVendorCreationRestricted */}
                <FeatureSettingStatus title={t("label:vendor_creation_for_all")} isEnabled={!company.isVendorCreationRestricted} tooltipText={t("info:vendor_creation_for_all")} />
                <FeatureSettingStatus title={t("label:vendorRulesShouldOverwrite")} isEnabled={company.vendorRulesOverwriteEmployeeRulesEnabled} />
                <FeatureSettingStatus title={t("label:tagging_feature_enable")} isEnabled={company.taggingEnabled} tooltipText={t("info:tagging_feature_enable")} />
                <FeatureSettingStatus title={t("label:editing_tags_enable")} isEnabled={company.editingTagsEnabled} tooltipText={t("info:editing_tags_enable")} />
                <FeatureSettingStatus title={t("label:show_auto_approver_warning")} isEnabled={company.showAutoApproverWarning} />
                <FeatureSettingStatus
                    title={t("label:allow_all_to_update_absences")}
                    isEnabled={company.allowAllToUpdateAbsences}
                    tooltipText={t("info:allow_all_to_update_absences")}
                />
                <FeatureSettingStatus title={t("label:show_cost_centers_ids")} isEnabled={company.showCostCentersIds} tooltipText={t("info:show_cost_centers_ids")} />
                <FeatureSettingStatus title={t("label:allow_cost_center_2_creation")} isEnabled={company.allowCostCenter2Creation} />
                <li>
                    <span className="title w-180">{t("label:auto_logout.title")}</span>
                    <span className="text text-left">{t(`label:auto_logout.${company.autoLogout?.toLowerCase()}`)}</span>
                </li>
                <li className="flex-col">
                    <div className="w-full flex align-center justify-between">
                        <span className="title w-240">
                            <span className="title w-240"> {t("label:fallback_approver")}</span>
                            <Tooltip getPopupContainer={getTooltipPopupContainer} title={t("tooltips:fallback_approver")} placement="right" className="align-middle">
                                <ExclamationCircleOutlined className="ml-6" />
                            </Tooltip>
                        </span>
                        <span className="flex items-center space-x-12 text">
                            {fallbackApprover && (
                                <div className="flex items-center flex-no-wrap">
                                    <UserImage user={fallbackApprover} />
                                    <span className="leading-tight truncate">{fallbackApprover.getFullName()}</span>
                                </div>
                            )}
                            {canWrite && <EditIcon className="cursor-pointer" size={14} onClick={() => setIsShowingFallbackApproverModal(true)} />}
                        </span>
                    </div>
                    {company.cardsEnabled && !fallbackApprover?.activeCompanyProfile.weavrData?.userId && (
                        <div className="w-full">
                            <Alert message={t("info:enroll_fallback_approver")} type="error" showIcon={true} />
                        </div>
                    )}
                    <FallbackApproverModal isShowing={isShowingFallbackApproverModal} onCancel={() => setIsShowingFallbackApproverModal(false)} />
                </li>

                <li>
                    <span className="title w-180">
                        {t("label:email_forwarding_address")}
                        <Tooltip getPopupContainer={getTooltipPopupContainer} title={t("info:email_forwarding_address")} placement="right" className="align-middle">
                            <ExclamationCircleOutlined className="ml-6" />
                        </Tooltip>
                    </span>
                    <div className="flex flex-row">
                        <span className="text text-left">{company.emailForwardingAddress}</span>
                        <Copy
                            className="ml-4 mt-4"
                            value={company.emailForwardingAddress}
                            onCopied={() => {
                                NotificationService.send(
                                    NotificationTypeEnum.SUCCESS,
                                    t("notification:settings.company.email_forwarding_address.copied.title"),
                                    <div>
                                        {t("notification:settings.company.email_forwarding_address.copied.message_1")}
                                        <b>{company.emailForwardingAddress}</b>
                                        {t("notification:settings.company.email_forwarding_address.copied.message_2")}
                                    </div>,
                                    20,
                                )
                            }}
                        />
                    </div>
                </li>
            </ul>
        </div>
    )
}

export default GeneralTabContent
