import { Employee, SystemRightEnum } from "@finway-group/shared/lib/models"
import UserCompanyProfileInterface from "@finway-group/shared/lib/models/user/userCompanyProfileInterface"
import { useQueryClient } from "@tanstack/react-query"
import { Button, Form, Modal } from "antd"
import { Store } from "antd/lib/form/interface"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"

import UserForm from "Components/forms/employeeForm/user.form"
import { useFormTouchedHandler } from "Shared/hooks/form.hooks"
import { QueryKeyEnum } from "Shared/reactQuery.types"
import { AuthzService, NotificationService, UserService } from "Shared/services"
import DialogService from "Shared/services/dialog.service"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { ThunkDispatchResult } from "Shared/store"
import { createUser, updateUser } from "Shared/store/actions/employee/employeeActions"
import { unsetApprovalFields } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

const { warning } = Modal

interface UserModalProps {
    isNew: boolean
    employee?: Employee
    sysAdminsCount?: number
    isShowing: boolean
    onCancel: () => void
    onSubmit?: () => void
}

export const UserModal = ({ isNew, isShowing, employee, sysAdminsCount, onCancel, onSubmit }: UserModalProps) => {
    const { t } = useTranslation()
    const { id } = useParams<{ id: string }>()
    const dispatch: ThunkDispatchResult = useDispatch()
    const loggedInUser = UserService.getLoggedInEmployeeProfile()
    const [isLoading, setIsLoading] = useStateIfMounted(false)
    const [formInstance] = Form.useForm()
    const { isFormTouched, handleTouchForm, handleResetTouchForm } = useFormTouchedHandler()
    const [showAddUserToCompanyFields, setShowAddUserToCompanyFields] = useStateIfMounted(false)
    const queryClient = useQueryClient()

    useEffect(() => {
        if (!isNew && isShowing) formInstance.setFieldsValue(employee)

        return () => {
            if (isShowing) formInstance.resetFields()
        }
    }, [isShowing])

    const handleSubmit = async (values: Store & UserCompanyProfileInterface) => {
        setIsLoading(true)

        const employeeData = new Employee({
            ...values,
            positionSource: employee?.positionSource,
        })

        if (isNew) {
            try {
                const user = await dispatch(createUser(employeeData))
                if (showAddUserToCompanyFields) await UserService.submitNewCompanyProfile({ values, user, dispatch })
                NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:employee.created.title"), t("notification:employee.created.message"))
                handleHideOnSubmit()
            } catch (err) {
                setIsLoading(false)
                NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:employee.create.title"))
            }
        } else {
            try {
                if (!AuthzService.isSystemRightGrantedForLoggedInUser(SystemRightEnum.SYS__USER__ALL__CREATE)) unsetApprovalFields(employeeData)

                const employeeId = id || (employee && employee.id)
                if (employeeId) await dispatch(updateUser(employeeId, employeeData))

                if (values.email !== employee?.email) warning({ title: t("info:email_verification_needed.title"), content: t("info:email_verification_needed.message") })

                if (showAddUserToCompanyFields && employee) await UserService.submitNewCompanyProfile({ values, user: employee, dispatch })

                handleHideOnSubmit()
                NotificationService.send(
                    NotificationTypeEnum.SUCCESS,
                    employeeId === loggedInUser.id ? t("notification:settings.user_profile.updated.title") : t("notification:employee.updated.title"),
                    employeeId === loggedInUser.id ? t("notification:settings.user_profile.updated.message") : t("notification:employee.updated.message"),
                )
            } catch (err) {
                setIsLoading(false)
                NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:employee.edit.title"))
            }
        }
    }

    const handleHideOnSubmit = () => {
        queryClient.invalidateQueries([QueryKeyEnum.USERS])
        if (onSubmit) onSubmit()
        resetLoadingAndForm()
    }

    const resetLoadingAndForm = () => {
        formInstance.resetFields()
        setIsLoading(false)
        setShowAddUserToCompanyFields(false)
        handleResetTouchForm()
        onCancel()
    }

    const handleHideOnCloseOrCancel = async () => {
        if (isFormTouched.current) {
            const isConfirmed = await DialogService.confirmUnsavedChanges()
            if (!isConfirmed) return
        }

        resetLoadingAndForm()
    }

    const titleText = () => {
        if (employee?.id === loggedInUser.id) return t("action:user_profile.edit")
        if (isNew) return `${t("action:user.create")}`
        return `${t("action:user.edit")}`
    }

    const buttonText = () => (isNew ? `${t("action:user.create_cta")}` : `${t("action:save")}`)

    return (
        <Modal
            title={titleText()}
            visible={isShowing}
            maskClosable={false}
            onCancel={handleHideOnCloseOrCancel}
            cancelText={t("action:cancel")}
            confirmLoading={isLoading}
            destroyOnClose
            footer={[
                <Button key="back" onClick={handleHideOnCloseOrCancel}>
                    {t("action:cancel")}
                </Button>,
                <Button
                    data-testid="submitNewEmployeeButton"
                    key="submit"
                    type="primary"
                    loading={isLoading}
                    onClick={() => {
                        formInstance.submit()
                    }}
                >
                    {buttonText()}
                </Button>,
            ]}
        >
            <UserForm
                form={formInstance}
                isNew={isNew}
                employee={employee}
                onSubmit={handleSubmit}
                sysAdminsCount={sysAdminsCount}
                onFormValuesChange={handleTouchForm}
                showAddUserToCompanyFields={showAddUserToCompanyFields}
                setShowAddUserToCompanyFields={setShowAddUserToCompanyFields}
            />
        </Modal>
    )
}

export default React.memo(UserModal)
