import React, { Suspense, useEffect } from "react"
import { useDispatch } from "react-redux"

import { useAreCardsActivated } from "Shared/hooks/corporate.hooks"
import { AuthzService } from "Shared/services"
import { fetchCardOverviewData } from "Shared/store/actions/analytics/analyticsActions"
import { fetchKYBProgress, fetchWallet } from "Shared/store/actions/corporate/corporateActions"

const CreditCardList = React.lazy(() => import("Features/pages/creditCards/creditCard.list"))
const KYBContainer = React.lazy(() => import("Features/pages/creditCards/kyb/kyb.container"))

interface CreditCardContainerInterface {}
const CreditCardContainer: React.FC<CreditCardContainerInterface> = () => {
    const dispatch = useDispatch()
    const showCardList = useAreCardsActivated()

    useEffect(() => {
        dispatch(fetchKYBProgress())
    }, [])

    useEffect(() => {
        if (!showCardList) {
            return
        }

        if (AuthzService.canLoggedInUserViewAllCards()) {
            dispatch(fetchCardOverviewData())
        }

        if (AuthzService.canLoggedInUserViewWallet()) {
            dispatch(fetchWallet())
        }
    }, [showCardList])

    return <Suspense fallback={<></>}>{showCardList ? <CreditCardList /> : <KYBContainer />}</Suspense>
}

export default CreditCardContainer
