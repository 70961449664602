import { CostCenter2 } from "@finway-group/shared/lib/models"
import { Reducer } from "redux"

import {
    CostCenter2ActionTypes,
    CostCenter2Actions,
    CreateManyCostCenters2Action,
    DeleteManyCostCenters2Action,
    FetchAllCostCenters2Action,
    UpdateManyCostCenters2Action,
} from "../actions/costCenter2/costCenter2Types"

export interface CostCenter2State {
    items: Array<CostCenter2>
}

const initialState = {
    items: [],
}

const fetchAllCostCenters2 = (state: CostCenter2State, action: FetchAllCostCenters2Action): CostCenter2State => ({
    ...state,
    items: action.costCenters2,
})

const updateManyCostCenter2 = (state: CostCenter2State, action: UpdateManyCostCenters2Action): CostCenter2State => ({
    ...state,
    items: action.costCenters2,
})

const createManyCostCenter2 = (state: CostCenter2State, action: CreateManyCostCenters2Action): CostCenter2State => ({
    ...state,
    items: action.costCenters2,
})

const deleteManyCostCenter2 = (state: CostCenter2State, action: DeleteManyCostCenters2Action): CostCenter2State => ({
    ...state,
    items: action.costCenters2,
})

export const costCenter2Reducer: Reducer<CostCenter2State, CostCenter2Actions> = (state = initialState, action) => {
    switch (action.type) {
        case CostCenter2ActionTypes.FETCH_ALL_COST_CENTERS_2:
            return fetchAllCostCenters2(state, action)
        case CostCenter2ActionTypes.UPDATE_MANY_COST_CENTERS_2:
            return updateManyCostCenter2(state, action)
        case CostCenter2ActionTypes.CREATE_MANY_COST_CENTERS_2:
            return createManyCostCenter2(state, action)
        case CostCenter2ActionTypes.DELETE_MANY_COST_CENTERS_2:
            return deleteManyCostCenter2(state, action)
        default:
            return state
    }
}
