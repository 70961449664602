import { ExpenseKindEnum } from "@finway-group/shared/lib/models"
import { ExpensePaymentOptionEnum } from "@finway-group/shared/lib/models/expense/expensePaymentOption.enum"
import { Col, Form, Radio, Row } from "antd"
import { RadioChangeEvent } from "antd/lib/radio"
import React from "react"
import { useTranslation } from "react-i18next"

import { useExpenseFormContext } from "Components/modals/expenseCreateForm.context"
import { useAreCardsActivated } from "Shared/hooks/corporate.hooks"

import ExpenseKindRadioField from "./ExpenseKindRadioField"
import ExpenseCommonInputFieldsForm from "./commonInputFields/commonInputFields.form"
import OfferDocumentUploadForm from "./commonInputFields/offerDocumentUpload.form"
import ExpenseFormLayout from "./expenseFormLayout"
import OneTimeExpenseFields from "./oneTimeExpenseFields"
import SubscriptionExpenseForm from "./subscriptionExpenseFields"

interface PurchaseRequestFormInterface {}

const PurchaseRequestForm: React.FC<PurchaseRequestFormInterface> = () => {
    const [{ hideExpenseKindRadioOption, expense, onKindChange, isPreApprovedSubscription, isNew, updateExpense, expenseForm, isInvoiceRequest }] = useExpenseFormContext()
    const cardsActive = useAreCardsActivated()
    const { t } = useTranslation()

    const getPaymentOptionType = () => {
        const radioInputs = [
            { value: ExpensePaymentOptionEnum.BANK_TRANSFER, label: t("label:bank_transfer") },
            { value: ExpensePaymentOptionEnum.SMART_CARD, label: t("label:cards.virtual_card") },
        ]
        const onPaymentOptionChange = ({ target: { value } }: RadioChangeEvent) => {
            updateExpense({
                paymentOption: value,
                // when we switch to a card expense, we gotta unset the payment status
                ...(value === ExpensePaymentOptionEnum.SMART_CARD ? { paymentFlowInformation: undefined } : {}),
            })
        }

        return (
            <Row gutter={[16, 16]} align="middle">
                <Col span={24}>
                    <Form.Item className="max-w-400" label={t("input:request.payment_option")}>
                        <div className="card-modal">
                            <Radio.Group value={expense?.paymentOption} onChange={onPaymentOptionChange} name="paymentOption">
                                {radioInputs.map(({ value, label }, index) => (
                                    <Radio.Button key={index} value={value}>
                                        <p className="truncate">{label}</p>
                                    </Radio.Button>
                                ))}
                            </Radio.Group>
                        </div>
                    </Form.Item>
                </Col>
            </Row>
        )
    }

    const leftSide = (
        <>
            {cardsActive && !isInvoiceRequest && isNew && getPaymentOptionType()}
            {!hideExpenseKindRadioOption && <ExpenseKindRadioField expense={expense} onKindChange={onKindChange} />}
            {expense.kind === ExpenseKindEnum.ONE_TIME_EXPENSE ? <OneTimeExpenseFields /> : <SubscriptionExpenseForm />}
            <ExpenseCommonInputFieldsForm allowVendorCreation={true} />
            {!isPreApprovedSubscription && <OfferDocumentUploadForm isNew={isNew} updateExpense={updateExpense} expenseForm={expenseForm} />}
        </>
    )

    return <ExpenseFormLayout leftSide={leftSide} />
}

export default PurchaseRequestForm
