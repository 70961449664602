import { Badge } from "antd"
import moment from "moment"
import React from "react"
import { Trans } from "react-i18next"
import { Link } from "react-router-dom"

import UserImage from "Components/UserImage"
import { SystemEmployee } from "Shared/utils/navbarActivityTemplates.utils"

interface SystemActivityInterface {
    created: Date
    isNotViewed: boolean
    description?: any
    link?: string
}

const SystemActivityCard = ({ created, isNotViewed, description, link = "" }: SystemActivityInterface) => (
    <>
        <Link to={link}>
            <UserImage user={SystemEmployee} size="large" />
        </Link>
        <div className="flex flex-col mr-5 overflow-auto w-full">
            <div className="mt-1 mb-1 leading-none">
                <Link to={link} className="text-text-dark text-xs font-bold">
                    {SystemEmployee.getFullName()}
                </Link>
            </div>
            <Trans>
                <div className="text-xs leading-tight whitespace-normal">{description}</div>
            </Trans>
            <div>
                <time className="text-xs">{moment(new Date(created)).fromNow()}</time>
            </div>
        </div>
        {isNotViewed ? (
            <div className="mt-14">
                <Badge dot={true} />
            </div>
        ) : (
            <></>
        )}
    </>
)

export default React.memo(SystemActivityCard)
