import { isAllowedCompanyNameCharsOnly, textMultipleWhiteSpace } from "@finway-group/shared/lib/utils/validators"
import { Rule } from "antd/lib/form"
import i18n from "i18next"

import { isNumeric, isValidBIC, isValidIBAN } from "Shared/utils/helper.utils"

import { allowlistCharValidator } from "./utils.rules"

const CompanySettingsRules = (): { [key: string]: Array<Rule> } => ({
    name: [
        {
            required: true,
            validator: (_rule, value: string) => {
                // Required
                if (!value || value === "") return Promise.reject(new Error(i18n.t("validation:required")))

                if (value.length > 100) return Promise.reject(new Error(i18n.t("validation:string_too_long")))
                if (value !== value.trim()) return Promise.reject(new Error(i18n.t("validation:string_white_space_start_or_end")))
                if (textMultipleWhiteSpace.test(value)) return Promise.reject(new Error(i18n.t("validation:string_white_space_duplicate")))
                if (!isAllowedCompanyNameCharsOnly(value)) return Promise.reject(new Error(i18n.t("validation:invalid_characters")))

                return Promise.resolve()
            },
        },
        allowlistCharValidator,
    ],
    iban: [
        {
            validator: (_rule: any, value: string) => {
                // Optional
                if (!value || value === "") return Promise.resolve()

                if (!isValidIBAN(value)) return Promise.reject(new Error(i18n.t("validation:iban")))

                return Promise.resolve()
            },
        },
    ],
    bic: [
        {
            validator: (_rule: any, value: string) => {
                // Optional
                if (!value || value === "") return Promise.resolve()

                if (!isValidBIC(value)) return Promise.reject(new Error(i18n.t("validation:bic")))
                if (value.length > 11) return Promise.reject(new Error(i18n.t("validation:string_too_long")))

                return Promise.resolve()
            },
        },
    ],
    companyIdentifier: [allowlistCharValidator],
    businessAddressAddressLine: [allowlistCharValidator],
    defaultCreditorNumber: [
        {
            validator: (_rule: any, value: string) => {
                // Optional
                if (!value || value === "") return Promise.resolve()

                if (!isNumeric(value)) return Promise.reject(i18n.t("validation:numeric"))
                if (value.length > 0 && value.substring(0, 1) === "0") return Promise.reject(i18n.t("validation:no_leading_zero"))
                // DATEV limit
                if (value.length < 5) return Promise.reject(i18n.t("validation:string_too_short"))
                if (value.length > 9) return Promise.reject(i18n.t("validation:string_too_long"))

                return Promise.resolve()
            },
        },
    ],
    countryCode: [
        {
            validator: (_rule: any, value: string) => {
                // Optional
                if (!value || value === "") return Promise.resolve()

                if (value.length !== 2) return Promise.reject(new Error(i18n.t("validation:exact.string", { num: 2 })))
                if (!/[A-Z]{2}/.test(value)) return Promise.reject(new Error(i18n.t("validation:only_uppercase")))

                return Promise.resolve()
            },
        },
    ],
    city: [
        {
            validator: (_rule: any, value: string) => {
                // Optional
                if (!value || value === "") return Promise.resolve()

                if (value.length > 20) return Promise.reject(new Error(i18n.t("validation:max.string", { max: 20 })))

                return Promise.resolve()
            },
        },
        allowlistCharValidator,
    ],
    zip: [
        {
            validator: (_rule: any, value: string) => {
                // Optional
                if (!value || value === "") return Promise.resolve()

                if (value.length > 10) return Promise.reject(new Error(i18n.t("validation:max.string", { max: 12 })))
                if (!/^[A-Za-z0-9 -]*$/.test(value)) return Promise.reject(new Error(i18n.t("validation:extended_alphanumeric")))

                return Promise.resolve()
            },
        },
        allowlistCharValidator,
    ],
    archiveAfterXDays: [
        {
            validator: (_rule: any, value: number) => {
                if (value < 1) return Promise.reject(new Error(i18n.t("validation:min.number", { min: 1 })))
                if (!Number.isInteger(value)) return Promise.reject(new Error(i18n.t("validation:integer")))
                return Promise.resolve()
            },
        },
    ],
    mileageDistanceBuffer: [
        { transform: (v) => Number(v), type: "number", message: i18n.t("validation:numeric") },
        { transform: (v) => Number(v), type: "number", min: 0, message: i18n.t("validation:min.number", { min: 0 }) },
    ],
    required: [{ required: true, message: i18n.t("validation:required") }],
})

export default CompanySettingsRules
