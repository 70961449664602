export const SESSION_INVALID_KEY = "SESSION_INVALID"
const SESSION_STORAGE_EVENT = "session-storage"

const SessionStorageService = {
    setRequireReAuthentication: () => sessionStorage.setItem("REQUIRE_RE_AUTHENTICATION", "true"),
    getRequireReAuthentication: () => !!sessionStorage.getItem("REQUIRE_RE_AUTHENTICATION"),

    getSessionInvalid: () => sessionStorage.getItem(SESSION_INVALID_KEY),

    setSessionInvalid: () => {
        if (SessionStorageService.getSessionInvalid() === null) {
            sessionStorage.setItem(SESSION_INVALID_KEY, "true")
            window.dispatchEvent(new Event(SESSION_STORAGE_EVENT))
        }
    },

    clearSessionInvalid: () => {
        sessionStorage.removeItem(SESSION_INVALID_KEY)
        window.dispatchEvent(new Event(SESSION_STORAGE_EVENT))
    },

    clear: () => sessionStorage.clear(),
}

export default SessionStorageService
