import { Button, Form, Modal } from "antd"
import { Store } from "antd/lib/form/interface"
import httpStatus from "http-status"
import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import { CreateCardFrom } from "Components/forms"
import { CardPrefillData } from "Components/forms/createCard.form"
import { NotificationService } from "Shared/services"
import CardService from "Shared/services/card.service"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { setShouldRefetch } from "Shared/store/actions/creditCard/creditCardActions"
import { syncExpense } from "Shared/store/actions/expense/expenseActions"
import { refetchCardRelatedTables } from "Shared/store/actions/tables/tableActions"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

interface CreateCardModalInterface {
    isVisible: boolean
    hideCardType?: boolean
    prefilledData?: CardPrefillData
    onCancel: (state: boolean) => void
}
const CreateCardModal = ({ isVisible, hideCardType = false, prefilledData, onCancel }: CreateCardModalInterface) => {
    const { t } = useTranslation()
    const [formInstance] = Form.useForm()
    const [isLoading, setIsLoading] = useStateIfMounted<boolean>(false)
    const dispatch = useDispatch()

    const onSubmit = (values: Store) => {
        const { cardHolder, ...createData } = values
        setIsLoading(true)

        const expenseId = prefilledData?.expense?._id
        createData.expense = expenseId
        CardService.createCard(cardHolder, createData)
            .then(async () => {
                NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:cards.created.title"), t("notification:cards.created.message"))
                await dispatch(setShouldRefetch(true))
                dispatch(refetchCardRelatedTables())
                expenseId && (await dispatch(syncExpense(expenseId)))
                onHide()
            })
            .catch((err) => {
                if (err.response?.data?.error?.includes("Physical card upgrade failed")) {
                    NotificationService.send(NotificationTypeEnum.WARNING, t("error:cards.physical_card_upgrade.title"), t("error:cards.physical_card_upgrade.message"), 0)
                    dispatch(setShouldRefetch(true))
                    dispatch(refetchCardRelatedTables())
                    onHide()
                } else {
                    NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:cards.create.title"))
                    if (err.response?.status === httpStatus.UNPROCESSABLE_ENTITY) {
                        // this means the card was still generated but an error ocurred while doing so, so close the modal.
                        dispatch(setShouldRefetch(true))
                        onHide()
                    }
                }
            })
            .finally(() => setIsLoading(false))
    }

    const onHide = () => {
        setIsLoading(false)
        formInstance.resetFields()
        onCancel(false)
    }

    return (
        <>
            <Modal
                className="xs:ant-modal--card md:ant-modal--large"
                title={t("creditCard:action.create_new")}
                visible={isVisible}
                maskClosable={false}
                onCancel={onHide}
                cancelText={t("action:cancel")}
                confirmLoading={isLoading}
                destroyOnClose
                footer={[
                    <Button key="back" onClick={onHide}>
                        {t("action:cancel")}
                    </Button>,
                    <Button key="submit" type="primary" loading={isLoading} disabled={isLoading} onClick={() => formInstance.submit()}>
                        {t("creditCard:action.submit")}
                    </Button>,
                ]}
            >
                <CreateCardFrom formInstance={formInstance} hideCardType={hideCardType} prefilledData={prefilledData} onSubmit={onSubmit} />
            </Modal>
        </>
    )
}

export default CreateCardModal
