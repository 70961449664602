import { GREEN } from "@finway-group/shared/lib/consts"
import { Alert, Button, Space } from "antd"
import Modal from "antd/lib/modal/Modal"
import React from "react"
import { Check as CheckedIcon } from "react-feather"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

import Icon from "Components/icon"
import { redirectToFreeDemo } from "Shared/utils/liquidity.utils"

interface UpgradeAlertInterface {}
const UpgradeAlert: React.FC<UpgradeAlertInterface> = ({}) => {
    const { t } = useTranslation()

    return (
        <div>
            <Alert
                className="ant-alert--upgrade-version"
                message=""
                description={
                    <>
                        <div className="flex justify-between pr-24">
                            <div className="text-white">
                                <div className="text-2xl">{t("info:upgrade_banner:title")}</div>
                                <div className="mt-8 max-w-4xl">{t("info:upgrade_banner:description")}</div>
                            </div>

                            <div className="flex items-center w-160">
                                <Button key="submit" type="default" onClick={() => redirectToFreeDemo(t)} size="large" className="bg-white upgrade-blue w-full h-40">
                                    <Icon icon="rocket" className="mr-8" color="#3578E9" />
                                    {t("label:upgrade")}
                                </Button>
                            </div>
                        </div>
                    </>
                }
                type="warning"
                closable
            />
        </div>
    )
}

export default UpgradeAlert
