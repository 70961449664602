import { Form, Select } from "antd"
import React, { useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { matchPath, useHistory, useLocation } from "react-router-dom"

import { DoneIcon } from "Components/icons"
import SelectIcon from "Components/icons/selectIcon"
import { useCompanies, useCompany } from "Shared/hooks/company.hooks"
import { useLoggedInEmployee } from "Shared/hooks/employee.hooks"
import { useIsSubCompaniesEnabled } from "Shared/hooks/featureFlags.hooks"
import { invalidateNotifications } from "Shared/queries/notification.queries"
import { detailPageRoutePaths } from "Shared/router/RoutePath.enum"
import { NotificationService } from "Shared/services"
import { selectCompany } from "Shared/store/actions/company/companyActions"
import { RootState } from "Shared/store/rootState.interface"
import { getPopupAnchor } from "Shared/utils/popup.utils"

const CompanySelectionNavBar = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const location = useLocation()
    const history = useHistory()

    const selectRef = useRef<any>()
    const loggedInEmployee = useLoggedInEmployee()
    const companies = useCompanies().filter((company) => loggedInEmployee.companyProfiles?.find((e) => !e.deleted && e.companyId === company._id))
    const selectedCompany = useCompany()
    const isSubCompaniesEnabled = useIsSubCompaniesEnabled()

    const [formInstance] = Form.useForm()

    const hasLoaded = useSelector(({ fetcher }: RootState) => fetcher.hasLoaded)

    const handleCompanySelect = async (selectedId: string) => {
        const company = companies.find(({ _id }) => _id === selectedId)
        if (!company) return

        try {
            await selectCompany(company)(dispatch)

            // redirect directly when inside a details page
            const detailPagePathMatch = matchPath(location.pathname, { path: detailPageRoutePaths, exact: true })

            if (detailPagePathMatch) {
                const firstPath = location.pathname.split("/")[1]
                history.push(`/${firstPath}`)
            }

            invalidateNotifications()
        } catch (err) {
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:error"), t("error:unauthorized_action"))
            formInstance.setFieldsValue({ selectedCompany: selectedCompany._id })
        }
    }

    useEffect(() => {
        formInstance.setFieldsValue({ selectedCompany: selectedCompany._id })
    }, [selectedCompany?._id])

    const setFocus = () => {
        selectRef.current && selectRef.current.focus()
    }

    return (
        <div className="flex items-center mr-24 justify-center">
            {isSubCompaniesEnabled && companies.length > 1 ? (
                <Form className="h-32" form={formInstance}>
                    <Form.Item name="selectedCompany">
                        <Select
                            data-testid="selectCompanyDropdown"
                            getPopupContainer={getPopupAnchor()}
                            className="company-selector mt-auto mb-auto h-21 w-200"
                            suffixIcon={
                                <div onClick={setFocus}>
                                    <SelectIcon />
                                </div>
                            }
                            bordered={false}
                            menuItemSelectedIcon={<DoneIcon />}
                            onChange={handleCompanySelect}
                            showAction={["focus", "click"]}
                            defaultValue={selectedCompany._id}
                            showArrow
                            showSearch
                            placeholder={t("input:find_sub_company")}
                            optionFilterProp="children"
                            filterOption={(input, option) => (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())}
                            ref={selectRef}
                            disabled={!hasLoaded}
                        >
                            {companies
                                .sort((companyA: any, companyB: any) => companyA.name.localeCompare(companyB.name))
                                .map(({ name, _id }: any) => (
                                    <Select.Option key={_id} value={_id} className="text-left">
                                        {name}
                                    </Select.Option>
                                ))}
                        </Select>
                    </Form.Item>
                </Form>
            ) : (
                <></>
            )}
        </div>
    )
}

export default React.memo(CompanySelectionNavBar)
