import { SystemRightEnum } from "@finway-group/shared/lib/models"
import React, { useState } from "react"
import { Plus as PlusIcon } from "react-feather"
import { useDebouncedCallback } from "use-debounce/lib"

import { UserModal } from "Components/modals/user.modal"
import SearchInput from "Components/searchInput"
import SettingsLayout, { SettingsButton } from "Features/settings/settings.layout"
import UsersList from "Features/settings/users/users.list"
import { DEBOUNCE_DURATION_LONG } from "Shared/config/consts"
import { useModal } from "Shared/context/modal.context"
import { AuthzService } from "Shared/services"

const UsersContainer = () => {
    const [userSearch, setUserSearch] = useState("")
    const handleGlobalUserSearch = useDebouncedCallback((searchString) => setUserSearch(searchString), DEBOUNCE_DURATION_LONG)

    const { showModal } = useModal()
    const onCreateUser = () => {
        showModal(UserModal, true, {
            isShowing: true,
            isNew: true,
        })
    }

    const buttons = (
        <>
            <SearchInput className="min-w-32" onSearch={(searchString) => handleGlobalUserSearch.callback(searchString)} value={userSearch} />
            {AuthzService.isSystemRightGrantedForLoggedInUser(SystemRightEnum.SYS__USER__ALL__CREATE) && (
                <SettingsButton icon={<PlusIcon />} onClick={onCreateUser} labelKey={"action:user.create"} type="primary" />
            )}
        </>
    )

    return (
        <SettingsLayout titleKey="label:users" buttons={buttons}>
            <UsersList searchValue={userSearch} />
        </SettingsLayout>
    )
}

export default React.memo(UsersContainer)
