import { Form, Select } from "antd"
import { FormInstance } from "antd/lib/form"
import React from "react"
import { useTranslation } from "react-i18next"

import { useIsTravelEnabled } from "Shared/hooks/featureFlags.hooks"

import { WorkflowFormTypeEnum } from "./workflowFormValues.interface"

interface WorkflowTypeSelectInterface {
    value: WorkflowFormTypeEnum
    formInstance: FormInstance
    onSelect: (requestTypes: any) => void
}

export const WorkflowTypeSelect: React.FC<WorkflowTypeSelectInterface> = ({ onSelect }) => {
    const { t } = useTranslation()
    const isTravelEnabled = useIsTravelEnabled() ?? false

    const handleTypeChange = (selectValue: WorkflowFormTypeEnum) => {
        onSelect(selectValue)
    }

    return (
        <Form.Item label={t("label:workflow.workflow_type.title")} required name="workflowType" hidden={!isTravelEnabled}>
            <Select onChange={handleTypeChange}>
                <Select.Option value={WorkflowFormTypeEnum.REQUEST}>{t("label:workflow.workflow_type.request")}</Select.Option>
                <Select.Option value={WorkflowFormTypeEnum.FOLDER}>{t("label:workflow.workflow_type.folder")}</Select.Option>
            </Select>
        </Form.Item>
    )
}
