import { FilterBaseInterface, FilterPresetEnum } from "@finway-group/shared/lib/models"
import { deepEqual } from "fast-equals"

import store from "Shared/store"
import { setTableFilter, updateTableFilter } from "Shared/store/actions/tables/tableActions"
import { TablesEnum } from "Shared/store/reducers/tableConfigReducer"
import { formatRemovalFilterOptions, getCurrentPresets } from "Shared/utils/filter.utils"

/**
 * Apply newest filter object to the filter of a table. If shouldSetBackendFilter is not set or set to true, changes will be sent to the API to store the latest filter state
 *
 * @param table Table that is affected by the filter
 * @param updatedFilterObject The new filter object
 * @param ignorePresets If triggered from quickFilter
 * @param shouldSetBackendFilter Should the webapp sync the filter state with the backend? Should be false for socket handling (as we already receive the latest state from the API)
 */
export const applyFilter = async (table: TablesEnum, updatedFilterObject: object, ignorePresets = false, shouldSetBackendFilter = true) => {
    // only updated when needed
    const currentFilterObject = store.getState().tables[table].data.filter
    const currentPresets = getCurrentPresets(currentFilterObject)

    if (!ignorePresets) {
        updatedFilterObject = {
            ...updatedFilterObject,
            presets: currentPresets,
        }
    }

    const isDuplicate = deepEqual(currentFilterObject, updatedFilterObject)

    if (!isDuplicate) {
        if (shouldSetBackendFilter) {
            store.dispatch(setTableFilter(table, updatedFilterObject))
        } else {
            store.dispatch(updateTableFilter(table, updatedFilterObject))
        }
    }
}

export const setQuickFilter = (table: TablesEnum, presets: Array<FilterPresetEnum>) => {
    const filterObject = store.getState().tables[table].data.filter
    applyFilter(table, { ...filterObject, presets }, true)
}

export const resetFilter = (table: TablesEnum, except = {}) => {
    let shouldFetch = true
    if ([TablesEnum.TRANSACTIONS_ARCHIVE, TablesEnum.ARCHIVE].includes(table)) shouldFetch = false

    store.dispatch(setTableFilter(table, { ...except }, shouldFetch))
}

export const removeFilterOption = (table: TablesEnum, key: string, filterObject: FilterBaseInterface) => {
    const { formattedFilterObject, shouldFetch } = formatRemovalFilterOptions(table, key, filterObject)

    store.dispatch(setTableFilter(table, formattedFilterObject, shouldFetch))
}
