import { IntegrationType, PersonioAttributes } from "@finway-group/shared/lib/models"
import { Form, Switch, Tooltip } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"

import FormTextInput from "Components/form/formTextInput"
import { personioRules } from "Components/forms/rules/personio.rules"
import { getLanguageCode } from "Shared/hooks/i18n.hooks"
import { PERSONIO_RESOURCES } from "Shared/utils/personio.utils"

interface PersonioSettingsProps {
    connection: IntegrationType | undefined
}

const PersonioSettings = ({ connection }: PersonioSettingsProps) => {
    const { t } = useTranslation()

    const rules = personioRules()

    const languageCode = getLanguageCode()

    const personioHelpCenterArticle = PERSONIO_RESOURCES.general[languageCode]
    const clientIdHelpCenterArticle = PERSONIO_RESOURCES.anchorClientId[languageCode]

    const personioAttributes: Array<{
        name: keyof PersonioAttributes
        title: string
        tooltip: string
    }> = [
        {
            name: "enableAbsenceSync",
            title: t("label:personio.enable_absence_sync.title"),
            tooltip: t("label:personio.enable_absence_sync.tooltip"),
        },
        {
            name: "enableCreateEmployee",
            title: t("label:personio.enable_create_employee.title"),
            tooltip: t("label:personio.enable_create_employee.tooltip"),
        },
        {
            name: "enableEmployeeSync",
            title: t("label:personio.enable_employee_sync.title"),
            tooltip: t("label:personio.enable_employee_sync.tooltip"),
        },
        {
            name: "enableTeamSync",
            title: t("label:personio.enable_team_sync.title"),
            tooltip: t("label:personio.enable_team_sync.tooltip"),
        },
    ]

    return (
        <>
            <p className="pb-20">
                {`${t("label:personio.easily_setup")} `}
                <a href={personioHelpCenterArticle} rel="noreferrer" target="_blank">
                    {t("label:learn_more")}
                </a>
            </p>
            <FormTextInput
                key="clientIdKey"
                label={t("input:personio.client_id")}
                name="clientId"
                placeholder={t("placeholder:personio.client_id")}
                rules={rules.clientId}
                initialValue={connection?.clientId}
                link={{
                    to: clientIdHelpCenterArticle,
                    label: t("label:personio.how_to_obtain_client_id"),
                }}
            />
            <FormTextInput
                label={t("input:personio.client_secret")}
                name="clientSecret"
                placeholder={t("placeholder:personio.client_secret")}
                rules={rules.clientSecret}
                initialValue={connection?.clientSecret}
            />
            {personioAttributes.map((element, index) => (
                <li key={element.title.split(" ").join("_").toLowerCase()} className="flex items-center justify-between h-50">
                    <div className="flex flex-1 items-center w-3/4">
                        <Tooltip title={element.tooltip} className="p-5" getPopupContainer={(triggerNode: HTMLElement) => triggerNode}>
                            {element.title}
                        </Tooltip>
                    </div>
                    <div className="title font-normal flex items-center justify-end space-x-10">
                        <Form.Item name={["attributes", element.name]} className="h-6" initialValue={connection?.attributes?.[element.name] ?? true} valuePropName="checked">
                            <Switch className="mr-10 pb-2" />
                        </Form.Item>
                    </div>
                </li>
            ))}
        </>
    )
}

export default PersonioSettings
