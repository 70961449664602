import { EnrichedPaymentSettings, PaymentProductType, PaymentSettings } from "@finway-group/shared/lib/models"
import { ActionCreator, Dispatch } from "redux"

import { PaymentSettingsService } from "Shared/services"
import { PaymentProductUI } from "Shared/services/paymentSettings.service"
import { ThunkResult } from "Shared/store"

import { FetchPaymentProductsAction, FetchPaymentSettingsAction, PaymentActionTypes } from "./paymentTypes"

export const updatePaymentSettings: ActionCreator<ThunkResult<PaymentSettings>> = (update: EnrichedPaymentSettings) => async (dispatch: Dispatch) => {
    dispatch({
        type: PaymentActionTypes.FETCH_PAYMENT_SETTINGS,
        settings: { data: update, isFetching: false },
    } as FetchPaymentSettingsAction)

    return update
}

export const fetchPaymentSettings: ActionCreator<ThunkResult<PaymentSettings>> = () => async (dispatch: Dispatch) => {
    // FETCHING
    dispatch({
        type: PaymentActionTypes.FETCH_PAYMENT_SETTINGS,
        settings: { isFetching: true },
    } as FetchPaymentSettingsAction)

    const settings = await PaymentSettingsService.fetch()

    // FETCHED
    dispatch({
        type: PaymentActionTypes.FETCH_PAYMENT_SETTINGS,
        settings: { data: settings, isFetching: false },
    } as FetchPaymentSettingsAction)

    return settings
}

export const fetchPaymentProducts: ActionCreator<ThunkResult<Array<PaymentProductUI>>> = () => async (dispatch: Dispatch) => {
    // FETCHING
    dispatch({
        type: PaymentActionTypes.FETCH_PAYMENT_PRODUCTS,
        products: { plans: [], cards: [], isFetching: true },
    } as FetchPaymentProductsAction)

    const products = await PaymentSettingsService.getProducts()

    // FETCHED
    dispatch({
        type: PaymentActionTypes.FETCH_PAYMENT_PRODUCTS,
        products: {
            plans: products.filter((e) => e.productType === PaymentProductType.PLAN),
            cards: products.filter((e) => e.productType === PaymentProductType.CARD),
            isFetching: false,
        },
    } as FetchPaymentProductsAction)

    return products
}
