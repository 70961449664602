import { Tabs } from "antd"
import React, { Suspense, useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom"

import Loading from "Components/loading"
import TransactionCategoryListBanner from "Features/pages/liquidity/tabs/transactionCategoryListBanner"
import { useAccountIds } from "Shared/hooks/bank.hooks"
import { useIsFreeVersion } from "Shared/hooks/featureFlags.hooks"
import { useInterval, useLiquidityActiveTab, useScenario, useShouldFetchCashFlow } from "Shared/hooks/liquidity.hooks"
import { useTransactionCategories } from "Shared/hooks/transactionCategory.hooks"
import { NotificationService } from "Shared/services"
import {
    fetchCashFlowData,
    fetchLast6MonthsCashflowData,
    fetchYearlyCashflowData,
    setLiquidityActiveTab,
    setShouldFetchCashFlowsData,
} from "Shared/store/actions/liquidity/liquidityActions"

import useStateIfMounted from "../../../shared/utils/hooks/useStateIfMounted"
import { LMTabTypeEnum } from "./liquidity.types"

const CashFlowPlanningTab = React.lazy(() => import("./tabs/cashflowPlanning/cashflowPlanningTab"))
const TransactionCategoriesTab = React.lazy(() => import("./tabs/transactionCategoriesTab"))
const DashboardTab = React.lazy(() => import("./tabs/dashboard/dashboardTab"))

const { TabPane } = Tabs

export const LiquidityContainer: React.FC<{}> = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const isFreeVersion = useIsFreeVersion()

    const [controls, setControls] = useState(<></>)
    const [isTableLoading, setIsTableLoading] = useStateIfMounted(false)
    const [isYearlyCashFlowLoading, setIsYearlyCashFlowLoading] = useStateIfMounted(false)
    const bankAccountIds = useAccountIds({ shouldFilterDeleted: true })
    const hasAccountConnected = bankAccountIds.length > 0
    const liquidityActiveTab = useLiquidityActiveTab()
    const selectedScenario = useScenario()
    const dateInterval = useInterval()
    const transactionCategories = useTransactionCategories()
    const shouldFetch = useShouldFetchCashFlow()
    const { path } = useRouteMatch()

    const onTabClick = (key: string, _event: React.KeyboardEvent | React.MouseEvent) => {
        if (key !== liquidityActiveTab) {
            dispatch(setLiquidityActiveTab(key))
            setControls(<></>)
        }
    }

    const onCashFlowChange = () => {
        setShouldFetchCashFlowsData(true)(dispatch)
    }

    // For the widgets
    useEffect(() => {
        const fetchYearlyCashFlow = async () => {
            setIsYearlyCashFlowLoading(true)
            await fetchLast6MonthsCashflowData(selectedScenario._id)(dispatch)
            await fetchYearlyCashflowData(selectedScenario._id)(dispatch)
            setIsYearlyCashFlowLoading(false)
        }

        if (hasAccountConnected && selectedScenario?._id && (shouldFetch.yearlyCashFlows || shouldFetch.last6MonthsCashFlows) && !isYearlyCashFlowLoading) {
            fetchYearlyCashFlow().catch((err) => {
                NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:error"))
            })
        }
    }, [shouldFetch.yearlyCashFlows, shouldFetch.last6MonthsCashFlows])

    // For the diagrams
    useEffect(() => {
        const fetchCashFlows = async () => {
            if (selectedScenario._id && transactionCategories.length > 0 && shouldFetch.cashFlows && !isTableLoading) {
                setIsTableLoading(true)
                await fetchCashFlowData(dateInterval, selectedScenario._id)(dispatch)
                setIsTableLoading(false)
            }
        }
        // Only fetch when user has bank account connected
        if (hasAccountConnected && shouldFetch.cashFlows) {
            fetchCashFlows().catch((err) => {
                NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:error"))
            })
        }
    }, [shouldFetch.cashFlows])

    const menuList = [
        {
            link: "cashflow-planning",
            key: LMTabTypeEnum.CASHFLOW_PLANNING,
            title: t("label:liquidity.tabs.cashflow"),
            showTitle: true,
            component: <CashFlowPlanningTab isYearlyCashFlowLoading={isYearlyCashFlowLoading} isTableLoading={isTableLoading} onCashFlowChange={onCashFlowChange} />,
        },
        {
            link: "categorization",
            key: LMTabTypeEnum.TRANSACTION_CATEGORIES,
            title: t("label:liquidity.tabs.transaction_categories"),
            showTitle: false,
            component: <TransactionCategoriesTab setControls={setControls} />,
        },
        {
            link: "dashboard",
            key: LMTabTypeEnum.DASHBOARD,
            title: t("label:liquidity.tabs.dashboard"),
            showTitle: true,
            component: <DashboardTab isTableLoading={isTableLoading} onCashFlowChange={onCashFlowChange} isYearlyCashFlowLoading={isYearlyCashFlowLoading} />,
        },
    ]

    return (
        <div>
            <Helmet>
                <title>{t("view:liquidity.title")}</title>
            </Helmet>

            {isFreeVersion ? (
                <Switch>
                    <Route exact path={path}>
                        <Redirect to={{ pathname: `${path}/cashflow-planning` }} />
                    </Route>
                    {menuList.map((menuItem) => (
                        <Route key={menuItem.key} path={`${path}/${menuItem.link}`}>
                            <Suspense fallback={<Loading />}>
                                {menuItem.showTitle && <h1 className="mb-30">{menuItem.title}</h1>}
                                {menuItem.component}
                            </Suspense>
                        </Route>
                    ))}
                    <Redirect key="redirect" from="*" to={`${path}/cashflow-planning`} />
                </Switch>
            ) : (
                <>
                    <div className="flex gap-10 justify-between items-center mb-16">
                        <h1>{t("view:liquidity.title")}</h1>
                        <div className="flex flex-row space-x-10 min-h-34">
                            <div className="flex btn-wrapper">{controls}</div>
                        </div>
                    </div>

                    <TransactionCategoryListBanner />

                    <Tabs defaultActiveKey={LMTabTypeEnum.CASHFLOW_PLANNING} activeKey={liquidityActiveTab} onTabClick={onTabClick} className="pb-10">
                        {menuList.map((menuItem) => (
                            <TabPane forceRender={true} tab={menuItem.title} key={menuItem.key}>
                                <Suspense fallback={<Loading />}>{menuItem.component}</Suspense>
                            </TabPane>
                        ))}
                    </Tabs>
                </>
            )}
        </div>
    )
}

export default LiquidityContainer
