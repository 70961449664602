import { MAX_INPUT_LENGTH } from "@finway-group/shared/lib/utils/validators"
import { Col, Input, Row } from "antd"
import Form, { FormInstance } from "antd/lib/form"
import { Store } from "antd/lib/form/interface"
import React from "react"
import { useTranslation } from "react-i18next"

import CostCenter2Rules from "./rules/costCenter2.rules"

interface CostCenter2FormInterface {
    formInstance: FormInstance
    onSubmit: (values: Store) => void
}
const CostCenter2Form: React.FC<CostCenter2FormInterface> = ({ formInstance, onSubmit }) => {
    const { t } = useTranslation()
    const { name, code } = CostCenter2Rules(formInstance, false, false)

    return (
        <Form name="costCenters2" form={formInstance} layout="vertical" onFinish={onSubmit}>
            <Row gutter={[8, 8]} align="bottom">
                <Col span={12}>
                    <Form.Item name="name" label={t("input:cost_center_2.name")} key="name" rules={name} required>
                        <Input maxLength={MAX_INPUT_LENGTH} placeholder={t("placeholder:cost_center_2.name")} />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item name="code" label={t("input:cost_center_2.code")} key="code" rules={code} required>
                        <Input maxLength={MAX_INPUT_LENGTH} placeholder={t("placeholder:cost_center_2.code")} />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    )
}

export default CostCenter2Form
