import { Form, Modal } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import { WorkflowForm } from "Components/forms/workflowForm/workflow.form"
import { useWorkflow } from "Shared/hooks/workflow.hooks"
import { NotificationService } from "Shared/services"
import DialogService from "Shared/services/dialog.service"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { createWorkflow, updateWorkflow } from "Shared/store/actions/workflow/workflowActions"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"
import { WorkflowFormData } from "Shared/utils/workflow.form.utils"

interface WorkflowModalInterface {
    isVisible: boolean
    onCancel: (state: boolean) => void
}
const WorkflowModal: React.FC<WorkflowModalInterface> = ({ isVisible, onCancel }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const workflow = useWorkflow()
    const [formInstance] = Form.useForm()
    const [isLoading, setIsLoading] = useStateIfMounted(false)
    const isNew = workflow === undefined

    const onSubmit = async (values: WorkflowFormData, { recalculateApprovalProcessesInProgress }: { recalculateApprovalProcessesInProgress?: boolean }) => {
        setIsLoading(true)

        if (isNew) {
            try {
                await dispatch(createWorkflow(values))
                NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:workflow.created.title"), t("notification:workflow.created.message"))
                onHide()
            } catch (err) {
                NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:error"))
            }
        } else {
            try {
                if (workflow) {
                    if (recalculateApprovalProcessesInProgress) {
                        const confirmRecalculateApprovalProcesses = await DialogService.confirmRecalculateApprovalProcesses()
                        if (!confirmRecalculateApprovalProcesses) {
                            setIsLoading(false)
                            return
                        }
                    }
                    await dispatch(updateWorkflow(workflow._id, values, { recalculateApprovalProcessesNotStarted: true, recalculateApprovalProcessesInProgress }))
                    NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:workflow.updated.title"), t("notification:workflow.updated.message"))
                    onHide()
                }
            } catch (err) {
                NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:error"))
            }
        }
        setIsLoading(false)
    }

    const onHide = () => {
        setIsLoading(false)
        formInstance.resetFields()
        onCancel(false)
    }

    return (
        <Modal
            title={isNew ? t("action:workflow.create") : t("action:workflow.edit")}
            visible={isVisible}
            maskClosable={false}
            onOk={formInstance.submit}
            onCancel={onHide}
            okText={t("action:save")}
            cancelText={t("action:cancel")}
            confirmLoading={isLoading}
            keyboard
            destroyOnClose
            className="ant-modal--large"
        >
            <WorkflowForm formInstance={formInstance} isNew={isNew} onSubmit={onSubmit} />
        </Modal>
    )
}

export default WorkflowModal
