import { BankAccount, CreditorInterface, TransactionFilter, TransactionTypeEnum } from "@finway-group/shared/lib/models"
import { TransactionStatusEnum } from "@finway-group/shared/lib/models/transaction/transactionStatus.enum"
import { Form } from "antd"
import moment from "moment"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"

import { FilterFormInterface } from "Components/filter/filterTypes"
import { FormInputEnum, FormSelectOption } from "Components/form/form.types"
import FormMinMax from "Components/form/formMinMax"
import FormMinMaxDate from "Components/form/formMinMaxDate"
import FormSelect from "Components/form/formSelect"
import { useAccounts, useBankConnections } from "Shared/hooks/bank.hooks"
import { getTableFilterObjectStore } from "Shared/hooks/table.hooks"
import { createDynamicList, createDynamicListWithLabel, getDynamicCreditorList } from "Shared/utils/filter.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

import { generateRules } from "./rules/transactionFilter.rules"

const adjustFilterObject = (filterObject: TransactionFilter): TransactionFilter => ({
    ...filterObject,
    // minTransactionDate: filterObject.minTransactionDate ? moment(filterObject.minTransactionDate).startOf("day").toDate() : undefined,
    // maxTransactionDate: filterObject.maxTransactionDate ? moment(filterObject.maxTransactionDate).endOf("day").toDate() : undefined,
    minTransactionDate: filterObject.minTransactionDate ? moment(filterObject.minTransactionDate).toDate() : undefined,
    maxTransactionDate: filterObject.maxTransactionDate ? moment(filterObject.maxTransactionDate).toDate() : undefined,
    minTransactionAmount: filterObject.minTransactionAmount ? Number(filterObject.minTransactionAmount) : undefined,
    maxTransactionAmount: filterObject.maxTransactionAmount ? Number(filterObject.maxTransactionAmount) : undefined,
})

const TransactionFilterForm: React.FC<FilterFormInterface> = ({ table, formInstance, options, onSubmit }) => {
    const { t } = useTranslation()

    const rules = generateRules(formInstance, table)
    const tableFilterObject = getTableFilterObjectStore<TransactionFilter>(table)
    const [transactionFilter, setTransactionFilter] = useStateIfMounted(adjustFilterObject(tableFilterObject))
    const [transactionType, setTransactionType] = useStateIfMounted<TransactionTypeEnum | undefined>(transactionFilter.transactionType)
    const connections = useBankConnections({ shouldFilterDeleted: false })
    const accounts = useAccounts({ shouldFilterDeleted: false })

    useEffect(() => {
        formInstance.resetFields()
        setTransactionFilter(adjustFilterObject(tableFilterObject))
    }, [])

    const transactionTypeList: Array<FormSelectOption> = createDynamicList(
        "label:transaction_types.",
        [TransactionTypeEnum.BANK_TRANSACTION, TransactionTypeEnum.CSV_TRANSACTION, TransactionTypeEnum.CARD_TRANSACTION],
        true,
    )

    const transactionStatusList: Array<FormSelectOption> = createDynamicListWithLabel("label", [
        { param: "matching.transaction_tag.completed", value: TransactionStatusEnum.COMPLETED },
        { param: "matching.transaction_tag.pending", value: TransactionStatusEnum.PENDING },
        { param: "matching.transaction_tag.failed", value: TransactionStatusEnum.FAILED },
    ])

    const transactionMatchedList: Array<FormSelectOption> = createDynamicListWithLabel("label", [
        { param: "matched_transaction_options.matched", value: "true" },
        { param: "matched_transaction_options.not_matched", value: "false" },
    ])

    const accountsList: Array<FormSelectOption> = createDynamicListWithLabel(
        "",
        // de-dup accounts list because we might have the same pairs since we store deleted connection objects
        accounts
            .map((account: BankAccount) => {
                // find connection that has this account
                const connection = connections.find((connection) => connection.accounts.some((acc) => acc.accountId === account.accountId))
                return { param: `${connection?.bankName} - ${account.accountName}`, value: account.accountName }
            })
            .filter((account, index, self) => self.findIndex((t) => t.param === account.param) === index),
        true,
    )

    return (
        <Form id="transactionFilterForm" className="form--filter" form={formInstance} onFinish={onSubmit}>
            <FormMinMaxDate
                header={t("input:transaction_filter.transaction_date_range")}
                min={{
                    name: "minTransactionDate",
                    label: t("input:expense_filter.min_date"),
                    placeholder: t("placeholder:expense_filter.min_date"),
                    initialValue: transactionFilter.minTransactionDate,
                    rules: rules.minTransactionDate,
                    onChange: () => formInstance.validateFields(["minTransactionDate", "maxInvoiceDate"]),
                }}
                max={{
                    name: "maxTransactionDate",
                    label: t("input:expense_filter.max_date"),
                    placeholder: t("placeholder:expense_filter.max_date"),
                    initialValue: transactionFilter.maxTransactionDate,
                    rules: rules.maxTransactionDate,
                    onChange: () => formInstance.validateFields(["minTransactionDate", "maxTransactionDate"]),
                }}
            />

            <FormSelect
                name="vendor"
                header={t("input:filter_form.vendor")}
                initialValue={transactionFilter.vendor}
                options={getDynamicCreditorList(options.creditors as Array<CreditorInterface>)}
                isCollapse
                showSearch
                fallback
            />

            <FormSelect
                name="transactionType"
                header={t("input:transaction_filter.transaction_type")}
                initialValue={transactionFilter.transactionType}
                options={transactionTypeList}
                isCollapse
                fallback
                onChange={(value: string) => setTransactionType(value as TransactionTypeEnum)}
            />

            {transactionType === TransactionTypeEnum.BANK_TRANSACTION && (
                <FormSelect
                    isForm
                    name="accountName"
                    header={t("input:transaction_filter.bank_account")}
                    initialValue={transactionFilter.accountName}
                    options={accountsList}
                    isCollapse
                    fallback
                />
            )}

            <FormMinMax
                header={t("input:filter_form.amount")}
                inputType={FormInputEnum.NUMBER}
                min={{
                    name: "minTransactionAmount",
                    label: t("input:filter_form.min_amount"),
                    placeholder: t("placeholder:expense_filter.min_amount"),
                    onChange: () => formInstance.validateFields(["minTransactionAmount", "maxTransactionAmount"]),
                    initialValue: transactionFilter.minTransactionAmount,
                    rules: rules.minTransactionAmount,
                }}
                max={{
                    name: "maxTransactionAmount",
                    label: t("input:filter_form.max_amount"),
                    placeholder: t("placeholder:expense_filter.max_amount"),
                    onChange: () => formInstance.validateFields(["maxTransactionAmount", "minTransactionAmount"]),
                    initialValue: transactionFilter.maxTransactionAmount,
                    rules: rules.maxTransactionAmount,
                }}
            />

            <FormSelect
                name="transactionStatus"
                header={t("input:transaction_filter.transaction_status")}
                initialValue={(transactionFilter.transactionStatus ?? "").toString()}
                options={transactionStatusList}
                isCollapse
                fallback
            />

            <FormSelect
                name="transactionMatched"
                header={t("input:transaction_filter.transaction_matched")}
                initialValue={transactionFilter.transactionMatched}
                options={transactionMatchedList}
                isCollapse
                fallback
            />
        </Form>
    )
}

export default TransactionFilterForm
