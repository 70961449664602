import { Employee, Expense, RightEnum, Role, StandardRoleEnum, SystemRightEnum } from "@finway-group/shared/lib/models"
import { checkIfUserIsPendingApproverOfExpense } from "@finway-group/shared/lib/utils/approvalProcess.utils"

import { UserService } from "Shared/services"
import store from "Shared/store"
import { RootState } from "Shared/store/rootState.interface"
import { isRightGranted } from "Shared/utils/helper.utils"

/**
 * Authorization Service - determines what an authenticated user can see and do
 */
const AuthzService = {
    getUserRoleById: (id: string): Role | undefined => {
        const state: RootState = store.getState()
        const roles = state.roles.items
        return roles.find((role: Role) => role._id === id)
    },

    isRightGrantedForLoggedInUser: (right: RightEnum) => {
        const state: RootState = store.getState()
        return state.auth?.accessToken?.activeRights?.includes(right) || false
    },

    // Expecting that in the future we might need to actually split those rights as of now we keep it this way
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
    isSystemRightGrantedForLoggedInUser: (_right: SystemRightEnum) => {
        const user = UserService.getLoggedInUserProfile()
        // currently when this flag is set ALL SystemRights are granted might change in the future
        return user?.hasSystemRights || false
    },

    canViewSystemSettings: () => {
        const user = UserService.getLoggedInUserProfile()
        // currently when this flag is set ALL SystemRights are granted might change in the future
        return user?.hasSystemRights || false
    },

    hasCompanySettingsUpdateSystemRight: () => AuthzService.isSystemRightGrantedForLoggedInUser(SystemRightEnum.SYS__COMPANY__ALL__UPDATE),

    hasEmployeeSystemRight: (employee: Employee) => employee?.hasSystemRights || false,

    isDataUploaderGrantedForLoggedInUser: (): boolean => {
        const user = UserService.getLoggedInUserProfile()
        return user.hasSystemRights && AuthzService.isUserDefaultAdmin()
    },

    isLoggedInUserPotentialApprover: (): boolean => AuthzService.isRightGrantedForLoggedInUser(RightEnum.EXPENSE__BASIC__APPROVE),

    isLoggedInUserAllowedToApproveExpense: (expense: Expense): boolean => {
        const user = UserService.getLoggedInUserProfile()

        // if user is global approver
        if (AuthzService.isRightGrantedForLoggedInUser(RightEnum.EXPENSE__ALL__APPROVE)) return true

        // if user is the next approver
        const isApproverOfApprovalProcess = checkIfUserIsPendingApproverOfExpense(expense.approvalProcesses, user.id)
        if (isApproverOfApprovalProcess) return true

        // if user is approver of team and expense is from same team
        return AuthzService.isRightGrantedForLoggedInUser(RightEnum.EXPENSE__TEAM__APPROVE) && user.getUserCompanyProfile(expense.companyId)?.team === expense.team
    },

    isLoggedInUserGlobalApprover: (): boolean => AuthzService.isRightGrantedForLoggedInUser(RightEnum.EXPENSE__ALL__APPROVE),

    isLoggedInUserAllowedToCreateExpenses: (): boolean => {
        const state: RootState = store.getState()
        return state.auth?.accessToken?.activeRights?.some((right) => [RightEnum.EXPENSE__ALL__CREATE, RightEnum.EXPENSE__ALL__CREATE_ON_BEHALF].includes(right)) || false
    },

    isAllowedToCreateVendors: (): boolean => AuthzService.isRightGrantedForLoggedInUser(RightEnum.VENDOR__ALL__CREATE),

    isAllowedToCreateUnapprovedVendors: (): boolean => AuthzService.isRightGrantedForLoggedInUser(RightEnum.VENDOR__ALL__CREATE_UNAPPROVED),

    isPotentialApprover: (roleId: string) => {
        const role = AuthzService.getUserRoleById(roleId)
        return isRightGranted(role?.rights, RightEnum.EXPENSE__BASIC__APPROVE)
    },

    isGlobalApprover: (roleId: string) => {
        const role = AuthzService.getUserRoleById(roleId)
        return isRightGranted(role?.rights, RightEnum.EXPENSE__ALL__APPROVE)
    },

    canManageCardForUser: (cardHolder?: Employee) => {
        if (!cardHolder) return false
        if (!cardHolder.activeCompanyProfile?.weavrData?.userId) return false // discard user who is not enrolled in the card program

        const loggedInUser = UserService.getLoggedInEmployeeProfile()

        return (
            AuthzService.isRightGrantedForLoggedInUser(RightEnum.CARD__ALL__WRITE) ||
            (AuthzService.isRightGrantedForLoggedInUser(RightEnum.CARD__TEAM__WRITE) && loggedInUser.activeCompanyProfile.team === cardHolder.activeCompanyProfile.team)
        )
    },

    isUserDefaultAdmin: (): boolean => {
        const role = UserService.getLoggedInEmployeeRole()
        return (role?.isReadOnly && role?.name === StandardRoleEnum.ADMIN) ?? false
    },

    canUpdateCompanyAccountingSettings: (): boolean => AuthzService.isRightGrantedForLoggedInUser(RightEnum.COMPANY_ACCOUNTING_SETTINGS__ALL__WRITE),
    canViewCompanyAccountingSettings: (): boolean => AuthzService.isRightGrantedForLoggedInUser(RightEnum.COMPANY_ACCOUNTING_SETTINGS__ALL__READ),
    canUpdateCompanyFeatureSettings: (): boolean => AuthzService.isRightGrantedForLoggedInUser(RightEnum.COMPANY_FEATURE_SETTINGS__ALL__WRITE),

    canUpdateFeatureCompanySettings: (): boolean =>
        AuthzService.isSystemRightGrantedForLoggedInUser(SystemRightEnum.SYS__COMPANY__ALL__UPDATE) ||
        AuthzService.isRightGrantedForLoggedInUser(RightEnum.COMPANY_FEATURE_SETTINGS__ALL__WRITE),

    canLoggedInUserArchiveRequest: (user: Employee, requestedBy: Employee): boolean =>
        AuthzService.isRightGrantedForLoggedInUser(RightEnum.EXPENSE__ALL__ARCHIVE) ||
        (AuthzService.isRightGrantedForLoggedInUser(RightEnum.EXPENSE__TEAM__ARCHIVE) && user.activeCompanyProfile.team === requestedBy.activeCompanyProfile.team),

    canLoggedInUserDeleteRequest: (user: Employee, requestedBy: Employee): boolean =>
        AuthzService.isRightGrantedForLoggedInUser(RightEnum.EXPENSE__ALL__DELETE) ||
        (AuthzService.isRightGrantedForLoggedInUser(RightEnum.EXPENSE__TEAM__DELETE) && user.activeCompanyProfile.team === requestedBy.activeCompanyProfile.team),

    canLoggedInUserResetStatusRequest: (user: Employee, requestedBy: Employee): boolean =>
        AuthzService.isRightGrantedForLoggedInUser(RightEnum.EXPENSE__ALL__RESET_STATUS) ||
        (AuthzService.isRightGrantedForLoggedInUser(RightEnum.EXPENSE__TEAM__RESET_STATUS) && user.activeCompanyProfile.team === requestedBy.activeCompanyProfile.team),

    canUpdateUserApprovalData: (user: Employee): boolean => {
        const loggedInUser = UserService.getLoggedInEmployeeProfile()
        const isUserFromSameTeam = user.activeCompanyProfile?.team === loggedInUser.activeCompanyProfile.team
        return (
            AuthzService.isRightGrantedForLoggedInUser(RightEnum.EMPLOYEE_APPROVAL_DATA__ALL__UPDATE) ||
            (AuthzService.isRightGrantedForLoggedInUser(RightEnum.EMPLOYEE_APPROVAL_DATA__TEAM__UPDATE) &&
                isUserFromSameTeam &&
                !isRightGranted(AuthzService.getUserRoleById(user.activeCompanyProfile.roleId)?.rights, RightEnum.EMPLOYEE_APPROVAL_DATA__ALL__UPDATE))
        )
    },
    canStartKyb: (): boolean => AuthzService.isRightGrantedForLoggedInUser(RightEnum.CARD_WALLET__ALL__MANAGE),
    canManageTags: (editingTagsEnabled: boolean) =>
        // When editingTagsEnabled company setting is enabled then everyone can manage tags (except auditors), otherwise user has to have the right COMPANY_ACCOUNTING_SETTINGS__ALL__WRITE
        editingTagsEnabled ? AuthzService.isLoggedInUserAllowedToCreateExpenses() : AuthzService.canUpdateCompanyAccountingSettings(),

    canCreateSubscription: () =>
        AuthzService.isRightGrantedForLoggedInUser(RightEnum.SUBSCRIPTION__ALL__WRITE) || AuthzService.isRightGrantedForLoggedInUser(RightEnum.SUBSCRIPTION__TEAM__WRITE),
    canUpdateSubscription: (isMyOwnSubscription: boolean, isSubscriptionOfMyTeam: boolean, isSubscriptionOfMyCostCenter: boolean) => {
        if (!AuthzService.isLoggedInUserAllowedToCreateExpenses()) return false // early return

        return (
            AuthzService.isRightGrantedForLoggedInUser(RightEnum.SUBSCRIPTION__ALL__WRITE) ||
            (AuthzService.isRightGrantedForLoggedInUser(RightEnum.SUBSCRIPTION__TEAM__WRITE) && isSubscriptionOfMyTeam) ||
            isMyOwnSubscription ||
            isSubscriptionOfMyCostCenter
        )
    },
    canLoggedInUserSeeBankAccounts: (): boolean => AuthzService.isRightGrantedForLoggedInUser(RightEnum.BANK_ACCOUNT__ALL__READ),
    canLoggedInUserExport: (): boolean => AuthzService.isRightGrantedForLoggedInUser(RightEnum.EXPENSE__ALL__EXPORT),
    canLoggedInUserSeeExpensesToExport: (): boolean => AuthzService.isRightGrantedForLoggedInUser(RightEnum.EXPENSE__ALL__ACCESS_TABLE_EXPORT_READ),
    canLoggedInUserPayByBank: (): boolean => AuthzService.isRightGrantedForLoggedInUser(RightEnum.EXPENSE__ALL__PAY_BY_BANK),
    canLoggedInUserSeeExpensesToPay: (): boolean => AuthzService.isRightGrantedForLoggedInUser(RightEnum.EXPENSE__ALL__ACCESS_TABLE_PAY_READ),
    canLoggedInUserReviewExpenses: (): boolean => AuthzService.isRightGrantedForLoggedInUser(RightEnum.EXPENSE__ALL__REVIEW),
    canLoggedInUserSeeExpensesToReview: (): boolean => AuthzService.isRightGrantedForLoggedInUser(RightEnum.EXPENSE__ALL__ACCESS_TABLE_IN_REVIEW_READ),

    canLoggedInUserViewWallet: (): boolean => AuthzService.isRightGrantedForLoggedInUser(RightEnum.CARD_WALLET__ALL__READ),
    canLoggedInUserViewTeamCards: (): boolean => AuthzService.isRightGrantedForLoggedInUser(RightEnum.CARD__TEAM__READ),
    canLoggedInUserViewAllCards: (): boolean => AuthzService.isRightGrantedForLoggedInUser(RightEnum.CARD__ALL__READ),

    canViewExpenseFromCostCenterPage: (expense: Expense): boolean => {
        const isUserFromSameTeam = UserService.isInMyTeam(expense.requestedBy._id)

        return (
            AuthzService.isRightGrantedForLoggedInUser(RightEnum.EXPENSE__ALL__READ) ||
            (AuthzService.isRightGrantedForLoggedInUser(RightEnum.EXPENSE__TEAM__READ) && isUserFromSameTeam)
        )
    },

    canViewEmployeeExpenses: (user: Employee): boolean => {
        const loggedInUser = UserService.getLoggedInEmployeeProfile()
        const isUserFromSameTeam = user.activeCompanyProfile?.team === loggedInUser.activeCompanyProfile.team
        return (
            loggedInUser.id === user.id ||
            AuthzService.isRightGrantedForLoggedInUser(RightEnum.EXPENSE__ALL__READ) ||
            (AuthzService.isRightGrantedForLoggedInUser(RightEnum.EXPENSE__TEAM__READ) && isUserFromSameTeam)
        )
    },
    canViewEmployeeDetails: (user: Employee): boolean => {
        const loggedInUser = UserService.getLoggedInEmployeeProfile()
        const isUserFromSameTeam = user.activeCompanyProfile?.team === loggedInUser.activeCompanyProfile.team
        return (
            AuthzService.isRightGrantedForLoggedInUser(RightEnum.EMPLOYEE__ALL__READ) ||
            (AuthzService.isRightGrantedForLoggedInUser(RightEnum.EMPLOYEE__TEAM__READ) && isUserFromSameTeam)
        )
    },
    canUpdateEmployeeDetails: (user?: Employee): boolean => {
        if (!user) return false
        const loggedInUser = UserService.getLoggedInEmployeeProfile()
        const isUserFromSameTeam = user.activeCompanyProfile?.team === loggedInUser.activeCompanyProfile.team
        return (
            AuthzService.isRightGrantedForLoggedInUser(RightEnum.EMPLOYEE__ALL__UPDATE) ||
            (AuthzService.isRightGrantedForLoggedInUser(RightEnum.EMPLOYEE__TEAM__UPDATE) && isUserFromSameTeam)
        )
    },
    canResetTfaForEmployee: (user: Employee): boolean => {
        const hasRightsToReset = AuthzService.isSystemRightGrantedForLoggedInUser(SystemRightEnum.SYS__USER__ALL__UPDATE)
        const canReset = hasRightsToReset && user.settings?.security?.tfaEnabled!!

        return canReset
    },

    isAllowedToCreateExpenses: (roleId: string): boolean => {
        const role = AuthzService.getUserRoleById(roleId)
        return isRightGranted(role?.rights, RightEnum.EXPENSE__ALL__CREATE)
    },
}

export default AuthzService
