import React from "react"

export const MapPinMultiIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <circle cx="12" cy="12" r="11.8391" fill="#3886FF" stroke="white" strokeWidth="0.321798" />
        <g clipPath="url(#clip0_111_28068)">
            <path
                d="M7.0594 17.9315L4.94569 14.1263C4.76245 13.7887 4.6582 13.4024 4.6582 12.9918C4.6582 11.6717 5.73325 10.6016 7.0594 10.6016C8.38554 10.6016 9.46059 11.6717 9.46059 12.9918C9.46059 13.4024 9.35634 13.7887 9.1731 14.1263L7.0594 17.9315Z"
                fill="white"
                stroke="white"
                strokeWidth="0.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.05928 14.142C7.68745 14.142 8.19669 13.6328 8.19669 13.0046C8.19669 12.3764 7.68745 11.8672 7.05928 11.8672C6.43111 11.8672 5.92188 12.3764 5.92188 13.0046C5.92188 13.6328 6.43111 14.142 7.05928 14.142Z"
                fill="#3886FF"
            />
        </g>
        <g clipPath="url(#clip1_111_28068)">
            <path
                d="M16.5379 17.9315L14.4242 14.1263C14.241 13.7887 14.1367 13.4024 14.1367 12.9918C14.1367 11.6717 15.2118 10.6016 16.5379 10.6016C17.8641 10.6016 18.9391 11.6717 18.9391 12.9918C18.9391 13.4024 18.8349 13.7887 18.6516 14.1263L16.5379 17.9315Z"
                fill="white"
                stroke="white"
                strokeWidth="0.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16.5378 14.142C17.166 14.142 17.6752 13.6328 17.6752 13.0046C17.6752 12.3764 17.166 11.8672 16.5378 11.8672C15.9096 11.8672 15.4004 12.3764 15.4004 13.0046C15.4004 13.6328 15.9096 14.142 16.5378 14.142Z"
                fill="#3886FF"
            />
        </g>
        <g clipPath="url(#clip2_111_28068)">
            <path
                d="M11.7996 12.576L9.68592 8.77081C9.50269 8.43323 9.39844 8.04692 9.39844 7.6363C9.39844 6.31622 10.4735 5.24609 11.7996 5.24609C13.1258 5.24609 14.2008 6.31622 14.2008 7.6363C14.2008 8.04692 14.0966 8.43323 13.9133 8.77081L11.7996 12.576Z"
                fill="white"
                stroke="white"
                strokeWidth="0.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.7995 8.78653C12.4277 8.78653 12.9369 8.2773 12.9369 7.64913C12.9369 7.02095 12.4277 6.51172 11.7995 6.51172C11.1713 6.51172 10.6621 7.02095 10.6621 7.64913C10.6621 8.2773 11.1713 8.78653 11.7995 8.78653Z"
                fill="#3886FF"
            />
        </g>
        <defs>
            <clipPath id="clip0_111_28068">
                <rect width="7.58271" height="9.09925" fill="white" transform="translate(3.27344 9.71484)" />
            </clipPath>
            <clipPath id="clip1_111_28068">
                <rect width="7.58271" height="9.09925" fill="white" transform="translate(12.752 9.71484)" />
            </clipPath>
            <clipPath id="clip2_111_28068">
                <rect width="7.58271" height="9.09925" fill="white" transform="translate(8.01367 4.36328)" />
            </clipPath>
        </defs>
    </svg>
)
