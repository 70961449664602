/*
MULTIPLE TABLE ENUMS
*/
export enum TableActionsTypes {
    // all tables
    INITIAL_FETCH_TABLES = "INITIAL_FETCH_TABLES",
    RESET_ALL_TABLES = "RESET_ALL_TABLES",

    // expense tables & related tables
    REFETCH_EXPENSE_RELATED_TABLES = "REFETCH_EXPENSE_RELATED_TABLES",

    // inbox invoice tables
    REFETCH_INBOX_INVOICE_TABLES = "REFETCH_INBOX_INVOICE_TABLES",

    // card tables & related tables
    REFETCH_CARD_RELATED_TABLES = "REFETCH_CARD_RELATED_TABLES",

    // user tables & related tables
    REFETCH_USER_RELATED_TABLES = "REFETCH_USER_RELATED_TABLES",

    // transaction categories tables & related tables
    REFETCH_TRANSACTION_CATEGORY_RELATED_TABLES = "REFETCH_TRANSACTION_CATEGORY_RELATED_TABLES",

    // dashboard tables
    UPDATE_DASHBOARD_TABLES_FILTER = "UPDATE_DASHBOARD_TABLES_FILTER",
    UPDATE_DASHBOARD_TABLES_SEARCH = "UPDATE_DASHBOARD_TABLES_SEARCH",

    // subscriptions
    UPDATE_SUBSCRIPTION_TABLES_FILTER = "UPDATE_SUBSCRIPTION_TABLES_FILTER",

    REFETCH_MULTIPLE_TABLES = "REFETCH_MULTIPLE_TABLES",

    // reset archive table
    RESET_TABLE_ARCHIVE = "RESET_TABLE_ARCHIVE",
}

/*
SINGLE TABLE ENUMS
*/
export enum TableFetchingTypes {
    TABLE_FETCHING = "TABLE_FETCHING",
    TABLE_FETCHING_DONE_REQUESTS = "TABLE_FETCHING_DONE_REQUESTS",
    TABLE_FETCHING_IN_PROGRESS = "TABLE_FETCHING_IN_PROGRESS",
    TABLE_FETCHING_ARCHIVE = "TABLE_FETCHING_ARCHIVE",
    TABLE_FETCHING_TRANSACTIONS_ARCHIVE = "TABLE_FETCHING_TRANSACTIONS_ARCHIVE",
    TABLE_FETCHING_CARDS = "TABLE_FETCHING_CARDS",
    TABLE_FETCHING_CARD_TRANSACTIONS = "TABLE_FETCHING_CARD_TRANSACTIONS",
    TABLE_FETCHING_COST_CENTERS = "TABLE_FETCHING_COST_CENTERS",
    TABLE_FETCHING_EMPLOYEES = "TABLE_FETCHING_EMPLOYEES",
    TABLE_FETCHING_INBOX_INVOICE = "TABLE_FETCHING_INBOX_INVOICE",
    TABLE_FETCHING_INBOX_INVOICE_MODAL_EXPENSES = "TABLE_FETCHING_INBOX_INVOICE_MODAL_EXPENSES",
    TABLE_FETCHING_INBOX_INVOICE_MODAL_INVOICES = "TABLE_FETCHING_INBOX_INVOICE_MODAL_INVOICES",
    TABLE_FETCHING_PAY_AND_EXPORT_TO_BE_EXPORTED = "TABLE_FETCHING_PAY_AND_EXPORT_TO_BE_EXPORTED",
    TABLE_FETCHING_PAY_AND_EXPORT_TO_BE_PAID = "TABLE_FETCHING_PAY_AND_EXPORT_TO_BE_PAID",
    TABLE_FETCHING_ALL_REQUESTS = "TABLE_FETCHING_ALL_REQUESTS",
    TABLE_FETCHING_POSSIBLE_MATCHES = "TABLE_FETCHING_POSSIBLE_MATCHES",
    TABLE_FETCHING_REVIEW_REQUESTS = "TABLE_FETCHING_REVIEW_REQUESTS",
    TABLE_FETCHING_SUBSCRIPTIONS = "TABLE_FETCHING_SUBSCRIPTIONS",
    TABLE_FETCHING_TODO_APPROVAL_PENDING = "TABLE_FETCHING_TODO_APPROVAL_PENDING",
    TABLE_FETCHING_TODO_DOCS_NEEDED = "TABLE_FETCHING_TODO_DOCS_NEEDED",
    TABLE_FETCHING_TODO_INVOICE_APPROVAL = "TABLE_FETCHING_TODO_INVOICE_APPROVAL",
    TABLE_FETCHING_TODO_PURCHASE_APPROVAL = "TABLE_FETCHING_TODO_PURCHASE_APPROVAL",
    TABLE_FETCHING_TODO_INVOICE_AND_PURCHASE = "TABLE_FETCHING_TODO_INVOICE_AND_PURCHASE",
    TABLE_FETCHING_TRANSACTIONS_INFLOW = "TABLE_FETCHING_TRANSACTIONS_INFLOW",
    TABLE_FETCHING_TRANSACTIONS_OUTFLOW = "TABLE_FETCHING_TRANSACTIONS_OUTFLOW",
    TABLE_FETCHING_TRANSACTIONS_ALL = "TABLE_FETCHING_TRANSACTIONS_ALL",
    TABLE_FETCHING_EXPENSE_TRANSACTIONS = "TABLE_FETCHING_EXPENSE_TRANSACTIONS",
    TABLE_FETCHING_VENDORS = "TABLE_FETCHING_VENDORS",
    TABLE_FETCHING_EMPLOYEE_VENDORS = "TABLE_FETCHING_EMPLOYEE_VENDORS",
    TABLE_FETCHING_WORKFLOWS = "TABLE_FETCHING_WORKFLOWS",
    TABLE_FETCHING_TRANSACTION_CATEGORIES_INFLOW = "TABLE_FETCHING_TRANSACTION_CATEGORIES_INFLOW",
    TABLE_FETCHING_TRANSACTION_CATEGORIES_OUTFLOW = "TABLE_FETCHING_TRANSACTION_CATEGORIES_OUTFLOW",
    TABLE_FETCHING_ATTACH_EXPENSE = "TABLE_FETCHING_ATTACH_EXPENSE",
    TABLE_FETCHING_TRIP_FOLDER_DRAFT = "TABLE_FETCHING_TRIP_FOLDER_DRAFT",
    TABLE_FETCHING_EXPORT_HISTORY = "TABLE_FETCHING_EXPORT_HISTORY",
}

export enum FetchTableSuccessTypes {
    FETCH_TABLE_SUCCESS = "FETCH_TABLE_SUCCESS",
    FETCH_TABLE_SUCCESS_DONE_REQUESTS = "FETCH_TABLE_SUCCESS_DONE_REQUESTS",
    FETCH_TABLE_SUCCESS_IN_PROGRESS = "FETCH_TABLE_SUCCESS_IN_PROGRESS",
    FETCH_TABLE_SUCCESS_ALL_REQUESTS = "FETCH_TABLE_SUCCESS_ALL_REQUESTS",
    FETCH_TABLE_SUCCESS_ARCHIVE = "FETCH_TABLE_SUCCESS_ARCHIVE",
    FETCH_TABLE_SUCCESS_TRANSACTIONS_ARCHIVE = "FETCH_TABLE_SUCCESS_TRANSACTIONS_ARCHIVE",
    FETCH_TABLE_SUCCESS_CARDS = "FETCH_TABLE_SUCCESS_CARDS",
    FETCH_TABLE_SUCCESS_CARD_TRANSACTIONS = "FETCH_TABLE_SUCCESS_CARD_TRANSACTIONS",
    FETCH_TABLE_SUCCESS_COST_CENTERS = "FETCH_TABLE_SUCCESS_COST_CENTERS",
    FETCH_TABLE_SUCCESS_EMPLOYEES = "FETCH_TABLE_SUCCESS_EMPLOYEES",
    FETCH_TABLE_SUCCESS_INBOX_INVOICE = "FETCH_TABLE_SUCCESS_INBOX_INVOICE",
    FETCH_TABLE_SUCCESS_INBOX_INVOICE_MODAL_EXPENSES = "FETCH_TABLE_SUCCESS_INBOX_INVOICE_MODAL_EXPENSES",
    FETCH_TABLE_SUCCESS_INBOX_INVOICE_MODAL_INVOICES = "FETCH_TABLE_SUCCESS_INBOX_INVOICE_MODAL_INVOICES",
    FETCH_TABLE_SUCCESS_PAY_AND_EXPORT_TO_BE_EXPORTED = "FETCH_TABLE_SUCCESS_PAY_AND_EXPORT_TO_BE_EXPORTED",
    FETCH_TABLE_SUCCESS_PAY_AND_EXPORT_TO_BE_PAID = "FETCH_TABLE_SUCCESS_PAY_AND_EXPORT_TO_BE_PAID",
    FETCH_TABLE_SUCCESS_POSSIBLE_MATCHES = "FETCH_TABLE_SUCCESS_POSSIBLE_MATCHES",
    FETCH_TABLE_SUCCESS_REVIEW_REQUESTS = "FETCH_TABLE_SUCCESS_REVIEW_REQUESTS",
    FETCH_TABLE_SUCCESS_SUBSCRIPTIONS = "FETCH_TABLE_SUCCESS_SUBSCRIPTIONS",
    FETCH_TABLE_SUCCESS_TODO_APPROVAL_PENDING = "FETCH_TABLE_SUCCESS_TODO_APPROVAL_PENDING",
    FETCH_TABLE_SUCCESS_TODO_DOCS_NEEDED = "FETCH_TABLE_SUCCESS_TODO_DOCS_NEEDED",
    FETCH_TABLE_SUCCESS_TODO_INVOICE_APPROVAL = "FETCH_TABLE_SUCCESS_TODO_INVOICE_APPROVAL",
    FETCH_TABLE_SUCCESS_TODO_PURCHASE_APPROVAL = "FETCH_TABLE_SUCCESS_TODO_PURCHASE_APPROVAL",
    FETCH_TABLE_SUCCESS_TODO_INVOICE_AND_PURCHASE = "FETCH_TABLE_SUCCESS_TODO_INVOICE_AND_PURCHASE",
    FETCH_TABLE_SUCCESS_TRANSACTIONS_INFLOW = "FETCH_TABLE_SUCCESS_TRANSACTIONS_INFLOW",
    FETCH_TABLE_SUCCESS_TRANSACTIONS_OUTFLOW = "FETCH_TABLE_SUCCESS_TRANSACTIONS_OUTFLOW",
    FETCH_TABLE_SUCCESS_TRANSACTIONS_ALL = "FETCH_TABLE_SUCCESS_TRANSACTIONS_ALL",
    FETCH_TABLE_SUCCESS_EXPENSE_TRANSACTIONS = "FETCH_TABLE_SUCCESS_EXPENSE_TRANSACTIONS",
    FETCH_TABLE_SUCCESS_VENDORS = "FETCH_TABLE_SUCCESS_VENDORS",
    FETCH_TABLE_SUCCESS_EMPLOYEE_VENDORS = "FETCH_TABLE_SUCCESS_EMPLOYEE_VENDORS",
    FETCH_TABLE_SUCCESS_WORKFLOWS = "FETCH_TABLE_SUCCESS_WORKFLOWS",
    FETCH_TABLE_SUCCESS_TRANSACTION_CATEGORIES_INFLOW = "FETCH_TABLE_SUCCESS_TRANSACTION_CATEGORIES_INFLOW",
    FETCH_TABLE_SUCCESS_TRANSACTION_CATEGORIES_OUTFLOW = "FETCH_TABLE_SUCCESS_TRANSACTION_CATEGORIES_OUTFLOW",
    FETCH_TABLE_SUCCESS_ATTACH_EXPENSE = "FETCH_TABLE_SUCCESS_ATTACH_EXPENSE",
    FETCH_TABLE_SUCCESS_TRIP_FOLDER_DRAFT = "FETCH_TABLE_SUCCESS_TRIP_FOLDER_DRAFT",
    FETCH_TABLE_SUCCESS_EXPORT_HISTORY = "FETCH_TABLE_SUCCESS_EXPORT_HISTORY",
}

export enum FetchTableFailureTypes {
    FETCH_TABLE_FAILURE = "FETCH_TABLE_FAILURE",
    FETCH_TABLE_FAILURE_DONE_REQUESTS = "FETCH_TABLE_FAILURE_DONE_REQUESTS",
    FETCH_TABLE_FAILURE_ALL_REQUESTS = "FETCH_TABLE_FAILURE_ALL_REQUESTS",
    FETCH_TABLE_FAILURE_IN_PROGRESS = "FETCH_TABLE_FAILURE_IN_PROGRESS",
    FETCH_TABLE_FAILURE_ARCHIVE = "FETCH_TABLE_FAILURE_ARCHIVE",
    FETCH_TABLE_FAILURE_TRANSACTIONS_ARCHIVE = "FETCH_TABLE_FAILURE_TRANSACTIONS_ARCHIVE",
    FETCH_TABLE_FAILURE_CARDS = "FETCH_TABLE_FAILURE_CARDS",
    FETCH_TABLE_FAILURE_CARD_TRANSACTIONS = "FETCH_TABLE_FAILURE_CARD_TRANSACTIONS",
    FETCH_TABLE_FAILURE_COST_CENTERS = "FETCH_TABLE_FAILURE_COST_CENTERS",
    FETCH_TABLE_FAILURE_EMPLOYEES = "FETCH_TABLE_FAILURE_EMPLOYEES",
    FETCH_TABLE_FAILURE_INBOX_INVOICE = "FETCH_TABLE_FAILURE_INBOX_INVOICE",
    FETCH_TABLE_FAILURE_INBOX_INVOICE_MODAL_EXPENSES = "FETCH_TABLE_FAILURE_INBOX_INVOICE_MODAL_EXPENSES",
    FETCH_TABLE_FAILURE_INBOX_INVOICE_MODAL_INVOICES = "FETCH_TABLE_FAILURE_INBOX_INVOICE_MODAL_INVOICES",
    FETCH_TABLE_FAILURE_PAY_AND_EXPORT_TO_BE_EXPORTED = "FETCH_TABLE_FAILURE_PAY_AND_EXPORT_TO_BE_EXPORTED",
    FETCH_TABLE_FAILURE_PAY_AND_EXPORT_TO_BE_PAID = "FETCH_TABLE_FAILURE_PAY_AND_EXPORT_TO_BE_PAID",
    FETCH_TABLE_FAILURE_POSSIBLE_MATCHES = "FETCH_TABLE_FAILURE_POSSIBLE_MATCHES",
    FETCH_TABLE_FAILURE_REVIEW_REQUESTS = "FETCH_TABLE_FAILURE_REVIEW_REQUESTS",
    FETCH_TABLE_FAILURE_SUBSCRIPTIONS = "FETCH_TABLE_FAILURE_SUBSCRIPTIONS",
    FETCH_TABLE_FAILURE_TODO_APPROVAL_PENDING = "FETCH_TABLE_FAILURE_TODO_APPROVAL_PENDING",
    FETCH_TABLE_FAILURE_TODO_DOCS_NEEDED = "FETCH_TABLE_FAILURE_TODO_DOCS_NEEDED",
    FETCH_TABLE_FAILURE_TODO_INVOICE_APPROVAL = "FETCH_TABLE_FAILURE_TODO_INVOICE_APPROVAL",
    FETCH_TABLE_FAILURE_TODO_PURCHASE_APPROVAL = "FETCH_TABLE_FAILURE_TODO_PURCHASE_APPROVAL",
    FETCH_TABLE_FAILURE_TODO_INVOICE_AND_PURCHASE = "FETCH_TABLE_FAILURE_TODO_INVOICE_AND_PURCHASE",
    FETCH_TABLE_FAILURE_TRANSACTIONS_INFLOW = "FETCH_TABLE_FAILURE_TRANSACTIONS_INFLOW",
    FETCH_TABLE_FAILURE_TRANSACTIONS_OUTFLOW = "FETCH_TABLE_FAILURE_TRANSACTIONS_OUTFLOW",
    FETCH_TABLE_FAILURE_TRANSACTIONS_ALL = "FETCH_TABLE_FAILURE_TRANSACTIONS_ALL",
    FETCH_TABLE_FAILURE_EXPENSE_TRANSACTIONS = "FETCH_TABLE_FAILURE_EXPENSE_TRANSACTIONS",
    FETCH_TABLE_FAILURE_VENDORS = "FETCH_TABLE_FAILURE_VENDORS",
    FETCH_TABLE_FAILURE_EMPLOYEE_VENDORS = "FETCH_TABLE_FAILURE_EMPLOYEE_VENDORS",
    FETCH_TABLE_FAILURE_WORKFLOWS = "FETCH_TABLE_FAILURE_WORKFLOWS",
    FETCH_TABLE_FAILURE_TRANSACTION_CATEGORIES_INFLOW = "FETCH_TABLE_FAILURE_TRANSACTION_CATEGORIES_INFLOW",
    FETCH_TABLE_FAILURE_TRANSACTION_CATEGORIES_OUTFLOW = "FETCH_TABLE_FAILURE_TRANSACTION_CATEGORIES_OUTFLOW",
    FETCH_TABLE_FAILURE_ATTACH_EXPENSE = "FETCH_TABLE_FAILURE_ATTACH_EXPENSE",
    FETCH_TABLE_FAILURE_TRIP_FOLDER_DRAFT = "FETCH_TABLE_FAILURE_TRIP_FOLDER_DRAFT",
    FETCH_TABLE_FAILURE_EXPORT_HISTORY = "FETCH_TABLE_FAILURE_EXPORT_HISTORY",
}

export enum RefetchTableTypes {
    REFETCH_TABLE = "REFETCH_TABLE",
    REFETCH_TABLE_DONE_REQUESTS = "REFETCH_TABLE_DONE_REQUESTS",
    REFETCH_TABLE_ALL_REQUESTS = "REFETCH_TABLE_ALL_REQUESTS",
    REFETCH_TABLE_IN_PROGRESS = "REFETCH_TABLE_IN_PROGRESS",
    REFETCH_TABLE_ARCHIVE = "REFETCH_TABLE_ARCHIVE",
    REFETCH_TABLE_TRANSACTIONS_ARCHIVE = "REFETCH_TABLE_TRANSACTIONS_ARCHIVE",
    REFETCH_TABLE_CARDS = "REFETCH_TABLE_CARDS",
    REFETCH_TABLE_CARD_TRANSACTIONS = "REFETCH_TABLE_CARD_TRANSACTIONS",
    REFETCH_TABLE_COST_CENTERS = "REFETCH_TABLE_COST_CENTERS",
    REFETCH_TABLE_EMPLOYEES = "REFETCH_TABLE_EMPLOYEES",
    REFETCH_TABLE_INBOX_INVOICE = "REFETCH_TABLE_INBOX_INVOICE",
    REFETCH_TABLE_INBOX_INVOICE_MODAL_EXPENSES = "REFETCH_TABLE_INBOX_INVOICE_MODAL_EXPENSES",
    REFETCH_TABLE_INBOX_INVOICE_MODAL_INVOICES = "REFETCH_TABLE_INBOX_INVOICE_MODAL_INVOICES",
    REFETCH_TABLE_PAY_AND_EXPORT_TO_BE_EXPORTED = "REFETCH_TABLE_PAY_AND_EXPORT_TO_BE_EXPORTED",
    REFETCH_TABLE_PAY_AND_EXPORT_TO_BE_PAID = "REFETCH_TABLE_PAY_AND_EXPORT_TO_BE_PAID",
    REFETCH_TABLE_POSSIBLE_MATCHES = "REFETCH_TABLE_POSSIBLE_MATCHES",
    REFETCH_TABLE_REVIEW_REQUESTS = "REFETCH_TABLE_REVIEW_REQUESTS",
    REFETCH_TABLE_SUBSCRIPTIONS = "REFETCH_TABLE_SUBSCRIPTIONS",
    REFETCH_TABLE_TODO_APPROVAL_PENDING = "REFETCH_TABLE_TODO_APPROVAL_PENDING",
    REFETCH_TABLE_TODO_DOCS_NEEDED = "REFETCH_TABLE_TODO_DOCS_NEEDED",
    REFETCH_TABLE_TODO_INVOICE_APPROVAL = "REFETCH_TABLE_TODO_INVOICE_APPROVAL",
    REFETCH_TABLE_TODO_PURCHASE_APPROVAL = "REFETCH_TABLE_TODO_PURCHASE_APPROVAL",
    REFETCH_TABLE_TODO_INVOICE_AND_PURCHASE = "REFETCH_TABLE_TODO_INVOICE_AND_PURCHASE",
    REFETCH_TABLE_TRANSACTIONS_INFLOW = "REFETCH_TABLE_TRANSACTIONS_INFLOW",
    REFETCH_TABLE_TRANSACTIONS_OUTFLOW = "REFETCH_TABLE_TRANSACTIONS_OUTFLOW",
    REFETCH_TABLE_TRANSACTIONS_ALL = "REFETCH_TABLE_TRANSACTIONS_ALL",
    REFETCH_TABLE_EXPENSE_TRANSACTIONS = "REFETCH_TABLE_EXPENSE_TRANSACTIONS",
    REFETCH_TABLE_VENDORS = "REFETCH_TABLE_VENDORS",
    REFETCH_TABLE_EMPLOYEE_VENDORS = "REFETCH_TABLE_EMPLOYEE_VENDORS",
    REFETCH_TABLE_WORKFLOWS = "REFETCH_TABLE_WORKFLOWS",
    REFETCH_TABLE_TRANSACTION_CATEGORIES_INFLOW = "REFETCH_TABLE_TRANSACTION_CATEGORIES_INFLOW",
    REFETCH_TABLE_TRANSACTION_CATEGORIES_OUTFLOW = "REFETCH_TABLE_TRANSACTION_CATEGORIES_OUTFLOW",
    REFETCH_TABLE_ATTACH_EXPENSE = "REFETCH_TABLE_ATTACH_EXPENSE",
    REFETCH_TABLE_FAILURE_TRIP_FOLDER_DRAFT = "REFETCH_TABLE_FAILURE_TRIP_FOLDER_DRAFT",
    REFETCH_TABLE_EXPORT_HISTORY = "REFETCH_TABLE_EXPORT_HISTORY",
}

export enum UpdateTablePaginationType {
    UPDATE_TABLE_PAGINATION = "UPDATE_TABLE_PAGINATION",
    UPDATE_TABLE_PAGINATION_DONE_REQUESTS = "UPDATE_TABLE_PAGINATION_DONE_REQUESTS",
    UPDATE_TABLE_PAGINATION_ALL_REQUESTS = "UPDATE_TABLE_PAGINATION_ALL_REQUESTS",
    UPDATE_TABLE_PAGINATION_IN_PROGRESS = "UPDATE_TABLE_PAGINATION_IN_PROGRESS",
    UPDATE_TABLE_PAGINATION_ARCHIVE = "UPDATE_TABLE_PAGINATION_ARCHIVE",
    UPDATE_TABLE_PAGINATION_TRANSACTIONS_ARCHIVE = "UPDATE_TABLE_PAGINATION_TRANSACTIONS_ARCHIVE",
    UPDATE_TABLE_PAGINATION_CARDS = "UPDATE_TABLE_PAGINATION_CARDS",
    UPDATE_TABLE_PAGINATION_CARD_TRANSACTIONS = "UPDATE_TABLE_PAGINATION_CARD_TRANSACTIONS",
    UPDATE_TABLE_PAGINATION_COST_CENTERS = "UPDATE_TABLE_PAGINATION_COST_CENTERS",
    UPDATE_TABLE_PAGINATION_EMPLOYEES = "UPDATE_TABLE_PAGINATION_EMPLOYEES",
    UPDATE_TABLE_PAGINATION_INBOX_INVOICE = "UPDATE_TABLE_PAGINATION_INBOX_INVOICE",
    UPDATE_TABLE_PAGINATION_INBOX_INVOICE_MODAL_EXPENSES = "UPDATE_TABLE_PAGINATION_INBOX_INVOICE_MODAL_EXPENSES",
    UPDATE_TABLE_PAGINATION_INBOX_INVOICE_MODAL_INVOICES = "UPDATE_TABLE_PAGINATION_INBOX_INVOICE_MODAL_INVOICES",
    UPDATE_TABLE_PAGINATION_PAY_AND_EXPORT_TO_BE_EXPORTED = "UPDATE_TABLE_PAGINATION_PAY_AND_EXPORT_TO_BE_EXPORTED",
    UPDATE_TABLE_PAGINATION_PAY_AND_EXPORT_TO_BE_PAID = "UPDATE_TABLE_PAGINATION_PAY_AND_EXPORT_TO_BE_PAID",
    UPDATE_TABLE_PAGINATION_POSSIBLE_MATCHES = "UPDATE_TABLE_PAGINATION_POSSIBLE_MATCHES",
    UPDATE_TABLE_PAGINATION_REVIEW_REQUESTS = "UPDATE_TABLE_PAGINATION_REVIEW_REQUESTS",
    UPDATE_TABLE_PAGINATION_SUBSCRIPTIONS = "UPDATE_TABLE_PAGINATION_SUBSCRIPTIONS",
    UPDATE_TABLE_PAGINATION_TODO_APPROVAL_PENDING = "UPDATE_TABLE_PAGINATION_TODO_APPROVAL_PENDING",
    UPDATE_TABLE_PAGINATION_TODO_DOCS_NEEDED = "UPDATE_TABLE_PAGINATION_TODO_DOCS_NEEDED",
    UPDATE_TABLE_PAGINATION_TODO_INVOICE_APPROVAL = "UPDATE_TABLE_PAGINATION_TODO_INVOICE_APPROVAL",
    UPDATE_TABLE_PAGINATION_TODO_PURCHASE_APPROVAL = "UPDATE_TABLE_PAGINATION_TODO_PURCHASE_APPROVAL",
    UPDATE_TABLE_PAGINATION_TODO_INVOICE_AND_PURCHASE = "UPDATE_TABLE_PAGINATION_TODO_INVOICE_AND_PURCHASE",
    UPDATE_TABLE_PAGINATION_TRANSACTIONS_INFLOW = "UPDATE_TABLE_PAGINATION_TRANSACTIONS_INFLOW",
    UPDATE_TABLE_PAGINATION_TRANSACTIONS_OUTFLOW = "UPDATE_TABLE_PAGINATION_TRANSACTIONS_OUTFLOW",
    UPDATE_TABLE_PAGINATION_TRANSACTIONS_ALL = "UPDATE_TABLE_PAGINATION_TRANSACTIONS_ALL",
    UPDATE_TABLE_PAGINATION_EXPENSE_TRANSACTIONS = "UPDATE_TABLE_PAGINATION_EXPENSE_TRANSACTIONS",
    UPDATE_TABLE_PAGINATION_VENDORS = "UPDATE_TABLE_PAGINATION_VENDORS",
    UPDATE_TABLE_PAGINATION_EMPLOYEE_VENDORS = "UPDATE_TABLE_PAGINATION_EMPLOYEE_VENDORS",
    UPDATE_TABLE_PAGINATION_WORKFLOWS = "UPDATE_TABLE_PAGINATION_WORKFLOWS",
    UPDATE_TABLE_PAGINATION_TRANSACTION_CATEGORIES_INFLOW = "UPDATE_TABLE_PAGINATION_TRANSACTION_CATEGORIES_INFLOW",
    UPDATE_TABLE_PAGINATION_TRANSACTION_CATEGORIES_OUTFLOW = "UPDATE_TABLE_PAGINATION_TRANSACTION_CATEGORIES_OUTFLOW",
    UPDATE_TABLE_PAGINATION_ATTACH_EXPENSE = "UPDATE_TABLE_PAGINATION_ATTACH_EXPENSE",
    UPDATE_TABLE_PAGINATION_TRIP_FOLDER_DRAFT = "UPDATE_TABLE_PAGINATION_TRIP_FOLDER_DRAFT",
    UPDATE_TABLE_PAGINATION_EXPORT_HISTORY = "UPDATE_TABLE_PAGINATION_EXPORT_HISTORY",
}

export enum UpdateTableSortType {
    UPDATE_TABLE_SORT = "UPDATE_TABLE_SORT",
    UPDATE_TABLE_SORT_DONE_REQUESTS = "UPDATE_TABLE_SORT_DONE_REQUESTS",
    UPDATE_TABLE_SORT_ALL_REQUESTS = "UPDATE_TABLE_SORT_ALL_REQUESTS",
    UPDATE_TABLE_SORT_IN_PROGRESS = "UPDATE_TABLE_SORT_IN_PROGRESS",
    UPDATE_TABLE_SORT_ARCHIVE = "UPDATE_TABLE_SORT_ARCHIVE",
    UPDATE_TABLE_SORT_TRANSACTIONS_ARCHIVE = "UPDATE_TABLE_SORT_TRANSACTIONS_ARCHIVE",
    UPDATE_TABLE_SORT_CARDS = "UPDATE_TABLE_SORT_CARDS",
    UPDATE_TABLE_SORT_CARD_TRANSACTIONS = "UPDATE_TABLE_SORT_CARD_TRANSACTIONS",
    UPDATE_TABLE_SORT_COST_CENTERS = "UPDATE_TABLE_SORT_COST_CENTERS",
    UPDATE_TABLE_SORT_EMPLOYEES = "UPDATE_TABLE_SORT_EMPLOYEES",
    UPDATE_TABLE_SORT_INBOX_INVOICE = "UPDATE_TABLE_SORT_INBOX_INVOICE",
    UPDATE_TABLE_SORT_INBOX_INVOICE_MODAL_EXPENSES = "UPDATE_TABLE_SORT_INBOX_INVOICE_MODAL_EXPENSES",
    UPDATE_TABLE_SORT_INBOX_INVOICE_MODAL_INVOICES = "UPDATE_TABLE_SORT_INBOX_INVOICE_MODAL_INVOICES",
    UPDATE_TABLE_SORT_PAY_AND_EXPORT_TO_BE_EXPORTED = "UPDATE_TABLE_SORT_PAY_AND_EXPORT_TO_BE_EXPORTED",
    UPDATE_TABLE_SORT_PAY_AND_EXPORT_TO_BE_PAID = "UPDATE_TABLE_SORT_PAY_AND_EXPORT_TO_BE_PAID",
    UPDATE_TABLE_SORT_POSSIBLE_MATCHES = "UPDATE_TABLE_SORT_POSSIBLE_MATCHES",
    UPDATE_TABLE_SORT_REVIEW_REQUESTS = "UPDATE_TABLE_SORT_REVIEW_REQUESTS",
    UPDATE_TABLE_SORT_SUBSCRIPTIONS = "UPDATE_TABLE_SORT_SUBSCRIPTIONS",
    UPDATE_TABLE_SORT_TODO_APPROVAL_PENDING = "UPDATE_TABLE_SORT_TODO_APPROVAL_PENDING",
    UPDATE_TABLE_SORT_TODO_DOCS_NEEDED = "UPDATE_TABLE_SORT_TODO_DOCS_NEEDED",
    UPDATE_TABLE_SORT_TODO_INVOICE_APPROVAL = "UPDATE_TABLE_SORT_TODO_INVOICE_APPROVAL",
    UPDATE_TABLE_SORT_TODO_PURCHASE_APPROVAL = "UPDATE_TABLE_SORT_TODO_PURCHASE_APPROVAL",
    UPDATE_TABLE_SORT_TODO_INVOICE_AND_PURCHASE = "UPDATE_TABLE_SORT_TODO_INVOICE_AND_PURCHASE",
    UPDATE_TABLE_SORT_TRANSACTIONS_INFLOW = "UPDATE_TABLE_SORT_TRANSACTIONS_INFLOW",
    UPDATE_TABLE_SORT_TRANSACTIONS_OUTFLOW = "UPDATE_TABLE_SORT_TRANSACTIONS_OUTFLOW",
    UPDATE_TABLE_SORT_TRANSACTIONS_ALL = "UPDATE_TABLE_SORT_TRANSACTIONS_ALL",
    UPDATE_TABLE_SORT_EXPENSE_TRANSACTIONS = "UPDATE_TABLE_SORT_EXPENSE_TRANSACTIONS",
    UPDATE_TABLE_SORT_VENDORS = "UPDATE_TABLE_SORT_VENDORS",
    UPDATE_TABLE_SORT_EMPLOYEE_VENDORS = "UPDATE_TABLE_SORT_EMPLOYEE_VENDORS",
    UPDATE_TABLE_SORT_TRANSACTION_CATEGORIES_INFLOW = "UPDATE_TABLE_SORT_TRANSACTION_CATEGORIES_INFLOW",
    UPDATE_TABLE_SORT_TRANSACTION_CATEGORIES_OUTFLOW = "UPDATE_TABLE_SORT_TRANSACTION_CATEGORIES_OUTFLOW",
    UPDATE_TABLE_SORT_ATTACH_EXPENSE = "UPDATE_TABLE_SORT_ATTACH_EXPENSE",
    UPDATE_TABLE_SORT_TRIP_FOLDER_DRAFT = "UPDATE_TABLE_SORT_TRIP_FOLDER_DRAFT",
    UPDATE_TABLE_SORT_EXPORT_HISTORY = "UPDATE_TABLE_SORT_EXPORT_HISTORY",
}

export enum UpdateTableSearchType {
    UPDATE_TABLE_SEARCH = "UPDATE_TABLE_SEARCH",
    UPDATE_TABLE_SEARCH_DONE_REQUESTS = "UPDATE_TABLE_SEARCH_DONE_REQUESTS",
    UPDATE_TABLE_SEARCH_ALL_REQUESTS = "UPDATE_TABLE_SEARCH_ALL_REQUESTS",
    UPDATE_TABLE_SEARCH_IN_PROGRESS = "UPDATE_TABLE_SEARCH_IN_PROGRESS",
    UPDATE_TABLE_SEARCH_ARCHIVE = "UPDATE_TABLE_SEARCH_ARCHIVE",
    UPDATE_TABLE_SEARCH_TRANSACTIONS_ARCHIVE = "UPDATE_TABLE_SEARCH_TRANSACTIONS_ARCHIVE",
    UPDATE_TABLE_SEARCH_CARDS = "UPDATE_TABLE_SEARCH_CARDS",
    UPDATE_TABLE_SEARCH_CARD_TRANSACTIONS = "UPDATE_TABLE_SEARCH_CARD_TRANSACTIONS",
    UPDATE_TABLE_SEARCH_COST_CENTERS = "UPDATE_TABLE_SEARCH_COST_CENTERS",
    UPDATE_TABLE_SEARCH_EMPLOYEES = "UPDATE_TABLE_SEARCH_EMPLOYEES",
    UPDATE_TABLE_SEARCH_INBOX_INVOICE = "UPDATE_TABLE_SEARCH_INBOX_INVOICE",
    UPDATE_TABLE_SEARCH_INBOX_INVOICE_MODAL_EXPENSES = "UPDATE_TABLE_SEARCH_INBOX_INVOICE_MODAL_EXPENSES",
    UPDATE_TABLE_SEARCH_INBOX_INVOICE_MODAL_INVOICES = "UPDATE_TABLE_SEARCH_INBOX_INVOICE_MODAL_INVOICES",
    UPDATE_TABLE_SEARCH_PAY_AND_EXPORT_TO_BE_EXPORTED = "UPDATE_TABLE_SEARCH_PAY_AND_EXPORT_TO_BE_EXPORTED",
    UPDATE_TABLE_SEARCH_PAY_AND_EXPORT_TO_BE_PAID = "UPDATE_TABLE_SEARCH_PAY_AND_EXPORT_TO_BE_PAID",
    UPDATE_TABLE_SEARCH_POSSIBLE_MATCHES = "UPDATE_TABLE_SEARCH_POSSIBLE_MATCHES",
    UPDATE_TABLE_SEARCH_REVIEW_REQUESTS = "UPDATE_TABLE_SEARCH_REVIEW_REQUESTS",
    UPDATE_TABLE_SEARCH_SUBSCRIPTIONS = "UPDATE_TABLE_SEARCH_SUBSCRIPTIONS",
    UPDATE_TABLE_SEARCH_TODO_APPROVAL_PENDING = "UPDATE_TABLE_SEARCH_TODO_APPROVAL_PENDING",
    UPDATE_TABLE_SEARCH_TODO_DOCS_NEEDED = "UPDATE_TABLE_SEARCH_TODO_DOCS_NEEDED",
    UPDATE_TABLE_SEARCH_TODO_INVOICE_APPROVAL = "UPDATE_TABLE_SEARCH_TODO_INVOICE_APPROVAL",
    UPDATE_TABLE_SEARCH_TODO_PURCHASE_APPROVAL = "UPDATE_TABLE_SEARCH_TODO_PURCHASE_APPROVAL",
    UPDATE_TABLE_SEARCH_TODO_INVOICE_AND_PURCHASE = "UPDATE_TABLE_SEARCH_TODO_INVOICE_AND_PURCHASE",
    UPDATE_TABLE_SEARCH_TRANSACTIONS_OUTFLOW = "UPDATE_TABLE_SEARCH_TRANSACTIONS_OUTFLOW",
    UPDATE_TABLE_SEARCH_TRANSACTIONS_INFLOW = "UPDATE_TABLE_SEARCH_TRANSACTIONS_INFLOW",
    UPDATE_TABLE_SEARCH_TRANSACTIONS_ALL = "UPDATE_TABLE_SEARCH_TRANSACTIONS_ALL",
    UPDATE_TABLE_SEARCH_EXPENSE_TRANSACTIONS = "UPDATE_TABLE_SEARCH_EXPENSE_TRANSACTIONS",
    UPDATE_TABLE_SEARCH_VENDORS = "UPDATE_TABLE_SEARCH_VENDORS",
    UPDATE_TABLE_SEARCH_EMPLOYEE_VENDORS = "UPDATE_TABLE_SEARCH_EMPLOYEE_VENDORS",
    UPDATE_TABLE_SEARCH_WORKFLOWS = "UPDATE_TABLE_SEARCH_WORKFLOWS",
    UPDATE_TABLE_SEARCH_TRANSACTION_CATEGORIES_INFLOW = "UPDATE_TABLE_SEARCH_TRANSACTION_CATEGORIES_INFLOW",
    UPDATE_TABLE_SEARCH_TRANSACTION_CATEGORIES_OUTFLOW = "UPDATE_TABLE_SEARCH_TRANSACTION_CATEGORIES_OUTFLOW",
    UPDATE_TABLE_SEARCH_ATTACH_EXPENSE = "UPDATE_TABLE_SEARCH_ATTACH_EXPENSE",
    UPDATE_TABLE_SEARCH_TRIP_FOLDER_DRAFT = "UPDATE_TABLE_SEARCH_TRIP_FOLDER_DRAFT",
    UPDATE_TABLE_SEARCH_EXPORT_HISTORY = "UPDATE_TABLE_SEARCH_EXPORT_HISTORY",
}

export enum UpdateTableFilterType {
    UPDATE_TABLE_FILTER = "UPDATE_TABLE_FILTER",
    UPDATE_TABLE_FILTER_DONE_REQUESTS = "UPDATE_TABLE_FILTER_DONE_REQUESTS",
    UPDATE_TABLE_FILTER_ALL_REQUESTS = "UPDATE_TABLE_FILTER_ALL_REQUESTS",
    UPDATE_TABLE_FILTER_IN_PROGRESS = "UPDATE_TABLE_FILTER_IN_PROGRESS",
    UPDATE_TABLE_FILTER_ARCHIVE = "UPDATE_TABLE_FILTER_ARCHIVE",
    UPDATE_TABLE_FILTER_TRANSACTIONS_ARCHIVE = "UPDATE_TABLE_FILTER_TRANSACTIONS_ARCHIVE",
    UPDATE_TABLE_FILTER_CARDS = "UPDATE_TABLE_FILTER_CARDS",
    UPDATE_TABLE_FILTER_CARD_TRANSACTIONS = "UPDATE_TABLE_FILTER_CARD_TRANSACTIONS",
    UPDATE_TABLE_FILTER_COST_CENTERS = "UPDATE_TABLE_FILTER_COST_CENTERS",
    UPDATE_TABLE_FILTER_EMPLOYEES = "UPDATE_TABLE_FILTER_EMPLOYEES",
    UPDATE_TABLE_FILTER_INBOX_INVOICE = "UPDATE_TABLE_FILTER_INBOX_INVOICE",
    UPDATE_TABLE_FILTER_INBOX_INVOICE_MODAL_EXPENSES = "UPDATE_TABLE_FILTER_INBOX_INVOICE_MODAL_EXPENSES",
    UPDATE_TABLE_FILTER_INBOX_INVOICE_MODAL_INVOICES = "UPDATE_TABLE_FILTER_INBOX_INVOICE_MODAL_INVOICES",
    UPDATE_TABLE_FILTER_PAY_AND_EXPORT_TO_BE_EXPORTED = "UPDATE_TABLE_FILTER_PAY_AND_EXPORT_TO_BE_EXPORTED",
    UPDATE_TABLE_FILTER_PAY_AND_EXPORT_TO_BE_PAID = "UPDATE_TABLE_FILTER_PAY_AND_EXPORT_TO_BE_PAID",
    UPDATE_TABLE_FILTER_POSSIBLE_MATCHES = "UPDATE_TABLE_FILTER_POSSIBLE_MATCHES",
    UPDATE_TABLE_FILTER_REVIEW_REQUESTS = "UPDATE_TABLE_FILTER_REVIEW_REQUESTS",
    UPDATE_TABLE_FILTER_SUBSCRIPTIONS = "UPDATE_TABLE_FILTER_SUBSCRIPTIONS",
    UPDATE_TABLE_FILTER_TODO_APPROVAL_PENDING = "UPDATE_TABLE_FILTER_TODO_APPROVAL_PENDING",
    UPDATE_TABLE_FILTER_TODO_DOCS_NEEDED = "UPDATE_TABLE_FILTER_TODO_DOCS_NEEDED",
    UPDATE_TABLE_FILTER_TODO_INVOICE_APPROVAL = "UPDATE_TABLE_FILTER_TODO_INVOICE_APPROVAL",
    UPDATE_TABLE_FILTER_TODO_PURCHASE_APPROVAL = "UPDATE_TABLE_FILTER_TODO_PURCHASE_APPROVAL",
    UPDATE_TABLE_FILTER_TODO_INVOICE_AND_PURCHASE = "UPDATE_TABLE_FILTER_TODO_INVOICE_AND_PURCHASE",
    UPDATE_TABLE_FILTER_TRANSACTIONS_INFLOW = "UPDATE_TABLE_FILTER_TRANSACTIONS_INFLOW",
    UPDATE_TABLE_FILTER_TRANSACTIONS_OUTFLOW = "UPDATE_TABLE_FILTER_TRANSACTIONS_OUTFLOW",
    UPDATE_TABLE_FILTER_TRANSACTIONS_ALL = "UPDATE_TABLE_FILTER_TRANSACTIONS_ALL",
    UPDATE_TABLE_FILTER_EXPENSE_TRANSACTIONS = "UPDATE_TABLE_FILTER_EXPENSE_TRANSACTIONS",
    UPDATE_TABLE_FILTER_VENDORS = "UPDATE_TABLE_FILTER_VENDORS",
    UPDATE_TABLE_FILTER_EMPLOYEE_VENDORS = "UPDATE_TABLE_FILTER_EMPLOYEE_VENDORS",
    UPDATE_TABLE_FILTER_WORKFLOWS = "UPDATE_TABLE_FILTER_WORKFLOWS",
    UPDATE_TABLE_FILTER_TRANSACTION_CATEGORIES_INFLOW = "UPDATE_TABLE_FILTER_TRANSACTION_CATEGORIES_INFLOW",
    UPDATE_TABLE_FILTER_TRANSACTION_CATEGORIES_OUTFLOW = "UPDATE_TABLE_FILTER_TRANSACTION_CATEGORIES_OUTFLOW",
    UPDATE_TABLE_FILTER_ATTACH_EXPENSE = "UPDATE_TABLE_FILTER_ATTACH_EXPENSE",
    UPDATE_TABLE_FILTER_TRIP_FOLDER_DRAFT = "UPDATE_TABLE_FILTER_TRIP_FOLDER_DRAFT",
    UPDATE_TABLE_FILTER_EXPORT_HISTORY = "UPDATE_TABLE_FILTER_EXPORT_HISTORY",
}
