import { MenuOutlined } from "@ant-design/icons"
import { CsvTemplateSelectedField } from "@finway-group/shared/lib/models"
import { Empty, Row, Table } from "antd"
import Input from "antd/lib/input/Input"
import { ColumnsType } from "antd/lib/table"
import React, { RefObject, useRef } from "react"
import { useTranslation } from "react-i18next"
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc"

import { SearchIcon, TrashIcon } from "Components/icons"

interface SelectedCsvHeadersTableInterface {
    data?: Array<CsvTemplateSelectedField>
    onChangeArray: (arg: Array<CsvTemplateSelectedField>) => void
    onDeleteItem: (arg: CsvTemplateSelectedField) => void
}

const SelectedCsvHeadersTable: React.FC<SelectedCsvHeadersTableInterface> = ({ data = [], onChangeArray, onDeleteItem }) => {
    const dragAndDropAnchor = useRef(null)

    const { t } = useTranslation()
    const onDragEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
        const newList = [...data]
        const [dragged] = newList.splice(oldIndex, 1)
        newList.splice(newIndex, 0, dragged)
        onChangeArray(newList)
    }

    const refMap: Array<RefObject<HTMLTableRowElement>> = []

    const search = (e: any) => {
        const term = e.target.value.toString().toLocaleLowerCase()
        const index = data.findIndex(({ label }) => label.toLocaleLowerCase().includes(term))
        const ref = refMap[index]
        ref?.current?.scrollIntoView()
    }

    const DragHandle = SortableHandle(() => <MenuOutlined style={{ cursor: "grab", color: "#999" }} />)
    const SortableItem = SortableElement((props: any) => {
        const rowRef = useRef<HTMLTableRowElement>(null)
        refMap.push(rowRef)
        return <tr ref={rowRef} {...props} />
    })
    const SortableWrapper = SortableContainer((props: any) => <tbody {...props} />)
    const DraggableWrapper = (props: any) => (
        <SortableWrapper helperContainer={dragAndDropAnchor.current} helperClass="table-row-dragging" useDragHandle onSortEnd={onDragEnd} {...props} />
    )

    const DraggableBodyRow = ({ className, ...restProps }: any) => {
        const index = restProps["data-row-key"]
        return <SortableItem index={index} style={{ border: "none" }} className={className} {...restProps} />
    }

    const columns: ColumnsType = [
        {
            title: "Order",
            dataIndex: "label",
            className: "header-names-cell",
            render: (label: string) => (
                <Row>
                    <div className="drag-visible-hover" style={{ fontSize: "10px", paddingRight: "10px", marginTop: "2px" }}>
                        <DragHandle />
                    </div>
                    <span>{label}</span>
                </Row>
            ),
        },
        {
            key: "action",
            title: "action",
            className: "action-visible-hover",
            align: "right",
            render: (selectedFieldToDelete: CsvTemplateSelectedField) => (
                <div
                    className="mb-4"
                    style={{ fontSize: "10px" }}
                    onClick={() => {
                        onDeleteItem(selectedFieldToDelete)
                    }}
                >
                    <TrashIcon />
                </div>
            ),
        },
    ]

    return (
        <>
            {data.length === 0 ? (
                <Empty></Empty>
            ) : (
                <div className="selectedHeadersTable ">
                    <div id="drag-and-drop-anchor" ref={dragAndDropAnchor} className="w-full antd-table" />
                    <Table<CsvTemplateSelectedField>
                        rowKey={(selected: CsvTemplateSelectedField) => data.findIndex(({ label }) => label === selected.label)}
                        title={() => (
                            <Input
                                bordered={true}
                                style={{ fontSize: "small", border: "none" }}
                                prefix={<SearchIcon />}
                                onChange={search}
                                disabled={data.length < 6}
                                allowClear
                                placeholder={t("placeholder:search")}
                                required
                            />
                        )}
                        showHeader={false}
                        columns={columns}
                        pagination={false}
                        dataSource={data}
                        size={"small"}
                        tableLayout="auto"
                        scroll={{ y: 200 }}
                        components={{
                            body: {
                                wrapper: DraggableWrapper,
                                row: DraggableBodyRow,
                            },
                        }}
                    ></Table>
                </div>
            )}
        </>
    )
}

export default SelectedCsvHeadersTable
