import { ArrowLeftOutlined } from "@ant-design/icons"
import { LiquidityPeriodEnum, RightEnum, TransactionCategoryTypeEnum } from "@finway-group/shared/lib/models"
import { MAX_INPUT_LENGTH } from "@finway-group/shared/lib/utils/validators"
import { Alert, Col, DatePicker, Input, Radio, Row, Select, Switch, Tabs } from "antd"
import Form, { FormInstance } from "antd/lib/form"
import { Store } from "antd/lib/form/interface"
import moment, { Moment } from "moment"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import CurrencyInput from "Components/currencyInput"
import FormItemCurrency from "Components/form/formItemCurrency"
import Loading from "Components/loading"
import { ForecastTransactionTypeEnum } from "Features/pages/liquidity/liquidity.types"
import { useForecastRules } from "Shared/hooks/liquidity.hooks"
import { useTransactionCategories } from "Shared/hooks/transactionCategory.hooks"
import { AuthzService, LiquidityService, UserService } from "Shared/services"
import { doFilterSelect } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"
import { getPopupAnchor } from "Shared/utils/popup.utils"

import LiquidityForecastRules from "../rules/liquidityForecastRules.rules"

const { TabPane } = Tabs
const { Option } = Select
const LiquidityForecastRule = React.lazy(() => import("Components/liquidityForecastRuleCard"))

enum ForecastRulesTypeEnum {
    ADD_PLANNED_TRANSACTIONS = "ADD_PLANNED_TRANSACTIONS",
    MANAGE_PLANNED_TRANSACTIONS = "MANAGE_PLANNED_TRANSACTIONS",
}

interface ForecastRulesFormInterface {
    formInstance: FormInstance
    onSubmit: (values: Store) => void
    onDeleteEditForecastRule: (id: string) => void
    editForecastRule: string
    setEditForecastRule: (id: string) => void
    setSaveButtonDisabled: (enabled: boolean) => void
}

const ForecastRulesForm = ({ formInstance, onSubmit, onDeleteEditForecastRule, editForecastRule, setEditForecastRule, setSaveButtonDisabled }: ForecastRulesFormInterface) => {
    const { t } = useTranslation()
    const [activeTab, setActiveTab] = useStateIfMounted<string>(ForecastRulesTypeEnum.ADD_PLANNED_TRANSACTIONS)
    const [transactionType, setTransactionType] = useStateIfMounted<ForecastTransactionTypeEnum>(ForecastTransactionTypeEnum.ONE_TIME)
    const forecastRules = useForecastRules()
    const loggedInUser = UserService.getLoggedInEmployeeProfile()
    const { globalCurrency } = loggedInUser.settings
    const transactionCategories = useTransactionCategories()
    const inflowCats = transactionCategories.filter((category) => !category.isGroup && category.type === TransactionCategoryTypeEnum.INFLOW)
    const outflowCats = transactionCategories.filter((category) => !category.isGroup && category.type === TransactionCategoryTypeEnum.OUTFLOW)
    const [leafNodeCategories, setLeafNodeCategories] = useStateIfMounted(inflowCats)
    const [amount, setAmount] = useStateIfMounted(0)
    const [loading, setLoading] = useState(false)

    const rules = LiquidityForecastRules(formInstance)

    useEffect(() => {
        if (editForecastRule) {
            setActiveTab(ForecastRulesTypeEnum.ADD_PLANNED_TRANSACTIONS)
        } else {
            setActiveTab(ForecastRulesTypeEnum.MANAGE_PLANNED_TRANSACTIONS)
        }
    }, [editForecastRule])

    const onEditForecastRule = async (id: string) => {
        try {
            setLoading(true)
            setSaveButtonDisabled(false)
            setEditForecastRule(id)
            const forecastRule = await LiquidityService.getForecastRule(id)
            setTransactionType(forecastRule.endDate ? ForecastTransactionTypeEnum.RECURRING : ForecastTransactionTypeEnum.ONE_TIME)
            setAmount(forecastRule.amount)
            setLeafNodeCategories(forecastRule.amount < 0 ? outflowCats : inflowCats)
            formInstance.setFieldsValue({
                ...forecastRule,
                startDate: moment(forecastRule.startDate),
                endDate: forecastRule.endDate ? moment(forecastRule.endDate) : undefined,
                kind: forecastRule.endDate ? ForecastTransactionTypeEnum.RECURRING : ForecastTransactionTypeEnum.ONE_TIME,
            })
        } finally {
            setLoading(false)
        }
    }

    const onUpdateAmount = (value: number) => {
        formInstance.setFieldsValue({ amount: value })
        // coordinate categories select based on amount
        if (value < 0 && amount >= 0) {
            setLeafNodeCategories(outflowCats)
            formInstance.setFieldsValue({ category: null })
        } else if (value >= 0 && amount < 0) {
            setLeafNodeCategories(inflowCats)
            formInstance.setFieldsValue({ category: null })
        }
        setAmount(value)
    }

    const onTabClick = (key: string, _event: React.KeyboardEvent | React.MouseEvent) => {
        if (key !== activeTab) {
            setActiveTab(key)
            if (key === ForecastRulesTypeEnum.ADD_PLANNED_TRANSACTIONS) {
                setSaveButtonDisabled(false)
            }
            if (key === ForecastRulesTypeEnum.MANAGE_PLANNED_TRANSACTIONS) {
                formInstance.resetFields()
                setSaveButtonDisabled(true)
            }
        }
    }

    const disabledPastMonths = (current: Moment) =>
        // Can not select month before the current
        current && current < moment().startOf("day")

    const commonTransactionFormItems = () => (
        <>
            <Row gutter={[16, 16]} align="middle">
                <Col span={14}>
                    <Form.Item name="title" label={t("input:liquidity.planned_transaction.title")} required rules={rules.title}>
                        <Input maxLength={MAX_INPUT_LENGTH} placeholder={t("placeholder:liquidity.transaction_title")} />
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Form.Item name="quantity" label={t("input:liquidity.planned_transaction.quantity")} initialValue={1} required rules={rules.quantity}>
                        <Input pattern="[0-9]*" inputMode="numeric" type="number" step="1" />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <FormItemCurrency currency={globalCurrency} name="amount" label={t("input:liquidity.planned_transaction.amount")} required rules={rules.amount}>
                        <CurrencyInput currency={globalCurrency} value={0} onChange={onUpdateAmount} colored />
                    </FormItemCurrency>
                </Col>
            </Row>
            <Row gutter={[16, 16]} align="middle">
                <Col span={12}>
                    <Form.Item name="category" label={t("input:liquidity.planned_transaction.category")} rules={rules.category}>
                        <Select showSearch filterOption={doFilterSelect} getPopupContainer={getPopupAnchor()} disabled={!!editForecastRule}>
                            {leafNodeCategories.map((category: any, index: number) => (
                                <Option key={index} value={category._id} label={category.name}>
                                    <div className="space-x-10">
                                        <span className="text-text">{category.name}</span>
                                    </div>
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="startDate" label={t("input:liquidity.planned_transaction.start_date")} initialValue={moment()} rules={rules.startDate}>
                        <DatePicker
                            disabledDate={disabledPastMonths}
                            getPopupContainer={getPopupAnchor()}
                            className="w-full"
                            picker="month"
                            format={"MMMM YYYY"}
                            disabled={!!editForecastRule}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </>
    )

    return (
        <>
            {editForecastRule && (
                <>
                    <ArrowLeftOutlined
                        onClick={() => {
                            setEditForecastRule("")
                            setSaveButtonDisabled(true)
                            formInstance.resetFields()
                        }}
                    />
                    <span className="relative text-base font-bold text-text-dark ml-10 truncate" style={{ top: "3px" }}>
                        {t("info:liquidity_management.edit_planned_cashflow")}
                    </span>
                </>
            )}
            <Tabs defaultActiveKey={ForecastRulesTypeEnum.ADD_PLANNED_TRANSACTIONS} activeKey={activeTab} onTabClick={onTabClick} className="pb-10">
                {AuthzService.isRightGrantedForLoggedInUser(RightEnum.LIQUIDITY__ALL__UPDATE) && (
                    <TabPane forceRender tab={`${t("label:liquidity.forecast_rule.tabs.cashflow_planning")}`} key={ForecastRulesTypeEnum.ADD_PLANNED_TRANSACTIONS}>
                        <Alert className="mb-20" message={t("info:liquidity_management.planned_transaction_amount")} type="info" closable showIcon />
                        {loading ? (
                            <Loading />
                        ) : (
                            <Form id="forecastRules-form" form={formInstance} layout="vertical" onFinish={onSubmit} autoComplete="off">
                                <Form.Item name="kind" initialValue={transactionType} className={editForecastRule ? "hidden" : "block"}>
                                    <div className="card-modal w-full">
                                        <Radio.Group value={transactionType} onChange={(e) => setTransactionType(e.target.value)}>
                                            <Radio.Button value={ForecastTransactionTypeEnum.ONE_TIME}>{t("input:liquidity.planned_transaction.onetime")}</Radio.Button>
                                            <Radio.Button value={ForecastTransactionTypeEnum.RECURRING}>{t("input:liquidity.planned_transaction.recurring")}</Radio.Button>
                                        </Radio.Group>
                                    </div>
                                </Form.Item>
                                {commonTransactionFormItems()}
                                {transactionType === ForecastTransactionTypeEnum.RECURRING && (
                                    <Row gutter={[16, 16]} align="middle">
                                        <Col span={12}>
                                            <Form.Item
                                                name="endDate"
                                                label={t("input:liquidity.planned_transaction.end_date")}
                                                initialValue={moment().add(1, "month")}
                                                rules={rules.endDate}
                                            >
                                                <DatePicker
                                                    disabledDate={disabledPastMonths}
                                                    getPopupContainer={getPopupAnchor()}
                                                    className="w-full"
                                                    picker="month"
                                                    format="MMMM YYYY"
                                                    disabled={!!editForecastRule}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item name="frequency" label={t("input:liquidity.planned_transaction.frequency")} initialValue={LiquidityPeriodEnum.MONTHLY}>
                                                <Select getPopupContainer={getPopupAnchor()} disabled={!!editForecastRule}>
                                                    <Option value={LiquidityPeriodEnum.MONTHLY}>{t("input:liquidity.planned_transaction.monthly")}</Option>
                                                    <Option value={LiquidityPeriodEnum.QUARTERLY}>{t("input:liquidity.planned_transaction.quarterly")}</Option>
                                                    <Option value={LiquidityPeriodEnum.YEARLY}>{t("input:liquidity.planned_transaction.yearly")}</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                )}

                                <Form.Item name="isGeneralized" label={t("input:liquidity.planned_transaction.apply_all")} valuePropName="checked" initialValue={true}>
                                    <Switch defaultChecked />
                                </Form.Item>
                            </Form>
                        )}
                    </TabPane>
                )}
                <TabPane
                    forceRender={true}
                    tab={`${t("label:liquidity.forecast_rule.tabs.planned_cashflow")}`}
                    key={ForecastRulesTypeEnum.MANAGE_PLANNED_TRANSACTIONS}
                    disabled={!!editForecastRule}
                >
                    <div className="w-full overflow-y-auto overflow-x-hidden h-400">
                        {forecastRules.length === 0 ? (
                            <div className="text-text-dark">{t("missing:no_forecast_rules")}</div>
                        ) : (
                            forecastRules.map((forecastRule, index) => (
                                <LiquidityForecastRule
                                    key={index}
                                    forecastRule={forecastRule}
                                    onEditForecastRule={onEditForecastRule}
                                    onDeleteEditForecastRule={onDeleteEditForecastRule}
                                />
                            ))
                        )}
                    </div>
                </TabPane>
            </Tabs>
        </>
    )
}

export default React.memo(ForecastRulesForm)
