import { ExclamationCircleOutlined } from "@ant-design/icons"
import { Vendor } from "@finway-group/shared/lib/models"
import { Button, Checkbox, Form, Modal, Tooltip } from "antd"
import { ButtonProps } from "antd/lib/button"
import { Store } from "antd/lib/form/interface"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useHistory, useParams } from "react-router-dom"

import VendorForm from "Components/forms/vendor.form"
import { useAuth } from "Shared/hooks/auth.hooks"
import { useFormTouchedHandler } from "Shared/hooks/form.hooks"
import { useVendor, useVendors } from "Shared/hooks/vendor.hooks"
import { NotificationService, VendorService } from "Shared/services"
import DialogService from "Shared/services/dialog.service"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { createVendor, fetchAllVendors, updateVendor } from "Shared/store/actions/vendor/vendorActions"
import { copyDeeply, getTooltipPopupContainer, removeStringWhiteSpaces, shouldRestoreItem, validateCreditorNumberDATEVCompatibility } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"
import { DragModalTypeEnum, dragElement } from "Shared/utils/plugins/draftjs/draggable"

const { confirm } = Modal

interface VendorFormModalProps {
    isShowing: boolean
    isNew: boolean
    isOpenedByExpenseModal?: boolean
    prefilledData?: any
    onCancel: (state: boolean, vendor?: Vendor) => void
    shouldRedirectOnRestoration?: boolean
    isDraggable?: boolean
}
const VendorFormModal = ({
    isShowing,
    isNew,
    prefilledData,
    isOpenedByExpenseModal = false,
    onCancel,
    shouldRedirectOnRestoration = true,
    isDraggable = false,
}: VendorFormModalProps) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const history = useHistory()
    const [formInstance] = Form.useForm()

    const [isLoading, setIsLoading] = useStateIfMounted<boolean>(false)
    const [isUploading, setIsUploading] = useStateIfMounted(false)
    const { userClass } = useAuth()

    const vendorsWithoutSoftDelete = useVendors(true)
    const { id } = useParams<{ id: string }>()
    const vendor = useVendor()
    const vendors = useVendors()
    const [shouldSyncExpenses, setShouldSyncExpenses] = useStateIfMounted(false)
    const { isFormTouched, handleTouchForm, handleResetTouchForm } = useFormTouchedHandler()

    useEffect(() => {
        if (!isNew && isShowing) {
            formInstance.setFieldsValue(vendor)
        } else if (prefilledData) {
            formInstance.setFieldsValue(prefilledData)
        }

        if (isShowing && isDraggable) {
            const modalElement = document.getElementsByClassName("draggableVendorModal")[0] as HTMLElement
            dragElement(modalElement, "dragVendorModalHeader", DragModalTypeEnum.small)
        }

        return () => {
            if (isShowing) formInstance.resetFields()
        }
    }, [isShowing])

    const onSubmit = async (values: Store, forceSubmit?: boolean) => {
        // extra variable to change it after confirmation dialog
        let shouldSynchronizeExpenses = shouldSyncExpenses

        if (!forceSubmit) {
            try {
                validateCreditorNumberDATEVCompatibility(values.creditorNumber)
            } catch (err) {
                confirm({
                    title: t("confirm:vendor.incompatible_creditor_number.title"),
                    content: t(`confirm:vendor.incompatible_creditor_number.message`, { reason: err.message }),
                    cancelText: t("confirm:vendor.incompatible_creditor_number.cancel"),
                    type: "warning",
                    okText: t("confirm:vendor.incompatible_creditor_number.confirm"),
                    onOk() {
                        return onSubmit(values, true)
                    },
                })
                return
            }
        }

        if (!shouldSynchronizeExpenses && !isNew && (await DialogService.confirmSync())) {
            shouldSynchronizeExpenses = true
        }

        try {
            const potentialDuplicateVendors = await VendorService.checkForDuplicateVendors(values)
            if (isNew && potentialDuplicateVendors.length > 0) {
                const { resolved, chosenVendor } = await DialogService.confirmCreationOfPotentialDuplicateVendor(
                    values as Vendor,
                    potentialDuplicateVendors,
                    isOpenedByExpenseModal,
                )
                if (!resolved) return
                if (chosenVendor) {
                    handleHide(chosenVendor)
                    return
                }
            }
        } catch (error) {}

        setIsLoading(true)

        // TODO: refactor like in employee modal
        const vendorData = new Vendor(
            !isNew
                ? {
                      ...vendor,
                      ...values,
                      img: vendor.img !== values.img ? (values.img.file ? values.img.file.xhr : values.img) : vendor.img,
                  }
                : {
                      ...values,
                      createdBy: userClass.id,
                      img: values.img && values.img?.file ? values.img.file.xhr : values.img,
                  },
        )

        if (vendorData.iban) vendorData.iban = removeStringWhiteSpaces(vendorData.iban)

        if (isNew) {
            try {
                const vendor = await createVendor(vendorData)(dispatch)
                NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:vendor.created.title"), t("notification:vendor.created.message"))
                handleHide(vendor)
            } catch (err) {
                setIsLoading(false)
                const conflictVendor = vendors.find((vendorFind) => vendorFind.name === vendorData.name || String(vendorFind.creditorNumber) === String(vendorData.creditorNumber))
                if (shouldRestoreItem(err, conflictVendor)) {
                    restoreVendor(copyDeeply(conflictVendor!, Vendor), vendorData)
                } else {
                    NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:vendor.create.title"))
                }
            }
        } else {
            try {
                const vendorId = id || (vendor && vendor.id)
                await dispatch(updateVendor(vendorId, vendorData))
                if (shouldSynchronizeExpenses) await VendorService.syncExpenses(vendorId)
                handleHide()
                NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:vendor.updated.title"), t("notification:vendor.updated.message"))
            } catch (err) {
                setIsLoading(false)
                NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:vendor.edit.title"))
            }
        }
    }

    const handleHide = (vendor?: Vendor) => {
        setIsLoading(false)
        formInstance.resetFields()
        onCancel(false, vendor)
    }

    const restoreVendor = (vendor: Vendor, conflictingData: any) => {
        const conflictingField = vendor.name === conflictingData.name ? "name" : "creditor_number"
        confirm({
            title: t("confirm:vendor.restore.title"),
            content: t(`confirm:vendor.restore.${conflictingField}_message`),
            cancelText: t("confirm:vendor.restore.cancel"),
            type: "warning",
            okText: t("confirm:vendor.restore.confirm"),
            onOk() {
                return handleRestore(vendor)
            },
            okButtonProps: { "data-testid": "vendorRestoreOkButton" } as ButtonProps,
        })
    }

    const handleRestore = async (vendorToRestore: Vendor) => {
        vendorToRestore.deleted = false
        try {
            const vendor = await updateVendor(vendorToRestore.id, vendorToRestore)(dispatch)
            NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:vendor.restored.title"), t("notification:vendor.restored.message"))
            handleHide(vendor)
            if (shouldRedirectOnRestoration) {
                await dispatch(fetchAllVendors())
                history.push(`/vendors/${vendorToRestore.id}`)
            }
        } catch (err) {
            setIsLoading(false)
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:cost_center.edit.title"))
        }
    }

    const resetLoadingAndForm = () => {
        formInstance.resetFields()
        setIsLoading(false)
        handleResetTouchForm()
        onCancel(false)
    }

    const handleHideOnCancel = async () => {
        if (isFormTouched.current) {
            const isConfirmed = await DialogService.confirmUnsavedChanges()
            if (!isConfirmed) return
        }

        resetLoadingAndForm()
    }

    return (
        <Modal
            destroyOnClose={true}
            afterClose={() => formInstance.resetFields()}
            visible={isShowing}
            maskClosable={false}
            title={<span id="dragVendorModalHeader">{isNew ? t("action:vendor.create") : t("action:vendor.edit")}</span>}
            onCancel={handleHideOnCancel}
            closable={true}
            keyboard={true}
            className={`ant-modal--small draggableVendorModal ${isDraggable ? "absolute" : ""}`}
            footer={
                <div className="flex justify-between items-center">
                    {!isNew && (
                        <div>
                            <Checkbox className="ml-8" onChange={(e) => setShouldSyncExpenses(e.target.checked)}>
                                {t("input:sync_updates")}
                            </Checkbox>
                            <Tooltip getPopupContainer={getTooltipPopupContainer} title={t("tooltips:synch_expenses:vendor")} placement="top" className="align-middle">
                                <ExclamationCircleOutlined />
                            </Tooltip>
                        </div>
                    )}
                    <div className="ml-auto mr-0">
                        <Button data-testid="createNewVendorCancelButton" key="back" onClick={handleHideOnCancel}>
                            {t("action:cancel")}
                        </Button>

                        <Button
                            data-testid="createNewVendorSubmitButton"
                            key="submit"
                            type="primary"
                            loading={isLoading}
                            disabled={isUploading}
                            onClick={() => {
                                formInstance.submit()
                            }}
                        >
                            {isNew ? t("action:vendor.submit") : t("action:vendor.save")}
                        </Button>
                    </div>
                </div>
            }
        >
            <VendorForm
                isNew={isNew}
                vendor={vendor}
                loggedInUser={userClass}
                formInstance={formInstance}
                onSubmit={onSubmit}
                setIsUploading={setIsUploading}
                onFormValuesChange={handleTouchForm}
            />
        </Modal>
    )
}

export default VendorFormModal
