import { ExpenseTagColorEnum, ExpenseTag as ExpenseTagModel } from "@finway-group/shared/lib/models"
import { Button, Input, List, Popover } from "antd"
import React, { useMemo, useState } from "react"
import { Trash2 as TrashIcon } from "react-feather"
import { useTranslation } from "react-i18next"

import ExpenseTag from "Components/expenseTag"
import Icon from "Components/icon"
import { useCompany } from "Shared/hooks/company.hooks"
import { useExpenseTags, useExpenseTagsInLoadingState } from "Shared/hooks/expenseTags.hooks"
import { AuthzService } from "Shared/services"
import { isEmptyString } from "Shared/utils/helper.utils"
import { getPopupAnchor } from "Shared/utils/popup.utils"

interface ExpenseTagManagerPopoverInterface {
    existingTags: Array<ExpenseTagModel>
    onTagSelected: (expenseTagId: string) => void
    onTagCreate: (tagColor: ExpenseTagColorEnum, tagName: string) => void
    onTagDelete: (expenseTagId: string, tagName: string) => void
}

enum ExpenseTagStepEnum {
    SELECT_TAG_NAME,
    SELECT_TAG_COLOR,
}

const ExpenseTagManagerPopover = ({ existingTags, onTagSelected, onTagCreate, onTagDelete }: ExpenseTagManagerPopoverInterface) => {
    const { t } = useTranslation()

    const [currentStep, setCurrentStep] = useState(ExpenseTagStepEnum.SELECT_TAG_NAME)
    const [tagName, setTagName] = useState("")
    const [isTagCreationDisabled, setIsTagCreationDisabled] = useState(false)
    const { expenseTagsLoading, addTagInLoading, removeTagFromLoading } = useExpenseTagsInLoadingState()

    const expenseTags = useExpenseTags()
    const { editingTagsEnabled } = useCompany()

    const hasTags = useMemo(() => existingTags.length > 0, [existingTags.length])

    const selectTag = async (expenseTagId: string) => {
        addTagInLoading(expenseTagId)
        await onTagSelected(expenseTagId)
        setTagName("")
        removeTagFromLoading(expenseTagId)
    }
    const createTag = async (tagColor: ExpenseTagColorEnum) => {
        setIsTagCreationDisabled(true)
        await onTagCreate(tagColor, tagName)
        setTagName("")
        setIsTagCreationDisabled(false)
        setCurrentStep(ExpenseTagStepEnum.SELECT_TAG_NAME)
    }

    let overlay

    switch (currentStep) {
        case ExpenseTagStepEnum.SELECT_TAG_NAME:
            const canEditTags = AuthzService.canManageTags(editingTagsEnabled)
            const hasSearchedTag = !isEmptyString(tagName.trim())
            const isTagAlreadyCreated = expenseTags.find((expenseTag) => expenseTag.tagName === tagName)

            const availableTags = expenseTags.filter((tag) => !existingTags.find((existingTag) => existingTag._id === tag._id))
            const filteredTags = hasSearchedTag ? availableTags.filter((tag) => tag.tagName.toLowerCase().startsWith(tagName.toLowerCase())) : availableTags

            let container
            if (filteredTags.length === 0 && !hasSearchedTag) {
                container = <span className="text-gray-400">{t("info:expense_tag.not_found")}</span>
            } else if (filteredTags.length === 0 && hasSearchedTag) {
                container = isTagAlreadyCreated ? (
                    <span className="text-gray-400">{t("info:expense_tag.added_already")}</span>
                ) : (
                    !canEditTags && <span className="text-gray-400">{t("info:expense_tag.not_found")}</span>
                )
            } else {
                container = (
                    <List className="h-64 max-h-200 overflow-y-auto">
                        {filteredTags.map((tag) => (
                            <List.Item key={tag._id} className="flex justify-between h-40 cursor-pointer border-none hover:bg-body custom-group" onClick={() => selectTag(tag._id)}>
                                <ExpenseTag className="h-30 cursor-pointer" color={tag.tagColor} isLoading={expenseTagsLoading.includes(tag._id)}>
                                    {tag.tagName}
                                </ExpenseTag>
                                {canEditTags && (
                                    <TrashIcon
                                        className="mr-18 hidden-child"
                                        color="#A1B1C1"
                                        size="15"
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            onTagDelete(tag._id, tag.tagName)
                                        }}
                                    />
                                )}
                            </List.Item>
                        ))}
                    </List>
                )
            }

            overlay = (
                <div className="flex flex-col">
                    <Input className="my-6" maxLength={30} value={tagName} onChange={(e) => setTagName(e.target.value)} />
                    {container}
                    {!isTagAlreadyCreated && hasSearchedTag && canEditTags && (
                        <Button className="mt-8 btn-default text-text" onClick={() => setCurrentStep(ExpenseTagStepEnum.SELECT_TAG_COLOR)}>
                            {t("action:expense_tag.create")}
                            <span className="text-primary">&nbsp;&ldquo;{tagName}&ldquo;</span>
                        </Button>
                    )}
                </div>
            )
            break
        case ExpenseTagStepEnum.SELECT_TAG_COLOR:
            overlay = (
                <div className="flex flex-row justify-between">
                    {Object.keys(ExpenseTagColorEnum)
                        .filter((key) => !Number.isNaN(Number(key)))
                        .map((color) => (
                            <ExpenseTag
                                className="rounded-full h-30 w-30 cursor-pointer"
                                key={color}
                                onClick={() => !isTagCreationDisabled && createTag(Number(color))}
                                color={Number(color)}
                            />
                        ))}
                </div>
            )
            break
        default:
            overlay = <></>
    }

    return (
        <Popover
            className="flex items-center zindex-tooltip"
            getPopupContainer={getPopupAnchor(undefined, true)}
            title={<span className="text-primary text-base block my-4">{t("label:tags")}</span>}
            content={overlay}
            overlayStyle={{ minWidth: "215px", zIndex: 50 }}
            trigger="click"
            placement="bottomLeft"
            onVisibleChange={() => (currentStep > 1 ? setCurrentStep(ExpenseTagStepEnum.SELECT_TAG_NAME) : null)}
        >
            <Button
                className={`m-5 rounded-full bg-transparent border-solid font-normal text-gray-800  border-gray-800 border-1 flex items-center justify-center ${
                    !hasTags ? "gap-8" : "w-35"
                } h-35`}
                icon={<Icon icon="add_expense_tag" />}
            >
                {!hasTags && <span>{t("action:expense_tag.add")}</span>}
            </Button>
        </Popover>
    )
}

export default ExpenseTagManagerPopover
