import { RightEnum, Team } from "@finway-group/shared/lib/models"
import moment from "moment"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { PeriodRangeOptionEnum } from "Features/pages/costCenters/costCenterDetails/costCenterPeriodOption.interface"
import { AnalyticsService, AuthzService } from "Shared/services"
import { fetchAllTeamsWithUsersCount } from "Shared/store/actions/analytics/analyticsActions"
import { TablesEnum } from "Shared/store/reducers/tableConfigReducer"
import { Table } from "Shared/store/reducers/tableReducer"
import { RootState } from "Shared/store/rootState.interface"
import { sumBy } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"
import { paginateItems } from "Shared/utils/paginator.utils"

import { useTeams } from "./team.hooks"
import { useLoggedInEmployeeProfile } from "./user.hooks"

export const useOverviewData = () => useSelector(({ analytics }: RootState) => analytics.overviewData)

export const useCardOverviewData = () => useSelector(({ analytics: { cards } }: RootState) => cards.overviewData)

export const useCostCenterBudgetData = () => useSelector(({ analytics }: RootState) => analytics.costCenterBudgetData)

export const useCostCenterOverLast12MonthsData = () => useSelector(({ analytics }: RootState) => analytics.expensesPerCostCenterOverLast12MonthsData)

export const useCostCenterOverLast12MonthsFilter = () => useSelector(({ analytics }: RootState) => analytics.expensesPerCostCenterOverLast12MonthsFilter)

export const useEmployeeData = () => {
    const employeeData = useSelector(({ analytics }: RootState) => analytics.expensesPerEmployee).filter((dataSet: any) => dataSet.value > 0)
    return {
        employeeData: employeeData || [],
        maxEmployeeValue: employeeData ? Math.max(...employeeData.map((dataSet: any) => dataSet.value)) : 0,
        totalSumEmployees: employeeData ? sumBy(employeeData, "value") : 0,
    }
}

export const useCostCenterExpenseData = () => useSelector(({ analytics }: RootState) => analytics.costCenterExpenseData)

export const useCostCenterExpenseDataPie = () => useSelector(({ analytics }: RootState) => analytics.costCenterExpenseDataPie)

export const useExpensesPerDepartmentDataPie = () => useSelector(({ analytics }: RootState) => analytics.expensesPerDepartmentDataPie)

export const useExpensesPerDepartmentIntervalPie = () => useSelector(({ analytics }: RootState) => analytics.expensesPerDepartmentIntervalPie)

export const useEmployeeInterval = () => useSelector(({ analytics }: RootState) => analytics.employeeInterval)

export const useCostCenterInterval = () => useSelector(({ analytics }: RootState) => analytics.costCenterInterval)

export const useCostCenterIntervalPie = () => useSelector(({ analytics }: RootState) => analytics.costCenterIntervalPie)

export const useCostCenterBudgetUsageBarGraphOptions = (isOnMobile = false) => {
    const graphOptions = useSelector(({ analytics }: RootState) => analytics.costCenterBudgetUsageBarGraphOptions)
    const DEFAULT_MOBILE_PERIODS = 6
    const DEFAULT_DESKTOP_PERIODS = 12
    const defaultPeriods = isOnMobile ? DEFAULT_MOBILE_PERIODS : DEFAULT_DESKTOP_PERIODS
    graphOptions.periodRangeOption = graphOptions.startDate
        ? graphOptions.periodRangeOption
        : isOnMobile
        ? PeriodRangeOptionEnum.LAST_6_PERIODS
        : PeriodRangeOptionEnum.LAST_12_PERIODS
    graphOptions.startDate = graphOptions.startDate || moment().startOf("month").subtract(defaultPeriods, "months").toISOString()
    graphOptions.endDate = graphOptions.endDate || moment().endOf("month").toISOString()
    return graphOptions
}

export const useGroupedExpenseData = (
    table: TablesEnum,
    { data: { docs, limit, page, search, filter } }: Table<any>,
    groupedByField: "vendor" | "creditorUser" | "requestedBy",
) => {
    const [expenseData, setExpenseData] = useStateIfMounted<Array<any>>([])
    const [creditorIds, setCreditorIds] = useStateIfMounted<Array<string>>([])
    const loggedInEmployee = useLoggedInEmployeeProfile()
    const abortController = new AbortController()

    const isNotAuthorizedToReadVendorsAndExpenses =
        !AuthzService.isRightGrantedForLoggedInUser(RightEnum.VENDOR__ALL__READ) && !AuthzService.isRightGrantedForLoggedInUser(RightEnum.EXPENSE__ALL__READ)

    useEffect(() => {
        if (table === TablesEnum.VENDORS && isNotAuthorizedToReadVendorsAndExpenses) return
        if (table === TablesEnum.EMPLOYEES && !AuthzService.isRightGrantedForLoggedInUser(RightEnum.EMPLOYEE__ALL__READ)) return

        // before we populate the data, let's clear it
        setExpenseData([])

        const docIds = creditorIds.length !== 0 ? creditorIds : paginateItems(docs, limit, page).map((doc: any) => doc.id)
        if (!docIds.length) return

        AnalyticsService.fetchExpenseDataForGroup({
            groupingField: `${groupedByField}._id`,
            ids: docIds,
            destCurrency: loggedInEmployee.settings.globalCurrency,
            abortController,
        })
            .then((response) => {
                setExpenseData(response)
            })
            // if expenseData fails to fetch, we catch the error to still show the vendors without expense data.
            .catch(() => {})

        return () => {
            abortController.abort()
        }
    }, [docs.length, page, limit, search, JSON.stringify(filter), JSON.stringify(creditorIds), loggedInEmployee.activeCompanyProfile.roleId])

    return {
        expenseData,
        setCreditorIds,
    }
}

export const useTeamsWithUsersCount: (excludeDeleted: boolean) => Array<Team & { usersCount: number }> = (excludeDeleted: boolean = false) => {
    const teams = useTeams(excludeDeleted)
    const dispatch = useDispatch()
    const teamsWithUsersCount = useSelector(({ analytics }: RootState) => analytics.teams ?? [])

    useEffect(() => {
        dispatch(fetchAllTeamsWithUsersCount())
    }, [JSON.stringify(teams)])

    return teams.map((team) => ({
        ...team,
        usersCount: teamsWithUsersCount.find((foundTeam) => foundTeam._id === team._id)?.usersCount || 0,
    }))
}
