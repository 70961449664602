import Icon from "@ant-design/icons"
import { BudgetPeriods } from "@finway-group/shared/lib/consts"
import { BudgetPeriodEnum, CostCenter, PriceIntervalEnum, RightEnum } from "@finway-group/shared/lib/models"
import { Button, Card } from "antd"
import * as HttpStatus from "http-status"
import moment from "moment"
import React, { memo, useEffect } from "react"
import { useTranslation } from "react-i18next"

import BudgetUsageProgressBar from "Components/budgetUsageProgressBar"
import Loading from "Components/loading"
import CostCenterPeriodDropDown from "Features/pages/costCenters/costCenterDetails/costCenterPeriodDropDown"
import { AnalyticsService, AuthzService, NotificationService } from "Shared/services"
import { getBudgetPeriodString } from "Shared/utils/costCenter.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"
import useUpdateEffect from "Shared/utils/hooks/useUpdateEffect"

interface CostCenterBudgetUsageProgressBarInterface {
    costCenter: CostCenter
    onAddBudgetClick: () => void
}
const CostCenterBudgetUsageProgressBar: React.FC<CostCenterBudgetUsageProgressBarInterface> = ({ costCenter, onAddBudgetClick }) => {
    const { t } = useTranslation()

    const [startDate, setStartDate] = useStateIfMounted(moment().startOf(getBudgetPeriodString(costCenter.budgetPeriod)))
    const [endDate, setEndDate] = useStateIfMounted(moment().endOf(getBudgetPeriodString(costCenter.budgetPeriod)))
    const [isLoading, setIsLoading] = useStateIfMounted(false)
    const [budgetUsage, setBudgetUsage] = useStateIfMounted({ consumption: 0, forecast: 0, capacity: 0 })
    useEffect(() => {
        let isFetching = true
        const fetchBudgetUsageData = async () => {
            setIsLoading(true)
            try {
                if (!isFetching) return
                const [budgetUsageData] = await AnalyticsService.fetchCostCenterBudgetUsageData(costCenter._id, startDate.toISOString(), endDate.toISOString())
                setBudgetUsage(budgetUsageData)
            } catch (err) {
                if (err.response?.status !== HttpStatus.FORBIDDEN)
                    NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:fetch.title"), t("error:fetch.message"))
            }
            setIsLoading(false)
        }
        fetchBudgetUsageData()
        return () => {
            isFetching = false
        }
    }, [costCenter._id, costCenter.budgetValue, JSON.stringify(costCenter.alternativeBudgets), startDate, endDate])

    useUpdateEffect(() => {
        setStartDate(moment().startOf(getBudgetPeriodString(costCenter.budgetPeriod)))
        setEndDate(moment().endOf(getBudgetPeriodString(costCenter.budgetPeriod)))
    }, [costCenter.budgetPeriod])

    const handleDropDownSelect = (selectedOption: any) => {
        const periodString = costCenter.budgetPeriod === BudgetPeriodEnum.MONTHLY ? "month" : costCenter.budgetPeriod === BudgetPeriodEnum.QUARTERLY ? "quarter" : "year"
        const [year, monthOrQuarter] = selectedOption.split("-")
        const date = moment()
        switch (costCenter.budgetPeriod) {
            case BudgetPeriodEnum.MONTHLY:
                date.set({ month: monthOrQuarter - 1, year })
                break
            case BudgetPeriodEnum.QUARTERLY:
                date.set({ quarter: monthOrQuarter, year })
                break
            case BudgetPeriodEnum.YEARLY:
                date.set({ year })
                break
            default:
                break
        }
        setStartDate(date.startOf(periodString))
        setEndDate(date.clone().endOf(periodString))
    }

    if (budgetUsage.capacity === 0 && !AuthzService.isRightGrantedForLoggedInUser(RightEnum.CC1__ALL__READ)) {
        return <></>
    }

    return (
        <div className="p-0">
            <div className="flex justify-between">
                <h2 className="py-8 mb-8">{t(`label:budget_period_type.${BudgetPeriods[costCenter.budgetPeriod].toLowerCase()}`)}</h2>
                <CostCenterPeriodDropDown key={costCenter.budgetPeriod} date={startDate} budgetPeriod={costCenter.budgetPeriod} onSelect={handleDropDownSelect} />
            </div>

            <Card bodyStyle={{ width: "100%" }} className="h-100 flex items-center justify-center">
                {isLoading ? (
                    <Loading className="h-60" margin={false} />
                ) : budgetUsage.capacity > 0 ? (
                    <BudgetUsageProgressBar isLoading={isLoading} budgetUsage={budgetUsage} interval={PriceIntervalEnum.ONE_TIME} costCenter={costCenter}></BudgetUsageProgressBar>
                ) : (
                    <div className="flex justify-center animation-appear">
                        <Button className="btn-highlight-blue" onClick={() => onAddBudgetClick()}>
                            <Icon component={() => <img src="./icons/stats.svg" alt="white bars on blue background" />} />
                            {t("action:cost_center.add_budget")}
                        </Button>
                    </div>
                )}
            </Card>
        </div>
    )
}
export default memo(CostCenterBudgetUsageProgressBar)
