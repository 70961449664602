import { Role } from "@finway-group/shared/lib/models"
import { ActionCreator, Dispatch } from "redux"

import RoleService from "Shared/services/role.service"
import { ThunkResult } from "Shared/store"

import { CancelFetchAction, FetcherActionTypes } from "../fetcher/fetcherTypes"
import { CreateRoleAction, DeleteRoleAction, FetchAllRolesAction, RoleTypes, SelectRoleAction, UpdateRoleAction } from "./roleTypes"

export const fetchAllRoles: ActionCreator<ThunkResult<Array<Role>>> = () => async (dispatch: Dispatch) => {
    const roles = await RoleService.fetchRoles()
    const cancelFetchAction: CancelFetchAction = {
        type: FetcherActionTypes.CANCEL_FETCH,
        isFetchCancelled: true,
    }

    dispatch(cancelFetchAction)

    const fetchAllRoles: FetchAllRolesAction = {
        type: RoleTypes.FETCH_ALL_ROLES,
        roles,
    }

    dispatch(fetchAllRoles)
    return roles
}

export const updateRole = (id: string, updateData: any) => async (dispatch: Dispatch<any>) => {
    const role = await RoleService.updateOne(id, updateData)

    const updateRoleAction: UpdateRoleAction = {
        type: RoleTypes.UPDATE_ROLE,
        role,
    }

    dispatch(updateRoleAction)

    return role
}

export const selectRole = (role: Role) => async (dispatch: Dispatch) => {
    const selectAction: SelectRoleAction = {
        type: RoleTypes.SELECT_ROLE,
        role,
    }

    dispatch(selectAction)
    return role
}

export const createRole = (role: Role) => async (dispatch: Dispatch) => {
    const newRole = await RoleService.createOne(role)
    const createRoleAction: CreateRoleAction = {
        type: RoleTypes.CREATE_ROLE,
        role: newRole,
    }

    dispatch(createRoleAction)
    return newRole
}

export const deleteRole = (roleId: string) => async (dispatch: Dispatch) => {
    await RoleService.deleteOne(roleId)
    const deleteAction: DeleteRoleAction = {
        type: RoleTypes.DELETE_ROLE,
        roleId,
    }

    dispatch(deleteAction)
    return roleId
}
