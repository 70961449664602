import { combineReducers } from "redux"

import { inboxInvoiceReducer } from "Shared/store/reducers/inboxInvoiceReducer"

import { AuthActionTypes } from "../actions/auth/authTypes"
import { RootState } from "../rootState.interface"
import { analyticsReducer } from "./analyticsReducer"
import { authReducer } from "./authReducer"
import { bankReducer } from "./bankReducer"
import { companyReducer } from "./companyReducer"
import { corporateReducer } from "./corporateReducer"
import { costCenter2Reducer } from "./costCenter2Reducer"
import { costCenterReducer } from "./costCenterReducer"
import { creditCardReducer } from "./creditCardReducer"
import { creditorReducer } from "./creditorReducer"
import { csvTemplateReducer } from "./csvTemplateReducer"
import { currencyReducer } from "./currencyReducer"
import { employeeReducer } from "./employeeReducer"
import { expenseAccountReducer } from "./expenseAccountReducer"
import { expenseReducer } from "./expenseReducer"
import { expenseTagReducer } from "./expenseTagReducer"
import { featureFlagsReducer } from "./featureFlagsReducer"
import { fetcherReducer } from "./fetcherReducer"
import { importReducer } from "./importReducer"
import { liquidityReducer } from "./liquidityReducer"
import { paymentReducer } from "./paymentReducer"
import { perDiemDestinationReducer } from "./perDiemDestinationReducer"
import { roleReducer } from "./roleReducer"
import { tableConfigReducer } from "./tableConfigReducer"
import { tableReducer } from "./tableReducer"
import { taxReducer } from "./taxReducer"
import { teamReducer } from "./teamReducer"
import { transactionCategoryReducer } from "./transactionCategoryReducer"
import { transactionReducer } from "./transactionReducer"
import { vendorReducer } from "./vendorReducer"
import { workflowReducer } from "./workflowReducer"

const appReducer = combineReducers<RootState>({
    auth: authReducer,
    employees: employeeReducer,
    vendors: vendorReducer,
    creditors: creditorReducer,
    expenses: expenseReducer,
    inboxInvoices: inboxInvoiceReducer,
    costCenters: costCenterReducer,
    costCenters2: costCenter2Reducer,
    fetcher: fetcherReducer,
    payment: paymentReducer,
    company: companyReducer,
    currency: currencyReducer,
    banks: bankReducer,
    expenseAccounts: expenseAccountReducer,
    analytics: analyticsReducer,
    transactions: transactionReducer,
    transactionCategories: transactionCategoryReducer,
    creditCards: creditCardReducer,
    corporate: corporateReducer,
    tableConfig: tableConfigReducer,
    taxes: taxReducer,
    teams: teamReducer,
    tables: tableReducer,
    workflows: workflowReducer,
    liquidity: liquidityReducer,
    featureFlags: featureFlagsReducer,
    imports: importReducer,
    roles: roleReducer,
    expenseTags: expenseTagReducer,
    perDiemDestinations: perDiemDestinationReducer,
    csvTemplates: csvTemplateReducer,
})

const rootReducer = (state: any, action: any) => {
    if (action.type === AuthActionTypes.RESET_REDUX_STORE) {
        // Undefined results in the state being reset to the initial state
        return appReducer(undefined, action)
    }
    return appReducer(state, action)
}

export default rootReducer
