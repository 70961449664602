import { Expense, ExpenseKindEnum, Hospitality, Mileage, PerDiem, TripFolder } from "@finway-group/shared/lib/models"
import { Card } from "antd"
import React from "react"

import { isFolderExpense } from "Shared/utils/expense.utils"

import FolderMainDetailsComponent from "./folderDetails/folderMainDetailsComponent"
import { FolderSubExpenseList } from "./folderDetails/folderSubExpenseList"
import MainDetailsSectionWrapper from "./mainDetailsSection.wrapper"
import { HospitalityMainDetailsSection } from "./reimbursementComponents/hospitalityMainDetailsSection.component"
import { MileageMainDetailsSection } from "./reimbursementComponents/mileageMainDetailsSection.component"
import { PerDiemMainDetailsSection } from "./reimbursementComponents/perDiemMainDetailsSection.component"

interface ExpenseMainDetailsSectionContainerInterface {
    expense: Expense | Mileage | PerDiem | Hospitality
    subExpenses?: Array<Expense>
    isInnerComponent?: boolean
    setIsExpenseUpdating: React.Dispatch<React.SetStateAction<boolean>>
    setIsEditModalShowing: React.Dispatch<React.SetStateAction<boolean>>
    setActiveExpenseId: (expenseId: string) => void
}

const ExpenseMainDetailsSectionContainer: React.FC<ExpenseMainDetailsSectionContainerInterface> = ({
    expense,
    subExpenses = [],
    isInnerComponent = false,
    setIsEditModalShowing,
    setIsExpenseUpdating,
    setActiveExpenseId,
}) => {
    const renderMainDetailsSection = () => {
        switch (expense.kind) {
            case ExpenseKindEnum.MILEAGE:
                return <MileageMainDetailsSection expense={expense as Mileage} showHeader={!isInnerComponent} />
            case ExpenseKindEnum.PER_DIEM:
                return <PerDiemMainDetailsSection expense={expense as PerDiem} showHeader={!isInnerComponent} />
            case ExpenseKindEnum.HOSPITALITY:
                return <HospitalityMainDetailsSection expense={expense as Hospitality} showHeader={!isInnerComponent} />
            case ExpenseKindEnum.TRIP_FOLDER:
                if (isInnerComponent) return <FolderMainDetailsComponent folder={expense as TripFolder} />
                return (
                    <FolderSubExpenseList
                        folder={expense as TripFolder}
                        subExpenses={subExpenses}
                        setIsEditModalShowing={setIsEditModalShowing}
                        setIsExpenseUpdating={setIsExpenseUpdating}
                        setActiveExpenseId={setActiveExpenseId}
                    />
                )
            default:
                return <MainDetailsSectionWrapper expense={expense} openEditModal={() => setIsEditModalShowing(true)} setIsExpenseUpdating={setIsExpenseUpdating} />
        }
    }

    // If it's a sub-expense or a folder, do not wrap with card component
    if (isInnerComponent || isFolderExpense(expense)) {
        return renderMainDetailsSection()
    }

    return <Card>{renderMainDetailsSection()}</Card>
}

export default ExpenseMainDetailsSectionContainer
