import React from "react"

import Icon, { IconInterface } from "Components/icon"

const accommodationSvg = (
    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M29.3385 24.6667H2.67188C1.56731 24.6667 0.671875 23.7712 0.671875 22.6667V20C0.671875 18.8954 1.56731 18 2.67188 18H29.3385C30.4431 18 31.3385 18.8954 31.3385 20V22.6667C31.3385 23.7712 30.4431 24.6667 29.3385 24.6667Z"
            stroke="#606C7E"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M2 18.1112V6.66406C2 5.55949 2.89543 4.66406 4 4.66406H28C29.1046 4.66406 30 5.55949 30 6.66406V18.1115"
            stroke="#606C7E"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path d="M3.34375 24.6641V27.3307H4.67708L6.01042 24.6641" stroke="#606C7E" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M28.6667 24.6641V27.3307H27.3333L26 24.6641" stroke="#606C7E" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path
            d="M4.67188 16.6615L4.67188 16.6615C5.26948 15.8077 6.08 15.3281 6.92514 15.3281H11.7519C12.5971 15.3281 13.4076 15.8077 14.0052 16.6615L14.0052 16.6615L14.0052 16.6615C13.4076 17.5152 12.5971 17.9948 11.7519 17.9948H6.92514C6.08 17.9948 5.26948 17.5152 4.67188 16.6615L4.67188 16.6615Z"
            stroke="#606C7E"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M18 16.6615L18 16.6615C18.5976 15.8077 19.4081 15.3281 20.2533 15.3281H25.0801C25.9252 15.3281 26.7357 15.8077 27.3333 16.6615V16.6615V16.6615C26.7357 17.5152 25.9252 17.9948 25.0801 17.9948H20.2533C19.4081 17.9948 18.5976 17.5152 18 16.6615L18 16.6615Z"
            stroke="#606C7E"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)

const AccommodationIcon = (props: IconInterface) => <Icon component={accommodationSvg} {...props} />

export default AccommodationIcon
