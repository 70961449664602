import { Col, Form, Row } from "antd"
import moment from "moment"
import React, { useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"

import FormMinMaxDate from "Components/form/formMinMaxDate"
import FormSelectTags from "Components/form/formSelectTags"
import { ReportingFilterFormInterface } from "Features/pages/reporting/reportingFilter.interface"
import { useSubCostCenters } from "Shared/hooks/costCenter.hooks"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

import { generateRules } from "./rules/costCenterFilter.rules"

const toggleSignal = "all"

const ReportingFilterForm: React.FC<ReportingFilterFormInterface> = ({ formInstance, filter, onSubmit }) => {
    const { t } = useTranslation()
    const costCenters = useSubCostCenters(true, true)

    // Keep track of the state of the values inside formInstance
    const [reportFilter, setReportFilter] = useStateIfMounted(filter)

    const rules = useMemo(() => generateRules(formInstance), [reportFilter])

    const deselectAllCostCenterFilterOption = () => formInstance.setFieldsValue({ costCenters: [] })
    const selectAllCostCenterFilterOption = () => formInstance.setFieldsValue({ costCenters: costCenters.map((costCenter) => costCenter._id) })

    useEffect(() => {
        formInstance.validateFields()
    }, [reportFilter])

    useEffect(() => {
        formInstance.resetFields()
        setReportFilter(filter)
    }, [])

    const handleCostCenterFilterChange = (costCenterIds: Array<string>) => {
        let currentSelectedFilters = costCenterIds.filter((costCenterId: string) => costCenterId !== toggleSignal)

        if (costCenterIds.includes(toggleSignal)) {
            if (currentSelectedFilters.length > 0) {
                deselectAllCostCenterFilterOption()
                currentSelectedFilters = []
            } else {
                selectAllCostCenterFilterOption()
                currentSelectedFilters = costCenters.map((cc) => cc._id)
            }
        }

        setReportFilter({ ...reportFilter, costCenters: currentSelectedFilters })
    }

    return (
        <div>
            <Form id="reportingFilterForm" form={formInstance} onFinish={onSubmit} className="form--filter">
                <div>
                    <Row gutter={[16, 16]}>
                        <Col>{t("input:reporting_filter.date_range")}</Col>
                    </Row>

                    <FormMinMaxDate
                        disableDateOption={true}
                        dateFormat="YYYY-MM"
                        header={t("input:reporting_filter.date_range")}
                        min={{
                            name: "minDate",
                            label: t("input:reporting_filter.min_date"),
                            placeholder: t("input:reporting_filter.starting_date"),
                            initialValue: reportFilter.minDate,
                            rules: rules.minDate,
                            onChange: (date) => {
                                setReportFilter({ ...reportFilter, minDate: moment(date) })
                            },
                        }}
                        max={{
                            name: "maxDate",
                            label: t("input:reporting_filter.max_date"),
                            placeholder: t("input:reporting_filter.ending_date"),
                            initialValue: reportFilter.maxDate,
                            rules: rules.maxDate,
                            onChange: (date) => {
                                setReportFilter({ ...reportFilter, maxDate: moment(date) })
                            },
                        }}
                        picker="month"
                    />

                    <FormSelectTags
                        header={t("input:reporting_filter.cost_center")}
                        placeholder={t("input:filter_form.not_set")}
                        initialValue={reportFilter.costCenters}
                        onChange={handleCostCenterFilterChange}
                        name="costCenters"
                        selectAndDeselectAll={true}
                    />
                </div>
            </Form>
        </div>
    )
}

export default ReportingFilterForm
