import { CurrencyExchange } from "@finway-group/shared/lib/models"
import { ActionCreator, Dispatch } from "redux"

import CurrencyService from "Shared/services/currency.service"
import { ThunkResult } from "Shared/store"

import { CancelFetchAction, FetcherActionTypes } from "../fetcher/fetcherTypes"
import { CurrencyActionTypes, FetchCurrencyExchangeAction } from "./currencyTypes"

export const fetchCurrencyExchange: ActionCreator<ThunkResult<CurrencyExchange>> = () => async (dispatch: Dispatch) => {
    const currencyExchange = await CurrencyService.fetchCurrencyExchange()

    const cancelFetchAction: CancelFetchAction = {
        type: FetcherActionTypes.CANCEL_FETCH,
        isFetchCancelled: true,
    }
    dispatch(cancelFetchAction)

    const fetchCurrencyExchangeAction: FetchCurrencyExchangeAction = {
        type: CurrencyActionTypes.FETCH_CURRENCY_EXCHANGE,
        currencyExchange,
    }
    dispatch(fetchCurrencyExchangeAction)

    return currencyExchange
}
