import { Employee, IntegrationTypeEnum, SystemRightEnum } from "@finway-group/shared/lib/models"
import { MAX_INPUT_LENGTH, MIN_INPUT_LENGTH } from "@finway-group/shared/lib/utils/validators"
import { Checkbox, Col, Form, Input, Row, Switch, Tooltip } from "antd"
import { FormInstance } from "antd/lib/form/Form"
import { Store } from "antd/lib/form/interface"
import React, { Dispatch, SetStateAction, useCallback, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import "react-phone-number-input/style.css"

import { ConditionalWrapper } from "Components/conditionalWrapper"
import { useCompanies } from "Shared/hooks/company.hooks"
import { useIsFreeVersion } from "Shared/hooks/featureFlags.hooks"
import { useIntegrationConfigurations } from "Shared/queries/integration.queries"
import { AuthzService, EmployeeService } from "Shared/services"
import { getAvailableCompanies } from "Shared/utils/company.utils"
import { isSysAdminRightsRevocationDenied } from "Shared/utils/employee.utils"
import { getTooltipPopupContainer } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"
import validateEmail from "Shared/validators/email.validator"

import { UserCommonRules } from "../rules/user.common.rules"
import { UserRules } from "../rules/user.rule"
import UserCompanyProfileForm from "./userCompanyProfile.form"

interface UserFormInterface {
    form: FormInstance
    isNew: boolean
    onSubmit: (values: Store) => any
    onFormValuesChange: () => void
    setShowAddUserToCompanyFields: Dispatch<SetStateAction<boolean>>
    showAddUserToCompanyFields: boolean
    sysAdminsCount?: number
    employee?: Employee
}

export const UserForm: React.FC<UserFormInterface> = ({
    form,
    isNew,
    employee,
    sysAdminsCount,
    onSubmit,
    onFormValuesChange,
    showAddUserToCompanyFields,
    setShowAddUserToCompanyFields,
}) => {
    const { t } = useTranslation()

    const [email, setEmail] = useState(employee?.email ?? "")
    const [emailExist, setEmailExist] = useStateIfMounted(false)

    // feature flags
    const isFreeVersion = useIsFreeVersion()

    const canManageEmployees = AuthzService.isSystemRightGrantedForLoggedInUser(isNew ? SystemRightEnum.SYS__USER__ALL__CREATE : SystemRightEnum.SYS__USER__ALL__UPDATE)

    const isSysAdminSwitchDisabled = employee && sysAdminsCount ? isSysAdminRightsRevocationDenied({ sysAdminsCount, user: employee }) : false

    const integrations = useIntegrationConfigurations()
    const personioIntegration = integrations.data?.[IntegrationTypeEnum.PERSONIO]
    const isPersonioUser = Boolean(employee?.source?.id && employee?.source?.system === IntegrationTypeEnum.PERSONIO)
    const isPersonioIntegrationEnabled = personioIntegration?.isEnabled ?? false
    const isPersonioEmployeeSyncEnabled = personioIntegration?.attributes?.enableEmployeeSync ?? false
    const isFetchingDataFromPersonio = integrations.isFetching && !isNew
    const isEmployeeBeingSynced = !isNew && isPersonioIntegrationEnabled && isPersonioEmployeeSyncEnabled && isPersonioUser

    const rules = UserRules()
    const rulesCommon = UserCommonRules()

    const verifyEmail = useCallback(async () => {
        const emailValue = form.getFieldValue("email")
        if (validateEmail(emailValue)) {
            try {
                setEmail(emailValue)
                setEmailExist(false)
                const employeeWithEmail = await EmployeeService.fetchOneByEmail(emailValue)
                if (employeeWithEmail) setEmailExist(true)
            } catch (_e) {
                // ignore
            }
        }
    }, [])

    const companies = useCompanies()

    /**
     * available companies do not include companies the user is currently part of
     */
    const availableCompanies = useMemo(() => getAvailableCompanies(companies, employee), [companies, employee?.companyProfiles])

    return (
        <Form form={form} layout="vertical" onFinish={onSubmit} scrollToFirstError onValuesChange={onFormValuesChange}>
            <Row>
                <Col span={24}>
                    <Form.Item
                        label={
                            <Tooltip title={t("info:roles.system_rights")} placement="bottomRight">
                                <span>{t("input:employee.has_system_rights")}</span>
                            </Tooltip>
                        }
                    >
                        <ConditionalWrapper
                            condition={isSysAdminSwitchDisabled}
                            wrapper={(children: any) => (
                                <Tooltip getPopupContainer={getTooltipPopupContainer} title={<div>{t("error:sys_admin_rights_cannot_be_revoked")}</div>} placement="bottomLeft">
                                    {children}
                                </Tooltip>
                            )}
                        >
                            <Form.Item name="hasSystemRights" valuePropName="checked">
                                <Switch disabled={isSysAdminSwitchDisabled} />
                            </Form.Item>
                        </ConditionalWrapper>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[16, 16]}>
                <Col span={12} sm={24} md={12}>
                    <Tooltip title={isEmployeeBeingSynced && !integrations.isFetching ? t("input:employee.personioTooltip") : undefined}>
                        <Form.Item label={t("input:employee.first_name")} name="firstName" rules={rules.firstName}>
                            <Input
                                placeholder={t("placeholder:employee.first_name")}
                                autoComplete="off"
                                maxLength={100}
                                disabled={isEmployeeBeingSynced || isFetchingDataFromPersonio}
                            />
                        </Form.Item>
                    </Tooltip>
                </Col>
                <Col span={12} sm={24} md={12}>
                    <Tooltip title={isEmployeeBeingSynced && !integrations.isFetching ? t("input:employee.personioTooltip") : undefined}>
                        <Form.Item label={t("input:employee.last_name")} name="lastName" rules={rules.lastName}>
                            <Input
                                placeholder={t("placeholder:employee.last_name")}
                                autoComplete="off"
                                maxLength={100}
                                disabled={isEmployeeBeingSynced || isFetchingDataFromPersonio}
                            />
                        </Form.Item>
                    </Tooltip>
                </Col>

                <Col span={24}>
                    <Tooltip title={isEmployeeBeingSynced && !integrations.isFetching ? t("input:employee.personioTooltip") : undefined}>
                        <Form.Item className="mb-10" name="email" label={t("input:employee.email")} rules={rules.email}>
                            <Input
                                type="email"
                                name="email"
                                placeholder={t("placeholder:employee.email")}
                                onChange={verifyEmail}
                                autoComplete="off"
                                maxLength={100}
                                disabled={isEmployeeBeingSynced || isFetchingDataFromPersonio}
                            />
                        </Form.Item>
                    </Tooltip>
                    {emailExist && <span className="text-error">{t("error:employee.exists")}</span>}

                    {!isNew && email !== employee?.email && <p className="ant-form-item-has-error -mt-10">{t("info:email_update")}</p>}
                </Col>

                <Col span={24}>
                    <Form.Item label={t("input:employee.location")} name="location" rules={rulesCommon.location}>
                        <Input placeholder={t("placeholder:employee.location")} autoComplete="off" maxLength={MAX_INPUT_LENGTH} minLength={MIN_INPUT_LENGTH} />
                    </Form.Item>
                </Col>

                {canManageEmployees && (
                    <>
                        {!isFreeVersion && (
                            <Col span={24}>
                                <Tooltip title={employee?.positionSource === IntegrationTypeEnum.PERSONIO ? t("input:employee.personioTooltip") : undefined}>
                                    <Form.Item
                                        label={t("input:employee.position")}
                                        name="position"
                                        rules={employee?.positionSource === IntegrationTypeEnum.PERSONIO ? [] : rulesCommon.position}
                                        required={!employee?.positionSource}
                                    >
                                        <Input
                                            placeholder={t("placeholder:employee.position")}
                                            autoComplete="off"
                                            maxLength={MAX_INPUT_LENGTH}
                                            minLength={MIN_INPUT_LENGTH}
                                            disabled={employee?.positionSource === IntegrationTypeEnum.PERSONIO}
                                        />
                                    </Form.Item>
                                </Tooltip>
                            </Col>
                        )}
                    </>
                )}

                <Col span={24}>
                    {availableCompanies.length > 0 && isNew && (
                        <Col span={24} className="p-0">
                            <Form.Item>
                                <Checkbox
                                    data-testid="addEmployeeToCompanyCheckbox"
                                    checked={showAddUserToCompanyFields}
                                    onChange={(e) => setShowAddUserToCompanyFields(e.target.checked)}
                                >
                                    {t("input:employee.show_add_user_to_company_fields")}
                                </Checkbox>
                            </Form.Item>
                        </Col>
                    )}

                    <UserCompanyProfileForm
                        isShowing={showAddUserToCompanyFields}
                        user={employee}
                        isCompanyEditable
                        layout="vertical"
                        formInstance={form}
                        availableCompanies={availableCompanies}
                    />
                </Col>
            </Row>
        </Form>
    )
}

export default UserForm
