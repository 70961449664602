import { Expense, ExpenseKindEnum, PossibleMatch, PriceIntervalEnum, RightEnum } from "@finway-group/shared/lib/models"
import { roundNumberTo2Decimals } from "@finway-group/shared/lib/utils"
import { Button, Card, Table } from "antd"
import { ColumnsType } from "antd/lib/table"
import moment from "moment"
import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"

import Loading from "Components/loading"
import PriceLabel from "Components/priceLabel"
import { useTransaction } from "Shared/hooks/transaction.hooks"
import { AuthzService } from "Shared/services"
import { setExpense } from "Shared/store/actions/expense/expenseActions"
import { getPriceIntervalForBillingPeriod } from "Shared/utils/expense.utils"
import { getIBANPrintFormat, insertIf } from "Shared/utils/helper.utils"
import { parseHtml } from "Shared/utils/htmlParser.utils"

interface MatchedExpensesInterface {
    loading: boolean
    loadingId: string
    onUnmatch: (expense: string) => void
}

interface ExpenseRow {
    dateMatched: Date
    expense: Expense & { _id: string }
}

export const MatchedExpenses = ({ loadingId, onUnmatch }: MatchedExpensesInterface) => {
    const { t } = useTranslation()
    const history = useHistory()
    const dispatch = useDispatch()
    const transaction = useTransaction()

    const matchedExpnesesColumns: ColumnsType<ExpenseRow> = [
        {
            key: "expense.datePurchased",
            title: t("label:purchased_on"),
            ellipsis: true,
            width: 180,
            className: "pl-58",
            sorter: (a, b) => new Date(a.expense.datePurchased).getTime() - new Date(b.expense.datePurchased).getTime(),
            render: ({ expense: { datePurchased } }: ExpenseRow) => <span>{datePurchased ? moment(datePurchased).format("ll") : "n/a"}</span>,
        },
        {
            key: "expense.invoiceDueDate",
            title: t("label:due_date"),
            ellipsis: true,
            width: 160,
            sorter: (a, b) => new Date(a.expense.invoiceDueDate).getTime() || 0 - new Date(b.expense.invoiceDueDate).getTime() || 0,
            render: ({ expense: { invoiceDueDate } }: ExpenseRow) => <span>{invoiceDueDate ? moment(invoiceDueDate).format("ll") : "n/a"}</span>,
        },
        {
            key: "vendor.name",
            title: t("label:vendor"),
            ellipsis: true,
            width: 180,
            sorter: (a, b) => {
                const vendorNameA = a?.expense?.getCreditor()?.name
                const vendorNameB = b?.expense?.getCreditor()?.name
                return vendorNameA?.localeCompare(vendorNameB ?? "")
            },
            render: ({ expense }: ExpenseRow) => {
                const name = expense?.getCreditor()?.name
                return <span>{name || "n/a"}</span>
            },
        },
        {
            key: "iban",
            title: t("label:iban"),
            ellipsis: true,
            width: 250,
            sorter: (a, b) => {
                const ibanA = a?.expense?.getCreditor()?.iban
                const ibanB = b?.expense?.getCreditor()?.iban
                return ibanA?.localeCompare(ibanB ?? "")
            },
            render: ({ expense }: ExpenseRow) => {
                const iban = expense?.getCreditor()?.iban
                return <span>{iban ? getIBANPrintFormat(iban) : "n/a"}</span>
            },
        },
        {
            key: "expense.description",
            title: t("label:description"),
            ellipsis: true,
            width: 180,
            sorter: (a, b) => (a.expense.description || "").localeCompare(b.expense.description || ""),
            render: ({ expense: { description } }: ExpenseRow) => <span>{parseHtml(description || "n/a")}</span>,
        },
        {
            key: "expense.amount",
            title: t("label:gross_amount"),
            ellipsis: true,
            width: 150,
            sorter: (a, b) => (a.expense?.totalGrossPrice ?? 0) - (b.expense?.totalGrossPrice ?? 0),
            render: ({ expense }) => {
                const isSub = expense.kind === ExpenseKindEnum.SUBSCRIPTION
                const interval = isSub ? getPriceIntervalForBillingPeriod(expense.billingPeriod) : PriceIntervalEnum.ONE_TIME
                return <PriceLabel value={roundNumberTo2Decimals(expense.totalGrossPrice)} currency={expense.currency} interval={interval} />
            },
        },
        {
            key: "probability",
            title: t("label:probability_short"),
            ellipsis: true,
            width: 80,
            sorter: (a, b) => {
                const possibleMatchA = transaction.matchingData.possibleMatches.find((possibleMatch: any) => possibleMatch.expense === a.expense._id)
                const possibleMatchB = transaction.matchingData.possibleMatches.find((possibleMatch: any) => possibleMatch.expense === b.expense._id)
                return (possibleMatchA?.probability ?? 0) - (possibleMatchB?.probability ?? 0)
            },
            render: ({ expense: { _id } }: ExpenseRow) => {
                const possibleMatchFound = transaction.matchingData.possibleMatches.find((possibleMatch: any) => possibleMatch.expense === _id)
                return <span>{roundNumberTo2Decimals(possibleMatchFound?.probability ?? 0)}%</span>
            },
        },
        ...insertIf(AuthzService.isRightGrantedForLoggedInUser(RightEnum.TRANSACTION__ALL__UPDATE), {
            key: "isMatched",
            title: t("label:matching.match_status"),
            ellipsis: true,
            width: 150,
            align: "right",
            render: ({ expense: { _id } }: ExpenseRow) => (
                <Button
                    loading={_id === loadingId}
                    onClick={(e) => {
                        e.stopPropagation()
                        onUnmatch(_id)
                    }}
                    className={`min-w-80 btn-highlight-green-active`}
                >
                    {t("action:matching.unmatch")}
                </Button>
            ),
        }),
    ]

    return (
        <div>
            <div className="flex justify-between intems-center">
                <h2>{t("label:matched_expenses")}</h2>
            </div>

            <div>
                {!transaction ? (
                    <Loading />
                ) : transaction.matchingData.matchedExpenses.length === 0 ? (
                    <Card>
                        <div className="flex justify-center">
                            <span>{t("missing:transactions.matched_expenses")}</span>
                        </div>
                    </Card>
                ) : (
                    <div className="overflow-auto p-2">
                        <Table
                            rowKey={({ expense: { _id } }: any) => _id}
                            pagination={false}
                            columns={matchedExpnesesColumns}
                            dataSource={transaction.matchingData.matchedExpenses.map((e: any) => ({ ...e, expense: new Expense(e?.expense) }))}
                            onRow={({ expense: { _id } }: any) => ({
                                onClick: () => {
                                    dispatch(setExpense(_id))
                                    history.push(`/expenses/${_id}`)
                                },
                            })}
                        />
                    </div>
                )}
            </div>
        </div>
    )
}

export default MatchedExpenses
