import { Dispatch } from "redux"

import store from "Shared/store"
import { TablesEnum, initialTableConfigData } from "Shared/store/reducers/tableConfigReducer"
import { convertSorter } from "Shared/utils/helper.utils"

import {
    SetCurrentPageAction,
    SetFilterStringAction,
    SetItemsPerPageAction,
    SetSearchValueAction,
    SetSearchValueDashboardAction,
    SetSortingAction,
    TableConfigActionTypes,
} from "./tableConfigTypes"

export const setSearchValue = (table: TablesEnum, searchValue: string) => async (dispatch: Dispatch) => {
    const setSearchValueAction: SetSearchValueAction = {
        type: TableConfigActionTypes.SET_SEARCH_VALUE,
        table,
        searchValue,
    }

    dispatch(setSearchValueAction)

    // set page 1 for searching
    setCurrentPage(table, 1)(dispatch)
}

export const setSearchValueDashboard = (searchValue: string) => async (dispatch: Dispatch) => {
    const setSearchValueDashboardAction: SetSearchValueDashboardAction = {
        type: TableConfigActionTypes.SET_SEARCH_VALUE_DASHBOARD,
        searchValue,
    }

    dispatch(setSearchValueDashboardAction)

    // set all dashboard tables to page 1 for the searching
    setCurrentPage(TablesEnum.TODO_APPROVAL_PENDING, 1)(dispatch)
    setCurrentPage(TablesEnum.TODO_DOCS_NEEDED, 1)(dispatch)
    setCurrentPage(TablesEnum.TODO_INVOICE_APPROVAL, 1)(dispatch)
    setCurrentPage(TablesEnum.TODO_INVOICE_AND_PURCHASE, 1)(dispatch)
    setCurrentPage(TablesEnum.TODO_PURCHASE_APPROVAL, 1)(dispatch)
    setCurrentPage(TablesEnum.REVIEW_REQUESTS, 1)(dispatch)
    setCurrentPage(TablesEnum.PAY_AND_EXPORT_TO_BE_PAID, 1)(dispatch)
    setCurrentPage(TablesEnum.PAY_AND_EXPORT_TO_BE_EXPORTED, 1)(dispatch)
    setCurrentPage(TablesEnum.DONE_REQUESTS, 1)(dispatch)
    setCurrentPage(TablesEnum.ALL_REQUESTS, 1)(dispatch)
}

export const setCurrentPage = (table: TablesEnum, currentPage: number) => async (dispatch: Dispatch) => {
    const setCurrentPageAction: SetCurrentPageAction = {
        type: TableConfigActionTypes.SET_CURRENT_PAGE,
        table,
        currentPage,
    }

    dispatch(setCurrentPageAction)
}

export const setItemsPerPage = (table: TablesEnum, itemsPerPage: number) => async (dispatch: Dispatch) => {
    const setItemsPerPageAction: SetItemsPerPageAction = {
        type: TableConfigActionTypes.SET_ITEMS_PER_PAGE,
        table,
        itemsPerPage,
    }

    dispatch(setItemsPerPageAction)
}

export const setSorting = (table: TablesEnum, sorting: any) => async (dispatch: Dispatch) => {
    const setSortingAction: SetSortingAction = {
        type: TableConfigActionTypes.SET_SORTING,
        table,
        sorting,
    }

    dispatch(setSortingAction)
}

export const setFilter = (table: TablesEnum, filterString: any) => async (dispatch: Dispatch) => {
    const setFilterStringAction: SetFilterStringAction = {
        type: TableConfigActionTypes.SET_FILTER_STRING,
        table,
        filterString,
    }

    dispatch(setFilterStringAction)
}

export const handleTableConfigChange = (table: TablesEnum, pagination: any, sorter: any) => async (dispatch: Dispatch) => {
    const { currentPage, itemsPerPage, sorting } = store.getState().tableConfig[table]

    if (pagination.current !== currentPage) {
        setCurrentPage(table, pagination.current)(dispatch)
    }

    if (pagination.pageSize !== itemsPerPage) {
        setItemsPerPage(table, pagination.pageSize)(dispatch)
    }

    const convertedSorter = convertSorter(sorter)
    // stringify needed here since objects don't yield true on ===-comparison
    if (JSON.stringify(convertedSorter) !== JSON.stringify(sorting)) {
        setSorting(table, convertedSorter)(dispatch)
    }
}

export const initializeTableConfig = (table: TablesEnum) => async (dispatch: Dispatch) => {
    setCurrentPage(table, initialTableConfigData.currentPage)(dispatch)
    setItemsPerPage(table, initialTableConfigData.itemsPerPage)(dispatch)
    setSorting(table, initialTableConfigData.sorting)(dispatch)
}
