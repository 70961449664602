import { Card, Empty } from "antd"
import React from "react"

interface EmptyTableInterface {
    showSearchIcon: boolean
    noDocumentText: string
    noSearchText: string
}

export const EmptyTable: React.FC<EmptyTableInterface> = ({ showSearchIcon = false, noDocumentText, noSearchText }) => (
    <Card bodyStyle={{ height: "130px", display: "flex", justifyContent: "center", alignItems: "center" }}>
        {showSearchIcon ? (
            <div className="animation-appear">
                <Empty
                    className="animation-appear"
                    image={`./icons/no_data_found.svg`}
                    imageStyle={{ height: 50, marginRight: "auto", marginLeft: "auto", display: "inline-block" }}
                    description={<span className="text-lg font-bold mb-0">{noSearchText}</span>}
                ></Empty>
            </div>
        ) : (
            <div className="animation-appear">
                <Empty
                    className="animation-appear"
                    image={`./icons/empty_table.svg`}
                    imageStyle={{ height: 50, marginRight: "auto", marginLeft: "auto", display: "inline-block" }}
                    description={<span className="text-lg font-bold mb-0">{noDocumentText}</span>}
                ></Empty>
            </div>
        )}
    </Card>
)
