import { Company } from "@finway-group/shared/lib/models"
import { Input } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import SettingsLayout from "Features/settings/settings.layout"
import { updateCompanyFeatureSettings } from "Shared/store/actions/company/companyActions"

import GeneralNotificationSettings from "./generalNotificationSettings"

const NotificationSettingsContainer = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const GeneralSettings = [
        {
            name: "monthlyInvoiceReminder.enabled",
            title: t("label:notifications.auto_invoice_missing_reminder"),
            input: {
                name: "monthlyInvoiceReminder.dayOfMonth",
                component: Input,
                props: {
                    className: "w-60 md:w-70 ml-10",
                    placeholder: "10",
                    type: "number",
                    maxLength: 2,
                    min: 0,
                    max: 31,
                    onInput: (e: any) => (e.target.value = Math.abs(e.target.value) > 31 ? 31 : Math.abs(e.target.value)),
                },
            },
        },
        {
            name: "weeklyInvoiceReminder.enabled",
            title: t("label:notifications.recurring_invoice_missing_reminder"),
            input: {
                name: "weeklyInvoiceReminder.startAfter",
                component: Input,
                props: {
                    className: "w-60 md:w-70 ml-10",
                    placeholder: "10",
                    type: "number",
                    maxLength: 2,
                    min: 0,
                    onInput: (e: any) => (e.target.value = Math.abs(e.target.value)),
                },
            },
        },
    ]

    /**
     * Update company related notification settings
     * @param company model
     * @returns
     */

    const onGeneralSettingsUpdate = (company: Company) => dispatch(updateCompanyFeatureSettings(company._id, company))

    return (
        <SettingsLayout titleKey="label:notifications.general_notification">
            <GeneralNotificationSettings generalSettings={GeneralSettings} onUpdate={onGeneralSettingsUpdate} />
        </SettingsLayout>
    )
}

export default NotificationSettingsContainer
