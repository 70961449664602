import { CreateSsoProviderType, SsoProviderType, SsoProviderTypeEnum } from "@finway-group/shared/lib/models"
import { useMutation, useQuery } from "@tanstack/react-query"

import { queryClient } from "Shared/config/reactQueryConfig"
import { QueryKeyEnum } from "Shared/reactQuery.types"
import LocalStorageService from "Shared/services/localstorage.service"
import { queryDelete, queryGet, queryPatch, queryPost, queryPut } from "Shared/utils/reactQuery.utils"

const MODEL = "sso"

const invalidateSsoCache = () => {
    queryClient.invalidateQueries({
        queryKey: [QueryKeyEnum.SSO_CONFIGURATIONS],
    })

    queryClient.invalidateQueries({
        queryKey: [QueryKeyEnum.SSO_PROVIDER_TYPES],
    })
}

export const useSsoProviderTypes = () =>
    useQuery({
        queryKey: [QueryKeyEnum.SSO_PROVIDER_TYPES],
        queryFn: ({ signal }) =>
            queryGet<Array<SsoProviderTypeEnum>>(`${MODEL}/enabled-types`, { signal }).then((data) => {
                if (data) {
                    LocalStorageService.setSsoProviderTypes({ ssoProviderTypes: data })
                }
                return data
            }),

        initialData: LocalStorageService.getSsoProviderTypes(),
    })

export const useSsoConfigurations = () => useQuery([QueryKeyEnum.SSO_CONFIGURATIONS], ({ signal }) => queryGet<Array<SsoProviderType>>(MODEL, { signal }))

export const useCreateSsoConfiguration = () =>
    useMutation((data: CreateSsoProviderType) => queryPost(MODEL, data), {
        onSuccess: invalidateSsoCache,
    })

export const useUpdateSsoConfiguration = () =>
    useMutation((data: SsoProviderType) => queryPut(MODEL, data), {
        onSuccess: invalidateSsoCache,
    })

export const useDeleteSsoConfiguration = () =>
    useMutation((type: SsoProviderTypeEnum) => queryDelete(`${MODEL}/${type}`), {
        onSuccess: invalidateSsoCache,
    })

export const useToggleSsoConfiguration = () =>
    useMutation(({ type, enable }: { type: SsoProviderTypeEnum; enable: boolean }) => queryPatch(`${MODEL}/${type}/${enable ? "enable" : "disable"}`), {
        onSuccess: invalidateSsoCache,
    })
