import { Transaction } from "@finway-group/shared/lib/models"
import { Reducer } from "redux"

import { TransactionTabTypeEnum } from "Features/pages/transactions/transactions.list"

import {
    DeleteManyTransactionsAction,
    FetchAllTransactionAction,
    FetchOneTransactionAction,
    SetActiveTransactionTabAction,
    SetShouldRefetchTransactionsAction,
    SetTransactionAction,
    SyncOneTransactionAction,
    TransactionActionTypes,
    TransactionActions,
} from "../actions/transaction/transactionTypes"

export interface TransactionState {
    item: any
    items: Array<any>
    totalPages: number
    totalDocs: number
    shouldRefetch: boolean
    activeTransactionTab: TransactionTabTypeEnum
}

const initialState = {
    item: new Transaction(),
    items: [],
    totalPages: 1,
    totalDocs: 0,
    shouldRefetch: false,
    activeTransactionTab: TransactionTabTypeEnum.OUTFLOW,
}

const setTransaction = (state: TransactionState, action: SetTransactionAction): TransactionState => ({
    ...state,
    item: action.transaction,
})

const fetchOneTransaction = (state: TransactionState, action: FetchOneTransactionAction): TransactionState => ({
    ...state,
    item: action.transaction,
})

const fetchAllTransaction = (state: TransactionState, action: FetchAllTransactionAction): TransactionState => {
    let { item } = state
    if (item) {
        const itemInAction = action.transactions.find(({ id }: Transaction) => id === item.id)
        if (itemInAction) item = itemInAction
    }

    return {
        ...state,
        items: action.transactions,
        item,
        totalDocs: action.totalDocs,
        totalPages: action.totalPages,
    }
}

const deleteManyTransactions = (state: TransactionState, { transactionIds }: DeleteManyTransactionsAction): TransactionState => {
    const remainingTransactions = state.items.filter((transaction: Transaction) => !transactionIds.includes(transaction.id))
    let { item } = state
    if (transactionIds.includes(state.item.id)) item = new Transaction()

    return {
        ...state,
        items: remainingTransactions,
        item,
    }
}

const syncOneTransaction = (state: TransactionState, { transaction }: SyncOneTransactionAction): TransactionState => {
    const isIncludedInStateItems = state.items.filter((tx) => tx.id === transaction.id).length > 0

    return {
        ...state,
        items: isIncludedInStateItems ? state.items.map((item) => (item.id === transaction.id ? transaction : item)) : state.items,
        item: state.item?.id === transaction.id ? transaction : state.item,
    }
}

const setShouldRefetch = (state: TransactionState, action: SetShouldRefetchTransactionsAction): TransactionState => ({
    ...state,
    shouldRefetch: action.shouldRefetch,
})

const setActiveTransactionTab = (state: TransactionState, action: SetActiveTransactionTabAction): TransactionState => ({
    ...state,
    activeTransactionTab: action.activeTab,
})

export const transactionReducer: Reducer<TransactionState, TransactionActions> = (state = initialState, action) => {
    switch (action.type) {
        case TransactionActionTypes.SET_TRANSACTION:
            return setTransaction(state, action)
        case TransactionActionTypes.SET_ACTIVE_TRANSACTION_TAB:
            return setActiveTransactionTab(state, action)
        case TransactionActionTypes.FETCH_ONE_TRANSACTION:
            return fetchOneTransaction(state, action)
        case TransactionActionTypes.FETCH_ALL_TRANSACTION:
            return fetchAllTransaction(state, action)
        case TransactionActionTypes.SYNC_ONE_TRANSACTION:
            return syncOneTransaction(state, action)
        case TransactionActionTypes.SET_SHOULD_REFETCH_TRANSACTIONS:
            return setShouldRefetch(state, action)
        case TransactionActionTypes.DELETE_MANY_TRANSACTIONS:
            return deleteManyTransactions(state, action)
        default:
            return state
    }
}
