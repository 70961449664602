import { TripFolder } from "@finway-group/shared/lib/models"
import { Tag } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"

import ExpenseStatusTag from "./expenseStatusTag"

interface TagPropInterface {
    folder: TripFolder
}

const FolderStatusTag: React.FC<TagPropInterface> = ({ folder }) => {
    const { t } = useTranslation()

    if (folder.isDraft) return <Tag className="ant-tag-yellow">{t("label:draft")}</Tag>

    return <ExpenseStatusTag status={folder.status} />
}

export default FolderStatusTag
