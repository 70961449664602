import { BankOutlined as BankIcon, ImportOutlined as ImportIcon } from "@ant-design/icons"
import { Bank } from "@finway-group/shared/lib/models"
import { Button, Col, Row } from "antd"
import React from "react"
import "react-feather"
import { useTranslation } from "react-i18next"

interface BankPreviewPropInterface {
    bank: Bank
    isImporting: boolean
    onImport: (bankId: string) => void
}

const BankPreview: React.FC<BankPreviewPropInterface> = ({ bank, isImporting, onImport }) => {
    const { t } = useTranslation()

    return (
        <div>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <div className="border-solid border rounded border-gray-600 px-20 py-4">
                        <Row gutter={[16, 16]} align="middle">
                            <Col span={3} className="mt-16">
                                <BankIcon style={{ fontSize: "30px" }} />
                            </Col>
                            <Col xs={{ span: 14, offset: 1 }} lg={{ span: 14, offset: 0 }} className="mt-10">
                                <Row style={{ width: "70%" }}>
                                    <span className="font-bold text-text-dark">{bank.bankName}</span>
                                </Row>
                                <Row>
                                    <span className="text">{bank.bankCode}</span>
                                </Row>
                            </Col>
                            <Col span={6} className="mt-16 content-right">
                                <Button loading={isImporting} className="float-right min-w-120" onClick={() => onImport(bank.bankId)}>
                                    <ImportIcon />
                                    {t("action:banking.import")}
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default BankPreview
