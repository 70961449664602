import Icon from "@ant-design/icons"
import React from "react"

const dollarSignInvoiceSvg = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7.94152 3.83333H8.34593C9.01598 3.83333 9.55917 4.39298 9.55917 5.08333C9.55917 5.31345 9.37811 5.5 9.15476 5.5C8.93141 5.5 8.75034 5.31345 8.75034 5.08333C8.75034 4.85321 8.56928 4.66667 8.34593 4.66667H5.11064C4.88729 4.66667 4.70623 4.85321 4.70623 5.08333V5.91667C4.70623 6.14679 4.88729 6.33333 5.11064 6.33333H8.34593C9.01598 6.33333 9.55917 6.89298 9.55917 7.58333V8.41667C9.55917 9.10702 9.01598 9.66667 8.34593 9.66667H7.94152V10.0833C7.94152 10.3135 7.76046 10.5 7.53711 10.5C7.31376 10.5 7.1327 10.3135 7.1327 10.0833V9.66667H6.32387V10.0833C6.32387 10.3135 6.14281 10.5 5.91946 10.5C5.69611 10.5 5.51505 10.3135 5.51505 10.0833V9.66667H5.11064C4.44059 9.66667 3.8974 9.10702 3.8974 8.41667C3.8974 8.18655 4.07846 8 4.30182 8C4.52517 8 4.70623 8.18655 4.70623 8.41667C4.70623 8.64678 4.88729 8.83333 5.11064 8.83333H8.34593C8.56928 8.83333 8.75034 8.64678 8.75034 8.41667V7.58333C8.75034 7.35321 8.56928 7.16667 8.34593 7.16667H5.11064C4.44059 7.16667 3.8974 6.60702 3.8974 5.91667V5.08333C3.8974 4.39298 4.44059 3.83333 5.11064 3.83333H5.51505V3.41667C5.51505 3.18655 5.69611 3 5.91946 3C6.14281 3 6.32387 3.18655 6.32387 3.41667V3.83333H7.1327V3.41667C7.1327 3.18655 7.31376 3 7.53711 3C7.76046 3 7.94152 3.18655 7.94152 3.41667V3.83333ZM11.9856 12.1667V2.58333C11.9856 1.89298 11.4425 1.33333 10.7724 1.33333H2.68417C2.01412 1.33333 1.47093 1.89298 1.47093 2.58333V13.8333C1.47093 14.2936 1.83306 14.6667 2.27976 14.6667C2.72646 14.6667 3.08858 14.2936 3.08858 13.8333V12.5833C3.08858 12.3532 3.26964 12.1667 3.49299 12.1667H11.9856ZM12.7945 12.1667H14.8165C15.0399 12.1667 15.2209 12.3532 15.2209 12.5833V13.4167C15.2209 14.5673 14.3156 15.5 13.1989 15.5H2.27976C1.38635 15.5 0.662109 14.7538 0.662109 13.8333V2.58333C0.662109 1.43274 1.56742 0.5 2.68417 0.5H10.7724C11.8892 0.5 12.7945 1.43274 12.7945 2.58333V12.1667ZM13.1989 14.6667C13.8689 14.6667 14.4121 14.107 14.4121 13.4167V13H3.8974V13.8333C3.8974 14.1369 3.81863 14.4215 3.68099 14.6667H13.1989Z"
            fill="#231C5B"
        />
    </svg>
)

const DollarSignInvoiceIcon = (props: any) => <Icon component={dollarSignInvoiceSvg} {...props} />

export default DollarSignInvoiceIcon
