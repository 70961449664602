import { Col, Row, Tag } from "antd"
import React, { MouseEvent, useEffect } from "react"
import { useTranslation } from "react-i18next"

import { removeFilterOption, resetFilter } from "Shared/hooks/filter.hooks"
import { useTableFilterObject } from "Shared/hooks/table.hooks"
import { TablesEnum } from "Shared/store/reducers/tableConfigReducer"
import { createFilterTagsArray } from "Shared/utils/filter.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

import { FilterSectionOptionsInterface } from "./filter/filterTypes"

interface FilterSectionInterface {
    table: TablesEnum
    options?: FilterSectionOptionsInterface
}

interface FilterOptionInterface {
    name: string
    label: string
    value: string
}

export const FilterSection: React.FC<FilterSectionInterface> = ({ table, options = {} }) => {
    const { t } = useTranslation()

    const [filterTags, setFilterTags] = useStateIfMounted<Array<FilterOptionInterface>>([])
    const filterObject = useTableFilterObject(table)

    useEffect(() => {
        setFilterTags(createFilterTagsArray(filterObject, table, options))
    }, [JSON.stringify(filterObject), JSON.stringify(options)])

    const onClose = (e: MouseEvent<HTMLDivElement>, name: string) => {
        e.preventDefault()
        removeFilterOption(table, name, filterObject)
    }

    return (
        <Row className="mb-14">
            <Col span={24}>
                {filterTags.map(({ name, label, value }) => (
                    <Tag key={label} className="ant-tag-filter p-4 mr-10 mt-4 mb-4" closable onClose={(e: MouseEvent<HTMLDivElement>) => onClose(e, name)}>
                        <span className="font-semibold">{label}</span>:<span className="font-normal ml-4">{value}</span>
                    </Tag>
                ))}

                {filterTags.length > 1 && (
                    <Tag
                        className="ant-tag-filter p-4 mr-10 mt-4 mb-4"
                        onClick={() => resetFilter(table, { presets: filterObject.presets })}
                        style={{ cursor: "pointer" }}
                        key={"clearTag"}
                    >
                        <span className="font-normal">{t("action:clear_all")}</span>
                    </Tag>
                )}
            </Col>
        </Row>
    )
}

export default FilterSection
