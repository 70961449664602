import { BankConnection } from "@finway-group/shared/lib/models"
import { Reducer } from "redux"

import { BankActionTypes, BankActions, FetchAllBankConnectionsAction, FetchOneBankConnectionAction, SetActionInProgressAction } from "../actions/bank/bankTypes"

export interface BankState {
    items: Array<BankConnection>
    item: BankConnection
    actionInProgress: boolean
}

const initialState = {
    items: [],
    item: {} as BankConnection,
    actionInProgress: false,
}

const fetchAllBankConnections = (state: BankState, action: FetchAllBankConnectionsAction): BankState => {
    let { item } = state
    if (item) {
        const itemInAction = action.bankConnections.find(({ _id }: BankConnection) => _id === item?._id)
        if (itemInAction) item = itemInAction
    }

    return {
        ...state,
        items: action.bankConnections,
        item,
    }
}

const fetchOneBankConnection = (state: BankState, action: FetchOneBankConnectionAction): BankState => ({
    ...state,
    item: action.bankConnection,
})

const setActionInProgress = (state: BankState, action: SetActionInProgressAction): BankState => ({
    ...state,
    actionInProgress: action.actionInProgress,
})

export const bankReducer: Reducer<BankState, BankActions> = (state = initialState, action) => {
    switch (action.type) {
        case BankActionTypes.FETCH_ALL_BANK_CONNECTIONS:
            return fetchAllBankConnections(state, action)
        case BankActionTypes.FETCH_ONE_BANK_CONNECTION:
            return fetchOneBankConnection(state, action)
        case BankActionTypes.SET_ACTION_IN_PROGRESS:
            return setActionInProgress(state, action)
        default:
            return state
    }
}
