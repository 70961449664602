import { ExpenseAccount } from "@finway-group/shared/lib/models"
import axios from "axios"

import store from "Shared/store"

const MODEL = "expenseaccounts"

const ExpenseAccountService = {
    fetchExpenseAccounts: async () => {
        const { data } = await axios.get(`/${MODEL}`)
        return data as Array<ExpenseAccount>
    },
    getExpenseAccountById: (id: string | undefined) => {
        const expenseAccounts = store.getState().expenseAccounts.items
        return expenseAccounts.find((acc: ExpenseAccount) => acc._id === id)
    },
    updateExpenseAccounts: async (updateData: Array<ExpenseAccount>) => {
        const { data } = await axios.put(`${MODEL}`, { expenseAccounts: updateData })
        return data as Array<ExpenseAccount>
    },
    deleteExpenseAccounts: async (deleteData: Array<ExpenseAccount>) => {
        const { data } = await axios.post(`${MODEL}/delete-many`, { expenseAccounts: deleteData })
        return data as Array<ExpenseAccount>
    },
    createExpenseAccounts: async (insertData: Array<ExpenseAccount>) => {
        const { data } = await axios.post(`${MODEL}/create-many`, { expenseAccounts: insertData })
        return data as Array<ExpenseAccount>
    },
    syncExpenses: async (updateExpenseAccounts: Array<ExpenseAccount>) => {
        const { data } = await axios.post(`${MODEL}/sync-expenses`, { expenseAccounts: updateExpenseAccounts })
        return data
    },
}

export default ExpenseAccountService
