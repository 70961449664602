import { ApprovalScopeEnum, ApprovalStatusEnum, Expense, ExpenseStatusEnum, RightEnum } from "@finway-group/shared/lib/models"
import {
    checkIfUserIsPendingApproverOfExpense,
    getApprovalProcess,
    getApprovalProcessScopeFromExpenseStatus,
    getApproverIdsOfApprovalProcess,
} from "@finway-group/shared/lib/utils/approvalProcess.utils"
import { Button, Tooltip } from "antd"
import React from "react"
import { Bell as BellIcon, X as CancelIcon, Check as CheckIcon, Download as DownloadIcon, Upload as UploadIcon } from "react-feather"
import { useTranslation } from "react-i18next"

import { ConditionalWrapper } from "Components/conditionalWrapper"
import { TrashIcon } from "Components/icons"
import ArrowThinUpIcon from "Components/icons/arrowThinUp"
import { AuthzService, UserService } from "Shared/services"
import { isFolderExpense } from "Shared/utils/expense.utils"
import { getFolderProgressionInfo } from "Shared/utils/folder.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

type ActionButtonProp = (event: React.MouseEvent<HTMLElement>, request: Expense) => void
interface RequestActionButtonsInterface {
    request: Expense
    disabled?: boolean
    isExportButtonLoading?: boolean
    onApprove?: (event: React.MouseEvent<HTMLElement>, request: Expense, approvalScope: ApprovalScopeEnum) => Promise<void>
    onReject?: ActionButtonProp
    onExport?: ActionButtonProp
    onReview?: ActionButtonProp
    onUpload?: ActionButtonProp
    onSendReminder?: (event: React.MouseEvent<HTMLElement>, request: Expense) => Promise<void>
    onCancel?: ActionButtonProp
    onDeleteFolder?: ActionButtonProp
    onSubmitFolder?: ActionButtonProp
}

const RequestActionButtons: React.FC<RequestActionButtonsInterface> = ({
    request,
    disabled,
    isExportButtonLoading,
    onApprove,
    onReject,
    onExport,
    onReview,
    onUpload,
    onSendReminder,
    onCancel,
    onDeleteFolder,
    onSubmitFolder,
}) => {
    const { t } = useTranslation()

    const loggedInProfile = UserService.getLoggedInEmployeeProfile()

    const isRequester = request.requestedBy?._id === loggedInProfile.id
    const isGlobalApprover = AuthzService.isLoggedInUserGlobalApprover()
    const canApproverRejectFromDashboard = AuthzService.isRightGrantedForLoggedInUser(RightEnum.EXPENSE__ALL__APPROVE_REJECT_DASHBOARD)
    const isPendingApproverOfStepInProgress = checkIfUserIsPendingApproverOfExpense(request.approvalProcesses, loggedInProfile.id)

    const invoiceApprovalProcess = getApprovalProcess(ApprovalScopeEnum.EXPENSE_INVOICE, request.approvalProcesses)
    const isApproverOfInvoiceApprovalProcess = getApproverIdsOfApprovalProcess(invoiceApprovalProcess).includes(loggedInProfile.id)

    const hasApproveRightForExpense = AuthzService.isLoggedInUserAllowedToApproveExpense(request)

    const [sendReminderButtonLoading, setSendReminderButtonLoading] = useStateIfMounted(false)
    const [approveButtonLoading, setApproveButtonLoading] = useStateIfMounted(false)

    const approvalScope = getApprovalProcessScopeFromExpenseStatus(request.status)

    const handleApprove = (event: React.MouseEvent<HTMLElement>, request: Expense) => {
        if (onApprove && approvalScope) {
            setApproveButtonLoading(true)
            onApprove(event, request, approvalScope).finally(() => setApproveButtonLoading(false))
        }
    }

    const handleSendReminder = (event: React.MouseEvent<HTMLElement>, req: Expense) => {
        if (onSendReminder) {
            setSendReminderButtonLoading(true)
            onSendReminder(event, req).finally(() => setSendReminderButtonLoading(false))
        }
    }

    switch (request.status) {
        case ExpenseStatusEnum.PURCHASE_PENDING:
        case ExpenseStatusEnum.INVOICE_PENDING:
            if (isFolderExpense(request) && request.isDraft) {
                return (
                    <div className="btn-wrapper">
                        <Button className="btn-default min-w-120" icon={<TrashIcon />} onClick={!disabled ? (event) => onDeleteFolder?.(event, request) : undefined}>
                            <span>{t("action:delete")}</span>
                        </Button>
                        <Button
                            className="min-w-120"
                            loading={approveButtonLoading}
                            type="primary"
                            icon={<ArrowThinUpIcon />}
                            disabled={request.children?.length === 0}
                            onClick={!disabled ? (event) => onSubmitFolder?.(event, request) : undefined}
                        >
                            <span>{t("action:submit")}</span>
                        </Button>
                    </div>
                )
            }

            const approvalProcess = approvalScope ? getApprovalProcess(approvalScope, request.approvalProcesses) : undefined
            const isApproverOfApprovalProcess = getApproverIdsOfApprovalProcess(approvalProcess).includes(loggedInProfile.id)
            if (isRequester && !isPendingApproverOfStepInProgress && approvalProcess?.status === ApprovalStatusEnum.IN_PROGRESS) {
                return (
                    <div className="btn-wrapper">
                        <Button className="btn-default min-w-120" type="default" icon={<CancelIcon />} onClick={!disabled ? (event) => onCancel?.(event, request) : undefined}>
                            <span>{t("action:cancel_request")}</span>
                        </Button>
                        <Button
                            className="btn-highlight-blue min-w-120"
                            icon={<BellIcon />}
                            loading={sendReminderButtonLoading}
                            onClick={!disabled ? (event) => handleSendReminder?.(event, request) : undefined}
                        >
                            <span className="m-0">{t("action:reminder")}</span>
                        </Button>
                    </div>
                )
            }
            if (canApproverRejectFromDashboard && (isGlobalApprover || isApproverOfApprovalProcess)) {
                const isSubmit = approvalProcess?.status === ApprovalStatusEnum.NOT_STARTED
                const folderIsBlocked = isFolderExpense(request) ? !getFolderProgressionInfo(request, request.children).canAdvance : false
                const approvalDisabled = folderIsBlocked || !hasApproveRightForExpense || disabled
                return (
                    <div className="btn-wrapper">
                        <Button
                            className="btn-default min-w-120"
                            icon={<CancelIcon />}
                            onClick={!disabled ? (event) => onReject?.(event, request) : undefined}
                            disabled={approvalDisabled}
                        >
                            <span>{t("action:reject")}</span>
                        </Button>
                        <ConditionalWrapper
                            condition={!hasApproveRightForExpense}
                            wrapper={(children: React.ReactNode) => (
                                <Tooltip className="min-w-120" overlayClassName="min-w-120" title={t("tooltips:approval_process.no_right")}>
                                    {children}
                                </Tooltip>
                            )}
                            // Do not remove the wrapping <div> of button or the tooltip wrapper won't work.
                            // https://github.com/ant-design/ant-design/issues/18842
                            children={
                                <div>
                                    <Button
                                        className="min-w-120"
                                        loading={approveButtonLoading}
                                        type="primary"
                                        icon={isSubmit ? <ArrowThinUpIcon /> : <CheckIcon />}
                                        onClick={!disabled ? (event) => handleApprove(event, request) : undefined}
                                        disabled={approvalDisabled}
                                    >
                                        <span>{isSubmit ? t("action:submit") : t("action:approve")}</span>
                                    </Button>
                                </div>
                            }
                        />
                    </div>
                )
            }
            return <></>
        case ExpenseStatusEnum.DOCS_NEEDED:
            return (
                <div className="btn-wrapper">
                    <Button className="btn-highlight-blue min-w-120" icon={<UploadIcon />}>
                        <span>{t("action:invoice")}</span>
                    </Button>
                    {(isGlobalApprover || isApproverOfInvoiceApprovalProcess) && !isRequester && (
                        <Button className="btn-highlight-blue min-w-120" icon={<BellIcon />} onClick={onSendReminder ? (event) => onSendReminder(event, request) : () => {}}>
                            <span className="m-0">{t("action:reminder")}</span>
                        </Button>
                    )}
                </div>
            )
        case ExpenseStatusEnum.APPROVED:
            return (
                <Button className="w-160" onClick={!disabled ? (event) => onReview?.(event, request) : undefined}>
                    <span>{t("action:mark_as_reviewed")}</span>
                </Button>
            )
        case ExpenseStatusEnum.REVIEWED:
        case ExpenseStatusEnum.PAID:
            return (
                <Button className="w-160" loading={isExportButtonLoading} onClick={(event) => onExport?.(event, request)}>
                    <DownloadIcon />
                    <span>{t("action:export")}</span>
                </Button>
            )
        default:
            return <></>
    }
}

export default RequestActionButtons
