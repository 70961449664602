import { Form, Modal } from "antd"
import { Store } from "antd/lib/form/interface"
import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import ArchiveModalForm from "Components/forms/archiveSearch.form"
import { NotificationService } from "Shared/services"
import { fetchArchivedExpensesCounts } from "Shared/store/actions/expense/expenseActions"
import { updateTableFilter, updateTableSearch } from "Shared/store/actions/tables/tableActions"
import { TablesEnum } from "Shared/store/reducers/tableConfigReducer"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

interface ExpenseArchiveSearchModalProps {
    isShowing: boolean
    onCancel: () => void
}

const ExpenseArchiveSearchModal = ({ isShowing, onCancel }: ExpenseArchiveSearchModalProps) => {
    const { t } = useTranslation()
    const [formInstance] = Form.useForm()
    const [isLoading, setIsLoading] = useStateIfMounted(false)

    const dispatch = useDispatch()

    const onSubmit = async (values: Store) => {
        try {
            const filter = {}

            setIsLoading(true)
            dispatch(updateTableSearch(TablesEnum.ARCHIVE, values.searchString, false)) // should not fetch
            dispatch(updateTableFilter(TablesEnum.ARCHIVE, { ...filter, minDate: values.period[0], maxDate: values.period[1] }))
            dispatch(fetchArchivedExpensesCounts())
            onHide()
        } catch (err) {
            setIsLoading(false)
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:error"))
        }
    }

    const onHide = () => {
        setIsLoading(false)
        onCancel()
    }

    return (
        <>
            <Modal
                className="ant-modal--card"
                title={t("action:archive.expense_search")}
                visible={isShowing}
                maskClosable={false}
                onOk={formInstance.submit}
                onCancel={onHide}
                okText={t("action:confirm")}
                cancelText={t("action:cancel")}
                confirmLoading={isLoading}
                keyboard
            >
                <ArchiveModalForm formInstance={formInstance} onSubmit={onSubmit} archiveTable={TablesEnum.ARCHIVE} />
            </Modal>
        </>
    )
}

export default ExpenseArchiveSearchModal
