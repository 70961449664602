import { CommentItem, Expense, TabTypeEnum } from "@finway-group/shared/lib/models"
import { ExpenseDiscountInterface } from "@finway-group/shared/lib/models/expense/expenseDiscount.interface"
import { Action } from "redux"

export interface ExpenseCountInterface {
    toDo: number
    inProgress: number
    review: number
    payExport: number
    done: number
    allRequests: number
    archive: number
}

export enum ExpenseActionTypes {
    FETCH_ALL_EXPENSES = "FETCH_ALL_EXPENSES",
    FETCH_ONE_EXPENSE = "FETCH_ONE_EXPENSE",
    SYNC_ONE_EXPENSE = "SYNC_ONE_EXPENSE",
    UPDATE_EXPENSE = "UPDATE_EXPENSE",
    DELETE_EXPENSE = "DELETE_EXPENSE",
    SEND_REMINDER = "SEND_REMINDER",
    UPDATE_EXPENSE_COMMENTS = "UPDATE_EXPENSE_COMMENTS",
    SET_ACTIVE_TAB = "SET_ACTIVE_TAB",
    UPDATE_FILTER = "UPDATE_FILTER",
    FETCH_EXPENSE_COUNTS = "FETCH_EXPENSE_COUNTS",
    SET_SHOULD_REFETCH_EXPENSES = "SET_SHOULD_REFETCH_EXPENSES",
    ATTACH_TAG_EXPENSE = "ATTACH_TAG_EXPENSE",
    DETACH_TAG_EXPENSE = "DETACH_TAG_EXPENSE",
    SET_REMINDER = "SET_REMINDER",
    SUBMIT_DISCOUNTS = "SUBMIT_DISCOUNTS",
    DELETE_DISCOUNTS = "DELETE_DISCOUNTS",
    FETCH_EXPENSE_CARD = "FETCH_EXPENSE_CARD",
}

export interface FetchAllExpensesAction extends Action<typeof ExpenseActionTypes.FETCH_ALL_EXPENSES> {
    type: typeof ExpenseActionTypes.FETCH_ALL_EXPENSES
    expenses: Array<Expense>
    totalPages: number
    totalDocs: number
    append: boolean
}

export interface FetchExpenseCountsAction extends Action<typeof ExpenseActionTypes.FETCH_EXPENSE_COUNTS> {
    type: typeof ExpenseActionTypes.FETCH_EXPENSE_COUNTS
    counts: any
}

export interface FetchOneExpenseAction extends Action<typeof ExpenseActionTypes.FETCH_ONE_EXPENSE> {
    type: typeof ExpenseActionTypes.FETCH_ONE_EXPENSE
    expense: Expense
}

export interface SyncOneExpenseAction extends Action<typeof ExpenseActionTypes.SYNC_ONE_EXPENSE> {
    type: typeof ExpenseActionTypes.SYNC_ONE_EXPENSE
    expense: Expense
}

export interface UpdateExpenseAction extends Action<typeof ExpenseActionTypes.UPDATE_EXPENSE> {
    type: typeof ExpenseActionTypes.UPDATE_EXPENSE
    expense: Expense
}

export interface DeleteExpenseAction extends Action<typeof ExpenseActionTypes.DELETE_EXPENSE> {
    type: typeof ExpenseActionTypes.DELETE_EXPENSE
    expense: Expense
}

export interface UpdateRealTimeComments extends Action<typeof ExpenseActionTypes.UPDATE_EXPENSE_COMMENTS> {
    type: typeof ExpenseActionTypes.UPDATE_EXPENSE_COMMENTS
    id: string
    comments: Array<CommentItem>
}
export interface SendExpenseReminderAction extends Action<typeof ExpenseActionTypes.SEND_REMINDER> {
    type: typeof ExpenseActionTypes.SEND_REMINDER
}
export interface SetActiveTabAction extends Action<typeof ExpenseActionTypes.SET_ACTIVE_TAB> {
    type: typeof ExpenseActionTypes.SET_ACTIVE_TAB
    activeTab: TabTypeEnum
}

export interface UpdateFilterAction extends Action<typeof ExpenseActionTypes.UPDATE_FILTER> {
    type: typeof ExpenseActionTypes.UPDATE_FILTER
    filter: string
}

export interface SetShouldRefetchExpensesAction extends Action<typeof ExpenseActionTypes.SET_SHOULD_REFETCH_EXPENSES> {
    type: typeof ExpenseActionTypes.SET_SHOULD_REFETCH_EXPENSES
    shouldRefetch: boolean
}

export interface AttachTagExpenseAction extends Action<typeof ExpenseActionTypes.ATTACH_TAG_EXPENSE> {
    type: typeof ExpenseActionTypes.ATTACH_TAG_EXPENSE
    expenseTagId: string
    expenseId: string
}

export interface DetachTagExpenseAction extends Action<typeof ExpenseActionTypes.DETACH_TAG_EXPENSE> {
    type: typeof ExpenseActionTypes.DETACH_TAG_EXPENSE
    expenseTagId: string
}
export interface SetReminderAction extends Action<typeof ExpenseActionTypes.SET_REMINDER> {
    type: typeof ExpenseActionTypes.SET_REMINDER
}

export interface SubmitDiscountsAction extends Action<typeof ExpenseActionTypes.SUBMIT_DISCOUNTS> {
    type: typeof ExpenseActionTypes.SUBMIT_DISCOUNTS
    expenseId: string
    discounts: Array<ExpenseDiscountInterface & { _id: string }>
}

export interface DeleteDiscountsAction extends Action<typeof ExpenseActionTypes.DELETE_DISCOUNTS> {
    type: typeof ExpenseActionTypes.DELETE_DISCOUNTS
    expenseId: string
}

export type ExpenseActions =
    | FetchAllExpensesAction
    | FetchOneExpenseAction
    | SyncOneExpenseAction
    | UpdateExpenseAction
    | DeleteExpenseAction
    | SendExpenseReminderAction
    | SetActiveTabAction
    | UpdateFilterAction
    | FetchExpenseCountsAction
    | SetShouldRefetchExpensesAction
    | AttachTagExpenseAction
    | DetachTagExpenseAction
    | SetReminderAction
    | SubmitDiscountsAction
    | DeleteDiscountsAction

export interface FetchExpenseCardAction extends Action<typeof ExpenseActionTypes.FETCH_EXPENSE_CARD> {
    type: typeof ExpenseActionTypes.FETCH_EXPENSE_CARD
    card: any
}
