import { Tabs } from "antd"
import React, { useState } from "react"
import { Edit as EditIcon } from "react-feather"
import { useTranslation } from "react-i18next"

import ToolSettingsModal from "Components/modals/toolSetting.modal"
import SettingsLayout, { SettingsButton } from "Features/settings/settings.layout"
import { useModal } from "Shared/context/modal.context"
import { useLoggedInEmployeeProfile } from "Shared/hooks/user.hooks"

import TroubleShootingSettings from "./troubleShootingSettings"

const { TabPane } = Tabs

const SecuritySettings = React.lazy(() => import("./securitySettings"))
const ToolsSettings = React.lazy(() => import("./toolSettings"))

enum ToolsAndSecurityActiveTabEnum {
    SECURITY,
    TOOL_SETTINGS,
    TROUBLESHOOTING,
}

const ToolsAndSecurityContainer = () => {
    const { t } = useTranslation()
    const { showModal } = useModal()

    const loggedInUser = useLoggedInEmployeeProfile()

    const [activeKey, setActiveKey] = useState<Number>(ToolsAndSecurityActiveTabEnum.TOOL_SETTINGS)

    const tabs = [
        {
            title: t("label:tool_settings"),
            key: ToolsAndSecurityActiveTabEnum.TOOL_SETTINGS,
            component: <ToolsSettings />,
        },
        {
            title: t("label:security"),
            key: ToolsAndSecurityActiveTabEnum.SECURITY,
            component: <SecuritySettings />,
        },
        {
            title: t("label:troubleshooting"),
            key: ToolsAndSecurityActiveTabEnum.TROUBLESHOOTING,
            component: <TroubleShootingSettings />,
        },
    ]

    const buttons = () => {
        switch (activeKey) {
            case ToolsAndSecurityActiveTabEnum.TOOL_SETTINGS:
                return (
                    <SettingsButton
                        icon={<EditIcon />}
                        onClick={() => {
                            showModal(ToolSettingsModal, false, { employee: loggedInUser, isShowing: true })
                        }}
                        labelKey={"action:edit"}
                    />
                )
            default:
                return null
        }
    }

    return (
        <SettingsLayout titleKey="label:tool_settings_security" buttons={buttons()}>
            <Tabs onChange={(key) => setActiveKey(Number(key))}>
                {tabs.map((tab) => (
                    <TabPane tab={tab.title} key={tab.key} className="pb-10">
                        {tab.component}
                    </TabPane>
                ))}
            </Tabs>
        </SettingsLayout>
    )
}

export default React.memo(ToolsAndSecurityContainer)
