import { Menu } from "antd"
import React from "react"

import ActivitySkeleton from "./activity.skeleton"

export const NotificationElementSkeleton = () => (
    <Menu.Item className="min-w-350">
        <ActivitySkeleton />
    </Menu.Item>
)
