import { Expense, InboxInvoice } from "@finway-group/shared/lib/models"
import React, { useContext, useState } from "react"

type SelectedExpensesContextInterface = [Array<Expense>, React.Dispatch<React.SetStateAction<Array<Expense>>>]
type LoadingExpenseIdsContextInterface = [Array<string>, React.Dispatch<React.SetStateAction<Array<string>>>]
type SelectedInboxInvoicesContextInterface = [Array<InboxInvoice>, React.Dispatch<React.SetStateAction<Array<InboxInvoice>>>]

// prettier-ignore
const SelectedExpensesContext = React.createContext<SelectedExpensesContextInterface>([[], () => { }]);
const LoadingExpenseIdsContext = React.createContext<LoadingExpenseIdsContextInterface>([[], () => {}])
const SelectedInboxInvoicesContext = React.createContext<SelectedInboxInvoicesContextInterface>([[], () => {}])

export const useSelectedExpenses = () => useContext(SelectedExpensesContext)
export const useLoadingExpenseIds = () => useContext(LoadingExpenseIdsContext)
export const useSelectedInboxInvoices = () => useContext(SelectedInboxInvoicesContext)

interface SelectedExpensesProviderInterface {
    children: any
}

/**
 * This Provider should wrap the component hierarchy where this state is used.
 *
 * In the individual child components it can be accessed like this:
 *     const [selectedExpenses, setSelectedExpenses] = useContext(SelectedExpensesContext)
 *     const [loadingExpenseId, setLoadingExpenseId] = useContext(LoadingExpenseContext)
 *
 * Or when using the hook above, even easier:
 *     const [selectedExpenses, setSelectedExpenses] = useSelectedExpenses()
 *     const [loadingExpenseId, setLoadingExpenseId] = useLoadingExpenseId()
 */
export const SelectedExpensesProvider: React.FC<SelectedExpensesProviderInterface> = ({ children }) => {
    const selectionState = useState<Array<Expense>>([])
    const loadingState = useState<Array<string>>([])

    // prettier-ignore
    return (
        <SelectedExpensesContext.Provider value={selectionState}>
            <LoadingExpenseIdsContext.Provider value={loadingState}>
                {children}
            </LoadingExpenseIdsContext.Provider>
        </SelectedExpensesContext.Provider>
    );
}

export const SelectedInboxInvoicesProvider: React.FC<SelectedExpensesProviderInterface> = ({ children }) => {
    const selectionState = useState<Array<InboxInvoice>>([])
    // prettier-ignore
    return (
        <SelectedInboxInvoicesContext.Provider value={selectionState}>
                {children}
        </SelectedInboxInvoicesContext.Provider>
    );
}
