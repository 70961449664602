import { Card, Table } from "antd"
import { ColumnsType } from "antd/lib/table"
import moment from "moment"
import React, { useMemo } from "react"

import { SkeletonMedium, SkeletonSmall } from "Components/simpleSkeleton"

const CashFlowTableSkeleton: React.FC = () => {
    const dummyColumnSource = Array.from(Array(5).keys())

    const initialColumns: ColumnsType = [
        {
            title: "Base forecast / scenario",
            dataIndex: "rowTitle",
            key: "rowTitle",
            width: 250,
            fixed: "left",
            render: () => <SkeletonMedium />,
        },
        ...dummyColumnSource.map((v) => ({
            title: <SkeletonMedium />,
            dataIndex: `skeleton_${v}`,
            key: `skeleton_${v}`,
            width: 250,
            render: () => (
                <div className="h-40 content-center">
                    <div>
                        <SkeletonSmall />
                    </div>
                    <div className="mt-12">
                        <SkeletonSmall />
                    </div>
                </div>
            ),
        })),
    ]

    const dummyDataSource = useMemo(() => {
        const dataSource: Array<any> = []
        for (const _i of dummyColumnSource) {
            const row: any = {}
            for (const col of initialColumns) {
                row[col.key!] = 0
            }
            dataSource.push(row)
        }
        return dataSource
    }, [])

    return (
        <Card>
            <Table className="overflow-auto " size="small" columns={initialColumns} dataSource={dummyDataSource} pagination={false} scroll={{ y: 500 }} />
        </Card>
    )
}

export default CashFlowTableSkeleton
