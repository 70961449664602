import { ExclamationCircleOutlined } from "@ant-design/icons"
import { Form, Switch, Tooltip } from "antd"
import * as React from "react"
import { AlertTriangle } from "react-feather"
import { useTranslation } from "react-i18next"

import { getTooltipPopupContainer } from "Shared/utils/helper.utils"

export type AdvancedNotificationType = {
    name: string
    title: string
    input?: any
    tooltip?: string
}
interface AdvancedNotificationInterface {
    advancedNotification: Array<AdvancedNotificationType>
    isWalletBalanceLimitNegative: boolean
}
const AdvancedNotificationContainer = ({ advancedNotification, isWalletBalanceLimitNegative }: AdvancedNotificationInterface) => {
    const { t } = useTranslation()

    const DynamicComponent = (Component: any, props: any) => <Component {...props} />

    return (
        <div className="mb-50">
            <h3>{t("label:notifications.advanced_notification")}</h3>

            <ul className="description-list">
                {advancedNotification.map((section, index) => (
                    <li key={index} className="flex items-center h-80 md:h-40">
                        <div className="flex flex-1 items-center">
                            <span className="title font-normal flex items-center justify-start">
                                {section.title}
                                {section.tooltip && (
                                    <Tooltip getPopupContainer={getTooltipPopupContainer} title={section.tooltip} className="p-5">
                                        <ExclamationCircleOutlined />
                                    </Tooltip>
                                )}
                                {section.input && (
                                    <>
                                        <Form.Item name={section.input.name} className="h-10 ml-4">
                                            {DynamicComponent(section.input.component, section.input.props)}
                                        </Form.Item>
                                        {isWalletBalanceLimitNegative && (
                                            <Tooltip title={t("label:notifications.wallet_balance_negative_amount_warning")}>
                                                <AlertTriangle fill="#FBAC3B" size={18} stroke="#FFFFFF" className="ml-2 mt-2" />
                                            </Tooltip>
                                        )}
                                    </>
                                )}
                            </span>
                        </div>
                        <Form.Item className="mt-auto mb-auto" name={section.name} valuePropName="checked">
                            <Switch className="mr-10 pb-2" />
                        </Form.Item>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default AdvancedNotificationContainer
