import { CostCenter, Expense, ExpenseKindEnum, ExpenseStatusEnum, SubscriptionTypeEnum } from "@finway-group/shared/lib/models"
import { Button, Card, Modal } from "antd"
import moment from "moment"
import React, { useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import InfiniteScroll from "react-infinite-scroller"

import Loading from "Components/loading"
import RequestPreview from "Components/requestPreview"
import { useShouldRefetchExpenses } from "Shared/hooks/expense.hooks"
import { AnalyticsService, NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

interface CostCenterAndIntervalInterface {
    costCenter: CostCenter
    interval: string // YYYY-MM
}

interface ExpenseReportModalInterface {
    selectedCostCenterAndInterval: CostCenterAndIntervalInterface
    isShowing: boolean
    onCancel: (state: boolean) => void
}

const ExpenseReportModal: React.FC<ExpenseReportModalInterface> = ({ selectedCostCenterAndInterval, isShowing, onCancel }) => {
    const { t } = useTranslation()

    const startTime = useMemo(
        () => (selectedCostCenterAndInterval?.interval ? moment(selectedCostCenterAndInterval.interval).startOf("month") : undefined),
        [selectedCostCenterAndInterval],
    )
    const endTime = useMemo(
        () => (selectedCostCenterAndInterval?.interval ? moment(selectedCostCenterAndInterval.interval).endOf("month") : undefined),
        [selectedCostCenterAndInterval],
    )

    const [isLoading, setIsLoading] = useStateIfMounted(false)
    const [expenses, setExpenses] = useStateIfMounted<Array<Expense<string>>>([])
    const [currentPage, setCurrentPage] = useStateIfMounted(1)
    const [totalPages, setTotalPages] = useStateIfMounted(0)
    const [totalDocs, setTotalDocs] = useStateIfMounted(0)

    const shouldRefetch = useShouldRefetchExpenses()

    const loadMore = expenses.length < totalDocs

    const fetchExpenses = async (page: number, append = false) => {
        try {
            setIsLoading(true)
            const startDate: string = startTime ? startTime.toISOString() : ""
            const endDate: string = endTime ? endTime.toISOString() : ""
            const query = `&kind[ne]=${ExpenseKindEnum.TRIP_FOLDER}&status[in]=${ExpenseStatusEnum.PAID},${ExpenseStatusEnum.DONE}&deleted[is]=false&subscriptionType[ne]=${SubscriptionTypeEnum.INITIAL_REQUEST}`
            const {
                docs: newExpenses,
                totalPages,
                totalDocs,
            } = await AnalyticsService.fetchPerformanceExpenses(selectedCostCenterAndInterval.costCenter._id, page, 10, query, startDate, endDate)

            setTotalPages(totalPages)
            setTotalDocs(totalDocs)
            setCurrentPage(page)
            setExpenses([...(append ? expenses : []), ...newExpenses])
        } catch (err) {
            NotificationService.send(NotificationTypeEnum.ERROR, t("error:request.fetch.title"), t("error:request.fetch.title"))
        }
        setIsLoading(false)
    }

    const onLoadMorePages = () => {
        if (currentPage !== totalPages) {
            fetchExpenses(currentPage + 1, true)
        }
    }

    useEffect(() => {
        let isFetching = true

        selectedCostCenterAndInterval.costCenter && isFetching && fetchExpenses(1, false)

        return () => {
            isFetching = false
        }
    }, [selectedCostCenterAndInterval, shouldRefetch])

    return (
        <Modal
            visible={isShowing}
            maskClosable={false}
            title={<div className="max-w-400 overflow-x-auto whitespace-no-wrap no-scrollbar">{selectedCostCenterAndInterval?.costCenter?.name}</div>}
            onCancel={() => onCancel(false)}
            className="ant-modal--small"
            footer={[
                <Button key="back" onClick={() => onCancel(false)}>
                    {t("action:cancel")}
                </Button>,
            ]}
            destroyOnClose
            closable
        >
            {isLoading ? (
                <Loading />
            ) : (
                <div id="report-expense-container" className="overflow-auto">
                    {expenses.length > 0 ? (
                        <div className="card-list" id="report-expense-list">
                            <InfiniteScroll loadMore={onLoadMorePages} hasMore={!isLoading && loadMore}>
                                {expenses.map((request, index) => (
                                    <RequestPreview costCenterViewIds={selectedCostCenterAndInterval.costCenter._id} key={index} request={request} hideActionButtons />
                                ))}
                            </InfiniteScroll>
                        </div>
                    ) : (
                        <Card>{t("missing:expense_data")}</Card>
                    )}
                </div>
            )}
        </Modal>
    )
}

export default ExpenseReportModal
