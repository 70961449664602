import React from "react"
import { Link, Route, RouteProps } from "react-router-dom"

// Component imports

export const ErrorRoute: React.FC<RouteProps> = (props) => (
    <Route {...props}>
        <div className={"min-w-screen min-h-screen"}>
            <header className={"bg-primary w-full fixed z-50 top-0"}>
                <div className="container">
                    <div className="flex justify-between items-center h-56 py-10">
                        <Link to="/expenses">
                            <img src={"./images/logo.svg"} className="w-120 sm:w-140" alt={"FINWAY"} />
                        </Link>
                    </div>
                </div>
            </header>
            <main className="">
                <div className="container">
                    <div className="min-h-screen flex flex-col sm:flex-row justify-center items-center">
                        <div className="mb-20 sm:mb-0 sm:mr-80">
                            <img src={"./images/error.png"} className="max-w-200 xs:max-w-300 w-full" alt={"Error"} />
                        </div>
                        <div>{props.children}</div>
                    </div>
                </div>
            </main>
        </div>
    </Route>
)
