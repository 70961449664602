import Icon from "@ant-design/icons"
import React from "react"

const discountSvg = () => (
    <svg width="19" height="15" viewBox="0 0 19 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M15.7826 7.28261C15.7826 6.05797 16.7754 5.06522 18 5.06522V2.1087C18 1.49638 17.5036 1 16.8913 1H2.1087C1.49638 1 1 1.49638 1 2.1087V5.06522C2.22464 5.06522 3.21739 6.05797 3.21739 7.28261C3.21739 8.50724 2.22464 9.5 1 9.5V12.4565C1 13.0688 1.49638 13.5652 2.1087 13.5652H16.8913C17.5036 13.5652 18 13.0688 18 12.4565V9.5C16.7754 9.5 15.7826 8.50724 15.7826 7.28261Z"
            stroke="currentColor"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path d="M11.7189 3.9565L7.28412 10.6087" stroke="currentColor" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path
            d="M7.65188 6.91302C8.26419 6.91302 8.76057 6.25118 8.76057 5.43476C8.76057 4.61834 8.26419 3.9565 7.65188 3.9565C7.03956 3.9565 6.54318 4.61834 6.54318 5.43476C6.54318 6.25118 7.03956 6.91302 7.65188 6.91302Z"
            stroke="currentColor"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M11.3462 10.6087C11.9585 10.6087 12.4548 9.94689 12.4548 9.13047C12.4548 8.31405 11.9585 7.65221 11.3462 7.65221C10.7338 7.65221 10.2375 8.31405 10.2375 9.13047C10.2375 9.94689 10.7338 10.6087 11.3462 10.6087Z"
            stroke="currentColor"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)

const DiscountIcon = (props: any) => <Icon component={discountSvg} {...props} />

export default DiscountIcon
