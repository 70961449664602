import { BudgetPeriodEnum, CardStatusEnum } from "@finway-group/shared/lib/models"
import localForage from "localforage"
import { applyMiddleware, createStore } from "redux"
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly"
import { createMigrate, persistReducer, persistStore } from "redux-persist"
import thunk, { ThunkAction, ThunkDispatch } from "redux-thunk"

import { PeriodRangeOptionEnum } from "Features/pages/costCenters/costCenterDetails/costCenterPeriodOption.interface"

import { AnalyticsActions } from "./actions/analytics/analyticsTypes"
import { AuthActions } from "./actions/auth/authTypes"
import { CompanyActions } from "./actions/company/companyTypes"
import { CostCenterActions } from "./actions/costCenter/costCenterTypes"
import { CsvTemplateActions } from "./actions/csvTemplates/csvTemplateTypes"
import { EmployeeActions } from "./actions/employee/employeeTypes"
import { ExpenseActions } from "./actions/expense/expenseTypes"
import { FetcherActions } from "./actions/fetcher/fetcherTypes"
import { ImportActions } from "./actions/import/importTypes"
import { PaymentActions } from "./actions/payment/paymentTypes"
import { TableActions } from "./actions/tables/tableTypes"
import { VendorActions } from "./actions/vendor/vendorTypes"
import { creditorsTransform } from "./reducers/creditorReducer"
import rootReducer from "./reducers/rootReducer"
import { initialTableConfigData } from "./reducers/tableConfigReducer"
import { createInitialTable, initialTablesState } from "./reducers/tableReducer"
import { RootState } from "./rootState.interface"

export type StoreActions =
    | AuthActions
    | CompanyActions
    | CostCenterActions
    | EmployeeActions
    | ExpenseActions
    | FetcherActions
    | PaymentActions
    | VendorActions
    | AnalyticsActions
    | TableActions
    | ImportActions
    | CsvTemplateActions

export type ThunkResult<R> = ThunkAction<Promise<R>, RootState, undefined, StoreActions>

export type ThunkDispatchResult = ThunkDispatch<RootState, undefined, StoreActions>

const db = localForage.createInstance({
    name: "IndexedDB",
})

/**
 * Persist the redux state using IndexedDB
 */
const initializeStorage = () => ({
    db,
    getItem: db.getItem,
    setItem: db.setItem,
    removeItem: db.removeItem,
})

export const clearStore = () => {
    db.clear()
}

const migrations = {
    0: (state: any) => ({
        ...state,
        analytics: {
            ...state.analytics,
            costCenterBudgetUsageBarGraphOptions: {
                startDate: "",
                endDate: "",
                periodRangeOption: PeriodRangeOptionEnum.LAST_12_PERIODS,
                viewPeriod: BudgetPeriodEnum.MONTHLY,
            },
        },
        company: {
            items: [],
            item: {},
        },
        workflows: {
            items: [],
            item: undefined,
        },
        tableConfig: {
            ...state.tableConfig,
            todoPurchaseApprovalTable: initialTableConfigData,
            todoInvoiceApprovalTable: initialTableConfigData,
            todoInvoiceAndPurchaseApprovalTable: initialTableConfigData,
            todoDocsNeededTable: initialTableConfigData,
            todoApprovalPendingTable: initialTableConfigData,
            workflowTable: initialTableConfigData,
            archiveTable: initialTableConfigData,
        },
    }),
    // table refactor update
    1: (state: any) => {
        const newTableConfig = {
            ...state.tableConfig,
            inboxInvoiceTable: state.tableConfig.invoiceInboxTable,
            inboxInvoiceModalInvoicesTable: state.tableConfig.invoiceInboxModalInvoicesTable,
            inboxInvoiceModalExpensesTable: state.tableConfig.invoiceInboxModalExpensesTable,
            payAndExportToBePaidTable: initialTableConfigData,
            payAndExportToBeExportedTable: initialTableConfigData,
            doneTable: initialTableConfigData,
        }
        delete newTableConfig.invoiceInboxTable
        delete newTableConfig.invoiceInboxModalInvoicesTable
        delete newTableConfig.invoiceInboxModalExpensesTable
        delete newTableConfig.payExportTable
        delete newTableConfig.allTable
        return {
            ...state,
            tableConfig: newTableConfig,
            tables: initialTablesState,
        }
    },
    // update card table initial values
    2: (state: any) => ({
        ...state,
        tables: { ...state.tables, cardTable: { ...state.tables.cardTable, data: { ...state.tables.cardTable.data, filter: { status: CardStatusEnum.ACTIVE } } } },
    }),
    // add transactionCategory to store and transactionCategoriesTable to tables store
    3: (state: any) => ({
        ...state,
        transactionCategories: { items: [] },
        tables: { ...state.tables, transactionCategoriesTable: createInitialTable() },
    }),
    // add card transaction table to table store
    4: (state: any) => ({
        ...state,
        tables: { ...state.tables, cardTransactionsTable: initialTableConfigData },
    }),
    // add all tab table to table store
    5: (state: any) => ({
        ...state,
        tables: { ...state.tables, allRequestsTable: createInitialTable() },
    }),
    // add expense transaction table to table store
    6: (state: any) => ({
        ...state,
        tables: { ...state.tables, expenseTransactionsTable: createInitialTable({}, 5) },
    }),
    // add transactionArchiveTable to table store
    7: (state: any) => ({
        ...state,
        tables: { ...state.tables, transactionArchiveTable: createInitialTable() },
    }),
    // add transactionArchiveTable to table store
    8: (state: any) => ({
        ...state,
        tables: { ...state.tables, todoInvoiceAndPurchaseApprovalTable: createInitialTable() },
    }),
    // add draftTripFolderTable and attachExpenseTable to table store for trip folder specific tables
    9: (state: any) => ({
        ...state,
        tables: { ...state.tables, draftTripFolderTable: createInitialTable(), attachExpenseTable: createInitialTable },
    }),
    // change type of isWeavrAuthenticated to boolean
    10: (state: any) => ({
        ...state,
        auth: { ...state.auth, isWeavrAuthenticated: false },
    }),
    // add exportHistoryTable
    11: (state: any) => ({
        ...state,
        tables: { ...state.tables, exportHistoryTable: createInitialTable() },
    }),
    12: (state: any) => ({
        state,
        tables: { ...state.tables, transactionAllTable: createInitialTable() },
    }),
}

const persistConfig = {
    key: "root",
    version: 12,
    storage: initializeStorage(),
    transforms: [creditorsTransform],
    // TODO: re-enable if needed
    // stateReconciler: autoMergeLevel2, // see "Merge Process" section for details.
    migrate: createMigrate(migrations),
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const middleware = [thunk]

const traceComposeEnhancer = composeWithDevTools({ trace: true, traceLimit: 25 })

const enhancers = process.env.NODE_ENV === "production" ? applyMiddleware(...middleware) : traceComposeEnhancer(applyMiddleware(...middleware))

const store = createStore(persistedReducer, enhancers)

export const persistor = persistStore(store)

export default store
