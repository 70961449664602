import i18n from "i18next"
import moment from "moment"

import { isEmptyString, isWhitespaceOnly } from "Shared/utils/helper.utils"

const SEPA_SUBJECT_LINE_CHAR_LIMIT = 140

const PaymentConfirmationRules = (isScheduledPayment: boolean) => ({
    paymentExecutionDate: [
        {
            validator: (_rule: any, value: moment.Moment) => {
                if (isScheduledPayment && value.startOf("day").isBefore(moment().startOf("day"))) {
                    return Promise.reject(i18n.t("validation:cannot_be_in_past"))
                }

                return Promise.resolve()
            },
        },
    ],
    noSymbolAllowed: [
        {
            validator: (_rule: any, value: string) => {
                if (isEmptyString(value)) return Promise.reject(i18n.t("validation:required"))
                if (isWhitespaceOnly(value)) return Promise.reject(i18n.t("validation:no_whitespace_only"))
                if (/^[A-Za-z0-9.,!@#$%^&*-+=_\-?\/\\()<>\[\]:;"'äöüÄÖÜß ]+$/g.test(value)) return Promise.resolve()
                return Promise.reject(i18n.t("validation:subject_contains_unsupported_character"))
            },
        },
    ],
    subjectLineCharacterLimit: [
        {
            validator: (_rule: any, value: string) => {
                if (value.length > SEPA_SUBJECT_LINE_CHAR_LIMIT) {
                    return Promise.reject(i18n.t("validation:max.string", { max: SEPA_SUBJECT_LINE_CHAR_LIMIT }))
                }
                return Promise.resolve()
            },
        },
    ],
})

export default PaymentConfirmationRules
