import { COLOR } from "@finway-group/shared/lib/consts"
import { ImportResult, ImportResultTypeEnum } from "@finway-group/shared/lib/models"
import { Button, Col, Divider, Row } from "antd"
import { RcFile } from "antd/lib/upload"
import Dragger from "antd/lib/upload/Dragger"
import React, { useCallback, useEffect } from "react"
import { Upload as UploadIcon } from "react-feather"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { v4 as uuidv4 } from "uuid"

import DownloadDataImportSheetButton from "Components/actionButton/downloadDataImportSheet.button"
import { TrashIcon } from "Components/icons"
import { CrossRedCircled } from "Components/icons/crossRedCircled"
import { SpreadSheetFileGreenIcon } from "Components/icons/spreadsheetFileGreenIcon"
import TickGreenCircled from "Components/icons/tickGreenCircled"
import Loading from "Components/loading"
import { ImportResults } from "Components/modals/spreadsheetDataUpload/importResults"
import { API_URL } from "Shared/config/consts"
import { useImportInProgress, useImportResultId } from "Shared/hooks/import.hooks"
import { FileService, ImportService, NotificationService } from "Shared/services"
import { getDataUploadTotalCounts, requestDataCreationFromSpreadSheet, requestDataValidationFromSpreadSheet } from "Shared/store/actions/import/importActions"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

interface ImportDataTabInterface {
    onCancel: () => void
    importResultId?: string
    onValidate?: (importType: ImportResultTypeEnum) => any
    onImportConfirm?: (importType: ImportResultTypeEnum) => any
}

export const ImportDataTab: React.FC<ImportDataTabInterface> = ({ onCancel, importResultId: importResultIdFromProps, onValidate, onImportConfirm }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const isImportInProgress = useImportInProgress()
    const importResultIdFromStore = useImportResultId()
    const importResultId = importResultIdFromProps || importResultIdFromStore

    // Local state
    const [importResult, setImportResult] = useStateIfMounted<ImportResult | undefined>(undefined)
    const [file, setFile] = useStateIfMounted<RcFile | undefined>(undefined)
    const [fileIcon, setFileIcon] = useStateIfMounted<"success" | "failure" | undefined>(undefined)
    const [isFetchingImportResult, setIsFetchingImportResult] = useStateIfMounted<boolean>(false)
    const [buttonsState, setButtonsState] = useStateIfMounted({ validateData: { enabled: false, loading: false }, confirmUpload: { enabled: false, loading: false } })

    useEffect(() => {
        fetchImportResultData(importResultId)
    }, [importResultId])

    // Disable buttons while upload is in progress. When upload ends, enable validate data button.
    useEffect(() => {
        if (isImportInProgress) {
            setButtonsState({ validateData: { enabled: false, loading: false }, confirmUpload: { enabled: false, loading: false } })
        }
    }, [isImportInProgress])

    const handleHide = () => {
        onCancel()
    }

    const checkIfDataCreationIsAllowed = useCallback(
        (importResult?: ImportResult) => {
            if (!importResult) return false
            const validationTotals = getDataUploadTotalCounts(importResult)
            return validationTotals.errorsList === 0 && validationTotals.created + validationTotals.ignored + validationTotals.restored !== 0
        },
        [importResult],
    )

    const fetchImportResultData = async (importResultId?: string) => {
        try {
            setIsFetchingImportResult(true)
            const importResult: ImportResult | undefined = importResultId
                ? await ImportService.fetchImportResult(importResultId)
                : (await ImportService.fetchLatestImportResults({ limit: 1 })).docs[0]

            if (!importResult) return

            const shouldAllowDataCreation = checkIfDataCreationIsAllowed(importResult)

            setFileIcon(shouldAllowDataCreation ? "success" : "failure")

            if (importResult?.importResultType === ImportResultTypeEnum.CREATION) {
                if (importResultId) setImportResult(importResult)
                if (file) setFile(undefined)
            } else if (importResult?.importResultType === ImportResultTypeEnum.VALIDATION) {
                setImportResult(importResult)
                if (file) {
                    setButtonsState({
                        validateData: { enabled: true, loading: false },
                        confirmUpload: {
                            enabled: shouldAllowDataCreation && importResult.importFile?.displayName === file.name && importResult.importFile?.size === file.size,
                            loading: false,
                        },
                    })
                } else if (importResult?.importFile?.url) {
                    const { data: binary } = await FileService.downladSignedUrl({ url: importResult.importFile.url })
                    const newFile: Partial<RcFile> = new File([binary], importResult.importFile.displayName, {
                        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    })
                    newFile.uid = uuidv4() // required for Dragger of antd
                    setFile(newFile as RcFile)

                    setButtonsState({ validateData: { enabled: true, loading: false }, confirmUpload: { enabled: shouldAllowDataCreation, loading: false } })
                }
            }
        } catch (err) {
            setImportResult(undefined)
            setFile(undefined)
        } finally {
            setIsFetchingImportResult(false)
        }
    }

    const handleValidateDataClick = async (file: RcFile) => {
        try {
            setButtonsState({ validateData: { enabled: false, loading: true }, confirmUpload: { enabled: false, loading: false } })
            onValidate?.(ImportResultTypeEnum.VALIDATION)
            await dispatch(requestDataValidationFromSpreadSheet(file))
        } catch (err) {
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:data_upload"))
            handleHide()
        } finally {
            setButtonsState({ validateData: { enabled: false, loading: false }, confirmUpload: { enabled: false, loading: false } })
        }
    }

    const handleConfirmDataImportClick = async (file: RcFile) => {
        try {
            setButtonsState({ validateData: { enabled: false, loading: false }, confirmUpload: { enabled: false, loading: true } })
            onImportConfirm?.(ImportResultTypeEnum.CREATION)
            await dispatch(requestDataCreationFromSpreadSheet(file))
        } catch (err) {
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:data_upload"))
            handleHide()
        } finally {
            setButtonsState({ validateData: { enabled: false, loading: false }, confirmUpload: { enabled: false, loading: false } })
        }
    }

    const handleTrashIconClick = () => {
        setFile(undefined)
        setButtonsState({ confirmUpload: { enabled: false, loading: false }, validateData: { enabled: false, loading: false } })
    }

    const renderFilePreview = (fileName: string, resultIcon: "success" | "failure" | undefined) => (
        <div className="flex items-center justify-between h-120 p-30 border border-4 border-slate-400 border-dashed animation-appear">
            <div className="flex items-center gap-20">
                <SpreadSheetFileGreenIcon />
                {fileName} {resultIcon === "success" ? <TickGreenCircled /> : resultIcon === "failure" ? <CrossRedCircled /> : <></>}
            </div>
            <Button className="w-34 p-0" onClick={handleTrashIconClick}>
                <TrashIcon />
            </Button>
        </div>
    )

    const renderDragger = () => (
        <Dragger
            action={`${API_URL}/files`}
            headers={{ Authorization: `Bearer ${localStorage.accessToken}` }}
            multiple={false}
            showUploadList={true}
            fileList={file ? [file] : []}
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            onRemove={() => {
                setFile(undefined)
                setButtonsState({ confirmUpload: { enabled: false, loading: false }, validateData: { enabled: false, loading: false } })
            }}
            beforeUpload={(file) => {
                if (!FileService.checkFileSizeAndHandleError(file)) return false
                setFile(file)

                const shouldAllowDataCreation = checkIfDataCreationIsAllowed(importResult)
                // Check if the uploaded file is probably the file that passed the validation before. If it is, then enable the confirm upload button.
                if (
                    importResult?.importResultType === ImportResultTypeEnum.VALIDATION &&
                    importResult.importFile?.displayName === file.name &&
                    importResult.importFile.size === file.size &&
                    shouldAllowDataCreation
                ) {
                    setButtonsState({ validateData: { enabled: true, loading: false }, confirmUpload: { enabled: true, loading: false } })
                    setFileIcon("success")
                } else {
                    setButtonsState({ validateData: { enabled: true, loading: false }, confirmUpload: { enabled: false, loading: false } })
                    setFileIcon(undefined)
                }
                return false
            }}
        >
            <div className="animation-appear" style={{ height: "88px" }}>
                <i className="el-icon-upload" />
                <div className="el-upload__text">
                    <Button className="mt-10 mr-8">
                        <UploadIcon />
                        <p className="inline-block">{t("action:document")}</p>
                    </Button>
                    <br />
                    <small>{t("action:or_drop_files")}</small>
                </div>
            </div>
        </Dragger>
    )

    const renderActionButtonsFooter = () => (
        <div className="flex justify-around gap-20 w-full">
            <Button
                name="Validate data"
                type="primary"
                disabled={!buttonsState.validateData.enabled}
                style={{ backgroundColor: !buttonsState.validateData.enabled ? COLOR.finway["green-light4"] : COLOR.finway["green-light2"] }}
                className="w-280"
                onClick={() => file && handleValidateDataClick(file)}
                loading={buttonsState.validateData.loading}
            >
                {t("action:validate_data")}
            </Button>

            <Button
                name="Confirm data upload"
                type="primary"
                disabled={!buttonsState.confirmUpload.enabled}
                style={{ backgroundColor: !buttonsState.confirmUpload.enabled ? COLOR.finway["green-light4"] : COLOR.finway["green-light2"] }}
                className="w-280"
                onClick={() => file && handleConfirmDataImportClick(file)}
                loading={buttonsState.confirmUpload.loading}
            >
                {t("action:confirm_data_import")}
            </Button>
        </div>
    )

    return (
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <p>{t("info:download_the_sheet_file_template")}</p>
            </Col>

            <Col span={24}>
                <DownloadDataImportSheetButton />
            </Col>

            {isFetchingImportResult ? (
                <Loading className="w-full h-500" />
            ) : (
                <>
                    <Divider className="my-10" />

                    {importResult && (
                        <Col span={24}>
                            <ImportResults importResult={importResult} />
                        </Col>
                    )}

                    <Divider className="my-10" />

                    <Col className="h-full" span={24}>
                        {file ? renderFilePreview(file.name, fileIcon) : renderDragger()}
                    </Col>

                    <Divider className="my-10" />

                    {renderActionButtonsFooter()}
                </>
            )}
        </Row>
    )
}
