/* eslint-disable no-console */
import { AccessTokenType, CollectionNameEnum, DocumentChangeInterface } from "@finway-group/shared/lib/models"
import io, { Socket } from "socket.io-client"

import { invalidateNotifications } from "Shared/queries/notification.queries"
import { AuthnService, LocalStorageService } from "Shared/services"
import store from "Shared/store"
import { updateAnalytics } from "Shared/store/actions/analytics/analyticsActions"
import { delay } from "Shared/utils/helper.utils"
import { isNotification } from "Shared/utils/navbarActivityTemplates.utils"

import {
    subscribeToBankConnection,
    subscribeToCompanySettings,
    subscribeToCorporate,
    subscribeToCostCenter,
    subscribeToCostCenter2,
    subscribeToCreditCards,
    subscribeToCurrencyExchange,
    subscribeToEmployee,
    subscribeToExpenseAccount,
    subscribeToExpenseTag,
    subscribeToExpenses,
    subscribeToFilter,
    subscribeToPaymentSettings,
    subscribeToRole,
    subscribeToTax,
    subscribeToTeam,
    subscribeToTransactions,
    subscribeToVendor,
    subscribeToWallet,
} from "."
import { subscribeToAuth } from "./authSocket.service"
import { subscribeToCompanySettingsCallbacks } from "./companySettingsSocket.service"
import { subscribeToCostCenterCallbacks } from "./costCenterSocket.service"
import { subscribeToCreditCardCallbacks } from "./creditCardSocket.service"
import subscribeToCsvTemplate from "./csvTemplatesSocket.service"
import { subscribeToExpenseCallbacks } from "./expenseSocket.service"
import { subscribeToExportHistoryCallbacks } from "./exportHistorySocket.service"
import { isIncomingFilterEqualToLocal } from "./filterSocket.service"
import { subscribeToImportResult } from "./importResultSocket.service"
import { subscribeToInboxInvoiceCallbacks, subscribeToInboxInvoices } from "./inboxInvoiceSocket.service"
import subscribeToTenant from "./tenantSocket.service"
import { subscribeToTransactionCategory, subscribeToTransactionCategoryCallbacks } from "./transactionCategorySocket.service"
import { subscribeToTransactionsCallbacks } from "./transactionSocket.service"
import { subscribeToVendorCallbacks } from "./vendorSocket.service"
import { subscribeToWorkflow } from "./workflowSocket.service"

class SocketService {
    private socket: typeof Socket
    private onSuccessInstance?: () => void
    private onErrorInstance?: (state: string) => void

    private loggedInUserId = ""
    private isReconnecting = false
    private location = ""

    private dispatch = store.dispatch

    public connect({ loggedInUserId, onError, onSuccess }: { loggedInUserId: string; onError?: (state: string) => void; onSuccess?: () => void }) {
        this.loggedInUserId = loggedInUserId
        this.onErrorInstance = onError
        this.onSuccessInstance = onSuccess

        console.log(`[Socket] Connecting: socketId=${this.socket?.id} userId=${this.loggedInUserId}`)

        const self = this
        let connection: any
        if (!this.socket || !this.socket.connected) {
            connection = io.connect(`${process.env.REACT_APP_API_SYNC}/dashboard`, {
                query: `auth_token=${localStorage.accessToken}`,
                transports: ["websocket"],
                secure: true,
                timeout: 60000,
            })

            connection.on("reconnect_attempt", () => {
                connection.io.opts.transports = ["websocket", "polling"]
            })

            connection.on("connect_error", (err: any) => {
                console.warn(`[Socket] Event connect_error: socketId=${connection.id} userId=${loggedInUserId}: ${err}`)

                onError?.(err)
            })

            connection.on("connect_failed", (err: any) => {
                console.warn(`[Socket] Event connect_failed reconnecting: socketId=${connection.id} userId=${loggedInUserId}`)

                onError?.(err)
            })

            connection.on("reconnect_error", (err: any) => {
                console.warn(`[Socket] Event reconnect_error retry in a couple seconds: socketId=${connection.id} userId=${loggedInUserId}: ${err}`)

                onError?.(err)
            })

            connection.on("connect", () => {
                console.debug(`[Socket] Event connect socketId=${connection.id} userId=${loggedInUserId} `)
                self.socket = connection
                onSuccess?.()
            })

            // Connection succeeded
            connection.on("success", (data: { message: string; user: AccessTokenType }) => {
                console.debug(`[Socket] Event success socketId=${connection.id} userId=${loggedInUserId}: ${data.message}`)
                self.socket = connection
                onSuccess?.()
                self.subscribeToChange()
            })

            connection.on("error", async (err: { [key: string]: string }) => {
                onError?.(err.message)
                if (LocalStorageService.isAuthorized() && err.message === "Token expired") {
                    if (!this.isReconnecting) {
                        this.isReconnecting = true
                        await self.reconnect({ refreshToken: true })
                    }
                    return
                }

                connection.disconnect()
            })

            connection.on("disconnect", () => {
                if (self.socket?.nsp && !LocalStorageService.isAuthorized()) {
                    self.disconnect("LoggingOut")
                    return
                }

                onError?.("disconnected")
            })
        } else if (this.socket.connected) {
            console.log(`[Socket] Connecting: status=${self.socket.connected}`)
            onSuccess?.()
        }

        return connection
    }

    // Check if socket reconnection is firing then delete this
    reconnect = async ({ refreshToken }: { refreshToken: boolean }) => {
        if (!LocalStorageService.isAuthorized()) {
            this.disconnect("No Access Token")
            return
        }

        console.log(`[Socket] Reconnecting: socketId=${this.socket?.id} userId=${this.loggedInUserId} refreshToken=${refreshToken}`)

        if (refreshToken) await AuthnService.silentRefresh()
        await delay(3000)
        this.socket?.connect()
        await delay(2000)
        if (!this.socket?.connected) {
            this.connect({ loggedInUserId: this.loggedInUserId, onError: this.onErrorInstance, onSuccess: this.onSuccessInstance })
        }

        await delay(2000)
        this.isReconnecting = false
    }

    setLocation = (location: string) => {
        this.location = location
    }

    checkAndNotify = (createdBy: string | number | Array<any> | Date, documentId: string | number | Array<any> | Date, shouldFetchActivities: boolean): boolean => {
        const notOwnAction = this.loggedInUserId !== createdBy
        const isAboutMe = this.loggedInUserId === documentId
        if (notOwnAction || isAboutMe) {
            if (shouldFetchActivities) invalidateNotifications()
        }
        return notOwnAction || isAboutMe
    }

    isMyFilterUpdate = (data: any) => this.loggedInUserId === data.fullDocument.metaData.updatedFields.user

    subscribeToChange() {
        const subscribeToExpenseOrSubscription = (data: DocumentChangeInterface) => {
            if (this.checkAndNotify(data.fullDocument.createdBy, data.fullDocument.documentId, isNotification(data.fullDocument))) subscribeToExpenses(this.dispatch, data)
            else subscribeToExpenseCallbacks(this.dispatch, data)
        }

        // socket.io/aws idle timeout handler
        this.socket.on("ping", (data: any) => {
            console.debug(`[Socket] heartbeat: ${JSON.stringify(data)}`)
            this.socket.emit("pong", data)
        })

        this.socket.on(CollectionNameEnum.EXPENSE, (data: DocumentChangeInterface) => {
            subscribeToExpenseOrSubscription(data)
        })
        this.socket.on(CollectionNameEnum.SUBSCRIPTION, (data: DocumentChangeInterface) => {
            subscribeToExpenseOrSubscription(data)
        })

        this.socket.on(CollectionNameEnum.MATCHING_TRANSACTION, (data: DocumentChangeInterface) => {
            if (this.checkAndNotify(data.fullDocument.createdBy, data.fullDocument.documentId, isNotification(data.fullDocument))) subscribeToTransactions(this.dispatch, data)
            else subscribeToTransactionsCallbacks(this.dispatch, data)
        })

        this.socket.on(CollectionNameEnum.EXPENSE_ACCOUNT, (data: DocumentChangeInterface) => {
            if (this.checkAndNotify(data.fullDocument.createdBy, data.fullDocument.documentId, isNotification(data.fullDocument))) subscribeToExpenseAccount(this.dispatch, data)
        })

        this.socket.on(CollectionNameEnum.COST_CENTER_2, (data: DocumentChangeInterface) => {
            if (this.checkAndNotify(data.fullDocument.createdBy, data.fullDocument.documentId, isNotification(data.fullDocument))) subscribeToCostCenter2(this.dispatch, data)
        })

        this.socket.on(CollectionNameEnum.USER, (data: DocumentChangeInterface) => {
            const shouldDisconnect = subscribeToAuth(data, this.loggedInUserId)
            if (shouldDisconnect) return this.socket.emit("disconnect")

            if (this.checkAndNotify(data.fullDocument.createdBy, data.fullDocument.documentId, isNotification(data.fullDocument)))
                subscribeToEmployee(this.dispatch, data, this.loggedInUserId)
        })

        this.socket.on(CollectionNameEnum.VENDOR, (data: DocumentChangeInterface) => {
            if (this.checkAndNotify(data.fullDocument.createdBy, data.fullDocument.documentId, isNotification(data.fullDocument))) subscribeToVendor(this.dispatch, data)
            else subscribeToVendorCallbacks(data)
        })

        this.socket.on(CollectionNameEnum.COST_CENTER, (data: DocumentChangeInterface) => {
            if (this.checkAndNotify(data.fullDocument.createdBy, data.fullDocument.documentId, isNotification(data.fullDocument))) subscribeToCostCenter(this.dispatch, data)
            else subscribeToCostCenterCallbacks(data)
        })

        this.socket.on(CollectionNameEnum.COMPANY_SETTINGS, (data: DocumentChangeInterface) => {
            if (this.checkAndNotify(data.fullDocument.createdBy, data.fullDocument.documentId, isNotification(data.fullDocument))) subscribeToCompanySettings(this.dispatch, data)
            else subscribeToCompanySettingsCallbacks(this.dispatch, data)
        })

        this.socket.on(CollectionNameEnum.BANK_CONNECTION, (data: DocumentChangeInterface) => {
            subscribeToBankConnection(this.dispatch, data, this.loggedInUserId)
        })

        this.socket.on(CollectionNameEnum.PAYMENT_SETTINGS, (data: DocumentChangeInterface) => {
            subscribeToPaymentSettings(this.dispatch, data)
        })

        this.socket.on(CollectionNameEnum.CURRENCY_EXCHANGE, (data: DocumentChangeInterface) => {
            if (this.checkAndNotify(data.fullDocument.createdBy, data.fullDocument.documentId, isNotification(data.fullDocument))) subscribeToCurrencyExchange(this.dispatch, data)
        })

        this.socket.on(CollectionNameEnum.CARD, (data: DocumentChangeInterface) => {
            if (this.checkAndNotify(data.fullDocument.createdBy, data.fullDocument.documentId, isNotification(data.fullDocument)))
                subscribeToCreditCards(this.dispatch, data, this.loggedInUserId)
            else subscribeToCreditCardCallbacks(data)
        })

        this.socket.on(CollectionNameEnum.WALLET, (data: DocumentChangeInterface) => {
            if (this.checkAndNotify(data.fullDocument.createdBy, data.fullDocument.documentId, isNotification(data.fullDocument))) subscribeToWallet(this.dispatch)
        })

        this.socket.on(CollectionNameEnum.CORPORATE, (data: DocumentChangeInterface) => {
            if (this.checkAndNotify(data.fullDocument.createdBy, data.fullDocument.documentId, isNotification(data.fullDocument))) subscribeToCorporate(this.dispatch, data)
        })

        this.socket.on(CollectionNameEnum.INBOX_INVOICE, (data: DocumentChangeInterface) => {
            if (this.checkAndNotify(data.fullDocument.createdBy, data.fullDocument.documentId, isNotification(data.fullDocument))) subscribeToInboxInvoices(this.dispatch, data)
            else subscribeToInboxInvoiceCallbacks(this.dispatch, data)
        })

        this.socket.on(CollectionNameEnum.TEAM, (data: DocumentChangeInterface) => {
            if (this.checkAndNotify(data.fullDocument.createdBy, data.fullDocument.documentId, isNotification(data.fullDocument))) subscribeToTeam(this.dispatch, data)
        })

        this.socket.on(CollectionNameEnum.TAX, (data: DocumentChangeInterface) => {
            if (this.checkAndNotify(data.fullDocument.createdBy, data.fullDocument.documentId, isNotification(data.fullDocument))) subscribeToTax(this.dispatch, data)
        })

        this.socket.on(CollectionNameEnum.WORKFLOW, (data: DocumentChangeInterface) => {
            if (this.checkAndNotify(data.fullDocument.createdBy, data.fullDocument.documentId, isNotification(data.fullDocument))) subscribeToWorkflow(this.dispatch, data)
        })

        this.socket.on(CollectionNameEnum.IMPORT_RESULT, (data: DocumentChangeInterface) => {
            if (this.location === "/reports") this.dispatch(updateAnalytics())
            subscribeToImportResult(this.dispatch, data, this.loggedInUserId)
        })

        this.socket.on(CollectionNameEnum.TRANSACTION_CATEGORY, (data: DocumentChangeInterface) => {
            if (this.checkAndNotify(data.fullDocument.createdBy, data.fullDocument.documentId, isNotification(data.fullDocument)))
                subscribeToTransactionCategory(this.dispatch, data)
            else subscribeToTransactionCategoryCallbacks(this.dispatch, data)
        })

        this.socket.on(CollectionNameEnum.EXPENSE_TAG, (data: DocumentChangeInterface) => {
            if (this.checkAndNotify(data.fullDocument.createdBy, data.fullDocument.documentId, isNotification(data.fullDocument))) {
                subscribeToExpenseTag(this.dispatch, data)
            }
        })

        this.socket.on(CollectionNameEnum.ROLE, (data: DocumentChangeInterface) => {
            if (this.checkAndNotify(data.fullDocument.createdBy, data.fullDocument.documentId, isNotification(data.fullDocument))) subscribeToRole(this.dispatch, data)
        })

        this.socket.on(CollectionNameEnum.CSV_TEMPLATE, (data: DocumentChangeInterface) => {
            if (this.checkAndNotify(data.fullDocument.createdBy, data.fullDocument.documentId, isNotification(data.fullDocument))) subscribeToCsvTemplate(this.dispatch, data)
        })

        this.socket.on(CollectionNameEnum.FILTER, (data: any) => {
            if (this.isMyFilterUpdate(data) && !isIncomingFilterEqualToLocal(data)) {
                subscribeToFilter(data)
            }
        })
        this.socket.on(CollectionNameEnum.EXPORT_HISTORY, (data: DocumentChangeInterface) => {
            // ! this uses only the callbacks (bc all export history objects are user specific)
            if (!this.checkAndNotify(data.fullDocument.createdBy, data.fullDocument.documentId, isNotification(data.fullDocument))) {
                subscribeToExportHistoryCallbacks(this.dispatch, data)
            }
        })
        this.socket.on(CollectionNameEnum.TENANT, (data: DocumentChangeInterface) => {
            subscribeToTenant(data)
        })
    }

    // disconnect - used when unmounting
    public async disconnect(reason: any) {
        console.warn(`[Socket] Disconnecting: reason=${reason}`)

        this.socket?.removeAllListeners()
        this.socket?.disconnect()
        this.socket?.close()
    }
}

export default new SocketService()
