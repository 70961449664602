import { Expense, ItemSplit, PriceSourceEnum, SplitTypeEnum, Tax } from "@finway-group/shared/lib/models"
import { ItemSplitKindEnum } from "@finway-group/shared/lib/models/expense/expenseSplit.model"
import { Col, Form, Row, Space } from "antd"
import { Rule } from "antd/lib/form"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { DebouncedState } from "use-debounce/lib/useDebouncedCallback"

import CurrencyInput from "Components/currencyInput"
import { ExpenseRules } from "Components/forms/rules"
import { ExpenseData } from "Components/modals/expenseCreateForm.context"
import { GetTaxLabel, getDefaultTaxId } from "Shared/utils/helper.utils"
import { GetPopupAnchor } from "Shared/utils/popup.utils"

import FormAmountsMismatchAlert from "./formAmountsMismatchAlert"
import { FormInputElem } from "./formInputAndTextarea"
import FormItemCurrency from "./formItemCurrency"
import FormItemSideButtons from "./formItemSideButtons"
import FormTaxRateSelector from "./formTaxRateSelector"

export interface FormAdditionalExpenseInterface {
    elem: {
        isHospitality: boolean
        isSubscription: boolean
        expense: Expense
        taxValues: Array<Tax>
        name: Array<Rule>
        onNameChange: DebouncedState<(value: string, index: number) => void>
        onNetPriceUpdate: (index: number, netPrice: number) => void
        onTaxRateSplitUpdate: (index: number, taxRateId: string, priceSource: PriceSourceEnum) => void
        onTaxPriceUpdate: (index: number, taxPrice: number, priceSource: PriceSourceEnum) => void
        onGrossPriceUpdate: (index: number, grossPrice: number) => void
        totalNetPrice: Array<Rule>
        getPopupAnchor: GetPopupAnchor
        isAmountsMismatch: boolean
        updateExpense: (data: ExpenseData) => void
        setShouldRecalculateAmounts: (shouldRecalculate: boolean) => void
    }
}

function FormAdditionalExpense({ elem }: FormAdditionalExpenseInterface) {
    const { t } = useTranslation()
    const { totalGrossPrice } = ExpenseRules()

    const {
        expense,
        taxValues,
        isSubscription,
        name,
        onNameChange,
        onNetPriceUpdate,
        onTaxRateSplitUpdate,
        onTaxPriceUpdate,
        onGrossPriceUpdate,
        getPopupAnchor,
        isAmountsMismatch,
        updateExpense,
        setShouldRecalculateAmounts,
        isHospitality,
    } = elem

    if (isSubscription) {
        return null
    }

    const [priceSource, setPriceSource] = useState(PriceSourceEnum.GROSS_PRICE)

    return (
        <>
            <Form.List name="splits">
                {(fields, { add, remove }) => (
                    <div>
                        {/* In oneTimeExpense form, we only want to show the item form if the expense split type is ITEM. */}
                        {(!expense.splitType || expense.splitType === SplitTypeEnum.ITEM) &&
                            fields.map((field, index) => {
                                /**
                                 * If its a hospitality type expense, it can have a tip
                                 * This row will:
                                 * - not have the item name editable (fixed to "Tip"/"Trinkgeld")
                                 * - not have the tax amount editable
                                 */
                                const isTipRow: boolean = (expense.splits[index] as ItemSplit)?.kind === ItemSplitKindEnum.TIP
                                return (
                                    <Space key={index} className="flex mb-3" align="start">
                                        <Row data-testid="splitItemRow" key={index} gutter={[16, 16]} align="top">
                                            <FormInputElem
                                                elem={{
                                                    name: [field.name, "name"],
                                                    label: t("input:request.item_name"),
                                                    key: "itemName",
                                                    rules: name,
                                                    isRequired: !isTipRow,
                                                    placeholder: t("placeholder:request.item_name"),
                                                    onChange: (e) => onNameChange.callback(e.target.value, index),
                                                    isDisabled: isTipRow,
                                                }}
                                                span={6}
                                                data-testid="itemNameInput"
                                            />
                                            <Col span={4}>
                                                <FormItemCurrency
                                                    data-testid="inlineItemNetPrice"
                                                    currency={expense.currency}
                                                    name={[field.name, "netPrice"]}
                                                    label={t("input:request.net_price")}
                                                    key="netPrice"
                                                    required={!isTipRow}
                                                >
                                                    <CurrencyInput
                                                        currency={expense.currency}
                                                        value={0}
                                                        onChange={(value) => onNetPriceUpdate(index, value)}
                                                        onKeyDown={() => {
                                                            setPriceSource(PriceSourceEnum.NET_PRICE)
                                                        }}
                                                    />
                                                </FormItemCurrency>
                                            </Col>

                                            <FormTaxRateSelector
                                                data-testid="inlineItemVat"
                                                elem={{
                                                    name: [field.name, "taxRate", "_id"],
                                                    label: t("input:request.tax_rate"),
                                                    key: "taxRate",
                                                    value: expense.splits[index]?.taxRate?._id ?? getDefaultTaxId(taxValues),
                                                    isRequired: !isTipRow && expense.isAtLeastApproved(),
                                                    className: "w-full text-xs md:text-base no-padding-select no-right-padding-select",
                                                    getPopupAnchor: getPopupAnchor(),
                                                    onChange: (value: string) => onTaxRateSplitUpdate(index, value, priceSource),
                                                    isDisabled: isTipRow,
                                                    taxValues,
                                                }}
                                                span={4}
                                            />
                                            <Col span={4}>
                                                <FormItemCurrency
                                                    data-testid="inlineItemVatPrice"
                                                    currency={expense.currency}
                                                    name={[field.name, "taxPrice"]}
                                                    label={t("input:request.tax_price")}
                                                    key="taxPrice"
                                                >
                                                    <CurrencyInput
                                                        currency={expense.currency}
                                                        value={0}
                                                        onChange={(value) => onTaxPriceUpdate(index, value, PriceSourceEnum.NET_PRICE)}
                                                        isDisabled={isTipRow}
                                                    />
                                                </FormItemCurrency>
                                            </Col>

                                            <Col span={4}>
                                                <FormItemCurrency
                                                    data-testid="inlineItemGrossPrice"
                                                    currency={expense.currency}
                                                    name={[field.name, "grossPrice"]}
                                                    label={t("input:request.gross_price")}
                                                    key="grossPrice"
                                                    required={!isTipRow}
                                                    rules={totalGrossPrice}
                                                >
                                                    <CurrencyInput
                                                        currency={expense.currency}
                                                        value={0}
                                                        onChange={(value) => onGrossPriceUpdate(index, value)}
                                                        onKeyDown={() => {
                                                            setPriceSource(PriceSourceEnum.GROSS_PRICE)
                                                        }}
                                                    />
                                                </FormItemCurrency>
                                            </Col>

                                            <FormItemSideButtons
                                                elem={{
                                                    fieldsLength: fields.length,
                                                    index,
                                                    onRemoveClick: remove,
                                                    setShouldRecalculateAmounts,
                                                    updateExpense,
                                                    expense,
                                                    isHospitality,
                                                    isTipRow,
                                                    taxValues,
                                                    onAddClick: add,
                                                }}
                                                span={1}
                                            />
                                        </Row>
                                    </Space>
                                )
                            })}
                    </div>
                )}
            </Form.List>

            <FormAmountsMismatchAlert
                elem={{
                    isAmountsMismatch,
                    msgText: t("info:request_net_amount_mismatch"),
                    btnText: t("action:request.sync_now"),
                    updateExpense,
                    expense,
                    taxValues,
                }}
                span={24}
                md={24}
                sm={24}
            />
        </>
    )
}

export default FormAdditionalExpense
