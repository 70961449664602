import { ExclamationCircleOutlined } from "@ant-design/icons"
import { Tooltip } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"

import { getTooltipPopupContainer } from "Shared/utils/helper.utils"

interface FeatureSettingsStatusInterface {
    title: string
    isEnabled: boolean
    tooltipText?: string
}

const FeatureSettingStatus = ({ title, isEnabled, tooltipText }: FeatureSettingsStatusInterface) => {
    const { t } = useTranslation()

    return (
        <li>
            <span className="title w-240">
                {title}
                {tooltipText && (
                    <Tooltip getPopupContainer={getTooltipPopupContainer} title={tooltipText} placement="right" className="align-middle">
                        <ExclamationCircleOutlined className="ml-6" />
                    </Tooltip>
                )}
            </span>
            <span className="text text-left">{isEnabled ? t("label:enabled") : t("label:disabled")}</span>
        </li>
    )
}

export default FeatureSettingStatus
