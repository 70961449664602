import { CsvTemplate, CsvTemplateSelectedField, HeaderFieldsEnum } from "@finway-group/shared/lib/models"
import { Form, Modal } from "antd"
import { Store } from "antd/lib/form/interface"
import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import CsvTemplateForm from "Components/forms/csvTemplate.form"
import { NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { createCsvTemplate, deleteCsvTemplate, updateCsvTemplate } from "Shared/store/actions/csvTemplates/csvTemplateActions"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

interface CsvTemplateModalProps {
    isShowing: boolean
    templates: Array<CsvTemplate>
    onCancel: () => void
}

const CsvTemplateModal = ({ isShowing, templates, onCancel }: CsvTemplateModalProps) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const [formInstance] = Form.useForm()

    const [isLoading, setIsLoading] = useStateIfMounted(false)

    const onSubmit = async (values: Store, isEditMode: boolean, id?: string) => {
        setIsLoading(true)
        const headerElements = values.csvHeadersTable as Array<CsvTemplateSelectedField>
        try {
            if (!isEditMode) {
                const newTemplate: CsvTemplate = { name: values.name.trim(), headers: headerElements.map(({ value }) => value as HeaderFieldsEnum) }
                await dispatch(createCsvTemplate(newTemplate))
            } else {
                const updatedTemplate: CsvTemplate = { _id: id, name: values.nameUpdate.trim(), headers: headerElements.map(({ value }) => value as HeaderFieldsEnum) }
                await dispatch(updateCsvTemplate(updatedTemplate))
            }
            NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:settings.csv_template.saved.title"), t("notification:settings.csv_template.saved.message"))
            onHide()
        } catch (error) {
            NotificationService.showErrorNotificationBasedOnResponseError(error, t("error:settings.edit.title"))
        } finally {
            setIsLoading(false)
        }
    }

    const deleteTemplate = async (id: string) => {
        try {
            setIsLoading(true)
            await dispatch(deleteCsvTemplate(id))
            NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:settings.csv_template.deleted.title"), t("notification:settings.csv_template.deleted.message"))
            onHide()
        } catch (error) {
            NotificationService.showErrorNotificationBasedOnResponseError(error, t("error:settings.edit.title"))
        } finally {
            setIsLoading(false)
        }
    }
    const onDelete = (id: string) => {
        Modal.confirm({
            title: t("confirm:csv_settings:delete:title"),
            content: t("confirm:csv_settings:delete:message"),
            cancelText: t("confirm:csv_settings:delete:cancel"),
            type: "warning",
            okText: t("confirm:csv_settings:delete:confirm"),
            async onOk() {
                await deleteTemplate(id)
                onHide()
            },
        })
    }

    const onHide = () => {
        setIsLoading(false)
        formInstance.resetFields()
        onCancel()
    }
    return (
        <Modal
            title={t("action:csv_settings_title")}
            visible={isShowing}
            maskClosable={false}
            onOk={formInstance.submit}
            onCancel={onHide}
            okText={t("action:company_settings.save")}
            cancelText={t("action:cancel")}
            confirmLoading={isLoading}
            keyboard
            destroyOnClose
        >
            <CsvTemplateForm formInstance={formInstance} templates={templates} onDelete={onDelete} onSubmit={onSubmit} />
        </Modal>
    )
}
export default React.memo(CsvTemplateModal)
