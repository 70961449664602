import { Role } from "@finway-group/shared/lib/models"
import axios from "axios"

const MODEL = "roles"

const RoleService = {
    fetchRoles: async () => {
        const {
            data: { docs },
        } = await axios.get(`/${MODEL}?limit=100`)
        return docs as Array<Role>
    },
    createOne: async (createData: any) => {
        const { data } = await axios.post<Role>(`/${MODEL}`, createData)
        return data
    },
    updateOne: async (id: string, updateData: any) => {
        const { data } = await axios.put<Role>(`/${MODEL}/${id}`, updateData)
        return data
    },
    deleteOne: async (id: string) => {
        const { data } = await axios.delete<string>(`/${MODEL}/${id}`)
        return data
    },
}

export default RoleService
