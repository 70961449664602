import { CardStatusEnum, CardTypeEnum, CardWindowEnum, CurrencyEnum } from "@finway-group/shared/lib/models"
import axios from "axios"

const MODEL = "cards"

const CardService = {
    loginWeavr: async (tokenizedPassword: string) => {
        // prettier-ignore
        const { data: { accessToken } } = await axios.post(`/${MODEL}/login`, { tokenizedPassword });
    },
    fetchCards: async (query = "", sortingCriteria = "", page = 1, limit = 20) => {
        const {
            data: { docs, totalPages, totalDocs },
        } = await axios.get(`${MODEL}/?page=${page}&limit=${limit}${query}${sortingCriteria}`)
        return {
            cards: docs,
            totalPages,
            totalDocs,
        }
    },
    fetchAllCards: async (queryParams: any, source: any, timeout?: number) => {
        const { data } = await axios.get(
            `/${MODEL}?page=${queryParams.page}&limit=${queryParams.limit}${queryParams.filterQueryString}${queryParams.searchQueryString}${queryParams.sortQueryString}`,
            {
                cancelToken: source.token,
                timeout,
            },
        )
        return data
    },
    fetchCardById: async (id: string) => {
        const { data } = await axios.get(`/${MODEL}/${id}`)
        return data
    },
    createCard: async (userId: string, createData: any) => {
        await axios.post(`/${MODEL}`, { userId, ...createData })
    },
    updateCard: async (id: string, userId: string, updateData: any) => {
        const { data } = await axios.put(`/${MODEL}/${id}`, { ...updateData, userId })
        return data
    },
    activateCard: async (id: string, userId: string, activationCode: string) => {
        const { data } = await axios.put(`/${MODEL}/${id}/status`, { userId, activationCode, status: CardStatusEnum.ACTIVE })
        return data
    },
    updateCardStatus: async (id: string, userId: string, status: CardStatusEnum) => {
        const { data } = await axios.put(`/${MODEL}/${id}/status`, { status, userId })
        return data
    },
    topUpCard: async (id: string) => {
        const { data } = await axios.put(`/${MODEL}/${id}/topup`)
        return data
    },
    emptyCard: async (id: string) => {
        const { data } = await axios.put(`/${MODEL}/${id}/empty`)
        return data
    },
    retrievePINToken: async (id: string) => {
        const { data } = await axios.get(`/${MODEL}/${id}/pin`)
        return data
    },
    unblockCardPin: async (id: string) => {
        const { data } = await axios.patch(`/${MODEL}/${id}/pin/unblock`)
        return data
    },
    fixCardError: async (id: string) => {
        const { data } = await axios.get(`/${MODEL}/${id}/retry`)
        return data
    },
    requestPhoneNumberVerification: async (id: string, phoneNumber: string | undefined) => {
        await axios.post(`/${MODEL}/${id}/phone/verification`, { phoneNumber })
    },
    checkPhoneNumberVerification: async (id: string, verificationCode: string) => {
        const { data } = await axios.post(`/${MODEL}/${id}/phone/verification-check`, { verificationCode })
        return data.verified
    },
    requestLimitChange: async (id: string, updateData: { limit: number; reason: string }) => {
        const { data } = await axios.post(`/${MODEL}/${id}/request-limit-change`, updateData)
        return data
    },
    resendCardActivationEmail: async (id: string) => {
        const { data } = await axios.get(`/${MODEL}/${id}/resend-verification-email`)
        return data
    },
}

export default CardService
