import { SizeType } from "antd/es/config-provider/SizeContext"
import * as IBAN from "iban"
import React, { InputHTMLAttributes } from "react"

import ControlledInput from "./controlledInput"

interface IbanInputInterface extends Omit<InputHTMLAttributes<HTMLInputElement>, "size"> {
    value?: string
    onChange?: (value: any) => void
    size?: SizeType
}

// NOTE: maxLength it takes into account the spaces after every 4th char
// eslint-disable-next-line arrow-body-style
const IbanInput: React.FC<IbanInputInterface> = ({ value = "", onChange, maxLength = 41, ...rest }) => {
    return (
        <ControlledInput
            value={IBAN.printFormat(IBAN.electronicFormat(value ?? ""))}
            computeCursorPosition={(e, val) => {
                const offset = val.length < e.target.value.length && e.target.selectionStart % 5 === 0 ? 1 : 0
                return e.target.selectionStart + offset
            }}
            onChange={(e) => {
                if (onChange) onChange(IBAN.printFormat(IBAN.electronicFormat(e.target.value)))
            }}
            maxLength={maxLength}
            placeholder="DE89 3704 0044 0532 0130 00"
            {...rest}
        />
    )
}

export default IbanInput
