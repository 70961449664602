import { DatePickerProps } from "antd/es/date-picker"
import Col from "antd/lib/col"
import DatePicker from "antd/lib/date-picker"
import Form from "antd/lib/form"
import moment from "moment"
import { PickerMode } from "rc-picker/lib/interface"
import React, { Dispatch, SetStateAction, useState } from "react"

import { getPopupAnchor } from "Shared/utils/popup.utils"

import { FormActiveElementInterface, FormCategoryEnum, FormInputElementInterface } from "./form.types"

interface FormInputInterface {
    elem: FormInputElementInterface
    span?: number
    className?: string
    pickerClassName?: string
    hideLabel?: boolean
    activeCategory?: FormActiveElementInterface
    /**
     * Requires a parent category to be set, containing a min, max or day input element
     */
    setActiveCategory?: Dispatch<SetStateAction<FormActiveElementInterface>>
    category?: FormCategoryEnum
    picker?: PickerMode
    disabledDateFunction?: DatePickerProps["disabledDate"]
    disabledMonthFunction?: DatePickerProps["disabledDate"]
    disabledYearFunction?: DatePickerProps["disabledDate"]
    dateFormat?: string
    allowClear?: boolean
    anchorRef?: React.RefObject<HTMLDivElement>
}

export default function FormInputDate({
    elem,
    span,
    className = "",
    pickerClassName = "",
    hideLabel = false,
    activeCategory,
    setActiveCategory,
    category,
    picker = "date",
    dateFormat = moment.localeData().longDateFormat("L"),
    disabledDateFunction,
    disabledMonthFunction,
    disabledYearFunction,
    allowClear = true,
    anchorRef,
}: FormInputInterface) {
    /* TODO FA-2088/BUG-9722 Remove after ANTD upgrade to v.4.24.15 or 5.x
    This is a quick hack due to an ANTD bug
    delete all code related the disabled function changing depending on mode once Antd is updated * */
    const [mode, setMode] = useState("date")
    const isFilterActive = category && activeCategory && activeCategory[category]

    return (
        <Col span={span || 12}>
            <Form.Item
                label={!hideLabel ? elem.label : undefined}
                name={elem.name}
                style={{ marginBottom: "0px" }}
                initialValue={elem.initialValue ? moment(elem.initialValue) : ""}
                rules={elem.rules}
                className={`mb-0 ${className} ${isFilterActive ? "filter--active" : ""}`}
            >
                <DatePicker
                    allowClear={allowClear}
                    disabledDate={
                        disabledDateFunction
                            ? (current: moment.Moment) => {
                                  if (mode === "month" && disabledMonthFunction) return disabledMonthFunction(current)
                                  if (mode === "year" && disabledYearFunction) return disabledYearFunction(current)

                                  return disabledDateFunction(current)
                              }
                            : undefined
                    }
                    style={{ width: "100%" }}
                    format={dateFormat}
                    placeholder={elem.placeholder}
                    onChange={(date) => {
                        if (category && setActiveCategory) setActiveCategory({ ...activeCategory, [category]: !!date })
                        if (elem.onChange) elem.onChange(date)
                    }}
                    className={pickerClassName}
                    getPopupContainer={anchorRef?.current ? getPopupAnchor(anchorRef.current) : getPopupAnchor(undefined, true)}
                    picker={picker}
                    onPanelChange={(_value, newMode) => setMode(newMode)}
                />
            </Form.Item>
        </Col>
    )
}
