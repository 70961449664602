import Icon from "@ant-design/icons"
import React from "react"

const dropdownDotsSvg = () => (
    <svg width="14" height="4" viewBox="0 0 14 4" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M2.19961 3.59996C1.77526 3.59996 1.3683 3.43139 1.06824 3.13133C0.76818 2.83128 0.599609 2.42431 0.599609 1.99996C0.599609 1.57562 0.76818 1.16865 1.06824 0.868593C1.3683 0.568535 1.77526 0.399963 2.19961 0.399963C2.62396 0.399963 3.03092 0.568535 3.33098 0.868593C3.63104 1.16865 3.79961 1.57562 3.79961 1.99996C3.79961 2.42431 3.63104 2.83128 3.33098 3.13133C3.03092 3.43139 2.62396 3.59996 2.19961 3.59996ZM6.99961 3.59996C6.57526 3.59996 6.1683 3.43139 5.86824 3.13133C5.56818 2.83128 5.39961 2.42431 5.39961 1.99996C5.39961 1.57562 5.56818 1.16865 5.86824 0.868593C6.1683 0.568535 6.57526 0.399963 6.99961 0.399963C7.42396 0.399963 7.83092 0.568535 8.13098 0.868593C8.43104 1.16865 8.59961 1.57562 8.59961 1.99996C8.59961 2.42431 8.43104 2.83128 8.13098 3.13133C7.83092 3.43139 7.42396 3.59996 6.99961 3.59996ZM11.7996 3.59996C11.3753 3.59996 10.9683 3.43139 10.6682 3.13133C10.3682 2.83128 10.1996 2.42431 10.1996 1.99996C10.1996 1.57562 10.3682 1.16865 10.6682 0.868593C10.9683 0.568535 11.3753 0.399963 11.7996 0.399963C12.224 0.399963 12.6309 0.568535 12.931 0.868593C13.231 1.16865 13.3996 1.57562 13.3996 1.99996C13.3996 2.42431 13.231 2.83128 12.931 3.13133C12.6309 3.43139 12.224 3.59996 11.7996 3.59996Z"
            fill="#606C7E"
        />
    </svg>
)

const DropdownDotsIcon = (props: any) => <Icon component={dropdownDotsSvg} {...props} />

export default DropdownDotsIcon
