import { useEffect } from "react"

export enum HotKey {
    F5 = "F5",
}

export const useHotkey = (hotKey: HotKey, callback: () => void) => {
    useEffect(() => {
        const hotkeyEventListener = (event: KeyboardEvent) => {
            if (event.key === hotKey.toString()) {
                event.preventDefault()
                callback()
            }
        }

        window.addEventListener("keydown", hotkeyEventListener)
        return () => {
            window.removeEventListener("keydown", hotkeyEventListener)
        }
    }, [])
}
