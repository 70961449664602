import { Tax } from "@finway-group/shared/lib/models"
import { Reducer } from "redux"

import { FetchAllTaxesAction, TaxTypes, TaxesActions, UpdateAllTaxesAction } from "../actions/tax/taxTypes"

export interface TaxState {
    items: Array<Tax>
}

const initialState = {
    items: [],
}

const fetchAllTaxes = (state: TaxState, action: FetchAllTaxesAction): TaxState => ({
    ...state,
    items: action.taxes,
})

const updateAllTaxes = (state: TaxState, action: UpdateAllTaxesAction): TaxState => ({
    ...state,
    items: action.taxes,
})

export const taxReducer: Reducer<TaxState, TaxesActions> = (state = initialState, action) => {
    switch (action.type) {
        case TaxTypes.FETCH_ALL_TAXES:
            return fetchAllTaxes(state, action)
        case TaxTypes.UPDATE_ALL_TAXES:
            return updateAllTaxes(state, action)
        default:
            return state
    }
}
