import { CurrencyEnum, PaymentProductPrice, PaymentProductType, PriceIntervalEnum } from "@finway-group/shared/lib/models"
import { TenantTierEnum } from "@finway-group/shared/lib/models/featureFlags/tenantTier.enum"
import { Col, DatePicker, Form, Input, InputNumber, Row, Select, Switch } from "antd"
import { FormInstance } from "antd/lib/form"
import { Store } from "antd/lib/form/interface"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import Loading from "Components/loading"
import { PaymentProductUI } from "Shared/services/paymentSettings.service"
import SimulatorService, { ResetPaymentSettingsRequest } from "Shared/services/simulator.service"

interface SimulatorPaymentSettingsTabInterface {
    formInstance: FormInstance<any>
}

const paymentSettingsLineItems = [0, 1, 2, 3]

const SimulatorPaymentSettingsTab: React.FC<SimulatorPaymentSettingsTabInterface> = ({ formInstance }) => {
    const { t } = useTranslation()

    const [plans, setPlans] = useState([] as Array<PaymentProductUI>)
    const [isFetching, setIsFetching] = useState(false)

    useEffect(() => {
        setIsFetching(true)
        SimulatorService.getAllProducts()
            .then((products) => setPlans(products.filter((e) => e.productType === PaymentProductType.PLAN)))
            .catch(alert)
            .finally(() => setIsFetching(false))
    }, [])

    const filterSelect = (input: string, option: any) => {
        const value = option?.children?.props?.children || ""
        return value.toLowerCase().indexOf(input.toLowerCase()) > -1
    }

    const renderLabel = (e: { price: PaymentProductPrice; plan: PaymentProductUI }) => {
        const pad = (num: number) => `___${String(num)}`.slice(-5)
        const custom = e.plan.metadata.custom === "true" ? "CUS" : "STD"
        const tier = e.plan.metadata.tier ?? "tier_?"
        const ival = e.price.interval === PriceIntervalEnum.MONTHLY ? "MLY" : "YRL"
        const price = Number(e.price.amount) / 100
        const planName = e.plan.name
        return <>{`${custom} | ${tier} | ${pad(price)}.00 ${CurrencyEnum.EUR} | ${ival} | ${planName}`}</>
    }

    if (isFetching) <Loading />

    return (
        <Row>
            <Col span={24}>
                <p className="mb-10">Reset Payment Settings</p>
                <Row className="mb-10">
                    {/* TODO: Remove once existing customers are migrated */}
                    <Col className="px-10" span={3}>
                        <Form.Item label="Existing Customer" name="paymentIsExistingCustomer" initialValue={false}>
                            <Switch defaultChecked={false} />
                        </Form.Item>
                    </Col>
                    <Col className="px-10" span={3}>
                        <Form.Item label="Country Code" name="paymentCountryCode" initialValue={"DE"}>
                            <Input maxLength={2} onInput={(e) => ((e.target as HTMLInputElement).value = (e.target as HTMLInputElement).value.toUpperCase())} />
                        </Form.Item>
                    </Col>
                </Row>
                {paymentSettingsLineItems.map((row) => {
                    const startDate = moment.utc().add(row, "months").startOf("day").startOf("month")
                    return (
                        <Row key={row}>
                            <Col className="px-10" span={3}>
                                <Form.Item label="Start Date" name={`paymentContractStartDate${row}`} initialValue={startDate}>
                                    <DatePicker
                                        style={{ width: "100%" }}
                                        format={startDate.localeData().longDateFormat("L")}
                                        placeholder={t("placeholder:request.date")}
                                        onChange={(value) => {
                                            const values = { ...formInstance.getFieldsValue() }
                                            values[`paymentContractStartDate${row}`] = value ? moment.utc(value.toDate()).startOf("day") : startDate
                                            formInstance.setFieldsValue(values)
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col className="px-10" span={3}>
                                <Form.Item label="Term In Months" name={`paymentTermInMonths${row}`} initialValue={1}>
                                    <InputNumber min={1} max={48} />
                                </Form.Item>
                            </Col>
                            <Col className="px-10" span={12}>
                                <Form.Item label="Payment Product" name={`paymentProductPlanId${row}`}>
                                    <Select showSearch filterOption={filterSelect}>
                                        {plans
                                            .reduce((pricePlanArray, plan) => {
                                                if (plan.prices)
                                                    plan.prices.forEach((price) => {
                                                        pricePlanArray.push({
                                                            plan,
                                                            price,
                                                        })
                                                    })
                                                return pricePlanArray
                                            }, [] as Array<{ price: PaymentProductPrice; plan: PaymentProductUI }>)
                                            .sort((a, b) => {
                                                // sort by tier
                                                const tierA = a.plan.metadata.tier ?? TenantTierEnum.TIER_2
                                                const tierB = b.plan.metadata.tier ?? TenantTierEnum.TIER_2
                                                if (tierA === TenantTierEnum.TIER_2 && tierB === TenantTierEnum.TIER_1) return -1
                                                if (tierA === TenantTierEnum.TIER_1 && tierB === TenantTierEnum.TIER_2) return 1

                                                // sort by price
                                                return (a.price.amount ?? 0) - (b.price.amount ?? 0)
                                            })
                                            .map((e) => {
                                                // Use Hubspot ProductID
                                                const value = e.price.metadata.hs_product_id
                                                return (
                                                    <Select.Option key={e.price.priceId} value={value}>
                                                        <span style={{ fontFamily: "monospace, monospace" }}>{renderLabel(e)}</span>
                                                    </Select.Option>
                                                )
                                            })}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col className="px-10" span={3}>
                                <Form.Item label="Discount Off" name={`paymentDiscountOff${row}`} initialValue={0}>
                                    <InputNumber min={0} max={100} />
                                </Form.Item>
                            </Col>
                            <Col className="px-10" span={3}>
                                <Form.Item label="Discount isPercent" name={`paymentDiscountIsPercent${row}`} initialValue={true}>
                                    <Switch defaultChecked={true} />
                                </Form.Item>
                            </Col>
                        </Row>
                    )
                })}
            </Col>
        </Row>
    )
}

export const createBodyData = (store: Store): ResetPaymentSettingsRequest => ({
    /* TODO: Remove once existing customers are migrated */
    isExistingCustomer: Boolean(store.paymentIsExistingCustomer),
    countryCode: store.paymentCountryCode,
    lineItems: paymentSettingsLineItems
        .filter((row) => store[`paymentProductPlanId${row}`])
        .map((row) => {
            const isPercent = Boolean(store[`paymentDiscountIsPercent${row}`])
            const off = Number(store[`paymentDiscountOff${row}`])
            const discount =
                off === 0
                    ? undefined
                    : {
                          off: isPercent ? off : off * 100, // CENT => EUR
                          currency: isPercent ? undefined : CurrencyEnum.EUR,
                      }
            return {
                startDate: store[`paymentContractStartDate${row}`].toDate() as Date,
                productId: String(store[`paymentProductPlanId${row}`]),
                termInMonths: Number(store[`paymentTermInMonths${row}`]),
                discount,
            }
        }),
})

export default SimulatorPaymentSettingsTab
