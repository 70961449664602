import { PerDiem } from "@finway-group/shared/lib/models"
import { Button, Col, Form, Row } from "antd"
import React, { useState } from "react"
import { PlusCircle } from "react-feather"
import { useTranslation } from "react-i18next"

import { useExpenseFormContext } from "Components/modals/expenseCreateForm.context"
import { MAX_PER_DIEM_DESTINATION_DAYS } from "Shared/config/consts"
import { usePerDiemDestinationMap } from "Shared/hooks/perDiemDestination.hooks"
import { getDestinationRateOfDifferentYear } from "Shared/utils/perDiem.utils"

import { PerDiemDestinationField } from "./components/perDiemDestinationField"
import { FormPerDiemDestinationItinerary } from "./perDiem.form.types"

interface PerDiemDestinationFormFieldsContainerInterface {
    belowRange: boolean
}

export const PerDiemDestinationFormFieldsContainer = ({ belowRange }: PerDiemDestinationFormFieldsContainerInterface) => {
    const { t } = useTranslation()
    const [{ updateExpense, expenseForm }] = useExpenseFormContext()
    const { destinationMap, isReady } = usePerDiemDestinationMap()
    const [aboveRange, setAboveRange] = useState<Record<string, boolean>>({})
    if (!isReady) {
        return null
    }

    const currentDestinationsState: Array<FormPerDiemDestinationItinerary> = expenseForm.getFieldValue(["destinations"]) ?? []

    const validateDestinationFields = () => {
        const destinationFieldList: Pick<PerDiem, "destinations"> = expenseForm.getFieldsValue(["destinations"])
        const touchedFieldNames = destinationFieldList.destinations
            .flatMap((_, i: number) => [
                ["destinations", i, "startDate"],
                ["destinations", i, "endDate"],
            ])
            .filter(expenseForm.isFieldTouched)
        expenseForm.validateFields(touchedFieldNames)
    }

    const adjustDestinationsOnChange = () => {
        adjustStartDateAndDestination()
        syncDestinationState()
        validateDestinationFields()
    }

    const calculateDateDifferenceInDays = (startDate: Date, endDate: Date): number => {
        const timeDiff = endDate.getTime() - startDate.getTime()
        const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24)) //= > (1000 milliseconds * 3600 seconds * 24 hours)
        return daysDiff
    }

    const checkDaysSpan = (destination: FormPerDiemDestinationItinerary): boolean => {
        const startDate = destination?.startDate?.toDate()
        const endDate = destination?.endDate?.toDate()
        const daysDiff = startDate && endDate ? calculateDateDifferenceInDays(startDate, endDate) : 0
        return daysDiff > MAX_PER_DIEM_DESTINATION_DAYS
    }

    const syncDestinationState = () => {
        const newDestinations: PerDiem["destinations"] = [...expenseForm.getFieldValue(["destinations"])]
        const lastDestination = newDestinations[newDestinations.length - 1]
        const updateData: Partial<PerDiem> = lastDestination ? { destinations: newDestinations, datePurchased: lastDestination.endDate } : { destinations: newDestinations }

        // Only update datePurchased if the last destination is valid
        if (lastDestination) {
            updateData.datePurchased = lastDestination.endDate
        }

        updateExpense({ ...updateData })
    }

    const setStartDate = (destinations: Array<FormPerDiemDestinationItinerary>, index: number) => {
        if (!destinations?.[index]) return
        destinations[index].startDate = destinations[index - 1].endDate
    }

    const updateDestination = (destinations: Array<FormPerDiemDestinationItinerary>, index: number) => {
        const destinationField = destinations?.[index]

        if (!destinationField || !destinationField.destination || !destinationField.startDate) {
            return
        }
        const { destination, startDate } = destinationField

        const destinationRate = destinationMap.get(destination)
        const isYearDifferent = destinationRate && startDate.get("year") !== destinationRate.year

        if (isYearDifferent) {
            const newDestinationId = getDestinationRateOfDifferentYear(startDate.get("year"), destinationRate, Array.from(destinationMap.values()))?._id
            if (newDestinationId) {
                destinations[index].destination = newDestinationId
            }
        }
    }

    const adjustStartDateAndDestination = () => {
        const destinations: Array<FormPerDiemDestinationItinerary> = expenseForm.getFieldValue(["destinations"])
        // reduce needed, because error message possibly shown between all destinations
        const newAboveRange = destinations.reduce<Record<string, boolean>>((acc, destination, i) => ({ ...acc, [i]: checkDaysSpan(destination) }), {})
        setAboveRange((prevState) => ({ ...prevState, ...newAboveRange }))

        if (destinations.length <= 1) {
            return
        }

        for (let i = 1; i < destinations.length; i++) {
            setStartDate(destinations, i)
            updateDestination(destinations, i)
        }
        expenseForm.setFieldsValue({ destinations })
    }

    return (
        <Form.List key="destinations" name={["destinations"]}>
            {(fields, { add, remove }) => (
                <Row gutter={[16, 16]}>
                    {fields.map((field, i) => (
                        <PerDiemDestinationField
                            key={`per-diem-destination-field-${i}`}
                            elementField={field}
                            index={i}
                            remove={remove}
                            aboveRange={aboveRange}
                            belowRange={belowRange}
                            tripDestinations={currentDestinationsState}
                            adjustDestinationsOnChange={adjustDestinationsOnChange}
                        />
                    ))}
                    <Col span={24}>
                        <Button
                            onClick={() => {
                                add()
                                syncDestinationState()
                            }}
                        >
                            <PlusCircle />
                            {t("input:request.per_diem.add_destination")}
                        </Button>
                    </Col>
                </Row>
            )}
        </Form.List>
    )
}
