import { Employee } from "@finway-group/shared/lib/models"
import { Reducer } from "redux"

import {
    EmployeeActionTypes,
    EmployeeActions,
    FetchAllEmployeesAction,
    FetchOneEmployeeAction,
    NewUserAction,
    SyncEmployeeAction,
    UpdateEmployeeAction,
    UpdateUserAction,
} from "../actions/employee/employeeTypes"

export interface EmployeeState {
    items: Array<Employee>
    item?: Employee
}

const initialState = {
    items: [],
    item: undefined,
}

const fetchAllEmployees = (state: EmployeeState, action: FetchAllEmployeesAction): EmployeeState => {
    let { item } = state
    if (item) {
        const itemInAction = action.employees.find(({ id }: Employee) => id === item?.id)
        if (itemInAction) item = itemInAction
    }

    return {
        ...state,
        items: action.employees,
        item,
    }
}

const fetchOneEmployee = (state: EmployeeState, action: FetchOneEmployeeAction): EmployeeState => ({
    ...state,
    item: action.employee,
})

const syncEmployee = (state: EmployeeState, { employee }: SyncEmployeeAction): EmployeeState => ({
    ...state,
    items: state.items.filter((emp) => emp.id === employee.id).length > 0 ? state.items.map((item) => (item.id === employee.id ? employee : item)) : [employee, ...state.items],
    item: state.item?.id === employee.id ? employee : state.item,
})

const newUser = (state: EmployeeState, action: NewUserAction): EmployeeState => ({
    ...state,
    items: [action.employee, ...state.items],
})

const updateUser = (state: EmployeeState, { employee }: UpdateUserAction): EmployeeState => ({
    ...state,
    item: state.item?.id === employee.id ? employee : state.item,
    items: [...state.items.map((item) => (item.id === employee.id ? employee : item))],
})

const updateEmployee = (state: EmployeeState, { employee }: UpdateEmployeeAction): EmployeeState => ({
    ...state,
    item: state.item?.id === employee.id ? employee : state.item,
    items: [...state.items.map((item) => (item.id === employee.id ? employee : item))],
})

export const employeeReducer: Reducer<EmployeeState, EmployeeActions> = (state = initialState, action) => {
    switch (action.type) {
        case EmployeeActionTypes.FETCH_ALL_EMPLOYEES:
            return fetchAllEmployees(state, action)
        case EmployeeActionTypes.FETCH_ONE_EMPLOYEE:
            return fetchOneEmployee(state, action)
        case EmployeeActionTypes.SYNC_EMPLOYEE:
            return syncEmployee(state, action)
        case EmployeeActionTypes.NEW_USER:
            return newUser(state, action)
        case EmployeeActionTypes.UPDATE_USER:
            return updateUser(state, action)
        case EmployeeActionTypes.UPDATE_EMPLOYEE:
            return updateEmployee(state, action)
        default:
            return state
    }
}
