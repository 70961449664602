import { DocumentChangeInterface, OperationTypeEnum } from "@finway-group/shared/lib/models"
import * as Sentry from "@sentry/react"

import { fetchCurrencyExchange } from "Shared/store/actions/currency/currencyActions"
import { isClientErrorCode } from "Shared/utils/errorHandler.utils"

const subscribeToCurrencyExchange = (dispatch: any, data: DocumentChangeInterface) => {
    const {
        fullDocument: { operationType },
    } = data

    try {
        if (operationType === OperationTypeEnum.INSERT || operationType === OperationTypeEnum.UPDATE) {
            dispatch(fetchCurrencyExchange())
        }
    } catch (err) {
        if (isClientErrorCode(err)) return
        Sentry.captureException(`[Currency Exchange Socket] Error: ${typeof err === "string" ? err : JSON.stringify(err)}`)
    }
}

export default subscribeToCurrencyExchange
