import i18n from "i18next"

import { isURL } from "Shared/utils/plugins/draftjs/utils"
import validateEmail from "Shared/validators/email.validator"

// eslint-disable-next-line import/prefer-default-export
export const ssoRules = () => ({
    domain: [
        {
            required: true,
            validator: (_rule: any, value: string) => {
                if (!value) {
                    return Promise.reject(new Error(i18n.t("validation:required")))
                }

                if (validateEmail(value)) {
                    return Promise.reject(new Error(i18n.t("validation:sso_domain_can_not_be_an_email")))
                }

                if (!isURL(value)) {
                    return Promise.reject(new Error(i18n.t("validation:url")))
                }

                return Promise.resolve()
            },
        },
    ],
    clientId: [
        {
            required: true,
            message: i18n.t("validation:required"),
            trigger: "blur",
        },
    ],
    clientSecret: [
        {
            required: true,
            message: i18n.t("validation:required"),
            trigger: "blur",
        },
    ],
})
