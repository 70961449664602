import { COLOR } from "@finway-group/shared/lib/consts"
import { LiquidityCashFlow } from "@finway-group/shared/lib/models"
import { Card } from "antd"
import moment from "moment"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Area, Bar, CartesianGrid, ComposedChart, Legend, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts"

import Loading from "Components/loading"
import { useLoggedInEmployeeProfile } from "Shared/hooks/user.hooks"
import { convertCashFlowForForecastDiagram, getDiagramLegendString } from "Shared/utils/liquidity.utils"

import { CashFlowAmountSourceEnum, ForecastDiagramData } from "../../liquidity.types"
import CustomTooltip from "../../supplements/customTooltip"

interface ForecastDiagramInterface {
    cashFlowData: Array<LiquidityCashFlow>
    isDiagramLoading: boolean
}

const ForecastDiagram: React.FC<ForecastDiagramInterface> = ({ cashFlowData, isDiagramLoading }) => {
    if (!cashFlowData || cashFlowData.length === 0 || isDiagramLoading)
        return (
            <Card>
                <Loading />
            </Card>
        )

    const { t } = useTranslation()

    const currentEmployee = useLoggedInEmployeeProfile()
    const { globalCurrency } = currentEmployee.settings

    const diagramData: Array<ForecastDiagramData> = useMemo(
        () => (cashFlowData ? convertCashFlowForForecastDiagram(CashFlowAmountSourceEnum.ACTUAL, cashFlowData) : []),
        [cashFlowData],
    )
    const lastActualBalance = diagramData.length > 0 ? diagramData[diagramData.length - 1].balance : 0
    const diagramDataProjection: Array<ForecastDiagramData> = useMemo(
        () => (cashFlowData ? convertCashFlowForForecastDiagram(CashFlowAmountSourceEnum.FORECAST, cashFlowData, lastActualBalance) : []),
        [cashFlowData],
    )
    const data = [...diagramData, ...diagramDataProjection]

    return (
        <Card className="w-full" style={{ height: "430px" }}>
            <ResponsiveContainer width={"100%"} height={400}>
                <ComposedChart data={data} stackOffset="sign" margin={{ top: 40, left: 40, right: 40, bottom: 40 }}>
                    <XAxis dataKey="month" />
                    <YAxis />
                    <Tooltip
                        itemStyle={{ color: COLOR.primary }}
                        filterNull={true}
                        allowEscapeViewBox={{ y: true }}
                        content={(tooltipProps) => <CustomTooltip props={tooltipProps} />}
                    />
                    <Legend
                        iconType="square"
                        wrapperStyle={{ position: "relative", left: 20 }}
                        formatter={(value) => <p className="inline-block text-text mr-10 transform translate-y-1">{getDiagramLegendString(t, value)}</p>}
                    />
                    <CartesianGrid stroke="#f5f5f5" vertical={false} />
                    <Bar dataKey="actualIncome" stackId="1" barSize={40} fill="#9CECD8" stroke="#60CDB2" radius={[5, 5, 0, 0]} />
                    <Bar dataKey="actualSpending" stackId="1" barSize={40} fill="#FC6C8F" stroke="#F42A68" radius={[5, 5, 0, 0]} />
                    <Bar dataKey="forecastIncome" stackId="1" barSize={40} fill="#DBFFF6" stroke="#60CDB2" radius={[5, 5, 0, 0]} />
                    <Bar dataKey="forecastSpending" stackId="1" barSize={40} fill="#FFBACB" stroke="#F42A68" radius={[5, 5, 0, 0]} />
                    <Area type="linear" dataKey="balance" fill="#CEE1FF" stroke="#3886FF" />
                    <ReferenceLine x={moment().format("MMM YY")} strokeDasharray="3" />
                </ComposedChart>
            </ResponsiveContainer>
        </Card>
    )
}

export default ForecastDiagram
