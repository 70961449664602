import { ExclamationCircleOutlined } from "@ant-design/icons"
import { CardModeEnum, CardTypeEnum, CardWindowEnum } from "@finway-group/shared/lib/models"
import { Col, Form, Input, Row, Select, Switch, Tooltip } from "antd"
import { FormInstance } from "antd/lib/form/Form"
import { Store } from "antd/lib/form/interface"
import React from "react"
import { useTranslation } from "react-i18next"

import CurrencyInput from "Components/currencyInput"
import { parseCurrencyInput } from "Components/currencyInput/config"
import FormItemCurrency from "Components/form/formItemCurrency"
import { cardWindowArray, getInfoTextForExpenseCard, getLabelForWindow, getWindowForExpenseCard } from "Shared/utils/creditCard.utils"
import { getPopupAnchor } from "Shared/utils/popup.utils"

import { CreditCardRules } from "./rules"

interface EditCardFormInterface {
    formInstance: FormInstance
    onSubmit: (values: Store) => void
    card: any
}

const EditCardForm: React.FC<EditCardFormInterface> = ({ formInstance, card, onSubmit }) => {
    const { t } = useTranslation()

    const onFinish = (values: Store) => {
        values.limit = parseCurrencyInput(values.limit)
        onSubmit(values)
    }

    const rules = CreditCardRules()
    const isAutoRechargeDisabled = card?.window === CardWindowEnum.LIFETIME || card?.window === CardWindowEnum.ONE_TIME

    const UpdateExpenseAmountSwitch = (
        <Form.Item
            name="shouldUpdateExpenseAmountOnCardTransaction"
            label={
                <span className="title w-240">
                    {t("input:card.should_update_expense_amount_on_card_transaction")}
                    <Tooltip title={t("info:cards.update_expense_amount_on_card_transaction")} placement="right" className="align-middle">
                        <ExclamationCircleOutlined className="ml-6" />
                    </Tooltip>
                </span>
            }
            valuePropName="checked"
            initialValue={card?.shouldUpdateExpenseAmountOnCardTransaction ?? true}
        >
            <Switch />
        </Form.Item>
    )

    return (
        <Form form={formInstance} layout="vertical" onFinish={onFinish}>
            <Row gutter={[16, 16]}>
                <Col span={24} sm="24" md="24">
                    <Form.Item name="cardName" label={t("input:card.card_name")} rules={rules.cardName} className="w-full">
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[16, 16]}>
                <Col span={12} sm="24" md="24">
                    <FormItemCurrency currency={card?.currency} name="limit" label={t("input:card.limit")} className="w-full">
                        <CurrencyInput currency={card?.currency} value={0} />
                    </FormItemCurrency>
                </Col>
                <Col span={12} sm="24" md="24">
                    <Form.Item
                        name="window"
                        label={t("input:card.frequency")}
                        extra={card?.attachedExpense && getInfoTextForExpenseCard(card?.attachedExpense)}
                        rules={rules.window}
                    >
                        <Select className="w-full" disabled={!!card?.attachedExpense} getPopupContainer={getPopupAnchor()}>
                            {card?.attachedExpense ? (
                                <Select.Option value={card?.window}>{getWindowForExpenseCard(card?.attachedExpense)}</Select.Option>
                            ) : (
                                cardWindowArray
                                    // phyiscal and debit cards should not allow one-time windows
                                    .filter((window) => {
                                        if (card?.cardType === CardTypeEnum.PHYSICAL || card?.mode === CardModeEnum.DEBIT_MODE) return window.value !== CardWindowEnum.ONE_TIME
                                        return true
                                    })
                                    .map((cardWindow, index) => (
                                        <Select.Option key={index} value={cardWindow.value}>
                                            {getLabelForWindow(cardWindow.value)}
                                        </Select.Option>
                                    ))
                            )}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            {card?.mode === CardModeEnum.PREPAID_MODE ? (
                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <Form.Item
                            name="isAutoRechargeEnabled"
                            label={t("input:card.auto_recharge_enabled")}
                            valuePropName="checked"
                            extra={isAutoRechargeDisabled && t("info:cards.disabled_auto_recharge")}
                        >
                            <Switch disabled={isAutoRechargeDisabled} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>{UpdateExpenseAmountSwitch}</Col>
                </Row>
            ) : (
                <Row gutter={[16, 16]}>
                    <Col span={24}>{UpdateExpenseAmountSwitch}</Col>
                </Row>
            )}
        </Form>
    )
}

export default EditCardForm
