import { BudgetPeriodEnum, CostCenter, CostCenterTypeEnum, CurrencyEnum, RightEnum } from "@finway-group/shared/lib/models"
import { Modal } from "antd"
import { ButtonProps } from "antd/lib/button"
import { FormInstance } from "antd/lib/form"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"

import { parseCurrencyInput } from "Components/currencyInput/config"
import CostCenterForm from "Components/forms/CostCenterForm"
import { NO_PARENT_COST_CENTER } from "Shared/config/consts"
import { useCostCenterBudgetUsageBarGraphOptions } from "Shared/hooks/analytics.hooks"
import { useCostCenter, useCostCenterGroups, useCostCenters } from "Shared/hooks/costCenter.hooks"
import { useEmployees } from "Shared/hooks/employee.hooks"
import { useRoleById } from "Shared/hooks/role.hooks"
import { AuthzService, NotificationService, UserService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { ThunkDispatchResult } from "Shared/store"
import { setCostCenterBudgetUsageBarGraphOptions } from "Shared/store/actions/analytics/analyticsActions"
import { createCostCenter, fetchAllCostCenters, syncOneCostCenter, updateCostCenter } from "Shared/store/actions/costCenter/costCenterActions"
import { getBudgetPeriodString, getBudgetsFromBudgetsList, getBudgetsList, getDateRangeObject, getPeriodNames } from "Shared/utils/costCenter.utils"
import { isRightGranted, pick, shouldRestoreItem } from "Shared/utils/helper.utils"

import { PeriodRangeOptionEnum } from "../costCenterDetails/costCenterPeriodOption.interface"
import { CostCenterFormData } from "./costCenterFormData.interface"

interface CostCenterFormContainerInterface {
    isNew: boolean
    formInstance: FormInstance
    handleHide: () => any
    setIsLoading: (value: React.SetStateAction<boolean>) => void
    onCostCenterTypeChange: (costCenterType: CostCenterTypeEnum) => void
    onFormValuesChange: () => void
}

export const CostCenterFormContainer: React.FC<CostCenterFormContainerInterface> = ({
    isNew,
    formInstance,
    handleHide,
    setIsLoading,
    onCostCenterTypeChange,
    onFormValuesChange,
}) => {
    const { t } = useTranslation()
    const history = useHistory()
    const loggedInProfile = UserService.getLoggedInEmployeeProfile()
    const dispatch: ThunkDispatchResult = useDispatch()
    const budgetUsageBarGraphOptions = useCostCenterBudgetUsageBarGraphOptions()

    const costCenters = useCostCenters()
    const costCenter = useCostCenter()
    const costCenterGroups = useCostCenterGroups(true, true)
    const budgetsList = getBudgetsList(costCenter?.alternativeBudgets)
    const employees = useEmployees({ excludeDeleted: true })
    const approvers = employees.filter((employee) => {
        const role = useRoleById(employee.activeCompanyProfile?.roleId)
        return isRightGranted(role?.rights, RightEnum.CC1__ALL__OWNER)
    })
    // add preset values, even if they were deleted. Otherwise it'll show the ID only.
    const [formInstanceInitialValuesSet, setFormInstanceInitialValuesSet] = useState<boolean>(false)

    useEffect(() => {
        const initialValues =
            !isNew && costCenter
                ? {
                      ...pick(costCenter, ["id", "name", "budgetValue", "budgetCurrency", "budgetPeriod", "responsibleUser", "costCenterType", "parentCostCenter", "sharedUsers"]),
                      budgetsList,
                  }
                : {
                      budgetCurrency: loggedInProfile.settings?.globalCurrency || CurrencyEnum.EUR,
                      budgetPeriod: BudgetPeriodEnum.MONTHLY,
                      responsibleUser: approvers[0]?.id,
                      costCenterType: CostCenterTypeEnum.SUB_COST_CENTER,
                  }
        formInstance.setFieldsValue(initialValues)
        setFormInstanceInitialValuesSet(true)
        onCostCenterTypeChange(initialValues.costCenterType)
    }, [])

    // state
    const initialPeriodNames = isNew ? getPeriodNames(BudgetPeriodEnum.MONTHLY) : getPeriodNames(costCenter?.budgetPeriod, budgetsList)
    const [periodNames, setPeriodNames] = useState<Array<string>>(initialPeriodNames)
    const [previousPeriodValue, setPreviousPeriodValue] = useState(costCenter?.budgetPeriod)
    const [costCenterType, setCostCenterType] = useState<CostCenterTypeEnum>(!isNew && costCenter ? costCenter.costCenterType : CostCenterTypeEnum.SUB_COST_CENTER)

    const changePeriod = (selectedPeriod: BudgetPeriodEnum) => {
        formInstance.setFieldsValue({ budgetsList: [] })
        const newPeriodNames = getPeriodNames(selectedPeriod)
        setPreviousPeriodValue(selectedPeriod)
        setPeriodNames(newPeriodNames)
    }

    const handlePeriodChange = (selectedPeriod: any) => {
        const budgets = formInstance.getFieldValue("budgetsList") || []

        // if user has budgets, warn him/her that all budgets will be lost if period changes
        if (budgets.length) {
            Modal.confirm({
                title: t("confirm:cost_center.budget_period_change.title"),
                content: t("confirm:cost_center.budget_period_change.message"),
                okText: t("confirm:cost_center.budget_period_change.confirm"),
                cancelText: t("confirm:cost_center.budget_period_change.cancel"),
                type: "warning",
                onOk() {
                    changePeriod(selectedPeriod)
                },
                onCancel() {
                    formInstance.setFieldsValue({ budgetPeriod: previousPeriodValue })
                },
            })
        } else {
            changePeriod(selectedPeriod)
        }
    }

    const handleCostCenterTypeSelect = (costCenterType: CostCenterTypeEnum) => {
        setCostCenterType(costCenterType)
        onCostCenterTypeChange(costCenterType)
    }

    const handleSubmit = async (formData: CostCenterFormData) => {
        // transforms formData to costCenter interface data. Adds "_id" and "alternativeBudgets"
        const costCenterData = {
            ...formData,
            ...(!isNew && { _id: costCenter?._id }),
            budgetValue: parseCurrencyInput(formData.budgetValue) || 0,
            alternativeBudgets: getBudgetsFromBudgetsList(formData.budgetsList, formData.budgetCurrency, formData.budgetPeriod),
        }
        // we need to add this because the CostCenterType is not always added to the form. (Only on new instances of the form)
        // if the cost center does not have a pre-established CostCenterType, (the currrent cost centers that users have) we make them by default sub cost centers.
        if (!costCenterData.costCenterType) costCenterData.costCenterType = costCenter?.costCenterType || CostCenterTypeEnum.SUB_COST_CENTER
        delete costCenterData.budgetsList

        if (isNew) {
            try {
                await dispatch(createCostCenter(costCenterData))
                NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:cost_center.created.title"), t("notification:cost_center.created.message"))
                handleHide()
            } catch (err) {
                setIsLoading(false)
                const conflictCostCenter = costCenters.find((costCenter: CostCenter) => {
                    if (costCenterData.costCenterType === CostCenterTypeEnum.COST_CENTER_GROUP) {
                        return costCenter.name === costCenterData.name && costCenter.costCenterType === CostCenterTypeEnum.COST_CENTER_GROUP
                    }
                    return costCenter.id === costCenterData.id
                })
                if (shouldRestoreItem(err, conflictCostCenter)) {
                    // TODO: copy deeply, but convert cost center into class first
                    restoreCostCenter(conflictCostCenter!, costCenterData)
                } else {
                    NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:cost_center.create.title"))
                }
            }
        } else {
            try {
                if (costCenterData?.parentCostCenter === NO_PARENT_COST_CENTER) delete (costCenterData as any).parentCostCenter
                await dispatch(updateCostCenter(costCenterData._id, costCenterData))
                costCenter?.parentCostCenter && (await dispatch(syncOneCostCenter(costCenter?.parentCostCenter)))
                // if user changes cost center period, update the view and change the popover options to the changed budget period
                if (budgetUsageBarGraphOptions.viewPeriod !== formData.budgetPeriod) {
                    const periodString = getBudgetPeriodString(formData.budgetPeriod)
                    const dateRangeObject = getDateRangeObject(PeriodRangeOptionEnum.LAST_12_PERIODS, periodString)
                    await dispatch(
                        setCostCenterBudgetUsageBarGraphOptions({
                            ...budgetUsageBarGraphOptions,
                            viewPeriod: formData.budgetPeriod,
                            startDate: dateRangeObject.startDate.toString(),
                            endDate: dateRangeObject.endDate.toString(),
                        }),
                    )
                }

                NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:cost_center.updated.title"), t("notification:cost_center.updated.message"))
                handleHide()
            } catch (err) {
                setIsLoading(false)
                NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:cost_center.edit.title"))
            }
        }
    }

    const handleRestore = async (costCenter: CostCenter, alernativeResponsibleUserId?: string) => {
        const costCenterToRestore = { ...costCenter, deleted: false, responsibleUser: alernativeResponsibleUserId || costCenter.responsibleUser }
        try {
            await dispatch(updateCostCenter(costCenterToRestore._id, costCenterToRestore))
            NotificationService.send(NotificationTypeEnum.WARNING, t("notification:cost_center.restored.title"), t("notification:cost_center.restored.message"))
            handleHide()
            await dispatch(fetchAllCostCenters())
            history.push(`/costCenters/${costCenterToRestore._id}`)
        } catch (err) {
            setIsLoading(false)
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:cost_center.edit.title"))
        }
    }

    const restoreCostCenter = (costCenterToRestore: CostCenter, costCenterData: any) => {
        const restoreConfirmationModal = Modal.confirm({
            title: t("confirm:cost_center.restore.title"),
            content: t("confirm:cost_center.restore.message"),
            cancelText: t("confirm:cost_center.restore.cancel"),
            type: "warning",
            okText: t("confirm:cost_center.restore.confirm"),
            okButtonProps: { "data-testid": "costCenterRestoreOkButton" } as ButtonProps,
            onOk() {
                // Check that the responsible user of the cost center to restore is an admin or an approver (employees can not be responsible users of cost centers)
                const originalResponsbleUser = employees.find((employee) => employee.id === costCenterToRestore.responsibleUser)
                if (originalResponsbleUser && !AuthzService.isPotentialApprover(originalResponsbleUser?.activeCompanyProfile.roleId)) {
                    restoreConfirmationModal.destroy()
                    const responsbleUserFromForm = employees.find((employee) => employee.id === costCenterData.responsibleUser)
                    return Modal.confirm({
                        title: t("confirm:cost_center.restore.title"),
                        content: t("confirm:cost_center.restore.change_responsible_user", {
                            originalUserName: `${originalResponsbleUser.firstName} ${originalResponsbleUser.lastName}`,
                            newUserName: `${responsbleUserFromForm?.firstName} ${responsbleUserFromForm?.lastName}`,
                        }),
                        cancelText: t("confirm:cost_center.restore.cancel"),
                        type: "info",
                        okText: t("confirm:cost_center.restore.confirm"),
                        onOk() {
                            return handleRestore(costCenterToRestore, responsbleUserFromForm?.id)
                        },
                    })
                }
                return handleRestore(costCenterToRestore)
            },
        })
    }

    if (!formInstanceInitialValuesSet) return <></>

    return (
        <CostCenterForm
            isNew={isNew}
            formInstance={formInstance}
            approvers={approvers}
            employees={employees}
            costCenterGroups={costCenterGroups}
            costCenterType={costCenterType}
            budgetPeriod={costCenter?.budgetPeriod}
            periodNames={periodNames}
            onSubmit={handleSubmit}
            onPeriodSelect={handlePeriodChange}
            onCostCenterTypeSelect={handleCostCenterTypeSelect}
            onFormValuesChange={onFormValuesChange}
        />
    )
}

export default CostCenterFormContainer
