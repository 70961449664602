import { Tax } from "@finway-group/shared/lib/models"
import { Action } from "redux"

export enum TaxTypes {
    FETCH_ALL_TAXES = "FETCH_ALL_TAXES",
    UPDATE_ALL_TAXES = "UPDATE_ALL_TAXES",
}

export interface FetchAllTaxesAction extends Action<typeof TaxTypes.FETCH_ALL_TAXES> {
    type: typeof TaxTypes.FETCH_ALL_TAXES
    taxes: Array<Tax>
}
export interface UpdateAllTaxesAction extends Action<typeof TaxTypes.UPDATE_ALL_TAXES> {
    type: typeof TaxTypes.UPDATE_ALL_TAXES
    taxes: Array<Tax>
}

export type TaxesActions = FetchAllTaxesAction | UpdateAllTaxesAction
