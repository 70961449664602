import { Activity, CollectionNameEnum, TabTypeEnum } from "@finway-group/shared/lib/models"
import { List } from "antd"
import React, { Suspense } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"

import { SpreadsheetDataUploadModal } from "Components/modals/spreadsheetDataUpload/spreadsheetDataUpload.modal"
import { useModal } from "Shared/context/modal.context"
import { useLoggedInEmployee } from "Shared/hooks/employee.hooks"
import { useMarkOneNotificationAsViewed } from "Shared/queries/notification.queries"
import { NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { setActiveTab as setActiveTabAction } from "Shared/store/actions/expense/expenseActions"
import { getActivityDocumentRoute } from "Shared/utils/navbarActivityTemplates.utils"

const ActivitySkeleton = React.lazy(() => import("./activity.skeleton"))
const ActivityCard = React.lazy(() => import("./activityCard"))

interface NotificationListProps {
    notifications: Array<Activity>
    title: string
    onElementClicked: () => void
}

const NotificationList = ({ notifications, title, onElementClicked }: NotificationListProps) => {
    const history = useHistory()
    const { t } = useTranslation()
    const { showModal } = useModal()
    const dispatch = useDispatch()

    const loggedInEmployee = useLoggedInEmployee()

    const markOneNotificationsAsViewed = useMarkOneNotificationAsViewed()

    const handleActivityItemSelected = async (activity: Activity) => {
        if (!activity) return

        markOneNotificationsAsViewed.mutateAsync({ activity, viewedBy: loggedInEmployee.id }).catch((error) => {
            NotificationService.showErrorNotificationBasedOnResponseError(error, t("error:error"))
        })

        // for data uploads we show a modal on click instead of redirecting to a page
        if (activity?.collectionName === CollectionNameEnum.IMPORT_RESULT) {
            showModal(SpreadsheetDataUploadModal, true, { isShowing: true, importResultId: activity.documentId })
            return
        }

        const activityRoute = await getActivityDocumentRoute(activity)
        if (activityRoute.length === 0) {
            NotificationService.send(NotificationTypeEnum.WARNING, t("error:notifications.title"), t("error:notifications.message"))
            return
        }

        if (activity.collectionName === CollectionNameEnum.INBOX_INVOICE) dispatch(setActiveTabAction(TabTypeEnum.INBOX_INVOICES))

        onElementClicked()

        history.push(activityRoute)
    }

    return (
        <>
            {notifications.length > 0 && (
                <List split={false}>
                    <span className="text-text m-5">{title}</span>
                    {notifications.map((activity) => (
                        <List.Item
                            key={activity.id}
                            className="finway-notification-element mx-5"
                            onClick={() => {
                                handleActivityItemSelected(activity)
                            }}
                        >
                            <Suspense fallback={<ActivitySkeleton />}>
                                <ActivityCard key={activity.id} activity={activity} loggedInUser={loggedInEmployee} />
                            </Suspense>
                        </List.Item>
                    ))}
                </List>
            )}
        </>
    )
}

export default React.memo(NotificationList)
