import { RightEnum, SystemRightEnum } from "@finway-group/shared/lib/models"
import * as Sentry from "@sentry/react"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useLocation } from "react-router-dom"

import { AXIOS_EXPIRED_MESSAGE, AXIOS_LOGOUT_MESSSAGE } from "Shared/config/consts"
import { ErrorTypes } from "Shared/error.types"
import { useAuth } from "Shared/hooks/auth.hooks"
import { useIsUserRightsReady } from "Shared/hooks/authz.hooks"
import { useCompany } from "Shared/hooks/company.hooks"
import { useIsPaidVersion } from "Shared/hooks/featureFlags.hooks"
import { RoutePathEnum } from "Shared/router/RoutePath.enum"
import { AuthzService, NotificationService, UtilityService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { fetchDashboardInboxInvoicesCount } from "Shared/store/actions/inboxInvoice/inboxInvoiceActions"
import { KYBData } from "Shared/store/reducers/corporateReducer"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

import { ThunkDispatchResult } from "."
import { fetchAllBankConnections } from "./actions/bank/bankActions"
import { fetchAllCompanies } from "./actions/company/companyActions"
import { fetchKYBProgress, setKYBData } from "./actions/corporate/corporateActions"
import { fetchAllCostCenters2 } from "./actions/costCenter2/costCenter2Actions"
import { fetchAllCostCenters } from "./actions/costCenter/costCenterActions"
import { fetchAllCsvTemplates } from "./actions/csvTemplates/csvTemplateActions"
import { fetchCurrencyExchange } from "./actions/currency/currencyActions"
import { fetchAllEmployees } from "./actions/employee/employeeActions"
import { fetchDashboardExpensesCounts } from "./actions/expense/expenseActions"
import { fetchAllExpenseAccounts } from "./actions/expenseAccount/expenseAccountActions"
import { fetchAllExpenseTags } from "./actions/expenseTag/expenseTagActions"
import { resetFetchFlag, setHasLoaded, setLoading } from "./actions/fetcher/fetcherActions"
import { fetchAllForecastRules, fetchAllScenarios } from "./actions/liquidity/liquidityActions"
import { fetchPaymentSettings } from "./actions/payment/paymentActions"
import { fetchPerDiemDestinations } from "./actions/perDiemDestination/perDiemDestinationActions"
import { fetchAllRoles } from "./actions/role/roleActions"
import { fetchAllFilters, initialFetchTables } from "./actions/tables/tableActions"
import { fetchAllTaxes } from "./actions/tax/taxActions"
import { fetchAllTeams } from "./actions/team/teamActions"
import { fetchAllTransactionCategories } from "./actions/transactionCategory/transactionCategoryAction"
import { fetchAllVendors } from "./actions/vendor/vendorActions"
import { fetchAllWorkflows } from "./actions/workflow/workflowActions"
import { RootState } from "./rootState.interface"

const DataFetcher = React.memo(() => {
    const dispatch: ThunkDispatchResult = useDispatch()

    const { t } = useTranslation()
    const { logout, isAuthenticated, userClass, featureFlags } = useAuth()

    const history = useHistory()
    const location = useLocation()
    const apiVersion = useSelector((state: RootState) => state.fetcher.apiVersion)
    const isUserRightReady = useIsUserRightsReady()

    const [isFetching, setIsFetching] = useStateIfMounted(false)

    const selectedCompany = useCompany()
    const isPaidVersion = useIsPaidVersion()

    const fetchCompanies = async () => dispatch(fetchAllCompanies())

    const createFetchTasks = () => {
        const tasks: Array<any> = []
        const add = (task: any) => tasks.push(dispatch(task))

        add(fetchAllRoles())
        add(initialFetchTables())
        add(fetchAllEmployees())
        add(fetchAllTeams())

        if (AuthzService.isRightGrantedForLoggedInUser(RightEnum.BANK_ACCOUNT__ALL__READ)) {
            add(fetchAllBankConnections())
        }

        if (AuthzService.isRightGrantedForLoggedInUser(RightEnum.LIQUIDITY__ALL__READ)) {
            add(fetchAllScenarios())
            add(fetchAllForecastRules())
        }

        if (AuthzService.isRightGrantedForLoggedInUser(RightEnum.TRANSACTION__ALL__READ) || AuthzService.isRightGrantedForLoggedInUser(RightEnum.LIQUIDITY__ALL__READ)) {
            add(fetchAllTransactionCategories())
        }

        if (featureFlags?.stripeEnabled && AuthzService.isSystemRightGrantedForLoggedInUser(SystemRightEnum.SYS__PAYMENT_SETTINGS__ALL__MANAGE)) {
            add(fetchPaymentSettings())
        }

        if (isPaidVersion) {
            add(fetchDashboardExpensesCounts())
            add(fetchDashboardInboxInvoicesCount())
            add(fetchCurrencyExchange())
            add(fetchAllCostCenters2())
            add(fetchAllTaxes())
            add(fetchAllWorkflows())
            add(fetchAllExpenseAccounts())
            add(fetchAllExpenseTags())
            add(fetchPerDiemDestinations())
            add(fetchAllCostCenters())
            add(fetchKYBProgress())

            // No need to block the vendors for now since the vendor data is required currently for expense detail and form purposes.
            // TODO - Once we have a better way to load these vendors asynchronously (somehow getting just the right vendors on demand, probably with react query?), add AuthzService.isRightGrantedForLoggedInUser(RightEnum.VENDOR__ALL__READ) check here.
            add(fetchAllVendors())

            if (featureFlags?.customCsvTemplatesEnabled) {
                add(fetchAllCsvTemplates())
            }
        }

        return tasks
    }

    const fetchData = async () => {
        await dispatch(setLoading())
        await dispatch(setKYBData({} as KYBData))
        if (isAuthenticated && !isFetching) {
            try {
                setIsFetching(true)
                const remoteApiVersion = await UtilityService.getApiVersion()
                console.log(`===== API version ${remoteApiVersion} =====`)

                await dispatch(fetchAllFilters())
                await Promise.allSettled(createFetchTasks())
                await dispatch(setHasLoaded())
                await dispatch(resetFetchFlag())
            } catch (err) {
                if ([AXIOS_LOGOUT_MESSSAGE, AXIOS_EXPIRED_MESSAGE, ErrorTypes.ACCESS_TOKEN_MISSING].includes(err.message)) {
                    await logout()
                    return
                }

                NotificationService.send(NotificationTypeEnum.ERROR, t("error:loader.title"), t("error:loader.message"))
                // if we encounter an error when fetching, forward to
                // error page and logout to delete the local storage
                await logout()
                history.push(RoutePathEnum.ERROR)
            } finally {
                setIsFetching(false)
            }
        }
    }

    useEffect(() => {
        let enableFetching = true

        // TEMP CODE: If 6.0 migration has not completed but user is logged prevent errors and log him out
        if (isAuthenticated && !userClass.companyProfiles?.[0].roleId) {
            logout()
        }

        // skip login page
        if (location.pathname.indexOf("login") > -1) {
            enableFetching = false
        }

        // if Authenticated but companies are not fetched we should fetch companies
        if (isAuthenticated && !selectedCompany._id) {
            enableFetching = false
            fetchCompanies()
        }

        if (selectedCompany?.shouldRefetch && enableFetching && isAuthenticated) {
            if (!isUserRightReady) {
                Sentry.captureMessage(`Redux is not yet rehydrated, although the access token is present.`)
                logout()
                return
            }

            fetchData()
        }

        return () => {
            enableFetching = false
        }
    }, [isAuthenticated, apiVersion, selectedCompany?.shouldRefetch, selectedCompany?._id])

    return <React.Fragment />
})

export default DataFetcher
