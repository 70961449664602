import {
    ApprovalScopeEnum,
    ApprovalStatusEnum,
    CostCenter,
    CreditorInterface,
    CurrencyEnum,
    Employee,
    ErrorCodeEnum,
    Expense,
    ExpenseKindEnum,
    ExpenseStatusEnum,
    ExportFormatEnum,
    ExtendedReferencedTax,
    FileUploadDuplicateData,
    RightEnum,
    SubscriptionTypeEnum,
    TabTypeEnum,
    VendorTypeEnum,
} from "@finway-group/shared/lib/models"
import { ExpensePaymentOptionEnum } from "@finway-group/shared/lib/models/expense/expensePaymentOption.enum"
import { GetEmployeeById } from "@finway-group/shared/lib/models/user/employee.model"
import { flattenExpression, getApprovalProcess } from "@finway-group/shared/lib/utils"
import { getApproverIdsOfApprovalProcesses } from "@finway-group/shared/lib/utils/approvalProcess.utils"
import httpStatus from "http-status"
import { TFunction } from "i18next"

import { FINWAY_ADMIN_EMAIL, NO_TAX_RATE } from "Shared/config/consts"
import { useActiveTab } from "Shared/hooks/expense.hooks"
import i18n from "Shared/locales/i18n"
import { AuthzService, CompanyService, CurrencyService, ExpenseHttpService, FileService, NotificationService, TableService, TaxService, UserService } from "Shared/services"
import DialogService from "Shared/services/dialog.service"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import store from "Shared/store"
import { TablesEnum } from "Shared/store/reducers/tableConfigReducer"
import {
    addCostCenterResponsible,
    addSuperiorToApproverList,
    ensureNextApproverInDropdown,
    isEditButtonDisabled,
    isExpenseArchived,
    statusWithCreditorNumberExpected,
} from "Shared/utils/expense.utils"

const ExpenseService = {
    fetchSubscriptions: async (sortingCriteria = "") => {
        const subscriptionFilter = `&kind[eq]=${ExpenseKindEnum.SUBSCRIPTION}&subscriptionType[eq]=${SubscriptionTypeEnum.INITIAL_REQUEST}&status[in]=${ExpenseStatusEnum.PAID},${ExpenseStatusEnum.DONE}`
        const { expenses } = await ExpenseHttpService.fetchExpenses(subscriptionFilter, sortingCriteria, 1, 1000)
        return expenses
    },
    searchFolders: async (searchString = "") => {
        const archiveAfterXDaysQueryString = `&archiveAfterXDays[eq]=${store.getState().company.item.archiveAfterXDays}`
        const query = `${TableService.getTableSearchQueryString(TablesEnum.ALL_REQUESTS, searchString)}&kind[eq]=${
            ExpenseKindEnum.TRIP_FOLDER
        }&isManuallyArchived[nis]=true&folderName[contains]=${searchString}&${archiveAfterXDaysQueryString}&deleted[is]=false`
        const { expenses } = await ExpenseHttpService.fetchExpenses(query, "", 1, 20)
        return expenses
    },
    fetchFolderContents: async (folderId: string) => {
        const query = `&folderId[eq]=${folderId}`
        const { expenses } = await ExpenseHttpService.fetchExpenses(query, "", 1, 1000)
        return expenses
    },
    /**
     * if this returns true, that means the there's no duplicate, or the user clicked on "continue".
     * if this returns false, that means the there's a duplicate AND the user clicked on "back"
     */
    checkForDuplicate: async (expense: Expense, invoiceNumber?: string, options?: { checkInvoice?: boolean; checkExpense?: boolean; ignoreIds?: Array<string> }) => {
        const data = await ExpenseHttpService.checkForDuplicate({ id: expense.id, invoiceNumber: invoiceNumber || expense.invoiceNumber, ...(options || {}) })
        return !data.isDuplicate || (await ExpenseService.checkForDuplicateInvoiceNumber(data))
    },
    checkForDuplicateInvoiceFile: async (conflictingData: FileUploadDuplicateData) =>
        !conflictingData.isDuplicate || (await DialogService.confirmDuplicateInvoiceFileWarning(conflictingData)),

    checkForDuplicateInvoiceNumber: async (conflictingData: any) => !conflictingData.isDuplicate || (await DialogService.confirmDuplicateInvoiceNumberWarning(conflictingData)),
    getTableAndAdjacentExpenseLabel: (status: ExpenseStatusEnum, requestedBy: string): [TablesEnum, string] => {
        const loggedInUser = UserService.getLoggedInEmployeeProfile()
        const isRequestedByLoggedInUser = loggedInUser.id == requestedBy

        const activeTab = useActiveTab()

        if (activeTab === TabTypeEnum.ALL) {
            return [TablesEnum.ALL_REQUESTS, "all"]
        }

        switch (status) {
            case ExpenseStatusEnum.PURCHASE_PENDING:
                return isRequestedByLoggedInUser ? [TablesEnum.TODO_APPROVAL_PENDING, "approval_pending"] : [TablesEnum.TODO_PURCHASE_APPROVAL, "purchase_approval_needed"]
            case ExpenseStatusEnum.DOCS_NEEDED:
                return [TablesEnum.TODO_DOCS_NEEDED, "docs_needed"]
            case ExpenseStatusEnum.INVOICE_PENDING:
                return isRequestedByLoggedInUser ? [TablesEnum.TODO_APPROVAL_PENDING, "approval_pending"] : [TablesEnum.TODO_INVOICE_APPROVAL, "invoice_approval_needed"]
            case ExpenseStatusEnum.APPROVED:
                return AuthzService.isRightGrantedForLoggedInUser(RightEnum.EXPENSE__ALL__REVIEW)
                    ? [TablesEnum.REVIEW_REQUESTS, "in_review"]
                    : [TablesEnum.IN_PROGRESS, "in_progress"]
            case ExpenseStatusEnum.REVIEWED:
                return AuthzService.isRightGrantedForLoggedInUser(RightEnum.EXPENSE__ALL__EXPORT)
                    ? [TablesEnum.PAY_AND_EXPORT_TO_BE_PAID, "reviewed"]
                    : [TablesEnum.IN_PROGRESS, "in_progress"]
            case ExpenseStatusEnum.PAID:
                return AuthzService.isRightGrantedForLoggedInUser(RightEnum.EXPENSE__ALL__EXPORT)
                    ? [TablesEnum.PAY_AND_EXPORT_TO_BE_EXPORTED, "paid"]
                    : [TablesEnum.IN_PROGRESS, "in_progress"]
            case ExpenseStatusEnum.DONE:
            case ExpenseStatusEnum.DECLINED:
                return [TablesEnum.DONE_REQUESTS, "done"]
            default:
                return [TablesEnum.TODO_PURCHASE_APPROVAL, ""]
        }
    },
    exportSepa: async ({
        expenses,
        subjectLines,
        exportFormat,
    }: {
        expenses: Array<Expense>
        subjectLines: Record<string, string>
        exportFormat: ExportFormatEnum.SEPA | ExportFormatEnum.NON_SEPA
    }) => {
        try {
            const response = await FileService.export(`&_id[in]=${expenses.map((exp) => exp.id).join(",")}&format=${exportFormat}`, false, subjectLines)
            const isAsyncExport = response?.status === httpStatus.ACCEPTED
            if (response?.data?.hasAmountMismatch) {
                NotificationService.send(
                    NotificationTypeEnum.WARNING,
                    i18n.t("notification:banking:amounts_mismatch:title"),
                    i18n.t("notification:banking:amounts_mismatch:message"),
                    10,
                )
            }

            if (isAsyncExport) {
                const filteredOutExpenseNumbers = response?.data.filteredOutExpenseNumbers
                if (filteredOutExpenseNumbers?.length) {
                    NotificationService.send(
                        NotificationTypeEnum.WARNING,
                        i18n.t(`notification:request.async_export_started_partial.title`),
                        i18n.t(`notification:request.async_export_started_partial.message`, { expenses: filteredOutExpenseNumbers.join(", ") }),
                        0,
                    )
                } else {
                    NotificationService.send(NotificationTypeEnum.INFO, i18n.t(`notification:async_export_started.title`), i18n.t(`notification:async_export_started.message`), 0)
                }
            } else {
                if (response.data.filteredOutExpenseNumbers?.length) {
                    NotificationService.send(
                        NotificationTypeEnum.WARNING,
                        i18n.t(`notification:request.exported_bulk_partial.title`),
                        i18n.t(`notification:request.exported_bulk_partial.message`, { expenses: response.data.filteredOutExpenseNumbers.join(", ") }),
                        0,
                    )
                }
                FileService.downloadFileAsSignedUrl(response?.data.fileUrl)
            }
        } catch (error) {
            NotificationService.showErrorNotificationBasedOnResponseError(error, i18n.t("error:file:export:title"))
            if (error.response?.data?.errorCode === ErrorCodeEnum.INVALID_EXPENSE_AMOUNTS) {
                NotificationService.send(
                    NotificationTypeEnum.WARNING,
                    i18n.t("notification:banking:amounts_mismatch:title"),
                    i18n.t("notification:banking:amounts_mismatch:message"),
                    10,
                )
            }
        }
    },
    isExpenseEditableBasedOnArchive: (isArchived: boolean) => !isArchived || !CompanyService.doesCompanyEnforceGobdCompliantInvoice(),
    isCreditorNumberReferenceMissing: (expense: Expense) => {
        const expenseNeedsCreditorNumber = statusWithCreditorNumberExpected.includes(expense.status)
        const creditor = expense.getCreditor()
        const creditorNumberAvailable = creditor?.type === VendorTypeEnum.VENDOR || creditor?.creditorNumber
        const userCanSee = AuthzService.isRightGrantedForLoggedInUser(RightEnum.EMPLOYEE__ALL__READ)
        return expenseNeedsCreditorNumber && !creditorNumberAvailable && userCanSee
    },
    isCreditorNumberMissing: (expense: Expense, vendor: CreditorInterface) =>
        statusWithCreditorNumberExpected.includes(expense.status) &&
        vendor?.type === VendorTypeEnum.USER &&
        !vendor.creditorNumber &&
        AuthzService.isRightGrantedForLoggedInUser(RightEnum.EMPLOYEE__ALL__READ),
    getAutoApprovalLimit: (employees: Array<Employee>, requestedBy: Employee, expenseCurrency: CurrencyEnum) => {
        const autoApprover = employees.find(({ email }) => email === FINWAY_ADMIN_EMAIL)
        const autoApprovalLimit = requestedBy?.activeCompanyProfile.approvals?.find(({ approver }) => approver === autoApprover?.id)
        const limitValue =
            autoApprovalLimit &&
            (autoApprovalLimit.currency === expenseCurrency
                ? autoApprovalLimit.limit
                : CurrencyService.convert(autoApprovalLimit.limit, autoApprovalLimit.currency, expenseCurrency))

        return limitValue
    },
    determinePossibleApproversForExpense: (
        expense: Expense,
        employees: Array<Employee>,
        costCenters: Array<CostCenter>,
        loggedInUser: Employee,
        isPreApprovedSubscription: boolean,
        filterRequester = true,
    ) => {
        const requestedBy = GetEmployeeById(employees, expense.requestedBy._id)
        const expensePrice = loggedInUser.settings.showGrossAmount ? expense.totalGrossPrice : expense.totalNetPrice

        // filter requester is by default set to true for expenses approval, and set to false for expense tagging list
        let filteredEmployees = employees.filter(
            (employee) =>
                !employee.activeCompanyProfile?.deleted &&
                (AuthzService.isPotentialApprover(employee.activeCompanyProfile.roleId) || (!filterRequester && employee.id === requestedBy?.id)),
        )

        // if not in the requester's limits and if the request's payment option is smart card, remove auto approver
        if (requestedBy) {
            // do allow it however if the card is already created
            const isAutoApproverAllowedForCardRequest = expense.card !== undefined

            const limit = ExpenseService.getAutoApprovalLimit(employees, requestedBy, expense.currency)
            if (!limit || limit < expensePrice || (expense?.paymentOption === ExpensePaymentOptionEnum.SMART_CARD && !isAutoApproverAllowedForCardRequest)) {
                filteredEmployees = filteredEmployees.filter(({ email }) => email !== FINWAY_ADMIN_EMAIL)
            }
        }

        // also add the superior to the list
        if (requestedBy?.activeCompanyProfile.superior) {
            filteredEmployees = addSuperiorToApproverList({ superiorId: requestedBy.activeCompanyProfile.superior, employees, filteredEmployees })
        }

        // for preapproved, also add the CC responsible to the list
        filteredEmployees = addCostCenterResponsible({ filteredEmployees, costCenters, expense })

        // requester should not be a possible approver himself
        if (requestedBy && filterRequester) filteredEmployees = filteredEmployees.filter((employee) => employee?.id !== requestedBy.id)

        // lastly remove all that are listed in the limits and we already exceeded their approval limit
        filteredEmployees = ExpenseService.removeEmployeeWithExceedLimitFromApprovers({ filteredEmployees, expense, expensePrice, requestedBy })

        // This will make sure that the next approver of current approval process is shown in the dropdown.
        filteredEmployees = expense.approvalProcesses.length ? ensureNextApproverInDropdown({ expense, employees, filteredEmployees }) : filteredEmployees

        // if pre-approved sub and card doesn't have to be created, enable auto-approver
        if (
            isPreApprovedSubscription &&
            AuthzService.isLoggedInUserGlobalApprover() &&
            !filteredEmployees.find(({ email }) => email === FINWAY_ADMIN_EMAIL) &&
            (expense.card || expense.paymentOption !== ExpensePaymentOptionEnum.SMART_CARD)
        ) {
            const autoApprover = employees.find(({ email }) => email === FINWAY_ADMIN_EMAIL)
            autoApprover && filteredEmployees.push(autoApprover)
        }

        return filteredEmployees
    },
    confirmAdminNotOwnApproval: async (expense: Expense, scope: ApprovalScopeEnum, user: Employee, approverName: string) => {
        // if user is admin check if we need to show a confirmation modal if he/she is going to approve for someone else
        const approvalProcess = getApprovalProcess(scope, expense.approvalProcesses)
        const activeStep = approvalProcess?.steps.find(({ status }) => status === ApprovalStatusEnum.IN_PROGRESS)
        const stepApprovals = activeStep ? flattenExpression(activeStep.approvalExpression) : []
        const userPendingApprovals = stepApprovals.filter((approval) => !approval.approvedBy && !approval.rejectedBy && approval.approver._id === user.id)

        // If user still has own approvals pending, no need to ask for a confirm. He/she will approve his/her pending approval.
        if (userPendingApprovals.length) return true

        return DialogService.confirmAdminNotOwnApprovalModal(approverName, expense.kind === ExpenseKindEnum.TRIP_FOLDER)
    },
    shouldDisplayUserAsTagged: (isMyExpense: boolean, isApproverOfAnyApprovalProcess: boolean, loggedInProfile: Employee, expense: Expense) => {
        if (!expense.userTagging) return false
        const taggedUsers = expense.userTagging?.map((tagging) => tagging.tagged)
        return !isMyExpense && !isApproverOfAnyApprovalProcess && !AuthzService.isLoggedInUserAllowedToApproveExpense(expense) && taggedUsers?.includes(loggedInProfile.id)
    },
    isEditButtonVisible: (expense: Expense, loggedInUser: Employee, archiveAfterXDays: number) => {
        const isMyExpense = expense.requestedBy?._id === loggedInUser.id
        const isApproverOfAnyApprovalProcess = getApproverIdsOfApprovalProcesses(expense.approvalProcesses).includes(loggedInUser.id)
        const isExpenseRelevantToMe = isMyExpense || isApproverOfAnyApprovalProcess || AuthzService.isLoggedInUserAllowedToApproveExpense(expense)
        const isArchived = isExpenseArchived(expense, archiveAfterXDays)

        // expense.isReadOnly is pre-calculated in the backend's getOne expense endpoint to calculate the user's permission to edit the expense. If this is not provided, calculate by ourself.
        const isReadOnly = expense.isReadOnly ?? !isExpenseRelevantToMe

        return !isReadOnly && ExpenseService.isExpenseEditableBasedOnArchive(isArchived) && !expense.deleted
    },
    calculateExpenseEditButtonView: (
        expense: Expense,
        loggedInUser: Employee,
        archiveAfterXDays: number,
        t: TFunction,
    ): { buttonVisible: boolean; buttonDisabled: boolean; reasonToDisplay?: string; expenseEditable: boolean } => {
        const isLoggedInUserGlobalApprover = AuthzService.isLoggedInUserGlobalApprover()
        const { disabled, reason } = isEditButtonDisabled(expense, t, isLoggedInUserGlobalApprover)
        const visible = ExpenseService.isEditButtonVisible(expense, loggedInUser, archiveAfterXDays)

        return {
            buttonVisible: visible,
            expenseEditable: visible && !disabled,
            buttonDisabled: disabled,
            reasonToDisplay: reason,
        }
    },
    onlyAllowFileExports: (expense: Expense) =>
        ![ExpenseStatusEnum.REVIEWED, ExpenseStatusEnum.PAID, ExpenseStatusEnum.DONE].includes(expense.status) ||
        !AuthzService.isRightGrantedForLoggedInUser(RightEnum.EXPENSE__ALL__EXPORT) ||
        expense.deleted,
    removeEmployeeWithExceedLimitFromApprovers: ({
        filteredEmployees,
        requestedBy,
        expense,
        expensePrice,
    }: {
        filteredEmployees: Array<Employee>
        expense: Expense
        expensePrice: number
        requestedBy?: Employee
    }) => {
        filteredEmployees.filter((employee: Employee) => {
            if (!employee) return false
            if (AuthzService.isGlobalApprover(employee.activeCompanyProfile.roleId) && employee.email !== FINWAY_ADMIN_EMAIL) return true

            const foundInApproval = requestedBy?.activeCompanyProfile.approvals?.find((approval) => approval.approver === employee.id)
            if (foundInApproval) {
                const limit =
                    foundInApproval.currency === expense.currency
                        ? foundInApproval.limit
                        : CurrencyService.convert(foundInApproval.limit, foundInApproval.currency, expense.currency)
                return limit >= expensePrice
            }
            return true
        })
        return filteredEmployees
    },
    getExpenseTaxRate: (taxRate?: Partial<ExtendedReferencedTax>): ExtendedReferencedTax => {
        if (taxRate?._id) {
            const newTaxRate = TaxService.getTaxById(taxRate._id)
            if (newTaxRate) return newTaxRate
        }

        if (taxRate?.taxRate || taxRate?.taxRate === 0) {
            const newTaxRate = TaxService.getTaxByTaxRate(taxRate?.taxRate)
            if (newTaxRate) return newTaxRate
        }

        return {
            _id: NO_TAX_RATE,
        }
    },
}

export default ExpenseService
