import { SystemRightEnum } from "@finway-group/shared/lib/models"
import { Alert, Button } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import { useCompany } from "Shared/hooks/company.hooks"
import { useFeatureFlags } from "Shared/hooks/featureFlags.hooks"
import { usePaymentSettings } from "Shared/hooks/payment.hooks"
import { RoutePathEnum } from "Shared/router/RoutePath.enum"
import { AuthzService } from "Shared/services"

const PaymentSignUpBanner = () => {
    const { t } = useTranslation()
    const history = useHistory()
    const featureFlags = useFeatureFlags()
    const company = useCompany()
    const paymentSettings = usePaymentSettings()

    const isHidden =
        // 1. Only render alert for sys admind
        !AuthzService.isSystemRightGrantedForLoggedInUser(SystemRightEnum.SYS__PAYMENT_SETTINGS__ALL__MANAGE) ||
        // 2. Currently we only allow stripe integration for paid customser (can be disabled in the future but also requires enabling the billing settings in the setting menu)
        !featureFlags?.paidVersion ||
        // 3. Don't show the banner at all if stripe is disabled
        !featureFlags?.stripeEnabled ||
        // 4. If paymentsettings are not available (fetching or missing) don't show the banner either
        !company.isRoot ||
        // 5. Only show the banner if the customer has not entered the payment method yet
        paymentSettings.isFetching ||
        !!paymentSettings?.data?.paymentMethod?.paymentMethodId

    if (isHidden) return null

    return (
        <Alert
            message={<span className="font-bold">{t("notification:payment_settings.sign_up_banner.title")}</span>}
            description={
                <div className="flex items-start justify-between space-x-20">
                    <div>
                        <p className="mb-10">{t("notification:payment_settings.sign_up_banner.message")}</p>
                    </div>
                    <Button type="primary" onClick={() => history.push(RoutePathEnum.SETTINGS_BILLING_TAB_PAYMENT_METHOD)}>
                        {t("action:payment_settings.add_payment_method")}
                    </Button>
                </div>
            }
            type="warning"
            showIcon={true}
        />
    )
}

export default PaymentSignUpBanner
