import { Button, Col, Row } from "antd"
import Modal from "antd/lib/modal/Modal"
import React from "react"
import { useTranslation } from "react-i18next"

interface LegendItem {
    id: string
    color: string
    name: string
}

interface LegendDetailModalInterface {
    items: Array<LegendItem>
    isVisible: boolean
    onClose: () => void
}

const LegendDetailModal: React.FC<LegendDetailModalInterface> = ({ items, isVisible, onClose }) => {
    const { t } = useTranslation()

    return (
        <Modal
            visible={isVisible}
            maskClosable={false}
            onCancel={onClose}
            title={t("label:legend")}
            destroyOnClose
            closable
            className="ant-modal--small"
            footer={
                <Button key="back" onClick={onClose}>
                    {t("action:close")}
                </Button>
            }
        >
            <Row className={"overflow-y-auto overflow-x-hidden max-h-250"} gutter={[0, 16]}>
                {items.map((data: LegendItem, index: number) => (
                    <div key={index}>
                        <Col span={24} className="ml-10 my-auto">
                            <div className="mr-10 float-left">
                                <span className="h-10 w-10 flex inline-flex" style={{ backgroundColor: `${data.color}` }}></span>{" "}
                                <label style={{ textOverflow: "ellipsis" }}>{data.name} </label>
                            </div>
                        </Col>
                    </div>
                ))}
            </Row>
        </Modal>
    )
}

export default LegendDetailModal
