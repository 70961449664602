import { TransactionCategoryTypeEnum } from "@finway-group/shared/lib/models"
import { Col, Form, Input, Radio, Row, Select } from "antd"
import { FormInstance } from "antd/lib/form"
import { Store } from "antd/lib/form/interface"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import { ModalTypeEnum } from "Components/modals/transactionCategory.modal"
import { useTransactionCategoryGroupsByType } from "Shared/hooks/transactionCategory.hooks"
import { doFilterSelect } from "Shared/utils/helper.utils"
import { getPopupAnchor } from "Shared/utils/popup.utils"

import TransactionCategoryRules from "./rules/transactionCategory.rules"

interface TransactionCategoryFormInterface {
    formInstance: FormInstance
    transactionCategoryType: TransactionCategoryTypeEnum
    modalType: ModalTypeEnum
    onSubmit: (values: Store) => void
    onParentSelect?: () => void
}
const TransactionCategoryForm: React.FC<TransactionCategoryFormInterface> = ({ modalType, formInstance, onSubmit, onParentSelect, transactionCategoryType }) => {
    const { t } = useTranslation()
    const rules = TransactionCategoryRules()
    const transactionCategoryGroups = useTransactionCategoryGroupsByType(transactionCategoryType)

    const [isGroup, setIsGroup] = useState<boolean>(false)

    return (
        <Form layout="vertical" form={formInstance} onFinish={onSubmit} scrollToFirstError={true}>
            {modalType === ModalTypeEnum.CREATE && (
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Form.Item name="isGroup">
                            <div className="card-modal">
                                <Radio.Group defaultValue={isGroup} onChange={(e) => setIsGroup(e.target.value)}>
                                    <Radio.Button value={true}>{t("label:transaction_category_group")}</Radio.Button>
                                    <Radio.Button value={false}>{t("label:transaction_category")}</Radio.Button>
                                </Radio.Group>
                            </div>
                        </Form.Item>
                    </Col>
                </Row>
            )}
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Form.Item label={t("label:name")} name="name" rules={rules.name} required>
                        <Input required autoComplete="off" />
                    </Form.Item>
                </Col>
            </Row>
            {modalType === ModalTypeEnum.CREATE && isGroup === false && (
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Form.Item label={t("label:transaction_category_group")} name="parent" extra={<p className="input-help">{t("info:category_will_be_a_subcategory")}</p>}>
                            <Select showSearch filterOption={doFilterSelect} onSelect={onParentSelect} getPopupContainer={getPopupAnchor()}>
                                {transactionCategoryGroups.map((transactionCategory) => (
                                    <Select.Option key={transactionCategory.id} label={transactionCategory.name} value={transactionCategory.id}>
                                        <div className="flex items-center">
                                            <p>{transactionCategory.name}</p>
                                        </div>
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            )}
        </Form>
    )
}

export default TransactionCategoryForm
