import { CostCenter2 } from "@finway-group/shared/lib/models"
import { Action } from "redux"

export enum CostCenter2ActionTypes {
    FETCH_ALL_COST_CENTERS_2 = "FETCH_ALL_COST_CENTERS_2",
    DELETE_MANY_COST_CENTERS_2 = "DELETE_MANY_COST_CENTERS_2",
    UPDATE_MANY_COST_CENTERS_2 = "UPDATE_MANY_COST_CENTERS_2",
    CREATE_MANY_COST_CENTERS_2 = "CREATE_MANY_COST_CENTERS_2",
}

export interface FetchAllCostCenters2Action extends Action<typeof CostCenter2ActionTypes.FETCH_ALL_COST_CENTERS_2> {
    type: typeof CostCenter2ActionTypes.FETCH_ALL_COST_CENTERS_2
    costCenters2: Array<CostCenter2>
}

export interface DeleteManyCostCenters2Action extends Action<typeof CostCenter2ActionTypes.DELETE_MANY_COST_CENTERS_2> {
    type: typeof CostCenter2ActionTypes.DELETE_MANY_COST_CENTERS_2
    costCenters2: Array<CostCenter2>
}

export interface UpdateManyCostCenters2Action extends Action<typeof CostCenter2ActionTypes.UPDATE_MANY_COST_CENTERS_2> {
    type: typeof CostCenter2ActionTypes.UPDATE_MANY_COST_CENTERS_2
    costCenters2: Array<CostCenter2>
}

export interface CreateManyCostCenters2Action extends Action<typeof CostCenter2ActionTypes.CREATE_MANY_COST_CENTERS_2> {
    type: typeof CostCenter2ActionTypes.CREATE_MANY_COST_CENTERS_2
    costCenters2: Array<CostCenter2>
}

export type CostCenter2Actions = FetchAllCostCenters2Action | DeleteManyCostCenters2Action | UpdateManyCostCenters2Action | CreateManyCostCenters2Action
