import { useEffect, useRef } from "react"

const usePrevious = (value: any, initialValue: any) => {
    const ref = useRef(initialValue)
    useEffect(() => {
        ref.current = value
    })
    return ref.current
}

/**
 * @param effectCallback the useEffect callback.
 * @param dependencies deps array
 * @param dependencyNames (optional) dependency names array for naming properties of the changedDeps prop.
 * @param customHook (optional) any custom hook you want to run instead of the default useEffect.
 */
export const useChangeEffect = (effectCallback: (changedDeps: any) => any, dependencies: any, dependencyNames: Array<string> = [], customHook?: any) => {
    const previousDeps = usePrevious(dependencies, [])

    const changedDeps: any = {}
    for (let i = 0; i < dependencies.length; i++) {
        if (dependencies[i] !== previousDeps[i]) {
            const keyName = dependencyNames[i] || i
            changedDeps[keyName] = { previousValue: previousDeps[i], currentValue: dependencies[i] }
        }
    }

    if (customHook) {
        customHook(() => effectCallback(changedDeps), dependencies)
    } else {
        useEffect(() => effectCallback(changedDeps), dependencies)
    }
}
