import { SsoProviderTypeEnum } from "@finway-group/shared/lib/models"
import { UserLanguageEnum } from "@finway-group/shared/lib/models/user/userSettings.interface"

// eslint-disable-next-line import/prefer-default-export
export const SSO_SETUP_RESOURCES = {
    [SsoProviderTypeEnum.GOOGLE]: {
        clientIds: {
            [UserLanguageEnum.DE]: "https://help.finway.de/de/articles/160560-google-single-sign-on-sso#h_247e5f04e8",
            [UserLanguageEnum.EN]: "https://help.finway.de/en/articles/160560-google-single-sign-on-sso#h_190ce6adbc",
        },
        howToConnect: {
            [UserLanguageEnum.DE]: "https://help.finway.de/de/articles/160560-google-single-sign-on-sso",
            [UserLanguageEnum.EN]: "https://help.finway.de/en/articles/160560-google-single-sign-on-sso",
        },
    },
    [SsoProviderTypeEnum.AZURE_AD]: {
        clientIds: {
            [UserLanguageEnum.DE]: "https://help.finway.de/de/articles/160518-azure-single-sign-on-sso#h_c36c2d03b8",
            [UserLanguageEnum.EN]: "https://help.finway.de/en/articles/160518-azure-single-sign-on-sso#h_c8643de221",
        },
        howToConnect: {
            [UserLanguageEnum.DE]: "https://help.finway.de/de/articles/160518-azure-single-sign-on-sso",
            [UserLanguageEnum.EN]: "https://help.finway.de/en/articles/160518-azure-single-sign-on-sso",
        },
    },
}
