import Icon from "@ant-design/icons"
import React from "react"

const blockSvg = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
            <path
                d="M14.3333 8.34876V2.66699C14.3333 2.11471 13.8855 1.66699 13.3333 1.66699H1.33325C0.780965 1.66699 0.333252 2.11471 0.333252 2.66699V10.667C0.333252 11.2193 0.780965 11.667 1.33325 11.667H9.0671"
                stroke="#606C7E"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M14.3333 3.66699H0.333252V5.66699H14.3333V3.66699Z" stroke="#606C7E" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M12.3333 14.3337C14.1743 14.3337 15.6667 12.8413 15.6667 11.0003C15.6667 9.15938 14.1743 7.66699 12.3333 7.66699C10.4924 7.66699 9 9.15938 9 11.0003C9 12.8413 10.4924 14.3337 12.3333 14.3337Z"
                stroke="#606C7E"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M9.97632 8.64355L14.6904 13.3576" stroke="#606C7E" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        </g>
        <defs>
            <clipPath id="clip0">
                <rect width="16" height="16" fill="white" />
            </clipPath>
        </defs>
    </svg>
)

const BlockCardIcon = (props: any) => <Icon component={blockSvg} {...props} />

export default BlockCardIcon
