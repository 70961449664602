import { CurrencyExchange } from "@finway-group/shared/lib/models"
import { Action } from "redux"

export enum CurrencyActionTypes {
    FETCH_CURRENCY_EXCHANGE = "FETCH_CURRENCY_EXCHANGE",
}

export interface FetchCurrencyExchangeAction extends Action<typeof CurrencyActionTypes.FETCH_CURRENCY_EXCHANGE> {
    type: typeof CurrencyActionTypes.FETCH_CURRENCY_EXCHANGE
    currencyExchange: CurrencyExchange
}
export type CurrencyActions = FetchCurrencyExchangeAction
