import { Role } from "@finway-group/shared/lib/models"
import { Alert } from "antd"
import Form, { FormInstance } from "antd/lib/form"
import { Store } from "antd/lib/form/interface"
import React from "react"
import { useTranslation } from "react-i18next"

import RightForm from "Components/forms/roles/right.form"
import RoleConfigForm from "Components/forms/roles/roleConfig.form"

import RoleNameForm from "./roleName.form"
import RoleTemplateForm from "./roleTemplate.form"
import StandardRightForm from "./standardRight.form"

interface RoleFormInterface {
    formInstance: FormInstance
    onSubmit?: (values: Store) => void
    role?: Role
}

const RoleForm = ({ formInstance, onSubmit, role }: RoleFormInterface) => {
    const { t } = useTranslation()
    const readOnlyForm = !onSubmit

    return (
        <Form id="role-form" form={formInstance} layout="vertical" onFinish={onSubmit} autoComplete="off">
            <RoleTemplateForm role={role} readOnly={readOnlyForm} formInstance={formInstance} />

            <RoleNameForm role={role} readOnly={readOnlyForm} />

            <RoleConfigForm readOnly={readOnlyForm} formInstance={formInstance} />

            <StandardRightForm />

            <Alert message={<span className="font-bold">{t("label:roles.default_menu_info")}</span>} type="info" showIcon={true} />

            <RightForm readOnly={readOnlyForm} formInstance={formInstance} />
        </Form>
    )
}

export default React.memo(RoleForm)
