import { Employee, UserApprover } from "@finway-group/shared/lib/models"
import { Button, Form, Modal } from "antd"
import { Store } from "antd/lib/form/interface"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import { parseCurrencyInput } from "Components/currencyInput/config"
import EmployeeLimitForm from "Components/forms/employeeForm/employeeLimit.form"
import { EmployeeRules } from "Components/forms/rules"
import { useCompany } from "Shared/hooks/company.hooks"
import { useEmployees } from "Shared/hooks/employee.hooks"
import { useRolesMap } from "Shared/hooks/role.hooks"
import { CurrencyService, EmployeeService, NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { updateEmployeeApprovals } from "Shared/store/actions/employee/employeeActions"
import { determinePossibleApprovers } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

interface EmployeeLimitModalProps {
    isShowing: boolean
    onCancel: () => void
    employee: Employee
}

const EmployeeLimitModal = ({ isShowing, onCancel, employee }: EmployeeLimitModalProps) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const [formInstance] = Form.useForm()
    const [approvalLimits, setApprovalLimits] = useState<Array<UserApprover>>([])
    const [isLoading, setIsLoading] = useStateIfMounted<boolean>(false)
    const [approverList, setApproverList] = useStateIfMounted<Array<Employee>>([])

    const company = useCompany()
    const rolesMap = useRolesMap({ excludeDeleted: true })
    const employees = useEmployees({ excludeDeleted: true, includeAutoApprover: true })

    const {
        id,
        settings: { globalCurrency },
        activeCompanyProfile: { superior, approvals },
    } = employee

    useEffect(() => {
        const newPossibleApprovers = determinePossibleApprovers(employee?.email || "", employees, rolesMap)

        // add employees that are already set previously, but potentially not in the possible
        // approver list anymore. Needed as it would only show IDs otherwise
        const presetApprovers = [
            ...(superior ? [EmployeeService.getEmployeeById(superior)] : []),
            ...(approvals ? approvals.map((approval: UserApprover) => EmployeeService.getEmployeeById(approval.approver)) : []),
        ]
        setApproverList([
            ...newPossibleApprovers,
            ...presetApprovers.filter((presetApprover) => !newPossibleApprovers.find((newPossibleApprover) => newPossibleApprover.id === presetApprover.id)),
        ])

        if (approvals) {
            setApprovalLimits(
                approvals?.map(
                    (approval: UserApprover) =>
                        new UserApprover({
                            ...approval,
                            limit: approval.currency === globalCurrency ? approval.limit : CurrencyService.convert(approval.limit, approval.currency, globalCurrency),
                            currency: globalCurrency,
                        }),
                ),
            )
        }
    }, [])

    const handleHide = () => {
        setIsLoading(false)
        onCancel()
    }

    const onSubmit = async (store: Store) => {
        setIsLoading(true)
        try {
            const employeeId = employee && id
            const updatedApprovals = store.approvals?.map((approval: any) => ({ ...approval, limit: parseCurrencyInput(approval.limit) }))

            await dispatch(updateEmployeeApprovals(employeeId, updatedApprovals, company._id))
            handleHide()
            NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:employee.updated.title"), t("notification:employee.updated.message"))
        } catch (err) {
            setIsLoading(false)
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:employee.edit.title"))
        }
    }
    const rules = EmployeeRules(approverList, formInstance, company.fallbackApprover, employee)

    return (
        <Modal
            destroyOnClose={true}
            afterClose={() => formInstance.resetFields()}
            visible={isShowing}
            maskClosable={false}
            title={t("label:set_employee_limits")}
            closable={true}
            keyboard={true}
            onCancel={() => handleHide()}
            className="ant-modal--small"
            footer={[
                <Button key="back" onClick={() => handleHide()}>
                    {t("action:cancel")}
                </Button>,
                <Button
                    key="submit"
                    type="primary"
                    loading={isLoading}
                    onClick={() => {
                        formInstance.submit()
                    }}
                >
                    {t("action:employee.add_limit")}
                </Button>,
            ]}
        >
            <Form layout="vertical" form={formInstance} onFinish={onSubmit} scrollToFirstError={true}>
                <p className="font-bold text-lg mb-20 text-text-dark">{t("label:limits")}</p>

                <EmployeeLimitForm
                    globalCurrency={globalCurrency}
                    superior={superior}
                    possibleApprovers={approverList}
                    formInstance={formInstance}
                    approvals={approvalLimits}
                    rules={rules}
                />
            </Form>
        </Modal>
    )
}
export default EmployeeLimitModal
