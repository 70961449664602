import React from "react"

import Icon, { IconInterface } from "Components/icon"

const cashFlowPlanningSvg = (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.6355 8.1259C16.314 4.02677 12.8733 0.833008 8.75045 0.833008C4.38425 0.833008 0.832031 4.38444 0.832031 8.74967C0.832031 12.9119 4.08796 16.3783 8.24497 16.6415C8.25311 16.6419 8.26207 16.6423 8.27102 16.6423C8.40126 16.6423 8.52417 16.5817 8.60312 16.4775C8.68778 16.3665 8.71057 16.2204 8.66417 16.0885C8.44521 15.4599 8.3337 14.8129 8.3337 14.1663C8.3337 10.9498 10.9515 8.33301 14.1683 8.33301C14.8146 8.33301 15.4609 8.4445 16.0901 8.66423C16.1349 8.67969 16.1813 8.68742 16.2277 8.68742C16.2342 8.68783 16.2399 8.68742 16.244 8.68742C16.4743 8.68742 16.6607 8.50106 16.6607 8.27075C16.6607 8.21989 16.6518 8.17106 16.6355 8.1259ZM9.16536 8.74967C9.16536 8.86035 9.12142 8.96615 9.04329 9.04427L6.54329 11.5443C6.46191 11.6257 6.35531 11.6663 6.2487 11.6663C6.14209 11.6663 6.03548 11.6257 5.9541 11.5443C5.79134 11.3815 5.79134 11.1178 5.9541 10.9551L8.33203 8.57715V3.74967C8.33203 3.51937 8.51839 3.33301 8.7487 3.33301C8.979 3.33301 9.16536 3.51937 9.16536 3.74967V8.74967Z" />
        <path d="M14.1641 9.16602C11.4069 9.16602 9.16406 11.4089 9.16406 14.166C9.16406 16.9232 11.4069 19.166 14.1641 19.166C16.9212 19.166 19.1641 16.9232 19.1641 14.166C19.1641 11.4089 16.9212 9.16602 14.1641 9.16602ZM13.9557 13.7493H14.3724C15.1764 13.7493 15.8307 14.4036 15.8307 15.2077C15.8307 15.9404 15.2856 16.5428 14.5807 16.645V17.0827C14.5807 17.313 14.3944 17.4993 14.1641 17.4993C13.9338 17.4993 13.7474 17.313 13.7474 17.0827V16.666H12.9141C12.6838 16.666 12.4974 16.4797 12.4974 16.2493C12.4974 16.019 12.6838 15.8327 12.9141 15.8327H14.3724C14.7166 15.8327 14.9974 15.5523 14.9974 15.2077C14.9974 14.863 14.7166 14.5827 14.3724 14.5827H13.9557C13.1517 14.5827 12.4974 13.9284 12.4974 13.1243C12.4974 12.3916 13.0425 11.7892 13.7474 11.6871V11.2493C13.7474 11.019 13.9338 10.8327 14.1641 10.8327C14.3944 10.8327 14.5807 11.019 14.5807 11.2493V11.666H15.4141C15.6444 11.666 15.8307 11.8524 15.8307 12.0827C15.8307 12.313 15.6444 12.4993 15.4141 12.4993H13.9557C13.6115 12.4993 13.3307 12.7797 13.3307 13.1243C13.3307 13.469 13.6115 13.7493 13.9557 13.7493Z" />
    </svg>
)

const CashFlowPlanningIcon = (props: IconInterface) => <Icon component={cashFlowPlanningSvg} {...props} />

export default CashFlowPlanningIcon
