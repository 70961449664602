import Icon from "@ant-design/icons"
import React from "react"

import { AntIconType } from "../antIcon.types"

const addAzureActiveDirectoryLogo = () => (
    <svg viewBox="0 0 24 24" fill="#1DACEA" xmlns="http://www.w3.org/2000/svg">
        <path d="M13 0H24V11H13V0Z" />
        <path d="M13 13H24V24H13V13Z" />
        <path d="M0 0H11V11H0V0Z" />
        <path d="M0 13H11V24H0V13Z" />
    </svg>
)

const AzureActiveDirectoryLogo = (props: AntIconType) => <Icon component={addAzureActiveDirectoryLogo} {...props} />

export default AzureActiveDirectoryLogo
