import { LoadingOutlined } from "@ant-design/icons"
import { Expense, RightEnum, Transaction, TransactionTypeEnum } from "@finway-group/shared/lib/models"
import { roundNumberTo2Decimals } from "@finway-group/shared/lib/utils"
import { Button, Card, Empty } from "antd"
import Table, { ColumnsType } from "antd/lib/table"
import moment from "moment"
import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { useDebouncedCallback } from "use-debounce/lib"

import NoSearchDataFound from "Components/NoSearchDataFound"
import { ErrorTable } from "Components/layout/errorTable"
import SearchInput from "Components/searchInput"
import TransactionAmountColumn from "Components/transactionAmountColumn"
import TransactionStatusTag from "Components/transactionStatusTag"
import { useFetchTable, useTable, useTableSearchString } from "Shared/hooks/table.hooks"
import { AuthzService, NotificationService, TransactionService, UserService, VendorService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { syncExpense } from "Shared/store/actions/expense/expenseActions"
import { refetchTable, updateTableOnChange, updateTableSearch } from "Shared/store/actions/tables/tableActions"
import { syncTransaction } from "Shared/store/actions/transaction/transactionActions"
import { TablesEnum } from "Shared/store/reducers/tableConfigReducer"
import { insertPropertyIf } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"
import { getSortOrderForColumn } from "Shared/utils/table.utils"

interface ExpenseTransactionListInterface {
    expense: Expense
}
const ExpenseTransactionList: React.FC<ExpenseTransactionListInterface> = ({ expense }) => {
    const { t } = useTranslation()
    const history = useHistory()
    const dispatch = useDispatch()
    const [loadingId, setLoadingId] = useStateIfMounted("-1")
    const globalCurrency = UserService.getLoggedInEmployeeProfile()?.settings.globalCurrency

    const isAllowedToTransactionExpenseMatching = AuthzService.isRightGrantedForLoggedInUser(RightEnum.TRANSACTION__ALL__UPDATE)

    const transactionTable = useTable<Transaction>(TablesEnum.EXPENSE_TRANSACTIONS)
    const searchString = useTableSearchString(TablesEnum.EXPENSE_TRANSACTIONS)

    useFetchTable(TablesEnum.EXPENSE_TRANSACTIONS, { expenseId: expense.id })

    const handleSearch = useDebouncedCallback((searchString) => dispatch(updateTableSearch(TablesEnum.EXPENSE_TRANSACTIONS, searchString)), 800)

    const columns: ColumnsType<Transaction> = [
        {
            key: "transactionData.date",
            title: t("label:transaction_date"),
            ellipsis: true,
            width: 160,
            sorter: true,
            sortOrder: getSortOrderForColumn(transactionTable, "transactionData.date"),
            render: ({ transactionData: { date } }: Transaction) => <span>{moment(date).format("ll")}</span>,
        },
        {
            key: "vendor",
            title: t("label:vendor"),
            ellipsis: true,
            width: 180,
            sorter: true,
            sortOrder: getSortOrderForColumn(transactionTable, "vendor"),
            render: ({ transactionData: { vendor, recipient } }: Transaction) => <span>{VendorService.getVendorById(vendor)?.name || recipient || "n/a"}</span>,
        },
        {
            key: "transactionType",
            title: t("label:transaction_source"),
            ellipsis: true,
            sorter: true,
            sortOrder: getSortOrderForColumn(transactionTable, "transactionType"),
            render: ({ transactionType, bankName }: Transaction) => {
                const transactionTypeLabel = t(`label:${transactionType.toLocaleLowerCase()}`)
                return (
                    <span>
                        {/* For bank transactions if bank name is not defined fallback to transactionTypeLabel */}
                        {transactionType === TransactionTypeEnum.BANK_TRANSACTION && bankName ? bankName : transactionTypeLabel}
                    </span>
                )
            },
        },
        {
            key: "transactionData.description",
            title: t("label:description"),
            ellipsis: true,
            width: 300,
            sorter: true,
            sortOrder: getSortOrderForColumn(transactionTable, "transactionData.description"),
            render: ({ transactionData: { description } }: Transaction) => <span>{description || "n/a"}</span>,
        },
        {
            key: "transactionData.amount",
            title: t("label:amount"),
            ellipsis: true,
            sorter: true,
            ...insertPropertyIf(expense.currency !== globalCurrency, { align: "center" }),
            sortOrder: getSortOrderForColumn(transactionTable, "transactionData.amount"),
            width: 200,
            render: (transaction: Transaction) => (
                <div className="flex">
                    <TransactionAmountColumn transaction={transaction} expenseCurrency={expense.currency} />
                </div>
            ),
        },

        {
            key: "status",
            title: t("label:status"),
            ellipsis: true,
            width: 110,
            align: "center",
            sorter: true,
            sortOrder: getSortOrderForColumn(transactionTable, "status"),
            render: ({ status }: Transaction) => <TransactionStatusTag status={status} />,
        },
        {
            key: "probabilityForExpense",
            title: t("label:probability_short"),
            ellipsis: true,
            width: 80,
            sorter: true,
            sortOrder: getSortOrderForColumn(transactionTable, "probabilityForExpense"),
            render: (transaction: any) => <span>{roundNumberTo2Decimals(transaction.probabilityForExpense ?? 0)}%</span>,
        },
        {
            key: "isMatched",
            title: t("label:matching.match_status"),
            ellipsis: true,
            width: 150,
            sorter: true,
            sortOrder: getSortOrderForColumn(transactionTable, "isMatched"),
            align: "right",
            render: (transaction: any) =>
                transaction.isMatched ? (
                    <Button
                        loading={transaction.id === loadingId}
                        onClick={(e) => {
                            e.stopPropagation()
                            onUnmatch(transaction.id)
                        }}
                        className={`min-w-80 btn-highlight-green-active`}
                        disabled={!isAllowedToTransactionExpenseMatching}
                    >
                        {t("action:matching.unmatch")}
                    </Button>
                ) : (
                    <Button
                        loading={transaction.id === loadingId}
                        onClick={(e) => {
                            e.stopPropagation()
                            onMatch(transaction.id)
                        }}
                        className={`min-w-80 btn-highlight-green`}
                        disabled={!isAllowedToTransactionExpenseMatching}
                    >
                        {t("action:matching.match")}
                    </Button>
                ),
        },
    ]

    const onMatch = (transactionId: string) => {
        setLoadingId(transactionId)
        TransactionService.matchTransaction(transactionId, expense.id)
            .then((transaction: Transaction) => {
                dispatch(syncTransaction(transaction.id))
                dispatch(syncExpense(expense.id))
                NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:matching.matched.title"), t("notification:matching.matched.message"))
            })
            .catch((err) => NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:matching.title")))
            .finally(() => setLoadingId("-1"))
    }

    const onUnmatch = (transactionId: string) => {
        setLoadingId(transactionId)
        TransactionService.unmatchTransaction(transactionId, expense.id)
            .then((transaction: Transaction) => {
                dispatch(syncTransaction(transaction.id))
                dispatch(syncExpense(expense.id))
                NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:matching.unmatched.title"), t("notification:matching.unmatched.message"))
            })
            .catch((err) => NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:matching.title")))
            .finally(() => setLoadingId("-1"))
    }

    return (
        <div>
            <div className="flex gap-10 justify-between items-center mb-16">
                <span></span>
                <div className="flex flex-row space-x-10">
                    <div className="flex btn-wrapper">
                        {(transactionTable.data.totalDocs > 0 || searchString) && (
                            <SearchInput onSearch={(searchString) => handleSearch.callback(searchString)} value={searchString} />
                        )}
                    </div>
                </div>
            </div>

            {transactionTable.error ? (
                <ErrorTable onTableReload={() => dispatch(refetchTable(TablesEnum.EXPENSE_TRANSACTIONS))} isLoading={transactionTable.isFetching} />
            ) : transactionTable.data.totalDocs === 0 && !transactionTable.isFetching ? (
                searchString ? (
                    <Card className="flex h-250 items-center justify-center">
                        <NoSearchDataFound imageStyle={{ height: 100, display: "inline-block", marginTop: "auto", marginBottom: "auto" }} />
                    </Card>
                ) : (
                    <Card className="flex h-250 items-center justify-center">
                        <Empty
                            className="animation-appear"
                            image={"./icons/empty_table.svg"}
                            imageStyle={{
                                height: 100,
                                display: "inline-block",
                            }}
                            description={<span className="text-lg font-bold mt-2 mb-0">{t("info:empty_state.transactions.title")}</span>}
                        />
                    </Card>
                )
            ) : (
                <div>
                    <div className="overflow-auto p-2 animation-appear">
                        <Table<Transaction>
                            rowKey={(transaction) => transaction.id}
                            dataSource={transactionTable.data.docs}
                            pagination={{
                                position: ["bottomRight"],
                                showSizeChanger: false,
                                current: transactionTable.data.page,
                                pageSize: transactionTable.data.limit,
                                hideOnSinglePage: false,
                                total: transactionTable.data.totalDocs,
                            }}
                            columns={columns}
                            loading={{
                                spinning: transactionTable.isFetching,
                                indicator: <LoadingOutlined style={{ fontSize: 30, color: "black" }} spin />,
                            }}
                            onChange={updateTableOnChange(TablesEnum.EXPENSE_TRANSACTIONS, transactionTable.data)}
                            onRow={(transaction) => ({
                                onClick: () => {
                                    history.push(`/transactions/${transaction.id}`)
                                },
                            })}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}

export default React.memo(ExpenseTransactionList)
