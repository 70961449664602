import { DocumentTypeEnum, Expense, ExpenseRequestTypeEnum, InboxInvoice, OcrResult, OcrStatusEnum, OperationTypeEnum } from "@finway-group/shared/lib/models"
import { insertIf } from "@finway-group/shared/lib/utils"
import { Button, Form, Modal, Row } from "antd"
import React, { useEffect } from "react"
import { FileText as InvoiceIcon, RefreshCw as RefreshIcon } from "react-feather"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import ExpenseCreateFormModal from "Components/modals/expenseCreateForm.modal"
import { useModal } from "Shared/context/modal.context"
import { useSelectedInboxInvoices } from "Shared/context/selectedExpenses.context"
import { useCompany } from "Shared/hooks/company.hooks"
import { useTaxes } from "Shared/hooks/tax.hooks"
import { useLoggedInEmployeeProfile } from "Shared/hooks/user.hooks"
import { useVendors } from "Shared/hooks/vendor.hooks"
import { ApprovalProcessService, AuthzService, ExpenseService, NotificationService, OcrService } from "Shared/services"
import InboxInvoiceService from "Shared/services/inboxInvoice.service"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { updateDocuments, updateExpense } from "Shared/store/actions/expense/expenseActions"
import { deleteManyInboxInvoices } from "Shared/store/actions/inboxInvoice/inboxInvoiceActions"
import { deleteUnnecessaryExpenseFormData, isHospitalityExpense, syncExpenseSplits } from "Shared/utils/expense.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"
import { compareFormOcrData, mergeExistingAndOcrItems } from "Shared/utils/ocr.utils"

import ConfirmOCRChangesModal from "../confirmOCRChanges.modal"
import InboxInvoiceModalExpenseSection from "./inboxInvoiceModalExpenseSection"
import InboxInvoiceModalInvoiceSection from "./inboxInvoiceModalInvoiceSection"

interface InboxInvoiceModalInterface {
    isShowing: boolean
    onCancel: () => void
    expenses: Array<Expense>
    isOcrEnabled: boolean
    isOcrItemizationEnabled: boolean
    preSelectedExpense?: Expense
    preSelectedInvoice?: InboxInvoice
    shouldResetSelectedExpenseOnClose?: boolean
    shouldResetSelectedInboxOnClose?: boolean
    setIsShowingDiscountForm?: React.Dispatch<React.SetStateAction<boolean>>
}

const InboxInvoiceModal = ({
    isShowing,
    onCancel,
    isOcrEnabled,
    isOcrItemizationEnabled,
    setIsShowingDiscountForm,
    preSelectedExpense = undefined,
    preSelectedInvoice = undefined,
    shouldResetSelectedExpenseOnClose = false,
    shouldResetSelectedInboxOnClose = false,
}: InboxInvoiceModalInterface) => {
    const { t } = useTranslation()
    const [formInstance] = Form.useForm()
    const dispatch = useDispatch()
    const [selectedExpense, setSelectedExpense] = useStateIfMounted<Expense | undefined>(preSelectedExpense)
    const [createNew, setCreateNew] = useStateIfMounted(false)
    const loggedInUser = useLoggedInEmployeeProfile()
    const [isLoading, setIsLoading] = useStateIfMounted(false)
    const [isNewReimbursement, setIsNewReimbursement] = useStateIfMounted(false)
    const vendors = useVendors(true)
    const taxes = useTaxes()
    const company = useCompany()
    const [selectedInvoice, setSelectedInvoice] = useStateIfMounted<any>(preSelectedInvoice)
    const [selectedInboxInvoices, setSelectedInboxInvoices] = useSelectedInboxInvoices()
    const [selectedInvoiceForOcr, setSelectedInvoiceForOcr] = useStateIfMounted<any>(selectedInboxInvoices[0])
    // if a single invoice is selected, we prioritize that, otherwise, we prioritize the checkbox selection
    const currentlySelected = selectedInvoice ? [selectedInvoice] : selectedInboxInvoices
    const [currentlySelectedSignedInvoices, setCurrentlySelectedSignedInvoices] = useStateIfMounted<Array<InboxInvoice>>([])
    const { showModal } = useModal()
    const [updatedOcrObject, setUpdatedObject] = useStateIfMounted<OcrResult | null>(null)
    const isAllowedToCreateExpenses = AuthzService.isLoggedInUserAllowedToCreateExpenses()

    useEffect(() => {
        setSelectedExpense(preSelectedExpense)
    }, [preSelectedExpense])

    useEffect(() => {
        setSelectedInvoice(preSelectedInvoice)
    }, [preSelectedInvoice])

    useEffect(() => {
        if (selectedInvoice) {
            setSelectedInvoiceForOcr(selectedInvoice)
        } else if (selectedInboxInvoices.length && !selectedInboxInvoices.find((invoice) => invoice.id === selectedInvoiceForOcr?.id)) {
            setSelectedInvoiceForOcr(selectedInboxInvoices[0])
        }
    }, [selectedInvoice])

    useEffect(() => {
        if (selectedInboxInvoices.length && !selectedInboxInvoices.find((invoice) => invoice.id === selectedInvoiceForOcr?.id)) {
            setSelectedInvoiceForOcr(selectedInboxInvoices[0])
        }
    }, [selectedInboxInvoices.length])

    useEffect(() => {
        if (updatedOcrObject) updateExpenseWithOcrModalResults(updatedOcrObject)
    }, [updatedOcrObject])

    const handleHide = () => {
        formInstance.resetFields()
        onCancel()
        setCreateNew(false)
        setIsLoading(false)
        if (shouldResetSelectedExpenseOnClose) setSelectedExpense(undefined)
        if (shouldResetSelectedInboxOnClose) setSelectedInboxInvoices([])
        setSelectedInvoice(undefined)
    }

    const updateExpenseWithOcr = async (expense: Expense) => {
        const ocrObject = selectedInvoiceForOcr?.ocrResults

        // see if we have a duplicate and should remove the invoice number from the update
        if (ocrObject.invoiceNumber) {
            // Ignore checks on invoice collection because we want to check this against expense's invoice numbers only
            const checkResult = await ExpenseService.checkForDuplicate(expense, ocrObject.invoiceNumber, { checkInvoice: false })
            if (!checkResult) return false
        }

        // to get the different data (form x OCR)
        const userLanguage = loggedInUser?.getUserLanguage()
        const formOCRDataDifferences = compareFormOcrData({ expense, ocrObject, vendors, t }, userLanguage)

        // if there are differences between form and OCR, it opens a modal showing all different data
        if (formOCRDataDifferences) {
            showModal(ConfirmOCRChangesModal, true, {
                isShowing: true,
                expense,
                ocrObject,
                formOCRDataDifferences,
                onClose: () => {},
                onCancel: () => {
                    setIsLoading(false)
                },
                setUpdatedObject,
            })
            return
        }

        // build items with ocr and expense items
        if (ocrObject.splits) ocrObject.splits = mergeExistingAndOcrItems(expense.splits, ocrObject.splits)
        return setUpdatedObject(ocrObject)
    }

    const updateExpenseWithOcrModalResults = async (updatedOcrObject: OcrResult) => {
        if (!selectedInvoiceForOcr) {
            NotificationService.send(NotificationTypeEnum.ERROR, t("error:error"), t("error:ocr.select_ocr_source"))
            return false
        }

        let errorTitle = "error:ocr_error"
        try {
            if (!selectedExpense) return

            // update expense with ocr data
            const updateData = OcrService.buildOcrUpdateData({
                ocrObject: updatedOcrObject,
                existingData: selectedExpense,
                vendors,
                taxes,
                t,
                ocrItemizationEnabled: isOcrItemizationEnabled,
            })
            const sanitizedUpdateData = deleteUnnecessaryExpenseFormData(updateData)

            const updatedExpense = new Expense({ ...selectedExpense, ...sanitizedUpdateData, isOcrUpdate: true })

            errorTitle = "error:error"

            // Rebuild invoice approval process with new ocr data change. Expenses inside folder do not change its aproval processes and follow the folder approval processes.
            if (!selectedExpense.folderId) {
                const approvalProcesses = await ApprovalProcessService.getApprovalProcessesAfterExpenseUpdate(updatedExpense, company, {
                    previousExpense: selectedExpense,
                    awaitConfirmation: true,
                    ocrConfirmation: true,
                })
                if (!approvalProcesses) return
                updatedExpense.approvalProcesses = approvalProcesses
            }
            const documentData = currentlySelected.map(({ url, fileName, hash, uploadDate, uploadedBy, assignedTo }) => ({
                url,
                fileName,
                hash,
                uploadDate,
                uploadedBy,
                assignedTo,
            }))
            const updatedExpenseWithSyncedSplits = syncExpenseSplits(updatedExpense, updatedExpense.splitType)
            await updateExpense(selectedExpense.id, updatedExpenseWithSyncedSplits)(dispatch)
            await updateDocuments(selectedExpense.id, DocumentTypeEnum.INVOICE, documentData, OperationTypeEnum.INBOX_ATTACH)(dispatch)
            await deleteManyInboxInvoices(currentlySelected.map((invoice) => new InboxInvoice(invoice)?.id))(dispatch)
            NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:invoice_inbox.expense_attached.title"), t("notification:invoice_inbox.expense_attached.message"))
            handleHide()
            return true
        } catch (err) {
            NotificationService.showErrorNotificationBasedOnResponseError(err, t(errorTitle))
            return false
        }
    }

    const attachExpense = async () => {
        if (selectedExpense) {
            setIsLoading(true)
            try {
                if (selectedInvoiceForOcr.ocrStatus === OcrStatusEnum.STARTED) {
                    NotificationService.send(NotificationTypeEnum.ERROR, t("error:error"), t("error:ocr.still_processing"))
                    return
                }

                const shouldUpdateExpenseWithOcr = selectedInvoiceForOcr.ocrStatus !== OcrStatusEnum.FAILED && isOcrEnabled && !isHospitalityExpense(selectedExpense)
                if (shouldUpdateExpenseWithOcr) {
                    const shouldProceed = await updateExpenseWithOcr(selectedExpense)
                    if (!shouldProceed) return
                } else {
                    const documentData = currentlySelected.map(({ url, fileName, hash, uploadDate, uploadedBy, assignedTo }) => ({
                        url,
                        fileName,
                        hash,
                        uploadDate,
                        uploadedBy,
                        assignedTo,
                    }))
                    await updateDocuments(selectedExpense.id, DocumentTypeEnum.INVOICE, documentData, OperationTypeEnum.INBOX_ATTACH)(dispatch)
                    await deleteManyInboxInvoices(currentlySelected.map((invoice) => new InboxInvoice(invoice)?.id))(dispatch)
                    NotificationService.send(
                        NotificationTypeEnum.SUCCESS,
                        t("notification:invoice_inbox.expense_attached.title"),
                        t("notification:invoice_inbox.expense_attached.message"),
                    )
                    handleHide()
                }
            } catch (err) {
                NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:error"))
            } finally {
                setIsLoading(false)
            }
        }
    }

    const onItemClicked = async (e: React.MouseEvent, key: string) => {
        e.preventDefault()

        try {
            const selectedInvoiceIds = currentlySelected?.map((invoice) => invoice.id)
            if (selectedInvoiceIds.length !== 0) {
                const signedInvoices = await InboxInvoiceService.fetchMultipleInvoicesByIds(selectedInvoiceIds)
                setCurrentlySelectedSignedInvoices(signedInvoices)
            }

            setIsNewReimbursement(key === "reimbursement")
            setCreateNew(true)
        } catch (err) {
            NotificationService.send(NotificationTypeEnum.ERROR, t("error:error"), t("error:invoice.fetch_failed.message"))
        }
    }

    const requestActionButtons = [
        !preSelectedExpense && selectedExpense === undefined && (
            <>
                <Button data-testid="attachInboxInvoiceToInvoiceRequestButton" className="ant-btn-primary" onClick={(e) => onItemClicked(e, "invoice")}>
                    <InvoiceIcon size={12} />
                    <span className="ml-8">{t("label:invoice_request")}</span>
                </Button>
                <Button data-testid="attachInboxInvoiceToReimbursementRequestButton" className="ant-btn-primary" onClick={(e) => onItemClicked(e, "reimbursement")}>
                    <RefreshIcon size={12} />
                    <span className="ml-8">{t("label:reimbursement_request")}</span>
                </Button>
            </>
        ),
        <Button
            data-testid="attachInboxInvoiceToExpenseButton"
            disabled={!currentlySelected.length}
            key="attach"
            type="primary"
            loading={isLoading}
            onClick={attachExpense}
            hidden={selectedExpense === undefined}
        >
            {t("action:inbox_invoice.attach")}
        </Button>,
    ]

    if (createNew) {
        return (
            <ExpenseCreateFormModal
                inboxInvoices={currentlySelectedSignedInvoices}
                inboxOcrSource={selectedInvoiceForOcr}
                type={isNewReimbursement ? ExpenseRequestTypeEnum.REIMBURSEMENT : ExpenseRequestTypeEnum.INVOICE}
                isNew={true}
                isShowing={isShowing}
                onCancel={() => handleHide()}
                reimbursementRequestsEnabled={{ standard: true, mileage: false, hospitality: true, perDiem: false }}
            />
        )
    }

    return (
        <Modal
            destroyOnClose
            visible={isShowing}
            maskClosable={false}
            onCancel={handleHide}
            className="ant-modal--full"
            closable
            footer={[
                <Button key="back" onClick={() => handleHide()}>
                    {t("action:cancel")}
                </Button>,
                ...insertIf(isAllowedToCreateExpenses, ...requestActionButtons),
            ]}
        >
            <div className="overflow-y-auto h-screen-30 mt-20">
                <Row className="md:flex-row">
                    <InboxInvoiceModalInvoiceSection
                        isShowing={isShowing}
                        selectedExpense={selectedExpense}
                        selectedInvoice={selectedInvoice}
                        setSelectedInvoice={setSelectedInvoice}
                        setSelectedExpense={setSelectedExpense}
                        selectedOcrSource={selectedInvoiceForOcr}
                        setSelectedOcrSource={setSelectedInvoiceForOcr}
                    />
                    <InboxInvoiceModalExpenseSection
                        isShowing={isShowing}
                        selectedInvoice={selectedInvoice}
                        preSelectedExpense={preSelectedExpense}
                        selectedExpense={selectedExpense}
                        setSelectedExpense={setSelectedExpense}
                        setIsShowingDiscountForm={setIsShowingDiscountForm}
                    />
                </Row>
            </div>
        </Modal>
    )
}

export default InboxInvoiceModal
