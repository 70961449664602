import React from "react"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import { Link } from "react-router-dom"

interface NotFoundPageInterface {}

export const NotFoundPage: React.FC<NotFoundPageInterface> = (props) => {
    const { t } = useTranslation()

    return (
        <div>
            <h2 className="p-0 text-center sm:text-left">{t("view:error.not_found.title")}</h2>
            <p className="mb-20 text-center sm:text-left">{t("view:error.not_found.message")}</p>

            <div className="text-center sm:text-left">
                <Link to={"/"}>{t("nav:go_back_to_homepage")}</Link>
            </div>
        </div>
    )
}

export default NotFoundPage
