import { Rule } from "antd/lib/form"

import i18n from "Shared/locales/i18n"
import validateEmail from "Shared/validators/email.validator"

const EmailFormRule = [
    { required: true, message: i18n.t("validation:required"), trigger: "blur" },
    {
        validator: (_: Rule, value: string) => {
            if (!validateEmail(value) && value !== "") {
                return Promise.reject(new Error(i18n.t("validation:email")))
            }
            return Promise.resolve()
        },
    },
]

export default EmailFormRule
