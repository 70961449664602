import { DocumentTypeEnum, Expense, ExpenseStatusEnum, Hospitality, InvoiceInterface, OcrResult, UploadKindEnum, Vendor } from "@finway-group/shared/lib/models"
import { ExpensePaymentOptionEnum } from "@finway-group/shared/lib/models/expense/expensePaymentOption.enum"
import { Card, Form, Spin, Switch, Tabs } from "antd"
import { FormInstance } from "antd/lib/form"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"

import DocumentTabManager from "Components/DocumentTabManager"
import { ExpenseRules } from "Components/forms/rules"
import { ExpenseData, useExpenseFormContext } from "Components/modals/expenseCreateForm.context"
import { useModal } from "Shared/context/modal.context"
import { useArchiveInterval } from "Shared/hooks/company.hooks"
import { useLoggedInEmployee } from "Shared/hooks/employee.hooks"
import { fillFormWithOcrData } from "Shared/hooks/ocr.hooks"
import { useTaxes } from "Shared/hooks/tax.hooks"
import { ExpenseService, FileService, NotificationService, OcrService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { isExpenseAlreadyPaid, isHospitalityExpense } from "Shared/utils/expense.utils"
import { convertFileDataFormat, flattenArray, mapToInvoiceInterface } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

interface CommonDocumentUploadInterface {
    expenseForm: FormInstance
    isSubscription: boolean
    ocrSettings?: {
        isOcrEnabled: boolean
        isOcrItemizationEnabled: boolean
        isOcrItemizationDisplayed: boolean
        setIsOcrEnabled: (flag: boolean) => void
        setIsOcrItemizationEnabled: (flag: boolean) => void
    }
    expenseInvoices: Array<InvoiceInterface>
    expenseDeliveryNote?: string
    isUploading: boolean
    setIsUploading: (flag: boolean) => void
    expense: Expense | Hospitality
    updateExpense: (data: ExpenseData) => void
    vendors: Array<Vendor>
    inboxInvoices?: Array<InvoiceInterface>
    invoicesRequired?: boolean
    isNew?: boolean
    expenseOffers?: Array<string>
    setIsOcrProcessed: (flag: boolean) => void
    activeTab: string
    onTabClick(activeKey: string, _event: React.KeyboardEvent | React.MouseEvent): void
    setPrefilledVendorData: (vendorData: Partial<Vendor>) => void
}

const CommonDocumentUpload: React.FC<CommonDocumentUploadInterface> = ({
    expenseForm,
    isSubscription,
    expenseInvoices,
    expenseDeliveryNote,
    ocrSettings,
    isUploading,
    setIsUploading,
    expense,
    updateExpense,
    vendors,
    inboxInvoices,
    invoicesRequired = false,
    isNew,
    expenseOffers,
    setIsOcrProcessed,
    activeTab,
    onTabClick,
    setPrefilledVendorData,
}) => {
    const { t } = useTranslation()
    const [{ isFormTouched }] = useExpenseFormContext()
    const [invoices, setInvoices] = useStateIfMounted<Array<InvoiceInterface>>(inboxInvoices ?? expenseInvoices)
    const [deliveryNote, setDeliveryNote] = useStateIfMounted(expenseDeliveryNote)
    const [isOcrUploading, setIsOcrUploading] = useStateIfMounted(false)
    const { invoiceUpload } = ExpenseRules({ invoices })
    const [activeInvoiceTabKey, setActiveInvoiceTabKey] = useStateIfMounted(0)
    const [invoicesOcrProcessed, setInvoicesOcrProcessed] = useStateIfMounted(inboxInvoices ?? expenseInvoices)
    const [offers, setOffers] = useStateIfMounted([...(expenseOffers || [])])
    const taxes = useTaxes()
    const loggedInEmployee = useLoggedInEmployee()
    const isAlreadyPaid = isExpenseAlreadyPaid(expense)
    const [showStampOnInvoice, setShowStampOnInvoice] = useStateIfMounted(true)
    const [updatedOcrObject, setUpdatedObject] = useStateIfMounted<OcrResult | null>(null)
    const archiveAfterXDays = useArchiveInterval()
    const { expenseEditable } = ExpenseService.calculateExpenseEditButtonView(expense, loggedInEmployee, archiveAfterXDays, t)
    const disableChanges = !expenseEditable
    const isHospitality = isHospitalityExpense(expense)
    const shouldEnableOcrItemization = isHospitality || !!ocrSettings?.isOcrItemizationEnabled

    const { showModal } = useModal()

    const fillForm = (ocrObject: OcrResult) => {
        const currentInvoice: InvoiceInterface = invoices[activeInvoiceTabKey]
        fillFormWithOcrData({
            formInstance: expenseForm,
            expense,
            ocrObject,
            vendors,
            taxes,
            updateExpense,
            setPrefilledVendorData,
            t,
            ocrItemizationEnabled: shouldEnableOcrItemization,
        }).finally(() => {
            setInvoicesOcrProcessed([...invoicesOcrProcessed, currentInvoice])
            setIsOcrProcessed(true)
            NotificationService.send(NotificationTypeEnum.INFO, t("notification:ocr.processed.title"), t("notification:ocr.processed.message"))
        })
    }

    // to update the form with the ocr data
    useEffect(() => {
        if (updatedOcrObject) fillForm(updatedOcrObject)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updatedOcrObject])

    const handleOcr = ({
        ocrInvoices,
        isOcrEnabled,
        isOcrRetrigger,
        method,
    }: {
        ocrInvoices: Array<{ url: string }>
        isOcrEnabled: boolean
        isOcrRetrigger: boolean
        method: string
    }) => {
        if (!isOcrEnabled) return

        const currentInvoice = ocrInvoices[activeInvoiceTabKey]
        if (!currentInvoice) return

        OcrService.runOcr({
            invoiceUrl: currentInvoice?.url,
            expense,
            setIsOcrUploading,
            vendors,
            t,
            noDataDifferenceFunction: fillForm,
            setUpdatedObject,
            showModal,
            language: loggedInEmployee?.getUserLanguage(),
            isOcrItemizationEnabled: shouldEnableOcrItemization,
            showOcrDifferencesModal: !isNew || isFormTouched,
            isOcrRetrigger,
            meta: { source: `commonDocumentUpload.form.tsx#${method}` },
        })
    }

    const doFileUpload = async (files: Array<File>, kind: DocumentTypeEnum) => {
        if (!FileService.checkFilesSizeAndHandleError(files)) return

        setIsUploading(true)
        try {
            const data = await FileService.uploadMultiple(files, UploadKindEnum.EXPENSE)
            switch (kind) {
                case DocumentTypeEnum.INVOICE:
                    const docsToAdd = []
                    for (const fileUploadData of data) {
                        if (await ExpenseService.checkForDuplicateInvoiceFile(fileUploadData.duplicateData)) {
                            docsToAdd.push(convertFileDataFormat(fileUploadData))
                        }
                    }
                    const formInvoices = expenseForm.getFieldValue("invoices")
                    const allInvoices = flattenArray<InvoiceInterface>([...formInvoices, ...docsToAdd])
                    setInvoices(allInvoices)
                    handleOcr({
                        ocrInvoices: allInvoices,
                        isOcrEnabled: ocrSettings?.isOcrEnabled ?? false,
                        isOcrRetrigger: false,
                        method: "doFileUpload",
                    })
                    updateExpense({ invoices: allInvoices })
                    break
                case DocumentTypeEnum.DELIVERY_NOTE:
                    const newFileData = convertFileDataFormat(data[0])
                    setDeliveryNote(newFileData?.url)
                    updateExpense({ deliveryNote: newFileData?.url })
                    break
                case DocumentTypeEnum.OFFER:
                    const formOffers = expenseForm.getFieldValue("offers")
                    const newOfferUrls = data.map((fileData) => convertFileDataFormat(fileData)?.url)
                    setOffers(flattenArray([...formOffers, ...newOfferUrls]))
                    updateExpense({ offers: flattenArray([...formOffers, ...newOfferUrls]) })
                    break
                default:
                    break
            }
        } catch (err) {
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:file.upload.title"))
        } finally {
            setIsUploading(false)
        }
    }

    const onRemoveTab = (documentType: DocumentTypeEnum, targetKey: string | React.MouseEvent | React.KeyboardEvent) => {
        switch (documentType) {
            case DocumentTypeEnum.INVOICE:
                let invoicesCopy = [...invoices]
                invoicesCopy = invoicesCopy.filter((_, i) => i !== Number(targetKey))
                setInvoices(invoicesCopy)
                const formInvoices = expenseForm.getFieldValue("invoices") as Array<InvoiceInterface>
                updateExpense({ invoices: formInvoices.filter((_, i) => i !== Number(targetKey)) })
                break
            case DocumentTypeEnum.DELIVERY_NOTE:
                setDeliveryNote(undefined)
                updateExpense({ deliveryNote: undefined })
                break
            case DocumentTypeEnum.OFFER:
                let offerCopy = [...offers]
                offerCopy = offerCopy.filter((_, i) => i !== Number(targetKey))
                setOffers(offerCopy)
                updateExpense({ offers: offerCopy })
                break
            default:
                break
        }
    }

    const onChangeInvoicesActiveKey = (newInvoiceTabKey: number) => {
        setActiveInvoiceTabKey(Math.max(newInvoiceTabKey, 0))
    }

    const toggles = (
        <div className="mr-10 ml-10 text-sm ">
            {isOcrUploading && <Spin className="mr-10" />}
            <span className="mr-10">{t("input:settings.enable_ocr")}</span>
            <Switch
                data-testid="enableOcrToggle"
                size="small"
                defaultChecked={ocrSettings?.isOcrEnabled}
                onChange={(checked) => {
                    handleOcr({
                        ocrInvoices: invoices,
                        isOcrEnabled: checked,
                        isOcrRetrigger: true,
                        method: "toggles",
                    })
                    ocrSettings?.setIsOcrEnabled(checked)
                }}
                disabled={isOcrUploading}
            />
            {ocrSettings?.isOcrItemizationDisplayed && (
                <>
                    <span className="mr-10 ml-10">{t("input:settings.inline_items")}</span>
                    <Switch
                        data-testid="enableInlineItemsToggle"
                        disabled={!ocrSettings?.isOcrEnabled || isHospitalityExpense(expense) || isOcrUploading}
                        size="small"
                        defaultChecked={ocrSettings?.isOcrItemizationEnabled}
                        onChange={(checked) => ocrSettings?.setIsOcrItemizationEnabled(checked)}
                    />
                </>
            )}

            {isAlreadyPaid && (
                <>
                    <span className="mr-10 ml-10">{t("input:settings.show_stamp")}</span>
                    <Switch size="small" defaultChecked={showStampOnInvoice} onChange={(checked) => setShowStampOnInvoice(checked)} />
                </>
            )}
        </div>
    )

    return (
        <>
            <Card className="w-full card-with-tabs h-full">
                <Tabs
                    activeKey={activeTab}
                    onTabClick={onTabClick}
                    className="w-full min-h-200 ml-1"
                    tabBarExtraContent={ocrSettings && toggles}
                    defaultActiveKey={DocumentTypeEnum.INVOICE}
                >
                    <Tabs.TabPane key={DocumentTypeEnum.OFFER} tab={t("input:request.offer")}>
                        <Form.Item name="offers" key="offers">
                            <DocumentTabManager
                                invoices={mapToInvoiceInterface(offers)}
                                doFileUpload={doFileUpload}
                                isUploading={isUploading}
                                onRemoveTab={onRemoveTab}
                                documentType={DocumentTypeEnum.OFFER}
                                label={{
                                    buttonLabel: t("action:add_offer"),
                                    draggerDescription: t("action:or_drop_files"),
                                }}
                                readOnly={disableChanges}
                            />
                        </Form.Item>
                    </Tabs.TabPane>
                    <Tabs.TabPane key={DocumentTypeEnum.INVOICE} tab={<span className={invoicesRequired ? `label-required` : ""}>{t("input:request.invoice")}</span>}>
                        <Form.Item data-testid="invoiceRequestForm" name="invoices" key="invoices" rules={invoicesRequired && isNew ? invoiceUpload : []}>
                            <DocumentTabManager
                                invoices={invoices}
                                deliveryNote={deliveryNote}
                                doFileUpload={doFileUpload}
                                isUploading={isUploading}
                                onRemoveTab={onRemoveTab}
                                documentType={DocumentTypeEnum.INVOICE}
                                label={{
                                    buttonLabel: t("action:add_invoice"),
                                    draggerDescription: t("action:or_drop_files"),
                                }}
                                onChangeInvoicesActiveKey={onChangeInvoicesActiveKey}
                                readOnly={
                                    expense.deleted ||
                                    (expense.status === ExpenseStatusEnum.DECLINED && expense.paymentOption === ExpensePaymentOptionEnum.SMART_CARD) ||
                                    disableChanges
                                }
                                showPaidStamp={isAlreadyPaid && showStampOnInvoice}
                            />
                        </Form.Item>
                    </Tabs.TabPane>

                    {!isSubscription && (
                        <Tabs.TabPane key={DocumentTypeEnum.DELIVERY_NOTE} tab={t("input:request.delivery_note")}>
                            <Form.Item name="deliveryNote" key="deliveryNote">
                                <DocumentTabManager
                                    invoices={invoices}
                                    deliveryNote={deliveryNote}
                                    doFileUpload={doFileUpload}
                                    onRemoveTab={onRemoveTab}
                                    documentType={DocumentTypeEnum.DELIVERY_NOTE}
                                    label={{
                                        buttonLabel: t("action:add_delivery_note"),
                                        draggerDescription: t("action:or_drop_files"),
                                    }}
                                    readOnly={disableChanges}
                                />
                            </Form.Item>
                        </Tabs.TabPane>
                    )}
                </Tabs>
            </Card>
        </>
    )
}

export default CommonDocumentUpload
