import { Employee } from "@finway-group/shared/lib/models"
import { Button, Form, Modal } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"

import PhoneVerificationForm, { PhoneVerificationStepEnum } from "Components/forms/phoneVerification.form"

import useStateIfMounted from "../../shared/utils/hooks/useStateIfMounted"

interface PhoneVerificationModalProps {
    isShowing: boolean
    phoneNumber: string | undefined
    employee?: Employee
    card?: any // TODO: update shared type
    onSuccess?: () => void
    onCancel: () => void
}

const PhoneVerificationModal = ({ isShowing, phoneNumber, employee, onSuccess, onCancel, card }: PhoneVerificationModalProps) => {
    const { t } = useTranslation()
    const [workPhoneFormInstance] = Form.useForm()
    const [isLoading, setIsLoading] = useStateIfMounted(false)

    const [currentStep, setCurrentStep] = useStateIfMounted(PhoneVerificationStepEnum.WORK_PHONE_INPUT_STEP)

    const handleHide = async () => {
        setIsLoading(false)
        workPhoneFormInstance.resetFields()
        setCurrentStep(PhoneVerificationStepEnum.WORK_PHONE_INPUT_STEP)
        onCancel()
    }

    return (
        <Modal
            title={t("label:phone_verification.title")}
            visible={isShowing}
            maskClosable={false}
            footer={[
                <Button key={0} className="h-40 md:h-34" onClick={handleHide} type="default">
                    {t("action:cancel")}
                </Button>,
                currentStep === PhoneVerificationStepEnum.WORK_PHONE_VERIFICATION_CODE_STEP && (
                    <Button key={1} className="h-40 md:h-34" onClick={() => setCurrentStep(PhoneVerificationStepEnum.WORK_PHONE_INPUT_STEP)} type="default">
                        {t("action:back")}
                    </Button>
                ),

                <Button key={2} className="h-40 md:h-34" onClick={workPhoneFormInstance.submit} type="primary" loading={isLoading}>
                    {t("action:send")}
                </Button>,
            ]}
            confirmLoading={isLoading}
            onCancel={handleHide}
            keyboard
            destroyOnClose
        >
            <PhoneVerificationForm
                workPhoneFormInstance={workPhoneFormInstance}
                employee={employee}
                card={card}
                phoneNumber={phoneNumber}
                onCurrentStepChange={setCurrentStep}
                currentStep={currentStep}
                onCancel={handleHide}
                onSuccess={onSuccess}
                setIsLoading={setIsLoading}
            />
        </Modal>
    )
}

export default React.memo(PhoneVerificationModal)
