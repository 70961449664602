import { BankAccount, BankConnection } from "@finway-group/shared/lib/models"
import { useSelector } from "react-redux"

import { RootState } from "Shared/store/rootState.interface"

export const useBankConnections = ({ shouldFilterDeleted }: { shouldFilterDeleted: boolean }) => {
    const connections = useSelector(({ banks: { items } }: RootState) => items)
    return shouldFilterDeleted ? connections.filter((connection: BankConnection) => !connection.deleted) : connections
}

export const useDefaultConnection = () => useSelector(({ banks }: RootState) => banks.items)?.find((con: BankConnection) => con.isDefault)

export const useDefaultAccount = () => useDefaultConnection()?.accounts.find((account: BankAccount) => account.isDefault)?.accountId

export const useAccountIdsMap = ({ shouldFilterDeleted }: { shouldFilterDeleted: boolean }) => {
    const connections = useBankConnections({ shouldFilterDeleted })
    const map = new Map<string, { bankName: string; accountName: string }>()

    for (const bankConnection of connections) {
        for (const account of bankConnection.accounts) {
            map.set(account.accountId, {
                bankName: bankConnection.bankName,
                accountName: account.accountName,
            })
        }
    }
    return map
}

export const useAccounts = ({ shouldFilterDeleted }: { shouldFilterDeleted: boolean }) => {
    const accounts: Array<BankAccount> = []
    const bankConnections = useBankConnections({ shouldFilterDeleted })

    for (const bankConnection of bankConnections) {
        for (const account of bankConnection.accounts) {
            accounts.push(account)
        }
    }
    return accounts
}

export const useAccountIds = ({ shouldFilterDeleted }: { shouldFilterDeleted: boolean }) => {
    const connections = useBankConnections({ shouldFilterDeleted })
    const accountIdArray: Array<string> = []

    for (const bankConnection of connections) {
        for (const account of bankConnection.accounts) {
            accountIdArray.push(account.accountId)
        }
    }
    return accountIdArray
}
