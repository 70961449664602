import {
    BillingPeriodEnum,
    Company,
    CreditorInterface,
    CurrencyEnum,
    Employee,
    Expense,
    ExpenseKindEnum,
    ExpenseStatusEnum,
    Hospitality,
    InboxInvoice,
    ItemSplit,
    Mileage,
    PerDiem,
    PerDiemDestination,
    SplitTypeEnum,
    SubscriptionTypeEnum,
    Tax,
    TripFolder,
    VehicleEnum,
    Vendor,
} from "@finway-group/shared/lib/models"
import { ExpensePaymentOptionEnum } from "@finway-group/shared/lib/models/expense/expensePaymentOption.enum"
import { ItemSplitKindEnum } from "@finway-group/shared/lib/models/expense/expenseSplit.model"
import { SubscriptionRenewalGenerationTypeEnum } from "@finway-group/shared/lib/models/expense/subscriptionRenewalGenerationType.enum"
import { FormInstance } from "antd/lib/form"
import moment, { Moment } from "moment"

import { DEFAULT_PER_DIEM_DESTINATION, MAX_PER_DIEM_DESTINATION_DAYS, NOT_SET_VALUE } from "Shared/config/consts"
import { getEmployeesFromStore } from "Shared/hooks/employee.hooks"
import i18n from "Shared/locales/i18n"
import { ApprovalProcessService } from "Shared/services"
import {
    buildUpdateDataAccordingToSetting,
    determineDefaultVendorForReimbursement,
    getCreditorUpdateObject,
    getExpenseModelByKind,
    isFolderExpense,
    isHospitalityExpense,
    isMileageExpense,
    isPerDiemExpense,
} from "Shared/utils/expense.utils"
import { getDefaultTaxRateObject } from "Shared/utils/helper.utils"
import { extractTextFromHtml } from "Shared/utils/htmlParser.utils"

interface FolderFields {
    tripDate?: Moment
    returnDate?: Moment
}

// TODO: chase down 'transactionData' type & narrow-down
interface InitialExpenseStateInterface {
    isNew: boolean
    prefilledExpense: Expense
    isInvoiceRequest: boolean
    isReimbursement: boolean
    isPreApprovedSubscription: boolean
    kind: ExpenseKindEnum
    inboxInvoices: Array<InboxInvoice>
    transactionData: any
    employees: Array<Employee>
    vendors: Array<Vendor>
    loggedInProfile: Employee
    taxes: Array<Tax>
    creditors: Array<CreditorInterface>
    perDiemDestinations: Array<PerDiemDestination>
    company: Company
    folder?: Partial<TripFolder>
}

interface InitializeNewExpenseDataInterface {
    isInvoiceRequest: boolean
    isReimbursement: boolean
    isPreApprovedSubscription: boolean
    kind: ExpenseKindEnum
    inboxInvoices: Array<InboxInvoice>
    transactionData: any
    employees: Array<Employee>
    vendors: Array<Vendor>
    loggedInUser: Employee
    taxes: Array<Tax>
    creditors: Array<CreditorInterface>
    perDiemDestinations: Array<PerDiemDestination>
    company: Company
    // if folder is set that means the expense is created under a folder.
    folder?: Partial<TripFolder>
}
interface InitializeOnKindChangeInterface {
    expense: Expense
    isReimbursement: boolean
    kind: ExpenseKindEnum
    transactionData: any
    employees: Array<Employee>
    vendors: Array<Vendor>
    loggedInUser: Employee
    isOcrProcessed: boolean | undefined
    expenseForm: FormInstance
    taxes: Array<Tax>
    isNew: boolean
    perDiemDestinations: Array<PerDiemDestination>
    creditors: Array<CreditorInterface>
    company: Company
    originalData: any
    folder?: TripFolder
}

class ExpenseInitializer {
    initializeExpenseState = ({
        isNew,
        prefilledExpense,
        isInvoiceRequest,
        isReimbursement,
        isPreApprovedSubscription,
        kind,
        inboxInvoices,
        transactionData,
        employees,
        vendors,
        loggedInProfile,
        taxes,
        creditors,
        perDiemDestinations,
        company,
        folder,
    }: InitialExpenseStateInterface) => {
        const initExpense =
            !isNew && prefilledExpense
                ? this._initializeExpenseDataFromExisting(prefilledExpense, employees)
                : this.initializeNewExpenseData({
                      isInvoiceRequest,
                      isReimbursement,
                      isPreApprovedSubscription,
                      kind,
                      inboxInvoices,
                      transactionData,
                      employees,
                      vendors,
                      loggedInUser: loggedInProfile,
                      taxes,
                      creditors,
                      perDiemDestinations,
                      company,
                      folder,
                  })

        const ExpenseModel = getExpenseModelByKind(kind)
        return new ExpenseModel(initExpense)
    }

    preProcessFolderFieldsForForm = (expense: Expense): FolderFields => {
        const folderFields: FolderFields = {}

        if (isFolderExpense(expense)) {
            folderFields.tripDate = moment(expense.tripDate)
            folderFields.returnDate = moment(expense.returnDate)
        }
        return folderFields
    }

    preProcessReimbursementFieldsForForm = (expense: Expense): Partial<PerDiem & Hospitality & Mileage> => {
        const employees = getEmployeesFromStore()
        const reimbursementFields: Partial<PerDiem & Hospitality & Mileage> = {}

        if (isPerDiemExpense(expense)) {
            reimbursementFields.billable = expense.billable

            // dailyExpenses.date needs Date, but antd need moment. So here we're making the date moment
            reimbursementFields.dailyExpenses = expense.dailyExpenses.map(({ date, destination, expenditure }): any => ({
                date: moment(date),
                destination,
                expenditure,
            }))

            // destinations.start/endDate needs Date, but antd need moment. So here we're making the date moment
            reimbursementFields.destinations = expense.destinations.map(({ destination, startDate, endDate }): any => ({
                destination,
                startDate: startDate ? moment(startDate) : undefined,
                endDate: endDate ? moment(endDate) : undefined,
            }))
        } else if (isHospitalityExpense(expense)) {
            reimbursementFields.merchant = expense.merchant
            reimbursementFields.attendees = expense.attendees

            // antd requires {value, label, key}, so we use any here
            reimbursementFields.attendees = (expense.attendees ?? []).map((a): any => ({
                value: a.user,
                key: a.user,
                label: a.internal ? employees.find((e) => e.id === a.user)?.getFullName() : a.user,
            }))
        } else if (isMileageExpense(expense)) {
            reimbursementFields.additionalStopReason = expense.additionalStopReason
            reimbursementFields.stops = expense.stops
            reimbursementFields.vehicle = expense.vehicle
            reimbursementFields.tripReason = expense.tripReason
            reimbursementFields.actualDistance = expense.actualDistance
            reimbursementFields.expectedDistance = expense.expectedDistance
        }
        return reimbursementFields
    }

    private initialEmptyExpenseState = () => ({
        datePurchased: moment(),
        dateCancellationDue: moment().add(1, "year").subtract(1, "day"),
        name: "",
        description: "",
        kind: ExpenseKindEnum.ONE_TIME_EXPENSE,
        subscriptionType: SubscriptionTypeEnum.INITIAL_REQUEST,
        renewalGenerationType: SubscriptionRenewalGenerationTypeEnum.BY_DATE,
        paymentOption: ExpensePaymentOptionEnum.BANK_TRANSFER,
        renewalsExpenses: [],
        splits: [],
        offers: [],
        approvalProcesses: [],
    })

    private initializeNewExpenseData = ({
        isInvoiceRequest,
        isReimbursement,
        isPreApprovedSubscription,
        kind,
        inboxInvoices,
        transactionData,
        employees,
        vendors,
        loggedInUser,
        taxes,
        creditors,
        perDiemDestinations,
        company,
        // if folder is set that means the expense is created under a folder.
        folder,
    }: InitializeNewExpenseDataInterface) => {
        const initialData: any = {
            ...this.initialEmptyExpenseState(),
            paymentOption: ExpensePaymentOptionEnum.BANK_TRANSFER,
            kind,
            isReimbursement,
            requestedBy: { _id: loggedInUser.id },
            createdBy: { _id: loggedInUser.id },
            status: this._getExpenseInitialStatus(isPreApprovedSubscription, isInvoiceRequest, isReimbursement, kind),
            currency: loggedInUser.settings.globalCurrency || CurrencyEnum.EUR,
            billingPeriod: BillingPeriodEnum.MONTHLY,
            link: undefined,
            invoices: inboxInvoices.map((inboxInvoice) =>
                inboxInvoice.url ? { url: inboxInvoice.url, hash: inboxInvoice.hash, fileName: inboxInvoice.fileName, uploadDate: inboxInvoice.uploadDate, isFromInbox: true } : [],
            ),
            // Folders does not have a tax rate.
            taxRate: kind === ExpenseKindEnum.TRIP_FOLDER ? { _id: NOT_SET_VALUE } : taxes.find(({ isDefault, deleted }) => isDefault && !deleted),
            totalNetPrice: 0,
            totalTaxPrice: 0,
            totalGrossPrice: 0,
            splits: [],
            ...(folder
                ? {
                      description: folder.description,
                      performancePeriod: [moment(folder.tripDate), moment(folder.returnDate)],
                      expenseAccount: folder.expenseAccount,
                      costCenter: folder.costCenter,
                      costCenter2: folder.costCenter2,
                      requestedBy: folder.requestedBy,
                      createdBy: folder.createdBy,
                      approvalNeededBy: folder.approvalNeededBy,
                  }
                : {}),
            ...(transactionData ? this._initializeFromTransactionData(transactionData, vendors) : undefined),
            ...(isPreApprovedSubscription && { datePaidAt: new Date() }),
            ...this._getInitialNewExpenseDataForKind(kind, loggedInUser.id, taxes, creditors, perDiemDestinations),
        }

        // Vendor and Employee rules
        Object.assign(initialData, buildUpdateDataAccordingToSetting(initialData, vendors, employees, company))

        return initialData
    }

    private _initializeExpenseDataFromExisting = (expenseDetails: Expense, employees: Array<Employee>) => {
        const nextApprover = ApprovalProcessService.getNextApprover(expenseDetails.approvalProcesses)
        const reimbursementSpecificFields = this.preProcessReimbursementFieldsForForm(expenseDetails)
        const folderSpecificFields = this.preProcessFolderFieldsForForm(expenseDetails)
        return {
            ...expenseDetails,
            description: extractTextFromHtml(expenseDetails.description || ""),
            invoices: expenseDetails.invoices,
            deliveryNote: expenseDetails.deliveryNote,
            ...(expenseDetails.invoiceDate && { invoiceDate: moment(expenseDetails.invoiceDate) }),
            ...(expenseDetails.invoiceDueDate && { invoiceDueDate: moment(expenseDetails.invoiceDueDate) }),
            ...(expenseDetails.datePaidAt && { datePaidAt: moment(expenseDetails.datePaidAt) }),
            datePurchased: moment(expenseDetails.datePurchased),
            ...(expenseDetails.dateCancellationDue && { dateCancellationDue: moment(expenseDetails.dateCancellationDue) }),
            // requestedBy: requester?.id, // TODO SAFE ?????
            approvalNeededBy: !nextApprover?.activeCompanyProfile?.deleted ? nextApprover?.id : undefined, // TODO SAFE ?? CAST TO OBJECT ?
            totalTaxPrice: expenseDetails.totalTaxPrice,
            totalGrossPrice: expenseDetails.totalGrossPrice,
            paymentFlowInformation: expenseDetails.paymentFlowInformation ?? NOT_SET_VALUE,
            splits: expenseDetails.splits,
            ...(expenseDetails.performancePeriodStartDate &&
                expenseDetails.performancePeriodEndDate && {
                    performancePeriod: [moment(expenseDetails.performancePeriodStartDate), moment(expenseDetails.performancePeriodEndDate)],
                }),
            ...reimbursementSpecificFields,
            ...folderSpecificFields,
        }
    }

    private _initializeFromTransactionData = (transactionData: any, vendors: Array<Vendor>) => {
        const transactionVendorId = transactionData && vendors.find(({ id }: Vendor) => id === transactionData.vendor)?.id
        return {
            totalNetPrice: Math.abs(Number(transactionData.amount)),
            totalGrossPrice: Math.abs(Number(transactionData.amount)),
            totalTaxPrice: 0,
            splits: [],
            datePurchased: moment(transactionData.date),
            invoiceDate: moment(transactionData.date),
            invoiceDueDate: moment(transactionData.date),
            ...(transactionData.vendor && transactionVendorId && { vendor: transactionVendorId }),
            ...(transactionData.currency && { currency: transactionData.currency }),
        }
    }

    private _getExpenseInitialStatus = (isPreApprovedSubscription: boolean, isInvoiceRequest: boolean, isReimbursement: boolean, kind: ExpenseKindEnum) => {
        if (isPreApprovedSubscription) return ExpenseStatusEnum.DONE
        if (isInvoiceRequest || isReimbursement || kind === ExpenseKindEnum.TRIP_FOLDER) return ExpenseStatusEnum.INVOICE_PENDING
        return ExpenseStatusEnum.PURCHASE_PENDING
    }

    initializeNewItemSplit = (taxes: Array<Tax>) => ({ name: "", taxRate: getDefaultTaxRateObject(taxes)!, netPrice: 0, grossPrice: 0, taxPrice: 0 })

    initializeOnKindChange = ({
        expense,
        isReimbursement,
        kind,
        transactionData,
        employees,
        vendors,
        loggedInUser,
        isOcrProcessed = false,
        expenseForm,
        taxes,
        isNew,
        perDiemDestinations,
        creditors,
        company,
        originalData,
        folder, // folder this expense to be attached to on creation
    }: InitializeOnKindChangeInterface) => {
        const initialData = {
            ...expense,
            isReimbursement,
            currency: loggedInUser.settings.globalCurrency || CurrencyEnum.EUR,
            ...expenseForm.getFieldsValue(),
            kind: kind || expense.kind,
            billingPeriod: BillingPeriodEnum.MONTHLY,
            dateCancellationDue: moment().add(1, "year").subtract(1, "day"),
            ...(!isOcrProcessed && { totalNetPrice: 0, totalTaxPrice: 0, totalGrossPrice: 0 }),
            link: undefined,
            name: !isOcrProcessed ? "" : expense.name,
            description: "",
            ...(!isOcrProcessed && { taxRate: getDefaultTaxRateObject(taxes) }),
            splits: isOcrProcessed ? originalData.splits : [],
            splitType: undefined,
            ...(transactionData ? this._initializeFromTransactionData(transactionData, vendors) : undefined),
            ...(isNew ? this._getInitialNewExpenseDataForKind(kind, expenseForm.getFieldValue("requestedBy"), taxes, creditors, perDiemDestinations, folder) : {}),
        }

        // Vendor and Employee rules
        Object.assign(initialData, buildUpdateDataAccordingToSetting(initialData, vendors, employees, company))

        // determine a default approver if there is no approval process

        return initialData
    }

    private _getInitialNewExpenseDataForKind = (
        kind: ExpenseKindEnum,
        requestedBy: string,
        taxes: Array<Tax>,
        creditors?: Array<CreditorInterface>,
        perDiemDestinations?: Array<PerDiemDestination>,
        folder?: TripFolder,
    ) => {
        if (kind === ExpenseKindEnum.PER_DIEM && perDiemDestinations?.length && creditors?.length) {
            const defaultVendor = determineDefaultVendorForReimbursement(requestedBy, kind, creditors)

            const startDate = folder?.tripDate ? moment(folder.tripDate) : moment()
            const endDate = folder?.returnDate ? moment(folder.returnDate) : startDate.clone().add(1, "day")

            const perDiemDestination = perDiemDestinations.find((d) => d.destination === DEFAULT_PER_DIEM_DESTINATION && d.year === startDate.year())
            // Limit the endDate to be startDate + 90 Days if the folder's duration is more
            const safeEndDate = Math.abs(startDate.diff(endDate, "day")) > MAX_PER_DIEM_DESTINATION_DAYS ? startDate.clone().add(MAX_PER_DIEM_DESTINATION_DAYS, "day") : endDate

            return {
                currency: CurrencyEnum.EUR,
                destinations: [{ destination: perDiemDestination?._id, startDate, endDate: safeEndDate }],
                ...getCreditorUpdateObject(defaultVendor?.id, defaultVendor?.type),
                invoiceNumber: undefined,
                taxRate: { _id: NOT_SET_VALUE },
            }
        }
        if (kind === ExpenseKindEnum.MILEAGE && creditors?.length) {
            const defaultVendor = determineDefaultVendorForReimbursement(requestedBy, kind, creditors)
            return {
                ...getCreditorUpdateObject(defaultVendor?.id, defaultVendor?.type),
                stops: [null, null],
                vehicle: VehicleEnum.CAR,
                invoiceNumber: undefined, // Let the mileage form ask the server about this.
                taxRate: { _id: NOT_SET_VALUE },
            }
        }
        if (kind === ExpenseKindEnum.HOSPITALITY) {
            return {
                invoiceNumber: undefined,
                splitType: SplitTypeEnum.ITEM,
                splits: [
                    {
                        name: i18n.t("label:hospitality.beverages"),
                        netPrice: 0,
                        taxRate: getDefaultTaxRateObject(taxes),
                        taxPrice: 0,
                        grossPrice: 0,
                    },
                    {
                        name: i18n.t("label:hospitality.tip"),
                        netPrice: 0,
                        taxRate: taxes?.find(({ taxRate }) => taxRate === 0) ?? taxes?.[0],
                        taxPrice: 0,
                        grossPrice: 0,
                        kind: ItemSplitKindEnum.TIP,
                    },
                ] as Array<ItemSplit>,
            }
        }
        return undefined
    }
}

export default new ExpenseInitializer()
