import { ExpenseTagColorEnum, ExpenseTag as ExpenseTagModel } from "@finway-group/shared/lib/models"
import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import { useExpenseTagsByIds, useExpenseTagsInLoadingState } from "Shared/hooks/expenseTags.hooks"
import { ExpenseTagService, NotificationService } from "Shared/services"
import DialogService from "Shared/services/dialog.service"
import { attachTagExpense, detachTagExpense } from "Shared/store/actions/expense/expenseActions"
import { createExpenseTag, deleteExpenseTag } from "Shared/store/actions/expenseTag/expenseTagActions"

import ExpenseTag from "./expenseTag"
import ExpenseTagManagerPopover from "./popovers/expenseTagManager.popover"
import ExpenseTagViewer from "./popovers/expenseTagViewer.popover"

interface ExpenseTagSectionPropInterface {
    expenseId: string
    expenseTags: Array<string>
    onTagChange?: (tagId: string, detach: boolean) => void
    hideButton?: boolean
    hideTags?: boolean
    inCreation?: boolean
}

const MAX_TAGS = 3

const ExpenseTagSection: React.FC<ExpenseTagSectionPropInterface> = ({ expenseId, expenseTags, onTagChange, hideButton = false, hideTags = false, inCreation = false }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const attachedTags = useExpenseTagsByIds(expenseTags)
    const { expenseTagsLoading, addTagInLoading, removeTagFromLoading } = useExpenseTagsInLoadingState()

    const onDetachTagExpense = async (expenseTagId: string) => {
        try {
            addTagInLoading(expenseTagId)
            if (!inCreation) await dispatch(detachTagExpense(expenseTagId, expenseId))
            else if (onTagChange) onTagChange(expenseTagId, true)
        } catch (err) {
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:expense_tag.detach.title"))
        } finally {
            removeTagFromLoading(expenseTagId)
        }
    }

    const onTagSelected = async (expenseTagId: string) => {
        try {
            addTagInLoading(expenseTagId)
            if (!inCreation) await dispatch(attachTagExpense(expenseTagId, expenseId))
            if (onTagChange) onTagChange(expenseTagId, false)
        } catch (err) {
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:expense_tag.attach.title"))
        } finally {
            removeTagFromLoading(expenseTagId)
        }
    }

    const onTagCreate = async (tagColor: ExpenseTagColorEnum, tagName: string) => {
        try {
            if (!inCreation) await dispatch(createExpenseTag({ tagName, tagColor }, expenseId))
            else
                await dispatch(
                    createExpenseTag({ tagName, tagColor }, undefined, (id: string) => {
                        if (onTagChange) onTagChange(id, false)
                    }),
                )
        } catch (err) {
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:expense_tag.create.title"))
        }
    }

    const onTagDelete = async (expenseTagId: string, tagName: string) => {
        try {
            const data = await ExpenseTagService.getTotalTagAttachCount(expenseTagId)
            if (await DialogService.confirmTagDelete(tagName, data.totalCount)) {
                try {
                    await dispatch(deleteExpenseTag(expenseTagId))
                    if (onTagChange) onTagChange(expenseTagId, true)
                } catch (err) {
                    NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:expense_tag.delete.title"))
                }
            }
        } catch (err) {
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:expense_tag.delete.title"))
        }
    }

    const renderExpenseTags = (expenseTags: Array<ExpenseTagModel>) =>
        expenseTags.map((expenseTag) => (
            <ExpenseTag
                className="m-5 h-34"
                key={expenseTag._id}
                color={expenseTag.tagColor}
                onClose={() => onDetachTagExpense(expenseTag._id)}
                closable={!expenseTagsLoading.includes(expenseTag._id)}
                isLoading={expenseTagsLoading.includes(expenseTag._id)}
            >
                <div className="max-w-240 truncate">{expenseTag.tagName}</div>
            </ExpenseTag>
        ))

    const renderExpenseTagsFormatted =
        attachedTags.length > MAX_TAGS ? (
            <>
                {renderExpenseTags(attachedTags.slice(0, MAX_TAGS - 1))}
                <ExpenseTagViewer expenseTags={attachedTags.slice(MAX_TAGS - 1)} onDelete={onDetachTagExpense}>
                    <ExpenseTag className="m-5 h-34" color={attachedTags[MAX_TAGS - 1].tagColor}>
                        + {attachedTags.slice(MAX_TAGS - 1).length}
                    </ExpenseTag>
                </ExpenseTagViewer>
            </>
        ) : (
            renderExpenseTags(attachedTags)
        )

    return (
        <>
            {!hideTags && renderExpenseTagsFormatted}
            {!hideButton && <ExpenseTagManagerPopover existingTags={attachedTags} onTagSelected={onTagSelected} onTagCreate={onTagCreate} onTagDelete={onTagDelete} />}
        </>
    )
}

export default ExpenseTagSection
