import Icon, { CarOutlined, DollarCircleOutlined } from "@ant-design/icons"
import { Expense, ExpenseKindEnum, Hospitality, Mileage, PerDiem } from "@finway-group/shared/lib/models"
import React from "react"
import { Coffee, Home } from "react-feather"
import { useTranslation } from "react-i18next"

import DiscountIcon from "Components/icons/discountIcon"
import PriceLabel from "Components/priceLabel"

interface ExpenseFolderItemRowInterface {
    expense: Expense | Mileage | PerDiem | Hospitality
}

export const ExpenseFolderItemRow = ({ expense }: ExpenseFolderItemRowInterface) => {
    const { t } = useTranslation()

    const getExpenseTypeString = () => {
        let expenseString = ""
        switch (expense.kind) {
            case ExpenseKindEnum.MILEAGE:
                expenseString = t("label:expense_types.mileage_reimbursement")
                break
            case ExpenseKindEnum.PER_DIEM:
                expenseString = t("label:expense_types.per_diem_reimbursement")
                break
            case ExpenseKindEnum.HOSPITALITY:
                expenseString = t("label:expense_types.hospitality_reimbursement")
                break
            default:
                if (expense.isReimbursement) expenseString = t("label:expense_types.standard_reimbursement")
                else expenseString = t("label:expense_types.invoice_request")
                break
        }

        return expenseString
    }

    const getExpenseIcon = () => {
        switch (expense.kind) {
            case ExpenseKindEnum.MILEAGE:
                return <CarOutlined />
            case ExpenseKindEnum.PER_DIEM:
                return <Icon component={() => <Home size={"1em"} />} />
            case ExpenseKindEnum.HOSPITALITY:
                return <Icon component={() => <Coffee size={"1em"} />} />
            default:
                return <DollarCircleOutlined />
        }
    }

    const discount = expense.getUsedOrActiveDiscount()
    const displayAmount = discount?.reducedAmount?.gross ?? expense.totalGrossPrice
    return (
        <div className="w-full flex justify-between border-solid border-1 rounded py-10 px-10 my-8">
            <div className="flex justify-start gap-12 align-center ">
                <div className="flex align-center mt-4">{getExpenseIcon()}</div>
                <div>{getExpenseTypeString()}</div>
            </div>
            <div className="flex gap-8">
                {discount && <DiscountIcon className="finway-green-2-text mt-4" />}
                <PriceLabel value={displayAmount} currency={expense.currency} />
            </div>
        </div>
    )
}
