import { EllipsisOutlined, LoadingOutlined, PlusOutlined } from "@ant-design/icons"
import { RightEnum, TransactionCategory, TransactionCategoryTypeEnum } from "@finway-group/shared/lib/models"
import { Button, Col, Dropdown, Menu, Table, Tabs } from "antd"
import { ColumnsType } from "antd/lib/table"
import React, { useEffect, useRef } from "react"
import { Edit as EditIcon, Trash as TrashIcon } from "react-feather"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc"
import { useDebouncedCallback } from "use-debounce/lib"

import CornerDownRightArrowIcon from "Components/icons/cornerDownRightArrow"
import DownCircleIcon from "Components/icons/downCircleIcon"
import MenuTwoLinesIcon from "Components/icons/menuTwoLinesIcon"
import UpCircleIcon from "Components/icons/upCircleIcon"
import { ErrorTable } from "Components/layout/errorTable"
import TransactionCategoryModal, { ModalTypeEnum } from "Components/modals/transactionCategory.modal"
import SearchInput from "Components/searchInput"
import UpgradeAlert from "Components/upgradeAlert"
import TransactionCategoryListBanner from "Features/pages/liquidity/tabs/transactionCategoryListBanner"
import { useModal } from "Shared/context/modal.context"
import { useIsFreeVersion } from "Shared/hooks/featureFlags.hooks"
import { useLiquidityActiveTab } from "Shared/hooks/liquidity.hooks"
import { useFetchTable, useTable } from "Shared/hooks/table.hooks"
import { OTHER_INFLOWS_EXTERNAL_NAME, OTHER_OUTFLOWS_EXTERNAL_NAME } from "Shared/hooks/transactionCategory.hooks"
import { AuthzService } from "Shared/services"
import { refetchTable, updateTableOnChange, updateTableSearch } from "Shared/store/actions/tables/tableActions"
import { updateTransactionCategoryOrder } from "Shared/store/actions/transactionCategory/transactionCategoryAction"
import { TablesEnum } from "Shared/store/reducers/tableConfigReducer"
import { Table as TableInterface, TransactionCategoryWithChildren } from "Shared/store/reducers/tableReducer"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"
import { getSortOrderForColumn } from "Shared/utils/table.utils"

import { LMTabTypeEnum } from "../liquidity.types"

interface TransactionCategoriesTabInterface {
    setControls: (controls: JSX.Element) => void
}

const TransactionCategoriesTab: React.FC<TransactionCategoriesTabInterface> = ({ setControls }) => {
    const { t } = useTranslation()
    const dragAndDropAnchor = useRef(null)
    const [activeTab, setActiveTab] = useStateIfMounted<TransactionCategoryTypeEnum>(TransactionCategoryTypeEnum.INFLOW)
    const dispatch = useDispatch()
    const { showModal } = useModal()
    const isFreeVersion = useIsFreeVersion()
    const liquidityActiveTab = useLiquidityActiveTab()

    const [isUpdatingOrder, setIsUpdatingOrder] = useStateIfMounted(false)
    // temporaryDocs is a variable state to temporary show the new ordered list while the back end is doing operations to update the order.
    const [tempDocs, setTempDocs] = useStateIfMounted<Array<TransactionCategoryWithChildren> | undefined>(undefined)

    const transactionCategoriesInflowTable = useTable<TransactionCategoryWithChildren>(TablesEnum.TRANSACTION_CATEGORIES_INFLOW)
    const transactionCategoriesOutflowTable = useTable<TransactionCategoryWithChildren>(TablesEnum.TRANSACTION_CATEGORIES_OUTFLOW)

    useFetchTable(TablesEnum.TRANSACTION_CATEGORIES_INFLOW)
    useFetchTable(TablesEnum.TRANSACTION_CATEGORIES_OUTFLOW)

    const handleTabClick = (key: TransactionCategoryTypeEnum) => {
        if (key === activeTab || isUpdatingOrder) return
        setActiveTab(key)
        if (!isFreeVersion) setControls(renderControls(key))
    }

    useEffect(() => {
        if (!isFreeVersion && liquidityActiveTab === LMTabTypeEnum.TRANSACTION_CATEGORIES) {
            setControls(renderControls(activeTab))
        }
    }, [liquidityActiveTab])

    const DragHandle = SortableHandle(() => <MenuTwoLinesIcon style={{ cursor: "grab", color: "#999" }} />)
    const SortableItem = SortableElement((props: any) => <tr {...props} />)
    const SortableWrapper = SortableContainer((props: any) => <tbody {...props} />)
    const getDraggableWrapper = (props: any, docs: Array<any>, helperClass: string) => (
        <SortableWrapper
            helperContainer={dragAndDropAnchor.current}
            helperClass={helperClass}
            useDragHandle
            disableAutoscroll
            onSortEnd={(props: any) => handleDragEnd(props, docs)}
            {...props}
        />
    )

    const handleDragEnd = async ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }, docs: Array<any>) => {
        if (oldIndex === newIndex) return
        // update order in the FE to quickly visualize the list ordered using the tempDocs state variable
        const newDocsList = [...docs]
        const [removedElement] = newDocsList.splice(oldIndex, 1)
        newDocsList.splice(newIndex, 0, removedElement)
        setTempDocs(newDocsList)

        // update the order in the BE
        const transactionCategoryId = docs[oldIndex].id
        const newOrder = docs[newIndex].order
        setIsUpdatingOrder(true)
        await dispatch(updateTransactionCategoryOrder(transactionCategoryId, newOrder))
        setTempDocs(undefined)
        setIsUpdatingOrder(false)
    }

    const getDraggableBodyRow = ({ className, ...restProps }: any, docs: Array<any>) => {
        const index = docs.findIndex((doc) => doc.id === restProps["data-row-key"])
        if (docs[index]) {
            return <SortableItem index={index} className={className} {...restProps} />
        }
        return <tr className={className} {...restProps}></tr>
    }

    const handleSelect = (option: ModalTypeEnum, transactionCategory?: TransactionCategoryWithChildren, transactionCategoryType?: TransactionCategoryTypeEnum) => {
        switch (option) {
            case ModalTypeEnum.CREATE:
                showModal(TransactionCategoryModal, true, { isShowing: true, modalType: ModalTypeEnum.CREATE, transactionCategoryType })
                break
            case ModalTypeEnum.EDIT:
                showModal(TransactionCategoryModal, true, { isShowing: true, modalType: ModalTypeEnum.EDIT, transactionCategory })
                break
            case ModalTypeEnum.DELETE:
                showModal(TransactionCategoryModal, true, { isShowing: true, modalType: ModalTypeEnum.DELETE, transactionCategory })
                break
            default:
                break
        }
    }

    const getTableColumns = (table: TableInterface<TransactionCategoryWithChildren>) => {
        const columns: ColumnsType<TransactionCategoryWithChildren> = [
            {
                colSpan: 0,
                key: "order",
                width: 30,
                className: "drag-visible",
                align: "center",
                render: (transactionCategory: TransactionCategory) => (
                    <div key={transactionCategory.id} className="flex mb-4">
                        {/* if table is sorted or filtered we dont show the drag handle */}
                        {!transactionCategory.parentId && !table.data.sort.order && (!table.data.search || table.data.search.length === 0) ? <DragHandle /> : <></>}
                    </div>
                ),
            },
            {
                colSpan: 0,
                dataIndex: "expandIcon",
                width: 30,
                align: "center",
            },
            {
                colSpan: 3,
                key: "name",
                title: t("label:categories"),
                align: "left",
                sorter: (a, b) => `${a.name} ${a.name}`.localeCompare(`${b.name} ${b.name}`),
                sortOrder: getSortOrderForColumn(table, "name"),
                render: (transactionCategory: TransactionCategoryWithChildren) => (
                    <div className="flex justify-between items-center">
                        <div key={transactionCategory.id}>{transactionCategory.name}</div>
                        <Dropdown
                            className="flex items-center z-100"
                            overlay={
                                <Menu onClick={(props) => handleSelect(props.key as ModalTypeEnum, transactionCategory)}>
                                    <Menu.Item key={ModalTypeEnum.EDIT} className="flex items-center gap-12 py-8">
                                        <EditIcon size={14} />
                                        <p className="leading-none text-text">{t("input:transaction_category.edit")}</p>
                                    </Menu.Item>
                                    {transactionCategory.externalName !== OTHER_INFLOWS_EXTERNAL_NAME && transactionCategory.externalName !== OTHER_OUTFLOWS_EXTERNAL_NAME && (
                                        <Menu.Item key={ModalTypeEnum.DELETE} className="flex items-center gap-12 py-8 ">
                                            <TrashIcon size={14} />
                                            <p className="leading-none text-text">{t("input:transaction_category.delete")}</p>
                                        </Menu.Item>
                                    )}
                                </Menu>
                            }
                            trigger={["click"]}
                        >
                            <Button className="w-34" icon={<EllipsisOutlined size={34} />}></Button>
                        </Dropdown>
                    </div>
                ),
            },
        ]
        return columns
    }

    const onExpandIcon = (props: any) => {
        // if the category does not have a parent
        if (!props.record.parentId) {
            // if it does not have any children render nothing
            if (props.record.children?.length === 0) return <></>

            // if it has children render the collapse up/down icon
            return props.expanded ? (
                <div
                    role="button"
                    onClick={(e: any) => {
                        props.onExpand(props.record, e)
                    }}
                >
                    <UpCircleIcon />
                </div>
            ) : (
                <div
                    role="button"
                    onClick={(e: any) => {
                        props.onExpand(props.record, e)
                    }}
                >
                    <DownCircleIcon />
                </div>
            )
        }
        // if the category has a parent
        return <CornerDownRightArrowIcon />
    }

    const searchCallback = useDebouncedCallback(async (searchString: string) => {
        dispatch(updateTableSearch(TablesEnum.TRANSACTION_CATEGORIES_INFLOW, searchString))
        dispatch(updateTableSearch(TablesEnum.TRANSACTION_CATEGORIES_OUTFLOW, searchString))
    }, 800)

    const renderTabPane = (type: TransactionCategoryTypeEnum, tableEnum: TablesEnum, table: TableInterface<TransactionCategoryWithChildren>) => (
        <Tabs.TabPane forceRender={true} tab={`${t(`label:${type.toLocaleLowerCase()}`)}`} key={type}>
            <div className="overflow-auto p-2 animation-appear">
                {table.error ? (
                    <ErrorTable onTableReload={() => dispatch(refetchTable(tableEnum))} isLoading={table.isFetching} />
                ) : (
                    <Table
                        rowKey={(transactionCategory: TransactionCategoryWithChildren) => transactionCategory.id}
                        columns={getTableColumns(table)}
                        dataSource={tempDocs || table.data.docs}
                        components={{
                            body: {
                                wrapper: (props: any) =>
                                    getDraggableWrapper(props, table.data.docs, "transaction-categories-table-row-dragging" /* applies styles when dragging the row */),
                                row: (props: any) => getDraggableBodyRow(props, table.data.docs),
                            },
                        }}
                        loading={{
                            spinning: table.isFetching || isUpdatingOrder,
                            indicator: <LoadingOutlined style={{ fontSize: 30, color: "black" }} spin />,
                        }}
                        onChange={updateTableOnChange(tableEnum, table.data, false)}
                        expandIconColumnIndex={1}
                        pagination={{
                            position: ["bottomRight"],
                            showSizeChanger: true,
                            defaultPageSize: 20,

                            hideOnSinglePage: false,
                            pageSizeOptions: ["5", "10", "20", "50", "100"],
                        }}
                        expandIcon={(props) => props.record.children && onExpandIcon(props)}
                    />
                )}
            </div>
        </Tabs.TabPane>
    )

    const renderControls = (categoryType: TransactionCategoryTypeEnum) => (
        <>
            <SearchInput onSearch={(value) => searchCallback.callback(value)} value={transactionCategoriesInflowTable.data.search} />
            {AuthzService.isRightGrantedForLoggedInUser(RightEnum.LIQUIDITY__ALL__UPDATE) && (
                <Button type="primary" onClick={() => handleSelect(ModalTypeEnum.CREATE, undefined, categoryType)} icon={<PlusOutlined />}>
                    <span>{t(`action:transaction_category.new_${categoryType.toLocaleLowerCase()}`)}</span>
                </Button>
            )}
        </>
    )

    return (
        <>
            {isFreeVersion && (
                <>
                    <div className="flex gap-10 justify-between items-center mb-16">
                        <h1>{t("label:liquidity.tabs.transaction_categories")}</h1>
                        <div className="flex flex-row space-x-10">
                            <div className="flex btn-wrapper">{renderControls(activeTab)}</div>
                        </div>
                    </div>

                    <TransactionCategoryListBanner />
                </>
            )}
            <Tabs id="inflow-outflow-tabs" defaultActiveKey={TransactionCategoryTypeEnum.INFLOW} activeKey={activeTab} onTabClick={handleTabClick} className="pb-10">
                {renderTabPane(TransactionCategoryTypeEnum.INFLOW, TablesEnum.TRANSACTION_CATEGORIES_INFLOW, transactionCategoriesInflowTable)}
                {renderTabPane(TransactionCategoryTypeEnum.OUTFLOW, TablesEnum.TRANSACTION_CATEGORIES_OUTFLOW, transactionCategoriesOutflowTable)}
            </Tabs>
            {isFreeVersion && (
                <Col span={24}>
                    <UpgradeAlert />
                </Col>
            )}
        </>
    )
}

export default TransactionCategoriesTab
