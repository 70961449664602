import { Button, Empty } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

interface KYBPlaceholderInterface {}

const KYBPlaceholder: React.FC<KYBPlaceholderInterface> = () => {
    const { t } = useTranslation()

    return (
        <Empty
            image={"./icons/creditCard_non-admin.svg"}
            imageStyle={{
                height: 150,
                marginRight: "auto",
                marginLeft: "auto",
                marginTop: "15%",
                marginBottom: "40px",
                display: "inline-block",
            }}
            description={<span className="text-lg font-bold mt-2 mb-0">{t("info:empty_state.kyb.non-admin_title")}</span>}
        >
            <>
                <span className="text-center max-w-450 mx-auto">{t("info:empty_state.kyb.message_non-admin")}</span>
                <br />
                <Link to="/expenses">
                    <Button className={`w-120 mt-20 btn-default btn-highlight-green-active`} type="primary">
                        {t("info:empty_state.kyb.button_home")}
                    </Button>
                </Link>
            </>
        </Empty>
    )
}

export default KYBPlaceholder
