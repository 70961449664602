import { LoadingOutlined } from "@ant-design/icons"
import React from "react"

export default function FullScreenLoader() {
    return (
        <div className="absolute inset-0 flex justify-center items-center">
            <LoadingOutlined style={{ fontSize: 30 }} spin />
        </div>
    )
}
