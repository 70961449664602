import { ActivityFilterEnum } from "@finway-group/shared/lib/models"
import { Dropdown, Empty, Menu, Radio } from "antd"
import React, { useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import LastNotificationIcon from "Components/icons/notificationIcon"
import { useLoggedInEmployee } from "Shared/hooks/employee.hooks"
import { formatNotificationData, useNotifications } from "Shared/queries/notification.queries"
import { getPopupAnchor } from "Shared/utils/popup.utils"

import { NotificationElementSkeleton } from "./notification.element.skeleton"
import NotificationList from "./notification.list"
import { NotificationMarkAllAsViewedButton } from "./notificationMarkAllAsViewed.button"

const NotificationBadge = React.lazy(() => import("./notification.badge"))

const NotificationNavbar = () => {
    const { t } = useTranslation()
    const loggedInEmployee = useLoggedInEmployee()
    const loggedInEmployeeId = loggedInEmployee?.id

    const [open, setOpen] = useState(false)
    const scrollEvent = useRef<HTMLDivElement>(null)
    const [filter, setFilter] = useState(ActivityFilterEnum.UNREAD)

    const notificationsResult = useNotifications({ filter, loggedInEmployeeId, enabled: open })
    const { allNotifications, newNotifications, oldNotifications } = formatNotificationData(notificationsResult)

    const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
        const { isInitialLoading, isFetchingNextPage, hasNextPage } = notificationsResult
        if (isInitialLoading || isFetchingNextPage || !hasNextPage) return

        if (!scrollEvent.current) return
        const eventTarget = event.nativeEvent.target as HTMLElement
        const isScrolledToEnd = eventTarget.scrollTop + 10 >= eventTarget.scrollHeight - eventTarget.clientHeight
        if (isScrolledToEnd) notificationsResult.fetchNextPage()
    }

    return (
        <div className="notification-activity" ref={scrollEvent} onScroll={handleScroll}>
            <Dropdown
                className={"flex items-center mr-20"}
                trigger={["click"]}
                visible={open}
                onVisibleChange={(visible) => setOpen(visible)}
                overlay={
                    <Menu className="z-20 notification-dropdown overscroll-y-auto">
                        <Menu.ItemGroup key="group-filter" title="">
                            <div className="w-full py-5 flex justify-between">
                                <Radio.Group onChange={(e) => setFilter(e.target.value)} defaultValue={ActivityFilterEnum.UNREAD} value={filter}>
                                    <Radio.Button className="m-5" value={ActivityFilterEnum.UNREAD}>
                                        {t("label:unread")}
                                    </Radio.Button>

                                    <Radio.Button className="m-5" value={ActivityFilterEnum.ALL}>
                                        {t("label:all")}
                                    </Radio.Button>
                                </Radio.Group>

                                <NotificationMarkAllAsViewedButton onSuccess={() => setFilter(ActivityFilterEnum.ALL)} />
                            </div>
                        </Menu.ItemGroup>

                        {notificationsResult.isInitialLoading ? (
                            <NotificationElementSkeleton key="notifications-loading" />
                        ) : (
                            <>
                                <NotificationList notifications={newNotifications} onElementClicked={() => setOpen(false)} title={t("label:notifications.most_recent")} />
                                <NotificationList notifications={oldNotifications} onElementClicked={() => setOpen(false)} title={t("label:notifications.older")} />

                                {notificationsResult.isFetchingNextPage && (
                                    <Menu.ItemGroup key="notifications-loading-group">
                                        <NotificationElementSkeleton key="notifications-loading-next" />
                                    </Menu.ItemGroup>
                                )}

                                {allNotifications.length === 0 && (
                                    <Menu.ItemGroup key="notifications-empty-group">
                                        <Menu.Item disabled className="hover:bg-white pointer-events-none">
                                            <Empty
                                                image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                                                imageStyle={{
                                                    height: 60,
                                                    margin: "auto",
                                                }}
                                                description={t("label:no_notifications")}
                                            />
                                        </Menu.Item>
                                    </Menu.ItemGroup>
                                )}

                                {filter === ActivityFilterEnum.ALL && !notificationsResult.hasNextPage && (
                                    <Menu.ItemGroup key="notifications-end-group">
                                        <Menu.Item disabled className="hover:bg-white pointer-events-none">
                                            <LastNotificationIcon className="flex justify-center items-center" />
                                            <p className="text-center whitespace-normal">{t("label:notifications.all_notifications")}</p>
                                        </Menu.Item>
                                    </Menu.ItemGroup>
                                )}
                            </>
                        )}
                    </Menu>
                }
                getPopupContainer={getPopupAnchor()}
            >
                <NotificationBadge />
            </Dropdown>
        </div>
    )
}

export default React.memo(NotificationNavbar)
