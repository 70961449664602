import { CloseOutlined, EditOutlined } from "@ant-design/icons"
import { BudgetPeriods } from "@finway-group/shared/lib/consts"
import { CostCenter, CostCenterTypeEnum, Employee, RightEnum } from "@finway-group/shared/lib/models"
import { GetEmployeeById, GetEmployeesByIds } from "@finway-group/shared/lib/models/user/employee.model"
import { Alert, Button, Card, Col, Dropdown, Menu, Modal, Row, Spin } from "antd"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useHistory, useParams } from "react-router-dom"

import PageCustomHeader from "Components/PageCustomHeader"
import EmployeeLink from "Components/employeeLink"
import GroupedExpenses from "Components/groupedExpenses"
import { useCostCenter, useCostCenterAuthorizedGuard, useCostCenterById } from "Shared/hooks/costCenter.hooks"
import { useEmployees } from "Shared/hooks/employee.hooks"
import { AuthzService, UserService } from "Shared/services"
import NotificationService, { NotificationTypeEnum } from "Shared/services/notification.service"
import { ThunkDispatchResult } from "Shared/store"
import { fetchAllCostCenters, fetchOneCostCenter, updateCostCenter } from "Shared/store/actions/costCenter/costCenterActions"

import CostCenterFormModal from "../costCenterForm/CostCenterFormModal"
import CostCenterBudgetUsageBarCharts from "./costCenterBudgetUsageBarCharts"
import CostCenterBudgetUsageProgressBar from "./costCenterBudgetUsageProgressBar"

const { confirm } = Modal

export const CostCenterDetail = () => {
    const { t } = useTranslation()
    const history = useHistory()
    const { id } = useParams<{ id: string }>()

    const dispatch: ThunkDispatchResult = useDispatch()
    const employees = useEmployees()
    const costCenter = useCostCenter()
    const parentCostCenter = useCostCenterById(costCenter?.parentCostCenter)
    const [showCostCenterForm, setShowCostCenterForm] = useState(false)

    const loggedInUser = UserService.getLoggedInEmployeeProfile()
    const responsibleUser = costCenter && GetEmployeeById(employees, costCenter.responsibleUser)

    const sharedUsers = costCenter && GetEmployeesByIds(employees, costCenter?.sharedUsers)
    const parentSharedUsers = parentCostCenter && GetEmployeesByIds(employees, parentCostCenter?.sharedUsers)

    // ensure that the user is allowed to see this page
    useCostCenterAuthorizedGuard(id as any, costCenter, parentCostCenter, loggedInUser)

    useEffect(() => {
        dispatch(fetchOneCostCenter(id)).catch((_error) => {
            history.push("/costCenters")
        })
    }, [id])

    const handleHide = () => setShowCostCenterForm(false)

    const handleAddBudgetClick = () => setShowCostCenterForm(true)

    const handleOk = async (costCenter: CostCenter) => {
        try {
            await dispatch(
                updateCostCenter(costCenter._id, {
                    ...costCenter,
                    deleted: true,
                    dateDeleted: Date.now(),
                }),
            )
            NotificationService.send(NotificationTypeEnum.WARNING, t("notification:cost_center.deleted.title"), t("notification:cost_center.deleted.message"))
            try {
                await dispatch(fetchAllCostCenters())
                history.push(`/costcenters`)
            } catch (err) {
                NotificationService.send(NotificationTypeEnum.ERROR, t("error:cost_center.delete.title"), t("error:cost_center.delete.message"))
            }
        } catch (err) {
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:cost_center.edit.title"))
        }
    }

    const handleDelete = () => {
        confirm({
            title: t("confirm:cost_center.delete.title"),
            content: `${t("confirm:cost_center.delete.message")} ${sharedUsers && sharedUsers.length > 0 ? t("confirm:cost_center.delete.message_shared_note") : ""}`,
            cancelText: t("confirm:cost_center.delete.cancel"),
            type: "warning",
            okText: t("confirm:cost_center.delete.confirm"),
            onOk() {
                if (costCenter) {
                    handleOk(costCenter)
                }
            },
        })
    }

    const renderSharedUsers = (sharedUsers: Array<Employee>, label: string) => (
        <li>
            <span className="title">{t(label)}</span>
            <Dropdown
                overlay={() => {
                    if (sharedUsers.length === 0) return <></>
                    return (
                        <Menu>
                            {sharedUsers.map((employee) => (
                                <Menu.Item key={employee.id} className="flex justify-start">
                                    <EmployeeLink employee={employee} />
                                </Menu.Item>
                            ))}
                        </Menu>
                    )
                }}
                placement="topRight"
            >
                <Button>
                    <span className="text">{`(${sharedUsers.length}) ${t("label:shared_people")}`}</span>
                </Button>
            </Dropdown>
        </li>
    )

    const actionButtonEdit = (
        <Button className="ml-10 btn-default" onClick={() => setShowCostCenterForm(true)} icon={<EditOutlined />}>
            <span>{t("action:edit")}</span>
        </Button>
    )
    const renderActionButtons = () => {
        if (!costCenter?.deleted) {
            if (AuthzService.isRightGrantedForLoggedInUser(RightEnum.CC1__ALL__MANAGE))
                return (
                    <>
                        <Button className="btn-default" onClick={handleDelete} icon={<CloseOutlined />}>
                            <span>{t("action:delete")}</span>
                        </Button>
                        {actionButtonEdit}
                    </>
                )
            if (loggedInUser.id === costCenter?.responsibleUser || loggedInUser.id === parentCostCenter?.responsibleUser) return <>{actionButtonEdit}</>
        }
        return <></>
    }

    return (
        <Spin spinning={!costCenter}>
            {costCenter && costCenter._id === id && (
                <div>
                    <Helmet>
                        <title>{costCenter.name}</title>
                    </Helmet>
                    {costCenter.deleted && (
                        <Alert
                            className="mb-20"
                            message={t("notification:cost_center.already_deleted.title")}
                            type="error"
                            description={t("notification:cost_center.already_deleted.message")}
                            closable={false}
                            showIcon={true}
                        />
                    )}
                    <CostCenterFormModal isNew={false} isShowing={showCostCenterForm} onHide={handleHide} />
                    <PageCustomHeader
                        title={
                            <div className="flex flex-1">
                                <div className="flex flex-col flex-1 truncate">
                                    <div className="flex flex-1">
                                        <h1 className="mb-3 truncate">{costCenter.name}</h1>
                                    </div>
                                    <p className="leading-tight truncate max-h-200">
                                        {costCenter.costCenterType === CostCenterTypeEnum.COST_CENTER_GROUP
                                            ? t("label:parent_cost_center")
                                            : parentCostCenter
                                            ? `${t("label:sub_cost_center")} ${parentCostCenter.name}`
                                            : `${t("label:individual_cost_center")}`}
                                    </p>
                                </div>
                            </div>
                        }
                        actionButtons={renderActionButtons()}
                    />
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <CostCenterBudgetUsageBarCharts costCenter={costCenter} />
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={10} className="mt-24">
                            <CostCenterBudgetUsageProgressBar costCenter={costCenter} onAddBudgetClick={handleAddBudgetClick} />
                            <h2>{t("label:about")}</h2>
                            <Card>
                                <ul className="description-list">
                                    <li>
                                        <span className="title">{t("label:cost_center")}</span>
                                        <span className="text truncate max-w-300">{costCenter.name}</span>
                                    </li>
                                    {costCenter.costCenterType === CostCenterTypeEnum.SUB_COST_CENTER && (
                                        <li>
                                            <span className="title">{t("label:cost_center_id")}</span>
                                            <span className="text truncate max-w-300">{costCenter.id}</span>
                                        </li>
                                    )}
                                    <li>
                                        <span className="title">{t("label:budget_period")}</span>
                                        <span className="text">{t(`label:${BudgetPeriods[costCenter.budgetPeriod].toLowerCase()}`)}</span>
                                    </li>
                                    <li>
                                        <span className="title">{t("label:budget_currency")}</span>
                                        <span className="text">{costCenter.budgetCurrency}</span>
                                    </li>
                                    <li>
                                        <span className="title">{t("label:responsible_employee")}</span>
                                        <span className="text truncate max-w-300">{responsibleUser && <EmployeeLink employee={responsibleUser} />}</span>
                                    </li>
                                    {parentSharedUsers && renderSharedUsers(parentSharedUsers, "label:shared_via_group_with")}
                                    {sharedUsers && renderSharedUsers(sharedUsers, "label:shared_with")}
                                    <li>
                                        <span className="title">{t("label:creation_date")}</span>
                                        <span className="text">{moment(costCenter.createdAt).format("ll")}</span>
                                    </li>
                                </ul>
                            </Card>
                        </Col>
                        <Col xs={24} md={14} className="mt-24" id="cost-center-expense-section">
                            <GroupedExpenses
                                groupedByField="splits.costCenter"
                                groupedById={
                                    costCenter.costCenterType === CostCenterTypeEnum.COST_CENTER_GROUP && costCenter.subCostCenters ? costCenter.subCostCenters : costCenter._id
                                }
                            />
                        </Col>
                    </Row>
                </div>
            )}
        </Spin>
    )
}

export default React.memo(CostCenterDetail)
