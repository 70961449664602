import { DownOutlined, ExclamationCircleOutlined } from "@ant-design/icons"
import { Button, Dropdown, Menu, Modal } from "antd"
import React from "react"

import { getPopupAnchor } from "Shared/utils/popup.utils"

export interface ConfirmOption {
    action: string
    text: string
}

interface MultiActionConfirmModalInterface {
    isShowing: boolean
    onCancel: () => void
    /**
     * If given, it will show dropdown in the modal. If not, it will show the default button and will send undefined for onConfirm param
     */
    confirmOptions?: Array<ConfirmOption>
    /**
     * action will be empty if confirmOptions is not provided and will pass be clicked key when confirmOptions is populated
     */
    onConfirm: (action?: string) => void
    okButtonText: string
    cancelButtonText: string
    title: string
    description: React.ReactNode
    size?: "sm" | "md" | "lg"
}

export const MultiActionConfirmModal: React.FC<MultiActionConfirmModalInterface> = ({
    isShowing,
    onCancel,
    onConfirm,
    confirmOptions,
    title,
    description,
    okButtonText,
    size = "sm",
    cancelButtonText,
}) => {
    const handleMenuClick = ({ key, domEvent }: any) => {
        domEvent.stopPropagation()
        onConfirm(key)
        onCancel()
    }

    const renderOkButton = () => {
        if (!confirmOptions || confirmOptions.length === 0) {
            return (
                <Button type="primary" onClick={() => onConfirm()}>
                    {okButtonText}
                </Button>
            )
        }

        const DropdownMenu = (
            <Menu onClick={handleMenuClick}>
                {confirmOptions.map((option) => (
                    <Menu.Item key={option.action}>
                        <div>{option.text}</div>
                    </Menu.Item>
                ))}
            </Menu>
        )

        return (
            <Dropdown overlay={DropdownMenu} getPopupContainer={getPopupAnchor()} trigger={["click"]} className="flex items-center">
                <Button type="primary">
                    {okButtonText} <DownOutlined />
                </Button>
            </Dropdown>
        )
    }

    const Footer = () => (
        <div className="flex justify-end gap-8 pb-12">
            <Button onClick={onCancel}>{cancelButtonText}</Button>
            {renderOkButton()}
        </div>
    )

    const getWidth = () => {
        switch (size) {
            case "sm":
                return 416
            case "md":
                return 520
            case "lg":
                return 1000
        }
    }

    return (
        <Modal visible={isShowing} footer={null} onCancel={onCancel} closeIcon={<></>} closable={false} width={getWidth()}>
            <div className="px-10">
                <div className="flex mb-24">
                    <div className="flex-initial">
                        <ExclamationCircleOutlined style={{ fontSize: 22, color: "#FFCA5A" }} type="warn" />
                    </div>
                    <div className="ml-12 flex-1">
                        <div className="mb-12" style={{ fontSize: 16 }}>
                            {title}
                        </div>
                        <div style={{ fontSize: 14 }} className="mt-8">
                            {description}
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </Modal>
    )
}
