import { PerDiem } from "@finway-group/shared/lib/models"
import { Col, Collapse, Divider, Row } from "antd"
import moment from "moment"
import React from "react"
import { useTranslation } from "react-i18next"

import { HoverableInfo } from "Components/forms/expenseForm/reimbursementForms/auxilliary/HoverableInfo"
import DownCircleIcon from "Components/icons/downCircleIcon"
import UpCircleIcon from "Components/icons/upCircleIcon"
import { usePerDiemDestinationMap } from "Shared/hooks/perDiemDestination.hooks"
import { parseHtml } from "Shared/utils/htmlParser.utils"

import { ExpenseHeader } from "../auxiliary/expenseHeader"

interface PerDiemMailDetailsSectionInterface {
    expense: PerDiem
    showHeader?: boolean
}

export const PerDiemMainDetailsSection: React.FC<PerDiemMailDetailsSectionInterface> = ({ expense, showHeader }) => {
    const { t } = useTranslation()
    const { destinationMap, isReady } = usePerDiemDestinationMap()

    if (!isReady) {
        return <></>
    }

    const [firstDestination, ...restDestinations] = expense.destinations

    const renderDestinationRow = (d: PerDiem["destinations"][0], index: number) => {
        const destination = destinationMap.get(d.destination)!
        const startDateMoment = d.startDate ? moment(d.startDate) : undefined
        const endDateMoment = d.endDate ? moment(d.endDate) : undefined
        const isTransfer = expense.destinations.length - 1 !== index
        return (
            <Row key={`per-diem-main-details-destination-${index}`} className="w-full mt-12">
                <Col span={6}>
                    <div>{t("label:per_diem.destination")}</div>
                    <div className="flex justify-start h-32 items-center font-medium">
                        <div className="leading-tight truncate text-base">
                            {destination.country ? `${destination.destination}, ${destination.country}` : destination.destination}
                        </div>
                    </div>
                </Col>
                <Col span={5}>
                    {index === 0 && (
                        <>
                            <div>{t("label:per_diem.start_date")}</div>
                            <div className="flex justify-start h-32 items-center font-medium">
                                <div className="leading-tight truncate text-base">{startDateMoment?.format("DD/MM/YYYY")}</div>
                            </div>
                        </>
                    )}
                </Col>
                <Col span={5}>
                    {index === 0 && (
                        <>
                            <div>{t("label:per_diem.time")}</div>
                            <div className="flex justify-start h-32 items-center font-medium">
                                <div className="leading-tight truncate text-base">{startDateMoment?.format("HH:mm")}</div>
                            </div>
                        </>
                    )}
                </Col>
                <Col span={4}>
                    <div>
                        {t(`label:per_diem.${!isTransfer ? "end_date" : "transfer_date"}`)} {isTransfer && <HoverableInfo message={t("info:per_diem.transfer_date")} />}
                    </div>
                    <div className="flex justify-start h-32 items-center font-medium">
                        <div className="leading-tight truncate text-base">{endDateMoment?.format("DD/MM/YYYY")}</div>
                    </div>
                </Col>
                <Col span={4}>
                    <div>
                        {t("label:per_diem.time")} {isTransfer && <HoverableInfo message={t("info:per_diem.transfer_time")} />}
                    </div>
                    <div className="flex justify-start h-32 items-center font-medium">
                        <div className="leading-tight truncate text-base">{endDateMoment?.format("HH:mm")}</div>
                    </div>
                </Col>
                {isTransfer && (
                    <Col span={24}>
                        <Divider className="mt-6 mb-2" />
                    </Col>
                )}
            </Row>
        )
    }

    const expandIcon = (props: any) => {
        const icon = props.isActive ? <UpCircleIcon /> : <DownCircleIcon />
        return <div>{icon}</div>
    }

    return (
        <>
            <Row className="px-16">
                {showHeader && (
                    <>
                        <Col span={24}>
                            <ExpenseHeader expense={expense} />
                        </Col>
                        <Divider className="mt-16 mb-20" />
                    </>
                )}
                <Col span={24}>
                    <Row>
                        <p className="text-sm sm:text-base leading-tight h-auto w-full whitespace-pre-wrap break-word-wrap mt-4">
                            {expense?.description ? parseHtml(expense.description) : t("label:n/a")}
                        </p>
                    </Row>
                </Col>
            </Row>
            <Row>
                <div className="w-full p-16">{renderDestinationRow(firstDestination, 0)}</div>
                {restDestinations.length > 0 && (
                    <Collapse className="w-full py-0 remove-collapse-padding" bordered={false} expandIcon={expandIcon} ghost={true}>
                        <Collapse.Panel className="py-0" header={<div className="ml-10">{t("label:per_diem.additional_destinations")}</div>} key={`perdiem-collapse`}>
                            {restDestinations.map((d, i) => renderDestinationRow(d, i + 1))}
                        </Collapse.Panel>
                    </Collapse>
                )}
            </Row>
        </>
    )
}
