import { Col, Form, Input } from "antd"
import React, { Dispatch, SetStateAction } from "react"

import { blurInputOnWeel } from "Shared/utils/helper.utils"

import { FormActiveElementInterface, FormCategoryEnum, FormInputElementInterface } from "./form.types"

interface FormInputInterface {
    elem: FormInputElementInterface
    span?: number
    md?: number | object
    sm?: number | object
    activeCategory?: FormActiveElementInterface
    setActiveCategory?: Dispatch<SetStateAction<FormActiveElementInterface>>
    category?: FormCategoryEnum
}

export default function FormInputElem({ activeCategory, setActiveCategory, category, elem, ...columnProps }: FormInputInterface) {
    const { label, name, initialValue, rules, placeholder, onChange, onBlur, key, inputType = "text", isDisabled = false } = elem
    const isFilterActive = category && activeCategory && activeCategory[category]
    return (
        <Col {...columnProps}>
            <Form.Item
                label={label}
                name={name}
                key={key || name}
                initialValue={initialValue}
                rules={rules}
                style={{ marginBottom: "0px" }}
                className={isFilterActive ? "filter--active" : ""}
            >
                <Input
                    name={name}
                    type={inputType}
                    placeholder={placeholder}
                    onChange={(e) => {
                        if (category && setActiveCategory) setActiveCategory({ ...activeCategory, [category]: !!e.target.value })
                        if (onChange) onChange(e)
                    }}
                    onBlur={onBlur}
                    onWheel={blurInputOnWeel}
                    disabled={isDisabled}
                />
            </Form.Item>
        </Col>
    )
}
