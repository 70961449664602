import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons"
import { Button, Col, Input, Row } from "antd"
import Form, { FormInstance } from "antd/lib/form"
import FormItem from "antd/lib/form/FormItem"
import { Store } from "antd/lib/form/interface"
import React from "react"
import { useTranslation } from "react-i18next"

import { CorporateService, NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

interface WeavrEmailVerificationFormInterface {
    formInstance: FormInstance
    onSubmit?: () => void
    setIsLoading: (flag: boolean) => void
}

const WeavrEmailVerificationForm = ({ formInstance, onSubmit, setIsLoading }: WeavrEmailVerificationFormInterface) => {
    const { t } = useTranslation()
    const [isResending, setIsResending] = useStateIfMounted(false)

    const onFinish = (values: Store) => {
        setIsLoading(true)
        CorporateService.verifyEmail(values.verificationCode)
            .then(() => {
                NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:corporate.verify_email.title"), t("notification:corporate.verify_email.message"))
                formInstance.resetFields()
                onSubmit?.()
            })
            .catch((err) => NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:corporate.verify_email.title"), t("error:corporate.verify_email.message")))
            .finally(() => setIsLoading(false))
    }

    const onRequestVerification = () => {
        setIsResending(true)
        CorporateService.requestEmailVerificationCode()
            .then(() =>
                NotificationService.send(
                    NotificationTypeEnum.SUCCESS,
                    t("notification:corporate.email_verification_code.title"),
                    t("notification:corporate.email_verification_code.message"),
                ),
            )
            .catch((err) =>
                NotificationService.showErrorNotificationBasedOnResponseError(
                    err,
                    t("error:corporate.email_verification_code.title"),
                    t("error:corporate.email_verification_code.message"),
                ),
            )
            .finally(() => setIsResending(false))
    }

    return (
        <div className="flex items-center mt-20 ml-10">
            <Form form={formInstance} onFinish={onFinish} layout="vertical" className="mx-auto w-full">
                <Row gutter={[16, 0]}>
                    <Col span={12}>
                        <FormItem
                            name="verificationCode"
                            label={t("corporate:verification.code")}
                            required
                            rules={[{ required: true, message: t("validation:required"), validateTrigger: "blur" }]}
                        >
                            <Input.Password placeholder={t("corporate:verification.code")} iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
                        </FormItem>
                    </Col>
                </Row>
                <Button loading={isResending} onClick={onRequestVerification}>
                    {t("corporate:verification.resend_code")}
                </Button>
            </Form>
        </div>
    )
}

export default React.memo(WeavrEmailVerificationForm)
