import { Activity, ActivityFilterEnum, CollectionNameEnum } from "@finway-group/shared/lib/models"
import { UseInfiniteQueryResult, useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query"
import moment from "moment"

import { queryClient } from "Shared/config/reactQueryConfig"
import { queryGet, queryPut } from "Shared/utils/reactQuery.utils"

type UnseenActivityData = {
    totalUnseen: number
}

type ActivityData = {
    docs: Array<Activity>
    page: number
    hasNextPage: boolean
}

const MODEL = "activity"

enum CacheEnum {
    NOTIFICATIONS = "NOTIFICATIONS",
    NOTIFICATIONS_COUNT = "NOTIFICATIONS_COUNT",
}

export const invalidateNotifications = () => {
    queryClient.invalidateQueries({
        queryKey: [CacheEnum.NOTIFICATIONS],
    })
    queryClient.invalidateQueries({
        queryKey: [CacheEnum.NOTIFICATIONS_COUNT],
    })
}

export const useNotifications = ({
    limit = 10,
    loggedInEmployeeId,
    filter,
    enabled,
}: {
    limit?: number
    loggedInEmployeeId: string
    filter: ActivityFilterEnum
    enabled: boolean
}) =>
    useInfiniteQuery({
        queryKey: [CacheEnum.NOTIFICATIONS, filter],
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        enabled,
        queryFn: ({ signal, pageParam = 1 }) =>
            queryGet<ActivityData>(`/${MODEL}?page=${pageParam}&limit=${limit}${buildNotificationsQueryParams(filter, loggedInEmployeeId)}&sortBy=desc(createdAt)`, {
                signal,
            }).then((response) => ({
                docs: response?.docs.map((element) => new Activity(element)) ?? [],
                page: response?.page ?? 1,
                hasNextPage: response?.hasNextPage ?? false,
            })),
        getNextPageParam: (lastPage) => (lastPage.hasNextPage ? lastPage.page + 1 : undefined),
    })

export const useNotificationUnseenCount = () =>
    useQuery({
        queryKey: [CacheEnum.NOTIFICATIONS_COUNT],
        refetchOnWindowFocus: false,
        queryFn: ({ signal }) => queryGet<UnseenActivityData>(`${MODEL}/unseen/count`, { signal }),
    })

export const useMarkOneNotificationAsViewed = () =>
    useMutation({
        mutationFn: ({ activity, viewedBy }: { activity: Activity; viewedBy: string }) => {
            if (activity.viewedBy.includes(viewedBy)) return Promise.resolve(activity)
            const data = new Activity(activity) // Create new object to prevent cache mutation
            data.viewedBy.push(viewedBy)
            return queryPut(`${MODEL}/${data.id}`, data).then((response) => new Activity(response))
        },
        onMutate: async ({ activity, viewedBy }) => {
            if (activity.viewedBy.includes(viewedBy)) return

            // Decrease count
            await queryClient.cancelQueries({ queryKey: [CacheEnum.NOTIFICATIONS_COUNT] })
            const oldNotificationCount = queryClient.getQueryData<UnseenActivityData>([CacheEnum.NOTIFICATIONS_COUNT])
            queryClient.setQueryData<UnseenActivityData>([CacheEnum.NOTIFICATIONS_COUNT], (input) => ({
                totalUnseen: input?.totalUnseen ? input.totalUnseen - 1 : 0,
            }))

            // Remove from unread list
            await queryClient.cancelQueries({ queryKey: [CacheEnum.NOTIFICATIONS, ActivityFilterEnum.UNREAD] })
            const oldNotificationsUnread = queryClient.getQueryData<{ pages: Array<ActivityData> }>([CacheEnum.NOTIFICATIONS, ActivityFilterEnum.UNREAD])
            queryClient.setQueryData<{ pages: Array<ActivityData> }>([CacheEnum.NOTIFICATIONS, ActivityFilterEnum.UNREAD], (input) => {
                if (!input) return input
                return {
                    ...input,
                    // remove element marked as viewed
                    pages: input.pages.map((page) => ({ ...page, docs: page.docs.filter((element) => element.id !== activity.id) })),
                }
            })

            return { oldNotificationCount, oldNotificationsUnread }
        },
        onError: (_error, _newData, context) => {
            queryClient.setQueryData<UnseenActivityData>([CacheEnum.NOTIFICATIONS_COUNT], context?.oldNotificationCount)
            queryClient.setQueryData<{ pages: Array<ActivityData> }>([CacheEnum.NOTIFICATIONS, ActivityFilterEnum.UNREAD], context?.oldNotificationsUnread)
        },
    })

export const useMarkAllNotificationsAsViewed = () =>
    useMutation({
        mutationFn: () => queryPut(`${MODEL}/viewed/markAllAsViewed`),
        onMutate: async () => {
            await queryClient.cancelQueries({ queryKey: [CacheEnum.NOTIFICATIONS_COUNT] })

            const oldNotificationCount = queryClient.getQueryData<UnseenActivityData>([CacheEnum.NOTIFICATIONS_COUNT])

            queryClient.setQueryData<UnseenActivityData>([CacheEnum.NOTIFICATIONS_COUNT], {
                totalUnseen: 0,
            })

            return { oldNotificationCount }
        },
        onError: (_error, _newData, context) => {
            queryClient.setQueryData<UnseenActivityData>([CacheEnum.NOTIFICATIONS_COUNT], context?.oldNotificationCount)
        },
    })

// QUERY UTILS

const buildNotificationsQueryParams = (activityFilter: ActivityFilterEnum, loggedInEmployeeId?: string | undefined) => {
    const nonNotificationFilter = `&isNotification[is]=true`

    const collectionNamesFilter = [CollectionNameEnum.CURRENCY_EXCHANGE, CollectionNameEnum.COMPANY_SETTINGS].join(",")
    const externalActivityFilter = `&collectionName[nin]=${collectionNamesFilter}`

    const systemActivityFilter = `&isSystemActivityFilter[is]=true`

    const queryParams = `${nonNotificationFilter}${externalActivityFilter}${systemActivityFilter}`

    if (activityFilter === ActivityFilterEnum.UNREAD && loggedInEmployeeId) {
        return `${queryParams}${`&viewedBy[ne]=${loggedInEmployeeId}`}`
    }

    return queryParams
}

const isCreatedToday = ({ created }: Pick<Activity, "created">) => moment(created).isSame(moment(), "day")
const isNotCreatedToday = ({ created }: Pick<Activity, "created">) => !isCreatedToday({ created })

export const formatNotificationData = (notificationsResult: UseInfiniteQueryResult<ActivityData, unknown>) => {
    const allNotifications = notificationsResult.data?.pages.map((page) => page.docs).flat() ?? []
    const newNotifications = allNotifications.filter(isCreatedToday)
    const oldNotifications = allNotifications.filter(isNotCreatedToday)

    return { allNotifications, newNotifications, oldNotifications }
}
