import { Alert, Col } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"

import { MAX_PER_DIEM_DESTINATION_MONTHS, MIN_PER_DIEM_HOURS } from "Shared/config/consts"

type PerDiemRangeAlertType = {
    index: number
    aboveRange: Record<string, boolean>
    belowRange: boolean
}

const PerDiemRangeAlert = ({ aboveRange, index, belowRange }: PerDiemRangeAlertType) => {
    const { t } = useTranslation()

    // This is necessary to counter a part of the 24px bottom margin on AntD Form.Items
    const rangeAlertStyles = { top: "-12px" }

    if (!aboveRange[index] && !belowRange) return null

    return (
        <Col span={24}>
            <Alert
                message={
                    belowRange
                        ? t("validation:per_diem.hoursMinimum", { count: MIN_PER_DIEM_HOURS })
                        : t("validation:per_diem.months_maximum", { months: MAX_PER_DIEM_DESTINATION_MONTHS })
                }
                type="warning"
                closable={false}
                showIcon
                style={rangeAlertStyles}
            />
        </Col>
    )
}

export default PerDiemRangeAlert
