import { RightEnum, TabTypeEnum } from "@finway-group/shared/lib/models"
import { Button, Tooltip } from "antd"
import React, { useState } from "react"
import { Archive as ArchiveIcon, Check as CheckedIcon, Download as DownloadIcon } from "react-feather"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { DebouncedState } from "use-debounce/lib/useDebouncedCallback"

import { ConditionalWrapper } from "Components/conditionalWrapper"
import ExportDropDownMenu from "Components/exportDropDownMenu"
import Filter from "Components/filter/filter"
import { FilterSectionOptionsInterface } from "Components/filter/filterTypes"
import QuickFilter from "Components/filter/quickFilter"
import ExportModal from "Components/modals/export.modal"
import SearchInput from "Components/searchInput"
import { ExpenseExport } from "Features/export/expenseExport"
import { useSelectedExpenses } from "Shared/context/selectedExpenses.context"
import { markExpenseAsReviewed, markMultipleExpensesAsReviewed, useActiveTab } from "Shared/hooks/expense.hooks"
import { useTableFilterObject } from "Shared/hooks/table.hooks"
import { AuthzService, NotificationService } from "Shared/services"
import DialogService from "Shared/services/dialog.service"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { archiveMultipleExpenses } from "Shared/store/actions/expense/expenseActions"
import { TablesEnum } from "Shared/store/reducers/tableConfigReducer"
import { getTooltipPopupContainer, isNotSet } from "Shared/utils/helper.utils"

interface ExpenseActionInterface {
    state: {
        enableToDoRequestCreation: boolean
        enableToDoExport: boolean
        enableReviewed: boolean
        enablePayExport: boolean
    }
    table: TablesEnum
    dataCount: number
    searchString?: string
    options?: FilterSectionOptionsInterface
    onSearch: DebouncedState<(searchValue: any) => void>
    onFilter: (filterFormData: any) => Promise<void>
}

const ExpenseActionButton: React.FC<ExpenseActionInterface> = ({ state, table, dataCount, options = {}, searchString = "", onSearch, onFilter }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const [selectedExpenses, setSelectedExpenses] = useSelectedExpenses()
    const activeTab = useActiveTab()

    const [isExportModalShowing, setIsExportModalShowing] = useState<boolean>(false)
    const [isFilterModalShowing, setIsFilterModalShowing] = useState<boolean>(false)
    const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false)

    const resetSelection = () => setSelectedExpenses([])

    const archiveFilterObject = useTableFilterObject(TablesEnum.ARCHIVE)
    const showFilterAndSearch = activeTab !== TabTypeEnum.ARCHIVE || (!isNotSet(archiveFilterObject.maxDate) && !isNotSet(archiveFilterObject.minDate))
    const canArchive = AuthzService.isRightGrantedForLoggedInUser(RightEnum.EXPENSE__ALL__ARCHIVE) || AuthzService.isRightGrantedForLoggedInUser(RightEnum.EXPENSE__TEAM__ARCHIVE)
    const hasReviewRight = AuthzService.canLoggedInUserReviewExpenses()
    const hasExportRight = AuthzService.canLoggedInUserExport()

    const onReset = () => {
        setIsButtonLoading(false)
        resetSelection()
    }

    const onMarkAsReviewed = async () => {
        setIsButtonLoading(true)
        if (selectedExpenses.length === 1) {
            markExpenseAsReviewed(selectedExpenses[0]).finally(() => onReset())
        } else {
            markMultipleExpensesAsReviewed(selectedExpenses).finally(() => onReset())
        }
    }

    const handleArchive = async () => {
        const isConfirmed = await DialogService.confirmRequestArchive(true)
        if (!isConfirmed) {
            onReset()
            return
        }

        archiveMultipleExpenses(selectedExpenses.map((expense) => expense.id))(dispatch)
            .then(() => NotificationService.send(NotificationTypeEnum.SUCCESS, t(`notification:request.archived.title`), t(`notification:request.archived.message`)))
            .catch((err) => {
                NotificationService.showErrorNotificationBasedOnResponseError(err, t(`error:request.archived.title`))
            })

        onReset()
    }

    const getActionButtons = () => {
        switch (activeTab) {
            case TabTypeEnum.REVIEW:
                return (
                    <>
                        <ConditionalWrapper
                            condition={!state.enablePayExport}
                            wrapper={(children: any) => (
                                <Tooltip
                                    getPopupContainer={getTooltipPopupContainer}
                                    // prettier-ignore
                                    title={<div>{t('tooltips:export_button_tabs.select_at_least_one')}</div>}
                                    placement="bottom"
                                >
                                    {children}
                                </Tooltip>
                            )}
                        >
                            <ExpenseExport
                                render={({ onExport }) => (
                                    <ExportDropDownMenu
                                        onExport={onExport}
                                        isAuditTrailVisible={selectedExpenses?.length === 1}
                                        expenses={selectedExpenses}
                                        disabled={!state.enableReviewed}
                                        onlyFileExport={!hasExportRight}
                                    />
                                )}
                                onFinish={resetSelection}
                            />
                        </ConditionalWrapper>
                        {hasReviewRight && (
                            <Button disabled={!state.enableReviewed} onClick={() => onMarkAsReviewed()} loading={isButtonLoading} type="primary">
                                <CheckedIcon />
                                <span>{t("action:mark_as_reviewed")}</span>
                            </Button>
                        )}
                    </>
                )
            case TabTypeEnum.DONE:
                return (
                    <>
                        {canArchive && (
                            <Button
                                className="btn-default"
                                disabled={!selectedExpenses.length}
                                onClick={async () => {
                                    setIsButtonLoading(true)
                                    handleArchive()
                                }}
                                loading={isButtonLoading}
                            >
                                <ArchiveIcon />
                                <span>{t("action:archive.do")}</span>
                            </Button>
                        )}

                        <ExpenseExport
                            onFinish={resetSelection}
                            render={({ onExport }) => (
                                <ExportDropDownMenu
                                    text={t("action:bulk_export")}
                                    expenses={selectedExpenses}
                                    onExport={onExport}
                                    includeSepa
                                    noIcon
                                    disabled={selectedExpenses.length === 0}
                                    buttonClassName="w-140"
                                    disabledTooltip={t("tooltips:expenses.no_expense_selected")}
                                    primaryButton
                                    onlyFileExport={!hasExportRight}
                                    isAuditTrailVisible={selectedExpenses?.length === 1}
                                />
                            )}
                        />
                    </>
                )
            case TabTypeEnum.ALL:
                return (
                    <>
                        {canArchive && (
                            <Button
                                className="btn-default"
                                disabled={!selectedExpenses.length}
                                onClick={async () => {
                                    setIsButtonLoading(true)
                                    handleArchive()
                                }}
                                loading={isButtonLoading}
                            >
                                <ArchiveIcon />
                                <span>{t("action:archive.do")}</span>
                            </Button>
                        )}
                        <Button type="primary" disabled={!state.enableToDoRequestCreation} onClick={() => setIsExportModalShowing(true)}>
                            <DownloadIcon />
                            <span>{t("action:export")}</span>
                        </Button>
                    </>
                )
            case TabTypeEnum.IN_PROGRESS:
            case TabTypeEnum.INBOX_INVOICES:
            case TabTypeEnum.PAY_EXPORT:
            case TabTypeEnum.ARCHIVE:
            case TabTypeEnum.TO_DOS:
            default:
                return <></>
        }
    }

    return (
        <div className="flex btn-wrapper">
            <ExportModal isShowing={isExportModalShowing} onCancel={setIsExportModalShowing} />
            {activeTab !== TabTypeEnum.INBOX_INVOICES && showFilterAndSearch && (
                <>
                    <SearchInput onSearch={(value) => onSearch.callback(value)} value={searchString} />
                    <QuickFilter table={table} />

                    <Filter table={table} isFilterModalShowing={isFilterModalShowing} setIsFilterModalShowing={setIsFilterModalShowing} onFilter={onFilter} options={options} />
                </>
            )}
            {getActionButtons()}
        </div>
    )
}

export default ExpenseActionButton
