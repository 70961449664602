import Icon from "@ant-design/icons"
import React from "react"

const cubeSvg = () => (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M13.7174 2.66025L7.18411 0.0352478C7.06563 -0.0117493 6.93438 -0.0117493 6.81589 0.0352478L0.282552 2.66025C0.111198 2.72903 0 2.88712 0 3.06229V10.4998C0 10.6656 0.10026 10.8168 0.257943 10.8911L6.79128 13.9536C6.8569 13.9844 6.92891 13.9998 7 13.9998C7.07109 13.9998 7.1431 13.9844 7.20872 13.9536L13.7421 10.8911C13.8997 10.8168 14 10.6656 14 10.4998V3.06229C14 2.88712 13.8888 2.72903 13.7174 2.66025ZM7 0.913239L12.3484 3.06229L10.032 3.99304L4.88149 1.76447L7 0.913239ZM3.72604 2.22873L8.87652 4.45735L7 5.21133L1.65156 3.06229L3.72604 2.22873ZM0.933333 3.7258L6.53333 5.97578V12.8543L0.933333 10.2293V3.7258ZM7.46667 12.8543V5.97578L13.0667 3.7258V10.2293L7.46667 12.8543Z"
            fill="#231C5B"
        />
    </svg>
)

const CubeIcon = (props: any) => <Icon component={cubeSvg} {...props} />

export default CubeIcon
