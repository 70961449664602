import { EllipsisOutlined, ExclamationCircleOutlined } from "@ant-design/icons"
import { Employee, IntegrationTypeEnum, RightEnum, SystemRightEnum } from "@finway-group/shared/lib/models"
import { Alert, Button, Col, Dropdown, Menu, Modal, Row, Spin, Tag, Tooltip } from "antd"
import moment from "moment-timezone"
import React, { Suspense, useEffect, useMemo } from "react"
import { Edit as EditIcon, RotateCcw as ResetIcon, Trash2 as TrashIcon } from "react-feather"
import { Helmet } from "react-helmet"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useHistory, useParams } from "react-router-dom"

import PageCustomHeader from "Components/PageCustomHeader"
import UserImage from "Components/UserImage"
import GroupedExpenses from "Components/groupedExpenses"
import Icon from "Components/icon"
import Loading from "Components/loading"
import { DeleteOrDemoteEmployeeModal } from "Components/modals/deleteEmployee.modal"
import { EmployeeModal } from "Components/modals/employee.modal"
import EmployeeAbsenceModal from "Components/modals/employeeAbsence.modal"
import PersonioTag from "Components/personioTag"
import SystemAdminTag from "Components/systemAdminTag"
import { useModal } from "Shared/context/modal.context"
import { useCompany } from "Shared/hooks/company.hooks"
import { useAreCardsActivated } from "Shared/hooks/corporate.hooks"
import { useEmployee, useEmployees } from "Shared/hooks/employee.hooks"
import { useIsFreeVersion } from "Shared/hooks/featureFlags.hooks"
import { useIntegrationConfigurations } from "Shared/queries/integration.queries"
import { AuthzService, EmployeeService, NotificationService, UserService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { ThunkDispatchResult } from "Shared/store"
import { fetchOneEmployee, syncEmployee, syncEmployeeWithEmployeeInfo } from "Shared/store/actions/employee/employeeActions"
import { fetchAllWorkflows } from "Shared/store/actions/workflow/workflowActions"
import { formatEmployeeAbsenceDate } from "Shared/utils/employee.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

import EmployeeTabView from "./employeeTabView"

const { confirm: confirmAction } = Modal

const EmployeePublicProfile = React.lazy(() => import("./employeePublicProfile"))

export const EmployeeDetail: React.FC<{}> = () => {
    const { t } = useTranslation()
    const { id } = useParams<{ id: string }>()
    const history = useHistory()
    const dispatch: ThunkDispatchResult = useDispatch()
    const { showModal } = useModal()
    const isFreeVersion = useIsFreeVersion()
    const { allowAllToUpdateAbsences, _id: companyId } = useCompany()

    const employees = useEmployees({ includeAutoApprover: true })
    const employee = useEmployee()
    const company = useCompany()
    const loggedInUser = UserService.getLoggedInEmployeeProfile()
    const { globalCurrency } = loggedInUser.settings

    const isMe = employee?.id === loggedInUser.id

    // Rights
    const integrations = useIntegrationConfigurations()
    const personioIntegration = integrations?.data?.[IntegrationTypeEnum.PERSONIO]
    const isPersonioUser = Boolean(employee?.source?.id && employee?.source?.system === IntegrationTypeEnum.PERSONIO)
    const isPersonioIntegrationEnabled = personioIntegration?.isEnabled ?? false
    const isPersonioAbsenceSyncEnabled = personioIntegration?.attributes?.enableAbsenceSync ?? false
    const isAbsenceBeingSynced = isPersonioUser && isPersonioIntegrationEnabled && isPersonioAbsenceSyncEnabled
    const isUserFromSameTeam = employee.activeCompanyProfile?.team === loggedInUser.activeCompanyProfile.team
    const canViewEmployeeDetails = AuthzService.canViewEmployeeDetails(employee)
    const canUpdateEmployeeDetails = AuthzService.canUpdateEmployeeDetails(employee)
    const canResetTfaForEmployee = AuthzService.canResetTfaForEmployee(employee)
    const canDeleteEmployee = AuthzService.isSystemRightGrantedForLoggedInUser(SystemRightEnum.SYS__USER__ALL__DELETE) && !isMe
    const isOptionsDropDownVisible = canUpdateEmployeeDetails || canResetTfaForEmployee || canDeleteEmployee
    const canUpdateAbsences = !isFreeVersion && (canUpdateEmployeeDetails || (allowAllToUpdateAbsences && isMe))
    const canViewEmployeeExpenses =
        AuthzService.isRightGrantedForLoggedInUser(RightEnum.EXPENSE__ALL__READ) ||
        (AuthzService.isRightGrantedForLoggedInUser(RightEnum.EXPENSE__TEAM__READ) && isUserFromSameTeam && !AuthzService.isGlobalApprover(employee.activeCompanyProfile?.roleId))

    const cardsActive = useAreCardsActivated()

    const [showDeleteEmployeeModal, setShowDeleteEmployeeModal] = useStateIfMounted<boolean>(false)

    const [isEmployeeAbsenceModalShowing, setIsEmployeeAbsenceModalShowing] = useStateIfMounted(false)

    const approvalList = useMemo(
        () =>
            (employee?.activeCompanyProfile?.approvals || []).map((approval) => ({
                ...approval,
                approver: employees.find((employee) => employee.id === approval.approver) || new Employee(),
            })),
        [employee],
    )

    useEffect(() => {
        dispatch(fetchOneEmployee(id as any)).catch((_error) => {
            history.push("/employees")
        })
    }, [id])

    const handleDelete = async () => {
        if (employee.id === company.fallbackApprover) {
            Modal.warning({
                title: t("confirm:employee.cannot_be_deleted_because_is_fallback_approver.title"),
                content: t("confirm:employee.cannot_be_deleted_because_is_fallback_approver.message"),
                okText: t("confirm:employee.cannot_be_deleted_because_is_fallback_approver.confirm"),
            })
        } else {
            if (AuthzService.isPotentialApprover(employee.activeCompanyProfile.roleId)) {
                const workflowsOfUser = employee.id ? await EmployeeService.getWorkflows(employee.id) : []
                if (workflowsOfUser.length) {
                    setShowDeleteEmployeeModal(true)
                    return
                }
            }
            setShowDeleteEmployeeModal(true)
        }
    }

    const handleEditModal = () => showModal(EmployeeModal, true, { isShowing: true, employee, isNew: false })

    const isEmployeeAbsent = employee.isInAbsenceOfLeave()
    const daysLeft = employee.inAbsenceOfLeaveFor()
    const isEmployeeInFutureAbsent = employee.absence ? moment().isBefore(employee.absence?.startDate) : false

    const handleDeleteEmployee = async (employee: Employee, workflowsReplacementApproverId: string) => {
        try {
            await UserService.deleteCompanyProfile(employee.id, company._id, workflowsReplacementApproverId)
            if (!isFreeVersion) {
                await dispatch(fetchAllWorkflows())
            }
            await dispatch(syncEmployee(employee.id))

            NotificationService.send(NotificationTypeEnum.WARNING, t("notification:employee.deleted.title"), t("notification:employee.deleted.message"))
            history.push({ pathname: "/employees" })
        } catch (err) {
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:employee.delete.title"))
        }
    }

    const handleTfaReset = () => {
        confirmAction({
            title: t("confirm:tfa.reset.title"),
            icon: <ExclamationCircleOutlined />,
            content: t("confirm:tfa.reset.message"),
            okText: t("confirm:tfa.reset.confirm"),
            cancelText: t("confirm:tfa.reset.cancel"),
            onOk: async () => {
                try {
                    const updatedEmployee = await UserService.resetTfa(employee.id)

                    dispatch(syncEmployeeWithEmployeeInfo(updatedEmployee))

                    NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:employee.reset_tfa.title"), t("notification:employee.reset_tfa.message"))
                } catch (err) {
                    NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:error"))
                }
            },
            onCancel() {},
        })
    }

    return (
        <Spin spinning={!employee}>
            <DeleteOrDemoteEmployeeModal
                isShowing={showDeleteEmployeeModal}
                onCancel={() => {
                    setShowDeleteEmployeeModal(false)
                }}
                operationType="delete"
                onSubmit={handleDeleteEmployee}
                companyId={companyId}
                user={employee}
            />
            {employee.id === id && (
                <div>
                    <Helmet>
                        <title>{`${employee.firstName} ${employee.lastName}`}</title>
                    </Helmet>
                    {employee.activeCompanyProfile.deleted && (
                        <Alert
                            className="mb-20"
                            message={t("notification:employee.already_deleted.title")}
                            type="error"
                            description={t("notification:employee.already_deleted.message")}
                            closable={false}
                            showIcon={true}
                        />
                    )}
                    {isEmployeeAbsenceModalShowing && (
                        <EmployeeAbsenceModal
                            isVisible={isEmployeeAbsenceModalShowing}
                            onCancel={() => setIsEmployeeAbsenceModalShowing(!isEmployeeAbsenceModalShowing)}
                            employee={employee}
                        />
                    )}
                    <PageCustomHeader
                        title={
                            <div className="flex max-w-200 xs:max-w-280 sm:max-w-400 md:max-w-2xl">
                                <div>
                                    <UserImage user={employee} size={55} />
                                </div>
                                <div className="flex justify-center flex-col overflow-x-auto no-scrollbar">
                                    <Row align="middle">
                                        <Col>
                                            <h1 className="mr-4">
                                                {employee.firstName} {employee.lastName}{" "}
                                            </h1>
                                        </Col>
                                        <Col>
                                            {AuthzService.hasEmployeeSystemRight(employee) && <SystemAdminTag />}
                                            {employee.settings?.security?.tfaEnabled && <Tag className="ant-tag-blue">2fa</Tag>}
                                            {employee?.source?.system === IntegrationTypeEnum.PERSONIO && employee.source?.isImported && (
                                                <Tooltip title={t("label:personio.importedTooltip")}>
                                                    <PersonioTag />
                                                </Tooltip>
                                            )}
                                        </Col>
                                    </Row>
                                    <p className="flex leading-tight overflow-x-auto no-scrollbar">{[employee.position, employee.location].filter(Boolean).join(", ")}</p>
                                </div>
                            </div>
                        }
                        actionButtons={
                            !employee.activeCompanyProfile.deleted && (
                                <>
                                    <div className="flex-wrap btn-wrapper mt-5">
                                        {isEmployeeAbsent && (
                                            <Button className="pointer-events-none bg-white" icon={<Icon className="pb-2 pr-6" color="#fff" icon="calendar_cancel" />}>
                                                {t("label:employee_absence.back_in")}{" "}
                                                <span className="font-medium">&nbsp;{t("label:employee_absence.day", { count: daysLeft })} </span>
                                            </Button>
                                        )}
                                        {isEmployeeInFutureAbsent && (
                                            <Button className="pointer-events-none bg-white" icon={<Icon className="pb-2 pr-6" color="#fff" icon="calendar_cancel" />}>
                                                {formatEmployeeAbsenceDate(employee)}
                                            </Button>
                                        )}
                                        {canUpdateAbsences && (
                                            <Tooltip title={isAbsenceBeingSynced && !integrations.isFetching ? t("action:employee_absence.tooltip") : undefined}>
                                                <Button
                                                    icon={<Icon className="pr-6" color="#fff" icon="calendar_absence" />}
                                                    onClick={() => setIsEmployeeAbsenceModalShowing(!isEmployeeAbsenceModalShowing)}
                                                    type="primary"
                                                    loading={integrations.isFetching}
                                                    disabled={isAbsenceBeingSynced}
                                                >
                                                    {t("action:employee_absence.absence")}
                                                </Button>
                                            </Tooltip>
                                        )}
                                        {isOptionsDropDownVisible && (
                                            <Dropdown
                                                className={"flex items-center"}
                                                trigger={["click"]}
                                                overlay={
                                                    <Menu className="z-20 user-dropdown" style={{ minWidth: "190px" }}>
                                                        {canUpdateEmployeeDetails && (
                                                            <Menu.Item onClick={handleEditModal} icon={<EditIcon className="w-16 h-16 mr-8" />} className="flex items-center">
                                                                {t("action:edit")}
                                                            </Menu.Item>
                                                        )}
                                                        {canDeleteEmployee && (
                                                            <Menu.Item onClick={handleDelete} icon={<TrashIcon className="w-16 h-16 mr-8" />} className="flex items-center">
                                                                {t("action:delete")}
                                                            </Menu.Item>
                                                        )}
                                                        {canResetTfaForEmployee && (
                                                            <Menu.Item onClick={handleTfaReset} icon={<ResetIcon className="w-16 h-16 mr-8" />} className="flex items-center">
                                                                {t("label:tfa.reset_for_user.button")}
                                                            </Menu.Item>
                                                        )}
                                                    </Menu>
                                                }
                                            >
                                                <Button>
                                                    <EllipsisOutlined />
                                                </Button>
                                            </Dropdown>
                                        )}
                                    </div>
                                </>
                            )
                        }
                    />
                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={14}>
                            <div id="employee-account-details-section">
                                <Suspense fallback={<Loading />}>
                                    <EmployeePublicProfile
                                        canUpdateEmployee={canUpdateEmployeeDetails}
                                        employee={employee}
                                        loggedInUser={loggedInUser}
                                        showPhoneNumber={canViewEmployeeDetails || isMe}
                                    />
                                </Suspense>
                            </div>

                            {(canViewEmployeeExpenses || isMe) && !isFreeVersion && (
                                <div className="mt-24" id="employee-expenses-section">
                                    <GroupedExpenses groupedByField="requestedBy._id" groupedById={employee.id} />
                                </div>
                            )}
                        </Col>
                        <Col xs={24} md={10}>
                            {(canViewEmployeeDetails || isMe) && !isFreeVersion && (
                                <div className="mt-24 md:mt-0" id="employee-limits-section">
                                    <EmployeeTabView globalCurrency={globalCurrency} approvals={approvalList} employee={employee} cardsActive={cardsActive} />
                                </div>
                            )}
                        </Col>
                    </Row>
                </div>
            )}
        </Spin>
    )
}

export default EmployeeDetail
