import { Currencies } from "@finway-group/shared/lib/consts"
import { BudgetPeriodEnum, CostCenter, CostCenterTypeEnum, currencyEnumArray } from "@finway-group/shared/lib/models"
import { Employee } from "@finway-group/shared/lib/models/user/employee.model"
import { Button, Col, Form, Image, Input, Radio, Row, Select } from "antd"
import { FormInstance } from "antd/lib/form"
import React from "react"
import { Plus as PlusIcon, Trash2 as TrashIcon } from "react-feather"
import { useTranslation } from "react-i18next"

import CurrencyInput from "Components/currencyInput"
import FormItemCurrency from "Components/form/formItemCurrency"
import { CostCenterFormData } from "Features/pages/costCenters/costCenterForm/costCenterFormData.interface"
import { NO_PARENT_COST_CENTER } from "Shared/config/consts"
import { useIsCostCenterGroupsEnabled } from "Shared/hooks/featureFlags.hooks"
import { getBudgetPeriodString, translateDateString } from "Shared/utils/costCenter.utils"
import { doFilterSelect } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"
import { getPopupAnchor } from "Shared/utils/popup.utils"

import { CostCenterFormUsersSelects } from "./CostCenterFormUsersSelect"
import { costCenterRules } from "./rules/costCenter.rules"

interface CostCenterFormInterface {
    formInstance: FormInstance
    approvers: Array<Employee>
    employees: Array<Employee>
    costCenterGroups: Array<CostCenter>
    costCenterType: CostCenterTypeEnum
    isNew: boolean
    budgetPeriod?: BudgetPeriodEnum
    periodNames: Array<string>
    onSubmit: (costCenterFormData: CostCenterFormData) => any
    onPeriodSelect: (value: any) => void
    onCostCenterTypeSelect: (costCenterType: CostCenterTypeEnum) => any
    onFormValuesChange: () => void
}

// TODO: Move the following properties inside the form: costCenterGroups, costCenterType, budgetPeriod, budgetNames (MR 849)
export const CostCenterForm: React.FC<CostCenterFormInterface> = ({
    formInstance,
    approvers,
    employees,
    costCenterGroups,
    costCenterType,
    isNew,
    budgetPeriod,
    periodNames,
    onSubmit,
    onPeriodSelect,
    onCostCenterTypeSelect,
    onFormValuesChange,
}) => {
    const { t } = useTranslation()
    const rules = costCenterRules()
    const selectedBudgetPeriod = formInstance.getFieldValue("budgetPeriod")
    const [selectedBudgetCurrency, setSelectedBudgetCurrency] = useStateIfMounted(formInstance.getFieldValue("budgetCurrency"))
    const [dropDownOptions, setDropDownOptions] = useStateIfMounted(periodNames)
    const isCostCenterGroupsEnabled = useIsCostCenterGroupsEnabled()

    // for filtering out already selected options in the budget list -- antd "filterOption" is used when searching by typing.
    const getFilteredDropdownOptions = (optionIndex: number) => {
        const budgetsList = formInstance.getFieldValue("budgetsList")
        const seletedPeriodName = budgetsList[optionIndex]?.periodName
        const selectedPeriodNames = budgetsList.map((budget: any) => budget?.periodName)
        const filteredDropdownOptions = periodNames.filter((e) => e === seletedPeriodName || !selectedPeriodNames.includes(e))
        setDropDownOptions(filteredDropdownOptions)
    }

    return (
        <div>
            <Form data-testid="costCenterForm" form={formInstance} layout="vertical" onFinish={onSubmit} autoComplete="off" onValuesChange={onFormValuesChange}>
                {isCostCenterGroupsEnabled && isNew && (
                    <Form.Item name="costCenterType">
                        <div className="card-modal">
                            <Radio.Group defaultValue={costCenterType} onChange={(e) => onCostCenterTypeSelect(e.target.value)}>
                                <Radio.Button value={CostCenterTypeEnum.COST_CENTER_GROUP}>{t("label:cost_center_group")}</Radio.Button>
                                <Radio.Button value={CostCenterTypeEnum.SUB_COST_CENTER}>{t("label:cost_center")}</Radio.Button>
                            </Radio.Group>
                        </div>
                    </Form.Item>
                )}
                <Form.Item name="name" label={t("input:cost_center.name")} rules={rules.name} required>
                    <Input name="name" maxLength={100} placeholder={t("placeholder:cost_center.name")} />
                </Form.Item>
                {costCenterType === CostCenterTypeEnum.SUB_COST_CENTER && (
                    <>
                        <Form.Item name="id" label={t("input:cost_center.id")} rules={rules.id} required>
                            <Input name="id" placeholder={t("placeholder:cost_center.id")} />
                        </Form.Item>
                        {isCostCenterGroupsEnabled && (
                            <Form.Item name="parentCostCenter" label={t("label:parent_cost_center")}>
                                <Select showSearch filterOption={doFilterSelect} getPopupContainer={getPopupAnchor()}>
                                    {costCenterGroups.map(({ _id, name }, index) => (
                                        <Select.Option key={index} value={_id} label={name} id={`costcenter-option-${index}`}>
                                            {name}
                                        </Select.Option>
                                    ))}
                                    {!isNew && <Select.Option value={NO_PARENT_COST_CENTER}>-</Select.Option>}
                                </Select>
                            </Form.Item>
                        )}
                    </>
                )}

                <CostCenterFormUsersSelects approvers={approvers} employees={employees} formInstance={formInstance} />

                <Form.Item className="gap-0" name="budgetPeriod" label={t("input:cost_center.budget_period")}>
                    <Radio.Group className="flex flex-wrap" onChange={(e) => onPeriodSelect(e.target.value)}>
                        <Radio.Button className="flex-grow text-xs sm:text-base text-center" value={BudgetPeriodEnum.MONTHLY}>
                            {t("label:monthly")}
                        </Radio.Button>
                        <Radio.Button className="flex-grow text-xs sm:text-base text-center" value={BudgetPeriodEnum.QUARTERLY}>
                            {t("label:quarterly")}
                        </Radio.Button>
                        <Radio.Button className="flex-grow text-xs sm:text-base text-center" value={BudgetPeriodEnum.YEARLY}>
                            {t("label:yearly")}
                        </Radio.Button>
                    </Radio.Group>
                </Form.Item>

                <Row gutter={20} align="bottom">
                    <Col span={11}>
                        <FormItemCurrency
                            currency={selectedBudgetCurrency}
                            name="budgetValue"
                            label={t("input:cost_center.default_budget_value")}
                            key="budgetValue"
                            rules={rules.budgetValue}
                        >
                            <CurrencyInput currency={selectedBudgetCurrency} value={formInstance.getFieldValue("budgetValue")} />
                        </FormItemCurrency>
                    </Col>
                    <Col span={8}>
                        <Form.Item name="budgetCurrency" label={t("input:cost_center.default_budget_currency")}>
                            <Select onChange={(value) => setSelectedBudgetCurrency(value)} showSearch filterOption={doFilterSelect} getPopupContainer={getPopupAnchor()}>
                                {currencyEnumArray.map((currency) => (
                                    <Select.Option key={currency} value={currency} label={currency}>
                                        <div className="space-x-10">
                                            <Image className="align-middle" width={20} preview={false} src={`data:image/png;base64,${Currencies[currency].flag}`} />
                                            <span className="text-text">{currency}</span>
                                        </div>
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Form.List name="budgetsList">
                    {(fields, { add, remove }) => (
                        <div>
                            {fields.map((field, index) => (
                                <Row key={field.key} gutter={[8, 8]} align="middle">
                                    <Col span={11} offset={0}>
                                        <FormItemCurrency
                                            currency={selectedBudgetCurrency}
                                            name={[field.name, "value"]}
                                            label={t("input:cost_center.alternative_budget")}
                                            rules={rules.budgetValue}
                                            required
                                        >
                                            <CurrencyInput currency={selectedBudgetCurrency} value={formInstance.getFieldValue("budgetsList")[index]?.value} />
                                        </FormItemCurrency>
                                    </Col>

                                    <Col span={10} offset={0}>
                                        <Form.Item
                                            name={[field.name, "periodName"]}
                                            label={t(`label:${getBudgetPeriodString(selectedBudgetPeriod)}`)}
                                            rules={rules.periodName}
                                            required
                                        >
                                            <Select
                                                getPopupContainer={getPopupAnchor()}
                                                onClick={() => getFilteredDropdownOptions(index)}
                                                filterOption={true}
                                                optionFilterProp={"value"}
                                            >
                                                {dropDownOptions.map((periodName: any) => (
                                                    <Select.Option key={periodName} value={periodName}>
                                                        <span>{translateDateString(periodName.substring(1), selectedBudgetPeriod ?? budgetPeriod)}</span>
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={3} className="pt-10">
                                        <Button
                                            onClick={() => {
                                                remove(field.name)
                                            }}
                                        >
                                            <TrashIcon />
                                        </Button>
                                    </Col>
                                </Row>
                            ))}
                            <Row>
                                <Button
                                    className="mb-20"
                                    type="dashed"
                                    onClick={() => {
                                        add()
                                    }}
                                    block
                                >
                                    <PlusIcon /> {t("action:cost_center.add_budget")}
                                </Button>
                            </Row>
                        </div>
                    )}
                </Form.List>
            </Form>
        </div>
    )
}

export default CostCenterForm
