import { DocumentChangeInterface, OperationTypeEnum } from "@finway-group/shared/lib/models"
import * as Sentry from "@sentry/react"

import { AuthnService } from "Shared/services"
import { refreshAuthUser } from "Shared/store/actions/auth/authActions"
import { fetchAllEmployees } from "Shared/store/actions/employee/employeeActions"
import { fetchAllRoles } from "Shared/store/actions/role/roleActions"
import { refetchTable } from "Shared/store/actions/tables/tableActions"
import { TablesEnum } from "Shared/store/reducers/tableConfigReducer"
import { isClientErrorCode } from "Shared/utils/errorHandler.utils"

const subscribeToRole = async (dispatch: any, data: DocumentChangeInterface) => {
    const {
        fullDocument: { operationType },
    } = data

    try {
        if (operationType === OperationTypeEnum.INSERT || operationType === OperationTypeEnum.UPDATE || operationType === OperationTypeEnum.DELETE) {
            dispatch(fetchAllRoles())
            await AuthnService.silentRefresh()
            await dispatch(refreshAuthUser())
        }

        if (operationType === OperationTypeEnum.DELETE) {
            // Users' roles fallback to employee role when it gets deleted
            await dispatch(fetchAllEmployees())
            dispatch(refetchTable(TablesEnum.EMPLOYEES))
        }
    } catch (err) {
        if (isClientErrorCode(err)) return
        Sentry.captureException(`[Role Socket] Error: ${typeof err === "string" ? err : JSON.stringify(err)}`)
    }
}

export default subscribeToRole
