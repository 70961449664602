import { ExclamationCircleOutlined } from "@ant-design/icons"
import { Company } from "@finway-group/shared/lib/models"
import { Tabs, Tooltip } from "antd"
import React from "react"
import { Edit as EditIcon } from "react-feather"
import { useTranslation } from "react-i18next"

import CompanySettingsModal from "Components/modals/companySettings.modal"
import SelectedCompanyPreview from "Components/selectedCompanyPreview"
import SettingsLayout, { SettingsButton } from "Features/settings/settings.layout"
import { useModal } from "Shared/context/modal.context"
import { useCompanies, useCompany } from "Shared/hooks/company.hooks"
import { AuthzService } from "Shared/services"
import { getIBANPrintFormat, getLanguageString, getTooltipPopupContainer } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

enum CompanyPreviewTabEnum {
    CONFIGS = "CONFIGS",
    FEATURES = "FEATURES",
    BUSINESS_ADDRESS = "BUSINESS_ADDRESS",
    DELIVERY_ADDRESS = "DELIVERY_ADDRESS",
}

const CompanySettings = () => {
    const { t } = useTranslation()
    const { showModal } = useModal()

    const [activeTab, setActiveTab] = useStateIfMounted(CompanyPreviewTabEnum.CONFIGS)
    const { _id, name, logo, isRoot } = useCompany()
    const company: any = useCompanies(false).find((data) => data._id === _id)
    const handleOnCompanyEdit = (selectedCompany: Company) => showModal(CompanySettingsModal, false, { company: selectedCompany, isShowing: true, isEditMode: true })

    return (
        <SettingsLayout
            titleKey=""
            titleExtra={<SelectedCompanyPreview name={name} logo={logo} isRoot={isRoot} />}
            buttons={
                <>
                    {AuthzService.hasCompanySettingsUpdateSystemRight() && (
                        <SettingsButton icon={<EditIcon />} onClick={() => handleOnCompanyEdit(company)} labelKey={company ? "action:edit" : "action:create"} />
                    )}
                </>
            }
        >
            {!company ? (
                <div>{t("missing:company_data")}</div>
            ) : (
                <Tabs defaultActiveKey={CompanyPreviewTabEnum.CONFIGS} activeKey={activeTab} className="pb-10" onTabClick={(key) => setActiveTab(key as CompanyPreviewTabEnum)}>
                    <Tabs.TabPane tab={t("label:company_settings")} key={CompanyPreviewTabEnum.CONFIGS}>
                        <div>
                            <ul className="description-list">
                                <li>
                                    <span className="title w-32">{t("label:company_identifier")}</span>
                                    <span className="text text-left ml-10 sm:ml-0">{company.companyIdentifier ? company.companyIdentifier : `-`}</span>
                                </li>
                                <li>
                                    <span className="title w-32">{t("label:iban")}</span>
                                    <span className="text text-left ml-10 sm:ml-0">{company.iban ? getIBANPrintFormat(company?.iban) : `-`}</span>
                                </li>
                                <li>
                                    <span className="title">{t("label:bic")}</span>
                                    <span className="text text-left">{company.bic ? company.bic : `-`}</span>
                                </li>
                                <li>
                                    <span className="title w-240">{t("label:default_creditor_number")}</span>
                                    <span className="text text-left">{company.defaultCreditorNumber}</span>
                                </li>
                                <li>
                                    <span className="title w-240">{t("label:default_employee_creditor_number")}</span>
                                    <span className="text text-left">{company.defaultEmployeeCreditorNumber}</span>
                                </li>
                                <li>
                                    <span className="title w-240">{t("label:timezone")}</span>
                                    <span className="text text-left">{company.timeZone}</span>
                                </li>
                                <li>
                                    <span className="title w-240">
                                        {t("label:default_employee_language")}
                                        <Tooltip
                                            getPopupContainer={getTooltipPopupContainer}
                                            title={t("tooltips:company_settings.employee_default_language")}
                                            placement="right"
                                            className="align-middle"
                                        >
                                            <ExclamationCircleOutlined className="ml-6" />
                                        </Tooltip>
                                    </span>
                                    <span className="text text-left">{getLanguageString(company.defaultEmployeeLanguage, t)}</span>
                                </li>
                            </ul>
                        </div>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={t("label:business_address")} key={CompanyPreviewTabEnum.BUSINESS_ADDRESS}>
                        <div>
                            <ul className="description-list">
                                <li>
                                    <span className="title">{t("label:company_name")}</span>
                                    <span className="text text-left">{company.name || "-"}</span>
                                </li>
                                <li>
                                    <span className="title">{t("label:address_line")}</span>
                                    <span className="text text-left">{company.businessAddress?.addressLine2 || "-"}</span>
                                </li>
                                <li>
                                    <span className="title">{t("label:city")}</span>
                                    <span className="text text-left">{company.businessAddress?.city || "-"}</span>
                                </li>
                                <li>
                                    <span className="title">{t("label:country")}</span>
                                    <span className="text text-left">{company.businessAddress?.country || "-"}</span>
                                </li>
                                <li>
                                    <span className="title">{t("label:zip")}</span>
                                    <span className="text text-left">{company.businessAddress?.zip || "-"}</span>
                                </li>
                            </ul>
                        </div>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={t("label:delivery_address")} key={CompanyPreviewTabEnum.DELIVERY_ADDRESS}>
                        <div>
                            <ul className="description-list">
                                {company.deliverySameAsBusinessAddress ? (
                                    <li>
                                        <span>{t("label:same_as_business_address")}</span>
                                    </li>
                                ) : (
                                    <>
                                        <li>
                                            <span className="title">{t("label:company_name")}</span>
                                            <span className="text text-left">{company.name || "-"}</span>
                                        </li>
                                        <li>
                                            <span className="title">{t("label:address_line")}</span>
                                            <span className="text text-left">{company.deliveryAddress?.addressLine2 || "-"}</span>
                                        </li>
                                        <li>
                                            <span className="title">{t("label:city")}</span>
                                            <span className="text text-left">{company.deliveryAddress?.city || "-"}</span>
                                        </li>
                                        <li>
                                            <span className="title">{t("label:country")}</span>
                                            <span className="text text-left">{company.deliveryAddress?.country || "-"}</span>
                                        </li>
                                        <li>
                                            <span className="title">{t("label:zip")}</span>
                                            <span className="text text-left">{company.deliveryAddress?.zip || "-"}</span>
                                        </li>
                                    </>
                                )}
                            </ul>
                        </div>
                    </Tabs.TabPane>
                </Tabs>
            )}
        </SettingsLayout>
    )
}
export default React.memo(CompanySettings)
