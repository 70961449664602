import { MAX_DATEV_ACCOUNT_NUMBER_LENGTH, MIN_DATEV_ACCOUNT_NUMBER_LENGTH } from "@finway-group/shared/lib/consts/datevConsts"
import { DatevCsvConfig } from "@finway-group/shared/lib/models"
import { Rule } from "antd/lib/form"
import i18n from "i18next"

const DatevCsvConfigRules = (): Record<keyof DatevCsvConfig, Array<Rule>> => ({
    walletAccount: [{ required: true, message: i18n.t("validation:required"), validateTrigger: "blur" }],
    cashTransitAccount: [{ required: true, message: i18n.t("validation:required"), validateTrigger: "blur" }],
    accountNumberLength: [
        {
            validator: (_rule: any, value: string) => {
                if (!value) return Promise.reject(i18n.t("validation:required"))

                if (parseInt(value) < MIN_DATEV_ACCOUNT_NUMBER_LENGTH || parseInt(value) > MAX_DATEV_ACCOUNT_NUMBER_LENGTH)
                    return Promise.reject(i18n.t("validation:numeric_min_max", { min: MIN_DATEV_ACCOUNT_NUMBER_LENGTH, max: MAX_DATEV_ACCOUNT_NUMBER_LENGTH }))

                return Promise.resolve()
            },
        },
    ],
})

export default DatevCsvConfigRules
