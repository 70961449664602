import { RightEnum } from "@finway-group/shared/lib/models"
import { Button, Tabs } from "antd"
import React, { useState } from "react"
import { Plus as PlusIcon } from "react-feather"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useDebouncedCallback } from "use-debounce/lib"

import Filter from "Components/filter/filter"
import VendorFormModal from "Components/modals/vendor.modal"
import PageTitle from "Components/page/pageTitle"
import SearchInput from "Components/searchInput"
import { useCompany } from "Shared/hooks/company.hooks"
import { applyFilter } from "Shared/hooks/filter.hooks"
import { useTable } from "Shared/hooks/table.hooks"
import { AuthzService, VendorService } from "Shared/services"
import { ThunkDispatchResult } from "Shared/store"
import { updateTableSearch } from "Shared/store/actions/tables/tableActions"
import { TablesEnum } from "Shared/store/reducers/tableConfigReducer"
import { VendorWithExpenseData } from "Shared/store/reducers/tableReducer"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

import MassExportButton from "../../../components/massExport.button"
import EmployeeVendorTable from "./employeeVendor.table"
import VendorTable from "./vendor.table"

const VendorList: React.FC<{}> = () => {
    const { t } = useTranslation()
    const dispatch: ThunkDispatchResult = useDispatch()
    const [activeTab, setActiveTab] = useStateIfMounted(TablesEnum.VENDORS)

    const activeTable = useTable<VendorWithExpenseData>(activeTab)

    const [isModalShowing, setIsModalShowing] = useState(false)
    const [isFilterModalShowing, setIsFilterModalShowing] = useState<boolean>(false)
    const { isVendorCreationRestricted } = useCompany()

    const handleSearch = useDebouncedCallback((searchString) => dispatch(updateTableSearch(activeTab, searchString)), 800)

    return (
        <div>
            <PageTitle titleKey="view:vendors.title">
                {activeTab === TablesEnum.VENDORS && (
                    <>
                        {(activeTable.data.totalDocs > 0 || activeTable.data.search) && (
                            <SearchInput onSearch={(searchString) => handleSearch.callback(searchString)} value={activeTable.data.search} />
                        )}

                        <Filter
                            table={TablesEnum.VENDORS}
                            isFilterModalShowing={isFilterModalShowing}
                            setIsFilterModalShowing={setIsFilterModalShowing}
                            onFilter={(data) => applyFilter(TablesEnum.VENDORS, data)}
                            options={{}}
                        />

                        <MassExportButton serviceExportFunction={VendorService.export} visible={AuthzService.isRightGrantedForLoggedInUser(RightEnum.VENDOR__ALL__READ)} />

                        {VendorService.shouldShowVendorButton(isVendorCreationRestricted) && (
                            <Button data-testid="createVendorButton" type="primary" onClick={() => setIsModalShowing(true)}>
                                <PlusIcon />
                                <span>{t("action:vendor:create")}</span>
                            </Button>
                        )}
                        <VendorFormModal isNew={true} isShowing={isModalShowing} onCancel={setIsModalShowing} />
                    </>
                )}
            </PageTitle>
            <Tabs
                defaultActiveKey={TablesEnum.VENDORS}
                onChange={(e: TablesEnum) => {
                    setActiveTab(e)
                }}
            >
                <Tabs.TabPane key={TablesEnum.VENDORS} tab={t("label:vendors")}>
                    <VendorTable onAddVendor={setIsModalShowing} />
                </Tabs.TabPane>
                <Tabs.TabPane key={TablesEnum.EMPLOYEE_VENDORS} tab={t("label:employees")}>
                    <EmployeeVendorTable />
                </Tabs.TabPane>
            </Tabs>
        </div>
    )
}

export default VendorList
