import Icon from "@ant-design/icons"
import React from "react"

const newInvoice = () => (
    <svg id="sideBarInvoiceRequestButton" width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="34" height="34" rx="17" fill="#F0FEFA" />
        <path
            d="M22.9019 12.4306L19.5694 9.09806C19.5091 9.03752 19.4257 9 19.3333 9H12.3333C11.5983 9 11 9.59798 11 10.3333V23.6667C11 24.402 11.5983 25 12.3333 25H21.6667C22.4017 25 23 24.402 23 23.6667V12.6667C23 12.5743 22.9625 12.4909 22.9019 12.4306ZM14 13.6667C13.4486 13.6667 13 13.2181 13 12.6667C13 12.2327 13.2795 11.8661 13.6667 11.7281V11.6667C13.6667 11.4824 13.8158 11.3333 14 11.3333C14.1842 11.3333 14.3333 11.4824 14.3333 11.6667H14.6667C14.8509 11.6667 15 11.8158 15 12C15 12.1842 14.8509 12.3333 14.6667 12.3333H14C13.8164 12.3333 13.6667 12.4827 13.6667 12.6667C13.6667 12.8506 13.8164 13 14 13C14.5514 13 15 13.4486 15 14C15 14.434 14.7205 14.8005 14.3333 14.9386V15C14.3333 15.1842 14.1842 15.3333 14 15.3333C13.8158 15.3333 13.6667 15.1842 13.6667 15H13.3333C13.1491 15 13 14.8509 13 14.6667C13 14.4824 13.1491 14.3333 13.3333 14.3333H14C14.1836 14.3333 14.3333 14.1839 14.3333 14C14.3333 13.8161 14.1836 13.6667 14 13.6667ZM17.3333 23H13.3333C13.1491 23 13 22.8509 13 22.6667C13 22.4824 13.1491 22.3333 13.3333 22.3333H17.3333C17.5176 22.3333 17.6667 22.4824 17.6667 22.6667C17.6667 22.8509 17.5176 23 17.3333 23ZM20.6667 21H13.3333C13.1491 21 13 20.8509 13 20.6667C13 20.4824 13.1491 20.3333 13.3333 20.3333H20.6667C20.8509 20.3333 21 20.4824 21 20.6667C21 20.8509 20.8509 21 20.6667 21ZM20.6667 19H13.3333C13.1491 19 13 18.8509 13 18.6667C13 18.4824 13.1491 18.3333 13.3333 18.3333H20.6667C20.8509 18.3333 21 18.4824 21 18.6667C21 18.8509 20.8509 19 20.6667 19ZM20.6667 17H13.3333C13.1491 17 13 16.8509 13 16.6667C13 16.4824 13.1491 16.3333 13.3333 16.3333H20.6667C20.8509 16.3333 21 16.4824 21 16.6667C21 16.8509 20.8509 17 20.6667 17ZM20.6667 15H16.6667C16.4824 15 16.3333 14.8509 16.3333 14.6667C16.3333 14.4824 16.4824 14.3333 16.6667 14.3333H20.6667C20.8509 14.3333 21 14.4824 21 14.6667C21 14.8509 20.8509 15 20.6667 15ZM19.6667 11.6667V10.138L21.862 12.3333H20.3333C19.9655 12.3333 19.6667 12.0342 19.6667 11.6667Z"
            fill="#60CDB2"
        />
    </svg>
)

const NewInvoiceIcon = (props: any) => <Icon component={newInvoice} {...props} />

export default NewInvoiceIcon
