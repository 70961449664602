import { LiquidityCashFlow, LiquidityForecastRule, LiquidityScenario } from "@finway-group/shared/lib/models"
import moment from "moment"
import { Reducer } from "redux"

import { LMTabTypeEnum } from "Features/pages/liquidity/liquidity.types"
import { BankBalanceInfo } from "Features/pages/liquidity/types"

import {
    CreateOneForecastRuleAction,
    CreateOneScenarioAction,
    DeleteOneForecastRuleAction,
    DeleteOneScenarioAction,
    FetchAllForecastRulesAction,
    FetchAllScenariosAction,
    FetchCashFlowsAction,
    FetchLast6MonthsCashFlowsAction,
    FetchYearlyCashFlowsAction,
    LiquidityActions,
    LiquidityTypes,
    SelectScenarioAction,
    SetBankBalanceData,
    SetLastBankBalanceDataFetch,
    SetLiquidityActiveTabAction,
    SetShouldFetchCashFlowsData,
    UpdateIntervalAction,
    UpdateOneForecastRuleAction,
    UpdateOneScenarioAction,
} from "../actions/liquidity/liquidityTypes"

export interface LiquidityState {
    scenarios: Array<LiquidityScenario>
    scenario: LiquidityScenario
    interval?: [moment.Moment, moment.Moment]
    forecastRules: Array<LiquidityForecastRule>
    activeTab: LMTabTypeEnum
    cashFlows: Array<LiquidityCashFlow>
    yearlyCashFlows: Array<LiquidityCashFlow>
    last6MonthsCashFlows: Array<LiquidityCashFlow>
    shouldFetchCashFlows: boolean
    shouldFetchYearlyCashFlows: boolean
    shouldFetchLast6MonthsCashFlows: boolean
    bankBalanceData: Array<BankBalanceInfo>
    shouldFetchBankBalanceData: boolean // Stores the date, but for redux compatibility, we use number
}

const createInitialState = (): LiquidityState => ({
    scenarios: [],
    scenario: {} as LiquidityScenario,
    interval: [moment().subtract(3, "months"), moment().add(9, "months")] as [moment.Moment, moment.Moment], // 3 months in the past and 9 upfront
    forecastRules: [],
    activeTab: LMTabTypeEnum.CASHFLOW_PLANNING,
    cashFlows: [],
    yearlyCashFlows: [],
    last6MonthsCashFlows: [],
    shouldFetchCashFlows: true,
    shouldFetchYearlyCashFlows: true,
    shouldFetchLast6MonthsCashFlows: true,
    bankBalanceData: [],
    shouldFetchBankBalanceData: true,
})

const initialState = createInitialState()

const fetchAllScenarios = (state: LiquidityState, action: FetchAllScenariosAction): LiquidityState => ({
    ...state,
    scenarios: action.scenarios,
    scenario: action.scenario,
})

const createScenario = (state: LiquidityState, action: CreateOneScenarioAction): LiquidityState => ({
    ...state,
    scenarios: [...state.scenarios, action.scenario],
    scenario: action.scenario,
    shouldFetchCashFlows: true,
    shouldFetchYearlyCashFlows: true,
})

const updateScenario = (state: LiquidityState, action: UpdateOneScenarioAction): LiquidityState => ({
    ...state,
    scenarios: state.scenarios.map((item) => {
        if (item._id === action.scenario._id) return action.scenario
        return item
    }),
    scenario: action.scenario,
})

const deleteScenario = (state: LiquidityState, action: DeleteOneScenarioAction): LiquidityState => {
    const newItems = state.scenarios.filter((item) => item._id != action.scenarioId)
    return {
        ...state,
        scenarios: newItems,
        scenario: newItems[0],
        shouldFetchCashFlows: true,
        shouldFetchYearlyCashFlows: true,
    }
}

const selectScenario = (state: LiquidityState, action: SelectScenarioAction): LiquidityState => ({
    ...state,
    scenario: action.scenario,
})

const updateInterval = (state: LiquidityState, action: UpdateIntervalAction): LiquidityState => ({
    ...state,
    interval: action.interval,
})

const fetchAllForecastRules = (state: LiquidityState, action: FetchAllForecastRulesAction): LiquidityState => ({
    ...state,
    forecastRules: action.forecastRules,
})

const createForecastRule = (state: LiquidityState, action: CreateOneForecastRuleAction): LiquidityState => ({
    ...state,
    forecastRules: [...state.forecastRules, action.forecastRule],
})

const updateForecastRule = (state: LiquidityState, action: UpdateOneForecastRuleAction): LiquidityState => ({
    ...state,
    forecastRules: state.forecastRules.map((item) => {
        if (item._id === action.forecastRule._id) return action.forecastRule
        return item
    }),
})

const deleteForecastRule = (state: LiquidityState, action: DeleteOneForecastRuleAction): LiquidityState => {
    const newItems = state.forecastRules.filter((item) => item._id != action.forecastRuleId)
    return {
        ...state,
        forecastRules: newItems,
    }
}

const setLiquidityActiveTab = (state: LiquidityState, action: SetLiquidityActiveTabAction): LiquidityState => ({
    ...state,
    activeTab: action.activeTab,
})

const fetchCashFlowData = (state: LiquidityState, action: FetchCashFlowsAction): LiquidityState => ({
    ...state,
    cashFlows: action.cashFlows,
    shouldFetchCashFlows: false,
})

const fetchYearlyCashflowData = (state: LiquidityState, action: FetchYearlyCashFlowsAction): LiquidityState => ({
    ...state,
    yearlyCashFlows: action.yearlyCashFlows,
    shouldFetchYearlyCashFlows: false,
})

const fetchLast6MonthsCashflowData = (state: LiquidityState, action: FetchLast6MonthsCashFlowsAction): LiquidityState => ({
    ...state,
    last6MonthsCashFlows: action.last6MonthsCashFlows,
    shouldFetchLast6MonthsCashFlows: false,
})

const resetLiquidities = () => createInitialState()

const setShouldFetchCashFlowsData = (state: LiquidityState, action: SetShouldFetchCashFlowsData): LiquidityState => ({
    ...state,
    shouldFetchCashFlows: action.value,
    shouldFetchLast6MonthsCashFlows: action.value,
    shouldFetchYearlyCashFlows: action.value,
})

const setShouldFetchBankBalanceData = (state: LiquidityState, action: SetLastBankBalanceDataFetch): LiquidityState => ({
    ...state,
    shouldFetchBankBalanceData: action.value,
})

const setBankBalanceData = (state: LiquidityState, action: SetBankBalanceData): LiquidityState => ({
    ...state,
    bankBalanceData: action.value,
})

export const liquidityReducer: Reducer<LiquidityState, LiquidityActions> = (state = initialState, action) => {
    switch (action.type) {
        case LiquidityTypes.FETCH_ALL_SCENARIOS:
            return fetchAllScenarios(state, action)
        case LiquidityTypes.CREATE_ONE_SCENARIO:
            return createScenario(state, action)
        case LiquidityTypes.UPDATE_ONE_SCENARIO:
            return updateScenario(state, action)
        case LiquidityTypes.DELETE_ONE_SCENARIO:
            return deleteScenario(state, action)
        case LiquidityTypes.SELECT_SCENARIO:
            return selectScenario(state, action)
        case LiquidityTypes.UPDATE_INTERVAL:
            return updateInterval(state, action)
        case LiquidityTypes.FETCH_ALL_FORECAST_RULES:
            return fetchAllForecastRules(state, action)
        case LiquidityTypes.CREATE_ONE_FORECAST_RULE:
            return createForecastRule(state, action)
        case LiquidityTypes.UPDATE_ONE_FORECAST_RULE:
            return updateForecastRule(state, action)
        case LiquidityTypes.DELETE_ONE_FORECAST_RULE:
            return deleteForecastRule(state, action)
        case LiquidityTypes.SET_LM_ACTIVE_TAB:
            return setLiquidityActiveTab(state, action)
        case LiquidityTypes.FETCH_CASH_FLOWS:
            return fetchCashFlowData(state, action)
        case LiquidityTypes.FETCH_YEARLY_CASH_FLOWS:
            return fetchYearlyCashflowData(state, action)
        case LiquidityTypes.FETCH_LAST_6_MONTHS_CASH_FLOWS:
            return fetchLast6MonthsCashflowData(state, action)
        case LiquidityTypes.RESET_LIQUIDITIES:
            return resetLiquidities()
        case LiquidityTypes.SET_SHOULD_FETCH_CASH_FLOWS_DATA:
            return setShouldFetchCashFlowsData(state, action)
        case LiquidityTypes.SET_SHOULD_FETCH_BANK_BALANCE_DATA:
            return setShouldFetchBankBalanceData(state, action)
        case LiquidityTypes.SET_BANK_BALANCE_DATA:
            return setBankBalanceData(state, action)
        default:
            return state
    }
}
