import { Button, Modal } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"

import CostCenterExpenseBudgetReport from "Features/pages/costCenters/costCenterDetails/costCenterExpenseBudgetReport"

interface ExpenseReportModalInterface {
    costCenterId: string
    costCenterName: string
    budgetUsage: any
    isVisible: boolean
    onCancel: (state: boolean) => void
}

const ExpenseBudgetReportModal: React.FC<ExpenseReportModalInterface> = ({ costCenterId, costCenterName, budgetUsage, isVisible, onCancel }) => {
    const { t } = useTranslation()

    return (
        <Modal
            visible={isVisible}
            maskClosable={false}
            title={costCenterName}
            onCancel={() => onCancel(false)}
            className="ant-modal--small"
            destroyOnClose
            closable
            footer={[
                <Button key="back" onClick={() => onCancel(false)}>
                    {t("action:cancel")}
                </Button>,
            ]}
        >
            <CostCenterExpenseBudgetReport costCenterId={costCenterId} budgetUsage={budgetUsage} />
        </Modal>
    )
}

export default ExpenseBudgetReportModal
