import { Tooltip } from "antd"
import React from "react"
import { AlertTriangle } from "react-feather"
import { useTranslation } from "react-i18next"

import { WARNING_ORANGE } from "Shared/config/consts"
import { DuplicateVendorInformationInterface } from "Shared/utils/vendor.utils"

import VendorImage from "./VendorImage"

interface VendorItemInterface {
    vendorInformation: DuplicateVendorInformationInterface
    showVendorPendingSign?: boolean
}

const VendorItem: React.FC<VendorItemInterface> = ({ vendorInformation, showVendorPendingSign = true }) => {
    const { t } = useTranslation()
    const { vendor, matchedAttributes } = vendorInformation

    return (
        <div className="flex items-start image-loader gap-10">
            <VendorImage size={"large"} vendor={vendor} />
            <div className="flex flex-col truncate">
                <div className="flex items-center pb-8">
                    <p className="font-bold leading-tight mb-0 truncate finway-dark-text">{vendor.name}</p>
                    {showVendorPendingSign && !vendor?.isApproved && (
                        <Tooltip title={t("info:pending_vendor")}>
                            <AlertTriangle fill={WARNING_ORANGE} size={18} stroke="#FFFFFF" className="ml-3" />
                        </Tooltip>
                    )}
                </div>
                {matchedAttributes.map((matchedAttribute) => (
                    <p className="text-xs text-text leading-none mb-6 truncate">
                        {t(`label:${matchedAttribute}`)}: {vendor[matchedAttribute]}
                    </p>
                ))}
            </div>
        </div>
    )
}

export default VendorItem
