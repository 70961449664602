import { CurrencyEnum, PaymentProduct, PaymentProductPrice, PaymentProductPriceTier, PriceIntervalEnum } from "@finway-group/shared/lib/models"
import { Modal, Table } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"

import PriceLabel from "Components/priceLabel"

interface PaymentCardPricesModalProps {
    cardProducts: Array<PaymentProduct>
    isShowing: boolean
    onCancel: () => void
}

interface PriceTier {
    [PriceIntervalEnum.MONTHLY]?: number
    [PriceIntervalEnum.YEARLY]?: number
    currency: CurrencyEnum
}

interface PriceData {
    value: number
    currency: CurrencyEnum
}

interface PriceTierData {
    tier: number
    m: PriceData
    y: PriceData
}

const PaymentCardPricesModal = ({ cardProducts, isShowing, onCancel }: PaymentCardPricesModalProps) => {
    const { t } = useTranslation()
    const onHide = () => onCancel()

    const mapCardProductsToTiers = () => {
        const tiers = new Map<number, PriceTier>()
        cardProducts.forEach((cardProduct: PaymentProduct) => {
            cardProduct.prices?.forEach((price: PaymentProductPrice) => {
                const interval = (price.interval as PriceIntervalEnum.MONTHLY) || PriceIntervalEnum.YEARLY
                const { currency } = price
                price.tiers.forEach((priceTier: PaymentProductPriceTier) => {
                    const upTo = priceTier.upTo ?? Number.MAX_SAFE_INTEGER
                    let tier = tiers.get(upTo)
                    if (!tier) {
                        tier = { currency }
                        tiers.set(upTo, tier)
                    }
                    tier[interval] = priceTier.unitAmount
                })
            })
        })

        return Array.from(
            tiers,
            ([tier, prices]) =>
                ({
                    tier,
                    m: {
                        value: prices[PriceIntervalEnum.MONTHLY],
                        currency: prices.currency,
                    },
                    y: {
                        value: prices[PriceIntervalEnum.YEARLY],
                        currency: prices.currency,
                    },
                } as PriceTierData),
        ).sort((tierA, tierB) => tierA.tier - tierB.tier)
    }

    const datasource = mapCardProductsToTiers()
    const maxIndex = datasource.length - 1

    const columns = [
        {
            title: t("label:payment_settings.card_prices_number"),
            dataIndex: "tier",
            render: (element: number, record: any, index: number) => {
                if (index === 0) return <>0 - {element}</>
                if (index === maxIndex)
                    return (
                        <>
                            {">"} {datasource[index - 1].tier + 1}
                        </>
                    )
                return (
                    <>
                        {datasource[index - 1].tier + 1} {"-"} {element}
                    </>
                )
            },
        },
        {
            title: t("label:payment_settings.card_prices_monthly"),
            dataIndex: "m",
            render: (element: PriceData) => <PriceLabel value={element.value / 100} currency={element.currency} />,
        },
        {
            title: t("label:payment_settings.card_prices_yearly"),
            dataIndex: "y",
            render: (element: PriceData) => <PriceLabel value={element.value / 100} currency={element.currency} />,
        },
    ]

    return (
        <Modal
            title={t("label:payment_settings.card_prices_title")}
            visible={isShowing}
            maskClosable={false}
            cancelText={t("action:cancel")}
            onCancel={onHide}
            okButtonProps={{ style: { display: "none" } }}
            destroyOnClose
        >
            <Table rowKey="tier" columns={columns} dataSource={datasource} pagination={false} />
        </Modal>
    )
}

export default PaymentCardPricesModal
