import { COLOR } from "@finway-group/shared/lib/consts"
import { LiquidityCashFlow } from "@finway-group/shared/lib/models"
import { Card } from "antd"
import moment from "moment"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Bar, BarChart, CartesianGrid, Legend, ReferenceArea, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts"

import Loading from "Components/loading"
import { getDiagramLegendString } from "Shared/utils/liquidity.utils"

import CustomTooltip from "../../supplements/customTooltip"

interface ActualVsForecastBarChartInterface {
    cashFlowData: Array<LiquidityCashFlow>
    isDiagramLoading: boolean
}

const ActualVsForecastBarChart: React.FC<ActualVsForecastBarChartInterface> = ({ cashFlowData, isDiagramLoading }) => {
    if (!cashFlowData || cashFlowData.length === 0 || isDiagramLoading) return <Loading />

    const { t } = useTranslation()

    const data = useMemo(
        () =>
            cashFlowData.map((l) => ({
                name: moment(l.period).format("MMM YY"),
                actualIncome: l.totalIncome.actual,
                actualSpending: l.totalSpending.actual,
                forecastIncome: l.totalIncome.forecast,
                forecastSpending: l.totalSpending.forecast,
            })),
        [cashFlowData],
    )

    return (
        <Card className="w-full" style={{ height: "480px" }}>
            <ResponsiveContainer width={"100%"} height={400}>
                <BarChart
                    data={data}
                    margin={{
                        top: 40,
                        right: 30,
                        left: 20,
                    }}
                    stackOffset="sign"
                >
                    <CartesianGrid vertical={false} />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip
                        itemStyle={{ color: COLOR.primary }}
                        cursor={{ fill: "transparent" }}
                        allowEscapeViewBox={{ y: true }}
                        content={(tooltipProps) => <CustomTooltip props={tooltipProps} />}
                    />

                    <Legend
                        iconType="square"
                        wrapperStyle={{ position: "relative", left: 30, top: 10 }}
                        formatter={(value) => <p className="inline-block text-text mr-10 transform translate-y-1">{getDiagramLegendString(t, value)}</p>}
                    />
                    <ReferenceArea x2={moment().format("MMM YY")} fill="#FAFCFD" />
                    <ReferenceLine x={moment().format("MMM YY")} strokeDasharray="3" position="end" />
                    <Bar dataKey="actualIncome" stackId="actual" fill="#9CECD8" stroke="#60CDB2" radius={[5, 5, 0, 0]} />
                    <Bar dataKey="actualSpending" stackId="actual" fill="#FC6C8F" stroke="#F42A68" radius={[5, 5, 0, 0]} />
                    <Bar dataKey="forecastIncome" stackId="forecast" fill="#DBFFF6" stroke="#60CDB2" radius={[5, 5, 0, 0]} />
                    <Bar dataKey="forecastSpending" stackId="forecast" fill="#FFBACB" stroke="#F42A68" radius={[5, 5, 0, 0]} />
                </BarChart>
            </ResponsiveContainer>
        </Card>
    )
}

export default ActualVsForecastBarChart
