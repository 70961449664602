import { RedoOutlined } from "@ant-design/icons"
import { Button, Card } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"

import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

interface ErrorTableInterface {
    isLoading: boolean
    onTableReload: () => void
}

export const ErrorTable: React.FC<ErrorTableInterface> = ({ isLoading, onTableReload }) => {
    const { t } = useTranslation()
    return (
        <Card bodyStyle={{ height: "130px", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <div className="animation-appear flex flex-col items-center gap-10">
                <Button type="primary" shape="circle" icon={<RedoOutlined />} onClick={onTableReload} loading={isLoading}>
                    {t("action:reload")}
                </Button>
                <p>{t("info:fetch_table_error")}</p>
            </div>
        </Card>
    )
}
