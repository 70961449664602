import { COLOR } from "@finway-group/shared/lib/consts"
import { LayerRenderStatus, Plugin, PluginOnCanvasLayerRender } from "@react-pdf-viewer/core"

export const customCanvasPlugin = (stamp: string): Plugin => {
    const onCanvasLayerRender = (e: PluginOnCanvasLayerRender) => {
        // Return if the canvas isn't rendered completely
        if (e.status !== LayerRenderStatus.DidRender) {
            return
        }

        // `e.ele` is the canvas element
        const canvas = e.ele
        const ctx = canvas.getContext("2d") as CanvasRenderingContext2D

        const centerX = canvas.width / 2
        const centerY = canvas.height / 2
        ctx.translate(centerX, centerY)
        ctx.rotate((-15 * Math.PI) / 180)

        const fonts = ctx.font.split(" ")
        const fontSize = parseInt(fonts[0], 10)
        ctx.textAlign = "center"
        ctx.font = `${fontSize * e.scale * (canvas.width / 100)}px ${fonts[1]}`
        ctx.fillStyle = COLOR.gray[600]
        ctx.strokeStyle = COLOR.gray[600]

        const txt = stamp.toUpperCase()
        ctx.fillText(txt, 0, 0)

        // rounded rectangle params
        const rectWidth = ctx.measureText(txt).width + (canvas.width / 5) * e.scale
        const rectHeight = (canvas.width / 5) * e.scale
        const rectX = -rectWidth / 2
        const rectY = -(rectHeight * 2) / 3
        const lineWidth = (canvas.width / 100) * e.scale
        const radius = (canvas.width / 50) * e.scale

        plotRoundedRectangle(ctx, radius, rectWidth, rectHeight, rectX, rectY, lineWidth)
    }
    return {
        onCanvasLayerRender,
    }
}

const plotRoundedRectangle = (ctx: CanvasRenderingContext2D, globalRadius: number, width: number, height: number, x: number, y: number, lineWidth: number) => {
    const radius = { tl: globalRadius, tr: globalRadius, br: globalRadius, bl: globalRadius }
    ctx.beginPath()
    ctx.lineWidth = lineWidth
    ctx.moveTo(x + radius.tl, y)
    ctx.lineTo(x + width - radius.tr, y)
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr)
    ctx.lineTo(x + width, y + height - radius.br)
    ctx.quadraticCurveTo(x + width, y + height, x + width - radius.br, y + height)
    ctx.lineTo(x + radius.bl, y + height)
    ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl)
    ctx.lineTo(x, y + radius.tl)
    ctx.quadraticCurveTo(x, y, x + radius.tl, y)
    ctx.stroke()
    ctx.closePath()
}
