import { CardFilter, Employee, RightEnum, Team } from "@finway-group/shared/lib/models"
import { CardModeEnum } from "@finway-group/shared/lib/models/card/cardMode.enum"
import { CardStatusEnum } from "@finway-group/shared/lib/models/card/cardStatus.enum"
import { CardTypeEnum } from "@finway-group/shared/lib/models/card/cardType.enum"
import { CardWindowEnum } from "@finway-group/shared/lib/models/card/cardWindow.enum"
import { Form } from "antd"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"

import { FilterFormInterface } from "Components/filter/filterTypes"
import { FormInputEnum, FormSelectOption } from "Components/form/form.types"
import FormMinMax from "Components/form/formMinMax"
import FormSelect from "Components/form/formSelect"
import { getTableFilterObjectStore } from "Shared/hooks/table.hooks"
import { useLoggedInEmployeeProfile } from "Shared/hooks/user.hooks"
import { AuthzService } from "Shared/services"
import { createDynamicList, createYesOrNoOption, getCardHolderList, getDynamicTeamList } from "Shared/utils/filter.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

import { generateRules } from "./rules/cardFilter.rules"

const adjustFilterObject = (filterObject: CardFilter): CardFilter => ({
    ...filterObject,
    minLimit: filterObject.minLimit ? Number(filterObject.minLimit) : undefined,
    maxLimit: filterObject.maxLimit ? Number(filterObject.maxLimit) : undefined,
})

const CardFilterForm: React.FC<FilterFormInterface> = ({ table, formInstance, options, onSubmit }) => {
    const { t } = useTranslation()
    const tableFilterObject = getTableFilterObjectStore<CardFilter>(table)
    const [cardFilter, setCardFilter] = useStateIfMounted(adjustFilterObject(tableFilterObject))

    const rules = generateRules(formInstance)
    const loggedInUser = useLoggedInEmployeeProfile()
    const cardHolderList = getCardHolderList(options.employees as Array<Employee>)

    useEffect(() => {
        formInstance.resetFields()
        setCardFilter(adjustFilterObject(tableFilterObject))
    }, [])

    useEffect(() => {
        formInstance.validateFields()
    }, [cardFilter])

    const yesOrNoOptions = createYesOrNoOption()

    const cardStatusList: Array<FormSelectOption> = createDynamicList("label:cards.tag", [CardStatusEnum.ACTIVE, CardStatusEnum.SUSPENDED, CardStatusEnum.TERMINATED])

    const cardTypeList: Array<FormSelectOption> = createDynamicList("label:cards.type", [CardTypeEnum.VIRTUAL, CardTypeEnum.PHYSICAL])

    const cardModeList: Array<FormSelectOption> = createDynamicList("label:cards.mode", [CardModeEnum.PREPAID_MODE, CardModeEnum.DEBIT_MODE])

    const cardWindowList: Array<FormSelectOption> = createDynamicList("label:cards.window", [
        CardWindowEnum.DAY,
        CardWindowEnum.WEEK,
        CardWindowEnum.MONTH,
        CardWindowEnum.QUARTER,
        CardWindowEnum.YEAR,
        CardWindowEnum.LIFETIME,
        CardWindowEnum.ONE_TIME,
    ])

    return (
        <Form id="cardFilterForm" className="form--filter" form={formInstance} onFinish={onSubmit}>
            <FormSelect name="cardType" header={t("input:card_filter.cardType")} initialValue={cardFilter.cardType} options={cardTypeList} isCollapse fallback />

            <FormSelect name="mode" header={t("input:card_filter.mode")} isCollapse initialValue={cardFilter.mode} options={cardModeList} fallback />

            {(AuthzService.isRightGrantedForLoggedInUser(RightEnum.CARD__ALL__READ) || AuthzService.isRightGrantedForLoggedInUser(RightEnum.CARD__TEAM__READ)) && (
                <FormSelect
                    name="user"
                    header={t("input:card_filter.card_holder")}
                    initialValue={cardFilter.user}
                    options={cardHolderList}
                    displayImages
                    isCollapse
                    showSearch
                    fallback
                />
            )}

            <FormMinMax
                header={t("input:card.limit")}
                inputType={FormInputEnum.NUMBER}
                min={{
                    name: "minLimit",
                    label: t("input:filter_form.min_amount"),
                    placeholder: t("placeholder:filter_form.min_amount"),
                    onChange: () => formInstance.validateFields(["minLimit", "maxLimit"]),
                    initialValue: cardFilter.minLimit,
                    rules: rules.minLimit,
                }}
                max={{
                    name: "maxLimit",
                    label: t("input:filter_form.max_amount"),
                    placeholder: t("placeholder:filter_form.max_amount"),
                    onChange: () => formInstance.validateFields(["minLimit", "maxLimit"]),
                    initialValue: cardFilter.maxLimit,
                    rules: rules.maxLimit,
                }}
            />

            <FormSelect
                name="status"
                header={t("input:card_filter.status")}
                initialValue={cardFilter.status}
                options={cardStatusList}
                fallback={String(t("input:filter_form.all"))}
                isCollapse
            />

            <FormSelect
                name="window"
                header={t("input:card_filter.window")}
                initialValue={cardFilter.window}
                options={cardWindowList}
                fallback={String(t("input:filter_form.all"))}
                isCollapse
            />

            <FormSelect
                name="subscriptionAttached"
                header={t("input:card_filter.subscription_attached")}
                initialValue={cardFilter.subscriptionAttached}
                options={yesOrNoOptions}
                isCollapse
                fallback
            />

            {AuthzService.isRightGrantedForLoggedInUser(RightEnum.EMPLOYEE__ALL__READ) && (
                <FormSelect
                    name="team"
                    header={t("input:filter_form.team")}
                    initialValue={cardFilter.team}
                    options={getDynamicTeamList(options.teams as Array<Team>)}
                    isCollapse
                    showSearch
                    fallback
                />
            )}
        </Form>
    )
}

export default CardFilterForm
