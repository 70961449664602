import { CollectionNameEnum, Company, CostCenter, CostCenter2, ExpenseAccount, PriceIntervalEnum, SplitTypeEnum, Tax } from "@finway-group/shared/lib/models"
import { roundNumberTo2Decimals } from "@finway-group/shared/lib/utils"
import { Button, Col, Form, Input, Row, Select, Tooltip } from "antd"
import React from "react"
import { Edit, Percent, RotateCcw, Save } from "react-feather"
import { useTranslation } from "react-i18next"

import DeletedTooltip from "Components/deletedTooltip"
import { TrashIcon } from "Components/icons"
import PriceLabel from "Components/priceLabel"
import { NO_TAX_RATE } from "Shared/config/consts"
import { useExpenseDetailsContext } from "Shared/context/expenseDetails.context"
import { useCompany } from "Shared/hooks/company.hooks"
import { useCostCenters2 } from "Shared/hooks/costCenter2.hooks"
import { useCostCenterById, useSubCostCenters } from "Shared/hooks/costCenter.hooks"
import { useExpenseAccounts } from "Shared/hooks/expenseAccount.hooks"
import { useTaxById, useTaxes } from "Shared/hooks/tax.hooks"
import { getCostCenterLabel } from "Shared/utils/costCenter.utils"
import { adjustGrossAmountsIfNeeded } from "Shared/utils/expense.utils"
import { doFilterSelect, getItemById, getTaxLabel } from "Shared/utils/helper.utils"

import { ExpenseDiscountRow } from "./auxiliary/expenseDiscountRow"
import { ExpenseTotalRow } from "./auxiliary/expenseTotalRow"
import AmountCell from "./expenseSplitCells/amountCell"
import CostCenter2Cell from "./expenseSplitCells/costCenter2Cell"
import CostCenterCell from "./expenseSplitCells/costCenterCell"
import ExpenseAccountCell from "./expenseSplitCells/expenseAccountCell"
import PercentageOrItemCell from "./expenseSplitCells/percentageOrItemCell"
import TaxRateCell from "./expenseSplitCells/taxRateCell"
import { PriceFieldEnum, useMainDetailsSectionContext } from "./mainDetailsSection.context"

interface ExpenseSplitsDetailTableInterface {}
const ExpenseSplitsDetailTable: React.FC<ExpenseSplitsDetailTableInterface> = () => {
    const {
        expense,
        splits,
        expenseTaxPrice: currentExpenseTaxPrice,
        onSplitUpdate,
        onSplitDelete,
        onAllSplitUpdate,
        onSave,
        disableSaveButton,
        isLoading,
        hasUnsavedChanges,
        expenseForm,
        isSplitManuallyAdjusted,
        roundingErrors,
        unlockSplitAmounts,
        isEditing,
        resetChanges,
        isExpenseEditable,
        discountDisplay,
        validateAndSave,
    } = useMainDetailsSectionContext()

    const { t } = useTranslation()
    const costCenters = useSubCostCenters(true)
    const costCenters2 = useCostCenters2()
    const expenseAccounts = useExpenseAccounts()
    const taxRates = useTaxes()
    const company = useCompany() as Company

    const { isReadOnly } = useExpenseDetailsContext() // either for an expense or a folder

    const isCostCenter2Configured = costCenters2.length > 0
    const isExpenseAccountConfigured = expenseAccounts.length > 0
    const splitTotalTax = expense.splits.reduce((acc, s: any) => s.taxPrice + acc, 0)
    const isTaxSynced = roundNumberTo2Decimals(splitTotalTax) === roundNumberTo2Decimals(currentExpenseTaxPrice)
    const hasRoundingError = roundingErrors.grossPrice !== 0 || roundingErrors.netPrice !== 0 || roundingErrors.taxPrice !== 0

    const isExpenseItemized = expense.splitType === SplitTypeEnum.ITEM
    const columnSpan = isExpenseItemized ? 4 : 3
    const currentExpenseTax = useTaxById(expense.taxRate?._id)
    const currentExpenseEmbeddedTax = expense.taxRate
    // Check if the taxRate field have a value. If not, get the value from the expense.
    const unsyncedTaxAmount = expense.totalNetPrice * ((getItemById(taxRates, expenseForm.getFieldValue("taxRate"))?.taxRate ?? currentExpenseEmbeddedTax?.taxRate) / 100)
    const { showCostCentersIds } = useCompany()

    const actualCC = useCostCenterById(expense.costCenter)

    const renderSelectAllRow = () => (
        <Row gutter={[8, 0]} className="items-center text-xs md:text-base">
            <Col span={4}>
                <p className="text-xs md:text-base">
                    {t("label:request")} #{expense.expenseNumber}
                </p>
            </Col>
            <Col span={3}>
                <Form.Item name="costCenter">
                    <Select
                        className="text-xs md:text-base no-padding-select"
                        showSearch
                        dropdownMatchSelectWidth={false}
                        filterOption={doFilterSelect}
                        bordered={false}
                        onChange={() => onAllSplitUpdate(CollectionNameEnum.COST_CENTER)}
                        disabled={isReadOnly}
                        placeholder="-"
                        dropdownClassName="z-50"
                    >
                        {actualCC?.deleted && (
                            <Select.Option value={actualCC._id} key={costCenters.length}>
                                <div>
                                    {getCostCenterLabel(actualCC, showCostCentersIds)}
                                    <DeletedTooltip translationKey="tooltips:cost_center.was_deleted" deletionDate={actualCC.dateDeleted} className="ml-4" />
                                </div>
                            </Select.Option>
                        )}
                        {costCenters.map((cc: CostCenter, index) => (
                            <Select.Option value={cc._id} key={index} label={getCostCenterLabel(cc, showCostCentersIds)}>
                                {getCostCenterLabel(cc, showCostCentersIds)}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Col>
            {isCostCenter2Configured && (
                <Col span={3}>
                    <Form.Item name="costCenter2">
                        <Select
                            className="text-xs md:text-base no-padding-select"
                            showSearch
                            filterOption={doFilterSelect}
                            dropdownMatchSelectWidth={false}
                            bordered={false}
                            disabled={isReadOnly}
                            onChange={() => onAllSplitUpdate(CollectionNameEnum.COST_CENTER_2)}
                            placeholder="-"
                            dropdownClassName="z-50"
                        >
                            <Select.Option value={""}>{t("label:not_set")}</Select.Option>
                            {costCenters2.map((cc2: CostCenter2, index) => (
                                <Select.Option value={cc2._id} key={index} name={cc2.name}>
                                    {cc2.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            )}
            {isExpenseAccountConfigured && (
                <Col span={3}>
                    <Form.Item name={["expenseAccount", "_id"]}>
                        <Select
                            className="text-xs md:text-base no-padding-select"
                            showSearch
                            filterOption={doFilterSelect}
                            dropdownMatchSelectWidth={false}
                            bordered={false}
                            disabled={isReadOnly}
                            onChange={() => onAllSplitUpdate(CollectionNameEnum.EXPENSE_ACCOUNT)}
                            placeholder="-"
                            dropdownClassName="z-50"
                        >
                            {/** Dont allow user to select "not set" when gobd compliance is enforced */}
                            {!company.gobdCompliance?.enforceGobdCompliantInvoice && <Select.Option value={""}>{t("label:not_set")}</Select.Option>}
                            {expenseAccounts.map((expenseAccount: ExpenseAccount, index) => (
                                <Select.Option value={expenseAccount._id} key={index} label={`${expenseAccount.accountName} ${expenseAccount.accountCode}`}>
                                    {expenseAccount.accountName} - {expenseAccount.accountCode}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            )}
            <Col span={2}>
                {expense.splitType === SplitTypeEnum.SPLIT ? (
                    <Form.Item name="taxRate">
                        <Select
                            showSearch
                            filterOption={doFilterSelect}
                            className="w-full text-xs md:text-base no-padding-select"
                            dropdownMatchSelectWidth={false}
                            disabled={isReadOnly}
                            dropdownStyle={{ maxWidth: "200px", overflowX: "hidden" }}
                            bordered={false}
                            onChange={() => onAllSplitUpdate(CollectionNameEnum.TAX)}
                            placeholder="-"
                            dropdownClassName="z-50"
                        >
                            {taxRates
                                .filter((tax: Tax) => !tax.deleted)
                                .map((entry: Tax, index: number) => (
                                    <Select.Option disabled={entry.deleted} key={index} value={entry._id} label={entry.taxRate}>
                                        <Tooltip placement="bottom" title={entry?.buCodeName}>
                                            <p className="truncate">{getTaxLabel(entry)}</p>
                                        </Tooltip>
                                    </Select.Option>
                                ))}
                        </Select>
                    </Form.Item>
                ) : currentExpenseEmbeddedTax && currentExpenseEmbeddedTax._id !== NO_TAX_RATE ? (
                    <>
                        <Tooltip placement="bottom" title={currentExpenseTax?.buCodeName}>
                            <p className="truncate">{getTaxLabel({ ...currentExpenseEmbeddedTax })}</p>
                        </Tooltip>
                        {currentExpenseTax?.deleted && (
                            <DeletedTooltip translationKey="tooltips:vat.was_deleted" deletionDate={currentExpenseTax?.dateDeleted} className="ml-4" anchorToDocumentBody />
                        )}
                    </>
                ) : (
                    "-"
                )}
            </Col>
            <Col span={3} className="text-right">
                {!isExpenseItemized ? (
                    <PriceLabel value={isTaxSynced ? splitTotalTax : unsyncedTaxAmount} currency={expense.currency} interval={PriceIntervalEnum.ONE_TIME} />
                ) : (
                    <PriceLabel value={expense.totalTaxPrice} currency={expense.currency} interval={PriceIntervalEnum.ONE_TIME} />
                )}
            </Col>
            <Col span={6} />
        </Row>
    )

    return (
        <>
            <Row id="expense-split-table" className="px-12 overflow-x-auto overflow-y-visible border-b">
                <Col span={24}>
                    <Row></Row>
                </Col>
                {!isExpenseItemized && (
                    <Col lg={3} xs={2} className="border-r border-b border-t">
                        <Row className="mt-12 mb-12 font-bold text-text-dark">
                            <Col>{t("label:name")}</Col>
                        </Row>
                        <Row>
                            <Col id="expenseNumber" className="text-xs md:text-base">
                                {t("label:request")} #{expense.expenseNumber}
                            </Col>
                        </Row>
                    </Col>
                )}
                <Col {...(isExpenseItemized ? { span: 24 } : { xs: 22, lg: 21 })}>
                    <div className={`${!isExpenseItemized ? "pl-24" : ""} border-t py-8`} style={{ minWidth: isCostCenter2Configured ? 1100 : 800 }}>
                        <Row gutter={[8, 0]} className="mt-12 mb-4 font-bold text-text-dark text-xs xl:text-base">
                            {isExpenseItemized && <Col span={columnSpan}>{t("label:item")}</Col>}
                            {!isExpenseItemized && <Col span={columnSpan}>{t("label:split_net")}</Col>}
                            <Col span={3}>{t("label:cost_center")}</Col>
                            {isCostCenter2Configured && <Col span={3}>{t("label:cost_center_2")}</Col>}
                            {isExpenseAccountConfigured && <Col span={3}>{t("label:expense_account")}</Col>}
                            <Col span={2} className="text-left">
                                {t("label:vat_unit", { unit: "%" })}
                            </Col>
                            <Col span={3} className="text-right break-normal ">
                                {t("label:vat")}
                            </Col>
                            <Col span={3} className="text-right break-normal ">
                                {t("label:total_net_price")}
                            </Col>
                            <Col span={3} className="text-right break-normal ">
                                {t("label:gross_amount")}
                            </Col>
                            {!isExpenseItemized && isExpenseEditable && splits.length > 1 && <Col span={1}></Col>}
                        </Row>
                        {
                            // Do not display select all row for non-itemized/splittable expense
                            isExpenseItemized && isExpenseEditable && renderSelectAllRow()
                        }
                        {isExpenseItemized && <Row className="border-b" />}
                        <Form form={expenseForm} onFinish={validateAndSave}>
                            <Form.List name="splits">
                                {(fields, { add, remove }) => {
                                    const adjustedSplits = adjustGrossAmountsIfNeeded(splits, expense)
                                    return (
                                        <Row className="text-xs md:text-base -mb-10" gutter={[8, 8]}>
                                            <Col span={24}>
                                                {fields.map((field, index) => {
                                                    const split = adjustedSplits[index]
                                                    return (
                                                        <Row gutter={[8, 0]} className="items-center" key={`split-row-${index}`}>
                                                            {(isExpenseItemized || splits.length > 1) && (
                                                                <Col span={columnSpan}>
                                                                    <PercentageOrItemCell splitIndex={index} field={field} editable={isExpenseEditable} split={split} />
                                                                </Col>
                                                            )}{" "}
                                                            {!isExpenseItemized && splits.length <= 1 && (
                                                                <Col data-testid="splitItemName" span={columnSpan}>
                                                                    <Input
                                                                        className="no-input-arrows pl-0"
                                                                        type="number"
                                                                        size="small"
                                                                        placeholder="100"
                                                                        suffix="%"
                                                                        bordered={false}
                                                                        disabled={true}
                                                                    />
                                                                </Col>
                                                            )}
                                                            <Col span={3}>
                                                                <CostCenterCell splitIndex={index} field={field} editable={isExpenseEditable} split={split} />
                                                            </Col>
                                                            {isCostCenter2Configured && (
                                                                <Col span={3}>
                                                                    <CostCenter2Cell splitIndex={index} field={field} editable={isExpenseEditable} split={split} />
                                                                </Col>
                                                            )}
                                                            {isExpenseAccountConfigured && (
                                                                <Col span={3}>
                                                                    <ExpenseAccountCell splitIndex={index} field={field} editable={isExpenseEditable} split={split} />
                                                                </Col>
                                                            )}
                                                            <Col data-testid="splitItemTaxPercentage" span={2}>
                                                                <TaxRateCell splitIndex={index} field={field} editable={isExpenseEditable} split={split} />
                                                            </Col>
                                                            <Col data-testid="splitItemTaxPrice" span={3} className={isEditing ? "" : "text-right"}>
                                                                <AmountCell
                                                                    splitIndex={index}
                                                                    field={field}
                                                                    editable={isExpenseEditable}
                                                                    priceField={PriceFieldEnum.TAX_PRICE}
                                                                    split={split}
                                                                    smallDisplay={isCostCenter2Configured}
                                                                />
                                                            </Col>
                                                            <Col data-testid="splitItemNetPrice" span={3} className={isEditing ? "" : "text-right"}>
                                                                <AmountCell
                                                                    splitIndex={index}
                                                                    field={field}
                                                                    editable={isExpenseEditable}
                                                                    priceField={PriceFieldEnum.NET_PRICE}
                                                                    split={split}
                                                                    smallDisplay={isCostCenter2Configured}
                                                                />
                                                            </Col>
                                                            <Col data-testid="splitItemGrossPrice" span={3} className={isEditing ? "" : "text-right"}>
                                                                <AmountCell
                                                                    splitIndex={index}
                                                                    field={field}
                                                                    editable={isExpenseEditable}
                                                                    priceField={PriceFieldEnum.GROSS_PRICE}
                                                                    split={split}
                                                                    smallDisplay={isCostCenter2Configured}
                                                                />
                                                            </Col>
                                                            {!isExpenseItemized && isExpenseEditable && splits.length > 1 && (
                                                                <Col span={1} className="text-right flex content-center justify-end">
                                                                    {splits.length > 1 && isExpenseEditable && (
                                                                        <TrashIcon
                                                                            onClick={() => {
                                                                                remove(index)
                                                                                onSplitDelete(index)
                                                                            }}
                                                                        />
                                                                    )}
                                                                </Col>
                                                            )}
                                                        </Row>
                                                    )
                                                })}
                                            </Col>
                                            {!isExpenseItemized && isExpenseEditable && !isEditing && (
                                                <Col>
                                                    <Button
                                                        onClick={() => {
                                                            add()
                                                            onSplitUpdate(undefined, true)
                                                        }}
                                                        className={"mr-5 mb-8"}
                                                        disabled={isLoading || isReadOnly}
                                                    >
                                                        <Percent />
                                                        {t("action:expense_split.split")}
                                                    </Button>
                                                </Col>
                                            )}
                                            {isExpenseEditable && !hasUnsavedChanges && !isEditing && (
                                                <Col>
                                                    <Button className="mb-8" onClick={unlockSplitAmounts}>
                                                        <Edit />
                                                        {t("action:expense_split.edit")}
                                                    </Button>
                                                </Col>
                                            )}
                                            {(hasUnsavedChanges || isEditing) && isSplitManuallyAdjusted && (
                                                <Col>
                                                    <Button className="mb-8" onClick={resetChanges}>
                                                        <RotateCcw />
                                                        {t("action:expense_split.reset")}
                                                    </Button>
                                                </Col>
                                            )}
                                            {(hasUnsavedChanges || isEditing) && (
                                                <Col>
                                                    <Button
                                                        className={"mb-8"}
                                                        onClick={onSave}
                                                        loading={isLoading}
                                                        disabled={disableSaveButton || isLoading || isReadOnly}
                                                        type="primary"
                                                    >
                                                        <Save />
                                                        {t("action:expense_split.save")}
                                                    </Button>
                                                </Col>
                                            )}
                                        </Row>
                                    )
                                }}
                            </Form.List>
                        </Form>
                    </div>
                </Col>
                <Col span={7} />
            </Row>
            <ExpenseDiscountRow />
            <ExpenseTotalRow />
        </>
    )
}

export default ExpenseSplitsDetailTable
