import { LoadingOutlined, MenuOutlined } from "@ant-design/icons"
import { RightEnum, Workflow } from "@finway-group/shared/lib/models"
import { Button, Checkbox, Empty, Modal, Table, Tag, Tooltip } from "antd"
import { ColumnsType } from "antd/es/table"
import { CheckboxChangeEvent } from "antd/lib/checkbox"
import React, { useEffect, useRef, useState } from "react"
import { Edit as EditIcon, Plus as PlusIcon, Trash2 as TrashIcon } from "react-feather"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc"
import { useDebouncedCallback } from "use-debounce/lib"

import NoSearchDataFound from "Components/NoSearchDataFound"
import Filter from "Components/filter/filter"
import FilterSection from "Components/filterSection"
import DownCircleIcon from "Components/icons/downCircleIcon"
import UpCircleIcon from "Components/icons/upCircleIcon"
import Loading from "Components/loading"
import WorkflowModal from "Components/modals/workflow.modal"
import PageTitle from "Components/page/pageTitle"
import SearchInput from "Components/searchInput"
import { useCostCenters2 } from "Shared/hooks/costCenter2.hooks"
import { useSubCostCenters } from "Shared/hooks/costCenter.hooks"
import { useEmployees } from "Shared/hooks/employee.hooks"
import { useIsTravelEnabled } from "Shared/hooks/featureFlags.hooks"
import { applyFilter } from "Shared/hooks/filter.hooks"
import { useFetchTable, useTable, useTableFilterAndSearchLoading } from "Shared/hooks/table.hooks"
import { useCreditors } from "Shared/hooks/vendor.hooks"
import { AuthzService } from "Shared/services"
import DialogService from "Shared/services/dialog.service"
import NotificationService, { NotificationTypeEnum } from "Shared/services/notification.service"
import WorkflowService from "Shared/services/workflowService"
import { refetchTable, updateTableOnChange, updateTableSearch } from "Shared/store/actions/tables/tableActions"
import { fetchAllWorkflows, setWorkflow, updateWorkflow } from "Shared/store/actions/workflow/workflowActions"
import { TablesEnum } from "Shared/store/reducers/tableConfigReducer"
import { getTooltipPopupContainer } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"
import { isTableFilterApplied } from "Shared/utils/table.utils"
import { getRequestAndTriggerTypesOptions } from "Shared/utils/workflow.form.utils"

import { WorkflowDetails } from "./workflowDetails"

interface WorkflowsInterface {}

const Workflows: React.FC<WorkflowsInterface> = ({}) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [isModalShowing, setIsModalShowing] = useState(false)
    const [expandedRows, setExpandedRows] = useState<Array<any>>([])
    const [isLoading, setIsLoading] = useStateIfMounted(false)
    const [isFilterModalShowing, setIsFilterModalShowing] = useState<boolean>(false)
    const isTravelEnabled = useIsTravelEnabled()

    const workflowsTable = useTable<Workflow>(TablesEnum.WORKFLOWS)
    const costCenters = useSubCostCenters(true)
    const creditors = useCreditors(AuthzService.isRightGrantedForLoggedInUser(RightEnum.EMPLOYEE__ALL__READ), true, true)
    const employees = useEmployees({ excludeDeleted: true })
    const costCenters2 = useCostCenters2()
    const isCreateBtnEnabled = costCenters.length > 0 || creditors.length > 0 || employees.length > 0
    const dragAndDropAnchor = useRef(null)
    const { id } = useParams<{ id: string }>()
    const userCanUpdateWorkflow = AuthzService.isRightGrantedForLoggedInUser(RightEnum.WORKFLOW__ALL__UPDATE)
    const userCanDeleteWorkflow = AuthzService.isRightGrantedForLoggedInUser(RightEnum.WORKFLOW__ALL__DELETE)
    const userCanCreateWorkflow = AuthzService.isRightGrantedForLoggedInUser(RightEnum.WORKFLOW__ALL__CREATE)

    const pageLoading = useTableFilterAndSearchLoading(TablesEnum.WORKFLOWS)

    useFetchTable(TablesEnum.WORKFLOWS)

    useEffect(() => {
        if (id) setExpandedRows([id])
    }, [id])

    const onDragEnd = (props: { oldIndex: number; newIndex: number }) => {
        const draggedWorkflow = workflowsTable.data.docs[props.oldIndex]
        const targetWorkflow = workflowsTable.data.docs[props.newIndex]
        if (draggedWorkflow.order === targetWorkflow.order) {
            dispatch(refetchTable(TablesEnum.WORKFLOWS)) // To prevent a visual bug when dragging an expanded workflow and leaving it in the same place.
            return
        }

        const updatedWorkflow = { ...draggedWorkflow, order: targetWorkflow.order }

        setIsLoading(true)

        // Start performing updates to server
        const performUpdate = async () => {
            await updateWorkflow(updatedWorkflow?._id, updatedWorkflow)(dispatch)
            await fetchAllWorkflows()(dispatch)
            dispatch(refetchTable(TablesEnum.WORKFLOWS))
        }

        performUpdate()
            .catch((e) => NotificationService.showErrorNotificationBasedOnResponseError(e, t("error:update.title"), t("error:update.message")))
            .finally(() => {
                setIsLoading(false)
            })
    }

    // Preparing draggable wrapper for antd table
    // TODO : we can utilize hooks to generate these
    const DragHandle = SortableHandle(() => <MenuOutlined style={{ cursor: "grab", color: "#999" }} />)
    const SortableItem = SortableElement((props: any) => <tr {...props} />)
    const SortableWrapper = SortableContainer((props: any) => <tbody {...props} />)
    const DraggableWrapper = (props: any) => (
        <SortableWrapper helperContainer={dragAndDropAnchor.current} helperClass="table-row-dragging" useDragHandle disableAutoscroll onSortEnd={onDragEnd} {...props} />
    )

    const previousIndex = useRef(0)
    const DraggableBodyRow = ({ className, ...restProps }: any) => {
        let index = workflowsTable.data.docs.findIndex((workflow) => workflow._id === restProps["data-row-key"])

        // Expanded rows have index -1, and therefore sorting wouldn't work with them. We will assign the index of its parent tr, which is the previousIndex.
        if (index === -1) index = previousIndex.current
        previousIndex.current = index

        return <SortableItem index={index} className={className} {...restProps} />
    }

    const onEditWorkflow = async (id: string) => {
        const workflow = workflowsTable.data.docs.find((workflow) => workflow._id === id)
        await setWorkflow(workflow)(dispatch)
        setIsModalShowing(true)
    }

    const handleSearch = useDebouncedCallback((searchString) => dispatch(updateTableSearch(TablesEnum.WORKFLOWS, searchString)), 800)

    const onDeleteWorkflow = (id: string) => {
        let checked = false
        Modal.confirm({
            title: (
                <>
                    <h2>{t("confirm:workflow.delete.title")}</h2>
                    <p>{t("confirm:workflow.delete.message")}</p>
                </>
            ),
            content: (
                <Tooltip title={t("tooltips:recalculate_approval_processes_in_progress")}>
                    <Checkbox
                        onChange={(e: CheckboxChangeEvent) => {
                            checked = e.target.checked
                        }}
                        className="finway-green-checkbox"
                    >
                        {t("input:workflow.recalculate_also_approval_processes_in_progress")}
                    </Checkbox>
                </Tooltip>
            ),
            okText: t("confirm:workflow.delete.confirm"),
            cancelText: t("confirm:workflow.delete.cancel"),
            type: "warning",
            async onOk() {
                const deleteWorkflow = async (metadata: { recalculateApprovalProcessesInProgress?: boolean } = {}) => {
                    try {
                        await WorkflowService.deleteWorkflow(id, metadata)
                        // refetch all rules in store
                        await dispatch(fetchAllWorkflows())
                        dispatch(refetchTable(TablesEnum.WORKFLOWS))
                        NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:workflow.deleted.title"), t("notification:workflow.deleted.message"))
                    } catch (err) {
                        NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:error"))
                    }
                }
                if (checked) {
                    const confirmRecalculateApprovalProcesses = await DialogService.confirmRecalculateApprovalProcesses()
                    if (!confirmRecalculateApprovalProcesses) return
                }

                await deleteWorkflow({ recalculateApprovalProcessesInProgress: checked })
            },
        })
    }

    const columns: ColumnsType = [
        {
            title: "Order",
            dataIndex: "sort",
            width: 30,
            className: "drag-visible",
            render: () => {
                if (!userCanUpdateWorkflow) {
                    return null
                }
                return (
                    <div className="mb-4">
                        <DragHandle />
                    </div>
                )
            },
        },
        {
            key: "name",
            title: t("label:name"),
            render: (workflow: Workflow) => (
                <span className="font-bold">
                    #{workflow.order} {workflow.name}
                </span>
            ),
        },
        {
            key: "steps",
            title: t("label:steps"),
            render: (workflow: Workflow) => (
                <>
                    <Tag className="ant-tag ant-tag-green-highlight pb-2" color="primary">
                        {workflow.steps.length}
                    </Tag>
                    <span className="ml-8">{t("label:steps")}</span>
                </>
            ),
        },
        {
            key: "triggerType",
            title: t("label:workflow.trigger.trigger_type"),
            render: (workflow: Workflow) => {
                if (!workflow.triggerConditionExpression) return <></>
                const { triggerTypes } = getRequestAndTriggerTypesOptions(workflow, isTravelEnabled ?? false)

                return (
                    <div className="flex flex-col">
                        <span>{t("label:workflow.trigger.trigger_type")}</span>
                        <div>
                            <span className="font-bold">
                                {triggerTypes.length === 1 ? (
                                    triggerTypes[0].condition?.toLowerCase() ? (
                                        t(`label:workflow.trigger.${triggerTypes[0].condition?.toLowerCase()}`)
                                    ) : (
                                        ""
                                    )
                                ) : (
                                    <>
                                        <Tooltip
                                            getPopupContainer={getTooltipPopupContainer}
                                            title={() => {
                                                const triggerTypesTooltipText = `${triggerTypes
                                                    .map((triggerType) => t(`label:workflow.trigger.${triggerType.condition?.toLowerCase()}`))
                                                    .join(", ")}.`
                                                return <p>{triggerTypesTooltipText}</p>
                                            }}
                                            placement="top"
                                        >
                                            <Tag className="text-base bg-gray-150">{t("label:workflow.types_amount", { amount: triggerTypes.length })}</Tag>
                                        </Tooltip>
                                    </>
                                )}
                            </span>
                        </div>
                    </div>
                )
            },
        },
        {
            key: "requestType",
            title: t("label:workflow.request_type.title"),
            render: (workflow: Workflow) => {
                if (!workflow.triggerConditionExpression) return <></>
                const { requestTypes } = getRequestAndTriggerTypesOptions(workflow, isTravelEnabled ?? false)

                return (
                    <div className="flex flex-col">
                        <span>{t("label:workflow.request_type.title")}</span>
                        <span className="font-bold">
                            {requestTypes?.length === 1 ? (
                                t(`label:workflow.request_type.${requestTypes[0].toLowerCase()}`)
                            ) : (
                                <>
                                    <Tooltip
                                        getPopupContainer={getTooltipPopupContainer}
                                        title={() => {
                                            const requestTypeTooltipText = `${requestTypes
                                                .map((requestType) => t(`label:workflow.request_type.${requestType.toLowerCase()}`))
                                                .join(", ")}.`
                                            return <p>{requestTypeTooltipText}</p>
                                        }}
                                        placement="top"
                                    >
                                        <Tag className="text-base bg-gray-150">{t("label:workflow.types_amount", { amount: requestTypes.length })}</Tag>
                                    </Tooltip>
                                </>
                            )}
                        </span>
                    </div>
                )
            },
        },
        {
            key: "action",
            title: t("label:action"),
            width: 100,
            render: (workflow: Workflow) => (
                <>
                    {userCanUpdateWorkflow && (
                        <Button className="mr-4" onClick={() => onEditWorkflow(workflow._id)}>
                            <EditIcon />
                        </Button>
                    )}
                    {userCanDeleteWorkflow && (
                        <Button data-testid="deleteWorkFlow" onClick={() => onDeleteWorkflow(workflow._id)}>
                            <TrashIcon />
                        </Button>
                    )}
                </>
            ),
        },
    ]

    const tableLoading = {
        spinning: isLoading,
        indicator: <LoadingOutlined style={{ fontSize: 30, color: "#757575" }} spin />,
    }

    return (
        <div>
            <PageTitle titleKey={"label:workflows"}>
                {(workflowsTable.data.totalDocs > 0 || workflowsTable.data.search) && (
                    <SearchInput onSearch={(searchString) => handleSearch.callback(searchString)} value={workflowsTable.data.search} />
                )}

                <Filter
                    table={TablesEnum.WORKFLOWS}
                    isFilterModalShowing={isFilterModalShowing}
                    setIsFilterModalShowing={setIsFilterModalShowing}
                    onFilter={(data) => applyFilter(TablesEnum.WORKFLOWS, data)}
                    options={{ creditors, costCenters, employees, costCenters2 }}
                />

                {userCanCreateWorkflow && (
                    <Button
                        data-testid="createWorkflowButton"
                        type="primary"
                        onClick={async () => {
                            await setWorkflow(undefined)(dispatch)
                            setIsModalShowing(true)
                        }}
                        disabled={!isCreateBtnEnabled}
                    >
                        <PlusIcon />
                        <span>{t("action:create_workflows")}</span>
                    </Button>
                )}
                <WorkflowModal isVisible={isModalShowing} onCancel={setIsModalShowing} />
            </PageTitle>
            <div id="drag-and-drop-anchor" ref={dragAndDropAnchor} className="w-full antd-table" />

            <FilterSection table={TablesEnum.WORKFLOWS} options={{ creditors, costCenters, costCenters2, employees }} />

            {pageLoading ? (
                <Loading />
            ) : workflowsTable.data.totalDocs === 0 && !workflowsTable.isFetching ? (
                !workflowsTable.data.search ? (
                    <Empty
                        className="animation-appear"
                        image={`./icons/empty_table.svg`}
                        imageStyle={{
                            height: 150,
                            marginRight: "auto",
                            marginLeft: "auto",
                            marginTop: "15%",
                            marginBottom: "40px",
                            display: "inline-block",
                        }}
                        description={
                            <span className="text-lg font-bold mt-2 mb-0">
                                {!isTableFilterApplied(workflowsTable.data.filter) ? t("info:empty_state.workflows.title") : t("info:no_filter_data_found.title.workflow")}
                            </span>
                        }
                    >
                        <div className="flex flex-col justify-between items-center gap-y-4">
                            <p className="mb-14">
                                {!isTableFilterApplied(workflowsTable.data.filter) ? t("info:empty_state.workflows.message") : t("info:no_filter_data_found.message")}
                            </p>
                            {userCanCreateWorkflow && (
                                <Button
                                    type="primary"
                                    onClick={async () => {
                                        await setWorkflow(undefined)(dispatch)
                                        setIsModalShowing(true)
                                    }}
                                    disabled={!isCreateBtnEnabled}
                                >
                                    <PlusIcon />
                                    <span>{t("action:create_workflows")}</span>
                                </Button>
                            )}
                        </div>
                    </Empty>
                ) : (
                    <div className="animation-appear">
                        <NoSearchDataFound />
                    </div>
                )
            ) : (
                <div className="overflow-auto p-2 animation-appear">
                    <Table
                        data-testid="workflowTable"
                        rowKey={(workflow: Workflow) => workflow._id}
                        showHeader={false}
                        columns={columns}
                        dataSource={workflowsTable.data.docs}
                        components={{
                            body: {
                                wrapper: DraggableWrapper,
                                row: DraggableBodyRow,
                            },
                        }}
                        pagination={{
                            position: ["bottomRight"],
                            showSizeChanger: true,
                            current: workflowsTable.data.search ? 1 : workflowsTable.data.page,
                            pageSize: workflowsTable.data.limit,
                            hideOnSinglePage: false,
                            pageSizeOptions: ["5", "10", "20", "50", "100"],
                        }}
                        onChange={updateTableOnChange(TablesEnum.WORKFLOWS, workflowsTable.data, false)}
                        expandable={{
                            expandRowByClick: true,
                            expandIconColumnIndex: 1,
                            expandedRowRender: (workflow: Workflow) => <WorkflowDetails workflow={workflow} />,
                            expandedRowKeys: expandedRows,
                            defaultExpandedRowKeys: expandedRows,
                            rowExpandable: () => true,
                            expandIcon: ({ expanded, onExpand, record }) => (
                                <button className="outline-none" onClick={(e) => onExpand(record, e)}>
                                    {expanded ? <UpCircleIcon /> : <DownCircleIcon />}
                                </button>
                            ),
                            onExpandedRowsChange: (expandedRows) => setExpandedRows(expandedRows),
                        }}
                        loading={tableLoading}
                    />
                </div>
            )}
        </div>
    )
}

export default Workflows
