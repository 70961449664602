import { PerDiemDestination } from "@finway-group/shared/lib/models"
import { Reducer } from "redux"
import { createTransform } from "redux-persist"

import { FetchPerDiemDestinationAction, PerDiemDestinationActionTypes, PerDiemDestinationActions } from "../actions/perDiemDestination/perDiemDestinationTypes"

export interface PerDiemDestinationState {
    items: Array<PerDiemDestination>
}

export interface PersistPerDiemDestinationState {
    items: Array<PerDiemDestination>
}

const initialState: PerDiemDestinationState = {
    items: [],
}

const fetchPerDiemDestinations = (state: PerDiemDestinationState, action: FetchPerDiemDestinationAction): PerDiemDestinationState => ({
    ...state,
    items: action.perDiemDestinations,
})

export const perDiemDestinationReducer: Reducer<PerDiemDestinationState, PerDiemDestinationActions> = (state = initialState, action) => {
    switch (action.type) {
        case PerDiemDestinationActionTypes.FETCH_PER_DIEM_DESTINATIONS:
            return fetchPerDiemDestinations(state, action)
        default:
            return state
    }
}

// ! @wibi it is not refetching from the datafetcher on reload.
export const perDiemTransform = createTransform<PerDiemDestinationState, PersistPerDiemDestinationState>(
    (inboundState) =>
        // Remove the items to save space, and let the dataFetcher fetch these again
        ({ ...inboundState, items: [] }),
    (outboundState, _key, rawState) => {
        // Just in case if somehow items and byYear is filled in
        let items = []
        try {
            if (rawState.perDiemDestinations) {
                items = JSON.parse(rawState.perDiemDestinations).items ?? []
            }
        } catch (err) {
            // ...logout?
        }
        return { ...outboundState, items }
    },
    { whitelist: ["perDiemDestinations"] },
)
