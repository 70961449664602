import Icon, { CarOutlined, DollarCircleOutlined } from "@ant-design/icons"
import { ExpenseKindEnum } from "@finway-group/shared/lib/models"
import { Button, Divider, Form } from "antd"
import React from "react"
import { Coffee, Home } from "react-feather"
import { useTranslation } from "react-i18next"

import { useExpenseFormContext } from "Components/modals/expenseCreateForm.context"

const ReimbursementTypeSelector = () => {
    const [{ expense, isNew, onKindChange, reimbursementRequestsEnabled }] = useExpenseFormContext()
    const { kind } = expense
    const { t } = useTranslation()

    if (!isNew) return null
    const reimbursementTypes = []

    if (reimbursementRequestsEnabled.standard) {
        reimbursementTypes.push({ type: ExpenseKindEnum.ONE_TIME_EXPENSE, icon: <DollarCircleOutlined />, label: t("label:reimbursement_types.standard"), className: "icon" })
    }

    if (reimbursementRequestsEnabled.mileage) {
        reimbursementTypes.push({ type: ExpenseKindEnum.MILEAGE, icon: <CarOutlined />, label: t("label:reimbursement_types.mileage"), className: "icon" })
    }

    if (reimbursementRequestsEnabled.hospitality) {
        reimbursementTypes.push({
            type: ExpenseKindEnum.HOSPITALITY,
            icon: <Icon component={() => <Coffee size="1em" className="mb-2" data-testid="hospitalityIcon" />} />,
            label: t("label:reimbursement_types.hospitality"),
            className: "icon",
        })
    }

    if (reimbursementRequestsEnabled.perDiem) {
        reimbursementTypes.push({
            type: ExpenseKindEnum.PER_DIEM,
            icon: <Icon component={() => <Home size="1em" className="mb-2" />} />,
            label: t("label:reimbursement_types.per_diem"),
            className: "icon",
        })
    }

    return (
        <>
            <label htmlFor="expenseKind" className="block text-base font-semibold mb-12 finway-dark-text">
                {t("label:reimbursement_type")}
            </label>
            <div className="flex w-full gap-10 flex-wrap">
                {reimbursementTypes.map(({ type, icon, label }) => (
                    <Form.Item name="kind" className="m-0 flex-grow reimbursement-button" id="expenseKind" key={type}>
                        <Button
                            key={`reimbursement-selector-${type}`}
                            onClick={() => {
                                onKindChange(type)
                            }}
                            className={`w-full selectable-button ${kind === type ? "active" : ""}`}
                            icon={icon}
                        >
                            <span className="mt-1 ml-6">{label}</span>
                        </Button>
                    </Form.Item>
                ))}
            </div>

            <Divider />
        </>
    )
}

export default ReimbursementTypeSelector
