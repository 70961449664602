import { UploadKindEnum } from "@finway-group/shared/lib/models"
import { Button, Upload } from "antd"
import React, { useState } from "react"
import { Edit as EditIcon, Upload as UploadIcon } from "react-feather"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import UserImage from "Components/UserImage"
import { EmployeeModal } from "Components/modals/employee.modal"
import PhoneVerificationModal from "Components/modals/phoneVerfication.modal"
import WeavrEmailVerificationModal from "Components/modals/weavrEmailVerification.modal"
import WeavrRecoverPasscodeModal from "Components/modals/weavrPasscodeRecovery.modal"
import WeavrUpdatePasscodeModal from "Components/modals/weavrUpdatePasscode.modal"
import PhoneNumber from "Components/phoneNumber"
import SettingsLayout, { SettingsButton } from "Features/settings/settings.layout"
import { API_URL, NOTIFICATION_OPEN_DURATION } from "Shared/config/consts"
import { useModal } from "Shared/context/modal.context"
import { useCompany } from "Shared/hooks/company.hooks"
import { useKYBData } from "Shared/hooks/corporate.hooks"
import { useTeams } from "Shared/hooks/team.hooks"
import { useLoggedInEmployeeProfile } from "Shared/hooks/user.hooks"
import { AuthnService, FileService, NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { updateEmployeeImg } from "Shared/store/actions/employee/employeeActions"
import { isUserEnrolled, shouldShowRootUserEmailReverification } from "Shared/utils/creditCard.utils"
import { getIBANPrintFormat, getItemById } from "Shared/utils/helper.utils"

const UserProfileSettings = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const { showModal } = useModal()
    const currentEmployee = useLoggedInEmployeeProfile()
    const teams = useTeams()
    const corporate = useKYBData()
    const company = useCompany()

    const [IsUploading, setIsUploading] = useState(false)
    const [isPhoneNumberModalShowing, setIsPhoneNumberModalShowing] = useState(false)

    const handleEmployeeModal = () => showModal(EmployeeModal, false, { isShowing: true, isNew: false, employee: currentEmployee })

    const initiatePasscodeUpdate = () => {
        if (showModal)
            showModal(WeavrUpdatePasscodeModal, true, {
                isShowing: true,
                onSuccess: () => {
                    NotificationService.send(
                        NotificationTypeEnum.SUCCESS,
                        t(`notification:passcode.update_success.title`),
                        t(`notification:passcode.update_success.message`),
                        NOTIFICATION_OPEN_DURATION,
                    )
                },
            })
    }

    const handleSetNewPasswordClick = async () => {
        try {
            await AuthnService.forgotPassword(currentEmployee.email)
            NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:set_new_password_request.title"), t("notification:set_new_password_request.message"))
        } catch (err) {
            NotificationService.showErrorNotificationBasedOnResponseError(err, "Error")
        }
    }

    const initiatePasscodeRecovery = () => {
        if (showModal)
            showModal(WeavrRecoverPasscodeModal, true, {
                isShowing: true,
                onSuccess: () => {
                    NotificationService.send(
                        NotificationTypeEnum.SUCCESS,
                        t(`notification:passcode.recovery_success.title`),
                        t(`notification:passcode.recovery_success.message`),
                        10,
                    )
                },
            })
    }

    const initiateEmailVerification = () => {
        if (showModal)
            showModal(WeavrEmailVerificationModal, true, {
                isShowing: true,
            })
    }

    const doFileUpload = (file: any) => {
        if (!FileService.checkFileSizeAndHandleError(file)) return

        setIsUploading(true)
        if (currentEmployee?.id)
            FileService.upload(file, UploadKindEnum.USER_IMG)
                .then((data) => {
                    updateEmployeeImg(
                        currentEmployee?.id,
                        data.url,
                    )(dispatch)
                        .then(() => {
                            setIsUploading(false)
                            NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:file.uploaded.title"), t("notification:file.uploaded.message"))
                        })
                        .catch(() => {
                            setIsUploading(false)
                            NotificationService.send(NotificationTypeEnum.ERROR, t("error:file.upload.title"), t("error:file.upload.message"))
                        })
                })
                .catch(() => {
                    setIsUploading(false)
                    NotificationService.send(NotificationTypeEnum.ERROR, t("error:file.upload.title"), t("error:file.upload.message"))
                })
    }

    return (
        <SettingsLayout titleKey="label:profile" buttons={<SettingsButton icon={<EditIcon />} onClick={handleEmployeeModal} labelKey={"action:edit"} />}>
            <PhoneVerificationModal
                isShowing={isPhoneNumberModalShowing}
                employee={currentEmployee}
                phoneNumber={currentEmployee.workPhone}
                onCancel={() => setIsPhoneNumberModalShowing(false)}
            />
            <ul className="description-list">
                <li>
                    <span className="title">{t("label:profile_image")}</span>
                    <div className="text text-left flex items-center flex-no-wrap">
                        <UserImage user={currentEmployee} />
                        <Upload
                            action={`${API_URL}/files`}
                            headers={{ Authorization: `Bearer ${localStorage.accessToken}` }}
                            accept=".jpg,.jpeg,.png"
                            showUploadList={false}
                            customRequest={(req) => doFileUpload(req.file)}
                        >
                            <Button className="btn-default min-w-200" loading={IsUploading}>
                                <UploadIcon />
                                <span className="md:block hidden">{t("action:upload_new_image")}</span>
                            </Button>
                        </Upload>
                    </div>
                </li>
                <li>
                    <span className="title">{t("label:first_name")}</span>
                    <span className="text text-left truncate max-w-250">{currentEmployee?.firstName}</span>
                </li>
                <li>
                    <span className="title">{t("label:last_name")}</span>
                    <span className="text text-left truncate max-w-250">{currentEmployee?.lastName}</span>
                </li>
                <li>
                    <span className="title">{t("label:email")}</span>
                    <span className="text text-left truncate max-w-250">{currentEmployee?.email}</span>
                </li>
                <li>
                    <span className="title">{t("label:password")}</span>
                    <Button style={{ whiteSpace: "normal", height: "100%" }} onClick={handleSetNewPasswordClick} className="btn-default min-w-200 h-34 p-0">
                        {t("action:set_new_password")}
                    </Button>
                </li>
                {company?.cardsEnabled && isUserEnrolled(currentEmployee.activeCompanyProfile.weavrData) && (
                    <>
                        <li>
                            <span className="title">{t("label:passcode_update")}</span>
                            <Button style={{ whiteSpace: "normal", height: "100%" }} onClick={initiatePasscodeUpdate} className="btn-default min-w-200 h-34 p-0">
                                {t("action:passcode.update")}
                            </Button>
                        </li>
                        <li>
                            <span className="title">{t("label:passcode_recovery")}</span>
                            <Button style={{ whiteSpace: "normal", height: "100%" }} onClick={initiatePasscodeRecovery} className="btn-default min-w-200 h-34 p-0">
                                {t("action:passcode.recover")}
                            </Button>
                        </li>
                        {shouldShowRootUserEmailReverification(currentEmployee, corporate) && (
                            <li>
                                <span className="title">{t("label:root_user_email_reverification")}</span>
                                <Button style={{ whiteSpace: "normal", height: "100%" }} onClick={initiateEmailVerification} className="btn-default min-w-200 h-34 p-0">
                                    {t("action:cards.verify_email")}
                                </Button>
                            </li>
                        )}
                    </>
                )}
                <li>
                    <span className="title">{t("label:location")}</span>
                    <span className="text text-left truncate max-w-250">{currentEmployee?.location}</span>
                </li>
                <li>
                    <span className="title">{t("label:team")}</span>
                    <span className="text text-left">{getItemById(teams, currentEmployee.activeCompanyProfile?.team)?.teamName}</span>
                </li>
                <li>
                    <span className="title">{t("label:position")}</span>
                    <span className="text text-left truncate max-w-250">{currentEmployee?.position}</span>
                </li>
                {(currentEmployee?.iban || currentEmployee?.bic) && (
                    <>
                        <li>
                            <span className="title">{t("label:iban")}</span>
                            <span className="text">{currentEmployee?.iban ? getIBANPrintFormat(currentEmployee?.iban) : "-"}</span>
                        </li>
                        <li>
                            <span className="title">{t("label:bic")}</span>
                            <span className="text">{currentEmployee?.bic || "-"}</span>
                        </li>
                    </>
                )}
                {currentEmployee?.workPhone && (
                    <li>
                        <span className="title">{t("label:work_phone")}</span>
                        <span className="text truncate max-w-250">
                            <PhoneNumber employee={currentEmployee} showVerificationModal={() => setIsPhoneNumberModalShowing(true)} />
                        </span>
                    </li>
                )}
            </ul>
        </SettingsLayout>
    )
}

export default React.memo(UserProfileSettings)
