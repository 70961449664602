import React from "react"

import Icon, { IconInterface } from "Components/icon"

const dinnerSvg = (
    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M0.53125 8H3.28927C4.13643 8 4.83676 8.66034 4.88651 9.50604L5.77599 24.6273C5.82574 25.473 6.52607 26.1333 7.37323 26.1333H31.4646"
            stroke="#606C7E"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M8 26.1292L9.464 10.5132C9.54108 9.69096 10.2312 9.0625 11.057 9.0625H27.343C28.1688 9.0625 28.8589 9.69096 28.936 10.5132L30.4 26.1292"
            stroke="#606C7E"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path d="M13.3323 14.3984L12.2656 26.1318" stroke="#606C7E" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M25.0625 14.3984L26.1292 26.1318" stroke="#606C7E" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M19.2031 14.3984V26.1318" stroke="#606C7E" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path
            d="M9.6 29.3328C10.4837 29.3328 11.2 28.6165 11.2 27.7328C11.2 26.8492 10.4837 26.1328 9.6 26.1328C8.71634 26.1328 8 26.8492 8 27.7328C8 28.6165 8.71634 29.3328 9.6 29.3328Z"
            stroke="#606C7E"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M28.8031 29.3328C29.6868 29.3328 30.4031 28.6165 30.4031 27.7328C30.4031 26.8492 29.6868 26.1328 28.8031 26.1328C27.9195 26.1328 27.2031 26.8492 27.2031 27.7328C27.2031 28.6165 27.9195 29.3328 28.8031 29.3328Z"
            stroke="#606C7E"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M19.1974 3.73073C19.4919 3.73073 19.7307 3.49195 19.7307 3.1974C19.7307 2.90284 19.4919 2.66406 19.1974 2.66406C18.9028 2.66406 18.6641 2.90284 18.6641 3.1974C18.6641 3.49195 18.9028 3.73073 19.1974 3.73073Z"
            stroke="#606C7E"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M13.3594 9.06776C13.6288 6.0776 16.1418 3.73438 19.2021 3.73438C22.2626 3.73438 24.7758 6.07791 25.0449 9.06834"
            stroke="#606C7E"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)

const DinnerIcon = (props: IconInterface) => <Icon component={dinnerSvg} {...props} />

export default DinnerIcon
