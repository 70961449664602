/* global SocketIOClient */
import * as Sentry from "@sentry/react"
import { Col, Layout, Row } from "antd"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

import Loading from "Components/loading"
import { CI_COMMIT } from "Shared/config/consts"
import { useAuth } from "Shared/hooks/auth.hooks"
import { useIsUserRightsReady } from "Shared/hooks/authz.hooks"
import DataFetcher from "Shared/store/dataFetcher"
import { RootState } from "Shared/store/rootState.interface"
import { breakpoints } from "Shared/utils/breakpoints.utils"
import { useResizeWindowWidth } from "Shared/utils/hooks/useResizeWindowWidth"

import BannerContainer from "./partials/banners/banner.container"
import { GlobalNotifications } from "./partials/globalNotifications"
import Navbar from "./partials/navbar/navbar.container"
import { Sidebar } from "./partials/sidebar"
import SocketTracker from "./partials/socketTracker"

const { Content } = Layout

interface MainProps {}

export const Main = (props: MainProps) => {
    const windowWidth = useResizeWindowWidth()
    const { t, i18n } = useTranslation()

    useIsUserRightsReady()

    const [showMobileNav, setShowMobileNav] = useState(false)
    const [, setSocket] = useState<SocketIOClient.Socket>()

    const { userClass } = useAuth()

    const hasLoaded = useSelector(({ fetcher }: RootState) => fetcher.hasLoaded)

    let onMobileDevice = false
    if (windowWidth < breakpoints.lg) {
        onMobileDevice = true
    }

    const triggerMobileMenu = (visible: boolean): void => setShowMobileNav(visible)

    const onMainContentLayoverClick = (): void => setShowMobileNav(false)

    const contentStyles = {
        marginLeft: showMobileNav ? "100px" : "0px",
        transition: "margin-left .5s",
    }

    const showBreakpointIndicator = false
    const content = (
        <Content style={contentStyles}>
            <BannerContainer />
            <GlobalNotifications />
            <main className="pt-40 pb-80 px-20 md:px-0">{props.children}</main>
        </Content>
    )

    // Handle cross tab logout
    useEffect(() => {
        // set logged in user to sentry config
        Sentry.setUser({ id: userClass.id })
        if (userClass.tenantId) {
            Sentry.setTag("tenantId", userClass?.tenantId)
            Sentry.setTag("version", CI_COMMIT)
        }
        i18n.changeLanguage(userClass.getUserLanguage())
    }, [])

    return (
        <div className={showMobileNav ? "overflow-hidden max-h-screen" : ""}>
            <Navbar showMobileNav={showMobileNav} onMobileToggleClick={triggerMobileMenu} onMobileDevice={onMobileDevice} />
            <DataFetcher />

            <SocketTracker onSocket={setSocket} />

            {!hasLoaded ? (
                <Loading text={t("info:loading_data")} />
            ) : (
                <React.Fragment>
                    <div className={`relative mt-56 ${showMobileNav ? "min-h-screen" : ""}`}>
                        <div className="container">
                            <Row className="w-full">
                                <Col xl={4}>
                                    <Sidebar onMobileDevice={onMobileDevice} showMobileNav={showMobileNav} />
                                </Col>
                                {onMobileDevice ? (
                                    <Col flex="auto" style={contentStyles}>
                                        {content}
                                    </Col>
                                ) : (
                                    <Col xs={24} sm={24} md={24} lg={24} xl={20} style={contentStyles}>
                                        {content}
                                    </Col>
                                )}
                            </Row>
                        </div>

                        {showMobileNav ? (
                            <div className="absolute bg-black opacity-50 w-full h-full left-0 top-0 bottom-0 right-0 z-40" onClick={onMainContentLayoverClick}></div>
                        ) : null}
                    </div>
                </React.Fragment>
            )}

            {showBreakpointIndicator && (
                <div className="flex items-center m-2 fixed bottom-0 right-0 border border-gray-400 rounded p-2 bg-gray-300 text-pink-600 text-sm">
                    <svg className="h-6 w-auto inline" viewBox="0 0 80 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            fill="url(#paint0_linear)"
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M32 16C24.8 16 20.3 19.6 18.5 26.8C21.2 23.2 24.35 21.85 27.95 22.75C30.004 23.2635 31.4721 24.7536 33.0971 26.4031C35.7443 29.0901 38.8081 32.2 45.5 32.2C52.7 32.2 57.2 28.6 59 21.4C56.3 25 53.15 26.35 49.55 25.45C47.496 24.9365 46.0279 23.4464 44.4029 21.7969C41.7557 19.1099 38.6919 16 32 16ZM18.5 32.2C11.3 32.2 6.8 35.8 5 43C7.7 39.4 10.85 38.05 14.45 38.95C16.504 39.4635 17.9721 40.9536 19.5971 42.6031C22.2443 45.2901 25.3081 48.4 32 48.4C39.2 48.4 43.7 44.8 45.5 37.6C42.8 41.2 39.65 42.55 36.05 41.65C33.996 41.1365 32.5279 39.6464 30.9029 37.9969C28.2557 35.3099 25.1919 32.2 18.5 32.2Z"
                        ></path>
                        <defs>
                            <linearGradient id="paint0_linear" x1="3.5" y1="16" x2="59" y2="48" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#2298BD"></stop>
                                <stop offset="1" stop-color="#0ED7B5"></stop>
                            </linearGradient>
                        </defs>
                    </svg>
                    Current breakpoint
                    <span className="ml-1 sm:hidden md:hidden lg:hidden xl:hidden">default (&lt; 640px)</span>
                    <span className="ml-1 hidden sm:inline md:hidden font-extrabold">sm</span>
                    <span className="ml-1 hidden md:inline lg:hidden font-extrabold">md</span>
                    <span className="ml-1 hidden lg:inline xl:hidden font-extrabold">lg</span>
                    <span className="ml-1 hidden xl:inline font-extrabold">xl</span>
                </div>
            )}
        </div>
    )
}

export default Main
