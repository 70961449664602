import { Mileage, VehicleEnum } from "@finway-group/shared/lib/models"
import { toAmount, toDinero } from "@finway-group/shared/lib/utils"
import { TFunction } from "i18next"

// TODO FA-161: refactor store value in mongo db and query by company country / maybe lamda instead?
enum VehicleMileageRatePerKm {
    CAR = 0.3,
    MOTORCYCLE = 0.2,
}

const KILOMETER_DIVIDER = 1000

export const getVehicleString = (vehicle: VehicleEnum, t: TFunction) => {
    switch (vehicle) {
        case VehicleEnum.CAR:
            return t("label:mileage.vehicle_type.car")
        case VehicleEnum.MOTORCYCLE:
            return t("label:mileage.vehicle_type.motorcycle")
        default:
            return t("label:n/a")
    }
}

export const calculateExpensePriceFromDistanceAndVehicle = (vehicle: VehicleEnum, distance: number, rateOverride?: number) => {
    let pricePerKm = 0
    if (rateOverride || rateOverride === 0) {
        pricePerKm = rateOverride! // isSet should assert this to be NOT undefined.
    } else if (vehicle === VehicleEnum.CAR) {
        pricePerKm = VehicleMileageRatePerKm.CAR
    } else {
        pricePerKm = VehicleMileageRatePerKm.MOTORCYCLE
    }
    const price = toAmount(toDinero(Number(distance) * pricePerKm))
    return { totalGrossPrice: price, totalNetPrice: price, totalTaxPrice: 0 }
}

export const convertMeterToKilometer = (distanceInMeter: number) => distanceInMeter / KILOMETER_DIVIDER

export const isDistanceOverThreshold = ({ expectedDistance, actualDistance }: Mileage, threshold: number) =>
    expectedDistance ? actualDistance > expectedDistance + threshold : false
