import { Expense, Hospitality, Mileage, PerDiem, TripFolder } from "@finway-group/shared/lib/models"
import { Button, Tooltip } from "antd"
import { ButtonProps } from "antd/lib/button"
import React from "react"
import { Edit } from "react-feather"
import { useTranslation } from "react-i18next"

import { ConditionalWrapper } from "Components/conditionalWrapper"
import ExpenseCreateFormModal from "Components/modals/expenseCreateForm.modal"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

interface ExpenseEditButtonInterface {
    expense: Expense | PerDiem | Mileage | Hospitality
    folder?: TripFolder
    button?: (props: ButtonProps) => JSX.Element
}

/**
 * Button to trigger an edit for expense that is not loaded into redux
 */
export const ExpenseEditButton = ({ expense, folder, button }: ExpenseEditButtonInterface) => {
    const [isModalShowing, setIsModalShowing] = useStateIfMounted<boolean>(false)
    const { t } = useTranslation()
    const onClick = () => setIsModalShowing(true)
    return (
        <>
            {/** To avoid the form modal getting rendered when not needed. */}
            {isModalShowing && (
                <ExpenseCreateFormModal
                    expenseOverride={expense}
                    type={expense.getRequestType()}
                    hideExpenseKindRadioOption={true}
                    isNew={false}
                    isShowing={isModalShowing}
                    kind={expense.kind}
                    onCancel={() => setIsModalShowing(false)}
                    folder={folder}
                />
            )}
            <ConditionalWrapper condition={expense.isGobdCompliantInvoice()} wrapper={() => <Tooltip title={t("tooltips:expenses.edit_disabled_due_to_beleg")} />}>
                {button ? (
                    button({ onClick })
                ) : (
                    <Button data-testid="editFolderExpense" onClick={onClick}>
                        <Edit />
                    </Button>
                )}
            </ConditionalWrapper>
        </>
    )
}
