import { Vendor } from "@finway-group/shared/lib/models"
import { ExpensePaymentFlowInformationEnum } from "@finway-group/shared/lib/models/expense/expensePaymentFlowInformation.enum"
import { MAX_DISCOUNT_DURATION_DAYS, MAX_DISCOUNT_PERCENTAGE, MIN_DISCOUNT_DURATION_DAYS } from "@finway-group/shared/lib/utils/validators"
import { MAX_PAYMENT_TERM_DAYS, MIN_PAYMENT_TERM_DAYS } from "@finway-group/shared/lib/utils/validators/vendor/vendorValidationConsts"
import { Col, Form, Input, Radio, Row } from "antd"
import { FormInstance } from "antd/lib/form"
import { Store } from "antd/lib/form/interface"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { NumericFormat } from "react-number-format"

import { FormSelectOption } from "Components/form/form.types"
import FormSelect from "Components/form/formSelect"
import { useCompany } from "Shared/hooks/company.hooks"
import { useCostCenters2 } from "Shared/hooks/costCenter2.hooks"
import { useSubCostCenters } from "Shared/hooks/costCenter.hooks"
import { useExpenseAccounts } from "Shared/hooks/expenseAccount.hooks"
import { createDynamicList, getDynamicCostCenter2List, getDynamicCostCenterList, getDynamicExpenseAccountList } from "Shared/utils/filter.utils"
import { handleEmptyValues } from "Shared/utils/helper.utils"
import { DiscountForForm } from "Shared/utils/vendorRule.form.utils"

import { VendorRules } from "./rules"
import { DiscountFormRules } from "./rules/discountRule.rules"

interface VendorRuleFormInterface {
    formInstance: FormInstance
    vendor: Vendor | undefined
    onSubmit: (values: Store) => void
}

enum VendorRulePaymentTermEnum {
    SEVEN_DAYS = 7,
    FOURTEEN_DAYS = 14,
    TWENTY_EIGHT_DAYS = 28,
}
// predefined options for payment terms
const paymentTermOptions = [VendorRulePaymentTermEnum.SEVEN_DAYS, VendorRulePaymentTermEnum.FOURTEEN_DAYS, VendorRulePaymentTermEnum.TWENTY_EIGHT_DAYS]

const VendorRuleForm = ({ formInstance, vendor, onSubmit }: VendorRuleFormInterface) => {
    const { t } = useTranslation()

    // states relevant for the payment term handling
    const [paymentTermRadio, setPaymentTermRadio] = useState<number | undefined>(undefined)
    const [paymentTermInput, setPaymentTermInput] = useState<number | undefined>(undefined)

    const costCenters = useSubCostCenters(true)
    const costCenters2 = useCostCenters2()
    const expenseAccounts = useExpenseAccounts()

    const { showCostCentersIds } = useCompany()

    useEffect(() => {
        // check if payment term is one of the options
        const currentPaymentTerm: VendorRulePaymentTermEnum | number = formInstance.getFieldsValue().paymentTerm
        const paymentTermIsInOptions = paymentTermOptions.filter((paymentTermParam: number) => paymentTermParam === currentPaymentTerm).length > 0
        const handler = paymentTermIsInOptions ? onPaymentTermRadioChange : onPaymentTermInputChange
        handler(vendor?.rule?.paymentTerm)
    }, [vendor?.rule?.paymentTerm])

    // handle values to show payment term on radio button or input
    const onPaymentTermRadioChange = (paymentTerm?: number) => {
        setPaymentTermInput(undefined)
        setPaymentTermRadio(paymentTerm)
    }

    const onPaymentTermInputChange = (paymentTerm?: number) => {
        setPaymentTermRadio(undefined)
        setPaymentTermInput(paymentTerm)
    }

    const paymentStatusList: Array<FormSelectOption> = createDynamicList("input:request.payment_flow_information", [
        ExpensePaymentFlowInformationEnum.ALREADY_PAID,
        ExpensePaymentFlowInformationEnum.ALREADY_APPROVED_AND_PAID,
        ExpensePaymentFlowInformationEnum.REPORTING_ONLY,
    ])

    const handleFinish = (values: Store) => {
        values.paymentTerm = paymentTermRadio || paymentTermInput
        const formValues = handleEmptyValues(values, ["costCenter", "costCenter2", "expenseAccount"])
        formValues.discounts = values.discounts.filter((discount: DiscountForForm) => discount.percentage && discount.period)
        onSubmit(formValues)
    }

    return (
        <Form layout="vertical" form={formInstance} onFinish={handleFinish} scrollToFirstError>
            <FormSelect name="costCenter" header={t("input:request.cost_center")} options={getDynamicCostCenterList(costCenters, showCostCentersIds)} fallback showSearch />

            {costCenters2.length > 0 && <FormSelect name="costCenter2" header={t("label:cost_center_2")} options={getDynamicCostCenter2List(costCenters2)} fallback showSearch />}
            {expenseAccounts.length > 0 && (
                <FormSelect name="expenseAccount" header={t("label:expense_account")} options={getDynamicExpenseAccountList(expenseAccounts)} fallback showSearch />
            )}

            <FormSelect name="expensePaymentFlowInformation" header={t("label:payment_flow_information.title")} options={paymentStatusList} fallback showSearch />

            <Row gutter={[16, 16]}>
                <Col span={24} sm="24">
                    <Form.Item label={t("label:payment_term_desc")} key="paymentTerm" name={"paymentTerm"} rules={VendorRules().paymentTerm}>
                        <div className="custom-payment-term-radio flex flex-row">
                            <Radio.Group
                                className="flex-col min-w-300"
                                onChange={(e) => {
                                    onPaymentTermRadioChange(e.target.value)
                                }}
                                value={paymentTermRadio}
                                optionType="button"
                            >
                                {paymentTermOptions.map((value, index) => (
                                    <Radio.Button key={value} value={value}>
                                        {value}
                                    </Radio.Button>
                                ))}
                            </Radio.Group>
                            <Input
                                className="ml-20 text-center"
                                type="number"
                                min={MIN_PAYMENT_TERM_DAYS}
                                max={MAX_PAYMENT_TERM_DAYS}
                                value={paymentTermInput}
                                onChange={(e) => {
                                    onPaymentTermInputChange(e.target.value ? Number(e.target.value) : undefined)
                                }}
                                bordered={false}
                            />
                        </div>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[16, 16]}>
                <Form.List name="discounts">
                    {(fields) => () =>
                        fields.map((field, index) => {
                            const discountFormRules = DiscountFormRules(formInstance, ["discounts", index])
                            return (
                                <>
                                    <Col span={10}>
                                        <Form.Item
                                            label={t("label:vendor_discount.discount")}
                                            name={[field.name, "percentage"]}
                                            rules={discountFormRules.percentage}
                                            dependencies={[["discounts", index, "period"]]}
                                        >
                                            <NumericFormat decimalScale={2} className="ant-input-number-input" min={0} max={MAX_DISCOUNT_PERCENTAGE} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={2}>
                                        <div className="mt-40">%</div>
                                    </Col>
                                    <Col span={9}>
                                        <Form.Item
                                            name={[field.name, "period"]}
                                            label=" "
                                            className="flex"
                                            rules={discountFormRules.period}
                                            dependencies={[["discounts", index, "percentage"]]}
                                        >
                                            <NumericFormat className="ant-input-number-input" min={MIN_DISCOUNT_DURATION_DAYS} max={MAX_DISCOUNT_DURATION_DAYS} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <div className="mt-40">{t("label:vendor_discount.days")}</div>
                                    </Col>
                                </>
                            )
                        })}
                </Form.List>
            </Row>
        </Form>
    )
}

export default VendorRuleForm
