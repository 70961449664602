import { Rule } from "antd/lib/form"
import i18n from "i18next"

import { parseCurrencyInput } from "Components/currencyInput/config"
import { isValidAccountNumber, isValidBIC, isValidIBAN, isValidSortCode } from "Shared/utils/helper.utils"

export const WalletRules = (): { [key: string]: Array<Rule> } => ({
    required: [{ required: true, message: i18n.t("validation:required") }],
    amount: [
        { required: true, message: i18n.t("validation:required") },
        {
            validator: (_rule: any, inputValue: string) => {
                const inputValueString = inputValue ? inputValue.toString() : ""
                if (inputValueString !== "") {
                    const inputValueParsed = parseCurrencyInput(inputValue)
                    if (inputValueParsed <= 0) {
                        return Promise.reject(new Error(i18n.t("validation:greater.number", { min: 0 })))
                    }

                    return Promise.resolve()
                }

                return Promise.reject(new Error(i18n.t("validation:not_zero")))
            },
        },
    ],
    description: [
        { required: true, message: i18n.t("validation:required") },
        {
            max: 35,
            message: i18n.t("validation:max.string", { max: 35 }),
        },
    ],
    countryCode: [
        {
            validator: (_rule: any, value: string) => {
                if (value && value !== "") {
                    if (value.length !== 2) return Promise.reject(new Error(i18n.t("validation:exact.string", { num: 2 })))
                    if (!/[A-Z]{2}/.test(value)) return Promise.reject(new Error(i18n.t("validation:only_uppercase")))
                }
                return Promise.resolve()
            },
        },
    ],
    iban: [
        {
            validator: (_rule: any, value: string) => {
                if (!value) {
                    return Promise.reject(i18n.t("validation:required"))
                }
                if (value && value !== "" && !isValidIBAN(value)) {
                    return Promise.reject(new Error(i18n.t("validation:iban")))
                }
                return Promise.resolve()
            },
        },
    ],
    bic: [
        {
            validator: (_rule: any, value: string) => {
                if (!value) {
                    return Promise.reject(i18n.t("validation:required"))
                }
                if (value && value !== "" && !isValidBIC(value)) {
                    return Promise.reject(new Error(i18n.t("validation:bic")))
                }
                return Promise.resolve()
            },
        },
    ],
    accountNumber: [
        {
            validator: (_rule: any, value: string) => {
                if (!value) {
                    return Promise.reject(i18n.t("validation:required"))
                }
                if (value && value !== "" && !isValidAccountNumber(value)) {
                    return Promise.reject(new Error(i18n.t("validation:account_number")))
                }
                return Promise.resolve()
            },
        },
    ],
    sortCode: [
        {
            validator: (_rule: any, value: string) => {
                if (!value) {
                    return Promise.reject(i18n.t("validation:required"))
                }
                if (value && value !== "" && !isValidSortCode(value)) {
                    return Promise.reject(new Error(i18n.t("validation:sort_code")))
                }
                return Promise.resolve()
            },
        },
    ],
})
