import { Employee } from "@finway-group/shared/lib/models"
import { Action } from "redux"

export enum EmployeeActionTypes {
    FETCH_ALL_EMPLOYEES = "FETCH_ALL_EMPLOYEES",
    FETCH_ONE_EMPLOYEE = "FETCH_ONE_EMPLOYEE",
    SYNC_EMPLOYEE = "SYNC_EMPLOYEE",
    NEW_USER = "NEW_EMPLOYEE", // sys admin action
    UPDATE_EMPLOYEE = "UPDATE_EMPLOYEE",
    UPDATE_USER = "UPDATE_USER", // sys admin action
    SET_ACTIVE_EMPLOYEE_PAGE_TAB = "SET_ACTIVE_EMPLOYEE_PAGE_TAB",
}

export interface FetchAllEmployeesAction extends Action<typeof EmployeeActionTypes.FETCH_ALL_EMPLOYEES> {
    type: typeof EmployeeActionTypes.FETCH_ALL_EMPLOYEES
    employees: Array<Employee>
}

export interface FetchOneEmployeeAction extends Action<typeof EmployeeActionTypes.FETCH_ONE_EMPLOYEE> {
    type: typeof EmployeeActionTypes.FETCH_ONE_EMPLOYEE
    employee: Employee
}

export interface SyncEmployeeAction extends Action<typeof EmployeeActionTypes.SYNC_EMPLOYEE> {
    type: typeof EmployeeActionTypes.SYNC_EMPLOYEE
    employee: Employee
}

export interface NewUserAction extends Action<typeof EmployeeActionTypes.NEW_USER> {
    type: typeof EmployeeActionTypes.NEW_USER
    employee: Employee
}

export interface UpdateUserAction extends Action<typeof EmployeeActionTypes.UPDATE_USER> {
    type: typeof EmployeeActionTypes.UPDATE_USER
    employee: Employee
}

export interface UpdateEmployeeAction extends Action<typeof EmployeeActionTypes.UPDATE_EMPLOYEE> {
    type: typeof EmployeeActionTypes.UPDATE_EMPLOYEE
    employee: Employee
}

export type EmployeeActions = FetchAllEmployeesAction | FetchOneEmployeeAction | SyncEmployeeAction | NewUserAction | UpdateUserAction | UpdateEmployeeAction
