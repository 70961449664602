import { LoadingOutlined } from "@ant-design/icons"
import { ExpenseTagColorEnum } from "@finway-group/shared/lib/models"
import { Tag } from "antd"
import React from "react"

import Icon from "Components/icon"
import { EXPENSE_TAG_ICON_COLORS } from "Shared/config/consts"

interface ExpenseTagPropInterface {
    color: ExpenseTagColorEnum
    isLoading?: boolean
    children?: React.ReactNode
    className?: string
    [x: string]: any
}

export const EXPENSE_TAG_CLASS_NAMES = {
    [ExpenseTagColorEnum.RED]: "ant-tag-red",
    [ExpenseTagColorEnum.GREEN]: "ant-tag-green",
    [ExpenseTagColorEnum.BLUE]: "ant-tag-blue",
    [ExpenseTagColorEnum.YELLOW]: "ant-tag-yellow",
    [ExpenseTagColorEnum.PURPLE]: "ant-tag-purple",
}

const ExpenseTag: React.FC<ExpenseTagPropInterface> = ({ color, children, className, isLoading, ...restProps }) => (
    <Tag
        {...restProps}
        className={`${EXPENSE_TAG_CLASS_NAMES[color]} inline-flex items-center ${className}`}
        icon={<Icon className="pr-4" color={EXPENSE_TAG_ICON_COLORS[color]} icon="expense_tag" />}
    >
        <div className="flex">
            {children}
            {isLoading && <LoadingOutlined className="ml-4 align-middle" />}
        </div>
    </Tag>
)

export default ExpenseTag
