import { Currencies } from "@finway-group/shared/lib/consts"
import { CurrencyEnum, currencyEnumArray } from "@finway-group/shared/lib/models"
import { MAX_INPUT_LENGTH } from "@finway-group/shared/lib/utils/validators"
import { Col, DatePicker, Form, Image, Input, Row, Select, Switch } from "antd"
import moment from "moment"
import React from "react"
import { useTranslation } from "react-i18next"

import { useExpenseFormContext } from "Components/modals/expenseCreateForm.context"
import { gobdNonCompliantDates } from "Shared/utils/date.utils"
import { isExpenseBillableAccessible } from "Shared/utils/expense.utils"
import { doFilterSelect } from "Shared/utils/helper.utils"
import { getPopupAnchor } from "Shared/utils/popup.utils"

import { ExpenseRules } from "../rules"
import TotalAmountForm from "./commonInputFields/totalAmount.form"

interface OneTimeExpenseInterface {}
const OneTimeExpenseFields: React.FC<OneTimeExpenseInterface> = () => {
    const [expenseFormContext] = useExpenseFormContext()
    const { expense, updateExpense, expenseForm, type, isNew, isLinked, setIsLinked } = expenseFormContext

    const { description } = ExpenseRules({ expense })
    const { t } = useTranslation()
    const showBillable = isExpenseBillableAccessible(expense)

    const BILLABLE_COL_SPAN = 9

    return (
        <>
            <TotalAmountForm expense={expense} updateExpense={updateExpense} isLinked={isLinked} setIsLinked={setIsLinked} type={type} isNew={isNew} expenseForm={expenseForm} />

            <Row gutter={[16, 16]} align="middle">
                <Col span={24 - (showBillable ? BILLABLE_COL_SPAN : 0)} sm="24" md="24">
                    <Form.Item name="description" label={t("input:request.description")} key="description" rules={description}>
                        <Input.TextArea
                            data-testid="descriptionExpenseField"
                            className="leading-normal pt-4"
                            autoSize
                            rows={1}
                            maxLength={MAX_INPUT_LENGTH}
                            name="description"
                            placeholder={t("placeholder:request.description")}
                            onChange={(e) => updateExpense({ description: e.target.value })}
                        />
                    </Form.Item>
                </Col>
                {showBillable && (
                    <Col span={BILLABLE_COL_SPAN}>
                        <Form.Item label={t("input:request.billable")} name="billable" valuePropName="checked" className="content-end">
                            <Switch onChange={(checked) => updateExpense({ billable: checked })} />
                        </Form.Item>
                    </Col>
                )}
            </Row>
            <Row gutter={[16, 16]} align="middle" justify="space-between">
                <Col span={12}>
                    <Form.Item data-testid="performancePeriod" label={t("label:performance_period")} name="performancePeriod" className="text-opacity-50">
                        <DatePicker.RangePicker
                            name="performancePeriod"
                            disabledDate={gobdNonCompliantDates}
                            style={{ width: "100%" }}
                            format={moment.localeData().longDateFormat("L")}
                            placeholder={[t("placeholder:start_date"), t("placeholder:end_date")]}
                            getPopupContainer={getPopupAnchor()}
                            onChange={(values) => updateExpense({ performancePeriod: values })}
                        />
                    </Form.Item>
                </Col>
                <Col span={11} sm="24" md="24">
                    <Form.Item name="currency" label={t("input:request.currency")} key="currency" initialValue={CurrencyEnum.EUR}>
                        <Select showSearch filterOption={doFilterSelect} getPopupContainer={getPopupAnchor()} onChange={(v: CurrencyEnum) => updateExpense({ currency: v })}>
                            {currencyEnumArray.map((currencyValue, index) => (
                                <Select.Option key={index} value={currencyValue} label={currencyValue}>
                                    <div className="space-x-10">
                                        <Image className="align-middle" width={20} preview={false} src={`data:image/png;base64,${Currencies[currencyValue].flag}`} />
                                        <span className="text-text">{currencyValue}</span>
                                        <span className="text-text">-</span>
                                        <span className="text-text">{Currencies[currencyValue].name}</span>
                                    </div>
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
        </>
    )
}

export default OneTimeExpenseFields
