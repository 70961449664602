import Icon from "@ant-design/icons"
import React from "react"

const cornerDownRightArrowSvg = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 6.66663L13.3333 9.99996L10 13.3333" stroke="#A1B1C1" strokeLinecap="round" strokeLinejoin="round" />
        <path
            d="M2.66663 2.66663V7.33329C2.66663 8.04054 2.94758 8.71881 3.44767 9.21891C3.94777 9.71901 4.62605 9.99996 5.33329 9.99996H13.3333"
            stroke="#A1B1C1"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)

const CornerDownRightArrowIcon = (props: any) => <Icon component={cornerDownRightArrowSvg} {...props} />

export default CornerDownRightArrowIcon
