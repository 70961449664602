import { LiquidityScenario } from "@finway-group/shared/lib/models"
import { MAX_INPUT_LENGTH } from "@finway-group/shared/lib/utils/validators"
import { Input, Radio, Select } from "antd"
import Form, { FormInstance } from "antd/lib/form"
import { Store } from "antd/lib/form/interface"
import { RadioChangeEvent } from "antd/lib/radio"
import React from "react"
import { useTranslation } from "react-i18next"

import { useScenario, useScenarios } from "Shared/hooks/liquidity.hooks"
import { doFilterSelect, isNotSet } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"
import { getPopupAnchor } from "Shared/utils/popup.utils"

interface ScenarioFormInterface {
    formInstance: FormInstance
    onSubmit: (values: Store) => void
    isNew: boolean
}

enum ScenarioTypeEnum {
    MAIN_SCENARIO = "MAIN_SCENARIO",
    SUB_SCENARIO = "SUB_SCENARIO",
}

const ScenarioForm = ({ formInstance, onSubmit, isNew }: ScenarioFormInterface) => {
    const { t } = useTranslation()
    const scenarios = useScenarios()
    const selectedScenario = useScenario()

    const [isSubScenario, setIsSubScenario] = useStateIfMounted(!isNotSet(selectedScenario?.parentScenario) && !isNew)

    const onKindChange = ({ target: { value } }: RadioChangeEvent) => setIsSubScenario(value === ScenarioTypeEnum.SUB_SCENARIO)

    return (
        <Form id="scenario-form" form={formInstance} layout="vertical" onFinish={onSubmit} autoComplete="off">
            <Form.Item name="kind">
                <div className="card-modal w-full sm:w-3/4">
                    <Radio.Group defaultValue={isSubScenario ? ScenarioTypeEnum.SUB_SCENARIO : ScenarioTypeEnum.MAIN_SCENARIO} onChange={onKindChange}>
                        <Radio.Button value={ScenarioTypeEnum.MAIN_SCENARIO}>{t("input:liquidity.scenario.main")}</Radio.Button>
                        <Radio.Button value={ScenarioTypeEnum.SUB_SCENARIO}>{t("input:liquidity.scenario.sub")}</Radio.Button>
                    </Radio.Group>
                </div>
            </Form.Item>
            <Form.Item name="name" label={t("input:liquidity.scenario.name")} validateTrigger={"onSubmit"} shouldUpdate>
                <Input maxLength={MAX_INPUT_LENGTH} placeholder={t("placeholder:liquidity.scenario_name")} />
            </Form.Item>

            {isSubScenario && (
                <Form.Item name="parentScenario" label={t("input:liquidity.scenario.parent")} extra={<p className="input-help">{t("info:liquidity_scenario")}</p>}>
                    <Select showSearch filterOption={doFilterSelect} getPopupContainer={getPopupAnchor()} placeholder={t("placeholder:liquidity.select_a_scenario")}>
                        {scenarios
                            .filter((scenario: LiquidityScenario) => !scenario.parentScenario && (isNew || scenario._id !== selectedScenario?._id))
                            .map(({ _id, name }, index) => (
                                <Select.Option key={index} value={_id} label={name} id={`scenario-option-${index}`}>
                                    {name}
                                </Select.Option>
                            ))}
                    </Select>
                </Form.Item>
            )}
        </Form>
    )
}

export default React.memo(ScenarioForm)
