import { ExpenseKindEnum, Mileage, PriceIntervalEnum, VehicleEnum } from "@finway-group/shared/lib/models"
import MileageLocationInterface from "@finway-group/shared/lib/models/expense/mileageLocation.interface"
import { MAX_INPUT_LENGTH } from "@finway-group/shared/lib/utils/validators"
import { Alert, Button, Col, DatePicker, Divider, Form, Input, Row, Select, Switch, Tooltip } from "antd"
import moment from "moment"
import numeral from "numeral"
import React, { useEffect } from "react"
import { AlertTriangle, MapPin, Trash } from "react-feather"
import { useTranslation } from "react-i18next"

import { ExpenseRules } from "Components/forms/rules"
import { MileageRules } from "Components/forms/rules/mileage.rules"
import AlertTriangleIcon from "Components/icons/alertTriangleIcon"
import { useExpenseFormContext } from "Components/modals/expenseCreateForm.context"
import PriceLabel from "Components/priceLabel"
import { useCompany } from "Shared/hooks/company.hooks"
import { useEmployees } from "Shared/hooks/employee.hooks"
import { ExpenseHttpService } from "Shared/services"
import { gobdNonCompliantDates } from "Shared/utils/date.utils"
import { getDefaultInvoiceNumber, isMileageExpense } from "Shared/utils/expense.utils"
import { getTooltipPopupContainer, isSet } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"
import useUpdateEffect from "Shared/utils/hooks/useUpdateEffect"
import { calculateExpensePriceFromDistanceAndVehicle, convertMeterToKilometer, isDistanceOverThreshold } from "Shared/utils/mileage.utils"
import { getPopupAnchor } from "Shared/utils/popup.utils"

import ExpenseCommonInputFieldsForm from "../../commonInputFields/commonInputFields.form"
import ExpenseFormLayout from "../../expenseFormLayout"
import { HoverableInfo } from "../auxilliary/HoverableInfo"
import ReimbursementTypeSelector from "../reimbursementTypeSelector"
import AddressAutocomplete, { LocationForForm } from "./mapAuxilliary/addressAutocomplete"
import MileageRequestSummary from "./mileageRequestSummary"

type OnLocationUpdateData = {
    index: number
    value?: LocationForForm
}

type MileageStop = Mileage["stops"][0]

interface MileageRequestFormInterface {}
export const MileageRequestForm: React.FC<MileageRequestFormInterface> = ({}) => {
    const [{ expense, expenseForm, updateExpense, isNew }] = useExpenseFormContext()
    const company = useCompany()
    if (!isMileageExpense(expense)) {
        return <ReimbursementTypeSelector />
    }

    const distanceTolerance = company.mileageDistanceBuffer ?? 20

    const [shouldMapReact, setShouldMapReact] = useStateIfMounted(false)
    const { t } = useTranslation()
    const employees = useEmployees()
    const { invoiceNumber } = ExpenseRules({ expense, employees })

    // Not using useEmployeeById as it doesnt populate activeCompanyProfile.
    const requester = employees.find((e) => e.id === expense.requestedBy._id)

    const isDistanceOverExpectedDistanceThreshold = isDistanceOverThreshold(expense, distanceTolerance)
    // If it has an additionalStopReason, that means the user has filled out the reason form, so no need to pop the warning out anymore
    const [hasUserNoticedThresholdAlert, setHasUserNoticedThresholdAlert] = useStateIfMounted(!!expense.additionalStopReason)

    const rules = MileageRules(expense, isDistanceOverExpectedDistanceThreshold, hasUserNoticedThresholdAlert)

    const syncExpenseWithForm = () => {
        // Setting shouldMapReact to true to let the map adjust the distance when there is user interaction
        setShouldMapReact(true)

        // Sync the expense.stops with the form values (to see if anything is created, deleted, or updated)
        updateExpense({
            stops: expenseForm.getFieldValue(["stops"]),
        })
    }

    const onLocationUpdate = (updateData: OnLocationUpdateData) => {
        // Update the form values manually (need to do this - see addressAutocomplete.tsx known bug)
        // map {label, coordinates} (needed by autocomplete) into {address, coordinates} (needed by our API data structure) for compatibility
        const mileageStop = {
            address: updateData.value?.label,
            coordinates: updateData.value?.coordinates,
        } as MileageStop
        expenseForm.setFields([
            {
                name: ["stops", updateData.index],
                value: mileageStop,
            },
        ])
    }

    const onMapDistanceUpdate = (newDistance: number) => {
        applyExpensePriceAndDistanceValue(newDistance, expense.vehicle, true)
    }

    const onActualDistanceUpdate = (e: any) => {
        // Remove this when we add proper validation on 6.1
        const distanceWithoutComma = isNaN(e) ? e.target.value.toString().replace(",", ".") : e.target.value
        // If actual distance is manually updated, do not update the expected distance.
        applyExpensePriceAndDistanceValue(distanceWithoutComma, expense.vehicle, false)
    }

    const onVehicleUpdate = (newVehicle: VehicleEnum) => {
        applyExpensePriceAndDistanceValue(expense.actualDistance, newVehicle, false)
    }

    const applyExpensePriceAndDistanceValue = (distance: number, vehicle: VehicleEnum, updateExpectedDistance = false, isRequesterUpdate = false) => {
        // If its new expense, use requester custom mileage rate if it exists. If its an expense update action, utilize the expense's custom mileage
        // that has been set previously. Expcept when the requester is updated, then get the value from the requester again.
        const rateOverride = isNew || isRequesterUpdate ? requester?.activeCompanyProfile?.mileageRate : (expense as any).customMileageRate
        const price = calculateExpensePriceFromDistanceAndVehicle(vehicle, distance, rateOverride)

        const updateObject: any = {
            ...price,
            vehicle,
            customMileageRate: rateOverride,
        }

        if (!isNaN(distance)) {
            updateObject.actualDistance = distance
            if (updateExpectedDistance) updateObject.expectedDistance = distance
        }

        updateExpense(updateObject)
    }

    // Use effect on requester update to recalculate rates.
    useUpdateEffect(() => {
        if (expense.actualDistance && isSet(expense.vehicle)) {
            applyExpensePriceAndDistanceValue(expense.actualDistance, expense.vehicle, false, true)
        }
    }, [expense.requestedBy])

    useEffect(() => {
        let mounted = true
        if (!expenseForm.getFieldValue(["invoiceNumber"]) || isNew) {
            ExpenseHttpService.getNextExpenseNumber()
                .then((result) => {
                    if (mounted) {
                        const invoiceNumber = getDefaultInvoiceNumber(ExpenseKindEnum.MILEAGE, result)
                        expenseForm.setFieldsValue({ invoiceNumber })
                        updateExpense({ invoiceNumber })
                    }
                })
                // Silent error catch
                .catch()
        }
        return () => {
            mounted = false
        }
    }, [])

    const getStopString = (index: number, length: number) => {
        if (index === 0) {
            return t(`input:request.mileage.start_address`)
        }
        if (index === length - 1) {
            return t(`input:request.mileage.destination_address`)
        }
        return t(`input:request.mileage.additional_stop${length > 1 ? "_x" : ""}`, { nth: numeral(index).format("0o") })
    }

    const getAutocompleteValue = (value: MileageLocationInterface) => {
        if (value) {
            const { address, coordinates } = value
            return { label: address, value: address, coordinates }
        }

        return value
    }

    const rightSide = (
        <>
            <ReimbursementTypeSelector />
            <Row gutter={[16, 16]} align="middle">
                <Col xs={12}>
                    <Form.Item
                        className="max-w-160 md:max-w-full"
                        label={t("input:request.mileage.invoice_number")}
                        name="invoiceNumber"
                        initialValue={expense.invoiceNumber}
                        rules={invoiceNumber}
                        required={expense.isAtLeastApproved()}
                    >
                        <Input placeholder={t("placeholder:request.invoiceNumber")} onChange={(e) => updateExpense({ invoiceNumber: e.target.value })} />
                    </Form.Item>
                </Col>
                <Col xs={12}>
                    <Form.Item
                        className="max-w-160 md:max-w-full"
                        label={
                            <>
                                {t("input:request.mileage.date")} <HoverableInfo message={t("info:mileage.date")} type="info" />
                            </>
                        }
                        name={["datePurchased"]}
                        key="datePurchased"
                        rules={rules.mileageDate}
                        required={expense.isAtLeastApproved()}
                    >
                        <DatePicker
                            className="w-full"
                            format={moment.localeData().longDateFormat("L")}
                            placeholder={t("placeholder:request.date")}
                            getPopupContainer={getPopupAnchor()}
                            onChange={(date) => {
                                updateExpense({ datePurchased: date as any, invoiceDate: date as any }) // datePurchased/invoiceDate is Date, but antd form can only take moment.
                            }}
                            disabledDate={(date: moment.Moment) => date.isAfter(moment(), "day") || gobdNonCompliantDates(date)}
                        />
                    </Form.Item>
                </Col>
                {isDistanceOverExpectedDistanceThreshold && !hasUserNoticedThresholdAlert && (
                    <Col span={24}>
                        <Alert
                            showIcon
                            type="error"
                            message={
                                <div>
                                    {t("info:mileage.above_threshold_prompt.1", { distance: distanceTolerance })}{" "}
                                    <Button className="button-warning-notification ml-4" type="text" onClick={() => setHasUserNoticedThresholdAlert(true)}>
                                        {t("info:mileage.above_threshold_prompt.2")}
                                    </Button>{" "}
                                    {t("info:mileage.above_threshold_prompt.3")}
                                </div>
                            }
                        />
                    </Col>
                )}
                <Col span={24}>
                    <Form.Item
                        className="max-w-160 md:max-w-full"
                        label={t("input:request.mileage.additional_distance_reason")}
                        name={["additionalStopReason"]}
                        key="additionalStopReason"
                        initialValue={expense.additionalStopReason}
                        hidden={!(isDistanceOverExpectedDistanceThreshold && hasUserNoticedThresholdAlert)}
                        rules={rules.additionalStopReason}
                    >
                        <Input maxLength={MAX_INPUT_LENGTH} size="small" placeholder={t("placeholder:request.mileage.additional_distance_reason")} />
                    </Form.Item>
                </Col>
                <Form.List key="stops" name={["stops"]}>
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map((field, i) => {
                                const showDeleteButton = i !== 0 && fields.length > 2
                                return (
                                    <React.Fragment key={`additional-stop-item-${i}`}>
                                        <Col xs={showDeleteButton ? 21 : 22} className={showDeleteButton ? "pr-20" : ""}>
                                            <AddressAutocomplete
                                                {...field}
                                                rules={rules.stops}
                                                label={getStopString(i, fields.length)}
                                                placeholder={t("placeholder:request.mileage.additional_stop")}
                                                onSelect={(selectedLocation) => {
                                                    onLocationUpdate({ index: i, value: selectedLocation })
                                                    syncExpenseWithForm()
                                                }}
                                                getValueProps={getAutocompleteValue}
                                            />
                                        </Col>
                                        <Col span={showDeleteButton ? 3 : 2}>
                                            <div className="flex gap-5 mt-6 justify-end">
                                                <div>
                                                    <Tooltip getPopupContainer={getTooltipPopupContainer} title={t("tooltips:mileage.add_stop")}>
                                                        <Button
                                                            className="btn-default"
                                                            onClick={() => {
                                                                add(null, i + 1)
                                                                syncExpenseWithForm()
                                                            }}
                                                        >
                                                            <MapPin />
                                                        </Button>
                                                    </Tooltip>
                                                </div>

                                                {showDeleteButton && (
                                                    <div>
                                                        <Button
                                                            className="btn-default"
                                                            onClick={() => {
                                                                remove(i)
                                                                syncExpenseWithForm()
                                                            }}
                                                        >
                                                            <Trash />
                                                        </Button>
                                                    </div>
                                                )}
                                            </div>
                                        </Col>
                                    </React.Fragment>
                                )
                            })}
                        </>
                    )}
                </Form.List>

                <Col xs={12}>
                    <Form.Item className="max-w-160 md:max-w-full" label={t("input:request.mileage.vehicle")} name={["vehicle"]} initialValue={VehicleEnum.CAR}>
                        <Select
                            onSelect={(e: VehicleEnum) => onVehicleUpdate(e)}
                            showSearch
                            getPopupContainer={getPopupAnchor()}
                            placeholder={t("placeholder:request.mileage.select_a_vehicle")}
                        >
                            <Select.Option key={0} value={VehicleEnum.CAR} label={t("input:request.mileage.vehicle_type.car")} id={`vehicle-option-0`}>
                                {t("input:request.mileage.vehicle_type.car")}
                            </Select.Option>
                            <Select.Option key={1} value={VehicleEnum.MOTORCYCLE} label={t("input:request.mileage.vehicle_type.motorcycle")} id={`vehicle-option-1`}>
                                {t("input:request.mileage.vehicle_type.motorcycle")}
                            </Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={12}>
                    <Form.Item
                        className="max-w-160 md:max-w-full"
                        label={
                            <>
                                {t("input:request.mileage.distance")}{" "}
                                {isDistanceOverExpectedDistanceThreshold && (
                                    <Tooltip getPopupContainer={getTooltipPopupContainer} title={t("info:mileage.above_threshold", { distance: distanceTolerance })}>
                                        <AlertTriangleIcon className="mt-2 ml-6" />
                                    </Tooltip>
                                )}
                                <HoverableInfo message={t("tooltips:mileage.distance")} type="info" />
                            </>
                        }
                        name={["actualDistance"]}
                        key="actualDistance"
                        initialValue={expense.actualDistance}
                        rules={rules.actualDistance}
                    >
                        <Input maxLength={MAX_INPUT_LENGTH} size="small" placeholder={t("placeholder:request.mileage.distance")} onChange={onActualDistanceUpdate} suffix="km" />
                    </Form.Item>
                </Col>

                <Col span={15}>
                    <Form.Item label={t("input:request.mileage.reason")} name={["tripReason"]} key="tripReason" rules={rules.tripReason} initialValue={expense.invoiceNumber}>
                        <Input
                            placeholder={t("placeholder:request.mileage.reason")}
                            onChange={(e) => {
                                updateExpense({ tripReason: e.target.value })
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col span={9}>
                    <Form.Item label={t("input:request.billable")} name="billable" valuePropName="checked" className="content-end">
                        <Switch onChange={(checked) => updateExpense({ billable: checked })} />
                    </Form.Item>
                </Col>
                <Col span={24} className="hidden">
                    <Form.Item key="totalNetPrice" name="totalNetPrice" hidden>
                        <Input name="totalNetPrice" />
                    </Form.Item>
                    <Form.Item key="totalGrossPrice" name="totalGrossPrice" hidden>
                        <Input name="totalGrossPrice" />
                    </Form.Item>
                    <Form.Item key="totalTaxPrice" name="totalTaxPrice" hidden>
                        <Input name="totalTaxPrice" />
                    </Form.Item>
                </Col>
                <Col xs={24}>
                    <div className="w-full flex items-center justify-end gap-8">
                        {isSet((expense as any).customMileageRate) && (
                            <Tooltip
                                getPopupContainer={getTooltipPopupContainer}
                                title={t("info:employee_mileage_rate_apply", { cent_rate: (expense as any).customMileageRate * 100 })}
                            >
                                <AlertTriangle fill="#FBAC3B" size={18} stroke="#FFFFFF" className="mt-2" />
                            </Tooltip>
                        )}
                        <div>{t("input:request.mileage.total_calculated_amount")}</div>
                        <div className="font-bold">
                            <PriceLabel value={expense.totalGrossPrice} currency={expense.currency} interval={PriceIntervalEnum.ONE_TIME} />
                        </div>
                        <HoverableInfo message={t("tooltips:mileage.total_calculated_amount")} type="info" />
                    </div>
                </Col>
            </Row>
            <Divider />
            <Row>
                <Col span={24}>
                    <Form.Item label={t("label:performance_period")} name="performancePeriod" className="text-opacity-50">
                        <DatePicker.RangePicker
                            name="performancePeriod"
                            style={{ width: "100%" }}
                            format={moment.localeData().longDateFormat("L")}
                            placeholder={[t("placeholder:start_date"), t("placeholder:end_date")]}
                            getPopupContainer={getPopupAnchor()}
                            disabledDate={(date: moment.Moment) => date.isAfter(moment(), "day") || gobdNonCompliantDates(date)}
                            onChange={(value) => updateExpense({ performancePeriod: value })}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <ExpenseCommonInputFieldsForm />
        </>
    )

    const leftSide = (
        <MileageRequestSummary
            expense={expense}
            interactive={true}
            updateRoute={(route) => {
                if (shouldMapReact) {
                    const adjustedDistance = convertMeterToKilometer(route.distance).toFixed(2)
                    onMapDistanceUpdate(Number(adjustedDistance))
                    updateExpense({ route } as any)
                }
            }}
        />
    )

    return <ExpenseFormLayout leftSide={leftSide} rightSide={rightSide} />
}
