import Row from "antd/lib/row"
import moment from "moment"
import React, { useRef, useState } from "react"

import { isDateOutOfRange } from "Shared/utils/date.utils"
import { CollapseWrapper } from "Shared/wrapper/collapse.wrapper"

import { FormCategoryEnum, FormMinMaxDateInterface } from "./form.types"
import FormInputDate from "./formInputDate"

export default function FormMinMaxDate({
    header,
    min,
    max,
    keyCode = "",
    extra = <></>,
    picker = "date",
    dateFormat,
    disableDateOption = false,
    isCollapse = true,
    allowClear = true,
}: FormMinMaxDateInterface) {
    const defaultDisabledMinDateFunction = (current: moment.Moment) => isDateOutOfRange(current) || current.isBefore(min.initialValue)
    const defaultDisabledMaxDateFunction = (current: moment.Moment) => isDateOutOfRange(current) || current.isAfter(max.initialValue)
    const anchorRef = useRef(null)

    const [activeCategory, setActiveCategory] = useState({
        isMin: !!min.initialValue,
        isMax: !!max.initialValue,
    })

    return (
        <>
            {/* empty div to fix ant-design bug for non-sticky dropdowns */}
            <div ref={anchorRef} />
            <CollapseWrapper keyCode={keyCode} header={header} isCollapse={isCollapse} isActive={activeCategory.isMin || activeCategory.isMax} extra={extra}>
                <Row gutter={[16, 16]}>
                    <FormInputDate
                        elem={min}
                        activeCategory={activeCategory}
                        setActiveCategory={setActiveCategory}
                        category={FormCategoryEnum.MIN}
                        picker={picker}
                        dateFormat={dateFormat}
                        disabledDateFunction={disableDateOption ? (min.disabledDateFunction ? min.disabledDateFunction : defaultDisabledMinDateFunction) : undefined}
                        disabledMonthFunction={disableDateOption && min.disabledMonthFunction ? min.disabledMonthFunction : undefined}
                        disabledYearFunction={disableDateOption && min.disabledYearFunction ? min.disabledYearFunction : undefined}
                        allowClear={allowClear}
                        anchorRef={anchorRef}
                    />
                    <FormInputDate
                        elem={max}
                        activeCategory={activeCategory}
                        setActiveCategory={setActiveCategory}
                        category={FormCategoryEnum.MAX}
                        picker={picker}
                        dateFormat={dateFormat}
                        disabledDateFunction={disableDateOption ? (max.disabledDateFunction ? max.disabledDateFunction : defaultDisabledMaxDateFunction) : undefined}
                        disabledMonthFunction={disableDateOption ? (max.disabledMonthFunction ? max.disabledMonthFunction : defaultDisabledMaxDateFunction) : undefined}
                        disabledYearFunction={disableDateOption ? (max.disabledYearFunction ? max.disabledYearFunction : defaultDisabledMaxDateFunction) : undefined}
                        allowClear={allowClear}
                        anchorRef={anchorRef}
                    />
                </Row>
            </CollapseWrapper>
        </>
    )
}
