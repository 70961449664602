import LoadingOutlined from "@ant-design/icons/lib/icons/LoadingOutlined"
import { Card, CardModeEnum, PriceIntervalEnum, RightEnum } from "@finway-group/shared/lib/models"
import { Alert, Badge, Button, Empty, Row, Table } from "antd"
import { ColumnsType } from "antd/lib/table"
import moment from "moment"
import React, { Suspense, useState } from "react"
import { Helmet } from "react-helmet"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { useDebouncedCallback } from "use-debounce/lib"

import NoSearchDataFound from "Components/NoSearchDataFound"
import ProgressBar from "Components/ProgressBar"
import UserImage from "Components/UserImage"
import CardStatusTag from "Components/cardStatusTag"
import Filter from "Components/filter/filter"
import FilterSection from "Components/filterSection"
import WalletIcon from "Components/icons/walletIcon"
import WithdrawIcon from "Components/icons/withdrawIcon"
import { ErrorTable } from "Components/layout/errorTable"
import Loading from "Components/loading"
import PriceLabel from "Components/priceLabel"
import SearchInput from "Components/searchInput"
import { isDemo } from "Shared/config/consts"
import { useEmployeeById, useEmployees } from "Shared/hooks/employee.hooks"
import { applyFilter } from "Shared/hooks/filter.hooks"
import { useFetchTable, useTable, useTableFilterAndSearchLoading, useTableSearchString } from "Shared/hooks/table.hooks"
import { useTeams } from "Shared/hooks/team.hooks"
import { RoutePathEnum } from "Shared/router/RoutePath.enum"
import { AuthzService } from "Shared/services"
import { setCreditCard } from "Shared/store/actions/creditCard/creditCardActions"
import { refetchTable, updateTableOnChange, updateTableSearch } from "Shared/store/actions/tables/tableActions"
import { TablesEnum } from "Shared/store/reducers/tableConfigReducer"
import { getCardLimitWithoutBuffer, getLabelForWindow } from "Shared/utils/creditCard.utils"
import { isNotSet } from "Shared/utils/helper.utils"
import { getSortOrderForColumn, isTableFilterApplied } from "Shared/utils/table.utils"

import CardWidget from "./cardWidget"

const CreateCardModal = React.lazy(() => import("Components/modals/createCard.modal"))

interface CreditCardInterface {}

const CreditCardList: React.FC<CreditCardInterface> = () => {
    const { t } = useTranslation()
    const history = useHistory()
    const dispatch = useDispatch()

    const [isCreateCardModalVisible, setIsCreateCardModalVisible] = useState(false)
    const [isFilterModalShowing, setIsFilterModalShowing] = useState<boolean>(false)

    const cardsTable = useTable<Card>(TablesEnum.CARDS)
    const pageLoading = useTableFilterAndSearchLoading(TablesEnum.CARDS)
    const searchString = useTableSearchString(TablesEnum.CARDS)
    useFetchTable(TablesEnum.CARDS)

    const teams = useTeams(true)
    const employees = useEmployees()
    const handleSearch = useDebouncedCallback((searchString) => dispatch(updateTableSearch(TablesEnum.CARDS, searchString)), 800)

    const hasViewWalletRight = AuthzService.canLoggedInUserViewWallet()
    const hasViewCardRight = AuthzService.canLoggedInUserViewTeamCards() || AuthzService.canLoggedInUserViewAllCards()

    const columns: ColumnsType = [
        {
            title: t("label:card"),
            key: "userObject.firstName",
            width: 300,
            className: "pl-10",
            ellipsis: true,
            sortOrder: getSortOrderForColumn(cardsTable, "userObject.firstName"),
            sorter: true,
            render: (card) => {
                const user = useEmployeeById(card.user)
                const expiringSoon = moment(card.expirationDate).isBetween(moment().subtract(1, "w"), moment().add(2, "w"), undefined, "[]")
                return (
                    <div className="flex items-center image-loader gap-10">
                        {user && <UserImage user={user} size="large" />}
                        <div className="flex flex-col truncate">
                            <div className="flex items-center mb-3">
                                <p className="leading-tight mb-0 truncate">
                                    <span className="font-bold">{user?.getFullName()}</span>
                                    <span className="ml-4">({t(`label:cards.type.${card.cardType.toLowerCase()}`)})</span>
                                </p>
                                {expiringSoon && <Badge className="ml-8 h-20 ant-badge-warning">{t("label:expires_soon")}</Badge>}
                            </div>
                            <p className="text-xs text-text leading-none mb-0 truncate">{`*** ${card.last4Digits} (${card.cardName})`}</p>
                        </div>
                    </div>
                )
            },
        },
        {
            title: t("label:cards.mode.mode"),
            key: "mode",
            width: 120,
            sortOrder: getSortOrderForColumn(cardsTable, "mode"),
            sorter: true,
            render: (card) => <span>{t(`label:cards.mode.${card.mode?.toLowerCase()}`)}</span>,
        },
        {
            title: t("label:balance"),
            key: "balance",
            width: 160,
            sortOrder: getSortOrderForColumn(cardsTable, "balance"),
            sorter: true,
            render: (card) =>
                card.mode === CardModeEnum.DEBIT_MODE ? <span>n/a</span> : <PriceLabel value={card.balance} currency={card.currency} interval={PriceIntervalEnum.ONE_TIME} />,
        },
        {
            title: t("label:limit"),
            key: "limit",
            width: 160,
            sortOrder: getSortOrderForColumn(cardsTable, "limit"),

            sorter: true,
            render: (card) => <PriceLabel value={getCardLimitWithoutBuffer(card)} currency={card.currency} interval={PriceIntervalEnum.ONE_TIME} />,
        },
        {
            title: t("label:cards.frequency"),
            key: "window",
            width: 180,
            sortOrder: getSortOrderForColumn(cardsTable, "window"),

            sorter: true,
            render: (card) => getLabelForWindow(card.window),
        },
        {
            title: t("label:status"),
            key: "status",
            width: 180,
            sortOrder: getSortOrderForColumn(cardsTable, "status"),

            sorter: true,
            render: (card) => <CardStatusTag status={card.status} hasError={!isNotSet(card.errorCode)} />,
        },
        {
            title: t("label:spendings"),
            key: "spending",
            width: 220,
            align: "right",
            className: "pr-10",
            sortOrder: getSortOrderForColumn(cardsTable, "spending"),

            sorter: true,
            render: (card) => (
                <div>
                    <Row className="block">
                        <span className="text-text-dark mb-4 leading-none float-left ml-2">
                            <PriceLabel value={card.spending} currency={card.currency} interval={PriceIntervalEnum.ONE_TIME} />
                        </span>

                        <span className="float-right mb-4 text-xs mr-5 opacity-75">
                            <PriceLabel value={card.limit} currency={card.currency} interval={PriceIntervalEnum.ONE_TIME} />
                        </span>
                    </Row>
                    <Row className="block">
                        <ProgressBar
                            total={card.limit || 1}
                            value={card.spending}
                            currency={card.currency}
                            forecastValue={card.spendingForecast || 0}
                            thin
                            hideLabels
                            extended
                            // TODO: what do we want?
                            // showRemaining={GREEN_LIGHT4}
                        />
                    </Row>
                </div>
            ),
        },
    ]

    return (
        <div>
            {(hasViewCardRight || hasViewWalletRight) && (
                <div className="mb-48">
                    <CardWidget />
                </div>
            )}
            <Helmet>
                <title>{t("view:credit_card.title")}</title>
            </Helmet>
            {isDemo && (
                <Alert
                    type="info"
                    showIcon
                    className="mb-20"
                    message={<span className="font-bold">{t("info:cards:demo.title")}</span>}
                    description={t("info:cards:demo.message")}
                />
            )}
            <div className="flex flex-wrap gap-10 justify-between items-center mb-16">
                <h1>{t("view:credit_card.title")}</h1>
                <div className="ml-auto">
                    <div className="flex btn-wrapper">
                        {(cardsTable.data.totalDocs > 0 || searchString) && <SearchInput onSearch={(searchString) => handleSearch.callback(searchString)} value={searchString} />}

                        <Filter
                            table={TablesEnum.CARDS}
                            isFilterModalShowing={isFilterModalShowing}
                            setIsFilterModalShowing={setIsFilterModalShowing}
                            onFilter={(data) => applyFilter(TablesEnum.CARDS, data)}
                            options={{ employees, teams }}
                        />
                        {hasViewWalletRight && (
                            <Button onClick={() => history.push(RoutePathEnum.WALLET_DETAIL_PAGE)}>
                                <WalletIcon />
                                <span>{t("action:wallet.wallet_details")}</span>
                            </Button>
                        )}
                        {(AuthzService.isRightGrantedForLoggedInUser(RightEnum.CARD__ALL__WRITE) || AuthzService.isRightGrantedForLoggedInUser(RightEnum.CARD__TEAM__WRITE)) && (
                            <Button type="primary" onClick={() => setIsCreateCardModalVisible(true)}>
                                <WithdrawIcon style={{ color: "white" }} />
                                <span>{t("creditCard:action.create_new")}</span>
                            </Button>
                        )}
                    </div>

                    <Suspense fallback={<></>}>
                        <CreateCardModal isVisible={isCreateCardModalVisible} onCancel={() => setIsCreateCardModalVisible(false)} />
                    </Suspense>
                </div>
            </div>

            <FilterSection table={TablesEnum.CARDS} options={{ employees, teams }} />

            {pageLoading ? (
                <Loading />
            ) : cardsTable.error ? (
                <ErrorTable onTableReload={() => dispatch(refetchTable(TablesEnum.CARDS))} isLoading={cardsTable.isFetching} />
            ) : cardsTable.data.totalDocs === 0 && !cardsTable.isFetching ? (
                searchString ? (
                    <div className="animation-appear">
                        <NoSearchDataFound />
                    </div>
                ) : (
                    <Empty
                        className="animation-appear"
                        image={`./icons/empty_table.svg`}
                        imageStyle={{
                            height: 150,
                            marginRight: "auto",
                            marginLeft: "auto",
                            marginTop: "15%",
                            marginBottom: "40px",
                            display: "inline-block",
                        }}
                        description={
                            <span className="text-lg font-bold mt-2 mb-0 mr-2">
                                {!isTableFilterApplied(cardsTable.data.filter) ? t("info:empty_state.creditCard.no_cards_yet.title") : t("info:no_filter_data_found.title.card")}
                            </span>
                        }
                    >
                        <div className="text-center max-w-450 mx-auto">
                            {!isTableFilterApplied(cardsTable.data.filter)
                                ? t("info:empty_state.creditCard.no_cards_yet.message")
                                : AuthzService.isRightGrantedForLoggedInUser(RightEnum.CARD__TEAM__WRITE)
                                ? t("info:no_filter_data_found.message")
                                : t("info:no_filter_data_found.message_approver")}
                        </div>
                    </Empty>
                )
            ) : (
                <div className="overflow-auto p-2 animation-appear">
                    <Table
                        rowKey={(record: any) => record._id}
                        dataSource={cardsTable.data.docs}
                        columns={columns}
                        onChange={updateTableOnChange(TablesEnum.CARDS, cardsTable.data)}
                        pagination={{
                            position: ["bottomRight"],
                            showSizeChanger: true,
                            current: cardsTable.data.page,
                            pageSize: cardsTable.data.limit,
                            hideOnSinglePage: false,
                            total: cardsTable.data.totalDocs,
                            pageSizeOptions: ["5", "10", "20", "50", "100"],
                        }}
                        loading={{
                            spinning: cardsTable.isFetching,
                            indicator: <LoadingOutlined style={{ fontSize: 30, color: "black" }} spin />,
                        }}
                        onRow={(card) => ({
                            onClick: (_event: any) => {
                                dispatch(setCreditCard(card))
                                history.push(`/credit-cards/${card._id}`)
                            },
                        })}
                    />
                </div>
            )}
        </div>
    )
}

export default CreditCardList
