import { WarningFilled } from "@ant-design/icons"
import { Alert } from "antd"
import React from "react"

interface WarningAlertInterface {
    message: React.ReactNode
    className?: string
}
const WarningAlert: React.FC<WarningAlertInterface> = ({ message, className }) => <Alert className={className} message={message} type="warning" showIcon icon={<WarningFilled />} />

export default WarningAlert
