import { Expense, ExportFormatEnum, TripFolder } from "@finway-group/shared/lib/models"
import { Button, Card, Divider, Dropdown, Empty, Menu } from "antd"
import httpStatus from "http-status"
import React, { useMemo, useState } from "react"
import { Download as DownloadIcon } from "react-feather"
import { useTranslation } from "react-i18next"

import WaitingModal from "Components/modals/waiting.modal"
import PriceLabel from "Components/priceLabel"
import { FileService, NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"

import { ExpenseFolderItemRow } from "./expenseFolderItemRow"

interface FolderSummaryInterface {
    folder: TripFolder
    subExpenses: Array<Expense>
}

export const FolderSummary = ({ folder, subExpenses }: FolderSummaryInterface) => {
    const { t } = useTranslation()
    const [isExporting, setIsExporting] = useState(false)

    const billableExpenses = useMemo(() => subExpenses.filter((subExpense) => subExpense.billable), [subExpenses])

    // TODO use export wrapper in here
    const handleInvoicesExport = async ({ key, domEvent }: any) => {
        domEvent.stopPropagation()

        const filteredSubExpenses = key === "all" ? subExpenses : billableExpenses

        setIsExporting(true)
        const expenseIds = [folder.id, ...filteredSubExpenses.map((expense) => expense.id)].join(",")

        try {
            const response = await FileService.export(`&_id[in]=${expenseIds}&format=${ExportFormatEnum.INVOICES}`)

            if (response.status === httpStatus.ACCEPTED) {
                NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:mass_export.started.message"), t("notification:mass_export.started.description"))
                return
            }

            FileService.downloadFileAsSignedUrl(response?.data.fileUrl)
        } catch (error) {
            NotificationService.showErrorNotificationBasedOnResponseError(error, t("error:file:export:title"))
        } finally {
            setIsExporting(false)
        }
    }

    const DropdownMenu = () => (
        <Menu onClick={handleInvoicesExport}>
            <Menu.Item key={"all"} className="flex items-center min-w-190">
                <DownloadIcon strokeWidth={1} size={16} className="mr-8" /> {t("action:download_invoices")}
            </Menu.Item>
            {billableExpenses.length > 0 && (
                <Menu.Item key={"billable"} className="flex items-center min-w-190">
                    <DownloadIcon strokeWidth={1} size={16} className="mr-8" /> {t("action:download_billable_invoices")}
                </Menu.Item>
            )}
        </Menu>
    )

    const folderDisplayAmount = folder.getUsedOrActiveDiscount()?.reducedAmount?.gross ?? folder.totalGrossPrice

    return (
        <>
            <WaitingModal isVisible={isExporting} infoText={t("info:export_in_progress")} onCancel={() => setIsExporting(false)} />
            <Card className="w-full">
                <div className="flex justify-between align-center">
                    <p className="font-bold">{t("label:folder.summary")}</p>
                    {subExpenses.length > 0 && (
                        <Dropdown overlay={DropdownMenu}>
                            <Button>{t("action:download_invoices")}</Button>
                        </Dropdown>
                    )}
                </div>
                <Divider />
                {subExpenses.length > 0 ? (
                    <>
                        <div className="flex justify-between items-center font-bold px-10">
                            <div>{t("label:item")}</div>
                            <div>{t("label:amount")}</div>
                        </div>
                        {subExpenses.map((child, index) => (
                            <ExpenseFolderItemRow expense={child} key={`folder-child-row-${index}`} />
                        ))}
                        <div className="flex justify-end px-10 gap-16 items-center mt-16 ">
                            <div>{t("label:folder.total")}</div>
                            <div className="font-bold text-lg">
                                <PriceLabel value={folderDisplayAmount} currency={folder.currency} />
                            </div>
                        </div>
                    </>
                ) : (
                    <Empty
                        className="animation-appear"
                        image={`./icons/empty_table.svg`}
                        imageStyle={{ height: 50, marginRight: "auto", marginLeft: "auto", display: "inline-block" }}
                        description={<span className="text-lg font-bold mb-0">{t("info:empty_state.folder.message")}</span>}
                    />
                )}
            </Card>
        </>
    )
}
