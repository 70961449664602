export enum CorporateSourceOfFundsEnum {
    LABOUR_CONTRACT,
    CIVIL_CONTRACT,
    RENT,
    FUNDS_FROM_OTHER_AUXILIARY_SOURCES,
    SALE_OF_MOVABLE_ASSETS,
    SALE_OF_REAL_ESTATE,
    ORDINARY_BUSINESS_ACTIVITY,
    DIVIDENDS,
    LOAN_FROM_FINANCIAL_INSTITUTIONS_CREDIT_UNIONS,
    LOAN_FROM_THIRD_PARTIES,
    SALE_OF_COMPANY_SHARES_BUSINESS,
    OTHER,
}
