import { CheckCircleOutlined as CheckIcon } from "@ant-design/icons"
import { BankAccount, BankConnection, BankConnectionUpdateStatusEnum } from "@finway-group/shared/lib/models"
import { Alert, Button, Col, Form, Modal, Radio, Row, Tag } from "antd"
import { UNAVAILABLE_FOR_LEGAL_REASONS } from "http-status"
import React, { useEffect } from "react"
import { Trash as TrashIcon } from "react-feather"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"

import BankConnectionAlert from "Components/bankConnectionAlert"
import BankConnectionForm from "Components/forms/banking/bankConnection.form."
import Icon from "Components/icon"
import { BankService, NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { fetchAllBankConnections, fetchBankConnection } from "Shared/store/actions/bank/bankActions"
import { RootState } from "Shared/store/rootState.interface"
import { isDev, isInternal } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

interface BankConnectionModalProps {
    isShowing: boolean
    onCancel: () => void
}

const filterDeletedAccounts = (accounts: Array<BankAccount>) => accounts.filter((ba: BankAccount) => !ba.deleted)

const BankConnectionModal = ({ isShowing, onCancel }: BankConnectionModalProps) => {
    const { t } = useTranslation()
    const [formInstance] = Form.useForm()
    const [isLoading, setIsLoading] = useStateIfMounted(false)
    const [isLoadingInterface, setIsLoadingInterface] = useStateIfMounted(false)
    const dispatch = useDispatch()

    const bankConnection = useSelector(({ banks: { item } }: RootState) => item as BankConnection)
    const hasUnconnectedInterfaces =
        bankConnection &&
        bankConnection.availableInterfaces &&
        bankConnection.connectedInterfaces &&
        bankConnection.availableInterfaces.length - bankConnection.connectedInterfaces.length > 0
    const [accounts, setAccounts] = useStateIfMounted(bankConnection && bankConnection.accounts ? filterDeletedAccounts(bankConnection.accounts) : [])

    useEffect(() => {
        setAccounts(bankConnection && bankConnection.accounts ? filterDeletedAccounts(bankConnection.accounts) : [])
    }, [bankConnection.accounts])

    const onRepairBankAccount = (accountId: string) => {
        BankService.performManualBankConnectionUpdate(bankConnection.connectionId, accountId, true).catch((err) => {
            if (err.response?.status === UNAVAILABLE_FOR_LEGAL_REASONS) {
                NotificationService.send(NotificationTypeEnum.INFO, t("notification:banking.update_started.title"), t("notification:banking.update_started.message"))
            } else {
                NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:banking.delete.title"))
            }
        })
    }

    const onSubmit = (isDefault: boolean) => {
        setIsLoading(true)
        BankService.updateBankConnection(bankConnection._id, { ...bankConnection, accounts, isDefault })
            .then(() => {
                setIsLoading(false)
                // refetch bank connection in store
                dispatch(fetchAllBankConnections())
                NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:banking.updated.title"), t("notification:banking.updated.message"))
                onCancel()
            })
            .catch((err) => {
                setIsLoading(false)
                NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:banking.error"))
            })
    }

    const onConnectMoreInterfaces = () => {
        const interfaces = bankConnection.availableInterfaces.filter((x) => !bankConnection.connectedInterfaces.includes(x))
        if (interfaces.length < 1) {
            return NotificationService.send(NotificationTypeEnum.SUCCESS, t("error:banking.error"), t("error:banking.all_interfaces_connected"))
        }

        const interfaceToConnect = interfaces[0]
        setIsLoadingInterface(true)
        BankService.addNewInterface(bankConnection.connectionId, interfaceToConnect)
            .then(() => {
                setIsLoadingInterface(false)
                NotificationService.send(
                    NotificationTypeEnum.INFO,
                    t("notification:banking.new_interface_add_started.title"),
                    t("notification:banking.new_interface_add_started.message"),
                    30,
                )
                onCancel()
            })
            .catch((err) => {
                setIsLoadingInterface(false)
                NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:banking.error"))
            })
    }

    const getDownloadStatus = (account: BankAccount) => {
        switch (account.status) {
            case BankConnectionUpdateStatusEnum.DOWNLOADING:
                return (
                    <Tag className="mr-6 mt-8 ant-tag ant-tag-yellow" color="warn">
                        {t("info:downloading")}
                    </Tag>
                )
            case BankConnectionUpdateStatusEnum.DONE:
                return (
                    <Tag className="mr-6 mt-8 ant-tag ant-tag-green" color="primary">
                        {t("info:downloaded")}
                    </Tag>
                )
            case BankConnectionUpdateStatusEnum.FAILED:
                return (
                    <Tag className="mr-6 mt-8 ant-tag ant-tag-red" color="primary" onClick={() => onRepairBankAccount(account.accountId)}>
                        <span className="whitespace-normal">
                            {t("info:failed")} - {t("info:click_to_repair")}
                        </span>
                    </Tag>
                )
        }
    }

    const onDeleteAccount = ({ isDefault, accountId }: BankAccount) => {
        if (isDefault)
            return NotificationService.send(
                NotificationTypeEnum.WARNING,
                t("error:banking.account.cannot_delete_default.title"),
                t("error:banking.account.cannot_delete_default.message"),
            )

        Modal.confirm({
            title: t("confirm:banking.account.delete.title"),
            content: t("confirm:banking.account.delete.message"),
            okText: t("confirm:banking.account.delete.confirm"),
            cancelText: t("confirm:banking.account.delete.cancel"),
            okType: "danger",
            style: { marginTop: "20px" },
            onOk() {
                return BankService.deleteBankAccount(accountId)
                    .then(() => {
                        // refetch bank connection in store
                        dispatch(fetchBankConnection(bankConnection._id))
                        NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:banking.account.deleted.title"), t("notification:banking.account.deleted.message"))
                    })
                    .catch((err) => {
                        // refetch bank connection in store
                        dispatch(fetchBankConnection(bankConnection._id))
                        NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:banking.account.delete.title"))
                    })
            },
        })
    }

    const onChangeDefault = (accountId: string) => setAccounts(accounts.map((acc) => ({ ...acc, isDefault: acc._id === accountId })))

    return (
        <>
            <Modal
                title={bankConnection?.bankName}
                maskClosable={false}
                visible={isShowing}
                onOk={formInstance.submit}
                onCancel={onCancel}
                okText={t("action:banking.update.save")}
                cancelText={t("action:cancel")}
                confirmLoading={isLoading}
                keyboard
                destroyOnClose
            >
                <BankConnectionAlert bankConnectionOverride={bankConnection} />
                <BankConnectionForm bankConnection={bankConnection} formInstance={formInstance} onSubmit={onSubmit} />
                <span className="font-bold">{t("label:connected_accounts")}</span>
                <div className="mb-20 mt-20">
                    {accounts.map((account) => (
                        <Row key={account.accountId} className="mb-14">
                            <Col span={3} className="mt-16">
                                <Icon icon="account" />
                            </Col>
                            <Col span={9}>
                                <div className="flex flex-col">
                                    <span>
                                        {t("label:type")}: {account.accountType}
                                        {account.isDefault && <CheckIcon style={{ fontSize: "10px", marginLeft: "4px" }} className="icon-default" />}
                                    </span>
                                    <span>
                                        {t("label:number")}: {account.accountName}
                                    </span>
                                    <span>
                                        {t("label:id")}: {account.accountId}
                                    </span>
                                </div>
                            </Col>
                            <Col span={accounts.length > 1 ? 10 : 12} className="flex items-start justify-end">
                                {getDownloadStatus(account)}
                            </Col>
                            {accounts.length > 1 && (
                                <>
                                    <Col span={1}>
                                        <TrashIcon onClick={() => onDeleteAccount(account)} size={12} className="mt-12" />
                                    </Col>
                                    <Col span={1}>
                                        <Radio checked={account.isDefault} className="mt-8 ml-8" onChange={() => onChangeDefault(account._id)} />
                                    </Col>
                                </>
                            )}
                        </Row>
                    ))}
                </div>

                {hasUnconnectedInterfaces && (
                    <div>
                        <span className="font-bold">{t("label:additional_interfaces")}</span>
                        <Alert type="info" className="mt-20" showIcon message={t("label:note")} description={t("info:more_interfaces_available")} />
                        <Button loading={isLoadingInterface} className="mt-20" type="default" onClick={onConnectMoreInterfaces}>
                            {t("action:banking.connect_more_interfaces")}
                        </Button>
                    </div>
                )}
                {(isDev || isInternal) && (
                    <Button
                        className="mt-24"
                        onClick={() =>
                            BankService.revokeConsent(bankConnection.connectionId).then(() =>
                                NotificationService.send(NotificationTypeEnum.SUCCESS, "Success", "Bank connection revoked successfully."),
                            )
                        }
                    >
                        Revoke bank connection consent
                    </Button>
                )}
            </Modal>
        </>
    )
}

export default BankConnectionModal
