import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons"
import { COLOR } from "@finway-group/shared/lib/consts"
import { Employee, ErrorCodeEnum, KYBStatusEnum, KYBStepsEnum, RightEnum } from "@finway-group/shared/lib/models"
import { Button, Card, Tag, Tooltip } from "antd"
import * as HttpStatus from "http-status"
import React, { useCallback } from "react"
import { AlertTriangle } from "react-feather"
import { useTranslation } from "react-i18next"
import { formatPhoneNumberIntl } from "react-phone-number-input"
import { useDispatch } from "react-redux"

import EmployeeLink from "Components/employeeLink"
import AddEmailAliasModal from "Components/modals/addEmailAlias.modal"
import EmployeeCreditorNumberModal from "Components/modals/employeeCreditorNumber.modal"
import PhoneVerificationModal from "Components/modals/phoneVerfication.modal"
import PhoneNumber from "Components/phoneNumber"
import { useModal } from "Shared/context/modal.context"
import { useCompany } from "Shared/hooks/company.hooks"
import { useCurrentKYBStep, useKYBData } from "Shared/hooks/corporate.hooks"
import { useIsFreeVersion, useIsTravelEnabled } from "Shared/hooks/featureFlags.hooks"
import { useAuditorRole, useRoleById } from "Shared/hooks/role.hooks"
import { useTeams } from "Shared/hooks/team.hooks"
import { AuthzService, CorporateService, EmployeeService, NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { syncEmployee } from "Shared/store/actions/employee/employeeActions"
import { getUserRoleName } from "Shared/utils/getter.utils"
import { getIBANPrintFormat, getItemById, getTooltipPopupContainer, roundNumber } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

interface EmployeePublicProfileInterface {
    employee: Employee
    loggedInUser: Employee
    showPhoneNumber: boolean
    canUpdateEmployee: boolean
}
const EmployeePublicProfile: React.FC<EmployeePublicProfileInterface> = ({ employee, loggedInUser, showPhoneNumber, canUpdateEmployee }) => {
    const { t } = useTranslation()
    const { showModal } = useModal()
    const kybData = useKYBData()
    const companySettings = useCompany()
    const teams = useTeams()
    const kybStatus = useCurrentKYBStep()
    const company = useCompany()
    const dispatch = useDispatch()
    const [isEmailAliasErrorModalShowing, setIsEmailAliasErrorModalShowing] = useStateIfMounted(false)
    const [isEnrolling, setIsEnrolling] = useStateIfMounted(false)
    const auditorRole = useAuditorRole()
    const employeeIsAuditor = employee.activeCompanyProfile.roleId === auditorRole?._id

    // feature flags
    const isFreeVersion = useIsFreeVersion()
    const isTravelEnabled = useIsTravelEnabled()

    const employeeCompanyProfile = employee.activeCompanyProfile
    const userRole = useRoleById((employeeCompanyProfile as any).roleId)

    const superior = EmployeeService.getEmployeeById(employee.activeCompanyProfile.superior || "")
    const isEmployeeCurrentLoggedInUser = employee.id === loggedInUser.id

    const mileageRate = employee.activeCompanyProfile?.mileageRate ? roundNumber(employee.activeCompanyProfile?.mileageRate * 100) : undefined

    const canManageEmployees = AuthzService.canUpdateEmployeeDetails(employee)

    const CreditorNumberAlert = () => (
        <div>
            <Tooltip getPopupContainer={getTooltipPopupContainer} title={t("info:employee_no_creditor_number")}>
                <AlertTriangle fill={COLOR.finway.orange} size={18} stroke={COLOR.white} className="mt-2" />
            </Tooltip>
        </div>
    )

    const getPhoneNumber = useCallback(
        () =>
            isEmployeeCurrentLoggedInUser ? (
                <PhoneNumber
                    employee={employee}
                    showVerificationModal={() =>
                        showModal(PhoneVerificationModal, false, {
                            isShowing: true,
                            employee,
                            phoneNumber: employee.workPhone,
                        })
                    }
                />
            ) : (
                <div className="flex flex-row">
                    <span>
                        {employee?.settings?.security?.workPhoneVerified && (
                            <Tag className="mr-5 flex items-center" icon={<CheckCircleOutlined />} color="success">
                                {t("label:phone_verification:verified")}
                            </Tag>
                        )}
                    </span>
                    {formatPhoneNumberIntl(employee.workPhone)}
                </div>
            ),
        [isEmployeeCurrentLoggedInUser, employee.workPhone],
    )

    const onEnrollInCardProgram = () => {
        setIsEnrolling(true)
        CorporateService.enrollUser(employee.id)
            .then(() => {
                NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:corporate.enroll_user.title"), t("notification:corporate.enroll_user.message"))
                syncEmployee(employee.id)(dispatch)
            })
            .catch((err) => {
                if (err.response?.status === HttpStatus.UNPROCESSABLE_ENTITY && err.response.data?.errorCode === ErrorCodeEnum.NO_EMAIL_ALIAS) {
                    setIsEmailAliasErrorModalShowing(true)
                } else {
                    NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:corporate.enroll_user.title"))
                }
            })
            .finally(() => setIsEnrolling(false))
    }

    return (
        <div className="overflow-hidden">
            <AddEmailAliasModal
                isShowing={isEmailAliasErrorModalShowing}
                onSuccess={onEnrollInCardProgram}
                onCancel={() => setIsEmailAliasErrorModalShowing(false)}
                companyId={company._id}
                employee={employee}
                isShownOnError
            />
            <h2>{t("label:profile")}</h2>
            <Card>
                <ul className="description-list">
                    <li>
                        <span className="title">{t("label:first_name")}</span>
                        <span className="text max-w-400 text-right">{employee.firstName}</span>
                    </li>
                    <li>
                        <span className="title">{t("label:last_name")}</span>
                        <span className="text max-w-400 text-right">{employee.lastName}</span>
                    </li>
                    <li>
                        <span className="title">{t("label:email")}</span>
                        <span className="text max-w-400 ml-80 flex float-right">{employee.email}</span>
                    </li>
                    <li>
                        <span className="title">{t("label:location")}</span>
                        <span className="text max-w-400 text-right">{employee.location ?? "n/a"}</span>
                    </li>
                    <li>
                        <span className="title">{t("label:team")}</span>
                        <span className="text max-w-400 text-right">{getItemById(teams, employeeCompanyProfile.team)?.teamName}</span>
                    </li>
                    <li>
                        <span className="title">{t("label:position")}</span>
                        <span className="text max-w-400 text-right">{employee.position}</span>
                    </li>
                    {AuthzService.isRightGrantedForLoggedInUser(RightEnum.EMPLOYEE__ALL__READ) && (
                        <li>
                            <span className="title">{t("label:role")}</span>
                            <span className="text max-w-400 text-right">{getUserRoleName(userRole)}</span>
                        </li>
                    )}
                    <li>
                        <span className="title">{t("label:superior")}</span>
                        <span className="text">{superior.id ? <EmployeeLink employee={superior} /> : "n/a"}</span>
                    </li>
                    {employee.workPhone && showPhoneNumber && (
                        <li className="truncate flex justify-between">
                            <span className="title">{t("label:work_phone")}</span>
                            <span className="text truncate max-w-400 text-right">{getPhoneNumber()}</span>
                        </li>
                    )}
                    {(AuthzService.isRightGrantedForLoggedInUser(RightEnum.EMPLOYEE__ALL__READ) || isEmployeeCurrentLoggedInUser) && (employee.iban || employee.bic) && (
                        <>
                            <li>
                                <span className="title">{t("label:iban")}</span>
                                <span className="text">{employee.iban ? getIBANPrintFormat(employee.iban) : "n/a"}</span>
                            </li>
                            <li>
                                <span className="title">{t("label:bic")}</span>
                                <span className="text">{employee.bic || "n/a"}</span>
                            </li>
                        </>
                    )}
                    {!isFreeVersion && canManageEmployees && employee.activeCompanyProfile && (
                        <>
                            <li>
                                <span className="title">
                                    <div className="flex flex-row">
                                        {t("label:creditor_number")}
                                        {!employee.activeCompanyProfile?.creditorNumber && <CreditorNumberAlert />}
                                    </div>
                                </span>
                                <span className="text">
                                    {/** if creditor number exist, display, else, fallback to the creation modal component */}
                                    {employee.activeCompanyProfile?.creditorNumber ?? (
                                        <Button
                                            key="submit"
                                            type="default"
                                            className="btn-highlight-green min-w-120"
                                            disabled={!canUpdateEmployee}
                                            onClick={() => showModal(EmployeeCreditorNumberModal, false, { isShowing: true, employee })}
                                        >
                                            {t("action:employee.add_creditor_number")}
                                        </Button>
                                    )}
                                </span>
                            </li>
                        </>
                    )}
                    {isTravelEnabled && (AuthzService.isRightGrantedForLoggedInUser(RightEnum.EMPLOYEE__ALL__READ) || isEmployeeCurrentLoggedInUser) && mileageRate && (
                        <>
                            <li>
                                <span className="title">
                                    <div className="flex flex-row">{t("label:employee_labels.mileage_rate")}</div>
                                </span>
                                <span className="text">{mileageRate} ct/km</span>
                            </li>
                        </>
                    )}
                    {AuthzService.isRightGrantedForLoggedInUser(RightEnum.EMPLOYEE__ALL__READ) &&
                        companySettings?.cardsEnabled &&
                        !employeeIsAuditor &&
                        (kybData.kybStatus === KYBStatusEnum.COMPLETED || kybStatus === KYBStepsEnum.KYB_FORM) && (
                            <li>
                                <span className="title">{t("label:cards.card_program")}</span>
                                <div className="text">
                                    {employeeCompanyProfile.weavrData?.userId ? (
                                        <div className="flex items-center justify-center">
                                            <Tag className="flex items-center" icon={<CheckCircleOutlined />} color="success">
                                                {t("label:cards.is_enrolled")}
                                            </Tag>
                                        </div>
                                    ) : loggedInUser.activeCompanyProfile.weavrData?.userId ? (
                                        <Button type="primary" loading={isEnrolling} onClick={onEnrollInCardProgram} className="btn-highlight-green">
                                            {t("action:cards.enroll_now")}
                                        </Button>
                                    ) : (
                                        <div className="flex items-center justify-center">
                                            <Tag className="flex items-center" icon={<CloseCircleOutlined />} color="warning">
                                                {t("label:cards.not_enrolled")}
                                            </Tag>
                                        </div>
                                    )}
                                </div>
                            </li>
                        )}
                </ul>
            </Card>
        </div>
    )
}

export default EmployeePublicProfile
