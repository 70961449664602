import { CloseOutlined, RightOutlined, UpOutlined } from "@ant-design/icons"
import { Expense, RightEnum, Transaction, TransactionTypeEnum } from "@finway-group/shared/lib/models"
import { TransactionStatusEnum } from "@finway-group/shared/lib/models/transaction/transactionStatus.enum"
import { Button, Row, Table, Tag } from "antd"
import { ColumnsType } from "antd/es/table"
import * as HttpStatus from "http-status"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { Archive as ArchiveIcon } from "react-feather"
import { Helmet } from "react-helmet"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useHistory, useParams } from "react-router-dom"

import PageCustomHeader from "Components/PageCustomHeader"
import Loading from "Components/loading"
import TransactionAmountColumn from "Components/transactionAmountColumn"
import { useCompany } from "Shared/hooks/company.hooks"
import { useIsFreeVersion } from "Shared/hooks/featureFlags.hooks"
import { handleArchiveTransactions, handleUnarchiveTransactions, useTransaction } from "Shared/hooks/transaction.hooks"
import { AuthzService, EmployeeService, NotificationService, VendorService } from "Shared/services"
import DialogService from "Shared/services/dialog.service"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import TransactionService from "Shared/services/transaction.service"
import { ThunkDispatchResult } from "Shared/store"
import { deleteTransaction, fetchOneTransaction, syncTransaction } from "Shared/store/actions/transaction/transactionActions"
import { TablesEnum } from "Shared/store/reducers/tableConfigReducer"
import { isCashWithdrawal } from "Shared/utils/creditCard.utils"
import { getIBANPrintFormat } from "Shared/utils/helper.utils"
import { isTransactionArchived, isTransactionManuallyUnarchived } from "Shared/utils/transaction.utils"

import ExpenseRequestDropdown from "../expenses/expenseRequestDropdown"
import MatchedExpenses from "./matchedExpenses"
import PossibleMatches from "./possibleMatches"
import TransactionBanner from "./transactionBanner"
import TransactionCategorySelect from "./transactionCategory.select"

export const TransactionsDetails = () => {
    const { t } = useTranslation()
    const history = useHistory()
    const { id } = useParams<{ id: string }>()
    const dispatch: ThunkDispatchResult = useDispatch()
    const isFreeVersion = useIsFreeVersion()
    const archiveAfterXDays = useCompany()?.transactionArchiveAfterXDays
    const activeTransaction = useTransaction()

    // TODO: check if remove or implement this
    const [loading, setLoading] = useState(false)
    const [loadingId, setLoadingId] = useState("-1")
    const isCardTransaction = activeTransaction?.transactionType === TransactionTypeEnum.CARD_TRANSACTION
    const expenseCurrency = activeTransaction?.matchingData?.matchedExpenses[0]?.expense?.currency ?? undefined

    // Rights
    const hasWriteAccess = AuthzService.isRightGrantedForLoggedInUser(RightEnum.TRANSACTION__ALL__UPDATE)

    useEffect(() => {
        dispatch(fetchOneTransaction(id)).catch(() => {
            history.push("/transactions")
        })
    }, [id])

    const transactionColumns: ColumnsType<Transaction> = [
        {
            key: "transactionDate",
            title: t("label:transaction_date"),
            ellipsis: true,
            width: 150,
            render: ({ transactionData: { date } }: Transaction) => <span>{moment(date).format("ll")}</span>,
        },
        {
            key: "transactionCategory",
            title: t("label:transaction_category"),
            width: 144,
            render: (transaction: Transaction) => {
                if (transaction.transactionType === TransactionTypeEnum.CSV_TRANSACTION || transaction.transactionType === TransactionTypeEnum.CARD_TRANSACTION) {
                    return <Tag className="ant-tag-gray my-5">{t("info:not_categorizable")}</Tag>
                }
                return <TransactionCategorySelect transaction={transaction} />
            },
        },
        {
            key: "vendor",
            title: activeTransaction?.transactionData?.amount <= 0 ? t("label:vendor") : t("label:debtor"),
            ellipsis: true,
            width: 180,
            render: ({ transactionData: { vendor, recipient } }: any) => <span>{recipient || VendorService.getVendorById(vendor)?.name || "n/a"}</span>,
        },
        {
            key: "iban",
            title: t("label:iban"),
            ellipsis: true,
            width: 250,
            render: ({ transactionData: { iban } }: Transaction) => <span>{iban ? getIBANPrintFormat(iban) : "n/a"}</span>,
        },
        {
            key: "description",
            title: t("label:description"),
            ellipsis: true,
            width: 180,
            render: ({ transactionData: { description } }: Transaction) => <span>{description || "n/a"}</span>,
        },
        {
            key: "amount",
            title: t("label:amount"),
            ellipsis: true,
            width: 150,
            render: (transaction: Transaction) => <TransactionAmountColumn transaction={transaction} expenseCurrency={expenseCurrency} />,
        },
        {
            key: "probability",
            ellipsis: true,
        },
        {
            key: "action",
            ellipsis: true,
            width: 150,
        },
    ]

    const onMatch = (expenseId: any, ignoreAlreadyMatchedError = false) => {
        setLoadingId(expenseId)
        TransactionService.matchTransaction(activeTransaction.id, expenseId)
            .then((transaction: Transaction) => {
                dispatch(syncTransaction(transaction.id))
                NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:matching.matched.title"), t("notification:matching.matched.message"))
            })
            .catch((err) => {
                if (!ignoreAlreadyMatchedError || err?.response?.status !== HttpStatus.UNPROCESSABLE_ENTITY) {
                    NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:matching.title"))
                }
            })
            .finally(() => setLoadingId("-1"))
    }

    const onUnmatch = (expenseId: any) => {
        setLoadingId(expenseId)
        TransactionService.unmatchTransaction(activeTransaction.id, expenseId)
            .then((transaction: Transaction) => {
                dispatch(syncTransaction(transaction.id))
                NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:matching.unmatched.title"), t("notification:matching.unmatched.message"))
            })
            .catch((err) => NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:matching.title")))
            .finally(() => setLoadingId("-1"))
    }

    const onExpenseCreated = (expense: Expense) => onMatch(expense.id, true)

    const onDelete = async () => {
        if (!activeTransaction || !(await DialogService.confirmDeleteTransaction())) return

        try {
            await deleteTransaction(activeTransaction.id)(dispatch)
            NotificationService.send(NotificationTypeEnum.WARNING, t("notification:transaction.deleted.title"), t("notification:transaction.deleted.message"))
            history.push({ pathname: "/transactions" })
        } catch (err) {
            NotificationService.send(NotificationTypeEnum.ERROR, t("error:transaction.delete.title"), t("error:transaction.delete.message"))
        }
    }

    const getTransactionDetails = ({ transactionData, transactionType }: Transaction) => (
        <>
            <Row className="font-bold text-text-dark text-left self-center">{activeTransaction.transactionData.amount <= 0 ? t("label:vendor") : t("label:debtor")}</Row>
            <Row className="text-left self-center whitespace-normal overflow-auto mt-4">{transactionData.recipient || "n/a"}</Row>
            <Row className="font-bold text-text-dark text-left self-center">
                {activeTransaction.transactionData.amount <= 0 ? t("label:derived_vendor") : t("label:derived_debtor")}
            </Row>
            <Row className="text-left self-center whitespace-normal overflow-auto mt-4">
                {VendorService.getVendorById(transactionData.vendor)?.name || EmployeeService.getEmployeeById(transactionData.reimbursementEmployee)?.getFullName() || "n/a"}
            </Row>
            <Row className="font-bold text-text-dark text-left self-center mt-4">{t("label:description")}</Row>
            <Row className="text-left self-center whitespace-normal overflow-auto mt-4">{transactionData.description || "n/a"}</Row>
            <Row className="font-bold text-text-dark text-left self-center mt-4">{t("label:transaction_source")}</Row>
            <Row className="text-left self-center whitespace-normal overflow-auto mt-4">{getTransactionType()}</Row>
            {transactionType === TransactionTypeEnum.CARD_TRANSACTION && (
                <>
                    <Row className="font-bold text-text-dark text-left self-center mt-4">{t("label:card_transaction_type")}</Row>
                    <Row className="text-left self-center whitespace-normal overflow-auto mt-4">
                        {t(`label:cards.transaction_types.${activeTransaction?.cardData?.cardTransactionType?.toLowerCase()}`)}
                    </Row>
                </>
            )}
        </>
    )

    const getTransactionType = () => {
        switch (activeTransaction.transactionType) {
            case TransactionTypeEnum.BANK_TRANSACTION:
                return <span>{`${activeTransaction.bankName} - ${activeTransaction?.accountName}` ?? t("label:bank_transaction")}</span>
            case TransactionTypeEnum.CSV_TRANSACTION:
                return <span>{t("label:csv_transaction")}</span>
            case TransactionTypeEnum.CARD_TRANSACTION:
                return <span>{t("label:card_transaction")}</span>
            default:
                return <></>
        }
    }
    const getArchiveTags = () => (
        <>
            {isTransactionArchived(activeTransaction, archiveAfterXDays) ? (
                <Tag className="ant-tag-blue mt-4 ml-4 flex flex-row">
                    <ArchiveIcon className="mt-3" size={12} />
                    <span className="mt-2 ml-5"> {t("label:archived")}</span>
                </Tag>
            ) : (
                isTransactionManuallyUnarchived(activeTransaction, archiveAfterXDays) && (
                    <Tag className="ant-tag-blue mt-4 ml-4 flex flex-row">
                        <ArchiveIcon className="mt-3 " size={12} />
                        <span className="mt-2 ml-5"> {t("label:manually_unarchived")}</span>
                    </Tag>
                )
            )}
        </>
    )

    const getTransactionTableEnum = () => (activeTransaction.transactionData.amount <= 0 ? TablesEnum.TRANSACTIONS_OUTFLOW : TablesEnum.TRANSACTIONS_INFLOW)

    return !activeTransaction?.id ? (
        <Loading />
    ) : (
        <>
            <Helmet>
                <title>{t("view:transactions.title")}</title>
            </Helmet>
            <PageCustomHeader
                title={
                    <div className="flex">
                        <h1 className="mb-0 truncate">{t("label:transaction_details")}</h1>
                        {activeTransaction.matchedExpense && <Tag className="ant-tag-green mt-4 ml-4">{t("info:matched")}</Tag>}
                        {getArchiveTags()}
                    </div>
                }
                actionButtons={
                    hasWriteAccess && (
                        <div className="flex gap-12">
                            <Button
                                className="btn-default"
                                onClick={async () => {
                                    if (isTransactionArchived(activeTransaction, archiveAfterXDays)) await handleUnarchiveTransactions([id])
                                    else await handleArchiveTransactions([id], getTransactionTableEnum())
                                    dispatch(fetchOneTransaction(id))
                                }}
                            >
                                <ArchiveIcon />
                                <span>{t(`action:archive.${isTransactionArchived(activeTransaction, archiveAfterXDays) ? "undo" : "do"}`)}</span>
                            </Button>

                            <Button className="btn-default" onClick={onDelete} icon={<CloseOutlined />} disabled={isCardTransaction}>
                                <span>{t("action:delete")}</span>
                            </Button>
                            {!isFreeVersion && (
                                <ExpenseRequestDropdown
                                    hideMenu
                                    transactionData={activeTransaction.transactionData}
                                    onExpenseCreated={onExpenseCreated}
                                    disabled={isCashWithdrawal(activeTransaction?.cardData.cardTransactionType)}
                                />
                            )}
                        </div>
                    )
                }
            />
            <TransactionBanner transaction={activeTransaction} />
            <div className="mb-32">
                <h2>{!isFreeVersion ? t("label:transaction_to_be_matched") : t("label:transaction_to_be_categorized")}</h2>
                <div className="overflow-auto p-2">
                    <Table<Transaction>
                        rowKey={(transaction: Transaction) => transaction.id}
                        columns={transactionColumns}
                        pagination={false}
                        dataSource={[activeTransaction]}
                        expandable={{
                            expandedRowRender: (transaction: Transaction) => getTransactionDetails(transaction),
                            rowExpandable: () => true,
                            expandIcon: ({ expanded, onExpand, record }) =>
                                expanded ? (
                                    <UpOutlined style={{ fontSize: "12px", marginBottom: "6px !important" }} onClick={(e) => onExpand(record, e)} />
                                ) : (
                                    <RightOutlined style={{ fontSize: "12px" }} onClick={(e) => onExpand(record, e)} />
                                ),
                        }}
                    />
                </div>
            </div>
            {!isFreeVersion && activeTransaction.status !== TransactionStatusEnum.FAILED && (
                <>
                    {activeTransaction?.matchingData?.matchedExpenses?.length > 0 && (
                        <>
                            <MatchedExpenses loading={loading} loadingId={loadingId} onUnmatch={onUnmatch} />
                            <div className="mt-40"></div>
                        </>
                    )}
                    {hasWriteAccess && !isCashWithdrawal(activeTransaction?.cardData.cardTransactionType) && (
                        <PossibleMatches transaction={activeTransaction} loading={loading} loadingId={loadingId} onMatch={onMatch} />
                    )}
                </>
            )}
        </>
    )
}

export default TransactionsDetails
