import { DocumentChangeInterface, OperationTypeEnum } from "@finway-group/shared/lib/models"
import * as Sentry from "@sentry/react"
import i18next from "i18next"

import { invalidateNotifications } from "Shared/queries/notification.queries"
import { fetchAllCostCenters2 } from "Shared/store/actions/costCenter2/costCenter2Actions"
import { fetchAllCostCenters } from "Shared/store/actions/costCenter/costCenterActions"
import { fetchAllEmployees } from "Shared/store/actions/employee/employeeActions"
import { setShouldRefetchExpenses } from "Shared/store/actions/expense/expenseActions"
import { fetchAllExpenseAccounts } from "Shared/store/actions/expenseAccount/expenseAccountActions"
import {
    importDataFailure,
    importDataSuccess,
    importDataValidateOnlyFailure,
    importDataValidateOnlySuccess,
    importDataValidationErrors,
} from "Shared/store/actions/import/importActions"
import { refetchExpenseTables, refetchUserRelatedTables } from "Shared/store/actions/tables/tableActions"
import { fetchAllTeams } from "Shared/store/actions/team/teamActions"
import { fetchAllTransactions } from "Shared/store/actions/transaction/transactionActions"
import { fetchAllVendors } from "Shared/store/actions/vendor/vendorActions"
import { isClientErrorCode } from "Shared/utils/errorHandler.utils"

import NotificationService from "../notification.service"

export const subscribeToImportResult = async (dispatch: any, data: DocumentChangeInterface, loggedInUserId: string) => {
    const {
        fullDocument: { documentId, operationType, metaData, createdBy },
    } = data

    try {
        if (loggedInUserId === createdBy) {
            const importResultId = documentId?.toString()
            switch (operationType) {
                case OperationTypeEnum.IMPORT_DATA_VALIDATE_ONLY_SUCCESS: {
                    dispatch(importDataValidateOnlySuccess(importResultId))
                    break
                }
                case OperationTypeEnum.IMPORT_DATA_SUCCESS: {
                    dispatch(importDataSuccess(importResultId))
                    break
                }

                case OperationTypeEnum.IMPORT_DATA_VALIDATION_ERRORS:
                    dispatch(importDataValidationErrors(importResultId))
                    NotificationService.showErrorNotificationBasedOnResponseError("err", i18next.t("error:data_validation"))
                    break

                case OperationTypeEnum.IMPORT_DATA_VALIDATE_ONLY_FAILURE:
                    dispatch(importDataValidateOnlyFailure())
                    NotificationService.showErrorNotificationBasedOnResponseError("err", i18next.t("error:data_validation"))
                    break
                case OperationTypeEnum.IMPORT_DATA_FAILURE:
                    dispatch(importDataFailure())
                    NotificationService.showErrorNotificationBasedOnResponseError("err", i18next.t("error:data_upload"))
                    break
            }
        }

        if (operationType === OperationTypeEnum.IMPORT_DATA_SUCCESS) {
            invalidateNotifications()
            dispatch(fetchAllTeams())
            dispatch(fetchAllExpenseAccounts())
            dispatch(fetchAllCostCenters())
            dispatch(fetchAllEmployees())
            dispatch(fetchAllVendors())
            setShouldRefetchExpenses(true)(dispatch)
            dispatch(refetchExpenseTables())
            dispatch(refetchUserRelatedTables())
            dispatch(fetchAllTransactions())
            dispatch(fetchAllCostCenters2())
        }
    } catch (err) {
        if (isClientErrorCode(err)) return
        Sentry.captureException(`[Import Result] Error: ${typeof err === "string" ? err : JSON.stringify(err)}`)
    }
}
