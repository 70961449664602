import { Reducer } from "redux"

import {
    CreditCardActionTypes,
    CreditCardActions,
    FetchAllCardsAction,
    FetchOneCardAction,
    SetShouldRefetchCardsAction,
    SyncOneCardAction,
} from "../actions/creditCard/creditCardTypes"

export interface CreditCardState {
    item: any
    items: Array<any>
    totalPages: number
    totalDocs: number
    itemsPerPage: number
    currentPage: number
    shouldRefetch: boolean
}

const initialState = {
    item: undefined,
    items: [],
    itemsPerPage: 10,
    currentPage: 1,
    totalPages: 0,
    totalDocs: 0,
    shouldRefetch: false,
}

const fetchAllCards = (state: CreditCardState, { cards, totalPages, totalDocs, append }: FetchAllCardsAction): CreditCardState => {
    let { item } = state
    if (item) {
        const itemInAction = cards.find(({ _id }: any) => _id === item?._id)
        if (itemInAction) item = itemInAction
    }

    return {
        ...state,
        items: !append ? cards : [...state.items, ...cards],
        totalPages,
        totalDocs,
    }
}

const fetchOneCard = (state: CreditCardState, action: FetchOneCardAction): CreditCardState => ({
    ...state,
    item: action.card,
})

const syncOneCard = (state: CreditCardState, { card }: SyncOneCardAction): CreditCardState => {
    const isIncludedInStateItems = state.items.filter((aCard) => aCard._id === card._id).length > 0

    return {
        ...state,
        items: isIncludedInStateItems ? state.items.map((item) => (item.id === card._id ? card : item)) : state.items,
        item: state.item?._id === card._id ? card : state.item,
    }
}

const setShouldRefetch = (state: CreditCardState, action: SetShouldRefetchCardsAction): CreditCardState => ({
    ...state,
    shouldRefetch: action.shouldRefetch,
})

export const creditCardReducer: Reducer<CreditCardState, CreditCardActions> = (state = initialState, action) => {
    switch (action.type) {
        case CreditCardActionTypes.FETCH_ALL_CARDS:
            return fetchAllCards(state, action)
        case CreditCardActionTypes.FETCH_ONE_CARD:
            return fetchOneCard(state, action)
        case CreditCardActionTypes.SYNC_ONE_CARD:
            return syncOneCard(state, action)
        case CreditCardActionTypes.SET_SHOULD_REFETCH_CARDS:
            return setShouldRefetch(state, action)
        default:
            return state
    }
}
