import {
    ApprovalScopeEnum,
    ApproverTypeEnum,
    ComparisonOperatorEnum,
    CurrencyEnum,
    ExpenseKindEnum,
    LogicalExpression,
    LogicalOperatorEnum,
    OperatorExpression,
    Workflow,
    WorkflowApproval,
    WorkflowCondition,
    WorkflowConditionEnum,
    WorkflowStep,
} from "@finway-group/shared/lib/models"
import { flattenExpression, isOperator, isWorkflowApproval, isWorkflowCondition } from "@finway-group/shared/lib/utils/logicalExpression.utils"

import { parseCurrencyInput } from "Components/currencyInput/config"
import { WorkflowFormRequestTypeEnum, WorkflowFormTriggerType, WorkflowFormTypeEnum, WorkflowFormValuesInterface } from "Components/forms/workflowForm/workflowFormValues.interface"
import { ALL_TRIGGER_OBJECTS, SELECT_ALL_RESOURCES_OPTION } from "Shared/config/consts"

import { isSet } from "./helper.utils"

export interface WorkflowFormData {
    name: string
    scopes: Array<ApprovalScopeEnum>
    triggerConditionExpression: LogicalExpression<WorkflowCondition>
    steps: Array<WorkflowStep>
}

export const workflowFormBasicRequestTypeOptions = [WorkflowFormRequestTypeEnum.PURCHASE, WorkflowFormRequestTypeEnum.INVOICE, WorkflowFormRequestTypeEnum.STANDARD_REIMBURSEMENT]

export const workflowFormReimbursementRequestTypeOptions = [
    WorkflowFormRequestTypeEnum.STANDARD_REIMBURSEMENT,
    WorkflowFormRequestTypeEnum.MILEAGE_REIMBURSEMENT,
    WorkflowFormRequestTypeEnum.HOSPITALITY_REIMBURSEMENT,
    WorkflowFormRequestTypeEnum.PER_DIEM_REIMBURSEMENT,
]

const workflowFormAllRequestTypeOptions = [WorkflowFormRequestTypeEnum.PURCHASE, WorkflowFormRequestTypeEnum.INVOICE, ...workflowFormReimbursementRequestTypeOptions]

export const buildWorkflowDataFromFormValues = (values: WorkflowFormValuesInterface, isTravelEnabled: boolean) => {
    const name = values?.name
    const scopes = buildWorkflowScopesFromFormValues(values)
    const triggerConditionExpression = buildWorkflowTriggerConditionExpressionFromFormValues(values, isTravelEnabled)
    const steps = buildWorkflowStepsFromFormValues(values)

    const workflowFormData: WorkflowFormData = { name, scopes, triggerConditionExpression, steps }
    return workflowFormData
}

export const getRequestAndTriggerTypesOptions = (workflow: Workflow, isTravelEnabled: boolean) => {
    if (!workflow.triggerConditionExpression) return { requestTypes: [], triggerTypes: [] }
    let { requestTypes, triggerTypes } = getRequestAndTriggerTypes(workflow.triggerConditionExpression, workflow.scopes)
    // Transform request type options to tree select parents. ie. if we have all reimbursement request types selected, select the "all reimbursement" option instead of the four of them separately.
    if (isTravelEnabled) requestTypes = updateTravelEnabledRequestTypes(requestTypes)
    else {
        const areAllBasicOptionsSelected = workflowFormBasicRequestTypeOptions.every((requestType: any) => requestTypes.includes(requestType))
        if (areAllBasicOptionsSelected) requestTypes = [WorkflowFormRequestTypeEnum.ALL]
    }

    return { requestTypes, triggerTypes }
}

const updateTravelEnabledRequestTypes = (requestTypes: Array<WorkflowFormRequestTypeEnum>) => {
    if (workflowFormAllRequestTypeOptions.every((requestType: any) => requestTypes.includes(requestType))) {
        requestTypes = [WorkflowFormRequestTypeEnum.ALL]
    } else if (workflowFormReimbursementRequestTypeOptions.every((requestType: any) => requestTypes.includes(requestType))) {
        requestTypes = requestTypes.filter((requestType: any) => !workflowFormReimbursementRequestTypeOptions.includes(requestType))
        requestTypes.push(WorkflowFormRequestTypeEnum.ALL_REIMBURSEMENTS)
    } else if (requestTypes.includes(WorkflowFormRequestTypeEnum.ALL_REIMBURSEMENTS)) {
        requestTypes = requestTypes.filter((requestType: any) => !workflowFormReimbursementRequestTypeOptions.includes(requestType))
    }

    return requestTypes
}

export const getWorkflowType = (workflow: Workflow): WorkflowFormTypeEnum => {
    if (!workflow.triggerConditionExpression) {
        return WorkflowFormTypeEnum.REQUEST
    }

    const triggerConditions = flattenExpression(workflow.triggerConditionExpression)
    const expenseKindCondition = triggerConditions.find((trigger) => trigger.condition === WorkflowConditionEnum.EXPENSE_KIND)

    if (expenseKindCondition?.expenseKinds?.includes(ExpenseKindEnum.TRIP_FOLDER)) return WorkflowFormTypeEnum.FOLDER

    return WorkflowFormTypeEnum.REQUEST
}

export const buildFormValuesFromWorkflow = (workflow: Workflow, autoApproverId: string, isTravelEnabled: boolean): WorkflowFormValuesInterface => {
    const { scopes } = workflow
    const { name } = workflow
    const { order } = workflow
    const workflowType = getWorkflowType(workflow)

    const { requestTypes, triggerTypes } = getRequestAndTriggerTypesOptions(workflow, isTravelEnabled)

    const steps = []
    for (const step of workflow.steps) {
        const formStep: any = {}
        if (step.conditionExpression && isWorkflowCondition(step.conditionExpression)) {
            formStep.amount = step.conditionExpression.amount
            formStep.currency = step.conditionExpression.currency
            formStep.condition = step.conditionExpression.condition
        }

        const getFormApproverValue = (expression: any) => {
            const formApproverValue = expression.workflowApprover === autoApproverId ? ApproverTypeEnum.AUTO_APPROVER : expression.workflowApprover ?? expression.approverType
            return formApproverValue
        }

        if (isWorkflowApproval(step.approvalExpression)) {
            formStep.approver = getFormApproverValue(step.approvalExpression)
            formStep.otherApprovers = []
        } else if (isOperator(step.approvalExpression)) {
            formStep.operator = step.approvalExpression.operator
            const otherApprovers = []
            for (const [idx, approvalExpression] of step.approvalExpression.operands.entries()) {
                if (isWorkflowApproval(approvalExpression)) {
                    const formApproverValue = getFormApproverValue(approvalExpression)
                    if (idx === 0) {
                        formStep.approver = formApproverValue
                    } else {
                        otherApprovers.push(formApproverValue)
                    }
                }
                formStep.otherApprovers = otherApprovers
            }
        }
        steps.push(formStep)
    }

    const workflowFormValues = { scopes, name, requestTypes, order, triggerTypes, steps, workflowType }

    return workflowFormValues
}

const buildWorkflowScopesFromFormValues = (values: WorkflowFormValuesInterface) => {
    const scopes: Array<ApprovalScopeEnum> = []

    // folders only have Expense Invoice approval scope.
    if (values.workflowType === WorkflowFormTypeEnum.FOLDER) {
        scopes.push(ApprovalScopeEnum.EXPENSE_INVOICE)
        return scopes
    }

    if (!values.requestTypes?.length) return scopes

    if (values.requestTypes.includes(WorkflowFormRequestTypeEnum.ALL)) {
        scopes.push(ApprovalScopeEnum.EXPENSE_PURCHASE, ApprovalScopeEnum.EXPENSE_INVOICE)
    } else {
        if (values.requestTypes.includes(WorkflowFormRequestTypeEnum.PURCHASE)) {
            scopes.push(ApprovalScopeEnum.EXPENSE_PURCHASE)
        }
        if (
            values.requestTypes.includes(WorkflowFormRequestTypeEnum.INVOICE) ||
            values.requestTypes.includes(WorkflowFormRequestTypeEnum.ALL_REIMBURSEMENTS) ||
            workflowFormReimbursementRequestTypeOptions.some((value) => values.requestTypes!.includes(value))
        ) {
            scopes.push(ApprovalScopeEnum.EXPENSE_INVOICE)
        }
    }

    return scopes
}

const buildExpenseKindTriggerConditionFromFormValues = (values: WorkflowFormValuesInterface, isTravelEnabled: boolean) => {
    const expenseKindCondition: WorkflowCondition = { condition: WorkflowConditionEnum.EXPENSE_KIND, expenseKinds: [] }

    switch (values.workflowType) {
        case WorkflowFormTypeEnum.FOLDER:
            expenseKindCondition.expenseKinds.push(ExpenseKindEnum.TRIP_FOLDER)
            break
        case WorkflowFormTypeEnum.REQUEST: {
            if (!values.requestTypes?.length) break

            if (values.requestTypes.includes(WorkflowFormRequestTypeEnum.ALL)) {
                const commonExpenseKinds = [ExpenseKindEnum.ONE_TIME_EXPENSE, ExpenseKindEnum.SUBSCRIPTION, ExpenseKindEnum.RECURRING_SUBSCRIPTION, ExpenseKindEnum.STANDARD]
                const expenseKindsToPush = isTravelEnabled
                    ? [...commonExpenseKinds, ExpenseKindEnum.MILEAGE, ExpenseKindEnum.HOSPITALITY, ExpenseKindEnum.PER_DIEM]
                    : commonExpenseKinds

                expenseKindCondition.expenseKinds.push(...expenseKindsToPush)
            } else {
                if (values.requestTypes.includes(WorkflowFormRequestTypeEnum.PURCHASE)) {
                    expenseKindCondition.expenseKinds.push(ExpenseKindEnum.ONE_TIME_EXPENSE, ExpenseKindEnum.SUBSCRIPTION)
                }

                if (values.requestTypes.includes(WorkflowFormRequestTypeEnum.INVOICE)) {
                    expenseKindCondition.expenseKinds.push(ExpenseKindEnum.ONE_TIME_EXPENSE, ExpenseKindEnum.SUBSCRIPTION, ExpenseKindEnum.RECURRING_SUBSCRIPTION)
                }

                if (values.requestTypes.includes(WorkflowFormRequestTypeEnum.ALL_REIMBURSEMENTS)) {
                    expenseKindCondition.expenseKinds.push(ExpenseKindEnum.STANDARD, ExpenseKindEnum.MILEAGE, ExpenseKindEnum.HOSPITALITY, ExpenseKindEnum.PER_DIEM)
                } else {
                    if (values.requestTypes.includes(WorkflowFormRequestTypeEnum.STANDARD_REIMBURSEMENT)) {
                        expenseKindCondition.expenseKinds.push(ExpenseKindEnum.STANDARD)
                    }
                    if (values.requestTypes.includes(WorkflowFormRequestTypeEnum.MILEAGE_REIMBURSEMENT)) {
                        expenseKindCondition.expenseKinds.push(ExpenseKindEnum.MILEAGE)
                    }
                    if (values.requestTypes.includes(WorkflowFormRequestTypeEnum.HOSPITALITY_REIMBURSEMENT)) {
                        expenseKindCondition.expenseKinds.push(ExpenseKindEnum.HOSPITALITY)
                    }
                    if (values.requestTypes.includes(WorkflowFormRequestTypeEnum.PER_DIEM_REIMBURSEMENT)) {
                        expenseKindCondition.expenseKinds.push(ExpenseKindEnum.PER_DIEM)
                    }
                }
            }
            break
        }
    }

    expenseKindCondition.expenseKinds = [...new Set(expenseKindCondition.expenseKinds)]
    return expenseKindCondition
}

const buildOtherTriggerConditionsFromFormValues = (values: WorkflowFormValuesInterface) => {
    const conditions = []
    for (const triggerType of values.triggerTypes) {
        const extraFields: any = {}
        if (triggerType.referenceIds?.includes(ALL_TRIGGER_OBJECTS)) {
            extraFields.isGeneralized = true
        } else {
            switch (triggerType.condition) {
                case WorkflowConditionEnum.COST_CENTER:
                    extraFields.costCenters = triggerType.referenceIds
                    extraFields.isGeneralized = false
                    break
                case WorkflowConditionEnum.VENDOR:
                    extraFields.vendors = triggerType.referenceIds
                    extraFields.isGeneralized = false
                    break
                case WorkflowConditionEnum.REQUESTER:
                    extraFields.requesters = triggerType.referenceIds
                    extraFields.isGeneralized = false
                    break
                case WorkflowConditionEnum.COST_CENTER_2:
                    extraFields.costCenters2 = triggerType.referenceIds
                    extraFields.isGeneralized = false
                    break
                case WorkflowConditionEnum.NET_AMOUNT:
                    extraFields.amount = parseCurrencyInput(triggerType.amount)
                    extraFields.currency = triggerType.currency
                    extraFields.comparison = ComparisonOperatorEnum.GREATER_THAN
                    break
                default:
                    break
            }
        }

        const condition: WorkflowCondition = {
            condition: triggerType.condition,
            ...extraFields,
        }
        conditions.push(condition)
    }
    return conditions
}

const buildWorkflowTriggerConditionExpressionFromFormValues = (values: WorkflowFormValuesInterface, isTravelEnabled: boolean) => {
    const conditionExpressions = []
    // Build expense kind condition
    if (values.requestTypes?.length || values.workflowType === WorkflowFormTypeEnum.FOLDER) {
        const expenseKindCondition = buildExpenseKindTriggerConditionFromFormValues(values, isTravelEnabled)
        if (expenseKindCondition.expenseKinds.length) conditionExpressions.push(expenseKindCondition)
    }

    // Build cost center, requester, cost center 2, vendor and amount conditions.
    if (values.triggerTypes?.length) {
        const otherConditions = buildOtherTriggerConditionsFromFormValues(values)
        if (otherConditions.length) conditionExpressions.push(...otherConditions)
    }

    if (conditionExpressions.length === 1) {
        return conditionExpressions[0]
    }

    const triggerConditionAndExpression: LogicalExpression<WorkflowCondition> = {
        operator: LogicalOperatorEnum.AND,
        operands: conditionExpressions,
    }
    return triggerConditionAndExpression
}

const buildWorkflowStepsFromFormValues = (values: WorkflowFormValuesInterface) => {
    const steps = []
    for (const formStep of values.steps) {
        let greaterThanCondition
        if (formStep.condition === WorkflowConditionEnum.NET_AMOUNT) {
            greaterThanCondition = createGreaterThanAmountCondition(parseCurrencyInput(formStep.amount), formStep.currency!)
        }

        const isUncalculatedApprover = [ApproverTypeEnum.RESPONSIBLE_USER, ApproverTypeEnum.SUPERIOR, ApproverTypeEnum.AUTO_APPROVER, ApproverTypeEnum.REQUESTER].includes(
            formStep.approver as any,
        )
        const approval = {
            approverType: isUncalculatedApprover ? formStep.approver : ApproverTypeEnum.USER,
            workflowApprover: isUncalculatedApprover ? undefined : formStep.approver,
        } as WorkflowApproval

        if (!formStep.otherApprovers?.length) {
            steps.push({ conditionExpression: greaterThanCondition, approvalExpression: approval })
        } else if (formStep.operator) {
            const approvals = [approval]
            for (const otherApprover of formStep.otherApprovers) {
                const isUncalculatedApprover = [ApproverTypeEnum.RESPONSIBLE_USER, ApproverTypeEnum.SUPERIOR, ApproverTypeEnum.AUTO_APPROVER, ApproverTypeEnum.REQUESTER].includes(
                    otherApprover as any,
                )
                const approval = {
                    approverType: isUncalculatedApprover ? otherApprover : ApproverTypeEnum.USER,
                    workflowApprover: isUncalculatedApprover ? undefined : otherApprover,
                } as WorkflowApproval
                approvals.push(approval)
            }

            const otherApproversExpression: OperatorExpression<WorkflowApproval> = {
                operator: formStep.operator,
                operands: approvals,
            }

            steps.push({ conditionExpression: greaterThanCondition, approvalExpression: otherApproversExpression })
        }
    }

    return steps as Array<WorkflowStep>
}

const getRequestAndTriggerTypes = (
    expression: LogicalExpression<WorkflowCondition>,
    scopes: Array<ApprovalScopeEnum>,
    requestTypes: Array<WorkflowFormRequestTypeEnum> = [],
    triggerTypes: Array<WorkflowFormTriggerType> = [],
) => {
    if (isOperator<WorkflowCondition>(expression)) {
        for (const operand of expression.operands) {
            getRequestAndTriggerTypes(operand, scopes, requestTypes, triggerTypes)
        }
    } else if (expression.condition === WorkflowConditionEnum.EXPENSE_KIND) {
        if (scopes.includes(ApprovalScopeEnum.EXPENSE_PURCHASE)) {
            requestTypes.push(WorkflowFormRequestTypeEnum.PURCHASE)
        }

        if (scopes.includes(ApprovalScopeEnum.EXPENSE_INVOICE)) {
            if (
                [ExpenseKindEnum.ONE_TIME_EXPENSE, ExpenseKindEnum.SUBSCRIPTION, ExpenseKindEnum.RECURRING_SUBSCRIPTION].every((expenseKind: any) =>
                    expression.expenseKinds.includes(expenseKind),
                )
            ) {
                requestTypes.push(WorkflowFormRequestTypeEnum.INVOICE)
            }

            if (expression.expenseKinds.includes(ExpenseKindEnum.STANDARD)) {
                requestTypes.push(WorkflowFormRequestTypeEnum.STANDARD_REIMBURSEMENT)
            }
            if (expression.expenseKinds.includes(ExpenseKindEnum.MILEAGE)) {
                requestTypes.push(WorkflowFormRequestTypeEnum.MILEAGE_REIMBURSEMENT)
            }
            if (expression.expenseKinds.includes(ExpenseKindEnum.HOSPITALITY)) {
                requestTypes.push(WorkflowFormRequestTypeEnum.HOSPITALITY_REIMBURSEMENT)
            }
            if (expression.expenseKinds.includes(ExpenseKindEnum.PER_DIEM)) {
                requestTypes.push(WorkflowFormRequestTypeEnum.PER_DIEM_REIMBURSEMENT)
            }
            if (expression.expenseKinds.includes(ExpenseKindEnum.TRIP_FOLDER)) {
                requestTypes.push(WorkflowFormRequestTypeEnum.TRIP_FOLDER)
            }
        }
    } else {
        // Build the triggerType
        const triggerType = {
            condition: expression.condition,
            referenceIds: expression.isGeneralized ? [ALL_TRIGGER_OBJECTS] : expression.costCenters || expression.vendors || expression.requesters || expression.costCenters2,
            amount: expression.amount,
            currency: expression.currency,
            comparison: expression.comparison,
        }
        triggerTypes.push(triggerType)
    }

    return { requestTypes, triggerTypes }
}

const createGreaterThanAmountCondition = (amount: number, currency: CurrencyEnum) => ({
    condition: WorkflowConditionEnum.NET_AMOUNT,
    amount,
    currency,
    comparison: ComparisonOperatorEnum.GREATER_THAN,
})

export const getWorkFlowBasicRequestTypeLabels = (): Array<string> => [
    WorkflowFormRequestTypeEnum.ALL,
    ...Object.values(workflowFormBasicRequestTypeOptions).map((requestType: WorkflowFormRequestTypeEnum) =>
        requestType === WorkflowFormRequestTypeEnum.STANDARD_REIMBURSEMENT ? requestType.split("_")[1] : requestType,
    ),
]

export const buildWorkflowTriggerConditionReferences = (workflow: Workflow) => {
    const triggerConditions = workflow.triggerConditionExpression ? flattenExpression(workflow.triggerConditionExpression) : []

    return triggerConditions.flatMap(({ costCenters, costCenters2, requesters, vendors }) => [
        ...(costCenters ?? []),
        ...(costCenters2 ?? []),
        ...(requesters ?? []),
        ...(vendors ?? []),
    ])
}

export const buildFilterCondition = ({
    workflow,
    triggerConditionReferences,
    workflowConditionEnum,
    filterValue,
}: {
    workflow: Workflow
    triggerConditionReferences: Array<string>
    workflowConditionEnum: WorkflowConditionEnum
    filterValue: string | undefined
}) => {
    const shouldFilterForAllResources = filterValue === SELECT_ALL_RESOURCES_OPTION
    if (shouldFilterForAllResources) return filterForGeneralizedTriggerConditions(workflow, workflowConditionEnum)

    return triggerConditionReferences.includes(filterValue!)
}

const filterForGeneralizedTriggerConditions = (workflow: Workflow, workflowConditionEnum: WorkflowConditionEnum) => {
    const triggerConditions = workflow.triggerConditionExpression ? flattenExpression(workflow.triggerConditionExpression) : []
    const doesGeneralizedTriggerConditionExist = triggerConditions.some(
        (condition: WorkflowCondition) => isSet(condition.isGeneralized) && condition.isGeneralized === true && condition.condition === workflowConditionEnum,
    )

    return doesGeneralizedTriggerConditionExist
}
