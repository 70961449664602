import { CurrencyEnum, PerDiem, PerDiemDailyExpenses, PriceIntervalEnum } from "@finway-group/shared/lib/models"
import { calculateDayReimbursement, calculateTotalReimbursement } from "@finway-group/shared/lib/utils"
import { Table, Tag } from "antd"
import { ColumnsType } from "antd/lib/table"
import moment from "moment"
import React, { useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"

import PriceLabel from "Components/priceLabel"
import { PER_DIEM_BREAKFAST_RATE, PER_DIEM_DINNER_RATE, PER_DIEM_LUNCH_RATE } from "Shared/config/consts"
import { usePerDiemDestinationMap, usePerDiemDestinations } from "Shared/hooks/perDiemDestination.hooks"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"
import { isPerDiemTripNotReimbursable } from "Shared/utils/perDiem.utils"

import { HoverableInfo } from "../auxilliary/HoverableInfo"

interface PerDiemReimbursementsPerDayTableInterface {
    perDiemExpense: PerDiem
    isDestinationFormChanged?: boolean
}

const PER_DIEM_PAGE_SIZE = 10

export const PerDiemReimbursementsPerDayTable: React.FC<PerDiemReimbursementsPerDayTableInterface> = ({ perDiemExpense, isDestinationFormChanged }) => {
    const { t } = useTranslation()
    const { destinations: perDiemDestinations, isReady } = usePerDiemDestinations()
    const { destinationMap } = usePerDiemDestinationMap()
    const isNotReimbursable = useMemo(() => isPerDiemTripNotReimbursable(perDiemExpense, perDiemDestinations), [perDiemExpense])
    const dailyExpenses = perDiemExpense.dailyExpenses ?? []
    const totalReimbursement = useMemo(() => calculateTotalReimbursement(dailyExpenses, perDiemDestinations), [perDiemExpense])
    const [currentPage, setCurrentPage] = useStateIfMounted(1)
    const getDayNumber = useCallback((tableIndex: number) => (currentPage - 1) * PER_DIEM_PAGE_SIZE + tableIndex, [currentPage])

    if (!isReady) {
        return null
    }

    const columns: ColumnsType = [
        {
            title: t("label:per_diem.day"),
            key: "date",
            dataIndex: "date",
            width: 100,
            render: (date: PerDiemDailyExpenses["date"], tripInfo: PerDiemDailyExpenses) => {
                const destinationRates = destinationMap.get(tripInfo.destination)!
                const dateMoment = date ? moment(date) : undefined
                return (
                    <div>
                        <p className="block">{dateMoment?.format("dddd")}</p>
                        <p className="block text-xs">({dateMoment?.format("DD.MM.YYYY")})</p>
                        {destinationRates?.country && <p className="block text-xs text-gray-800">{destinationRates?.country}</p>}
                        <p className="block text-xs text-gray-800">{destinationRates?.destination}</p>
                    </div>
                )
            },
            fixed: "left",
        },
        {
            title: t("label:per_diem.allowance"),
            key: "allowance",
            width: 140,
            render: (day: PerDiemDailyExpenses, _record: any, index: number) => {
                const destinationRates = destinationMap.get(day.destination)!
                let value = destinationRates.fullDay
                let tag = <Tag className="ant-tag-green block">{t("label:per_diem.full_day")}</Tag>

                const dayNumber = getDayNumber(index)

                if (dayNumber === 0 || dayNumber === dailyExpenses.length - 1) {
                    const suffix = dayNumber === 0 ? "_start" : "_return"
                    value = destinationRates.halfDay
                    tag = <Tag className="ant-tag-green block">{t(`label:per_diem.half_day${suffix}`)}</Tag>
                }
                return (
                    <div className="flex flex-col justify-center align-center gap-10">
                        <div>{tag}</div>
                        <p className="text-center">
                            <PriceLabel value={value || 0} currency={CurrencyEnum.EUR} interval={PriceIntervalEnum.ONE_TIME} showFullNumber />
                        </p>
                    </div>
                )
            },
            fixed: "left",
        },
        {
            title: t("label:per_diem.breakfast"),
            key: "breakfast",
            width: 140,
            render: (day: PerDiemDailyExpenses, _record: any) => {
                const destinationRates = destinationMap.get(day.destination)!

                if (day.expenditure.breakfast) {
                    const value = (destinationRates.fullDay || 0) * PER_DIEM_BREAKFAST_RATE
                    return (
                        <div className="flex flex-col justify-center align-center gap-10">
                            <div>
                                <Tag className="ant-tag-red block">-{PER_DIEM_BREAKFAST_RATE * 100}%</Tag>
                            </div>
                            <p className="text-center">
                                <PriceLabel value={-value || 0} currency={CurrencyEnum.EUR} interval={PriceIntervalEnum.ONE_TIME} showFullNumber />
                            </p>
                        </div>
                    )
                }

                return (
                    <div className="flex flex-col justify-center align-center gap-10">
                        <div className="flex flex-col justify-start align-center">
                            <Tag className="ant-tag-red block">{t("label:not_provided")}</Tag>
                        </div>
                        <p className="text-center">n/a</p>
                    </div>
                )
            },
        },
        {
            title: t("label:per_diem.lunch"),
            key: "lunch",
            width: 140,
            render: (day: PerDiemDailyExpenses, _record: any) => {
                const destinationRates = destinationMap.get(day.destination)!
                if (day.expenditure.lunch) {
                    const value = (destinationRates.fullDay || 0) * PER_DIEM_LUNCH_RATE
                    return (
                        <div className="flex flex-col justify-center align-center gap-10">
                            <div>
                                <Tag className="ant-tag-red block">-{PER_DIEM_LUNCH_RATE * 100}%</Tag>
                            </div>
                            <p className="text-center">
                                <PriceLabel value={-value || 0} currency={CurrencyEnum.EUR} interval={PriceIntervalEnum.ONE_TIME} showFullNumber />
                            </p>
                        </div>
                    )
                }

                return (
                    <div className="flex flex-col justify-center align-center gap-10">
                        <div className="flex flex-col justify-start align-center">
                            <Tag className="ant-tag-red block">{t("label:not_provided")}</Tag>
                        </div>
                        <p className="text-center">n/a</p>
                    </div>
                )
            },
        },
        {
            title: t("label:per_diem.dinner"),
            key: "dinner",
            width: 140,
            render: (day: PerDiemDailyExpenses, _record: any) => {
                const destinationRates = destinationMap.get(day.destination)!
                if (day.expenditure.dinner) {
                    const value = (destinationRates.fullDay || 0) * PER_DIEM_DINNER_RATE
                    return (
                        <div className="flex flex-col justify-center align-center gap-10">
                            <div>
                                <Tag className="ant-tag-red block">-{PER_DIEM_DINNER_RATE * 100}%</Tag>
                            </div>
                            <p className="text-center">
                                <PriceLabel value={-value || 0} currency={CurrencyEnum.EUR} interval={PriceIntervalEnum.ONE_TIME} showFullNumber />
                            </p>
                        </div>
                    )
                }

                return (
                    <div className="flex flex-col justify-center align-center gap-10">
                        <div className="flex flex-col justify-start align-center">
                            <Tag className="ant-tag-red block">{t("label:not_provided")}</Tag>
                        </div>
                        <p className="text-center">n/a</p>
                    </div>
                )
            },
        },
        {
            title: t("label:per_diem.accommodation"),
            key: "accommodation",
            width: 140,
            render: (day: PerDiemDailyExpenses, _record: any, index: number) => {
                const destinationRates = destinationMap.get(day.destination)!

                const dayNumber = getDayNumber(index)
                const isLastDay = dayNumber === dailyExpenses.length - 1
                // Last day will display "-" because no accomodation could be provided
                if (isLastDay) {
                    return (
                        <div className="flex items-center gap-x-2">
                            <p className="w-full text-center">-</p>
                        </div>
                    )
                }

                // Accommodation can only be provided before the last day.
                if (!day.expenditure.accommodation) {
                    const value = destinationRates.accommodation || 0
                    return (
                        <div className="flex flex-col justify-center align-center gap-10">
                            <div>
                                <Tag className="ant-tag-red block">{t("label:not_provided")}</Tag>
                            </div>
                            <p className="text-center">
                                <PriceLabel value={value} currency={CurrencyEnum.EUR} interval={PriceIntervalEnum.ONE_TIME} showFullNumber />
                            </p>
                        </div>
                    )
                }

                return <Tag className="ant-tag-green block">{t("label:provided")}</Tag>
            },
        },
        {
            title: (
                <div className="flex items-center gap-x-2">
                    <span>{t("label:per_diem.reimbursement")}</span>
                    <HoverableInfo placement="bottom" message={t("info:per_diem.reimbursement_calc")} />
                </div>
            ),
            key: "reimbursement",
            width: 145,
            render: (day: PerDiemDailyExpenses, _record: any, index: number) => {
                const dayNumber = getDayNumber(index)
                const destination = destinationMap.get(day.destination)!
                const totalValue = calculateDayReimbursement(day, dailyExpenses, dayNumber, destination)

                return (
                    <div className="flex flex-col justify-center align-center gap-10">
                        <p className="text-center">
                            <PriceLabel value={totalValue || 0} currency={CurrencyEnum.EUR} interval={PriceIntervalEnum.ONE_TIME} showFullNumber />
                        </p>
                    </div>
                )
            },
            fixed: "right",
        },
    ]

    const tableData = isNotReimbursable ? [] : dailyExpenses

    return (
        <div className={isDestinationFormChanged ? "opacity-25 cursor-not-allowed" : ""}>
            <Table<PerDiemDailyExpenses>
                className={isDestinationFormChanged ? "pointer-events-none" : ""}
                locale={isNotReimbursable ? { emptyText: t("confirm:request.per_diem_less_than_8h.message") } : {}}
                columns={columns}
                dataSource={tableData}
                bordered
                rowKey={(record) => `per-diem-per-day-table-${moment(record.date).valueOf()}`}
                scroll={{ x: "max-content" }}
                pagination={{
                    pageSize: PER_DIEM_PAGE_SIZE,
                    hideOnSinglePage: true,
                    onChange: (page) => {
                        setCurrentPage(page)
                    },
                    showSizeChanger: false,
                }}
                footer={() => (
                    <div className="w-full flex justify-end gap-8">
                        <span>{t("info:per_diem.total_reimbursable_amount")} </span>
                        <span className="font-bold">
                            <PriceLabel currency={CurrencyEnum.EUR} value={isNotReimbursable ? 0 : totalReimbursement} interval={PriceIntervalEnum.ONE_TIME} />
                        </span>
                    </div>
                )}
                sticky
            />
        </div>
    )
}
