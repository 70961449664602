import { ExclamationCircleOutlined } from "@ant-design/icons"
import { Currencies } from "@finway-group/shared/lib/consts"
import { CurrencyEnum, WorkflowConditionEnum, currencyEnumArray } from "@finway-group/shared/lib/models"
import { Button, Col, Form, Image, Row, Select, Tooltip } from "antd"
import React, { useEffect } from "react"
import { Plus as PlusIcon } from "react-feather"
import { useTranslation } from "react-i18next"

import CurrencyInput from "Components/currencyInput"
import FormItemCurrency from "Components/form/formItemCurrency"
import { TrashIcon } from "Components/icons"
import { useCostCenters2 } from "Shared/hooks/costCenter2.hooks"
import { useSubCostCenters } from "Shared/hooks/costCenter.hooks"
import { useEmployees, useLoggedInEmployee } from "Shared/hooks/employee.hooks"
import { useVendors } from "Shared/hooks/vendor.hooks"
import { getTooltipPopupContainer } from "Shared/utils/helper.utils"
import { getPopupAnchor } from "Shared/utils/popup.utils"

import { WorkflowFormResourceTypeEnum, WorkflowTriggerObjectSelect } from "./triggerObjectSelect"
import { WorkflowFormRequestTypeEnum, WorkflowFormTriggerType, WorkflowFormTypeEnum, WorkflowFormValuesInterface } from "./workflowFormValues.interface"

interface WorkflowFormTriggerTypesInterface {
    formValues: WorkflowFormValuesInterface
    setFormValues: (formUpdate: Partial<WorkflowFormValuesInterface>) => void
    rules: any
}

const DEFAULT_TRIGGER_CONDITIONS = Object.values(WorkflowConditionEnum).filter((condition) => condition !== WorkflowConditionEnum.EXPENSE_KIND)
const FOLDER_ALLOWED_TRIGGER_CONDITIONS = [WorkflowConditionEnum.COST_CENTER, WorkflowConditionEnum.COST_CENTER_2, WorkflowConditionEnum.REQUESTER]
const PER_DIEM_AND_MILEAGE_ALLOWED_TRIGGER_CONDITIONS = [
    WorkflowConditionEnum.COST_CENTER,
    WorkflowConditionEnum.COST_CENTER_2,
    WorkflowConditionEnum.REQUESTER,
    WorkflowConditionEnum.NET_AMOUNT,
]

// prettier-ignore
const areOnlyPerDiemOrMileageOrBothSelected = (requestTypes: Array<WorkflowFormRequestTypeEnum>) => {
    if (!requestTypes.length) return false
    const includesSelectedTypes = requestTypes.filter((type) => type === WorkflowFormRequestTypeEnum.PER_DIEM_REIMBURSEMENT || type === WorkflowFormRequestTypeEnum.MILEAGE_REIMBURSEMENT).length
    return includesSelectedTypes === requestTypes.length
}

const getConditionOptions = (workflowType: WorkflowFormTypeEnum, requestTypes: Array<WorkflowFormRequestTypeEnum>) => {
    if (workflowType === WorkflowFormTypeEnum.FOLDER) return FOLDER_ALLOWED_TRIGGER_CONDITIONS
    if (areOnlyPerDiemOrMileageOrBothSelected(requestTypes)) return PER_DIEM_AND_MILEAGE_ALLOWED_TRIGGER_CONDITIONS
    return DEFAULT_TRIGGER_CONDITIONS
}

export const WorkflowFormTriggerTypes: React.FC<WorkflowFormTriggerTypesInterface> = ({ formValues, setFormValues, rules }) => {
    const { t } = useTranslation()
    const costCenters = useSubCostCenters()
    const vendors = useVendors()
    const employees = useEmployees()
    const costCenters2 = useCostCenters2()
    const isFolderWorkflow = formValues.workflowType === WorkflowFormTypeEnum.FOLDER
    const loggedInEmployee = useLoggedInEmployee()

    // On triggerType=request, mileage & per diem do not support the VENDOR option. If only those two are selected, remove VENDOR from the trigger options.
    useEffect(() => {
        const onlyPerDiemAndOrMileageAreSelected = areOnlyPerDiemOrMileageOrBothSelected(formValues.requestTypes)
        if (onlyPerDiemAndOrMileageAreSelected) {
            const triggerTypes = formValues.triggerTypes.filter((triggerType) => triggerType.condition !== WorkflowConditionEnum.VENDOR)
            if (!triggerTypes.length) triggerTypes.push({ condition: WorkflowConditionEnum.COST_CENTER })
            setFormValues({ triggerTypes })
        }
    }, [formValues.requestTypes.length])

    const handleTriggerTypeConditionSelect = (index: number, condition: WorkflowConditionEnum) => {
        const triggerTypes = [...formValues.triggerTypes]
        triggerTypes[index].referenceIds = []
        triggerTypes[index].condition = condition
        if (triggerTypes[index].condition === WorkflowConditionEnum.NET_AMOUNT) {
            triggerTypes[index].currency = loggedInEmployee.settings.globalCurrency ?? CurrencyEnum.EUR
        }
        setFormValues({ triggerTypes })
    }

    const handleTriggerTypeReferenceSelect = (index: number, referenceIds: any) => {
        const triggerTypes = [...formValues.triggerTypes]
        triggerTypes[index].referenceIds = referenceIds
        setFormValues({ triggerTypes })
    }

    const addTriggerType = () => {
        const conditionsAlreadyInTheForm = formValues.triggerTypes.map((triggerType) => triggerType.condition)
        const conditions = getConditionOptions(formValues.workflowType, formValues.requestTypes)
        const condition = conditions.filter((condition) => !conditionsAlreadyInTheForm.includes(condition))[0]
        const newTriggerType: WorkflowFormTriggerType = { condition }
        if (newTriggerType.condition === WorkflowConditionEnum.NET_AMOUNT) {
            newTriggerType.currency = loggedInEmployee.settings.globalCurrency ?? CurrencyEnum.EUR
        }
        const triggerTypes = [...formValues.triggerTypes, newTriggerType]
        setFormValues({ triggerTypes })
    }

    const removeTriggerType = (index: number) => {
        const triggerTypes = formValues.triggerTypes.slice(0, index).concat(formValues.triggerTypes.slice(index + 1))
        setFormValues({ triggerTypes })
    }

    const renderTriggerTypeRow = (triggerType: WorkflowFormValuesInterface["triggerTypes"][0], index: number) => {
        let resources = []
        let resourceType

        switch (triggerType.condition) {
            case WorkflowConditionEnum.VENDOR:
                resources = vendors
                resourceType = WorkflowFormResourceTypeEnum.VENDOR
                break
            case WorkflowConditionEnum.REQUESTER:
                resources = employees
                resourceType = WorkflowFormResourceTypeEnum.EMPLOYEE
                break
            case WorkflowConditionEnum.COST_CENTER_2:
                resources = costCenters2
                resourceType = WorkflowFormResourceTypeEnum.COST_CENTER_2
                break
            case WorkflowConditionEnum.COST_CENTER:
            default:
                resources = costCenters
                resourceType = WorkflowFormResourceTypeEnum.COST_CENTER
        }

        const onlyPerDiemAndOrMileageAreSelected = areOnlyPerDiemOrMileageOrBothSelected(formValues.requestTypes)

        const hasCostCentersOption =
            costCenters.length > 0 &&
            (formValues.triggerTypes[index].condition === WorkflowConditionEnum.COST_CENTER ||
                !formValues.triggerTypes.map((triggerType) => triggerType.condition).includes(WorkflowConditionEnum.COST_CENTER))
        const hasVendorOption =
            vendors.length > 0 &&
            (formValues.triggerTypes[index].condition === WorkflowConditionEnum.VENDOR ||
                !formValues.triggerTypes.map((triggerType) => triggerType.condition).includes(WorkflowConditionEnum.VENDOR)) &&
            !onlyPerDiemAndOrMileageAreSelected
        const hasRequestersOption =
            employees.length > 0 &&
            (formValues.triggerTypes[index].condition === WorkflowConditionEnum.REQUESTER ||
                !formValues.triggerTypes.map((triggerType) => triggerType.condition).includes(WorkflowConditionEnum.REQUESTER))
        const hasCostCenters2Option =
            costCenters.length > 0 &&
            (formValues.triggerTypes[index].condition === WorkflowConditionEnum.COST_CENTER_2 ||
                !formValues.triggerTypes.map((triggerType) => triggerType.condition).includes(WorkflowConditionEnum.COST_CENTER_2))
        const hasNetAmountOption =
            formValues.triggerTypes[index].condition === WorkflowConditionEnum.NET_AMOUNT ||
            !formValues.triggerTypes.map((triggerType) => triggerType.condition).includes(WorkflowConditionEnum.NET_AMOUNT)

        return (
            <Row gutter={[16, 16]} key={index}>
                <Col span={10}>
                    <Form.Item
                        label={
                            <>
                                <p>{t("input:workflow.trigger.trigger_type")}</p>
                                {[
                                    WorkflowFormRequestTypeEnum.ALL,
                                    WorkflowFormRequestTypeEnum.ALL_REIMBURSEMENTS,
                                    WorkflowFormRequestTypeEnum.PER_DIEM_REIMBURSEMENT,
                                    WorkflowFormRequestTypeEnum.MILEAGE_REIMBURSEMENT,
                                ].some((type) => formValues.requestTypes.includes(type)) &&
                                    formValues.triggerTypes[index].condition === WorkflowConditionEnum.VENDOR && (
                                        <Tooltip
                                            getPopupContainer={getTooltipPopupContainer}
                                            title={t("tooltips:vendor_trigger_type_do_not_apply_to_mileage_and_per_diem")}
                                            placement="right"
                                            className="align-middle"
                                        >
                                            <ExclamationCircleOutlined className="ml-6" />
                                        </Tooltip>
                                    )}
                            </>
                        }
                        name={["triggerTypes", index, "condition"]}
                        rules={rules.triggerType.condition}
                    >
                        <Select
                            getPopupContainer={getPopupAnchor()}
                            defaultValue={WorkflowConditionEnum.VENDOR}
                            onChange={(value) => handleTriggerTypeConditionSelect(index, value)}
                        >
                            {hasCostCentersOption && <Select.Option value={WorkflowConditionEnum.COST_CENTER}>{t("input:workflow.trigger.cost_center")}</Select.Option>}
                            {hasCostCenters2Option && <Select.Option value={WorkflowConditionEnum.COST_CENTER_2}>{t("input:workflow.trigger.cost_center_2")}</Select.Option>}
                            {hasVendorOption && !isFolderWorkflow && <Select.Option value={WorkflowConditionEnum.VENDOR}>{t("input:workflow.trigger.vendor")}</Select.Option>}
                            {hasRequestersOption && <Select.Option value={WorkflowConditionEnum.REQUESTER}>{t("input:workflow.trigger.requester")}</Select.Option>}
                            {hasNetAmountOption && !isFolderWorkflow && (
                                <Select.Option value={WorkflowConditionEnum.NET_AMOUNT}>{t("input:workflow.trigger.amount")}</Select.Option>
                            )}
                        </Select>
                    </Form.Item>
                </Col>

                {triggerType.condition === WorkflowConditionEnum.NET_AMOUNT ? (
                    <Col span={7}>
                        <FormItemCurrency
                            currency={triggerType.currency || CurrencyEnum.EUR}
                            label={t(`input:workflow.trigger.amount_greater`)}
                            name={["triggerTypes", index, "amount"]}
                            required
                            rules={rules.triggerType.amount}
                        >
                            <CurrencyInput className="react-currency-input w-200" isNegativeAllowed={false} value={0} min={0} currency={triggerType.currency || CurrencyEnum.EUR} />
                        </FormItemCurrency>
                    </Col>
                ) : (
                    <Col flex="auto">
                        {resourceType && (
                            <WorkflowTriggerObjectSelect
                                name={["triggerTypes", index, "referenceIds"]}
                                label={t(`input:workflow.trigger.${(triggerType.condition as any)?.toLowerCase()}`)}
                                required={true}
                                index={index}
                                isMultiple
                                resources={resources}
                                resourceType={resourceType}
                                selectedResourcesIds={triggerType.referenceIds || []}
                                anchor={getPopupAnchor()}
                                onSelect={handleTriggerTypeReferenceSelect}
                                rules={rules.triggerType.referenceIds}
                                includeAllOption
                            />
                        )}
                    </Col>
                )}

                {triggerType.condition === WorkflowConditionEnum.NET_AMOUNT && (
                    <Col flex="auto" sm="24" md="24">
                        <Form.Item name={["triggerTypes", index, "currency"]} label={t("input:request.currency")} key="currency" rules={rules.triggerType.currency}>
                            <Select showSearch getPopupContainer={(trigger) => trigger.parentElement} defaultValue={formValues.triggerTypes[index].currency || CurrencyEnum.EUR}>
                                {currencyEnumArray.map((currency) => (
                                    <Select.Option key={currency} value={currency} label={currency}>
                                        <div className="space-x-10">
                                            <Image className="align-middle" width={20} preview={false} src={`data:image/png;base64,${Currencies[currency].flag}`} />
                                            <span className="text-text">{currency}</span>
                                        </div>
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                )}

                {index !== 0 && (
                    <Col className="mt-30 w-50 flex justify-end">
                        <Button onClick={() => removeTriggerType(index)}>
                            <TrashIcon />
                        </Button>
                    </Col>
                )}
            </Row>
        )
    }

    return (
        <>
            {formValues.triggerTypes.map((condition: any, index: number) => renderTriggerTypeRow(condition, index))}
            <Row gutter={[16, 16]}>
                {formValues.triggerTypes.length < getConditionOptions(formValues.workflowType, formValues.requestTypes).length && (
                    <Button type={"default"} icon={<PlusIcon />} onClick={addTriggerType}>
                        {t("input:workflow.add_trigger_type")}
                    </Button>
                )}
            </Row>
        </>
    )
}
