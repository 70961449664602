import { Vendor } from "@finway-group/shared/lib/models"
import { Action } from "redux"

export enum VendorActionTypes {
    FETCH_ALL_VENDORS = "FETCH_ALL_VENDORS",
    FETCH_ONE_VENDOR = "FETCH_ONE_VENDOR",
    SYNC_VENDOR = "SYNC_VENDOR",
    NEW_VENDOR = "NEW_VENDOR",
    UPDATE_VENDOR = "UPDATE_VENDOR",
    DELETE_VENDOR = "DELETE_VENDOR",
}

export interface FetchAllVendorsAction extends Action<typeof VendorActionTypes.FETCH_ALL_VENDORS> {
    type: typeof VendorActionTypes.FETCH_ALL_VENDORS
    vendors: Array<Vendor>
}

export interface FetchOneVendorAction extends Action<typeof VendorActionTypes.FETCH_ONE_VENDOR> {
    type: typeof VendorActionTypes.FETCH_ONE_VENDOR
    vendor: Vendor
}

export interface SyncVendorAction extends Action<typeof VendorActionTypes.SYNC_VENDOR> {
    type: typeof VendorActionTypes.SYNC_VENDOR
    vendor: Vendor
}

export interface NewVendorAction extends Action<typeof VendorActionTypes.NEW_VENDOR> {
    type: typeof VendorActionTypes.NEW_VENDOR
    vendor: Vendor
}

export interface UpdateVendorAction extends Action<typeof VendorActionTypes.UPDATE_VENDOR> {
    type: typeof VendorActionTypes.UPDATE_VENDOR
    vendor: Vendor
}

export interface DeleteVendorAction extends Action<typeof VendorActionTypes.DELETE_VENDOR> {
    type: typeof VendorActionTypes.DELETE_VENDOR
    vendorId: string
}

export type VendorActions = FetchAllVendorsAction | FetchOneVendorAction | SyncVendorAction | NewVendorAction | UpdateVendorAction | DeleteVendorAction
