import { InboxInvoice } from "@finway-group/shared/lib/models"
import { Reducer } from "redux"

import {
    DeleteManyInboxInvoicesAction,
    DiscardInboxInvoiceAction,
    FetchAllInboxInvoicesAction,
    FetchInboxInvoicesCountAction,
    FetchOneInboxInvoiceAction,
    InboxInvoiceActionTypes,
    InboxInvoiceActions,
    MoveOneInboxInvoiceAction,
    NewInboxInvoiceAction,
    SetShouldRefetchInboxAction,
    SyncOneInboxInvoiceAction,
} from "Shared/store/actions/inboxInvoice/inboxInvoiceTypes"

export interface InboxInvoiceState {
    items: Array<InboxInvoice>
    item?: InboxInvoice
    count: number
    totalPages: number
    totalDocs: number
    shouldRefetch: boolean
}

const initialState = {
    items: [],
    item: undefined,
    count: 0,
    totalPages: 0,
    totalDocs: 0,
    shouldRefetch: false,
}

const setDashboardInboxInvoicesCount = (state: InboxInvoiceState, action: FetchInboxInvoicesCountAction): InboxInvoiceState => ({
    ...state,
    count: action.count,
})

const fetchAllInboxInvoices = (state: InboxInvoiceState, { inboxInvoices, totalPages, totalDocs, append }: FetchAllInboxInvoicesAction): InboxInvoiceState => ({
    ...state,
    items: !append ? inboxInvoices : [...state.items, ...inboxInvoices],
    totalPages,
    totalDocs,
})

const fetchOneInboxInvoice = (state: InboxInvoiceState, action: FetchOneInboxInvoiceAction): InboxInvoiceState => ({
    ...state,
    items: state.items.map((item) => (item.id === action.inboxInvoice.id ? action.inboxInvoice : item)),
})

const moveOneInboxInvoice = (state: InboxInvoiceState, action: MoveOneInboxInvoiceAction): InboxInvoiceState => ({
    ...state,
    items: state.items.filter((item) => item.id !== action.inboxInvoiceId),
})

const newInboxInvoice = (state: InboxInvoiceState, action: NewInboxInvoiceAction): InboxInvoiceState => ({
    ...state,
    items: [...state.items, action.inboxInvoice],
})

const discardInboxInvoice = (state: InboxInvoiceState, action: DiscardInboxInvoiceAction) => ({
    ...state,
    items: state.items.filter((item: InboxInvoice) => item != action.inboxInvoice),
})

const syncInboxInvoice = (state: InboxInvoiceState, { inboxInvoice }: SyncOneInboxInvoiceAction): InboxInvoiceState => {
    const isIncludedInStateItems = state.items.filter((invoice) => invoice.id === inboxInvoice.id).length > 0

    return {
        ...state,
        items: isIncludedInStateItems ? state.items.map((item) => (item.id === inboxInvoice.id ? inboxInvoice : item)) : state.items,
        item: state.item?.id === inboxInvoice.id ? inboxInvoice : state.item,
    }
}

const deleteManyInboxInvoices = (state: InboxInvoiceState, { inboxInvoiceIds }: DeleteManyInboxInvoicesAction): InboxInvoiceState => {
    const remainingInvoices = state.items.filter((inboxInvoice: InboxInvoice) => !inboxInvoiceIds.includes(inboxInvoice.id))

    return {
        ...state,
        items: remainingInvoices,
    }
}

const setShouldRefetch = (state: InboxInvoiceState, action: SetShouldRefetchInboxAction): InboxInvoiceState => ({
    ...state,
    shouldRefetch: action.shouldRefetch,
})

export const inboxInvoiceReducer: Reducer<InboxInvoiceState, InboxInvoiceActions> = (state = initialState, action) => {
    switch (action.type) {
        case InboxInvoiceActionTypes.FETCH_INBOX_INVOICES_COUNT:
            return setDashboardInboxInvoicesCount(state, action)
        case InboxInvoiceActionTypes.FETCH_ALL_INBOX_INVOICES:
            return fetchAllInboxInvoices(state, action)
        case InboxInvoiceActionTypes.FETCH_ONE_INBOX_INVOICE:
            return fetchOneInboxInvoice(state, action)
        case InboxInvoiceActionTypes.NEW_INBOX_INVOICE:
            return newInboxInvoice(state, action)
        case InboxInvoiceActionTypes.DISCARD_INBOX_INVOICE:
            return discardInboxInvoice(state, action)
        case InboxInvoiceActionTypes.SYNC_ONE_INBOX_INVOICE:
            return syncInboxInvoice(state, action)
        case InboxInvoiceActionTypes.SET_SHOULD_REFETCH_INBOX:
            return setShouldRefetch(state, action)
        case InboxInvoiceActionTypes.DELETE_MANY_INBOX_INVOICES:
            return deleteManyInboxInvoices(state, action)
        case InboxInvoiceActionTypes.MOVE_ONE_INBOX_INVOICE:
            return moveOneInboxInvoice(state, action)
        default:
            return state
    }
}
