import { Rule } from "antd/lib/form"
import i18n from "i18next"

const TransactionCategoryRules = (): { [key: string]: Array<Rule> } => ({
    name: [
        { required: true, message: i18n.t("validation:required") },
        { max: 128, message: i18n.t("validation:max.string", { max: 128 }) },
    ],
})

export default TransactionCategoryRules
