import { Expense, ExportFormatEnum } from "@finway-group/shared/lib/models"
import { isDiscountExpired } from "@finway-group/shared/lib/utils/expense.discount.utils"
import { Form, Input, Modal } from "antd"
import { Store } from "antd/lib/form/interface"
import React, { useEffect, useState } from "react"
import { Trans, useTranslation } from "react-i18next"

import WarningAlert from "Components/alert/warningAlert"
import SEPAExportConfirmationRules from "Components/forms/rules/sepaExportConfirmationRule"
import { useCompany } from "Shared/hooks/company.hooks"
import { buildSEPASubjectLine } from "Shared/utils/banking.utils"
import { isFolderExpense } from "Shared/utils/expense.utils"

import { SimpleExpenseList } from "./simpleExpenseList/simpleExpenseList"

interface SEPAExportConfirmationModalProps {
    expenses: Array<Expense>
    isShowing: boolean
    isNonSepaXml: boolean
    onCancel: () => void
    onSubmit: ({
        expensesToExport,
        exportFormat,
        subjectLines,
    }: {
        expensesToExport: Array<Expense>
        exportFormat: ExportFormatEnum.SEPA | ExportFormatEnum.NON_SEPA
        subjectLines: Record<string, string>
    }) => Promise<void>
}

const SEPAExportConfirmationModal = ({ expenses, isShowing, isNonSepaXml, onCancel, onSubmit }: SEPAExportConfirmationModalProps) => {
    const [form] = Form.useForm()
    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useState(false)
    const rules = SEPAExportConfirmationRules()
    const company = useCompany()

    const expensesToExport = expenses.filter((expense) => !isFolderExpense(expense))

    const expensesWithExpiredDiscount = expensesToExport.filter(isDiscountExpired)

    useEffect(() => {
        form.resetFields()
    }, [isShowing])

    const onFormSubmit = (values: Store) => {
        setIsLoading(true)
        const { subjects } = values // This contains an object that acts as a key-value pair: {expenseId: subjectLine}
        const exportFormat = isNonSepaXml ? ExportFormatEnum.NON_SEPA : ExportFormatEnum.SEPA
        onSubmit({ expensesToExport, subjectLines: subjects, exportFormat }).finally(handleHide)
    }

    const handleSubmit = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation()
        form.submit()
    }

    const handleHide = (e?: React.MouseEvent<HTMLElement>) => {
        e?.stopPropagation()
        setIsLoading(false)
        form.resetFields()
        onCancel()
    }

    return (
        <Modal
            title={!isNonSepaXml ? t("label:export_sepa") : t("label:export_non_sepa")}
            visible={isShowing}
            maskClosable={false}
            onOk={(e) => handleSubmit(e)}
            onCancel={(e) => handleHide(e)}
            okText={!isNonSepaXml ? t("action:download_sepa") : t("action:download_non_sepa")}
            keyboard
            destroyOnClose
            confirmLoading={isLoading}
        >
            <Form form={form} layout="vertical" onFinish={onFormSubmit}>
                {expensesToExport.map((expense, index) => {
                    const initialSubjectLine = expense.sepaXmlSubjectLine ?? buildSEPASubjectLine(company?.customBankSubjectParts, expense)
                    return (
                        <Form.Item
                            key={index}
                            label={`${t(`label:subject_line`)}${expense.expenseNumber} (${expense.getCreditor()?.name ?? ""})`}
                            name={["subjects", expense.id]}
                            initialValue={initialSubjectLine}
                            rules={rules.subjectLine}
                        >
                            <Input />
                        </Form.Item>
                    )
                })}
                {expensesWithExpiredDiscount.length > 0 && (
                    <WarningAlert
                        message={
                            <>
                                <div className="font-bold">{t("info:expense_discount.sepa_export.expired.title")}</div>
                                <Trans t={t} i18nKey="info:expense_discount.sepa_export.expired.message">
                                    <SimpleExpenseList expenses={expensesWithExpiredDiscount} />
                                </Trans>
                            </>
                        }
                    />
                )}
            </Form>
        </Modal>
    )
}

export default SEPAExportConfirmationModal
