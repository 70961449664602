import React from "react"

import Icon, { IconInterface } from "Components/icon"

const dashboardLiquiditySvg = (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.06779 15.7894L6.99929 13.1334L9.12689 16.0591C9.2532 16.2329 9.45437 16.3335 9.66596 16.3335C9.69981 16.3335 9.73366 16.3309 9.76817 16.3257C10.0149 16.2876 10.2193 16.1144 10.2981 15.8778L12.5509 9.11922L15.1236 12.7209C15.2499 12.8983 15.4537 13.0002 15.666 13.0002C15.7122 13.0002 15.7591 12.9953 15.8059 12.9852C16.0657 12.9295 16.2675 12.7248 16.3196 12.4644L18.1744 3.18982C18.4673 3.01522 18.666 2.69857 18.666 2.3335C18.666 1.78206 18.2174 1.3335 17.666 1.3335C17.1145 1.3335 16.666 1.78206 16.666 2.3335C16.666 2.55721 16.7424 2.76184 16.867 2.92859L15.3137 10.6938L13.3071 7.88477C13.3229 7.81441 13.3326 7.74186 13.3326 7.66683C13.3326 7.1154 12.8841 6.66683 12.3326 6.66683C11.7812 6.66683 11.3326 7.1154 11.3326 7.66683C11.3326 7.84591 11.3838 8.01176 11.4665 8.15731L9.4433 14.2271L7.89647 12.1001C7.96035 11.9685 7.99929 11.8227 7.99929 11.6668C7.99929 11.1154 7.55072 10.6668 6.99929 10.6668C6.44786 10.6668 5.99929 11.1154 5.99929 11.6668C5.99929 11.8227 6.03823 11.9685 6.10211 12.1001L3.98955 15.0051C3.8813 15.1539 3.91419 15.3624 4.06301 15.4706L4.60208 15.8629C4.75097 15.9712 4.95949 15.9383 5.06779 15.7894Z" />
        <path d="M19.6667 17.3333H2.66667V0.333333C2.66667 0.149089 2.51758 0 2.33333 0H1.66667C1.48242 0 1.33333 0.149089 1.33333 0.333333V17.3333H0.333333C0.149089 17.3333 0 17.4824 0 17.6667V18.3333C0 18.5176 0.149089 18.6667 0.333333 18.6667H1.33333V19.6667C1.33333 19.8509 1.48242 20 1.66667 20H2.33333C2.51758 20 2.66667 19.8509 2.66667 19.6667V18.6667H19.6667C19.8509 18.6667 20 18.5176 20 18.3333V17.6667C20 17.4824 19.8509 17.3333 19.6667 17.3333Z" />
    </svg>
)

const DashboardLiquidityIcon = (props: IconInterface) => <Icon component={dashboardLiquiditySvg} {...props} />

export default DashboardLiquidityIcon
