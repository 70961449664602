import { RightEnum, Role } from "@finway-group/shared/lib/models"
import { FormInstance } from "antd/lib/form"
import { FieldData } from "rc-field-form/es/interface"

import { RightFormElementsEnum, RoleConfigFormElementsEnum } from "./role.form.types"
import { StandardRightFormElementsEnum } from "./standardRight.form"

export const applyTemplateToFormModel = (role: Role, form: FormInstance) => {
    const rights = RIGHT_MAP_ENTRIES.map(([fieldName, mappedRights]) => {
        if (mappedRights.every((mappedRight) => role.rights.includes(mappedRight))) return { name: fieldName, value: true }
        return { name: fieldName, value: false }
    })

    rights.push({ name: RoleConfigFormElementsEnum.ALL_NO_EDIT, value: role.hasNoWriteRights })
    rights.push({ name: RoleConfigFormElementsEnum.ALL_VIEW, value: role.hasAllReadRights })

    form.setFields(rights)
}

export const mapRoleModelToFormModel = (role: Role, form: FormInstance) => {
    const rights = RIGHT_MAP_ENTRIES.reduce((result, [fieldName, mappedRights]) => {
        if (mappedRights.every((mappedRight) => role.rights.includes(mappedRight))) result.push({ name: fieldName, value: true })
        return result
    }, [] as Array<FieldData>)

    form.setFields([
        { name: "name", value: role.name },
        { name: "template", value: role.template },
        { name: RoleConfigFormElementsEnum.ALL_NO_EDIT, value: role.hasNoWriteRights },
        { name: RoleConfigFormElementsEnum.ALL_VIEW, value: role.hasAllReadRights },
        ...rights,
    ])
}

export const enableStandardRights = (form: FormInstance) => {
    form.setFields(Object.values(StandardRightFormElementsEnum).map((value) => ({ name: value, value: true })))
}

export const mapFormModelToRoleModel = (form: FormInstance) => {
    const rights = RIGHT_MAP_ENTRIES.reduce((result, [fieldName, mappedRights]) => {
        // if field is set to true (aka enabled)
        if (form.getFieldsValue([fieldName])[fieldName])
            // add rights
            result.push(...mappedRights)
        return result
    }, [] as Array<RightEnum>)
    return {
        name: form.getFieldValue("name"),
        template: form.getFieldValue("template"),
        rights,
        hasNoWriteRights: form.getFieldValue(RoleConfigFormElementsEnum.ALL_NO_EDIT) ?? false,
        hasAllReadRights: form.getFieldValue(RoleConfigFormElementsEnum.ALL_VIEW) ?? false,
    } as Role
}

const RIGHT_MAP = {
    [RoleConfigFormElementsEnum.INBOX_INVOICE_MANAGE_ALL]: [RightEnum.INBOX_INVOICE__ALL__READ, RightEnum.INBOX_INVOICE__ALL__ASSIGN],
    [RoleConfigFormElementsEnum.REQUEST_CREATE_ON_BEHALF]: [RightEnum.EXPENSE__ALL__CREATE_ON_BEHALF],
    [RoleConfigFormElementsEnum.REQUEST_VIEW_ALL]: [RightEnum.EXPENSE__ALL__READ],
    [RoleConfigFormElementsEnum.REQUEST_VIEW_ALL_TEAM]: [RightEnum.EXPENSE__TEAM__READ],
    [RoleConfigFormElementsEnum.SELECTABLE_AS_APPROVER]: [RightEnum.EXPENSE__BASIC__APPROVE],

    [StandardRightFormElementsEnum.CREATE_REQUESTS]: [RightEnum.EXPENSE__ALL__CREATE],
    [StandardRightFormElementsEnum.UPLOAD_INVOICES]: [RightEnum.INBOX_INVOICE__ALL__WRITE],
    [StandardRightFormElementsEnum.VENDOR_SUGGESTION]: [RightEnum.VENDOR__ALL__CREATE_UNAPPROVED],

    [RightFormElementsEnum.BANK_ACCOUNT_ABILITY_WRITE]: [RightEnum.BANK_ACCOUNT__ALL__CREATE],
    [RightFormElementsEnum.BANK_ACCOUNT_ACCESS]: [RightEnum.BANK_ACCOUNT__ALL__READ],

    [RightFormElementsEnum.CARD_ABILITY_MANAGE]: [RightEnum.CARD__ALL__WRITE],
    [RightFormElementsEnum.CARD_ABILITY_MANAGE_TEAM]: [RightEnum.CARD__TEAM__WRITE],
    [RightFormElementsEnum.CARD_ACCESS]: [RightEnum.CARD__ALL__READ],
    [RightFormElementsEnum.CARD_ACCESS_TEAM]: [RightEnum.CARD__TEAM__READ],
    [RightFormElementsEnum.CARD_WALLET_ACCESS]: [RightEnum.CARD_WALLET__ALL__READ],
    [RightFormElementsEnum.CARD_WALLET_ABILITY_MANAGE]: [RightEnum.CARD_WALLET__ALL__MANAGE],

    [RightFormElementsEnum.COMPANY_FEATURE_SETTINGS_ACCESS]: [RightEnum.COMPANY_FEATURE_SETTINGS__ALL__READ],
    [RightFormElementsEnum.COMPANY_FEATURE_SETTINGS_ABILITY_MANAGE]: [RightEnum.COMPANY_FEATURE_SETTINGS__ALL__WRITE],
    [RightFormElementsEnum.COMPANY_ACCOUNTING_SETTINGS_ACCESS]: [RightEnum.COMPANY_ACCOUNTING_SETTINGS__ALL__READ],
    [RightFormElementsEnum.COMPANY_ACCOUNTING_SETTINGS_ABILITY_MANAGE]: [RightEnum.COMPANY_ACCOUNTING_SETTINGS__ALL__WRITE],

    [RightFormElementsEnum.COST_CENTER_1_ABILITY_MANAGE]: [RightEnum.CC1__ALL__MANAGE],
    [RightFormElementsEnum.COST_CENTER_1_ABILITY_OWNER]: [RightEnum.CC1__ALL__OWNER],
    [RightFormElementsEnum.COST_CENTER_1_ACCESS]: [RightEnum.CC1__ALL__READ],

    [RightFormElementsEnum.EXPORT_ABILITY_EXPORT_ALL_FORMATS]: [RightEnum.EXPENSE__ALL__EXPORT],
    [RightFormElementsEnum.EXPORT_ACCESS]: [RightEnum.EXPENSE__ALL__ACCESS_TABLE_EXPORT_READ],

    [RightFormElementsEnum.LIQUIDITY_ABILITY_MANAGE]: [RightEnum.LIQUIDITY__ALL__UPDATE],
    [RightFormElementsEnum.LIQUIDITY_ACCESS]: [RightEnum.LIQUIDITY__ALL__READ],

    [RightFormElementsEnum.PAY_ABILITY_PAY_REQUESTS]: [RightEnum.EXPENSE__ALL__PAY_BY_BANK],
    [RightFormElementsEnum.PAY_ACCESS]: [RightEnum.EXPENSE__ALL__ACCESS_TABLE_PAY_READ],

    [RightFormElementsEnum.REPORTING_ACCESS]: [RightEnum.REPORTING__ALL__READ],

    [RightFormElementsEnum.REQUEST_ABILITY_APPROVE]: [RightEnum.EXPENSE__ALL__APPROVE],
    [RightFormElementsEnum.REQUEST_ABILITY_APPROVE_DASHBOARD]: [RightEnum.EXPENSE__ALL__APPROVE_REJECT_DASHBOARD],
    [RightFormElementsEnum.REQUEST_ABILITY_APPROVE_TEAM]: [RightEnum.EXPENSE__TEAM__APPROVE],
    [RightFormElementsEnum.REQUEST_ABILITY_FORMAL_REVIEW]: [RightEnum.EXPENSE__ALL__REVIEW],
    [RightFormElementsEnum.REQUEST_ABILITY_MANAGE]: [RightEnum.EXPENSE__ALL__DELETE, RightEnum.EXPENSE__ALL__ARCHIVE, RightEnum.EXPENSE__ALL__RESET_STATUS],
    [RightFormElementsEnum.REQUEST_ABILITY_MANAGE_TEAM]: [RightEnum.EXPENSE__TEAM__DELETE, RightEnum.EXPENSE__TEAM__ARCHIVE, RightEnum.EXPENSE__TEAM__RESET_STATUS],
    [RightFormElementsEnum.REQUEST_SUBSCRIPTION_ABILITY_MANAGE]: [RightEnum.SUBSCRIPTION__ALL__WRITE],
    [RightFormElementsEnum.REQUEST_SUBSCRIPTION_ACCESS]: [RightEnum.SUBSCRIPTION__ALL__READ],
    [RightFormElementsEnum.REQUEST_SUBSCRIPTION_TEAM_ABILITY_MANAGE]: [RightEnum.SUBSCRIPTION__TEAM__WRITE],
    [RightFormElementsEnum.REQUEST_SUBSCRIPTION_TEAM_ACCESS]: [RightEnum.SUBSCRIPTION__TEAM__READ],
    [RightFormElementsEnum.REQUEST_ACCESS_FORMAL_REVIEW]: [RightEnum.EXPENSE__ALL__ACCESS_TABLE_IN_REVIEW_READ],

    [RightFormElementsEnum.TRANSACTION_ABILITY_MANAGE]: [RightEnum.TRANSACTION__ALL__UPDATE],
    [RightFormElementsEnum.TRANSACTION_ACCESS]: [RightEnum.TRANSACTION__ALL__READ],

    [RightFormElementsEnum.USER_ABILITY_APPROVAL_DATA_EDIT_ALL]: [RightEnum.EMPLOYEE_APPROVAL_DATA__ALL__UPDATE],
    [RightFormElementsEnum.USER_ABILITY_APPROVAL_DATA_EDIT_TEAM]: [RightEnum.EMPLOYEE_APPROVAL_DATA__TEAM__UPDATE],
    [RightFormElementsEnum.USER_ABILITY_GLOBAL_DATA_EDIT_ALL]: [RightEnum.EMPLOYEE__ALL__UPDATE],
    [RightFormElementsEnum.USER_ABILITY_GLOBAL_DATA_EDIT_TEAM]: [RightEnum.EMPLOYEE__TEAM__UPDATE],
    [RightFormElementsEnum.USER_ACCESS]: [RightEnum.EMPLOYEE__ALL__READ],
    [RightFormElementsEnum.USER_ACCESS_TEAM]: [RightEnum.EMPLOYEE__TEAM__READ],

    [RightFormElementsEnum.VENDOR_ABILITY_MANAGE]: [RightEnum.VENDOR__ALL__CREATE, RightEnum.VENDOR__ALL__UPDATE],
    [RightFormElementsEnum.VENDOR_ACCESS]: [RightEnum.VENDOR__ALL__READ],
    [RightFormElementsEnum.VENDOR_CONTRACT_ABILITY_EDIT]: [RightEnum.VENDOR_CONTRACT__ALL__UPDATE],
    [RightFormElementsEnum.VENDOR_CONTRACT_ACCESS]: [RightEnum.VENDOR_CONTRACT__ALL__READ],
    [RightFormElementsEnum.VENDOR_PAYMENT_DATA_ABILITY_EDIT]: [RightEnum.VENDOR_PAYMENT_DATA__ALL__CREATE],
    [RightFormElementsEnum.VENDOR_PAYMENT_DATA_ACCESS]: [RightEnum.VENDOR_PAYMENT_DATA__ALL__READ],

    [RightFormElementsEnum.WORKFLOW_ABILITY_ADJUST]: [RightEnum.EXPENSE__ALL__ADJUST_WORKFLOW],
    [RightFormElementsEnum.WORKFLOW_ABILITY_MANAGE]: [RightEnum.WORKFLOW__ALL__CREATE, RightEnum.WORKFLOW__ALL__UPDATE, RightEnum.WORKFLOW__ALL__DELETE],
    [RightFormElementsEnum.WORKFLOW_ACCESS]: [RightEnum.WORKFLOW__ALL__READ],
}

const RIGHT_MAP_ENTRIES = Object.entries(RIGHT_MAP)
