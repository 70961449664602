import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons"
import { Expense, InboxInvoice, PriceIntervalEnum, RightEnum } from "@finway-group/shared/lib/models"
import { roundNumberTo2Decimals } from "@finway-group/shared/lib/utils"
import { Alert, Button, Col, Radio, Row, Table } from "antd"
import { ColumnsType } from "antd/lib/table"
import moment from "moment"
import React, { Suspense, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import EmptyTableData from "Components/emptyTableData"
import FileViewer from "Components/fileViewer/fileViewer"
import { TrashIcon } from "Components/icons"
import { ErrorTable } from "Components/layout/errorTable"
import OcrStatusBadge from "Components/ocrStatusBadge"
import PriceLabel from "Components/priceLabel"
import TableButtons from "Components/tableButtons"
import { useSelectedInboxInvoices } from "Shared/context/selectedExpenses.context"
import { useFetchTable, useTable } from "Shared/hooks/table.hooks"
import { useLoggedInEmployeeProfile } from "Shared/hooks/user.hooks"
import { useCreditors } from "Shared/hooks/vendor.hooks"
import { AuthzService, NotificationService } from "Shared/services"
import InboxInvoiceService from "Shared/services/inboxInvoice.service"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { deleteManyInboxInvoices } from "Shared/store/actions/inboxInvoice/inboxInvoiceActions"
import { refetchTable, updateTableFilter, updateTableOnChange } from "Shared/store/actions/tables/tableActions"
import { TablesEnum } from "Shared/store/reducers/tableConfigReducer"
import { insertIf, isNotSet } from "Shared/utils/helper.utils"
import { getSortOrderForColumn } from "Shared/utils/table.utils"

import FilterModal from "../filter.modal"

interface InboxInvoiceModalInvoiceSectionInterface {
    isShowing: boolean
    selectedExpense: any
    selectedInvoice: any
    setSelectedInvoice: (invoice: any) => void
    setSelectedExpense: (expense: any) => void
    selectedOcrSource: InboxInvoice | undefined
    setSelectedOcrSource: (id: string | undefined) => void
}

const InboxInvoiceModalInvoiceSection: React.FC<InboxInvoiceModalInvoiceSectionInterface> = ({
    isShowing,
    selectedExpense: currentExpense,
    selectedInvoice,
    setSelectedInvoice,
    setSelectedExpense,
    selectedOcrSource,
    setSelectedOcrSource,
}) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const loggedInProfile = useLoggedInEmployeeProfile()
    const invoicesTable = useTable<InboxInvoice>(TablesEnum.INBOX_INVOICE_MODAL_INVOICES)
    const [selectedInboxInvoices, setSelectedInboxInvoices] = useSelectedInboxInvoices()
    const creditors = useCreditors(AuthzService.isRightGrantedForLoggedInUser(RightEnum.EMPLOYEE__ALL__READ), true, true)
    const canWriteInboxInvoice = AuthzService.isRightGrantedForLoggedInUser(RightEnum.INBOX_INVOICE__ALL__WRITE)

    useFetchTable(TablesEnum.INBOX_INVOICE_MODAL_INVOICES, currentExpense ? { expenseId: currentExpense._id } : {})

    useEffect(() => {
        if (isShowing) {
            dispatch(refetchTable(TablesEnum.INBOX_INVOICE_MODAL_INVOICES))
        }
    }, [isShowing, selectedInvoice?._id, currentExpense?._id])

    const inboxColumns: ColumnsType<InboxInvoice> = [
        {
            title: t("info:inbox_invoice.file_name"),
            key: "fileName",
            align: "left",
            className: "pl-10",
            ellipsis: true,
            sorter: true,
            sortOrder: getSortOrderForColumn(invoicesTable, "fileName"),
            render: ({ fileName }: InboxInvoice) => <div>{fileName}</div>,
        },
        {
            title: t("info:inbox_invoice.upload_date"),
            key: "uploadDate",
            align: "left",
            width: 140,
            className: "pr-10",
            sorter: true,
            sortOrder: getSortOrderForColumn(invoicesTable, "uploadDate"),
            render: ({ uploadDate }: InboxInvoice) => <div>{moment(uploadDate).format("ll")}</div>,
        },
        {
            title: t("info:inbox_invoice.vendor"),
            key: "ocrResults.vendor.name",
            width: 190,
            align: "left",
            sorter: true,
            sortOrder: getSortOrderForColumn(invoicesTable, "ocrResults.vendor.name"),
            render: ({ ocrResults }: InboxInvoice) => <div>{ocrResults?.vendor?.name || "n/a"} </div>,
        },
        {
            title: t("info:inbox_invoice.amount"),
            key: "ocrResults.totalGrossPrice",
            width: 120,
            align: "center",
            sorter: true,
            sortOrder: getSortOrderForColumn(invoicesTable, "ocrResults.totalGrossPrice"),
            render: ({ ocrResults }: InboxInvoice) =>
                ocrResults?.totalGrossPrice && ocrResults?.currency ? (
                    <PriceLabel value={ocrResults.totalGrossPrice} currency={ocrResults.currency} interval={PriceIntervalEnum.ONE_TIME} />
                ) : (
                    <div>{"n/a"} </div>
                ),
        },
        ...insertIf(!isNotSet(currentExpense), {
            key: "probability",
            title: t("label:probability_short"),
            ellipsis: true,
            align: "right",
            width: 80,
            sorter: true,
            sortOrder: getSortOrderForColumn(invoicesTable, "probability"),
            render: (inboxInvoice: any) => <span>{roundNumberTo2Decimals(inboxInvoice?.probability ?? 0)}%</span>,
        }),
    ]

    if (selectedInboxInvoices?.length > 1 && loggedInProfile?.settings?.enableOcr) {
        inboxColumns.push({
            title: t("info:inbox_invoice.ocr"),
            key: "ocrSource",
            width: 60,
            align: "center",
            render: (value) =>
                selectedInboxInvoices.find(({ id }) => id === value.id) && (
                    <Radio checked={selectedOcrSource?.id === value.id} onClick={(e) => e.stopPropagation()} onChange={() => setSelectedOcrSource(value)} />
                ),
        })
    }

    const rowSelection = {
        selectedRowKeys: selectedInboxInvoices.map((record: InboxInvoice) => record.id),
        onChange: (_selectedRowKeys: any, selectedRows: Array<InboxInvoice>) => setSelectedInboxInvoices(selectedRows),
        onSelect: (record: InboxInvoice, _selected: any, _selectedRow: any) => setSelectedInboxInvoices([...selectedInboxInvoices, record]),
    }

    const signInvoiceUrl = async (id: string) => {
        try {
            const invoiceDocument = await InboxInvoiceService.fetchOneInboxInvoice(id)
            setSelectedInvoice(invoiceDocument)
        } catch (err) {
            NotificationService.send(NotificationTypeEnum.ERROR, t("error:error"), t("error:invoice.fetch_failed.message"))
        }
    }

    const moveToPreviousInvoice = async () => {
        const currentIndex = invoicesTable.data.docs.findIndex((currInvoice) => currInvoice.id === selectedInvoice.id)
        const newIndex = currentIndex > 0 ? currentIndex - 1 : invoicesTable.data.docs.length - 1
        const { id } = invoicesTable.data.docs[newIndex]
        await signInvoiceUrl(id)
    }

    const moveToNextInvoice = async () => {
        const currentIndex = invoicesTable.data.docs.findIndex((currInvoice) => currInvoice.id === selectedInvoice.id)
        const newIndex = currentIndex < invoicesTable.data.docs.length - 1 ? currentIndex + 1 : 0
        const { id } = invoicesTable.data.docs[newIndex]
        await signInvoiceUrl(id)
    }

    const onDeleteSelectedInvoices = async () => {
        try {
            const invoiceIds = selectedInboxInvoices.map((invoice) => invoice.id)
            await deleteManyInboxInvoices(invoiceIds)(dispatch)
            setSelectedInboxInvoices([])
            NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:invoice_inbox.deleted.title"), t("notification:invoice_inbox.deleted.message"))
        } catch (err) {
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:error"))
        }
    }

    return (
        <Col span={24} lg={12} className="px-5">
            {selectedInvoice ? (
                <>
                    <Row className="ml-20 mb-20 mt-2" justify="space-between">
                        <Col span={18}>
                            <ArrowLeftOutlined onClick={() => setSelectedInvoice(undefined)} />
                            <span className="relative text-base font-bold text-text-dark ml-10 truncate" style={{ top: "3px" }}>
                                {t("info:inbox_invoice.invoice_details")}
                            </span>
                            <span className="ml-10" style={{ top: "2px" }}>
                                <OcrStatusBadge ocrStatus={selectedInvoice.ocrStatus} />{" "}
                            </span>
                        </Col>
                        <Col span={6} lg={4}>
                            <ArrowLeftOutlined className="ml-14" onClick={moveToPreviousInvoice} />
                            <ArrowRightOutlined className="ml-14" onClick={moveToNextInvoice} />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Suspense fallback={"..."}>
                                <FileViewer filePath={selectedInvoice?.url ?? ""} />
                            </Suspense>
                        </Col>
                    </Row>
                </>
            ) : (
                <>
                    <Row className="mb-10">
                        <span className="text-base font-bold text-text-dark">{t("info:inbox_invoice.invoices_from_inbox")}</span>
                    </Row>
                    {selectedInboxInvoices.length > 1 ? (
                        <Alert
                            type="warning"
                            showIcon
                            className="mb-20"
                            message={
                                <div>
                                    <span>{t("info:ocr.make_sure_ocr_source_is_selected")}</span>
                                </div>
                            }
                        />
                    ) : null}
                    <TableButtons
                        table={TablesEnum.INBOX_INVOICE_MODAL_INVOICES}
                        additionalButtons={
                            canWriteInboxInvoice && (
                                <Button onClick={onDeleteSelectedInvoices} disabled={!selectedInboxInvoices.length}>
                                    <TrashIcon />
                                </Button>
                            )
                        }
                        injectFilterModal={(isVisible: boolean, onCancel: any) => (
                            <FilterModal
                                table={TablesEnum.INBOX_INVOICE}
                                options={{ creditors }}
                                isVisible={isVisible}
                                onCancel={onCancel}
                                onSubmit={async (filterFormData: any) => {
                                    dispatch(updateTableFilter(TablesEnum.INBOX_INVOICE_MODAL_INVOICES, filterFormData))
                                }}
                            />
                        )}
                    />

                    {
                        <div>
                            {invoicesTable.error ? (
                                <ErrorTable onTableReload={() => dispatch(refetchTable(TablesEnum.INBOX_INVOICE_MODAL_INVOICES))} isLoading={invoicesTable.isFetching} />
                            ) : invoicesTable.data.totalDocs === 0 && !invoicesTable.isFetching ? (
                                <EmptyTableData searchString={invoicesTable.data.search} title={t("info:empty_state.inbox.title")} message={t("info:empty_state.inbox.message")} />
                            ) : (
                                <div className="overflow-auto mb-24 mt-20 p-2">
                                    <Table
                                        rowKey={(record) => record.id}
                                        dataSource={invoicesTable.data.docs}
                                        columns={inboxColumns}
                                        tableLayout={"auto"}
                                        pagination={{
                                            position: ["bottomRight"],
                                            showSizeChanger: false,
                                            current: invoicesTable.data.page,
                                            pageSize: invoicesTable.data.limit,
                                            hideOnSinglePage: false,
                                            total: invoicesTable.data.totalDocs,
                                            pageSizeOptions: ["5", "10", "20", "50", "100"],
                                        }}
                                        onChange={updateTableOnChange(TablesEnum.INBOX_INVOICE_MODAL_INVOICES, invoicesTable.data)}
                                        onRow={(invoice) => ({
                                            onClick: async (_event) => {
                                                await signInvoiceUrl(invoice.id)

                                                if (currentExpense) {
                                                    setSelectedExpense(new Expense({ ...currentExpense, probability: (invoice as any).probability }))
                                                }
                                            },
                                        })}
                                        rowSelection={{
                                            type: "checkbox",
                                            ...rowSelection,
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                    }
                </>
            )}
        </Col>
    )
}

export default InboxInvoiceModalInvoiceSection
