import { PriceIntervalEnum } from "@finway-group/shared/lib/models"
import { MAX_INPUT_LENGTH } from "@finway-group/shared/lib/utils/validators"
import { Col, Form, Input, Row } from "antd"
import { FormInstance } from "antd/lib/form"
import { Store } from "antd/lib/form/interface"
import React from "react"
import { useTranslation } from "react-i18next"

import CurrencyInput from "Components/currencyInput"
import FormItemCurrency from "Components/form/formItemCurrency"
import PriceLabel from "Components/priceLabel"
import { getCardLimitWithoutBuffer } from "Shared/utils/creditCard.utils"

import { cardLimitRules } from "./rules/cardLimit.rules"

interface CardLimitFormInterface {
    formInstance: FormInstance
    onSubmit: (values: Store) => Promise<void>
    card: any
}

const CardLimitForm: React.FC<CardLimitFormInterface> = ({ formInstance, onSubmit, card }) => {
    const { t } = useTranslation()
    const rules = cardLimitRules()

    return (
        <div>
            <Form id="cardLimitForm" form={formInstance} layout="vertical" onFinish={onSubmit}>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <FormItemCurrency
                            currency={card.currency}
                            label={t("input:card.limit_change_amount")}
                            extra={t("info:changing_the_limit_of_the_card_will_top_it_up")}
                            name="limit"
                            rules={rules.cardLimit}
                        >
                            <CurrencyInput currency={card.currency} value={getCardLimitWithoutBuffer(card)} className="w-full h-100 text-center text-3xl border-2" />
                        </FormItemCurrency>
                        <p className="text-black text-opacity-50 w-full text-center transform -translate-y-80">
                            {`${t(`label:cards.current_card_limit`)}: `}
                            <PriceLabel value={getCardLimitWithoutBuffer(card)} currency={card.currency} interval={PriceIntervalEnum.ONE_TIME} />
                        </p>
                    </Col>

                    <Col span={24}>
                        <Form.Item label={t("input:card.limit_change_reason")} name="reason" rules={rules.reason}>
                            <Input maxLength={MAX_INPUT_LENGTH} type="text" required />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default CardLimitForm
