import { PlusCircleOutlined } from "@ant-design/icons"
import { COLOR } from "@finway-group/shared/lib/consts"
import { ExpenseKindEnum, ExpenseRequestTypeEnum, RightEnum, SystemRightEnum } from "@finway-group/shared/lib/models"
import { Button, Divider, Dropdown, Menu } from "antd"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import NewCostCenter1Icon from "Components/icons/newCostCenter1"
import NewEmployeeIcon from "Components/icons/newEmployee"
import NewInvoiceIcon from "Components/icons/newInvoice"
import NewPurchaseIcon from "Components/icons/newPurchase"
import NewReimbursementIcon from "Components/icons/newReimbursement"
import NewTripFolderIcon from "Components/icons/newTripFolder"
import NewVendorIcon from "Components/icons/newVendor"
import ExpenseCreateFormModal from "Components/modals/expenseCreateForm.modal"
import UserModal from "Components/modals/user.modal"
import VendorFormModal from "Components/modals/vendor.modal"
import CostCenterFormModal from "Features/pages/costCenters/costCenterForm/CostCenterFormModal"
import { useCompany } from "Shared/hooks/company.hooks"
import { useRequestActionDisabled } from "Shared/hooks/expense.hooks"
import { useIsTravelEnabled } from "Shared/hooks/featureFlags.hooks"
import { RoutePathEnum } from "Shared/router/RoutePath.enum"
import { AuthzService, VendorService } from "Shared/services"
import { getPopupAnchor } from "Shared/utils/popup.utils"

import { MenuItemInterface, NavbarMenuItemEnum } from "./types"

const ActionMenuSidebar = () => {
    const { t } = useTranslation()

    const [isExpenseModalShowing, setIsExpenseModalShowing] = useState(false)
    const [isVendorModalShowing, setIsVendorModalShowing] = useState<boolean>(false)
    const [isCostCenterModalShowing, setIsCostCenterModalShowing] = useState<boolean>(false)
    const [isUserModalShowing, setIsUserModalShowing] = useState<boolean>(false)

    const [requestType, setRequestType] = useState<ExpenseRequestTypeEnum>(ExpenseRequestTypeEnum.PURCHASE)
    const [isReimbursement, setIsReimbursement] = useState<boolean>(false)

    const { isVendorCreationRestricted } = useCompany()

    const requestButtonsDisabled = useRequestActionDisabled()

    const isTravelEnabled = useIsTravelEnabled()
    const isAllowedToCreateExpenses = AuthzService.isLoggedInUserAllowedToCreateExpenses()

    const expenseMenuItems: Array<MenuItemInterface> = requestButtonsDisabled
        ? []
        : [
              {
                  link: "purchase_request",
                  label: t("label:purchase"),
                  canView: isAllowedToCreateExpenses,
                  icon: <NewPurchaseIcon />,
              },
              {
                  link: "invoice_request",
                  label: t("label:invoice"),
                  canView: isAllowedToCreateExpenses,
                  icon: <NewInvoiceIcon />,
              },
              {
                  link: "reimbursement_request",
                  label: t("label:reimbursement"),
                  canView: isAllowedToCreateExpenses,
                  icon: <NewReimbursementIcon />,
              },
              {
                  link: "trip_folder_request",
                  label: t("label:trip_folder_request"),
                  canView: isAllowedToCreateExpenses && isTravelEnabled,
                  icon: <NewTripFolderIcon />,
              },
          ]

    const resourcesMenuItems: Array<MenuItemInterface> = [
        {
            link: "employee",
            label: t("label:employee"),
            canView: AuthzService.isSystemRightGrantedForLoggedInUser(SystemRightEnum.SYS__USER__ALL__CREATE),
            icon: <NewEmployeeIcon />,
        },
        {
            link: "costCenter",
            label: t("label:cost_center"),
            canView: AuthzService.isRightGrantedForLoggedInUser(RightEnum.CC1__ALL__MANAGE),
            icon: <NewCostCenter1Icon />,
        },
        {
            link: "vendor",
            label: t("label:vendor"),
            canView: VendorService.shouldShowVendorButton(isVendorCreationRestricted),
            icon: <NewVendorIcon />,
        },
    ]

    const filteredExpenseMenuItems = expenseMenuItems.filter((menuItem) => menuItem.canView)
    const filteredResourcesMenuItems = resourcesMenuItems.filter((menuItem) => menuItem.canView)
    const menuItemCount = filteredExpenseMenuItems.length + filteredResourcesMenuItems.length
    if (menuItemCount === 0) return null

    const handleCreateMenuCommand = (command: string): void => {
        switch (command) {
            case NavbarMenuItemEnum.PURCHASED_REQUEST:
                setRequestType(ExpenseRequestTypeEnum.PURCHASE)
                setIsExpenseModalShowing(true)
                break
            case NavbarMenuItemEnum.INVOICE_REQUEST:
                setRequestType(ExpenseRequestTypeEnum.INVOICE)
                setIsExpenseModalShowing(true)
                break
            case NavbarMenuItemEnum.REIMBURSEMENT_REQUEST:
                setRequestType(ExpenseRequestTypeEnum.REIMBURSEMENT)
                setIsExpenseModalShowing(true)
                setIsReimbursement(true)
                break
            case NavbarMenuItemEnum.TRIP_FOLDER_REQUEST:
                setRequestType(ExpenseRequestTypeEnum.TRIP_FOLDER)
                setIsExpenseModalShowing(true)
                break
            case NavbarMenuItemEnum.EMPLOYEE:
                setIsUserModalShowing(true)
                break
            case NavbarMenuItemEnum.COST_CENTER:
                setIsCostCenterModalShowing(true)
                break
            case NavbarMenuItemEnum.VENDOR:
                setIsVendorModalShowing(true)
                break
            default:
                break
        }
    }

    const renderMenuItems = (menuItems: Array<MenuItemInterface>) =>
        menuItems.map(({ link, label, icon }) => (
            <Menu.Item mode="inline" icon={icon} key={link} className="flex items-center my-5 font-medium text-base" onClick={() => handleCreateMenuCommand(link)}>
                {label}
            </Menu.Item>
        ))

    return (
        <>
            <ExpenseCreateFormModal
                kind={requestType === ExpenseRequestTypeEnum.TRIP_FOLDER ? ExpenseKindEnum.TRIP_FOLDER : ExpenseKindEnum.ONE_TIME_EXPENSE}
                isShowing={isExpenseModalShowing}
                isNew={true}
                type={requestType}
                onCancel={() => setIsExpenseModalShowing(false)}
                isReimbursement={isReimbursement}
            />
            <CostCenterFormModal isNew={true} isShowing={isCostCenterModalShowing} onHide={() => setIsCostCenterModalShowing(false)} />
            <VendorFormModal isNew={true} isShowing={isVendorModalShowing} onCancel={setIsVendorModalShowing} />
            <UserModal
                isNew={true}
                isShowing={isUserModalShowing}
                onCancel={() => {
                    setIsUserModalShowing(false)
                }}
            />

            <Dropdown
                placement="bottomLeft"
                className="flex items-center"
                trigger={["click"]}
                align={{ offset: [180, -36] }}
                overlay={
                    <Menu className="z-20 w-220 py-5 mr-8">
                        {renderMenuItems(filteredExpenseMenuItems)}
                        {filteredResourcesMenuItems.length > 0 && filteredExpenseMenuItems.length > 0 && <Divider className="my-10" />}
                        {renderMenuItems(filteredResourcesMenuItems)}
                    </Menu>
                }
                getPopupContainer={getPopupAnchor()}
            >
                <Button data-testid="sideBarNewButton" className={`my-8 h-36 w-full`} style={{ background: COLOR.finway.green, color: COLOR.white }}>
                    <PlusCircleOutlined />
                    {t("action:create")}
                </Button>
            </Dropdown>
        </>
    )
}

export default ActionMenuSidebar
