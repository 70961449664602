import { Button, Modal } from "antd"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSessionStorage } from "usehooks-ts"

import SessionStorageService, { SESSION_INVALID_KEY } from "Shared/services/sessionstorage.service"

export const SessionConflictModal = () => {
    const { t } = useTranslation()

    const [isOpen, setIsOpen] = useState(false)
    const [sessionInvalid] = useSessionStorage(SESSION_INVALID_KEY, false)

    useEffect(() => {
        if (!sessionInvalid) return
        setIsOpen(true)
    }, [sessionInvalid])

    const closeModal = () => {
        SessionStorageService.clearSessionInvalid()
        setIsOpen(false)
    }

    return (
        <Modal
            className="pop-confirm"
            title={<span className="w-full text-center text-lg font-semibold text-primary">{t("confirm:session_conflict.title")}</span>}
            centered
            visible={isOpen}
            onCancel={closeModal}
            closable={false}
            destroyOnClose
            width={450}
            footer={[
                <Button htmlType="submit" size="large" className="w-full rounded-lg font-medium bg-login-button" type="primary" onClick={closeModal}>
                    {t("confirm:session_conflict.action")}
                </Button>,
            ]}
        >
            <span>{t("confirm:session_conflict.message")}</span>
        </Modal>
    )
}
