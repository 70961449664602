import { CheckCircleOutlined } from "@ant-design/icons"
import { Employee } from "@finway-group/shared/lib/models"
import { Tag } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"
import { formatPhoneNumberIntl } from "react-phone-number-input"

interface PhoneNumberInterface {
    employee: Employee
    showVerificationModal: (state: boolean) => void
}
const PhoneNumber: React.FC<PhoneNumberInterface> = ({ employee, showVerificationModal }) => {
    const { t } = useTranslation()

    return (
        <div className="flex flex-row">
            {!employee.settings.security?.workPhoneVerified ? (
                <>
                    {formatPhoneNumberIntl(employee.workPhone)}
                    <span onClick={() => showVerificationModal(true)}>
                        <Tag className="ml-5 cursor-pointer" color="error">
                            {t("action:verify")}
                        </Tag>
                    </span>
                </>
            ) : (
                <>
                    <Tag className="mr-5 flex items-center" icon={<CheckCircleOutlined />} color="success">
                        {t("label:phone_verification:verified")}
                    </Tag>
                    {formatPhoneNumberIntl(employee.workPhone)}
                </>
            )}
        </div>
    )
}

export default PhoneNumber
