import { Company } from "@finway-group/shared/lib/models"
import { Col, Row, Switch } from "antd"
import Form, { FormInstance } from "antd/lib/form"
import { Store } from "antd/lib/form/interface"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"

import { FINWAY_ADMIN_EMAIL } from "Shared/config/consts"
import { useLoggedInEmployee } from "Shared/hooks/employee.hooks"

interface ExportAndBookkeepingFormInterface {
    formInstance: FormInstance
    onSubmit: (values: Store) => void
    company: Company
}

const ExportAndBookkeepingForm: React.FC<ExportAndBookkeepingFormInterface> = ({ formInstance, onSubmit, company }) => {
    const { t } = useTranslation()
    const loggedInUser = useLoggedInEmployee()

    useEffect(() => {
        if (company) {
            formInstance.setFieldsValue({
                ...company,
            })
        }

        return () => {
            formInstance.resetFields()
        }
    }, [])

    const onFinish = (values: Store) => onSubmit({ ...values })

    return (
        <Form form={formInstance} layout="vertical" onFinish={onFinish} autoComplete="off">
            <Row gutter={[16, 16]}>
                <Col span={8}>
                    <Form.Item label={t("input:company_settings.is_lexware_export_enabled")} name="isLexwareExportEnabled" valuePropName="checked">
                        <Switch />
                    </Form.Item>
                </Col>
                <Col span={10}>
                    <Form.Item label={t("input:company_settings.append_audit_trail_to_invoice")} name="isAppendAuditTrailToInvoiceEnabled" valuePropName="checked">
                        <Switch />
                    </Form.Item>
                </Col>
                {loggedInUser.email === FINWAY_ADMIN_EMAIL ? (
                    <Col span={8}>
                        <Form.Item label={t("input:company_settings.is_non_sepa_xml_export_enabled")} name="isNonSepaXmlExportEnabled" valuePropName="checked">
                            <Switch disabled={loggedInUser.email !== FINWAY_ADMIN_EMAIL} />
                        </Form.Item>
                    </Col>
                ) : null}
                {/* TODO enable when we want to include GoB in the forthcoming version */}
                {/* <Col span={8}>
                    <Form.Item
                        label={t("input:company_settings.enforce_belegfunktion")}
                        name={["gobdCompliance", "enforceGobdCompliantInvoice"]}
                        valuePropName="checked"
                        extra={t("info:gobd_compliance.ask_support_to_enable")}
                    >
                        <Switch disabled={loggedInUser.email !== FINWAY_ADMIN_EMAIL} />
                    </Form.Item>
                </Col> */}
            </Row>
        </Form>
    )
}

export default ExportAndBookkeepingForm
