import { FormInstance, Rule } from "antd/lib/form"

import { checkMaxNumber, checkMinNumber } from "./utils.rules"

export const generateRules = (formInstance: FormInstance): { [key: string]: Array<Rule> } => ({
    minBudget: [
        {
            validator: (_rule: any, value: number | string) => checkMinNumber({ formInstance, value, name: "maxBudget" }),
        },
    ],
    maxBudget: [
        {
            validator: (_rule: any, value: number | string) => checkMaxNumber({ formInstance, value, name: "minBudget" }),
        },
    ],
    minCostCenterId: [
        {
            validator: (_rule: any, value: number | string) => checkMinNumber({ formInstance, value, name: "maxCostCenterId" }),
        },
    ],
    maxCostCenterId: [
        {
            validator: (_rule: any, value: number | string) => checkMaxNumber({ formInstance, value, name: "minCostCenterId" }),
        },
    ],
})
