import { COLOR, Currencies } from "@finway-group/shared/lib/consts"
import { CostCenter, CostCenterTypeEnum } from "@finway-group/shared/lib/models"
import { ResponsiveBar } from "@nivo/bar"
import { Col, Row } from "antd"
import numeral from "numeral"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import { barProps } from "Shared/config/consts"
import { useCostCenters } from "Shared/hooks/costCenter.hooks"
import { UserService } from "Shared/services"
import { breakpoints } from "Shared/utils/breakpoints.utils"
import { ColorGenerator } from "Shared/utils/colorGenerator.utils"
import { convertToBarDiagram, formatCurrencyNumber } from "Shared/utils/helper.utils"
import { useResizeWindowWidth } from "Shared/utils/hooks/useResizeWindowWidth"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

import ExpenseReportModal from "../../../components/modals/expenseReport.modal"
import LegendDetailModal from "./reportingDetails/legendDetailModal"

interface CostCenterOverTimeDiagramInterface {
    prefilledCostCenter?: CostCenter | any
    grouped?: boolean
    dataForCCOverTimeDiagram: Array<{
        costCenter: CostCenter | string
        data: Array<{
            year: number
            month: number
            expenseTotal: number
        }>
    }>
    maxValue?: number | "auto"
}

export const CostCenterOverTimeDiagram: React.FC<CostCenterOverTimeDiagramInterface> = ({ dataForCCOverTimeDiagram, prefilledCostCenter, grouped, maxValue }) => {
    const costCenterList = prefilledCostCenter ? [prefilledCostCenter.name] : dataForCCOverTimeDiagram.map((v) => v.costCenter)
    const costCenters = useCostCenters()
    const convertedBarData = convertToBarDiagram(dataForCCOverTimeDiagram)
    const usedCurrency = prefilledCostCenter ? prefilledCostCenter.budgetCurrency : UserService.getLoggedInEmployeeProfile().settings.globalCurrency
    const colors = ColorGenerator.generateColorGradient(COLOR.finway.green, COLOR.primary, costCenterList.length)
    const windowWidth = useResizeWindowWidth()
    const [selectedBarChartInfo, setSelectedBarChartInfo] = useStateIfMounted<any>({ costCenter: null, interval: null })
    const [showExpense, setShowExpense] = useState<boolean>(false)
    const [showLegendModal, setShowLegendModal] = useStateIfMounted(false)
    const { t } = useTranslation()

    const isOnMobile = windowWidth < breakpoints.lg

    const Tooltip = (id: any, value: number, color: string) => (
        <strong style={{ color }}>
            {id}: {formatCurrencyNumber(value, usedCurrency)}
        </strong>
    )

    const renderLegend = () => (
        <>
            <LegendDetailModal
                isVisible={showLegendModal}
                onClose={() => {
                    setShowLegendModal(false)
                }}
                items={costCenterList.map((ccName: string, index) => ({
                    id: ccName,
                    name: ccName,
                    color: colors[index],
                }))}
            />
            <Row className="ml-80 mt-30 overflow-y-hidden" style={{ height: "1.4em" }}>
                <Col span={20}>
                    <Row>
                        {costCenterList.map((costName: string, index: number) => (
                            <Col key={index} className="inline-block">
                                <div className="mr-10 float-left" key={index}>
                                    <span className="h-10 w-10 flex inline-flex" style={{ backgroundColor: `${colors[index]}` }}></span> <label>{costName} </label>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Col>
                <Col span={4} className="overflow-x-overlay">
                    <a
                        className="float-right mr-10"
                        onClick={() => {
                            setShowLegendModal(true)
                        }}
                        style={{ whiteSpace: "nowrap" }}
                    >
                        {t("label:show_more")}
                    </a>
                </Col>
            </Row>
        </>
    )

    const onBarClicked = (e: any) => {
        setSelectedBarChartInfo({
            costCenter: costCenters.filter((item) => item.name === e.id && item.costCenterType === CostCenterTypeEnum.SUB_COST_CENTER)[0],
            interval: e?.indexValue,
        })
        setShowExpense(true)
    }

    const calculatePaddingValue = () => (convertedBarData.length ? 0.1 + (0.8 * (12.0 - convertedBarData.length)) / 12.0 : 0.1)

    // To avoid clipping on the right part of the tick text on mobile view
    const marginProp = isOnMobile ? { margin: { right: 20, bottom: 50, left: 70, top: 10 } } : {}
    const maxValueProp = maxValue ? { maxValue } : {}
    return (
        <div>
            <div className="h-400 w-full m-0">
                <ResponsiveBar
                    {...barProps}
                    data={convertedBarData}
                    indexBy={"date"}
                    keys={costCenterList}
                    colors={colors}
                    padding={calculatePaddingValue()}
                    groupMode={grouped ? "grouped" : "stacked"}
                    axisBottom={{ tickSize: 10, tickPadding: isOnMobile ? 0 : 5, tickRotation: isOnMobile ? 45 : 0 }}
                    axisLeft={{
                        tickSize: 8,
                        tickPadding: isOnMobile ? 0 : 5,
                        format: (v: number) => formatCurrencyNumber(v, usedCurrency, false, 1, 5 - Currencies[usedCurrency].symbol.length),
                        tickValues: 8,
                    }}
                    tooltip={({ id, value, color }) => Tooltip(id, value, color)}
                    onClick={(e) => onBarClicked(e)}
                    label={({ value }) => numeral(value).format("0,0[.]00a")}
                    {...marginProp}
                    {...maxValueProp}
                />
            </div>
            {!prefilledCostCenter && renderLegend()}
            <ExpenseReportModal selectedCostCenterAndInterval={selectedBarChartInfo} isShowing={showExpense} onCancel={setShowExpense} />
        </div>
    )
}

export default CostCenterOverTimeDiagram
