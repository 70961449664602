import { Dispatch, SetStateAction, useState } from "react"

import useIsComponentMounted from "./useIsComponentMounted"

const useStateIfMounted = <T>(initialValue: T): [T, Dispatch<SetStateAction<T>>] => {
    const isComponentMounted = useIsComponentMounted()
    const [state, setState] = useState(initialValue)

    const newSetState = (value: SetStateAction<T>) => {
        if (isComponentMounted.current) {
            setState(value)
        }
    }

    return [state, newSetState]
}

export default useStateIfMounted
