import { ExclamationCircleOutlined } from "@ant-design/icons"
import { Employee } from "@finway-group/shared/lib/models"
import UserCompanyProfileInterface from "@finway-group/shared/lib/models/user/userCompanyProfileInterface"
import { Card, Col, Row, Tooltip } from "antd"
import React, { Fragment, useCallback } from "react"
import { Edit as EditIcon, Plus as PlusIcon } from "react-feather"
import { useTranslation } from "react-i18next"

import AddEmailAliasModal from "Components/modals/addEmailAlias.modal"
import { useCompanies } from "Shared/hooks/company.hooks"
import { AuthzService } from "Shared/services"
import { getTooltipPopupContainer } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

interface EmployeeEmailAliasesInterface {
    employee: Employee
    shouldShowEmailAliases: boolean
}

const EmployeeEmailAliases: React.FC<EmployeeEmailAliasesInterface> = ({ employee, shouldShowEmailAliases }) => {
    const { t } = useTranslation()
    const [isAddEmailAliasVisible, setIsAddEmailAliasVisible] = useStateIfMounted(false)
    const [companyId, setCompanyId] = useStateIfMounted<string | undefined>(undefined)

    const companies = useCompanies(false)

    const canUpdateEmployeeEmailAliases = AuthzService.canUpdateUserApprovalData(employee)

    const getCompanyNameInitials = useCallback((name: any) => {
        const arr = name ? name.trim().split(" ") : ""
        return arr.slice(0, arr.length > 3 ? 3 : arr.length).map((word: string) => word[0].toUpperCase())
    }, [])

    const CompanyLogo = ({ isMobile, logo, companyName }: { isMobile?: boolean; logo: any; companyName: any }) => {
        const cssClasses = isMobile
            ? "md:hidden w-full h-full max-w-50 max-h-50 border justify-center align-left mr-10 p-2 mb-10 rounded-lg"
            : "hidden md:block w-full h-full max-w-50 max-h-50 border justify-center align-center self-center items-center mr-10 p-2 rounded-lg"
        return (
            <div className={cssClasses}>
                {logo ? (
                    <img className="w-full h-auto max-w-50 max-h-50 flex justify-center items-center" src={logo} alt="logo" />
                ) : (
                    <span className="placeholder-primary text-xl h-44 max-w-50 flex justify-center items-center">{getCompanyNameInitials(companyName)}</span>
                )}
            </div>
        )
    }

    const onAddEmailAlias = (companyId: string) => {
        setCompanyId(companyId)
        setIsAddEmailAliasVisible(true)
    }

    const CompanyAliasCard = (companyProfile: UserCompanyProfileInterface) => {
        const companyObject = companies.find((company) => company._id === companyProfile.companyId)
        if (!companyObject) {
            return <Fragment key={companyProfile.companyId}></Fragment>
        }

        return (
            <Row key={companyProfile.companyId} gutter={[16, 16]} justify="center">
                <Col md={24} xs={22}>
                    <div className="p-10 flex flex-row border flex-grow w-full rounded-lg">
                        <CompanyLogo logo={companyObject.logo} companyName={companyObject.name} />

                        <div className="flex flex-col md:flex-row w-full">
                            <CompanyLogo logo={companyObject.logo} companyName={companyObject.name} isMobile />

                            <div className="align-left flex flex-col mr-10 w-full">
                                <b className="truncate placeholder-primary">{companyObject.name}</b>

                                <div className="overflow-x-auto w-auto pr-10">
                                    {companyProfile.weavrData?.weavrEmail ? (
                                        canUpdateEmployeeEmailAliases ? (
                                            <div className="cursor-pointer" onClick={() => onAddEmailAlias(companyObject._id)}>
                                                <Row>
                                                    <EditIcon size={14} className="mt-4 mr-6" />
                                                    {companyProfile.weavrData?.weavrEmail}
                                                </Row>
                                            </div>
                                        ) : (
                                            <Row>{companyProfile.weavrData?.weavrEmail}</Row>
                                        )
                                    ) : (
                                        canUpdateEmployeeEmailAliases && (
                                            <div className="cursor-pointer" onClick={() => onAddEmailAlias(companyObject._id)}>
                                                <Row>
                                                    <PlusIcon size={14} className="mt-4 mr-4" />
                                                    {t("action:employee.add_email_alias")}
                                                </Row>
                                            </div>
                                        )
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        )
    }

    return !shouldShowEmailAliases ? (
        <></>
    ) : (
        <div className="overflow-hidden">
            <h2>
                {t("label:sub_company_email_aliases")}
                <Tooltip getPopupContainer={getTooltipPopupContainer} title={t("info:sub_company_aliases")} className="ml-10">
                    <ExclamationCircleOutlined />
                </Tooltip>
            </h2>

            {companyId && <AddEmailAliasModal isShowing={isAddEmailAliasVisible} employee={employee} companyId={companyId} onCancel={() => setIsAddEmailAliasVisible(false)} />}
            <Card>{employee.companyProfiles?.map(CompanyAliasCard)}</Card>
        </div>
    )
}

export default EmployeeEmailAliases
