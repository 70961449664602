import { CostCenter2 } from "@finway-group/shared/lib/models"
import { Button, Form, Modal } from "antd"
import { Store } from "antd/lib/form/interface"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import CostCenter2Form from "Components/forms/costCenter2.form"
import { NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { createCostCenter2 } from "Shared/store/actions/costCenter2/costCenter2Actions"

interface CostCenter2ModalProps {
    isShowing: boolean
    onCancel: (state: boolean, costCenter2?: CostCenter2) => void
    shouldRedirectOnRestoration?: boolean
}

const CostCenter2Modal = ({ isShowing, onCancel, shouldRedirectOnRestoration = true }: CostCenter2ModalProps) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [formInstance] = Form.useForm()
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const onSubmit = async (values: Store) => {
        setIsLoading(true)

        const costCenter2Data = values as CostCenter2
        try {
            const costCenter2 = await createCostCenter2(costCenter2Data)(dispatch)
            NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:settings.cost_center_2.created.title"), t("notification:settings.cost_center_2.created.message"))
            handleHide(costCenter2)
        } catch (err) {
            setIsLoading(false)
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:error"))
        }
    }

    const handleHide = (costCenter2?: CostCenter2) => {
        setIsLoading(false)
        formInstance.resetFields()
        onCancel(false, costCenter2)
    }

    return (
        <Modal
            destroyOnClose={true}
            afterClose={() => formInstance.resetFields()}
            visible={isShowing}
            maskClosable={false}
            title={t("action:cost_center_2.create")}
            onCancel={() => onCancel(false)}
            closable={true}
            keyboard={true}
            className="ant-modal--small"
            footer={[
                <Button
                    key="back"
                    onClick={() => {
                        formInstance.resetFields()
                        onCancel(false)
                    }}
                >
                    {t("action:cancel")}
                </Button>,
                <Button
                    key="submit"
                    type="primary"
                    loading={isLoading}
                    onClick={() => {
                        formInstance.submit()
                    }}
                >
                    {t("action:cost_center_2.submit")}
                </Button>,
            ]}
        >
            <CostCenter2Form formInstance={formInstance} onSubmit={onSubmit} />
        </Modal>
    )
}

export default CostCenter2Modal
