import { Empty } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"

interface NoSearchDataFoundInterface {
    imageStyle?: React.CSSProperties
    hasMessage?: boolean
}

const NoSearchDataFound: React.FC<NoSearchDataFoundInterface> = ({ imageStyle, hasMessage = true }) => {
    const { t } = useTranslation()
    return (
        <div>
            <Empty
                image={`./icons/no_data_found.svg`}
                imageStyle={{
                    height: 150,
                    marginRight: "auto",
                    marginLeft: "auto",
                    marginTop: "15%",
                    marginBottom: "40px",
                    display: "inline-block",
                    ...imageStyle,
                }}
                description={<span className="text-lg font-bold mt-2 mb-0">{t("info:no_search_data_found.title")}</span>}
            >
                {hasMessage ? <p>{t("info:no_search_data_found.message")}</p> : <></>}
            </Empty>
        </div>
    )
}

export default NoSearchDataFound
