import Icon from "@ant-design/icons"
import React from "react"

const infoCircleSVG = () => (
    <svg width="16" height="16" fill="none" version="1.1" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <path
            d="m8 14.667c3.6819 0 6.6667-2.9848 6.6667-6.6666 0-3.6819-2.9848-6.6667-6.6667-6.6667-3.6819 0-6.6667 2.9848-6.6667 6.6667 0 3.6818 2.9848 6.6666 6.6667 6.6666z"
            stroke="#606C7E"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <g transform="translate(0 .011903)" fill="none">
            <g transform="translate(7 5.7136)">
                <path d="m1 6.0006v-4.6667" stroke="#606c7e" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <g transform="translate(7 3.5959)">
                <path d="m1 1.3327v-0.66666" stroke="#606c7e" strokeLinecap="round" strokeLinejoin="round" />
            </g>
        </g>
    </svg>
)

const InfoCircleIcon = (props: any) => <Icon component={infoCircleSVG} {...props} />

export default InfoCircleIcon
