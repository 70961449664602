import { BankConnection } from "@finway-group/shared/lib/models"
import { ActionCreator, Dispatch } from "redux"

import { BankService } from "Shared/services"
import { ThunkResult } from "Shared/store"

import { CancelFetchAction, FetcherActionTypes } from "../fetcher/fetcherTypes"
import { BankActionTypes, FetchAllBankConnectionsAction, FetchOneBankConnectionAction, SetActionInProgressAction } from "./bankTypes"

export const fetchAllBankConnections: ActionCreator<ThunkResult<Array<BankConnection>>> = () => async (dispatch: Dispatch) => {
    const bankConnections = await BankService.fetchAllBankConnections()

    const cancelFetchAction: CancelFetchAction = {
        type: FetcherActionTypes.CANCEL_FETCH,
        isFetchCancelled: true,
    }

    dispatch(cancelFetchAction)

    const fetchAllBankConnectionsAction: FetchAllBankConnectionsAction = {
        type: BankActionTypes.FETCH_ALL_BANK_CONNECTIONS,
        bankConnections,
    }
    dispatch(fetchAllBankConnectionsAction)

    return bankConnections
}

export const setBankConnection: ActionCreator<ThunkResult<void>> = (bankConnection: BankConnection) => async (dispatch: Dispatch) => {
    const fetchOneBankConnectionAction: FetchOneBankConnectionAction = {
        type: BankActionTypes.FETCH_ONE_BANK_CONNECTION,
        bankConnection,
    }

    dispatch(fetchOneBankConnectionAction)
}

export const fetchBankConnection: ActionCreator<ThunkResult<void>> = (id: string) => async (dispatch: Dispatch) => {
    const bankConnection = await BankService.fetchBankConnectionById(id)

    const fetchOneExpenseAction: FetchOneBankConnectionAction = {
        type: BankActionTypes.FETCH_ONE_BANK_CONNECTION,
        bankConnection,
    }

    dispatch(fetchOneExpenseAction)
}

export const setActionInProgress: ActionCreator<ThunkResult<void>> = (actionInProgress: boolean) => async (dispatch: Dispatch) => {
    const setActionInProgress: SetActionInProgressAction = {
        type: BankActionTypes.SET_ACTION_IN_PROGRESS,
        actionInProgress,
    }

    dispatch(setActionInProgress)
}
