import {
    Card,
    CardStatusEnum,
    Employee,
    Expense,
    FilterBaseInterface,
    FilterPresetEnum,
    InboxInvoice,
    Transaction,
    TransactionCategory,
    Vendor,
    Workflow,
} from "@finway-group/shared/lib/models"
import axios from "axios"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { isDemo } from "Shared/config/consts"
import { TableService } from "Shared/services"
import FilterService from "Shared/services/filter.service"
import store from "Shared/store"
import { fetchTable, updateTableFilter } from "Shared/store/actions/tables/tableActions"
import { TablesEnum } from "Shared/store/reducers/tableConfigReducer"
import { Table, TableData, TransactionCategoryWithChildren, createInitialTable } from "Shared/store/reducers/tableReducer"
import { RootState } from "Shared/store/rootState.interface"
import { convertSortingToString } from "Shared/utils/expense.utils"
import { getFilterTargetPage } from "Shared/utils/filter.utils"
import { employeeSorter, insertIf } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"
import { TableDocType, getTableDefaultSort, getTableDocType } from "Shared/utils/table.utils"

/*
SELECTORS (STORE SUBSCRIPTION)
*/
export const useTable = <T>(table: TablesEnum): Table<T> => {
    const storeTable: Table<T> = useSelector((({ tables }: RootState) => tables[table]) as any)

    // if more specific differentiation is needed in the future, use TableEnum instead of TableDocType
    const tableDocType = getTableDocType(table)
    switch (tableDocType) {
        case TableDocType.INBOX_INVOICE:
            return { ...storeTable, data: { ...storeTable.data, docs: storeTable.data.docs.map((inboxInvoice) => new InboxInvoice(inboxInvoice)) } } as any
        case TableDocType.EXPENSE:
        case TableDocType.TRIP_FOLDER:
            return {
                ...storeTable,
                data: {
                    ...storeTable.data,
                    docs: storeTable.data.docs.map((expense: any) =>
                        expense?.children?.length > 0 ? new Expense({ ...expense, children: expense.children.map((child: any) => new Expense(child)) }) : new Expense(expense),
                    ),
                },
            } as any
        case TableDocType.CARD:
            return {
                ...storeTable,
                data: { ...storeTable.data, ...insertIf(!isDemo, { filter: { status: CardStatusEnum.ACTIVE } }), docs: storeTable.data.docs.map((card) => new Card(card)) },
            } as any
        case TableDocType.TRANSACTION:
            return { ...storeTable, data: { ...storeTable.data, docs: storeTable.data.docs.map((transaction) => new Transaction(transaction)) } } as any
        case TableDocType.EMPLOYEE:
            return { ...storeTable, data: { ...storeTable.data, docs: storeTable.data.docs.map((employee) => new Employee(employee)).sort(employeeSorter) } } as any
        case TableDocType.COST_CENTER:
        case TableDocType.EXPORT_HISTORY:
            return storeTable
        case TableDocType.VENDOR:
            return { ...storeTable, data: { ...storeTable.data, docs: storeTable.data.docs.map((vendor) => new Vendor(vendor)) } } as any
        case TableDocType.WORKFLOW:
            return { ...storeTable, data: { ...storeTable.data, docs: storeTable.data.docs.map((workflow) => new Workflow(workflow)) } } as any
        case TableDocType.TRANSACTION_CATEGORY:
            return {
                ...storeTable,
                data: {
                    ...storeTable.data,
                    docs: storeTable.data.docs.map((doc: any) => {
                        const transactionCategory = new TransactionCategory(doc) as unknown as TransactionCategoryWithChildren
                        transactionCategory.children = transactionCategory.children.map((child: any) => new TransactionCategory(child))
                        return transactionCategory
                    }),
                },
            } as any
        default:
            return createInitialTable()
    }
}

export const useTableShouldFetch = (table: TablesEnum): boolean => useSelector(({ tables }: RootState) => tables[table].shouldFetch)

export const useTableFilterObject = (table: TablesEnum) => useSelector(({ tables }: RootState) => tables[table].data.filter)

export const useTableFilterPresets = (table: TablesEnum) => useSelector(({ tables }: RootState) => (tables[table].data.filter?.presets ?? []) as Array<FilterPresetEnum>)

export const useTableFilterQueryString = (table: TablesEnum) => {
    const filterObject = useSelector(({ tables }: RootState) => tables[table].data.filter)
    return TableService.getTableFilterQueryString(table, filterObject)
}

export const useTableSearchString = (table: TablesEnum) => useSelector(({ tables }: RootState) => tables[table].data.search)

export const useTableSearchQueryString = (table: TablesEnum) => {
    const searchString = useSelector(({ tables }: RootState) => tables[table].data.search)
    return TableService.getTableSearchQueryString(table, searchString)
}

/*
STORE ACCESS
*/
export const getTableDocsStore = (table: TablesEnum) => store.getState().tables[table].data.docs

export const getTableFilterObjectStore = <T>(table: TablesEnum) => {
    const data = store.getState().tables[table].data.filter
    return data as T
}

export const getTableFilterQueryStringStore = (table: TablesEnum) => {
    const filterObject = store.getState().tables[table].data.filter
    return TableService.getTableFilterQueryString(table, filterObject)
}

export const getTableSearchQueryStringStore = (table: TablesEnum) => {
    const searchString = store.getState().tables[table].data.search
    return TableService.getTableSearchQueryString(table, searchString)
}

export const getTableQueryParams = async (table: TablesEnum) => {
    const { filter, sort, limit, page, search } = store.getState().tables[table].data as TableData<any>

    return {
        limit,
        page,
        search,
        filterQueryString: TableService.getTableBaseFilterQueryString(table) + TableService.getTableFilterQueryString(table, filter),
        sortQueryString: sort.field ? convertSortingToString(sort) : convertSortingToString(getTableDefaultSort(table)),
        searchQueryString: TableService.getTableSearchQueryString(table, search),
    }
}

export const getTableQueryStringWithoutPagination = (table: TablesEnum) => {
    const { filter, search } = store.getState().tables[table].data as TableData<any>
    return `${TableService.getTableBaseFilterQueryString(table) + TableService.getTableFilterQueryString(table, filter)}${TableService.getTableSearchQueryString(table, search)}`
}

/*
OTHER HOOKS
*/
export interface tableOptions {
    cardId?: string
    expenseId?: string
    inboxInvoiceId?: string
}

export const useFetchTable = (table: TablesEnum, options: tableOptions = {}) => {
    const dispatch = useDispatch()
    const shouldFetch = useTableShouldFetch(table) // checks if table is in redux

    useEffect(() => {
        if (!shouldFetch) return

        const source = axios.CancelToken.source()
        dispatch(fetchTable(table, source, options))

        return () => {
            source.cancel("Cancelling in cleanup")
        }
    }, [shouldFetch])
}

export const useTableFilter = (table: TablesEnum) => useSelector(({ tables }: RootState) => tables[table].data.filter)

/**
 * Triggers a loading when dashboard search or filter change, until the table passed as parameter is fetched or returns an error
 */
export const useTableFilterAndSearchLoading = (table: TablesEnum) => {
    const storeTable: Table<any> = useTable(table)
    const [isLoading, setIsLoading] = useStateIfMounted(false)
    const tableSearchString = useTableSearchString(table)
    const tableFilterQueryString = useTableFilterQueryString(table)

    useEffect(() => {
        if (storeTable.shouldFetch) setIsLoading(true) // When we mount the tab, if it has to fetch the table we set the loading to true until it fetches it.
    }, [tableSearchString, tableFilterQueryString])

    useEffect(() => {
        if ((storeTable.isFetching === false || storeTable.error) && isLoading === true) setIsLoading(false)
    }, [storeTable.isFetching, storeTable.error])

    return isLoading
}

export const useTodoFilterAndSearchTabLoading = () => {
    const purchaseApprovalLoading = useTableFilterAndSearchLoading(TablesEnum.TODO_PURCHASE_APPROVAL)
    const invoiceApprovalLoading = useTableFilterAndSearchLoading(TablesEnum.TODO_INVOICE_APPROVAL)
    const invoiceAndPurchaseApprovalTable = useTableFilterAndSearchLoading(TablesEnum.TODO_INVOICE_AND_PURCHASE)
    const docsNeededLoading = useTableFilterAndSearchLoading(TablesEnum.TODO_DOCS_NEEDED)
    const approvalPendingLoading = useTableFilterAndSearchLoading(TablesEnum.TODO_APPROVAL_PENDING)
    return purchaseApprovalLoading || invoiceApprovalLoading || docsNeededLoading || approvalPendingLoading || invoiceAndPurchaseApprovalTable
}

export const usePayAndExportFilterAndSearchTabLoading = () => {
    const toBePaid = useTableFilterAndSearchLoading(TablesEnum.PAY_AND_EXPORT_TO_BE_PAID)
    const toBeExported = useTableFilterAndSearchLoading(TablesEnum.PAY_AND_EXPORT_TO_BE_EXPORTED)
    return toBePaid || toBeExported
}
