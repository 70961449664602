import { ExclamationCircleFilled } from "@ant-design/icons"
import { Tooltip } from "antd"
import { TooltipPlacement } from "antd/es/tooltip"
import React from "react"
import { AlertTriangle } from "react-feather"

interface HoverableInfoInterface {
    message: string
    iconClassName?: string
    overlayInnerStyle?: React.CSSProperties
    placement?: TooltipPlacement
    type?: "info" | "warn"
    iconOverride?: React.ReactNode
}
export const HoverableInfo = ({ message, iconClassName = "ml-6", overlayInnerStyle = {}, placement = "top", type = "info", iconOverride }: HoverableInfoInterface) => {
    let icon

    if (iconOverride) {
        icon = iconOverride
    } else {
        icon =
            type === "warn" ? (
                <AlertTriangle fill="#FBAC3B" size={18} stroke="#FFFFFF" className={iconClassName} />
            ) : (
                <ExclamationCircleFilled className={iconClassName} style={{ color: "#3866FF" }} />
            )
    }

    const getPopupContainer = () => (document.querySelector(".ant-modal-content") ?? document.body) as HTMLElement

    return (
        <Tooltip getPopupContainer={getPopupContainer} title={message} placement={placement} className="align-middle" overlayInnerStyle={overlayInnerStyle}>
            {icon}
        </Tooltip>
    )
}
