import { CurrencyEnum, EnrichedPaymentSettings, PriceIntervalEnum } from "@finway-group/shared/lib/models"
import { mapIvalToMonths } from "@finway-group/shared/lib/utils/ival"
import { Alert, Button, Card, Col, Row, Tag } from "antd"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import ProgressBar from "Components/ProgressBar"
import { CardIcon, CubeIcon, CubeXLIcon, DollarSignInvoiceIcon, InfoCircleIcon, LinesIcon, RocketIcon } from "Components/icons"
import { IconBackground } from "Components/icons/iconBackground"
import Loading from "Components/loading"
import PaymentCardPricesModal from "Components/modals/paymentCardPrices.modal"
import PriceLabel from "Components/priceLabel"
import { useModal } from "Shared/context/modal.context"
import { getLanguageCode } from "Shared/hooks/i18n.hooks"
import { usePaymentProducts } from "Shared/hooks/payment.hooks"
import { PaymentSettingsTabRoutes, RoutePathEnum } from "Shared/router/RoutePath.enum"
import { NotificationService, PaymentSettingsService } from "Shared/services"
import { ThunkDispatchResult } from "Shared/store"
import { fetchPaymentProducts } from "Shared/store/actions/payment/paymentActions"

interface PaymentSubscriptionSettingsInterface {
    paymentSettings: EnrichedPaymentSettings
    onUpdated: (paymentSettings: EnrichedPaymentSettings) => void
    setActiveTab: (activeTab: PaymentSettingsTabRoutes) => void
}

const PaymentSubscriptionSettings: React.FC<PaymentSubscriptionSettingsInterface> = ({ paymentSettings, onUpdated, setActiveTab }) => {
    const dispatch: ThunkDispatchResult = useDispatch()
    const { t } = useTranslation()
    const locale = getLanguageCode()
    const { showModal } = useModal()
    const { plans, cards, isFetching } = usePaymentProducts()

    const contractLineItems = paymentSettings?.contract?.lineItems
    const contractFirstLineItem = contractLineItems && contractLineItems.length > 0 ? contractLineItems[0] : undefined
    const contractPlanDiscount = contractFirstLineItem?.discount
        ? {
              currency: contractFirstLineItem?.discount.currency,
              off: contractFirstLineItem?.discount.off,
              startDate: contractFirstLineItem?.startDate,
              durationInMonths: contractFirstLineItem?.termInMonths,
          }
        : undefined

    const subscription = paymentSettings?.subscription
    const subscriptionIval = (subscription?.interval ?? contractFirstLineItem?.interval) as PriceIntervalEnum
    const subscriptionPlanId = subscription?.paymentProductPlanId ?? (contractFirstLineItem?.productId as string)
    const [isFetchingCustomPlan, setIsFetchingCustomPlan] = useState(false)
    const [subscriptionPlan, setSubscriptionPlan] = useState(plans?.find((e) => e._id === subscriptionPlanId))
    const subscriptionPlanDiscount = subscription?.planDiscount ?? (!subscription ? contractPlanDiscount : undefined)
    const calculateSubsciptionUnits = () => {
        const units = parseInt(subscriptionPlan?.metadata?.units ?? "0", 10)
        let multiplier = 0
        try {
            multiplier = mapIvalToMonths(subscriptionIval)
        } catch (err) {
            multiplier = 1
        }
        return units * multiplier
    }
    const subscriptionUnits = calculateSubsciptionUnits()
    const subscriptionPrice = subscriptionPlan?.prices?.find((e) => e.interval === subscriptionIval)

    const cardCount = paymentSettings?.physicalCards
    const cardPrice = cards[0]?.prices?.find((e) => e.interval === subscriptionIval)

    const getCardPricePerCard = () => {
        const price = cardPrice?.tiers[0]?.unitAmount ?? 0
        return price > 0 ? price / 100 : 0
    }

    const calculateCardPriceTotal = () => {
        const tiers = cardPrice?.tiers
        if (!tiers) return 0
        let cardCountCopy = cardCount
        let unitAmount = 0
        let prevUpTo = 0
        // eslint-disable-next-line no-restricted-syntax
        for (const tier of tiers) {
            if (tier.upTo && cardCount >= tier.upTo) {
                const cardsTier = tier.upTo - prevUpTo
                unitAmount += cardsTier * tier.unitAmount
                cardCountCopy -= cardsTier
                prevUpTo = tier.upTo
            } else {
                unitAmount += cardCountCopy * tier.unitAmount
                break
            }
        }
        return unitAmount / 100
    }

    useEffect(() => {
        dispatch(fetchPaymentProducts())
    }, [dispatch])

    useEffect(() => {
        if (subscriptionPlanId && !subscriptionPlan) {
            setIsFetchingCustomPlan(true)
            PaymentSettingsService.getProduct(subscriptionPlanId)
                .then(setSubscriptionPlan)
                .catch((err) => NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:unknown")))
                .finally(() => setIsFetchingCustomPlan(false))
        }
    }, [paymentSettings, subscriptionPlan, subscriptionPlanId, t])

    const handleOnShowCardPrices = () =>
        showModal(PaymentCardPricesModal, false, {
            cardProducts: cards,
            isShowing: true,
        })

    const renderInfoCardPlanDiscountTag = () => {
        if (!subscriptionPlanDiscount) return <></>
        return (
            <Tag color="green" className="ml-10">
                {`${t("label:payment_settings.discount")}: `}
                {subscriptionPlanDiscount.currency ? (
                    <PriceLabel value={subscriptionPlanDiscount.off / 100} currency={subscriptionPlanDiscount.currency} />
                ) : (
                    `${subscriptionPlanDiscount.off}%`
                )}
                {` - ${t("label:payment_settings.discount_until")} ${moment(subscriptionPlanDiscount.startDate)
                    .add(subscriptionPlanDiscount.durationInMonths, "months")
                    .subtract(1, "days")
                    .format("L")}`}
            </Tag>
        )
    }

    const renderInfoCardPriceLabel = () => {
        const subscriptionPriceAmount = (subscriptionPrice?.amount ?? 1) / 100
        const subscriptionPriceCurrency = subscriptionPrice?.currency ?? CurrencyEnum.EUR

        if (!subscriptionPlanDiscount) return <PriceLabel value={subscriptionPriceAmount} currency={subscriptionPriceCurrency} />
        return (
            <>
                <span className="mr-10">
                    <PriceLabel
                        value={
                            subscriptionPlanDiscount.currency
                                ? subscriptionPriceAmount - subscriptionPlanDiscount.off / 100
                                : subscriptionPriceAmount * ((100 - subscriptionPlanDiscount.off) / 100)
                        }
                        currency={subscriptionPriceCurrency}
                    />
                </span>
                <span className="line-through">
                    <PriceLabel value={subscriptionPriceAmount} currency={subscriptionPriceCurrency} />
                </span>
            </>
        )
    }

    const renderInfoCard = (button = <></>) => (
        <Card>
            <Row gutter={[16, 16]}>
                <Col flex={1} className="flex items-center space-x-10">
                    <IconBackground>
                        <CubeIcon />
                    </IconBackground>
                    <p className="text-lg text-primary">
                        {subscriptionPlan?.metadata[`${locale}_name`] || subscriptionPlan?.name}
                        {renderInfoCardPlanDiscountTag()}
                    </p>
                </Col>
                <Col flex={1} className="flex justify-end items-center">
                    <p className="text-xl font-semibold text-primary">{renderInfoCardPriceLabel()}</p>
                </Col>
            </Row>
            <Row gutter={[16, 16]}>
                <Col flex={1} className="flex items-center flex-wrap">
                    <div className="basis-full">
                        <p className="text-gray-900">{t("label:payment_settings.usage_consumption")}</p>
                    </div>
                    <div className="block w-full">
                        <ProgressBar
                            currency={subscriptionPrice?.currency ?? CurrencyEnum.EUR}
                            value={paymentSettings?.invoiceConsumption ?? 0}
                            total={subscriptionUnits}
                            thin
                            extended
                            includeValuesBelowBar
                            hideLabels
                        />
                    </div>
                </Col>
                <Col flex={1} className="flex justify-end items-center space-x-10 flex-wrap">
                    {button}
                </Col>
            </Row>
        </Card>
    )

    /* TODO: Remove once existing customers are migrated */
    const renderAlreadyPaidLabel = () => {
        const now = moment()
        let isVisible = paymentSettings.isExistingCustomer && moment(subscription?.trialEndDate).isAfter(now)

        // using simulated clock from stripe
        if (!now.isBetween(moment(subscription?.startDate), moment(subscription?.endDate))) {
            isVisible = false
        }

        if (!isVisible) return <></>

        return (
            <Tag color="blue" className="ml-10">
                {t("label:payment_settings.already_paid")}
            </Tag>
        )
    }

    const renderTrialLabel = () => {
        const now = moment()
        let isVisible = !paymentSettings.isExistingCustomer && moment(subscription?.trialEndDate).isAfter(now)

        // using simulated clock from stripe
        if (!now.isBetween(moment(subscription?.startDate), moment(subscription?.endDate))) {
            isVisible = false
        }

        if (!isVisible) return <></>

        return (
            <Tag color="blue" className="ml-10">
                {t("label:payment_settings.trial")}
            </Tag>
        )
    }

    const renderBillingPeriodCard = (canUpgrade: boolean) => (
        <Card>
            <Row gutter={[16, 16]}>
                <Col flex={1} className="flex items-center text-lg text-primary space-x-10">
                    <IconBackground>
                        <DollarSignInvoiceIcon />
                    </IconBackground>
                    <p>{t("label:payment_settings.billing_period")}</p>
                    {renderAlreadyPaidLabel()}
                    {renderTrialLabel()}
                </Col>
                <Col flex={1} className="flex justify-end items-center text-xl font-semibold text-primary">
                    {t(`label:payment_settings.intervals.${PriceIntervalEnum[subscriptionIval].toLowerCase()}`)}
                </Col>
            </Row>
            <Row>
                <Col className="flex items-center text-gray-900">
                    {moment(subscription?.startDate).format("L")} - {moment(subscription?.endDate).subtract(1, "days").format("L")}
                </Col>
            </Row>
        </Card>
    )

    const renderCardPrices = (interval: PriceIntervalEnum) => (
        <Card>
            <Row gutter={[16, 16]}>
                <Col flex={1} className="flex items-center text-lg text-primary space-x-10">
                    <div>
                        <CardIcon />
                    </div>
                    <div>
                        <p>
                            ({cardCount}) {t("label:payment_settings.physical_cards")}
                        </p>
                        <p>
                            <span>
                                <PriceLabel value={calculateCardPriceTotal()} currency={cardPrice?.currency ?? CurrencyEnum.EUR} />{" "}
                                {t(`label:payment_settings.per_card_interval.${PriceIntervalEnum[interval].toLowerCase()}`)}
                            </span>
                            <span className="finway-gray-text text-sm">
                                {" "}
                                / <PriceLabel value={getCardPricePerCard()} currency={cardPrice?.currency ?? CurrencyEnum.EUR} /> {t("label:payment_settings.per_card")}
                            </span>
                        </p>
                    </div>
                </Col>
                <Col flex={1} className="flex justify-end items-center text-xl font-semibold text-primary">
                    <Button loading={isFetching} icon={<InfoCircleIcon />} className="ant-btn btn-highlight-gray-active" onClick={handleOnShowCardPrices}>
                        {t("action:payment_settings.card_prices")}
                    </Button>
                </Col>
            </Row>
        </Card>
    )

    //
    // Render Logic
    //

    if (isFetching || isFetchingCustomPlan) return <Loading />

    // Customer is subscribed
    if (subscription) {
        // if there is a schedule attached the customer should not upgrade
        const canUpgrade = !subscription.scheduleId
        return (
            <>
                {renderInfoCard()}
                {renderBillingPeriodCard(canUpgrade)}
                {renderCardPrices(subscriptionIval)}
            </>
        )
    }

    // Customer has hubspot quote (aka contract) but is not signed up in stripe yet
    if (contractFirstLineItem) {
        return (
            <>
                <Row className="mb-10">
                    <Col span={24}>
                        <Alert
                            message={<span className="font-bold">{t("notification:payment_settings.automatic_billing.title")}</span>}
                            description={
                                <>
                                    <p>{t("notification:payment_settings.automatic_billing.message1")}</p>
                                    <p>{t("notification:payment_settings.automatic_billing.message2")}</p>
                                </>
                            }
                            type="info"
                            showIcon={true}
                        />
                    </Col>
                </Row>
                {renderInfoCard(
                    <Button icon={<RocketIcon />} className="ant-btn btn-highlight-green-active" onClick={() => setActiveTab(RoutePathEnum.SETTINGS_BILLING_TAB_PAYMENT_METHOD)}>
                        {t("action:payment_settings.sign_up")}
                    </Button>,
                )}
                {renderCardPrices(paymentSettings?.contract?.lineItems[0].interval)}
            </>
        )
    }

    // Customer has no subscription
    return (
        <Row justify="space-around">
            <Col className="flex flex-col items-center">
                <div className="relative flex items-center my-20">
                    {/* TODO FA-22: use correct theme color classes for icons */}
                    <CubeXLIcon className="absolute mx-auto inset-x-0 finway-primary-text" style={{ width: 210, height: 210 }} />
                    <LinesIcon style={{ color: "#F3F3F3" }} />
                </div>
                <h2 className="mt-20 mb-10">{t("label:payment_settings.trial_upgrade_header")}</h2>
                <p className="mb-10 text-sm text-text leading-none">{t("label:payment_settings.trial_upgrade_message")}</p>
            </Col>
        </Row>
    )
}

export default PaymentSubscriptionSettings
