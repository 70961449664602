import { ImportResult, ImportResultTypeEnum } from "@finway-group/shared/lib/models"
import { Button, Col, Divider, Pagination, Row } from "antd"
import React, { useEffect } from "react"
import { Download as DownloadIcon } from "react-feather"
import { useTranslation } from "react-i18next"

import { SpreadSheetFileGreenIcon } from "Components/icons/spreadsheetFileGreenIcon"
import Loading from "Components/loading"
import { ImportResultOperationEnum } from "Components/modals/spreadsheetDataUpload/importResult.types"
import { useCompanyDateTimeFormatter } from "Shared/hooks/company.hooks"
import { EmployeeService, FileService, ImportService, NotificationService } from "Shared/services"
import { getDataUploadOperationTotalCount } from "Shared/store/actions/import/importActions"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

interface ImportHistoryTabInterface {
    onCancel: () => void
}

const IMPORT_RESULTS_PER_PAGE = 5

export const ImportHistoryTab: React.FC<ImportHistoryTabInterface> = ({ onCancel }) => {
    const { t } = useTranslation()
    const { formatDateTime } = useCompanyDateTimeFormatter()

    const [importResults, setImportResults] = useStateIfMounted<Array<ImportResult> | Array<any>>([])
    const [isFetchingImportResults, setIsFetchingImportResults] = useStateIfMounted<boolean>(false)

    const [reportFileDownloadLoadings, setReportFileDownloadLoadings] = useStateIfMounted<{ [key: string]: boolean }>({})
    const [importFileDownloadLoadings, setImportFileDownloadLoadings] = useStateIfMounted<{ [key: string]: boolean }>({})

    const [page, setPage] = useStateIfMounted(1)
    const [totalDocs, setTotalDocs] = useStateIfMounted(0)

    const fetchImportResultHistory = async () => {
        try {
            setIsFetchingImportResults(true)
            const result = await ImportService.fetchLatestImportResults({ limit: IMPORT_RESULTS_PER_PAGE, page, importResultType: ImportResultTypeEnum.CREATION })
            setImportResults(result.docs)
            setTotalDocs(result.totalDocs)
        } catch (err) {
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:import_result_history"))
            onCancel()
        } finally {
            setIsFetchingImportResults(false)
        }
    }

    useEffect(() => {
        fetchImportResultHistory()
    }, [page])

    const handleImportFileDownload = async (importResult: ImportResult) => {
        try {
            setImportFileDownloadLoadings((prevState) => ({ ...prevState, [importResult._id]: true }))

            if (!importResult.importFile?.url) return
            FileService.downloadFileAsSignedUrl(importResult.importFile.url)
        } catch (err) {
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:bulk_data_upload.download_import"))
        } finally {
            setImportFileDownloadLoadings((prevState) => ({ ...prevState, [importResult._id]: false }))
        }
    }

    const handleReportDownload = async (importResult: ImportResult) => {
        try {
            setReportFileDownloadLoadings((prevState) => ({ ...prevState, [importResult._id]: true }))
            if (!importResult.reportFile?.url) return
            FileService.downloadFileAsSignedUrl(importResult.reportFile!.url)
        } catch (err) {
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:bulk_data_upload.download_report"))
        } finally {
            setReportFileDownloadLoadings((prevState) => ({ ...prevState, [importResult._id]: false }))
        }
    }

    const handlePagination = (page: number, _pageSize: number) => {
        setPage(page)
    }

    const renderHistoryElements = () => {
        if (isFetchingImportResults) return <Loading className="w-full" />

        if (!totalDocs) return <div className="text-text-dark">{t("missing:no_imports_yet")}</div>

        if (importResults.length) {
            return (importResults as Array<any>)?.map((importResult: ImportResult) => {
                const employee = EmployeeService.getEmployeeById(importResult.importedBy)
                const totalCreatedRecords = getDataUploadOperationTotalCount(importResult, ImportResultOperationEnum.CREATE)
                const totalRestoredRecords = getDataUploadOperationTotalCount(importResult, ImportResultOperationEnum.RESTORE)

                return employee ? (
                    <Row id={importResult._id} gutter={[16, 32]} justify="center" className="border-2 border-slate-500 rounded m-1 p-20">
                        <Col sm={12} xs={24}>
                            <div>
                                <p className="text-lg text-primary truncate">{employee?.getFullName()}</p>
                                <p className="text-primary truncate">{employee.email}</p>
                                <p className="text-gray-900 truncate">
                                    {t("info:total_imported_entries_on_date", {
                                        total: totalCreatedRecords + totalRestoredRecords,
                                        date: formatDateTime(importResult.createdAt),
                                    })}
                                </p>
                            </div>
                        </Col>
                        <Col sm={12} xs={24}>
                            <div className="flex flex-col gap-10">
                                {importResult.importFile && (
                                    <div className="flex items-center gap-10 import-history-element-file">
                                        <SpreadSheetFileGreenIcon />
                                        <p className="text-gray-900 truncate">{importResult.importFile?.displayName || ""}</p>
                                        <Button
                                            loading={importFileDownloadLoadings[importResult._id]}
                                            className="w-34 h-34"
                                            onClick={() => handleImportFileDownload(importResult)}
                                            icon={<DownloadIcon />}
                                        />
                                    </div>
                                )}

                                {importResult.reportFile && (
                                    <div className="flex items-center gap-10 import-history-element-file">
                                        <SpreadSheetFileGreenIcon />
                                        <p className="text-gray-900 truncate">{importResult.reportFile?.displayName}</p>
                                        <Button
                                            loading={reportFileDownloadLoadings[importResult._id]}
                                            className="w-34 h-34"
                                            onClick={() => handleReportDownload(importResult)}
                                            icon={<DownloadIcon />}
                                        />
                                    </div>
                                )}
                            </div>
                        </Col>
                    </Row>
                ) : (
                    <></>
                )
            })
        }

        return <></>
    }

    return (
        <div className="animation-appear w-full overflow-auto mb-10">
            {renderHistoryElements()}
            {!!totalDocs && (
                <>
                    <Divider />
                    <Row gutter={[16, 16]} justify="center">
                        <Pagination current={page} total={totalDocs} onChange={handlePagination} pageSize={IMPORT_RESULTS_PER_PAGE} />
                    </Row>
                </>
            )}
        </div>
    )
}
