import { Currencies } from "@finway-group/shared/lib/consts"
import { CurrencyEnum, Employee, ExpensePaymentFlowInformationEnum, currencyEnumArray } from "@finway-group/shared/lib/models"
import { Col, DatePicker, Form, Image, Input, Row, Select, Switch } from "antd"
import moment from "moment"
import React from "react"
import { useTranslation } from "react-i18next"

import { ExpenseRules } from "Components/forms/rules"
import { useExpenseFormContext } from "Components/modals/expenseCreateForm.context"
import { useEmployees } from "Shared/hooks/employee.hooks"
import { useVendors } from "Shared/hooks/vendor.hooks"
import { gobdNonCompliantDates } from "Shared/utils/date.utils"
import { doFilterSelect, doFilterTagSelect } from "Shared/utils/helper.utils"
import { getPopupAnchor } from "Shared/utils/popup.utils"

import CommonDocumentUpload from "../commonInputFields/commonDocumentUpload.form"
import ExpenseCommonInputFieldsForm from "../commonInputFields/commonInputFields.form"
import TotalAmountForm from "../commonInputFields/totalAmount.form"
import ExpenseFormLayout from "../expenseFormLayout"
import ReimbursementTypeSelector from "./reimbursementTypeSelector"

interface HospitalityRequestFormInterface {}
export const HospitalityRequestForm: React.FC<HospitalityRequestFormInterface> = ({}) => {
    const [
        {
            expense,
            updateExpense,
            expenseForm,
            isNew,
            type,
            setIsOcrProcessed,
            ocrSettings,
            activeDocumentsTab,
            setActiveDocumentsTab,
            setPrefilledVendorData,
            setIsUploading,
            isUploading,
            isSubscription,
            isLinked,
            setIsLinked,
        },
    ] = useExpenseFormContext()
    const { t } = useTranslation()
    const vendors = useVendors(true)
    const employees = useEmployees({ excludeDeleted: true })
    const expenseRules = ExpenseRules({ expense, employees })

    const { isOcrEnabled, isOcrItemizationEnabled, setIsOcrEnabled, setIsOcrItemizationEnabled } = ocrSettings

    const leftSide = (
        <CommonDocumentUpload
            expenseForm={expenseForm}
            isSubscription={isSubscription}
            expenseInvoices={!isNew && expense ? expense.invoices : []}
            expenseOffers={!isNew && expense ? expense.offers : []}
            expenseDeliveryNote={!isNew && expense ? expense.deliveryNote : undefined}
            ocrSettings={{ isOcrEnabled, isOcrItemizationEnabled, isOcrItemizationDisplayed: false, setIsOcrEnabled, setIsOcrItemizationEnabled }}
            isUploading={isUploading}
            setIsUploading={setIsUploading}
            expense={expense}
            updateExpense={updateExpense}
            vendors={vendors}
            inboxInvoices={expenseForm.getFieldValue("invoices")}
            invoicesRequired={expense.paymentFlowInformation !== ExpensePaymentFlowInformationEnum.REPORTING_ONLY}
            setIsOcrProcessed={setIsOcrProcessed}
            activeTab={activeDocumentsTab}
            onTabClick={setActiveDocumentsTab}
            setPrefilledVendorData={setPrefilledVendorData}
            isNew={isNew}
        />
    )
    const rightSide = (
        <>
            <ReimbursementTypeSelector />
            <Row gutter={[16, 16]} align="middle">
                <Col xs={12}>
                    <Form.Item name="merchant" label={t("input:request.hospitality.merchant")} required rules={expenseRules.merchant} validateTrigger="onBlur">
                        <Input placeholder={t("placeholder:request.hospitality.merchant")} onChange={(e) => updateExpense({ merchant: e.target.value })} />
                    </Form.Item>
                </Col>
                <Col xs={12}>
                    <Form.Item name="datePurchased" label={t("input:request.hospitality.date")} required rules={expenseRules.hospitalityDate} validateTrigger="onBlur">
                        <DatePicker
                            style={{ width: "100%" }}
                            format={moment.localeData().longDateFormat("L")}
                            placeholder={t("placeholder:request.date")}
                            getPopupContainer={getPopupAnchor()}
                            onChange={(e) => updateExpense({ datePurchased: e as any })} // e is moment, and expense stores in date. will be converted on submission
                            disabledDate={(current: any) => (current && current > moment().endOf("day")) || gobdNonCompliantDates(current)}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={[16, 16]} align="middle">
                <Col xs={24}>
                    <Form.Item name="attendees" label={t("input:request.hospitality.attendees")} rules={expenseRules.attendees} required>
                        <Select
                            className="w-full"
                            mode="tags"
                            placeholder={t("placeholder:request.hospitality.attendees")}
                            filterOption={doFilterTagSelect}
                            getPopupContainer={getPopupAnchor()}
                            onChange={(attendees: Array<{ value: string; label: string; key: string }>) => {
                                // using any because the attendees that we store in the form is different than the model
                                // This data will be converted once submitted on expenseForm > onFinish()
                                updateExpense({ attendees: attendees as any })
                            }}
                            allowClear
                            labelInValue
                        >
                            {employees.map(({ getFullName, id }: Employee) => (
                                <Select.Option key={id} value={id}>
                                    {getFullName()}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={[16, 16]} align="middle">
                <Col xs={15}>
                    <Form.Item name="description" label={t("input:request.hospitality.reason")} rules={expenseRules.reason} required>
                        <Input.TextArea
                            onChange={(e) => updateExpense({ description: e.target.value })}
                            className="leading-normal pt-4"
                            autoSize
                            rows={1}
                            placeholder={t("placeholder:request.hospitality.reason")}
                        />
                    </Form.Item>
                </Col>
                <Col span={9}>
                    <Form.Item label={t("input:request.billable")} name="billable" valuePropName="checked" className="content-end">
                        <Switch onChange={(checked) => updateExpense({ billable: checked })} />
                    </Form.Item>
                </Col>
            </Row>

            <TotalAmountForm
                expense={expense}
                expenseForm={expenseForm}
                updateExpense={updateExpense}
                isLinked={isLinked}
                setIsLinked={setIsLinked}
                isNew={isNew}
                type={type}
                isForcedAmountSync={true}
            />

            <Row gutter={[16, 16]} align="middle">
                <Col xs={12}>
                    <Form.Item label={t("label:performance_period")} name="performancePeriod" className="text-opacity-50">
                        <DatePicker.RangePicker
                            disabledDate={gobdNonCompliantDates}
                            name="performancePeriod"
                            style={{ width: "100%" }}
                            format={moment.localeData().longDateFormat("L")}
                            placeholder={[t("placeholder:start_date"), t("placeholder:end_date")]}
                            getPopupContainer={getPopupAnchor()}
                            onChange={(values) => updateExpense({ performancePeriod: values })}
                        />
                    </Form.Item>
                </Col>

                <Col xs={12}>
                    <Form.Item name="currency" label={t("input:request.currency")} initialValue={expense.currency}>
                        <Select
                            showSearch
                            filterOption={doFilterSelect}
                            getPopupContainer={getPopupAnchor()}
                            onSelect={(value) => {
                                updateExpense({ currency: value as CurrencyEnum })
                            }}
                        >
                            {currencyEnumArray.map((currency) => (
                                <Select.Option key={currency} value={currency} label={currency}>
                                    <div className="space-x-10">
                                        <Image className="align-middle" width={20} preview={false} src={`data:image/png;base64,${Currencies[currency].flag}`} />
                                        <span className="text-text">{currency}</span>
                                    </div>
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={[16, 16]} align="middle">
                <Col xs={12}>
                    <Form.Item
                        className="max-w-160 md:max-w-full"
                        label={t("input:request.invoice_number")}
                        name="invoiceNumber"
                        initialValue={expense.invoiceNumber}
                        rules={expenseRules.invoiceNumber}
                        required={expense.isAtLeastApproved()}
                    >
                        <Input placeholder={t("placeholder:request.invoiceNumber")} onChange={(e) => updateExpense({ invoiceNumber: e.target.value })} />
                    </Form.Item>
                </Col>
                <Col xs={12}>
                    <Form.Item
                        label={t("input:request.invoice_date")}
                        name="invoiceDate"
                        rules={expenseRules.invoiceDate}
                        required={expense.isAtLeastApproved()}
                        validateTrigger="onBlur"
                    >
                        <DatePicker
                            name="invoiceDate"
                            className="w-full"
                            disabledDate={gobdNonCompliantDates}
                            format={moment.localeData().longDateFormat("L")}
                            placeholder={t("placeholder:request.date")}
                            getPopupContainer={getPopupAnchor()}
                            onChange={(v) => updateExpense({ invoiceDate: (v as any) ?? undefined })}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <ExpenseCommonInputFieldsForm allowVendorCreation={true} />
        </>
    )

    return <ExpenseFormLayout leftSide={leftSide} rightSide={rightSide} />
}
