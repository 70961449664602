import { DEVICE_ID_COOKIE_FIELD } from "@finway-group/shared/lib/consts/common"
import { DocumentChangeInterface, OperationTypeEnum, User } from "@finway-group/shared/lib/models"
import * as Sentry from "@sentry/react"
import { Cookies } from "react-cookie"

import { isClientErrorCode } from "Shared/utils/errorHandler.utils"

import LocalStorageService from "../localstorage.service"
import SessionStorageService from "../sessionstorage.service"

export const subscribeToAuth = (data: DocumentChangeInterface, loggedInUserId: string) => {
    const {
        fullDocument: { documentId, operationType, fullData },
    } = data

    const userData = new User(fullData)

    try {
        if (operationType === OperationTypeEnum.LOGIN && documentId === loggedInUserId) {
            const cookies = new Cookies(document.cookie)
            const deviceId = cookies.get(DEVICE_ID_COOKIE_FIELD)
            const currentSession = userData.sessions.find((session) => session.deviceId === deviceId)
            if (!currentSession?.isAuthenticated) {
                SessionStorageService.setSessionInvalid()
                LocalStorageService.unsetAccessToken()
                return true
            }
        }

        if (data.fullDocument.operationType === OperationTypeEnum.LOGOUT) {
            return true
        }

        return false
    } catch (err) {
        if (isClientErrorCode(err)) return false
        Sentry.captureException(`[User Socket] Error: ${typeof err === "string" ? err : JSON.stringify(err)}`)
        return false
    }
}
