import { COLOR, Currencies } from "@finway-group/shared/lib/consts"
import { PriceIntervalEnum, Workflow, WorkflowCondition, WorkflowConditionEnum } from "@finway-group/shared/lib/models"
import { flattenExpression, isOperator, isWorkflowCondition } from "@finway-group/shared/lib/utils/logicalExpression.utils"
import { Avatar, Col, Row, Steps, Tag, Tooltip } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"

import UserImage from "Components/UserImage"
import PriceLabel from "Components/priceLabel"
import { useCostCenters2 } from "Shared/hooks/costCenter2.hooks"
import { useSubCostCenters } from "Shared/hooks/costCenter.hooks"
import { useEmployees } from "Shared/hooks/employee.hooks"
import { useVendors } from "Shared/hooks/vendor.hooks"
import { WorkflowService } from "Shared/services"
import { getTooltipPopupContainer } from "Shared/utils/helper.utils"

interface WorkflowDetailsInterface {
    workflow: Workflow
}

export const WorkflowDetails: React.FC<WorkflowDetailsInterface> = ({ workflow }) => {
    const { t } = useTranslation()

    const employees = useEmployees()
    const costCenters = useSubCostCenters(true)
    const vendors = useVendors(true)
    const costCenters2 = useCostCenters2()

    // prettier-ignore
    const getTriggerObjectAvatarGroup = (triggerObjects: Array<any>, nameKey: string, color: string, backgroundColor: string, maxAvatars = 3, isEmployeesGroup = false) => {
        const numberOfAvatars = Math.min(triggerObjects.length, maxAvatars)
        const includeMaxAvatar = triggerObjects.length > maxAvatars

        const avatarGroupTooltipText = triggerObjects.map((triggerObject) => (isEmployeesGroup ? triggerObject.getFullName() : triggerObject[nameKey])).join(", ")
        const avatars = []

        for (let i = 0; i < numberOfAvatars; i++) {
            const triggerObject = triggerObjects[i]
            avatars.push(<Avatar style={{ color, backgroundColor }} key={triggerObject.id} > {isEmployeesGroup ? triggerObject.getInitials() : triggerObject[nameKey][0]} </Avatar>)
        }

        if (includeMaxAvatar) avatars.push(<Avatar style={{ color, backgroundColor }} key={"requesters-length"}> +{triggerObjects.length - maxAvatars} </Avatar>)

        const avatarGroup = (
            <Tooltip getPopupContainer={getTooltipPopupContainer} title={() => <p>{avatarGroupTooltipText}</p>} placement="top">
                <div className="transform translate-y-4"> <Avatar.Group maxCount={numberOfAvatars + Number(includeMaxAvatar) + 1} maxStyle={{ display: "none" }}> {...avatars} </Avatar.Group> </div>
            </Tooltip>
        )
        return avatarGroup
    }

    const getRequestersAvatarGroup = (triggerCondition: WorkflowCondition) => {
        const label = { title: t("label:workflow.trigger.requester"), value: <></> }

        if (triggerCondition.condition !== WorkflowConditionEnum.REQUESTER) return label

        if (triggerCondition.isGeneralized) {
            label.value = <>{t("label:workflow.trigger.all")}</>
            return label
        }

        const requesters = employees.filter(({ id }) => triggerCondition.requesters.includes(id))
        const costCentersAvatarGroup = getTriggerObjectAvatarGroup(requesters, "", COLOR.finway["orange-dark"], COLOR.finway["orange-light"], 3, true)

        label.value = costCentersAvatarGroup
        return label
    }

    const getCostCentersAvatarGroup = (triggerCondition: WorkflowCondition) => {
        const label = { title: t("label:workflow.trigger.cost_center"), value: <></> }

        if (triggerCondition.condition !== WorkflowConditionEnum.COST_CENTER) return label

        if (triggerCondition.isGeneralized) {
            label.value = <>{t("label:workflow.trigger.all")}</>
            return label
        }

        const triggerCostCenters = costCenters.filter(({ _id }) => triggerCondition.costCenters.includes(_id))
        if (!triggerCostCenters.length) return label

        const costCentersAvatarGroup = getTriggerObjectAvatarGroup(triggerCostCenters, "name", COLOR.finway["blue-dark"], COLOR.finway["blue-accent-light"])
        label.value = costCentersAvatarGroup
        return label
    }

    const getCostCenters2AvatarGroup = (triggerCondition: WorkflowCondition) => {
        const label = { title: t("label:workflow.trigger.cost_center_2"), value: <></> }

        if (triggerCondition.condition !== WorkflowConditionEnum.COST_CENTER_2) return label

        if (triggerCondition.isGeneralized) {
            label.value = <>{t("label:workflow.trigger.all")}</>
            return label
        }

        const triggerCostCenters2 = costCenters2.filter(({ _id }) => triggerCondition.costCenters2.includes(_id))
        if (!triggerCostCenters2.length) return label

        const costCenters2AvatarGroup = getTriggerObjectAvatarGroup(triggerCostCenters2, "name", COLOR.finway["blue-dark"], COLOR.finway["blue-light2"])
        label.value = costCenters2AvatarGroup
        return label
    }

    const getVendorsAvatarGroup = (triggerCondition: WorkflowCondition) => {
        const label = { title: t("label:workflow.trigger.vendor"), value: <></> }
        if (triggerCondition.condition !== WorkflowConditionEnum.VENDOR) return label

        if (triggerCondition.isGeneralized) {
            label.value = <>{t("label:workflow.trigger.all")}</>
            return label
        }

        const triggerVendors = vendors.filter(({ id }) => triggerCondition.vendors.includes(id))
        if (!triggerVendors.length) return label

        const vendorsAvatarGroup = getTriggerObjectAvatarGroup(triggerVendors, "name", COLOR.finway.red2, COLOR.finway["red-faded"])
        label.value = vendorsAvatarGroup
        return label
    }

    const renderTriggerObjectsRow = (workflow: Workflow) => {
        if (!workflow.triggerConditionExpression) return <></>

        const triggerConditions = flattenExpression(workflow.triggerConditionExpression).filter(
            (triggerCondition) => triggerCondition.condition !== WorkflowConditionEnum.EXPENSE_KIND,
        )

        const labels: Array<{ title: string; value: JSX.Element }> = []
        for (const triggerCondition of triggerConditions) {
            let label = { title: "", value: <></> }
            switch (triggerCondition.condition) {
                case WorkflowConditionEnum.COST_CENTER:
                    label = getCostCentersAvatarGroup(triggerCondition)
                    break
                case WorkflowConditionEnum.COST_CENTER_2:
                    label = getCostCenters2AvatarGroup(triggerCondition)
                    break
                case WorkflowConditionEnum.VENDOR:
                    label = getVendorsAvatarGroup(triggerCondition)
                    break
                case WorkflowConditionEnum.REQUESTER:
                    label = getRequestersAvatarGroup(triggerCondition)
                    break
                case WorkflowConditionEnum.NET_AMOUNT:
                    label = {
                        title: t("label:workflow.trigger.net_amount"),
                        value: (
                            <>
                                <span className="mr-6 whitespace-no-wrap">&gt;</span>
                                <span className="whitespace-no-wrap">
                                    <PriceLabel value={triggerCondition.amount} currency={triggerCondition.currency} interval={PriceIntervalEnum.ONE_TIME} />
                                </span>
                            </>
                        ),
                    }
            }

            labels.push(label)
        }

        const LabelItem = ({ title, value }: typeof labels[0]) => (
            <div className="flex items-center gap-10">
                <p>{title}: </p>
                {value}
            </div>
        )

        return (
            <Row gutter={[16, 16]}>
                <Col className="flex items-center gap-10">
                    <p className="font-bold whitespace-no-wrap">{t("label:workflow.trigger.trigger_objects")}: </p>
                    {labels.map((label, index) => (
                        <React.Fragment key={index}>
                            {index !== 0 && isOperator(workflow.triggerConditionExpression) && (
                                <Tag className="bg-gray-150 h-20">{t(`label:workflow.operator.${workflow.triggerConditionExpression.operator.toLowerCase()}`)}</Tag>
                            )}
                            <LabelItem title={label.title} value={label.value} />
                        </React.Fragment>
                    ))}
                </Col>
            </Row>
        )
    }

    return (
        <div className="workflows ml-10 mt-10">
            {renderTriggerObjectsRow(workflow)}

            <Steps className="mt-10" direction="vertical" size="small" current={-1}>
                {workflow.steps.map((step, index) => {
                    const approvals = flattenExpression(step.approvalExpression)

                    const approver = WorkflowService.getApproverLabelData(approvals[0])
                    let secondApprover = WorkflowService.getApproverLabelData(approvals[0])
                    let thirdApprover = WorkflowService.getApproverLabelData(approvals[0])
                    let operator
                    let netAmountCondition

                    if (isOperator(step.approvalExpression)) {
                        operator = step.approvalExpression.operator
                        secondApprover = WorkflowService.getApproverLabelData(approvals?.[1])
                        thirdApprover = WorkflowService.getApproverLabelData(approvals?.[2])
                    }

                    if (isWorkflowCondition(step.conditionExpression)) {
                        if (step.conditionExpression.condition === WorkflowConditionEnum.NET_AMOUNT) netAmountCondition = step.conditionExpression
                    }

                    return (
                        <Steps.Step
                            className="flex items-center"
                            key={index}
                            status="process"
                            title={
                                <Row className="-mt-4">
                                    <Col className="flex items-center gap-10">
                                        {netAmountCondition && netAmountCondition.amount && (
                                            <>
                                                <Tag className="bg-gray-150 h-20">{`>${Currencies[netAmountCondition.currency].symbol}`}</Tag>
                                                {netAmountCondition.amount}
                                                <Tag className="bg-gray-150 h-20">{t(`label:workflow.condition.then`)}</Tag>
                                            </>
                                        )}
                                        {approver && (
                                            <div className="flex items-center">
                                                <UserImage user={approver.user} />
                                                <span>{approver.label}</span>
                                            </div>
                                        )}
                                        {secondApprover && operator && (
                                            <>
                                                <Tag className="bg-gray-150 h-20">{t(`label:workflow.operator.${operator.toLowerCase()}`)}</Tag>
                                                <div className="flex items-center">
                                                    <UserImage user={secondApprover.user} />
                                                    <span>{secondApprover.label}</span>
                                                </div>
                                            </>
                                        )}
                                        {thirdApprover && operator && (
                                            <>
                                                <Tag className="bg-gray-150 h-20">{t(`label:workflow.operator.${operator.toLowerCase()}`)}</Tag>
                                                <div className="flex items-center">
                                                    <UserImage user={thirdApprover.user} />
                                                    <span>{thirdApprover.label}</span>
                                                </div>
                                            </>
                                        )}
                                    </Col>
                                </Row>
                            }
                        />
                    )
                })}
            </Steps>
        </div>
    )
}
