import { RightEnum } from "@finway-group/shared/lib/models"
import { Tabs } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"

import { useExpenseDetailsContext } from "Shared/context/expenseDetails.context"
import { AuthzService } from "Shared/services"
import { isFolderExpense } from "Shared/utils/expense.utils"

import DatevCompatibilityBanner from "./datevCompatibilityBanner"
import ExpenseMainDetailsSectionContainer from "./expenseMainDetailSectionContainer"
import ExpenseTransactionList from "./expenseTransaction.list"

interface MainDetailsTabsInterface {
    setIsExpenseUpdating: React.Dispatch<React.SetStateAction<boolean>>
    setIsEditModalShowing: React.Dispatch<React.SetStateAction<boolean>>
    setActiveExpenseId: (expenseId: string) => void
}
const MainDetailsTabs: React.FC<MainDetailsTabsInterface> = ({ setIsExpenseUpdating, setIsEditModalShowing, setActiveExpenseId }) => {
    const { t } = useTranslation()
    const showTransactionTab = AuthzService.isRightGrantedForLoggedInUser(RightEnum.TRANSACTION__ALL__UPDATE)

    const { expense, subExpenses, activeExpense } = useExpenseDetailsContext()

    return (
        <Tabs className="w-full min-h-200 ml-1" renderTabBar={isFolderExpense(expense) ? () => <></> : undefined}>
            <Tabs.TabPane key="requestDetails" tab={t("label:request_details")} className="pl-0">
                {!isFolderExpense(activeExpense) && <DatevCompatibilityBanner expense={activeExpense} />}
                <>
                    <ExpenseMainDetailsSectionContainer
                        expense={expense}
                        subExpenses={subExpenses}
                        setIsEditModalShowing={setIsEditModalShowing}
                        setIsExpenseUpdating={setIsExpenseUpdating}
                        setActiveExpenseId={setActiveExpenseId}
                    />
                </>
            </Tabs.TabPane>
            {showTransactionTab && (
                <Tabs.TabPane key="transactions" tab={t("label:transactions")}>
                    <ExpenseTransactionList expense={expense} />
                </Tabs.TabPane>
            )}
        </Tabs>
    )
}

export default React.memo(MainDetailsTabs)
