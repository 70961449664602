import subscribeToBankConnection from "./bankConnectionSocket.service"
import subscribeToCompanySettings from "./companySettingsSocket.service"
import subscribeToCorporate from "./corporateSocket.service"
import subscribeToCostCenter2 from "./costCenter2Socket.service"
import subscribeToCostCenter from "./costCenterSocket.service"
import { subscribeToCreditCards } from "./creditCardSocket.service"
import subscribeToCsvTemplate from "./csvTemplatesSocket.service"
import subscribeToCurrencyExchange from "./currencyExchangeSocket.service"
import subscribeToEmployee from "./employeeSocket.service"
import subscribeToExpenseAccount from "./expenseAccountSocket.service"
import subscribeToExpenses from "./expenseSocket.service"
import subscribeToExpenseTag from "./expenseTagSocket.service"
import subscribeToFilter from "./filterSocket.service"
import { subscribeToInboxInvoices } from "./inboxInvoiceSocket.service"
import subscribeToPaymentSettings from "./paymentSettingsSocket.service"
import subscribeToRole from "./roleSocket.service"
import subscribeToTax from "./taxSocket.service"
import subscribeToTeam from "./teamSocket.service"
import subscribeToTransactions from "./transactionSocket.service"
import subscribeToVendor from "./vendorSocket.service"
import subscribeToWallet from "./walletSocket.service"

export {
    subscribeToEmployee,
    subscribeToExpenses,
    subscribeToExpenseAccount,
    subscribeToVendor,
    subscribeToCostCenter,
    subscribeToCompanySettings,
    subscribeToBankConnection,
    subscribeToPaymentSettings,
    subscribeToCurrencyExchange,
    subscribeToTransactions,
    subscribeToCreditCards,
    subscribeToInboxInvoices,
    subscribeToWallet,
    subscribeToCostCenter2,
    subscribeToTeam,
    subscribeToTax,
    subscribeToCorporate,
    subscribeToRole,
    subscribeToExpenseTag,
    subscribeToCsvTemplate,
    subscribeToFilter,
}
