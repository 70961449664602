import axios from "axios"

const MODEL = "wallet"

const WalletService = {
    fetchWalletDetails: async () => {
        const { data } = await axios.get(`/${MODEL}`)
        return data
    },
    fetchWalletFundingHistory: async () => {
        const { data } = await axios.get(`/${MODEL}/funding`)
        return data
    },
    fetchAccountStatements: async () => {
        const { data } = await axios.get(`/${MODEL}/statements`)
        return data
    },
    fetchAccountStatementById: async (id: string) => {
        const { data } = await axios.get(`/${MODEL}/statements/${id}`)
        return data
    },
    regenerateAccountStatement: async (id: string) => {
        const { data } = await axios.put(`/${MODEL}/statements/${id}`)
        return data
    },
    generateOutstandingAccountStatements: async () => {
        const { data } = await axios.post(`/${MODEL}/statements/outstanding`)
        return data
    },
    regenerateAllAccountStatements: async () => {
        const { data } = await axios.post(`/${MODEL}/statements`)
        return data
    },
    executeOutgoingWireTransfer: async (transferData: any) => {
        const { data } = await axios.post(`/${MODEL}/wire/`, transferData)
        return data
    },
    verifyOutgoingWireTransfer: async (wireTransferId: string, verificationCode: string) => {
        const { data } = await axios.post(`/${MODEL}/wire/verify`, { wireTransferId, verificationCode })
        return data
    },
    checkUserAuthenticationFactor: async () => {
        const { data } = await axios.get(`/${MODEL}/authfactor/check`)
        return data
    },
    enrollUserAuthenticationFactor: async () => {
        const { data } = await axios.post(`/${MODEL}/authfactor/enroll`)
        return data
    },
    verifyUserAuthenticationFactor: async (verificationCode: string) => {
        const { data } = await axios.post(`/${MODEL}/authfactor/verify`, { verificationCode })
        return data
    },
    createIban: async (accountId: string) => {
        const { data } = await axios.post(`/${MODEL}/iban`, { accountId })
        return data
    },
}

export default WalletService
