import { Store } from "antd/lib/form/interface"
import React from "react"
import { useTranslation } from "react-i18next"
import { Link, useHistory, withRouter } from "react-router-dom"

import ForgotPasswordForm from "Components/forms/forgotPassword.form"
import { AuthnService } from "Shared/services"
import NotificationService, { NotificationTypeEnum } from "Shared/services/notification.service"

import AuthLayout from "./auth.layout"

const ForgotPasswordPage = () => {
    const history = useHistory()
    const { t } = useTranslation()

    const handleResetPassword = async ({ email }: Store) => {
        try {
            await AuthnService.forgotPassword(email)
            NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:password_forgot.title"), t("notification:password_forgot.message"))

            history.push("/login")
        } catch (err) {
            NotificationService.showErrorNotificationBasedOnResponseError(err, "Error")
        }
    }
    return (
        <AuthLayout>
            <ForgotPasswordForm onSubmit={handleResetPassword} />
            <div className="flex w-full flex-col items-center justify-center">
                <Link data-testid="backToLogin" className="finway-forgot-password finway-forgot-password-link" to="/login">
                    {t("nav:back_to_login")}
                </Link>
            </div>
        </AuthLayout>
    )
}

export default withRouter(ForgotPasswordPage)
