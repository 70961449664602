import { CostCenter, CostCenter2, CreditorInterface, ExpenseAccount, RightEnum, SubscriptionFilter } from "@finway-group/shared/lib/models"
import { BillingPeriodEnum } from "@finway-group/shared/lib/models/expense/billingPeriod.enum"
import { Form } from "antd"
import moment from "moment"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"

import { FilterFormInterface } from "Components/filter/filterTypes"
import { FormSelectOption } from "Components/form/form.types"
import FormMinMaxCurrency from "Components/form/formMinMaxCurrency"
import FormMinMaxDate from "Components/form/formMinMaxDate"
import FormSelect from "Components/form/formSelect"
import { useCompany } from "Shared/hooks/company.hooks"
import { getTableFilterObjectStore } from "Shared/hooks/table.hooks"
import { useLoggedInEmployeeProfile } from "Shared/hooks/user.hooks"
import { AuthzService } from "Shared/services"
import {
    createDynamicListWithLabel,
    createYesOrNoOption,
    getDynamicCostCenter2List,
    getDynamicCostCenterList,
    getDynamicCreditorList,
    getDynamicExpenseAccountList,
} from "Shared/utils/filter.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

import { generateRules } from "./rules/subscriptionFilter.rules"

const adjustFilterObject = (filterObject: SubscriptionFilter): SubscriptionFilter => ({
    ...filterObject,
    minDateCancellationDue: filterObject.minDateCancellationDue ? moment(filterObject.minDateCancellationDue).startOf("day").toDate() : undefined,
    maxDateCancellationDue: filterObject.maxDateCancellationDue ? moment(filterObject.maxDateCancellationDue).endOf("day").toDate() : undefined,
})

const SubscriptionFilterForm: React.FC<FilterFormInterface> = ({ table, formInstance, options, onSubmit }) => {
    const { t } = useTranslation()

    const rules = generateRules(formInstance)
    const loggedInUser = useLoggedInEmployeeProfile()
    const tableFilterObject = getTableFilterObjectStore<SubscriptionFilter>(table)
    const [subscriptionFilter, setSubscriptionFilter] = useStateIfMounted(adjustFilterObject(tableFilterObject))
    const { showCostCentersIds } = useCompany()

    useEffect(() => {
        formInstance.resetFields()
        setSubscriptionFilter(adjustFilterObject(tableFilterObject))
    }, [])

    const yesOrNoOptions = createYesOrNoOption()
    const billingPeriodList: Array<FormSelectOption> = createDynamicListWithLabel("input", [
        { param: "request.monthly", value: BillingPeriodEnum.MONTHLY },
        { param: "request.quarterly", value: BillingPeriodEnum.QUARTERLY },
        { param: "request.yearly", value: BillingPeriodEnum.YEARLY },
    ])

    return (
        <Form id="subscriptionFilterForm" className="form--filter" form={formInstance} onFinish={onSubmit}>
            <FormSelect
                name="vendor"
                header={t("input:filter_form.vendor")}
                initialValue={subscriptionFilter.vendor}
                options={getDynamicCreditorList(options.creditors as Array<CreditorInterface>)}
                isCollapse
                showSearch
                fallback
            />

            <FormSelect
                name="costCenter"
                header={t("input:filter_form.cost_center")}
                initialValue={subscriptionFilter.costCenter}
                options={getDynamicCostCenterList(options.costCenters as Array<CostCenter>, showCostCentersIds)}
                isCollapse
                showSearch
                fallback
            />

            <FormSelect
                name="costCenter2"
                header={t("input:filter_form.cost_center_2")}
                initialValue={subscriptionFilter.costCenter2}
                options={getDynamicCostCenter2List(options.costCenters2 as Array<CostCenter2>)}
                isCollapse
                showSearch
                fallback
            />

            <FormSelect
                name="expenseAccount"
                header={t("input:filter_form.expense_account")}
                initialValue={subscriptionFilter.expenseAccount}
                options={getDynamicExpenseAccountList(options.expenseAccounts as Array<ExpenseAccount>)}
                isCollapse
                showSearch
                fallback
            />

            {(AuthzService.isRightGrantedForLoggedInUser(RightEnum.EXPENSE__ALL__READ) || AuthzService.isRightGrantedForLoggedInUser(RightEnum.EXPENSE__TEAM__READ)) && (
                <FormSelect
                    name="requestedBy"
                    header={t("input:expense_filter.requester")}
                    initialValue={subscriptionFilter.requestedBy}
                    options={options.employees ?? []}
                    displayImages
                    isCollapse
                    showSearch
                    fallback
                />
            )}

            <FormSelect
                name="cardAttached"
                header={t("input:filter_form.card_attached")}
                initialValue={subscriptionFilter.cardAttached}
                options={yesOrNoOptions}
                isCollapse
                showSearch
                fallback
            />

            <FormMinMaxCurrency
                header={t("input:filter_form.amount")}
                min={{
                    name: "minAmount",
                    label: t("input:filter_form.min_amount"),
                    placeholder: t("placeholder:filter_form.min_amount"),
                    onChange: () => formInstance.validateFields(["minAmount"]),
                    initialValue: subscriptionFilter.minAmount,
                    rules: rules.minAmount,
                }}
                max={{
                    name: "maxAmount",
                    label: t("input:filter_form.max_amount"),
                    placeholder: t("placeholder:filter_form.max_amount"),
                    onChange: () => formInstance.validateFields(["maxAmount"]),
                    initialValue: subscriptionFilter.maxAmount,
                    rules: rules.maxAmount,
                }}
                currency={subscriptionFilter.currency}
            />

            <FormSelect
                name="billingPeriod"
                header={t("input:filter_form.billing_period")}
                initialValue={(subscriptionFilter.billingPeriod ?? "").toString()}
                options={billingPeriodList}
                isCollapse
                showSearch
                fallback
            />

            <FormMinMaxDate
                header={t("label:renewal_date")}
                min={{
                    name: "minDateCancellationDue",
                    label: t("input:filter_form.from"),
                    placeholder: t("placeholder:filter_form.min_date"),
                    initialValue: subscriptionFilter.minDateCancellationDue,
                    rules: rules.minDateCancellationDue,
                    onChange: () => formInstance.validateFields(["minDateCancellationDue", "maxDateCancellationDue"]),
                }}
                max={{
                    name: "maxDateCancellationDue",
                    label: t("input:filter_form.from"),
                    placeholder: t("placeholder:filter_form.max_date"),
                    initialValue: subscriptionFilter.maxDateCancellationDue,
                    rules: rules.maxDateCancellationDue,
                    onChange: () => formInstance.validateFields(["minDateCancellationDue", "maxDateCancellationDue"]),
                }}
            />
        </Form>
    )
}

export default SubscriptionFilterForm
