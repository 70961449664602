import { textMultipleWhiteSpace } from "@finway-group/shared/lib/utils/validators"
import { FormInstance, Rule } from "antd/lib/form"
import i18n from "i18next"

import { countInArray } from "Shared/utils/helper.utils"

import { allowlistCharValidator } from "./utils.rules"

const TeamRules = (formInstance: FormInstance): { [key: string]: Array<Rule> } => ({
    teamName: [
        { required: true, message: i18n.t("validation:required") },
        {
            validator: (_rule: any, value: string) => {
                // Directly return when empty, because it has been handled by { required: true }
                if (!value) return Promise.resolve()
                const teamNames = formInstance.getFieldsValue().teams.map((e: any) => e.teamName)
                if (value && countInArray(teamNames, value, false) >= 2) {
                    return Promise.reject(i18n.t("validation:duplicate", { value: i18n.t("input:team.team_name") }))
                }

                if (value !== value.trim()) {
                    return Promise.reject(new Error(i18n.t("validation:string_white_space_start_or_end")))
                }

                if (textMultipleWhiteSpace.test(value)) {
                    return Promise.reject(new Error(i18n.t("validation:string_white_space_duplicate")))
                }

                return Promise.resolve()
            },
        },
        allowlistCharValidator,
    ],
})

export default TeamRules
