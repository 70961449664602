import { Expense, RightEnum } from "@finway-group/shared/lib/models"
import { Table } from "antd"
import { ColumnsType } from "antd/lib/table"
import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import EmptyTableData from "Components/emptyTableData"
import ExpenseAmountColumn from "Components/expenseAmountColumn"
import { ErrorTable } from "Components/layout/errorTable"
import RequestColumn from "Components/requestColumn"
import TableButtons from "Components/tableButtons"
import { useCostCenters } from "Shared/hooks/costCenter.hooks"
import { useEmployees, useLoggedInEmployee } from "Shared/hooks/employee.hooks"
import { useExpenseTags } from "Shared/hooks/expenseTags.hooks"
import { useFetchTable, useTable } from "Shared/hooks/table.hooks"
import { useCreditors } from "Shared/hooks/vendor.hooks"
import { AuthzService } from "Shared/services"
import { refetchTable, updateTableFilter, updateTableOnChange } from "Shared/store/actions/tables/tableActions"
import { TablesEnum } from "Shared/store/reducers/tableConfigReducer"
import { getSortOrderForColumn } from "Shared/utils/table.utils"

import FilterModal from "../filter.modal"

interface AttachExpenseTableInterface {
    selectedExpenses: Array<Expense>
    setSelectedExpenses: (selectedExpenses: Array<Expense>) => void
    disableSelection?: boolean
}
export const AttachExpenseTable = ({ selectedExpenses, setSelectedExpenses, disableSelection }: AttachExpenseTableInterface) => {
    const expensesTable = useTable<Expense>(TablesEnum.ATTACH_EXPENSE)
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const loggedInUser = useLoggedInEmployee()
    const creditors = useCreditors(AuthzService.isRightGrantedForLoggedInUser(RightEnum.EMPLOYEE__ALL__READ), true, true)
    const costCenters = useCostCenters()
    const employees = useEmployees()
    const expenseTags = useExpenseTags()

    // Check if there's expenses available. If totalDocs is 0 and search / filter is filled in, that means the totalDocs is 0 because a filter is applied, so data is still available
    const dataAvailable = expensesTable.data.totalDocs > 0 || expensesTable.data.search !== "" || Object.keys(expensesTable.data.filter).length > 0

    useFetchTable(TablesEnum.ATTACH_EXPENSE)

    const expenseColumns: ColumnsType<Expense> = [
        {
            title: t("label:request"),
            key: "expenseNumber",
            align: "left",
            className: "pl-10",
            ellipsis: true,
            width: 300,
            ...(loggedInUser.settings.showExpenseNumbers && {
                sorter: true,
                sortOrder: getSortOrderForColumn(expensesTable, "expenseNumber"),
            }),
            render: (data: Expense) => <RequestColumn request={data} showStatus />,
        },
        {
            title: loggedInUser.settings.showGrossAmount ? t("label:gross_amount") : t("label:net_amount"),
            key: loggedInUser.settings.showGrossAmount ? "totalGrossPrice" : "totalNetPrice",
            align: "right",
            width: 120,
            className: "pr-10",
            sorter: true,
            sortOrder: getSortOrderForColumn(expensesTable, loggedInUser.settings.showGrossAmount ? "totalGrossPrice" : "totalNetPrice"),
            render: (data: Expense) => <ExpenseAmountColumn request={data} showGross={loggedInUser.settings.showGrossAmount} />,
        },
    ]

    return (
        <>
            {dataAvailable && (
                <TableButtons
                    table={TablesEnum.ATTACH_EXPENSE}
                    injectFilterModal={(isVisible: boolean, onCancel: (flag: boolean) => void) => (
                        <FilterModal
                            table={TablesEnum.ATTACH_EXPENSE}
                            options={{ creditors, costCenters, employees, expenseTags }}
                            isVisible={isVisible}
                            onCancel={onCancel}
                            onSubmit={async (filterData: any) => {
                                dispatch(updateTableFilter(TablesEnum.ATTACH_EXPENSE, filterData))
                            }}
                        />
                    )}
                />
            )}
            {expensesTable.error ? (
                <ErrorTable onTableReload={() => dispatch(refetchTable(TablesEnum.ATTACH_EXPENSE))} isLoading={expensesTable.isFetching} />
            ) : expensesTable.data.totalDocs === 0 ? (
                <EmptyTableData searchString={expensesTable.data.search} title={t("info:empty_state.all.title")} message={t("info:empty_state.all.message")} />
            ) : (
                <div className="overflow-auto max-h-550 mb-24 mt-20 p-2">
                    <Table
                        columns={expenseColumns}
                        dataSource={expensesTable.data.docs}
                        onChange={updateTableOnChange(TablesEnum.ATTACH_EXPENSE, expensesTable.data)}
                        rowKey={(record) => record.id}
                        rowSelection={{
                            type: "checkbox",
                            renderCell: (_value, _record, _index, originNode) => (
                                <div onClick={(e) => e.stopPropagation()} className="bigger-hitbox-checkbox">
                                    {originNode}
                                </div>
                            ),
                            getCheckboxProps: (record: any) => ({
                                disabled: selectedExpenses.find((item: any) => item._id === record._id) ? false : disableSelection ?? false,
                            }),
                            selectedRowKeys: selectedExpenses.map((record) => record.id),
                            onChange: (_selectedRowKeys, selectedRows) => setSelectedExpenses(selectedRows),
                            onSelect: (record, _selected, _selectedRow) => setSelectedExpenses([...selectedExpenses, record]),
                        }}
                        onRow={(record, _rowIndex) => ({
                            onClick: (_event) => {
                                if (selectedExpenses.find((item: any) => item._id === record._id) ? false : disableSelection ?? false) {
                                    return
                                }

                                const clickedExpenseIndex = selectedExpenses.findIndex((expense) => expense.id === record.id)

                                if (clickedExpenseIndex < 0) {
                                    setSelectedExpenses([...selectedExpenses, record])
                                    return
                                }

                                const newSelectedExpenses = [...selectedExpenses]
                                newSelectedExpenses.splice(clickedExpenseIndex, 1)
                                setSelectedExpenses(newSelectedExpenses)
                            },
                        })}
                        pagination={{
                            position: ["bottomRight"],
                            showSizeChanger: false,
                            current: expensesTable.data.page,
                            pageSize: expensesTable.data.limit,
                            hideOnSinglePage: true,
                            total: expensesTable.data.totalDocs,
                            pageSizeOptions: ["10", "20", "50", "100"],
                        }}
                    ></Table>
                </div>
            )}
        </>
    )
}
