import { Role } from "@finway-group/shared/lib/models"
import { Action } from "redux"

export enum RoleTypes {
    FETCH_ALL_ROLES = "FETCH_ALL_ROLES",
    UPDATE_ROLE = "UPDATE_ROLE",
    SELECT_ROLE = "SELECT_ROLE",
    CREATE_ROLE = "CREATE_ROLE",
    DELETE_ROLE = "DELETE_ROLE",
}

export interface FetchAllRolesAction extends Action<typeof RoleTypes.FETCH_ALL_ROLES> {
    type: typeof RoleTypes.FETCH_ALL_ROLES
    roles: Array<Role>
}

export interface UpdateRoleAction extends Action<typeof RoleTypes.UPDATE_ROLE> {
    type: typeof RoleTypes.UPDATE_ROLE
    role: Role
}

export interface SelectRoleAction extends Action<typeof RoleTypes.SELECT_ROLE> {
    type: typeof RoleTypes.SELECT_ROLE
    role: Role
}

export interface CreateRoleAction extends Action<typeof RoleTypes.CREATE_ROLE> {
    type: typeof RoleTypes.CREATE_ROLE
    role: Role
}
export interface DeleteRoleAction extends Action<typeof RoleTypes.DELETE_ROLE> {
    type: typeof RoleTypes.DELETE_ROLE
    roleId: string
}

export type RolesActions = FetchAllRolesAction | UpdateRoleAction | SelectRoleAction | CreateRoleAction | DeleteRoleAction
