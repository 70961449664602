import { BankConnection, BankConnectionUpdateStatusEnum } from "@finway-group/shared/lib/models"
import { Alert, Button } from "antd"
import { UNAVAILABLE_FOR_LEGAL_REASONS } from "http-status"
import React from "react"
import { useTranslation } from "react-i18next"

import { useBankConnections } from "Shared/hooks/bank.hooks"
import { useCompany } from "Shared/hooks/company.hooks"
import { BankService, NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"

interface BankConnectionAlertInterface {
    bankConnectionOverride?: BankConnection
    showConsentAlert?: boolean
}

const BankConnectionAlert: React.FC<BankConnectionAlertInterface> = ({ bankConnectionOverride, showConsentAlert = false }) => {
    const { t } = useTranslation()
    const company = useCompany()

    const bankConnections = useBankConnections({ shouldFilterDeleted: true })
    const usedBankConnection = bankConnectionOverride ? (bankConnectionOverride.deleted ? [] : [bankConnectionOverride]) : bankConnections

    // Prioritize showing downloading bank alert
    // Check if any is still downloading.
    let problematicBankConnection = usedBankConnection.find((b) => b.status === BankConnectionUpdateStatusEnum.DOWNLOADING)

    // if no bank is downloading and banking payment auto match is enabled, look for banks that needs consent
    if (!problematicBankConnection && (company.bankingPaymentAutoMatchEnabled || showConsentAlert)) {
        problematicBankConnection = usedBankConnection.find((b) => b.consentActionRequired)
    }

    if (problematicBankConnection) {
        const onResolveClick = () => {
            if (problematicBankConnection) {
                BankService.performManualBankConnectionUpdate(problematicBankConnection.connectionId, undefined, true).catch((err) => {
                    if (err.response?.status === UNAVAILABLE_FOR_LEGAL_REASONS) {
                        NotificationService.send(NotificationTypeEnum.INFO, t("notification:banking.update_started.title"), t("notification:banking.update_started.message"))
                    } else {
                        NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:banking.delete.title"))
                    }
                })
            }
        }

        // Prioritize showing downloading alert
        let message = <></>
        if (problematicBankConnection.status === BankConnectionUpdateStatusEnum.DOWNLOADING) {
            message = (
                <>
                    {t(`info:banking.${bankConnectionOverride ? `this_` : ``}currently_downloading`, {
                        bank_name: problematicBankConnection.bankName,
                        bank_code: problematicBankConnection.bankCode,
                    })}
                </>
            )
        } else {
            message = (
                <>
                    {`${
                        t(`info:banking.${bankConnectionOverride ? `this_` : ``}consent_required`, {
                            bank_name: problematicBankConnection.bankName,
                            bank_code: problematicBankConnection.bankCode,
                        }) + (!showConsentAlert ? t(`info:banking.automatic_payment_disabled`) : t(`info:banking.outdated_data_displayed`))
                    } -`}
                    <Button className="button-warning-notification ml-4" onClick={onResolveClick}>
                        {t("action:banking.resolve_consent")}
                    </Button>
                </>
            )
        }
        return <Alert message={message} type="warning" showIcon className="mb-20" />
    }

    return <></>
}

export default BankConnectionAlert
