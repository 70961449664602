import axios from "axios"

const UtilityService = {
    getApiVersion: async () => {
        const {
            data: { apiVersion },
        } = await axios.get("version")
        return apiVersion
    },
    getMapAutocomplete: async (text: string) => {
        const { data } = await axios.post("/maps/autocomplete", { input: text })
        return data
    },
    wipeAllData: async () => {
        const { data } = await axios.post("/wipe-data")
        return data
    },
    resetDemoData: async () => {
        const { data } = await axios.post("/reset-demo-data")
        return data
    },
}

export default UtilityService
