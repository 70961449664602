import { LoadingOutlined } from "@ant-design/icons"
import { ExpenseTag } from "@finway-group/shared/lib/models"
import { Popover } from "antd"
import React from "react"
import { X as RemoveIcon } from "react-feather"

import Icon from "Components/icon"
import { EXPENSE_TAG_ICON_COLORS } from "Shared/config/consts"
import { useExpenseTagsInLoadingState } from "Shared/hooks/expenseTags.hooks"

interface ExpenseTagViewerInterface {
    expenseTags: Array<ExpenseTag>
    onDelete?: (expenseTagId: string) => Promise<void>
}

const ExpenseTagViewer: React.FunctionComponent<ExpenseTagViewerInterface> = ({ expenseTags, onDelete, children }) => {
    const { expenseTagsLoading, addTagInLoading, removeTagFromLoading } = useExpenseTagsInLoadingState()

    const onTagDetach = async (expenseTagId: string) => {
        try {
            addTagInLoading(expenseTagId)
            if (onDelete) {
                await onDelete(expenseTagId)
            }
        } catch (err) {
            throw err
        } finally {
            removeTagFromLoading(expenseTagId)
        }
    }

    const innerContent = (
        <div className="flex flex-col align-items justify-start">
            {expenseTags.map((expenseTag: ExpenseTag) => (
                <div key={expenseTag._id} className="flex items-center justify-between hover:bg-body group h-34 custom-group">
                    <div className="flex items-center">
                        <Icon className="mr-5" color={EXPENSE_TAG_ICON_COLORS[expenseTag.tagColor]} icon="expense_tag" />
                        <span className="cursor-default" style={{ color: EXPENSE_TAG_ICON_COLORS[expenseTag.tagColor] }}>
                            {expenseTag.tagName}{" "}
                        </span>
                    </div>
                    {onDelete ? (
                        expenseTagsLoading.includes(expenseTag._id) ? (
                            <LoadingOutlined />
                        ) : (
                            <RemoveIcon onClick={() => onTagDetach(expenseTag._id)} color="#72808E" className="ml-6 cursor-pointer hidden-child" size="15" />
                        )
                    ) : null}
                </div>
            ))}
        </div>
    )

    return (
        <Popover z-index={20} overlayStyle={{ minWidth: "150px" }} placement="bottom" color="#fff" trigger="hover" content={innerContent}>
            {children}
        </Popover>
    )
}

export default ExpenseTagViewer
