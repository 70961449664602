import ThunderboltOutlined from "@ant-design/icons/lib/icons/ThunderboltOutlined"
import { FilterPresetEnum } from "@finway-group/shared/lib/models/filter/filterPreset.enum"
import { Badge, Button, Checkbox, Popover } from "antd"
import { deepEqual } from "fast-equals"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDebouncedCallback } from "use-debounce/lib"

import { useIsTravelEnabled } from "Shared/hooks/featureFlags.hooks"
import { setQuickFilter } from "Shared/hooks/filter.hooks"
import { useTableFilterPresets } from "Shared/hooks/table.hooks"
import { useLoggedInEmployeeProfile } from "Shared/hooks/user.hooks"
import { AuthzService } from "Shared/services"
import { TablesEnum } from "Shared/store/reducers/tableConfigReducer"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

const QuickFilter = ({ table }: { table: TablesEnum }) => {
    const { t } = useTranslation()
    const loggedInEmployee = useLoggedInEmployeeProfile()
    const isTravelEnabled = useIsTravelEnabled()

    const filterPresets = useTableFilterPresets(table)
    const delayedPresetsUpdate = useDebouncedCallback((newPresets) => {
        setQuickFilter(table, newPresets)
    }, 500)

    const [isModalShowing, setIsModalShowing] = useState(false)
    const [checkboxState, setCheckboxState] = useStateIfMounted<Array<FilterPresetEnum>>(filterPresets)

    useEffect(() => {
        const isDuplicate = deepEqual(filterPresets, checkboxState)
        if (!isDuplicate) setCheckboxState(filterPresets)
    }, [filterPresets])

    /**
     * Builds the array of presets, then perform optimistic update on the checkboxes.
     * It will not dispatch the setQuickFilter until 500ms of no activity with the checkboxes, to cut down the amount of requests
     */
    const updatePreset = (field: FilterPresetEnum, isActive: boolean) => {
        const newPresets = isActive ? [...new Set([...checkboxState, field])] : checkboxState.filter((preset) => preset !== field)
        setCheckboxState(newPresets)
        delayedPresetsUpdate.callback(newPresets)
    }

    /**
     * Filter presets to be shown to admins only
     * @param value
     * @returns
     */
    const filterAdminPresets = (value: string) => {
        if (value === FilterPresetEnum.DRAFT_TRIP_FOLDER && (!AuthzService.isLoggedInUserGlobalApprover() || !isTravelEnabled)) {
            return false
        }

        return true
    }

    return (
        <div>
            <Popover
                title={t("label:quick_filter")}
                overlayClassName="rounded z-20"
                placement="bottom"
                trigger="click"
                visible={isModalShowing}
                onVisibleChange={(show: boolean) => setIsModalShowing(show)}
                content={
                    <div className="flex flex-col gap-1">
                        {Object.values(FilterPresetEnum)
                            .filter(filterAdminPresets)
                            .map((preset) => (
                                <Checkbox key={preset} className="ml-0" checked={checkboxState.includes(preset)} onChange={(e) => updatePreset(preset, e.target.checked)}>
                                    {t(`label:filter_presets.${preset}`)}
                                </Checkbox>
                            ))}
                    </div>
                }
            >
                <Badge className="pl-0 pr-0 -mt-2 cursor-default" count={checkboxState.length} showZero={false} offset={[-4, 5]}>
                    <Button>
                        <ThunderboltOutlined />
                    </Button>
                </Badge>
            </Popover>
        </div>
    )
}

export default QuickFilter
