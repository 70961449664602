import { LiquidityCashFlow, LiquidityForecastRule, LiquidityScenario } from "@finway-group/shared/lib/models"
import moment from "moment"
import { Action } from "redux"

import { LMTabTypeEnum } from "Features/pages/liquidity/liquidity.types"
import { BankBalanceInfo } from "Features/pages/liquidity/types"

export enum LiquidityTypes {
    FETCH_ALL_SCENARIOS = "FETCH_ALL_SCENARIOS",
    CREATE_ONE_SCENARIO = "CREATE_ONE_SCENARIO",
    UPDATE_ONE_SCENARIO = "UPDATE_ONE_SCENARIO",
    DELETE_ONE_SCENARIO = "DELETE_ONE_SCENARIO",
    SELECT_SCENARIO = "SELECT_SCENARIO",
    UPDATE_INTERVAL = "UPDATE_INTERVAL",
    FETCH_ALL_FORECAST_RULES = "FETCH_ALL_FORECAST_RULES",
    CREATE_ONE_FORECAST_RULE = "CREATE_ONE_FORECAST_RULE",
    UPDATE_ONE_FORECAST_RULE = "UPDATE_ONE_FORECAST_RULE",
    DELETE_ONE_FORECAST_RULE = "DELETE_ONE_FORECAST_RULE",
    SET_LM_ACTIVE_TAB = "SET_LM_ACTIVE_TAB",
    FETCH_CASH_FLOWS = "FETCH_CASH_FLOWS",
    FETCH_YEARLY_CASH_FLOWS = "FETCH_YEARLY_CASH_FLOWS",
    FETCH_LAST_6_MONTHS_CASH_FLOWS = "FETCH_LAST_6_MONTHS_CASH_FLOWS",
    RESET_LIQUIDITIES = "RESET_LIQUIDITIES",
    SET_SHOULD_FETCH_CASH_FLOWS_DATA = "SET_SHOULD_FETCH_CASH_FLOWS_DATA",
    SET_SHOULD_FETCH_BANK_BALANCE_DATA = "SET_SHOULD_FETCH_BANK_BALANCE_DATA",
    SET_BANK_BALANCE_DATA = "SET_BANK_BALANCE_DATA",
}

export interface FetchAllScenariosAction extends Action<typeof LiquidityTypes.FETCH_ALL_SCENARIOS> {
    type: typeof LiquidityTypes.FETCH_ALL_SCENARIOS
    scenarios: Array<LiquidityScenario>
    scenario: LiquidityScenario
}

export interface CreateOneScenarioAction extends Action<typeof LiquidityTypes.CREATE_ONE_SCENARIO> {
    type: typeof LiquidityTypes.CREATE_ONE_SCENARIO
    scenario: LiquidityScenario
}

export interface UpdateOneScenarioAction extends Action<typeof LiquidityTypes.UPDATE_ONE_SCENARIO> {
    type: typeof LiquidityTypes.UPDATE_ONE_SCENARIO
    scenario: LiquidityScenario
}

export interface DeleteOneScenarioAction extends Action<typeof LiquidityTypes.DELETE_ONE_SCENARIO> {
    type: typeof LiquidityTypes.DELETE_ONE_SCENARIO
    scenarioId: string
}

export interface SelectScenarioAction extends Action<typeof LiquidityTypes.SELECT_SCENARIO> {
    type: typeof LiquidityTypes.SELECT_SCENARIO
    scenario: LiquidityScenario
}

export interface UpdateIntervalAction extends Action<typeof LiquidityTypes.UPDATE_INTERVAL> {
    type: typeof LiquidityTypes.UPDATE_INTERVAL
    interval?: [moment.Moment, moment.Moment]
}

export interface FetchAllForecastRulesAction extends Action<typeof LiquidityTypes.FETCH_ALL_FORECAST_RULES> {
    type: typeof LiquidityTypes.FETCH_ALL_FORECAST_RULES
    forecastRules: Array<LiquidityForecastRule>
}

export interface CreateOneForecastRuleAction extends Action<typeof LiquidityTypes.CREATE_ONE_FORECAST_RULE> {
    type: typeof LiquidityTypes.CREATE_ONE_FORECAST_RULE
    forecastRule: LiquidityForecastRule
}

export interface UpdateOneForecastRuleAction extends Action<typeof LiquidityTypes.UPDATE_ONE_FORECAST_RULE> {
    type: typeof LiquidityTypes.UPDATE_ONE_FORECAST_RULE
    forecastRule: LiquidityForecastRule
}

export interface DeleteOneForecastRuleAction extends Action<typeof LiquidityTypes.DELETE_ONE_FORECAST_RULE> {
    type: typeof LiquidityTypes.DELETE_ONE_FORECAST_RULE
    forecastRuleId: string
}

export interface SetLiquidityActiveTabAction extends Action<typeof LiquidityTypes.SET_LM_ACTIVE_TAB> {
    type: typeof LiquidityTypes.SET_LM_ACTIVE_TAB
    activeTab: LMTabTypeEnum
}

export interface FetchCashFlowsAction extends Action<typeof LiquidityTypes.FETCH_CASH_FLOWS> {
    type: typeof LiquidityTypes.FETCH_CASH_FLOWS
    cashFlows: Array<LiquidityCashFlow>
}

export interface FetchYearlyCashFlowsAction extends Action<typeof LiquidityTypes.FETCH_YEARLY_CASH_FLOWS> {
    type: typeof LiquidityTypes.FETCH_YEARLY_CASH_FLOWS
    yearlyCashFlows: Array<LiquidityCashFlow>
}

export interface FetchLast6MonthsCashFlowsAction extends Action<typeof LiquidityTypes.FETCH_LAST_6_MONTHS_CASH_FLOWS> {
    type: typeof LiquidityTypes.FETCH_LAST_6_MONTHS_CASH_FLOWS
    last6MonthsCashFlows: Array<LiquidityCashFlow>
}

export interface ResetLiquiditiesAction extends Action<typeof LiquidityTypes.RESET_LIQUIDITIES> {
    type: typeof LiquidityTypes.RESET_LIQUIDITIES
}

export interface SetShouldFetchCashFlowsData extends Action<typeof LiquidityTypes.SET_SHOULD_FETCH_CASH_FLOWS_DATA> {
    type: typeof LiquidityTypes.SET_SHOULD_FETCH_CASH_FLOWS_DATA
    value: boolean
}

export interface SetLastBankBalanceDataFetch extends Action<typeof LiquidityTypes.SET_SHOULD_FETCH_BANK_BALANCE_DATA> {
    type: typeof LiquidityTypes.SET_SHOULD_FETCH_BANK_BALANCE_DATA
    value: boolean
}

export interface SetBankBalanceData extends Action<typeof LiquidityTypes.SET_BANK_BALANCE_DATA> {
    type: typeof LiquidityTypes.SET_BANK_BALANCE_DATA
    value: Array<BankBalanceInfo>
}

export type LiquidityActions =
    | FetchAllScenariosAction
    | CreateOneScenarioAction
    | UpdateOneScenarioAction
    | DeleteOneScenarioAction
    | SelectScenarioAction
    | UpdateIntervalAction
    | FetchAllForecastRulesAction
    | CreateOneForecastRuleAction
    | UpdateOneForecastRuleAction
    | DeleteOneForecastRuleAction
    | SetLiquidityActiveTabAction
    | FetchCashFlowsAction
    | FetchYearlyCashFlowsAction
    | FetchLast6MonthsCashFlowsAction
    | ResetLiquiditiesAction
    | SetShouldFetchCashFlowsData
    | SetLastBankBalanceDataFetch
    | SetBankBalanceData
