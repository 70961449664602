export enum ForecastTransactionTypeEnum {
    ONE_TIME = "ONE_TIME",
    RECURRING = "RECURRING",
}

export const enum LMTabTypeEnum {
    CASHFLOW_PLANNING = "cashflowPlanning",
    DASHBOARD = "dashboard",
    TRANSACTION_CATEGORIES = "transactionCategories",
}

export const enum CashFlowAmountSourceEnum {
    ACTUAL = "actual",
    FORECAST = "forecast",
}

export interface ForecastDiagramData {
    month: string
    balance: number
    actualIncome?: number
    actualSpending?: number
    forecastIncome?: number
    forecastSpending?: number
}

export enum CashFlowDirectionEnum {
    TOTAL_INCOME = "totalIncome",
    TOTAL_SPENDING = "totalSpending",
    TOTAL_BALANCE = "totalBalance",
}
