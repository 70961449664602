export enum CorporateIndustryTypeEnum {
    ACCOUNTING,
    AUDIT,
    FINANCE,
    PUBLIC_SECTOR_ADMINISTRATION,
    ART_ENTERTAINMENT,
    AUTO_AVIATION,
    BANKING_LENDING,
    BUSINESS_CONSULTANCY_LEGAL,
    CONSTRUCTION_REPAIR,
    EDUCATION_PROFESSIONAL_SERVICES,
    INFORMATIONAL_TECHNOLOGIES,
    TOBACCO_ALCOHOL,
    GAMING_GAMBLING,
    MEDICAL_SERVICES,
    MANUFACTURING,
    PRECIOUS_GOODS_JEWELRY,
    NON_GOVERNMENTAL_ORGANIZATION,
    INSURANCE_SECURITY,
    RETAIL_WHOLESALE,
    TRAVEL_TOURISM,
    FREELANCER,
    PR_MARKETING,
}
