import { Tax } from "@finway-group/shared/lib/models"
import axios from "axios"

import store from "Shared/store"

const MODEL = "taxes"

const TaxService = {
    fetchTaxes: async () => {
        const {
            data: { docs },
        } = await axios.get(`/${MODEL}?limit=100`)
        return docs as Array<Tax>
    },
    updateAllTaxes: async (updateData: Array<Tax>) => {
        const { data } = await axios.put(`${MODEL}`, { taxes: updateData })
        return data as Array<Tax>
    },
    getTaxById: (id: string): Tax => {
        const taxes = store.getState().taxes.items
        return taxes.find((tax: Tax) => tax._id === id)
    },
    getTaxByTaxRate: (taxRate: number): Tax => {
        const taxes = store.getState().taxes.items
        return taxes.find((tax: Tax) => tax.taxRate === taxRate && tax.deleted === false)
    },
    getTaxesByIds: (ids: Array<string>): Array<Tax> => {
        const taxes = store.getState().taxes.items
        return taxes.filter((tax: Tax) => ids.includes(tax._id))
    },

    getReferencedTaxRates: async () => {
        const { data } = await axios.get(`${MODEL}/check-referenced`)
        return data
    },
    syncExpenses: async (updatedTaxes: Array<Tax>) => {
        const { data } = await axios.post(`${MODEL}/sync-expenses`, { taxes: updatedTaxes })
        return data
    },
}

export default TaxService
