import { Button, Tooltip } from "antd"
import React from "react"
import { Edit } from "react-feather"
import { useTranslation } from "react-i18next"

import { useIsPersonioEnabled } from "Shared/hooks/featureFlags.hooks"

import { ConditionalWrapper } from "./conditionalWrapper"

export default function PersonioActionButton({ onClick, isDisconnected }: { onClick: () => void; isDisconnected: boolean }) {
    const { t } = useTranslation()

    const isPersonioDisabled = !useIsPersonioEnabled()

    return (
        <ConditionalWrapper condition={isPersonioDisabled} wrapper={(children) => <Tooltip title={t("tooltips:personio.contact_support_to_enable")}>{children}</Tooltip>}>
            <Button disabled={isPersonioDisabled} onClick={onClick} className={`ml-8`}>
                {isDisconnected ? <>{t("action:connect")}</> : <Edit style={{ color: "inherit" }} />}
            </Button>
        </ConditionalWrapper>
    )
}
