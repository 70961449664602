import { Alert, Button } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"

import { SpreadsheetDataUploadModal } from "Components/modals/spreadsheetDataUpload/spreadsheetDataUpload.modal"
import { useModal } from "Shared/context/modal.context"

const DataUploadAlert = () => {
    const { t } = useTranslation()
    const { showModal } = useModal()

    const handleShowDataUploadModal = () => {
        showModal(SpreadsheetDataUploadModal, true, { isShowing: true })
    }

    return (
        <Alert
            message={
                <div className="flex justify-between">
                    <p>{t("info:get_started_uploading_data")}</p>
                    <Button type="primary" style={{ transform: "translateY(12px)" }} className="translate-y-24" onClick={handleShowDataUploadModal}>
                        {t("action:upload_my_data")}
                    </Button>
                </div>
            }
            description={t("info:simply_get_started_by_downloading_our_template")}
            type="info"
            showIcon
            // action= // newer version of antd has this prop where we can add the button instead of in the message prop like we are doing right now, and avoid the transform translateY
        />
    )
}

export default DataUploadAlert
