import { KYBStatusEnum } from "@finway-group/shared/lib/models"
import { Employee, GetEmployeeById } from "@finway-group/shared/lib/models/user/employee.model"
import { Form, Modal, Row } from "antd"
import { Store } from "antd/lib/form/interface"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import FormSelect, { EmployeeOption } from "Components/form/formSelect"
import { useCompany } from "Shared/hooks/company.hooks"
import { useKYBData } from "Shared/hooks/corporate.hooks"
import { useEmployees } from "Shared/hooks/employee.hooks"
import { AuthzService, NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { updateCompanyFeatureSettings } from "Shared/store/actions/company/companyActions"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

interface FallbackApproverModalInterface {
    isShowing: boolean
    onCancel: () => void
}

export const FallbackApproverModal: React.FC<FallbackApproverModalInterface> = ({ isShowing, onCancel }) => {
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useState(false)
    const company = useCompany()
    const kybData = useKYBData()
    const employees = useEmployees({ excludeDeleted: true })

    const [options, setOptions] = useStateIfMounted<Array<EmployeeOption>>([])

    useEffect(() => {
        const options: Array<EmployeeOption> =
            kybData.kybStatus === KYBStatusEnum.COMPLETED && company.cardsEnabled
                ? employees.filter(({ activeCompanyProfile }) => AuthzService.isGlobalApprover(activeCompanyProfile.roleId) && activeCompanyProfile.weavrData?.userId)
                : employees.filter(({ activeCompanyProfile }) => AuthzService.isGlobalApprover(activeCompanyProfile.roleId))

        if (company.fallbackApprover) {
            const current = GetEmployeeById(employees, company.fallbackApprover)
            // in case the current approver is either:
            //   1. deleted or
            //   2. not enrolled in the cards program
            // still add that user as option or drop down will show only ID and not image and name
            if (current && !options.some((e) => e?.id === current?.id)) {
                const currentOption: EmployeeOption = new Employee(current)
                currentOption.disabled = true // prevent that this user is selected
                options.push(currentOption)
            }
        }

        setOptions(options)
    }, [])

    const onFormSubmit = async (values: Store) => {
        try {
            setIsLoading(true)

            company.shouldRefetch = undefined

            await dispatch(updateCompanyFeatureSettings(company._id, { fallbackApprover: values.fallbackApprover }))

            NotificationService.send(
                NotificationTypeEnum.SUCCESS,
                t("notification:settings.company.fallback_approver.updated.title"),
                t("notification:settings.company.fallback_approver.updated.message"),
            )
            handleHide()
        } catch (err) {
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:error"))
        } finally {
            setIsLoading(false)
        }
    }

    const handleHide = () => {
        form.resetFields()
        onCancel()
    }

    return (
        <Modal
            title={t("label:define_fallback_approver")}
            visible={isShowing}
            maskClosable={false}
            onOk={form.submit}
            onCancel={handleHide}
            okText={t("action:save")}
            className={"ant-modal--small"}
            keyboard
            destroyOnClose={true}
            confirmLoading={isLoading}
        >
            <Form form={form} layout="vertical" onFinish={onFormSubmit}>
                <Row className="mb-30">
                    <span className="text-text">{t("info:select_fallback_approver")}</span>
                </Row>
                <FormSelect
                    name="fallbackApprover"
                    header={t("input:fallback_approver")}
                    initialValue={company.fallbackApprover}
                    options={options}
                    displayImages
                    showSearch
                    fallback={false}
                    keyCode="0"
                />
            </Form>
        </Modal>
    )
}
