import React from "react"
import { useTranslation } from "react-i18next"

import Loading from "Components/loading"
import { useKYBForm } from "Shared/hooks/weavr.hooks"

interface KYBFormInterface {}

const KYBForm: React.FC<KYBFormInterface> = ({}) => {
    // this will inject the KYB form in the container below
    const { isLoading, hasError } = useKYBForm("kyb-container")
    const { t } = useTranslation()

    return (
        <div>
            <div className="flex items-center">
                <h2 className="ml-10">{t("corporate:kyb.title")}</h2>
            </div>
            {isLoading ? <Loading /> : hasError ? <div className="mt-40 ml-10">Failed to load KYB form.</div> : <div id="kyb-container"> </div>}
        </div>
    )
}

export default KYBForm
