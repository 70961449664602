import { DocumentChangeInterface, OperationTypeEnum } from "@finway-group/shared/lib/models"
import * as Sentry from "@sentry/react"
import i18n from "i18next"

import { fetchAllBankConnections } from "Shared/store/actions/bank/bankActions"
import { setShouldFetchBankBalanceData, setShouldFetchCashFlowsData } from "Shared/store/actions/liquidity/liquidityActions"
import { isClientErrorCode } from "Shared/utils/errorHandler.utils"

import NotificationService, { NotificationTypeEnum } from "../notification.service"

const subscribeBankConnection = (dispatch: any, data: DocumentChangeInterface, loggedInUserId: string) => {
    const {
        fullDocument: { operationType, createdBy, metaData },
    } = data

    try {
        if (loggedInUserId === createdBy) {
            const errorMsg = extractError((metaData as any)?.reason)
            switch (operationType) {
                case OperationTypeEnum.BANK_IMPORT_SUCCESS:
                    NotificationService.send(NotificationTypeEnum.SUCCESS, i18n.t("notification:banking.imported.title"), i18n.t("notification:banking.imported.message"), 10)
                    break
                case OperationTypeEnum.BANK_IMPORT_FAILED:
                    NotificationService.send(NotificationTypeEnum.ERROR, i18n.t("error:banking.import.title"), errorMsg || i18n.t("error:banking.import.message"), 0)
                    break
                case OperationTypeEnum.BANK_IMPORT_CONFLICT:
                    NotificationService.send(NotificationTypeEnum.ERROR, i18n.t("error:banking.import.conflict.title"), i18n.t("error:banking.import.conflict.message"), 0)
                    break
                case OperationTypeEnum.BANK_ADD_INTERFACE_SUCCESS:
                    NotificationService.send(
                        NotificationTypeEnum.SUCCESS,
                        i18n.t("notification:banking.new_interface_added.title"),
                        i18n.t("notification:banking.new_interface_added.message"),
                    )
                    break
                case OperationTypeEnum.BANK_ADD_INTERFACE_FAILED:
                    NotificationService.send(
                        NotificationTypeEnum.ERROR,
                        i18n.t("error:banking.new_interface_added.title"),
                        errorMsg || i18n.t("error:banking.new_interface_added.message"),
                        0,
                    )
                    break
                case OperationTypeEnum.BANK_PAYMENT_SUCCESS:
                    NotificationService.send(NotificationTypeEnum.SUCCESS, i18n.t("notification:banking.paid.title"), i18n.t("notification:banking.paid.message"))
                    break
                case OperationTypeEnum.BANK_PAYMENT_FAILED:
                    NotificationService.send(NotificationTypeEnum.ERROR, i18n.t("error:banking.payment.title"), errorMsg || i18n.t("error:banking.payment.message"), 0)
                    break
                case OperationTypeEnum.BANK_WEB_FORM_ABORTED:
                    NotificationService.send(NotificationTypeEnum.WARNING, i18n.t("error:banking.webform_abort.title"), i18n.t("error:banking.webform_abort.message"), 0)
                    break
                case OperationTypeEnum.BANK_DOWNLOAD_SUCCESS:
                    NotificationService.send(NotificationTypeEnum.SUCCESS, i18n.t("notification:banking.downloaded.title"), i18n.t("notification:banking.downloaded.message"), 10)
                    break
                case OperationTypeEnum.BANK_DOWNLOAD_FAILED:
                    NotificationService.send(NotificationTypeEnum.ERROR, i18n.t("error:banking.download.title"), errorMsg || i18n.t("error:banking.download.message"), 0)
                    break
            }
        }

        // liquidity related actions
        dispatch(setShouldFetchBankBalanceData(true))
        dispatch(setShouldFetchCashFlowsData(true))

        dispatch(fetchAllBankConnections())
    } catch (err) {
        if (isClientErrorCode(err)) return
        Sentry.captureException(`[Bank Connection Socket] Error: ${typeof err === "string" ? err : JSON.stringify(err)}`)
    }
}

const extractError = (errorMessage: any) => {
    if (errorMessage && errorMessage.includes("Bank rejected request with message:"))
        return i18n.t("error:banking.import.message_with_reason", { message: errorMessage.replace("Bank rejected request with message:", "") }) // refactor later if there are other special cases.
    return undefined
}

export default subscribeBankConnection
