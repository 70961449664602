import { PerDiemDestination } from "@finway-group/shared/lib/models"
import { useSelector } from "react-redux"

import { RootState } from "Shared/store/rootState.interface"
import { generatePerDiemDestinationTree } from "Shared/utils/expense.utils"

import { useIsTravelEnabled } from "./featureFlags.hooks"

export const usePerDiemDestinations = (asTree = false) => {
    const destinations = useSelector(({ perDiemDestinations }: RootState) => perDiemDestinations.items)
    const travelEnabled = useIsTravelEnabled()
    const isReady = destinations.length > 0 || !travelEnabled
    if (asTree) {
        return { destinations: generatePerDiemDestinationTree(destinations), isReady }
    }
    return { destinations, isReady }
}

export const usePerDiemDestinationsForYear = (year: number, asTree = false) => {
    const destinations = useSelector(({ perDiemDestinations }: RootState) => perDiemDestinations.items)
    const travelEnabled = useIsTravelEnabled()
    const isReady = destinations.length > 0 || !travelEnabled

    const destinationsForYear = destinations.filter((destination) => destination.year === year)
    if (asTree) {
        return { destinations: generatePerDiemDestinationTree(destinationsForYear), isReady }
    }
    return { destinations: destinationsForYear, isReady }
}

export const usePerDiemDestinationMap = () => {
    const destinations = useSelector(({ perDiemDestinations }: RootState) => perDiemDestinations.items)
    const isReady = destinations.length > 0
    return {
        destinationMap: new Map<string, PerDiemDestination>(destinations.map((d) => [d._id, d])),
        isReady,
    }
}

export const usePerDiemDestinationById = (id: string) => {
    const destinations = useSelector(({ perDiemDestinations }: RootState) => perDiemDestinations.items)
    return destinations.find((d) => d._id === id)
}
