import { ExclamationCircleOutlined } from "@ant-design/icons"
import { CsvNumberDecimalSeparatorTypeEnum, UserLanguageEnum } from "@finway-group/shared/lib/models/user/userSettings.interface"
import { Tooltip } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"

import { DataUploaderOpenModalButton } from "Components/actionButton/dataUploaderOpenModalButton"
import { useIsFreeVersion } from "Shared/hooks/featureFlags.hooks"
import { useLoggedInEmployeeProfile } from "Shared/hooks/user.hooks"
import { AuthzService } from "Shared/services"

interface ToolSettingsInterface {}
const ToolSettings: React.FC<ToolSettingsInterface> = () => {
    const { t } = useTranslation()

    const currentEmployee = useLoggedInEmployeeProfile()
    const isFreeVersion = useIsFreeVersion()

    const userLanguage = currentEmployee.getUserLanguage() === UserLanguageEnum.DE ? t("label:german") : t("label:english")

    return (
        <div id="finway-tool-settings">
            <div className="mt-10">
                <ul className="description-list">
                    <li>
                        <span className="title">{t("label:global_currency")}</span>
                        <span className="text text-left">{currentEmployee.settings.globalCurrency}</span>
                    </li>
                    <li>
                        <span className="title">{t("label:language")}</span>
                        <span className="text text-left">{userLanguage}</span>
                    </li>
                    <li>
                        <span className="title w-180">{t("label:csv_delimiter")}</span>
                        <span className="text text-left">{t(`label:csv_delimiter_type.${currentEmployee.settings.csvDelimiter.toLowerCase()}`)}</span>
                    </li>
                    <li>
                        <span className="title w-180">{t("label:number_separator")}</span>
                        {currentEmployee.settings.csvNumberDecimalSeparator ? (
                            <span className="text text-left">
                                {currentEmployee.settings.csvNumberDecimalSeparator === CsvNumberDecimalSeparatorTypeEnum.COMMA
                                    ? t(`label:number_separator_type.comma`)
                                    : t(`label:number_separator_type.dot`)}
                            </span>
                        ) : (
                            <span className="text text-left">{t(`label:number_separator_type.comma`)}</span>
                        )}
                    </li>
                    {!isFreeVersion && (
                        <>
                            <li>
                                <span className="title min-w-140 md:min-w-300">{t("label:show_expense_numbers")}</span>
                                <span className="text text-left">{currentEmployee.settings.showExpenseNumbers ? t("label:enabled") : t("label:disabled")}</span>
                            </li>
                            <li>
                                <span className="title w-180">{t("label:enable_ocr")}</span>
                                <span className="text text-left">{currentEmployee.settings.enableOcr ? t("label:enabled") : t("label:disabled")}</span>
                            </li>
                            {currentEmployee.settings.enableOcr && (
                                <li>
                                    <span className="title w-180">{t("label:enable_ocr_itemization")}</span>
                                    <span className="text text-left">{currentEmployee.settings.enableOcrItemization ? t("label:enabled") : t("label:disabled")}</span>
                                </li>
                            )}
                            {AuthzService.isLoggedInUserPotentialApprover() && (
                                <li>
                                    <span className="title w-180">
                                        {t("label:enable_go_to_next_expense")}
                                        <Tooltip title={t("info:enable_go_to_next_expense")} placement="right" className="align-middle">
                                            <ExclamationCircleOutlined className="ml-4" />
                                        </Tooltip>
                                    </span>
                                    <span className="text text-left">{currentEmployee.settings.enableGoToNextExpense ? t("label:enabled") : t("label:disabled")}</span>
                                </li>
                            )}
                            <li>
                                <span className="title w-180">{t("label:show_gross")}</span>
                                <span className="text text-left">{currentEmployee.settings.showGrossAmount ? t("label:enabled") : t("label:disabled")}</span>
                            </li>
                            {AuthzService.isDataUploaderGrantedForLoggedInUser() && (
                                <li>
                                    <span className="title w-180">{t("label:bulk_data_upload")}</span>
                                    <DataUploaderOpenModalButton text={t("action:upload")} />
                                </li>
                            )}
                        </>
                    )}
                </ul>
            </div>
        </div>
    )
}

export default ToolSettings
