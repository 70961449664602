import { Button, Col, DatePicker, Form, TimePicker } from "antd"
import { FormListFieldData } from "antd/lib/form/FormList"
import moment, { Moment } from "moment-timezone"
import React, { useEffect } from "react"
import { Trash } from "react-feather"
import { useTranslation } from "react-i18next"

import { DestinationFieldsRule } from "Components/forms/rules/perDiem.rules"
import { useExpenseFormContext } from "Components/modals/expenseCreateForm.context"
import { usePerDiemDestinationMap } from "Shared/hooks/perDiemDestination.hooks"
import { getDestinationRateOfDifferentYear } from "Shared/utils/perDiem.utils"
import { getPopupAnchor } from "Shared/utils/popup.utils"

import { HoverableInfo } from "../../auxilliary/HoverableInfo"
import { FormPerDiemDestinationItinerary } from "../perDiem.form.types"
import PerDiemDestinationSelector from "./perDiemDestinationSelector"
import PerDiemRangeAlert from "./perDiemRangeAlert"

const PER_DIEM_UNSUPPORTED_LIMIT = "2021-01-01"

interface PerDiemDestinationFieldInterface {
    elementField: FormListFieldData
    index: number
    remove: (i: number) => void
    aboveRange: { [key: string]: boolean }
    belowRange: boolean
    tripDestinations: Array<FormPerDiemDestinationItinerary>
    adjustDestinationsOnChange: () => void
}

export const PerDiemDestinationField = ({
    elementField,
    index,
    remove,
    aboveRange,
    belowRange,
    tripDestinations,
    adjustDestinationsOnChange,
}: PerDiemDestinationFieldInterface) => {
    const { t } = useTranslation()

    const [expenseFormContext] = useExpenseFormContext()
    const { expenseForm } = expenseFormContext
    const { destinationMap, isReady } = usePerDiemDestinationMap()
    const tripYear = tripDestinations[index]?.startDate?.get("year") ?? moment().get("year")

    // When the user selects a destination for e.g. year 2022, but changes the start date to 2023, change the destination into the 2023 one.
    useEffect(() => {
        const currentDestination = tripDestinations[index]?.destination

        if (currentDestination) {
            const newTripDestinations = [...tripDestinations]
            newTripDestinations[index].destination = getDestinationRateOfDifferentYear(tripYear, destinationMap.get(currentDestination)!, Array.from(destinationMap.values()))?._id
            expenseForm.setFieldsValue({ destinations: newTripDestinations })
            adjustDestinationsOnChange()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tripYear])

    if (!isReady) {
        return null
    }

    const spans = {
        destination: index === 0 ? 24 : 8,
        startDate: index === 0 ? 6 : 0,
        startTime: index === 0 ? 6 : 0,
        endDate: index === 0 ? 6 : 8,
        endTime: 6,
        trash: index === 0 ? 0 : 2,
    }

    const rules = DestinationFieldsRule("destinations", index)

    /**
     * Determines the disabled dates for the start date picker.
     * Blocks the user from picking dates after the current destination's end date.
     */
    const startDisabledDate = (current: Moment) => {
        if (current.isSameOrBefore(PER_DIEM_UNSUPPORTED_LIMIT)) return true
        const currentDestination = tripDestinations[index]

        if (!currentDestination?.endDate) return false

        return current.isAfter(currentDestination.endDate, "minute")
    }

    /**
     * Determines the disabled dates for the return/transfer date picker.
     * Blocks the user from picking dates before the previous destination or after the next destination
     */
    const endDisabledDate = (current: Moment) => {
        if (current.isSameOrBefore(PER_DIEM_UNSUPPORTED_LIMIT)) return true
        const currentDestination = tripDestinations[index]

        // Current destination's startDate always be the previous's endDate, managed by adjustStartDateDestinations
        if (!currentDestination?.startDate) return false

        // Block dates that is after the next destination's end date
        const nextDestination = tripDestinations[index + 1]
        if (nextDestination?.endDate && current.isAfter(nextDestination.endDate, "minute")) return true

        // Block dates that is before the current destination's start date
        return current.isBefore(currentDestination.startDate, "minute")
    }

    /**
     * Determines the hours of the time-picker that should be blocked.
     * Should evaluate when the endDate of the current destination is equal with the endDate of the other destination
     */
    const disabledHours = () => {
        // Don't do check if the date hasn't been defined yet.
        if (!tripDestinations[index]) return []

        // Get the previous destination end date (transfer date) OR current destination start date as a fallback
        const previousMovementDate = tripDestinations[index - 1]?.endDate ?? tripDestinations[index]?.startDate
        if (!previousMovementDate) return []

        // When the current destination date is not the same as the previous date, do nothing
        if (tripDestinations[index].endDate?.isAfter(previousMovementDate, "day")) return []

        // But if it's the same, make sure that the user cannot select the hours before the previous date
        const disabledUntil = previousMovementDate.hour()

        return disabledUntil ? [...Array(disabledUntil).keys()] : []
    }

    /**
     * Determines the minutes of the time-picker that should be blocked.
     * Should evaluate when the endDate of the current destination is equal with the endDate of other destination, and have the same hour set.
     */
    const disabledMinutes = (selectedHour: number) => {
        // Don't check if the date hasn't been defined yet.
        if (!tripDestinations[index]) return []

        // Get the previous destination end date (transfer date) OR current destination start date as a fallback
        const previousMovementDate = tripDestinations[index - 1]?.endDate ?? tripDestinations[index]?.startDate

        if (!previousMovementDate) return []

        // When the current destination date is not the same as the previous date, do not disabled anything in the minutes
        if (tripDestinations[index].endDate?.isAfter(previousMovementDate, "day")) return []

        // When the selected hour is more then the previous date's hour, do not disable anything in the minutes
        if (selectedHour > previousMovementDate.hours()) return []

        const disabledUntil = previousMovementDate.minute()
        return disabledUntil ? [...Array(disabledUntil).keys(), disabledUntil] : []
    }

    // date and time fields is referring to the same property so the date-picker and time-picker have the same "name" (startDate and endDate) prop.
    return (
        <React.Fragment key={`per-diem-destination-field-${index}`}>
            <Col span={spans.destination}>
                <PerDiemDestinationSelector
                    rules={rules.destination}
                    elementField={elementField}
                    adjustDestinationsOnChange={adjustDestinationsOnChange}
                    tripYear={tripYear}
                    destinationIndex={index}
                    value={tripDestinations[index]?.destination}
                />
            </Col>
            <Col span={spans.startDate}>
                <Form.Item {...elementField} label={t("input:request.per_diem.start_date")} name={[elementField.name, "startDate"]} key="startDate" rules={rules.startDate}>
                    <DatePicker onChange={adjustDestinationsOnChange} disabledDate={startDisabledDate} format={"DD/MM/YYYY"} getPopupContainer={getPopupAnchor()} />
                </Form.Item>
            </Col>
            <Col span={spans.startTime}>
                <Form.Item {...elementField} label={t("input:request.per_diem.start_time")} name={[elementField.name, "startDate"]} key="startTime" rules={rules.startTime}>
                    <TimePicker onChange={adjustDestinationsOnChange} format={"HH:mm"} getPopupContainer={getPopupAnchor()} popupClassName="timepicker-display-fix" />
                </Form.Item>
            </Col>
            <Col span={spans.endDate}>
                <Form.Item
                    {...elementField}
                    label={
                        index === tripDestinations.length - 1 ? (
                            t("input:request.per_diem.return_date")
                        ) : (
                            <>
                                {t("input:request.per_diem.transfer_date")}
                                <HoverableInfo message={t("info:per_diem.transfer_date")} />
                            </>
                        )
                    }
                    name={[elementField.name, "endDate"]}
                    key="endDate"
                    rules={rules.endDate}
                >
                    <DatePicker
                        disabledDate={endDisabledDate}
                        className="w-full"
                        onChange={adjustDestinationsOnChange}
                        format={"DD/MM/YYYY"}
                        getPopupContainer={getPopupAnchor()}
                    />
                </Form.Item>
            </Col>
            <Col span={spans.endTime}>
                <Form.Item
                    {...elementField}
                    label={
                        index === tripDestinations.length - 1 ? (
                            t("input:request.per_diem.return_time")
                        ) : (
                            <>
                                {t("input:request.per_diem.transfer_time")}
                                <HoverableInfo message={t("info:per_diem.transfer_time")} />
                            </>
                        )
                    }
                    name={[elementField.name, "endDate"]}
                    key="endTime"
                    rules={rules.endTime}
                >
                    <TimePicker
                        className="w-full"
                        onChange={adjustDestinationsOnChange}
                        format={"HH:mm"}
                        getPopupContainer={getPopupAnchor()}
                        disabledHours={disabledHours}
                        disabledMinutes={disabledMinutes}
                        popupClassName="timepicker-display-fix"
                    />
                </Form.Item>
            </Col>
            <Col span={spans.trash}>
                <Button
                    className="mt-28"
                    onClick={() => {
                        remove(index)
                        adjustDestinationsOnChange()
                    }}
                >
                    <Trash />
                </Button>
            </Col>
            <PerDiemRangeAlert aboveRange={aboveRange} index={index} belowRange={belowRange} />
        </React.Fragment>
    )
}
