import i18n from "i18next"

import { isEmptyString } from "Shared/utils/helper.utils"

const SEPAExportConfirmationRules = () => ({
    subjectLine: [
        {
            validator: (_rule: any, value: string) => {
                if (isEmptyString(value)) return Promise.reject(i18n.t("validation:required"))
                if (value.length > 140) return Promise.reject(i18n.t("validation:below.string", { max: 140 }))
                return Promise.resolve()
            },
        },
    ],
})

export default SEPAExportConfirmationRules
