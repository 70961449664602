import { PaymentSettings } from "@finway-group/shared/lib/models"
import { CardCvcElement, CardExpiryElement, CardNumberElement } from "@stripe/react-stripe-js"
import { StripeElementChangeEvent } from "@stripe/stripe-js"
import { Button, Col, Form, Input, Row } from "antd"
import { Store } from "antd/lib/form/interface"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"

import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

import { EmailFormRule } from "./rules"

interface PaymentMethodFormCardInterface {
    onSubmit: (values: Store) => void
    paymentSettings: PaymentSettings
    isEditable: boolean
    onCancel: () => void
}

const PaymentMethodCardForm = ({ onSubmit, paymentSettings, isEditable, onCancel }: PaymentMethodFormCardInterface) => {
    const { t } = useTranslation()
    const [formInstance] = Form.useForm()

    const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useStateIfMounted<any>(false)
    const [requiredFieldsComplete, setRequiredFieldsComplete] = useStateIfMounted({ cardNumber: false, cardExpiry: false, cardCvc: false, email: false })

    const cardElementsOptions = {
        placeholder: "",
        disabled: !isEditable,
        classes: { base: "w-full border-2 border-transparent p-8", focus: "border-2 border-lightsteelblue shadow-md" },
        style: {},
    }

    // form init
    useEffect(() => {
        formInstance.setFieldsValue({
            email: paymentSettings.paymentMethod?.email,
            secondEmail: paymentSettings.paymentMethod?.secondEmail,
        })
        setRequiredFieldsComplete({ ...requiredFieldsComplete, email: !!paymentSettings.paymentMethod?.email })
    }, [])

    // enables save button when all required fields are filled
    useEffect(() => {
        if (Object.values(requiredFieldsComplete).every((value) => value)) {
            setIsSaveButtonEnabled(true)
        } else if (isSaveButtonEnabled) {
            setIsSaveButtonEnabled(false)
        }
    }, [requiredFieldsComplete])

    const handleStripeCardElementChange = (event: StripeElementChangeEvent) => {
        if (event.complete) {
            setRequiredFieldsComplete({ ...requiredFieldsComplete, [event.elementType]: true })
        } else if ((requiredFieldsComplete as any)[event.elementType] === true) {
            setRequiredFieldsComplete({ ...requiredFieldsComplete, [event.elementType]: false })
        }
    }

    const handleEmailChange = async (event: any) => {
        if (!event.target.value) return
        try {
            await formInstance.validateFields(["email"])
            setRequiredFieldsComplete({ ...requiredFieldsComplete, email: true })
        } catch (err) {
            if (requiredFieldsComplete.email) setRequiredFieldsComplete({ ...requiredFieldsComplete, email: false })
        }
    }

    const cardNumberField = (
        <Form.Item className="w-full" label={t("label:payment_settings.card_number")} required>
            {paymentSettings.paymentMethod?.card && !isEditable ? (
                <Input value={`**** **** **** ${paymentSettings.paymentMethod?.card?.last4Digits}`} className="w-full" disabled />
            ) : (
                <div className="border-b-1">
                    <CardNumberElement options={cardElementsOptions} onChange={handleStripeCardElementChange} />
                </div>
            )}
        </Form.Item>
    )

    // prettier-ignore
    const expirationDateField = (
        <Form.Item className="w-full" label={t("label:payment_settings.expiration_date")} required>
            {paymentSettings.paymentMethod?.card && !isEditable ? (
                <Input value={`${String(paymentSettings.paymentMethod?.card?.expirationMonth).padStart(2, "0")}/${paymentSettings.paymentMethod?.card?.expirationYear.toString().slice(-2)}`} disabled className="w-full" />
            ) : (
                <div className="border-b-1">
                    <CardExpiryElement options={cardElementsOptions} onChange={handleStripeCardElementChange}/>
                </div>
            )}
        </Form.Item>
    )

    const cvcField = (
        <Form.Item className="w-full" label={t("label:payment_settings.cvc")} required>
            {paymentSettings.paymentMethod?.card && !isEditable ? (
                <Input value={"***"} className="w-full" disabled />
            ) : (
                <div className="border-b-1">
                    <CardCvcElement options={cardElementsOptions} onChange={handleStripeCardElementChange} />
                </div>
            )}
        </Form.Item>
    )

    const emailField = (
        <Form.Item name="email" className="w-full" label={t("label:payment_settings.email")} required rules={EmailFormRule}>
            <Input disabled={!isEditable} onChange={handleEmailChange} />
        </Form.Item>
    )

    const secondEmailField = (
        // TODO: enable when implemented - currently not yet supported by stripe API (dashboard only)
        // See: https://stackoverflow.com/q/65685098
        // <Form.Item name="secondEmail" className="w-full" label={t("label:payment_settings.second_email")} rules={[{ type: "email" }]}>
        //     <Input disabled={!isEditable} />
        // </Form.Item>
        <></>
    )

    const issueFinwayCardFooter = () => <></> // TODO implment button <Button className="ant-btn btn-highlight-green-active">{t("action:issue_finway_card")}</Button>

    const footerRow = (firstColumnContent: any) => (
        <Row className="flex justify-between gap-10">
            <Col className="flex grow-0 items-center space-x-10">{firstColumnContent()}</Col>
            <Col className="flex grow-0 items-center space-x-10">
                <Button onClick={onCancel}>{t("action:payment_method.cancel")}</Button>
                <Button type="primary" disabled={!isSaveButtonEnabled} loading={false} onClick={() => formInstance.submit()}>
                    {t("action:payment_method.save")}
                </Button>
            </Col>
        </Row>
    )

    // prettier-ignore
    return (
        <Form form={formInstance} id="payment-method-card-form"/* contains custom styles */ layout="vertical" onFinish={onSubmit} className="m-2" validateTrigger="onBlur">
            <div className="animation-appear">
                <Row gutter={[16, 16]} align="bottom">
                    <Col span={24} md={12}> {cardNumberField} </Col>
                    <Col span={12} md={6}> {expirationDateField} </Col>
                    <Col span={12} md={6}> {cvcField} </Col>
                </Row>
                <Row gutter={[16, 16]} align="bottom">
                    <Col span={12}>{emailField}</Col>
                    <Col span={12}>{secondEmailField}</Col>
                </Row>
                {isEditable && footerRow(issueFinwayCardFooter)}
            </div>
        </Form>
    )
}

export default PaymentMethodCardForm
