import { ExclamationCircleOutlined } from "@ant-design/icons"
import { CsvTemplate } from "@finway-group/shared/lib/models"
import { Button, Divider, Tooltip } from "antd"
import React from "react"
import { Edit as EditIcon } from "react-feather"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

import CsvTemplateModal from "Components/modals/csvTemplate.modal"
import CustomDatevInformationModal from "Components/modals/customDatevInformation.modal"
import { FINWAY_ADMIN_EMAIL } from "Shared/config/consts"
import { useModal } from "Shared/context/modal.context"
import { useCompany } from "Shared/hooks/company.hooks"
import { useLoggedInEmployee } from "Shared/hooks/employee.hooks"
import { useIsCustomCsvTemplatesEnabled } from "Shared/hooks/featureFlags.hooks"
import { AuthzService } from "Shared/services"
import { RootState } from "Shared/store/rootState.interface"
import { getTooltipPopupContainer } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

import { DatevFeatureSettingsList } from "./datev/datevFeatureSettingsList"
import FeatureSettingStatus from "./featureSettingStatus"

const ExportAndBookkeepingTabContent = () => {
    const { t } = useTranslation()
    const { showModal } = useModal()
    const company = useCompany()
    const loggedInUser = useLoggedInEmployee()
    const isCsvTemplatesEnabled = useIsCustomCsvTemplatesEnabled()
    const templates = useSelector(({ csvTemplates: { templates } }: RootState) => templates as Array<CsvTemplate>)
    const [isShowingCustomDatevInfoModal, setIsShowingCustomDatevInfoModal] = useStateIfMounted(false)

    const handleOnTemplateEdit = (templates: Array<CsvTemplate>) => showModal(CsvTemplateModal, false, { templates, isShowing: true })
    const canWrite = AuthzService.canUpdateCompanyFeatureSettings()

    return (
        <div>
            <ul className="description-list">
                {isCsvTemplatesEnabled && (
                    <li>
                        <span className="title w-240 flex items-center">
                            {t("label:csv_settings:custom_csv_template")}
                            <Tooltip getPopupContainer={getTooltipPopupContainer} title={t("tooltips:csv_template_settings")} className="ml-5">
                                <ExclamationCircleOutlined />
                            </Tooltip>
                        </span>
                        {templates.length ? (
                            <span className="flex space-x-12 items-center text">
                                {templates.length <= 3 ? (
                                    <>
                                        <span className="overflow-x-auto w-240"> {templates.map(({ name }) => name).join(", ")}</span>
                                    </>
                                ) : (
                                    <span className="text text-left"> {t("label:csv_settings:total_templates", { numberOfTemplates: templates.length })}</span>
                                )}

                                {canWrite && <EditIcon className="cursor-pointer" size={14} onClick={() => handleOnTemplateEdit(templates)} />}
                            </span>
                        ) : (
                            <Button onClick={() => handleOnTemplateEdit(templates)} disabled={!canWrite}>
                                <span>{t("label:csv_settings:set_up")}</span>
                            </Button>
                        )}
                    </li>
                )}
                <FeatureSettingStatus title={t("label:is_lexware_export_enabled")} isEnabled={company.isLexwareExportEnabled} />
                <FeatureSettingStatus
                    title={t("label:append_audit_trail_to_invoice")}
                    isEnabled={company.isAppendAuditTrailToInvoiceEnabled}
                    tooltipText={t("info:append_audit_trail_to_invoice")}
                />
                {loggedInUser.email === FINWAY_ADMIN_EMAIL ? (
                    <FeatureSettingStatus title={t("label:is_non_sepa_xml_export_enabled")} isEnabled={company.isNonSepaXmlExportEnabled} />
                ) : null}

                {/* TODO enable when we want to include GoB in the forthcoming version */}
                {/* <FeatureSettingStatus title={t("label:gobd.enforce_belegfunktion")} isEnabled={company.gobdCompliance?.enforceGobdCompliantInvoice} /> */}
            </ul>

            <Divider />

            <ul className="description-list">
                <li>{t("label:datev_settings")}</li>
                <DatevFeatureSettingsList />
                <li>
                    <span className="title w-240">
                        <span className="title w-240">{t("label:custom_datev_settings")}</span>
                        <Tooltip getPopupContainer={getTooltipPopupContainer} title={t("tooltips:datev.information_customisation")} placement="right" className="align-middle">
                            <ExclamationCircleOutlined className="ml-6" />
                        </Tooltip>
                    </span>
                    <span className="flex items-center space-x-12 text">
                        {canWrite && <EditIcon className="cursor-pointer" size={14} onClick={() => setIsShowingCustomDatevInfoModal(true)} />}
                    </span>
                    <CustomDatevInformationModal
                        isShowing={isShowingCustomDatevInfoModal}
                        onCancel={() => setIsShowingCustomDatevInfoModal(false)}
                        datevInfo={company?.datevSettings?.customDatevInformation}
                        datevBooking={company?.datevSettings?.datevBookingText}
                    />
                </li>
            </ul>
        </div>
    )
}

export default ExportAndBookkeepingTabContent
