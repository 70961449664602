import * as Sentry from "@sentry/react"
import HttpStatus from "http-status"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"

import { WEAVR_UX_KEY, WEAVR_UX_SCRIPT_URL } from "Shared/config/consts"
import i18n from "Shared/locales/i18n"
import { CorporateService, LocalStorageService, NotificationService } from "Shared/services"
import CardService from "Shared/services/card.service"
import { WEAVR_TOKEN } from "Shared/services/localstorage.service"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import store from "Shared/store"
import { setIsWeavrAuthenticated, setShouldShowWeavrAuthModal } from "Shared/store/actions/auth/authActions"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"
import weavrFormStyle from "Styles/weavr-form-style"

import { useWeavrAuth } from "./auth.hooks"
import { useKYBData } from "./corporate.hooks"

export const useLoadWeavr = () => {
    const [isLoaded, setIsLoaded] = useStateIfMounted(false)

    useEffect(() => {
        let script: HTMLScriptElement
        let scriptInstance: HTMLElement | null

        const loadScript = () => {
            script = document.createElement("script")
            script.id = "weavr_script"
            script.type = "text/javascript"
            script.src = WEAVR_UX_SCRIPT_URL!
            script.async = true
            // Setup onload & onerror listeners before actually loading the script
            script.onload = () => {
                // Initialize Weavr
                // This has failed in the past due to errors in the script
                // If the execution of the script fails OpcUxSecureClient WILL NOT be defined
                window.OpcUxSecureClient!.init(WEAVR_UX_KEY!, {
                    fonts: [
                        {
                            cssSrc: "https://fonts.googleapis.com/css?family=Montserrat",
                            // Should be https://use.typekit.net/ucp6oqc.css (proxima-nova) but weavr secure ux does not understand the css field: font-display, so it will throw error
                        },
                        {
                            cssSrc: "https://fonts.googleapis.com/css2?family=Inter",
                        },
                    ],
                })
                setIsLoaded(true)
            }
            script.onerror = (err) => {
                setIsLoaded(false)
                Sentry.captureException(`[Weavr Hooks] script load error: ${typeof err === "string" ? err : JSON.stringify(err)}`)
            }

            scriptInstance = document.getElementById("weavr_script")
            if (!document.body.contains(scriptInstance)) {
                // Add script to DOM & EXECUTE it
                // After completion onload will be called
                document.body.appendChild(script)
            }
        }

        if (!isLoaded) loadScript()

        return () => {
            if (isLoaded && script && document.body.contains(scriptInstance)) {
                document.body.removeChild(script)
            }
        }
    }, [])
}

/**
 * This hook will inject a Secure UX Password component from Weavr into
 * a DOM element by the given ID.
 * @param elementId ID of the DOM element to inject into
 * @param trigger A trigger to start the injection. Useful for modal windows where it would be the "isShowing" flag.
 * @returns
 */
export const useWeavrPwdInput = (elementId: string, trigger?: boolean) => {
    const [secureForm, setSecureForm] = useStateIfMounted(window?.OpcUxSecureClient?.form())
    const [shouldLoad, setShouldLoad] = useStateIfMounted(trigger === undefined || trigger)

    useEffect(() => {
        if (!secureForm && window?.OpcUxSecureClient) {
            setSecureForm(window?.OpcUxSecureClient?.form())
        }
    }, [window?.OpcUxSecureClient])

    useEffect(() => {
        const loadWeavrPasswordInput = () => {
            // Create an instance of a secure password component that will collect the password
            const input = secureForm.input("pwd", "password", {
                placeholder: "Password",
                maxlength: 20,
            })

            // Embed it into the corresponding div
            input?.mount(document.getElementById(elementId))

            // update state
            setShouldLoad(false)
        }

        if (shouldLoad && secureForm) {
            loadWeavrPasswordInput()
        }
    }, [shouldLoad, secureForm])

    return { secureForm, setShouldLoad }
}

// Passcode is used to perform end-user authentication by using 4-digit number
export const useWeavrPasscodeInput = (elementId: string, trigger?: boolean) => {
    const [secureForm, setSecureForm] = useStateIfMounted(window?.OpcUxSecureClient?.form())
    const [shouldLoad, setShouldLoad] = useStateIfMounted(trigger === undefined || trigger)

    useEffect(() => {
        if (!secureForm && window?.OpcUxSecureClient) {
            setSecureForm(window?.OpcUxSecureClient?.form())
        }
    }, [window?.OpcUxSecureClient])

    useEffect(() => {
        const loadWeavrPasscodeInput = (elem: HTMLElement | null) => {
            // Create an instance of a secure password component that will collect the password
            const input = secureForm.input("passcode", "passCode", {
                placeholder: "Passcode",
                style: {
                    base: {
                        letterSpacing: "1.85ch",
                    },
                    empty: {
                        letterSpacing: "unset",
                    },
                },
            })

            // Embed it into the corresponding div
            input?.mount(elem)

            // update state
            setShouldLoad(false)
        }

        // check for document state every 2s after load then clear itself with injection
        const readyStateCheckInterval = setInterval(() => {
            if (document.readyState === "complete" && shouldLoad && secureForm && document.getElementById(elementId)) {
                clearInterval(readyStateCheckInterval)
                loadWeavrPasscodeInput(document.getElementById(elementId))
            }
        }, 1000)
    }, [shouldLoad, secureForm])

    return { secureForm, setShouldLoad }
}

export const useKYBForm = (containerId: string) => {
    const [isLoading, setIsLoading] = useStateIfMounted(false)
    const [hasLoaded, setHasLoaded] = useStateIfMounted(false)
    const [hasError, setHasError] = useStateIfMounted(false)
    const kybData = useKYBData()
    const { isWeavrAuthenticated } = useWeavrAuth()

    const weavrToken = LocalStorageService.get(WEAVR_TOKEN)

    useEffect(() => {
        const weavrClient = window?.OpcUxSecureClient
        if (!weavrClient || hasLoaded || !kybData.isPasswordCreated) return

        if (!weavrToken) {
            store.dispatch(setShouldShowWeavrAuthModal(true))
            return
        }

        setIsLoading(true)
        CorporateService.getKYBFormToken()
            .then(({ kybToken }) => {
                weavrClient?.associate(
                    `Bearer ${weavrToken}`,
                    () => {
                        // Initialise the KYB UI Component
                        weavrClient?.kyb().init(
                            `#${containerId}`,
                            { reference: kybToken },
                            (messageType, payload) => console.log({ messageType, payload }), // TODO: do we need this?
                            { customCss: weavrFormStyle }, // TODO: custom CSS to brand the UI elements in our style
                        )
                        setHasLoaded(true)
                        setIsLoading(false)
                        setHasError(false)
                    },
                    (err) => {
                        setIsLoading(false)
                        setHasError(true)
                        store.dispatch(setIsWeavrAuthenticated(false))
                        store.dispatch(setShouldShowWeavrAuthModal(true))
                        Sentry.captureException(`[Weavr Hooks] failed to load KYB form error: ${typeof err === "string" ? err : JSON.stringify(err)}`)
                    },
                )
            })
            .catch((err) => {
                setIsLoading(false)
                if (err?.message?.includes(HttpStatus.UNAUTHORIZED)) {
                    store.dispatch(setIsWeavrAuthenticated(false))
                    setHasLoaded(false)
                    store.dispatch(setShouldShowWeavrAuthModal(true))
                } else {
                    NotificationService.showErrorNotificationBasedOnResponseError(err, "Error")
                    Sentry.captureException(`[Weavr Hooks] failed to get KYB token error: ${typeof err === "string" ? err : JSON.stringify(err)}`)
                }
            })
    }, [window?.OpcUxSecureClient, weavrToken, isWeavrAuthenticated])

    return { isLoading, hasError }
}

export const useSecureCreditCardDetails = (card: any, cardNumberContainerId: string, cvvContainerId: string, onSuccess: () => void, onFailed: () => void) => {
    const [isLoading, setIsLoading] = useStateIfMounted(false)
    const [shouldStopLoading, setShouldStopLoading] = useStateIfMounted(false)
    const [hasLoaded, setHasLoaded] = useStateIfMounted(false)

    useEffect(() => {
        if (shouldStopLoading) {
            const timer = setTimeout(() => {
                setIsLoading(!isLoading)
                setShouldStopLoading(false)
            }, 1000) // Give a chance for the cardNumber.mount and cvvSpan.mount to be fully loaded
            return () => clearTimeout(timer)
        }
        return () => {}
    }, [shouldStopLoading])

    const mountCardDetails = async () => {
        const weavrClient = window?.OpcUxSecureClient
        if (!weavrClient || hasLoaded) return

        setIsLoading(true)
        const weavrToken = LocalStorageService.get(WEAVR_TOKEN)

        weavrClient?.associate(
            `Bearer ${weavrToken}`,
            () => {
                const numberSpan = weavrClient.span("cardNumber", card.cardNumberToken, {
                    style: {
                        fontFamily: "Montserrat, sans-serif",
                        height: "25px",
                        fontSize: "18px",
                        color: "#FFFFFF",
                    },
                })
                numberSpan.mount(document.getElementById(cardNumberContainerId))

                const cvvSpan = weavrClient.span("cvv", card.cvvToken)
                cvvSpan.mount(document.getElementById(cvvContainerId))

                setHasLoaded(true)
                setShouldStopLoading(true)
            },
            (err) => {
                setIsLoading(false)
                if (err?.message?.includes(HttpStatus.UNAUTHORIZED)) {
                    store.dispatch(setIsWeavrAuthenticated(false))
                    onFailed()
                    store.dispatch(setShouldShowWeavrAuthModal(true))
                } else {
                    NotificationService.send(NotificationTypeEnum.ERROR, i18n.t("error:cards.weavr_error.title"), i18n.t("error:cards.weavr_error.message"))
                    Sentry.captureException(`[Weavr Hooks] failed to load credit card details error: ${typeof err === "string" ? err : JSON.stringify(err)}`)
                }
            },
        )
    }

    return { isLoading, mountCardDetails, hasLoaded }
}

// Passcode is used to perform end-user authentication by using 4-digit number
export const useWeavrUpdatePasscodeInput = (oldPasscodeContainerId: string, newPasscodeContainerId: string, trigger?: boolean) => {
    const { t } = useTranslation()
    const [secureForm, setSecureForm] = useStateIfMounted(window?.OpcUxSecureClient?.form())
    const [shouldLoad, setShouldLoad] = useStateIfMounted(trigger === undefined || trigger)

    useEffect(() => {
        if (!secureForm && window?.OpcUxSecureClient) {
            setSecureForm(window?.OpcUxSecureClient?.form())
        }
    }, [window?.OpcUxSecureClient])

    useEffect(() => {
        const style = {
            base: {
                letterSpacing: "1.85ch",
            },
            empty: {
                letterSpacing: "unset",
            },
        }

        const loadWeavrPasscodeInput = () => {
            // Create an instance of a secure password component that will collect the password
            const oldPasscodeInput = secureForm.input("oldPasscode", "passCode", {
                placeholder: t("input:passcode.update.old_passcode"),
                style: { ...style },
            })

            const newPasscodeInput = secureForm.input("newPasscode", "passCode", {
                placeholder: t("input:passcode.update.new_passcode"),
                style: { ...style },
            })

            oldPasscodeInput?.mount(document.getElementById(oldPasscodeContainerId))
            newPasscodeInput?.mount(document.getElementById(newPasscodeContainerId))

            // update state
            setShouldLoad(false)
        }

        if (shouldLoad && secureForm) {
            loadWeavrPasscodeInput()
        }
    }, [shouldLoad, secureForm])

    return { secureForm, setShouldLoad }
}

export const useWeavrPhysicalPinDisplay = (card: any, pinDisplayContainerId: string, trigger: boolean, onClose: any) => {
    const [isLoading, setIsLoading] = useStateIfMounted(false)
    const [shouldStopLoading, setShouldStopLoading] = useStateIfMounted(false)
    const [error, setError] = useStateIfMounted(null)
    const [hasLoaded, setHasLoaded] = useStateIfMounted(false)

    useEffect(() => {
        if (shouldStopLoading) {
            const timer = setTimeout(() => {
                setIsLoading(!isLoading)
                setShouldStopLoading(false)
            }, 1000) // Give a chance for the pinSpan.mount to be fully loaded
            return () => clearTimeout(timer)
        }
        return () => {}
    }, [shouldStopLoading])

    const mountPin = async () => {
        const weavrClient = window?.OpcUxSecureClient
        if (!weavrClient) return
        setIsLoading(true)

        try {
            const { pin } = await CardService.retrievePINToken(card._id)

            weavrClient?.associate(
                `Bearer ${LocalStorageService.get(WEAVR_TOKEN)}`,
                () => {
                    const pinSpan = weavrClient.span("cardPin", pin.value, {
                        style: {
                            fontSize: "36px",
                            fontFamily: "Inter, sans-serif",
                            letterSpacing: "15px",
                        },
                    })
                    pinSpan.mount(document.getElementById(pinDisplayContainerId))
                    setHasLoaded(true)
                    setShouldStopLoading(true)
                    setError(null)
                },
                (err) => {
                    if (err?.message?.includes(HttpStatus.UNAUTHORIZED)) {
                        store.dispatch(setIsWeavrAuthenticated(false))
                        setHasLoaded(false)
                    } else {
                        Sentry.captureException(`[Weavr Hooks] failed to load physical PIN display details error: ${typeof err === "string" ? err : JSON.stringify(err)}`)
                    }
                },
            )
        } catch (err) {
            setIsLoading(false)
            NotificationService.showErrorNotificationBasedOnResponseError(err, i18n.t("error:error"))
            onClose?.()
        }
    }

    useEffect(() => {
        if (trigger) mountPin()
    }, [window?.OpcUxSecureClient, card?._id, trigger])

    return { isLoading, error, hasLoaded }
}
