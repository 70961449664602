import { Select, Spin } from "antd"
import { SelectProps } from "antd/lib/select"
import debounce from "lodash/debounce"
import React, { useEffect, useMemo, useRef } from "react"

import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"
import useUpdateEffect from "Shared/utils/hooks/useUpdateEffect"
import { getPopupAnchor } from "Shared/utils/popup.utils"

export interface SelectOption {
    label: string | JSX.Element
    value: string
}

interface FetchOptionsParams {
    id?: string
    search?: string
    disabled?: boolean
}

interface DebounceSelectInterface extends SelectProps<any> {
    fetchOptions: ({ id, search, disabled }: FetchOptionsParams) => Promise<Array<SelectOption>>
    fetchOptionsDependency?: any // Change on this will trigger options re-fetch
    fetchTimeout?: number
}

export const DebounceSelect: React.FC<DebounceSelectInterface> = ({ fetchOptions, fetchTimeout: debounceTimeout = 1000, fetchOptionsDependency, ...props }) => {
    const [fetching, setFetching] = useStateIfMounted(true)
    const [initial, setInitial] = useStateIfMounted(true)
    const [options, setOptions] = useStateIfMounted<Array<SelectOption>>([])

    const fetchRef = useRef(0)
    const loadOptions = (isId: boolean) => (value: any) => {
        fetchRef.current += 1
        const fetchId = fetchRef.current
        setOptions([])
        setFetching(true)
        fetchOptions(isId ? { id: value } : { search: value })
            .then((newOptions) => {
                // for fetch callback order
                if (fetchId !== fetchRef.current) return

                setOptions(newOptions)
            })
            .finally(() => {
                // for fetch callback order
                if (fetchId !== fetchRef.current) return

                setFetching(false)

                setInitial(false)
            })
    }

    // This prevents having a stale state on re-renders
    useEffect(() => {
        const loadOptionsById = loadOptions(true)
        loadOptionsById(props.value)
    }, [props.value])

    // This refetches the options if the dependency used by fetchOption changes
    useUpdateEffect(() => {
        const loadOptionFn = loadOptions(false)
        loadOptionFn(undefined)
    }, [fetchOptionsDependency])

    const debounceFetcher = useMemo(() => {
        const loadOptionsByName = loadOptions(false)
        return debounce(loadOptionsByName, debounceTimeout)
    }, [fetchOptions, debounceTimeout])

    if (initial) return <Select disabled={true} loading={true} />

    return (
        <Select
            loading={fetching}
            disabled={fetching}
            value={props.value}
            filterOption={false}
            showSearch={true}
            onFocus={() => {
                if (options.length === 0) debounceFetcher(undefined)
            }}
            onSearch={debounceFetcher}
            notFoundContent={fetching ? <Spin size="small" /> : null}
            options={options}
            {...props}
            getPopupContainer={getPopupAnchor()}
        />
    )
}
