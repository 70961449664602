import { Button, Tooltip } from "antd"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { RefreshCw } from "react-feather"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import PageTitle from "Components/page/pageTitle"
import { HotKey, useHotkey } from "Shared/hooks/shortcut.hook"
import { NotificationService, UserService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { updateAnalytics } from "Shared/store/actions/analytics/analyticsActions"
import { syncEmployee } from "Shared/store/actions/employee/employeeActions"
import { getTooltipPopupContainer } from "Shared/utils/helper.utils"

import AnalyticsBadges from "./analyticsBadges"
import CostCenterAnalytics from "./costCenterAnalytics"
import EmployeeAnalytics from "./employeeAnalytics"
import ExpensesOverTimeAnalytics from "./expensesOverTimeAnalytics"

const MIN_UPDATE_WAIT_MINS = 10

export const Reports: React.FC<{}> = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const loggedInUser = UserService.getLoggedInEmployeeProfile()
    const [lastRefresh, setLastRefresh] = useState(moment())
    useHotkey(HotKey.F5, () => updateData())

    useEffect(() => {
        // To make sure that the employee information in reporting is correct
        syncEmployee(loggedInUser.id)(dispatch)
    }, [])

    const updateData = () => {
        const minsUntilNextUpdateAllowed = MIN_UPDATE_WAIT_MINS - moment().diff(lastRefresh, "minutes")
        if (minsUntilNextUpdateAllowed <= 0) {
            try {
                NotificationService.send(NotificationTypeEnum.INFO, t("notification:reports.refresh_started.title"), t("notification:reports.refresh_started.message"))
                dispatch(updateAnalytics())
            } finally {
                setLastRefresh(moment())
            }
        } else {
            NotificationService.send(
                NotificationTypeEnum.WARNING,
                t("notification:reports.refresh_forbidden.title"),
                t("notification:reports.refresh_forbidden.message", { time1: MIN_UPDATE_WAIT_MINS, time2: minsUntilNextUpdateAllowed }),
            )
        }
    }

    return (
        <div>
            <PageTitle titleKey="view:reporting.title">
                <Tooltip getPopupContainer={getTooltipPopupContainer} title={t(`tooltips:reports.refresh`)} placement="bottom">
                    <Button type="primary" onClick={() => updateData()}>
                        <RefreshCw />
                    </Button>
                </Tooltip>
            </PageTitle>

            <AnalyticsBadges />

            <ExpensesOverTimeAnalytics />

            <CostCenterAnalytics />

            <EmployeeAnalytics />
        </div>
    )
}

export default Reports
