import { LoginInput } from "@finway-group/shared/lib/models"
import { Button, Form, Input } from "antd"
import React, { useRef, useState } from "react"
import ReCAPTCHA from "react-google-recaptcha"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

import SocialLogins from "Features/authentication/components/SocialLogins"

import { EmailFormRule } from "./rules"
import PasswordFormRule from "./rules/password.rule"

type LoginFormType = {
    loading: boolean
    attemptCount: number
    onSubmit(loginInput: LoginInput): void
}

export const LoginForm = ({ loading, attemptCount, onSubmit }: LoginFormType) => {
    const [form] = Form.useForm()
    const { t } = useTranslation()
    let reCaptchaRef: any = useRef<ReCAPTCHA>()

    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)

    const rules = { password: PasswordFormRule, email: EmailFormRule }

    const isReCaptchaVisible = attemptCount > 0 && attemptCount <= 2

    // Fires only if the form validation passes
    const handleFinish = (values: any) => {
        if (isReCaptchaVisible) {
            if (!values.recaptchaToken) return
        }

        onSubmit(values as LoginInput)
        if (reCaptchaRef && reCaptchaRef.reset) {
            ;(reCaptchaRef as ReCAPTCHA)?.reset()
        }
    }

    const handleValuesChange = (_changedFields: any, allFields: any) =>
        setIsSubmitDisabled(!allFields.email || !allFields.email.length || !allFields.password || !allFields.password.length || (!allFields.recaptchaToken && isReCaptchaVisible))

    return (
        <Form data-testid="loginForm" form={form} onFinish={handleFinish} layout="vertical" onValuesChange={handleValuesChange}>
            <Form.Item
                label={
                    <label data-testid="emailLabel" className="p-0">
                        {t("input:work_email")}
                    </label>
                }
                name="email"
                rules={rules.email}
            >
                <Input
                    className="border-1 border-gray-500 rounded-lg outline-login min-h-40"
                    data-testid="emailInput"
                    type="email"
                    name="email"
                    autoComplete="username"
                    placeholder={t("placeholder:work_email")}
                />
            </Form.Item>

            <Form.Item
                label={
                    <label data-testid="passwordLabel" className="p-0">
                        {t("input:password")}
                    </label>
                }
                name="password"
                rules={rules.password}
                extra={
                    <Link data-testid="forgotPasswordLink" className="finway-forgot-password finway-forgot-password-link text-sm" to="/forgot-password">
                        {t("nav:forgot_password")}
                    </Link>
                }
                className="pb-10"
            >
                <Input.Password
                    data-testid="passwordInput"
                    className="border-1 border-gray-500 rounded-lg outline-login min-h-32 py-2 mb-10"
                    type="password"
                    name="password"
                    placeholder={t("placeholder:password")}
                    autoComplete="current-password"
                />
            </Form.Item>

            {isReCaptchaVisible && (
                <Form.Item name="recaptchaToken">
                    <ReCAPTCHA
                        ref={(ref) => {
                            reCaptchaRef = ref
                        }}
                        sitekey={process.env.GOOGLE_RECAPTCHA_SITE_KEY || ""}
                        onChange={(token: string | null) => form.setFieldsValue({ ...form.getFieldsValue(), recaptchaToken: token })}
                        onExpired={() => form.setFieldsValue({ ...form.getFieldsValue(), recaptchaToken: null })}
                    />
                </Form.Item>
            )}

            <Form.Item className="m-0">
                <Button
                    data-testid="loginButton"
                    htmlType="submit"
                    size="large"
                    className="w-full rounded-lg font-medium bg-login-button"
                    type="primary"
                    loading={loading}
                    disabled={isSubmitDisabled}
                >
                    {t("action:login")}
                </Button>
            </Form.Item>

            <SocialLogins />
        </Form>
    )
}

export default LoginForm
