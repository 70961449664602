import i18n from "i18next"

import { parseCurrencyInput } from "Components/currencyInput/config"

import { WorkflowFormValuesInterface } from "../workflowForm/workflowFormValues.interface"
import { allowlistCharValidator } from "./utils.rules"

export const generateWorkflowFormRules = () => {
    const amountRule = [
        {
            validator: (_rule: any, triggerTypeAmount: WorkflowFormValuesInterface["triggerTypes"][0]["amount"]) => {
                const parsedTriggerTypeAmount = parseCurrencyInput(triggerTypeAmount)
                if (parsedTriggerTypeAmount <= 0) {
                    return Promise.reject(new Error(i18n.t("validation:greater.number_short", { min: 0 })))
                }

                if (!parsedTriggerTypeAmount || parsedTriggerTypeAmount === 0) {
                    return Promise.reject(new Error(i18n.t("validation:greater.number_short", { min: 0 })))
                }

                return Promise.resolve()
            },
        },
    ]

    return {
        name: [{ required: true, message: i18n.t("validation:required"), trigger: "blur" }, allowlistCharValidator],
        requestTypes: [{ required: true, message: i18n.t("validation:required") }],
        triggerType: {
            condition: [{ required: true }],
            referenceIds: [{ required: true, message: i18n.t("validation:required") }, { type: "array" }],
            amount: amountRule,
            currency: [{ required: true }],
        },
        step: {
            approver: [{ required: true, message: i18n.t("validation:required") }],
            otherApprovers: [{ required: true, message: i18n.t("validation:required") }, { type: "array" }],
            amount: amountRule,
            currency: [{ required: true }],
        },
        recalculateApprovalProcessesInProgress: [{ type: "boolean" }],
    }
}
