import { BudgetPeriodEnum, CurrencyEnum, Expense } from "@finway-group/shared/lib/models"
import axios from "axios"

import store from "Shared/store"

interface TimeInterval {
    start?: Date
    end?: Date
}

const MODEL = "reports"

const AnalyticsService = {
    getEmployeeInterval: () => store.getState().analytics?.employeeInterval,
    getExpensesPerCostCenterOverLast12MonthsFilter: () => store.getState().analytics?.expensesPerCostCenterOverLast12MonthsFilter,
    getCostCenterInterval: () => store.getState().analytics?.costCenterInterval,
    getCostCenterIntervalPie: () => store.getState().analytics?.costCenterIntervalPie,
    getExpensesPerDepartmentIntervalPie: () => store.getState().analytics?.expensesPerDepartmentIntervalPie,
    fetchOverviewData: async (destCurrency: CurrencyEnum) => {
        const { data } = await axios.post(`${MODEL}/overview`, { destCurrency })
        return data
    },
    fetchExpensesPerCostCenterOverLast12Months: async (ids: Array<string>, destCurrency: CurrencyEnum) => {
        const { data } = await axios.post(`${MODEL}/expenses/costcenter/overtime`, { ids, destCurrency })
        return data
    },
    fetchExpenseDataForGroup: async ({
        groupingField,
        ids,
        destCurrency,
        timeInterval,
        abortController,
    }: {
        groupingField: string
        ids: Array<string>
        destCurrency: CurrencyEnum
        timeInterval?: TimeInterval
        abortController?: AbortController
    }) => {
        const { data } = await axios.post(
            `${MODEL}/expenses/grouped`,
            { groupingField, ids, destCurrency, timeInterval },
            { ...(!!abortController && { signal: abortController?.signal }) },
        )
        return data
    },
    fetchExpenseDataForGroupByTeam: async (ids: Array<string>, destCurrency: CurrencyEnum, timeInterval?: TimeInterval) => {
        const { data } = await axios.post(`${MODEL}/expenses/grouped-by-team`, { ids, destCurrency, timeInterval })
        return data
    },
    fetchExpenseDataForGroupByCostCenterSplits: async (ids: Array<string>, destCurrency: CurrencyEnum, timeInterval?: TimeInterval) => {
        const { data } = await axios.post(`${MODEL}/expenses/grouped-by-cost-center-splits`, { ids, destCurrency, timeInterval })
        return data
    },
    fetchCostCenterBudgetData: async (ids: Array<string>, destCurrency: CurrencyEnum) => {
        const { data } = await axios.post(`${MODEL}/costcenters/budget`, { ids, destCurrency })
        return data
    },
    fetchCostCenterBudgetUsageData: async (id: string, startDate: string, endDate: string, viewPeriod?: BudgetPeriodEnum) => {
        const { data } = await axios.post<any>(`${MODEL}/costcenters/${id}/budget-usage`, { startDate, endDate, viewPeriod })
        return data
    },
    fetchCostCenterDataForEmployee: async (employeeId: string, destCurrency: CurrencyEnum) => {
        const { data } = await axios.post(`${MODEL}/costcenters/employee/${employeeId}`, { destCurrency })
        return data
    },
    fetchCardSpending: async (ids: Array<string>, destCurrency: CurrencyEnum) => {
        const { data } = await axios.post(`${MODEL}/cards/spendings`, { ids, destCurrency })
        return data
    },
    fetchCardOverviewData: async (destCurrency: CurrencyEnum) => {
        const { data } = await axios.post(`${MODEL}/cards/overview`, { destCurrency })
        return data
    },
    fetchPerformanceExpenses: async (id: string, page: number, limit: number, query: string, startDate: string, endDate: string) => {
        const { data } = await axios.post<{ docs: Array<Expense>; totalPages: number; totalDocs: number }>(
            `${MODEL}/costcenters/${id}/performance-expenses?page=${page}&limit=${limit}${query}`,
            { startDate, endDate },
        )
        data.docs = data.docs.map((expense) => new Expense(expense))
        return data
    },
    fetchTeams: async () => {
        const { data } = await axios.get(`${MODEL}/teams`)
        return data
    },
}

export default AnalyticsService
