import Icon from "@ant-design/icons"
import React from "react"

const newEmployee = () => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="32" height="32" rx="16" fill="#ECF3FF" />
        <path
            d="M19.7156 14.9998C20.767 14.9998 21.6127 14.1465 21.6127 13.0951C21.6127 12.0436 20.767 11.1903 19.7156 11.1903C18.6642 11.1903 17.8108 12.0436 17.8108 13.0951C17.8108 14.1465 18.6642 14.9998 19.7156 14.9998ZM14.0013 14.2379C15.2661 14.2379 16.2794 13.217 16.2794 11.9522C16.2794 10.6875 15.2661 9.6665 14.0013 9.6665C12.7365 9.6665 11.7156 10.6875 11.7156 11.9522C11.7156 13.217 12.7365 14.2379 14.0013 14.2379ZM19.7156 16.5236C18.3213 16.5236 15.5251 17.2246 15.5251 18.6189V19.5713C15.5251 19.9903 15.868 20.3332 16.287 20.3332H23.1442C23.5632 20.3332 23.9061 19.9903 23.9061 19.5713V18.6189C23.9061 17.2246 21.1099 16.5236 19.7156 16.5236ZM14.0013 15.7617C12.2261 15.7617 8.66797 16.6532 8.66797 18.4284V19.5713C8.66797 19.9903 9.01083 20.3332 9.42987 20.3332H14.0013V18.6189C14.0013 17.9713 14.2527 16.836 15.807 15.9751C15.1442 15.8379 14.5042 15.7617 14.0013 15.7617Z"
            fill="#3886FF"
        />
    </svg>
)

const NewEmployeeIcon = (props: any) => <Icon component={newEmployee} {...props} />

export default NewEmployeeIcon
