import { ExclamationCircleTwoTone } from "@ant-design/icons"
import { COLOR } from "@finway-group/shared/lib/consts"
import { ActualForecastType, AntdTableCompatibleCashFlow, CashFlowRowIndexEnum, CurrencyEnum, PriceIntervalEnum } from "@finway-group/shared/lib/models"
import { Tooltip } from "antd"
import moment from "moment"
import React, { useEffect } from "react"
import { Trans, useTranslation } from "react-i18next"

import CurrencyInput from "Components/currencyInput"
import PriceLabel from "Components/priceLabel"
import { useForecastRules } from "Shared/hooks/liquidity.hooks"
import { getTooltipPopupContainer, roundNumber } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

interface ForecastDataPropInterface {
    value: ActualForecastType
    currency: CurrencyEnum
    periodIndex: string
    handleForecastChange: (periodIndex: string, record: AntdTableCompatibleCashFlow, newValue: number) => void
    record: AntdTableCompatibleCashFlow
}

const ForecastData = ({ value, periodIndex, handleForecastChange, record, currency }: ForecastDataPropInterface) => {
    const { t } = useTranslation()

    const [editMode, setEditMode] = useStateIfMounted<boolean>(false)
    const [forecastValue, setForecastValue] = useStateIfMounted<number>(roundNumber(value.forecast))

    const forecastRules = useForecastRules()

    const ruleBasedForecast = value.forecastRules?.reduce((sum: number, a: string) => {
        const forecastRule = forecastRules.find((f) => f._id === a)
        if (forecastRule) return sum + forecastRule.amount * forecastRule.quantity
        return sum
    }, 0)

    const manualForecast = value.forecast - ruleBasedForecast

    useEffect(() => {
        setForecastValue(roundNumber(value.forecast))
    }, [value])

    const onValueSubmission = () => {
        setEditMode(false)
        if (forecastValue !== roundNumber(value.forecast)) handleForecastChange(periodIndex, record, forecastValue)
    }

    const warningCondition =
        ruleBasedForecast &&
        ((record.rowIndex === CashFlowRowIndexEnum.TOTAL_SPENDING && forecastValue > ruleBasedForecast) ||
            (record.rowIndex === CashFlowRowIndexEnum.TOTAL_INCOME && forecastValue < ruleBasedForecast))

    const tooltipOnEdit = {
        visible: warningCondition,
        title: (
            <div className="flex items-center mr-12">
                <ExclamationCircleTwoTone size={12} />
                <div className="ml-3 mt-3 text-text-dark">{t("label:liquidity.forecast_conflict_warning")}</div>
            </div>
        ),
        color: COLOR.white,
        trigger: ["focus"],
    }

    const tooltipOnValue = {
        title: warningCondition ? (
            <p className="text-black">
                <Trans
                    t={t}
                    i18nKey={"label:liquidity.forecast_conflict_tooltip_message"}
                    components={[<PriceLabel value={ruleBasedForecast} currency={currency} interval={PriceIntervalEnum.ONE_TIME} />]}
                />
            </p>
        ) : (
            <>
                <p className="text-black">
                    <span className="mr-6 font-bold text-text-dark"> {<PriceLabel value={ruleBasedForecast} currency={currency} interval={PriceIntervalEnum.ONE_TIME} />}</span>
                    {t("label:liquidity.rule_based_value")}
                </p>
                <p className="text-black">
                    <span className="mr-6 font-bold text-text-dark"> {<PriceLabel value={manualForecast} currency={currency} interval={PriceIntervalEnum.ONE_TIME} />}</span>
                    {t("label:liquidity.manually_added_value")}
                </p>
                <p className="text-text">
                    <span>{moment(periodIndex, "YYYYMM").format("MMM YYYY")}</span>
                </p>
            </>
        ),
        color: COLOR.finway["alice-blue"],
        trigger: ["hover"],
    }
    return record.childrenIds?.length === 0 && !record.isGroup ? (
        <Tooltip getPopupContainer={getTooltipPopupContainer} {...(editMode ? tooltipOnEdit : tooltipOnValue)} mouseEnterDelay={1} destroyTooltipOnHide>
            <div>
                {editMode ? (
                    <CurrencyInput
                        currency={currency}
                        value={forecastValue}
                        onChange={(v) => {
                            setForecastValue(v)
                        }}
                        onBlur={onValueSubmission}
                        isAutoFocused
                        className="w-80"
                    />
                ) : (
                    <p className="cursor-pointer" onClick={() => setEditMode(true)}>
                        <PriceLabel value={forecastValue} currency={currency} interval={PriceIntervalEnum.ONE_TIME} />
                    </p>
                )}
            </div>
        </Tooltip>
    ) : (
        <p className="cursor-text">
            <PriceLabel value={forecastValue} currency={currency} interval={PriceIntervalEnum.ONE_TIME} />
        </p>
    )
}

export default React.memo(ForecastData)
