import { textNoWhiteSpacesRegex } from "@finway-group/shared/lib/utils/validators"
import { FormInstance, Rule } from "antd/lib/form"
import i18n from "i18next"

import { countInArray, isNotSet } from "Shared/utils/helper.utils"

import { allowlistCharValidator } from "./utils.rules"

const TaxRules = (formInstance: FormInstance): { [key: string]: Array<Rule> } => ({
    taxRate: [
        {
            validator: (_rule: any, value: string) => {
                if (isNotSet(value)) return Promise.reject(new Error(i18n.t("validation:required")))

                if (!textNoWhiteSpacesRegex.test(value)) {
                    return Promise.reject(new Error(i18n.t("validation:string_white_space")))
                }

                return Promise.resolve()
            },
        },
        { transform: (v) => Number(v), type: "number", message: i18n.t("validation:numeric") },
        { transform: (v) => Number(v), type: "number", max: 100, message: i18n.t("validation:max.number", { max: 100 }) },
        { transform: (v) => Number(v), type: "number", min: 0, message: i18n.t("validation:min.number", { min: 0 }) },
    ],
    buCode: [
        {
            validator: (_rule: any, value: number | string) => {
                const buCodes = formInstance.getFieldsValue().taxes.map((e: any) => e.buCode)
                if (countInArray(buCodes, value) >= 2) {
                    return Promise.reject(i18n.t("validation:duplicate", { value: i18n.t("validation:bu_code") }))
                }

                if (value && !textNoWhiteSpacesRegex.test(value.toString())) {
                    return Promise.reject(new Error(i18n.t("validation:string_white_space")))
                }

                return Promise.resolve()
            },
        },
    ],
    buCodeName: [allowlistCharValidator],
})

export default TaxRules
