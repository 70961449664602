import { MutableRefObject, useRef } from "react"

interface FormTouchedHandlerInterface {
    isFormTouched: MutableRefObject<boolean>
    handleTouchForm: () => void
    handleResetTouchForm: () => void
}

export const useFormTouchedHandler = (isFormTouchedInitialValue: boolean = false): FormTouchedHandlerInterface => {
    const isFromTouchedRef = useRef(isFormTouchedInitialValue)

    const handleTouchForm = () => {
        isFromTouchedRef.current = true
    }
    const handleResetTouchForm = () => {
        isFromTouchedRef.current = false
    }

    return { isFormTouched: isFromTouchedRef, handleTouchForm, handleResetTouchForm }
}
