import { ExpenseTag, ExpenseTagColorEnum } from "@finway-group/shared/lib/models"
import { Dispatch } from "redux"

import { ExpenseTagService } from "Shared/services"

import { attachTagExpense } from "../expense/expenseActions"
import { DetachTagExpenseAction, ExpenseActionTypes } from "../expense/expenseTypes"
import { CancelFetchAction, FetcherActionTypes } from "../fetcher/fetcherTypes"
import { CreateExpenseTagAction, DeleteExpenseTagAction, ExpenseTagActionTypes, FetchAllExpenseTagsAction } from "./expenseTagTypes"

export const fetchAllExpenseTags = () => async (dispatch: Dispatch) => {
    const { docs: expenseTags } = await ExpenseTagService.fetchAllExpenseTags()

    const cancelFetchAction: CancelFetchAction = {
        type: FetcherActionTypes.CANCEL_FETCH,
        isFetchCancelled: true,
    }

    dispatch(cancelFetchAction)

    const fetchAllExpenseTagsAction: FetchAllExpenseTagsAction = {
        type: ExpenseTagActionTypes.FETCH_ALL_EXPENSE_TAGS,
        expenseTags,
    }

    dispatch(fetchAllExpenseTagsAction)

    return expenseTags
}

export const createExpenseTag =
    (data: { tagName: string; tagColor: ExpenseTagColorEnum }, expenseId?: string, idCallbackFunction?: (id: string) => void) => async (dispatch: Dispatch) => {
        const expenseTag: ExpenseTag = await ExpenseTagService.createExpenseTag(data)

        const createExpenseTagAction: CreateExpenseTagAction = {
            type: ExpenseTagActionTypes.CREATE_EXPENSE_TAG,
            expenseTag,
        }

        dispatch(createExpenseTagAction)

        if (expenseId) await attachTagExpense(expenseTag._id, expenseId)(dispatch)
        idCallbackFunction?.(expenseTag._id)
    }

export const deleteExpenseTag = (expenseTagId: string) => async (dispatch: Dispatch) => {
    await ExpenseTagService.deleteExpenseTag(expenseTagId)

    const deleteExpenseTagAction: DeleteExpenseTagAction = {
        type: ExpenseTagActionTypes.DELETE_EXPENSE_TAG,
        expenseTagId,
    }

    dispatch(deleteExpenseTagAction)

    const detachTagExpenseAction: DetachTagExpenseAction = {
        type: ExpenseActionTypes.DETACH_TAG_EXPENSE,
        expenseTagId,
    }

    dispatch(detachTagExpenseAction)
}
