import { LoadingOutlined as LoadingIcon } from "@ant-design/icons"
import { BankConnection, RightEnum } from "@finway-group/shared/lib/models"
import { Modal } from "antd"
import React from "react"
import { Plus as PlusIcon } from "react-feather"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"

import BankAccount from "Components/bankAccount"
import BankConnectionModal from "Components/modals/bankConnection.modal"
import BankSearchModal from "Components/modals/bankSearch.modal"
import SettingsLayout, { SettingsButton } from "Features/settings/settings.layout"
import { useModal } from "Shared/context/modal.context"
import { useBankConnections } from "Shared/hooks/bank.hooks"
import { AuthzService, BankService, NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { fetchAllBankConnections, setBankConnection } from "Shared/store/actions/bank/bankActions"
import { refetchTable } from "Shared/store/actions/tables/tableActions"
import { TablesEnum } from "Shared/store/reducers/tableConfigReducer"
import { RootState } from "Shared/store/rootState.interface"

const BankSettings = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { showModal } = useModal()

    const bankConnections = useBankConnections({ shouldFilterDeleted: true })
    const actionInProgress = useSelector(({ banks }: RootState) => banks.actionInProgress)

    const onEditBankConnection = (id: string) => {
        const connectionToEdit = bankConnections.find((bankConnection: BankConnection) => bankConnection._id === id)
        dispatch(setBankConnection(connectionToEdit))
        showModal(BankConnectionModal, false, { isShowing: true })
    }

    const onDeleteBankConnection = (id: string) => {
        Modal.confirm({
            title: t("confirm:banking.delete.title"),
            content: (
                <>
                    <p>{t("confirm:banking.delete.message")}</p>
                    <p className="pt-10">{t("confirm:banking.delete.warning")}</p>
                </>
            ),
            okText: t("confirm:banking.delete.confirm"),
            cancelText: t("confirm:banking.delete.cancel"),
            okType: "danger",
            onOk() {
                return BankService.deleteBankConnection(id)
                    .then(() => {
                        NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:banking.deleted.title"), t("notification:banking.deleted.message"))
                    })
                    .catch((err) => {
                        NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:banking.delete.title"))
                    })
                    .finally(async () => {
                        // refetch bank connections in store
                        dispatch(fetchAllBankConnections())
                        // refetch transactions
                        dispatch(refetchTable(TablesEnum.TRANSACTIONS_INFLOW))
                        dispatch(refetchTable(TablesEnum.TRANSACTIONS_OUTFLOW))
                        dispatch(refetchTable(TablesEnum.TRANSACTIONS_ALL))
                    })
            },
        })
    }

    const searchBank = () => showModal(BankSearchModal, false, { isShowing: true })

    return (
        <SettingsLayout
            titleKey="label:bank_accounts"
            titleExtra={
                <>
                    {actionInProgress && (
                        <span className="ml-20">
                            <LoadingIcon className="mr-10" /> {t("info:action_in_progress")}
                        </span>
                    )}
                </>
            }
            buttons={
                <>
                    {AuthzService.isRightGrantedForLoggedInUser(RightEnum.BANK_ACCOUNT__ALL__CREATE) && (
                        <SettingsButton icon={<PlusIcon />} onClick={searchBank} labelKey="action:banking.add_bank_account" />
                    )}
                </>
            }
        >
            <div className="w-full overflow-y-auto overflow-x-hidden h-screen md:max-h-450">
                {bankConnections.length === 0 ? (
                    <div className="text-text-dark">{t("missing:no_bank_accounts")}</div>
                ) : (
                    bankConnections.map((con: BankConnection) => (
                        <BankAccount key={con._id} bankConnection={con} onEditBankConnection={onEditBankConnection} onDeleteBankConnection={onDeleteBankConnection} />
                    ))
                )}
            </div>
        </SettingsLayout>
    )
}

export default BankSettings
