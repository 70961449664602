import { LoadingOutlined } from "@ant-design/icons"
import { COLOR } from "@finway-group/shared/lib/consts"
import { Expense, RightEnum } from "@finway-group/shared/lib/models"
import { Empty, Table } from "antd"
import { ColumnsType } from "antd/lib/table"
import React from "react"
import { Check as CheckedIcon, X as XIcon } from "react-feather"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"

import NoSearchDataFound from "Components/NoSearchDataFound"
import AmountColumn from "Components/expenseAmountColumn"
import ExpenseReminderInfo from "Components/expenseReminderInfo"
import { ErrorTable } from "Components/layout/errorTable"
import Loading from "Components/loading"
import RequestColumn from "Components/requestColumn"
import ExpandTripFolderTableIcon from "Components/tables/expandTripFolderTableIcon"
import { useSelectedExpenses } from "Shared/context/selectedExpenses.context"
import { useFetchTable, useTable, useTableFilterAndSearchLoading, useTableSearchString } from "Shared/hooks/table.hooks"
import { useLoggedInEmployeeProfile } from "Shared/hooks/user.hooks"
import { AuthzService } from "Shared/services"
import { setExpense } from "Shared/store/actions/expense/expenseActions"
import { refetchTable, updateTableOnChange } from "Shared/store/actions/tables/tableActions"
import { TablesEnum } from "Shared/store/reducers/tableConfigReducer"
import { getStatusLabel } from "Shared/utils/expense.utils"
import { insertIf } from "Shared/utils/helper.utils"
import { getSortOrderForColumn } from "Shared/utils/table.utils"

interface DoneOrAllTabInterface {
    tablesEnum: TablesEnum
}

const DoneOrAllTab: React.FC<DoneOrAllTabInterface> = ({ tablesEnum }) => {
    const { t } = useTranslation()
    const history = useHistory()
    const dispatch = useDispatch()
    const loggedInUser = useLoggedInEmployeeProfile()
    const dashboardSearchString = useTableSearchString(tablesEnum)
    const commonTable = useTable<Expense>(tablesEnum)
    const tabLoading = useTableFilterAndSearchLoading(tablesEnum)
    const [selectedExpenses, setSelectedExpenses] = useSelectedExpenses()

    useFetchTable(tablesEnum)
    const renderCheckedOrPlusIconColumn = (isChecked: boolean) => (
        <div className="ml-30">{isChecked ? <CheckedIcon color={COLOR.finway.green} /> : <XIcon color={COLOR.finway.red} />}</div>
    )

    //* If any of the table data have children adjust the table design accordingly otherwise remove extras spaces provided for the dropdown icon
    const expandableColumn = commonTable.data.docs.some((expense) => (expense as any)?.children && (expense as any)?.children.length > 0)
        ? [
              {
                  dataIndex: "expandIcon",
                  width: 30,
                  align: "center",
              } as any,
          ]
        : []

    const columns: ColumnsType<Expense> = [
        ...expandableColumn,
        {
            title: t("label:request"),
            key: "expenseNumber",
            align: "left",
            className: "pl-10",
            width: 360,
            ...(loggedInUser.settings.showExpenseNumbers && {
                sorter: true,
                sortOrder: getSortOrderForColumn(commonTable, "expenseNumber"),
            }),
            render: (data: Expense) => <RequestColumn request={data} />,
        },
        {
            key: "reminder",
            align: "center",
            width: 50,
            render: (request: Expense) => <ExpenseReminderInfo request={request} />,
        },
        ...insertIf(AuthzService.isRightGrantedForLoggedInUser(RightEnum.EXPENSE__ALL__REVIEW), {
            title: t("label:invoice"),
            key: "invoices",
            width: 100,
            align: "center",
            sorter: true,
            sortOrder: getSortOrderForColumn(commonTable, "invoices"),
            render: ({ invoices }: Expense) => renderCheckedOrPlusIconColumn(!!invoices.length),
        }),
        ...insertIf(AuthzService.isRightGrantedForLoggedInUser(RightEnum.TRANSACTION__ALL__READ), {
            title: t("label:transaction"),
            key: "matchedTransactions",
            width: 110,
            align: "center",
            sorter: true,
            sortOrder: getSortOrderForColumn(commonTable, "matchedTransactions"),
            render: (data: Expense) => renderCheckedOrPlusIconColumn(data.transactionState !== null),
        }),
        {
            title: t("label:status"),
            key: "status",
            width: 120,
            align: "left",
            sorter: true,
            sortOrder: getSortOrderForColumn(commonTable, "status"),
            render: (data: Expense) => <div>{getStatusLabel(data.status)}</div>,
        },
        {
            title: loggedInUser.settings.showGrossAmount ? t("label:gross_amount") : t("label:net_amount"),
            key: loggedInUser.settings.showGrossAmount ? "totalGrossPrice" : "totalNetPrice",
            width: 180,
            align: "right",
            className: "pr-10",
            sorter: true,
            sortOrder: getSortOrderForColumn(commonTable, loggedInUser.settings.showGrossAmount ? "totalGrossPrice" : "totalNetPrice"),
            render: (data: Expense) => <AmountColumn request={data} showGross={loggedInUser.settings.showGrossAmount} showDiscountWhenAvailable />,
        },
    ]

    return (
        <>
            {tabLoading ? (
                <Loading />
            ) : (
                <div>
                    {commonTable.error ? (
                        <ErrorTable onTableReload={() => dispatch(refetchTable(tablesEnum))} isLoading={commonTable.isFetching} />
                    ) : commonTable.data.totalDocs === 0 && !commonTable.isFetching ? (
                        dashboardSearchString ? (
                            <div className="animation-appear">
                                <NoSearchDataFound />
                            </div>
                        ) : (
                            <Empty
                                className="animation-appear"
                                image={`./icons/empty_table.svg`}
                                imageStyle={{
                                    height: 150,
                                    marginRight: "auto",
                                    marginLeft: "auto",
                                    marginTop: "15%",
                                    marginBottom: "40px",
                                    display: "inline-block",
                                }}
                                description={<span className="text-lg font-bold mt-2 mb-0">{t("info:empty_state.all.title")}</span>}
                            >
                                <p>{t("info:empty_state.all.message")}</p>
                            </Empty>
                        )
                    ) : (
                        <div className="overflow-auto mb-24 p-2 animation-appear">
                            <Table<Expense>
                                rowKey={(record) => record.id}
                                rowSelection={{
                                    type: "checkbox",
                                    renderCell: (_value, _record, _index, originNode) => (
                                        <div onClick={(e) => e.stopPropagation()} className="bigger-hitbox-checkbox">
                                            {originNode}
                                        </div>
                                    ),
                                    selectedRowKeys: selectedExpenses.map((record) => record.id),
                                    onChange: (_selectedRowKeys, selectedRows) => setSelectedExpenses(selectedRows),
                                    onSelect: (record, _selected, _selectedRow) => setSelectedExpenses([...selectedExpenses, record]),
                                }}
                                dataSource={commonTable.data.docs}
                                columns={columns}
                                onChange={updateTableOnChange(tablesEnum, commonTable.data)}
                                loading={{
                                    spinning: commonTable.isFetching,
                                    indicator: <LoadingOutlined style={{ fontSize: 30, color: "black" }} spin />,
                                }}
                                pagination={{
                                    position: ["bottomRight"],
                                    showSizeChanger: true,
                                    current: commonTable.data.page,
                                    pageSize: commonTable.data.limit,
                                    hideOnSinglePage: false,
                                    total: commonTable.data.totalDocs,
                                    pageSizeOptions: ["5", "10", "20", "50", "100"],
                                }}
                                onRow={(record, _rowIndex) => ({
                                    onClick: (_event) => {
                                        dispatch(setExpense(record))
                                        history.push(`/expenses/${record.id}`)
                                    },
                                })}
                                expandIcon={(props) => <ExpandTripFolderTableIcon props={props} />}
                            />
                        </div>
                    )}
                </div>
            )}
        </>
    )
}

export default DoneOrAllTab
