import { CostCenter } from "@finway-group/shared/lib/models"
import { Action } from "redux"

export enum CostCenterActionTypes {
    FETCH_ALL_COST_CENTERS = "FETCH_ALL_COST_CENTERS",
    FETCH_ONE_COST_CENTER = "FETCH_ONE_COST_CENTER",
    SYNC_ONE_COST_CENTER = "SYNC_ONE_COST_CENTER",
    NEW_COST_CENTER = "NEW_COST_CENTER",
    UPDATE_COST_CENTER = "UPDATE_COST_CENTER",
    RESET_COST_CENTER = "RESET_COST_CENTER",
}

export interface FetchAllCostCentersAction extends Action<typeof CostCenterActionTypes.FETCH_ALL_COST_CENTERS> {
    type: typeof CostCenterActionTypes.FETCH_ALL_COST_CENTERS
    costCenters: Array<CostCenter>
}

export interface FetchOneCostCenterAction extends Action<typeof CostCenterActionTypes.FETCH_ONE_COST_CENTER> {
    type: typeof CostCenterActionTypes.FETCH_ONE_COST_CENTER
    costCenter: CostCenter
}

export interface SyncOneCostCenterAction extends Action<typeof CostCenterActionTypes.SYNC_ONE_COST_CENTER> {
    type: typeof CostCenterActionTypes.SYNC_ONE_COST_CENTER
    costCenter: CostCenter
}

export interface NewCostCenterAction extends Action<typeof CostCenterActionTypes.NEW_COST_CENTER> {
    type: typeof CostCenterActionTypes.NEW_COST_CENTER
    costCenter: CostCenter
}

export interface UpdateConstCenterAction extends Action<typeof CostCenterActionTypes.UPDATE_COST_CENTER> {
    type: typeof CostCenterActionTypes.UPDATE_COST_CENTER
    costCenter: CostCenter
}

export interface ResetCostCenterAction extends Action<CostCenterActionTypes.RESET_COST_CENTER> {
    type: CostCenterActionTypes.RESET_COST_CENTER
}

export type CostCenterActions =
    | FetchAllCostCentersAction
    | FetchOneCostCenterAction
    | SyncOneCostCenterAction
    | NewCostCenterAction
    | UpdateConstCenterAction
    | ResetCostCenterAction
