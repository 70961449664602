export const ColorGenerator = {
    hex: (c: number) => {
        const s = "0123456789abcdef"
        let i = c
        if (i == 0 || isNaN(c)) return "00"
        i = Math.round(Math.min(Math.max(0, i), 255))
        return s.charAt((i - (i % 16)) / 16) + s.charAt(i % 16)
    },
    convertToHex: (rgb: Array<number>) => ColorGenerator.hex(rgb[0]) + ColorGenerator.hex(rgb[1]) + ColorGenerator.hex(rgb[2]),
    trim: (s: string) => (s.charAt(0) == "#" ? s.substring(1, 7) : s),
    convertToRGB: (hex: string) => {
        const color = []
        color[0] = parseInt(ColorGenerator.trim(hex).substring(0, 2), 16)
        color[1] = parseInt(ColorGenerator.trim(hex).substring(2, 4), 16)
        color[2] = parseInt(ColorGenerator.trim(hex).substring(4, 6), 16)

        return color
    },
    generateColorGradient: (colorStart: string, colorEnd: string, colorCount: number) => {
        const gradientStart = ColorGenerator.convertToRGB(colorStart)
        const gradientEnd = ColorGenerator.convertToRGB(colorEnd)

        // alpha blending amount
        let alpha = 0.0

        const colorGradients = []

        for (let i = 0; i < colorCount; i++) {
            const c = []
            alpha += 1.0 / colorCount

            c[0] = gradientStart[0] * alpha + (1 - alpha) * gradientEnd[0]
            c[1] = gradientStart[1] * alpha + (1 - alpha) * gradientEnd[1]
            c[2] = gradientStart[2] * alpha + (1 - alpha) * gradientEnd[2]

            colorGradients.push(`#${ColorGenerator.convertToHex(c)}`)
        }

        return colorGradients
    },
    generateMultipleColor: (targetColors: Array<string>, colorCount: number) => {
        const colorCountPerProcess = colorCount / targetColors.length
        const generatedColors: Array<string> = []
        for (let i = 0; i < targetColors.length; i++) {
            const colors = ColorGenerator.generateColorGradient(targetColors[i], targetColors[(i + 1) % (targetColors.length - 1)], colorCountPerProcess)
            generatedColors.push(...colors)
        }
        return generatedColors
    },
}
