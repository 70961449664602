import { MAX_INPUT_LENGTH } from "@finway-group/shared/lib/utils/validators"
import { Col, Form, Input } from "antd"
import { Rule } from "antd/lib/form"
import { NamePath } from "antd/lib/form/interface"
import React, { ChangeEvent, Key } from "react"

import { blurInputOnWeel } from "Shared/utils/helper.utils"

export interface FormInputInterface {
    elem: {
        initialValue?: Key
        label: string
        key?: string
        name: NamePath
        rules?: Array<Rule>
        isRequired?: boolean
        className?: string
        placeholder?: string
        isAutoComplete?: boolean
        onChange: (e: ChangeEvent<HTMLInputElement>) => void
        maxLength?: number
        isDisabled?: boolean
        inputType?: "date" | "number" | "text"
    }
    span?: number
    md?: number | object
    sm?: number | object
}

export interface FormTextAreaInterface {
    elem: {
        initialValue?: Key
        label: string
        key?: string
        name: NamePath
        className?: string
        rules?: Array<Rule>
        maxLength?: number
        placeholder?: string
        onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void
        isRequired?: boolean
        isDisabled?: boolean
        autoSize?: {
            minRows?: number
            maxRows?: number
        }
    }
    span?: number
    md?: number | object
    sm?: number | object
}

// The NamePath type sometimes is a number or tuple.
// This function ensues we always get a string name to feed the element name attribute
const getHtmlName = (val: NamePath): string => (typeof val === "string" || typeof val === "number" ? String(val) : String(val[1]))

export function FormInputElem({ elem, ...columnProps }: FormInputInterface) {
    const { label, key, name, rules, isAutoComplete, initialValue, isRequired, placeholder, isDisabled, inputType = "text", maxLength = MAX_INPUT_LENGTH, onChange } = elem
    const htmlInputName = getHtmlName(elem.name)

    return (
        <Col {...columnProps}>
            <Form.Item label={label} key={key} name={name} rules={rules} initialValue={initialValue} required={isRequired}>
                <Input
                    type={inputType}
                    onWheel={blurInputOnWeel}
                    name={htmlInputName}
                    disabled={isDisabled}
                    autoComplete={String(isAutoComplete)}
                    placeholder={placeholder}
                    maxLength={MAX_INPUT_LENGTH}
                    onChange={onChange}
                />
            </Form.Item>
        </Col>
    )
}

export function FormTextArea({ elem, ...columnProps }: FormTextAreaInterface) {
    const { label, key, name, rules, initialValue, isRequired, isDisabled, placeholder, maxLength = MAX_INPUT_LENGTH, onChange } = elem
    const htmlInputName = getHtmlName(elem.name)

    return (
        <Col {...columnProps}>
            <Form.Item label={label} key={key} name={name} rules={rules} initialValue={initialValue} required={isRequired}>
                <Input.TextArea name={htmlInputName} disabled={isDisabled} placeholder={placeholder} maxLength={maxLength} onChange={onChange} />
            </Form.Item>
        </Col>
    )
}
