import LoadingOutlined from "@ant-design/icons/lib/icons/LoadingOutlined"
import { PriceIntervalEnum } from "@finway-group/shared/lib/models"
import { Empty } from "antd"
import Table, { ColumnsType } from "antd/lib/table"
import moment from "moment"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"

import PriceLabel from "Components/priceLabel"
import { NotificationService } from "Shared/services"
import WalletService from "Shared/services/wallet.service"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

const FundingHistory = () => {
    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useStateIfMounted(false)
    const [fundingHistory, setFundingHistory] = useStateIfMounted([])

    useEffect(() => {
        let isFetching = true
        const fetchFundingHistory = async () => {
            try {
                if (!isFetching) return
                setIsLoading(true)
                const fundingHistory = await WalletService.fetchWalletFundingHistory()
                isFetching && setFundingHistory(fundingHistory)
            } catch (err) {
                NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:fetch.title"), t("error:fetch.message"))
            }

            isFetching && setIsLoading(false)
        }
        fetchFundingHistory()

        return () => {
            isFetching = false
        }
    }, [])

    const columns: ColumnsType = [
        {
            title: t("creditCard:label.date"),
            width: 150,
            className: "pl-10",
            align: "left",
            // prettier-ignore
            render: ({ transaction: { transactionData: { date } } }: any) => <div>{moment(date).format('ll')}</div>,
        },
        {
            title: t("creditCard:label.load_amount"),
            width: 220,
            align: "center",
            // prettier-ignore
            render: ({ transaction: { transactionData: { amount, currency } } }: any) => <PriceLabel value={amount} currency={currency} interval={PriceIntervalEnum.ONE_TIME} showFullNumber />,
        },
        {
            title: t("creditCard:label.load_type"),
            width: 300,
            className: "pl-10",
            align: "center",
            render: (_record) => <span>{t("label:cards.bank_transfer")}</span>,
        },
        {
            title: t("creditCard:label.balance_after"),
            width: 220,
            align: "center",
            // prettier-ignore
            render: ({ balanceBefore, transaction: { transactionData: { amount, currency } } } : any) => <PriceLabel value={balanceBefore + amount } currency={currency} interval={PriceIntervalEnum.ONE_TIME} showFullNumber />,
        },
    ]

    return (
        <div className="overflow-auto p-2">
            {fundingHistory.length === 0 ? (
                <Empty
                    image={`./icons/empty_funding_history.svg`}
                    imageStyle={{
                        height: 150,
                        marginRight: "auto",
                        marginLeft: "auto",
                        marginTop: "15%",
                        marginBottom: "40px",
                        display: "inline-block",
                    }}
                    description={<span className="text-lg font-bold mt-2 mb-0">{t("info:empty_state.funding_history.title")}</span>}
                >
                    <p>{t("info:empty_state.funding_history.message")}</p>
                </Empty>
            ) : (
                <Table
                    rowKey={(record: any) => record._id}
                    dataSource={fundingHistory}
                    columns={columns}
                    pagination={false}
                    loading={{
                        spinning: isLoading,
                        indicator: <LoadingOutlined style={{ fontSize: 30, color: "black" }} spin />,
                    }}
                />
            )}
        </div>
    )
}

export default FundingHistory
