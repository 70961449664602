import { FormOutlined } from "@ant-design/icons"
import { TfaChannelEnum } from "@finway-group/shared/lib/models/user/tfaMethod.enum"
import { Button } from "antd"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import PhoneVerificationModal from "Components/modals/phoneVerfication.modal"
import { TfaModal } from "Components/modals/tfa.modal"
import { useLoggedInEmployee } from "Shared/hooks/employee.hooks"
import { disableTwoFactorAuth, setupSMSTwoFactorAuth } from "Shared/store/actions/auth/authActions"

import { useModal } from "../../../shared/context/modal.context"

const SecuritySettings: React.FC = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { showModal } = useModal()

    const loggedInUser = useLoggedInEmployee()

    useEffect(() => {
        const securityOptions = loggedInUser.getSecurityOptions?.()
        if (securityOptions?.tfaEnabled && !securityOptions.workPhoneVerified && securityOptions.tfaMethod === TfaChannelEnum.SMS) {
            disableTfa(false)
        }
    }, [loggedInUser.getSecurityOptions()?.workPhoneVerified])

    const disableTfa = async (checked: boolean) => dispatch(disableTwoFactorAuth())

    const setupSmsTfa = async () => {
        if (loggedInUser.getSecurityOptions()?.workPhoneVerified) {
            await dispatch(setupSMSTwoFactorAuth())
            return
        }

        showModal(PhoneVerificationModal, false, {
            isShowing: true,
            phoneNumber: loggedInUser.workPhone,
            employee: loggedInUser,
            onSuccess: setupSmsTfa,
        })
    }

    return (
        <>
            <div className="mt-10">
                <ul className="description-list">
                    <li className="flex flex-col">
                        <div className="flex justify-between w-full">
                            <span className="title">{t("label:tfa.authenticator.title")}</span>
                            <Button
                                onClick={() =>
                                    showModal(TfaModal, false, {
                                        isShowing: true,
                                        disableTfa,
                                        setupSmsTfa,
                                    })
                                }
                            >
                                <FormOutlined />
                            </Button>
                        </div>
                        <div className="self-start max-w-xl text-left">
                            <p>{t("label:tfa.authenticator.notice")}</p>
                        </div>
                    </li>
                </ul>
            </div>
        </>
    )
}

export default SecuritySettings
