import { CardWindowEnum, Expense, ExpenseStatusEnum } from "@finway-group/shared/lib/models"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import i18n from "Shared/locales/i18n"
import { NotificationService } from "Shared/services"
import store from "Shared/store"
import { fetchAllCreditCards, fetchExpenseCard, setCreditCard, setShouldRefetch } from "Shared/store/actions/creditCard/creditCardActions"
import { RootState } from "Shared/store/rootState.interface"
import { convertToSortingString } from "Shared/utils/expense.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

export const useCards = (filtered = false) => {
    const cardsState = useSelector(({ creditCards }: RootState) => creditCards)
    // filter out one time cards
    if (filtered) {
        cardsState.items = cardsState.items.filter((card) => card.window !== CardWindowEnum.ONE_TIME || card.expense.status !== ExpenseStatusEnum.DONE)
    }

    return {
        cards: cardsState.items,
        totalDocs: cardsState.totalDocs,
        totalPages: cardsState.totalPages,
    }
}

export const useCard = () => useSelector(({ creditCards: { item } }: RootState) => item)

export const useCardByExpense = (expense: Expense) => {
    const [isLoadingCard, setIsLoadingCard] = useStateIfMounted(false)
    const dispatch = useDispatch()
    const card = useCard()
    const expenseCardId = expense?.card

    useEffect(() => {
        if (card?._id === expenseCardId) return
        if (!expenseCardId) {
            setCreditCard(undefined)(dispatch)
            return
        }
        setIsLoadingCard(true)
        fetchExpenseCard(expense.id)(dispatch).finally(() => setIsLoadingCard(false))
    }, [expenseCardId])

    return {
        card,
        isLoadingCard,
    }
}

export const useCurrentPage = () => useSelector(({ creditCards: { currentPage } }: RootState) => currentPage)

export const useCurrentLimit = () => useSelector(({ creditCards: { itemsPerPage } }: RootState) => itemsPerPage)

export const usePagedCards = (page: number = 1, limit: number = 20, filter = "", sortingCriteria: Array<any> = [], append = false) => {
    const { cards, totalDocs, totalPages } = useCards()
    const [isLoading, setLoading] = useStateIfMounted(false)
    const [currentPage, setCurrentPage] = useStateIfMounted(page)
    const [currentLimit, setCurrentLimit] = useStateIfMounted(limit)
    const [query, setQuery] = useStateIfMounted(filter)
    const [sorting, setSorting] = useStateIfMounted(sortingCriteria)
    const shouldRefetch = useShouldRefetchCards()

    const fetchCards = async () => {
        try {
            setLoading(true)
            const sortingString = convertToSortingString(sorting)
            await store.dispatch(fetchAllCreditCards(query, sortingString, currentPage, currentLimit, append))
            await store.dispatch(setShouldRefetch(false))
        } catch (err) {
            NotificationService.showErrorNotificationBasedOnResponseError(err, i18n.t("error:fetch.title"), i18n.t("error:fetch.title"))
        }
        setLoading(false)
    }

    useEffect(() => {
        let isFetching = true

        isFetching && fetchCards()

        return () => {
            isFetching = false
        }
    }, [currentPage, currentLimit, query, sorting])

    useEffect(() => {
        let isFetching = true

        shouldRefetch && isFetching && fetchCards()

        return () => {
            isFetching = false
        }
    }, [shouldRefetch])

    return {
        currentPage,
        setCurrentPage,
        currentLimit,
        setCurrentLimit,
        setShouldRefetch,
        query,
        setQuery,
        sorting,
        setSorting,
        isLoading,
        cards,
        totalDocs,
        totalPages,
    }
}

export const useShouldRefetchCards = () => useSelector(({ creditCards }: RootState) => creditCards.shouldRefetch)
