import Icon from "@ant-design/icons"
import React from "react"

const upCircleSvg = () => (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="13" cy="13" r="12" fill="#F8F8F9" stroke="#DEE4EA" />
        <path d="M9 14.3328L13 10.3328L17 14.3328" stroke="#606C7E" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
)

const UpCircleIcon = (props: any) => <Icon component={upCircleSvg} {...props} />

export default UpCircleIcon
