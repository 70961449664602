import { Button, Checkbox, Empty, Form, Modal } from "antd"
import FormItem from "antd/lib/form/FormItem"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import i18n from "Shared/locales/i18n"
import DialogService from "Shared/services/dialog.service"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

interface KYBStartInterface {
    isLoading: boolean
    onStart: () => void
}
const KYBStart: React.FC<KYBStartInterface> = ({ isLoading, onStart }) => {
    const { t } = useTranslation()
    const [checkedPoints, setCheckedPoints] = useStateIfMounted({ first: false, second: false, third: false, fourth: false })
    const [isContinueBtnEnabled, setIsContinueBtnEnabled] = useStateIfMounted(false)
    const [isKYBWarningModalVisible, setIsKYBWarningModalVisible] = useStateIfMounted(false)

    useEffect(() => {
        const isEnabled = checkedPoints.first && checkedPoints.second && checkedPoints.third && checkedPoints.fourth
        isContinueBtnEnabled !== isEnabled && setIsContinueBtnEnabled(isEnabled)
    }, [checkedPoints.first, checkedPoints.second, checkedPoints.third, checkedPoints.fourth])

    const onStartBtnClicked = () => {
        setIsKYBWarningModalVisible(true)
    }

    const handleKYBWarningModalOk = () => {
        setIsKYBWarningModalVisible(false)
        onStart()
    }

    return (
        <>
            <Empty
                image={"./icons/creditCard_start1.svg"}
                imageStyle={{
                    height: 150,
                    marginRight: "auto",
                    marginLeft: "auto",
                    marginTop: "15%",
                    marginBottom: "40px",
                    display: "inline-block",
                }}
                description={
                    <>
                        <span className="text-lg font-bold mt-2 mb-0 mr-2">{t("info:empty_state.kyb.start_title")}</span>
                        <br />
                        <span className="text-lg">{t("info:empty_state.kyb.start_subtitle")}</span>
                    </>
                }
            >
                <div className="text-center max-w-450 mx-auto">{t("info:empty_state.kyb.message_start")}</div>
                <Button className={`w-120 mt-20 btn-default btn-highlight-green-active`} loading={isLoading} type="primary" onClick={onStartBtnClicked}>
                    {t("info:empty_state.kyb.button_next")}
                </Button>
            </Empty>
            <Modal
                title={t(`confirm:cards.kyb_start.title`)}
                okButtonProps={{ disabled: !isContinueBtnEnabled }}
                className="ant-modal--full"
                cancelText={t(`confirm:cards.kyb_start.cancel`)}
                okText={t(`confirm:cards.kyb_start.confirm`)}
                visible={isKYBWarningModalVisible}
                maskClosable={false}
                onOk={handleKYBWarningModalOk}
                onCancel={() => setIsKYBWarningModalVisible(false)}
            >
                <div>
                    <span>{t(`confirm:cards.kyb_start.message_start`)}</span>
                    <Form layout="vertical" className="mt-30">
                        <FormItem required>
                            <Checkbox onChange={(e) => setCheckedPoints({ ...checkedPoints, first: e.target.checked })}>{t(`confirm:cards.kyb_start.message_point_1`)}</Checkbox>
                        </FormItem>
                        <FormItem required>
                            <Checkbox onChange={(e) => setCheckedPoints({ ...checkedPoints, second: e.target.checked })}>{t(`confirm:cards.kyb_start.message_point_2`)}</Checkbox>
                        </FormItem>
                        <FormItem required>
                            <Checkbox onChange={(e) => setCheckedPoints({ ...checkedPoints, third: e.target.checked })}>{t(`confirm:cards.kyb_start.message_point_3`)}</Checkbox>
                        </FormItem>
                        <FormItem required>
                            <Checkbox onChange={(e) => setCheckedPoints({ ...checkedPoints, fourth: e.target.checked })}>{t(`confirm:cards.kyb_start.message_point_4`)}</Checkbox>
                        </FormItem>
                    </Form>
                    <span>{i18n.t(`confirm:cards.kyb_start.message_end`)}</span>
                </div>
            </Modal>
        </>
    )
}
export default KYBStart
