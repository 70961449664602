import { LoadingOutlined } from "@ant-design/icons"
import { EnrichedPaymentSettings, PaymentMethodType } from "@finway-group/shared/lib/models"
import { CardNumberElement, IbanElement, useElements, useStripe } from "@stripe/react-stripe-js"
import { SetupIntentResult, Stripe, StripeElements } from "@stripe/stripe-js"
import { Radio, Spin } from "antd"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"

import PaymentMethodCardForm from "Components/forms/paymentMethodCard.form"
import PaymentMethodSepaDebitForm from "Components/forms/paymentMethodSepaDebit.form"
import { NOTIFICATION_OPEN_DURATION } from "Shared/config/consts"
import { NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import PaymentSettingsService from "Shared/services/paymentSettings.service"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

interface ExpenseExportInterface {
    paymentSettings: EnrichedPaymentSettings
    isFormEditable: boolean
    setIsFormEditable: (value: boolean) => void
    onUpdated: (paymentSettings: EnrichedPaymentSettings) => void
}

const PaymentMethodSettings: React.FC<ExpenseExportInterface> = ({ paymentSettings, isFormEditable, setIsFormEditable, onUpdated }) => {
    const { t } = useTranslation()
    const stripe = useStripe()
    const elements = useElements()

    const [isLoading, setIsLoading] = useStateIfMounted<any>(false)
    const [clientSecret, setClientSecret] = useStateIfMounted<any>(undefined) // stripe setup intent
    const [paymentMethodType, setPaymentMethodType] = useStateIfMounted<any>(paymentSettings.paymentMethod?.paymentMethodType || PaymentMethodType.CARD)

    const fetchClientSecret = async () => {
        const secret = await PaymentSettingsService.getClientSecret()
        setClientSecret(secret)
    }

    useEffect(() => {
        if (!isFormEditable) return
        fetchClientSecret()
    }, [isFormEditable])

    // if user does not have a payment method yet, make form editable
    useEffect(() => {
        if (!paymentSettings.paymentMethod) {
            setIsFormEditable(true)
        }
    }, [])

    const handleCancel = () => {
        setIsFormEditable(false)
    }

    const handleSubmit = async (formData: any) => {
        if (!stripe || !elements) {
            return
        }

        try {
            setIsLoading(true)
            const result = await savePaymentMethodInStripe(elements, stripe, clientSecret, formData, paymentMethodType)
            if (result) {
                if (result.error) {
                    // errors from stripe
                    NotificationService.send(
                        NotificationTypeEnum.ERROR,
                        t("error:payment_method.update_in_stripe.title"),
                        t("error:payment_method.update_in_stripe.message"),
                        NOTIFICATION_OPEN_DURATION,
                    )
                } else {
                    const update = await PaymentSettingsService.savePaymentMethod(result.setupIntent?.payment_method)
                    NotificationService.send(
                        NotificationTypeEnum.SUCCESS,
                        t("notification:payment_method.updated.title"),
                        t("notification:payment_method.updated.message"),
                        NOTIFICATION_OPEN_DURATION,
                    )
                    setIsFormEditable(false)
                    onUpdated(update)
                }
            }
        } catch (err) {
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:payment_method.update.title"))
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Spin spinning={isLoading} indicator={<LoadingOutlined />}>
            <Radio.Group
                className="w-full mt-20 mb-30"
                defaultValue={paymentMethodType}
                value={paymentMethodType}
                disabled={!isFormEditable}
                onChange={(e) => setPaymentMethodType(e.target.value)}
            >
                <Radio value={PaymentMethodType.CARD} className="inline-block">
                    {t("label:payment_settings.card_payment")}
                </Radio>
                <Radio value={PaymentMethodType.SEPA_DEBIT} className="inline-block">
                    {t("label:payment_settings.sepa_direct_debit")}
                </Radio>
            </Radio.Group>

            {paymentMethodType === PaymentMethodType.CARD ? (
                <PaymentMethodCardForm paymentSettings={paymentSettings} isEditable={isFormEditable} onSubmit={handleSubmit} onCancel={handleCancel} />
            ) : (
                <PaymentMethodSepaDebitForm paymentSettings={paymentSettings} isEditable={isFormEditable} onSubmit={handleSubmit} onCancel={handleCancel} />
            )}
        </Spin>
    )
}

export default PaymentMethodSettings

const savePaymentMethodInStripe = async (
    elements: StripeElements,
    stripe: Stripe,
    clientSecret: string,
    formData: any,
    paymentMethodType: PaymentMethodType,
    additionalAttempts = 1,
): Promise<SetupIntentResult> => {
    let result
    if (paymentMethodType === PaymentMethodType.CARD) {
        const cardElement = elements.getElement(CardNumberElement)

        result = await stripe.confirmCardSetup(clientSecret, {
            payment_method: {
                card: cardElement!,
                billing_details: {
                    email: formData?.email || null,
                },
                metadata: {
                    secondEmail: formData?.secondEmail || null,
                },
            },
        })
    } else {
        const iban = elements.getElement(IbanElement)
        result = await stripe.confirmSepaDebitSetup(clientSecret, {
            payment_method: {
                sepa_debit: iban!,
                billing_details: {
                    name: formData.name,
                    email: formData.email,
                    address: {
                        city: formData.city,
                        country: formData.country,
                        line1: formData.invoiceAddress,
                        line2: formData?.secondAddress,
                        postal_code: formData.postcode,
                    },
                },
                metadata: {
                    secondEmail: formData?.secondEmail || null,
                },
            },
        })
    }

    if (result.error && additionalAttempts > 0) {
        return savePaymentMethodInStripe(elements, stripe, clientSecret, formData, paymentMethodType, additionalAttempts - 1)
    }
    return result
}
