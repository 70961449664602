import { textNoWhiteSpacesRegex } from "@finway-group/shared/lib/utils/validators"
import { Rule } from "antd/lib/form"
import i18n from "i18next"

import validateEmail from "Shared/validators/email.validator"

export const personioRules = (excludedUsers?: Array<string>) => ({
    clientId: [
        {
            required: true,
            message: i18n.t("validation:required"),
            trigger: "blur",
        },
    ],
    clientSecret: [
        {
            required: true,
            message: i18n.t("validation:required"),
            trigger: "blur",
        },
    ],
    excludedUsers: [
        {
            validator: (_rule: Rule, values: Array<string>) => {
                if (!values) {
                    return Promise.resolve()
                }
                const emailWithWhiteSpaceStartOrEnd = values.filter((value) => value !== value.trim())
                if (emailWithWhiteSpaceStartOrEnd.length > 0) {
                    return Promise.reject(i18n.t("validation:string_white_space_start_or_end"))
                }
                const emailWithWhiteSpace = values.filter((value) => !textNoWhiteSpacesRegex.test(value))
                if (emailWithWhiteSpace.length > 0) {
                    return Promise.reject(i18n.t("validation:string_white_space"))
                }
                const invalidEmail = values.filter((value) => !validateEmail(value))
                if (invalidEmail.length > 0) {
                    return Promise.reject(i18n.t("validation:email"))
                }
                const emailAlreadyExists = values.filter((value) => excludedUsers?.includes(value))
                if (emailAlreadyExists.length > 0) {
                    return Promise.reject(i18n.t("validation:personio.duplicate_email", { count: emailAlreadyExists.length, email: emailAlreadyExists.join(", ") }))
                }
                return Promise.resolve()
            },
        },
    ],
})
