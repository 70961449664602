import { CurrencyEnum, Employee, PriceIntervalEnum } from "@finway-group/shared/lib/models"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"

import PriceLabel from "Components/priceLabel"
import { AnalyticsService } from "Shared/services"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

interface EmployeeCostCenterInterface {
    employee: Employee
    globalCurrency: CurrencyEnum
}
const EmployeeCostCenter: React.FC<EmployeeCostCenterInterface> = ({ employee, globalCurrency }) => {
    const { t } = useTranslation()
    const [costCenterData, setCostCenterData] = useStateIfMounted([])

    useEffect(() => {
        let isFetching = true
        const fetchData = async () => {
            const data = await AnalyticsService.fetchCostCenterDataForEmployee(employee.id, globalCurrency)
            isFetching && setCostCenterData(data)
        }

        isFetching && fetchData()

        return () => {
            isFetching = false
        }
    }, [employee.id])

    return (
        <div className="overflow-hidden">
            {costCenterData.length === 0 ? (
                <p>{t("missing:expense_data")}</p>
            ) : (
                <ul className="description-list">
                    {costCenterData.map((data: any, index: number) => (
                        <li key={index}>
                            <span className="title truncate">{data?.costCenter}</span>
                            <span className="text">
                                <PriceLabel value={data?.value} currency={globalCurrency} interval={PriceIntervalEnum.ONE_TIME} showFullNumber />
                            </span>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    )
}

export default EmployeeCostCenter
