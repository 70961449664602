import { CostCenter2 } from "@finway-group/shared/lib/models"
import { Dispatch } from "redux"

import { CostCenter2Service } from "Shared/services"

import { CancelFetchAction, FetcherActionTypes } from "../fetcher/fetcherTypes"
import { CostCenter2ActionTypes, CreateManyCostCenters2Action, DeleteManyCostCenters2Action, FetchAllCostCenters2Action, UpdateManyCostCenters2Action } from "./costCenter2Types"

export const fetchAllCostCenters2 = () => async (dispatch: Dispatch) => {
    const costCenters2 = await CostCenter2Service.fetchCostCenters2()
    const cancelFetchAction: CancelFetchAction = {
        type: FetcherActionTypes.CANCEL_FETCH,
        isFetchCancelled: true,
    }

    dispatch(cancelFetchAction)

    const fetchAllCostCenters2: FetchAllCostCenters2Action = {
        type: CostCenter2ActionTypes.FETCH_ALL_COST_CENTERS_2,
        costCenters2,
    }

    dispatch(fetchAllCostCenters2)
    return costCenters2
}

export const deleteCostCenters2 = (deleteCostCenters2: Array<CostCenter2>) => async (dispatch: Dispatch) => {
    const remainingCostCenters2 = await CostCenter2Service.deleteCostCenters2(deleteCostCenters2)
    const deleteManyCostCenters2Action: DeleteManyCostCenters2Action = {
        type: CostCenter2ActionTypes.DELETE_MANY_COST_CENTERS_2,
        costCenters2: remainingCostCenters2,
    }

    dispatch(deleteManyCostCenters2Action)
}

export const updateCostCenters2 = (updateCostCenters2: Array<CostCenter2>) => async (dispatch: Dispatch) => {
    const updatedCostCenters2 = await CostCenter2Service.updateCostCenters2(updateCostCenters2)
    const updateManyCostCenters2Action: UpdateManyCostCenters2Action = {
        type: CostCenter2ActionTypes.UPDATE_MANY_COST_CENTERS_2,
        costCenters2: updatedCostCenters2,
    }

    dispatch(updateManyCostCenters2Action)
}

export const createCostCenters2 = (createCostCenters2: Array<CostCenter2>) => async (dispatch: Dispatch) => {
    const allCostCenters2 = await CostCenter2Service.createCostCenters2(createCostCenters2)
    const createdManyCostCenters2Action: CreateManyCostCenters2Action = {
        type: CostCenter2ActionTypes.CREATE_MANY_COST_CENTERS_2,
        costCenters2: allCostCenters2,
    }

    dispatch(createdManyCostCenters2Action)
}

export const createCostCenter2 = (costCenter2: CostCenter2) => async (dispatch: Dispatch) => {
    const newCostCenter2 = await CostCenter2Service.create(costCenter2)

    fetchAllCostCenters2()(dispatch)

    return newCostCenter2
}
