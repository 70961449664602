import { Button, Col, Form, Modal, Row, Steps } from "antd"
import { useForm } from "antd/lib/form/Form"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import WeavrMobileVerificationForm from "Components/forms/weavr/weavrMobileVerification.form"
import Icon from "Components/icon"
import { useLoggedInEmployeeProfile } from "Shared/hooks/user.hooks"
import { useWeavrPasscodeInput } from "Shared/hooks/weavr.hooks"
import { AuthnService, CorporateService } from "Shared/services"
import NotificationService from "Shared/services/notification.service"
import { setIsWeavrAuthenticated, setIsWeavrUserOnboard } from "Shared/store/actions/auth/authActions"
import { syncEmployee } from "Shared/store/actions/employee/employeeActions"
import { WeavrUserOnboardEnum } from "Shared/store/reducers/authReducer"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

export enum CardEnrollmentStepsEnum {
    CREATE_PASSCODE,
    WORK_PHONE_INPUT_STEP,
    WORK_PHONE_VERIFICATION_CODE_STEP,
    DONE,
}

const { Step } = Steps

interface EnrollCardModalProps {
    isShowing: boolean
    onCancel(): void
    onClosed?(): void
    onSuccess?(): void
}

const EnrollCardModal = ({ isShowing, onSuccess, onCancel, onClosed }: EnrollCardModalProps) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const user = useLoggedInEmployeeProfile()
    const isPasswordCreated = user.activeCompanyProfile?.weavrData?.isPasswordCreated
    const [currentStep, setCurrentStep] = useStateIfMounted<any>(CardEnrollmentStepsEnum.CREATE_PASSCODE)
    const [isLoading, setIsLoading] = useState(false)
    const [mobileVerificationFormInstance] = useForm()
    const [isWeavrOTPRetryLimitReached, setIsWeavrOTPRetryLimitReached] = useStateIfMounted(false)

    const { secureForm, setShouldLoad: setPasscodeShouldLoad } = useWeavrPasscodeInput("passcode", currentStep === CardEnrollmentStepsEnum.CREATE_PASSCODE)

    // Initialize initial step in case of user change
    useEffect(() => {
        setCurrentStep(isPasswordCreated ? CardEnrollmentStepsEnum.WORK_PHONE_INPUT_STEP : CardEnrollmentStepsEnum.CREATE_PASSCODE)
    }, [user.id])

    useEffect(() => {
        // Make sure that the passcode only mounts when it's the passcode step, so the weavr secure ux will find the target DOM properly
        setPasscodeShouldLoad(currentStep === CardEnrollmentStepsEnum.CREATE_PASSCODE)
    }, [currentStep])

    const handleHide = (manual = false) => {
        if (manual) {
            onClosed?.()
        }
        onCancel()
    }

    const evaluateCurrentStepSubmission = () => {
        switch (currentStep) {
            case CardEnrollmentStepsEnum.WORK_PHONE_INPUT_STEP:
                mobileVerificationFormInstance.submit()
                break
            case CardEnrollmentStepsEnum.CREATE_PASSCODE:
                setIsLoading(true)
                secureForm.tokenize((tokens: any) => {
                    const tokenizedPassword = tokens.passcode
                    CorporateService.createUserPasscode(tokenizedPassword)
                        .then(async () => {
                            const accessToken = await CorporateService.authenticateUser(tokenizedPassword)
                            dispatch(setIsWeavrAuthenticated(true, accessToken))
                            setCurrentStep(CardEnrollmentStepsEnum.WORK_PHONE_INPUT_STEP)
                            // to update the user object with the new weavrData
                            AuthnService.silentRefresh()
                            dispatch(syncEmployee(user.id))
                        })
                        .catch((err) =>
                            NotificationService.showErrorNotificationBasedOnResponseError(
                                err,
                                t("error:corporate.setup_user_passcode.title"),
                                t("error:corporate.setup_user_passcode.message"),
                            ),
                        )
                        .finally(() => setIsLoading(false))
                })
                break
            case CardEnrollmentStepsEnum.DONE:
                onSuccess?.()
                handleHide()
                break
            default:
                break
        }
    }

    const disableFormSubmissionIfNecessary = () => {
        if (currentStep === CardEnrollmentStepsEnum.WORK_PHONE_VERIFICATION_CODE_STEP) setIsWeavrOTPRetryLimitReached(true)
    }

    const resetFormSubmissionIfNecessary = () => {
        if (currentStep === CardEnrollmentStepsEnum.WORK_PHONE_VERIFICATION_CODE_STEP && isWeavrOTPRetryLimitReached) setIsWeavrOTPRetryLimitReached(false)
    }

    const handleStepBack = () => {
        resetFormSubmissionIfNecessary()
        setCurrentStep(currentStep - 1)
    }

    const getCurrentActiveStep = () => {
        switch (currentStep) {
            case CardEnrollmentStepsEnum.WORK_PHONE_INPUT_STEP:
            case CardEnrollmentStepsEnum.WORK_PHONE_VERIFICATION_CODE_STEP:
                return (
                    <>
                        <h4 className="mb-20">{t("creditCard:card_onboarding.mobile_verification.title")}</h4>
                        <WeavrMobileVerificationForm
                            workPhoneFormInstance={mobileVerificationFormInstance}
                            employee={user}
                            onCurrentStepChange={(step) => {
                                if (step > currentStep) {
                                    setCurrentStep(step)
                                }
                            }}
                            currentStep={currentStep}
                            onSuccess={() => {
                                setCurrentStep(CardEnrollmentStepsEnum.DONE)
                                // to update the user object with the new weavrData
                                AuthnService.silentRefresh()
                                dispatch(syncEmployee(user.id))
                                dispatch(setIsWeavrUserOnboard(WeavrUserOnboardEnum.ONBOARD))
                                // remove weavr token so that the step-up process is executed next time
                                dispatch(setIsWeavrAuthenticated(false))
                            }}
                            setIsLoading={setIsLoading}
                            isWeavrOTPRetryLimitReached={isWeavrOTPRetryLimitReached}
                            disableFormSubmission={disableFormSubmissionIfNecessary}
                        />
                    </>
                )
            case CardEnrollmentStepsEnum.CREATE_PASSCODE:
                return (
                    <>
                        <div>{t("creditCard:card_onboarding.description")}</div>
                        <h4 className="mt-50 mb-30">{t("creditCard:card_onboarding.passcode.title")}</h4>
                        <Form layout="vertical" className="mx-auto w-full">
                            <Row gutter={[16, 0]}>
                                <Col span={24}>
                                    <div className="ant-form-item-label">
                                        <label className={"ant-form-item-required"}>{t("creditCard:card_onboarding.passcode.prompt")}</label>
                                    </div>
                                </Col>
                                <Col span={12}>
                                    <div className="ant-input rounded-lg min-h-32 mt-4 pt-8" id="passcode" />
                                </Col>
                            </Row>
                        </Form>
                    </>
                )
            default:
                return (
                    <Row className={"w-full"}>
                        <Col span={24} className="mx-auto mt-20">
                            <Icon icon="onboarding_complete" />
                        </Col>
                        <Col span={24} className="mx-auto mt-10 mb-20 text-center">
                            {t("creditCard:card_onboarding.complete")}
                        </Col>
                    </Row>
                )
        }
    }

    const renderTitle = () => (
        <div className="w-full flex flex-col">
            <h4 className="align-middle font-bold">{t("creditCard:card_onboarding.title")}</h4>
            <Steps current={currentStep} size="small" className="flex flex-row no-number">
                {[...Array(4)].map((_, i) => (
                    <Step key={i} />
                ))}
            </Steps>
        </div>
    )

    return (
        <Modal
            visible={isShowing}
            maskClosable={false}
            title={renderTitle()}
            onCancel={() => handleHide(true)}
            footer={[
                <Button key={0} className="h-40 md:h-34" type="default" onClick={() => handleHide(true)}>
                    {t("action:cancel")}
                </Button>,
                currentStep === CardEnrollmentStepsEnum.WORK_PHONE_VERIFICATION_CODE_STEP && (
                    <Button key={1} className="h-40 md:h-34" onClick={handleStepBack} type="default">
                        {t("action:back")}
                    </Button>
                ),
                <Button key={2} className="h-40 md:h-34" onClick={evaluateCurrentStepSubmission} type="primary" loading={isLoading} disabled={isWeavrOTPRetryLimitReached}>
                    {currentStep === CardEnrollmentStepsEnum.DONE ? t("action:finish") : t("action:next_step")}
                </Button>,
            ]}
            destroyOnClose
        >
            {getCurrentActiveStep()}
        </Modal>
    )
}

export default EnrollCardModal
