import Icon from "@ant-design/icons"
import React from "react"

const newVendor = () => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="32" height="32" rx="16" fill="#ECF3FF" />
        <g clipPath="url(#clip0_9425_207332)">
            <path
                d="M15.9987 12.6667V11.3333C15.9987 10.6 15.3987 10 14.6654 10H10.6654C9.93203 10 9.33203 10.6 9.33203 11.3333V20.6667C9.33203 21.4 9.93203 22 10.6654 22H21.332C22.0654 22 22.6654 21.4 22.6654 20.6667V14C22.6654 13.2667 22.0654 12.6667 21.332 12.6667H15.9987ZM11.9987 20.6667H10.6654V19.3333H11.9987V20.6667ZM11.9987 18H10.6654V16.6667H11.9987V18ZM11.9987 15.3333H10.6654V14H11.9987V15.3333ZM11.9987 12.6667H10.6654V11.3333H11.9987V12.6667ZM14.6654 20.6667H13.332V19.3333H14.6654V20.6667ZM14.6654 18H13.332V16.6667H14.6654V18ZM14.6654 15.3333H13.332V14H14.6654V15.3333ZM14.6654 12.6667H13.332V11.3333H14.6654V12.6667ZM20.6654 20.6667H15.9987V19.3333H17.332V18H15.9987V16.6667H17.332V15.3333H15.9987V14H20.6654C21.032 14 21.332 14.3 21.332 14.6667V20C21.332 20.3667 21.032 20.6667 20.6654 20.6667ZM19.9987 15.3333H18.6654V16.6667H19.9987V15.3333ZM19.9987 18H18.6654V19.3333H19.9987V18Z"
                fill="#3886FF"
            />
        </g>
        <defs>
            <clipPath id="clip0_9425_207332">
                <rect width="16" height="16" fill="white" transform="translate(8 8)" />
            </clipPath>
        </defs>
    </svg>
)

const NewVendorIcon = (props: any) => <Icon component={newVendor} {...props} />

export default NewVendorIcon
