import { COLOR } from "@finway-group/shared/lib/consts"
import { RightEnum } from "@finway-group/shared/lib/models"
import { Avatar, Button, Dropdown, Menu, TreeSelect } from "antd"
import React from "react"
import { Edit as EditIcon, MoreHorizontal as MoreIcon, PlusCircle as PlusIcon, Trash as TrashIcon } from "react-feather"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import LiquidityScenarioModal from "Components/modals/liquidity/liquidityScenario.modal"
import { useScenario, useScenarios } from "Shared/hooks/liquidity.hooks"
import { AuthzService, NotificationService } from "Shared/services"
import DialogService from "Shared/services/dialog.service"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { deleteScenario, selectScenario, setShouldFetchCashFlowsData } from "Shared/store/actions/liquidity/liquidityActions"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

const { TreeNode } = TreeSelect

interface ScenarioSelectionComponentInterface {
    hideOptions?: boolean
    isLoading: boolean
}

const ScenarioSelectionComponent = ({ hideOptions = false, isLoading }: ScenarioSelectionComponentInterface) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const selectedScenario = useScenario()
    const scenarios = useScenarios()

    const [showScenarioModal, setShowScenarioModal] = useStateIfMounted(false)
    const [isScenarioNew, setIsScenarioNew] = useStateIfMounted(false)
    const isDeleteScenarioDisabled = !selectedScenario?.parentScenario && scenarios.filter((scenario) => !scenario.parentScenario).length < 2

    const onDeleteScenario = async () => {
        if (!selectedScenario || !(await DialogService.confirmDeleteLiquidityScenario())) return

        try {
            await deleteScenario(selectedScenario._id)(dispatch)

            NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:liquidity.deleted_scenario.title"), t("notification:liquidity.deleted_scenario.message"))
        } catch (err) {
            NotificationService.send(NotificationTypeEnum.ERROR, t("error:liquidity.deleted_scenario.title"), t("error:liquidity.deleted_scenario.message"))
        }
    }

    const onNewScenario = () => {
        setIsScenarioNew(true)
        setShowScenarioModal(true)
    }

    const onSelect = async ({ key, domEvent }: any) => {
        domEvent.stopPropagation()
        switch (key) {
            case "newScenario":
                onNewScenario()
                break
            case "editScenario":
                setIsScenarioNew(false)
                setShowScenarioModal(true)
                break
            case "deleteScenario":
                onDeleteScenario()
                break
            default:
                break
        }
    }

    const dropDownMenu = () => (
        <Menu onClick={onSelect}>
            <Menu.Item key="newScenario" className="flex items-center min-w-190 py-8">
                <div className="flex items-center justify-center rounded-full w-16 h-16">
                    <PlusIcon />
                </div>
                <div className="flex items-center ml-12">
                    <p className="leading-none">
                        <span className="text-text">{t("action:liquidity.add_scenario")}</span>
                    </p>
                </div>
            </Menu.Item>

            <Menu.Item key="editScenario" className="flex items-center min-w-190 py-8">
                <div className="flex items-center justify-center rounded-full w-16 h-16">
                    <EditIcon />
                </div>
                <div className="flex items-center ml-12">
                    <p className="leading-none">
                        <span className="text-text">{t("action:liquidity.edit_scenario")}</span>
                    </p>
                </div>
            </Menu.Item>
            <Menu.Item key="deleteScenario" className="flex items-center min-w-190 py-8" disabled={isDeleteScenarioDisabled}>
                <div className="flex items-center justify-center rounded-full w-16 h-16">
                    <TrashIcon />
                </div>
                <div className="flex items-center ml-12">
                    <p className="leading-none">
                        <span className="text-text">{t("action:liquidity.delete_scenario")}</span>
                    </p>
                </div>
            </Menu.Item>
        </Menu>
    )

    const onSelectScenario = (value: string) => {
        const scenario = scenarios.find((scenario) => scenario._id == value)
        if (scenario) {
            dispatch(selectScenario(scenario))
            dispatch(setShouldFetchCashFlowsData(true))
        }
    }

    return (
        <div>
            {selectedScenario && (
                <LiquidityScenarioModal scenario={selectedScenario} isShowing={showScenarioModal} isNew={isScenarioNew} onCancel={() => setShowScenarioModal(false)} />
            )}
            {scenarios.length < 1 ? (
                <Button type="primary" size="small" onClick={() => onNewScenario()}>
                    <PlusIcon />
                    {t("action:liquidity.add_scenario")}
                </Button>
            ) : (
                <div className="flex flex-row space-x-5">
                    <div>
                        <TreeSelect
                            dropdownStyle={{ maxHeight: 400, overflow: "auto", zIndex: 0 }}
                            className="min-w-160"
                            value={selectedScenario?._id}
                            onSelect={onSelectScenario}
                            treeDefaultExpandAll
                        >
                            {scenarios.length > 0 &&
                                // @ts-ignore
                                scenarios.map((scenario) => {
                                    if (!scenario.parentScenario) {
                                        const subScenarios = scenarios.filter((scenarios) => scenarios.parentScenario === scenario._id)
                                        return (
                                            <TreeNode
                                                treeIcon={false}
                                                key={scenario._id}
                                                value={scenario._id}
                                                className={subScenarios.length < 1 ? "-ml-20" : ""}
                                                title={
                                                    <div className="align-middle space-x-3">
                                                        <Avatar style={{ border: 0, backgroundColor: scenario.color }} size={10} />
                                                        <span className="text-text">{scenario.name}</span>
                                                    </div>
                                                }
                                            >
                                                {subScenarios.map((subScenario) => (
                                                    <TreeNode
                                                        key={subScenario._id}
                                                        value={subScenario._id}
                                                        className="-ml-10"
                                                        title={
                                                            <div className="align-middle space-x-3">
                                                                <Avatar style={{ borderWidth: "1px", borderColor: subScenario.color, backgroundColor: COLOR.white }} size={10} />
                                                                <span className="text-text">{subScenario.name}</span>
                                                            </div>
                                                        }
                                                    />
                                                ))}
                                            </TreeNode>
                                        )
                                    }
                                })}
                        </TreeSelect>
                    </div>

                    {!hideOptions && AuthzService.isRightGrantedForLoggedInUser(RightEnum.LIQUIDITY__ALL__UPDATE) && (
                        <Dropdown disabled={isLoading} overlay={dropDownMenu} trigger={["click"]} className="flex items-center z-100 -mt-1">
                            <Button onClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}>
                                <MoreIcon />
                            </Button>
                        </Dropdown>
                    )}
                </div>
            )}
        </div>
    )
}

export default React.memo(ScenarioSelectionComponent)
