import { Action } from "redux"

export enum FetcherActionTypes {
    RESET_FETCH_FLAG = "RESET_FETCH_FLAG",
    LOADED = "LOADED",
    CANCEL_FETCH = "CANCEL_FETCH",
    START_LOADING = "START_LOADING",
    FETCH_API_VERSION = "FETCH_API_VERSION",
}

export interface ResetFetchFlagAction extends Action<typeof FetcherActionTypes.RESET_FETCH_FLAG> {
    type: typeof FetcherActionTypes.RESET_FETCH_FLAG
    isCancelled: boolean
}

export interface LoadedAction extends Action<typeof FetcherActionTypes.LOADED> {
    type: typeof FetcherActionTypes.LOADED
    hasLoaded: boolean
}

export interface CancelFetchAction extends Action<typeof FetcherActionTypes.CANCEL_FETCH> {
    type: typeof FetcherActionTypes.CANCEL_FETCH
    isFetchCancelled: boolean
}

export interface StartLoadingAction extends Action<typeof FetcherActionTypes.START_LOADING> {
    type: typeof FetcherActionTypes.START_LOADING
    hasLoaded: boolean
}

export interface FetchApiVersionAction extends Action<typeof FetcherActionTypes.FETCH_API_VERSION> {
    type: typeof FetcherActionTypes.FETCH_API_VERSION
    apiVersion: string
}

export type FetcherActions = ResetFetchFlagAction | LoadedAction | CancelFetchAction | StartLoadingAction | FetchApiVersionAction
