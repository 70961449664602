import { PageHeader } from "antd"
import React from "react"
import { useHistory, useLocation } from "react-router-dom"

interface PageCustomHeaderProps {
    title: React.ReactNode
    subTitle?: string
    className?: string
    actionButtons?: React.ReactNode
}

interface LocationState {
    fromExpenseSwitching?: boolean
}

const PageCustomHeader = ({ className, title, subTitle, actionButtons }: PageCustomHeaderProps) => {
    const history = useHistory()
    const location = useLocation<LocationState>()

    const goBack = () => {
        if (location.state?.fromExpenseSwitching) history.push("/expenses")
        else history.goBack()
    }

    return <PageHeader className={className} onBack={() => goBack()} title={title} subTitle={subTitle} extra={actionButtons} />
}

export default PageCustomHeader
