import { DEFAULT_DEBIT_CARD_LIMIT_BUFFER } from "@finway-group/shared/lib/consts/common"
import { Col, Form, InputNumber, Modal, Row, Slider } from "antd"
import { Store } from "antd/lib/form/interface"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import { useCompany } from "Shared/hooks/company.hooks"
import { NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { updateCompanyFeatureSettings } from "Shared/store/actions/company/companyActions"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

interface DebitCardLimitBufferModalInterface {
    isShowing: boolean
    onCancel: () => void
}

const DebitCardLimitBufferModal: React.FC<DebitCardLimitBufferModalInterface> = ({ isShowing, onCancel }) => {
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useState(false)
    const company = useCompany()
    const [limitBuffer, setLimitBuffer] = useStateIfMounted<number>(company?.debitCardLimitBuffer ?? DEFAULT_DEBIT_CARD_LIMIT_BUFFER)

    useEffect(() => {
        isShowing && setLimitBuffer(company?.debitCardLimitBuffer ?? DEFAULT_DEBIT_CARD_LIMIT_BUFFER)
    }, [isShowing])

    const isInvalidNumber = isNaN(limitBuffer) || limitBuffer < 0 || limitBuffer > 10

    const onFormSubmit = async (_values: Store) => {
        setIsLoading(true)
        try {
            company.debitCardLimitBuffer = limitBuffer
            // this flag is used by the table fetching but not part
            // of the actual model and would be rejected on an update
            company.shouldRefetch = undefined
            await updateCompanyFeatureSettings(company._id, company)(dispatch)

            NotificationService.send(
                NotificationTypeEnum.SUCCESS,
                t("notification:settings.company.debit_card_limit_buffer_updated.title"),
                t("notification:settings.company.debit_card_limit_buffer_updated.message"),
            )
            handleHide()
        } catch (err) {
            setIsLoading(false)
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:error"))
        }
    }

    const handleHide = () => {
        setIsLoading(false)
        onCancel()
    }

    return (
        <Modal
            title={t("label:debit_card_limit_buffer")}
            visible={isShowing}
            maskClosable={false}
            onOk={form.submit}
            onCancel={handleHide}
            okText={t("action:save")}
            keyboard
            destroyOnClose
            confirmLoading={isLoading}
            okButtonProps={{ disabled: isInvalidNumber }}
        >
            <Form form={form} layout="vertical" onFinish={onFormSubmit}>
                <Row className="mb-30">
                    <span className="text-text">{t("info:debit_card_limit_buffer")}</span>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col span={18}>
                        <Slider min={0} max={10} onChange={(value: number) => setLimitBuffer(value)} value={isInvalidNumber ? 0 : limitBuffer} />
                    </Col>
                    <Col span={2}>
                        <InputNumber min={0} max={10} onChange={(value: number) => setLimitBuffer(value)} value={limitBuffer} formatter={(value: number) => `${value} %`} />
                    </Col>
                </Row>
                {isInvalidNumber && <span className="finway-error-text">{t("validation:number_interval", { min: 0, max: 10 })}</span>}
            </Form>
        </Modal>
    )
}

export default DebitCardLimitBufferModal
