import Editor from "@draft-js-plugins/editor"
import "@draft-js-plugins/emoji/lib/plugin.css"
import { MentionData, defaultSuggestionsFilter } from "@draft-js-plugins/mention"
import "@draft-js-plugins/mention/lib/plugin.css"
import { CompositeDecorator, ContentState, DraftHandleValue, EditorState, Modifier, RichUtils, convertFromRaw, getDefaultKeyBinding } from "draft-js"
import "draft-js/dist/Draft.css"
import React, { Suspense, useCallback, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import { getEditorContent } from "Shared/utils/comment.utils"
import { extractTextFromHtml } from "Shared/utils/htmlParser.utils"

import { EditorDecoratorProps } from "./useEditorDecoratorsProps"

interface CommentEditorInterface {
    mentions: Array<MentionData>
    onSubmit: (newComment: string) => void
    defaultValue?: string
    editorState: EditorState
    setEditorState: (state: EditorState) => void
    decoratorProps: EditorDecoratorProps
}

const CommentEditor = ({ decoratorProps, editorState, setEditorState, mentions, onSubmit, defaultValue }: CommentEditorInterface) => {
    const { t } = useTranslation()
    const ref = useRef<Editor>(null)

    const [suggestions, setSuggestions] = useState<Array<MentionData>>(mentions)
    const [open, setOpen] = useState(false)

    useEffect(() => {
        setSuggestions(mentions)
        // init editor with content in case in edit mode
        if (defaultValue) {
            let content
            // content can be editor config or plain text
            // we handle both in here
            try {
                content = convertFromRaw(JSON.parse(defaultValue) as any)
            } catch (error) {
                content = ContentState.createFromText(extractTextFromHtml(defaultValue || ""))
            }

            // inject decorators and stylings with content to preserve mentions
            const decoratorPlugins = decoratorProps.plugins.reduce((acc: any, p: any) => [...acc, ...p.decorators], [])
            setEditorState(EditorState.moveFocusToEnd(EditorState.createWithContent(content, new CompositeDecorator(decoratorPlugins))))
        }
    }, [])

    const onSearchChange = useCallback(({ value }: { value: string }) => {
        const filtered = defaultSuggestionsFilter(value, mentions)
        if (filtered.length === 0) setOpen(false)
        setSuggestions(filtered)
    }, [])

    const onChange = (newState: EditorState) => {
        const currentContent = editorState.getCurrentContent()
        const newContent = newState.getCurrentContent()

        const currentSelection = editorState.getSelection()
        const newSelection = newState.getSelection()

        // Check if any key properties of the selection state have changed
        const hasSelectionChanged =
            currentSelection.getAnchorKey() !== newSelection.getAnchorKey() ||
            currentSelection.getAnchorOffset() !== newSelection.getAnchorOffset() ||
            currentSelection.getFocusKey() !== newSelection.getFocusKey() ||
            currentSelection.getFocusOffset() !== newSelection.getFocusOffset() ||
            currentSelection.getIsBackward() !== newSelection.getIsBackward()

        // Only update if content or selection has changed
        if (currentContent !== newContent || hasSelectionChanged) {
            setEditorState(newState)
        }
    }

    const removeSelectedBlocksStyle = (currentState: EditorState) => {
        const newContentState = RichUtils.tryToRemoveBlockStyle(currentState)
        if (newContentState) {
            return EditorState.push(currentState, newContentState, "change-block-type")
        }
        return currentState
    }

    /**
     * @issue https://github.com/facebookarchive/draft-js/issues/2485#issuecomment-1001968982
     */
    const getResetEditorState = (currentState: EditorState) => {
        const blocks = currentState.getCurrentContent().getBlockMap().toList()
        const updatedSelection = currentState.getSelection().merge({
            anchorKey: blocks.first().get("key"),
            anchorOffset: 0,
            focusKey: blocks.last().get("key"),
            focusOffset: blocks.last().getLength(),
        })
        const newContentState = Modifier.removeRange(currentState.getCurrentContent(), updatedSelection, "forward")

        const newState = EditorState.push(currentState, newContentState, "remove-range")
        return removeSelectedBlocksStyle(newState)
    }

    const handleReturnKey = (e: React.KeyboardEvent<{}>, currentState: EditorState): DraftHandleValue => {
        e.preventDefault()
        if (open) return "not-handled"

        const HANDLED_KEY: DraftHandleValue = "handled"

        const content = currentState.getCurrentContent()
        if (!content.hasText()) return HANDLED_KEY

        const editorContent = getEditorContent(content)
        onSubmit(editorContent)
        setEditorState(getResetEditorState(editorState))

        return HANDLED_KEY
    }

    const onOpenChange = useCallback((openState: boolean) => {
        setOpen(openState)
    }, [])

    const { MentionSuggestions, EmojiSuggestions, EmojiSelect } = decoratorProps

    return (
        <div className="editor-mention" onClick={() => ref.current?.focus()}>
            <Editor
                editorKey={"editor"}
                ref={ref}
                editorState={editorState}
                onChange={onChange}
                plugins={decoratorProps.plugins}
                placeholder={t("placeholder:comment")}
                handleReturn={handleReturnKey}
                stripPastedStyles
            />
            <MentionSuggestions open={suggestions.length > 0 && open} onOpenChange={onOpenChange} onSearchChange={onSearchChange} suggestions={suggestions} />
            <EmojiSuggestions onOpen={() => onOpenChange(true)} onClose={() => onOpenChange(false)} />
            <div className={`emoji-select-wrapper ${defaultValue && "editMode"}`}>
                <Suspense fallback={<></>}>
                    <EmojiSelect closeOnEmojiSelect />
                </Suspense>
            </div>
        </div>
    )
}

export default CommentEditor
