import React from "react"

import Icon, { IconInterface } from "Components/icon"

const attachIcon = (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6.77865 5.66667C6.59455 5.66667 6.44531 5.51743 6.44531 5.33333C6.44531 5.14924 6.59455 5 6.77865 5H10.112C10.2961 5 10.4453 5.14924 10.4453 5.33333C10.4453 5.51743 10.2961 5.66667 10.112 5.66667H6.77865ZM5.44531 7.66667C5.26122 7.66667 5.11198 7.51743 5.11198 7.33333C5.11198 7.14924 5.26122 7 5.44531 7H10.112C10.2961 7 10.4453 7.14924 10.4453 7.33333C10.4453 7.51743 10.2961 7.66667 10.112 7.66667H5.44531ZM5.44531 9.66667C5.26122 9.66667 5.11198 9.51743 5.11198 9.33333C5.11198 9.14924 5.26122 9 5.44531 9H8.77865C8.96274 9 9.11198 9.14924 9.11198 9.33333C9.11198 9.51743 8.96274 9.66667 8.77865 9.66667H5.44531ZM6.77865 2.33333C6.59455 2.33333 6.44531 2.18409 6.44531 2C6.44531 1.81591 6.59455 1.66667 6.77865 1.66667H11.4453C12.3658 1.66667 13.112 2.41286 13.112 3.33333V12C13.112 12.9205 12.3658 13.6667 11.4453 13.6667H4.11198C3.1915 13.6667 2.44531 12.9205 2.44531 12V8.66667C2.44531 8.48257 2.59455 8.33333 2.77865 8.33333C2.96274 8.33333 3.11198 8.48257 3.11198 8.66667V12C3.11198 12.5523 3.55969 13 4.11198 13H11.4453C11.9976 13 12.4453 12.5523 12.4453 12V3.33333C12.4453 2.78105 11.9976 2.33333 11.4453 2.33333H6.77865ZM3.11198 4.66667C3.11198 4.85076 2.96274 5 2.77865 5C2.59455 5 2.44531 4.85076 2.44531 4.66667V2.33333C2.44531 1.59695 3.04227 1 3.77865 1C4.51503 1 5.11198 1.59695 5.11198 2.33333V4.66667C5.11198 5.95533 4.06731 7 2.77865 7C1.48998 7 0.445312 5.95533 0.445312 4.66667V1.33333C0.445312 1.14924 0.594551 1 0.778646 1C0.962741 1 1.11198 1.14924 1.11198 1.33333V4.66667C1.11198 5.58714 1.85817 6.33333 2.77865 6.33333C3.69912 6.33333 4.44531 5.58714 4.44531 4.66667V2.33333C4.44531 1.96514 4.14684 1.66667 3.77865 1.66667C3.41046 1.66667 3.11198 1.96514 3.11198 2.33333V4.66667Z"
            fill="#606C7E"
            stroke="currentColor"
            strokeWidth="0.3"
        />
    </svg>
)

const AttachIcon = (props: IconInterface) => <Icon component={attachIcon} {...props} />

export default AttachIcon
