import { EnrichedPaymentSettings, PaymentInvoice, PaymentProduct } from "@finway-group/shared/lib/models"
import axios from "axios"

export const MODEL = "payment-settings"

export interface PaymentProductUI extends PaymentProduct {
    _id: string
}

export type PaymentInvoiceLinks = Pick<PaymentInvoice, "stripeHostedPdf" | "stripeHostedUrl">

const PaymentSettingsService = {
    fetch: async () => {
        const { data } = await axios.get(`${MODEL}`)
        return data as EnrichedPaymentSettings
    },
    getProducts: async () => {
        const { data } = await axios.get(`${MODEL}/products`)
        return data as Array<PaymentProductUI>
    },
    getProduct: async (id: string) => {
        const { data } = await axios.get(`${MODEL}/products/${id}`)
        return data as PaymentProductUI
    },
    getInvoices: async (query = "", sortingCriteria = "", page = 1, limit = 20) => {
        const {
            data: { docs, totalPages, totalDocs },
        } = await axios.get(`${MODEL}/invoices?page=${page}&limit=${limit}${query}${sortingCriteria}`)
        return {
            docs,
            totalPages,
            totalDocs,
        } as {
            docs: Array<PaymentInvoice>
            totalPages: number
            totalDocs: number
        }
    },
    getInvoiceLinks: async (invoice: PaymentInvoice) => {
        const { data } = await axios.get(`${MODEL}/invoices/${invoice.invoiceId}/links`)
        return data as PaymentInvoiceLinks
    },
    getClientSecret: async () => {
        const { data } = await axios.get(`${MODEL}/secret`)
        return data.clientSecret
    },
    savePaymentMethod: async (paymentMethodId: any) => {
        const { data } = await axios.post(`${MODEL}/payment-method`, { paymentMethodId })
        return data as EnrichedPaymentSettings
    },
}

export default PaymentSettingsService
