import { CostCenter } from "@finway-group/shared/lib/models"
import { ActionCreator, Dispatch } from "redux"

import CostCenterService from "Shared/services/costCenter.service"
import { ThunkResult } from "Shared/store"
import { TablesEnum } from "Shared/store/reducers/tableConfigReducer"

import { CancelFetchAction, FetcherActionTypes } from "../fetcher/fetcherTypes"
import { refetchTable } from "../tables/tableActions"
import {
    CostCenterActionTypes,
    FetchAllCostCentersAction,
    FetchOneCostCenterAction,
    NewCostCenterAction,
    ResetCostCenterAction,
    SyncOneCostCenterAction,
    UpdateConstCenterAction,
} from "./costCenterTypes"

export const fetchAllCostCenters: ActionCreator<ThunkResult<Array<CostCenter>>> = () => async (dispatch: Dispatch) => {
    const costCenters = await CostCenterService.fetchAllCostCenters()
    const cancelFetchAction: CancelFetchAction = {
        type: FetcherActionTypes.CANCEL_FETCH,
        isFetchCancelled: true,
    }

    dispatch(cancelFetchAction)

    const fetchAllCostCentersAction: FetchAllCostCentersAction = {
        type: CostCenterActionTypes.FETCH_ALL_COST_CENTERS,
        costCenters,
    }

    dispatch(fetchAllCostCentersAction)
    return costCenters
}

export const fetchOneCostCenter: ActionCreator<ThunkResult<CostCenter>> = (id: string) => async (dispatch: Dispatch) => {
    const costCenter = await CostCenterService.fetchOneCostCenter(id)
    const fetchOneCostCenterAction: FetchOneCostCenterAction = {
        type: CostCenterActionTypes.FETCH_ONE_COST_CENTER,
        costCenter,
    }

    dispatch(fetchOneCostCenterAction)
    return costCenter
}

export const syncOneCostCenter: ActionCreator<ThunkResult<CostCenter>> = (id: string) => async (dispatch: Dispatch) => {
    const costCenter = await CostCenterService.fetchOneCostCenter(id)
    const syncOneCostCenterAction: SyncOneCostCenterAction = {
        type: CostCenterActionTypes.SYNC_ONE_COST_CENTER,
        costCenter,
    }

    dispatch(syncOneCostCenterAction)
    return costCenter
}

export const setCostCenter: ActionCreator<ThunkResult<void>> = (costCenter: CostCenter) => async (dispatch: Dispatch) => {
    const fetchOneCostCenter: FetchOneCostCenterAction = {
        type: CostCenterActionTypes.FETCH_ONE_COST_CENTER,
        costCenter,
    }

    dispatch(fetchOneCostCenter)
}

export const resetCostCenter: ActionCreator<ResetCostCenterAction> = () => ({
    type: CostCenterActionTypes.RESET_COST_CENTER,
})

export const createCostCenter: ActionCreator<ThunkResult<CostCenter>> = (createCostCenter: CostCenter) => async (dispatch: Dispatch<any>) => {
    const costCenter = await CostCenterService.createCostCenter(createCostCenter)
    const newCostCenterAction: NewCostCenterAction = {
        type: CostCenterActionTypes.NEW_COST_CENTER,
        costCenter,
    }

    dispatch(newCostCenterAction)
    if (createCostCenter.parentCostCenter) {
        await dispatch(syncOneCostCenter(createCostCenter.parentCostCenter))
    }
    dispatch(refetchTable(TablesEnum.COST_CENTERS))
    return costCenter
}

export const updateCostCenter: ActionCreator<ThunkResult<CostCenter>> = (id: string, updateCostCenter: CostCenter) => async (dispatch: Dispatch<any>) => {
    const costCenter = updateCostCenter.deleted ? await CostCenterService.deleteCostCenter(id) : await CostCenterService.updateCostCenter(id, updateCostCenter)
    const updateCostCenterAction: UpdateConstCenterAction = {
        type: CostCenterActionTypes.UPDATE_COST_CENTER,
        costCenter,
    }

    dispatch(updateCostCenterAction)
    if (updateCostCenter.parentCostCenter) {
        await dispatch(syncOneCostCenter(updateCostCenter.parentCostCenter))
    }
    dispatch(refetchTable(TablesEnum.COST_CENTERS))

    return costCenter
}
