import { PerDiemDailyExpenses } from "@finway-group/shared/lib/models"
import { Col, Divider, Form, Row } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"

import { useExpenseFormContext } from "Components/modals/expenseCreateForm.context"

import { PerDiemOptionRow } from "./components/perDiemOptionRow"
import AccommodationIcon from "./icons/accommodation.icon"
import BreakfastIcon from "./icons/breakfast.icon"
import DinnerIcon from "./icons/dinner.icon"
import LunchIcon from "./icons/lunch.icon"

interface PerDiemOptionTableInterface {}
export const PerDiemOptionTable: React.FC<PerDiemOptionTableInterface> = ({}) => {
    const { t } = useTranslation()
    const [{ expenseForm }] = useExpenseFormContext()
    const dailyExpenses: Array<PerDiemDailyExpenses> = expenseForm.getFieldValue(["dailyExpenses"]) ?? []

    return (
        <Row gutter={[16, 16]} className="pt-16 mt-16 rounded border-2 border-gray-300 mx-0 w-full">
            <Col span={6} className="border-x-gray-300">
                <div className="flex justify-center h-32">
                    <span className="w-32">
                        <BreakfastIcon />
                    </span>
                </div>
                <div className="text-center">{t("input:request.per_diem.breakfast")}</div>
            </Col>
            <Col span={6} className="border-x-gray-300">
                <div className="flex justify-center h-32">
                    <span className="w-32">
                        <LunchIcon />
                    </span>
                </div>
                <div className="text-center">{t("input:request.per_diem.lunch")}</div>
            </Col>
            <Col span={6} className="border-x-gray-300">
                <div className="flex justify-center h-32">
                    <span className="w-32">
                        <DinnerIcon />
                    </span>
                </div>
                <div className="text-center">{t("input:request.per_diem.dinner")}</div>
            </Col>
            <Col span={6} className="border-x-gray-300">
                <div className="flex justify-center h-32">
                    <span className="w-32">
                        <AccommodationIcon />
                    </span>
                </div>
                <div className="text-center">{t("input:request.per_diem.accommodation")}</div>
            </Col>
            <Col span={24}>
                <Divider className="mb-0" />
            </Col>
            <Form.List name={["dailyExpenses"]}>
                {(fields) => (
                    <div className={`w-full ${fields.length > 4 ? "overflow-y-scroll per-diem-option-table-max-height" : ""}`}>
                        {fields.map((_field, i) => {
                            const dailyExpense: PerDiemDailyExpenses = expenseForm.getFieldValue(["dailyExpenses", i]) ?? []
                            return <PerDiemOptionRow tripDailyExpenses={dailyExpenses} dailyExpense={dailyExpense} index={i} key={`per-diem-option-row-${i}`} />
                        })}
                    </div>
                )}
            </Form.List>
        </Row>
    )
}
