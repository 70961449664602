import { Card, Col, Row } from "antd"
import React from "react"

import { SkeletionLarge, SkeletonMedium, SkeletonSmall } from "Components/simpleSkeleton"

interface SkeletonCardInterface {
    title: string
    height: number
}

const SkeletonCard: React.FC<SkeletonCardInterface> = ({ title, height }) => (
    <Card className={`h-${height}`}>
        <Row>
            <Col span={24}>
                <div className="flex justify-between">
                    <p className="text-base font-bold">{title}</p>
                </div>
            </Col>
        </Row>
        <Row className="mt-40">
            <Col span={24}>
                <div className="flex justify-between">
                    <SkeletonMedium />
                </div>
            </Col>
        </Row>
        <Row className="mt-20">
            <Col span={24}>
                <div className="flex justify-between">
                    <SkeletionLarge />
                </div>
            </Col>
            <Col span={24}>
                <div className="flex justify-between">
                    <div>
                        <SkeletonSmall />
                    </div>
                    <div>
                        <SkeletonSmall />
                    </div>
                </div>
            </Col>
        </Row>

        <Row className="mt-40">
            <Col span={24}>
                <div className="flex justify-between">
                    <SkeletonMedium />
                </div>
            </Col>
        </Row>
        <Row className="mt-20">
            <Col span={24}>
                <div className="flex justify-between">
                    <SkeletionLarge />
                </div>
            </Col>
            <Col span={24}>
                <div className="flex justify-between">
                    <div>
                        <SkeletonSmall />
                    </div>
                    <div>
                        <SkeletonSmall />
                    </div>
                </div>
            </Col>
        </Row>
    </Card>
)

export default SkeletonCard
