import { Expense, Transaction, TransactionTypeEnum } from "@finway-group/shared/lib/models"
import { TransactionStatusEnum } from "@finway-group/shared/lib/models/transaction/transactionStatus.enum"
import { Tag } from "antd"
import moment from "moment"
import React from "react"
import { useTranslation } from "react-i18next"

import { summarizeExpenseTransactions } from "Shared/utils/expense.utils"
import { isNotSet } from "Shared/utils/helper.utils"

import TransactionStatusTag from "./transactionStatusTag"

interface ExpenseTransactionStatusTagInterface {
    expense: Expense & { matchedTransactions: Array<Transaction> }
}

const ExpenseTransactionStatusTag = ({ expense }: ExpenseTransactionStatusTagInterface) => {
    const { t } = useTranslation()

    let { transactionState, datePaidAt, transactionType, matchedTransactions } = expense
    if (isNotSet(transactionState)) transactionState = summarizeExpenseTransactions(expense)
    if (isNotSet(transactionType) && matchedTransactions.length > 0) transactionType = matchedTransactions[0].transactionType

    const getTag = () => {
        if (!transactionType || transactionState === undefined) {
            return <Tag className={`ant-tag-gray`}>{t("label:matching.not_matched")}</Tag>
        }
        if (transactionType === TransactionTypeEnum.BANK_TRANSACTION && datePaidAt && transactionState === TransactionStatusEnum.PENDING) {
            return <Tag className={`ant-tag-yellow`}>{t("label:matching.scheduled_for", { date: moment(datePaidAt).format("DD.MM.YYYY") })}</Tag>
        }
        return <TransactionStatusTag status={transactionState} />
    }

    return <>{getTag()}</>
}

export default ExpenseTransactionStatusTag
