import { FeatureFlagsInterface } from "@finway-group/shared/lib/models"
import { Action } from "redux"

export enum FeatureFlagsActionTypes {
    SET_FEATURE_FLAGS = "SET_FEATURE_FLAGS",
}

export interface SetFeatureFlagsAction extends Action<typeof FeatureFlagsActionTypes.SET_FEATURE_FLAGS> {
    type: typeof FeatureFlagsActionTypes.SET_FEATURE_FLAGS
    featureFlags: FeatureFlagsInterface
}

export type FeatureFlagsActions = SetFeatureFlagsAction
