import { Tax } from "@finway-group/shared/lib/models"
import { ActionCreator, Dispatch } from "redux"

import { TaxService } from "Shared/services"
import { ThunkResult } from "Shared/store"

import { CancelFetchAction, FetcherActionTypes } from "../fetcher/fetcherTypes"
import { FetchAllTaxesAction, TaxTypes, UpdateAllTaxesAction } from "./taxTypes"

export const fetchAllTaxes: ActionCreator<ThunkResult<Array<Tax>>> = () => async (dispatch: Dispatch) => {
    const taxes = await TaxService.fetchTaxes()
    const cancelFetchAction: CancelFetchAction = {
        type: FetcherActionTypes.CANCEL_FETCH,
        isFetchCancelled: true,
    }

    dispatch(cancelFetchAction)

    const fetchAllTaxes: FetchAllTaxesAction = {
        type: TaxTypes.FETCH_ALL_TAXES,
        taxes,
    }

    dispatch(fetchAllTaxes)
    return taxes
}

export const updateTaxes = (updatedTaxes: Array<Tax>) => async (dispatch: Dispatch) => {
    const taxes = await TaxService.updateAllTaxes(updatedTaxes)
    const updateAllTaxesAction: UpdateAllTaxesAction = {
        type: TaxTypes.UPDATE_ALL_TAXES,
        taxes,
    }

    dispatch(updateAllTaxesAction)
    return taxes
}
