import { LoadingOutlined } from "@ant-design/icons"
import { Button, Col, Form, Modal, Row } from "antd"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { useWeavrPasscodeInput } from "Shared/hooks/weavr.hooks"
import { CorporateService, NotificationService } from "Shared/services"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

interface WeavrRecoverPasscodeModalProps {
    isShowing: boolean
    onCancel?: () => void
    onSuccess?: () => void
}

const WeavrRecoverPasscodeModal = ({ isShowing, onCancel, onSuccess }: WeavrRecoverPasscodeModalProps) => {
    const { t } = useTranslation()
    const [formInstance] = Form.useForm()
    const [isLoading, setIsLoading] = useStateIfMounted(false)
    const { secureForm, setShouldLoad } = useWeavrPasscodeInput("new-passcode-field", isShowing)
    const [isSendingVerificationEmail, setIsSendingVerificationEmail] = useStateIfMounted(true)
    const [nonce, setNonce] = useState<string>("")

    useEffect(() => {
        isShowing && setShouldLoad(true)

        if (isShowing) {
            setIsSendingVerificationEmail(true)
            CorporateService.initiatePasscodeRecovery()
                .then(() => {
                    setIsSendingVerificationEmail(false)
                })
                .catch((err) => {
                    NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:error"))
                    handleHide()
                })
        }
    }, [isShowing])

    const onSubmit = () => {
        setIsLoading(true)

        secureForm.tokenize(
            (tokens: any) => {
                CorporateService.continuePasscodeRecovery(nonce, tokens.passcode)
                    .then(() => {
                        handleHide()
                        onSuccess?.()
                    })
                    .catch((err) => {
                        NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:error"))
                    })
                    .finally(() => setIsLoading(false))
            },
            (err: any) => {
                NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:error"))
                setIsLoading(false)
            },
        )
    }

    const handleHide = () => {
        setIsLoading(false)
        formInstance.resetFields()
        onCancel?.()
    }

    return (
        <>
            <Modal
                visible={isShowing}
                maskClosable={false}
                title={t(`corporate:passcode_recovery:title`)}
                onCancel={handleHide}
                className="ant-modal--small"
                footer={[
                    <Button key="back" onClick={() => onCancel?.()}>
                        {t("action:cancel")}
                    </Button>,
                    <Button key="submit" type="primary" loading={isLoading} onClick={formInstance.submit}>
                        {t("action:submit")}
                    </Button>,
                ]}
                destroyOnClose
                closable
                keyboard
            >
                <div>
                    {isSendingVerificationEmail && (
                        <div className="absolute" style={{ left: "50%", top: "45%" }}>
                            <LoadingOutlined className="relative" style={{ fontSize: 24, color: "black", left: "-50%" }} spin />
                        </div>
                    )}
                    <Form form={formInstance} onFinish={onSubmit} layout="vertical" style={{ visibility: isSendingVerificationEmail ? "hidden" : "unset" }}>
                        <Row gutter={[16, 24]}>
                            <Col span={24}>
                                <label>{t(`corporate:passcode_recovery.prompt`)}</label>
                            </Col>
                            <Col span={12}>
                                <Row>
                                    <div className="ant-form-item-label">
                                        <label className={"ant-form-item-required"}>{t(`corporate:passcode_recovery.verification_code`)}</label>
                                    </div>
                                </Row>
                                <Row>
                                    <div className="ant-input rounded-lg min-h-34 w-160">
                                        <div className={"relative"} style={{ bottom: nonce.length > 0 ? "2px" : "unset" }}>
                                            <input
                                                type="password"
                                                className={"w-full weavr-imitation"}
                                                placeholder={t("corporate:passcode_recovery.verification_code")}
                                                value={nonce}
                                                style={{
                                                    ...(nonce.length > 0
                                                        ? {
                                                              letterSpacing: "1.85ch",
                                                              marginTop: "0",
                                                          }
                                                        : {}),
                                                }}
                                                onChange={(e) => {
                                                    setNonce(e.target.value)
                                                }}
                                            />
                                        </div>
                                    </div>
                                </Row>
                            </Col>
                            <Col span={12}>
                                <Row>
                                    <div className="ant-form-item-label">
                                        <label className={"ant-form-item-required"}>{t(`corporate:passcode_recovery.enter_new_passcode`)}</label>
                                    </div>
                                </Row>
                                <Row>
                                    <div className="ant-input rounded-lg min-h-34 pt-8 w-160" id="new-passcode-field" />
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Modal>
        </>
    )
}

export default WeavrRecoverPasscodeModal
