import { TransactionCategory, TransactionCategoryTypeEnum } from "@finway-group/shared/lib/models"
import { useSelector } from "react-redux"

import store from "Shared/store"
import { TransactionCategoryWithChildren } from "Shared/store/reducers/tableReducer"
import { RootState } from "Shared/store/rootState.interface"

export const OTHER_INFLOWS_EXTERNAL_NAME = "other_inflows"
export const OTHER_OUTFLOWS_EXTERNAL_NAME = "other_outflows"

export const useTransactionCategories = () => useSelector(({ transactionCategories }: RootState) => transactionCategories.items) || []

export const getParentTransactionCategoriesFromStore = (type: TransactionCategoryTypeEnum, searchValue = "") =>
    filterTransactionCategories(store.getState().transactionCategories.items, type, searchValue)

const filterTransactionCategories = (transactionCategories: Array<TransactionCategory>, type: TransactionCategoryTypeEnum, searchValue = "") => {
    const txCatsWithType = transactionCategories.filter((cat) => cat.type === type)
    const txCatsWithTypeMap = new Map(txCatsWithType.map((i) => [i.id, i]))
    const txCatsWthTypeMatchingSearch = txCatsWithType.filter((cat) => cat.name.toLowerCase().includes(searchValue.toLowerCase()))
    const matchingParentTxCats = txCatsWthTypeMatchingSearch.filter((cat) => !cat.parentId)
    const matchingChildTxCats = txCatsWthTypeMatchingSearch.filter((cat) => cat.parentId)

    const allParentTxCatsMap = new Map(matchingParentTxCats.map((i) => [i.id, i]))
    // add missing parent tx cats of children that match the search string but the parent actually does not
    // e.g. parentX => [childX, childY] && parentY => [childX] w/ search 'x' adds parentY
    matchingChildTxCats.forEach((e) => allParentTxCatsMap.set(e.parentId as any, txCatsWithTypeMap.get(e.parentId as any) as any))

    // Map => array
    return (
        Array.from(allParentTxCatsMap, ([id, cat]) => cat)
            // populate parents w/ children
            .map((cat) => {
                // Attention: make copy => don't not change the store object directly
                const transactionCategoryAny: any = new TransactionCategory(cat)
                const populatedChildren = []
                for (const childId of transactionCategoryAny.children) {
                    const child = matchingChildTxCats.find((e: any) => e.id === childId)
                    if (child) populatedChildren.push(new TransactionCategory(child))
                }
                transactionCategoryAny.children = populatedChildren
                return transactionCategoryAny as TransactionCategoryWithChildren
            })
            // sort order
            .sort((catA, catB) => catA.order - catB.order)
    )
}

export const useTransactionCategoriesByType = (type: TransactionCategoryTypeEnum) =>
    useSelector(({ transactionCategories }: RootState) => transactionCategories.items.filter((e: TransactionCategory) => e.type === type))

export const useTransactionCategoryById = (id?: string) =>
    useSelector(({ transactionCategories }: RootState) => transactionCategories.items.find((e: TransactionCategory) => e.id === id))

export const useTransactionCategoryGroupsByType = (type: TransactionCategoryTypeEnum, includeOtherCategory = false) =>
    useTransactionCategoriesByType(type).filter((transactionCategory: TransactionCategory) => {
        if (includeOtherCategory) return transactionCategory.isGroup && transactionCategory.type === type
        return (
            transactionCategory.isGroup &&
            transactionCategory.type === type &&
            transactionCategory.externalName !== OTHER_INFLOWS_EXTERNAL_NAME &&
            transactionCategory.externalName !== OTHER_OUTFLOWS_EXTERNAL_NAME
        )
    })

export const useTransactionCategoriesMap = (leafOnly: boolean) =>
    useSelector(({ transactionCategories }: RootState) => {
        const categories = leafOnly ? transactionCategories.items.filter((c) => !c.children || c.children.length === 0) : transactionCategories.items
        return new Map<string, TransactionCategory>(categories.map((l: TransactionCategory) => [l.id, l]))
    })

export const useTransactionCategoriesChildrenByType = (type: TransactionCategoryTypeEnum) =>
    useSelector(({ transactionCategories }: RootState) => transactionCategories.items.filter((e: TransactionCategory) => e.isGroup === false && e.type === type))
