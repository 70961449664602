import { EnrichedPaymentSettings } from "@finway-group/shared/lib/models"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import { Button, Tabs } from "antd"
import React, { useEffect, useState } from "react"
import { Edit as EditIcon } from "react-feather"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useHistory, useRouteMatch } from "react-router-dom"

import Loading from "Components/loading"
import SettingsLayout, { SettingsButton } from "Features/settings/settings.layout"
import { FINWAY_ADMIN_EMAIL, STRIPE_API_KEY } from "Shared/config/consts"
import { usePaymentSettings } from "Shared/hooks/payment.hooks"
import { useLoggedInEmployeeProfile } from "Shared/hooks/user.hooks"
import { PaymentSettingsTabRoutes, RoutePathEnum } from "Shared/router/RoutePath.enum"
import { ThunkDispatchResult } from "Shared/store"
import { fetchPaymentSettings, updatePaymentSettings } from "Shared/store/actions/payment/paymentActions"
import { isDev, isInternal } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

const { TabPane } = Tabs

const stripePromise = loadStripe(STRIPE_API_KEY)

const PaymentSubscriptionSettings = React.lazy(() => import("./paymentSubscriptionSettings"))
const PaymentMethodSettings = React.lazy(() => import("./paymentMethodSettings"))
const PaymentInvoices = React.lazy(() => import("./paymentInvoice.list"))

const PaymentSettingsContainer = () => {
    const { t } = useTranslation()
    const history = useHistory()

    const routeMatch = useRouteMatch<{ params: string }>("/settings/system/billing/:tab")
    const dispatch: ThunkDispatchResult = useDispatch()
    const [activeTab, setActiveTabBasic] = useState<PaymentSettingsTabRoutes>(RoutePathEnum.SETTINGS_BILLING_TAB_PLAN)
    const [isPaymentMethodFormEditable, setIsPaymentMethodFormEditable] = useStateIfMounted(false)
    const paymentSettings = usePaymentSettings()
    const loggedInUser = useLoggedInEmployeeProfile()

    useEffect(() => {
        dispatch(fetchPaymentSettings())
    }, [dispatch])

    useEffect(() => {
        if (routeMatch) setActiveTabBasic(`${RoutePathEnum.SETTINGS_BILLING}/${(routeMatch.params as any).tab}` as PaymentSettingsTabRoutes)
    }, [routeMatch])

    const handleEdit = () => {
        if (activeTab === RoutePathEnum.SETTINGS_BILLING_TAB_PAYMENT_METHOD) {
            setIsPaymentMethodFormEditable(true)
        }
    }

    const onUpdated = (update: EnrichedPaymentSettings) => dispatch(updatePaymentSettings(update))

    const setActiveTab = (activeTabToSet: PaymentSettingsTabRoutes, isPushHistory = true) => {
        if (isPushHistory) history.push(`${activeTabToSet}`)
        setActiveTabBasic(activeTabToSet)
    }

    if (paymentSettings.isFetching || !paymentSettings.data) return <Loading />

    const tabs = [
        {
            title: t("label:payment_settings.plan"),
            key: RoutePathEnum.SETTINGS_BILLING_TAB_PLAN,
            component: <PaymentSubscriptionSettings paymentSettings={paymentSettings.data} onUpdated={onUpdated} setActiveTab={setActiveTab} />,
        },
        {
            key: RoutePathEnum.SETTINGS_BILLING_TAB_PAYMENT_METHOD,
            title: t("label:payment_settings.payment"),
            component: (
                <Elements
                    stripe={stripePromise}
                    options={{
                        appearance: { variables: { fontFamily: "proxima-nova, sans-serif", fontSizeBase: "15px" } },
                    }}
                >
                    <PaymentMethodSettings
                        paymentSettings={paymentSettings.data}
                        onUpdated={onUpdated}
                        isFormEditable={isPaymentMethodFormEditable}
                        setIsFormEditable={setIsPaymentMethodFormEditable}
                    />
                </Elements>
            ),
        },
        {
            key: RoutePathEnum.SETTINGS_BILLING_TAB_INVOICES,
            title: t("label:payment_settings.invoices"),
            component: <PaymentInvoices activeTab={activeTab} />,
        },
    ]

    const renderButtons = () => {
        if (activeTab === RoutePathEnum.SETTINGS_BILLING_TAB_PAYMENT_METHOD) {
            return (
                <>
                    {renderTestButton()}
                    <SettingsButton icon={<EditIcon />} onClick={handleEdit} labelKey="action:edit" />
                </>
            )
        }
        return renderTestButton()
    }

    const renderTestButton = () => {
        const isTest = isDev || isInternal
        return (
            <>
                {(isDev || isInternal || loggedInUser.email === FINWAY_ADMIN_EMAIL) && paymentSettings.data?.customerId && (
                    <a target="_blank" href={`https://dashboard.stripe.com/${isTest ? "test/" : ""}customers/${paymentSettings.data?.customerId}`} rel="noreferrer">
                        <Button>View Customer On Stripe</Button>
                    </a>
                )}
            </>
        )
    }

    return (
        <SettingsLayout titleKey="label:payment_settings.title" buttons={renderButtons()}>
            {" "}
            <Tabs activeKey={activeTab} onChange={(key: PaymentSettingsTabRoutes) => setActiveTab(key)}>
                {tabs.map((tab) => (
                    <TabPane tab={tab.title} key={tab.key} className="pb-10">
                        {tab.component}
                    </TabPane>
                ))}
            </Tabs>
        </SettingsLayout>
    )
}

export default React.memo(PaymentSettingsContainer)
