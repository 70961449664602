import { BankAccount, BankConnection } from "@finway-group/shared/lib/models"
import { Col, DatePicker, Form, Row, Select } from "antd"
import { FormInstance } from "antd/lib/form"
import { Store } from "antd/lib/form/interface"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { useBankConnections } from "Shared/hooks/bank.hooks"
import { flatten } from "Shared/utils/helper.utils"
import { getPopupAnchor } from "Shared/utils/popup.utils"

import { BankMatchingRules } from "../rules/bankMatching.rules"

interface BankMatchingFormInterface {
    bankAccounts: Array<BankAccount>
    formInstance: FormInstance
    onSubmit: (values: Store) => void
}

const initDateFilter = (form?: any): any => ({
    startDate: form.startDate ? moment(form.startDate) : "",
    endDate: form.endDate ? moment(form.endDate) : "",
})

const BankMatchingForm: React.FC<BankMatchingFormInterface> = ({ formInstance, onSubmit }) => {
    const { t } = useTranslation()

    const bankAccounts = flatten(
        useBankConnections({ shouldFilterDeleted: true }).map((con: BankConnection) =>
            con.accounts.map((account: BankAccount) => ({ ...account, bank: con.bankName, isDefault: con.isDefault && account.isDefault })),
        ),
    )

    const [dateFilter, setDateFilter] = useState(initDateFilter(formInstance))
    const rules = BankMatchingRules(dateFilter)

    useEffect(() => {
        formInstance.validateFields()
    }, [dateFilter])

    return (
        <Form layout="vertical" onFinish={onSubmit} form={formInstance}>
            <Row gutter={[16, 16]}>
                <Col span={12} md="12">
                    <Form.Item label={t("input:export.start_date")} name="startDate" rules={rules.startDate} initialValue={dateFilter.startDate}>
                        <DatePicker
                            style={{ width: "100%" }}
                            size={"middle"}
                            format={moment.localeData().longDateFormat("L")}
                            placeholder={t("placeholder:matching.min_date")}
                            onChange={(v) => setDateFilter({ ...dateFilter, startDate: v })}
                            getPopupContainer={getPopupAnchor()}
                        />
                    </Form.Item>
                </Col>
                <Col span={12} md="12">
                    <Form.Item label={t("input:export.end_date")} name="endDate" rules={rules.endDate} initialValue={dateFilter.endDate}>
                        <DatePicker
                            style={{ width: "100%" }}
                            size={"middle"}
                            format={moment.localeData().longDateFormat("L")}
                            placeholder={t("placeholder:matching.max_date")}
                            onChange={(v) => setDateFilter({ ...dateFilter, endDate: v })}
                            getPopupContainer={getPopupAnchor()}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[16, 16]}>
                <Col span={24} md="24">
                    <Form.Item label={t("input:banking:bank_account")} name="bankAccount">
                        <Select getPopupContainer={getPopupAnchor()}>
                            {bankAccounts.map((bankAccount: any) => (
                                <Select.Option key={bankAccount._id} value={bankAccount.accountId}>
                                    {`${bankAccount.bank} - ${bankAccount.accountName} (${bankAccount.accountType})`}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    )
}

export default BankMatchingForm
