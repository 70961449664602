import { GREEN } from "@finway-group/shared/lib/consts"
import { Button } from "antd"
import Modal from "antd/lib/modal/Modal"
import React from "react"
import { Check as CheckedIcon } from "react-feather"
import { useTranslation } from "react-i18next"

import Icon from "Components/icon"
import { redirectToFreeDemo } from "Shared/utils/liquidity.utils"

interface UpgradeModalProps {
    isShowing: boolean
    onCancel: () => void
}
const UpgradeModal = ({ isShowing, onCancel }: UpgradeModalProps) => {
    const { t } = useTranslation()

    const content = [
        t("info:upgrade_version.1"),
        t("info:upgrade_version.2"),
        t("info:upgrade_version.3"),
        t("info:upgrade_version.4"),
        t("info:upgrade_version.5"),
        t("info:upgrade_version.6"),
        t("info:upgrade_version.7"),
        t("info:upgrade_version.8"),
        t("info:upgrade_version.9"),
        t("info:upgrade_version.10"),
        t("info:upgrade_version.11"),
    ]

    return (
        <Modal
            className="ant-modal--tiny ant-modal--upgrade-version"
            destroyOnClose
            visible={isShowing}
            closable={false}
            title={t("info:upgrade_version.title")}
            footer={[
                <Button key="back" onClick={onCancel}>
                    {t("label:modal_footer.cancel")}
                </Button>,
                <Button key="submit" type="primary" onClick={() => redirectToFreeDemo(t)}>
                    <Icon icon="rocket" className="mr-8" />

                    {t("label:get_free_demo")}
                </Button>,
            ]}
        >
            {content.map((entry, index) => (
                <p key={index} className="flex flex-row mb-10">
                    <CheckedIcon color={GREEN} size={16} className="mr-8 mt-2" /> {entry}
                </p>
            ))}
        </Modal>
    )
}

export default UpgradeModal
