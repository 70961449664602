import { CaretDownOutlined, LoadingOutlined, PaperClipOutlined } from "@ant-design/icons"
import { COLOR } from "@finway-group/shared/lib/consts"
import { Company, DocumentTypeEnum, InboxInvoice, OcrStatusEnum, PriceIntervalEnum, RightEnum, UploadKindEnum } from "@finway-group/shared/lib/models"
import { Employee, GetEmployeeById } from "@finway-group/shared/lib/models/user/employee.model"
import { insertIf } from "@finway-group/shared/lib/utils"
import { Avatar, Button, Dropdown, Menu, Table, Tag, Tooltip } from "antd"
import { ColumnsType } from "antd/lib/table"
import moment from "moment"
import React, { Key, MouseEvent, useState } from "react"
import { UserPlus as Assign, User as DefaultAssign, Download as DownloadIcon, ArrowRight as Move, UserMinus as UnAssign } from "react-feather"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import UserImage from "Components/UserImage"
import EmptyTableData from "Components/emptyTableData"
import { CircleArrowIcon, EyeNoBgIcon, TrashIcon } from "Components/icons"
import { ErrorTable } from "Components/layout/errorTable"
import Loading from "Components/loading"
import FilterModal from "Components/modals/filter.modal"
import InboxInvoiceModal from "Components/modals/inboxInvoice/inboxInvoice.modal"
import OcrStatusBadge from "Components/ocrStatusBadge"
import PriceLabel from "Components/priceLabel"
import { EmployeeList } from "Components/select/employeeList.select"
import { SubCompanyList } from "Components/select/subCompanyList.select"
import TableButtons from "Components/tableButtons"
import UploadFileDraggerContent from "Components/upload/UploadFileDraggerContent"
import UploadFilesDragger from "Components/upload/UploadFilesDragger"
import { NOTIFICATION_OPEN_DURATION } from "Shared/config/consts"
import { useSelectedInboxInvoices } from "Shared/context/selectedExpenses.context"
import { useCompanies, useUserCompanies } from "Shared/hooks/company.hooks"
import { useEmployees, useEmployeesWithEditingRights } from "Shared/hooks/employee.hooks"
import { useExpenses } from "Shared/hooks/expense.hooks"
import { useRolesMap } from "Shared/hooks/role.hooks"
import { useFetchTable, useTable, useTableFilterAndSearchLoading, useTableSearchString } from "Shared/hooks/table.hooks"
import { useActiveCompanyProfile } from "Shared/hooks/user.hooks"
import { useCreditors } from "Shared/hooks/vendor.hooks"
import { AuthzService, ExpenseService, FileService, NotificationService, UserService } from "Shared/services"
import DialogService from "Shared/services/dialog.service"
import InboxInvoiceService from "Shared/services/inboxInvoice.service"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import {
    createInboxInvoice,
    deleteManyInboxInvoices,
    discardInboxInvoice,
    fetchDashboardInboxInvoicesCount,
    moveOneInboxInvoice,
} from "Shared/store/actions/inboxInvoice/inboxInvoiceActions"
import { refetchTable, updateTableFilter, updateTableOnChange, updateTableSearch } from "Shared/store/actions/tables/tableActions"
import { TablesEnum } from "Shared/store/reducers/tableConfigReducer"
import { getExpensesDocsNeeded } from "Shared/utils/expense.utils"
import { isNotSet } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"
import { getSortOrderForColumn } from "Shared/utils/table.utils"

const enum InboxInvoiceOptionsEnum {
    REMOVE,
    PREVIEW,
    DOWNLOAD,
    MOVE,
    RETRY_OCR,
    ASSIGN,
    UNASSIGN,
}

const InboxInvoiceTab = () => {
    useFetchTable(TablesEnum.INBOX_INVOICE)
    const { expenses } = useExpenses(true)
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const employees = useEmployees()
    const rolesMap = useRolesMap({ excludeDeleted: true })
    const possibleAssignees = useEmployeesWithEditingRights({ excludeDeleted: true, rolesMap })
    const companies = useCompanies()
    const activeCompany = useActiveCompanyProfile()
    const [isUploading, setIsUploading] = useState(false)
    const tabLoading = useTableFilterAndSearchLoading(TablesEnum.INBOX_INVOICE)
    const loggedInEmployee = UserService.getLoggedInEmployeeProfile()
    const [isInvoiceInboxLoading, setIsInvoiceInboxLoading] = useStateIfMounted(false)
    const docsNeeded = getExpensesDocsNeeded(expenses)
    const [selectedInvoice, setSelectedInvoice] = useStateIfMounted<InboxInvoice | undefined>(undefined)
    const [isShowingModal, setIsShowingModal] = useState(false)
    const inboxInvoiceTable = useTable<InboxInvoice>(TablesEnum.INBOX_INVOICE)
    const searchString = useTableSearchString(TablesEnum.INBOX_INVOICE)
    const [selectedInboxInvoices, setSelectedInboxInvoices] = useSelectedInboxInvoices()
    const canWriteInboxInvoice = AuthzService.isRightGrantedForLoggedInUser(RightEnum.INBOX_INVOICE__ALL__WRITE)
    const canAssignInboxInvoice = AuthzService.isRightGrantedForLoggedInUser(RightEnum.INBOX_INVOICE__ALL__ASSIGN)
    const isAllowedToCreateExpenses = AuthzService.isLoggedInUserAllowedToCreateExpenses()
    const userCompanies = useUserCompanies(companies, loggedInEmployee, activeCompany)
    const menuKeySelector = (company: Company) => company.name
    const employeeKeySelector = (employee: Employee) => employee.getFullName()
    const creditors = useCreditors(AuthzService.isRightGrantedForLoggedInUser(RightEnum.EMPLOYEE__ALL__READ), true, true)

    const handleSubMenuTitleClick = (e: { domEvent: Event }) => {
        e.domEvent.stopPropagation()
    }

    const isFilterFilled = () => {
        const filterObject = inboxInvoiceTable.data.filter
        return Object.values(filterObject).some((val) => !isNotSet(val))
    }

    const signInvoiceUrl = async (id: string) => {
        try {
            const invoiceDocument = await InboxInvoiceService.fetchOneInboxInvoice(id)
            setSelectedInvoice(invoiceDocument)
        } catch (err) {
            NotificationService.send(NotificationTypeEnum.ERROR, t("error:error"), t("error:invoice.fetch_failed.message"))
        }
    }

    const openInvoicePreview = async (inboxInvoice: InboxInvoice) => {
        await signInvoiceUrl(inboxInvoice.id)
        setIsShowingModal(true)
    }

    const moveInvoice = (inboxInvoice: InboxInvoice, company: Company) => {
        const shouldUnassignInvoice = inboxInvoice.assignedTo
            ? !GetEmployeeById(employees, inboxInvoice.assignedTo)?.companyProfiles?.some(({ companyId }) => companyId === company._id)
            : false

        const newInvoice = new InboxInvoice({ ...inboxInvoice, ...(shouldUnassignInvoice && { assignedTo: null }), companyId: company._id })

        moveOneInboxInvoice(
            inboxInvoice.id,
            newInvoice,
        )(dispatch)
            .then((data) =>
                NotificationService.send(
                    NotificationTypeEnum.SUCCESS,
                    t("notification:invoice_inbox.move.title"),
                    t("notification:invoice_inbox.move.message", { name: data.fileName, company: company.name }),
                    NOTIFICATION_OPEN_DURATION,
                ),
            )
            .catch((err) => NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:invoice_inbox.move.title")))
    }

    const assignInvoiceToUser = async (inboxInvoice: InboxInvoice, employee: Employee) => {
        if (!(await DialogService.confirmAssignInvoiceToUser(employee.getFullName()))) return
        const newInvoice = new InboxInvoice({ ...inboxInvoice, assignedTo: employee.id })

        try {
            const data = await InboxInvoiceService.updateOne(inboxInvoice.id, newInvoice)
            NotificationService.send(
                NotificationTypeEnum.SUCCESS,
                t("notification:invoice_inbox.assign.title"),
                t("notification:invoice_inbox.assign.message", { name: data.fileName, employee: employee.getFullName() }),
                NOTIFICATION_OPEN_DURATION,
            )
        } catch (err) {
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:invoice_inbox.assign.title"))
        }
    }

    const unassignInvoiceToUser = async (inboxInvoice: InboxInvoice, assignedTo: Employee) => {
        if (!(await DialogService.confirmUnassignInvoiceToUser(assignedTo.getFullName()))) return
        const newInvoice = new InboxInvoice({ ...inboxInvoice, assignedTo: null })

        try {
            const data = await InboxInvoiceService.updateOne(inboxInvoice.id, newInvoice)
            NotificationService.send(
                NotificationTypeEnum.SUCCESS,
                t("notification:invoice_inbox.unassign.title"),
                t("notification:invoice_inbox.unassign.message", { name: data.fileName, employee: assignedTo.getFullName() }),
                NOTIFICATION_OPEN_DURATION,
            )
        } catch (err) {
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:invoice_inbox.unassign.title"))
        }
    }

    const onSelect =
        (inboxInvoice: InboxInvoice) =>
        async ({ key, domEvent }: { key: Key; domEvent: MouseEvent<HTMLElement> }) => {
            domEvent.stopPropagation()

            switch (Number(key)) {
                case InboxInvoiceOptionsEnum.REMOVE:
                    setIsInvoiceInboxLoading(true)
                    discardInboxInvoice(inboxInvoice)(dispatch)
                        .then(() =>
                            NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:invoice_inbox.deleted.title"), t("notification:invoice_inbox.deleted.message")),
                        )
                        .catch((err) => NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:invoice_inbox.delete.titlle")))
                        .finally(() => setIsInvoiceInboxLoading(false))
                    break
                case InboxInvoiceOptionsEnum.PREVIEW:
                    await openInvoicePreview(inboxInvoice)
                    break
                case InboxInvoiceOptionsEnum.MOVE:
                    domEvent.preventDefault()
                    break
                case InboxInvoiceOptionsEnum.DOWNLOAD:
                    FileService.downloadFileAsSignedUrl(inboxInvoice.url)
                    break
                case InboxInvoiceOptionsEnum.RETRY_OCR:
                    InboxInvoiceService.retryInboxInvoiceOcr(inboxInvoice.id)
                    break
                case InboxInvoiceOptionsEnum.UNASSIGN:
                    const employee = GetEmployeeById(employees, inboxInvoice.assignedTo)
                    if (employee) unassignInvoiceToUser(inboxInvoice, employee)
                    break
                default:
                    break
            }
        }

    const dropDownOverlay = (inboxInvoice: InboxInvoice) => () => {
        const MenuItemContent = ({ icon, text }: { icon: JSX.Element; text: string }) => (
            <div className="flex items-center gap-8 text-text min-w-190 h-22">
                {icon}
                <span className="leading-none">{text}</span>
            </div>
        )

        return (
            <Menu onClick={onSelect(inboxInvoice)} triggerSubMenuAction="click">
                {inboxInvoice.ocrStatus === OcrStatusEnum.FAILED && canWriteInboxInvoice && (
                    <Menu.Item key={InboxInvoiceOptionsEnum.RETRY_OCR}>
                        <MenuItemContent icon={<CircleArrowIcon size={14} />} text={t("action:inbox_invoice.retry_ocr")} />
                    </Menu.Item>
                )}

                <Menu.Item key={InboxInvoiceOptionsEnum.PREVIEW}>
                    <MenuItemContent icon={<EyeNoBgIcon size={14} />} text={t("action:inbox_invoice.preview")} />
                </Menu.Item>
                {canAssignInboxInvoice ? (
                    inboxInvoice.assignedTo ? (
                        <Menu.Item key={InboxInvoiceOptionsEnum.UNASSIGN} className="flex items-center min-w-190 py-8">
                            <div className="flex items-center">
                                <UnAssign size={14} />
                                <p className="leading-none ml-5">
                                    <span className="text-text"> {t("action:inbox_invoice.unassign")}</span>
                                </p>
                            </div>
                        </Menu.Item>
                    ) : (
                        <Menu.SubMenu
                            key={InboxInvoiceOptionsEnum.ASSIGN}
                            className="no-submenu-arrow no-arrow"
                            onTitleClick={handleSubMenuTitleClick}
                            title={<MenuItemContent icon={<Assign size={14} />} text={t("action:inbox_invoice.assign")} />}
                        >
                            <EmployeeList
                                employees={possibleAssignees}
                                keySelector={employeeKeySelector}
                                onEmployeeSelect={(employee) => assignInvoiceToUser(inboxInvoice, employee)}
                            />
                        </Menu.SubMenu>
                    )
                ) : null}

                {userCompanies.length > 0 && (
                    <Menu.SubMenu
                        key={InboxInvoiceOptionsEnum.MOVE}
                        popupOffset={[0, 0]}
                        className="no-submenu-arrow no-arrow"
                        onTitleClick={handleSubMenuTitleClick}
                        title={<MenuItemContent icon={<Move size={14} />} text={t("action:inbox_invoice.move")} />}
                    >
                        <SubCompanyList userCompanies={userCompanies} keySelector={menuKeySelector} onCompanySelect={(company) => moveInvoice(inboxInvoice, company)} />
                    </Menu.SubMenu>
                )}

                <Menu.Item key={InboxInvoiceOptionsEnum.DOWNLOAD}>
                    <MenuItemContent icon={<DownloadIcon size={14} />} text={t("action:inbox_invoice.download")} />
                </Menu.Item>

                {canWriteInboxInvoice && (
                    <Menu.Item key={InboxInvoiceOptionsEnum.REMOVE}>
                        <MenuItemContent icon={<TrashIcon size={14} />} text={t("action:inbox_invoice.delete")} />
                    </Menu.Item>
                )}
            </Menu>
        )
    }

    const columns: ColumnsType<InboxInvoice> = [
        {
            title: t("info:inbox_invoice.file_name"),
            key: "fileName",
            align: "left",
            className: "pl-10",
            width: 220,
            ellipsis: true,
            sorter: true,
            sortOrder: getSortOrderForColumn(inboxInvoiceTable, "fileName"),
            render: ({ fileName, duplicate }: InboxInvoice) => (
                <div data-testid="invoiceInboxFileNameColumn" className="flex flex-wrap">
                    <p className="truncate">{fileName}</p>
                    {duplicate.hash || duplicate.invoiceNumber ? <Tag className="ant-tag-red ml-10">{t("label:potential_duplicate")} </Tag> : <></>}
                </div>
            ),
        },
        {
            title: t("info:inbox_invoice.upload_date"),
            key: "uploadDate",
            width: 120,
            align: "left",
            sorter: true,
            sortOrder: getSortOrderForColumn(inboxInvoiceTable, "uploadDate"),
            render: ({ uploadDate }: InboxInvoice) => <div>{moment(uploadDate).format("ll")}</div>,
        },
        {
            title: t("info:inbox_invoice.ocr"),
            key: "ocrStatus",
            width: 120,
            align: "left",
            sorter: true,
            sortOrder: getSortOrderForColumn(inboxInvoiceTable, "ocrStatus"),
            render: ({ ocrStatus }: InboxInvoice) => <OcrStatusBadge ocrStatus={ocrStatus} />,
        },
        {
            title: t("info:inbox_invoice.vendor"),
            key: "ocrResults.vendor.name",
            width: 120,
            align: "left",
            sorter: true,
            sortOrder: getSortOrderForColumn(inboxInvoiceTable, "ocrResults.vendor.name"),
            render: ({ ocrResults }: InboxInvoice) => <div>{ocrResults?.vendor?.name || "n/a"} </div>,
        },
        {
            title: t("info:inbox_invoice.uploaded_by"),
            key: "uploadedByObject.firstName",
            width: 120,
            align: "left",
            sorter: true,
            sortOrder: getSortOrderForColumn(inboxInvoiceTable, "uploadedByObject.firstName"),
            render: ({ uploadedBy }: InboxInvoice) => {
                const employee = GetEmployeeById(employees, uploadedBy)
                return (
                    <div>
                        {employee?.firstName} {employee?.lastName}
                    </div>
                )
            },
        },
        {
            width: 50,
            render: ({ assignedTo }: InboxInvoice) => {
                const employee = GetEmployeeById(employees, assignedTo)
                return (
                    <div className="flex items-center w-full justify-center">
                        {employee ? (
                            <UserImage user={employee} tooltipTitle={t("tooltips:inbox_invoice.assigned", { name: employee.getFullName() })} />
                        ) : (
                            <Tooltip title={t("tooltips:inbox_invoice.unassigned")} placement="top">
                                <Avatar size={24} style={{ backgroundColor: COLOR.gray[200] }} className="flex items-center justify-center">
                                    <DefaultAssign size={14} color={COLOR.text} />
                                </Avatar>
                            </Tooltip>
                        )}
                    </div>
                )
            },
        },
        {
            title: t("info:inbox_invoice.gross_amount"),
            key: "ocrResults.totalGrossPrice",
            width: 120,
            align: "right",
            sorter: true,
            sortOrder: getSortOrderForColumn(inboxInvoiceTable, "ocrResults.totalGrossPrice"),
            render: ({ ocrResults }: InboxInvoice) =>
                ocrResults?.totalGrossPrice && ocrResults?.currency ? (
                    <PriceLabel value={ocrResults.totalGrossPrice} currency={ocrResults.currency} interval={PriceIntervalEnum.ONE_TIME} />
                ) : (
                    <div>{"n/a"} </div>
                ),
        },
        ...insertIf(isAllowedToCreateExpenses, {
            title: t("info:inbox_invoice.action"),
            width: 120,
            align: "right",
            className: "pr-10",
            render: (inboxInvoice: InboxInvoice) => (
                <Dropdown overlay={dropDownOverlay(inboxInvoice)} trigger={["click"]} placement="bottomRight">
                    <Button onClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()} className="m-5 ant-btn-primary">
                        <span>{t("label:options")} </span>
                        <CaretDownOutlined className="text-sm" />
                    </Button>
                </Dropdown>
            ),
        }),
    ]

    const doFileUpload = async (files: Array<File>) => {
        if (!FileService.checkFilesSizeAndHandleError(files)) return

        setIsUploading(true)
        try {
            const data = await FileService.uploadMultiple(files, UploadKindEnum.INBOX_INVOICE)
            for (const fileUploadData of data) {
                const createData = {
                    fileName: fileUploadData.originalname,
                    url: fileUploadData.url,
                    hash: fileUploadData.hash,
                    uploadDate: fileUploadData.createdAt,
                    uploadedBy: fileUploadData.createdBy,
                    duplicate: { hash: fileUploadData.duplicateData.isDuplicate },
                }
                if (await ExpenseService.checkForDuplicateInvoiceFile(fileUploadData.duplicateData)) createInboxInvoice(createData)(dispatch)
            }
        } catch (err) {
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:file.upload.title"))
        } finally {
            setIsUploading(false)
        }
    }

    const onDeleteSelectedInvoices = async () => {
        try {
            setIsInvoiceInboxLoading(true)
            const invoiceIds = selectedInboxInvoices.map((invoice) => invoice.id)
            await deleteManyInboxInvoices(invoiceIds)(dispatch)
        } catch (err) {
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:error"))
        } finally {
            setIsInvoiceInboxLoading(false)
        }
    }

    return (
        <>
            {tabLoading ? (
                <Loading />
            ) : (
                <>
                    <div className="animation-appear">
                        <UploadFilesDragger doFileUpload={doFileUpload} documentType={DocumentTypeEnum.INVOICE} disabled={isUploading || !canWriteInboxInvoice}>
                            <UploadFileDraggerContent
                                label={{
                                    buttonLabel: t("action:add_invoice"),
                                    draggerDescription: t("action:or_drop_files"),
                                }}
                                isUploading={isUploading}
                                readOnly={!canWriteInboxInvoice}
                                isCompanyEmailForwardingAddressButtonVisible
                                // TODO: re-enable
                                // shouldShowCompanyEmailForwardingAddressButton
                            />
                        </UploadFilesDragger>
                    </div>
                    {(!!inboxInvoiceTable.data.totalDocs || inboxInvoiceTable.data.search || isFilterFilled()) && (
                        <TableButtons
                            table={TablesEnum.INBOX_INVOICE}
                            onSearch={async (searchString: string) => {
                                setIsInvoiceInboxLoading(true)
                                await dispatch(updateTableSearch(TablesEnum.INBOX_INVOICE, searchString))
                                await dispatch(fetchDashboardInboxInvoicesCount())
                                setIsInvoiceInboxLoading(false)
                            }}
                            injectFilterModal={(isVisible: boolean, onCancel: any) => (
                                <FilterModal
                                    table={TablesEnum.INBOX_INVOICE}
                                    options={{ creditors }}
                                    isVisible={isVisible}
                                    onCancel={onCancel}
                                    onSubmit={async (filterFormData: any) => {
                                        setIsInvoiceInboxLoading(true)
                                        await dispatch(updateTableFilter(TablesEnum.INBOX_INVOICE, filterFormData))
                                        await dispatch(fetchDashboardInboxInvoicesCount())
                                        setIsInvoiceInboxLoading(false)
                                    }}
                                />
                            )}
                            setIsShowingModal={setIsShowingModal}
                            additionalButtons={
                                <>
                                    <Button disabled={!isAllowedToCreateExpenses} onClick={() => setIsShowingModal(true)}>
                                        <PaperClipOutlined data-testid="attachToRequestInvoiceInbox" />
                                        {t("action:inbox_invoice.attach_to_request")}
                                    </Button>
                                    {canWriteInboxInvoice && (
                                        <Button onClick={onDeleteSelectedInvoices} disabled={!selectedInboxInvoices.length}>
                                            <TrashIcon data-testid="deleteInvoiceInbox" />
                                            {t("action:inbox_invoice.delete")}
                                        </Button>
                                    )}
                                </>
                            }
                        />
                    )}
                    {inboxInvoiceTable.error ? (
                        <ErrorTable onTableReload={() => dispatch(refetchTable(TablesEnum.INBOX_INVOICE))} isLoading={inboxInvoiceTable.isFetching} />
                    ) : inboxInvoiceTable.data.totalDocs === 0 && !inboxInvoiceTable.isFetching ? (
                        <EmptyTableData searchString={searchString} title={t("info:empty_state.inbox.title")} message={t("info:empty_state.inbox.message")} />
                    ) : (
                        <div className="overflow-auto mb-24 mt-20 p'2 animation-appear">
                            <Table
                                id="table"
                                rowKey={(record) => record.id}
                                dataSource={inboxInvoiceTable.data.docs}
                                columns={columns}
                                onChange={updateTableOnChange(TablesEnum.INBOX_INVOICE, inboxInvoiceTable.data)}
                                loading={{
                                    spinning: inboxInvoiceTable.isFetching || isInvoiceInboxLoading,
                                    indicator: <LoadingOutlined style={{ fontSize: 30, color: "black" }} spin />,
                                }}
                                rowSelection={{
                                    renderCell: (_value, _record, _index, originNode) => (
                                        <div onClick={(e) => e.stopPropagation()} className="bigger-hitbox-checkbox">
                                            {originNode}
                                        </div>
                                    ),
                                    selectedRowKeys: selectedInboxInvoices.map((record) => record.id),
                                    onChange: (_selectedRowKeys, selectedRows) => setSelectedInboxInvoices(selectedRows),
                                    onSelect: (record, _selected, _selectedRow) => setSelectedInboxInvoices([...selectedInboxInvoices, record]),
                                }}
                                pagination={{
                                    position: ["bottomRight"],
                                    showSizeChanger: true,
                                    current: inboxInvoiceTable.data.page,
                                    pageSize: inboxInvoiceTable.data.limit,
                                    hideOnSinglePage: false,
                                    total: inboxInvoiceTable.data.totalDocs,
                                    pageSizeOptions: ["5", "10", "20", "50", "100"],
                                }}
                                onRow={(record, _rowIndex) => ({
                                    onClick: (_event) => {
                                        openInvoicePreview(record)
                                    },
                                })}
                            />
                        </div>
                    )}
                    {isShowingModal && (
                        <InboxInvoiceModal
                            isShowing={isShowingModal}
                            onCancel={() => {
                                setIsShowingModal(false)
                                setSelectedInvoice(undefined)
                            }}
                            expenses={docsNeeded}
                            preSelectedInvoice={selectedInvoice}
                            shouldResetSelectedExpenseOnClose
                            shouldResetSelectedInboxOnClose
                            isOcrEnabled={loggedInEmployee.settings.enableOcr}
                            isOcrItemizationEnabled={loggedInEmployee.settings.enableOcrItemization}
                        />
                    )}
                </>
            )}
        </>
    )
}

export default InboxInvoiceTab
