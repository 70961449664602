import { FormInstance, Rule } from "antd/lib/form"
import { Moment } from "moment"

import { checkMaxDate, checkMaxNumber, checkMinDate, checkMinNumber } from "./utils.rules"

export const generateRules = (formInstance: FormInstance): { [key: string]: Array<Rule> } => ({
    minAmount: [
        {
            validator: (_rule: any, value: number | string) => checkMinNumber({ formInstance, value, name: "maxAmount" }),
        },
    ],
    maxAmount: [
        {
            validator: (_rule: any, value: number | string) => checkMaxNumber({ formInstance, value, name: "minAmount" }),
        },
    ],
    minDateCancellationDue: [
        {
            validator: (_rule: any, value: Moment) => checkMinDate(formInstance, value, "maxDateCancellationDue"),
        },
    ],
    maxDateCancellationDue: [
        {
            validator: (_rule: any, value: Moment) => checkMaxDate(formInstance, value, "minDateCancellationDue"),
        },
    ],
})
