import { ExpenseReminder, OperationTypeEnum } from "@finway-group/shared/lib/models"
import { DatePicker, Form, Modal } from "antd"
import type { RangePickerProps } from "antd/es/date-picker"
import moment from "moment"
import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import { NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { setReminder } from "Shared/store/actions/expense/expenseActions"
import { getPopupAnchor } from "Shared/utils/popup.utils"

interface ExpenseReminderModalProps {
    expenseId: string
    isShowing: boolean
    reminder: ExpenseReminder
    onCancel: () => void
}

const disabledDate: RangePickerProps["disabledDate"] = (current) => current && current < moment().endOf("day")

const ExpenseReminderModal = ({ expenseId, isShowing, onCancel, reminder }: ExpenseReminderModalProps) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const [formInstance] = Form.useForm()

    const handleSubmit = async (values: { date: Date }) => {
        try {
            await dispatch(setReminder(expenseId, { date: values.date, operation: reminder ? OperationTypeEnum.UPDATE : OperationTypeEnum.INSERT }))
            onCancel()
            NotificationService.send(
                NotificationTypeEnum.SUCCESS,
                t("notification:request.follow_up_set.title"),
                t("notification:request.follow_up_set.message", { date: moment(values.date).format("DD/MM/YYYY") }),
            )
        } catch (err) {
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:request.follow_up.title"), t("error:request.follow_up.message"))
        }
    }

    return (
        <Modal okText={t("action:save")} title={t("action:follow_up")} visible={isShowing} onOk={() => formInstance.submit()} onCancel={onCancel}>
            <p className="mb-18">{t("label:follow_up_info")}</p>
            <Form layout="vertical" form={formInstance} onFinish={handleSubmit}>
                <Form.Item name="date" required label={t("input:follow_up")} initialValue={reminder ? moment(reminder.date) : null}>
                    <DatePicker className="w-full" format={moment.localeData().longDateFormat("L")} disabledDate={disabledDate} getPopupContainer={getPopupAnchor()} />
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default ExpenseReminderModal
