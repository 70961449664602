import { ErrorCodeEnum } from "@finway-group/shared/lib/models"
import { notification } from "antd"
import * as HttpStatus from "http-status"
import React from "react"

import { AXIOS_EXPIRED_MESSAGE } from "Shared/config/consts"
import i18n from "Shared/locales/i18n"
import { ErrorInterface, extractErrors } from "Shared/utils/errorHandler.utils"

import AuthnService from "./authn.service"

export enum NotificationTypeEnum {
    SUCCESS = "success",
    INFO = "info",
    WARNING = "warning",
    ERROR = "error",
}

export const NotificationDurationSec = {
    REQUEST_CREATION: 7,
}

const NotificationService = {
    send: (
        type: NotificationTypeEnum,
        message: string | React.ReactNode,
        description: string | React.ReactNode,
        duration: number = 3,
        key?: string,
        btn?: { onClick: (_event: React.KeyboardEvent | React.MouseEvent) => void; text: string },
        onClose?: () => void,
    ) => {
        notification[type]({
            message,
            description,
            duration,
            className: "max-w-full ml-10 max-h-400 overflow-auto",
            key: key || type,
            btn: btn ? (
                <button
                    className="outline-none request-link"
                    onClick={(e) => {
                        btn.onClick(e)
                        notification.destroy()
                    }}
                >
                    {" "}
                    {btn.text}{" "}
                </button>
            ) : undefined,
            onClose,
        })
    },
    showLogoutWarning: () => {
        const validTime = AuthnService.getAccessTokenValidTime()
        NotificationService.send(
            NotificationTypeEnum.WARNING,
            "Login invalidated",
            <>Wait until you are logged out after {validTime} secs or log out manually.</>,
            validTime,
            "Login invalidated",
        )
    },
    showErrorNotificationBasedOnResponseError: (error: ErrorInterface | any, title: string, message?: string) => {
        let description

        if (message) {
            description = message
        } else if (error && error.response) {
            if (error.response.status === HttpStatus.UNAUTHORIZED) {
                return
            }
            if (error.response.status === HttpStatus.CONFLICT && error.response?.data?.errorCode === ErrorCodeEnum.PARALLEL_EDITING) {
                description = i18n.t("error:parallel_editing")
            } else {
                description = message || extractErrors(error.response)[0]
            }
        } else if (error.message === "Network Error") {
            description = i18n.t("error:unavailable")
        } else if (error.message === AXIOS_EXPIRED_MESSAGE) {
            description = i18n.t("error:unauthorized_action")
        } else if (error === "cancelled") {
            description = i18n.t("error:unauthorized_action")
        } else if (error?.code === "ECONNABORTED") {
            // axios request timeout triggered
            description = i18n.t("error:timeout")
        } else {
            description = i18n.t("error:unknown")
        }
        notification[NotificationTypeEnum.ERROR]({
            message: title,
            description: <span className="whitespace-pre-line">{description}</span>,
            duration: 10,
            className: "max-w-full ml-10",
        })
    },
    showNotificationOnExportSuccess: (response: any) => {
        const isAsyncExport = response?.status === HttpStatus.ACCEPTED
        const isOnlyPartiallyExported = response?.data?.filteredOutExpenseNumbers?.length

        const notificationString = isAsyncExport ? `async_export_started${isOnlyPartiallyExported ? "_partial" : ""}` : `exported_bulk${isOnlyPartiallyExported ? "_partial" : ""}`

        const notificationType = isOnlyPartiallyExported ? NotificationTypeEnum.WARNING : NotificationTypeEnum.SUCCESS

        NotificationService.send(
            notificationType,
            i18n.t(`notification:request.${notificationString}.title`),
            i18n.t(`notification:request.${notificationString}.message`, { expenses: response?.data?.filteredOutExpenseNumbers.join(", ") }),
            0,
        )
    },
    clear: () => notification.destroy(),
}

export default NotificationService
