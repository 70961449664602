import { LoadingOutlined } from "@ant-design/icons"
import { ApprovalScopeEnum, Expense, ExpenseKindEnum, Hospitality, Mileage, PerDiem, PriceIntervalEnum, TripFolder } from "@finway-group/shared/lib/models"
import { Tooltip } from "antd"
import Table, { ColumnsType } from "antd/lib/table"
import moment from "moment"
import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"

import RequestActionButtons from "Components/actionButton/requestActionButton"
import ExpenseReminderInfo from "Components/expenseReminderInfo"
import { EmptyTable } from "Components/layout/emptyTable"
import { ErrorTable } from "Components/layout/errorTable"
import RequestColumnWithAvatar from "Components/requestColumnWithAvatar"
import { useTableSearchString } from "Shared/hooks/table.hooks"
import { UserService } from "Shared/services"
import { setExpense } from "Shared/store/actions/expense/expenseActions"
import { TablesEnum } from "Shared/store/reducers/tableConfigReducer"
import { Table as TableInterface } from "Shared/store/reducers/tableReducer"
import { getSubscriptionPriceInterval, isFolderExpense } from "Shared/utils/expense.utils"
import { getTooltipPopupContainer, insertIf } from "Shared/utils/helper.utils"
import { parseHtml } from "Shared/utils/htmlParser.utils"

import ExpenseStatusTag from "../expenseStatusTag"
import Icon from "../icon"
import PriceLabel from "../priceLabel"
import ExpandTripFolderTableIcon from "./expandTripFolderTableIcon"

type RequestButtonProp = (event: React.MouseEvent<HTMLElement>, request: Expense) => void

interface TodoTableInterface {
    table: TableInterface<Expense>
    isExportButtonLoading?: boolean
    onTableChange: (pagination: any, filters: any, sorter: any) => void
    requestButtonProps?: {
        onApprove?: (event: React.MouseEvent<HTMLElement>, request: Expense, approvalScope: ApprovalScopeEnum) => Promise<void>
        onReject?: RequestButtonProp
        onExport?: RequestButtonProp
        onReview?: RequestButtonProp
        onUpload?: RequestButtonProp
        onSendReminder?: (event: React.MouseEvent<HTMLElement>, request: Expense) => Promise<void>
        onCancel?: RequestButtonProp
        onDeleteFolder?: RequestButtonProp
        onSubmitFolder?: RequestButtonProp
    }
    onTableReload: () => void
    showActionButtons: boolean
}

export const TodoTable: React.FC<TodoTableInterface> = ({ table, isExportButtonLoading, requestButtonProps, onTableChange, onTableReload, showActionButtons }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const history = useHistory()
    const userSettings = UserService.getLoggedInEmployeeProfile().settings
    const showGross = UserService.getLoggedInEmployeeProfile().settings.showGrossAmount
    const { showExpenseNumbers } = userSettings
    const dashboardSearchString = useTableSearchString(TablesEnum.DONE_REQUESTS)

    //* If any of the table data have children adjust the table design accordingly otherwise remove extras spaces provided for the dropdown icon
    const expandableColumn = table.data.docs.some((expense) => (expense as any)?.children && (expense as any)?.children.length > 0)
        ? [
              {
                  dataIndex: "expandIcon",
                  width: 30,
                  align: "center",
                  render: () => <div className="w-20"></div>,
              } as any,
          ]
        : []

    const columns: ColumnsType = [
        ...expandableColumn,
        {
            title: "details",
            key: "details",
            align: "left",
            className: "pr-10",
            width: 370,
            sorter: true,
            render: (request: Expense) => <RequestColumnWithAvatar request={request} showExpenseNumbers={showExpenseNumbers} />,
        },
        {
            title: "description",
            key: "description",
            align: "center",
            ellipsis: true,
            render: (request: Expense | Mileage | PerDiem | Hospitality | TripFolder) => {
                if (request.kind === ExpenseKindEnum.TRIP_FOLDER) return <></>
                const description = request.description || (request as Mileage).tripReason || (request as PerDiem).reason
                return (
                    <div className="text-sm sm:text-base leading-tight truncate max-h-20 text-left" onClick={(e) => e.preventDefault()}>
                        {showExpenseNumbers && `# ${request.expenseNumber}${description || request.isAutoGenerated ? " - " : ""}`}
                        {description ? parseHtml(description) : request.isAutoGenerated ? <span className="italic">{t("info:auto_generated_request")}</span> : null}
                    </div>
                )
            },
        },
        {
            title: "reminder",
            key: "reminder",
            align: "center",
            width: 50,
            render: (request: Expense) => <ExpenseReminderInfo request={request} />,
        },
        ...insertIf(showActionButtons, {
            title: "action_buttons",
            key: "action_buttons",
            align: "center",
            width: 315,
            render: (request: Expense) => <RequestActionButtons request={request} isExportButtonLoading={isExportButtonLoading} {...requestButtonProps} />,
        }),
        {
            title: "tags",
            key: "tags",
            align: "center",
            width: 155,
            render: (expense) => <ExpenseStatusTag status={expense.status} isDraft={isFolderExpense(expense) ? expense.isDraft : undefined} />,
        },
        {
            title: "totalNetPrice",
            key: "totalNetPrice",
            align: "center",
            width: 160,
            render: (request: Expense) => (
                <div className="flex flex-col items-end">
                    <p className="flex items-center text-text-dark leading-none mb-2">
                        {request.paymentFlowInformation && (
                            <Tooltip
                                getPopupContainer={getTooltipPopupContainer}
                                title={t(`tooltips:expense_payment_flow_information.${request.paymentFlowInformation}`)}
                                className="ml-5"
                            >
                                <Icon icon={request.paymentFlowInformation} className="mr-8" />
                            </Tooltip>
                        )}
                        <b className="flex text-right">
                            <PriceLabel
                                value={showGross ? request.totalGrossPrice : request.totalNetPrice}
                                currency={request.currency}
                                interval={request.kind === "Subscription" ? getSubscriptionPriceInterval(request) : PriceIntervalEnum.ONE_TIME}
                            />
                        </b>
                    </p>
                    <p className="text-sm mb-5">{moment(request.datePurchased).format("ll")}</p>
                </div>
            ),
        },
    ]

    return (
        <div className="animation-appear overflow-x-auto">
            {table.error ? (
                <ErrorTable onTableReload={onTableReload} isLoading={table.isFetching} />
            ) : table.data.totalDocs === 0 && !table.isFetching ? (
                <EmptyTable showSearchIcon={!!dashboardSearchString} noSearchText={t("info:no_search_data_found.title")} noDocumentText={t("info:empty_state.requests.title")} />
            ) : (
                <Table<Expense>
                    rowKey={(record) => record.id}
                    dataSource={table.data.docs}
                    columns={columns}
                    onChange={onTableChange}
                    showHeader={false}
                    scroll={{ scrollToFirstRowOnChange: false }}
                    loading={{
                        spinning: table.isFetching,
                        indicator: <LoadingOutlined style={{ fontSize: 30, color: "black" }} spin />,
                    }}
                    pagination={{
                        position: ["bottomRight"],
                        showSizeChanger: true,
                        current: table.data.page || 1,
                        pageSize: table.data.limit,
                        hideOnSinglePage: false,
                        total: table.data.totalDocs,
                        pageSizeOptions: ["5", "10", "20", "50", "100"],
                    }}
                    onRow={(record, _rowIndex) => ({
                        onClick: (_event) => {
                            dispatch(setExpense(record))
                            history.push(`/expenses/${record.id}`)
                        },
                    })}
                    expandIcon={(props) => <ExpandTripFolderTableIcon props={props} />}
                />
            )}
        </div>
    )
}
