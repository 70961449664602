import { CostCenter } from "@finway-group/shared/lib/models"
import { Form, Select } from "antd"
import React from "react"

import DeletedTooltip from "Components/deletedTooltip"
import { SplitRules } from "Components/forms/rules/splitRules"
import { useCompany } from "Shared/hooks/company.hooks"
import { useCostCenterById, useSubCostCenters } from "Shared/hooks/costCenter.hooks"
import { getCostCenterLabel } from "Shared/utils/costCenter.utils"
import { doFilterSelect } from "Shared/utils/helper.utils"

import { useMainDetailsSectionContext } from "../mainDetailsSection.context"
import { ExpenseSplitCellInterface } from "./expenseSplitCellInterface"

const CostCenterCell: React.FC<ExpenseSplitCellInterface> = ({ field, splitIndex, editable }) => {
    const { expense, splits, onSplitUpdate } = useMainDetailsSectionContext()
    const currentSplit = splits[splitIndex]

    const rules = SplitRules(expense.splits)
    const costCenters = useSubCostCenters(true)
    const { showCostCentersIds } = useCompany()

    const generateCell = () => {
        const actualCC = useCostCenterById(currentSplit.costCenter)
        if (!editable && actualCC)
            return (
                <div>
                    ID {actualCC.id} - {actualCC.name}
                </div>
            )

        return (
            <Form.Item name={[field.name, "costCenter"]} key="costCenter" rules={rules.costCenter} className="mb-0" style={{ marginBottom: 0 }}>
                <Select
                    showSearch
                    filterOption={doFilterSelect}
                    onChange={() => onSplitUpdate(splitIndex)}
                    dropdownMatchSelectWidth={false}
                    className="text-xs md:text-base no-padding-select"
                    dropdownClassName="pl-0 z-50"
                    bordered={false}
                    placeholder="-"
                >
                    {actualCC?.deleted && (
                        <Select.Option value={actualCC._id} key={costCenters.length}>
                            <div>
                                {getCostCenterLabel(actualCC, showCostCentersIds)}
                                <DeletedTooltip translationKey="tooltips:cost_center.was_deleted" deletionDate={actualCC.dateDeleted} anchorToDocumentBody />
                            </div>
                        </Select.Option>
                    )}
                    {costCenters.map((cc: CostCenter, index) => (
                        <Select.Option value={cc._id} key={index} label={getCostCenterLabel(cc, showCostCentersIds)}>
                            {getCostCenterLabel(cc, showCostCentersIds)}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
        )
    }
    return generateCell()
}

export default CostCenterCell
