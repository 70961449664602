import { LiquidityForecastRule, LiquidityScenario } from "@finway-group/shared/lib/models"
import { useSelector } from "react-redux"

import { RootState } from "Shared/store/rootState.interface"

export const useScenarios = () => useSelector(({ liquidity }: RootState) => liquidity.scenarios) || []

export const useScenario = () => useSelector(({ liquidity }: RootState) => liquidity.scenario)

export const useInterval = () => useSelector(({ liquidity }: RootState) => liquidity.interval)

export const useForecastRules = () => useSelector(({ liquidity }: RootState) => liquidity.forecastRules) || []

export const useForecastRuleById = (id: string) => useForecastRules().find((forecastRule: LiquidityForecastRule) => forecastRule._id === id)

export const useLiquidityActiveTab = () => useSelector(({ liquidity }: RootState) => liquidity.activeTab)

export const useCashFlows = () => useSelector(({ liquidity }: RootState) => liquidity.cashFlows) || []

export const useYearlyCashFlows = () => useSelector(({ liquidity }: RootState) => liquidity.yearlyCashFlows) || []

export const useLast6MonthsCashFlows = () => useSelector(({ liquidity }: RootState) => liquidity.last6MonthsCashFlows) || []

export const useShouldFetchCashFlow = () =>
    useSelector(({ liquidity }: RootState) => ({
        cashFlows: liquidity.shouldFetchCashFlows,
        yearlyCashFlows: liquidity.shouldFetchYearlyCashFlows,
        last6MonthsCashFlows: liquidity.shouldFetchLast6MonthsCashFlows,
    }))

export const useBankBalanceData = () => useSelector(({ liquidity }: RootState) => liquidity.bankBalanceData) || []

export const useShouldFetchBankBalanceData = () => useSelector(({ liquidity }: RootState) => liquidity.shouldFetchBankBalanceData)
