import { LoadingOutlined } from "@ant-design/icons"
import { Expense, TripFolder } from "@finway-group/shared/lib/models"
import { AutoComplete, Modal } from "antd"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useDebouncedCallback } from "use-debounce/lib"

import { DEBOUNCE_DURATION_SHORT } from "Shared/config/consts"
import { ExpenseHttpService, ExpenseService, NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { attachExpenses } from "Shared/store/actions/expense/expenseActions"
import { FOLDER_CHILDREN_LIMIT } from "Shared/utils/folder.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"
import { getPopupAnchor } from "Shared/utils/popup.utils"

interface AttachToFolderModalProps {
    expense: Expense
    isShowing: boolean
    onCancel: () => void
}

export const AttachToFolderModal = ({ expense, isShowing, onCancel }: AttachToFolderModalProps) => {
    const [searchString, setSearchString] = useStateIfMounted<string>("")
    const [searchResults, setSearchResults] = useStateIfMounted<Array<Expense>>([])
    const [selectedFolder, setSelectedFolder] = useStateIfMounted<TripFolder | undefined>(undefined)
    const [isLoading, setIsLoading] = useStateIfMounted(false)
    const [disableSubmit, setDisableSubmit] = useStateIfMounted(true)
    const { t } = useTranslation()
    const dispatch = useDispatch()

    useEffect(() => {
        ExpenseService.searchFolders("")
            .then((results) => setSearchResults(results))
            .catch(() => {})
    }, [])

    const delayedSearchExpense = useDebouncedCallback((searchValue: string) => {
        ExpenseService.searchFolders(searchValue)
            .then((result) => {
                setSearchResults(result)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }, DEBOUNCE_DURATION_SHORT)

    const loadingOption = (
        <AutoComplete.Option value={""} key={"loading-option"} disabled>
            <div className="w-full text-center">
                <LoadingOutlined />
            </div>
        </AutoComplete.Option>
    )

    const options = searchResults.map((result: TripFolder) => (
        <AutoComplete.Option key={result.id} label={result.folderName} value={result.id}>
            {result.folderName}
        </AutoComplete.Option>
    ))

    const onSubmit = async () => {
        if (selectedFolder) {
            try {
                await attachExpenses(selectedFolder.id, [expense.id], true)(dispatch)
                NotificationService.send(
                    NotificationTypeEnum.SUCCESS,
                    t(`notification:request.attached.title`),
                    t(`notification:request.attached.message`, { folderName: selectedFolder?.folderName }),
                )
                onCancel()
            } catch (err) {
                NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:error"))
            }
        }
    }

    const handleFolderSelection = async (folderId: string) => {
        const filter = {
            filterQueryString: `&folderId[eq]=${folderId}`,
            limit: 20,
            page: 1,
            searchQueryString: "",
            sortQueryString: "",
        }
        const childrenCount = (await ExpenseHttpService.fetchAllExpenses(filter, "")).totalDocs

        const targetFolder = searchResults.find((folder) => folder.id === folderId) as TripFolder
        setSelectedFolder(targetFolder)
        setDisableSubmit(!targetFolder || childrenCount >= FOLDER_CHILDREN_LIMIT)
        setSearchString(targetFolder?.folderName ?? "")
    }

    return (
        <Modal
            visible={isShowing}
            onOk={onSubmit}
            onCancel={onCancel}
            okText={t("action:request.attach_to_folder")}
            title={t("action:request.add_request_to_folder")}
            okButtonProps={{ disabled: disableSubmit }}
        >
            <div className="w-full">
                <div className="mb-12">{t("label:trip_folder_request")}</div>
                <AutoComplete
                    className="w-full"
                    showSearch
                    getPopupContainer={getPopupAnchor()}
                    onSearch={(searchValue) => {
                        setIsLoading(true)
                        setSearchString(searchValue)
                        delayedSearchExpense.callback(searchValue)
                    }}
                    onSelect={handleFolderSelection}
                    value={searchString}
                >
                    {isLoading ? loadingOption : options}
                </AutoComplete>
            </div>
        </Modal>
    )
}
