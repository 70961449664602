import { FormDueInDaysEnum } from "@finway-group/shared/lib/models/form/dueInDays.enum"
import Col from "antd/lib/col"
import Collapse from "antd/lib/collapse"
import Form from "antd/lib/form"
import Input from "antd/lib/input"
import Radio from "antd/lib/radio"
import Row from "antd/lib/row"
import Space from "antd/lib/space"
import React, { useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import { FormCategoryEnum, FormMinMaxDateDueDaysInterface } from "./form.types"
import FormInputDate from "./formInputDate"

export default function FormMinMaxDateDueDays({ header, min, max, days, keyCode = "" }: FormMinMaxDateDueDaysInterface) {
    const { t } = useTranslation()
    const [value, setValue] = useState(() => (days.initialValue ? FormDueInDaysEnum.DAY_RANGE : FormDueInDaysEnum.DATE_RANGE))
    const anchorRef = useRef(null)
    const [activeCategory, setActiveCategory] = useState({
        isMin: !!min.initialValue,
        isMax: !!max.initialValue,
        isDays: !!days.initialValue,
    })

    return (
        <>
            {/* empty div to fix ant-design bug for non-sticky dropdowns */}
            <div ref={anchorRef} />
            <Collapse ghost defaultActiveKey={keyCode} className={activeCategory.isMin || activeCategory.isMax || activeCategory.isDays ? "filter--active" : ""}>
                <Collapse.Panel header={header} key={keyCode} forceRender>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Form.Item name="dueInDaysRadio" style={{ marginBottom: "0px" }}>
                                <Radio.Group className="flex items-center w-full" size="large" onChange={(e) => setValue(e.target.value)} value={value}>
                                    <Space direction="vertical" className="w-full">
                                        {/** Had to separate the radio, label, and input because we have to align the content of the top row and the bottom row... :/ */}
                                        <Row align="middle">
                                            <Col span={2}>
                                                <Radio value={FormDueInDaysEnum.DATE_RANGE} />
                                            </Col>
                                            <Col span={3} style={{ fontSize: "14px" }}>
                                                {min.label}:
                                            </Col>
                                            <FormInputDate
                                                elem={min}
                                                hideLabel
                                                span={8}
                                                activeCategory={activeCategory}
                                                setActiveCategory={setActiveCategory}
                                                category={FormCategoryEnum.MIN}
                                                anchorRef={anchorRef}
                                            />
                                            <Col span={3} style={{ fontSize: "14px" }} className="flex justify-end pr-16">
                                                {max.label}:
                                            </Col>
                                            <FormInputDate
                                                elem={max}
                                                hideLabel
                                                span={8}
                                                activeCategory={activeCategory}
                                                setActiveCategory={setActiveCategory}
                                                category={FormCategoryEnum.MAX}
                                                anchorRef={anchorRef}
                                            />
                                        </Row>
                                        <Row align="middle">
                                            <Col span={2}>
                                                <Radio value={FormDueInDaysEnum.DAY_RANGE} className="flex items-center" />
                                            </Col>
                                            <Col span={3} style={{ fontSize: "14px" }}>
                                                {days.label}:
                                            </Col>
                                            <Col span={8}>
                                                <Form.Item name={days.name} initialValue={days.initialValue} style={{ marginBottom: "0px" }} rules={days.rules}>
                                                    <Input
                                                        placeholder={days.placeholder}
                                                        onChange={(e) => {
                                                            if (days.onChange) days.onChange(e)
                                                            setActiveCategory({ ...activeCategory, isDays: !!e.target.value })
                                                        }}
                                                        suffix={t("input:filter_form.days")}
                                                        min="0"
                                                        size="small"
                                                        className={activeCategory.isDays ? "filter--active flex items-center" : "flex items-center"}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Space>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                </Collapse.Panel>
            </Collapse>
        </>
    )
}
