import { Currencies } from "@finway-group/shared/lib/consts"
import { Expense, currencyEnumArray } from "@finway-group/shared/lib/models"
import { Col, DatePicker, Form, Image, Row, Select } from "antd"
import { FormInstance } from "antd/lib/form"
import { Store } from "antd/lib/form/interface"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { gobdNonCompliantDates } from "Shared/utils/date.utils"
import { doFilterSelect } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"
import { getPopupAnchor } from "Shared/utils/popup.utils"

import TotalAmountForm from "./expenseForm/commonInputFields/totalAmount.form"
import { ExpenseRules } from "./rules"

interface CreateRecurringExpenseFormInterface {
    formInstance: FormInstance
    handleSubmit: (values: Store) => void
    parentSubscription: Expense
}

const CreateRecurringExpenseForm: React.FC<CreateRecurringExpenseFormInterface> = ({ formInstance, handleSubmit, parentSubscription }) => {
    const { t } = useTranslation()
    const [isLinked, setIsLinked] = useStateIfMounted(true)
    const [expense, setExpense] = useState<Expense>(new Expense({ ...parentSubscription, datePurchased: moment() }))
    const { datePurchased } = ExpenseRules({ expense })

    useEffect(() => {
        formInstance.setFieldsValue({ ...formInstance.getFieldsValue(), ...expense })
    }, [expense])

    const updateExpense = (data: any) => {
        const formData = formInstance.getFieldsValue()
        setExpense((old) => new Expense({ ...old, ...formData, ...data }))
    }

    return (
        <div>
            <Form form={formInstance} layout="vertical" onFinish={handleSubmit}>
                <TotalAmountForm expense={expense} updateExpense={updateExpense} isLinked={isLinked} setIsLinked={setIsLinked} expenseForm={formInstance} isNew={true} />
                <Row gutter={[16, 16]}>
                    <Col span={12} sm="24">
                        <Form.Item label={t("input:request.date")} key="datePurchased" name="datePurchased" required rules={datePurchased}>
                            <DatePicker
                                name="datePurchased"
                                style={{ width: "100%" }}
                                format={moment.localeData().longDateFormat("L")}
                                placeholder={t("placeholder:request.date")}
                                getPopupContainer={getPopupAnchor()}
                                disabledDate={gobdNonCompliantDates}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12} sm="24">
                        <Form.Item name="currency" label={t("input:request.currency")} key="currency" initialValue={parentSubscription.currency}>
                            <Select showSearch filterOption={doFilterSelect} getPopupContainer={getPopupAnchor()} onChange={(value) => updateExpense({ currency: value })}>
                                {currencyEnumArray.map((currencyValue, index) => (
                                    <Select.Option key={index} value={currencyValue} label={currencyValue}>
                                        <div className="space-x-10">
                                            <Image className="align-middle" width={20} preview={false} src={`data:image/png;base64,${Currencies[currencyValue].flag}`} />
                                            <span className="text-text">{currencyValue}</span>
                                            <span className="text-text">-</span>
                                            <span className="text-text">{Currencies[currencyValue].name}</span>
                                        </div>
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default CreateRecurringExpenseForm
