import { FeatureFlagEnum, RightEnum, SystemRightEnum } from "@finway-group/shared/lib/models"
import React from "react"

import SettingsContainer from "Features/settings/settings.container"
import { RoutePathEnum } from "Shared/router/RoutePath.enum"
import { AuthzService } from "Shared/services"

const CostCenter2Settings = React.lazy(() => import("./costCenter2/costCenter2Settings"))
const ExpenseAccountSettings = React.lazy(() => import("./expenseAccounts/expenseAccountSettings"))
const CompanyIntegrations = React.lazy(() => import("./integrations/companyIntegrations"))
const TaxSettings = React.lazy(() => import("./taxes/taxSettings"))
const TeamSettings = React.lazy(() => import("./teams/teamSettings"))
const CompanySettings = React.lazy(() => import("./company/companySettings"))
const BankSettings = React.lazy(() => import("./banks/bankSettings"))
const FeatureSettings = React.lazy(() => import("./features/featureSettings"))
const ExportHistoryList = React.lazy(() => import("./exportHistory/exportHistoryList"))
const NotificationSettingsContainer = React.lazy(() => import("./notifications/notificationSettingsContainer"))

const CompanySettingsContainer = () => {
    const menuList = [
        {
            link: RoutePathEnum.SETTINGS_COMPANY_SETTINGS,
            titleKey: "label:company_settings",
            requiredFeatureFlags: [FeatureFlagEnum.PAID_VERSION],
            canSee: AuthzService.isSystemRightGrantedForLoggedInUser(SystemRightEnum.SYS__COMPANY__ALL__READ),
            component: <CompanySettings />,
            isDefault: true,
        },
        {
            link: RoutePathEnum.SETTINGS_FEATURE,
            titleKey: "label:feature_settings",
            requiredFeatureFlags: [FeatureFlagEnum.PAID_VERSION],
            canSee: AuthzService.isRightGrantedForLoggedInUser(RightEnum.COMPANY_FEATURE_SETTINGS__ALL__READ),
            component: <FeatureSettings />,
        },
        {
            link: RoutePathEnum.SETTINGS_BANK_ACCOUNTS,
            titleKey: "label:bank_accounts",
            requiredFeatureFlags: [],
            canSee: AuthzService.isRightGrantedForLoggedInUser(RightEnum.BANK_ACCOUNT__ALL__READ),
            component: <BankSettings />,
        },
        {
            link: RoutePathEnum.SETTINGS_EXPENSE_ACCOUNTS,
            titleKey: "label:expense_accounts",
            requiredFeatureFlags: [FeatureFlagEnum.PAID_VERSION],
            canSee: AuthzService.isRightGrantedForLoggedInUser(RightEnum.COMPANY_ACCOUNTING_SETTINGS__ALL__READ),
            component: <ExpenseAccountSettings />,
        },
        {
            link: RoutePathEnum.SETTINGS_CC2,
            titleKey: "label:cost_center_2",
            requiredFeatureFlags: [FeatureFlagEnum.PAID_VERSION],
            canSee: AuthzService.isRightGrantedForLoggedInUser(RightEnum.COMPANY_ACCOUNTING_SETTINGS__ALL__READ),
            component: <CostCenter2Settings />,
        },
        {
            link: RoutePathEnum.SETTINGS_VAT,
            titleKey: "label:vat_title",
            requiredFeatureFlags: [FeatureFlagEnum.PAID_VERSION],
            canSee: AuthzService.isRightGrantedForLoggedInUser(RightEnum.COMPANY_ACCOUNTING_SETTINGS__ALL__READ),
            component: <TaxSettings />,
        },
        {
            link: RoutePathEnum.SETTINGS_TEAMS,
            titleKey: "label:teams",
            requiredFeatureFlags: [],
            canSee: AuthzService.isRightGrantedForLoggedInUser(RightEnum.COMPANY_ACCOUNTING_SETTINGS__ALL__READ),
            component: <TeamSettings />,
        },
        {
            link: RoutePathEnum.SETTINGS_INTEGRATIONS,
            titleKey: "label:integrations",
            requiredFeatureFlags: [FeatureFlagEnum.PAID_VERSION],
            canSee: AuthzService.canViewCompanyAccountingSettings(),
            component: <CompanyIntegrations />,
        },
        {
            link: RoutePathEnum.SETTINGS_NOTIFICATIONS,
            titleKey: "label:notifications.title",
            requiredFeatureFlags: [FeatureFlagEnum.PAID_VERSION],
            canSee: AuthzService.canUpdateFeatureCompanySettings(),
            component: <NotificationSettingsContainer />,
        },
        {
            link: RoutePathEnum.SETTINGS_EXPORT_HISTORY,
            titleKey: "label:export_history.title",
            requiredFeatureFlags: [FeatureFlagEnum.PAID_VERSION],
            canSee: true,
            component: <ExportHistoryList />,
        },
    ]

    return <SettingsContainer menuList={menuList} defaultPath={RoutePathEnum.SETTINGS_COMPANY} />
}

export default React.memo(CompanySettingsContainer)
