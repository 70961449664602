import { CostCenterFilter, EmployeeFilter, Expense, TransactionCategoryTypeEnum, User, VendorFilter } from "@finway-group/shared/lib/models"

import { WorkflowFilter } from "Components/forms/workflowFilter.form"
import { getCostCentersFromStore } from "Shared/hooks/costCenter.hooks"
import { getEmployeesFromStore } from "Shared/hooks/employee.hooks"
import { getIsTravelEnabledFeatureFromStore } from "Shared/hooks/featureFlags.hooks"
import { getTableFilterObjectStore, tableOptions } from "Shared/hooks/table.hooks"
import { getParentTransactionCategoriesFromStore } from "Shared/hooks/transactionCategory.hooks"
import { getVendorsFromStore } from "Shared/hooks/vendor.hooks"
import { getWorkflowsFromStore } from "Shared/hooks/workflow.hooks"
import { AnalyticsService, ExpenseHttpService, TransactionService, UserService } from "Shared/services"
import CardService from "Shared/services/card.service"
import ExportHistoryService from "Shared/services/exportHistory.service"
import InboxInvoiceService from "Shared/services/inboxInvoice.service"
import store from "Shared/store"
import { fetchTableSuccess } from "Shared/store/actions/tables/tableActions"
import { TablesEnum } from "Shared/store/reducers/tableConfigReducer"
import { CostCenterWithBudgetData, EmployeeWithExpenseData, TransactionCategoryWithChildren } from "Shared/store/reducers/tableReducer"
import { getExpenseModel, isFolderExpense } from "Shared/utils/expense.utils"
import { getFilteredCostCenter1, getFilteredEmployees, getFilteredVendors, getFilteredWorkflows } from "Shared/utils/filter.utils"
import { convertSearchValueToQuery, groupBy } from "Shared/utils/helper.utils"
import {
    TableDocType,
    buildTableQueryString,
    generateTableFilterQueryString,
    getChildrenQuery,
    getFolderIds,
    getSearchTargets,
    getTableDocType,
    isTableFilterApplied,
} from "Shared/utils/table.utils"

const TableService = {
    /**
     * For all folders we need to fetch all children in bulk
     * @param expenses Expenses missing type because of missing mapping while fetching the expenses
     * @param Options
     * @returns trip folder list
     */
    fetchFolderChildren: async (expenses: Array<Expense>, { queryParams, source, timeout }: any, applySearchQuery = false) => {
        const folderIds = getFolderIds(expenses)
        const childrenQuery = getChildrenQuery({ queryParams, applySearchQuery, folderIds })

        const childrenDocs = (await ExpenseHttpService.fetchAllExpenses(childrenQuery, source, timeout)).docs
        const groupedChildren = groupBy(childrenDocs.map(getExpenseModel), "folderId")

        const tripFolders = expenses.map((expense: any) => {
            expense.children = groupedChildren[expense._id] ?? []
            return getExpenseModel(expense)
        })

        return tripFolders
    },
    fetchTableData: async (table: TablesEnum, queryParams: any, source: any, options: tableOptions, timeout?: number) => {
        const tableDocType = getTableDocType(table)

        switch (tableDocType) {
            case TableDocType.INBOX_INVOICE:
                if (table === TablesEnum.INBOX_INVOICE_MODAL_INVOICES && options.expenseId) {
                    queryParams.filterQueryString = `${queryParams.filterQueryString}&expense[eq]=${options.expenseId}`
                }
                return InboxInvoiceService.fetchInboxInvoices(queryParams, source, timeout)
            case TableDocType.EXPENSE:
                if (table === TablesEnum.INBOX_INVOICE_MODAL_EXPENSES && options.inboxInvoiceId) {
                    queryParams.filterQueryString = `${queryParams.filterQueryString}&inboxInvoice[eq]=${options.inboxInvoiceId}`
                }

                // This enables the filter/search for subexpenses inside a folder as some fields are not present in the parent expense
                // check https://levaroio.atlassian.net/browse/CUS-288 for context
                const isFilterActive = queryParams.filterQueryString !== TableService.getTableBaseFilterQueryString(table)
                const folderQuery = isFilterActive ? "" : "&folderId[set]=false"

                const expensesResponse = await ExpenseHttpService.fetchAllExpenses(
                    { ...queryParams, filterQueryString: `${queryParams.filterQueryString}${folderQuery}` },
                    source,
                    timeout,
                )

                if (expensesResponse.docs.some((expense: Expense) => isFolderExpense(expense))) {
                    const tripFolders = await TableService.fetchFolderChildren(expensesResponse.docs, { queryParams, source, timeout })
                    return { ...expensesResponse, docs: tripFolders }
                }

                return expensesResponse
            case TableDocType.TRIP_FOLDER:
                const expenses = await ExpenseHttpService.fetchAllExpenses(queryParams, source, timeout)

                const tripFolders = await TableService.fetchFolderChildren(expenses.docs, { queryParams, source, timeout })

                return { ...expenses, docs: tripFolders }
            case TableDocType.CARD:
                return CardService.fetchAllCards(queryParams, source, timeout)
            case TableDocType.TRANSACTION:
                if (table === TablesEnum.CARD_TRANSACTIONS && options.cardId) {
                    return TransactionService.fetchCardTransactions(queryParams, source, options.cardId, timeout)
                }
                if (table === TablesEnum.EXPENSE_TRANSACTIONS && options.expenseId) {
                    queryParams.filterQueryString = `${queryParams.filterQueryString}&expense[eq]=${options.expenseId}`
                }

                return TransactionService.fetchAllTransactions(queryParams, source, timeout)

            /*
            The employee table is not from the api re-fetched so we have to filter on the frontend
        */
            case TableDocType.EMPLOYEE:
                const employees = getEmployeesFromStore({ excludeDeleted: true, includeAutoApprover: false, searchValue: queryParams.search })
                const tableFilterObject = getTableFilterObjectStore<EmployeeFilter>(table)

                const filteredEmployees = getFilteredEmployees(tableFilterObject, employees as Array<EmployeeWithExpenseData>)
                return { docs: filteredEmployees, totalDocs: filteredEmployees.length }

            case TableDocType.COST_CENTER: {
                const loggedInUser = UserService.getLoggedInEmployeeProfile()
                const costCenters = JSON.parse(JSON.stringify(getCostCentersFromStore(true, true, queryParams.search)))
                const costCentersCopy = JSON.parse(JSON.stringify(costCenters))
                const tableFilterObject = getTableFilterObjectStore<CostCenterFilter>(table)

                // add budget data to cost centers◊
                const costCenterIds = costCenters.map((costCenter: any) => costCenter._id)
                AnalyticsService.fetchCostCenterBudgetData(costCenterIds, loggedInUser.settings.globalCurrency)
                    .then((budgetData) => {
                        for (const costCenter of costCentersCopy as Array<CostCenterWithBudgetData>) {
                            costCenter.budgetData = budgetData.find((doc: any) => doc.id === costCenter._id)
                        }
                        const filteredCostCenters = getFilteredCostCenter1(tableFilterObject, costCentersCopy)
                        store.dispatch(fetchTableSuccess(table, { docs: filteredCostCenters, totalDocs: filteredCostCenters.length }))
                    })
                    // if budgetData fails to fetch, we catch the error to still show the cost center without budget data.
                    .catch(() => {})

                const filteredCostCenters = getFilteredCostCenter1(tableFilterObject, costCenters)
                return { docs: filteredCostCenters, totalDocs: filteredCostCenters.length }
            }

            case TableDocType.VENDOR: {
                const vendors = getVendorsFromStore(true, queryParams.search)
                const tableFilterObject = getTableFilterObjectStore<VendorFilter>(table)
                const filteredVendors = getFilteredVendors(tableFilterObject, vendors)
                return { docs: filteredVendors, totalDocs: filteredVendors.length }
            }

            case TableDocType.WORKFLOW: {
                const workflows = getWorkflowsFromStore(queryParams.search)
                const tableFilterObject = getTableFilterObjectStore<WorkflowFilter>(table)
                const isTravelEnabled = getIsTravelEnabledFeatureFromStore()
                const filteredWorkflows = getFilteredWorkflows(tableFilterObject, workflows, isTravelEnabled)

                return { docs: filteredWorkflows, totalDocs: filteredWorkflows.length }
            }

            case TableDocType.TRANSACTION_CATEGORY: {
                let transactionCategoryType: any
                if (table === TablesEnum.TRANSACTION_CATEGORIES_INFLOW) {
                    transactionCategoryType = TransactionCategoryTypeEnum.INFLOW
                } else if (table === TablesEnum.TRANSACTION_CATEGORIES_OUTFLOW) {
                    transactionCategoryType = TransactionCategoryTypeEnum.OUTFLOW
                }

                const transactionCategoriesWithChildren: Array<TransactionCategoryWithChildren> = getParentTransactionCategoriesFromStore(
                    transactionCategoryType,
                    queryParams.search,
                )

                return { docs: transactionCategoriesWithChildren, totalDocs: transactionCategoriesWithChildren.length }
            }
            case TableDocType.EXPORT_HISTORY:
                return ExportHistoryService.fetchAllExportHistory(queryParams, source, timeout)
            default:
                break
        }
    },
    /**
     * Returns the base filter query string the table always has, regardless of the filters the user sets.
     * NOTE: Do not add sorting here, it won't work because we don't currently support two sorts at the same time.
     */
    getTableBaseFilterQueryString: (table: TablesEnum) => {
        const loggedInUserId = UserService.getLoggedInUserId()

        const archiveAfterXDaysQueryString = `&archiveAfterXDays[eq]=${store.getState().company.item.archiveAfterXDays}`
        const transactionArchiveAfterXDaysQueryString = `&archiveAfterXDays[eq]=${store.getState().company.item.transactionArchiveAfterXDays}`

        return buildTableQueryString({ table, loggedInUserId, transactionArchiveAfterXDaysQueryString, archiveAfterXDaysQueryString })
    },

    /**
     * Returns the search query string for a table that the user sets when using search boxes.
     */
    getTableSearchQueryString: (table: TablesEnum, search: string): string => {
        const loggedInUser = UserService.getLoggedInEmployeeProfile()
        const useGross = loggedInUser?.settings?.showGrossAmount

        const searchTargets = getSearchTargets(table, useGross)

        return convertSearchValueToQuery(search, searchTargets)
    },
    /**
     * Returns the filter query string that the user sets using the filter modals/buttons..
     */
    getTableFilterQueryString: (table: TablesEnum, filterObject: any) => {
        const loggedInUser = UserService.getLoggedInEmployeeProfile()
        const useGross = loggedInUser?.settings?.showGrossAmount

        return generateTableFilterQueryString(table, useGross, filterObject)
    },
    hasTableSearchOrFilterEnabled: (table: TablesEnum) => {
        const tableState = store.getState().tables[table]
        return tableState.data.search !== "" || isTableFilterApplied(tableState.data.filter)
    },
}

export default TableService
