import { LoadingOutlined } from "@ant-design/icons"
import { ActualForecastType, AntdTableCompatibleCashFlow, CashFlowRowIndexEnum, ForecastChange, LiquidityCashFlow, LiquidityScenario } from "@finway-group/shared/lib/models"
import { Col, Row, Table } from "antd"
import { ColumnsType } from "antd/lib/table"
import moment from "moment"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"

import { useTransactionCategories } from "Shared/hooks/transactionCategory.hooks"
import { useLoggedInEmployeeProfile } from "Shared/hooks/user.hooks"
import { LiquidityService, NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"
import { transformAllCashFlowsToTableCompatibleFormat } from "Shared/utils/liquidity.utils"

const ActualData = React.lazy(() => import("Features/pages/liquidity/tabs/cashflowPlanning/actualData"))
const ForecastData = React.lazy(() => import("Features/pages/liquidity/tabs/cashflowPlanning/forecastData"))

interface CashFlowDetailsInterface {
    cashFlowData: Array<LiquidityCashFlow>
    selectedScenario: LiquidityScenario
    isTableLoading: boolean
    onCashFlowChange: () => void
    setShowPlanTransactionModal: (showPlanTransactionModal: boolean) => void
}

const CashFlowDetails: React.FC<CashFlowDetailsInterface> = ({ cashFlowData, selectedScenario, isTableLoading, onCashFlowChange, setShowPlanTransactionModal }) => {
    const { t } = useTranslation()

    const transactionCategories = useTransactionCategories()
    const currentEmployee = useLoggedInEmployeeProfile()
    const { globalCurrency } = currentEmployee.settings

    const initialColumn: ColumnsType = [
        {
            title: <span className="font-bold text-text-dark">{t("label:liquidity.tabs.cashflow")}</span>,
            dataIndex: "rowTitle",
            key: "rowTitle",
            width: 250,
            fixed: "left",
            render: (value, record: any, index) => (
                <Row align="middle">
                    <Col span={16} className="overflow-x-ellipsis">
                        {value}
                    </Col>
                </Row>
            ),
        },
    ]

    const [tableColumns, setTableColumns] = useStateIfMounted(initialColumn)
    const [dataSource, setDataSource] = useStateIfMounted<Array<AntdTableCompatibleCashFlow>>([])

    const handleForecastChange = (periodIndex: string, record: AntdTableCompatibleCashFlow, newValue: number) => {
        // Enforcing so that spending will always have negative, and income will have positive
        const adjustedValue = record.rowIndex === CashFlowRowIndexEnum.TOTAL_SPENDING ? -Math.abs(newValue) : Math.abs(newValue)
        const difference = adjustedValue - record[periodIndex].forecast
        record[periodIndex].forecast = adjustedValue

        const forecastChanges: Array<ForecastChange> = [
            {
                period: moment(periodIndex, "YYYYMM").set("date", 15).toDate(),
                categoryId: record.categoryId,
                rowIndex: record.rowIndex,
                value: difference,
            },
        ]
        LiquidityService.updateCashFlowForecast(forecastChanges, false, selectedScenario._id)
            .then(() => onCashFlowChange())
            .catch((err) => NotificationService.send(NotificationTypeEnum.ERROR, t("error:error"), err))
    }

    useEffect(() => {
        if (cashFlowData) {
            const tableCompatibleData = transformAllCashFlowsToTableCompatibleFormat(cashFlowData, t, transactionCategories)
            fillLiquidityTable(tableCompatibleData)
        }
    }, [cashFlowData])

    const fillLiquidityTable = (tableCompatibleData: { tableDataSource: Array<AntdTableCompatibleCashFlow>; monthColumns: ColumnsType<unknown> }) => {
        const dataAvailability: any = {}
        if (cashFlowData) {
            for (const monthData of cashFlowData) {
                const periodIndex = `${moment(monthData.period).year()}${moment(monthData.period).month() + 1}`
                dataAvailability[periodIndex] = moment(monthData.period).isSameOrBefore(moment(), "month")
            }
        }
        for (const monthColumn of tableCompatibleData.monthColumns) {
            const periodIndex = monthColumn.key! as string

            monthColumn.render = (value: ActualForecastType, record: AntdTableCompatibleCashFlow, index: number) => {
                const hasActualData = dataAvailability[periodIndex]
                return (
                    <div className="text-sm">
                        <Row className="text-gray-800 cursor-text">
                            {hasActualData ? <ActualData value={value} currency={globalCurrency} record={record} periodIndex={periodIndex}></ActualData> : <p>-</p>}
                        </Row>
                        <Row>
                            <ForecastData
                                value={value}
                                periodIndex={periodIndex}
                                handleForecastChange={handleForecastChange}
                                record={record}
                                currency={globalCurrency}
                            ></ForecastData>
                        </Row>
                    </div>
                )
            }
        }

        setDataSource(tableCompatibleData.tableDataSource)
        setTableColumns([...initialColumn, ...tableCompatibleData.monthColumns])
    }

    const tableLoading = {
        spinning: !cashFlowData || cashFlowData.length === 0 || isTableLoading,
        indicator: <LoadingOutlined style={{ fontSize: 30, color: "#757575" }} spin />,
    }

    return <Table className="overflow-auto " size="small" columns={tableColumns} dataSource={dataSource} pagination={false} scroll={{ y: 800 }} loading={tableLoading} />
}

export default CashFlowDetails
