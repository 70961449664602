import { CurrencyEnum, CurrencyExchange, PriceIntervalEnum } from "@finway-group/shared/lib/models"
import { CurrencyExchangeObjectType } from "@finway-group/shared/lib/models/common/currencyExchangeObjectType.enum"
import { Skeleton, Tooltip } from "antd"
import moment from "moment"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"

import { useCurrentCurrencyExchange } from "Shared/hooks/currency.hooks"
import { CurrencyService } from "Shared/services"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

import PriceLabel from "./priceLabel"

interface ExpenseConversionInfoInterface {
    value: number
    sourceCurrency: CurrencyEnum
    destCurrency: CurrencyEnum
    exchangeId?: string
    exchangeDate?: moment.Moment
    showSourceAmount?: boolean
    className?: string
    sourceAmount?: number // if this param is defined then it is a card transaction and we should manually calculate the exchange rates
    size?: "sm" | "lg"
}

const ExpenseConversionInfo: React.FC<ExpenseConversionInfoInterface> = ({
    value,
    sourceCurrency,
    destCurrency,
    showSourceAmount = true,
    className = "",
    exchangeId,
    exchangeDate,
    sourceAmount,
    size = "lg",
}) => {
    const { t } = useTranslation()
    const currentForex = useCurrentCurrencyExchange()
    const [conversionFactor, setConversionFactor] = useStateIfMounted<number | undefined>(undefined)
    const [reversedConversionFactor, setReversedConversionFactor] = useStateIfMounted<number | undefined>(undefined)
    const [convertedAmount, setConvertedAmount] = useStateIfMounted<number | undefined>(undefined)
    const [currentCurrencyExchange, setCurrencyExchange] = useStateIfMounted<CurrencyExchange | undefined>(undefined)
    const [isLoading, setIsLoading] = useStateIfMounted(true)
    const noExchangeSourceDefined = !exchangeId && !exchangeDate

    const determineCurrencyExchange = async () => {
        if (sourceAmount) {
            setCurrencyExchange({
                baseCurrency: destCurrency,
                type: CurrencyExchangeObjectType.ROOT,
                rates: [{ currencyName: sourceCurrency, conversionFactor: value / sourceAmount }],
            })
        } else if (exchangeId || (exchangeDate && !exchangeDate.isSame(moment(), "day"))) {
            const currencyExchange = await CurrencyService.fetchCurrencyExchange(exchangeId, exchangeDate)
            setCurrencyExchange(currencyExchange)
        } else {
            setCurrencyExchange(currentForex)
        }
    }

    useEffect(() => {
        let mounted = true

        if (mounted) {
            determineCurrencyExchange().then(() => setIsLoading(false))
        }

        return () => {
            mounted = false
        }
    }, [exchangeId, exchangeDate?.format("YYYY-MM-DD")])

    useEffect(() => {
        setReversedConversionFactor(sourceAmount ? Number((value / sourceAmount).toFixed(6)) : CurrencyService.convert(1, destCurrency, sourceCurrency, 6, currentCurrencyExchange))
        setConversionFactor(sourceAmount ? Number((sourceAmount / value).toFixed(6)) : CurrencyService.convert(1, sourceCurrency, destCurrency, 6, currentCurrencyExchange))
        setConvertedAmount(sourceAmount ? Number(sourceAmount.toFixed(2)) : CurrencyService.convert(value, sourceCurrency, destCurrency, 2, currentCurrencyExchange))
    }, [currentCurrencyExchange, value, sourceAmount, sourceCurrency, destCurrency])

    const conversionInfo = conversionFactor ? `${conversionFactor} ${sourceCurrency}/${destCurrency}` : ``

    if (isLoading) {
        return (
            <div className={`opacity-75 flex justify-end ${className}`}>
                <Skeleton className="w-100" title={false} paragraph={{ rows: 1, width: 100 }} round={true} active />
            </div>
        )
    }

    const tooltip = (
        <div className="w-160">
            <div className="flex">
                <div className="flex-1">
                    {sourceCurrency}/{destCurrency}
                </div>
                <div className="flex-1">{conversionFactor}</div>
            </div>
            <div className="flex">
                <div className="flex-1">
                    {destCurrency}/{sourceCurrency}
                </div>
                <div className="flex-1">{reversedConversionFactor}</div>
            </div>
            <div className="flex justify-between">
                {sourceAmount ? (
                    <div>
                        {t("info:exchange.settlement")} {moment(exchangeDate).format("DD.MM.YYYY")}
                    </div>
                ) : (
                    <div>
                        {noExchangeSourceDefined ? t("info:exchange.estimate") : t("info:exchange.paid")}{" "}
                        {moment((currentCurrencyExchange as any).dateLastUpdated).format("DD.MM.YYYY")}
                    </div>
                )}
            </div>
        </div>
    )

    return (
        <div className={`inline-block opacity-75 ${className}`}>
            <Tooltip title={tooltip}>
                <div className="flex align-middle justify-start gap-4">
                    {showSourceAmount && <PriceLabel value={value} currency={sourceCurrency} interval={PriceIntervalEnum.ONE_TIME} showFullNumber />}
                    {size === "lg" && <div> ({conversionInfo})</div>}
                    <PriceLabel value={convertedAmount ?? 0} currency={destCurrency} interval={PriceIntervalEnum.ONE_TIME} showFullNumber />
                </div>
            </Tooltip>
        </div>
    )
}

export default ExpenseConversionInfo
