import { Transaction } from "@finway-group/shared/lib/models"
import { ActionCreator, Dispatch } from "redux"

import { TransactionTabTypeEnum } from "Features/pages/transactions/transactions.list"
import { TransactionService } from "Shared/services"
import { ThunkResult } from "Shared/store"
import { TablesEnum } from "Shared/store/reducers/tableConfigReducer"

import { setShouldFetchCashFlowsData } from "../liquidity/liquidityActions"
import { refetchTableIfNecessary } from "../tables/tableActions"
import {
    DeleteManyTransactionsAction,
    DeleteTransactionAction,
    FetchAllTransactionAction,
    FetchOneTransactionAction,
    SetActiveTransactionTabAction,
    SetShouldRefetchTransactionsAction,
    SetTransactionAction,
    SyncOneTransactionAction,
    TransactionActionTypes,
} from "./transactionTypes"

export const setTransaction: ActionCreator<ThunkResult<Transaction>> = (transaction: Transaction) => async (dispatch: Dispatch) => {
    const setTransaction: SetTransactionAction = {
        type: TransactionActionTypes.SET_TRANSACTION,
        transaction,
    }

    dispatch(setTransaction)

    return transaction
}

export const fetchOneTransaction: ActionCreator<ThunkResult<Transaction>> = (id: string) => async (dispatch: Dispatch) => {
    const transaction = await TransactionService.fetchOneMatchingTransaction(id)

    const fetchOneTransaction: FetchOneTransactionAction = {
        type: TransactionActionTypes.FETCH_ONE_TRANSACTION,
        transaction,
    }

    dispatch(fetchOneTransaction)

    return transaction
}

// prettier-ignore
export const fetchAllTransactions: ActionCreator<ThunkResult<Transaction>> = (page: number = 1, limit: number = 20, sortingCriteria = '', query = '') => async (dispatch: Dispatch) => {
    const { transactions, totalPages, totalDocs } = await TransactionService.fetchMatchingTransactions(page, limit, sortingCriteria, query);

    const fetchAllTransaction: FetchAllTransactionAction = {
        type: TransactionActionTypes.FETCH_ALL_TRANSACTION,
        transactions,
        page,
        limit,
        totalPages,
        totalDocs,
    };

    dispatch(fetchAllTransaction);

    // reset refetch flag
    setShouldRefetch(false)(dispatch);

    return transactions;
};

export const deleteTransaction = (id: string) => async (dispatch: Dispatch) => {
    await TransactionService.deleteTransaction(id)
    const deleteTransactionAction: DeleteTransactionAction = {
        type: TransactionActionTypes.DELETE_TRANSACTION,
        transactionId: id,
    }

    refetchTableIfNecessary(TablesEnum.CARD_TRANSACTIONS, [id])
    refetchTableIfNecessary(TablesEnum.TRANSACTIONS_INFLOW, [id])
    refetchTableIfNecessary(TablesEnum.TRANSACTIONS_OUTFLOW, [id])
    refetchTableIfNecessary(TablesEnum.TRANSACTIONS_ALL, [id])
    refetchTableIfNecessary(TablesEnum.EXPENSE_TRANSACTIONS, [id])
    refetchTableIfNecessary(TablesEnum.TRANSACTIONS_ARCHIVE, [id])

    dispatch(deleteTransactionAction)
}

export const deleteManyTransactions = (ids: Array<string>) => async (dispatch: Dispatch) => {
    await TransactionService.deleteManyTransactions(ids)
    const deleteTransactionsAction: DeleteManyTransactionsAction = {
        type: TransactionActionTypes.DELETE_MANY_TRANSACTIONS,
        transactionIds: ids,
    }

    refetchTableIfNecessary(TablesEnum.CARD_TRANSACTIONS, ids)
    refetchTableIfNecessary(TablesEnum.TRANSACTIONS_INFLOW, ids)
    refetchTableIfNecessary(TablesEnum.TRANSACTIONS_OUTFLOW, ids)
    refetchTableIfNecessary(TablesEnum.TRANSACTIONS_ALL, ids)
    refetchTableIfNecessary(TablesEnum.EXPENSE_TRANSACTIONS, ids)
    refetchTableIfNecessary(TablesEnum.TRANSACTIONS_ARCHIVE, ids)

    dispatch(deleteTransactionsAction)
}

export const syncTransaction = (id: string) => async (dispatch: Dispatch) => {
    const transaction = await TransactionService.fetchOneMatchingTransaction(id)
    const syncOneTransactionAction: SyncOneTransactionAction = {
        type: TransactionActionTypes.SYNC_ONE_TRANSACTION,
        transaction,
    }

    dispatch(syncOneTransactionAction)
    refetchTableIfNecessary(TablesEnum.CARD_TRANSACTIONS, [id])
    refetchTableIfNecessary(TablesEnum.TRANSACTIONS_INFLOW, [id])
    refetchTableIfNecessary(TablesEnum.TRANSACTIONS_OUTFLOW, [id])
    refetchTableIfNecessary(TablesEnum.TRANSACTIONS_ALL, [id])
    refetchTableIfNecessary(TablesEnum.EXPENSE_TRANSACTIONS, [id])
    refetchTableIfNecessary(TablesEnum.TRANSACTIONS_ARCHIVE, [id])

    return transaction
}

export const setShouldRefetch = (shouldRefetch: boolean) => async (dispatch: Dispatch) => {
    const setShouldRefetchAction: SetShouldRefetchTransactionsAction = {
        type: TransactionActionTypes.SET_SHOULD_REFETCH_TRANSACTIONS,
        shouldRefetch,
    }

    dispatch(setShouldRefetchAction)
}

export const updateTransactionCategoryField = (transactionId: string, transactionCategoryId: string) => async (dispatch: Dispatch) => {
    const transaction = await TransactionService.updateTransactionCategory(transactionId, transactionCategoryId)

    syncTransaction(transaction.id)(dispatch)
    setShouldFetchCashFlowsData(true)(dispatch)

    return transaction
}

export const setActiveTransactionTab: ActionCreator<ThunkResult<void>> = (activeTab: TransactionTabTypeEnum) => async (dispatch: Dispatch) => {
    const setActiveTransactionTabAction: SetActiveTransactionTabAction = {
        type: TransactionActionTypes.SET_ACTIVE_TRANSACTION_TAB,
        activeTab,
    }

    dispatch(setActiveTransactionTabAction)
}
