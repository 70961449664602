import { StandardRoleEnum } from "@finway-group/shared/lib/models"
import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { Userpilot } from "userpilot"

import { USERPILOT_APP_ID, USERPILOT_TEST_APP_ID } from "Shared/config/consts"
import { useRoleById } from "Shared/hooks/role.hooks"
import { useLoggedInEmployeeProfile } from "Shared/hooks/user.hooks"
import { isDev } from "Shared/utils/helper.utils"

const UserPilot: React.FC = () => {
    const location = useLocation()
    const loggedInUser = useLoggedInEmployeeProfile()
    const loggedInUserId = loggedInUser.id
    const [hasBooted, setHasBooted] = useState(false)
    const APP_ID = isDev ? USERPILOT_TEST_APP_ID : USERPILOT_APP_ID
    const userRole = useRoleById(loggedInUser.activeCompanyProfile?.roleId)

    // product specs: https://levaroio.atlassian.net/browse/WEB-1376
    const getRoleValueForUserPilot = () => {
        if (!userRole?.isReadOnly) return 5
        if (userRole?.name === StandardRoleEnum.EMPLOYEE) return 1
        if (userRole?.name === StandardRoleEnum.APPROVER) return 2
        if (userRole?.name === StandardRoleEnum.ADMIN) return 3
        if (userRole?.name === StandardRoleEnum.AUDITOR) return 4
        return undefined
    }

    // when the user ID changes (different logins or logouts), do the corresponding action
    useEffect(() => {
        if (!loggedInUserId && hasBooted) {
            shutdownUserPilot()
        } else if (loggedInUserId && !hasBooted) {
            bootUserPilot()
            identifyUser()
        }
    }, [loggedInUserId])

    useEffect(() => {
        Userpilot.reload()
    }, [location])

    // when user data that is used for userpilot changes, update as well
    useEffect(() => {
        if (loggedInUserId && hasBooted) identifyUser()
    }, [loggedInUser.lastName, loggedInUser.firstName, loggedInUser.activeCompanyProfile?.roleId, loggedInUser.email, loggedInUser.img, loggedInUser.settings?.language])

    const bootUserPilot = () => {
        Userpilot.initialize(APP_ID ?? "")
        setHasBooted(true)
    }

    const shutdownUserPilot = () => {
        try {
            Userpilot?.destroy()
        } catch (err) {
            console.log("Error shutting down UserPilot", err)
        }
        setHasBooted(false)
    }

    const identifyUser = () => {
        Userpilot.identify(loggedInUser.id, {
            name: loggedInUser.firstName,
            email: loggedInUser.email,
            role: getRoleValueForUserPilot(),
            system_admin: +loggedInUser.hasSystemRights,
            created_at: (loggedInUser as any).createdAt,
            locale_code: loggedInUser.settings?.language,
        })
    }

    return <></>
}

export default UserPilot
