import { Vendor } from "@finway-group/shared/lib/models"
import { Button, Col, Row } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { v4 as uuidv4 } from "uuid"

import VendorImage from "Components/VendorImage"
import GetMyInvoicesModal from "Components/modals/getMyInvoices.modal"
import { useLoggedInEmployeeProfile } from "Shared/hooks/user.hooks"
import { EmployeeService, NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { syncEmployee } from "Shared/store/actions/employee/employeeActions"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

interface GetMyInvoicesSettingsInterface {}

const getMyInvoicesVendor = new Vendor({ name: "GetMyInvoices", img: "https://logo.clearbit.com/GetMyInvoices.com", id: uuidv4() })

const GetMyInvoicesSettings: React.FC<GetMyInvoicesSettingsInterface> = ({}) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [isShowingModal, setIsShowingModal] = useStateIfMounted(false)
    const [isLoading, setIsLoading] = useStateIfMounted(false)
    const loggedInUser = useLoggedInEmployeeProfile()
    // TODO: typing
    const { getMyInvoicesUserId } = loggedInUser.activeCompanyProfile as any

    const onDisconnect = async (value: string) =>
        EmployeeService.updateGetMyInvoicesUserId(loggedInUser.id, value)
            .then(() => {
                dispatch(syncEmployee(loggedInUser.id))
                setIsLoading(false)
                NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:integrations.get_my_invoices.title"), t("notification:integrations.get_my_invoices.message"))
            })
            .catch((err) => {
                setIsLoading(false)
                NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:error"))
            })

    return (
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <div className="p-10 flex flex-col sm:flex-row border flex-grow w-full rounded-lg justify-between">
                    <div className="flex flex-row">
                        <div className="mr-10">
                            <VendorImage vendor={getMyInvoicesVendor} size={45} bordered />
                        </div>

                        <div className="align-left flex flex-col ml-10 justify-center">
                            <b className="truncate placeholder-primary">{t("label:get_my_invoices")}</b>
                            {getMyInvoicesUserId && (
                                <div>
                                    <span>
                                        {t("label:user_id")}: {getMyInvoicesUserId}
                                    </span>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="flex justify-end md:justify-center items-center mt-auto mb-auto float-right">
                        <Button onClick={() => setIsShowingModal(true)}>{getMyInvoicesUserId ? t("action:edit") : t("action:connect")}</Button>
                        {getMyInvoicesUserId && (
                            <Button loading={isLoading} className="ml-8" onClick={() => onDisconnect("")}>
                                {t("action:disconnect")}
                            </Button>
                        )}
                    </div>
                </div>
            </Col>
            <GetMyInvoicesModal isVisible={isShowingModal} onCancel={() => setIsShowingModal(false)} onSubmit={async (value: string) => onDisconnect(value)} />
        </Row>
    )
}

export default GetMyInvoicesSettings
