import { ExpenseKindEnum, ExpensePaymentFlowInformationEnum, ExpenseRequestTypeEnum, ExpenseStatusEnum, RightEnum } from "@finway-group/shared/lib/models"
import { Col, DatePicker, Form, Input, Row } from "antd"
import moment from "moment"
import React from "react"
import { useTranslation } from "react-i18next"
import { useDebouncedCallback } from "use-debounce/lib"

import { useExpenseFormContext } from "Components/modals/expenseCreateForm.context"
import { useEmployees } from "Shared/hooks/employee.hooks"
import { useIsTravelEnabled } from "Shared/hooks/featureFlags.hooks"
import { useVendors } from "Shared/hooks/vendor.hooks"
import { AuthzService } from "Shared/services"
import { gobdNonCompliantDates } from "Shared/utils/date.utils"
import { getPopupAnchor } from "Shared/utils/popup.utils"

import { ExpenseRules } from "../rules"
import ExpenseKindRadioField from "./ExpenseKindRadioField"
import CommonDocumentUpload from "./commonInputFields/commonDocumentUpload.form"
import ExpenseCommonInputFieldsForm from "./commonInputFields/commonInputFields.form"
import ExpenseFormLayout from "./expenseFormLayout"
import OneTimeExpenseFields from "./oneTimeExpenseFields"
import ReimbursementTypeSelector from "./reimbursementForms/reimbursementTypeSelector"
import SubscriptionExpenseFields from "./subscriptionExpenseFields"

interface InvoiceRequestInterface {}
export const InvoiceRequestForm: React.FC<InvoiceRequestInterface> = ({}) => {
    // prettier-ignore
    const [ { expense, updateExpense, ocrSettings, hideExpenseKindRadioOption, onKindChange, expenseForm, isNew, inboxInvoices, setIsOcrProcessed, activeDocumentsTab, setActiveDocumentsTab, setPrefilledVendorData, setIsUploading, isUploading, isSubscription, type } ] = useExpenseFormContext()
    const { t } = useTranslation()
    const employees = useEmployees()

    const { invoiceNumber, invoiceDate, invoiceDueDate, datePurchased, datePaidAt } = ExpenseRules({ expense, employees })

    const disabledInvoiceDueDate = (current: moment.Moment) => expense.invoiceDate && current.isBefore(expense.invoiceDate, "day")
    const disabledInvoiceDate = (current: moment.Moment) => expense.invoiceDueDate && current.isAfter(expense.invoiceDueDate, "day")

    const showPurchaseDate = expense.kind === ExpenseKindEnum.ONE_TIME_EXPENSE
    const showPaidDate =
        AuthzService.isRightGrantedForLoggedInUser(RightEnum.EXPENSE__ALL__ACCESS_TABLE_PAY_READ) &&
        (expense.status === ExpenseStatusEnum.PAID || expense.status === ExpenseStatusEnum.DONE || expense.datePaidAt)
    const shouldShowTwoRows = showPurchaseDate && showPaidDate
    const vendors = useVendors(true)
    const isTravelEnabled = useIsTravelEnabled()
    const areInvoiceFieldsRequired = expense.isAtLeastApproved() && expense.paymentFlowInformation !== ExpensePaymentFlowInformationEnum.REPORTING_ONLY

    const debouncedUpdate = useDebouncedCallback((updateData) => {
        updateExpense(updateData)
    }, 300)

    const { isOcrEnabled, isOcrItemizationEnabled, setIsOcrEnabled, setIsOcrItemizationEnabled, isOcrItemizationDisplayed } = ocrSettings

    // TODO_TRM maybe refactor CommonDocumentUpload ?
    const leftSide = (
        <>
            <CommonDocumentUpload
                expenseForm={expenseForm}
                isSubscription={isSubscription}
                expenseInvoices={!isNew && expense ? expense.invoices : []}
                expenseOffers={!isNew && expense ? expense.offers : []}
                expenseDeliveryNote={!isNew && expense ? expense.deliveryNote : undefined}
                ocrSettings={{ isOcrEnabled, isOcrItemizationEnabled, isOcrItemizationDisplayed, setIsOcrEnabled, setIsOcrItemizationEnabled }}
                isUploading={isUploading}
                setIsUploading={setIsUploading}
                expense={expense}
                updateExpense={updateExpense}
                vendors={vendors}
                inboxInvoices={expenseForm.getFieldValue("invoices") ?? inboxInvoices}
                invoicesRequired={expense.paymentFlowInformation !== ExpensePaymentFlowInformationEnum.REPORTING_ONLY}
                setIsOcrProcessed={setIsOcrProcessed}
                activeTab={activeDocumentsTab}
                onTabClick={setActiveDocumentsTab}
                setPrefilledVendorData={setPrefilledVendorData}
                isNew={isNew}
            />
        </>
    )

    const rightSide = (
        <>
            {!hideExpenseKindRadioOption && type !== ExpenseRequestTypeEnum.REIMBURSEMENT && <ExpenseKindRadioField expense={expense} onKindChange={onKindChange} />}
            {type === ExpenseRequestTypeEnum.REIMBURSEMENT && isTravelEnabled && <ReimbursementTypeSelector />}
            <Row gutter={[16, 16]} align="middle">
                <Col span={6} lg={6} md={24} sm={24} xs={24}>
                    <Form.Item
                        className="max-w-160 md:max-w-full"
                        label={t("input:request.invoice_number")}
                        name="invoiceNumber"
                        initialValue={expense.invoiceNumber}
                        rules={invoiceNumber}
                        required={areInvoiceFieldsRequired}
                    >
                        <Input
                            data-testid="expenseFormInvoiceNumber"
                            placeholder={t("placeholder:request.invoiceNumber")}
                            onChange={(e) => debouncedUpdate.callback({ invoiceNumber: e.target.value })}
                        />
                    </Form.Item>
                </Col>

                <Col xs={8} xl={6}>
                    <Form.Item label={t("input:request.invoice_date")} name="invoiceDate" required={areInvoiceFieldsRequired} rules={invoiceDate}>
                        <DatePicker
                            data-testid="expenseFormInvoiceDate"
                            name="invoiceDate"
                            format={moment.localeData().longDateFormat("L")}
                            placeholder={t("placeholder:request.date")}
                            getPopupContainer={getPopupAnchor()}
                            disabledDate={(current: moment.Moment) => gobdNonCompliantDates(current) || disabledInvoiceDate(current)}
                            onChange={(date) => debouncedUpdate.callback({ invoiceDate: date ? moment(date) : undefined })}
                        />
                    </Form.Item>
                </Col>
                {!shouldShowTwoRows && (
                    <>
                        <Col span={8} lg={6} md={6} sm={6} xs={8}>
                            <Form.Item label={t("input:request.invoice_due_date")} name="invoiceDueDate" rules={invoiceDueDate}>
                                <DatePicker
                                    data-testid="expenseFormInvoiceDueDate"
                                    name="invoiceDueDate"
                                    format={moment.localeData().longDateFormat("L")}
                                    placeholder={t("placeholder:request.date")}
                                    getPopupContainer={getPopupAnchor()}
                                    disabledDate={(current: moment.Moment) => gobdNonCompliantDates(current) || disabledInvoiceDueDate(current)}
                                    onChange={(date) => debouncedUpdate.callback({ invoiceDueDate: date ? moment(date) : undefined })}
                                />
                            </Form.Item>
                        </Col>
                        {showPurchaseDate && (
                            <Col xs={8} xl={6}>
                                <Form.Item label={t("input:request.performance_date")} name="datePurchased" rules={datePurchased} required>
                                    <DatePicker
                                        data-testid="expenseFormPerformanceDate"
                                        disabledDate={gobdNonCompliantDates}
                                        name="datePurchased"
                                        format={moment.localeData().longDateFormat("L")}
                                        placeholder={t("placeholder:request.date")}
                                        getPopupContainer={getPopupAnchor()}
                                        onChange={(date) => debouncedUpdate.callback({ datePurchased: date ? moment(date) : undefined })}
                                    />
                                </Form.Item>
                            </Col>
                        )}
                        {showPaidDate && (
                            <Col xs={8} xl={6}>
                                <Form.Item label={t("input:request.date_paid_at")} name="datePaidAt" rules={datePaidAt} required={expense.isSuccessfullyProcessed()}>
                                    <DatePicker
                                        name="datePaidAt"
                                        format={moment.localeData().longDateFormat("L")}
                                        placeholder={t("placeholder:request.date")}
                                        onChange={(date) => debouncedUpdate.callback({ datePaidAt: date ? moment(date) : undefined })}
                                    />
                                </Form.Item>
                            </Col>
                        )}
                    </>
                )}
            </Row>
            {shouldShowTwoRows && (
                <Row gutter={[16, 16]} align="middle">
                    <Col span={8} lg={6} md={6} sm={6} xs={8}>
                        <Form.Item label={t("input:request.invoice_due_date")} name="invoiceDueDate">
                            <DatePicker
                                disabledDate={gobdNonCompliantDates}
                                name="invoiceDueDate"
                                format={moment.localeData().longDateFormat("L")}
                                placeholder={t("placeholder:request.date")}
                                getPopupContainer={getPopupAnchor()}
                                onChange={(v) => updateExpense({ invoiceDueDate: (v as any) ?? undefined })}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={8} xl={6}>
                        <Form.Item label={t("input:request.performance_date")} name="datePurchased" rules={datePurchased} required>
                            <DatePicker
                                disabledDate={gobdNonCompliantDates}
                                name="datePurchased"
                                format={moment.localeData().longDateFormat("L")}
                                placeholder={t("placeholder:request.date")}
                                getPopupContainer={getPopupAnchor()}
                                onChange={(date) => debouncedUpdate.callback({ datePurchased: date ? moment(date) : undefined })}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={8} xl={6}>
                        <Form.Item label={t("input:request.date_paid_at")} name="datePaidAt" rules={datePaidAt} required={expense.isSuccessfullyProcessed()}>
                            <DatePicker
                                name="datePaidAt"
                                format={moment.localeData().longDateFormat("L")}
                                placeholder={t("placeholder:request.date")}
                                getPopupContainer={getPopupAnchor()}
                                onChange={(date) => debouncedUpdate.callback({ datePaidAt: date ? moment(date) : undefined })}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            )}
            {expense.kind === ExpenseKindEnum.ONE_TIME_EXPENSE ? <OneTimeExpenseFields /> : <SubscriptionExpenseFields />}
            <ExpenseCommonInputFieldsForm allowVendorCreation={true} showVendor={true} />
        </>
    )

    return <ExpenseFormLayout leftSide={leftSide} rightSide={rightSide}></ExpenseFormLayout>
}
