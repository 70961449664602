import DOMPurify from "dompurify"
import ReactHtmlParser, { convertNodeToElement } from "react-html-parser"

import { RoutePathEnum } from "Shared/router/RoutePath.enum"

const { sanitize } = DOMPurify

DOMPurify.addHook("afterSanitizeAttributes", (node: any) => {
    if (!("target" in node)) return
    if (node.getAttribute("href")?.startsWith(RoutePathEnum.EMPLOYEES)) return
    node.setAttribute("target", "_blank")
    node.setAttribute("rel", "noopener") // for security issues
})

const linkDomainExtractor = (link: string) => {
    let hostname
    // find & remove protocol (http, ftp, etc.) and get hostname
    if (link.indexOf("//") > -1) {
        hostname = link.split("/")[2]
    } else {
        hostname = link.split("/")[0]
    }

    // find & remove port number
    hostname = hostname.split(":")[0]
    // find & remove "?"
    hostname = hostname.split("?")[0]
    return hostname.replace("www.", "")
}

export const linkNodeTransform = (node: any, index: number) => {
    try {
        if (node.type === "tag" && node.name === "a") {
            node.children[0].data = linkDomainExtractor(node.children[0].data)
            return convertNodeToElement(node, index, linkNodeTransform)
        }
    } catch (err) {
        console.log(err)
    }

    return convertNodeToElement(node, index, linkNodeTransform)
}

export const extractTextFromHtml = (html: string) => {
    if (!html) return ""

    const temporalDivElement = document.createElement("div")
    temporalDivElement.innerHTML = html

    return temporalDivElement.textContent || temporalDivElement.innerText || html.replace(/<[^>]+>/g, "") || ""
}

export const parseHtml = (content: string) => ReactHtmlParser(sanitize(content), { transform: linkNodeTransform })
