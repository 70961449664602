import { Expense } from "@finway-group/shared/lib/models"
import { Switch } from "antd"
import { SwitchChangeEventHandler } from "antd/lib/switch"
import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import { NotificationService } from "Shared/services"
import { updateExpense } from "Shared/store/actions/expense/expenseActions"
import { isFolderExpense } from "Shared/utils/expense.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

interface ExpenseHeaderInterface {
    expense: Expense
}

export const ExpenseHeader: React.FC<ExpenseHeaderInterface> = ({ expense }) => {
    const [isSwitchLoading, setIsSwitchLoading] = useStateIfMounted(false)
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const onBillableSwitch: SwitchChangeEventHandler = async (checked: boolean) => {
        setIsSwitchLoading(true)
        expense.billable = checked
        try {
            await updateExpense(expense.id, expense)(dispatch)
        } catch (err) {
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:error"))
        } finally {
            setIsSwitchLoading(false)
        }
    }

    const BillableSwitch = () => (
        <>
            <span className="title w-240">{t("label:billable")}</span>
            <Switch checked={expense.billable} size="small" className="ml-6 mb-2" onChange={onBillableSwitch} loading={isSwitchLoading} />
        </>
    )

    return (
        <div className="flex justify-between items-center">
            <div className="font-bold">
                {isFolderExpense(expense) ? t("label:folder.folder") : t("label:request")} #{expense.expenseNumber}
            </div>
            <div>
                <div>
                    <BillableSwitch />
                </div>
            </div>
        </div>
    )
}
