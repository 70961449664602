import { IntegrationTypeEnum } from "@finway-group/shared/lib/models"
import React from "react"

import SettingsLayout from "Features/settings/settings.layout"
import { useIntegrationConfigurations } from "Shared/queries/integration.queries"

import CS_PersonioFeatureFlag from "./personio/CS_PersonioFeatureFlag"
import PersonioIntegration from "./personio/personioIntegration"

const SystemIntegrationsContainer = () => {
    const { data, refetch } = useIntegrationConfigurations()
    return (
        <SettingsLayout titleKey="label:integrations">
            <div className="justify-items-center">
                <PersonioIntegration connection={data?.[IntegrationTypeEnum.PERSONIO]} refetch={refetch} />
                <CS_PersonioFeatureFlag />
            </div>
        </SettingsLayout>
    )
}

export default React.memo(SystemIntegrationsContainer)
