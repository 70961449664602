import { WorkflowConditionEnum } from "@finway-group/shared/lib/models"
import { Checkbox, Col, Divider, Form, Input, Row, Tooltip } from "antd"
import { FormInstance } from "antd/lib/form"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"

import { useSubCostCenters } from "Shared/hooks/costCenter.hooks"
import { useIsTravelEnabled } from "Shared/hooks/featureFlags.hooks"
import { useWorkflow } from "Shared/hooks/workflow.hooks"
import { EmployeeService } from "Shared/services"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"
import { WorkflowFormData, buildFormValuesFromWorkflow, buildWorkflowDataFromFormValues } from "Shared/utils/workflow.form.utils"

import { generateWorkflowFormRules } from "../rules/workflow.rules"
import { RequestTypeTreeSelect } from "./requestTypeTreeSelect"
import { WorkflowFormTypeEnum, WorkflowFormValuesInterface } from "./workflowFormValues.interface"
import { WorkflowFormSteps } from "./workflowSteps.form"
import { WorkflowFormTriggerTypes } from "./workflowTriggerTypes.form"
import { WorkflowTypeSelect } from "./workflowTypeSelect"

interface WorkflowFormInterface {
    isNew: boolean
    formInstance: FormInstance<WorkflowFormValuesInterface>
    onSubmit: (workflowFormData: WorkflowFormData, otherData: { recalculateApprovalProcessesInProgress?: boolean }) => void
}

export const WorkflowForm: React.FC<WorkflowFormInterface> = ({ formInstance, isNew, onSubmit }) => {
    const { t } = useTranslation()
    const workflow = useWorkflow()
    const autoApprover = EmployeeService.getAutoApprover()
    const isTravelEnabled = useIsTravelEnabled()
    const costCenters = useSubCostCenters()

    const [formValues, _setFormValues] = useStateIfMounted<WorkflowFormValuesInterface>({
        name: "",
        requestTypes: [],
        triggerTypes: [{ condition: costCenters.length ? WorkflowConditionEnum.COST_CENTER : WorkflowConditionEnum.NET_AMOUNT }],
        steps: [{ approver: "", otherApprovers: [] }],
        workflowType: WorkflowFormTypeEnum.REQUEST,
    })

    const [isFormEdited, setIsFormEdited] = useStateIfMounted(false)

    // Use only for programmatic changes. User input through items will automatically set formValues state. Check handleFormValuesChange function below.
    const setFormValues = (values: Partial<WorkflowFormValuesInterface>) => {
        formInstance.setFieldsValue(values)
        _setFormValues((prevState) => ({ ...prevState, ...values }))
    }

    const rules = generateWorkflowFormRules()

    // Form initialization
    useEffect(() => {
        const initialFormValues = workflow ? buildFormValuesFromWorkflow(workflow, autoApprover.id, isTravelEnabled ?? false) : formValues
        setFormValues(initialFormValues)
    }, [workflow])

    const onFinish = (values: WorkflowFormValuesInterface) => {
        const workflowData = buildWorkflowDataFromFormValues(values, isTravelEnabled ?? false)
        const otherData = { recalculateApprovalProcessesInProgress: values.recalculateApprovalProcessesInProgress }
        onSubmit(workflowData, otherData)
    }

    const handleRequestTypeSelect = (requestTypes: any) => {
        setFormValues({ requestTypes })
    }

    const handleWorkflowTypeSelect = (workflowType: WorkflowFormTypeEnum) => {
        if (workflowType === formValues.workflowType) return

        // If the form workflow type is changed from REQUEST to FOLDER
        if (workflowType === WorkflowFormTypeEnum.FOLDER) {
            // clear the invalid trigger types for folder.
            const triggerTypes = formValues.triggerTypes.filter((triggerType) => ![WorkflowConditionEnum.NET_AMOUNT, WorkflowConditionEnum.VENDOR].includes(triggerType.condition))

            // if after clearing the trigger type is empty, fallback to COST_CENTER trigger
            if (triggerTypes.length === 0) triggerTypes.push({ condition: WorkflowConditionEnum.COST_CENTER })

            // Clear the invalid step conditions for folder
            const steps = formValues.steps.filter((step) => step.condition !== WorkflowConditionEnum.NET_AMOUNT)

            // If after clearing the steps are empty, push an initial value.
            if (!steps.length) steps.push({ approver: "", otherApprovers: [] })

            return setFormValues({ workflowType, requestTypes: [], triggerTypes, steps })
        }
        return setFormValues({ workflowType, requestTypes: [] })
    }

    // This will ensure all user manual inputs updates our local state and keep the form refreshed.
    const handleFormValuesChange = (_changedValues: Partial<WorkflowFormValuesInterface>, values: WorkflowFormValuesInterface) => {
        setFormValues(values)
        if (!isFormEdited) setIsFormEdited(true)
    }

    return (
        <Form onValuesChange={handleFormValuesChange} layout="vertical" form={formInstance} onFinish={onFinish}>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Form.Item label={t("input:workflow.name")} name="name" required rules={rules.name}>
                        <Input value={formValues.name} maxLength={100} placeholder={t("placeholder:workflow.name")} />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <WorkflowTypeSelect value={formValues.workflowType} formInstance={formInstance} onSelect={handleWorkflowTypeSelect} />
                </Col>
                {formValues.workflowType === WorkflowFormTypeEnum.REQUEST && (
                    <Col span={24} data-testid="selectRequestType">
                        <RequestTypeTreeSelect value={formValues.requestTypes} formInstance={formInstance} onSelect={handleRequestTypeSelect} rule={rules.requestTypes} />
                    </Col>
                )}
            </Row>

            <WorkflowFormTriggerTypes formValues={formValues} setFormValues={setFormValues} rules={rules} />

            <Divider />

            <WorkflowFormSteps formValues={formValues} setFormValues={setFormValues} rules={rules} />

            {!isNew && (
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Tooltip title={t("tooltips:recalculate_approval_processes_in_progress")}>
                            <Form.Item name="recalculateApprovalProcessesInProgress" valuePropName="checked" rules={rules.recalculateApprovalProcessesInProgress as any}>
                                <Checkbox disabled={!isFormEdited}>{t("input:workflow.recalculate_approval_processes_in_progress")}</Checkbox>
                            </Form.Item>
                        </Tooltip>
                    </Col>
                </Row>
            )}
        </Form>
    )
}
