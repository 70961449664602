import { TripFolder } from "@finway-group/shared/lib/models"
import { Rule } from "antd/lib/form"
import i18n from "i18next"
import moment from "moment"

const validateTripDateTime = (tripFolder: TripFolder, type: string) => (_rule: any, value: moment.Moment) => {
    if (!value) return Promise.reject(new Error(i18n.t("validation:required")))

    return Promise.resolve()
}

export const TripFolderFieldsRule = (expense: TripFolder): { [key: string]: Array<Rule> } => ({
    returnDate: [
        {
            required: true,
            validator: validateTripDateTime(expense, "date"),
        },
    ],
    returnTime: [
        {
            required: true,
            validator: validateTripDateTime(expense, "date"),
        },
    ],
    tripDate: [
        {
            required: true,
            validator: validateTripDateTime(expense, "date"),
        },
    ],
    tripTime: [
        {
            required: true,
            validator: validateTripDateTime(expense, "date"),
        },
    ],
    requiredTextInput: [{ required: true, message: i18n.t("validation:required"), validateTrigger: "blur" }],
})
