import { Button, Col, Form, Modal, Row } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"

import WeavrEmailVerificationForm from "Components/forms/weavr/weavrEmailVerification.form"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

interface WeavrEmailVerificationModalProps {
    isShowing: boolean
    onCancel?: () => void
}

const WeavrEmailVerificationModal = ({ isShowing, onCancel }: WeavrEmailVerificationModalProps) => {
    const { t } = useTranslation()
    const [formInstance] = Form.useForm()
    const [isLoading, setIsLoading] = useStateIfMounted(false)

    const handleHide = () => {
        setIsLoading(false)
        formInstance.resetFields()
        onCancel?.()
    }

    const onSubmit = () => {
        handleHide()
    }

    return (
        <>
            <Modal
                visible={isShowing}
                maskClosable={false}
                title={t(`corporate:passcode_recovery:title`)}
                onCancel={handleHide}
                className="ant-modal--small"
                footer={[
                    <Button key="back" onClick={() => onCancel?.()}>
                        {t("action:cancel")}
                    </Button>,
                    <Button key="submit" type="primary" loading={isLoading} onClick={formInstance.submit}>
                        {t("action:submit")}
                    </Button>,
                ]}
                destroyOnClose
                closable
                keyboard
            >
                <Row gutter={[16, 0]}>
                    <Col span={20}>
                        <div className="ml-10">{t("info:corporate.email_verification")}</div>
                    </Col>
                </Row>
                <WeavrEmailVerificationForm formInstance={formInstance} setIsLoading={setIsLoading} onSubmit={onSubmit} />
            </Modal>
        </>
    )
}

export default WeavrEmailVerificationModal
