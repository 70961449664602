import { CurrencyEnum, PriceIntervalEnum } from "@finway-group/shared/lib/models"
import { Card, Table } from "antd"
import { ColumnsType } from "antd/lib/table"
import React from "react"
import { useTranslation } from "react-i18next"

import Loading from "Components/loading"
import PriceLabel from "Components/priceLabel"
import { useAccountIds, useAccountIdsMap } from "Shared/hooks/bank.hooks"
import { useLoggedInEmployeeProfile } from "Shared/hooks/user.hooks"

import { BankBalanceInfo } from "../../types"
import NoBankAccountCard from "./noBankAccountCard"

interface BankAccountCashTableInterface {
    bankBalanceData: Array<BankBalanceInfo>
    isLoading: boolean
}

const BankAccountCashTable: React.FC<BankAccountCashTableInterface> = ({ bankBalanceData, isLoading }) => {
    const { t } = useTranslation()

    const currentEmployee = useLoggedInEmployeeProfile()
    const { globalCurrency } = currentEmployee.settings

    const accountIdToNameMap = useAccountIdsMap({ shouldFilterDeleted: true })
    const accountIds = useAccountIds({ shouldFilterDeleted: true })

    const lastBankBalanceInfo = bankBalanceData[bankBalanceData.length - 1]

    if (isLoading) {
        return (
            <Card className="h-400">
                <Loading margin={false} />
            </Card>
        )
    }

    if (!accountIds || accountIds.length === 0) {
        return <NoBankAccountCard />
    }

    const dataSource = accountIds.map((accountId) => {
        const accountData = accountIdToNameMap.get(accountId)
        return { accountId, bankName: accountData?.bankName, accountName: accountData?.accountName, balance: lastBankBalanceInfo?.[accountId] }
    })

    const renderSummary = (pageData: Array<{ name: string; currency: CurrencyEnum; balance: number }>) => {
        const total = dataSource.reduce((accu, p) => p.balance + accu, 0)

        return (
            <div className="flex justify-between">
                <span className="text-base font-bold ">{t("label:total")}</span>
                <span className="text-base font-bold">
                    <PriceLabel value={total} currency={globalCurrency} interval={PriceIntervalEnum.ONE_TIME} />
                </span>
            </div>
        )
    }

    const columns: ColumnsType = [
        {
            title: <span className="font-bold pl-12 py-12">{t("label:liquidity.bank_name")}</span>,
            dataIndex: "bankName",
            key: "bankName",
            align: "left",
            render: (value) => <div className="pl-12">{value}</div>,
        },
        {
            title: <span className="font-bold">{t("label:liquidity.account_name")}</span>,
            dataIndex: "accountName",
            key: "accountName",
            align: "center",
        },
        {
            title: <span className="font-bold">{t("label:liquidity.balance")}</span>,
            dataIndex: "balance",
            key: "balance",
            align: "right",
            render: (value: number) => <PriceLabel value={value} currency={globalCurrency} interval={PriceIntervalEnum.ONE_TIME} />,
        },
    ]

    return (
        <Card className="card-no-padding pb-40">
            <div className="min-w-400 min-h-450 max-h-450 ">
                <Table className="table-no-shadow" rowKey="accountId" dataSource={dataSource} columns={columns} footer={renderSummary} scroll={{ y: 400 }} pagination={false} />
            </div>
        </Card>
    )
}

export default BankAccountCashTable
