import WarningFilled from "@ant-design/icons/lib/icons/WarningFilled"
import { ORANGE } from "@finway-group/shared/lib/consts"
import { BankAccount, BankConnection, CurrencyEnum, ErrorCodeEnum, Expense, PriceIntervalEnum } from "@finway-group/shared/lib/models"
import { isDiscountExpired } from "@finway-group/shared/lib/utils/expense.discount.utils"
import { Alert, Card, Checkbox, DatePicker, Form, Input, Modal, Select, Tooltip } from "antd"
import { Store } from "antd/lib/form/interface"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { Trans, useTranslation } from "react-i18next"

import WarningAlert from "Components/alert/warningAlert"
import { PaymentConfirmationRules } from "Components/forms/rules"
import PriceLabel from "Components/priceLabel"
import { EURO_COUNTRY_CODE } from "Shared/config/consts"
import { useBankConnections, useDefaultAccount } from "Shared/hooks/bank.hooks"
import { useCompany } from "Shared/hooks/company.hooks"
import { BankService, NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { buildBankSubjectLine } from "Shared/utils/banking.utils"
import { sumTotalGrossPriceByCurrencies } from "Shared/utils/expense.utils"
import { doFilterSelect } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"
import { getPopupAnchor } from "Shared/utils/popup.utils"

import { SimpleExpenseList } from "./simpleExpenseList/simpleExpenseList"

interface PaymentConfirmationModalInterface {
    isScheduledPayment: boolean
    expenses: Array<Expense>
    isVisible: boolean
    onCancel: () => void
}

interface BankAccountWithBalance extends BankAccount {
    accountBalance: number
    accountCurrency: CurrencyEnum
}

const PaymentConfirmationModal: React.FC<PaymentConfirmationModalInterface> = ({ expenses, isVisible, onCancel, isScheduledPayment }) => {
    const [form] = Form.useForm()
    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useStateIfMounted(false)
    const [showBypassingCheckbox, setShowBypassingCheckbox] = useStateIfMounted(false)
    const [shouldBypassAmountCheck, setShouldBypassAmountCheck] = useStateIfMounted(false)

    const defaultAccountId = useDefaultAccount()
    const bankConnections = useBankConnections({ shouldFilterDeleted: true })
    const company = useCompany()
    const { paymentExecutionDate, noSymbolAllowed, subjectLineCharacterLimit } = PaymentConfirmationRules(isScheduledPayment)

    const [bankAccountsWithBalance, setBankAccountsWithBalance] = useState<Array<BankAccountWithBalance>>([])
    const [balanceAvailable, setBalanceAvailable] = useState(true)

    const bankConnectionsConsentActionRequired = bankConnections.filter((bankConnection) => bankConnection.consentActionRequired)
    const accountIdsConsetActionRequired = bankConnectionsConsentActionRequired.reduce(
        (accumulator, bank) => [...accumulator, ...bank.accounts.map((account) => account.accountId)],
        [],
    )

    const totalGrossPriceByCurrencies = sumTotalGrossPriceByCurrencies(expenses)

    const areSomeVendorBanksNonEuro = expenses.some(({ vendor }) => {
        const vendorIban = vendor?.iban
        if (!vendorIban) return false
        const countryCode = vendorIban.slice(0, 2)

        return !EURO_COUNTRY_CODE.includes(countryCode)
    })

    const areSomeExpenseCurrenciesNonEuro = expenses.some((expense) => expense.currency !== CurrencyEnum.EUR)

    const expensesWithExpiredDiscount = expenses.filter(isDiscountExpired)

    useEffect(() => {
        BankService.getBankAccounts()
            .then((data) => setBankAccountsWithBalance(data))
            .catch(() => setBalanceAvailable(false))
    }, [])

    const onFormSubmit = (values: Store) => {
        const expenseIds = expenses.map((e: Expense) => e.id)
        setIsLoading(true)

        // Using .local() to make sure that the date selected is exactly the same, without any timezone adjustment.
        const executionDateString = isScheduledPayment ? (values.executionDate as moment.Moment).local().format("YYYY-MM-DD") : undefined

        BankService.issuePayment(values.bankAccount!, expenseIds, executionDateString, values.subjects, shouldBypassAmountCheck)
            .then(() => {
                NotificationService.send(NotificationTypeEnum.INFO, t("notification:banking.payment_started.title"), t("notification:banking.payment_started.message"), 30)
                handleHide()
            })
            .catch((err) => {
                setIsLoading(false)
                if (err.response?.data?.errorCode === ErrorCodeEnum.INVALID_EXPENSE_AMOUNTS) {
                    setShowBypassingCheckbox(true)
                }
                NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:banking.error"))
            })
    }

    const handleHide = () => {
        setIsLoading(false)
        setShowBypassingCheckbox(false)
        form.resetFields()
        onCancel()
    }

    const getAccountBalance = (accountId: string) => {
        const account = bankAccountsWithBalance.find((bankAccountWithBalance) => bankAccountWithBalance.accountId == accountId)

        return account ? (
            <span className="text-text ml-auto">
                {accountIdsConsetActionRequired.includes(accountId) ? (
                    <Tooltip title={t("info:banking:balance_missing_due_consent")}>
                        <WarningFilled className="align-middle" style={{ color: ORANGE }} />
                    </Tooltip>
                ) : (
                    <PriceLabel currency={account.accountCurrency} value={account.accountBalance} interval={PriceIntervalEnum.ONE_TIME} />
                )}
            </span>
        ) : null
    }

    return (
        <Modal
            title={isScheduledPayment ? t("label:schedule_payment") : t("label:direct_payment")}
            visible={isVisible}
            maskClosable={false}
            onOk={form.submit}
            onCancel={handleHide}
            okText={isScheduledPayment ? t("action:banking.schedule_payment.submit") : t(`action:banking.direct_payment.submit`)}
            cancelText={isScheduledPayment ? t("action:banking.schedule_payment.cancel") : t("action:banking.direct_payment.cancel")}
            keyboard
            destroyOnClose
            confirmLoading={isLoading}
        >
            <Form form={form} layout="vertical" onFinish={onFormSubmit}>
                {isScheduledPayment && (
                    <Form.Item
                        label={t("input:request.execution_date")}
                        key="executionDate"
                        name="executionDate"
                        initialValue={
                            expenses.length === 1 && expenses[0].invoiceDueDate && moment(expenses[0].invoiceDueDate).isAfter(moment(), "d")
                                ? moment(expenses[0].invoiceDueDate)
                                : moment().add(1, "d")
                        }
                        rules={paymentExecutionDate}
                        required
                        extra={
                            expenses.length === 1 && t("input:request.due_on_extra", { dueOn: expenses[0].invoiceDueDate ? moment(expenses[0].invoiceDueDate).format("l") : "n/a" })
                        }
                    >
                        <DatePicker name="executionDate" style={{ width: "100%" }} format={moment.localeData().longDateFormat("L")} placeholder={t("placeholder:request.date")} />
                    </Form.Item>
                )}
                {expenses.map((expense, index) => (
                    <Form.Item
                        key={index}
                        label={`${t(`label:subject_line`)}${expense.expenseNumber} ${expense.getCreditor()?.name ?? ""}`}
                        name={["subjects", expense.id]}
                        rules={[...noSymbolAllowed, ...subjectLineCharacterLimit]}
                        initialValue={buildBankSubjectLine(company?.customBankSubjectParts, expense)}
                        extra={<p>{t(`label:omit_special_character`)}</p>}
                    >
                        <Input />
                    </Form.Item>
                ))}
                <Form.Item
                    className="form-label-flex"
                    key="bankAccount"
                    label={
                        <div className="flex justify-between w-full">
                            <span>{t("label:bank_account")}</span>
                            <span>
                                {balanceAvailable ? (
                                    t("label:balance")
                                ) : (
                                    <>
                                        <WarningFilled className="align-middle mr-2" style={{ color: ORANGE }} />
                                        {t("info:banking:balance_missing")}
                                    </>
                                )}
                            </span>
                        </div>
                    }
                    name="bankAccount"
                    initialValue={defaultAccountId}
                    required
                >
                    <Select showSearch filterOption={doFilterSelect} getPopupContainer={getPopupAnchor()} defaultValue={defaultAccountId}>
                        {bankConnections.map((bankConnection: BankConnection) =>
                            bankConnection.accounts.map((account: BankAccount) => (
                                <Select.Option key={account._id} value={account.accountId} label={`${bankConnection.bankName} - ${account.accountName}`}>
                                    <div className="flex flex-row">
                                        <span> {`${bankConnection.bankName} - ${account.accountName}`} </span>
                                        {getAccountBalance(account.accountId)}
                                    </div>
                                </Select.Option>
                            )),
                        )}
                    </Select>
                </Form.Item>
            </Form>

            {(areSomeVendorBanksNonEuro || areSomeExpenseCurrenciesNonEuro) && (
                <WarningAlert
                    className="mb-20"
                    message={
                        <Trans i18nKey={"info:banking:potential_extra_payment_fee"}>
                            <br />
                        </Trans>
                    }
                />
            )}

            <label>{t("label:amount_payable")}</label>
            <Card className="py-18">
                {Array.from(totalGrossPriceByCurrencies).map(([currency, totalGrossPrice]) => (
                    <h1 className="text-primary text-center mb-18">
                        <PriceLabel currency={currency} value={totalGrossPrice} interval={PriceIntervalEnum.ONE_TIME} />
                    </h1>
                ))}
            </Card>

            {expensesWithExpiredDiscount.length > 0 && (
                <WarningAlert
                    message={
                        <>
                            <div className="font-bold">{t("info:expense_discount.expired.title")}</div>
                            <Trans t={t} i18nKey="info:expense_discount.expired.message">
                                <SimpleExpenseList expenses={expensesWithExpiredDiscount} />
                            </Trans>
                        </>
                    }
                />
            )}

            {showBypassingCheckbox && (
                <Alert
                    type="error"
                    message={t("input:banking.amounts_mismatch.title")}
                    showIcon
                    description={<Checkbox onChange={(e) => setShouldBypassAmountCheck(e.target.checked)}>{t("input:banking.amounts_mismatch.checkbox")}</Checkbox>}
                />
            )}
        </Modal>
    )
}

export default PaymentConfirmationModal
