import { KYBStatusEnum } from "@finway-group/shared/lib/models"
import { useSelector } from "react-redux"

import { RootState } from "Shared/store/rootState.interface"

export const useKYBData = () => useSelector(({ corporate }: RootState) => corporate.kybData)

export const useCurrentKYBStep = () => useSelector(({ corporate }: RootState) => corporate.currentKYBStep)

export const useWallet = () => useSelector(({ corporate }: RootState) => corporate.wallet)

export const useAreCardsActivated = () => useSelector(({ corporate }: RootState) => corporate.kybData.kybStatus === KYBStatusEnum.COMPLETED)
