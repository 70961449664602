import { Button, Tabs } from "antd"
import React, { useEffect, useState } from "react"
import { ArrowLeft as ArrowLeftIcon } from "react-feather"
import { useTranslation } from "react-i18next"
import { Route, useHistory, useLocation } from "react-router-dom"

import PageTitle from "Components/page/pageTitle"
import { useAuth } from "Shared/hooks/auth.hooks"
import { useCompany } from "Shared/hooks/company.hooks"
import { RoutePathEnum } from "Shared/router/RoutePath.enum"
import { AuthzService } from "Shared/services"

import CompanySettingsContainer from "./companySettings.container"
import { SettingsContextProvider } from "./settings.context"
import SystemSettingsContainer from "./systemSettings.container"
import UserSettingsContainer from "./userSettings.container"

export const enum SettingsTabTypeEnum {
    USER = "user",
    COMPANY = "company",
    SYSTEM = "system",
}

export const SettingsPage = () => {
    const { t } = useTranslation()
    const history = useHistory()
    const { userClass } = useAuth()
    const company = useCompany()
    const { pathname } = useLocation()

    const activeTabFromRoute = pathname.startsWith("/settings") ? pathname.split("/")[2] : undefined

    const [activeTab, setActiveSettingsTab] = useState(activeTabFromRoute ?? SettingsTabTypeEnum.COMPANY)

    // This will make sure that we switch tabs if the route is switched from the outside via history push etc.
    useEffect(() => {
        if (!!activeTabFromRoute && activeTabFromRoute !== activeTab) setActiveSettingsTab(activeTabFromRoute)
    }, [activeTabFromRoute])

    const onTabClick = (key: SettingsTabTypeEnum) => {
        if (key !== activeTab) {
            history.push(`/settings/${key}`)
            setActiveSettingsTab(key)
        }
    }

    const canViewSystemSettings = AuthzService.canViewSystemSettings()

    return (
        <SettingsContextProvider>
            <PageTitle titleKey={t("view:settings.title")}>
                {history.location.state === "liquidity" && (
                    <Button className="mb-16" onClick={() => history.goBack()}>
                        <ArrowLeftIcon />
                        {t("label:liquidity.return")}
                    </Button>
                )}
            </PageTitle>

            <Tabs onTabClick={onTabClick} activeKey={activeTab} className="pb-10">
                <Tabs.TabPane tab={`${t("label:user")} (${userClass?.email})`} forceRender={true} key={SettingsTabTypeEnum.USER}>
                    <Route path={RoutePathEnum.SETTINGS_USER}>
                        <UserSettingsContainer />
                    </Route>
                </Tabs.TabPane>
                <Tabs.TabPane tab={`${t("label:company")} (${company?.name})`} forceRender={true} key={SettingsTabTypeEnum.COMPANY}>
                    <Route path={RoutePathEnum.SETTINGS_COMPANY}>
                        <CompanySettingsContainer />
                    </Route>
                </Tabs.TabPane>
                {canViewSystemSettings && (
                    <Tabs.TabPane tab={t("label:system")} forceRender={true} key={SettingsTabTypeEnum.SYSTEM}>
                        <Route path={RoutePathEnum.SETTINGS_SYSTEM}>
                            <SystemSettingsContainer />
                        </Route>
                    </Tabs.TabPane>
                )}
            </Tabs>
        </SettingsContextProvider>
    )
}
