import { UserDeviceKindEnum } from "@finway-group/shared/lib/models/user/userDeviceKind.enum"
import { UserSessionInterface } from "@finway-group/shared/lib/models/user/userSession.interface"
import { Badge, Table } from "antd"
import { ColumnsType } from "antd/lib/table"
import moment from "moment"
import React from "react"
import { useTranslation } from "react-i18next"

import { LaptopIcon } from "Components/icons/laptopIcon"
import { LogoutIcon } from "Components/icons/logoutIcon"
import { MobileIcon } from "Components/icons/mobileIcon"
import { useLoggedInEmployeeProfile } from "Shared/hooks/user.hooks"
import { AuthnService, NotificationService } from "Shared/services"

import SettingsLayout, { SettingsButton } from "../settings.layout"

const UserActiveSessionsSettings = () => {
    const { t } = useTranslation()
    const { sessions } = useLoggedInEmployeeProfile()

    const hasSessions = sessions && sessions.length > 0

    const onLogoutAllSessions = async () => {
        try {
            await AuthnService.logoutAllSessions()
        } catch (err) {
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:error"))
        }
    }

    const logoutAllSessionsButton = hasSessions ? <SettingsButton icon={<LogoutIcon />} onClick={onLogoutAllSessions} labelKey="action:sessions_logout" /> : null

    const formatSessionsDate = (date: Date) => moment(date).format("lll")

    const compareDatesForTableSorter = (a: UserSessionInterface, b: UserSessionInterface, field: keyof Pick<UserSessionInterface, "lastLoginAt" | "lastLogoutAt">) => {
        const dateA = a[field] ? moment(a[field]).valueOf() : 0
        const dateB = b[field] ? moment(b[field]).valueOf() : 0
        return dateA - dateB
    }

    const tableHeadColumns: ColumnsType<UserSessionInterface> = [
        {
            title: t("label:devices"),
            key: "deviceName",
            render: ({ deviceKind, deviceName, browserName }: UserSessionInterface) => (
                <div className="flex items-center">
                    <div className="p-5 mr-10 bg-gray-200 rounded">{deviceKind === UserDeviceKindEnum.MOBILE ? <MobileIcon /> : <LaptopIcon />}</div>
                    <p>
                        {deviceName}, {deviceKind === UserDeviceKindEnum.WEBAPP ? browserName : t("label:finway_app")}
                    </p>
                </div>
            ),
            sorter: (a: UserSessionInterface, b: UserSessionInterface) => `${a.deviceName} ${a.browserName}`.localeCompare(`${b.deviceName} ${b.browserName}`),
        },
        // TODO: to be added later
        // { title: t("label:location"), dataIndex: "location", key: "location" },
        {
            title: t("label:login"),
            dataIndex: "lastLoginAt",
            key: "lastLoginAt",
            render: (lastLoginAt: UserSessionInterface["lastLoginAt"]) => formatSessionsDate(lastLoginAt),
            sorter: (a: UserSessionInterface, b: UserSessionInterface) => compareDatesForTableSorter(a, b, "lastLoginAt"),
            defaultSortOrder: "descend",
        },
        {
            title: t("label:logout"),
            key: "lastLogoutAt",
            render: ({ lastLogoutAt, isAuthenticated }: UserSessionInterface) => (!isAuthenticated && lastLogoutAt ? formatSessionsDate(lastLogoutAt) : ""),
            sorter: (a: UserSessionInterface, b: UserSessionInterface) => compareDatesForTableSorter(a, b, "lastLogoutAt"),
        },
        {
            title: t("label:status"),
            dataIndex: "isAuthenticated",
            key: "isAuthenticated",
            render: (isAuthenticated: UserSessionInterface["isAuthenticated"]) => (
                <Badge className="ant-badge-normal-text justify-start" color={isAuthenticated ? "green" : "red"} text={isAuthenticated ? t("info:active") : t("info:inactive")} />
            ),
            sorter: (a: UserSessionInterface, b: UserSessionInterface) => Number(a.isAuthenticated) - Number(b.isAuthenticated),
        },
    ]

    return (
        <SettingsLayout titleKey="label:active_sessions" buttons={logoutAllSessionsButton}>
            <Table<UserSessionInterface>
                columns={tableHeadColumns}
                rowKey="_id"
                dataSource={sessions}
                pagination={{
                    position: ["bottomRight"],
                    showSizeChanger: true,
                    defaultPageSize: 20,
                    pageSizeOptions: ["5", "10", "20"],
                }}
            />
        </SettingsLayout>
    )
}

export default React.memo(UserActiveSessionsSettings)
