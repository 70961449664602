import { Currencies, GREEN, HIGHLIGHT_RED } from "@finway-group/shared/lib/consts"
import { CurrencyEnum } from "@finway-group/shared/lib/models"
import { UserLanguageEnum } from "@finway-group/shared/lib/models/user/userSettings.interface"
import React, { useEffect } from "react"
import { NumberFormatValues, NumericFormat } from "react-number-format"

import { useEmployeeLanguage } from "Shared/hooks/employee.hooks"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

import { CurrencySymbolPosition } from "./config"

interface CurrencyInputInterface {
    id?: string
    currency?: CurrencyEnum
    value: number
    isAutoFocused?: boolean
    isAutoSelected?: boolean
    className?: string
    onChange?: (value: number) => void
    onBlur?: () => void
    colored?: boolean
    isDisabled?: boolean
    isNegativeAllowed?: boolean
    currencySymbolPosition?: CurrencySymbolPosition
    onKeyDown?: () => void
}

const CurrencyInput = ({
    currency = CurrencyEnum.USD,
    value,
    isAutoFocused = false,
    className = "react-currency-input",
    onChange,
    onBlur,
    colored,
    isDisabled = false,
    isNegativeAllowed = true,
    currencySymbolPosition = CurrencySymbolPosition.OUTSIDE_INPUT,
    onKeyDown,
}: CurrencyInputInterface) => {
    const language = useEmployeeLanguage()
    const [internalValue, setInternalValue] = useStateIfMounted<string | number>(value)
    const [decimalSeparator, thousandSeparator] = language === UserLanguageEnum.DE ? [",", "."] : [".", ","]

    useEffect(() => {
        setInternalValue(value)
    }, [value]) // DO NOT add setInternalValue as dependency! It will break the internal state logic

    const handleFocus = () => {
        if (!internalValue) setInternalValue("")
    }

    const handleBlur = (e: any) => {
        if (!e.target.value) {
            /**
             * this is required to resolve a library caused bug.
             *
             * If you remove the input 2 times in a row, the `formatValue` will get out of sync and the library
             * thinks its showing the correct value, even-though it does not. By checking the `internalValue` and
             * triggering a re-render the correct value is displayed
             */
            setInternalValue(internalValue === "0" ? 0 : "0")
        }

        onBlur?.()
    }

    const handleValueChange = ({ floatValue }: NumberFormatValues) => {
        onChange?.(floatValue ?? 0)
    }

    const onColoredStyle = value > 0 ? { color: GREEN } : value < 0 ? { color: HIGHLIGHT_RED } : {}
    const placeholder = currencySymbolPosition === CurrencySymbolPosition.INSIDE_INPUT ? `${Currencies[currency]?.symbol} 0${decimalSeparator}00` : `0${decimalSeparator}00`

    return (
        <div className="flex flex-row items-center">
            {currencySymbolPosition === CurrencySymbolPosition.OUTSIDE_INPUT && Currencies[currency]?.symbol}
            <NumericFormat
                prefix={currencySymbolPosition === CurrencySymbolPosition.INSIDE_INPUT ? `${Currencies[currency]?.symbol} ` : undefined}
                placeholder={placeholder}
                allowLeadingZeros={false}
                disabled={isDisabled}
                autoFocus={isAutoFocused}
                style={colored ? onColoredStyle : {}}
                allowNegative={isNegativeAllowed}
                className={className}
                decimalScale={2}
                fixedDecimalScale={true}
                value={internalValue}
                onFocus={handleFocus}
                decimalSeparator={decimalSeparator}
                thousandSeparator={thousandSeparator}
                allowedDecimalSeparators={[decimalSeparator]}
                onBlur={handleBlur}
                onValueChange={handleValueChange}
                onKeyDown={onKeyDown}
                data-testid="currencyValue"
            />
        </div>
    )
}

export default CurrencyInput
