import { ExpenseTag } from "@finway-group/shared/lib/models"
import axios from "axios"

const MODEL = "expensetag"

const ExpenseTagService = {
    fetchAllExpenseTags: async () => {
        const { data } = await axios.get(`/${MODEL}?limit=200`)
        return data
    },
    createExpenseTag: async (expenseTagData: any) => {
        const { data } = await axios.post(`/${MODEL}`, expenseTagData)
        return data as ExpenseTag
    },
    deleteExpenseTag: async (expenseTagId: string) => {
        const { data } = await axios.delete(`/${MODEL}/${expenseTagId}`)
        return data
    },
    getTotalTagAttachCount: async (tagId: string) => {
        const { data } = await axios.get(`/${MODEL}/${tagId}/attach/count`)
        return data
    },
}

export default ExpenseTagService
