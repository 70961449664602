import { Currencies } from "@finway-group/shared/lib/consts"
import {
    CalculationIntervalEnum,
    CostCenter,
    CostCenter2,
    CurrencyEnum,
    CurrencyExchange,
    Employee,
    InvoiceInterface,
    RightEnum,
    Role,
    Tax,
    User,
    Vendor,
    WorkflowConditionEnum,
} from "@finway-group/shared/lib/models"
import { UserLanguageEnum } from "@finway-group/shared/lib/models/user/userSettings.interface"
import { toAmount, toDinero } from "@finway-group/shared/lib/utils"
import { FormInstance } from "antd/lib/form"
import { Store } from "antd/lib/form/interface"
import de_DE from "antd/lib/locale/de_DE"
import en_US from "antd/lib/locale/en_US"
import HttpStatus from "http-status"
import i18n, { type TFunction } from "i18next"
import * as IBAN from "iban"
import moment from "moment"
import numeral from "numeral"
import qs from "qs"
import React from "react"
import { isMobile } from "react-device-detect"
import { getCountryCallingCode } from "react-phone-number-input"

import type { KYBData } from "Shared/store/reducers/corporateReducer"

import { ALLOWED_UPLOAD_FILE_SIZE_IN_MB, FINWAY_ADMIN_EMAIL, defaultCountry, isDemo } from "../config/consts"

require("numeral/locales/de")

// fixes delimiter
numeral.localeData("de").delimiters.thousands = "."

export const isTest = process.env.NODE_ENV === "test"
export const isProd = process.env.NODE_ENV === "production"
export const isDev = process.env.NODE_ENV === "development"
const deployEnvironment = process.env.DEPLOY_ENVIRONMENT || ""
export const isInternal = ["preview", "staging", "bugfixing", "bugfixing-dev", "tpm", "gobd"].includes(deployEnvironment) || deployEnvironment.endsWith("finway-canary")

export const truncateToDecimals = (num: number, dec = 2) => {
    const calcDec = 10 ** dec
    return Math.trunc(num * calcDec) / calcDec
}

export const getAntdLocale = (localeString: string) => {
    const safeLocale = localeString.includes("-") ? localeString.split("-")[0] : localeString
    switch (safeLocale) {
        case "de":
            return de_DE
        case "en":
            return en_US
        default:
            return en_US
    }
}

export const formatCurrencyNumber = (
    value: number | string | undefined,
    currency: CurrencyEnum,
    showFullNumber: boolean = false,
    decimalPrecision: number = 2,
    maxDigits: number = 8,
    localeData?: { localeFrom: string; localeTo: string },
) => {
    // convert between locale if necessary
    if (localeData && localeData.localeFrom !== localeData.localeTo) {
        numeral.locale(localeData.localeFrom)
        const numeralValue = numeral(value)
        numeral.locale(localeData.localeTo)
        value = numeralValue.value()
    }

    // To avoid too much digit count on number such as 21.00000003
    const testString = numeral(value).format("00")
    const decimalFormatter = "0".repeat(decimalPrecision)
    const formatString =
        showFullNumber || testString.length <= maxDigits ? `0,0${decimalPrecision && `.${decimalFormatter}`}` : `0,0${decimalPrecision && `[.]${decimalFormatter}a`}`
    const formattedValue = numeral(value).format(formatString)
    const prefix = currency === CurrencyEnum.USD ? "$ " : ""
    const suffix = currency !== CurrencyEnum.USD ? ` ${Currencies[currency]?.symbol ?? ""}` : ""

    return `${prefix}${formattedValue}${suffix}`
}

export const getCurrencyFlag = (currencyValue: CurrencyEnum) => `data:image/png;base64,${Currencies[currencyValue].flag}`

export const getRandomInt = (min: number, max: number) => {
    min = Math.ceil(min)
    max = Math.floor(max)
    return Math.floor(Math.random() * (max - min + 1)) + min
}

export const isEmpty = (obj: any) => {
    const { hasOwnProperty } = Object.prototype

    // null and undefined are "empty"
    if (obj == null) return true

    // Assume if it has a length property with a non-zero value
    // that that property is correct.
    if (obj.length > 0) return false
    if (obj.length === 0) return true

    // If it isn't an object at this point
    // it is empty, but it can't be anything *but* empty
    // Is it empty?  Depends on your application.
    if (typeof obj !== "object") return true

    // Otherwise, does it have any properties of its own?
    // Note that this doesn't handle
    // toString and valueOf enumeration bugs in IE < 9
    for (const key in obj) {
        if (hasOwnProperty.call(obj, key)) return false
    }

    return true
}

export const roundNumber = (n: number) => Number(n.toFixed(2))

export const toFixed = (n: number, digits: number = 2) => Math.trunc(n * 10 ** digits) / 10 ** digits

export const groupBy = (items: any, key: any) =>
    items.reduce(
        (result: any, item: any) => ({
            ...result,
            [item[key]]: [...(result[item[key]] || []), item],
        }),
        {},
    )

export const shouldRestoreItem = (err: any, item: CostCenter | Employee | Vendor | undefined) => err?.response?.status === HttpStatus.CONFLICT && item?.deleted

export const determinePossibleApprovers = (employeeEmail: string, employees: Array<Employee>, rolesMap: Map<string, Role>): Array<Employee> =>
    employees.filter((e) => {
        const role = rolesMap.get(e.activeCompanyProfile?.roleId)
        return e.email !== employeeEmail && isRightGranted(role?.rights, RightEnum.EXPENSE__BASIC__APPROVE)
    })

export const doesContainForbiddenChars = (s: string): boolean => /\`|\~|\!|\@|\#|\$|\%|\^|\&|\*|\(|\)|\+|\=|\[|\{|\]|\}|\||\\|\'|\<|\,|\.|\>|\?|\/|\""|\;|\:/.test(s)

export const isExtendedAlphanumeric = (s: string): boolean => /^[a-zA-Z0-9-\/]+$/.test(s)

export const isNumeric = (s: string): boolean => /^[0-9]+$/.test(s)

export const isNumber = (n: number) => !isNaN(n)

export const timeDiffInMonths = (dateOne: Date, dateTwo: Date) => {
    let diff = (dateTwo.getTime() - dateOne.getTime()) / 1000
    diff /= 60 * 60 * 24 * 7 * 4
    return Math.abs(Math.round(diff))
}

export const getQuarterByCurrentMonth = (currentMonth: number): number => {
    if (currentMonth > 11 || currentMonth < 0) {
        throw new Error(`Invalid input! Current month should be in the range from 0 to 11.`)
    } else {
        if (currentMonth <= 2 && currentMonth >= 0) {
            return 1
        }
        if (currentMonth <= 5 && currentMonth >= 3) {
            return 2
        }
        if (currentMonth <= 8 && currentMonth >= 6) {
            return 3
        }
        return 4
    }
}

export const isValidIBAN = (str: string) => IBAN.isValid(str)

export const isValidBIC = (str: string) => /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/.test(str)

export const isValidAccountNumber = (str: string) => /^[0-9]{8}$/.test(str)

export const isValidSortCode = (str: string) => /^[0-9]{6}$/.test(str)

export const isValidVAT = (str: string) =>
    /^(ATU[0-9]{8}|BE[01][0-9]{9}|BG[0-9]{9,10}|HR[0-9]{11}|CY[A-Z0-9]{9}|CZ[0-9]{8,10}|DK[0-9]{8}|EE[0-9]{9}|FI[0-9]{8}|FR[0-9A-Z]{2}[0-9]{9}|DE[0-9]{9}|EL[0-9]{9}|HU[0-9]{8}|IE([0-9]{7}[A-Z]{1,2}|[0-9][A-Z][0-9]{5}[A-Z])|IT[0-9]{11}|LV[0-9]{11}|LT([0-9]{9}|[0-9]{12})|LU[0-9]{8}|MT[0-9]{8}|NL[0-9]{9}B[0-9]{2}|PL[0-9]{10}|PT[0-9]{9}|RO[0-9]{2,10}|SK[0-9]{10}|SI[0-9]{8}|ES[A-Z]([0-9]{8}|[0-9]{7}[A-Z])|SE[0-9]{12}|GB([0-9]{9}|[0-9]{12}|GD[0-4][0-9]{2}|HA[5-9][0-9]{2}))$/.test(
        str,
    )

export const getIBANPrintFormat = (inputIBAN: string): string => IBAN.printFormat(IBAN.electronicFormat(inputIBAN))

export const shouldRestore = (err: { response: { status: any } }, item: Vendor) => err && err.response && err.response.status === HttpStatus.CONFLICT && item.deleted

export const doesImageExist = (url: string | undefined, successCallback: () => void, errorCallback: () => void) => {
    if (url && url.endsWith("default.jpg")) {
        return errorCallback()
    }

    const img = new Image()
    img.onload = successCallback
    img.onerror = errorCallback
    img.src = url || ""
}

export const copyDeeply = (object: any, Model: any) => {
    const deepCopy = JSON.parse(JSON.stringify(object))

    if (Array.isArray(deepCopy)) {
        return deepCopy.map((obj: any) => new Model(obj))
    }
    return new Model(JSON.parse(JSON.stringify(object)))
}

export const insertIf = (condition: any, ...elements: any) => (condition ? elements : [])

export const insertPropertyIf = (condition: boolean, value: { [key: string]: any }) => (condition ? value : {})

export const parseValidDateFormat = (s: string, formats: Array<string>) => {
    for (const format of formats) {
        const parsedDate = moment(s, format, true)
        if (parsedDate.isValid()) return parsedDate
    }
    return undefined
}

export const decodeFilterString = (s: string) => qs.parse(s)

export const encodeFilterString = (obj: any) => {
    const encoded = qs.stringify(obj, { encode: false })
    return encoded ? `&${encoded}` : ""
}

export const getStartAndEndDateOfInterval = (interval: CalculationIntervalEnum) => {
    switch (interval) {
        case CalculationIntervalEnum.ALL_TIME:
            return {}
        case CalculationIntervalEnum.CURRENT_YEAR:
            return { start: moment().startOf("year").toDate(), end: moment().endOf("year").toDate() }
        case CalculationIntervalEnum.CURRENT_MONTH:
            return { start: moment().startOf("month").toDate(), end: moment().endOf("month").toDate() }
    }
}

export const convertToDiagramData = (data: Array<any>) =>
    data.map((dataSet) => {
        const monthLabel = dataSet.month + 1 < 10 ? `0${dataSet.month + 1}` : dataSet.month + 1
        const yearLabel = isMobile ? dataSet.year.toString().slice(-2) : dataSet.year
        return { x: `${monthLabel}/${yearLabel}`, y: dataSet.expenseTotal }
    })

export const convertToBarDiagram = (data: any) => {
    const newMap = new Map()
    data?.forEach((item: any) => {
        const field = item.costCenter
        item.data.forEach((dateItem: any) => {
            const date = `${dateItem.year}-${dateItem.month + 1}`
            const currentItem = newMap.get(date)
            currentItem ? (currentItem[`${field}`] = dateItem.expenseTotal) : newMap.set(date, { date, [field]: dateItem.expenseTotal }) // if field doesn't exists, create
        })
    })
    return [...newMap.values()] // returns MapIterator.values()
}

export const sortItemsDescByDateField = (items: Array<any>, by: string) =>
    items.sort((itemA: { [key: string]: any }, itemB: { [key: string]: any }) => new Date(itemB[by]).getTime() - new Date(itemA[by]).getTime())

/**
 * wrap the promise to communicate with lazy loading async components
 * @param promise
 */
export const wrapPromise = (promise: Promise<any>) => {
    let status = "pending"
    let result: any
    const suspender = promise.then(
        (r) => {
            status = "success"
            result = r
        },
        (e) => {
            status = "error"
            result = e
        },
    )
    return {
        read() {
            if (status === "pending") {
                throw suspender
            } else if (status === "error") {
                throw result
            } else if (status === "success") {
                return result
            }
        },
    }
}

export const delay = (ms: number) => new Promise((res) => setTimeout(res, ms))

export const assignIfNull = (initialEl: any, newEl: any) => initialEl || newEl

export const msToTime = (duration: number) => {
    let hours: string | number = Math.floor(duration / 3600)
    let minutes: string | number = Math.floor((duration - hours * 3600) / 60)

    if (hours < 10) hours = `0${hours}`
    if (minutes < 10) minutes = `0${minutes}`

    return hours === "00" ? `${minutes} minutes` : `${hours} hours ${minutes} minutes`
}

export const idleMsToTime = (millis: number) => {
    const minutes = Math.floor(millis / 60000)
    const seconds = ((millis % 60000) / 1000).toFixed(0)
    return `${minutes}:${Number(seconds) < 10 ? "0" : ""}${seconds}`
}

export const minutesToMilliseconds = (minutes: number) => 1000 * 60 * minutes

export const flatten = (arr: Array<any>): Array<any> => arr.reduce((flat, toFlatten) => flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten), [])

export const configLocaleNumberFormatting = (locale: string) => {
    const safeLocale = locale.includes("-") ? locale.split("-")[0] : locale // Strip locale such as 'en-GB' / 'en-US' so it becomes 'en'
    const supportedLocales = Object.keys(numeral.locales)
    if (supportedLocales.includes(safeLocale)) {
        numeral.locale(safeLocale)
    } else {
        // fallback to DE
        numeral.locale("de")
    }
}

export const isEmptyString = (stringInput: string): boolean => stringInput === ``

export const isWhitespaceOnly = (stringInput: string): boolean => /^\s*$/.test(stringInput)

export const getRecommendationTagColour = (recommendationInput: string) => (recommendationInput === `Fine` ? `ant-tag-green` : `ant-tag-red`)

export const removeStringWhiteSpaces = (inputString: string): string => inputString.trim().split(` `).join(``)

export const getDecimalSeperator = () => numeral.localeData().delimiters.decimal

export const isNotSet = (value: any) => value === undefined || value === null || value === "" || (value && isString(value) && value.trim() === "")

export const isString = (s: any) => typeof s === "string" || s instanceof String

export const isEmptyObject = (object: any) => object !== undefined && Object.keys(object).length === 0 && object.constructor === Object

/**
 * Use this method to filter (antd) `<Select>` values
 *
 * ATTENTION: label must be a string!
 *
 *
 * For example:
 * ```jsx
 * <Select filterOption={doFilterSelect}>
 *     <Select.Option label={`${entry.taxRate}`}>text</Select.Option>
 * </Select>
 * ```
 *
 */
export const doFilterSelect = (input: string, option: any) => option.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0

export const doFilterTagSelect = (input: string, option: any) => option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0

export const capitalizeFirstLetter = (s: string) => s.charAt(0).toUpperCase() + s.slice(1)

export const lowercaseFirstLetter = (s: string) => s.charAt(0).toLowerCase() + s.slice(1)

/**
 * @param e keyboard event
 * @param index input index
 */
export const codeInitInputRefHelper = (input: HTMLInputElement | null, index: number, codeRef: any) => {
    codeRef[index] = input
    if (index === 0) codeRef[index] && codeRef[index].focus()
}

export const codeInputJumpHelper = (e: any, index: number, form: FormInstance, codeRef: any, codeSize: number, autoSubmit: boolean = true) => {
    const key = e.which || e.keyCode
    const ctrl = e.ctrlKey || e.metaKey

    if (ctrl && key === 86) return
    e.preventDefault()

    // if keyboard event is a number in ASCII code (and keypad)
    if ((key <= 57 && key >= 48) || (key >= 96 && key <= 105)) {
        codeRef[index].value = e.key
        index + 1 >= codeSize ? codeRef[codeSize - 1].focus() : codeRef[index + 1].focus()
    }

    // if keyboard event is delete (with backspace or delete)
    if (key === 8 || key === 127) {
        if (codeRef[index].value.length > 0) {
            codeRef[index].value = ""
            return
        }

        index - 1 >= 0 ? (codeRef[index - 1].value = "") : (codeRef[index].value = "")
        index > 0 ? codeRef[index - 1].focus() : codeRef[index].focus()
    }

    const value = codeRef.map((ref: any) => ref.value).join("")
    if (value.length === codeSize && autoSubmit) form.submit()
}

export const codePasteHelper = (e: any, form: FormInstance, codeRef: any, codeSize: number, autoSubmit: boolean = true) => {
    const clipboardData = e.clipboardData.getData("text")
    e.preventDefault()

    if (clipboardData.length === codeSize && !isNaN(clipboardData)) {
        codeRef.map((ref: any, i: number) => (ref.value = clipboardData[i]))
        autoSubmit && form.submit()
    }
}

export const sumBy = (arr: Array<any>, key: string) => arr.reduce((a, b) => a + (b[key] || 0), 0)

export const dateToAge = (date: string): number => {
    const timeDiff = Math.abs(Date.now() - new Date(date).getTime())
    return Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25)
}

export const getDateFromMonth = (year: number, month: number) => moment(new Date(year, month)).format("YYYY-MM-DD")

export const getLastDayOfMonth = (year: number, month: number) => {
    const date = new Date((new Date(year, month + 1, 1) as any) - 1)
    return moment(date).format("YYYY-MM-DD")
}

export const convertSorter = (original: any) => (!original.order ? {} : { order: original.order, field: original.columnKey })

export const convertSearchValueToQuery = (searchValue: string, targets: Array<string>) => {
    const searchObject: any = {}
    targets.forEach((i: string) => (searchObject[i] = { contains: searchValue }))
    return searchValue ? encodeFilterString(searchObject) : ""
}

export const employeeSorter = (employeeA: Employee, employeeB: Employee) => {
    try {
        const roleA = employeeA.activeCompanyProfile?.roleId ?? ""
        const roleB = employeeB.activeCompanyProfile?.roleId ?? ""
        return roleA.localeCompare(roleB) || `${employeeA.firstName} ${employeeA.lastName}`.localeCompare(`${employeeB.firstName} ${employeeB.lastName}`)
    } catch (err) {
        return 0
    }
}

// This might not work everywhere as it uses the `react-phone-number-input` and the countries available there
export const getDisplayCountryCallingCode = (country: string = defaultCountry): string => `+${getCountryCallingCode(country)}`

export const checkShouldOnboardUser = ({ user, kybData, companyId }: { user: User; kybData: KYBData; companyId: string }) => {
    if (!user) return false

    const userActiveCompanyProfile = user.getUserCompanyProfile(companyId ?? "")

    if (userActiveCompanyProfile && userActiveCompanyProfile.weavrData?.userId && !isDemo) {
        // for root users do not show it if we're in the first steps
        // Note: after those 2 steps, the root user would automatically satisfy the next condition, but adding this makes sure
        //       that the onboarding dialog is shown for the rare case of having to use root user switches which then start off
        //       where they should have a passcode already but don't.
        if (userActiveCompanyProfile.weavrData?.isRoot && !kybData.isMobileVerified && userActiveCompanyProfile.weavrData?.isMobileEnrolled) return false
        // if user is enrolled and created a passcode and have a verified phone number
        if (userActiveCompanyProfile.weavrData?.isPasswordCreated && userActiveCompanyProfile.weavrData?.isMobileEnrolled) return false

        return true
    }

    return false
}

// @todo move to employee model in shared
// these fields are forbidden to send to the API
// unless the user sending it is an Admin
export const unsetApprovalFields = (employee: any) => {
    delete employee.activeCompanyProfile
    delete employee.companyProfiles
    delete employee.position
}

export interface GetTaxLabel {
    taxRate?: number
    buCode?: string
    buCodeName?: string
}

export interface IsUniqueInReduxAndFormInterface {
    formValues: any
    existingValuesRaw: Array<any>
    valueKey: string
    value: string
    isEdit: boolean
}

export const getTaxLabel = ({ taxRate, buCode, buCodeName }: GetTaxLabel): string => {
    const taxLabel = isSet(taxRate) ? `${taxRate} %` : "-"
    const additionalInfo = [buCode, buCodeName].filter(Boolean).join(" ")
    return additionalInfo ? `${taxLabel} ${additionalInfo}` : taxLabel
}

export const isItemDeleted = (items: Array<any>, id: string) => items.find((item: any) => item._id === id)?.deleted

export const getItemById = (items: Array<any>, id: string) => items.find((item: any) => item._id === id)

export const getTax = (taxes: Array<Tax>, id: string): number => getItemById(taxes, id)?.taxRate ?? getDefaultTaxRate(taxes)

const getDefaultTaxRate = (taxes: Array<Tax>): number =>
    taxes.find(({ isDefault }) => isDefault)?.taxRate ?? taxes.find(({ taxRate }) => taxRate === 0)?.taxRate ?? taxes[0].taxRate

export const getDefaultTaxRateObject = (taxes: Array<Tax>): Tax => taxes.find(({ isDefault }) => isDefault) ?? taxes.find(({ taxRate }) => taxRate === 0) ?? taxes[0]

export const getDefaultTaxId = (taxes: Array<Tax>): string => taxes.find(({ isDefault }) => isDefault)?._id ?? taxes.find(({ taxRate }) => taxRate === 0)?._id ?? taxes[0]._id

export const checkFilesSize = (files: Array<File>) => {
    const totalSize = files.reduce((total, file) => total + file.size, 0)
    const sizeInMB = totalSize / 1024 / 1024
    if (sizeInMB > ALLOWED_UPLOAD_FILE_SIZE_IN_MB) {
        return false
    }
    return true
}

export const blurInputOnWeel = (event: React.WheelEvent<HTMLInputElement>) => {
    event.currentTarget.blur()
}

export const countInArray = (array: Array<any>, value: number | string, isCaseSensitive = true): number =>
    array.filter((item: any) => item !== undefined && !isEmptyString(item) && (isCaseSensitive ? item == value : item.toString().toLowerCase() == value.toString().toLowerCase()))
        .length

export const isUniqueInReduxAndForm = ({ formValues, existingValuesRaw, valueKey, value, isEdit = true }: IsUniqueInReduxAndFormInterface) => {
    let occurrences = 0

    if (isEdit) {
        // On edit mode, we want to compare the inputted value against what's in redux along with the other values that's loaded in the form.
        // we collect all values in the form.
        const valuesInForm = formValues.map((e: any) => e[valueKey].toLowerCase().trim())

        // we need to get rid of the redux values that is currently being edited/loaded in the form.
        const currentEditedIds = formValues.map((v: any) => v._id)
        const valuesNotInForm = existingValuesRaw.filter((e) => !currentEditedIds.includes(e._id)).map((e) => e[valueKey].toLowerCase().trim())

        occurrences = countInArray([...valuesInForm, ...valuesNotInForm], value.toLowerCase().trim())
    } else {
        // on create mode, we want to compare the inputted value against the values in the redux only.
        const valuesInForm: Array<any> = formValues?.filter((e: any) => e?.[valueKey])?.map((e: any) => e[valueKey].toLowerCase().trim())
        const existingValues: Array<any> = existingValuesRaw.map((e: any) => e[valueKey].toLowerCase().trim())

        occurrences = countInArray([...valuesInForm, ...existingValues], value.toLowerCase().trim())
    }

    return occurrences === 1
}

/**
 * Copies an object with only the selected keys.
 */
export const pick = <T extends object, U extends keyof T>(obj: T, keys: Array<U>): Pick<T, U> => Object.assign({}, ...keys.map((key) => ({ [key]: obj[key] })))

/**
 * Merges two arrays together, removing duplicates.
 */
export const mergeArrays = (array1: Array<any>, array2: Array<any>): Array<any> => [...new Set([...array1, ...array2])]

// Banking Subject line allows the following special chars: . , <blank>
export const stripForBankSubjectLine = (subjectLine: any) => replaceUmlauts(subjectLine).replace(/[^a-zA-Z0-9\.\, ]/g, "")

// SEPA Subject line allows the following special chars: . - , / <blank>
export const stripForSEPASubjectLine = (subjectLine: any) => replaceUmlauts(subjectLine).replace(/[^a-zA-Z0-9\.\,\-\/\ ]/g, "")

export const replaceUmlauts = (str: string) =>
    str.replace(/ö/g, "oe").replace(/ä/g, "ae").replace(/ü/g, "ue").replace(/Ö/g, "oe").replace(/Ä/g, "ae").replace(/Ü/g, "ue").replace(/ß/g, "ss")

/**
 * To check if all the fields of the elements in an array have equal values.
 * example:
 *
 *      const arr = [{a: 10, b: 40}, {a: 10, b: 30}, {a: 10, b: 30}]
 *      doAllElementsHaveTheSameFieldValue(arr, "a") -> true
 *      doAllElementsHaveTheSameFieldValue(arr, "b") -> false
 */
export const doAllElementsHaveTheSameFieldValue = (array: Array<any>, field: string) => {
    if (!array || array.length === 0) {
        return true
    }
    return array.every((element: any) => element[field] === array[0][field])
}

export const validateCreditorNumberDATEVCompatibility = (value: string) => {
    if (value) {
        if (!isNumeric(value)) {
            throw new Error(i18n.t("validation:numeric"))
        }

        if (value.length > 0 && value.substr(0, 1) === "0") {
            throw new Error(i18n.t("validation:no_leading_zero"))
        }

        if (value.length < 5) {
            throw new Error(i18n.t("validation:string_too_short"))
        }
        if (value.length > 9) {
            throw new Error(i18n.t("validation:string_too_long"))
        }
    }
}

export const isCreditorNumberDATEVCompatible = (value: string) => {
    try {
        validateCreditorNumberDATEVCompatibility(value)
        return true
    } catch {
        return false
    }
}

export const getResourcesForTrigger = (
    triggerType: WorkflowConditionEnum,
    costCenters: Array<CostCenter>,
    vendors: Array<Vendor>,
    employees: Array<Employee>,
    costCenter2s: Array<CostCenter2>,
    triggerReferenceIds: Array<any>,
) => {
    switch (triggerType) {
        case WorkflowConditionEnum.COST_CENTER:
            return costCenters
        case WorkflowConditionEnum.VENDOR:
            return vendors.filter((vendor) => vendor.isApproved)
        case WorkflowConditionEnum.REQUESTER:
            return employees.filter((employee: Employee) => {
                if (triggerReferenceIds.includes(employee.id)) return true
                return !employee.activeCompanyProfile.deleted && employee.email !== FINWAY_ADMIN_EMAIL
            })

        case WorkflowConditionEnum.COST_CENTER_2:
            return costCenter2s
        default:
            return []
    }
}

export const zeroPad = (num: number, places: number) => String(num).padStart(places, "0")

export const isSet = (value: any) => value !== null && value !== undefined && value !== "" && JSON.stringify(value) !== "{}"

export const emptyStringToUndefined = (val?: string) => (val === "" || !val ? undefined : val)

export const isRightGranted = (rights: Array<RightEnum> | undefined, right: RightEnum): boolean => rights?.includes(right) || false
export const checkIfDuplicateExists = (arr: Array<any>) => new Set(arr).size !== arr.length

export const getExchangeConversionFactor = (exchange: CurrencyExchange, currency: CurrencyEnum) => exchange?.rates?.find((r) => r.currencyName === currency)?.conversionFactor

export const isExpensePaymentFlowInfoEnabled = (company: any, isLoggedInUserPotentialApprover: boolean) =>
    company?.expensePaymentFlowInformation.isAlreadyPaidEnabled ||
    company?.expensePaymentFlowInformation.isAlreadyApprovedAndPaidEnabled ||
    (isLoggedInUserPotentialApprover && company?.expensePaymentFlowInformation.isReportingOnlyEnabled)

export const getTooltipPopupContainer = (triggerNode: HTMLElement) => triggerNode?.parentNode?.parentNode?.parentNode as HTMLElement

export const parseNumber = (s: string): number => Number(s.replace(",", "."))

/**
 * Maps the given file urls into an invoice interface format
 * @param fileUrls
 * @returns
 */
export const mapToInvoiceInterface = (fileUrls: Array<string>): Array<InvoiceInterface> =>
    fileUrls?.map((fileUrl) => ({ url: fileUrl, hash: "", fileName: "", uploadDate: new Date() } as InvoiceInterface))

export const linkDetection = (text: string) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g
    return text
        .replace(" ", "&nbsp;")
        .replace(urlRegex, (url) => `<a target="_blanc" href="${url}">${url}</a>`)
        .replace(/\n/g, "<br>\n")
}

export const getUniqueElements = <T>(arr: Array<T>, keyToCheck: keyof T) => [...new Map(arr.map((el) => [el[keyToCheck], el])).values()]

export const parseMultipartBody = ({ data, headers }: any) => {
    const boundary = headers["content-type"].split(";")[1].split("=")[1]

    const parts = data.split(`--${boundary}`).reduce((parts: any, part: any) => {
        if (part && part !== "--") {
            const [head, body] = part.trim().split(/\r\n\r\n/g)
            if (body) {
                parts.push({
                    body,
                    headers: head.split(/\r\n/g).reduce((headers: any, header: any) => {
                        const [key, value] = header.split(/:\s+/)
                        headers[key.toLowerCase()] = value
                        return headers
                    }, {}),
                })
            }
        }
        return parts
    }, [])

    const base64File = parts.find((part: any) => part.headers["content-disposition"]?.includes('name="file"'))?.body
    const dataPart = parts.find((part: any) => part.headers["content-disposition"]?.includes('name="data"'))?.body

    return {
        base64File,
        expenses: dataPart ? JSON.parse(dataPart) : undefined,
    }
}

export const getBufferFromEncoding = (encodedFile: string, bufferEncoding: BufferEncoding): Buffer => Buffer.from(encodedFile, bufferEncoding)
export const isArrayBuffer = (input: any) => input instanceof ArrayBuffer

/**
 * Create an array of numbers where the value starts from start to number.
 * E.g. when start 5, end is 10, then return will be [6,7,8,9]
 */
export const getIntegerRangeExclusive = (start: number, end: number) => {
    const arr = []
    for (let i = start + 1; i < end; i++) {
        arr.push(i)
    }
    return arr
}

export const convertFileDataFormat = (fileUploadData: any) => ({
    fileName: fileUploadData.originalname,
    url: fileUploadData.url,
    hash: fileUploadData.hash,
    uploadDate: fileUploadData.createdAt,
    uploadedBy: fileUploadData.createdBy,
    duplicateData: { hash: fileUploadData.duplicateData },
})

export const flattenArray = <T>(array: Array<any>): Array<T> => [].concat.apply([], array)

export const handleEmpty = (value?: string) => (value && value !== "" ? value : undefined)

export function handleEmptyValues(values: Store, keys: Array<string> = []) {
    const object = { ...values }

    for (const key of keys) {
        object[key] = handleEmpty(values[key])
    }

    return object
}

export const addPrices = (priceAddends: Array<number>): number => {
    const dineroPriceAddends = priceAddends.map((price) => toDinero(price))
    let sum = toDinero(0)
    for (const dineroPriceAddend of dineroPriceAddends) {
        sum = sum.add(dineroPriceAddend)
    }
    return toAmount(sum)
}

export const subtractPrices = (priceMinuend: number, priceSubtrahends: Array<number>): number => {
    const dineroPriceSubtrahends = priceSubtrahends.map((price) => toDinero(price))

    let sum = toDinero(priceMinuend)
    for (const dineroPriceSubtrahend of dineroPriceSubtrahends) {
        sum = sum.subtract(dineroPriceSubtrahend)
    }
    return toAmount(sum)
}

export const getLanguageString = (language: UserLanguageEnum, t: TFunction) => t(`enum:UserLanguageEnum.${language}`)
