import { Col, Row } from "antd"
import React from "react"

interface ExpenseFormLayout {
    leftSide: React.ReactElement
    rightSide?: React.ReactElement
}

const ExpenseFormLayout: React.FC<ExpenseFormLayout> = ({ leftSide, rightSide }) => (
    <div className="animation-appear">
        <Row gutter={[16, 16]} className="flex-col md:flex-row">
            <Col className={`${!rightSide && "min-w-full"}`} xs={24} lg={14}>
                {leftSide}
            </Col>
            {rightSide && (
                <Col xs={24} lg={10}>
                    {rightSide}
                </Col>
            )}
        </Row>
    </div>
)

export default ExpenseFormLayout
