import { PER_DIEM_2022, PER_DIEM_2023, PER_DIEM_2024 } from "@finway-group/shared/lib/consts/perDiem"
import { PerDiem, PerDiemDestination } from "@finway-group/shared/lib/models"
import { TFunction } from "i18next"
import moment, { unitOfTime } from "moment"

import {
    FormPerDiemDestinationItinerary,
    PerDiemDailyExpensesWithMomentDate,
    PerDiemDestinationItinerary,
} from "Components/forms/expenseForm/reimbursementForms/perDiem/perDiem.form.types"
import { DEFAULT_PER_DIEM_DESTINATION, REIMBURSABLE_DURATION_THRESHOLD_MINUTE } from "Shared/config/consts"

export const generatePerDiemDestinationLabel = (p: PerDiemDestination) => {
    if (p.country) {
        return `${p.destination}, ${p.country}`
    }
    return p.destination
}

const getFirstAndLastDestinations = (itinerary: Array<PerDiemDestinationItinerary>) => {
    if (!itinerary || itinerary.length === 0) {
        return undefined
    }
    return [itinerary[0], itinerary[itinerary.length - 1]]
}

const getPerDiemTravelDuration = (start: PerDiemDestinationItinerary, end: PerDiemDestinationItinerary, unit: unitOfTime.Diff = "hour") =>
    moment(end.endDate).diff(start.startDate, unit)

export const isPerDiemTripNotReimbursable = (perDiemExpense: PerDiem, perDiemDestinations: Array<PerDiemDestination>) => {
    const destinations = getFirstAndLastDestinations(perDiemExpense.destinations)
    if (!destinations) return false
    const [start, end] = destinations
    if (!start || !end) {
        return false
    }

    const deutschlandDestination = perDiemDestinations.find((d) => d.destination === DEFAULT_PER_DIEM_DESTINATION)

    // For trips in germany, trips less or equal than 8 minutes cannot be reimbursed. (8 min + 1 sec can though)
    const minute = getPerDiemTravelDuration(start, end, "minute")
    return end.destination === deutschlandDestination?._id && minute <= REIMBURSABLE_DURATION_THRESHOLD_MINUTE
}

export const formatPerDiemDestinationDates = (expense: PerDiem): Array<FormPerDiemDestinationItinerary> =>
    expense.destinations.map((d) => ({
        ...d,
        startDate: moment(d?.startDate),
        endDate: moment(d?.endDate),
    }))

export const getDestinationRateOfDifferentYear = (newYear: number, perDiemDestination: PerDiemDestination, allPerDiemDestinations: Array<PerDiemDestination>) =>
    allPerDiemDestinations.find((destination) => destination.year === newYear && destination.destinationId === perDiemDestination.destinationId)

/**
 * When we have the following itinerary
 * ```
 * 0    | Lyon (2022)   | (30.12.2022 - 02.01.2023) - Notice that the start date and end date is on a different year
 * 1    | Paris (2023)  | (02.01.2023 - 03.01.2023)
 * ```
 * Lyon (2022) have perDiemDestination data, but Lyon (2023) does not exist, and it has to be replaced by "im übringen"
 *
 * This function returns the input itinerary, but the problematic destination is broken into 2:
 * ```
 * 0    | Lyon (2022)                       | (30.12.2022 - 01.01.2023) <- part 1
 * 1    | Frankreich, im Übringen (2023)    | (01.01.2023 - 02.01.2023) <- part 2, Lyon is replaced with Frankreich, im Übringen
 * 2    | Paris (2023)                      | (02.01.2022 - 03.01.2023)
 * ```
 */
export const breakOffItineraries = (itineraries: Array<FormPerDiemDestinationItinerary>, originalDestination: PerDiemDestination, replacementDestination: PerDiemDestination) => {
    const index = itineraries.findIndex((itinerary) => itinerary.startDate?.get("year") !== itinerary.endDate?.get("year"))!
    const itineraryNeedsBreaking = itineraries[index]!
    const firstPiece: FormPerDiemDestinationItinerary = {
        startDate: itineraryNeedsBreaking.startDate?.clone(),
        endDate: itineraryNeedsBreaking.endDate?.clone().startOf("year"),
        destination: originalDestination._id,
    }
    const secondPiece: FormPerDiemDestinationItinerary = {
        startDate: itineraryNeedsBreaking.endDate?.clone().startOf("year"),
        endDate: itineraryNeedsBreaking.endDate?.clone(),
        destination: replacementDestination?._id,
    }

    const brokenOffItineraries = [...itineraries]
    brokenOffItineraries[index] = firstPiece
    brokenOffItineraries.splice(index + 1, 0, secondPiece)

    return brokenOffItineraries
}

/**
 * On new year, the itinerary might refer to a PerDiemDestination from a wrong year.
 * When the user enters the following itinerary:
 * ```
 * 0    | Chengdu (2022)    | (30.12.2022 - 01.01.2023) - Notice that the start date and end date is on a different year
 * ```
 * generateDailyExpenditureData() will generate the following daily expenditures:
 * ```
 * Index| Destination       | Date          |                      Expenditures
 * 0    | Chengdu (2022)    | 30.12.2022    | Breakfast?    | Lunch?    | Dinner?   | Accommodation?
 * 1    | Chengdu (2022)    | 31.12.2022    | Breakfast?    | Lunch?    | Dinner?   | Accommodation?
 * 2    | Chengdu (2022)    | 01.01.2023    | Breakfast?    | Lunch?    | Dinner?   | Accommodation?  - Using a false perDiemDestination source, so this daily expenditure needs correcting
 * ```
 *
 * This function will recieve
 * @param newYear the expected year (2023)
 * @param originalDestination the original PerDiemDestination that have the wrong year (Chengdu (2022))
 * @param perDiemDestinations array of all PerDiemDestinations
 *
 * It will return
 * 1. correctedDestination: Contains the destination with the correct reference (Chengdu (2023))
 * 2. itineraryNeedsCorrection: On case of a destination that needs special treatment, this flag will set to true so that the itinerary can be fixed
 */
export const correctDestinationForNewYear = (newYear: number, originalDestination: PerDiemDestination, perDiemDestinations: Array<PerDiemDestination>) => {
    let itineraryNeedsCorrection = false

    let correctedDestination = getDestinationRateOfDifferentYear(newYear, originalDestination, perDiemDestinations)

    if (!correctedDestination) {
        // Means that in the new year, the destination is not supported anymore / needs particular mapping.
        // Especially Marseilles and Lyon, in 2023 they're not supported anymore.
        if (["53", "54"].includes(originalDestination.destinationId) && originalDestination.year === PER_DIEM_2022) {
            // Lyon and Marseilles will be corrected to Frankreich - im Übringen, 2023.
            correctedDestination = perDiemDestinations.find((destination) => destination.year === PER_DIEM_2023 && destination.destinationId === "57")!
            itineraryNeedsCorrection = true
        }

        if (["71"].includes(originalDestination.destinationId) && originalDestination.year === PER_DIEM_2023) {
            // Banglore will be corrected to Indien - im Übringen, 2024.
            correctedDestination = perDiemDestinations.find((destination) => destination.year === PER_DIEM_2024 && destination.destinationId === "76")!
            itineraryNeedsCorrection = true
        }
    }

    // when correctedItineraries is not undefined, that means the destinations needs breaking off due to data incompatibility (Lyon/Marseille 2022 => 2023).
    return { correctedDestination, itineraryNeedsCorrection }
}

/**
 * Builds the expenditure table for the per diem expense
 * It takes the destination values, and make them into an array (1 element for each day) with the proper rates.
 *
 * E.g. When itineraries (form on the right side entered by the users) contain:
 * ```
 * 0 Lyon (05.06.2022 - 08.06.2022)
 * 1 Paris (08.06.2022 - 10.06.2022)
 * ```
 * It will generate the following daily expenditures:
 * ```
 * Index| Destination   | Date          |                      Expenditures
 * 0    | Lyon(2022)    | 05.06.2022    | Breakfast?    | Lunch?    | Dinner?   | Accommodation?
 * 1    | Lyon(2022)    | 06.06.2022    | Breakfast?    | Lunch?    | Dinner?   | Accommodation?
 * 2    | Lyon(2022)    | 07.06.2022    | Breakfast?    | Lunch?    | Dinner?   | Accommodation?
 * 3    | Paris(2022)   | 08.06.2022    | Breakfast?    | Lunch?    | Dinner?   | Accommodation?
 * 4    | Paris(2022)   | 09.06.2022    | Breakfast?    | Lunch?    | Dinner?   | Accommodation?
 * 5    | Paris(2022)   | 10.06.2022    | Breakfast?    | Lunch?    | Dinner?   | Accommodation?
 * ```
 *
 * @return an object containing 3 properties:
 * 1. dailyExpenses: the final dailyExpenses that should be displayed to the user
 * 2. correctedPerDiemItinerary: if not undefined, means that the itinerary (or expense.destinations) must be corrected following this value
 * 3. problematicDestiantion: The per diem destination needs replacing
 */
export const generateDailyExpenditureData = (
    itineraries: Array<FormPerDiemDestinationItinerary>,
    existingDailyExpenditures: Array<PerDiemDailyExpensesWithMomentDate>,
    destinationMap: Map<string, PerDiemDestination>,
    t: TFunction,
) => {
    const firstDate = itineraries[0]?.startDate
    const lastDate = itineraries[itineraries.length - 1]?.endDate
    const startMoment = moment(firstDate)
    const endMoment = moment(lastDate)

    const dailyExpenses: Array<PerDiemDailyExpensesWithMomentDate> = []
    let correctedPerDiemItinerary: Array<FormPerDiemDestinationItinerary> | undefined
    let problematicDestination: PerDiemDestination | undefined
    let newDestinationValue: PerDiemDestination | undefined

    let i = 0

    // Iterate through the trip dates daily....
    while (startMoment.isSameOrBefore(endMoment, "date")) {
        const yearOfDay = startMoment.get("year") // the year that the day on this iteration is being processed on

        const isLastDay = startMoment.isSame(endMoment, "date")

        // We determine the location of the requester by where they are AT THE END OF THE DAY so we set this to the end of the day
        const date = moment(startMoment).endOf("day")

        let currentDestination: FormPerDiemDestinationItinerary
        if (isLastDay) {
            // When it's the last day, just pick the last destination.
            currentDestination = itineraries[itineraries.length - 1]
        } else {
            // Search destinations that the user is on at the end of the day
            const targetDestination = itineraries.find((d) => date.isBetween(d.startDate, d.endDate, "second"))
            if (targetDestination === undefined) {
                throw new Error("Missing destination for date range")
            }

            currentDestination = targetDestination
        }

        let destinationToPush = destinationMap.get(currentDestination.destination!)!
        if (destinationToPush.year !== yearOfDay) {
            // When the year changes, (1st of Jan 2023), but the destination is using the source from the year before (2022), find the same destination, but on the next year.
            // E.g. its 1st of Jan 2023, and the trip goes to Deutschland from 30th of December. on 30 and 31, we will use the 2022 source, but once we hit 1st of January, we have to use 2023 source.
            const { correctedDestination, itineraryNeedsCorrection } = correctDestinationForNewYear(yearOfDay, destinationToPush, Array.from(destinationMap.values()))

            // When correctedItineraries is populated, means there should be a fixing in the destinations form. We store the perDiemDestination that causes it.
            if (itineraryNeedsCorrection && correctedDestination) {
                problematicDestination = destinationToPush
                newDestinationValue = correctedDestination
                correctedPerDiemItinerary = breakOffItineraries(itineraries, destinationToPush, correctedDestination)
            }

            destinationToPush = correctedDestination ?? destinationToPush // This fallback shouldnt happen....
        }
        let expenditure = existingDailyExpenditures?.[i]?.expenditure
        if (!expenditure) expenditure = { breakfast: false, lunch: false, dinner: false, accommodation: true }
        dailyExpenses.push({ destination: destinationToPush._id, date, expenditure })
        startMoment.add(1, "day")
        i++
    }

    return { dailyExpenses, correctedPerDiemItinerary, problematicDestination, correctedDestination: newDestinationValue }
}
