import i18n from "i18next"
import moment from "moment"

export const archiveSearchRules = () => ({
    period: [
        {
            validator: (_rule: any, value: [moment.Moment, moment.Moment]) => {
                if (!value) {
                    return Promise.reject(i18n.t("validation:required"))
                }
                if (Math.abs(value[0].diff(value[1], "months")) > 6) {
                    return Promise.reject(i18n.t("validation:period_too_long"))
                }
                return Promise.resolve()
            },
        },
    ],
})
