import { ExclamationCircleOutlined, UploadOutlined } from "@ant-design/icons"
import { COLOR } from "@finway-group/shared/lib/consts"
import { supportedInvoiceFormats } from "@finway-group/shared/lib/consts/common"
import { Employee, IntegrationTypeEnum, UploadKindEnum, UserApprover } from "@finway-group/shared/lib/models"
import { MAX_INPUT_LENGTH, MIN_INPUT_LENGTH } from "@finway-group/shared/lib/utils/validators"
import { Button, Col, Form, Input, Row, Tooltip, Upload } from "antd"
import { FormInstance } from "antd/lib/form/Form"
import { Store } from "antd/lib/form/interface"
import { RcCustomRequestOptions, RcFile } from "antd/lib/upload/interface"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import PhoneInput from "react-phone-number-input"
import "react-phone-number-input/style.css"

import IbanInput from "Components/ibanInput"
import { useCompany } from "Shared/hooks/company.hooks"
import { useEmployees } from "Shared/hooks/employee.hooks"
import { useIsFreeVersion, useIsTravelEnabled } from "Shared/hooks/featureFlags.hooks"
import { useRolesMap } from "Shared/hooks/role.hooks"
import { AuthzService, EmployeeService, FileService, UserService } from "Shared/services"
import { determinePossibleApprovers, getDisplayCountryCallingCode, getTooltipPopupContainer, validateCreditorNumberDATEVCompatibility } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

import { EmployeeRules } from "../rules"
import { UserCommonRules } from "../rules/user.common.rules"

interface EmployeeFormInterface {
    form: FormInstance
    employee?: Employee
    onSubmit: (values: Store) => void
    onRestore: (employee: Employee) => any
    setIsUploading: (flag: boolean) => void
}

export const EmployeeForm: React.FC<EmployeeFormInterface> = ({ form, employee, onSubmit, setIsUploading }) => {
    const { t } = useTranslation()

    const [phoneNumber, setPhoneNumber] = useStateIfMounted(form.getFieldValue("workPhone"))
    const [approverList, setApproverList] = useStateIfMounted<Array<Employee>>([])

    const [fileList, setFileList] = useStateIfMounted<Array<any>>([])

    const [phoneInputPlaceholder, setPhoneInputPlaceholder] = useState(getDisplayCountryCallingCode())

    const rolesMap = useRolesMap({ excludeDeleted: true })

    // feature flags
    const isFreeVersion = useIsFreeVersion()
    const isTravelEnabled = useIsTravelEnabled()

    const employees = useEmployees({ excludeDeleted: true })
    const loggedInUser = UserService.getLoggedInEmployeeProfile()
    const selectedCompany = useCompany()

    const isMe = employee && loggedInUser.id === employee.id

    const canUpdateEmployee = AuthzService.canUpdateEmployeeDetails(employee)

    const isPersonioUser = employee?.source?.id && employee?.source?.system === IntegrationTypeEnum.PERSONIO

    const [creditorNumberInfo, setCreditorNumberInfo] = useStateIfMounted<string | undefined>(undefined)

    const onCreditorNumberChange = (e: any) => {
        try {
            if (e.target.value) validateCreditorNumberDATEVCompatibility(e.target.value)
            setCreditorNumberInfo(undefined)
        } catch (err) {
            setCreditorNumberInfo(t("error:vendor.incompatible_creditor_number"))
        }
    }

    useEffect(() => {
        if (employee) {
            if (employee.img) {
                const currentFileList = [
                    {
                        uid: "1",
                        name: employee.getFullName(),
                        status: "done",
                        thumbUrl: employee.img,
                        type: "image/png",
                    },
                ]
                setFileList(currentFileList)
            }
            setPhoneNumber(employee.workPhone)
        }

        const newPossibleApprovers = determinePossibleApprovers(employee?.email || "", employees, rolesMap)
        // add employees that are already set previously, but potentially not in the possible
        // approver list anymore. Needed as it would only show IDs otherwise
        const presetApprovers = [
            ...(employee?.activeCompanyProfile.superior ? [EmployeeService.getEmployeeById(employee.activeCompanyProfile.superior)] : []),
            ...(employee?.activeCompanyProfile.approvals
                ? employee?.activeCompanyProfile.approvals.map((approval: UserApprover) => EmployeeService.getEmployeeById(approval.approver))
                : []),
        ]
        setApproverList([
            ...newPossibleApprovers,
            ...presetApprovers.filter((presetApprover) => !newPossibleApprovers.find((newPossibleApprover) => newPossibleApprover.id === presetApprover.id)),
        ])
    }, [])

    const rules = EmployeeRules(approverList, form, selectedCompany.fallbackApprover, employee)
    const rulesCommon = UserCommonRules()

    const doFileUpload = async (options: RcCustomRequestOptions) => {
        const { onSuccess, onError, onProgress, file } = options
        if (!FileService.checkFileSizeAndHandleError(file)) return

        try {
            setIsUploading(true)
            if (employee) employee.img = ""
            const data = await FileService.upload(file, UploadKindEnum.USER_IMG, onProgress)
            setIsUploading(false)
            onSuccess(data, data.url as unknown as RcFile)
        } catch (err) {
            setIsUploading(false)
            onError(err)
        }
    }

    const handlePhoneNumberChange = (value: string) => {
        form.setFieldsValue({
            workPhone: value ?? "",
        })
        return {
            workPhone: value ?? "",
        }
    }

    const handleCountryChange = (value: any) => {
        if (value) {
            const displayCountryCallingCode = getDisplayCountryCallingCode(value)
            setPhoneInputPlaceholder(displayCountryCallingCode)
        }
    }

    return (
        <Form form={form} layout="vertical" onFinish={onSubmit} scrollToFirstError>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Form.Item label={t("input:employee.location")} name="location" rules={rulesCommon.location}>
                        <Input placeholder={t("placeholder:employee.location")} autoComplete="off" maxLength={MAX_INPUT_LENGTH} minLength={MIN_INPUT_LENGTH} />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item label={t("input:employee.work_phone")} name="workPhone" rules={rules.workPhone} getValueFromEvent={handlePhoneNumberChange}>
                        <Input
                            hidden
                            addonAfter={
                                <PhoneInput
                                    className="-mr-10"
                                    key="workPhone"
                                    placeholder={phoneInputPlaceholder}
                                    onCountryChange={handleCountryChange}
                                    value={phoneNumber}
                                    onChange={handlePhoneNumberChange}
                                />
                            }
                        />
                    </Form.Item>
                </Col>
                <Col span={24} md="20">
                    <Form.Item label={t("input:employee.profile_picture")} name="img" shouldUpdate>
                        <Upload
                            listType="picture"
                            accept={supportedInvoiceFormats.join(",")}
                            customRequest={doFileUpload}
                            multiple={false}
                            fileList={fileList}
                            onChange={(data) => setFileList(data.fileList)}
                            onRemove={() => setFileList([])}
                            // to avoid opening the URL on click
                            onPreview={() => {}}
                            iconRender={() => <></>}
                            progress={{
                                strokeWidth: 3,
                                showInfo: false,
                                strokeColor: COLOR.secondary,
                                size: "small",
                                className: "pr-20",
                            }}
                        >
                            {fileList.length === 0 && (
                                <Button className="text-left btn-default">
                                    <UploadOutlined />
                                    <span> {t("action:upload_profile_picture")}</span>
                                </Button>
                            )}
                        </Upload>
                    </Form.Item>
                </Col>

                {(canUpdateEmployee || isMe) && (
                    <>
                        <Col span={14} md="14">
                            <Form.Item label={t("input:vendor.iban")} name="iban" rules={rules.iban}>
                                <IbanInput />
                            </Form.Item>
                        </Col>
                        <Col span={10} md="10">
                            <Form.Item label={t("input:vendor.bic")} name="bic" rules={rules.bic}>
                                <Input placeholder={t("placeholder:vendor.bic")} />
                            </Form.Item>
                        </Col>
                    </>
                )}
                {!isFreeVersion && canUpdateEmployee && (
                    <React.Fragment>
                        <Col span={12} md="24">
                            <Form.Item
                                label={t("input:vendor.creditor_number")}
                                name="creditorNumber"
                                help={creditorNumberInfo}
                                validateStatus={creditorNumberInfo ? "warning" : undefined}
                            >
                                <Input placeholder={t("placeholder:vendor.creditor_number")} onChange={onCreditorNumberChange} />
                            </Form.Item>
                        </Col>
                    </React.Fragment>
                )}

                {isTravelEnabled && canUpdateEmployee && (
                    <Col span={12} md="24">
                        <Form.Item
                            label={
                                <>
                                    {t("input:employee.mileage_rate")}
                                    <Tooltip getPopupContainer={getTooltipPopupContainer} title={t("info:employee_mileage_rate")} placement="left">
                                        <ExclamationCircleOutlined className="ml-8" />
                                    </Tooltip>
                                </>
                            }
                            name="mileageRate"
                            rules={rules.mileageRate}
                        >
                            <Input placeholder={t("placeholder:employee.mileage_rate")} />
                        </Form.Item>
                    </Col>
                )}

                {!isFreeVersion && canUpdateEmployee && (
                    <Col span={24}>
                        <Tooltip title={employee?.positionSource === IntegrationTypeEnum.PERSONIO ? t("input:employee.personioTooltip") : undefined}>
                            <Form.Item
                                label={t("input:employee.position")}
                                name="position"
                                rules={employee?.positionSource === IntegrationTypeEnum.PERSONIO ? [] : rulesCommon.position}
                                required={!isPersonioUser}
                            >
                                <Input
                                    placeholder={t("placeholder:employee.position")}
                                    autoComplete="off"
                                    maxLength={MAX_INPUT_LENGTH}
                                    minLength={MIN_INPUT_LENGTH}
                                    disabled={employee?.positionSource === IntegrationTypeEnum.PERSONIO}
                                />
                            </Form.Item>
                        </Tooltip>
                    </Col>
                )}
            </Row>
        </Form>
    )
}

export default EmployeeForm
