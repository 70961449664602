import { Transaction } from "@finway-group/shared/lib/models"
import { Action } from "redux"

import { TransactionTabTypeEnum } from "Features/pages/transactions/transactions.list"

export enum TransactionActionTypes {
    SET_TRANSACTION = "SET_TRANSACTION",
    FETCH_ONE_TRANSACTION = "FETCH_ONE_TRANSACTION",
    FETCH_ALL_TRANSACTION = "FETCH_ALL_TRANSACTION",
    DELETE_TRANSACTION = "DELETE_TRANSACTION",
    DELETE_MANY_TRANSACTIONS = "DELETE_MANY_TRANSACTIONS",
    SYNC_ONE_TRANSACTION = "SYNC_ONE_TRANSACTION",
    SET_SHOULD_REFETCH_TRANSACTIONS = "SET_SHOULD_REFETCH_TRANSACTIONS",
    SET_ACTIVE_TRANSACTION_TAB = "SET_ACTIVE_TRANSACTION_TAB",
}

export interface SetActiveTransactionTabAction extends Action<typeof TransactionActionTypes.SET_ACTIVE_TRANSACTION_TAB> {
    type: typeof TransactionActionTypes.SET_ACTIVE_TRANSACTION_TAB
    activeTab: TransactionTabTypeEnum
}

export interface SetTransactionAction extends Action<typeof TransactionActionTypes.SET_TRANSACTION> {
    type: typeof TransactionActionTypes.SET_TRANSACTION
    transaction: Transaction
}

export interface FetchOneTransactionAction extends Action<typeof TransactionActionTypes.FETCH_ONE_TRANSACTION> {
    type: typeof TransactionActionTypes.FETCH_ONE_TRANSACTION
    transaction: Transaction
}
export interface FetchAllTransactionAction extends Action<typeof TransactionActionTypes.FETCH_ALL_TRANSACTION> {
    type: typeof TransactionActionTypes.FETCH_ALL_TRANSACTION
    transactions: Array<Transaction>
    page: number
    limit: number
    totalPages: number
    totalDocs: number
}

export interface DeleteTransactionAction extends Action<typeof TransactionActionTypes.DELETE_TRANSACTION> {
    type: typeof TransactionActionTypes.DELETE_TRANSACTION
    transactionId: string
}

export interface DeleteManyTransactionsAction extends Action<typeof TransactionActionTypes.DELETE_MANY_TRANSACTIONS> {
    type: typeof TransactionActionTypes.DELETE_MANY_TRANSACTIONS
    transactionIds: Array<string>
}

export interface SyncOneTransactionAction extends Action<typeof TransactionActionTypes.SYNC_ONE_TRANSACTION> {
    type: typeof TransactionActionTypes.SYNC_ONE_TRANSACTION
    transaction: Transaction
}

export interface SetShouldRefetchTransactionsAction extends Action<typeof TransactionActionTypes.SET_SHOULD_REFETCH_TRANSACTIONS> {
    type: typeof TransactionActionTypes.SET_SHOULD_REFETCH_TRANSACTIONS
    shouldRefetch: boolean
}

export type TransactionActions =
    | SetTransactionAction
    | SetActiveTransactionTabAction
    | FetchOneTransactionAction
    | DeleteTransactionAction
    | FetchAllTransactionAction
    | SyncOneTransactionAction
    | SetShouldRefetchTransactionsAction
    | DeleteManyTransactionsAction
