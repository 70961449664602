import {
    ActualForecastType,
    AntdTableCompatibleCashFlow,
    CashFlowRowIndexEnum,
    LiquidityCashFlow,
    LiquidityPeriodEnum,
    TransactionCategory,
    TransactionCategoryTypeEnum,
} from "@finway-group/shared/lib/models"
import { ColumnsType } from "antd/lib/table"
import { TFunction } from "i18next"
import moment from "moment"

import { CashFlowAmountSourceEnum, CashFlowDirectionEnum, ForecastDiagramData } from "../../features/pages/liquidity/liquidity.types"

/**
 * This will transform the array of monthly cashflow reports into a format compatible with antd table
 * Must receive array of monthly cash flow report in a flat form (not made into a tree yet)
 */
export const transformAllCashFlowsToTableCompatibleFormat = (cashFlows: Array<LiquidityCashFlow>, t: TFunction, categories: Array<TransactionCategory>) => {
    const monthColumns: ColumnsType = []

    // The order of the elements of tableDataSource follows CashFlowRowIndexEnum's order it will be displayed in this order too.
    const tableDataSource: Array<AntdTableCompatibleCashFlow> = [
        {
            key: CashFlowRowIndexEnum.OPENING_BALANCE.toString(),
            rowTitle: t("info:liquidity_management.opening_balance"),
            rowIndex: CashFlowRowIndexEnum.OPENING_BALANCE,
        },
        {
            key: CashFlowRowIndexEnum.TOTAL_INCOME.toString(),
            rowTitle: t("info:liquidity_management.total_income"),
            rowIndex: CashFlowRowIndexEnum.TOTAL_INCOME,
        },
        {
            key: CashFlowRowIndexEnum.TOTAL_SPENDING.toString(),
            rowTitle: t("info:liquidity_management.total_spending"),
            rowIndex: CashFlowRowIndexEnum.TOTAL_SPENDING,
        },
        {
            key: CashFlowRowIndexEnum.CASHFLOW_CUMULATED.toString(),
            rowTitle: t("info:liquidity_management.cashflow_cumulated"),
            rowIndex: CashFlowRowIndexEnum.CASHFLOW_CUMULATED,
        },
        {
            key: CashFlowRowIndexEnum.FINAL_BALANCE.toString(),
            rowTitle: t("info:liquidity_management.final_balance"),
            rowIndex: CashFlowRowIndexEnum.FINAL_BALANCE,
        },
    ]

    const incomeTableData: Array<AntdTableCompatibleCashFlow> = []
    const spendingTableData: Array<AntdTableCompatibleCashFlow> = []

    const categoryMap = new Map<string, string>()
    categories.forEach((category) => {
        categoryMap.set(category.externalId, category.id)
    })

    for (const category of categories) {
        category.type == TransactionCategoryTypeEnum.INFLOW
            ? incomeTableData.push({
                  key: `${CashFlowRowIndexEnum.TOTAL_INCOME}${category.name}`,
                  rowIndex: CashFlowRowIndexEnum.TOTAL_INCOME,
                  rowTitle: category.name,
                  childrenIds: category.children, // Refer the parent/child with finAPI internal ids to find out the category hierarchy.
                  parentId: category.parentId,
                  categoryId: category.id,
                  isGroup: category.isGroup,
              } as AntdTableCompatibleCashFlow)
            : spendingTableData.push({
                  key: `${CashFlowRowIndexEnum.TOTAL_SPENDING}${category.name}`,
                  rowIndex: CashFlowRowIndexEnum.TOTAL_SPENDING,
                  rowTitle: category.name,
                  childrenIds: category.children,
                  parentId: category.parentId,
                  categoryId: category.id,
                  isGroup: category.isGroup,
              } as AntdTableCompatibleCashFlow)
    }

    for (const cashFlow of cashFlows) {
        const periodIndex = `${moment(cashFlow.period).year()}${moment(cashFlow.period).month() + 1}`
        monthColumns.push({
            title: moment(periodIndex, "YYYYMM").format("MMM YY"),
            dataIndex: periodIndex,
            key: periodIndex,
            width: 140,
        })

        tableDataSource[CashFlowRowIndexEnum.OPENING_BALANCE][periodIndex] = cashFlow.openingBalance
        tableDataSource[CashFlowRowIndexEnum.TOTAL_INCOME][periodIndex] = cashFlow.totalIncome
        tableDataSource[CashFlowRowIndexEnum.TOTAL_SPENDING][periodIndex] = cashFlow.totalSpending
        tableDataSource[CashFlowRowIndexEnum.CASHFLOW_CUMULATED][periodIndex] = cashFlow.totalBalance
        tableDataSource[CashFlowRowIndexEnum.FINAL_BALANCE][periodIndex] = cashFlow.finalBalance

        const spendingObject: { [categoryId: string]: ActualForecastType } = cashFlow.cashFlowsPerCategory.reduce(
            (a, v) => ({ ...a, [v.category?.toString() ?? ""]: { ...v.spending, forecastRules: v.forecastRules } }),
            {},
        )
        const incomeObject: { [categoryId: string]: ActualForecastType } = cashFlow.cashFlowsPerCategory.reduce(
            (a, v) => ({ ...a, [v.category?.toString() ?? ""]: { ...v.income, forecastRules: v.forecastRules } }),
            {},
        )
        incomeTableData.map((data) => (data[periodIndex] = incomeObject[data.categoryId!] ?? { actual: 0, forecast: 0, forecastRules: [] })) // TODO check whether we all categories are included in one cashflow.
        spendingTableData.map((data) => (data[periodIndex] = spendingObject[data.categoryId!] ?? { actual: 0, forecast: 0, forecastRules: [] }))
    }
    tableDataSource[CashFlowRowIndexEnum.TOTAL_INCOME].children = constructTreeForSpendingOrIncomeRows(incomeTableData)
    tableDataSource[CashFlowRowIndexEnum.TOTAL_SPENDING].children = constructTreeForSpendingOrIncomeRows(spendingTableData)

    return {
        tableDataSource,
        monthColumns,
    }
}

export const constructTreeForSpendingOrIncomeRows = (rowsWithHierarchyDefinition: Array<AntdTableCompatibleCashFlow>) => {
    for (const row of rowsWithHierarchyDefinition) {
        const parent = row.parentId ? rowsWithHierarchyDefinition.find((entry) => entry.categoryId === row.parentId) : undefined
        if (parent) {
            if (parent.children) {
                parent.children.push(row)
            } else {
                parent.children = [row]
            }
        }
    }

    // return root only
    return rowsWithHierarchyDefinition.filter((i) => !i.parentId)
}

// Returns the months within a date range taking into account the frequency: monthly, quarterly or annually.
// If endDate is undefined then only return the startDate
export const getMonthsRangeWithFrequency = (startDate: Date, endDate?: Date, frequency?: LiquidityPeriodEnum): Array<string> => {
    const months: Array<string> = []

    if (endDate) {
        const startDateMoment = moment(startDate)
        const endDataMoment = moment(endDate)

        if (endDataMoment.isBefore(startDateMoment)) {
            throw "End date must be greater than start date."
        }

        while (startDateMoment.isSameOrBefore(endDataMoment)) {
            months.push(startDateMoment.format("YYYY-MM-15"))
            startDateMoment.add(1, "month")
        }
        if (frequency == LiquidityPeriodEnum.QUARTERLY) {
            return months.filter((_, index) => index % 3 === 0)
        }
        if (frequency == LiquidityPeriodEnum.YEARLY) {
            return months.filter((_, index) => index % 12 === 0)
        }
    } else months.push(startDate.toString())

    return months
}

export const getInflowValue = (liquidityCashFlow: Array<LiquidityCashFlow>, startPeriod: moment.Moment, endPeriod: moment.Moment, cashFlowDirection: CashFlowDirectionEnum) =>
    liquidityCashFlow
        .filter((l) => moment(l.period).isBetween(startPeriod, endPeriod, "day"))
        .reduce((a, l) => ({ actual: a.actual + l[cashFlowDirection].actual, forecast: a.forecast + l[cashFlowDirection].forecast }), { actual: 0, forecast: 0 })

export const getLineChartData = (liquidityCashFlow: Array<LiquidityCashFlow>) => {
    const relevantData = liquidityCashFlow.filter((l) => moment(l.period).isBefore(moment(), "month"))
    const data = relevantData.map((c) => ({ x: moment(c.period).format("YYYY-MM-DD"), y: c.openingBalance.actual }))
    return [
        {
            id: "balance",
            color: "hsl(148, 70%, 50%)", // todo - design
            data,
        },
    ]
}

export const getDiagramLegendString = (t: TFunction, keyName: string) => {
    switch (keyName) {
        case "actualIncome":
            return t("label:liquidity.cash_in_actual")
        case "actualSpending":
            return t("label:liquidity.cash_out_actual")
        case "forecastIncome":
            return t("label:liquidity.cash_in_forecast")
        case "forecastSpending":
            return t("label:liquidity.cash_out_forecast")
        case "balance":
            return t("label:liquidity.balance")
        default:
            return undefined
    }
}

export const convertCashFlowForForecastDiagram = (
    cashFlowAmountSource: CashFlowAmountSourceEnum,
    cashFlow: Array<LiquidityCashFlow>,
    lastActualBalance: number = 0,
): Array<ForecastDiagramData> => {
    const selector =
        cashFlowAmountSource === CashFlowAmountSourceEnum.ACTUAL
            ? (entry: LiquidityCashFlow) => moment(entry.period).isSameOrBefore(moment(), "month")
            : (entry: LiquidityCashFlow) => moment(entry.period).isAfter(moment(), "month")

    const filteredCashFlows = cashFlow.filter(selector)

    const chartPoints: Array<ForecastDiagramData> = []

    // To continue (project) balance after the actual data ends with forecast data.
    let balanceAdjustment = cashFlowAmountSource === CashFlowAmountSourceEnum.ACTUAL ? 0 : lastActualBalance

    for (const entry of filteredCashFlows) {
        const chartPoint: ForecastDiagramData = {
            month: moment(entry.period).format("MMM YY"),
            balance: entry.finalBalance[cashFlowAmountSource],
        }
        if (cashFlowAmountSource === CashFlowAmountSourceEnum.ACTUAL) {
            chartPoint.actualIncome = entry.totalIncome.actual
            chartPoint.actualSpending = entry.totalSpending.actual
        } else {
            chartPoint.forecastIncome = entry.totalIncome.forecast
            chartPoint.forecastSpending = entry.totalSpending.forecast
            // Use totalBalance for forecast, because we're projecting how the chart would look like if we continue the data with forecast
            chartPoint.balance = entry.totalBalance.forecast + balanceAdjustment
            balanceAdjustment = chartPoint.balance
        }
        chartPoints.push(chartPoint)
    }

    return chartPoints
}

export const findCurrentMonthCashFlow = (cashFlowData: Array<LiquidityCashFlow>) => cashFlowData.find((c) => moment(c.period).isSame(moment(), "month"))
export const findPreviousMonthCashFlow = (cashFlowData: Array<LiquidityCashFlow>) => cashFlowData.find((c) => moment(c.period).isSame(moment().subtract(1, "month"), "month"))

export const redirectToFreeDemo = (t: TFunction) => {
    window.open(t("action:demo_url")) // urls for different languages are embedded in action.json
}
