import axios from "axios"

export const TENANT_MODEL = "tenant"

const TenantService = {
    getTenant: async () => {
        const { data } = await axios.get(`${TENANT_MODEL}/simulate`)
        return data as { [key: string]: string }
    },
}

export default TenantService
