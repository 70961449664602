import { FormInstance, Rule } from "antd/lib/form"
import moment from "moment"

import i18n from "Shared/locales/i18n"
import { getFilteredExpenseStatusArrayForExport } from "Shared/utils/expense.utils"

export const generateRules = (formInstance: FormInstance): { [key: string]: Array<Rule> } => ({
    minDate: [
        { required: true },
        {
            validator: (_rule: any, value: moment.Moment) => {
                const maxDate = formInstance.getFieldValue("maxDate")
                if (maxDate && value) {
                    if (value.toDate() > maxDate) {
                        return Promise.reject(
                            i18n.t("validation:date_before", {
                                attribute: i18n.t("input:expense_filter.starting_date"),
                                compare: i18n.t("input:expense_filter.ending_date"),
                            }),
                        )
                    }
                }

                return Promise.resolve()
            },
        },
    ],
    maxDate: [
        { required: true },
        {
            validator: (_rule: any, value: moment.Moment) => {
                const minDate = formInstance.getFieldValue("minDate")
                if (minDate && value) {
                    if (value.toDate() < minDate) {
                        return Promise.reject(
                            i18n.t("validation:date_after", {
                                attribute: i18n.t("input:expense_filter.ending_date"),
                                compare: i18n.t("input:expense_filter.starting_date"),
                            }),
                        )
                    }
                }

                return Promise.resolve()
            },
        },
    ],
    status: [
        {
            validator: (_rule: any, value: any) => {
                const exportFormat = formInstance.getFieldValue("format")
                if (value !== "" && !getFilteredExpenseStatusArrayForExport(exportFormat).includes(Number(value))) {
                    return Promise.reject(i18n.t("validation:export_status_not_supported"))
                }

                return Promise.resolve()
            },
        },
    ],
})
