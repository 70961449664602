import { Button, Dropdown, Menu } from "antd"
import React from "react"
import { Settings as SettingsIcon } from "react-feather"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import { RoutePathEnum } from "Shared/router/RoutePath.enum"
import { AuthzService } from "Shared/services"
import { getPopupAnchor } from "Shared/utils/popup.utils"

const SettingsNavbar = () => {
    const history = useHistory()
    const { t } = useTranslation()

    const canViewSystemSettings = AuthzService.canViewSystemSettings()

    const label = (isOnClickEnabled: boolean) => (
        <div className="w-full cursor-pointer pr-20" data-testid="settingsNavBar" style={{ height: 56 }}>
            <Button
                style={{ borderRadius: "50px", height: 24, width: 24 }}
                onClick={() => {
                    if (isOnClickEnabled) history.push(RoutePathEnum.SETTINGS_COMPANY)
                }}
                className="finway-settings-button"
            >
                <span>
                    <SettingsIcon width={16} height={16} color={"#191345"} />
                </span>
            </Button>
        </div>
    )

    if (!canViewSystemSettings) return <>{label(true)}</>

    return (
        <Dropdown
            getPopupContainer={getPopupAnchor()}
            className="flex items-center"
            trigger={["click"]}
            overlay={
                <Menu className="z-20 user-dropdown">
                    <Menu.Item onClick={() => history.push(RoutePathEnum.SETTINGS_COMPANY)}>{t("label:company")}</Menu.Item>
                    {canViewSystemSettings && <Menu.Item onClick={() => history.push(RoutePathEnum.SETTINGS_SYSTEM)}>{t("label:system")}</Menu.Item>}
                </Menu>
            }
        >
            {label(false)}
        </Dropdown>
    )
}

export default SettingsNavbar
