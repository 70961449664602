import { RightFormElementsEnum, RoleConfigFormElementsEnum } from "./role.form.types"

export const rightFormData = [
    // EXPENSES
    {
        resource: "label:rights.resource.requests",
        readRight: {
            tooltip: "label:rights.approval_viewingright1.tooltip",
            name: RoleConfigFormElementsEnum.REQUEST_VIEW_ALL,
            onEnabled: {
                [RoleConfigFormElementsEnum.REQUEST_VIEW_ALL_TEAM]: true,
                [RoleConfigFormElementsEnum.REQUEST_VIEW_ALL]: true,
            },
            onDisabled: {
                [RoleConfigFormElementsEnum.REQUEST_VIEW_ALL]: false,
                [RoleConfigFormElementsEnum.ALL_VIEW]: false,
                [RightFormElementsEnum.REQUEST_ABILITY_APPROVE]: false,
                [RightFormElementsEnum.REQUEST_ABILITY_MANAGE]: false,
            },
        },
        actionRight: {
            title: "label:rights.approval_requests.title",
            tooltip: "label:rights.approval_requests.tooltip",
            name: RightFormElementsEnum.REQUEST_ABILITY_APPROVE,
            onEnabled: {
                [RoleConfigFormElementsEnum.ALL_NO_EDIT]: false,
                [RoleConfigFormElementsEnum.SELECTABLE_AS_APPROVER]: true,
                [RoleConfigFormElementsEnum.REQUEST_VIEW_ALL_TEAM]: true,
                [RoleConfigFormElementsEnum.REQUEST_VIEW_ALL]: true,
            },
            onDisabled: {},
        },
    },
    {
        actionRight: {
            title: "label:rights.approval_requests_status.title",
            tooltip: "label:rights.approval_requests_status.tooltip",
            name: RightFormElementsEnum.REQUEST_ABILITY_MANAGE,
            onEnabled: {
                [RoleConfigFormElementsEnum.ALL_NO_EDIT]: false,
                [RoleConfigFormElementsEnum.REQUEST_VIEW_ALL_TEAM]: true,
                [RoleConfigFormElementsEnum.REQUEST_VIEW_ALL]: true,
            },
            onDisabled: {},
        },
    },
    {
        readRight: {
            tooltip: "label:rights.approval_viewingright2.tooltip",
            name: RoleConfigFormElementsEnum.REQUEST_VIEW_ALL_TEAM,
            onEnabled: {},
            onDisabled: {
                [RoleConfigFormElementsEnum.ALL_VIEW]: false,
                [RoleConfigFormElementsEnum.REQUEST_VIEW_ALL_TEAM]: false,
                [RightFormElementsEnum.REQUEST_ABILITY_APPROVE_TEAM]: false,
                [RightFormElementsEnum.REQUEST_ABILITY_MANAGE_TEAM]: false,
                [RoleConfigFormElementsEnum.REQUEST_VIEW_ALL]: false,
                [RightFormElementsEnum.REQUEST_ABILITY_APPROVE]: false,
                [RightFormElementsEnum.REQUEST_ABILITY_MANAGE]: false,
            },
        },
        actionRight: {
            title: "label:rights.approval_requests_team.title",
            tooltip: "label:rights.approval_requests_team.tooltip",
            name: RightFormElementsEnum.REQUEST_ABILITY_APPROVE_TEAM,
            onEnabled: {
                [RoleConfigFormElementsEnum.ALL_NO_EDIT]: false,
                [RoleConfigFormElementsEnum.SELECTABLE_AS_APPROVER]: true,
                [RoleConfigFormElementsEnum.REQUEST_VIEW_ALL_TEAM]: true,
            },
            onDisabled: {},
        },
    },
    {
        actionRight: {
            title: "label:rights.approval_requests_status_team.title",
            tooltip: "label:rights.approval_requests_status_team.tooltip",
            name: RightFormElementsEnum.REQUEST_ABILITY_MANAGE_TEAM,
            onEnabled: {
                [RoleConfigFormElementsEnum.REQUEST_VIEW_ALL_TEAM]: true,
                [RoleConfigFormElementsEnum.ALL_NO_EDIT]: false,
            },
            onDisabled: {},
        },
    },
    {
        actionRight: {
            title: "label:rights.approval_dashboard.title",
            tooltip: "label:rights.approval_dashboard.tooltip",
            name: RightFormElementsEnum.REQUEST_ABILITY_APPROVE_DASHBOARD,
            onEnabled: {
                [RoleConfigFormElementsEnum.ALL_NO_EDIT]: false,
            },
            onDisabled: {},
        },
    },
    {
        resource: "label:rights.resource.formal_review",
        readRight: {
            tooltip: "label:rights.review_viewingright1.tooltip",
            name: RightFormElementsEnum.REQUEST_ACCESS_FORMAL_REVIEW,
            onEnabled: {},
            onDisabled: {
                [RoleConfigFormElementsEnum.ALL_VIEW]: false,
                [RightFormElementsEnum.REQUEST_ABILITY_FORMAL_REVIEW]: false,
            },
        },
        actionRight: {
            title: "label:rights.review_requests.title",
            tooltip: "label:rights.review_requests.tooltip",
            name: RightFormElementsEnum.REQUEST_ABILITY_FORMAL_REVIEW,
            onEnabled: {
                [RoleConfigFormElementsEnum.ALL_NO_EDIT]: false,
                [RightFormElementsEnum.REQUEST_ACCESS_FORMAL_REVIEW]: true,
            },
            onDisabled: {},
        },
    },
    {
        resource: "label:rights.resource.pay",
        readRight: {
            tooltip: "label:rights.pay_viewingright1.tooltip",
            name: RightFormElementsEnum.PAY_ACCESS,
            onEnabled: {},
            onDisabled: {
                [RoleConfigFormElementsEnum.ALL_VIEW]: false,
                [RightFormElementsEnum.PAY_ABILITY_PAY_REQUESTS]: false,
            },
        },
        actionRight: {
            title: "label:rights.payment_requests.title",
            tooltip: "label:rights.payment_requests.tooltip",
            name: RightFormElementsEnum.PAY_ABILITY_PAY_REQUESTS,
            onEnabled: {
                [RoleConfigFormElementsEnum.ALL_NO_EDIT]: false,
                [RightFormElementsEnum.PAY_ACCESS]: true,
            },
            onDisabled: {},
        },
    },
    {
        resource: "label:rights.resource.export",
        readRight: {
            tooltip: "label:rights.export_viewingright1.tooltip",
            name: RightFormElementsEnum.EXPORT_ACCESS,
            onEnabled: {},
            onDisabled: {
                [RoleConfigFormElementsEnum.ALL_VIEW]: false,
                [RightFormElementsEnum.EXPORT_ABILITY_EXPORT_ALL_FORMATS]: false,
            },
        },
        actionRight: {
            title: "label:rights.export_requests.title",
            tooltip: "label:rights.export_requests.tooltip",
            name: RightFormElementsEnum.EXPORT_ABILITY_EXPORT_ALL_FORMATS,
            onEnabled: {
                [RoleConfigFormElementsEnum.ALL_NO_EDIT]: false,
                [RightFormElementsEnum.EXPORT_ACCESS]: true,
            },
            onDisabled: {},
        },
    },
    // SUBSCRIPTIONS
    {
        resource: "label:rights.resource.subscriptions",
        readRight: {
            tooltip: "label:rights.subscriptions_viewingright1.tooltip",
            name: RightFormElementsEnum.REQUEST_SUBSCRIPTION_ACCESS,
            onEnabled: {
                [RightFormElementsEnum.REQUEST_SUBSCRIPTION_TEAM_ACCESS]: true,
            },
            onDisabled: {
                [RoleConfigFormElementsEnum.ALL_VIEW]: false,
                [RightFormElementsEnum.REQUEST_SUBSCRIPTION_ABILITY_MANAGE]: false,
            },
        },
        actionRight: {
            title: "label:rights.subscriptions_company.title",
            tooltip: "label:rights.subscriptions_company.tooltip",
            name: RightFormElementsEnum.REQUEST_SUBSCRIPTION_ABILITY_MANAGE,
            onEnabled: {
                [RoleConfigFormElementsEnum.ALL_NO_EDIT]: false,
                [RightFormElementsEnum.REQUEST_SUBSCRIPTION_TEAM_ACCESS]: true,
                [RightFormElementsEnum.REQUEST_SUBSCRIPTION_TEAM_ABILITY_MANAGE]: true,
                [RightFormElementsEnum.REQUEST_SUBSCRIPTION_ACCESS]: true,
            },
            onDisabled: {},
        },
    },
    {
        readRight: {
            tooltip: "label:rights.subscriptions_viewingright2.tooltip",
            name: RightFormElementsEnum.REQUEST_SUBSCRIPTION_TEAM_ACCESS,
            onEnabled: {},
            onDisabled: {
                [RoleConfigFormElementsEnum.ALL_VIEW]: false,
                [RightFormElementsEnum.REQUEST_SUBSCRIPTION_TEAM_ABILITY_MANAGE]: false,
                [RightFormElementsEnum.REQUEST_SUBSCRIPTION_ACCESS]: false,
                [RightFormElementsEnum.REQUEST_SUBSCRIPTION_ABILITY_MANAGE]: false,
            },
        },
        actionRight: {
            title: "label:rights.subscriptions_team.title",
            tooltip: "label:rights.subscriptions_team.tooltip",
            name: RightFormElementsEnum.REQUEST_SUBSCRIPTION_TEAM_ABILITY_MANAGE,
            onEnabled: {
                [RoleConfigFormElementsEnum.ALL_NO_EDIT]: false,
                [RightFormElementsEnum.REQUEST_SUBSCRIPTION_TEAM_ACCESS]: true,
            },
            onDisabled: {
                [RightFormElementsEnum.REQUEST_SUBSCRIPTION_ABILITY_MANAGE]: false,
            },
        },
    },
    // CARDS
    {
        resource: "label:rights.resource.cards",
        readRight: {
            tooltip: "label:rights.cards_viewingright1.tooltip",
            name: RightFormElementsEnum.CARD_ACCESS,
            onEnabled: {
                [RightFormElementsEnum.CARD_ACCESS_TEAM]: true,
            },
            onDisabled: {
                [RoleConfigFormElementsEnum.ALL_VIEW]: false,
                [RightFormElementsEnum.CARD_ABILITY_MANAGE]: false,
            },
        },
        actionRight: {
            title: "label:rights.cards_company.title",
            tooltip: "label:rights.cards_company.tooltip",
            name: RightFormElementsEnum.CARD_ABILITY_MANAGE,
            onEnabled: {
                [RoleConfigFormElementsEnum.ALL_NO_EDIT]: false,
                [RightFormElementsEnum.CARD_ACCESS_TEAM]: true,
                [RightFormElementsEnum.CARD_ABILITY_MANAGE_TEAM]: true,
                [RightFormElementsEnum.CARD_ACCESS]: true,
            },
            onDisabled: {},
        },
    },
    {
        readRight: {
            tooltip: "label:rights.cards_viewingright2.tooltip",
            name: RightFormElementsEnum.CARD_ACCESS_TEAM,
            onEnabled: {},
            onDisabled: {
                [RoleConfigFormElementsEnum.ALL_VIEW]: false,
                [RightFormElementsEnum.CARD_ABILITY_MANAGE_TEAM]: false,
                [RightFormElementsEnum.CARD_ACCESS]: false,
                [RightFormElementsEnum.CARD_ABILITY_MANAGE]: false,
            },
        },
        actionRight: {
            title: "label:rights.cards_team.title",
            tooltip: "label:rights.cards_team.tooltip",
            name: RightFormElementsEnum.CARD_ABILITY_MANAGE_TEAM,
            onEnabled: {
                [RoleConfigFormElementsEnum.ALL_NO_EDIT]: false,
                [RightFormElementsEnum.CARD_ACCESS_TEAM]: true,
            },
            onDisabled: {},
        },
    },
    {
        readRight: {
            tooltip: "label:rights.cards_viewingright3.tooltip",
            name: RightFormElementsEnum.CARD_WALLET_ACCESS,
            onEnabled: {},
            onDisabled: {
                [RoleConfigFormElementsEnum.ALL_VIEW]: false,
                [RightFormElementsEnum.CARD_WALLET_ABILITY_MANAGE]: false,
            },
        },
        actionRight: {
            title: "label:rights.cards_wallet.title",
            tooltip: "label:rights.cards_wallet.tooltip",
            name: RightFormElementsEnum.CARD_WALLET_ABILITY_MANAGE,
            onEnabled: {
                [RoleConfigFormElementsEnum.ALL_NO_EDIT]: false,
                [RightFormElementsEnum.CARD_WALLET_ACCESS]: true,
            },
            onDisabled: {},
        },
    },
    // TRANSACTIONS
    {
        resource: "label:rights.resource.transactions",
        readRight: {
            tooltip: "label:rights.transactions_viewingright1.tooltip",
            name: RightFormElementsEnum.TRANSACTION_ACCESS,
            onEnabled: {},
            onDisabled: {
                [RoleConfigFormElementsEnum.ALL_VIEW]: false,
                [RightFormElementsEnum.TRANSACTION_ABILITY_MANAGE]: false,
            },
        },
        actionRight: {
            title: "label:rights.transactions_manage.title",
            tooltip: "label:rights.transactions_manage.tooltip",
            name: RightFormElementsEnum.TRANSACTION_ABILITY_MANAGE,
            onEnabled: {
                [RoleConfigFormElementsEnum.ALL_NO_EDIT]: false,
                [RightFormElementsEnum.TRANSACTION_ACCESS]: true,
            },
            onDisabled: {},
        },
    },
    // EMPLOYEES
    {
        resource: "label:rights.resource.employees",
        readRight: {
            tooltip: "label:rights.emplyees_viewingright1.tooltip",
            name: RightFormElementsEnum.USER_ACCESS,
            onEnabled: {
                [RightFormElementsEnum.USER_ACCESS_TEAM]: true,
            },
            onDisabled: {
                [RoleConfigFormElementsEnum.ALL_VIEW]: false,
                [RightFormElementsEnum.USER_ACCESS_TEAM]: false,
                [RightFormElementsEnum.USER_ABILITY_APPROVAL_DATA_EDIT_ALL]: false,
                [RightFormElementsEnum.USER_ABILITY_APPROVAL_DATA_EDIT_TEAM]: false,
                [RightFormElementsEnum.USER_ABILITY_GLOBAL_DATA_EDIT_ALL]: false,
                [RightFormElementsEnum.USER_ABILITY_GLOBAL_DATA_EDIT_TEAM]: false,
            },
        },
        actionRight: {
            title: "label:rights.employees_manage.title",
            tooltip: "label:rights.employees_manage.tooltip",
            name: RightFormElementsEnum.USER_ABILITY_GLOBAL_DATA_EDIT_ALL,
            onEnabled: {
                [RoleConfigFormElementsEnum.ALL_NO_EDIT]: false,
                [RightFormElementsEnum.USER_ACCESS]: true,
                [RightFormElementsEnum.USER_ACCESS_TEAM]: true,
                [RightFormElementsEnum.USER_ABILITY_GLOBAL_DATA_EDIT_TEAM]: true,
            },
            onDisabled: {},
        },
    },
    {
        actionRight: {
            title: "label:rights.employees_conditions.title",
            tooltip: "label:rights.employees_conditions.tooltip",
            name: RightFormElementsEnum.USER_ABILITY_APPROVAL_DATA_EDIT_ALL,
            onEnabled: {
                [RoleConfigFormElementsEnum.ALL_NO_EDIT]: false,
                [RightFormElementsEnum.USER_ACCESS]: true,
                [RightFormElementsEnum.USER_ACCESS_TEAM]: true,
                [RightFormElementsEnum.USER_ABILITY_APPROVAL_DATA_EDIT_TEAM]: true,
            },
            onDisabled: {},
        },
    },
    {
        readRight: {
            tooltip: "label:rights.employees_viewingright2.tooltip",
            name: RightFormElementsEnum.USER_ACCESS_TEAM,
            onEnabled: {},
            onDisabled: {
                [RoleConfigFormElementsEnum.ALL_VIEW]: false,
                [RightFormElementsEnum.USER_ABILITY_GLOBAL_DATA_EDIT_TEAM]: false,
                [RightFormElementsEnum.USER_ABILITY_APPROVAL_DATA_EDIT_TEAM]: false,
            },
        },
        actionRight: {
            title: "label:rights.employees_manage_team.title",
            tooltip: "label:rights.employees_manage_team.tooltip",
            name: RightFormElementsEnum.USER_ABILITY_GLOBAL_DATA_EDIT_TEAM,
            onEnabled: {
                [RoleConfigFormElementsEnum.ALL_NO_EDIT]: false,
                [RightFormElementsEnum.USER_ACCESS_TEAM]: true,
            },
            onDisabled: {},
        },
    },
    {
        actionRight: {
            title: "label:rights.employees_conditions_team.title",
            tooltip: "label:rights.employees_conditions_team.tooltip",
            name: RightFormElementsEnum.USER_ABILITY_APPROVAL_DATA_EDIT_TEAM,
            onEnabled: {
                [RoleConfigFormElementsEnum.ALL_NO_EDIT]: false,
                [RightFormElementsEnum.USER_ACCESS_TEAM]: true,
            },
            onDisabled: {},
        },
    },
    // COST CENTERS
    {
        resource: "label:rights.resource.cost_centers",
        readRight: {
            tooltip: "label:rights.costcenter1_viewingright1.tooltip",
            name: RightFormElementsEnum.COST_CENTER_1_ACCESS,
            onEnabled: {},
            onDisabled: {
                [RoleConfigFormElementsEnum.ALL_VIEW]: false,
                [RightFormElementsEnum.COST_CENTER_1_ABILITY_MANAGE]: false,
            },
        },
        actionRight: {
            title: "label:rights.costcenter1_manage.title",
            tooltip: "label:rights.costcenter1_manage.tooltip",
            name: RightFormElementsEnum.COST_CENTER_1_ABILITY_MANAGE,
            onEnabled: {
                [RoleConfigFormElementsEnum.ALL_NO_EDIT]: false,
                [RightFormElementsEnum.COST_CENTER_1_ACCESS]: true,
                [RightFormElementsEnum.COST_CENTER_1_ABILITY_OWNER]: true,
            },
            onDisabled: {},
        },
    },
    {
        actionRight: {
            title: "label:rights.costcenter1_approval.title",
            tooltip: "label:rights.costcenter1_approval.tooltip",
            name: RightFormElementsEnum.COST_CENTER_1_ABILITY_OWNER,
            onEnabled: {
                [RoleConfigFormElementsEnum.ALL_NO_EDIT]: false,
            },
            onDisabled: {},
        },
    },
    // VENDORS
    {
        resource: "label:rights.resource.vendors",
        readRight: {
            tooltip: "label:rights.vendors_viewingright1.tooltip",
            name: RightFormElementsEnum.VENDOR_ACCESS,
            onEnabled: {},
            onDisabled: {
                [RoleConfigFormElementsEnum.ALL_VIEW]: false,
                [RightFormElementsEnum.VENDOR_ABILITY_MANAGE]: false,
                [RightFormElementsEnum.VENDOR_PAYMENT_DATA_ACCESS]: false,
                [RightFormElementsEnum.VENDOR_PAYMENT_DATA_ABILITY_EDIT]: false,
                [RightFormElementsEnum.VENDOR_CONTRACT_ACCESS]: false,
                [RightFormElementsEnum.VENDOR_CONTRACT_ABILITY_EDIT]: false,
            },
        },
        actionRight: {
            title: "label:rights.vendors_manage.title",
            tooltip: "label:rights.vendors_manage.tooltip",
            name: RightFormElementsEnum.VENDOR_ABILITY_MANAGE,
            onEnabled: {
                [RightFormElementsEnum.VENDOR_ACCESS]: true,
            },
            onDisabled: {},
        },
    },
    {
        readRight: {
            tooltip: "label:rights.vendors_viewingright2.tooltip",
            name: RightFormElementsEnum.VENDOR_PAYMENT_DATA_ACCESS,
            onEnabled: {
                [RightFormElementsEnum.VENDOR_ACCESS]: true,
            },
            onDisabled: {
                [RoleConfigFormElementsEnum.ALL_VIEW]: false,
                [RightFormElementsEnum.VENDOR_ABILITY_MANAGE]: false,
                [RightFormElementsEnum.VENDOR_PAYMENT_DATA_ABILITY_EDIT]: false,
            },
        },
        actionRight: {
            title: "label:rights.vendors_paymentdata.title",
            tooltip: "label:rights.vendors_paymentdata.tooltip",
            name: RightFormElementsEnum.VENDOR_PAYMENT_DATA_ABILITY_EDIT,
            onEnabled: {
                [RoleConfigFormElementsEnum.ALL_NO_EDIT]: false,
                [RightFormElementsEnum.VENDOR_ACCESS]: true,
                [RightFormElementsEnum.VENDOR_ABILITY_MANAGE]: true,
                [RightFormElementsEnum.VENDOR_PAYMENT_DATA_ACCESS]: true,
            },
            onDisabled: {},
        },
    },
    {
        readRight: {
            tooltip: "label:rights.vendors_viewingright3.tooltip",
            name: RightFormElementsEnum.VENDOR_CONTRACT_ACCESS,
            onEnabled: {
                [RightFormElementsEnum.VENDOR_ACCESS]: true,
            },
            onDisabled: {
                [RoleConfigFormElementsEnum.ALL_VIEW]: false,
                [RightFormElementsEnum.VENDOR_ABILITY_MANAGE]: false,
                [RightFormElementsEnum.VENDOR_CONTRACT_ABILITY_EDIT]: false,
            },
        },
        actionRight: {
            title: "label:rights.vendors_contracts.title",
            tooltip: "label:rights.vendors_contracts.tooltip",
            name: RightFormElementsEnum.VENDOR_CONTRACT_ABILITY_EDIT,
            onEnabled: {
                [RoleConfigFormElementsEnum.ALL_NO_EDIT]: false,
                [RightFormElementsEnum.VENDOR_ACCESS]: true,
                [RightFormElementsEnum.VENDOR_ABILITY_MANAGE]: true,
                [RightFormElementsEnum.VENDOR_CONTRACT_ACCESS]: true,
            },
            onDisabled: {},
        },
    },
    // WORKFLOWS
    {
        resource: "label:rights.resource.workflows",
        readRight: {
            tooltip: "label:rights.workflows_viewingright1.tooltip",
            name: RightFormElementsEnum.WORKFLOW_ACCESS,
            onEnabled: {},
            onDisabled: {
                [RoleConfigFormElementsEnum.ALL_VIEW]: false,
                [RightFormElementsEnum.WORKFLOW_ABILITY_MANAGE]: false,
            },
        },
        actionRight: {
            title: "label:rights.workflows_manage.title",
            tooltip: "label:rights.workflows_manage.tooltip",
            name: RightFormElementsEnum.WORKFLOW_ABILITY_MANAGE,
            onEnabled: {
                [RoleConfigFormElementsEnum.ALL_NO_EDIT]: false,
                [RightFormElementsEnum.WORKFLOW_ACCESS]: true,
            },
            onDisabled: {},
        },
    },
    {
        actionRight: {
            title: "label:rights.workflows_overwrite.title",
            tooltip: "label:rights.workflows_overwrite.tooltip",
            name: RightFormElementsEnum.WORKFLOW_ABILITY_ADJUST,
            onEnabled: { [RoleConfigFormElementsEnum.ALL_NO_EDIT]: false },
            onDisabled: {},
        },
    },
    // LIQUIDITY
    {
        resource: "label:rights.resource.liquidity",
        readRight: {
            tooltip: "label:rights.liquidity_viewingright1.tooltip",
            name: RightFormElementsEnum.LIQUIDITY_ACCESS,
            onEnabled: {
                [RightFormElementsEnum.BANK_ACCOUNT_ACCESS]: true,
            },
            onDisabled: {
                [RoleConfigFormElementsEnum.ALL_VIEW]: false,
                [RightFormElementsEnum.LIQUIDITY_ABILITY_MANAGE]: false,
            },
        },
        actionRight: {
            title: "label:rights.liquidity_manage.title",
            tooltip: "label:rights.liquidity_manage.tooltip",
            name: RightFormElementsEnum.LIQUIDITY_ABILITY_MANAGE,
            onEnabled: {
                [RoleConfigFormElementsEnum.ALL_NO_EDIT]: false,
                [RightFormElementsEnum.LIQUIDITY_ACCESS]: true,
                [RightFormElementsEnum.BANK_ACCOUNT_ACCESS]: true,
            },
            onDisabled: {},
        },
    },
    // REPORTING
    {
        resource: "label:rights.resource.reporting",
        readRight: {
            tooltip: "label:rights.reporting_viewingright1.tooltip",
            name: RightFormElementsEnum.REPORTING_ACCESS,
            onEnabled: {},
            onDisabled: {
                [RoleConfigFormElementsEnum.ALL_VIEW]: false,
            },
        },
    },
    // COMPANY SETTINGS INCL BANK ACCOUNT
    {
        resource: "label:rights.resource.settings",
        readRight: {
            tooltip: "label:rights.settings_viewingright1.tooltip",
            name: RightFormElementsEnum.COMPANY_FEATURE_SETTINGS_ACCESS,
            onEnabled: {},
            onDisabled: {
                [RoleConfigFormElementsEnum.ALL_VIEW]: false,
                [RightFormElementsEnum.COMPANY_FEATURE_SETTINGS_ABILITY_MANAGE]: false,
            },
        },
        actionRight: {
            title: "label:rights.feature_settings_manage.title",
            tooltip: "label:rights.feature_settings_manage.tooltip",
            name: RightFormElementsEnum.COMPANY_FEATURE_SETTINGS_ABILITY_MANAGE,
            onEnabled: {
                [RoleConfigFormElementsEnum.ALL_NO_EDIT]: false,
                [RightFormElementsEnum.COMPANY_FEATURE_SETTINGS_ACCESS]: true,
            },
            onDisabled: {},
        },
    },
    {
        readRight: {
            tooltip: "label:rights.settings_viewingright2.tooltip",
            name: RightFormElementsEnum.COMPANY_ACCOUNTING_SETTINGS_ACCESS,
            onEnabled: {},
            onDisabled: {
                [RoleConfigFormElementsEnum.ALL_VIEW]: false,
                [RightFormElementsEnum.COMPANY_ACCOUNTING_SETTINGS_ABILITY_MANAGE]: false,
            },
        },
        actionRight: {
            title: "label:rights.accounting_settings_manage.title",
            tooltip: "label:rights.accounting_settings_manage.tooltip",
            name: RightFormElementsEnum.COMPANY_ACCOUNTING_SETTINGS_ABILITY_MANAGE,
            onEnabled: {
                [RoleConfigFormElementsEnum.ALL_NO_EDIT]: false,
                [RightFormElementsEnum.COMPANY_ACCOUNTING_SETTINGS_ACCESS]: true,
            },
            onDisabled: {},
        },
    },
    {
        readRight: {
            tooltip: "label:rights.settings_viewingright3.tooltip",
            name: RightFormElementsEnum.BANK_ACCOUNT_ACCESS,
            onEnabled: {},
            onDisabled: {
                [RoleConfigFormElementsEnum.ALL_VIEW]: false,
                [RightFormElementsEnum.BANK_ACCOUNT_ABILITY_WRITE]: false,
                [RightFormElementsEnum.LIQUIDITY_ACCESS]: false,
                [RightFormElementsEnum.LIQUIDITY_ABILITY_MANAGE]: false,
            },
        },
        actionRight: {
            title: "label:rights.settings_bankaccounts.title",
            tooltip: "label:rights.settings_bankaccounts.tooltip",
            name: RightFormElementsEnum.BANK_ACCOUNT_ABILITY_WRITE,
            onEnabled: {
                [RoleConfigFormElementsEnum.ALL_NO_EDIT]: false,
                [RightFormElementsEnum.BANK_ACCOUNT_ACCESS]: true,
            },
            onDisabled: {},
        },
    },
]
