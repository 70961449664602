import ExclamationCircleOutlined from "@ant-design/icons/lib/icons/ExclamationCircleOutlined"
import { Button, Checkbox, DatePicker, Tooltip } from "antd"
import axios from "axios"
import moment, { Moment } from "moment"
import React from "react"
import { AlertTriangle } from "react-feather"

import { NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import TenantService from "Shared/services/tenant.service"
import { getTooltipPopupContainer } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

const SimulatorCronTab = () => {
    const [fakeDate, setFakeDate] = useStateIfMounted<Moment | null>(moment())
    const [shouldRegenerateAll, setShouldRegenerateAll] = useStateIfMounted<boolean>(false)
    const actions = [
        {
            key: "daily",
            title: "Daily",
            tooltip: "expired cards, send wallet balance to admins, monthly missing invoices, bundle email",
            endpoint: "/cronjobs/daily/simulate",
        },
        {
            key: "weekly",
            title: "Weekly",
            tooltip: "sendWeeklyReminderForMissingInvoices, send weekly bundle email",
            endpoint: "/cronjobs/weekly/simulate",
        },
        {
            key: "hourly",
            title: "hourly",
            tooltip: "pending payment, pending banking transactions, check bank connection download status",
            endpoint: "/cronjobs/every15mins/simulate",
        },
        {
            key: "midnight",
            title: "midnight",
            tooltip:
                "recurring expenses, renewing subscriptions, activity cleanup, exchange rate, stop stuck payment process, stop stuck export process, correct balances, auto recharge cards, missing payment rates, expired tokens, cleanup pending banking transactions, Personio sync, new fiscal year calculation.",
            endpoint: "/cronjobs/midnight/simulate",
        },
        {
            key: "firstofmonth",
            title: "firstofmonth",
            tooltip: "generateAccountStatements per company",
            endpoint: "/cronjobs/firstofmonth/simulate",
        },
    ]

    const onClick = (endpoint: string, title: string) => {
        TenantService.getTenant()
            .then((res) =>
                axios.post(endpoint, {
                    tenantId: res.tenantId,
                    fakeDate: fakeDate ? new Date(Date.UTC(fakeDate?.year(), fakeDate?.month(), fakeDate?.date())) : undefined,
                    shouldRegenerateAll,
                }),
            )
            .then(() => {
                NotificationService.send(NotificationTypeEnum.INFO, "Cronjob started!", `${title}`)
            })
            .catch((error) => {
                if (error.response.status === 423) {
                    NotificationService.send(NotificationTypeEnum.WARNING, "Previous cronjob still running!", `${title}`)
                } else {
                    NotificationService.showErrorNotificationBasedOnResponseError(error, "Failed to execute cronjob!", "This is most likely a bug!")
                }
            })
    }

    return (
        <div className="container">
            <div className="mb-20 flex flex-row">
                <span className="mr-5 text-text">Select a fake date for testing purposes</span>
                <Tooltip
                    getPopupContainer={getTooltipPopupContainer}
                    title="This only works for - recurring expenses for subscriptions creation - account statements generation - fiscal year calculation."
                >
                    <AlertTriangle fill="#FBAC3B" size={18} stroke="#FFFFFF" className="mt-2" />
                </Tooltip>
                <DatePicker className="ml-20" onChange={setFakeDate} defaultValue={moment(fakeDate)} disabledDate={(d) => d.isBefore(moment())} />
            </div>

            {actions.map(({ key, title, tooltip, endpoint }) => (
                <div key={key} className="flex items-center justify-between pt-6">
                    <div className="flex items-center justify-center">
                        <h3>{title}</h3>
                        <Tooltip getPopupContainer={getTooltipPopupContainer} title={tooltip} className="pl-5 pb-12">
                            <ExclamationCircleOutlined />
                        </Tooltip>
                    </div>
                    <Button onClick={() => onClick(endpoint, title)}>Execute</Button>
                </div>
            ))}
            <div className="mt-20 flex flex-row">
                <Checkbox value={shouldRegenerateAll} onChange={(e) => setShouldRegenerateAll(e.target.checked)}>
                    Regenerate all statements (Only relevant for monthly, takes a lot of time!)
                </Checkbox>
            </div>
        </div>
    )
}

export default SimulatorCronTab
