import { ExpenseSplit } from "@finway-group/shared/lib/models"
import { Rule } from "antd/lib/form"
import i18n from "i18next"

import { isNotSet } from "Shared/utils/helper.utils"

export const validateSplitPercentage = (value: string) => {
    if (isNaN(value as any) || isNotSet(value)) throw new Error(i18n.t("validation:number"))
    const percentage = parseFloat(value)
    if (percentage >= 100) throw new Error(i18n.t("validation:below.number", { max: 100 }))
    if (percentage <= 0) throw new Error(i18n.t("validation:greater.number", { min: 0 }))
}

const validateSplitPercentagePromise = (value: string) => {
    try {
        validateSplitPercentage(value)
    } catch (err) {
        return Promise.reject(err.message)
    }
    return Promise.resolve()
}

export const SplitRules = (splits?: Array<ExpenseSplit>): { [key: string]: Array<Rule> } => ({
    splitPercentage: [
        {
            validator: (_rule: any, value: string) => validateSplitPercentagePromise(value),
        },
    ],
    costCenter: [{ type: "string", required: true, message: i18n.t("validation:required"), validateTrigger: "blur" }],
})
