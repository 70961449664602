import { FeatureFlagsInterface } from "@finway-group/shared/lib/models"
import { ActionCreator, Dispatch } from "redux"

import { ThunkResult } from "Shared/store"

import { FeatureFlagsActionTypes, SetFeatureFlagsAction } from "./featureFlagsTypes"

export const setFeatureFlags: ActionCreator<ThunkResult<FeatureFlagsInterface>> = (featureFlags: FeatureFlagsInterface) => async (dispatch: Dispatch) => {
    const setFeatureFlagsAction: SetFeatureFlagsAction = {
        type: FeatureFlagsActionTypes.SET_FEATURE_FLAGS,
        featureFlags,
    }
    dispatch(setFeatureFlagsAction)

    return featureFlags
}
