import {
    ApprovalProcess,
    ApprovalScopeEnum,
    CommentItem,
    DiscountRule,
    DocumentTypeEnum,
    Expense,
    ExpenseKindEnum,
    Hospitality,
    Mileage,
    OperationTypeEnum,
    PerDiem,
    PerDiemDestination,
    TripFolder,
} from "@finway-group/shared/lib/models"
import { ExpenseDiscountInterface } from "@finway-group/shared/lib/models/expense/expenseDiscount.interface"
import axios from "axios"

import { getTableQueryParams } from "Shared/hooks/table.hooks"
import { TablesEnum } from "Shared/store/reducers/tableConfigReducer"

const MODEL = "expenses"

const ExpenseHttpService = {
    getAdjacentExpenses: async (expenseId: string, table: TablesEnum) => {
        const queryParams = await getTableQueryParams(table)
        const { data } = await axios.get(`${MODEL}/${expenseId}/adjacent?${queryParams.filterQueryString}${queryParams.searchQueryString}${queryParams.sortQueryString}`)
        return data
    },
    buildApprovalProcesses: async (approvalProcessScopes: Array<ApprovalScopeEnum>, expenseData: any): Promise<Array<ApprovalProcess>> => {
        const { data } = await axios.post(`${MODEL}/approval-processes`, { approvalProcessScopes, expenseData })
        return data
    },
    fetchPerDiemDestinationRates: async (years: Array<number>) => {
        const { data } = await axios.get(`/${MODEL}/perdiem/rates?years=${years.join(",")}`)
        return data as Array<PerDiemDestination>
    },
    getNextExpenseNumber: async () => {
        const { data } = await axios.get<{ expenseNumberSequence: number | undefined }>(`/${MODEL}/sequence/next-expense-number`)
        return data.expenseNumberSequence
    },
    exportAllSubscriptions: async (): Promise<void> => {
        await axios.post(`/${MODEL}/subscription/export`)
    },
    checkDatevOnlineJobStatusForExpense: async (id: string) => {
        const { data } = await axios.get(`/${MODEL}/${id}/duo-job`)
        return data
    },
    initializeApprovalProcess: async (expenseId: string, approvalProcessScope: ApprovalScopeEnum) => {
        const { data } = await axios.patch(`/${MODEL}/${expenseId}/approval-processes/initialize`, { approvalProcessScope })
        return new Expense(data)
    },
    createReminder: async (expenseId: string, reminderBody: { date: Date }) => {
        const { data } = await axios.post(`${MODEL}/${expenseId}/reminder`, reminderBody)
        return new Expense(data)
    },
    updateReminder: async (expenseId: string, reminderBody: { date: Date }) => {
        const { data } = await axios.put(`${MODEL}/${expenseId}/reminder`, reminderBody)
        return new Expense(data)
    },
    deleteReminder: async (expenseId: string) => {
        const { data } = await axios.delete(`${MODEL}/${expenseId}/reminder`)
        return new Expense(data)
    },
    syncExpenseCreditor: async (expenseId: string) => {
        const { data } = await axios.post(`${MODEL}/${expenseId}/sync-creditor`)
        return data
    },
    checkForExpenseGobdCompliance: async (expenseId: string, expense: Expense) => {
        const { data } = await axios.post<{ isCompliant: boolean }>(`/expenses/${expenseId}/check-gobd-compliance`, expense)
        return data
    },
    attachTagToExpense: async (tagId: string, expenseId: string) => {
        const { data } = await axios.post(`/${MODEL}/${expenseId}/expense-tags`, { tagId })
        return data
    },
    detachTagFromExpense: async (tagId: string, expenseId: string) => {
        const { data } = await axios.delete(`/${MODEL}/${expenseId}/expense-tags/${tagId}`)
        return data
    },
    submitDiscounts: async (expenseId: string, discounts: Array<DiscountRule>) => {
        const { data } = await axios.put<Array<ExpenseDiscountInterface & { _id: string }>>(`/${MODEL}/${expenseId}/discounts/`, discounts)
        return data
    },
    deleteDiscounts: async (expenseId: string): Promise<void> => {
        await axios.delete(`/${MODEL}/${expenseId}/discounts/`)
    },
    createExpense: async (expense: Expense | PerDiem | Mileage | Hospitality) => {
        const { data } = await axios.post(`/expenses`, expense)
        switch (data.kind) {
            case ExpenseKindEnum.HOSPITALITY:
                return new Hospitality(data)
            case ExpenseKindEnum.MILEAGE:
                return new Mileage(data)
            case ExpenseKindEnum.PER_DIEM:
                return new PerDiem(data)
            default:
                return new Expense(data)
        }
    },
    checkForDuplicate: async (duplicateData: { id: string; invoiceNumber?: string; checkInvoice?: boolean; checkExpense?: boolean; ignoreIds?: Array<string> }) => {
        const { data } = await axios.post(`${MODEL}/check-duplicate`, duplicateData)
        return data
    },
    createPreApprovedSubscription: async (expense: Expense) => {
        const { data } = await axios.post(`/expenses/subscription`, expense)
        return new Expense(data)
    },
    createRecurringExpense: async (expense: Expense) => {
        const { data } = await axios.post(`/expenses/${expense.parentSubscription}/recurring`, expense)
        return new Expense(data)
    },
    fetchExpenseCount: async (query = "") => {
        const { data } = await axios.get(`${MODEL}/reports/counts?${query}`)
        return data
    },
    fetchExpenses: async (query = "", sortingCriteria = "", page = 1, limit = 20) => {
        const {
            data: { docs, totalPages, totalDocs },
        } = await axios.get(`${MODEL}/?page=${page}&limit=${limit}${query}${sortingCriteria}`)
        return {
            expenses: docs.map((item: any) => new Expense(item)).filter((expense: Expense) => !expense.deleted),
            totalPages,
            totalDocs,
        }
    },
    fetchAllExpenses: async (queryParams: any, source: any, timeout?: number) => {
        const { data } = await axios.get(
            `/${MODEL}?page=${queryParams.page}&limit=${queryParams.limit}${queryParams.filterQueryString}${queryParams.searchQueryString}${queryParams.sortQueryString}`,
            {
                cancelToken: source.token,
                timeout,
            },
        )
        return data
    },
    fetchOneExpense: async (expenseId: string) => {
        const { data } = await axios.get(`${MODEL}/${expenseId}`)
        return new Expense(data)
    },
    fetchExpenseWriteAccess: async (expenseId: string) => {
        const { data } = await axios.get(`${MODEL}/${expenseId}/writeAccess`)
        return data
    },
    updateExpense: async (id: string, updateExpense: Expense) => {
        const { data } = await axios.put(`/${MODEL}/${id}`, updateExpense)
        return new Expense(data)
    },
    approveExpense: async (id: string, approvalProcessScope: ApprovalScopeEnum) => {
        const { data } = await axios.patch(`/${MODEL}/${id}/approve`, { approvalProcessScope })
        return new Expense(data)
    },
    submitFolder: async (id: string) => {
        const { data } = await axios.patch(`/${MODEL}/${id}/folder-submit`)
        return new TripFolder(data)
    },
    approveFolder: async (folderId: string, approvalProcessScope: ApprovalScopeEnum) => {
        const { data } = await axios.patch(`/${MODEL}/${folderId}/folder-approve`, { approvalProcessScope })
        return new TripFolder(data)
    },
    reviewExpense: async (id: string) => {
        const { data } = await axios.patch(`/${MODEL}/${id}/review`)
        return new Expense(data)
    },
    reviewMultipleExpenses: async (ids: Array<string>) => {
        const { data } = await axios.patch(`/${MODEL}/review`, { ids })
        return data
    },
    reviewFolder: async (folderId: string) => {
        const { data } = await axios.patch(`/${MODEL}/${folderId}/folder-review`)
        return data
    },
    rejectExpense: async (id: string, rejectionNote: string, approvalProcessScope?: ApprovalScopeEnum) => {
        const { data } = await axios.patch(`/${MODEL}/${id}/reject`, { rejectionNote, approvalProcessScope })
        return new Expense(data)
    },
    archiveExpense: async (id: string) => {
        const { data } = await axios.patch(`/${MODEL}/${id}/archive`)
        return new Expense(data)
    },
    unarchiveExpense: async (id: string) => {
        const { data } = await axios.patch(`/${MODEL}/${id}/unarchive`)
        return new Expense(data)
    },
    archiveMultipleExpenses: async (ids: Array<string>) => {
        const { data } = await axios.patch(`/${MODEL}/archive`, { ids })
        return data
    },
    unarchiveMultipleExpenses: async (ids: Array<string>) => {
        const { data } = await axios.patch(`/${MODEL}/unarchive`, { ids })
        return data
    },
    resetExpense: async (id: string) => {
        const { data } = await axios.patch(`/${MODEL}/${id}/reset`)
        return new Expense(data)
    },
    markExpenseAsPaid: async (id: string) => {
        const { data } = await axios.patch(`/${MODEL}/${id}/mark-as-paid`)
        return new Expense(data)
    },
    markMultipleExpensesAsPaid: async (ids: Array<string>) => {
        const { data } = await axios.patch(`/${MODEL}/mark-as-paid`, { ids })
        return data
    },
    markExpenseAsDone: async (id: string) => {
        const { data } = await axios.patch(`/${MODEL}/${id}/mark-as-done`)
        return new Expense(data)
    },
    markMultipleExpensesAsDoneByExpenseIds: async (ids: Array<string>) => {
        const { data } = await axios.patch(`/${MODEL}/mark-as-done`, { ids })
        return data
    },
    markAllExpensesAsDone: async (filter: string) => {
        const { data } = await axios.patch(`/${MODEL}/mark-all-as-done?${filter}`)
        return data
    },
    updateDocuments: async (id: string, kind: DocumentTypeEnum, documentData: any, operation: OperationTypeEnum) => {
        const { data } = await axios.put(`/${MODEL}/${id}/documents`, { kind, documentData, operation })
        return new Expense(data)
    },
    deleteExpense: async (id: string) => {
        const { data } = await axios.delete(`/${MODEL}/${id}`)
        return new Expense(data)
    },
    detachOneExpense: async (id: string) => {
        const { data } = await axios.patch(`/${MODEL}/${id}/detach-from-folder`)
        return new Expense(data)
    },
    detachFolderContents: async (folderId: string) => {
        const { data } = await axios.patch(`/${MODEL}/${folderId}/folder-detach-all`)
        return new Expense(data)
    },
    attachExpenses: async (folderId: string, subExpenseIds: Array<string>) => {
        const { data } = await axios.patch(`/${MODEL}/${folderId}/folder-attach-expenses`, { ids: subExpenseIds })
        return new Expense(data)
    },
    sendReminder: async (expenseId: string) => axios.get(`${MODEL}/${expenseId}/remind`),
    pauseSubscription: async (expenseId: string) => {
        const { data } = await axios.put(`${MODEL}/${expenseId}/pause`)
        return new Expense(data)
    },
    unpauseSubscription: async (expenseId: string) => {
        const { data } = await axios.put(`${MODEL}/${expenseId}/unpause`)
        return new Expense(data)
    },
    createComment: async (expenseId: string, comment: CommentItem) => {
        const { data } = await axios.post(`${MODEL}/${expenseId}/comments`, comment)
        return new Expense(data)
    },
    deleteComment: async (expenseId: string, comment: CommentItem) => {
        const { data } = await axios.delete(`${MODEL}/${expenseId}/comments/${comment.id}`)
        return new Expense(data)
    },
    updateComment: async (expenseId: string, comment: CommentItem) => {
        const { data } = await axios.patch(`${MODEL}/${expenseId}/comments/${comment.id}`, comment)
        return new Expense(data)
    },
    fetchExpenseCard: async (expenseId: string) => {
        const { data } = await axios.get(`/${MODEL}/${expenseId}/card/`)
        return data
    },
}

export default ExpenseHttpService
