import React from "react"
import { Link } from "react-router-dom"

import { RoutePathEnum } from "Shared/router/RoutePath.enum"

const Error: React.FC<{}> = (props) => (
    <div className={"min-w-screen min-h-screen"}>
        <header className={"bg-primary w-full fixed z-50 top-0"}>
            <div className="container">
                <div className="flex justify-between items-center h-56 py-10">
                    <Link to={RoutePathEnum.EXPENSES}>
                        <img src={"./images/logo.svg"} className="w-120 sm:w-140" alt={"FINWAY"} />
                    </Link>
                </div>
            </div>
        </header>
        <main className="">
            <div className="container">
                <div className="min-h-screen flex flex-col justify-center items-center">
                    <div className="mb-20 sm:mb-0">
                        <img src={"./images/error.svg"} className="w-auto" alt={"Error"} />
                    </div>
                    <div className="mt-10 p-5">{props.children}</div>
                </div>
            </div>
        </main>
    </div>
)

export default Error
