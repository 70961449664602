import { Rule } from "antd/lib/form"
import i18n from "i18next"
import { isValidPhoneNumber } from "react-phone-number-input"

import validateEmail from "Shared/validators/email.validator"

export const CorporateRules = (): { [key: string]: Array<Rule> } => ({
    required: [{ required: true, message: i18n.t("validation:required") }],
    companyName: [
        { required: true, message: i18n.t("validation:required") },
        {
            validator: (_rule: any, value: string) => {
                if (value && value.length > 100) {
                    return Promise.reject(new Error(i18n.t("validation:below.string", { max: 100 })))
                }
                return Promise.resolve()
            },
        },
    ],
    companyRegistrationNumber: [
        { required: true, message: i18n.t("validation:required") },
        {
            validator: (_rule: any, value: string) => {
                if (value && value.length > 20) {
                    return Promise.reject(new Error(i18n.t("validation:below.string", { max: 20 })))
                }
                return Promise.resolve()
            },
        },
    ],
    phoneNumber: [
        { required: true, message: i18n.t("validation:required") },
        {
            validator: (_rule: any, value: string) => {
                if (!isValidPhoneNumber(value) && value) {
                    return Promise.reject(new Error(i18n.t("validation:phone")))
                }
                return Promise.resolve()
            },
        },
    ],
    email: [
        { required: true, message: i18n.t("validation:required") },
        {
            validator: (_rule: any, value: string) => {
                if (!validateEmail(value) && value) {
                    return Promise.reject(new Error(i18n.t("validation:email")))
                }
                if (value && value.length > 255) {
                    return Promise.reject(new Error(i18n.t("validation:below.string", { max: 255 })))
                }
                return Promise.resolve()
            },
        },
    ],
    countryCode: [
        { required: true, message: i18n.t("validation:required") },
        {
            validator: (_rule: any, value: string) => {
                if (value && value !== "") {
                    if (value.length !== 2) return Promise.reject(new Error(i18n.t("validation:exact.string", { num: 2 })))
                    if (!/[A-Z]{2}/.test(value)) return Promise.reject(new Error(i18n.t("validation:only_uppercase")))
                }
                return Promise.resolve()
            },
        },
    ],
    city: [
        { required: true, message: i18n.t("validation:required") },
        {
            validator: (_rule: any, value: string) => {
                if (value && value !== "") {
                    if (value.length > 20) return Promise.reject(new Error(i18n.t("validation:max.string", { max: 20 })))
                }
                return Promise.resolve()
            },
        },
    ],
    zip: [
        { required: true, message: i18n.t("validation:required") },
        {
            validator: (_rule: any, value: string) => {
                if (value && value !== "") {
                    if (value.length > 10) return Promise.reject(new Error(i18n.t("validation:max.string", { max: 10 })))
                    if (!/^[A-Za-z0-9 -]*$/.test(value)) return Promise.reject(new Error(i18n.t("validation:extended_alphanumeric")))
                }
                return Promise.resolve()
            },
        },
    ],
    name: [
        { required: true, message: i18n.t("validation:required") },
        {
            validator: (_rule: any, value: string) => {
                if (value && value.length > 20) {
                    return Promise.reject(new Error(i18n.t("validation:below.string", { max: 20 })))
                }
                return Promise.resolve()
            },
        },
    ],
    terms: [
        {
            required: true,
            type: "boolean",
            transform: (value) => value || undefined,
            message: i18n.t("validation:accept_terms"),
        },
    ],
})

export default CorporateRules
