import { WarningFilled } from "@ant-design/icons"
import { DiscountRule, Expense, ExpenseAmount, ExpenseStatusEnum } from "@finway-group/shared/lib/models"
import { calculateDiscountInformation, isNumeric } from "@finway-group/shared/lib/utils"
import { isDiscountExpired } from "@finway-group/shared/lib/utils/expense.discount.utils"
import { MAX_DISCOUNT_DURATION_DAYS, MAX_DISCOUNT_PERCENTAGE, MIN_DISCOUNT_DURATION_DAYS, isDiscountRuleValid } from "@finway-group/shared/lib/utils/validators"
import { Button, Col, Form, Row, Tooltip } from "antd"
import { FormListFieldData } from "antd/lib/form/FormList"
import moment from "moment"
import React, { useEffect } from "react"
import { X as CancelIcon, Check as CheckIcon, Edit as EditIcon, Trash as TrashIcon } from "react-feather"
import { useTranslation } from "react-i18next"
import { NumericFormat } from "react-number-format"
import { useDispatch } from "react-redux"

import { ConditionalWrapper } from "Components/conditionalWrapper"
import { DiscountFormRulesStrict } from "Components/forms/rules/discountRule.rules"
import PriceLabel from "Components/priceLabel"
import { DE_DATE_FORMAT } from "Shared/config/consts"
import { useExpenseDetailsContext } from "Shared/context/expenseDetails.context"
import { useVendorById } from "Shared/hooks/vendor.hooks"
import { AuthzService, NotificationService } from "Shared/services"
import DialogService from "Shared/services/dialog.service"
import { deleteDiscounts, submitDiscounts } from "Shared/store/actions/expense/expenseActions"
import { getClassNameForDiscountHighlight } from "Shared/utils/expense.discount.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"
import { DiscountForForm } from "Shared/utils/vendorRule.form.utils"

import { useMainDetailsSectionContext } from "../mainDetailsSection.context"

const EXPENSE_DISCOUNT_DISPLAY_ORDER: Array<keyof ExpenseAmount> = ["tax", "net", "gross"]

export const ExpenseDiscountRow = () => {
    const { isShowingDiscountForm, activeExpense, setIsShowingDiscountForm } = useExpenseDetailsContext()
    const { discountDisplay, setDiscountDisplay } = useMainDetailsSectionContext()
    const { t } = useTranslation()
    const [formInstance] = Form.useForm<{ discounts: Array<DiscountForForm> }>()
    const vendor = useVendorById(activeExpense?.vendor?._id)
    const dispatch = useDispatch()

    const [shouldRecalculateDiscount, setShouldRecalculateDiscount] = useStateIfMounted(false)
    const [isLoading, setIsLoading] = useStateIfMounted(false)

    const hasRightToPay = AuthzService.canLoggedInUserPayByBank()

    const shouldDisplayDiscountWarning = isDiscountExpired(activeExpense) && !activeExpense.isSuccessfullyProcessed()

    // TODO - This shouln't be really necessary when we upgrade our Antd at least to 4.22 so it has the useWatch hook - to watch the form state directly.
    // useEffect is used instead of calculating this with onChange because we need the form internal state to be updated before calculating the discount.
    // Not doing so might require another "clone" state of the form to be used to calculate the discount
    useEffect(() => {
        if (!shouldRecalculateDiscount) return

        setShouldRecalculateDiscount(false)

        const discounts = formInstance.getFieldValue("discounts") as Array<DiscountForForm>
        const discountPercentageRaw = discounts?.[0]?.percentage
        const discountPercent = discountPercentageRaw && isNumeric(discountPercentageRaw) ? Number(discountPercentageRaw) : undefined

        const discountPeriodRaw = discounts?.[0]?.period
        const discountPeriod = discountPeriodRaw && isNumeric(discountPeriodRaw) ? Number(discountPeriodRaw) : undefined

        if (!isDiscountRuleValid({ percentage: discountPercent, period: discountPeriod })) {
            setDiscountDisplay(undefined)
            return
        }

        const amountInfo = {
            net: activeExpense.totalNetPrice,
            tax: activeExpense.totalTaxPrice,
            gross: activeExpense.totalGrossPrice,
        }

        setDiscountDisplay(calculateDiscountInformation(amountInfo, discountPercent!))
    }, [shouldRecalculateDiscount])

    // useEffect to initialize form data and discount display
    useEffect(() => {
        setFormInstanceFieldsValueFromActiveExpense()
        setShouldRecalculateDiscount(true)
    }, [activeExpense.id, new Date(activeExpense.updatedAt).toString()])

    const setFormInstanceFieldsValueFromActiveExpense = () => {
        const discounts: Array<DiscountForForm> =
            activeExpense.discounts?.map((discount: DiscountRule) => ({
                percentage: discount.percentage,
                period: discount.period,
            })) ?? []

        if (discounts.length === 0) {
            if (vendor?.rule?.discounts?.length) discounts.push(...vendor.rule.discounts)
            else discounts.push({ percentage: undefined, period: undefined })
        }

        formInstance.setFieldsValue({ discounts })
    }

    const submitForm = async (value: { discounts: Array<DiscountForForm> }) => {
        setIsLoading(true)
        try {
            const discounts = value.discounts.map((discount) => ({
                percentage: Number(discount.percentage),
                period: Number(discount.period),
            }))

            const isDueDateExpired = (expense: Pick<Expense, "invoiceDate">, discountPeriod: number) =>
                moment().isAfter(moment(expense.invoiceDate).add(discountPeriod, "days"), "day")

            if (discounts.every((discount) => isDueDateExpired(activeExpense, discount.period))) {
                const confirm = await DialogService.confirmSubmitExpiredDiscount()
                if (!confirm) return
            }

            await submitDiscounts(activeExpense.id, discounts)(dispatch)
            setIsShowingDiscountForm(false)
            setShouldRecalculateDiscount(true)
        } catch (error) {
            NotificationService.showErrorNotificationBasedOnResponseError(error, t("error:error"))
        } finally {
            setIsLoading(false)
        }
    }

    const deleteExpenseDiscounts = async () => {
        setIsLoading(true)
        try {
            await deleteDiscounts(activeExpense.id)(dispatch)
        } catch (error) {
            NotificationService.showErrorNotificationBasedOnResponseError(error, t("error:error"))
        } finally {
            setIsLoading(false)
        }
    }

    const handleCloseDiscountForm = () => {
        setIsShowingDiscountForm(false)
        setFormInstanceFieldsValueFromActiveExpense()
        setShouldRecalculateDiscount(true)
    }

    const AmountReductionSection = ({ amount }: { amount: ExpenseAmount }) => (
        <>
            {EXPENSE_DISCOUNT_DISPLAY_ORDER.map((key) => ({ key, value: amount[key] })).map(({ key, value }) => (
                <Col id={key} span={3} className="text-right">
                    <span className={getClassNameForDiscountHighlight(activeExpense)}>
                        <PriceLabel value={-value} currency={activeExpense.currency} />
                    </span>
                </Col>
            ))}
        </>
    )

    if (!isShowingDiscountForm) {
        const discount = activeExpense.isProcessed() ? activeExpense.getUsedOrActiveDiscount() : activeExpense.getActiveDiscount()

        // TODO: temporary fix for the discount percentage because the discount might be populated without the percentage
        if (!discount || !discount.percentage) return null

        const { reduction } = calculateDiscountInformation(
            {
                gross: activeExpense.totalGrossPrice,
                net: activeExpense.totalNetPrice,
                tax: activeExpense.totalTaxPrice,
            },

            discount.percentage,
        )

        return (
            <div className="border-b py-10">
                <Row className={`${getClassNameForDiscountHighlight(activeExpense)} my-20 full items-center`}>
                    <Col span={15} className="">
                        <Row align="middle" gutter={[8, 0]}>
                            <Col flex="none">
                                <ConditionalWrapper
                                    condition={shouldDisplayDiscountWarning}
                                    wrapper={(children: any) => (
                                        <Tooltip placement="bottom" title={t("tooltips:discount_expired")}>
                                            {children}
                                        </Tooltip>
                                    )}
                                >
                                    <div className="flex gap-10">
                                        {shouldDisplayDiscountWarning && <WarningFilled className="self-center" />}
                                        <p>
                                            <span>{t("label:expense_discount.discount_info_1")}</span>
                                            <span className="font-bold">
                                                {t("label:expense_discount.discount_info_2", {
                                                    percentage: discount.percentage,
                                                    dueDate: moment(discount.dueDate).format(DE_DATE_FORMAT),
                                                })}
                                            </span>
                                        </p>
                                    </div>
                                </ConditionalWrapper>
                            </Col>
                            {activeExpense.status === ExpenseStatusEnum.REVIEWED && hasRightToPay && (
                                <>
                                    <Col flex="none" className="text-gray-900">
                                        <EditIcon size={14} className="cursor-pointer" onClick={() => setIsShowingDiscountForm(true)} />
                                    </Col>
                                    <Col flex="none" className="text-gray-900">
                                        <TrashIcon size={14} className="cursor-pointer" onClick={deleteExpenseDiscounts} />
                                    </Col>
                                </>
                            )}
                        </Row>
                    </Col>
                    <AmountReductionSection amount={reduction} />
                </Row>
            </div>
        )
    }

    const formRow = (field: FormListFieldData, index: number) => {
        const rule = DiscountFormRulesStrict(formInstance, ["discounts", index])

        return (
            <>
                <div>
                    <Form.Item className="w-90" name={[field.name, "percentage"]} rules={rule.percentage}>
                        <NumericFormat decimalScale={2} className="ant-input-number-input" min={0} max={MAX_DISCOUNT_PERCENTAGE} />
                    </Form.Item>
                </div>
                <div className="mt-6">%</div>
                <div>
                    <Form.Item className="w-90" name={[field.name, "period"]} rules={rule.period}>
                        <NumericFormat className="ant-input-number-input" min={MIN_DISCOUNT_DURATION_DAYS} max={MAX_DISCOUNT_DURATION_DAYS} />
                    </Form.Item>
                </div>
                <div className="mt-6">{t("label:vendor_discount.days")}</div>
            </>
        )
    }

    return (
        <Row className="mx-12 border-b mt-20 px-10">
            <Col span={15}>
                <div className="flex gap-8">
                    <div className="mt-6">{t("label:vendor_discount.discount")}:</div>
                    <Form form={formInstance} className="flex gap-8" onChange={() => setShouldRecalculateDiscount(true)} onFinish={submitForm}>
                        <Form.List name={["discounts"]}>{(fields) => fields.map(formRow)}</Form.List>
                        <Button type="default" onClick={handleCloseDiscountForm} disabled={isLoading}>
                            <CancelIcon />
                        </Button>
                        <Button type="primary" onClick={() => formInstance.submit()} disabled={isLoading}>
                            <CheckIcon />
                        </Button>
                    </Form>
                </div>
            </Col>
            {discountDisplay && <AmountReductionSection amount={discountDisplay.reduction} />}
        </Row>
    )
}
