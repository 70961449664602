import { ErrorCodeEnum } from "@finway-group/shared/lib/models"
import { Button, Form, Modal, Steps } from "antd"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import TokenCodeForm from "Components/forms/tfauth/tokenCode.form"
import axiosInterceptor from "Shared/config/axios.interceptor"
import { useLoggedInEmployeeProfile } from "Shared/hooks/user.hooks"
import { CorporateService, NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { setIsWeavrAuthenticated, setShouldShowWeavrAuthModal } from "Shared/store/actions/auth/authActions"
import { syncEmployee } from "Shared/store/actions/employee/employeeActions"
import { ErrorInterface } from "Shared/utils/errorHandler.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

import WeavrPasscodeForm from "../forms/weavr/weavrPasscode.form"

enum WeavrAuthenticationStepsEnum {
    PASSCODE_STEP,
    VERIFICATION_CODE_STEP,
}

interface WeavrAuthModalInterface {
    isShowing: boolean
    onClosed: () => void
    onLoggedIn?: () => void
    enforceTfa?: boolean
}

const WeavrAuthModal: React.FC<WeavrAuthModalInterface> = ({ isShowing, onClosed, onLoggedIn, enforceTfa = false }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [passcodeFormInstance] = Form.useForm()
    const [isLoading, setIsLoading] = useStateIfMounted(false)
    const [currentStep, setCurrentStep] = useStateIfMounted<WeavrAuthenticationStepsEnum>(WeavrAuthenticationStepsEnum.PASSCODE_STEP)
    const [accessToken, setAccessToken] = useStateIfMounted<string>("")
    const user = useLoggedInEmployeeProfile()
    const shouldUseTfa = enforceTfa || user?.activeCompanyProfile?.weavrData?.isMobileEnrolled

    useEffect(() => {
        if (shouldUseTfa && !user?.activeCompanyProfile?.weavrData?.isMobileEnrolled) {
            NotificationService.send(
                NotificationTypeEnum.ERROR,
                t("error:cards:card_program_onboarding_not_finished.title"),
                t("error:cards:card_program_onboarding_not_finished.message"),
            )
            handleHide()
        }
    }, [shouldUseTfa, user?.activeCompanyProfile?.weavrData?.isMobileEnrolled])

    const onSubmitPasscode = (tokenizedPasscode: string) => {
        const authenticate = shouldUseTfa ? CorporateService.strongAuthenticateUser : CorporateService.authenticateUser
        return authenticate(tokenizedPasscode)
            .then((accessTokenInResponse) => {
                if (!shouldUseTfa) {
                    onSuccess(accessTokenInResponse)
                } else {
                    setAccessToken(accessTokenInResponse)
                    setCurrentStep(WeavrAuthenticationStepsEnum.VERIFICATION_CODE_STEP)
                }
                passcodeFormInstance.resetFields()
            })
            .catch((err) => {
                NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:error"))

                // if we hit this error we gotta refresh the auth user as the flag changed, in order to show the onboarding dialog
                if (err.response?.data.errorCode === ErrorCodeEnum.MOBILE_ENROLLEMENT_RESET) {
                    dispatch(syncEmployee(user.id))
                    handleHide()
                }
            })
    }

    const onSubmitVerificationCode = async (code: string) => {
        setIsLoading(true)

        try {
            await CorporateService.verifyStepUpChallenge(accessToken, code)
            onSuccess(accessToken)
        } catch (error) {
            handleVerificationCodeSubmissionError(error)
        } finally {
            setIsLoading(false)
        }
    }

    const handleVerificationCodeSubmissionError = (error: ErrorInterface | any) => {
        switch (error.response?.data?.errorCode) {
            case ErrorCodeEnum.WEAVR_AUTH_REQUIRED:
                handleHide()
                NotificationService.send(NotificationTypeEnum.ERROR, t("error:error"), t("error:corporate.authentication_expired"))
                break
            case ErrorCodeEnum.WEAVR_CHALLENGE_LIMIT_EXCEEDED:
                handleHide()
                NotificationService.showErrorNotificationBasedOnResponseError(error, t("error:error"))
                break
            default:
                NotificationService.showErrorNotificationBasedOnResponseError(error, t("error:error"))
        }
    }

    const onSuccess = (accessTokenToSet: string) => {
        dispatch(setIsWeavrAuthenticated(true, accessTokenToSet))
        axiosInterceptor.replayOriginalWeavrRequest()

        handleHide()
        onLoggedIn?.()
    }

    const handleHide = () => {
        dispatch(setShouldShowWeavrAuthModal(false))
        setCurrentStep(WeavrAuthenticationStepsEnum.PASSCODE_STEP)
        onClosed()
    }

    const renderTitle = () => (
        <div className="w-full flex flex-col">
            <h4 className="align-middle font-bold">{shouldUseTfa ? t("label:cards:card_program_strong_auth") : t("label:cards:card_program_auth")}</h4>
            {shouldUseTfa && (
                <Steps current={currentStep} size="small" className="w-140 flex flex-row no-number">
                    {[...Array(2)].map((_, i) => (
                        <Steps.Step key={i} />
                    ))}
                </Steps>
            )}
        </div>
    )

    const getCurrentActiveStep = () => {
        switch (currentStep) {
            case WeavrAuthenticationStepsEnum.PASSCODE_STEP:
                return <WeavrPasscodeForm formInstance={passcodeFormInstance} isModalShowing={isShowing} setIsLoading={setIsLoading} onSubmit={onSubmitPasscode} />
            case WeavrAuthenticationStepsEnum.VERIFICATION_CODE_STEP:
                return (
                    <>
                        <p className="mb-10 font-medium items-center primary-placeholder ant-form-item-required">{t("label:phone_verification.code_description")}</p>
                        <TokenCodeForm isLoginForm={false} onSubmit={onSubmitVerificationCode} />
                    </>
                )
            default:
                return null
        }
    }

    const evaluateCurrentStepSubmission = () => {
        switch (currentStep) {
            case WeavrAuthenticationStepsEnum.PASSCODE_STEP:
                passcodeFormInstance.submit()
                break
            case WeavrAuthenticationStepsEnum.VERIFICATION_CODE_STEP:
                // implicitly handled by the TokenCodeForm
                break
            default:
                break
        }
    }

    return (
        <Modal
            visible={isShowing}
            maskClosable={false}
            title={renderTitle()}
            onCancel={handleHide}
            className="ant-modal--small"
            footer={[
                <Button key="back" onClick={() => handleHide()}>
                    {t("action:cancel")}
                </Button>,
                <Button key="submit" type="primary" loading={isLoading} onClick={evaluateCurrentStepSubmission}>
                    {t("action:submit")}
                </Button>,
            ]}
            destroyOnClose
            closable
            keyboard
        >
            {getCurrentActiveStep()}
        </Modal>
    )
}

export default WeavrAuthModal
