import { KYBStepsEnum } from "@finway-group/shared/lib/models"
import { Action } from "redux"

import { KYBData, Wallet } from "Shared/store/reducers/corporateReducer"

export enum CorporateActionTypes {
    FETCH_KYB_PROGRESS = "FETCH_KYB_PROGRESS",
    SET_KYB_DATA = "SET_KYB_DATA",
    SET_CURRENT_KYB_STEP = "SET_CURRENT_KYB_STEP",
    SET_WALLET = "SET_WALLET",
    RESET_WALLET = "RESET_WALLET",
}

export interface FetchKYBProgressAction extends Action<typeof CorporateActionTypes.FETCH_KYB_PROGRESS> {
    type: typeof CorporateActionTypes.FETCH_KYB_PROGRESS
    currentKYBStep: KYBStepsEnum
    kybData: KYBData
}

export interface SetKYBDataAction extends Action<typeof CorporateActionTypes.SET_KYB_DATA> {
    type: typeof CorporateActionTypes.SET_KYB_DATA
    kybData: KYBData
}

export interface SetCurrentKYBStepAction extends Action<typeof CorporateActionTypes.SET_CURRENT_KYB_STEP> {
    type: typeof CorporateActionTypes.SET_CURRENT_KYB_STEP
    currentKYBStep: KYBStepsEnum
}

export interface SetWalletAction extends Action<typeof CorporateActionTypes.SET_WALLET> {
    type: typeof CorporateActionTypes.SET_WALLET
    wallet: Wallet
}

export interface ResetWalletAction extends Action<typeof CorporateActionTypes.RESET_WALLET> {
    type: typeof CorporateActionTypes.RESET_WALLET
}

export type CorporateActions = SetKYBDataAction | FetchKYBProgressAction | SetCurrentKYBStepAction | SetWalletAction | ResetWalletAction
