import { LoadingOutlined } from "@ant-design/icons"
import { Expense, ExpenseStatusEnum } from "@finway-group/shared/lib/models"
import { Button, Empty, Table, Tag, Tooltip } from "antd"
import { ColumnsType } from "antd/lib/table"
import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"

import NoSearchDataFound from "Components/NoSearchDataFound"
import AmountColumn from "Components/expenseAmountColumn"
import ExpenseReminderInfo from "Components/expenseReminderInfo"
import ExpenseStatusTag from "Components/expenseStatusTag"
import ExpenseTagColumn from "Components/expenseTagColumn"
import { ErrorTable } from "Components/layout/errorTable"
import Loading from "Components/loading"
import RequestColumn from "Components/requestColumn"
import ExpandTripFolderTableIcon from "Components/tables/expandTripFolderTableIcon"
import { useLoadingExpenseIds, useSelectedExpenses } from "Shared/context/selectedExpenses.context"
import { useCompany } from "Shared/hooks/company.hooks"
import { markExpenseAsReviewed, reviewFolder } from "Shared/hooks/expense.hooks"
import { useFetchTable, useTable, useTableFilterAndSearchLoading, useTableSearchString } from "Shared/hooks/table.hooks"
import { useAllCreditors } from "Shared/hooks/vendor.hooks"
import { AuthzService, UserService } from "Shared/services"
import { setExpense } from "Shared/store/actions/expense/expenseActions"
import { refetchTable, updateTableOnChange } from "Shared/store/actions/tables/tableActions"
import { TablesEnum } from "Shared/store/reducers/tableConfigReducer"
import { diagnoseExpenseForReview, isFolderExpense } from "Shared/utils/expense.utils"
import { getUniqueElements, insertIf } from "Shared/utils/helper.utils"
import { getPopupAnchor } from "Shared/utils/popup.utils"
import { getSortOrderForColumn } from "Shared/utils/table.utils"

interface ReviewTabPropInterface {}

const ReviewTab: React.FC<ReviewTabPropInterface> = ({}) => {
    const { t } = useTranslation()
    const history = useHistory()
    const dispatch = useDispatch()
    const loggedInProfile = UserService.getLoggedInEmployeeProfile()
    const [selectedExpenses, setSelectedExpenses] = useSelectedExpenses()
    const [loadingExpenseIds, setLoadingExpenseIds] = useLoadingExpenseIds()
    const reviewTable = useTable<Expense>(TablesEnum.REVIEW_REQUESTS)
    const tabLoading = useTableFilterAndSearchLoading(TablesEnum.REVIEW_REQUESTS)
    const { taggingEnabled } = useCompany()
    const creditorMap = useAllCreditors()
    const showTags = taggingEnabled && reviewTable.data.docs.some((expense) => expense?.expenseTags?.length)
    const searchString = useTableSearchString(TablesEnum.REVIEW_REQUESTS)

    const hasReviewRight = AuthzService.canLoggedInUserReviewExpenses()

    useFetchTable(TablesEnum.REVIEW_REQUESTS)

    const renderActionColumn = (data: Expense) => {
        if (data.status !== ExpenseStatusEnum.APPROVED || !hasReviewRight) {
            return <ExpenseStatusTag status={data.status} />
        }

        const { isReviewable, isVendorApproved } = diagnoseExpenseForReview(data, creditorMap)

        return (
            <div className="btn-wrapper">
                <Tooltip
                    // Need to explicitly set the width to avoid being pushed back by the next column
                    overlayStyle={{ width: 500 }}
                    getPopupContainer={getPopupAnchor()}
                    title={
                        !isReviewable ? (
                            <div>
                                <p>{t("tooltips:expenses.not_all_information_provided")}</p>
                                {isFolderExpense(data) && data.children.some((child) => child.status < ExpenseStatusEnum.APPROVED) && (
                                    <p>{t("tooltips:expenses.not_approved_yet")}</p>
                                )}
                            </div>
                        ) : !isVendorApproved ? (
                            <div>{t("tooltips:expenses.vendor_not_approved")}</div>
                        ) : undefined
                    }
                    placement="right"
                >
                    {data.status < ExpenseStatusEnum.APPROVED ? (
                        <Tag className="ant-tag-red ml-10">Pending approval state</Tag>
                    ) : (
                        <Button
                            loading={loadingExpenseIds.includes(data.id)}
                            className="mr-auto ml-auto"
                            onClick={(e) => {
                                e.stopPropagation()
                                setLoadingExpenseIds([data.id])
                                if (isFolderExpense(data)) {
                                    return reviewFolder(data, (data as any).children)
                                }

                                return markExpenseAsReviewed(data).finally(() => setLoadingExpenseIds([]))
                            }}
                            disabled={!isReviewable || !isVendorApproved}
                            type="primary"
                        >
                            <span>{t("action:mark_as_reviewed")}</span>
                        </Button>
                    )}
                </Tooltip>
            </div>
        )
    }

    const expandableColumn = reviewTable.data.docs.some((expense) => (expense as any)?.children && (expense as any)?.children.length > 0)
        ? [
              {
                  dataIndex: "expandIcon",
                  width: 30,
                  align: "center",
              } as any,
          ]
        : []

    const columns: ColumnsType<Expense> = [
        ...expandableColumn,
        {
            title: t("label:request"),
            key: "expenseNumber",
            align: "left",
            ellipsis: true,
            width: 350,
            ...(loggedInProfile.settings.showExpenseNumbers && {
                sorter: true,
                sortOrder: getSortOrderForColumn(reviewTable, "expenseNumber"),
            }),
            render: (data: any) => <RequestColumn request={data} />,
        },
        ...insertIf(showTags, {
            title: t("label:tag"),
            key: "tag",
            align: "left",
            width: 130,
            render: ({ expenseTags: expenseTagIds }: Expense) => <ExpenseTagColumn expenseTagIds={expenseTagIds} />,
        }),
        {
            key: "reminder",
            align: "center",
            width: 50,
            render: (request: Expense) => <ExpenseReminderInfo request={request} />,
        },
        {
            title: t("label:action"),
            key: "action",
            align: "center",
            width: 260,
            render: (expense: Expense) => renderActionColumn(expense),
        },
        {
            title: loggedInProfile.settings.showGrossAmount ? t("label:gross_amount") : t("label:net_amount"),
            key: loggedInProfile.settings.showGrossAmount ? "totalGrossPrice" : "totalNetPrice",
            align: "right",
            className: "pr-10",
            width: 200,
            sorter: true,
            sortOrder: getSortOrderForColumn(reviewTable, loggedInProfile.settings.showGrossAmount ? "totalGrossPrice" : "totalNetPrice"),
            render: (data: Expense) => <AmountColumn request={data} showGross={loggedInProfile.settings.showGrossAmount} />,
        },
    ]

    return tabLoading ? (
        <Loading />
    ) : reviewTable.error ? (
        <ErrorTable onTableReload={() => dispatch(refetchTable(TablesEnum.REVIEW_REQUESTS))} isLoading={reviewTable.isFetching} />
    ) : reviewTable.data.totalDocs === 0 && !reviewTable.isFetching ? (
        searchString ? (
            <div className="animation-appear">
                <NoSearchDataFound />
            </div>
        ) : (
            <Empty
                className="animation-appear"
                image={`./icons/empty_table.svg`}
                imageStyle={{
                    height: 150,
                    marginRight: "auto",
                    marginLeft: "auto",
                    marginTop: "15%",
                    marginBottom: "40px",
                    display: "inline-block",
                }}
                description={<span className="text-lg font-bold mt-2 mb-0">{t("info:empty_state.requests.title")}</span>}
            >
                <p>{t("info:empty_state.review.message")}</p>
            </Empty>
        )
    ) : (
        <div className="overflow-auto mb-24 p-2 animation-appear">
            <ul className="card-list">
                <Table
                    rowKey={(record) => record.id}
                    dataSource={reviewTable.data.docs}
                    columns={columns}
                    rowSelection={{
                        type: "checkbox",
                        renderCell: (_value, _record, _index, originNode) => (
                            <div onClick={(e) => e.stopPropagation()} className="bigger-hitbox-checkbox">
                                {originNode}
                            </div>
                        ),
                        selectedRowKeys: selectedExpenses.map((record) => record.id),
                        checkStrictly: false,
                        onChange: (_selectedRowKeys, selectedRows) => {
                            setSelectedExpenses(selectedRows)
                        },
                        onSelectAll: (selected, selectedRows, changes) => {
                            // * Deselect all on bulk deselect
                            if (!selected && !selectedRows.length) {
                                setSelectedExpenses([])
                                return
                            }

                            // onSelectAll will reliably fire when the "select all" checkbox is clicked. If the check is clicked somewhere else, it wont fire.

                            // If the user clicks the "select all" check box, dont include the children because they are hidden if not expanded (to avoid user confusion).
                            // Read: https://levaroio.atlassian.net/browse/BUG-7150
                            // filteredChanges.length > 0 means that there are unselected non-children rows to select when the user clicks on "select all"
                            const filteredChanges = changes.filter((expense) => !expense.folderId)
                            // * Data would contain a collection of only reviewable expenses or folders to be set as selected
                            const data: Array<Expense> = []
                            if (selected && filteredChanges.length > 0) {
                                filteredChanges.forEach((expenseObject: any) => {
                                    if (expenseObject.children && expenseObject.children.length > 0) {
                                        //* Avoid selecting reviewable expenses inside a non reviewable folder
                                        const canBeReviewed = expenseObject.children.every((item: Expense) => {
                                            const { isReviewable, isVendorApproved } = diagnoseExpenseForReview(item, creditorMap)
                                            return isReviewable || isVendorApproved
                                        })

                                        if (!canBeReviewed) {
                                            return
                                        }

                                        //* if folder is reviewable and children are reviewable push to data array
                                        data.push(...expenseObject.children)
                                    }

                                    data.push(expenseObject)
                                })

                                setSelectedExpenses(getUniqueElements([...data, ...selectedExpenses], "id"))
                                return
                            }

                            // if filteredChanges === 0, that means that the "select all" checkbox must be currently in partially selected mode (displayed as square in antd)
                            // and there are no more rows to check.
                            // Therefore we perform deselect all on the current page
                            const currentPageIds = reviewTable.data.docs.map((doc) => doc.id)
                            setSelectedExpenses(selectedExpenses.filter((expense) => !currentPageIds.includes(expense.id)))
                        },
                        getCheckboxProps: (data: Expense) => {
                            const { isReviewable, isVendorApproved } = diagnoseExpenseForReview(data, creditorMap)
                            return {
                                disabled: !isReviewable || !isVendorApproved,
                            }
                        },
                    }}
                    pagination={{
                        current: reviewTable.data.page,
                        pageSize: reviewTable.data.limit,
                        total: reviewTable.data.totalDocs,
                        position: ["bottomRight"],
                        showSizeChanger: true,
                        hideOnSinglePage: false,
                        pageSizeOptions: ["5", "10", "20", "50", "100"],
                    }}
                    onChange={updateTableOnChange(TablesEnum.REVIEW_REQUESTS, reviewTable.data)}
                    loading={{
                        spinning: reviewTable.isFetching,
                        indicator: <LoadingOutlined style={{ fontSize: 30, color: "black" }} spin />,
                    }}
                    onRow={(record: Expense, _rowIndex) => ({
                        onClick: (_event) => {
                            dispatch(setExpense(record))
                            history.push(`/expenses/${record.id}`)
                        },
                    })}
                    expandIcon={(props) => <ExpandTripFolderTableIcon props={props} />}
                />
            </ul>
        </div>
    )
}

export default ReviewTab
