import { Bank } from "@finway-group/shared/lib/models"
import { Form, Modal } from "antd"
import { Store } from "antd/lib/form/interface"
import React from "react"
import { useTranslation } from "react-i18next"

import BankPreview from "Components/bankPreview"
import BankSearchForm from "Components/forms/banking/bankSearch.form"
import { BankService, NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

interface BankSearchModalProps {
    isShowing: boolean
    onCancel: () => void
}

const BankSearchModal = ({ isShowing, onCancel }: BankSearchModalProps) => {
    const { t } = useTranslation()
    const [formInstance] = Form.useForm()
    const [isLoading, setIsLoading] = useStateIfMounted(false)
    const [banks, setBanks] = useStateIfMounted<Array<Bank>>([])
    const [hasSearched, setHasSearched] = useStateIfMounted(false)
    const [importingBank, setImportingBank] = useStateIfMounted<string>("")

    const onSubmit = (values: Store) => {
        setIsLoading(true)
        BankService.searchBanks(values.searchTerm)
            .then((banks) => {
                setHasSearched(true)
                setIsLoading(false)
                setBanks(banks)
            })
            .catch(() => {
                setHasSearched(true)
                setIsLoading(false)
                setBanks([])
            })
    }

    const onImport = (bankId: string) => {
        setImportingBank(bankId)
        BankService.importBankConnection(bankId)
            .then(() => {
                setImportingBank("")
                NotificationService.send(NotificationTypeEnum.INFO, t("notification:banking.import_started.title"), t("notification:banking.import_started.message"), 30)
                onHide()
            })
            .catch((err) => {
                setImportingBank("")
                NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:banking.error"))
            })
    }

    const onHide = () => {
        setHasSearched(false)
        setBanks([])
        formInstance.resetFields()
        onCancel()
    }

    return (
        <>
            <Modal
                title={t("action:banking.search_bank.title")}
                visible={isShowing}
                maskClosable={false}
                onOk={formInstance.submit}
                onCancel={onHide}
                okText={t("action:banking.search_bank.search")}
                cancelText={t("action:cancel")}
                confirmLoading={isLoading}
                keyboard
                destroyOnClose
            >
                <BankSearchForm formInstance={formInstance} onSubmit={onSubmit} />
                {banks.length > 0 ? (
                    <>
                        <p className="text-text-dark text-lg font-bold mt-10 mb-20 leading-none">{t("label:bank_search_results")}</p>
                        <ul>
                            {banks.map((bank: Bank) => (
                                <li className="mb-20" key={bank.bankId}>
                                    <BankPreview bank={bank} isImporting={importingBank === bank.bankId} onImport={onImport} />
                                </li>
                            ))}
                        </ul>
                    </>
                ) : (
                    hasSearched && <span className="font-bold">{t("missing:banking.no_banks_found")}</span>
                )}
            </Modal>
        </>
    )
}

export default BankSearchModal
