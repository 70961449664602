import { Expense, TripFolder } from "@finway-group/shared/lib/models"
import { Button, Modal, Tooltip } from "antd"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import AttachIcon from "Features/pages/expenses/expenseDetails/icons/attach.icon"
import { useCompany } from "Shared/hooks/company.hooks"
import { ApprovalProcessService, NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { attachExpenses } from "Shared/store/actions/expense/expenseActions"
import { FOLDER_CHILDREN_LIMIT } from "Shared/utils/folder.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"
import { getPopupAnchor } from "Shared/utils/popup.utils"

import { AttachExpenseTable } from "./attachExpense.table"

interface AttachExpenseButtonInterface {
    folder: TripFolder
    subExpenseCount: number
}
export const AttachExpenseButton = ({ folder, subExpenseCount }: AttachExpenseButtonInterface) => {
    const { t } = useTranslation()
    const [isShowing, setIsShowing] = useStateIfMounted(false)
    const [disableSelection, setDisableSelection] = useStateIfMounted(false)
    const dispatch = useDispatch()
    const [selectedExpenses, setSelectedExpenses] = useStateIfMounted<Array<Expense>>([])
    const okDisabled = selectedExpenses.length === 0
    const company = useCompany()
    const [isLoading, setIsLoading] = useStateIfMounted(false)

    useEffect(() => {
        let mounted = true

        if (mounted) {
            setDisableSelection(selectedExpenses.length + subExpenseCount >= FOLDER_CHILDREN_LIMIT)
        }

        return () => {
            mounted = false
        }
    }, [setDisableSelection, selectedExpenses.length, subExpenseCount])

    const onAttachExpenses = async () => {
        const subExpenseIds = selectedExpenses.map((subExpense) => subExpense.id)
        const grossDiff = selectedExpenses.reduce((acc, expense) => acc + expense.totalGrossPrice, 0)
        const isFolderAmountChangeApproved = await ApprovalProcessService.confirmFolderAmountChange(grossDiff, folder, company)
        if (!isFolderAmountChangeApproved) return

        setIsLoading(true)
        try {
            await attachExpenses(folder.id, subExpenseIds)(dispatch)
            NotificationService.send(
                NotificationTypeEnum.SUCCESS,
                t(`notification:request.attached.title`),
                t(`notification:request.attached.message`, { folderName: folder?.folderName }),
            )
            setSelectedExpenses([])
            setIsShowing(false)
        } catch (err) {
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:error"))
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <>
            {isShowing && (
                <Modal
                    visible={isShowing}
                    title={t("action:request.folder.attach_expense")}
                    onCancel={() => setIsShowing(false)}
                    onOk={onAttachExpenses}
                    maskClosable={false}
                    className="ant-modal--large"
                    footer={[
                        <div className="flex gap-12 justify-end">
                            <Button key="cancel" onClick={() => setIsShowing(false)}>
                                {t("action:cancel")}
                            </Button>
                            <Tooltip title={t("tooltips:folder.attach_empty")} trigger={okDisabled ? ["hover"] : []} key="okbutton" getTooltipContainer={getPopupAnchor()}>
                                <Button
                                    key="submit"
                                    type="primary"
                                    onClick={onAttachExpenses}
                                    disabled={okDisabled || selectedExpenses.length + subExpenseCount > FOLDER_CHILDREN_LIMIT}
                                    loading={isLoading}
                                >
                                    {t("action:request.folder.attach_expense")}
                                </Button>
                            </Tooltip>
                        </div>,
                    ]}
                >
                    <AttachExpenseTable selectedExpenses={selectedExpenses} setSelectedExpenses={setSelectedExpenses} disableSelection={disableSelection} />
                </Modal>
            )}
            <Button className="m-5" onClick={() => setIsShowing(true)} disabled={disableSelection}>
                <AttachIcon className="mr-5" /> {t("action:request.folder.attach")}
            </Button>
        </>
    )
}
