import { Col, Form, Input, Row, Select } from "antd"
import { FormInstance } from "antd/lib/form"
import { Store } from "antd/lib/form/interface"
import React from "react"
import { useTranslation } from "react-i18next"

const { Option } = Select

interface BankSearchFormInterface {
    formInstance: FormInstance
    onSubmit: (values: Store) => void
}

const BankSearchForm: React.FC<BankSearchFormInterface> = ({ formInstance, onSubmit }) => {
    const { t } = useTranslation()

    return (
        <Form form={formInstance} layout="vertical" onFinish={onSubmit} autoComplete="off">
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Form.Item label={t("input:banking.search_term")} name="searchTerm">
                        <Input placeholder={t("placeholder:banking.search_term")} required />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    )
}

export default BankSearchForm
