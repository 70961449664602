// This optional code is used to register a service worker.
// register() is not called by default.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://cra.link/PWA

interface Config {
    onUpdate?: (registration: ServiceWorkerRegistration) => void
    onSuccess?: (registration: ServiceWorkerRegistration) => void
}

const isLocalhost = Boolean(
    window.location.hostname === "localhost" ||
        // [::1] is the IPv6 localhost address.
        window.location.hostname === "[::1]" ||
        // 127.0.0.0/8 are considered localhost for IPv4.
        window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/),
)

export function register(config?: Config) {
    if (process.env.NODE_ENV !== "production" || !("serviceWorker" in navigator)) return

    window.addEventListener("load", () => {
        const swUrl = `${window.location.origin}/service-worker.js`

        console.debug(`[SW] Registering serviceWorker with url ${swUrl}`)

        if (isLocalhost) {
            // This is running on localhost. Let's check if a service worker still exists or not.
            checkValidServiceWorker(swUrl, config)
            return
        }

        // Is not localhost. Just register service worker
        registerValidSW(swUrl, config)
    })
}

const registerValidSW = (swUrl: string, config?: Config) => {
    navigator.serviceWorker
        .register(swUrl)
        .then((registration) => {
            // check for update one immediately and then...
            checkForUpdate(registration)
            // ...check for updates periodically => every minute
            scheduleUpdateCheck(registration, 1000 * 60)

            registration.onupdatefound = () => {
                const installingWorker = registration.installing
                console.debug(`[SW] onupdatefound w/ worker ${installingWorker}`)
                if (installingWorker == null) return

                installingWorker.onstatechange = () => {
                    console.debug(`[SW] onstatechange w/ state ${installingWorker.state} & config ${!!config?.onUpdate}`)
                    if (installingWorker.state !== "installed") return

                    if (navigator.serviceWorker.controller) {
                        config?.onUpdate?.(registration)
                    } else {
                        config?.onSuccess?.(registration)
                    }
                }
            }
        })
        .catch((error) => {
            console.error(`[SW] Error during service worker registration: ${error}`)
        })
}

const scheduleUpdateCheck = (registration: ServiceWorkerRegistration, intervalMs: number) => {
    setInterval(() => {
        checkForUpdate(registration)
    }, intervalMs)
}

const checkForUpdate = (registration: ServiceWorkerRegistration) => {
    registration
        .update()
        .then(() => {
            console.debug(`[SW] Checked for update`)
        })
        .catch((e) => {
            console.error(`[SW] Failed to check for update ${e}`)
        })
}

const checkValidServiceWorker = (swUrl: string, config?: Config) => {
    // Check if the service worker can be found. If it can't reload the page.
    fetch(swUrl, {
        headers: { "Service-Worker": "script" },
    })
        .then((response) => {
            // Ensure service worker exists, and that we really are getting a JS file.
            const contentType = response.headers.get("content-type")
            if (response.status === 404 || (contentType != null && contentType.indexOf("javascript") === -1)) {
                // No service worker found. Probably a different app. Reload the page.
                navigator.serviceWorker.ready.then((registration) => {
                    registration.unregister().then(() => {
                        window.location.reload()
                    })
                })
            } else {
                // Service worker found. Proceed as normal.
                registerValidSW(swUrl, config)
            }
        })
        .catch(() => {
            console.log("[SW] No internet connection found. App is running in offline mode.")
        })
}

export const unregister = () => {
    if ("serviceWorker" in navigator) {
        navigator.serviceWorker.ready
            .then((registration) => {
                registration.unregister()
            })
            .catch((error) => {
                console.error(`[SW] Unregistration error ${error.message}`)
            })
    }
}
