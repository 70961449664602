import { CurrencyEnum, Employee, PriceIntervalEnum } from "@finway-group/shared/lib/models"
import { Button } from "antd"
import React, { useEffect } from "react"
import { Edit as EditIcon } from "react-feather"
import { useTranslation } from "react-i18next"

import EmployeeLink from "Components/employeeLink"
import EmployeeLimitModal from "Components/modals/employeeLimit.modal"
import PriceLabel from "Components/priceLabel"
import { useModal } from "Shared/context/modal.context"
import { AuthzService, CurrencyService } from "Shared/services"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

interface EmployeeLimitsInterface {
    globalCurrency: CurrencyEnum
    approvals: Array<EmployeeLimitType>
    employee: Employee
}

interface EmployeeLimitType {
    approver: Employee
    currency: CurrencyEnum
    limit: number
}

const EmployeeLimits: React.FC<EmployeeLimitsInterface> = ({ globalCurrency, approvals, employee }) => {
    const { t } = useTranslation()
    const { showModal, hideModal } = useModal()

    const [approverList, setApproverList] = useStateIfMounted<Array<EmployeeLimitType>>([])

    const openLimitModal = () => showModal(EmployeeLimitModal, true, { isShowing: true, employee, onCancel: hideLimitModal })
    const hideLimitModal = () => hideModal(EmployeeLimitModal, true, {})

    const canUpdateEmployeeLimits = AuthzService.canUpdateUserApprovalData(employee)

    useEffect(() => {
        setApproverList(
            approvals.map((approval) => ({
                ...approval,
                limit: approval.currency === globalCurrency ? approval.limit : CurrencyService.convert(approval.limit, approval.currency, globalCurrency),
            })),
        )
    }, [approvals])

    return (
        <>
            <div className="overflow-hidden">
                {approvals?.length === 0 ? (
                    <div className="flex justify-between">
                        <span>{t("missing:approvals")}</span>
                        {canUpdateEmployeeLimits && (
                            <Button size="small" className="btn-default min-w-100" onClick={openLimitModal}>
                                <span>{t("action:set")}</span>
                            </Button>
                        )}
                    </div>
                ) : (
                    <>
                        {canUpdateEmployeeLimits && (
                            <div className="flex flex-row-reverse">
                                <EditIcon className="cursor-pointer mt-5 mb-20" size={14} onClick={openLimitModal} />
                            </div>
                        )}
                        <ul className="description-list">
                            {approverList?.map((approval, i) => (
                                <React.Fragment key={i}>
                                    <li>
                                        <span className="title">{t("label:limit_up_to")}</span>
                                        <span className="text">
                                            <PriceLabel value={approval.limit} currency={globalCurrency} interval={PriceIntervalEnum.ONE_TIME} showFullNumber />
                                        </span>
                                    </li>
                                    <li>
                                        <span className="title">{t("label:approver")}</span>
                                        <span className="text truncate max-w-300">
                                            <EmployeeLink employee={approval.approver} />
                                        </span>
                                    </li>
                                </React.Fragment>
                            ))}
                        </ul>
                    </>
                )}
            </div>
        </>
    )
}

export default EmployeeLimits
