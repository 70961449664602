import { ErrorCodeEnum, KYBStatusEnum } from "@finway-group/shared/lib/models"
import httpStatus from "http-status"
import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import AddEmailAliasModal from "Components/modals/addEmailAlias.modal"
import { FINWAY_ADMIN_EMAIL } from "Shared/config/consts"
import { useCompany } from "Shared/hooks/company.hooks"
import { useKYBData } from "Shared/hooks/corporate.hooks"
import { AuthzService, NotificationService, UserService } from "Shared/services"
import CorporateService from "Shared/services/corporate.service"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { fetchKYBProgress } from "Shared/store/actions/corporate/corporateActions"
import { syncEmployee } from "Shared/store/actions/employee/employeeActions"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

import KYBStart from "./states/kybStart"

interface KYBContainerInterface {}

const KYB = React.lazy(() => import("Features/pages/creditCards/kyb/kyb"))
const KYBPlaceholder = React.lazy(() => import("Features/pages/creditCards/kyb/states/kybPlaceholder"))

const KYBContainer: React.FC<KYBContainerInterface> = () => {
    const { t } = useTranslation()
    const loggedInUser = UserService.getLoggedInEmployeeProfile()
    const { kybStatus } = useKYBData()
    const [isLoading, setIsLoading] = useStateIfMounted(false)
    const canStartKyb = AuthzService.canStartKyb()
    const [showKYBStart, setShowKYBStart] = useState(canStartKyb && kybStatus === KYBStatusEnum.NOT_STARTED)
    const showKYB = !showKYBStart && (loggedInUser.activeCompanyProfile.weavrData?.isRoot || loggedInUser.email === FINWAY_ADMIN_EMAIL)
    const dispatch = useDispatch()
    const company = useCompany()
    const [isEmailAliasErrorModalShowing, setIsEmailAliasErrorModalShowing] = useStateIfMounted(false)

    useEffect(() => {
        setShowKYBStart(canStartKyb && kybStatus === KYBStatusEnum.NOT_STARTED)
    }, [kybStatus])

    const onStartKYB = () => {
        setIsLoading(true)
        CorporateService.startKYB()
            .then(async () => {
                setShowKYBStart(false)
                await syncEmployee(loggedInUser.id)(dispatch)
                await fetchKYBProgress()(dispatch)
                NotificationService.send(NotificationTypeEnum.SUCCESS, "Success", "KYB Process started")
            })
            .catch((err) => {
                if (err.response.status === httpStatus.UNPROCESSABLE_ENTITY && err.response.data?.errorCode === ErrorCodeEnum.NO_EMAIL_ALIAS) {
                    setIsEmailAliasErrorModalShowing(true)
                } else {
                    NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:error"))
                }
            })
            .finally(() => setIsLoading(false))
    }

    return (
        <div className="kyb">
            <Helmet>
                <title>{t("view:kyb.title")}</title>
            </Helmet>
            <AddEmailAliasModal
                isShowing={isEmailAliasErrorModalShowing}
                onCancel={() => setIsEmailAliasErrorModalShowing(false)}
                onSuccess={onStartKYB}
                companyId={company._id}
                employee={loggedInUser}
                isShownOnError
            />
            {showKYBStart ? <KYBStart onStart={onStartKYB} isLoading={isLoading} /> : showKYB ? <KYB /> : <KYBPlaceholder />}
        </div>
    )
}

export default KYBContainer
