import { EditorPlugin } from "@draft-js-plugins/editor"
import createEmojiPlugin, { EmojiPlugin, defaultTheme } from "@draft-js-plugins/emoji"
import { EmojiSelectPubParams } from "@draft-js-plugins/emoji/lib/components/EmojiSelect"
import { EmojiSuggestionsPubParams } from "@draft-js-plugins/emoji/lib/components/EmojiSuggestions"
import createMentionPlugin from "@draft-js-plugins/mention"
import { MentionSuggestionsPubProps } from "@draft-js-plugins/mention/lib/MentionSuggestions/MentionSuggestions"
import Draft, { DraftDecorator } from "draft-js"
import { ComponentType, useMemo } from "react"

import createLinkDetectionPlugin from "Shared/utils/plugins/draftjs/linkDetectionPlugin"

type MentionPlugin = EditorPlugin & {
    MentionSuggestions: ComponentType<MentionSuggestionsPubProps>
}

type LinkDetectionPlugin = {
    decorators: Array<DraftDecorator>
    onChange: (editorState: Draft.EditorState) => Draft.EditorState
}

type PluginType = MentionPlugin | LinkDetectionPlugin | EmojiPlugin

export interface EditorDecoratorProps {
    plugins: Array<PluginType>
    MentionSuggestions: ComponentType<MentionSuggestionsPubProps>
    EmojiSuggestions: ComponentType<EmojiSuggestionsPubParams>
    EmojiSelect: ComponentType<EmojiSelectPubParams>
}

export const useEditorDecoratorProps = () =>
    useMemo(() => {
        const mentionPlugin = createMentionPlugin({
            positionSuggestions: (_settings: any) => ({
                transform: "scale(1) translateY(10%)",
                transformOrigin: "1em 0% 0px",
                transition: "all 0.25s cubic-bezier(0.3, 1.2, 0.2, 1)",
            }),
        })
        const { MentionSuggestions } = mentionPlugin

        const linkDetectionPlugin = createLinkDetectionPlugin()

        const emojiPlugin = createEmojiPlugin({
            theme: {
                ...defaultTheme,
                emoji: "emoji-custom-wrapper",
                emojiSelectButton: "custom-emoji-select-btn",
                emojiSelectButtonPressed: "custom-emoji-select-btn-pressed",
                emojiSelectPopover: "custom-emoji-select-popover",
                emojiSuggestionsEntryIcon: "custom-emoji-suggestions-entry-icon",
                emojiSelectPopoverNavEntryActive: "custom-emoji-select-popover-navEntryActive",
            },
            useNativeArt: true,
        })
        const { EmojiSuggestions, EmojiSelect } = emojiPlugin

        const plugins = [mentionPlugin, linkDetectionPlugin, emojiPlugin]

        return { plugins, MentionSuggestions, EmojiSuggestions, EmojiSelect }
    }, [])
