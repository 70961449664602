import { LoadingOutlined } from "@ant-design/icons"
import { Expense, ExpenseStatusEnum, TripFolder } from "@finway-group/shared/lib/models"
import { Checkbox, Dropdown, Menu, Table } from "antd"
import { ColumnsType } from "antd/lib/table"
import moment from "moment"
import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"

import AmountColumn from "Components/expenseAmountColumn"
import ExpenseReminderInfo from "Components/expenseReminderInfo"
import ExpenseStatusTag from "Components/expenseStatusTag"
import ExpenseTagColumn from "Components/expenseTagColumn"
import ExpenseTransactionStatusTag from "Components/expenseTransactionStatusTag"
import ExportDropDownMenu from "Components/exportDropDownMenu"
import { EmptyTable } from "Components/layout/emptyTable"
import { ErrorTable } from "Components/layout/errorTable"
import RequestColumn from "Components/requestColumn"
import { ExpenseExport } from "Features/export/expenseExport"
import { useTableSearchString } from "Shared/hooks/table.hooks"
import { AuthzService, UserService } from "Shared/services"
import { setExpense } from "Shared/store/actions/expense/expenseActions"
import { refetchTable, updateTableOnChange } from "Shared/store/actions/tables/tableActions"
import { TablesEnum } from "Shared/store/reducers/tableConfigReducer"
import { Table as TableInterface } from "Shared/store/reducers/tableReducer"
import { getExpensesToPayOrExportForTables, isFolderExpense } from "Shared/utils/expense.utils"
import { flattenArray, getUniqueElements, insertIf } from "Shared/utils/helper.utils"
import { getSortOrderForColumn } from "Shared/utils/table.utils"

import ExpandTripFolderTableIcon from "./expandTripFolderTableIcon"

interface ExportTableInterface {
    tableEnum: TablesEnum.PAY_AND_EXPORT_TO_BE_EXPORTED
    table: TableInterface<Expense>
    showTags: boolean
    checkedExpenses: Array<Expense>
    onCheckboxClick: (table: TablesEnum, selectedRows: Array<Expense>) => void
    onExportSuccess?: () => void
    onExpenseSelectAll: (selectAll: boolean) => void
    allSelected: boolean
    setExcludedExpensesFromExport: (expenseIds: Array<string>) => void
    excludedExpensesFromExport: Array<string>
}

const ExportTable: React.FC<ExportTableInterface> = ({
    tableEnum,
    table,
    checkedExpenses,
    showTags,
    onCheckboxClick,
    onExportSuccess,
    onExpenseSelectAll,
    allSelected,
    setExcludedExpensesFromExport,
    excludedExpensesFromExport,
}) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const history = useHistory()

    const loggedInProfile = UserService.getLoggedInEmployeeProfile()
    const dashboardSearchString = useTableSearchString(TablesEnum.DONE_REQUESTS)

    const checkedExpenseIds = checkedExpenses.map((expense) => expense.id)
    const currentPageRowIds = flattenArray<string>(table.data.docs.map((doc) => (isFolderExpense(doc) ? [doc.id, ...doc.children.map((e) => e.id)] : [doc.id])))
    const hasExportRight = AuthzService.canLoggedInUserExport()

    const expandableColumn = table.data.docs.some((expense) => isFolderExpense(expense) && (expense as TripFolder)?.children.length > 0)
        ? [
              {
                  dataIndex: "expandIcon",
                  width: 30,
                  align: "center",
              } as any,
          ]
        : []

    const getTableColumns = (): ColumnsType<Expense> => {
        const columns: ColumnsType<Expense> = [
            ...expandableColumn,
            {
                title: t("label:request"),
                key: "expenseNumber",
                ellipsis: true,
                width: 320,
                ...(loggedInProfile.settings.showExpenseNumbers && {
                    sorter: true,
                    sortOrder: getSortOrderForColumn(table, "expenseNumber"),
                }),
                render: (data: Expense) => <RequestColumn request={data} />,
            },
            {
                title: t("label:date_paid_at"),
                key: "datePaidAt",
                align: "left",
                width: 130,
                sorter: true,
                sortOrder: getSortOrderForColumn(table, "datePaidAt"),
                render: (expense: Expense) => <div>{expense.datePaidAt ? moment(expense.datePaidAt).format("ll") : "n/a"}</div>,
            },
            ...insertIf(showTags, {
                title: t("label:tag"),
                key: "tag",
                align: "left",
                width: 150,
                render: ({ expenseTags: expenseTagIds }: Expense) => <ExpenseTagColumn expenseTagIds={expenseTagIds} />,
            }),
            {
                key: "reminder",
                align: "center",
                width: 50,
                render: (request: Expense) => <ExpenseReminderInfo request={request} />,
            },
            {
                title: t("label:action"),
                key: "status",
                align: "left",
                width: 190,
                render: (expense: Expense) => (
                    <div className="btn-wrapper">
                        <ExpenseExport
                            onSuccess={onExportSuccess}
                            render={({ onExport, isExporting }) => {
                                if (expense.status !== ExpenseStatusEnum.PAID) {
                                    return <ExpenseStatusTag status={expense.status} />
                                }
                                return (
                                    <ExportDropDownMenu
                                        // lets the children of the folder to be included in the export
                                        expenses={getExpensesToPayOrExportForTables([expense], undefined, true)}
                                        onExport={onExport}
                                        isLoading={isExporting}
                                        includeSepa
                                        noIcon
                                        primaryButton
                                        buttonClassName={"w-120"}
                                        onlyFileExport={!hasExportRight}
                                        isAuditTrailVisible={true}
                                    />
                                )
                            }}
                        />
                    </div>
                ),
            },
            {
                title: t("label:matching.transaction_status"),
                key: "transactionState",
                align: "center",
                width: 170,
                sorter: true,
                sortOrder: getSortOrderForColumn(table, "transactionState"),
                render: (data: Expense) => <ExpenseTransactionStatusTag expense={data} />,
            },
            {
                title: loggedInProfile.settings.showGrossAmount ? t("label:gross_amount") : t("label:net_amount"),
                key: loggedInProfile.settings.showGrossAmount ? "totalGrossPrice" : "totalNetPrice",
                align: "right",
                width: 180,
                className: "pr-10",
                sorter: true,
                sortOrder: getSortOrderForColumn(table, loggedInProfile.settings.showGrossAmount ? "totalGrossPrice" : "totalNetPrice"),
                render: (data: Expense) => <AmountColumn request={data} showGross={loggedInProfile.settings.showGrossAmount} showDiscountWhenAvailable />,
            },
        ]
        return columns
    }

    const deselectAll = () => {
        onCheckboxClick(tableEnum, [])
        onExpenseSelectAll(false)
        setExcludedExpensesFromExport([])
    }

    const onSelect = ({ key, domEvent }: any) => {
        domEvent.stopPropagation()
        switch (key) {
            case "select_page":
                onCheckboxClick(tableEnum, [...checkedExpenses, ...table.data.docs])

                break
            case "select_all":
                onExpenseSelectAll(true)
                break
            default:
                break
        }
    }
    const dropDownMenu = () => (
        <Menu onClick={onSelect}>
            <Menu.Item key="select_page" className="flex items-center ">
                <span className="text-text">{t("action:table.select_page")}</span>
            </Menu.Item>
            <Menu.Item key="select_all" className="flex items-center min-w-190 py-8">
                <span className="text-text">{t("action:table.select_all")}</span>
            </Menu.Item>
        </Menu>
    )

    const headerCheckbox =
        table.data.docs.filter((doc) => checkedExpenseIds.includes(doc.id)).length || allSelected ? (
            <Checkbox checked indeterminate={checkedExpenses.length > 0 && checkedExpenses.length < currentPageRowIds.length} onChange={deselectAll} />
        ) : (
            <Dropdown trigger={["click"]} overlay={dropDownMenu} placement="bottomLeft">
                <Checkbox checked={false} />
            </Dropdown>
        )

    const handleTableSelectionChange = (selectedRowKeys: any, selectedRows: any) => {
        // Get the list of deselected ids of the current page
        const deselectedRowIdsFromThisPage = currentPageRowIds.filter((doc) => !selectedRowKeys.includes(doc))
        // Make sure we do not append duplicates
        const deselectedRowIdsFromOtherPage = excludedExpensesFromExport.filter((id) => !currentPageRowIds.includes(id))
        const deselectedRowIds = [...deselectedRowIdsFromThisPage, ...deselectedRowIdsFromOtherPage]
        allSelected && setExcludedExpensesFromExport(deselectedRowIds)

        // selectedRows contains only the selected rows of the CURRENT PAGE
        // The selectedRows also have to include everything that has been selected in the other pages,
        const fullSelectedRows = getUniqueElements([...checkedExpenses, ...selectedRows], "id")
            // but have to check if there's any previous selected expenses has been deselected too.
            .filter((doc) => !deselectedRowIds.includes(doc.id))

        onCheckboxClick(tableEnum, fullSelectedRows)
    }

    return (
        <div className="animation-appear overflow-x-auto mb-20">
            {table.error ? (
                <ErrorTable onTableReload={() => dispatch(refetchTable(tableEnum))} isLoading={table.isFetching} />
            ) : table.data.totalDocs === 0 && !table.isFetching ? (
                <EmptyTable showSearchIcon={!!dashboardSearchString} noSearchText={t("info:no_search_data_found.title")} noDocumentText={t("info:empty_state.requests.title")} />
            ) : (
                <Table
                    rowKey={(record) => record.id}
                    dataSource={table.data.docs}
                    columns={getTableColumns()}
                    rowSelection={{
                        type: "checkbox",
                        renderCell: (_value, _record, _index, originNode) => (
                            <div onClick={(e) => e.stopPropagation()} className="bigger-hitbox-checkbox">
                                {originNode}
                            </div>
                        ),
                        checkStrictly: false,
                        columnTitle: headerCheckbox,
                        selectedRowKeys: allSelected
                            ? table.data.docs.filter((doc) => !excludedExpensesFromExport.includes(doc.id)).map((record) => record.id)
                            : checkedExpenses.map((record) => record.id),
                        onChange: handleTableSelectionChange,
                    }}
                    pagination={{
                        position: ["bottomRight"],
                        showSizeChanger: true,
                        current: table.data.page,
                        pageSize: table.data.limit,
                        hideOnSinglePage: false,
                        total: table.data.totalDocs,
                        pageSizeOptions: ["5", "10", "20", "50", "100"],
                    }}
                    loading={{
                        spinning: table.isFetching,
                        indicator: <LoadingOutlined style={{ fontSize: 30, color: "black" }} spin />,
                    }}
                    onChange={updateTableOnChange(tableEnum, table.data)}
                    onRow={(record, _rowIndex) => ({
                        onClick: (_event) => {
                            dispatch(setExpense(record))
                            history.push(`/expenses/${record.id}`)
                        },
                    })}
                    expandIcon={(props) => <ExpandTripFolderTableIcon props={props} />}
                />
            )}
        </div>
    )
}

export default ExportTable
