import { RightOutlined } from "@ant-design/icons"
import { FeatureFlagEnum } from "@finway-group/shared/lib/models"
import { Card, Col, List, Row } from "antd"
import React, { Suspense } from "react"
import { useTranslation } from "react-i18next"
import { NavLink, Route, useLocation } from "react-router-dom"

import { WEBAPP_VERSION } from "Shared/config/consts"
import { useAuth } from "Shared/hooks/auth.hooks"
import { hasFeatureFlags } from "Shared/utils/featureFlags.utils"

import { useSettingsContext } from "./settings.context"

interface MenuListItem {
    link: string
    titleKey: string
    requiredFeatureFlags: Array<FeatureFlagEnum>
    canSee?: boolean
    component?: JSX.Element
    isDefault?: boolean
}

export interface DefaultPathProps {
    defaultPath: string
}

interface SettingsContainerProps extends DefaultPathProps {
    menuList: Array<MenuListItem>
}

const SettingsContainer = ({ menuList, defaultPath }: SettingsContainerProps) => {
    const { t } = useTranslation()
    const { pathname } = useLocation()
    const { listVisible, setListVisible } = useSettingsContext()
    const { featureFlags } = useAuth()

    const filteredMenuList = menuList.filter((menuItem) => hasFeatureFlags(featureFlags, menuItem.requiredFeatureFlags) && menuItem.canSee)

    return (
        <Card className="settings-card pb-100">
            <Row gutter={[16, 16]} className="m-0 p-0 justify-start sm:justify-center">
                <Col xs={24} md={6} className={`p-0 ${!listVisible ? "hidden" : ""} sm:block overflow-y-auto h-screen-25`}>
                    <List
                        style={{ borderColor: "#EEF4F8" }}
                        dataSource={filteredMenuList}
                        renderItem={(item, index) => {
                            const isActive = pathname.includes(item.link) || (item.isDefault && pathname === defaultPath)
                            return (
                                <NavLink key={index} to={item.link} activeClassName="active" isActive={() => isActive ?? false}>
                                    <List.Item
                                        key={index}
                                        style={{ border: "solid 1px #EEF4F8" }}
                                        className={`settings-menu-item ${isActive ? "active" : ""}`}
                                        extra={<RightOutlined key="outlineIcon" />}
                                        onClick={() => setListVisible(false)}
                                    >
                                        <span className="text-base leading-relaxed">{t(item.titleKey)}</span>
                                    </List.Item>
                                </NavLink>
                            )
                        }}
                        bordered
                    />
                </Col>
                <Col xs={24} md={18} className={`md:mt-10 ${listVisible ? "hidden" : ""} sm:block`}>
                    <div className="w-full pr-10 pl-10 md:pr-20 md:pl-20">
                        {filteredMenuList.length > 0 && (
                            <Route exact path={defaultPath}>
                                <Suspense fallback={<></>}>{filteredMenuList[0].component}</Suspense>
                            </Route>
                        )}
                        {filteredMenuList.map(({ link, component }, index) => (
                            <Route key={index} path={link}>
                                <Suspense fallback={<></>}>{component}</Suspense>
                            </Route>
                        ))}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <p className="ml-20 mb-16 text-xs opacity-50">{`Version ${WEBAPP_VERSION}`}</p>
                </Col>
            </Row>
        </Card>
    )
}

export default React.memo(SettingsContainer)
