import { CostCenter2 } from "@finway-group/shared/lib/models"
import { Form, Select } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"

import { NOT_SET_VALUE } from "Shared/config/consts"
import { useCostCenters2 } from "Shared/hooks/costCenter2.hooks"
import { doFilterSelect } from "Shared/utils/helper.utils"

import { useMainDetailsSectionContext } from "../mainDetailsSection.context"
import { ExpenseSplitCellInterface } from "./expenseSplitCellInterface"

const CostCenter2Cell: React.FC<ExpenseSplitCellInterface> = ({ field, editable, splitIndex }) => {
    const { splits, onSplitUpdate } = useMainDetailsSectionContext()
    const currentSplit = splits[splitIndex]
    const costCenters2 = useCostCenters2()
    const { t } = useTranslation()

    const generateCell = () => {
        if (!editable) return <div>{costCenters2.find((cc) => cc._id === currentSplit.costCenter2)?.name}</div>

        return (
            <Form.Item name={[field.name, "costCenter2"]} key="costCenter2" className="mb-0">
                <Select
                    showSearch
                    filterOption={doFilterSelect}
                    className="text-xs md:text-base no-padding-select"
                    dropdownMatchSelectWidth={false}
                    bordered={false}
                    onChange={() => onSplitUpdate(splitIndex)}
                    placeholder={t("label:not_set")}
                    dropdownClassName="z-50"
                >
                    <Select.Option value={NOT_SET_VALUE}>{t("label:not_set")}</Select.Option>
                    {costCenters2.map((cc2: CostCenter2, index) => (
                        <Select.Option value={cc2._id} label={cc2.name} key={index}>
                            {cc2.name}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
        )
    }
    return generateCell()
}

export default CostCenter2Cell
