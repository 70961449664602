import { PlusOutlined } from "@ant-design/icons"
import { CurrencyEnum, Employee, UserApprover } from "@finway-group/shared/lib/models"
import { Button, Col, Form, Input, Row, Select, Tooltip } from "antd"
import { FormInstance, Rule } from "antd/lib/form"
import React, { useEffect } from "react"
import { Trash2 as TrashIcon } from "react-feather"
import { useTranslation } from "react-i18next"

import UserImage from "Components/UserImage"
import { ConditionalWrapper } from "Components/conditionalWrapper"
import CurrencyInput from "Components/currencyInput"
import FormItemCurrency from "Components/form/formItemCurrency"
import { FINWAY_ADMIN_EMAIL } from "Shared/config/consts"
import { getTooltipPopupContainer } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"
import { getPopupAnchor } from "Shared/utils/popup.utils"

const { Option } = Select

interface EmployeeLimitInterface {
    superior?: string
    possibleApprovers: Array<Employee>
    globalCurrency: CurrencyEnum
    approvals: Array<UserApprover>
    rules: { [key: string]: Array<Rule> }
    formInstance: FormInstance
}
const EmployeeLimitForm: React.FC<EmployeeLimitInterface> = ({ possibleApprovers, formInstance, approvals, globalCurrency, rules, superior }) => {
    const { t } = useTranslation()
    const { limit, approver } = rules
    const [fieldsCount, setFieldsCount] = useStateIfMounted(0)
    useEffect(() => {
        formInstance.setFieldsValue({ approvals })
        setFieldsCount(approvals.length)
        return () => {
            formInstance.setFieldsValue({ approvals: [] })
        }
    }, [approvals])

    const sentFieldCount = (action: "add" | "remove") => {
        action === "add" ? setFieldsCount(fieldsCount + 1) : setFieldsCount(fieldsCount - 1)
    }

    return (
        <>
            {!fieldsCount && <div className="mb-10">{t("missing:limits")}</div>}
            <Form.List name="approvals">
                {(fields, { add, remove }) => (
                    <div>
                        {fields.map((field, index) => (
                            <Row key={field.key} gutter={[16, 16]}>
                                <Col span={6} md={6}>
                                    <FormItemCurrency currency={globalCurrency} name={[field.name, "limit"]} label={t("input:employee.limit")} key="limit" rules={limit} required>
                                        <CurrencyInput currency={globalCurrency} value={0} />
                                    </FormItemCurrency>
                                </Col>
                                <Form.Item key={"currency"} name={[field.name, "currency"]} initialValue={globalCurrency} required>
                                    <Input type="hidden" />
                                </Form.Item>
                                <Col flex="auto">
                                    <Form.Item
                                        key={`approver-${index}`}
                                        label={t("input:employee.approver")}
                                        name={[field.name, "approver"]}
                                        initialValue={
                                            superior !== "" ? superior : possibleApprovers.filter(({ email }: Employee) => index === 0 || email !== FINWAY_ADMIN_EMAIL)[0]?.id
                                        }
                                        required
                                        rules={approver}
                                    >
                                        <Select getPopupContainer={getPopupAnchor()} onSelect={() => formInstance.validateFields()}>
                                            {possibleApprovers
                                                .filter(({ email }: Employee) => index === 0 || email !== FINWAY_ADMIN_EMAIL)
                                                .map((employee: Employee, index: number) => (
                                                    <Option key={index} label={`${employee.firstName} ${employee.lastName}`} value={employee.id}>
                                                        <div className="flex items-center">
                                                            <UserImage user={employee} />
                                                            <span>{`${employee.firstName} ${employee.lastName}`}</span>
                                                        </div>
                                                    </Option>
                                                ))}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={3}>
                                    <Button
                                        className="mt-30"
                                        onClick={() => {
                                            const index = field.name
                                            remove(index)
                                            sentFieldCount("remove")
                                            formInstance.validateFields()
                                        }}
                                    >
                                        <TrashIcon />
                                    </Button>
                                </Col>
                            </Row>
                        ))}

                        <Row>
                            <ConditionalWrapper
                                condition={possibleApprovers.length === 0}
                                wrapper={(children: any) => (
                                    <Tooltip getPopupContainer={getTooltipPopupContainer} title={t("tooltips:limit_add_disabled")}>
                                        {children}
                                    </Tooltip>
                                )}
                            >
                                <Button
                                    type="dashed"
                                    disabled={possibleApprovers.length === 0}
                                    onClick={() => {
                                        add()
                                        sentFieldCount("add")
                                    }}
                                    block
                                >
                                    <PlusOutlined className="mb-2" /> {t("action:employee.add_limit")}
                                </Button>
                            </ConditionalWrapper>
                        </Row>
                    </div>
                )}
            </Form.List>
        </>
    )
}

export default EmployeeLimitForm
