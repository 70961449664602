import { ExclamationCircleOutlined } from "@ant-design/icons"
import {
    DatevBookingItem,
    DatevBookingTextEnum,
    DatevInformationEnum,
    DatevOteWithItems,
    DatevOteWithoutItems,
    DatevReimbursementWithItems,
    DatevReimbursementWithoutItems,
    DatevSubscription,
} from "@finway-group/shared/lib/models"
import { Col, Divider, Form, Modal, Row, Select, Tooltip } from "antd"
import { Store } from "antd/lib/form/interface"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import { useCompany } from "Shared/hooks/company.hooks"
import { CompanyService, NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { setCompany } from "Shared/store/actions/company/companyActions"
import { getTooltipPopupContainer } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"
import { getPopupAnchor } from "Shared/utils/popup.utils"

interface CustomDatevInformationModalInterface {
    datevInfo: any
    datevBooking: Array<DatevBookingItem>
    isShowing: boolean
    onCancel: () => void
}

interface DatevInfoObject {
    first: DatevInformationEnum
    second: DatevInformationEnum
    third?: DatevInformationEnum
    fourth?: DatevInformationEnum
    fifth?: DatevInformationEnum
    sixth?: DatevInformationEnum
}

const buildInfoParts = (datevInfo: Array<any>) => {
    if (!datevInfo) datevInfo = []
    datevInfo.sort((a, b) => a.order - b.order)
    return {
        first: datevInfo[0]?.part ?? "",
        second: datevInfo[1]?.part ?? "",
        third: datevInfo[2]?.part ?? "",
        fourth: datevInfo[3]?.part ?? "",
        fifth: datevInfo[4]?.part ?? "",
        sixth: datevInfo[5]?.part ?? "",
    }
}

const CustomDatevInformationModal: React.FC<CustomDatevInformationModalInterface> = ({ datevInfo, datevBooking, isShowing, onCancel }) => {
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useState(false)
    const companyId = useCompany()?._id
    const [oneTimeExpenseNoItemsParts, setOneTimeExpenseNoItemsParts] = useStateIfMounted(buildInfoParts(datevInfo?.oneTimeExpenseNoItems))
    const [oneTimeExpenseWithItemsParts, setOneTimeExpenseWithItemsParts] = useStateIfMounted(buildInfoParts(datevInfo?.oneTimeExpenseWithItems))
    const [subscriptionParts, setSubscriptionParts] = useStateIfMounted(buildInfoParts(datevInfo?.subscription))
    const [reimbursementNoItemsParts, setReimbursementNoItemsParts] = useStateIfMounted(buildInfoParts(datevInfo?.reimbursementNoItems))
    const [reimbursementWithItemsParts, setReimbursementWithItemsParts] = useStateIfMounted(buildInfoParts(datevInfo?.reimbursementWithItems))
    const [datevBookingItems, setDatevBookingItems] = useStateIfMounted(buildInfoParts(datevBooking))
    const handleHide = () => {
        setIsLoading(false)
        onCancel()
    }

    useEffect(() => {
        setOneTimeExpenseNoItemsParts(buildInfoParts(datevInfo?.oneTimeExpenseNoItems))
        setOneTimeExpenseWithItemsParts(buildInfoParts(datevInfo?.oneTimeExpenseWithItems))
        setSubscriptionParts(buildInfoParts(datevInfo?.subscription))
        setReimbursementNoItemsParts(buildInfoParts(datevInfo?.reimbursementNoItems))
        setReimbursementWithItemsParts(buildInfoParts(datevInfo?.reimbursementWithItems))
    }, [isShowing, datevInfo])

    const isPartAlreadySelected = (part: DatevInformationEnum, parts: DatevInfoObject) => Object.values(parts).includes(part)

    const prepareArray = (object: DatevInfoObject) =>
        // filter out empty parts and assign ordering by indexing
        Object.values(object)
            .filter((x) => x)
            .map((part, index) => ({ order: index, part }))

    const onFormSubmit = async (_values: Store) => {
        setIsLoading(true)
        try {
            const oneTimeNoItems = prepareArray(oneTimeExpenseNoItemsParts)
            const oneTimeWithItems = prepareArray(oneTimeExpenseWithItemsParts)
            const subscription = prepareArray(subscriptionParts)
            const reimbursementNoItems = prepareArray(reimbursementNoItemsParts)
            const reimbursementWithItems = prepareArray(reimbursementWithItemsParts)
            const datevBookingToSend = prepareArray(datevBookingItems)

            const convertedParts = {
                info: {
                    oneTimeExpenseNoItems: oneTimeNoItems,
                    oneTimeExpenseWithItems: oneTimeWithItems,
                    subscription,
                    reimbursementNoItems,
                    reimbursementWithItems,
                },
                booking: datevBookingToSend,
            }

            const updatedCompany = await CompanyService.updateDatevInfo(companyId, convertedParts)
            dispatch(setCompany(updatedCompany))

            NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:settings.company.datev_info.title"), t("notification:settings.company.datev_info.message"))
            handleHide()
        } catch (err) {
            setIsLoading(false)
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:error"))
        }
    }
    const renderSelect = (defaultValue: DatevInformationEnum, setter: any, infoType: object, parts: DatevInfoObject) => (
        <Select getPopupContainer={getPopupAnchor()} defaultValue={defaultValue} onChange={setter} dropdownMatchSelectWidth={false}>
            {Object.values(infoType).map((part: DatevInformationEnum) => (
                <Select.Option disabled={isPartAlreadySelected(part, parts)} value={part}>
                    {t(`input:custom_datev_info.${part}`)}
                </Select.Option>
            ))}
            <Select.Option value="" label={t("input:custom_datev_info.not_set")}>
                {t("input:custom_datev_info.not_set")}
            </Select.Option>
        </Select>
    )

    const renderSelectRow = (object: DatevInfoObject, setter: Function, infoType: object) => {
        const numberOfFields = Object.keys(infoType).length
        if (numberOfFields < 6) {
            delete object.sixth
        }
        if (numberOfFields < 5) {
            delete object.fifth
        }

        if (numberOfFields < 4) {
            delete object.fourth
        }

        if (numberOfFields < 3) {
            delete object.fourth
            delete object.third
        }
        return (
            <Row gutter={[8, 8]}>
                {Object.keys(object).map((number) => (
                    <Col>{renderSelect(object[number as keyof DatevInfoObject]!, (value: any) => setter({ ...object, [number]: value }), infoType, object)}</Col>
                ))}
            </Row>
        )
    }

    return (
        <Modal
            title={t("label:custom_datev_modal")}
            visible={isShowing}
            maskClosable={false}
            onOk={form.submit}
            onCancel={handleHide}
            okText={t("action:save")}
            className={"ant-modal--large"}
            keyboard
            destroyOnClose
            confirmLoading={isLoading}
        >
            <Form form={form} layout="vertical" onFinish={onFormSubmit}>
                <Row className="mb-30">
                    <h3>{t("label:custom_datev_information")}</h3>
                    <span className="text-text">{t("info:custom_datev_info")}</span>
                </Row>
                <Form.Item>
                    <span className="title w-240 flex items-center mb-10">{t("input:custom_datev_info.one_time_no_items")}</span>
                    {renderSelectRow(oneTimeExpenseNoItemsParts, setOneTimeExpenseNoItemsParts, DatevOteWithoutItems)}
                </Form.Item>
                <Form.Item>
                    <span className="title w-240 flex items-center mb-10">{t("input:custom_datev_info.one_time_with_items")}</span>
                    {renderSelectRow(oneTimeExpenseWithItemsParts, setOneTimeExpenseWithItemsParts, DatevOteWithItems)}
                </Form.Item>
                <Form.Item>
                    <span className="title w-240 flex items-center mb-10">
                        {t("input:custom_datev_info.subscriptions")}
                        <Tooltip
                            getPopupContainer={getTooltipPopupContainer}
                            title={t("tooltips:datev.subscription")}
                            overlayStyle={{ minWidth: "320px" }}
                            className="align-middle ml-5"
                        >
                            <ExclamationCircleOutlined className="ml-5" />
                        </Tooltip>
                    </span>
                    {renderSelectRow(subscriptionParts, setSubscriptionParts, DatevSubscription)}
                </Form.Item>
                <Form.Item>
                    <span className="title w-240 flex items-center mb-10">
                        {t("input:custom_datev_info.reimbursement_no_items")}
                        <Tooltip
                            getPopupContainer={getTooltipPopupContainer}
                            title={t("tooltips:datev.reimbursement_items")}
                            overlayStyle={{ minWidth: "550px" }}
                            className="align-middle ml-5"
                        >
                            <ExclamationCircleOutlined className="ml-5" />
                        </Tooltip>
                    </span>
                    {renderSelectRow(reimbursementNoItemsParts, setReimbursementNoItemsParts, DatevReimbursementWithoutItems)}
                </Form.Item>
                <Form.Item>
                    <span className="title w-240 flex items-center mb-10">
                        {t("input:custom_datev_info.reimbursement_with_items")}
                        <Tooltip
                            getPopupContainer={getTooltipPopupContainer}
                            title={t("tooltips:datev.reimbursement_items")}
                            overlayStyle={{ minWidth: "550px" }}
                            className="align-middle ml-5"
                        >
                            <ExclamationCircleOutlined className="ml-5" />
                        </Tooltip>
                    </span>
                    {renderSelectRow(reimbursementWithItemsParts, setReimbursementWithItemsParts, DatevReimbursementWithItems)}
                </Form.Item>
                <Row className="mb-30">
                    <span className="text-text">{t("info:custom_datev_info_footer")}</span>
                </Row>
                <Divider></Divider>
                <Row className="mb-30">
                    <h3>{t("label:custom_datev_booking")}</h3>
                    <span className="text-text">{t("info:custom_datev_booking")}</span>
                </Row>
                <Form.Item label={t("input:custom_datev_info.all_expense")}>{renderSelectRow(datevBookingItems, setDatevBookingItems, DatevBookingTextEnum)}</Form.Item>
                <Row className="mb-30">
                    <span className="text-text">{t("info:custom_datev_booking_footer")}</span>
                </Row>
            </Form>
        </Modal>
    )
}

export default CustomDatevInformationModal
