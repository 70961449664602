import Icon from "@ant-design/icons"
import React from "react"

const menuTwoLinesSvg = () => (
    <svg width="14" height="6" viewBox="0 0 14 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.6667 0.5H0.333374V2.16667H13.6667V0.5ZM0.333374 5.5H13.6667V3.83333H0.333374V5.5Z" fill="#C3C3C3" />
    </svg>
)

const MenuTwoLinesIcon = (props: any) => <Icon component={menuTwoLinesSvg} {...props} />

export default MenuTwoLinesIcon
