/* global Numeral */
import { ColumnMappingEnum, UploadKindEnum } from "@finway-group/shared/lib/models"
import { CsvMatchingAmountOptionEnum } from "@finway-group/shared/lib/models/common/csvMatchingAmountOption.enum"
import { MAX_INPUT_LENGTH } from "@finway-group/shared/lib/utils/validators"
import { Alert, Button, Form, Input, Modal, Radio, Select, Switch, Table, Tooltip } from "antd"
import Dragger from "antd/lib/upload/Dragger"
import * as HttpStatus from "http-status"
import numeral from "numeral"
import React from "react"
import { useTranslation } from "react-i18next"
import { v4 as uuidv4 } from "uuid"

import { InfoCircleIcon } from "Components/icons"
import Loading from "Components/loading"
import { FileService, NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import TransactionService from "Shared/services/transaction.service"
import { getAuthorizationHeader } from "Shared/utils/getter.utils"
import { getTooltipPopupContainer, parseNumber } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"
import { CsvColumnMappingResponse } from "Shared/utils/matching.utils"

interface CsvMatchingModalInterface {
    isShowing: boolean
    onSubmit: () => void
    onCancel: () => void
}

const CsvMatchingModal: React.FC<CsvMatchingModalInterface> = ({ isShowing = false, onSubmit, onCancel }) => {
    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useStateIfMounted(false)
    const [isUploading, setIsUploading] = useStateIfMounted(false)
    const [csvUrl, setCsvUrl] = useStateIfMounted<string>("")
    const [columnMapping, setColumnMapping] = useStateIfMounted<Array<ColumnMappingEnum>>([])
    const [sampleRows, setSampleRows] = useStateIfMounted<Array<Array<string>>>([])
    const [amountOption, setAmountOption] = useStateIfMounted(CsvMatchingAmountOptionEnum.NEGATIVE)

    const [isSignChanged, setIsSignChanged] = useStateIfMounted<boolean>(false)
    const [csvName, setCsvName] = useStateIfMounted<string>("")

    const shouldDisplayTable = columnMapping.length > 0 && sampleRows.length > 0

    const onUploadCSV = (file: File) => {
        setIsUploading(true)
        FileService.upload(file, UploadKindEnum.CSV_MATCHING)
            .then((data) => {
                setCsvUrl(data.url)
                TransactionService.determineCSVMapping(data.url)
                    .then(({ columnMapping, firstSampleRows }: CsvColumnMappingResponse) => {
                        setIsUploading(false)
                        setColumnMapping(columnMapping)
                        setSampleRows(firstSampleRows)
                    })
                    .catch((err) => {
                        setIsUploading(false)

                        const status = err.response?.status
                        switch (status) {
                            case HttpStatus.BAD_REQUEST:
                                NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:matching.determine.title"))
                                break
                            default:
                                NotificationService.send(NotificationTypeEnum.ERROR, t("error:matching.determine.title"), t("error:matching.determine.message"))
                                break
                        }
                    })
            })
            .catch(() => {
                setIsUploading(false)
                NotificationService.send(NotificationTypeEnum.ERROR, t("error:file.upload.title"), t("error:file.upload.message"))
            })
    }

    const onHandleSubmit = () => {
        setIsLoading(true)
        TransactionService.determineCSVMatching(csvUrl, columnMapping, amountOption, csvName, isSignChanged)
            .then(({ data: { includesArchivedTransactions } }) => {
                NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:matching.title"), t("notification:matching.message"))
                if (includesArchivedTransactions)
                    NotificationService.send(
                        NotificationTypeEnum.WARNING,
                        t("notification:matching.hasArchivedTransactions.title"),
                        t("notification:matching.hasArchivedTransactions.message"),
                        10,
                    )

                handleHide()
                // will refresh the transaction list
                onSubmit()
            })
            .catch((err) => {
                setIsLoading(false)
                const status = err.response?.status
                switch (status) {
                    case HttpStatus.NOT_FOUND:
                        NotificationService.send(
                            NotificationTypeEnum.ERROR,
                            t("error:matching.determine.no_transactions_found.title"),
                            t("error:matching.determine.no_transactions_found.message"),
                        )
                        break
                    case HttpStatus.INTERNAL_SERVER_ERROR:
                        NotificationService.send(NotificationTypeEnum.ERROR, t("error:matching.determine.title"), t("error:matching.determine.message"))
                        break
                    default:
                        NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:matching.determine.title"))
                }
            })
    }

    const handleHide = () => {
        setIsLoading(false)
        setCsvUrl("")
        setColumnMapping([])
        setSampleRows([])
        onCancel()
    }

    const onUpdateMapping = (newValue: ColumnMappingEnum, index: number) => {
        setColumnMapping(
            columnMapping.map((currValue, currIndex) => {
                const descriptionCondition =
                    (newValue === ColumnMappingEnum.DESCRIPTION && currValue === ColumnMappingEnum.DESCRIPTION_WITH_IBAN) ||
                    (newValue === ColumnMappingEnum.DESCRIPTION_WITH_IBAN && currValue === ColumnMappingEnum.DESCRIPTION)

                if (currIndex === index) return newValue
                if (currValue === newValue || descriptionCondition) return ColumnMappingEnum.NONE
                return currValue
            }),
        )
    }

    const createMappingSelect = (index: number): JSX.Element => (
        <Select value={columnMapping[index]} className="w-full" dropdownMatchSelectWidth={false} bordered={false} onSelect={(value) => onUpdateMapping(value, index)}>
            <Select.Option value={ColumnMappingEnum.AMOUNT}>{t("input:matching.csv.mapping.amount")}</Select.Option>
            <Select.Option value={ColumnMappingEnum.DATE}>{t("input:matching.csv.mapping.date")}</Select.Option>
            <Select.Option value={ColumnMappingEnum.DESCRIPTION}>{t("input:matching.csv.mapping.description")}</Select.Option>
            <Select.Option value={ColumnMappingEnum.DESCRIPTION_WITH_IBAN}>{t("input:matching.csv.mapping.description_and_iban")}</Select.Option>
            <Select.Option value={ColumnMappingEnum.IBAN}>{t("input:matching.csv.mapping.iban")}</Select.Option>
            <Select.Option value={ColumnMappingEnum.VENDOR}>{t("input:matching.csv.mapping.vendor")}</Select.Option>
            <Select.Option value={ColumnMappingEnum.CURRENCY}>{t("input:matching.csv.mapping.currency")}</Select.Option>
            <Select.Option value={ColumnMappingEnum.NONE}>{t("input:matching.csv.mapping.none")}</Select.Option>
        </Select>
    )

    const renderCsvTable = () => {
        const columns = [...Array(columnMapping.length)].map((_column, index) => ({
            title: createMappingSelect(index),
            ellipsis: true,
            render: (record: Array<string>) => {
                const value = record[index]
                if (columnMapping[index] !== ColumnMappingEnum.AMOUNT) return value

                const parsedAmount = parseNumber(value)
                if (isNaN(parsedAmount)) return value

                const amount: Numeral = numeral(parsedAmount)
                if (isSignChanged) amount.multiply(-1)
                return amount.value()
            },
            className: "min-w-100 w-full max-w-300",
        }))

        return (
            <div className="overflow-auto p-2">
                <Form layout="vertical">
                    <div>
                        <Form.Item
                            className="w-500"
                            label={
                                <>
                                    {t("input:matching.csv.csv_name")}
                                    <Tooltip className="ml-8" title={t("tooltips:csv_transaction_import.csv_name")} getPopupContainer={getTooltipPopupContainer} placement="right">
                                        <InfoCircleIcon />
                                    </Tooltip>
                                </>
                            }
                        >
                            <Input maxLength={MAX_INPUT_LENGTH} onChange={(e) => setCsvName(e.target.value)} />
                        </Form.Item>
                    </div>
                    <div className="flex justify-start gap-36 content-end">
                        <Form.Item label={t("input:matching.csv.amount_option.title")}>
                            <Radio.Group onChange={(e) => setAmountOption(e.target.value)} value={amountOption}>
                                <Radio value={CsvMatchingAmountOptionEnum.NEGATIVE}>{t(`input:matching.csv.amount_option.${CsvMatchingAmountOptionEnum.NEGATIVE}`)}</Radio>
                                <Radio value={CsvMatchingAmountOptionEnum.POSITIVE}>{t(`input:matching.csv.amount_option.${CsvMatchingAmountOptionEnum.POSITIVE}`)}</Radio>
                                <Radio value={CsvMatchingAmountOptionEnum.ALL}>{t(`input:matching.csv.amount_option.${CsvMatchingAmountOptionEnum.ALL}`)}</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item label=" " className="w-500">
                            <div className="flex gap-8">
                                <span> {t("input:matching.csv.change_sign")}</span>
                                <Tooltip title={t("tooltips:csv_transaction_import.change_sign")} placement="right" getPopupContainer={getTooltipPopupContainer}>
                                    <InfoCircleIcon className="mt-4" />
                                </Tooltip>
                                <Switch onChange={setIsSignChanged} checked={isSignChanged} />
                            </div>
                        </Form.Item>
                    </div>
                </Form>
                <Alert type="warning" showIcon closable message={t("info:matching.csv_mapping_verify")} description={t("info:matching.csv_mapping_verify_description")} />
                <Table rowKey={() => uuidv4()} className="mt-20" dataSource={sampleRows} columns={columns} pagination={false} tableLayout="auto" />
            </div>
        )
    }

    return (
        <Modal
            destroyOnClose={true}
            visible={isShowing}
            maskClosable={false}
            title={t("action:matching.csv.title")}
            onCancel={handleHide}
            closable={true}
            keyboard={true}
            className={columnMapping.length > 8 ? "ant-modal--full-csv" : "ant-modal--full"}
            footer={[
                <Button key="back" onClick={handleHide}>
                    {t("action:cancel")}
                </Button>,

                <Button key="submit" type="primary" disabled={columnMapping.length === 0 || sampleRows.length === 0} loading={isLoading} onClick={onHandleSubmit}>
                    {t("action:matching.csv.submit")}
                </Button>,
            ]}
        >
            {isUploading ? (
                <Loading className="h-150 mb-20" />
            ) : shouldDisplayTable ? (
                renderCsvTable()
            ) : (
                <Dragger headers={getAuthorizationHeader()} showUploadList={false} customRequest={(req) => onUploadCSV(req.file)} accept=".csv">
                    <i className="el-icon-upload"></i>
                    <div className="el-upload__text">
                        <Button>{t("action:matching.upload_csv")}</Button>
                        <br />
                        <small>{t("action:or_drop_files")}</small>
                    </div>
                </Dragger>
            )}
        </Modal>
    )
}

export default CsvMatchingModal
