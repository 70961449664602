import Icon from "@ant-design/icons"
import React from "react"

const TripSvg = () => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12.3746 7.875L10.8277 5.0625C10.7443 4.90066 10.6199 4.76354 10.4669 4.66484C10.3139 4.56613 10.1377 4.50929 9.95586 4.5H3.17773C2.89648 4.5 2.61523 4.78125 2.44648 5.0625C2.27773 5.34375 0.787109 8.15625 0.787109 8.15625"
            stroke="#03BC9D"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M15.75 11.8125H17.4375V8.71875C17.4375 8.49497 17.3486 8.28036 17.1904 8.12213C17.0321 7.96389 16.8175 7.875 16.5937 7.875H1.40625C1.18247 7.875 0.967862 7.96389 0.809629 8.12213C0.651395 8.28036 0.5625 8.49497 0.5625 8.71875V11.8125H2.25M5.625 11.8125H12.375"
            stroke="#03BC9D"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M3.9375 13.5C4.86948 13.5 5.625 12.7445 5.625 11.8125C5.625 10.8805 4.86948 10.125 3.9375 10.125C3.00552 10.125 2.25 10.8805 2.25 11.8125C2.25 12.7445 3.00552 13.5 3.9375 13.5Z"
            stroke="#03BC9D"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M14.0625 13.5C14.9945 13.5 15.75 12.7445 15.75 11.8125C15.75 10.8805 14.9945 10.125 14.0625 10.125C13.1305 10.125 12.375 10.8805 12.375 11.8125C12.375 12.7445 13.1305 13.5 14.0625 13.5Z"
            stroke="#03BC9D"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)

const TripIcon = (props: any) => <Icon component={TripSvg} {...props} />

export default TripIcon
