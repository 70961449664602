import Icon from "@ant-design/icons"
import React from "react"

const lastNotificationIcon = () => (
    <svg width="65" height="64" viewBox="0 0 65 64" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M65.0002 56.6299C65.0002 60.3293 50.46 63.3281 32.524 63.3281C14.5878 63.3281 0.0478516 60.3293 0.0478516 56.6299C0.0478516 52.9304 14.5878 49.9316 32.524 49.9316C50.46 49.9316 65.0002 52.9304 65.0002 56.6299Z"
            fill="#E9E9E9"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M56.2476 22.3274H13.4443V9.81972C13.4443 4.39649 17.826 0 23.2316 0H47.4996C52.9052 0 57.2872 4.39649 57.2872 9.81972V21.2847C57.2872 21.8605 56.8219 22.3274 56.2476 22.3274Z"
            fill="#ACCBC3"
        />
        <path fillRule="evenodd" clipRule="evenodd" d="M56 20H12V9.55714C12 4.27879 16.3973 0 21.8223 0H46.1773C51.6023 0 56 4.27879 56 9.55714V20Z" fill="#6FD6BC" />
        <path fillRule="evenodd" clipRule="evenodd" d="M21.5 1C16.8057 1 13 4.75943 13 9.3973V20H30V9.3973C30 4.75943 26.1946 1 21.5 1Z" fill="#EFFBF8" />
        <path fillRule="evenodd" clipRule="evenodd" d="M12.2266 9.33789V9.74384C12.2266 9.60789 12.2322 9.47308 12.2371 9.33789H12.2266Z" fill="#E7EAF0" />
        <path fillRule="evenodd" clipRule="evenodd" d="M54.8625 9.33789H54.8516C54.8564 9.47308 54.8625 9.60789 54.8625 9.74384V9.33789Z" fill="#E7EAF0" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M46.0087 0H22.0465C22.0206 0 21.9953 0.00164359 21.9697 0.00197231C27.0373 0.0433908 31.1332 4.17209 31.1332 9.2613V9.3369H55.6638C55.4801 4.14941 51.2291 0 46.0087 0Z"
            fill="#DFF3EE"
        />
        <path fillRule="evenodd" clipRule="evenodd" d="M56 23H7.87903C7.39321 23 7 22.3283 7 21.4997C7 20.6711 7.39321 20 7.87903 20H56V23Z" fill="#6FD6BC" />
        <path fillRule="evenodd" clipRule="evenodd" d="M31.3066 56.8318H34.9602V21.9199H31.3066V56.8318Z" fill="#DFF3EE" />
        <path fillRule="evenodd" clipRule="evenodd" d="M32.5234 56.8318H34.9592V21.9199H32.5234V56.8318Z" fill="#ACCBC3" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M33.1334 54.3984C29.8825 54.3984 27.2471 55.7523 27.2471 57.4222C27.2471 59.0921 29.8825 59.2699 33.1334 59.2699C36.3843 59.2699 39.0197 59.0921 39.0197 57.4222C39.0197 55.7523 36.3843 54.3984 33.1334 54.3984Z"
            fill="#ACCBC3"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M37.4196 24.3556H28.8468C27.9632 24.3556 27.2471 23.6889 27.2471 22.8659V21.9199H39.0197V22.8659C39.0197 23.6889 38.3032 24.3556 37.4196 24.3556Z"
            fill="#DFF3EE"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M31.5236 58.2377C32.7102 56.8658 32.5166 55.273 32.5074 54.3984C29.2347 54.3984 27.2471 55.6861 27.2471 57.2882C27.2471 58.1564 28.0264 58.6041 29.2614 58.8321C29.2614 58.8321 30.8029 59.0713 31.5236 58.2377Z"
            fill="#DFF3EE"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M43.4502 14.8033C43.2345 16.0171 42.0752 16.8263 40.8614 16.6103C39.6479 16.3946 38.8384 15.2356 39.0544 14.0218C39.2701 12.808 40.4291 11.9988 41.6432 12.2145C42.857 12.4305 43.6659 13.5895 43.4502 14.8033Z"
            fill="#DFF3EE"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M43.0126 14.494C42.7436 15.4661 41.737 16.0356 40.7652 15.7666C39.7931 15.4973 39.2232 14.4911 39.4925 13.519C39.7616 12.547 40.7681 11.9771 41.7403 12.2464C42.712 12.5158 43.2819 13.522 43.0126 14.494Z"
            fill="#60CDB2"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M49.8927 3.03417L44.8033 1.65424C44.3671 1.53568 43.9109 1.78854 43.7898 2.21585L43.0912 4.68559L42.7633 5.84512L40.2373 14.7728L41.1539 15.0214L43.6796 6.09348L48.6459 7.43999C49.8838 7.77574 51.1765 7.05895 51.5195 5.84737C51.8624 4.63547 51.1302 3.3696 49.8927 3.03417Z"
            fill="url(#paint0_linear_10445_71389)"
        />
        <path fillRule="evenodd" clipRule="evenodd" d="M27 23H39V22H27V23Z" fill="#DFF3EE" />
        <defs>
            <linearGradient id="paint0_linear_10445_71389" x1="45.9206" y1="-5.07321" x2="32.703" y2="6.14173" gradientUnits="userSpaceOnUse">
                <stop stopColor="#F42A68" />
                <stop offset="1" stopColor="#F37FA3" />
            </linearGradient>
        </defs>
    </svg>
)

const LastNotificationIcon = (props: any) => <Icon component={lastNotificationIcon} {...props} />

export default LastNotificationIcon
