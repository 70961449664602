import { BankAccount, BankConnection } from "@finway-group/shared/lib/models"
import { Col, Form, Radio, Row, Select } from "antd"
import { FormInstance } from "antd/lib/form"
import { Store } from "antd/lib/form/interface"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

const { Option } = Select

interface BankConnectionFormInterface {
    bankConnection: BankConnection | undefined
    formInstance: FormInstance
    onSubmit: (isDefault: boolean) => void
}

const BankConnectionForm: React.FC<BankConnectionFormInterface> = ({ bankConnection, formInstance, onSubmit }) => {
    const { t } = useTranslation()
    const [isDefault, setIsDefault] = useState(bankConnection?.isDefault)
    const defaultAccountId = bankConnection?.accounts.find((acc) => acc.isDefault)?._id

    useEffect(() => {
        if (bankConnection) {
            formInstance.setFieldsValue({ defaultAccountId })
        }
    }, [bankConnection])

    const onFinish = () => onSubmit(isDefault || false)

    return (
        <Form form={formInstance} layout="vertical" onFinish={onFinish}>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Form.Item label={t("input:banking.default_connection")}>
                        <Radio.Group value={isDefault} onChange={(e) => setIsDefault(e.target.value)}>
                            <Radio value={true}>{t("input:banking.yes")}</Radio>
                            <Radio value={false}>{t("input:banking.no")}</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    )
}

export default BankConnectionForm
