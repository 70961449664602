import React from "react"

export const MapPinSingleIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <circle cx="12" cy="12" r="11.7472" fill="#3886FF" stroke="white" strokeWidth="0.505682" />
        <path
            d="M11.8228 17.6305L8.71902 12.0429C8.44996 11.5472 8.29688 10.9799 8.29688 10.377C8.29688 8.43856 9.87547 6.86719 11.8228 6.86719C13.7701 6.86719 15.3487 8.43856 15.3487 10.377C15.3487 10.9799 15.1956 11.5472 14.9266 12.0429L11.8228 17.6305Z"
            fill="white"
            stroke="white"
            strokeWidth="0.727273"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M11.8225 12.0669C12.7449 12.0669 13.4927 11.3191 13.4927 10.3967C13.4927 9.47432 12.7449 8.72656 11.8225 8.72656C10.9001 8.72656 10.1523 9.47432 10.1523 10.3967C10.1523 11.3191 10.9001 12.0669 11.8225 12.0669Z"
            fill="#3886FF"
        />
    </svg>
)
