import { COLOR } from "@finway-group/shared/lib/consts"
import { Viewer } from "@react-pdf-viewer/core"
import "@react-pdf-viewer/core/lib/styles/index.css"
import { RenderCurrentPageLabelProps, pageNavigationPlugin } from "@react-pdf-viewer/page-navigation"
import { RotateDirection } from "@react-pdf-viewer/rotate"
import { toolbarPlugin } from "@react-pdf-viewer/toolbar"
import "@react-pdf-viewer/toolbar/lib/styles/index.css"
import { Divider, Dropdown, InputNumber } from "antd"
import React, { useRef } from "react"
import {
    ArrowLeftCircle,
    ArrowRightCircle,
    Download as DownloadIcon,
    Maximize,
    Printer,
    RotateCcw,
    RotateCw,
    Search as SearchIcon,
    ZoomIn as ZoomInIcon,
    ZoomOut as ZoomOutIcon,
} from "react-feather"
import { useTranslation } from "react-i18next"

import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"
import { getPopupAnchor } from "Shared/utils/popup.utils"

import { customCanvasPlugin } from "./customCanvasPlugin"
import CustomSearchMenu from "./customSearchMenu"

export interface FileViewerProps {
    filePath: string
    showPaidStamp?: boolean
}

const FileViewer = ({ filePath, showPaidStamp = false }: FileViewerProps) => {
    const { t } = useTranslation()

    const [searchDropdownVisible, setSearchDropdownVisible] = useStateIfMounted(false)
    const anchorRef = useRef(null)

    const pdfHeight = 900
    const toolBarIconColor = "#3F4246" // same as in figma

    const pageNavigationPluginInstance = pageNavigationPlugin()
    const { jumpToPage, CurrentPageLabel } = pageNavigationPluginInstance

    const toolbarPluginInstance = toolbarPlugin({})
    const { Toolbar } = toolbarPluginInstance

    const onChangePage = (value?: number) => {
        if (value) jumpToPage(value - 1)
    }

    const renderToolbar = (
        <div
            style={{
                alignItems: "center",
                backgroundColor: COLOR.white,
                borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                display: "flex",
                paddingBottom: "10px",
                width: "100%",
            }}
        >
            <Toolbar>
                {(slots) => {
                    const { Download, EnterFullScreen, GoToNextPage, GoToPreviousPage, Print, Zoom, ZoomIn, ZoomOut, Rotate, Search } = slots
                    return (
                        <div className="flex w-full justify-between">
                            <div className="flex min-w-1/4 flex-auto" ref={anchorRef}>
                                {/* Custom search box */}
                                <Search>
                                    {(props) => (
                                        <Dropdown
                                            overlay={() => <CustomSearchMenu props={props} setSearchDropdownVisible={setSearchDropdownVisible} />}
                                            placement="bottomLeft"
                                            visible={searchDropdownVisible}
                                            arrow
                                            getPopupContainer={getPopupAnchor(anchorRef.current)}
                                        >
                                            <span className="cursor-pointer self-center mx-5" onClick={() => setSearchDropdownVisible(true)}>
                                                <SearchIcon color={toolBarIconColor} size="18" />
                                            </span>
                                        </Dropdown>
                                    )}
                                </Search>

                                <Divider type="vertical" className="self-center" />

                                {/* Custom page navigation */}
                                <GoToPreviousPage>
                                    {(props) => (
                                        <span onClick={props.onClick} className="mx-5 cursor-pointer self-center">
                                            <ArrowLeftCircle color={toolBarIconColor} size="18" />
                                        </span>
                                    )}
                                </GoToPreviousPage>

                                <CurrentPageLabel>
                                    {(props: RenderCurrentPageLabelProps) => (
                                        <>
                                            <InputNumber
                                                className="w-50 self-center"
                                                size="small"
                                                min={1}
                                                max={props.numberOfPages}
                                                value={props.currentPage + 1}
                                                onChange={onChangePage}
                                            />
                                            <span className="ml-4 text-sm text-text self-center">{` /${props.numberOfPages}`}</span>
                                        </>
                                    )}
                                </CurrentPageLabel>

                                <GoToNextPage>
                                    {(props) => (
                                        <span onClick={props.onClick} className="cursor-pointer ml-10 self-center">
                                            <ArrowRightCircle color={toolBarIconColor} size="18 " />
                                        </span>
                                    )}
                                </GoToNextPage>

                                <Divider type="vertical" className="mx-10 self-center" />

                                {/* Custom rotation buttons  */}
                                <Rotate direction={RotateDirection.Backward}>
                                    {(props) => (
                                        <span onClick={props.onClick} className="mr-5 cursor-pointer self-center">
                                            <RotateCcw color={toolBarIconColor} size="18" />
                                        </span>
                                    )}
                                </Rotate>
                                <Rotate direction={RotateDirection.Forward}>
                                    {(props) => (
                                        <span onClick={props.onClick} className="cursor-pointer self-center">
                                            <RotateCw color={toolBarIconColor} size="18" />
                                        </span>
                                    )}
                                </Rotate>
                            </div>

                            <div className="flex flex-1 justify-center">
                                <Divider type="vertical" className="mx-10 self-center xs:hidden" />

                                {/* Custom zoom out button  */}
                                <ZoomOut>
                                    {(props) => (
                                        <span onClick={props.onClick} className="cursor-pointer self-center">
                                            <ZoomOutIcon color={toolBarIconColor} size="18" />
                                        </span>
                                    )}
                                </ZoomOut>
                                <span className="xs:inline-flex hidden">
                                    {" "}
                                    <Zoom></Zoom>
                                </span>

                                {/* Custom zoom in button  */}
                                <ZoomIn>
                                    {(props) => (
                                        <span onClick={props.onClick} className="cursor-pointer self-center">
                                            <ZoomInIcon color={toolBarIconColor} size="18" />
                                        </span>
                                    )}
                                </ZoomIn>
                                <Divider type="vertical" className="mx-10 self-center xs:hidden" />
                            </div>

                            <div className="flex  w-1/4 flex-auto flex-row-reverse">
                                {/* Custom print button  */}
                                <Print>
                                    {(props) => (
                                        <span onClick={props.onClick} className="cursor-pointer self-center mr-10">
                                            <Printer color={toolBarIconColor} size="18" />
                                        </span>
                                    )}
                                </Print>

                                <Divider type="vertical" className="mx-10 self-center" />

                                {/* Custom download button  */}
                                <Download>
                                    {(props) => (
                                        <span onClick={props.onClick} className="cursor-pointer self-center">
                                            <DownloadIcon color={toolBarIconColor} size="18" />
                                        </span>
                                    )}
                                </Download>

                                <Divider type="vertical" className="mx-10 self-center" />

                                {/* Custom full screen button  */}
                                <EnterFullScreen>
                                    {(props) => (
                                        <span onClick={props.onClick} className="cursor-pointer self-center">
                                            <Maximize color={toolBarIconColor} size="18" />
                                        </span>
                                    )}
                                </EnterFullScreen>
                            </div>
                        </div>
                    )
                }}
            </Toolbar>
        </div>
    )

    if (!filePath) return null

    return (
        <div
            style={{
                border: "none",
                display: "flex",
                flexDirection: "column",
                height: pdfHeight,
                width: "100%",
            }}
        >
            {renderToolbar}
            <div
                style={{
                    flex: 1,
                    overflow: "hidden",
                }}
            >
                {/* https://github.com/react-pdf-viewer/react-pdf-viewer/issues/1076
                ugly hack to mitigate the re-rendering issue. TODO replace it after getting an answer from the library maintainer */}
                <Viewer
                    defaultScale={1}
                    fileUrl={showPaidStamp ? filePath : ` ${filePath}`}
                    plugins={[toolbarPluginInstance, pageNavigationPluginInstance, ...(showPaidStamp ? [customCanvasPlugin(t("label:paid"))] : [])]}
                />
            </div>
        </div>
    )
}

export default FileViewer
