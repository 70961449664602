import { textMultipleWhiteSpace, textNoWhiteSpacesRegex } from "@finway-group/shared/lib/utils/validators"
import { Rule } from "antd/lib/form"
import i18n from "i18next"

import { doesContainForbiddenChars } from "Shared/utils/helper.utils"
import validateEmail from "Shared/validators/email.validator"

export const UserRules = () => ({
    firstName: [
        {
            required: true,
            validator: (_rule: Rule, value: string) => {
                if (!value) return Promise.reject(i18n.t("validation:required"))

                if (doesContainForbiddenChars(value) && value !== "") {
                    return Promise.reject(i18n.t("validation:name"))
                }

                if (value !== value.trim()) {
                    return Promise.reject(new Error(i18n.t("validation:string_white_space_start_or_end")))
                }

                if (textMultipleWhiteSpace.test(value)) {
                    return Promise.reject(new Error(i18n.t("validation:string_white_space_duplicate")))
                }

                return Promise.resolve()
            },
        },
    ],
    lastName: [
        {
            required: true,
            validator: (_rule: Rule, value: string) => {
                if (!value) return Promise.reject(i18n.t("validation:required"))

                if (doesContainForbiddenChars(value) && value !== "") {
                    return Promise.reject(i18n.t("validation:name"))
                }

                if (value !== value.trim()) {
                    return Promise.reject(new Error(i18n.t("validation:string_white_space_start_or_end")))
                }

                if (textMultipleWhiteSpace.test(value)) {
                    return Promise.reject(new Error(i18n.t("validation:string_white_space_duplicate")))
                }

                return Promise.resolve()
            },
        },
    ],
    email: [
        {
            required: true,
            validator: (_rule: any, value: string) => {
                if (!value) return Promise.reject(i18n.t("validation:required"))

                if (value !== value.trim()) {
                    return Promise.reject(new Error(i18n.t("validation:string_white_space_start_or_end")))
                }

                if (!textNoWhiteSpacesRegex.test(value)) {
                    return Promise.reject(new Error(i18n.t("validation:string_white_space")))
                }

                if (!validateEmail(value) && value) {
                    return Promise.reject(new Error(i18n.t("validation:email")))
                }

                return Promise.resolve()
            },
        },
    ],
})
