import { Avatar } from "antd"
import React from "react"

import { LOGO_API } from "Shared/config/consts"

const personioLogo = `${LOGO_API}/personio.com`

const PersonioTag = ({ ...props }) => <Avatar {...props} size="small" icon={<img src={personioLogo} alt="Imported from Personio" />} className="ml-10" />

export default PersonioTag
