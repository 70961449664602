import { ExpenseAccount } from "@finway-group/shared/lib/models"
import { isExpenseAccountCodeDATEVCompliant } from "@finway-group/shared/lib/utils/datevValidation"
import { MAX_INPUT_LENGTH } from "@finway-group/shared/lib/utils/validators"
import { Button, Col, Input, Row } from "antd"
import Form, { FormInstance } from "antd/lib/form"
import { Store } from "antd/lib/form/interface"
import React, { useState } from "react"
import { Plus as PlusIcon } from "react-feather"
import { useTranslation } from "react-i18next"

import { WARNING_ORANGE } from "Shared/config/consts"
import { isSet } from "Shared/utils/helper.utils"

import ExpenseAccountRules from "./rules/expenseAccount.rules"

interface ExpenseAccountFormInterface {
    formInstance: FormInstance
    onSubmit: (values: Store) => void
    expenseAccounts: Array<ExpenseAccount>
    isEdit: boolean
}
const ExpenseAccountForm: React.FC<ExpenseAccountFormInterface> = ({ formInstance, onSubmit, isEdit, expenseAccounts }) => {
    const { t } = useTranslation()
    const { accountName, accountCode } = ExpenseAccountRules(formInstance, isEdit)
    const [expenseAccountCodes, setExpenseAccountCodes] = useState(expenseAccounts.map((expenseAccount: ExpenseAccount) => expenseAccount.accountCode))

    const onUpdateExpenseAccountCode = (value: string, index: number) => {
        setExpenseAccountCodes([...expenseAccountCodes.slice(0, index), value, ...expenseAccountCodes.slice(index + 1)])
    }

    return (
        <Form form={formInstance} layout="vertical" onFinish={onSubmit} autoComplete="off">
            <Form.List name="expenseAccounts">
                {(fields, { add }) => (
                    <div>
                        {fields.map((field, index) => (
                            <Row key={index} gutter={[8, 8]} align="top">
                                <Col span={11} offset={0}>
                                    <Form.Item name={[field.name, "accountName"]} label={t("input:expense_account.account_name")} key="accountName" rules={accountName} required>
                                        <Input maxLength={MAX_INPUT_LENGTH} placeholder={t("placeholder:expense_account.account_name")} />
                                    </Form.Item>
                                </Col>

                                <Col span={10} offset={0}>
                                    <Form.Item
                                        name={[field.name, "accountCode"]}
                                        label={t("input:expense_account.account_code")}
                                        key="accountCode"
                                        rules={accountCode}
                                        required
                                        shouldUpdate
                                        extra={
                                            isSet(expenseAccountCodes[index]) &&
                                            !isExpenseAccountCodeDATEVCompliant(expenseAccountCodes[index]) && (
                                                <span className="-ml-4 text-base" style={{ color: WARNING_ORANGE }}>
                                                    {t("validation:expense_account_code_validation")}
                                                </span>
                                            )
                                        }
                                    >
                                        <Input
                                            onChange={(value) => onUpdateExpenseAccountCode(value.target.value, index)}
                                            placeholder={t("placeholder:expense_account.account_code")}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        ))}

                        {!isEdit && (
                            <Row>
                                <Button
                                    className="mb-20"
                                    type="dashed"
                                    onClick={() => {
                                        add()
                                    }}
                                    block
                                >
                                    <PlusIcon /> {t("action:expense_account.add")}
                                </Button>
                            </Row>
                        )}
                    </div>
                )}
            </Form.List>
        </Form>
    )
}

export default ExpenseAccountForm
