import { ArrowLeftOutlined } from "@ant-design/icons"
import { Expense, ExpenseRequestTypeEnum, RightEnum } from "@finway-group/shared/lib/models"
import { roundNumberTo2Decimals } from "@finway-group/shared/lib/utils"
import { Card, Col, Row, Table } from "antd"
import { ColumnsType } from "antd/lib/table"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import EmptyTableData from "Components/emptyTableData"
import AmountColumn from "Components/expenseAmountColumn"
import { ErrorTable } from "Components/layout/errorTable"
import RequestColumn from "Components/requestColumn"
import TableButtons from "Components/tableButtons"
import ExpenseSourceSection from "Features/pages/expenses/expenseDetails/expenseSourceSection"
import MainDetailsSectionWrapper from "Features/pages/expenses/expenseDetails/mainDetailsSection.wrapper"
import { useCostCenters } from "Shared/hooks/costCenter.hooks"
import { useEmployees } from "Shared/hooks/employee.hooks"
import { useExpenseTags } from "Shared/hooks/expenseTags.hooks"
import { applyFilter } from "Shared/hooks/filter.hooks"
import { useFetchTable, useTable } from "Shared/hooks/table.hooks"
import { useLoggedInEmployeeProfile } from "Shared/hooks/user.hooks"
import { useCreditors } from "Shared/hooks/vendor.hooks"
import { AuthzService, CostCenter2Service, CostCenterService, EmployeeService, ExpenseHttpService } from "Shared/services"
import { refetchTable, updateTableOnChange } from "Shared/store/actions/tables/tableActions"
import { TablesEnum } from "Shared/store/reducers/tableConfigReducer"
import { insertIf, isNotSet } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"
import { getSortOrderForColumn } from "Shared/utils/table.utils"

import FilterModal from "../filter.modal"

interface InboxInvoiceModalExpenseSectionInterface {
    isShowing: boolean
    // TODO: fix cast to any
    selectedInvoice: any | undefined
    preSelectedExpense: Expense | undefined
    selectedExpense: Expense | undefined
    setSelectedExpense: (expense: Expense | undefined) => void
    setIsShowingDiscountForm?: React.Dispatch<React.SetStateAction<boolean>>
}

const InboxInvoiceModalExpenseSection: React.FC<InboxInvoiceModalExpenseSectionInterface> = ({
    isShowing,
    selectedInvoice: currentInboxInvoice,
    preSelectedExpense,
    selectedExpense,
    setSelectedExpense,
    setIsShowingDiscountForm,
}) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const loggedInUser = useLoggedInEmployeeProfile()

    const costCenters = useCostCenters(true)
    const employees = useEmployees({ excludeDeleted: true })
    const creditors = useCreditors(AuthzService.isRightGrantedForLoggedInUser(RightEnum.EMPLOYEE__ALL__READ), true, true)
    const expenseTags = useExpenseTags()

    const [isTransactionTableLoading, setIsTransactionTableLoading] = useStateIfMounted<boolean>(false)
    const expensesTable = useTable<Expense>(TablesEnum.INBOX_INVOICE_MODAL_EXPENSES)
    useFetchTable(TablesEnum.INBOX_INVOICE_MODAL_EXPENSES, currentInboxInvoice ? { inboxInvoiceId: currentInboxInvoice._id } : {})

    useEffect(() => {
        if (isShowing) {
            dispatch(refetchTable(TablesEnum.INBOX_INVOICE_MODAL_EXPENSES))
        }
    }, [isShowing, selectedExpense?.id, currentInboxInvoice?._id])

    const expenseColumns: ColumnsType<Expense> = [
        {
            title: t("label:request"),
            key: "expenseNumber",
            align: "left",
            className: "pl-10",
            ellipsis: true,
            width: 300,
            ...(loggedInUser.settings.showExpenseNumbers && {
                sorter: true,
                sortOrder: getSortOrderForColumn(expensesTable, "expenseNumber"),
            }),
            render: (data: Expense) => <RequestColumn request={data} />,
        },
        ...insertIf(!isNotSet(currentInboxInvoice), {
            key: "probability",
            title: t("label:probability_short"),
            ellipsis: true,
            width: 80,
            sorter: true,
            sortOrder: getSortOrderForColumn(expensesTable, "probability"),
            render: (expense: Expense) => <span>{roundNumberTo2Decimals(expense?.probability ?? 0)}%</span>,
        }),
        {
            title: loggedInUser.settings.showGrossAmount ? t("label:gross_amount") : t("label:net_amount"),
            key: loggedInUser.settings.showGrossAmount ? "totalGrossPrice" : "totalNetPrice",
            align: "right",
            width: 120,
            className: "pr-10",
            sorter: true,
            sortOrder: getSortOrderForColumn(expensesTable, loggedInUser.settings.showGrossAmount ? "totalGrossPrice" : "totalNetPrice"),
            render: (data: Expense) => <AmountColumn request={data} showGross={loggedInUser.settings.showGrossAmount} />,
        },
    ]

    return (
        <Col span={24} lg={12} className="px-5">
            {selectedExpense ? (
                <>
                    <Row className="mb-10">
                        {!preSelectedExpense && <ArrowLeftOutlined className="mr-10 mt-4" onClick={() => setSelectedExpense(undefined)} />}
                        <span className="text-base font-bold text-text-dark">{t("info:inbox_invoice.expense_details")}</span>
                    </Row>
                    <Row>
                        <Card className="w-full">
                            <MainDetailsSectionWrapper
                                expense={selectedExpense}
                                isMinimized={true}
                                showProbability={currentInboxInvoice !== undefined}
                                showLink={!preSelectedExpense}
                                setIsShowingDiscountForm={setIsShowingDiscountForm}
                            />
                        </Card>
                    </Row>
                    <Row>
                        <Col xs={24} md={24} lg={24}>
                            <div className="card-list">
                                <ExpenseSourceSection
                                    expense={selectedExpense}
                                    loggedInUser={loggedInUser}
                                    requestedBy={EmployeeService.getEmployeeById(selectedExpense.requestedBy._id)}
                                    costCenter={selectedExpense.costCenter ? CostCenterService.getCostCenterById(selectedExpense.costCenter) : undefined}
                                    costCenter2={selectedExpense.costCenter2 ? CostCenter2Service.getCostCenter2ById(selectedExpense.costCenter2) : undefined}
                                    requestType={ExpenseRequestTypeEnum.PURCHASE}
                                    isDeliveryNoteUploading={false}
                                    isTransactionTableLoading={isTransactionTableLoading}
                                    setSelectedExpense={setSelectedExpense}
                                />
                            </div>
                        </Col>
                    </Row>
                </>
            ) : (
                <>
                    <Row className="mb-10">
                        <span data-testid="documentsNeededExpenses" className="text-base font-bold text-text-dark">
                            {t("info:inbox_invoice.docs_needed_expenses")}
                        </span>
                    </Row>

                    <TableButtons
                        table={TablesEnum.INBOX_INVOICE_MODAL_EXPENSES}
                        injectFilterModal={(isVisible: boolean, onCancel: any) => (
                            <FilterModal
                                table={TablesEnum.INBOX_INVOICE_MODAL_EXPENSES}
                                options={{ expenseTags, costCenters, employees, creditors }}
                                isVisible={isVisible}
                                onCancel={onCancel}
                                onSubmit={(data) => applyFilter(TablesEnum.INBOX_INVOICE_MODAL_EXPENSES, data)}
                            />
                        )}
                    />

                    {
                        <div>
                            {expensesTable.error ? (
                                <ErrorTable onTableReload={() => dispatch(refetchTable(TablesEnum.INBOX_INVOICE_MODAL_EXPENSES))} isLoading={expensesTable.isFetching} />
                            ) : expensesTable.data.totalDocs === 0 && !expensesTable.isFetching ? (
                                <EmptyTableData searchString={expensesTable.data.search} title={t("info:empty_state.all.title")} message={t("info:empty_state.all.message")} />
                            ) : (
                                <div className="overflow-auto mb-24 mt-20 p-2">
                                    <Table
                                        data-testid="documentsNeededExpensesTable"
                                        rowKey={(record) => record.id}
                                        dataSource={expensesTable.data.docs}
                                        columns={expenseColumns}
                                        pagination={{
                                            position: ["bottomRight"],
                                            showSizeChanger: false,
                                            current: expensesTable.data.page,
                                            pageSize: expensesTable.data.limit,
                                            hideOnSinglePage: false,
                                            total: expensesTable.data.totalDocs,
                                            pageSizeOptions: ["5", "10", "20", "50", "100"],
                                        }}
                                        onChange={updateTableOnChange(TablesEnum.INBOX_INVOICE_MODAL_EXPENSES, expensesTable.data)}
                                        onRow={(record, _rowIndex) => ({
                                            onClick: (_event) => {
                                                // Requires an extra fetch as the current expense item in redux does not refer to the one selected from the inbox invoice table
                                                setIsTransactionTableLoading(true)
                                                ExpenseHttpService.fetchOneExpense(record.id)
                                                    .then((expense: Expense) => {
                                                        // enrich expense with probability
                                                        expense.probability = expensesTable.data.docs.find((otherExpense) => expense.id === otherExpense.id)?.probability ?? 0
                                                        setSelectedExpense(expense)
                                                    })
                                                    .finally(() => setIsTransactionTableLoading(false))
                                            },
                                        })}
                                    />
                                </div>
                            )}
                        </div>
                    }
                </>
            )}
        </Col>
    )
}

export default InboxInvoiceModalExpenseSection
