import Icon from "@ant-design/icons"
import React from "react"

const TickGreenCircledComponent = ({ size = 24 }) => (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="12" r="12" fill="#F0FFFB" />
        <path
            d="M9.73333 14.0667L7.4 11.7333C7.14227 11.4756 6.7244 11.4756 6.46667 11.7333C6.20893 11.9911 6.20893 12.4089 6.46667 12.6667L9.73333 15.9333L17.2667 8.4C17.5244 8.14227 17.5244 7.7244 17.2667 7.46667C17.0089 7.20893 16.5911 7.20893 16.3333 7.46667L9.73333 14.0667Z"
            fill="#105816"
        />
    </svg>
)

const TickGreenCircled = (props: any) => <Icon component={() => <TickGreenCircledComponent size={props.size} />} {...props} />

export default TickGreenCircled
