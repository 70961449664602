import { Alert } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"

import { useImportInProgress } from "Shared/hooks/import.hooks"

const SpreadsheetImportInProgressBanner: React.FC = () => {
    const { t } = useTranslation()
    const spreadsheetDataimportInProgress = useImportInProgress()

    if (!spreadsheetDataimportInProgress) return null

    return <Alert className="mb-20 animation-pulse" message={t("info:data_import_in_progress")} type="info" showIcon={true} />
}

export default SpreadsheetImportInProgressBanner
