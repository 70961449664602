import "@react-pdf-viewer/core/lib/styles/index.css"
import { RenderSearchProps } from "@react-pdf-viewer/search"
import "@react-pdf-viewer/toolbar/lib/styles/index.css"
import { Button, Checkbox, Input, Tooltip } from "antd"
import React from "react"
import { ChevronDown, ChevronUp } from "react-feather"
import { useTranslation } from "react-i18next"

import { getTooltipPopupContainer } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

interface CustomSearchMenuInterface {
    props: RenderSearchProps
    setSearchDropdownVisible: (value: React.SetStateAction<boolean>) => void
}

const CustomSearchMenu: React.FC<CustomSearchMenuInterface> = ({ props, setSearchDropdownVisible }) => {
    const { t } = useTranslation()

    const [searchInput, setSearchInput] = useStateIfMounted("")
    const toolBarIconColor = "#3F4246" // same as in figma

    const onSearchClose = (props: RenderSearchProps) => {
        setSearchDropdownVisible(false)
        setSearchInput("")
        props.clearKeyword()
    }

    const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>, props: RenderSearchProps) => {
        props.setKeyword(e.target.value)
        setSearchInput(e.target.value)
    }

    return (
        <div className="min-w-200 bg-white space-y-10 border border-sky-500 hover:opacity-100 opacity-75">
            <div className="p-10">
                <Input
                    value={searchInput}
                    addonAfter={`${props.currentMatch} / ${props.numberOfMatches}`}
                    onPressEnter={() => props.search()}
                    onChange={(e) => onSearchInputChange(e, props)}
                />
            </div>
            <div className="px-10">
                <Checkbox onChange={(e) => props.changeMatchCase(e.target.checked)}>{t("label:pdf_viewer.match_case")}</Checkbox>
            </div>
            <div className="px-10">
                <Checkbox onChange={(e) => props.changeWholeWords(e.target.checked)}>{t("label:pdf_viewer.whole_words")}</Checkbox>
            </div>
            <div className="flex flex-row justify-between p-10">
                <div className="flex flex-row">
                    <Tooltip getPopupContainer={getTooltipPopupContainer} title={t("label:pdf_viewer.previous_match")} placement="bottom">
                        <span onClick={props.jumpToPreviousMatch} className="mx-10 cursor-pointer self-center">
                            <ChevronUp color={toolBarIconColor} size="22" />
                        </span>
                    </Tooltip>
                    <Tooltip getPopupContainer={getTooltipPopupContainer} title={t("label:pdf_viewer.next_match")} placement="bottom">
                        <span onClick={props.jumpToNextMatch} className="mx-10 cursor-pointer self-center">
                            <ChevronDown color={toolBarIconColor} size="22" />
                        </span>
                    </Tooltip>
                </div>
                <div>
                    <Button onClick={() => onSearchClose(props)}>{t("label:pdf_viewer.close")}</Button>
                </div>
            </div>
        </div>
    )
}

export default CustomSearchMenu
