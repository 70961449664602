import { Form, Modal } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"

import ReportingFilterForm from "Components/forms/reportingFilterForm"
import { ReportingFilterModalInterface } from "Features/pages/reporting/reportingFilter.interface"

const ReportingFilterModal: React.FC<ReportingFilterModalInterface> = ({ isVisible, reportFilter, onSubmit, onCancel }) => {
    const { t } = useTranslation()
    const [formInstance] = Form.useForm()

    return (
        <Modal
            title={t("action:filter")}
            visible={isVisible}
            maskClosable={false}
            onOk={formInstance.submit}
            onCancel={() => onCancel(false)}
            okText={t("action:apply_filter")}
            cancelText={t("action:cancel")}
            destroyOnClose
        >
            <ReportingFilterForm formInstance={formInstance} filter={reportFilter} onSubmit={onSubmit} />
        </Modal>
    )
}

export default ReportingFilterModal
