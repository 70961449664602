import { TripFolder } from "@finway-group/shared/lib/models"
import moment from "moment"
import React from "react"
import { useTranslation } from "react-i18next"

import { ExpensePropertyDisplay } from "../auxiliary/expensePropertyDisplay"

interface FolderMainDetailComponentInterface {
    folder: TripFolder
}
const FolderMainDetailsComponent = ({ folder }: FolderMainDetailComponentInterface) => {
    const { t } = useTranslation()

    const startDateMoment = moment(folder.tripDate)
    const endDateMoment = moment(folder.returnDate)

    return (
        <div className="pr-16">
            <div className="grid grid-cols-2">
                <ExpensePropertyDisplay title={t("label:folder.description")} value={folder.description} className="w-7/12" />
                <ExpensePropertyDisplay title={t("label:folder.main_destination")} value={folder.destination} />
            </div>
            <div className="grid grid-cols-4 mt-24">
                <ExpensePropertyDisplay title={t("label:folder.start_date")} value={startDateMoment.format("DD/MM/YYYY")} />
                <ExpensePropertyDisplay title={t("label:folder.time")} value={startDateMoment.format("HH:mm")} />
                <ExpensePropertyDisplay title={t("label:folder.return_date")} value={endDateMoment.format("DD/MM/YYYY")} />
                <ExpensePropertyDisplay title={t("label:folder.time")} value={endDateMoment.format("HH:mm")} />
            </div>
        </div>
    )
}

export default FolderMainDetailsComponent
