import { Tag } from "antd"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"

interface SelectedCompanyPreviewInterface {
    name: string
    logo: string
    isRoot: boolean
}

const SIZE = 50
const IMAGE_SIZE = SIZE - 4

const SelectedCompanyPreview = ({ logo, name, isRoot = false }: SelectedCompanyPreviewInterface) => {
    const { t } = useTranslation()

    const getComanyNameInitials = useMemo(() => {
        const arr: any = name ? name.trim().split(" ") : ""
        return arr.slice(0, arr.length > 3 ? 3 : arr.length).map((word: string) => word[0].toUpperCase())
    }, [name])

    return (
        <div className="flex flex-row justify-between items-center">
            <div className={`w-${SIZE} h-${SIZE} border flex justify-center align-center self-center items-center mr-10 p-2 rounded-lg`}>
                {logo ? (
                    <img className={`max-w-${IMAGE_SIZE} max-h-${IMAGE_SIZE}`} src={logo} alt="logo" />
                ) : (
                    <span className={`placeholder-primary text-xl w-${SIZE} h-${SIZE} flex justify-center items-center`}>{getComanyNameInitials}</span>
                )}
            </div>
            <h2 className="text-xs sm:text-sm md:text-lg mb-0 whitespace-no-wrap">{name}</h2>
            {isRoot && <Tag className="ant-tag-green ml-4">{t("label:root_company")}</Tag>}
        </div>
    )
}

export default React.memo(SelectedCompanyPreview)
