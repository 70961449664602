import { CsvTemplate, ErrorCodeEnum, Expense, ExportFormatEnum } from "@finway-group/shared/lib/models"
import { DatevOnlineJobStatusEnum, isDuoJobPending } from "@finway-group/shared/lib/utils/datevOnlineJobStatus.enum"
import httpStatus from "http-status"
import React, { ReactElement } from "react"
import { useTranslation } from "react-i18next"

import SEPAExportConfirmationModal from "Components/modals/sepaExportConfirmation.modal"
import WaitingModal from "Components/modals/waiting.modal"
import { markExpenseAsDone, markMultipleExpensesAsDone } from "Shared/hooks/expense.hooks"
import { FileService, NotificationService } from "Shared/services"
import DialogService from "Shared/services/dialog.service"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

export type OnExportSignature = ({
    expensesArrayOrExpenseQuery,
    exportFormat,
    csvTemplate,
}: {
    expensesArrayOrExpenseQuery: Array<Expense> | string
    exportFormat: ExportFormatEnum
    csvTemplate?: CsvTemplate
}) => Promise<void>

interface ExpenseExportInterface {
    render: ({ onExport }: { onExport: OnExportSignature; isExporting: boolean }) => ReactElement
    onSuccess?: () => void
    onFinish?: () => void
    skipStatusUpdate?: boolean
}

export const ExpenseExport: React.FC<ExpenseExportInterface> = ({ render, onSuccess, onFinish, skipStatusUpdate = false }) => {
    const { t } = useTranslation()
    const [isExporting, setIsExporting] = useStateIfMounted(false)
    const [isSEPAModalVisible, setIsSEPAModalVisible] = useStateIfMounted(false)
    const [isNonSepaXml, setIsNonSepaXml] = useStateIfMounted(false)
    const [expensesToBeExported, setExpensesToBeExported] = useStateIfMounted<Array<Expense>>([])

    // Attention: There is no dispatch for refetching tables or updating expense dashboard counts here because the actions are dispatched in the socket service. (Exports are treated as BULK_UPDATE).

    const handleSEPAExport = async ({
        expenses,
        exportFormat,
        subjectLines,
    }: {
        expenses: Array<Expense>
        exportFormat: ExportFormatEnum.SEPA | ExportFormatEnum.NON_SEPA
        subjectLines: Record<string, string>
    }) => {
        await handleExport({ expensesArrayOrExpenseQuery: expenses, exportFormat, csvTemplate: undefined, subjectLines })
    }

    const handleDuoExport = async (expensesArrayOrExpenseQuery: Array<Expense> | string) => {
        try {
            const response = Array.isArray(expensesArrayOrExpenseQuery)
                ? await exportMultipleExpenses(expensesArrayOrExpenseQuery, ExportFormatEnum.DATEV_ONLINE)
                : await exportExpenseQuery(expensesArrayOrExpenseQuery, ExportFormatEnum.DATEV_ONLINE)

            if (!response) return

            const isOnlyPartiallyExported = response?.data?.filteredOutExpenseNumbers?.length
            const notificationString = `notification:request.async_duo_export_started${isOnlyPartiallyExported ? "_partial" : ""}`
            const notificationType = isOnlyPartiallyExported ? NotificationTypeEnum.WARNING : NotificationTypeEnum.SUCCESS
            // prettier-ignore
            NotificationService.send(notificationType, t(`${notificationString}.title`), t(`${notificationString}.message`, { expenses: response?.data?.filteredOutExpenseNumbers.join(", ") }), 0)
            onSuccess?.()
        } catch (err) {
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:file:export:title"))
        } finally {
            onFinish?.()
        }
    }

    const handleExport = async ({
        expensesArrayOrExpenseQuery,
        exportFormat,
        csvTemplate,
        subjectLines,
    }: {
        expensesArrayOrExpenseQuery: Array<Expense> | string
        exportFormat: ExportFormatEnum
        csvTemplate?: CsvTemplate
        subjectLines?: Record<string, string>
    }) => {
        try {
            if (exportFormat === ExportFormatEnum.MARK_AS_DONE) {
                if (Array.isArray(expensesArrayOrExpenseQuery) && expensesArrayOrExpenseQuery.length === 1)
                    await markExpenseAsDone((expensesArrayOrExpenseQuery as Array<Expense>)[0])
                else await markMultipleExpensesAsDone(expensesArrayOrExpenseQuery as Array<Expense>)
                return
            }

            setIsExporting(true)

            const response = Array.isArray(expensesArrayOrExpenseQuery)
                ? await exportMultipleExpenses(expensesArrayOrExpenseQuery, exportFormat, csvTemplate, subjectLines)
                : await exportExpenseQuery(expensesArrayOrExpenseQuery, exportFormat, csvTemplate, subjectLines)

            if (!response) return

            const isAsyncExport = response?.status === httpStatus.ACCEPTED

            if (response?.data?.hasAmountMismatch) {
                NotificationService.send(NotificationTypeEnum.WARNING, t("notification:banking:amounts_mismatch:title"), t("notification:banking:amounts_mismatch:message"), 10)
            }

            if (!isAsyncExport) FileService.downloadFileAsSignedUrl(response?.data.fileUrl)

            NotificationService.showNotificationOnExportSuccess(response)

            onSuccess?.()
        } catch (err) {
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:file:export:title"))
            if (err.response?.data?.errorCode === ErrorCodeEnum.INVALID_EXPENSE_AMOUNTS) {
                NotificationService.send(NotificationTypeEnum.WARNING, t("notification:banking:amounts_mismatch:title"), t("notification:banking:amounts_mismatch:message"), 10)
            }
        } finally {
            setIsExporting(false)
            onFinish?.()
        }
    }

    const exportMultipleExpenses = async (expenses: Array<Expense>, exportFormat: ExportFormatEnum, csvTemplate?: any, subjectLines?: Record<string, string>) => {
        const expenseIds = expenses.map((expense) => expense.id)

        if (exportFormat === ExportFormatEnum.DATEV_ONLINE) {
            // confirm duplicate DUO export
            const duplicateExport = expenses.filter(
                (exp: Expense) =>
                    exp.exportInformation.isExportedToDatevOnline ||
                    (exp.datevOnlineInformation?.status && isDuoJobPending(exp.datevOnlineInformation?.status as DatevOnlineJobStatusEnum)),
            )
            const duplicateData = duplicateExport.map(({ id, expenseNumber }: Expense) => ({ id, expenseNumber }))
            if (duplicateData.length > 0 && !(await DialogService.confirmDuplicateDatevOnlineExport(duplicateData))) return

            // confirm that the export was enqueued
            await DialogService.confirmQueuedExport()
        }

        const query = `&_id[in]=${expenseIds.join(",")}&format=${exportFormat}${exportFormat === ExportFormatEnum.CSV && csvTemplate?._id ? `&template=${csvTemplate?._id}` : ""}`
        return FileService.export(query, skipStatusUpdate, subjectLines)
    }

    const exportExpenseQuery = async (expenseQuery: string, exportFormat: ExportFormatEnum, csvTemplate?: any, subjectLines?: Record<string, string>) => {
        const query = `${expenseQuery}&format=${exportFormat}${exportFormat === ExportFormatEnum.CSV && csvTemplate?._id ? `&template=${csvTemplate?._id}` : ""}`
        return FileService.export(query, skipStatusUpdate, subjectLines)
    }

    const handleExportExpenses = async ({
        expensesArrayOrExpenseQuery,
        exportFormat,
        csvTemplate,
    }: {
        expensesArrayOrExpenseQuery: Array<Expense> | string
        exportFormat: ExportFormatEnum
        csvTemplate?: CsvTemplate
    }) => {
        if ([ExportFormatEnum.SEPA, ExportFormatEnum.NON_SEPA].includes(exportFormat) && Array.isArray(expensesArrayOrExpenseQuery)) {
            // handled separately bc of the subject lines
            setExpensesToBeExported(expensesArrayOrExpenseQuery)
            setIsNonSepaXml(exportFormat === ExportFormatEnum.NON_SEPA)
            setIsSEPAModalVisible(true)
        } else if (exportFormat === ExportFormatEnum.DATEV_ONLINE) {
            await handleDuoExport(expensesArrayOrExpenseQuery)
        } else {
            await handleExport({ expensesArrayOrExpenseQuery, exportFormat, csvTemplate })
        }
    }

    return (
        <>
            {render({ onExport: handleExportExpenses, isExporting })}
            <SEPAExportConfirmationModal
                isShowing={isSEPAModalVisible}
                isNonSepaXml={isNonSepaXml}
                expenses={expensesToBeExported}
                onSubmit={({ expensesToExport, exportFormat, subjectLines }) => handleSEPAExport({ expenses: expensesToExport, exportFormat, subjectLines })}
                onCancel={() => setIsSEPAModalVisible(false)}
            />
            <WaitingModal isVisible={isExporting} infoText={t("info:export_in_progress")} onCancel={() => setIsExporting(false)} />
        </>
    )
}
