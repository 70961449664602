import {
    Approval,
    ApprovalProcess,
    ApprovalScopeEnum,
    ApprovalStatusEnum,
    Company,
    ComparisonPositionEnum,
    Expense,
    ExpenseKindEnum,
    ExpensePaymentFlowInformationEnum,
    ExpenseStatusEnum,
    TripFolder,
} from "@finway-group/shared/lib/models"
import { ApprovalProcessBuildIssueEnum } from "@finway-group/shared/lib/models/approvalProcess/approvalProcessBuildIssueEnum"
import { ExpensePaymentOptionEnum } from "@finway-group/shared/lib/models/expense/expensePaymentOption.enum"
import {
    compareApprovalLevelOfApprovalProcesses,
    getApprovalProcess,
    getApprovalProcessInProgress,
    getNextPendingApprovalByStatus,
} from "@finway-group/shared/lib/utils/approvalProcess.utils"
import { flattenExpression } from "@finway-group/shared/lib/utils/logicalExpression.utils"

import { TODO_STATUSES } from "Shared/config/consts"
import i18n from "Shared/locales/i18n"

type ValueChange = { previousValue: any; currentValue: any }
export type ValuesChange = {
    totalNetPrice?: ValueChange
    totalGrossPrice?: ValueChange
    totalTaxPrice?: ValueChange
    taxRate?: ValueChange
    requestedBy?: ValueChange
    costCenter?: ValueChange
    costCenter2?: ValueChange
    vendor?: ValueChange
    paymentOption?: ValueChange
    paymentFlowInformation?: ValueChange
} & { [key: string]: ValueChange }

export const getStatusLabelForApprovalProcess = (status: ApprovalStatusEnum) => {
    switch (status) {
        case ApprovalStatusEnum.NOT_STARTED:
            return i18n.t("label:approval_process_status.not_started")
        case ApprovalStatusEnum.IN_PROGRESS:
            return i18n.t("label:approval_process_status.in_progress")
        case ApprovalStatusEnum.APPROVED:
            return i18n.t("label:approval_process_status.approved")
        case ApprovalStatusEnum.REJECTED:
            return i18n.t("label:approval_process_status.rejected")
        case ApprovalStatusEnum.SKIPPED:
            return i18n.t("label:approval_process_status.skipped")
    }
}

export const getRemainingApprovers = (approvalProcess: ApprovalProcess) => {
    const approvals: Array<Approval<string>> = []
    for (const step of approvalProcess.steps) {
        if (step.status === ApprovalStatusEnum.SKIPPED || step.status === ApprovalStatusEnum.APPROVED) continue
        const stepApprovals = flattenExpression(step.approvalExpression)
        const approvalsNotYetApprovedOrRejected = stepApprovals.filter((approval) => !approval.approvedBy && !approval.rejectedBy)
        approvals.push(...approvalsNotYetApprovedOrRejected)
    }
    return approvals
}

const shouldRecalculateApprovalProcessDueToAmountChange = (company: Company, valuesChange: ValuesChange) =>
    company.recalculateApprovalProcessesInProgress.amount && Object.keys(valuesChange).some((dep) => ["totalNetPrice", "totalGrossPrice", "totalTaxPrice", "taxRate"].includes(dep))

const shouldRecalculateApprovalProcessDueToOtherChange = (company: Company, valuesChange: ValuesChange) =>
    company.recalculateApprovalProcessesInProgress.other && Object.keys(valuesChange).some((dep) => ["requestedBy", "costCenter", "costCenter2", "vendor"].includes(dep))

const shouldRecalculateApprovalProcessDueToExpenseChange = (company: Company, valuesChange: ValuesChange) => {
    const shouldRecalculateDueToAmountChange = shouldRecalculateApprovalProcessDueToAmountChange(company, valuesChange)
    const shouldRecalculateDueToOtherChange = shouldRecalculateApprovalProcessDueToOtherChange(company, valuesChange)

    return shouldRecalculateDueToAmountChange || shouldRecalculateDueToOtherChange
}

const shouldRecalculateApprovalProcessDueToFolderAmountChange = (company: Company, valuesChange: ValuesChange) =>
    company.recalculateApprovalProcessesInProgress.folderAmount &&
    Object.keys(valuesChange).some((dep) => ["totalNetPrice", "totalGrossPrice", "totalTaxPrice", "taxRate"].includes(dep))

const shouldRecalculateApprovalProcessDueToFolderOtherChange = (company: Company, valuesChange: ValuesChange) =>
    company.recalculateApprovalProcessesInProgress.folderOther && Object.keys(valuesChange).some((dep) => ["requestedBy", "costCenter", "costCenter2"].includes(dep))

const shouldRecalculateApprovalProcessDueToFolderChange = (company: Company, valuesChange: ValuesChange) => {
    const shouldRecalculateDueToAmountChange = shouldRecalculateApprovalProcessDueToFolderAmountChange(company, valuesChange)
    const shouldRecalculateDueToOtherChange = shouldRecalculateApprovalProcessDueToFolderOtherChange(company, valuesChange)

    return shouldRecalculateDueToAmountChange || shouldRecalculateDueToOtherChange
}
const shouldRecalculateApprovalProcessDueToPaymentInformationChange = (paymentFlowInformation: ExpensePaymentFlowInformationEnum) =>
    paymentFlowInformation === ExpensePaymentFlowInformationEnum.ALREADY_APPROVED_AND_PAID || paymentFlowInformation === ExpensePaymentFlowInformationEnum.REPORTING_ONLY

const getValuesChange = (previousValues: any, currentValues: any) => {
    const valueChanges: ValuesChange = {}
    const objKeys = [...new Set(Object.keys(previousValues).concat(Object.keys(currentValues)))]
    for (let i = 0; i < objKeys.length; i++) {
        // Not strict operator on purpose, to not consider different null and undefined.
        if (previousValues[objKeys[i]] != currentValues[objKeys[i]]) {
            valueChanges[objKeys[i]] = { previousValue: previousValues[objKeys[i]], currentValue: currentValues[objKeys[i]] }
        }
    }
    return valueChanges
}

const shouldBuildPurchaseApprovalProcess = (expense: Expense, company: Company, valuesChange: ValuesChange) => {
    if (expense.status !== ExpenseStatusEnum.PURCHASE_PENDING) return false

    const purchaseApproval = getApprovalProcess(ApprovalScopeEnum.EXPENSE_PURCHASE, expense.approvalProcesses)

    if (!purchaseApproval || purchaseApproval?.status === ApprovalStatusEnum.NOT_STARTED) return true

    if (purchaseApproval?.status === ApprovalStatusEnum.IN_PROGRESS) {
        if (valuesChange.paymentOption?.currentValue === ExpensePaymentOptionEnum.SMART_CARD) return true // For purchase approval processes we want to always re-calculate it when payment option is changed to card, because we need to make sure the last approvers are all enrolled.

        if (expense.kind === ExpenseKindEnum.TRIP_FOLDER) {
            if (shouldRecalculateApprovalProcessDueToFolderChange(company, valuesChange)) return true
        } else if (shouldRecalculateApprovalProcessDueToExpenseChange(company, valuesChange)) {
            return true
        }

        const paymentFlowInformation = valuesChange.paymentFlowInformation?.currentValue
        if (shouldRecalculateApprovalProcessDueToPaymentInformationChange(paymentFlowInformation)) return true
    }

    return false
}

const shouldBuildInvoiceApprovalProcess = (expense: Expense, company: Company, valuesChange: ValuesChange, isPreApprovedSubscription = false) => {
    if (!TODO_STATUSES.includes(expense.status) && !isPreApprovedSubscription) return false

    const invoiceApproval = getApprovalProcess(ApprovalScopeEnum.EXPENSE_INVOICE, expense.approvalProcesses)

    if (!invoiceApproval || invoiceApproval?.status === ApprovalStatusEnum.NOT_STARTED) return true

    if (invoiceApproval?.status === ApprovalStatusEnum.APPROVED && isPreApprovedSubscription) return true

    if (invoiceApproval?.status === ApprovalStatusEnum.IN_PROGRESS) {
        if (expense.kind === ExpenseKindEnum.TRIP_FOLDER) {
            if (shouldRecalculateApprovalProcessDueToFolderChange(company, valuesChange)) return true
        } else if (shouldRecalculateApprovalProcessDueToExpenseChange(company, valuesChange)) {
            return true
        }

        const paymentFlowInformation = valuesChange.paymentFlowInformation?.currentValue
        if (shouldRecalculateApprovalProcessDueToPaymentInformationChange(paymentFlowInformation)) {
            return true
        }
    }

    return false
}

export const getNextPendingApproverId = (approvalProcesses: Array<ApprovalProcess>) => {
    const approvalProcessInProgress = getApprovalProcessInProgress(approvalProcesses)
    const nextApprovalInProgress = approvalProcessInProgress ? getNextPendingApprovalByStatus(approvalProcessInProgress, [ApprovalStatusEnum.IN_PROGRESS]) : undefined
    return nextApprovalInProgress?.approver._id
}

export const getNextApproverId = (approvalProcesses: Array<ApprovalProcess>, isPreApprovedSubscription = false) => {
    const statuses = [ApprovalStatusEnum.IN_PROGRESS, ApprovalStatusEnum.NOT_STARTED]
    const approvalProcessNotStartedOrInProgress = approvalProcesses.find(({ status }) => statuses.includes(status))
    const nextApprovalInProgress = approvalProcessNotStartedOrInProgress ? getNextPendingApprovalByStatus(approvalProcessNotStartedOrInProgress, statuses) : undefined
    if (nextApprovalInProgress) return nextApprovalInProgress.approver._id
    return undefined
}

export const getNextExpenseEmbeddedApprover = (approvalProcesses: Array<ApprovalProcess>) => {
    const statuses = [ApprovalStatusEnum.IN_PROGRESS, ApprovalStatusEnum.NOT_STARTED]
    const approvalProcessNotStartedOrInProgress = approvalProcesses.find(({ status }) => statuses.includes(status))
    const nextApprovalInProgress = approvalProcessNotStartedOrInProgress ? getNextPendingApprovalByStatus(approvalProcessNotStartedOrInProgress, statuses) : undefined
    if (nextApprovalInProgress) return nextApprovalInProgress.approver
    return undefined
}

export const getFirstNotSkippedApprover = (approvalProcess: ApprovalProcess) => {
    const step = approvalProcess.steps.find((step) => step.status !== ApprovalStatusEnum.SKIPPED)
    if (!step) return
    const approvals = flattenExpression(step.approvalExpression)
    return approvals[0].approver
}

/**
 * Sets an approval process back to its NOT_STARTED state.
 * - Removes all approvals.
 * - Resets all status to NOT_STARTED, except SKIPPED statuses.
 */
export const getResetApprovalProcessesCopy = (approvalProcesses: Array<ApprovalProcess>) => {
    const approvalProcessesCopy: Array<ApprovalProcess> = JSON.parse(JSON.stringify(approvalProcesses))
    for (const approvalProcessCopy of approvalProcessesCopy) {
        for (const step of approvalProcessCopy.steps) {
            const approvals = flattenExpression(step.approvalExpression)
            for (const approval of approvals) {
                approval.approvedBy = undefined
                approval.rejectedBy = undefined
                approval.dateProcessed = undefined
            }

            if (step.status !== ApprovalStatusEnum.SKIPPED) {
                step.status = ApprovalStatusEnum.NOT_STARTED
            }
        }

        approvalProcessCopy.status = ApprovalStatusEnum.NOT_STARTED
    }

    return approvalProcessesCopy
}

export const canApproveFolderBasedOnSubExpensesProgression = (folder: TripFolder, subExpenses: Array<Expense>, scope: ApprovalScopeEnum) => {
    const folderApprovalProcess = getApprovalProcess(scope, folder.approvalProcesses)
    if (!folderApprovalProcess) return false // folder has no approval process

    return subExpenses
        .map((subExpense) => {
            const expenseApprovalProcess = getApprovalProcess(ApprovalScopeEnum.EXPENSE_INVOICE, subExpense.approvalProcesses)
            if (expenseApprovalProcess && expenseApprovalProcess.status === ApprovalStatusEnum.IN_PROGRESS && expenseApprovalProcess?.workflow === folderApprovalProcess.workflow) {
                const approvalProcessComparison = compareApprovalLevelOfApprovalProcesses(folderApprovalProcess, expenseApprovalProcess)
                return approvalProcessComparison?.position === ComparisonPositionEnum.BEHIND
            }
            return false
        })
        .every((isLeftBehind) => !isLeftBehind)
}

export const getApprovalProcessChangeConfirmData = ({
    ocrConfirmation,
    folderAmountConfirmation,
    currentApprovalProcess,
    newApprovalProcess,
    detachedExpenseApprovalProcess,
}: {
    currentApprovalProcess: ApprovalProcess
    newApprovalProcess: ApprovalProcess
    folderAmountConfirmation?: boolean
    ocrConfirmation?: boolean
    detachedExpenseApprovalProcess?: ApprovalProcess
}) => {
    let title = i18n.t(`confirm:approval_process.recalculate_in_progress.title`)
    let message = i18n.t(`confirm:approval_process.recalculate_in_progress.message`)
    if (ocrConfirmation) {
        title = i18n.t(`confirm:approval_process.recalculate_in_progress.ocr_title`)
        message = i18n.t(`confirm:approval_process.recalculate_in_progress.ocr_message`)
    } else if (folderAmountConfirmation) {
        message = i18n.t(`confirm:approval_process.recalculate_in_progress.folder_amount_message`)
    }

    const approvalProcessChangeBlocks = [{ message, currentApprovalProcess, newApprovalProcess }]
    if (detachedExpenseApprovalProcess) {
        approvalProcessChangeBlocks.push({
            message: i18n.t(`confirm:approval_process.recalculate_in_progress.detached_expense_message`),
            currentApprovalProcess,
            newApprovalProcess: detachedExpenseApprovalProcess,
        })
    }
    return { title, approvalProcessChangeBlocks }
}

export const determineApprovalScopes = (
    updatedExpense: Expense,
    company: Company,
    otherData: { valuesChange?: ValuesChange; previousExpense?: Expense; isPreApprovedSubscription?: boolean } = {},
) => {
    if (!otherData.valuesChange && !otherData.previousExpense) {
        throw Error("buildNewApprovalProcessesIfApplicable requires either 'valuesChange' or 'previousExpense' arguments. ")
    }

    const scopes: Array<ApprovalScopeEnum> = []
    const valuesChange = otherData.valuesChange ?? getValuesChange(otherData.previousExpense, updatedExpense)
    if (shouldBuildPurchaseApprovalProcess(updatedExpense, company, valuesChange)) scopes.push(ApprovalScopeEnum.EXPENSE_PURCHASE)
    if (shouldBuildInvoiceApprovalProcess(updatedExpense, company, valuesChange, otherData.isPreApprovedSubscription)) scopes.push(ApprovalScopeEnum.EXPENSE_INVOICE)

    return scopes
}

export const buildIssueTranslationString = (buildIssue: ApprovalProcessBuildIssueEnum, namesString: string) => {
    switch (buildIssue) {
        case ApprovalProcessBuildIssueEnum.APPROVER_IS_REQUESTER:
            return i18n.t("info:approval_process.build_issue.approver_is_requester", { approver: namesString })
        case ApprovalProcessBuildIssueEnum.FALLBACK_APPROVER_IS_REQUESTER:
            return i18n.t("info:approval_process.build_issue.fallback_approver_is_requester", { approver: namesString })
        case ApprovalProcessBuildIssueEnum.FALLBACK_APPROVER_NOT_ENROLLED:
            return i18n.t("info:approval_process.build_issue.fallback_approver_not_enrolled", { approver: namesString })
        case ApprovalProcessBuildIssueEnum.FINAL_STEP_APPROVERS_NOT_ENROLLED:
            return i18n.t("info:approval_process.build_issue.final_step_approvers_not_enrolled", { approvers: namesString })
        case ApprovalProcessBuildIssueEnum.SUPERIOR_NOT_ENROLLED:
            return i18n.t("info:approval_process.build_issue.superior_not_enrolled", { approver: namesString })
        case ApprovalProcessBuildIssueEnum.ALL_STEPS_SKIPPED:
            return i18n.t("info:approval_process.build_issue.all_steps_skipped")
        case ApprovalProcessBuildIssueEnum.ESTIMATED_PAST_STEPS:
            return i18n.t("info:approval_process.build_issue.estimated_past_steps")
    }
}
