import { Col, Row } from "antd"
import React from "react"

import CostCenterBudgetChart from "./reportingDetails/costCenterBudgetChart"
import CostCenterPieChart from "./reportingDetails/costCenterPieChart"
import CostCenterProgressBarChart from "./reportingDetails/costCenterProgressBarChart"
import ExpenseByDepartmentPieChart from "./reportingDetails/expenseByDepartmentPieChart"

const CostCenterAnalytics = React.memo(() => (
    <>
        <Row gutter={[16, 16]}>
            {/* Column (Left side) for Cost Center Progress Bar Chart */}
            <Col xs={24} lg={12}>
                <CostCenterProgressBarChart />
            </Col>
            {/* Column (Right side) for Cost Center Progress Pie Chart */}
            <Col xs={24} lg={12} className="pb-0">
                <CostCenterBudgetChart />
            </Col>
        </Row>
        <Row gutter={[16, 16]}>
            <Col xs={24} lg={12} id="budget-section">
                <CostCenterPieChart />
            </Col>
            <Col xs={24} lg={12} id="team-expense-section">
                <ExpenseByDepartmentPieChart />
            </Col>
        </Row>
    </>
))

export default CostCenterAnalytics
