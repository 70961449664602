import { ApproverData, Employee, RightEnum, Role, User } from "@finway-group/shared/lib/models"
import UserCompanyProfileInterface from "@finway-group/shared/lib/models/user/userCompanyProfileInterface"
import axios from "axios"
import { Dispatch } from "react"

import { NOT_SET_VALUE } from "Shared/config/consts"
import { AuthnService, AuthzService, EmployeeService } from "Shared/services"
import store from "Shared/store"
import { syncEmployee } from "Shared/store/actions/employee/employeeActions"
import { isRightGranted } from "Shared/utils/helper.utils"

export const MODEL = "users"

const UserService = {
    getLoggedInUserProfile: () => {
        const { userClass } = AuthnService.getDataFromAccessToken()
        return userClass
    },
    getLoggedInUserId: () => {
        const { userId } = AuthnService.getDataFromAccessToken()
        return userId
    },
    getLoggedInEmployeeProfile: (): Employee => {
        const { userId, companyId } = AuthnService.getDataFromAccessToken()
        const loggedInEmployee = EmployeeService.getEmployeeById(userId)
        loggedInEmployee.setActiveCompanyProfile(companyId)
        return loggedInEmployee
    },
    getLoggedActiveCompanyProfileRole: (employee: Employee): Role | undefined => {
        const roles: Array<Role> = store.getState().roles.items
        return roles.find((role) => role._id === employee.activeCompanyProfile.roleId)
    },
    getLoggedInEmployeeRole: (): Role | undefined => {
        const { companyId } = AuthnService.getDataFromAccessToken()
        const userActiveProfile = UserService.getLoggedInUserProfile().getUserCompanyProfile(companyId)
        const roles: Array<Role> = store.getState().roles.items
        return roles.find((role) => role._id === userActiveProfile?.roleId)
    },
    isInMyTeam: (userId: string): boolean => {
        const myProfile = UserService.getLoggedInEmployeeProfile().activeCompanyProfile
        const employeeTeam = EmployeeService.getEmployeeById(userId).setActiveCompanyProfile(myProfile.companyId)?.team
        return employeeTeam === myProfile.team
    },

    //
    // API
    //

    fetch: async ({ page = 1, limit = 20, filterQueryString = "", searchQueryString = "", sortQueryString = "", controller = new AbortController(), timeout = 20000 }) => {
        const { data } = await axios.get(`/${MODEL}?page=${page}&limit=${limit}${filterQueryString}${searchQueryString}${sortQueryString}`, {
            signal: controller.signal,
            timeout,
        })
        data.docs = data.docs.map((e: any) => new User(e))
        return data as {
            docs: Array<User>
            page: number
            totalPages: number
            totalDocs: number
        }
    },
    fetchOneByEmail: async (email: string) => {
        const { data } = await axios.get(`/${MODEL}/email/${email}`)
        return data
    },
    fetchUserById: async (id: string) => {
        const { data } = await axios.get(`/${MODEL}/${id}`)
        return data
    },
    create: async (createEmployee: User) => {
        const { data } = await axios.post(`/${MODEL}`, createEmployee)
        return new User(data)
    },
    update: async (id: string, updateUser: User) => {
        const { data } = await axios.put(`/${MODEL}/${id}`, updateUser)
        return EmployeeService.mapEmployeeActiveCompany(new Employee(data))
    },
    createCompanyProfile: async (userId: string, companyId: string, companyProfile: UserCompanyProfileInterface) => {
        const { data } = await axios.post(`/${MODEL}/${userId}/companyProfiles/${companyId}`, companyProfile)
        return new User(data)
    },
    updateCompanyProfile: async (userId: string, companyId: string, companyProfile: UserCompanyProfileInterface) => {
        const { data } = await axios.put(`/${MODEL}/${userId}/companyProfiles/${companyId}`, companyProfile)
        return new User(data)
    },
    deleteCompanyProfile: async (id: string, companyId: string, replacementApproverId?: string) => {
        const { data } = await axios.delete(`/${MODEL}/${id}/companyProfiles/${companyId}`, { data: { replacementApproverId } })
        return new User(data)
    },
    requestPasswordResetLink: async () => {
        const { data } = await axios.get<{ link: string }>(`/${MODEL}/password/reset-link`, { withCredentials: true })
        return data.link
    },
    requestPhoneNumberVerification: async (phoneNumber: string | undefined) => {
        await axios.post(`/${MODEL}/phone/verification`, { phoneNumber })
    },
    checkPhoneNumberVerification: async (verificationCode: string) => {
        const { data } = await axios.post(`/${MODEL}/phone/verification-check`, { verificationCode })
        return data.verified
    },
    resetTfa: async (id: string) => {
        const response = await axios.post(`/${MODEL}/${id}/reset-tfauth`)
        return EmployeeService.mapEmployeeActiveCompany(new Employee(response.data))
    },
    submitNewCompanyProfile: async ({ values, user, dispatch }: { values: UserCompanyProfileInterface; user: User; dispatch: Dispatch<any> }) => {
        const formValues = { ...values }

        const updatedUser = await UserService.createCompanyProfile(user.id, formValues.companyId, formValues)
        dispatch(syncEmployee(updatedUser.id))
    },
    submitCompanyProfileUpdate: async ({
        values,
        user,
        workflowsReplacementApproverId,
        dispatch,
    }: {
        values: UserCompanyProfileInterface
        user: User
        workflowsReplacementApproverId?: string
        dispatch: Dispatch<any>
    }): Promise<{ showDemoteEmployeeModal: boolean }> => {
        const formValues = { ...values }

        if (formValues.superior === NOT_SET_VALUE) formValues.superior = undefined
        const employee = new Employee(user)
        employee.setActiveCompanyProfile(formValues.companyId)
        // If the user is being demoted to employee role, ask for a replacement user for workflows if the user is part of any workflow.
        const isUserBeingDemoted = AuthzService.isPotentialApprover(employee?.activeCompanyProfile.roleId) && !AuthzService.isPotentialApprover(formValues.roleId)
        if (isUserBeingDemoted && !workflowsReplacementApproverId) {
            const workflowsOfUser = employee.id ? await EmployeeService.getWorkflows(employee.id) : []
            if (workflowsOfUser.length) {
                return { showDemoteEmployeeModal: true }
            }
        }

        const updatedUser = await UserService.updateCompanyProfile(user.id, formValues.companyId, formValues)
        dispatch(syncEmployee(updatedUser.id))

        return { showDemoteEmployeeModal: false }
    },
    getApproverEmployeeList: (employees: Array<Employee>) =>
        employees.filter((e: Employee) => {
            const role = UserService.getLoggedActiveCompanyProfileRole(e)
            return isRightGranted(role?.rights, RightEnum.EXPENSE__BASIC__APPROVE)
        }),
}

export default UserService
