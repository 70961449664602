import { COLOR } from "@finway-group/shared/lib/consts"
import { CurrencyEnum, Expense, ExpenseKindEnum, ExpenseStatusEnum, SubscriptionTypeEnum } from "@finway-group/shared/lib/models"
import { Card, Tabs } from "antd"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import InfiniteScroll from "react-infinite-scroller"

import Loading from "Components/loading"
import RequestPreview from "Components/requestPreview"
import { useCostCenter } from "Shared/hooks/costCenter.hooks"
import { useShouldRefetchExpenses } from "Shared/hooks/expense.hooks"
import { AnalyticsService, NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { formatCurrencyNumber } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

interface CostCenterExpenseBudgetReportInterface {
    costCenterId: string
    budgetUsage: any
}

const CostCenterExpenseBudgetReport: React.FC<CostCenterExpenseBudgetReportInterface> = ({ costCenterId, budgetUsage }) => {
    const { t } = useTranslation()
    const costCenter = useCostCenter()

    // state
    const [isLoadingPaidExpenses, setIsLoadingPaidExpenses] = useStateIfMounted(false)
    const [paidExpensesState, setPaidExpensesState] = useStateIfMounted<{ docs: Array<Expense>; totalPages: number; totalDocs: number; currentPage: number }>({
        docs: [],
        totalPages: 0,
        totalDocs: 0,
        currentPage: 0,
    })
    const [isLoadingPendingExpenses, setIsLoadingPendingExpenses] = useStateIfMounted(false)
    const [pendingExpensesState, setPendingExpensesState] = useStateIfMounted<{ docs: Array<Expense>; totalPages: number; totalDocs: number; currentPage: number }>({
        docs: [],
        totalPages: 0,
        totalDocs: 0,
        currentPage: 0,
    })

    const shouldRefetch = useShouldRefetchExpenses()

    const handleLoadMorePaidExpenses = () => {
        if (paidExpensesState.currentPage !== paidExpensesState.totalPages) {
            fetchPaidExpenses(paidExpensesState.currentPage + 1, true)
        }
    }

    const handleLoadMorePendingExpenses = () => {
        if (pendingExpensesState.currentPage !== pendingExpensesState.totalPages) {
            fetchPendingExpenses(pendingExpensesState.currentPage + 1, true)
        }
    }

    const fetchPaidExpenses = async (page: number, append = false) => {
        try {
            setIsLoadingPaidExpenses(true)
            const paidExpensesQuery = `&kind[ne]=${ExpenseKindEnum.TRIP_FOLDER}&status[in]=${ExpenseStatusEnum.PAID},${ExpenseStatusEnum.DONE}&subscriptionType[ne]=${SubscriptionTypeEnum.INITIAL_REQUEST}`
            const { docs, totalPages, totalDocs } = await AnalyticsService.fetchPerformanceExpenses(
                costCenterId,
                page,
                10,
                paidExpensesQuery,
                budgetUsage.startDate,
                budgetUsage.endDate,
            )
            setPaidExpensesState({
                docs: [...(append ? paidExpensesState.docs : []), ...docs],
                totalPages,
                totalDocs,
                currentPage: page,
            })
        } catch (err) {
            NotificationService.send(NotificationTypeEnum.ERROR, t("error:request.fetch.title"), t("error:request.fetch.title"))
        }
        setIsLoadingPaidExpenses(false)
    }

    const fetchPendingExpenses = async (page: number, append = false) => {
        try {
            setIsLoadingPendingExpenses(true)
            const pendingExpensesQuery = `&kind[ne]=${ExpenseKindEnum.TRIP_FOLDER}&status[in]=${ExpenseStatusEnum.PURCHASE_PENDING},${ExpenseStatusEnum.DOCS_NEEDED},${ExpenseStatusEnum.INVOICE_PENDING},${ExpenseStatusEnum.APPROVED},${ExpenseStatusEnum.REVIEWED}`
            const { docs, totalPages, totalDocs } = await AnalyticsService.fetchPerformanceExpenses(
                costCenterId,
                page,
                10,
                pendingExpensesQuery,
                budgetUsage.startDate,
                budgetUsage.endDate,
            )
            setPendingExpensesState({
                docs: [...(append ? pendingExpensesState.docs : []), ...docs],
                totalPages,
                totalDocs,
                currentPage: page,
            })
        } catch (err) {
            NotificationService.send(NotificationTypeEnum.ERROR, t("error:request.fetch.title"), t("error:request.fetch.title"))
        }
        setIsLoadingPendingExpenses(false)
    }

    useEffect(() => {
        fetchPaidExpenses(1, false)
        fetchPendingExpenses(1, false)
    }, [costCenterId, budgetUsage.startDate, budgetUsage.endDate, shouldRefetch])

    const budgetLeft = budgetUsage.capacity - (budgetUsage.consumption + budgetUsage.forecast)

    return (
        <>
            <div>
                <p>
                    {t("label:budget_left")}
                    <b className="ml-10" style={{ color: budgetLeft > 0 ? COLOR.primary : COLOR.finway.red }}>
                        {formatCurrencyNumber(budgetLeft, costCenter?.budgetCurrency || CurrencyEnum.EUR)}
                    </b>
                </p>
            </div>
            <Tabs>
                <Tabs.TabPane key={"expenses-paid"} tab={t("label:expenses_paid")}>
                    <div id="report-expense-container">
                        {paidExpensesState.docs && paidExpensesState.docs?.length > 0 ? (
                            <div style={{ height: 700 }} className="card-list overflow-auto" id="report-expense-list">
                                <InfiniteScroll
                                    useWindow={false}
                                    hasMore={paidExpensesState.docs.length < paidExpensesState.totalDocs}
                                    loader={<Loading />}
                                    loadMore={handleLoadMorePaidExpenses}
                                >
                                    {paidExpensesState.docs.map((request, index) => (
                                        <RequestPreview key={index} request={request} hideActionButtons costCenterViewIds={costCenterId} />
                                    ))}
                                </InfiniteScroll>
                            </div>
                        ) : isLoadingPendingExpenses ? (
                            <Loading />
                        ) : (
                            <Card>{t("missing:expense_data")}</Card>
                        )}
                    </div>
                </Tabs.TabPane>
                <Tabs.TabPane key={"expenses-pending"} tab={t("label:expenses_pending")}>
                    <div id="report-expense-container">
                        {pendingExpensesState.docs && pendingExpensesState.docs?.length > 0 ? (
                            <div style={{ height: 700 }} className="card-list overflow-auto" id="report-expense-list">
                                <InfiniteScroll
                                    useWindow={false}
                                    loadMore={handleLoadMorePendingExpenses}
                                    hasMore={pendingExpensesState.docs.length < pendingExpensesState.totalDocs}
                                    loader={<Loading />}
                                >
                                    {pendingExpensesState.docs.map((request, index) => (
                                        <RequestPreview key={index} request={request} hideActionButtons costCenterViewIds={costCenterId} />
                                    ))}
                                </InfiniteScroll>
                            </div>
                        ) : isLoadingPendingExpenses ? (
                            <Loading />
                        ) : (
                            <Card>{t("missing:expense_data")}</Card>
                        )}
                    </div>
                </Tabs.TabPane>
            </Tabs>
        </>
    )
}
export default CostCenterExpenseBudgetReport
