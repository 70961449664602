import axios, { AxiosInstance, AxiosRequestConfig } from "axios"

export const queryGet = <T>(path: string, config?: AxiosRequestConfig<any>, axiosInstance?: AxiosInstance): Promise<T> =>
    (axiosInstance || axios).get(path, config).then((response) => response.data)

export const queryPatch = <T>(path: string, payload?: T, config?: AxiosRequestConfig<any>): Promise<T> => axios.patch(path, payload, config).then((response) => response.data)

export const queryPut = <T>(path: string, payload?: T, config?: AxiosRequestConfig<any>): Promise<T> => axios.put(path, payload, config).then((response) => response.data)

export const queryPost = <T>(path: string, payload?: T, config?: AxiosRequestConfig<any>): Promise<T> => axios.post(path, payload, config).then((response) => response.data)

export const queryDelete = (path: string, config?: AxiosRequestConfig<any>) => axios.delete(path, config)
