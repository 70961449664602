import { ExpenseTag } from "@finway-group/shared/lib/models"
import { useState } from "react"
import { useSelector } from "react-redux"

import { RootState } from "Shared/store/rootState.interface"

export const useExpenseTags = () => useSelector(({ expenseTags }: RootState) => expenseTags.items)

export const useExpenseTagsByIds = (ids: Array<string>) => useExpenseTags()?.filter((expenseTag: ExpenseTag) => ids?.includes(expenseTag._id))

export const useExpenseTagsInLoadingState = () => {
    const [expenseTagsLoading, setExpenseTagsLoading] = useState<Array<string>>([])

    const addTagInLoading = (expenseTagId: string) => {
        setExpenseTagsLoading((previousExpenseTagsLoading) => [...previousExpenseTagsLoading, expenseTagId])
    }

    const removeTagFromLoading = (expenseTagId: string) => {
        setExpenseTagsLoading((previousExpenseTagsLoading) => previousExpenseTagsLoading.filter((tagId) => tagId !== expenseTagId))
    }

    return { expenseTagsLoading, addTagInLoading, removeTagFromLoading }
}
