import Icon from "@ant-design/icons"
import React from "react"

const coffeeSvg = () => (
    <svg width="247" height="162" viewBox="0 0 247 162" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M68.0359 146.947H190.796C196.514 146.947 201.15 142.312 201.15 136.594C201.15 130.876 196.514 126.24 190.796 126.24C190.796 126.24 181.922 121.605 181.922 115.887C181.922 110.169 187.767 105.534 194.976 105.534H210.024C215.742 105.534 220.377 100.898 220.377 95.1805C220.377 89.4626 215.742 84.8272 210.024 84.8272H177.485C183.203 84.8272 187.838 80.1919 187.838 74.4739C187.838 68.756 183.203 64.1206 177.485 64.1206H236.647C242.365 64.1206 247 59.4853 247 53.7674C247 48.0494 242.365 43.4141 236.647 43.4141H91.7006C85.9826 43.4141 81.3473 48.0494 81.3473 53.7674C81.3473 59.4853 85.9826 64.1206 91.7006 64.1206H32.5389C26.821 64.1206 22.1856 68.756 22.1856 74.4739C22.1856 80.1919 26.821 84.8272 32.5389 84.8272H69.515C75.2329 84.8272 79.8683 89.4626 79.8683 95.1805C79.8683 100.898 75.2329 105.534 69.515 105.534H10.3533C4.63533 105.534 0 110.169 0 115.887C0 121.605 4.63533 126.24 10.3533 126.24H68.0359C62.318 126.24 57.6826 130.876 57.6826 136.594C57.6826 142.312 62.318 146.947 68.0359 146.947ZM226.293 95.1807C226.293 100.899 230.929 105.534 236.647 105.534C242.365 105.534 247 100.899 247 95.1807C247 89.4627 242.365 84.8274 236.647 84.8274C230.929 84.8274 226.293 89.4627 226.293 95.1807Z"
            fill="#F3F3F3"
        />
        <mask id="path-2-inside-1_20624_143609" fill="white">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M97.6176 121.036V95.9514C97.3733 95.9318 97.1267 95.9219 96.8781 95.9219C90.9695 95.9219 86.1797 101.55 86.1797 108.494C86.1797 115.437 90.9695 121.066 96.8781 121.066C97.1267 121.066 97.3733 121.056 97.6176 121.036Z"
            />
        </mask>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M97.6176 121.036V95.9514C97.3733 95.9318 97.1267 95.9219 96.8781 95.9219C90.9695 95.9219 86.1797 101.55 86.1797 108.494C86.1797 115.437 90.9695 121.066 96.8781 121.066C97.1267 121.066 97.3733 121.056 97.6176 121.036Z"
            fill="white"
        />
        <path
            d="M97.6176 95.9514H100.945V92.8799L97.8837 92.6343L97.6176 95.9514ZM100.945 121.036V95.9514H94.2898V121.036H100.945ZM97.8837 92.6343C97.551 92.6076 97.2156 92.594 96.8781 92.594V99.2497C97.0377 99.2497 97.1955 99.2561 97.3515 99.2686L97.8837 92.6343ZM96.8781 92.594C88.6483 92.594 82.8518 100.236 82.8518 108.494H89.5075C89.5075 102.865 93.2908 99.2497 96.8781 99.2497V92.594ZM82.8518 108.494C82.8518 116.751 88.6483 124.393 96.8781 124.393V117.738C93.2908 117.738 89.5075 114.123 89.5075 108.494H82.8518ZM96.8781 124.393C97.2156 124.393 97.551 124.38 97.8837 124.353L97.3515 117.719C97.1955 117.731 97.0377 117.738 96.8781 117.738V124.393Z"
            fill="#75A4FE"
            mask="url(#path-2-inside-1_20624_143609)"
        />
        <path
            d="M156.778 76.035C156.881 76.035 156.963 76.1178 156.963 76.2199H160.291C160.291 74.2799 158.719 72.7072 156.778 72.7072V76.035ZM156.594 76.2199C156.594 76.1178 156.676 76.035 156.778 76.035V72.7072C154.838 72.7072 153.266 74.2799 153.266 76.2199H156.594ZM156.594 103.314V76.2199H153.266V103.314H156.594ZM156.778 103.498C156.676 103.498 156.594 103.416 156.594 103.314H153.266C153.266 105.254 154.838 106.826 156.778 106.826V103.498ZM156.963 103.314C156.963 103.416 156.881 103.498 156.778 103.498V106.826C158.719 106.826 160.291 105.254 160.291 103.314H156.963ZM156.963 76.2199V103.314H160.291V76.2199H156.963ZM156.778 111.533C156.881 111.533 156.963 111.616 156.963 111.718H160.291C160.291 109.778 158.719 108.205 156.778 108.205V111.533ZM156.594 111.718C156.594 111.616 156.676 111.533 156.778 111.533V108.205C154.838 108.205 153.266 109.778 153.266 111.718H156.594ZM156.594 118.733V111.718H153.266V118.733H156.594ZM156.778 118.918C156.676 118.918 156.594 118.835 156.594 118.733H153.266C153.266 120.673 154.838 122.246 156.778 122.246V118.918ZM156.963 118.733C156.963 118.835 156.881 118.918 156.778 118.918V122.246C158.719 122.246 160.291 120.673 160.291 118.733H156.963ZM156.963 111.718V118.733H160.291V111.718H156.963Z"
            fill="#A4C3FE"
        />
        <path
            d="M137.553 2C137.553 2 131.637 11.348 131.637 18.2695C131.637 26.4872 138.52 30.1675 138.52 39.0692C138.52 45.8462 131.637 52.5272 131.637 52.5272"
            stroke="#60CDB2"
            strokeOpacity="0.5"
            strokeWidth="3.32784"
            strokeLinecap="round"
        />
        <path
            d="M119.803 18.2695C118.512 26.423 124.24 27.931 124.24 35.2409C124.24 40.8061 119.803 46.3713 119.803 46.3713"
            stroke="#60CDB2"
            strokeOpacity="0.5"
            strokeWidth="3.32784"
            strokeLinecap="round"
        />
        <path
            d="M147.599 14.5352C145.645 19.5923 146.743 22.6747 147.599 24.4891C149.429 28.3672 152.339 31.7149 152.339 35.6613C152.339 42.5366 147.599 48.8947 147.599 48.8947"
            stroke="#60CDB2"
            strokeOpacity="0.5"
            strokeWidth="3.32784"
            strokeLinecap="round"
        />
        <path
            d="M95.9539 131.949C84.7687 131.416 75.617 121.256 75.617 108.491C75.617 95.3754 85.2773 85.0111 96.8783 85.0111C97.1064 85.0111 97.3337 85.0151 97.56 85.023L99.2817 85.0828V83.36V68.5568C99.2817 66.2083 101.186 64.3045 103.534 64.3045H164.175C166.523 64.3045 168.427 66.2083 168.427 68.5568V158.778C168.427 159.493 167.848 160.073 167.133 160.073H100.576C99.8611 160.073 99.2817 159.493 99.2817 158.778V135.229V133.622V130.122H97.6178H95.9539V131.949Z"
            fill="white"
            stroke="#60CDB2"
            strokeWidth="3.32784"
            strokeLinecap="round"
        />
        <path d="M47.5212 150.642H57.6853" stroke="#60CDB2" strokeWidth="3.32784" strokeLinecap="round" />
        <path d="M216.127 150.642H220.375" stroke="#60CDB2" strokeWidth="3.32784" strokeLinecap="round" />
        <path d="M176.004 150.642H209.175" stroke="#60CDB2" strokeWidth="3.32784" strokeLinecap="round" />
        <path d="M66.5586 150.642H98.2901" stroke="#60CDB2" strokeWidth="3.32784" strokeLinecap="round" />
        <path
            d="M88.1874 108.907C88.1874 102.453 93.0147 97.2706 99.0029 96.8687V120.945C93.0147 120.544 88.1874 115.361 88.1874 108.907Z"
            fill="white"
            stroke="#60CDB2"
            strokeWidth="3.32784"
            strokeLinecap="round"
        />
        <path d="M153.961 114.821V122.492" stroke="#AFE6D8" strokeWidth="3.32784" strokeLinecap="round" />
        <path d="M153.961 76.0086V105.634" stroke="#AFE6D8" strokeWidth="3.32784" strokeLinecap="round" />
    </svg>
)

const CoffeIcon = (props: any) => <Icon component={coffeeSvg} {...props} />

export default CoffeIcon
