import { Col, Row, Spin } from "antd"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Link, useParams } from "react-router-dom"

import { AuthnService } from "Shared/services"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

import AuthFooter from "./components/auth.footer"
import LoginLogo from "./components/login.logo"

interface IMatchParams {
    token: string
}

export const ConfirmEmail: React.FC<{}> = () => {
    const { token } = useParams<IMatchParams>()
    const [isValid, setIsValid] = useStateIfMounted(true)
    const [hasLoaded, setHasLoaded] = useStateIfMounted(false)
    const { t } = useTranslation()

    useEffect(() => {
        ;(async () => {
            try {
                await AuthnService.verifyEmailConfirmationToken(String(token))
                setIsValid(true)
            } catch (err) {
                setIsValid(false)
            } finally {
                setHasLoaded(true)
            }
        })()
    }, [])

    return (
        <div className="login-bg flex flex-col items-center justify-center min-h-screen h-full w-full overflow-hidden relative">
            <Row className="w-full p-10 z-50 mb-30 my-auto">
                <Col span={24} className="max-w-400 min-w-300 my-0 mx-auto overflow-x-hidden overflow-y-hidden">
                    <LoginLogo />
                    <div className="bg-white w-full rounded-lg shadow p-32 mb-32 mt-32 md:mt-40">
                        {!hasLoaded ? (
                            <Spin spinning={true} />
                        ) : isValid ? (
                            <>
                                <div>
                                    <h2 className="p-0">{t("info:verify_email.title")}</h2>
                                    <p className="mt-10">{t("info:verify_email.message")}</p>
                                </div>
                                <div className="flex w-full flex-col items-center justify-center pt-32">
                                    <Link className="finway-forgot-password finway-forgot-password-link" to="/">
                                        {t("nav:go_back_to_homepage")}
                                    </Link>
                                </div>
                            </>
                        ) : (
                            <>
                                <h2 className="p-0">{t("error:verify_email.title")}</h2>
                                <p className="mt-10">{t("error:verify_email.message")}</p>
                            </>
                        )}
                    </div>
                </Col>
            </Row>
            <Row className="w-full p-10 z-50 bottom-0 sticky mt-auto">
                <Col span={24} className="max-w-450 min-w-300 my-0 mx-auto">
                    <AuthFooter />
                </Col>
            </Row>
        </div>
    )
}

export default ConfirmEmail
