import Icon from "@ant-design/icons"
import React from "react"

const linesSVG = () => (
    <svg width="325" height="137" viewBox="0 0 325 137" xmlns="http://www.w3.org/2000/svg">
        <path
            fill="currentColor"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M89.521 136.909H251.048C258.572 136.909 264.671 130.81 264.671 123.286C264.671 115.763 258.572 109.664 251.048 109.664C251.048 109.664 239.371 103.565 239.371 96.0409C239.371 88.5173 247.061 82.4182 256.548 82.4182H276.347C283.871 82.4182 289.97 76.3191 289.97 68.7954C289.97 61.2718 283.871 55.1727 276.347 55.1727H233.533C241.057 55.1727 247.156 49.0735 247.156 41.5499C247.156 34.0263 241.057 27.9271 233.533 27.9271H311.377C318.901 27.9271 325 21.828 325 14.3044C325 6.78076 318.901 0.681641 311.377 0.681641H120.659C113.135 0.681641 107.036 6.78076 107.036 14.3044C107.036 21.828 113.135 27.9271 120.659 27.9271H42.8144C35.2907 27.9271 29.1916 34.0263 29.1916 41.5499C29.1916 49.0735 35.2907 55.1727 42.8144 55.1727H91.4671C98.9907 55.1727 105.09 61.2718 105.09 68.7954C105.09 76.3191 98.9907 82.4182 91.4671 82.4182H13.6228C6.09911 82.4182 0 88.5173 0 96.0409C0 103.565 6.09911 109.664 13.6228 109.664H89.521C81.9973 109.664 75.8982 115.763 75.8982 123.286C75.8982 130.81 81.9973 136.909 89.521 136.909ZM297.754 68.7954C297.754 76.3191 303.854 82.4182 311.377 82.4182C318.901 82.4182 325 76.3191 325 68.7954C325 61.2718 318.901 55.1727 311.377 55.1727C303.854 55.1727 297.754 61.2718 297.754 68.7954Z"
        />
    </svg>
)

const LinesIcon = (props: any) => <Icon component={linesSVG} {...props} />

export default LinesIcon
