import ExclamationCircleOutlined from "@ant-design/icons/lib/icons/ExclamationCircleOutlined"
import { ExpenseAccount, RightEnum } from "@finway-group/shared/lib/models"
import { isExpenseAccountCodeDATEVCompliant } from "@finway-group/shared/lib/utils/datevValidation"
import { Alert, Switch } from "antd"
import Modal from "antd/es/modal"
import Table from "antd/es/table/Table"
import { ColumnsType } from "antd/lib/table"
import React, { useEffect, useState } from "react"
import { Edit as EditIcon, Plus as PlusIcon } from "react-feather"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import { TrashIcon } from "Components/icons"
import ExpenseAccountModal from "Components/modals/expenseAccount.modal"
import SettingsLayout, { SettingsButton } from "Features/settings/settings.layout"
import { WARNING_ORANGE } from "Shared/config/consts"
import { useModal } from "Shared/context/modal.context"
import { useCompany } from "Shared/hooks/company.hooks"
import { useExpenseAccounts } from "Shared/hooks/expenseAccount.hooks"
import { AuthzService } from "Shared/services"
import NotificationService, { NotificationTypeEnum } from "Shared/services/notification.service"
import { updateCompanyFeatureSettings } from "Shared/store/actions/company/companyActions"
import { deleteExpenseAccounts } from "Shared/store/actions/expenseAccount/expenseAccountActions"
import { removeStringWhiteSpaces } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

const { confirm } = Modal

const ExpenseAccountSettings = () => {
    const { t } = useTranslation()
    const { showModal } = useModal()

    const expenseAccounts = useExpenseAccounts()
    const company = useCompany()
    const [selectedRows, setSelectedRows] = useState<Array<ExpenseAccount>>([])
    const [isDeleting, setIsDeleting] = useStateIfMounted(false)
    const [isSwitchLoading, setIsSwitchLoading] = useStateIfMounted(false)

    const dispatch = useDispatch()

    const isDatevCompliant = expenseAccounts.every((expenseAccount: ExpenseAccount) => isExpenseAccountCodeDATEVCompliant(expenseAccount.accountCode))

    const columns: ColumnsType<ExpenseAccount> = [
        {
            title: t("label:account_name"),
            dataIndex: "accountName",
            key: "accountName",
        },
        {
            title: t("label:account_code"),
            key: "accountCode",
            width: 150,
            align: "right",
            render: ({ accountCode }: ExpenseAccount) => (
                <div className="self-center truncate flex items-center justify-end">
                    <span>{accountCode}</span>
                    {!isExpenseAccountCodeDATEVCompliant(accountCode) && <ExclamationCircleOutlined className="ml-6" style={{ color: WARNING_ORANGE }} />}
                </div>
            ),
        },
    ]

    const canWrite = AuthzService.isRightGrantedForLoggedInUser(RightEnum.COMPANY_ACCOUNTING_SETTINGS__ALL__WRITE)

    const handleDelete = async () => {
        try {
            const count = selectedRows.length

            setIsDeleting(true)
            await dispatch(deleteExpenseAccounts(selectedRows))
            NotificationService.send(
                NotificationTypeEnum.SUCCESS,
                t("notification:settings.expense_account.deleted.title"),
                t("notification:settings.expense_account.deleted.message", { count }),
            )
            setSelectedRows([])
        } catch (err) {
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:expense_account.update.title"))
        } finally {
            setIsDeleting(false)
        }
    }

    const handleDeleteRequest = () => {
        const count = selectedRows.length

        if (count > 1) {
            confirm({
                icon: <ExclamationCircleOutlined />,
                title: t("confirm:expense_account_settings.bulk_delete.title"),
                okText: t("confirm:expense_account_settings.bulk_delete.confirm"),
                cancelText: t("confirm:expense_account_settings.bulk_delete.cancel"),
                content: t("confirm:expense_account_settings.bulk_delete.message", { count }),
                onOk: () => handleDelete(),
            })
        } else {
            handleDelete()
        }
    }

    useEffect(() => {
        if (selectedRows.length > 0) {
            // update selected rows with updated data
            const selectedIds = selectedRows.map(({ _id }) => _id)
            setSelectedRows(expenseAccounts.filter(({ _id }) => selectedIds.includes(_id)))
        }
    }, [expenseAccounts])

    const onAllowSpecialCharsInCodes = async (checked: boolean) => {
        setIsSwitchLoading(true)
        try {
            // we need to do this so that the middleware is not throwing an error
            if (company.iban) company.iban = removeStringWhiteSpaces(company.iban)
            company.shouldRefetch = undefined

            await updateCompanyFeatureSettings(company._id, { allowSpecialCharactersInExpenseAccountCodesForDatev: checked })(dispatch)
            NotificationService.send(NotificationTypeEnum.SUCCESS, t("notification:settings.company.updated.title"), t("notification:settings.company.updated.message"))
        } catch (err) {
            NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:settings.edit.title"))
        } finally {
            setIsSwitchLoading(false)
        }
    }

    return (
        <SettingsLayout
            titleKey="label:expense_accounts"
            buttons={
                <>
                    {canWrite && (
                        <>
                            {selectedRows.length > 0 ? (
                                <>
                                    <SettingsButton icon={<TrashIcon />} onClick={handleDeleteRequest} labelKey="action:delete" />
                                    <SettingsButton
                                        icon={<EditIcon />}
                                        onClick={() => showModal(ExpenseAccountModal, false, { isShowing: true, expenseAccounts: selectedRows })}
                                        labelKey="action:edit"
                                    />
                                </>
                            ) : (
                                <SettingsButton
                                    icon={<PlusIcon />}
                                    onClick={() =>
                                        showModal(ExpenseAccountModal, false, {
                                            isShowing: true,
                                            expenseAccounts: [{ accountName: undefined, accountCode: undefined }],
                                            isEdit: false,
                                        })
                                    }
                                    labelKey="action:create"
                                />
                            )}
                        </>
                    )}
                </>
            }
        >
            <div className="mt-20">
                <ul className="description-list">
                    <li>
                        <span className="title w-400">{t("label:allow_expense_account_code_special_characters")}</span>
                        <span className="text text-left">
                            <Switch
                                checked={company?.allowSpecialCharactersInExpenseAccountCodesForDatev}
                                size="small"
                                onChange={onAllowSpecialCharsInCodes}
                                loading={isSwitchLoading}
                                disabled={!AuthzService.isRightGrantedForLoggedInUser(RightEnum.COMPANY_FEATURE_SETTINGS__ALL__WRITE)}
                            />
                        </span>
                    </li>
                </ul>
            </div>
            <div className="mt-20">
                <Alert type="error" showIcon className="mb-20" message={t("info:expense_account_code_special_character_warning")} />
            </div>
            {!isDatevCompliant && <Alert type="warning" showIcon className="mb-20" message={t("info:expense_account_code_datev_compliance")} />}
            <div>
                {expenseAccounts.length === 0 ? (
                    <div className="text-text-dark p-10">{t("missing:no_expense_accounts")}</div>
                ) : (
                    <div className="w-full overflow-y-auto overflow-x-hidden">
                        <Table
                            columns={columns}
                            dataSource={expenseAccounts}
                            rowSelection={
                                canWrite
                                    ? {
                                          onChange: (_keys, rows) => {
                                              setSelectedRows(rows)
                                          },
                                          getCheckboxProps: (record) => ({
                                              name: record.accountName,
                                          }),
                                          renderCell: (_value, _record, _index, originNode) => (
                                              <div onClick={(e) => e.stopPropagation()} className="bigger-hitbox-checkbox">
                                                  {originNode}
                                              </div>
                                          ),
                                      }
                                    : undefined
                            }
                            rowKey="_id"
                            pagination={{
                                position: ["bottomRight"],
                                showSizeChanger: true,
                                hideOnSinglePage: false,
                                pageSizeOptions: ["5", "10", "20", "50", "100"],
                            }}
                        />
                    </div>
                )}
            </div>
        </SettingsLayout>
    )
}

export default ExpenseAccountSettings
