import { COLOR } from "@finway-group/shared/lib/consts"
import {
    ApprovalProcessesDiferenceLevelEnum,
    ApprovalScopeEnum,
    ApprovalStatusEnum,
    ComparisonPositionEnum,
    Expense,
    ExpenseStatusEnum,
    RightEnum,
    TripFolder,
} from "@finway-group/shared/lib/models"
import { compareApprovalLevelOfApprovalProcesses, getApprovalProcess } from "@finway-group/shared/lib/utils/approvalProcess.utils"
import { Card, Divider, Tabs, Tag, Tooltip } from "antd"
import React, { useEffect } from "react"
import { AlertCircle, Folder, Info } from "react-feather"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"

import ExportTag from "Components/exportTag"
import { useExpenseDetailsContext } from "Shared/context/expenseDetails.context"
import { useEmployeeById } from "Shared/hooks/employee.hooks"
import { AuthzService } from "Shared/services"
import { syncExpense } from "Shared/store/actions/expense/expenseActions"
import { breakpoints } from "Shared/utils/breakpoints.utils"
import { getStatusLabel, isExpenseMissingCostCenter1, isFolderExpense, isMatched } from "Shared/utils/expense.utils"
import { useResizeWindowWidth } from "Shared/utils/hooks/useResizeWindowWidth"

import { ExpenseArchiveTags } from "../auxiliary/expenseArchiveTags"
import { FolderContentInteractables } from "../auxiliary/folderContentInteractables"
import ExpenseMainDetailsSectionContainer from "../expenseMainDetailSectionContainer"
import ExpenseTransactionList from "../expenseTransaction.list"

interface FolderMainDetailsSectionOuterInterface {
    folder: TripFolder
    subExpenses?: Array<Expense>
    setIsExpenseUpdating: React.Dispatch<React.SetStateAction<boolean>>
    setIsEditModalShowing: React.Dispatch<React.SetStateAction<boolean>>
    setActiveExpenseId: (expenseId: string) => void
}

export const FolderSubExpenseList: React.FC<FolderMainDetailsSectionOuterInterface> = ({
    folder,
    subExpenses = [],
    setIsExpenseUpdating,
    setIsEditModalShowing,
    setActiveExpenseId,
}) => {
    const dispatch = useDispatch()

    // 1st index will be parent
    const expenseList: Array<Expense> = [folder, ...subExpenses]

    const { isReadOnly } = useExpenseDetailsContext()

    const { id, childId } = useParams<{ id: string; childId?: string }>()

    const { t } = useTranslation()

    const windowWidth = useResizeWindowWidth()
    const isMobileView = windowWidth < breakpoints.lg

    return (
        <Card className="w-full">
            <Tabs
                tabPosition={isMobileView ? "top" : "left"}
                className="expense-folder-tab w-full"
                onChange={(activeChildId) => {
                    syncExpense(activeChildId)(dispatch).then(() => {
                        setImmediate(() => {
                            setActiveExpenseId(activeChildId)
                        })
                    })
                }}
                activeKey={childId ?? id}
                data-testid="folderExpensesTab"
            >
                {expenseList.map((expense) => (
                    <Tabs.TabPane tab={<TabTitle expense={expense} folder={folder} />} key={expense.id}>
                        <div className="flex justify-between items-center">
                            <div className="font-bold">
                                {isFolderExpense(expense) ? (
                                    t("label:folder.folder")
                                ) : (
                                    <div className="flex flex-col md:flex-row items-center gap-x-2">
                                        <span className="pt-6">{`${t("label:request")} #${expense.expenseNumber}`}</span> <ExpenseArchiveTags expense={expense} />
                                        {AuthzService.isRightGrantedForLoggedInUser(RightEnum.EXPENSE__ALL__EXPORT) && <ExportTag expense={expense} className="mt-4 ml-4" />}
                                        {AuthzService.isRightGrantedForLoggedInUser(RightEnum.TRANSACTION__ALL__READ) && isMatched(expense) && (
                                            <Tag className="ant-tag-green mt-4 ml-4">{t("info:matched")}</Tag>
                                        )}
                                    </div>
                                )}
                            </div>
                            <div>
                                <div>
                                    <div>{!isReadOnly && <FolderContentInteractables expense={expense} folder={folder} />}</div>
                                </div>
                            </div>
                        </div>
                        <Divider className="pt-0 mt-16" />

                        <Tabs className="w-full min-h-200 ml-1 overflow-auto" tabPosition="top">
                            <Tabs.TabPane key="requestDetails" tab={t("label:request_details")}>
                                <Card className="overflow-hidden">
                                    <ExpenseMainDetailsSectionContainer
                                        expense={expense}
                                        subExpenses={subExpenses}
                                        isInnerComponent={true}
                                        setIsExpenseUpdating={setIsExpenseUpdating}
                                        setIsEditModalShowing={setIsEditModalShowing}
                                        setActiveExpenseId={setActiveExpenseId}
                                    />
                                </Card>
                            </Tabs.TabPane>
                            {AuthzService.isRightGrantedForLoggedInUser(RightEnum.TRANSACTION__ALL__READ) && !isFolderExpense(expense) && (
                                <Tabs.TabPane key="transactions" tab={t("label:transactions")}>
                                    <ExpenseTransactionList expense={expense} />
                                </Tabs.TabPane>
                            )}
                        </Tabs>
                    </Tabs.TabPane>
                ))}
            </Tabs>
        </Card>
    )
}

interface TabTitleInterface {
    expense: Expense
    folder: TripFolder
}
const TabTitle = ({ expense, folder }: TabTitleInterface) => {
    const requester = useEmployeeById(expense.requestedBy._id)
    const { t } = useTranslation()

    const isExpenseStatusLeftBehindFolder = !isFolderExpense(expense) ? expense.status < folder.status && folder.status !== ExpenseStatusEnum.DECLINED : false
    let areExpenseApprovalsBehindFolder = false
    let approvalProcessComparisonLevel: any
    const folderApprovalProcess = getApprovalProcess(ApprovalScopeEnum.EXPENSE_INVOICE, folder.approvalProcesses)
    const expenseApprovalProcess = getApprovalProcess(ApprovalScopeEnum.EXPENSE_INVOICE, expense.approvalProcesses)

    if (
        folderApprovalProcess?.status === ApprovalStatusEnum.IN_PROGRESS &&
        expenseApprovalProcess?.status === ApprovalStatusEnum.IN_PROGRESS &&
        folderApprovalProcess.workflow === expenseApprovalProcess.workflow
    ) {
        const approvalProcessComparison = compareApprovalLevelOfApprovalProcesses(folderApprovalProcess, expenseApprovalProcess)
        areExpenseApprovalsBehindFolder = approvalProcessComparison?.position === ComparisonPositionEnum.BEHIND
        approvalProcessComparisonLevel = approvalProcessComparison?.level
    }

    const getApprovalIssues = (expense: Expense) => {
        if (isFolderExpense(expense) || expense.status > folder.status) return

        const approvalIssues = []
        if (!expense.getCreditor()) approvalIssues.push(t("tooltips:folder.sub_expense_approval_missing_vendor"))
        if (isExpenseMissingCostCenter1(expense)) approvalIssues.push(t("tooltips:folder.sub_expense_approval_missing_cost_center"))

        if (isExpenseStatusLeftBehindFolder) {
            approvalIssues.push(t("tooltips:folder.single_sub_expense_status_left_behind"))
        } else if (areExpenseApprovalsBehindFolder) {
            if (approvalProcessComparisonLevel === ApprovalProcessesDiferenceLevelEnum.STEP) {
                approvalIssues.push(t("tooltips:folder.sub_expense_step_left_behind"))
            } else {
                approvalIssues.push(t("tooltips:folder.sub_expense_approval_left_behind"))
            }
        }

        if (folderApprovalProcess?.status === ApprovalStatusEnum.IN_PROGRESS && expenseApprovalProcess?.status === ApprovalStatusEnum.NOT_STARTED) {
            approvalIssues.push(t("tooltips:folder.unsubmitted_invoice"))
        }

        if (!approvalIssues.length) return

        return (
            <ul>
                {approvalIssues.map((issue) => (
                    <li className="list-disc" key={issue}>
                        {issue}
                    </li>
                ))}
            </ul>
        )
    }

    const approvalIssues = getApprovalIssues(expense)

    const tooltipText = (
        <>
            <div>
                {t("tooltips:status")}: {getStatusLabel(expense.status, isFolderExpense(expense) ? expense.isDraft : undefined)}
            </div>
            <div>{approvalIssues}</div>
        </>
    )

    const statusTooltip = (
        <Tooltip title={tooltipText}>
            {approvalIssues ? <AlertCircle stroke={COLOR.white} fill={COLOR.finway.orange} size={16} strokeWidth={1} /> : <Info size={16} strokeWidth={1} />}
        </Tooltip>
    )

    return (
        <div className="w-full flex justify-between h-54 pl-6">
            {!isFolderExpense(expense) ? (
                <>
                    <div className="text-left flex flex-col justify-center">
                        <div className="font-medium">{requester?.getFullName()}</div>
                        <div className="truncate w-140 font-light">
                            #{expense.expenseNumber} - {expense.getCreditor()?.name}
                        </div>
                    </div>
                    <div className="mt-16">{statusTooltip}</div>
                </>
            ) : (
                <>
                    <div className="text-left flex items-center gap-4">
                        <div>
                            <Folder size={16} strokeWidth={1} />
                        </div>
                        <div className="font-medium">{t("label:folder.details")}</div>
                    </div>
                    <div className="flex items-center">{statusTooltip}</div>
                </>
            )}
        </div>
    )
}
