import { RightEnum } from "@finway-group/shared/lib/models"
import { Col, Row, Tooltip } from "antd"
import React from "react"
import { Edit as EditIcon, Plus as PlusIcon } from "react-feather"
import { useTranslation } from "react-i18next"

import TeamModal from "Components/modals/team.modal"
import PersonioTag from "Components/personioTag"
import SettingsLayout, { SettingsButton } from "Features/settings/settings.layout"
import { useModal } from "Shared/context/modal.context"
import { useTeamsWithUsersCount } from "Shared/hooks/analytics.hooks"
import { useTeams } from "Shared/hooks/team.hooks"
import { AuthzService } from "Shared/services"
import { isTeamSourcePersonio } from "Shared/utils/team.utils"

const TeamSettings = () => {
    const { t } = useTranslation()
    const { showModal } = useModal()

    const teams = useTeams(true)
    const teamsWithUserCount = useTeamsWithUsersCount(true)

    const canWrite = AuthzService.isRightGrantedForLoggedInUser(RightEnum.COMPANY_ACCOUNTING_SETTINGS__ALL__WRITE)

    const onClick = () => showModal(TeamModal, false, { isShowing: true, teams })
    const isEmpty = teams.length === 0

    return (
        <SettingsLayout
            titleKey="label:teams"
            buttons={<>{canWrite && <SettingsButton icon={isEmpty ? <PlusIcon /> : <EditIcon />} onClick={onClick} labelKey={isEmpty ? "action:create" : "action:edit"} />}</>}
        >
            <div>
                {isEmpty ? (
                    <div className="text-text-dark p-10">{t("missing:no_team")}</div>
                ) : (
                    <div className="p-5 h-full">
                        <Row gutter={[16, 16]} className="font-bold">
                            <Col flex="auto" className="text-left self-center">
                                {t("label:name")}
                            </Col>
                            <Col flex="auto" className="text-right self-center">
                                {t("label:team_users")}
                            </Col>
                        </Row>
                        {teamsWithUserCount.map((team, index: number) => (
                            <Row gutter={[16, 16]} key={index}>
                                <Col flex="auto" className="text-left self-center truncate">
                                    {team.teamName}
                                    {isTeamSourcePersonio(team) && (
                                        <Tooltip overlayStyle={{ maxWidth: "100%" }} title={t("tooltips:personio.team_source")}>
                                            <PersonioTag />
                                        </Tooltip>
                                    )}
                                </Col>
                                <Col flex="auto" className="text-right self-center">
                                    {team.usersCount}
                                </Col>
                            </Row>
                        ))}
                    </div>
                )}
            </div>
        </SettingsLayout>
    )
}

export default TeamSettings
