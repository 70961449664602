import { ExclamationCircleOutlined } from "@ant-design/icons"
import {
    DocumentTypeEnum,
    Expense,
    ExpenseKindEnum,
    ExpenseRequestTypeEnum,
    ExpenseStatusEnum,
    FileExtEnum,
    OperationTypeEnum,
    Vendor,
    VendorTypeEnum,
} from "@finway-group/shared/lib/models"
import { ExpensePaymentOptionEnum } from "@finway-group/shared/lib/models/expense/expensePaymentOption.enum"
import { Card, Modal, Spin, Switch, Tabs } from "antd"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import DocumentTabManager from "Components/DocumentTabManager"
import Loading from "Components/loading"
import { UploadFilesDraggerInterface } from "Components/upload/UploadFilesDragger"
import { useArchiveInterval } from "Shared/hooks/company.hooks"
import { useCreditorById } from "Shared/hooks/vendor.hooks"
import { ExpenseService, NotificationService, VendorService } from "Shared/services"
import { updateDocuments } from "Shared/store/actions/expense/expenseActions"
import { isExpenseAlreadyPaid, isExpenseArchived, isHospitalityExpense } from "Shared/utils/expense.utils"
import { mapToInvoiceInterface } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

const FileViewer = React.lazy(() => import("Components/fileViewer/fileViewer"))

interface ExpenseDocumentSectionInterface {
    expense: Expense
    doFileUpload: UploadFilesDraggerInterface["doFileUpload"]
    onInboxSelected: (event: any) => void
    isUploadingInvoice?: boolean
    ocrSettings: {
        isOcrEnabled: boolean
        isOcrItemizationEnabled: boolean
        setIsOcrEnabled: (flag: boolean) => void
        setIsOcrItemizationEnabled: (flag: boolean) => void
    }
    isOcrUploading: boolean
    isReadOnly: boolean
}
const ExpenseDocumentSection: React.FC<ExpenseDocumentSectionInterface> = ({
    expense,
    doFileUpload,
    onInboxSelected,
    isUploadingInvoice,
    ocrSettings,
    isOcrUploading,
    isReadOnly,
}) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const creditor = useCreditorById(expense.getCreditor()?._id)
    const archiveAfterXDays = useArchiveInterval()
    const archived = isExpenseArchived(expense, archiveAfterXDays)
    const vendor = creditor?.type === VendorTypeEnum.VENDOR ? (creditor.source as Vendor) : undefined
    const [vendorWithContracts, setVendorWithContracts] = useState<Vendor | undefined>(undefined)
    const isSubscription = expense.kind === ExpenseKindEnum.SUBSCRIPTION
    // prettier-ignore
    const requestType = expense.status === ExpenseStatusEnum.PURCHASE_PENDING ? ExpenseRequestTypeEnum.PURCHASE : expense.status === ExpenseStatusEnum.INVOICE_PENDING ? ExpenseRequestTypeEnum.INVOICE : undefined;
    const isAlreadyPaid = isExpenseAlreadyPaid(expense)
    const [showStampOnInvoice, setShowStampOnInvoice] = useStateIfMounted(true)
    const documentEditingIsProhibited =
        isReadOnly ||
        expense.isGobdCompliantInvoice() ||
        expense.deleted ||
        (expense.status === ExpenseStatusEnum.DECLINED && expense.paymentOption === ExpensePaymentOptionEnum.SMART_CARD) ||
        archived

    if (isHospitalityExpense(expense)) {
        ocrSettings.setIsOcrEnabled(false)
        ocrSettings.setIsOcrItemizationEnabled(false)
    }

    useEffect(() => {
        if (archived && ocrSettings.isOcrEnabled) {
            ocrSettings.setIsOcrEnabled(false)
        }
    }, [archived])

    const onChangeTab = (activeKey: string) => {
        if (activeKey === "contracts") {
            if (vendor && vendor?.contracts?.length > 0) {
                VendorService.fetchOneVendor(vendor.id)
                    .then((fechtedVendor) => {
                        setVendorWithContracts(fechtedVendor)
                    })
                    .catch((_ignore) => {})
            }
        }
    }

    const onRemoveTab = (documentType: DocumentTypeEnum, targetKey: string | React.MouseEvent | React.KeyboardEvent) => {
        const { confirm } = Modal

        confirm({
            title: t("confirm:file.delete_from_server.title"),
            icon: <ExclamationCircleOutlined />,
            content: t("confirm:file.delete_from_server.message"),
            cancelText: t("confirm:file.delete_from_server.cancel"),
            okText: t("confirm:file.delete_from_server.confirm"),
            onOk() {
                return new Promise<void>((resolve, reject) => {
                    let documentData
                    switch (documentType) {
                        case DocumentTypeEnum.INVOICE:
                            documentData = expense.invoices[Number(targetKey)]
                            break
                        case DocumentTypeEnum.OFFER:
                            documentData = expense.offers && expense.offers[Number(targetKey)]
                            break
                        case DocumentTypeEnum.DELIVERY_NOTE:
                            documentData = expense.deliveryNote
                            break
                        default:
                            break
                    }
                    if (!documentData) reject()

                    // prettier-ignore
                    updateDocuments(expense.id, documentType, documentData, OperationTypeEnum.DELETE)(dispatch)
                        .then(() => resolve())
                        .catch((err) => reject(err));
                }).catch((err) => NotificationService.showErrorNotificationBasedOnResponseError(err, t("error:error")))
            },
            onCancel() {},
        })
    }

    const renderPurchase = () =>
        expense.offers?.length ? (
            <Card className="w-full card-with-tabs">
                <Tabs className="w-full min-h-200 ml-1">
                    {expense.offers?.length && (
                        <Tabs.TabPane key="offers" tab={t("input:request.offer")}>
                            <DocumentTabManager
                                invoices={mapToInvoiceInterface(expense.offers)}
                                doFileUpload={doFileUpload}
                                onRemoveTab={onRemoveTab}
                                documentType={DocumentTypeEnum.OFFER}
                                label={{
                                    buttonLabel: t("action:add_offer"),
                                    draggerDescription: t("action:or_drop_files"),
                                }}
                                readOnly={documentEditingIsProhibited}
                            />
                        </Tabs.TabPane>
                    )}
                </Tabs>
            </Card>
        ) : (
            <></>
        )

    const toggles = (
        <div className="mr-10 ml-10 text-sm ">
            {!isHospitalityExpense(expense) && ExpenseService.isExpenseEditableBasedOnArchive(archived) && !expense.isGobdCompliantInvoice() && (
                <>
                    {isOcrUploading && <Spin className="mr-10" />}
                    <span className="mr-10">{t("input:settings.ocr")}</span>
                    <Switch size="small" checked={ocrSettings.isOcrEnabled} onChange={(checked) => ocrSettings.setIsOcrEnabled(checked)} disabled={archived || isOcrUploading} />
                    <span className="mr-10 ml-10">{t("input:settings.inline_items")}</span>
                    <Switch
                        disabled={!ocrSettings?.isOcrEnabled || isOcrUploading}
                        size="small"
                        defaultChecked={ocrSettings.isOcrItemizationEnabled}
                        onChange={(checked) => ocrSettings.setIsOcrItemizationEnabled(checked)}
                    />
                </>
            )}

            {isAlreadyPaid && (
                <>
                    <span className="mr-10 ml-10">{t("input:settings.show_stamp")}</span>
                    <Switch size="small" defaultChecked={showStampOnInvoice} onChange={(checked) => setShowStampOnInvoice(checked)} />
                </>
            )}
        </div>
    )

    return (
        <>
            {(requestType as ExpenseRequestTypeEnum) !== ExpenseRequestTypeEnum.PURCHASE ? (
                <Card className="w-full card-with-tabs">
                    <Tabs className="w-full min-h-200 ml-1" tabBarExtraContent={toggles} defaultActiveKey="invoices" onChange={onChangeTab}>
                        {expense.offers?.length && (
                            <Tabs.TabPane key="offers" tab={t("input:request.offer")}>
                                <DocumentTabManager
                                    invoices={mapToInvoiceInterface(expense.offers)}
                                    doFileUpload={doFileUpload}
                                    onRemoveTab={onRemoveTab}
                                    documentType={DocumentTypeEnum.OFFER}
                                    label={{
                                        buttonLabel: t("action:add_offer"),
                                        draggerDescription: t("action:or_drop_files"),
                                    }}
                                    readOnly={documentEditingIsProhibited}
                                />
                            </Tabs.TabPane>
                        )}
                        <Tabs.TabPane key="invoices" tab={t("input:request.invoice")}>
                            <DocumentTabManager
                                invoices={expense.invoices}
                                deliveryNote={expense.deliveryNote}
                                doFileUpload={doFileUpload}
                                onRemoveTab={onRemoveTab}
                                documentType={DocumentTypeEnum.INVOICE}
                                label={{
                                    buttonLabel: t("action:add_invoice"),
                                    draggerDescription: t("action:or_drop_files"),
                                }}
                                onInboxSelected={onInboxSelected}
                                isUploading={isUploadingInvoice}
                                readOnly={documentEditingIsProhibited}
                                showPaidStamp={isAlreadyPaid && showStampOnInvoice}
                            />
                        </Tabs.TabPane>
                        {!isSubscription && expense.deliveryNote && (
                            <Tabs.TabPane key="deliveryNote" tab={t("input:request.delivery_note")}>
                                <DocumentTabManager
                                    invoices={expense.invoices}
                                    deliveryNote={expense.deliveryNote}
                                    doFileUpload={doFileUpload}
                                    onRemoveTab={onRemoveTab}
                                    documentType={DocumentTypeEnum.DELIVERY_NOTE}
                                    label={{
                                        buttonLabel: t("action:add_invoice"),
                                        draggerDescription: t("action:or_drop_files"),
                                    }}
                                    onInboxSelected={onInboxSelected}
                                    readOnly={expense.deleted || documentEditingIsProhibited}
                                />
                            </Tabs.TabPane>
                        )}
                        {vendor && vendor?.contracts?.length && (
                            <Tabs.TabPane key="contracts" tab={t("label:contracts")}>
                                <Tabs type="line" hideAdd>
                                    {!vendorWithContracts ? (
                                        <Loading />
                                    ) : (
                                        vendorWithContracts.contracts.map((filePath, index) => (
                                            <Tabs.TabPane key={`${index}`} tab={`${t("label:contract_document")} ${index + 1}`}>
                                                {filePath.includes(FileExtEnum.PDF) ? <FileViewer filePath={filePath} /> : <img alt="Failed to load file" src={filePath} />}
                                            </Tabs.TabPane>
                                        ))
                                    )}
                                </Tabs>
                            </Tabs.TabPane>
                        )}
                    </Tabs>
                </Card>
            ) : (
                renderPurchase()
            )}
        </>
    )
}

export default ExpenseDocumentSection
