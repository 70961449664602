import { AVAILABLE_PER_DIEM_YEARS } from "@finway-group/shared/lib/consts/perDiem"
import { PerDiemDestination } from "@finway-group/shared/lib/models"
import { ActionCreator, Dispatch } from "redux"

import { ExpenseHttpService } from "Shared/services"
import { ThunkResult } from "Shared/store"

import { FetchPerDiemDestinationAction, PerDiemDestinationActionTypes } from "./perDiemDestinationTypes"

export const fetchPerDiemDestinations: ActionCreator<ThunkResult<Array<PerDiemDestination>>> = () => async (dispatch: Dispatch) => {
    const data = await ExpenseHttpService.fetchPerDiemDestinationRates(AVAILABLE_PER_DIEM_YEARS)
    const action: FetchPerDiemDestinationAction = {
        type: PerDiemDestinationActionTypes.FETCH_PER_DIEM_DESTINATIONS,
        perDiemDestinations: data,
    }
    dispatch(action)
    return data
}
