import { CheckCircleOutlined, CloseCircleOutlined, ExclamationCircleTwoTone, LoadingOutlined } from "@ant-design/icons"
import { COLOR } from "@finway-group/shared/lib/consts"
import { Employee, PriceIntervalEnum, RightEnum } from "@finway-group/shared/lib/models"
import { Empty, Table, Tag, Tooltip } from "antd"
import { ColumnsType } from "antd/lib/table"
import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"

import NoSearchDataFound from "Components/NoSearchDataFound"
import UserImage from "Components/UserImage"
import DataUploadAlert from "Components/alert/dataUpload.alert"
import FilterSection from "Components/filterSection"
import Icon from "Components/icon"
import { ErrorTable } from "Components/layout/errorTable"
import Loading from "Components/loading"
import LoadingData from "Components/loading.data"
import PriceLabel from "Components/priceLabel"
import SystemAdminTag from "Components/systemAdminTag"
import { useGroupedExpenseData } from "Shared/hooks/analytics.hooks"
import { useAreCardsActivated } from "Shared/hooks/corporate.hooks"
import { useIsFreeVersion } from "Shared/hooks/featureFlags.hooks"
import { useAuditorRole, useRolesMap } from "Shared/hooks/role.hooks"
import { useFetchTable, useTable, useTableFilterAndSearchLoading } from "Shared/hooks/table.hooks"
import { useTeams } from "Shared/hooks/team.hooks"
import { useLoggedInEmployeeProfile } from "Shared/hooks/user.hooks"
import { AuthzService } from "Shared/services"
import { ThunkDispatchResult } from "Shared/store"
import { setEmployee } from "Shared/store/actions/employee/employeeActions"
import { refetchTable, updateTableOnChange } from "Shared/store/actions/tables/tableActions"
import { TablesEnum } from "Shared/store/reducers/tableConfigReducer"
import { EmployeeWithExpenseData } from "Shared/store/reducers/tableReducer"
import { formatEmployeeAbsenceDate } from "Shared/utils/employee.utils"
import { getEmployeeLocations, getUserRoleName } from "Shared/utils/getter.utils"
import { getItemById, insertIf, isItemDeleted } from "Shared/utils/helper.utils"
import { getSortOrderForColumn, isTableFilterApplied } from "Shared/utils/table.utils"

const EmployeesList: React.FC<{}> = () => {
    const { t } = useTranslation()
    const history = useHistory()
    const dispatch: ThunkDispatchResult = useDispatch()
    const isFreeVersion = useIsFreeVersion()

    const employeesTable = useTable<EmployeeWithExpenseData>(TablesEnum.EMPLOYEES)
    const loggedInUser = useLoggedInEmployeeProfile()
    const cardsActive = useAreCardsActivated()
    const teams = useTeams()

    const rolesMap = useRolesMap({ excludeDeleted: true })
    const auditorRole = useAuditorRole()

    const locations = getEmployeeLocations(employeesTable.data.docs)
    const pageLoading = useTableFilterAndSearchLoading(TablesEnum.EMPLOYEES)
    useFetchTable(TablesEnum.EMPLOYEES)

    const { expenseData, setCreditorIds } = useGroupedExpenseData(TablesEnum.EMPLOYEES, employeesTable, "requestedBy")

    const nameFormatter = (data: Employee) => (
        <div className="flex items-center image-loader gap-10">
            <UserImage user={data} size="large" />
            <div className="flex flex-col truncate">
                <div className="flex flex-row items-center mb-5 flex-wrap">
                    <p className="font-bold leading-tight truncate mr-5 mt-2">
                        {data.firstName} {data.lastName}
                    </p>
                    {data.settings?.security?.tfaEnabled && <Tag className="ant-tag-blue mr-5 mt-2">2fa</Tag>}
                    {AuthzService.hasEmployeeSystemRight(data) && <SystemAdminTag className="mr-5 mt-2" />}
                    {data.isInAbsenceOfLeave() && (
                        <Tooltip title={formatEmployeeAbsenceDate(data)}>
                            <Tag className="ant-tag-gray inline-flex mr-5 mt-2" icon={<Icon icon="calendar_cancel" className="pr-4" />}>
                                {t("label:employee_absence.absent")}
                            </Tag>
                        </Tooltip>
                    )}
                </div>

                <p className="text-xs text-text leading-none mb-0 truncate">{data.position}</p>
            </div>
        </div>
    )

    const columns: ColumnsType<EmployeeWithExpenseData> = [
        {
            key: "employee",
            title: t("label:employee"),
            className: "pl-10",
            ellipsis: true,
            sortOrder: getSortOrderForColumn(employeesTable, "employee"),
            sorter: (a, b) => `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`),
            render: nameFormatter,
            width: 270,
        },
        {
            key: "location",
            title: t("label:location"),
            ellipsis: true,
            sortOrder: getSortOrderForColumn(employeesTable, "location"),
            sorter: (a, b) => (a.location ?? "").localeCompare(b.location),
            width: 140,
            render: (employee) => <div className="truncate">{employee.location}</div>,
        },
        ...insertIf(AuthzService.isRightGrantedForLoggedInUser(RightEnum.EMPLOYEE__ALL__READ) && !isFreeVersion, {
            title: t("label:role"),
            key: "activeCompanyProfile.roleId",
            sortOrder: getSortOrderForColumn(employeesTable, "activeCompanyProfile.roleId"),
            sorter: (a: Employee, b: Employee) => {
                const aRole = rolesMap.get(a?.activeCompanyProfile?.roleId)?.name
                const bRole = rolesMap.get(b?.activeCompanyProfile?.roleId)?.name
                return aRole?.localeCompare(bRole ?? "")
            },
            width: 120,
            render: (employee: Employee) => {
                const role = rolesMap.get(employee?.activeCompanyProfile?.roleId)
                return <span>{getUserRoleName(role)}</span>
            },
        }),
        {
            title: t("label:team"),
            key: "activeCompanyProfile.team",
            sortOrder: getSortOrderForColumn(employeesTable, "activeCompanyProfile.team"),
            sorter: (a, b) => getItemById(teams, a?.activeCompanyProfile?.team)?.teamName.localeCompare(getItemById(teams, b.activeCompanyProfile.team)?.teamName),
            width: 170,
            render: (employee) => (
                <div className="inline-flex space-x-4">
                    <span>{getItemById(teams, employee?.activeCompanyProfile?.team)?.teamName}</span>
                    {isItemDeleted(teams, `${employee?.activeCompanyProfile?.team}`) && (
                        <Tooltip autoAdjustOverflow={false} placement="right" title={t("info:team_deleted")} color={COLOR.finway.orange}>
                            <ExclamationCircleTwoTone twoToneColor={COLOR.finway.orange} className="pt-4" />
                        </Tooltip>
                    )}
                </div>
            ),
        },
        ...insertIf(AuthzService.isRightGrantedForLoggedInUser(RightEnum.EMPLOYEE__ALL__READ) && cardsActive, {
            title: t("label:cards.card_program"),
            key: "cardProgram",
            width: 120,
            className: "pr-10",
            sortOrder: getSortOrderForColumn(employeesTable, "cardProgram"),
            sorter: (a: Employee, b: Employee) => {
                if (a.activeCompanyProfile.weavrData?.userId && !b.activeCompanyProfile.weavrData?.userId) return 1
                if (!a.activeCompanyProfile.weavrData?.userId && b.activeCompanyProfile.weavrData?.userId) return -1
                return 0
            },
            render: (employee: Employee) => {
                if (employee.activeCompanyProfile.weavrData?.userId) {
                    return (
                        <div className="flex items-left justify-left">
                            <Tag className="flex items-center" icon={<CheckCircleOutlined />} color="success">
                                {t("label:cards.is_enrolled")}
                            </Tag>
                        </div>
                    )
                }

                if (employee.activeCompanyProfile.roleId === auditorRole?._id) {
                    return (
                        <div className="flex items-left justify-left">
                            <Tag className="flex items-center" icon={<CloseCircleOutlined />} color="default">
                                {t("label:cards.enrollment_not_eligible")}
                            </Tag>
                        </div>
                    )
                }

                return (
                    <div className="flex items-left justify-left">
                        <Tag className="flex items-center" icon={<CloseCircleOutlined />} color="warning">
                            {t("label:cards.not_enrolled")}
                        </Tag>
                    </div>
                )
            },
        }),
        ...insertIf(AuthzService.isRightGrantedForLoggedInUser(RightEnum.EMPLOYEE__ALL__READ) && !isFreeVersion, {
            title: t("label:current_expenses"),
            key: "expenses",
            width: 150,
            align: "right",
            className: "pr-10",
            render: (employee: EmployeeWithExpenseData) => {
                const employeeExpenseData = expenseData.find((data) => data.id === employee.id)
                if (!AuthzService.canViewEmployeeExpenses(employee)) return <span>-</span>
                if (!employeeExpenseData) return <LoadingData />

                return <PriceLabel value={employeeExpenseData.value || 0} currency={loggedInUser.settings.globalCurrency} interval={PriceIntervalEnum.ONE_TIME} />
            },
        }),
    ]

    return (
        <>
            <FilterSection table={TablesEnum.EMPLOYEES} options={{ teams, locations, employees: employeesTable.data.docs, rolesMap }} />
            {pageLoading ? (
                <Loading />
            ) : employeesTable.error ? (
                <ErrorTable onTableReload={() => dispatch(refetchTable(TablesEnum.SUBSCRIPTIONS))} isLoading={employeesTable.isFetching} />
            ) : employeesTable.data.totalDocs === 0 && !employeesTable.isFetching ? (
                employeesTable.data.search ? (
                    <div className="animation-appear">
                        <NoSearchDataFound />
                    </div>
                ) : (
                    <>
                        {AuthzService.isDataUploaderGrantedForLoggedInUser() && !isFreeVersion && !isTableFilterApplied(employeesTable.data.filter) && <DataUploadAlert />}

                        <Empty
                            className="animation-appear"
                            image={`./icons/empty_table.svg`}
                            imageStyle={{
                                height: 150,
                                marginRight: "auto",
                                marginLeft: "auto",
                                marginTop: "15%",
                                marginBottom: "40px",
                                display: "inline-block",
                            }}
                            description={
                                <span className="text-lg font-bold mt-2 mb-0">
                                    {!employeesTable.data.filter ? t("info:empty_state.employees.title") : t("info:no_filter_data_found.title.employee")}
                                </span>
                            }
                        >
                            <div className="flex flex-col justify-between items-center">
                                <p>
                                    {!isTableFilterApplied(employeesTable.data.filter) ? t("info:empty_state.employees.message") : t("info:no_filter_data_found.message_approver")}
                                </p>
                            </div>
                        </Empty>
                    </>
                )
            ) : (
                <div className="overflow-auto p-2 animation-appear">
                    <Table<Employee>
                        rowKey={(record) => record.id}
                        columns={columns}
                        dataSource={employeesTable.data.docs} // this table does not use table.data.docs but the docs from the employee store
                        onChange={(pagination, filters, sorter, extra) => {
                            const onChange = updateTableOnChange(TablesEnum.EMPLOYEES, employeesTable.data, false)
                            onChange(pagination, filters, sorter)

                            const creditorIds = extra.currentDataSource.map((employee: Employee) => employee.id)
                            setCreditorIds(creditorIds)
                        }}
                        pagination={{
                            position: ["bottomRight"],
                            showSizeChanger: true,
                            current: employeesTable.data.search ? 1 : employeesTable.data.page,
                            pageSize: employeesTable.data.limit,
                            hideOnSinglePage: false,
                            pageSizeOptions: ["5", "10", "20", "50", "100"],
                        }}
                        loading={{
                            spinning: employeesTable.isFetching,
                            indicator: <LoadingOutlined style={{ fontSize: 30, color: "black" }} spin />,
                        }}
                        onRow={(employee) => ({
                            onClick: () => {
                                dispatch(setEmployee(employee))
                                history.push(`/employees/${employee.id}`)
                            },
                        })}
                    />
                </div>
            )}
        </>
    )
}

export default EmployeesList
