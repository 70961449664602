import { Expense } from "@finway-group/shared/lib/models"
import { getApprovalProcessScopeFromExpenseStatus } from "@finway-group/shared/lib/utils/approvalProcess.utils"
import { MAX_INPUT_LENGTH } from "@finway-group/shared/lib/utils/validators"
import { Form, Input } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"

import { ExpenseRules } from "Components/forms/rules"
import { MultiActionConfirmModal } from "Features/pages/expenses/expenseDetails/folderDetails/multiActionConfirm.modal"
import { rejectExpenseRequest } from "Shared/hooks/expense.hooks"
import i18n from "Shared/locales/i18n"
import { NotificationService, UserService } from "Shared/services"
import { detachAllFolderContents } from "Shared/store/actions/expense/expenseActions"
import { isFolderExpense } from "Shared/utils/expense.utils"

interface RejectModalInterface {
    isVisible: boolean
    cancelText: string
    request: Expense
    nextExpense?: string
    onCancel: () => void
}
const RejectFolderModal: React.FC<RejectModalInterface> = ({ request, isVisible, cancelText, nextExpense, onCancel }) => {
    const history = useHistory()
    const [form] = Form.useForm()
    const { t } = useTranslation()
    const loggedInProfile = UserService.getLoggedInEmployeeProfile()
    const rules = ExpenseRules()
    const dispatch = useDispatch()

    if (!isFolderExpense(request)) return <></>

    const onFormSubmit = (values: { [key: string]: string }) => {
        execFolderReject(values.reasonToReject)
            .then(() => {
                handleHide()
                if (loggedInProfile.settings.enableGoToNextExpense && nextExpense) {
                    history.push({ pathname: nextExpense, state: { fromExpenseSwitching: true } })
                }
            })
            .catch((err) => {
                NotificationService.showErrorNotificationBasedOnResponseError(err, i18n.t(`error:request.reject_folder.title`))
            })
    }

    const execFolderReject = async (reason: string) => {
        const scope = getApprovalProcessScopeFromExpenseStatus(request.status)
        await detachAllFolderContents(request.id)(dispatch)
        await rejectExpenseRequest({ expense: request, rejectionNote: reason, history, scope })
    }

    const handleHide = () => {
        form.resetFields()
        onCancel()
    }

    const description = (
        <>
            <p className="mb-24">{t("confirm:request.reject_folder.message")}</p>
            <Form form={form} layout="vertical" onFinish={onFormSubmit}>
                <Form.Item name="reasonToReject" key="reasonToReject" required rules={rules.reasonToReject}>
                    <Input maxLength={MAX_INPUT_LENGTH} placeholder={t("placeholder:request.reject")} />
                </Form.Item>
            </Form>
        </>
    )

    return (
        <MultiActionConfirmModal
            isShowing={isVisible}
            onConfirm={form.submit}
            onCancel={handleHide}
            okButtonText={t("confirm:request.reject_folder.confirm")}
            cancelButtonText={t("confirm:request.reject_folder.cancel")}
            title={t("confirm:request.reject_folder.title")}
            description={description}
            size="md"
        />
    )
}

export default RejectFolderModal
