import { CaretUpOutlined } from "@ant-design/icons"
import { CardStatusEnum, CardTypeEnum, CurrencyEnum, PriceIntervalEnum } from "@finway-group/shared/lib/models"
import { CardBinTypeEnum } from "@finway-group/shared/lib/models/card/cardBinType.enum"
import { roundNumberTo2Decimals } from "@finway-group/shared/lib/utils"
import { Row, Skeleton, Space, Tag, Tooltip } from "antd"
import moment from "moment"
import React, { useEffect } from "react"
import { Trans, useTranslation } from "react-i18next"

import ProgressBar from "Components/ProgressBar"
import EyeIcon from "Components/icons/eyeIcon"
import FlipIcon from "Components/icons/flipIcon"
import GermanyIcon from "Components/icons/germanyIcon"
import PriceLabel from "Components/priceLabel"
import { isDemo } from "Shared/config/consts"
import { useWeavrAuth } from "Shared/hooks/auth.hooks"
import { useEmployeeById } from "Shared/hooks/employee.hooks"
import { useLoggedInEmployeeProfile } from "Shared/hooks/user.hooks"
import { useSecureCreditCardDetails } from "Shared/hooks/weavr.hooks"
import { AuthzService, NotificationService } from "Shared/services"
import { NotificationTypeEnum } from "Shared/services/notification.service"
import { calcBufferSpending, getCardLimitWithoutBuffer } from "Shared/utils/creditCard.utils"
import { getTooltipPopupContainer } from "Shared/utils/helper.utils"
import useStateIfMounted from "Shared/utils/hooks/useStateIfMounted"

interface CreditCardInterface {
    cardInfo?: any
    card?: any
    showActionBtn?: boolean
    dimension?: string
    showBudget?: boolean
    skipDemoTag?: boolean
    isCardLoading?: boolean // Loading state of the card data
    showPaddingInfo?: boolean
}
const CreditCard = ({
    card,
    isCardLoading,
    showActionBtn = false,
    showBudget = false,
    dimension = "sm:w-400 sm:h-252 lg:h-189 lg:w-300 xl:w-400 xl:h-252 md:w-300 md:h-189",
    skipDemoTag = false,
    showPaddingInfo = false,
}: CreditCardInterface) => {
    const { t } = useTranslation()

    const [showFlipAction, setShowFlipAction] = useStateIfMounted(false)
    const [showDetails, setShowDetails] = useStateIfMounted(false)
    const [flipCard, setFlipCard] = useStateIfMounted(false)
    const [didMountingFail, setDidMountingFail] = useStateIfMounted(false)
    const cardHolderEmployee = useEmployeeById(card?.user)
    const { isWeavrAuthenticated } = useWeavrAuth()
    const cardHolderName = card?.nameOnCard || cardHolderEmployee?.getFullName() || "John Doe"
    const cardType = card?.cardType || CardTypeEnum.VIRTUAL
    const cardCurrency = card?.currency || CurrencyEnum.EUR

    const loggedInUser = useLoggedInEmployeeProfile()
    const cardUser = useEmployeeById(card?.user)
    const isCardOwner = loggedInUser?.id === cardUser?.id
    const canManageCard = AuthzService.canManageCardForUser(cardUser)

    const expirationDate = card.expirationDate ? moment(card.expirationDate) : undefined

    const bufferSpending = calcBufferSpending(card)
    let availableAmount = getCardLimitWithoutBuffer(card) - (card?.spending ?? 0)
    if (availableAmount < 0) availableAmount = 0

    const showCardDetails = () => {
        mountCardDetails()
        setShowFlipAction(true)
        setShowDetails(true)
        setDidMountingFail(false)
    }

    const hideCardDetails = () => {
        setShowFlipAction(false)
        setShowDetails(false)
    }

    const onFailedToShowDetails = () => {
        hideCardDetails()
        setDidMountingFail(true)
    }

    useEffect(() => {
        if (isWeavrAuthenticated && didMountingFail) {
            showCardDetails()
        }
    }, [isWeavrAuthenticated])

    const { isLoading: isCardDetailLoading, mountCardDetails, hasLoaded } = useSecureCreditCardDetails(card, "credit-card-number", "cvv", showCardDetails, onFailedToShowDetails) // Loading state of the *weavr secure* card data

    const checkIfAllowed = () => {
        if (isDemo) {
            NotificationService.send(NotificationTypeEnum.ERROR, t("error:error"), t("error:cards.not_allowed_in_demo"))
            return
        }

        if (!showDetails) showCardDetails()
        else hideCardDetails()
    }

    // CC number
    const renderPan = () => {
        const splitter = `************${card?.last4Digits || "1234"}`.match(/.{1,4}/g)
        const result: Array<JSX.Element> = [
            <div style={!showDetails || isCardDetailLoading ? { display: "none" } : {}}>
                <div key="credit-card-number" id="credit-card-number" className={`mt-4 ${!isCardDetailLoading && hasLoaded && "w-200"}`} />
            </div>,
        ]
        if (!showDetails && !isCardLoading && splitter) {
            result.push(
                ...splitter.map((chunk: string, index: number) =>
                    index < 3 ? (
                        <div key={index} className={`part-${index + 1} mr-5 mt-6`} style={{ fontSize: "2rem" }}>
                            ****
                        </div>
                    ) : (
                        <div key={index} className={`part-${index + 1}`}>
                            {chunk}
                        </div>
                    ),
                ),
            )
        } else if (isCardDetailLoading || !hasLoaded) {
            result.push(<Skeleton.Input key="credit-card-skeleton" className="opacity-75 w-200 h-14 md:h-20 mt-2" size="small" active />)
        }

        return result
    }

    const renderExpireDate = () => {
        const shouldShowDate = showDetails && !!expirationDate

        const getFormatted = (format: "MM" | "YY") => (shouldShowDate ? expirationDate?.format(format) : "**")

        return (
            <>
                <div className={`month ${!shouldShowDate && "mt-5"}`}>
                    <span style={{ fontSize: shouldShowDate ? "18px" : "2rem" }}>{getFormatted("MM")}</span>
                </div>
                <div>/</div>
                <div className={`year ${!shouldShowDate && "mt-5"}`}>
                    <span style={{ fontSize: shouldShowDate ? "18px" : "2rem" }}>{getFormatted("YY")}</span>
                </div>
            </>
        )
    }

    return (
        <>
            <div className={`card mr-auto ml-auto max-h-full w-300 h-189 ${dimension}`}>
                <div className={`w-full h-full card-display ${flipCard ? "active" : ""}`} id="virtual-card-container">
                    <div className="card-box-inner w-full h-full">
                        <div className={`card-box-front w-full h-full flex flex-col ${cardType.toLowerCase()} bg-contain xl:bg-full`}>
                            <div className="card-content w-full h-full">
                                <div className="w-full max-h-full flex flex-row justify-end p-10 h-50 md:h-50 lg:h-50 xl:h-60">
                                    {card.binType === CardBinTypeEnum.GERMAN_BIN && (
                                        <div className="flex pt-12 px-6">
                                            <Tag
                                                color="default"
                                                className="flex max-w-98 h-18 py-1 px-5 justify-center items-center bg-black bg-opacity-10 border-black border border-opacity-10"
                                            >
                                                <div className="flex gap-4">
                                                    <div className="flex items-center">
                                                        <GermanyIcon className="w-16" />
                                                    </div>
                                                    <div className="text-white text-xxs font-medium font-sans">{t("label:cards.bin")}</div>
                                                </div>
                                            </Tag>
                                        </div>
                                    )}
                                    {showActionBtn && (
                                        <div className="control-icon">
                                            {showFlipAction && <FlipIcon onClick={() => setFlipCard(!flipCard)} />}
                                            {card.status !== CardStatusEnum.UNACTIVATED && (canManageCard || isCardOwner) && <EyeIcon onClick={checkIfAllowed} />}
                                        </div>
                                    )}
                                </div>

                                {isDemo && !skipDemoTag && (
                                    <div className="text-2xl sm:text-4xl md:text-2xl lg:text-2xl xl:text-4xl mt-10 sm:mt-20 md:mt-10 lg:mt-10 xl:mt-20">DEMO CARD</div>
                                )}
                                <div className="card-info w-full mr-auto ml-auto flex flex-col items-start justify-center text-white text-lg h-100 lg:h-98 xl:h-120 md:h-100 sm:h-120 pt-5 pb-5 bottom-0">
                                    <div className={`card-pan flex flex-row top-0 ${showDetails && "pt-10"} text-sm md:text-lg`} id="mq-card-pan">
                                        {renderPan()}
                                    </div>

                                    <div className="expiry flex flex-row mt-10" id="mq-card-exp">
                                        {renderExpireDate()}
                                    </div>

                                    <div className="account-holder-name text-left w-full bottom-0 xl:mb-10 lg:mb-4 mb-8 max-w-180 sm:max-w-220 md:max-w-180 xl:max-w-220 truncate">
                                        {cardHolderName}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`card-box-back w-full h-full bg-full flex flex-col ${cardType.toLowerCase()}`}>
                            <div className="flex control-icon justify-end p-10">
                                <FlipIcon onClick={() => setFlipCard(!flipCard)} />
                            </div>
                            <div className="cvv">
                                <div className="code" id="mq-card-cvv">
                                    <div style={{ display: showDetails ? "unset" : "none" }} id="cvv" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {showBudget && (
                <div className="mt-30 w-full mr-auto ml-auto">
                    <Space direction="vertical" className="w-full">
                        <Row className={`block ${!card?.limitBuffer ? "w-full" : "w-11/12"}`} id="cost-center-budget-section">
                            <span className="float-right mb-4 mr-10">
                                <Trans i18nKey={card?.limit < card?.spending ? "label:above_limit" : "label:available"}>
                                    <PriceLabel value={availableAmount} currency={cardCurrency} interval={PriceIntervalEnum.ONE_TIME} />
                                </Trans>
                            </span>
                        </Row>
                        <Row className={!card?.limitBuffer ? "block" : ""}>
                            <ProgressBar
                                barClassName={card?.limitBuffer ? "w-11/12" : "w-full"}
                                total={getCardLimitWithoutBuffer(card) || 1}
                                value={card?.spending || 0}
                                currency={cardCurrency}
                                forecastValue={card.spendingForecast || 0}
                                extended
                                hideLabels
                                dontShowAsExceeding={bufferSpending <= card.limitBuffer}
                                // TODO: what do we want?
                                // showRemaining={GREEN_LIGHT4}
                            />
                            {card?.limitBuffer ? (
                                <ProgressBar
                                    barClassName={`w-1/12 ${card.spendingForecast > 0 ? "mb-18" : ""}`}
                                    total={card?.limitBuffer || 1}
                                    value={bufferSpending || 0}
                                    currency={cardCurrency}
                                    forecastValue={0}
                                    extended
                                    hideLabels
                                    renderTooltip={(children: any) => (
                                        <Tooltip
                                            getPopupContainer={getTooltipPopupContainer}
                                            title={
                                                bufferSpending > card.limitBuffer
                                                    ? t("info:cards.limit_buffer_spent")
                                                    : t("info:cards.limit_buffer", { available: roundNumberTo2Decimals(card.limitBuffer - bufferSpending).toFixed(2) })
                                            }
                                        >
                                            {children}
                                        </Tooltip>
                                    )}
                                />
                            ) : (
                                <></>
                            )}
                        </Row>
                        <Row>
                            <div className={`block ${!card?.limitBuffer ? "w-full" : "w-11/12"}`}>
                                <span className="mb-4 float-left ml-2">
                                    <PriceLabel value={card?.spending || 0} currency={cardCurrency} interval={PriceIntervalEnum.ONE_TIME} />
                                </span>
                                <span className="float-right mb-4 mr-10">
                                    <PriceLabel value={getCardLimitWithoutBuffer(card)} currency={cardCurrency} interval={PriceIntervalEnum.ONE_TIME} />
                                </span>
                                {showPaddingInfo && (
                                    <>
                                        <Tooltip getPopupContainer={getTooltipPopupContainer} title={t("info:cards.limit_padded")}>
                                            <span className="float-right mt-3 text-xs mr-10 opacity-75">3%</span>
                                        </Tooltip>
                                        <span className="float-right text-xs mt-1 opacity-75">
                                            <CaretUpOutlined />
                                        </span>
                                    </>
                                )}
                            </div>
                        </Row>
                    </Space>
                </div>
            )}
        </>
    )
}

export default React.memo(CreditCard)
