import { MAX_INPUT_LENGTH, MIN_INPUT_LENGTH, textMultipleWhiteSpace } from "@finway-group/shared/lib/utils/validators"
import { Rule } from "antd/lib/form"
import i18n from "i18next"

import { allowlistCharValidator } from "./utils.rules"

export const UserCommonRules = () => ({
    location: [
        {
            required: true,
            validateTrigger: ["blur", "onChange"],
            validator: (_rule: Rule, value: string) => {
                if (!value) return Promise.reject(i18n.t("validation:required"))

                if (value !== value.trim()) {
                    return Promise.reject(new Error(i18n.t("validation:string_white_space_start_or_end")))
                }

                if (textMultipleWhiteSpace.test(value)) {
                    return Promise.reject(new Error(i18n.t("validation:string_white_space_duplicate")))
                }

                if (value.length > MAX_INPUT_LENGTH) {
                    return Promise.reject(i18n.t("validation:string_too_long", { length: MAX_INPUT_LENGTH }))
                }

                if (value.length < MIN_INPUT_LENGTH) {
                    return Promise.reject(i18n.t("validation:string_too_short", { length: MIN_INPUT_LENGTH }))
                }

                return Promise.resolve()
            },
        },
        allowlistCharValidator,
    ],
    position: [
        {
            required: true,
            validateTrigger: ["blur", "onChange"],
            validator: (_rule: Rule, value: string) => {
                if (!value) return Promise.reject(i18n.t("validation:required"))

                if (value !== value.trim()) return Promise.reject(new Error(i18n.t("validation:string_white_space_start_or_end")))

                if (textMultipleWhiteSpace.test(value)) return Promise.reject(new Error(i18n.t("validation:string_white_space_duplicate")))

                if (value.length > MAX_INPUT_LENGTH) return Promise.reject(i18n.t("validation:string_too_long", { length: MAX_INPUT_LENGTH }))

                return Promise.resolve()
            },
        },
        allowlistCharValidator,
    ],
})
