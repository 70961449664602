import Icon from "@ant-design/icons"
import React from "react"

const csvSvg = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
            <path
                d="M12.3333 7.66659V3.66671L8.99996 0.333252H2C1.44771 0.333252 1 0.780965 1 1.33325V14.6666C1 15.2189 1.44771 15.6666 2 15.6666H11.3333C11.8856 15.6666 12.3333 15.2189 12.3333 14.6666V12.9999"
                stroke="#606C7E"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M9 0.333252V2.66659C9 3.21887 9.44772 3.66659 10 3.66659H12.3334" stroke="#606C7E" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M15.0007 7.6665H4.33398V12.9998H15.0007V7.6665Z" stroke="#606C7E" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M10.3333 9H9.66667C9.29848 9 9 9.29848 9 9.66667V9.66667C9 10.0349 9.29848 10.3333 9.66667 10.3333H9.66667C10.0349 10.3333 10.3333 10.6318 10.3333 11V11C10.3333 11.3682 10.0349 11.6667 9.66667 11.6667H9"
                stroke="#606C7E"
                strokeWidth="0.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.66732 11C7.66732 11.3682 7.36884 11.6667 7.00065 11.6667H7.00065C6.63246 11.6667 6.33398 11.3682 6.33398 11V9.66667C6.33398 9.29848 6.63246 9 7.00065 9H7.00065C7.36884 9 7.66732 9.29848 7.66732 9.66667"
                stroke="#606C7E"
                strokeWidth="0.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M11.666 9V10.6667L12.3327 11.6667L12.9993 10.6667V9" stroke="#606C7E" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        </g>
        <defs>
            <clipPath id="clip0">
                <rect width="16" height="16" fill="white" />
            </clipPath>
        </defs>
    </svg>
)

const CsvIcon = (props: any) => <Icon component={csvSvg} {...props} />

export default CsvIcon
