import React from "react"

import Icon, { IconInterface } from "Components/icon"

const removeFromFolderIcon = (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12.3333 15.0007C14.1743 15.0007 15.6667 13.5083 15.6667 11.6673C15.6667 9.82637 14.1743 8.33398 12.3333 8.33398C10.4924 8.33398 9 9.82637 9 11.6673C9 13.5083 10.4924 15.0007 12.3333 15.0007Z"
            stroke="#606C7E"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path d="M11 11.668H13.6667" stroke="#606C7E" stroke-miterlimit="10" stroke-linecap="round" strokeLinejoin="round" />
        <path
            d="M14.334 9.0151V3.33333C14.334 2.78105 13.8863 2.33333 13.334 2.33333H7.00065L5.66732 1H1.33398C0.781698 1 0.333984 1.44771 0.333984 2V11.3333C0.333984 11.8856 0.781698 12.3333 1.33398 12.3333H9.06783"
            stroke="#606C7E"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)

const RemoveFromFolderIcon = (props: IconInterface) => <Icon component={removeFromFolderIcon} {...props} />

export default RemoveFromFolderIcon
